import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button,
    Dialog, DialogContent,
    DialogActions,
} from '@material-ui/core';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { cloneDeep } from 'lodash';
import { Form } from 'react-bootstrap';
import CatalogEnum from 'utils/enum/CatalogEnum';
import If from 'components/widgets/conditional/If';
import ButtonStyles from 'styles/theme/Button';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

// Icons
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        width: '300px',
        padding: '20px 10px',
        height: '300px',
        overflowX: 'hidden',
        overflowY: 'auto',
        border: `0.5px solid ${theme.palette.border.ghost}`,
    },
    category: {
        alignItems: 'center',
        display: 'flex',
        zIndex: 9999,
        paddingBottom: '10px',
        borderBottom: `0.5px solid ${theme.palette.border.ghost}`,
        marginBottom: '15px',
        fontSize: '14px',
        cursor: 'pointer',
        '& > div:nth-child(1)': {
            width: '10%',
            fontWeight: 'bold',
        },
        '& > div:nth-child(2)': {
            width: '70%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '10px',
        },
    },
    dialogActions: {
        paddingRight: '20px',
    },
    actionsContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '25px',
            height: '25px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
        height: '27px',
    },
    newCategoryinput: {
        fontSize: '14px',
        resize: 'none',
        height: '31px',
        width: '161px',
    },
}));

const CatalogManager = ({
    isJobTypesCatalog,
    categories,
    jobTypes,
    onClose,
    onSave,
}) => {
    const distanceInPixels = 5;
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        categories,
        jobTypes,
        selectedItem: null,
        isReorderModeOn: !isJobTypesCatalog,
        isEditModeOn: false,
        isCreateModeOn: false,
        isSaving: false,
        newName: '',
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let sorted = arrayMove(
                state.categories,
                oldIndex,
                newIndex,
            );

            sorted = sorted.map((category, index) => ({
                ...category,
                order: index + 1,
            }));

            setState({
                ...state,
                categories: sorted,
            });
        }
    };

    const onPrepareForNew = () => {
        setState({
            ...state,
            selectedItem: {},
            isReorderModeOn: false,
            isCreateModeOn: true,
            isEditModeOn: false,
        });
    };

    const onCreateItem = () => {
        const clone = cloneDeep(isJobTypesCatalog ? state.jobTypes : state.categories);

        const { newName } = state;
        if (StringUtils.isEmpty(newName)) return;
        const anyWithSameName = clone.some((el) => (isJobTypesCatalog ? el.description : el.name).toLowerCase() === newName.toLowerCase());
        if (anyWithSameName) {
            ModalUtils.errorMessage(null, 'There is an item with that name.');
            return;
        }

        if (!isJobTypesCatalog) {
            clone.push({
                reconInspectionCategoryId: -1,
                name: newName,
                order: clone.length + 1,
                active: true,
            });
        } else {
            clone.push({
                enumValuesId: -1,
                description: newName,
                lotName: 'All',
                type: CatalogEnum.JOB_TYPE,
            });
        }

        setState({
            ...state,
            ...(isJobTypesCatalog ? { jobTypes: clone } : { categories: clone }),
            selectedItem: null,
            isReorderModeOn: !isJobTypesCatalog,
            isCreateModeOn: false,
            isEditModeOn: false,
            newName: '',
        });
    };

    const editItem = (item) => {
        setState({
            ...state,
            selectedItem: item,
            isReorderModeOn: false,
            isCreateModeOn: false,
            isEditModeOn: true,
        });
    };

    const onChange = ({ target: { name, value } }) => {
        const { selectedItem } = state;

        if (name === 'item-name') {
            setState({
                ...state,
                newName: value,
            });
            return;
        }

        setState({
            ...state,
            selectedItem: {
                ...selectedItem,
                [name]: value,
            },
        });
    };

    const onCancel = () => {
        setState({
            ...state,
            selectedItem: null,
            isReorderModeOn: !isJobTypesCatalog,
            isCreateModeOn: false,
            isEditModeOn: false,
            newName: '',
        });
    };

    const onDone = () => {
        const { selectedItem } = state;
        const clone = cloneDeep(isJobTypesCatalog ? state.jobTypes : state.categories);

        const index = clone
            .findIndex((el) => (el.reconInspectionCategoryId || el.enumValuesId) === (selectedItem.reconInspectionCategoryId || selectedItem.enumValuesId));
        if (index >= 0) {
            const property = isJobTypesCatalog ? 'description' : 'name';
            clone[index][property] = selectedItem[property];
        }

        setState({
            ...state,
            ...(isJobTypesCatalog ? { jobTypes: clone } : { categories: clone }),
            selectedItem: null,
            isReorderModeOn: !isJobTypesCatalog,
            isEditModeOn: false,
        });
    };

    const onRemoveNew = (item) => {
        const clone = cloneDeep(isJobTypesCatalog ? state.jobTypes : state.categories);
        let filtered = clone.filter((el) => (el.name || el.description) !== (item.name || item.description));

        if (!isJobTypesCatalog) {
            filtered = filtered.map((cat, index) => ({
                ...cat,
                order: index + 1,
            }));
        }

        setState({
            ...state,
            ...(isJobTypesCatalog ? { jobTypes: filtered } : { categories: filtered }),
        });
    };

    const onSaving = () => {
        setState({
            ...state,
            isSaving: true,
        });
        onSave(isJobTypesCatalog ? state.jobTypes : state.categories);
    };

    const getChild = (item, index) => {
        const { selectedItem } = state;
        const isSelectedItem = (
            selectedItem
            && (item.reconInspectionCategoryId || item.enumValuesId) === (selectedItem.reconInspectionCategoryId || selectedItem.enumValuesId)
        );

        return (
            <div
                key={`item-${index}`}
                index={index}
                className={classes.category}
            >
                <div>{item.order || index + 1}</div>
                <div>
                    <If condition={state.isEditModeOn && isSelectedItem}>
                        <Form.Control
                            maxLength="100"
                            className={classes.input}
                            type="text"
                            name={isJobTypesCatalog ? 'description' : 'name'}
                            value={(isJobTypesCatalog ? state.selectedItem?.description : state.selectedItem?.name) || ''}
                            onChange={onChange}
                        />
                    </If>
                    <If condition={!state.isEditModeOn || (state.isEditModeOn && !isSelectedItem)}>
                        {isJobTypesCatalog ? item.description : item.name}
                    </If>
                </div>
                <div className={classes.actionsContainer}>
                    <If condition={state.isEditModeOn && isSelectedItem}>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            disabled={StringUtils.isEmpty((isJobTypesCatalog ? state.selectedItem?.description : state.selectedItem?.name))}
                            startIcon={<DoneOutlinedIcon />}
                            onClick={() => onDone()}
                        />
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<CloseOutlinedIcon />}
                            onClick={() => onCancel()}
                        />
                    </If>
                    <If
                        condition={
                            (!state.isEditModeOn
                            || (state.isEditModeOn && !isSelectedItem))
                            && (item.reconInspectionCategoryId || item.enumValuesId) !== -1
                        }
                    >
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => editItem(item)}
                        />
                    </If>
                    <If condition={(item.reconInspectionCategoryId || item.enumValuesId) === -1}>
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<CloseOutlinedIcon />}
                            onClick={() => onRemoveNew(item)}
                        />
                    </If>
                </div>
            </div>
        );
    };

    const SortableItem = SortableElement(({ value, sortIndex }) => getChild(value, sortIndex));
    const SortableList = SortableContainer(({ items }) => (
        <div className={classes.content}>
            {
                items.map((category, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        sortIndex={index}
                        value={category}
                    />
                ))
            }
        </div>
    ));

    return (
        <Dialog
            open
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={isJobTypesCatalog ? 'Manage Job Types' : 'Manage Categories'}
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent>
                <If condition={state.isReorderModeOn && !isJobTypesCatalog}>
                    <SortableList
                        axis="y"
                        distance={distanceInPixels}
                        items={state.categories}
                        onSortEnd={onSortEnd}
                    />
                </If>
                <If condition={!state.isReorderModeOn}>
                    <div className={classes.content}>
                        {(isJobTypesCatalog ? state.jobTypes : state.categories).map((item, index) => getChild(item, index))}
                    </div>
                </If>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <If condition={state.isCreateModeOn}>
                    <Form.Control
                        autoFocus
                        maxLength="100"
                        className={classes.newCategoryinput}
                        type="text"
                        name="item-name"
                        value={state.newName}
                        onChange={onChange}
                    />
                </If>
                <Button
                    size="small"
                    className={classes.containedSecondaryInfo}
                    disabled={state.isEditModeOn}
                    onClick={
                        () => (state.isCreateModeOn ? onCreateItem() : onPrepareForNew())
                    }
                >
                    {state.isCreateModeOn ? 'Add' : 'New'}
                </Button>
                <Button
                    size="small"
                    className={classes.containedSecondaryInfo}
                    disabled={state.isEditModeOn || state.isSaving}
                    onClick={
                        () => (state.isCreateModeOn ? onCancel() : onSaving())
                    }
                >
                    {state.isCreateModeOn ? 'Cancel' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CatalogManager.propTypes = {
    isJobTypesCatalog: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    jobTypes: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CatalogManager;

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid,
    Dialog, DialogContent,
    DialogActions,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import useAxios from 'axios-hooks';
import HttpClient from 'services/api/HttpClient';
import { modules } from 'utils/enum/modules';
import { HttpMethods, DataSort } from 'utils/enum/Core';
import { Col, Form } from 'react-bootstrap';
import ButtonStyles from 'styles/theme/Button';
import Table from 'components/widgets/Table';
import If from 'components/widgets/conditional/If';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StringUtils from 'lib/StringUtils';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    columnStyle: {
        alignItems: 'center',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    tableContainer: {
        '& > div': {
            maxHeight: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        '& .rt-table': {
            minHeight: '400px',
        },
        '& .rt-tbody > div > div': {
            height: '40px',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '450px',
            overflowX: 'auto',
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    labels: {
        fontSize: '14px',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
}));

const INIT_STATE = {
    start: 1,
    limit: 50,
    tags: [],
    tagsTotal: 0,
    selectedRow: null,
    formOpen: false,
    confirmDialogOpen: false,
};

const ACTION_TYPES = {
    SET_TAGS: 'setTags',
    SET_SELECTED_ROW: 'setSelectedRow',
    TOGGLE_FORM: 'toggleForm',
    CHANGE_FIELD: 'changeField',
    TOGGLE_CONFIRM_DIALOG: 'toggleConfirmDialog',
    MODIFY_TAG: 'removeTag',
    SET_START: 'setStart',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TAGS:
        const { rows, total } = action.value;

        return {
            ...state,
            tags: rows,
            tagsTotal: total,
        };
    case ACTION_TYPES.SET_START:
        return {
            ...state,
            start: action.value,
        };
    case ACTION_TYPES.SET_SELECTED_ROW:
        return {
            ...state,
            selectedRow: action.value,
        };
    case ACTION_TYPES.TOGGLE_FORM:
        if (action.value) {
            const { selectedRow } = action.value;

            return {
                ...state,
                formOpen: !state.formOpen,
                selectedRow,
            };
        }

        return {
            ...state,
            formOpen: !state.formOpen,
        };
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG:
        return {
            ...state,
            confirmDialogOpen: !state.confirmDialogOpen,
        };
    case ACTION_TYPES.CHANGE_FIELD:
        const { name, value } = action.value;
        const clone = {
            ...(state.selectedRow || {}),
        };
        clone[name] = value;

        return {
            ...state,
            selectedRow: clone,
        };
    case ACTION_TYPES.MODIFY_TAG:
        const { tags, selectedRow } = action.value;

        return {
            ...state,
            tags,
            selectedRow,
        };
    default:
        return state;
    }
};

const TagsSettings = ({ canAdd, canDelete }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const {
        start,
        limit,
        tags,
        tagsTotal,
        selectedRow,
        formOpen,
        confirmDialogOpen,
    } = state;

    const [{
        data: tagsData,
        loading: tagsLoading,
        error: tagsError,
    }] = useAxios({
        url: HttpClient.getURLInventoryTags(),
        method: HttpMethods.GET,
        params: {
            start,
            limit,
            dir: DataSort.ASC,
            orderBy: 'name',
        },
    });

    const [
        { loading: loadingCreateTag },
        createTagRequest,
    ] = useAxios({
        url: HttpClient.getURLInventoryTagCreate(),
        method: HttpMethods.POST,
    }, { manual: true });

    const [
        { loading: loadingUpdateTag },
        updateTagRequest,
    ] = useAxios({
        url: HttpClient.getURLInventoryTagUpdate(selectedRow?.TagId),
        method: HttpMethods.PUT,
    }, { manual: true });

    const [
        { loading: loadingDeleteTag },
        deleteTagRequest,
    ] = useAxios({
        url: HttpClient.getURLInventoryTagDelete(selectedRow?.Module, selectedRow?.TagId),
        method: HttpMethods.DELETE,
    }, { manual: true });

    useEffect(() => {
        if (tagsError) {
            ModalUtils.errorMessage(null, tagsError.message);
            return;
        }

        if (!tagsLoading) {
            const { Rows, TotalCount } = tagsData?.Data;
            dispatch({
                type: ACTION_TYPES.SET_TAGS,
                value: {
                    rows: Rows,
                    total: TotalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsLoading, tagsError]);

    const editTag = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_FORM,
        });
    };

    const onSelectRow = (record) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_ROW,
            value: record,
        });
    };

    const onCloseForm = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_FORM,
        });
    };

    const onChange = ({ target: { name, value } }) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_FIELD,
            value: {
                name,
                value,
            },
        });
    };

    const saveTag = async () => {
        try {
            if (!selectedRow.TagId) {
                const payload = {
                    Name: selectedRow.Name,
                    Description: selectedRow.Description,
                    Module: StringUtils.toPascalCase(modules.INVENTORY),
                    Priority: selectedRow.Priority,
                    Color: '#757575',
                    Active: true,
                };

                const response = await createTagRequest({
                    data: payload,
                });

                const clone = [...tags];
                const TagId = response?.data?.Data;
                clone.push({
                    TagId,
                    AssociatedRecords: 0,
                    ...payload,
                });

                clone.sort((a, b) => a.Name.localeCompare(b.Name));
                dispatch({
                    type: ACTION_TYPES.MODIFY_TAG,
                    value: {
                        tags: clone,
                        selectedRow: null,
                    },
                });

                ModalUtils.successMessage(null, 'Tag created successfully');
                onCloseForm();
                return;
            }

            await updateTagRequest({
                data: {
                    Name: selectedRow.Name,
                    Description: selectedRow.Description,
                    Module: selectedRow.Module,
                    Priority: selectedRow.Priority,
                    Color: selectedRow.Color,
                    Active: selectedRow.Active,
                },
            });

            const clone = [...tags];
            const index = clone.findIndex((item) => item.TagId === selectedRow.TagId);
            clone[index] = selectedRow;

            dispatch({
                type: ACTION_TYPES.SET_TAGS,
                value: {
                    rows: clone,
                    total: tagsTotal,
                },
            });

            ModalUtils.successMessage(null, 'Tag updated successfully');
            onCloseForm();
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const addTag = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_FORM,
            value: {
                selectedRow: null,
            },
        });
    };

    const deleteTag = async (skipDialog = false) => {
        if (!selectedRow) return;

        if (!skipDialog && selectedRow.AssociatedRecords > 0) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
            });

            return;
        }

        try {
            await deleteTagRequest();

            let clone = [...tags];
            clone = clone.filter((item) => item.TagId !== selectedRow.TagId);

            dispatch({
                type: ACTION_TYPES.MODIFY_TAG,
                value: {
                    tags: clone,
                    selectedRow: null,
                },
            });
            ModalUtils.successMessage(null, 'Tag deleted successfully');
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
        });
    };

    const loadMore = () => {
        dispatch({
            type: ACTION_TYPES.SET_START,
            value: tags.length + 1,
        });
    };

    const getColumns = () => [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'Name',
            className: classes.columnStyle,
            minWidth: 120,
        },
        {
            Header: 'Description',
            id: 'description',
            accessor: 'Description',
            className: classes.columnStyle,
            minWidth: 150,
        },
        {
            Header: 'Module',
            id: 'module',
            accessor: 'Module',
            className: classes.columnStyle,
            width: 120,
            minWidth: 120,
        },
        {
            Header: 'Priority',
            id: 'priority',
            accessor: 'Priority',
            className: classes.columnStyle,
            width: 120,
            minWidth: 120,
        },
    ];

    const priorityOptions = ['High', 'Medium', 'Low', 'Informational']
        .map((item) => ({
            value: item,
            label: item,
        }));

    return (
        <>
            <Grid container className={classes.box}>
                <Form.Group as={Col}>
                    <If condition={canAdd}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            startIcon={<AddOutlinedIcon />}
                            onClick={addTag}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            startIcon={<EditOutlinedIcon />}
                            disabled={!selectedRow}
                            onClick={editTag}
                        >
                            Edit
                        </Button>
                    </If>
                    <If condition={canDelete}>
                        <Button
                            size="small"
                            className={classes.containedError}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={!selectedRow || loadingDeleteTag}
                            onClick={() => deleteTag()}
                        >
                            Delete
                        </Button>
                    </If>
                </Form.Group>
                <Grid item xs={12}>
                    <Form.Group className={classes.tableContainer} as={Col}>
                        <Table
                            cursor="default"
                            loadMore={loadMore}
                            totalRecords={tagsTotal}
                            data={tags}
                            columns={getColumns()}
                            sortable
                            getTrProps={(_, rowInfo) => {
                                const record = rowInfo.original;
                                const selected = (!selectedRow ? false : selectedRow.TagId === record.TagId);
                                return {
                                    onDoubleClick: editTag,
                                    onClick: () => onSelectRow(record),
                                    className: selected ? 'active' : '',
                                };
                            }}
                        />
                    </Form.Group>
                </Grid>
            </Grid>
            <Dialog
                open={formOpen}
                fullWidth
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Tag Form"
                    onClose={onCloseForm}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.content}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Name (10 Chars Max)</Form.Label>
                            <Form.Control
                                maxLength="10"
                                className={classes.input}
                                type="text"
                                name="Name"
                                value={selectedRow?.Name || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Description</Form.Label>
                            <Form.Control
                                className={classes.input}
                                as="textarea"
                                name="Description"
                                value={selectedRow?.Description || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Priority</Form.Label>
                            <Select
                                nowrap
                                className={classes.input}
                                name="Priority"
                                onChange={(name, value) => onChange(
                                    { target: { name, value } },
                                )}
                                value={selectedRow?.Priority || ''}
                                options={priorityOptions}
                            />
                        </Form.Group>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Form.Group as={Col}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            disabled={
                                StringUtils.isEmpty(selectedRow?.Name)
                                || StringUtils.isEmpty(selectedRow?.Description)
                                || StringUtils.isEmpty(selectedRow?.Priority)
                                || loadingUpdateTag
                                || loadingCreateTag
                            }
                            onClick={saveTag}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="Attention!"
                description={`
                    This tag is currently on one or more stocks,
                    by deleting this tag you will be removing this tag from them all.
                    Continue?
                `}
                open={confirmDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onConfirmDialogClose}
                onClickSecondary={onConfirmDialogClose}
                onClickPrimary={() => {
                    deleteTag(true);
                    onConfirmDialogClose();
                }}
            />
        </>
    );
};

TagsSettings.propTypes = {
    canAdd: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
};

export default TagsSettings;

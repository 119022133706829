import { gql } from '@apollo/client';

export default class ActivitySubscription {
    static UPDATED_ACTIVITY_TASK = gql`
        subscription updatedActivityTask($crmId: ID!) {
            updatedActivityTask(crmId: $crmId) {
                data {
                    type
                    note
                    activityId
                    realizationDate
                    subject
                    assignee
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    task {
                        status
                        createdOn
                    }
                }
                type
            }
        }
    `;

    static UPDATED_ACTIVITY_CALL = gql`
        subscription updatedActivityCall($crmId: ID!) {
            updatedActivityCall(crmId: $crmId) {
                data {
                    type
                    note
                    activityId
                    realizationDate
                    subject
                    assignee
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    call {
                        duration
                        status
                        result
                        createdOn
                    }
                }
                type
            }
        }
    `;

    static UPDATED_ACTIVITY_EVENT = gql`
        subscription updatedActivityEvent($crmId: ID!) {
            updatedActivityEvent(crmId: $crmId) {
                data {
                    type
                    note
                    activityId
                    realizationDate
                    subject
                    assignee
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    event {
                        start
                        end
                        status
                        createdOn
                    }
                }
                type
            }
        }
    `;

    static UPDATED_ACTIVITY_NOTE = gql`
        subscription updatedActivityNote($crmId: ID!) {
            updatedActivityNote(crmId: $crmId) {
                data {
                    note
                    realizationDate
                    activityId
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                }
                type
            }
        }
    `;

    static ACTIVITY_UPDATED = gql`
        subscription activityUpdated($crmId: ID!) {
            activityUpdated(crmId: $crmId) {
                data {
                    __typename
                    activityId
                    type
                    note
                    subject
                    realizationDate
                    modifiedOn
                    createdOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    ... on CallActivity {
                        call {
                            duration
                            status
                            result
                            createdOn
                        }
                    }
                    ... on TaskActivity {
                        task {
                            status
                            createdOn
                        }
                    }
                    ... on EventActivity {
                        event {
                            start
                            end
                            status
                            createdOn
                        }
                    }
                    ... on ActionActivity {
                        action {
                            data
                            type
                        }
                    }
                }
                type
            }
        }
    `;
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Grid, Button,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { GOAL_DATA_TYPE } from 'utils/enum/BusinessIntelligenceEnum';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ButtonStyles from 'styles/theme/Button';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import BIMutation from 'services/graphQL/mutate/businessIntelligence/Mutation';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import InputSearch from 'components/widgets/InputSearch';
import VirtualTable from 'components/widgets/VirtualTable';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AddGoalDialog from 'components/modules/settings/businessIntelligence/AddGoalDialog';
import AddCategoryDialog from 'components/modules/settings/businessIntelligence/AddCategoryDialog';

// icons
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        minWidth: '800px',
        height: '400px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        '& > div': {
            overflow: 'hidden',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingRight: '15px',
        paddingLeft: '15px',
        '& > div': {
            width: '250px',
        },
        '& > button span.MuiButton-startIcon': {
            marginRight: 0,
        },
    },
    dialog: {
        '& > div.MuiDialog-container > div': {
            maxWidth: '400px',
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    subLabel: {
        fontSize: '13px',
    },
    input: {
        fontSize: '13px',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
        '.css-26l3qy-menu > div': {
            maxHeight: '130px',
            overflowX: 'hidden',
        },
    },
    dynamicFieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    categoryWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '15px',
        '& > button': {
            minWidth: '20px',
            height: '31px',
            marginRight: '5px',
            marginTop: '9px',
            '& span.MuiButton-startIcon': {
                margin: 0,
            },
        },
    },
    valueWrapper: {
        display: 'flex',
        '& > input, & > div.select-bootstrap:nth-child(1)': {
            width: '65%',
        },
        '& > div.select-bootstrap:nth-child(2)': {
            width: '35%',
            marginLeft: '5px',
        },
    },
}));

const goalAutomaticOptions = BIHelper.getGoalAutomaticOptions();
const GoalsSettings = ({ canWrite }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        goals: [],
        categories: [],
        selectedGoal: null,
        selectedLabel: null,
        selectedCategory: null,
        selectedType: GOAL_DATA_TYPE.NUMERIC,
        selectedValue: 0,
        filteredRecords: null,
        isDeletePromptVisible: false,
        isDialogOpen: false,
        isCategoryPanelOpen: false,
    });

    const {
        data: goalsData,
        loading: loadingGoals,
        error: errorLoadingGoals,
        refetch: refetchGoals,
    } = useQuery(BIQuery.PULL_AVAILABLE_GOALS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: categoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
        refetch: refetchCategories,
    } = useQuery(BIQuery.PULL_AVAILABLE_CATEGORIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const [saveGoal, { loading: savingGoal }] = useMutation(BIMutation.SAVE_GOAL, {
        onCompleted: (response) => {
            if (response) {
                setState({
                    ...state,
                    isDialogOpen: false,
                    isDeletePromptVisible: false,
                    selectedGoal: null,
                    selectedLabel: null,
                    selectedCategory: null,
                    selectedType: GOAL_DATA_TYPE.NUMERIC,
                    selectedValue: 0,
                });
                refetchGoals();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveCategory, { loading: savingCategory }] = useMutation(BIMutation.SAVE_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                setState({
                    ...state,
                    isCategoryPanelOpen: false,
                    selectedCategory: response?.saveCategory,
                });
                refetchCategories();
                refetchGoals();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingGoals) {
            ModalUtils.errorMessage(errorLoadingGoals?.graphQLErrors);
            return;
        }

        if (!loadingGoals) {
            const goals = goalsData?.pullAvailableGoals;
            if (goals) setState({ ...state, goals });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingGoals, errorLoadingGoals]);

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
            return;
        }

        if (!loadingCategories) {
            const categories = categoriesData?.pullAvailableCategories;
            if (categories) setState({ ...state, categories });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    const onSearch = (value) => {
        if (!value) {
            setState({ ...state, filteredRecords: null });
            return;
        }

        const filteredRecords = state.goals
            .filter((goal) => goal.label?.toLowerCase().includes(value.toLowerCase()) || goal.category?.name?.toLowerCase().includes(value.toLowerCase()));
        setState({ ...state, filteredRecords });
    };

    const toggleDeletePrompt = () => {
        setState({ ...state, isDeletePromptVisible: false });
    };

    const displayPrompt = (record) => {
        setState({ ...state, isDeletePromptVisible: true, selectedGoal: record });
    };

    const onEditGoal = (record) => {
        let formattedValue = null;
        const goalFunction = goalAutomaticOptions.find((el) => el.function === record.value);

        if (record.type === GOAL_DATA_TYPE.NUMERIC) formattedValue = goalFunction ? goalFunction.function : Number(record.value);
        if (record.type === GOAL_DATA_TYPE.DATE_TIME) formattedValue = DateUtils.toLocal(record.value).toDate();
        if (record.type === GOAL_DATA_TYPE.ALPHANUMERIC) formattedValue = record.value;

        setState({
            ...state,
            isDialogOpen: !state.isDialogOpen,
            selectedGoal: record,
            selectedLabel: record.label,
            selectedCategory: record.category.id,
            selectedType: record.type,
            selectedValue: formattedValue,
        });
    };

    const onDeleteGoal = () => {
        if (!state.selectedGoal) return;

        saveGoal({
            variables: {
                ...state.selectedGoal,
                categoryId: state.selectedGoal.category.id,
                active: false,
            },
        });
    };

    const toggleDialog = () => {
        setState({
            ...state,
            isDialogOpen: !state.isDialogOpen,
            ...(state.isDialogOpen ? {
                selectedGoal: null,
                selectedLabel: null,
                selectedCategory: null,
                selectedType: GOAL_DATA_TYPE.NUMERIC,
                selectedValue: 0,
            } : {}),
        });
    };

    const onChange = ({ target: { name, value } }) => {
        const isType = name === 'selectedType';
        let defaultValue = null;

        if (isType && value === GOAL_DATA_TYPE.NUMERIC) defaultValue = 0;
        if (isType && value === GOAL_DATA_TYPE.DATE_TIME) defaultValue = null;
        if (isType && value === GOAL_DATA_TYPE.ALPHANUMERIC) defaultValue = '';

        setState({
            ...state,
            [name]: value,
            ...(isType ? { selectedValue: defaultValue } : {}),
        });
    };

    const onSaveGoal = () => {
        const isDateTime = state.selectedType === GOAL_DATA_TYPE.DATE_TIME;
        if (state.selectedGoal) {
            saveGoal({
                variables: {
                    id: state.selectedGoal.id,
                    categoryId: state.selectedCategory,
                    label: state.selectedLabel,
                    type: state.selectedType,
                    value: isDateTime ? DateUtils.getISOStringFromLocal(state.selectedValue) : String(state.selectedValue),
                },
            });

            return;
        }

        saveGoal({
            variables: {
                categoryId: state.selectedCategory,
                label: state.selectedLabel,
                type: state.selectedType,
                value: isDateTime ? DateUtils.getISOStringFromLocal(state.selectedValue) : String(state.selectedValue),
            },
        });
    };

    const toggleCategoryPanel = (isNew) => {
        setState({
            ...state,
            isCategoryPanelOpen: !state.isCategoryPanelOpen,
            ...(isNew ? { selectedCategory: null } : {}),
        });
    };

    const onSaveCategory = (name) => {
        if (state.selectedCategory) {
            saveCategory({
                variables: {
                    id: state.selectedCategory,
                    name,
                },
            });

            return;
        }

        saveCategory({
            variables: {
                name,
            },
        });
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Category',
            dataKey: 'category',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.category?.name?.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Label',
            dataKey: 'label',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.label}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Type',
            dataKey: 'type',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.type}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Value',
            dataKey: 'value',
            width: 350,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                let formattedValue = null;

                switch (record.type) {
                case GOAL_DATA_TYPE.DATE_TIME:
                    formattedValue = DateUtils.getFormattedDateInUserTimezone(record.value, DateFormat.DEFAULT_DATETIME_WITHOUT_SECONDS);
                    break;
                case GOAL_DATA_TYPE.NUMERIC:
                    const goalFunction = goalAutomaticOptions.find((el) => el.function === record.value);
                    formattedValue = goalFunction ? goalFunction.label : NumberUtils.applyThousandsFormat(Number(record.value), '0,0.00');
                    break;
                default:
                    formattedValue = record.value;
                    break;
                }

                return (
                    <span className={classes.text}>
                        {formattedValue}
                    </span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!canWrite) return null;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => onEditGoal(record)}
                        />
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => displayPrompt(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const categoryDialogInitValue = state.selectedCategory
        ? state.categories.find((cat) => cat.id === state.selectedCategory)?.name
        : '';

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12}>
                <div className={classes.header}>
                    <InputSearch
                        size="sm"
                        executeWhenClearButton={() => onSearch()}
                        onSearch={onSearch}
                    />
                    {canWrite && (
                        <Button
                            size="small"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={toggleDialog}
                        />
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingGoals}
                        rowHeight={45}
                        totalRecords={(state.filteredRecords || state.goals).length}
                        data={state.filteredRecords || state.goals}
                        columns={getColumns()}
                    />
                </div>
            </Grid>
            <AddGoalDialog
                classes={classes}
                isDialogOpen={state.isDialogOpen}
                toggleDialog={toggleDialog}
                toggleCategoryPanel={toggleCategoryPanel}
                onChange={onChange}
                onSaveGoal={onSaveGoal}
                loadingCategories={loadingCategories}
                savingGoal={savingGoal}
                categories={state.categories}
                selectedLabel={state.selectedLabel}
                selectedCategory={state.selectedCategory}
                selectedType={state.selectedType}
                selectedValue={state.selectedValue}
            />
            {state.isCategoryPanelOpen && (
                <AddCategoryDialog
                    initialValue={categoryDialogInitValue}
                    isSavingCategory={savingCategory}
                    onClose={() => toggleCategoryPanel(false)}
                    onSaveCategory={onSaveCategory}
                />
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this goal, existing queries using it won't be affected?"
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={toggleDeletePrompt}
                onClickSecondary={toggleDeletePrompt}
                onClickPrimary={onDeleteGoal}
                disablePrimaryButton={savingGoal}
                disableSecondaryButton={savingGoal}
            />
        </Grid>
    );
};

GoalsSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default GoalsSettings;

import React, { Component } from 'react';

// Components and Others
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';
import CustomerService from 'services/modules/CustomerService';

const ManageEmploymentContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.customerService = new CustomerService();
        this.employmentListSubscription = null;

        this.state = {
            records: [],
            load: false,
            customerEmploymentId: null,
            openConfirm: false,
            openEmployment: false,
            currentEmployment: {},
            isEditing: false,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
        this.subscribeEmploymentList();
    }

    componentWillUnmount() {
        this.unsubscribeEmploymentList();
    }

    onOpenConfirm(customerEmploymentId) {
        this.setState({
            openConfirm: true,
            customerEmploymentId,
        });
    }

    onCloseConfirm() {
        this.setState({
            openConfirm: false,
            customerEmploymentId: null,
        });
    }

    onOpenEmployment() {
        this.setState({
            openEmployment: true,
        });
    }

    onCloseEmployment() {
        this.setState({
            openEmployment: false,
            currentEmployment: {},
            isEditing: false,
        });
    }

    onEdit(record) {
        this.setState({
            currentEmployment: record,
            openEmployment: true,
            isEditing: true,
        });
    }

    setCurrentEmployment(record) {
        this.customerService
            .setCurrentEmployment({ customerId: record.customerId, customerEmploymentId: record.customerEmploymentId })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    getServicesData() {
        const { props } = this;
        const input = {
            input: {
                customerId: props.customerId,
                status: 'Active',
            },
            sort: {
                field: 'start',
                dir: 'desc',
            },
        };

        this.setState({
            load: true,
        });

        this.customerService.getEmploymentList(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setState({
                        records: data,
                    });
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    subscribeEmploymentList() {
        const { props } = this;
        const input = {
            customerId: props.customerId,
        };
        this.graphqlClient.subscribe(this.responseSubscription, CustomerSubscription.CUSTOMER_EMPLOYMENT_LIST, input)
            .then((response) => {
                this.employmentListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.customerEmploymentListChanged) {
            const { customerEmploymentListChanged: { type, customerEmployment } } = data;

            switch (type) {
            case SubscriptionActionType.CREATED:
                this.addNewEmployment(customerEmployment);
                break;
            case SubscriptionActionType.UPDATED:
                this.updateEmployment(customerEmployment);
                break;
            case SubscriptionActionType.DELETED:
                this.removeEmployment(customerEmployment);
                break;
            default:
            }
        }
    }

    addNewEmployment(record) {
        this.setState(({ records }) => {
            let newRecords = [];

            if (record.isCurrentEmployment) {
                newRecords = this.makeOutdatedEmployment([...records]);
            } else {
                newRecords = [...records];
            }

            newRecords = orderBy(update(newRecords, { $push: [record] }), ['start'], ['desc']);

            return { records: newRecords };
        });
    }

    updateEmployment(record) {
        this.setState(({ records }) => {
            let newRecords = [...records];

            if (record.isCurrentEmployment) {
                newRecords = this.makeOutdatedEmployment([...records]);
            }

            const employmentIndex = newRecords.findIndex((item) => item.customerEmploymentId === record.customerEmploymentId);
            newRecords[employmentIndex] = { ...record };

            return { records: newRecords };
        });
    }

    removeEmployment(record) {
        this.setState(({ records }) => {
            const newRecords = [...records];
            newRecords.splice(newRecords.findIndex((item) => item.customerEmploymentId === record.customerEmploymentId), 1);

            return { records: newRecords };
        });
    }

    makeOutdatedEmployment(records) {
        const newRecords = [...records];
        const currentEmpIndex = newRecords.findIndex((item) => item.isCurrentEmployment);
        newRecords[currentEmpIndex] = { ...newRecords[currentEmpIndex], isCurrentEmployment: false };

        return newRecords;
    }

    unsubscribeEmploymentList() {
        if (this.employmentListSubscription) {
            this.employmentListSubscription.unsubscribe();
        }
    }

    deleteCustomerEmployment() {
        const { state: { customerEmploymentId } } = this;
        const input = {
            customerEmploymentId,
        };

        this.customerService.deleteCustomerEmployment(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data && data.deleteCustomerEmployment) {
                    this.onCloseConfirm();
                }
            });
    }

    initBind() {
        this.onEdit = this.onEdit.bind(this);
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onOpenEmployment = this.onOpenEmployment.bind(this);
        this.onCloseEmployment = this.onCloseEmployment.bind(this);
        this.setCurrentEmployment = this.setCurrentEmployment.bind(this);
        this.deleteCustomerEmployment = this.deleteCustomerEmployment.bind(this);
        this.subscribeEmploymentList = this.subscribeEmploymentList.bind(this);
        this.unsubscribeEmploymentList = this.unsubscribeEmploymentList.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.addNewEmployment = this.addNewEmployment.bind(this);
        this.updateEmployment = this.updateEmployment.bind(this);
        this.removeEmployment = this.removeEmployment.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onEdit={this.onEdit}
                onOpenConfirm={this.onOpenConfirm}
                onCloseConfirm={this.onCloseConfirm}
                getServicesData={this.getServicesData}
                onOpenEmployment={this.onOpenEmployment}
                onCloseEmployment={this.onCloseEmployment}
                setCurrentEmployment={this.setCurrentEmployment}
                deleteCustomerEmployment={this.deleteCustomerEmployment}
            />
        );
    }
};

export default ManageEmploymentContainer;

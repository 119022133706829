import { gql } from '@apollo/client';

export default class ConversationMutate {
    static ADD_CRM_MESSAGE = gql`
        mutation addCRMMessage($id: ID!, $textMessage: TextMessageInput!) {
            addCRMMessage(id:$id, textMessage: $textMessage) {
                ... on QuickReply {
                    _id
                }
                ... on CardMessage {
                    _id
                }
                ... on TextMessage {
                    _id
                }
            }
        }
    `;

    static JOIN_TO_CONVERSATION = gql`
        mutation joinToConversation($conversationId: ID!) {
            joinToConversation(conversationId: $conversationId) {
                _id
            }
        }
    `;
}

import { gql } from '@apollo/client';

export default class DealsQuery {
    static GET_DEALS = gql`
        query getDeals($paginate: DealPaginate, $sort: DealSort, $filter: DealFilter) {
            getDeals(paginate: $paginate, sort: $sort,  filter: $filter) {
                totalCount
                deals {
                    accountNumber
                    dealType
                    flag
                    status
                    dealFunded
                    postedDate
                    soldDate
                    financeCompany
                    frontEndProfit
                    backEndProfit
                    totalProfit
                    notes
                    lotName
                    tradeInCode1
                    tradeInCode2
                    hasTrade1
                    hasTrade2
                    cashDownPayment
                    clientId
                    sentToTagAgencyOn
                    transferStatus
                    vehicle {
                        stockNumber
                        year
                        make
                        model
                        trim
                        extColor
                        miles
                        thumbnail
                        style
                        purchasedDate
                    }
                    buyer {
                        customerCode
                        firstName
                        middleName
                        lastName
                    }
                    coBuyer {
                        customerCode
                        firstName
                        middleName
                        lastName
                    }
                    salesPerson1 {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    salesPerson2 {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    financeManager {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    salesManager {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    }
                    bdcAgent {
                        userId
                        firstName
                        middleName
                        lastName
                        picture
                    },
                }
            }
        }
    `;

    static GET_DEALS_BY_CUSTOMER = gql`
        query getDealsByCustomer($customerId: Int!, $sort: DataSort, $paging: DataPaging) {
            getDealsByCustomer(customerId: $customerId, sort: $sort, paging: $paging) {
                accountNumber
                soldDate
                status
                dealType
                vehicle {
                    stockNumber
                    year
                    make
                    model
                    trim
                    thumbnail
                    style
                }
            }
        }
    `;

    static GET_DEAL = gql`
        query getDeal($accountNumber: Int!){
            getDeal(accountNumber: $accountNumber) {
                deal {
                    dealType
                    stockNumber
                    status
                    soldDate
                    salesPrice
                    financeAmount
                    totalFees
                    totalProductsPrice
                    totalDeferredDownPayment
                    totalTradeAllowance
                    totalTradePayOff
                    buyRate
                    interestRate
                    maxRate
                    reserveAmount
                    taxableAmount
                    salesTax
                    cashDownPayment
                    discount
                    balanceDue
                    paymentAmount
                    discountOverride
                    reserveOverride
                    registerOutState
                    term
                    firstPaymentDueDays
                    firstPaymentDue
                    tagPlate
                    tagPlateType
                    tags
                    assigned
                    paymentFrequency
                    dealerFee
                    inspectionFee
                    tagAgencyFee
                    userField1
                    userField2
                    userField3
                    userField4
                    userField5
                    userField6
                    userField7
                    registration
                    title
                    totalTitleRegistration
                    financeCompany
                    taxExempt
                    salesTaxOverride
                    inventoryTaxOverride
                    dealerInventoryTax
                    state
                    county
                    city
                    country
                    lotName
                    floorPlanCost
                    isResidualPercent
                    isUpFrontLeaseTaxes
                    mbi
                    msrp
                    leaseOveragePerMile
                    plannedAnnualMileage
                    contractAnnualMileage
                    leaseEndingOverage
                    terminationFee
                    rebates
                    reservePercent
                    residualPercent
                    residualValue
                    docStamp
                    tiPayoff1
                    tiPayoff2
                    bankOriginAmount
                    regZAPR
                    loanContractId
                    portfolioId
                    clientId
                    postedDate
                    netDue
                    salesTaxRate
                    finalPaymentDue
                    finalPaymentAmount
                    paymentOverride
                    minimumPaymentOverride
                    paymentOverridePercent
                    leadSource
                    originalFinanceCharge
                    balanceDueOverride
                    balanceDueOverrideAmount
                    totalGrossCapitalizedCost,
                    leaseTotalDueAtSigning,
                    monthlyLeaseTax,
                    backEndTax,
                    hasBalloonPayment
                    adjustLastPayment
                }
                deferredPayments {
                    deferredDownPaymentId
                    paymentDate
                    paymentNumber
                    paymentAmount
                }
            }
        }
    `;

    static GET_DEALS_TO_RFC = gql`
        query getDealsToRFC($filter: DealFilter) {
            getDealsToRFC(filter: $filter) {
                totalCount
                deals {
                    accountNumber
                    dealType
                    status
                    postedDate
                    soldDate
                    lotName
                    vehicle {
                        stockNumber
                        year
                        make
                        model
                    }
                    buyer {
                        customerCode
                        firstName
                        middleName
                        lastName
                    }
                }
            }
        }
    `;

    static GET_DEAL_VEHICLES = gql`
        query getDealVehicles($accountNumber: Int!) {
            getDealVehicles(accountNumber: $accountNumber) {
                deal {
                    status
                    postedDate
                }
                vehicle {
                    thumbnail
                    stockNumber
                    vin
                    year
                    make
                    model
                    trim
                    extColor
                    intColor
                    miles
                    tmu
                    exempt
                    eml
                    engine
                    transmission
                    style
                    drivetrain
                    titleStatus
                    title
                    titleNumber
                    spareKey
                }
                tradeIns {
                    dealVehicleTradeID
                    dealId
                    tradeNumber
                    name
                    address
                    city
                    state
                    zip
                    county
                    notes
                    phone
                    stockNumber
                    allowance
                    acv
                    payOff
                    payOffGoodTill
                    noSalesTaxCredit
                    vehicle {
                        stockNumber
                        thumbnail
                        vin
                        year
                        make
                        model
                        trim
                        extColor
                        intColor
                        miles
                        tmu
                        exempt
                        eml
                        engine
                        transmission
                        style
                        drivetrain
                        titleStatus
                        title
                        titleNumber
                        spareKey
                    }
                }
            }
        }
    `;

    static GET_DEAL_CUSTOMER = gql`
        query getDealCustomer($accountNumber: Int!) {
            getDealCustomer(accountNumber: $accountNumber) {
                deal {
                    postedDate
                    stage
                }
                buyer {
                    customerCode
                    customerId
                    firstName
                    middleName
                    lastName
                    email
                    cellPhone
                    homePhone
                    workPhone
                    dob
                    ssn
                    dln
                    currentAddressId
                    currentEmploymentId
                    isBusiness
                    isTaxable
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                }
                coBuyer {
                    customerCode
                    customerId
                    firstName
                    middleName
                    lastName
                    email
                    cellPhone
                    homePhone
                    workPhone
                    dob
                    ssn
                    dln
                    currentAddressId
                    currentEmploymentId
                    isBusiness
                    isTaxable
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                }
                buyerAddress {
                    dealPreviousAddressId
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    isCurrentAddress
                    start
                    end
                }
                coBuyerAddress {
                    dealPreviousAddressId
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    isCurrentAddress
                    start
                    end
                }
                buyerEmployments {
                    dealPreviousEmploymentId
                    customerEmploymentId
                    employer
                    title
                    totalMonths
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    zipCode
                    county
                    country
                    status
                    start
                    isCurrentEmployment
                }
                coBuyerEmployments {
                    dealPreviousEmploymentId
                    customerEmploymentId
                    employer
                    title
                    totalMonths
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    zipCode
                    county
                    country
                    status
                    start
                    isCurrentEmployment
                }
            }
        }
    `;

    static GET_DEAL_WEOWE = gql`
        query getWeOweList($dealNumber: Int){
            getWeOweList(dealNumber: $dealNumber) {
                weOweId
                dealNumber
                quantity
                nameOfItem
                part
                labor
                imported
            }
        }
    `;

    static GET_DEAL_DEFERRED_DOWN_PAYMENT = gql`
        query getDeferredDownPaymentList($accountNumber: Int ){
            getDeferredDownPaymentList(accountNumber: $accountNumber) {
                deferredDownPaymentId
                paymentNumber
                paymentAmount
                paymentDate
            }
        }
    `;

    static GET_INSURANCE = gql`
        query getInsurance($dealId: Int! ){
            getInsurance(dealId: $dealId) {
                insuranceCompany
                insuranceAgent
                insuranceAddress
                insuranceCity
                insuranceState
                insuranceZip
                insurancePhone
                insurancePolicy
                insuranceDate
                insuranceDateFrom
                insuranceComp
                insuranceColl
            }
        }
    `;

    static GET_DOWNPAYMENT_RECEIVED = gql`
        query getDownPaymentReceived($dealId: Int! ){
            getDownPaymentReceived(dealId: $dealId)
        }
    `;

    static GET_CONTRACTS = gql`
        query getLoanContractTemplateList {
            getLoanContractTemplateList {
                loanContractTemplateId
                name
                state
                isDefault
              }
          }
      `;

    /**
    * @param {string} filter.searchTerm Search by: firstName, middleName , lastName, firstName + lastName, customerCode, cellPhone, homePhone, workPhone, email
    */
    static GET_CUSTOMERS = gql`
        query getCustomers($paginate: DataPaging!, $filter: CustomerFilter){
            getCustomers(paginate: $paginate, filter: $filter) {
                customers {
                    customerCode
                    customerId
                    firstName
                    middleName
                    lastName
                    cellPhone
                    workPhone
                    homePhone
                    email
                    dob
                    ssn
                    dln
                    isBusiness
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                    currentAddress {
                        customerAddressId
                        customerId
                        parentId
                        address1
                        address2
                        housingStatus
                        mortgageOrRent
                        start
                        end
                        totalMonths
                        phone
                        city
                        state
                        county
                        zipCode
                        country
                    }
                    currentEmployment {
                        customerId
                        customerEmploymentId
                        status
                        employer
                        title
                        monthlyIncome
                        workPhone
                        otherIncomeMonthly
                        otherIncomeSource
                        address1
                        address2
                        city
                        state
                        county
                        zipCode
                        country
                        totalMonths
                    }
                }
                totalCount
            }
        }
    `;

    static GET_CUSTOMERS_LIST = gql`
        query getCustomersList($paginate: DataPaging!, $filter: CustomerFilter){
            getCustomersList(paginate: $paginate, filter: $filter) {
                customers {
                    customerId
                    customerCode
                    firstName
                    lastName
                    middleName
                    cellPhone
                    homePhone
                    workPhone
                    email
                }
                totalCount
            }
        }
    `;

    static GET_PORTFOLIOS = gql`
        query listPortfolios {
            listPortfolios {
                portfolioId
                description
                name
                isDefault
            }
        }
    `;

    static GET_DEAL_PREVIEW = gql`
        query getDealPreview($input: DealPreviewInput!) {
        getDealPreview(input: $input) {
            dealStructure {
              price
              totalProductsPrice
              taxableAmount
              totalFees
              salesTax {
                taxExempt
                taxOverride
                taxAmount
                inventoryTaxAmount
                inventoryTaxOverride
              }
              cashDownPayment
              balanceDue
              finance {
                amountFinanced
                docStamp
                originalFinanceCharge
              }
              interestRate
              regZAPR
              maxRate
              buyRate
              reserve {
                amount
              }
              payment
              discount {
                amount
                override
              }
              term
              finalPaymentDue
              finalPaymentAmount
              minimumPaymentOverride
              paymentOverridePercent
              balanceDueOverride
              balanceDueOverrideAmount
              capCostReduction
              leasePaymentAmount
              leasePaymentTax
              grossCapitalizedCost
              cashDueAtSigning
              registerOutState
              backEndTax
              hasBalloonPayment
            }
            dealRecap {
              vehicleGross
              fees
              tradeEquity
              lenderDiscount
              vsc
              gap
              products
              reserve
              backEndGross
              frontEndGross
              totalCommissions
              totalDealProfit
              commissions {
                salesRep1Commission
                salesRep2Commission
                salesManagerCommission
                financeManagerCommission
                bdcAgentCommission
              }
            }
        }
    }`;

    static GET_COMMISSIONS = gql`
        query getDealCommissions($accountNumber: Int!) {
        getDealCommissions(accountNumber: $accountNumber) {
            salesManagerId
            salesManagerCommission
            salesManagerOverride
            salesManager {
              userId
              username
              firstName
              lastName
            }
            salespersonId1
            commissions1
            commission1Override
            salesPerson1 {
              userId
              username
              firstName
              lastName
            }
            salespersonId2
            commissions2
            commission2Override
            salesPerson2 {
              userId
              username
              firstName
              lastName
            }
            bdcAgentId
            bdcAgentCommission
            bdcAgentOverride
            bdcAgent {
              userId
              username
              firstName
              lastName
            }
            financeManagerId
            financeCommissions
            financeCommissionOverride
            financeManager {
              userId
              username
              firstName
              lastName
            }
        }
    }`;

    static CALCULATE_COMMISSIONS = gql`
        query calculateCommission($accountNumber: Int!, $commissionFields: CommissionsCalculationsInput){
            calculateCommission(accountNumber: $accountNumber, commissionFields: $commissionFields) {
              salesRep1Commission {
                id
                override
                amount
              }
              salesRep2Commission {
                id
                override
                amount
              }
              bdcAgentCommission {
                id
                override
                amount
              }
              salesManagerCommission {
                id
                override
                amount
              }
              financeManagerCommission {
                id
                override
                amount
              }
          }
      }`;

    static GET_ADDRESS_HISTORY = gql`
        query getCustomerAddressHistory($accountNumber: Int!, $customerId: ID!) {
            getCustomerAddressHistory(accountNumber: $accountNumber, customerId: $customerId) {
                customerAddressId
                address1
                address2
                state
                city
                county
                country
                zipCode
                phone
                housingStatus
                mortgageOrRent
                totalMonths
            }
        }
    `;

    static GET_EMPLOYMENT_HISTORY = gql`
        query getCustomerEmploymentHistory($accountNumber: Int!, $customerId: ID!) {
            getCustomerEmploymentHistory(accountNumber: $accountNumber, customerId: $customerId) {
                customerEmploymentId
                status
                employer
                title
                totalMonths
                address1
                address2
                state
                city
                county
                country
                zipCode
            }
        }
    `;

    static GET_STAGES_TRANSITION_LIST = gql`
        query {
            getStagesTransitionList {
                stageTransitionId
                stageFrom
                stageFromId
                stageTo
                stageToId
            }
        }
    `;

    static GET_VEHICLE_MILES = gql`
        query getVehiclebyStock($stockNumber: Int!){
          getVehicleByStock(stockNumber: $stockNumber){
            miles
          }
        }
    `;

    static GET_DEAL_REFERENCES = gql`
        query getDealReferenceList($accountNumber: Int!) {
            references: getDealReferenceList(accountNumber: $accountNumber) {
              dealReferenceId
              accountNumber
              firstName
              lastName
              phoneNo
              email
              relation
            }
        }
    `;

    static GET_REFERENCE = gql`
        query getDealReference($dealReferenceId: Int!) {
          reference: getDealReference(dealReferenceId: $dealReferenceId) {
              dealReferenceId
              accountNumber
              firstName
              lastName
              phoneNo
              relation
              address
              address2
              email
              zip
              city
              state
              county
              country
          }
        }
      `;

    static GET_PRODUCTS = gql`
        query getProducts($dealId: Int!) {
            products: getProducts(dealId: $dealId) {
                type
                price
                cost
                dealId
                term
                policyNumber
                dealProductId
                addToSellingPrice
                vendor {
                    vendorId
                    vendorName
                }
                product {
                    productId
                    productName
                }
            }
        }
    `;

    static GET_DEAL_RECAP = gql`
        query getDealRecap($dealId: Int!) {
            getDealRecap(dealId: $dealId) {
                purchasedPrice
                pack
                repairs
                estimatedFloorPlanCost
                weOwe
                vehicleCost
                vehicleGross
                fees
                tradeEquity
                lenderDiscount
                frontEndGross
                salesRep1
                salesRep2
                bdcAgent
                salesManager
                financeManager
                totalCommissions
                vsc
                gap
                products
                reserve
                backEndGross
                totalDealProfit
                commission1Override
                commission2Override
                salesManagerOverride
                bdcAgentOverride
                financeCommissionOverride
                salesPerson1Employee {
                    firstName
                    middleName
                    lastName
                }
                salesPerson2Employee {
                    firstName
                    middleName
                    lastName
                }
                financeManagerEmployee {
                    firstName
                    middleName
                    lastName
                }
                salesManagerEmployee {
                    firstName
                    middleName
                    lastName
                }
                bdcAgentEmployee {
                    firstName
                    middleName
                    lastName
                },
            }
        }
    `;

    static GET_DEAL_FROM_CRM = gql`
        query getDealFromCRM($paginate: DataPaging!, $filter: String, $sort: [DataSort], $lotName: String!){
            getDealFromCRM(paginate: $paginate, filter: $filter, sort: $sort, lotName: $lotName) {
                data {
                    leadCode
                    leadSource
                    tradeACV
                    tradeOffer
                    tradePayoff
                    dealType
                    availableCash
                    lotName
                    lotId
                    buyer {
                        customerCode
                        firstName
                        lastName
                        homePhone
                        cellPhone
                        workPhone
                        email
                    }
                    cobuyer {
                        customerCode
                        firstName
                        lastName
                        homePhone
                        cellPhone
                        workPhone
                        email
                    }
                    vehicle {
                        stockNumber
                        year
                        make
                        model
                        trim
                        vin
                        stickerPrice
                        minimumSalePrice
                    }
                    tradeIn {
                        style: bodyStyle
                        vin
                        year
                        make
                        model
                        trim
                        engine: engines
                        drivetrain
                        miles
                    }
                }
                totalCount
            }
        }
    `;

    static GET_DEAL_FROM_NEO = gql`
        query getDealFromNEO($init: Int, $limit: Int, $filter: String, $isNeo: Boolean!) {
            getDealFromNEO(init: $init, limit: $limit, filter: $filter, isNeo: $isNeo) {
                totalCount
                data {
                    modifiedOn
                    customerApplicationID
                    applicationSource
                    applicationId
                    deal {
                        stockNumber
                        salesPrice
                        deferredPayments
                        paymentFrequency
                        interestRate
                        term
                        downPayment
                        gap
                        warranty
                    }
                    buyer {
                        email
                        lastName
                        cellPhone
                        firstName
                        homePhone
                        workPhone
                        customerId
                        middleName
                    }
                    vehicle {
                        stockNumber
                        year
                        minimumSalePrice: stickerPrice
                        make
                        model
                    }
                }
            }
        }
    `;

    static DEAL_BY_STOCKNUMBER = gql`
        query getDealByStockNumber($stockNumber: Int!) {
            getDealByStockNumber(stockNumber: $stockNumber) {
                accountNumber
                soldDate
                status
                buyer {
                    customerCode
                    firstName
                    lastName
                }
            }
        }
    `;

    static GET_DEAL_NOTES_BY_DEAL = gql`
        query getDealNotesByDeal($dealId: Int!){
            getDealNotesByDeal(dealId: $dealId) {
                date
                note
                userName
            }
        }
    `;

    static GET_LEAD_SOURCE_LIST = gql`
        query getLeadSourceList {
            getLeadSourceList {
                leadSourceId
                leadSource
            }
        }
    `;

    static GET_DEALS_BY_CUSTOMER_ID = gql`
        query getDealsByCustomerId($customerId: ID!, $sort: DataSort, $paging: DataPaging) {
            getDealsByCustomerId(customerId: $customerId, sort: $sort, paging: $paging) {
                accountNumber
                soldDate
                status
                dealType
                vehicle {
                    stockNumber
                    year
                    make
                    model
                    trim
                    thumbnail
                    style
                }
            }
        }
    `;

    static GET_DEAL_SUMMARY = gql`
    query getDealSummary($dealId: Int!){
        getDealSummary(dealId: $dealId) {
            accountNumber
            stockNumber
            lotName
            status
            vehicle {
                stockNumber
                year
                make
                model
                trim
                extColor
                miles
                thumbnail
                style
            }
            buyer {
                customerCode
                firstName
                middleName
                lastName
            }
            coBuyer {
                customerCode
                firstName
                middleName
                lastName
            }
            salesPerson1 {
                userId
                firstName
                middleName
                lastName
                picture
            }
            salesPerson2 {
                userId
                firstName
                middleName
                lastName
                picture
            }
            financeManager {
                userId
                firstName
                middleName
                lastName
                picture
            }
            salesManager {
                userId
                firstName
                middleName
                lastName
                picture
            }
            bdcAgent {
                userId
                firstName
                middleName
                lastName
                picture
            },
        }
    }
    `;

    static GET_RFC_COMPANY_LIST = gql`
        query getRFCCompanyList {
            getRFCCompanyList {
                companyCode
                companyName
            }
        }
    `;

    static GET_DEALS_PENDING_LIST = gql`
        query getDealsPendingList {
            getDealsPendingList {
                buyer {
                    lastName
                    firstName
                },
                coBuyer {
                    lastName
                    firstName
                },
                vehicle {
                    make
                    trim
                    year
                    model
                    thumbnail
                    stockNumber
                },
                sendingUser {
                    firstName
                    lastName
                    picture
                },
                term
                status
                lotName
                dealType
                soldDate
                sendDate
                sourceDealer
                accountNumber
                financeAmount
                financeCompany
                firstPaymentDue
                dealTransferQueueId
            }
        }
    `;

    static PULL_AVAILABLE_DEAL_DOCUMENTS = gql`
        query pullAvailableDealDocuments($accountNumber: Int!) {
            pullAvailableDealDocuments(accountNumber: $accountNumber) {
                dealDocuments {
                    documentId
                    documentUrl
                    documentSize
                    referenceType
                    createdOn
                }
                inventoryDocuments {
                    documentId
                    documentUrl
                    documentSize
                    referenceType
                    createdOn
                }
            }
        }
    `;

    static GET_DEAL_DOCUMENT_UPLOAD_SIGNED_URL = gql`
        query getDealDocumentUploadSignedURL($accountNumber: Int!, $fileName: String!) {
            getDealDocumentUploadSignedURL(accountNumber: $accountNumber, fileName: $fileName) {
                url
                path
            }
        }
    `;

    static GET_DEAL_DOCUMENT_SECURE_URL = gql`
        query getDealDocumentSecureURL($path: String!) {
            getDealDocumentSecureURL(path: $path)
        }
    `;

    static PROCESS_NEO_APPLICATION = gql`
        query processNeoApplication {
            processNeoApplication
        }
    `;

static GET_DEAL_EDITING_STATUS = gql`
        query getDealEditingStatus($accountNumber: Int!) {
            getDealEditingStatus(accountNumber: $accountNumber) {
                isEditing
                editingById
                editingBy
                accountNumber
            }
        }
        `;

    static CALCULATE_DEAL_PAYMENT = gql`
        query calculateDealPayment(
            $salesPrice: Float!
            $otherAmount: Float!
            $tradeAllowance: Float!
            $tradePayoff: Float!
            $downPayment: Float!
            $paymentFrequency: String!
            $annualInterestRate: Float!
            $term: Int!
            $lotId: Int!
            $taxableFeesAmount: Float!
            $nonTaxableFeesAmount: Float!
        ) {
            calculateDealPayment(
                salesPrice: $salesPrice
                otherAmount: $otherAmount
                tradeAllowance: $tradeAllowance
                tradePayoff: $tradePayoff
                downPayment: $downPayment
                paymentFrequency: $paymentFrequency
                annualInterestRate: $annualInterestRate
                term: $term
                lotId: $lotId
                taxableFeesAmount: $taxableFeesAmount
                nonTaxableFeesAmount: $nonTaxableFeesAmount
            ) {
                payment
                financeAmount
                salesTax
            }
        }
    `;
}

import React from 'react';
import { useParams } from 'react-router-dom';

// Material
import { makeStyles } from '@material-ui/core/styles';

// Components and Others
import BackButton from 'components/widgets/BackButton';
import Header from 'components/widgets/Header';
import DealStyles from 'styles/modules/DealStyles';
import Container from 'components/widgets/Container';
import CustomerTabs from 'components/modules/customer/read/CustomerTabs';

const useStyles = makeStyles((theme) => DealStyles.details(theme));

const CustomerDetail = () => {
    const classes = useStyles();
    const { customerCode } = useParams();

    return (
        <Container className="floatBox">
            <Header>
                <div>
                    <BackButton route="/customers" />
                </div>
            </Header>
            <Container className={classes.boxContainer}>
                <CustomerTabs customerCode={customerCode} />
            </Container>
        </Container>
    );
};

export default CustomerDetail;

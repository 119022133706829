import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';

// Material UI
import {
    makeStyles,
    TextField,
    IconButton,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import { FetchPolicy } from 'utils/enum/Core';
import { Tabs, Tab } from 'react-bootstrap';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import TabContainer from 'components/widgets/tabs/TabContainer';
import EditIcon from '@material-ui/icons/Edit';

// HTTP
import { useQuery, useMutation } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import VehicleNoteStyle from 'styles/widgets/inventory/VehicleNoteStyle';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => VehicleNoteStyle.notePanel(theme));

const VehicleNote = (props) => {
    const { stockNumber } = props;
    const classes = useStyles();
    const keyStore = new KeyStore();
    const WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const [state, setState] = useState({
        openDialog: false,
        dialogLabel: '',
        selectedNote: '',
        notes: null,
        marketingNotes: null,
        marketCompsNotes: null,
        notesBackup: null,
    });

    const {
        openDialog,
        dialogLabel,
        selectedNote,
        notes,
        marketingNotes,
        marketCompsNotes,
        notesBackup,
    } = state;

    const [updateNote, { loading: saving }] = useMutation(InventoryMutation.UPDATE_VEHICLE);
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(InventoryQuery.PULL_NOTES, {
        variables: {
            stockNumber,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }

        if (!loading) {
            const response = data?.pullNotes || {};
            setState((prevState) => ({
                ...prevState,
                ...response,
                openDialog: false,
                dialogLabel: '',
                selectedNote: '',
                notesBackup: response,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onEditNote = (label, selected) => {
        setState((prevState) => ({
            ...prevState,
            openDialog: !openDialog,
            dialogLabel: label || '',
            selectedNote: selected || '',
            ...(!selected ? {
                [selectedNote]: notesBackup[selectedNote],
            } : {}),
        }));
    };
    const onSave = async () => {
        if (WRITE) {
            try {
                const newNote = trim(state[selectedNote]);
                const variables = {
                    stockNumber,
                    input: {
                        [selectedNote]: newNote,
                    },
                };

                const response = await updateNote({ variables });
                if (response?.data?.updateVehicle) {
                    refetch();

                    ModalUtils.successMessage(null, 'Notes updated successfully!');
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Notes'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Notes'));
            }
        }
    };

    return (
        <>
            <div className={classes.main}>
                <TabContainer>
                    <Tabs
                        className={clsx(classes.borderContainer, classes.navbar)}
                    >
                        <Tab className={classes.tab} eventKey={0} title="Notes">
                            {WRITE && (
                                <IconButton
                                    className={classes.editButton}
                                    size="small"
                                    onClick={() => onEditNote('Edit Notes', 'notes')}
                                >
                                    <EditIcon fontSize="inherit" />
                                    Edit
                                </IconButton>
                            )}
                            <TextField
                                placeholder="Notes"
                                multiline
                                rows="4"
                                value={notes || ''}
                                margin="normal"
                                variant="outlined"
                                disabled
                            />
                        </Tab>
                        <Tab className={classes.tab} eventKey={1} title="MarketComps">
                            {WRITE && (
                                <IconButton
                                    className={classes.editButton}
                                    size="small"
                                    onClick={() => onEditNote('Edit MarketComps Notes', 'marketCompsNotes')}
                                >
                                    <EditIcon fontSize="inherit" />
                                    Edit
                                </IconButton>
                            )}
                            <TextField
                                placeholder="Notes"
                                multiline
                                rows="4"
                                value={marketCompsNotes || ''}
                                margin="normal"
                                variant="outlined"
                                disabled
                            />
                        </Tab>
                        <Tab className={classes.tab} eventKey={2} title="Marketing">
                            {WRITE && (
                                <IconButton
                                    className={classes.editButton}
                                    size="small"
                                    onClick={() => onEditNote('Edit Marketing Notes', 'marketingNotes')}
                                >
                                    <EditIcon fontSize="inherit" />
                                    Edit
                                </IconButton>
                            )}
                            <TextField
                                placeholder="Notes"
                                multiline
                                rows="4"
                                value={marketingNotes || ''}
                                margin="normal"
                                variant="outlined"
                                disabled
                            />
                        </Tab>
                    </Tabs>
                </TabContainer>
            </div>
            <Dialog
                open={openDialog}
                maxWidth="sm"
                fullWidth
                className={classes.dialog}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title={dialogLabel}
                    onClose={() => onEditNote()}
                />
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        label="Notes"
                        multiline
                        rows="4"
                        margin="normal"
                        variant="outlined"
                        value={StringUtils.isEmpty(state[selectedNote]) ? '' : state[selectedNote]}
                        className={classes.textField}
                        onChange={(event) => onChange(selectedNote, event.target.value)}
                    />
                </DialogContent>
                <DialogActions
                    titlePrimary="Save"
                    onClickPrimary={onSave}
                    disableSecondaryButton={saving}
                    disablePrimaryButton={saving}
                    titleSecondary="Cancel"
                    onClickSecondary={() => onEditNote()}
                    variant="contained"
                />
            </Dialog>
        </>
    );
};

VehicleNote.propTypes = {
    stockNumber: PropTypes.number.isRequired,
};

export default VehicleNote;

export default class GalleryStyle {
    static gallery() {
        return ({
            imageContain: {
                position: 'absolute',
                top: 0,
                left: 0,
                margin: 0,
                zIndex: 0,
                height: '100%',
                width: '100%',
                border: 'none',
                borderRadius: 0,
            },
            container: {
                position: 'relative',
                visibility: 'hidden',
            },
            containerVisible: {
                visibility: 'visible',
            },
            iconContainer: {
                width: '100%',
                height: '100%',
            },
            iconButton: {
                color: '#FFF',
                fontSize: '3.5rem',
                zIndex: 1,
            },
            loader: {
                position: 'absolute',
                opacity: '0.5',
                width: '98%',
                height: '96%',
                marginTop: '0px',
            },
        });
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import ShortMessageService from 'components/widgets/sms/ShortMessageService';
import { makeStyles } from '@material-ui/core';
import MessageStyles from 'styles/widgets/MessageStyles';

const useStyles = makeStyles((theme) => MessageStyles.message(theme));

const Message = ({ records }) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            {records.map((item, index) => <ShortMessageService record={item} key={index} />)}
        </div>
    );
};

Message.propTypes = {
    records: PropTypes.array.isRequired,
};

export default Message;

import GraphQLClient from 'services/apollo/GraphQLClient';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';

export default class CompanyService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    async getCompany() {
        return this.graphqlClient
            .query(CompanyQuery.GET_COMPANY)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getCompany } = data;

                return {
                    data: { company: getCompany },
                };
            });
    }

    async updateCompany(input) {
        return this.graphqlClient
            .mutate(CompanyMutation.UPDATE_COMPANY, input);
    }

    async addCompanyLogo(input) {
        return this.graphqlClient
            .mutate(CompanyMutation.ADD_COMPANY_LOGO, input);
    }

    async removeCompanyLogo() {
        return this.graphqlClient
            .mutate(CompanyMutation.REMOVE_COMPANY_LOGO);
    }

    async getCompanyCarfax() {
        return this.graphqlClient
            .query(CompanyQuery.GET_COMPANY_CARFAX)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }
                const { getCompanyCarfax } = data;

                return {
                    data: {
                        isCarfaxEnabled: getCompanyCarfax?.value?.toLowerCase() === 'true',
                    },
                };
            });
    }

    async updateCompanyCarfax(input) {
        return this.graphqlClient
            .mutate(CompanyMutation.UPDATE_COMPANY_CARFAX, input);
    }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer } from 'react';

import clsx from 'clsx';
import {
    makeStyles, Grid,
    Button, TextField,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import InputNumber from 'components/widgets/InputNumber';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'components/widgets/Select';
import ButtonStyles from 'styles/theme/Button';
import Loading from 'components/widgets/Loading';
import Permissions from 'utils/enum/Permissions';
import Checkbox from '@material-ui/core/Checkbox';
import If from 'components/widgets/conditional/If';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import { useMutation, useQuery } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useRecordManager from 'components/hook/crm/useRecordManagers';
import LeadDistributionUserExceptionQuery from 'services/graphQL/query/setting/LeadDistributionUserExceptionQuery';
import LeadDistributionUserExceptionMutation from 'services/graphQL/mutate/setting/LeadDistributionUserExceptionMutation';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

const useStyles = makeStyles((theme) => ({
    box: {
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
    },
    containerRadio: {
        padding: theme.spacing(1, 0),
    },
    body: {
        padding: theme.spacing(1, 2),
        height: '440px',
        marginBottom: theme.spacing(1),
    },
    container: {
        border: '1px solid #E5E5E5',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 2),
        height: '100%',
        overflow: 'auto',
    },
    title: {
        fontSize: '15px',
        padding: theme.spacing(1, 2, 0),
        marginBottom: theme.spacing(1),
    },
    rightSection: {
        width: '99%',
        height: '100%',
        marginLeft: '5px',
        padding: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginTop: '10px',
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 400,
            fontSize: '14px',
            alignItems: 'center',
            marginBottom: '15px',
            flexWrap: 'wrap',
            padding: 0,
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'initial',
            },
            '& > input': {
                width: '50px',
                marginLeft: '10px',
                marginRight: '10px',
            },
            '& > div.select-bootstrap': {
                marginLeft: '10px',
                marginRight: '10px',
                width: '200px',
            },
        },
    },
    autocomplete: {
        borderRadius: '3px',
        minHeight: '31px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: `1px solid ${theme.palette.border.ghost}`,
        marginLeft: '10px',
        marginRight: '10px',
        width: '250px',
        '& > div > div > div': {
            fontSize: '12px',
            lineHeight: 1,
            height: '23px',
        },
    },
    firstRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        '& > div:nth-child(1)': {
            width: '360px',
            flexShrink: 0,
            '& > div': {
                padding: 0,
            },
        },
        '& > div:nth-child(2)': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
            '& > div:nth-child(1)': {
                padding: 0,
                maxWidth: 'initial',
                flexGrow: 'initial',
                flexBasis: 'initial',
                width: '200px',
                marginRight: '20px',
                '& > div > div': {
                    width: '100px',
                },
            },
            '& > div:nth-child(2)': {
                padding: 0,
                maxWidth: 'initial',
                flexGrow: 'initial',
                flexBasis: 'initial',
                width: '100px',
                marginRight: '10px',
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    '& > label:nth-child(1)': {
                        marginRight: '5px',
                        marginLeft: 0,
                        '& > span': {
                            padding: 0,
                        },
                    },
                    '& > label:nth-child(2)': {
                        marginRight: 0,
                        marginLeft: 0,
                        '& > span': {
                            padding: 0,
                        },
                    },
                },
            },
        },
    },
    ...ButtonStyles.getStyle(theme),
}));

const automatedEmailLeadDistribution = {
    ACTIVATED: 'Activated',
    DEACTIVATED: 'Deactivated',
};

const emailLeadDistribution = {
    SCHEDULE: 'Schedule',
    ROUND_ROBIN: 'Round Robin',
};

const ACTION_TYPE = {
    SET_SETTINGS: 'setSettings',
    ON_CHANGE_LOT: 'onChangeLot',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_VALUE_SELECTED: 'onValueSelected',
    SET_LEAD_DISTRIBUTION: 'setLeadDistribution',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_LEAD_DISTRIBUTION:
        return update(state, {
            leadDistributionUser: { $set: action.payload },
            leadDistributionUserSelected: { $set: action.payload.map((item) => item.userId) },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.payload.field]: { $set: action.payload.value },
        });
    case ACTION_TYPE.ON_VALUE_SELECTED:
        const { payload } = action;

        if (state.leadDistributionUserSelected.includes(payload.value)) {
            const record = state.leadDistributionUserSelected.filter((item) => item !== payload.value);

            return update(state, {
                leadDistributionUserSelected: { $set: record },
            });
        }

        return update(state, {
            leadDistributionUserSelected: { $push: [payload.value] },
        });
    case ACTION_TYPE.SET_SETTINGS:
        const currentStatus = action.payload.find((item) => item.key === 'AutomatedEmailLeadDistribution')?.value;
        const currentEmailLeadDistribution = action.payload.find((item) => item.key === 'AutomatedEmailSchedule')?.value;

        return update(state, {
            status: { $set: currentStatus?.toLowerCase() === 'true' ? automatedEmailLeadDistribution.ACTIVATED : automatedEmailLeadDistribution.DEACTIVATED },
            emailLeadDistribution: {
                $set: currentEmailLeadDistribution?.toLowerCase() === emailLeadDistribution.ROUND_ROBIN.replace(' ', '').toLocaleLowerCase()
                    ? emailLeadDistribution.ROUND_ROBIN : emailLeadDistribution.SCHEDULE,
            },
        });
    case ACTION_TYPE.ON_CHANGE_LOT:
        return update(state, {
            lotId: { $set: action.payload },
            leadDistributionUserSelected: { $set: state.leadDistributionUser.map((item) => item.userId) },
            pushNotificationReminderInterval: { $set: 3 },
            latePushNotificationUsers: { $set: null },
            latePushNotificationDelay: { $set: 10 },
        });
    default:
        return state;
    }
};

const minStartTime = new Date(1900, 0, null, 9);
const maxStartTime = new Date(1900, 0, null, 21);
const EmailLeadDistribution = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const { userInformation = {} } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, {
        status: automatedEmailLeadDistribution.ACTIVATED,
        lotId: keyStore.getSelectedLot().lotId,
        emailLeadDistribution: emailLeadDistribution.SCHEDULE,
        leadDistributionUser: [],
        leadDistributionUserSelected: [],
        pushNotificationReminderInterval: 3,
        latePushNotificationUsers: null,
        latePushNotificationDelay: 10,
        startTime: minStartTime,
        endTime: maxStartTime,
        availableOnSaturday: true,
        availableOnSunday: true,
    });

    const {
        startTime,
        endTime,
        availableOnSaturday,
        availableOnSunday,
    } = state;

    const CRM_SETTINGS_WRITE = keyStore.hasPermission(Permissions.CRM_SETTINGS_WRITE);
    const listStatus = [automatedEmailLeadDistribution.ACTIVATED, automatedEmailLeadDistribution.DEACTIVATED];
    const {
        recordManagers,
        loading: loadingRecordManagers,
        error: errorListRecordManager,
    } = useRecordManager({ notIncludeEmptyValue: true, lotId: state.lotId });
    const availableLot = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));
    const {
        loading, data, error, refetch,
    } = useQuery(LeadDistributionUserExceptionQuery.GET_LEAD_DISTRIBUTION_USER, { fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const deactivated = state.status === automatedEmailLeadDistribution.DEACTIVATED;
    const [saveEmailLeadDistribution, { loading: emailLeadIsLoading }] = useMutation(LeadDistributionUserExceptionMutation.SAVE_EMAIL_LEAD_DISTRIBUTION);

    const {
        loading: loadingGetEmailLead, data: emailLead, error: errorEmailLead,
    } = useQuery(LotQuery.GET_EMAIL_LEAD_DISTRIBUTION_SETTINGS, { fetchPolicy: FetchPolicy.NETWORK_ONLY, variables: { lotId: state.lotId } });

    const {
        PUSH_NOTIFICATION_REMINDER_INTERVAL,
        LATE_PUSH_NOTIFICATION_USERS,
        LATE_PUSH_NOTIFICATION_DELAY,
        PUSH_NOTIFICATION_START_TIME,
        PUSH_NOTIFICATION_END_TIME,
        PUSH_NOTIFICATION_ON_SATURDAY,
        PUSH_NOTIFICATION_ON_SUNDAY,
    } = LotDefaultskey;

    const {
        data: pushAvailabilitySettings,
        loading: pushAvailabilitySettingsLoading,
        error: pushAvailabilitySettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: ALL_LOTS,
            key: [
                PUSH_NOTIFICATION_START_TIME,
                PUSH_NOTIFICATION_END_TIME,
                PUSH_NOTIFICATION_ON_SATURDAY,
                PUSH_NOTIFICATION_ON_SUNDAY,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: pushSettingsData,
        loading: pushSettingsLoading,
        error: pushSettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: availableLot.find((lot) => lot.value === state.lotId)?.label,
            key: [
                PUSH_NOTIFICATION_REMINDER_INTERVAL,
                LATE_PUSH_NOTIFICATION_USERS,
                LATE_PUSH_NOTIFICATION_DELAY,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: loadingRecordManagers || !state.lotId || availableLot.length === 0 || (recordManagers ?? []).length === 0,
    });

    const [saveSettings, { loading: isSavingSettings }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    // TODO: Remove this when subscription is ready
    useEffect(() => {
        refetch();
    }, [state.lotId]);

    useEffect(() => {
        if (pushAvailabilitySettingsError) {
            ModalUtils.errorMessage(pushAvailabilitySettingsError?.graphQLErrors);
            return;
        }

        if (!pushAvailabilitySettingsLoading && pushAvailabilitySettings) {
            const { getSettings } = pushAvailabilitySettings;

            (getSettings ?? []).forEach((setting) => {
                let field = null;
                switch (setting.key) {
                case LotDefaultskey.PUSH_NOTIFICATION_START_TIME:
                    field = 'startTime'; break;
                case LotDefaultskey.PUSH_NOTIFICATION_END_TIME:
                    field = 'endTime'; break;
                case LotDefaultskey.PUSH_NOTIFICATION_ON_SATURDAY:
                    field = 'availableOnSaturday'; break;
                case LotDefaultskey.PUSH_NOTIFICATION_ON_SUNDAY:
                    field = 'availableOnSunday'; break;
                default:
                    break;
                }

                dispatch({
                    type: ACTION_TYPE.ON_CHANGE_VALUE,
                    payload: {
                        field,
                        value: setting.key.includes('Time') ? new Date(setting.value) : setting.value === 'true',
                    },
                });
            });
        }
    }, [pushAvailabilitySettingsLoading, pushAvailabilitySettingsError]);

    useEffect(() => {
        if (pushSettingsError) {
            ModalUtils.errorMessage(pushSettingsError?.graphQLErrors);
            return;
        }

        if (!pushSettingsLoading && pushSettingsData) {
            const { getSettings } = pushSettingsData;

            (getSettings ?? []).forEach((setting) => {
                dispatch({
                    type: ACTION_TYPE.ON_CHANGE_VALUE,
                    payload: {
                        field: setting.key,
                        value: setting.key !== LATE_PUSH_NOTIFICATION_USERS
                            ? Number(setting.value)
                            : recordManagers.filter((manager) => (setting.value ?? '').split(',').map((userId) => Number(userId ?? 0)).includes(manager.value)),
                    },
                });
            });
        }
    }, [pushSettingsLoading, pushSettingsError]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getLeadDistributionUser) {
            const { getLeadDistributionUser } = data;
            dispatch({ type: ACTION_TYPE.SET_LEAD_DISTRIBUTION, payload: getLeadDistributionUser });
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (errorEmailLead) {
            ModalUtils.errorMessage(errorEmailLead?.graphQLErrors);
            return;
        }

        if (!loadingGetEmailLead && emailLead?.getEmailLeadDistributionSettings) {
            const { getEmailLeadDistributionSettings } = emailLead;
            dispatch({ type: ACTION_TYPE.SET_SETTINGS, payload: getEmailLeadDistributionSettings });
        }
    }, [loadingGetEmailLead, emailLead, errorEmailLead]);

    const savePushNotificationsSettings = () => {
        [
            {
                key: 'pushNotificationReminderInterval',
                value: state.pushNotificationReminderInterval,
            },
            {
                key: 'latePushNotificationUsers',
                value: state.latePushNotificationUsers,
            },
            {
                key: 'latePushNotificationDelay',
                value: state.latePushNotificationDelay,
            },
        ].forEach((setting) => {
            if (setting.key === 'latePushNotificationUsers') {
                // eslint-disable-next-line no-param-reassign
                setting.value = (state.latePushNotificationUsers ?? []).map((user) => user.value ?? user.id).join(',');
            }

            saveSettings({
                variables: {
                    category: LotsCategory.CRM,
                    key: setting.key,
                    value: String(setting.value),
                    lotName: availableLot.find((lot) => lot.value === state.lotId)?.label,
                    critical: false,
                },
            });
        });
    };

    const onSave = async () => {
        try {
            const input = recordManagers.map((item) => ({ userId: item.value, exclude: !state.leadDistributionUserSelected.includes(item.value) }));
            await saveEmailLeadDistribution({
                variables:
                    {
                        input,
                        lotId: state.lotId,
                        automatedEmailSchedule: state.emailLeadDistribution.replace(' ', ''),
                        active: state.status === automatedEmailLeadDistribution.ACTIVATED,
                    },
            });
        } catch (e) {
            ModalUtils.errorMessage(e?.graphQLErrors);
        }
    };

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field,
                value,
            },
        });
    };

    const onValueSelection = (value) => {
        dispatch({
            payload: value,
            type: ACTION_TYPE.ON_VALUE_SELECTED,
        });
    };

    const onChangeLot = (field, value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_LOT,
            payload: value,
        });
    };

    const onChangeTime = (name, value) => {
        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: LotDefaultskey.PUSH_NOTIFICATION_START_TIME,
                value: name === 'startTime' ? value.toISOString() : startTime.toISOString(),
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: LotDefaultskey.PUSH_NOTIFICATION_END_TIME,
                value: name === 'endTime' ? value.toISOString() : endTime.toISOString(),
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field: name,
                value,
            },
        });
    };

    const onChangeDayAvailability = (field, currentValue) => {
        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: field === 'availableOnSaturday' ? LotDefaultskey.PUSH_NOTIFICATION_ON_SATURDAY : LotDefaultskey.PUSH_NOTIFICATION_ON_SUNDAY,
                value: !currentValue === true ? 'true' : 'false',
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field,
                value: !currentValue,
            },
        });
    };

    return (
        <Grid container className="overflow-auto">
            <div className={classes.firstRow}>
                <div>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Lot</Form.Label>
                        <Select
                            size="sm"
                            name="lotId"
                            value={state.lotId}
                            options={availableLot}
                            onChange={onChangeLot}
                        />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Notification Availability</Form.Label>
                        <div>
                            <DatePicker
                                size="sm"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                minTime={minStartTime}
                                maxTime={endTime}
                                selected={startTime}
                                className={clsx('form-control form-control-sm')}
                                popperContainer={CalendarContainer}
                                onChange={(date) => onChangeTime('startTime', date)}
                            />
                            <DatePicker
                                size="sm"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                minTime={startTime}
                                maxTime={maxStartTime}
                                selected={endTime}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className={clsx('form-control form-control-sm')}
                                popperContainer={CalendarContainer}
                                onChange={(date) => onChangeTime('endTime', date)}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Weekend</Form.Label>
                        <div>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={availableOnSaturday}
                                        onClick={() => onChangeDayAvailability('availableOnSaturday', availableOnSaturday)}
                                    />
                                )}
                                label="Saturday"
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={availableOnSunday}
                                        onClick={() => onChangeDayAvailability('availableOnSunday', availableOnSunday)}
                                    />
                                )}
                                label="Sunday"
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
            <Grid item lg={3} xs={12} className={classes.box}>
                <Grid container>
                    <Grid item xs={12}>
                        {state.emailLeadDistribution !== emailLeadDistribution.SCHEDULE && (
                            <div className={clsx('d-flex-center', classes.containerRadio)}>
                                <Form.Group
                                    className="form-check form-check-inline mb-0"
                                >
                                    {listStatus.map((item, index) => (
                                        <Form.Check
                                            key={index}
                                            type="radio"
                                            name="radio"
                                            label={item}
                                            value={item}
                                            id={`radio${index}`}
                                            checked={item === state.status}
                                            onChange={(e) => onChangeValue('status', e.target.value)}
                                        />
                                    ))}
                                </Form.Group>
                            </div>
                        )}
                        <If condition={state.emailLeadDistribution === emailLeadDistribution.ROUND_ROBIN}>
                            <>
                                <div className={classes.title}>Round Robin</div>
                                <div className={classes.body}>
                                    <div className={classes.container}>
                                        {loadingRecordManagers ? <Loading /> : recordManagers.map((item, index) => (
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        checked={!state.leadDistributionUserSelected.includes(item.value)}
                                                        onClick={() => onValueSelection(item)}
                                                    />
                                                )}
                                                key={index}
                                                label={item.label}
                                                disabled={deactivated}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <If condition={CRM_SETTINGS_WRITE}>
                                    <Form.Group as={Col} className="d-flex-justify-end">
                                        <Button
                                            variant="contained"
                                            className={classes.containedSecondaryInfo}
                                            onClick={onSave}
                                            disabled={emailLeadIsLoading || !isEmpty(errorListRecordManager?.graphQLErrors) || isSavingSettings}
                                        >
                                            Save
                                        </Button>
                                    </Form.Group>
                                </If>
                            </>
                        </If>
                        <If condition={state.emailLeadDistribution === emailLeadDistribution.SCHEDULE}>
                            <div className={clsx(classes.body, 'd-flex-justify-center-align-center text-center')}>
                                The scheduled setting is not available on dmspro
                            </div>
                        </If>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={9} xs={12}>
                <div className={clsx(classes.box, classes.rightSection)}>
                    <div>
                        <span>If no reply Push Notification to Record Manager & Sales Rep every</span>
                        <InputNumber
                            size="sm"
                            min={1}
                            value={state.pushNotificationReminderInterval}
                            allowNegative={false}
                            decimalScale={0}
                            onChange={(value) => onChangeValue('pushNotificationReminderInterval', value)}
                        />
                        <span>minutes.</span>
                    </div>
                    <div>
                        <span>If no reply send one notification to</span>
                        <Autocomplete
                            multiple
                            freeSolo
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option.label}
                            options={recordManagers.map((item) => ({ label: item.label, id: item.value }))}
                            value={state.latePushNotificationUsers ?? []}
                            onChange={(_, value) => onChangeValue('latePushNotificationUsers', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                    variant="standard"
                                    size="small"
                                />
                            )}
                        />
                        <span>after</span>
                        <InputNumber
                            size="sm"
                            min={1}
                            value={state.latePushNotificationDelay}
                            allowNegative={false}
                            decimalScale={0}
                            onChange={(value) => onChangeValue('latePushNotificationDelay', value)}
                        />
                        <span>minutes.</span>
                    </div>
                    <Form.Group as={Col} className="d-flex-justify-end">
                        <Button
                            variant="contained"
                            className={classes.containedSecondaryInfo}
                            onClick={savePushNotificationsSettings}
                            disabled={emailLeadIsLoading || isSavingSettings}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </div>
            </Grid>
        </Grid>
    );
};
export default EmailLeadDistribution;

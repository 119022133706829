import * as yup from 'yup';

const {
    string, number, object, date,
} = yup;

const PurchaseOrderSchema = object().shape({
    shippingMethodId: number().required().moreThan(0, 'Shipping method is required'),
    vendorId: number().required().moreThan(0, 'Vendor id required'),
    partsClerk: string(),
    status: string().max(12),
    lotName: string().required('Lot is required'),
    salesTaxRate: number(),
    orderDate: date().nullable().required('Order date required'),
    deliveryDate: date().nullable().required('Delivery date required'),
    memo: string().max(
        250,
        'Memo has a maximum limit of 250 characters',
    ),
});

export default PurchaseOrderSchema;

export default class VehicleWidgetStyles {
    static style(theme = {}) {
        return ({
            root: {
                display: 'flex',
            },
            vehicleColumn: {
                maxWidth: 120,
                maxHeight: 76,
            },
            column: {
                color: '#382652',
                display: 'flex',
            },
            textBuyer: {
                color: theme.palette.text.waterloo,
                fontWeight: 700,
                fontFamily: 'Arial',
                fontSize: '14px',
            },
            imageStock: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: theme.spacing(1.2),
            },
            vehicleImageStock: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1),
            },
            numberBold: {
                fontWeight: 700,
                marginLeft: theme.spacing(0.5),
                color: theme.palette.text.waterloo,
                fontFamily: 'Arial',
                fontSize: 14,
            },
            containerUnderVehicle: {
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
            },
            numberBoldBlack: {
                fontWeight: 'bold',
                marginLeft: theme.spacing(1),
                color: '#767676',
            },
            titleStock: {
                color: theme.palette.text.scorpion,
                lineHeight: '14px',
                fontSize: 14,
                fontWeight: 400,
                fontFamily: 'Arial',
            },
            nameCustomer: {
                marginBottom: theme.spacing(1),
                fontWeight: 'bold',
            },
            lineHeight16: {
                lineHeight: `${theme.spacing(2.2)}px`,
                color: theme.palette.text.boulder,
                fontWeight: 500,
            },
            textVehicle: {
                fontFamily: 'Roboto',
                color: theme.palette.text.boulder,
                fontWeight: 400,
                lineHeight: 'initial',
            },
            textVehicleColor: {
                color: theme.palette.text.boulderGray,
            },
            stockNumberMargin: {
                marginLeft: 7,
            },
            image: {
                alignItems: 'center',
                maxWidth: 115,
                minWidth: 115,
                '& > img': {
                    border: `1px solid ${theme.palette.border.secondary}`,
                    maxHeight: 75,
                    height: 75,
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            vehicleImage: {
                alignItems: 'center',
                maxWidth: 120,
                minWidth: 120,
                '& > img': {
                    border: '2px solid #EFEFEF',
                    borderRadius: '2px',
                    maxHeight: 76,
                    height: 76,
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            vehicleInfo: {
                flexDirection: 'column',
                maxWidth: 250,
                minWidth: 250,

                '& > div:first-child': {
                    fontWeight: 'bold',
                    marginBottom: '6px',
                },
                '& > div:last-child span': {
                    color: '#7983AD',
                    fontWeight: 'bold',
                    marginLeft: 5,
                },
            },
            vehicleDetail: {
                flexDirection: 'column',
                maxWidth: 220,
                minWidth: 220,

                '& > div:first-child': {
                    fontWeight: 'bold',
                },
                '& > div:last-child span': {
                    fontWeight: 'bold',
                    marginLeft: 5,
                },
            },
            thirdRow: {
                display: 'flex',
                alignItems: 'center',
                height: 16,
            },
            cobuyerAlign: {
                display: 'flex',
                'flex-direction': 'row',
                height: 16,
            },
            iconSpace: {
                'margin-start': '3px',
                'margin-top': '-1px',
            },
            itemStyle: {
                color: '#767676',
                lineHeight: '24px',
            },
            itemStock: {
                color: '#767676',
                width: '100%',
                paddingLeft: '10px',
            },
            editMiles: {
                color: '#2748AC',
                '& > div:last-child span': {
                    color: '#2748AC',
                    fontWeight: '500',
                },
            },
            tooltip: {
                margin: 0,
            },
            iconContainer: {
                width: 14,
            },
        });
    }
}

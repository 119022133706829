import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils, { DateFormat } from 'lib/DateUtils';

// Material UI
import {
    Typography, Tooltip, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    name: {
        color: theme.palette.text.grayLabel,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
    },
    description: {
        color: theme.palette.text.grayLabel,
        fontSize: 14,
        fontFamily: 'Roboto',
        lineHeight: '20px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    containerUserInfo: {
        width: 130,
        minWidth: 130,
    },
    createdOn: {
        fontSize: 13,
    },
    item: {
        borderBottom: `1px solid ${theme.palette.border.gallery}`,
        borderTop: 0,
        padding: '8px 5px',
    },
    containerDescription: {
        flex: 1,
        marginLeft: theme.spacing(2),
    },
}));

const DealNoteItem = ({ record, index }) => {
    const classes = useStyles();

    return (
        <div key={index} className={clsx('d-flex-center-align-start', classes.item)}>
            <div className={classes.containerUserInfo}>
                <Tooltip title={record.userName} placement="left-start">
                    <Typography className={classes.name} noWrap>{record.userName}</Typography>
                </Tooltip>
                <Typography className={classes.createdOn}>{DateUtils.format(record.date, DateFormat.DATETIME_WITHOUT_SECONDS)}</Typography>
            </div>
            <div className={classes.containerDescription}>
                <Tooltip title={record.note} placement="top-start">
                    <Typography className={classes.description}>{record.note}</Typography>
                </Tooltip>
            </div>
        </div>
    );
};

DealNoteItem.propTypes = {
    record: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default DealNoteItem;

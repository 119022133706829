import { gql } from '@apollo/client';

export default class ProductsSubscription {
    static VENDOR_SETTINGS_LIST = gql`
        subscription vendorSettingsListChanged($searchTerm: String, $status: Boolean) {
            vendorSettingsListChanged(searchTerm: $searchTerm, status: $status) {
                type
                vendor: data {
                    vendorId
                    vendorName
                    vendorCode
                    vendorType
                    vendorAddress
                    vendorCity
                    vendorState
                    active
                }
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import DealService from 'services/modules/DealService';
import CustomerService from 'services/modules/CustomerService';

// Utilities
import KeyStore from 'utils/KeyStore';

const CRMCustomer = (WrappedComponent) => class extends Component {
    static propTypes = {
        onSelectRow: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.keyStore = new KeyStore();
        this.dealServices = new DealService();
        this.customerService = new CustomerService();

        this.state = {
            records: [],
            isLoading: false,
            totalCount: 0,
            // TODO: When filters are integrated, use this state variable for the sort
            sortBy: 'customerCode',
            selectedRecord: null,
        };
        this.initBind();
    }

    onSearch(searchTerm) {
        const { state: { previousSearchTerm } } = this;
        if (searchTerm && (searchTerm === previousSearchTerm)) return;

        this.setState({ searchTerm });

        if (!StringUtils.isEmpty(searchTerm)) {
            this.getServicesData(searchTerm, true);
        } else {
            this.clearList();
        }
    }

    onClickRecord(record) {
        this.setState({ selectedRecord: record });
    }

    onClickPrimary() {
        const { props: { onSelectRow }, state: { selectedRecord } } = this;

        if (selectedRecord) {
            this.customerService.getCustomerAndAddress({ customerCode: selectedRecord?.buyer?.customerCode })
                .then((response) => {
                    const { data, graphQLErrors } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);

                        return;
                    }
                    selectedRecord.buyer = data;
                    onSelectRow(selectedRecord);
                });
        }
    }

    getServicesData(searchTerm, isSearching = false) {
        const { records, sortBy } = this.state;
        let start = records.length;
        const selectedLot = this.keyStore.getSelectedLot();

        if (isSearching) {
            start = 0;
            this.setState({ records: [], totalCount: 0 });
        }

        const input = {
            paginate: {
                start,
                limit: 50,
            },
            sort: {
                field: sortBy,
                dir: 'DESC',
            },
            filter: searchTerm,
            lotName: selectedLot.lotName,
        };

        this.setState({ isLoading: true, previousSearchTerm: searchTerm });
        this.dealServices.getDealFromCRM(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getDealFromCRM) {
                    const { getDealFromCRM } = data;

                    this.setState((prevState) => ({
                        records: prevState.records.concat(getDealFromCRM.data),
                        totalCount: getDealFromCRM.totalCount,
                    }));
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    loadMore() {
        const { searchTerm } = this.state;
        this.getServicesData(searchTerm);
    }

    clearList() {
        const { state: { records } } = this;

        if (records.length > 0) {
            this.setState({
                records: [],
                totalCount: 0,
                previousSearchTerm: '',
            });
        }
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clearList = this.clearList.bind(this);
        this.onClickRecord = this.onClickRecord.bind(this);
        this.onClickPrimary = this.onClickPrimary.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSearch={this.onSearch}
                loadMore={this.loadMore}
                onClickRecord={this.onClickRecord}
                onClickPrimary={this.onClickPrimary}
            />
        );
    }
};

export default CRMCustomer;

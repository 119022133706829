import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import StringUtils from 'lib/StringUtils';
import DealStyles from 'styles/modules/DealStyles';
import { ReactComponent as DeleteIcon } from 'assets/remove.svg';
import InputNumber from 'components/widgets/InputNumber';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import WeOweContainer from 'components/containers/deals/create/dealTab/WeOweContainer';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { WeOweSchema, WeOweRowSchema } from 'utils/schema/deal/WeOwe';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    Grid, DialogContent,
    IconButton,
    Dialog,
} from '@material-ui/core';

const styles = (theme) => DealStyles.dealStyles(theme, fade);

// TODO: Change the quantity, part, labor fields when integrating the component to type of type number
class WeOweDialog extends Component {
    componentDidMount() {
        const { props: { getServicesData } } = this;
        getServicesData();
    }

    onChangeIntegerValue = (field, value, index) => {
        const { props: { onChangeValue } } = this;
        const currentValue = StringUtils.isEmpty(value) ? 0 : parseInt(value, 10);

        onChangeValue(field, currentValue, index);
    }

    renderRow() {
        const {
            props: {
                classes, records, onChangeValue, showConfirm, writePermission,
            },
        } = this;

        return map(records, (item, index) => {
            const {
                quantity, nameOfItem, part, labor, currentAccountNumber,
            } = item;
            const total = (quantity * part) + labor;

            const isValidData = isValidSchema(WeOweRowSchema, item);
            const { errors } = isValidData;

            return (
                <Grid
                    container
                    spacing={2}
                    className={clsx(classes.rowWeOwe, 'show-icon-when-hovering')}
                    key={`${currentAccountNumber}${index}`}
                >
                    <Grid item xs={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <InputNumber
                                    required
                                    value={quantity || ''}
                                    className={isValidField(errors, 'quantity') ? 'invalid-field' : ''}
                                    onChange={(value) => this.onChangeIntegerValue('quantity', value, index)}
                                    placeholder="0"
                                    decimalScale={0}
                                    thousandSeparator
                                    disabled={!writePermission}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Form.Control
                                    value={nameOfItem}
                                    className={isValidField(errors, 'nameOfItem') ? 'invalid-field' : ''}
                                    onChange={(e) => onChangeValue('nameOfItem', e.target.value, index)}
                                    type="text"
                                    placeholder=""
                                    disabled={!writePermission}
                                    size="sm"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <InputNumber
                                    value={part || ''}
                                    className={isValidField(errors, 'part') ? 'invalid-field' : ''}
                                    onChange={(value) => onChangeValue('part', value, index)}
                                    showCurrency
                                    thousandSeparator
                                    placeholder="0.00"
                                    disabled={!writePermission}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputNumber
                                    value={labor || ''}
                                    className={isValidField(errors, 'labor') ? 'invalid-field' : ''}
                                    onChange={(value) => onChangeValue('labor', value, index)}
                                    showCurrency
                                    thousandSeparator
                                    placeholder="0.00"
                                    disabled={!writePermission}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputNumber
                                    value={total || ''}
                                    placeholder="0.00"
                                    onChange={(value) => onChangeValue('total', value, index)}
                                    showCurrency
                                    thousandSeparator
                                    disabled
                                    size="sm"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {writePermission && (
                        <div className="with-icon-right">
                            <IconButton
                                aria-label="delete"
                                className={clsx(classes.textError, 'show-hidden-item')}
                                onClick={() => showConfirm(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                </Grid>
            );
        });
    }

    render() {
        const {
            props: {
                toggleModal, classes, addRow, open, onSave, allowSave,
                openConfirm, onDelete, cancelConfirm, isSaving, records,
                writePermission,
            },
        } = this;

        const isValidData = isValidSchema(WeOweSchema, records);
        const { isValid } = isValidData;

        return (
            <Dialog
                open={open}
                fullWidth
                className={classes.weOweDialogWidth}
                maxWidth="md"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="We Owe" onClose={toggleModal} />
                <DialogContent className={classes.dialogContentWeOwe}>
                    <Grid container spacing={2} className={classes.rowWeOwe}>
                        <Grid item xs={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Form.Label className={classes.weOweLabels}>QTY</Form.Label>
                                </Grid>
                                <Grid item xs={8}>
                                    <Form.Label>Description</Form.Label>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Form.Label className={classes.weOweLabels}>Part</Form.Label>
                                </Grid>
                                <Grid item xs={4}>
                                    <Form.Label className={classes.weOweLabels}>Labor</Form.Label>
                                </Grid>
                                <Grid item xs={4}>
                                    <Form.Label className={classes.weOweLabels}>Total</Form.Label>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.boxIconDelete} />
                    </Grid>
                    {this.renderRow()}
                    {writePermission && (
                        <div>
                            <IconButton aria-label="add" className={clsx(classes.textSuccess, classes.addWeOweIcon)} onClick={addRow}>
                                <AddCircleIcon />
                            </IconButton>
                        </div>
                    )}
                </DialogContent>
                {writePermission && (
                    <DialogActions
                        disablePrimaryButton={!allowSave || isSaving || !isValid}
                        titlePrimary="Save"
                        onClickSecondary={toggleModal}
                        onClickPrimary={() => {
                            onSave();
                        }}
                    />
                )}
                <ConfirmDialog
                    title="Confirm remove line"
                    description="Are you sure you want to remove this line?"
                    open={openConfirm}
                    showHeader
                    dividerFooter={false}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    onClickPrimary={onDelete}
                    onClose={cancelConfirm}
                    onClickSecondary={cancelConfirm}
                />
            </Dialog>
        );
    }
}

WeOweDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    allowSave: PropTypes.bool.isRequired,
    addRow: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    showConfirm: PropTypes.func.isRequired,
    cancelConfirm: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isSaving: PropTypes.bool.isRequired,
    writePermission: PropTypes.bool,
};

WeOweDialog.defaultProps = {
    writePermission: false,
};

export default withStyles(styles)(WeOweContainer(WeOweDialog));

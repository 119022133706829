import { gql } from '@apollo/client';

export default class Query {
    static PULL_AVAILABLE_GOALS = gql`
        {
            pullAvailableGoals {
                id
                category {
                    id
                    name
                }
                label
                type
                value
            }
        }
    `;

    static PULL_AVAILABLE_CATEGORIES = gql`
        {
            pullAvailableCategories {
                id
                name
            }
        }
    `;

    static PULL_AVAILABLE_RULES = gql`
        {
            pullAvailableRules {
                id
                category {
                    id
                    name
                }
                label
                query
                instructions
                assignedUsers {
                    id
                    userId
                }
            }
        }
    `;

    static PULL_ASSIGNED_USERS = gql`
        query pullAssignedUsers($id: String!, $type: String!) {
            pullAssignedUsers(id: $id, type: $type) {
                id
                userId
                scheduleReport
            }
        }
    `;

    static PULL_AVAILABLE_RULES_PER_USER = gql`
        {
            pullAvailableRulesPerUser {
                id
                category {
                    id
                    name
                }
                label
                instructions
                query
            }
        }
    `;

    static PULL_QUERY_RESULT_ENTITY = gql`
        query pullQueryResultOfEntity($id: String!, $type: String!, $paginate: DataPaging, $filters: [queryPullFilter!]) {
            pullQueryResultOfEntity(id: $id, type: $type, paginate: $paginate, filters: $filters) {
                entityId
                output {
                    total
                    records {
                        name
                        value
                    }
                }
                defaultDateValues {
                    column
                    defaultStartDate
                    defaultEndDate
                }
            }
        }
    `;

    static PULL_AVAILABLE_CHARTS = gql`
        {
            pullAvailableCharts {
                id
                category {
                    id
                    name
                }
                label
                query
                type
                options
                startOn
                frequency
                customReport
                assignedUsers {
                    id
                    userId
                }
            }
        }
    `;

    static PREVIEW_QUERY_RESULTS = gql`
        query previewQueryResults($statement: String!, $filters: [queryPullFilter!]) {
            previewQueryResults(statement: $statement, filters: $filters) {
                output {
                    records {
                        name
                        value
                    }
                    total
                }
                defaultDateValues {
                    column
                    defaultStartDate
                    defaultEndDate
                }
            }
        }
    `;

    static PULL_AVAILABLE_CHARTS_PER_USER = gql`
        {
            pullAvailableChartsPerUser {
                id
                category {
                    id
                    name
                }
                label
                query
                type
                options
                customReport
            }
        }
    `;

    static PULL_DASHBOARD_COMPONENTS_PER_USER = gql`
        {
            pullDashboardComponentsPerUser {
                id
                staticName
                chart {
                    id
                    category {
                        id
                        name
                    }
                    label
                    query
                    type
                    options 
                }
                order
                width
            }
        }
    `;
}

import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import useTechnicianJobs from 'components/modules/service/serviceInvoice/hooks/useTechnicianJobs';
import TechnicianJobForm from 'components/modules/service/serviceInvoice/create/TechnicianJobForm';

const ownStyle = makeStyles((theme) => (
    {
        root: {
            overflow: 'auto',
            width: '100%',
            height: 'calc(100vh - 110px)',
            backgroundColor: `rgba(${theme.palette.rgb.black}, 0.02)`,
        },
    }
));

const TechnicianJobList = () => {
    const classes = ownStyle();
    const {
        state,
        loading,
        loadMore,
        loadData,
    } = useTechnicianJobs();
    const { table } = state;
    const { records, totalCount } = table;
    const saving = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadData(), []);

    return (
        <>
            <InfiniteScroll
                className={classes.root}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={loading}
            >
                <Grid container>
                    {
                        records.map((item) => (
                            <Grid key={`technician-item-${item.serviceJobId}`} item md={12} lg={6}>
                                <TechnicianJobForm item={item} />
                            </Grid>
                        ))
                    }
                </Grid>
            </InfiniteScroll>
            <If condition={saving}>
                <DialogActionMessage message="saving..." />
            </If>
        </>
    );
};

export default TechnicianJobList;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect, { components } from 'react-select';
import useAssigneeByLots from 'components/hook/crm/useAssigneeByLots';

const useStyles = makeStyles((theme) => ({
    assignee: {
        width: '100%',
        marginLeft: theme.spacing(2),
        '& .css-yk16xz-control': {
            borderColor: 'transparent',
        },
    },
    option: {
        display: 'flex !important',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    image: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        marginRight: 8,
    },
}));

const SingleValue = ({ children, ...props }) => {
    const { selectProps } = props;
    const classes = useStyles();
    return (
        <components.SingleValue {...props} className="d-flex-center">
            <img
                src={selectProps?.value?.image}
                className={classes.image}
                alt=""
            />
            <div className="text-ellipsis">
                {children}
            </div>
        </components.SingleValue>
    );
};

SingleValue.propTypes = {
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

const Option = (props) => {
    const { data, children } = props;
    const classes = useStyles();
    return (
        <components.Option className={classes.option} {...props}>
            <img
                src={data?.image}
                className={classes.image}
                alt=""
            />
            <div>
                {children}
            </div>
        </components.Option>
    );
};

Option.propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

const Assignee = ({
    value, onChange, innerRef, className, lotId, ...props
}) => {
    const classes = useStyles();
    const [record, setRecord] = useState([]);
    const { assignee } = useAssigneeByLots({ notIncludeEmptyValue: true, lots: [lotId] });
    const selectedValue = record.find((item) => item.value === value);

    useEffect(() => {
        if (!isEmpty(assignee)) {
            setRecord(assignee);
        }
    }, [assignee]);

    return (
        <ReactSelect
            {...props}
            isClearable
            name="assignee"
            ref={innerRef}
            options={record}
            value={selectedValue}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                SingleValue,
                Option,
            }}
            placeholder="Select"
            onChange={onChange}
            className={clsx('hidden-indicator basic-multi-select', classes.assignee, className)}
        />
    );
};

Assignee.propTypes = {
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    value: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    lotId: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

Assignee.defaultProps = {
    value: null,
    innerRef: null,
    onChange: () => {},
    className: '',
    lotId: null,
};

export default Assignee;

import { gql } from '@apollo/client';

export default class ReportQuery {
  static GET_RECON_REPORT = gql`
    query getReconReport(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: ReportFilter
    ) {
      getReconReport(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          stockNumber
          make
          model
          trim
          vehicleLocation
          lotName
          details{
            assignee 
            category
            repairItem
            part
            partStatus
            estimate
            comment
          }
        }
      }
    }
  `;

  static GET_RECON_ASSIGNEE_USERS = gql`
  query getReconAssigneeUsers {
    getReconAssigneeUsers {
      userId
      userName
    }
  }
  `;

  static PRINT_RECON_REPORT = gql`
  query printReconReport(
    $paginate: PaginationInput
    $sort: [SortField]
    $filter: ReportFilter
  ) {
    printReconReport(paginate: $paginate, sort: $sort, filter: $filter)
  }`;

  static GET_APPRAISAL_HISTORY_REPORT = gql`
    query getAppraisalHistoryReport(
      $paginate: DataPaging!,
      $sort: DataSort!,
      $filters: [ColumnFilter],
      $startDate: Date!,
      $endDate: Date!
    ) {
      getAppraisalHistoryReport(paginate: $paginate, sort: $sort, filters: $filters, startDate: $startDate, endDate: $endDate) {
        totalCount
        data {
          appraisalListId
          customer {
            customerId
            firstName
            lastName
            cellPhone
            email
          }
          appraisalDate
          appraiser
          vin
          year
          make
          model
          trim
          odometer
          notes
          purchased
          wholesale
          images
          suggestedPrice
          marketPercentage
          repairs
          markUp
          appraisedValue
          listingPrice
          daysListed
          seller
          listingURL
        }
      }
    }
  `;

  static GET_DISTINCT_VALUES_BY_COLUMN_APPRAISAL_LIST = gql`
    query getDistinctValuesByColumnAppraisalList(
        $columnName: String!,
        $search: String,
        $paginate: DataPaging,
        $startDate: Date!,
        $endDate: Date!
        ) {
          getDistinctValuesByColumnAppraisalList(
            columnName: $columnName,
            search: $search,
            paginate: $paginate,
            startDate: $startDate,
            endDate: $endDate
            ) {
            values
            totalCount
        }
    }
  `;

  static GET_DISTINCT_VALUES_BY_COLUMN_NO_COUNT_APPRAISAL_LIST = gql`
    query getDistinctValuesByColumnAppraisalList(
        $columnName: String!,
        $search: String,
        $paginate: DataPaging,
        $startDate: Date!,
        $endDate: Date!
        ) {
          getDistinctValuesByColumnAppraisalList(
            columnName: $columnName,
            search: $search,
            paginate: $paginate,
            startDate: $startDate,
            endDate: $endDate
            ) {
            values
        }
    }
  `;

  static GET_SALES_TAX_REPORT = gql`
    query getSalesTaxReport(
        $lotId: Int!,
        $month: Int!,
        $year: Int!,
        $dealType: String!
        ) {
          getSalesTaxReport(
            lotId: $lotId,
            month: $month,
            year: $year,
            dealType: $dealType
            ) {
              grossSales
              exemptSales
              taxableAmount
              taxCollected
              deletedDealsAmount
              amount15a
              amount15b
              amount15c
              amount15d
        }
    }
  `;

  static GET_REPORT_LAST_RUN_DATE = gql`
  query getReportLastRunDate($lotId: Int!, $dealType: String!) {
        getReportLastRunDate(lotId: $lotId, dealType: $dealType)
  }
  `;

    static GET_INVENTORY_TAX_REPORT = gql`
        query getInventoryTaxReport($lotId: Int!, $reportDate: String!) {
            getInventoryTaxReport(lotId: $lotId, reportDate: $reportDate)
        }
    `;
}

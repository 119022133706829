import React, { useState } from 'react';

// Material UI and React Bootstrap
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import StringUtils from 'lib/StringUtils';

// HTTP
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import ActivityMutation from 'services/graphQL/mutate/ActivityMutation';

const useStyle = makeStyles((theme) => ({
    textArea: {
        margin: theme.spacing(1.5, 0),
        resize: 'none',
    },
    dialog: {
        width: 450,
    },
}));

const NoteForm = ({
    open, onClose, crmId,
}) => {
    const classes = useStyle();
    const [value, setValue] = useState();
    const [createNoteActivity, { loading }] = useMutation(ActivityMutation.CREATE_NOTE_ACTIVITY);

    const onSave = async () => {
        try {
            const newValue = value?.trim();
            const input = {
                note: newValue,
                processRecordId: crmId,
            };
            const response = await createNoteActivity({ variables: { input } });

            if (response?.data?.createNoteActivity) {
                onClose();
                ModalUtils.successMessage(null, 'Note created successfully!');
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Note'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Note'));
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Note" toolbarSize="sm" onClose={onClose} titleVariant="h5" />
            <DialogContent>
                <Form.Group id="notegroup">
                    <Box
                        display="flex"
                        justifyContent="end"
                    >
                        <Form.Label className={classes.noteLabel}>{`${value?.length ?? 0}/500`}</Form.Label>
                    </Box>
                    <Form.Control
                        rows={4}
                        value={value}
                        as="textarea"
                        maxLength={500}
                        placeholder="Notes"
                        className={classes.textArea}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Form.Group>
            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                onClickPrimary={onSave}
                disablePrimaryButton={StringUtils.isEmpty(value) || loading}
            />
        </Dialog>
    );
};

NoteForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
};

export default NoteForm;

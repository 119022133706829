import React, { Component } from 'react';

// Material
import { fade, withStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button, Grid,
} from '@material-ui/core';
import { Language } from 'utils/enum/Customer';
import { withRouter } from 'react-router-dom';
import Header from 'components/widgets/Header';
import Container from 'components/widgets/Container';
import CustomerStyles from 'styles/modules/CustomerStyles';
import CustomerCreateContainer from 'components/containers/customer/create/CustomerCreateContainer';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputSSN from 'components/widgets/form/InputSSN';
import PhoneInput from 'components/widgets/form/PhoneInput';
import Select from 'components/widgets/Select';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { CustomerSchema } from 'utils/schema/customer/Customer';

const styles = (theme) => CustomerStyles.details(theme, fade);

class CustomerCreate extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    render() {
        const {
            props: {
                classes,
                onCancel,
                onSave,
                firstName,
                middleName,
                lastName,
                dob,
                ssn,
                dln,
                email,
                cellPhone,
                workPhone,
                homePhone,
                onChange,
                isBusiness,
                isTaxable,
                language,
            },
            CUSTOMER_WRITE,
        } = this;
        const record = {
            firstName,
            lastName,
            dob,
            email,
            cellPhone,
            isBusiness,
        };
        const isValidData = isValidSchema(CustomerSchema, { ...record });
        const { isValid, errors } = isValidData;

        return (
            <Container className="floatBox">
                <Header>
                    <div>
                        {CUSTOMER_WRITE && (
                            <Button
                                className={clsx(classes.button, classes.containedSecondaryInfo)}
                                disabled={!isValid}
                                variant="contained"
                                onClick={onSave}
                            >
                                Save
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            className={classes.button}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </Header>
                <Container className={classes.boxContainer}>
                    <Grid container spacing={0} className={classes.contentContainer}>
                        <Grid item xs={12} className="am-form">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={clsx(classes.gridRow, classes.businessCheckBox)} container spacing={2}>
                                        <Grid item xs={2}>
                                            <Form.Check type="checkbox" id="businessDeals">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={isBusiness}
                                                    onChange={(e) => onChange('isBusiness', e.target.checked)}
                                                />
                                                <Form.Check.Label className={classes.businessCheckLabel}>Business</Form.Check.Label>
                                            </Form.Check>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Form.Check type="checkbox" id="isTaxableId">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={isTaxable}
                                                    onChange={(e) => onChange('isTaxable', e.target.checked)}
                                                />
                                                <Form.Check.Label className={classes.businessCheckLabel}>Is Taxable</Form.Check.Label>
                                            </Form.Check>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={isBusiness ? 12 : 4}>
                                            <Form.Label className="required">{isBusiness ? 'Business Name' : 'First Name'}</Form.Label>
                                            <Form.Control
                                                className={isValidField(errors, 'firstName') ? 'invalid-field' : ''}
                                                type="text"
                                                placeholder={isBusiness ? 'Business Name' : 'First Name'}
                                                value={firstName}
                                                onChange={(e) => onChange('firstName', e.target.value)}
                                                maxLength={70}
                                            />
                                        </Grid>
                                        {!isBusiness && (
                                            <>
                                                <Grid item xs={4}>
                                                    <Form.Label>Middle Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Middle Name"
                                                        value={middleName}
                                                        onChange={(e) => onChange('middleName', e.target.value)}
                                                        maxLength={70}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Form.Label className="required">Last Name</Form.Label>
                                                    <Form.Control
                                                        className={isValidField(errors, 'lastName') ? 'invalid-field' : ''}
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={lastName}
                                                        onChange={(e) => onChange('lastName', e.target.value)}
                                                        maxLength={70}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={4} className="full-width-date-picker">
                                            <Form.Label className={clsx(classes.datePicker, 'required')}>DOB</Form.Label>
                                            <DatePicker
                                                className={clsx('form-control', isValidField(errors, 'dob') ? 'invalid-field' : '')}
                                                selected={dob}
                                                onChange={(value) => onChange('dob', value)}
                                                placeholderText="mm/dd/yyyy"
                                                maxDate={new Date()}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Label>SSN</Form.Label>
                                            <InputSSN
                                                value={ssn}
                                                onChange={(value) => onChange('ssn', value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Label>DLN</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="DLN"
                                                value={dln}
                                                onChange={(e) => onChange('dln', e.target.value)}
                                                maxLength={20}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={4}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                className={isValidField(errors, 'email') ? 'invalid-field' : ''}
                                                type="text"
                                                placeholder="email@address.com"
                                                value={email}
                                                onChange={(e) => onChange('email', e.target.value)}
                                                maxLength={100}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Label>Language</Form.Label>
                                            <Select
                                                nowrap
                                                size="sm"
                                                name="language"
                                                loading={false}
                                                className={classes.input}
                                                onChange={(name, value) => onChange(name, value)}
                                                value={language || ''}
                                                options={Object.values(Language).map((l) => ({
                                                    value: l,
                                                    label: l,
                                                }))}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={4}>
                                            <Form.Label>Cell Phone</Form.Label>
                                            <PhoneInput
                                                value={cellPhone}
                                                onChange={(value) => onChange('cellPhone', value)}
                                                className={isValidField(errors, 'cellPhone') ? 'invalid-field' : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={4}>
                                            <Form.Label>Work Phone</Form.Label>
                                            <PhoneInput
                                                value={workPhone}
                                                onChange={(value) => onChange('workPhone', value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid className={classes.gridRow} container spacing={2}>
                                        <Grid item xs={4}>
                                            <Form.Label>Home Phone</Form.Label>
                                            <PhoneInput
                                                value={homePhone}
                                                onChange={(value) => onChange('homePhone', value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        );
    }
}

CustomerCreate.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.instanceOf(Date),
    ssn: PropTypes.string.isRequired,
    dln: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cellPhone: PropTypes.string.isRequired,
    workPhone: PropTypes.string.isRequired,
    homePhone: PropTypes.string.isRequired,
    isBusiness: PropTypes.bool.isRequired,
    isTaxable: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
};

CustomerCreate.defaultProps = {
    dob: null,
};

export default withStyles(styles)(withRouter(CustomerCreateContainer(CustomerCreate)));

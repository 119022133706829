import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import FeatureList from 'components/modules/inventory/read/panels/keyFeature/FeatureList';

const useStyles = makeStyles({
    noFeature: {
        textAlign: 'center',
        fontSize: 14,
    },
    featuresList: {
        display: 'grid',
        gridAutoFlow: 'dense',
        gridAutoRows: 'max-content',
        gridGap: 20,
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    },
});

const VehicleFeatures = ({
    features,
    allowAction,
    onChange,
    onSortEnd,
    disabled,
}) => {
    const classes = useStyles();

    if (features.length === 0) {
        return (
            <div className={classes.noFeature}>
                No feature has been added
            </div>
        );
    }

    return (
        <FeatureList
            features={features}
            allowAction={allowAction}
            onChange={onChange}
            onSortEnd={onSortEnd}
            disabled={disabled}
        />
    );
};

VehicleFeatures.propTypes = {
    features: PropTypes.array,
    allowAction: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
};

VehicleFeatures.defaultProps = {
    features: [],
    allowAction: false,
    disabled: false,
};

export default VehicleFeatures;

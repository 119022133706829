import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StringUtils from 'lib/StringUtils';

import { Form, Col } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router-dom';

import {
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    withStyles,
} from '@material-ui/core';

// Internal UI components
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

// Container
import NeoDialogContainer from 'components/modules/settings/underwriting/NeoDialogContainer';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    button: {
        marginTop: 8,
        minWidth: 300,
    },
    buttonPrimaryLoading: {
        marginTop: 8,
        minWidth: 300,
        '&:disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8,
            color: '#FFF',
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        overflowX: 'hidden',
        display: 'flex',
        padding: theme.spacing(0.5),
    },
    inputStyle: {
        marginBottom: 8,
        minWidth: 300,
    },
    loadingCircle: {
        alignSelf: 'center',
    },
    formGroup: {
        width: 'min-content',
    },
});

class NeoDialogForm extends PureComponent {
    renderStatusToFetch() {
        const {
            props: { statusesToFetch },
            props,
        } = this;
        const itemStyle = {
            display: 'inline-block',
            width: '33%',
        };

        return (
            <Form.Group>
                <fieldset>
                    <legend>
                        Statuses to fetch
                    </legend>
                    {statusesToFetch.map((statusItem, index) => (
                        <div style={itemStyle} key={index}>
                            <Checkbox
                                checked={statusItem.active}
                                color="primary"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                                onChange={(event) => {
                                    const value = event.target.checked;
                                    props.onChangeStatuses(value, index);
                                }}
                            />
                            <span>{statusItem.statusLabel}</span>
                        </div>
                    ))}
                </fieldset>
            </Form.Group>
        );
    }

    render() {
        const { props } = this;
        const {
            classes,
            isEditing,
            record,
            validatingToken,
            loading,
            isValidToken,
            token,
            saving,
            active,
        } = props;

        const invalidToken = StringUtils.isEmpty(token) || StringUtils.isEmpty(record);
        const inputStyle = { marginBottom: ' 2px' };

        return (
            <Dialog open maxWidth="lg">
                <DialogAppBar
                    iconSize="sm"
                    onClose={props.onClose}
                    title={`${isEditing ? 'Edit' : 'New'} Integration`}
                />
                <DialogContent className={classes.dialogContent}>
                    {!loading && (
                        <div className={classes.body}>
                            <Form.Group as={Col} className={classes.formGroup}>
                                <FormControl
                                    margin="dense"
                                    variant="outlined"
                                    className={classes.formControl}
                                    style={inputStyle}
                                >
                                    <InputLabel
                                        ref={(ref) => {
                                            this.InputLabelRef = ref;
                                        }}
                                        htmlFor="vendor"
                                        shrink
                                    >
                                        Vendor *
                                    </InputLabel>
                                    <Select
                                        value={record}
                                        disabled
                                        input={(
                                            <OutlinedInput
                                                name="State"
                                                labelWidth={50}
                                                notched
                                                id="vendor"
                                            />
                                        )}
                                        className={classes.inputStyle}
                                    >
                                        <MenuItem value={record}>
                                            {record.name}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="password"
                                    label="Token *"
                                    value={token}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        props.onChange(value, 'token');
                                    }}
                                    onFocus={(event) => event.target.select()}
                                    margin="dense"
                                    variant="outlined"
                                    style={inputStyle}
                                    className={classes.inputStyle}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={invalidToken || validatingToken}
                                    className={
                                        validatingToken
                                            ? classes.buttonPrimaryLoading
                                            : classes.button
                                    }
                                    onClick={() => {
                                        props.onValidateToken(token);
                                    }}
                                >
                                    {validatingToken ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={24}
                                        />
                                    ) : null}
                                    Test Credentials
                                </Button>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>Active</span>
                                    <Checkbox
                                        checked={active}
                                        onChange={(event) => {
                                            const value = event.target.checked;
                                            props.onChange(value, 'active');
                                        }}
                                        color="primary"
                                        inputProps={{
                                            'aria-label': 'secondary checkbox',
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            {this.renderStatusToFetch()}
                        </div>
                    )}
                    {loading && (
                        <div className={classes.loadingCircle}>
                            <CircularProgress />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!isValidToken || invalidToken || saving}
                        className={saving ? classes.buttonPrimaryLoading : ''}
                        onClick={() => {
                            if (isEditing) {
                                props.onUpdate();
                            } else {
                                props.onSave();
                            }
                        }}
                    >
                        {saving ? (
                            <CircularProgress color="inherit" size={24} />
                        ) : null}
                        {isEditing ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

NeoDialogForm.propTypes = {
    record: PropTypes.object,
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isValidToken: PropTypes.bool.isRequired,
    onValidateToken: PropTypes.func.isRequired,
    validatingToken: PropTypes.bool.isRequired,
    statusesToFetch: PropTypes.array,
};

NeoDialogForm.defaultProps = {
    record: null,
    statusesToFetch: [],
};

export default withRouter(withStyles(styles)(NeoDialogContainer(NeoDialogForm)));

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Grid,
    makeStyles,
    useMediaQuery,
    Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap';
import DateUtils from 'lib/DateUtils';
import DatePicker from 'react-datepicker';
import InputNumber from 'components/widgets/InputNumber';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        background: theme.palette.background.alabaster,
        marginBottom: 20,
        filter: 'brightness(98%)',
        '& > h5': {
            marginBottom: '15px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    gridContainer: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
        },
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        justifyContent: 'flex-end',
        '& > label': {
            marginRight: '10px',
            fontWeight: 'bold',
            fontSize: '14px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: 1,
            letterSpacing: '-0.05px',
            width: '106px',
        },
    },
    shortField: {
        width: '100px',
    },
}));

const LienHolderForm = ({
    data,
    onChange,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <Typography variant="h5">Lien Holder Information</Typography>
            <Grid
                container
                orientation="column"
                className={classes.gridContainer}
            >
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                maxLength={100}
                                type="text"
                                value={data.name ?? ''}
                                onChange={(e) => onChange('name', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Address:</Form.Label>
                            <Form.Control
                                maxLength={50}
                                type="text"
                                value={data.address ?? ''}
                                onChange={(e) => onChange('address', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Zip:</Form.Label>
                            <InputNumber
                                className={classes.shortField}
                                value={data.zip}
                                onChange={(value) => onChange('zip', (value || '').toString(), 'lienHolderInfo')}
                                decimalScale={0}
                                max={99999}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>City:</Form.Label>
                            <Form.Control
                                maxLength={35}
                                type="text"
                                value={data.city ?? ''}
                                onChange={(e) => onChange('city', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>County:</Form.Label>
                            <Form.Control
                                maxLength={50}
                                type="text"
                                value={data.county ?? ''}
                                onChange={(e) => onChange('county', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>State:</Form.Label>
                            <Form.Control
                                className={classes.shortField}
                                maxLength={5}
                                type="text"
                                value={data.state ?? ''}
                                onChange={(e) => onChange('state', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Phone:</Form.Label>
                            <Form.Control
                                maxLength={12}
                                type="text"
                                value={data.phone ?? ''}
                                onChange={(e) => onChange('phone', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Note:</Form.Label>
                            <Form.Control
                                type="text"
                                value={data.notes ?? ''}
                                onChange={(e) => onChange('notes', e.target.value, 'lienHolderInfo')}
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Pay Off:</Form.Label>
                            <InputNumber
                                className={classes.shortField}
                                value={data.payOff ?? 0}
                                onChange={(value) => onChange('payOff', parseFloat(value), 'lienHolderInfo')}
                                showCurrency
                                thousandSeparator
                                placeholder="0.00"
                                size="sm"
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={4} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <Form.Group className={classes.field}>
                            <Form.Label>Pay Off good till:</Form.Label>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={DateUtils.toLocal(data.payOffGoodTill)?.toDate()}
                                placeholderText="mm/dd/yyyy"
                                onChange={(date) => onChange('payOffGoodTill', date, 'lienHolderInfo')}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

LienHolderForm.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

LienHolderForm.defaultProps = {
    data: {},
};

export default LienHolderForm;

import { isEmpty, isFinite } from 'lodash';

class ReferenceMap {
    static mapReferenceToSave(record, dealId) {
        if (!isEmpty(record) && isFinite(dealId)) {
            return {
                accountNumber: dealId,
                firstName: record.firstName,
                lastName: record.lastName,
                phoneNo: record.phoneNo,
                relation: record.relation,
                address: record.address,
                address2: record.address2,
                email: record.email,
                zip: record.zip,
                city: record.city,
                state: record.state,
                county: record.county,
                country: record.country,
            };
        }

        return {};
    }

    static mapReferenceToUpdate(record, referenceId) {
        if (!isEmpty(record) && isFinite(referenceId)) {
            return {
                dealReferenceId: referenceId,
                input: {
                    firstName: record.firstName,
                    lastName: record.lastName,
                    phoneNo: record.phoneNo,
                    relation: record.relation,
                    address: record.address,
                    address2: record.address2,
                    email: record.email,
                    zip: record.zip,
                    city: record.city,
                    state: record.state,
                    county: record.county,
                    country: record.country,
                },
            };
        }

        return {};
    }
}

export default ReferenceMap;

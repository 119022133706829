import React from 'react';

// Material UI
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { AccountingCBType } from 'utils/enum/AccountingEnum';

const useStyles = makeStyles(() => ({
    dropdown: {
        width: '180px',
    },
    dropdownFull: {
        width: '100%',
    },
}));

const TypeFilter = ({
    name, value, onChange,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const accountingCBTypes = [
        { label: AccountingCBType.ACH_CHECK_CUSTOMER, value: AccountingCBType.ACH_CHECK_CUSTOMER },
        { label: AccountingCBType.ACH_CHECK_EMPLOYEE, value: AccountingCBType.ACH_CHECK_EMPLOYEE },
        { label: AccountingCBType.ACH_CHECK_TRANSFER, value: AccountingCBType.ACH_CHECK_TRANSFER },
        { label: AccountingCBType.ACH_CHECK_VENDOR, value: AccountingCBType.ACH_CHECK_VENDOR },
        { label: AccountingCBType.ACH_DEPOSIT, value: AccountingCBType.ACH_DEPOSIT },
        { label: AccountingCBType.ACH_DEPOSIT_TRANSFER, value: AccountingCBType.ACH_DEPOSIT_TRANSFER },
        { label: AccountingCBType.ACH_PAYROLL_CHECK_EMPLOYEE, value: AccountingCBType.ACH_PAYROLL_CHECK_EMPLOYEE },
        { label: AccountingCBType.BILL_PAYMENT_ACH_CHECK_VENDOR, value: AccountingCBType.BILL_PAYMENT_ACH_CHECK_VENDOR },
        { label: AccountingCBType.BILL_PAYMENT_CHECK_VENDOR, value: AccountingCBType.BILL_PAYMENT_CHECK_VENDOR },
        { label: AccountingCBType.CHECK_CUSTOMER, value: AccountingCBType.CHECK_CUSTOMER },
        { label: AccountingCBType.CHECK_EMPLOYEE, value: AccountingCBType.CHECK_EMPLOYEE },
        { label: AccountingCBType.CHECK_VENDOR, value: AccountingCBType.CHECK_VENDOR },
        { label: AccountingCBType.DEPOSIT, value: AccountingCBType.DEPOSIT },
        { label: AccountingCBType.PAYROLL_CHECK_EMPLOYEE, value: AccountingCBType.PAYROLL_CHECK_EMPLOYEE },
    ];

    return (
        <DropdownQuery
            name={name}
            value={value}
            placeholder="Type"
            className={clsx('form-control-xs', isMobile ? classes.dropdownFull : classes.dropdown)}
            onChange={(fieldName, newValue) => onChange(fieldName, newValue === 0 ? '' : newValue)}
            dataSource={{
                localData: accountingCBTypes,
                idField: 'value',
                descriptionField: 'label',
            }}
            defaultEmptyLineText="All"
        />
    );
};

TypeFilter.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TypeFilter;

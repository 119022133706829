/* eslint-disable no-unused-expressions */
import React, {
    useReducer, useRef, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip, makeStyles, Dialog, DialogContent,
    AppBar, Toolbar, IconButton, Typography, useTheme,
    useMediaQuery, Button as ButtonMaterial,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Split from 'react-split';
import { SaveIcon, CloseIcon } from 'components/icons';
import {
    Col, Form, InputGroup, Button,
} from 'react-bootstrap';
import { isValidField, isValidSchema, getErrorMessage } from 'utils/schema/utils';
import PartsTicketSchema from 'utils/schema/parts/PartsTicketSchema';
import { useLazyQuery, useMutation } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import PartsMapper from 'services/mapData/PartsMapper';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import PartsTicketReducer, { ACTION_TYPES } from 'components/modules/purchaseParts/reducer/PartsTicketReducer';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { FetchPolicy } from 'utils/enum/Core';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import { PartsPurchaseSubModules, PartsTicketSalesType, PartsTicketStatus } from 'utils/enum/PartsPurchaseEnum';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import InputNumber from 'components/widgets/InputNumber';
import UserQuery from 'services/graphQL/query/UserQuery';
import PartsTicketDetail from 'components/modules/purchaseParts/partsTicket/create/PartsTicketDetail';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import usePartsTicketValidateActions from 'components/modules/purchaseParts/hooks/usePartsTicketValidateActions';
import { EmployeeType } from 'utils/enum/UserEnum';
import LabelMemorize from 'components/widgets/form/LabelMemorize';
import If from 'components/widgets/conditional/If';
import CustomerForServiceList from 'components/modules/service/serviceInvoice/create/CustomerForServiceList';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import CreateCustomer from 'components/modules/deals/create/dealTab/CreateCustomer';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';
import { isEmpty } from 'lodash';
import BasicStyles from 'styles/modules/basic/basicStyles';
import clsx from 'clsx';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const ownStyle = makeStyles((theme) => ({
    dialogContent: {
        height: 600,
        padding: theme.spacing(1, 1.8),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    toolBarColor: {
        backgroundColor: theme.palette.background.default,
    },
    paddingLeftRigth20: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
}));

const keyStore = new KeyStore();

const salesTypesData = [{ type: PartsTicketSalesType.RETAIL }, { type: PartsTicketSalesType.WHOLESALE }, { type: PartsTicketSalesType.WEOWE }];

const PartsTicketCreate = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id } = useParams();
    const history = useHistory();

    const PARTS_TICKET_WRITE_EDIT = keyStore.hasPermission(Permission.PARTS_TICKET_WRITE_EDIT);

    const initialState = {
        record: {
            ticketNumber: 0,
            customerCode: 0,
            cellPhone: '',
            email: '',
            customerName: '',
            customerPhone: '',
            customerEmail: '',
            customerAddress: '',
            lotId: 0,
            lotName: '',
            status: PartsTicketStatus.NEW,
            isTaxable: false,
            salesType: PartsTicketSalesType.RETAIL,
            counterPerson: '',
            discountPercent: 0,
            ticketOpen: new Date(),
            ticketClose: null,
            quickNote: '',
        },
        isDirty: false,
        couldLostData: false,
        showPartsTicketPopup: true,
        clerkList: [],
        openCustomerDialog: !(id > 0),
        openNewCustomerDialog: false,
        partsSettings: {
            surplusTax: 0,
        },
    };

    const classes = {
        ...useStyle(),
        ...basicFormStyle(),
        ...ownStyle(),
    };
    const detailRef = useRef();

    const [state, dispatch] = useReducer(PartsTicketReducer, initialState);
    const {
        record, isDirty, couldLostData,
        showPartsTicketPopup,
        clerkList, openCustomerDialog,
        openNewCustomerDialog,
    } = state;

    const { isValid, errors } = isValidSchema(PartsTicketSchema, record);
    const isNew = record.ticketNumber === 0;

    const {
        defaultLotId, defaultLot, lotsUser, availableLots,
    } = useContext(UserContext);
    const overrideAvailableLots = (availableLots || []).map((item) => ({ value: item.id, label: item.label }));

    const { lotName, counterPerson } = record;

    const {
        editCustomer,
        editSalesType,
        editCounterPerson,
        editDiscountPercent,
        editLot,
        editQuickNote,
        editDetails,
        allowSaveAction,
    } = usePartsTicketValidateActions(record.status);

    const [getClerkList] = useLazyQuery(UserQuery.GET_USERS, {
        variables: {
            types: [EmployeeType.PARTS_CLERK],
        },
        onCompleted: (res) => {
            if (res.getUsers) {
                const list = res?.getUsers?.map((item) => ({
                    userId: item.userId,
                    user: [item.firstName, item.lastName].join(' '),
                }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'clerkList',
                        value: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getPartsTicket, { loading }] = useLazyQuery(PartsQuery.GET_PARTS_TICKET, {
        onCompleted: (res) => {
            if (res.getPartsTicket) {
                const result = PartsMapper.mapPartsTicket(res.getPartsTicket);

                if (result.lotName !== '' && (result?.lotId ?? 0) === 0) {
                    const currentLot = lotsUser?.find((c) => c.lotName === record.lotName);
                    result.lotId = currentLot?.lotId ?? 0;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: { ...result },
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                showPartsTicketPopup: false,
                isDirty: false,
            },
        });

        history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PARTS_TICKET}`);
    };

    const onPopupClose = (event, forceClose = false) => {
        if ((isDirty) && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        onCloseAction();
    };

    const mutationQuery = isNew ? PartsMutation.CREATE_PARTS_TICKET : PartsMutation.UPDATE_PARTS_TICKET;
    const [addRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.createPartsTicket || mutationData?.updatePartsTicket) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: ({ graphQLErrors }) => {
            ModalUtils.errorMessage(graphQLErrors);
        },
    });

    const onSave = async (requestedNew) => {
        let resultSave = false;
        let recordToSave = { ...record };

        const { records, errors: detailLinesError } = detailRef.current.getRecords();

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'The detail lines are empty');
            return;
        }

        if (detailLinesError.length > 0) {
            ModalUtils.errorMessage(detailLinesError);
            return;
        }

        recordToSave.partsTicketDetails = records;

        recordToSave = PartsMapper.mapPartsTicketToSave(recordToSave);

        const resultAfterSave = await addRecord({
            variables: {
                record: recordToSave,
            },
        });

        const createdPartsTicketId = isNew ? resultAfterSave?.data?.createPartsTicket : resultAfterSave?.data?.updatePartsTicket;
        resultSave = createdPartsTicketId > 0;

        if (resultSave) {
            if (!requestedNew) onCloseAction(true);
            else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW_PARTS_TICKET,
                    value: initialState,
                });

                detailRef.current.clear();
            }
        }
    };

    const setOpenCustomerDialog = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openCustomerDialog: val,
            },
        });
    };

    const setOpenNewCustomerDialog = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openNewCustomerDialog: val,
            },
        });
    };

    const onSetCustomer = (val) => {
        const {
            firstName, lastName, customerCode, isTaxable,
            cellPhone, email,
        } = val;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                customerCode,
                customerName: `${firstName} ${lastName}`,
                customerEmail: email,
                customerPhone: cellPhone,
                isTaxable,
            },
        });
    };

    const clearCustomerInformation = () => {
        setOpenCustomerDialog(false);
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                customerCode: 0,
                customerName: '',
                isTaxable: true,
                customerPhone: '',
                customerEmail: '',
            },
        });
    };

    const onClickPrimaryDialog = (val) => {
        if (val.customer) onSetCustomer(val.customer);
        setOpenCustomerDialog(false);
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeLot = (field, value) => {
        const currentRecord = { ...record };

        currentRecord.lotId = value;
        const lotNameSelected = overrideAvailableLots?.find((c) => c.value === value);
        currentRecord.lotName = lotNameSelected?.label ?? '';

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeCounterPerson = (field, value) => {
        const currentRecord = { ...record };

        currentRecord.counterPersonId = value === 0 ? null : value;
        const currentClerk = clerkList?.find((c) => c.userId === value);
        currentRecord.counterPerson = value === 0 ? null : currentClerk.user;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === PartsTicketStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record.status.toLowerCase() === PartsTicketStatus.QUOTE.toLowerCase()) badgeStatus = classes.badgeCleared;
        else if (record.status.toLowerCase() === PartsTicketStatus.IN_PROGRESS.toLowerCase()) badgeStatus = classes.badgePrinted;
        else if (record.status.toLowerCase() === PartsTicketStatus.CLOSED.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record.status.toLowerCase() === PartsTicketStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;

        return <Chip size="small" label={record.status} className={badgeStatus} />;
    };

    useEffect(() => {
        if (id > 0) {
            getPartsTicket({
                variables: {
                    id: Number(id),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (lotName !== '') {
            if (record.lotId === 0 && overrideAvailableLots.length > 0) {
                const currentLot = overrideAvailableLots.find((c) => c.label.toLowerCase() === lotName.toLowerCase());
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        lotId: currentLot.value,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotName, overrideAvailableLots.length > 0]);

    useEffect(() => {
        getClerkList();

        if (defaultLotId > 0 && record.lotName === '') {
            dispatch({
                type: ACTION_TYPES.SET_DEFAULT_LOT,
                value: {
                    lotId: defaultLotId,
                    lotName: defaultLot,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (counterPerson && (counterPerson !== '' && (record.counterPersonId === 0 || isEmpty(record.counterPersonId)) && clerkList.length > 0)) {
            const currentClerk = clerkList.find((c) => c.user.toLowerCase() === counterPerson.toLowerCase());
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: {
                    ...record,
                    counterPersonId: currentClerk?.userId ?? null,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counterPerson, clerkList.length > 0]);

    const enableBtnSave = (isValid && isDirty && allowSaveAction && !isSaving);

    const messageToAllowSave = [];

    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    return (
        <>
            <Dialog
                open={showPartsTicketPopup}
                onClose={onPopupClose}
                aria-labelledby="form-dialog-title"
                fullScreen
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar className={clsx(classes.centerItems, classes.toolBarColor)}>
                        <div className={classes.buttonSpacing}>
                            {PARTS_TICKET_WRITE_EDIT
                        && allowSaveAction
                                && (
                                    <ErrorMessageIndicator
                                        messages={messageToAllowSave}
                                    >

                                        <ButtonMaterial
                                            className={classes.buttonSave}
                                            startIcon={<SaveIcon />}
                                            size="small"
                                            disabled={!enableBtnSave}
                                            onClick={() => onSave(false)}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </ButtonMaterial>
                                    </ErrorMessageIndicator>
                                )}
                            {PARTS_TICKET_WRITE_EDIT
                        && allowSaveAction
                                && (
                                    <ButtonMaterial
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(true)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Save & New
                                    </ButtonMaterial>
                                )}
                        </div>
                        <div className={classes.centerItems}>
                            <Typography variant="h6">
                                { record.ticketNumber === 0 ? 'New Parts Ticket' : `Edit Parts Ticket ${record.ticketNumber}` }
                            </Typography>
                            <div className={classes.paddingLeftRigth20}>
                                { getStatusChip() }
                            </div>
                            <IconButton edge="start" color="primary" onClick={onPopupClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.dialogContent}>
                    { loading ? <LoadingMask />
                        : (
                            <Container className={classes.containerSplit}>
                                <Split
                                    sizes={isMobile ? [70, 30] : [30, 70]}
                                    className={isMobile ? classes.verticalSplit : 'split'}
                                    direction={isMobile ? null : 'horizontal'}
                                >
                                    <div>
                                        <Grid container className={classes.compactForm}>
                                            <Grid item xs={12}>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Customer *" />
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            className={isValidField(errors, 'customerCode') ? 'invalid-field' : ''}
                                                            value={record.customerName}
                                                            type="text"
                                                            placeholder="Customer name"
                                                            size="sm"
                                                            disabled
                                                            name="customerName"
                                                        />
                                                        {editCustomer && (
                                                            <InputGroup.Append>
                                                                <Button
                                                                    variant="outline-primary"
                                                                    size="sm"
                                                                    onClick={() => setOpenCustomerDialog(true)}
                                                                    disabled={!editCustomer}
                                                                >
                                                                    Select
                                                                </Button>
                                                                <Button
                                                                    variant="outline-primary"
                                                                    size="sm"
                                                                    onClick={clearCustomerInformation}
                                                                    disabled={!editCustomer}
                                                                >
                                                                    X
                                                                </Button>
                                                            </InputGroup.Append>
                                                        ) }
                                                    </InputGroup>
                                                    <LabelMemorize text={`Email: ${record.customerEmail}. Cell phone: ${record.customerPhone}`} />
                                                </Form.Group>
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Lot *" />
                                                    <DropdownQuery
                                                        name="lotId"
                                                        disabled={!editLot}
                                                        className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                                        value={record.lotId}
                                                        placeHolder="select a lot"
                                                        onChange={(name, newValue) => onChangeLot(name, newValue)}
                                                        dataSource={{
                                                            localData: overrideAvailableLots,
                                                            idField: 'value',
                                                            descriptionField: 'label',
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Sales Type *" />
                                                    <DropdownQuery
                                                        name="salesType"
                                                        disabled={!editSalesType}
                                                        className={isValidField(errors, 'salesType') ? 'invalid-field' : ''}
                                                        value={record.salesType}
                                                        placeHolder="select a sales type"
                                                        onChange={(name, newValue) => onChangeValue(name, newValue === 0 ? '' : newValue)}
                                                        dataSource={{
                                                            localData: salesTypesData,
                                                            idField: 'type',
                                                            descriptionField: 'type',
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Counter Person" />
                                                    <DropdownQuery
                                                        name="counterPersonId"
                                                        disabled={!editCounterPerson}
                                                        className={isValidField(errors, 'counterPersonId') ? 'invalid-field' : ''}
                                                        value={record.counterPersonId}
                                                        placeHolder="select a counter person"
                                                        onChange={(name, newValue) => onChangeCounterPerson(name, newValue)}
                                                        dataSource={{
                                                            localData: clerkList,
                                                            idField: 'userId',
                                                            descriptionField: 'user',
                                                        }}
                                                        defaultEmptyLineText="Empty"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Discount %" />
                                                    <InputNumber
                                                        disabled={!editDiscountPercent}
                                                        size="sm"
                                                        showCurrency={false}
                                                        allowNegative={false}
                                                        value={record.discountPercent}
                                                        thousandSeparator
                                                        placeholder="0"
                                                        onChange={(value) => onChangeValue('discountPercent', value)}
                                                    />
                                                </Form.Group>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Form.Group as={Col}>
                                                    <LabelMemorize text="Note" />
                                                    <Form.Control
                                                        as="textarea"
                                                        disabled={!editQuickNote}
                                                        className={isValidField(errors, 'quickNote') ? 'invalid-field' : ''}
                                                        size="sm"
                                                        rows="2"
                                                        value={record.quickNote || ''}
                                                        placeholder="Notes"
                                                        onChange={(e) => onChangeValue('quickNote', e.target.value)}
                                                    />
                                                    <p className={classes.fontSmall}>{getErrorMessage('quickNote', errors)}</p>
                                                </Form.Group>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <PartsTicketDetail
                                        lotName={record.lotName}
                                        recordId={record.ticketNumber}
                                        discountPercent={record.discountPercent ?? 0}
                                        recordFields={{
                                            isTaxable: record.isTaxable ?? true,
                                            sumSubTotal: (record.subTotal || record.totalNetPrice) ?? 0,
                                            sumDiscount: record.discount ?? 0,
                                            sumTax: record.totalTax ?? 0,
                                            sumTotal: record.total ?? 0,
                                        }}
                                        editDetails={editDetails}
                                        writePermissions={PARTS_TICKET_WRITE_EDIT}
                                        ref={detailRef}
                                        onDirty={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { isDirty: true } })}
                                    />
                                </Split>
                                <ConfirmDialog
                                    title="Attention - You have unsaved changes!"
                                    description="Do you want to close without saving?"
                                    open={couldLostData}
                                    variant="outlined"
                                    titlePrimary="Close"
                                    titleSecondary="Cancel"
                                    onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                    onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                    onClickPrimary={(e) => onPopupClose(e, true)}
                                />
                            </Container>
                        )}
                </DialogContent>
                {isSaving && <DialogActionMessage message="Saving information... " />}
            </Dialog>
            <If condition={openCustomerDialog}>
                <CustomerForServiceList
                    inHouse={false}
                    showVehicleGrid={false}
                    onClose={() => setOpenCustomerDialog(false)}
                    onSelectCustomer={onClickPrimaryDialog}
                    showAddNewCustomerButton
                    onClickNewCustomerButton={() => { setOpenCustomerDialog(false); setOpenNewCustomerDialog(true); }}
                />
            </If>
            <If condition={openNewCustomerDialog}>
                <Dialog
                    open={openNewCustomerDialog}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogAppBar
                        title="Create new customer"
                        onClose={() => setOpenNewCustomerDialog(false)}
                    />
                    <DialogContent className={classes.dialogContent}>
                        <CreateCustomer toggleCreateCustomer={() => setOpenNewCustomerDialog(false)} onSetCustomer={onSetCustomer} />
                    </DialogContent>
                </Dialog>
            </If>
        </>
    );
};

export default PartsTicketCreate;

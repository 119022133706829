import React, { PureComponent } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Paper, Typography, Divider,
    Button,
} from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

const styles = (theme = {}) => ({
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        minHeight: 38,
    },
    paper: {
        minHeight: (props) => props.minHeight,
        maxHeight: (props) => props.maxHeight,
        display: 'flex',
        flexDirection: 'column',
    },
    generalClassBoxHeader: {
        padding: theme.spacing(0.5, 1.8),
    },
    generalClassBox: {
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    generalClassTitle: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    bodyBoxWidget: {
        overflow: 'auto',
        flex: 1,
    },
    buttonEdit: {
        color: theme.palette.text.infoDark,
    },
    primaryButtonFooter: {
        color: theme.palette.info.main,
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(0.5, 1.8),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
});

class BoxWidget extends PureComponent {
    constructor(props) {
        super(props);

        this.initBind();
    }

    onClickPrimaryButtonFooter() {
        const { props: { onClickPrimaryButtonFooter } } = this;

        if (typeof onClickPrimaryButtonFooter === 'function') {
            onClickPrimaryButtonFooter();
        }
    }

    initBind() {
        this.onClickPrimaryButtonFooter = this.onClickPrimaryButtonFooter.bind(this);
    }

    renderRightSection() {
        const { props: { classes, disabledButtonRight } } = this;

        return (
            <div>
                <Button
                    size="small"
                    disabled={disabledButtonRight}
                    className={classes.buttonEdit}
                    startIcon={<BorderColorOutlinedIcon />}
                >
                    Edit
                </Button>
            </div>
        );
    }

    render() {
        const {
            props: {
                classes, children, title, titleClass, className, rightHeaderSection, bodyClass,
                typeBox, hiddenHeaderBorder, showButtonRight, showFooter, disabledPrimaryButtonFooter,
            },
        } = this;
        const titleClasses = titleClass ? clsx(classes.title, titleClass) : classes.title;

        let generalClassBoxHeader = '';
        let generalClassBox = '';
        let generalClassTitle = '';

        if (!StringUtils.isEmpty(typeBox) && typeBox === 'general') {
            generalClassBox = classes.generalClassBox;
            generalClassBoxHeader = classes.generalClassBoxHeader;
            generalClassTitle = classes.generalClassTitle;
        }

        return (
            <Paper
                elevation={0}
                square
                className={clsx(classes.paper, generalClassBox, className)}
            >
                <div className={clsx(classes.boxHeader, generalClassBoxHeader)}>
                    {title && (
                        <Typography variant="h6" color="primary" className={clsx(titleClasses, generalClassTitle)}>{title}</Typography>
                    )}
                    {rightHeaderSection}
                    {showButtonRight && this.renderRightSection()}
                </div>
                {title && !hiddenHeaderBorder && <Divider />}
                <div className={clsx(classes.bodyBoxWidget, bodyClass)}>
                    {children}
                </div>
                {showFooter && (
                    <div className={classes.footer}>
                        <Button
                            disabled={disabledPrimaryButtonFooter}
                            size="small"
                            className={classes.primaryButtonFooter}
                            onClick={this.onClickPrimaryButtonFooter}
                        >
                            Add previous
                        </Button>
                    </div>
                )}
            </Paper>
        );
    }
}

BoxWidget.propTypes = {
    title: PropTypes.string,
    typeBox: PropTypes.string,
    showFooter: PropTypes.bool,
    bodyClass: PropTypes.string,
    className: PropTypes.string,
    /* eslint-disable react/no-unused-prop-types */
    minHeight: PropTypes.string,
    /* eslint-disable react/no-unused-prop-types */
    maxHeight: PropTypes.string,
    titleClass: PropTypes.string,
    showButtonRight: PropTypes.bool,
    rightHeaderSection: PropTypes.node,
    hiddenHeaderBorder: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClickPrimaryButtonFooter: PropTypes.func,
    disabledButtonRight: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    disabledPrimaryButtonFooter: PropTypes.bool,
};

BoxWidget.defaultProps = {
    title: '',
    typeBox: '',
    bodyClass: '',
    className: '',
    minHeight: '250px',
    maxHeight: '250px',
    titleClass: null,
    showFooter: false,
    showButtonRight: false,
    rightHeaderSection: null,
    hiddenHeaderBorder: false,
    disabledButtonRight: false,
    onClickPrimaryButtonFooter: () => {},
    disabledPrimaryButtonFooter: false,
};

export default withStyles(styles)(BoxWidget);

import { gql } from '@apollo/client';

export default class PortfolioQuery {
    static GET_SETTINGS_PORTFOLIOS = gql`
        query getPortfolioList($filter: PortfolioSettingsFilter) {
            getPortfolioList(filter: $filter) {
                portfolioId
                name
                type
                inHouse
                activelyUsed
                isDefault
            }
        }
    `;

    static GET_SETTINGS_VENDOR_LIST = gql`
        query getVendorSettingsList($paginate: DataPaging, $filter: VendorSettingsFilter, $sort: DataSort) {
            getVendorSettingsList(paginate: $paginate, filter: $filter, sort: $sort, ) {
                totalCount
                data {
                    vendorId
                    vendorName
                    vendorCode
                    vendorType
                    vendorAddress
                    vendorCity
                    vendorState
                    active
                }
            }
        }
    `;

    static PORTFOLIO_CREDIT_COMPANIES = gql`
        query getPortfolioCreditCompanies {
            getPortfolioCreditCompanies {
                creditReportingId
                furnisherName
                active
            }
        }
    `;

    static PORTFOLIO_DETAIL = gql`
        query getPortfolio($id: Int!) {
            getPortfolio(id: $id) {
                portfolioId
                creditReportingCompanyId
                ownerCompany
                creditCompany
                accountingClass
                type
                description
                name
                originationDate
                activelyUsed
                inHouse
                stockIsLoanNumber
                loanNumberPrefix
                nextLoanNumber
                addressName
                textName
                address1
                address2
                zip
                city
                state
                phone
                fax
                website
                receiptMessage
                convenienceFee
                imported
                isDefault
                achConvenienceFee
            }
        }
    `;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Paper, Typography, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { SERVICE_JOBS_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import { Form } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import clsx from 'clsx';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';

const ownStyle = makeStyles((theme) => (
    {
        itemCls: {
            padding: '8px',
            margin: '10px',
            border: `solid 1px rgba(${theme.palette.rgb.black}, 0.12)`,
            '&:nth-child(even)': {
                backgroundColor: theme.palette.background.titanWhite,
            },
            '& p, & h6': {
                fontSize: 12,
            },
        },
        labelHeader: {
            backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            padding: '6px',
            fontSize: '14px !important',
        },
        timePickerCls: {
            padding: 0,
            width: '100%',
        },
        labelItem: {
            lineHeight: '30px',
        },
        btnSave: {
            float: 'right',
            marginRight: '10px',
        },
    }
));

const TechnicianJobForm = ({ item }) => {
    const writePermissions = true;
    const {
        invoiceNumber,
        jobNumber,
        serviceJobId,
        requestedParts,
        estimatedHours,
        serviceInvoice,
        status,
        customerStates,
        cause,
        correction,
        recommend1,
        recommend2,
        recommend3,
        recommend4,
        recommend5,
    } = item;

    const recomendations = `${recommend1} ${recommend2} ${recommend3} ${recommend4} ${recommend5}`;

    const [saveData, { loading: saving }] = useMutation(ServiceMutation.UPDATE_SERVICE_TECHNICIAN_JOB, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.updateTechnicianJob) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const {
        advisor,
        customer,
        status: invoiceStatus,
        vehicleStock,
        vehicleMake,
        vehicleModel,
        vehicleTrim,
        vehicleYear,
    } = serviceInvoice;

    const stockNumber = vehicleStock > 0 ? vehicleStock : 'No stock available';
    const classes = ownStyle();

    const [record, setRecord] = useState({
        estimatedHours,
        requestedParts,
        serviceJobId,
        status,
    });

    const estimatedTimeHours = record.estimatedHours.toString().split('.')[0];
    const estimatedTimeMinutes = (Number((record.estimatedHours.toString().split('.')[1] || 0)) * 60) / 100;
    const estimatedHoursObject = moment().set({ hour: estimatedTimeHours, minutes: estimatedTimeMinutes, seconds: 0 });

    const handleEditorChange = (name, value) => {
        let newValue = value;
        if (name === 'estimatedHours') {
            newValue = moment.duration(`${value.get('hour')}:${value.get('minute')}`).asHours().toFixed(2);
        }

        setRecord((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    const onSave = () => {
        saveData({
            variables: {
                ...record,
                estimatedHours: Number(record.estimatedHours),
            },
        });
    };

    return (
        <Paper elevation={3} className={classes.itemCls}>
            <Grid container spacing={1}>
                <Grid item container xs={12}>
                    <Grid item xs={12} md={12}>
                        <Typography className={classes.labelHeader} variant="h6" noWrap>{`Invoice #: ${invoiceNumber} - Job #: ${jobNumber}`}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Service Advisor:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{advisor || 'advisor not assigned'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Vehicle:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{`${stockNumber} - ${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Customer:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{`${customer?.firstName} ${customer.lastName} `}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>RO Status:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{invoiceStatus}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Customer States:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{customerStates}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Cause:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{cause}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Correction:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{correction}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color="primary" variant="h6" noWrap>Recommends:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography noWrap>{recomendations}</Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.labelItem} color="primary" variant="h6" noWrap>Estimated hours:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TimePicker
                            value={estimatedHoursObject}
                            focusOnOpen
                            showSecond={false}
                            placeholder="hours and minutes"
                            onChange={(value) => handleEditorChange('estimatedHours', moment(value))}
                            className={clsx('form-control-sm', classes.timePickerCls)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.labelItem} color="primary" variant="h6" noWrap>Job Status:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DropdownQuery
                            name="status"
                            allowEmptyLine={false}
                            value={record.status}
                            placeHolder="select the status"
                            onChange={handleEditorChange}
                            dataSource={{
                                localData: SERVICE_JOBS_STATUS_LIST,
                                idField: 'value',
                                descriptionField: 'label',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6" noWrap>Requested Parts:</Typography>
                        {writePermissions
                            && (
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    name="requestedParts"
                                    value={record.requestedParts || ''}
                                    onChange={(event) => handleEditorChange(event.target.name, event.target.value)}
                                    rows={4}
                                />
                            )}
                        {!writePermissions
                            && (
                                <Typography noWrap>{record.requestedParts}</Typography>
                            )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        startIcon={<SaveOutlinedIcon />}
                        size="small"
                        color="secondary"
                        className={classes.btnSave}
                        disabled={saving}
                        onClick={onSave}
                    >
                        {saving ? 'Saving...' : 'Save'}
                        {saving && <CircularProgress size={20} />}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

TechnicianJobForm.propTypes = {
    item: PropTypes.shape({
        serviceJobId: PropTypes.string.isRequired,
        invoiceNumber: PropTypes.number.isRequired,
        jobNumber: PropTypes.number.isRequired,
        status: PropTypes.string,
        lotName: PropTypes.string,
        requestedParts: PropTypes.string,
        estimatedHours: PropTypes.number,
        customerStates: PropTypes.string,
        cause: PropTypes.string,
        correction: PropTypes.string,
        recommend1: PropTypes.string,
        recommend2: PropTypes.string,
        recommend3: PropTypes.string,
        recommend4: PropTypes.string,
        recommend5: PropTypes.string,
        serviceInvoice: PropTypes.shape({
            advisor: PropTypes.string,
            status: PropTypes.string,
            vehicleStock: PropTypes.number,
            vehicleYear: PropTypes.string,
            vehicleMake: PropTypes.string,
            vehicleModel: PropTypes.string,
            vehicleTrim: PropTypes.string,
            customer: PropTypes.oneOfType([PropTypes.object]),
        }).isRequired,
    }).isRequired,
};

export default TechnicianJobForm;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DisplaySelect from 'components/modules/inventory/create/panels/DisplaySelect';

import StringUtils from 'lib/StringUtils';
import InventoryHelper from 'utils/InventoryHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        background: '#FBFBFB',
        marginBottom: 20,
        filter: 'brightness(98%)',
        [theme.breakpoints.down(780)]: {
            marginBottom: 0,
        },
    },
    gridContainer: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
}));

const AdvertisingForm = ({
    data,
    onChange,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const onChangeForm = (fieldName, value) => {
        onChange(fieldName, value, 'advertisingInfo');
    };
    const invalidReducePrice = data.isReducePrice && StringUtils.isEmpty(data.reducePrice);
    const invalidFrequency = data.isReducePrice && StringUtils.isEmpty(data.every);
    const reduceFrequencyOptions = InventoryHelper.reduceFrequency;
    let reducedPriceOptions = InventoryHelper.reducedPrice;

    if (!data.isReducePrice) {
        reducedPriceOptions = [
            {
                label: 'Not Setup',
                value: '',
            },
        ];
    }

    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <Typography variant="h5">Advertising Information</Typography>
            <Grid
                container
                orientation="column"
                className={classes.gridContainer}
            >
                <DisplaySelect
                    label={(
                        <>
                            <FormLabel className="text-bold">Reduce Price:</FormLabel>
                            <Checkbox
                                checked={data.isReducePrice}
                                color="primary"
                                size="small"
                                onChange={(_, value) => onChangeForm('isReducePrice', value)}
                            />
                        </>
                    )}
                    value={data.reducePrice}
                    name="reducePrice"
                    onChange={(value) => onChangeForm('reducePrice', value)}
                    options={reducedPriceOptions}
                    disabled={!data.isReducePrice}
                    bold
                    alignRight={!isMobile}
                    className={clsx({ 'invalid-field': invalidReducePrice })}
                />
                <DisplaySelect
                    label="Every"
                    value={data.every}
                    name="every"
                    onChange={(value) => onChangeForm('every', value)}
                    options={reduceFrequencyOptions}
                    disabled={!data.isReducePrice}
                    bold
                    alignRight={!isMobile}
                    className={clsx({ 'invalid-field': invalidFrequency })}
                />
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Place online ads for this vehicle:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Checkbox
                            checked={data.isPlaceOnline}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChangeForm('isPlaceOnline', value)}
                        />
                    </Grid>
                </Grid>
                <DisplaySelect
                    label="Remove ad"
                    value={data.removeAd}
                    name="removeAd"
                    onChange={(value) => onChangeForm('removeAd', value)}
                    options={InventoryHelper.removeAd}
                    bold
                    alignRight={!isMobile}
                />
            </Grid>
        </div>
    );
};

AdvertisingForm.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

AdvertisingForm.defaultProps = {
    data: {},
};

export default AdvertisingForm;

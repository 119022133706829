import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
} from '@material-ui/core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { Form } from 'react-bootstrap';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
            fontSize: '15px',
        },
    },
    content: {
        display: 'flex',
        padding: '10px 10px',
        height: '550px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& > div:nth-child(1)': {
            width: '80%',
            height: '100%',
            '& > textarea': {
                resize: 'none',
                height: '100%',
                whiteSpace: 'pre',
                fontFamily: 'monospace',
                fontSize: '13px',
            },
        },
        '& > div:nth-child(2)': {
            width: '20%',
            height: '100%',
            border: `1px solid ${theme.palette.border.ghost}`,
            marginLeft: '2px',
            borderRadius: '3px',
        },
    },
    listItem: {
        userSelect: 'none',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        paddingLeft: '5px',
        fontSize: '12px',
        height: '18px',
        '&:hover': {
            backgroundColor: theme.palette.background.silver,
            color: theme.palette.text.darkShark,
        },
    },
}));

const FileEditor = ({ file, toggleFileEditor }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const textareaRef = useRef(null);
    const [state, setState] = useState({
        content: null,
        contentPreview: null,
        sections: [],
        isPreviewEnabled: false,
        wasFileUpdated: false,
        filedEditedSize: null,
    });

    const {
        content,
        contentPreview,
        sections,
        isPreviewEnabled,
        wasFileUpdated,
        filedEditedSize,
    } = state;

    const {
        aiGlobalFileId,
        name,
        url,
    } = file;

    const {
        data: contentData,
        loading: loadingContent,
        error: errorLoadingContent,
    } = useQuery(AIQuery.GET_FILE_ON_BUCKET_CONTENT, {
        variables: {
            path: url,
            toString: true,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: sectionsData,
        loading: loadingSections,
        error: errorLoadingSections,
    } = useQuery(AIQuery.GET_DYNAMIC_SECTIONS_ON_FILE, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [previewFile] = useLazyQuery(AIQuery.PREVIEW_FILE_CONTENT, {
        onCompleted: (response) => {
            if (response) {
                const newContent = response.previewFileContent;
                setState((prevState) => ({
                    ...prevState,
                    contentPreview: newContent,
                }));
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [updateFile, { loading: updatingFile }] = useMutation(AIMutation.EDIT_GLOBAL_FILE, {
        onCompleted: () => {
            setState((prevState) => ({
                ...prevState,
                wasFileUpdated: true,
            }));

            ModalUtils.successMessage(null, 'File updated successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [updateLocalFile, { loading: updatingLocalFile }] = useMutation(AIMutation.EDIT_FILE_ON_BUCKET, {
        onCompleted: () => {
            ModalUtils.successMessage(null, 'File updated successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingContent) {
            ModalUtils.errorMessage(errorLoadingContent?.graphQLErrors);
            return;
        }

        if (!loadingContent) {
            const data = contentData?.getFileOnBucketContent;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    content: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContent, errorLoadingContent]);

    useEffect(() => {
        if (errorLoadingSections) {
            ModalUtils.errorMessage(errorLoadingSections?.graphQLErrors);
            return;
        }

        if (!loadingSections) {
            const data = sectionsData?.getDynamicSectionsOnFile;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    sections: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSections, errorLoadingSections]);

    const onChangeContent = (value) => {
        setState((prevState) => ({
            ...prevState,
            content: value,
        }));
    };

    const onPreview = () => {
        if (isPreviewEnabled) {
            setState((prevState) => ({
                ...prevState,
                contentPreview: null,
                isPreviewEnabled: false,
            }));

            return;
        }

        setState((prevState) => ({
            ...prevState,
            isPreviewEnabled: true,
        }));

        previewFile({
            variables: {
                content,
            },
        });
    };

    const onSelectSection = (section) => {
        const input = textareaRef.current;
        const { value } = input;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const currentValue = value.slice(0, start) + section + value.slice(end);
        input.value = currentValue;
        input.selectionEnd = start + section.length;
        input.selectionStart = input.selectionEnd;

        onChangeContent(currentValue);
    };

    const onUpdateFile = () => {
        if (content?.length === 0) return;
        // eslint-disable-next-line no-undef
        const generatedFile = new File([content], `${name}.txt`, { type: 'text/plain' });

        if (aiGlobalFileId) {
            updateFile({
                variables: {
                    fileId: aiGlobalFileId,
                    path: url,
                    size: generatedFile.size / 1024 / 1024,
                    file: generatedFile,
                },
            });

            return;
        }

        setState((prevState) => ({
            ...prevState,
            filedEditedSize: NumberUtils.round(generatedFile.size / 1024 / 1024),
        }));

        updateLocalFile({
            variables: {
                path: url,
                file: generatedFile,
            },
        });
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={`Editing file: ${name}`}
                onClose={() => (!isPreviewEnabled ? toggleFileEditor(null, wasFileUpdated, filedEditedSize) : null)}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div>
                        <Form.Control
                            ref={textareaRef}
                            disabled={isPreviewEnabled || updatingFile || updatingLocalFile}
                            as="textarea"
                            size="sm"
                            value={contentPreview ?? content ?? ''}
                            onChange={(e) => onChangeContent(e.target.value)}
                        />
                    </div>
                    <div>
                        {sections.map((section, index) => (
                            <div
                                className={classes.listItem}
                                onDoubleClick={() => (!isPreviewEnabled && !updatingFile && !updatingLocalFile ? onSelectSection(section) : null)}
                                key={index}
                            >
                                <span>{section}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={updatingFile || updatingLocalFile}
                    className={classes.containedSecondaryInfo}
                    size="small"
                    onClick={onPreview}
                >
                    {isPreviewEnabled ? 'Close Preview' : 'Preview'}
                </Button>
                <Button
                    disabled={isPreviewEnabled || updatingFile || updatingLocalFile}
                    className={classes.containedSecondaryInfo}
                    size="small"
                    onClick={onUpdateFile}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FileEditor.propTypes = {
    file: PropTypes.object.isRequired,
    toggleFileEditor: PropTypes.func.isRequired,
};

export default FileEditor;

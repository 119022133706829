import { gql } from '@apollo/client';

export default class MiscCommissionsMutation {
    static MARK_COMMISSION_AS_PAID = gql`
        mutation markDealCommissionAsPaid($accountNumberList: [Int!]!, $employeeId: Int!) {
            markDealCommissionAsPaid(accountNumberList: $accountNumberList, employeeId: $employeeId)
        }
    `;

    static PRINT_COMMISSIONS_FORM = gql`
        mutation printDealCommissionReport($employeeId: Int!, $payPeriod: String!) {
            printDealCommissionReport(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static APPROVE_DEAL_COMMISSION = gql`
        mutation approveDealCommission($input: [DealCommissionInput!]!, $employeeId: Int!, $payPeriod: String!) {
            approveDealCommission(input: $input, employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static APPROVE_GENERAL_COMMISSION = gql`
        mutation approveGeneralCommission($amount: Float!, $employeeId: Int!, $payPeriod: String!) {
            approveGeneralCommission(amount: $amount, employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static PRINT_PURCHASING_AGENT_COMMISSIONS_FORM = gql`
        mutation printPurchasingAgentCommissionReport($employeeId: Int!, $payPeriod: String!) {
            printPurchasingAgentCommissionReport(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static SAVE_COMMISSIONS = gql`
        mutation savePayrollDealCommissions($accountNumber: Int!, $commissions: CommissionsInput) {
            savePayrollDealCommissions(accountNumber: $accountNumber, commissions: $commissions)
        }
    `;
}

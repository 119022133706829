import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper, List, ListItem, ListItemText, Grid,
} from '@material-ui/core';

const useStyles = makeStyles(({
    main: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    card: {
        margin: 5,
        position: 'relative',
        overflow: 'hidden',
    },
    editButton: {
        right: 14,
        position: 'absolute',
        top: 14,
        zIndex: 1,
        padding: '0 5px',
        borderRadius: 2,
    },
    item: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const CardTable = (props) => {
    const {
        data, columns, actions,
    } = props;
    const classes = useStyles();
    const detailItems = (item) => columns.map(({ Header, accessor, Cell }, index) => {
        const value = item[accessor];

        return (
            <div key={`carditem_${index}`}>
                {Header !== 'Action' && (
                    <ListItem key={accessor} className={classes.item}>
                        <ListItemText
                            primary={(
                                <div>
                                    <span>{`${Header}: `}</span>
                                    <span>{Cell ? Cell({ value }) : value}</span>
                                </div>
                            )}
                        />
                    </ListItem>
                )}
            </div>
        );
    });

    const items = () => data.map((item, i) => {
        const value = item;
        return (
            <Paper elevation={3} key={`card_${i}`} className={classes.card}>
                <div className={classes.editButton}>
                    {actions ? actions({ value }) : null}
                </div>
                <List dense>
                    {detailItems(item)}
                </List>
            </Paper>
        );
    });

    return (
        <Grid container direction="column" className={classes.main}>
            {items()}
        </Grid>
    );
};

CardTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.func,
};
CardTable.defaultProps = {
    actions: null,
};

export default CardTable;

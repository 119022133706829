import {
    Button,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Permission from 'utils/enum/Permissions';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import InputNumber from 'components/widgets/InputNumber';
import { ThumbUpAltOutlinedIcon } from 'components/icons';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import useEmployeHasCheck from 'components/hook/payroll/useEmployeHasCheck';
import MiscCommissionsQuery from 'services/graphQL/query/payroll/MiscCommissions';
import MiscCommissionsMutation from 'services/graphQL/mutate/payroll/MiscCommissions';

const useStyles = makeStyles((theme) => ({
    div: {
        padding: 10,
        display: 'flex',
    },
    label: {
        alignSelf: 'center',
    },
    input: {
        width: 100,
        marginLeft: 10,
        marginRight: 10,
    },
    actionButtonApprove: {
        color: theme.palette.text.green,
    },
}));

const GeneralCommissionPanel = ({
    employeeId, payPeriod,
}) => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const [amount, setAmount] = useState(0);
    const [openApprovalDialog, toggleApprovalDialog] = useState(false);
    const { hasCheck } = useEmployeHasCheck({ employeeId, payPeriod });

    const {
        loading, data, error,
    } = useQuery(MiscCommissionsQuery.GET_GENERAL_COMMISSION, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            setAmount(data?.getGeneralCommission || 0);
        }
    }, [data, loading, error]);

    const [approve, { loading: isApproving }] = useMutation(MiscCommissionsMutation.APPROVE_GENERAL_COMMISSION, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveGeneralCommission) {
                ModalUtils.successMessage(null, 'Commission(s) Approved Successfully!');
                toggleApprovalDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    return (
        <div className={classes.div}>
            <FormLabel className={classes.label}>
                Commission Total:
            </FormLabel>
            <InputNumber
                size="sm"
                showCurrency
                value={amount}
                thousandSeparator
                fixedDecimalScale
                className={classes.input}
                name="generalCommissionAmount"
                onChange={(value) => setAmount(value)}
            />
            {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_WRITE) && !hasCheck && (
                <Button
                    size="small"
                    variant="outlined"
                    disabled={isApproving}
                    onClick={() => toggleApprovalDialog(true)}
                    startIcon={(<ThumbUpAltOutlinedIcon className={classes.actionButtonApprove} />)}
                >
                    Approve
                </Button>
            )}
            <ConfirmDialog
                title="Confirm Approval"
                description="Approve Commission?"
                open={openApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={() => toggleApprovalDialog(false)}
                onClose={() => toggleApprovalDialog(false)}
                onClickPrimary={() => approve({
                    variables: {
                        amount,
                        payPeriod,
                        employeeId,
                    },
                })}
            />
        </div>
    );
};

GeneralCommissionPanel.propTypes = {
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
};

export default GeneralCommissionPanel;

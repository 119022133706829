import React from 'react';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import PayrollBaseList from 'components/modules/payroll/read/PayrollBaseList';
import PayrollCheckContainer from 'components/modules/payroll/read/payrollChecks/PayrollCheckContainer';

const PayrollCheckList = () => {
    const keyStore = new KeyStore();
    const savedSizes = keyStore.getPayrollChecksSplitSize() || [70, 30];

    const onResizingSplit = (size) => {
        keyStore.setPayrollChecksSplitSize(size);
    };

    const getRightSideComponent = ({
        pendingFinalPayment,
        employeeId, payPeriod, salary,
        payPeriodYear, loanBalance, payType,
    }) => (
        <PayrollCheckContainer
            salary={salary}
            payType={payType}
            year={payPeriodYear}
            payPeriod={payPeriod}
            employeeId={employeeId}
            loanBalance={loanBalance}
            pendingFinalPayment={pendingFinalPayment}
        />
    );

    getRightSideComponent.propTypes = {
        salary: PropTypes.number.isRequired,
        payType: PropTypes.string.isRequired,
        payPeriod: PropTypes.string.isRequired,
        employeeId: PropTypes.number.isRequired,
        loanBalance: PropTypes.number.isRequired,
        payPeriodYear: PropTypes.number.isRequired,
        pendingFinalPayment: PropTypes.bool.isRequired,
    };

    return (
        <PayrollBaseList
            splitSize={savedSizes}
            onResizingSplit={onResizingSplit}
            getRightSideComponent={getRightSideComponent}
        />
    );
};

export default PayrollCheckList;

/* eslint-disable */
NodeList.prototype.addEvent = function (evt, fn) {
    const list = this;
    const { length } = list;
    let i = 0;

    if (length === 0) {
        return;
    }
    // eslint-disable-next-line no-plusplus
    for (i; i < length; i++) {
        list[i].addEventListener(evt, fn);
    }
};

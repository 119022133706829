import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import { concat } from 'lodash';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import NoteForm from 'components/modules/crm/activities/form/NoteForm';
import ActivityItem from 'components/modules/crm/activities/ActivityItem';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';

// Materia UI
import { makeStyles, IconButton } from '@material-ui/core';

// Apollo
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useSubscription } from '@apollo/client';
import ActivitiesQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import ActivitySubscription from 'services/graphQL/subscription/crm/ActivitySubscription';

const useStyles = makeStyles((theme) => ({
    body: {
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
    },
    container: {
        height: '100%',
        padding: theme.spacing(2),
    },
}));

const ACTION_TYPE = {
    ADD_RECORD: 'addRecord',
    SET_OFFSET: 'setOffset',
    SET_RECORDS: 'setRecords',
    TOGGLE_MODAL: 'toggleModal',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.TOGGLE_MODAL:
        return update(state, {
            open: { $set: action.open },
        });
    case ACTION_TYPE.SET_RECORDS:
        const records = concat(state.records, action.payload?.data);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.payload?.totalCount },
        });
    case ACTION_TYPE.ADD_RECORD:
        return update(state, {
            records: { $unshift: [action?.payload?.data] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    default:
        return state;
    }
};

const NoteActivity = ({ crmId }) => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, {
        open: false,
        records: [],
        totalCount: 0,
        offset: 0,
    });
    const {
        data, error, loading,
    } = useQuery(ActivitiesQuery.GET_NOTE_ACTIVITIES_BY_CRM_ID, {
        variables: { crmId, paging: { start: state.offset, limit: 25 } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const { data: subscriptionData } = useSubscription(ActivitySubscription.UPDATED_ACTIVITY_NOTE, {
        variables: {
            crmId,
        },
        shouldResubscribe: true,
    });
    const CRM_LEAD_ACTIVITY_WRITE = keyStore.hasPermission(Permission.CRM_LEAD_ACTIVITY_WRITE);

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data?.getNoteActivitiesByCRMId,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (subscriptionData) {
            dispatch({
                type: ACTION_TYPE.ADD_RECORD,
                payload: subscriptionData.updatedActivityNote,
            });
        }
    }, [subscriptionData]);

    const onClose = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_MODAL,
            open: false,
        });
    };

    const onOpen = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_MODAL,
            open: true,
        });
    };

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };

    if (error) {
        ModalUtils.errorMessage(error.graphQLErrors);
        return null;
    }

    return (
        <div className={clsx('d-flex-column', classes.container)}>
            {CRM_LEAD_ACTIVITY_WRITE && (
                <div className={classes.header}>
                    <IconButton
                        size="small"
                        aria-label="Add"
                        onClick={onOpen}
                        className={classes.iconButton}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
                </div>
            )}
            <InfiniteScroll
                lengthRecord={state?.records.length}
                totalRecord={state?.totalCount}
                loadMore={loadMore}
                load={loading}
                loadAtScrollPercent={80}
            >
                {
                    state?.records.map((item, index) => (
                        <ActivityItem
                            key={index}
                            record={item}
                        />
                    ))
                }
            </InfiniteScroll>
            {state.open && (
                <NoteForm
                    crmId={crmId}
                    open={state.open}
                    onClose={onClose}
                />
            )}
        </div>
    );
};

NoteActivity.propTypes = {
    crmId: PropTypes.string.isRequired,
};

export default NoteActivity;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import DateUtils from 'lib/DateUtils';
import { useTheme } from '@material-ui/core/styles';

import InputNumber from 'components/widgets/InputNumber';
import DatePicker from 'react-datepicker';
import Select from 'components/widgets/Select';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        background: '#FBFBFB',
        marginBottom: 20,
        filter: 'brightness(98%)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    gridContainer: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
        '& > div:nth-child(2) > div:nth-child(2)': {
            '& > div': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            '& input': {
                height: '31px',
                fontSize: '0.875rem',
            },
        },
        '& > div:nth-child(4) > div:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            '& > input': {
                width: '185px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
        },
    },
}));

const FloorplanForm = ({
    data,
    onChange,
    flooringCompanies,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const invalidFlooringCompany = data.isFloorPlanned && Number(data.flooredBy ?? 0) <= 0;
    const invalidFloorPlannedDate = data.isFloorPlanned && !data.floorPlannedDate;
    const invalidBorrowedAmount = data.borrowedAmount <= 0 && data.isFloorPlanned;
    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <Typography variant="h5">Floorplan Information</Typography>
            <Grid
                container
                orientation="column"
                className={classes.gridContainer}
            >
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Floor Planned:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Checkbox
                            checked={data.isFloorPlanned === null ? false : data.isFloorPlanned}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChange('isFloorPlanned', value, 'floorPlanInfo')}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Floor Date:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <DatePicker
                            size="small"
                            className={clsx('form-control', { 'invalid-field': invalidFloorPlannedDate })}
                            onChange={(date) => onChange('floorPlannedDate', date, 'floorPlanInfo')}
                            selected={data.floorPlannedDate ? DateUtils.toLocal(data.floorPlannedDate).toDate() : ''}
                            popperPlacement="top-end"
                            popperContainer={CalendarContainer}
                            disabled={!data.isFloorPlanned}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Flooring Company:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Select
                            nowrap
                            size="sm"
                            className={clsx(classes.input, { 'invalid-field': invalidFlooringCompany })}
                            name="flooredBy"
                            onChange={(name, value) => onChange(name, value, 'floorPlanInfo')}
                            value={data.flooredBy}
                            options={flooringCompanies.map((item) => ({
                                value: item.vendorId,
                                label: item.vendorName,
                            }))}
                            disabled={!data.isFloorPlanned}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Amount Borrowed:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Checkbox
                            checked={data.isBorrowedAmountOverride}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChange('isBorrowedAmountOverride', value, 'floorPlanInfo')}
                            disabled={!data.isFloorPlanned}
                        />
                        <InputNumber
                            showCurrency
                            thousandSeparator
                            size="sm"
                            value={data.borrowedAmount}
                            min={0}
                            disabled={!data.isFloorPlanned || !data.isBorrowedAmountOverride}
                            onChange={(value) => onChange('borrowedAmount', value, 'floorPlanInfo')}
                            className={clsx({ 'invalid-field': invalidBorrowedAmount })}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

FloorplanForm.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    flooringCompanies: PropTypes.array.isRequired,
};

FloorplanForm.defaultProps = {
    data: {},
};

export default FloorplanForm;

import React from 'react';
import InputNumber from 'components/widgets/InputNumber';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FormControlNumberMemorize = (props) => {
    const {
        onChange,
        onKeyDown,
        value,
        ...other
    } = props;
    const { className, ...otherProps } = other;
    delete otherProps.comparePropertyId;

    const handleChange = (event) => {
        if (value === event) return;
        onChange(event);
    };

    const handleKeyDown = (event) => {
        onKeyDown(event);
    };

    return (
        <InputNumber
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={clsx(className)}
            {...otherProps}
        />

    );
};

FormControlNumberMemorize.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    size: PropTypes.string,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    showCurrency: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
    thousandSeparator: PropTypes.bool,
    fixedDecimalScale: PropTypes.bool,
    onKeyDown: PropTypes.func,
    removeDecimalScale: PropTypes.bool,
    alignRight: PropTypes.bool,
    symbol: PropTypes.string,
    comparePropertyId: PropTypes.number,
};

FormControlNumberMemorize.defaultProps = {
    value: 0,
    min: null,
    max: null,
    size: '',
    decimalScale: 2,
    inputRef: () => {},
    onChange: () => {},
    showCurrency: false,
    allowNegative: false,
    thousandSeparator: false,
    fixedDecimalScale: false,
    onKeyDown: () => null,
    removeDecimalScale: false,
    alignRight: false,
    symbol: '$',
    comparePropertyId: 0,
};

const areEqual = (prevProps, nextProps) => Number(prevProps.value ?? 0) === Number(nextProps.value ?? 0)
    && String(prevProps.className ?? '') === String(nextProps.className ?? '')
    && String(prevProps?.disabled ?? false) === String(nextProps?.disabled ?? false);
export default React.memo(FormControlNumberMemorize, areEqual);

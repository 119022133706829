import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Dialog, DialogContent, AppBar, Toolbar, Typography, ButtonGroup, Button,
} from '@material-ui/core';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useLazyQuery } from '@apollo/client';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import { FetchPolicy } from 'utils/enum/Core';
import ServiceInvoiceList from 'components/modules/service/serviceInvoice/list/ServiceInvoiceList';
import { v1 as uuid } from 'uuid';
import { ExpandLessIcon, ExpandMoreIcon } from 'components/icons';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles((theme) => (
    {
        dialogContent: {
            height: '62vh',
            '& > div:first-child': {
                height: '62vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        search: {
            borderRadius: 10,
            width: 400,
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
        collapseExpandClass: {
            paddingRight: '30px',
            '& :hover': {
                backgroundColor: theme.palette.background.gray,
            },
        },
        groupBtnCls: {
            backgroundColor: theme.palette.background.white,
        },
    }
));

const ServiceHistoryRepairs = (props) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        appBar, centerItems, title,
        dialogContent, groupBtnCls,
        collapseExpandClass,
    } = classes;

    const {
        invoiceNumber,
        onClose,
    } = props;

    const [data, setData] = useState([]);

    const [getServiceROHistory, { loading }] = useLazyQuery(ServiceQuery.GET_SERVICE_INVOICE_HISTORY, {
        onCompleted: (res) => {
            if (res.getROHistory) {
                const dataProcessed = res.getROHistory.map((item) => ({
                    ...item,
                    open: true,
                    identifier: uuid(),
                }));
                setData(dataProcessed);
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const expandOrCollapse = (val) => {
        const currentRecords = data.map((item) => {
            const cloneItem = { ...item };
            if (item.invoiceNumber === val.invoiceNumber) {
                cloneItem.open = val.open;
            }

            cloneItem.identifier = uuid();

            return cloneItem;
        });

        setData(currentRecords);
    };

    const expandAndCollapseAllJobs = (val) => {
        const currentRecords = data.map((item) => ({
            ...item,
            open: val,
            identifier: uuid(),
        }));

        setData(currentRecords);
    };

    useEffect(() => {
        if (invoiceNumber > 0) {
            getServiceROHistory({
                variables: {
                    invoiceNumber,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceNumber]);

    const vinNumber = data?.[0]?.vehicleVin ?? '';

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            Vehicle Repairs History - VIN # :
                            {' '}
                            {vinNumber}
                        </Typography>
                        <div className={centerItems}>
                            <ButtonGroup class={collapseExpandClass}>
                                <Button
                                    variant="outlined"
                                    disabled={loading}
                                    size="small"
                                    className={groupBtnCls}
                                    onClick={() => expandAndCollapseAllJobs(true)}
                                >
                                    <ExpandMoreIcon />
                                </Button>
                                <Button
                                    variant="outlined"
                                    disabled={loading}
                                    size="small"
                                    className={groupBtnCls}
                                    onClick={() => expandAndCollapseAllJobs(false)}
                                >
                                    <ExpandLessIcon />
                                </Button>
                            </ButtonGroup>
                            <IconButton edge="start" color="inherit" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <ServiceInvoiceList
                        isHistory
                        loadMore={() => null}
                        loading={loading}
                        expandOrCollapse={expandOrCollapse}
                        table={{
                            records: data,
                            totalCount: data.length || 0,
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

ServiceHistoryRepairs.propTypes = {
    onClose: PropTypes.func.isRequired,
    invoiceNumber: PropTypes.number.isRequired,
};

export default ServiceHistoryRepairs;

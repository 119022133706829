import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Dialog, DialogContent, AppBar, Toolbar, Typography,
} from '@material-ui/core';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ServiceNotesHistory from 'components/modules/service/serviceInvoice/list/serviceNotes/ServiceNotesHistory';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles(() => (
    {
        dialogContent: {
            height: '62vh',
            '& > div:first-child': {
                height: '62vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        search: {
            borderRadius: 10,
            width: 400,
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
    }
));

const ServiceNotesPopup = (props) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        appBar, centerItems, title,
        dialogContent,
    } = classes;

    const {
        invoiceNumber,
        onClose,
    } = props;

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            RO History Notes
                        </Typography>
                        <div className={centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <ServiceNotesHistory
                        invoiceNumber={invoiceNumber}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

ServiceNotesPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    invoiceNumber: PropTypes.number.isRequired,
};

export default ServiceNotesPopup;

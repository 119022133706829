import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import UserQuery from 'services/graphQL/query/UserQuery';

const keyStore = new KeyStore();
const useRecordManagersByLots = (props = {}) => {
    const [recordManagers, setRecordManagers] = useState([]);
    const lots = (props.lots && props.lots.length > 0) ? props.lots : keyStore.getUserLots().map((c) => c.lotId);

    const { data, loading, error } = useQuery(UserQuery.GET_RECORD_MANAGERS_BY_LOTS, {
        variables: { lots },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            const { getRecordManagersByLots } = data;
            const result = getRecordManagersByLots.map((item) => ({
                value: item.userId,
                label: `${item.firstName} ${item.lastName}`,
            }));
            setRecordManagers([{ label: 'Unassigned', value: null }, ...result]);
        }
    }, [data, loading, error]);

    return {
        recordManagers,
        loading,
    };
};

export default useRecordManagersByLots;

/* eslint-disable */
import React from 'react';
import { map } from 'lodash';
import ReactTable from 'react-table';
import withDraggableColumns from 'react-table-hoc-draggable-columns';
import Loading from 'components/widgets/Loading';
import TableUtils from 'utils/TableUtils';
import clsx from 'clsx';

// Styles
import 'react-table/react-table.css';
import 'react-table-hoc-draggable-columns/dist/styles.css';
import 'styles/table.scss';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TableStyles from 'styles/widgets/TableStyles.js';
const styles = () => TableStyles.style();

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);
class Table extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: props.columns,
            orderBy: undefined,
            desc: true,
            isExpanded: false,
            keyTable: `key${TableUtils.generateUUID()}`,
        };

        this.initBind();
    }

    initBind() {
        this.loadMoreData = this.loadMoreData.bind(this);
        this.onSortedChange = this.onSortedChange.bind(this);
        this.toggleExpandAll = this.toggleExpandAll.bind(this);
    }

    static propTypes = {
        loadMore: PropTypes.func,
        sortedChange: PropTypes.func,
        className: PropTypes.string,
        data: PropTypes.array,
        columns: PropTypes.array,
        totalRecords: PropTypes.number,
        rowSelected: PropTypes.bool,
        containerClassName: PropTypes.string,
        isDraggable: PropTypes.bool,
        collapseAll: PropTypes.bool,
        unselectRow: PropTypes.bool,
        additionalClasses: PropTypes.string,
        isFilterable: PropTypes.bool,
        saveColumnSettings: PropTypes.func,
    };

    static get defaultProps() {
        return {
            load: false,
            data: [],
            defaultPageSize: 5000,
            minRows: 0,
            showPagination: false,
            multiSort: false,
            sortable: true,
            alignElementsRow: 'center',
            rowSelected: false,
            border: true,
            cursor: 'pointer',
            isDraggable: false,
            collapseAll: false,
            unselectRow: false,
            additionalClasses: '',
            isFilterable: false,
            saveColumnSettings: () => null,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            props: {
                rowSelected,
                collapseAll,
                loadMore,
                totalRecords,
                data,
                load,
                unselectRow,
            },
            state: { keyTable },
        } = this;

        if (loadMore) {
            const querySelector = `#${keyTable} .rt-tbody`;
            const element = document.querySelector(querySelector);

            if (element) {
                const footerLoading = document.querySelector(
                    `#footer-loading.${keyTable}`,
                );

                if (footerLoading) {
                    if (load && data.length < totalRecords) {
                        element.append(footerLoading);
                        footerLoading.style.display = 'block';
                    } else {
                        footerLoading.style.display = 'none';
                    }
                }
            }
        }

        if (rowSelected) {
            document
                .querySelectorAll('.rt-tbody .rt-tr')
                .addEvent('click', function () {
                    const elementActive = this.parentNode.parentNode.querySelector(
                        '.rt-tr.active',
                    );

                    if (elementActive) {
                        elementActive.classList.remove('active');
                    }

                    this.classList.add('active');
                });
        }

        if (
            typeof collapseAll === 'boolean'
            && prevProps.collapseAll !== collapseAll
        ) {
            this.toggleExpandAll(collapseAll);
        }

        if(unselectRow) {
            const element = document.querySelector(`#${keyTable} .rt-tbody .rt-tr.active`);
            if (element) {
                element.classList.remove('active');
            }
        }
    }

    componentDidMount() {
        const { props, state } = this;
        const { keyTable } = state;

        if (props.loadMore) {
            const querySelector = `#${keyTable} .rt-tbody`;
            const element = document.querySelector(querySelector);

            element.addEventListener('scroll', () => {
                const { props } = this;
                const { data, totalRecords, load } = props;

                if ( element.offsetHeight + element.scrollTop >= element.scrollHeight 
                    && data.length < totalRecords 
                    && !load) 
                            this.loadMoreData();
            });
        }
    }

    loadMoreData() {
        const { props } = this;

        props.loadMore();
    }

    getClass() {
        const { props } = this;
        const {
            minHeightRow,
            alignElementsRow,
            className,
            border,
            sortable,
            loadMore,
            cursor,
        } = props;
        let classMinHeightRow = '';
        let classAlignElementsRow = '';
        let classBorderTable = '';
        let classSortable = '';
        let classCursor = '';

        classMinHeightRow = !TableUtils.isEmpty(minHeightRow) && minHeightRow < 7 && minHeightRow > 0
            ? `rt-min-hb-${minHeightRow}`
            : '';
        classAlignElementsRow = TableUtils.isEmpty(alignElementsRow)
            ? ''
            : alignElementsRow;
        classBorderTable = border ? '' : 'rt-table-expandable';
        classSortable = (!loadMore && !sortable) || (loadMore && !sortable)
            ? 'no-sort-table'
            : '';
        classCursor = cursor === 'pointer'
            ? 'rt-cursor-pointer'
            : cursor === 'default'
                ? 'rt-cursor-default'
                : '';

        return `${classMinHeightRow} ${classAlignElementsRow} ${className} ${classBorderTable} ${classSortable} ${classCursor}`;
    }

    onSortedChange(newSorted, column) {
        const { props, state } = this;
        const columnName = column.id;

        this.setState(
            (prevState) => ({ desc: !prevState.desc, orderBy: columnName }),
            () => {
                if (props.sortedChange) {
                    props.sortedChange(columnName, state.desc);
                }
            },
        );
    }

    getDraggableColumns = (columns = []) => map(columns.map(
        (column) => {
            if (typeof column.accessor === 'string') return { id: column.accessor };
            if (typeof column.id === 'string') return { id: column.id };

            return {};
        },
    ), 'id');

    toggleExpandAll(isExpanded = false) {
        const table = this.table || {};
        const tableState = table.state || {};
        const rowCount = tableState.data.length;
        const expanded = [];

        if (isExpanded) {
            for (let i = 0; i <= rowCount; i++) expanded.push({ [i]: isExpanded });
        }

        table.setState({ expanded });
        this.setState({ isExpanded });
    }

    setUpSortingIcon = (columns) => {
        const { state: { desc, orderBy } } = this;
        if (!orderBy) return columns;

        for (const item of columns) {
            if (item.id === orderBy) {
                item.headerClassName = !desc ? '-sort-asc' : '-sort-desc';
            } else {
                item.headerClassName = !item.headerClassName
                    ? ''
                    : !item.sortable
                    && !item.headerClassName.includes('no-sort')
                        ? ''
                        : item.headerClassName;
            }
        }

        return columns;
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { props: { isFilterable, isDraggable }, state } = this;
        if (isFilterable || isDraggable) {
            this.setState({ columns: this.setUpSortingIcon(nextProps.columns) });
            return;
        }

        const columns = Object.assign(nextProps.columns, state.columns);
        this.setState({ columns: this.setUpSortingIcon(columns) });
    }

    renderReactTable(isDraggable, tableStyle, isSortable) {
        const { props, state } = this;

        if (isDraggable) {
            return (
                <ReactTableDraggableColumns
                    draggableColumns={{
                        mode: 'reorder',
                        draggable: this.getDraggableColumns(state.columns) || [],
                        onDragEnterClassName: 'rt-drag-enter-item',
                        enableColumnWideDrag: false,
                        disableTableScroll: true,
                        useDragImage: false,
                        onDraggedColumnChange: (newColumns) => props.saveColumnSettings(newColumns, true),
                    }}
                    ref={(t) => (this.table = t)}
                    style={tableStyle}
                    defaultPageSize={props.defaultPageSize}
                    minRows={props.minRows}
                    showPagination={props.showPagination}
                    noDataText={props.load ? '' : 'No rows found'}
                    loading={props.data.length > 0 ? false : props.load}
                    multiSort={props.multiSort}
                    onSortedChange={
                        props.loadMore
                            ? (newSorted, column) => {
                                this.onSortedChange(newSorted, column);
                            }
                            : null
                    }
                    {...props}
                    className={this.getClass()}
                    sortable={isSortable}
                    columns={state.columns}
                /> 
            ); 
        }

        return (
            <ReactTable
                ref={(t) => (this.table = t)}
                style={tableStyle}
                defaultPageSize={props.defaultPageSize}
                minRows={props.minRows}
                showPagination={props.showPagination}
                noDataText={props.load ? '' : 'No rows found'}
                loading={props.data.length > 0 ? false : props.load}
                multiSort={props.multiSort}
                onSortedChange={
                    props.loadMore
                        ? (newSorted, column) => {
                            this.onSortedChange(newSorted, column);
                        }
                        : props.sortedChange
                        ? (newSorted, column) => props.sortedChange(newSorted, column)
                        : null
                }
                {...props}
                className={this.getClass()}
                sortable={isSortable}
                columns={state.columns}
            />
        );
    }

    renderTable(isDraggable, tableStyle, isSortable) {
        return this.renderReactTable(isDraggable, tableStyle, isSortable);
    }

    render() {
        const { props, state } = this;
        const tableStyle = {
            flex: '1',
            height: '100%',
            width: '100%',
        };
        const {
            sortable, loadMore, containerClassName, isDraggable, classes,
        } = props;
        const { keyTable } = state;
        let isSortable = true;

        if ((!loadMore && !sortable) || (loadMore && !sortable)) {
            isSortable = false;
        }
        const containerClass = TableUtils.isEmpty(containerClassName)
            ? ''
            : containerClassName;

        return (
            <div
                id={keyTable}
                className={`table-selected ${containerClass} ${classes.containerStyles}`}
            >
                {this.renderTable(isDraggable, tableStyle, isSortable)}
                {props.loadMore && props.load && (
                    <div>
                        <div
                            id="footer-loading"
                            className={clsx(keyTable, classes.footerStyle) }
                        >
                            <div className={classes.loadingStyle}>
                                <Loading className="loading-table"/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Table);

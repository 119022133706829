import { PurchaseOrderStatus } from 'utils/enum/PartsPurchaseEnum';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AMOUNT: 'setAmount',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
    SET_SAVE_AND_NEW_PURCHASE: 'setSaveAndNewPurchase',
};

const PurchaseOrderReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.value, isDirty: action?.isDirty ?? state.isDirty };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value.lotId,
                lotName: action.value.lotName,
            },
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW_PURCHASE: {
        const { record } = state;

        return {
            ...action.value,
            record: {
                purchaseOrderId: 0,
                vendorId: record.vendorId,
                lotId: record.lotId,
                lotName: record.lotName,
                status: PurchaseOrderStatus.NEW,
                roNumber: null,
                shippingMethodId: record.shippingMethodId,
                partsClerk: record.partsClerk,
                orderDate: new Date(),
                deliveryDate: new Date(),
                salesTaxRate: record.salesTaxRate,
            },
            isDirty: false,
            couldLostData: false,
            isDirtyFiles: false,
            showPurchasePopup: true,
            vendorList: state.vendorList,
            clerkList: state.clerkList,
        };
    }
    default:
        return state;
    }
};

export default PurchaseOrderReducer;

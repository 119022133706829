import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card as CardMUI, CardHeader as CardHeaderMUI, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: theme.customShadows.z16,
        borderRadius: 5,
        position: 'relative',
        zIndex: 0,
    },
    header: {
        padding: theme.spacing(3, 3, 0),
    },
    body: {
        padding: theme.spacing(3, 3, 1),
    },
}));

const Card = (props) => {
    const classes = useStyles();
    const { className } = props;
    return (<CardMUI {...props} className={clsx(classes.card, className)} />);
};

Card.propTypes = {
    props: PropTypes.object,
    className: PropTypes.string,
};

Card.defaultProps = {
    props: {},
    className: '',
};

const CardHeader = (props) => {
    const classes = useStyles();
    const { className } = props;
    return (<CardHeaderMUI {...props} className={clsx(classes.header, className)} />);
};

CardHeader.propTypes = {
    props: PropTypes.object,
    className: PropTypes.string,
};

CardHeader.defaultProps = {
    props: {},
    className: '',
};

export { Card, CardHeader };

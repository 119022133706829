import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import {
    makeStyles, AppBar, Toolbar,
} from '@material-ui/core';
import {
    findIndex, orderBy, concat,
} from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import MailItem from 'components/modules/crm/mail/MailItem';
import FilterDropdown from 'components/modules/crm/mail/FilterDropdown';
// http
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useSubscription } from '@apollo/client';
import MailQuery from 'services/graphQL/query/crm/MailQuery';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import MailSubscription from 'services/graphQL/subscription/crm/MailSubscription';

const useStyles = makeStyles(() => ({
    body: {
        overflow: 'auto',
        flex: 1,
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#fff',
        borderBottom: '1px solid #eeeeee',
        zIndex: 2,
    },
    dropDownMenu: {
        color: '#000000',
    },
    dropDownText: {
        fontSize: 'small',
    },
}));

const initState = {
    records: [],
    start: 0,
    totalCount: 0,
    status: null,
};

const ACTION_TYPES = {
    LOAD_MORE: 'loadMore',
    SET_START: 'setStart',
    ADD_RECORD: 'addRecord',
    RECORD_UPDATED: 'recordUpdated',
    FILTER_LIST: 'filterList',
};

const reducer = (state, action) => {
    const index = findIndex(state.records, { threadId: action.payload?.threadId });
    switch (action.type) {
    case ACTION_TYPES.LOAD_MORE:
        const newRecords = concat(state.records, action.payload.data);
        return update(state, {
            records: { $set: newRecords },
            totalCount: { $set: action.payload.total },
        });
    case ACTION_TYPES.SET_START:
        return update(state, {
            start: { $set: action.payload },
        });
    case ACTION_TYPES.ADD_RECORD:
        if (index > -1) {
            const result = orderBy(update(state.records, {
                [index]: {
                    lastBody: { $set: action.payload.bodyPlain },
                    lastStatus: { $set: action.payload.status },
                    lastIncoming: { $set: action.payload.incoming },
                    createdOn: { $set: action.payload.createdOn },
                },
            }), ['createdOn'], ['desc']);

            return { ...state, records: result };
        }
        return update(state, {
            records: { $unshift: [action.payload] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPES.RECORD_UPDATED:
        if (index > -1) {
            return update(state, {
                records: {
                    [index]: {
                        lastStatus: { $set: action.payload.status },
                    },
                },
            });
        }

        return state;
    case ACTION_TYPES.FILTER_LIST:
        if (state.status !== action.status) {
            return update(state, {
                status: { $set: action.status },
                start: { $set: 0 },
                records: { $set: [] },
                totalCount: { $set: 0 },
            });
        }

        return state;
    default:
        return state;
    }
};

const MailList = ({
    onOpen,
    category,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const input = { limit: 50, start: state.start };
    const filters = { category, status: state.status };
    const statuses = [{ value: null, text: 'All' }, { value: 'Read', text: 'Read' }, { value: 'Received', text: 'Unread' }];

    const {
        loading, data, error,
    } = useQuery(MailQuery.GET_USER_EMAILS, { variables: { input, filters }, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const { data: subscriptionData } = useSubscription(MailSubscription.CHANGED_USER_EMAIL_LIST);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            dispatch({
                type: ACTION_TYPES.LOAD_MORE,
                payload: data.getUserEmails,
            });
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (subscriptionData) {
            const { changedUserEmailList } = subscriptionData;
            if (changedUserEmailList?.type?.toUpperCase() === SubscriptionActionType.NEW) {
                dispatch({
                    type: ACTION_TYPES.ADD_RECORD,
                    payload: changedUserEmailList.email,
                });
            }

            if (changedUserEmailList?.type?.toUpperCase() === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPES.RECORD_UPDATED,
                    payload: changedUserEmailList.email,
                });
            }
        }
    }, [subscriptionData]);

    const loadMore = () => {
        const start = state.records.length;
        dispatch({
            type: ACTION_TYPES.SET_START,
            payload: start,
        });
    };

    const filterList = (value) => {
        dispatch({
            type: ACTION_TYPES.FILTER_LIST,
            status: value,
        });
    };

    return (
        <>
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar style={{ alignSelf: 'end' }}>
                    <FilterDropdown options={statuses} value={state.status} onSelect={(item) => filterList(item.value)} />
                </Toolbar>
            </AppBar>
            <div className={classes.body}>
                <InfiniteScroll
                    load={loading}
                    loadMore={loadMore}
                    loadAtScrollPercent={70}
                    totalRecord={state?.totalCount}
                    lengthRecord={state?.records.length}
                >
                    {state.records.map((item, index) => <MailItem key={index} record={item} onOpen={onOpen} />)}
                </InfiniteScroll>
            </div>
        </>
    );
};

MailList.propTypes = {
    onOpen: PropTypes.func.isRequired,
    category: PropTypes.string,
};

MailList.defaultProps = {
    category: null,
};

export default MailList;

/* eslint-disable no-restricted-globals */
import { DataSort } from 'utils/enum/Core';

const useSortingActions = () => {
    const sortDataByColumn = (column, direction, data) => data.sort((a, b) => {
        const compareFrom = a[column];
        const compareTo = b[column];
        const isNumber = !isNaN(compareFrom);

        if (isNumber) {
            if (direction === DataSort.ASC) return Number(compareFrom) - Number(compareTo);
            if (direction === DataSort.DESC) return Number(compareTo) - Number(compareFrom);
        } else {
            if (direction === DataSort.ASC) return String(compareFrom).toLowerCase().localeCompare(String(compareTo).toLowerCase());
            if (direction === DataSort.DESC) return String(compareTo).toLowerCase().localeCompare(String(compareFrom).toLowerCase());
        }

        return data;
    });

    return {
        sortDataByColumn,
    };
};

export default useSortingActions;

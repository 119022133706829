import { gql } from '@apollo/client';

export default class OpportunityTagQuery {
    static GET_OPPORTUNITY_TAG_BY_SEARCH_TERM = gql`
        query getOpportunityTagBySearchTerm($searchTerm: String!) {
            getOpportunityTagBySearchTerm(searchTerm: $searchTerm) {
                totalCount
                data {
                    tagId
                    name
                    description
                    module
                    priority
                }
            }
        }
    `;

    static GET_CRM_TAG_BY_OPTION = gql`
        query getCRMTagByOption($paginate: DataPaging, $active: Boolean) {
            getCRMTagByOption(paginate: $paginate, active: $active) {
                totalCount
                data {
                    tagId
                    name
                    color
                    description
                    module
                    priority
                }
            }
        }
    `;
}

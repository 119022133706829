import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import KeyStore from 'utils/KeyStore';
import client from 'services/apollo/ApolloClient';
import UserMutation from 'services/graphQL/mutate/core/UserMutation';
import ModalUtils from 'utils/ModalUtils';

const useLogOut = () => {
    const keyStore = new KeyStore();
    const history = useHistory();
    const [onLogout, { loading: executingLogOut }] = useMutation(UserMutation.LOGOUT);

    const hideChat = () => {
        const chat = window.LiveChatWidget;
        if (chat) {
            chat.call('hide');
        }
    };

    const logOut = async () => {
        try {
            hideChat();

            await onLogout();
            client.resetStore();
            keyStore.clear();
            history.push('/login');
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    return {
        logOut,
        executingLogOut,
    };
};

export default useLogOut;

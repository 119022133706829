import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import PayrollSettingsQuery from 'services/graphQL/query/payroll/Settings';

const useCustomTaxFields = () => {
    const [state, setState] = useState({
        userTax: '',
        stateTax: '',
        userTaxName: '',
    });
    const keyStore = new KeyStore();
    const {
        data, loading, error,
    } = useQuery(PayrollSettingsQuery.GET_PAYROLL_DEFAULT_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            lotName: keyStore.getSelectedLot()?.lotName,
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getPayrollDefaultSettings)) {
            const info = data.getPayrollDefaultSettings;
            setState({
                userTax: info.userTax,
                stateTax: info.stateTax,
                userTaxName: info.userTaxName,
            });
        }
    }, [data, loading, error]);

    return {
        displayUserTax: !isEmpty(state.userTax),
        displayStateTax: !isEmpty(state.stateTax),
        userTaxName: isEmpty(state.userTaxName) ? 'Custom Tax:' : `${state.userTaxName}:`,
    };
};

export default useCustomTaxFields;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DealsGeneralSettings from 'components/modules/settings/deals/DealsGeneralSettings';
import DealsIntegrationSettings from 'components/modules/settings/deals/DealsIntegrationSettings';
import DealsMiscellaneaSettings from 'components/modules/settings/deals/DealsMiscellaneaSettings';
import DealsUserTabSettings from 'components/modules/settings/deals/DealsUserTabSettings';
import ProductSettingsList from 'components/modules/settings/deals/ProductSettingsList';

// icons
import AppsIcon from '@material-ui/icons/Apps';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import TocIcon from '@material-ui/icons/Toc';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['default', 'integration', 'user-tab', 'miscellaneous', 'products'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];
    const DEALS_SETTINGS_WRITE = keyStore.hasPermission(Permission.DEALS_SETTINGS_WRITE);

    if (keyStore.hasPermission(Permission.DEALS_SETTINGS_READ)) {
        settings.push(
            {
                index: 0,
                text: 'General',
                icon: <AppsIcon />,
                component: <DealsGeneralSettings writable={DEALS_SETTINGS_WRITE} />,
            },
            {
                index: 1,
                text: 'Integration',
                icon: <SettingsEthernetIcon />,
                component: <DealsIntegrationSettings writable={DEALS_SETTINGS_WRITE} />,
            },
            {
                index: 2,
                text: 'User Tab',
                icon: <TocIcon />,
                component: <DealsUserTabSettings writable={DEALS_SETTINGS_WRITE} />,
            },
            {
                index: 3,
                text: 'Miscellaneous',
                icon: <FormatAlignJustifyIcon />,
                component: <DealsMiscellaneaSettings writable={DEALS_SETTINGS_WRITE} />,
            },
            {
                index: 4,
                text: 'Products',
                icon: <AppsIcon />,
                component: <ProductSettingsList />,
            },
        );
    }

    return settings;
};

const DealsSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <>
            <Container className={classes.rootContainer}>
                <List component="nav" className={classes.sideBar}>
                    {settingsItems.map((item) => (
                        <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.content}>
                    {settingsItems.map((item) => (
                        activeIndex === item.index && <div key={item.index}>{item.component}</div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default DealsSettings;

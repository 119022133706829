import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, useTheme, useMediaQuery, Tooltip, Chip,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import DateUtils from 'lib/DateUtils';
import { LocalAtmIcon } from 'components/icons';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
import CashierPaymentList from 'components/modules/accounting/accountsReceivable/list/CashierPaymentList';
import LabelValue from 'components/widgets/LabelValue';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router-dom';
import NumberUtils from 'lib/NumberUtils';
import { AccountingSubModules, AccountsReceivableEntities } from 'utils/enum/AccountingEnum';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const baseRoute = `/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${AccountsReceivableEntities.SERVICE}`;
const ACTION_TYPES = {
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            isEditing: false,
            openTakePayment: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
            selectedRow: null,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
            openTakePayment: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default: return state;
    }
};

const keyStore = new KeyStore();

const AccountReceivableServiceList = () => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 25,
            },
            search: null,
            includeBalanced: false,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: null,
        openTakePayment: false,
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow,
    } = state;

    const {
        data, loading, error,
    } = useQuery(AccountReceivableQuery.GET_UNPAID_SERVICE_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                includeBalanced: params.includeBalanced,
            },
            sort: [{ fieldName: 'invoiceNumber', dir: DataSort.DESC }],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getUnpaidServiceInvoiceList } = data;

            const mapRecords = AccountReceivablesMapper.mapServices(getUnpaidServiceInvoiceList.data);

            records.push(...mapRecords);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getUnpaidServiceInvoiceList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onFilterChange = (param, value) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Invoice #',
            minWidth: 100,
            width: 100,
            id: 'InvoiceNumber',
            accessor: 'invoiceNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
        },
        {
            Header: 'Customer',
            minWidth: 250,
            width: 250,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Advisor',
            minWidth: 250,
            width: 250,
            id: 'Advisor',
            accessor: 'advisor',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Ro Closed',
            minWidth: 100,
            width: 100,
            id: 'RoClosed',
            accessor: 'roClosed',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.format(value),
        },

        {
            Header: 'Vehicle',
            minWidth: 250,
            id: 'Vehicle',
            accessor: 'vehicle',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Balance Due',
            minWidth: 100,
            width: 100,
            id: 'BalanceDue',
            accessor: 'balanceDue',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Status',
            minWidth: 120,
            width: 120,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status?.toLowerCase() === ServiceInvoiceStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
    ];

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.containerFilterSearch}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <Form.Group controlId="formBasicCheckbox" className={classes.alignContent}>
                        <Form.Check
                            checked={params.includeBalanced}
                            type="checkbox"
                            label="Include paid accounts"
                            className={classes.labelFont}
                            onChange={(e) => onFilterChange('includeBalanced', e.target.checked)}
                        />
                    </Form.Group>
                    <LabelValue
                        label="Items"
                        value={table.totalCount}
                    />
                </div>
                <div className={classes.buttonSpacing}>
                    {ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS && (
                        <Tooltip title="Take Payment" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => {
                                        dispatch({ type: ACTION_TYPES.SET_NEW });
                                        history.push(`${baseRoute}/${selectedRow?.invoiceNumber}/payment`);
                                    }}
                                    variant="outlined"
                                    startIcon={<LocalAtmIcon />}
                                    disabled={loading || !selectedRow}
                                    size="small"
                                >
                                    Take Payment
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </div>
            </Header>
            <Container className={classes.containerSplit}>
                <Split
                    sizes={selectedRow && selectedRow.invoiceNumber ? [60, 40] : [100, 0]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        data={table?.records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={table?.totalCount}
                        loadMore={loadMore}
                        rowSelected
                        className="-highlight"
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;
                            return {
                                onDoubleClick: () => {
                                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: record });
                                },
                                onClick: () => {
                                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: record });
                                },
                            };
                        }}
                    />
                    <div>
                        {selectedRow && selectedRow.invoiceNumber && (
                            <CashierPaymentList
                                accountNumber={selectedRow.invoiceNumber}
                            />
                        )}
                    </div>
                </Split>
            </Container>
        </div>
    );
};

export default AccountReceivableServiceList;

/* eslint-disable react/prop-types */
import React, { useReducer, useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import useAxios from 'axios-hooks';
import update from 'immutability-helper';

import {
    makeStyles,
    FormLabel,
    Button,
    ButtonGroup,
    Hidden,
    Typography,
    Grid,
    DialogContent,
    Dialog,
} from '@material-ui/core';
import clsx from 'clsx';
import {
    AddCircleOutline as AddCircleOutlineIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import Table from 'components/widgets/Table';
import RepairsExpensesForm from 'components/modules/inventory/create/dialogs/RepairsExpensesForm';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LotsCategory from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import MapInventoryData from 'services/mapData/MapInventoryData';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import PDFUtils from 'utils/PDFUtils';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import StringUtils from 'lib/StringUtils';
import HttpClient from 'services/api/HttpClient';
import ButtonStyles from 'styles/theme/Button';
import { HttpMethods, FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import CardTable from 'components/widgets/CardTable';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import If from 'components/widgets/conditional/If';
import Loading from 'components/widgets/Loading';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '20px 10px',
        height: '85vh',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 'initial !important',
            '& > div:nth-child(1), & > div:nth-child(2)': {
                marginLeft: '5px',
            },
        },
    },
    tableContent: {
        flex: 1,
        overflow: 'auto',
        '& .rt-thead, & .rt-tbody': {
            minWidth: '1200px !important',
        },
    },
    centerColumns: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        whiteSpace: 'break-spaces !important',
    },
    footer: {
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    button: {
        marginBottom: 10,
    },
    footerItem: {
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: '40px',
        '& > label': {
            fontWeight: 'bold',
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    iframe: {
        minHeight: '83vh',
        height: '100%',
        width: '100%',
    },
    loading: {
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999,
        opacity: '0.4',
        background: theme.palette.background.white,
    },
    preview: {
        cursor: 'pointer',
        width: '30px',
        height: '30px',
        fill: theme.palette.primary.main,
    },
    ...ButtonStyles.getStyle(theme),
}));
const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    ON_CLOSE_DIALOG: 'onCloseDialog',
    SET_EDITING_MODE: 'setEditingMode',
    SET_CREATE_MODE: 'setCreateMode',
    ON_CLOSE_CONFIM_DIALOG: 'onCloseConfimDialog',
    ON_DELETE: 'onDelete',
    TOGGLE_SERVICE_INVOICE_DIALOG: 'toggleServiceInvoiceDialog',
    TOGGLE_IS_GENERATING_SERVICE_INVOICE: 'toggleIsGeneratingServiceInvoice',
    SET_ENABLE_REPAIRS: 'setEnableRepairs',
};
const initState = {
    isGeneratingServiceInvoice: false,
    isServiceInvoiceDialogOpen: false,
    openDialog: false,
    openConfirmDialog: false,
    isEditing: false,
    enableRepairs: false,
    selectedRecord: {},
    pdf: null,
    records: [],
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_ENABLE_REPAIRS:
        return update(state, {
            enableRepairs: { $set: action.payload },
        });
    case ACTION_TYPES.SET_RECORDS:
        return update(state, {
            records: { $set: action.payload },
        });
    case ACTION_TYPES.ON_CLOSE_DIALOG:
        return update(state, {
            selectedRecord: { $set: {} },
            isEditing: { $set: false },
            openDialog: { $set: false },
        });
    case ACTION_TYPES.SET_EDITING_MODE:
        return update(state, {
            selectedRecord: { $set: action.payload },
            isEditing: { $set: true },
            openDialog: { $set: true },
        });
    case ACTION_TYPES.SET_CREATE_MODE:
        return update(state, {
            openDialog: { $set: true },
        });
    case ACTION_TYPES.ON_CLOSE_CONFIM_DIALOG:
        return update(state, {
            openConfirmDialog: { $set: false },
            selectedRecord: { $set: {} },
        });
    case ACTION_TYPES.ON_DELETE:
        return update(state, {
            openConfirmDialog: { $set: true },
            selectedRecord: { $set: action.payload },
        });
    case ACTION_TYPES.TOGGLE_SERVICE_INVOICE_DIALOG:
        const { selectedRecord, pdf } = action.payload;

        return update(state, {
            isServiceInvoiceDialogOpen: { $set: !state.isServiceInvoiceDialogOpen },
            isGeneratingServiceInvoice: { $set: false },
            selectedRecord: { $set: selectedRecord },
            pdf: { $set: pdf },
        });
    case ACTION_TYPES.TOGGLE_IS_GENERATING_SERVICE_INVOICE:
        return update(state, {
            isGeneratingServiceInvoice: { $set: !state.isGeneratingServiceInvoice },
        });
    default:
        return initState;
    }
};
const RepairsExpensesTab = ({ stockNumber, vehicleData, setRepairs }) => {
    const client = useApolloClient();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [data, dispatchData] = useReducer(reducer, initState);
    const [{ data: response, loading, error }, reFetch] = useAxios({
        url: HttpClient.getURLRepairList(stockNumber),
    });
    const [
        { loading: loadingDelete },
        executeDelete,
    ] = useAxios({
        url: HttpClient.getURLRepairDelete(stockNumber, data?.selectedRecord?.inventoryRepairId),
        method: HttpMethods.DELETE,
    }, { manual: true });

    if (error) {
        ModalUtils.errorMessage(null, MessageUtils.getGenericError('get', 'repairs'));
    }

    const { REPAIRS_MANAGEMENT_ENABLE } = InventoryCategory;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [REPAIRS_MANAGEMENT_ENABLE],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loading && response?.Success) {
            const records = [];
            const serviceResponse = response?.Data || [];

            serviceResponse.forEach((item) => {
                records.push(MapInventoryData.mapRepairs(item));
            });

            dispatchData({
                type: ACTION_TYPES.SET_RECORDS,
                payload: records,
            });
        }
    }, [response, loading]);

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading) {
            const settings = settingsData?.getSettings;
            if (settings) {
                const savedValue = settings.find((setting) => setting.key === REPAIRS_MANAGEMENT_ENABLE)?.value ?? 'false';
                dispatchData({
                    type: ACTION_TYPES.SET_ENABLE_REPAIRS,
                    payload: savedValue === 'true',
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_COST_READ = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_COST_READ);
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const CORE_VENDOR_READ = keyStore.hasPermission(Permission.CORE_VENDOR_READ);
    const ALLOW_WRITE = CORE_VENDOR_READ && INVENTORY_VEHICLE_WRITE;

    const onClose = () => {
        dispatchData({
            type: ACTION_TYPES.ON_CLOSE_DIALOG,
        });
    };
    const onCloseConfirmDialog = () => {
        dispatchData({
            type: ACTION_TYPES.ON_CLOSE_CONFIM_DIALOG,
        });
    };
    const onReload = (repairs) => {
        reFetch();
        setRepairs(repairs);
    };
    const createRecord = () => {
        dispatchData({
            type: ACTION_TYPES.SET_CREATE_MODE,
        });
    };
    const editRecord = (record) => {
        dispatchData({
            type: ACTION_TYPES.SET_EDITING_MODE,
            payload: record,
        });
    };
    const deleteRecord = async () => {
        if (INVENTORY_VEHICLE_WRITE && data?.selectedRecord?.inventoryRepairId) {
            try {
                const deleteResponse = await executeDelete();

                if (deleteResponse?.data?.Success) {
                    ModalUtils.successMessage(null, 'Record deleted successfully!');

                    const { repairs = 0 } = vehicleData;
                    const { amount = 0 } = data?.selectedRecord || {};
                    const newTotal = repairs - amount;

                    onReload(newTotal);
                    onCloseConfirmDialog();
                } else {
                    ModalUtils.errorMessage(deleteResponse?.data?.Messages, deleteResponse?.data?.Message);
                }
            } catch (e) {
                ModalUtils.errorMessage(null, 'There was an error trying to delete this record.');
            }
        }
    };
    const onDelete = (record) => {
        dispatchData({
            type: ACTION_TYPES.ON_DELETE,
            payload: record,
        });
    };
    const actions = (props) => {
        const { value: record } = props;

        return (
            <ButtonGroup variant="contained" disabled={loadingDelete}>
                <Button
                    color="primary"
                    onClick={() => editRecord(record)}
                >
                    <EditIcon fontSize="small" />
                </Button>
                <Button
                    color="secondary"
                    className={classes.containedError}
                    onClick={() => onDelete(record)}
                >
                    <DeleteIcon fontSize="small" />
                </Button>
            </ButtonGroup>
        );
    };

    const isInvoicePrintingDisable = (comment) => {
        const matcher = 'invoice # : ';
        return (StringUtils.isEmpty(comment) || !comment.toLowerCase().startsWith(matcher));
    };

    const previewServiceInvoice = async (record) => {
        const { inventoryRepairId, comment } = record;
        if (isInvoicePrintingDisable(comment)) return;

        try {
            dispatchData({
                type: ACTION_TYPES.TOGGLE_IS_GENERATING_SERVICE_INVOICE,
            });

            const { data: result } = await client.mutate({
                mutation: InventoryMutation.PRINT_SERVICE_INVOICE,
                variables: { inventoryRepairId },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const signedURL = result?.printServiceInvoice;
            if (signedURL) {
                const pdf = await PDFUtils.convertPDFToBlobURL(signedURL);
                dispatchData({
                    type: ACTION_TYPES.TOGGLE_SERVICE_INVOICE_DIALOG,
                    payload: {
                        selectedRecord: record,
                        pdf,
                    },
                });
            }
        } catch (err) {
            ModalUtils.errorMessage(null, err.message);
        }
    };

    const closeServiceInvoiceDialog = () => {
        dispatchData({
            type: ACTION_TYPES.TOGGLE_SERVICE_INVOICE_DIALOG,
            payload: {
                selectedRecord: {},
                pdf: null,
            },
        });
    };

    const renderIframe = (url = '') => (
        <iframe
            className={classes.iframe}
            title="Service Invoice"
            src={url}
            frameBorder="0"
            allowFullScreen
        />
    );

    const getItem = (label, value, display = true) => (
        display && (
            <div className={classes.footerItem}>
                <FormLabel>
                    {`${label}: `}
                    <span>{NumberUtils.applyCurrencyFormat(value)}</span>
                </FormLabel>
            </div>
        )
    );
    const getColumns = () => {
        const columns = isMobile ? [
            {
                Header: 'Category',
                accessor: 'categoryName',
                minWidth: 50,
                className: classes.centerColumns,
            },
            {
                Header: 'Date Paid',
                accessor: 'datePaid',
                minWidth: 50,
                className: classes.centerColumns,
                Cell: (props) => DateUtils.format(props.value),
            },
            {
                Header: 'Vendor',
                accessor: 'vendorName',
                className: classes.centerColumns,
            },
        ] : [
            {
                Header: 'Preview',
                accessor: (record) => record,
                id: 'preview',
                sortable: false,
                minWidth: 30,
                className: classes.centerColumns,
                headerClassName: 'no-sort',
                Cell: ({ value: record }) => {
                    const { comment } = record;
                    if (isInvoicePrintingDisable(comment)) {
                        return null;
                    }

                    return (
                        <FindInPageIcon
                            className={classes.preview}
                            onClick={() => (ALLOW_WRITE
                                ? previewServiceInvoice(record)
                                : null)}
                        />
                    );
                },
            },
            {
                Header: 'Check #',
                accessor: 'checkNo',
                minWidth: 45,
                className: classes.centerColumns,
            },
            {
                Header: 'Category',
                accessor: 'categoryName',
                minWidth: 50,
                className: classes.centerColumns,
            },
            {
                Header: 'Date Paid',
                accessor: 'datePaid',
                minWidth: 50,
                className: classes.centerColumns,
                Cell: (props) => DateUtils.format(props.value),
            },
            {
                Header: 'Vendor',
                accessor: 'vendorName',
                className: classes.centerColumns,
            },
            {
                Header: 'Memo',
                accessor: 'memo',
                className: classes.centerColumns,
            },
            {
                Header: 'Details',
                accessor: 'comment',
                className: classes.centerColumns,
            },
        ];
        if (INVENTORY_VEHICLE_COST_READ) {
            columns.push({
                Header: 'Amt. Paid',
                accessor: 'amount',
                minWidth: 95,
                maxWidth: 95,
                className: classes.centerColumns,
                Cell: (props) => NumberUtils.applyCurrencyFormat(props.value),
            });
        }
        if (ALLOW_WRITE && data.enableRepairs) {
            columns.push({
                Header: 'Action',
                accessor: (record) => record,
                sortable: false,
                id: 'action',
                minWidth: 115,
                maxWidth: 115,
                className: classes.centerColumns,
                headerClassName: 'no-sort',
                Cell: (props) => actions(props),
            });
        }

        return columns;
    };
    const {
        purchasedPrice = 0,
        repairs = 0,
        cost = 0,
    } = vehicleData;

    return (
        <div className={clsx('d-flex-column', classes.main)}>
            <Hidden mdUp>
                <div className={classes.tabTitle}>
                    <Typography variant="h3" gutterBottom>
                        Repair &amp; Expenses
                    </Typography>
                </div>
            </Hidden>
            {ALLOW_WRITE && data.enableRepairs && (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={createRecord}
                    >
                        Add
                    </Button>
                </div>
            )}
            <div className={classes.tableContent}>
                {isMobile && (
                    <CardTable
                        data={data.records}
                        columns={getColumns()}
                    />
                )}
                {!isMobile && (
                    <Table
                        loading={loading}
                        columns={getColumns()}
                        data={data.records}
                        rowSelected
                        minHeightRow={3}
                    />
                )}
            </div>
            <Grid container className={classes.footer}>
                {getItem('Purchased Price', purchasedPrice, INVENTORY_VEHICLE_COST_READ)}
                {getItem('Repairs', repairs)}
                {getItem('Total cost', cost, INVENTORY_VEHICLE_COST_READ)}
            </Grid>
            {data.openDialog && (
                <RepairsExpensesForm
                    stockNumber={stockNumber}
                    isEditing={data.isEditing}
                    openDialog={data.openDialog}
                    onClose={onClose}
                    record={data.selectedRecord}
                    onReload={onReload}
                    totalRepairs={repairs}
                />
            )}
            <If condition={data.isGeneratingServiceInvoice}>
                <Loading className={classes.loading} />
            </If>
            <Dialog
                open={data.isServiceInvoiceDialogOpen}
                fullWidth
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Service Invoice"
                    onClose={closeServiceInvoiceDialog}
                    toolbarSize="sm"
                />
                <DialogContent>
                    {renderIframe(data.pdf)}
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                title="Confirm remove repair"
                description="Are you sure you want to remove this repair?"
                open={data.openConfirmDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="No"
                onClose={onCloseConfirmDialog}
                onClickSecondary={onCloseConfirmDialog}
                onClickPrimary={deleteRecord}
                disablePrimaryButton={loadingDelete}
            />
        </div>
    );
};

RepairsExpensesTab.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    setRepairs: PropTypes.func.isRequired,
    vehicleData: PropTypes.object,
};

RepairsExpensesTab.defaultProps = {
    vehicleData: {},
};

export default RepairsExpensesTab;

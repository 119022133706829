import { gql } from '@apollo/client';

export default class PayrollAdjustmentsMutation {
    static REMOVE_PAYROLL_ADJUSTMENT = gql`
        mutation removePayrollAdjustment($payrollAdditionId: Int, $payrollDeductionsMappingId: String) {
            removePayrollAdjustment(
                payrollAdditionId: $payrollAdditionId,
                payrollDeductionsMappingId: $payrollDeductionsMappingId
            )
        }
    `;

    static SAVE_PAYROLL_ADDITION = gql`
        mutation savePayrollAddition($input: PayrollAdditionInput!) {
            savePayrollAddition(input: $input)
        }
    `;

    static SAVE_PAYROLL_DEDUCTION = gql`
        mutation savePayrollDeduction($input: PayrollAdditionInput!) {
            savePayrollDeduction(input: $input)
        }
    `;
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useReducer } from 'react';

import update from 'immutability-helper';
import {
    makeStyles, Grid, Button,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import { Form, Col } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import { useQuery, useMutation } from '@apollo/client';
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';
import { FetchPolicy } from 'utils/enum/Core';
import ButtonStyles from 'styles/theme/Button';
import Permissions from 'utils/enum/Permissions';
import UserSettingQuery from 'services/graphQL/query/setting/UserSettingQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import LotsCategory from 'utils/enum/LotsCategory';
import { isEmpty, clone } from 'lodash';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    list: {
        border: '1px solid #ced4da',
        borderRadius: '10px',
        padding: '5px',
        color: '#000000a6',
        fontSize: '15px',
        overflow: 'scroll',
        height: '450px',
    },
    option: {
        listStyle: 'none',
        cursor: 'pointer',
        padding: '3px',
    },
    optionSelected: {
        listStyle: 'none',
        cursor: 'pointer',
        padding: '3px',
        backgroundColor: '#ebebeb',
    },
    ...ButtonStyles.getStyle(theme),
}));
const keyStore = new KeyStore();
const ACTION_TYPE = {
    SET_EMPLOYEE_TYPES: 'setEmployeeRecords',
    SET_INCLUDED_TYPES: 'setIncludedRecords',
    ON_CHOOSE_RECORD: 'onChooseRecord',
};
const TYPES = {
    AVAILABLES: 'availables',
    INCLUDED: 'included',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_EMPLOYEE_TYPES:
        return update(state, {
            employeeTypes: { $set: action.payload },
        });
    case ACTION_TYPE.SET_INCLUDED_TYPES:
        let payloadData = [];
        const payload = action.payload || [];
        if (payload.length > 0) {
            payloadData = (payload[0].value || '').split(',').map((s) => s.trim());
        }

        return update(state, {
            includedTypes: { $set: payloadData },
        });
    case ACTION_TYPE.ON_CHOOSE_RECORD:
        return update(state, {
            recordSelected: { $set: action.payload },
        });
    default:
        return state;
    }
};

const RecordManager = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        employeeTypes: [],
        includedTypes: [],
        recordSelected: {
            currentType: '',
            currentField: '',
        },
    });
    const CRM_SETTINGS_WRITE = keyStore.hasPermission(Permissions.CRM_SETTINGS_WRITE);
    const {
        data: employeeData, error: employeeError, loading: employeeLoading, refetch,
    } = useQuery(UserSettingQuery.GET_EMPLOYEE_TYPES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!employeeLoading && employeeData?.getEmployeeTypes) {
            dispatch({
                type: ACTION_TYPE.SET_EMPLOYEE_TYPES,
                payload: employeeData.getEmployeeTypes,
            });
        }
    }, [employeeData, employeeError, employeeLoading]);

    if (employeeError) {
        ModalUtils.errorMessage(null, employeeError?.message);
    }

    const {
        data: includedData, error: includedError, loading: includedLoading, refetch: refetch2,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: 'All Lots',
            key: ['recordManager'],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveLotSettings, { loading: saveLoading }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS);

    useEffect(() => {
        if (!includedLoading && includedData?.getSettings) {
            dispatch({
                type: ACTION_TYPE.SET_INCLUDED_TYPES,
                payload: includedData.getSettings,
            });
        }
    }, [includedData, includedError, includedLoading]);

    if (includedError) {
        ModalUtils.errorMessage(null, includedError?.message);
    }

    const onClick = (currentField, currentType) => {
        if (saveLoading && employeeLoading && includedLoading) {
            return;
        }

        dispatch({
            type: ACTION_TYPE.ON_CHOOSE_RECORD,
            payload: {
                currentField,
                currentType,
            },
        });
    };

    const onSave = async () => {
        try {
            if (saveLoading && employeeLoading && includedLoading) {
                return;
            }

            const { recordSelected } = state;
            let includedTypes = clone(state.includedTypes);

            if (recordSelected.currentType === TYPES.AVAILABLES) {
                includedTypes.push(recordSelected.currentField);
            } else if (recordSelected.currentType === TYPES.INCLUDED) {
                includedTypes = includedTypes.filter((item) => item !== recordSelected.currentField);
            }

            const variables = {
                category: LotsCategory.CRM,
                key: 'recordManager',
                value: includedTypes.toString(),
                lotName: 'All Lots',
                critical: false,
            };

            await saveLotSettings({
                variables,
            });

            refetch();
            refetch2();

            // Clear selection
            onClick('', '');
        } catch (e) {
            ModalUtils.errorMessage(e?.graphQLErrors);
        }
    };

    const checkButtonStatus = (currentType) => {
        const { recordSelected } = state;

        if (!isEmpty(recordSelected) && CRM_SETTINGS_WRITE
        && !saveLoading && !employeeLoading && !includedLoading) {
            if (currentType === recordSelected.currentType) {
                return false;
            }
        }

        return true;
    };

    return (
        <Grid container className="overflow-auto">
            <Grid item xs={3} className={classes.box}>
                <div>
                    <Form.Group as={Col}>
                        <Form.Label>Available Types</Form.Label>
                        <ul className={classes.list}>
                            {(state.employeeTypes || []).map((item, index) => {
                                const includedTypes = state.includedTypes || [];

                                if (!includedTypes.includes(item.employeeType)) {
                                    const field = `${item.employeeType}`;
                                    const { recordSelected } = state;
                                    const isSelected = !isEmpty(recordSelected)
                                    && (recordSelected.currentType === TYPES.AVAILABLES && recordSelected.currentField === item.employeeType);

                                    const optionStyle = isSelected ? classes.optionSelected : classes.option;

                                    return (
                                        <li
                                            key={index}
                                            className={optionStyle}
                                            onClick={() => {
                                                onClick(
                                                    item.employeeType, TYPES.AVAILABLES,
                                                );
                                            }}
                                            onDoubleClick={onSave}
                                        >
                                            {field}
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </Form.Group>
                </div>

            </Grid>
            <Grid item xs={1}>
                <div style={{
                    paddingTop: '200px',
                }}
                >
                    <Form.Group as={Col}>
                        <Button
                            disabled={checkButtonStatus(TYPES.INCLUDED)}
                            size="small"
                            startIcon={<ArrowBackIosOutlined />}
                            onClick={onSave}
                        />
                        <Button
                            disabled={checkButtonStatus(TYPES.AVAILABLES)}
                            size="small"
                            startIcon={<ArrowForwardIosOutlined />}
                            onClick={onSave}
                        />
                    </Form.Group>
                </div>
            </Grid>
            <Grid item xs={3} className={classes.box}>
                <div>
                    <Form.Group as={Col}>
                        <Form.Label>Included Types</Form.Label>
                        <ul className={classes.list}>
                            {(state.includedTypes || []).map((item, index) => {
                                const field = `${item}`;
                                const { recordSelected } = state;
                                const isSelected = !isEmpty(recordSelected)
                                && (recordSelected.currentType === TYPES.INCLUDED && recordSelected.currentField === item);

                                const optionStyle = isSelected ? classes.optionSelected : classes.option;

                                return (
                                    <li
                                        key={index}
                                        className={optionStyle}
                                        onClick={() => {
                                            onClick(
                                                item, TYPES.INCLUDED,
                                            );
                                        }}
                                        onDoubleClick={onSave}
                                    >
                                        {field}
                                    </li>
                                );
                            })}
                        </ul>
                    </Form.Group>
                </div>
            </Grid>
        </Grid>
    );
};

export default RecordManager;

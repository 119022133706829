import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import { withRouter } from 'react-router-dom';
import ReferenceTabContainer from 'components/containers/deals/read/buyer/ReferenceTabContainer';
import ReferenceDialog from 'components/modules/deals/read/buyer/ReferenceDialog';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import DealStyles from 'styles/modules/DealStyles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => DealStyles.referenceStyles(theme);

class ReferenceTab extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_CUSTOMER_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_WRITE);
    }

    getColumns() {
        const { props: { classes } } = this;

        return [{
            Header: 'First Name',
            id: 'firstName',
            minWidth: 100,
            accessor: 'firstName',
            className: classes.columnStyle,
        }, {
            Header: 'Last Name',
            id: 'lastName',
            minWidth: 100,
            accessor: 'lastName',
            className: classes.columnStyle,
        }, {
            Header: 'Relation',
            id: 'relation',
            minWidth: 100,
            accessor: 'relation',
            className: classes.columnStyle,
        }, {
            Header: 'Cell Phone',
            id: 'phoneNo',
            minWidth: 100,
            accessor: 'phoneNo',
            className: classes.columnStyle,
        }, {
            Header: 'Email',
            id: 'email',
            minWidth: 100,
            accessor: 'email',
            className: classes.columnStyle,
        }];
    }

    render() {
        const {
            props: {
                classes, openDialog, openReferenceDialog, closeReferenceDialog,
                isEditingReference, onSave, selectedReferenceId, openConfirmDialog,
                deleteReference, closeConfirmDialog, openConfirmationDialog,
                references, loading, setSelectedReferenceId,
            },
        } = this;
        const totalRecords = references.length;
        const disableEditAndRemove = selectedReferenceId === null;

        return (
            <div className={classes.root}>
                {this.SALES_DEAL_CUSTOMER_WRITE && (
                    <div className={classes.header}>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.newButton)}
                            startIcon={<AddIcon />}
                            onClick={() => openReferenceDialog(false)}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.editButton)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={disableEditAndRemove}
                            onClick={() => openReferenceDialog(true)}
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.removeButton)}
                            disabled={disableEditAndRemove}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => openConfirmDialog()}
                        >
                            Delete
                        </Button>
                    </div>
                )}
                <Table
                    rowSelected
                    load={loading}
                    totalRecords={totalRecords}
                    data={references}
                    columns={this.getColumns()}
                    unselectRow={disableEditAndRemove}
                    getTrProps={(state, rowInfo) => {
                        const record = rowInfo.original;

                        return {
                            onClick: () => {
                                setSelectedReferenceId(record.dealReferenceId);
                            },
                        };
                    }}
                />
                {openDialog
                    && (
                        <ReferenceDialog
                            open={openDialog}
                            isEditing={isEditingReference}
                            onClose={closeReferenceDialog}
                            dealReferenceId={selectedReferenceId}
                            onSave={onSave}
                        />
                    )}
                {openConfirmationDialog
                    && (
                        <ConfirmDialog
                            title="Confirm delete reference"
                            description="Are you sure you want to remove this reference?"
                            open={openConfirmationDialog}
                            showHeader
                            titlePrimary="Yes"
                            titleSecondary="No"
                            variant="outlined"
                            dividerFooter={false}
                            onClickPrimary={() => {
                                deleteReference();
                                closeConfirmDialog();
                            }}
                            onClose={closeConfirmDialog}
                            onClickSecondary={closeConfirmDialog}
                        />
                    )}
            </div>
        );
    }
}

ReferenceTab.propTypes = {
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    openReferenceDialog: PropTypes.func.isRequired,
    closeReferenceDialog: PropTypes.func.isRequired,
    deleteReference: PropTypes.func.isRequired,
    openConfirmDialog: PropTypes.func.isRequired,
    closeConfirmDialog: PropTypes.func.isRequired,
    setSelectedReferenceId: PropTypes.func.isRequired,
    isEditingReference: PropTypes.bool.isRequired,
    openConfirmationDialog: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedReferenceId: PropTypes.number,
    references: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
};

ReferenceTab.defaultProps = {
    selectedReferenceId: null,
};

export default withRouter(withStyles(styles)(ReferenceTabContainer(ReferenceTab)));

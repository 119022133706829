/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

const LotSettingSchema = yup.object().shape({
    lotName: yup.string().required(),
    lotAddress: yup.string().required(),
    lotState: yup.string().required(),
    lotCity: yup.string().required(),
    lotZip: yup.string().required(),
    lotPhone: yup.string().required(),
    dealerFee: yup.number().required(),
    tagAgencyFee: yup.number().required(),
    registrationFee: yup.number().required(),
    titleFee: yup.number().required(),
    packCost: yup.number().required(),
});

export {
    LotSettingSchema,
};

import { useEffect } from 'react';

const useIntervalExecutor = (func, intervalInSeconds, callback) => {
    const milliseconds = intervalInSeconds * 1000;

    const executeFunction = () => {
        const result = func();
        if (callback) callback(result);
    };

    useEffect(() => {
        // Execute function the very first time
        executeFunction();

        const interval = setInterval(
            () => executeFunction(),
            milliseconds,
        );

        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [func]);
};

export default useIntervalExecutor;

import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';

// Utils
import Permission from 'utils/enum/Permissions';
import DateUtils from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';

// GraphQL
import CustomerService from 'services/modules/CustomerService';

const CustomerCreateContainer = (WrappedComponent) => class extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.object.isRequired,
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.customerService = new CustomerService();

        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            dob: null,
            ssn: '',
            dln: '',
            email: '',
            cellPhone: '',
            workPhone: '',
            homePhone: '',
            isBusiness: false,
            isTaxable: true,
            language: 'English',
        };

        this.initBind();
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    onSave() {
        const keyStore = new KeyStore();
        const CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);

        if (CUSTOMER_WRITE) {
            const { props: { history }, state } = this;
            const input = {
                ...state,
                firstName: state.firstName.trim(),
                middleName: state.isBusiness ? '' : state.middleName.trim(),
                lastName: state.isBusiness ? '' : state.lastName.trim(),
                dln: state.dln.trim(),
                dob: DateUtils.getOnlyDate(state.dob),
                email: !StringUtils.isEmpty(state.email) ? state.email : null,
                isTaxable: state.isTaxable,
            };

            this.customerService.create(input)
                .then((response) => {
                    const { data, graphQLErrors } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        return;
                    }

                    if (data && data.createCustomer && data.createCustomer.customerCode) {
                        history.push(`/customers/${data.createCustomer.customerCode}`);
                    }
                });
        }
    }

    onCancel() {
        const { props: { history } } = this;

        history.push('/customers');
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onChange={this.onChange}
            />
        );
    }
};

export default CustomerCreateContainer;

import { gql } from '@apollo/client';

export default class MailSubscription {
    static CHANGED_USER_EMAIL_LIST = gql`
        subscription changedUserEmailList {
            changedUserEmailList {
                type
                email {
                    emailId
                    parentId
                    threadId
                    recordId
                    to
                    from
                    fromName
                    subject
                    bodyPlain
                    messageId
                    inReplyTo
                    incoming
                    status
                    createdOn
                    lastEmailId
                    lastBody
                    lastStatus
                    lastIncoming
                }
            }
        }
    `;

    static CHANGED_USER_EMAIL_DETAIL = gql`
        subscription changedUserEmailDetail($threadId: String!) {
            changedUserEmailDetail(threadId: $threadId) {
                type
                email {
                    emailId
                    parentId
                    threadId
                    recordId
                    to
                    toFull
                    from
                    fromName
                    subject
                    bodyHTML
                    bodyPlain
                    messageId
                    inReplyTo
                    incoming
                    status
                    createdOn
                    lastEmailId
                    lastBody
                    lastStatus
                    lastIncoming
                    cc
                    bcc
                    attachments {
                        emailAttachmentId
                        fileName
                        extension
                        contentType
                        inline
                        url
                    }
                }
            }
        }
    `;

    static EMAIL_LIST_BY_CUSTOMER_CODE_CHANGED = gql`
        subscription emailListByCustomerCodeChanged($customerCode: Int!) {
            emailListByCustomerCodeChanged(customerCode: $customerCode) {
                type
                email {
                    emailId
                    parentId
                    threadId
                    recordId
                    to
                    from
                    fromName
                    subject
                    bodyPlain
                    messageId
                    inReplyTo
                    incoming
                    status
                    createdOn
                    lastEmailId
                    lastBody
                    lastStatus
                    lastIncoming
                    toFull
                    cc
                }
            }
        }
    `;

    static RECEIVED_EMAIL_NOTIFICATION = gql`
        subscription receivedEmailNotification {
            receivedEmailNotification {
                email {
                    bodyPlain
                    threadId
                }
                opportunity {
                    crmId
                    leadCode
                    prospect {
                        firstName
                        lastName
                    }
                }
            }
        }
    `;
}

import React from 'react';
import PropTypes from 'prop-types';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import {
    makeStyles,
    IconButton,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles({
    featureItem: {
        display: 'inline-block',
        padding: '12px',
        position: 'relative',
        '&:hover > button': {
            display: 'inline-flex',
        },
        '& img': {
            width: '64px',
            height: '64px',
            objectFit: 'cover',
            cursor: 'pointer',
        },
        '&:hover': {
            backgroundColor: '#DFE6E9',
        },
    },
    featureItemSelected: {
        backgroundColor: '#DFE6E9',
    },
    deleteButton: {
        color: '#FF4D4F',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '2px 2px 0 0',
        display: 'none',
    },
});

const KeyFeatureIcon = ({
    icon,
    index,
    onSelectIcon,
    toggleConfirmDialog,
}) => {
    const classes = useStyles();

    const { featureItem, featureItemSelected } = classes;
    const className = icon.selected
        ? `${featureItem} ${featureItemSelected}`
        : featureItem;
    const iconToDelete = {
        key: icon.key,
        index,
    };
    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const isDefaultIcon = (icon.url || '').includes('key-features/general/');

    return (
        <div
            className={className}
            onClick={() => onSelectIcon(index)}
        >
            {INVENTORY_VEHICLE_WRITE && !isDefaultIcon && (
                <IconButton
                    aria-label="delete"
                    className={classes.deleteButton}
                    onClick={() => toggleConfirmDialog(iconToDelete)}
                >
                    <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
            )}
            <img src={icon.url} alt="" />
        </div>
    );
};

KeyFeatureIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onSelectIcon: PropTypes.func.isRequired,
    toggleConfirmDialog: PropTypes.func.isRequired,
};

export default KeyFeatureIcon;

import React, { useState } from 'react';

// Material UI and React Bootstrap
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import StringUtils from 'lib/StringUtils';

// HTTP
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';

const useStyle = makeStyles((theme) => ({
    textArea: {
        margin: theme.spacing(1.5, 0),
        resize: 'none',
    },
}));

const DealNoteDialog = ({
    open, onClose, dealId, refetch,
}) => {
    const classes = useStyle();
    const [value, setValue] = useState();
    const [saveDealNote, { loading }] = useMutation(DealsMutate.SAVE_DEAL_NOTE);

    const onSaveDealNote = async () => {
        try {
            const newValue = value?.trim();
            const variables = {
                note: newValue,
                dealId,
            };
            const response = await saveDealNote({ variables });

            if (response?.data?.saveDealNote) {
                onClose();
                refetch();
                ModalUtils.successMessage(null, 'Note created successfully!');
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Note'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Note'));
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Add Note" iconSize="sm" onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <Form.Control
                    rows={5}
                    value={value}
                    as="textarea"
                    placeholder="Placeholder"
                    className={classes.textArea}
                    onChange={(e) => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                onClickPrimary={onSaveDealNote}
                disablePrimaryButton={StringUtils.isEmpty(value) || loading}
            />
        </Dialog>
    );
};

DealNoteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    dealId: PropTypes.number.isRequired,
};

export default DealNoteDialog;

export default class LotUtils {
    static getLotTypes() {
        return [
            'AUTO',
            'BOAT',
            'RV',
            'AIRCRAFT',
            'MOTORCYCLE',
        ];
    }
}

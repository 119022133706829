import * as yup from 'yup';

const PayrollAdditionsSchema = yup.object().shape({
    amount: yup.number().positive().required(),
    descriptionId: yup.number().integer().positive().required(),
    term: yup.number().test({
        name: 'termValidation',
        message: 'Term cannot be zero.',
        test(value) {
            const { descriptionId, descriptionList } = this.parent;
            const isLoan = Boolean(descriptionList?.find((item) => item.bdId === descriptionId)?.isLoan);
            return isLoan ? value > 0 && value <= 255 : true;
        },
    }),
});

export default PayrollAdditionsSchema;

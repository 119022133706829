import StringUtils from 'lib/StringUtils';
import { isEmpty } from 'lodash';
import PROVIDER from 'utils/enum/MenuEnum';

class VendorSettingMap {
    static mapVendorSettingToSave(record) {
        if (!isEmpty(record)) {
            const sendDetails = [PROVIDER.STONE_EAGLE, PROVIDER.FI_EXPRESS].includes(record.provider);

            return {
                vendorName: record.vendorName,
                vendorAddress: record.vendorAddress,
                vendorZip: record.vendorZip,
                vendorCity: record.vendorCity,
                vendorState: record.vendorState,
                vendorPhone: record.vendorPhone,
                vendorFax: record.vendorFax,
                vendorContact: record.vendorContact,
                vendorFednid: record.vendorFednid || '',
                vendorCrNumber: record.vendorCrNumber,
                reservePercentage: record.reservePercentage,
                vendorFee: record.vendorFee,
                floorRate: record.floorRate,
                vendorType: record.vendorType,
                vendorFormula: record.vendorFormula,
                vendorNotes: record.vendorNotes,
                active: record.active || false,
                is1099: record.is1099 || false,
                imported: record.imported || false,
                curtailmentDays: record.curtailmentDays,
                curtailmentPercentage: record.curtailmentPercentage,
                curtailmentDueDays: record.curtailmentDueDays,
                discountFormula: record.discountFormula,
                provider: record.provider,
                menuVendordetail: sendDetails ? {
                    providerCompanyId: record.companyId > 0 ? record.companyId : null,
                    companyCode: !StringUtils.isEmpty(record.companyCode) ? record.companyCode.trim() : null,
                    dealerCode: !StringUtils.isEmpty(record.dealerCode) ? record.dealerCode.trim() : null,
                    lotCodeList: record.lotCodes.filter((l) => !StringUtils.isEmpty(l.lotCode)).map((l) => ({ lot: l.lotName, code: l.lotCode.trim() })),
                    userName: !StringUtils.isEmpty(record.username) ? record.username.trim() : null,
                    password: !StringUtils.isEmpty(record.password) ? record.password.trim() : null,
                    allowVoidContracts: record.allowVoid,
                    allowMultipleContractsSubmit: true,
                } : null,
            };
        }

        return {};
    }

    static mapVendorSettingToUpdate(record, selectedVendorId) {
        if (!isEmpty(record) && selectedVendorId === record.vendorId) {
            const sendDetails = [PROVIDER.STONE_EAGLE, PROVIDER.FI_EXPRESS].includes(record.provider);

            return {
                id: selectedVendorId,
                input: {
                    vendorName: record.vendorName,
                    vendorAddress: record.vendorAddress,
                    vendorZip: record.vendorZip,
                    vendorCity: record.vendorCity,
                    vendorState: record.vendorState,
                    vendorPhone: record.vendorPhone,
                    vendorFax: record.vendorFax,
                    vendorContact: record.vendorContact,
                    vendorFednid: record.vendorFednid || '',
                    vendorCrNumber: record.vendorCrNumber || '',
                    reservePercentage: record.reservePercentage,
                    vendorFee: record.vendorFee,
                    floorRate: record.floorRate,
                    vendorType: record.vendorType,
                    vendorFormula: record.vendorFormula,
                    vendorNotes: record.vendorNotes || '',
                    active: record.active || false,
                    is1099: record.is1099 || false,
                    imported: record.imported || false,
                    curtailmentDays: record.curtailmentDays,
                    curtailmentPercentage: record.curtailmentPercentage,
                    curtailmentDueDays: record.curtailmentDueDays,
                    discountFormula: record.discountFormula,
                    provider: record.provider,
                    menuVendordetail: sendDetails ? {
                        ...(record.menuVendorDetailId ? { menuVendorDetailId: record.menuVendorDetailId } : {}),
                        providerCompanyId: record.companyId > 0 ? record.companyId : null,
                        companyCode: !StringUtils.isEmpty(record.companyCode) ? record.companyCode.trim() : null,
                        dealerCode: !StringUtils.isEmpty(record.dealerCode) ? record.dealerCode.trim() : null,
                        lotCodeList: record.lotCodes.filter((l) => !StringUtils.isEmpty(l.lotCode)).map((l) => ({ lot: l.lotName, code: l.lotCode.trim() })),
                        userName: !StringUtils.isEmpty(record.username) ? record.username.trim() : null,
                        password: !StringUtils.isEmpty(record.password) ? record.password.trim() : null,
                        allowVoidContracts: record.allowVoid,
                        allowMultipleContractsSubmit: true,
                    } : null,
                },
            };
        }

        return {};
    }
}

export default VendorSettingMap;

/* eslint-disable max-len */
import SelectControl from 'components/widgets/editorControls/SelectControl';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import {
    Button, Grid, makeStyles, Divider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    box: {
        paddingTop: '10px',
        display: 'block',
        marginLeft: '10px',
        border: 'solid 1px #e5e5e5',
    },
    labels: {
        fontSize: '0.875rem',
    },
    main: {
        '& h4 ': {
            color: theme.palette.text.red,
        },
    },
}));

const keyStore = new KeyStore();

const MovingGLBetweenAccount = () => {
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    const classes = useStyles();

    const [record, setRecord] = useState({
        overrideAccount: 0,
        replaceAccount: 0,
        showAgreePopup: false,
    });

    const {
        overrideAccount,
        replaceAccount,
        showAgreePopup,
    } = record;

    const handleEditorChange = (columnId, newValue) => {
        setRecord((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    const [saveData, { loading: updating }] = useMutation(AccountingMutation.MOVING_GL_BETWEEN_ACCOUNTS, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.movingGlBetweenAccounts) {
                ModalUtils.successMessage(null, 'Save successfully');
                setRecord({
                    overrideAccount: 0,
                    replaceAccount: 0,
                    showAgreePopup: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = () => {
        saveData({
            variables: {
                record: {
                    overrideAccount,
                    replaceAccount,
                },
            },
        });
    };

    return (
        <>
            <Grid lg={4} item className={classes.box}>
                <Form.Group as={Col}>
                    <Form.Label className={classes.labels}>
                        Move all GLs from account
                        {' '}
                        {overrideAccount}
                        {' '}
                        to account
                        {' '}
                        {replaceAccount}
                    </Form.Label>
                    <Divider />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className={classes.labels}>Move all GLs from account: </Form.Label>
                    <SelectControl
                        name="overrideAccount"
                        value={overrideAccount}
                        placeHolder="select an account"
                        onChange={handleEditorChange}
                        className={overrideAccount > 0 ? '' : 'invalid-field'}
                        dataSource={{
                            query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                            variables: {
                                paginate: {
                                    init: 0,
                                    ignoreLimit: true,
                                },
                            },
                            rootData: 'getAccountingCOAList.data',
                            idField: 'accountNumber',
                            descriptionField: 'fullDescription',
                            additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                        }}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className={classes.labels}>To account:</Form.Label>
                    <SelectControl
                        name="replaceAccount"
                        value={replaceAccount}
                        placeHolder="select an account"
                        onChange={handleEditorChange}
                        className={replaceAccount > 0 ? '' : 'invalid-field'}
                        dataSource={{
                            query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                            variables: {
                                paginate: {
                                    init: 0,
                                    ignoreLimit: true,
                                },
                            },
                            rootData: 'getAccountingCOAList.data',
                            idField: 'accountNumber',
                            descriptionField: 'fullDescription',
                            additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                        }}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    {ACCOUNTING_SETTINGS_WRITE && (
                        <Button
                            variant="outlined"
                            startIcon={<SaveOutlinedIcon />}
                            size="small"
                            disabled={updating || overrideAccount === 0 || replaceAccount === 0 || overrideAccount === replaceAccount}
                            onClick={() => handleEditorChange('showAgreePopup', true)}
                        >
                            {updating ? 'Saving...' : 'Save'}
                            {updating && <CircularProgress size={20} />}
                        </Button>
                    )}
                </Form.Group>
            </Grid>
            {(showAgreePopup && !updating)
                && (
                    <ConfirmDialog
                        classes={classes}
                        title="Attention: This action is irreversible"
                        description={`Are you sure you want to move all GLs from account ${overrideAccount} to account ${replaceAccount}?`}
                        open={showAgreePopup && !updating}
                        variant="outlined"
                        titlePrimary="Yes"
                        titleSecondary="Cancel"
                        onClose={() => handleEditorChange('showAgreePopup', false)}
                        onClickSecondary={() => handleEditorChange('showAgreePopup', false)}
                        onClickPrimary={onSave}
                    />
                )}
        </>
    );
};

export default MovingGLBetweenAccount;

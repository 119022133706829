import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import VehicleErrorPageStyle from 'styles/widgets/inventory/VehicleErrorPageStyle';
import VehicleImage from 'components/widgets/VehicleImage';
import { modules } from 'utils/enum/modules';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => VehicleErrorPageStyle.main(theme));

const REQUEST_RESPONSE = {
    NOT_FOUND: {
        response: 'Not found.',
        message: 'Vehicle Not Found',
    },
    REQUEST_INVALID: {
        response: 'Request failed with status code 400',
        message: "You've sent a bad request",
    },
    DEFAULT: {
        response: 'Something went wrong.',
        message: 'Something went wrong.',
    },
};

const getMessage = (message) => {
    let labelText = REQUEST_RESPONSE.DEFAULT.message;

    if (!StringUtils.isEmpty(message)) {
        Object.keys(REQUEST_RESPONSE).forEach((item) => {
            if (REQUEST_RESPONSE[item].response === message) {
                labelText = REQUEST_RESPONSE[item].message;
            }
        });
    }

    return labelText;
};

const VehicleErrorPage = ({ message }) => {
    const classes = useStyles();
    const labelText = getMessage(message);

    return (
        <div className={classes.main}>
            <VehicleImage height="300px" />
            <h1 className={classes.labels}>{ labelText }</h1>
            <RouteLink
                to={{ pathname: `/${modules.INVENTORY}` }}
                className={classes.button}
            >
                <Button variant="outlined">Go Back</Button>
            </RouteLink>
        </div>
    );
};

VehicleErrorPage.propTypes = {
    message: PropTypes.string,
};

VehicleErrorPage.defaultProps = {
    message: REQUEST_RESPONSE.DEFAULT.message,
};

export default VehicleErrorPage;

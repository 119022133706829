import React from 'react';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';
// Material UI
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(InventoryListStyle.vehicleColors());

const VehicleColors = ({ interiorColor, exteriorColor }) => {
    const classes = useStyles();
    const interior = !StringUtils.isEmpty(interiorColor)
        ? interiorColor
        : 'None';
    const exterior = !StringUtils.isEmpty(exteriorColor)
        ? exteriorColor
        : 'None';

    return (
        <div className={classes.colorsContainer}>
            <div className={classes.colorColumn}>
                {`Interior: ${interior}`}
            </div>
            <div className={classes.colorColumn}>
                {`Exterior: ${exterior}`}
            </div>
        </div>
    );
};

VehicleColors.propTypes = {
    interiorColor: PropTypes.string,
    exteriorColor: PropTypes.string,
};

VehicleColors.defaultProps = {
    interiorColor: 'None',
    exteriorColor: 'None',
};

export default VehicleColors;

import NumberUtils from 'lib/NumberUtils';

export default class AccountingGLMap {
    static glInformation(record) {
        if (record) {
            return record.map((data) => (
                {
                    entryId: data.entryId,
                    accountNumber: data.accountCOA.accountNumber,
                    accountDescription: `${data.accountCOA.accountNumber} - ${data.accountCOA.description}`,
                    controlledBy: data.accountCOA.controlledBy,
                    isControlled: data.accountCOA.isControlled,
                    accountType: data.accountCOA.type,
                    amount: NumberUtils.round(data.debit + data.credit),
                    controlNumber: String(data.controlNumber),
                    departmentId: data.departmentData?.departmentId,
                    department: data.departmentData?.description,
                    lotId: data.lot?.lotId,
                    lotName: data.lot?.lotName,
                    memo: data.memo,
                    processDetailRecordId: data?.processDetailRecordId ?? null,
                    processTypeId: data?.processTypeId ?? null,
                }
            ));
        }

        return [];
    }

    static commonGlInformation(record) {
        if (record) {
            return record.map((data) => (
                {
                    isControlled: data.accountCOA.isControlled,
                    controlledBy: data.accountCOA.controlledBy,
                    accountDescription: data.accountCOA.description,
                    accountNumber: data.accountNumber,
                    controlNumber: String(data.controlNumber),
                    credit: data.credit,
                    debit: data.debit,
                    entryId: data.entryId,
                    lotId: data.lotId,
                    lotName: data.lotName,
                    memo: data.memo,
                    referenceNumber: data.referenceNumber,
                }
            ));
        }

        return [];
    }
}

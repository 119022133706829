import { gql } from '@apollo/client';

export default class UserMutation {
    static LOGOUT = gql`
        mutation logout {
            logout
        }
    `;

    static LOGIN_TO_COMPANY = gql`
        mutation loginToCompany($userLoginMappingId: ID!) {
            loginToCompany(userLoginMappingId: $userLoginMappingId) {
                userId
                username
                companyCode
                companyName
                token
                expiresIn
                scopes
                lots {
                lotName
                lotId
                lotTimeZoneId
                packCost
                lotState
                isDefault
                }
                hashToken
                meteorToken
                defaultLot
                userLoginMapping {
                userLoginMappingId
                companyName
                }
            }
        }
    `;
}

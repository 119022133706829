import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { arrayMove } from 'react-sortable-hoc';

import {
    makeStyles,
    Button,
} from '@material-ui/core';
import clsx from 'clsx';

import Loading from 'components/widgets/Loading';
import VehicleFeatures from 'components/modules/inventory/read/panels/keyFeature/VehicleFeatures';
import AvailableFeatures from 'components/modules/inventory/read/panels/keyFeature/AvailableFeatures';

import { useQuery, useMutation } from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';

import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import ButtonStyles from 'styles/theme/Button';
import { Actions } from 'utils/enum/InventoryEnum';
import MessageUtils from 'utils/MessageUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        margin: '20px 15px',
        overflow: 'hidden',
    },
    loader: {
        minHeight: '85vh',
    },
    featureContent: {
        overflow: 'auto',
        flex: 1,
    },
    actions: {
        marginBottom: 10,
        textAlign: 'center',
    },
    cancel: {
        marginLeft: 10,
    },
    ...ButtonStyles.getStyle(theme),
}));
const initState = {
    editingMode: false,
    vehicleFeatures: [],
    vehicleFeatureBackup: [],
};
const ACTION_TYPES = {
    SET_EDITING_MODE: 'setEditingMode',
    SET_VEHICLE_FEATURES: 'setVehicleFeatures',
    REMOVE_FEATURE: 'removeFeature',
    ADD_FEATURE: 'addFeature',
    ON_SAVE: 'onSave',
    ON_CANCEL: 'onCancel',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_EDITING_MODE:
        return update(state, {
            editingMode: { $set: !state.editingMode },
        });
    case ACTION_TYPES.SET_VEHICLE_FEATURES:
        return update(state, {
            vehicleFeatures: { $set: action.payload },
            vehicleFeatureBackup: { $set: action.payload },
        });
    case ACTION_TYPES.ON_SAVE:
        return update(state, {
            editingMode: { $set: false },
            vehicleFeatureBackup: { $set: state.vehicleFeatures },
        });
    case ACTION_TYPES.ON_CANCEL:
        return update(state, {
            vehicleFeatures: { $set: state.vehicleFeatureBackup },
            editingMode: { $set: false },
        });
    case ACTION_TYPES.REMOVE_FEATURE:
        return update(state, {
            vehicleFeatures: { $splice: [[action.payload, 1]] },
        });
    case ACTION_TYPES.ADD_FEATURE:
        return update(state, {
            vehicleFeatures: { $push: [action.payload] },
        });
    default:
        return initState;
    }
};

const KeyFeatureTab = ({ stockNumber }) => {
    const classes = useStyles();
    const [data, dispatchData] = useReducer(reducer, initState);
    const {
        data: selectedFeatureResponse,
        error: selectedFeatureError,
        loading,
    } = useQuery(InventoryQuery.SELECTED_FEATURE, {
        variables: { stockNumber },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const [updateFeature, { loading: saving }] = useMutation(InventoryMutation.SET_VEHICLE_FEATURES);

    useEffect(() => {
        if (!loading) {
            dispatchData({
                type: ACTION_TYPES.SET_VEHICLE_FEATURES,
                payload: selectedFeatureResponse?.getSelectedFeature || [],
            });
        }
    }, [selectedFeatureResponse, loading]);

    if (loading) {
        return (<Loading className={classes.loader} />);
    }
    if (selectedFeatureError) {
        const graphQLErrors = selectedFeatureError?.graphQLErrors;

        ModalUtils.errorMessage(null, graphQLErrors);
    }

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const onEdit = async () => {
        if (INVENTORY_VEHICLE_WRITE && data.editingMode) {
            try {
                const features = data.vehicleFeatures.map((item) => item.optionNumber);
                const variables = {
                    stockNumber,
                    features,
                };
                const response = await updateFeature({ variables });

                if (response?.data?.setVehicleFeatures) {
                    dispatchData({
                        type: ACTION_TYPES.ON_SAVE,
                    });
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Features'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Features'));
            }
        } else {
            dispatchData({
                type: ACTION_TYPES.SET_EDITING_MODE,
            });
        }
    };
    const onCancel = () => {
        dispatchData({
            type: ACTION_TYPES.ON_CANCEL,
        });
    };
    const onFeatureChange = (feature, action) => {
        const features = data.vehicleFeatures || [];

        if (action === Actions.ADD) {
            const newFeature = update(feature, {
                added: { $set: true },
            });

            dispatchData({
                type: ACTION_TYPES.ADD_FEATURE,
                payload: newFeature,
            });
        } else if (action === Actions.REMOVE) {
            const index = features.findIndex((item) => item.optionId === feature.optionId);

            dispatchData({
                type: ACTION_TYPES.REMOVE_FEATURE,
                payload: index,
            });
        }
    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const dataSorted = arrayMove(
                data.vehicleFeatures,
                oldIndex,
                newIndex,
            );

            dispatchData({
                type: ACTION_TYPES.SET_VEHICLE_FEATURES,
                payload: dataSorted,
            });
        }
    };

    const textButton = !data.editingMode ? 'Edit Key Features' : 'Save';
    return (
        <div className={clsx('d-flex-column', classes.main)}>
            {INVENTORY_VEHICLE_WRITE && (
                <div className={classes.actions}>
                    <Button
                        onClick={onEdit}
                        variant="outlined"
                        color="primary"
                        disabled={saving}
                    >
                        {textButton}
                    </Button>
                    {data.editingMode && (
                        <Button
                            className={clsx(classes.containedError, classes.cancel)}
                            onClick={onCancel}
                            variant="outlined"
                            color="secondary"
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            )}
            <div className={clsx('d-flex-column', classes.featureContent)}>
                <VehicleFeatures
                    features={data.vehicleFeatures}
                    allowAction={data.editingMode}
                    onChange={onFeatureChange}
                    onSortEnd={onSortEnd}
                    disabled={saving}
                />
                {data.editingMode && (
                    <AvailableFeatures
                        vehicleFeatures={data.vehicleFeatures}
                        stockNumber={stockNumber}
                        disabled={saving}
                        onChange={onFeatureChange}
                        allowAction={data.editingMode}
                    />
                )}
            </div>
        </div>
    );
};

KeyFeatureTab.propTypes = {
    stockNumber: PropTypes.number.isRequired,
};

export default KeyFeatureTab;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import Search from 'components/widgets/Search';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import NeoCustomerContainer from 'components/containers/deals/read/buyer/NeoCustomerContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog } from '@material-ui/core';

const styles = (theme) => DealStyles.dialogSearch(theme);

class NeoCustomer extends Component {
    getColumns() {
        const columnStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        const columns = [
            {
                Header: 'Customer',
                minWidth: 90,
                id: 'customer',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { buyer } = value;
                    const fullName = `${buyer.firstName} ${buyer.lastName}`;

                    return <span>{fullName}</span>;
                },
            },
            {
                Header: 'Email',
                minWidth: 120,
                id: 'email',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { buyer } = value;

                    return <span>{buyer?.email}</span>;
                },
            },
            {
                Header: 'Cellphone',
                minWidth: 80,
                id: 'cellPhone',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { buyer } = value;

                    return <span>{buyer?.cellPhone}</span>;
                },
            },
            {
                Header: 'Workphone',
                minWidth: 80,
                id: 'workPhone',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { buyer } = value;

                    return <span>{buyer?.workPhone}</span>;
                },
            },
            {
                Header: 'Stock',
                minWidth: 40,
                id: 'stockNumber',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { vehicle } = value;

                    return <span>{vehicle?.stockNumber}</span>;
                },
            },
            {
                Header: 'Year',
                minWidth: 35,
                id: 'year',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { vehicle } = value;

                    return <span>{vehicle?.year}</span>;
                },
            },
            {
                Header: 'Make',
                minWidth: 40,
                id: 'make',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { vehicle } = value;

                    return <span>{vehicle?.make}</span>;
                },
            },
            {
                Header: 'Model',
                minWidth: 70,
                id: 'model',
                accessor: (record) => record,
                style: columnStyle,
                Cell: ({ value }) => {
                    const { vehicle } = value;

                    return <span>{vehicle?.model}</span>;
                },
            },
        ];

        return columns;
    }

    render() {
        const {
            props: {
                toggleModal, open, classes,
                onSearch, isLoading, loadMore,
                totalCount, records, onSelectRow,
                onClickRecord, disablePrimaryButton,
                selectedRecord, onClickPrimary,
                title,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: classes.dialogMd,
                }}
            >
                <DialogAppBar title={title} onClose={toggleModal} titleVariant="subtitle1" toolbarSize="sm" />
                <DialogContent className={classes.dialogContentMd}>
                    <div className={classes.boxSearch}>
                        <Search fullWidth onKeyDown={onSearch} />
                    </div>
                    <Table
                        rowSelected
                        cursor="default"
                        load={isLoading}
                        loadMore={loadMore}
                        totalRecords={totalCount}
                        data={records}
                        columns={this.getColumns()}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onDoubleClick: () => {
                                    onSelectRow(record);
                                },
                                onClick: () => {
                                    onClickRecord(record);
                                },
                            };
                        }}
                    />
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={toggleModal}
                    onClickPrimary={onClickPrimary}
                    disablePrimaryButton={!selectedRecord || disablePrimaryButton}
                />
            </Dialog>
        );
    }
}

NeoCustomer.propTypes = {
    onClickPrimary: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSelectRow: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    disablePrimaryButton: PropTypes.bool,
    selectedRecord: PropTypes.object,
    title: PropTypes.string,
};

NeoCustomer.defaultProps = {
    onClickPrimary: () => {},
    disablePrimaryButton: false,
    selectedRecord: null,
    title: 'NEO Customer Lookup',
};

export default withStyles(styles)(NeoCustomerContainer(NeoCustomer));

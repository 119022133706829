/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

const phoneRegex = /^[+]?[0-9]{3}?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;

const ReferenceSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address: yup.string().required(),
    zip: yup.string().required().test(
        'required',
        'Zip is required.',
        function (zip) {
            return zip.trim().length === 5 && zip !== '00000';
        },
    ),
    phoneNo: yup.string().required().test(
        'required',
        'Cell phone is required.',
        function (phoneNo) {
            return phoneNo.trim().length > 0 && phoneRegex.test(phoneNo.trim());
        },
    ),
});

export {
    ReferenceSchema,
};

import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import { inRange } from 'lodash';
import PropTypes from 'prop-types';
import LoadingStyles from 'styles/widgets/LoadingStyles';

// Material Ui
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => LoadingStyles.loading(theme);

class Loading extends Component {
    render() {
        const {
            props: {
                classes, minHeight, marginTop, className,
            },
        } = this;
        const classMinHeight = minHeight ? classes.minHeight : '';
        const classMarginTop = inRange(marginTop, 1, 5) ? classes[`marginTop${marginTop}`] : '';

        return (
            <div className={clsx(className, classes.root, classMinHeight, classMarginTop)}>
                <div className={clsx('loading-container', classes.container)}>
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        className={clsx('back-circle', classes.backCircle)}
                        size={24}
                        thickness={4}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={clsx('front-circle', classes.frontCircle)}
                        size={24}
                        thickness={4}
                    />
                </div>
            </div>
        );
    }
}

Loading.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    minHeight: PropTypes.bool,
    marginTop: PropTypes.number,
    className: PropTypes.string,
};

Loading.defaultProps = {
    minHeight: false,
    marginTop: 1,
    className: '',
};

export default withStyles(styles)(Loading);

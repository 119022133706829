import React, { PureComponent } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ButtonStyles from 'styles/theme/Button';

const styles = (theme) => ({
    button: {
        flex: 1,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    buttonGroup: {
        display: 'flex',
    },
    ...ButtonStyles.getStyle(theme),
});

const Variant = {
    CONTAINED: 'contained',
    OULTINED: 'outlined',
};

class SwitchButton extends PureComponent {
    render() {
        const {
            props: {
                value,
                firstLabel,
                secondLabel,
                onChange,
                classes,
                className,
            },
        } = this;
        const firstVariant = value ? Variant.CONTAINED : Variant.OUTLINED;
        const secondVariant = !value ? Variant.CONTAINED : Variant.OUTLINED;
        const classFirstButtonColor = value ? classes.containedInfo : classes.outlinedInfo;
        const classSecondButtonColor = !value ? classes.containedInfo : classes.outlinedInfo;

        return (
            <ButtonGroup
                className={clsx(classes.buttonGroup, className)}
                aria-label="button group"
            >
                <Button
                    className={clsx(classes.button, classFirstButtonColor)}
                    onClick={() => onChange(true)}
                    variant={firstVariant}
                    color="primary"
                    fullWidth
                >
                    {firstLabel}
                </Button>
                <Button
                    className={clsx(classes.button, classSecondButtonColor)}
                    onClick={() => onChange(false)}
                    variant={secondVariant}
                    color="primary"
                    fullWidth
                >
                    {secondLabel}
                </Button>
            </ButtonGroup>
        );
    }
}

SwitchButton.propTypes = {
    className: PropTypes.string,
    firstLabel: PropTypes.string,
    secondLabel: PropTypes.string,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

SwitchButton.defaultProps = {
    firstLabel: 'Yes',
    secondLabel: 'No',
    className: '',
};

export default withStyles(styles)(SwitchButton);

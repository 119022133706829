import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    makeStyles, Button, Radio,
    RadioGroup, FormControlLabel,
    Dialog, DialogContent, DialogActions,
    Checkbox,
} from '@material-ui/core';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import KeyStore from 'utils/KeyStore';
import { FetchPolicy } from 'utils/enum/Core';
import Permissions from 'utils/enum/Permissions';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { Col, Row, Form } from 'react-bootstrap';
import ObstacleStyle from 'styles/modules/crm/ObstacleStyle';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ObstacleStyle.content(theme));
const INIT_STATE = {
    obstacleToCloseId: null,
    description: '',
    active: true,
};

const keyStore = new KeyStore();
const ObstaclesToClose = () => {
    const CRM_SETTINGS_WRITE = keyStore.hasPermission(Permissions.CRM_SETTINGS_WRITE);
    const classes = useStyles();
    const [state, setState] = useState({
        obstacles: [],
        currentFilter: 0,
        isFormOpen: false,
        selectedObstacle: cloneDeep(INIT_STATE),
    });

    const {
        obstacles,
        currentFilter,
        isFormOpen,
        selectedObstacle,
    } = state;

    const {
        data: obstacleData,
        loading: loadingObstacle,
        error: errorLoadingObstacle,
        refetch,
    } = useQuery(OpportunityQuery.GET_OBSTACLE_TO_CLOSE, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [saveObstacle, { loading: savingObstacle }] = useMutation(OpportunityMutation.SAVE_OBSTACLE_TO_CLOSE, {
        onCompleted: () => {
            // eslint-disable-next-line no-use-before-define
            toggleForm();
            refetch();
            ModalUtils.successMessage(null, 'Obstacle saved successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingObstacle) {
            ModalUtils.errorMessage(errorLoadingObstacle?.graphQLErrors);
            return;
        }

        if (!loadingObstacle && obstacleData) {
            const { getObstaclesToClose } = obstacleData;
            if (ArrayUtils.isNotEmpty(getObstaclesToClose)) {
                setState((prevState) => ({
                    ...prevState,
                    obstacles: getObstaclesToClose,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingObstacle, errorLoadingObstacle]);

    const changeFilter = (value) => {
        setState((prevState) => ({
            ...prevState,
            currentFilter: value,
        }));
    };

    const toggleForm = (record = {}) => {
        setState((prevState) => ({
            ...prevState,
            selectedObstacle: Object.keys(record).length > 0 ? record : INIT_STATE,
            isFormOpen: !state.isFormOpen,
        }));
    };

    const onChange = (name, value) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.selectedObstacle);
            clone[name] = value;

            return {
                ...prevState,
                selectedObstacle: clone,
            };
        });
    };

    const onSave = () => {
        const {
            obstacleToCloseId,
            description,
            active,
        } = selectedObstacle;

        saveObstacle({
            variables: {
                obstacleToCloseId,
                description,
                active,
            },
        });
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Description',
            dataKey: 'description',
            width: 500,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.description}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Modified On',
            dataKey: 'modifiedOn',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.modifiedOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleForm(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const filteredObstacles = obstacles.filter((f) => f.active === !currentFilter);
    const parentWidth = document.getElementById('table-container')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 30;
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <RadioGroup row value={currentFilter} onChange={(e, val) => changeFilter(Number(val))}>
                    <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Active"
                    />
                    <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Inactive"
                    />
                </RadioGroup>
                {CRM_SETTINGS_WRITE && (
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => toggleForm()}
                        className={classes.containedGreen}
                        startIcon={<AddIcon />}
                    >
                        New
                    </Button>
                )}
            </div>
            <div className={classes.tableGrid}>
                <div id="table-container" className={classes.tableContainer}>
                    {parentWidth > 0 && (
                        <VirtualTable
                            loading={loadingObstacle}
                            rowHeight={45}
                            totalRecords={filteredObstacles.length}
                            data={filteredObstacles}
                            columns={columns}
                            width={tableWidth}
                        />
                    )}
                </div>
            </div>
            {isFormOpen && (
                <Dialog
                    open
                    maxWidth="sm"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Obstacle to Close"
                        onClose={() => toggleForm()}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.content}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.dialogLabels}>Description (100 Chars Max)</Form.Label>
                                <div>
                                    <Form.Control
                                        maxLength="100"
                                        className={clsx(classes.input, { 'invalid-field': StringUtils.isEmpty(selectedObstacle.description) })}
                                        type="text"
                                        name="description"
                                        value={selectedObstacle.description}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                    />
                                    <Form.Group as={Row}>
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="active"
                                            style={{ padding: 0 }}
                                            checked={selectedObstacle.active}
                                            onChange={(e) => onChange(e.target.name, e.target.checked)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                        <Form.Label className={classes.dialogLabels}>Active</Form.Label>
                                    </Form.Group>
                                </div>
                            </Form.Group>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Form.Group as={Row}>
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                disabled={StringUtils.isEmpty(selectedObstacle.description) || savingObstacle}
                                onClick={() => onSave()}
                            >
                                Save
                            </Button>
                        </Form.Group>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default ObstaclesToClose;

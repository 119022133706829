import React, { useState } from 'react';

// Material UI and React Bootstrap
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import StringUtils from 'lib/StringUtils';

// HTTP
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';

const useStyle = makeStyles((theme) => ({
    textArea: {
        margin: theme.spacing(1.5, 0),
        resize: 'none',
    },
    dialog: {
        width: 450,
    },
}));

const QuickNoteEditable = ({
    open, onClose, record,
}) => {
    const classes = useStyle();
    const [value, setValue] = useState(record.notes?.substring(0, 75) || '');
    const [updateQuickNote, { loading }] = useMutation(OpportunityMutation.UPDATE_CRM_QUICK_NOTE);

    const onSave = async () => {
        try {
            const note = value.trim();
            if (note === record.notes.trim()) onClose();

            const response = await updateQuickNote({ variables: { note, crmId: record.crmId } });

            if (response?.data?.updateCRMQuickNote) {
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Quick Note" toolbarSize="sm" onClose={onClose} titleVariant="h5" />
            <DialogContent>
                <Form.Group id="notegroup">
                    <Box
                        display="flex"
                        justifyContent="end"
                    >
                        <Form.Label className={classes.noteLabel}>{`${value?.length ?? 0}/75`}</Form.Label>
                    </Box>
                    <Form.Control
                        rows={4}
                        value={value}
                        as="textarea"
                        maxLength={75}
                        placeholder="Notes"
                        className={classes.textArea}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Form.Group>
            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                onClickPrimary={onSave}
                disablePrimaryButton={StringUtils.isEmpty(value) || loading}
            />
        </Dialog>
    );
};

QuickNoteEditable.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
};

export default QuickNoteEditable;

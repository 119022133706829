import React, { Component } from 'react';

// Components ant Others
import update from 'immutability-helper';

const FormsTabContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.initBind();
        this.state = {
            packSelected: null,
        };
    }

    onSelectFormPacks(record, isNewFormPack = false) {
        this.setState((prevState) => ({
            packSelected: update(prevState.packSelected, { $set: record }),
            isNewFormPack,
        }));
    }

    initBind() {
        this.onSelectFormPacks = this.onSelectFormPacks.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSelectFormPacks={this.onSelectFormPacks}
            />
        );
    }
};

export default FormsTabContainer;

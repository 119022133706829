import React, { useEffect, useState } from 'react';

// Material UI
import {
    Divider, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import Placeholder from 'components/widgets/Placeholder';
import ProcessStage from 'components/modules/crm/widgets/ProcessStage';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CardVehicle from 'components/modules/lead/widgets/CardVehicle';

// HTTP
import { useQuery } from '@apollo/client';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

// Helpers
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyle = makeStyles((theme = {}) => ({
    label: {
        color: `${theme.palette.text.minsk}`,
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
        whiteSpace: 'nowrap',
    },
    middleColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    vehicle: {
        padding: 0,
        border: 'none',
        minWidth: 210,
        maxWidth: 210,
    },
    widget: {
        marginBottom: '20px',
    },
    container: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    processStageWrapper: {
        margin: '5px 0px',
        width: '100%',
    },
    step: {
        padding: '2px 0px',
    },
    status: {
        textTransform: 'uppercase',
        '&.open': {
            color: 'blue',
        },
        '&.sold': {
            color: '#40a63b',
        },
        '&.lost': {
            color: 'red',
        },
    },
    stage: {
        margin: '5px 0px',
    },
}));

const OpportunityWidget = ({ crmId }) => {
    const classes = useStyle();
    const [records, setRecords] = useState([]);
    const { data, loading, error } = useQuery(OpportunityQuery.GET_OPPORTUNITIES_BY_CRM_ID, { variables: { crmId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });

    useEffect(() => {
        if (!loading && !error) {
            setRecords(data?.getOpportunitiesByCRMId);
        } else if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    }, [data, loading, error]);

    if (records.length === 0) return null;

    return (
        <BoxWidget
            title={`Related Opportunities (${records.length})`}
            className={classes.widget}
            minHeight="auto"
        >
            {records.length > 0 ? (
                <div className={classes.container}>
                    {records.map((record, index) => <Item key={index} {...record} classes={classes} />)}
                </div>
            ) : <Placeholder text="No Opportunities" />}
        </BoxWidget>
    );
};

OpportunityWidget.propTypes = {
    crmId: PropTypes.string.isRequired,
};

export default OpportunityWidget;

const Item = ({
    processStage, availableCash, vehicle, vehicleYear, vehicleMake, vehicleModel, vehicleTrim,
    modifiedOn, classes, status, crmId, showed, demo, writeUp, finance,
}) => {
    const stockNumber = vehicle?.stockNumber;
    const currentModifiedOn = DateUtils.calendarWithTime(modifiedOn);

    return (
        <div>
            <Grid container className={classes.item}>
                <Grid item xs={4}>
                    <CardVehicle
                        className={classes.vehicle}
                        vehicle={vehicle || {
                            year: vehicleYear, make: vehicleMake, model: vehicleModel, trim: vehicleTrim,
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.middleColumn}>
                    <div>
                        <span className={classes.label}>Stock: </span>
                        <span className={classes.value}>
                            {stockNumber ?? 'None'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Cash Down: </span>
                        <span className={clsx(classes.value)}>
                            {NumberUtils.applyCurrencyFormat(availableCash, '$0,0')}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightColumn}>
                    <div className={classes.processStageWrapper}>
                        <ProcessStage
                            disabled
                            data={{
                                crmId,
                                showed,
                                demo,
                                writeUp,
                                finance,
                            }}
                            stepClass={classes.step}
                        />
                    </div>
                    <div>
                        <span className={classes.label}>Status: </span>
                        <span className={clsx(classes.value, classes.status, status.toLowerCase())}>
                            {status}
                        </span>
                    </div>
                    <div className={classes.stage}>
                        <span className={classes.label}>Stage: </span>
                        <span className={classes.value}>
                            {processStage.trim() || 'Unknown'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Last Update: </span>
                        <span className={clsx(classes.value)}>
                            {currentModifiedOn}
                        </span>
                    </div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};
Item.defaultProps = {
    availableCash: 0,
    vehicle: null,
    vehicleYear: null,
    vehicleMake: null,
    vehicleModel: null,
    vehicleTrim: null,
};

Item.propTypes = {
    processStage: PropTypes.string.isRequired,
    availableCash: PropTypes.number,
    vehicle: PropTypes.object,
    vehicleYear: PropTypes.string,
    vehicleMake: PropTypes.string,
    vehicleModel: PropTypes.string,
    vehicleTrim: PropTypes.string,
    modifiedOn: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    crmId: PropTypes.string.isRequired,
    showed: PropTypes.bool.isRequired,
    demo: PropTypes.bool.isRequired,
    writeUp: PropTypes.bool.isRequired,
    finance: PropTypes.bool.isRequired,
};

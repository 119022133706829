import React from 'react';

import TodayIcon from '@material-ui/icons/Today';
import CallIcon from '@material-ui/icons/Call';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

export const icons = {
    EVENT: <TodayIcon fontSize="small" />,
    CALL: <CallIcon fontSize="small" />,
    TASK: <AssignmentTurnedInIcon fontSize="small" />,
};

const getName = (type) => (type?.toLowerCase() === 'event' ? 'appointment' : type?.toLowerCase());

export default {
    getNotificationTypeIcon(type) {
        const record = type.split('_') || [];

        return {
            activityName: getName(record[1]),
            icon: icons[record[1]],
            type: record[0]?.toLowerCase(),
        };
    },
};

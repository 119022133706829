import { gql } from '@apollo/client';

export default class LeadDistributionUserExceptionMutation {
    static SAVE_EMAIL_LEAD_DISTRIBUTION = gql`
       mutation saveEmailLeadDistribution(
            $input: [EmailLeadDistribution!]
            $automatedEmailSchedule: String!
            $lotId: Int!
            $active: Boolean!
        ) {
            saveEmailLeadDistribution(
                input: $input
                automatedEmailSchedule: $automatedEmailSchedule
                lotId: $lotId
                active: $active
            )
        }

    `;
}

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from 'components/widgets/Table';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import ModalUtils from 'utils/ModalUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';
import PropTypes from 'prop-types';

import { useQuery, useMutation } from '@apollo/client';
import { concat } from 'lodash';

import SelectControl from 'components/widgets/editorControls/SelectControl';
import CatalogSelect from 'components/widgets/catalogs/CatalogSelect';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import EditableTableStyles from 'styles/modules/accounting/EditableTableStyles';
import { LotDefaultskey } from 'utils/enum/LotsCategory';

const useStyles = makeStyles((theme) => EditableTableStyles.settingsTable(theme));
const keyStore = new KeyStore();

const CashierMappingsAccount = (props) => {
    const classes = useStyles();
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    const [records, setRecords] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const { lotDefaultKey, catalogEnumForReason, catalogEnumForType } = props;

    const {
        data, loading, error, refetch,
    } = useQuery(AccountReceivableQuery.GET_CASHIER_MAPPING_CREDIT, {
        variables: {
            key: lotDefaultKey,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const reload = () => {
        refetch();
    };

    const [saveData, { loading: updating }] = useMutation(AccountReceivableMutation.SAVE_CASHIER_MAPPING_CREDIT, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveCashierMappingCredit) {
                ModalUtils.successMessage(null, 'saved successfully');
                reload();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const generateLines = (currentData) => currentData.map((element, index) => {
        const currentElement = { ...element };
        currentElement.lineId = index + 1;

        return currentElement;
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getCashierMappingCredit } = data;
            setRecords(generateLines(getCashierMappingCredit));
        }
    }, [data, loading, error]);

    const onCloseDeleteConfirm = () => {
        setOpenDelete(false);
        setIdToDelete(0);
    };

    const onDeleteConfirm = () => {
        setRecords((prev) => generateLines(prev.filter((item) => item.lineId !== idToDelete)));
        onCloseDeleteConfirm();
    };

    const onSave = () => {
        let input = null;

        if (lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT
            && lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT
        ) {
            input = records.filter((item) => item.reason && item.type && item.accountId > 0)
                .map((item) => {
                    const { reason, type, accountId } = item;
                    return { reason, type, accountId };
                });
        } else {
            input = records.filter((item) => item.reason && item.accountId > 0)
                .map((item) => {
                    const { reason, accountId } = item;
                    return { reason, accountId };
                });
        }

        saveData({ variables: { data: input, key: lotDefaultKey } });
    };

    const onAddNewLine = () => {
        if (lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT
            && lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT
        ) {
            setRecords((prev) => generateLines(concat(prev, { reason: '', type: '', accountId: 0 })));
        } else {
            setRecords((prev) => generateLines(concat(prev, { reason: '', accountId: 0 })));
        }
    };

    const handleEditorChange = (columnId, newValue, cell) => {
        let newIntValue = newValue;
        setRecords((prev) => {
            const { reason, type } = prev.find((item) => item.lineId === cell.original.lineId);
            let recordExist = null;

            if (lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT
                && lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT
            ) {
                recordExist = prev.find((item) => (item.reason === reason && item.type === newIntValue)
                || (item.reason === newIntValue && item.type === type));
            } else {
                recordExist = prev.find((item) => item.reason === newIntValue);
            }

            if (recordExist) {
                ModalUtils.errorMessage(null, `The line ${recordExist.lineId} has the same configuration`);
                newIntValue = 0;
            }

            const _records = prev.map((item) => {
                const newItem = { ...item };

                if (item.lineId === cell.original.lineId) { newItem[columnId] = newIntValue; }

                return newItem;
            });

            return _records;
        });
    };

    const getColumns = () => {
        const columns = [
            {
                Header: 'Line',
                minWidth: 60,
                width: 60,
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
                className: clsx(classes.columnStyle, classes.columnCenter),
                accessor: 'lineId',
            },
            {
                Header: 'Reason',
                minWidth: 60,
                accessor: 'reason',
                Cell: (cell) => {
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <CatalogSelect
                                editorCellObject={cell}
                                catalogEnum={catalogEnumForReason}
                                catalogTitle="Reason For Deals"
                                name="reason"
                                className={cell.original.reason ? '' : 'invalid-field'}
                                enableAddOption
                                value={cell.original.reason}
                                placeHolder="select the reason"
                                onChange={handleEditorChange}
                            />
                        );
                    }
                    return <span>{cell.original.reason}</span>;
                },
            },
            {
                Header: 'Credit',
                minWidth: 60,
                accessor: 'accountId',
                Cell: (cell) => {
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <SelectControl
                                name="accountId"
                                value={cell.original.accountId}
                                editorCellObject={cell}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                className={cell.original.accountId > 0 ? '' : 'invalid-field'}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                                }}
                            />
                        );
                    }
                    return <span>{cell.original.accountDescription}</span>;
                },
            }];

        if (lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT
            && lotDefaultKey !== LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT) {
            columns.splice(2, 0, {
                Header: 'Type',
                minWidth: 60,
                accessor: 'type',
                Cell: (cell) => {
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <CatalogSelect
                                editorCellObject={cell}
                                catalogEnum={catalogEnumForType}
                                catalogTitle="type"
                                className={cell.original.type ? '' : 'invalid-field'}
                                name="type"
                                value={cell.original.type}
                                placeHolder="select the type"
                                onChange={handleEditorChange}
                            />
                        );
                    }
                    return <span>{cell.original.type}</span>;
                },
            });
        }

        if (ACCOUNTING_SETTINGS_WRITE) {
            columns.push({
                Header: 'Actions',
                minWidth: 60,
                width: 70,
                Cell: (cell) => (
                    <IconButton onClick={() => { setOpenDelete(true); setIdToDelete(cell.original.lineId); }}>
                        <DeleteIcon className={classes.deleteButton} />
                    </IconButton>
                ),
            });
        }

        return columns;
    };

    return (
        <>
            <Header>
                <div className={classes.buttonSpacing}>
                    {ACCOUNTING_SETTINGS_WRITE && (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<SaveOutlinedIcon />}
                                size="small"
                                disabled={updating}
                                onClick={onSave}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                size="small"
                                onClick={onAddNewLine}
                            >
                                Add
                            </Button>
                        </>
                    )}
                    <Button
                        variant="outlined"
                        startIcon={<CachedIcon />}
                        onClick={reload}
                        disabled={loading}
                        size="small"
                    >
                        Reload
                    </Button>
                </div>
            </Header>
            <Table
                className={clsx('-highlight', classes.tableSpacing)}
                data={loading ? [] : records}
                columns={getColumns()}
                cursor="default"
                load={loading}
                totalRecords={records?.length}
                rowSelected
                enableRowHover
            />
            <ConfirmDialog
                title="Confirm remove line"
                description="Are you sure you want to remove this line?"
                open={openDelete}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
        </>
    );
};

CashierMappingsAccount.propTypes = {
    lotDefaultKey: PropTypes.string.isRequired,
    catalogEnumForReason: PropTypes.string.isRequired,
    catalogEnumForType: PropTypes.string,
};

CashierMappingsAccount.defaultProps = {
    catalogEnumForType: '',
};

export default CashierMappingsAccount;

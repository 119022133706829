import React, { useState, useEffect } from 'react';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import { Alert } from 'react-bootstrap';
import clsx from 'clsx';

// material
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// widgets
import Table from 'components/widgets/Table';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

// utils
import ModalUtils from 'utils/ModalUtils';
import { isEmpty, concat } from 'lodash';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';

// service
import LotQuery from 'services/graphQL/query/LotQuery';
import SplitAmountSettingsQuery from 'services/graphQL/query/SplitAmountSettingsQuery';
import SplitAmountSettingsMutate from 'services/graphQL/mutate/SplitAmountSettingsMutate';
import { useQuery, useMutation } from '@apollo/client';

// custom
import SelectControl from 'components/widgets/editorControls/SelectControl';
import PercentageControl from 'components/modules/settings/accounting/PercentageControl';

const useStyles = makeStyles((theme) => SettingsStyles.splitAmountStyles(theme));

const SplitAmountSettings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    // table
    const [records, setRecords] = useState([]);

    // delete
    const [openDelete, setOpenDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    // get
    const {
        data, loading, error, refetch,
    } = useQuery(SplitAmountSettingsQuery.GET_SPLIT_AMOUNT_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const reload = () => {
        refetch();
    };

    // update
    const [updateSplitAmountSettings, { loading: updating }] = useMutation(SplitAmountSettingsMutate.SAVE_SPLIT_AMOUNT_BETWEEN_LOTS, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveSplitAmountBetweenLots) {
                ModalUtils.successMessage(null, 'saved successfully');
                reload();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getSplitAmountBetweenLots } = data;
            setRecords(getSplitAmountBetweenLots);
        }
    }, [data, loading, error]);

    const onCloseDeleteConfirm = () => {
        setOpenDelete(false);
        setIdToDelete(0);
    };

    const onDeleteConfirm = () => {
        setRecords((prev) => prev.filter((item) => item.lotId !== idToDelete));
        onCloseDeleteConfirm();
    };

    const onUpdate = () => {
        const input = records.map((item) => ({ lotId: item.lotId, percentage: item.percentage }));
        updateSplitAmountSettings({ variables: { input } });
    };

    const onAddNewLine = () => {
        setRecords((prev) => concat(prev, { lotId: 0, percentage: 0 }));
    };

    const handleEditorChange = (columnId, newValue, cell) => {
        const keyValue = 'lotId';
        let newIntValue = Number(newValue);
        setRecords((prev) => {
            if (columnId === keyValue && prev.filter((item) => item[columnId] === newIntValue).length > 0) {
                ModalUtils.errorMessage(null, 'You must select a different lot');
                newIntValue = 0;
            }

            return prev.map((item) => {
                const newItem = { ...item };
                if (item[keyValue] === cell.original[keyValue]) { newItem[columnId] = newIntValue; }

                return newItem;
            });
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        if (event && (key === 'Tab' || keyCode === 9) && cell.original.lotId > 0 && cell.original.percentage > 0) {
            onAddNewLine();
        }
    };

    const getColumns = () => {
        const columns = [
            {
                Header: 'Lot Name',
                minWidth: 60,
                accessor: 'lotId',
                Cell: (cell) => {
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name="lotId"
                                value={cell.original.lotId}
                                placeHolder="select a lot"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotId', descriptionField: 'lotName',
                                }}
                            />
                        );
                    }
                    return <span>{cell.original.lotName}</span>;
                },
            },
            {
                Header: 'Percentage',
                minWidth: 60,
                accessor: 'percentage',
                Cell: (cell) => {
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return <PercentageControl cell={cell} onChange={handleEditorChange} onKeyDown={handleEditorKeyDown} />;
                    }
                    return <span>{cell.original.percentage}</span>;
                },
            }];

        if (ACCOUNTING_SETTINGS_WRITE) {
            columns.push({
                Header: 'Actions',
                minWidth: 60,
                width: 70,
                Cell: (cell) => (
                    <IconButton onClick={() => { setOpenDelete(true); setIdToDelete(cell.original.lotId); }}>
                        <DeleteIcon className={classes.deleteButton} />
                    </IconButton>
                ),
            });
        }

        return columns;
    };

    const totalPercentage = !isEmpty(records) && records.map((item) => item.percentage).reduce((prev, next) => prev + next);

    return (
        <>
            <Header>
                <div className={classes.buttonSpacing}>
                    {ACCOUNTING_SETTINGS_WRITE && (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<SaveOutlinedIcon />}
                                size="small"
                                disabled={updating || totalPercentage !== 100}
                                onClick={onUpdate}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                size="small"
                                onClick={onAddNewLine}
                            >
                                Add
                            </Button>
                        </>
                    )}
                    <Button
                        variant="outlined"
                        startIcon={<CachedIcon />}
                        onClick={reload}
                        disabled={loading}
                        size="small"
                    >
                        Reload
                    </Button>
                </div>
                <div className={classes.containerHeader}>
                    Total Percentage: &nbsp;
                    <strong>
                        {totalPercentage}
                        %
                    </strong>
                </div>
            </Header>
            <Table
                className={clsx('-highlight', classes.tableSpacing)}
                data={records}
                columns={getColumns()}
                cursor="default"
                load={loading}
                totalRecords={records?.length}
                rowSelected
                enableRowHover
            />
            {totalPercentage !== 100 && !loading && records?.length > 0 && (
                <Alert className={classes.alert} variant="warning">
                    The total percentage should be 100%!
                </Alert>
            )}
            <ConfirmDialog
                title="Confirm remove line"
                description="Are you sure you want to remove this line?"
                open={openDelete}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
        </>
    );
};

export default SplitAmountSettings;

/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { AdjustmentType } from 'utils/enum/PayrollEnum';

const PayrollBDSchema = yup.object().shape({
    bdType: yup.string().oneOf(Object.keys(AdjustmentType)).required(),
    description: yup.string().trim().required(),
    taxType: yup.string().required(),
});

export default PayrollBDSchema;

import React, { useState, useContext } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Container from 'components/widgets/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';

// icons
import AppsIcon from '@material-ui/icons/Apps';
import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded';
import AccountBalanceWalletRounded from '@material-ui/icons/AccountBalanceWalletRounded';
import { ReactComponent as DealsOutlined } from 'assets/deals-settings.svg';
import { DriveEtaOutlined as DriveEtaOutlinedIcon } from '@material-ui/icons';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import { BIIcon, WidgetsOutlinedIcon, BuildOutlinedIcon } from 'components/icons/index';
import SettingsEthernetOutlinedIcon from '@material-ui/icons/SettingsEthernetOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';

// widgets
import TabPanel from 'components/widgets/tabs/TabPanel';

// utils
import KeyStore from 'utils/KeyStore';
import { Helmet } from 'react-helmet-async';
import Permission from 'utils/enum/Permissions';
import { useHistory, useParams } from 'react-router-dom';
import CRMSettings from 'components/modules/settings/crm/CRMSettings';
import FormSettings from 'components/modules/settings//form/FormSettings';
import UseTabSetting from 'components/modules/settings/user/UseTabSetting';
import DealsSettings from 'components/modules/settings/deals/DealsSettings';
import PayrollSettings from 'components/modules/settings/payroll/PayrollSettings';
import GeneralSettings from 'components/modules/settings/general/GeneralSettings';
import InventorySettings from 'components/modules/settings/inventory/InventorySettings';
import AccountingSettings from 'components/modules/settings/accounting/AccountingSettings';
import BISettings from 'components/modules/settings/businessIntelligence/BISettings';
import PartsSettings from 'components/modules/settings/parts/PartsSettings';
import BHPHSettings from 'components/modules/settings/bphp/BHPHSettings';
import ServiceSettings from 'components/modules/settings/service/ServiceSettings';
import IntegrationsSettings from 'components/modules/settings/integrations/IntegrationsSettings';
import TrainingSettings from 'components/modules/settings/training/TrainingSettings';
import AISettings from 'components/modules/settings/ai/AISettings';
import UserContext from 'components/context/UserContext';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanel(theme));

const getSettings = (classes) => {
    const keyStore = new KeyStore();

    const settings = [
        {
            permission: keyStore.hasPermission(Permission.SETTINGS_GENERAL_READ),
            text: 'General',
            url: 'general',
            icon: <AppsIcon />,
            component: <GeneralSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.INVENTORY_SETTINGS_READ),
            text: 'Inventory',
            url: 'inventory',
            icon: <DriveEtaOutlinedIcon />,
            component: <InventorySettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.DEALS_SETTINGS_READ),
            text: 'Deals',
            url: 'deals',
            icon: <DealsOutlined />,
            component: <DealsSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_READ) || keyStore.hasPermission(Permission.ACCOUNTING_COA_READ),
            text: 'Accounting',
            url: 'accounting',
            icon: <AccountBalanceOutlinedIcon />,
            component: <AccountingSettings />,
        },
        {
            text: 'Payroll',
            permission: keyStore.hasPermission(Permission.PAYROLL_SETTINGS_READ),
            component: <PayrollSettings />,
            url: 'payroll',
            icon: <AssignmentIndOutlinedIcon />,
        },
        {
            text: 'CRM',
            permission: keyStore.hasPermission(Permission.CRM_SETTINGS_READ),
            component: <CRMSettings />,
            url: 'crm',
            icon: <ContactsRoundedIcon />,
        },
        {
            text: 'BHPH',
            permission: keyStore.hasPermission(Permission.BHPH_SETTINGS_READ),
            component: <BHPHSettings />,
            url: 'bphp',
            icon: <AccountBalanceWalletRounded />,
        },
        {
            text: 'Integrations',
            permission: keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_READ),
            component: <IntegrationsSettings />,
            url: 'integrations',
            icon: <SettingsEthernetOutlinedIcon />,
        },
        {
            text: 'User',
            permission: keyStore.hasPermission(Permission.USER_EMAIL_SIGNATURE_READ),
            component: <UseTabSetting />,
            url: 'user',
            icon: <ContactsRoundedIcon />,
        },
        {
            permission: keyStore.hasPermission(Permission.BI_SETTINGS_READ),
            text: 'BI',
            url: 'business-intelligence',
            icon: <BIIcon className={classes.biIcon} />,
            component: <BISettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.PARTS_READ),
            text: 'Parts',
            url: 'parts',
            icon: <WidgetsOutlinedIcon className={classes.biIcon} />,
            component: <PartsSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.SERVICE_SETTINGS_READ),
            text: 'Service',
            url: 'service',
            icon: <BuildOutlinedIcon />,
            component: <ServiceSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.SETTINGS_GENERAL_READ),
            text: 'Forms',
            url: 'forms',
            icon: <AppsIcon />,
            component: <FormSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.TRAINING_SETTINGS_READ),
            text: 'Training',
            url: 'training',
            icon: <LiveHelpOutlinedIcon />,
            component: <TrainingSettings />,
        },
        {
            permission: keyStore.hasPermission(Permission.AI_SETTINGS_READ),
            text: 'AI',
            url: 'artificial-intelligence',
            icon: <WbIncandescentOutlinedIcon />,
            component: <AISettings />,
        },
    ];

    return settings.filter((item) => item.permission);
};

const SettingsPanel = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const history = useHistory();
    const { maintab } = useParams();
    const settingsItems = getSettings(classes);
    const subTabs = settingsItems.map((item) => item.url);
    const getSelectedTab = (submodule) => {
        const tabIndex = subTabs.indexOf(submodule);
        return (tabIndex !== -1) ? tabIndex : 0;
    };

    const [value, setValue] = useState(getSelectedTab(maintab || 0));

    const handleChange = (event, newValue) => {
        history.push(`/settings/${subTabs[newValue]}`);
        setValue(newValue);
    };

    return (
        <>
            <AppBar color="default" position="static" elevation={0}>
                <Tabs value={value} onChange={handleChange} scrollButtons={isMobile ? 'on' : 'auto'} variant="scrollable">
                    {settingsItems.map((item, index) => (
                        <Tab key={index} label={item.text} icon={item.icon} />
                    ))}
                </Tabs>
            </AppBar>
            {settingsItems.map((item, index) => (
                <TabPanel key={index} value={value} index={index}>
                    <Helmet
                        defer={false}
                    >
                        <title>{`Settings / ${item.text}`}</title>
                    </Helmet>
                    {item.component}
                </TabPanel>
            ))}
        </>
    );
};

const SettingsMain = () => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    if (!userInformation) return null;

    return (
        <Container className={classes.rootContainer}>
            <div className={classes.panelContainer}>
                <SettingsPanel />
            </div>
        </Container>
    );
};

export default SettingsMain;

/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import StringUtils from 'lib/StringUtils';
import ArrayUtils from 'lib/ArrayUtils';
import KeyStore from 'utils/KeyStore';

export default class HttpClientModule {
    constructor() {
        const keyStore = new KeyStore();

        this.client = axios.create({
            baseURL: process.env.REACT_APP_API,
            headers: {
                Authorization: keyStore.getToken(),
                'Content-Type': 'application/json',
                'x-auth-type': 'oauth2',
            },
        });
    }

    getMessage(error, url) {
        let message;

        if (error.response) {
            const messageList = error.response.data?.Messages;
            message = ArrayUtils.isNotEmpty(messageList) ? messageList[0]?.Message : null;

            if (StringUtils.isEmpty(message)) {
                message = error.response.data?.Message;
            }
        }

        if (StringUtils.isEmpty(message)) {
            message = `There was an error to call the service: \n ${url}`;
        }

        return message;
    }

    /**
     * @param url string
     * @return Promise
     */
    GET(url) {
        return new Promise((resolve, reject) => {
            this.client.get(url)
                .then((result) => {
                    resolve(
                        Object.assign(result.data || {}, {
                            StatusCode: result.status,
                        }),
                    );
                })
                .catch((error) => {
                    reject(this.getMessage(error, url));
                });
        });
    }

    /**
     * @param url string
     * @param body json
     * @return Promise
     */
    POST(url, body) {
        return new Promise((resolve, reject) => {
            this.client.post(url, body)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(this.getMessage(error, url));
                });
        });
    }

    /**
     * @param url string
     * @param body json
     * @return Promise
     */
    PUT(url, body) {
        return new Promise((resolve, reject) => {
            this.client.put(url, body)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(this.getMessage(error, url));
                });
        });
    }

    /**
     * @param url string
     * @param body json
     * @return Promise
     */
    DELETE(url, body) {
        return new Promise((resolve, reject) => {
            this.client.delete(url, { data: body })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(this.getMessage(error, url));
                });
        });
    }
}

// Utils
import DynamicColor from 'utils/DynamicColor';

export default class ConversationStyles {
    static messageAdd(theme) {
        return ({
            root: {
                padding: theme.spacing(0, 2),
                height: 64,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
            },
            fileInput: {
                display: 'none',
            },
            iconButton: {
                padding: theme.spacing(1),
            },
            iconButtonSendMessage: {
                width: 40,
                height: 40,
                minWidth: 40,
                borderRadius: '50%',
                padding: 0,
            },
            attachFileIcon: {
                transform: 'rotate(36deg)',
            },
            buttonJoin: {
                height: '100%',
                color: theme.palette.text.purplelight,
            },
            joinConversation: {
                position: 'absolute',
                width: '100%',
                background: 'rgba(255,255,255,0.8)',
                top: 0,
                left: 0,
                bottom: 0,
                zIndex: 999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        });
    }

    static conversation(theme) {
        return ({
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            buttonText: {
                marginLeft: theme.spacing(1),
            },
            boxListVehicle: {
                padding: theme.spacing(2),
            },
            headerDrawer: {
                justifyContent: 'flex-end',
                display: 'flex',
                marginBottom: theme.spacing(1),
            },
            buttonClose: {
                minWidth: 36,
                width: 36,
            },
            startIcon: {
                margin: 'auto',
                color: theme.palette.text.default,
            },
            title: {
                color: theme.palette.text.boulderGray,
            },
            contentVehicle: {
                margin: theme.spacing(0, -2),
                '& .vehicle-item': {
                    padding: theme.spacing(1, 2, 1, 1),
                    '&:first-child': {
                        borderTop: `1px solid ${theme.palette.divider}`,
                    },
                },
            },
        });
    }

    static conversationDetailsToolbar(theme) {
        return ({
            customerName: {
                textTransform: 'capitalize',
            },
            root: {
                padding: theme.spacing(0, 2),
                height: 64,
            },
        });
    }

    static conversationPlaceholder(theme) {
        return ({
            title: {
                marginBottom: theme.spacing(1),
            },
        });
    }

    static message(theme) {
        return ({
            message: {
                backgroundColor: theme.palette.primary.main,
                borderRadius: theme.spacing(1, 1, 1, 0),
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(1),
                maxWidth: '50%',
            },
            messageOut: {
                backgroundColor: theme.palette.background.gray,
                borderRadius: theme.spacing(1, 1, 0, 1),
                marginLeft: 0,
                marginRight: theme.spacing(1),
            },
            boxMessageOut: {
                flexDirection: 'row-reverse',
            },
            text: {
                color: theme.palette.text.contrastTextPurpure,
                fontWeight: 'bold',
                whiteSpace: 'break-spaces',
            },
            dateTime: {
                marginTop: theme.spacing(1.5),
                fontSize: theme.spacing(1.6),
                color: theme.palette.text.secondary,
            },
            textOut: {
                color: theme.palette.text.default,
                fontWeight: 'bold',
            },
            customerName: {
                fontWeight: 'bold',
            },
            avatar: {
                backgroundColor: theme.palette.listColor[0],
                marginBottom: theme.spacing(2),
            },
            cardMessage: {
                width: 430,
                justifyContent: 'center',
                '& .carousel-inner': {
                    padding: theme.spacing(2),
                },
            },
            joinItem: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            },
            divider: {
                flex: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            dividerLeft: {
                marginLeft: theme.spacing(6),
            },
            dividerRight: {
                marginRight: theme.spacing(6),
            },
            joinTitle: {
                padding: theme.spacing(0, 6),
                color: theme.palette.text.purplelight,
                fontWeight: 'bold',
                fontSize: 14,
            },
        });
    }

    static sidebar(theme) {
        return ({
            box: {
                width: 320,
                minWidth: 320,
                padding: theme.spacing(3),
                overflow: 'auto',
                paddingTop: 0,
            },
            bodyConversation: {
                display: 'flex',
                width: '100%',
            },
            list: {
                padding: 0,
            },
            statusConversation: {
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1.3),
                fontSize: theme.spacing(1.3),
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: theme.palette.link,
            },
            listItem: {
                backgroundColor: theme.palette.background.secondary,
                marginBottom: theme.spacing(3),
                borderRadius: theme.spacing(1),
                padding: theme.spacing(3, 1.5),
                '&:last-child': {
                    marginBottom: 0,
                },
                flexDirection: 'column',
                border: '2px solid transparent',
            },
            customerDetails: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'hidden',
            },
            customerName: {
                fontWeight: 'bold',
                color: theme.palette.text.purpure,
                textTransform: 'capitalize',
            },
            nameLastConnection: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            lastMessage: {
                fontSize: '13px',
            },
            conversationSelected: {
                border: `2px solid rgb(${theme.palette.rgb.purple}, 0.5)`,
            },
            containerSidebar: {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.primary,
            },
            inputSearchContainer: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(2, 3),
                '& input': {
                    background: 'transparent',
                    paddingRight: theme.spacing(7),
                },
                '& input:focus': {
                    background: 'transparent',
                },
            },
            inputIcon: {
                display: 'flex',
                position: 'relative',
                flex: 1,
                '& button': {
                    position: 'absolute',
                    right: 0,
                    padding: 0,
                    height: '100%',
                    minWidth: theme.spacing(7),
                },
            },
            clearSearch: {
                position: 'absolute',
                right: '58px',
                height: '100%',
                fontSize: '15px',
                zIndex: 99,
                cursor: 'pointer',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            ...DynamicColor.getStyles(theme),
        });
    }
}

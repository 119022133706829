import * as yup from 'yup';

const GeneratedCheckSchema = yup.object().shape({
    lotId: yup.number().integer().positive().required(),
    paidFromId: yup.number().integer().positive().required(),
    date: yup.date().required(),
    checkNumber: yup.number().integer().positive().required(),
    amount: yup.number().required().test({
        name: 'amountValidation',
        message: 'Check amount cannot be different.',
        test(amount) {
            return amount === this.parent.originalAmount;
        },
    }),
    checkLines: yup.array().of(
        yup.object().shape({
            amount: yup.number().required().test({
                name: 'checkLineAmountValidation',
                message: 'Check Line amount cannot be 0.',
                test(amount) {
                    return amount !== 0;
                },
            }),
            accountNumber: yup.number().integer().positive().required(),
        }),
    ).required(),
});

export default GeneratedCheckSchema;

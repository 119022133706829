/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable import/no-named-default */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import {
    makeStyles, Button,
    Dialog, DialogContent,
    Select, MenuItem, ListItemText,
    Checkbox,
} from '@material-ui/core';
import { FORMATTING_CONDITION, CHART_COLUMN_DATA_TYPE } from 'utils/enum/BusinessIntelligenceEnum';
import { Form } from 'react-bootstrap';
import { useTheme } from '@material-ui/core/styles';
import ButtonStyles from 'styles/theme/Button';
import StringUtils from 'lib/StringUtils';
import ArrayUtils from 'lib/ArrayUtils';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import { default as SelectWidget } from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';

// Icons
import FormatBoldOutlinedIcon from '@material-ui/icons/FormatBoldOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import FormatColorTextOutlinedIcon from '@material-ui/icons/FormatColorTextOutlined';

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            maxWidth: 200,
        },
    },
    getContentAnchorEl: null,
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '15px 0px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    tableContainer: {
        marginTop: '10px',
        height: '300px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            padding: 0,
            justifyContent: 'center',
            marginLeft: '5px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            '& > span': {
                fontSize: '11px',
            },
            '& > button': {
                padding: 0,
                minWidth: 'auto',
                '& > span > span': {
                    margin: 0,
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    hiddenColorSelector: {
        width: '0px !important',
        height: '0px !important',
        visibility: 'hidden',
        marginRight: '0 !important',
        border: 0,
    },
    input: {
        width: '100%',
        fontSize: '13px',
        '& > div:nth-child(1)': {
            fontSize: '13px !important',
        },
    },
    '@global': {
        '.css-26l3qy-menu': {
            minWidth: '150px',
        },
        '.css-26l3qy-menu div, .css-2b097c-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
        '.css-26l3qy-menu > div, .css-2b097c-menu > div': {
            maxHeight: '130px',
            overflowX: 'hidden',
        },
    },
    conditionInput: {
        marginLeft: '2px',
        width: '75px',
        fontSize: '13px',
        height: '32px',
    },
    bolded: {
        backgroundColor: theme.palette.background.black,
        color: theme.palette.text.white,
    },
    select: {
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '5px',
        height: '33px',
        padding: '5px',
    },
}));

const FormattingForm = ({
    input,
    rawData,
    saveFormatting,
    formattingData,
}) => {
    const themeHook = useTheme();
    const classes = { ...useStyles(), ...buttonStyles() };
    const columnMinimumWidth = 120;
    const style = window.getComputedStyle(document.body);
    const fontFamily = style.getPropertyValue('font-family');

    const fontColorRef = useRef([]);
    const backgroundColorRef = useRef([]);

    const [state, setState] = useState({
        data: formattingData,
    });

    const {
        data,
    } = state;

    const pivotColumns = input?.find((item) => item.name === 'PivotColumns')?.value ?? [];
    const pivotValues = input?.find((item) => item.name === 'PivotValues')?.value ?? [];
    const cols = [
        ...pivotColumns,
        'Calculation',
        'Condition',
        'Size',
        'Bold',
        'Color',
        'Background',
        'Action',
    ];

    const getDefaultValue = (col) => {
        switch (col) {
        case 'Color':
            return themeHook.palette.text.outerSpace;
        case 'Background':
            return 'auto';
        case 'Size':
            return 12;
        case 'Bold':
            return false;
        case 'Action':
            return '';
        case 'Condition':
            return null;
        default:
            return ['All'];
        }
    };

    const addRecord = () => {
        const clone = cloneDeep(data);
        clone.push(
            cols
                .reduce((a, b) => { a[b] = getDefaultValue(b); return a; }, {}),
        );

        setState((prevState) => ({ ...prevState, data: clone }));
    };

    const removeRecord = (index) => {
        let clone = cloneDeep(data);
        clone = clone.filter((_, idx) => idx !== index);
        setState((prevState) => ({ ...prevState, data: clone }));
    };

    const updateConditional = (field, value, index, options) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.data);
            const row = clone.find((_, idx) => index === idx);
            if (row) {
                if (ArrayUtils.isNotEmpty(value)) {
                    const currentValue = row[field];
                    const wasAll = currentValue.includes('All');
                    const isAll = value.includes('All');

                    if (!wasAll && isAll) row[field] = ['All'];
                    if (wasAll && !isAll) row[field] = [];
                    if (wasAll && isAll) row[field] = options.filter((o) => !value.includes(o));
                    if (!wasAll && !isAll) {
                        const allSelected = options.every((o) => value.includes(o));
                        if (!allSelected) row[field] = value;
                        if (allSelected) row[field] = ['All'];
                    }
                } else {
                    row[field] = value;
                }
            }

            return { ...prevState, data: clone };
        });
    };

    const filterConditions = () => {
        const conditions = { ...FORMATTING_CONDITION };
        return Object.keys(conditions)
            .map((key) => ({
                value: conditions[key],
                label: StringUtils.toPascalCase(key.replace(/_/g, ' ').toLowerCase()),
            }));
    };

    const getColumnUniqueValues = (column) => {
        const defaultColumns = input?.find((item) => item.name === 'Columns')?.value;
        const columnDataType = defaultColumns.find((c) => c.column === column)?.dataType;

        const uniques = [...new Map(
            rawData
                .map((record) => String(record.find((item) => item.name === column)?.value ?? ''))
                .map((val) => ((StringUtils.isEmpty(val) || val?.toLowerCase() === 'null') ? '(Blanks)' : val))
                .sort((a, b) => (columnDataType === CHART_COLUMN_DATA_TYPE.NUMERIC ? a - b : a.localeCompare(b)))
                .map((val) => [val.toLowerCase(), val]),
        ).values()];

        return uniques;
    };

    const tableColumns = cols.map((pc) => {
        const calculatedWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(pc, 'bold 12px ' + fontFamily) || 0);
        const width = calculatedWidth > columnMinimumWidth ? calculatedWidth : columnMinimumWidth;

        return {
            label: pc,
            dataKey: pc,
            headerClassName: classes.tableHeader,
            width: pc === 'Condition' ? 200 : width,
            filterEnabled: false,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record, rowIndex } = cell;
                const currentValue = record[pc];

                switch (pc) {
                case 'Calculation':
                    return (
                        <Select
                            displayEmpty
                            multiple
                            className={clsx(classes.input, classes.select, currentValue?.length === 0 ? 'invalid-field' : '')}
                            value={currentValue}
                            onChange={({ target }) => updateConditional('Calculation', target.value, rowIndex, pivotValues.map((pv) => pv.name))}
                            renderValue={(selected) => (selected ?? []).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {['All', ...pivotValues.map((pv) => pv.name)].map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    <Checkbox
                                        checked={
                                            (currentValue || []).includes(item)
                                            || (currentValue || []).includes('All')
                                        }
                                    />
                                    <ListItemText primary={item} />
                                </MenuItem>
                            ))}
                        </Select>
                    );
                case 'Condition':
                    return (
                        <>
                            <SelectWidget
                                nowrap
                                size="sm"
                                loading={false}
                                name=""
                                placeholder="Condition"
                                className={clsx(classes.input, !currentValue ? 'invalid-field' : '')}
                                onChange={(_, newValue) => updateConditional('Condition', newValue, rowIndex)}
                                value={currentValue || ''}
                                options={filterConditions()}
                            />
                            <Form.Control
                                placeholder="Value"
                                className={clsx(classes.conditionInput, !record.ConditionValue ? 'invalid-field' : '')}
                                type="number"
                                value={record.ConditionValue || ''}
                                onChange={({ target }) => updateConditional('ConditionValue', target.value, rowIndex)}
                            />
                        </>
                    );
                case 'Size':
                    return (
                        <SelectWidget
                            nowrap
                            size="sm"
                            loading={false}
                            name=""
                            className={clsx(classes.input, !currentValue ? 'invalid-field' : '')}
                            onChange={(_, newValue) => updateConditional('Size', newValue, rowIndex)}
                            value={currentValue || 0}
                            options={[4, 7, 5, 9, 10, 11, 12, 14, 18].map((size) => ({ value: size, label: size }))}
                        />
                    );
                case 'Bold':
                    return (
                        <Button
                            className={currentValue ? classes.bolded : ''}
                            size="small"
                            startIcon={<FormatBoldOutlinedIcon />}
                            onClick={() => updateConditional('Bold', !currentValue, rowIndex)}
                        />
                    );
                case 'Color':
                    return (
                        <>
                            <Button
                                style={currentValue ? { border: `1px solid ${currentValue}` } : {}}
                                size="small"
                                startIcon={<FormatColorTextOutlinedIcon />}
                                onClick={() => fontColorRef.current[rowIndex].click()}
                            />
                            <input
                                ref={(el) => { fontColorRef.current[rowIndex] = el; }}
                                className={classes.hiddenColorSelector}
                                type="color"
                                value={currentValue || '#ffffff'}
                                onChange={({ target }) => updateConditional('Color', target.value, rowIndex)}
                            />
                        </>
                    );
                case 'Background':
                    return (
                        <>
                            <Button
                                style={currentValue ? { border: `1px solid ${currentValue}` } : {}}
                                size="small"
                                startIcon={<FormatColorFillOutlinedIcon />}
                                onClick={() => backgroundColorRef.current[rowIndex].click()}
                            />
                            <input
                                ref={(el) => { backgroundColorRef.current[rowIndex] = el; }}
                                className={classes.hiddenColorSelector}
                                type="color"
                                value={currentValue || '#ffffff'}
                                onChange={({ target }) => updateConditional('Background', target.value, rowIndex)}
                            />
                        </>
                    );
                case 'Action':
                    return (
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => removeRecord(rowIndex)}
                        />
                    );
                default:
                    return (
                        <Select
                            displayEmpty
                            multiple
                            className={clsx(classes.input, classes.select)}
                            value={currentValue}
                            onChange={({ target }) => updateConditional(pc, target.value, rowIndex, getColumnUniqueValues(pc))}
                            renderValue={(selected) => (selected ?? []).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {['All', ...getColumnUniqueValues(pc)].map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    <Checkbox
                                        checked={
                                            (currentValue || []).includes(item)
                                            || (currentValue || []).includes('All')
                                        }
                                    />
                                    <ListItemText primary={item} />
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
            },
        };
    });

    const allDataValid = data.every((r) => {
        const values = Object.keys(r).filter((k) => !pivotColumns.includes(k)).map((k) => r[k]);
        return !values.some((v) => (Array.isArray(v) && v.length === 0) || v === null);
    });
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Formatting"
                onClose={() => (allDataValid ? saveFormatting(data) : null)}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.containedSecondaryInfo}
                            onClick={addRecord}
                        >
                            Add
                        </Button>
                    </div>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={false}
                            rowHeight={45}
                            totalRecords={data.length}
                            data={data}
                            columns={tableColumns}
                            width={tableColumns.reduce((a, b) => a + b.width, 0)}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

FormattingForm.defaultProps = {
    input: [],
    rawData: [],
    formattingData: [],
};

FormattingForm.propTypes = {
    saveFormatting: PropTypes.func.isRequired,
    input: PropTypes.array,
    rawData: PropTypes.array,
    formattingData: PropTypes.array,
};

export default FormattingForm;

import React, { useState, useContext, useEffect } from 'react';
import {
    makeStyles,
    Button, Tooltip,
} from '@material-ui/core';
import {
    useQuery,
    useLazyQuery,
} from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { Form, Row } from 'react-bootstrap';
import { PaymentFrequency } from 'utils/enum/DealEnum';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import UserContext from 'components/context/UserContext';
import LotQuery from 'services/graphQL/query/LotQuery';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    header: {
        color: theme.palette.text.white,
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: theme.palette.background.bigStone,
    },
    content: {
        display: 'flex',
        overflowY: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    topSection: {
        display: 'flex',
        width: '100%',
        flexShrink: 0,
        height: '40px',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.ghost}`,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('sm')]: {
            height: 'auto !important',
            flexDirection: 'column',
            padding: '5px',
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        '& > div:nth-child(1)': {
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            '& > span': {
                color: theme.palette.text.redBerry,
                marginLeft: '5px',
            },
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                marginRight: '10px',
                '& > div': {
                    width: '200px',
                    border: `1px solid ${theme.palette.border.ghost}`,
                    background: theme.palette.background.white,
                    borderRadius: '5px',
                    '& > div': {
                        fontSize: '13px',
                    },
                },
            },
            '& > button': {
                height: '31px',
            },
        },
    },
    bottomSection: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flexGrow: 'initial !important',
        },
        '& > div': {
            width: '33.33%',
            height: '100%',
            paddingTop: '25px',
            paddingLeft: '50px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto',
                padding: '20px',
            },
        },
    },
    field: {
        margin: 0,
        flexDirection: 'row',
        fontSize: '13px',
        alignItems: 'center',
        marginBottom: '18px',
        '& > label': {
            fontWeight: 500,
            width: '135px',
        },
        '& > input': {
            width: '250px',
        },
        '& > div.select-bootstrap': {
            width: '250px',
        },
        '& > button': {
            width: '250px',
        },
    },
    subTitle: {
        marginBottom: '15px',
        fontSize: '13px',
        fontWeight: 'bold',
        paddingTop: '10px',
        borderBottom: `1px solid ${theme.palette.border.ghost}`,
        width: '142px',
    },
}));

const splitFields = (setting) => {
    const taxableFields = [];
    const nonTaxableFields = [];
    const newValues = {};

    if (setting.displayDealerFee) {
        if (setting.dealerFeeTaxable) { taxableFields.push({ name: 'Dealer Fee', variable: 'dealerFee' }); }
        if (!setting.dealerFeeTaxable) { nonTaxableFields.push({ name: 'Dealer Fee', variable: 'dealerFee' }); }

        newValues.dealerFee = setting.dealerFee ?? 0;
    }

    if (setting.displayAcquisitionFee) {
        if (setting.mbiTaxable) { taxableFields.push({ name: 'Acquisition Fee', variable: 'mbi' }); }
        if (!setting.mbiTaxable) { nonTaxableFields.push({ name: 'Acquisition Fee', variable: 'mbi' }); }

        newValues.mbi = setting.mbi ?? 0;
    }

    if (setting.displayTagAgencyFee) {
        if (setting.tagAgencyFeeTaxable) { taxableFields.push({ name: 'Tag Agency Fee', variable: 'tagAgencyFee' }); }
        if (!setting.tagAgencyFeeTaxable) { nonTaxableFields.push({ name: 'Tag Agency Fee', variable: 'tagAgencyFee' }); }

        newValues.tagAgencyFee = setting.tagAgencyFee ?? 0;
    }

    if (setting.displayTagFee) {
        if (setting.tagFeeTaxable) { taxableFields.push({ name: 'Tag Fee', variable: 'tagFee' }); }
        if (!setting.tagFeeTaxable) { nonTaxableFields.push({ name: 'Tag Fee', variable: 'tagFee' }); }

        newValues.tagFee = setting.tagFee ?? 0;
    }

    if (setting.displayRegistrationFee) {
        if (setting.registrationFeeTaxable) { taxableFields.push({ name: 'Registration Fee', variable: 'registrationFee' }); }
        if (!setting.registrationFeeTaxable) { nonTaxableFields.push({ name: 'Registration Fee', variable: 'registrationFee' }); }

        newValues.registrationFee = setting.registrationFee ?? 0;
    }

    if (setting.displayTitleFee) {
        if (setting.titleFeeTaxable) { taxableFields.push({ name: 'Title Fee', variable: 'titleFee' }); }
        if (!setting.titleFeeTaxable) { nonTaxableFields.push({ name: 'Title Fee', variable: 'titleFee' }); }

        newValues.titleFee = setting.titleFee ?? 0;
    }

    if (setting.displayInspectionFee) {
        if (setting.inspectionFeeTaxable) { taxableFields.push({ name: 'Inspection Fee', variable: 'inspectionFee' }); }
        if (!setting.inspectionFeeTaxable) { nonTaxableFields.push({ name: 'Inspection Fee', variable: 'inspectionFee' }); }

        newValues.inspectionFee = setting.inspectionFee ?? 0;
    }

    for (let x = 1; x <= 7; x += 1) {
        if (setting[`displayUserField${x}`]) {
            if (setting[`userField${x}Taxable`]) { taxableFields.push({ name: setting[`userField${x}Name`], variable: `userField${x}Variable` }); }
            if (!setting[`userField${x}Taxable`]) { nonTaxableFields.push({ name: setting[`userField${x}Name`], variable: `userField${x}Variable` }); }

            newValues[`userField${x}Variable`] = setting[`userField${x}`] ?? 0;
        }
    }

    return {
        taxableFields,
        nonTaxableFields,
        ...newValues,
    };
};

const PaymentCalculator = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation = {} } = useContext(UserContext);
    const [state, setState] = useState({
        selectedLot: null,
        availableLots: [],
        salesPrice: 0,
        otherAmount: 0,
        tradeAllowance: 0,
        tradePayoff: 0,
        paymentFrequency: null,
        term: null,
        rate: null,
        lotsFullSettings: [],
        taxableFields: [],
        nonTaxableFields: [],
        taxableAmount: 0,
        salesTax: 0,
        totalPrice: 0,
        downPayment: 0,
        amountFinanced: 0,
        payment: 0,
    });

    const {
        selectedLot,
        availableLots,
        salesPrice,
        otherAmount,
        tradeAllowance,
        tradePayoff,
        lotsFullSettings,
        taxableFields,
        nonTaxableFields,
        taxableAmount,
        salesTax,
        totalPrice,
        downPayment,
        amountFinanced,
        paymentFrequency,
        term,
        rate,
        payment,
    } = state;

    const {
        data: lotSettings,
        loading: lotSettingsLoading,
        error: lotSettingsError,
    } = useQuery(LotQuery.GET_LOT_SETTINGS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: selectedLot == null,
    });

    const [getDealSettings] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_GENERAL, {
        onCompleted: (response) => {
            if (response) {
                const settings = response.getDealSettingsGeneral;
                if (settings) {
                    const { defaultInterestRate, defaultPaymentFrequency, defaultTerm } = settings;
                    const frequency = (defaultPaymentFrequency ?? PaymentFrequency.MONTHLY).toUpperCase().replace('_', '-');

                    setState((prevState) => ({
                        ...prevState,
                        paymentFrequency: [
                            PaymentFrequency.MONTHLY,
                            PaymentFrequency.BI_WEEKLY,
                            PaymentFrequency.WEEKLY,
                        ].includes(frequency) ? frequency : PaymentFrequency.MONTHLY,
                        term: defaultTerm ?? 60,
                        rate: defaultInterestRate ?? 12,
                    }));
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [calculatePayment, { loading }] = useLazyQuery(DealsQuery.CALCULATE_DEAL_PAYMENT, {
        onCompleted: (response) => {
            if (response) {
                const result = response.calculateDealPayment;
                if (result) {
                    setState((prevState) => ({
                        ...prevState,
                        salesTax: result.salesTax,
                        payment: result.payment,
                    }));
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (selectedLot != null) {
            getDealSettings({
                variables: {
                    lotName: availableLots.find((al) => al.value === selectedLot).label,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLot]);

    useEffect(() => {
        if (lotSettingsError) {
            ModalUtils.errorMessage(lotSettingsError?.graphQLErrors);
            return;
        }

        if (!lotSettingsLoading && lotSettings) {
            const { lotList } = lotSettings;
            setState((prevState) => ({
                ...prevState,
                lotsFullSettings: lotList,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotSettingsLoading, lotSettingsError]);

    useEffect(() => {
        if (Object.keys(userInformation).length > 0) {
            const { defaultLot, usersLot, lots } = userInformation;
            const formattedLots = (lots ?? []).map((l) => ({ value: l.lotId, label: l.lotName }));

            setState((prevState) => ({
                ...prevState,
                selectedLot: formattedLots.find((l) => l.label === (defaultLot ?? usersLot))?.value,
                availableLots: formattedLots,
            }));
        }
    }, [userInformation]);

    useEffect(() => {
        if (selectedLot != null && lotsFullSettings.length > 0) {
            // eslint-disable-next-line no-use-before-define
            resetFees();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLot, lotsFullSettings]);

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        onChange('term', state.term, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxableFields, salesTax, payment]);

    const onChange = (name, value, keepPayment = false) => {
        const fieldsForTaxable = [
            'salesPrice',
            'otherAmount',
            'tradeAllowance',
            ...(state.taxableFields.map((t) => t.variable)),
        ];

        const wasTaxableAmountModified = fieldsForTaxable.includes(name);
        const taxAmount = NumberUtils.round(
            fieldsForTaxable
                .reduce((a, b) => {
                    const isThereNewValue = name === b;
                    const val = isThereNewValue ? value : state[b];

                    return a + (b === 'tradeAllowance' ? (val * -1) : val);
                }, 0),
        );
        const totalNonTaxable = NumberUtils.round(
            state.nonTaxableFields
                .reduce((a, b) => {
                    const isThereNewValue = name === b.variable;
                    const val = isThereNewValue ? value : state[b.variable];

                    return a + val;
                }, 0),
        );

        setState((prevState) => {
            const isDownPayment = name === 'downPayment';
            const isTradePayoff = name === 'tradePayoff';
            const totPrice = taxAmount + prevState.salesTax + totalNonTaxable + (isTradePayoff ? value : prevState.tradePayoff);

            return {
                ...prevState,
                [name]: value,
                taxableAmount: taxAmount,
                salesTax: wasTaxableAmountModified ? 0 : prevState.salesTax,
                totalPrice: totPrice,
                amountFinanced: totPrice - (isDownPayment ? value : prevState.downPayment),
                payment: keepPayment ? prevState.payment : 0,
            };
        });
    };

    const resetFees = () => {
        if (!selectedLot) return;

        const selectedLotSettings = lotsFullSettings.find((s) => s.lotId === selectedLot) ?? {};
        const variables = splitFields(selectedLotSettings);

        setState((prevState) => ({
            ...prevState,
            ...variables,
        }));
    };

    const onCalculate = () => {
        const taxableFieldsAmount = taxableFields.reduce((a, b) => a + state[b.variable], 0);
        const nonTaxableFieldsAmount = nonTaxableFields.reduce((a, b) => a + state[b.variable], 0);

        calculatePayment({
            variables: {
                salesPrice,
                otherAmount,
                tradeAllowance,
                tradePayoff,
                downPayment,
                paymentFrequency,
                annualInterestRate: rate,
                term,
                lotId: selectedLot,
                taxableFeesAmount: taxableFieldsAmount,
                nonTaxableFeesAmount: nonTaxableFieldsAmount,
            },
        });
    };

    if (lotsFullSettings.length === 0 || availableLots.length === 0) return null;
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                Payment Calculator
            </div>
            <div className={classes.content}>
                <div className={classes.topSection}>
                    <div>
                        <span>Payments in the calculator may vary from the deal calculation due to rounding differences.</span>
                    </div>
                    <div>
                        <Select
                            size="sm"
                            name="selectedLot"
                            value={selectedLot ?? -1}
                            options={availableLots}
                            onChange={onChange}
                        />
                        <Button
                            disabled={false}
                            className={classes.containedSecondaryInfo}
                            size="medium"
                            onClick={resetFees}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
                <div className={classes.bottomSection}>
                    <div>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Sales Price:</Form.Label>
                            <InputNumber
                                showCurrency
                                allowNegative={false}
                                value={salesPrice}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('salesPrice', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Tooltip title="Other amounts added to the Sales Price" placement="top">
                                <Form.Label>Other:</Form.Label>
                            </Tooltip>
                            <InputNumber
                                showCurrency
                                allowNegative={false}
                                value={otherAmount}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('otherAmount', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Trade Allowance:</Form.Label>
                            <InputNumber
                                showCurrency
                                allowNegative={false}
                                value={tradeAllowance}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('tradeAllowance', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Trade Payoff:</Form.Label>
                            <InputNumber
                                showCurrency
                                allowNegative={false}
                                value={tradePayoff}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('tradePayoff', value)}
                            />
                        </Form.Group>
                        <div className={classes.subTitle}>
                            Taxable Fees
                        </div>
                        {taxableFields.map((f, index) => (
                            <Form.Group as={Row} className={classes.field} key={index}>
                                <Form.Label>{`${StringUtils.toPascalCase(f.name.replace('*', '')?.toLowerCase())}:`}</Form.Label>
                                <InputNumber
                                    showCurrency
                                    allowNegative={false}
                                    value={state[f.variable]}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    size="sm"
                                    onChange={(value) => onChange(f.variable, value)}
                                />
                            </Form.Group>
                        ))}
                        <div className={classes.subTitle}>
                            Sales Tax
                        </div>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Taxable Amount:</Form.Label>
                            <InputNumber
                                readOnly
                                showCurrency
                                value={taxableAmount}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Sales Tax:</Form.Label>
                            <InputNumber
                                readOnly
                                showCurrency
                                value={salesTax}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <div className={classes.subTitle}>
                            Non-Taxable Fees
                        </div>
                        {nonTaxableFields.map((f, index) => (
                            <Form.Group as={Row} className={classes.field} key={index}>
                                <Form.Label>{`${StringUtils.toPascalCase(f.name.replace('*', '')?.toLowerCase())}:`}</Form.Label>
                                <InputNumber
                                    showCurrency
                                    allowNegative={false}
                                    value={state[f.variable]}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    size="sm"
                                    onChange={(value) => onChange(f.variable, value)}
                                />
                            </Form.Group>
                        ))}
                        <div className={classes.subTitle}>
                            Total Price
                        </div>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Total:</Form.Label>
                            <InputNumber
                                readOnly
                                showCurrency
                                value={totalPrice}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Down Payment:</Form.Label>
                            <InputNumber
                                showCurrency
                                allowNegative={false}
                                value={downPayment}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('downPayment', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Amount Financed:</Form.Label>
                            <InputNumber
                                readOnly
                                showCurrency
                                value={amountFinanced}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                size="sm"
                            />
                        </Form.Group>
                        {paymentFrequency && (
                            <>
                                <div className={classes.subTitle}>
                                    Variables for Payment
                                </div>
                                <Form.Group as={Row} className={classes.field}>
                                    <Form.Label>Payment Frequency:</Form.Label>
                                    <Select
                                        size="sm"
                                        name="paymentFrequency"
                                        value={paymentFrequency}
                                        options={[
                                            PaymentFrequency.MONTHLY,
                                            PaymentFrequency.BI_WEEKLY,
                                            PaymentFrequency.WEEKLY,
                                        ].map((f) => ({
                                            value: f,
                                            label: StringUtils.toPascalCase(f.toLowerCase()),
                                        }))}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={classes.field}>
                                    <Form.Label>Interest Rate:</Form.Label>
                                    <InputNumber
                                        allowNegative={false}
                                        value={rate}
                                        decimalScale={2}
                                        size="sm"
                                        onChange={(value) => onChange('rate', value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={classes.field}>
                                    <Form.Label>Term:</Form.Label>
                                    <InputNumber
                                        allowNegative={false}
                                        value={term}
                                        decimalScale={0}
                                        size="sm"
                                        onChange={(value) => onChange('term', value)}
                                    />
                                </Form.Group>
                                <div className={classes.subTitle}>
                                    Calculated Payment
                                </div>
                                <Form.Group as={Row} className={classes.field}>
                                    <Form.Label>Payment:</Form.Label>
                                    <InputNumber
                                        readOnly
                                        showCurrency
                                        value={payment}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={classes.field}>
                                    <Form.Label />
                                    <Button
                                        disabled={loading}
                                        className={classes.containedSecondaryInfo}
                                        size="medium"
                                        onClick={onCalculate}
                                    >
                                        Calculate
                                    </Button>
                                </Form.Group>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentCalculator;

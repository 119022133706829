import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components and Others
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealService from 'services/modules/DealService';

const EmploymentContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        dealCurrentEmploymentId: PropTypes.string,
        employments: PropTypes.array,
    };

    static defaultProps = {
        employments: null,
        dealCurrentEmploymentId: null,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            record: {},
            open: false,
            saving: false,
            openConfirm: false,
            employmentId: null,
            openFormDialog: false,
            editFromManager: false,
            openManagerDialog: false,
        };

        this.initBind();
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onClose() {
        this.setState({
            open: false,
        });
    }

    onOpenConfirm(employmentId) {
        this.setState({
            openConfirm: true,
            employmentId,
        });
    }

    onCloseConfirm() {
        this.setState({
            openConfirm: false,
            employmentId: null,
        });
    }

    onDelete() {
        const { state: { employmentId } } = this;

        this.dealService.removePreviousEmployment(employmentId)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.removeDealPreviousEmployment) {
                    this.setState({
                        openConfirm: false,
                        employmentId: null,
                    });
                }
            });
    }

    onAdd() {
        const { props: { employments, dealCurrentEmploymentId } } = this;
        const currentEmployment = employments?.find((x) => x.isCurrentEmployment);

        if (dealCurrentEmploymentId && !currentEmployment) {
            this.setCurrent(dealCurrentEmploymentId);
        } else {
            this.toggleEditModal();
        }
    }

    onEdit(record) {
        this.setState({
            record,
            openFormDialog: true,
            editFromManager: true,
        });
    }

    setCurrent(customerEmploymentId) {
        const { props: { accountNumber, type, customerId } } = this;
        this.setState({ saving: true });
        const input = {
            customerId,
            customerEmploymentId,
            dealId: accountNumber,
            type: type.replace(' ', '_'),
        };

        this.dealService.setDealCurrentEmployment({ input })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) ModalUtils.errorMessage(graphQLErrors);
                else ModalUtils.successMessage(null, 'Current Employment was updated.');
            })
            .finally(() => this.setState({ saving: false }));
    }

    toggleManager() {
        this.setState((prev) => ({
            openManagerDialog: !prev.openManagerDialog,
            editFromManager: !prev.editFromManager,
        }));
    }

    toggleEditModal() {
        const { props: { employments }, state: { editFromManager } } = this;
        const recordToEdit = editFromManager ? {} : employments?.find((x) => x.isCurrentEmployment) || {};

        this.setState((prevState) => ({
            openFormDialog: !prevState.openFormDialog,
            record: recordToEdit,
            editFromManager,
        }));
    }

    initBind() {
        this.onAdd = this.onAdd.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this.toggleManager = this.toggleManager.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
                onOpen={this.onOpen}
                onClose={this.onClose}
                onDelete={this.onDelete}
                setCurrent={this.setCurrent}
                onOpenConfirm={this.onOpenConfirm}
                toggleManager={this.toggleManager}
                onCloseConfirm={this.onCloseConfirm}
                toggleEditModal={this.toggleEditModal}
            />
        );
    }
};

export default EmploymentContainer;

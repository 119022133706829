import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import PlaidMappingsQuery from 'services/graphQL/query/plaid/PlaidMappingsQuery';
import {
    Dialog, DialogContent, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        height: 350,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(1, 1.8),
    },
}));

const initialState = {
    records: [],
    institutionId: '',
    institutionName: '',
};

const PlaidBankDialog = ({ onClose, onBankSelected }) => {
    const classes = useStyles();
    const [state, setState] = useState(initialState);

    const {
        data, loading, error,
    } = useQuery(PlaidMappingsQuery.GET_PLAID_BANK_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getPlaidBankList)) {
            const { getPlaidBankList } = data;
            setState((previousState) => ({
                ...previousState,
                records: getPlaidBankList,
            }));
        }
    }, [data, loading, error]);

    const onTableClicked = ({ institutionId, institutionName }) => {
        setState((previousState) => ({
            ...previousState,
            institutionId,
            institutionName,
        }));
    };

    const getColumns = () => [{
        minWidth: 30,
        Header: 'Bank Id',
        id: 'institutionId',
        accessor: 'institutionId',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 70,
        Header: 'Bank Name',
        id: 'institutionName',
        accessor: 'institutionName',
        className: 'd-flex-justify-center-align-center',
    }];

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            <DialogAppBar
                title="Banks"
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Table
                    rowSelected
                    load={loading}
                    totalRecords={state.records.length}
                    data={state.records}
                    columns={getColumns()}
                    getTrProps={(_, rowInfo) => {
                        const record = rowInfo.original;

                        return {
                            onDoubleClick: () => onBankSelected(record),
                            onClick: () => onTableClicked(record),
                        };
                    }}
                />
            </DialogContent>
            <DialogActions
                titlePrimary="Ok"
                variant="contained"
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                disablePrimaryButton={isEmpty(state.institutionId)}
                onClickPrimary={() => onBankSelected({ institutionId: state.institutionId, institutionName: state.institutionName })}
            />
        </Dialog>
    );
};

PlaidBankDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onBankSelected: PropTypes.func.isRequired,
};

export default PlaidBankDialog;

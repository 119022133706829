import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Bootstrap
import { Tab } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import Tabs from 'components/widgets/tabs/Tabs';

// Components
import CallActivity from 'components/modules/crm/activities/CallActivity';
import TaskActivity from 'components/modules/crm/activities/TaskActivity';
import NoteActivity from 'components/modules/crm/activities/NoteActivity';
import EmailActivity from 'components/modules/crm/activities/EmailActivity';
import EventActivity from 'components/modules/crm/activities/EventActivity';
import TrackActivity from 'components/modules/crm/activities/TrackActivity';

const useStyles = makeStyles(() => ({
    tab: {
        '@media (max-width: 2048px)': {
            '& .nav-item': {
                minWidth: '100px',
            },
        },
        '@media (max-width: 1920px)': {
            '& .nav-item': {
                minWidth: 'auto',
            },
        },
    },
}));

const ActivityTabs = ({
    crmId, customerCode, email, lotId, prospectLanguage,
    leadCode, defaultActiveKey, locationState,
}) => {
    const classes = useStyles();
    const config = [
        {
            title: 'Activity',
            component: <TrackActivity crmId={crmId} lotId={lotId} locationState={locationState} />,
        },
        {
            title: 'Calls',
            component: <CallActivity crmId={crmId} lotId={lotId} />,
        },
        {
            title: 'Tasks',
            component: <TaskActivity crmId={crmId} lotId={lotId} />,
        },
        {
            title: 'Notes',
            component: <NoteActivity crmId={crmId} />,
        },
        {
            title: 'Appointments',
            component: <EventActivity crmId={crmId} lotId={lotId} />,
        },
        {
            title: 'Email',
            component: <EmailActivity
                crmId={crmId}
                customerCode={customerCode}
                email={email}
                prospectLanguage={prospectLanguage}
                leadCode={leadCode}
                locationState={locationState}
            />,
        },
    ];

    return (
        <div className={clsx('d-flex-column overflow-hidden tab-container')}>
            <Tabs
                id="controlled-tab"
                mountOnEnter
                className={classes.tab}
                defaultActiveKey={defaultActiveKey}
            >
                {config.map((item, index) => (
                    <Tab eventKey={index} mountOnEnter title={item.title} key={index}>
                        {item.component}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

ActivityTabs.propTypes = {
    lotId: PropTypes.number,
    email: PropTypes.string,
    leadCode: PropTypes.number,
    customerCode: PropTypes.number,
    locationState: PropTypes.object,
    defaultActiveKey: PropTypes.number,
    crmId: PropTypes.string.isRequired,
    prospectLanguage: PropTypes.string,
};

ActivityTabs.defaultProps = {
    email: null,
    customerCode: null,
    lotId: null,
    leadCode: null,
    defaultActiveKey: 0,
    locationState: {},
    prospectLanguage: 'English',
};

export default ActivityTabs;

import { gql } from '@apollo/client';

export default class SettingsQuery {
    static LIST_PRODUCTS_VENDOR = gql`
        query getProductsVendor($searchTerm: String, $status: Boolean) {
            getProductsVendor(searchTerm: $searchTerm, status: $status) {
                productId
                vendorId
                vendorName
                productName
                productPackage
                productType
                productActive
                isLine1
            }
        }
    `;

    static PRODUCT_VENDOR_DETAIL = gql`
        query getProductVendor($productId: Int!) {
            getProductVendor(productId: $productId) {
                productId
                vendorId
                vendorName
                productName
                productPackage
                productType
                productActive
                imported
                isLine1
                menuVendordetail {
                    providerCompanyId
                }
                menuProductDetail {
                    menuProductDetailId
                    providerProductId
                    providerProductCode
                    description
                    bulletPoints
                    isTaxable
                    notRegulated
                    pdfContract
                    terms {
                        menuProductTermId
                        term
                        cost
                        price
                    }
                }
            }
        }
    `;
}

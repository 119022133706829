/* eslint-disable import/prefer-default-export */
const CodeType = Object.freeze({
    CRM: 'CRM',
    AELD: 'AELD',
    ASSET_INVENTORY: 'Asset Inventory',
    BHPH: 'BHPH',
    COMPANY: 'Company',
    DEALS: 'Deals',
    EQUIPMENT: 'Equipment',
    INVENTORY: 'Inventory',
    PARTS_INVENTORY: 'Parts Inventory',
    PARTS_TICKET: 'Parts Ticket',
    PURCHASE: 'Purchase',
    QUEUE_SETTINGS: 'QueueSettings',
    QUEUE_V2: 'QUEUEV2',
    SERVICE: 'Service',
    CUSTOMER: 'Customer',
});

export { CodeType };

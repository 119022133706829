import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import {
    Dialog, DialogContent, makeStyles,
} from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import Select from 'components/widgets/Select';
import { ProcessStage } from 'components/modules/crm/enum/Opportunity';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import { useMutation } from '@apollo/client';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import useStage from 'components/hook/crm/useStage';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: '400px',
        padding: theme.spacing(3),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ProcessStageDialog = ({
    onClose, open, value, crmId,
    refetchAutomatedResponseStatus,
}) => {
    const classes = useStyles();
    const [stage, setStage] = useState(null);
    const { stages } = useStage();
    const newEmailLead = ProcessStage.NEW_EMAIL_LEAD.toUpperCase();
    const options = stage?.toUpperCase() === newEmailLead
        ? stages.filter((item) => ![ProcessStage.AUTOMATED_FOLLOW_UP, ProcessStage.AI_ENGAGED].includes(item.value))
        : stages.filter((item) => ![ProcessStage.AUTOMATED_FOLLOW_UP, ProcessStage.AI_ENGAGED, ProcessStage.NEW_EMAIL_LEAD].includes(item.value));
    const [setProcessStage, { loading: isUpdating }] = useMutation(OpportunityMutation.SET_PROCESS_STAGE);

    const onChangeProcessStage = async () => {
        try {
            const input = {
                opportunityId: crmId,
                processStage: stage,
            };

            const response = await setProcessStage({ variables: input });

            if (response.data?.setProcessStage) {
                refetchAutomatedResponseStatus();
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'opportunity'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    useEffect(() => {
        setStage(value);
    }, [value]);

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Edit Process Stage" onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <Form.Group>
                    <Select
                        name="processStage"
                        options={options}
                        value={stage}
                        onChange={(_, record) => setStage(record)}
                        maxMenuHeight={200}
                    />
                </Form.Group>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickSecondary={onClose}
                onClickPrimary={onChangeProcessStage}
                disablePrimaryButton={value === stage || isUpdating}
            />
        </Dialog>
    );
};

ProcessStageDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    crmId: PropTypes.string.isRequired,
    refetchAutomatedResponseStatus: PropTypes.func,
};

ProcessStageDialog.defaultProps = {
    refetchAutomatedResponseStatus: () => null,
};

export default ProcessStageDialog;

import { gql } from '@apollo/client';

export default class ColumnSettingsQuery {
    static GET_COLUMN_SETTINGS_BY_TABLE = gql`
        query getColumnSettingsByTable($module: String!, $tableName: String!) {
            getColumnSettingsByTable(module: $module, tableName: $tableName){
                columnName
                columnOrder
                columnWidth
                isDisplayed
            }
        }
    `;
}

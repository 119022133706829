import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TitleRegistrationContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        registration: PropTypes.number.isRequired,
        title: PropTypes.number.isRequired,
        tags: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        onApplyTitleRegistration: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            registration: 0,
            title: 0,
            tags: 0,
        };

        this.initBind();
    }

    componentDidMount() {
        const { props } = this;

        this.setState({
            registration: props.registration,
            title: props.title,
            tags: props.tags,
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    onApply() {
        const { props } = this;
        const { state: { registration, title, tags } } = this;

        props.onApplyTitleRegistration({ registration, title, tags });
        props.toggleModal();
    }

    initBind() {
        this.onApply = this.onApply.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onApply={this.onApply}
                onChange={this.onChange}
            />
        );
    }
};

export default TitleRegistrationContainer;

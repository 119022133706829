import React, { useState, useEffect } from 'react';

import {
    DialogContent, Dialog, Typography, Button, IconButton, Grid,
    makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    contentList: {
        flex: 1,
        overflow: 'auto',
        '& .customer-item': {
            border: `1px solid ${theme.palette.divider}`,
            fontSize: 14,
            borderBottom: 0,
            paddingRight: '16px',
        },
        '& .customer-item:last-child': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: 1,
        },
    },
    dialogWidth: {
        '& > div > div': {
            width: '600px',
        },
    },
    dialogContent: {
        padding: '15px 30px',
    },
    textItem: {
        fontWeight: 'bold',
        padding: theme.spacing(1),
        cursor: 'pointer',
    },
    noHover: {
        color: 'black',
        backgroundColor: 'transparent !important',
    },
}));

const ListLotToTransfer = ({ records, onSelectRecord }) => {
    const classes = useStyles();

    return (
        <div className={classes.contentList}>
            { records?.map((item) => (
                <Grid
                    container
                    className="show-icon-when-hovering customer-item"
                    justify="center"
                    alignItems="center"
                    key={item.companyCode}
                >
                    <Grid item xs={10} container>
                        <Typography className={classes.textItem}>{item.companyName}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button
                                size="small"
                                className="show-hidden-item"
                                onClick={() => {
                                    onSelectRecord(item);
                                }}
                            >
                                Transfer
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                disableRipple
                                aria-label="delete"
                                className={classes.noHover}
                                size="small"
                            >
                                <ArrowForward fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

ListLotToTransfer.propTypes = {
    records: PropTypes.array.isRequired,
    onSelectRecord: PropTypes.func.isRequired,
};

const TransferToRFC = ({
    recordId,
    onSend,
    closeDialog,
    sendingToRFC,
}) => {
    const classes = useStyles();
    const initialState = {
        records: [],
        selectedRFC: null,
        openConfirmDialog: false,
    };
    const [state, setState] = useState(initialState);
    const description = `Do you want to transfer the Deal #${recordId} to ${state.selectedRFC?.companyName}?`;

    const {
        data, loading, error,
    } = useQuery(DealsQuery.GET_RFC_COMPANY_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getRFCCompanyList } = data;
            setState((previousState) => ({ ...previousState, records: getRFCCompanyList }));
        }
    }, [data, loading, error]);

    const onSelectRecord = (value) => {
        setState((previousState) => ({
            ...previousState,
            selectedRFC: value,
            openConfirmDialog: true,
        }));
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="xl"
            scroll="paper"
            className={classes.dialogWidth}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                titleVariant="h4"
                onClose={closeDialog}
                title="Transfer Deal To RFC"
            />
            <DialogContent className={classes.dialogContent} dividers>
                <ListLotToTransfer
                    records={state.records}
                    onSelectRecord={onSelectRecord}
                />
            </DialogContent>
            <DialogActions
                hiddenPrimaryButton
                onClickSecondary={closeDialog}
            />
            <ConfirmDialog
                titlePrimary="Yes"
                variant="outlined"
                titleSecondary="No"
                title="Confirm transfer"
                description={description}
                open={state.openConfirmDialog}
                onClickPrimary={() => onSend(state.selectedRFC?.companyCode)}
                onClose={() => setState((previousState) => ({ ...previousState, openConfirmDialog: false }))}
                onClickSecondary={() => setState((previousState) => ({ ...previousState, openConfirmDialog: false }))}
                disablePrimaryButton={sendingToRFC}
                disableSecondaryButton={sendingToRFC}
            />
        </Dialog>
    );
};

TransferToRFC.propTypes = {
    recordId: PropTypes.number.isRequired,
    onSend: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    sendingToRFC: PropTypes.bool.isRequired,
};

export default TransferToRFC;

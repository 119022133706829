/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

const ProductSettingSchema = yup.object().shape({
    productName: yup.string().required(),
    vendorId: yup.number().required(),
    productType: yup.string().required(),
});

export {
    ProductSettingSchema,
};

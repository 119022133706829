import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';

export default class CustomerService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Delete Customer Address
     * @param {Object} input
     * @param {number} data.customerAddressId
    */
    async deleteCustomerAddress(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.DELETE_CUSTOMER_ADDRESS, input);
    }

    /**
     * Create Customer
     * @param {Object} input Information of the customer to insert.
    */
    async create(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.CREATE_CUSTOMER, { input });
    }

    /**
     * Create Full Customer
     * @param {Object} input Information of the customer, address and employment to insert.
    */
    async createFullCustomer(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.CREATE_FULL_CUSTOMER, { input });
    }

    /**
     * @param input Information of the customer to update.
    */
    async updateCustomer(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.UPDATE_CUSTOMER, input);
    }

    /**
     * Create Customer Employment
     * @param {Object} input Information of the customer employment to insert.
    */
    async createCustomerEmployment(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.CREATE_CUSTOMER_EMPLOYMENT, input);
    }

    /**
     * @param {Object} input
     * @param {string} input.customerEmploymentId ID of the employment to be deleted
    */
    async deleteCustomerEmployment(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.DELETE_CUSTOMER_EMPLOYMENT, input);
    }

    /**
     * @param input Information of the customer employment to update.
    */
    async updateCustomerEmployment(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.UPDATE_CUSTOMER_EMPLOYMENT, input);
    }

    async getList(input) {
        return this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMERS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return response;
                }

                const { getCustomerList } = data;

                return {
                    data: getCustomerList,
                };
            });
    }

    async createCustomerAddress(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.SAVE_CUSTOMER_ADDRESS, input);
    }

    async updateCustomerAddress(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.UPDATE_ADDRESS, input);
    }

    /**
     * Get address list
     * @param {Object} input
     * @param {Object} input.sort
     * @param {string} input.sort.field
     * @param {string} input.sort.dir
     * @param {Object} input.input
     * @param {string} input.input.customerId
     * @param {string} input.input.status
     * @returns {Promise<Object>}
    */
    async getAddressList(input) {
        return this.graphqlClient
            .query(CustomerQuery.GET_ADDRESS_LIST, input);
    }

    /**
     * To set a customer address as current address of the customer
     * @param { Object } input - contain information about the customer and customer address
     * @returns {Promise<*|FetchResult<any>>}
     */
    async setCurrentAddress(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.SET_CURRENT_ADDRESS, input);
    }

    /**
     * Set Current Employment
     * @param {Object} input
     * @param {string} input.customerId
     * @param {string} input.customerEmploymentId
    */
    async setCurrentEmployment(input) {
        return this.graphqlClient
            .mutate(CustomerMutate.SET_CURRENT_EMPLOYMENT, input);
    }

    /**
     * Set Current Employment
     * @param {Object} input
     * @param {number} input.customerCode
    */
    async getCurrentEmploymentId(input) {
        return this.graphqlClient
            .query(CustomerQuery.GET_CURRENT_EMPLOYMENT_ID, input);
    }

    /**
     * Retrieve a list of Customer Employments
     * @param {Object} input
     * @param {Object} input.sort
     * @param {string} input.sort.field
     * @param {string} input.sort.dir
     * @param {Object} input.input
     * @param {string} input.input.customerId
     * @param {string} input.input.status
     * @returns {Promise<Object>}
    */
    async getEmploymentList(input) {
        return this.graphqlClient
            .query(CustomerQuery.GET_EMPLOYMENT_LIST, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                if (!data || !data.getCustomerEmploymentList) {
                    return { data: [] };
                }

                const { getCustomerEmploymentList } = data;

                return { data: getCustomerEmploymentList };
            });
    }

    /**
     * Retrieve Customer Information with Address and Employment
     * @param {Object} input
     * @returns {Promise<Object>}
    */
    async getCustomerAndAddress(input) {
        return this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMER_AND_ADDRESS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                if (!data || !data.getCustomerAndAddress) {
                    return { data: [] };
                }

                const { getCustomerAndAddress } = data;

                return { data: getCustomerAndAddress };
            });
    }
}

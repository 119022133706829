import palette from 'styles/theme/palette';

export default class AccountingStyles {
    static basicStyles(theme = {}) {
        return ({
            alignContent: {
                display: 'contents',
            },
            labelFont: {
                fontSize: 14,
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            overFlowAuto: {
                overflow: 'auto',
            },
            printingButtons: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '5px',
                    justifyContent: 'flex-end',
                    display: 'flex',
                },
                '& > button:nth-child(1)': {
                    marginRight: '5px',
                },
            },
        });
    }

    static searchStyles(theme = {}) {
        return ({
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            search: {
                borderRadius: 10,
                width: 230,
                '& .form-control': {
                    height: 'calc(1.5em + 0.5rem + 2px)',
                    padding: '0.25rem 0.5rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                    borderRadius: '0.2rem',
                },
            },
            searchFull: {
                width: '100%',
            },
        });
    }

    static columnStyles(theme = {}) {
        return ({
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
        });
    }

    static dateCalendarStyles() {
        return ({
            date: {
                width: '100px',
                height: '2rem',
            },
            dateInRange: {
                width: '88px',
            },
        });
    }

    static containerStyles() {
        return ({
            flexContainer: {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                paddingBottom: '25px',
            },
            bottomTableHeight: {
                flex: 1,
            },
        });
    }

    static buttonStyles(theme = {}) {
        return ({
            buttonSpacing: {
                display: 'flex',
                flexWrap: 'wrap',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
                textAlign: 'center',
            },
            deleteButton: {
                color: theme.palette.error.main,
            },
            actionButtonPrint: {
                color: theme.palette.text.royalBlue,
            },
            actionButtonSuccess: {
                color: theme.palette.text.outerSpace,
            },
            actionButtonError: {
                color: theme.palette.error.main,
            },
            actionButtonWarning: {
                color: theme.palette.warning.main,
            },
            actionButtonMain: {
                color: theme.palette.success.main,
            },
            actionButtonApprove: {
                color: theme.palette.text.green,
            },
            buttonGreen: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                },
            },
            actionButtonSize: {
                fontSize: '1.2rem',
                cursor: 'pointer',
            },
            buttonWrapper: {
                padding: '6px 3px',
            },
        });
    }

    static mainList(theme = {}) {
        return ({
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            boxContainer: {
                flexDirection: 'column',
            },
            title: {
                color: theme.palette.text.boulderGray,
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            actionButtonSize: {
                fontSize: '1.2rem',
                cursor: 'pointer',
            },
            actionButtonPrint: {
                color: theme.palette.text.royalBlue,
            },
            actionButtonSuccess: {
                color: theme.palette.text.outerSpace,
            },
            actionButtonError: {
                color: theme.palette.error.main,
            },
            actionButtonWarning: {
                color: theme.palette.warning.main,
            },
            actionButtonMain: {
                color: theme.palette.success.main,
            },
            actionButtonApprove: {
                color: theme.palette.text.green,
            },
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
            marginleft20: {
                marginLeft: '20px',
            },
            containerSplit: {
                flexDirection: 'column',
                padding: '0px',
            },
            split: {
                flexDirection: 'column',
                height: '100%',
            },
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                '& > *': {
                    marginRight: 10,
                },
                '& > button': {
                    minWidth: 104,
                },
                flexWrap: 'wrap',
            },
            alignContent: {
                display: 'contents',
            },
            date: {
                width: '100px',
                height: '2rem',
            },
            dateInRange: {
                width: '88px',
            },
            dropdown: {
                width: '100px',
            },
            dropdownWidth200: {
                width: '200px',
            },
            dropdownWidth150: {
                width: '150px',
            },
            badgeCleared: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
            },
            badgeVoid: {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.white,
            },
            badgeApproved: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
            },
            badgePrinted: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
            },
            labelFont: {
                fontSize: 14,
            },
            search: {
                borderRadius: 10,
                width: 230,
                '& .form-control': {
                    height: 'calc(1.5em + 0.5rem + 2px)',
                    padding: '0.25rem 0.5rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                    borderRadius: '0.2rem',
                },
            },
            buttonSave: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
            voidPaymentInput: {
                paddingTop: 10,
                paddingLeft: 0,
                paddingRight: 0,
            },
            searchFull: {
                width: '100%',
            },
            hidden: {
                display: 'none',
            },
            vendorBalanceTable: {
                height: 'calc(100vh - 166px)',
            },
            voidCls: {
                color: theme.palette.text.oldBrick,
            },
            highlightedColor: {
                color: theme.palette.text.wildBlueYonder,
            },
            highlightedBackground: {
                backgroundColor: theme.palette.text.wildBlueYonder,
            },
        });
    }

    static settingsPanel(fade, theme = {}) {
        return ({
            panelContainer: {
                flex: 1,
                overflow: 'hidden',
                '&::-webkit-scrollbar': {
                    left: 100,
                },
                border: `solid 2px ${palette.border.primary}`,
            },
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
            },
            boxContainer: {
                flexDirection: 'column',
                background: theme.palette.background.default,
            },
            title: {
                color: theme.palette.text.boulderGray,
            },
            box: {
                flexDirection: 'column',
                height: 'calc(100% - 50px)',
            },
            paddingLeft25: {
                paddingLeft: '25px',
            },
            labelContainer: {
                width: 'auto',
                padding: 0,
            },
            iconLabelWrapper: {
                flexDirection: 'row',
                alignItems: 'normal',
            },
            iconStyle: {
                fontSize: '1.8rem',
                '& > g > g > *': {
                    stroke: 'currentColor',
                },
            },
        });
    }

    static dialogForm(theme = {}) {
        return ({
            toolbar: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            appBar: {
                position: 'relative',
            },
            appBarMove: {
                position: 'relative',
                cursor: 'move',
            },
            title: {
                marginRight: theme.spacing(5),
                flex: 1,
                color: theme.palette.text.white,
            },
            margin5: {
                marginRight: theme.spacing(5),
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonWhite: {
                color: theme.palette.text.white,
                borderColor: '#808080',
            },
            buttonDisable: {
                color: `${theme.palette.text.whiteTransparent} !important`,
            },
            marginleft20: {
                marginLeft: '20px',
            },
            containerSplit: {
                flexDirection: 'column',
                overflow: 'auto',
                padding: '20px',
            },
            containerSplit2: {
                flexDirection: 'column',
                overflow: 'auto',
                padding: '20px 5px',
            },
            dialogContent: {
                height: '90vh',
                padding: 0,
            },
            centerItems: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 0,
            },
            buttonClear: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.warning.dark,
                },
            },
            buttonNew: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                },

            },
            buttonSave: {
                backgroundColor: theme.palette.secondaryInfo.main,
                color: theme.palette.secondaryInfo.contrastText,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: theme.palette.secondaryInfo.dark,
                },
            },
            buttonCancel: {
                color: theme.palette.text.stormGray,
                backgroundColor: theme.palette.cancel.main,
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            buttonDelete: {
                color: theme.palette.error.main,
            },
            noPaddingSides: {
                paddingLeft: 0,
                paddingRight: 0,
            },
            noPadding: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            },
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
            table: {
                maxHeight: 'calc(50vh)',
                minHeight: '450px',
                '& .rt-td': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
            cashierCheckBox: {
                fontSize: '0.875rem',
                marginTop: '5px',
                marginLeft: '10px',
            },
            tablePanel: {
                paddingBottom: '25px',
            },
            splitOne: {
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
            },
            autocompleteSM: {
                backgroundColor: theme.palette.background.white,
                '& > div': {
                    margin: 0,
                    '&:hover': {
                        backgroundColor: `${theme.palette.background.white} !important`,
                        borderColor: `${theme.palette.border.malibu} !important`,
                        outline: 0,
                        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%) !important',
                    },
                },
            },
            fullWidth: {
                width: '100%',
            },
            badgeCleared: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
            },
            badgeVoid: {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.white,
            },
            badgeApproved: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
            },
            badgePrinted: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
            },
            fontSmall: {
                fontSize: '12px',
                fontWeight: 'bold',
            },
            verticalSplit: {
                flexDirection: 'column',
                height: '100%',
            },
            paddingSides15: {
                paddingLeft: 15,
                paddingRight: 15,
            },
            date: {
                height: '2rem',
            },
            textRight: {
                textAlign: 'right',
            },
            dialogContentGeneralJournal: {
                height: '90vh',
                '& > div:first-child': {
                    height: '100%',
                },
                padding: 0,
            },
            borderTop: {
                borderTop: `1px solid ${palette.border.gallery}`,
            },
            selectSM: {
                padding: 0,
                height: 'calc(1.5em + 0.75rem + 3.5px)',
                '& .css-yk16xz-control': {
                    borderColor: 'transparent',
                },
                '& > *': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
                '& > .select__control .select__dropdown-indicator': {
                    padding: '0px 5px',
                },
            },
            control100: {
                width: '100%',
            },
        });
    }

    static cashierOpenBalance(theme = {}) {
        return ({
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.5),
                },
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
            actionButtonSize: {
                fontSize: '1.2rem',
                cursor: 'pointer',
            },
            actionButtonSuccess: {
                color: theme.palette.text.outerSpace,
            },
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center !important',
            },
            split: {
                flexDirection: 'column',
                height: '100%',
            },
            splitOne: {
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
            },
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            search: {
                borderRadius: 10,
                width: 230,
                '& .form-control': {
                    height: 'calc(1.5em + 0.5rem + 2px)',
                    padding: '0.25rem 0.5rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                    borderRadius: '0.2rem',
                },
            },
            itemContainer: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: '2px',
            },
            operationColumn: {
                '& > h6:first-child': {
                    marginTop: '5px',
                    fontSize: '13px',
                },
                textAlign: 'right',
            },
            operationColumnLeft: {
                '& > h6:first-child': {
                    marginTop: '5px',
                    fontSize: '13px',
                },
                textAlign: 'left',
            },
            expansionCol: {
                color: '#382652',
                width: '100%',
            },
            boldHeaderColor: {
                textAlign: 'right',
                lineHeight: 'normal',
                fontSize: '13px',
                fontWeight: 'bold',
                whiteSpace: 'break-spaces',
            },
            boldHeaderLeft: {
                textAlign: 'left',
                lineHeight: 'normal',
                fontSize: '13px',
                fontWeight: 'bold',
            },
            dividerPrimary: {
                backgroundColor: theme.palette.primary.main,
                marginLeft: '15px',
                marginRight: theme.spacing(-1),
            },
            searchFull: {
                width: '100%',
            },
            containerSplit: {
                flexDirection: 'column',
                padding: '0px',
            },
            customHeight: {
                height: 'calc(100vh - 210px)',
            },
            columnFlexEnd: {
                alignItems: 'center',
                justifyContent: 'flex-end',
                textAlign: 'center !important',
            },
            paddingLeft10: {
                paddingLeft: '10px',
            },
            paddingLeft3: {
                paddingLeft: '3px',
            },
        });
    }

    static checkActionList(theme = {}) {
        return ({
            dialogContent: {
                height: '90vh',
                '& > div:first-child': {
                    height: '90vh',
                },
                padding: 0,
                overflow: 'hidden',
            },
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            splitVertical: {
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
            },
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            appBar: {
                position: 'relative',
            },
            appBarMove: {
                position: 'relative',
                cursor: 'move',
            },
            centerItems: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonSave: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
            buttonDisable: {
                color: `${theme.palette.text.whiteTransparent} !important`,
            },
            title: {
                marginRight: theme.spacing(5),
                flex: 1,
                color: theme.palette.text.white,
            },
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
            date: {
                width: '100px',
            },
            list: {
                overflow: 'auto',
                padding: 5,
                backgroundColor: '#F7FAFF',
            },
            listItem: {
                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                borderRadius: 1,
                marginBottom: 10,
                backgroundColor: '#fff',
            },
            listItemApproved: {
                backgroundColor: '#ccedff !important',
            },
            checkboxSelector: {
                textAlign: 'center',
            },
            textBold: {
                fontSize: 12,
            },
            text: {
                fontSize: 12,
                color: '#0000008a',
            },
            search: {
                borderRadius: 10,
                width: 230,
                '& .form-control': {
                    height: 'calc(1.5em + 0.5rem + 2px)',
                    padding: '0.25rem 0.5rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                    borderRadius: '0.2rem',
                },
            },
            flex: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            centerDiv: {
                textAlign: 'center',
                marginTop: 15,
            },
            searchFull: {
                width: '100%',
            },
            mainTable: {
                height: 'calc(100vh - 190px)',
            },
            badgeApproved: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
            },
            highlightedColor: {
                color: theme.palette.text.wildBlueYonder,
            },
            highlightedBackground: {
                backgroundColor: theme.palette.text.wildBlueYonder,
            },
        });
    }
}

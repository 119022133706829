import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import Label from 'components/widgets/Label';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { ReactComponent as RemoveOutlined } from 'assets/remove.svg';
import EmploymentDialog from 'components/widgets/customer/EmploymentDialog';
import EmploymentContent from 'components/widgets/customer/EmploymentContent';
import EmploymentHistory from 'components/modules/deals/read/buyer/EmploymentHistory';
import EmploymentContainer from 'components/containers/deals/read/buyer/EmploymentContainer';
import ManageEmployment from 'components/modules/customer/read/personalTab/ManageEmployment';

// Material UI
import {
    IconButton, Button, Divider, Paper, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';
import { BorderColorOutlined as EditIcon } from '@material-ui/icons';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => ({
    content: {
        padding: theme.spacing(1),
    },
    addButton: {
        padding: 0,
    },
    textIcon: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing(1.5),
        cursor: 'pointer',
        color: theme.palette.text.infoDark,
    },
    current: {
        color: theme.palette.text.shipCove,
        background: theme.palette.background.alabaster,
        borderRadius: '10px',
        height: '20px',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        width: '58px',
        textTransform: 'capitalize',
    },
    currentRow: {
        alignItems: 'flex-end',
    },
    title: {
        color: theme.palette.text.stormGray,
        fontWeight: 500,
    },
    paper: {
        minHeight: 310,
        maxHeight: 310,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    footer: {
        padding: theme.spacing(0.5, 1.8),
    },
    primaryButton: {
        color: theme.palette.info.main,
    },
    body: {
        flex: 1,
        overflow: 'auto',
    },
    boxHeader: {
        padding: theme.spacing(1, 2),
        minHeight: 38,
    },
    generalClassBox: {
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    loading: {
        padding: '30px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    outdatedTag: {
        color: theme.palette.text.white,
        background: theme.palette.error.light,
        borderRadius: '10px',
        height: '20px',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        width: '58px',
        textTransform: 'capitalize',
    },
    updateButton: {
        color: theme.palette.warn.main,
        marginTop: '60px',
    },
    editButton: {
        color: theme.palette.text.gray,
        marginTop: '60px',
    },
});

class Employment extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_CUSTOMER_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_WRITE);
    }

    renderButtonDelete(dealPreviousEmploymentId) {
        const { props: { classes, onOpenConfirm } } = this;

        return (
            <div className={classes.contentButtonDelete}>
                <IconButton
                    aria-label="delete"
                    size="small"
                    className="show-hidden-item"
                    onClick={() => onOpenConfirm(dealPreviousEmploymentId)}
                >
                    <RemoveOutlined />
                </IconButton>
            </div>
        );
    }

    render() {
        const {
            props: {
                classes, employments, open, onClose, accountNumber, onOpen, openConfirm,
                onCloseConfirm, onDelete, clientId, customerId, loading, postedDate,
                editingStatus, dealCurrentEmploymentId, onAdd, toggleManager, saving,
                setCurrent, openFormDialog, record, toggleEditModal, openManagerDialog,
                editFromManager,
            },
        } = this;
        const isNotPosted = postedDate === null;
        const { isEditing } = editingStatus;
        const currentEmployment = employments?.find((x) => x.isCurrentEmployment);
        const allowAddEmployment = isNotPosted && isEmpty(currentEmployment) && !isEditing;
        const allowEditEmployment = isNotPosted && !isEmpty(currentEmployment) && !isEditing;
        const isEmploymentOutdated = isNotPosted && dealCurrentEmploymentId !== currentEmployment?.customerEmploymentId;

        return (
            <Paper
                elevation={0}
                square
                className={clsx(classes.paper)}
            >
                <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                    <Typography variant="h5" color="primary" className={classes.title}>Employment</Typography>
                    <div>
                        {DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.SALES_DEAL_CUSTOMER_WRITE && allowAddEmployment && (
                            <IconButton
                                color="primary"
                                aria-label="Add Employment"
                                component="span"
                                onClick={onAdd}
                                className={classes.addButton}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        {DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.SALES_DEAL_CUSTOMER_WRITE && allowEditEmployment && (
                            <Button
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={toggleManager}
                            >
                                Manage
                            </Button>
                        )}
                    </div>
                </div>
                <Divider />
                <div className={classes.body}>
                    {saving && (
                        <div className={classes.loading}>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    )}
                    {!saving && (map(employments, (item, index) => {
                        let classCurrent = '';
                        let rightSectionButton = null;
                        const rightSectionTagLabel = isEmploymentOutdated ? 'Outdated' : 'Current';
                        const rightSectionTag = <Label className={isEmploymentOutdated ? classes.outdatedTag : classes.current}>{rightSectionTagLabel}</Label>;

                        if (DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.SALES_DEAL_CUSTOMER_WRITE
                        && allowEditEmployment && !isEmploymentOutdated) {
                            rightSectionButton = (
                                <Button
                                    className={classes.editButton}
                                    variant="text"
                                    onClick={onAdd}
                                >
                                    Edit
                                </Button>
                            );
                        } else if (isEmploymentOutdated) {
                            rightSectionButton = (
                                <Button
                                    className={classes.updateButton}
                                    variant="text"
                                    onClick={() => setCurrent(dealCurrentEmploymentId)}
                                >
                                    Update
                                </Button>
                            );
                        }

                        let rightSection = (
                            <div>
                                {rightSectionTag}
                                {rightSectionButton}
                            </div>
                        );

                        if (!item.isCurrentEmployment) {
                            rightSection = this.SALES_DEAL_CUSTOMER_WRITE ? this.renderButtonDelete(item.dealPreviousEmploymentId) : null;
                            classCurrent = classes.currentRow;
                        }

                        return (
                            <EmploymentContent
                                key={index}
                                record={item}
                                className={clsx(classes.content, classCurrent, 'show-icon-when-hovering')}
                                classNameFooter={classes.classesFooter}
                                rightSection={rightSection}
                            />
                        );
                    }))}
                </div>
                {DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.SALES_DEAL_CUSTOMER_WRITE && (
                    <>
                        <Divider />
                        <div className={clsx(classes.footer, 'd-flex-justify-end')}>
                            <Button
                                disabled={loading}
                                size="small"
                                className={classes.primaryButton}
                                onClick={onOpen}
                            >
                                Add previous
                            </Button>
                        </div>
                    </>
                )}
                <ConfirmDialog
                    variant="outlined"
                    title="Confirm remove employment"
                    description="Are you sure you want to remove this employment?"
                    open={openConfirm}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={onCloseConfirm}
                    onClickPrimary={onDelete}
                    onClickSecondary={onCloseConfirm}
                />
                {open && <EmploymentHistory open={open} onClose={onClose} accountNumber={accountNumber} customerId={customerId} />}
                {openFormDialog
                && (
                    <EmploymentDialog
                        record={record}
                        open={openFormDialog}
                        customerId={customerId}
                        onClose={toggleEditModal}
                        isEditing={!isEmpty(record)}
                        setDealCurrentAddress={editFromManager ? null : setCurrent}
                    />
                )}
                {openManagerDialog && (
                    <ManageEmployment
                        open={openManagerDialog}
                        onClose={toggleManager}
                        customerId={customerId}
                    />
                )}
            </Paper>
        );
    }
}

Employment.propTypes = {
    employments: PropTypes.arrayOf(
        PropTypes.shape({
            dealPreviousEmploymentId: PropTypes.string,
            customerEmploymentId: PropTypes.string,
            status: PropTypes.string,
            employer: PropTypes.string,
            title: PropTypes.string,
            totalMonths: PropTypes.number,
            monthlyIncome: PropTypes.number,
            workPhone: PropTypes.string,
            otherIncomeMonthly: PropTypes.number,
            otherIncomeSource: PropTypes.string,
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            isCurrentEmployment: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    record: PropTypes.object,
    open: PropTypes.bool.isRequired,
    editingStatus: PropTypes.object,
    onAdd: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    setCurrent: PropTypes.func.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    onOpenConfirm: PropTypes.func.isRequired,
    toggleManager: PropTypes.func.isRequired,
    dealCurrentEmploymentId: PropTypes.string,
    onCloseConfirm: PropTypes.func.isRequired,
    openFormDialog: PropTypes.bool.isRequired,
    accountNumber: PropTypes.number.isRequired,
    editFromManager: PropTypes.bool.isRequired,
    toggleEditModal: PropTypes.func.isRequired,
    openManagerDialog: PropTypes.bool.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    customerId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    postedDate: PropTypes.string,
};

Employment.defaultProps = {
    record: {},
    loading: false,
    postedDate: null,
    dealCurrentEmploymentId: null,
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(EmploymentContainer(Employment));

import React from 'react';
import { Dialog, DialogTitle, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const DialogActionMessage = (props) => {
    const { message, ...rest } = props;

    return (
        <Dialog open {...rest}>
            <DialogTitle>
                {message}
                <CircularProgress size={15} />
            </DialogTitle>
        </Dialog>
    );
};

DialogActionMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

export default DialogActionMessage;

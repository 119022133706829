import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import LabelIcon from '@material-ui/icons/Label';

const useStyles = makeStyles((theme) => ({
    columnStyle: {
        alignItems: 'center',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    tableContainer: {
        '& > div': {
            maxHeight: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        '& .rt-table': {
            minHeight: '400px',
        },
        '& .rt-tbody > div > div': {
            height: '40px',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '450px',
            overflowX: 'auto',
        },
    },
}));

const TagTable = ({
    onEdit, loadMore, selectedRow, onSelectRow, records, totalCount,
    loading,
}) => {
    const classes = useStyles();
    const getColumns = () => [
        {
            Header: 'Color',
            id: 'color',
            accessor: 'color',
            className: classes.columnStyle,
            minWidth: 30,
            Cell: (cellData) => {
                const { original } = cellData;
                return <LabelIcon fontSize="medium" style={{ color: original.color }} />;
            },
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            className: classes.columnStyle,
            minWidth: 120,
        },
        {
            Header: 'Description',
            id: 'description',
            accessor: 'description',
            className: classes.columnStyle,
            minWidth: 150,
        },
        {
            Header: 'Module',
            id: 'module',
            accessor: 'module',
            className: classes.columnStyle,
            width: 120,
            minWidth: 120,
        },
        {
            Header: 'Priority',
            id: 'priority',
            accessor: 'priority',
            className: classes.columnStyle,
            width: 120,
            minWidth: 120,
        },
    ];

    return (
        <Table
            cursor="default"
            loadMore={loadMore}
            totalRecords={totalCount}
            data={records}
            load={loading}
            columns={getColumns()}
            sortable
            getTrProps={(_, rowInfo) => {
                const record = rowInfo.original;
                const selected = (!selectedRow ? false : selectedRow.tagId === record.tagId);
                return {
                    onDoubleClick: onEdit,
                    onClick: () => onSelectRow(record),
                    className: selected ? 'active' : '',
                };
            }}
        />
    );
};

TagTable.propTypes = {
    loading: PropTypes.bool,
    onEdit: PropTypes.func,
    loadMore: PropTypes.func,
    onSelectRow: PropTypes.func,
    selectedRow: PropTypes.object,
    records: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
};

TagTable.defaultProps = {
    onEdit: () => {},
    loadMore: () => {},
    onSelectRow: () => {},
    selectedRow: null,
    loading: false,
};

export default TagTable;

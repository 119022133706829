const MESSAGE_STATUS = Object.freeze({
    SENDING: 'Sending',
    DELIVERED: 'Delivered',
    READ: 'Read',
    RECEIVED: 'Received',
    FAILED: 'Failed',
});

const ERROR_MESSAGE = {
    // * Nexmo error => https://developer.vonage.com/en/messaging/sms/guides/delivery-receipts
    1: 'Unknown',
    2: 'Absent Subscriber - Temporary',
    3: 'Absent Subscriber - Permanent',
    4: 'Call Barred by User',
    5: 'Portability Error',
    6: 'Anti-Spam Rejection',
    7: 'Handset Busy',
    8: 'Network Error',
    9: 'Illegal Number',
    10: 'Illegal Message',
    11: 'Unroutable',
    12: 'Destination Unreachable',
    13: 'Subscriber Age Restriction',
    14: 'Number Blocked by Carrier',
    15: 'Prepaid Insufficient Funds',
    16: 'Gateway Quota Exceeded',
    39: 'Illegal sender address for US destination',
    41: 'Daily Limit Surpassed',
    50: 'Entity Filter',
    51: 'Header Filter',
    52: 'Content Filter',
    53: 'Consent Filter',
    54: 'Regulation Error',
    99: 'General Error',
    // * Bandwidth error => https://dev.bandwidth.com/docs/messaging/errors/
    // * Bandwidth Detected Client Errors
    4001: 'Message was rejected for reasons other than those covered by other 4xxx codes',
    4301: 'Malformed message encoding',
    4302: 'Malformed From number',
    4303: 'Malformed To Number',
    4350: 'Malformed message encoding',
    4360: 'Message expired',
    4401: 'BW is unable to route the message',
    4403: 'Messaging forbidden on From number',
    4404: 'Messaging forbidden on To number',
    4405: 'Unallocated from number',
    4406: 'Unallocated to number',
    4407: 'From Number is associated with account',
    4408: 'To Number not associated with account',
    4409: 'Invalid destination profile',
    4410: 'Could not download media',
    4411: 'Combined size of media too large',
    4412: 'Failed to parse Content-Type for media',
    4420: 'No Route to Destination Carrier',
    4421: 'No Route to Destination Carrier',
    4431: 'Messaging on shortcode forbidden',
    4432: 'Messaging to country forbidden',
    4433: 'Messaging on Toll Free Number Forbidden',
    4434: 'Messaging to Toll Free Number Forbidden',
    4435: 'Too Many Recipients',
    4451: 'Invalid User Id',
    4452: 'Invalid Application ID',
    4470: 'Rejected as SPAM',
    4481: 'From Number in black list',
    4482: 'To Number in black list',
    4492: 'Message to emergency number forbidden',
    4493: 'Unauthorized',
    // * Carrier Reported Client Errors
    4700: 'Carrier Rejected as Invalid Service Type',
    4701: 'Destination is not reachable and SMS service is not available.',
    4702: 'Destination subscriber is unavailable.',
    4711: 'Media size too large',
    4712: 'The media content type is not supported',
    4720: 'Carrier Rejected as Invalid Destination Address',
    4721: 'TN on deactivation list',
    4730: 'No route to destination carrier or no roaming route exists.',
    4740: 'Carrier Rejected as Invalid Source Address',
    4750: 'Carrier Rejected Message',
    4751: 'Message is too long or message length is invalid for the carrier.',
    4752: 'Message is malformed for the carrier.',
    4770: 'Carrier Rejected as SPAM',
    4771: 'Rejected due to shortened url',
    4775: 'Carrier Rejected due to user opt out',
    4780: 'T-Mobile rejected due to volumetric violation',
    4781: 'AT&T rejected due to 10DLC volumetric violation or throttling',
    4785: 'Carrier rejected due to volumetric violation',
    4790: 'Carrier Rejected Due to Short Code Restriction',
    4791: 'Carrier Rejected Short Code Campaign Not Allowed',
    4792: 'Carrier Rejected Short Code Not Provisioned',
    4793: 'Carrier Rejected Short Code Expired',
    4795: 'Toll Free number is not verified',
    // * Bandwidth Service Failures
    5100: 'Application Error',
    5101: 'Application Error',
    5106: 'Impossible to route / Attempt to deliver through retries has failed.',
    5111: 'Application Error',
    5201: 'Application Error',
    5211: 'Application Error',
    5220: 'Application Error',
    5231: 'Application Error',
    5500: 'General Message Send Failure',
    5501: 'General Message Send Failure',
    5999: 'Unknown error from Bandwidth',
    // * Carrier Reported Service Failures
    5600: 'Carrier Service Unavailable',
    5610: 'Carrier Service Failure',
    5620: 'Carrier Application Error',
    5630: 'Carrier Application Error',
    5650: 'Carrier Service Failure',
    // * Carrier Errors with Ambiguous Cause
    9902: 'Timed out waiting for delivery receipt. The reason a delivery receipt was not received is not known.',
    9999: 'Unknown error from downstream. Carrier reported a failure code that is unknown to Bandwidth.',
};

export { MESSAGE_STATUS, ERROR_MESSAGE };

/* eslint-disable no-undef */
import React from 'react';
import 'moment-timezone';
import moment from 'moment';
import StringUtils from 'lib/StringUtils';

class UserUtils {
    constructor() {
        if (!UserUtils.instance) {
            UserUtils.instance = this;
        }

        return UserUtils.instance;
    }

    getUserTimeZone(timeZones) {
        const { companyTimeZone, lotTimeZone } = { ...timeZones };
        const clientTimeZone = moment.tz.guess();

        if (lotTimeZone?.timeZoneId) return lotTimeZone?.timeZoneId;
        if (companyTimeZone?.timeZoneId) return companyTimeZone?.timeZoneId;
        return clientTimeZone;
    }

    getUserInformation(record) {
        if (!StringUtils.isEmpty(record)) {
            const result = [`${record.firstName} ${record.lastName}`, record?.company?.companyName, record.defaultLot];
            return <>{result.map((item, index) => <div key={`selected-${index}`}>{item}</div>)}</>;
        }

        return '';
    }
}

// Reference https://www.sitepoint.com/javascript-design-patterns-singleton/
const instance = new UserUtils();
Object.freeze(instance);

export default instance;

import React, { useState } from 'react';
import clsx from 'clsx';
import { Col, Form, Row } from 'react-bootstrap';
import {
    makeStyles, Button, FormControl, FormControlLabel, Radio,
    RadioGroup, Dialog,
    DialogContent, DialogActions, Typography, IconButton,
    AppBar, Toolbar, Slide, useTheme, useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DatePicker from 'react-datepicker';
import AsyncCreatableSelect from 'react-select/async-creatable';

import PaperComponent from 'components/modules/accounting/PaperComponent';
import { CloseIcon, HighlightOffOutlinedIcon, SearchOutlinedIcon } from 'components/icons';
import { InvoicePaidType } from 'utils/enum/AccountingEnum';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    centerItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 0,
    },
    title: {
        marginRight: theme.spacing(5),
        flex: 1,
        color: theme.palette.text.white,
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.gallery}`,
    },
    fullWidth: {
        width: '100%',
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
}));

const InvoiceListFilters = (props) => {
    const classes = useStyles();

    const {
        onClose, vendorList, onFilterSearch, onFilterClear,
        currentParams, initParams,
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initalState = currentParams;

    const [params, setParams] = useState(initalState);

    const onClearVendorFilter = (item) => {
        params.vendorId = item?.paidToId ?? 0;
        params.vendorName = item?.paidTo ?? '';

        setParams({ ...params });
    };

    const onFilterChange = (param, value) => {
        params[param] = value;

        setParams({ ...params });
    };

    const clearFilter = () => {
        setParams({
            ...initParams,
            vendorId: params.vendorId,
            vendorName: params.vendorName,
        });

        onFilterClear();
    };

    const getAsyncOptionsFiltered = (inputValue) => new Promise((resolve) => {
        const filtered = vendorList.filter((data) => data.paidTo.toLowerCase().includes(inputValue.toLowerCase()));
        resolve(filtered.slice(0, vendorList.length));
    });

    return (
        <Dialog
            open
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
            PaperComponent={PaperComponent}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        Advance Search
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Form.Group as={Row}>
                    <Form.Label column="sm" xs={isMobile ? 12 : 3}>Vendor</Form.Label>
                    <Col xs={isMobile ? 12 : 9}>
                        <AsyncCreatableSelect
                            defaultOptions={vendorList}
                            isValidNewOption={() => (false)}
                            getOptionLabel={(option) => option.paidTo ?? ''}
                            getOptionValue={(option) => option.paidToId}
                            isClearable
                            classNamePrefix="select"
                            loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                            value={!params.vendorId ? null : { paidToId: params.vendorId, paidTo: params.vendorName }}
                            onChange={(newValue) => onClearVendorFilter(newValue)}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column="sm" xs={isMobile ? 12 : 3}>Type</Form.Label>
                    <Col xs={isMobile ? 12 : 9}>
                        <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                            <RadioGroup
                                row
                                value={params.payType}
                                onChange={(e, val) => onFilterChange('payType', val)}
                            >
                                <FormControlLabel
                                    value={InvoicePaidType.DUE}
                                    control={<Radio color="primary" />}
                                    label={InvoicePaidType.DUE}
                                />
                                <FormControlLabel
                                    value={InvoicePaidType.PAID}
                                    control={<Radio color="primary" />}
                                    label={InvoicePaidType.PAID}
                                />
                                <FormControlLabel
                                    value={InvoicePaidType.VOID}
                                    control={<Radio color="primary" />}
                                    label={InvoicePaidType.VOID}
                                />
                                <FormControlLabel
                                    value={InvoicePaidType.ALL}
                                    control={<Radio color="primary" />}
                                    label={InvoicePaidType.ALL}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                </Form.Group>
                { params.payType !== InvoicePaidType.DUE
                        && (
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={isMobile ? 12 : 3} className="required">&nbsp;</Form.Label>
                                <Col xs={isMobile ? 12 : 9}>
                                    <Form.Group controlId="formBasicCheckbox" className={classes.alignContent}>
                                        <Form.Check
                                            checked={params.useDateRange}
                                            type="checkbox"
                                            label="Use date range"
                                            className={classes.labelFont}
                                            onChange={(e) => onFilterChange('useDateRange', e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Group>
                        )}
                { params.useDateRange && params.payType !== InvoicePaidType.DUE
                        && (
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={isMobile ? 12 : 3} className="required">From Date</Form.Label>
                                <Col xs={isMobile ? 12 : 9}>
                                    <DatePicker
                                        selected={params.fromDate}
                                        name="fromDate"
                                        size="sm"
                                        className={clsx('form-control form-control-sm')}
                                        placeholderText="mm/dd/yyyy"
                                        popperContainer={CalendarContainer}
                                        maxDate={params.toDate}
                                        onChange={(date) => onFilterChange('fromDate', date)}
                                    />
                                </Col>
                            </Form.Group>
                        ) }
                <Form.Group as={Row}>
                    <Form.Label column="sm" xs={isMobile ? 12 : 3} className="required">To Date</Form.Label>
                    <Col xs={isMobile ? 12 : 9}>
                        <DatePicker
                            selected={params.toDate}
                            name="toDate"
                            size="sm"
                            wrapperClassName={classes.control100}
                            className={clsx('form-control form-control-sm')}
                            placeholderText="mm/dd/yyyy"
                            popperContainer={CalendarContainer}
                            minDate={params.useDateRange ? params.fromDate : null}
                            onChange={(date) => onFilterChange('toDate', date)}
                        />
                    </Col>
                </Form.Group>
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            <Button
                                variant="outlined"
                                startIcon={<HighlightOffOutlinedIcon />}
                                size="small"
                                onClick={clearFilter}
                            >
                                Reset
                            </Button>
                        </div>
                        <div className={classes.buttonSpacing}>
                            <Button
                                variant="outlined"
                                startIcon={<CloseIcon />}
                                size="small"
                                onClick={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<SearchOutlinedIcon />}
                                size="small"
                                onClick={() => onFilterSearch(params)}
                            >
                                Search
                            </Button>
                        </div>
                    </Toolbar>
                </div>
            </DialogActions>
        </Dialog>
    );
};

InvoiceListFilters.propTypes = {
    initParams: PropTypes.object.isRequired,
    currentParams: PropTypes.object.isRequired,
    onFilterSearch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    vendorList: PropTypes.array,
    onFilterClear: PropTypes.func.isRequired,
};

InvoiceListFilters.defaultProps = {
    vendorList: [],
};

export default InvoiceListFilters;

import React from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import Split from 'react-split';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import PayrollUtils from 'utils/PayrollUtils';
import Select from 'components/widgets/Select';
import Loading from 'components/widgets/Loading';
import PayrollSearch from 'components/widgets/payroll/PayrollSearch';

const PayrollTable = ({
    classes,
    records,
    onSearch,
    loadTable,
    splitSize,
    isFiltered,
    payPeriods,
    loadColumns,
    searchValue,
    counterLabel,
    filteredData,
    onChangeYear,
    onTableClick,
    payPeriodYear,
    filtersApplied,
    getCustomHeader,
    onResizingSplit,
    onChangePayPeriod,
    payPeriodSelected,
    selectedTableIndex,
    getRightSideComponent,
}) => {
    let tableRef;

    const onSortedChange = () => {
        if (selectedTableIndex > -1) onTableClick(tableRef.table.state.sortedData[selectedTableIndex]._original);
    };

    const table = (
        <Table
            rowSelected
            // eslint-disable-next-line no-return-assign
            ref={(t) => (tableRef = t)}
            enableRowHover
            columns={loadColumns()}
            filtered={filtersApplied}
            sortedChange={onSortedChange}
            totalRecords={records.length}
            className="-highlight actionsInLine payrollTable"
            data={isFiltered ? filteredData : records}
            getTrProps={(_, rowInfo) => ({
                onClick: (event) => {
                    event.currentTarget.classList.add('active');
                    onTableClick(rowInfo.original, rowInfo.viewIndex);
                },
            })}
            defaultFilterMethod={(localFilter, row) => {
                const id = localFilter.pivotId || localFilter.id;
                if (typeof localFilter.value === 'object') {
                    return row[id] !== undefined
                        ? localFilter.value.indexOf(row[id]) > -1
                        : true;
                }
                return row[id] !== undefined
                    ? String(row[id]).indexOf(localFilter.value) > -1
                    : true;
            }}
        />
    );

    const rightSideComponent = getRightSideComponent();
    return (
        <div className={classes.boxContent}>
            <Grid item className={classes.left}>
                <>
                    <Select
                        size="sm"
                        name="payPeriodYear"
                        value={payPeriodYear}
                        onChange={onChangeYear}
                        placeholder="Select Year"
                        options={PayrollUtils.getYears()}
                    />
                    <List className={classes.listStyle} component="nav">
                        { payPeriods.map((item, index) => (
                            <ListItem
                                button
                                key={index}
                                className={classes.listItemStyle}
                                selected={index === payPeriodSelected}
                                onClick={() => onChangePayPeriod(index)}
                                classes={{
                                    root: classes.listItemRoot,
                                    selected: classes.selected,
                                }}
                            >
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                </>
            </Grid>
            <Grid container spacing={2} className={classes.tableContainer}>
                <Grid item className={classes.header}>
                    <>
                        <PayrollSearch
                            classes={classes}
                            onSearch={onSearch}
                            counter={records.length}
                            searchValue={searchValue}
                            counterLabel={counterLabel}
                        />
                        {getCustomHeader()}
                    </>
                </Grid>
                <Grid item xs={12} className={classes.tableHeight}>
                    {!loadTable && (
                        <div className={classes.loadingIndicator}>
                            <Loading />
                        </div>
                    )}
                    {loadTable && rightSideComponent !== null && (
                        <Split
                            minSize={0}
                            onDragEnd={onResizingSplit}
                            className={classes.splitOne}
                            sizes={selectedTableIndex === -1 || payPeriodSelected === -1 ? [100, 0] : splitSize}
                        >
                            {table}
                            {rightSideComponent}
                        </Split>
                    )}
                    {loadTable && rightSideComponent === null && table}
                </Grid>
            </Grid>
        </div>
    );
};

PayrollTable.propTypes = {
    isFiltered: PropTypes.bool,
    splitSize: PropTypes.array,
    onTableClick: PropTypes.func,
    filteredData: PropTypes.array,
    searchValue: PropTypes.string,
    onResizingSplit: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    records: PropTypes.array.isRequired,
    selectedTableIndex: PropTypes.number,
    classes: PropTypes.object.isRequired,
    loadTable: PropTypes.bool.isRequired,
    getRightSideComponent: PropTypes.func,
    loadColumns: PropTypes.func.isRequired,
    payPeriods: PropTypes.array.isRequired,
    onChangeYear: PropTypes.func.isRequired,
    counterLabel: PropTypes.string.isRequired,
    filtersApplied: PropTypes.array.isRequired,
    getCustomHeader: PropTypes.func.isRequired,
    payPeriodYear: PropTypes.number.isRequired,
    onChangePayPeriod: PropTypes.func.isRequired,
    payPeriodSelected: PropTypes.number.isRequired,
};

PayrollTable.defaultProps = {
    splitSize: [],
    searchValue: '',
    filteredData: [],
    isFiltered: false,
    selectedTableIndex: -1,
    onTableClick: () => null,
    onResizingSplit: () => null,
    getRightSideComponent: () => null,
};

export default PayrollTable;

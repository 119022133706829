import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { concat, size } from 'lodash';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';

const CustomerListContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();

        this.state = {
            contentList: {
                records: [],
                limit: 50,
                search: '',
                totalCount: 0,
            },
            load: false,
        };

        this.customerEditedSubscription = null;
        this.initBind();
    }

    onNew() {
        const { props: { history } } = this;
        history.push('/customers/create');
    }

    onSearch(value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                search: { $set: value },
            }),
        }), () => {
            this.getServicesData(true);
        });
    }

    getServicesData(isSearching = false) {
        const { contentList: { records, limit, search } } = this.state;
        let offset = size(records) ? records.length : 0;
        offset = isSearching ? 0 : offset;

        this.setState({ load: true });

        const input = {
            paginate: {
                start: offset,
                limit,
            },
            sort: {
                field: 'sysDate',
                dir: 'DESC',
            },
            filter: {
                searchTerm: search,
            },
        };

        if (isSearching) {
            this.setState((prevState) => ({
                contentList: update(prevState.contentList, {
                    records: { $set: [] },
                    limit: { $set: limit },
                    totalRecords: { $set: 0 },
                }),
                load: true,
            }));
        } else {
            this.setState({ load: true });
        }

        this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMERS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerList) {
                    const recordServices = isSearching ? [] : records;
                    const { getCustomerList: { totalCount, customers } } = data;
                    const newRecords = concat(recordServices, customers);

                    this.setState((prevState) => ({
                        contentList: update(prevState.contentList, {
                            records: { $set: newRecords },
                            totalCount: { $set: totalCount },
                        }),
                    }));
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    loadMore() {
        this.getServicesData();
    }

    subscribeCustomerList() {
        this.graphqlClient.subscribe(this.responseSubscription, CustomerSubscription.CUSTOMER_LIST)
            .then((response) => {
                this.customerEditedSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.customerList) {
            const { customerList: { type, data: { customer, totalCount } } } = data;

            if (type === SubscriptionActionType.CREATED) {
                this.setState((prevState) => ({
                    contentList: update(prevState.contentList, {
                        records: { $unshift: [customer] },
                        totalCount: { $set: totalCount },
                    }),
                }));
            }
        }
    }

    unsubscribeCustomerList() {
        if (this.customerEditedSubscription) {
            this.customerEditedSubscription.unsubscribe();
        }
    }

    initBind() {
        this.onNew = this.onNew.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeCustomerList = this.subscribeCustomerList.bind(this);
        this.unsubscribeCustomerList = this.unsubscribeCustomerList.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onNew={this.onNew}
                onSearch={this.onSearch}
                loadMore={this.loadMore}
                getServicesData={this.getServicesData}
                subscribeCustomerList={this.subscribeCustomerList}
                unsubscribeCustomerList={this.unsubscribeCustomerList}
            />
        );
    }
};

export default CustomerListContainer;

/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

const CompanySettingSchema = yup.object().shape({
    companyName: yup.string().required(),
    companyAddress: yup.string().required(),
    companyCity: yup.string().required(),
    companyZip: yup.string().required(),
    companyState: yup.string().required(),
    companyCountry: yup.string().required(),
    companyPhone: yup.string().required(),
});

export {
    CompanySettingSchema,
};

import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import If from 'components/widgets/conditional/If';
import Jodit from 'components/widgets/editor/JoditEditor';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Button,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import BulletinBoardStyles from 'styles/modules/home/BulletinBoardStyles';
import { MessageContentType } from 'utils/enum/BulletinBoardEnum';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const messagesStyle = makeStyles((theme) => BulletinBoardStyles.messages(theme));

const MessageForm = ({
    open,
    selectedContentType,
    isMessagePinned,
    isFileBeingUploaded,
    content,
    onPDFUpload,
    actionClose,
    actionChange,
    actionSave,
    isSaving,
}) => {
    const messagesClasses = messagesStyle();
    const buttonClasses = buttonStyles();
    const uploader = useRef(null);

    const openFileDialog = () => {
        const { current } = uploader;
        if (current) current.click();
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar title="Add Message" onClose={actionClose} toolbarSize="sm" />
            <DialogContent>
                <div className={messagesClasses.selector}>
                    <Form.Group>
                        <Form.Label size="sm">Type of Content</Form.Label>
                        <Form.Control
                            name="content-type"
                            as="select"
                            size="sm"
                            value={selectedContentType === null ? '' : selectedContentType}
                            onChange={actionChange}
                        >
                            <option>Pick one</option>
                            {Object.values(MessageContentType).map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            name="pin"
                            size="sm"
                            checked={isMessagePinned}
                            type="checkbox"
                            label="Pin to Top"
                            onChange={actionChange}
                        />
                    </Form.Group>
                </div>
                <div className={messagesClasses.contentArea}>
                    <If condition={selectedContentType === MessageContentType.PLAIN_TEXT}>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                size="lg"
                                name="content"
                                value={content === null ? '' : content}
                                placeholder="Start typing your message"
                                onChange={actionChange}
                            />
                        </Form.Group>
                    </If>
                    <If condition={selectedContentType === MessageContentType.HTML}>
                        <Jodit
                            value={content === null ? '' : content}
                            onChange={(newContent) => actionChange({ target: { name: 'content', value: newContent } })}
                        />
                    </If>
                    <If condition={selectedContentType === MessageContentType.PDF}>
                        <Form.Group>
                            <Form.Control
                                as="input"
                                size="sm"
                                name="content"
                                placeholder="Paste your PDF's URL here"
                                value={content === null ? '' : content}
                                onChange={actionChange}
                            />
                        </Form.Group>
                        <Button
                            disabled={isFileBeingUploaded || isSaving}
                            className={clsx(buttonClasses.containedInfo)}
                            size="small"
                            onClick={openFileDialog}
                        >
                            Upload
                        </Button>
                        <input
                            ref={uploader}
                            className={messagesClasses.uploader}
                            type="file"
                            onChange={onPDFUpload}
                        />
                    </If>
                </div>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickSecondary={actionClose}
                onClickPrimary={actionSave}
                disablePrimaryButton={!content || isSaving}
                disableSecondaryButton={isSaving}
            />
        </Dialog>
    );
};

MessageForm.defaultProps = {
    selectedContentType: null,
    content: null,
};

MessageForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onPDFUpload: PropTypes.func.isRequired,
    actionClose: PropTypes.func.isRequired,
    actionChange: PropTypes.func.isRequired,
    actionSave: PropTypes.func.isRequired,
    selectedContentType: PropTypes.string,
    isMessagePinned: PropTypes.bool.isRequired,
    isFileBeingUploaded: PropTypes.bool.isRequired,
    content: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
};

export default MessageForm;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import InputColumn from 'components/widgets/businessIntelligence/InputColumn';

const useStyles = makeStyles((theme) => ({
    pivotTableInput: {
        display: 'flex',
        flexDirection: 'row',
        height: '200px',
        border: `1px solid ${theme.palette.border.ghost}`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '& > div:nth-child(1), & > div:nth-child(2)': {
            borderRight: `1px solid ${theme.palette.border.ghost}`,
        },
        '& > div': {
            width: '30%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                width: '100% !important',
            },
            '& > div:nth-child(1)': {
                textAlign: 'center',
                flexShrink: 0,
                color: theme.palette.text.white,
                backgroundColor: theme.palette.secondary.main,
                padding: '2px',
                textDecoration: 'none',
                fontStyle: 'normal',
                fontSize: '12px',
            },
            '& > div:nth-child(2)': {
                flexGrow: 1,
                overflowX: 'hidden',
                overflowY: 'auto',
            },
        },
        '& > div:nth-child(3)': {
            width: '40%',
        },
    },
    columnsDrop: {
        border: `1px solid ${theme.palette.border.ghost} !important`,
        height: '170px !important',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: 0,
        width: '100% !important',
        borderRadius: '0px !important',
        '& > div': {
            width: '100%',
            display: 'flex !important',
            justifyContent: 'space-between !important',
            alignItems: 'center !important',
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
            fontStyle: 'initial',
            fontWeight: 'bold !important',
            cursor: 'pointer !important',
            marginTop: '5px',
            fontSize: '13px !important',
            height: '25px !important',
            '&:hover': {
                backgroundColor: theme.palette.border.ghost,
            },
            '& > div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& > div > div': {
                marginRight: '5px',
                marginTop: '1px',
                height: '18px',
                '& > div': {
                    padding: 0,
                    '& span': {
                        fontSize: '13px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& > div > svg': {
                fill: theme.palette.background.red,
                width: '20px',
                height: '20px',
            },
        },
    },
}));

const PivotTableInputContent = ({
    PivotRows,
    PivotColumns,
    PivotValues,
    prepareItem,
    reorderItems,
    removeColumn,
    handleDrop,
    updateChartOptions,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.pivotTableInput}>
            <div>
                <div>Rows</div>
                <div
                    className={classes.columnsDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, { name: 'PivotRows' })}
                >
                    {PivotRows.map((column, idx) => (
                        <InputColumn
                            key={idx}
                            index={idx}
                            field={{ name: 'PivotRows', value: PivotRows }}
                            column={column}
                            prepareItem={prepareItem}
                            reorderItems={reorderItems}
                            removeColumn={removeColumn}
                        />
                    ))}
                </div>
            </div>
            <div>
                <div>Columns</div>
                <div
                    className={classes.columnsDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, { name: 'PivotColumns' })}
                >
                    {
                        PivotColumns
                            .map((column, idx) => (
                                <InputColumn
                                    key={idx}
                                    index={idx}
                                    field={{ name: 'PivotColumns', value: PivotColumns }}
                                    column={column}
                                    prepareItem={prepareItem}
                                    reorderItems={reorderItems}
                                    removeColumn={removeColumn}
                                />
                            ))
                    }
                </div>
            </div>
            <div>
                <div>Values</div>
                <div
                    className={classes.columnsDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, { name: 'PivotValues' })}
                >
                    {
                        PivotValues
                            .map((
                                {
                                    name,
                                    dataType,
                                    aggregation,
                                },
                                idx,
                            ) => (
                                <InputColumn
                                    key={idx}
                                    index={idx}
                                    field={{ name: 'PivotValues', value: PivotValues }}
                                    column={name}
                                    dataType={dataType}
                                    aggregation={aggregation}
                                    prepareItem={prepareItem}
                                    reorderItems={reorderItems}
                                    removeColumn={removeColumn}
                                    updateChartOptions={updateChartOptions}
                                />
                            ))
                    }
                </div>
            </div>
        </div>
    );
};

PivotTableInputContent.defaultProps = {
    PivotRows: [],
    PivotColumns: [],
    PivotValues: [],
    updateChartOptions: () => null,
};

PivotTableInputContent.propTypes = {
    PivotRows: PropTypes.array,
    PivotColumns: PropTypes.array,
    PivotValues: PropTypes.array,
    prepareItem: PropTypes.func.isRequired,
    reorderItems: PropTypes.func.isRequired,
    removeColumn: PropTypes.func.isRequired,
    handleDrop: PropTypes.func.isRequired,
    updateChartOptions: PropTypes.func,
};

export default PivotTableInputContent;

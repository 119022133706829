import { useEffect, useState } from 'react';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ServiceSettingsQuery from 'services/graphQL/query/service/ServiceSettings';

const useServiceSetting = () => {
    const [minimumGPList, setMinimumGPList] = useState([]);

    const { data, loading, error } = useQuery(ServiceSettingsQuery.GET_MINIMUM_GP_PER_LOTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getMinimumGPPerLots } = data;
            setMinimumGPList(getMinimumGPPerLots);
        }
    }, [data, loading, error]);

    return {
        minimumGPList,
        loading,
    };
};

export default useServiceSetting;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import EditorStyles from 'styles/widgets/EditorControlStyles';

const useStyles = makeStyles(() => EditorStyles.selectStyles());

const DropdownQuery = (props) => {
    const [records, setRecords] = useState([]);
    const classes = useStyles();
    const {
        name, value, onChange, dataSource, editorCellObject, className, allowEmptyLine, defaultEmptyLineText, ...other
    } = props;

    const {
        query = '', rootData, idField, descriptionField, variables, localData, additionalFieldsReturned, fetchPolicy,
    } = dataSource;

    const getRootData = (data) => {
        const rootArray = rootData.split('.');
        let recordList = data;

        if (rootArray) {
            rootArray.forEach((item) => {
                const items = recordList[item];
                recordList = items;
            });
        }

        return recordList;
    };

    const [loadData] = useCallback(useLazyQuery(
        query,
        {
            onCompleted: (data) => {
                const recordList = getRootData(data);
                const dataList = recordList.map((item) => ({
                    ...item,
                    value: item[idField],
                    label: item[descriptionField],
                }));

                if (allowEmptyLine) dataList.unshift({ value: 0, label: defaultEmptyLineText });
                setRecords(dataList);
            },
            onError: (mutationError) => {
                ModalUtils.errorMessage(null, mutationError);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: fetchPolicy ?? FetchPolicy.NETWORK_ONLY,
        },
    ), []);

    const handleChange = (id, newValue) => {
        const additionalFieldsResult = {};
        if (additionalFieldsReturned) {
            const recordSelected = records.find((item) => item.accountNumber === newValue);

            if (recordSelected) {
                additionalFieldsReturned.forEach((item) => {
                    additionalFieldsResult[item] = recordSelected[item];
                });
            }
        }
        onChange(id, newValue, editorCellObject, additionalFieldsResult);
    };

    useEffect(() => {
        if (localData) {
            const currentData = localData.map((item) => ({
                ...item,
                value: item[idField] || item.value,
                label: item[descriptionField] || item.label,
            }));

            if (other.creatable) {
                const isValueAnOption = currentData.find((item) => item.value === value);
                if (!isValueAnOption) currentData.push({ label: value, value });
            }

            if (allowEmptyLine) {
                currentData.unshift({ value: 0, label: defaultEmptyLineText });
            }
            setRecords(currentData);
        } else {
            loadData({
                variables: variables || null,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localData?.length > 0]);

    const handleCreate = useCallback(
        (inputValue) => {
            const newValue = { value: inputValue, label: inputValue };
            setRecords([...records, newValue]);
            handleChange(name, inputValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [records],
    );

    return (
        <Select
            name={name}
            options={records}
            value={value}
            onChange={handleChange}
            onCreate={(createdValue) => (other.creatable ? handleCreate(createdValue) : null)}
            className={clsx('basic-multi-select select-bootstrap form-control-sm', classes.selectSM, className)}
            {...other}
        />
    );
};

DropdownQuery.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    dataSource: PropTypes.object.isRequired,
    editorCellObject: PropTypes.object,
    className: PropTypes.string,
    defaultEmptyLineText: PropTypes.string,
    allowEmptyLine: PropTypes.bool,
};

DropdownQuery.defaultProps = {
    value: null,
    className: '',
    editorCellObject: {},
    defaultEmptyLineText: 'Select ...',
    allowEmptyLine: true,
};

export default DropdownQuery;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PhoneNumberUtil from 'lib/PhoneUtils';
import { withRouter } from 'react-router-dom';
import Header from 'components/widgets/Header';
import Select from 'components/widgets/Select';
import { Form, Col, Row } from 'react-bootstrap';
import If from 'components/widgets/conditional/If';
import SearchIcon from '@material-ui/icons/Search';
import DealStyles from 'styles/modules/DealStyles';
import Container from 'components/widgets/Container';
import InputNumber from 'components/widgets/InputNumber';
import { isValidSchema, isValidField } from 'utils/schema/utils';
import AddVehicle from 'components/widgets/inventory/AddVehicle';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AddressSection from 'components/widgets/customer/AddressSection';
import { opportunitySchema } from 'utils/schema/opportunity/Opportunity';
import PaperAddInformation from 'components/widgets/form/PaperAddInformation';
import VehicleDialog from 'components/modules/deals/read/vehicle/VehicleDialog';
import EditCustomerDialog from 'components/widgets/customer/EditCustomerDialog';
import Employment from 'components/modules/customer/read/personalTab/Employment';
import CustomerDialog from 'components/modules/customer/read/CustomerDialog';
import CreateCustomer from 'components/modules/deals/create/dealTab/CreateCustomer';
import CustomerInformation from 'components/modules/deals/read/buyer/CustomerInformation';
import OpportunityCreateContainer from 'components/containers/opportunity/create/OpportunityCreateContainer';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import {
    Button, Grid,
    Divider,
} from '@material-ui/core';
import { CustomerType } from 'utils/enum/DealEnum';
import { isEmpty } from 'lodash';
import { OptionalAddressSchema } from 'utils/schema/customer/Address';

const styles = (theme) => DealStyles.dealCreate(theme, fade);

class OpportunityCreate extends Component {
    renderBoxCoBuyer() {
        const {
            coBuyer,
            toggleModal,
            toggleEditCustomer,
            classes,
        } = this.props;
        const currentCoBuyerName = `${coBuyer.firstName || ''} ${coBuyer.lastName || ''}`;

        if (coBuyer?.customerCode) {
            return (
                <Grid container spacing={0} className={classes.personalInformation}>
                    <Grid item xs={12} className="gridItem">
                        <CustomerInformation
                            title="Co Buyer"
                            customerName={currentCoBuyerName}
                            email={coBuyer.email}
                            cellPhone={PhoneNumberUtil.formatPhone(coBuyer.cellPhone) || ''}
                            homePhone={PhoneNumberUtil.formatPhone(coBuyer.homePhone) || ''}
                            workPhone={PhoneNumberUtil.formatPhone(coBuyer.workPhone) || ''}
                            onSearchCustomer={() => toggleModal(CustomerType.CO_BUYER)}
                            type={CustomerType.CO_BUYER}
                            toggleEditCustomer={() => toggleEditCustomer(coBuyer)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <AddressSection
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            customerId={coBuyer.customerId}
                            addressInformation={coBuyer.currentAddress}
                            showAddButton={isEmpty(coBuyer.currentAddress)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <Employment
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            showAddButton={isEmpty(coBuyer.currentEmployment)}
                            customerId={coBuyer.customerId}
                            record={coBuyer.currentEmployment}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <PaperAddInformation
                label="Add Co Buyer"
                icon={<SearchIcon />}
                onClickLeftLabel={() => toggleModal(CustomerType.CO_BUYER)}
            />
        );
    }

    renderBoxBuyer() {
        const {
            props: {
                buyer,
                toggleModal,
                toggleEditCustomer,
                classes,
            },
        } = this;
        const currentBuyerName = `${buyer.firstName || ''} ${buyer.lastName || ''}`;

        if (buyer?.customerCode) {
            return (
                <Grid container spacing={0} className={classes.personalInformation}>
                    <Grid item xs={12} className="gridItem">
                        <CustomerInformation
                            title="Buyer"
                            customerName={currentBuyerName}
                            email={buyer.email}
                            cellPhone={PhoneNumberUtil.formatPhone(buyer.cellPhone) || ''}
                            homePhone={PhoneNumberUtil.formatPhone(buyer.homePhone) || ''}
                            workPhone={PhoneNumberUtil.formatPhone(buyer.workPhone) || ''}
                            onSearchCustomer={() => toggleModal(CustomerType.BUYER)}
                            type={CustomerType.BUYER}
                            toggleEditCustomer={() => toggleEditCustomer(buyer)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <AddressSection
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            customerId={buyer.customerId}
                            addressInformation={buyer.currentAddress}
                            showAddButton={isEmpty(buyer.currentAddress)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <Employment
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            showAddButton={isEmpty(buyer.currentEmployment)}
                            customerId={buyer.customerId}
                            record={buyer.currentEmployment}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <PaperAddInformation
                label="Add Buyer"
                icon={<SearchIcon />}
                onClickLeftLabel={() => toggleModal(CustomerType.BUYER)}
            />
        );
    }

    renderGeneralInformationByOpportunity() {
        const {
            classes, recordManagers, salesPersonList, vehicle, userAvailableLots,
            toggleModalVehicle, onChangeOpportunity, opportunityManagement, onChangeLot,
        } = this.props;
        const record = {
            stock: vehicle.stockNumber,
            ...vehicle,
        };

        return (
            <Grid container spacing={2} className={classes.containerCard}>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.renderBoxBuyer()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.renderBoxCoBuyer()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className="d-flex-column">
                    <AddVehicle
                        record={record}
                        toggleModal={toggleModalVehicle}
                    />
                    <div className="box-shadow flex-1">
                        <Form className={clsx(classes.formDealStructure, classes.formW100)} noValidate>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Lot</Form.Label>
                                <Col sm="12" xl="7">
                                    <Select
                                        size="sm"
                                        name="lotName"
                                        placeholder="Select"
                                        onChange={onChangeLot}
                                        options={userAvailableLots}
                                        value={opportunityManagement.lotId}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Record Manager:</Form.Label>
                                <Col sm="12" xl="7">
                                    <Select
                                        size="sm"
                                        name="recordManagerId"
                                        placeholder="Select"
                                        className="select-bootstrap"
                                        options={recordManagers}
                                        value={opportunityManagement.recordManagerId}
                                        onChange={onChangeOpportunity}
                                    />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Sales Person:</Form.Label>
                                <Col sm="12" xl="7">

                                    <Select
                                        size="sm"
                                        name="salesPerson"
                                        placeholder="Select"
                                        className="select-bootstrap"
                                        options={salesPersonList}
                                        value={opportunityManagement.salesPerson}
                                        onChange={onChangeOpportunity}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Available Cash:</Form.Label>
                                <Col sm="12" xl="7">
                                    <InputNumber
                                        placeholder="0"
                                        size="sm"
                                        value={opportunityManagement.availableCash || ''}
                                        onChange={(value) => onChangeOpportunity('availableCash', value)}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                </Grid>
            </Grid>
        );
    }

    renderLeftSectionHeader(isValid) {
        const {
            props: {
                isSelectClient, classes, cancelCreate, onSave, saving,
            },
        } = this;

        return (
            <>
                <Button
                    variant="contained"
                    className={clsx(classes.button, classes.containedSecondaryInfo)}
                    onClick={onSave}
                    disabled={!(isValid && isSelectClient) || saving}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    className={clsx(classes.button)}
                    onClick={cancelCreate}
                >
                    Cancel
                </Button>
            </>
        );
    }

    renderImport() {
        const {
            toggleModal, onDoubleClick, open, customerTag, toggleCreateCustomer,
        } = this.props;

        switch (customerTag) {
        case CustomerType.BUYER:
        case CustomerType.CO_BUYER:
            // eslint-disable-next-line max-len
            return <CustomerDialog open={open} toggleModal={toggleModal} onSelectRow={onDoubleClick} customerTag={customerTag} toggleCreateCustomerPanel={() => { toggleModal(); toggleCreateCustomer(customerTag); }} />;
        default:
            return null;
        }
    }

    render() {
        const {
            classes, open, openCreateCustomer, openDialogVehicle, openEditCustomer, toggleModalVehicle, onDoubleClick,
            onDoubleClickVehicle, toggleCreateCustomer, toggleEditCustomer, leadSourceList, recordToEdit, opportunityManagement,
            onChangeOpportunity, openConfirmationDialog, closeConfirmDialog, saveOpportunity, dealTypeList, saving,
        } = this.props;

        const isValidData = isValidSchema(opportunitySchema, opportunityManagement);
        const { isValid, errors } = isValidData;

        return (
            <Container className="floatBox">
                <Header>
                    <div className="d-flex-justify-center-align-center">
                        {this.renderLeftSectionHeader(isValid)}
                    </div>
                    <div />
                </Header>
                <Container className={classes.boxContainerDetails}>
                    <div className={clsx(classes.dealCreateContainer, 'box-shadow')}>
                        <div className={classes.dealCreateHeader}>
                            <Form.Group>
                                <Form.Label className={classes.labelColor}>Deal Type:</Form.Label>
                                <Select
                                    size="sm"
                                    name="dealType"
                                    placeholder="Select"
                                    options={dealTypeList}
                                    className="select-bootstrap"
                                    value={opportunityManagement.dealType}
                                    onChange={onChangeOpportunity}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={classes.labelColor}>Lead Source:</Form.Label>
                                <Select
                                    size="sm"
                                    name="leadSourceId"
                                    placeholder="Select"
                                    options={leadSourceList}
                                    className="select-bootstrap"
                                    value={opportunityManagement.leadSourceId}
                                    onChange={onChangeOpportunity}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={classes.labelColor}>Lead Type:</Form.Label>
                                <Select
                                    size="sm"
                                    name="leadType"
                                    placeholder="Select"
                                    options={[
                                        {
                                            label: 'Phone-Up',
                                            value: 'Phone-Up',
                                        },
                                        {
                                            label: 'Walk-In',
                                            value: 'Walk-In',
                                        },
                                    ]}
                                    className={clsx('select-bootstrap', isValidField(errors, 'leadType') ? 'invalid-field' : '')}
                                    value={opportunityManagement.leadType}
                                    onChange={onChangeOpportunity}
                                />
                            </Form.Group>
                        </div>
                        <Divider />
                        {this.renderGeneralInformationByOpportunity()}
                    </div>
                    {open && this.renderImport()}
                    {openDialogVehicle && (
                        <VehicleDialog
                            toggleModal={toggleModalVehicle}
                            open={openDialogVehicle}
                            onSelectVehicle={onDoubleClickVehicle}
                            includeDefaultLot={false}
                        />
                    )}
                    <If condition={openCreateCustomer}>
                        <CreateCustomer
                            toggleCreateCustomer={toggleCreateCustomer}
                            onSetCustomer={onDoubleClick}
                            addressSchema={OptionalAddressSchema}
                        />
                    </If>
                    {openEditCustomer && (
                        <EditCustomerDialog
                            open={openEditCustomer}
                            record={recordToEdit}
                            toggleModal={toggleEditCustomer}
                        />
                    )}
                    {openConfirmationDialog
                    && (
                        <ConfirmDialog
                            title="Confirm"
                            description="Are you sure contact has NO CASH?"
                            open={openConfirmationDialog}
                            showHeader
                            titlePrimary="Yes"
                            titleSecondary="No"
                            variant="outlined"
                            dividerFooter={false}
                            disablePrimaryButton={saving}
                            onClickPrimary={saveOpportunity}
                            onClose={closeConfirmDialog}
                            onClickSecondary={closeConfirmDialog}
                        />
                    )}
                </Container>
            </Container>
        );
    }
}

OpportunityCreate.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    isSelectClient: PropTypes.bool.isRequired,
    customerTag: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    coBuyer: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        customerCode: PropTypes.number,
        customerId: PropTypes.string,
        currentAddress: PropTypes.object,
        currentEmployment: PropTypes.object,
    }).isRequired,
    buyer: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        customerCode: PropTypes.number,
        customerId: PropTypes.string,
        currentAddress: PropTypes.object,
        currentEmployment: PropTypes.object,
    }).isRequired,
    openDialogVehicle: PropTypes.bool.isRequired,
    toggleModalVehicle: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onDoubleClickVehicle: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({
        stockNumber: PropTypes.number,
        year: PropTypes.string,
        make: PropTypes.string,
        model: PropTypes.string,
        trim: PropTypes.string,
        thumbnail: PropTypes.string,
        minimumPrice: PropTypes.number,
    }).isRequired,
    cancelCreate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    leadSourceList: PropTypes.array.isRequired,
    toggleCreateCustomer: PropTypes.func.isRequired,
    openCreateCustomer: PropTypes.bool.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    openEditCustomer: PropTypes.bool.isRequired,
    recordToEdit: PropTypes.object,
    recordManagers: PropTypes.array.isRequired,
    salesPersonList: PropTypes.array.isRequired,
    onChangeOpportunity: PropTypes.func.isRequired,
    opportunityManagement: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.bool.isRequired,
    closeConfirmDialog: PropTypes.func.isRequired,
    saveOpportunity: PropTypes.func.isRequired,
    dealTypeList: PropTypes.array.isRequired,
    userAvailableLots: PropTypes.array.isRequired,
    onChangeLot: PropTypes.func.isRequired,
};

OpportunityCreate.defaultProps = {
    recordToEdit: null,
};

export default withRouter(withStyles(styles)(OpportunityCreateContainer(OpportunityCreate)));

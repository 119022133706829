import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { useMutation } from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import {
    makeStyles,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    TextField,
} from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import UploadIcon from 'components/modules/inventory/create/dialogs/keyFeature/UploadIcon';
import KeyFeatureIconList from 'components/modules/inventory/create/dialogs/keyFeature/KeyFeatureIconList';

import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import MessageUtils from 'utils/MessageUtils';

const useStyles = makeStyles((theme) => ({
    dialog: {
        zIndex: '9000 !important',
    },
    buttonGreen: {
        color: theme.palette.text.white,
        background: theme.palette.background.green,
        maxWidth: '247px',
        '&:hover': {
            backgroundColor: theme.palette.background.green,
        },
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '22px',
    },
    rightDialogActions: {
        '& Button': {
            marginLeft: '16px',
            textTransform: 'inherit',
        },
    },
    leftDialogActions: {
        display: 'flex',
        alignItems: 'end',
        '& > Button': {
            marginRight: 16,
            textTransform: 'inherit',
        },
    },
    dialogContent: {
        display: 'flex',
        overflow: 'hidden',
        height: '320px',
        flexDirection: 'column',
    },
    paperFull: {
        height: '490px',
        width: '850px',
    },
    deleteFeatureButton: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.text.white,
    },
}));

const initState = {
    featureName: '',
    showConfirmDialog: false,
    editFeature: false,
    deleteFeature: false,
    selectedIcon: null,
    newIcons: null,
};
const ACTION_TYPES = {
    CHANGE_FEATURE_NAME: 'changeFeatureName',
    TOGGLE_CONFIRM_DIALOG: 'toggleConfirmDialog',
    SET_SELECTED_ICON: 'setSelectedIcon',
    ADD_NEW_ICONS: 'addNewIcons',
    SET_DATA_TO_EDIT: 'setDataToEdit',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.CHANGE_FEATURE_NAME:
        return update(state, {
            featureName: { $set: action.payload },
        });
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG:
        if (state.showConfirmDialog) {
            return update(state, {
                showConfirmDialog: { $set: false },
                editFeature: { $set: false },
                deleteFeature: { $set: false },
            });
        }

        return update(state, {
            showConfirmDialog: { $set: true },
            [action?.payload]: { $set: true },
        });
    case ACTION_TYPES.SET_SELECTED_ICON:
        return update(state, {
            selectedIcon: { $set: action.payload },
        });
    case ACTION_TYPES.ADD_NEW_ICONS:
        return update(state, {
            newIcons: { $set: action.payload },
        });
    case ACTION_TYPES.SET_DATA_TO_EDIT:
        return update(state, {
            featureName: { $set: action.payload.featureName },
            selectedIcon: { $set: action.payload.selectedIcon },
        });
    default:
        return initState;
    }
};

const KeyFeatureDialog = ({
    open,
    onClose,
    editMode,
    feature,
    onReload,
}) => {
    const classes = useStyles();
    const [data, dispatchData] = useReducer(reducer, initState);
    const [createFeature, { loading: loadingCreate }] = useMutation(InventoryMutation.CREATE_FEATURE);
    const [updateFeature, { loading: loadingEdit }] = useMutation(InventoryMutation.EDIT_FEATURE);
    const [deleteFeature, { loading: loadingDelete }] = useMutation(InventoryMutation.DELETE_FEATURE);

    useEffect(() => {
        if (feature) {
            dispatchData({
                type: ACTION_TYPES.SET_DATA_TO_EDIT,
                payload: {
                    featureName: feature.optionName,
                    selectedIcon: feature.icon,
                },
            });
        }
    }, [feature]);

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const onChangeFeatureName = (event) => {
        const {
            target: { value },
        } = event;

        dispatchData({
            type: ACTION_TYPES.CHANGE_FEATURE_NAME,
            payload: value,
        });
    };
    const toggleConfirmDialog = (fieldName) => {
        dispatchData({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
            payload: fieldName,
        });
    };
    const onEdit = async () => {
        if (INVENTORY_VEHICLE_WRITE) {
            try {
                const variables = {
                    input: {
                        optionName: data.featureName.trim(),
                        icon: data.selectedIcon,
                    },
                    optionId: feature.optionId,
                };
                const editFeatureResponse = await updateFeature({ variables });

                if (editFeatureResponse?.data?.editFeature) {
                    onClose();
                    onReload();
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('edit', 'Feature'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('edit', 'Feature'));
            }
        }
    };
    const onCreate = async () => {
        if (INVENTORY_VEHICLE_WRITE) {
            try {
                const variables = {
                    input: {
                        optionName: data.featureName.trim(),
                        icon: data.selectedIcon,
                    },
                };
                const createFeatureResponse = await createFeature({ variables });

                if (createFeatureResponse?.data?.createFeature) {
                    onClose();
                    onReload();
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('create', 'Feature'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('create', 'Feature'));
            }
        }
    };
    const onSave = () => {
        const { featureName } = data;
        const allowSave = !StringUtils.isEmpty(featureName);

        if (allowSave) {
            if (editMode) {
                if (featureName !== feature.optionName) {
                    toggleConfirmDialog('editFeature');
                } else {
                    onEdit();
                }
            } else {
                onCreate();
            }
        }
    };
    const onDelete = async () => {
        if (INVENTORY_VEHICLE_WRITE) {
            try {
                const variables = {
                    optionId: feature.optionId,
                };
                const deleteFeatureResponse = await deleteFeature({ variables });

                if (deleteFeatureResponse?.data?.deleteFeature) {
                    toggleConfirmDialog();
                    onClose();
                    onReload();
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('delete', 'Feature'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('delete', 'Feature'));
            }
        }
    };
    const confirmDialogData = {
        edit: {
            title: 'Confirm edit Feature',
            description: `When you change the name, it will be changed for all Sold
                and Current Inventory which are associated with this Key Feature.
                If you don't want this to happen, you can create a new Key Feature`,
            onAccept: onEdit,
        },
        delete: {
            title: 'Confirm remove Feature',
            description: 'Are you sure you want to remove this feature?',
            onAccept: onDelete,
        },
    };
    const getConfirmDialogData = () => {
        if (data.editFeature) {
            return {
                ...confirmDialogData.edit,
                loading: loadingCreate || loadingEdit,
            };
        }
        if (data.deleteFeature) {
            return {
                ...confirmDialogData.delete,
                loading: loadingDelete,
            };
        }

        return {};
    };
    const setSelectedIcon = (selectedIcon = null) => {
        dispatchData({
            type: ACTION_TYPES.SET_SELECTED_ICON,
            payload: selectedIcon,
        });
    };
    let allowSave = false;

    if (editMode) {
        allowSave = !StringUtils.isEmpty(data.featureName) && (
            data.featureName.trim() !== (feature.optionName || '').trim() || data.selectedIcon !== feature.icon
        );
    } else {
        allowSave = !StringUtils.isEmpty(data.featureName);
    }
    const saving = loadingCreate || loadingEdit;
    const confirmData = getConfirmDialogData();
    const addIcon = (iconList = []) => {
        dispatchData({
            type: ACTION_TYPES.ADD_NEW_ICONS,
            payload: iconList,
        });
    };

    return (
        <>
            <Dialog
                open={open}
                maxWidth="md"
                classes={{
                    paper: classes.paperFull,
                }}
                className={classes.dialog}
            >
                <DialogAppBar title="Key Feature" onClose={onClose} />
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        fullWidth
                        label="Feature Name"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={data.featureName}
                        onChange={onChangeFeatureName}
                    />
                    <KeyFeatureIconList
                        toggleConfirmDialog={toggleConfirmDialog}
                        editMode={editMode}
                        feature={feature}
                        setSelectedIcon={setSelectedIcon}
                        newIcons={data.newIcons}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <div className={classes.leftDialogActions}>
                        <Button
                            margin="dense"
                            disabled={!editMode || saving}
                            className={classes.deleteFeatureButton}
                            variant="contained"
                            onClick={() => toggleConfirmDialog('deleteFeature')}
                        >
                            Delete this Feature
                        </Button>
                        <UploadIcon
                            disabled={saving}
                            addIcon={addIcon}
                        />
                    </div>
                    <div className={classes.rightDialogActions}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            margin="dense"
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.buttonGreen}
                            variant="contained"
                            color="primary"
                            margin="dense"
                            disabled={!allowSave || saving}
                            onClick={onSave}
                        >
                            Save
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            {data.showConfirmDialog && (
                <ConfirmDialog
                    title={confirmData.title}
                    description={confirmData.description}
                    open={data.showConfirmDialog}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={toggleConfirmDialog}
                    onClickSecondary={toggleConfirmDialog}
                    onClickPrimary={confirmData.onAccept}
                    disablePrimaryButton={confirmData.loading}
                />
            )}
        </>
    );
};

KeyFeatureDialog.propTypes = {
    feature: PropTypes.object,
    open: PropTypes.bool,
    editMode: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
};

KeyFeatureDialog.defaultProps = {
    open: false,
    feature: null,
};

export default KeyFeatureDialog;

export default class VendorUtils {
    static getFormulaFields() {
        return [
            { id: 1, value: 'AccessoriesCost' },
            { id: 2, value: 'AccessoriesPrice' },
            { id: 3, value: 'AmountFinanced' },
            { id: 4, value: 'BalanceDue' },
            { id: 5, value: 'BuyRate' },
            { id: 6, value: 'BuyRatePaymentAmount' },
            { id: 7, value: 'DealerFee' },
            { id: 8, value: 'Discount' },
            { id: 9, value: 'DocStamp' },
            { id: 10, value: 'DownPayment' },
            { id: 11, value: 'GapCost' },
            { id: 12, value: 'GapPrice' },
            { id: 13, value: 'InsuranceCost' },
            { id: 14, value: 'InsurancePrice' },
            { id: 15, value: 'InterestRate' },
            { id: 16, value: 'MinimumPrice' },
            { id: 17, value: 'PaymentAmount' },
            { id: 18, value: 'Registration' },
            { id: 19, value: 'ReserveAmount' },
            { id: 20, value: 'ReservePercentage' },
            { id: 21, value: 'SalesmanPrice' },
            { id: 22, value: 'SalesPrice' },
            { id: 23, value: 'SalesTax' },
            { id: 24, value: 'TagCost' },
            { id: 25, value: 'Tags' },
            { id: 26, value: 'Taxable' },
            { id: 27, value: 'Term' },
            { id: 28, value: 'Title' },
            { id: 29, value: 'Trade1ACV' },
            { id: 30, value: 'Trade2ACV' },
            { id: 31, value: 'Trade1Allowance' },
            { id: 32, value: 'Trade2Allowance' },
            { id: 33, value: 'Trade1Payoff' },
            { id: 34, value: 'Trade2Payoff' },
            { id: 35, value: 'WarrantyCost' },
            { id: 36, value: 'WarrantyPrice' },
            { id: 37, value: 'VehicleCost' },
        ];
    }

    static getVendorStates() {
        return [
            'AL',
            'AK',
            'AZ',
            'AR',
            'CA',
            'CO',
            'CT',
            'DE',
            'DC',
            'FL',
            'GA',
            'HI',
            'ID',
            'IL',
            'IN',
            'IA',
            'KS',
            'KY',
            'LA',
            'ME',
            'MD',
            'MA',
            'MI',
            'MN',
            'MS',
            'MO',
            'MT',
            'NE',
            'NV',
            'NH',
            'NJ',
            'NM',
            'NY',
            'NC',
            'ND',
            'OH',
            'OK',
            'OK',
            'OR',
            'PA',
            'RI',
            'SC',
            'SD',
            'TN',
            'TX',
            'UT',
            'VT',
            'VA',
            'WA',
            'WV',
            'WI',
            'WY',
        ];
    }

    static getVendorTypes() {
        return [
            'Financial Institution',
            'Insurance Company',
            'Product Vendor',
            'Repair Vendor',
            'Floorplan',
            'Expenses',
            'Tax Assessor-Collector',
            'Appraisal District',
            'Recovery Agent',
        ];
    }
}

import { gql } from '@apollo/client';

export default class MenuMutation {
    static SAVE_USER_SETTINGS = gql`
        mutation saveUserSettings(
            $id: String,
            $pricingVisibility: String!,
            $packageDistribution: String!,
            $saveSettings: Boolean!,
            $products: [ProductSettingInput]!
        ) {
            saveUserSettings(
                id: $id
                pricingVisibility: $pricingVisibility
                packageDistribution: $packageDistribution
                saveSettings: $saveSettings
                products: $products
            )
        }
    `;

    static GENERATE_CONTRACT = gql`
        mutation generateContract(
            $vendorId: Int!,
            $productId: Int!,
            $dealNumber: Int!,
            $rateData: RateInput!
        ) {
            generateContract(
                vendorId: $vendorId
                productId: $productId
                dealNumber: $dealNumber
                rateData: $rateData
            ) {
                contractNumber
                effectiveDate
                expirationDate
                expirationOdometer
                pdf
            }
        }
    `;

    static VOID_CONTRACT = gql`
        mutation voidContract(
            $vendorId: Int!,
            $contractNumber: String!,
            $vin: String!
        ) {
            voidContract(
                vendorId: $vendorId
                contractNumber: $contractNumber
                vin: $vin
            )
        }
    `;
}

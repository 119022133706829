/* eslint-disable react/no-danger */
import React, { useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
    makeStyles, Accordion,
    AccordionDetails, AccordionSummary,
    Button,
} from '@material-ui/core';
import {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    PAGE_FORMAT,
    PAGE_ORIENTATION,
} from 'utils/enum/General';
import { ENTITY_TYPE, PRINTABLE_TYPE, COMMON_CONSTANT } from 'utils/enum/BusinessIntelligenceEnum';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ArrayUtils from 'lib/ArrayUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import GeneralUtils from 'utils/GeneralUtils';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import GeneralQuery from 'services/graphQL/query/GeneralQuery';
import VirtualTable from 'components/widgets/VirtualTable';
import BIHelper from 'utils/BusinessIntelligenceHelper';

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrintOutlinedIcon } from 'components/icons';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    header: {
        color: theme.palette.text.white,
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: theme.palette.background.bigStone,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingLeft: '45px',
        paddingRight: '45px',
        paddingTop: '30px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 0,
            minHeight: '80vh',
        },
    },
    loader: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '27px',
        fontSize: '14px',
        backgroundColor: theme.palette.secondaryInfo.main,
        color: theme.palette.text.white,
        fontWeight: 'bold',
    },
    empty: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '28px',
        color: theme.palette.text.waterloo,
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    category: {
        backgroundColor: theme.palette.primary.main,
        pointerEvents: 'none',
        height: '40px',
        overflow: 'hidden',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        fontWeight: 'bold',
    },
    accordion: {
        borderRadius: 'initial',
        boxShadow: 'initial',
        paddingLeft: '35px',
        '& > div:nth-child(1)': {
            minHeight: 'initial',
            height: '35px',
            '& > div:nth-child(1)': {
                fontSize: '15px',
            },
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.white,
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.whitesmoke,
        },
    },
    accordionDetails: {
        flexDirection: 'column',
        backgroundColor: theme.palette.background.white,
    },
    subheader: {
        color: theme.palette.text.darkBlue,
        padding: '5px',
        fontSize: '15px',
    },
    instructions: {
        padding: '10px',
        minHeight: '70px',
        fontSize: '15px',
        listStylePosition: 'inside',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '350px',
        overflowY: 'hidden',
        overflowX: 'auto',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
                '& > div.ReactVirtualized__Table__headerTruncatedText': {
                    justifyContent: 'center',
                },
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tools: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        '& > span': {
            fontSize: '15px',
            marginRight: '10px',
        },
        '& > button:nth-child(2)': {
            marginRight: '10px',
        },
    },
    ruleLabel: {
        fontWeight: 'bold',
    },
}));

const INIT_STATE = {
    isLoading: true,
    loadingCounter: 1,
    expandedAccordion: '',
    rules: [],
    selectedRule: null,
};

const ACTION_TYPES = {
    UPDATE_FIELDS: 'updateFields',
    UPDATE_RULE: 'updateRule',
    UPDATE_RULES: 'updateRules',
    SET_SELECTED_RULE: 'setSelectedRule',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.UPDATE_FIELDS:
        return {
            ...state,
            ...action.value,
        };
    case ACTION_TYPES.UPDATE_RULE:
        const { ruleId, output } = action.value;
        const clone = cloneDeep(state.rules);
        const rule = clone.find((el) => el.id === ruleId);

        if (rule && output && output.records?.length > 0) {
            const { records, total } = output;
            rule.matched = true;
            rule.records = [...(rule.records ?? []), ...records];
            rule.totalRecords = total;
            rule.paginationComplete = records.length === 0;
            rule.loadingMoreData = false;

            return {
                ...state,
                rules: clone,
                loadingCounter: state.loadingCounter + 1,
            };
        }

        return state;
    case ACTION_TYPES.SET_SELECTED_RULE:
        return {
            ...state,
            selectedRule: action.value,
        };
    case ACTION_TYPES.UPDATE_RULES:
        return {
            ...state,
            rules: action.value,
        };
    default:
        return state;
    }
};

const BusinessIntelligencePanel = () => {
    const classes = useStyles();
    const client = useApolloClient();
    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const {
        data: rulesData,
        loading: loadingRules,
        error: errorLoadingRules,
    } = useQuery(BIQuery.PULL_AVAILABLE_RULES_PER_USER, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const [printDocument, { loading: printingDocument }] = useLazyQuery(GeneralQuery.PRINT_DOCUMENT, {
        onCompleted: (response) => {
            const result = response.printDocument;
            if (result) {
                if (result?.data?.startsWith('Request failed')) {
                    ModalUtils.errorMessage(null, 'Error printing report');
                    return;
                }

                const {
                    documentType,
                    data: documentOutput,
                } = result;

                if (documentType === PRINTING_DOCUMENT_TYPE.PDF) {
                    BIHelper.printChart(PRINTABLE_TYPE.PDF, {
                        url: documentOutput,
                    });
                }

                if (documentType === PRINTING_DOCUMENT_TYPE.SPREADSHEET) {
                    const { selectedRule } = state;
                    const name = `${selectedRule?.label}-${DateUtils.format(new Date(), DateFormat.SHORT_DATE_WITH_DASHES)}.xlsx`;
                    GeneralUtils.downloadFile(documentOutput, name);
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        async function validateRules() {
            if (errorLoadingRules) {
                ModalUtils.errorMessage(errorLoadingRules?.graphQLErrors);
                return;
            }

            if (!loadingRules) {
                const rules = rulesData?.pullAvailableRulesPerUser;

                if (rules) {
                    const sorted = rules
                        .map((rule) => {
                            const { query = '' } = rule;
                            const paginationEnabled = ['@Offset', '@PageSize']
                                .every((variable) => query.toLowerCase().includes(variable.toLowerCase()));

                            return {
                                ...rule,
                                matched: false,
                                records: [],
                                ...(paginationEnabled ? { paginate: { start: 0, limit: COMMON_CONSTANT.PAGINATION_PAGE_SIZE } } : {}),
                            };
                        })
                        .sort((a, b) => a.category.name.localeCompare(b.category.name));

                    dispatch({
                        type: ACTION_TYPES.UPDATE_FIELDS,
                        value: {
                            rules: sorted,
                            ...(sorted.length === 0 ? { isLoading: false } : {}),
                        },
                    });

                    if (sorted.length > 0) {
                        const payloads = [];
                        sorted.forEach((el) => {
                            payloads.push({
                                id: el.id,
                                type: ENTITY_TYPE.RULE,
                                ...(el.paginate ? { paginate: el.paginate } : {}),
                            });
                        });

                        // eslint-disable-next-line no-use-before-define
                        if (payloads.length > 0) await ArrayUtils.processPromisesInSequence(payloads, pullQueryRecords);
                        setTimeout(() => dispatch(
                            {
                                type: ACTION_TYPES.UPDATE_FIELDS,
                                value: {
                                    isLoading: false,
                                },
                            },
                        ), 1000);
                    }
                }
            }
        }

        validateRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRules, errorLoadingRules]);

    const pullQueryRecords = async (payload) => {
        try {
            const { data } = await client.query({
                query: BIQuery.PULL_QUERY_RESULT_ENTITY,
                variables: payload,
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                const { entityId, output } = data.pullQueryResultOfEntity;
                dispatch({
                    type: ACTION_TYPES.UPDATE_RULE,
                    value: {
                        ruleId: entityId,
                        output,
                    },
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const formatRules = (rules) => {
        const matches = rules.filter((rule) => rule.matched);
        if (matches) {
            const output = {};
            matches.forEach((el) => {
                const category = el.category.name;
                if (!Object.keys(output).includes(category)) output[category] = [];
                output[category] = [...output[category], el];
            });

            return output;
        }

        return [];
    };

    const onAccordionChange = (name, isExpanded) => {
        dispatch({
            type: ACTION_TYPES.UPDATE_FIELDS,
            value: {
                expandedAccordion: isExpanded ? name : false,
            },
        });
    };

    const printChart = async (rule, documentType) => {
        const {
            label,
            query,
        } = rule;

        dispatch({
            type: ACTION_TYPES.SET_SELECTED_RULE,
            value: rule,
        });

        printDocument({
            variables: {
                title: label,
                source: {
                    content: query,
                    type: PRINTING_DOCUMENT_SOURCE_TYPE.QUERY,
                    queryValidation: true,
                    options: {
                        pageFormat: PAGE_FORMAT.LETTER,
                        paseOrientation: PAGE_ORIENTATION.LANDSCAPE,
                        border: 15,
                    },
                },
                type: documentType,
            },
        });
    };

    const loadMoreData = (ruleId) => {
        const { rules } = state;
        const rule = rules.find((el) => el.id === ruleId);
        const { paginationComplete, paginate, records } = rule;

        if (!paginationComplete) {
            rule.loadingMoreData = true;
            paginate.start = records.length;

            dispatch({ type: ACTION_TYPES.UPDATE_RULES, value: rules });
            pullQueryRecords({
                id: rule.id,
                type: ENTITY_TYPE.RULE,
                paginate,
            });
        }
    };

    const generateColumns = (records) => {
        if (records.length === 0) return [];
        const columns = records[0].map((el) => el.name);

        const generated = [];
        columns.forEach((col) => {
            const style = window.getComputedStyle(document.body);
            const fontFamily = style.getPropertyValue('font-family');

            const colWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(col, `bold 12px ${fontFamily}`) || 0) + 40;
            generated.push({
                label: col,
                dataKey: col,
                width: colWidth,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;
                    const value = record.find((e) => e.name?.toLowerCase() === col.toLowerCase())?.value;
                    const isHTML = StringUtils.isHTML(value);

                    if (isHTML) {
                        return (
                            <div
                                className={classes.text}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: value }}

                            />
                        );
                    }

                    return (
                        <span className={classes.text}>{value === 'null' ? '' : value}</span>
                    );
                },
            });
        });

        return generated;
    };

    const totalRules = state.rules.length;
    const progress = `${totalRules > 0 ? Math.ceil((state.loadingCounter * 100) / totalRules) : 0}%`;
    const formattedData = formatRules(state.rules);
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                Rules
            </div>
            <div className={classes.content}>
                {Object.keys(formattedData).map((category, index) => {
                    const rules = formattedData[category] || [];
                    return (
                        <div key={`category-${index}`}>
                            <div className={classes.category}>
                                <span className={classes.text}>{category}</span>
                            </div>
                            {rules.map((el, idx) => {
                                const accordionName = `${category}-accordion-${idx}`;
                                const columns = generateColumns(el.records);
                                const { innerWidth } = window;
                                let tableWidth = columns.reduce((a, b) => a + b.width, 0);
                                if (innerWidth > tableWidth) tableWidth = innerWidth - 250;

                                return (
                                    <Accordion
                                        key={accordionName}
                                        className={classes.accordion}
                                        expanded={state.expandedAccordion === accordionName}
                                        onChange={(_, isExpanded) => onAccordionChange(accordionName, isExpanded)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <span className={clsx(classes.text, classes.ruleLabel)}>{el.label}</span>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            className={classes.accordionDetails}
                                        >
                                            <div className={classes.subheader}>
                                                Instructions
                                            </div>
                                            <div
                                                className={classes.instructions}
                                                dangerouslySetInnerHTML={{ __html: el.instructions }}
                                            />
                                            <div className={classes.tools}>
                                                <span>{`Records: ${el.totalRecords ?? el.records.length}`}</span>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<PrintOutlinedIcon />}
                                                    disabled={printingDocument}
                                                    size="small"
                                                    onClick={() => printChart(el, PRINTING_DOCUMENT_TYPE.PDF)}
                                                >
                                                    Print
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<CloudDownloadOutlinedIcon />}
                                                    disabled={printingDocument}
                                                    size="small"
                                                    onClick={() => printChart(el, PRINTING_DOCUMENT_TYPE.SPREADSHEET)}
                                                >
                                                    Excel
                                                </Button>
                                            </div>
                                            <div id={el.id} className={classes.tableContainer}>
                                                <VirtualTable
                                                    tableWidth={document.getElementById(el.id)?.clientWidth - 57}
                                                    loading={el.loadingMoreData}
                                                    rowHeight={45}
                                                    totalRecords={el.totalRecords ?? el.records.length}
                                                    data={el.records}
                                                    columns={columns}
                                                    width={tableWidth}
                                                    loadMore={el.paginate ? () => loadMoreData(el.id) : null}
                                                />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            {!state.isLoading && !state.rules.some((rule) => rule.matched) && (
                <div className={classes.empty}>
                    All Empty!!!
                </div>
            )}
            {state.isLoading && (
                <div
                    style={{
                        width: progress,
                        paddingRight: progress === '0%' ? 0 : '10px',
                    }}
                    className={classes.loader}
                >
                    {progress}
                </div>
            )}
        </div>
    );
};

export default BusinessIntelligencePanel;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import { withRouter } from 'react-router-dom';
import ProductSettingsListContainer from 'components/containers/settings/products/ProductSettingsListContainer';
import ProductSettingsDialog from 'components/modules/settings/deals/ProductSettingsDialog';
import { Form } from 'react-bootstrap';
import InputSearch from 'components/widgets/InputSearch';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ProductSettingsStyles from 'styles/modules/settings/ProductSettingsStyles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { Typography } from '@material-ui/core';

const styles = (theme) => ProductSettingsStyles.listStyles(theme);

class ProductSettingsList extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SETTINGS_GENERAL_WRITE = keyStore.hasPermission(Permission.SETTINGS_GENERAL_WRITE);
    }

    getColumns() {
        const { props: { classes } } = this;

        return [{
            Header: 'Product ID',
            id: 'id',
            minWidth: 100,
            accessor: 'productId',
            className: classes.columnStyle,
        }, {
            Header: 'Vendor Name',
            id: 'vendorName',
            minWidth: 150,
            accessor: 'vendorName',
            className: classes.columnStyle,
        }, {
            Header: 'Product Name',
            id: 'productName',
            minWidth: 150,
            accessor: 'productName',
            className: classes.columnStyle,
        }, {
            Header: 'Product Placement',
            id: 'productType',
            minWidth: 100,
            accessor: 'productType',
            className: classes.columnStyle,
        }, {
            Header: 'Line 1',
            id: 'line1',
            minWidth: 70,
            accessor: 'isLine1',
            className: classes.columnStyle,
            Cell: ({ value }) => (value ? (<CheckIcon fontSize="small" className={classes.checkedGreen} />) : null),
        }, {
            Header: 'Active',
            id: 'active',
            minWidth: 100,
            accessor: 'productActive',
            className: classes.columnStyle,
            Cell: ({ value }) => {
                if (value) {
                    return (
                        <CheckIcon
                            fontSize="small"
                            className={classes.checkedGreen}
                        />
                    );
                }
                return (
                    <CheckBoxOutlineBlank
                        fontSize="small"
                    />
                );
            },
        }];
    }

    render() {
        const {
            props: {
                classes, openDialog, openProductDialog, closeProductDialog,
                isEditingProduct, onSave, selectedProductId, onSearch,
                products, loading, setSelectedProductId, onChangeProductStatus,
                searchTerm, productStatus, isMobile,
            },
        } = this;
        const totalRecords = products.length;
        const disableEditAndRemove = selectedProductId === null;

        return (
            <div className={classes.root}>

                {isMobile && (
                    <div>
                        <div className={classes.header}>
                            {this.SETTINGS_GENERAL_WRITE && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={clsx(classes.button, classes.newButton)}
                                    startIcon={<AddIcon />}
                                    onClick={() => openProductDialog(false)}
                                >
                                    New
                                </Button>
                            )}
                            {this.SETTINGS_GENERAL_WRITE && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={clsx(classes.button, classes.editButton)}
                                    startIcon={<BorderColorOutlinedIcon />}
                                    disabled={disableEditAndRemove}
                                    onClick={() => openProductDialog(true)}
                                >
                                    Edit
                                </Button>
                            )}
                            <Typography
                                variant="body1"
                            >
                                {`Items: ${totalRecords}`}
                            </Typography>
                        </div>
                        <div className={classes.header}>
                            <InputSearch
                                size="sm"
                                forceSearch
                                customClasses={classes.search}
                                onSearch={onSearch}
                                initialSearch={searchTerm}
                            />
                        </div>
                        <div className={classes.header}>
                            <div className="d-flex-center">
                                <Form.Group
                                    className="form-check form-check-inline mb-0"
                                >
                                    <Form.Check
                                        key="radioActive"
                                        type="radio"
                                        name="radio"
                                        id="radioActive"
                                        label="Active Products"
                                        value={productStatus}
                                        checked={productStatus}
                                        onChange={() => onChangeProductStatus(true)}
                                    />
                                    <Form.Check
                                        key="radioInactive"
                                        type="radio"
                                        name="radio"
                                        id="radioInactive"
                                        label="In-Active Products"
                                        value={!productStatus}
                                        checked={!productStatus}
                                        onChange={() => onChangeProductStatus(false)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                )}
                {!isMobile && (
                    <div className={classes.header}>
                        {this.SETTINGS_GENERAL_WRITE && (
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.newButton)}
                                startIcon={<AddIcon />}
                                onClick={() => openProductDialog(false)}
                            >
                                New
                            </Button>
                        )}
                        {this.SETTINGS_GENERAL_WRITE && (
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.editButton)}
                                startIcon={<BorderColorOutlinedIcon />}
                                disabled={disableEditAndRemove}
                                onClick={() => openProductDialog(true)}
                            >
                                Edit
                            </Button>
                        )}
                        <InputSearch
                            size="sm"
                            forceSearch
                            customClasses={classes.search}
                            onSearch={onSearch}
                            initialSearch={searchTerm}
                        />
                        <Typography
                            variant="body1"
                        >
                            {`Items: ${totalRecords}`}
                        </Typography>
                        <div className="d-flex-center">
                            <Form.Group
                                className="form-check form-check-inline mb-0"
                            >
                                <Form.Check
                                    key="radioActive"
                                    type="radio"
                                    name="radio"
                                    id="radioActive"
                                    label="Active Products"
                                    value={productStatus}
                                    checked={productStatus}
                                    onChange={() => onChangeProductStatus(true)}
                                />
                                <Form.Check
                                    key="radioInactive"
                                    type="radio"
                                    name="radio"
                                    id="radioInactive"
                                    label="In-Active Products"
                                    value={!productStatus}
                                    checked={!productStatus}
                                    onChange={() => onChangeProductStatus(false)}
                                />
                            </Form.Group>
                        </div>
                    </div>

                )}

                <div className={classes.tableHeight}>
                    <Table
                        rowSelected
                        load={loading}
                        totalRecords={totalRecords}
                        data={products}
                        columns={this.getColumns()}
                        unselectRow={disableEditAndRemove}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onClick: () => {
                                    setSelectedProductId(record.productId);
                                },
                            };
                        }}
                    />
                </div>
                {openDialog
                    && (
                        <ProductSettingsDialog
                            open={openDialog}
                            isEditing={isEditingProduct}
                            onClose={closeProductDialog}
                            productId={selectedProductId}
                            onSave={onSave}
                        />
                    )}
            </div>
        );
    }
}

ProductSettingsList.propTypes = {
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    openProductDialog: PropTypes.func.isRequired,
    closeProductDialog: PropTypes.func.isRequired,
    setSelectedProductId: PropTypes.func.isRequired,
    isEditingProduct: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedProductId: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChangeProductStatus: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    productStatus: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

ProductSettingsList.defaultProps = {
    selectedProductId: null,
};

export default withRouter(withStyles(styles)(ProductSettingsListContainer(ProductSettingsList)));

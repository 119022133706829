export default class LabelStyles {
    static styles(theme = {}, fade) {
        return ({
            root: {
                alignItems: 'center',
                borderRadius: 2,
                display: 'inline-flex',
                flexGrow: 0,
                whiteSpace: 'nowrap',
                cursor: 'default',
                flexShrink: 0,
                fontSize: theme.typography.pxToRem(14),
                fontWeight: 400,
                justifyContent: 'center',
                letterSpacing: 0.5,
                minWidth: 20,
                textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'initial'),
                padding: '2px 5px',
            },
            primary: {
                color: theme.palette.primary.main,
                backgroundColor: fade(theme.palette.primary.main, 0.08),
            },
            secondary: {
                color: theme.palette.secondary.main,
                backgroundColor: fade(theme.palette.secondary.main, 0.08),
            },
            purple: {
                color: theme.palette.text.poloBlue,
                backgroundColor: theme.palette.background.selago,
            },
            error: {
                color: theme.palette.error.main,
                backgroundColor: fade(theme.palette.error.main, 0.08),
            },
            success: {
                color: theme.palette.success.main,
                backgroundColor: fade(theme.palette.success.main, 0.08),
            },
            warning: {
                color: theme.palette.warning.main,
                backgroundColor: fade(theme.palette.warning.main, 0.08),
            },
        });
    }
}

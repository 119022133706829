import { gql } from '@apollo/client';

export default class ActivityMutation {
    static CREATE_TASK_ACTIVITY = gql`
        mutation createTaskActivity($input: TaskActivityInput!) {
           createTaskActivity(input: $input)
        }
    `;

    static CREATE_CALL_ACTIVITY = gql`
        mutation createCallActivity($input: CallActivityInput!) {
            createCallActivity(input: $input)
        }
    `;

    static CREATE_EVENT_ACTIVITY = gql`
        mutation createEventActivity($input: EventActivityInput!) {
           createEventActivity(input: $input)
        }
    `;

    static CREATE_NOTE_ACTIVITY = gql`
        mutation createNoteActivity($input: NoteActivityInput!) {
           createNoteActivity(input: $input)
        }
    `;

    static UPDATE_CALL_ACTIVITY = gql`
        mutation updateCallActivity($input: CallActivityInput!, $activityId: ID!) {
            updateCallActivity(input: $input, activityId: $activityId)
        }
    `;

    static UPDATE_TASK_ACTIVITY = gql`
        mutation updateTaskActivity($input: TaskActivityInput!, $activityId: ID!) {
            updateTaskActivity(input: $input, activityId: $activityId)
        }
    `;

    static UPDATE_EVENT_ACTIVITY = gql`
        mutation updateEventActivity($input: EventActivityInput!, $activityId: ID!) {
            updateEventActivity(input: $input, activityId: $activityId)
        }
    `;

    static DELETE_ACTIVITY = gql`
        mutation deleteActivity($activityId: ID!, $processRecordId: ID!) {
            deleteActivity(activityId: $activityId, processRecordId: $processRecordId)
        }
    `;
}

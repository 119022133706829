import React from 'react';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import PayrollBaseList from 'components/modules/payroll/read/PayrollBaseList';
import DealCommissionTable from 'components/widgets/payroll/MiscCommissions/DealCommissionTable';
import GeneralCommissionPanel from 'components/widgets/payroll/MiscCommissions/GeneralCommissionTable';
import PurchasingAgentCommissionTable from 'components/widgets/payroll/MiscCommissions/PurchasingAgentCommissionTable';

const MiscCommissionList = () => {
    const keyStore = new KeyStore();
    const savedSizes = keyStore.getMiscCommissionSplitSize() || [70, 30];

    const onResizingSplit = (size) => {
        keyStore.setMiscCommissionSplitSize(size);
    };

    const getRightSideComponent = ({ employeeId, payPeriod, employeeType }) => {
        const isGeneralCommissions = employeeType.toUpperCase().includes('GENERAL');
        const isPurchasingAgentCommissions = employeeType.toUpperCase().includes('PURCHASING');
        const displayPanel = employeeId > 0 && Boolean(payPeriod);
        return (
            <div>
                {!displayPanel && (
                    <div>No display Available</div>
                )}
                {displayPanel && !isGeneralCommissions && !isPurchasingAgentCommissions && (
                    <DealCommissionTable
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                    />
                )}
                {displayPanel && isGeneralCommissions && (
                    <GeneralCommissionPanel
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                    />
                )}
                {displayPanel && isPurchasingAgentCommissions && (
                    <PurchasingAgentCommissionTable
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                    />
                )}
            </div>
        );
    };

    getRightSideComponent.propTypes = {
        payPeriod: PropTypes.string.isRequired,
        employeeId: PropTypes.number.isRequired,
        employeeType: PropTypes.string.isRequired,
    };

    return (
        <PayrollBaseList
            commissionOnly
            splitSize={savedSizes}
            onResizingSplit={onResizingSplit}
            getRightSideComponent={getRightSideComponent}
        />
    );
};

export default MiscCommissionList;

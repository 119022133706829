import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    makeStyles,
} from '@material-ui/core';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';

const useStyles = makeStyles({
    root: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
    slashedPrice: {
        display: 'flex',
        alignItems: 'center',
    },
});

const BookValueEditPanel = ({
    data,
    onChange,
    writePermission,
}) => {
    const classes = useStyles();

    if (!writePermission) {
        return null;
    }

    return (
        <Grid
            container
            orientation="column"
            className={classes.root}
        >
            <DisplayInput
                label="Retail"
                value={data.nadaRetail}
                onChange={(value) => onChange('nadaRetail', value)}
            />
            <DisplayInput
                label="Trade In"
                value={data.nadaTrade}
                onChange={(value) => onChange('nadaTrade', value)}
            />
            <Grid container item xs={12} sm={6} />
        </Grid>
    );
};

BookValueEditPanel.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    writePermission: PropTypes.bool,
};

BookValueEditPanel.defaultProps = {
    data: {},
    writePermission: false,
    onChange: () => {},
};

export default BookValueEditPanel;

import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import {
    List, ListItem, ListItemIcon, ListItemText, useMediaQuery,
} from '@material-ui/core';

// icons
import AppsIcon from '@material-ui/icons/Apps';

import { useHistory, useParams } from 'react-router-dom';
// utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import VendorSettingsList from 'components/modules/settings/general/vendors/VendorSettingsList';
import CompanySettings from 'components/modules/settings/general/company/CompanySettings';
import LotSettings from 'components/modules/settings/general/lots/LotSettings';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['company', 'lots', 'vendors'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    if (keyStore.hasPermission(Permission.SETTINGS_READ)) {
        settings.push(
            {
                index: 0,
                text: 'Company Information',
                icon: <AppsIcon />,
                component: <CompanySettings />,
            },
        );
        settings.push(
            {
                index: 1,
                text: 'Lots',
                icon: <AppsIcon />,
                component: <LotSettings />,
            },
        );
        settings.push(
            {
                index: 2,
                text: 'Vendors',
                icon: <AppsIcon />,
                component: <VendorSettingsList />,
            },
        );
    }

    return settings;
};

const GeneralSettings = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab || 'general'}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        {!isMobile && (
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                        )}
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            {settingsItems.map((item) => (
                activeIndex === item.index && <div className={classes.content} key={item.index}>{item.component}</div>
            ))}
        </Container>
    );
};

export default GeneralSettings;

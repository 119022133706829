export default class VehicleLocationStyle {
    static location() {
        return ({
            main: {
                display: 'flex',
                padding: 10,
                justifyContent: 'center',
            },
            select: {
                flex: 1,
            },
        });
    }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles, Dialog, DialogContent, AppBar, Toolbar, Typography, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Header from 'components/widgets/Header';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputSearch from 'components/widgets/InputSearch';
import DialogActions from 'components/widgets/modal/DialogActions';
import Split from 'react-split';
import { AddIcon } from 'components/icons';
import useCustomerAndVehicleMethods from 'components/modules/service/serviceInvoice//hooks/useCustomerAndVehicleMethods';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles((theme) => (
    {
        dialogContent: {
            height: '62vh',
            '& > div:first-child': {
                height: '62vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        search: {
            borderRadius: 10,
            width: 400,
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
        buttonSave: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.text.white,
            '&:hover': {
                backgroundColor: theme.palette.success.dark,
            },
        },
    }
));

const CustomerForServiceList = (props) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        appBar, centerItems, title, dialogContent, search,
    } = classes;

    const {
        inHouse, onClose, onSelectCustomer,
        showAddNewCustomerButton, onClickNewCustomerButton,
        onClickNewVehicleButton, showVehicleGrid,
    } = props;

    const {
        getCustomerData, loadingCustomerInfo, records, getCustomerColumns,
        getVehicleData, loadingVehicleInfo,
        vehicles, getVehicleColumns,
    } = useCustomerAndVehicleMethods({ inHouse });

    const [selectedRecord, selectCustomer] = useState(null);
    const [selectedVehicle, onSelectedVehicle] = useState(null);

    const onSelectedRecord = (value) => {
        selectCustomer(value);
        onSelectedVehicle(null);
    };

    const onSearch = (value) => {
        if (!inHouse && (!value || value?.length <= 3)) return;
        getCustomerData({
            variables: {
                inHouse,
                search: value,
            },
        });
    };

    const onSearchVehicle = (value) => {
        const { firstName = '', lastName = '', customerCode = 0 } = selectedRecord || {};
        getVehicleData({
            variables: {
                code: inHouse ? `${firstName} ${lastName}` : customerCode,
                inHouse,
                search: value?.trim() ?? '',
            },
        });
    };

    useEffect(() => {
        if (inHouse) {
            getCustomerData({
                variables: {
                    inHouse,
                    search: '',
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedRecord?.customerCode > 0) {
            const { firstName, lastName, customerCode } = selectedRecord;
            getVehicleData({
                variables: {
                    code: inHouse ? `${firstName} ${lastName}` : customerCode,
                    inHouse,
                    search: '',
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecord?.customerCode]);

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth={inHouse ? 'lg' : 'md'}
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            {showVehicleGrid ? 'Select customer and vehicle' : 'Select customer'}
                        </Typography>
                        <div className={centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <Split
                        sizes={showVehicleGrid ? [50, 50] : [100, 0]}
                        className={classes.split}
                        direction="vertical"
                        minSize={0}
                    >
                        <div className={classes.main}>
                            <Header>
                                <InputSearch
                                    autoFocus
                                    customClasses={clsx(search)}
                                    onSearch={onSearch}
                                />
                                {!inHouse && showAddNewCustomerButton && (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        size="small"
                                        onClick={onClickNewCustomerButton}
                                    >
                                        Add new customer
                                    </Button>
                                ) }
                            </Header>
                            <div className={classes.mainTable}>
                                <Table
                                    rowSelected
                                    cursor="default"
                                    load={loadingCustomerInfo}
                                    totalRecords={records.length || 0}
                                    data={loadingCustomerInfo ? [] : records}
                                    columns={getCustomerColumns()}
                                    enableRowHover
                                    getTrProps={(_, rowInfo) => ({
                                        onDoubleClick: () => {
                                            onSelectedRecord(rowInfo.original);
                                        },
                                        onClick: () => {
                                            onSelectedRecord(rowInfo.original);
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        {showVehicleGrid
                            && (
                                <div className={classes.main}>
                                    <Header>
                                        <InputSearch
                                            customClasses={clsx(search)}
                                            onSearch={onSearchVehicle}
                                            placeholder="Enter text to search vehicle"
                                        />
                                        {!inHouse && (
                                            <Button
                                                startIcon={<AddIcon />}
                                                size="small"
                                                onClick={() => onClickNewVehicleButton(selectedRecord)}
                                                disabled={!selectedRecord}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                Add new Vehicle
                                            </Button>
                                        ) }
                                    </Header>
                                    <div className={classes.mainTable}>
                                        <Table
                                            cursor="default"
                                            load={loadingVehicleInfo}
                                            totalvehicles={vehicles.length || 0}
                                            data={loadingVehicleInfo ? [] : vehicles}
                                            columns={getVehicleColumns()}
                                            className="-highlight"
                                            rowSelected
                                            enableRowHover
                                            getTrProps={(_, rowInfo) => ({
                                                onDoubleClick: () => {
                                                    onSelectedVehicle(rowInfo.original);
                                                },
                                                onClick: () => {
                                                    onSelectedVehicle(rowInfo.original);
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            )}
                    </Split>
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSelectCustomer({
                        customer: selectedRecord,
                        vehicle: selectedVehicle,
                    })}
                    disablePrimaryButton={!selectedRecord}
                />
            </Dialog>
        </>
    );
};

CustomerForServiceList.propTypes = {
    inHouse: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    showAddNewCustomerButton: PropTypes.bool,
    onClickNewCustomerButton: PropTypes.func,
    onClickNewVehicleButton: PropTypes.func,
    showVehicleGrid: PropTypes.bool,
};

CustomerForServiceList.defaultProps = {
    inHouse: false,
    showAddNewCustomerButton: false,
    onClickNewCustomerButton: () => {},
    onClickNewVehicleButton: () => {},
    showVehicleGrid: true,
};

export default CustomerForServiceList;

const HousingStatus = Object.freeze({
    MORTGAGE: 'Mortgage',
    RENT: 'Rent',
    FAMILY: 'Family',
    OTHER: 'Other',
    OWNOUTRIGHT: 'OwnOutright',
    MILITARY: 'Military',
});

const DefaultCountry = Object.freeze({
    COUNTRY: 'USA',
});

const Language = Object.freeze({
    ENGLISH: 'English',
    SPANISH: 'Spanish',
});

export { HousingStatus, DefaultCountry, Language };

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VehiclePanel from 'components/modules/deals/read/vehicle/VehiclePanel';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.default,
        '& .gridItem': {
            display: 'flex',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
            paddingRight: theme.spacing(1),
        },
        '& .gridItem:last-child': {
            padding: 0,
        },
    },
});

class VehiclesTab extends Component {
    render() {
        const {
            props: {
                classes, editingMode, onChangeEditingMode, accountNumber,
                clientId, editingStatus,
            },
        } = this;

        return (
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12} className="gridItem">
                    <VehiclePanel
                        clientId={clientId}
                        editingMode={editingMode}
                        editingStatus={editingStatus}
                        accountNumber={accountNumber}
                        onChangeEditingMode={onChangeEditingMode}
                    />
                </Grid>
            </Grid>
        );
    }
}

VehiclesTab.propTypes = {
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    clientId: PropTypes.string.isRequired,
    accountNumber: PropTypes.number.isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    editingStatus: PropTypes.object,
};

VehiclesTab.defaultProps = {
    editingStatus: {
        isEditing: false,
        editingById: null,
        editingBy: null,
    },
};

export default withStyles(styles)(VehiclesTab);

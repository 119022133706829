import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import ImagesUtils from 'utils/ImagesUtils';

export default class InventoryMap {
    static getTotalCost = (data) => {
        const {
            floorplanCost = 0,
            pack = 0,
            purchasedPrice = 0,
            repairs = 0,
        } = data;

        return {
            forUI: NumberUtils.round(purchasedPrice + pack + repairs + floorplanCost),
            toSave: NumberUtils.round(purchasedPrice + repairs),
        };
    };

    static mapPricingData(record) {
        if (record) {
            return {
                purchasedPrice: record.purchasedPrice,
                slashedPrice: record.slashedPrice,
                pack: record.packOverride ? (record.pack || 0) : record.pack,
                packOverride: record.packOverride || false,
                stickerPrice: record.stickerPrice,
                internetPrice: record.internetPrice,
                floorplanCost: record.floorplanCost,
                minimumPrice: record.minimumPrice,
                wholeSalePrice: record.wholeSalePrice,
                showSlashed: record.showSlashed || false,
                maximumReconCost: record.maximumReconCost,
            };
        }

        return {};
    }

    static mapBookValueData(record) {
        if (record) {
            return {
                nada: record.nadaTrade || 0,
                nadaRetail: record.nadaRetail || 0,
            };
        }

        return {};
    }

    static mapFloorplanData(record) {
        if (record) {
            return {
                isFloorPlanned: record.isFloorPlanned || false,
                flooredBy: record.flooredBy || '',
                floorPlannedDate: record.floorPlannedDate ? new Date(record.floorPlannedDate).toISOString() : null,
                floorPaidOn: record.floorPaidOn ? new Date(record.floorPaidOn).toISOString() : null,
                borrowedAmountOverride: record.isBorrowedAmountOverride || false,
                borrowedAmount: record.borrowedAmount || 0,
                cartalmentDate: record.curtailmentDate ? new Date(record.curtailmentDate).toISOString() : null,
                flooredRate: record.flooredRate || 0,
                floorplanCost: record.floorplanCost || 0,
            };
        }

        return {};
    }

    static mapAdvertisingData(record) {
        if (record) {
            return {
                enableReduce: record.enableReduce || false,
                reducePrice: record.reducePrice || '',
                reduceFrequency: record.reduceFrequency || '',
                placeAd: record.placeAd || false,
                removeAdvertising: record.removeAd || '',
                lastReduced: record.lastReduced ? new Date(record.lastReduced).toISOString() : null,
                websiteDownPayment: record.websiteDownpayment || 0,
                websitePayment: record.websitePayment || 0,
                paymentFrequency: record.paymentFrequency || '',
            };
        }

        return {};
    }

    static mapUpdateVehicle(record) {
        if (record) {
            const newRecord = {
                year: record.year.toString(),
                make: record.make.toUpperCase(),
                model: record.model.toUpperCase(),
                trim: record.trim,
                style: record.style,
                engine: record.engine,
                vin: record.vin,
                transmission: record.transmission,
                extColor: record.extColor,
                intColor: record.intColor,
                genericExteriorColor: record.genericExteriorColor,
                lotName: record.lotName,
                miles: record.miles,
                tmu: record.tmu,
                exempt: record.exempt,
                eml: record.eml,
                titleStatus: record.titleStatus,
                spareKey: record.spareKey,
                wholesale: record.wholesale,
                titleNumber: record.titleNumber,
                title: record.title,
                tag: record.tag,
                tagExpires: DateUtils.format(record.tagExpires),
                purchasedFrom: record.pricing.purchasedFrom,
                purchasingAgent: record.pricing.purchasedAgent,
                purchasedDate: DateUtils.format(record.pricing.purchasedDate),
                drivetrain: record.drivetrain,
                active: record.active,
                userTabURL1: record.userTabURL1,
                userTabURL2: record.userTabURL2,
            };

            if (record.thumbnail) {
                newRecord.thumbnail = record.thumbnail;
            }
            if (record.alternativeThumbnail) {
                newRecord.alternativeThumbnail = record.alternativeThumbnail;
            }

            if (Object.keys(record.lienHolder).length > 0) {
                newRecord.lienHolder = record.lienHolder;
            }

            return newRecord;
        }

        return {};
    }

    static mapVIN(data = {}, vin = '') {
        if (data) {
            const { interiorColor, exteriorColor } = data;
            const image = ImagesUtils.getSecureImageURL(
                data.image,
                data.bodyStyle,
            );

            return {
                year: data.year,
                make: data.make,
                model: data.model,
                trimList: data.trim || [],
                trim: Array.isArray(data.trim) ? data.trim[0] : '',
                decodedStyle: data.decodedStyle,
                style: data.bodyStyle,
                engine: data.engines[0] || '',
                engineList: data.engines,
                cityMPG: data.cityMPG,
                highwayMPG: data.highwayMPG,
                vin,
                intColor: interiorColor ? interiorColor.colorName : '',
                extColor: exteriorColor ? exteriorColor.colorName : '',
                exteriorColorHEX: exteriorColor ? exteriorColor.rgbValue : '',
                drivetrain: data.drivetrain,
                alternativeThumbnail: image,
                thumbnail: image,
            };
        }

        return {};
    }
}

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import If from 'components/widgets/conditional/If';
import { arrayMove } from 'react-sortable-hoc';
import { useApolloClient, useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import { FetchPolicy } from 'utils/enum/Core';
import CompanyQuery from 'services/graphQL/query/core/CompanyQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import { AdvertisingTabs, SpecialRegularExpression, ConfirmDialogOperation } from 'utils/enum/InventoryEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import PhotosTab from 'components/modules/inventory/read/PhotosTab';
import DescriptionTab from 'components/modules/inventory/read/DescriptionTab';
import AdvertisingTabReducer, { ACTION_TYPES } from 'components/modules/inventory/reducer/AdvertisingTabReducer';

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: '55px',
        },
        '& div.MuiTabs-root div.MuiTabs-flexContainer > button': {
            '& > span': {
                fontSize: '12px',
            },
        },
    },
}));

const AdvertisingTab = ({
    images,
    thumbnail,
    description,
    stockNumber,
    setVehicleDetail,
    setVehicleImages,
}) => {
    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const classes = useStyles();
    const client = useApolloClient();
    const {
        createThumbnail,
        addImages,
        deleteImages,
        watermarkImages,
    } = useImagesActions();

    const INITIAL_STATE = {
        data: {
            images: [],
            thumbnail: '',
            description: '',
            stockNumber: 0,
        },
        imagesCounter: 0,
        selectedTab: 0,
        isEditMode: false,
        isUploadingImages: false,
        isConfirmDialogOpen: false,
        operation: null,
        isReorderModeOn: false,
        watermarks: [],
        watermark: null,
        selectedImages: [],
        marketingImagesData: null,
        isInsertingMarketingImages: false,
        isGeneratingDescription: false,
        threadId: null,
        generateByAI: false,
    };
    const [state, dispatch] = useReducer(AdvertisingTabReducer, INITIAL_STATE);
    const {
        data,
        imagesCounter,
        selectedTab,
        isEditMode,
        isUploadingImages,
        isConfirmDialogOpen,
        operation,
        isReorderModeOn,
        watermarks,
        watermark,
        selectedImages,
        isInsertingMarketingImages,
        isGeneratingDescription,
        generateByAI,
    } = state;

    const {
        data: watermarksData,
        loading: loadingWatermarks,
        error: errorWatermarks,
    } = useQuery(CompanyQuery.PULL_AVAILABLE_WATERMARKS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_INIT_DATA,
            value: {
                images,
                thumbnail,
                description,
                stockNumber,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images, stockNumber]);

    useEffect(() => {
        if (errorWatermarks) {
            ModalUtils.errorMessage(errorWatermarks?.graphQLErrors);
        }

        if (!loadingWatermarks) {
            const response = watermarksData?.pullAvailableWatermarks;

            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_WATERMARKS,
                    value: response.slice().sort((a, b) => a.name.localeCompare(b.name)) || [],
                });
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingWatermarks, errorWatermarks]);

    const onTabChange = (_, value) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_TAB,
            value,
        });
    };

    const onDescriptionChange = (content, threadId) => {
        dispatch({
            type: ACTION_TYPES.SET_DESCRIPTION,
            value: content,
            threadId,
        });
    };

    const onDescriptionSave = async () => {
        try {
            const { data: saveDescriptionResponse } = await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_DESCRIPTION,
                variables: {
                    stockNumber: data.stockNumber,
                    description: data.description ?? '',
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const updated = saveDescriptionResponse?.saveVehicleDescription;
            if (updated) {
                ModalUtils.successMessage(null, 'Description saved successfully');

                setVehicleDetail('description', data.description);
                dispatch({
                    type: ACTION_TYPES.TOGGLE_DESCRIPTION_MODE,
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const toggleDescriptionMode = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_DESCRIPTION_MODE,
        });
    };

    const saveImages = async (imagesInput, thumbnailInput) => {
        try {
            const { data: rearrangeImagesResponse } = await client.mutate({
                mutation: InventoryMutation.REARRANGE_VEHICLE_IMAGES,
                variables: {
                    stockNumber: data.stockNumber,
                    images: imagesInput,
                    thumbnail: thumbnailInput,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = rearrangeImagesResponse?.rearrangeVehicleImages;
            if (response) {
                ModalUtils.successMessage(null, 'Images modified successfully');

                setVehicleImages(imagesInput, thumbnailInput);
                dispatch({
                    type: ACTION_TYPES.SET_INIT_DATA,
                    value: {
                        ...data,
                        images: imagesInput,
                        thumbnail: thumbnailInput,
                    },
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const onAddImages = async (files, replacement = false) => {
        const dispatcherPayload = {
            type: ACTION_TYPES.TOGGLE_UPLOADING_IMAGES,
        };

        addImages(
            files,
            dispatch,
            dispatcherPayload,
            null,
            null,
            null,
            data.stockNumber,
            InventoryMutation.UPLOAD_VEHICLE_PHOTO,
            'uploadVehiclePhoto',
            null,
            saveImages,
            true,
            replacement,
            imagesCounter,
            data.thumbnail,
            data.images,
            selectedImages,
        );
    };

    const onConfirmDialogOpen = (opt, useAI) => {
        if (opt === ConfirmDialogOperation.EDIT_DESCRIPTION && StringUtils.isEmpty(data.description)) {
            // eslint-disable-next-line no-use-before-define
            generateDescription(false, useAI);
            return;
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG_OPEN,
            value: opt,
            generateByAI: useAI ?? false,
        });
    };

    const insertMarketingImages = async (resources, insertResourceAt, force = false) => {
        const totalImagesSpot = 60;
        const currentImagesInPlace = (data.images ?? []).length;
        if (currentImagesInPlace === totalImagesSpot) {
            ModalUtils.errorMessage(null, 'No spots available to insert the marketing images');
            return;
        }

        if (currentImagesInPlace + resources.length > totalImagesSpot && !force) {
            dispatch({
                type: ACTION_TYPES.SET_MARKETING_IMAGES_DATA,
                value: {
                    resources,
                    insertResourceAt,
                },
            });

            onConfirmDialogOpen(ConfirmDialogOperation.CHECK_MARKETING_IMAGES);
            return;
        }

        const currentResources = force ? (state.marketingImagesData ?? {}).resources : resources;
        const currentInsertAt = force ? (state.marketingImagesData ?? {}).insertResourceAt : insertResourceAt;
        if (currentResources && currentInsertAt) {
            let loopedRecords = 0;
            let currentResourceIndex = 0;
            const newImages = [];

            const currentImages = data.images;
            for (let x = 0; x < currentImages.length; x += 1) {
                newImages.push(currentImages[x]); loopedRecords += 1;
                if (
                    newImages.length < totalImagesSpot
                    && loopedRecords === currentInsertAt
                    && currentResourceIndex < currentResources.length
                ) {
                    newImages.push(currentResources[currentResourceIndex].resourceURL);
                    loopedRecords = 0;
                    currentResourceIndex += 1;
                }
            }

            dispatch({ type: ACTION_TYPES.TOGGLE_INSERTING_MARKETING_IMAGES });
            await saveImages(newImages, data.thumbnail);
            dispatch({ type: ACTION_TYPES.TOGGLE_INSERTING_MARKETING_IMAGES });
        }
    };

    const onDeleteImages = async () => deleteImages(
        data.images,
        null,
        InventoryMutation.REMOVE_VEHICLE_PHOTOS,
        selectedImages,
        null,
        null,
        null,
        true,
        saveImages,
        data.stockNumber,
        data.thumbnail,
    );

    const toogleReorderMode = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_REORDER_MODE_ON,
        });
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG_OPEN,
        });
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const dataSorted = arrayMove(
                data.images,
                oldIndex,
                newIndex,
            );

            dispatch({
                type: ACTION_TYPES.SET_INIT_DATA,
                value: {
                    ...data,
                    images: dataSorted,
                    thumbnail: data.thumbnail,
                },
            });
        }
    };

    const saveImagesPosition = async () => {
        let thumbnailURL = data.thumbnail;

        if (data.images.length > 0) {
            const firstImage = data.images[0];
            const regExp = new RegExp(SpecialRegularExpression.IMAGES_BUCKET_MATCH);
            const isHostedOnUs = regExp.test(firstImage);

            if (isHostedOnUs) {
                const { origin, pathname } = new URL(firstImage);
                const startPositionToSlice = pathname.search(regExp) === -1
                    ? 1 : 2;
                const path = pathname
                    .split('/')
                    .slice(startPositionToSlice)
                    .join('/');

                const thumbnailRequest = await createThumbnail(path, data.stockNumber);
                if (thumbnailRequest && thumbnailRequest.success) {
                    thumbnailURL = `${origin}${thumbnailRequest.thumbnail}`;
                }
            }
        }

        saveImages(data.images, thumbnailURL);
        toogleReorderMode();
    };

    const updateWatermark = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_WATERMARK,
            value,
        });
    };

    const toogleImageSelection = (index, value) => {
        let clone = [...selectedImages];

        if (value) {
            clone.push(index);
        } else {
            clone = clone.filter((ix) => ix !== index);
        }

        dispatch({
            type: ACTION_TYPES.SET_SELECTED_IMAGES,
            value: clone,
        });
    };

    const watermarkSelectedImages = async () => {
        const dispatcherPayload = {
            type: ACTION_TYPES.TOGGLE_UPLOADING_IMAGES,
        };

        watermarkImages(
            selectedImages,
            data.images,
            dispatch,
            dispatcherPayload,
            watermark,
            onAddImages,
        );
    };

    const generateDescription = async (closeDialog = true, useAI) => {
        try {
            dispatch({ type: ACTION_TYPES.TOGGLE_GENERATING_VEHICLE_DESCRIPTION });

            const { data: response } = await client.mutate({
                mutation: InventoryMutation.GENERATE_VEHICLE_DESCRIPTION,
                variables: {
                    stockNumber: data.stockNumber,
                    useAI: useAI ?? generateByAI,
                    threadId: state.threadId,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const { content, threadId } = response?.generateVehicleDescription ?? {};
            if (content) {
                ModalUtils.successMessage(null, 'Description generated successfully');

                setVehicleDetail('description', content);
                onDescriptionChange(content, threadId);
                if (closeDialog) onConfirmDialogOpen(null);
            }

            dispatch({ type: ACTION_TYPES.TOGGLE_GENERATING_VEHICLE_DESCRIPTION });
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
            dispatch({ type: ACTION_TYPES.TOGGLE_GENERATING_VEHICLE_DESCRIPTION });
        }
    };

    const chooseOperation = () => {
        if (operation === ConfirmDialogOperation.DELETE) onDeleteImages();
        if (operation === ConfirmDialogOperation.WATERMARK) watermarkSelectedImages();
        if (operation === ConfirmDialogOperation.EDIT_DESCRIPTION) generateDescription();
        if (operation === ConfirmDialogOperation.CHECK_MARKETING_IMAGES) insertMarketingImages(null, null, true);
    };

    const getDialogMessage = () => {
        if (!operation) return '';

        switch (operation) {
        case ConfirmDialogOperation.DELETE:
            return 'Do you want to delete these images?';
        case ConfirmDialogOperation.WATERMARK:
            return 'Do you want to watermark these images?';
        case ConfirmDialogOperation.EDIT_DESCRIPTION:
            return 'Regenerating the description will override the existing description with default information in Settings, continue?';
        case ConfirmDialogOperation.CHECK_MARKETING_IMAGES:
            return 'There are no enough spots to insert all the marketing images. Do you want to insert only into the spots available?, continue?';
        default:
            return '';
        }
    };

    return (
        <div className={classes.main}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="PHOTOS" />
                    <Tab label="DESCRIPTION" />
                </Tabs>
            </AppBar>
            <If condition={selectedTab === AdvertisingTabs.PHOTOS}>
                <PhotosTab
                    canWrite={INVENTORY_VEHICLE_WRITE}
                    isUploadingImages={isUploadingImages}
                    addImages={onAddImages}
                    images={data.images}
                    isReorderModeOn={isReorderModeOn}
                    toogleReorderMode={toogleReorderMode}
                    onSortEnd={onSortEnd}
                    saveImagesPosition={saveImagesPosition}
                    watermark={watermark}
                    watermarks={watermarks}
                    updateWatermark={updateWatermark}
                    selectedImages={selectedImages}
                    toogleImageSelection={toogleImageSelection}
                    onConfirmDialogOpen={onConfirmDialogOpen}
                    insertMarketingImages={insertMarketingImages}
                    isInsertingMarketingImages={isInsertingMarketingImages}
                />
            </If>
            <If condition={selectedTab === AdvertisingTabs.DESCRIPTION}>
                <DescriptionTab
                    canWrite={INVENTORY_VEHICLE_WRITE}
                    description={data.description}
                    isEditMode={isEditMode}
                    onDescriptionChange={onDescriptionChange}
                    onDescriptionSave={onDescriptionSave}
                    onGenerateDescription={(useAI) => onConfirmDialogOpen(ConfirmDialogOperation.EDIT_DESCRIPTION, useAI)}
                    toggleDescriptionMode={toggleDescriptionMode}
                    isGeneratingDescription={isGeneratingDescription}
                />
            </If>
            <ConfirmDialog
                title="Attention!"
                description={getDialogMessage()}
                open={isConfirmDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onConfirmDialogClose}
                onClickSecondary={onConfirmDialogClose}
                onClickPrimary={chooseOperation}
                disablePrimaryButton={isUploadingImages || isGeneratingDescription}
                disableSecondaryButton={isUploadingImages || isGeneratingDescription}
            />
        </div>
    );
};

AdvertisingTab.propTypes = {
    images: PropTypes.array,
    thumbnail: PropTypes.string,
    description: PropTypes.string,
    stockNumber: PropTypes.number,
    setVehicleDetail: PropTypes.func.isRequired,
    setVehicleImages: PropTypes.func.isRequired,
};

AdvertisingTab.defaultProps = {
    images: [],
    thumbnail: '',
    description: '',
    stockNumber: 0,
};

export default AdvertisingTab;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

// Components and Others
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import DealService from 'services/modules/DealService';

const AddressContainer = (WrappedComponent) => class extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        customerCurrentAddressId: PropTypes.string,
        buyerAddress: PropTypes.array,
    };

    static defaultProps = {
        buyerAddress: null,
        customerCurrentAddressId: null,
    };

    constructor(props) {
        super(props);
        this.dealService = new DealService();

        this.state = {
            open: false,
            openConfirm: false,
            openFormDialog: false,
            addressId: null,
            saving: false,
            openDialogManage: false,
            reloadManagerAddress: false,
            editFromManage: false,
            record: {},
        };
        this.initBind();
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onClose() {
        this.setState({
            open: false,
        });
    }

    onOpenConfirm(addressId) {
        this.setState({
            openConfirm: true,
            addressId,
        });
    }

    onCloseConfirm() {
        this.setState({
            openConfirm: false,
            addressId: null,
        });
    }

    onDelete() {
        const { state: { addressId } } = this;

        this.dealService.removeAddressToPreviousList(addressId)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.deleteDealPreviousAddress) {
                    this.setState({
                        openConfirm: false,
                        addressId: null,
                    });
                }
            });
    }

    onAddAddress() {
        const { props: { buyerAddress, customerCurrentAddressId } } = this;
        const dealCurrentAddress = buyerAddress?.find((x) => x.isCurrentAddress);

        if (customerCurrentAddressId && !dealCurrentAddress) {
            this.setCurrentAddress(customerCurrentAddressId);
        } else {
            this.toggleFormModal();
        }
    }

    onEdit(record) {
        this.setState({
            openFormDialog: true,
            reloadManagerAddress: false,
            editFromManage: true,
            record,
        });
    }

    setCurrentAddress(customerAddressId) {
        const { props: { accountNumber, type, customerId } } = this;
        this.setState({ saving: true });
        const input = {
            dealId: accountNumber,
            customerAddressId,
            customerId,
            type: type.replace(' ', '_'),
        };

        this.dealService.setDealCurrentAddress({ input })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
                ModalUtils.successMessage(null, 'Current Address was updated');
                this.setState({ saving: false });
            });
    }

    makeReloadAddress(isSave = false) {
        this.setState({
            reloadManagerAddress: isSave,
        });
    }

    toggleManageAddress() {
        this.setState((prev) => ({
            openDialogManage: !prev.openDialogManage,
            editFromManage: !prev.editFromManage,
        }));
    }

    toggleFormModal() {
        const { props: { buyerAddress }, state: { editFromManage } } = this;
        const recordToEdit = editFromManage ? {} : buyerAddress?.find((x) => x.isCurrentAddress) || {};

        this.setState((prevState) => ({
            openFormDialog: !prevState.openFormDialog,
            record: recordToEdit,
            editFromManage,
        }));
    }

    initBind() {
        this.onOpen = this.onOpen.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggleManageAddress = this.toggleManageAddress.bind(this);
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.onAddAddress = this.onAddAddress.bind(this);
        this.toggleFormModal = this.toggleFormModal.bind(this);
        this.setCurrentAddress = this.setCurrentAddress.bind(this);
        this.makeReloadAddress = this.makeReloadAddress.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onOpen={this.onOpen}
                onClose={this.onClose}
                onDelete={this.onDelete}
                onEdit={this.onEdit}
                toggleManageAddress={this.toggleManageAddress}
                onOpenConfirm={this.onOpenConfirm}
                onCloseConfirm={this.onCloseConfirm}
                onAddAddress={this.onAddAddress}
                toggleFormModal={this.toggleFormModal}
                makeReloadAddress={this.makeReloadAddress}
                setCurrentAddress={this.setCurrentAddress}
            />
        );
    }
};

export default AddressContainer;

import React, { Component } from 'react';

// Components and Others
import printJS from 'print-js';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import FormMap from 'services/mapData/FormMap';

// GraphQL
import FormService from 'services/modules/FormService';
import {
    findIndex, map, compact, orderBy,
} from 'lodash';
import GraphQLClient from 'services/apollo/GraphQLClient';
import FormMutation from 'services/graphQL/mutate/FormMutation';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import FormSubscription from 'services/graphQL/subscription/FormSubscription';
import StringUtils from 'lib/StringUtils';

const FormDetailContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        packSelected: PropTypes.object.isRequired,
        accountNumber: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);
        this.keyStore = new KeyStore();
        this.formService = new FormService();
        this.graphqlClient = new GraphQLClient();
        this.formPacksChangedSubscription = null;

        this.state = {
            formPackList: [],
            formLibrary: [],
            customField: [],
            isPreview: false,
            formPackListBackUp: [],
            loadingPrint: false,
            openDialogCustomField: false,
            printingFormWithCustomField: false,
        };
        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
        this.subscriptionFormPacksChanged();
        this.subscriptionFormPacksListChange();
    }

    componentDidUpdate(prevProps) {
        const { packSelected } = this.props;

        if (packSelected?.packId !== prevProps.packSelected?.packId) {
            this.unsubscribeFormPacksChanged();
            this.unsubscribeFormPacksListChange();
            this.getServicesData();
            this.subscriptionFormPacksChanged();
            this.subscriptionFormPacksListChange();
        }
    }

    componentWillUnmount() {
        this.unsubscribeFormPacksChanged();
        this.unsubscribeFormPacksListChange();
    }

    onSavePrintListCheckedDefault() {
        const { packSelected } = this.props;
        const { formPackList } = this.state;
        const currentFormPackList = formPackList.filter((item) => item.defaultChecked).map((item) => item.id);
        const input = {
            packId: packSelected.packId,
            formsId: currentFormPackList,
        };

        this.graphqlClient.mutate(FormMutation.savePrintListCheckedDefault, input)
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    /**
     * Check if the forms to print have custom field
     * If it has a custom filed, it shows a modal to add them, otherwise it calls the print method
     * */
    onPrintList() {
        const listOfFormsToPrint = this.formPackSelected();
        const { accountNumber } = this.props;
        this.setState({
            loadingPrint: true,
        });

        if (listOfFormsToPrint?.length > 0) {
            this.formService.getCustomFieldByForms({ forms: listOfFormsToPrint, dealId: accountNumber })
                .then((response) => {
                    const { graphQLErrors, data } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        this.setLoadingPrint();
                    }

                    if (data?.getCustomFieldByForms.length > 0) {
                        if (data.getCustomFieldByForms.some((item) => item.customField)) {
                            this.setState({
                                openDialogCustomField: true,
                                customField: this.mapCustomField(data?.getCustomFieldByForms) || [],
                            });
                        } else {
                            this.printForm(FormMap.mapFormWithoutCustomField(data?.getCustomFieldByForms));
                        }
                    } else {
                        this.printForm(FormMap.mapFormWithoutCustomField(listOfFormsToPrint));
                    }
                });
        }
    }

    onPreview(record) {
        const { accountNumber } = this.props;
        this.setState({
            isPreview: true,
        });

        this.formService.getCustomFieldByForms({ forms: [record.id], dealId: accountNumber })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    this.setLoadingPrint();
                }

                if (data?.getCustomFieldByForms.length > 0) {
                    // Check if its have custom fields
                    if (data.getCustomFieldByForms.some((item) => item.customField?.data)) {
                        this.setState({
                            openDialogCustomField: true,
                            customField: this.mapCustomField(data?.getCustomFieldByForms),
                        });
                    } else {
                        this.getFormAndOpenNewTab(FormMap.mapFormWithoutCustomField([record]));
                    }
                } else {
                    this.getFormAndOpenNewTab(FormMap.mapFormWithoutCustomField([record]));
                }
            });
    }

    onSearchForm(search = '') {
        this.getServicesData(search);
    }

    onChangeValueFormPack(id, checked) {
        const { formPackList } = this.state;
        const index = findIndex(formPackList, { id });

        if (index >= 0) {
            this.setState((prevState) => ({
                formPackList: update(prevState.formPackList, { [index]: { defaultChecked: { $set: checked } } }),
            }));
        }
    }

    onRemoveFormOfFormPack(formId) {
        const { packSelected } = this.props;
        const input = {
            formId,
            packId: packSelected.packId,
        };
        this.graphqlClient.mutate(FormMutation.REMOVE_FORM_OF_FORM_PACK, input)
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    onCloseCustomField() {
        this.setLoadingPrint();
    }

    setLoadingPrint(loading = false) {
        this.setState({
            printingFormWithCustomField: false,
            loadingPrint: loading,
            isPreview: false,
            openDialogCustomField: false,
        });
    }

    getServicesData(search = '') {
        const { packSelected } = this.props;
        const selectedLot = this.keyStore.getSelectedLot();
        const input = { packId: packSelected.packId };
        const inputAvailableForms = {
            packId: packSelected.packId,
            lotState: selectedLot.lotState,
            search,
        };

        this.formService.getFormByFormPacks(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getFormByFormPacks) {
                    const { getFormByFormPacks } = data;

                    this.setState({
                        formPackList: getFormByFormPacks,
                        formPackListBackUp: getFormByFormPacks,
                    });
                }
            });

        this.formService.getAllAvailableForms(inputAvailableForms)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getAllAvailableForms) {
                    const { getAllAvailableForms } = data;

                    this.setState({
                        formLibrary: getAllAvailableForms,
                    });
                }
            });
    }

    getFormAndOpenNewTab(records) {
        const { accountNumber } = this.props;
        const selectedLot = this.keyStore.getSelectedLot();
        const input = {
            forms: records,
            lotId: selectedLot.lotId,
            dealId: accountNumber,
        };

        this.formService.printFormPack({ input })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data?.printFormPack) {
                    const URL = data?.printFormPack;
                    window.open(URL, '_blank');
                }
            })
            .finally(() => {
                this.setLoadingPrint();
            });
    }

    getCustomFieldInformation(records) {
        this.setState({
            printingFormWithCustomField: true,
        });

        const { isPreview } = this.state;
        const result = FormMap.mapCustomField(records);

        // Do not call the pdf micro services if no form is mapped
        if (result.length === 0) {
            this.setLoadingPrint();
            return;
        }

        if (isPreview) {
            this.getFormAndOpenNewTab(result);
        } else {
            this.printForm(result);
        }
    }

    mapCustomField(records) {
        return records.map((item) => {
            let fields = [];

            if (!StringUtils.isEmpty(item.customField)) {
                fields = JSON.parse(item.customField.data)?.customField;
            }

            return {
                data: fields,
                formId: item.id,
                name: item.name,
                commonName: item.commonName,
                originalName: item.originalName,
            };
        });
    }

    printForm(list) {
        const { accountNumber } = this.props;
        const selectedLot = this.keyStore.getSelectedLot();

        const input = {
            forms: list,
            lotId: selectedLot.lotId,
            dealId: accountNumber,
        };

        this.formService.printFormPack({ input })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    this.setLoadingPrint();
                }

                if (data?.printFormPack) {
                    const URL = data?.printFormPack;
                    printJS({
                        printable: URL,
                        type: 'pdf',
                        showModal: false,
                        onLoadingEnd: () => this.setLoadingPrint(),
                    });
                }
            })
            .finally(() => {
                this.setLoadingPrint();
            });
    }

    formPackSelected() {
        const { formPackList } = this.state;
        return compact(map(formPackList, (item) => (item.defaultChecked ? item.id : null)));
    }

    subscriptionFormPacksChanged() {
        const { packSelected } = this.props;
        const input = {
            packId: packSelected.packId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, FormSubscription.FORM_ADDED_OR_REMOVE_TO_FORM_PACK, input)
            .then((response) => {
                this.formPacksChangedSubscription = response;
            });
    }

    subscriptionFormPacksListChange() {
        const { packSelected } = this.props;
        const input = {
            packId: packSelected.packId,
        };

        this.graphqlClient.subscribe(this.responseSubscriptionFormPacksList, FormSubscription.FORM_PACKS_LIST_CHANGE, input)
            .then((response) => {
                this.formPacksListChangeSubscription = response;
            });
    }

    responseSubscriptionFormPacksList(response) {
        const { data } = response;
        const { formPackList } = this.state;

        if (data.formPacksListChange) {
            const newFormPackList = formPackList.map((item) => ({ ...item, defaultChecked: data.formPacksListChange.includes(item.id) }));
            this.setState((prevState) => ({
                formPackList: update(prevState.formLibrary, {
                    $set: newFormPackList,
                }),
                formPackListBackUp: update(prevState.formPackListBackUp, {
                    $set: newFormPackList,
                }),
            }));
        }
    }

    responseSubscription(response) {
        const { data } = response;
        const { formLibrary, formPackList } = this.state;

        if (data?.formAddedOrRemoveToFormPack?.type === SubscriptionActionType.ADDED) {
            const { form } = data.formAddedOrRemoveToFormPack;
            const index = formLibrary.findIndex((item) => item.originalName === form.originalName);
            const newFormPackList = update(formPackList, { $push: [{ ...form, defaultChecked: false }] });

            if (index >= 0) {
                this.setState((prevState) => ({
                    formLibrary: update(prevState.formLibrary, { $splice: [[index, 1]] }),
                    formPackList: newFormPackList,
                    formPackListBackUp: newFormPackList,
                }));
            }
        }

        if (data?.formAddedOrRemoveToFormPack?.type === SubscriptionActionType.DELETED) {
            const { form } = data.formAddedOrRemoveToFormPack;
            const index = formPackList.findIndex((item) => item.originalName === form.originalName);

            if (index >= 0) {
                this.setState((prevState) => ({
                    formLibrary: orderBy(update(prevState.formLibrary, { $push: [form] }), [(item) => item?.commonName?.toLowerCase()], ['asc']),
                    formPackList: update(prevState.formPackList, { $splice: [[index, 1]] }),
                    formPackListBackUp: update(prevState.formPackListBackUp, { $splice: [[index, 1]] }),
                }));
            }
        }
    }

    unsubscribeFormPacksChanged() {
        if (this.formPacksChangedSubscription) {
            this.formPacksChangedSubscription.unsubscribe();
        }
    }

    unsubscribeFormPacksListChange() {
        if (this.formPacksListChangeSubscription) {
            this.formPacksListChangeSubscription.unsubscribe();
        }
    }

    initBind() {
        this.onPreview = this.onPreview.bind(this);
        this.onPrintList = this.onPrintList.bind(this);
        this.onSearchForm = this.onSearchForm.bind(this);
        this.setLoadingPrint = this.setLoadingPrint.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.formPackSelected = this.formPackSelected.bind(this);
        this.onCloseCustomField = this.onCloseCustomField.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.onChangeValueFormPack = this.onChangeValueFormPack.bind(this);
        this.onRemoveFormOfFormPack = this.onRemoveFormOfFormPack.bind(this);
        this.getCustomFieldInformation = this.getCustomFieldInformation.bind(this);
        this.subscriptionFormPacksChanged = this.subscriptionFormPacksChanged.bind(this);
        this.onSavePrintListCheckedDefault = this.onSavePrintListCheckedDefault.bind(this);
        this.subscriptionFormPacksListChange = this.subscriptionFormPacksListChange.bind(this);
        this.responseSubscriptionFormPacksList = this.responseSubscriptionFormPacksList.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onPreview={this.onPreview}
                onPrintList={this.onPrintList}
                onSearchForm={this.onSearchForm}
                formPackSelected={this.formPackSelected}
                onCloseCustomField={this.onCloseCustomField}
                onChangeValueFormPack={this.onChangeValueFormPack}
                onRemoveFormOfFormPack={this.onRemoveFormOfFormPack}
                getCustomFieldInformation={this.getCustomFieldInformation}
                onSavePrintListCheckedDefault={this.onSavePrintListCheckedDefault}
            />
        );
    }
};

export default FormDetailContainer;

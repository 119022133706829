import React, { useReducer, useEffect } from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, useTheme, useMediaQuery,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import CloseIcon from '@material-ui/icons/Close';
import { SaveIcon } from 'components/icons';
import { Row, Form, Col } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import TransferSchema from 'utils/schema/accounting/banking/TransferSchema';
import ModalUtils from 'utils/ModalUtils';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { AccountingCBStatus, AccountingSubModules, AccountingCOAType } from 'utils/enum/AccountingEnum';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import clsx from 'clsx';
import PaperComponent from 'components/modules/accounting/PaperComponent';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import DatePicker from 'react-datepicker';
import DateUtils from 'lib/DateUtils';
import { FetchPolicy } from 'utils/enum/Core';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
};

const TransferReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
            isNew: action.value.cbId === 0,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const keyStore = new KeyStore();

const TransferCreate = () => {
    const ACCOUNTING_BANKING_TRANSFER_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_TRANSFER_WRITE);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id } = useParams();
    const history = useHistory();

    const initialState = {
        record: {
            cbId: 0,
            fromBankAccountId: 0,
            toBankAccountId: 0,
            amount: 0,
            status: AccountingCBStatus.NEW,
            date: new Date(),
        },
        isDirty: false,
        isNew: true,
        couldLostData: false,
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(TransferReducer, initialState);
    const {
        record, isDirty, couldLostData,
    } = state;

    const isValidData = isValidSchema(TransferSchema, record);
    const { isValid, errors } = isValidData;

    const [getTransfer, { loading }] = useLazyQuery(AccountingQuery.GET_ACCOUNTING_TRANSFER_CB, {
        onCompleted: (res) => {
            if (res.getTransfer) {
                const transferRecord = res.getTransfer;

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...transferRecord,
                        date: new Date(DateUtils.getOnlyDate(transferRecord.date)),
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (id > 0) {
            getTransfer({
                variables: {
                    cbId: Number(id),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}`);
    };

    const [addRecord, { loading: isSaving }] = useMutation(AccountingMutation.CREATE_UPDATE_TRANSFER, {
        onCompleted: (mutationData) => {
            if (mutationData?.createUpdateTransfer) {
                ModalUtils.successMessage(null, 'Funds have been transferred successfully!');

                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        const currentRecord = { ...record, date: DateUtils.isValid(record.date) ? DateUtils.toLocal(record.date).toDate() : '' };
        delete currentRecord.status;

        await addRecord({
            variables: {
                record: currentRecord,
            },
        });
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record?.status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record?.status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
        else if (record?.status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase()) badgeStatus = classes.badgeCleared;
        else if (record?.status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()) badgeStatus = classes.badgePrinted;

        return (
            <>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </>
        );
    };

    const allowToSave = ACCOUNTING_BANKING_TRANSFER_WRITE && (record.status.toLowerCase() === AccountingCBStatus.POSTED.toLowerCase()
    || record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase());

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="xs"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
            PaperComponent={PaperComponent}
        >
            <AppBar className={classes.appBarMove}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        Transfer Founds Between Accounts
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                { loading && <LoadingMask />}
                {!loading && (
                    <Container className={clsx(classes.containerSplit, classes.paddingSides15)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">From Bank Account</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <DropdownQuery
                                            name="fromBankAccountId"
                                            className={isValidField(errors, 'fromBankAccountId') ? 'invalid-field' : ''}
                                            value={record.fromBankAccountId}
                                            placeHolder="select the account"
                                            onChange={(name, newValue) => onChangeValue(name, newValue)}
                                            dataSource={{
                                                query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                variables: {
                                                    paginate: {
                                                        init: 0,
                                                        ignoreLimit: true,
                                                    },
                                                    filters: {
                                                        accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                    },
                                                },
                                                rootData: 'getAccountingCOAList.data',
                                                idField: 'accountNumber',
                                                descriptionField: 'fullDescription',
                                            }}
                                            disabled={!allowToSave}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">To Bank Account</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <DropdownQuery
                                            name="toBankAccountId"
                                            className={isValidField(errors, 'toBankAccountId') ? 'invalid-field' : ''}
                                            value={record.toBankAccountId}
                                            placeHolder="select the account"
                                            onChange={(name, newValue) => onChangeValue(name, newValue)}
                                            dataSource={{
                                                query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                variables: {
                                                    paginate: {
                                                        init: 0,
                                                        ignoreLimit: true,
                                                    },
                                                    filters: {
                                                        accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                    },
                                                },
                                                rootData: 'getAccountingCOAList.data',
                                                idField: 'accountNumber',
                                                descriptionField: 'fullDescription',
                                            }}
                                            disabled={!allowToSave}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Amount</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <InputNumber
                                            size="sm"
                                            showCurrency
                                            value={record.amount}
                                            className={isValidField(errors, 'amount') ? 'invalid-field' : ''}
                                            thousandSeparator
                                            placeholder="Cash"
                                            onChange={(value) => onChangeValue('amount', value)}
                                            disabled={!allowToSave}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Date</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <DatePicker
                                            selected={record.date}
                                            disabled={!allowToSave}
                                            name="date"
                                            size="sm"
                                            wrapperClassName={classes.control100}
                                            className={clsx('form-control form-control-sm', isValidField(errors, 'date') ? 'invalid-field' : '')}
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeValue('date', date)}
                                        />
                                    </Col>
                                </Form.Group>
                            </Grid>
                        </Grid>
                        <ConfirmDialog
                            title="Attention - You have unsaved changes!"
                            description="Do you want to close without saving?"
                            open={couldLostData}
                            variant="outlined"
                            titlePrimary="Close"
                            titleSecondary="Cancel"
                            onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                            onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                            onClickPrimary={(e) => onPopupClose(e, true)}
                        />
                    </Container>
                )}
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            { getStatusChip() }
                        </div>
                        <div className={classes.buttonSpacing}>
                            {allowToSave && (
                                <Button
                                    className={(isValid && isDirty) ? classes.buttonSave : classes.buttonDisable}
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!(isValid && isDirty) || isSaving}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            )}
                        </div>
                    </Toolbar>
                </div>
            </DialogActions>
            {isSaving && <DialogActionMessage message="Saving transfer information... " />}
        </Dialog>
    );
};

export default TransferCreate;

import React from 'react';

import EmailIcon from 'components/icons/EmailIcon';
import PhoneIcon from 'components/icons/PhoneIcon';
import PersonIcon from 'components/icons/PersonIcon';

export const icons = {
    email: <EmailIcon width="16px" color="#e57373" />,
    walkin: <PersonIcon width="16px" color="#2196f3" />,
    phone: <PhoneIcon width="16px" color="#4caf50" />,
};

export default {
    getLeadType(type) {
        return (type || '').toLowerCase().replace('email', 'Email').replace('phone-up', 'Phone');
    },
    /**
     * @note Remove these methods once we get the data insert from the desktop and lead grabber fixed.
     * mapLeadTypeToSave, mapEmailLeadType
     * */
    mapLeadTypeToSave(type) {
        if (type.toLowerCase() === 'email') {
            return 'EMail';
        }

        return type;
    },

    mapEmailLeadType(type) {
        if (type.toLowerCase() === 'email') {
            return 'Email';
        }

        return type;
    },

    getLeadTypeIcon(type) {
        return {
            name: type.toLowerCase().replace('-', ''),
            icon: icons[type.toLowerCase().replace('-', '')],
        };
    },

    getVehiclePriceField(chosenVehiclePrice) {
        return ['stickerPrice', 'internetPrice'].find((item) => item.toUpperCase() === chosenVehiclePrice?.toUpperCase());
    },

    getLeadTypeOption(isFloorLog = false) {
        const options = [
            {
                label: 'Email',
                value: 'Email',
            },
            {
                label: 'Phone-Up',
                value: 'Phone-Up',
            },
        ];

        if (!isFloorLog) options.push({ label: 'Walk-In', value: 'Walk-In' });
        return options;
    },
};

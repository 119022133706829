import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { makeStyles } from '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import { Link as RouterLink } from 'react-router-dom';
import { NOTIFICATION_TYPE } from 'utils/enum/Core';

const useStyles = makeStyles((theme) => ({
    containerEmailNotification: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#fff',
    },
    emailNotificationHeader: {
        fontWeight: 'bold',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& svg g path': {
            fill: '#fff',
        },
    },
    emailNotificationBody: {
        fontSize: '13px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    icon: {
        fontSize: '19px',
    },
}));

const EmailNotification = ({ record }) => {
    const classes = useStyles();
    const { opportunity = {}, email } = record;
    const { leadCode, crmId, prospect = {} } = opportunity;
    const fullName = isEmpty(prospect) ? '' : `${prospect.firstName || ''} ${prospect.lastName || ''}:`;

    return (
        <RouterLink
            to={{
                pathname: `/${modules.OPPORTUNITIES}/${crmId}`,
                state: {
                    type: NOTIFICATION_TYPE.EMAIL_RECEIVED,
                    data: record.email,
                },
            }}
            className={classes.containerEmailNotification}
        >
            <span className={classes.emailNotificationHeader}>
                <MailOutline fontSize="small" className={classes.icon} />
                {`New Email from Opp Code: ${leadCode}`}
            </span>
            <span className={classes.emailNotificationBody}>{`${fullName} ${email.bodyPlain}`}</span>
        </RouterLink>
    );
};

EmailNotification.propTypes = {
    record: PropTypes.object.isRequired,
};

export default EmailNotification;

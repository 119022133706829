import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Dialog, DialogContent,
} from '@material-ui/core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import GpsHelper from 'utils/GpsHelper';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';

const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        borderBottom: 'initial',
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '5px 5px',
    },
    tableContainer: {
        marginTop: '15px',
        height: '65vh',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '700px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'center',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
}));

const GpsHistoryCommand = ({
    gpsCompany,
    lastExecutedCommand,
    toggleCommandHistoryDialog,
}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        records: [],
    });

    const {
        body,
    } = lastExecutedCommand;

    const {
        records,
    } = state;

    useEffect(() => {
        if (body) {
            const parsedData = JSON.parse(body ?? '{}');
            const { data, errors } = parsedData;

            if (ArrayUtils.isNotEmpty(errors)) {
                const { code, message } = errors[0];
                const errorMessage = GpsHelper.getErrorMessageByCode(code, message, gpsCompany);

                ModalUtils.errorMessage(null, errorMessage);
                toggleCommandHistoryDialog();
            } else {
                const { commandHistory } = data ?? {};
                if (!commandHistory) {
                    ModalUtils.errorMessage(null, 'No command history available');
                    toggleCommandHistoryDialog();

                    return;
                }

                setState((previousState) => ({
                    ...previousState,
                    records: commandHistory,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body]);

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Date',
            dataKey: 'date',
            width: 150,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        date,
                    },
                } = cell;

                return (<span>{DateUtils.getFormattedDateInUserTimezone(date, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Command',
            dataKey: 'command',
            width: 350,
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Status',
            dataKey: 'description',
            width: 150,
        },
    ];

    return (
        <Dialog
            open
            maxWidth="md"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Command History"
                onClose={toggleCommandHistoryDialog}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={false}
                            rowHeight={45}
                            totalRecords={records.length}
                            data={records}
                            columns={columns}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

GpsHistoryCommand.propTypes = {
    gpsCompany: PropTypes.string,
    lastExecutedCommand: PropTypes.object,
    toggleCommandHistoryDialog: PropTypes.func.isRequired,
};

GpsHistoryCommand.defaultProps = {
    gpsCompany: '',
    lastExecutedCommand: {},
};

export default GpsHistoryCommand;

/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button,
    Dialog, DialogContent,
} from '@material-ui/core';
import {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    PAGE_FORMAT,
    PAGE_ORIENTATION,
} from 'utils/enum/General';
import GeneralUtils from 'utils/GeneralUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery } from '@apollo/client';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import GeneralQuery from 'services/graphQL/query/GeneralQuery';

// icons
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '13px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '15px 0px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    tableContainer: {
        marginTop: '10px',
        paddingRight: '15px',
        height: '450px',
        minWidth: '1400px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'center',
            padding: '7px 5px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            '& > span': {
                fontSize: '11px',
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    exporting: {
        position: 'absolute',
        top: 0,
        left: 0,
        '& > button': {
            padding: 0,
            minWidth: '32px',
            border: 0,
            '& > span > span': {
                margin: 0,
                '& > svg': {
                    fontSize: '25px !important',
                },
            },
        },
    },
}));

const PivotTableDetails = ({
    data,
    filters,
    toggleDialog,
}) => {
    const classes = useStyles();
    const columnMinimumWidth = 150;
    const style = window.getComputedStyle(document.body);
    const fontFamily = style.getPropertyValue('font-family');

    const [printDocument, { loading: printingDocument }] = useLazyQuery(GeneralQuery.PRINT_DOCUMENT, {
        onCompleted: (response) => {
            const result = response.printDocument;
            if (result) {
                if (result?.data?.startsWith('Request failed')) {
                    ModalUtils.errorMessage(null, 'Error printing report');
                    return;
                }

                const {
                    data: documentOutput,
                } = result;

                const name = `Pivot Table Details - ${DateUtils.format(new Date(), DateFormat.SHORT_DATE_WITH_DASHES)}.xlsx`;
                GeneralUtils.downloadFile(documentOutput, name);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const exportData = (documentType) => {
        const title = 'Pivot Table Details';

        printDocument({
            variables: {
                title,
                source: {
                    content: JSON.stringify(
                        data
                            .map((r) => r.reduce((a, b) => { a[b.name] = (b.value == null || b.value === 'null' ? '' : b.value); return a; }, {})),
                    ),
                    type: PRINTING_DOCUMENT_SOURCE_TYPE.RECORDS,
                    options: {
                        pageFormat: PAGE_FORMAT.LETTER,
                        paseOrientation: PAGE_ORIENTATION.LANDSCAPE,
                        border: 15,
                    },
                },
                type: documentType,
            },
        });
    };

    const columns = data[0]?.map(({
        name,
    }) => {
        const calculatedWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(name, 'bold 12px ' + fontFamily) || 0);

        return {
            label: name,
            dataKey: name,
            headerClassName: classes.tableHeader,
            width: calculatedWidth > columnMinimumWidth ? calculatedWidth : columnMinimumWidth,
            filterEnabled: false,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                let value = record.find((r) => r.name === name)?.value;
                if (value == null || StringUtils.isEmpty(value) || value === 'null') value = '';

                return (
                    <span className={classes.ellipsis}>{value}</span>
                );
            },
        };
    }) ?? [];

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={`Details (${filters.map((f) => f.value).join(' | ')})`}
                onClose={() => toggleDialog()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.exporting}>
                        <Button
                            disabled={printingDocument}
                            variant="outlined"
                            startIcon={<CloudDownloadOutlinedIcon />}
                            size="small"
                            onClick={() => exportData(PRINTING_DOCUMENT_TYPE.SPREADSHEET)}
                        />
                    </div>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={false}
                            rowHeight={30}
                            totalRecords={data.length}
                            data={data}
                            columns={columns}
                            width={columns.reduce((a, b) => a + b.width, 0)}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

PivotTableDetails.defaultProps = {
    data: [],
    filters: [],
};

PivotTableDetails.propTypes = {
    data: PropTypes.array,
    filters: PropTypes.array,
    toggleDialog: PropTypes.func.isRequired,
};

export default PivotTableDetails;

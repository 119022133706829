/* eslint-disable no-undef */
import axios from 'axios';
import { v1 as uuid } from 'uuid';
import StringUtils from 'lib/StringUtils';
import { WATERMARK_SPOTS } from 'utils/enum/Core';

const MAX_SIZES = Object.freeze({
    DEFAULT: 1000,
    ICONS: 100,
});

const MAX_SIZES_WITH_ERROR = Object.freeze({
    IMAGES: {
        SIZE: 10485760, // 10MB
        ERROR: 'You can only upload images up to 10MB.',
    },
    PDF: {
        SIZE: 10485760, // 10MB
        ERROR: 'You can only upload PDFs up to 10MB.',
    },
});

export default class ImagesUtils {
    static getSecureImageURL(path, placeholder) {
        if (StringUtils.validateURL(path)) {
            if (StringUtils.isSecureURL(path)) {
                return path;
            }

            return path.replace('http', 'https');
        }

        return placeholder;
    }

    /**
    * @param maxSize type MAX_SIZES or an Int represented in KB
    * @param customText of type String, value of the custom text
    */
    static getSizes(maxSize = MAX_SIZES.DEFAULT, customText = null) {
        const defaultText = `Images under ${maxSize} KB`;
        const text = StringUtils.isEmpty(customText) ? defaultText : customText;

        return {
            maxSize: 1 * 1024 * maxSize,
            text,
        };
    }

    static isImage(extension) {
        if (StringUtils.isEmpty(extension)) return false;

        const exp = new RegExp(/\.(jpe?g|png)$/i);
        return exp.test(extension.toLowerCase());
    }

    static resizeImage = (
        file,
        maxSize = 1920,
        imageType = 'image/jpeg',
        imageQuality = 0.90,
    ) => new Promise((resolve, reject) => {
        try {
            if (!file.type.startsWith('image/')) {
                resolve(file);
                return;
            }

            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const image = new Image();
                image.onload = async () => {
                    const canvas = document.createElement('canvas');
                    let { width, height } = image;

                    if (width > height && width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    } else if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    canvas.toBlob((blob) => resolve(
                        new File([blob], `${uuid()}${imageType.replace('image/', '.')}`, { type: imageType }),
                    ), imageType, imageQuality);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        } catch (error) {
            reject(error);
        }
    });

    static calculateDrawingStartPointAndScaling = (
        spot,
        padding = 0,
        sourceWidth,
        sourceHeight,
        watermarkWidth,
        watermarkHeight,
    ) => {
        switch (spot) {
        case WATERMARK_SPOTS.TOP_LEFT:
            return { x: padding, y: padding };
        case WATERMARK_SPOTS.TOP_CENTER:
            return { x: (sourceWidth / 2) - (watermarkWidth / 2), y: padding };
        case WATERMARK_SPOTS.TOP_RIGHT:
            return { x: (sourceWidth - padding) - watermarkWidth, y: padding };
        case WATERMARK_SPOTS.CENTER:
            return { x: (sourceWidth / 2) - (watermarkWidth / 2), y: (sourceHeight / 2) - (watermarkHeight / 2) };
        case WATERMARK_SPOTS.CENTER_LEFT:
            return { x: padding, y: (sourceHeight / 2) - (watermarkHeight / 2) };
        case WATERMARK_SPOTS.CENTER_RIGHT:
            return { x: (sourceWidth - padding) - watermarkWidth, y: (sourceHeight / 2) - (watermarkHeight / 2) };
        case WATERMARK_SPOTS.BOTTOM_LEFT:
            return { x: padding, y: sourceHeight - padding - watermarkHeight };
        case WATERMARK_SPOTS.BOTTOM_CENTER:
            return { x: (sourceWidth / 2) - (watermarkWidth / 2), y: sourceHeight - padding - watermarkHeight };
        case WATERMARK_SPOTS.BOTTOM_RIGHT:
            return { x: (sourceWidth - padding) - watermarkWidth, y: sourceHeight - padding - watermarkHeight };
        case WATERMARK_SPOTS.OVERLAY:
        default:
            return {
                x: padding,
                y: padding,
                scaledWidth: sourceWidth,
                scaledHeight: watermarkHeight * (sourceWidth / watermarkWidth),
            };
        }
    };

    static loadImageUrlIntoFile = (url) => new Promise((resolve, reject) => {
        try {
            axios.get(`${url}?cacheblock=true`, { responseType: 'blob' })
                .then((response) => {
                    const split = url.split('.');
                    const ext = split[split.length - 1]?.toLowerCase();
                    resolve(new File([response.data], `${uuid()}.${ext}`, { type: `image/${ext}` }));
                }, (error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });

    static loadImageUrlIntoImage = (url) => new Promise((resolve, reject) => {
        try {
            axios.get(`${url}?cacheblock=true`, { responseType: 'blob' })
                .then((response) => {
                    const blobURL = URL.createObjectURL(response.data);

                    const img = new Image();
                    img.src = blobURL;
                    img.onload = async () => {
                        resolve(img);
                    };
                }, (error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });

    static addWatermarks = (
        file,
        watermaks = [], // [{ url, spot, padding }]
    ) => new Promise((resolve, reject) => {
        try {
            if (watermaks.length === 0) {
                resolve(file);
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (readerEvent) => {
                const sourceImage = new Image();
                sourceImage.src = readerEvent.target.result;
                sourceImage.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const { width, height } = sourceImage;

                    canvas.width = width;
                    canvas.height = height;
                    context.drawImage(sourceImage, 0, 0);

                    let pass = true;
                    const loadedWatermarks = [];

                    for (let i = 0; i < watermaks.length; i += 1) {
                        const { url, spot } = watermaks[i];
                        if (!Object.values(WATERMARK_SPOTS).includes(spot)) {
                            resolve(file);
                            pass = false;
                            break;
                        }

                        const promise = this.loadImageUrlIntoImage(url);
                        promise.catch(() => reject(new Error('Error loading the watermark selected')));
                        loadedWatermarks.push(promise);
                    }

                    if (pass) {
                        const images = await Promise.all(loadedWatermarks);
                        for (let i = 0; i < watermaks.length; i += 1) {
                            const { spot, padding } = watermaks[i];
                            const watermark = images[i];

                            if (watermark instanceof Image) {
                                const { width: watermarkWidth, height: watermarkHeight } = watermark;
                                const {
                                    x,
                                    y,
                                    scaledWidth = watermarkWidth,
                                    scaledHeight = watermarkHeight,
                                } = this.calculateDrawingStartPointAndScaling(
                                    spot,
                                    padding,
                                    width,
                                    height,
                                    watermarkWidth,
                                    watermarkHeight,
                                );

                                context.drawImage(watermark, x, y, scaledWidth, scaledHeight);
                            }
                        }

                        const { type } = file;
                        canvas.toBlob((blob) => resolve(
                            new File([blob], `${uuid()}${type.replace('image/', '.')}`, { type }),
                        ), type);
                    }
                };
            };
        } catch (error) {
            reject(error);
        }
    });

    static convertImageToBlobURL = (url, type) => new Promise((resolve, reject) => {
        axios.get(url, { responseType: 'blob' })
            .then((response) => {
                // eslint-disable-next-line no-undef
                const blob = new Blob([response.data], { type });
                resolve(URL.createObjectURL(blob));
            }, (error) => reject(error));
    });
}

ImagesUtils.MAX_SIZES = MAX_SIZES;
ImagesUtils.MAX_SIZES_WITH_ERROR = MAX_SIZES_WITH_ERROR;

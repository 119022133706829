import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import ModalUtils from 'utils/ModalUtils';
import DataType from 'utils/enum/DataType';

const useStyles = makeStyles({
    labelsGrid: {
        padding: '5px 10px',
    },
});

const SubscriptionPanel = ({ stockNumber }) => {
    const classes = useStyles();
    const { data, error } = useQuery(InventoryQuery.GET_VEHICLE_DEPRECIATION, {
        variables: { stockNumber },
    });
    const {
        beginningDepreciatedAmount = 0,
        depreciationPercent = 0,
        depreciationAmount = 0,
        accumulatedDepreciation = 0,
        depreciatedPurchasedPrice = 0,
    } = data?.getVehicleDepreciation || {};

    const { MONEY, PERCENTAGE } = DataType;
    const percent = depreciationPercent * 100;

    if (error) {
        ModalUtils.errorMessage(error?.graphQLErrors);
    }

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            <DisplayField
                maxWidth
                field="Beginning Depreciation"
                value={beginningDepreciatedAmount}
                type={MONEY}
            />
            <DisplayField
                maxWidth
                field="Depreciation Percent"
                value={percent}
                type={PERCENTAGE}
            />
            <DisplayField
                maxWidth
                field="Depreciation Amount"
                value={depreciationAmount}
                type={MONEY}
            />
            <DisplayField
                maxWidth
                field="Accumulated Depreciation"
                value={accumulatedDepreciation}
                type={MONEY}
            />
            <DisplayField
                maxWidth
                field="Depreciated Purchased Price"
                value={depreciatedPurchasedPrice}
                type={MONEY}
            />
        </Grid>
    );
};

SubscriptionPanel.propTypes = {
    stockNumber: PropTypes.number.isRequired,
};

export default SubscriptionPanel;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    loadedColumns: {
        '& > div': {
            width: '100%',
            display: 'flex',
            marginRight: '0px !important',
            alignItems: 'center',
            fontSize: '12px',
            position: 'relative',
            height: '25px !important',
            fontWeight: 'bold',
            cursor: 'move !important',
            '& > span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '80%',
            },
            '& > div': {
                position: 'absolute',
                top: '4px',
                right: '2px',
                '& > svg': {
                    width: '16px !important',
                    height: '16px !important',
                },
            },
        },
    },
}));

const LoadedColumnsList = ({
    columns,
    data,
}) => {
    const classes = useStyles();
    const styleDraggingComponent = (event, start) => {
        const { style } = event.target;

        if (start) {
            style.border = '1px dotted';
            style.opacity = 0.8;

            const columnIndex = event.target.getAttribute('data-column-index');
            const columnType = event.target.getAttribute('data-column-type');
            event.dataTransfer.setData('text', JSON.stringify({ index: columnIndex, type: columnType }));
        } else {
            style.border = 'initial';
            style.opacity = 1;
        }
    };

    return (
        <div className={clsx(classes.loadedColumns, 'columns-list-selector')}>
            {columns.map((col, index) => {
                const { type, icon } = BIHelper.getColumnDataType(col.name, data);

                return (
                    <div
                        draggable
                        data-column-index={index}
                        data-column-type={type}
                        onDragStart={(event) => styleDraggingComponent(event, true)}
                        onDragEnd={(event) => styleDraggingComponent(event, false)}
                        key={index}
                    >
                        <span>{col.name}</span>
                        <div>{icon}</div>
                    </div>
                );
            })}
        </div>
    );
};

LoadedColumnsList.defaultProps = {
    columns: [],
    data: [],
};

LoadedColumnsList.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
};

export default LoadedColumnsList;

/* eslint-disable react/prop-types */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { map, cloneDeep } from 'lodash';
import {
    makeStyles, fade,
    useMediaQuery, Checkbox,
    TextField, Button,
} from '@material-ui/core';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { Form, Row } from 'react-bootstrap';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/client';
import useReconActions from 'components/modules/inventory/hooks/useReconActions';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import { FetchPolicy } from 'utils/enum/Core';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import { useTheme } from '@material-ui/core/styles';
import ButtonStyles from 'styles/theme/Button';
import { ReconAssigneeType, ReconStatus } from 'utils/enum/InventoryEnum';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import If from 'components/widgets/conditional/If';
import AsyncSelect from 'react-select/async';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import Switch from '@material-ui/core/Switch';
import RepairItemPanel from 'components/modules/inventory/read/panels/RepairItemPanel';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';
import ReconActionsBar from 'components/modules/inventory/read/ReconActionsBar';

// Icons
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const INIT_STATE = {
    items: [],
    assignees: [],
    status: null,
    note: null,
    inspector: null,
    inspectors: [],
    isPicturesFormOpen: false,
    selectedItem: null,
    selectedItemImages: null,
    isUploadingImages: false,
    openImagePreview: false,
    photoIndex: -1,
    isReorderModeOn: false,
};

const ACTION_TYPES = {
    SET_ITEMS: 'setItems',
    SET_ASSIGNEES: 'setAssignees',
    INIT_RECON_STATUS: 'initReconStatus',
    TOGGLE_IS_PICTURES_FORM_OPEN: 'toggleIsPicturesFormOPen',
    TOGGLE_UPLOADING_IMAGES: 'toggleUploadingImages',
    TOGGLE_IMAGE_PREVIEW: 'toggleImagePreview',
    SET_PHOTO_INDEX: 'setPhotoIndex',
    SET_SELECTED_ITEM_IMAGES: 'setSelectedItemImages',
    TOGGLE_REORDER_MODE: 'toggleReorderMode',
    SET_RECON_STATUS: 'setReconStatus',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_ITEMS:
        return {
            ...state,
            items: action.value,
        };
    case ACTION_TYPES.SET_ASSIGNEES:
        return {
            ...state,
            assignees: action.value,
        };
    case ACTION_TYPES.INIT_RECON_STATUS:
        const {
            status,
            note,
            currentInspector,
            inspectors,
        } = action.value;

        return {
            ...state,
            status,
            note,
            inspector: currentInspector?.userID,
            inspectors,
        };
    case ACTION_TYPES.SET_RECON_STATUS:
        return {
            ...state,
            status: action.value.status,
            inspector: action.value.inspector,
        };
    case ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN:
        return {
            ...state,
            isPicturesFormOpen: !state.isPicturesFormOpen,
            selectedItem: action.value,
            selectedItemImages: (!action.value ? null : state.selectedItemImages),
        };
    case ACTION_TYPES.TOGGLE_UPLOADING_IMAGES:
        return {
            ...state,
            isUploadingImages: !state.isUploadingImages,
        };
    case ACTION_TYPES.TOGGLE_IMAGE_PREVIEW:
        return {
            ...state,
            openImagePreview: !state.openImagePreview,
            photoIndex: action.value,
        };
    case ACTION_TYPES.SET_PHOTO_INDEX:
        return {
            ...state,
            photoIndex: action.value,
        };
    case ACTION_TYPES.SET_SELECTED_ITEM_IMAGES:
        return {
            ...state,
            selectedItemImages: action.value,
        };
    case ACTION_TYPES.TOGGLE_REORDER_MODE:
        return {
            ...state,
            isReorderModeOn: !state.isReorderModeOn,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        minWidth: '1020px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '20px',
            minWidth: 'initial',
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '100px',
        height: '73vh',
        width: '100%',
        minWidth: '1300px',
        overflow: 'hidden',
        position: 'relative',
        '& .ReactVirtualized__Grid': {
            paddingBottom: '40px',
            '& > div': {
                overflow: 'initial !important',
            },
        },
        '& .ReactVirtualized__Table__row': {
            overflow: 'initial !important',
        },
        '& .ReactVirtualized__Table__headerRow': {
            border: `1px solid ${theme.palette.border.ghost}`,
            backgroundColor: `${theme.palette.background.white} !important`,
            color: `${theme.palette.text.waterloo} !important`,
            fontSize: '13px',
        },
        '& .ReactVirtualized__Table__rowColumn': {
            padding: '7px 0px',
            fontSize: '13px',
            color: theme.palette.text.outerSpace,
            justifyContent: 'left',
            display: 'flex',
            overflow: 'initial !important',
            '& > span': {
                fontSize: '13px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    actionButton: {
        fontSize: '12px',
        borderRadius: '0px',
        minWidth: '14px',
        color: 'white !important',
        '& > span:nth-child(1) > span': {
            marginRight: '0px',
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    buttonDisabled: {
        opacity: 0.8,
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        marginLeft: '0px',
        '& > *:last-child': {
            flex: 1,
        },
    },
    '@global': {
        '.css-26l3qy-menu': {
            minWidth: '200px',
        },
        '.css-26l3qy-menu div': {
            fontSize: '12px',
            lineHeight: '1.4',
        },
        '.css-26l3qy-menu > div': {
            maxHeight: '130px',
            overflowX: 'hidden',
        },
    },
    afterStartActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            backgroundColor: `${theme.palette.background.green} !important`,
        },
        '& > button:nth-child(2)': {
            backgroundColor: `${theme.palette.background.red} !important`,
            marginLeft: '5px',
        },
    },
    normalText: {
        fontSize: '12px !important',
    },
    reorderSwitcher: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        fontSize: '14px',
    },
    actionBarContainer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        '& > div': {
            minWidth: 'initial',
        },
    },
}));

const RepairsListTab = ({
    stockNumber,
    canWrite,
    saveRepairItem,
    canApprove,
    maximumReconCost,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const {
        moveType, addImages, deleteImages, setPhotoIndex,
    } = useImagesActions();
    const { changeReconStatus } = useReconActions();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const {
        items,
        assignees,
        status,
        note,
        inspector,
        inspectors,
        isPicturesFormOpen,
        selectedItem,
        selectedItemImages,
        isUploadingImages,
        openImagePreview,
        photoIndex,
        isReorderModeOn,
    } = state;

    const readyToWork = (status === ReconStatus.INSPECTION_APPROVED)
            || (status === ReconStatus.REPAIRS_COMPLETE);
    const {
        data: statusData,
        loading: loadingStatus,
        error: errorStatus,
    } = useQuery(InventoryQuery.GET_RECON_STATUS, {
        variables: {
            stockNumber,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: repairsListItemsData,
        loading: loadingRepairsListItems,
        error: errorRepairsListItems,
        refetch,
    } = useQuery(InventoryQuery.GET_REPAIR_ITEMS, {
        variables: {
            stockNumber,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: assigneesData,
        loading: loadingAssignees,
        error: errorAssignees,
    } = useQuery(InventoryQuery.GET_ASSIGNEES_FOR_REPAIR, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getImages] = useLazyQuery(InventoryQuery.GET_VEHICLE_RECON_ITEM_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.getVehicleReconItemImages;
                dispatch({
                    type: ACTION_TYPES.SET_SELECTED_ITEM_IMAGES,
                    value: images,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorStatus) {
            ModalUtils.errorMessage(errorStatus?.graphQLErrors);
        }

        if (!loadingStatus) {
            dispatch({
                type: ACTION_TYPES.INIT_RECON_STATUS,
                value: statusData?.getReconStatus,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus, errorStatus]);

    useEffect(() => {
        if (errorAssignees) {
            ModalUtils.errorMessage(errorAssignees?.graphQLErrors);
        }

        if (!loadingAssignees) {
            dispatch({
                type: ACTION_TYPES.SET_ASSIGNEES,
                value: assigneesData?.getAssigneesForRepair,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAssignees, errorAssignees]);

    useEffect(() => {
        if (errorRepairsListItems) {
            ModalUtils.errorMessage(errorRepairsListItems?.graphQLErrors);
        }

        if (!loadingRepairsListItems) {
            dispatch({
                type: ACTION_TYPES.SET_ITEMS,
                value: repairsListItemsData?.getRepairItems,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRepairsListItems, errorRepairsListItems]);

    const onChange = (vehicleRepairItemId, field, value) => {
        const clone = cloneDeep(items);
        const item = clone.find((el) => el.vehicleRepairItemId === vehicleRepairItemId);
        if (!item) return;

        switch (field) {
        case 'assignee':
            const { id, type } = value;
            if (type === ReconAssigneeType.INDIVIDUAL) item.assigneeId = id;
            if (type === ReconAssigneeType.VENDOR) item.vendorId = id;

            saveRepairItem({
                vehicleRepairItemId,
                vehicleInspectionItemId: item.inspectionItem.vehicleInspectionItemId,
                order: item.order,
                assigneeId: item.assigneeId,
                vendorId: item.vendorId,
                startOn: item.startOn,
                completeOn: item.completeOn,
                comment: item.inspectionItem.comment,
                active: true,
            });
            break;
        case 'comment':
            item.inspectionItem.comment = value;
            break;
        case 'estimation':
            item.inspectionItem.estimation = value;
            break;
        default:
            break;
        }

        dispatch({
            type: ACTION_TYPES.SET_ITEMS,
            value: clone,
        });
    };

    const saveEstimate = async (vehicleRepairItemId, value) => {
        if (status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || !canWrite) return;
        const item = items.find((el) => el.vehicleRepairItemId === vehicleRepairItemId);
        if (!item) return;

        try {
            const inspectedItem = item.inspectionItem;
            await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_RECON_ITEMS,
                variables: {
                    stockNumber,
                    items: [
                        {
                            vehicleInspectionItemId: inspectedItem.vehicleInspectionItemId,
                            reconInspectionItemId: inspectedItem.reconItem.reconInspectionItemId,
                            reconInspectionItemName: inspectedItem.reconItem.name,
                            pass: inspectedItem.pass,
                            fail: inspectedItem.fail,
                            part: inspectedItem.part,
                            repair: inspectedItem.repair,
                            estimation: value,
                            comment: inspectedItem.comment,
                        },
                    ],
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            onChange(vehicleRepairItemId, 'estimation', value);
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const saveComment = (vehicleRepairItemId) => {
        const item = items.find((el) => el.vehicleRepairItemId === vehicleRepairItemId);
        if (!item) return;

        saveRepairItem({
            vehicleRepairItemId,
            vehicleInspectionItemId: item.inspectionItem.vehicleInspectionItemId,
            order: item.order,
            assigneeId: item.assigneeId,
            vendorId: item.vendorId,
            startOn: item.startOn,
            completeOn: item.completeOn,
            comment: item.inspectionItem.comment,
            active: true,
        });
    };

    const onAddImages = async (files) => {
        const { current } = selectedItem;
        const dispatcherPayload = {
            type: ACTION_TYPES.TOGGLE_UPLOADING_IMAGES,
        };

        addImages(
            files,
            dispatch,
            dispatcherPayload,
            null,
            'vehicleInspectionItemId',
            current.vehicleInspectionItemId,
            stockNumber,
            InventoryMutation.UPLOAD_VEHICLE_RECON_ITEM_IMAGE,
            'uploadVehicleReconItemImage',
            InventoryMutation.SAVE_VEHICLE_RECON_ITEM_IMAGES,
            getImages,
        );
    };

    const togglePicturesForm = async (item) => {
        if (!item) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
                value: null,
            });

            refetch();
            return;
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
            value: item,
        });

        getImages({
            variables: {
                vehicleInspectionItemId: item.current.vehicleInspectionItemId,
            },
        });
    };

    const onOpenImage = (index) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
            value: index,
        });
    };

    const toggleImagePreview = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
        });
    };

    const onSetPhotoIndex = (type) => setPhotoIndex(
        type,
        photoIndex,
        selectedItemImages,
        dispatch,
        ACTION_TYPES.SET_PHOTO_INDEX,
    );

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const { current } = selectedItem;
        const payload = {
            variables: {
                vehicleInspectionItemId: current.vehicleInspectionItemId,
            },
        };

        deleteImages(
            selectedItemImages,
            'vehicleInspectionItemImageId',
            InventoryMutation.REMOVE_VEHICLE_RECON_ITEM_IMAGES,
            selectedImages,
            toggleImagesDeletionPrompt,
            getImages,
            payload,
        );
    };

    const sortRow = async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
            try {
                let itemsSorted = arrayMove(
                    items,
                    oldIndex,
                    newIndex,
                );

                const repairItemstoUpdate = [];
                itemsSorted = itemsSorted.map((item, index) => {
                    const order = index + 1;

                    repairItemstoUpdate.push({
                        vehicleRepairItemId: item.vehicleRepairItemId,
                        order,
                    });

                    return {
                        ...item,
                        order,
                    };
                });

                client.mutate({
                    mutation: InventoryMutation.SAVE_VEHICLE_REPAIR_ITEMS_ORDER,
                    variables: {
                        input: repairItemstoUpdate,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                });

                dispatch({
                    type: ACTION_TYPES.SET_ITEMS,
                    value: itemsSorted,
                });
            } catch (error) {
                ModalUtils.errorMessage(null, error);
            }
        }
    };

    const totalEstimate = (items || []).filter((el) => el.inspectionItem?.repair).reduce((a, b) => a + (b.inspectionItem?.estimation || 0), 0);
    const onChangeReconStatus = async (nextStatus, ins = null, inputNote, isApproving = false, isReapprovalRequest = false) => {
        const data = await changeReconStatus(
            stockNumber,
            nextStatus,
            ins,
            inputNote,
            isApproving,
            totalEstimate,
            maximumReconCost,
            isReapprovalRequest,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_RECON_STATUS,
                value: data,
            });
        }
    };

    const startRepairItem = (item) => {
        const clone = cloneDeep(items);
        const utcDateTime = new Date().toISOString();

        const element = clone.find((el) => el.vehicleRepairItemId === item.vehicleRepairItemId);
        element.startOn = utcDateTime;
        dispatch({
            type: ACTION_TYPES.SET_ITEMS,
            value: clone,
        });

        saveRepairItem({
            vehicleRepairItemId: item.vehicleRepairItemId,
            vehicleInspectionItemId: item.inspectionItem.vehicleInspectionItemId,
            order: item.order,
            assigneeId: item.assigneeId,
            vendorId: item.vendorId,
            startOn: utcDateTime,
            completeOn: item.completeOn,
            comment: item.inspectionItem.comment,
            active: true,
        });
    };

    const completeRepairItem = (item) => {
        if (!item.startOn) return;
        const clone = cloneDeep(items);
        const utcDateTime = new Date().toISOString();

        const element = clone.find((el) => el.vehicleRepairItemId === item.vehicleRepairItemId);
        element.completeOn = utcDateTime;
        dispatch({
            type: ACTION_TYPES.SET_ITEMS,
            value: clone,
        });

        saveRepairItem({
            vehicleRepairItemId: item.vehicleRepairItemId,
            vehicleInspectionItemId: item.inspectionItem.vehicleInspectionItemId,
            order: item.order,
            assigneeId: item.assigneeId,
            vendorId: item.vendorId,
            startOn: item.startOn,
            completeOn: utcDateTime,
            comment: item.inspectionItem.comment,
            active: true,
        });

        const completeItems = clone.filter((el) => el.completeOn !== null);
        if (completeItems.length === clone.length) {
            onChangeReconStatus(ReconStatus.REPAIRS_COMPLETE, inspector);
        }
    };

    const cancelRepairItem = (item) => {
        if (!item.startOn) return;
        const clone = cloneDeep(items);
        const element = clone.find((el) => el.vehicleRepairItemId === item.vehicleRepairItemId);
        element.startOn = null;
        element.completeOn = null;
        dispatch({
            type: ACTION_TYPES.SET_ITEMS,
            value: clone,
        });

        saveRepairItem({
            vehicleRepairItemId: item.vehicleRepairItemId,
            vehicleInspectionItemId: item.inspectionItem.vehicleInspectionItemId,
            order: item.order,
            assigneeId: item.assigneeId,
            vendorId: item.vendorId,
            startOn: null,
            completeOn: item.completeOn,
            comment: item.inspectionItem.comment,
            active: true,
        });
    };

    const toggleReorderMode = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_REORDER_MODE,
        });
    };

    const maximumVendorItemsShown = 20;
    const assigneeList = map(assignees, (item) => ({
        value: item,
        label: item.name,
    }));

    const getAsyncOptionsFiltered = (inputValue) => new Promise((resolve) => {
        const filtered = assigneeList
            .filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));

        resolve(filtered.slice(0, maximumVendorItemsShown));
    });

    const AssigneeSelector = React.memo(({
        loading,
        assignee,
        list,
        repairItemId,
    }) => (
        <Form.Group as={Row} className={classes.group}>
            <AsyncSelect
                className={clsx('select-bootstrap select-sm')}
                isLoading={loading}
                value={assignee}
                defaultOptions={list.slice(0, maximumVendorItemsShown)}
                loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                onChange={(option) => onChange(repairItemId, 'assignee', option.value)}
            />
        </Form.Group>
    ), (prevProps, nextProps) => prevProps.loading === nextProps.loading
    && prevProps.assignee === nextProps.assignee
    && prevProps.list === nextProps.list
    && prevProps.repairItemId === nextProps.repairItemId);

    const PartSelector = React.memo(({
        part,
    }) => (
        <Checkbox
            checked={part}
        />
    ), (prevProps, nextProps) => prevProps.part === nextProps.part);

    const EstimateField = React.memo(({
        ready,
        estimation,
        repairItemId,
    }) => (
        <InputNumber
            disabled={ready}
            value={estimation}
            onBlur={(e) => saveEstimate(repairItemId, Number(String(e.target.value ?? '').replace('$', '')))}
            placeholder="$0"
            showCurrency
            min={0}
            decimalScale={0}
            thousandSeparator
            size="sm"
        />
    ), (prevProps, nextProps) => prevProps.ready === nextProps.ready
    && prevProps.estimation === nextProps.estimation && prevProps.repairItemId === nextProps.repairItemId);

    const getColumns = () => [
        {
            label: '',
            dataKey: 'number',
            width: 80,
            cellRenderer: (cell) => {
                const { rowIndex } = cell;

                return (
                    <span>{rowIndex + 1}</span>
                );
            },
        },
        {
            label: 'Repair Item',
            dataKey: 'repairItem',
            width: 250,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        inspectionItem: {
                            reconItem: {
                                name,
                            },
                        },
                    },
                } = cell;

                return (
                    <span>{name}</span>
                );
            },
        },
        {
            label: 'Assignee',
            dataKey: 'assignee',
            width: 300,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        vehicleRepairItemId,
                        assigneeId,
                        vendorId,
                    },
                } = cell;
                if (!canWrite) return null;

                const selectedAssignee = assigneeList
                    .find((option) => {
                        const assignee = assigneeId
                            && option.value.id === assigneeId
                            && option.value.type === ReconAssigneeType.INDIVIDUAL;

                        const vendor = vendorId
                            && option.value.id === vendorId
                            && option.value.type === ReconAssigneeType.VENDOR;

                        return assignee || vendor;
                    });

                return (
                    <AssigneeSelector
                        loading={loadingAssignees}
                        assignee={selectedAssignee}
                        list={assigneeList}
                        repairItemId={vehicleRepairItemId}
                    />
                );
            },
        },
        {
            label: 'Part',
            dataKey: 'part',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        inspectionItem: {
                            part,
                        },
                    },
                } = cell;

                return (
                    <PartSelector
                        part={part}
                    />
                );
            },
        },
        {
            label: 'Part Status',
            dataKey: 'partStatus',
            width: 180,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        partStatus,
                    },
                } = cell;

                return (
                    <span>{partStatus}</span>
                );
            },
        },
        {
            label: 'Comments',
            dataKey: 'comments',
            width: 350,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        vehicleRepairItemId,
                        inspectionItem: {
                            comment,
                        },
                    },
                } = cell;
                if (!canWrite) return null;

                return (
                    <TextField
                        disabled={!readyToWork}
                        className={classes.textFieldSmall}
                        value={comment || ''}
                        name="comment"
                        onChange={({ target: { name, value } }) => onChange(vehicleRepairItemId, name, value)}
                        onBlur={() => saveComment(vehicleRepairItemId)}
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },
        {
            label: 'Estimate',
            dataKey: 'estimate',
            width: 200,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        vehicleRepairItemId,
                        inspectionItem: {
                            estimation,
                        },
                    },
                } = cell;
                if (!canWrite) return null;

                return (
                    <EstimateField
                        ready={readyToWork}
                        estimation={estimation}
                        repairItemId={vehicleRepairItemId}
                    />
                );
            },
        },
        {
            label: 'Pictures',
            dataKey: 'pictures',
            width: 150,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        inspectionItem: {
                            images,
                            vehicleInspectionItemId,
                        },
                    },
                } = cell;
                if (!canWrite) return null;

                return (
                    <Button
                        size="small"
                        className={clsx(
                            classes.containedSecondaryInfo,
                            classes.uploadButton,
                            (images || 0) > 0 ? classes.greenUploadButton : '',
                        )}
                        onClick={() => togglePicturesForm({ current: { vehicleInspectionItemId } })}
                    >
                        {`View (${images || 0})`}
                    </Button>
                );
            },
        },
        {
            label: 'Start On',
            dataKey: 'startOn',
            width: 180,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        startOn,
                    },
                } = cell;

                return (
                    <span className={classes.normalText}>{DateUtils.getFormattedDateInUserTimezone(startOn)}</span>
                );
            },
        },
        {
            label: 'Complete On',
            dataKey: 'completeOn',
            width: 180,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        completeOn,
                    },
                } = cell;

                return (
                    <span className={classes.normalText}>{DateUtils.getFormattedDateInUserTimezone(completeOn)}</span>
                );
            },
        },
        {
            label: 'Action',
            dataKey: 'action',
            width: 120,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        startOn,
                        completeOn,
                    },
                    rowData: record,
                } = cell;
                if (!canWrite) return null;

                if (!startOn) {
                    return (
                        <Button
                            disabled={!readyToWork}
                            size="small"
                            className={clsx(
                                classes.containedSecondaryInfo,
                                classes.actionButton,
                                classes.greenUploadButton,
                                !readyToWork ? classes.buttonDisabled : '',
                            )}
                            startIcon={<PlayArrowOutlinedIcon />}
                            onClick={() => startRepairItem(record)}
                        />
                    );
                }

                return (
                    <div className={classes.afterStartActions}>
                        <If condition={!completeOn}>
                            <Button
                                disabled={!readyToWork || completeOn}
                                size="small"
                                className={clsx(
                                    classes.containedSecondaryInfo,
                                    classes.actionButton,
                                    !readyToWork ? classes.buttonDisabled : '',
                                )}
                                startIcon={<DoneAllOutlinedIcon />}
                                onClick={() => completeRepairItem(record)}
                            />
                            <Button
                                disabled={!readyToWork || completeOn}
                                size="small"
                                className={clsx(
                                    classes.containedSecondaryInfo,
                                    classes.actionButton,
                                    !readyToWork ? classes.buttonDisabled : '',
                                )}
                                startIcon={<HighlightOffOutlinedIcon />}
                                onClick={() => cancelRepairItem(record)}
                            />
                        </If>
                    </div>
                );
            },
        },
    ];

    const getChild = (item, index) => (
        <RepairItemPanel
            index={index}
            key={`item-${index}`}
            data={item}
            canWrite={canWrite}
            onChange={onChange}
            togglePicturesForm={togglePicturesForm}
            loadingAssignees={loadingAssignees}
            maximumVendorItemsShown={maximumVendorItemsShown}
            assigneeList={assigneeList}
            getAsyncOptionsFiltered={getAsyncOptionsFiltered}
            saveComment={saveComment}
            startRepairItem={startRepairItem}
            completeRepairItem={completeRepairItem}
            cancelRepairItem={cancelRepairItem}
            isReorderModeOn={isReorderModeOn}
            isApproved={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE}
        />
    );

    const distanceInPixels = 5;
    const SortableItem = SortableElement(({ value, sortIndex }) => getChild(value, sortIndex));
    const SortableList = SortableContainer(({ elements }) => (
        <div>
            {
                elements.map((element, index) => (
                    <SortableItem
                        key={`element-${index}`}
                        index={index}
                        sortIndex={index}
                        value={element}
                    />
                ))
            }
        </div>
    ));

    const defaultInspector = inspector
        || (!canApprove ? inspectors.find((el) => el.default)?.userID : null);
    const inspectorOptions = inspectors
        .map((item) => ({
            value: item.userID,
            label: `${item.firstName} ${item.lastName}`,
        }));

    return (
        <div className={classes.main}>
            <If condition={isMobile}>
                <div className={classes.reorderSwitcher}>
                    Reorder
                    <Switch
                        checked={isReorderModeOn}
                        onChange={toggleReorderMode}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <If condition={isReorderModeOn}>
                    <SortableList
                        axis="y"
                        distance={distanceInPixels}
                        elements={items}
                        onSortEnd={sortRow}
                    />
                </If>
                <If condition={!isReorderModeOn}>
                    {items.map((item, index) => getChild(item, index))}
                </If>
            </If>
            <If condition={!isMobile}>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        areRowsDraggable
                        sortRow={sortRow}
                        loading={loadingRepairsListItems}
                        rowHeight={35}
                        totalRecords={items.length}
                        data={items}
                        columns={getColumns()}
                    />
                    {status && (
                        <div className={classes.actionBarContainer}>
                            <ReconActionsBar
                                canApprove={canApprove}
                                status={status}
                                note={note}
                                inspector={inspector}
                                defaultInspector={defaultInspector}
                                inspectorOptions={inspectorOptions}
                                totalEstimate={totalEstimate}
                                maximumReconCost={maximumReconCost}
                                onChangeReconStatus={onChangeReconStatus}
                            />
                        </div>
                    )}
                </div>
            </If>
            <InspectionImagesDialog
                isPicturesFormOpen={isPicturesFormOpen}
                isUploadingImages={isUploadingImages}
                openImagePreview={openImagePreview}
                addImages={onAddImages}
                canWrite={canWrite}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={selectedItemImages}
                onOpenImage={onOpenImage}
                photoIndex={photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={false}
            />
            {isMobile && status && (
                <div className={classes.actionBarContainer}>
                    <ReconActionsBar
                        canApprove={canApprove}
                        status={status}
                        note={note}
                        inspector={inspector}
                        defaultInspector={defaultInspector}
                        inspectorOptions={inspectorOptions}
                        totalEstimate={totalEstimate}
                        maximumReconCost={maximumReconCost}
                        onChangeReconStatus={onChangeReconStatus}
                    />
                </div>
            )}
        </div>
    );
};

RepairsListTab.propTypes = {
    stockNumber: PropTypes.number,
    canWrite: PropTypes.bool.isRequired,
    saveRepairItem: PropTypes.func.isRequired,
    canApprove: PropTypes.bool.isRequired,
    maximumReconCost: PropTypes.number,
};

RepairsListTab.defaultProps = {
    stockNumber: 0,
    maximumReconCost: 0,
};

export default RepairsListTab;

/* eslint-disable react/no-danger */
import React, { useReducer, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import { useMutation, useApolloClient } from '@apollo/client';
import If from 'components/widgets/conditional/If';
import ButtonStyles from 'styles/theme/Button';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import PDFUtils from 'utils/PDFUtils';
import BulletinBoardStyles from 'styles/modules/home/BulletinBoardStyles';
import {
    makeStyles,
    Button,
    DialogContent,
    Dialog,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MessageIcon from '@material-ui/icons/Message';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import MessageForm from 'components/modules/home/create/MessageForm';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import BulletingBoardMutation from 'services/graphQL/mutate/home/BulletingBoardMutation';
import MesssagesReducer, { ACTION_TYPES } from 'components/modules/home/reducer/MesssagesReducer';
import { ComponentType, MessageContentType } from 'utils/enum/BulletinBoardEnum';
import BulletingBoardQuery from 'services/graphQL/query/home/BulletingBoardQuery';

const useStyle = makeStyles((theme) => BulletinBoardStyles.board(theme));
const messagesStyle = makeStyles((theme) => BulletinBoardStyles.messages(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const Messages = ({
    component,
    actionRemove,
    actionChangeTitle,
    permission,
}) => {
    const classes = { ...useStyle(), ...buttonStyles() };
    const messagesClasses = messagesStyle();
    const client = useApolloClient();

    const INITIAL_STATE = {
        pinnedElements: component.elements.filter((element) => element.isPinned && element.type === ComponentType.MESSAGE),
        nonPinnedElements: component.elements.filter((element) => !element.isPinned && element.type === ComponentType.MESSAGE),
        groupTitle: component.groupTitle === null ? '' : component.groupTitle,
        isMessageFormOpen: false,
        selectedContentType: null,
        isMessagePinned: false,
        content: null,
        selectedMessageId: null,
        confirmDialog: {
            isOpen: false,
            options: {},
        },
        isPDFPreviewDialogOpen: false,
        PDFPreviewContent: null,
        loadedPDFs: [],
        isFileBeingUploaded: false,
        isMessageBeingSaved: false,
    };

    const [state, dispatch] = useReducer(MesssagesReducer, INITIAL_STATE);
    const {
        groupTitle,
        isMessageFormOpen,
        selectedContentType,
        isMessagePinned,
        content,
        pinnedElements,
        nonPinnedElements,
        confirmDialog,
        selectedMessageId,
        isPDFPreviewDialogOpen,
        PDFPreviewContent,
        loadedPDFs,
        isFileBeingUploaded,
        isMessageBeingSaved,
    } = state;

    const renewSecurePDFURL = async (path) => {
        const { data: secureURLData } = await client.query({
            query: BulletingBoardQuery.GET_SECURE_URL_PDF,
            variables: { path },
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

        const secureURL = secureURLData?.getSecureURLPDF;
        return secureURL;
    };

    const isPDFHostedOnAWS = (url) => {
        if (StringUtils.isEmpty(url)) return false;

        return url.includes('amazonaws.com')
        && url.includes('bulletin-board')
        && url.includes('.pdf');
    };

    useEffect(() => {
        const loadBase64Data = async () => {
            const hostedElementsOnAWS = [...pinnedElements, ...nonPinnedElements]
                .filter((element) => isPDFHostedOnAWS(element.value));
            const PDFSources = [];

            await Promise.all(hostedElementsOnAWS.map(async (element) => {
                const { id, value } = element;
                const { pathname } = new URL(value);
                const path = pathname.substring(pathname.indexOf('bulletin-board'));

                const secureURL = await renewSecurePDFURL(path);
                const pdf = await PDFUtils.convertPDFToBlobURL(secureURL);
                PDFSources.push({ id, pdf });
            }));

            dispatch({
                type: ACTION_TYPES.SET_LOADED_PDFS,
                value: PDFSources,
            });
        };

        loadBase64Data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [saveMessageComponent] = useMutation(BulletingBoardMutation.SAVE_MESSAGE_COMPONENT, {
        onCompleted: async (response) => {
            if (response) {
                let pdf = null;
                const messageId = response.saveMessageComponent;
                const hostedOnAWS = isPDFHostedOnAWS(content);

                if (hostedOnAWS) {
                    const { pathname } = new URL(content);
                    const path = pathname.substring(pathname.indexOf('bulletin-board'));

                    const secureURL = await renewSecurePDFURL(path);
                    pdf = await PDFUtils.convertPDFToBlobURL(secureURL);
                }

                dispatch({
                    type: ACTION_TYPES.SAVE_MESSAGE,
                    value: {
                        id: messageId,
                        title: null,
                        value: content,
                        type: ComponentType.MESSAGE,
                        isPinned: isMessagePinned,
                        pdf,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);

            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_MESSAGE_BEING_SAVED,
            });
        },
    });

    const [removeMessageComponent] = useMutation(BulletingBoardMutation.REMOVE_MESSAGE_COMPONENT, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.REMOVE_MESSAGE,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const saveMessage = () => {
        const { bulletinBoardComponentId } = component;
        const message = {
            id: selectedMessageId,
            title: null,
            value: content,
            type: ComponentType.MESSAGE,
            isPinned: isMessagePinned,
        };

        if (
            (selectedContentType === MessageContentType.PDF
            && !StringUtils.validateURL(content))
            || (selectedContentType === MessageContentType.PDF
            && !content.toLowerCase().includes('.pdf'))
        ) {
            ModalUtils.errorMessage(null, 'Check the URL of your PDF.');
            return;
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_MESSAGE_BEING_SAVED,
        });
        saveMessageComponent({ variables: { bulletinBoardComponentId, message } });
    };

    const onPDFUpload = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const extension = name.substr(name.lastIndexOf('.') + 1)?.toLowerCase();
        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 5242880; // 5MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 5MB.');
            return;
        }

        if (extension !== 'pdf') {
            ModalUtils.errorMessage(null, 'Only PDF files can be uploaded.');
            return;
        }

        try {
            dispatch({
                type: ACTION_TYPES.SET_PDF_UPLOADING,
            });

            const { data: signedURLData } = await client.query({
                query: BulletingBoardQuery.GET_SIGNED_URL_UPLOAD_PDF,
                fetchPolicy: FetchPolicy.NETWORK_ONLY,
            });

            const { url, path } = signedURLData?.getSignedURLUploadPDF;
            if (url) {
                await axios.put(url, file);
                const secureURL = await renewSecurePDFURL(path);
                if (secureURL) {
                    dispatch({
                        type: ACTION_TYPES.SET_CONTENT,
                        value: secureURL,
                    });
                }
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const onAddMessageFormChange = ({ target: { name, value } }) => {
        switch (name) {
        case 'content-type':
            dispatch({
                type: ACTION_TYPES.SET_CONTENT_TYPE,
                value,
            });

            break;
        case 'pin':
            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_PINNED,
                value,
            });

            break;
        case 'content':
            dispatch({
                type: ACTION_TYPES.SET_CONTENT,
                value,
            });

            break;
        default:
            break;
        }
    };

    const onGroupTitleChange = ({ target: { value } }) => {
        dispatch({
            type: ACTION_TYPES.SET_GROUP_TITLE,
            value,
        });
    };

    const openMessageForm = () => {
        dispatch({
            type: ACTION_TYPES.OPEN_MESSAGE_FORM,
        });
    };

    const closeMessageForm = () => {
        dispatch({
            type: ACTION_TYPES.CLOSE_MESSAGE_FORM,
        });
    };

    const editMessage = (element) => {
        let contentType = null;
        const { value } = element;
        const isPDF = value?.toLowerCase().includes('.pdf') || false;
        const isHTML = StringUtils.isHTML(value);

        if (isPDF) {
            contentType = MessageContentType.PDF;
        } else if (isHTML) {
            contentType = MessageContentType.HTML;
        } else {
            contentType = MessageContentType.PLAIN_TEXT;
        }

        dispatch({
            type: ACTION_TYPES.EDIT_MESSAGE,
            value: {
                element,
                contentType,
            },
        });
    };

    const openConfirmDialog = (messageId) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_CONFIRM_DIALOG_OPEN,
            value: messageId,
        });
    };

    const removeMessage = () => {
        removeMessageComponent({ variables: { messageId: confirmDialog.options.messageId } });
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_CONFIRM_DIALOG_OPEN,
        });
    };

    const openPDFPreviewDialog = (element) => {
        const { id, value } = element;
        let pdf = value;

        const source = loadedPDFs.find((item) => item.id === id);
        if (source) pdf = source.pdf;

        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_PDF_PREVIEW_OPEN,
            value: pdf,
        });
    };

    const closePDFPreviewDialog = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_PDF_PREVIEW_OPEN,
        });
    };

    const renderIframe = (url = '') => (
        <iframe
            className={messagesClasses.iframe}
            title={groupTitle}
            src={url}
            frameBorder="0"
            allowFullScreen
        />
    );

    const renderElementsInComponent = (elements) => elements.map((element) => {
        const {
            value,
            modifiedOn,
            isPinned,
            id,
        } = element;
        const modifiedDate = DateUtils.format(modifiedOn);

        const isPDF = value?.toLowerCase().includes('.pdf') || false;
        const additionalItems = () => (
            <>
                <div className={messagesClasses.lastModification}>
                    {modifiedDate}
                </div>
                <If condition={isPinned}>
                    <div className={messagesClasses.pinned}>
                        <BeenhereIcon />
                    </div>
                </If>
                <If condition={isPDF}>
                    <div className={messagesClasses.pdfPreview}>
                        <IconButton
                            onClick={() => openPDFPreviewDialog(element)}
                        >
                            <LaunchIcon />
                        </IconButton>
                    </div>
                </If>
                <If condition={permission}>
                    <div className={messagesClasses.elementActions}>
                        <IconButton
                            onClick={() => editMessage(element)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => openConfirmDialog(element.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </If>
            </>
        );

        if (isPDF) {
            let pdf = value;
            const hostedOnAWS = isPDFHostedOnAWS(value);
            const source = loadedPDFs.find((item) => item.id === id);

            if (source) pdf = source.pdf;
            if (!source && hostedOnAWS) pdf = null;
            return (
                <div key={element.id} style={{ position: 'relative' }}>
                    <div className={messagesClasses.element}>
                        {renderIframe(pdf)}
                    </div>
                    {additionalItems()}
                </div>
            );
        }

        const isHTML = StringUtils.isHTML(value);
        if (isHTML) {
            return (
                <div key={element.id} style={{ position: 'relative' }}>
                    <div
                        dangerouslySetInnerHTML={{ __html: value }}
                        className={messagesClasses.element}
                    />
                    {additionalItems()}
                </div>
            );
        }

        return (
            <div key={element.id} style={{ position: 'relative' }}>
                <div className={clsx(messagesClasses.element, messagesClasses.elementPlainText)}>
                    {value}
                </div>
                {additionalItems()}
            </div>
        );
    });

    return (
        <div className={messagesClasses.container}>
            <If condition={permission}>
                <Button
                    className={clsx(classes.containedError, classes.actionClosePanel)}
                    size="small"
                    onClick={() => actionRemove(component.bulletinBoardComponentId)}
                >
                    x
                </Button>
            </If>
            <div className={messagesClasses.title}>
                <Form.Group>
                    <Form.Control
                        readOnly={!permission}
                        as="input"
                        size="md"
                        placeholder="Title"
                        value={groupTitle}
                        onChange={onGroupTitleChange}
                        onBlur={() => actionChangeTitle(component.bulletinBoardComponentId, groupTitle)}
                    />
                </Form.Group>
            </div>
            <div className={clsx(messagesClasses.content, permission ? '' : messagesClasses.contentNoPermission)}>
                <If condition={pinnedElements.length === 0 && nonPinnedElements.length === 0}>
                    <MessageIcon />
                </If>
                {renderElementsInComponent(pinnedElements)}
                {renderElementsInComponent(nonPinnedElements)}
            </div>
            <If condition={permission}>
                <div className={messagesClasses.actions}>
                    <Button
                        className={clsx(classes.containedInfo, messagesClasses.actionAdd)}
                        size="small"
                        onClick={openMessageForm}
                    >
                        Add
                    </Button>
                </div>
            </If>
            <If condition={permission}>
                <MessageForm
                    open={isMessageFormOpen}
                    selectedContentType={selectedContentType}
                    isMessagePinned={isMessagePinned}
                    isFileBeingUploaded={isFileBeingUploaded}
                    content={content}
                    onPDFUpload={onPDFUpload}
                    actionClose={closeMessageForm}
                    actionChange={onAddMessageFormChange}
                    actionSave={saveMessage}
                    isSaving={isMessageBeingSaved}
                />
                <ConfirmDialog
                    title="Attention!"
                    description="Do you want to remove this message?"
                    open={confirmDialog.isOpen}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onConfirmDialogClose}
                    onClickSecondary={onConfirmDialogClose}
                    onClickPrimary={removeMessage}
                />
            </If>
            <Dialog
                open={isPDFPreviewDialogOpen}
                fullWidth
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar title="PDF Preview" onClose={closePDFPreviewDialog} toolbarSize="sm" />
                <DialogContent>
                    {renderIframe(PDFPreviewContent)}
                </DialogContent>
            </Dialog>
        </div>
    );
};

Messages.propTypes = {
    component: PropTypes.object.isRequired,
    actionRemove: PropTypes.func.isRequired,
    actionChangeTitle: PropTypes.func.isRequired,
    permission: PropTypes.bool.isRequired,
};

export default Messages;

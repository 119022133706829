import React, {
    useEffect, useReducer, forwardRef, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import { Alert, InputGroup } from 'react-bootstrap';
import {
    Button, useMediaQuery, Tooltip, Dialog,
    DialogContent, Typography, AppBar, Toolbar,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import InputNumber from 'components/widgets/InputNumber';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { concat, isEmpty, round } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { v1 as uuid } from 'uuid';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import { useLazyQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import SplitAmountSettingsQuery from 'services/graphQL/query/SplitAmountSettingsQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import JournalDistributionReducer, { ACTION_TYPES } from 'components/modules/accounting/journal/reducer/JournalDistributionReducer';
import AccountingGLMap from 'services/mapData/AccountingGLMap';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountingSettingsQuery from 'services/graphQL/query/accounting/AccountingSettings';
import { CallSplitOutlinedIcon, CachedIcon, DeleteOutlineOutlinedIcon } from 'components/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SplitJournalLine from 'components/modules/accounting/journal/list/SplitJournalLine';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import { Link as RouteLink } from 'react-router-dom';
import PaperComponent, { TransitionComponent } from 'components/modules/accounting/PaperComponent';
import SplitAmountSettings from 'components/modules/settings/accounting/SplitAmountSettings';
import UploadRowsExcel from 'components/widgets/UploadRowsExcel';
import Summary from 'components/widgets/accounting/Summary';
import { TransactionType } from 'utils/enum/AccountingEnum';
import VirtualTable from 'components/widgets/VirtualTable';
import useAvailableControlNumbers from 'components/modules/accounting/journal/hooks/useAvailableControlNumbers';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));
const ownStyle = makeStyles(() => (
    {
        customHeight: {
            minHeight: '80vh',
            paddingLeft: '16px',
        },
    }
));

const JournalDistribution = forwardRef((props, ref) => {
    const theme = useTheme();
    const keyStore = new KeyStore();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        writePermissions, onAmountChange, processRecordId, onDirty, glLines,
        glOptions, onInventoryPurchaseLineAdded, processTypeId,
        transactionType, transactionAmount, accountTypesExcluded,
    } = props;

    const classes = {
        ...useStyles(),
        ...containerStyles(),
        ...ownStyle(),
    };

    const mountedRef = useRef(true);

    const initialState = {
        records: [],
        splitSettings: {
            accountId: 0,
            accountDescription: '',
            isControlled: false,
            controlledBy: '',
            accountType: '',
            amount: 0,
        },
        isModalDeleteOpen: false,
        idToDelete: 0,
        entryId: 0,
        differenceToAdjust: 0,
        splitAmountSettings: {},
        missingSplitAmountSettings: false,
        inventoryPurchasedCreditAccounts: [],
        openSplitAmountSettings: false,
    };
    const [state, dispatch] = useReducer(JournalDistributionReducer, initialState);
    const { loadAvailableControlNumbers, loadingControlNumbers, availableControlNumbers } = useAvailableControlNumbers();

    const [loadJournalDistribution, {
        loading, error,
    }] = useLazyQuery(
        AccountingGLQuery.GET_ACCOUNTING_GL_CHECK_LIST,
        {
            onCompleted: (res) => {
                const { getAccountingGLCheckList } = res;
                const mapValues = AccountingGLMap.glInformation(getAccountingGLCheckList.data);

                let hasPurchasedCreditAccount = false;
                mapValues.forEach((item) => {
                    if (state.inventoryPurchasedCreditAccounts.length > 0
                        && state.inventoryPurchasedCreditAccounts.includes(item.accountNumber)
                    ) {
                        hasPurchasedCreditAccount = true;
                    }
                });
                onInventoryPurchaseLineAdded(hasPurchasedCreditAccount);
                loadAvailableControlNumbers(mapValues);

                dispatch({ type: ACTION_TYPES.SET_FETCHED_RECORDS, value: mapValues });
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const [getInventoryPurchasedCreditAccounts] = useLazyQuery(
        AccountingSettingsQuery.GET_INVENTORY_PURCHASED_CREDIT_ACCOUNTS,
        {
            onCompleted: (res) => {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        inventoryPurchasedCreditAccounts: res.getInventoryPurchasedCreditAccounts,
                    },
                });
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage([errorMessage]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const [loadSplitSettings] = useLazyQuery(
        SplitAmountSettingsQuery.GET_ACCOUNTING_SPLIT_AMOUNT_SETTINGS,
        {
            onCompleted: (res) => {
                const { getAccountingSplitAmountBetweenLots } = res;
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        splitAmountSettings: getAccountingSplitAmountBetweenLots,
                    },
                });
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage([errorMessage]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const getLineErrors = (records) => {
        const errors = [];

        records.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.accountNumber <= 0) message += ' Account Number is required,';
            if (!item.amount || item.amount === 0) message += ' Amount is required and must be different than zero,';
            if (item.lotId <= 0) message += ' Lot name is required,';
            if (item.isControlled && !isEmpty(item.controlledBy) && (!item.controlNumber || (String(item.controlNumber)?.trim() ?? '') === '')) {
                message += ` The account ${item.accountDescription} must have a ${item.controlledBy} as Control number,`;
            }

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        return errors;
    };

    useImperativeHandle(ref, () => ({
        getRecords() {
            const { records } = state;
            const errors = getLineErrors(records);

            return {
                errors,
                records,
            };
        },
        clear() {
            dispatch({ type: ACTION_TYPES.CLEAR_LINES });
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state.records]);

    useEffect(() => {
        getInventoryPurchasedCreditAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddNewLine = () => {
        dispatch({ type: ACTION_TYPES.ADD_NEW_LINE });
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = () => {
        let dataAfterDeleted = [];
        const currentRecord = state.records.find((item) => item.entryId === state.idToDelete);

        if (currentRecord.processDetailRecordId) {
            dataAfterDeleted = state.records.filter((item) => item.processDetailRecordId !== currentRecord.processDetailRecordId);
        } else dataAfterDeleted = state.records.filter((item) => item.entryId !== state.idToDelete);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: dataAfterDeleted,
            notifyChange: onAmountChange,
            notifyDirty: onDirty,
        });

        onCloseDeleteConfirm();
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            notifyChange: onAmountChange,
            additionalFields,
            notifyDirty: onDirty,
            notifyInventoryPurchaseLineAdded: onInventoryPurchaseLineAdded,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;
        if (event && (key === 'Tab' || keyCode === 9) && id === 'memo') {
            dispatch({
                type: ACTION_TYPES.VALIDATE_ADD_LINE,
                method: onAddNewLine,
                rowIndex: cell.rowIndex,
                event,
            });
        }

        if (event && id !== 'memo' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'memo' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const onSplitAmount = () => {
        const {
            accountId, amount, accountDescription, isControlled, controlledBy,
        } = state.splitSettings;

        if (accountId === 0) {
            ModalUtils.errorMessage([{ message: 'Please select an account.' }]);
            return;
        }

        if (amount === 0) {
            ModalUtils.errorMessage([{ message: 'Amount must be different than zero.' }]);
            return;
        }

        if (!state.splitAmountSettings || state.splitAmountSettings.length === 0) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    missingSplitAmountSettings: true,
                },
            });
            return;
        }

        let { entryId } = state;
        const groupId = uuid();

        const generateRecords = state.splitAmountSettings.map((item) => {
            entryId -= 1;

            return {
                entryId,
                accountNumber: accountId,
                amount: round(amount * (item.percentage / 100), 2),
                controlNumber: '',
                lotId: item.lotId,
                memo: '',
                groupId,
                accountDescription,
                isControlled,
                controlledBy,
            };
        });

        const sumAmount = generateRecords.reduce((sum, next) => round(sum + next.amount, 2), 0);
        if (amount !== sumAmount) dispatch({ type: ACTION_TYPES.ADJUST_AMOUNT, differenceToAdjust: round(amount - sumAmount, 2) });

        const newRecords = concat(state.records, generateRecords);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: newRecords,
            notifyChange: onAmountChange,
            notifyDirty: onDirty,
            newEntryId: entryId,
        });
    };

    const onSplitLine = (items) => {
        dispatch({ type: ACTION_TYPES.SET_RECORDS_FROM_SPLIT, value: items, notifyDirty: onDirty });
    };

    const onCloseSplitAmountSettings = () => {
        loadSplitSettings();
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openSplitAmountSettings: false,
                missingSplitAmountSettings: false,
            },
        });
    };

    const loadRecordsFromExcel = ({ records, lots }) => {
        if (records) {
            const columnsNameTemplate = [
                {
                    name: ['accountnumber', 'account', 'account#', 'account number'],
                    index: 0,
                },
                {
                    name: ['amount', 'amounts'],
                    index: 0,
                },
                {
                    name: ['control#', 'control #', 'controlnumber', 'control number', 'control'],
                    index: 0,
                },
                {
                    name: ['lotname', 'lot name', 'lot'],
                    index: 0,
                },
                {
                    name: ['memo'],
                    index: 0,
                },
            ];
            const { rows } = records;
            const columns = rows[0];

            const columnsName = columnsNameTemplate.map((col) => {
                const currentCol = { ...col };
                columns.every((_, j) => {
                    const columnName = (columns[j] || '').replace(/\s+/g, '').toLowerCase();

                    if (currentCol.name.some((key) => key === columnName)) {
                        currentCol.index = Number(j);
                        return false;
                    }
                    return true;
                });

                return currentCol;
            });

            rows.shift();
            const recordsFromExcel = [];
            let { entryId } = state;

            rows.forEach((element) => {
                entryId -= 1;
                const lotName = (element[columnsName[3].index] || '').trim();

                recordsFromExcel.push({
                    entryId,
                    accountNumber: Number((element[columnsName[0].index])) || 0,
                    amount: NumberUtils.round(element[columnsName[1].index] || 0),
                    controlNumber: String(element[columnsName[2].index])?.trim() || '',
                    lotId: lots ? lots.find((item) => item.lotName.toUpperCase() === lotName.toUpperCase())?.lotId : null,
                    lotName,
                    memo: String(element[columnsName[4].index])?.trim() ?? '',
                });
            });

            const newRecords = concat(state.records, recordsFromExcel);

            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                value: newRecords,
                notifyChange: onAmountChange,
                notifyDirty: onDirty,
                newEntryId: entryId,
            });
        }
    };

    const getColumns = (columnOptions) => {
        const columns = [
            {
                label: 'Line',
                width: 40,
                className: clsx(classes.columnStyle, classes.columnLeft),
                dataKey: 'line',
                cellRenderer: (cell) => cell.rowIndex + 1,
            },
            {
                label: 'Account',
                width: 225,
                dataKey: 'accountNumber',
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            accountNumber, accountDescription, entryId, accountType,
                        }, dataKey,
                    } = cell;
                    const isExcludedAccount = accountTypesExcluded?.includes(accountType) || false;
                    if (writePermissions && columnOptions.editglAccount && !isExcludedAccount) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                value={accountNumber}
                                className={accountNumber > 0 ? '' : 'invalid-field'}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypesExcluded,
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{accountDescription}</span>;
                },
            },
            {
                label: 'Amount',
                width: 120,
                dataKey: 'amount',
                cellRenderer: (cell) => {
                    const { rowData: { amount, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editglAmount) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={amount}
                                className={amount && amount !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(amount || 0)}</span>;
                },
            },
            {
                label: 'Control #',
                width: 160,
                dataKey: 'controlNumber',
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            isControlled, controlledBy, controlNumber, entryId, processDetailRecordId,
                        }, dataKey,
                    } = cell;
                    const controlledByValue = isControlled ? controlledBy ?? '' : '';
                    const cellObject = { column: { id: dataKey }, ...cell };

                    if (writePermissions && columnOptions.editglControlNumber) {
                        if (processDetailRecordId > 0 && availableControlNumbers.length > 0) {
                            const controlNumberOptions = availableControlNumbers
                                .find((item) => Number(item.recordId) === Number(processDetailRecordId))
                                ?.controlNumbers.map((control) => ({
                                    label: control.description,
                                    value: String(control.id),
                                }));

                            return (
                                <SelectControl
                                    sorted
                                    creatable
                                    formatCreateLabel={(inputValue) => inputValue}
                                    allowEmptyLine={false}
                                    name={dataKey}
                                    value={controlNumber}
                                    loading={loadingControlNumbers}
                                    placeHolder="select or type a control number"
                                    dataSource={{
                                        localData: controlNumberOptions,
                                    }}
                                    editorCellObject={cellObject}
                                    className={controlledByValue === '' || (controlledByValue !== '' && controlNumber !== '') ? '' : 'invalid-field'}
                                    onChange={handleEditorChange}
                                    comparePropertyId={entryId}
                                />
                            );
                        }

                        return (
                            <InputControl
                                name={dataKey}
                                value={controlNumber}
                                editorCellObject={cellObject}
                                className={controlledByValue === '' || (controlledByValue !== '' && controlNumber !== '') ? '' : 'invalid-field'}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{controlNumber}</span>;
                },
            },
            {
                label: 'Lot Name',
                width: 130,
                dataKey: 'lotId',
                cellRenderer: (cell) => {
                    const { rowData: { lotId, lotName, entryId }, dataKey } = cell;
                    if (writePermissions && columnOptions.editglLot) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                value={lotId}
                                className={lotId > 0 ? '' : 'invalid-field'}
                                placeHolder="select a lot"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotId', descriptionField: 'lotName',
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{lotName}</span>;
                },
            },
            {
                label: 'Memo',
                width: 250,
                dataKey: 'memo',
                cellRenderer: (cell) => {
                    const { rowData: { memo, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editglMemo) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={memo}
                                editorCellObject={cellObject}
                                type="textarea"
                                rows={1}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{memo}</span>;
                },
            },
        ];

        if (writePermissions && (columnOptions.showDeleteAction || columnOptions.showSplitBatchLine)) {
            columns.push({
                label: 'Actions',
                width: 100,
                dataKey: 'actions',
                cellRenderer: (cell) => (
                    <div className={classes.buttonSpacing}>
                        {writePermissions && columnOptions.showSplitBatchLine
                        && (
                            <IconButton
                                className={classes.buttonWrapper}
                                onClick={() => dispatch({ type: ACTION_TYPES.SET_ON_SPLIT, value: cell.rowData })}
                            >
                                <Tooltip title="Split batch line">
                                    <CallSplitOutlinedIcon
                                        className={clsx(classes.actionButtonSize, classes.actionButtonWarning)}
                                    />
                                </Tooltip>
                            </IconButton>
                        )}

                        {writePermissions && columnOptions.showDeleteAction
                          && (
                              <IconButton
                                  className={classes.buttonWrapper}
                                  onClick={() => dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: cell.rowData.entryId })}
                              >
                                  <Tooltip title="Delete line">
                                      <DeleteOutlineOutlinedIcon
                                          className={clsx(classes.actionButtonSize, classes.deleteButton)}
                                      />
                                  </Tooltip>
                              </IconButton>
                          )}
                    </div>
                ),
            });
        }

        return columns;
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!mountedRef) return;

        if (processRecordId > 0) {
            loadJournalDistribution({
                variables: {
                    processRecordId,
                    processTypeId,
                },
            });
        }

        if (processRecordId === 0 && state.records.length === 0) onAddNewLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processRecordId, error, processTypeId]);

    useEffect(() => {
        if (glLines && glLines.length > 0) {
            const mapValues = AccountingGLMap.glInformation(glLines);
            loadAvailableControlNumbers(mapValues);

            dispatch({
                type: ACTION_TYPES.SET_RECORDS_FROM_PROCESS, value: mapValues, notifyChange: onAmountChange, notifyDirty: onDirty,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glLines]);

    useEffect(() => {
        if (glOptions.showSplitAction) loadSplitSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glOptions.showSplitAction]);

    useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    useEffect(() => {
        if (state.entryId !== 0) {
            const rows = state.records.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.amount-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.entryId]);

    const journalAmount = state.records.reduce((sum, next) => round(sum + next.amount, 2), 0);
    const totalDebit = transactionType === TransactionType.DEBIT ? transactionAmount : journalAmount;
    const totalCredit = transactionType === TransactionType.CREDIT ? transactionAmount : journalAmount;

    return (
        <div className={clsx(classes.flexContainer, classes.customHeight)}>
            <If condition={!(glOptions.showSplitAction || glOptions.showNewAction)}>
                <Header classes={{ toolbar: classes.toolbar }}>
                    <Summary displayInline debit={totalDebit} credit={totalCredit} />
                </Header>
            </If>
            <If condition={writePermissions && (glOptions.showSplitAction || glOptions.showNewAction)}>
                <Header classes={{ toolbar: classes.toolbar }}>
                    <>
                        <div className={classes.containerHeader}>
                            {writePermissions && glOptions.showNewAction && (
                                <>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddCircleOutlineIcon />}
                                        size="small"
                                        className={classes.buttonGreen}
                                        disabled={state.differenceToAdjust !== 0}
                                        onClick={onAddNewLine}
                                    >
                                        New Line
                                    </Button>
                                    <UploadRowsExcel setLoadRecords={loadRecordsFromExcel} />
                                </>
                            )}
                        </div>
                        {writePermissions && glOptions.showSplitAction && (
                            <div className={classes.buttonSpacing}>
                                <SelectControl
                                    className={isMobile ? classes.widthFull : classes.width160}
                                    name="accountId"
                                    value={state.splitSettings.accountId}
                                    placeHolder="select an account"
                                    onChange={(id, value, _, additionalFields) => dispatch({
                                        type: ACTION_TYPES.SET_SPLIT_AMOUNT_SETTTINGS, id, value, additionalFields,
                                    })}
                                    dataSource={{
                                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                        variables: {
                                            paginate: {
                                                init: 0,
                                                ignoreLimit: true,
                                            },
                                            filters: {
                                                accountTypesExcluded,
                                            },
                                        },
                                        rootData: 'getAccountingCOAList.data',
                                        idField: 'accountNumber',
                                        descriptionField: 'fullDescription',
                                        additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                                    }}
                                />
                                <InputGroup className={[classes.splitAmountGroup, isMobile ? classes.width55Percentage : classes.width160]}>
                                    <InputGroup.Prepend className={classes.InputGroupPrepend}>
                                        <InputGroup.Text className={classes.inputGroupSM}>Amount</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <InputNumber
                                        value={state.splitSettings.amount}
                                        placeholder="0.00"
                                        showCurrency
                                        thousandSeparator
                                        allowNegative
                                        name="amount"
                                        onChange={(value) => dispatch({ type: ACTION_TYPES.SET_SPLIT_AMOUNT_SETTTINGS, id: 'amount', value })}
                                        disabled={state.differenceToAdjust !== 0}
                                        size="sm"
                                    />
                                </InputGroup>
                                <Button
                                    variant="outlined"
                                    startIcon={<CachedIcon />}
                                    disabled={!state.splitAmountSettings || state.differenceToAdjust !== 0}
                                    size="small"
                                    onClick={onSplitAmount}
                                    className={classes.splitButton}
                                >
                                    Split Amount
                                </Button>
                            </div>
                        )}
                    </>
                    <>
                        <Summary debit={totalDebit} credit={totalCredit} />
                    </>
                </Header>
            </If>
            { loading && <div className={classes.centerDiv}><CircularProgress size="1em" color="inherit" /></div>}
            { !loading && (
                <div className={clsx(classes.bottomTableHeight)}>
                    <VirtualTable
                        loading={loading}
                        data={state.records}
                        columns={getColumns(glOptions)}
                    />
                </div>
            )}
            { state.differenceToAdjust !== 0 && state.splitAmountSettings.length > 0 && (
                <Alert className={classes.alert} variant="warning">
                    Please adjust the amount! (Difference: $
                    {state.differenceToAdjust}
                    )
                </Alert>
            )}
            { state.missingSplitAmountSettings && (
                <Alert className={classes.alert} variant="warning">
                    Missing split amount configuration! &nbsp;
                    {keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE)
                        && (
                            <RouteLink
                                to="#"
                                onClick={() => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: { openSplitAmountSettings: true },
                                })}
                            >
                                Click here to configure
                            </RouteLink>
                        )}
                </Alert>
            )}
            {writePermissions && (state.recordToSplit || state.splitedRecords) && (
                <SplitJournalLine
                    glLine={state.recordToSplit}
                    splitLines={state.splitedRecords}
                    onSplitLine={onSplitLine}
                    onCloseSplitModal={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { recordToSplit: null, splitedRecords: null } })}
                />
            )}
            <ConfirmDialog
                title={state.deletingMultipleLines ? 'Confirm remove multiple lines' : 'Confirm remove line'}
                description={
                    state.deletingMultipleLines ? 'This line is part of the split process. \n Are you sure you want to remove all the lines associated.'
                        : 'Are you sure you want to remove this line?'
                }
                open={state.isModalDeleteOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
            <If condition={state.openSplitAmountSettings}>
                <Dialog
                    open={state.openSplitAmountSettings}
                    onClose={onCloseSplitAmountSettings}
                    maxWidth="lg"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    TransitionComponent={TransitionComponent}
                    PaperComponent={PaperComponent}
                >
                    <AppBar className={classes.appBarMove}>
                        <Toolbar className={classes.centerItems}>
                            <Typography variant="h6" className={classes.title}>
                                Split amount between lots settings
                            </Typography>
                            <div className={classes.centerItems}>
                                <IconButton edge="start" color="inherit" onClick={onCloseSplitAmountSettings}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className={classes.noPaddingSides}>
                        <SplitAmountSettings />
                    </DialogContent>
                </Dialog>
            </If>
        </div>
    );
});

JournalDistribution.propTypes = {
    processRecordId: PropTypes.number.isRequired,
    writePermissions: PropTypes.bool.isRequired,
    onAmountChange: PropTypes.func,
    onDirty: PropTypes.func,
    glLines: PropTypes.array,
    glOptions: PropTypes.object,
    onInventoryPurchaseLineAdded: PropTypes.func,
    processTypeId: PropTypes.number.isRequired,
    transactionType: PropTypes.string.isRequired,
    transactionAmount: PropTypes.number,
    accountTypesExcluded: PropTypes.array,
};

JournalDistribution.defaultProps = {
    glLines: null,
    onAmountChange: () => null,
    onDirty: () => null,
    onInventoryPurchaseLineAdded: () => null,
    transactionAmount: 0,
    accountTypesExcluded: [],
    glOptions: {
        editglAccount: true,
        editglAmount: true,
        editglControlNumber: true,
        editglLot: true,
        editglMemo: true,
        showNewAction: true,
        showSplitAction: true,
        showDeleteAction: true,
        showSplitBatchLine: false,
    },
};

export default JournalDistribution;

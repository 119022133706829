import React, { Component } from 'react';

// Components and Others
import { map } from 'lodash';
import PropTypes from 'prop-types';
import Loading from 'components/widgets/Loading';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import AddressContent from 'components/widgets/customer/AddressContent';
import AddressHistoryContainer from 'components/containers/deals/read/buyer/AddressHistoryContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, IconButton,
} from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

const styles = (theme) => DealStyles.dialogHistory(theme);

class AddressHistory extends Component {
    componentDidMount() {
        const { getServicesData } = this.props;
        getServicesData();
    }

    renderRow(record, index) {
        const { props: { classes, addToPreviewsList } } = this;

        return (
            <div key={index} className={classes.rowDialog}>
                <div className={classes.contentButtonPlus}>
                    <IconButton
                        aria-label="plus"
                        size="small"
                        className={classes.buttonPlus}
                        onClick={() => addToPreviewsList(record.customerAddressId)}
                    >
                        <ControlPointIcon />
                    </IconButton>
                </div>
                <AddressContent record={record} className={classes.boxContent} />
            </div>
        );
    }

    render() {
        const {
            props: {
                open, classes, onClose, records, load,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Address History" onClose={onClose} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    {map(records, (item, index) => this.renderRow(item, index))}
                    {load && <Loading />}
                </DialogContent>
                <DialogActions onClickPrimary={onClose} titlePrimary="Close" hiddenSecondaryButton variant="outlined" />
            </Dialog>
        );
    }
}

AddressHistory.propTypes = {
    open: PropTypes.bool.isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        county: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
        housingStatus: PropTypes.string,
        mortgageOrRent: PropTypes.number,
        totalMonths: PropTypes.number,
    }).isRequired).isRequired,
    load: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    addToPreviewsList: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(AddressHistoryContainer(AddressHistory));

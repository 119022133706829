import { gql } from '@apollo/client';

export default class EntityQuery {
    static GET_ENTITY_METADATA = gql`
        query getEntityMetadata($entityTable: [String], $property: [String]){
            getEntityMetadata(entityTable: $entityTable, property: $property){
                entityMetadataId
                entityTable
                property
                label
                type
                displayed
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { orderBy, findIndex } from 'lodash';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import CustomerService from 'services/modules/CustomerService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';

const ManageAddressContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        reloadManagerAddress: PropTypes.bool.isRequired,
        makeReloadAddress: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.customerService = new CustomerService();
        this.addressListSubscription = null;

        this.state = {
            records: [],
            load: false,
            openConfirm: false,
            customerAddressId: null,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
        this.subscribeAddresslList();
    }

    componentDidUpdate() {
        const { props: { reloadManagerAddress, makeReloadAddress } } = this;

        if (reloadManagerAddress) {
            this.getServicesData();
            makeReloadAddress(false);
        }
    }

    componentWillUnmount() {
        this.unsubscribeAddressList();
    }

    onOpenConfirm(customerAddressId) {
        this.setState({
            openConfirm: true,
            customerAddressId,
        });
    }

    onCloseConfirm() {
        this.setState({
            openConfirm: false,
            customerAddressId: null,
        });
    }

    setCurrentAddress(record) {
        this.customerService.setCurrentAddress({ customerId: record.customerId, customerAddressId: record.customerAddressId })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    getServicesData() {
        const { props } = this;
        const input = {
            input: {
                customerId: props.customerId,
                status: 'Active',
            },
            sort: {
                field: 'start',
                dir: 'desc',
            },
        };

        this.setState({
            load: true,
        });

        this.customerService.getAddressList(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.getCustomerAddressList) {
                    const { getCustomerAddressList } = data;

                    this.setState({
                        records: getCustomerAddressList,
                    });
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    subscribeAddresslList() {
        const { props } = this;
        const input = {
            customerId: props.customerId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, CustomerSubscription.CUSTOMER_ADDRESS_LIST, input)
            .then((response) => {
                this.addressListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.customerAddressList) {
            const { customerAddressList: { type }, customerAddressList } = data;

            switch (type) {
            case SubscriptionActionType.CREATED:
                this.addNewAddress(customerAddressList.data);
                break;
            case SubscriptionActionType.UPDATED:
                this.updateAddress(customerAddressList.data);
                break;
            case SubscriptionActionType.DELETED:
                this.removeAddress(customerAddressList.data);
                break;
            default:
            }
        }
    }

    addNewAddress(record) {
        this.setState(({ records }) => {
            let newRecords = [];

            if (record.isCurrentAddress) {
                newRecords = this.makeOutdatedAddress([...records]);
            } else {
                newRecords = [...records];
            }

            newRecords = orderBy(update(newRecords, { $push: [record] }), ['start'], ['desc']);

            return { records: newRecords };
        });
    }

    updateAddress(record) {
        const { state } = this;
        const index = findIndex(state.records, { customerAddressId: record.customerAddressId });

        if (index > -1) {
            if (record.isCurrentAddress) {
                const newRecord = this.makeOutdatedAddress(state.records);
                const updatedRecord = update(newRecord, { [index]: { $set: record } });

                this.setState((prevState) => ({
                    records: update(prevState.records, { $set: updatedRecord }),
                }));
            } else {
                const updatedRecord = update(state.records, { [index]: { $set: record } });

                this.setState((prevState) => ({
                    records: update(prevState.records, { $set: updatedRecord }),
                }));
            }
        }
    }

    removeAddress(record) {
        const { records } = this.state;
        const index = findIndex(records, { customerAddressId: record.customerAddressId });

        if (index > -1) {
            this.setState((prevState) => ({
                records: update(prevState.records, { $splice: [[index, 1]] }),
            }));
        }
    }

    deleteCustomerAddress() {
        const { state: { customerAddressId } } = this;
        const input = {
            customerAddressId,
        };

        this.customerService.deleteCustomerAddress(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data && data.deleteCustomerAddress) {
                    this.onCloseConfirm();
                }
            });
    }

    makeOutdatedAddress(records) {
        const index = findIndex(records, { isCurrentAddress: true });
        if (index > -1) {
            return update(records, { [index]: { isCurrentAddress: { $set: false } } });
        }

        return records;
    }

    unsubscribeAddressList() {
        if (this.addressListSubscription) {
            this.addressListSubscription.unsubscribe();
        }
    }

    initBind() {
        this.onOpenConfirm = this.onOpenConfirm.bind(this);
        this.onCloseConfirm = this.onCloseConfirm.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.setCurrentAddress = this.setCurrentAddress.bind(this);
        this.deleteCustomerAddress = this.deleteCustomerAddress.bind(this);
        this.subscribeAddresslList = this.subscribeAddresslList.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.addNewAddress = this.addNewAddress.bind(this);
        this.removeAddress = this.removeAddress.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onOpenConfirm={this.onOpenConfirm}
                onCloseConfirm={this.onCloseConfirm}
                setCurrentAddress={this.setCurrentAddress}
                deleteCustomerAddress={this.deleteCustomerAddress}
            />
        );
    }
};

export default ManageAddressContainer;

import { concat } from 'lodash';

export const ACTION_TYPES = {
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    ADD_NEW_LINE: 'addNewLine',
    SET_ON_DELETE: 'setOnDelete',
    SET_STATE_VALUES: 'setStateValues',
    VALIDATE_ADD_LINE: 'validateAddLine',
    CLEAR_LINES: 'clearLines',
    ADD_SELECTED_PARTS: 'addSelectedParts',
};

const PurchaseOrderDetailReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.ADD_SELECTED_PARTS:
    {
        const parts = action.value;
        const { records, purchaseOrderDetailId } = state;
        let currentIndex = purchaseOrderDetailId;

        parts.forEach((data) => {
            currentIndex -= 1;

            const recordExist = records.find((c) => c.partInventoryId === data.partsInventoryId);
            if (recordExist) {
                records.forEach((item) => {
                    if (item.partInventoryId === data.partsInventoryId) {
                        // eslint-disable-next-line no-param-reassign
                        item.quantity += 1;
                    }
                });

                return;
            }

            records.push({
                purchaseOrderDetailId: currentIndex,
                purchaseOrderId: action.purchaseOrderId,
                partInventoryId: data.partsInventoryId,
                partStockNumber: data.partStockNumber,
                description: data.description,
                quantity: 1,
                unitPrice: Number(data.partCost),
                netPrice: Number(data.netPrice),
                listPrice: Number(data.listPrice),
                partCost: Number(data.partCost),
                subTotal: Number(data.partCost),
            });
        });

        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records,
            purchaseOrderDetailId: currentIndex,
            openPartsSelect: false,
        };
    }
    case ACTION_TYPES.SET_RECORDS: {
        const newAmount = action.value.reduce((sum, next) => sum + next.amount, 0);
        if (action.notifyChange) action.notifyChange(newAmount);
        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records: action.value,
            purchaseOrderDetailId: action?.newPurchaseOrderDetailId ?? state.purchaseOrderDetailId,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const keyValue = 'purchaseOrderDetailId';
        const {
            value, columnId, cell,
        } = action;

        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;

                if (columnId === 'quantity' || columnId === 'unitPrice') {
                    newItem.subTotal = Number(newItem.quantity) * Number(newItem.unitPrice);
                }
            }

            return newItem;
        });

        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const newLine = {
            purchaseOrderDetailId: state.purchaseOrderDetailId - 1,
            partStockNumber: '',
            description: '',
        };
        const data = concat(state.records, newLine);

        return {
            ...state,
            records: data,
            purchaseOrderDetailId: newLine.purchaseOrderDetailId,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.VALIDATE_ADD_LINE: {
        const { rowIndex, event } = action;

        if (rowIndex === state.records.length - 1) action.method(state.records, event);

        return {
            ...state,
        };
    }
    case ACTION_TYPES.CLEAR_LINES:
    {
        return {
            ...state,
            records: [],
            purchaseOrderDetailId: -1,
        };
    }
    default:
        return state;
    }
};

export default PurchaseOrderDetailReducer;

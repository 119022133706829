import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import ModalUtils from 'utils/ModalUtils';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import If from 'components/widgets/conditional/If';
import { Grid, makeStyles } from '@material-ui/core';
import { Col, Form, Row } from 'react-bootstrap';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import PropTypes from 'prop-types';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import { FetchPolicy } from 'utils/enum/Core';

const ownStyle = makeStyles(() => ({
    paddingTop20: {
        paddingTop: '20px',
    },
}));

const PurchaseReceiveDialog = (props) => {
    const classes = {
        ...ownStyle(),
    };
    const {
        haveMappingInParts, onCloseActionDialog, purchaseOrderId, isReturn,
    } = props;

    const [record, setRecord] = useState({
        accountPayableDefault: 0,
        referenceNumber: '',
        openPopup: true,
    });

    const {
        accountPayableDefault,
        referenceNumber,
        openPopup,
    } = record;

    const [getDefaultBankSettings] = useLazyQuery(AccountingQuery.GET_ACCOUNTS_PAYABLE_SETTINGS_PURCHASE_ORDER, {
        onCompleted: (res) => {
            if (res.getAccountsPayableSettingsPurchaseOrder) {
                const { accountsPayable } = res.getAccountsPayableSettingsPurchaseOrder;

                setRecord({
                    ...record,
                    accountPayableDefault: Number(accountsPayable),
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [receivePurchase, { loading: receiving }] = useMutation(PartsMutation.RECEIVE_PURCHASE_ORDER, {
        onCompleted: (mutationData) => {
            if (mutationData?.receivePurchase) {
                ModalUtils.successMessage(null, 'Purchase order received successfully');
            }

            onCloseActionDialog();
        },
        onError: (errorMessage) => {
            onCloseActionDialog();
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [returnPurchase, { loading: returning }] = useMutation(PartsMutation.RETURN_PURCHASE_ORDER, {
        onCompleted: (mutationData) => {
            if (mutationData?.returnPurchase) {
                ModalUtils.successMessage(null, 'Purchase order returned successfully');
            }

            onCloseActionDialog();
        },
        onError: (errorMessage) => {
            onCloseActionDialog();
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onActionConfirm = async () => {
        setRecord({
            ...record,
            openPopup: false,
        });

        if (isReturn) {
            await returnPurchase({
                variables: {
                    id: purchaseOrderId,
                    accountNumber: accountPayableDefault,
                    referenceNumber,
                },
            });

            return;
        }

        await receivePurchase({
            variables: {
                id: purchaseOrderId,
                accountNumber: accountPayableDefault,
                referenceNumber,
            },
        });
    };

    useEffect(() => {
        getDefaultBankSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dialogTitle = isReturn ? `Return Purchase  ${purchaseOrderId}` : `Receive Purchase  ${purchaseOrderId}`;
    const dialogDescription = isReturn ? `Are you sure you want to return this purchase? ${haveMappingInParts === 0
        ? `If you want to create an invoice automatically for this vendor,
        you should go to parts settings and choose the account for mapping "Parts Inventory"` : ''}`
        : `Are you sure you want to receive this purchase? ${haveMappingInParts === 0
            ? `If you want to create a credit invoice automatically for this vendor,
            you should go to parts settings and choose the account for mapping "Parts Inventory"` : ''}`;

    return (
        <>
            <ConfirmDialog
                title={dialogTitle}
                description={dialogDescription}
                open={openPopup}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseActionDialog}
                onClickSecondary={onCloseActionDialog}
                onClickPrimary={onActionConfirm}
                disablePrimaryButton={referenceNumber === '' || accountPayableDefault === 0 || receiving || returning}
            >
                <>
                    <Grid item xs={12} className={classes.paddingTop20}>
                        <Form.Group as={Row}>
                            <Form.Label column="sm" xs={4} className="required">{isReturn ? 'Credit Number' : 'Invoice Number'}</Form.Label>
                            <Col xs={8}>
                                <Form.Control
                                    className={referenceNumber === '' ? 'invalid-field' : ''}
                                    type="text"
                                    size="sm"
                                    placeholder={isReturn ? 'Credit Number' : 'Invoice Number'}
                                    onChange={(e) => setRecord({
                                        ...record,
                                        referenceNumber: e.target.value,
                                    })}
                                />
                            </Col>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12}>
                        <Form.Group as={Row}>
                            <Form.Label column="sm" xs={4} className="required">Account Payable</Form.Label>
                            <Col xs={8}>
                                <DropdownQuery
                                    name="accountPayableDefault"
                                    className={accountPayableDefault === 0 ? 'invalid-field' : ''}
                                    value={accountPayableDefault}
                                    placeHolder="Select the account payable"
                                    onChange={(name, newValue) => setRecord({
                                        ...record,
                                        accountPayableDefault: newValue,
                                    })}
                                    dataSource={{
                                        query: AccountingCOAQuery.GET_PURCHASE_ORDER_COA_LIST,
                                        variables: {
                                            paginate: {
                                                init: 0,
                                                ignoreLimit: true,
                                            },
                                            filters: {
                                                accountTypes: [AccountingCOAType.ACCOUNTS_PAYABLE],
                                            },
                                        },
                                        rootData: 'getPurchaseOrderCOAList.data',
                                        idField: 'accountNumber',
                                        descriptionField: 'fullDescription',
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Grid>
                </>
            </ConfirmDialog>
            <If condition={receiving}>
                <DialogActionMessage message="Receiving..." />
            </If>
            <If condition={returning}>
                <DialogActionMessage message="Returning..." />
            </If>
        </>
    );
};

PurchaseReceiveDialog.propTypes = {
    haveMappingInParts: PropTypes.bool,
    onCloseActionDialog: PropTypes.func,
    purchaseOrderId: PropTypes.number.isRequired,
    isReturn: PropTypes.bool,
};

PurchaseReceiveDialog.defaultProps = {
    haveMappingInParts: false,
    onCloseActionDialog: () => {},
    isReturn: false,
};

export default PurchaseReceiveDialog;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PhoneUtils from 'lib/PhoneUtils';
import StringUtils from 'lib/StringUtils';
import LabelValue from 'components/widgets/LabelValue';
import MultipleLineValues from 'components/widgets/customer/MultipleLineValues';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    boxEmploymentContent: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    labelClass: {
        minWidth: '150px',
    },
});

class EmploymentContent extends Component {
    render() {
        const {
            props: {
                className, classes, record, mainInformation, classNameFooter, rightSection,
            },
        } = this;
        const {
            totalMonths, status, employer, title, monthlyIncome, workPhone,
            otherIncomeMonthly, otherIncomeSource, city, address1, address2, state,
            zipCode, county, country,
        } = record || {};
        const currentCity = StringUtils.isEmpty(city) ? '' : `${city},`;
        const address = [
            address1,
            address2,
            `${currentCity} ${state || ''}`,
            zipCode,
            county,
            country,
        ];

        const years = Math.floor((totalMonths || 0) / 12);
        const months = Math.floor((totalMonths || 0) % 12);
        const period = `${years} year + ${months} months`;

        return (
            <div className={clsx(classes.boxEmploymentContent, className)}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        className={classes.customer}
                        xs={6}
                    >
                        <LabelValue
                            labelClass={classes.labelClass}
                            label="Employment Status"
                            value={status}
                        />
                        <LabelValue
                            labelClass={classes.labelClass}
                            label="Employer"
                            value={employer}
                        />
                        <LabelValue
                            labelClass={classes.labelClass}
                            label="Job Title"
                            value={title}
                        />
                        <LabelValue
                            labelClass={classes.labelClass}
                            label="Period"
                            value={period}
                        />
                        {!mainInformation && (
                            <>
                                <LabelValue
                                    labelClass={classes.labelClass}
                                    label="Monthly Income"
                                    value={monthlyIncome}
                                />
                                <LabelValue
                                    labelClass={classes.labelClass}
                                    label="Work Phone"
                                    value={PhoneUtils.formatPhone(workPhone) || ''}
                                />
                                <LabelValue
                                    labelClass={classes.labelClass}
                                    label="Other Monthly Income"
                                    value={otherIncomeMonthly}
                                />
                                <LabelValue
                                    labelClass={classes.labelClass}
                                    label="Source Other Income"
                                    value={otherIncomeSource}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        className={classes.customer}
                        xs={6}
                    >
                        <MultipleLineValues
                            label="Address"
                            values={address}
                        />
                    </Grid>
                </Grid>
                {rightSection && (<div className={classNameFooter || ''}>{rightSection}</div>)}
            </div>
        );
    }
}

EmploymentContent.propTypes = {
    record: PropTypes.shape({
        status: PropTypes.string,
        employer: PropTypes.string,
        title: PropTypes.string,
        totalMonths: PropTypes.number,
        monthlyIncome: PropTypes.number,
        workPhone: PropTypes.string,
        otherIncomeMonthly: PropTypes.number,
        otherIncomeSource: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        county: PropTypes.string,
        country: PropTypes.string,
    }),
    rightSection: PropTypes.node,
    className: PropTypes.string,
    mainInformation: PropTypes.bool,
    classNameFooter: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

EmploymentContent.defaultProps = {
    record: {},
    className: '',
    mainInformation: false,
    rightSection: null,
    classNameFooter: '',
};

export default withStyles(styles)(EmploymentContent);

import React, {
    useEffect,
    useContext,
    useReducer,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid, Divider,
} from '@material-ui/core';
import { v1 as uuid } from 'uuid';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import DataType from 'utils/enum/DataType';
import UserContext from 'components/context/UserContext';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import { Col, Form } from 'react-bootstrap';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotsCategory from 'utils/enum/LotsCategory';
import ButtonStyles from 'styles/theme/Button';
import VirtualTable from 'components/widgets/VirtualTable';
import If from 'components/widgets/conditional/If';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

const INIT_STATE = {
    confirmDialogOpen: false,
    invUserTabName1: '',
    invUserTabName2: '',
    customFields: [],
};

const ACTION_TYPES = {
    SET_TABS_NAME: 'setTabsName',
    SET_CUSTOM_FIELDS: 'setCustomFields',
    SET_CUSTOM_FIELD: 'setCustomField',
    TOGGLE_CONFIRM_DIALOG: 'toggleConfirmDialog',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABS_NAME:
        const { value } = action;

        return {
            ...state,
            invUserTabName1: value.invUserTabName1 || '',
            invUserTabName2: value.invUserTabName2 || '',
        };
    case ACTION_TYPES.SET_CUSTOM_FIELDS:
        return {
            ...state,
            customFields: action.value,
        };
    case ACTION_TYPES.SET_CUSTOM_FIELD:
        const { index, customField } = action.value;
        const clone = [...state.customFields];

        clone[index] = customField;
        return {
            ...state,
            customFields: clone,
        };
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG:
        return {
            ...state,
            confirmDialogOpen: !state.confirmDialogOpen,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '12px',
    },
    input: {
        fontSize: '14px',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    customFieldsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 'initial',
        },
    },
    tableContainer: {
        height: '260px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowY: 'hidden',
            overflowX: 'auto',
            '& > div': {
                minWidth: '600px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'center',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
}));

const CustomFieldsSettings = ({ writable }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const tempCustomFieldData = useRef();

    const {
        confirmDialogOpen,
        invUserTabName1,
        invUserTabName2,
        customFields,
    } = state;

    const {
        data: inventoryMetaData,
        loading: inventoryMetaDataLoading,
        error: inventoryMetaDataError,
    } = useQuery(InventoryQuery.GET_INVENTORY_METADATA, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveUserTabNames] = useMutation(InventoryMutation.SAVE_INVENTORY_SETTINGS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'User Tab Names saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the User Tab Names');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [saveMetadata] = useMutation(InventoryMutation.SAVE_INVENTORY_METADATA, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Custom fields saved successfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [getSettings] = useLazyQuery(LotQuery.GET_SETTINGS, {
        onCompleted: (response) => {
            if (response) {
                const settings = response.getSettings;
                dispatch({
                    type: ACTION_TYPES.SET_TABS_NAME,
                    value: settings.reduce((a, b) => {
                        const current = { ...a };
                        current[b.key] = b.value;

                        return current;
                    }, {}),
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2 } = InventoryCategory;
    useEffect(() => {
        if (userInformation) {
            const defaultLot = userInformation?.defaultLot || userInformation?.usersLot;

            getSettings({
                variables: {
                    category: LotsCategory.INVENTORY,
                    lotName: defaultLot,
                    key: [INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2],
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        if (inventoryMetaDataError) {
            ModalUtils.errorMessage(inventoryMetaDataError?.graphQLErrors);
            return;
        }

        if (!inventoryMetaDataLoading) {
            const { getInventoryMetadata } = inventoryMetaData;
            dispatch({
                type: ACTION_TYPES.SET_CUSTOM_FIELDS,
                value: getInventoryMetadata,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryMetaDataError, inventoryMetaDataLoading]);

    const onChange = ({ target: { name, value } }, skipConfirm = false) => {
        if (name.startsWith('invUserTabName')) {
            dispatch({
                type: ACTION_TYPES.SET_TABS_NAME,
                value: {
                    invUserTabName1,
                    invUserTabName2,
                    [name]: value,
                },
            });

            return;
        }

        const property = name.split('-')[0];
        const field = name.split('-')[1];
        const index = customFields.findIndex((cf) => cf.property === property);
        const customField = { ...customFields[index] };
        const hasData = customField?.hasData;

        if (field === 'type' && hasData && !skipConfirm) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
            });

            tempCustomFieldData.current = { name, value };
            return;
        }

        customField[field] = field !== 'displayed' ? value : !customField[field];

        dispatch({
            type: ACTION_TYPES.SET_CUSTOM_FIELD,
            value: {
                index,
                customField,
            },
        });
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
        });
    };

    const saveCustomFields = () => {
        const input = [];
        let failure = false;

        for (let i = 0; i < customFields.length; i += 1) {
            const {
                entityMetadataId,
                label,
                type,
                displayed,
            } = customFields[i];

            if (displayed && StringUtils.isEmpty(label)) {
                failure = true;
                ModalUtils.errorMessage(null, 'Please make sure a label is set for any custom field to display');
                break;
            }

            input.push({
                entityMetadataId,
                label,
                type,
                displayed,
            });
        }

        if (failure) return;
        saveMetadata({
            variables: {
                input,
            },
        });
    };

    const saveUserTabs = () => {
        const defaultLot = userInformation?.defaultLot || userInformation?.usersLot;
        const input = [
            {
                value: invUserTabName1 || '',
                key: INV_USER_TAB_NAME_1,
                lotName: defaultLot,
            },
            {
                value: invUserTabName2 || '',
                key: INV_USER_TAB_NAME_2,
                lotName: defaultLot,
            },
        ];

        saveUserTabNames({
            variables: {
                input,
            },
        });
    };

    const renderTypeDropdown = (record) => {
        const options = [
            {
                key: uuid(),
                text: 'Alphanumeric',
                value: DataType.STRING.toUpperCase(),
            },
            {
                key: uuid(),
                text: 'Money',
                value: DataType.MONEY.toUpperCase(),
            },
            {
                key: uuid(),
                text: 'Date',
                value: DataType.DATE.toUpperCase(),
            },
            {
                key: uuid(),
                text: 'Dropdown',
                value: DataType.COMBO.toUpperCase(),
            },
        ];

        return (
            <TextField
                select
                name={`${record.property}-type`}
                value={record.type || ''}
                onChange={(e) => onChange(e)}
            >
                {options.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                        {item.text}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: '',
            dataKey: 'number',
            width: 50,
            // eslint-disable-next-line react/prop-types
            cellRenderer: ({ rowIndex }) => (
                <span>{rowIndex + 1}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Label',
            dataKey: 'label',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    (
                        <Form.Control
                            type="text"
                            size="sm"
                            name={`${record.property}-label`}
                            value={record.label}
                            onChange={onChange}
                        />
                    )
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Type',
            dataKey: 'type',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return renderTypeDropdown(record);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Display',
            dataKey: 'display',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <Checkbox
                        color="primary"
                        value="selected"
                        name={`${record.property}-displayed`}
                        style={{ padding: 0 }}
                        checked={record.displayed}
                        onChange={onChange}
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Grid container className={classes.box}>
                <Grid item>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Custom User Tabs</Form.Label>
                        <Divider />
                    </Form.Group>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>User Tab Name 1</Form.Label>
                            <Form.Control
                                className={classes.input}
                                type="text"
                                name="invUserTabName1"
                                value={invUserTabName1}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>User Tab Name 2</Form.Label>
                            <Form.Control
                                className={classes.input}
                                type="text"
                                name="invUserTabName2"
                                value={invUserTabName2}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <If condition={writable}>
                    <Grid container>
                        <Grid item lg={6} md={8} xs={12}>
                            <Form.Group className={classes.customTabsSave} as={Col}>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    disabled={
                                        StringUtils.isEmpty(invUserTabName1)
                                        || StringUtils.isEmpty(invUserTabName2)
                                    }
                                    onClick={saveUserTabs}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </If>
            </Grid>
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Fields</Form.Label>
                        <Divider />
                    </Form.Group>
                </Grid>
                <Grid item lg={11} xs={12}>
                    <Form.Group as={Col}>
                        <div className={classes.tableContainer}>
                            <VirtualTable
                                loading={inventoryMetaDataLoading}
                                rowHeight={45}
                                totalRecords={customFields.length}
                                data={customFields}
                                columns={getColumns()}
                            />
                        </div>
                    </Form.Group>
                </Grid>
                <If condition={writable}>
                    <Grid item className={classes.customFieldsSave} lg={11} xs={12}>
                        <Grid item>
                            <Form.Group as={Col}>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    onClick={saveCustomFields}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </If>
            </Grid>
            <ConfirmDialog
                title="Attention!"
                description={`
                    Changing the Field Type may cause data issues.
                    Example: If you are using dollar amounts and change to Text, then you can’t get a subtotal for this field.
                    Continue?
                `}
                open={confirmDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onConfirmDialogClose}
                onClickSecondary={onConfirmDialogClose}
                onClickPrimary={() => {
                    const { name, value } = tempCustomFieldData.current;
                    onChange({ target: { name, value } }, true);
                    onConfirmDialogClose();
                }}
            />
        </>
    );
};

CustomFieldsSettings.propTypes = {
    writable: PropTypes.bool.isRequired,
};

export default CustomFieldsSettings;

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import Emojic from 'components/widgets/Emoji';
import { ReactComponent as Send } from 'assets/send.svg';
import ConversationStyles from 'styles/modules/ConversationStyles';
import KeyStore from 'utils/KeyStore';

// Material UI
// import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
    Box, Tooltip, IconButton, Input, Button,
} from '@material-ui/core';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

// Material UI
import GraphqlClient from 'services/apollo/GraphQLClient';
import ConversationMutate from 'services/graphQL/mutate/ConversationMutate';

const styles = (theme) => ConversationStyles.messageAdd(theme);

class MessageAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            textMessage: '',
            loadingRequest: false,
        };

        this.graphqlClient = new GraphqlClient();
        this.initBind();
    }

    onChangeMessage(value) {
        this.setState({
            textMessage: value,
        });
    }

    onKeyDownInputMessage(e) {
        const { state: { textMessage } } = this;

        if (e.keyCode === 13 && !StringUtils.isEmpty(textMessage)) {
            this.sendMessage();
        }
    }

    onJoinConversation() {
        const { props: { conversationId } } = this;
        const input = {
            conversationId,
        };

        this.setState({ loadingRequest: true });

        this.graphqlClient
            .mutate(ConversationMutate.JOIN_TO_CONVERSATION, input)
            .then((response) => {
                const { data, graphQLErros } = response;
                if (graphQLErros) {
                    // TODO: Add graphql errors
                    return;
                }

                if (data && data.joinToConversation) {
                    // TODO: add snackbar
                }
            }).finally(() => {
                this.setState({ loadingRequest: false });
            });
    }

    initBind() {
        this.addEmojic = this.addEmojic.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.toggleEmojic = this.toggleEmojic.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.onJoinConversation = this.onJoinConversation.bind(this);
        this.onKeyDownInputMessage = this.onKeyDownInputMessage.bind(this);
    }

    toggleEmojic() {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
    }

    sendMessage() {
        const { state: { textMessage }, props: { conversationId } } = this;
        const input = {
            id: conversationId,
            textMessage: {
                content: {
                    text: textMessage,
                },
            },
        };

        this.clearInputMessage();

        // TODO: Add graphql errors and check message status
        // Currently the sms are added by subscription (SMS will be added from this method when the states of 'pending' and 'sent' are included)
        this.graphqlClient
            .mutate(ConversationMutate.ADD_CRM_MESSAGE, input)
            .then();
    }

    clearInputMessage() {
        this.setState({
            textMessage: '',
        });
    }

    addEmojic(value) {
        const { state: { textMessage } } = this;
        const currentValue = textMessage + value;

        this.setState({
            textMessage: currentValue,
        });
    }

    render() {
        const {
            props: { classes, idUser },
            state: { open, textMessage, loadingRequest },
        } = this;
        const disableSendMessage = StringUtils.isEmpty(textMessage);
        const assignee = new KeyStore().getUserId();
        const showButtonJoin = !idUser || idUser !== assignee;
        const disabledButton = (idUser === assignee) || loadingRequest;
        // TODO: Remove this when functionality is added
        const hidden = true;

        return (
            <Box
                className={classes.root}
            >
                {showButtonJoin && (
                    <Box className={classes.joinConversation}>
                        <Button
                            fullWidth
                            disabled={disabledButton}
                            className={classes.buttonJoin}
                            onClick={this.onJoinConversation}
                        >
                            Join Conversation
                        </Button>
                    </Box>
                )}
                <Box
                    flexGrow={1}
                >
                    {open && (
                        <Emojic
                            onChange={this.addEmojic}
                            onClose={this.toggleEmojic}
                        />
                    )}
                    <Input
                        onChange={(e) => this.onChangeMessage(e.target.value)}
                        fullWidth
                        value={textMessage}
                        disableUnderline
                        onKeyDown={this.onKeyDownInputMessage}
                        placeholder="Entre your message"
                    />
                </Box>
                <Tooltip title="Emojic">
                    <IconButton
                        onClick={this.toggleEmojic}
                        className={classes.iconButton}
                    >
                        <SentimentVerySatisfiedIcon />
                    </IconButton>
                </Tooltip>
                {!hidden && (
                    <Tooltip title="Attach file">
                        <IconButton className={classes.iconButton}>
                            <AttachFileIcon className={classes.attachFileIcon} />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Press enter to send a new sms">
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disableSendMessage}
                            className={classes.iconButtonSendMessage}
                            onClick={this.sendMessage}
                        >
                            <Send />
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        );
    }
}

MessageAdd.propTypes = {
    idUser: PropTypes.number,
    conversationId: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

MessageAdd.defaultProps = {
    idUser: '',
};

export default withStyles(styles)(MessageAdd);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';
import { ACTIVITY_ACTION_TYPE } from 'components/modules/crm/activities/ActivityEnum';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    minsk: {
        color: theme.palette.text.minsk,
    },
    milestone: {
        '& span': {
            marginRight: theme.spacing(0.7),
        },
    },
}));

const getHeader = (record, classes) => {
    const data = JSON.parse(record?.action?.data) || {};
    const action = data?.action?.toLowerCase();

    switch (record?.action?.type) {
    case ACTIVITY_ACTION_TYPE.VEHICLE_CHANGED_TO_INVENTORY:
    case ACTIVITY_ACTION_TYPE.VEHICLE_CHANGED_TO_MANUALLY:
    case ACTIVITY_ACTION_TYPE.PROCESS_STAGE_UPDATED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                <span>{action}</span>
                <span>to</span>
                <span className={classes.minsk}>{data.to}</span>
            </span>
        );
    case ACTIVITY_ACTION_TYPE.OPPORTUNITY_CREATED:
    case ACTIVITY_ACTION_TYPE.TRADE_IN_ADDED:
    case ACTIVITY_ACTION_TYPE.TRADE_IN_REMOVED:
    case ACTIVITY_ACTION_TYPE.VEHICLE_ADDED:
    case ACTIVITY_ACTION_TYPE.VEHICLE_UPDATED:
    case ACTIVITY_ACTION_TYPE.VEHICLE_REMOVED:
    case ACTIVITY_ACTION_TYPE.QUICK_NOTE:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                {action}
            </span>
        );
    case ACTIVITY_ACTION_TYPE.MANAGER_SALESPERSON_ASSIGNED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                {`were ${action}`}
            </span>
        );
    case ACTIVITY_ACTION_TYPE.RECORD_MANAGER_ASSIGNED:
    case ACTIVITY_ACTION_TYPE.SALESPERSON_ASSIGNED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                {`was ${action}`}
            </span>
        );
    case ACTIVITY_ACTION_TYPE.VEHICLE_MANUALLY_ADDED:
    case ACTIVITY_ACTION_TYPE.VEHICLE_MANUALLY_UPDATED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                {`${action} (Manually).`}
            </span>
        );
    case ACTIVITY_ACTION_TYPE.OPPORTUNITY_LOST:
    case ACTIVITY_ACTION_TYPE.OPPORTUNITY_SOLD:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                {`marked as ${action}.`}
            </span>
        );
    case ACTIVITY_ACTION_TYPE.OPPORTUNITY_OPEN:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                was reopened.
            </span>
        );
    case ACTIVITY_ACTION_TYPE.OPPORTUNITY_UPDATED:
    case ACTIVITY_ACTION_TYPE.TRADE_IN_UPDATED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                information was changed.
            </span>
        );
    case ACTIVITY_ACTION_TYPE.LOT_CHANGED:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                <span>{`was ${action}`}</span>
                <span>to</span>
                <span className={classes.minsk}>{data.to}</span>
            </span>
        );
    case ACTIVITY_ACTION_TYPE.SENT_EMAIL:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                <span>{action}</span>
                <span>to</span>
                <span className={classes.minsk}>{data.email}</span>
            </span>
        );
    case ACTIVITY_ACTION_TYPE.SENT_SMS:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                <span>{action}</span>
                <span>to</span>
                <span className={classes.minsk}>{data.cellphone}</span>
            </span>
        );
    case ACTIVITY_ACTION_TYPE.AUTOMATED_RESPONSE_COMPLETE:
        return (
            <span className={classes.milestone}>
                <span className={classes.minsk}>{data.processName}</span>
                finalized.
            </span>
        );
    default:
        return null;
    }
};

const ActionActivityItem = ({ record }) => {
    const classes = useStyles();
    const header = getHeader(record, classes);

    return (
        <div className={clsx(classes.container, 'box-shadow')}>
            <div className={classes.header}>
                <div className="d-flex-center">
                    {header}
                </div>
                <CreatedOnWithAvatar
                    record={record}
                />
            </div>
        </div>
    );
};

ActionActivityItem.propTypes = {
    record: PropTypes.object.isRequired,
};

export default ActionActivityItem;

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import clsx from 'clsx';

import {
    Hidden,
    makeStyles,
    Typography,
    Grid,
} from '@material-ui/core';

import { useLazyQuery, useMutation } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import Loading from 'components/widgets/Loading';
import EquipmentCategory from 'components/modules/inventory/read/panels/equipment/EquipmentCategory';
import EditButtons from 'components/widgets/EditButtons';

import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        padding: '20px 10px',
        justifyContent: 'center',
    },
    header: {
        justifyContent: 'flex-end',
    },
    content: {
        position: 'relative',
        flex: 1,
        overflow: 'auto',
    },
    contentBox: {
        flex: 1,
    },
    noRecord: {
        fontWeight: 'bold',
        fontSize: '1em',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.nevada,
    },
    loader: {
        minHeight: '85vh',
    },
}));

const initState = {
    isEditable: false,
    data: null,
    backup: null,
};
const ACTION_TYPES = {
    SET_DATA: 'setData',
    SET_EDIT_MODE: 'setEditMode',
    ON_CANCEL: 'onCancel',
    ON_CHANGE: 'onChange',
    ON_SAVE: 'onSave',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_DATA:
        return update(state, {
            data: { $set: action.payload },
            backup: { $set: action.payload },
        });
    case ACTION_TYPES.SET_EDIT_MODE:
        return update(state, {
            isEditable: { $set: true },
            backup: { $set: state.data },
        });
    case ACTION_TYPES.ON_CANCEL:
        return update(state, {
            isEditable: { $set: false },
            data: { $set: state.backup },
        });
    case ACTION_TYPES.ON_CHANGE:
        return update(state, {
            data: { $set: action.payload },
        });
    case ACTION_TYPES.ON_SAVE:
        return update(state, {
            backup: { $set: state.data },
            isEditable: { $set: false },
        });
    default:
        return initState;
    }
};
const getVinData = (data) => {
    const standardEquipment = [];
    const optionalEquipment = [];
    const additionalSpec = [];

    data.standardEquipment.forEach((item) => {
        const equipment = {
            ...item,
            name: item.equipmentDescription,
        };
        standardEquipment.push(equipment);
    });
    data.optionalEquipment.forEach((item) => {
        const equipment = {
            ...item,
            name: item.equipmentDescription,
        };
        optionalEquipment.push(equipment);
    });
    data.additionalSpec.forEach((item) => {
        const equipment = {
            ...item,
            name: item.specTitle,
            description: item.specDescription,
            readOnly: true,
        };
        additionalSpec.push(equipment);
    });

    return update(data, {
        standardEquipment: { $set: standardEquipment },
        optionalEquipment: { $set: optionalEquipment },
        additionalSpec: { $set: additionalSpec },
    });
};
const getDataToRender = (data) => {
    const { standardEquipment, optionalEquipment, additionalSpec } = data || {};
    const records = [
        {
            title: 'Standard Equipment',
            section: 'standardEquipment',
            data: standardEquipment || [],
        },
        {
            title: 'Optional Equipment',
            section: 'optionalEquipment',
            data: optionalEquipment || [],
        },
        {
            title: 'Additional Spec',
            section: 'additionalSpec',
            data: additionalSpec || [],
        },
    ];

    return records;
};

const EquipmentTab = ({
    stockNumber,
    vin,
    trim,
    year,
}) => {
    const vinYearStandardization = 1981;
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const variables = {
        vin,
        trim,
        stockNumber,
    };
    const [getEquipments, {
        data: queryResponse,
        error,
        loading,
    }] = useLazyQuery(InventoryQuery.GET_EQUIPMENTS, { variables, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const [createEquipment, { loading: saving }] = useMutation(InventoryMutation.CREATE_EQUIPMENT);

    useEffect(() => {
        if (year && Number(year) >= vinYearStandardization) getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    useEffect(() => {
        if (!loading && queryResponse) {
            dispatch({
                type: ACTION_TYPES.SET_DATA,
                payload: getVinData(queryResponse.decodeVin),
            });
        }
    }, [queryResponse, loading]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);

        return (
            <div className={clsx('d-flex-column', classes.main)}>
                <span className={classes.noRecord}>No records found</span>
            </div>
        );
    }
    if (loading) {
        return (<Loading className={classes.loader} />);
    }

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const onChangeItem = (section, index, value = false) => {
        const newData = update(state.data, {
            [section]: { [index]: { installed: { $set: value } } },
        });

        dispatch({
            type: ACTION_TYPES.ON_CHANGE,
            payload: newData,
        });
    };
    const onSave = async () => {
        if (INVENTORY_VEHICLE_WRITE) {
            try {
                const { data } = state;
                const params = {
                    stockNumber,
                    trim,
                    decodedStyle: data.decodedStyle,
                    standardEquipment: ArrayUtils.omitFields(data.standardEquipment, ['name']),
                    optionalEquipment: ArrayUtils.omitFields(data.optionalEquipment, ['name']),
                    additionalSpec: ArrayUtils.omitFields(data.additionalSpec, ['name', 'description', 'readOnly']),
                    isEditing: data.alreadyDecoded,
                };

                const response = await createEquipment({ variables: params });

                if (response?.data?.createEquipment) {
                    dispatch({
                        type: ACTION_TYPES.ON_SAVE,
                    });

                    ModalUtils.successMessage(null, 'Equipments updated successfully!');
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Equipments'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Equipments'));
            }
        }
    };
    const onCancel = () => {
        dispatch({
            type: ACTION_TYPES.ON_CANCEL,
        });
    };
    const onEdit = () => {
        dispatch({
            type: ACTION_TYPES.SET_EDIT_MODE,
        });
    };
    const records = getDataToRender(state.data);

    return (
        <div className={clsx('d-flex-column', classes.main)}>
            <Hidden mdUp>
                <div className={classes.tabTitle}>
                    <Typography variant="h3" gutterBottom>
                        Equipment
                    </Typography>
                </div>
            </Hidden>
            <div className={clsx('d-flex', classes.header)}>
                <EditButtons
                    onSave={onSave}
                    onCancel={onCancel}
                    onEdit={onEdit}
                    editMode={state.isEditable}
                    hasPermission={INVENTORY_VEHICLE_WRITE && Number(year) >= vinYearStandardization}
                    saving={saving}
                    loading={loading}
                />
            </div>
            <div className={clsx('d-flex', classes.content)}>
                <Grid container>
                    {!loading && (
                        records.map((item, index) => (
                            <Grid item xs={12} md={4} key={`grid-${index}`}>
                                <EquipmentCategory
                                    record={item}
                                    key={`key-${index}`}
                                    isEditable={state.isEditable}
                                    onChangeItem={onChangeItem}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </div>
        </div>
    );
};

EquipmentTab.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    vin: PropTypes.string,
    trim: PropTypes.string,
    year: PropTypes.string,
};

EquipmentTab.defaultProps = {
    vin: '',
    trim: null,
    year: '',
};

export default EquipmentTab;

import React, { Component } from 'react';

// Others
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import PortfolioService from 'services/modules/PortfolioService';
import PortfolioSettingMap from 'services/mapData/PortfolioSettingMap';
import GraphQLClient from 'services/apollo/GraphQLClient';

const PortfolioSettingsListContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.portfolioService = new PortfolioService();
        this.initBind();
    }

    /* eslint-disable react/state-in-constructor */
    state = {
        openDialog: false,
        openConfirmationDialog: false,
        isEditingPortfolio: false,
        selectedPortfolioId: null,
        searchTerm: '',
        tableContent: {
            records: [],
            tableLength: 0,
            loading: true,
            limit: 50,
            sortable: true,
            desc: false,
            orderBy: 'portfolioId',
        },
    }

    componentDidMount() {
        this.getServicesData();
    }

    onSave(input) {
        const { state: { isEditingPortfolio } } = this;

        if (isEditingPortfolio) {
            this.updatePortfolio(input);
        } else {
            this.createPortfolio(input);
        }
    }

    onDefault() {
        const { selectedPortfolioId } = this.state;

        this.portfolioService.setDefaultPortfolio({ id: selectedPortfolioId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.setDefaultPortfolio) {
                    ModalUtils.successMessage(null, 'Portfolio was set as default successfully');
                    this.getServicesData(true);
                }
            });
    }

    onSearch(searchTerm) {
        this.setState({ searchTerm }, () => {
            this.getServicesData(true);
        });
    }

    setSelectedPortfolioId(selectedPortfolioId) {
        this.setState({ selectedPortfolioId });
    }

    getServicesData(isSearching = false) {
        const { state } = this;

        if (isSearching) {
            this.setState({
                tableContent: {
                    records: [],
                    tableLength: 0,
                    loading: true,
                    limit: 50,
                    sortable: true,
                    desc: false,
                },
            });
        } else {
            this.setState({
                tableContent: Object.assign(state.tableContent, {
                    loading: true,
                }),
            });
        }

        const {
            searchTerm,
        } = state;

        const filter = {
            searchTerm,
        };

        this.portfolioService.getSettingList(filter)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.listPortfolios) {
                    const { listPortfolios, totalCount } = data;

                    const currentRecords = isSearching || (isSearching && searchTerm === '')
                        ? []
                        : state.tableContent.records;

                    listPortfolios.forEach((listPortfolio) => {
                        currentRecords.push(listPortfolio);
                    });

                    this.setState({
                        tableContent: {
                            records: currentRecords,
                            tableLength: totalCount,
                            sortable: true,
                            desc: state.tableContent.desc,
                            orderBy: 'portfolioId',
                            loading: false,
                            limit: 50,
                        },
                    });
                } else {
                    this.setState(
                        (prevState) => ({
                            selectedPortfolioId: null,
                            tableContent: Object.assign(prevState.tableContent, {
                                records: [],
                                tableLength: 0,
                            }),
                        }),
                    );
                }
            })
            .finally(() => {
                this.setState(
                    (prevState) => ({
                        selectedPortfolioId: null,
                        tableContent: Object.assign(prevState.tableContent, {
                            loading: false,
                            limit: 50,
                        }),
                    }),
                );
            });
    }

    deletePortfolio() {
        const { selectedPortfolioId } = this.state;

        this.portfolioService.deletePortfolio({ id: selectedPortfolioId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.deletePortfolio) {
                    ModalUtils.successMessage(null, 'Portfolio deleted successfully');
                    this.getServicesData(true);
                }
            });
    }

    createPortfolio(input) {
        const inputCreate = PortfolioSettingMap.mapPortfolioSettingToSave(input);

        this.portfolioService.createPortfolio({ input: inputCreate })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.addPortfolio) {
                    ModalUtils.successMessage(null, 'Portfolio created successfully');
                    this.closePortfolioDialog();
                    this.getServicesData(true);
                }
            });
    }

    updatePortfolio(input) {
        const { selectedPortfolioId } = this.state;
        const inputUpdate = PortfolioSettingMap.mapPortfolioSettingToUpdate(input, selectedPortfolioId);

        this.portfolioService.updatePortfolio(inputUpdate)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updatePortfolio) {
                    ModalUtils.successMessage(null, 'Portfolio updated successfully');
                    this.closePortfolioDialog();
                    this.getServicesData(true);
                }
            });
    }

    openPortfolioDialog(isEditing) {
        this.setState({
            openDialog: true,
            isEditingPortfolio: isEditing,
        });
    }

    closePortfolioDialog() {
        this.setState({ openDialog: false });
    }

    openConfirmDialog() {
        this.setState({
            openConfirmationDialog: true,
        });
    }

    closeConfirmDialog() {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.deletePortfolio = this.deletePortfolio.bind(this);
        this.onDefault = this.onDefault.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.createPortfolio = this.createPortfolio.bind(this);
        this.updatePortfolio = this.updatePortfolio.bind(this);
        this.openPortfolioDialog = this.openPortfolioDialog.bind(this);
        this.closePortfolioDialog = this.closePortfolioDialog.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.setSelectedPortfolioId = this.setSelectedPortfolioId.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                openPortfolioDialog={this.openPortfolioDialog}
                closePortfolioDialog={this.closePortfolioDialog}
                openConfirmDialog={this.openConfirmDialog}
                closeConfirmDialog={this.closeConfirmDialog}
                setSelectedPortfolioId={this.setSelectedPortfolioId}
                onEnableDecoder={this.onEnableDecoder}
                onSearch={this.onSearch}
                onDefault={this.onDefault}
                deletePortfolio={this.deletePortfolio}
                onSave={this.onSave}
            />
        );
    }
};

export default PortfolioSettingsListContainer;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Permission from 'utils/enum/Permissions';
import { makeStyles } from '@material-ui/core/styles';
import LabelValue from 'components/widgets/LabelValue';
import {
    Grid, Typography, Button, IconButton,
} from '@material-ui/core';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';

// Helpers
import KeyStore from 'utils/KeyStore';
import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import TradeInForm from 'components/modules/lead/create/TradeInForm';
import clsx from 'clsx';

const keyStore = new KeyStore();
const useStyles = makeStyles((theme) => ({
    paper: {
        background: theme.palette.background.white,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.text.butterflyBush,
    },
    hidden: {
        display: 'none',
    },
    button: {
        '&:disabled svg g': {
            fill: `rgba(${theme.palette.rgb.black},0.26)`,
        },
    },
}));
const labelWidth = 150;
const placeholder = '--';

const isEmpty = (value, defaultValue) => (!value || value === '0' || value === defaultValue);

const hasTrade = (record) => {
    const {
        tradeVin, tradeYear, tradeMake, tradeModel, tradeTrim, tradeMiles, tradeOffer, tradePayoff, tradePayoffDate, tradeACV, tradeColor, tradeLeinHolder,
    } = record;

    return !isEmpty(tradeVin)
        || !isEmpty(tradeYear, 'Select Year')
        || !isEmpty(tradeMake, 'Select Make')
        || !isEmpty(tradeModel, 'Select Model')
        || !isEmpty(tradeTrim, 'Select Trim')
        || !isEmpty(tradeMiles, 0)
        || !isEmpty(tradeOffer, 0)
        || !isEmpty(tradePayoff, 0)
        || !isEmpty(tradePayoffDate, null)
        || !isEmpty(tradeACV, null)
        || !isEmpty(tradeColor, 0)
        || !isEmpty(tradeLeinHolder, '');
};

const TradeIn = ({ record, crmId, loading }) => {
    const classes = useStyles();
    const CRM_LEAD_TRADE_IN_WRITE = keyStore.hasPermission(Permission.CRM_LEAD_TRADE_IN_WRITE);
    const [open, setOpen] = useState(false);
    const miles = record.tradeMiles != null ? NumberUtils.applyCurrencyFormat(record.tradeMiles, '0,0') : placeholder;
    const tradeACV = NumberUtils.applyCurrencyFormat(record.tradeACV);
    const payoff = NumberUtils.applyCurrencyFormat(record.tradePayoff);
    const lienHolder = record.tradeLeinHolder || placeholder;
    const payoffDate = record.tradePayoffDate != null ? DateUtils.format(record.tradePayoffDate) : placeholder;
    const showTrade = hasTrade(record);

    const onClose = () => {
        setOpen(false);
    };

    const onOpen = () => {
        setOpen(true);
    };

    let button = null;

    if (CRM_LEAD_TRADE_IN_WRITE) {
        if (!showTrade) {
            button = (
                <IconButton
                    size="small"
                    onClick={onOpen}
                    disabled={loading}
                    aria-label="Add Trade-In"
                    className={classes.button}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            );
        } else {
            button = (
                <Button
                    color="primary"
                    size="small"
                    onClick={onOpen}
                    disabled={loading}
                    startIcon={<BorderColorOutlinedIcon />}
                >
                    Edit
                </Button>
            );
        }
    }

    return (
        <div className={classes.paper}>
            <div>
                <div className="d-flex-space-between">
                    <Typography variant="h5" className={classes.title}>Trade-In</Typography>
                    {button}
                </div>
                <Grid container className={clsx(classes.top, { [classes.hidden]: !showTrade })}>
                    <Grid item component="dl" xs={6}>
                        <LabelValue
                            label="VIN"
                            value={record.tradeVin || placeholder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Year"
                            value={record.tradeYear || placeholder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Make"
                            value={record.tradeMake || placeholder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Model"
                            value={record.tradeModel || placeholder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Trim"
                            value={record.tradeTrim || placeholder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Miles"
                            value={miles}
                            labelWidth={labelWidth}
                        />
                    </Grid>
                    <Grid item component="dl" xs={6}>
                        <LabelValue
                            label="Trade ACV"
                            value={tradeACV}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Payoff"
                            value={payoff}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Lien Holder"
                            value={lienHolder}
                            labelWidth={labelWidth}
                        />
                        <LabelValue
                            label="Payoff Date"
                            value={payoffDate}
                            labelWidth={labelWidth}
                        />
                    </Grid>
                </Grid>
            </div>
            {open && (
                <TradeInForm
                    open={open}
                    crmId={crmId}
                    record={record}
                    onClose={onClose}
                />
            )}
        </div>
    );
};

TradeIn.propTypes = {
    crmId: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default TradeIn;

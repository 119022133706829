/* eslint-disable import/prefer-default-export */
const DIRECTION = Object.freeze({
    OUTGOING: 'Outgoing',
    INCOMING: 'Incoming',
});

const STATUS = Object.freeze({
    DELIVERED: 'Delivered',
    READ: 'Read',
    RECEIVED: 'Received',
});

export { DIRECTION, STATUS };

import { gql } from '@apollo/client';

export default class NotesSubscription {
    static NOTE_ADDED = gql`
        subscription noteAdded($codeType: String!, $code: Int!) {
            noteAdded(codeType: $codeType, code: $code) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;

    static NOTE_OPPORTUNITY_ADDED = gql`
        subscription noteOpportunityAdded($opportunityId: ID!) {
            noteOpportunityAdded(opportunityId: $opportunityId) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;
}

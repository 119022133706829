import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { PartsPurchaseSubModules } from 'utils/enum/PartsPurchaseEnum';

export default class PartsUtils {
    static getIndexFromList = (list, selected) => {
        const tabIndex = list.indexOf(selected);
        if (tabIndex !== -1) return tabIndex;

        return 0;
    };

    static getSubModuleList = () => {
        const subModules = [];
        const keyStore = new KeyStore();

        const PARTS_READ = keyStore.hasPermission(Permission.PARTS_READ);
        const PARTS_TICKET_READ = keyStore.hasPermission(Permission.PARTS_TICKET_READ);
        const PURCHASE_READ = keyStore.hasPermission(Permission.PURCHASE_READ);
        const PURCHASE_APPROVAL_READ = keyStore.hasPermission(Permission.PURCHASE_APPROVAL_READ);

        if (PARTS_READ) subModules.push(PartsPurchaseSubModules.PARTS);
        if (PARTS_READ) subModules.push(PartsPurchaseSubModules.REORDER_PARTS);
        if (PARTS_TICKET_READ) subModules.push(PartsPurchaseSubModules.PARTS_TICKET);
        if (PURCHASE_READ) subModules.push(PartsPurchaseSubModules.PURCHASE_ORDER);
        if (PARTS_READ) subModules.push(PartsPurchaseSubModules.PARTS_REQUEST);
        if (PURCHASE_APPROVAL_READ) subModules.push(PartsPurchaseSubModules.PURCHASE_APPROVAL);

        return subModules;
    };
}

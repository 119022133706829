import { gql } from '@apollo/client';

export default class AccountPayableMutation {
static CREATE_INVOICE = gql`
  mutation createInvoice($record: InvoiceInput!) {
    createInvoice(record: $record)
  }
`;

static UPDATE_INVOICE = gql`
  mutation updateInvoice($record: InvoiceInput!) {
    updateInvoice(record: $record)
  }
`;

static VOID_INVOICE = gql`
mutation voidInvoice($invoiceId: Int!, $forceVoid: Boolean!, $comment: String, $dateToPost: Date, $forceFromSold: Boolean) {
  voidInvoice(invoiceId: $invoiceId, forceVoid: $forceVoid, comment: $comment, dateToPost: $dateToPost, forceFromSold: $forceFromSold){
    success
    lockedDate
    isLockedDate
  }
}`;

static PRINT_INVOICE_LIST = gql`
mutation printInvoiceList(  
  $sort: [SortField]
  $filter: InvoiceFilter
  ) {
  printInvoiceList(sort: $sort, filter: $filter)
}`;
}

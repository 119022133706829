import React, { Component } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import DrawerStyles from 'styles/widgets/DrawerStyles';

// Material Ui
import { withStyles, Drawer } from '@material-ui/core';

const styles = (theme) => DrawerStyles.getStyle(theme, 340);

class DrawerContainer extends Component {
    render() {
        const { props: { classes, open }, props } = this;

        return (
            <Drawer
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                variant="permanent"
                anchor="right"
                open={open}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                {props.children}
            </Drawer>
        );
    }
}

DrawerContainer.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DrawerContainer);

import { gql } from '@apollo/client';

export default class PortfolioMutation {
    static CREATE_PORTFOLIO = gql`
        mutation addPortfolio($input: PortfolioInput) {
            addPortfolio(input: $input){
                portfolioId
            }
        }
    `;

    static UPDATE_PORTFOLIO = gql`
        mutation updatePortfolio(
            $id: Int!
            $input: PortfolioInput
        ) {
            updatePortfolio(id: $id, input: $input){
                portfolioId
            }
        }
    `;

    static SET_DEFAULT_PORTFOLIO = gql`
        mutation setDefaultPortfolio(
            $id: Int!
        ) {
            setDefaultPortfolio(id: $id)
        }
    `;

    static DELETE_PORTFOLIO = gql`
        mutation deletePortfolio(
            $id: Int!
        ) {
            deletePortfolio(id: $id)
        }
    `;
}

export default {
    SERVICE_SETTINGS_READ: 'service.settings:read',
    SERVICE_SETTINGS_WRITE: 'service.settings:write',
    SERVICE_READ: 'service:read',
    SERVICE_OPEN: 'service:open',
    SERVICE_EDIT: 'service:edit',
    SERVICE_VOID: 'service:void',
    SERVICE_TRANSFER: 'service:transfer',
    SERVICE_EDIT_ADVISOR: 'service:edit_advisor',
    SERVICE_FLAGS: 'service:flags',
    SERVICE_JOB_LABOR_HOURS: 'service.job:labor_hours',
    SERVICE_JOBS_OVERRIDE_LABOR_RATE: 'service.job:override_labor_rate',
    SERVICE_JOBS_OVERRIDE_SUBLET_PRICE: 'service.job:override_sublet_price',
    SERVICE_JOBS_PARTS_ADD: 'service.job.parts:add_parts',
    SERVICE_JOBS_PARTS_REMOVE: 'service.job.parts:remove_parts',
    SERVICE_JOBS_PARTS_OVERRIDE_PRICE: 'service.job.parts:override_part_price',
};

/* eslint-disable no-unused-vars */
import palette from 'styles/theme/palette';

export default class BasicStyles {
    static basicForm(theme = {}) {
        return ({
            formTiny: {
                fontSize: 12,
            },
            formSmall: {
                fontSize: 14,
            },
            formNormal: {
                fontSize: 16,
            },
            fontBold: {
                fontWeight: 'bold',
            },
            divScrollable: {
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '100%',
            },
            compactForm: {
                '& .form-group': {
                    marginBottom: '5px',
                },
                '& .form-label, & .form-check-label': {
                    fontSize: '12px',
                    marginBottom: '2px',
                    fontWeight: '500',
                },
            },
            padding15: {
                padding: '15px',
            },
            paddingSides15: {
                padding: '0 15px',
            },
            paddingTop15: {
                paddingTop: '15px',
            },
            removePaddingGroup: {
                '& .form-group.col': {
                    padding: 0,
                },
            },
        });
    }

    static containerDialogProcess(theme = {}) {
        return ({
            dialogContent: {
                padding: 0,
                height: '100%',
            },
            splitContainer: {
                height: '100%',
            },
            appBar: {
                position: 'relative',
            },
            centerItemsInToolbar: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 0,
            },
            buttonSpacingInToolbar: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonSave: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                },
                [theme.breakpoints.down('xs')]: {
                    width: '82%',
                },
            },
            titleInToolbar: {
                marginRight: theme.spacing(5),
                flex: 1,
                color: theme.palette.text.white,
            },
            titleInToolbarDefault: {
                marginRight: theme.spacing(5),
                flex: 1,
            },
            margin5: {
                marginRight: theme.spacing(5),
            },
        });
    }

    static dialogForm(theme = {}) {
        return ({
            toolbar: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            appBar: {
                position: 'relative',
            },
            appBarMove: {
                position: 'relative',
                cursor: 'move',
            },
            title: {
                marginRight: theme.spacing(5),
                flex: 1,
                color: theme.palette.text.white,
            },
            margin5: {
                marginRight: theme.spacing(5),
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonWhite: {
                color: theme.palette.text.white,
                borderColor: '#808080',
            },
            buttonDisable: {
                color: `${theme.palette.text.whiteTransparent} !important`,
            },
            marginleft20: {
                marginLeft: '20px',
            },
            containerSplit: {
                flexDirection: 'column',
                overflow: 'auto',
                padding: '20px',
            },
            containerSplit2: {
                flexDirection: 'column',
                overflow: 'auto',
                padding: '20px 5px',
            },
            dialogContent: {
                height: '90vh',
                padding: 0,
            },
            centerItems: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 0,
            },
            buttonClear: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.warning.dark,
                },
            },
            buttonNew: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                },

            },
            buttonSave: {
                backgroundColor: theme.palette.secondaryInfo.main,
                color: theme.palette.secondaryInfo.contrastText,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: theme.palette.secondaryInfo.dark,
                },
            },
            buttonCancel: {
                color: theme.palette.text.stormGray,
                backgroundColor: theme.palette.cancel.main,
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            buttonDelete: {
                color: theme.palette.error.main,
            },
            noPaddingSides: {
                paddingLeft: 0,
                paddingRight: 0,
            },
            noPadding: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            },
            columnHeaderStyle: {
                backgroundColor: theme.palette.background.cornFlowerBlueTrans,
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
            table: {
                maxHeight: 'calc(50vh)',
                minHeight: '450px',
                '& .rt-td': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
            cashierCheckBox: {
                fontSize: '0.875rem',
                marginTop: '5px',
                marginLeft: '10px',
            },
            tablePanel: {
                paddingBottom: '25px',
            },
            splitOne: {
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
            },
            autocompleteSM: {
                backgroundColor: theme.palette.background.white,
                '& > div': {
                    margin: 0,
                    '&:hover': {
                        backgroundColor: `${theme.palette.background.white} !important`,
                        borderColor: `${theme.palette.border.malibu} !important`,
                        outline: 0,
                        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%) !important',
                    },
                },
            },
            fullWidth: {
                width: '100%',
            },
            badgeCleared: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
            },
            badgeVoid: {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.white,
            },
            badgeApproved: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
            },
            badgePrinted: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
            },
            fontSmall: {
                fontSize: '12px',
                fontWeight: 'bold',
            },
            verticalSplit: {
                flexDirection: 'column',
                height: '100%',
            },
            paddingSides15: {
                paddingLeft: 15,
                paddingRight: 15,
            },
            date: {
                height: '2rem',
            },
            textRight: {
                textAlign: 'right',
            },
            dialogContentGeneralJournal: {
                height: '90vh',
                '& > div:first-child': {
                    height: '100%',
                },
                padding: 0,
            },
            borderTop: {
                borderTop: `1px solid ${palette.border.gallery}`,
            },
            selectSM: {
                padding: 0,
                height: 'calc(1.5em + 0.75rem + 3.5px)',
                '& .css-yk16xz-control': {
                    borderColor: 'transparent',
                },
                '& > *': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
                '& > .select__control .select__dropdown-indicator': {
                    padding: '0px 5px',
                },
            },
            control100: {
                width: '100%',
            },
        });
    }
}

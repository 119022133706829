import React from 'react';
import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { makeStyles, Dialog, DialogContent } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    iframe: {
        height: '100%',
        width: '100%',
    },
    dialogContent: {
        height: '70vh',
        overflow: 'hidden',
        padding: '8px',
    },
}));
const CreditReportDialog = ({ url, open, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            open={open}
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar title="Credit Report" toolbarSize="sm" onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <iframe
                    frameBorder="0"
                    allowFullScreen
                    title="Credit Report"
                    className={classes.iframe}
                    src={url}
                />
            </DialogContent>
        </Dialog>
    );
};

CreditReportDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    url: PropTypes.string.isRequired,
};

CreditReportDialog.defaultProps = {
    open: false,
    onClose: () => {},
};

export default CreditReportDialog;

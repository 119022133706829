import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import EditIcon from '@material-ui/icons/Edit';
import {
    size, isNumber, isNaN,
} from 'lodash';
import update from 'immutability-helper';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ModalUtils from 'utils/ModalUtils';
import NumberFormat from 'react-number-format';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import { Box, Paper, Button } from '@material-ui/core';

// React Bootstrap
import { Form, Col } from 'react-bootstrap';

// HTTP
import GraphqlClient from 'services/apollo/GraphQLClient';
import VehicleQuery from 'services/graphQL/query/VehicleQuery';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';

const styles = (theme) => CustomerStyles.customerInformation(theme, fade);

class VehicleForm extends Component {
    constructor(props) {
        super(props);

        this.graphqlClient = new GraphqlClient();
        this.state = {
            vehicle: props.isEditing ? props.record : {
                year: '',
                make: '',
                model: '',
                trim: '',
                color: '',
                notes: '',
                stock: '',
                picture: '',
            },
            messageErrorStock: '',
        };
        this.initBind();
    }

    onBlurStock() {
        const { state: { vehicle: { stock } } } = this;

        if (StringUtils.isEmpty(stock)) {
            this.setState({ messageErrorStock: '' });
        }
    }

    onChangeVehicleValue(value = '', field = '') {
        const { state: { vehicle } } = this;

        this.setState({
            vehicle: update(vehicle, { [field]: { $set: value } }),
        });
    }

    onKeyDownInput(e) {
        const { state: { vehicle: { stock } } } = this;

        if (e.keyCode === 13 && !StringUtils.isEmpty(stock)) {
            if (NumberUtils.isOnlyNumber(stock)) {
                this.setState({ messageErrorStock: '' });
                this.getVehicleByStock(stock);
            } else {
                this.setState({ messageErrorStock: 'Invalid stock number' });
            }
        }
    }

    // TODO: Add notification when can't find a stock number
    getVehicleByStock(stockNumber) {
        const { state: { vehicle } } = this;
        const currentStockNumber = parseInt(stockNumber, 10);
        const input = {
            stockNumber: currentStockNumber,
            active: true,
        };

        this.graphqlClient
            .query(VehicleQuery.GET_VEHICLE_LIST, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                } else {
                    const { listInventory } = data;

                    if (size(listInventory)) {
                        const {
                            year, make, model, trim, color, picture,
                        } = listInventory[0];

                        this.setState({
                            vehicle: Object.assign(vehicle, {
                                year: year || '',
                                make: make || '',
                                model: model || '',
                                trim: trim || '',
                                color: color || '',
                                picture: picture || '',
                            }),
                        });
                    } else {
                        this.setState({
                            vehicle: Object.assign(vehicle, {
                                year: '',
                                make: '',
                                model: '',
                                trim: '',
                                color: '',
                                picture: '',
                            }),
                            messageErrorStock: 'Stock number not found',
                        });
                    }
                }
            });
    }

    initBind() {
        this.saveVehicle = this.saveVehicle.bind(this);
        this.onBlurStock = this.onBlurStock.bind(this);
        this.updateVehicle = this.updateVehicle.bind(this);
        this.onKeyDownInput = this.onKeyDownInput.bind(this);
        this.saveOrEditVehicle = this.saveOrEditVehicle.bind(this);
        this.renderHoverPictureCoponent = this.renderHoverPictureCoponent.bind(this);
    }

    saveOrEditVehicle() {
        const { props: { isEditing, record: { vehicleWishId } } } = this;
        const {
            state: {
                vehicle: {
                    stock, year, make, model, trim, color, notes, picture,
                },
            },
        } = this;
        const currentStockNumber = parseInt(stock, 10);
        // TODO: Add utility to check if data type is number
        const currentPicture = isNumber(currentStockNumber) && !isNaN(currentStockNumber) ? picture : '';

        const input = {
            stockNumber: currentStockNumber,
            year: parseInt(year, 10),
            make,
            model,
            trim,
            color,
            notes,
            picture: currentPicture,
        };

        if (isEditing) {
            this.updateVehicle(input, vehicleWishId);
        } else {
            this.saveVehicle(input);
        }
    }

    saveVehicle(record) {
        const { props: { conversationId, getServicesData, toggleVehicle } } = this;
        const input = {
            conversationId,
            input: record,
        };

        this.graphqlClient
            .mutate(CustomerMutate.SAVE_VEHICLE_WISH, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                } else {
                    const { saveVehicleWish } = data;

                    if (saveVehicleWish && typeof getServicesData === 'function') {
                        getServicesData();
                        toggleVehicle();
                    }
                }
            });
    }

    updateVehicle(record, vehicleWishId) {
        const { props: { getServicesData, toggleVehicle } } = this;
        const input = {
            vehicleWishId,
            input: record,
        };

        this.graphqlClient
            .mutate(CustomerMutate.UPDATE_VEHICLE_WISH, input)
            .then((response) => {
                const { graphQLErrors, data } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                } else {
                    const { updateVehicleWish } = data;
                    if (updateVehicleWish && typeof getServicesData === 'function') {
                        getServicesData();
                        toggleVehicle();
                    }
                }
            });
    }

    renderHoverPictureCoponent() {
        const { props: { classes } } = this;

        return (
            <Paper elevation={0} className={clsx(classes.paper, 'picture-hover')}>
                <Button
                    variant="contained"
                    className={clsx(classes.containedWhite, classes.buttonIcon)}
                >
                    <EditIcon />
                </Button>
                <Button
                    variant="contained"
                    className={clsx(classes.containedWhite, classes.buttonIcon)}
                >
                    <DeleteOutlineIcon />
                </Button>
            </Paper>
        );
    }

    render() {
        const {
            props: { classes, toggleVehicle }, state: {
                messageErrorStock,
                vehicle: {
                    year, make, model, trim, color, notes, stock,
                },
            },
        } = this;
        const disableFields = !StringUtils.isEmpty(stock);

        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <NumberFormat
                            className="form-control"
                            format="####"
                            value={year}
                            onValueChange={(values) => this.onChangeVehicleValue(values.floatValue, 'year')}
                            disabled={disableFields}
                            placeholder="Year"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <NumberFormat
                            className="form-control"
                            maxLength={19}
                            onKeyDown={this.onKeyDownInput}
                            onBlur={this.onBlurStock}
                            onValueChange={(values) => this.onChangeVehicleValue(values.floatValue, 'stock')}
                            value={stock}
                            placeholder="Stock"
                        />
                        {!StringUtils.isEmpty(messageErrorStock) && <Form.Text className={classes.textDanger}>{messageErrorStock}</Form.Text>}
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={disableFields}
                        onChange={(e) => this.onChangeVehicleValue(e.target.value, 'make')}
                        value={make}
                        maxLength={50}
                        placeholder="Make"
                    />
                </Form.Group>
                <Form.Group className={classes.lastFormGroup}>
                    <Form.Control
                        type="text"
                        disabled={disableFields}
                        onChange={(e) => this.onChangeVehicleValue(e.target.value, 'model')}
                        value={model}
                        maxLength={50}
                        placeholder="Model"
                    />
                </Form.Group>
                <Form.Group className={classes.lastFormGroup}>
                    <Form.Control
                        type="text"
                        disabled={disableFields}
                        onChange={(e) => this.onChangeVehicleValue(e.target.value, 'trim')}
                        value={trim}
                        maxLength={50}
                        placeholder="Trim"
                    />
                </Form.Group>
                <Form.Group className={classes.lastFormGroup}>
                    <Form.Control
                        type="text"
                        disabled={disableFields}
                        onChange={(e) => this.onChangeVehicleValue(e.target.value, 'color')}
                        value={color}
                        maxLength={70}
                        placeholder="Color"
                    />
                </Form.Group>
                <Form.Group className={classes.lastFormGroup}>
                    <Form.Control
                        as="textarea"
                        value={notes}
                        onChange={(e) => this.onChangeVehicleValue(e.target.value, 'notes')}
                        className={classes.textArea}
                        maxLength={1000}
                        placeholder="Notes"
                        rows="3"
                    />
                </Form.Group>
                <Box className="footer">
                    <Button
                        variant="contained"
                        onClick={toggleVehicle}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={this.saveOrEditVehicle}
                        className={classes.containedInfo}
                    >
                        Save Vehicle
                    </Button>
                </Box>
            </Form>
        );
    }
}

VehicleForm.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    toggleVehicle: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    conversationId: PropTypes.string.isRequired,
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(VehicleForm);

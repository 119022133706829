import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import Header from 'components/widgets/Header';

const ownStyle = makeStyles((theme) => ({
    containerTitle: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
    containerFullMobile: {
        width: '100%',
        paddingBottom: '10px',
        textAlign: 'center',
        justifyContent: 'center !important',
    },
    containerMobileTitle: {
        paddingTop: '10px',
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    reportTitle: {
        color: theme.palette.text.chambray,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        paddingTop: '5px',
    },
    colorHeader: {
        background: theme.palette.background.default,
    },
}));

const ReportHeader = (props) => {
    const { titleReport, children } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...ownStyle(),
    };

    return (
        <Header className={classes.colorHeader}>
            <div className={clsx(isMobile ? classes.containerFullMobile : classes.containerTitle,
                { [classes.containerMobileTitle]: isMobile })}
            >
                <span className={classes.reportTitle}>
                    {titleReport}
                </span>
            </div>
            <div className={clsx(classes.buttonSpacing, { [classes.containerFullMobile]: isMobile })}>
                {children}
            </div>
        </Header>
    );
};

ReportHeader.propTypes = {
    titleReport: PropTypes.string.isRequired,
    children: PropTypes.node,
};

ReportHeader.defaultProps = {
    children: null,
};

export default ReportHeader;

import { gql } from '@apollo/client';

export default class TecnicianCommissionsMutation {
    static MARK_COMMISSION_AS_PAID = gql`
        mutation markTechnicianCommissionAsPaid($input: [TechnicianCommissionInput!]!) {
            markTechnicianCommissionAsPaid(input: $input)
        }
    `;

    static APPROVE_COMMISSION = gql`
        mutation approveTechnicianCommission($input: [TechnicianCommissionInput!]!, $employeeId: Int!, $payPeriod: String!) {
            approveTechnicianCommission(input: $input, employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static PRINT_TECHNICIAN_COMMISSIONS_FORM = gql`
        mutation printTechnicianCommissionReport($employeeId: Int!, $payPeriod: String!) {
            printTechnicianCommissionReport(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;
}

import { gql } from '@apollo/client';

export default class PayrollAdjustmentsQuery {
    static GET_PAYROLL_ADJUSTMENTS = gql`
        query getPayrollAdjustments(
            $employeeId: Int!
            $payPeriod: String!
            $isDeduction: Boolean
        ) {
            getPayrollAdjustments(
                employeeId: $employeeId
                payPeriod: $payPeriod
                isDeduction: $isDeduction
            ) {
                bdId
                memo
                term
                amount
                description
                controlNumber
                payrollAdditionId
            }
        }
    `;

    static GET_PAYROLL_DB_DESCRIPTION_LIST_BY_TYPE = gql`
        query getPayrollDBListByType($bdType: AdjustmentType!) {
            getPayrollDBListByType(bdType: $bdType) {
                bdId
                description
                isLoan
            }
        }
    `;

    static GET_PAYROLL_DEDUCTIONS = gql`
        query getPayrollDeductions(
            $employeeId: Int!
            $payPeriod: String!
        ) {
            getPayrollDeductions(
                employeeId: $employeeId
                payPeriod: $payPeriod
            ) {
                bdId
                memo
                amount
                description
                controlNumber
                payrollAdditionId
                payrollDeductionsMappingId
            }
        }
    `;
}

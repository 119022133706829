import React, { useState } from 'react';
import Header from 'components/widgets/Header';
import InputSearch from 'components/widgets/InputSearch';
import {
    makeStyles, useTheme, useMediaQuery, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LabelValue from 'components/widgets/LabelValue';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { JournalTypes, ShowJournalType } from 'utils/enum/AccountingEnum';
import DateUtils from 'lib/DateUtils';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const HeaderJournalCommon = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyle();

    const {
        journalType, setParameters, totalCount, labelPostedOption,
    } = props;

    const isJournalTypeInventory = journalType === JournalTypes.INVENTORY;

    const initState = {
        search: '',
        radioOption: isJournalTypeInventory ? ShowJournalType.SHOW_NO_POSTED_ACTIVE : ShowJournalType.SHOW_NO_POSTED,
        fromDate: new Date(DateUtils.subtractAndFormatUTC(new Date(), 1, 'months')),
        toDate: new Date(),
    };
    const [params, setParams] = useState(initState);

    const {
        search,
        radioOption,
        fromDate,
        toDate,
    } = params;

    const onSetParams = (name, value) => {
        const currentParams = {
            ...params,
            [name]: value,
        };

        setParams(currentParams);

        setParameters(currentParams);
    };

    return (
        <Header>
            <div className={clsx(classes.containerFilterSearch, isMobile ? classes.searchFull : '')}>
                <InputSearch
                    customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                    initialSearch={search || ''}
                    onSearch={(val) => onSetParams('search', val)}
                />
                <RadioGroup row value={radioOption} onChange={(e, val) => onSetParams('radioOption', val)}>
                    {isJournalTypeInventory
                    && (
                        <FormControlLabel
                            value={ShowJournalType.SHOW_NO_POSTED_ACTIVE}
                            control={<Radio color="primary" />}
                            label="No Posted (Active)"
                        />
                    )}
                    <FormControlLabel
                        value={ShowJournalType.SHOW_NO_POSTED}
                        control={<Radio color="primary" />}
                        label="No Posted (All)"
                    />
                    <FormControlLabel
                        value={ShowJournalType.SHOW_POSTED}
                        control={<Radio color="primary" />}
                        label={`Posted between ${labelPostedOption}`}
                    />
                </RadioGroup>
                {radioOption === ShowJournalType.SHOW_POSTED && (
                    <>
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={fromDate || new Date()}
                            maxDate={toDate}
                            onChange={(val) => onSetParams('fromDate', val)}
                            placeholderText="mm/dd/yyyy"
                        />
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={toDate || new Date()}
                            // minDate={useDateRange ? fromDate : null}
                            minDate={fromDate}
                            onChange={(val) => onSetParams('toDate', val)}
                            placeholderText="mm/dd/yyyy"
                        />
                    </>
                )}
                <LabelValue
                    label="Items"
                    value={totalCount}
                />
            </div>
        </Header>
    );
};

HeaderJournalCommon.propTypes = {
    journalType: PropTypes.string.isRequired,
    setParameters: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
    labelPostedOption: PropTypes.string.isRequired,
};

HeaderJournalCommon.defaultProps = {
    totalCount: 0,
};

export default HeaderJournalCommon;

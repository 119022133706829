import { gql } from '@apollo/client';

export default class DealsProductQuery {
    static GET_VENDOR_DEAL_PRODUCTS = gql`
        query getVendorProducts {
            getVendorProducts {
            productType
                vendor {
                    vendorId
                    vendorName
                    products {
                        productId
                        productType
                        productName
                    }
                }
            }
        }
    `;
}

/* eslint-disable no-param-reassign */
import React, {
    useEffect,
    useReducer,
} from 'react';
import {
    Grid,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import NumberUtils from 'lib/NumberUtils';
import Table from 'components/widgets/Table';
import Filter from 'components/widgets/Filter';
import Loading from 'components/widgets/Loading';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        height: 'fit-content',
    },
    header: {
        marginBottom: 10,
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    content: {
        flexGrow: 1,
        marginTop: 40,
        height: 'fit-content',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    tableHeight: {
        height: 'calc(50vh - 170px)',
    },
    totalLabel: {
        marginLeft: 50,
    },
    grandTotalLabel: {
        marginLeft: 148,
    },
}));

const ACTION_TYPES = {
    APPLY_FILTER: 'applyFilter',
    SET_STATE_VALUE: 'setStateValue',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: value },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === action.accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: action.accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const PurchasingAgentDealCommissionTable = ({
    items, loadTable, totalPurchasingCommissionAmount,
}) => {
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };

    const initState = {
        filtersApplied: [],
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const { filtersApplied } = state;

    useEffect(() => {
        dispatch({
            value: [],
            field: 'filtersApplied',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    }, [items]);

    const getColumns = () => [{
        Header: 'Date',
        id: 'soldDate',
        minWidth: 16,
        accessor: 'soldDate',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'acct #',
        id: 'accountNumber',
        minWidth: 16,
        accessor: 'accountNumber',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Buyer',
        id: 'buyerName',
        minWidth: 16,
        accessor: 'buyerName',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Sold Vehicle',
        id: 'vehicle',
        minWidth: 22,
        accessor: 'vehicle',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Days after purchase',
        id: 'age',
        minWidth: 16,
        accessor: 'age',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Commissions',
        id: 'commissionAmount',
        minWidth: 16,
        accessor: 'commissionAmount',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Sales Price - Cost',
        id: 'profit',
        minWidth: 16,
        accessor: 'profit',
        className: 'd-flex-justify-center-align-center',
    }];

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const loadColumns = () => {
        const columnNamesExcluded = [
            'checkbox-column', 'isApproved',
        ];
        const columns = getColumns();
        columns.forEach((column) => {
            if (!columnNamesExcluded.includes(column.id)) {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                filterId={column.id}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    const totalCommissionAmount = items.reduce((sum, item) => sum + item.commissionAmount, 0);

    return (
        <div className={classes.content}>
            <FormLabel className={classes.localButtonSpacing}>
                Sold in date span commissions:
            </FormLabel>
            <Grid item xs={12} className={classes.tableHeight}>
                {!loadTable && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {loadTable && (
                    <Table
                        enableRowHover
                        className="-highlight payrollTable"
                        columns={loadColumns()}
                        filtered={filtersApplied}
                        totalRecords={items.length}
                        data={items}
                        defaultFilterMethod={(localFilter, row) => {
                            const id = localFilter.pivotId || localFilter.id;
                            if (typeof localFilter.value === 'object') {
                                return row[id] !== undefined
                                    ? localFilter.value.indexOf(row[id]) > -1
                                    : true;
                            }
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(localFilter.value) > -1
                                : true;
                        }}
                    />
                )}
                <div>
                    <FormLabel className={classes.localButtonSpacing}>
                        {`Vehicle Sold Count: ${items.length}`}
                    </FormLabel>
                    <FormLabel className={classes.totalLabel}>
                        {`Total: ${NumberUtils.applyCurrencyFormat(totalCommissionAmount)}`}
                    </FormLabel>
                </div>
                <FormLabel className={classes.grandTotalLabel}>
                    {`Grand Total: ${NumberUtils.applyCurrencyFormat(totalCommissionAmount + totalPurchasingCommissionAmount)}`}
                </FormLabel>
            </Grid>
        </div>
    );
};

PurchasingAgentDealCommissionTable.propTypes = {
    items: PropTypes.array,
    loadTable: PropTypes.bool,
    totalPurchasingCommissionAmount: PropTypes.number,
};

PurchasingAgentDealCommissionTable.defaultProps = {
    items: [],
    loadTable: false,
    totalPurchasingCommissionAmount: 0,
};

export default PurchasingAgentDealCommissionTable;

import { gql } from '@apollo/client';

export default class NotificationQuery {
    static GET_NOTIFICATION_BY_USER = gql`
        query getNotificationByUser($paging: DataPaging, $type: String!) {
            getNotificationByUser(paging: $paging, type: $type) {
                data
                type
                createdOn
                notificationId
                processRecordId
                userNotification {
                    read
                }
            }
        }
    `;

    static GET_NOTIFICATION_BY_OPPORTUNITIES = gql`
        query getNotificationByOpportunities($opportunities: [ID!]!) {
            getNotificationByOpportunities(opportunities: $opportunities) {
                read
                process
                opportunityId
            }
        }
    `;
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme = {}) => ({
    placeholder: {
        fontSize: '14px',
        padding: theme.spacing(1.5),
        textAlign: 'center',
    },
});

const Placeholder = ({ text, classes }) => (<div className={classes.placeholder}>{text}</div>);

Placeholder.propTypes = {
    text: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

Placeholder.defaultProps = {
    text: null,
};

export default withStyles(styles)(Placeholder);

import React, { Component } from 'react';

// Components and Others
import { map } from 'lodash';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Loading from 'components/widgets/Loading';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AddressContent from 'components/widgets/customer/AddressContent';
import ManageAddressContainer from 'components/containers/widgets/customers/ManageAddressContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, Button,
} from '@material-ui/core';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => CustomerStyles.manageAddress(theme);

class ManageAddress extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    renderCurrentOrMakeCurrentAddress(record) {
        const { props: { setCurrentAddress }, CUSTOMER_WRITE } = this;
        const { customerId, isCurrentAddress, customerAddressId } = record;

        if (isCurrentAddress) {
            return (
                <div className="current-address">
                    Current Address
                </div>
            );
        }

        return (
            <Button
                color="primary"
                size="small"
                className="make-current"
                onClick={() => setCurrentAddress({ customerId, customerAddressId })}
                disabled={!CUSTOMER_WRITE}
            >
                Make Current
            </Button>
        );
    }

    renderItem(record) {
        const {
            props: {
                classes, onOpenConfirm, onEdit,
            },
            CUSTOMER_WRITE,
        } = this;
        const {
            customerAddressId, isCurrentAddress,
        } = record;

        return (
            <Grid container spacing={0} className="row-address" key={customerAddressId}>
                <Grid
                    item
                    className={classes.customer}
                    xs={9}
                >
                    <AddressContent record={record} className={classes.addressContent} />
                </Grid>
                <Grid
                    item
                    className={`${classes.customer} ${classes.actionPanel}`}
                    xs={3}
                >
                    <div>
                        {this.renderCurrentOrMakeCurrentAddress(record)}
                    </div>
                    {CUSTOMER_WRITE && (
                        <div>
                            <Button
                                color="primary"
                                size="small"
                                className="edit"
                                onClick={() => onEdit(record)}
                            >
                                Edit
                            </Button>
                            {isCurrentAddress ? null : (
                                <Button color="primary" size="small" className="delete" onClick={() => onOpenConfirm(customerAddressId)}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    }

    render() {
        const {
            CUSTOMER_WRITE,
            props: {
                open, classes, toggleModal, records,
                openAddress, openConfirm, onCloseConfirm,
                deleteCustomerAddress, load,
            },
        } = this;
        const newAddressButton = CUSTOMER_WRITE ? (
            <Button
                color="primary"
                size="small"
                className={classes.newAddressButton}
                startIcon={<AddIcon />}
                onClick={openAddress}
            >
                Add new Address
            </Button>
        ) : null;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Manage Addresses" onClose={toggleModal} iconSize="sm" secondaryButton={newAddressButton} />
                <DialogContent className={classes.dialogContent}>
                    {map(records, (item) => this.renderItem(item))}
                    {load && <Loading />}
                </DialogContent>
                <DialogActions onClickPrimary={toggleModal} titlePrimary="Close" hiddenSecondaryButton variant="outlined" />
                <ConfirmDialog
                    title="Confirm delete address"
                    description="Are you sure you want to remove this address?"
                    open={openConfirm}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={onCloseConfirm}
                    onClickSecondary={onCloseConfirm}
                    onClickPrimary={deleteCustomerAddress}
                />
            </Dialog>
        );
    }
}

ManageAddress.propTypes = {
    load: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    openAddress: PropTypes.func.isRequired,
    onOpenConfirm: PropTypes.func.isRequired,
    onCloseConfirm: PropTypes.func.isRequired,
    setCurrentAddress: PropTypes.func.isRequired,
    deleteCustomerAddress: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(ManageAddressContainer(ManageAddress));

import { gql } from '@apollo/client';

export default class MailQuery {
    static GET_USER_EMAILS = gql`
        query getUserEmails($input: DataPaging!, $filters: JSON) {
            getUserEmails(input: $input, filters: $filters) {
                total
                data {
                    emailId
                    parentId
                    recordId
                    to
                    threadId
                    from
                    fromName
                    subject
                    bodyPlain
                    messageId
                    inReplyTo
                    incoming
                    status
                    createdOn
                    lastEmailId
                    lastBody
                    lastStatus
                    lastIncoming
                }
            }
        }
    `;

    static GET_EMAIL_THREAD = gql`
        query getEmailThread($threadId: String!) {
            getEmailThread(threadId: $threadId) {
                emailId
                parentId
                recordId
                to
                toFull
                from
                fromName
                subject
                bodyHTML
                bodyPlain
                messageId
                inReplyTo
                incoming
                status
                createdOn
                cc
                bcc
                attachments {
                    emailAttachmentId
                    fileName
                    extension
                    contentType
                    inline
                    url
                }
            }
        }
    `;

    static GET_EMAIL_BY_THREAD = gql`
        query getEmailByThread($threadId: String!) {
            getEmailByThread(threadId: $threadId) {
                emailId
                parentId
                recordId
                to
                toFull
                from
                fromName
                subject
                bodyHTML
                bodyPlain
                messageId
                inReplyTo
                incoming
                status
                createdOn
                cc
                bcc
                attachments {
                    emailAttachmentId
                    fileName
                    extension
                    contentType
                    inline
                    url
                }
            }
        }
    `;

    static GET_EMAIL_OPENED_BY_LIST = gql`
        query getEmailOpenedByList($emailId: String!) {
            getEmailOpenedByList(emailId: $emailId) {
                    recipient
            }
        }
    `;

    static GET_EMAILS_BY_CRMID = gql`
        query getEmailsByCRMId($crmId: ID!, $paginate: DataPaging!) {
            getEmailsByCRMId(crmId: $crmId, paginate: $paginate) {
                total
                data {
                    emailId
                    parentId
                    recordId
                    to
                    threadId
                    from
                    fromName
                    subject
                    bodyPlain
                    messageId
                    inReplyTo
                    incoming
                    status
                    createdOn
                    lastEmailId
                    lastBody
                    toFull
                    cc
                    lastStatus
                    lastIncoming
                }
            }
        }
    `;

    static GET_EMAILS_HISTORY_BY_OPPORTUNITY_ID = gql`
        query getEmailsHistoryByOpportunityId($opportunityId: ID!, $paginate: DataPaging!) {
            getEmailsHistoryByOpportunityId(opportunityId: $opportunityId, paginate: $paginate) {
                total
                data {
                    createdOn
                    incoming
                    bodyPlain
                    createdBy {
                        firstName
                        lastName
                    }
                }
            }
        }
    `;
}

/* eslint-disable no-plusplus */
import moment from 'moment';
import 'moment-timezone';
import KeyStore from 'utils/KeyStore';

const FORMAT = 'MM/DD/YYYY';

export const Period = Object.freeze({
    AM: 'AM',
    PM: 'PM',
});

export const DateFormat = {
    LONG_DATETIME_AMPM: 'ddd DD MMM YY, hh:mm A',
    DEFAULT_DATETIME: 'MM/DD/YYYY HH:mm:ss',
    DEFAULT_DATETIME_WITHOUT_SECONDS: 'MM/DD/YYYY HH:mm',
    DATETIME_WITHOUT_SECONDS: 'MM/DD/YYYY hh:mm A',
    DEFAULT_TIME: 'HH:mm',
    TIME_AMPM: 'hh:mm A',
    DAY_MONTH: 'MMM DD',
    SHORT_DATE: 'YYMMDD',
    SHORT_DATE_WITH_DASHES: 'MM-DD-YYYY',
    DEFAULT_DATE: FORMAT,
    LONG_DATE_TIME_AM_PM: 'ddd MMM DD YY, hh:mm A',
};

export default class DateUtils {
    static isValid(value = null) {
        return moment(value).isValid();
    }

    static getUTCDate() {
        return moment().utc();
    }

    static getUTCDateFormat() {
        return moment().utc().format();
    }

    static toLocal(value = null) {
        if (moment(value).isValid()) {
            return moment(value).local();
        }

        return null;
    }

    static fromNow(value = null) {
        if (moment(value).isValid()) {
            return moment(value).fromNow();
        }

        return null;
    }

    static format(value = null, format = FORMAT) {
        if (moment(value).isValid()) {
            return moment(value).format(format);
        }

        return null;
    }

    static formatUTC(value = null, format) {
        if (moment(value).isValid()) {
            return moment.utc(value).format(format);
        }

        return null;
    }

    static formatToLocal(value = null, format = FORMAT) {
        if (this.isValid(value)) {
            return moment.utc(value).local().format(format);
        }

        return null;
    }

    static subtractAndFormatUTC(value, valueSubtract, type = 'hours') {
        if (moment(value).isValid()) {
            return moment.utc(value).subtract(valueSubtract, type).format();
        }

        return null;
    }

    static calendarWithoutTime(value) {
        if (this.isValid(value)) {
            return moment(value).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'DD/MM/YYYY',
            });
        }

        return null;
    }

    static calendarWithTime(value) {
        if (this.isValid(value)) {
            return moment(value).calendar(null, {
                sameDay: '[Today] hh:mm a',
                nextDay: '[Tomorrow] hh:mm a',
                nextWeek: 'dddd hh:mm a',
                lastDay: '[Yesterday] hh:mm a',
                lastWeek: '[Last] dddd hh:mm a',
                sameElse: `${FORMAT} hh:mm a`,
            });
        }

        return null;
    }

    static calendar(value) {
        if (this.isValid(value)) {
            return moment(value).calendar();
        }

        return null;
    }

    static isSameOrAfter(firstDate, secondDate) {
        if (this.isValid(firstDate) && this.isValid(secondDate)) {
            return moment(firstDate).isSameOrAfter(secondDate);
        }

        return null;
    }

    static getDate() {
        return moment().toDate();
    }

    static add(value, number, type = 'days') {
        if (moment(value).isValid()) {
            return moment(value).add(number, type);
        }

        return null;
    }

    static subtract(value, number, type = 'days') {
        if (moment(value).isValid()) {
            return moment(value).subtract(number, type);
        }

        return null;
    }

    static diff(date1, date2, type = 'days') {
        if (moment(date1).isValid() && moment(date2).isValid()) {
            return moment(date1).diff(date2, type);
        }

        return null;
    }

    static getDateByTimezone(date, timeZone) {
        if (this.isValid(date)) return moment.tz(date, 'UTC').tz(timeZone);

        return date;
    }

    /** return a date formatted without time
     * @param {String} Value
     * @example "2022-03-11T06:00:00.000Z" return return "03/11/2022"
     * @example "2022-03-11T00:00:00.000Z" return return "03/11/2022"
     * @example "Fri Mar 11 2022 10:57:34 GMT-0600 (Central Standard Time)" return "03/11/2022"
    */
    static getOnlyDate(date) {
        if (this.isValid(date)) {
            let currentDate = date;
            if (date.toString().includes('GMT')) { currentDate = date.toISOString(); }

            if (currentDate.includes('T')) {
                const dateSplit = currentDate.split('T');
                return moment(dateSplit[0]).format(FORMAT);
            }
        }

        return null;
    }

    static getOnlyDateString(date) {
        if (this.isValid(date)) {
            let currentDate = date;
            if (date.toString().includes('GMT')) { currentDate = date.toISOString(); }

            if (currentDate.includes('T')) {
                const dateSplit = currentDate.split('T');
                return dateSplit[0];
            }
        }

        return null;
    }

    /** return a date with local time
     * @param {String} Value
     * @example "2022-03-11T06:00:00.000Z" return "2022-03-11T10:57:34.000Z"
     * @example "2022-03-11T00:00:00.000Z" return "2022-03-11T10:57:34.000Z"
     * @example "Fri Mar 11 2022 10:57:34 GMT-0600 (Central Standard Time)" return "Fri Mar 11 2022 10:57:34 GMT-0600 (Central Standard Time)""
    */
    static getDateOverrideToLocalTime(date) {
        if (this.isValid(date)) {
            const currentDate = date;
            if (currentDate.includes('T')) {
                const localTime = new Date().toLocaleTimeString();
                const newDate = new Date(`${date.split('T')[0]} ${localTime}`);
                return newDate;
            }

            return date;
        }

        return null;
    }

    static isSame(firstDate, secondDate, type = 'days') {
        if (this.isValid(firstDate) && this.isValid(secondDate)) {
            return moment(firstDate).isSame(secondDate, type);
        }

        return false;
    }

    static setTimeToZero(date) {
        return moment(date).utcOffset(0);
    }

    static isSameOrBefore(firstDate, secondDate) {
        if (this.isValid(firstDate) && this.isValid(secondDate)) {
            return moment(firstDate).isSameOrBefore(secondDate);
        }

        return null;
    }

    static getFormattedDateInUserTimezone(utcDate, format = DateFormat.DATETIME_WITHOUT_SECONDS) {
        if (!this.isValid(utcDate)) return null;

        let timeZone = moment.tz.guess();
        const userLot = new KeyStore().getSelectedLot();
        if (userLot?.isDefault && userLot?.lotTimeZoneId) {
            timeZone = userLot.lotTimeZoneId;
        }

        return moment(utcDate).tz(timeZone).format(format);
    }

    static getISOStringFromLocal(value) {
        return moment(value).toISOString();
    }

    static splitDate(value) {
        if (!this.isValid(value)) return {};

        return {
            day: Number(moment(value).format('DD')),
            month: Number(moment(value).format('MM')),
            year: Number(moment(value).format('YYYY')),
        };
    }

    static getFirstLastDaysOfGivenMonth(month, year) {
        if (month < 1 || month > 12) return {};
        const date = moment([year, month - 1]);

        return {
            firstDay: moment(date).startOf('month').format('DD'),
            lastDay: moment(date).endOf('month').format('DD'),
        };
    }

    static getUSFormattedDateFromUTC(date, format) {
        if (this.isValid(date)) return this.format(moment(date).utc(), format);

        return date;
    }

    static getMinDate(dates) {
        if (Array.isArray(dates) && dates.length > 0) return moment.min(dates.map((d) => moment(d)));

        return null;
    }

    static getMaxDate(dates) {
        if (Array.isArray(dates) && dates.length > 0) return moment.max(dates.map((d) => moment(d)));

        return null;
    }
}

import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VehicleImage from 'components/widgets/VehicleImage';
import CardVehicleStyles from 'styles/widgets/CardVehicleStyles';

// Material UI
import StarIcon from '@material-ui/icons/Star';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const styles = (theme) => CardVehicleStyles.CardVehicle(theme);

class CardVehicle extends Component {
    constructor(props) {
        super(props);
        this.onSetFavoriteColor = this.onSetFavoriteColor.bind(this);
    }

    onSetFavoriteColor(vehicleWishId, isFavorite) {
        const { props: { setFavoriteVehicle } } = this;

        if (typeof setFavoriteVehicle === 'function') {
            setFavoriteVehicle(vehicleWishId, isFavorite);
        }
    }

    renderRating(vehicleWishId) {
        const { props: { showRating, classes, vehicle: { isFavorite } } } = this;

        if (showRating) {
            if (isFavorite) {
                return (
                    <StarIcon
                        className={classes.favorite}
                        onClick={() => this.onSetFavoriteColor(vehicleWishId, false)}
                    />
                );
            }

            return (
                <StarBorderIcon
                    className={classes.notFavorite}
                    onClick={() => this.onSetFavoriteColor(vehicleWishId, true)}
                />
            );
        }

        return null;
    }

    render() {
        const {
            props: {
                classes,
                className,
                rightSection,
                hoverPictureComponent,
                showRating = true,
                showStock = true,
                vehicle: {
                    stock, year, make, model, trim, vehicleWishId, thumbnail,
                    style,
                },
            },
        } = this;
        const showStockNumber = showStock && stock;
        const modelTrim = `${model || ''} - ${trim || ''}`;

        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.containerImage}>
                    <VehicleImage
                        image={thumbnail}
                        bodyStyle={style}
                        className={classes.image}
                    />
                    {hoverPictureComponent}
                </div>
                <div className={classes.containerDetails}>
                    <div className={classes.box}>
                        <Typography className={classes.textBold}>
                            {year}
                        </Typography>
                        {showStockNumber && (
                            <Typography>
                                Stock:
                                <span className={clsx(classes.textBold, classes.stock)}>{stock}</span>
                            </Typography>
                        )}
                    </div>
                    <Typography>{make}</Typography>
                    <div className={classes.box}>
                        <Typography component="legend">{modelTrim}</Typography>
                        {showRating && this.renderRating(vehicleWishId)}
                        {rightSection}
                    </div>
                </div>
            </div>
        );
    }
}

CardVehicle.propTypes = {
    showStock: PropTypes.bool,
    showRating: PropTypes.bool,
    className: PropTypes.string,
    rightSection: PropTypes.element,
    setFavoriteVehicle: PropTypes.func,
    hoverPictureComponent: PropTypes.element,
    vehicle: PropTypes.oneOfType([PropTypes.object]).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CardVehicle.defaultProps = {
    className: '',
    showRating: false,
    showStock: true,
    hoverPictureComponent: null,
    rightSection: null,
    setFavoriteVehicle: () => {},
};

export default withStyles(styles)(CardVehicle);

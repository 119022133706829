import React, { Component } from 'react';

// Material UI
import {
    Typography, Box, Tooltip, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components and others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { CustomerName } from 'utils/enum/ConversationEnum';
import ConversationStyles from 'styles/modules/ConversationStyles';

// Material icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as PersonAddOutlinedIcon } from 'assets/add.svg';

const styles = (theme) => ConversationStyles.conversationDetailsToolbar(theme);

class ConversationDetailsToolbar extends Component {
    render() {
        const { props: { classes, onToggleDrawer, customerName } } = this;
        const currentCustomerName = StringUtils.isEmpty(customerName) ? CustomerName.ANONYMOUS.toLowerCase() : customerName;
        // TODO: Remove this when functionality is added
        const hidden = true;

        return (
            <Box
                display="flex"
                alignItems="center"
                className={classes.root}
            >
                <Box>
                    <Typography
                        variant="h4"
                        className={classes.customerName}
                        color="textPrimary"
                    >
                        {currentCustomerName}
                    </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box>
                    <Tooltip title="User">
                        <IconButton
                            onClick={() => onToggleDrawer(true, 'customer')}
                        >
                            <PersonAddOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    {!hidden && (
                        <Tooltip title="More options">
                            <IconButton>
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        );
    }
}

ConversationDetailsToolbar.propTypes = {
    customerName: PropTypes.string,
    onToggleDrawer: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ConversationDetailsToolbar.defaultProps = {
    customerName: '',
};

export default withStyles(styles)(ConversationDetailsToolbar);

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card, Typography, CardHeader,
} from '@material-ui/core';

const useStyles = makeStyles(({
    rootCardList: {
        position: 'relative',
        overflow: 'hidden',
    },
    card: {
        marginBottom: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        position: 'relative',
        overflow: 'hidden',
    },
    details: {
        display: 'flex',
    },
    media: {
        height: 100,
        width: 130,
        marginLeft: 16,
    },
    content: {
        marginTop: 0,
        paddingTop: 0,
        marginLeft: 15,
        marginBottom: 5,
        '& > div > p': {
            lineHeight: '15px !important',
        },
    },
    link: {
        textDecoration: 'none',
    },
    title: {
        padding: 5,
    },
}));

const CardList = (props) => {
    const {
        data, title, image, details, pathname, pathnameParams, itemKey,
    } = props;
    const classes = useStyles();

    const pathnameBuild = (item) => {
        let result = pathname;
        pathnameParams.forEach(({ param, key }) => {
            result = result.replace(param, item[key]);
        });
        return result;
    };

    const detailItems = (item) => details.map(({ label, key, nullValue }) => (
        <div key={key}>
            <Typography className={classes.textBold} color="textPrimary" align="left" display="inline">
                {label}
                : &nbsp;
            </Typography>
            <Typography className={classes.text} color="textSecondary" align="left" display="inline">
                {item[key] || nullValue}
            </Typography>
        </div>
    ));

    const cars = () => data.map((item) => {
        const fullTitle = title.map((key) => item[key]).join(' ');
        return (
            <Card className={classes.card} key={item[itemKey]}>
                <RouteLink
                    to={{
                        pathname: pathnameBuild(item),
                        stocks: item.stocks,
                    }}
                    className={classes.link}
                >
                    <CardHeader
                        className={classes.title}
                        subheader={fullTitle}
                    />

                    <div className={classes.details}>
                        {image && (
                            <img
                                alt={fullTitle}
                                src={item[image]}
                                height="40%"
                                width="40%"
                            />
                        )}
                        <div className={classes.content}>
                            {detailItems(item)}
                        </div>
                    </div>
                </RouteLink>
            </Card>
        );
    });

    return (
        <div className={classes.rootCardList}>
            {cars()}
        </div>
    );
};

CardList.propTypes = {
    itemKey: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
        nullValue: PropTypes.string,
    })).isRequired,
    pathname: PropTypes.string,
    pathnameParams: PropTypes.arrayOf(PropTypes.shape({
        param: PropTypes.string,
        key: PropTypes.string,
    })),
};

CardList.defaultProps = {
    image: null,
    pathname: '',
    pathnameParams: [],
};

export default CardList;

import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { MailStatus } from 'utils/enum/Mail';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { useLazyQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import EmailEventQuery from 'services/graphQL/query/crm/EmailEventQuery';

const useStyles = makeStyles((theme) => ({
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
    iconFail: {
        color: theme.palette.background.red,
    },
    iconDelivered: {
        color: '#1e88e5',
    },
    iconReceived: {
        color: theme.palette.background.fruitSalad,
    },
    iconSending: {
        color: theme.palette.background.flamingo,
    },
    tooltip: {
        whiteSpace: 'pre-wrap',
    },
}));
const ACTION_TYPE = {
    SET_RECORD: 'setRecord',
    TOGGLE_TOOLTIP: 'toggleTooltip',
};
const initState = {
    open: false,
    code: null,
    message: '',
};
const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
    case ACTION_TYPE.TOGGLE_TOOLTIP:
        return update(state, {
            open: { $set: payload },
        });
    case ACTION_TYPE.SET_RECORD:
        const result = JSON.parse(payload.data);
        if (result) {
            return update(state, {
                open: { $set: true },
                code: { $set: payload.emailEventId },
                message: { $set: result?.code === 605 ? result.description : result.message },
            });
        }

        return update(state, {
            open: { $set: true },
        });
    default:
        return initState;
    }
};

const StatusIcon = ({ status, emailId }) => {
    const classes = useStyles();
    const currentStatus = status?.toUpperCase();
    const [state, dispatch] = useReducer(reducer, initState);
    const [getEmailStatus, { data, loading }] = useLazyQuery(
        EmailEventQuery.GET_STATUS_EMAIL_BY_EMAIL_ID,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY, variables: { emailId } },
    );

    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (emailId) {
            getEmailStatus();
        }
    };

    const handleTooltipClose = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_DIALOG,
            payload: false,
        });
    };

    useEffect(() => {
        const record = data?.getStatusEmailByEmailId;
        if (record) {
            dispatch({
                type: ACTION_TYPE.SET_RECORD,
                payload: record,
            });
        }
    }, [data, loading]);

    switch (currentStatus) {
    case MailStatus.FAILED.toUpperCase():
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    classes={{
                        tooltipPlacementBottom: classes.tooltip,
                    }}
                    onClose={handleTooltipClose}
                    open={state.open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={state.message}
                >
                    <IconButton aria-label="delete" onClick={onClick} size="small">
                        <PriorityHighIcon fontSize="small" className={classes.iconFail} />
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
        );
    case MailStatus.RECEIVED.toUpperCase():
        return <CallReceivedIcon fontSize="small" className={classes.iconReceived} />;
    case MailStatus.READ.toUpperCase():
        return <DoneAllIcon fontSize="small" className={classes.iconDelivered} />;
    case MailStatus.DELIVERED.toUpperCase():
        return <CheckIcon fontSize="small" />;
    case MailStatus.SENDING.toUpperCase():
        return <QueryBuilderIcon fontSize="small" className={classes.iconSending} />;
    default:
        return null;
    }
};

StatusIcon.propTypes = {
    emailId: PropTypes.string,
    status: PropTypes.string.isRequired,
};

StatusIcon.defaultProps = {
    emailId: null,
};

export default StatusIcon;

import React, { useReducer, useEffect } from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, useTheme, useMediaQuery,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import CloseIcon from '@material-ui/icons/Close';
import { SaveIcon } from 'components/icons';
import { Row, Form, Col } from 'react-bootstrap';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import AssignPaymentSchema from 'utils/schema/accounting/accountReceivable/AssignPaymentSchema';
import ModalUtils from 'utils/ModalUtils';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { AccountingSubModules, AccountReceivableTransactionType, AccountsReceivableEntities } from 'utils/enum/AccountingEnum';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';
import clsx from 'clsx';
import PaperComponent from 'components/modules/accounting/PaperComponent';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import AccountingUtils from 'utils/AccountingUtils';
import CatalogSelect from 'components/widgets/catalogs/CatalogSelect';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import { DealStatus } from 'utils/enum/DealEnum';
import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValues',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
};

const AssignPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const keyStore = new KeyStore();

const AssignPayment = () => {
    const ASSIGN_PAYMENT = keyStore.hasPermission(Permission.ASSIGN_PAYMENT);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id, entity } = useParams();
    const history = useHistory();

    const initialState = {
        record: {
            transactionNumber: id,
            newAccountNumber: '',
            newReason: '',
            transactionType: '',
            comment: '',
        },
        isDirty: false,
        couldLostData: false,
        customer: null,
        vehicleInformation: null,
        isService: false,
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(AssignPaymentReducer, initialState);
    const {
        record, isDirty, couldLostData, isService,
    } = state;

    const isValidData = isValidSchema(AssignPaymentSchema, record);
    const { isValid, errors } = isValidData;

    const [getProcessInfo, { loading }] = useLazyQuery(AccountReceivableQuery.GET_PAYMENT_PROCESS_INFORMATION, {
        onCompleted: (res) => {
            if (res.getPaymentProcessInformation) {
                const { customer, status } = res.getPaymentProcessInformation;
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        customer,
                        status,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (id > 0) {
            let currentTransactionType = AccountingUtils.getTransactionType(entity);
            if (entity.toLowerCase() === AccountsReceivableEntities.OTHER_COLLECTIONS.toLowerCase() && !isService) {
                currentTransactionType = AccountReceivableTransactionType.DEALS;
            }

            if (entity.toLowerCase() === AccountsReceivableEntities.OTHER_COLLECTIONS.toLowerCase() && isService) {
                currentTransactionType = AccountReceivableTransactionType.SERVICE;
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    record: {
                        ...state.record,
                        transactionNumber: Number(id),
                        transactionType: currentTransactionType,
                    },
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, entity]);

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${entity}`);
    };

    const [addRecord, { loading: isSaving }] = useMutation(AccountingMutation.ASSIGN_PAYMENT, {
        onCompleted: (mutationData) => {
            if (mutationData?.assignPayment) {
                ModalUtils.successMessage(null, 'Payment assigned successfully!');

                onPopupClose(null, true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        await addRecord({
            variables: {
                record,
            },
        });
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const handleEditorKeyDown = (event) => {
        const { key, keyCode } = event;
        if (event && (key === 'Enter' || keyCode === 13)) {
            getProcessInfo({
                variables: {
                    transactionType: record.transactionType,
                    accountNumber: Number(event.target.value),
                },
            });
        }
    };

    const getChipStatus = (status) => {
        let badgeStatus = classes.primary;
        if (status?.toLowerCase() === DealStatus.DELETED.toLowerCase()
                && record.transactionType === AccountReceivableTransactionType.DEALS) badgeStatus = classes.badgeVoid;
        if (status?.toLowerCase() === ServiceInvoiceStatus.VOID.toLowerCase()
                && record.transactionType === AccountReceivableTransactionType.SERVICE) badgeStatus = classes.badgeVoid;

        return <Chip size="small" label={status} className={badgeStatus} />;
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    const getTransactionLabel = () => {
        let label = '';
        switch (record.transactionType) {
        case AccountReceivableTransactionType.DEALS:
            label = 'account #';
            break;
        case AccountReceivableTransactionType.SERVICE:
            label = 'invoice #';
            break;
        case AccountReceivableTransactionType.PARTS:
            label = 'part ticket #';
            break;
        default:
            label = 'record #';
        }

        return label;
    };

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="xs"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
            PaperComponent={PaperComponent}
        >
            <AppBar className={classes.appBarMove}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        {`Assign ${record.transactionType} payment`}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                <Container className={clsx(classes.containerSplit, classes.paddingSides15)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">
                                    Type the
                                    {' '}
                                    {getTransactionLabel()}
                                    {' '}
                                    and press enter
                                </Form.Label>
                                <Col xs={isMobile ? 12 : 7}>
                                    <Form.Control
                                        className={isValidField(errors, 'newAccountNumber') ? 'invalid-field' : ''}
                                        size="sm"
                                        type="number"
                                        value={record.newAccountNumber}
                                        placeholder={getTransactionLabel()}
                                        onChange={(e) => onChangeValue('newAccountNumber', Number(e.target.value))}
                                        onKeyDown={handleEditorKeyDown}
                                    />
                                </Col>
                            </Form.Group>
                            {entity && entity.toLowerCase() === AccountsReceivableEntities.OTHER_COLLECTIONS.toLowerCase()
                                && (
                                    <Form.Group as={Row}>
                                        <Col xs={isMobile ? 12 : 5}>
                                            <Form.Check
                                                id="isDealId"
                                                checked={!isService}
                                                type="checkbox"
                                                label="Deals"
                                                className={classes.labelFont}
                                                onChange={() => dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isService: false,
                                                        customer: null,
                                                        vehicleInformation: null,
                                                        record: {
                                                            ...record,
                                                            transactionType: AccountReceivableTransactionType.DEALS,
                                                            newReason: null,
                                                        },
                                                    },
                                                })}
                                            />
                                        </Col>
                                        <Col xs={isMobile ? 12 : 7}>
                                            <Form.Check
                                                id="isServiceId"
                                                checked={isService}
                                                type="checkbox"
                                                label="Service"
                                                className={classes.labelFont}
                                                onChange={() => dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isService: true,
                                                        customer: null,
                                                        vehicleInformation: null,
                                                        record: {
                                                            ...record,
                                                            transactionType: AccountReceivableTransactionType.SERVICE,
                                                            newReason: null,
                                                        },
                                                    },
                                                })}
                                            />
                                        </Col>
                                    </Form.Group>
                                )}
                            { record.transactionType.toLowerCase() === AccountReceivableTransactionType.DEALS.toLowerCase()
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Assign as *</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <CatalogSelect
                                            catalogEnum={AccountingUtils.getCatalogReason(AccountsReceivableEntities.DEALS)}
                                            name="newReason"
                                            catalogTitle=""
                                            className={isValidField(errors, 'newReason') ? 'invalid-field' : ''}
                                            value={record.newReason}
                                            placeHolder="select the reason"
                                            onChange={(name, newValue) => onChangeValue('newReason', newValue)}
                                        />
                                    </Col>
                                </Form.Group>
                            )}
                            { record.transactionType.toLowerCase() === AccountReceivableTransactionType.SERVICE.toLowerCase()
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Assign as *</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <CatalogSelect
                                            catalogEnum={AccountingUtils.getCatalogReason(AccountsReceivableEntities.SERVICE)}
                                            name="newReason"
                                            catalogTitle=""
                                            className={isValidField(errors, 'newReason') ? 'invalid-field' : ''}
                                            value={record.newReason}
                                            placeHolder="select the reason"
                                            onChange={(name, newValue) => onChangeValue('newReason', newValue)}
                                        />
                                    </Col>
                                </Form.Group>
                            )}
                            { record.transactionType.toLowerCase() === AccountReceivableTransactionType.PARTS.toLowerCase()
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Assign as *</Form.Label>
                                    <Col xs={isMobile ? 12 : 7}>
                                        <CatalogSelect
                                            catalogEnum={AccountingUtils.getCatalogReason(AccountsReceivableEntities.PARTS)}
                                            name="newReason"
                                            catalogTitle=""
                                            className={isValidField(errors, 'newReason') ? 'invalid-field' : ''}
                                            value={record.newReason}
                                            placeHolder="select the reason"
                                            onChange={(name, newValue) => onChangeValue('newReason', newValue)}
                                        />
                                    </Col>
                                </Form.Group>
                            )}
                            {state.customer
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Customer</Form.Label>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 7} className="required">{state.customer}</Form.Label>
                                </Form.Group>
                            ) }
                            {state.vehicleInformation
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Vehicle</Form.Label>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 7} className="required">{state.vehicleInformation}</Form.Label>
                                </Form.Group>
                            )}
                            {state.status
                            && (
                                <Form.Group as={Row}>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 5} className="required">Status</Form.Label>
                                    <Form.Label column="sm" xs={isMobile ? 12 : 7} className="required">{getChipStatus(state.status)}</Form.Label>
                                </Form.Group>
                            ) }
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={12} className="required">
                                    Reason
                                </Form.Label>
                                <Col xs={12}>
                                    <Form.Control
                                        as="textarea"
                                        size="sm"
                                        rows="2"
                                        value={record.comment}
                                        className={isValidField(errors, 'comment') ? 'invalid-field' : ''}
                                        placeholder="Provide a comment"
                                        onChange={(e) => onChangeValue('comment', String(e.target.value))}
                                    />
                                </Col>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <ConfirmDialog
                        title="Attention - You have unsaved changes!"
                        description="Do you want to close without saving?"
                        open={couldLostData}
                        variant="outlined"
                        titlePrimary="Close"
                        titleSecondary="Cancel"
                        onClose={onCloseFormWithoutSave}
                        onClickSecondary={onCloseFormWithoutSave}
                        onClickPrimary={(e) => onPopupClose(e, true)}
                    />
                </Container>
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing} />
                        <div className={classes.buttonSpacing}>
                            {ASSIGN_PAYMENT && (
                                <Button
                                    className={(isValid && isDirty) ? classes.buttonSave : classes.buttonDisable}
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!(isValid && record.newAccountNumber > 0 && state.customer && isDirty) || isSaving}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            )}
                        </div>
                    </Toolbar>
                </div>
            </DialogActions>
            {loading && <DialogActionMessage message="Searching information... " />}
            {isSaving && <DialogActionMessage message="Assigning payment... " />}
        </Dialog>
    );
};

export default AssignPayment;

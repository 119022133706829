import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    FormLabel,
    Checkbox,
} from '@material-ui/core';
import {
    CropSquare as CropSquareIcon,
    Done as DoneIcon,
} from '@material-ui/icons';

const useStyles = makeStyles({
    content: {
        marginBottom: 5,
        alignItems: 'center',
    },
    readOnlyLabel: {
        marginLeft: 10,
        marginBottom: 5,
        lineHeight: 1.2,
    },
    label: {
        marginLeft: 5,
        lineHeight: 1.5,
    },
    icon: {
        fontSize: 27,
    },
    span: {
        fontWeight: 'bold',
    },
    checkbox: {
        padding: 0,
        display: 'flex',
        alignItems: 'flex-start',
    },
});

const EquipmentItem = ({
    equipment,
    isEditable,
    onChangeItem,
    section,
    index,
}) => {
    const classes = useStyles();

    if (equipment.readOnly) {
        return (
            <div className={clsx('d-flex', classes.content)}>
                <FormLabel className={clsx('d-flex-column', classes.readOnlyLabel)}>
                    <span className={classes.span}>{equipment.name}</span>
                    <span>{equipment.description}</span>
                </FormLabel>
            </div>
        );
    }

    if (isEditable) {
        const checkBox = (
            <Checkbox
                className={classes.checkbox}
                checked={equipment.installed}
                onChange={(event, checked) => onChangeItem(section, index, checked)}
                color="primary"
            />
        );

        return (
            <div className={clsx('d-flex', classes.content)}>
                {checkBox}
                <FormLabel className={classes.label}>{equipment.name}</FormLabel>
            </div>
        );
    }

    const icon = equipment.installed ? (
        <DoneIcon />
    ) : (
        <CropSquareIcon className={classes.icon} />
    );
    return (
        <div className={clsx('d-flex', classes.content)}>
            {icon}
            <FormLabel className={classes.label}>{equipment.name}</FormLabel>
        </div>
    );
};

EquipmentItem.propTypes = {
    onChangeItem: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
    equipment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isEditable: PropTypes.bool,
};

EquipmentItem.defaultProps = {
    isEditable: false,
};

export default EquipmentItem;

import { useApolloClient } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import { ReconStatus } from 'utils/enum/InventoryEnum';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

const useReconActions = () => {
    const client = useApolloClient();

    const changeReconStatus = async (
        stockNumber,
        nextStatus,
        ins = null,
        note,
        isApproving = false,
        totalEstimate,
        maximumReconCost,
        saveReconItemsCallback,
        isReapprovalRequest,
    ) => {
        if (
            isApproving
            && nextStatus === ReconStatus.INSPECTION_APPROVED
            && totalEstimate > maximumReconCost
        ) {
            ModalUtils.errorMessage(null, `The Max Recon Cost for this vehicle is ${NumberUtils.applyCurrencyFormat(maximumReconCost)}`);
            return null;
        }

        try {
            if (saveReconItemsCallback) await saveReconItemsCallback(false);
            const { data } = await client.mutate({
                mutation: InventoryMutation.SET_RECON_STATUS,
                variables: {
                    stockNumber,
                    status: nextStatus,
                    note,
                    inspector: ins,
                    reapproval: isReapprovalRequest,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                return {
                    status: nextStatus,
                    inspector: ins,
                };
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }

        return null;
    };

    return {
        changeReconStatus,
    };
};

export default useReconActions;

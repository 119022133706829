import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { remove, clone, findIndex } from 'lodash';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealService from 'services/modules/DealService';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

const AddressHistoryContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.addressHistorySubscription = null;

        this.state = {
            records: [],
            load: false,
        };
        this.initBind();
    }

    getServicesData() {
        const { props: { accountNumber, customerId } } = this;
        const input = {
            accountNumber,
            customerId,
        };

        this.setState({
            load: true,
        });

        this.graphqlClient
            .query(DealsQuery.GET_ADDRESS_HISTORY, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerAddressHistory) {
                    const { getCustomerAddressHistory } = data;

                    this.setState({
                        records: getCustomerAddressHistory,
                    });
                }
            })
            .finally(() => {
                this.setState({
                    load: false,
                });
            });
    }

    addToPreviewsList(customerAddressId) {
        const { state: { records }, props: { accountNumber, customerId } } = this;
        const backUp = clone(records);
        const input = {
            accountNumber,
            customerAddressId,
            customerId,
        };

        this.dealService.addAddressToPreviousList(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.createDealPreviousAddress) {
                    remove(backUp, { customerAddressId });

                    this.setState({
                        records: backUp,
                    });
                }
            });
    }

    subscriptionAddressHistory() {
        const { customerId, accountNumber } = this.props;
        const input = {
            accountNumber,
            customerId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.CUSTOMER_ADDRESS_HISTORY, input)
            .then((response) => {
                this.addressHistorySubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;
        if (data) {
            const { customerAddressHistory } = data;

            switch (customerAddressHistory?.type) {
            case SubscriptionActionType.CREATED:
                this.addAddressHistory(customerAddressHistory?.data);
                break;
            case SubscriptionActionType.DELETED:
                this.removeAddressHistory(customerAddressHistory?.data);
                break;
            default:
            }
        }
    }

    addAddressHistory(record) {
        if (record) {
            this.setState((prevState) => ({
                records: update(prevState.records, { $push: [record] }),
            }));
        }
    }

    removeAddressHistory(record) {
        const { records } = this.state;
        const index = findIndex(records, { customerAddressId: record?.customerAddressId });

        if (index > -1) {
            this.setState((prevState) => ({
                records: update(prevState.records, { $splice: [[index, 1]] }),
            }));
        }
    }

    unsubscribeAddressHistory() {
        if (this.addressHistorySubscription) {
            this.addressHistorySubscription.unsubscribe();
        }
    }

    initBind() {
        this.getServicesData = this.getServicesData.bind(this);
        this.addToPreviewsList = this.addToPreviewsList.bind(this);
        this.addAddressHistory = this.addAddressHistory.bind(this);
        this.removeAddressHistory = this.removeAddressHistory.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.unsubscribeAddressHistory = this.unsubscribeAddressHistory.bind(this);
        this.subscriptionAddressHistory = this.subscriptionAddressHistory.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                getServicesData={this.getServicesData}
                addToPreviewsList={this.addToPreviewsList}
                unsubscribeAddressHistory={this.unsubscribeAddressHistory}
                subscriptionAddressHistory={this.subscriptionAddressHistory}
            />
        );
    }
};

export default AddressHistoryContainer;

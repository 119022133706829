import React, { useEffect, useReducer } from 'react';
import update from 'immutability-helper';

// Components and Others
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import StringUtils from 'lib/StringUtils';

const ACTIONS_TYPE = {
    SET_MASK: 'setMask',
    TOGGLE_SHOW_MASK: 'toggleShowMask',
};
const initState = {
    maskedSSN: '',
    currentSSN: '',
    showMask: true,
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTIONS_TYPE.SET_MASK:
        const { payload } = action;

        return update(state, {
            currentSSN: { $set: payload },
            maskedSSN: { $set: StringUtils.applySSNMask(payload) },
        });
    case ACTIONS_TYPE.TOGGLE_SHOW_MASK:
        return update(state, {
            showMask: { $set: !state.showMask },
        });
    default:
        return initState;
    }
};

const InputSSN = ({
    onChange,
    value,
    placeholder,
    size,
}) => {
    const [state, dispatch] = useReducer(reducer, initState);

    useEffect(() => {
        dispatch({
            type: ACTIONS_TYPE.SET_MASK,
            payload: value,
        });
    }, [value]);

    const toggleShowMask = () => {
        dispatch({ type: ACTIONS_TYPE.TOGGLE_SHOW_MASK });
    };
    const handleChange = (newValue) => {
        const regExp = /^[0-9\b]+$/;
        const char = newValue.substring(newValue.length - 1);

        if (char === '' || char === '-' || regExp.test(char)) {
            let ssn = newValue;
            const { length } = ssn;

            if (char === '' || char === '-') {
                ssn = char === '-' ? ssn.substring(0, ssn.length - 1) : ssn;

                dispatch({
                    type: ACTIONS_TYPE.SET_MASK,
                    payload: ssn,
                });
                onChange(ssn);
                return ssn;
            }

            if (length === 4 || length === 7) {
                ssn = `${ssn.substring(0, length - 1)}-${char}`;
            }

            dispatch({
                type: ACTIONS_TYPE.SET_MASK,
                payload: ssn,
            });
            onChange(ssn);
        }

        return state.currentSSN;
    };

    return (
        <div>
            <Form.Control
                className="form-control"
                name="ssn"
                type="tel"
                size={size}
                placeholder={placeholder}
                value={state.showMask ? state.maskedSSN : state.currentSSN}
                autoComplete="off"
                onChange={({ target }) => handleChange(target.value)}
                onFocus={toggleShowMask}
                onBlur={toggleShowMask}
                maxLength={11}
            />
        </div>
    );
};

InputSSN.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
};

InputSSN.defaultProps = {
    onChange: () => {},
    value: '',
    placeholder: '***-**-****',
    size: 'medium',
};

export default InputSSN;

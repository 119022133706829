import ButtonStyles from 'styles/theme/Button';

export default class CustomerStyles {
    static customerInformation(theme = {}) {
        return ({
            root: {
                padding: theme.spacing(2),
            },
            text: {
                fontWeight: 400,
                marginBottom: theme.spacing(2),
            },
            textVehicle: {
                fontWeight: 400,
            },
            headerDrawer: {
                justifyContent: 'flex-end',
                display: 'flex',
            },
            buttonClose: {
                minWidth: 36,
                width: 36,
            },
            startIcon: {
                margin: 'auto',
                color: theme.palette.text.default,
            },
            lastFormGroup: {
                marginBottom: theme.spacing(2),
            },
            contentVehicle: {
                margin: theme.spacing(0, -2),
                '& .vehicle-item': {
                    padding: theme.spacing(1, 2, 1, 1),
                    '&:first-child': {
                        borderTop: `1px solid ${theme.palette.divider}`,
                    },
                },
            },
            contentAddVehicle: {
                margin: 0,
                '& .footer': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
            },
            boxAddVehicle: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 48,
            },
            button: {
                width: '50%',
            },
            buttonIcon: {
                minWidth: theme.spacing(5),
                maxWidth: theme.spacing(5),
                margin: theme.spacing(0, 0.5),
            },
            textArea: {
                resize: 'initial',
            },
            paper: {
                position: 'absolute',
                borderRadius: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `rgba(${theme.palette.rgb.gray}, 0.8)`,
            },
            textDanger: {
                color: theme.palette.error.main,
            },
            ...ButtonStyles.getStyle(theme),
        });
    }

    static addressDialog(theme = {}) {
        return ({
            rowCountryZip: {
                display: 'flex',
                padding: '0 5px',
            },
            zip: {
                width: 120,
                marginLeft: 10,
            },
            country: {
                flex: 1,
            },
            dialog: {
                width: 892,
                height: 564,
            },
            dialogContent: {
                padding: theme.spacing(3),
            },
        });
    }

    static manageAddress(theme = {}) {
        return ({
            customer: {
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
            },
            dialog: {
                width: 742,
                height: 411,
            },
            dialogContent: {
                padding: 0,
                '& .row-address': {
                    borderBottom: `1px solid ${theme.palette.border.secondary}`,
                },
                '& .row-address:last-child': {
                    borderBottom: 0,
                },
            },
            newAddressButton: {
                color: theme.palette.text.royalBlue,
            },
            actionPanel: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',

                '& > div:last-child': {
                    display: 'flex',
                },
                '& button': {
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    marginRight: 6,
                },
                '& > div > .current-address': {
                    color: theme.palette.text.tacao,
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    lineHeight: 1.75,
                    borderRadius: 4,
                    letterSpacing: '0.02857em',
                    textTransform: 'capitalize',
                    fontSize: '0.8125rem',
                    padding: '4px 5px',
                    marginRight: '6px',
                    cursor: 'default',
                    display: 'inline-flex',

                },
                '& > div > button.make-current': {
                    color: theme.palette.text.deYork,
                },
                '& > div > button.edit': {
                    color: theme.palette.text.royalBlue,
                },
                '& > div > button.delete': {
                    color: theme.palette.text.geraldine,
                },
            },
        });
    }

    static customerItems(theme = {}) {
        return ({
            nameText: {
                color: theme.palette.text.tundora,
            },
            min250: {
                minWidth: 250,
                maxWidth: 250,
            },
            min280: {
                minWidth: 280,
                maxWidth: 280,
            },
            label: {
                minWidth: 80,
                marginRight: theme.spacing(1),
                textAlign: 'right',
                color: theme.palette.text.stormGray,
            },
            value: {
                color: theme.palette.text.stormGray,
                fontWeight: 500,
            },
            referred: {
                marginLeft: theme.spacing(1),
            },
            ...ButtonStyles.getStyle(theme),
        });
    }

    static customerList(theme = {}) {
        return ({
            expandRow: {
                padding: theme.spacing(2),
            },
            mainButton: {
                minWidth: 90,
                marginRight: theme.spacing(2),
            },
            header: {
                marginTop: theme.spacing(1),
            },
            search: {
                width: 300,
            },
            ...ButtonStyles.getStyle(theme),
        });
    }

    static details(theme) {
        return ({
            boxContainer: {
                background: theme.palette.background.default,
                flex: 1,
            },
            contentContainer: {
                background: theme.palette.background.white,
                flex: 1,
                width: '100%',
                height: '100%',
                margin: theme.spacing(1),
                padding: theme.spacing(4),
                overflow: 'auto',
            },
            ...ButtonStyles.getStyle(theme),
            button: {
                marginRight: theme.spacing(2),
                minWidth: 100,
            },
            gridRow: {
                marginBottom: theme.spacing(2.5),
            },
            datePicker: {
                width: '100%',
            },
            businessCheckBox: {
                marginBottom: theme.spacing(2),
                marginLeft: '2px',
                '& > div:nth-child(1)': {
                    paddingLeft: 0,
                },
            },
            businessCheckLabel: {
                fontSize: '16px !important',
            },
            input: {
                fontSize: '13px',
                resize: 'none',
                width: '100%',
                '& > div': {
                    height: '38px !important',
                },
            },
            '@global': {
                '.css-26l3qy-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
            },
        });
    }

    static employmentDialog(theme = {}) {
        return ({
            zip: {
                width: 120,
                marginLeft: 10,
            },
            country: {
                flex: 1,
            },
            dialog: {
                width: 892,
                height: 680,
            },
            dialogContent: {
                padding: theme.spacing(3),
            },
            marginBottom0: {
                marginBottom: 0,
            },
        });
    }
}

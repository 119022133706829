import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import CommonJournalDetailList from 'components/modules/accounting/journal/list/CommonJournalDetailList';

const useOwnStyle = makeStyles(() => ({
    modalContainer: {
        height: 500,
        '& > div > div > div > div': {
            minHeight: 408,
            minWidth: 'fit-content',
        },
    },
    appBar: {
        padding: '15px 16px',
    },
}));

const CommonJournalPopup = (props) => {
    const {
        recordId,
        showPopup,
        postedDate,
        isAlreadyPosted,
        onToogleModal,
        journalType,
    } = props;
    const theme = useTheme();
    const classes = useOwnStyle();
    const { modalContainer, ...appBarClasses } = classes;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={showPopup}
            maxWidth="lg"
            fullScreen={isMobile}
            fullWidth={!isMobile}
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
        >
            <DialogAppBar
                classes={{ ...appBarClasses }}
                title={isAlreadyPosted ? 'Reverting Journal' : 'Posting Journal'}
                onClose={onToogleModal}
                titleVariant="subtitle1"
                toolbarSize="sm"
            />
            <DialogContent className={modalContainer}>
                <CommonJournalDetailList
                    recordId={recordId}
                    journalType={journalType}
                    writePermissions={!isAlreadyPosted}
                    showPosted={isAlreadyPosted}
                    initialPostedDate={postedDate}
                    onCompleted={onToogleModal}
                />
            </DialogContent>

        </Dialog>
    );
};

CommonJournalPopup.propTypes = {
    recordId: PropTypes.number.isRequired,
    postedDate: PropTypes.string,
    showPopup: PropTypes.bool.isRequired,
    isAlreadyPosted: PropTypes.bool.isRequired,
    onToogleModal: PropTypes.func.isRequired,
    journalType: PropTypes.string.isRequired,
};

CommonJournalPopup.defaultProps = {
    postedDate: null,
};

export default CommonJournalPopup;

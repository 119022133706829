import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/widgets/Loading';

// Material UI
import {
    Grid,
    Dialog, DialogContent,
} from '@material-ui/core';
import {
    withStyles,
} from '@material-ui/core/styles';

// Bootstrap
import Select from 'components/widgets/Select';
import { Form } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CommissionsDialogContainer from 'components/containers/deals/read/commissions/CommissionsDialogContainer';

import KeyStore from 'utils/KeyStore';
import Permissions from 'utils/enum/Permissions';

const styles = (theme = {}) => ({
    row: {
        marginBottom: theme.spacing(1),
    },
    dialogContainer: {
        position: 'relative',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 9,
        background: theme.palette.background.white,
    },
});

class CommissionsDialog extends Component {
    componentDidMount() {
        const { props } = this;
        props.subscribeCommissionsUpdated();
        props.getServicesData();
    }

    componentWillUnmount() {
        const { unsubscribeCommissionUpdated } = this.props;

        unsubscribeCommissionUpdated();
    }

    getSalesmansFiltered(userId) {
        const { props: { salesmanList } } = this;
        return salesmanList.filter((item) => item.value !== userId || item.value === 0);
    }

    render() {
        const allowChangeFinanceManager = KeyStore.isAllowed(Permissions.SALES_COMMISSIONS_CHANGE_FINANCE_MANAGER);
        const allowOverride = KeyStore.isAllowed(Permissions.SALES_COMMISSIONS_OVERRIDE);
        const {
            props: {
                load,
                classes,
                salesManagerList,
                financeManagerList,
                BDCList,
                hideCommissionsDialog,
                onChange,
                onSave,
                isCallingService,
                writePermission,
                commissions: {
                    salesPersonId1,
                    commissions1,
                    commission1Override,
                    salesPerson1Paid,
                    salesPersonId2,
                    commissions2,
                    commission2Override,
                    salesPerson2Paid,
                    bdcAgentId,
                    bdcAgentCommission,
                    bdcAgentOverride,
                    bdcAgentPaid,
                    salesManagerId,
                    salesManagerCommission,
                    salesManagerOverride,
                    salesManagerPaid,
                    financeManagerId,
                    financeCommissions,
                    financeCommissionOverride,
                    financeManagerPaid,
                },
            },
        } = this;

        const salesReps1 = this.getSalesmansFiltered(salesPersonId2);
        const salesReps2 = this.getSalesmansFiltered(salesPersonId1);

        return (
            <Dialog
                open
                fullWidth
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Commissions" onClose={hideCommissionsDialog} iconSize="sm" />
                <DialogContent className={classes.dialogContainer}>
                    {load && <Loading className={classes.loading} />}
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item xs={8}>
                            <Form.Label>Sales Rep 1</Form.Label>
                            <Select
                                name="salesPersonId1"
                                placeholder="Select"
                                disabled={salesPerson1Paid || !writePermission}
                                options={salesReps1}
                                value={salesPersonId1}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={3}>
                            <InputNumber
                                value={commissions1}
                                disabled={!commission1Override || salesPerson1Paid || !writePermission}
                                onChange={(value) => onChange('commissions1', value)}
                                showCurrency
                                size="sm"
                            />
                        </Grid>
                        <Grid item className={classes.checkbox} xs={1}>
                            <Form.Check
                                checked={commission1Override}
                                disabled={!allowOverride || salesPerson1Paid || !writePermission}
                                onChange={(e) => onChange('commission1Override', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item xs={8}>
                            <Form.Label>Sales Rep 2</Form.Label>
                            <Select
                                name="salesPersonId2"
                                placeholder="Select"
                                disabled={salesPerson2Paid || !writePermission}
                                options={salesReps2}
                                value={salesPersonId2}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={3}>
                            <InputNumber
                                value={commissions2}
                                disabled={!commission2Override || salesPerson2Paid || !writePermission}
                                onChange={(value) => onChange('commissions2', value)}
                                showCurrency
                                size="sm"
                            />
                        </Grid>
                        <Grid item className={classes.checkbox} xs={1}>
                            <Form.Check
                                checked={commission2Override}
                                disabled={!allowOverride || salesPerson2Paid || !writePermission}
                                onChange={(e) => onChange('commission2Override', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item xs={8}>
                            <Form.Label>BDC Agent</Form.Label>
                            <Select
                                name="bdcAgentId"
                                placeholder="Select"
                                disabled={bdcAgentPaid || !writePermission}
                                options={BDCList}
                                value={bdcAgentId}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={3}>
                            <InputNumber
                                value={bdcAgentCommission}
                                disabled={!bdcAgentOverride || bdcAgentPaid || !writePermission}
                                onChange={(value) => onChange('bdcAgentCommission', value)}
                                showCurrency
                                size="sm"
                            />
                        </Grid>
                        <Grid item className={classes.checkbox} xs={1}>
                            <Form.Check
                                checked={bdcAgentOverride}
                                disabled={!allowOverride || bdcAgentPaid || !writePermission}
                                onChange={(e) => onChange('bdcAgentOverride', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item xs={8}>
                            <Form.Label>Sales Manager</Form.Label>
                            <Select
                                name="salesManagerId"
                                placeholder="Select"
                                disabled={salesManagerPaid || !writePermission}
                                options={salesManagerList}
                                value={salesManagerId}
                                onChange={onChange}
                                className="select-sm"
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={3}>
                            <InputNumber
                                value={salesManagerCommission}
                                disabled={!salesManagerOverride || salesManagerPaid || !writePermission}
                                onChange={(value) => onChange('salesManagerCommission', value)}
                                showCurrency
                                size="sm"
                            />
                        </Grid>
                        <Grid item className={classes.checkbox} xs={1}>
                            <Form.Check
                                checked={salesManagerOverride}
                                disabled={!allowOverride || salesManagerPaid || !writePermission}
                                onChange={(e) => onChange('salesManagerOverride', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.row}>
                        <Grid item xs={8}>
                            <Form.Label>Finance Manager</Form.Label>
                            <Select
                                name="financeManagerId"
                                placeholder="Select"
                                disabled={!allowChangeFinanceManager || financeManagerPaid || !writePermission}
                                options={financeManagerList}
                                value={financeManagerId}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={3}>
                            <InputNumber
                                value={financeCommissions}
                                disabled={!financeCommissionOverride || financeManagerPaid || !writePermission}
                                onChange={(value) => onChange('financeCommissions', value)}
                                showCurrency
                                size="sm"
                            />
                        </Grid>
                        <Grid item className={classes.checkbox} xs={1}>
                            <Form.Check
                                checked={financeCommissionOverride}
                                disabled={!allowOverride || financeManagerPaid || !writePermission}
                                onChange={(e) => onChange('financeCommissionOverride', e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {writePermission && (
                    <DialogActions
                        onClickSecondary={hideCommissionsDialog}
                        onClickPrimary={onSave}
                        disablePrimaryButton={isCallingService}
                    />
                )}
            </Dialog>
        );
    }
}

CommissionsDialog.propTypes = {
    load: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    hideCommissionsDialog: PropTypes.func.isRequired,
    BDCList: PropTypes.array.isRequired,
    salesmanList: PropTypes.array.isRequired,
    salesManagerList: PropTypes.array.isRequired,
    financeManagerList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    isCallingService: PropTypes.bool.isRequired,
    commissions: PropTypes.object.isRequired,
    writePermission: PropTypes.bool,
    unsubscribeCommissionUpdated: PropTypes.func.isRequired,
    subscribeCommissionsUpdated: PropTypes.func.isRequired,
};

CommissionsDialog.defaultProps = {
    load: false,
    writePermission: false,
};

export default withStyles(styles)(CommissionsDialogContainer(CommissionsDialog));

import { gql } from '@apollo/client';

export default class ConversationQuery {
    static CONVERSATION_LIST = gql`
        query conversationList($offset: Int!, $limit: Int!, $filter: SearchInConversationInput ) {
            conversationList(offset: $offset, limit: $limit, filter: $filter) {
                totalCount
                conversations {
                    _id
                    userPlatformId
                    platform {
                        name
                    }
                    lastMessage {
                        ... on QuickReply {
                            type
                            date
                            quickReplyContent: content {
                                text
                            }
                        }
                        ... on CardMessage {
                            type
                            date
                            cardContent: content {
                                title
                            }
                        }
                        ... on TextMessage {
                            type
                            date
                            content {
                                text
                            }
                        }
                        ... on JoinedAgentMessage {
                            type
                            date
                        }
                        ... on DateTimePicker {
                            type
                        }
                    }
                    color
                    status
                    customerName
                }
            }
        }
    `;

    static GET_CONVERSATION_DETAILS = gql`
        query getCRMConversation($id: ID!, $offset: Int!, $limit: Int!) {
            getCRMConversation(id: $id, offset: $offset, limit: $limit) {
                totalCount
                conversation {
                    messages {
                        ... on QuickReply {
                            type
                            date
                            isCustomer
                            quickReplyContent: content {
                                text
                                replies {
                                    type
                                    title
                                    payload
                                }
                            }
                            _id
                            sentBy {
                                profile {
                                    firstName
                                    lastName
                                    userPicture
                                }
                            }
                        }
                        ... on CardMessage {
                            type
                            date
                            isCustomer
                            cardContent: content {
                                title
                                subTitle
                                imageURI
                                buttons {
                                    type
                                    text
                                    postback
                                }
                            }
                            _id
                            sentBy {
                                profile {
                                    firstName
                                    lastName
                                    userPicture
                                }
                            }
                        }
                        ... on TextMessage {
                            type
                            date
                            isCustomer
                            content {
                                text
                            }
                            _id
                            sentBy {
                                profile {
                                firstName
                                lastName
                                userPicture
                                }
                            }
                        }
                        ... on JoinedAgentMessage {
                            type
                            date
                            _id
                            sentBy {
                                userId
                                profile {
                                    firstName
                                    lastName
                                    userPicture
                                }
                            }
                        }
                    }
                    color
                    status
                    customerName
                    assignee {
                        userId
                    }
                }
            }
        }
    `;
}

import React, {
    useEffect,
    useContext,
    useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid,
    fade,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import Select from 'components/widgets/Select';
import { isEmpty } from 'lodash';

import { FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery, useMutation } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import DealsSettingsMutate from 'services/graphQL/mutate/DealsSettingsMutate';
import { Col, Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';

const ACTION_TYPES = {
    ON_CHANGE_LOT: 'onChangeLot',
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.SET_RECORD_CHANGED:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.ON_CHANGE_LOT:
        return update(state, {
            lotId: { $set: action.payload },
        });
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '0.875rem',
    },
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    input: {
        fontSize: '14px',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    alignCheckBox: {
        marginBottom: '10px',
    },
}));

const DealsUserTabSettings = ({ writable }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation = {} } = useContext(UserContext);
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, {
        lotId: keyStore.getSelectedLot().lotId,
        record: {
            dealUserTabName1: '',
            dealUserTabName2: '',
            dealUserTabName3: '',
            dealUserTabName4: '',
            dealUserTabName5: '',
            dealUserTabURL1: '',
            dealUserTabURL2: '',
            dealUserTabURL3: '',
            dealUserTabURL4: '',
            dealUserTabURL5: '',
        },
    });

    const availableLots = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    const {
        record,
        lotId,
    } = state;

    const [getSettings, { loading }] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_USER_TAB, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: response.getDealSettingsUserTab || {},
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        let { lotName } = keyStore.getSelectedLot();
        if (!isEmpty(availableLots)) {
            const currentLot = availableLots.find((obj) => obj.value === state.lotId);
            if (!isEmpty(currentLot)) {
                lotName = currentLot.label;
            }
        }

        if (lotName) {
            getSettings({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lotId]);

    const [saveIntegrationSettings, { loading: isSavingSettings }] = useMutation(DealsSettingsMutate.SAVE_DEALS_SETTINGS_USER_TAB, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Deal Settings saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Deal Settings');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const saveSettings = () => {
        const currentLot = availableLots.find((obj) => obj.value === lotId);

        if (currentLot) {
            saveIntegrationSettings({
                variables: {
                    input: record,
                    lotName: currentLot.label,
                },
            });
        }
    };

    const onChange = async (field, value) => {
        if (value === record[field]) return;
        const currentRecord = { ...record };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeLot = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_LOT,
            payload: value,
        });
    };

    const shouldDisableButton = () => ((loading || isSavingSettings));
    const saveButtonText = () => ((isSavingSettings) ? 'Saving' : 'Save');

    return (
        <>
            <Grid container className={classes.box}>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Lot</Form.Label>
                            <Select
                                size="sm"
                                name="lotId"
                                value={state.lotId}
                                placeholder="Select"
                                options={availableLots}
                                onChange={onChangeLot}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>User Tab Name 1</Form.Label>
                            <Form.Control
                                value={record.dealUserTabName1}
                                name="dealUserTabName1"
                                type="text"
                                onChange={(e) => onChange('dealUserTabName1', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>URL 1</Form.Label>
                            <Form.Control
                                value={record.dealUserTabURL1}
                                name="dealUserTabURL1"
                                type="text"
                                onChange={(e) => onChange('dealUserTabURL1', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>User Tab Name 2</Form.Label>
                            <Form.Control
                                value={record.dealUserTabName2}
                                name="dealUserTabName2"
                                type="text"
                                onChange={(e) => onChange('dealUserTabName2', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>URL 2</Form.Label>
                            <Form.Control
                                value={record.dealUserTabURL2}
                                name="dealUserTabURL2"
                                type="text"
                                onChange={(e) => onChange('dealUserTabURL2', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>User Tab Name 3</Form.Label>
                            <Form.Control
                                value={record.dealUserTabName3}
                                name="dealUserTabName3"
                                type="text"
                                onChange={(e) => onChange('dealUserTabName3', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>URL 3</Form.Label>
                            <Form.Control
                                value={record.dealUserTabURL3}
                                name="dealUserTabURL3"
                                type="text"
                                onChange={(e) => onChange('dealUserTabURL3', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>User Tab Name 4</Form.Label>
                            <Form.Control
                                value={record.dealUserTabName4}
                                name="dealUserTabName4"
                                type="text"
                                onChange={(e) => onChange('dealUserTabName4', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>URL 4</Form.Label>
                            <Form.Control
                                value={record.dealUserTabURL4}
                                name="dealUserTabURL4"
                                type="text"
                                onChange={(e) => onChange('dealUserTabURL4', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>User Tab Name 5</Form.Label>
                            <Form.Control
                                value={record.dealUserTabName5}
                                name="dealUserTabName5"
                                type="text"
                                onChange={(e) => onChange('dealUserTabName5', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>URL 5</Form.Label>
                            <Form.Control
                                value={record.dealUserTabURL5}
                                name="dealUserTabURL5"
                                type="text"
                                onChange={(e) => onChange('dealUserTabURL5', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <If condition={writable}>
                    <Grid container>
                        <Grid item lg={6} md={8} xs={12}>
                            <Form.Group className={classes.customTabsSave} as={Col}>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    disabled={shouldDisableButton()}
                                    onClick={saveSettings}
                                >
                                    {saveButtonText()}
                                </Button>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </If>
            </Grid>
        </>
    );
};

DealsUserTabSettings.propTypes = {
    writable: PropTypes.bool.isRequired,
};

export default DealsUserTabSettings;

import React, { useContext, useEffect, useReducer } from 'react';

// Material UI and React Bootstrap
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Tooltip,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import UserContext from 'components/context/UserContext';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';

// HTTP
import useAxios from 'axios-hooks';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import { HttpMethods } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import HttpCRMModule from 'services/httpModules/HttpCRMModule';
import SMSMutation from 'services/graphQL/mutate/crm/SMSMutation';
import SelectMessageTemplate from 'components/modules/crm/widgets/SelectMessageTemplate';
import { calculateSMSInfo, ErrorMessageSegments } from 'components/widgets/sms/MessageAdd';
import clsx from 'clsx';

const ACTION_TYPE = {
    SET_SMS_LIST: 'setSMSList',
    SET_TEXT_MESSAGE: 'setTextMessage',
    CHANGE_TEMPLATE: 'onChangeTemplate',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_SMS_LIST:
        const templateList = (action.payload || []).map((item) => ({ label: item.templateName, value: item.messageTemplateId, subject: item.templateSubject }));

        return update(state, {
            templateList: { $set: templateList },
        });
    case ACTION_TYPE.CHANGE_TEMPLATE:
        return update(state, {
            templateSelected: { $set: action.value },
        });
    case ACTION_TYPE.SET_TEXT_MESSAGE:
        return update(state, {
            textMessage: { $set: action.value },
        });
    default:
        return state;
    }
};
const useStyle = makeStyles((theme) => ({
    textArea: {
        margin: theme.spacing(1.5, 0),
        resize: 'none',
    },
    dialog: {
        width: 450,
    },
    select: {
        width: '220px',
    },
    counterMessage: {
        fontSize: '13px',
    },
    error: {
        color: theme.palette.text.red,
        cursor: 'default',
    },
}));

const MessageDialog = ({
    open, onClose, crmId,
    leadCode,
}) => {
    const classes = useStyle();
    const [state, dispatch] = useReducer(reducer, {
        textMessage: '',
        templateSelected: null,
    });
    const { userInformation } = useContext(UserContext);
    const [sendMessage, { loading }] = useMutation(SMSMutation.SEND_MESSAGE_FROM_CRM);
    const [
        { loading: loadingGetPreview },
        getPreview,
    ] = useAxios({
        url: HttpCRMModule.getURLSMSTemplatePreview(state.templateSelected, leadCode),
        method: HttpMethods.GET,
    }, { manual: true });
    const onSendMessage = async () => {
        const input = {
            crmId,
            text: state.textMessage,
            lotName: userInformation?.defaultLot,
            manual: true,
        };

        try {
            const response = await sendMessage({ variables: { input } });

            if (!response?.data?.sendMessageFromCRM) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('send', 'message'));
            } else {
                onClose();
            }
        } catch (e) {
            ModalUtils.errorMessage(null, e.message);
        }
    };
    const onChangeValue = (value) => {
        dispatch({
            type: ACTION_TYPE.SET_TEXT_MESSAGE,
            value,
        });
    };
    const onChangeTemplate = (value) => {
        dispatch({
            type: ACTION_TYPE.CHANGE_TEMPLATE,
            value,
        });
    };
    const onGetPreview = async () => {
        if (!StringUtils.isEmpty(state.templateSelected)) {
            try {
                const response = await getPreview();
                if (response?.data?.Success) {
                    // * Some templates have more than 500 characters so the length is being cut off.
                    dispatch({
                        type: ACTION_TYPE.SET_TEXT_MESSAGE,
                        value: response?.data?.Data?.slice(0, 500),
                    });
                } else {
                    ModalUtils.errorMessage(response?.data?.Messages, response?.data?.Message);
                }
            } catch (e) {
                ModalUtils.errorMessage(e?.response?.data?.errors);
            }
        }
    };

    useEffect(() => {
        if (state.templateSelected) {
            onGetPreview();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.templateSelected]);

    const result = calculateSMSInfo(state.textMessage);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Send Message" toolbarSize="sm" onClose={onClose} titleVariant="h5" />
            <DialogContent>
                <Form.Group>
                    <div className="d-flex-space-between">
                        <SelectMessageTemplate
                            onChange={onChangeTemplate}
                            disabled={loadingGetPreview}
                        />
                        <Tooltip title={result.numberOfSMS > 4 ? ErrorMessageSegments : ''}>
                            <div
                                className={
                                    clsx(classes.counterMessage, { [classes.error]: result.numberOfSMS > 4 })
                                }
                            >
                                {`${result.remaining} / ${result.numberOfSMS}`}
                            </div>
                        </Tooltip>
                    </div>
                    <Form.Control
                        rows={4}
                        value={state.textMessage}
                        as="textarea"
                        maxLength={500}
                        placeholder="Enter your message"
                        className={classes.textArea}
                        onChange={(e) => onChangeValue(e.target.value)}
                    />
                </Form.Group>
            </DialogContent>
            <DialogActions
                titlePrimary="Send"
                onClickSecondary={onClose}
                onClickPrimary={onSendMessage}
                disablePrimaryButton={StringUtils.isEmpty(state.textMessage) || loading || loadingGetPreview || result.numberOfSMS > 4}
            />
        </Dialog>
    );
};

MessageDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    leadCode: PropTypes.number.isRequired,
};

export default MessageDialog;

import { gql } from '@apollo/client';

export default class FormSubscription {
    static FORM_PACKS_CHANGED = gql`
        subscription formPacksChanged($lotId: Int!) {
            formPacksChanged(lotId: $lotId) {
                type
                data {
                    packId
                    packName
                    lotName
                    lotId
                }
            }
        }
    `;

    static FORM_ADDED_OR_REMOVE_TO_FORM_PACK = gql`
        subscription formAddedOrRemoveToFormPack($packId: Int!){
            formAddedOrRemoveToFormPack(packId: $packId) {
                type
                form {
                    id
                    commonName
                    category
                    formPrintingType
                    originalName
                }
            }
        }
    `;

    static FORM_PACKS_LIST_CHANGE = gql`
        subscription formPacksListChange($packId: Int!){
            formPacksListChange(packId: $packId)
        }
    `;
}

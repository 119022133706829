/* eslint-disable import/prefer-default-export */
export const reminderOption = [
    {
        value: 0,
        label: 'None',
    },
    {
        value: 0.25,
        label: '15 min before',
    },
    {
        value: 0.5,
        label: '30 min before',
    },
    {
        value: 0.75,
        label: '45 min before',
    },
    {
        value: 1,
        label: '1 hour before',
    },
];

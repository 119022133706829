import { gql } from '@apollo/client';

export default class DeductionsReportQuery {
    static GET_DEDUCTIONS_PER_EMPLOYEE = gql`
        query getDeductionsPerEmployee($deductionId: Int!) {
            getDeductionsPerEmployee(deductionId: $deductionId) {
                employeeId
                employeeName
                amount
            }
        }
    `;
}

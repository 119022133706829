import React, { useState, useEffect } from 'react';
import {
    makeStyles, Grid,
    FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import {
    optoutMessageAction,
} from 'utils/enum/OpportunityEnum';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import { useQuery, useMutation } from '@apollo/client';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';

const useStyles = makeStyles(() => ({
    box: {
        height: 'auto',
        padding: '40px',
    },
}));

const UnsubscribeSettings = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        optoutBehavior: 0, // optoutMessageAction.EVERY_MESSAGE
    });

    const {
        optoutBehavior,
    } = state;

    const {
        OPTOUT_MESSAGE_BEHAVIOR,
    } = LotDefaultskey;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: ALL_LOTS,
            key: [
                OPTOUT_MESSAGE_BEHAVIOR,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading && settingsData) {
            const { getSettings } = settingsData;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                setState((prevState) => ({
                    ...prevState,
                    optoutBehavior: getSettings
                        .find((setting) => setting.key === OPTOUT_MESSAGE_BEHAVIOR)?.value === optoutMessageAction.FIRST_MESSAGE_ONLY ? 1 : 0,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    const [saveSettings] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const changeBehavior = (value) => {
        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: OPTOUT_MESSAGE_BEHAVIOR,
                value: value === 0 ? optoutMessageAction.EVERY_MESSAGE : optoutMessageAction.FIRST_MESSAGE_ONLY,
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        setState((prevState) => ({
            ...prevState,
            optoutBehavior: value,
        }));
    };

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12}>
                <RadioGroup column value={optoutBehavior} onChange={(e, val) => changeBehavior(Number(val))}>
                    <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="At the end of every message (Reply STOP to opt out)"
                    />
                    <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Once at the end of the first message (Reply STOP at anytime to opt out)"
                    />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

export default UnsubscribeSettings;

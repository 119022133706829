import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

const useLeadSources = (props = {}) => {
    const [leadSources, setLeadSources] = useState([]);
    const { data, loading, error } = useQuery(OpportunityQuery.GET_LEAD_SOURCES_FROM_CRM, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getLeadSourcesFromCRM)) {
            const { getLeadSourcesFromCRM } = data;
            const result = getLeadSourcesFromCRM.map((item) => ({ value: item.leadSourceId, label: item.leadSource }));
            setLeadSources(props.notIncludeEmptyValue ? result : [{ label: 'None', value: null }, ...result]);
        }
    }, [data, loading, error, props.notIncludeEmptyValue]);

    return {
        leadSources,
    };
};

export default useLeadSources;

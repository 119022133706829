import React, { useReducer, useRef } from 'react';

// Material Ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import moment from 'moment';
import { isFinite } from 'lodash';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import Assignee from 'components/widgets/Assignee';
import { EventStatus } from 'utils/enum/ActivitiesEnum';
import ActivitiesStyles from 'styles/modules/ActivitiesStyles';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import ActivityContent from 'components/modules/crm/activities/form/ActivityContent';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ActivityEventSchema } from 'utils/schema/lead/Activity';
import { reminderOption } from 'components/modules/crm/activities/form/ActivityHelper';

// GraphQL
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import MessageUtils from 'utils/MessageUtils';
import { useMutation } from '@apollo/client';
import ActivityMutation from 'services/graphQL/mutate/ActivityMutation';

const eventStatus = Object.keys(EventStatus).map((c) => ({ value: EventStatus[c], label: EventStatus[c] === EventStatus.SHOW ? 'Showed' : EventStatus[c] }));
const useStyles = makeStyles((theme) => ActivitiesStyles.activitiesForm(theme));
const keyStore = new KeyStore();
const initState = {
    subject: '',
    assignee: keyStore.getUserId(),
    note: '',
    reminderOn: 0,
    start: moment().toDate(),
    end: moment().toDate(),
    startTime: null,
    endTime: null,
    status: EventStatus.SCHEDULED,
};

const ACTION_TYPE = {
    ON_CHANGE_DATE: 'onChangeDate',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_CHANGE_STATUS: 'onChangeStatus',
    ON_CHANGE_END_TIME: 'onChangeEndTime',
    SET_ACTIVITY_EVENT: 'setActivityEvent',
    ON_CHANGE_START_TIME: 'onChangeStartTime',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPE.ON_CHANGE_START_TIME:
        const value = moment(action.value);
        const hoursMinutes = { minute: value.get('minute'), hour: value.get('hour') };

        const generatedEndTime = DateUtils.add(value, 1, 'hours');
        return update(state, {
            startTime: { $set: moment(state.start).set(hoursMinutes) },
            endTime: { $set: moment(state.start).set({ minute: generatedEndTime.get('minute'), hour: generatedEndTime.get('hour') }) },
        });
    case ACTION_TYPE.ON_CHANGE_END_TIME:
        if (state.endTime === null) {
            const time = moment(action.value, 'HH:mm');
            const currentEndTime = moment(state.start).set({
                hour: time.get('hour'),
                minute: time.get('minute'),
            });

            return update(state, {
                endTime: { $set: currentEndTime },
            });
        }

        return update(state, {
            endTime: { $set: action.value },
        });
    case ACTION_TYPE.ON_CHANGE_DATE:
        const isBeforeDate = moment(action.value).isBefore(moment(), 'day');
        const { endTime, startTime } = state;
        const currentEndTime = endTime != null ? moment(action.value).set({ minute: endTime.get('minute'), hour: endTime.get('hour') }) : null;
        const currentStartTime = startTime != null ? moment(action.value).set({ minute: startTime.get('minute'), hour: startTime.get('hour') }) : null;

        if (isBeforeDate) {
            return update(state, {
                reminderOn: { $set: 0 },
                start: { $set: action.value },
                endTime: { $set: currentEndTime },
                startTime: { $set: currentStartTime },
            });
        }

        return update(state, {
            start: { $set: action.value },
            endTime: { $set: currentEndTime },
            startTime: { $set: currentStartTime },
        });
    case ACTION_TYPE.ON_CHANGE_STATUS:
        if (![EventStatus.SCHEDULED, EventStatus.CONFIRMED].includes(action.value)) {
            return update(state, {
                status: { $set: action.value },
                reminderOn: { $set: 0 },
            });
        }

        return update(state, {
            status: { $set: action.value },
        });
    default:
        return state;
    }
};

const EventForm = ({
    onClose, crmId, lotId,
}) => {
    const selectRef = useRef();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const [createEvent, { loading: isSaving }] = useMutation(ActivityMutation.CREATE_EVENT_ACTIVITY);

    const onChangeValue = (value = '', field) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            field,
            value,
        });
    };

    const onChangeStartTime = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_START_TIME,
            value,
        });
    };

    const onChangeEndTime = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_END_TIME,
            value,
        });
    };

    const onChangeStatus = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_STATUS,
            value,
        });
    };

    const onChangeDate = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_DATE,
            value,
        });
    };

    const onSave = async (input) => {
        try {
            const response = await createEvent({ variables: { input } });

            if (response?.data?.createEventActivity) {
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('creating', 'appointment'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onSaveActivity = () => {
        const currentStart = DateUtils.formatUTC(state.start);
        const input = {
            processRecordId: crmId,
            subject: state.subject,
            assignee: state.assignee,
            note: state.note,
        };

        const currentStartTime = state.startTime ? moment(currentStart).set({
            hour: state.startTime.hour(), minute: state.startTime.minute(), second: 0, millisecond: 0,
        }) : currentStart;

        const currentEndTime = state.endTime ? moment(currentStart).set({
            hour: state.endTime.hour(), minute: state.endTime.minute(), second: 0, millisecond: 0,
        }) : currentStart;
        const startTime = DateUtils.formatUTC(currentStartTime);

        input.reminderOn = isFinite(state.reminderOn) && state.reminderOn !== 0
            ? DateUtils.subtractAndFormatUTC(startTime, state.reminderOn, 'hours') : null;
        input.realizationDate = startTime;
        input.event = {
            start: startTime,
            end: DateUtils.formatUTC(currentEndTime),
            status: state.status,
        };

        onSave(input);
    };

    const isValidData = isValidSchema(ActivityEventSchema, { ...state, startTime: state.startTime?.toDate(), endTime: state.endTime?.toDate() });
    const { isValid, errors } = isValidData;
    const enableReminder = [EventStatus.SCHEDULED, EventStatus.CONFIRMED].includes(state.status) && !moment(state.start).isBefore(moment(), 'day');

    return (
        <ActivityContent
            title="Event"
            onClose={onClose}
            disabledSaveButton={!isValid || isSaving}
            onSave={onSaveActivity}
        >
            <Form>
                <Box
                    display="flex"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography variant="subtitle1">Assignee:</Typography>
                    <Assignee
                        innerRef={selectRef}
                        value={state.assignee}
                        lotId={lotId}
                        onChange={(e) => {
                            selectRef.current.select.blur();
                            onChangeValue(e?.value, 'assignee');
                        }}
                        className={clsx({ 'invalid-field': isValidField(errors, 'assignee') })}
                    />
                </Box>
                <Form.Label>Date:</Form.Label>
                <Form.Row>
                    <Form.Group as={Col} className="col-md-5">
                        <DatePicker
                            className={clsx('form-control', { 'invalid-field': isValidField(errors, 'start') })}
                            selected={state.start}
                            popperContainer={CalendarContainer}
                            onChange={(date) => onChangeDate(date)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <TimePicker
                            use12Hours
                            minuteStep={15}
                            showSecond={false}
                            value={state.startTime}
                            placeholder="hh:mm"
                            onChange={(e) => onChangeStartTime(e)}
                            className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'startTime') })}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <TimePicker
                            use12Hours
                            minuteStep={15}
                            showSecond={false}
                            value={state.endTime}
                            placeholder="hh:mm"
                            disabled={state.startTime === null}
                            onChange={(e) => onChangeEndTime(e)}
                            className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'endTime') })}

                        />
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Select
                        name="status"
                        maxMenuHeight={100}
                        options={eventStatus}
                        value={state.status}
                        className={clsx({ 'invalid-field': isValidField(errors, 'status') })}
                        onChange={(_, value) => onChangeStatus(value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Reminder</Form.Label>
                    <Select
                        name="reminderOn"
                        maxMenuHeight={100}
                        options={reminderOption}
                        value={state.reminderOn}
                        disabled={!enableReminder}
                        onChange={(_, value) => onChangeValue(value, 'reminderOn')}
                    />
                </Form.Group>
                <Form.Group id="notegroup">
                    <Box
                        display="flex"
                        justifyContent="end"
                    >
                        <Form.Label className={classes.noteLabel}>{`${state.note?.length}/500`}</Form.Label>
                    </Box>
                    <Form.Control
                        as="textarea"
                        placeholder="Notes"
                        rows="4"
                        maxLength={500}
                        onChange={({ target }) => onChangeValue(target.value, 'note')}
                        value={state.note}
                    />
                </Form.Group>
            </Form>
        </ActivityContent>
    );
};

EventForm.propTypes = {
    lotId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
};

EventForm.defaultProps = {
    lotId: null,
};

export default EventForm;

import { gql } from '@apollo/client';

export default class CompanyQuery {
    static GET_COMPANY = gql`
        query getCompany {
            getCompany {
                companyCode
                companyDbName
                companyName
                companyAddress
                companyCity
                companyState
                companyZip
                companyCountry
                companyPhone
                companyFax
                companyWebsite
                companyContact
                companyEin
                companyEmail
                carfaxId
                secureCloseDmsguid
                secureCloseEnabled
                companyTimeZoneId
                companyTimezone
                companyLogo
                plaidClientId
                plaidSecretKey
            }
        }
    `;

    static GET_COMPANY_THEME = gql`
        query getCompanyTheme {
            getCompanyTheme {
                key
                value
                companySettingId
            }
        }
    `;

    static GET_COMPANY_CARFAX = gql`
    query getCompanyCarfax {
        getCompanyCarfax {
            value
        }
    }
    `;
}

import { gql } from '@apollo/client';

export default class BulletingBoardQuery {
    static GET_BULLETIN_BOARD_COMPONENTS_ELEMENTS = gql`
    query getBulletinBoardComponentsAndElements {
        getBulletinBoardComponentsAndElements {
            bulletinBoardComponentId
            componentType
            groupTitle
            positionX
            positionY
            width
            height
            minimumWidth
            minimumHeight
            isDraggable
            isResizable
            isBounded
            elements {
                id
                title
                value
                type
                isPinned
                createdOn
                modifiedOn
            }
        }
    }
    `;

    static GET_ALL_MESSAGES_COMPONENT = gql`
    query getAllMessagesComponent($bulletinBoardComponentId: Int!) {
        getAllMessagesComponent(bulletinBoardComponentId: $bulletinBoardComponentId){
            id
            title
            value
            type
            isPinned
        }
    }
    `;

    static GET_SIGNED_URL_UPLOAD_PDF = gql`
        query getSignedURLUploadPDF {
            getSignedURLUploadPDF {
                url
                path
            }
        }
    `;

    static GET_SECURE_URL_PDF = gql`
        query getSecureURLPDF($path: String!) {
            getSecureURLPDF(path: $path)
        }
    `;

    static GET_SIGNED_URL_UPLOAD_IMAGE = gql`
        query getSignedURLUploadImage($fileExtension: String!) {
            getSignedURLUploadImage(fileExtension: $fileExtension) {
                url
                path
            }
        }
    `;

    static GET_SECURE_URL_IMAGE = gql`
        query getSecureURLImage($path: String!) {
            getSecureURLImage(path: $path)
        }
    `;
}

import { gql } from '@apollo/client';

export default class HoursWorkedQuery {
    static GET_HOURS_WORKED_LIST = gql`
        query getEmployeeHoursList(
            $employeeId: Int!,
            $lotName: String!,
            $payType: String!,
            $payPeriod: String!
        ) {
            getEmployeeHoursList(
                lotName: $lotName,
                payType: $payType,
                payPeriod: $payPeriod,
                employeeId: $employeeId
            ) {
                actualHours {
                    date
                    timeIn
                    timeOut
                    dayOfWeek
                    totalHours
                    timeInReason
                    timeOutReason
                }
                adjustedHours {
                    date
                    errors
                    status
                    timeId
                    timeIn
                    timeOut
                    approved
                    warnings
                    dayOfWeek
                    timeInFull
                    totalHours
                    timeOutFull
                    timeInReason
                    timeOutReason
                }
                actualTotal
                adjustedTotal
                actualRegular
                actualOvertime
                adjustedRegular
                adjustedOvertime
            }
        }
    `;
}

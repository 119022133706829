/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
    IconButton, Badge, Popover, makeStyles,
    Typography, Divider, ListItem, ListItemText, List,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    popover: {
        minHeight: 60,
        width: 360,
        maxHeight: '90%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        borderRadius: theme.spacing(1),
    },
    notificationList: {
        overflow: 'auto',
    },
    bodyMessage: {
        position: 'relative',
        width: '100%',
    },
    textPrimary: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    divider: {
        borderStyle: 'dashed',
        borderWidth: '0px 0px thin',
        borderColor: 'rgba(145, 158, 171, 0.24)',
    },
    textMessage: {
        fontWeight: 400,
        color: 'rgb(99, 115, 129)',
        margin: theme.spacing(0.5, 0),
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 20px',
    },
    iconPadding: {
        padding: '0px 5px',
        color: theme.palette.warning.main,
    },
}));

const UnpaidEmployeeInformation = ({ list }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.iconContainer}>
            <IconButton className={classes.iconPadding} aria-describedby={id} onClick={handleClick}>
                <Badge
                    badgeContent={list.reduce((sum, current) => sum + current.detail.length, 0)}
                    color="secondary"
                    children={<ErrorOutlineIcon />}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <div className={classes.header}>
                    <Typography variant="h6">Reconcile the following to remove the employee from the list:</Typography>
                </div>
                <Divider className={classes.divider} />
                <List className={classes.notificationList}>
                    {list.map((item, index) => {
                        const { title, detail } = item;

                        return (
                            <ListItem
                                key={index}
                            >
                                <ListItemText
                                    classes={{ primary: classes.textPrimary }}
                                    primary={(
                                        <>
                                            <div className={classes.bodyMessage}>
                                                <Typography variant="h6">
                                                    {title}
                                                </Typography>
                                                {
                                                    detail.map(
                                                        (error, errorIndex) => (
                                                            <Typography key={errorIndex} className={classes.textMessage}>{`- ${error}`}</Typography>
                                                        ),
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </div>
    );
};

UnpaidEmployeeInformation.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default UnpaidEmployeeInformation;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const ownStyles = makeStyles(() => ({
    frame: {
        border: '1px solid #ced4da61',
        position: 'relative',
        padding: '5px 5px 0 5px',
        marginBottom: '20px',
        '&:before': {
            content: 'attr(data-title)',
            background: '#fff',
            position: 'absolute',
            color: '#575555',
            left: 10,
            top: -10,
            fontSize: '12px',
        },
    },
}));

const FieldsetTitle = (props) => {
    const { title, children } = props;

    const classes = {
        ...ownStyles(),
    };

    return (
        <div className={classes.frame} data-title={title}>
            {children}
        </div>
    );
};

FieldsetTitle.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

FieldsetTitle.defaultProps = {
    title: '',
    children: null,
};

export default FieldsetTitle;

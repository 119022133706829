import React from 'react';
import Lightbox from 'lightbox-react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import PhotosGalleryStyle from 'styles/widgets/inventory/PhotosGalleryStyle';

const useStyles = makeStyles(PhotosGalleryStyle.gallery());

const PhotosGallery = ({
    isOpen,
    photos,
    photoIndex,
    onCloseRequest,
    onMovePrevRequest,
    onMoveNextRequest,
}) => {
    const classes = useStyles();

    return (
        <div columns={classes.main}>
            <div>
                {isOpen && (
                    <Lightbox
                        mainSrc={photos[photoIndex]}
                        nextSrc={photos[(photoIndex + 1) % photos.length]}
                        prevSrc={
                            photos[
                                (photoIndex + photos.length - 1) % photos.length
                            ]
                        }
                        onCloseRequest={() => onCloseRequest()}
                        onMovePrevRequest={onMovePrevRequest}
                        onMoveNextRequest={onMoveNextRequest}
                    />
                )}
            </div>
        </div>
    );
};

PhotosGallery.propTypes = {
    isOpen: PropTypes.bool,
    photos: PropTypes.array,
    photoIndex: PropTypes.number,
    onCloseRequest: PropTypes.func.isRequired,
    onMovePrevRequest: PropTypes.func.isRequired,
    onMoveNextRequest: PropTypes.func.isRequired,
};

PhotosGallery.defaultProps = {
    isOpen: false,
    photos: [],
    photoIndex: 0,
};

export default PhotosGallery;

/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery } from '@apollo/client';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import { makeStyles, Button } from '@material-ui/core';
import InputSearch from 'components/widgets/InputSearch';
import { AddIcon, EditOutlinedIcon } from 'components/icons';
import FormsMapperQuery from 'services/graphQL/query/setting/FormsMapperQuery';
import FormMapperDialog from 'components/modules/settings/form/FormMapperDialog';

const useStyles = makeStyles((theme) => ({
    tableHeight: {
        display: 'contents',
    },
    boxContent: {
        overflow: 'hidden',
    },
    root: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        flex: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    buttonSpacing: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
    hidden: {
        display: 'none',
    },
    actionButtonSuccess: {
        color: theme.palette.text.outerSpace,
    },
    actionButtonError: {
        color: theme.palette.error.main,
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            left: '5px',
            width: '15%',
            display: 'flex',
            paddingTop: '4px',
            textAlign: 'left',
            paddingBottom: '4px',
            position: 'absolute',
        },
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    searchBox: {
        width: 'auto',
        marginLeft: 20,
    },
}));

const ACTION_TYPES = {
    ON_SEARCH: 'onSearch',
    INIT_STATE: 'initState',
    APPLY_FILTER: 'applyFilter',
    TOGGLE_ADD_EDIT_DIALOG: 'toggleAddEditDialog',
};

const reducer = (state, action) => {
    const {
        type,
        value,
        accessor,
    } = action;
    switch (type) {
    case ACTION_TYPES.INIT_STATE:
        return update(state, {
            items: { $set: value },
            loadTable: { $set: true },
            selectedRecord: { $set: null },
            openAddEditDialog: { $set: false },
            openDeleteDialoog: { $set: false },
        });
    case ACTION_TYPES.ON_SEARCH:
        return update(state, {
            search: { $set: value },
            loadTable: { $set: false },
        });
    case ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG:
        const { openAddEditDialog } = state;
        return update(state, {
            selectedRecord: { $set: value },
            openAddEditDialog: { $set: !openAddEditDialog },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const initState = {
    items: [],
    search: '',
    loadTable: false,
    filtersApplied: [],
    selectedRecord: null,
    openAddEditDialog: false,
};

const FormMapper = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, initState);

    const {
        openAddEditDialog, loadTable,
        items, search, filtersApplied, selectedRecord,
    } = state;

    const [getData, {
        loading, data, error, refetch,
    }] = useLazyQuery(FormsMapperQuery.GET_FORMS_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        getData({
            variables: {
                search,
            },
        });
    }, [search, getData]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            dispatch({
                value: data.getFormsList,
                type: ACTION_TYPES.INIT_STATE,
            });
        }
    }, [data, loading, error]);

    const toggleAddEdit = ({ record = null, saving = false }) => {
        dispatch({
            value: record,
            type: ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG,
        });
        if (saving) refetch();
    };

    const onSearch = (value) => {
        dispatch({
            value,
            type: ACTION_TYPES.ON_SEARCH,
        });
    };

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const getColumns = () => [{
        id: 'id',
        width: 120,
        Header: 'ID',
        accessor: 'id',
        className: 'd-flex-justify-center-align-center',
    }, {
        width: 120,
        id: 'state',
        Header: 'State',
        accessor: 'state',
        className: 'd-flex-justify-center-align-center',
    }, {
        width: 120,
        id: 'category',
        Header: 'Category',
        accessor: 'category',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Name',
        id: 'commonName',
        accessor: 'commonName',
    }, {
        id: 'actions',
        width: 'auto',
        Header: 'Actions',
        headerClassName: clsx(classes.hidden),
        className: clsx(classes.hidden, 'actionColumnTarget'),
        Cell: (cellData) => {
            const { original } = cellData;

            return (
                <div className={classes.buttonSpacing}>
                    {keyStore.hasPermission(Permission.FORMS_SETTINGS_MAPPER_WRITE) && (
                        <>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => toggleAddEdit({ record: original })}
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                            >
                                Edit
                            </Button>
                        </>
                    )}
                </div>
            );
        },
    }];

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            column.Header = (
                <div
                    className={classes.filterContainer}
                >
                    {column.Header}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Filter
                            showTooltip
                            showIconOnly
                            useInternalSearch
                            maxWidthLabel={200}
                            records={getFilterValues(items, column.id)}
                            onClearFilter={() => resetFilters(column.id)}
                            applyFilter={(record) => onFilteredChangeCustom(
                                record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                            )}
                        />
                    </div>
                </div>
            );
        });
        return columns;
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    {keyStore.hasPermission(Permission.FORMS_SETTINGS_MAPPER_WRITE) && (
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={toggleAddEdit}
                        >
                            New Form
                        </Button>
                    )}
                    <InputSearch
                        size="sm"
                        onSearch={onSearch}
                        initialSearch={search || ''}
                        customClasses={classes.searchBox}
                    />
                </div>
                <div className={classes.tableHeight}>
                    {!loadTable && (
                        <div className={classes.loadingIndicator}>
                            <Loading />
                        </div>
                    )}
                    {loadTable && (
                        <Table
                            data={items}
                            load={loading}
                            columns={loadColumns()}
                            filtered={filtersApplied}
                            totalRecords={items.count}
                            className="-highlight actionsInLine payrollTable"
                            defaultFilterMethod={(localFilter, row) => {
                                const id = localFilter.pivotId || localFilter.id;
                                if (typeof localFilter.value === 'object') {
                                    return row[id] !== undefined
                                        ? localFilter.value.indexOf(row[id]) > -1
                                        : true;
                                }
                                return row[id] !== undefined
                                    ? String(row[id]).indexOf(localFilter.value) > -1
                                    : true;
                            }}
                        />
                    )}
                </div>
            </div>
            {openAddEditDialog && (
                <FormMapperDialog onClose={toggleAddEdit} formId={selectedRecord?.id || 0} />
            )}
        </>
    );
};

export default FormMapper;

import * as yup from 'yup';

const PartsInventorySchema = yup.object().shape({
    partStockNumber: yup.string().required('Part # is required').max(50, 'Part # has a maximum limit of 50 characters'),
    description: yup.string().required('Description is required').max(50, 'Description has a maximum limit of 50 characters'),
    binNumber: yup.string().max(50, 'bin has a maximum limit of 50 characters'),
    vendorId: yup.number().required().moreThan(0, 'Vendor is required'),
    quickNote: yup.string().max(250, 'NotEs has a maximum limit of 250 characters'),
    quantityOnHand: yup.number(),
    reorderQuantity: yup.number(),
    partCost: yup.number(),
    netPrice: yup.number(),
    notes: yup.string(),
    shelf: yup.string().nullable().max(250, 'Shelf has a maximum limit of 250 characters'),
});

export default PartsInventorySchema;

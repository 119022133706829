import React, { useReducer } from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    DialogContent,
    Dialog,
    Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ButtonStyles from 'styles/theme/Button';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import If from 'components/widgets/conditional/If';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CommonReducer, { ACTION_TYPES } from 'components/modules/home/reducer/CommonReducer';
import BulletinBoardStyles from 'styles/modules/home/BulletinBoardStyles';
import { ComponentType } from 'utils/enum/BulletinBoardEnum';
import BulletingBoardMutation from 'services/graphQL/mutate/home/BulletingBoardMutation';

const useStyle = makeStyles((theme) => BulletinBoardStyles.board(theme));
const iframeStyle = makeStyles((theme) => BulletinBoardStyles.iframe(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const IFrame = ({
    component,
    actionRemove,
    permission,
}) => {
    const classes = { ...useStyle(), ...buttonStyles() };
    const iframeClasses = iframeStyle();
    const { elements, bulletinBoardComponentId } = component;
    const frameData = elements.find((element) => element.type === ComponentType.IFRAME)
    || { title: '', value: '' };

    const INITIAL_STATE = {
        data: frameData,
        currentTitle: frameData.title,
        currentURL: frameData.value,
        isEditFormOpen: false,
        isContentPreviewOpen: false,
    };

    const [state, dispatch] = useReducer(CommonReducer, INITIAL_STATE);
    const {
        data,
        currentTitle,
        currentURL,
        isEditFormOpen,
        isContentPreviewOpen,
    } = state;

    const onAddEditFormChange = ({ target: { name, value } }) => {
        dispatch({
            type: ACTION_TYPES.SET_DATA,
            value: { name, value },
        });
    };

    const openEditForm = () => {
        dispatch({
            type: ACTION_TYPES.OPEN_EDIT_FORM,
        });
    };

    const openContentPreviewDialog = () => {
        dispatch({
            type: ACTION_TYPES.OPEN_CONTENT_PREVIEW,
        });
    };

    const closeDialog = () => {
        dispatch({
            type: ACTION_TYPES.CLOSE_DIALOG,
        });
    };

    const [saveIFrameComponent, { loading }] = useMutation(BulletingBoardMutation.SAVE_IFRAME_COMPONENT, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SAVE_IFRAME_DATA,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const onDataSave = async () => {
        if (!StringUtils.validateURL(currentURL)) {
            ModalUtils.errorMessage(null, 'Double check the url typed in.');
            return;
        }

        saveIFrameComponent({ variables: { bulletinBoardComponentId, data: { title: currentTitle, url: currentURL } } });
    };

    const isYoutubeVideo = (content) => {
        if (StringUtils.isEmpty(content)) return false;

        return content.includes('youtube.com')
        || content.includes('youtu.be');
    };

    const renderIframe = (url = '') => {
        let content = url;
        if (isYoutubeVideo(content)) {
            const { searchParams } = new URL(content);
            const videoId = searchParams.get('v') || content.substring(content.lastIndexOf('/') + 1);

            if (videoId) content = `https://www.youtube.com/embed/${videoId}`;
        }

        return (
            (
                <iframe
                    className={iframeClasses.iframe}
                    title={currentTitle}
                    src={content}
                    frameBorder="0"
                    allowFullScreen
                />
            )
        );
    };

    return (
        <div className={iframeClasses.container}>
            <If condition={permission}>
                <Button
                    className={clsx(classes.containedError, classes.actionClosePanel)}
                    size="small"
                    onClick={() => actionRemove(component.bulletinBoardComponentId)}
                >
                    x
                </Button>
            </If>
            <div className={iframeClasses.title}>
                <Form.Group>
                    <Form.Control
                        readOnly
                        as="input"
                        size="md"
                        placeholder="Title"
                        value={data.title}
                    />
                </Form.Group>
            </div>
            <div className={iframeClasses.content}>
                <If condition={data.value === ''}>
                    <VideoLibraryIcon />
                </If>
                <If condition={data.value !== ''}>
                    {renderIframe(data.value)}
                </If>
            </div>
            <If condition={permission}>
                <div className={iframeClasses.elementActions}>
                    <IconButton
                        onClick={openEditForm}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        disabled={data.value === ''}
                        onClick={openContentPreviewDialog}
                    >
                        <LaunchIcon />
                    </IconButton>
                </div>
                <Dialog
                    open={isEditFormOpen || isContentPreviewOpen}
                    fullWidth
                    maxWidth={isEditFormOpen ? 'sm' : 'md'}
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar title={isEditFormOpen ? 'Edit IFrame' : 'Content Preview'} onClose={closeDialog} toolbarSize="sm" />
                    <DialogContent>
                        <If condition={isEditFormOpen}>
                            <Form.Group>
                                <Form.Label size="sm">Title</Form.Label>
                                <Form.Control
                                    as="input"
                                    size="sm"
                                    name="title"
                                    value={currentTitle}
                                    onChange={onAddEditFormChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label size="sm">URL</Form.Label>
                                <Form.Control
                                    as="input"
                                    size="sm"
                                    name="url"
                                    value={currentURL}
                                    onChange={onAddEditFormChange}
                                />
                            </Form.Group>
                        </If>
                        <If condition={isContentPreviewOpen}>
                            <div className={iframeClasses.contentPreview}>
                                {renderIframe(currentURL)}
                            </div>
                        </If>
                    </DialogContent>
                    <If condition={isEditFormOpen}>
                        <DialogActions
                            titlePrimary="Save"
                            onClickSecondary={closeDialog}
                            onClickPrimary={onDataSave}
                            disablePrimaryButton={currentURL === '' || loading}
                        />
                    </If>
                </Dialog>
            </If>
        </div>
    );
};

IFrame.propTypes = {
    component: PropTypes.object.isRequired,
    actionRemove: PropTypes.func.isRequired,
    permission: PropTypes.bool.isRequired,
};

export default IFrame;

import React from 'react';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import { JournalTypes, ShowJournalType } from 'utils/enum/AccountingEnum';
import clsx from 'clsx';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { makeStyles, Chip } from '@material-ui/core';
import DateUtils from 'lib/DateUtils';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from 'components/icons';
import { DataSort } from 'utils/enum/Core';

const useStyle = makeStyles((theme) => AccountingStyles.columnStyles(theme));

const useJournalMethods = (journaltType, journalOption) => {
    const classes = useStyle();

    let graphQLMethod = '';
    let processColumns = [];
    let responseMethod = '';
    let primaryKey = '';
    let dateFieldColumn = '';
    let labelPostedOption = '';
    const sort = [];

    const dealsColumns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Deal #',
            accessor: 'accountNumber',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Sold Date',
            accessor: 'soldDate',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Posted Date',
            accessor: 'postedDate',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Customer',
            id: 'buyer',
            minWidth: 220,
            width: 220,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => {
                const { original: { firstName, lastName } } = cell;
                return `${firstName} ${lastName}`;
            },
        },
        {
            Header: 'Stock #',
            accessor: 'stockNumber',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Deal Type',
            accessor: 'dealType',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Funding',
            accessor: 'dealFunded',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Lot Name',
            accessor: 'lotName',
            minWidth: 150,
            width: 150,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            accessor: 'status',
            minWidth: 120,
            width: 120,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                const badgeStatus = classes.primary;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Notes',
            accessor: 'notes',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
    ];

    const serviceColumns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'RO #',
            accessor: 'invoiceNumber',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Open Date',
            accessor: 'roOpen',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Closed Date',
            accessor: 'roClosed',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Post Date',
            accessor: 'postedDate',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Customer',
            id: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => {
                const { original: { firstName, lastName } } = cell;
                return `${firstName} ${lastName}`;
            },
        },
        {
            Header: 'RO Type',
            accessor: 'roType',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Lot Name',
            accessor: 'lotName',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            accessor: 'status',
            minWidth: 150,
            width: 150,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                const badgeStatus = classes.primary;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
    ];

    const inventoryColumns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Stock #',
            accessor: 'stockNumber',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Purchased Date',
            accessor: 'purchasedDate',
            minWidth: 120,
            width: 120,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Post Date',
            accessor: 'postedDate',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'VIN',
            accessor: 'vin',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Year',
            accessor: 'year',
            minWidth: 70,
            width: 70,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Make',
            accessor: 'make',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Model',
            accessor: 'model',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Trim',
            accessor: 'trim',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Color',
            accessor: 'extColor',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Floored',
            accessor: 'isFloorPlanned',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cell) => (cell.value ? <CheckBoxIcon className={classes.highlightedColor} />
                : <CheckBoxOutlineBlankIcon className={classes.highlightedColor} />),
        },
        {
            Header: 'Lot Name',
            accessor: 'lotName',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
    ];

    const partsTicketColumns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Ticket #',
            accessor: 'ticketNumber',
            minWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Opened Date',
            accessor: 'ticketOpen',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnCenter, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Post Date',
            accessor: 'postedDate',
            minWidth: 130,
            width: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Customer',
            id: 'customer',
            minWidth: 220,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => {
                const { original: { customer } } = cell;
                return `${customer?.firstName} ${customer?.lastName}`;
            },
        },
        {
            Header: 'Sales Type',
            accessor: 'salesType',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Lot Name',
            accessor: 'lotName',
            minWidth: 200,
            width: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            accessor: 'status',
            minWidth: 90,
            width: 90,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                const badgeStatus = classes.primary;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
    ];

    switch (journaltType) {
    case JournalTypes.DEALS:
        graphQLMethod = AccountingGLQuery.DEALS_JOURNAL_LIST;
        processColumns = dealsColumns;
        responseMethod = 'getDealsJournalList';
        primaryKey = 'accountNumber';
        dateFieldColumn = Number(journalOption) === Number(ShowJournalType.SHOW_NO_POSTED) ? 'soldDate' : 'postedDate';
        labelPostedOption = '';
        sort.push({
            fieldName: 'soldDate',
            dir: DataSort.DESC,
        });
        break;
    case JournalTypes.INVENTORY:
        graphQLMethod = AccountingGLQuery.INVENTORY_JOURNAL_LIST;
        processColumns = inventoryColumns;
        responseMethod = 'getInventoryJournalList';
        primaryKey = 'stockNumber';
        dateFieldColumn = 'purchasedDate';
        labelPostedOption = 'Purchased Date';
        sort.push({
            fieldName: 'purchasedDate',
            dir: DataSort.DESC,
        });
        break;
    case JournalTypes.SERVICE:
        graphQLMethod = AccountingGLQuery.SERVICE_JOURNAL_LIST;
        processColumns = serviceColumns;
        responseMethod = 'getServiceJournalList';
        primaryKey = 'invoiceNumber';
        dateFieldColumn = 'roClosed';
        labelPostedOption = 'Closed Date';
        sort.push({
            fieldName: 'roOpen',
            dir: DataSort.DESC,
        });
        break;
    case JournalTypes.PARTS_TICKET:
        graphQLMethod = AccountingGLQuery.PARTS_JOURNAL_LIST;
        processColumns = partsTicketColumns;
        responseMethod = 'getPartsJournalList';
        primaryKey = 'ticketNumber';
        sort.push({
            fieldName: 'ticketOpen',
            dir: DataSort.DESC,
        });
        break;
    default:
        graphQLMethod = AccountingGLQuery.DEALS_JOURNAL_LIST;
        processColumns = dealsColumns;
        responseMethod = 'getDealsJournalList';
        primaryKey = 'accountNumber';
        dateFieldColumn = Number(journalOption) === Number(ShowJournalType.SHOW_NO_POSTED) ? 'soldDate' : 'postedDate';
        labelPostedOption = '';
        sort.push({
            fieldName: 'soldDate',
            dir: DataSort.DESC,
        });
        break;
    }

    return {
        graphQLMethod,
        processColumns,
        responseMethod,
        primaryKey,
        sort,
        dateFieldColumn,
        labelPostedOption,
    };
};

export default useJournalMethods;

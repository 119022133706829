import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import CatalogEnum from 'utils/enum/CatalogEnum';
import { useMutation, useQuery } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import PlaidMapSchema from 'utils/schema/plaid/PlaidMapSchema';
import useEnumValues from 'components/hook/core/useEnumValues';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import PlaidBankDialog from 'components/modules/settings/plaid/PlaidBankDialog';
import PlaidMappingsQuery from 'services/graphQL/query/plaid/PlaidMappingsQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import PlaidMappingsMutation from 'services/graphQL/mutate/plaid/PlaidMappingsMutation';
import PlaidBankAccountDialog from 'components/modules/settings/plaid/PlaidBankAccountDialog';
import {
    Dialog, DialogContent, makeStyles, Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 175,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
            minWidth: 550,
        },
    },
    checkBox: {
        alignSelf: 'baseline',
    },
}));

const initialState = {
    active: true,
    institutionId: '',
    institutionName: '',
    bankAccountNumber: 0,
    plaidAccessToken: '',
    openBankDialog: false,
    institutionAccountId: '',
    institutionAccountName: '',
    openBankAccountDialog: false,
    plaidTransactionCategory: '',
    institutionAccountLastFour: '',
};

const PlaidMappingDialog = ({ onClose, mapId }) => {
    const isNew = isEmpty(mapId);
    const classes = useStyles();
    const [state, setState] = useState(initialState);
    const { enumValues: categoryList } = useEnumValues({ catalog: CatalogEnum.PLAID_TRANSACTION_CATEGORY });
    const [savePlaidMapping, { loading: updating }] = useMutation(PlaidMappingsMutation.UPSERT_PLAID_MAPPING);

    const {
        data, loading, error,
    } = useQuery(PlaidMappingsQuery.GET_PLAID_MAPPING, {
        skip: isNew,
        variables: { mapId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getPlaidMapping)) {
            const { getPlaidMapping } = data;
            setState({ ...getPlaidMapping });
        }
    }, [data, loading, error]);

    const onChange = (field, value) => {
        setState((item) => ({
            ...item,
            [field]: value,
        }));
    };

    const toggleBankDialog = () => {
        setState((item) => ({
            ...item,
            openBankDialog: !item.openBankDialog,
        }));
    };

    const toggleBankAccountDialog = () => {
        setState((item) => ({
            ...item,
            openBankAccountDialog: !item.openBankAccountDialog,
        }));
    };

    const onBankSelected = ({ institutionId, institutionName }) => {
        setState((item) => ({
            ...item,
            institutionId,
            institutionName,
            openBankDialog: false,
            institutionAccountId: '',
            institutionAccountName: '',
            institutionAccountLastFour: '',
        }));
    };

    const onBankAccountSelected = ({
        institutionAccountId, institutionAccountName, institutionAccountLastFour, plaidAccessToken,
    }) => {
        setState((item) => ({
            ...item,
            plaidAccessToken,
            institutionAccountId,
            institutionAccountName,
            institutionAccountLastFour,
            openBankAccountDialog: false,
        }));
    };

    const onSave = async () => {
        try {
            const input = { ...state };

            delete input.openBankDialog;
            delete input.openBankAccountDialog;

            const response = await savePlaidMapping({
                variables: {
                    input,
                    mapId,
                },
            });

            if (response.data?.upsertPlaidMapping) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({ id: null, saving: true });
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Plaid Mapping'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const isValidData = isValidSchema(PlaidMapSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <>
            <Dialog
                open
                maxWidth="md"
            >
                <DialogAppBar
                    title={isNew ? 'New Record' : 'Editing record'}
                    onClose={() => onClose({ id: null, saving: false })}
                />
                <DialogContent className={classes.dialogContent}>
                    <Form>
                        <Row className="g-2">
                            <Col xl>
                                <Form.Row className={classes.row}>
                                    <Form.Group as={Row} className={classes.group}>
                                        <Form.Label>Bank Account #:</Form.Label>
                                        <DropdownQuery
                                            name="bankAccountNumber"
                                            value={state.bankAccountNumber}
                                            placeholder="select an account"
                                            onChange={onChange}
                                            className={isValidField(errors, 'bankAccountNumber') ? 'invalid-field' : ''}
                                            dataSource={{
                                                query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                variables: {
                                                    paginate: {
                                                        init: 0,
                                                        ignoreLimit: true,
                                                    },
                                                    filters: {
                                                        accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                    },
                                                },
                                                rootData: 'getAccountingCOAList.data',
                                                idField: 'accountNumber',
                                                descriptionField: 'fullDescription',
                                                additionalFieldsReturned: ['fullDescription'],
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Tooltip title="Double click to select a Bank">
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>Bank Name:</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                contentEditable={false}
                                                value={state.institutionName}
                                                onDoubleClick={toggleBankDialog}
                                                placeholder="Double click to select a Bank"
                                                className={isValidField(errors, 'institutionName') ? 'invalid-field' : ''}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Tooltip>
                                <Tooltip title="Double click to select a Bank Account">
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>Last Four:</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                contentEditable={false}
                                                onDoubleClick={toggleBankAccountDialog}
                                                disabled={isEmpty(state.institutionId)}
                                                value={state.institutionAccountLastFour}
                                                placeholder="Double click to select a Bank Account"
                                                className={isValidField(errors, 'institutionAccountLastFour') ? 'invalid-field' : ''}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Tooltip>
                                <Tooltip title="Double click to select a Bank Account">
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>Bank Account Name:</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                contentEditable={false}
                                                value={state.institutionAccountName}
                                                onDoubleClick={toggleBankAccountDialog}
                                                disabled={isEmpty(state.institutionId)}
                                                placeholder="Double click to select a Bank Account"
                                                className={isValidField(errors, 'institutionAccountName') ? 'invalid-field' : ''}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Tooltip>
                                <Tooltip title={state.plaidTransactionCategory}>
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>Plaid Transaction Category:</Form.Label>
                                            <Select
                                                onChange={onChange}
                                                options={categoryList}
                                                name="plaidTransactionCategory"
                                                value={state.plaidTransactionCategory}
                                                className={isValidField(errors, 'plaidTransactionCategory') ? 'invalid-field' : ''}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Tooltip>
                                <Form.Row>
                                    <Form.Group as={Row} className={classes.group}>
                                        <Form.Label>Active:</Form.Label>
                                        <Form.Check
                                            id="active"
                                            type="checkbox"
                                            disabled={isNew}
                                            checked={state.active}
                                            className={classes.checkBox}
                                            onChange={(e) => onChange('active', e.target.checked)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Col>
                        </Row>
                    </Form>
                </DialogContent>
                <DialogActions
                    variant="contained"
                    titlePrimary="Save"
                    onClickPrimary={onSave}
                    titleSecondary="Cancel"
                    disablePrimaryButton={!isValid || updating}
                    onClickSecondary={() => onClose({ id: null, saving: false })}
                />
            </Dialog>
            {state.openBankDialog && (
                <PlaidBankDialog
                    onClose={toggleBankDialog}
                    onBankSelected={onBankSelected}
                />
            )}
            {state.openBankAccountDialog && (
                <PlaidBankAccountDialog
                    bankId={state.institutionId}
                    onClose={toggleBankAccountDialog}
                    onBankAccountSelected={onBankAccountSelected}
                />
            )}
        </>
    );
};

PlaidMappingDialog.propTypes = {
    mapId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

PlaidMappingDialog.defaultProps = {
    mapId: null,
};

export default PlaidMappingDialog;

import React from 'react';

// Material UI
import { makeStyles, Typography } from '@material-ui/core';

// Components and Others
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
}));

const Summary = ({ debit, credit, displayInline }) => {
    const classes = useStyles();

    return (
        <Typography
            variant="h6"
            className={displayInline ? classes.summaryTextInLine : classes.summaryText}
        >
            <span>Debit:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(debit || 0)}</span>
            <span>Credit:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(credit || 0)}</span>
            <span>Difference:&nbsp;</span>
            <span className={clsx(classes.contentSpace, NumberUtils.round(debit - credit) !== 0 ? classes.difference : '')}>
                {NumberUtils.applyCurrencyFormat(NumberUtils.round(debit - credit) || 0)}
            </span>
        </Typography>
    );
};

Summary.propTypes = {
    debit: PropTypes.number.isRequired,
    credit: PropTypes.number.isRequired,
    displayInline: PropTypes.bool,
};

Summary.defaultProps = {
    displayInline: false,
};

export default Summary;

import React from 'react';
import PropTypes from 'prop-types';
import Search from 'components/widgets/Search';
import { makeStyles } from '@material-ui/core/styles';

// Style
import QueriesBaseLayoutStyles from 'styles/modules/queries/QueriesBaseLayoutStyles';

const useStyles = makeStyles((theme) => QueriesBaseLayoutStyles.childList(theme));

const QueryList = ({
    data,
    selected,
    action,
    actionFilter,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.label}>
                Query Name
            </div>
            <div className={classes.boxSearch}>
                <Search fullWidth onKeyDown={actionFilter} />
            </div>
            <div className={classes.list}>
                {data.map((query, index) => (
                    <div
                        className={selected === query.savedQuerieId ? classes.listItemSelected : classes.listItem}
                        onClick={(e) => action(e)}
                        data-queryid={query.savedQuerieId}
                        key={index}
                    >
                        {query?.queryName?.toUpperCase()}
                    </div>
                ))}
            </div>
        </div>
    );
};

QueryList.defaultProps = {
    data: [],
    selected: 0,
};

QueryList.propTypes = {
    data: PropTypes.array,
    selected: PropTypes.number,
    action: PropTypes.func.isRequired,
    actionFilter: PropTypes.func.isRequired,
};

export default QueryList;

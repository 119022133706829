import { InvoiceStatus } from 'utils/enum/AccountingEnum';

const useValidateInvoiceActions = (status) => {
    let editAccountNumber = true;
    let editLot = true;
    let editMemo = true;
    let editDate = true;
    let editAddress = true;
    let editPayTo = true;
    let editIsCredit = true;
    let allowSaveAction = true;
    let allowClearAction = true;
    let editInvoiceNumber = true;
    let glOptions = {
        editglAccount: true,
        editglAmount: true,
        editglControlNumber: true,
        editglLot: true,
        editglMemo: true,
        showNewAction: true,
        showSplitAction: true,
        showDeleteAction: true,
    };

    if (status.toLowerCase() === InvoiceStatus.VOID.toLowerCase()) {
        editAccountNumber = false;
        editLot = false;
        editMemo = false;
        editDate = false;
        editAddress = false;
        editPayTo = false;
        editInvoiceNumber = false;
        editIsCredit = false;
        allowSaveAction = false;
        allowClearAction = false;
        glOptions = {
            editglAccount: false,
            editglAmount: false,
            editglControlNumber: false,
            editglLot: false,
            editglMemo: false,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: false,
        };
    } else if (status.toLowerCase() === InvoiceStatus.PARTIALLY_PAID.toLowerCase()
    || status.toLowerCase() === InvoiceStatus.PAID.toLowerCase()) {
        editAccountNumber = false;
        editPayTo = false;
        editInvoiceNumber = false;
        editIsCredit = false;
        allowClearAction = false;
        glOptions = {
            editglAccount: true,
            editglAmount: true,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: true,
            showSplitAction: true,
            showDeleteAction: true,
        };
    }

    return {
        editAccountNumber,
        editLot,
        editMemo,
        editDate,
        editAddress,
        editPayTo,
        editInvoiceNumber,
        editIsCredit,
        allowSaveAction,
        allowClearAction,
        glOptions,
    };
};

export default useValidateInvoiceActions;

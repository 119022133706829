import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FormLabel, Grid } from '@material-ui/core';
import InputNumber from 'components/widgets/InputNumber';

const DisplayInputNumber = ({
    label,
    value,
    className,
    disabled,
    display,
    bold,
    alignRight,
    onChange,
    isReconCost,
}) => {
    if (!display) {
        return null;
    }

    let node = (
        <FormLabel
            className={clsx({ 'text-bold': bold })}
        >
            { `${label}:` }
        </FormLabel>
    );

    if (React.isValidElement(label)) {
        node = label;
    }

    return (
        <Grid container item xs={12} sm={6}>
            <Grid
                container
                item
                wrap="nowrap"
                className={clsx({ 'justify-end': alignRight })}
                xs={12}
                sm={5}
            >
                { node }
            </Grid>
            <Grid item xs={12} sm={7}>
                <InputNumber
                    value={value}
                    onChange={(newValue) => onChange(newValue)}
                    placeholder={isReconCost ? '$0' : '0.00'}
                    showCurrency
                    {...(isReconCost ? { min: 0 } : {})}
                    {...(isReconCost ? { decimalScale: 0 } : {})}
                    thousandSeparator
                    size="sm"
                    className={className}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

DisplayInputNumber.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    display: PropTypes.bool,
    bold: PropTypes.bool,
    alignRight: PropTypes.bool,
    isReconCost: PropTypes.bool,
};

DisplayInputNumber.defaultProps = {
    disabled: false,
    display: true,
    bold: false,
    alignRight: false,
    className: '',
    value: 0,
    onChange: () => {},
    isReconCost: false,
};

export default DisplayInputNumber;

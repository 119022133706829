import React, { Component } from 'react';

// Components ant Others
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { FormsActions } from 'utils/enum/DealEnum';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import FormService from 'services/modules/FormService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import FormSubscription from 'services/graphQL/subscription/FormSubscription';

const FormPacksContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        lotName: PropTypes.string,
        packSelected: PropTypes.object,
        onSelectFormPacks: PropTypes.func.isRequired,
    }

    static defaultProps = {
        lotName: null,
        packSelected: null,
    }

    constructor(props) {
        super(props);
        this.keyStore = new KeyStore();
        this.formService = new FormService();
        this.graphqlClient = new GraphQLClient();
        this.formPackListSubscription = null;

        this.state = {
            records: [],
            action: '',
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
        this.subscribeToFormPackList();
    }

    componentWillUnmount() {
        this.unsubscribeFormPackList();
    }

    onSelectAction(action, record) {
        const currentRecord = FormsActions.DUPLICATE.toUpperCase() === action ? this.addCopyToNamProperty(record) : record;

        this.setState({
            action,
            record: currentRecord,
        });
    }

    onDeleteFormPack() {
        const { packSelected, onSelectFormPacks } = this.props;
        const { record } = this.state;

        const input = {
            lotId: record?.lotId,
            packId: record?.packId,
        };

        this.formService.deleteFormPack(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.deleteFormPack) {
                    this.hiddenFormPack();

                    if (record?.packId === packSelected?.packId) onSelectFormPacks();
                }
            });
    }

    getActions() {
        return [
            {
                label: FormsActions.RENAME,
                value: FormsActions.RENAME.toUpperCase(),
            },
            {
                label: FormsActions.DUPLICATE,
                value: FormsActions.DUPLICATE.toUpperCase(),
            },
            {
                label: FormsActions.DELETE,
                value: FormsActions.DELETE.toUpperCase(),
            },
        ];
    }

    getServicesData() {
        const { props: { lotName } } = this;
        const selectedLot = this.keyStore.getSelectedLot();
        const userLots = this.keyStore.getUserLots();
        const dealLot = userLots.find((x) => x.lotName === lotName);
        const input = {
            lotId: dealLot?.lotId || selectedLot.lotId,
        };

        this.formService.getFormPacks(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getFormPacks) {
                    const { getFormPacks } = data;

                    this.setState({
                        records: getFormPacks,
                    });
                }
            });
    }

    addCopyToNamProperty(record) {
        return { ...record, packName: `${record?.packName} copy` };
    }

    subscribeToFormPackList() {
        const { props: { lotName } } = this;
        const selectedLot = this.keyStore.getSelectedLot();
        const userLots = this.keyStore.getUserLots();
        const dealLot = userLots.find((x) => x.lotName === lotName);
        const input = {
            lotId: dealLot?.lotId || selectedLot.lotId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, FormSubscription.FORM_PACKS_CHANGED, input)
            .then((response) => {
                this.formPackListSubscription = response;
            });
    }

    unsubscribeFormPackList() {
        if (this.formPackListSubscription) {
            this.formPackListSubscription.unsubscribe();
        }
    }

    responseSubscription(response) {
        const { data } = response;
        const { records } = this.state;
        const { onSelectFormPacks } = this.props;

        if (data?.formPacksChanged?.type === SubscriptionActionType.UPDATED) {
            const { formPacksChanged } = data;
            const index = records.findIndex((item) => item.packId === formPacksChanged.data?.packId);

            if (index >= 0) {
                this.setState((prevState) => ({
                    records: update(prevState.records, { [index]: { $set: formPacksChanged.data } }),
                }));
            }
            return;
        }

        if (data?.formPacksChanged?.type === SubscriptionActionType.CREATED) {
            const { formPacksChanged } = data;

            this.setState((prevState) => ({
                records: update(prevState.records, { $push: [formPacksChanged.data] }),
            }));

            onSelectFormPacks(formPacksChanged.data, true);
            return;
        }

        if (data?.formPacksChanged?.type === SubscriptionActionType.DELETED) {
            const { formPacksChanged } = data;
            const index = records.findIndex((item) => item.packId === formPacksChanged.data?.packId);

            if (index > -1) {
                this.setState((prevState) => ({
                    records: update(prevState.records, { $splice: [[index, 1]] }),
                }));
            }
        }
    }

    addFormPack() {
        this.setState({
            action: FormsActions.Add.toUpperCase(),
        });
    }

    hiddenFormPack() {
        this.setState({
            action: '',
            record: null,
        });
    }

    initBind() {
        this.getActions = this.getActions.bind(this);
        this.addFormPack = this.addFormPack.bind(this);
        this.hiddenFormPack = this.hiddenFormPack.bind(this);
        this.onSelectAction = this.onSelectAction.bind(this);
        this.onDeleteFormPack = this.onDeleteFormPack.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeToFormPackList = this.subscribeToFormPackList.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                getActions={this.getActions}
                addFormPack={this.addFormPack}
                onSelectAction={this.onSelectAction}
                hiddenFormPack={this.hiddenFormPack}
                onDeleteFormPack={this.onDeleteFormPack}
            />
        );
    }
};

export default FormPacksContainer;

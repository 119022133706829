import StringUtils from 'lib/StringUtils';
import {
    map, isFinite, compact,
} from 'lodash';
import { MetaData } from 'utils/enum/CustomField';

export default class FormMap {
    static mapCustomField(records) {
        if (records.length > 0) {
            const result = records.map((item) => this.mapField(item));
            // Remove form OdometerStatement when none was selected
            return compact(map(result, (item) => {
                if (item.formName.includes('OdometerStatement') || item.formName.includes('CreditScoreDisclosure')) {
                    if (item?.fields.length > 0) {
                        return item;
                    }
                    return null;
                }

                return item;
            }));
        }

        return [];
    }

    // TODO: Pending to add the new data types to map
    static mapField(record) {
        const result = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const item of record.data) {
            const type = item?.type.toUpperCase();

            if (MetaData.TEXT === type) {
                if (item.split && isFinite(item.splitValue)) {
                    const currentValue = StringUtils.isEmpty(item.value) ? '' : item.value;
                    const resultValue1 = currentValue.substring(0, item.splitValue);
                    const resultValue2 = currentValue.substring(item.splitValue, currentValue.length);

                    result.push({
                        value: resultValue1,
                        field: item.customField2,
                    });

                    result.push({
                        value: resultValue2,
                        field: item.customField1,
                    });
                } else {
                    result.push({
                        value: item.value,
                        field: item.customField,
                    });
                }
            }

            if ([MetaData.DATE, MetaData.INTEGER].includes(type)) {
                result.push({
                    value: item.value,
                    field: item.customField,
                });
            }

            if (MetaData.SELECT === type) {
                result.push({
                    value: item.value,
                    field: item.customField,
                    label: item.record?.label || '',
                });
            }

            if (MetaData.FLOAT === type) {
                const resultValue = isFinite(item.value) ? item.value : item.defaultValue;

                result.push({
                    ...item,
                    value: resultValue,
                    field: item.customField,
                });
            }

            if (MetaData.CHECKBOX_TEXT === type) {
                result.push({
                    value: item.valueCheck,
                    field: item.customFieldCheck,
                });

                result.push({
                    value: item.valueText,
                    field: item.customFieldText,
                });
            }

            if (MetaData.CHECKBOX === type) {
                // eslint-disable-next-line no-restricted-syntax
                for (const currentItem of item.values) {
                    if (currentItem.replaceValue) {
                        if (currentItem.value) {
                            result.push({
                                value: currentItem.value ? currentItem.replaceValueWhenChecked : currentItem.replaceValueWhenNotChecked,
                                field: currentItem.customField,
                            });
                        }
                    } else {
                        result.push({
                            value: currentItem.value,
                            field: currentItem.customField,
                        });
                    }
                }
            }

            if (MetaData.RADIO === type) {
                // eslint-disable-next-line no-restricted-syntax
                for (const currentItem of item.values) {
                    const isChecked = item.value === currentItem.value;

                    if (item.replaceValue) {
                        result.push({
                            value: isChecked ? item.replaceValueWhenChecked : item.replaceValueWhenNotChecked,
                            field: currentItem.customField,
                        });
                    } else {
                        result.push({
                            value: isChecked ? item.value : '',
                            field: currentItem.customField,
                        });
                    }
                }
            }
        }

        return {
            formId: record.formId,
            fields: result,
            formName: record.originalName,
            secureCloseMapID: record.secureCloseMapID,
            secureCloseDocumentName: record.secureCloseDocumentName,
        };
    }

    static mapFormWithoutCustomField(records) {
        const result = [];
        if (records.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of records) {
                result.push({
                    formId: item.id,
                    formName: item.originalName,
                    secureCloseMapID: item.secureCloseMapID,
                    secureCloseDocumentName: item.secureCloseDocumentName,
                    fields: [],
                });
            }

            return result;
        }

        return result;
    }

    static mapData(records) {
        return records.map((item) => ({
            data: item.customField?.data?.customField || [],
            formId: item.id,
            name: item.name,
            commonName: item.commonName,
            originalName: item.originalName,
            secureCloseMapID: item.secureCloseMapID,
            secureCloseDocumentName: item.secureCloseDocumentName,
        }));
    }
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import DatePicker from 'react-datepicker';
import DateUtils from 'lib/DateUtils';
import { Col, Form } from 'react-bootstrap';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        background: theme.palette.background.white,
        padding: 10,
        display: 'flex',
        alignItems: 'end',
        '& > button': {
            marginRight: 10,
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            fontSize: '13px',
            flexGrow: 'initial',
            padding: 0,
            marginRight: '10px',
            marginBottom: 0,
            '& > label': {
                marginBottom: '2px',
            },
            '& > div': {
                width: '150px',
            },
        },
    },
}));

const Toolbar = ({
    onSave, writePermission, requiredFieldsCompleted, loading,
    postVehicleAccounting, lockedDate, postToDate, onChange,
    vinWasDecoded, enterManually,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const onCancel = () => {
        history.push(`/${modules.INVENTORY}`);
    };

    return (
        <div className={classes.toolbar}>
            {postVehicleAccounting && lockedDate && (vinWasDecoded || enterManually) && (
                <Form.Group as={Col}>
                    <Form.Label className={classes.subLabel}>Post Date</Form.Label>
                    <DatePicker
                        popperContainer={CalendarContainer}
                        className={clsx('form-control form-control-sm')}
                        selected={postToDate}
                        minDate={new Date(DateUtils.add(lockedDate, 1))}
                        disabled={loading}
                        onChange={(value) => onChange('postToDate', value)}
                        placeholderText="mm/dd/yyyy"
                    />
                </Form.Group>
            )}
            {writePermission && (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!requiredFieldsCompleted || loading}
                    onClick={onSave}
                >
                    Save
                </Button>
            )}
            <Button variant="contained" onClick={onCancel} disabled={loading}>
                Cancel
            </Button>
        </div>
    );
};

Toolbar.propTypes = {
    onSave: PropTypes.func.isRequired,
    writePermission: PropTypes.bool,
    requiredFieldsCompleted: PropTypes.bool,
    loading: PropTypes.bool,
    postVehicleAccounting: PropTypes.bool.isRequired,
    lockedDate: PropTypes.any,
    postToDate: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    vinWasDecoded: PropTypes.bool,
    enterManually: PropTypes.bool,
};

Toolbar.defaultProps = {
    writePermission: false,
    requiredFieldsCompleted: false,
    loading: false,
    lockedDate: null,
    postToDate: null,
    vinWasDecoded: false,
    enterManually: false,
};

export default Toolbar;

/* eslint-disable max-len */
import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, useTheme,
    useMediaQuery, Tooltip, RadioGroup,
    FormControlLabel, Radio, Chip,
} from '@material-ui/core';
import Split from 'react-split';
import Table from 'components/widgets/Table';
import ModalUtils from 'utils/ModalUtils';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    useQuery, useLazyQuery, useSubscription, useMutation,
} from '@apollo/client';
import NumberUtils from 'lib/NumberUtils';
import {
    EditOutlinedIcon, BlockOutlinedIcon,
    PrintOutlinedIcon,
    ThumbUpAltOutlinedIcon,
    AddIcon,
    OpenInBrowserOutlinedIcon,
    SwapHorizOutlinedIcon,
} from 'components/icons';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import InputSearch from 'components/widgets/InputSearch';
import DateUtils from 'lib/DateUtils';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { PartsPurchaseSubModules, PurchaseOrderStatus, PurchaseActions } from 'utils/enum/PartsPurchaseEnum';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import printJS from 'print-js';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import If from 'components/widgets/conditional/If';
import PurchaseOrderDetailList from 'components/modules/purchaseParts/purchaseOrder/list/PurchaseOrderDetailList';
import PartsSubscription from 'services/graphQL/subscription/parts/PartsSubscription';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import PurchaseReceiveDialog from 'components/modules/purchaseParts/purchaseOrder/list/PurchaseReceiveDialog';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    highlightText: {
        color: theme.palette.text.red,
    },
}));

const ACTION_TYPES = {
    SET_EDITING: 'setEditing',
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
    SET_PURCHASE_ACTION: 'setCBAction',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_EDITING: {
        return {
            ...state,
            isEditing: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_PURCHASE_ACTION: {
        return {
            ...state,
            ...action.value,
        };
    }
    default: return action.value;
    }
};

const PurchaseList = () => {
    const keyStore = new KeyStore();
    const PURCHASE_WRITE = keyStore.hasPermission(Permission.PURCHASE_WRITE);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
            },
            status: PurchaseOrderStatus.DRAFT,
            search: '',
            lotName: '',
        },
        table: {
            records: [],
            totalCount: 0,
        },
        mappingParts: [],
        actionRecord: null,
        actionType: '',
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow, mappingParts,
        actionRecord, actionType,
    } = state;

    const {
        data, loading, error,
    } = useQuery(PartsQuery.GET_PURCHASE_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                status: params.status,
                lotName: params.lotName,
            },
            sort: [
                { fieldName: 'purchaseOrderId', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionRecord: null,
                actionType: '',
            },
        });
    };

    const [getPartsInventoryMapping] = useLazyQuery(AccountingGLQuery.GET_MAPPING_BY_TYPE, {
        variables: {
            mapType: 'Parts Inventory',
        },
        onCompleted: (res) => {
            if (res.getAccountingMappingByMapType) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        mappingParts: res.getAccountingMappingByMapType,
                    },
                });
            }
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [changeStatus, { loading: changingStatus }] = useMutation(PartsMutation.CHANGE_STATUS_PURCHASE_ORDERS, {
        onCompleted: (mutationData) => {
            if (mutationData?.changePurchaseStatus) {
                ModalUtils.successMessage(null, 'Change Status sucessfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [printPurchase, { loading: printing }] = useMutation(PartsMutation.PRINT_PURCHASE_ORDERS, {
        onCompleted: (mutationData) => {
            if (mutationData?.printPurchaseOrders) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printPurchaseOrders,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const onActionConfirm = async () => {
        onCloseActionDialog();

        if (actionType === PurchaseActions.VOID) {
            await changeStatus({
                variables: {
                    record: {
                        ids: [actionRecord?.purchaseOrderId],
                        status: PurchaseOrderStatus.VOID,
                    },
                },
            });
        } else if (actionType === PurchaseActions.PRINT) {
            await printPurchase({
                variables: {
                    ids: [actionRecord?.purchaseOrderId],
                },
            });
        } else if (actionType === PurchaseActions.APPROVE) {
            await changeStatus({
                variables: {
                    record: {
                        ids: [actionRecord?.purchaseOrderId],
                        status: PurchaseOrderStatus.APPROVED,
                    },
                },
            });
        }
    };

    const onActionClick = (val, action) => {
        dispatch({
            type: ACTION_TYPES.SET_PURCHASE_ACTION,
            value: {
                actionRecord: val,
                actionType: action,
            },
        });
    };

    const getFormattedText = (status, value) => {
        const textColorCls = status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase() ? classes.voidCls : '';
        return <span className={textColorCls}>{value}</span>;
    };

    useSubscription(PartsSubscription.PURCHASE_ORDER_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { action, data: subsData, id } = subscriptionData?.data?.purchaseOrderChanged;
            if (action === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                records.unshift({ ...currentData });

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount: totalCount + 1,
                    },
                });
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.purchaseOrderId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, { ...currentData });

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                            totalCount: table.totalCount,
                        },
                    });
                }
            }
        },
    });

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'PO #',
            minWidth: 80,
            width: 80,
            accessor: 'purchaseOrderId',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Vendor',
            minWidth: 200,
            width: 200,
            accessor: 'vendor',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Shipping Method',
            minWidth: 150,
            width: 150,
            accessor: 'shippingMethod',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Parts Clerk',
            minWidth: 150,
            width: 150,
            accessor: 'partsClerk',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Order Date',
            minWidth: 100,
            width: 100,
            accessor: 'orderDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Delivery Date',
            minWidth: 100,
            width: 100,
            accessor: 'deliveryDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Ro/Ticket #',
            minWidth: 100,
            width: 100,
            accessor: 'roNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Sub Total',
            minWidth: 100,
            width: 100,
            accessor: 'subTotal',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Tax',
            minWidth: 80,
            width: 80,
            accessor: 'salesTax',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Total',
            minWidth: 80,
            width: 80,
            accessor: 'total',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Status',
            minWidth: 120,
            width: 120,
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status.toLowerCase() === PurchaseOrderStatus.DRAFT.toLowerCase()) badgeStatus = classes.badgeCleared;
                else if (status.toLowerCase() === PurchaseOrderStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
                else if (status.toLowerCase() === PurchaseOrderStatus.RECEIVED.toLowerCase()) badgeStatus = classes.badgePrinted;
                else if (status.toLowerCase() === PurchaseOrderStatus.RETURNED.toLowerCase()) badgeStatus = classes.primary;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Notes',
            minWidth: 90,
            accessor: 'notes',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, isMobile ? '' : classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, isMobile ? '' : classes.hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        purchaseOrderId, status,
                    },
                    original,
                } = cellData;

                const currentForm = (status === PurchaseOrderStatus.IN_RETURNED_PROCESS || status === PurchaseOrderStatus.RETURNED) ? 'return' : 'edit';

                return (
                    <div className={classes.buttonSpacing}>
                        {PURCHASE_WRITE
                            && (
                                <Button
                                    onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/${currentForm}/${purchaseOrderId}`)}
                                    variant="outlined"
                                    startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                    size="small"
                                >
                                    { isMobile ? '' : 'Edit' }
                                </Button>
                            )}
                        {PURCHASE_WRITE && (status === PurchaseOrderStatus.DRAFT || status === PurchaseOrderStatus.APPROVED) && (
                            <Button
                                onClick={() => onActionClick(original, PurchaseActions.VOID)}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Void' }
                            </Button>
                        )}
                        {PURCHASE_WRITE && status === PurchaseOrderStatus.DRAFT && (
                            <Button
                                onClick={() => onActionClick(original, PurchaseActions.APPROVE)}
                                variant="outlined"
                                startIcon={<ThumbUpAltOutlinedIcon className={classes.actionButtonApprove} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Approve' }
                            </Button>
                        )}
                        {PURCHASE_WRITE && status === PurchaseOrderStatus.APPROVED && (
                            <Button
                                onClick={() => onActionClick(original, PurchaseActions.RECEIVE)}
                                variant="outlined"
                                startIcon={<OpenInBrowserOutlinedIcon className={classes.actionButtonWarning} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Receive' }
                            </Button>
                        )}
                        <Button
                            onClick={() => onActionClick(original, PurchaseActions.PRINT)}
                            variant="outlined"
                            startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                            size="small"
                        >
                            { isMobile ? '' : 'Print' }
                        </Button>
                        {PURCHASE_WRITE && status === PurchaseOrderStatus.RECEIVED && (
                            <Button
                                onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/return-from-purchase/${purchaseOrderId}`)}
                                variant="outlined"
                                startIcon={<SwapHorizOutlinedIcon className={classes.primary} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Return PO' }
                            </Button>
                        )}
                        {PURCHASE_WRITE && status === PurchaseOrderStatus.IN_RETURNED_PROCESS && (
                            <Button
                                onClick={() => onActionClick(original, PurchaseActions.RETURN)}
                                variant="outlined"
                                startIcon={<SwapHorizOutlinedIcon className={classes.actionButtonApprove} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Return' }
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getPurchaseOrderList } = data;

            records.push(...getPurchaseOrderList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getPurchaseOrderList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        getPartsInventoryMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const haveMappingInParts = mappingParts?.[0]?.glAccount ?? 0;

    let dialogMessage = '';
    let dialogTitle = '';
    let dialogDescription = '';

    if (printing)dialogMessage = 'Printing...';
    if (changingStatus)dialogMessage = 'Changing Status...';

    switch (actionType) {
    case PurchaseActions.VOID:
        dialogTitle = 'Void Purchase';
        dialogDescription = 'Are you sure you want to void this purchase?';
        break;
    case PurchaseActions.APPROVE:
        dialogTitle = 'Approve Purchase';
        dialogDescription = 'Are you sure you want to approve this purchase?';
        break;
    case PurchaseActions.PRINT:
        dialogTitle = 'Print Purchase';
        dialogDescription = 'Are you sure you want to print this purchase?';
        break;
    default:
        break;
    }

    return (
        <>
            <div className={classes.main}>
                <Header>
                    <div className={classes.containerFilterSearch}>
                        <InputSearch
                            customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                            initialSearch={params.search || ''}
                            onSearch={onSearch}
                        />
                        <RadioGroup row value={params.status} onChange={(e, val) => onFilterChange('status', val)}>
                            <FormControlLabel
                                value="All"
                                control={<Radio color="primary" />}
                                label="All"
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.DRAFT}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.DRAFT}
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.APPROVED}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.APPROVED}
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.RECEIVED}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.RECEIVED}
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.VOID}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.VOID}
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.IN_RETURNED_PROCESS}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.IN_RETURNED_PROCESS}
                            />
                            <FormControlLabel
                                value={PurchaseOrderStatus.RETURNED}
                                control={<Radio color="primary" />}
                                label={PurchaseOrderStatus.RETURNED}
                            />
                        </RadioGroup>
                    </div>
                    <div className={clsx(classes.buttonSpacing, classes.justifyContentCenter)}>
                        {PURCHASE_WRITE
                && (
                    <Tooltip title="New Purchase Order" placement="top-start">
                        <span>
                            <Button
                                onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/create`)}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                disabled={loading}
                                size="small"
                            >
                                New
                            </Button>
                        </span>
                    </Tooltip>
                )}
                        {PURCHASE_WRITE
                    && (
                        <Tooltip title="Approve Order" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/approve-purchases`)}
                                    variant="outlined"
                                    startIcon={<ThumbUpAltOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Approve
                                </Button>
                            </span>
                        </Tooltip>
                    ) }
                        {PURCHASE_WRITE
                && (
                    <Tooltip title="Return Purchase Order" placement="top-start">
                        <span>
                            <Button
                                onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/return`)}
                                variant="outlined"
                                startIcon={<SwapHorizOutlinedIcon />}
                                disabled={loading}
                                size="small"
                            >
                                Return PO
                            </Button>
                        </span>
                    </Tooltip>
                )}
                    </div>
                </Header>
                <Container className={classes.containerSplit}>
                    <Split
                        sizes={[55, 45]}
                        className={classes.split}
                        direction="vertical"
                        minSize={0}
                    >
                        <Table
                            rowSelected
                            cursor="default"
                            load={loading}
                            loadMore={loadMore}
                            totalRecords={table?.totalCount}
                            data={table?.records}
                            columns={columns}
                            className="-highlight actionsInLine"
                            getTrProps={(_, rowInfo) => ({
                                onClick: () => {
                                    if (selectedRow?.purchaseOrderId === rowInfo.original.purchaseOrderId) return;
                                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: rowInfo.original });
                                },
                            })}
                        />
                        <PurchaseOrderDetailList
                            recordId={selectedRow?.purchaseOrderId}
                        />
                    </Split>
                </Container>
            </div>
            {(actionType === PurchaseActions.RECEIVE || actionType === PurchaseActions.RETURN) && actionRecord.purchaseOrderId > 0
                && (
                    <PurchaseReceiveDialog
                        haveMappingInParts={haveMappingInParts > 0}
                        onCloseActionDialog={onCloseActionDialog}
                        purchaseOrderId={actionRecord.purchaseOrderId}
                        isReturn={actionRecord.status === PurchaseOrderStatus.IN_RETURNED_PROCESS}
                    />
                )}
            {actionType && actionType !== PurchaseActions.RECEIVE && actionType !== PurchaseActions.RETURN
            && actionRecord.purchaseOrderId > 0 && (
                <ConfirmDialog
                    title={dialogTitle}
                    description={dialogDescription}
                    open={actionType && actionRecord.purchaseOrderId > 0}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirm}
                />
            ) }
            <If condition={printing || changingStatus}>
                <DialogActionMessage message={dialogMessage} />
            </If>
        </>
    );
};

export default PurchaseList;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';

const useStyles = makeStyles({
    root: {
        padding: 10,
        background: '#FBFBFB',
        marginBottom: 20,
        filter: 'brightness(98%)',
    },
    gridContainer: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
});

const PricingForm = ({
    data,
    onChange,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const onChangeForm = (fieldName, value) => {
        onChange(fieldName, value, 'pricingInfo');
    };

    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <Typography variant="h5">Pricing Information</Typography>
            <Grid
                container
                orientation="column"
                className={classes.gridContainer}
            >
                <DisplayInput
                    label={(
                        <>
                            <FormLabel className="text-bold">Slashed Price:</FormLabel>
                            <Checkbox
                                checked={data.isSlashedPrice}
                                color="primary"
                                size="small"
                                onChange={(_, value) => onChangeForm('isSlashedPrice', value)}
                            />
                        </>
                    )}
                    value={data.isSlashedPrice ? data.slashedPrice : 0}
                    onChange={(value) => onChangeForm('slashedPrice', value)}
                    disabled={!data.isSlashedPrice}
                    bold
                    alignRight={!isMobile}
                />
                <DisplayInput
                    label="Internet Price"
                    value={data.internetPrice}
                    onChange={(value) => onChangeForm('internetPrice', value)}
                    bold
                    alignRight={!isMobile}
                />
                <DisplayInput
                    label="Sticker Price"
                    value={data.stickerPrice}
                    onChange={(value) => onChangeForm('stickerPrice', value)}
                    bold
                    alignRight={!isMobile}
                />
                <DisplayInput
                    label="Minimum Price"
                    value={data.minimumPrice}
                    onChange={(value) => onChangeForm('minimumPrice', value)}
                    bold
                    alignRight={!isMobile}
                />
            </Grid>
        </div>
    );
};

PricingForm.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

PricingForm.defaultProps = {
    data: {},
};

export default PricingForm;

import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Grid,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { useQuery, useApolloClient } from '@apollo/client';
import { ENTITY_TYPE, COMMON_CONSTANT } from 'utils/enum/BusinessIntelligenceEnum';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import {
    MinusSquare,
    BlockOutlinedIcon,
    PlusSquare,
    AssignmentOutlinedIcon,
    HighlightOffOutlinedIcon,
} from 'components/icons/index';
import Split from 'react-split';
import { Tabs, Tab } from 'react-bootstrap';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import Chart from 'components/widgets/businessIntelligence/Chart';

const useStyles = makeStyles((theme) => ({
    box: {
        height: '100%',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            overflow: 'hidden',
        },
    },
    splitter: {
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
    categories: {
        padding: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& > div:nth-child(1)': {
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            color: theme.palette.text.white,
            backgroundColor: theme.palette.background.bigStone,
        },
        '& > ul': {
            height: '100%',
            width: '100%',
            marginTop: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '& .MuiTreeItem-label': {
                fontSize: '13px',
            },
        },
    },
    category: {
        fontWeight: 500,
    },
    report: {
        marginTop: '5px',
        '& svg': {
            fill: theme.palette.secondary.main,
        },
        '& .MuiTreeItem-label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        },
    },
    expand: {
        fill: theme.palette.secondary.main,
    },
    minus: {
        fill: theme.palette.background.red,
    },
    none: {
        fill: theme.palette.background.bigStone,
    },
    content: {
        padding: '5px',
        width: '100%',
        '& > nav > a': {
            fontSize: '13px',
            textDecoration: 'none',
            color: 'initial',
            maxWidth: '250px',
            position: 'relative',
            '& > div': {
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& > div:nth-child(1)': {
                width: '95%',
            },
        },
        '& > nav > a.active': {
            color: `${theme.palette.background.bigStone} !important`,
            fontWeight: 500,
        },
        '& > div.tab-content': {
            padding: '2px',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: '5px',
            },
            '& > div': {
                height: '100%',
            },
        },
    },
    componentParent: {
        width: '100%',
        height: '100%',
        padding: '5px',
        overflow: 'auto',
        position: 'relative',
    },
    loadingChart: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
    },
    remover: {
        position: 'absolute',
        top: '6px',
        right: '2px',
        '& > svg': {
            width: '20px',
            height: '20px',
            fill: theme.palette.background.red,
        },
    },
}));

const CustomReports = () => {
    const classes = useStyles();
    const client = useApolloClient();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        categories: [],
        charts: [],
        expandedCategories: [],
        selectedTab: null,
        tabs: [],
    });

    const {
        data: categoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
    } = useQuery(BIQuery.PULL_AVAILABLE_CATEGORIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: chartsData,
        loading: loadingCharts,
        error: errorCharts,
    } = useQuery(BIQuery.PULL_AVAILABLE_CHARTS_PER_USER, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorCharts) {
            ModalUtils.errorMessage(errorCharts?.graphQLErrors);
        }

        if (!loadingCharts) {
            const charts = chartsData?.pullAvailableChartsPerUser;
            if (!charts) return;

            setState((prevState) => ({
                ...prevState,
                charts: charts.filter((c) => c.customReport),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCharts, errorCharts]);

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
            return;
        }

        if (!loadingCategories) {
            const categories = categoriesData?.pullAvailableCategories;
            if (!categories) return;

            setState((prevState) => ({
                ...prevState,
                categories,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    const onCategoryToggle = (_, nodeIds) => {
        const current = ArrayUtils.isNotEmpty(nodeIds) ? [nodeIds[0]] : [];

        setState((prevState) => ({
            ...prevState,
            expandedCategories: current,
        }));
    };

    const pullQueryRecords = async (chart, removeOldRecords = false) => {
        try {
            const { id, paginate, filters } = chart;

            const { data } = await client.query({
                query: BIQuery.PULL_QUERY_RESULT_ENTITY,
                variables: {
                    id,
                    type: ENTITY_TYPE.CHART,
                    ...(paginate ? { paginate } : {}),
                    ...(filters ? { filters } : {}),
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                const { output: { records, total }, defaultDateValues } = data.pullQueryResultOfEntity;

                setState((prevState) => {
                    const clone = cloneDeep(prevState.tabs);
                    const tabIndex = clone.findIndex((t) => t.id === chart.id);

                    if (tabIndex >= 0) {
                        clone[tabIndex].componentLoaded = true;
                        clone[tabIndex].error = false;
                        clone[tabIndex].totalRecords = total;
                        clone[tabIndex].records = removeOldRecords ? records : [...(clone[tabIndex].records ?? []), ...records];
                        clone[tabIndex].paginationComplete = records.length === 0;
                        clone[tabIndex].loadingMoreData = false;
                        clone[tabIndex].defaultDateValues = defaultDateValues;
                    }

                    return {
                        ...prevState,
                        tabs: clone,
                    };
                });
            }
        } catch (_) {
            setState((prevState) => {
                const clone = cloneDeep(prevState.tabs);
                const tabIndex = clone.findIndex((t) => t.id === chart.id);

                if (tabIndex >= 0) {
                    clone[tabIndex].componentLoaded = true;
                    clone[tabIndex].error = true;
                    clone[tabIndex].totalRecords = null;
                    clone[tabIndex].records = [];
                    clone[tabIndex].paginationComplete = true;
                    clone[tabIndex].loadingMoreData = false;
                    clone[tabIndex].defaultDateValues = [];
                }

                return {
                    ...prevState,
                    tabs: clone,
                };
            });
        }
    };

    const openReport = (chart) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.tabs);
            const tabIndex = clone.findIndex((t) => t.id === chart.id);
            if (tabIndex < 0) {
                const { query = '', type } = chart;
                const paginationEnabled = ['@Offset', '@PageSize']
                    .every((variable) => query.toLowerCase().includes(variable.toLowerCase()))
                    && type === 'Table';

                const addedTab = {
                    ...chart,
                    ...(paginationEnabled ? { paginate: { start: 0, limit: COMMON_CONSTANT.PAGINATION_PAGE_SIZE } } : {}),
                    componentLoaded: false,
                };
                clone.push(addedTab);
                pullQueryRecords(addedTab);
            }

            return {
                ...prevState,
                tabs: clone,
                selectedTab: tabIndex >= 0 ? clone[tabIndex].id : chart.id,
            };
        });
    };

    const onChangeTab = (eventKey) => {
        setState((prevState) => ({
            ...prevState,
            selectedTab: prevState.tabs.findIndex((t) => t.id === eventKey) >= 0 ? eventKey : prevState.selectedTab,
        }));
    };

    const removeTab = (id) => {
        setState((prevState) => {
            const { tabs } = prevState;
            const result = tabs.filter((t) => t.id !== id);

            return {
                ...prevState,
                tabs: result,
                selectedTab: result.length > 0 ? result[0].id : null,
            };
        });
    };

    const loadMoreData = (id) => {
        const { tabs } = state;
        const current = tabs.find((t) => t.id === id);
        const { paginationComplete, paginate, records } = current;

        if (!paginationComplete) {
            current.loadingMoreData = true;
            setState((prevState) => ({ ...prevState, tabs }));

            paginate.start = records.length;
            pullQueryRecords(current);
        }
    };

    const pullQueryResultsWithFilters = (id, filter) => {
        const { tabs } = state;
        const current = tabs.find((t) => t.id === id);

        if (current.paginate) current.paginate.start = 0;
        current.loadingMoreData = true;
        setState((prevState) => ({ ...prevState, tabs }));

        if (!current.filters) current.filters = [filter];
        if (current.filters) {
            const currentIndex = current.filters.findIndex((f) => f.column === filter.column);
            if (currentIndex >= 0) current.filters[currentIndex] = filter;
            if (currentIndex < 0) current.filters.push(filter);
        }

        pullQueryRecords(current, true);
    };

    const getContentForCategories = () => (
        <div className={classes.categories}>
            <div>Categories</div>
            <TreeView
                expanded={state.expandedCategories}
                onNodeToggle={onCategoryToggle}
                defaultCollapseIcon={<MinusSquare className={classes.minus} />}
                defaultExpandIcon={<PlusSquare className={classes.expand} />}
                defaultEndIcon={<BlockOutlinedIcon className={classes.none} />}
            >
                {state.categories.map((c) => (
                    <TreeItem
                        key={c.id}
                        label={<span className={classes.category}>{c.name}</span>}
                        nodeId={c.id}
                    >
                        {
                            state.charts
                                .filter((chart) => chart.category.id === c.id)
                                .map((chart) => (
                                    <Tooltip key={chart.id} title={chart.label}>
                                        <TreeItem
                                            className={classes.report}
                                            label={chart.label}
                                            nodeId={chart.id}
                                            icon={<AssignmentOutlinedIcon />}
                                            onLabelClick={(event) => {
                                                event.preventDefault();
                                                openReport(chart);
                                            }}
                                        />
                                    </Tooltip>
                                ))
                        }
                    </TreeItem>
                ))}
            </TreeView>
        </div>
    );

    const getContentForChart = () => (
        <div id="component-parent" className={classes.content}>
            {state.tabs.length > 0 && (
                <Tabs
                    activeKey={state.selectedTab}
                    onSelect={(k) => onChangeTab(k)}
                >
                    {state.tabs.map(({
                        id,
                        label,
                        type,
                        query,
                        options,
                        componentLoaded,
                        paginate,
                        error,
                        totalRecords,
                        records,
                        loadingMoreData,
                        defaultDateValues,
                    }, index) => {
                        let opt = null;
                        try { opt = JSON.parse(options); } catch (ex) { return null; }

                        return (
                            <Tab
                                key={index}
                                eventKey={id}
                                title={(
                                    <>
                                        <Tooltip title={label}>
                                            <div>
                                                {label}
                                            </div>
                                        </Tooltip>
                                        <div onClick={() => removeTab(id)} className={classes.remover}>
                                            <HighlightOffOutlinedIcon />
                                        </div>
                                    </>
                                )}
                            >
                                <div className={classes.componentParent}>
                                    {!componentLoaded && (
                                        <div className={classes.loadingChart}>Loading component, please wait...</div>
                                    )}
                                    {componentLoaded && error && (
                                        <div className={classes.loadingChart}>Error loading component, check settings...</div>
                                    )}
                                    {componentLoaded && !error && (
                                        <Chart
                                            cache={false}
                                            key={id}
                                            tableWidth={document.getElementById('component-parent')?.clientWidth - 35}
                                            height={isMobile ? 400 : '85%'}
                                            label={label}
                                            type={type}
                                            query={query}
                                            totalRecords={totalRecords}
                                            data={records}
                                            options={opt}
                                            loadMore={paginate ? () => loadMoreData(id) : null}
                                            loadingMoreData={loadingMoreData}
                                            pullQueryResultsWithFilters={(filter) => pullQueryResultsWithFilters(id, filter)}
                                            defaultDateValues={defaultDateValues}
                                        />
                                    )}
                                </div>
                            </Tab>
                        );
                    })}
                </Tabs>
            )}
        </div>
    );

    return (
        <>
            {isMobile && (
                <Grid container className={classes.box}>
                    {getContentForCategories()}
                    {getContentForChart()}
                </Grid>
            )}
            {!isMobile && (
                <Grid container className={classes.box}>
                    <Split
                        sizes={[15, 85]}
                        minSize={200}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        {getContentForCategories()}
                        {getContentForChart()}
                    </Split>
                </Grid>
            )}
        </>
    );
};

export default CustomReports;

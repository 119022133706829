import React, { useState } from 'react';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Container from 'components/widgets/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const TabSetting = ({ records, rootPath }) => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const subTabs = records.map((item) => item.url);

    const getSelectedTab = (submodule) => {
        const tabIndex = subTabs.indexOf(submodule);
        return (tabIndex !== -1) ? tabIndex : 0;
    };

    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${rootPath}/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {records.map((item, index) => (
                    <ListItem key={index} onClick={() => handleChange(index)} button selected={activeIndex === index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            {records.map((item, index) => (
                activeIndex === index && <div key={index} className={classes.content}>{item.component}</div>
            ))}
        </Container>
    );
};

TabSetting.propTypes = {
    rootPath: PropTypes.string,
    records: PropTypes.array.isRequired,
};

TabSetting.defaultProps = {
    rootPath: 'settings',
};

export default TabSetting;

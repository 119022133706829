import { gql } from '@apollo/client';

export default class FormsMapperMutation {
    static REMOVE_FORMS_MAPPED_FIELD = gql`
        mutation removeFormsMappedField($id: Int!) {
            removeFormsMappedField(id: $id)
        }
    `;

    static SAVE_FORMS_MAPPED_FIELD = gql`
        mutation upsertFormsMappedField($input: FieldsTableMappingInput!) {
            upsertFormsMappedField(input: $input)
        }
    `;

    static SAVE_FORM_MAPPING = gql`
        mutation upsertFormMapping($input: InputDealForm!) {
            upsertFormMapping(input: $input)
        }
    `;
}

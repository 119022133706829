/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Button, Grid,
    FormControl, FormControlLabel,
    Radio, RadioGroup, Checkbox,
    Dialog, DialogContent, DialogActions,
    TextField, Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { useQuery, useMutation } from '@apollo/client';
import { Language } from 'utils/enum/Customer';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import { Col, Row, Form } from 'react-bootstrap';
import {
    leadType as leadTypeOption,
    automatedResponseTrigger,
    automatedResponseDelayMeasure,
    automatedResponseKeywordsJoint,
} from 'utils/enum/OpportunityEnum';
import UserContext from 'components/context/UserContext';
import { modules } from 'utils/enum/modules';
import ButtonStyles from 'styles/theme/Button';
import AutomatedResponseStyle from 'styles/modules/crm/AutomatedResponseStyle';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import UserQuery from 'services/graphQL/query/UserQuery';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import EmailTemplateQuery from 'services/graphQL/query/setting/EmailTemplateQuery';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import InputNumber from 'components/widgets/InputNumber';
import Select from 'components/widgets/Select';
import InputSearch from 'components/widgets/InputSearch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VirtualTable from 'components/widgets/VirtualTable';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

// Icons
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => AutomatedResponseStyle.content(theme));

const RESPONSE_INIT_STATE = {
    automatedResponseId: null,
    name: '',
    leadType: '',
    trigger: '',
    keywords: [],
    keywordsJoint: 'OR',
    active: true,
    aiEnabled: false,
    aiAssistant: null,
    steps: [],
};

const FOLLOW_UP_INIT_STATE = {
    followUpMessageId: null,
    message: '',
    delayAmount: 0,
    delayMeasure: 'Minutes',
};

const minStartTime = new Date(1900, 0, null, 8);
const maxStartTime = new Date(1900, 0, null, 21);
const AutomatedResponse = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation } = useContext(UserContext);
    const [state, setState] = useState({
        assistants: [],
        responses: [],
        followUpMessages: [],
        smsTemplates: [],
        emailTemplates: [],
        currentFilter: 0,
        selectedResponse: cloneDeep(RESPONSE_INIT_STATE),
        selectedFollowUpMessage: cloneDeep(FOLLOW_UP_INIT_STATE),
        deletedSteps: [],
        isFormOpen: false,
        isFollowUpFormOpen: false,
        isExclusionsOpen: false,
        exclusionContent: '',
        exclusions: [],
        defaultRepresentative: -1,
        users: [],
        search: '',
        startTime: minStartTime,
        endTime: maxStartTime,
        availabilityEnabled: true,
        followUpavailabilityEnabled: true,
        followUpStartTime: minStartTime,
        followUpEndTime: maxStartTime,
        selectedLot: null,
        lots: [],
    });

    const {
        assistants,
        responses,
        followUpMessages,
        currentFilter,
        selectedResponse,
        selectedFollowUpMessage,
        smsTemplates,
        emailTemplates,
        deletedSteps,
        isFormOpen,
        isFollowUpFormOpen,
        isExclusionsOpen,
        exclusionContent,
        exclusions,
        defaultRepresentative,
        users,
        search,
        startTime,
        endTime,
        availabilityEnabled,
        followUpavailabilityEnabled,
        followUpStartTime,
        followUpEndTime,
        selectedLot,
        lots,
    } = state;

    const {
        data: assistantsData,
        loading: loadingSssistants,
        error: errorLoadingAssistants,
    } = useQuery(AIQuery.PULL_ASSISTANTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: responsesData,
        loading: loadingResponses,
        error: errorLoadingResponses,
        refetch,
    } = useQuery(OpportunityQuery.GET_AUTOMATED_RESPONSE_LIST, {
        variables: {
            target: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: followUpsData,
        loading: loadingfollowUps,
        error: errorLoadingfollowUps,
        refetch: refetchMessages,
    } = useQuery(OpportunityQuery.GET_AI_FOLLOW_UP_MESSAGES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: smsTemplateList,
        loading: loadingSmsTemplateList,
        error: errorLoadingSmsTemplateList,
    } = useQuery(EmailTemplateQuery.GET_SMS_TEMPLATE_LIST, {
        variables: {
            moduleTemplate: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: emailTemplateList,
        loading: loadingEmailTemplateList,
        error: errorLoadingEmailTemplateList,
    } = useQuery(EmailTemplateQuery.GET_EMAIL_TEMPLATE_LIST, {
        variables: {
            moduleTemplate: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: exclusionsData,
        loading: loadingExclusions,
        error: errorLoadingExclusions,
        refetch: refetchExclusions,
    } = useQuery(OpportunityQuery.GET_AUTOMATED_RESPONSE_NOTE_EXCLUSIONS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: usersData,
        loading: loadingUsers,
        error: errorLoadingUsers,
    } = useQuery(UserQuery.GET_RECORD_MANAGERS, {
        variables: {
            lotId: selectedLot,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: !selectedLot,
    });

    const {
        AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE,
        AUTOMATED_RESPONSE_START_TIME,
        AUTOMATED_RESPONSE_END_TIME,
        AUTOMATED_RESPONSE_AVAILABILITY_ENABLED,
        FOLLOW_UP_START_TIME,
        FOLLOW_UP_END_TIME,
        FOLLOW_UP_AVAILABILITY_ENABLED,
    } = LotDefaultskey;
    const {
        data: availabilitySettingsData,
        loading: availabilitySettingsLoading,
        error: availabilitySettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: ALL_LOTS,
            key: [
                AUTOMATED_RESPONSE_START_TIME,
                AUTOMATED_RESPONSE_END_TIME,
                AUTOMATED_RESPONSE_AVAILABILITY_ENABLED,
                FOLLOW_UP_START_TIME,
                FOLLOW_UP_END_TIME,
                FOLLOW_UP_AVAILABILITY_ENABLED,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: representativeSettingsData,
        loading: representativeSettingsLoading,
        error: representativeSettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.CRM,
            lotName: lots.find((l) => l.lotId === selectedLot)?.lotName,
            key: [AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: !selectedLot,
    });

    const [saveAutomatedResponse, { loading: savingResponse }] = useMutation(OpportunityMutation.SAVE_AUTOMATED_RESPONSE, {
        onCompleted: () => {
            // eslint-disable-next-line no-use-before-define
            toggleForm();
            refetch();
            ModalUtils.successMessage(null, 'Automated Response saved successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveFollowUpMessage, { loading: savingMessage }] = useMutation(OpportunityMutation.SAVE_AI_FOLLOW_UP_MESSAGE, {
        onCompleted: () => {
            // eslint-disable-next-line no-use-before-define
            if (isFollowUpFormOpen) toggleFollowUpForm();
            refetchMessages();
            ModalUtils.successMessage(null, 'Follow-Up Message saved successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveNoteExclusion, { loading: savingNoteExclusion }] = useMutation(OpportunityMutation.SAVE_AUTOMATED_RESPONSE_NOTE_EXCLUSION, {
        onCompleted: () => {
            refetchExclusions();
            ModalUtils.successMessage(null, 'Note exclusion modified successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [saveSettings, { loading: isSavingSettings }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (errorLoadingfollowUps) {
            ModalUtils.errorMessage(errorLoadingfollowUps?.graphQLErrors);
            return;
        }

        if (!loadingfollowUps && followUpsData) {
            setState((prevState) => ({
                ...prevState,
                followUpMessages: followUpsData?.getAIFollowUpMessages,
            }));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingfollowUps, errorLoadingfollowUps]);

    useEffect(() => {
        if (userInformation) {
            const availableLots = userInformation?.lots;
            if (availableLots && availableLots.length > 0) {
                const sortedLots = cloneDeep(availableLots).sort((a, b) => a.lotName.localeCompare(b.lotName));
                setState((prevState) => ({
                    ...prevState,
                    selectedLot: sortedLots[0].lotId,
                    lots: sortedLots,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        if (errorLoadingAssistants) {
            ModalUtils.errorMessage(errorLoadingAssistants?.graphQLErrors);
            return;
        }

        if (!loadingSssistants && assistantsData) {
            setState((prevState) => ({
                ...prevState,
                assistants: assistantsData?.pullAsistants,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSssistants, errorLoadingAssistants]);

    useEffect(() => {
        if (availabilitySettingsError) {
            ModalUtils.errorMessage(availabilitySettingsError?.graphQLErrors);
            return;
        }

        if (!availabilitySettingsLoading && availabilitySettingsData) {
            const { getSettings } = availabilitySettingsData;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                const startTimeSaved = getSettings.find((setting) => setting.key === AUTOMATED_RESPONSE_START_TIME)?.value;
                const endTimeSaved = getSettings.find((setting) => setting.key === AUTOMATED_RESPONSE_END_TIME)?.value;
                const availEnabled = getSettings.find((setting) => setting.key === AUTOMATED_RESPONSE_AVAILABILITY_ENABLED)?.value ?? 'true';

                const followUpStartTimeSaved = getSettings.find((setting) => setting.key === FOLLOW_UP_START_TIME)?.value;
                const followUpEndTimeSaved = getSettings.find((setting) => setting.key === FOLLOW_UP_END_TIME)?.value;
                const followUpAvailEnabled = getSettings.find((setting) => setting.key === FOLLOW_UP_AVAILABILITY_ENABLED)?.value ?? 'true';

                setState((prevState) => ({
                    ...prevState,
                    ...(startTimeSaved ? { startTime: new Date(startTimeSaved) } : {}),
                    ...(endTimeSaved ? { endTime: new Date(endTimeSaved) } : {}),
                    availabilityEnabled: availEnabled === 'true',
                    ...(followUpStartTimeSaved ? { followUpStartTime: new Date(followUpStartTimeSaved) } : {}),
                    ...(followUpEndTimeSaved ? { followUpEndTime: new Date(followUpEndTimeSaved) } : {}),
                    followUpavailabilityEnabled: followUpAvailEnabled === 'true',
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilitySettingsLoading, availabilitySettingsError]);

    useEffect(() => {
        if (representativeSettingsError) {
            ModalUtils.errorMessage(representativeSettingsError?.graphQLErrors);
            return;
        }

        if (!representativeSettingsLoading && representativeSettingsData) {
            const { getSettings } = representativeSettingsData;
            const savedRepresentative = getSettings.find((setting) => setting.key === AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE)?.value ?? -1;

            setState((prevState) => ({
                ...prevState,
                defaultRepresentative: Number(savedRepresentative),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [representativeSettingsLoading, representativeSettingsError]);

    useEffect(() => {
        if (errorLoadingUsers) {
            ModalUtils.errorMessage(errorLoadingUsers?.graphQLErrors);
            return;
        }

        if (!loadingUsers && usersData) {
            const { getRecordManagers } = usersData;
            if (ArrayUtils.isNotEmpty(getRecordManagers)) {
                setState((prevState) => ({
                    ...prevState,
                    users: getRecordManagers,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers, errorLoadingUsers]);

    useEffect(() => {
        if (errorLoadingExclusions) {
            ModalUtils.errorMessage(errorLoadingExclusions?.graphQLErrors);
            return;
        }

        if (!loadingExclusions && exclusionsData) {
            const { getAutomatedResponseNoteExclusions } = exclusionsData;
            if (ArrayUtils.isNotEmpty(getAutomatedResponseNoteExclusions)) {
                setState((prevState) => ({
                    ...prevState,
                    exclusions: getAutomatedResponseNoteExclusions,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingExclusions, errorLoadingExclusions]);

    useEffect(() => {
        if (errorLoadingSmsTemplateList) {
            ModalUtils.errorMessage(errorLoadingSmsTemplateList?.graphQLErrors);
            return;
        }

        if (!loadingSmsTemplateList && smsTemplateList) {
            const { getSMSTemplateList } = smsTemplateList;
            if (ArrayUtils.isNotEmpty(getSMSTemplateList)) {
                setState((prevState) => ({
                    ...prevState,
                    smsTemplates: getSMSTemplateList,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSmsTemplateList, errorLoadingSmsTemplateList]);

    useEffect(() => {
        if (errorLoadingEmailTemplateList) {
            ModalUtils.errorMessage(errorLoadingEmailTemplateList?.graphQLErrors);
            return;
        }

        if (!loadingEmailTemplateList && emailTemplateList) {
            const { getEmailTemplateList } = emailTemplateList;
            if (ArrayUtils.isNotEmpty(getEmailTemplateList)) {
                setState((prevState) => ({
                    ...prevState,
                    emailTemplates: getEmailTemplateList,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEmailTemplateList, errorLoadingEmailTemplateList]);

    useEffect(() => {
        if (errorLoadingResponses) {
            ModalUtils.errorMessage(errorLoadingResponses?.graphQLErrors);
            return;
        }

        if (!loadingResponses && responsesData) {
            const { getAutomatedResponseList } = responsesData;
            if (ArrayUtils.isNotEmpty(getAutomatedResponseList)) {
                setState((prevState) => ({
                    ...prevState,
                    responses: getAutomatedResponseList,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingResponses, errorLoadingResponses]);

    const changeFilter = (value) => {
        setState((prevState) => ({
            ...prevState,
            currentFilter: value,
        }));
    };

    const toggleForm = (record = {}) => {
        setState((prevState) => ({
            ...prevState,
            selectedResponse: Object.keys(record).length > 0 ? { ...record, keywords: record.keywords?.split('|') ?? [] } : RESPONSE_INIT_STATE,
            isFormOpen: !state.isFormOpen,
        }));
    };

    const toggleFollowUpForm = (record = {}) => {
        setState((prevState) => ({
            ...prevState,
            selectedFollowUpMessage: Object.keys(record).length > 0 ? record : FOLLOW_UP_INIT_STATE,
            isFollowUpFormOpen: !state.isFollowUpFormOpen,
        }));
    };

    const toggleExclusionsForm = () => {
        if (!isExclusionsOpen) refetchExclusions();
        setState((prevState) => ({
            ...prevState,
            isExclusionsOpen: !state.isExclusionsOpen,
        }));
    };

    const onChangeTime = (name, value) => {
        const isFollowUp = name === 'followUpStartTime' || name === 'followUpEndTime';
        let startVal = null;
        let endVal = null;

        if (isFollowUp) {
            startVal = name === 'followUpStartTime' ? value.toISOString() : followUpStartTime.toISOString();
            endVal = name === 'followUpEndTime' ? value.toISOString() : followUpEndTime.toISOString();
        } else {
            startVal = name === 'startTime' ? value.toISOString() : startTime.toISOString();
            endVal = name === 'endTime' ? value.toISOString() : endTime.toISOString();
        }

        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: isFollowUp ? FOLLOW_UP_START_TIME : AUTOMATED_RESPONSE_START_TIME,
                value: startVal,
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: isFollowUp ? FOLLOW_UP_END_TIME : AUTOMATED_RESPONSE_END_TIME,
                value: endVal,
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChangeAvailability = (name, value) => {
        const isFollowUp = name === 'followUpavailabilityEnabled';

        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: isFollowUp ? FOLLOW_UP_AVAILABILITY_ENABLED : AUTOMATED_RESPONSE_AVAILABILITY_ENABLED,
                value: value ? 'true' : 'false',
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChange = (name, value, stepIndex) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.selectedResponse);
            if (stepIndex == null) {
                clone[name] = value;
                if (name === 'aiEnabled' && !value) clone.aiAssistant = null;
            } else { clone.steps[stepIndex][name] = value; }

            return {
                ...prevState,
                selectedResponse: clone,
            };
        });
    };

    const onChangeFollowUpMessage = (name, value) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.selectedFollowUpMessage);
            clone[name] = value;

            return {
                ...prevState,
                selectedFollowUpMessage: clone,
            };
        });
    };

    const onChangeLot = (lotId) => {
        setState((prevState) => ({
            ...prevState,
            selectedLot: lotId,
        }));
    };

    const onChangeRepresentative = (value) => {
        saveSettings({
            variables: {
                category: LotsCategory.CRM,
                key: AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE,
                value: String(value),
                lotName: lots.find((l) => l.lotId === selectedLot)?.lotName,
                critical: false,
            },
        });

        setState((prevState) => ({
            ...prevState,
            defaultRepresentative: value,
        }));
    };

    const onChangeExclusion = (value) => {
        setState((prevState) => ({
            ...prevState,
            exclusionContent: value,
        }));
    };

    const addStep = () => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.selectedResponse);
            clone.steps.push({
                automatedResponseStepId: null,
                delayAmount: 0,
                delayMeasure: automatedResponseDelayMeasure.MINUTES,
                englishSMSTemplateId: null,
                englishEmailTemplateId: null,
                spanishSMSTemplateId: null,
                spanishEmailTemplateId: null,
            });

            return {
                ...prevState,
                selectedResponse: clone,
            };
        });
    };

    const removeStep = (stepIndex) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.selectedResponse);
            const deletedStepsClone = cloneDeep(prevState.deletedSteps);

            const { steps } = clone;
            deletedStepsClone.push(steps[stepIndex]);
            steps.splice(stepIndex, 1);
            steps.forEach((s, index) => { s.order = index + 1; });

            return {
                ...prevState,
                selectedResponse: clone,
                deletedSteps: deletedStepsClone,
            };
        });
    };

    const deleteMessage = (record) => {
        const {
            followUpMessageId,
            message,
            delayAmount,
            delayMeasure,
        } = record;

        saveFollowUpMessage({
            variables: {
                followUpMessageId,
                message,
                delayAmount,
                delayMeasure,
                active: false,
            },
        });
    };

    const onSaveMessage = () => {
        const {
            followUpMessageId,
            message,
            delayAmount,
            delayMeasure,
        } = selectedFollowUpMessage;

        saveFollowUpMessage({
            variables: {
                followUpMessageId,
                message,
                delayAmount,
                delayMeasure,
            },
        });
    };

    const saveResponse = () => {
        const {
            automatedResponseId,
            name,
            leadType,
            trigger,
            keywords,
            keywordsJoint,
            aiEnabled,
            aiAssistant,
            active,
            steps,
        } = selectedResponse;

        const stepsToModify = steps.map(({ createdOn, ...others }, index) => ({ ...others, order: index + 1 }));
        const input = {
            automatedResponseId,
            name,
            leadType,
            trigger,
            keywords: ArrayUtils.isNotEmpty(keywords) ? keywords.join('|') : null,
            keywordsJoint,
            target: modules.CRM,
            aiEnabled,
            aiAssistant,
            active,
            steps: [
                ...stepsToModify,
                ...deletedSteps.filter((ds) => ds.automatedResponseStepId != null).map(({ createdOn, ...others }) => ({ ...others, active: false })),
            ],
        };

        saveAutomatedResponse({
            variables: {
                input,
            },
        });
    };

    const addExclusion = () => {
        saveNoteExclusion({
            variables: {
                content: exclusionContent,
            },
        });
    };

    const removeExclusion = ({
        automatedResponseNoteExclusionId,
        content,
    }) => {
        saveNoteExclusion({
            variables: {
                automatedResponseNoteExclusionId,
                content,
                active: false,
            },
        });
    };

    const onSearch = (searchValue) => {
        setState((prevState) => ({
            ...prevState,
            search: searchValue,
        }));
    };

    const followUpColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Execute After',
            dataKey: 'executeAfter',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{`${record.delayAmount} ${record.delayMeasure}`}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Message',
            dataKey: 'message',
            width: 500,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.message}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedSecondaryInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            disabled={savingMessage}
                            onClick={() => toggleFollowUpForm(record)}
                        />
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={savingMessage}
                            onClick={() => deleteMessage(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.name}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Keywords',
            dataKey: 'keywords',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{(record.keywords ?? '').replaceAll('|', ', ')}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Join',
            dataKey: 'keywordsJoint',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (StringUtils.isEmpty(record.keywords)) return null;
                return (
                    <span className={classes.text}>{record.keywordsJoint}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Lead Type',
            dataKey: 'leadType',
            width: 100,
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Trigger',
            dataKey: 'trigger',
            width: 200,
        },
        {
            headerClassName: classes.tableHeader,
            label: 'AI Enabled',
            dataKey: 'aiEnabled',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.aiEnabled ? 'True' : 'False'}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Steps',
            dataKey: 'steps',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { steps = [], aiEnabled } = record;

                return (
                    <span className={classes.text}>{aiEnabled ? 'N/A' : steps.length}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleForm(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const { aiEnabled, aiAssistant } = selectedResponse;
    const innerColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Step',
            dataKey: 'step',
            width: 50,
            cellRenderer: (cell) => {
                const { rowIndex } = cell;
                return (
                    <span className={classes.text}>{rowIndex + 1}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Execute After Trigger',
            dataKey: 'executeAfter',
            width: 200,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <div className={classes.execution}>
                        <InputNumber
                            disabled={aiEnabled}
                            size="sm"
                            decimalScale={0}
                            thousandSeparator
                            allowNegative={false}
                            value={record.delayAmount}
                            onChange={(value) => onChange('delayAmount', value, rowIndex)}
                        />
                        <Select
                            disabled={aiEnabled}
                            nowrap
                            className={clsx(classes.input, StringUtils.isEmpty(record.delayMeasure) ? 'invalid-field' : '')}
                            name="delayMeasure"
                            value={record.delayMeasure}
                            onChange={(name, value) => onChange(name, value, rowIndex)}
                            options={Object.values(automatedResponseDelayMeasure).map((item) => ({ label: item, value: item }))}
                        />
                    </div>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'English SMS Template',
            dataKey: 'englishSmsTemplate',
            width: 200,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <Select
                        disabled={aiEnabled}
                        className={clsx(classes.input, StringUtils.isEmpty(record.englishSMSTemplateId) ? 'invalid-field' : '')}
                        name="englishSMSTemplateId"
                        value={record.englishSMSTemplateId}
                        onChange={(name, value) => onChange(name, value, rowIndex)}
                        options={
                            smsTemplates
                                .filter((t) => t.language === Language.ENGLISH).map((item) => ({ label: item.templateName, value: item.messageTemplateId }))
                        }
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'English Email Template',
            dataKey: 'englishEmailTemplate',
            width: 200,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <Select
                        disabled={aiEnabled}
                        className={clsx(classes.input, StringUtils.isEmpty(record.englishEmailTemplateId) ? 'invalid-field' : '')}
                        name="englishEmailTemplateId"
                        value={record.englishEmailTemplateId}
                        onChange={(name, value) => onChange(name, value, rowIndex)}
                        options={
                            emailTemplates
                                .filter((t) => t.language === Language.ENGLISH).map((item) => ({ label: item.templateName, value: item.messageTemplateId }))
                        }
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Spanish SMS Template',
            dataKey: 'spanishSmsTemplate',
            width: 200,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <Select
                        disabled={aiEnabled}
                        className={classes.input}
                        name="spanishSMSTemplateId"
                        value={record.spanishSMSTemplateId}
                        onChange={(name, value) => onChange(name, value, rowIndex)}
                        options={
                            smsTemplates
                                .filter((t) => t.language === Language.SPANISH).map((item) => ({ label: item.templateName, value: item.messageTemplateId }))
                        }
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Spanish Email Template',
            dataKey: 'spanishEmailTemplate',
            width: 200,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <Select
                        disabled={aiEnabled}
                        className={classes.input}
                        name="spanishEmailTemplateId"
                        value={record.spanishEmailTemplateId}
                        onChange={(name, value) => onChange(name, value, rowIndex)}
                        options={
                            emailTemplates
                                .filter((t) => t.language === Language.SPANISH).map((item) => ({ label: item.templateName, value: item.messageTemplateId }))
                        }
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 70,
            // eslint-disable-next-line arrow-body-style
            cellRenderer: (cell) => {
                const { rowIndex } = cell;
                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={savingResponse || aiEnabled}
                            onClick={() => removeStep(rowIndex)}
                        />
                    </div>
                );
            },
        },
    ];

    const exclusionsColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Content',
            dataKey: 'content',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.content}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 70,
            // eslint-disable-next-line arrow-body-style
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={savingNoteExclusion}
                            onClick={() => removeExclusion(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const filteredResponses = responses.filter((f) => f.active === !currentFilter);
    const parentWidth = document.getElementById('table-container')?.clientWidth;

    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 30;

    let followUpTableWidth = followUpColumns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > followUpTableWidth) followUpTableWidth = parentWidth - 30;

    const isNameValid = !StringUtils.isEmpty(selectedResponse.name);
    const isLeadTypeValid = !StringUtils.isEmpty(selectedResponse.leadType);
    const isTriggerValid = !StringUtils.isEmpty(selectedResponse.trigger);
    const anyStepIncomplete = selectedResponse.steps?.some((s) => s.delayAmount == null || !s.englishSMSTemplateId || !s.englishEmailTemplateId);
    const isSaveDisabled = !isNameValid || !isLeadTypeValid || !isTriggerValid || anyStepIncomplete || (aiEnabled && !aiAssistant);

    const filteredUsers = users
        .filter((user) => !StringUtils.isEmpty(user.firstName))
        .sort((a, b) => a.firstName.localeCompare(b.firstName));
    const filteredExclusions = StringUtils.isEmpty(search) ? exclusions : exclusions.filter((ex) => ex.content?.toLowerCase()?.includes(search.toLowerCase()));
    const steps = selectedResponse.steps ?? [];
    return (
        <>
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                        <div className={classes.leftSection}>
                            <div className={classes.leftSectionTop}>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>Lot</Form.Label>
                                    <Select
                                        size="sm"
                                        loading={isSavingSettings}
                                        className={classes.input}
                                        name="lot"
                                        onChange={(_, value) => onChangeLot(value)}
                                        value={selectedLot}
                                        options={lots.map((lot) => ({
                                            value: lot.lotId,
                                            label: lot.lotName,
                                        }))}
                                    />
                                </Form.Group>
                                <Tooltip
                                    title={`
                                        If the CRM Opportunity record isn't assigned to anyone, 
                                        then the automated email response and follow-up will use the selected person's work email
                                    `}
                                    placement="top"
                                >
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.dialogLabels}>Default Representative</Form.Label>
                                        <Select
                                            size="sm"
                                            loading={isSavingSettings || loadingUsers}
                                            className={classes.input}
                                            name="defaultRepresentative"
                                            onChange={(_, value) => onChangeRepresentative(value)}
                                            value={defaultRepresentative}
                                            options={[{
                                                userId: -1,
                                                firstName: 'None',
                                                lastName: '',
                                            }, ...filteredUsers].map((user) => ({
                                                value: user.userId,
                                                label: StringUtils
                                                    .toPascalCase(`${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`),
                                            }))}
                                        />
                                    </Form.Group>
                                </Tooltip>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>Global Availability</Form.Label>
                                    <div>
                                        <DatePicker
                                            disabled={!availabilityEnabled}
                                            size="sm"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            minTime={minStartTime}
                                            maxTime={endTime}
                                            selected={startTime}
                                            className={clsx('form-control form-control-sm')}
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeTime('startTime', date)}
                                        />
                                        <DatePicker
                                            disabled={!availabilityEnabled}
                                            size="sm"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={startTime}
                                            maxTime={maxStartTime}
                                            selected={endTime}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            className={clsx('form-control form-control-sm')}
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeTime('endTime', date)}
                                        />
                                        <div>
                                            <Checkbox
                                                color="primary"
                                                value="selected"
                                                name="availabilityEnabled"
                                                style={{ padding: 0 }}
                                                checked={availabilityEnabled}
                                                onChange={(e) => onChangeAvailability(e.target.name, e.target.checked)}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                            />
                                            <Form.Label className={classes.dialogLabels}>Send responses in availability time frame</Form.Label>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <RadioGroup row value={currentFilter} onChange={(e, val) => changeFilter(Number(val))}>
                                <FormControlLabel
                                    value={0}
                                    control={<Radio color="primary" />}
                                    label="Active"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio color="primary" />}
                                    label="Inactive"
                                />
                            </RadioGroup>
                        </div>
                        <div className={classes.otherActions}>
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => toggleForm()}
                            >
                                Add Response
                            </Button>
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => toggleExclusionsForm()}
                            >
                                Exclusions
                            </Button>
                        </div>
                    </FormControl>
                </Grid>
                <Grid className={classes.tableGrid} item xs={12}>
                    <div id="table-container" className={classes.tableContainer}>
                        {parentWidth > 0 && (
                            <VirtualTable
                                loading={loadingResponses}
                                rowHeight={45}
                                totalRecords={filteredResponses.length}
                                data={filteredResponses}
                                columns={columns}
                                width={tableWidth}
                            />
                        )}
                    </div>
                </Grid>
                <Grid className={classes.tableGrid} item xs={12}>
                    <div className={classes.followUp}>
                        <div>AI Follow-Up Messages</div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.dialogLabels}>Follow-Up Availability</Form.Label>
                                <div>
                                    <div>
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="followUpavailabilityEnabled"
                                            style={{ padding: 0 }}
                                            checked={followUpavailabilityEnabled}
                                            onChange={(e) => onChangeAvailability(e.target.name, e.target.checked)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                    <DatePicker
                                        disabled={!followUpavailabilityEnabled}
                                        size="sm"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        minTime={minStartTime}
                                        maxTime={followUpEndTime}
                                        selected={followUpStartTime}
                                        className={clsx('form-control form-control-sm')}
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChangeTime('followUpStartTime', date)}
                                    />
                                    <DatePicker
                                        disabled={!followUpavailabilityEnabled}
                                        size="sm"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        minTime={followUpStartTime}
                                        maxTime={maxStartTime}
                                        selected={followUpEndTime}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className={clsx('form-control form-control-sm')}
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChangeTime('followUpEndTime', date)}
                                    />
                                </div>
                            </Form.Group>
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => toggleFollowUpForm()}
                            >
                                Add Message
                            </Button>
                        </div>
                    </div>
                    <div className={classes.tableContainer}>
                        {parentWidth > 0 && (
                            <VirtualTable
                                loading={loadingfollowUps}
                                rowHeight={45}
                                totalRecords={followUpMessages.length}
                                data={followUpMessages}
                                columns={followUpColumns}
                                width={followUpTableWidth}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            {isFormOpen && (
                <Dialog
                    open
                    maxWidth="lg"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Add/Edit Automated Response"
                        onClose={toggleForm}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.content}>
                            <div>
                                <div>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.dialogLabels}>Name</Form.Label>
                                        <Form.Control
                                            maxLength="150"
                                            className={clsx(classes.input, !isNameValid ? 'invalid-field' : '')}
                                            type="text"
                                            name="name"
                                            value={selectedResponse.name}
                                            onChange={(e) => onChange(e.target.name, e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.dialogLabels}>Lead Type</Form.Label>
                                        <Select
                                            nowrap
                                            className={clsx(classes.input, !isLeadTypeValid ? 'invalid-field' : '')}
                                            name="leadType"
                                            value={selectedResponse.leadType}
                                            onChange={(name, value) => onChange(name, value)}
                                            options={Object.values(leadTypeOption).map((item) => ({ label: item, value: item }))}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.dialogLabels}>Trigger</Form.Label>
                                        <Select
                                            nowrap
                                            className={clsx(classes.input, !isTriggerValid ? 'invalid-field' : '')}
                                            name="trigger"
                                            value={selectedResponse.trigger}
                                            onChange={(name, value) => onChange(name, value)}
                                            options={Object.values(automatedResponseTrigger).map((item) => ({ label: item, value: item }))}
                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group as={Row}>
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="active"
                                            style={{ padding: 0 }}
                                            checked={selectedResponse.active}
                                            onChange={(e) => onChange(e.target.name, e.target.checked)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                        <Form.Label className={classes.dialogLabels}>Active</Form.Label>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={classes.keywords}>
                                <Form.Group as={Col}>
                                    <Tooltip
                                        title="Join Keywords With"
                                        placement="top"
                                    >
                                        <Form.Label className={classes.dialogLabels}>Join With</Form.Label>
                                    </Tooltip>
                                    <Select
                                        nowrap
                                        className={classes.input}
                                        name="keywordsJoint"
                                        value={selectedResponse.keywordsJoint}
                                        onChange={(name, value) => onChange(name, value)}
                                        options={Object.values(automatedResponseKeywordsJoint).map((item) => ({ label: item, value: item }))}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>
                                        You can enter keywords or sentences that are contained within leads to apply these follow-up templates
                                    </Form.Label>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        className={classes.autocomplete}
                                        options={[]}
                                        value={selectedResponse.keywords}
                                        onChange={(_, value) => onChange('keywords', value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                                variant="standard"
                                                size="small"
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={classes.aiSection}>
                                <div>AI Enablement</div>
                                <div>
                                    <Checkbox
                                        color="primary"
                                        value="selected"
                                        name="aiEnabled"
                                        style={{ padding: 0 }}
                                        checked={aiEnabled}
                                        onChange={(e) => onChange(e.target.name, e.target.checked)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                    <Select
                                        nowrap
                                        placeholder="Assistant"
                                        className={clsx(classes.input, aiEnabled && !aiAssistant ? 'invalid-field' : '')}
                                        name="aiAssistant"
                                        value={aiAssistant}
                                        onChange={(name, value) => onChange(name, value)}
                                        options={assistants.map(({ aiAssistantId, name }) => ({ label: name, value: aiAssistantId }))}
                                    />
                                </div>
                            </div>
                            <div className={classes.message}>
                                {`
                                    If the Record Manager or the Sales Rep's name is blank in a record and the template is using their name to be merged in
                                    the email or SMS, then the place holder for the name will be blank in the message sent.
                                `}
                            </div>
                            <div>
                                <Grid className={classes.tableGrid} item xs={12}>
                                    <div className={clsx(classes.tableContainer, classes.innerTableContainer)}>
                                        <VirtualTable
                                            loading={false}
                                            rowHeight={40}
                                            totalRecords={steps.length}
                                            data={steps}
                                            columns={innerColumns}
                                        />
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Form.Group as={Row}>
                            {!aiEnabled && (
                                <Button
                                    size="small"
                                    className={classes.containedSecondaryInfo}
                                    disabled={savingResponse}
                                    onClick={addStep}
                                >
                                    Add Step
                                </Button>
                            )}
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Button
                                size="small"
                                className={classes.containedSecondaryInfo}
                                disabled={isSaveDisabled || savingResponse}
                                onClick={saveResponse}
                            >
                                Save
                            </Button>
                        </Form.Group>
                    </DialogActions>
                </Dialog>
            )}
            {isFollowUpFormOpen && (
                <Dialog
                    open
                    maxWidth="md"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Follow-Up Message"
                        onClose={() => toggleFollowUpForm()}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.followUpContent}>
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>
                                        Message
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        maxLength={4000}
                                        value={selectedFollowUpMessage.message}
                                        className={clsx({ 'invalid-field': StringUtils.isEmpty(selectedFollowUpMessage.message) }, classes.textArea)}
                                        onChange={(e) => onChangeFollowUpMessage('message', e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>
                                        Execute after the last AI message sent
                                    </Form.Label>
                                    <div className={classes.execution}>
                                        <InputNumber
                                            size="sm"
                                            decimalScale={0}
                                            thousandSeparator
                                            allowNegative={false}
                                            value={selectedFollowUpMessage.delayAmount}
                                            onChange={(value) => onChangeFollowUpMessage('delayAmount', value)}
                                        />
                                        <Select
                                            nowrap
                                            className={classes.input}
                                            name="delayMeasure"
                                            value={selectedFollowUpMessage.delayMeasure}
                                            onChange={(name, value) => onChangeFollowUpMessage(name, value)}
                                            options={Object.values(automatedResponseDelayMeasure).map((item) => ({ label: item, value: item }))}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Form.Group as={Row}>
                            <Button
                                disabled={StringUtils.isEmpty(selectedFollowUpMessage.message) || savingMessage}
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => onSaveMessage()}
                            >
                                Save
                            </Button>
                        </Form.Group>
                    </DialogActions>
                </Dialog>
            )}
            {isExclusionsOpen && (
                <Dialog
                    open
                    maxWidth="md"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Exclusions"
                        onClose={toggleExclusionsForm}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.exclusionsWrapper}>
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.dialogLabels}>Name</Form.Label>
                                    <div className={classes.keywords}>
                                        <Form.Control
                                            className={clsx(classes.input, StringUtils.isEmpty(exclusionContent) ? 'invalid-field' : '')}
                                            type="text"
                                            value={exclusionContent}
                                            onChange={(e) => onChangeExclusion(e.target.value)}
                                        />
                                        <Button
                                            size="small"
                                            className={classes.containedSecondaryInfo}
                                            disabled={savingNoteExclusion || StringUtils.isEmpty(exclusionContent)}
                                            onClick={() => addExclusion()}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className={classes.search}>
                                <InputSearch
                                    executeWhenClearButton={() => onSearch('')}
                                    onSearch={onSearch}
                                />
                            </div>
                            <div>
                                <Grid className={classes.tableGrid} item xs={12}>
                                    <div className={clsx(classes.tableContainer, classes.exclusionsTableContainer)}>
                                        <VirtualTable
                                            loading={loadingExclusions}
                                            rowHeight={40}
                                            totalRecords={filteredExclusions.length}
                                            data={filteredExclusions}
                                            columns={exclusionsColumns}
                                        />
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default AutomatedResponse;

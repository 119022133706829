import * as yup from 'yup';

const AssignPaymentSchema = yup.object().shape({
    transactionNumber: yup.number().required(),
    newAccountNumber: yup.number().required(),
    newReason: yup.string().required(),
    transactionType: yup.string().required(),
    comment: yup.string().required(),
});

export default AssignPaymentSchema;

import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Select from 'components/widgets/Select';
import { Language } from 'utils/enum/Customer';
import { leadType } from 'utils/enum/OpportunityEnum';
import InputNumber from 'components/widgets/InputNumber';
import useDealTypes from 'components/hook/crm/useDealTypes';
import LeadHelper from 'components/modules/lead/LeadHelper';
import useLeadSources from 'components/hook/crm/useLeadSources';
import useSalespersons from 'components/hook/crm/useSalespersons';
import DialogActions from 'components/widgets/modal/DialogActions';
import useRecordManagers from 'components/hook/crm/useRecordManagers';

// Material UI
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import {
    Dialog,
    DialogContent,
    makeStyles,
    Button,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';

// Bootstrap
import { Form, Row, Col } from 'react-bootstrap';

// Apollo Client
import { useMutation } from '@apollo/client';
import Permissions from 'utils/enum/Permissions';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';

const leadTypes = Object.keys(leadType).filter((t) => leadType[t] !== leadType.EMAIL).map((item) => ({ label: leadType[item], value: leadType[item] }));
const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > textarea': {
            resize: 'none',
            height: '80px',
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    languageWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
            width: '180px',
            marginRight: '5px',
        },
    },
    ...ButtonStyles.getStyle(theme),
}));

const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_FIELD: 'setField',
};
const initState = {
    leadSourceId: null,
    dealType: null,
    leadType: null,
    availableCash: 0,
    recordManagerId: null,
    salespersonId: null,
    obstacleToCloseId: null,
    language: Language.ENGLISH,
    lastResults: null,
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPES.SET_RECORD:
        return update(state, {
            leadSourceId: { $set: payload.leadSourceId },
            dealType: { $set: payload.dealType },
            leadType: { $set: LeadHelper.mapEmailLeadType(payload.leadType) },
            availableCash: { $set: payload.availableCash },
            recordManagerId: { $set: payload.recordManagerId },
            salespersonId: { $set: payload.salespersonId },
            language: { $set: payload.prospect.language },
            obstacleToCloseId: { $set: payload.obstacleToCloseId },
            lastResults: { $set: payload.lastResults },
        });
    case ACTION_TYPES.SET_FIELD:
        return update(state, {
            [payload?.field]: { $set: payload?.value },
        });
    default:
        return initState;
    }
};

const keyStore = new KeyStore();
const OpportunityForm = ({
    open, onClose, record, crmId,
    updateLanguage, obstacles,
}) => {
    const { dealTypes } = useDealTypes();
    const { leadSources } = useLeadSources();

    const { salespersons } = useSalespersons({ lotId: record.lotId });
    const { recordManagers } = useRecordManagers({ lotId: record.lotId });
    const classes = useStyles();
    const [state, dispatchData] = useReducer(reducer, initState);
    const [updateOpportunity] = useMutation(OpportunityMutation.UPDATE_OPPORTUNITY_BASIC_INFORMATION);
    const [updateCustomerLanguage] = useMutation(CustomerMutate.UPDATE_CUSTOMER_LANGUAGE);
    const crmOpportunityChangeManagement = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_CHANGE_RECORD_MANAGER);
    const crmOpportunityChangeSalesRep = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_CHANGE_SALES_REPRESENTATIVE);

    const onChange = (field, value) => {
        dispatchData({
            type: ACTION_TYPES.SET_FIELD,
            payload: {
                field,
                value,
            },
        });
    };

    const onSaveLanguage = async () => {
        const { customerId } = record.prospect;
        const { language } = state;

        const response = await updateCustomerLanguage({ variables: { customerId, language } });
        if (response.data?.updateCustomerLanguage) {
            updateLanguage(language);
            ModalUtils.successMessage(null, 'Customer\'s Language changed Successfully');
        } else {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'customer'));
        }
    };

    useEffect(() => {
        dispatchData({
            type: ACTION_TYPES.SET_RECORD,
            payload: {
                ...record,
            },
        });
    }, [record]);

    const onSave = async () => {
        try {
            const input = {
                leadSourceId: state.leadSourceId,
                leadType: LeadHelper.mapLeadTypeToSave(state.leadType),
                dealType: state.dealType,
                availableCash: state.availableCash,
                recordManagerId: state.recordManagerId,
                salespersonId: state.salespersonId,
                obstacleToCloseId: state.obstacleToCloseId,
                lastResults: state.lastResults ?? '',
            };

            const response = await updateOpportunity({ variables: { input, crmId } });

            if (response.data?.updateOpportunityBasicInformation) {
                ModalUtils.successMessage(null, 'Successfully saved!');
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'opportunity'));
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogAppBar
                title="Detail"
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col md>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lead Source:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="leadSourceId"
                                        placeholder="Select"
                                        size="sm"
                                        options={leadSources}
                                        value={state.leadSourceId}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Deal Type:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="dealType"
                                        placeholder="Select"
                                        size="sm"
                                        options={dealTypes}
                                        value={state.dealType}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lead Type:</Form.Label>
                                    <Select
                                        className={clsx('select-bootstrap', { 'invalid-field': StringUtils.isEmpty(state.leadType) })}
                                        name="leadType"
                                        placeholder="Select"
                                        size="sm"
                                        options={leadTypes}
                                        value={state.leadType}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Down payment:</Form.Label>
                                    <InputNumber
                                        showCurrency
                                        thousandSeparator
                                        placeholder="$0.00"
                                        value={state.availableCash}
                                        onChange={(value) => onChange('availableCash', value)}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Obstacle to Close:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="obstacleToCloseId"
                                        placeholder="Select"
                                        size="sm"
                                        options={obstacles.map((item) => ({ label: item.description, value: item.obstacleToCloseId }))}
                                        value={state.obstacleToCloseId}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                        <Col md>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Last Results:</Form.Label>
                                    <Form.Control
                                        value={state.lastResults ?? ''}
                                        as="textarea"
                                        size="sm"
                                        maxLength={500}
                                        onChange={(e) => onChange('lastResults', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Record Manager:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="recordManagerId"
                                        placeholder="Select"
                                        size="sm"
                                        options={recordManagers}
                                        disabled={!crmOpportunityChangeManagement}
                                        value={state.recordManagerId}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Sales Rep:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="salespersonId"
                                        placeholder="Select"
                                        size="sm"
                                        disabled={!crmOpportunityChangeSalesRep}
                                        options={salespersons}
                                        value={state.salespersonId}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Language:</Form.Label>
                                    <div className={classes.languageWrapper}>
                                        <Select
                                            nowrap
                                            size="sm"
                                            name="language"
                                            loading={false}
                                            className={classes.input}
                                            onChange={(name, value) => onChange(name, value)}
                                            value={state.language || ''}
                                            options={Object.values(Language).map((l) => ({
                                                value: l,
                                                label: l,
                                            }))}
                                        />
                                        <Button
                                            size="small"
                                            className={classes.containedSecondaryInfo}
                                            onClick={() => onSaveLanguage()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                variant="contained"
            />
        </Dialog>
    );
};

OpportunityForm.propTypes = {
    open: PropTypes.bool,
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    updateLanguage: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    obstacles: PropTypes.array.isRequired,
};

OpportunityForm.defaultProps = {
    open: false,
    record: {},
};

export default OpportunityForm;

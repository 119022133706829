import { gql } from '@apollo/client';

export default class PartsMutation {
static CREATE_UPDATE_PURCHASE_ORDER = gql`
mutation createUpdatePurchase($record: PurchaseOrderInput!) {
  createUpdatePurchase(record: $record)
}`

static CHANGE_STATUS_PURCHASE_ORDERS = gql`
mutation changePurchaseStatus($record: PurchaseOrderChangeStatusInput!) {
  changePurchaseStatus(record: $record)
}`;

static PRINT_PURCHASE_ORDERS = gql`
mutation printPurchaseOrders($ids: [Int!]!) {
  printPurchaseOrders(ids: $ids)
}`;

static RECEIVE_PURCHASE_ORDER = gql`
mutation receivePurchase($id: Int!, $accountNumber: Int!, $referenceNumber: String!) {
  receivePurchase(id: $id, accountNumber: $accountNumber, referenceNumber: $referenceNumber)
}`;

static RETURN_PURCHASE_ORDER = gql`
mutation returnPurchase($id: Int!, $accountNumber: Int!, $referenceNumber: String!) {
  returnPurchase(id: $id, accountNumber: $accountNumber, referenceNumber: $referenceNumber)
}`;

static CREATE_PARTS_INVENTORY = gql`
mutation createPartsInventory($record: PartsInventoryInput!) {
  createPartsInventory(record: $record)
}`;

static UPDATE_PARTS_INVENTORY = gql`
mutation updatePartsInventory($record: PartsInventoryInput!) {
  updatePartsInventory(record: $record)
}`;

static SAVE_DEFAULT_PARTS_SETTINGS = gql`
  mutation saveDefaultPartsSettings($record: PartsSettingsInput!) {
    saveDefaultPartsSettings(record: $record)
  }
`;

static ACTIVE_PART = gql`
  mutation activePart($id: Int, $active: Boolean) {
    activePart(id: $id, active: $active)
  }
`;

static TRANSFER_PART = gql`
  mutation transferPart($id: Int, $quantity: Int, $lotName: String) {
    transferPart(id: $id, quantity: $quantity, lotName: $lotName)
  }
`;

static CREATE_PARTS_TICKET = gql`
mutation createPartsTicket($record: PartsTicketInput!) {
  createPartsTicket(record: $record)
}`;

static UPDATE_PARTS_TICKET = gql`
mutation updatePartsTicket($record: PartsTicketInput!) {
  updatePartsTicket(record: $record)
}`;

static CHANGE_STATUS_PARTS_TICKET = gql`
mutation changePartsTicketStatus($record: PartsTicketChangeStatusInput!) {
  changePartsTicketStatus(record: $record){
    success
    errorCode
    errorMessage
  }
}`;

static PRINT_PARTS_TICKET = gql`
mutation printPartsTicket($id: Int!) {
  printPartsTicket(id: $id)
}`;

static REQUEST_PART = gql`
mutation requestPart($record: RequestPartsInput!) {
  requestPart(record: $record)
}`;

static DELETE_PART = gql`
  mutation deletePart($id: Int) {
    deletePart(id: $id)
  }
`;
}

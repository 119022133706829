import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, useTheme,
    useMediaQuery, Tooltip, RadioGroup,
    FormControlLabel, Radio, Chip,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import ModalUtils from 'utils/ModalUtils';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    EditOutlinedIcon, OpenInBrowserOutlinedIcon, BlockOutlinedIcon, PrintOutlinedIcon, ReceiptOutlinedIcon, SyncAltOutlinedIcon, AddIcon,
} from 'components/icons';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import InputSearch from 'components/widgets/InputSearch';
import DateUtils from 'lib/DateUtils';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { PartsPurchaseSubModules, PartsTicketAction, PartsTicketStatus } from 'utils/enum/PartsPurchaseEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import PartsSubscription from 'services/graphQL/subscription/parts/PartsSubscription';
import printJS from 'print-js';
import NumberUtils from 'lib/NumberUtils';
import CommonJournalPopup from 'components/modules/accounting/journal/list/CommonJournalPopup';
import { JournalTypes } from 'utils/enum/AccountingEnum';
import useAccountingGraphqlMethods from 'components/modules/accounting/journal/hooks/useAccountingGraphqlMethods';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    highlightText: {
        color: theme.palette.text.red,
    },
    paddingTop10: {
        paddingBottom: '10px',
    },
}));

const ACTION_TYPES = {
    SET_EDITING: 'setEditing',
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
    SET_ACTION: 'setAction',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_EDITING: {
        return {
            ...state,
            isEditing: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ACTION: {
        return {
            ...state,
            ...action.value,
        };
    }
    default: return action.value;
    }
};

const PartsTicketList = () => {
    const keyStore = new KeyStore();
    const PARTS_TICKET_WRITE_ADD = keyStore.hasPermission(Permission.PARTS_TICKET_WRITE_ADD);
    const PARTS_TICKET_WRITE_EDIT = keyStore.hasPermission(Permission.PARTS_TICKET_WRITE_EDIT);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
            },
            status: 'quote',
            search: '',
            lotName: '',
        },
        table: {
            records: [],
            totalCount: 0,
        },
        actionRecord: null,
        actionType: '',
        postedRecordId: null,
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        postingJournalAutomatic,
        postingJournal,
        postJournalToAccountingAutomatic,
    } = useAccountingGraphqlMethods({
        journalType: JournalTypes.PARTS_TICKET,
    });

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow,
        actionRecord, actionType,
    } = state;

    const {
        data, loading, error,
    } = useQuery(PartsQuery.GET_PARTS_TICKET_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                status: params.status,
                lotName: params.lotName,
            },
            sort: [
                { fieldName: 'ticketOpen', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionRecord: null,
                actionType: '',
            },
        });
    };

    const [printTicket, { loading: printing }] = useMutation(PartsMutation.PRINT_PARTS_TICKET, {
        onCompleted: (mutationData) => {
            if (mutationData?.printPartsTicket) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printPartsTicket,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [changeStatus, { loading: changingStatus }] = useMutation(PartsMutation.CHANGE_STATUS_PARTS_TICKET, {
        onCompleted: (mutationData) => {
            const { success, errorCode, errorMessage } = mutationData.changePartsTicketStatus;

            if (success) {
                ModalUtils.successMessage(null, 'Change Status sucessfully');

                onCloseActionDialog();
            }

            if (!success && errorCode === -5 && errorMessage.length > 0) {
                const messagesError = errorMessage.map((m) => ({
                    message: `- ${m}`,
                }));

                messagesError.unshift({ message: 'You cannot close this ticket because the following parts are not longer in inventory.' });
                ModalUtils.errorMessage(messagesError);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getPartsTicketList } = data;

            records.push(...getPartsTicketList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getPartsTicketList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onActionConfirm = async () => {
        onCloseActionDialog();

        if (actionType === PartsTicketAction.CLOSE) {
            const resultChangeStatus = await changeStatus({
                variables: {
                    record: {
                        id: Number(actionRecord.ticketNumber),
                        status: PartsTicketStatus.CLOSED,
                    },
                },
            });

            if (resultChangeStatus?.data?.changePartsTicketStatus.success) {
                postJournalToAccountingAutomatic(Number(actionRecord.ticketNumber), JournalTypes.PARTS_TICKET);
            }
        } else if (actionType === PartsTicketAction.VOID) {
            await changeStatus({
                variables: {
                    record: {
                        id: Number(actionRecord.ticketNumber),
                        status: PartsTicketStatus.VOID,
                    },
                },
            });
        } else if (actionType === PartsTicketAction.QUOTE) {
            await changeStatus({
                variables: {
                    record: {
                        id: Number(actionRecord.ticketNumber),
                        status: PartsTicketStatus.QUOTE,
                    },
                },
            });
        } else if (actionType === PartsTicketAction.PRINT) {
            await printTicket({
                variables: {
                    id: Number(actionRecord.ticketNumber),
                },
            });
        }
    };

    const onActionClick = (val, action) => {
        dispatch({
            type: ACTION_TYPES.SET_ACTION,
            value: {
                actionRecord: val,
                actionType: action,
            },
        });
    };

    useSubscription(PartsSubscription.PARTS_TICKET_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { action, data: subsData, id } = subscriptionData?.data?.partsTicketChanged;
            if (action === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                records.unshift({ ...currentData });

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount: totalCount + 1,
                    },
                });
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.ticketNumber) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, { ...currentData });

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                            totalCount: table.totalCount,
                        },
                    });
                }
            }
        },
    });

    const getFormattedText = (status, value) => {
        const textColorCls = status.toLowerCase() === PartsTicketStatus.VOID.toLowerCase() ? classes.voidCls : '';
        return <span className={textColorCls}>{value}</span>;
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Ticket #',
            minWidth: 100,
            width: 100,
            id: 'TicketNumber',
            accessor: 'ticketNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Date',
            minWidth: 100,
            width: 100,
            id: 'TicketOpen',
            accessor: 'ticketOpen',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Customer',
            minWidth: 200,
            width: 200,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Counter Person',
            minWidth: 200,
            width: 200,
            id: 'CounterPerson',
            accessor: 'counterPerson',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Sales Type',
            minWidth: 100,
            width: 100,
            id: 'SalesType',
            accessor: 'salesType',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Lot',
            minWidth: 120,
            width: 120,
            id: 'LotName',
            accessor: 'lotName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Sub Total',
            minWidth: 100,
            width: 100,
            accessor: 'subTotal',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Discount',
            minWidth: 100,
            width: 100,
            accessor: 'discount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Tax',
            minWidth: 100,
            width: 100,
            accessor: 'totalTax',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Total',
            minWidth: 100,
            width: 100,
            accessor: 'total',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Notes',
            minWidth: 90,
            id: 'QuickNote',
            accessor: 'quickNote',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Status',
            minWidth: 150,
            width: 150,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.badgeCleared;
                if (status.toLowerCase() === PartsTicketStatus.IN_PROGRESS.toLowerCase()) badgeStatus = classes.badgePrinted;
                else if (status.toLowerCase() === PartsTicketStatus.CLOSED.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === PartsTicketStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, isMobile ? '' : classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, isMobile ? '' : classes.hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        ticketNumber, status, postedDate,
                    },
                    original,
                } = cellData;

                return (
                    <div className={classes.buttonSpacing}>
                        {PARTS_TICKET_WRITE_EDIT && (
                            <Button
                                onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PARTS_TICKET}/edit/${ticketNumber}`)}
                                variant="outlined"
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Edit' }
                            </Button>
                        )}
                        {status.toLowerCase() === PartsTicketStatus.CLOSED.toLowerCase() && PARTS_TICKET_WRITE_EDIT && (
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<ReceiptOutlinedIcon />}
                                onClick={() => onActionClick(original, PartsTicketAction.POST)}
                            >
                                {postedDate != null ? 'Revert journal' : 'Post to accounting'}
                            </Button>
                        )}
                        <Button
                            onClick={() => onActionClick(original, PartsTicketAction.PRINT)}
                            variant="outlined"
                            startIcon={<PrintOutlinedIcon className={classes.actionButtonPrint} />}
                            size="small"
                        >
                            { isMobile ? '' : 'Print' }
                        </Button>
                        {PARTS_TICKET_WRITE_EDIT && status === PartsTicketStatus.QUOTE && (
                            <Button
                                onClick={() => onActionClick(original, PartsTicketAction.VOID)}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Void' }
                            </Button>
                        )}
                        {PARTS_TICKET_WRITE_EDIT && status === PartsTicketStatus.QUOTE && (
                            <Button
                                onClick={() => onActionClick(original, PartsTicketAction.CLOSE)}
                                variant="outlined"
                                startIcon={<OpenInBrowserOutlinedIcon className={classes.actionButtonApprove} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Close' }
                            </Button>
                        )}
                        {PARTS_TICKET_WRITE_EDIT && status === PartsTicketStatus.VOID && (
                            <Button
                                onClick={() => onActionClick(original, PartsTicketAction.QUOTE)}
                                variant="outlined"
                                startIcon={<SyncAltOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Quote' }
                            </Button>
                        )}
                        {PARTS_TICKET_WRITE_EDIT && status === PartsTicketStatus.CLOSED && (
                            <Button
                                onClick={() => onActionClick(original, PartsTicketAction.VOID)}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Void' }
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    let dialogMessage = '';
    let dialogTitle = '';
    let dialogDescription = '';
    if (changingStatus)dialogMessage = 'Changing Status...';

    switch (actionType) {
    case PartsTicketAction.CLOSE:
        dialogTitle = 'Change Status';
        dialogDescription = 'Are you sure you want to close this ticket?';
        break;
    case PartsTicketAction.VOID:
        dialogTitle = 'Change Status';
        dialogDescription = actionRecord?.status.toLowerCase() === PartsTicketStatus.CLOSED.toLowerCase()
            ? 'Are you sure you want to void this ticket? This will get back the parts to inventory.'
            : 'Are you sure you want to void this ticket?';
        break;
    case PartsTicketAction.QUOTE:
        dialogTitle = 'Change Status';
        dialogDescription = 'Are you sure you want to back to quote this ticket?';
        break;
    case PartsTicketAction.PRINT:
        dialogTitle = 'Print Ticket';
        dialogDescription = 'Are you sure you want to print this ticket?';
        break;
    default:
        break;
    }

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.containerFilterSearch}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <RadioGroup row value={params.status} onChange={(e, val) => onFilterChange('status', val)}>
                        <FormControlLabel
                            value="quote"
                            control={<Radio color="primary" />}
                            label="Quote"
                        />
                        <FormControlLabel
                            value="closed"
                            control={<Radio color="primary" />}
                            label="Closed"
                        />
                        <FormControlLabel
                            value="void"
                            control={<Radio color="primary" />}
                            label="Void"
                        />
                    </RadioGroup>
                </div>
                <div className={clsx(classes.buttonSpacing, classes.justifyContentCenter)}>
                    {PARTS_TICKET_WRITE_ADD
                && (
                    <Tooltip title="New Parts Ticket" placement="top-start">
                        <span>
                            <Button
                                onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PARTS_TICKET}/create`)}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                disabled={loading}
                                size="small"
                            >
                                New
                            </Button>
                        </span>
                    </Tooltip>
                )}
                </div>
            </Header>
            <Container className={classes.containerSplit}>
                <Table
                    data={table?.records}
                    columns={columns}
                    cursor="default"
                    load={loading}
                    totalRecords={table?.totalCount}
                    loadMore={loadMore}
                    rowSelected
                    enableRowHover
                    className="-highlight actionsInLine"
                    getTrProps={(_, rowInfo) => ({
                        onClick: () => {
                            if (selectedRow?.ticketNumber === rowInfo.original.ticketNumber) return;
                            dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: rowInfo.original });
                        },
                    })}
                />
            </Container>
            <If condition={actionRecord?.ticketNumber > 0 && actionType !== PartsTicketAction.POST}>
                <ConfirmDialog
                    title={dialogTitle}
                    description={dialogDescription}
                    open={actionType && actionRecord.ticketNumber > 0}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirm}
                />
            </If>
            <If condition={actionRecord?.ticketNumber > 0 && actionType === PartsTicketAction.POST}>
                <CommonJournalPopup
                    showPopup
                    postedDate={actionRecord?.postedDate ?? null}
                    isAlreadyPosted={actionRecord?.postedDate != null}
                    recordId={Number(actionRecord?.ticketNumber ?? 0)}
                    onToogleModal={onCloseActionDialog}
                    journalType={JournalTypes.PARTS_TICKET}
                />
            </If>
            <If condition={postingJournalAutomatic || postingJournal}>
                <DialogActionMessage message="Posting to accounting" />
            </If>
            <If condition={changingStatus || printing}>
                <DialogActionMessage message={dialogMessage} />
            </If>
        </div>
    );
};

export default PartsTicketList;

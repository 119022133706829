import React from 'react';

// Material UI
import {
    Divider, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import CardVehicle from 'components/modules/lead/widgets/CardVehicle';

const useStyle = makeStyles((theme = {}) => ({
    label: {
        color: `${theme.palette.text.minsk}`,
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
        whiteSpace: 'nowrap',
    },
    middleColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    item: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    vehicle: {
        padding: 0,
        border: 'none',
        minWidth: 210,
        maxWidth: 210,
    },
}));

const DealWidgetItem = ({
    accountNumber, vehicle, soldDate, dealType, status,
}) => {
    const date = DateUtils.getOnlyDate(soldDate);
    const classes = useStyle();

    return (
        <div>
            <Grid container className={classes.item}>
                <Grid item xs={4}>
                    <CardVehicle
                        className={classes.vehicle}
                        vehicle={vehicle}
                    />
                </Grid>
                <Grid item xs={4} className={classes.middleColumn}>
                    <div>
                        <span className={classes.label}>Type: </span>
                        <span className={clsx(classes.value)}>{dealType}</span>
                    </div>
                    <div>
                        <span className={classes.label}>Status: </span>
                        <span className={clsx(classes.value)}>
                            {status}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightColumn}>
                    <div>
                        <span className={classes.label}>Account #: </span>
                        <span className={classes.value}>
                            {accountNumber}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Sold Date: </span>
                        <span className={clsx(classes.value)}>
                            {date}
                        </span>
                    </div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};

DealWidgetItem.propTypes = {
    accountNumber: PropTypes.number.isRequired,
    soldDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dealType: PropTypes.string.isRequired,
    vehicle: PropTypes.object.isRequired,
};

export default DealWidgetItem;

import { gql } from '@apollo/client';

export default class HoursWorkedMutation {
    static PRINT_TIME_SHEET = gql`
        mutation printPayrollTimeSheet($employeeId: Int!, $payPeriod: String!, $lotId: Int!) {
            printPayrollTimeSheet(employeeId: $employeeId, payPeriod: $payPeriod, lotId: $lotId)
        }
    `;

    static MARK_TIME_FOR_DELETE = gql`
        mutation markTimeForDelete($timeId: Int!) {
            markTimeForDelete(timeId: $timeId)
        }
    `;

    static APPROVE_HOURS = gql`
        mutation approveHours($employeeId: Int!, $payPeriod: String!) {
            approveHours(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static ADD_TIME_LINE = gql`
        mutation addTimeLine($input: EmployeeHoursInput!) {
            addTimeLine(input: $input)
        }
    `;

    static UPDATE_TIME_LINE = gql`
        mutation updateTimeLine($input: EmployeeHoursInput!) {
            updateTimeLine(input: $input)
        }
    `;
}

const ROLE = Object.freeze({
    USER: 'user',
    ASSISTANT: 'assistant',
});

export const SUPPORTED_FILES_EXT = Object.freeze({
    C: '.c',
    CS: '.cs',
    CPP: '.cpp',
    DOC: '.doc',
    DOCX: '.docx',
    HTML: '.html',
    JAVA: '.java',
    JSON: '.json',
    MD: '.md',
    PDF: '.pdf',
    PHP: '.php',
    PPTX: '.pptx',
    PY: '.py',
    RB: '.rb',
    TEX: '.tex',
    TXT: '.txt',
    CSS: '.css',
    JS: '.js',
    SH: '.sh',
    TS: '.ts',
});

export default ROLE;

/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useReducer } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import AutoSuggest from 'react-autosuggest';
import TagItem from 'components/widgets/tag/TagItem';
import {
    makeStyles, Paper, TextField, MenuItem, IconButton,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Permissions from 'utils/enum/Permissions';
import { Add as AddIcon } from '@material-ui/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import TagMutation from 'services/graphQL/mutate/crm/TagMutation';
import OpportunityTagQuery from 'services/graphQL/query/crm/OpportunityTagQuery';

const useStyles = makeStyles((theme) => ({
    chip: {
        color: theme.palette.border.gallery,
        fontWeight: 'bold',
        height: 24,
        margin: '2px !important',
    },
    chipIcon: {
        color: theme.palette.border.gallery,
    },
    edit: {
        display: 'none',
    },
    // start AutoSuggest
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 998,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    // end AutoSuggest
}));

const ACTION_TYPE = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_SUGGESTIONS: 'setSuggestions',
    ON_CHANGE_SEARCH_TERM: 'onChangeSearchTerm',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.payload },
            searchTerm: { $set: '' },
        });
    case ACTION_TYPE.ON_CHANGE_SEARCH_TERM:
        return update(state, {
            searchTerm: { $set: action.payload },
        });
    case ACTION_TYPE.SET_SUGGESTIONS:
        return update(state, {
            suggestions: { $set: action.payload },
        });
    default:
        return state;
    }
};

const CustomTags = ({ onDelete, records, recordId }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        searchTerm: '',
        edit: false,
        suggestions: [],
    });

    const keyStore = new KeyStore();
    const crmOpportunityTagWrite = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_TAG_WRITE);

    // Mutation and query
    const [createTag] = useMutation(TagMutation.ADD_OPPORTUNITY_TAG);
    const [createAssignCRMTag] = useMutation(TagMutation.CREATE_ASSIGN_CRM_TAG);
    const [getAssigneeByOpportunityId, { data: suggestionResult }] = useLazyQuery(
        OpportunityTagQuery.GET_OPPORTUNITY_TAG_BY_SEARCH_TERM,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY },
    );

    const onChangeValue = (field, payload) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            field,
            payload,
        });
    };

    const onChangeSearchTerm = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_SEARCH_TERM,
            payload: value,
        });
    };

    const onSuggestionsFetchRequested = useCallback(debounce((record) => {
        getAssigneeByOpportunityId({
            variables: { searchTerm: record.value },
        });
    }, 500, { trailing: true }), []);

    useEffect(() => {
        const result = suggestionResult?.getOpportunityTagBySearchTerm;
        if (!isEmpty(result)) {
            const tagIds = records.map((item) => item.tagId);
            dispatch({
                type: ACTION_TYPE.SET_SUGGESTIONS,
                payload: result.data.filter((item) => !tagIds.includes(item.tagId)),
            });
        }
    }, [suggestionResult, records]);

    const onCreate = async (tagId) => {
        try {
            const response = await createTag({ variables: { tagId, opportunityId: recordId } });

            if (response.data?.addOpportunityTag) {
                onChangeValue('edit', false);
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onCreateAssignCRMTag = async () => {
        try {
            const response = await createAssignCRMTag({ variables: { tagName: state.searchTerm, opportunityId: recordId } });

            if (response.data?.createAssignCRMTag) {
                onChangeValue('edit', false);
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    //* Event Auto suggest (start)
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCreateAssignCRMTag();
        }
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        onCreate(suggestion.tagId);
    };

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const selected = isHighlighted
            || query.toLowerCase() === suggestion.name.toLowerCase();

        return (
            <MenuItem selected={selected} component="div" key={suggestion.name}>
                {suggestion.name}
            </MenuItem>
        );
    };

    const renderInputComponent = (inputProps) => {
        const { ref, ...other } = inputProps;

        return (
            <TextField
                className={classes.textField}
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    disableUnderline: true,
                }}
                inputRef={(input) => input && input.focus()}
                inputProps={{
                    maxLength: 20,
                }}
                {...other}
            />
        );
    };

    const autosuggestProps = {
        renderInputComponent,
        onSuggestionsFetchRequested,
        onSuggestionsClearRequested: () => {},
        onSuggestionSelected,
        getSuggestionValue: (suggestion) => suggestion.name,
        renderSuggestion: (suggestion, results) => <div>{renderSuggestion(suggestion, results)}</div>,
        suggestions: state.suggestions,
    };
    const inputProps = {
        onChange: (event, { newValue }) => onChangeSearchTerm(newValue),
        onKeyDown,
        onBlur: () => onChangeValue('edit', false),
        placeholder: 'Enter tag name',
        value: state.searchTerm,
        style: {
            display: state.edit ? 'inline' : 'none',
            margin: '2px',
        },
    };
    //* Event Auto suggest (End)

    return (
        <div className="d-flex tag-component">
            <div className="tags-container">
                <div className="tags-item">
                    <div>
                        {records.map((tag, index) => <TagItem record={tag} index={index} allowRemove onDelete={onDelete} key={index} />)}
                    </div>
                    <AutoSuggest
                        {...autosuggestProps}
                        inputProps={inputProps}
                        theme={{
                            container: classes.container,
                            suggestionsContainerOpen:
                                        classes.suggestionsContainerOpen,
                            suggestionsList: classes.suggestionsList,
                            suggestion: classes.suggestion,
                        }}
                        renderSuggestionsContainer={(options) => (
                            <Paper {...options.containerProps} square>
                                {options.children}
                            </Paper>
                        )}
                    />
                </div>
            </div>
            {crmOpportunityTagWrite && (
                <div className="button-container">
                    <IconButton
                        color="primary"
                        className={clsx({ [classes.edit]: state.edit })}
                        onClick={() => onChangeValue('edit', true)}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

CustomTags.propTypes = {
    recordId: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    records: PropTypes.array.isRequired,
};

CustomTags.defaultProps = {
    onDelete: false,
};

export default CustomTags;

import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import PlaidMappingsQuery from 'services/graphQL/query/plaid/PlaidMappingsQuery';
import {
    Dialog, DialogContent, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        height: 350,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(1, 1.8),
    },
    boxSearch: {
        marginBottom: theme.spacing(1.8),
    },
}));

const initialState = {
    records: [],
    plaidAccessToken: '',
    institutionAccountId: '',
    institutionAccountName: '',
    institutionAccountLastFour: '',
};

const PlaidBankAccountDialog = ({ onClose, onBankAccountSelected, bankId }) => {
    const classes = useStyles();
    const [state, setState] = useState(initialState);

    const {
        data, loading, error,
    } = useQuery(PlaidMappingsQuery.GET_PLAID_BANK_ACCOUNTS_LIST, {
        skip: isEmpty(bankId),
        variables: { bankId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getPlaidBankAccountsList)) {
            const { getPlaidBankAccountsList } = data;
            setState((previousState) => ({
                ...previousState,
                records: getPlaidBankAccountsList,
            }));
        }
    }, [data, loading, error]);

    const onTableClicked = ({
        institutionAccountId, institutionAccountName, institutionAccountLastFour, plaidAccessToken,
    }) => {
        setState((previousState) => ({
            ...previousState,
            plaidAccessToken,
            institutionAccountId,
            institutionAccountName,
            institutionAccountLastFour,
        }));
    };

    const getColumns = () => [{
        minWidth: 30,
        Header: 'Last Four',
        id: 'institutionAccountLastFour',
        accessor: 'institutionAccountLastFour',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 70,
        Header: 'Account Name',
        id: 'institutionAccountName',
        accessor: 'institutionAccountName',
        className: 'd-flex-justify-center-align-center',
    }];

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            <DialogAppBar
                onClose={onClose}
                title="Bank Accounts"
            />
            <DialogContent className={classes.dialogContent}>
                <Table
                    rowSelected
                    load={loading}
                    totalRecords={state.records.length}
                    data={state.records}
                    columns={getColumns()}
                    getTrProps={(_, rowInfo) => {
                        const record = rowInfo.original;

                        return {
                            onDoubleClick: () => onBankAccountSelected(record),
                            onClick: () => onTableClicked(record),
                        };
                    }}
                />
            </DialogContent>
            <DialogActions
                titlePrimary="Ok"
                variant="contained"
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                disablePrimaryButton={isEmpty(state.institutionAccountId)}
                onClickPrimary={() => onBankAccountSelected({
                    plaidAccessToken: state.plaidAccessToken,
                    institutionAccountId: state.institutionAccountId,
                    institutionAccountName: state.institutionAccountName,
                    institutionAccountLastFour: state.institutionAccountLastFour,
                })}
            />
        </Dialog>
    );
};

PlaidBankAccountDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    bankId: PropTypes.string.isRequired,
    onBankAccountSelected: PropTypes.func.isRequired,
};

export default PlaidBankAccountDialog;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import KeyStore from 'utils/KeyStore';

const ProtectedRoute = ({ children, ...rest }) => (
    <Route
        {...rest}
        render={
            () => (new KeyStore().exists() ? (
                <>
                    <Helmet
                        defer={false}
                    >
                        <title>{rest?.title || 'Automatrix DMS'}</title>
                        <meta name="description" content={rest?.description || 'Automatrix dmspro App'} />
                    </Helmet>
                    {children}
                </>
            ) : <Redirect to={{ pathname: '/login' }} />)
        }
        onEnter
    />
);

ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProtectedRoute;

import { gql } from '@apollo/client';

export default class CommissionQuery {
    static GET_DEAL_COMMISSION = gql`
        query getDealCommissions($dealId: Int!) {
            getDealCommissions(dealId: $dealId) {
                salesManagerId
                salesManagerCommission
                salesManagerOverride
                salesManagerPaid
                salesManager {
                    userId
                    username
                    firstName
                    lastName
                }
                salespersonId1
                commissions1
                commission1Override
                salesPerson1Paid
                salesPerson1 {
                    userId
                    username
                    firstName
                    lastName
                }
                salespersonId2
                commissions2
                commission2Override
                salesPerson2Paid
                salesPerson2 {
                    userId
                    username
                    firstName
                    lastName
                }
                bdcAgentId
                bdcAgentCommission
                bdcAgentOverride
                bdcAgentPaid
                bdcAgent {
                    userId
                    username
                    firstName
                    lastName
                }
                financeManagerId
                financeCommissions
                financeCommissionOverride
                financeManagerPaid
                financeManager {
                    userId
                    username
                    firstName
                    lastName
                }
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import Search from 'components/widgets/Search';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CRMCustomerContainer from 'components/containers/deals/read/buyer/CRMCustomerContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog } from '@material-ui/core';

const styles = (theme) => DealStyles.dialogSearch(theme);

class CRMCustomer extends Component {
    getColumns() {
        return [
            {
                Header: 'Lead ID',
                minWidth: 60,
                accessor: 'leadCode',
                className: 'd-flex-justify-center-align-center',
            },
            {
                Header: 'Customer ID',
                minWidth: 60,
                accessor: 'buyer',
                className: 'd-flex-justify-center-align-center',
                Cell: ({ value }) => <span>{value?.customerCode}</span>,
            },
            {
                Header: 'Customer Name',
                minWidth: 80,
                accessor: 'buyer',
                className: 'd-flex-justify-start-align-center',
                Cell: ({ value }) => <span>{`${value?.firstName ?? ''} ${value?.lastName ?? ''}`}</span>,
            },
            {
                Header: 'Home Phone',
                minWidth: 75,
                accessor: 'buyer',
                className: 'd-flex-justify-center-align-center',
                Cell: ({ value }) => <span>{value?.homePhone}</span>,
            },
            {
                Header: 'Email',
                minWidth: 100,
                accessor: 'buyer',
                className: 'd-flex-justify-center-align-center',
                Cell: ({ value }) => <span>{value?.email}</span>,
            },
        ];
    }

    render() {
        const {
            props: {
                toggleModal, open, classes,
                onSearch, isLoading, loadMore,
                totalCount, records,
                onClickRecord, disablePrimaryButton,
                selectedRecord, onClickPrimary,
                title,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: classes.dialog,
                }}
            >
                <DialogAppBar title={title} onClose={toggleModal} titleVariant="subtitle1" toolbarSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.boxSearch}>
                        <Search autoFocus fullWidth onKeyDown={onSearch} />
                    </div>
                    <Table
                        rowSelected
                        load={isLoading}
                        loadMore={loadMore}
                        totalRecords={totalCount}
                        data={records}
                        columns={this.getColumns()}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onDoubleClick: () => {
                                    onClickPrimary();
                                },
                                onClick: () => {
                                    onClickRecord(record);
                                },
                            };
                        }}
                    />
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={toggleModal}
                    onClickPrimary={onClickPrimary}
                    disablePrimaryButton={!selectedRecord || disablePrimaryButton}
                />
            </Dialog>
        );
    }
}

CRMCustomer.propTypes = {
    onClickPrimary: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    disablePrimaryButton: PropTypes.bool,
    selectedRecord: PropTypes.object,
    title: PropTypes.string,
};

CRMCustomer.defaultProps = {
    onClickPrimary: () => {},
    disablePrimaryButton: false,
    selectedRecord: null,
    title: 'CRM Customer Lookup',
};

export default withStyles(styles)(CRMCustomerContainer(CRMCustomer));

import { gql } from '@apollo/client';

export default class CompanyQuery {
    static PULL_AVAILABLE_WATERMARKS = gql`
        query pullAvailableWatermarks {
            pullAvailableWatermarks {
                companyWatermarkId
                name
                url
            }
        }
    `;
}

import axios from 'axios';
import StringUtils from 'lib/StringUtils';

export default class PDFUtils {
    static convertPDFToBlobURL = (url) => new Promise((resolve, reject) => {
        axios.get(url, { responseType: 'blob' })
            .then((response) => {
                // eslint-disable-next-line no-undef
                const blob = new Blob([response.data], { type: 'application/pdf' });
                resolve(URL.createObjectURL(blob));
            }, (error) => reject(error));
    });

    static sendPDFToPrinter = async (url) => {
        if (StringUtils.isEmpty(url)) return;

        const response = await axios.get(url, { responseType: 'blob' });
        const blob = response.data;

        const frameId = 'printing-iframe';
        let iframe = document.getElementById(frameId);
        if (iframe) iframe.parentNode.removeChild(iframe);

        iframe = document.createElement('iframe');
        iframe.id = frameId;
        iframe.style.display = 'none';
        iframe.src = URL.createObjectURL(blob);
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    };
}

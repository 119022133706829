import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { Col, Form } from 'react-bootstrap';
import { ServiceActions, ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import InputNumber from 'components/widgets/InputNumber';

const ownStyle = makeStyles(() => (
    {
        paddingTop10: {
            paddingTop: '10px',
        },
        milesOutInput: {
            paddingTop: '10px',
            paddingLeft: 0,
        },
    }
));

const ChangeStatusInvoicePopup = (props) => {
    const {
        actionType,
        status,
        onClose,
        onActionConfirm,
        milesIn,
        milesOut,
    } = props;

    const classes = ownStyle();

    const [milesOutToClose, setMilesOut] = useState(milesOut);

    let dialogTitle = '';
    let dialogDescription = '';
    switch (actionType) {
    case ServiceActions.CHANGE_STATUS:
        dialogTitle = 'Change Status';
        dialogDescription = status === ServiceInvoiceStatus.VOID
            ? 'There are amounts associated with this RO. It may affect Payroll and Accounting. Are you sure you want to void this RO?'
            : `Are you sure you want to change the status to ${status}?`;
        break;
    case ServiceActions.PRINT_CONSENT:
        dialogTitle = 'Print Consent';
        dialogDescription = 'Are you sure you want to print the consent?';
        break;
    case ServiceActions.PRINT_INVOICE:
        dialogTitle = 'Print Invoice';
        dialogDescription = 'Are you sure you want to print the invoice?';
        break;
    default:
        break;
    }

    return (
        <ConfirmDialog
            title={dialogTitle}
            description={dialogDescription}
            open
            variant="outlined"
            titlePrimary="Yes"
            titleSecondary="Cancel"
            onClose={onClose}
            onClickSecondary={onClose}
            onClickPrimary={() => onActionConfirm(milesOutToClose)}
            disablePrimaryButton={actionType === ServiceActions.CHANGE_STATUS
                && status === ServiceInvoiceStatus.CLOSED
                && milesOutToClose < milesIn}
        >
            {actionType === ServiceActions.CHANGE_STATUS
                    && status === ServiceInvoiceStatus.CLOSED
                    && milesOut < milesIn
                    && (
                        <>
                            <div>
                                <p className={classes.paddingTop10}>
                                    Miles out is requested to close this RO, and this should be equal to or greater than current miles in:
                                    {' '}
                                    {milesIn}
                                </p>
                            </div>
                            <Grid item xs={4}>
                                <Form.Group as={Col} className={classes.milesOutInput}>
                                    <InputNumber
                                        size="sm"
                                        showCurrency={false}
                                        allowNegative={false}
                                        value={milesOutToClose}
                                        thousandSeparator
                                        placeholder="0"
                                        onChange={(value) => setMilesOut(value)}
                                        alignRight
                                        className={milesOutToClose < milesIn ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Grid>
                        </>
                    ) }
        </ConfirmDialog>
    );
};

ChangeStatusInvoicePopup.propTypes = {
    actionType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onActionConfirm: PropTypes.func.isRequired,
    milesIn: PropTypes.number,
    milesOut: PropTypes.number,
};

ChangeStatusInvoicePopup.defaultProps = {
    milesIn: 0,
    milesOut: 0,
};

export default ChangeStatusInvoicePopup;

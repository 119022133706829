import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Table from 'components/widgets/Table';
import FormTab from 'styles/modules/deals/FormsTab';

// Material UI
import { IconButton, fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const styles = (theme) => FormTab.formPacksTab(theme, fade);

class FormPacksTab extends Component {
    getColumns() {
        const {
            classes, onChangeValueFormPack, onPreview, onRemoveFormOfFormPack,
        } = this.props;

        return [
            {
                Header: '',
                minWidth: 20,
                accessor: 'id',
                className: 'd-flex-justify-center-align-center',
                Cell: ({ original, value }) => {
                    const { defaultChecked } = original;

                    return (
                        <Form.Check
                            inline
                            checked={defaultChecked}
                            className={classes.checkbox}
                            type="checkbox"
                            onChange={({ target }) => {
                                const { checked } = target;

                                onChangeValueFormPack(value, checked);
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Form Name',
                minWidth: 100,
                accessor: 'commonName',
            },
            {
                Header: 'Category',
                minWidth: 50,
                accessor: 'category',
                className: 'd-flex-justify-center-align-center',
            },
            {
                Header: 'Type',
                minWidth: 30,
                accessor: 'formPrintingType',
                className: 'd-flex-justify-center-align-center',
            },
            {
                Header: 'Preview',
                id: 'preview',
                minWidth: 40,
                maxWidth: 90,
                className: clsx('d-flex-justify-center-align-center', classes.preview),
                Cell: ({ original }) => (
                    <IconButton size="small" onClick={() => onPreview(original)}>
                        <FindInPageIcon fontSize="small" className={classes.print} />
                    </IconButton>
                ),
            },
            {
                Header: '',
                minWidth: 38,
                maxWidth: 38,
                height: '100%',
                className: 'd-flex-justify-center-align-center',
                Cell: ({ original }) => {
                    const { id } = original;

                    return (
                        <IconButton size="small" className="show-hidden-item" onClick={() => onRemoveFormOfFormPack(id)}>
                            <RemoveCircleIcon fontSize="small" className={classes.textError} />
                        </IconButton>
                    );
                },
            },
        ];
    }

    render() {
        const { records } = this.props;

        return (
            <Table
                className="without-style"
                resizable={false}
                sortable={false}
                cursor="default"
                getTrProps={(_, rowInfo) => {
                    if (rowInfo) {
                        return {
                            className: 'show-icon-when-hovering',
                        };
                    }

                    return {};
                }}
                data={records}
                columns={this.getColumns()}
            />
        );
    }
}

FormPacksTab.propTypes = {
    records: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onPreview: PropTypes.func.isRequired,
    onChangeValueFormPack: PropTypes.func.isRequired,
    onRemoveFormOfFormPack: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormPacksTab);

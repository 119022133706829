import { gql } from '@apollo/client';

export default class SplitAmountSettings {
    static GET_SPLIT_AMOUNT_SETTINGS = gql`
        query getSplitAmountBetweenLots{
            getSplitAmountBetweenLots{
                lotId
                lotName
                percentage
            }
        }
    `;

    static GET_ACCOUNTING_SPLIT_AMOUNT_SETTINGS = gql`
    query getAccountingSplitAmountBetweenLots{
        getAccountingSplitAmountBetweenLots{
            lotId
            lotName
            percentage
        }
    }
`;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ImageGallery from 'react-image-gallery';

import Loading from 'components/widgets/Loading';
import VehicleImage from 'components/widgets/VehicleImage';
import PhotosGallery from 'components/widgets/inventory/PhotosGallery';
import If from 'components/widgets/conditional/If';

import StringUtils from 'lib/StringUtils';

import GalleryStyle from 'styles/widgets/inventory/GalleryStyle';
import 'react-image-gallery/styles/css/image-gallery.css';

const useStyles = makeStyles(GalleryStyle.gallery());

const Gallery = ({
    images,
    bodyStyle,
    thumbnail,
    alternativeThumbnail,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [showGallery, setShowGallery] = useState(false);

    if (images.length === 0) {
        const image = !StringUtils.isEmpty(thumbnail)
            ? thumbnail : alternativeThumbnail;

        return (
            <VehicleImage
                image={image}
                bodyStyle={bodyStyle}
            />
        );
    }

    return (
        <>
            <If condition={!showGallery}>
                <Loading className={classes.loader} />
            </If>
            <div className={clsx(classes.container, showGallery ? classes.containerVisible : '')}>
                <ImageGallery
                    items={images.map((item) => ({ original: item }))}
                    showThumbnails={false}
                    infinite={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showIndex
                    startIndex={photoIndex}
                    onSlide={(currentIndex) => setPhotoIndex(currentIndex)}
                    onImageLoad={() => setTimeout(() => setShowGallery(true), 500)}
                />
                <div
                    className={`picture-car-read image-contain ${classes.imageContain}`}
                >
                    <div className={`show-image ${classes.iconContainer}`}>
                        <div className="action-container">
                            <IconButton
                                onClick={() => {
                                    setPhotoIndex(photoIndex);
                                    setOpen(true);
                                }}
                            >
                                <ZoomInIcon fontSize="large" className={classes.iconButton} />
                            </IconButton>
                            <div className="picture-bg" />
                        </div>
                    </div>
                </div>
            </div>
            <PhotosGallery
                photos={images}
                photoIndex={photoIndex}
                isOpen={open}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
        </>
    );
};

Gallery.propTypes = {
    thumbnail: PropTypes.string,
    alternativeThumbnail: PropTypes.string,
    bodyStyle: PropTypes.string,
    images: PropTypes.array,
};

Gallery.defaultProps = {
    images: [],
    bodyStyle: '',
    thumbnail: '',
    alternativeThumbnail: '',
};

export default Gallery;

import HttpClientModule from 'services/httpModules/HttpClientModule';
import MapTagsData from 'services/mapData/MapTagsData';

export const URL = {
    TAGS_BY_MODULE: '/tags/~module~/all',
    UPDATE: '/tag/~id~',
};

export default class HttpTagsModule {
    constructor() {
        this.http = new HttpClientModule();
    }

    async getTagsByModule(module = '') {
        let url = URL.TAGS_BY_MODULE;

        url = url.replace('~module~', module);

        const response = await this.http.GET(url);

        return response;
    }

    async updateTag(tagId, data = {}) {
        let url = URL.UPDATE;
        const body = MapTagsData.mapEditingTag(data);

        url = url.replace('~id~', tagId);

        const response = await this.http.PUT(url, body);

        return response;
    }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

// Components
import If from 'components/widgets/conditional/If';
import Message from 'components/widgets/sms/Message';
import MessageAdd from 'components/widgets/sms/MessageAdd';
import SelectMessageTemplate from 'components/modules/crm/widgets/SelectMessageTemplate';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

// Http
import GraphQLClient from 'services/apollo/GraphQLClient';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import MessageCenterQuery from 'services/graphQL/query/crm/MessageCenterQuery';

// Utils
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { isEmpty, isFinite } from 'lodash';
import Permission from 'utils/enum/Permissions';
import { DIRECTION, STATUS } from 'utils/enum/MessageCenter';
import HttpCRMModule from 'services/httpModules/HttpCRMModule';
import MessageCenterMutate from 'services/graphQL/mutate/crm/MessageCenterMutate';
import MessageCenterSubscription from 'services/graphQL/subscription/crm/MessageCenterSubscription';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

const styles = (theme) => ({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    toolbar: {
        padding: theme.spacing(0, 2),
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});
const keyStore = new KeyStore();

class MessageCenterDetail extends Component {
    constructor(props) {
        super(props);

        this.graphqlClient = new GraphQLClient();
        this.messageCenterDetailSubscription = null;
        this.httpCRMModule = new HttpCRMModule();
        this.CRM_MESSAGE_CENTER_WRITE = keyStore.hasPermission(Permission.CRM_MESSAGE_CENTER_WRITE);

        this.state = {
            contentMessage: {
                messages: [],
                limit: 15,
                totalCount: 0,
            },
            load: false,
            textMessage: '',
            templateSelected: null,
        };
        this.initBind = this.initBind();
    }

    componentDidMount() {
        const { smsChatId } = this.props;

        this.unsubscribeMessageCenterDetail();
        this.getServicesData(smsChatId);
        this.subscribeToMessageCenterDetail(smsChatId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { props } = this;

        if (props.smsChatId !== nextProps.smsChatId) {
            this.unsubscribeMessageCenterDetail();
            this.getServicesData(nextProps.smsChatId, true);
            this.setState({
                textMessage: '',
                templateSelected: null,
            });
            this.subscribeToMessageCenterDetail(nextProps.smsChatId);
        }
    }

    componentWillUnmount() {
        this.unsubscribeMessageCenterDetail();
    }

    onChangeTemplate(value) {
        const { customer: { opportunity } } = this.props;
        this.setState({
            templateSelected: value,
        });
        this.httpCRMModule.getSMSTemplatePreview(value, opportunity.leadCode).then(
            (result) => {
                if (result?.StatusCode === 200) {
                    // * Some templates have more than 500 characters so the length is being cut off.
                    this.setState({
                        textMessage: result?.Data?.slice(0, 500),
                    });
                } else {
                    ModalUtils.errorMessage(result?.Messages, result?.Message);
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onSendMessage(record) {
        const { smsChatId } = this.props;
        const input = {
            smsChatId,
            text: record.text,
            lotName: keyStore.getSelectedLot()?.lotName,
        };

        this.graphqlClient
            .mutate(MessageCenterMutate.SEND_MESSAGE_FROM_MESSAGE_CENTER, { input })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    getServicesData(smsChatId = null, onReload = false) {
        const { state: { contentMessage: { messages, limit }, contentMessage } } = this;

        if (onReload) {
            this.setState({
                contentMessage: Object.assign(contentMessage, {
                    messages: [],
                    limit,
                    offset: 0,
                    totalCount: 0,
                }),
                load: true,
            });
        } else {
            this.setState({
                load: true,
            });
        }

        this.graphqlClient
            .query(MessageCenterQuery.GET_SMS_BY_CHAT_ID, { smsChatId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getSMSByChatId) {
                    const recordsService = onReload ? [] : messages;
                    recordsService.unshift(...data.getSMSByChatId.data);

                    this.setState({
                        contentMessage: Object.assign(contentMessage, {
                            messages: recordsService,
                            totalCount: data.getSMSByChatId.totalCount,
                        }),
                    });
                }
            })
            .finally(() => {
                this.markMailAsRead(smsChatId);
                this.setState({ load: false });
            });
    }

    markMailAsRead(smsChatId) {
        const { contentMessage } = this.state;
        const message = contentMessage.messages[contentMessage.messages.length - 1] ?? {};

        if (message.status === STATUS.RECEIVED && message.direction === DIRECTION.INCOMING) {
            this.graphqlClient
                .mutate(MessageCenterMutate.MARK_MESSAGE_AS_READ, { smsChatId })
                .then((response) => {
                    const { graphQLErrors } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                    }
                });
        }
    }

    subscribeToMessageCenterDetail(smsChatId) {
        this.graphqlClient.subscribe(this.responseSubscription, MessageCenterSubscription.MESSAGE_CENTER_UPDATED, { smsChatId })
            .then((response) => {
                this.messageCenterDetailSubscription = response;
            });
    }

    responseSubscription(record) {
        const result = record.data?.messageCenterUpdated;

        if (!isEmpty(result?.sms)) {
            const { state } = this;

            if (result.type === SubscriptionActionType.ADDED) {
                this.setState((prevState) => ({
                    contentMessage: update(prevState.contentMessage, {
                        messages: { $push: [result.sms] },
                        totalCount: { $set: state.contentMessage.totalCount + 1 },
                    }),
                }), () => {
                    const { smsChatId } = this.props;
                    this.markMailAsRead(smsChatId);
                });
            }

            if (result.type === SubscriptionActionType.UPDATED) {
                const index = state.contentMessage.messages.findIndex((item) => item.smsId === result.sms.smsId);

                if (index >= 0) {
                    this.setState((prevState) => ({
                        contentMessage: update(prevState.contentMessage, {
                            messages: { [index]: { status: { $set: result.sms.status }, errorCode: { $set: result.sms.errorCode } } },
                        }),
                    }));
                }
            }
        }
    }

    unsubscribeMessageCenterDetail() {
        if (this.messageCenterDetailSubscription) {
            this.messageCenterDetailSubscription.unsubscribe();
        }
    }

    loadMore() {
        const { smsChatId } = this.props;
        this.getServicesData(smsChatId);
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.markMailAsRead = this.markMailAsRead.bind(this);
        this.onChangeTemplate = this.onChangeTemplate.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeToMessageCenterDetail = this.subscribeToMessageCenterDetail.bind(this);
    }

    render() {
        const { classes, customer } = this.props;
        const {
            contentMessage: { messages, totalCount }, load, textMessage,
            templateSelected,
        } = this.state;
        const hasPermissionToSendMessage = this.CRM_MESSAGE_CENTER_WRITE
            && ((customer.customerId != null
            && customer.allowTextCellPhone) || customer.customerId == null);

        return (
            <div className={classes.container}>
                <div className={classes.toolbar}>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                    >
                        Text messages
                    </Typography>
                    {(isFinite(customer.opportunity?.leadCode) && hasPermissionToSendMessage) && (
                        <SelectMessageTemplate
                            value={templateSelected}
                            onChange={this.onChangeTemplate}
                        />
                    )}
                </div>
                <Divider />
                <InfiniteScroll
                    lengthRecord={messages.length}
                    totalRecord={totalCount}
                    loadMore={this.loadMore}
                    scroll="top"
                    autoScrollBottom
                    load={load}
                >
                    <Message
                        records={messages}
                    />
                </InfiniteScroll>
                <Divider />
                <If condition={hasPermissionToSendMessage}>
                    <MessageAdd onSubmit={this.onSendMessage} textMessage={textMessage} />
                </If>
            </div>
        );
    }
}

MessageCenterDetail.propTypes = {
    smsChatId: PropTypes.string.isRequired,
    customer: PropTypes.object.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(MessageCenterDetail);

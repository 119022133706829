import React from 'react';
import {
    makeStyles, Button, useTheme,
    useMediaQuery, Tooltip, Typography, ButtonGroup,
} from '@material-ui/core';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { AddIcon, ExpandLessIcon, ExpandMoreIcon } from 'components/icons';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import InputSearch from 'components/widgets/InputSearch';
import { ServiceSubModules, INVOICE_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import useServiceList from 'components/modules/service/serviceInvoice/hooks/useServiceList';
import ServiceInvoiceList from 'components/modules/service/serviceInvoice/list/ServiceInvoiceList';
import Filter from 'components/widgets/Filter';
import useAdvisorList from 'components/modules/service/serviceInvoice/hooks/useAdvisorList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSubscription } from '@apollo/client';
import ServiceSubscription from 'services/graphQL/subscription/service/ServiceSubscription';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import { v1 as uuid } from 'uuid';
import ServiceHistoryRepairs from 'components/modules/service/serviceInvoice/list/serviceHistory/ServiceHistoryRepairs';
import ServiceNotesPopup from 'components/modules/service/serviceInvoice/list/serviceNotes/ServiceNotesPopup';
import { Form } from 'react-bootstrap';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    listContainer: {
        margin: '15px',
    },
    filterLabel: {
        fontSize: '15px',
        fontWeight: 500,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        color: theme.palette.text.gray,
    },
    filterWidth: {
        width: '170px',
        border: `solid 1px ${theme.palette.border.ghost}`,
        padding: '4px 5px',
        borderRadius: '0.2rem',
        paddingRight: '10px',
        '& div': {
            fontSize: '14px',
            fontWeight: 400,
        },
    },
    iconFilterCls: {
        position: 'relative',
        right: '25px',
        top: '6px',
        zIndex: '-1',
    },
    groupBtnCls: {
        '& svg': {
            fontSize: 22,
        },
    },
    filterCls: {
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
            flexDirection: 'column',
        },
    },
    containerFilterSearchCustom: {
        [theme.breakpoints.down('md')]: {
            flexWrap: 'nowrap',
            flexGrow: '1',
        },
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
}));

const keyStore = new KeyStore();

const ServiceInvoiceMain = () => {
    const SERVICE_OPEN = keyStore.hasPermission(Permission.SERVICE_OPEN);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        state,
        loading,
        loadMore,
        onSearch,
        onFilterChange,
        setDataFromSuscription,
        expandAndCollapseAllJobs,
        expandOrCollapse,
        availableLots,
        onHistory,
        onNotes,
        postToAccountingAutomatic,
    } = useServiceList();

    const { advisors, loading: loadingAdvisors } = useAdvisorList();

    const {
        params: {
            status, search: searchParam, lotName, advisor, ignoreFilterWhenSearch,
        },
        table,
        invoiceNumberHistory,
        invoiceNumberNote,
    } = state;

    const {
        main,
        containerFilterSearch,
        search,
        searchFull,
        buttonSpacing,
        justifyContentCenter,
        listContainer,
        filterWidth,
        iconFilterCls,
        groupBtnCls,
        filterCls,
        containerFilterSearchCustom,
    } = classes;

    useSubscription(ServiceSubscription.SERVICE_INVOICE_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { action, data: subsData, id } = subscriptionData?.data?.serviceInvoiceChanged;

            if (action === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                records.unshift({ ...currentData, identifier: uuid() });

                setDataFromSuscription({
                    records,
                    totalCount: totalCount + 1,
                });
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.invoiceNumber) === Number(id));
                const currentRecord = currentRecords.find((item) => Number(item.invoiceNumber) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, { ...currentData, identifier: uuid(), open: currentRecord.open });
                    setDataFromSuscription({
                        records: currentRecords,
                        totalCount: table.totalCount,
                    });
                }
            }
        },
    });

    return (
        <>
            <div className={main}>
                <Header>
                    <div className={clsx(containerFilterSearch, containerFilterSearchCustom)}>
                        <InputSearch
                            customClasses={clsx(search, isMobile ? searchFull : '')}
                            initialSearch={searchParam || ''}
                            onSearch={onSearch}
                        />
                        <div className={clsx('d-flex-space-between', filterCls)}>
                            <div className="d-flex-align-baseline-space-between">
                                <Typography
                                    variant="h5"
                                    className={classes.filterLabel}
                                >
                                    Lot:
                                </Typography>
                                <Filter
                                    showTooltip
                                    useInternalSearch
                                    emptyRecord="All"
                                    maxWidthLabel={150}
                                    className={filterWidth}
                                    records={availableLots}
                                    selectedValues={lotName || []}
                                    applyFilter={(record) => onFilterChange('lotName', record)}
                                    onClearFilter={() => onFilterChange('lotName', [])}
                                />
                                <ArrowDropDownIcon className={iconFilterCls} />
                            </div>
                            <div className="d-flex-align-baseline-space-between">
                                <Typography
                                    variant="h5"
                                    className={classes.filterLabel}
                                >
                                    Status:
                                </Typography>
                                <Filter
                                    showTooltip
                                    useInternalSearch
                                    emptyRecord="All"
                                    maxWidthLabel={150}
                                    className={filterWidth}
                                    records={INVOICE_STATUS_LIST}
                                    selectedValues={status}
                                    applyFilter={(record) => onFilterChange('status', record)}
                                    onClearFilter={() => onFilterChange('status', [])}
                                />
                                <ArrowDropDownIcon className={iconFilterCls} />
                            </div>
                            <div className="d-flex-align-baseline-space-between">
                                <Typography
                                    variant="h5"
                                    className={classes.filterLabel}
                                >
                                    Advisor:
                                </Typography>
                                <Filter
                                    showTooltip
                                    useInternalSearch
                                    emptyRecord="All"
                                    maxWidthLabel={150}
                                    className={filterWidth}
                                    records={advisors}
                                    selectedValues={advisor}
                                    loading={loadingAdvisors}
                                    applyFilter={(record) => onFilterChange('advisor', record)}
                                    onClearFilter={() => onFilterChange('advisor', [])}
                                />
                                <ArrowDropDownIcon className={iconFilterCls} />
                            </div>
                            <div className="d-flex-align-baseline-space-between">
                                <Form.Group controlId="ignoreFiltersServiceId" className={classes.alignContent}>
                                    <Form.Check
                                        checked={ignoreFilterWhenSearch}
                                        type="checkbox"
                                        label="Ignore Filters"
                                        className={classes.labelFont}
                                        onChange={(e) => onFilterChange('ignoreFilterWhenSearch', e.target.checked)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(buttonSpacing, justifyContentCenter)}>
                        {SERVICE_OPEN
                && (
                    <>
                        <Tooltip title="New Customer Repair Order" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/customer/create`)}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Customer RO
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="New Internal Repair Order" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/internal/create`)}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Internal RO
                                </Button>
                            </span>
                        </Tooltip>
                        <ButtonGroup>
                            <Button
                                variant="outlined"
                                disabled={loading}
                                size="small"
                                className={groupBtnCls}
                                onClick={() => expandAndCollapseAllJobs(true)}
                            >
                                <ExpandMoreIcon />
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={loading}
                                size="small"
                                className={groupBtnCls}
                                onClick={() => expandAndCollapseAllJobs(false)}
                            >
                                <ExpandLessIcon />
                            </Button>
                        </ButtonGroup>
                    </>
                )}
                    </div>
                </Header>
                <div className={listContainer}>
                    <ServiceInvoiceList
                        loadMore={loadMore}
                        table={table}
                        loading={loading}
                        expandOrCollapse={expandOrCollapse}
                        onHistory={onHistory}
                        onNotes={onNotes}
                        postToAccountingAutomatic={postToAccountingAutomatic}
                    />
                </div>
            </div>
            {invoiceNumberHistory > 0 && (
                <ServiceHistoryRepairs
                    onClose={() => onHistory(0)}
                    invoiceNumber={invoiceNumberHistory}
                />
            )}
            {invoiceNumberNote > 0 && (
                <ServiceNotesPopup
                    onClose={() => onNotes(0)}
                    invoiceNumber={invoiceNumberNote}
                />
            )}
        </>
    );
};

export default ServiceInvoiceMain;

/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';

const PayrollDefaultSettingsSchema = yup.object().shape({
    payPeriod: yup.string().required(),
    payPeriodStartDate: yup.date().required(),
    payPeriodStarts: yup.string().required(),
    defaultBank: yup.number().moreThan(0).required(),
    overtimeRate: yup.number().min(1).max(2).required(),
    socialSecurityLimit: yup.number().moreThan(0).required(),
    weeklyRegularHours: yup.number().min(15).max(50).required(),
    minimumWage: yup.number().min(0.25).max(20).required(),
});

export default PayrollDefaultSettingsSchema;

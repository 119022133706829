import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import {
    Typography,
    Button,
    Tooltip,
    Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import CRMUtils from 'utils/CRMUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import Grid from '@material-ui/core/Grid';
import { Row, Form } from 'react-bootstrap';
import { modules } from 'utils/enum/modules';
import { useMutation } from '@apollo/client';
import PhoneNumberUtil from 'lib/PhoneUtils';
import MessageUtils from 'utils/MessageUtils';
import Permissions from 'utils/permission/CRM';
import EmailIcon from '@material-ui/icons/Email';
import Dropdown from 'components/widgets/dropdown';
import If from 'components/widgets/conditional/If';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import LeadStyles from 'styles/modules/crm/LeadStyles';
import VehicleImage from 'components/widgets/VehicleImage';
import LeadHelper from 'components/modules/lead/LeadHelper';
import { ReactComponent as IconUser } from 'assets/user.svg';
import { OpportunityStatus, funnelStages, ProcessStage } from 'components/modules/crm/enum/Opportunity';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import TagItem from 'components/widgets/tag/TagItem';
import useFunnelStage from 'components/hook/crm/useFunnelStage';
import useIntervalExecutor from 'components/hook/core/useIntervalExecutor';

const ACTION = {
    TRANSFER_TO_LOT: 'transferToLot',
    CHANGE_PROCESS_STAGE: 'changeProcessStage',
};
const NEW_EMAIL_LEAD = 'New Email Lead';

const keyStore = new KeyStore();
const useStyle = makeStyles((theme) => LeadStyles.leadListItem(theme));
const OpportunityList = ({
    record, openDialogTransfer, openDialogQuickNote, stages,
    isFloorLog,
}) => {
    const classes = useStyle();
    const { updateFunnelStage } = useFunnelStage();
    const { coProspect, status, automatedResponseType } = record;
    const stockNumber = record?.stockNumber || null;
    const createdOn = DateUtils.format(record.createdOn, DateFormat.LONG_DATE_TIME_AM_PM);
    const modifiedOnRelativeDate = DateUtils.fromNow(record.modifiedOn);
    const vehicleLine1 = `${record?.vehicleYear ?? ''} - ${record?.vehicleMake ?? ''}`;
    const vehicleLine2 = `${record?.vehicleModel ?? ''} - ${record?.vehicleTrim ?? ''}`;
    const prospectName = `${record.prospect.firstName} ${record.prospect.lastName ?? ''}`;
    const isValidCoProspect = coProspect?.customerCode > 0;
    const coProspectName = isValidCoProspect ? `${coProspect.firstName} ${coProspect.lastName ?? ''}` : null;
    const closeDate = DateUtils.format(record.closedDate, DateFormat.LONG_DATE_TIME_AM_PM) || '--';
    const type = LeadHelper.getLeadType(record.leadType);
    const typeIcon = LeadHelper.getLeadTypeIcon(type);
    const tags = record?.tagsData ? JSON.parse(record.tagsData) : record.tags;
    const [isUpdatingStage, setIsUpdatingStage] = useState(false);
    const [timeOnLot, setTimeOnLot] = useState(null);
    const {
        showed,
        showedDate,
        prospectLeftDate,
        demo,
        writeUp,
        finance,
    } = record;

    const [setProcessStage] = useMutation(OpportunityMutation.SET_PROCESS_STAGE);
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_WRITE);
    const CRM_OPPORTUNITY_TRANSFER_TO_LOT = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_TRANSFER_TO_LOT);
    const processStages = stages.filter((item) => item.value !== NEW_EMAIL_LEAD)
        .map((item) => ({
            ...item,
            disabled: record.processStage === item.value,
            action: ACTION.CHANGE_PROCESS_STAGE,
        }));
    const onChangeProcessStage = async (processStage) => {
        try {
            const input = {
                opportunityId: record.crmId,
                processStage: processStage.value,
            };

            const response = await setProcessStage({ variables: input });

            if (!response.data?.setProcessStage) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'opportunity'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const handleClick = (data) => {
        switch (data.action) {
        case ACTION.CHANGE_PROCESS_STAGE:
            onChangeProcessStage(data);
            break;
        case ACTION.TRANSFER_TO_LOT:
            openDialogTransfer(record);
            break;
        default:
        }
    };

    const getActions = () => {
        const result = [{
            label: 'Process stage',
            items: processStages,
            disabled: [OpportunityStatus.LOST.toUpperCase(), OpportunityStatus.SOLD.toUpperCase()].includes(record.status?.toUpperCase()),
        }];

        if (CRM_OPPORTUNITY_TRANSFER_TO_LOT) {
            result.push({
                label: 'Transfer to Lot',
                value: 'Transfer to Lot',
                action: ACTION.TRANSFER_TO_LOT,
            });
        }

        return result;
    };

    const onChangeFunnelStage = async (name, value) => {
        if (isUpdatingStage) return;
        const isSettingCustomerLeft = name === 'prospectLeftDate';

        setIsUpdatingStage(true);
        if (isSettingCustomerLeft) await updateFunnelStage(record.crmId, funnelStages.SHOWED, value, true);
        if (!isSettingCustomerLeft) await updateFunnelStage(record.crmId, name, value);
        setIsUpdatingStage(false);
    };

    useIntervalExecutor(() => CRMUtils.calculateTimeOnLot(prospectLeftDate, showedDate), 60, setTimeOnLot);
    return (
        <RouterLink
            to={{
                pathname: `/${modules.OPPORTUNITIES}/${record.crmId}`,
                state: {
                    isFloorLog,
                },
            }}
            className={classes.link}
        >
            <Grid
                container
                className={clsx('show-icon-when-hovering',
                    classes.container,
                    classes.statusBadge,
                    status.toLowerCase(),
                    { [classes.newEmailLead]: (record.processStage === NEW_EMAIL_LEAD) })}
            >
                <Grid item xs={3}>
                    <div className={classes.column}>
                        <div className={classes.imageContainer}>
                            <div className={`${classes.column} ${classes.vehicleImageWrapper}`}>
                                <VehicleImage
                                    image={record.vehicle?.thumbnail}
                                    bodyStyle={record.vehicle?.style}
                                />
                            </div>
                            <div className={classes.stockNumberWrapper}>
                                <Typography variant="body1" className={classes.stockNumberLabel}>
                                    Opp:
                                </Typography>
                                <span className={classes.leadCodeValue}>{record.leadCode}</span>
                            </div>
                        </div>
                        <div>
                            <div className={classes.prospect}>
                                <div className="d-flex-center">
                                    <Typography variant="body1" className={classes.prospectName}>{prospectName}</Typography>
                                    <Typography variant="body1" className={classes.cellPhone}>
                                        {PhoneNumberUtil.formatPhone(record.prospect?.cellPhone)}
                                    </Typography>
                                </div>
                                <div className={classes.coProspect}>
                                    <Typography variant="body1" className={classes.coProspectName}>{coProspectName}</Typography>
                                    {isValidCoProspect && (<IconUser className={classes.coProspectIcon} width="14px" height="15px" />)}
                                </div>
                            </div>
                            <div className={classes.vehicleMeta}>
                                <Typography variant="body1" className={clsx(classes.vehicleTextColor)}>{vehicleLine1}</Typography>
                                <Typography variant="body1" className={clsx(classes.bold, classes.stockNumberValue)}>{stockNumber}</Typography>
                            </div>
                            <Typography variant="body1" className={classes.vehicleTextColor}>{vehicleLine2}</Typography>
                            {automatedResponseType && (
                                <Typography
                                    variant="body1"
                                    className={clsx(classes.automatedResponse, automatedResponseType === ProcessStage.AUTOMATED_FOLLOW_UP
                                        ? classes.automatedResponseTemplates
                                        : classes.automatedResponseAI)}
                                >
                                    {automatedResponseType}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={3}>
                    <div className={classes.table}>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Lead Type:</Typography>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.leadType, typeIcon.name)}>
                                {typeIcon.icon}
                                <span>{type}</span>
                            </Typography>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Opened On:</Typography>
                            <Typography variant="body1" className={classes.tableCell}>{createdOn}</Typography>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Last Update:</Typography>
                            <div className="d-flex-center">
                                <Typography variant="body1">{modifiedOnRelativeDate}</Typography>
                                <Typography variant="body1" className={classes.lastAction}>{record.lastAction}</Typography>
                            </div>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Close Date:</Typography>
                            <Typography variant="body1" className={classes.tableCell}>{closeDate}</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.overflowHidden}>
                    <div className={classes.table}>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Process Stage:</Typography>
                            <Typography
                                variant="h6"
                                className={clsx(classes.tableCell, classes.processStage, { new: (record.processStage === 'New Email Lead') })}
                            >
                                {record.processStage}
                            </Typography>
                        </div>
                        <If condition={CRM_OPPORTUNITY_WRITE}>
                            <div className={clsx(classes.tableRow, classes.stages)}>
                                <Form.Group as={Row}>
                                    <Form.Label
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        className={classes.dialogLabels}
                                    >
                                        Showed
                                    </Form.Label>
                                    <Checkbox
                                        value="selected"
                                        name="showed"
                                        checked={showed}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => onChangeFunnelStage(e.target.name, e.target.checked)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        className={classes.dialogLabels}
                                    >
                                        Demo
                                    </Form.Label>
                                    <Checkbox
                                        value="selected"
                                        name="demo"
                                        checked={demo}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => onChangeFunnelStage(e.target.name, e.target.checked)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        className={classes.dialogLabels}
                                    >
                                        WriteUp
                                    </Form.Label>
                                    <Checkbox
                                        value="selected"
                                        name="writeUp"
                                        checked={writeUp}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => onChangeFunnelStage(e.target.name, e.target.checked)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        className={classes.dialogLabels}
                                    >
                                        Finance
                                    </Form.Label>
                                    <Checkbox
                                        value="selected"
                                        name="finance"
                                        checked={finance}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => onChangeFunnelStage(e.target.name, e.target.checked)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Tooltip placement="top" title="Customer Left">
                                        <Form.Label
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            className={classes.dialogLabels}
                                        >
                                            Left
                                        </Form.Label>
                                    </Tooltip>
                                    <Checkbox
                                        value="selected"
                                        name="prospectLeftDate"
                                        checked={showed && !StringUtils.isEmpty(prospectLeftDate)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onChange={(e) => (showed ? onChangeFunnelStage(e.target.name, e.target.checked) : null)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </If>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Time on lot:</Typography>
                            {showedDate && (
                                <Typography
                                    variant="body1"
                                    className={clsx(classes.tableCell, classes.lastResultText)}
                                >
                                    {timeOnLot}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.label)}>Quick Note:</Typography>
                            <Tooltip placement="bottom" title={record.notes || ''}>
                                <Typography variant="body1" className={clsx(classes.tableCell, classes.quickNote)}>{record.notes}</Typography>
                            </Tooltip>
                            <Button
                                color="primary"
                                size="small"
                                className={clsx('show-hidden-item', classes.editQuickNote)}
                                startIcon={<BorderColorOutlinedIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openDialogQuickNote(record);
                                }}
                            />
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label)}>Lead Source:</Typography>
                            <Typography variant="body1" className={clsx(classes.tableCell, classes.lastResultText)}>{record.leadSource}</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} className="d-flex-space-between">
                    <div className={classes.table}>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label, classes.alignRight)}>Record Manager:</Typography>
                            <Typography variant="body1" className={classes.tableCell}>{record.recordManager}</Typography>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label, classes.alignRight)}>Sales Rep:</Typography>
                            <Typography variant="h6" className={classes.tableCell}>{record.appointmentSalesman}</Typography>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label, classes.alignRight)}>Lot:</Typography>
                            <Typography variant="h6" className={classes.tableCell}><span className={classes.lotTag}>{record.lotName}</span></Typography>
                        </div>
                        <div className={classes.tableRow}>
                            <Typography variant="h6" className={clsx(classes.tableCell, classes.label, classes.alignRight)}>Tag:</Typography>
                            {(tags || []).map((tag, index) => <TagItem record={tag} index={index} key={index} />)}
                        </div>
                    </div>
                    {typeof record.isReadNotification === 'boolean' && !record.isReadNotification && (
                        <div>
                            <EmailIcon className={classes.notificationIcon} />
                        </div>
                    )}
                    <If condition={CRM_OPPORTUNITY_WRITE}>
                        <div className={classes.actions}>
                            <Dropdown
                                pullRight
                                actions={getActions()}
                                onSelect={handleClick}
                            />
                        </div>
                    </If>
                </Grid>
            </Grid>
        </RouterLink>
    );
};

OpportunityList.propTypes = {
    stages: PropTypes.array,
    isFloorLog: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    openDialogTransfer: PropTypes.func.isRequired,
    openDialogQuickNote: PropTypes.func.isRequired,
};

OpportunityList.defaultProps = {
    stages: [],
};

export default OpportunityList;

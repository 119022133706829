import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

// Components and Others
import PROVIDER from 'utils/enum/MenuEnum';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import MenuService from 'services/modules/MenuService';
import VendorService from 'services/modules/VendorService';
import ProductService from 'services/modules/ProductService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import ImagesUtils from 'utils/ImagesUtils';

const initRecordState = {
    provider: PROVIDER.NON_MENU,
    vendorId: null,
    productName: '',
    productPackage: '',
    productType: '',
    productActive: true,
    isLine1: false,
    menuProductDetailId: null,
    notRegulated: false,
    isTaxable: false,
    description: '',
    bulletPoints: '',
    contract: '',
    productCode: '',
    providerProductId: 0,
    terms: [],
    term: 3,
    cost: 0,
    price: 0,
};

const ProductSettingsDialogContainer = (WrappedComponent) => class extends Component {
  static propTypes = {
      isEditing: PropTypes.bool,
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      onSave: PropTypes.func.isRequired,
      productId: PropTypes.number,
  };

  static defaultProps = {
      isEditing: false,
      productId: null,
  }

  constructor(props) {
      super(props);
      this.graphqlClient = new GraphQLClient();
      this.vendorService = new VendorService();
      this.productService = new ProductService();
      this.menuService = new MenuService();

      this.initBind();
  }

  /* eslint-disable react/state-in-constructor */
  state = {
      record: cloneDeep(initRecordState),
      vendorList: [],
      externalProducts: [],
      isLoading: false,
  };

  componentDidMount() {
      this.getProductVendorList();
  }

  async onUploadContract(files, vendorName) {
      if (!vendorName) return;
      const contract = files[0];

      try {
          const errorManager = ImagesUtils.MAX_SIZES_WITH_ERROR.PDF;
          if (contract.size > errorManager.SIZE) {
              ModalUtils.errorMessage(null, errorManager.ERROR);
              return;
          }

          this.productService.uploadContract(vendorName, contract)
              .then((response) => {
                  const { data, graphQLErrors } = response;

                  if (graphQLErrors) {
                      ModalUtils.errorMessage(graphQLErrors);
                      return;
                  }

                  const url = data?.uploadContract;
                  if (url) {
                      this.onChangeValue('contract', url);
                  }
              });
      } catch (error) {
          ModalUtils.errorMessage(null, error);
      }
  }

  onChangeValue(field, value) {
      if (field === 'resetValues') {
          const { record } = this.state;
          this.setState({ record: { ...cloneDeep(initRecordState), provider: value, productId: record.productId } });
          return;
      }

      this.setState(({ record }) => {
          const newRecord = { ...record };
          if (field === 'providerProductId') {
              newRecord[field] = value.productId;
              newRecord.productCode = value.productCode;
          } else {
              newRecord[field] = value;
          }

          if (field === 'vendorId' && record.provider === PROVIDER.FI_EXPRESS) {
              this.vendorService.getVendorDetail({ vendorId: value }).then((response) => {
                  const { data: { vendor } } = response;
                  const providerId = vendor?.menuVendordetail?.providerCompanyId;
                  if (providerId) this.getExternalProducts(providerId);
              });
          }

          if (field === 'term') {
              newRecord.cost = record.terms?.find((l) => l.term === value)?.cost || 0;
              newRecord.price = record.terms?.find((l) => l.term === value)?.price || 0;
          }

          if (field === 'cost') {
              const { term, terms } = newRecord;
              const current = terms.find((l) => l.term === term);
              if (!current) newRecord.terms = [...terms, { term, cost: value, price: 0 }];
              if (current) current.cost = value;
          }

          if (field === 'price') {
              const { term, terms } = newRecord;
              const current = terms.find((l) => l.term === term);
              if (!current) newRecord.terms = [...terms, { term, price: value, cost: 0 }];
              if (current) current.price = value;
          }

          return { record: newRecord };
      });
  }

  getProductDetail() {
      const { props: { productId } } = this;

      this.setState({ isLoading: true });
      this.productService.getProductDetail({ productId })
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data && data.product) {
                  const { product } = data;

                  const vendorData = { ...(product.menuVendordetail || {}) };
                  let details = { ...(product.menuProductDetail || {}) };
                  delete product.menuProductDetail;
                  delete product.menuVendordetail;
                  if (Object.keys(details).length > 0) {
                      details = {
                          menuProductDetailId: details.menuProductDetailId,
                          providerProductId: details.providerProductId || 0,
                          productCode: details.providerProductCode || '',
                          description: details.description || '',
                          bulletPoints: details.bulletPoints || '',
                          isTaxable: details.isTaxable || false,
                          notRegulated: details.notRegulated || false,
                          contract: details.pdfContract || '',
                          terms: details.terms || [],
                      };

                      if (vendorData?.providerCompanyId) this.getExternalProducts(vendorData.providerCompanyId);
                  } else {
                      details = {
                          menuProductDetailId: null,
                          providerProductId: 0,
                          productCode: '',
                          description: '',
                          bulletPoints: '',
                          isTaxable: false,
                          notRegulated: false,
                          contract: '',
                          terms: [],
                      };
                  }

                  this.setState({ record: { ...product, ...details }, isLoading: false });
              }
          });
  }

  getExternalProducts(providerId) {
      this.menuService.getExternalProducts(providerId)
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data?.pullExternalProducts) {
                  this.setState({ externalProducts: data.pullExternalProducts });
              }
          });
  }

  getProductVendorList() {
      this.setState({ isLoading: true });
      this.productService.getProductVendorList()
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data && data.getVendorList) {
                  const { props: { isEditing } } = this;
                  const { getVendorList } = data;
                  this.setState({ vendorList: getVendorList, isLoading: isEditing });
                  if (isEditing) this.getProductDetail();
              }
          });
  }

  initBind() {
      this.onChangeValue = this.onChangeValue.bind(this);
      this.onUploadContract = this.onUploadContract.bind(this);
      this.getProductDetail = this.getProductDetail.bind(this);
      this.getProductVendorList = this.getProductVendorList.bind(this);
  }

  render() {
      const { props, state } = this;

      return (
          <WrappedComponent
              {...props}
              {...state}
              onSave={props.onSave}
              onClose={props.onClose}
              onChangeValue={this.onChangeValue}
              onUploadContract={this.onUploadContract}
              onCloseDialogConfirm={this.onCloseDialogConfirm}
          />
      );
  }
};

export default ProductSettingsDialogContainer;

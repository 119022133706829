/* eslint-disable no-mixed-operators */
import React, {
    useEffect, useReducer, forwardRef, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Fab,
    Grid, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import { AddIcon, DeleteOutlineOutlinedIcon } from 'components/icons';
import IconButton from '@material-ui/core/IconButton';
import { isEmpty } from 'lodash';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { useLazyQuery } from '@apollo/client';
import PartsMapper from 'services/mapData/PartsMapper';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import PartsTicketDetailSummary from 'components/modules/purchaseParts/partsTicket/create/PartsTicketDetailSummary';
import PartsSelect from 'components/modules/purchaseParts/parts/list/PartsSelect';
import PartsTicketDetailReducer, { ACTION_TYPES } from 'components/modules/purchaseParts/reducer/PartsTicketDetailReducer';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const ownStyle = makeStyles((theme) => (
    {
        customHeight: {
            minHeight: '80vh',
            paddingLeft: '16px',
        },
        fab: {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            margin: '20px 18px',
            width: '20px !important',
        },
        fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.green,
            '&:hover': {
                backgroundColor: theme.palette.background.green,
            },
        },
        header: {
            fontSize: '12px',
            fontWeight: 500,
            width: 'calc(100% - 5px)',
            display: 'flex',
            backgroundColor: theme.palette.background.titanWhite,
            border: `solid 1px ${theme.palette.background.gray}`,
            padding: '4px 15px 4px 4px',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        deleteButton: {
            color: theme.palette.error.main,
        },
        deleteIconButton: {
            padding: '3px',
        },
        itemPartCls: {
            padding: '8px',
            marginBottom: '10px',
            marginRight: '5px',
        },
        labelItem: {
            padding: '6px 0px',
        },
        parListCls: {
            maxHeight: '650px',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '100vh',
            },
            '& div.itemPart:nth-child(even)': {
                backgroundColor: theme.palette.background.selago,
            },
            '& .mobileOnly': {
                display: 'none',
                [theme.breakpoints.down('xs')]: {
                    display: 'block',
                },
            },
        },
        alignRight: {
            textAlign: 'right',
        },
        partsTableCls: {
            [theme.breakpoints.down('sm')]: {
                padding: 15,
            },
        },
        toolbarSummary: {
            padding: '10px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            margin: '0 0 10px;',
            backgroundColor: theme.palette.background.selago,
        },
    }
));

const keyStore = new KeyStore();

const PartsTicketDetail = forwardRef((props, ref) => {
    const PARTS_TICKET_WRITE_ADD_DELETE_PARTS = keyStore.hasPermission(Permission.PARTS_TICKET_WRITE_ADD_DELETE_PARTS);

    const {
        recordId,
        onDirty,
        lotName,
        editDetails: {
            allowAdd,
            allowDelete,
        },
        recordFields,
        discountPercent,
    } = props;

    const classes = {
        ...ownStyle(),
    };

    const {
        customHeight,
        fab,
        fabGreen,
        header,
        deleteButton,
        deleteIconButton,
        itemPartCls,
        labelItem,
        parListCls,
        alignRight,
        partsTableCls,
        toolbarSummary,
    } = classes;

    const mountedRef = useRef(true);

    const initialState = {
        records: [],
        openPartsSelect: false,
        idToDelete: null,
        partsTicketPartId: 0,
        sumSubTotal: 0,
        sumDiscount: 0,
        sumTax: 0,
        sumTotal: 0,
        isTaxable: false,
    };
    const [state, dispatch] = useReducer(PartsTicketDetailReducer, initialState);

    const {
        idToDelete, records,
        sumSubTotal,
        sumDiscount,
        sumTax,
        sumTotal,
    } = state;

    const [getRecords] = useLazyQuery(PartsQuery.GET_PARTS_TICKET_DETAIL_LIST,
        {
            onCompleted: (res) => {
                const mapValues = PartsMapper.mapPartsTicketDetail(res.getPartsTicketDetails);

                dispatch({
                    type: ACTION_TYPES.SET_FETCHED_RECORDS,
                    value: mapValues,
                    totals: {
                        ...recordFields,
                    },
                });
            },
            onError: (error) => {
                ModalUtils.errorMessage([error]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const [getPartsSettingsKeys] = useLazyQuery(PartsQuery.GET_PARTS_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getPartsDefaultSettings) {
                dispatch({
                    type: ACTION_TYPES.SET_PARTS_SETTINGS,
                    payload: res.getPartsDefaultSettings,
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getLineErrors = (lines) => {
        const errors = [];

        lines.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.description <= 0) message += ' Description is required,';
            if (!item.netPrice || item.netPrice === 0) message += ' Net Price is required and must be different than zero,';

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        return errors;
    };

    useImperativeHandle(ref, () => ({
        getRecords() {
            const errors = getLineErrors(records);

            return {
                errors,
                records,
            };
        },
        clear() {
            dispatch({ type: ACTION_TYPES.CLEAR_LINES });
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state.records]);

    const onAddNewLine = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPartsSelect: true,
            },
        });
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = () => {
        const dataAfterDeleted = state.records.filter((item) => item.partsTicketPartId !== state.idToDelete);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: dataAfterDeleted,
            notifyDirty: onDirty,
            discountPercent,
        });

        onCloseDeleteConfirm();
    };

    const setIdToDelete = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                idToDelete: val,
            },
        });
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
            notifyDirty: onDirty,
            discountPercent,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;
        if (event && (key === 'Tab' || keyCode === 9) && id === 'unitPrice') {
            dispatch({
                type: ACTION_TYPES.VALIDATE_ADD_LINE,
                method: onAddNewLine,
                rowIndex: cell.rowIndex,
                event,
            });
        }

        if (event && id !== 'description' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'description' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const addSelectedParts = (values) => {
        dispatch({
            type: ACTION_TYPES.ADD_SELECTED_PARTS,
            value: values,
            ticketNumber: recordId,
            notifyDirty: onDirty,
            discountPercent,
        });
    };

    const setOpenParts = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPartsSelect: true,
            },
        });
    };

    useEffect(() => {
        if (!mountedRef) return;
        if (recordId > 0) {
            getRecords({
                variables: {
                    id: recordId,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    useEffect(() => {
        if (lotName !== '') {
            getPartsSettingsKeys({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotName]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                isTaxable: recordFields.isTaxable,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordFields.isTaxable]);

    useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    useEffect(() => {
        if (state.partsTicketPartId !== 0) {
            const rows = state.records.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.amount-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.partsTicketPartId]);

    const renderPartList = (partList) => {
        const { partItem, index } = partList;
        const {
            partsTicketPartId,
            partNumber,
            description,
            quantity,
            netPrice,
            partCost,
            total,
        } = partItem;

        return (
            <Paper elevation={1} className={clsx(itemPartCls, 'itemPart')} variant="outlined" square key={partsTicketPartId}>
                <Grid container spacing={1}>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={6} sm={1}>
                            <Typography className="mobileOnly" noWrap>Line</Typography>
                            <Typography className={labelItem} noWrap>{index + 1}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography className="mobileOnly" noWrap>Part Stock # / Description</Typography>
                            <Typography className={labelItem} noWrap>
                                {partNumber}
                                {' / '}
                                {description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {allowAdd && (
                                <>
                                    <Typography className="mobileOnly" noWrap>Quantity</Typography>
                                    <InputControl
                                        name="quantity"
                                        value={quantity}
                                        editorCellObject={{ column: { id: 'quantity' }, rowData: partItem }}
                                        type="number"
                                        onChange={handleEditorChange}
                                        onKeyDown={handleEditorKeyDown}
                                        comparePropertyId={partsTicketPartId}
                                        showCurrency={false}
                                        allowNegative={false}
                                        alignRight
                                    />
                                </>
                            )}
                            {!allowAdd
                            && (
                                <>
                                    <Typography className="mobileOnly" noWrap>Quantity</Typography>
                                    <Typography className={clsx(labelItem, alignRight)} noWrap>{quantity}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {allowAdd && PARTS_TICKET_WRITE_ADD_DELETE_PARTS && (
                                <>
                                    <Typography className="mobileOnly" noWrap>List Price</Typography>
                                    <InputControl
                                        name="netPrice"
                                        value={netPrice}
                                        className={netPrice && netPrice !== 0 ? '' : 'invalid-field'}
                                        editorCellObject={{ column: { id: 'netPrice' }, rowData: partItem }}
                                        type="number"
                                        onChange={handleEditorChange}
                                        onKeyDown={handleEditorKeyDown}
                                        comparePropertyId={partsTicketPartId}
                                        allowNegative={false}
                                        alignRight
                                    />
                                </>
                            )}
                            {!(allowAdd && PARTS_TICKET_WRITE_ADD_DELETE_PARTS)
                            && (
                                <>
                                    <Typography className="mobileOnly" noWrap>List Price</Typography>
                                    <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(netPrice)}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography className="mobileOnly" noWrap>Cost</Typography>
                            <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(partCost)}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography className="mobileOnly" noWrap>Total</Typography>
                            <Typography className={clsx(labelItem, alignRight)} noWrap>{NumberUtils.applyCurrencyFormat(total)}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={1} className={alignRight}>
                            {allowDelete && PARTS_TICKET_WRITE_ADD_DELETE_PARTS && (
                                <IconButton
                                    onClick={() => setIdToDelete(partsTicketPartId)}
                                    className={deleteIconButton}
                                >
                                    <Tooltip title="Delete line">
                                        <DeleteOutlineOutlinedIcon
                                            className={deleteButton}
                                        />
                                    </Tooltip>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    return (
        <div className={clsx(customHeight)}>
            <Header classes={{ toolbar: toolbarSummary }}>
                <PartsTicketDetailSummary
                    displayInline
                    subTotal={sumSubTotal}
                    discount={sumDiscount}
                    tax={sumTax}
                    total={sumTotal}
                />
            </Header>
            <div>
                <div>
                    <div className={partsTableCls}>
                        <span className={header}>
                            <Grid container spacing={1}>
                                <Grid item container spacing={1} xs={12}>
                                    <Grid item xs={12} sm={1}>
                                        <Typography color="primary" variant="h6">Line</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography color="primary" variant="h6">Part # / Description</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Typography color="primary" variant="h6" className={alignRight}>Quantity</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Typography color="primary" variant="h6" className={alignRight}>List Price</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <Typography color="primary" variant="h6" className={alignRight}>Part Cost</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <Typography color="primary" variant="h6" className={alignRight}>Total</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <Typography color="primary" variant="h6" className={alignRight} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </span>
                        <div className={parListCls}>
                            {records?.length > 0 && records?.map((partItem, index) => (
                                renderPartList({ partItem, index })
                            ))}
                            {allowAdd && PARTS_TICKET_WRITE_ADD_DELETE_PARTS && (
                                <Fab
                                    label="Add part"
                                    className={clsx(fab, fabGreen)}
                                    onClick={() => setOpenParts(true)}
                                    size="small"
                                    variant="extended"
                                >
                                    <AddIcon />
                                </Fab>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
            {state.openPartsSelect && allowAdd && (
                <PartsSelect
                    open={state.openPartsSelect}
                    onPopupClose={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: {
                            openPartsSelect: false,
                        },
                    })}
                    onSelectItems={(values) => addSelectedParts(values)}
                    lotName={lotName}
                    allowSelectNegative={false}
                    isSellingParts
                />
            )}
            {allowDelete && idToDelete && (
                <ConfirmDialog
                    title="Confirm remove line"
                    description="Are you sure you want to remove this line?"
                    open
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseDeleteConfirm}
                    onClickSecondary={onCloseDeleteConfirm}
                    onClickPrimary={onDeleteConfirm}
                />
            )}
        </div>
    );
});

PartsTicketDetail.propTypes = {
    lotName: PropTypes.string,
    recordId: PropTypes.number,
    onDirty: PropTypes.func,
    editDetails: PropTypes.object,
    discountPercent: PropTypes.number,
    recordFields: PropTypes.shape({
        isTaxable: PropTypes.bool,
        sumSubTotal: PropTypes.number,
        sumDiscount: PropTypes.number,
        sumTax: PropTypes.number,
        sumTotal: PropTypes.number,
    }).isRequired,
};

PartsTicketDetail.defaultProps = {
    lotName: '',
    recordId: 0,
    onDirty: () => null,
    discountPercent: 0,
    editDetails: {
        editQuantity: false,
        editNetPrice: false,
        allowAdd: false,
        allowDelete: false,
    },
};

export default PartsTicketDetail;

import * as yup from 'yup';

const CheckSchema = yup.object().shape({
    bankAccount: yup.number().required().moreThan(0),
    type: yup.string().required().max(50),
    paidTo: yup.string().required().max(50),
    date: yup.date().required(),
    amount: yup.number().required(),
    lotId: yup.number().required().moreThan(0),
    memo: yup.string().max(
        250,
        'Memo has a maximum limit of 250 characters',
    ),
    status: yup.string().max(12),
    lotName: yup.string().max(50),
    entityAddress: yup.string().max(
        500,
        'Address has a maximum limit of 500 characters',
    ),
});

export default CheckSchema;

import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/client';
import Table from 'components/widgets/Table';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { FetchPolicy } from 'utils/enum/Core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const PartsTicketAssociated = forwardRef(({ partsInventoryId }, ref) => {
    const [getData, { data, loading }] = useLazyQuery(PartsQuery.GET_PARTS_TICKETS_ASSOCIATED, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const refreshData = (id) => {
        if (id > 0) {
            getData({
                variables: {
                    id,
                },
            });
        }
    };

    const classes = useStyles();
    const columns = [
        {
            Header: 'Ticket #',
            accessor: 'ticketNumber',
            maxWidth: 110,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Ticket Open',
            accessor: 'ticketOpen',
            maxWidth: 200,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
        },
        {
            Header: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            maxWidth: 130,
        },
    ];

    useEffect(() => {
        refreshData(partsInventoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partsInventoryId]);

    useImperativeHandle(ref, () => ({
        updateData(id) {
            refreshData(id);
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    return (
        <div className={clsx(classes.main)}>
            <Table
                loading={loading}
                columns={columns}
                data={data?.getPartsTicketAssociatedToPartsInventory ?? []}
                rowSelected
            />
        </div>
    );
});

PartsTicketAssociated.propTypes = {
    partsInventoryId: PropTypes.number,
};

PartsTicketAssociated.defaultProps = {
    partsInventoryId: 0,
};

export default PartsTicketAssociated;

import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import { concat, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import { TYPE } from 'utils/enum/core/Notification';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import ActivityItem from 'components/modules/crm/activities/ActivityItem';
import CallActivityItem from 'components/modules/crm/activities/CallActivityItem';
import TaskActivityItem from 'components/modules/crm/activities/TaskActivityItem';
import ActivitiesForm from 'components/modules/crm/activities/form/ActivitiesForm';
import EventActivityItem from 'components/modules/crm/activities/EventActivityItem';
import ActionActivityItem from 'components/modules/crm/activities/ActionActivityItem';

// Materia UI
import { makeStyles } from '@material-ui/core';

// Apollo
import { FetchPolicy } from 'utils/enum/Core';
import { ActivityType } from 'utils/enum/ActivitiesEnum';
import { useQuery, useSubscription } from '@apollo/client';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import ActivitiesQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import ActivitySubscription from 'services/graphQL/subscription/crm/ActivitySubscription';

const useStyles = makeStyles((theme) => ({
    body: {
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
    },
    container: {
        height: '100%',
        padding: theme.spacing(2),
    },
}));

const ACTION_TYPE = {
    ADD_RECORD: 'addRecord',
    SET_OFFSET: 'setOffset',
    SET_RECORDS: 'setRecords',
    TOGGLE_MODAL: 'toggleModal',
    UPDATE_RECORD: 'updateRecord',
    DELETE_RECORD: 'deleteRecord',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.TOGGLE_MODAL:
        return update(state, {
            open: { $set: action.open },
            activityId: { $set: action.activityId },
            activityType: { $set: action.activityType },
        });
    case ACTION_TYPE.SET_RECORDS:
        const records = concat(state.records, action.payload?.data);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.payload?.totalCount },
        });
    case ACTION_TYPE.DELETE_RECORD:
        const activityIndex = state.records.findIndex((item) => item.activityId === action.payload?.data?.activityId);

        if (activityIndex >= 0) {
            return update(state, {
                records: { $splice: [[activityIndex, 1]] },
                totalCount: { $set: state.totalCount - 1 },
            });
        }

        return state;
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    case ACTION_TYPE.ADD_RECORD:
        return update(state, {
            records: { $unshift: [action?.payload?.data] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPE.UPDATE_RECORD:
        const index = state.records.findIndex((item) => item.activityId === action?.payload?.data?.activityId);
        if (index >= 0) {
            return update(state, {
                records: { [index]: { $set: action?.payload?.data || {} } },
            });
        }
        return state;
    default:
        return state;
    }
};

const TrackActivity = ({ crmId, lotId, locationState }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        open: false,
        records: [],
        totalCount: 0,
        offset: 0,
        activityId: null,
        activityType: null,
    });
    const {
        data, error, loading,
    } = useQuery(ActivitiesQuery.GET_ACTIVITIES_BY_CRM_ID,
        {
            variables: { crmId, paging: { start: state.offset, limit: 25 }, active: true },
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });
    const { data: subscriptionData } = useSubscription(ActivitySubscription.ACTIVITY_UPDATED, {
        variables: {
            crmId,
        },
        shouldResubscribe: true,
    });

    const onClose = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_MODAL,
            open: false,
            activityId: null,
            activityType: null,
        });
    };

    const onOpen = (record) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_MODAL,
            open: true,
            activityId: record.activityId,
            activityType: record.type,
        });
    };

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data?.getActivitiesByCRMId,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (subscriptionData) {
            if (subscriptionData.activityUpdated.type === SubscriptionActionType.ADDED) {
                dispatch({
                    type: ACTION_TYPE.ADD_RECORD,
                    payload: subscriptionData.activityUpdated,
                });
            }

            if (subscriptionData.activityUpdated.type === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPE.UPDATE_RECORD,
                    payload: subscriptionData.activityUpdated,
                });
            }

            if (subscriptionData.activityUpdated.type === SubscriptionActionType.DELETED) {
                dispatch({
                    type: ACTION_TYPE.DELETE_RECORD,
                    payload: subscriptionData.activityUpdated,
                });
            }
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (!isEmpty(locationState?.data) && [TYPE.REMINDER_CALL, TYPE.REMINDER_EVENT, TYPE.REMINDER_TASK].includes(locationState.type)) {
            dispatch({
                type: ACTION_TYPE.TOGGLE_MODAL,
                open: true,
                activityId: locationState?.processRecordId,
                activityType: locationState?.data?.type,
            });
        }
    }, [locationState]);

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };

    if (error) {
        ModalUtils.errorMessage(error.graphQLErrors);
        return null;
    }

    const getRecordByType = (item, index) => {
        const type = item?.type?.toUpperCase();
        switch (type) {
        case ActivityType.EVENT: {
            return (
                <EventActivityItem
                    record={item}
                    key={index}
                    onOpen={onOpen}
                />
            );
        }
        case ActivityType.TASK: {
            return (
                <TaskActivityItem
                    record={item}
                    key={index}
                    onOpen={onOpen}
                />
            );
        }
        case ActivityType.CALL: {
            return (
                <CallActivityItem
                    record={item}
                    key={index}
                    onOpen={onOpen}
                />
            );
        }
        case ActivityType.NOTE: {
            return (
                <ActivityItem
                    record={item}
                    key={index}
                />
            );
        }
        case ActivityType.ACTION: {
            return (
                <ActionActivityItem
                    record={item}
                    key={index}
                />
            );
        }
        default:
            return null;
        }
    };

    return (
        <div className={clsx('d-flex-column', classes.container)}>
            <InfiniteScroll
                lengthRecord={state?.records.length}
                totalRecord={state?.totalCount}
                loadMore={loadMore}
                load={loading}
                loadAtScrollPercent={80}
            >
                {
                    state.records.map((item, index) => getRecordByType(item, index))
                }
            </InfiniteScroll>
            {state.open && (
                <ActivitiesForm
                    isEditing
                    crmId={crmId}
                    lotId={lotId}
                    open={state.open}
                    onClose={onClose}
                    type={state.activityType}
                    activityId={state.activityId}
                />
            )}
        </div>
    );
};

TrackActivity.propTypes = {
    lotId: PropTypes.number,
    locationState: PropTypes.object,
    crmId: PropTypes.string.isRequired,
};

TrackActivity.defaultProps = {
    lotId: null,
    locationState: {},
};

export default TrackActivity;

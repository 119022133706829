import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import UserQuery from 'services/graphQL/query/UserQuery';

const keyStore = new KeyStore();

const useRecordManager = (props = {}) => {
    const [recordManagers, setRecordManagers] = useState([]);
    const lotId = props.lotId || keyStore.getSelectedLot().lotId;
    const { data, loading, error } = useQuery(UserQuery.GET_RECORD_MANAGERS, {
        variables: { lotId },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            const { getRecordManagers } = data;
            const result = getRecordManagers.map((item) => ({ value: item.userId, label: `${item.firstName} ${item.lastName}`, image: item.userPicture }));
            setRecordManagers(props.notIncludeEmptyValue ? result : [{ label: 'None', value: null }, ...result]);
        }
    }, [data, loading, error, props.notIncludeEmptyValue, props.lotId]);

    return {
        recordManagers,
        loading,
        error,
    };
};

export default useRecordManager;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from 'components/widgets/Select';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';
import DisplaySelect from 'components/modules/inventory/create/panels/DisplaySelect';
import DatePicker from 'react-datepicker';
import ButtonStyles from 'styles/theme/Button';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import PurchasingDialog from 'components/modules/inventory/create/dialogs/PurchasingDialog';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import { VehicleInfoProviders } from 'utils/enum/InventoryEnum';

// icons
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles({
    root: {
        padding: 10,
        background: '#FBFBFB',
        marginBottom: 20,
        filter: 'brightness(98%)',
    },
    gridContainer: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
    datePicker: {
        '& > div:first-child': {
            width: '100%',
        },
        '& input': {
            maxHeight: 31,
        },
    },
    purchasingAgentActions: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div:nth-child(1)': {
            flexGrow: 1,
        },
        '& > div:nth-child(2)': {
            flexShrink: 0,
            width: '50px',
            '& > button': {
                marginLeft: '5px',
                padding: 0,
                minWidth: '25px',
                '& > span > span': {
                    margin: 0,
                    '& > svg': {
                        width: '15px',
                        height: '20px',
                    },
                },
            },
        },
    },
});
const getConditionReportOptions = () => {
    const conditionReportOptions = [];

    for (let i = 0; i <= 5; i += 0.1) {
        const value = Number(i.toFixed(2));

        conditionReportOptions.push({
            value,
            label: value,
        });
    }

    return conditionReportOptions;
};
const getProviders = (list) => {
    const { CAR_FAX, AUTO_CHECK } = VehicleInfoProviders;
    const carFaxOptions = [];
    const autoCheckOptions = [];

    list.forEach((item) => {
        if (item.type.toUpperCase() === CAR_FAX) {
            carFaxOptions.push({
                value: item.description,
                label: item.description,
            });
        }
        if (item.type.toUpperCase() === AUTO_CHECK) {
            autoCheckOptions.push({
                value: item.description,
                label: item.description,
            });
        }
    });

    return {
        carFaxOptions,
        autoCheckOptions,
    };
};

const PurchasingForm = ({
    data,
    loading,
    purchasedFromList,
    purchasingAgentList,
    providersOptions,
    onChange,
    onCreate,
    onEdit,
    onDelete,
    defaultReconCost,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [state, setState] = useState({
        isPurchasingDialogOpen: false,
        isDeleteDialogOpen: false,
        title: '',
        field: '',
        currentAgent: null,
    });

    const invalidPurchasedFrom = StringUtils.isEmpty(data.purchasedFrom);
    const invalidPurchasedPrice = data.purchasedPrice <= 0;

    const toggleDeleteDialog = (id, name) => {
        setState({
            ...state,
            isDeleteDialogOpen: !state.isDeleteDialogOpen,
            currentAgent: { purchasingAgentsId: id, name },
        });
    };

    const openPurchasingDialog = (titleInput, fieldInput, currentAgent) => {
        setState({
            ...state,
            isPurchasingDialogOpen: true,
            title: titleInput,
            field: fieldInput,
            currentAgent,
        });
    };

    const purchasedFromOptions = [...purchasedFromList.map((item) => ({
        value: item.purchasedFrom,
        label: item.purchasedFrom,
    })), {
        label: (
            <Button
                className={classes.containedInfo}
                size="medium"
                fullWidth
                onClick={() => openPurchasingDialog('Add Purchased From', 'purchasedFrom')}
            >
                Add
            </Button>
        ),
        value: '-1',
        isDisabled: true,
    }];

    const purchasingAgentOptions = [...purchasingAgentList.map((item) => ({
        value: item.agent,
        label: (
            <div className={classes.purchasingAgentActions}>
                <div>{item.agent}</div>
                <div>
                    <Button
                        className={classes.containedInfo}
                        size="medium"
                        startIcon={<EditOutlinedIcon />}
                        onClick={(e) => {
                            e.stopPropagation();
                            openPurchasingDialog('Edit Purchasing Agent', 'purchasedAgent', item);
                        }}
                    />
                    <Button
                        className={classes.containedError}
                        size="medium"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDeleteDialog(item.purchasingAgentsId, item.agent);
                        }}
                    />
                </div>
            </div>
        ),
    })), {
        label: (
            <Button
                className={classes.containedInfo}
                size="medium"
                fullWidth
                onClick={() => openPurchasingDialog('Add Purchasing Agent', 'purchasedAgent')}
            >
                Add
            </Button>
        ),
        value: '-1',
        isDisabled: true,
    }];

    const conditionReportOptions = getConditionReportOptions();
    const { carFaxOptions, autoCheckOptions } = getProviders(providersOptions);
    const onChangeForm = (fieldName, value) => {
        onChange(fieldName, value, 'purchasingInfo');
    };

    const onClose = () => {
        setState({
            ...state,
            isPurchasingDialogOpen: false,
        });
    };

    return (
        <div className={clsx('d-flex-column', classes.root)}>
            <Typography variant="h5">Purchasing Information</Typography>
            <Grid
                container
                orientation="column"
                className={classes.gridContainer}
            >
                <Grid container item xs={12} sm={6}>
                    <Grid
                        container
                        item
                        wrap="nowrap"
                        className={isMobile ? '' : 'justify-end'}
                        xs={12}
                        sm={5}
                    >
                        <FormLabel
                            className="text-bold"
                        >
                            Purchased From:
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Select
                            sorted
                            nowrap
                            size="sm"
                            name="purchasedFrom"
                            loading={loading}
                            options={purchasedFromOptions}
                            value={data.purchasedFrom}
                            className={clsx({ 'invalid-field': invalidPurchasedFrom })}
                            onChange={(name, value) => onChangeForm(name, value)}
                        />
                    </Grid>
                </Grid>
                <DisplayInput
                    label="Purchased Price"
                    value={data.purchasedPrice}
                    className={clsx({ 'invalid-field': invalidPurchasedPrice })}
                    onChange={(value) => onChangeForm('purchasedPrice', value)}
                    bold
                    alignRight={!isMobile}
                />
                <Grid container item xs={12} sm={6}>
                    <Grid
                        container
                        item
                        wrap="nowrap"
                        className={isMobile ? '' : 'justify-end'}
                        xs={12}
                        sm={5}
                    >
                        <FormLabel
                            className="text-bold"
                        >
                            Purchasing Agent:
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Select
                            sorted
                            nowrap
                            size="sm"
                            name="purchasedAgent"
                            loading={loading}
                            options={purchasingAgentOptions}
                            value={data.purchasedAgent}
                            onChange={(name, value) => onChangeForm(name, value)}
                        />
                    </Grid>
                </Grid>
                <DisplayInput
                    label="Max Recon Cost"
                    value={data.maximumReconCost || defaultReconCost}
                    onChange={(value) => onChangeForm('maximumReconCost', value)}
                    bold
                    alignRight={!isMobile}
                    isReconCost
                />
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Purchased Date:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7} className={classes.datePicker}>
                        <DatePicker
                            size="small"
                            className="form-control form-control-sm"
                            onChange={(date) => onChangeForm('purchasedDate', date)}
                            selected={data.purchasedDate ? DateUtils.toLocal(data.purchasedDate).toDate() : ''}
                            popperContainer={CalendarContainer}
                            maxDate={DateUtils.toLocal(new Date()).toDate()}
                        />
                    </Grid>
                </Grid>
                <DisplayInput
                    label={(
                        <>
                            <FormLabel className="text-bold">Pack:</FormLabel>
                            <Checkbox
                                checked={data.packOverride}
                                color="primary"
                                size="small"
                                onChange={(_, value) => onChangeForm('packOverride', value)}
                            />
                        </>
                    )}
                    value={data.pack}
                    onChange={(value) => onChangeForm('pack', value)}
                    disabled={!data.packOverride}
                    bold
                    alignRight={!isMobile}
                />
                <DisplaySelect
                    label="CR"
                    tooltip="Condition Report from Auction"
                    value={data.cr}
                    name="cr"
                    onChange={(value) => onChangeForm('cr', value)}
                    options={conditionReportOptions}
                    bold
                    alignRight={!isMobile}
                />
                <DisplayInput
                    label="Cost"
                    value={data.cost}
                    disabled
                    bold
                    alignRight={!isMobile}
                />
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12} sm={5} className={clsx({ 'd-flex-justify-end': !isMobile })}>
                        <FormLabel className="text-bold">Frame:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Checkbox
                            checked={data.isFrame}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChangeForm('isFrame', value)}
                        />
                    </Grid>
                </Grid>
                <DisplaySelect
                    label="CarFax"
                    value={data.carFax}
                    name="carFax"
                    onChange={(value) => onChangeForm('carFax', value)}
                    options={carFaxOptions}
                    bold
                    alignRight={!isMobile}
                />
                <Grid container item xs={12} sm={6} />
                <DisplaySelect
                    label="Auto Check"
                    value={data.autoCheck}
                    name="autoCheck"
                    onChange={(value) => onChangeForm('autoCheck', value)}
                    options={autoCheckOptions}
                    bold
                    alignRight={!isMobile}
                />
            </Grid>
            <PurchasingDialog
                isPurchasingDialogOpen={state.isPurchasingDialogOpen}
                title={state.title}
                field={state.field}
                current={state.currentAgent}
                onCreate={onCreate}
                onEdit={onEdit}
                onClose={onClose}
            />
            <ConfirmDialog
                title="Attention!"
                description={`Do you want to remove the purchasing agent: ${state.currentAgent?.name}?`}
                open={state.isDeleteDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeleteDialog()}
                onClickSecondary={() => toggleDeleteDialog()}
                onClickPrimary={() => {
                    onDelete(state.currentAgent.purchasingAgentsId);
                    toggleDeleteDialog();
                }}
            />
        </div>
    );
};

PurchasingForm.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    purchasedFromList: PropTypes.array,
    purchasingAgentList: PropTypes.array,
    providersOptions: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    defaultReconCost: PropTypes.number,
};

PurchasingForm.defaultProps = {
    data: {},
    loading: false,
    purchasedFromList: [],
    purchasingAgentList: [],
    providersOptions: [],
    defaultReconCost: 0,
};

export default PurchasingForm;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useQuery } from '@apollo/client';
import VendorQuery from 'services/graphQL/query/VendorQuery';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';
import DisplaySelect from 'components/modules/inventory/create/panels/DisplaySelect';

import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import { VendorType } from 'utils/enum/Vendors';
import { DataSort } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import InventoryHelper from 'utils/InventoryHelper';

const useStyles = makeStyles({
    root: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
    datePicker: {
        '& > div:first-child': {
            width: '100%',
        },
        '& input': {
            maxHeight: 31,
            fontSize: '14px',
        },
    },
});
const SortFields = {
    VENDOR_NAME: 'vendorName',
    CATEGORY: 'category',
};

const calculateDataByCompany = (data, company, vendorFee, floorRate) => {
    const floorplanData = { ...data };

    floorplanData.flooredRate = floorRate;
    floorplanData.flooredBy = company;
    floorplanData.isFloorPlanned = company !== null && company !== 0;

    if (!floorplanData.floorPlannedDate) {
        floorplanData.floorPlannedDate = DateUtils.format(new Date());
    }

    floorplanData.floorplanCost = InventoryHelper.calculateFloorplanCost(
        vendorFee,
        floorplanData.borrowedAmount,
        floorplanData.flooredRate,
        floorplanData.floorPlannedDate,
        floorplanData.floorPaidOn,
    );

    return floorplanData;
};

const FloorplanEditPanel = (props) => {
    const classes = useStyles();
    const variables = {
        filter: {
            vendorType: [VendorType.FLOORPLAN],
        },
        sort: {
            field: SortFields.VENDOR_NAME,
            dir: DataSort.ASC,
        },
    };
    const {
        data: vendorListResponse,
        loading: loadingVendorList,
        error: vendorListError,
    } = useQuery(VendorQuery.GET_VENDOR_LIST, { variables });

    if (vendorListError) {
        ModalUtils.errorMessage(vendorListError?.graphQLErrors);
    }

    const { data, writePermission } = props;

    if (!writePermission) {
        return null;
    }

    const vendorList = vendorListResponse?.getVendorList || [];
    const vendorListData = vendorList.map((item) => ({
        value: item.vendorName,
        label: item.vendorName,
    }));

    const invalidFlooringCompany = data.isFloorPlanned
        && StringUtils.isEmpty(data.flooredBy);

    const invalidFloorPlannedDate = data.isFloorPlanned
        && !data.floorPlannedDate;

    const invalidBorrowedAmount = data.borrowedAmount <= 0
        && data.isFloorPlanned
        && data.isBorrowedAmountOverride;

    const getVendor = (company) => {
        const vendor = vendorList.find((item) => item.vendorName === company);

        return vendor || {};
    };
    const onChange = (fieldName, value) => {
        if (fieldName === 'flooredBy') {
            const vendor = getVendor(value);
            const { vendorFee, floorRate } = vendor;
            const floorplanData = calculateDataByCompany(
                data, value,
                vendorFee, floorRate,
            );

            props.onChange(null, null, floorplanData);
        } else {
            let floorplanData = { ...data };
            floorplanData[fieldName] = value;

            if (fieldName === 'isFloorPlanned') {
                if (value) {
                    const currentDate = DateUtils.format(new Date());

                    floorplanData = {
                        ...floorplanData,
                        floorPlannedDate: data.floorPlannedDate || currentDate,
                        curtailmentDate: data.curtailmentDate,
                        floorPaidOn: data.floorPaidOn,
                        borrowedAmount: data.purchasedPrice || 0,
                        flooredBy: data.flooredBy || '',
                    };
                } else {
                    floorplanData = {
                        ...floorplanData,
                        floorPlannedDate: null,
                        curtailmentDate: null,
                        floorPaidOn: null,
                        borrowedAmount: 0,
                        flooredBy: '',
                        isBorrowedAmountOverride: false,
                        floorplanCost: 0,
                        flooredRate: 0,
                    };
                }
            }

            const vendor = getVendor(floorplanData.flooredBy);
            floorplanData.floorplanCost = InventoryHelper.calculateFloorplanCost(
                vendor.vendorFee,
                floorplanData.borrowedAmount,
                floorplanData.flooredRate,
                floorplanData.floorPlannedDate,
                floorplanData.floorPaidOn,
            );

            props.onChange(null, null, floorplanData);
        }
    };

    return (
        <Grid
            container
            orientation="column"
            className={classes.root}
        >
            <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={5}>
                    <FormLabel>Floor Planned:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Checkbox
                        checked={data.isFloorPlanned === null ? false : data.isFloorPlanned}
                        color="primary"
                        size="small"
                        onChange={(_, value) => onChange('isFloorPlanned', value)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={5}>
                    <FormLabel>Floor Date:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.datePicker}>
                    <DatePicker
                        size="small"
                        className={clsx('form-control', { 'invalid-field': invalidFloorPlannedDate })}
                        onChange={(date) => onChange('floorPlannedDate', date)}
                        selected={data.floorPlannedDate ? DateUtils.toLocal(data.floorPlannedDate).toDate() : ''}
                        popperPlacement="top-end"
                        popperContainer={CalendarContainer}
                        disabled={!data.isFloorPlanned}
                        maxDate={data.curtailmentDate ? DateUtils.toLocal(data.curtailmentDate).toDate() : ''}
                    />
                </Grid>
            </Grid>
            <DisplaySelect
                label="Flooring Company"
                value={data.flooredBy}
                name="flooredBy"
                onChange={(value) => onChange('flooredBy', value)}
                options={vendorListData}
                disabled={!data.isFloorPlanned}
                className={clsx({ 'invalid-field': invalidFlooringCompany })}
                loading={loadingVendorList}
            />
            <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={5}>
                    <FormLabel>Curtailment Date:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.datePicker}>
                    <DatePicker
                        size="small"
                        className="form-control"
                        onChange={(date) => onChange('curtailmentDate', date)}
                        selected={data.curtailmentDate ? DateUtils.toLocal(data.curtailmentDate).toDate() : ''}
                        popperPlacement="top-end"
                        popperContainer={CalendarContainer}
                        disabled={!data.isFloorPlanned}
                        minDate={data.floorPlannedDate ? DateUtils.toLocal(data.floorPlannedDate).toDate() : ''}
                    />
                </Grid>
            </Grid>
            <DisplayInput
                label={(
                    <>
                        <FormLabel>Amount Borrowed:</FormLabel>
                        <Checkbox
                            checked={data.isBorrowedAmountOverride}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChange('isBorrowedAmountOverride', value)}
                            disabled={!data.isFloorPlanned}
                        />
                    </>
                )}
                value={data.borrowedAmount}
                onChange={(value) => onChange('borrowedAmount', value)}
                disabled={!data.isFloorPlanned || !data.isBorrowedAmountOverride}
                className={clsx({ 'invalid-field': invalidBorrowedAmount })}
            />
            <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={5}>
                    <FormLabel>Floor Paid On:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.datePicker}>
                    <DatePicker
                        size="small"
                        className="form-control"
                        onChange={(date) => onChange('floorPaidOn', date)}
                        selected={data.floorPaidOn ? DateUtils.toLocal(data.floorPaidOn).toDate() : ''}
                        popperPlacement="top-end"
                        popperContainer={CalendarContainer}
                        disabled={!data.isFloorPlanned}
                        minDate={data.floorPlannedDate ? DateUtils.toLocal(data.floorPlannedDate).toDate() : ''}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

FloorplanEditPanel.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    writePermission: PropTypes.bool,
};

FloorplanEditPanel.defaultProps = {
    data: {},
    onChange: () => {},
    writePermission: false,
};

export default FloorplanEditPanel;

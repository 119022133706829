import React, { Component } from 'react';

// Components and Ohters
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ZipUtils from 'utils/ZipUtils';
import { DefaultCountry } from 'utils/enum/Customer';

// Http
import CustomerMapper from 'services/mapData/CustomerMapper';
import CustomerMap from 'services/mapData/CustomerMap';
import GraphqlClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerService from 'services/modules/CustomerService';

const AddressDialogContainer = (WrappedComponent) => class extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        toggleModal: PropTypes.func.isRequired,
        isEditing: PropTypes.bool.isRequired,
        record: PropTypes.object.isRequired,
        openManage: PropTypes.func,
        handleCreatedAddress: PropTypes.func,
    };

    static defaultProps = {
        openManage: null,
        handleCreatedAddress: null,
    };

    constructor(props) {
        super(props);

        const newRecord = props.isEditing ? CustomerMapper.mapAddressData(props.record) : this.getInitState();
        const cities = [];
        if (newRecord && newRecord.city) {
            cities.push(newRecord.city);
        }

        this.graphqlClient = new GraphqlClient();
        this.customerService = new CustomerService();
        this.state = {
            zipData: [],
            record: newRecord,
            listCity: cities,
            openConfirm: false,
            isDecoderEnabled: false,
            isDecodingZip: false,
            saving: false,
        };

        this.initBind();
    }

    componentDidMount() {
        const { isEditing, record: { zipCode } } = this.props;

        if (isEditing && !StringUtils.isEmpty(zipCode)) {
            this.decodeZip(zipCode, true);
        }
    }

    onEnableDecoder() {
        const { state: { isDecoderEnabled } } = this;
        if (!isDecoderEnabled) {
            this.setState({ isDecoderEnabled: true });
        }
    }

    onChangeValue(field, value) {
        this.setState(({ record }) => {
            const newRecord = { ...record };
            newRecord[field] = value;
            return { record: newRecord };
        }, () => {
            if (field === 'zipCode' && value.length === 5) {
                const { state: { isDecoderEnabled } } = this;
                if (isDecoderEnabled) {
                    this.decodeZip(value);
                }
            }
        });
    }

    onChangeCity(field, value) {
        this.setState(({ record, zipData }) => {
            const newRecord = { ...record };
            if (zipData && zipData.length > 0) {
                const alreadyExist = zipData.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(value));
                if (!alreadyExist) {
                    const newData = clone(zipData[0]);
                    newData.city = value;
                    zipData.unshift(newData);
                }
            }

            const countyAndState = ZipUtils.getCountryAndStateByCity(value, zipData);
            newRecord.city = value;
            if (!StringUtils.isEmpty(countyAndState.county)) newRecord.county = countyAndState.county;
            if (!StringUtils.isEmpty(countyAndState.state)) newRecord.state = countyAndState.state;
            return { record: newRecord };
        });
    }

    onChangeEndDate(value) {
        const { state: { record } } = this;
        const backUp = clone(record);
        backUp.end = value;

        if (DateUtils.isValid(value)) {
            backUp.isCurrentAddress = false;
        }

        this.setState({
            record: backUp,
        });
    }

    onSave(input) {
        const { props: { toggleModal, openManage, handleCreatedAddress } } = this;

        this.setState({ saving: true });

        this.customerService.createCustomerAddress(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data && data.createCustomerAddress) {
                    if (handleCreatedAddress) handleCreatedAddress(data.createCustomerAddress);
                    toggleModal();
                    if (openManage) openManage();

                    return;
                }

                this.setState({ saving: false });
            });
    }

    onUpdate(input) {
        const { props: { toggleModal, openManage, handleCreatedAddress } } = this;

        this.setState({ saving: true });

        this.customerService.updateCustomerAddress(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data && data.updateCustomerAddress) {
                    if (handleCreatedAddress) handleCreatedAddress(data.updateCustomerAddress);
                    toggleModal();
                    if (openManage) openManage();

                    return;
                }
                this.setState({ saving: false });
            });
    }

    getInitState() {
        const { props: { handleCreatedAddress } } = this;
        return {
            address1: '',
            address2: '',
            city: '',
            state: '',
            county: '',
            zipCode: '',
            country: DefaultCountry.COUNTRY,
            start: '',
            end: '',
            mortgageOrRent: null,
            housingStatus: '',
            phone: '',
            isCurrentAddress: handleCreatedAddress != null,
            action: '',
        };
    }

    getData(isCurrentAddress) {
        const { state: { record }, props: { customerId } } = this;
        const currentCountry = typeof record.country === 'object' ? record.country.value : record.country;
        const currentCity = typeof record.city === 'object' ? record.city.value : record.city;

        const input = {
            customerId,
            input: {
                address1: record.address1,
                address2: record.address2,
                city: currentCity,
                state: record.state,
                county: record.county,
                country: currentCountry,
                zipCode: StringUtils.isEmpty(record.zipCode) ? '' : record.zipCode.toString(),
                isCurrentAddress: isCurrentAddress !== null ? isCurrentAddress : record.isCurrentAddress,
                phone: record.phone,
                mortgageOrRent: record.mortgageOrRent,
                housingStatus: record.housingStatus,
                start: DateUtils.formatUTC(record.start),
                end: DateUtils.formatUTC(record.end),
                action: '',
            },
        };

        return input;
    }

    beforeSaving() {
        const { state: { record: { end, isCurrentAddress } } } = this;

        if (!DateUtils.isValid(end) && !isCurrentAddress) {
            this.setState({
                openConfirm: true,
            });

            return;
        }

        this.isSaveOrEdit();
    }

    isSaveOrEdit(isCurrentAddress = null) {
        const { props: { isEditing, record: { customerAddressId } } } = this;
        const input = this.getData(isCurrentAddress);

        if (isEditing) {
            input.customerAddressId = customerAddressId;
            this.onUpdate(input);
        } else {
            this.onSave(input);
        }
    }

    toggleConfirm() {
        this.setState((prevState) => ({
            openConfirm: !prevState.openConfirm,
        }));
    }

    initBind() {
        this.decodeZip = this.decodeZip.bind(this);
        this.onSave = this.onSave.bind(this);
        this.isSaveOrEdit = this.isSaveOrEdit.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.beforeSaving = this.beforeSaving.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.onEnableDecoder = this.onEnableDecoder.bind(this);
    }

    decodeZip(zip = '', ignoreCity = false) {
        const input = {
            zip,
        };

        this.setState({ isDecodingZip: true });
        this.graphqlClient
            .query(CustomerQuery.DECODE_ZIP_CODE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.decodeZip) {
                    const { decodeZip } = data;

                    /* In order to keep the city value, we need
                    to assign it using the record from database */
                    if (ignoreCity && decodeZip.length > 0) {
                        const { state: { record } } = this;
                        const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.city));

                        if (!alreadyExist) {
                            const newData = clone(decodeZip[0]);
                            newData.city = record.city;
                            decodeZip.unshift(newData);
                        }
                    }

                    const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                    if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                    const zipData = decodeZip;

                    this.setState(({ record, isDecoderEnabled }) => {
                        if (isDecoderEnabled) {
                            const newRecord = {
                                ...record,
                                city: '',
                                county: '',
                                state: '',
                            };
                            return {
                                listCity, zipData, record: newRecord, isDecodingZip: false,
                            };
                        }
                        return { listCity, zipData, isDecodingZip: false };
                    });

                    if (decodeZip.length === 1) {
                        const currentData = decodeZip[0];

                        this.setState(({ record }) => {
                            const newRecord = {
                                ...record,
                                city: currentData.city,
                                county: currentData.county,
                                state: currentData.state,
                            };

                            return { record: newRecord };
                        });
                    }
                }
            });
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                isSaveOrEdit={this.isSaveOrEdit}
                beforeSaving={this.beforeSaving}
                toggleConfirm={this.toggleConfirm}
                onChangeCity={this.onChangeCity}
                onChangeValue={this.onChangeValue}
                onChangeEndDate={this.onChangeEndDate}
                onEnableDecoder={this.onEnableDecoder}
            />
        );
    }
};

export default AddressDialogContainer;

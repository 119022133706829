import React, { useRef } from 'react';
import TabContainer from 'components/widgets/tabs/TabContainer';
import { Tabs, Tab } from 'react-bootstrap';
import TabStyles from 'styles/widgets/tabs/TabsStyles';
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PurchasesAssociated from 'components/modules/purchaseParts/parts/list/PurchasesAssociated';
import PartsHistory from 'components/modules/purchaseParts/parts/list/PartsHistory';
import PartsTicketAssociated from 'components/modules/purchaseParts/parts/list/PartsTicketAssociated';
import { CachedIcon } from 'components/icons';

const useStyle = makeStyles((theme) => TabStyles.tabPanel(theme));
const ownStyle = makeStyles((theme) => ({
    navbar: {
        '& > a': {
            color: theme.palette.text.outerSpace,
            'text-decoration': 'none',
            'background-color': 'transparent',
            'line-height': '1.5',
            'font-size': '14px',
            'font-weight': 500,
        },
    },
    littleButton: {
        padding: '0px',
        marginLeft: '5px',
    },
    littleIcon: {
        width: '20px',
        height: '20px',
    },
}));

const PartsMappingProcess = ({ partsInventoryId }) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const partsHistoryRef = useRef();
    const purchaseOrderRef = useRef();
    const partsTicketRef = useRef();

    return (
        <TabContainer>
            <Tabs
                mountOnEnter
                className={`${classes.borderContainer} ${classes.navbar}`}
            >
                <Tab
                    className={classes.tab}
                    eventKey={0}
                    mountOnEnter
                    title={(
                        <div>
                            Purchase Orders
                            {partsInventoryId > 0 && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    className={classes.littleButton}
                                    onClick={() => purchaseOrderRef?.current?.updateData(partsInventoryId)}
                                >
                                    <CachedIcon className={classes.littleIcon} />
                                </IconButton>
                            )}
                        </div>
                    )}
                >
                    <PurchasesAssociated
                        partsInventoryId={partsInventoryId}
                        ref={purchaseOrderRef}
                    />
                </Tab>
                <Tab
                    className={classes.tab}
                    eventKey={1}
                    mountOnEnter
                    title={(
                        <div>
                            Parts Ticket
                            {partsInventoryId > 0 && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    className={classes.littleButton}
                                    onClick={() => partsTicketRef?.current?.updateData(partsInventoryId)}
                                >
                                    <CachedIcon className={classes.littleIcon} />
                                </IconButton>
                            )}
                        </div>
                    )}
                >
                    <PartsTicketAssociated
                        partsInventoryId={partsInventoryId}
                        ref={partsTicketRef}
                    />
                </Tab>
                <Tab
                    className={classes.tab}
                    eventKey={2}
                    mountOnEnter
                    title={(
                        <div>
                            Parts History
                            {partsInventoryId > 0 && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    className={classes.littleButton}
                                    onClick={() => partsHistoryRef?.current?.updateData(partsInventoryId)}
                                >
                                    <CachedIcon className={classes.littleIcon} />
                                </IconButton>
                            )}
                        </div>
                    )}
                >
                    <PartsHistory
                        ref={partsHistoryRef}
                        partsInventoryId={partsInventoryId}
                    />
                </Tab>
            </Tabs>
        </TabContainer>
    );
};

PartsMappingProcess.propTypes = {
    partsInventoryId: PropTypes.number,
};

PartsMappingProcess.defaultProps = {
    partsInventoryId: 0,
};

export default PartsMappingProcess;

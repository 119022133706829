import { gql } from '@apollo/client';

export default class InventoryQuery {
    static VEHICLE_LOCATION_LIST = gql`
        {
            vehicleLocationList {
                location
            }
        }
    `;

    static GET_VEHICLE_INTERACTIONS = gql`
        query getVehicleInteractions($stockNumber: Int!) {
            getVehicleInteractions(stockNumber: $stockNumber) {
                totalVDP
                totalLeads
                latestLeadsCount
                appointments
                appointmentsConfirmed
                appointmentsShowed
                totaldemos
                latestDemosCount
            }
        }
    `;

    static GET_EQUIPMENTS = gql`
        query($vin: String!, $trim: String, $stockNumber: Int) {
            decodeVin(vin: $vin, trim: $trim, stockNumber: $stockNumber) {
                decodedStyle
                standardEquipment {
                    equipmentId
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                optionalEquipment {
                    equipmentId
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                additionalSpec {
                    specTitle
                    specDescription
                }
                alreadyDecoded
            }
        }
    `;

    static SELECTED_FEATURE = gql`
        query getSelectedFeature($stockNumber: Int!) {
            getSelectedFeature(stockNumber: $stockNumber) {
                optionId
                optionNumber
                optionName
                icon
                active
            }
        }
    `;

    static FEATURE_LIST = gql`
        query getFeatureList($filter: KeyFeatureFilter, $sort: FeatureSort) {
            getFeatureList(filter: $filter, sort: $sort) {
                optionId
                optionNumber
                optionName
                icon
                active
            }
        }
    `;

    static ICON_LIST = gql`
        {
            getIconList {
                general {
                    key
                    url
                }
                custom {
                    key
                    url
                }
            }
        }
    `;

    static GET_MAKES_LIST = gql`
        {
            getMakesList
        }
    `;

    static GET_DRIVETRAINS_LIST = gql`
        query getDriveTrainsByMake($make: String!) {
            getDriveTrainsByMake(make: $make) {
                driveTrains
            }
        }
    `;

    static GET_COLORS = gql`
        query($vin: String!, $trim: String, $stockNumber: Int) {
            decodeVin(vin: $vin, trim: $trim, stockNumber: $stockNumber) {
                trim
                decodedStyle
                bodyStyle
                engines
                drivetrain
                exteriorColors {
                    colorCode
                    colorName
                    rgbValue
                }
                interiorColors {
                    colorCode
                    colorName
                }
                genericColors {
                    colorName
                }
                standardEquipment {
                    equipmentId
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                optionalEquipment {
                    equipmentId
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                additionalSpec {
                    specTitle
                    specDescription
                }
            }
        }
    `;

    static GET_VEHICLE_DATA = gql`
        query($vin: String!, $trim: String) {
            decodeVin(vin: $vin, trim: $trim) {
                year
                make
                model
                trim
                decodedStyle
                bodyStyle
                engines
                cityMPG
                highwayMPG
                image
                drivetrain
                interiorColor {
                    colorCode
                    colorName
                }
                exteriorColor {
                    colorCode
                    colorName
                    rgbValue
                }
                exteriorColors {
                    colorCode
                    colorName
                    rgbValue
                }
                interiorColors {
                    colorCode
                    colorName
                }
                genericColors {
                    colorName
                }
                standardEquipment {
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                optionalEquipment {
                    equipmentDescription
                    equipmentGroupDescription
                    equipmentGroupId
                    installed
                    defaultInstalled
                }
                additionalSpec {
                    specTitle
                    specDescription
                }
            }
        }
    `;

    static GET_VEHICLE_DEPRECIATION = gql`
        query getVehicleDepreciation($stockNumber: Int!) {
            getVehicleDepreciation(stockNumber: $stockNumber) {
                depreciationId
                stockNumber
                beginningDepreciatedAmount
                depreciationPercent
                depreciationAmount
                depreciationCount
                accumulatedDepreciation
                depreciatedPurchasedPrice
                nextDepreciationDate
            }
        }
    `;

    static GET_VEHICLE_CUSTOM_FIELDS = gql`
        query getCustomFieldsInventory($stockNumber: String!) {
            getCustomFieldsInventory(stockNumber: $stockNumber) {
                entityMetadataId
                label
                type
                value
                options {
                    entityMetadataOptionId
                    option
                }
            }
        }
    `;

    static GET_DISTINCT_VALUES_BY_COLUMN = gql`
        query getDistinctValuesByColumn(
            $columnName: String!,
            $search: String,
            $onlyActive: Boolean!,
            $lots: [String],
            $paginate: DataPaging,
            $criteria: [criteriaInput],
            ) {
            getDistinctValuesByColumn(
                columnName: $columnName,
                search: $search,
                onlyActive: $onlyActive,
                lots: $lots,
                paginate: $paginate,
                criteria: $criteria,
                ) {
                values
                totalCount
            }
        }
    `;

    static GET_DISTINCT_VALUES_BY_COLUMN_NO_COUNT = gql`
        query getDistinctValuesByColumn(
            $columnName: String!,
            $search: String,
            $onlyActive: Boolean!,
            $lots: [String],
            $paginate: DataPaging,
            $criteria: [criteriaInput],
            ) {
            getDistinctValuesByColumn(
                columnName: $columnName,
                search: $search,
                onlyActive: $onlyActive,
                lots: $lots,
                paginate: $paginate,
                criteria: $criteria,
                ) {
                values
            }
        }
    `;

    static GET_INVENTORY_METADATA = gql`
        {
            getInventoryMetadata {
                entityMetadataId
                property
                label
                type
                displayed
                hasData
            }
        }
    `;

    static GET_INSPECTION_CATEGORIES = gql`
        query getInspectionCategories($activeItems: Boolean) {
            getInspectionCategories(activeItems: $activeItems) {
                reconInspectionCategoryId
                name
                order
                active
                items {
                    reconInspectionItemId
                    name
                    jobTypeIds
                    active
                }
            }
        }
    `;

    static GET_RECON_INSPECTORS = gql`
        query getReconInspectors {
            getReconInspectors {
                userID
                firstName
                lastName
                userName
                default
            }
        }
    `;

    static GET_INSPECTION_LIST_ITEMS = gql`
        query getInspectionListItems($stockNumber: Int!) {
            getInspectionListItems(stockNumber: $stockNumber) {
                reconInspectionCategoryId
                name
                order
                active
                items {
                    reconInspectionItemId
                    name
                    active
                    current {
                        vehicleInspectionItemId
                        pass
                        fail
                        part
                        repair
                        estimation
                        comment
                        active
                    }
                    images
                }
            }
        }
    `;

    static GET_RECON_STATUS = gql`
        query getReconStatus($stockNumber: Int!) {
            getReconStatus(stockNumber: $stockNumber) {
                status
                note
                currentInspector {
                    userID
                    firstName
                    lastName
                    userName
                }
                inspectors {
                    userID
                    firstName
                    lastName
                    userName
                    default
                }
            }
        }
    `;

    static GET_VEHICLE_RECON_ITEM_IMAGES = gql`
        query getVehicleReconItemImages($vehicleInspectionItemId: Int!) {
            getVehicleReconItemImages(vehicleInspectionItemId: $vehicleInspectionItemId) {
                vehicleInspectionItemImageId
                imageURL
            }
        }
    `;

    static GET_REPAIR_ITEMS = gql`
        query getRepairItems($stockNumber: Int!) {
            getRepairItems(stockNumber: $stockNumber) {
                vehicleRepairItemId
                order
                partStatus
                assigneeId
                vendorId
                startOn
                completeOn
                inspectionItem {
                    vehicleInspectionItemId
                    pass
                    fail
                    part
                    repair
                    estimation
                    comment
                    active
                    images
                    reconItem {
                        reconInspectionItemId
                        name
                        active
                    }
                }
            }
        }
    `;

    static GET_ASSIGNEES_FOR_REPAIR = gql`
        query getAssigneesForRepair {
            getAssigneesForRepair {
                id
                type
                name
            }
        }
    `;

    static GET_RECON_APPROVALS = gql`
        query getReconApprovals($paginate: DataPaging!, $sort: DataSort, $search: String, $filters: [ColumnFilter]) {
            getReconApprovals(paginate: $paginate, sort: $sort, search: $search, filters: $filters) {
                stockNumber
                thumbnail
                alternativeThumbnail
                year
                make
                model
                trim
                vin
                style
                maximumReconCost
                defaultReconCost
                reconNote
                reconStatus
                reconCurrentInspector
                approver
                part
                totalEstimate
            }
        }
    `;

    static GET_PARTS_LIST = gql`
        query getPartsList($paginate: DataPaging!, $sort: DataSort, $search: String, $filters: [ColumnFilter]) {
            getPartsList(paginate: $paginate, sort: $sort, search: $search, filters: $filters) {
                vehicleRepairItemId
                vehicleInspectionItemId
                name
                stockNumber
                thumbnail
                alternativeThumbnail
                year
                make
                model
                trim
                vin
                style
                approver
                comment
                estimation
                partStatus
                partStatusModifiedOn
                partStatusETA
                reconStatus
                images
            }
    }
    `;

static GET_PARTS_REQUEST_LIST = gql`
query getPartsRequestList($paginate: DataPaging!, $sort: DataSort, $search: String, $filters: [ColumnFilter]) {
    getPartsRequestList(paginate: $paginate, sort: $sort, search: $search, filters: $filters) {
        vehicleRepairItemId
        stockNumber
        thumbnail
        alternativeThumbnail
        year
        make
        model
        trim
        vin
        style
        roInvoiceNumber
        partDescription
        partComment
        partStatus
        partStatusModifiedOn
        partStatusETA
        images
    }
}`;

    static PULL_MARKET_COMPS_VEHICLES = gql`
        query pullMarketCompsVehicles(
            $distance: Int,
            $stockNumber: Int!,
            $year: [String!]!,
            $make: String!,
            $model: String!,
            $trim: String!,
            $months: Int,
            $lotName: String!,
            $notPullFromMarket: Boolean!,
            $isAppraisals: Boolean!
        ) {
            pullMarketCompsVehicles(
                distance: $distance,
                stockNumber: $stockNumber,
                year: $year,
                make: $make,
                model: $model,
                trim: $trim,
                months: $months,
                lotName: $lotName,
                notPullFromMarket: $notPullFromMarket,
                isAppraisals: $isAppraisals
            ) {
                isDistanceDisabled
                market {
                    heading
                    vin
                    year
                    make
                    model
                    trim
                    bodyStyle
                    transmission
                    drivetrain
                    engine
                    miles
                    sellingPrice
                    age
                    latitude
                    longitude
                    distance
                    sellerId
                    sellerName
                    sellerAddress
                    sellerCity
                    sellerZip
                    sellerState
                    sellerType
                    url
                    stock
                    availability
                    thumbnail
                }
                inventory {
                    stockNumber
                    vin
                    year
                    make
                    model
                    trim
                    engine
                    transmission
                    style
                    drivetrain
                    miles
                    age
                    lotName
                    internetPrice
                    thumbnail
                    alternativeThumbnail
                    soldDate
                    dealType
                    active
                }
                decodedModel
                decodedTrim
            }
        }
    `;

    static GET_FILTER_CRITERIA = gql`
        query getFilterCriteria($stockNumber: Int!) {
            getFilterCriteria(stockNumber: $stockNumber) {
                stockNumber
                criteria
            }
        }
    `;

    static DECODE_VIN_BASIC_SPECS = gql`
        query decodeVinBasicSpecs($vin: String!) {
            decodeVinBasicSpecs(vin: $vin) {
                isValid
                year
                make
                model
                trim
                bodyStyle
                engines
                transmission
                drivetrain
                cityMPG
                highwayMPG
            }
        }
    `;

    static PULL_APPRAISAL_DATA = gql`
        query pullAppraisalData($vin: String!) {
            pullAppraisalData(vin: $vin) {
                appraisalListId
                customer {
                    customerId
                    firstName
                    lastName
                    cellPhone
                    email
                }
                vin
                year
                make
                model
                trim
                miles
                suggestedPrice
                marketPercentage
                repairs
                markUp
                appraisedValue
                notes
                purchased
                wholesale
                createdOn
                modifiedOn
                images
            }
        }
    `;

    static PULL_APPRAISAL_IMAGES = gql`
        query pullAppraisalImages($appraisalListId: Int!) {
            pullAppraisalImages(appraisalListId: $appraisalListId) {
                appraisalListImageId
                imageURL
            }
        }
    `;

    static GET_MONRONEY_WINDOW_STICKER = gql`
        query getMonroneyWindowSticker($vin: String!) {
            getMonroneyWindowSticker(vin: $vin) {
                pdf
                jpg
                mobile
            }
        }
    `;

    static PULL_CARGURUS_DATA = gql`
        query pullCarGurusData($stockNumber: Int, $manualInput: CarGurusManualInput) {
            pullCarGurusData(stockNumber: $stockNumber, manualInput: $manualInput) {
                imv
                rating
                ratingBoundaries {
                    greatPrice
                    goodPrice
                    fairPrice
                    highPrice
                }
                comparableCars {
                    year
                    trim
                    miles
                    normalizedPrice
                    isCertified
                }
                rank
            }
        }
    `;

    static GET_AUTOCHECK_REPORT = gql`
        query getAutocheckReport($vin: String!) {
            getAutocheckReport(vin: $vin)
        }
    `;

    static PULL_AVAILABLE_DOCUMENTS = gql`
        query pullAvailableDocuments($stockNumber: Int!) {
            pullAvailableDocuments(stockNumber: $stockNumber) {
                documentId
                documentUrl
                documentSize
                referenceType
                createdOn
            }
        }
    `;

    static GET_DOCUMENT_UPLOAD_SIGNED_URL = gql`
        query getDocumentUploadSignedURL($stockNumber: Int!, $fileName: String!) {
            getDocumentUploadSignedURL(stockNumber: $stockNumber, fileName: $fileName) {
                url
                path
            }
        }
    `;

    static GET_DOCUMENT_SECURE_URL = gql`
        query getDocumentSecureURL($path: String!) {
            getDocumentSecureURL(path: $path)
        }
    `;

    static PULL_MATCHING_VEHICLES_DESKING = gql`
        query pullMatchingVehiclesInDesking(
            $lotIds: [Int!]!,
            $searchPrice: String!,
            $downPayment: Int!,
            $payment: Int!,
            $paymentWithin: Int!,
            $paymentFrequency: String!,
            $term: Int!,
            $annualRate: Float!,
            $year: Int,
            $miles: Int,
            $sort: DataSort!,
            $filters: [ColumnFilter]
        ) {
            pullMatchingVehiclesInDesking(
                lotIds: $lotIds,
                searchPrice: $searchPrice,
                downPayment: $downPayment,
                payment: $payment,
                paymentWithin: $paymentWithin,
                paymentFrequency: $paymentFrequency,
                term: $term,
                annualRate: $annualRate,
                year: $year,
                miles: $miles,
                sort: $sort,
                filters: $filters
            ) {
                stockNumber
                year
                make
                model
                style
                miles
                internetPrice
                stickerPrice
                payment
                financeAmount
                lotName
            }
        }
    `;

    static PULL_SENTENCES_FOR_DESCRIPTION = gql`
        query pullSentencesForDescription {
            pullSentencesForDescription {
                id
                sentence
                order
                isParagraph
                active
            }
        }
    `;

    static GET_CARFAX_URL = gql`
        query getCarfaxURL($vin: String!) {
            getCarfaxURL(vin: $vin)
        }
    `;

    static GET_VEHICLE_BLOCKING_STATUS = gql`
        query getVehicleRecordBlockingStatus(
            $stockNumber: Int!
        ) {
            getVehicleRecordBlockingStatus(
                stockNumber: $stockNumber
            ) {
                isEditing
                editingById
                editingBy
                stockNumber
            }
        }
    `;

    static PULL_DEMOS = gql`
        query pullDemos(
            $stockNumber: Int!,
            $onlyLatest: Boolean!,
            $sort: DataSort!
        ) {
            pullDemos(
                stockNumber: $stockNumber,
                onlyLatest: $onlyLatest,
                sort: $sort
            ) {
                showedDate
                leadSource
                dealType
                lastResults
                lostReason
                obstacleToClose
            }
        }
    `;

    static PULL_NOTES = gql`
        query pullNotes(
            $stockNumber: Int!
        ) {
            pullNotes(
                stockNumber: $stockNumber
            ) {
                notes
                marketingNotes
                marketCompsNotes
            }
        }
    `;

    static getPullInventorySchema = (columns) => gql`
        query pullInventory(
            $search: String,
            $active: Boolean!,
            $lots: [String!],
            $paginate: DataPaging,
            $sort: DataSort!,
            $filters: [InventoryListFilter!]
        ) {
            pullInventory(
                search: $search,
                active: $active,
                lots: $lots,
                paginate: $paginate,
                sort: $sort,
                filters: $filters
            ) {
                ${columns.join('\n')}
            }
        }
    `;

    static GET_DEALER_SPECIFIC_MAKES = gql`
        query getDealerSpecificMakesList {
            getDealerSpecificMakesList {
                id
                name
            }
        }
    `;

    static GET_VEHICLE_LIEN_HOLDER = gql`
        query getVehicleLienHolder($stockNumber: Int!) {
            getVehicleLienHolder(stockNumber: $stockNumber) {
                dealVehicleTradeID
                name
                address
                city
                state
                zip
                county
                phone
                notes
                payOff
                payOffGoodTill
            }
        }
    `;
}

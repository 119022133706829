import { gql } from '@apollo/client';

export default class OpportunitySubscription {
    static OPPORTUNITY_LIST_UPDATED = gql`
        subscription opportunityListUpdated($filters: CRMFilterInput, $lots: [Int]!) {
            opportunityListUpdated(filters: $filters, lots: $lots) {
                type
                opportunity {
                    crmId
                    leadCode
                    leadSource
                    leadSourceId
                    leadType
                    stockNumber
                    vehicleYear
                    vehicleMake
                    vehicleModel
                    vehicleTrim
                    closedDate
                    lastAction
                    notes
                    prospect {
                        customerCode
                        firstName
                        lastName
                        cellPhone
                        email
                    }
                    coProspect {
                        customerCode
                        firstName
                        lastName
                    }
                    vehicle {
                        style
                        thumbnail
                    }
                    processStage
                    lastResults
                    noDeal
                    lotName
                    recordManager
                    appointmentSalesman
                    recordManagerId
                    salespersonId
                    modifiedOn
                    createdOn
                    status
                    tags {
                        name
                        tagId
                        color
                    }
                    automatedResponseType
                    sold
                    showed
                    showedDate
                    prospectLeftDate
                    demo
                    writeUp
                    finance
                }
            }
        }
    `;

    static OPPORTUNITY_UPDATED = gql`
        subscription opportunityUpdated($crmId: ID!) {
            opportunityUpdated(crmId: $crmId) {
                leadCode
                leadSource
                leadType
                processStage
                dealType
                prospect {
                    customerId
                    customerCode
                    email
                    cellPhone
                    firstName
                    lastName
                    language
                    allowTextCellPhone
                }
                vehicle {
                    stockNumber
                    vin
                    year
                    make
                    model
                    trim
                    extColor
                    thumbnail
                    miles
                    stickerPrice
                    internetPrice
                }
                availableCash
                vehicleYear
                vehicleMake
                vehicleModel
                vehicleTrim
                vehiclePrice
                tradeVin
                tradeYear
                tradeMake
                tradeModel
                tradeTrim
                tradeMiles
                tradeACV
                tradePayoff
                tradePayoffDate
                tradeLeinHolder
                recordManager
                appointmentDate
                appointmentSalesman
                noDeal
                createdOn
                modifiedOn
                status
                crmId
                recordManagerId
                salespersonId
                reason
                leadSourceId
                lotId
                lotName
                obstacleToCloseId
                chosenVehiclePrice
                tags {
                    name
                    tagId
                    color
                }
                sold
                showed
                showedDate
                prospectLeftDate
                demo
                writeUp
                finance
                lastResults
            }
        }
    `;

    static PROSPECT_OR_CO_PROSPECT_CHANGED = gql`
        subscription prospectOrCoProspectChanged($crmId: ID!) {
            prospectOrCoProspectChanged(crmId: $crmId) {
                type
                prospect {
                    customerCode
                    customerId
                    firstName
                    middleName
                    lastName
                    email
                    cellPhone
                    homePhone
                    workPhone
                    dob
                    ssn
                    dln
                    isBusiness
                    allowCallCellPhone
                    allowTextCellPhone
                    allowCallHomePhone
                    allowCallWorkPhone
                    currentAddress {
                        customerAddressId
                        address1
                        address2
                        state
                        city
                        county
                        country
                        zipCode
                        phone
                        housingStatus
                        mortgageOrRent
                        totalMonths
                        start
                        end
                    }
                    currentEmployment {
                        customerEmploymentId
                        employer
                        title
                        totalMonths
                        monthlyIncome
                        workPhone
                        otherIncomeMonthly
                        otherIncomeSource
                        address1
                        address2
                        city
                        state
                        zipCode
                        county
                        country
                        status
                    }
                }
            }
        }
    `;
}

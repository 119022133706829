export default {
    INVENTORY_READ: 'inventory:read',
    INVENTORY_VEHICLE_WRITE: 'inventory.vehicle:write',
    INVENTORY_VEHICLE_READ: 'inventory.vehicle:read',
    INVENTORY_VEHICLE_COST_READ: 'inventory.vehicle.cost:read',
    INVENTORY_VEHICLE_TAG_WRITE: 'inventory.vehicle.tag:write',
    INVENTORY_SETTINGS_READ: 'inventory.settings:read',
    INVENTORY_SETTINGS_WRITE: 'inventory.settings:write',
    INVENTORY_SETTINGS_TAG_ADD: 'inventory.settings.tag:add',
    INVENTORY_SETTINGS_TAG_DELETE: 'inventory.settings.tag:delete',
    RECON_SETTINGS_READ: 'recon.settings:read',
    RECON_SETTINGS_WRITE: 'recon.settings:write',
    INVENTORY_RECON_READ: 'inventory.recon:read',
    INVENTORY_RECON_WRITE: 'inventory.recon:write',
    INVENTORY_RECON_APPROVE: 'inventory.recon:approve',
    INVENTORY_MARKETCOMPS_READ: 'inventory.marketcomps:read',
    INVENTORY_MARKETCOMPS_WRITE: 'inventory.marketcomps:write',
    INVENTORY_APPRAISALS_READ: 'inventory.appraisals:read',
    INVENTORY_APPRAISALS_WRITE: 'inventory.appraisals:write',
    INVENTORY_DOCUMENTS_READ: 'inventory.documents:read',
    INVENTORY_DOCUMENTS_WRITE: 'inventory.documents:write',
    INVENTORY_DOCUMENTS_DELETE: 'inventory.documents:delete',
};

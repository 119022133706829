import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import update from 'immutability-helper';
import {
    makeStyles, Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import ButtonStyles from 'styles/theme/Button';
import Permissions from 'utils/enum/Permissions';
import DeleteIcon from '@material-ui/icons/Delete';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import LostReasonQuery from 'services/graphQL/query/setting/LostReasonQuery';
import LostReasonMutation from 'services/graphQL/mutate/setting/LostReasonMutation';
import LostReasonDialog from 'components/modules/settings/widgets/lostReason/LostReasonDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& > button': {
            marginRight: theme.spacing(1),
            width: '95px',
        },
    },
    tableHeight: {
        display: 'contents',
    },
    ...ButtonStyles.getStyle(theme),
}));
const keyStore = new KeyStore();
const ACTION_TYPE = {
    SET_RECORDS: 'setRecords',
    ON_OPEN_DIALOG: 'onOpenDialog',
    ON_CLOSE_DIALOG: 'onCloseDialog',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_SET_SELECT_RECORD: 'onSetSelectRecord',
    TOGGLE_DIALOG_CONFIRM: 'toggleDialogConfirm',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_RECORDS:
        return update(state, {
            records: { $set: action.payload },
        });
    case ACTION_TYPE.ON_SET_SELECT_RECORD:
        return update(state, {
            selectedRow: { $set: action.payload.record },
            open: { $set: action.payload.open },
            isEditing: { $set: action.payload.isEditing },
        });
    case ACTION_TYPE.ON_CLOSE_DIALOG:
        return update(state, {
            open: { $set: false },
            selectedRow: { $set: null },
            isEditing: { $set: false },
        });
    case ACTION_TYPE.ON_OPEN_DIALOG:
        return update(state, {
            open: { $set: true },
            isEditing: { $set: action.payload?.isEditing || false },
        });
    case ACTION_TYPE.TOGGLE_DIALOG_CONFIRM:
        if (action.payload) {
            return update(state, {
                confirmDialogOpen: { $set: action.payload },
            });
        }

        return update(state, {
            confirmDialogOpen: { $set: action.payload },
            selectedRow: { $set: null },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.payload },
            selectedRow: { $set: null },
            records: { $set: [] },
        });
    default:
        return state;
    }
};

const LostReason = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        records: [],
        open: false,
        active: true,
        selectedRow: null,
        confirmDialogOpen: false,
    });
    const CRM_SETTING_LOST_REASON_WRITE = keyStore.hasPermission(Permissions.CRM_SETTING_LOST_REASON_WRITE);
    const [deleteLostReason, { loading: deleting }] = useMutation(LostReasonMutation.DELETE_LOST_REASON);
    // TODO: Change this in the next update (remove refetch function)
    const {
        data, error, loading, refetch,
    } = useQuery(LostReasonQuery.GET_LOST_REASON_LIST, {
        variables: { filters: { active: state.active } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loading && data?.getLostReasonList) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data.getLostReasonList,
            });
        }
    }, [data, loading]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    const onChangeValue = (field, payload) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            field,
            payload,
        });
    };

    const toggleDialogConfirm = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_DIALOG_CONFIRM,
            payload: !state.confirmDialogOpen,
        });
    };

    const onSelectRow = (selectedRow) => {
        dispatch({
            type: ACTION_TYPE.ON_SET_SELECT_RECORD,
            payload: {
                record: selectedRow,
            },
        });
    };

    const onClose = () => {
        dispatch({
            type: ACTION_TYPE.ON_CLOSE_DIALOG,
        });
    };

    const onEdit = () => {
        dispatch({
            type: ACTION_TYPE.ON_OPEN_DIALOG,
            payload: {
                isEditing: true,
            },
        });
    };

    const onCreate = () => {
        dispatch({
            type: ACTION_TYPE.ON_OPEN_DIALOG,
        });
    };

    const onDelete = async () => {
        try {
            const { lostReasonId } = state.selectedRow;
            const response = await deleteLostReason({ variables: { lostReasonId } });

            if (response.data?.deleteLostReason) {
                toggleDialogConfirm();
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const getColumns = () => [
        {
            Header: 'Lost Reason Id',
            id: 'lostReasonId',
            accessor: 'lostReasonId',
            className: 'd-flex-justify-center-align-center',
            minWidth: 20,
        },
        {
            Header: 'Description',
            id: 'description',
            accessor: 'description',
            minWidth: 120,
        },
        {
            Header: 'Created On',
            id: 'createdOn',
            accessor: 'createdOn',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
            width: 150,
            minWidth: 150,
        },
        {
            Header: 'Modified On',
            id: 'modifiedOn',
            accessor: 'modifiedOn',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
            width: 150,
            minWidth: 150,
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {CRM_SETTING_LOST_REASON_WRITE && (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={onCreate}
                            className={clsx(classes.containedGreen)}
                            startIcon={<AddIcon />}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={onEdit}
                            className={clsx(classes.containedInfo)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={isEmpty(state.selectedRow)}
                        >
                            Edit
                        </Button>
                        {state.active
                            && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={toggleDialogConfirm}
                                    className={clsx(classes.containedError)}
                                    startIcon={<DeleteIcon />}
                                    disabled={isEmpty(state.selectedRow)}
                                >
                                    Delete
                                </Button>
                            )}
                    </>
                )}
                <div className="d-flex-center">
                    <Form.Group
                        className="form-check form-check-inline mb-0"
                    >
                        <Form.Check
                            key="radioActive"
                            type="radio"
                            name="radio"
                            id="radioActive"
                            label="Active"
                            value={state.active}
                            checked={state.active}
                            onChange={() => onChangeValue('active', true)}
                        />
                        <Form.Check
                            key="radioInactive"
                            type="radio"
                            name="radio"
                            id="radioInactive"
                            label="In-Active"
                            value={!state.active}
                            checked={!state.active}
                            onChange={() => onChangeValue('active', false)}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className={classes.tableHeight}>
                <Table
                    cursor="default"
                    totalRecords={state.records.length}
                    data={state.records}
                    load={loading}
                    columns={getColumns()}
                    sortable
                    getTrProps={(_, rowInfo) => {
                        const record = rowInfo.original;
                        const selected = (!state.selectedRow ? false : state.selectedRow.lostReasonId === record.lostReasonId);
                        return {
                            onDoubleClick: onEdit,
                            onClick: () => onSelectRow(record),
                            className: selected ? 'active' : '',
                        };
                    }}
                />
            </div>
            {state.open && (
                <LostReasonDialog
                    open={state.open}
                    onClose={onClose}
                    refetch={refetch}
                    record={state.isEditing ? state.selectedRow : null}
                />
            )}
            {state.confirmDialogOpen && (
                <ConfirmDialog
                    title="Confirm delete lost reason"
                    description="Are you sure you want to remove this lost reason?"
                    open={state.confirmDialogOpen}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    dividerFooter={false}
                    onClose={toggleDialogConfirm}
                    onClickSecondary={toggleDialogConfirm}
                    onClickPrimary={onDelete}
                    disablePrimaryButton={deleting}
                />
            )}
        </div>
    );
};

export default LostReason;

/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

const VendorSettingSchema = yup.object().shape({
    vendorName: yup.string().required(),
    vendorType: yup.string().required(),
});

export {
    VendorSettingSchema,
};

import { gql } from '@apollo/client';

export default class ProductMutate {
    static CREATE_PRODUCT_VENDOR = gql`
        mutation addProduct($input: ProductInput) {
            addProduct(input: $input){
                productId
            }
        }
    `;

    static UPDATE_PRODUCT_VENDOR = gql`
        mutation updateProduct(
            $id: Int!
            $input: ProductInput
        ) {
            updateProduct(id: $id, input: $input){
                productId
            }
        }
    `;

    static UPLOAD_CONTRACT = gql`
        mutation uploadContract(
            $vendorName: String!
            $file: Upload!
        ) {
            uploadContract(vendorName: $vendorName, file: $file)
        }
    `;
}

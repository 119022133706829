import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Bootstrap
import { Tabs, Tab } from 'react-bootstrap';

// Components and Others
import PropTypes from 'prop-types';
import TabStyles from 'styles/widgets/tabs/TabsStyles';
import TabContainer from 'components/widgets/tabs/TabContainer';
import InsuranceTab from 'components/modules/deals/read/buyer/InsuranceTab';
import ReferenceTab from 'components/modules/deals/read/buyer/ReferenceTab';
import InternalBuyerTab from 'components/modules/deals/read/buyer/InternalBuyerTab';

const styles = (theme) => TabStyles.tabPanel(theme);

class BuyerContentTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(key) {
        this.setState({
            value: key,
        });
    }

    render() {
        const { value } = this.state;
        const {
            clientId, classes, accountNumber, editingMode, onChangeEditingMode, editingStatus,
        } = this.props;

        return (
            <TabContainer>
                <Tabs
                    id="controlled-tab-example"
                    mountOnEnter
                    className={classes.navbar}
                    activeKey={value}
                    onSelect={this.handleChange}
                >
                    <Tab className={classes.tab} eventKey={0} title="Buyer">
                        <InternalBuyerTab
                            clientId={clientId}
                            editingMode={editingMode}
                            editingStatus={editingStatus}
                            accountNumber={accountNumber}
                            onChangeEditingMode={onChangeEditingMode}
                        />
                    </Tab>
                    <Tab className={classes.tab} eventKey={1} title="Insurance">
                        <InsuranceTab
                            editingMode={editingMode}
                            accountNumber={accountNumber}
                            onChangeEditingMode={onChangeEditingMode}
                        />
                    </Tab>
                    <Tab className={classes.tab} eventKey={2} title="References">
                        <ReferenceTab
                            dealId={accountNumber}
                        />
                    </Tab>
                </Tabs>
            </TabContainer>
        );
    }
}

BuyerContentTabs.propTypes = {
    accountNumber: PropTypes.number.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    clientId: PropTypes.string.isRequired,
    editingStatus: PropTypes.object,
};

BuyerContentTabs.defaultProps = {
    editingStatus: {
        isEditing: false,
        editingById: null,
        editingBy: null,
    },
};

export default withStyles(styles)(BuyerContentTabs);

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import Search from 'components/widgets/Search';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CustomerDialogContainer from 'components/modules/customer/read/CustomerDialogContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CustomerType } from 'utils/enum/DealEnum';

const styles = (theme) => ({
    ...DealStyles.dialogSearch(theme),
    textAdd: {
        color: theme.palette.text.infoDark,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        marginLeft: theme.spacing(1),
    },
    text: {
        marginLeft: theme.spacing(1),
    },
    boxSearch: {
        marginBottom: theme.spacing(1.8),
        display: 'flex',
    },
    searchField: {
        flexGrow: 9,
    },
});

class CustomerDialog extends Component {
    getColumns() {
        const columnStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        return [
            {
                Header: 'Customer Code',
                minWidth: 80,
                accessor: 'customerCode',
                style: columnStyle,
            },
            {
                Header: 'First Name',
                minWidth: 80,
                accessor: 'firstName',
                style: columnStyle,
            },
            {
                Header: 'Middle Name',
                minWidth: 80,
                accessor: 'middleName',
                style: columnStyle,
            },
            {
                Header: 'Last Name',
                minWidth: 80,
                accessor: 'lastName',
                style: columnStyle,
            },
            {
                Header: 'Address',
                minWidth: 140,
                accessor: 'currentAddress.address1',
                style: columnStyle,
            },
            {
                Header: 'Cell Phone',
                minWidth: 75,
                accessor: 'cellPhone',
                style: columnStyle,
            },
            {
                Header: 'Email',
                minWidth: 120,
                accessor: 'email',
                style: columnStyle,
            },
        ];
    }

    render() {
        const {
            props: {
                toggleModal, open, classes,
                onSearch, isLoading, loadMore,
                totalCount, records, onSelectRow,
                onClickRecord, disablePrimaryButton,
                selectedRecord, onClickPrimary,
                title, showAddNewCustomerButton,
                onClickNewCustomerButton, toggleCreateCustomerPanel,
                customerTag,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: classes.dialog,
                }}
            >
                <DialogAppBar title={title} onClose={toggleModal} titleVariant="subtitle1" toolbarSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.boxSearch}>
                        <Search autoFocus fullWidth onKeyDown={onSearch} className={classes.searchField} />
                        { toggleCreateCustomerPanel
                            && (
                                <Typography className={classes.textAdd} onClick={toggleCreateCustomerPanel}>
                                    <AddCircleOutlineIcon
                                        fontSize="small"
                                    />
                                    <span className={classes.text}>{`Add ${(customerTag === CustomerType.BUYER ? 'Buyer' : 'Co Buyer')}`}</span>
                                </Typography>
                            )}
                    </div>
                    <Table
                        rowSelected
                        load={isLoading}
                        loadMore={loadMore}
                        totalRecords={totalCount}
                        data={records}
                        columns={this.getColumns()}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onDoubleClick: () => {
                                    onSelectRow(record);
                                },
                                onClick: () => {
                                    onClickRecord(record);
                                },
                            };
                        }}
                    />
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={toggleModal}
                    onClickPrimary={onClickPrimary}
                    disablePrimaryButton={!selectedRecord || disablePrimaryButton}
                    useAdditionalButton={showAddNewCustomerButton}
                    titleAdditional="New Customer"
                    onClickAdditional={onClickNewCustomerButton}
                />
            </Dialog>
        );
    }
}

CustomerDialog.propTypes = {
    onClickPrimary: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSelectRow: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    disablePrimaryButton: PropTypes.bool,
    selectedRecord: PropTypes.object,
    title: PropTypes.string,
    showAddNewCustomerButton: PropTypes.bool,
    onClickNewCustomerButton: PropTypes.func,
    toggleCreateCustomerPanel: PropTypes.func,
    customerTag: PropTypes.string,
};

CustomerDialog.defaultProps = {
    onClickPrimary: () => {},
    disablePrimaryButton: false,
    selectedRecord: null,
    title: 'Customers',
    showAddNewCustomerButton: false,
    onClickNewCustomerButton: () => {},
    toggleCreateCustomerPanel: null,
    customerTag: CustomerType.BUYER,
};

export default withStyles(styles)(CustomerDialogContainer(CustomerDialog));

import { useEffect, useState } from 'react';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';

const useAdvisorList = () => {
    const [advisors, setAdvisors] = useState([]);

    const { data, loading, error } = useQuery(ServiceQuery.GET_SERVICE_ADVISOR, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getServiceAdvisor } = data;
            const result = getServiceAdvisor.map((item) => ({
                value: item.advisor,
                label: item.advisor,
            }));
            setAdvisors(result);
        }
    }, [data, loading, error]);

    return {
        advisors,
        loading,
    };
};

export default useAdvisorList;

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import TabStyles from 'styles/widgets/tabs/TabsStyles';

const styles = () => TabStyles.tabContainer();

class TabContainer extends Component {
    render() {
        const { props: { children, classes, className } } = this;

        return (
            <div className={clsx(classes.root, className)}>
                {children}
            </div>
        );
    }
}

TabContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TabContainer.defaultProps = {
    className: '',
};

export default withStyles(styles)(TabContainer);

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import ServiceDefaultSettings from 'components/modules/settings/service/ServiceDefaultSettings';
import { TuneOutlinedIcon } from 'components/icons';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = [];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    if (keyStore.hasPermission(Permission.SERVICE_SETTINGS_READ)) {
        settings.push(
            {
                index: 0,
                text: 'Default Settings',
                icon: <TuneOutlinedIcon />,
                component: <ServiceDefaultSettings />,
            },
        );
        subTabs.push('default');
    }

    return settings;
};

const ServiceSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();

    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <>
            <Container className={classes.rootContainer}>
                <List component="nav" className={classes.sideBar}>
                    {settingsItems.map((item) => (
                        <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.content}>
                    {settingsItems.map((item) => (
                        activeIndex === item.index && <div key={item.index}>{item.component}</div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default ServiceSettings;

import React from 'react';
import PropTypes from 'prop-types';
import Search from 'components/widgets/Search';
import { makeStyles } from '@material-ui/core/styles';

// Style
import QueriesBaseLayoutStyles from 'styles/modules/queries/QueriesBaseLayoutStyles';

const useStyles = makeStyles((theme) => QueriesBaseLayoutStyles.childList(theme));

const ColumnList = ({ data, actionFilter }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.label}>
                Columns
            </div>
            <div className={classes.boxSearch}>
                <Search fullWidth onKeyDown={actionFilter} />
            </div>
            <div className={classes.list}>
                {data.map((column, index) => (
                    <div className={classes.listItem} key={index}>
                        {column}
                    </div>
                ))}
            </div>
        </div>
    );
};

ColumnList.defaultProps = {
    data: [],
};

ColumnList.propTypes = {
    data: PropTypes.array,
    actionFilter: PropTypes.func.isRequired,
};

export default ColumnList;

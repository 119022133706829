import SelectControl from 'components/widgets/editorControls/SelectControl';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import {
    Button, Grid, makeStyles, Divider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ModalUtils from 'utils/ModalUtils';
import { useMutation, useQuery } from '@apollo/client';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles(() => ({
    box: {
        paddingTop: '10px',
        display: 'block',
        marginLeft: '10px',
        border: 'solid 1px #e5e5e5',
    },
    labels: {
        fontSize: '0.875rem',
    },
}));

const keyStore = new KeyStore();

const CashierMappingsPaymentType = () => {
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    const classes = useStyles();

    const [record, setRecord] = useState({
        cashAccountId: 0, checkAccountId: 0, chargeAccountId: 0,
    });

    const handleEditorChange = (columnId, newValue) => {
        const newIntValue = Number(newValue);
        setRecord((item) => ({
            ...item,
            [columnId]: newIntValue,
        }));
    };

    const {
        data, loading, error,
    } = useQuery(AccountReceivableQuery.GET_CASHIER_MAPPING_DEBIT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getCashierMappingDebit } = data;
            setRecord(getCashierMappingDebit);
        }
    }, [data, loading, error]);

    const [saveData, { loading: updating }] = useMutation(AccountReceivableMutation.SAVE_CASHIER_MAPPING_DEBIT, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveCashierMappingDebit) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = () => {
        saveData({ variables: { data: record } });
    };

    return (
        <Grid item className={classes.box}>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Debit</Form.Label>
                <Divider />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Cash</Form.Label>
                <SelectControl
                    name="cashAccountId"
                    value={record.cashAccountId}
                    placeHolder="select an account"
                    onChange={handleEditorChange}
                    className={record.cashAccountId > 0 ? '' : 'invalid-field'}
                    dataSource={{
                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                        variables: {
                            paginate: {
                                init: 0,
                                ignoreLimit: true,
                            },
                        },
                        rootData: 'getAccountingCOAList.data',
                        idField: 'accountNumber',
                        descriptionField: 'fullDescription',
                        additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                    }}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Check</Form.Label>
                <SelectControl
                    name="checkAccountId"
                    value={record.checkAccountId}
                    placeHolder="select an account"
                    onChange={handleEditorChange}
                    className={record.checkAccountId > 0 ? '' : 'invalid-field'}
                    dataSource={{
                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                        variables: {
                            paginate: {
                                init: 0,
                                ignoreLimit: true,
                            },
                        },
                        rootData: 'getAccountingCOAList.data',
                        idField: 'accountNumber',
                        descriptionField: 'fullDescription',
                        additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                    }}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Charge</Form.Label>
                <SelectControl
                    name="chargeAccountId"
                    value={record.chargeAccountId}
                    placeHolder="select an account"
                    onChange={handleEditorChange}
                    className={record.chargeAccountId > 0 ? '' : 'invalid-field'}
                    dataSource={{
                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                        variables: {
                            paginate: {
                                init: 0,
                                ignoreLimit: true,
                            },
                        },
                        rootData: 'getAccountingCOAList.data',
                        idField: 'accountNumber',
                        descriptionField: 'fullDescription',
                        additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                    }}
                />
            </Form.Group>
            <Form.Group as={Col}>
                {ACCOUNTING_SETTINGS_WRITE && (
                    <Button
                        variant="outlined"
                        startIcon={<SaveOutlinedIcon />}
                        size="small"
                        disabled={updating}
                        onClick={onSave}
                    >
                        {updating ? 'Saving...' : 'Save'}
                        {updating && <CircularProgress size={20} />}
                    </Button>
                )}
            </Form.Group>
        </Grid>
    );
};

export default CashierMappingsPaymentType;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button,
    CircularProgress,
    FormLabel,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import VehicleImage from 'components/widgets/VehicleImage';
import useVinValidation from 'components/modules/inventory/hooks/useVinValidation';

const useStyles = makeStyles((theme) => ({
    content: {
        background: theme.palette.background.white,
        marginTop: 15,
        '& > div:first-child > *': {
            marginBottom: 15,
        },
        [theme.breakpoints.down('sm')]: {
            flex: 'initial',
        },
    },
    vinInput: {
        marginBottom: '10px !important',
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0px',
        marginBottom: '10px',
    },
}));

const VinDecoder = ({
    vinNumber,
    onChange,
    decodeVin,
    loading,
    vinWasDecoded,
    onClickManually,
}) => {
    const classes = useStyles();
    const {
        isInvalidVINLength,
        isInvalidVINChars,
        vinNumberLength,
        notAllowedCharsInVIN,
    } = useVinValidation('none', vinNumber);

    if (!vinWasDecoded) {
        const contentButton = (loading) ? <CircularProgress color="inherit" size={20} /> : 'Decode';

        return (
            <div
                className={
                    clsx(
                        'd-flex-justify-center-align-center',
                        'flex-1',
                        classes.content,
                    )
                }
            >
                <div className="d-flex-column-center">
                    <VehicleImage width="300px" />
                    <Tooltip
                        title="For vehicles made in 1981 or newer"
                        placement="right-start"
                    >
                        <Form.Control
                            className={classes.vinInput}
                            type="text"
                            placeholder="Enter VIN to decode"
                            maxLength={vinNumberLength}
                            value={vinNumber}
                            onChange={({ target }) => onChange('vinNumber', target.value)}
                        />
                    </Tooltip>
                    {vinNumber && isInvalidVINChars
                    && (
                        <Form.Text
                            className={classes.error}
                        >
                            {`The following characters are not allowed: ${notAllowedCharsInVIN.toString().toUpperCase()}`}
                        </Form.Text>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isInvalidVINLength || isInvalidVINChars || loading}
                        onClick={decodeVin}
                    >
                        { contentButton }
                    </Button>
                    <FormLabel className="text-bold">OR</FormLabel>
                    <Button
                        onClick={onClickManually}
                    >
                        Enter information manually
                    </Button>
                </div>
            </div>
        );
    }

    return null;
};

VinDecoder.propTypes = {
    vinNumber: PropTypes.string,
    loading: PropTypes.bool,
    vinWasDecoded: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    decodeVin: PropTypes.func.isRequired,
    onClickManually: PropTypes.func.isRequired,
};

VinDecoder.defaultProps = {
    vinNumber: '',
    loading: false,
    vinWasDecoded: false,
};

export default VinDecoder;

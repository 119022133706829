import React, { useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Typography, Grid, Checkbox, Tooltip,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Components and Others
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import LeadHelper from 'components/modules/lead/LeadHelper';
import { LeadTypeStyle } from 'styles/modules/crm/LeadStyles';
import ProcessStage from 'components/modules/crm/widgets/ProcessStage';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import ProcessStageDialog from 'components/modules/crm/create/ProcessStageDialog';

// Helpers
import NumberUtils from 'lib/NumberUtils';

// Utility packages
import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import Permissions from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import CustomTags from 'components/widgets/tag/CustomTags';
import TagMutation from 'services/graphQL/mutate/crm/TagMutation';
import OpportunityForm from 'components/modules/crm/create/OpportunityForm';
import { OpportunityStatus } from 'components/modules/crm/enum/Opportunity';

const keyStore = new KeyStore();
const useStyles = makeStyles((theme) => ({
    paper: {
        background: theme.palette.background.white,
        padding: theme.spacing(2),
    },
    noWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    alignRight: {
        textAlign: 'right',
    },
    inlineBlock: {
        display: 'inline-block',
    },
    selfCenter: {
        alignSelf: 'center',
    },
    top: {
        minHeight: '32px',
    },
    customer: {
        color: theme.palette.text.butterflyBush,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dataItem: {
        display: 'flex',
        padding: '5px 0px',
        '& > dt': {
            minWidth: '150px',
            textAlign: 'right',
        },
    },
    statusWrapper: {
        justifyContent: 'center',
    },
    label: {
        color: theme.palette.text.minsk,
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        marginRight: theme.spacing(1),
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
    },
    processStageWrapper: {
        margin: '10px 0px',
    },
    separator: {
        margin: '10px 0px',
        borderTopColor: 'transparent',
        borderBottomColor: theme.palette.border.secondary,
        borderLeft: 'none',
        borderRight: 'none',
    },
    // Specific values
    status: {
        fontSize: '13px',
        textTransform: 'uppercase',
        borderRadius: '4px',
        padding: `0px ${theme.spacing(1)}px`,
        '&.open': {
            color: theme.palette.text.white,
            background: '#455fea',
        },
        '&.sold': {
            color: theme.palette.text.white,
            background: '#40a63b',
        },
        '&.lost': {
            color: theme.palette.text.white,
            background: '#f44336',
        },
    },
    cellPhone: {
        width: '100px',
    },
    date: {
        display: 'inline-block',
        minWidth: '150px',
    },
    checkbox: {
        padding: '0px',
        verticalAlign: 'top',
    },
    lotTag: {
        color: '#1e88e5',
        padding: '2px 5px',
        background: '#F4F5FD',
        borderRadius: '2px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },
    containerStage: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        flex: 1,
        '&& dd': {
            width: 'calc(100% - 42px)',
        },
        '&& button': {
            position: 'absolute',
            right: 0,
            minWidth: 46,
        },
    },
    lastResults: {
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden',
    },
    ...LeadTypeStyle,
}));

const LeadInfo = ({
    data,
    loading,
    crmId,
    updateLanguage,
    obstacles,
    refetchAutomatedResponseStatus,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openDialog, toggleDialog] = useState(false);
    const { prospect = {}, status } = data;
    const placeholder = '--';
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_WRITE);
    const name = prospect.firstName != null ? `${prospect.firstName || ''} ${prospect.lastName || ''}` : 'Prospect';
    const downPayment = data.availableCash ?? 0;
    const createdOn = (data.createdOn != null ? DateUtils.calendarWithTime(data.createdOn) : placeholder);
    const leadType = LeadHelper.getLeadType(data?.leadType);
    const typeIcon = LeadHelper.getLeadTypeIcon(leadType);
    const [deleteTag] = useMutation(TagMutation.REMOVE_OPPORTUNITY_TAG);

    const onDeleteTag = async (record) => {
        try {
            const response = await deleteTag({ variables: { tagId: record.tagId, opportunityId: crmId } });
            if (!response.data?.removeOpportunityTag) {
                ModalUtils.errorMessage(null, 'Error ');
                return;
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    return (
        <div
            className={classes.paper}
        >
            <Grid container className={classes.top}>
                <Grid item xs={4} className={classes.selfCenter}>
                    <Typography variant="h4" className={classes.customer} title={name}>{name}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.selfCenter}>
                    <div className={clsx(classes.dataItem, classes.statusWrapper)}>
                        <span className={classes.label}>Status: </span>
                        <dd className={clsx(classes.value, { [classes.status]: (status != null) }, status?.toLowerCase())}>
                            {status || placeholder}
                        </dd>
                    </div>
                </Grid>
                <Grid item xs={4} className={clsx(classes.alignRight, classes.selfCenter)}>
                    <span className={classes.label}>Cell Phone: </span>
                    <span className={clsx(classes.value, classes.cellPhone)}>{prospect.cellPhone ? prospect.cellPhone : '000 000-0000'}</span>
                </Grid>
            </Grid>
            <div className={classes.processStageWrapper}>
                <ProcessStage data={data} />
            </div>
            <Grid container>
                <Grid item component="dl" xs={12}>
                    <div className={clsx('d-flex-justify-end', classes.dataItem)}>
                        <If condition={CRM_OPPORTUNITY_WRITE}>
                            <Button
                                color="primary"
                                size="small"
                                disabled={loading}
                                onClick={() => setOpen(true)}
                                startIcon={<BorderColorOutlinedIcon />}
                            >
                                Edit
                            </Button>
                        </If>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item component="dl" xs={6}>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Lead Source: </dt>
                        <dd className={clsx(classes.value, classes.noWrap)} title={data.leadSource}>{data.leadSource || placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Deal Type: </dt>
                        <dd className={classes.value}>{data.dealType || placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Lead Type: </dt>
                        <dd className={clsx(classes.value, classes.leadType, typeIcon.name)}>
                            {typeIcon.icon}
                            <span>{leadType || placeholder}</span>
                        </dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Down Payment: </dt>
                        <dd className={classes.value}>{NumberUtils.applyCurrencyFormat(downPayment, '$0,0')}</dd>
                    </div>
                </Grid>
                <Grid item component="dl" xs={6}>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Language: </dt>
                        <dd className={classes.value}>{prospect.language}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Record Manager: </dt>
                        <dd className={classes.value}>{data.recordManager || placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Sales Rep: </dt>
                        <dd className={classes.value}>{data.appointmentSalesman || placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Lot: </dt>
                        <Typography variant="h6" className={classes.value}><span className={classes.lotTag}>{data.lotName || placeholder}</span></Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item component="dl" xs={12}>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Last Results: </dt>
                        <dd className={clsx(classes.value, classes.lastResults)} title={data.lastResults}>{data.lastResults}</dd>
                    </div>
                </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container>
                <Grid item component="dl" xs={6}>
                    <div className={classes.dataItem}>
                        <dt className={clsx(classes.label, 'd-flex-justify-end-align-center')}>Process Stage: </dt>
                        <div className={classes.containerStage}>
                            <Tooltip title={data.processStage || placeholder}>
                                <dd className={clsx(classes.value, 'text-ellipsis')}>{data.processStage || placeholder}</dd>
                            </Tooltip>
                            <If condition={CRM_OPPORTUNITY_WRITE}>
                                <Button
                                    color="primary"
                                    size="small"
                                    disabled={loading || status?.toUpperCase() !== OpportunityStatus.OPEN.toUpperCase()}
                                    onClick={() => toggleDialog(true)}
                                >
                                    <BorderColorOutlinedIcon fontSize="small" />
                                </Button>
                            </If>
                        </div>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Obstacle to Close: </dt>
                        <dd className={classes.value}>{obstacles.find((o) => o.obstacleToCloseId === data.obstacleToCloseId)?.description ?? placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Lost Reason: </dt>
                        <dd className={classes.value}>{data.reason || placeholder}</dd>
                    </div>
                </Grid>
                <Grid item component="dl" xs={6}>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Created On: </dt>
                        <dd className={clsx(classes.value, classes.date)}>{!loading ? createdOn : <Skeleton variant="text" />}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Appointment: </dt>
                        <dd className={classes.value}>{data.appointmentDate ? DateUtils.calendarWithTime(data.appointmentDate) : placeholder}</dd>
                    </div>
                    <div className={classes.dataItem}>
                        <dt className={classes.label}>Confirmed: </dt>
                        <dd className={classes.value}><Checkbox className={classes.checkbox} size="small" disabled /></dd>
                    </div>
                </Grid>
            </Grid>
            <Grid item component="dl" xs={12}>
                <div className={clsx(classes.dataItem, 'd-flex-center')}>
                    <dt className={classes.label}>Tag: </dt>
                    <dd className={classes.value}><CustomTags onDelete={onDeleteTag} records={data.tags || []} recordId={crmId} /></dd>
                </div>
            </Grid>
            <If condition={open}>
                <OpportunityForm
                    open={open}
                    crmId={crmId}
                    record={data}
                    onClose={() => setOpen(false)}
                    updateLanguage={updateLanguage}
                    obstacles={obstacles}
                />
            </If>
            {openDialog && (
                <ProcessStageDialog
                    crmId={crmId}
                    open={openDialog}
                    value={data.processStage}
                    onClose={() => toggleDialog(false)}
                    refetchAutomatedResponseStatus={refetchAutomatedResponseStatus}
                />
            )}
        </div>
    );
};

LeadInfo.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    crmId: PropTypes.string.isRequired,
    updateLanguage: PropTypes.func.isRequired,
    refetchAutomatedResponseStatus: PropTypes.func,
    obstacles: PropTypes.array.isRequired,
};

LeadInfo.defaultProps = {
    refetchAutomatedResponseStatus: () => null,
};

export default LeadInfo;

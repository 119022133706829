import * as yup from 'yup';

const CashierPaymentSchema = yup.object().shape({
    transactionType: yup.string().required(),
    lotName: yup.string().required(),
    reason: yup.string().required(),
    dateNTime: yup.date().required(),
    cash: yup.number().required(),
    check: yup.number().required(),
    charge: yup.number().required(),
    cashiersCheck: yup.number().required(),
});

export default CashierPaymentSchema;

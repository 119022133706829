import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import EmploymentDialog from 'components/widgets/customer/EmploymentDialog';
import EmploymentContent from 'components/widgets/customer/EmploymentContent';
import EmploymentContainer from 'components/containers/customer/read/EmploymentContainer';
import ManageEmployment from 'components/modules/customer/read/personalTab/ManageEmployment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography } from '@material-ui/core';
import {
    BorderColorOutlined as BorderColorOutlinedIcon,
    Visibility as VisibilityOutlinedIcon,
} from '@material-ui/icons';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => ({
    employmentContent: {
        padding: theme.spacing(1),
    },
    addButton: {
        padding: theme.spacing(1),
        '&:disabled svg g': {
            fill: `rgba(${theme.palette.rgb.black},0.26)`,
        },
    },
    noDataText: {
        minHeight: 100,
    },
    textIcon: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing(1.5),
        cursor: 'pointer',
        color: theme.palette.text.infoDark,
    },
    current: {
        color: theme.palette.text.shipCove,
        background: theme.palette.background.alabaster,
        borderRadius: '10px',
        height: '20px',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        width: '58px',
        textTransform: 'capitalize',
    },
    classesFooter: {
        padding: theme.spacing(0, 1),
        minWidth: '74px',
    },
});

class Employment extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    renderSectionActions() {
        const {
            props: {
                classes, onOpen, onOpenManage, showAddButton,
                labelManage, showNoDataText, record, addIcon,
                loading,
            },
            CUSTOMER_WRITE,
        } = this;
        const isEmptyEmployment = showNoDataText && isEmpty(record);
        const icon = CUSTOMER_WRITE ? (<BorderColorOutlinedIcon />) : <VisibilityOutlinedIcon />;

        return (
            <div
                className="d-flex-center"
            >
                {showAddButton && CUSTOMER_WRITE && (
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={onOpen}
                        disabled={loading}
                        className={classes.addButton}
                    >
                        {addIcon}
                    </IconButton>
                )}
                {!isEmptyEmployment && (
                    <div className={classes.textIcon}>
                        <Button
                            color="primary"
                            size="small"
                            startIcon={icon}
                            onClick={onOpenManage}
                        >
                            {labelManage}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            props: {
                classes, record, open, onClose,
                openManageEmployment, onCloseManage,
                customerId, showNoDataText,
            },
        } = this;
        const isEmptyEmployment = showNoDataText && isEmpty(record);

        return (
            <BoxWidget
                title="Employment"
                typeBox="general"
                rightHeaderSection={this.renderSectionActions()}
                className={clsx({ [classes.noDataText]: isEmptyEmployment })}
                bodyClass={isEmptyEmployment ? 'd-flex-justify-center-align-center' : ''}
            >
                {isEmptyEmployment ? <Typography>No Current Employment</Typography> : (
                    <EmploymentContent
                        record={record}
                        className={classes.employmentContent}
                        classNameFooter={classes.classesFooter}
                    />
                )}
                {open && (
                    <EmploymentDialog
                        open={open}
                        onClose={onClose}
                        customerId={customerId}
                    />
                )}
                {openManageEmployment && (
                    <ManageEmployment
                        open={openManageEmployment}
                        onClose={onCloseManage}
                        customerId={customerId}
                    />
                )}
            </BoxWidget>
        );
    }
}

Employment.propTypes = {
    loading: PropTypes.bool,
    record: PropTypes.object,
    addIcon: PropTypes.element,
    labelManage: PropTypes.string,
    showAddButton: PropTypes.bool,
    showNoDataText: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpenManage: PropTypes.func.isRequired,
    onCloseManage: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    openManageEmployment: PropTypes.bool.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Employment.defaultProps = {
    showAddButton: true,
    labelManage: 'Manage',
    record: {},
    showNoDataText: false,
    addIcon: <PlusIcon />,
    loading: false,
};

export default withStyles(styles)(EmploymentContainer(Employment));

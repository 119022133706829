import React, { useEffect, useReducer } from 'react';

// Material UI
import {
    makeStyles, Typography, IconButton,
} from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import Table from 'components/widgets/Table';
import { useLazyQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CreditQuery from 'services/graphQL/query/credit/CreditQuery';
import CreditReportDialog from 'components/modules/crm/read/credit/CreditReportDialog';
import ModalUtils from 'utils/ModalUtils';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.white,
        padding: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .ReactTable.without-style .rt-table': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        '& .ReactTable.without-style .rt-tbody .rt-tr.-even.active': {
            background: '#e2e6f0 !important',
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));
const ACTION_TYPE = {
    ON_CLOSE: 'onClose',
    SET_CREDIT_REPORT_URL: 'setCreditReportURL',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_CREDIT_REPORT_URL:
        return update(state, {
            url: { $set: action.payload },
            open: { $set: true },
        });
    case ACTION_TYPE.ON_CLOSE:
        return update(state, {
            open: { $set: false },
        });
    default:
        return state;
    }
};

const CreditHistory = ({
    records, onSelectedRecord, recordSelected,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        url: null,
        open: false,
    });
    const [getCreditReportURL, { data, loading, error }] = useLazyQuery(CreditQuery.GET_CREDIT_REPORT_URL, { fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const onReportURL = (record) => {
        if (!StringUtils.isEmpty(record.transactionId)) {
            getCreditReportURL({ variables: { transactionId: record.transactionId } });
        }
    };
    const onClose = () => {
        dispatch({ type: ACTION_TYPE.ON_CLOSE });
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (data?.getCreditReportURL) {
            dispatch({
                type: ACTION_TYPE.SET_CREDIT_REPORT_URL,
                payload: data.getCreditReportURL.url,
            });
        }
    }, [data, loading, error]);

    const columns = [
        {
            Header: 'Date',
            accessor: 'createdOn',
            maxWidth: 200,
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => DateUtils.format(record.value, DateFormat.DEFAULT_DATETIME),
        },
        {
            Header: 'Individual/Joint',
            accessor: 'type',
            maxWidth: 200,
            className: 'd-flex-justify-center-align-center',
        },
        {
            Header: 'Bureaus',
            accessor: 'bureaus',
            className: 'd-flex-justify-center-align-center',
        },
        {
            Header: 'View Report',
            id: 'preview',
            minWidth: 40,
            maxWidth: 90,
            className: clsx('d-flex-justify-center-align-center'),
            // eslint-disable-next-line react/prop-types
            Cell: ({ original }) => (
                <IconButton size="small" onClick={() => onReportURL(original)}>
                    <FindInPageIcon fontSize="small" className={classes.print} />
                </IconButton>
            ),
        },
    ];

    return (
        <div className={clsx('box-shadow', classes.container)}>
            <Typography variant="h5" className={classes.title}>History</Typography>
            <div className="flex-1 overflow-hidden">
                <Table
                    rowSelected
                    data={records}
                    sortable={false}
                    columns={columns}
                    resizable={false}
                    className="without-style"
                    getTrProps={(_, rowInfo) => {
                        const record = rowInfo.original;
                        const selected = record.creditHistoryScoreId === recordSelected?.creditHistoryScoreId;

                        return {
                            onClick: () => {
                                onSelectedRecord(record);
                            },
                            className: selected ? 'active' : '',
                        };
                    }}
                />
            </div>
            {state.open && <CreditReportDialog url={state.url} open={state.open} onClose={onClose} />}
        </div>
    );
};

CreditHistory.propTypes = {
    recordSelected: PropTypes.object,
    records: PropTypes.array.isRequired,
    onSelectedRecord: PropTypes.func.isRequired,
};

CreditHistory.defaultProps = {
    recordSelected: null,
};

export default CreditHistory;

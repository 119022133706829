import { gql } from '@apollo/client';

export default class SMSSubscription {
    static SMS_UPDATED = gql`
        subscription smsUpdated($crmId: ID!) {
            smsUpdated(crmId: $crmId) {
                type
                sms {
                    smsId
                    status
                    message
                    smsChatId
                    direction
                    createdOn
                    createdBy {
                        firstName
                        lastName
                        avatar
                    }
                    errorCode
                }
            }
        }

    `;

    static RECEIVED_SMS_NOTIFICATION = gql`
        subscription receivedSMSNotification {
            receivedSMSNotification {
                message
                processRecordId
                opportunity {
                    leadCode
                    prospect {
                        firstName
                        lastName
                    }
                }
            }
        }
    `;
}

import React, { useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import update from 'immutability-helper';

// Material UI
import {
    makeStyles, Typography, IconButton,
} from '@material-ui/core';

// Other
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Loading from 'components/widgets/Loading';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealNoteItem from 'components/modules/deals/read/dealTab/DealNoteItem';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import DealNoteDialog from 'components/modules/deals/create/dealTab/DealNoteDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonAddProduct: {
        cursor: 'pointer',
        '&:disabled svg g': {
            fill: `rgba(${theme.palette.rgb.black},0.26)`,
        },
    },
    containerList: {
        overflow: 'auto',
        height: '100%',
        border: `1px solid ${theme.palette.border.gallery}`,
        borderTop: 0,
    },
    header: {
        height: 40,
        border: `1px solid ${theme.palette.border.gallery}`,
        padding: '8px 5px',
        background: theme.palette.background.default,
    },
    emptyRecordMessage: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ACTION_TYPES = {
    SET_NOTES: 'setNotes',
    OPEN_DIALOG: 'openModal',
    CLOSE_DIALOG: 'closeDialog',
};

const initState = {
    records: [],
    open: false,
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NOTES: {
        return update(state, {
            records: { $set: action.value },
        });
    }
    case ACTION_TYPES.OPEN_DIALOG: {
        return update(state, {
            open: { $set: true },
        });
    }
    case ACTION_TYPES.CLOSE_DIALOG: {
        return update(state, {
            open: { $set: false },
        });
    }
    default: return action.value;
    }
};

const DealNote = ({ dealId }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        loading, error, data, refetch,
    } = useQuery(DealsQuery.GET_DEAL_NOTES_BY_DEAL, { variables: { dealId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });

    useEffect(() => {
        if (!loading && !error) {
            dispatch({
                type: ACTION_TYPES.SET_NOTES,
                value: data?.getDealNotesByDeal,
            });
        } else if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    }, [data, loading, error]);

    return (
        <div className={classes.container}>
            <div className={clsx('d-flex-center-space-between', classes.header)}>
                <Typography variant="h5">Notes</Typography>
                <IconButton
                    size="small"
                    className={classes.buttonAddProduct}
                    aria-label="Add Product"
                    onClick={() => dispatch({ type: ACTION_TYPES.OPEN_DIALOG })}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            </div>
            <div className={classes.containerList}>
                {loading && <Loading />}
                {!loading && state.records.length === 0 && (<div className={classes.emptyRecordMessage}>No records to display</div>)}
                {state.records.map((item, index) => <DealNoteItem key={index} record={item} index={index} />)}
            </div>
            {state.open && (
                <DealNoteDialog
                    dealId={dealId}
                    open={state.open}
                    refetch={refetch}
                    onClose={() => dispatch({ type: ACTION_TYPES.CLOSE_DIALOG })}
                />
            )}
        </div>
    );
};

DealNote.propTypes = {
    dealId: PropTypes.number.isRequired,
};

export default DealNote;

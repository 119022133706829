/* eslint-disable no-param-reassign */
import React, {
    useEffect,
    useReducer,
} from 'react';
import {
    Grid,
    Button,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import {
    CheckBoxIcon,
    EditOutlinedIcon,
    DeleteOutlineIcon,
} from 'components/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import palette from 'styles/theme/palette';
import Table from 'components/widgets/Table';
import { useMutation } from '@apollo/client';
import Filter from 'components/widgets/Filter';
import Loading from 'components/widgets/Loading';
import { LineStatus } from 'utils/enum/PayrollEnum';
import MessageUtils from 'utils/MessageUtils';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import HoursWorkedMutation from 'services/graphQL/mutate/payroll/HoursWorked';
import EmployeeHoursDialog from 'components/widgets/payroll/HoursWorked/EmployeeHoursDialog';
import PayrollUtils from 'utils/PayrollUtils';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        height: 'fit-content',
    },
    header: {
        marginBottom: 10,
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    content: {
        flexGrow: 1,
        marginTop: 40,
        height: 'fit-content',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    tableHeight: {
        height: 'calc(50vh - 151px)',
    },
    totalLabel: {
        marginLeft: 50,
    },
    grandTotalLabel: {
        marginLeft: 148,
    },
    button: {
        width: '95px',
        marginRight: '16px',
        color: theme.palette.text.white,
    },
    newButton: {
        backgroundColor: theme.palette.background.eucalyptus,
        '&:hover': {
            backgroundColor: theme.palette.background.forestGreen,
        },
    },
    invalidLine: {
        background: theme.palette.background.lightShadeYellow,
    },
}));

const ACTION_TYPES = {
    APPLY_FILTER: 'applyFilter',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === action.accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: action.accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const AdjustedHoursTable = ({
    hasWritePermission, refetch, hasCheck,
    items, loadTable, regular, overtime, total,
}) => {
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };

    const initState = {
        entryIdSelected: 0,
        filtersApplied: [],
        recordSelected: {},
        openEditDialog: false,
        openDeleteDialog: false,
        openTwelveHoursExceedDialog: false,
        recordToSave: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        entryIdSelected, openEditDialog, recordSelected, recordToSave,
        filtersApplied, openDeleteDialog, openTwelveHoursExceedDialog,
    } = state;

    const getInvalidLineStyle = (_, rowInfo) => {
        const { original: { errors, status, warnings } } = rowInfo;
        if (rowInfo && rowInfo.row) {
            return {
                ...((errors.length > 0 || warnings.length > 0) && {
                    style: {
                        minHeight: 38,
                        background: palette.background.lightShadeYellow,
                    },
                }),
                ...((status.toUpperCase() === LineStatus.DELETED.toUpperCase()
                || status.toUpperCase() === LineStatus.SAVE_DELETE.toUpperCase()) && {
                    style: {
                        minHeight: 38,
                        color: palette.background.danger,
                    },
                }),
            };
        }
        return {};
    };

    useEffect(() => {
        dispatch({
            value: [],
            field: 'filtersApplied',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    }, [items]);

    const toggleDeleteDialog = (entryId = 0) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                openDeleteDialog: !openDeleteDialog,
                entryIdSelected: entryId,
            },
        });
    };

    const toggleEditDialog = (isSaving = false, record = {}) => {
        if (isSaving) refetch();
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                recordSelected: record,
                openEditDialog: !openEditDialog,
            },
        });
    };

    const toggleTwelveHoursExceedDialog = (record = null) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                openTwelveHoursExceedDialog: !openTwelveHoursExceedDialog,
                recordToSave: record,
            },
        });
    };

    const [deleteEntry, { loading: deleting }] = useMutation(HoursWorkedMutation.MARK_TIME_FOR_DELETE, {
        onCompleted: (mutationData) => {
            if (mutationData?.markTimeForDelete) {
                ModalUtils.successMessage(null, 'Entry Deleted Successfully!');
                toggleDeleteDialog();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [updateLine, { loading: updating }] = useMutation(HoursWorkedMutation.UPDATE_TIME_LINE);

    const onSave = async (record) => {
        const {
            timeInDate, timeInAmPm, timeInHour, timeInMinute,
            timeOutDate, timeOutAmPm, timeOutHour, timeOutMinute,
            timeInReason, totalHours, openEndedDate, timeOutReason,
        } = record;
        try {
            const timeIn = PayrollUtils.getParsedDate({
                dateOnly: timeInDate,
                hour: timeInHour,
                minutes: timeInMinute,
                amPm: timeInAmPm,
            });
            const timeOut = PayrollUtils.getParsedDate({
                dateOnly: timeOutDate,
                hour: timeOutHour,
                minutes: timeOutMinute,
                amPm: timeOutAmPm,
            });
            const input = {
                timeIn,
                totalHours,
                timeInReason,
                status: recordSelected.status,
                timeId: recordSelected.timeId,
                timeOut: openEndedDate ? null : timeOut,
                timeOutReason: openEndedDate ? null : timeOutReason,
            };
            const response = await updateLine({ variables: { input } });

            if (response.data?.updateTimeLine) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                if (openTwelveHoursExceedDialog) toggleTwelveHoursExceedDialog();
                toggleEditDialog(true);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Time Line'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const openApprovalDialogs = (record) => {
        const { totalHours } = record;
        const [hours, minutes] = totalHours.split(':');
        const totalHoursExceedTwelve = Number(hours) > 12 || (Number(hours) === 12 && Number(minutes) > 0);
        if (totalHoursExceedTwelve) toggleTwelveHoursExceedDialog(record);
        else onSave(record);
    };

    const getColumns = () => [{
        Header: 'Approved',
        id: 'approved',
        minWidth: 10,
        accessor: 'approved',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
        Cell: (cellData) => (cellData.original.approved && (
            <CheckBoxIcon className={classes.highlightedColor} />
        )),
    }, {
        Header: 'Date',
        id: 'date',
        minWidth: 10,
        accessor: 'date',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Date Of Week',
        id: 'dayOfWeek',
        minWidth: 12,
        accessor: 'dayOfWeek',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Time In',
        id: 'timeIn',
        minWidth: 12,
        accessor: 'timeIn',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Time In Reason',
        id: 'timeInReason',
        minWidth: 12,
        accessor: 'timeInReason',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Time Out',
        id: 'timeOut',
        minWidth: 12,
        accessor: 'timeOut',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Time Out Reason',
        id: 'timeOutReason',
        minWidth: 12,
        accessor: 'timeOutReason',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Total Hours',
        id: 'totalHours',
        minWidth: 10,
        accessor: 'totalHours',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Status',
        id: 'status',
        minWidth: 12,
        accessor: 'status',
        getProps: getInvalidLineStyle,
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Actions',
        width: 'auto',
        id: 'actions',
        headerClassName: clsx(classes.hidden),
        getProps: getInvalidLineStyle,
        className: clsx(classes.hidden, 'actionColumnTarget'),
        Cell: (cellData) => {
            const {
                original: {
                    status, timeId, timeInFull, timeOutFull,
                    timeInReason, timeOutReason,
                },
            } = cellData;
            const record = {
                timeId,
                status,
                timeInReason,
                timeOutReason,
                timeIn: timeInFull,
                timeOut: timeOutFull,
            };

            return (
                <div className={classes.buttonSpacing}>
                    {hasWritePermission && !hasCheck && !deleting
                        && (
                            <Button
                                onClick={() => toggleEditDialog(false, record)}
                                variant="outlined"
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                Edit
                            </Button>
                        )}
                    {hasWritePermission && !hasCheck && !deleting
                    && status.toLowerCase() !== LineStatus.DELETED.toLowerCase()
                    && status.toLowerCase() !== LineStatus.SAVE_DELETE.toLowerCase()
                        && (
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => toggleDeleteDialog(timeId)}
                                startIcon={<DeleteOutlineIcon className={classes.actionButtonError} />}
                            >
                                Delete
                            </Button>
                        )}
                </div>
            );
        },
    }];

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const loadColumns = () => {
        const columnNamesExcluded = [
            'checkbox-column', 'isApproved',
        ];
        const columns = getColumns();
        columns.forEach((column) => {
            if (!columnNamesExcluded.includes(column.id)) {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                filterId={column.id}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    return (
        <div className={classes.content}>
            <FormLabel className={classes.localButtonSpacing}>
                Adjusted Hours:
            </FormLabel>
            <Grid item xs={12} className={classes.tableHeight}>
                {!loadTable && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {loadTable && (
                    <Table
                        enableRowHover
                        className="-highlight actionsInLine payrollTable"
                        columns={loadColumns()}
                        filtered={filtersApplied}
                        totalRecords={items.length}
                        data={items}
                        defaultFilterMethod={(localFilter, row) => {
                            const id = localFilter.pivotId || localFilter.id;
                            if (typeof localFilter.value === 'object') {
                                return row[id] !== undefined
                                    ? localFilter.value.indexOf(row[id]) > -1
                                    : true;
                            }
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(localFilter.value) > -1
                                : true;
                        }}
                    />
                )}
                <div>
                    <FormLabel className={classes.localButtonSpacing}>
                        {`Total Regular Hours: ${regular}`}
                    </FormLabel>
                    <FormLabel className={classes.totalLabel}>
                        {`Total Overtime: ${overtime}`}
                    </FormLabel>
                    <FormLabel className={classes.totalLabel}>
                        {`Total Hours: ${total}`}
                    </FormLabel>
                </div>
            </Grid>
            <ConfirmDialog
                title="Confirm Delete"
                description="Mark Entry For Delete?"
                open={openDeleteDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleDeleteDialog}
                onClose={toggleDeleteDialog}
                onClickPrimary={() => deleteEntry({
                    variables: {
                        timeId: entryIdSelected,
                    },
                })}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Do you want to save adjusted time with more than 12 hours?"
                open={openTwelveHoursExceedDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleTwelveHoursExceedDialog}
                onClose={toggleTwelveHoursExceedDialog}
                onClickPrimary={() => onSave(recordToSave)}
            />
            { openEditDialog && (
                <EmployeeHoursDialog
                    record={recordSelected}
                    loading={updating}
                    onClose={toggleEditDialog}
                    onSave={openApprovalDialogs}
                />
            )}
        </div>
    );
};

AdjustedHoursTable.propTypes = {
    items: PropTypes.array.isRequired,
    refetch: PropTypes.func.isRequired,
    total: PropTypes.string.isRequired,
    hasCheck: PropTypes.bool.isRequired,
    loadTable: PropTypes.bool.isRequired,
    regular: PropTypes.string.isRequired,
    overtime: PropTypes.string.isRequired,
    hasWritePermission: PropTypes.bool.isRequired,
};

export default AdjustedHoursTable;

/* eslint-disable no-param-reassign */
import React, {
    useReducer,
} from 'react';
import {
    Grid,
    FormLabel,
    makeStyles,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import Table from 'components/widgets/Table';
import Filter from 'components/widgets/Filter';
import Loading from 'components/widgets/Loading';

const ownStyles = makeStyles(() => ({
    filterContainer: {
        height: '20px',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            left: '5px',
            width: '15%',
            display: 'flex',
            textAlign: 'left',
            paddingTop: '4px',
            position: 'absolute',
            paddingBottom: '4px',
        },
    },
    content: {
        flexGrow: 1,
        height: 'fit-content',
    },
    labelSpacing: {
        marginLeft: '8px',
    },
    tableHeight: {
        minHeight: 225,
        height: 'calc(40vh - 170px)',
    },
    loadingIndicator: {
        flex: 1,
        height: 225,
    },
}));

const ACTION_TYPES = {
    APPLY_FILTER: 'applyFilter',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === action.accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: action.accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const CustomerTable = ({
    title, getColumns, items, loadTable,
}) => {
    const classes = ownStyles();
    const initState = { filtersApplied: [] };
    const [state, dispatch] = useReducer(reducer, initState);
    const { filtersApplied } = state;

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            column.Header = (
                <div
                    className={classes.filterContainer}
                >
                    {column.Header}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Filter
                            showTooltip
                            showIconOnly
                            useInternalSearch
                            maxWidthLabel={200}
                            filterId={column.id}
                            records={getFilterValues(items, column.id)}
                            onClearFilter={() => resetFilters(column.id)}
                            applyFilter={(record) => onFilteredChangeCustom(
                                record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                            )}
                        />
                    </div>
                </div>
            );
        });
        return columns;
    };

    return (
        <div className={classes.content}>
            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
                {!loadTable && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {loadTable && (
                    <Table
                        enableRowHover
                        className="-highlight payrollTable"
                        containerClassName={classes.tableHeight}
                        columns={loadColumns()}
                        filtered={filtersApplied}
                        totalRecords={items.length}
                        data={items}
                        defaultFilterMethod={(localFilter, row) => {
                            const id = localFilter.pivotId || localFilter.id;
                            if (typeof localFilter.value === 'object') {
                                return row[id] !== undefined
                                    ? localFilter.value.indexOf(row[id]) > -1
                                    : true;
                            }
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(localFilter.value) > -1
                                : true;
                        }}
                    />
                )}
                <div>
                    <FormLabel className={classes.labelSpacing}>
                        {`Total records: ${items.length}`}
                    </FormLabel>
                </div>
            </Grid>
        </div>
    );
};

CustomerTable.propTypes = {
    title: PropTypes.string.isRequired,
    loadTable: PropTypes.bool.isRequired,
    getColumns: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomerTable;

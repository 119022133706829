export default {
    PAYROLL_READ: 'payroll:read',
    PAYROLL_REPORTS_PRINT: 'payroll.reports:print',
    PAYROLL_SETTINGS_READ: 'payroll.settings:read',
    PAYROLL_SETTINGS_WRITE: 'payroll.settings:write',
    PAYROLL_MAPPINGS_READ: 'payroll.mappings:read',
    PAYROLL_MAPPINGS_WRITE: 'payroll.mappings:write',
    PAYROLL_ADJUSTMENTS_READ: 'payroll.adjustments:read',
    PAYROLL_ADJUSTMENTS_WRITE: 'payroll.adjustments:write',
    PAYROLL_GENERATED_CHECKS_READ: 'payroll.generated_checks:read',
    PAYROLL_GENERATED_CHECKS_VOID: 'payroll.generated_checks:void',
    PAYROLL_GENERATED_CHECKS_WRITE: 'payroll.generated_checks:write',
    PAYROLL_DEDUCTIONS_REPORT_READ: 'payroll.deductions_report:read',
    PAYROLL_TECHNICIAN_COMMISSIONS_READ: 'payroll.technician_commissions:read',
    PAYROLL_TECHNICIAN_COMMISSIONS_PAID: 'payroll.technician_commissions:paid',
    PAYROLL_TECHNICIAN_COMMISSIONS_WRITE: 'payroll.technician_commissions:write',
    PAYROLL_TECHNICIAN_COMMISSIONS_PRINT: 'payroll.technician_commissions:print',
    PAYROLL_MISC_COMMISSIONS_READ: 'payroll.misc_commissions:read',
    PAYROLL_MISC_COMMISSIONS_PAID: 'payroll.misc_commissions:paid',
    PAYROLL_MISC_COMMISSIONS_WRITE: 'payroll.misc_commissions:write',
    PAYROLL_MISC_COMMISSIONS_PRINT: 'payroll.misc_commissions:print',
    PAYROLL_HOURS_WORKED_READ: 'payroll.hours_worked:read',
    PAYROLL_HOURS_WORKED_PRINT: 'payroll.hours_worked:print',
    PAYROLL_HOURS_WORKED_WRITE: 'payroll.hours_worked:write',
};

import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SearchStyles from 'styles/widgets/SearchStyles';

// Material UI
import { InputBase } from '@material-ui/core';
import { withStyles, fade } from '@material-ui/core/styles';

// Material Icons
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => SearchStyles.search(theme, fade);

class Search extends Component {
    constructor(props) {
        super(props);

        const { text } = props;

        this.state = {
            textSearch: text,
        };
        this.initBind();
    }

    onChangeInputSearch(value) {
        this.setState({
            textSearch: value,
        });
    }

    onKeyDownInput(e) {
        const { state: { textSearch }, props: { onKeyDown } } = this;

        if (e.keyCode === 13) {
            onKeyDown(textSearch);
        }
    }

    initBind() {
        this.onKeyDownInput = this.onKeyDownInput.bind(this);
        this.onChangeInputSearch = this.onChangeInputSearch.bind(this);
    }

    render() {
        const {
            props: {
                classes, fullWidth, type, className, autoFocus, placeholder, overrideStyle,
            },
            state: {
                textSearch,
            },
        } = this;

        const searchFullWidth = fullWidth ? classes.searchFullWidth : '';
        const inputFullWidth = fullWidth ? classes.inputFullWidth : '';

        return (
            <div className={clsx(classes.search, searchFullWidth, className)}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    classes={{
                        input: clsx(classes.inputInput, inputFullWidth, overrideStyle?.overrideInput),
                    }}
                    type={type}
                    className="input-root-mui"
                    onChange={(e) => this.onChangeInputSearch(e.target.value)}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={this.onKeyDownInput}
                    value={textSearch}
                />
            </div>
        );
    }
}

Search.propTypes = {
    onKeyDown: PropTypes.func,
    fullWidth: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    text: PropTypes.string,
    overrideStyle: PropTypes.object,
};

Search.defaultProps = {
    fullWidth: false,
    type: 'text',
    onKeyDown: () => {},
    className: '',
    autoFocus: false,
    placeholder: 'Search…',
    text: '',
    overrideStyle: null,
};

export default withStyles(styles)(Search);

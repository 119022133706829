import { gql } from '@apollo/client';

export default class MessageCenterMutate {
    static SEND_MESSAGE_FROM_MESSAGE_CENTER = gql`
        mutation sendMessageFromMessageCenter($input: MessageCenterInput!) {
            sendMessageFromMessageCenter(input: $input)
        }
    `;

    static MARK_MESSAGE_AS_READ = gql`
        mutation markMessageAsRead($smsChatId: ID!) {
            markMessageAsRead(smsChatId: $smsChatId)
        }
    `;
}

import React, {
    useState,
    useEffect,
} from 'react';

import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { AdjustmentType } from 'utils/enum/PayrollEnum';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import PayrollDeductionSchema from 'utils/schema/payroll/PayrollDeductionSchema';
import PayrollAdjustmentsQuery from 'services/graphQL/query/payroll/PayrollAdjustments';
import PayrollAdjustmentsMutation from 'services/graphQL/mutate/payroll/PayrollAdjustments';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 92,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
            minWidth: 400,
        },
    },
    checkBox: {
        alignSelf: 'baseline',
    },
}));

const initialState = {
    memo: '',
    amount: 0,
    descriptionId: 0,
    controlNumber: '',
    reocurring: false,
    descriptionList: [],
};

const PayrollDeductionsDialog = ({
    open,
    record,
    onClose,
    payPeriod,
    employeeId,
    setCalculateCheckDetail,
}) => {
    const payrollAdditionId = record?.payrollAdditionId || 0;
    const isPayrollDeductionMapping = Boolean(record?.payrollDeductionsMappingId);
    const classes = useStyles();
    const [state, setState] = useState(initialState);

    const {
        data, loading, error,
    } = useQuery(PayrollAdjustmentsQuery.GET_PAYROLL_DB_DESCRIPTION_LIST_BY_TYPE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            bdType: AdjustmentType.DEDUCTION.toUpperCase(),
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPayrollDBListByType } = data;
            setState((previousState) => ({
                ...previousState,
                descriptionList: getPayrollDBListByType,
            }));
        }
    }, [data, loading, error, payrollAdditionId]);

    const [savePayrollDeduction, { loading: updating }] = useMutation(PayrollAdjustmentsMutation.SAVE_PAYROLL_DEDUCTION);

    useEffect(() => {
        if (record) {
            setState({
                memo: record.memo,
                amount: record.amount,
                descriptionId: record.bdId,
                controlNumber: record.controlNumber,
                reocurring: isPayrollDeductionMapping,
            });
        }
    }, [record, isPayrollDeductionMapping]);

    const onChange = (field, value) => {
        setState((previousState) => ({
            ...previousState,
            [field]: value,
        }));
    };

    const onSave = async () => {
        try {
            const input = {
                ...state,
                payPeriod,
                employeeId,
                ...(payrollAdditionId > 0 && { payrollAdditionId }),
                ...(isPayrollDeductionMapping && { payrollDeductionsMappingId: record.payrollDeductionsMappingId }),
            };
            const { descriptionList, ...filteredInput } = input;
            const response = await savePayrollDeduction({ variables: { input: filteredInput } });

            if (response.data?.savePayrollDeduction) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose();
                setCalculateCheckDetail(true);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Payroll Deductions'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const { isValid, errors } = isValidSchema(PayrollDeductionSchema, state);
    const mappedDescriptionList = state?.descriptionList?.map((item) => ({
        value: item.bdId,
        label: item.description,
    }));

    return (
        <Dialog
            maxWidth="md"
            open={open}
        >
            <DialogAppBar
                title={payrollAdditionId > 0 || isPayrollDeductionMapping ? 'Editing Deduction' : 'New Deduction'}
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Deduction for:</Form.Label>
                                    <Select
                                        name="descriptionId"
                                        placeholder="Select"
                                        size="sm"
                                        options={mappedDescriptionList}
                                        value={state.descriptionId}
                                        onChange={onChange}
                                        className={isValidField(errors, 'descriptionId') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Control #:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.controlNumber}
                                        onChange={(e) => onChange('controlNumber', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Amount:</Form.Label>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        name="amount"
                                        thousandSeparator
                                        fixedDecimalScale
                                        value={state.amount}
                                        onChange={(value) => onChange('amount', value)}
                                        className={isValidField(errors, 'amount') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Memo:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.memo}
                                        onChange={(e) => onChange('memo', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Save as a re-ocurring deduction:</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="reocurring"
                                        checked={state.reocurring}
                                        className={classes.checkBox}
                                        onChange={(e) => onChange('reocurring', e.target.checked)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                variant="contained"
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

PayrollDeductionsDialog.propTypes = {
    record: PropTypes.object,
    payPeriod: PropTypes.string,
    employeeId: PropTypes.number,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setCalculateCheckDetail: PropTypes.func.isRequired,
};

PayrollDeductionsDialog.defaultProps = {
    record: null,
    employeeId: 0,
    payPeriod: null,
};

export default PayrollDeductionsDialog;

export default class InputSearchStyle {
    static input(theme) {
        return ({
            inputSearchContainer: {
                display: 'flex',
                alignItems: 'center',
                '& input': {
                    background: 'transparent',
                    paddingRight: theme.spacing(7),
                },
                '& input:focus': {
                    background: 'transparent',
                },
            },
            inputIcon: {
                display: 'flex',
                position: 'relative',
                flex: 1,
                '& button': {
                    position: 'absolute',
                    right: 0,
                    padding: 0,
                    height: '100%',
                    minWidth: theme.spacing(7),
                },
            },
            clearSearch: {
                position: 'absolute',
                right: '58px',
                height: '100%',
                fontSize: '15px',
                zIndex: 99,
                cursor: 'pointer',
                color: 'rgba(0, 0, 0, 0.54)',
            },
        });
    }
}

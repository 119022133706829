/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton, Badge, Popover, makeStyles,
    Typography, Divider, ListItem, ListItemText, List,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    popover: {
        minHeight: 60,
        width: 360,
        maxHeight: '90%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        borderRadius: theme.spacing(1),
    },
    notificationList: {
        overflow: 'auto',
    },
    bodyMessage: {
        position: 'relative',
        width: '100%',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    textPrimary: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    messageUnread: {
        width: 8,
        height: 8,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(24, 144, 255)',
    },
    divider: {
        borderStyle: 'dashed',
        borderWidth: '0px 0px thin',
        borderColor: 'rgba(145, 158, 171, 0.24)',
    },
    footerItem: {
        color: 'rgb(145, 158, 171)',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
    },
    textMessage: {
        fontWeight: 400,
        color: 'rgb(99, 115, 129)',
        margin: theme.spacing(0.5, 0),
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 20px',
    },
    containerSMSNotification: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#959595',
        borderBottom: '1px solid #959595',
        display: 'block',
        padding: theme.spacing(1.5, 0.8),
    },
    smsNotificationHeader: {
        fontWeight: 'bold',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& svg g path': {
            fill: '#959595',
        },
    },
    smsNotificationBody: {
        fontSize: '13px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    alignCenter: {
        textAlign: 'center',
    },
    iconContainer: {
        marginLeft: 'auto',
    },
    iconPadding: {
        padding: '0px 5px',
        color: theme.palette.warning.main,
    },
}));

const EmployeeHoursErrors = ({ records }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.iconContainer}>
            <IconButton className={classes.iconPadding} aria-describedby={id} onClick={handleClick}>
                <Badge
                    badgeContent={records.reduce((sum, current) => sum + current.errors.length + current.warnings.length, 0)}
                    color="secondary"
                    children={<ErrorOutlineIcon />}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <div className={classes.header}>
                    <Typography variant="h6">Please correct the following issues before saving adjusted times:</Typography>
                </div>
                <Divider className={classes.divider} />
                <List className={classes.notificationList}>
                    {records.filter((hour) => hour.errors.length > 0 || hour.warnings.length).map((item, index) => {
                        const { date, errors, warnings } = item;
                        const finalList = errors.concat(warnings);

                        return (
                            <ListItem
                                key={index}
                            >
                                <ListItemText
                                    classes={{ primary: classes.textPrimary }}
                                    primary={(
                                        <>
                                            <div className={classes.bodyMessage}>
                                                <Typography variant="h6">
                                                    {`Date: ${date}`}
                                                </Typography>
                                                {
                                                    finalList.map(
                                                        (error, errorIndex) => (
                                                            <Typography key={errorIndex} className={classes.textMessage}>{`- ${error}`}</Typography>
                                                        ),
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </div>
    );
};

EmployeeHoursErrors.propTypes = {
    records: PropTypes.object.isRequired,
};
export default EmployeeHoursErrors;

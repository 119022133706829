import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/client';
import Table from 'components/widgets/Table';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { FetchPolicy } from 'utils/enum/Core';
import PropTypes from 'prop-types';
import {
    Button, Chip, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import DateUtils from 'lib/DateUtils';
import { PartsPurchaseSubModules, PurchaseOrderStatus } from 'utils/enum/PartsPurchaseEnum';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import NumberUtils from 'lib/NumberUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { VisibilityOutlinedIcon } from 'components/icons';
import { useHistory } from 'react-router';
import { modules } from 'utils/enum/modules';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const PurchasesAssociated = forwardRef((props, ref) => {
    const { partsInventoryId } = props;
    const theme = useTheme();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const keyStore = new KeyStore();
    const PURCHASE_READ = keyStore.hasPermission(Permission.PURCHASE_READ);

    const [getData, { data, loading }] = useLazyQuery(PartsQuery.GET_PURCHASE_ORDERS_ASSOCIATED, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const refreshData = (id) => {
        if (id > 0) {
            getData({
                variables: {
                    id,
                },
            });
        }
    };

    useEffect(() => {
        refreshData(partsInventoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partsInventoryId]);

    useImperativeHandle(ref, () => ({
        updateData(id) {
            refreshData(id);
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const classes = {
        ...useStyles(),
    };

    const getFormattedText = (status, value) => {
        const textColorCls = status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase() ? classes.voidCls : '';
        return <span className={textColorCls}>{value}</span>;
    };

    const columns = [
        {
            Header: 'Purchase Order',
            accessor: 'purchaseOrderId',
            maxWidth: 130,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Order Date',
            accessor: 'orderDate',
            maxWidth: 100,
            width: 100,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Delivery Date',
            minWidth: 120,
            width: 120,
            accessor: 'deliveryDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, DateUtils.getOnlyDate(value)),
        },
        {
            Header: 'Vendor',
            accessor: 'vendor',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value, original: { status } }) => getFormattedText(status, value),
        },
        {
            Header: 'Part Cost',
            minWidth: 120,
            width: 120,
            id: 'UnitPrice',
            accessor: 'unitPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Net Price',
            minWidth: 120,
            width: 120,
            id: 'NetPrice',
            accessor: 'netPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'List Price',
            minWidth: 120,
            width: 120,
            id: 'ListPrice',
            accessor: 'listPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value, original: { status } }) => getFormattedText(status, NumberUtils.applyCurrencyFormat(value)),
        },
        {
            Header: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            maxWidth: 130,
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status.toLowerCase() === PurchaseOrderStatus.DRAFT.toLowerCase()) badgeStatus = classes.primary;
                else if (status.toLowerCase() === PurchaseOrderStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
                else if (status.toLowerCase() === PurchaseOrderStatus.RECEIVED.toLowerCase()) badgeStatus = classes.badgePrinted;
                else if (status.toLowerCase() === PurchaseOrderStatus.RETURNED.toLowerCase()) badgeStatus = classes.badgeCleared;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, isMobile ? '' : classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, isMobile ? '' : classes.hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: { purchaseOrderId },
                } = cellData;

                return (
                    <div className={classes.buttonSpacing}>
                        {PURCHASE_READ
                            && (
                                <Button
                                    onClick={() => history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/view/${purchaseOrderId}`)}
                                    variant="outlined"
                                    startIcon={<VisibilityOutlinedIcon className={classes.actionButtonSuccess} />}
                                    size="small"
                                >
                                    { isMobile ? '' : 'View' }
                                </Button>
                            )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className={clsx(classes.main)}>
            <Table
                loading={loading}
                columns={columns}
                className="-highlight actionsInLine"
                data={data?.getPurchaseOrderAssociatedToPartsInventory ?? []}
                rowSelected
            />
        </div>
    );
});

PurchasesAssociated.propTypes = {
    partsInventoryId: PropTypes.number,
};

PurchasesAssociated.defaultProps = {
    partsInventoryId: 0,
};

export default PurchasesAssociated;

import DateUtils, { DateFormat } from 'lib/DateUtils';
import ImagesUtils from 'utils/ImagesUtils';
import InventoryHelper from 'utils/InventoryHelper';

export default class MapInventoryData {
    static mapList(record) {
        if (record) {
            const emptyColor = 'None';
            const interiorColor = record.intColor || emptyColor;
            const exteriorColor = record.extColor || emptyColor;

            return {
                ...record,
                stock: record.stockNumber,
                image: ImagesUtils.getSecureImageURL(
                    record.thumbnail,
                    InventoryHelper.getThumbnail(record.style),
                ),
                exteriorColor: (exteriorColor.toLowerCase() !== 'null') ? exteriorColor : emptyColor,
                interiorColor: (interiorColor.toLowerCase() !== 'null') ? interiorColor : emptyColor,
                lastUpdatedPrice: DateUtils.getUSFormattedDateFromUTC(record.lastUpdatedPrice, DateFormat.DEFAULT_DATE),
                purchasedDate: DateUtils.getUSFormattedDateFromUTC(record.purchasedDate, DateFormat.DEFAULT_DATE),
                quickNote: record.quickNote || '',
                placeAd: record.placeAd || false,
                editable: false,
            };
        }

        return {};
    }

    static mapVehicleDetail(record) {
        if (record) {
            return {
                images: (record.Images || []).map((image) => ImagesUtils.getSecureImageURL(image, record.Style)),
                thumbnail: ImagesUtils.getSecureImageURL(
                    record.Thumbnail,
                    record.Style,
                ),
                alternativeThumbnail: ImagesUtils.getSecureImageURL(
                    record.AlternativeThumbnail,
                    record.Style,
                ),
                userTabURL1: record.UserTabURL1 || '',
                userTabURL2: record.UserTabURL2 || '',
                detail: {
                    stockNumber: record.StockNumber,
                    year: record.Year,
                    description: record.Description,
                    make: String(record.Make).toUpperCase(),
                    model: String(record.Model).toUpperCase(),
                    vin: record.VIN,
                    trim: record.Trim,
                    style: record.Style,
                    engine: record.Engine,
                    cityMpg: record.CityMPG,
                    highwayMPG: record.HighwayMPG,
                    miles: record.Miles,
                    eml: record.EML,
                    tmu: record.TMU,
                    exempt: record.Exempt,
                    tags: record.Tags || [],
                    transmission: record.Transmission,
                    drivetrain: record.Drivetrain,
                    titleStatus: record.TitleStatus,
                    title: record.Title,
                    titleNumber: record.TitleNumber,
                    lotName: record.LotName,
                    extColor: record.ExtColor,
                    intColor: record.IntColor,
                    genericExteriorColor: record.GenericExteriorColor,
                    spareKey: record.SpareKey,
                    wholesale: record.Wholesale,
                    notes: record.Notes,
                    gpsCompany: record.GPSCompany,
                    gpsInfo: record.GPSDevice,
                    gpsDeviceType: record.GPSDeviceType,
                    tag: record.Tag,
                    tagExpires: record.TagExpires || '',
                    vehicleLocation: record.VehicleLocation || '',
                    active: record.Active,
                    postedDate: record.PostedDate,
                    pricing: {
                        age: record.Age || 0,
                        purchasedFrom: record.PurchasedFrom,
                        purchasedAgent: record.PurchasingAgent,
                        purchasedDate: record.PurchasedDate || new Date(),
                        purchasedPrice: record.PurchasedPrice,
                        repairs: record.Repairs,
                        cost: record.Cost,
                        stickerPrice: record.StickerPrice,
                        nadaTrade: record.NADA,
                        nadaRetail: record.NADARetail,
                        internetPrice: record.InternetPrice,
                        minimumPrice: record.MinimumPrice,
                        floorplanCost: record.FloorplanCost,
                        markup: record.Markup,
                        slashedPrice: record.SlashedPrice,
                        pack: record.Pack,
                        packOverride: record.PackOverride,
                        wholeSalePrice: record.WholesalePrice,
                        showSlashed: record.ShowSlashed,
                        maximumReconCost: record.MaximumReconCost,
                        transport: record.Transport,
                    },
                    bookValue: {
                        nadaTrade: record.NADA,
                        nadaRetail: record.NADARetail,
                    },
                    floorPlan: {
                        flooredRate: record.FlooredRate,
                        flooredBy: record.FlooredBy,
                        floorPlannedDate: record.FloorPlannedDate,
                        curtailmentDate: record.CartalmentDate,
                        floorPaidOn: record.FloorPaidOn,
                        borrowedAmount: record.BorrowedAmount,
                        isFloorPlanned: record.IsFloorPlanned,
                        isBorrowedAmountOverride: record.BorrowedAmountOverride,
                        floorplanCost: record.FloorplanCost,
                        purchasedPrice: record.PurchasedPrice,
                    },
                    advertising: {
                        enableReduce: record.EnableReduce,
                        reducePrice: record.ReducePrice,
                        reduceFrequency: record.ReduceFrequency,
                        removeAd: record.RemoveAdvertising,
                        placeAd: record.PlaceAd,
                        websiteDownpayment: record.WebSiteDownPayment,
                        websitePayment: record.WebsitePayment,
                        paymentFrequency: record.PaymentFrequency,
                        lastReduced: record.LastReduced,
                    },
                    customFields: {
                        customField1Id: record.CustomField1Id || '',
                        customField2Id: record.CustomField2Id || '',
                        customField3Id: record.CustomField3Id || '',
                        customField4Id: record.CustomField4Id || '',
                    },
                    carfaxUrl: record.CarfaxUrl || '',
                    carfaxButtonUrl: record.CarfaxButtonUrl || '',
                },
            };
        }

        return {};
    }

    static mapRepairs(record) {
        if (record) {
            return {
                stockNumber: record.StockNumber,
                checkNo: record.CheckNo === 0 ? '' : record.CheckNo,
                vendorName: record.VendorName,
                datePaid: record.DatePaid,
                comment: record.Comment,
                amount: record.Amount,
                categoryName: record.CategoryName,
                billID: record.BillID,
                memo: record.BillMemo,
                inventoryRepairId: record.InventoryRepairId,
                imported: record.Imported,
            };
        }

        return {};
    }

    static mapSaveRepair(record) {
        if (record) {
            return {
                CheckNo: record.checkNo,
                VendorName: record.vendorName,
                DatePaid: DateUtils.formatUTC(record.datePaid),
                Comment: record.comment,
                Amount: record.amount,
                CategoryName: record.categoryName,
            };
        }

        return {};
    }

    static mapCreateUpdateVechicle(record) {
        return {
            Thumbnail: record.thumbnail,
            AlternativeThumbnail: record.alternativeThumbnail,
            Year: record.year,
            Make: record.make.toUpperCase(),
            Model: record.model.toUpperCase(),
            Trim: record.trim,
            Style: record.style,
            Engine: record.engine,
            Vin: record.vin,
            Transmission: record.transmission,
            ExteriorColor: record.extColor,
            InteriorColor: record.intColor,
            GenericExteriorColor: record.genericExteriorColor,
            LotName: record.lotName,
            Active: true,
            NewUsed: 'Used',
            Miles: record.miles,
            IsTMU: record.isTMU,
            IsExempt: record.isExempt,
            IsEML: record.isEML,
            Title: record.title || false,
            TitleNumber: record.title ? record.titleNumber : '',
            TitleStatus: record.titleStatus,
            IsWholesale: record.isWholesale,
            IsSpareKey: record.spareKey,
            PurchasedFrom: record.purchasingInfo.purchasedFrom,
            PurchasedAgent: record.purchasingInfo.purchasedAgent,
            PurchasedDate: DateUtils.format(
                record.purchasingInfo.purchasedDate,
            ),
            CR: record.purchasingInfo.cr,
            IsFrame: record.purchasingInfo.isFrame,
            PurchasedPrice: record.purchasingInfo.purchasedPrice,
            Repairs: record.purchasingInfo.repairs,
            Cost: record.purchasingInfo.cost,
            CarFax: record.purchasingInfo.carFax,
            AutoCheck: record.purchasingInfo.autoCheck,
            Pack: record.purchasingInfo.pack,
            PackOverride: record.purchasingInfo.packOverride,
            MaximumReconCost: record.purchasingInfo.maximumReconCost,
            ShowSlashed: record.pricingInfo.isSlashedPrice,
            SlashedPrice: record.pricingInfo.slashedPrice,
            StickerPrice: record.pricingInfo.stickerPrice,
            InternetPrice: record.pricingInfo.internetPrice,
            MinimumPrice: record.pricingInfo.minimumPrice,
            EnableReduce: record.advertisingInfo.isReducePrice,
            ReducePrice: record.advertisingInfo.reducePrice,
            ReduceFrequency: record.advertisingInfo.every,
            RemoveAdvertising: record.advertisingInfo.removeAd,
            PlaceAd: record.advertisingInfo.isPlaceOnline,
            LastReduced: record.advertisingInfo.lastReduced,
            Tag: record.tag,
            TagExpires: record.tagExpires,
            Drivetrain: record.drivetrain,
            UserTabURL1: record.userTabURL1,
            UserTabURL2: record.userTabURL2,
        };
    }

    static mapWindowStickerRecord(record) {
        if (record) {
            return {
                stockNumber: record.stockNumber,
                year: record.year,
                make: record.make,
                model: record.model,
                trim: record.trim,
                miles: record.miles,
                isChecked: false,
            };
        }

        return null;
    }
}

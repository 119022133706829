import { gql } from '@apollo/client';

export default class WindowStickersQuery {
    static GET_AVAILABLE_FORMS = gql`
        query getAvailableForms {
            getAvailableForms {
                id
                name
                commonName
            }
        }
    `;

    static GET_FORMS_BY_DEALER = gql`
        query getFormsByDealer {
            getFormsByDealer {
                id
                name
                commonName
                category
            }
        }
    `;

    static GET_WARRANTIES = gql`
        query getWarranties {
            getWarranties {
                id
                name
                asIs
                fullWarranty
                limitedWarranty
                percentLabor
                percentParts
                serviceContract
                systemsCovered0
                systemsCovered1
                systemsCovered2
                systemsCovered3
                systemsCovered4
                systemsCovered5
                systemsCovered6
                systemsCovered7
                systemsCovered8
                duration0
                duration1
                duration2
                duration3
                duration4
                duration5
                duration6
                duration7
                duration8
            }
        }
    `;
}

import React from 'react';
import PropTypes from 'prop-types';

import {
    FormLabel, FormControl, TextField, makeStyles,
} from '@material-ui/core';
import Select from 'components/widgets/Select';

const useStyles = makeStyles({
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
});
const getOptions = (trimValue, trimList) => {
    const options = [{
        value: trimValue,
        label: trimValue,
        isDisabled: true,
    }];

    trimList.forEach((item) => {
        options.push({
            value: item,
            label: item,
        });
    });

    return options;
};

const InputTrim = ({
    multipleTrim, loading, trimList,
    allowEditTrim, trimValue, onChange,
}) => {
    const classes = useStyles();
    let component = null;

    if (multipleTrim) {
        component = (
            <Select
                nowrap
                creatable
                size="sm"
                name="trim"
                formatCreateLabel={(inputValue) => `Set "${inputValue}"`}
                disabled={loading}
                onChange={(_, value) => onChange(value)}
                onCreate={(value) => onChange(value)}
                value={trimValue}
                options={trimList.map((item) => ({
                    value: item,
                    label: item,
                }))}
            />
        );
    } else if (!allowEditTrim) {
        if (trimList.includes(trimValue)) {
            component = (
                <TextField
                    value={trimValue}
                    onChange={({ target }) => onChange(target.value)}
                    variant="outlined"
                    size="small"
                    disabled
                    className={classes.textFieldSmall}
                />
            );
        } else {
            component = (
                <Select
                    name="trim"
                    onChange={(_, newValue) => onChange(newValue)}
                    value={trimValue}
                    options={getOptions(trimValue, trimList)}
                    nowrap
                    size="sm"
                    disabled={loading}
                />
            );
        }
    } else {
        component = (
            <TextField
                value={trimValue}
                onChange={({ target }) => onChange(target.value)}
                variant="outlined"
                size="small"
                className={classes.textFieldSmall}
            />
        );
    }

    return (
        <FormControl>
            <FormLabel>Trim</FormLabel>
            {component}
        </FormControl>
    );
};

InputTrim.propTypes = {
    multipleTrim: PropTypes.bool,
    allowEditTrim: PropTypes.bool,
    loading: PropTypes.bool,
    trimList: PropTypes.array,
    trimValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

InputTrim.defaultProps = {
    multipleTrim: false,
    allowEditTrim: false,
    loading: false,
    trimList: [],
    trimValue: '',
};

export default InputTrim;

import React, { useState, useContext } from 'react';
import { makeStyles, fade, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from 'components/widgets/Container';
import { useHistory, useParams } from 'react-router-dom';
import SettingsStyles from 'styles/modules/accounting/AccountingStyles';
import { ReactComponent as DealsOutlined } from 'assets/deals.svg';
// widgets
import TabPanel from 'components/widgets/tabs/TabPanel';
import BankingList from 'components/modules/accounting/banking/list/BankingList';
import { modules } from 'utils/enum/modules';
import AccountingUtils from 'utils/AccountingUtils';
import { AccountsReceivableEntities, AccountingSubModules, JournalTypes } from 'utils/enum/AccountingEnum';
import AccountsPayableLayout from 'components/modules/accounting/accountsPayable/list/AccountsPayableLayout';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    CreditCardOutlinedIcon, AccountBalanceWalletOutlinedIcon,
    LocalAtmOutlinedIcon, BuildOutlinedIcon,
    ReceiptOutlinedIcon, ShoppingCartOutlinedIcon,
} from 'components/icons';

import { Helmet } from 'react-helmet-async';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DriveEtaOutlined } from '@material-ui/icons';
import GeneralJournalList from 'components/modules/accounting/journal/list/GeneralJournalList';
import CommonJournalList from 'components/modules/accounting/journal/list/CommonJournalList';
import AccountReceivableList from 'components/modules/accounting/accountsReceivable/list/AccountReceivableList';
import UserContext from 'components/context/UserContext';
import AccountingContext from 'components/context/AccountingContext';
import useCombineAccounting from 'components/hook/accounting/useCombineAccounting';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanel(fade, theme));
const keyStore = new KeyStore();

const AccountingTabPanel = () => {
    const ACCOUNTING_BANKING_READ = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_READ);
    const ACCOUNTING_GENERAL_JOURNAL_READ = keyStore.hasPermission(Permission.ACCOUNTING_GENERAL_JOURNAL_READ);
    const ACCOUNTING_ACCOUNT_RECEIVABLE_READ = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_READ);
    const ACCOUNTING_ACCOUNTS_PAYABLE_READ = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNTS_PAYABLE_READ);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const subModules = AccountingUtils.getSubModuleList();

    const history = useHistory();
    const classes = useStyles();
    const { submodule, entity } = useParams();

    const [subModuleTab, setSubModuleTab] = useState(AccountingUtils.getIndexFromList(subModules, submodule || 0));

    const handleChange = (event, newValue) => {
        if (newValue === 0 && !entity) {
            history.push(`/${modules.ACCOUNTING}/${subModules[newValue]}/${AccountsReceivableEntities.DEALS}`);
        } else {
            history.push(`/${modules.ACCOUNTING}/${subModules[newValue]}`);
        }

        setSubModuleTab(newValue);
    };

    return (
        <>
            <AppBar color="default" position="static">
                <Tabs value={subModuleTab} onChange={handleChange} scrollButtons="on" variant="scrollable">
                    {ACCOUNTING_ACCOUNT_RECEIVABLE_READ
                    && <Tab label={isMobile ? '' : 'Accounts Receivable'} icon={<LocalAtmOutlinedIcon />} />}
                    {ACCOUNTING_ACCOUNTS_PAYABLE_READ
                    && <Tab label={isMobile ? '' : 'Accounts Payable'} icon={<CreditCardOutlinedIcon />} />}
                    {ACCOUNTING_BANKING_READ
                    && <Tab label={isMobile ? '' : 'Banking'} icon={<AccountBalanceWalletOutlinedIcon />} />}
                    {ACCOUNTING_GENERAL_JOURNAL_READ
                    && <Tab label={isMobile ? '' : 'General Journals'} icon={<ReceiptOutlinedIcon />} />}
                    {ACCOUNTING_GENERAL_JOURNAL_READ
                    && <Tab label={isMobile ? '' : 'Deal Journals'} icon={<DealsOutlined className={classes.iconStyle} />} />}
                    {ACCOUNTING_GENERAL_JOURNAL_READ
                    && <Tab label={isMobile ? '' : 'Inventory Journals'} icon={<DriveEtaOutlined />} />}
                    {ACCOUNTING_GENERAL_JOURNAL_READ
                    && <Tab label={isMobile ? '' : 'RO Journals'} icon={<BuildOutlinedIcon />} />}
                    {ACCOUNTING_GENERAL_JOURNAL_READ
                    && <Tab label={isMobile ? '' : 'Parts Journals'} icon={<ShoppingCartOutlinedIcon />} />}
                </Tabs>
            </AppBar>
            {ACCOUNTING_ACCOUNT_RECEIVABLE_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.ACCOUNTS_RECEIVABLE)}
                    className={classes.box}
                >
                    <>
                        <Helmet
                            defer={false}
                        >
                            <title>Accounting / Accounts Receivable</title>
                        </Helmet>
                        <AccountReceivableList />
                    </>
                </TabPanel>
            )}
            {ACCOUNTING_ACCOUNTS_PAYABLE_READ
            && (
                <TabPanel value={subModuleTab} index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.ACCOUNTS_PAYABLE)}>
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / Accounts Payable</title>
                    </Helmet>
                    <AccountsPayableLayout />
                </TabPanel>
            )}
            {ACCOUNTING_BANKING_READ
                    && (
                        <TabPanel
                            value={subModuleTab}
                            index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.BANKING)}
                            className={classes.box}
                        >
                            <Helmet
                                defer={false}
                            >
                                <title>Accounting / Banking</title>
                            </Helmet>
                            <BankingList />
                        </TabPanel>
                    )}
            {ACCOUNTING_GENERAL_JOURNAL_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.JOURNALS)}
                    className={classes.box}
                >
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / General Journals</title>
                    </Helmet>
                    <GeneralJournalList />
                </TabPanel>
            )}
            {ACCOUNTING_GENERAL_JOURNAL_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.DEAL_JOURNALS)}
                    className={classes.box}
                >
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / Deal Journals</title>
                    </Helmet>
                    <CommonJournalList journalType={JournalTypes.DEALS} />
                </TabPanel>
            )}
            {ACCOUNTING_GENERAL_JOURNAL_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.INVENTORY_JOURNALS)}
                    className={classes.box}
                >
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / Inventory Journals</title>
                    </Helmet>
                    <CommonJournalList journalType={JournalTypes.INVENTORY} />
                </TabPanel>
            )}
            {ACCOUNTING_GENERAL_JOURNAL_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.SERVICE_JOURNALS)}
                    className={classes.box}
                >
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / RO Journals</title>
                    </Helmet>
                    <CommonJournalList journalType={JournalTypes.SERVICE} />
                </TabPanel>
            )}
            {ACCOUNTING_GENERAL_JOURNAL_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, AccountingSubModules.PARTS_TICKET_JOURNALS)}
                    className={classes.box}
                >
                    <Helmet
                        defer={false}
                    >
                        <title>Accounting / Parts Journals</title>
                    </Helmet>
                    <CommonJournalList journalType={JournalTypes.PARTS_TICKET} />
                </TabPanel>
            )}
        </>
    );
};

const AccountingLayout = () => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const { combineAccounting, lotName } = useCombineAccounting(userInformation);

    if (!userInformation) return null;

    return (
        <Container className={classes.boxContainer}>
            <div className={classes.panelContainer}>
                <AccountingContext.Provider value={{ combineAccounting, lotName }}>
                    <AccountingTabPanel />
                </AccountingContext.Provider>
            </div>
        </Container>
    );
};

export default AccountingLayout;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

const RepairSwitch = React.memo(({
    disabled,
    checked,
    inspectionComplete,
    reconInspectionItemId,
    onChange,
}) => (
    <Switch
        disabled={disabled}
        checked={checked}
        onChange={(_, value) => (!inspectionComplete ? onChange('repair', value, reconInspectionItemId) : null)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
    />
), (prevProps, nextProps) => prevProps.inspectionComplete === nextProps.inspectionComplete
&& prevProps.checked === nextProps.checked
&& prevProps.disabled === nextProps.disabled);

RepairSwitch.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    inspectionComplete: PropTypes.bool,
    reconInspectionItemId: PropTypes.number,
};

RepairSwitch.defaultProps = {
    inspectionComplete: false,
    reconInspectionItemId: 0,
};

export default RepairSwitch;

import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Header from 'components/widgets/Header';
import Container from 'components/widgets/Container';
import { Link as RouterLink } from 'react-router-dom';
import InputSearch from 'components/widgets/InputSearch';
import ExpandRow from 'components/modules/lead/list/ExpandRow';
import { ReactComponent as AddUser } from 'assets/addUser.svg';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import ExpansionPanelStyles from 'styles/widgets/ExpansionPanelStyles';
import CustomerItems from 'components/modules/customer/list/CustomerItems';
import CustomerListContainer from 'components/containers/customer/list/CustomerListContainer';

// Material UI
import MuiAccordion from '@material-ui/core/Accordion';
import CustomerStyles from 'styles/modules/CustomerStyles';
import { Button } from '@material-ui/core';
import { withStyles, fade } from '@material-ui/core/styles';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => CustomerStyles.customerList(theme, fade);
const Accordion = withStyles(ExpansionPanelStyles.expansionPanel())(MuiAccordion);

class CustomerList extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    componentDidMount() {
        const { props: { subscribeCustomerList, getServicesData } } = this;

        subscribeCustomerList();
        getServicesData();
    }

    componentWillUnmount() {
        const { props: { unsubscribeCustomerList } } = this;

        unsubscribeCustomerList();
    }

    renderList() {
        const {
            props: {
                classes, contentList: { records, totalCount }, load,
                loadMore,
            },
        } = this;

        return (
            <InfiniteScroll
                className={classes.box}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={load}
                loadAtScrollPercent={80}
            >
                {
                    records.map((item) => (
                        <Accordion
                            square
                            elevation={0}
                            component={RouterLink}
                            key={item.customerCode}
                            to={`/customers/${item.customerCode}`}
                        >
                            <ExpandRow className={classes.expandRow}>
                                <CustomerItems record={item} />
                            </ExpandRow>
                        </Accordion>
                    ))
                }
            </InfiniteScroll>
        );
    }

    render() {
        const {
            classes, onNew, contentList, onSearch,
        } = this.props;

        return (
            <>
                <Header className={classes.header}>
                    <div className="d-flex-center">
                        {this.CUSTOMER_WRITE && (
                            <Button
                                className={clsx(classes.containedSecondaryInfo, classes.mainButton)}
                                variant="contained"
                                startIcon={<AddUser />}
                                onClick={onNew}
                            >
                                New
                            </Button>
                        )}
                        <InputSearch
                            size="sm"
                            onSearch={onSearch}
                            customClasses={classes.search}
                            initialSearch={contentList.search}
                        />
                    </div>
                </Header>
                <Container className={classes.boxContainer}>
                    { this.renderList() }
                </Container>
            </>
        );
    }
}

CustomerList.propTypes = {
    load: PropTypes.bool,
    onNew: PropTypes.func.isRequired,
    contentList: PropTypes.shape({
        records: PropTypes.array.isRequired,
        limit: PropTypes.number,
        totalCount: PropTypes.number,
        search: PropTypes.string.isRequired,
    }).isRequired,
    onSearch: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    subscribeCustomerList: PropTypes.func.isRequired,
    unsubscribeCustomerList: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CustomerList.defaultProps = {
    load: false,
};

export default withStyles(styles)(withRouter(CustomerListContainer(CustomerList)));

import { isFinite } from 'lodash';
import moment from 'moment';
import sedan from 'assets/inventory/sedan-avatar.svg';
import truck from 'assets/inventory/truck-avatar.svg';
import suv from 'assets/inventory/suv-avatar.svg';
import minivan from 'assets/inventory/minivan-avatar.svg';
import van from 'assets/inventory/van-avatar.svg';
import convertible from 'assets/inventory/convertible-avatar.svg';
import { Tabs } from 'utils/enum/InventoryEnum';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import { modules } from 'utils/enum/modules';

export default {
    getThumbnail(style) {
        const vehicleStyle = (style || '').toLowerCase();

        switch (vehicleStyle) {
        case 'sedan':
            return sedan;
        case 'truck':
        case 'pickup':
        case 'pick-up':
            return truck;
        case 'suv':
            return suv;
        case 'minivan':
            return minivan;
        case 'van':
            return van;
        case 'convertible':
            return convertible;
        default:
            return sedan;
        }
    },
    getSelectedTab(hash) {
        const tabs = Object.values(Tabs);
        const index = tabs.indexOf(hash) !== -1 ? tabs.indexOf(hash) : 0;

        return tabs[index];
    },
    validateCustomTabURL(url) {
        // If the value is empty it return true because the field is not required
        if (StringUtils.isEmpty(url)) return true;

        return StringUtils.validateSecureURL(url);
    },
    calculateAgeActiveVehicle(purchasedDate) {
        if (!purchasedDate) return null;

        return DateUtils.diff(new Date(), purchasedDate);
    },
    formatInfoForMarketCompsTooltip(data) {
        const bodyStyle = (data.bodyStyle || data.style);
        let thumbnail = (data.thumbnail || data.alternativeThumbnail);
        if ((!thumbnail && bodyStyle) || !StringUtils.validateURL(thumbnail)) {
            thumbnail = this.getThumbnail(bodyStyle);
        }

        return {
            stockNumber: (data.stockNumber || data.stock),
            vin: data.vin,
            year: data.year,
            make: data.make,
            model: data.model,
            trim: data.trim,
            engine: data.engine,
            bodyStyle,
            age: data.age || this.calculateAgeActiveVehicle(data.pricing?.purchasedDate),
            distance: data.distance,
            price: (data.internetPrice ?? data.sellingPrice ?? data.pricing?.internetPrice),
            miles: NumberUtils.applyThousandsFormat(data.miles || 0),
            soldDate: data.soldDate,
            dealType: data.dealType,
            lotName: data.lotName,
            seller: (data.sellerName || ''),
            thumbnail,
            url: (data.url || `/${modules.INVENTORY}/${data.stockNumber}`),
        };
    },
    getMarketCompsChartOptions(theme, tooltipGenerator, {
        tickAmountX,
        tickAmountY,
        maxValueX,
        maxValueY,
        minValueX,
        minValueY,
    }) {
        return {
            tooltip: {
                shared: false,
                intersect: false,
                followCursor: true,
                custom: tooltipGenerator,
            },
            colors: [
                theme.palette.background.silverChalice, // Suggested Price
                theme.palette.background.purpleHeart, // Current Price
                theme.palette.background.lima, // Market Vehicles
                theme.palette.background.pickledBluewood, // Other Active Vehicles
                theme.palette.background.christine, // Trend Line
                theme.palette.background.valencia, // Sold
                theme.palette.background.bronze, // Market Vehicles - Older Year
                theme.palette.background.camarone, // Market Vehicles - Newer Year
            ],
            chart: {
                type: 'line',
                zoom: { enabled: true },
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                    },
                },
                animations: {
                    enabled: false,
                },
                events: {
                    dataPointMouseEnter: (event) => {
                        const parentWidth = document.getElementById('chartContainer')?.clientWidth;
                        const {
                            target: {
                                cx: { baseVal: { value: x } },
                            },
                        } = event;

                        const tooltip = document.querySelector('.apexcharts-tooltip');
                        tooltip.style.display = 'block';

                        if (x && parentWidth > 0) {
                            const positionXInPorcentage = (x / parentWidth) * 100;

                            let translateX = '-50%';
                            if (positionXInPorcentage <= 15) translateX = '-10%';
                            if (positionXInPorcentage > 15 && positionXInPorcentage <= 20) translateX = '-30%';
                            if (positionXInPorcentage > 20 && positionXInPorcentage <= 46) translateX = '-50%';
                            if (positionXInPorcentage > 46 && positionXInPorcentage <= 75) translateX = '50%';
                            if (positionXInPorcentage > 75) translateX = '5%';

                            tooltip.style.transform = `translate(${translateX}, -70%)`;
                        }

                        tooltip.addEventListener('mouseenter', () => {
                            tooltip.classList.add('isActive');

                            const element = tooltip.querySelector('.vinCopy');
                            if (element) {
                                const vin = element.parentElement.children[1].innerHTML;
                                element.onclick = () => {
                                    element.children[0].style.fill = theme.palette.background.silver;
                                    return navigator?.clipboard?.writeText(vin);
                                };
                            }
                        }, { once: true });

                        tooltip.addEventListener('mouseleave', () => {
                            tooltip.classList.remove('isActive');
                            tooltip.style.display = 'none';
                        }, { once: true });
                    },
                },
            },
            stroke: {
                show: true,
                width: 2.5,
            },
            fill: { type: 'solid' },
            markers: {
                size: [4, 6, 7, 7, 5, 5, 7, 7],
                strokeWidth: 1.5,
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '12px',
                offsetY: 5,
            },
            xaxis: {
                type: 'numeric',
                tickAmount: tickAmountX,
                max: maxValueX,
                min: minValueX,
                labels: {
                    formatter: (value) => NumberUtils.applyCurrencyFormat(value, '0a'),
                },
                title: {
                    text: 'Miles',
                },
                forceNiceScale: false,
            },
            yaxis: {
                type: 'numeric',
                tickAmount: tickAmountY,
                max: maxValueY,
                min: minValueY,
                labels: {
                    formatter: (value) => NumberUtils.applyCurrencyFormat(value, '0a'),
                },
                title: {
                    text: 'Price',
                },
                forceNiceScale: false,
            },
        };
    },
    openURLInNewTab(url) {
        const linkId = 'externalPage';
        let link = document.getElementById(linkId);
        if (link) link.parentNode.removeChild(link);

        link = document.createElement('a');
        link.id = linkId;
        link.href = url;
        link.target = '_blank';

        document.body.appendChild(link);
        document.getElementById(linkId).click();
    },
    hasStockInvalidYear(year) {
        const validYear = isFinite(Number(year));
        const currentYear = new Date().getFullYear();

        return validYear
            ? Number(year) <= 1900 || Number(year) > currentYear + 2
            : true;
    },
    reducedPrice: [
        {
            label: '$100',
            value: '$100',
        },
        {
            label: '$200',
            value: '$200',
        },
        {
            label: '$300',
            value: '$300',
        },
        {
            label: '$400',
            value: '$400',
        },
        {
            label: '$500',
            value: '$500',
        },
        {
            label: '$600',
            value: '$600',
        },
        {
            label: '$700',
            value: '$700',
        },
    ],
    reduceFrequency: [
        {
            label: 'None',
            value: '',
        },
        {
            label: 'Week',
            value: 'Week',
        },
        {
            label: '2 Weeks',
            value: '2 Weeks',
        },
        {
            label: '3 Weeks',
            value: '3 Weeks',
        },
        {
            label: 'Month',
            value: 'Month',
        },
    ],
    removeAd: [
        {
            value: 'Immediately',
            label: 'Immediately after sales',
        },
        {
            value: '15 Days',
            label: '15 Days after sales',
        },
        {
            value: '30 Days',
            label: '30 Days after sales',
        },
        {
            value: '45 Days',
            label: '45 Days after sales',
        },
        {
            value: '60 Days',
            label: '60 Days after sales',
        },
    ],
    frequency: [
        {
            value: '',
            label: 'None',
        },
        {
            value: 'Weekly',
            label: 'Weekly',
        },
        {
            value: 'Bi-Weekly',
            label: 'Bi-Weekly',
        },
        {
            value: 'Semi-Monthly',
            label: 'Semi-Monthly',
        },
        {
            value: 'Monthly',
            label: 'Monthly',
        },
    ],
    calculateFloorplanCost(
        vendorFee = 0,
        borrowedAmount = 0,
        flooredRate = 0,
        floorDate = null,
        floorPaidOn = null,
    ) {
        const subCost = (borrowedAmount * (flooredRate / 100)) / 365;
        let floorPlannedDate = moment(floorDate || new Date());

        if (floorPlannedDate.isValid()) {
            const floorDateSelected = floorPlannedDate.toObject();
            const endDate = floorPaidOn ? moment(floorPaidOn) : moment();

            floorPlannedDate = moment([
                floorDateSelected.years,
                floorDateSelected.months,
                floorDateSelected.date,
            ]);

            const days = endDate.diff(floorPlannedDate, 'days');
            const total = subCost * days;
            const floorplanCost = total + vendorFee;

            return Number(floorplanCost.toFixed(2));
        }

        return 0;
    },
};

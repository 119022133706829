/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

// Components and Others
import UserContext from 'components/context/UserContext';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import ProductSettingsStyles from 'styles/modules/settings/ProductSettingsStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import VendorSettingsDialogContainer from 'components/containers/settings/vendors/VendorSettingsDialogContainer';
import InputNumber from 'components/widgets/InputNumber';
import NumberFormat from 'react-number-format';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import VendorUtils from 'utils/VendorUtils';
import { VendorSettingSchema } from 'utils/schema/settings/VendorSettings';
import { VendorFormula, VendorType } from 'utils/enum/Vendors';
import PROVIDER from 'utils/enum/MenuEnum';
import StringUtils from 'lib/StringUtils';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, TextField,
} from '@material-ui/core';

const styles = (theme) => ProductSettingsStyles.referenceDialog(theme);

class VendorSettingsDialog extends Component {
    static contextType = UserContext;

    componentDidMount() {
        const { onChangeValue } = this.props;
        const { userInformation: { lots } } = this.context;
        this.setState({ lots });

        if (lots.length > 0) onChangeValue('lotName', lots[0].lotName);
    }

    onAddFormulaField(item) {
        const {
            props: {
                record, onChangeValue, currentActiveFormula,
            },
        } = this;

        let currentFormula = '';
        let currentFormulaField = '';

        if (currentActiveFormula === VendorFormula.RESERVE) {
            currentFormula = record.vendorFormula;
            currentFormulaField = 'vendorFormula';
        } else if (currentActiveFormula === VendorFormula.DISCOUNT) {
            currentFormula = record.discountFormula;
            currentFormulaField = 'discountFormula';
        }

        if (StringUtils.isEmpty(currentFormula)) {
            currentFormula = '';
        }

        const finalString = currentFormula.concat(item);
        onChangeValue(currentFormulaField, finalString);
    }

    getVendorStates() {
        const fields = VendorUtils.getVendorStates();
        return fields.map((item) => ({ value: item, label: item }));
    }

    getVendorTypes() {
        const fields = VendorUtils.getVendorTypes();
        return fields.map((item) => ({ value: item, label: item }));
    }

    renderFormulaFields() {
        const fields = VendorUtils.getFormulaFields();
        return fields.map((item) => (
            <li
                style={{
                    listStyle: 'none',
                    cursor: 'pointer',
                }}
                key={item.id}
                onDoubleClick={() => {
                    this.onAddFormulaField(
                        item.value,
                    );
                }}
            >
                {' '}
                {item.value}
            </li>
        ));
    }

    render() {
        const {
            props: {
                classes, onSave, open, onClose,
                onChangeValue, isEditing, record,
                onEnableDecoder, onToggleFormulas,
                externalProviders,
            },
        } = this;
        const prefixTitle = isEditing ? 'Edit' : 'New';
        const {
            vendorName, vendorAddress, vendorZip, vendorCity, vendorState,
            vendorPhone, vendorFax, vendorContact, vendorFednid, vendorCrNumber,
            reservePercentage, vendorFee, floorRate, vendorType, provider, vendorFormula, discountFormula,
            vendorNotes, active, curtailmentDays, curtailmentPercentage, curtailmentDueDays,
            username, password, companyCode, dealerCode, lotName, lotCode,
            allowVoid, companyId, is1099,
        } = record;

        const isNonMenu = provider === PROVIDER.NON_MENU;
        const isStoneEagle = provider === PROVIDER.STONE_EAGLE;
        const isExpress = provider === PROVIDER.FI_EXPRESS;
        const isValidExpress = !isExpress || (isExpress && companyId > 0);
        const isValidStoneEagle = !isStoneEagle
        || (
            isStoneEagle
            && !StringUtils.isEmpty(companyCode)
            && !StringUtils.isEmpty(dealerCode)
            && !StringUtils.isEmpty(username)
            && !StringUtils.isEmpty(password)
        );

        const isValidCoreData = isValidSchema(VendorSettingSchema, record);
        const { isValid, errors } = isValidCoreData;
        const showCurtailmentFields = vendorType === 'Floorplan';

        const { lots } = this.state || {};
        const lotOptions = (lots || []).map((l) => ({ value: l.lotName, label: l.lotName }));

        return (
            <Dialog
                open={open}
                maxWidth="lg"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Vendor`} onClose={onClose} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1} className="am-form">
                        <Grid item xs={12} sm={12} md={isNonMenu ? 8 : 12} lg={isNonMenu ? 8 : 12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Vendor Type</Form.Label>
                                        <Select
                                            name="vendorType"
                                            value={vendorType}
                                            options={this.getVendorTypes()}
                                            placeholder="Select"
                                            className={isValidField(errors, 'vendorType') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Provider</Form.Label>
                                        <Select
                                            disabled={vendorType !== VendorType.PRODUCT_VENDOR}
                                            name="provider"
                                            value={provider}
                                            options={Object.keys(PROVIDER).map((key) => ({ value: PROVIDER[key], label: PROVIDER[key] }))}
                                            placeholder="Select"
                                            className="select-bootstrap"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Vendor Name</Form.Label>
                                        {isExpress && (
                                            <Select
                                                className={!companyId ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                                name="companyId"
                                                value={companyId}
                                                options={externalProviders.map((p) => ({ value: p.providerId, label: `${p.providerId} - ${p.providerName}` }))}
                                                placeholder="Select"
                                                maxMenuHeight={140}
                                                onChange={(name, value) => onChangeValue(name, externalProviders.find((x) => x.providerId === value))}
                                            />
                                        )}
                                        {!isExpress && (
                                            <Form.Control
                                                className={isValidField(errors, 'vendorName') ? 'invalid-field' : ''}
                                                value={vendorName}
                                                type="text"
                                                onChange={(e) => onChangeValue('vendorName', e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            value={vendorAddress}
                                            type="text"
                                            onChange={(e) => onChangeValue('vendorAddress', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Zip</Form.Label>
                                        <NumberFormat
                                            className="form-control"
                                            value={vendorZip}
                                            placeholder="Placeholder"
                                            format="#####"
                                            onFocus={onEnableDecoder}
                                            onValueChange={({ value }) => onChangeValue('vendorZip', value)}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            value={vendorCity}
                                            type="text"
                                            onChange={(e) => onChangeValue('vendorCity', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Select
                                            name="vendorState"
                                            value={vendorState}
                                            options={this.getVendorStates()}
                                            placeholder="Select"
                                            className="select-bootstrap"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            value={vendorPhone}
                                            type="text"
                                            onChange={(e) => onChangeValue('vendorPhone', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Fax</Form.Label>
                                        <Form.Control
                                            value={vendorFax}
                                            type="text"
                                            onChange={(e) => onChangeValue('vendorFax', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Contact</Form.Label>
                                        <Form.Control
                                            value={vendorContact}
                                            type="text"
                                            onChange={(e) => onChangeValue('vendorContact', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            {isNonMenu && (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>FEDNID #</Form.Label>
                                                <Form.Control
                                                    value={vendorFednid}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('vendorFednid', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Doc Stamp #</Form.Label>
                                                <Form.Control
                                                    value={vendorCrNumber}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('vendorCrNumber', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Reserve %</Form.Label>
                                                <InputNumber
                                                    className={isValidField(errors, 'reservePercentage') ? 'invalid-field' : ''}
                                                    placeholder="0"
                                                    decimalScale={2}
                                                    value={reservePercentage}
                                                    onChange={(value) => onChangeValue('reservePercentage', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Floor/Vendor Fee</Form.Label>
                                                <InputNumber
                                                    className={isValidField(errors, 'vendorFee') ? 'invalid-field' : ''}
                                                    placeholder="0"
                                                    decimalScale={2}
                                                    value={vendorFee}
                                                    onChange={(value) => onChangeValue('vendorFee', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Floor Rate</Form.Label>
                                                <InputNumber
                                                    className={isValidField(errors, 'floorRate') ? 'invalid-field' : ''}
                                                    placeholder="0"
                                                    decimalScale={2}
                                                    value={floorRate}
                                                    onChange={(value) => onChangeValue('floorRate', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {showCurtailmentFields && isNonMenu && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Curtailment days</Form.Label>
                                            <InputNumber
                                                className={isValidField(errors, 'curtailmentDays') ? 'invalid-field' : ''}
                                                placeholder="0"
                                                decimalScale={0}
                                                value={curtailmentDays}
                                                onChange={(value) => onChangeValue('curtailmentDays', value)}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Curtailment %</Form.Label>
                                            <InputNumber
                                                className={isValidField(errors, 'curtailmentPercentage') ? 'invalid-field' : ''}
                                                placeholder="0"
                                                decimalScale={2}
                                                value={curtailmentPercentage}
                                                onChange={(value) => onChangeValue('curtailmentPercentage', value)}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Curtail due days</Form.Label>
                                            <InputNumber
                                                className={isValidField(errors, 'curtailmentDueDays') ? 'invalid-field' : ''}
                                                placeholder="0"
                                                decimalScale={0}
                                                value={curtailmentDueDays}
                                                onChange={(value) => onChangeValue('curtailmentDueDays', value)}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Grid>
                            )}
                            {isNonMenu && (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Reserve Formula</Form.Label>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    rows={4}
                                                    variant="outlined"
                                                    value={vendorFormula || ''}
                                                    onFocus={() => onToggleFormulas(VendorFormula.RESERVE)}
                                                    onChange={(e) => onChangeValue('vendorFormula', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Discount Formula</Form.Label>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    rows={4}
                                                    variant="outlined"
                                                    value={discountFormula || ''}
                                                    onFocus={() => onToggleFormulas(VendorFormula.DISCOUNT)}
                                                    onChange={(e) => onChangeValue('discountFormula', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {isStoneEagle && (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control
                                                    className={StringUtils.isEmpty(username) ? 'invalid-field' : ''}
                                                    value={username}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('username', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    className={StringUtils.isEmpty(password) ? 'invalid-field' : ''}
                                                    value={password}
                                                    type="password"
                                                    onChange={(e) => onChangeValue('password', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Company Code</Form.Label>
                                                <Form.Control
                                                    className={StringUtils.isEmpty(companyCode) ? 'invalid-field' : ''}
                                                    value={companyCode}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('companyCode', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Dealer Code</Form.Label>
                                                <Form.Control
                                                    className={StringUtils.isEmpty(dealerCode) ? 'invalid-field' : ''}
                                                    value={dealerCode}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('dealerCode', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {(isExpress || isStoneEagle) && (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Lot Name</Form.Label>
                                                <Select
                                                    name="lotName"
                                                    value={lotName}
                                                    options={lotOptions}
                                                    placeholder="Select"
                                                    className="select-bootstrap"
                                                    maxMenuHeight={140}
                                                    onChange={onChangeValue}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Lot Code</Form.Label>
                                                <Form.Control
                                                    value={lotCode}
                                                    type="text"
                                                    onChange={(e) => onChangeValue('lotCode', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <div className={classes.contractOptions}>
                                            <Form.Group as={Col} className={classes.flexCenter}>
                                                Contract Options
                                            </Form.Group>
                                            <Form.Group as={Col} className={classes.flexCenter}>
                                                <Form.Check
                                                    onChange={(e) => onChangeValue('allowVoid', e.target.checked)}
                                                    checked={allowVoid}
                                                    className={classes.alignCheckBox}
                                                />
                                                <Form.Label className={classes.labelCheck}>Allow void contracts</Form.Label>
                                            </Form.Group>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Notes</Form.Label>
                                        <TextField
                                            multiline
                                            fullWidth
                                            rows={4}
                                            variant="outlined"
                                            value={vendorNotes || ''}
                                            onChange={(e) => onChangeValue('vendorNotes', e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Form.Group as={Col} className={classes.flexCenter}>
                                        <Form.Label className={classes.labelCheck}>Active</Form.Label>
                                        <Form.Check
                                            onChange={(e) => onChangeValue('active', e.target.checked)}
                                            checked={active}
                                            className={classes.alignCheckBox}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={3}>
                                    <Form.Group as={Col} className={classes.flexCenter}>
                                        <Form.Label className={classes.labelCheck}>1099 Vendor</Form.Label>
                                        <Form.Check
                                            onChange={(e) => onChangeValue('is1099', e.target.checked)}
                                            checked={is1099}
                                            className={classes.alignCheckBox}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isNonMenu && (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div>
                                    <Form.Group as={Col}>
                                        <Form.Label>Formula Fields</Form.Label>
                                        <ul className={classes.formulaList}>
                                            {this.renderFormulaFields()}
                                        </ul>
                                    </Form.Group>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions
                    disablePrimaryButton={!isValid || !isValidExpress || !isValidStoneEagle}
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSave(record)}
                />
            </Dialog>
        );
    }
}

VendorSettingsDialog.defaultProps = {
    externalProviders: [],
};

VendorSettingsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggleFormulas: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    currentActiveFormula: PropTypes.string.isRequired,
    externalProviders: PropTypes.array,
};

export default withStyles(styles)(VendorSettingsDialogContainer(VendorSettingsDialog));

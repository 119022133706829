import { gql } from '@apollo/client';

export default class PartsSubscription {
static PURCHASE_ORDER_SUBSCRIPTION = gql`
subscription purchaseOrderChanged {
  purchaseOrderChanged {
    id
    data
    action
    companyCode
  }
}
`;

static PARTS_INVENTORY_SUBSCRIPTION = gql`
subscription partsInventoryChanged {
  partsInventoryChanged {
    id
    data
    action
    companyCode
  }
}
`;

static PARTS_TICKET_SUBSCRIPTION = gql`
subscription partsTicketChanged {
  partsTicketChanged {
    id
    data
    action
    companyCode
  }
}
`;
}

import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import DealService from 'services/modules/DealService';

const BasicCoBuyerInformationContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        dealId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.dealServices = new DealService();

        this.initBind();
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onClose() {
        this.setState({
            open: false,
        });
    }

    changeCoBuyer(record) {
        const { dealId } = this.props;
        const { customerId } = record;
        const input = {
            dealId,
            customerId,
        };

        this.setState({
            saving: true,
        });

        this.dealServices.assignCoBuyer(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.assignCoBuyer) {
                    ModalUtils.successMessage([{ message: 'Co Buyer updated successfully' }]);
                    this.onClose();
                }
            })
            .finally(() => {
                this.setState({
                    saving: false,
                });
            });
    }

    initBind() {
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.changeCoBuyer = this.changeCoBuyer.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onOpen={this.onOpen}
                onClose={this.onClose}
                changeCoBuyer={this.changeCoBuyer}
            />
        );
    }
};

export default BasicCoBuyerInformationContainer;

import * as yup from 'yup';

const FormFieldsSchema = yup.object().shape({
    label: yup.string().required(),
    dataType: yup.string().required(),
    value: yup.string().required(),
});

const FormMapperSchema = yup.object().shape({
    formName: yup.string().required(),
});

export {
    FormFieldsSchema, FormMapperSchema,
};

/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';

import Split from 'react-split';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { useQuery, useLazyQuery } from '@apollo/client';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import { makeStyles } from '@material-ui/core';
import { AdjustmentType } from 'utils/enum/PayrollEnum';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import PayrollAdjustments from 'services/graphQL/query/payroll/PayrollAdjustments';
import DeductionsReportQuery from 'services/graphQL/query/payroll/DeductionsReport';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles(() => ({
    splitOne: {
        display: 'flex',
        padding: '10px',
        overflow: 'hidden',
        flex: '1',
    },
    tableHeight: {
        height: 'calc(97vh - 98px)',
    },
}));

const ACTION_TYPES = {
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
    SET_DEDUCTION_INDEX_AND_RECORD: 'setDeductionIndexAndRecord',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.entity]: {
                [action.field]: { $set: action.value },
            },
        });
    case ACTION_TYPES.SET_DEDUCTION_INDEX_AND_RECORD:
        action.callback({
            variables: {
                deductionId: action.record.bdId,
            },
        });
        return update(state, {
            deductions: {
                selectedIndex: { $set: action.index === null ? state.deductions.selectedIndex : action.index },
                selectedRecord: { $set: action.record },
            },
        });
    case ACTION_TYPES.SET_STATE_VALUES:
        return update(state, {
            [action.entity]: {
                $set: { ...state[action.entity], ...action.values },
            },
        });
    default: return state;
    }
};

const EmployeeDeductionsMapping = () => {
    let tableRef;
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };

    const initState = {
        deductions: {
            list: [],
            selectedIndex: -1,
            selectedRecord: null,
        },
        employees: {
            list: [],
        },
        loadTable: false,
        filteredData: [],
        searchValue: null,
        filtersApplied: [],
        checkCBIDSelected: 0,
        openVoidDialog: false,
        payPeriodSelected: -1,
        openApprovalDialog: false,
        openLockDateDialog: false,
        openEditCheckDialog: false,
        openNachaEffectiveDateDialog: false,
        payPeriodYear: new Date().getFullYear(),
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        deductions: {
            list,
            selectedIndex,
        },
        employees: {
            list: employeeList,
        },
    } = state;

    const {
        data, loading, error,
    } = useQuery(PayrollAdjustments.GET_PAYROLL_DB_DESCRIPTION_LIST_BY_TYPE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            bdType: AdjustmentType.DEDUCTION.toUpperCase(),
        },
    });

    const [getPayrollEmployeeData, {
        data: employeData, loading: loadingEmployees, error: employeeErrors,
    }] = useLazyQuery(DeductionsReportQuery.GET_DEDUCTIONS_PER_EMPLOYEE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (employeeErrors) {
            ModalUtils.errorMessage(employeeErrors?.graphQLErrors);
            return;
        }

        if (!loadingEmployees && employeData) {
            const { getDeductionsPerEmployee } = employeData;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                entity: 'employees',
                values: {
                    list: getDeductionsPerEmployee,
                },
            });
        }
    }, [employeData, loadingEmployees, employeeErrors]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPayrollDBListByType } = data;
            const value = ArrayUtils.sortByObjectField(getPayrollDBListByType, 'bdId');
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUE,
                field: 'list',
                entity: 'deductions',
                value,
            });
        }
    }, [data, loading, error]);

    const setSelectedRecordAndIndex = (record, index = null) => {
        dispatch({
            type: ACTION_TYPES.SET_DEDUCTION_INDEX_AND_RECORD,
            index,
            record,
            callback: getPayrollEmployeeData,
        });
    };

    const getDeductionsColumns = () => [{
        Header: 'ID',
        id: 'bdId',
        minWidth: 1,
        accessor: 'bdId',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Description',
        id: 'description',
        minWidth: 9,
        accessor: 'description',
    }];

    const getEmployeeColumns = () => [{
        Header: 'ID',
        id: 'employeeId',
        minWidth: 1,
        accessor: 'employeeId',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Employee Name',
        id: 'employeeName',
        minWidth: 6,
        accessor: 'employeeName',
    }, {
        Header: 'Amount',
        id: 'amount',
        minWidth: 3,
        accessor: 'amount',
        className: 'd-flex-justify-center-align-center',
    }];

    const sorting = () => {
        if (selectedIndex > -1) setSelectedRecordAndIndex(tableRef.table.state.sortedData[selectedIndex]._original);
    };

    return (
        <Split
            sizes={[20, 80]}
            className={classes.splitOne}
            minSize={0}
        >
            <div className={classes.tableHeight}>
                <Table
                    rowSelected
                    // eslint-disable-next-line no-return-assign
                    ref={(t) => (tableRef = t)}
                    load={loading}
                    totalRecords={list.count}
                    data={list}
                    columns={getDeductionsColumns()}
                    className="-highlight payrollTable"
                    getTrProps={(_, rowInfo) => ({
                        onClick: (event) => {
                            event.currentTarget.classList.add('active');
                            setSelectedRecordAndIndex(rowInfo.original, rowInfo.viewIndex);
                        },
                    })}
                    sortedChange={sorting}
                />
            </div>
            <div className={classes.tableHeight}>
                <Table
                    load={loadingEmployees}
                    totalRecords={employeeList.count}
                    data={employeeList}
                    columns={getEmployeeColumns()}
                    className="-highlight payrollTable"
                />
            </div>
        </Split>
    );
};
export default EmployeeDeductionsMapping;

import React, { Component } from 'react';

// Components and Ohters
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ZipUtils from 'utils/ZipUtils';

// Http
import CustomerMap from 'services/mapData/CustomerMap';
import GraphqlClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import DealService from 'services/modules/DealService';

const InsuranceDialogContainer = (WrappedComponent) => class extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        dealId: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        reload: PropTypes.func.isRequired,
        record: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const newRecord = props.record;
        const cities = [];
        if (newRecord && newRecord.city) {
            cities.push(newRecord.city);
        }

        this.graphqlClient = new GraphqlClient();
        this.dealService = new DealService();
        this.state = {
            saving: false,
            zipData: [],
            record: newRecord,
            listCity: cities,
            isDecoderEnabled: false,
            isDecodingZip: false,
        };

        this.initBind();
    }

    componentDidMount() {
        const { record: { insuranceZip } } = this.props;

        if (!StringUtils.isEmpty(insuranceZip)) {
            this.decodeZip(insuranceZip, true);
        }
    }

    onEnableDecoder() {
        const { state: { isDecoderEnabled } } = this;
        if (!isDecoderEnabled) {
            this.setState({ isDecoderEnabled: true });
        }
    }

    onChangeValue(field, value) {
        this.setState(({ record }) => {
            const newRecord = { ...record };
            newRecord[field] = value;
            return { record: newRecord };
        }, () => {
            if (field === 'insuranceZip' && value.length === 5) {
                const { state: { isDecoderEnabled } } = this;
                if (isDecoderEnabled) {
                    this.decodeZip(value);
                }
            }
        });
    }

    onChangeCity(field, value) {
        this.setState(({ record, zipData }) => {
            const newRecord = { ...record };
            if (zipData && zipData.length > 0) {
                const alreadyExist = zipData.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(value));
                if (!alreadyExist) {
                    const newData = clone(zipData[0]);
                    newData.city = value;
                    zipData.unshift(newData);
                }
            }

            const countyAndState = ZipUtils.getCountryAndStateByCity(value, zipData);
            newRecord.insuranceCity = value;
            if (!StringUtils.isEmpty(countyAndState.state)) newRecord.insuranceState = countyAndState.state;

            return { record: newRecord };
        });
    }

    onSave() {
        const { props: { toggleModal, reload, dealId }, state: { record } } = this;

        this.setState({ saving: true });

        const input = {
            dealId,
            input: {
                ...record,
            },
        };

        this.dealService.updateInsurance(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data && data.updateInsurance) {
                    reload();
                    toggleModal();
                    return;
                }

                this.setState({ saving: false });
            });
    }

    decodeZip(zip = '', ignoreCity = false) {
        const input = {
            zip,
        };

        this.setState({ isDecodingZip: true });
        this.graphqlClient
            .query(CustomerQuery.DECODE_ZIP_CODE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.decodeZip) {
                    const { decodeZip } = data;

                    /* In order to keep the city value, we need
                    to assign it using the record from database */
                    if (ignoreCity && decodeZip.length > 0) {
                        const { state: { record } } = this;
                        const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.insuranceCity));

                        if (!alreadyExist) {
                            const newData = clone(decodeZip[0]);
                            newData.city = record.insuranceCity;
                            decodeZip.unshift(newData);
                        }
                    }

                    const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                    if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                    const zipData = decodeZip;

                    this.setState(({ record, isDecoderEnabled }) => {
                        if (isDecoderEnabled) {
                            const newRecord = {
                                ...record,
                                insuranceCity: '',
                                insuranceState: '',
                            };
                            return {
                                listCity, zipData, record: newRecord, isDecodingZip: false,
                            };
                        }
                        return { listCity, zipData, isDecodingZip: false };
                    });

                    if (decodeZip.length === 1) {
                        const currentData = decodeZip[0];

                        this.setState(({ record }) => {
                            const newRecord = {
                                ...record,
                                insuranceCity: currentData.city,
                                insuranceState: currentData.state,
                            };

                            return { record: newRecord };
                        });
                    }
                }
            });
    }

    initBind() {
        this.decodeZip = this.decodeZip.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onEnableDecoder = this.onEnableDecoder.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                isSaveOrEdit={this.isSaveOrEdit}
                beforeSaving={this.beforeSaving}
                toggleConfirm={this.toggleConfirm}
                onChangeCity={this.onChangeCity}
                onChangeValue={this.onChangeValue}
                onChangeEndDate={this.onChangeEndDate}
                onEnableDecoder={this.onEnableDecoder}
            />
        );
    }
};

export default InsuranceDialogContainer;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions,
} from '@material-ui/core';
import { DateFormat } from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { GOAL_DATA_TYPE } from 'utils/enum/BusinessIntelligenceEnum';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

// icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const goalAutomaticOptions = BIHelper.getGoalAutomaticOptions();
const AddGoalDialog = ({
    classes,
    isDialogOpen,
    toggleDialog,
    toggleCategoryPanel,
    onChange,
    onSaveGoal,
    loadingCategories,
    savingGoal,
    categories,
    selectedLabel,
    selectedCategory,
    selectedType,
    selectedValue,
}) => {
    const [isAutomaticSelected, setIsAutomaticSelected] = useState(false);
    useEffect(() => {
        const goalFunction = goalAutomaticOptions.find((el) => el.function === selectedValue);
        setIsAutomaticSelected(!selectedValue ? false : !!goalFunction);
    }, [selectedValue]);

    const renderValueField = () => {
        const type = selectedType;

        switch (type) {
        case GOAL_DATA_TYPE.NUMERIC:
            return (
                <div className={classes.valueWrapper}>
                    {!isAutomaticSelected && (
                        <InputNumber
                            value={Number(selectedValue)}
                            onChange={(newValue) => onChange({ target: { name: 'selectedValue', value: newValue } })}
                            decimalScale={2}
                            allowNegative
                            thousandSeparator
                            size="sm"
                        />
                    )}
                    {isAutomaticSelected && (
                        <Select
                            nowrap
                            size="sm"
                            loading={false}
                            className={classes.input}
                            name="selectedValue"
                            onChange={(name, value) => onChange({ target: { name, value } })}
                            value={selectedValue || goalAutomaticOptions[0].function}
                            options={goalAutomaticOptions.map((el) => ({ value: el.function, label: el.label }))}
                        />
                    )}
                    <Select
                        nowrap
                        size="sm"
                        loading={false}
                        className={classes.input}
                        name="valueSource"
                        onChange={(_, value) => {
                            if (value === 'Manual') {
                                setIsAutomaticSelected(false);
                                onChange({ target: { name: 'selectedValue', value: 0 } });
                            } else {
                                setIsAutomaticSelected(true);
                                onChange({ target: { name: 'selectedValue', value: goalAutomaticOptions[0].function } });
                            }
                        }}
                        value={!isAutomaticSelected ? 'Manual' : 'Automatic'}
                        options={[
                            {
                                value: 'Manual',
                                label: 'Manual',
                            },
                            {
                                value: 'Automatic',
                                label: 'Automatic',
                            },
                        ]}
                    />
                </div>
            );
        case GOAL_DATA_TYPE.DATE_TIME:
            return (
                <DatePicker
                    size="sm"
                    showTimeSelect
                    timeIntervals={1}
                    timeFormat={DateFormat.DEFAULT_TIME}
                    dateFormat={
                        DateFormat
                            .DEFAULT_DATETIME_WITHOUT_SECONDS
                            .replace('DD', 'dd')
                            .replace('YYYY', 'yyyy')
                    }
                    selected={selectedValue}
                    className={clsx('form-control form-control-sm')}
                    popperPlacement="top-end"
                    popperContainer={CalendarContainer}
                    onChange={(date) => onChange({ target: { name: 'selectedValue', value: date } })}
                />
            );
        case GOAL_DATA_TYPE.ALPHANUMERIC:
            return (
                <Form.Control
                    className={classes.input}
                    type="text"
                    name="selectedValue"
                    value={selectedValue}
                    onChange={onChange}
                    maxLength={255}
                />
            );
        default:
            return null;
        }
    };

    const isAlphanumericSelected = selectedType === GOAL_DATA_TYPE.ALPHANUMERIC;
    return (
        <Dialog
            open={isDialogOpen}
            className={classes.dialog}
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Goal"
                onClose={() => { setIsAutomaticSelected(false); toggleDialog(); }}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Label (50 Characters Max. Only dashes allowed)</Form.Label>
                        <Form.Control
                            className={classes.input}
                            type="text"
                            name="selectedLabel"
                            value={selectedLabel || ''}
                            onChange={onChange}
                            maxLength={50}
                        />
                    </Form.Group>
                    <div className={classes.categoryWrapper}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>Category</Form.Label>
                            <Select
                                nowrap
                                size="sm"
                                loading={loadingCategories}
                                className={classes.input}
                                name="selectedCategory"
                                onChange={(name, value) => onChange({ target: { name, value } })}
                                value={selectedCategory || ''}
                                options={categories.map((cat) => ({ value: cat.id, label: cat.name?.toUpperCase() }))}
                            />
                        </Form.Group>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<AddOutlinedIcon />}
                            onClick={() => toggleCategoryPanel(true)}
                        />
                        <Button
                            disabled={StringUtils.isEmpty(selectedCategory)}
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleCategoryPanel(false)}
                        />
                    </div>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Type</Form.Label>
                        <Select
                            nowrap
                            size="sm"
                            className={classes.input}
                            name="selectedType"
                            onChange={(name, value) => onChange({ target: { name, value } })}
                            value={selectedType || ''}
                            options={Object.values(GOAL_DATA_TYPE).map((el) => ({ value: el, label: el }))}
                        />
                    </Form.Group>
                    <Form.Group className={classes.dynamicFieldWrapper} as={Col}>
                        <Form.Label className={classes.subLabel}>
                            {isAlphanumericSelected ? 'Value (255 Characters Max)' : 'Value'}
                        </Form.Label>
                        {renderValueField()}
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Form.Group as={Col}>
                    <Button
                        size="small"
                        className={classes.containedSecondaryInfo}
                        disabled={
                            savingGoal
                            || StringUtils.isEmpty(selectedLabel)
                            || StringUtils.isEmpty(selectedCategory)
                            || StringUtils.isEmpty(selectedValue)
                        }
                        onClick={onSaveGoal}
                    >
                        Save
                    </Button>
                </Form.Group>
            </DialogActions>
        </Dialog>
    );
};

AddGoalDialog.defaultProps = {
    selectedLabel: null,
    selectedCategory: null,
    selectedType: null,
    selectedValue: null,
};

AddGoalDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    toggleDialog: PropTypes.func.isRequired,
    toggleCategoryPanel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSaveGoal: PropTypes.func.isRequired,
    loadingCategories: PropTypes.bool.isRequired,
    savingGoal: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    selectedLabel: PropTypes.string,
    selectedCategory: PropTypes.string,
    selectedType: PropTypes.string,
    selectedValue: PropTypes.any,
};

export default AddGoalDialog;

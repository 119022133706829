import { gql } from '@apollo/client';

export default class UnderwritingMutation {
    static VALIDATE_INTEGRATION_CREDENTIALS = gql`
        mutation validateIntegrationCredentials(
            $vendorName: String!
            $user: String!
            $password: String
            $lotId: Int
            $bureausSetting: [BureauSetup!]!
        ) {
            validateIntegrationCredentials(
                input: {
                    vendorName: $vendorName
                    user: $user
                    lotId: $lotId
                    password: $password
                    bureausSetting: $bureausSetting
                }
            )
        }
    `;

    static NEW_API_VENDOR_INTEGRATION = gql`
        mutation setUpDealerIntegration(
            $apiIntegrationVendorId: Int!
            $bureausSetting: [BureauSetup!]!
            $active: Boolean
            $credentials: CredentialsInput!
            $lotId: Int
        ) {
            setUpDealerIntegration(
                input: {
                    apiIntegrationVendorId: $apiIntegrationVendorId
                    bureausSetting: $bureausSetting
                    active: $active
                    credentials: $credentials
                    lotId: $lotId
                }
            )
        }
    `;

    static UPDATE_API_INTEGRATIONS_BY_COMPANY = gql`
        mutation updateDealerIntegration(
            $companyApiIntegrationId: Int!
            $apiIntegrationVendorId: Int!
            $bureausSetting: [BureauSetup!]!
            $active: Boolean
            $credentials: CredentialsInput!
        ) {
            updateDealerIntegration(
                input: {
                    companyApiIntegrationId: $companyApiIntegrationId
                    apiIntegrationVendorId: $apiIntegrationVendorId
                    bureausSetting: $bureausSetting
                    active: $active
                    credentials: $credentials
                }
            )
        }
    `;

    static VALIDATE_NEO_INTEGRATION = gql`
        mutation validateNeoIntegration($token: String) {
            validateNeoIntegration(token: $token)
        }
    `;

    static CREATE_NEO_INTEGRATION = gql`
        mutation createNeoIntegration($input: CreateNeoInput) {
            createNeoIntegration(input: $input)
        }
    `;

    static UPDATE_NEO_INTEGRATION = gql`
        mutation updateNeoIntegraton($input: UpdateNeoInput) {
            updateNeoIntegraton(input: $input)
        }
    `;
}

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Button, Grid,
    FormControl, FormControlLabel,
    Radio, RadioGroup, Checkbox,
    Dialog, DialogContent, DialogActions,
} from '@material-ui/core';
import {
    arrayMove,
} from 'react-sortable-hoc';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import { DescriptionSentenceMapping } from 'utils/enum/InventoryEnum';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import ButtonStyles from 'styles/theme/Button';
import { Col, Form } from 'react-bootstrap';
import If from 'components/widgets/conditional/If';
import VirtualTable from 'components/widgets/VirtualTable';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import Select from 'components/widgets/Select';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        overflow: 'auto',
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    boxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    paddingLeft25: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    otherActions: {
        [theme.breakpoints.down('sm')]: {
            '& > button:nth-child(1)': {
                marginBottom: '5px',
            },
        },
    },
    tableGrid: {
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    notification: {
        fontSize: '13px',
        fontWeight: 500,
        marginTop: '15px',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '350px',
        overflow: 'hidden',
        minWidth: '1000px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    checkboxWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '15px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    dialogLabels: {
        fontSize: '13px',
    },
    input: {
        fontSize: '13px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
    mapping: {
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
            width: '200px',
            marginLeft: '5px',
            '& > div': {
                minHeight: '34px',
            },
        },
    },
    paragraphWrapper: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > div': {
            width: '70px',
            fontSize: '13px',
        },
    },
    aiSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        flexDirection: 'row',
        marginLeft: '15px',
        paddingTop: '5px',
        '& > span': {
            padding: 0,
        },
        '& > div': {
            display: 'flex',
            marginRight: '10px',
            '& > div': {
                width: '250px',
                marginLeft: '10px',
                '& > div': {
                    minHeight: '30px',
                },
            },
        },
        '& > button': {
            padding: 0,
            height: '36px',
            minWidth: '80px',
        },
    },
}));

const DescriptionSettings = ({ canWrite }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        currentFilter: 0,
        sentences: [],
        currentSentences: [],
        selectedItem: {},
        isRemoveWarningOpen: false,
        isAddFormOpen: false,
        aiEnabled: false,
        aiAssistant: null,
        assistants: [],
    });

    const {
        data: assistantsData,
        loading: loadingSssistants,
        error: errorLoadingAssistants,
    } = useQuery(AIQuery.PULL_ASSISTANTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: sentencesData,
        loading: loadingSentences,
        error: errorLoadingSentences,
        refetch,
    } = useQuery(InventoryQuery.PULL_SENTENCES_FOR_DESCRIPTION, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const {
        AI_ENABLED_FOR_VEHICLE_DESCRIPTION,
        AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION,
    } = LotDefaultskey;

    const {
        data: aiSettings,
        loading: aiSettingsLoading,
        error: aiSettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [
                AI_ENABLED_FOR_VEHICLE_DESCRIPTION,
                AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveSettings, { loading: isSavingSettings }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (aiSettingsError) {
            ModalUtils.errorMessage(aiSettingsError?.graphQLErrors);
            return;
        }

        if (!aiSettingsLoading && aiSettings) {
            const { getSettings } = aiSettings;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                const aiEnabled = getSettings.find((setting) => setting.key === AI_ENABLED_FOR_VEHICLE_DESCRIPTION)?.value ?? 'false';
                const aiAssistant = getSettings.find((setting) => setting.key === AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION)?.value;

                setState((prevState) => ({
                    ...prevState,
                    aiEnabled: aiEnabled === 'true',
                    aiAssistant,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiSettingsLoading, aiSettingsError]);

    useEffect(() => {
        if (errorLoadingAssistants) {
            ModalUtils.errorMessage(errorLoadingAssistants?.graphQLErrors);
            return;
        }

        if (!loadingSssistants && assistantsData) {
            setState((prevState) => ({
                ...prevState,
                assistants: assistantsData?.pullAsistants,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSssistants, errorLoadingAssistants]);

    useEffect(() => {
        if (errorLoadingSentences) {
            ModalUtils.errorMessage(errorLoadingSentences?.graphQLErrors);
            return;
        }

        if (!loadingSentences && sentencesData) {
            const { pullSentencesForDescription } = sentencesData;
            if (ArrayUtils.isNotEmpty(pullSentencesForDescription)) {
                const sentences = pullSentencesForDescription;

                setState((prevState) => ({
                    ...prevState,
                    sentences,
                    currentSentences: sentences.filter((el) => el.active !== Boolean(state.currentFilter)),
                    selectedItem: {},
                    isRemoveWarningOpen: false,
                    isAddFormOpen: false,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSentences, errorLoadingSentences]);

    useEffect(() => {
        const { sentences, currentFilter } = state;

        setState((prevState) => ({
            ...prevState,
            currentSentences: sentences.filter((el) => el.active !== Boolean(currentFilter)),
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentFilter]);

    const changeFilter = (value) => {
        setState((prevState) => ({
            ...prevState,
            currentFilter: value,
        }));
    };

    const sortRow = async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
            let itemsSorted = arrayMove(
                state.currentSentences,
                oldIndex,
                newIndex,
            );

            itemsSorted = itemsSorted.map((item, index) => ({
                ...item,
                order: index + 1,
            }));

            const clone = cloneDeep(state.sentences);
            itemsSorted.forEach((item) => {
                const current = clone.find((el) => el.id === item.id);
                if (current) current.order = item.order;
            });

            setState((prevState) => ({
                ...prevState,
                sentences: clone.sort((a, b) => a.order - b.order),
                currentSentences: itemsSorted,
            }));

            itemsSorted.forEach((item) => {
                client.mutate({
                    mutation: InventoryMutation.EDIT_SENTENCE_FOR_DESCRIPTION,
                    variables: {
                        ...item,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                });
            });
        }
    };

    const onConfirmDialogToggle = (record = {}) => {
        setState((prevState) => ({
            ...prevState,
            selectedItem: record,
            isRemoveWarningOpen: !state.isRemoveWarningOpen,
        }));
    };

    const removeSentence = async () => {
        try {
            const { selectedItem, currentSentences } = state;
            const promises = [];
            promises.push(
                client.mutate({
                    mutation: InventoryMutation.EDIT_SENTENCE_FOR_DESCRIPTION,
                    variables: {
                        ...selectedItem,
                        active: false,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                }),
            );

            const otherSentencesSorted = currentSentences
                .filter((el) => el.id !== selectedItem.id)
                .map((item, index) => ({ ...item, order: index + 1 }));

            otherSentencesSorted.forEach((item) => {
                promises.push(
                    client.mutate({
                        mutation: InventoryMutation.EDIT_SENTENCE_FOR_DESCRIPTION,
                        variables: {
                            ...item,
                        },
                        fetchPolicy: FetchPolicy.NO_CACHE,
                    }),
                );
            });

            await Promise.all(promises);
            refetch();
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const restoreSentence = async (record) => {
        try {
            const activeItems = state.sentences.filter((el) => el.active === true);
            const nextPosition = activeItems.length + 1;
            await client.mutate({
                mutation: InventoryMutation.EDIT_SENTENCE_FOR_DESCRIPTION,
                variables: {
                    ...record,
                    order: nextPosition,
                    active: true,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            refetch();
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    };

    const toggleAddForm = (record = {}) => {
        setState((prevState) => ({
            ...prevState,
            selectedItem: record,
            isAddFormOpen: !state.isAddFormOpen,
        }));
    };

    const onChangeAI = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChange = (event) => {
        const { name, value, checked } = event.target;
        const clone = cloneDeep(state.selectedItem);
        const isParagraph = name === 'isParagraph';
        if (!isParagraph) clone[name] = value;
        if (isParagraph) clone[name] = checked;

        setState((prevState) => ({
            ...prevState,
            selectedItem: clone,
        }));
    };

    const saveSentence = async () => {
        const { selectedItem } = state;
        const activeItems = state.sentences.filter((el) => el.active === true);
        const nextPosition = activeItems.length + 1;

        await client.mutate({
            mutation: InventoryMutation.EDIT_SENTENCE_FOR_DESCRIPTION,
            variables: {
                ...selectedItem,
                ...(!selectedItem.id ? {
                    order: nextPosition,
                    isParagraph: selectedItem.isParagraph || false,
                } : {}),
            },
            fetchPolicy: FetchPolicy.NO_CACHE,
        });

        ModalUtils.successMessage(null, `Sentence ${!selectedItem.id ? 'added' : 'modified'} successfully`);
        refetch();
    };

    const saveAISettings = async () => {
        const { aiEnabled, aiAssistant } = state;

        saveSettings({
            variables: {
                category: LotsCategory.INVENTORY,
                key: AI_ENABLED_FOR_VEHICLE_DESCRIPTION,
                value: aiEnabled ? 'true' : 'false',
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        saveSettings({
            variables: {
                category: LotsCategory.INVENTORY,
                key: AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION,
                value: aiAssistant,
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Order',
            dataKey: 'order',
            width: 80,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!record.active) return null;

                return (
                    <span>{record.order}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Sentence',
            dataKey: 'sentence',
            width: isMobile ? 300 : 600,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.sentence}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Paragraph',
            dataKey: 'isParagraph',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.checkboxWrapper}>
                        <Checkbox
                            color="primary"
                            style={{ padding: 0 }}
                            checked={record.isParagraph}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    </div>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!canWrite) return null;

                if (!record.active) {
                    return (
                        <div className={classes.actionsContainer}>
                            <Button
                                disabled={isSavingSettings}
                                className={classes.containedInfo}
                                size="small"
                                startIcon={<RestoreFromTrashIcon />}
                                onClick={() => restoreSentence(record)}
                            />
                        </div>
                    );
                }

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            disabled={isSavingSettings}
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleAddForm(record)}
                        />
                        <Button
                            disabled={isSavingSettings}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => onConfirmDialogToggle(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const columns = getColumns();
    const parentWidth = document.getElementById('table-container')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 30;
    return (
        <>
            <div className={classes.notification}>Templates | Click and drag to reorder sentences</div>
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                        <RadioGroup row value={state.currentFilter} onChange={(e, val) => changeFilter(Number(val))}>
                            <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="Active"
                            />
                            <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label="Inactive"
                            />
                        </RadioGroup>
                        <div className={classes.otherActions}>
                            <If condition={canWrite}>
                                <Button
                                    disabled={isSavingSettings}
                                    size="small"
                                    startIcon={<AddOutlinedIcon />}
                                    className={classes.containedSecondaryInfo}
                                    onClick={toggleAddForm}
                                >
                                    Add Sentence
                                </Button>
                            </If>
                        </div>
                    </FormControl>
                </Grid>
                <Grid className={classes.tableGrid} item xs={12}>
                    <div id="table-container" className={classes.tableContainer}>
                        <VirtualTable
                            areRowsDraggable={state.currentFilter === 0}
                            sortRow={sortRow}
                            loading={loadingSentences}
                            rowHeight={45}
                            totalRecords={state.currentSentences.length}
                            data={state.currentSentences}
                            columns={columns}
                            width={tableWidth}
                        />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.notification}>AI Enablement</div>
            <Grid container className={classes.box}>
                <div className={classes.aiSection}>
                    <Checkbox
                        color="primary"
                        value="selected"
                        name="aiEnabled"
                        style={{ padding: 0 }}
                        checked={state.aiEnabled}
                        onChange={(e) => onChangeAI(e.target.name, e.target.checked)}
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                    <Select
                        nowrap
                        placeholder="Assistant"
                        className={clsx(classes.input, state.aiEnabled && !state.aiAssistant ? 'invalid-field' : '')}
                        name="aiAssistant"
                        value={state.aiAssistant}
                        onChange={(name, value) => onChangeAI(name, value)}
                        options={state.assistants.map(({ aiAssistantId, name }) => ({ label: name, value: aiAssistantId }))}
                    />
                    <Button
                        disabled={(state.aiEnabled && !state.aiAssistant) || isSavingSettings}
                        size="small"
                        className={classes.containedSecondaryInfo}
                        onClick={saveAISettings}
                    >
                        Save
                    </Button>
                </div>
            </Grid>
            <Dialog
                open={state.isAddFormOpen}
                maxWidth="sm"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Add/Edit Sentence"
                    onClose={toggleAddForm}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.content}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.dialogLabels}>Sentence (2000 Chars Max)</Form.Label>
                            <div className={classes.mapping}>
                                <Form.Control
                                    maxLength="2000"
                                    className={classes.input}
                                    type="text"
                                    name="sentence"
                                    value={state.selectedItem?.sentence || ''}
                                    onChange={onChange}
                                />
                                <Select
                                    nowrap
                                    className={classes.input}
                                    placeholder="Mapping..."
                                    name="sentence"
                                    onChange={(name, value) => onChange({ target: { name, value } })}
                                    options={[
                                        {
                                            value: DescriptionSentenceMapping.KEY_FEATURES,
                                            label: DescriptionSentenceMapping
                                                .KEY_FEATURES
                                                .replace(/\[/g, '')
                                                .replace(/\]/g, ''),
                                        },
                                    ]}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className={classes.paragraphWrapper} as={Col}>
                            <div>Paragraph</div>
                            <Checkbox
                                color="primary"
                                value="selected"
                                name="isParagraph"
                                style={{ padding: 0 }}
                                checked={state.selectedItem?.isParagraph || false}
                                onChange={onChange}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        </Form.Group>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Form.Group as={Col}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            disabled={
                                StringUtils.isEmpty(state.selectedItem?.sentence)
                            }
                            onClick={saveSentence}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="Attention!"
                description="This sentence will be deactivated. Later you can reactivate it on the inactivate sentences. Continue?"
                open={state.isRemoveWarningOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => onConfirmDialogToggle()}
                onClickSecondary={() => onConfirmDialogToggle()}
                onClickPrimary={removeSentence}
            />
        </>
    );
};

DescriptionSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default DescriptionSettings;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import clsx from 'clsx';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog,
} from '@material-ui/core';

const styles = (theme = {}) => ({
    dialogContent: {
        padding: theme.spacing(2),
    },
    formW100: {
        width: '100%',
    },
    styleColLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignTextEnd: {
        textAlign: 'end',
    },
    labelStyle: {
        marginBottom: '2px !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        color: '#767676',
    },
    formGroupMarginTop: {
        marginTop: '10px',
    },
    switchMargin: {
        marginLeft: '10px',
    },
});

// TODO: Move this constant to a utility
const TX = 'TX';

class SalesTaxDialog extends Component {
    render() {
        const {
            props: {
                onChangeSalesTax, onCancelSalesTax,
                open, onSaveSalesTax, classes,
                salesTaxData: {
                    taxExempt,
                    salesTaxOverride,
                    inventoryTaxOverride,
                    salesTax,
                    inventoryTaxAmount,
                    salesTaxRate,
                    overrideTaxRate,
                },
                lotSetting: {
                    lotState,
                },
                isCalculatingDeal,
                isWholesale,
            },
        } = this;
        const isTXState = lotState === TX;
        const displayInventoryTax = (isTXState && !isWholesale) || inventoryTaxAmount > 0;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Sales Tax" onClose={onCancelSalesTax} iconSize="sm" titleVariant="h4" />
                <DialogContent className={classes.dialogContent} dividers>
                    <Form className={clsx(classes.formW100, 'am-form')}>
                        <Form.Row>
                            <Col>
                                <Form.Group className={classes.formGroupMarginTop}>
                                    <Form.Label className="blank-label" />
                                    <Row noGutters className={classes.styleColLeft}>
                                        <Col className={classes.alignTextEnd} xs={8}>
                                            <Form.Label className={classes.labelStyle}>Tax Exempt:</Form.Label>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Check
                                                checked={taxExempt}
                                                className={classes.switchMargin}
                                                type="switch"
                                                id="tax-exempt-switch"
                                                label=""
                                                onChange={(e) => onChangeSalesTax('taxExempt', e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col />
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group className={classes.formGroupMarginTop}>
                                    <Form.Label className="blank-label" />
                                    <Row noGutters className={classes.styleColLeft}>
                                        <Col className={classes.alignTextEnd} xs={8}>
                                            <Form.Label className={classes.labelStyle}>Enter Tax Rate:</Form.Label>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Check
                                                checked={overrideTaxRate}
                                                className={classes.switchMargin}
                                                type="switch"
                                                id="tax-rate-switch"
                                                label=""
                                                onChange={(e) => onChangeSalesTax('overrideTaxRate', e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tax Rate</Form.Label>
                                    <InputNumber
                                        disabled={taxExempt || !overrideTaxRate}
                                        value={salesTaxRate}
                                        onChange={(value) => onChangeSalesTax('salesTaxRate', value)}
                                        placeholder="0.00"
                                        thousandSeparator
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group className={classes.formGroupMarginTop}>
                                    <Form.Label className="blank-label" />
                                    <Row noGutters className={classes.styleColLeft}>
                                        <Col className={classes.alignTextEnd} xs={8}>
                                            <Form.Label className={classes.labelStyle}>Tax Override:</Form.Label>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Check
                                                checked={salesTaxOverride}
                                                className={classes.switchMargin}
                                                type="switch"
                                                id="tax-override-switch"
                                                label=""
                                                onChange={(e) => onChangeSalesTax('salesTaxOverride', e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tax Amount</Form.Label>
                                    <InputNumber
                                        disabled={taxExempt || !salesTaxOverride}
                                        value={salesTax}
                                        onChange={(value) => onChangeSalesTax('salesTax', value)}
                                        placeholder="0.00"
                                        showCurrency
                                        thousandSeparator
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        { displayInventoryTax && (
                            <Form.Row>
                                <Col>
                                    <Form.Group className={classes.formGroupMarginTop}>
                                        <Form.Label className="blank-label" />
                                        <Row noGutters className={classes.styleColLeft}>
                                            <Col className={classes.alignTextEnd} xs={8}>
                                                <Form.Label className={classes.labelStyle}>Inventory Tax Override:</Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Check
                                                    checked={inventoryTaxOverride}
                                                    className={classes.switchMargin}
                                                    type="switch"
                                                    id="inventory-tax-switch"
                                                    label=""
                                                    onChange={(e) => onChangeSalesTax('inventoryTaxOverride', e.target.checked)}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Inventory Tax</Form.Label>
                                        <InputNumber
                                            disabled={taxExempt || !inventoryTaxOverride}
                                            value={inventoryTaxAmount}
                                            onChange={(value) => onChangeSalesTax('inventoryTaxAmount', value)}
                                            placeholder="0.00"
                                            showCurrency
                                            thousandSeparator
                                            size="sm"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions
                    titlePrimary="Apply"
                    onClickSecondary={onCancelSalesTax}
                    onClickPrimary={onSaveSalesTax}
                    disablePrimaryButton={isCalculatingDeal || (overrideTaxRate && salesTaxRate <= 0)}
                />
            </Dialog>
        );
    }
}

SalesTaxDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    lotSetting: PropTypes.object.isRequired,
    salesTaxData: PropTypes.shape({
        taxExempt: PropTypes.bool,
        salesTaxOverride: PropTypes.bool,
        inventoryTaxOverride: PropTypes.bool,
        salesTax: PropTypes.number,
        inventoryTaxAmount: PropTypes.number,
        salesTaxRate: PropTypes.number,
        overrideTaxRate: PropTypes.bool,
    }).isRequired,
    onChangeSalesTax: PropTypes.func.isRequired,
    onCancelSalesTax: PropTypes.func.isRequired,
    onSaveSalesTax: PropTypes.func.isRequired,
    isCalculatingDeal: PropTypes.bool.isRequired,
    isWholesale: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SalesTaxDialog);

import { gql } from '@apollo/client';

export default class FormsQuery {
    static GET_FORM_PACKS = gql`
        query getFormPacks($lotId: Int!) {
            getFormPacks(lotId: $lotId) {
                packId
                packName
                lotName
                lotId
            }
        }
    `;

    static GET_FORM_BY_FORM_PACKS = gql`
        query getFormByFormPacks($packId: Int!) {
            getFormByFormPacks(packId: $packId) {
                id
                commonName
                category
                formPrintingType
                originalName
                defaultChecked
            }
        }
    `;

    static GET_ALL_AVAILABLE_FORMS = gql`
        query getAllAvailableForms($lotState: String!, $packId: Int!, $search: String) {
            getAllAvailableForms(lotState: $lotState, packId: $packId, search: $search) {
                id
                commonName
                originalName
                category
                originalName
                formPrintingType
            }
        }
    `;

    static GET_CUSTOM_FIELD_BY_FORMS = gql`
        query getCustomFieldByForms($forms: [Int!], $dealId: Int!) {
            getCustomFieldByForms(forms: $forms, dealId: $dealId) {
                id
                name
                originalName
                commonName
                customField {
                    customFieldId      
                    data
                }
                secureCloseMapID,
                secureCloseDocumentName,
            }
        }
    `;

    static GET_SECURE_CLOSE_FORMS_LIST = gql`
        query getSecureCloseFormsList($state: String!) {
            getSecureCloseFormsList(state: $state) {
                id
                commonName
                originalName
                secureCloseMapID,
                secureCloseDocumentName,
            }
        }
    `;
}

import React, { PureComponent } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Box, Button, Grid, IconButton, Typography,
} from '@material-ui/core';
import {
    BorderColorOutlined as BorderColorOutlinedIcon,
    Visibility as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import clsx from 'clsx';

// Components and Others
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import LabelValue from 'components/widgets/LabelValue';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import AddressDialog from 'components/widgets/customer/AddressDialog';
import ManageAddress from 'components/widgets/customer/ManageAddress';
import MultipleLineValues from 'components/widgets/customer/MultipleLineValues';
import AddressSectionContainer from 'components/containers/widgets/customers/AddressSectionContainer';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { isEmpty } from 'lodash';

const styles = (theme) => ({
    customer: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
    },
    noDataText: {
        minHeight: 100,
    },
    textActivities: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        marginLeft: theme.spacing(0.5),
        color: theme.palette.text.blueGrey,
    },
    boxContentActivities: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textIcon: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing(1.5),
        cursor: 'pointer',
        color: theme.palette.text.infoDark,
    },
    title: {
        color: theme.palette.text.secondaryPurpure,
    },
    addButton: {
        padding: theme.spacing(1),
        '&:disabled svg g': {
            fill: `rgba(${theme.palette.rgb.black},0.26)`,
        },
    },
});

class AddressSection extends PureComponent {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    renderSectionActions() {
        const {
            props: {
                classes, toggleModal, toggleManageAddress, showAddButton,
                labelManage, showNoDataText, addressInformation, addIcon,
                loading,
            },
            CUSTOMER_WRITE,
        } = this;
        const isEmptyAddress = showNoDataText && isEmpty(addressInformation);
        const icon = CUSTOMER_WRITE ? (<BorderColorOutlinedIcon />) : <VisibilityOutlinedIcon />;

        return (
            <Box
                className={classes.boxContentActivities}
            >
                {showAddButton && CUSTOMER_WRITE && (
                    <IconButton
                        aria-label="delete"
                        size="small"
                        disabled={loading}
                        onClick={toggleModal}
                        className={classes.addButton}
                    >
                        {addIcon}
                    </IconButton>
                )}
                {!isEmptyAddress && (
                    <Box className={classes.textIcon}>
                        <Button
                            color="primary"
                            size="small"
                            startIcon={icon}
                            onClick={toggleManageAddress}
                        >
                            {labelManage}
                        </Button>
                    </Box>
                )}
            </Box>
        );
    }

    render() {
        const {
            props: {
                classes, addressInformation, toggleModal, open,
                openDialogManage, toggleManageAddress, customerId,
                onEdit, isEditing, currentAddress, openManage, reloadManagerAddress,
                makeReloadAddress, showNoDataText,
            },
        } = this;
        // TODO: remove this after get data from API
        const {
            phone,
            housingStatus,
            mortgageOrRent,
            address1,
            address2,
            city,
            state,
            zipCode,
            county,
            country,
            totalMonths,
        } = addressInformation || {};
        const address = [
            address1,
            address2,
            `${city || ''}, ${state || ''}`,
            zipCode,
            county,
            country,
        ];
        const years = Math.floor((totalMonths || 0) / 12);
        const months = Math.floor((totalMonths || 0) % 12);
        const yearString = `${years} year + `;
        const validationYears = `${years > 0 ? yearString : ''} ${months} months`;
        const period = totalMonths ? validationYears : '';
        const isEmptyAddress = showNoDataText && isEmpty(addressInformation);

        return (
            <BoxWidget
                className={clsx(classes.paper, { [classes.noDataText]: isEmptyAddress })}
                title="Address"
                typeBox="general"
                titleClass={classes.title}
                rightHeaderSection={this.renderSectionActions()}
                bodyClass={isEmptyAddress ? 'd-flex-justify-center-align-center' : ''}
            >
                {isEmptyAddress ? <Typography>No Current Address</Typography> : (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <MultipleLineValues
                                label="Address"
                                values={address}
                            />
                        </Grid>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="Phone Number"
                                value={phone}
                            />
                            <LabelValue
                                label="Housing Status"
                                value={housingStatus}
                            />
                            <LabelValue
                                label="Rent/Morgage"
                                value={NumberUtils.applyCurrencyFormat(mortgageOrRent)}
                            />
                            <LabelValue
                                label="Period"
                                value={period}
                            />
                        </Grid>
                    </Grid>
                )}
                {open && (
                    <AddressDialog
                        open={open}
                        toggleModal={toggleModal}
                        makeReloadAddress={makeReloadAddress}
                        customerId={customerId}
                        isEditing={isEditing}
                        record={currentAddress}
                        openManage={openManage}
                        toggleManageAddress={toggleManageAddress}
                    />
                )}
                {openDialogManage && (
                    <ManageAddress
                        open={openDialogManage}
                        toggleModal={toggleManageAddress}
                        makeReloadAddress={makeReloadAddress}
                        customerId={customerId}
                        onEdit={onEdit}
                        openAddress={toggleModal}
                        reloadManagerAddress={reloadManagerAddress}
                    />
                )}
            </BoxWidget>
        );
    }
}

AddressSection.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    addressInformation: PropTypes.object,
    open: PropTypes.bool.isRequired,
    openDialogManage: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    makeReloadAddress: PropTypes.func.isRequired,
    toggleManageAddress: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    currentAddress: PropTypes.object.isRequired,
    openManage: PropTypes.func.isRequired,
    reloadManagerAddress: PropTypes.bool.isRequired,
    showAddButton: PropTypes.bool,
    showNoDataText: PropTypes.bool,
    labelManage: PropTypes.string,
    addIcon: PropTypes.element,
    loading: PropTypes.bool,
};

AddressSection.defaultProps = {
    showAddButton: true,
    labelManage: 'Manage',
    addressInformation: {},
    showNoDataText: false,
    addIcon: <PlusIcon />,
    loading: false,
};

export default withStyles(styles)(AddressSectionContainer(AddressSection));

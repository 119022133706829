import { gql } from '@apollo/client';

export default class SettingsQuery {
    static GET_PURCHASED_FROM = gql`
        {
            getPurchasedFrom {
                purchasedFromId
                purchasedFrom
            }
        }
    `;

    static GET_PURCHASING_AGENT = gql`
        {
            getPurchasingAgent {
                purchasingAgentsId
                agent
            }
        }
    `;

    static GET_SIDE_MENU_SETTINGS = gql`
        {
            getSideMenuSettings {
                userSettingId
                key
                value
            }
        }
    `;
}

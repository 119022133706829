import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import { useLazyQuery } from '@apollo/client';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';
import JournalDetailMap from 'services/mapData/JournalDetailMap';
import { AccountingSubModules, BankingProcessTypes } from 'utils/enum/AccountingEnum';
import DateUtils from 'lib/DateUtils';
import { FetchPolicy } from 'utils/enum/Core';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

const searchStyles = makeStyles((theme) => AccountingStyles.searchStyles(theme));
const columnStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const dateCalendarStyles = makeStyles((theme) => AccountingStyles.dateCalendarStyles(theme));
const basicStyles = makeStyles((theme) => AccountingStyles.basicStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));

const ACTION_TYPES = {
    SET_TABLE_RECORDS: 'setTableRecords',
    SET_PAGING: 'setPaging',
    SET_STATE_VALUES: 'setStateValues',
};

const journalDetailReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE_RECORDS:
        return {
            ...state,
            table: {
                records: action.records,
                totalCount: action.totalCount,
            },
            search: action.clearSearch ? '' : state.search,
        };
    case ACTION_TYPES.SET_PAGING:
        return { ...state, paginate: { ...state.paginate, init: action.value } };
    case ACTION_TYPES.SET_STATE_VALUES:
        return { ...state, ...action.value };
    default:
        return state;
    }
};

const JournalDetail = ({ cbId, showDebitCreditAsAmount, processType }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...searchStyles(),
        ...columnStyles(),
        ...dateCalendarStyles(),
        ...basicStyles(),
        ...containerStyles(),
    };

    const initialState = {
        paginate: {
            init: 0,
            limit: 100,
            ignoreLimit: false,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        search: '',
        useDateRange: true,
        fromDate: new Date(DateUtils.subtractAndFormatUTC(new Date(), 3, 'months')),
        toDate: new Date(),
    };

    const [journalDetailData, dispatch] = useReducer(journalDetailReducer, initialState);

    const [loadJournalDetail, { loading, error }] = useLazyQuery(
        AccountingGLQuery.GET_ACCOUNTING_GL_LIST_FOR_BANKING,
        {
            onCompleted: (result) => {
                const { getAccountingGLList: { data, totalCount } } = result;
                const mapValues = JournalDetailMap.glLines(data);
                dispatch({
                    type: ACTION_TYPES.SET_TABLE_RECORDS,
                    records: mapValues,
                    totalCount,
                });
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (cbId > 0) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
                clearSearch: true,
            });
            loadJournalDetail({
                variables: {
                    paginate: {
                        ...journalDetailData.paginate,
                        init: 0,
                    },
                    filter: {
                        cbId,
                        showOnlyDebitLines: processType === BankingProcessTypes.CHECK.toLowerCase(),
                        showOnlyCreditLines: processType === BankingProcessTypes.DEPOSIT.toLowerCase(),
                    },
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cbId, error]);

    const loadMore = () => {
        const { table } = journalDetailData;
        const currentPage = table?.records?.length || 0;
        dispatch({ type: ACTION_TYPES.SET_PAGING, value: currentPage });
    };

    const onFilterChange = (param, value) => {
        if ((param === 'fromDate' || param === 'toDate') && DateUtils.getOnlyDate(journalDetailData[param]) === DateUtils.getOnlyDate(value)) return;
        if (journalDetailData[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                [param]: value,
            },
        });
    };

    useEffect(() => {
        if (journalDetailData.search) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
            });
            loadJournalDetail({
                variables: {
                    paginate: {
                        ...journalDetailData.paginate,
                        init: 0,
                        ignoreLimit: true,
                    },
                    filter: {
                        search: journalDetailData.search,
                        subModule: AccountingSubModules.BANKING,
                        useDateRange: journalDetailData.useDateRange,
                        fromDate: journalDetailData.fromDate,
                        toDate: journalDetailData.toDate,
                    },
                },
            });
        }

        if (!journalDetailData.search) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE_RECORDS,
                records: [],
                totalCount: 0,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journalDetailData.search, journalDetailData.useDateRange, journalDetailData.fromDate, journalDetailData.toDate]);

    const getColumns = () => {
        const columns = [
            {
                minWidth: 30,
                width: 30,
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
                Cell: (cell) => cell.viewIndex + 1,
            },
            {
                Header: 'Account #',
                minWidth: 180,
                width: 180,
                id: 'accountNumber',
                accessor: 'accountDescription',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
            {
                Header: 'Post Date',
                minWidth: 90,
                width: 90,
                id: 'postDate',
                accessor: 'postDate',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
                className: clsx(classes.columnStyle, classes.columnCenter),
                Cell: ({ value }) => DateUtils.getOnlyDate(value),
            },
            {
                Header: 'Control #',
                minWidth: 80,
                width: 80,
                id: 'controlNumber',
                accessor: 'controlNumber',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
            {
                Header: 'Check / Deposit #',
                minWidth: 140,
                width: 140,
                id: 'referenceNumber',
                accessor: 'referenceNumber',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
            {
                Header: 'GL Type',
                minWidth: 150,
                width: 150,
                id: 'glType',
                accessor: 'glType',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
        ];

        if (showDebitCreditAsAmount) {
            columns.push(
                {
                    Header: 'Amount',
                    minWidth: 60,
                    width: 60,
                    id: 'amount',
                    accessor: 'amount',
                    headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
                    className: clsx(classes.columnStyle, classes.columnRight),
                    Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
                },
            );
        } else {
            columns.push(
                {
                    Header: 'Debit',
                    minWidth: 80,
                    width: 80,
                    id: 'debit',
                    accessor: 'debit',
                    headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
                    className: clsx(classes.columnStyle, classes.columnRight),
                    Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
                },
                {
                    Header: 'Credit',
                    minWidth: 80,
                    width: 80,
                    id: 'credit',
                    accessor: 'credit',
                    headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
                    className: clsx(classes.columnStyle, classes.columnRight),
                    Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
                },
            );
        }

        columns.push(
            {
                Header: 'Memo',
                minWidth: 200,
                id: 'memo',
                accessor: 'memo',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
            {
                Header: 'Lot Name',
                minWidth: 120,
                width: 120,
                id: 'lotName',
                accessor: 'lotName',
                headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
                className: clsx(classes.columnStyle, classes.columnLeft),
            },
        );

        return columns;
    };

    return (
        <div className={classes.flexContainer}>
            <Header>
                <div className={classes.buttonSpacing}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={journalDetailData.search || ''}
                        onSearch={(val) => onFilterChange('search', val)}
                    />
                    <Form.Group controlId="formBasicCheckboxBottom" className={classes.alignContent}>
                        <Form.Check
                            checked={journalDetailData.useDateRange}
                            type="checkbox"
                            label="Search between dates"
                            className={classes.labelFont}
                            onChange={(e) => onFilterChange('useDateRange', e.target.checked)}
                        />
                    </Form.Group>
                    {journalDetailData.useDateRange
                    && (
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={journalDetailData.fromDate}
                            maxDate={journalDetailData.toDate}
                            onChange={(value) => onFilterChange('fromDate', value)}
                            placeholderText="mm/dd/yyyy"
                        />
                    )}
                    {journalDetailData.useDateRange
                    && (
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                            selected={journalDetailData.toDate}
                            minDate={journalDetailData.fromDate}
                            onChange={(value) => onFilterChange('toDate', value)}
                            placeholderText="mm/dd/yyyy"
                        />
                    )}
                </div>
            </Header>
            <div className={clsx(classes.bottomTableHeight, classes.overFlowAuto)}>
                <Table
                    rowSelected
                    cursor="default"
                    className="-highlight"
                    load={loading}
                    loadMore={loadMore}
                    totalRecords={journalDetailData.table.totalCount}
                    data={journalDetailData.table.records}
                    columns={getColumns()}
                />
            </div>
        </div>
    );
};

JournalDetail.propTypes = {
    cbId: PropTypes.number,
    showDebitCreditAsAmount: PropTypes.bool,
    processType: PropTypes.string,
};

JournalDetail.defaultProps = {
    showDebitCreditAsAmount: false,
    processType: '',
    cbId: 0,
};

export default JournalDetail;

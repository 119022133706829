import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Menu from 'components/widgets/dropdown/Menu';
import StringUtils from 'lib/StringUtils';

const MenuItem = ({
    label, disabled, items, open, onMouseEnter, onMouseLeave, onMouseMove, pullRight, onClick, setOpen, onSelect,
    component,
}) => {
    const renderMenu = () => (
        <Menu items={items} className={clsx('multi-level', { 'pull-right': pullRight })} pullRight={pullRight} setOpen={setOpen} onSelect={onSelect} />
    );

    const value = StringUtils.isEmpty(label) ? component : label;

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            onMouseDown={(e) => e.preventDefault()}
            onClick={!(items || []).length > 0 ? onClick : null}
            className={clsx({ 'menu-item-wrapper': Array.isArray(items), open })}
        >
            <div className={clsx('menu-item', { disabled })}>
                {value}
                {(items || []).length > 0 ? <Arrow /> : null}
            </div>
            {open && Array.isArray(items) ? renderMenu() : null}
        </div>
    );
};

MenuItem.propTypes = {
    disabled: PropTypes.bool,
    items: PropTypes.array,
    open: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    pullRight: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    label: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

MenuItem.defaultProps = {
    disabled: false,
    items: [],
    label: '',
    component: null,
};

const Arrow = (props) => (
    <svg width={14} height={5} {...props} style={{ marginLeft: '10px', verticalAlign: 'middle', transform: 'rotate(90deg)' }}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CDCFD0"
                d="M7 .07v1.428l-5.55 5.5L0 6.982zM7 .07v1.428l5.55 5.5L14 6.982z"
            />
            <path d="M1.45 7L7 1.498 12.55 7z" />
        </g>
    </svg>
);

export default MenuItem;

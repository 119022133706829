import React, { Component } from 'react';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    DialogTitle, Typography, IconButton, DialogContent, Divider, DialogActions, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Components and Others
import PropTypes from 'prop-types';
import ActivitiesStyles from 'styles/modules/ActivitiesStyles';

const styles = (theme) => ActivitiesStyles.activityContent(theme, fade);

class ActivityContent extends Component {
    render() {
        const {
            props: {
                children, classes, title, onClose, onSave,
                disabledSaveButton, deleteButton,
            },
        } = this;

        return (
            <>
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <Typography variant="subtitle1">{title}</Typography>
                    <IconButton size="small" aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    className={classes.dialogContent}
                >
                    {children}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>Cancel</Button>
                    {deleteButton}
                    <Button variant="contained" className={classes.containedInfo} disabled={disabledSaveButton} onClick={onSave}>Save</Button>
                </DialogActions>
            </>
        );
    }
}

ActivityContent.propTypes = {
    title: PropTypes.string,
    deleteButton: PropTypes.node,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabledSaveButton: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ActivityContent.defaultProps = {
    title: '',
    deleteButton: null,
    disabledSaveButton: false,
};

export default withStyles(styles)(ActivityContent);

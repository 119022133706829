import { gql } from '@apollo/client';

export default class CreditMutation {
    static GENERATE_CREDIT_SCORE = gql`    
        mutation generateCreditScore(
            $lotId: Int,
            $type: String!,
            $recordId: Int!,
            $uniqueCode: String!,
            $recordType: String!,
            $multipleBureaus: String!
        ) {
            generateCreditScore(
                type: $type,
                lotId: $lotId
                recordId: $recordId,
                uniqueCode: $uniqueCode,
                recordType: $recordType,
                multipleBureaus: $multipleBureaus,
            ) {
                url
                vendor
            }
        }
    `;

    static SEND_CREDIT_SCORE_TO_THIRD_PARTY = gql`    
        mutation sendCreditScoreToThirdParty(
            $recordId: Int!,
            $losSystem: String!,
            $recordType: String!,
            $applicationType: String!,
        ) {
            sendCreditScoreToThirdParty(
                recordId: $recordId,
                losSystem: $losSystem,
                recordType: $recordType,
                applicationType: $applicationType,
            )
        }
    `;
}

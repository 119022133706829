import React, {
    useReducer,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { SaveIcon, CloseIcon } from 'components/icons';
import { Row, Form, Col } from 'react-bootstrap';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import DialogActions from '@material-ui/core/DialogActions';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import PartsRequestSchema from 'utils/schema/parts/PartsRequestSchema';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';
import PropTypes from 'prop-types';
import BasicStyles from 'styles/modules/basic/basicStyles';

const useStyle = makeStyles((theme) => BasicStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
};

const PartsRequestReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value, isDirty } = action;
        return { ...state, record: value, isDirty };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const PartsRequestCreate = (props) => {
    const initialState = {
        record: {
            description: '',
            notes: '',
        },
        isDirty: false,
        couldLostData: false,
    };

    const {
        onPopupClose, invoiceNumber, serviceJobId, technicianId,
    } = props;

    const classes = useStyle();

    const [state, dispatch] = useReducer(PartsRequestReducer, initialState);
    const {
        record, isDirty, couldLostData,
    } = state;

    const { isValid, errors } = isValidSchema(PartsRequestSchema, record);

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: {
                ...initialState,
                record: initialState.record,
            },
        });
    };

    const popupClose = (forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch({
                type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
            });
            return;
        }

        onClearForm();
        onPopupClose();
    };

    const [addRecord, { loading: isSaving }] = useMutation(PartsMutation.REQUEST_PART, {
        onCompleted: (mutationData) => {
            if (mutationData?.requestPart) {
                ModalUtils.successMessage(null, 'Successfully saved!');

                popupClose(true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        await addRecord({
            variables: {
                record: {
                    ...record,
                    invoiceNumber,
                    serviceJobId,
                    technicianId,
                },
            },
        });
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
            isDirty: true,
        });
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    const messageToAllowSave = [];
    // eslint-disable-next-line no-unused-expressions
    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    return (
        <Dialog
            open
            onClose={() => popupClose(false)}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        Request Part
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={() => popupClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                <Container className={classes.containerSplit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={12} className="required">Description</Form.Label>
                                <Col xs={12}>
                                    <Form.Control
                                        className={isValidField(errors, 'description') ? 'invalid-field' : ''}
                                        size="sm"
                                        value={record.description}
                                        placeholder="Description"
                                        onChange={(e) => onChangeValue('description', e.target.value)}
                                        as="textarea"
                                    />
                                </Col>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group as={Row}>
                                <Form.Label column="sm" xs={12} className="required">Comments</Form.Label>
                                <Col xs={12}>
                                    <Form.Control
                                        className={isValidField(errors, 'notes') ? 'invalid-field' : ''}
                                        size="sm"
                                        value={record.notes}
                                        placeholder="comments"
                                        onChange={(e) => onChangeValue('notes', e.target.value)}
                                        as="textarea"
                                    />
                                </Col>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    {couldLostData && (
                        <ConfirmDialog
                            title="Attention - You have unsaved changes!"
                            description="Do you want to close without saving?"
                            open={couldLostData}
                            variant="outlined"
                            titlePrimary="Close"
                            titleSecondary="Cancel"
                            onClose={onCloseFormWithoutSave}
                            onClickSecondary={onCloseFormWithoutSave}
                            onClickPrimary={() => popupClose(true)}
                        />
                    )}
                </Container>
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            <ErrorMessageIndicator
                                messages={messageToAllowSave}
                            >
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!(isValid && isDirty)}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </ErrorMessageIndicator>
                        </div>
                    </Toolbar>
                </div>
            </DialogActions>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

PartsRequestCreate.propTypes = {
    onPopupClose: PropTypes.func,
    invoiceNumber: PropTypes.number,
    serviceJobId: PropTypes.string,
    technicianId: PropTypes.number,
};

PartsRequestCreate.defaultProps = {
    onPopupClose: () => {},
    invoiceNumber: 0,
    serviceJobId: '',
    technicianId: 0,
};

export default PartsRequestCreate;

/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { modules } from 'utils/enum/modules';
import { Link as RouteLink } from 'react-router-dom';
import VehicleImage from 'components/widgets/VehicleImage';

// Material UI
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme = {}) => ({
    box: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    image: {
        width: 96,
        display: 'block',
        height: '100%',
        objectFit: 'cover',
    },
    containerImage: {
        marginRight: theme.spacing(1),
        position: 'relative',
        overflow: 'hidden',
        height: 60,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    containerDetails: {
        flex: 1,
        overflow: 'hidden',
    },
    textBold: {
        color: theme.palette.text.purpure,
        fontWeight: 'bold',
    },
    model: {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});

class CardVehicle extends Component {
    render() {
        const {
            props: {
                classes,
                className,
                vehicle: {
                    year, make, model, trim, picture, thumbnail, style, stockNumber, // TODO: Merge the picture and thumbnail
                },
            },
        } = this;
        const image = picture || thumbnail;
        const modelTrim = `${model || ''} - ${trim || ''}`;

        const getVehicleImage = () => (
            <Box className={classes.containerImage}>
                <VehicleImage
                    image={image}
                    bodyStyle={style}
                    className={classes.image}
                />
            </Box>
        );

        const validStockNumber = !StringUtils.isEmpty(stockNumber) && !isNaN(stockNumber);
        return (
            <Box className={clsx(classes.root, className)}>
                {validStockNumber && (
                    <RouteLink
                        to={{
                            pathname: `/${modules.INVENTORY}/${stockNumber}`,
                        }}
                        target="_blank"
                    >
                        {getVehicleImage()}
                    </RouteLink>
                )}
                {!validStockNumber && getVehicleImage()}
                <Box className={classes.containerDetails}>
                    <Box className={classes.box}>
                        <Typography className={classes.textBold}>
                            {year}
                        </Typography>
                    </Box>
                    <Typography noWrap>{make}</Typography>
                    <Box className={classes.box}>
                        <span className={classes.model} title={modelTrim}>{modelTrim}</span>
                    </Box>
                </Box>
            </Box>
        );
    }
}

CardVehicle.propTypes = {
    className: PropTypes.string,
    vehicle: PropTypes.oneOfType([PropTypes.object]).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CardVehicle.defaultProps = {
    className: '',
};

export default withStyles(styles)(CardVehicle);

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Search from 'components/widgets/Search';
import Header from 'components/widgets/Header';
import Container from 'components/widgets/Container';
import CardVehicle from 'components/widgets/CardVehicle';
import SideBar from 'components/modules/conversation/list/Sidebar';
import DrawerContainer from 'components/widgets/DrawerContainer';
import ConversationStyles from 'styles/modules/ConversationStyles';
import ConversationDetail from 'components/modules/conversation/read/ConversationDetail';
import ConversationPlaceholder from 'components/modules/conversation/list/ConversationPlaceholder';
import CustomerInformation from 'components/modules/customer/read/CustomerInformation';

// Material UI
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const styles = (theme) => ConversationStyles.conversation(theme);

class Conversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            conversationId: null,
            open: false,
            type: '',
            vehicles: [],
        };

        this.initBind();
    }

    componentDidMount() {
        const { props: { match: { params: { id } } } } = this;

        this.setConversation(id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { match: { params } } = nextProps;

        if (prevState.id !== params.id) {
            return {
                conversationId: params.id,
            };
        }

        return {
            conversationId: null,
        };
    }

    onToggleDrawer(open = false, type = '') {
        this.setState({ open, type });
    }

    setConversation(id) {
        this.setState({ conversationId: id });
    }

    initBind() {
        this.onToggleDrawer = this.onToggleDrawer.bind(this);
    }

    renderBody() {
        const { state: { type, vehicles, conversationId }, props: { classes } } = this;

        if (type === 'customer') {
            return (
                <CustomerInformation
                    onClose={this.onToggleDrawer}
                    conversationId={conversationId}
                />
            );
        }
        if (type === 'vehicle') {
            return (
                <div
                    className={classes.divListVehicle}
                >
                    <div
                        className={classes.headerDrawer}
                    >
                        <Button
                            className={classes.buttonClose}
                            variant="outlined"
                            color="default"
                            size="small"
                            onClick={() => this.onToggleDrawer(false)}
                        >
                            <CloseIcon className={classes.startIcon} />
                        </Button>
                    </div>
                    <div
                        className={classes.contentVehicle}
                    >
                        {vehicles?.map((item) => (
                            <CardVehicle
                                vehicle={item}
                                key={item.stock}
                                vehicleClass="vehicle-item"
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderConversationDetails() {
        const { state: { conversationId, open } } = this;

        if (conversationId) {
            return (
                <ConversationDetail
                    onToggleDrawer={this.onToggleDrawer}
                    conversationId={conversationId}
                    open={open}
                />
            );
        }

        return (
            <ConversationPlaceholder />
        );
    }

    render() {
        const { props: { classes }, state: { open, conversationId } } = this;
        // TODO: Remove this when functionality is added
        const hidden = true;

        return (
            <div className={classes.main}>
                <Header divider>
                    <Typography
                        variant="h5"
                        className={classes.title}
                    >
                        Conversations
                    </Typography>
                    <div>
                        {!hidden && (
                            <Button>
                                <AddCircleOutlineIcon />
                                <Typography
                                    variant="body1"
                                    className={classes.buttonText}
                                >
                                    New Message
                                </Typography>
                            </Button>
                        )}
                    </div>
                    {!hidden && <Search />}
                </Header>
                <Container padding={false} backgroundColor={false}>
                    {KeyStore.isAllowed(Permission.CRM_CONVERSATION_READ) && (
                        <SideBar
                            conversationId={conversationId}
                        />
                    )}
                    {KeyStore.isAllowed(Permission.CRM_CHAT_READ) && this.renderConversationDetails()}
                    <DrawerContainer
                        open={open}
                    >
                        {open && this.renderBody()}
                    </DrawerContainer>
                </Container>
            </div>
        );
    }
}

Conversation.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(withStyles(styles)(Conversation));

import React, { useContext, useEffect, useReducer } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import WarningIcon from '@material-ui/icons/Warning';
import UserContext from 'components/context/UserContext';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { ListLotToTransfer } from 'components/widgets/transfer/TransferLot';

// Material UI
import {
    DialogContent, Dialog,
    makeStyles, Typography,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import { FetchPolicy } from 'utils/enum/Core';
import { isEmpty, isFinite } from 'lodash';

const useStyles = makeStyles((theme) => ({
    dialogWidth: {
        '& > div > div': {
            width: '600px',
        },
    },
    dialogContent: {
        padding: '15px 30px',
    },
    description: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.text.secondaryPurpure,
    },
    titleWarning: {
        fontWeight: 'bold',
        color: '#841f16',
        marginBottom: theme.spacing(1),
    },
    textBold: {
        fontWeight: 'bold',
        color: '#841f16',
    },
    warning: {
        fontSize: 14,
        fontWeight: 400,
        color: '#f14b35',
    },
    icon: {
        marginRight: theme.spacing(1),
        color: '#f14b35',
    },
    boxWarning: {
        display: 'flex',
        marginTop: theme.spacing(2),
        background: 'rgb(255,228,212)',
        borderLeft: '4px solid rgb(248,101,54)',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.5),
    },
}));

const ACTION_TYPE = {
    SET_ASSIGNEE: 'setAssignee',
    ON_SELECT_LOT: 'onSelectLot',
    TOGGLE_DIALOG: 'toggleDialog',
};
const initState = {
    open: false,
    lot: {},
};
const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
    case ACTION_TYPE.TOGGLE_DIALOG:
        return update(state, {
            open: { $set: payload },
        });
    case ACTION_TYPE.ON_SELECT_LOT:
        return update(state, {
            open: { $set: true },
            lot: { $set: payload },
        });
    case ACTION_TYPE.SET_ASSIGNEE:
        const recordManager = payload.users.find((item) => item.userId === payload.recordManagerId);
        const salesperson = payload.users.find((item) => item.userId === payload.salespersonId);

        return update(state, {
            recordManager: { $set: isEmpty(recordManager) ? null : `${recordManager.firstName} ${recordManager.lastName}` },
            salesperson: { $set: isEmpty(salesperson) ? null : `${salesperson.firstName} ${salesperson.lastName}` },
            recordManagerHaveAccessToLot: { $set: isEmpty(recordManager) ? false : recordManager.lots.includes(state.lot.lotName) },
            salespersonHaveAccessToLot: { $set: isEmpty(salesperson) ? false : salesperson.lots.includes(state.lot.lotName) },
        });
    default:
        return initState;
    }
};
const OpportunityTransferLot = ({
    open, record, onTransfer,
    disableConfirmDialogPrimaryButton,
    disableConfirmDialogSecondaryButton,
    closeDialog,
}) => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, initState);
    const userAvailableLots = userInformation?.lots || [];
    const [getAssigneeByOpportunityId, { data: dataAssignee, loading: loadingAssignee }] = useLazyQuery(
        OpportunityQuery.GET_ASSIGNEE_BY_OPPORTUNITY_ID,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY },
    );
    let warningMessage = null;
    const message = `Do you want to transfer Opportunity #${record?.leadCode} from ${record?.lotName} to ${state.lot.lotName}?`;
    const assignee = [record.recordManagerId, record.salespersonId].filter((item) => Number(item));

    // Check if opportunity have assignee
    if (assignee.length > 0) {
        const assigneeMessage = [];
        const isNotValidRecordManager = isFinite(record.recordManagerId) && !state.recordManagerHaveAccessToLot;

        if (isNotValidRecordManager) {
            assigneeMessage.push(`The Record Manager ${state.recordManager}`);
        }

        if (isFinite(record.salespersonId) && !state.salespersonHaveAccessToLot) {
            assigneeMessage.push(`${!isNotValidRecordManager ? 'The' : ''} Sales Rep ${state.salesperson}`);
        }

        if (assigneeMessage.length > 0) {
            warningMessage = `${assigneeMessage.join(' and ')} assigned to this opportunity don't have
            access to ${state.lot.lotName} and will be removed upon transfer.`;
        }
    }

    const onSelectLot = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_SELECT_LOT,
            payload: value,
        });
        getAssigneeByOpportunityId({
            variables: {
                opportunityId: record.crmId,
            },
        });
    };

    const toggleConfirm = (value = true) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_DIALOG,
            payload: value,
        });
    };

    useEffect(() => {
        if (loadingAssignee) return;

        if (dataAssignee?.getAssigneeByOpportunityId) {
            dispatch({
                type: ACTION_TYPE.SET_ASSIGNEE,
                payload: {
                    recordManagerId: record.recordManagerId,
                    salespersonId: record.salespersonId,
                    users: dataAssignee?.getAssigneeByOpportunityId,
                },
            });
        }
    }, [dataAssignee, loadingAssignee, record]);

    return (
        <Dialog
            open={open}
            className={classes.dialogWidth}
            fullWidth
            maxWidth="xl"
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                titleVariant="h4"
                onClose={closeDialog}
                title="Transfer Opportunity To Another Lot"
            />
            <DialogContent className={classes.dialogContent} dividers>
                <ListLotToTransfer record={userAvailableLots} lotName={record?.lotName} onSelectLot={onSelectLot} />
            </DialogContent>
            <DialogActions
                hiddenPrimaryButton
                onClickSecondary={closeDialog}
            />
            <ConfirmDialog
                open={state.open}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="No"
                title="Confirm transfer"
                loading={loadingAssignee}
                onClose={() => toggleConfirm(false)}
                onClickSecondary={() => toggleConfirm(false)}
                onClickPrimary={() => {
                    onTransfer(state.lot);
                }}
                disablePrimaryButton={disableConfirmDialogPrimaryButton}
                disableSecondaryButton={disableConfirmDialogSecondaryButton}
            >
                <Typography className={classes.description}>{message}</Typography>
                {warningMessage && (
                    <div className={classes.boxWarning}>
                        <WarningIcon className={classes.icon} />
                        <div>
                            <Typography className={classes.titleWarning}>Warning</Typography>
                            <Typography className={classes.warning}>{warningMessage}</Typography>
                        </div>
                    </div>
                )}
            </ConfirmDialog>
        </Dialog>
    );
};

OpportunityTransferLot.propTypes = {
    open: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    onTransfer: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    disableConfirmDialogPrimaryButton: PropTypes.bool,
    disableConfirmDialogSecondaryButton: PropTypes.bool,
};

OpportunityTransferLot.defaultProps = {
    disableConfirmDialogPrimaryButton: false,
    disableConfirmDialogSecondaryButton: false,
};

export default OpportunityTransferLot;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';

import DealService from 'services/modules/DealService';

const DealTabsContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.dealService = new DealService();
        this.state = {
            // edit mode
            clientId: '',
            lotName: null,
            editingMode: {
                isEditing: false,
                sectionName: '',
                tabName: '',
            },
            editingStatus: {
                isEditing: false,
                editingById: null,
                editingBy: '',
            },
        };

        this.initBind();
    }

    onChangeEditingMode(isEditing = false, sectionName = '', tabName = '') {
        const { props } = this;
        const { accountNumber } = props;
        this.dealService.toggleEditingStatus({ accountNumber, isBlocking: isEditing });
        this.setState({ editingMode: { isEditing, sectionName, tabName } });
    }

    setClientId(value) {
        this.setState({ clientId: value });
    }

    setLotName(value) {
        this.setState({ lotName: value });
    }

    setData(data) {
        this.setState({
            editingStatus: data,
        });
    }

    getServicesData() {
        const { props } = this;
        const { accountNumber } = props;

        this.setState({ loading: true });

        this.dealService.getEditingStatus({ accountNumber })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setState({
                        editingStatus: data,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    subscribeDealChanged() {
        const { props: { accountNumber } } = this;

        this.dealService.dealEditingStatusSubscribe(this.dealSubscription, { accountNumber })
            .then((response) => {
                this.dealChangedSubscription = response;
            });
    }

    dealSubscription(response) {
        const { data } = response;

        if (data) {
            this.setState({
                editingStatus: data,
            });
        }
    }

    unsubscribeDealChanged() {
        if (this.dealChangedSubscription) {
            this.dealChangedSubscription.unsubscribe();
        }
    }

    initBind() {
        this.setLotName = this.setLotName.bind(this);
        this.setClientId = this.setClientId.bind(this);
        this.onChangeEditingMode = this.onChangeEditingMode.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.dealSubscription = this.dealSubscription.bind(this);
        this.subscribeDealChanged = this.subscribeDealChanged.bind(this);
        this.unsubscribeDealChanged = this.unsubscribeDealChanged.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                setLotName={this.setLotName}
                setClientId={this.setClientId}
                getServicesData={this.getServicesData}
                subscribeDealChanged={this.subscribeDealChanged}
                unsubscribeDealChanged={this.unsubscribeDealChanged}
                onChangeEditingMode={this.onChangeEditingMode}
            />
        );
    }
};

export default DealTabsContainer;

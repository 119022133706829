/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from '@material-ui/core';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import DataType from 'utils/enum/DataType';
import { Link as RouteLink } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import VehicleWidget from 'components/modules/inventory/list/VehicleWidget';
import VehicleImage from 'components/widgets/VehicleImage';
import VehicleColors from 'components/modules/inventory/list/VehicleColors';
import QuickNoteEditable from 'components/modules/inventory/list/QuickNoteEditable';
import LinearProgress from '@material-ui/core/LinearProgress';

const formatCustomField = (customField, type) => {
    switch (type.toLowerCase()) {
    case DataType.MONEY:
        return NumberUtils.applyCurrencyFormat(customField);
    case DataType.DATE:
        return DateUtils.format(customField);
    default:
        return customField;
    }
};

const StockColumn = React.memo(({
    stock,
    stocks,
}) => (
    <RouteLink to={{
        pathname: `/${modules.INVENTORY}/${stock}`,
        stocks,
    }}
    >
        <span>{stock}</span>
    </RouteLink>
), (prevProps, nextProps) => prevProps.stock === nextProps.stock && prevProps.stocks?.length === nextProps.stocks?.length);

const ThumbnailColumn = React.memo(({
    stock,
    stocks,
    image,
    style,
}) => (
    <RouteLink to={{
        pathname: `/${modules.INVENTORY}/${stock}`,
        stocks,
    }}
    >
        <VehicleImage
            image={image}
            bodyStyle={style}
            height="100%"
            width="100%"
        />
    </RouteLink>
), (prevProps, nextProps) => prevProps.stock === nextProps.stock
    && prevProps.stocks?.length === nextProps.stocks?.length
    && prevProps.image === nextProps.image
    && prevProps.style === nextProps.style);

const VehicleColumn = React.memo(({
    record,
}) => (
    <VehicleWidget record={record} />
), (prevProps, nextProps) => prevProps.record.stock === nextProps.record.stock
    && prevProps.record.vin === nextProps.record.vin
    && prevProps.record.year === nextProps.record.year
    && prevProps.record.make === nextProps.record.make
    && prevProps.record.model === nextProps.record.model
    && prevProps.record.trim === nextProps.record.trim
    && prevProps.record.drivetrain === nextProps.record.drivetrain
    && prevProps.record.wholesale === nextProps.record.wholesale
    && prevProps.record.placeAd === nextProps.record.placeAd
    && prevProps.record.imageCounter === nextProps.record.imageCounter);

const VehicleColorsColumn = React.memo(({
    interiorColor,
    exteriorColor,
}) => (
    <VehicleColors
        interiorColor={interiorColor}
        exteriorColor={exteriorColor}
    />
), (prevProps, nextProps) => prevProps.interiorColor === nextProps.interiorColor && prevProps.exteriorColor === nextProps.exteriorColor);

const ReconProgressColumn = React.memo(({
    progressContainer,
    reconProgress,
}) => {
    let { completedRepairItems, uncompletedRepairItems } = typeof reconProgress === 'string' ? JSON.parse(reconProgress) : {};
    if (!completedRepairItems) completedRepairItems = 0;
    if (!uncompletedRepairItems) uncompletedRepairItems = 0;
    if (completedRepairItems === 0 && uncompletedRepairItems === 0) return null;

    const total = completedRepairItems + uncompletedRepairItems;
    const progress = (completedRepairItems * 100) / total;

    return (
        <Tooltip
            title={`${completedRepairItems}/${total}`}
            placement="top"
        >
            <div className={progressContainer}>
                <LinearProgress variant="determinate" value={progress} />
            </div>
        </Tooltip>
    );
}, (prevProps, nextProps) => prevProps.reconProgress === nextProps.reconProgress && prevProps.progressContainer === nextProps.progressContainer);

const QuickNoteColumn = React.memo(({
    rowIndex,
    stock,
    quickNote,
    editable,
    enableRow,
    onChangeQuickNote,
}) => (
    <QuickNoteEditable
        index={rowIndex}
        stockNumber={stock}
        quickNote={quickNote}
        editable={editable}
        enableRow={enableRow}
        onChangeQuickNote={onChangeQuickNote}
    />
), (prevProps, nextProps) => prevProps.rowIndex === nextProps.rowIndex
    && prevProps.stock === nextProps.stock
    && prevProps.quickNote === nextProps.quickNote
    && prevProps.editable === nextProps.editable);

const getCustomFieldData = (name, customFields) => customFields.find((cf) => cf.property === name && cf.displayed);
const getColumns = (customFields, classes, enableRow, onChangeQuickNote, hasShowCost) => {
    const totalCustomFields = 8;
    const customFieldsArray = [];
    for (let i = 1; i <= totalCustomFields; i += 1) {
        const cf = getCustomFieldData(`CustomField${i}`, customFields);
        customFieldsArray.push(
            {
                label: cf ? cf.label : '',
                dataKey: `customField${i}`,
                width: 200,
                show: false,
                filterEnabled: true,
                disableSort: false,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;
                    const customField = record[`customField${i}`];

                    if (cf && !StringUtils.isEmpty(customField)) {
                        return formatCustomField(customField, cf.type);
                    }

                    return customField;
                },
            },
        );
    }

    let columns = [
        {
            label: 'Thumbnail',
            dataKey: 'thumbnail',
            width: 130,
            show: true,
            filterEnabled: false,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <ThumbnailColumn
                        stock={record.stock}
                        stocks={record.stocks}
                        image={record.image}
                        style={record.style}
                    />
                );
            },
        },
        {
            label: 'Vehicle',
            dataKey: 'vin',
            width: 290,
            show: true,
            filterEnabled: false,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <VehicleColumn
                        record={record}
                    />
                );
            },
        },
        {
            label: 'Stock #',
            dataKey: 'stockNumber',
            width: 200,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <StockColumn
                        stock={record.stock}
                        stocks={record.stocks}
                    />
                );
            },
        },
        {
            label: 'Images',
            dataKey: 'imageCounter',
            width: 100,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Make',
            dataKey: 'make',
            width: 130,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Sticker Price',
            dataKey: 'stickerPrice',
            width: 150,
            show: true,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const stickerPrice = record.stickerPrice || 0;
                return NumberUtils.applyCurrencyFormat(stickerPrice);
            },
        },
        {
            label: 'Model',
            dataKey: 'model',
            width: 130,
            show: false,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.tags}>
                        {record.model}
                    </span>
                );
            },
        },
        {
            label: 'Style',
            dataKey: 'style',
            width: 120,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Trim',
            dataKey: 'trim',
            width: 150,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Year',
            dataKey: 'year',
            width: 120,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Miles',
            dataKey: 'miles',
            width: 100,
            show: true,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{NumberUtils.applyThousandsFormat(record.miles || 0)}</span>;
            },
        },
        {
            label: 'Age',
            dataKey: 'age',
            width: 100,
            show: true,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyThousandsFormat(record.age || 0);
            },
        },
        {
            label: 'Transmission',
            dataKey: 'transmission',
            width: 150,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Drivetrain',
            dataKey: 'drivetrain',
            width: 150,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Tags',
            dataKey: 'inventoryTags',
            width: 120,
            show: false,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.tags}>
                        {record.inventoryTags ?? ''}
                    </span>
                );
            },
        },
        {
            label: 'Mark Up',
            dataKey: 'markUp',
            width: 150,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const markup = record.markUp || 0;
                return (
                    <span className={markup < 0 ? classes.negativeMarkUp : ''}>
                        {NumberUtils.applyCurrencyFormat(markup)}
                    </span>
                );
            },
        },
        {
            label: 'Internet Price',
            dataKey: 'internetPrice',
            width: 150,
            show: true,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.internetPrice || 0);
            },
        },
        {
            label: 'Minimum Price',
            dataKey: 'minimumPrice',
            width: 150,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.minimumPrice || 0);
            },
        },
        {
            label: 'Wholesale Price',
            dataKey: 'wholesalePrice',
            width: 170,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.wholesalePrice || 0);
            },
        },
        {
            label: 'Last Updated Price',
            dataKey: 'lastUpdatedPrice',
            width: 180,
            show: true,
            filterEnabled: false,
            disableSort: false,
        },
        {
            label: 'Cost',
            dataKey: 'cost',
            width: 150,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.cost || 0);
            },
        },
        {
            label: 'Repairs',
            dataKey: 'repairs',
            width: 150,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.repairs || 0);
            },
        },
        {
            label: 'NADA',
            dataKey: 'nada',
            width: 150,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.nada || 0);
            },
        },
        {
            label: 'Quick Note',
            dataKey: 'quickNote',
            width: 170,
            show: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowIndex, rowData: record } = cell;
                return (
                    <QuickNoteColumn
                        rowIndex={rowIndex}
                        stock={record.stock}
                        quickNote={record.quickNote}
                        editable={record.editable}
                        enableRow={enableRow}
                        onChangeQuickNote={onChangeQuickNote}
                    />
                );
            },
        },
        {
            label: 'Purchased From',
            dataKey: 'purchasedFrom',
            width: 220,
            show: false,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.tags}>
                        {record.purchasedFrom}
                    </span>
                );
            },
        },
        {
            label: 'Purchasing Agent',
            dataKey: 'purchasingAgent',
            width: 180,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Purchased Price',
            dataKey: 'purchasedPrice',
            width: 170,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyCurrencyFormat(record.purchasedPrice || 0);
            },
        },
        {
            label: 'Purchased Date',
            dataKey: 'purchasedDate',
            width: 180,
            show: false,
            filterEnabled: false,
            disableSort: false,
        },
        {
            label: 'Vehicle Location',
            dataKey: 'vehicleLocation',
            width: 200,
            show: true,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Lot Name',
            dataKey: 'lotName',
            width: 145,
            show: true,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Notes',
            dataKey: 'notes',
            width: 180,
            show: false,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.tags}>
                        {record.notes}
                    </span>
                );
            },
        },
        {
            label: 'Generic Exterior Color',
            dataKey: 'genericExteriorColor',
            width: 220,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Color',
            dataKey: 'extColor',
            width: 220,
            show: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <VehicleColorsColumn
                        interiorColor={record.interiorColor}
                        exteriorColor={record.exteriorColor}
                    />
                );
            },
        },
        ...customFieldsArray,
        {
            label: 'Recon Progress',
            dataKey: 'reconProgress',
            width: 220,
            show: false,
            filterEnabled: false,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <ReconProgressColumn
                        progressContainer={classes.progressContainer}
                        reconProgress={record.reconProgress}
                    />
                );
            },
        },
        {
            label: 'Parts Status',
            dataKey: 'partsStatus',
            width: 145,
            show: false,
            filterEnabled: true,
            disableSort: false,
        },
        {
            label: 'Recon Age',
            dataKey: 'reconAge',
            width: 130,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyThousandsFormat(record.reconAge || 0);
            },
        },
        {
            label: 'Total Leads',
            dataKey: 'totalLeads',
            width: 130,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyThousandsFormat(record.totalLeads || 0);
            },
        },
        {
            label: 'Leads last 15 days',
            dataKey: 'latestLeads',
            width: 170,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyThousandsFormat(record.latestLeads || 0);
            },
        },
        {
            label: 'Demos',
            dataKey: 'demos',
            width: 100,
            show: false,
            isNumeric: true,
            filterEnabled: true,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return NumberUtils.applyThousandsFormat(record.demos || 0);
            },
        },
    ];

    if (!hasShowCost) {
        columns = columns.filter((col) => ![
            'markUp',
            'cost',
            'minimumPrice',
            'repairs',
            'purchasedPrice',
        ].includes(col.dataKey));
    }

    return columns.map((x, index) => ({ ...x, order: index + 1 }));
};

export default getColumns;

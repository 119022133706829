import { gql } from '@apollo/client';

export default class AccountingQuery {
  static GET_ACCOUNTINGCB_LIST = gql`
    query getAccountingCBList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingCBFilter
    ) {
      getAccountingCBList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          cbId
          checkNumber
          depositNumber
          achCheckNumber
          amount
          date
          paidTo
          memo
          status
          type
          voidDate
          sysUser
          processTypeId
          clearedDate
          accountDescription
          bankAccount
        }
      }
    }
  `;

static GET_ACCOUNTINGCB_CHECK_ACTION_LIST = gql`
query getAccountingCBList(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: AccountingCBFilter
) {
  getAccountingCBList(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      cbId
      checkNumber
      depositNumber
      achCheckNumber
      amount
      date
      paidTo
      status
      type
      memo
      sysUser
      bankAccount
      accountDescription
    }
  }
}
`;

static GET_ACCOUNTING_CB = gql`
  query getCheck(
    $cbId: Int!
  ) {
    getCheck(cbId: $cbId) {
        cbId
        checkNumber
        depositNumber
        achCheckNumber
        amountInWords
        amount
        bankAccount
        date
        paidToId
        paidTo
        memo
        status
        is1099
        type
        lotId
        lotName
        departmentId
        department
        voidDate
        sysUser
        sysDate
        writeOff
        imported
        entityAddress
        processTypeId
        processRecordId
        isPaymentRefund
        isRepoRefund
        isVehiclePurchase
    }
  }
`;

static GET_ACCOUNTING_DEPOSIT_CB = gql`
query getDeposit(
  $cbId: Int!
) {
  getDeposit(cbId: $cbId) {
      cbId
      checkNumber
      depositNumber
      achCheckNumber
      amountInWords
      amount
      bankAccount
      date
      paidTo
      memo
      status
      type
      lotId
      lotName
      departmentId
      department
      voidDate
      sysUser
      sysDate
      writeOff
      imported
      processTypeId
      processRecordId
  }
}
`;

static GET_LAST_CHECK = gql`
        query getLastCheck(
            $bankAccount: Int!
        ) {
          getLastCheck(
            bankAccount: $bankAccount
            ) {
              result
            }
        }
    `;

static GET_LAST_ACHCHECK = gql`
query getLastACHCheck(
    $bankAccount: Int!
) {
  getLastACHCheck(
    bankAccount: $bankAccount
    ) {
      result
    }
}
`;

static GET_LAST_DEPOSIT= gql`
        query getLastDeposit(
            $bankAccount: Int!
        ) {
          getLastDeposit(
            bankAccount: $bankAccount
            ) {
              result
            }
        }
    `;

static GET_ENDING_BALANCE= gql`
        query getEndingBalance(
            $bankAccount: Int!
        ) {
          getEndingBalance(
            bankAccount: $bankAccount
            ) {
              result
            }
        }
    `;

static GET_VENDOR_BALANCE_LIST = gql`
query getVendorWithBalance(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: VendorBalanceFilter
) {
  getVendorWithBalance(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      vendorId
      vendorName
      vendorAddress
      vendorPhone
      vendorType
      vendorNotes
      balance
    }
  }
}
`;

static GET_VENDOR_BALANCE_DETAIL_LIST = gql`
query getVendorDetailFromAccountsPayable(
  $sort: [SortField]
  $filter: VendorBalanceForAccountsPayableFilter
) {
  getVendorDetailFromAccountsPayable(sort: $sort, filter: $filter) {
    referenceNumber
    glType
    balance
    postDate
    dateDue
    datePaid
    paidWithCheck
    memo
    sysUser
    cbId
    bankAccount
    vendorId
    status
  }
}`;

static GET_ACCOUNTING_TRANSFER_CB = gql`
query getTransfer(
  $cbId: Int!
) {
  getTransfer(cbId: $cbId) {
      cbId
      fromBankAccountId
      toBankAccountId
      amount
      date
      status
  }
}`;

static GET_BANKING_DEFAULT_SETTINGS = gql`
query getBankingDefaultSettings{ 
  getBankingDefaultSettings{
    defaultBankChecks
    defaultCheckType
    editableCheckNumbers
}}`;

static GET_ACCOUNTS_PAYABLE_DEFAULT_SETTINGS = gql`
query getAccountsPayableDefaultSettings{ 
  getAccountsPayableDefaultSettings{
    accountsPayable
}}`;

static GET_ACCOUNTS_PAYABLE_SETTINGS_PURCHASE_ORDER = gql`
query getAccountsPayableSettingsPurchaseOrder{ 
  getAccountsPayableSettingsPurchaseOrder{
    accountsPayable
}}`;

static GET_ACCOUNTINGCB_USER_LIST = gql`
  query getAccountingCBUsers {
    getAccountingCBUsers {
      userName
    }
  }
`;
}

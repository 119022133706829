import { gql } from '@apollo/client';

export default class CustomQueries {
    static GET_DATABASE_TABLES_NAME = gql`
        query getDatabaseTablesName {
            getDatabaseTablesName
        }
    `;

    static GET_ALL_COLUMNS_TABLE = gql`
        query getAllColumnsTable($tableName: String!) {
            getAllColumnsTable(tableName: $tableName)
        }
    `;

    static GET_ALL_SAVED_QUERIES = gql`
        query getAllSavedQueries {
            getAllSavedQueries {
                savedQuerieId
                queryName
                queryText
                locked
            }
        }
    `;

    static PULL_RECORDS_SAVED_QUERY = gql`
        query pullRecordsSavedQuery($statement: String!) {
            pullRecordsSavedQuery(statement: $statement){
                records {
                    name
                    value
                }
            }
        }
    `;
}

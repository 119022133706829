/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-default */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions, Grid,
    Tooltip, Tabs, Tab,
    MenuItem, ListItemText, Select,
} from '@material-ui/core';
import { arrayMove } from 'react-sortable-hoc';
import {
    STYLE_COMPONENT,
    CHART_COLUMN_DATA_TYPE,
    ENTITY_FIELD_NAME,
    FORMATTING_CONDITION,
    AGGREGATE_FUNCTIONS,
} from 'utils/enum/BusinessIntelligenceEnum';
import DatePicker from 'react-datepicker';
import { Col, Form, Row } from 'react-bootstrap';
import { useTheme } from '@material-ui/core/styles';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import { Period } from 'lib/DateUtils';
import TableUtils from 'utils/TableUtils';
import { default as SelectWidget } from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import Jodit from 'components/widgets/editor/JoditEditor';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputNumber from 'components/widgets/InputNumber';
import Chart from 'components/widgets/businessIntelligence/Chart';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import InputColumn from 'components/widgets/businessIntelligence/InputColumn';
import LoadedColumnsList from 'components/widgets/businessIntelligence/LoadedColumnsList';
import PivotTableInputContent from 'components/widgets/businessIntelligence/PivotTableInputContent';

// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import FormatBoldOutlinedIcon from '@material-ui/icons/FormatBoldOutlined';
import FormatColorTextOutlinedIcon from '@material-ui/icons/FormatColorTextOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined';
import NumericInput from 'react-numeric-input';

const joditConfiguration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 400,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'ul', 'ol',
        'indent', 'outdent', 'left', 'font', 'fontsize', 'paragraph', 'classSpan', 'brush',
        'image', 'link', 'source',
    ],
};

const frequency = {
    ONCE: 'Once',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
};
const frequencyOption = Object.keys(frequency).map((item) => ({ label: frequency[item], value: frequency[item] }));
const amPmOptionList = Object.keys(Period).map((item) => ({ label: Period[item], value: Period[item] }));
const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 150,
        },
    },
    getContentAnchorEl: null,
};

const chartTypes = BIHelper.getChartType();
const AddEntityDialog = ({
    classes,
    isMobile,
    canWrite,
    chartKey,
    isRuleEntity,
    isDialogOpen,
    toggleDialog,
    onChange,
    onSaveEntity,
    savingEntity,
    getDataPreview,
    loadingColumns,
    loadingUsers,
    categories,
    goals,
    users,
    selectedGoalCategory,
    selectedEntityLabel,
    selectedEntityQuery,
    selectedEntityInstructions,
    selectedEntityType,
    selectedEntityOptions,
    selectedEntityLoadedColumns,
    selectedChartExtraOptions,
    selectedEntityCategory,
    selectedEntityUsers,
    isCustomReport,
    onChangeScheduleReport,
    scheduleReport,
    onChangeFrequency,
}) => {
    const themeHook = useTheme();
    const [selectedTab, setSelectedTab] = useState(0);
    const [openScheduleReport, setOpenScheduleReport] = useState(false);
    const [isReorderingItems, setIsReorderingItems] = useState(false);
    const labelRef = useRef();
    const queryRef = useRef();
    const fontColorRef = useRef([]);
    const backgroundColorRef = useRef([]);
    const insertGoalInStatement = (variable) => {
        const focusedElement = document.activeElement;

        if (focusedElement === labelRef.current) {
            let clone = (selectedEntityLabel || '').repeat(1);
            const { selectionStart = 0, selectionEnd = 0 } = focusedElement;
            const anySelection = selectionStart !== selectionEnd;

            if (anySelection) clone = clone.replace(clone.substring(selectionStart, selectionEnd), variable);
            if (!anySelection) clone = StringUtils.insertAt(clone, variable, selectionStart);
            onChange({ target: { name: 'selectedEntityLabel', value: clone } });
        }

        if (focusedElement === queryRef.current) {
            let clone = (selectedEntityQuery || '').repeat(1);
            const { selectionStart = 0, selectionEnd = 0 } = focusedElement;
            const anySelection = selectionStart !== selectionEnd;

            if (anySelection) clone = clone.replace(clone.substring(selectionStart, selectionEnd), variable);
            if (!anySelection) clone = StringUtils.insertAt(clone, variable, selectionStart);
            onChange({ target: { name: 'selectedEntityQuery', value: clone } });
        }
    };

    const removeUser = (user) => {
        const clone = cloneDeep(selectedEntityUsers)
            .filter((selected) => selected.userId !== user.userId);
        onChange({ target: { name: 'selectedEntityUsers', value: clone } });
    };

    const loadColumns = () => {
        getDataPreview({
            variables: {
                statement: selectedEntityQuery,
            },
        });
    };

    const setOptionsValues = (options) => {
        const { input: confInput, style: confStyle, filters: confFilters } = options;
        const { input: currentInput, style: currentStyle, filters: currentFilters } = selectedEntityOptions;

        currentInput.forEach((el) => {
            const property = confInput.find((p) => p.name === el.name);
            if (property) {
                const keys = Object.keys(el).filter((key) => key !== 'name' && !key.includes('Options'));
                keys.forEach((key) => {
                    if (key in property) property[key] = el[key];
                });
            }
        });

        currentStyle.forEach((el) => {
            const property = confStyle.find((p) => p.name === el.name);
            if (property) {
                const keys = Object.keys(el).filter((key) => key !== 'name' && !key.includes('Options') && !key.includes('Default'));
                keys.forEach((key) => {
                    if (key in property) property[key] = el[key];
                });
            }
        });

        currentFilters.forEach((el) => { confFilters.push(el); });
    };

    const queryColumns = selectedEntityLoadedColumns.length > 0
        ? selectedEntityLoadedColumns[0]
            .filter((el) => !StringUtils.isEmpty(el.name))
        : [];
    const updateChartOptions = (section, propertyName, propertyData) => {
        const chart = chartTypes.find((el) => el.name === selectedEntityType);
        const { configuration } = chart;
        const options = cloneDeep(configuration);
        if (selectedEntityOptions) setOptionsValues(options);

        const cols = (queryColumns ?? [])
            .map((col) => ({ column: col.name, dataType: BIHelper.getColumnDataType(col.name, selectedEntityLoadedColumns)?.type }));

        let property = null;
        switch (section) {
        case 'input':
            if (['Columns', 'PivotRows', 'PivotColumns', 'PivotValues'].includes(propertyName)) {
                property = options.input.find((p) => p.name === propertyName);
                if (property) {
                    const { value, aggregation, replacement } = propertyData;

                    if (replacement) {
                        property.value = value;
                    } else {
                        const columns = property.value;
                        if (propertyName === 'PivotValues') {
                            const record = columns.find((item) => item.name === propertyData.value);
                            if (!record) {
                                columns.push({
                                    name: propertyData.value,
                                    dataType: propertyData.dataType,
                                    aggregation: propertyData.dataType === CHART_COLUMN_DATA_TYPE.NUMERIC ? AGGREGATE_FUNCTIONS.AVG : AGGREGATE_FUNCTIONS.COUNT,
                                });
                            } else {
                                record.aggregation = aggregation;
                            }
                        } else if (!columns.includes(propertyData.value)) columns.push(propertyData.value);

                        if (['PivotRows', 'PivotColumns', 'PivotValues'].includes(propertyName)) {
                            const columnsProperty = options.input.find((p) => p.name === 'Columns');
                            columnsProperty.value = cols;
                        }
                    }
                }
            } else if (propertyName === 'PivotTable') {
                (options?.input ?? []).forEach((prop) => {
                    if (prop.name === 'PivotTable') {
                        prop.value = propertyData.value;
                    } else if (prop.name === 'Columns') {
                        if (propertyData.value) prop.value = cols;
                        if (!propertyData.value) prop.value = [];
                    } else {
                        prop.value = [];
                    }
                });

                (options?.style ?? []).forEach((prop) => {
                    if (prop.name === 'Conditional Formatting') prop.value = [];
                    if (prop.name === 'Display Totals') { prop.value = false; prop.columnsSkipped = []; }
                });
            } else {
                const columnUsed = options.input.some((p) => 'value' in propertyData && propertyData.value != null && (p.value === propertyData.value));
                property = options.input.find((p) => p.name === propertyName);
                if (property && !columnUsed) {
                    const keys = Object.keys(propertyData);
                    keys.forEach((key) => {
                        if (key in property) property[key] = propertyData[key];
                    });
                }
            }

            break;
        case 'style':
            property = options.style.find((p) => p.name === propertyName);
            if (property) {
                const keys = Object.keys(propertyData);
                keys.forEach((key) => {
                    if (key in property) property[key] = propertyData[key];
                });
            }

            break;
        case 'filters':
            const {
                value,
                dataType,
                replacement,
                startDate,
                endDate,
            } = propertyData;

            if (replacement) {
                options.filters = value;
                break;
            }

            if (![CHART_COLUMN_DATA_TYPE.ALPHANUMERIC, CHART_COLUMN_DATA_TYPE.DATE, CHART_COLUMN_DATA_TYPE.NUMERIC].includes(dataType)) {
                ModalUtils.errorMessage(null, 'Only Alphanumeric, Date and Numeric Data Types are allowed');
                break;
            }

            const currentFilter = options.filters.find((filter) => filter.column === value);
            if (currentFilter) {
                if (startDate) currentFilter.startDate = startDate;
                if (endDate) currentFilter.endDate = endDate;
                break;
            }

            options.filters.push({ column: value, dataType });
            break;
        default:
            break;
        }

        onChange({ target: { name: 'selectedEntityOptions', value: options } });
    };

    const removeColumn = (index, propertyName) => {
        const clone = cloneDeep(selectedEntityOptions);
        const property = clone.input.find((p) => p.name === propertyName);
        property.value = property.value.filter((_, idx) => idx !== index);

        onChange({ target: { name: 'selectedEntityOptions', value: clone } });
    };

    const removeFilter = (index) => {
        const clone = cloneDeep(selectedEntityOptions);
        clone.filters = clone.filters.filter((_, idx) => idx !== index);
        onChange({ target: { name: 'selectedEntityOptions', value: clone } });
    };

    const isTable = selectedEntityType === 'Table';
    const isPivotTable = isTable && selectedEntityOptions?.input?.find((prop) => prop.name === 'PivotTable' && prop.value === true);
    const handleDrop = (event, field, isFilter = false) => {
        event.preventDefault();
        if (isReorderingItems) return;

        const { index, type } = JSON.parse(event.dataTransfer.getData('text'));
        const columnIndex = Number(index);

        if (columnIndex !== Number.NaN) {
            const columnName = queryColumns[columnIndex]?.name;
            const propertyName = field?.name;

            if (
                isPivotTable
                && !isFilter
                && (selectedEntityOptions?.input ?? [])
                    .filter((item) => ['PivotRows', 'PivotColumns', 'PivotValues'].includes(item.name))
                    .reduce((a, b) => [...b.value, ...a], []).includes(columnName)
            ) {
                ModalUtils.errorMessage(null, 'Selected column already added');
                return;
            }

            if (
                isPivotTable
                && !isFilter
                && ['PivotRows', 'PivotColumns'].includes(propertyName)
                && (type === CHART_COLUMN_DATA_TYPE.DATE || type === CHART_COLUMN_DATA_TYPE.FLAG)
            ) {
                ModalUtils.errorMessage(null, 'For rows and columns only alphanumeric and numeric data type allowed');
                return;
            }

            if (!isFilter) updateChartOptions('input', propertyName, { value: columnName, dataType: type });
            if (isFilter) updateChartOptions('filters', null, { value: columnName, dataType: type });
        }
    };

    const prepareItem = (event) => {
        setIsReorderingItems(true);

        const section = event.target.getAttribute('data-item-section');
        const field = JSON.parse(event.target.getAttribute('data-item-property'));
        const oldItemIndex = Number(event.target.getAttribute('data-item-index') || 0);
        event.dataTransfer.setData('text', JSON.stringify({ section, field, oldItemIndex }));
    };

    const reorderItems = (event, newItemIndex) => {
        if (!isReorderingItems) return;
        setIsReorderingItems(false);

        const { section, field, oldItemIndex } = JSON.parse(event.dataTransfer.getData('text'));
        if (section && field && oldItemIndex >= 0) {
            const itemsSorted = arrayMove(
                field.value,
                oldItemIndex,
                newItemIndex,
            );

            updateChartOptions(section, field.name, { value: itemsSorted, replacement: true });
        }
    };

    const addAnnotation = (value = []) => {
        const newAnnotation = { text: null, axisValue: null, lineColor: '#ffffff' };
        updateChartOptions('style', 'Annotations', { value: [...value, newAnnotation] });
    };

    const addConditionalFormat = (value = []) => {
        const newCondition = {
            column: null,
            condition: null,
            value: null,
            fontColor: themeHook.palette.text.outerSpace,
            backgroundColor: 'transparent',
            fontSize: 12,
            bold: false,
        };
        updateChartOptions('style', STYLE_COMPONENT.CONDITIONAL_FORMATTING, { value: [...value, newCondition] });
    };

    const removeAnnotation = (value = [], index) => {
        updateChartOptions('style', 'Annotations', { value: value.filter((_, idx) => idx !== index) });
    };

    const removeConditional = (value = [], index) => {
        updateChartOptions('style', STYLE_COMPONENT.CONDITIONAL_FORMATTING, { value: value.filter((_, idx) => idx !== index) });
    };

    const updateAnnotation = (value = [], propertyName, propertyValue, index) => {
        const annotation = value[index];
        annotation[propertyName] = propertyValue;

        updateChartOptions('style', 'Annotations', { value });
    };

    const updateConditional = (value = [], propertyName, propertyValue, index) => {
        const condition = value[index];
        condition[propertyName] = propertyValue;

        updateChartOptions('style', STYLE_COMPONENT.CONDITIONAL_FORMATTING, { value });
    };

    const getInputContent = () => {
        const chart = chartTypes.find((el) => el.name === selectedEntityType);
        const { configuration: { input } } = chart;

        const savedInput = (selectedEntityOptions?.input || []);
        const fields = input.map((f) => {
            const currentSavedField = savedInput.find((item) => item.name === f.name);
            if (currentSavedField) return currentSavedField;

            return f;
        });

        return (
            <div className={classes.optionsWrapper}>
                <div className="input-tab">
                    {fields
                        .filter((field) => !['PivotRows', 'PivotColumns', 'PivotValues'].includes(field.name))
                        .map((field, index) => {
                            const isPivotTableOption = isTable && field.name === 'PivotTable';
                            const pivotRows = (fields.find((item) => item.name === 'PivotRows')?.value ?? []);
                            const pivotColumns = (fields.find((item) => item.name === 'PivotColumns')?.value ?? []);
                            const pivotValues = (fields.find((item) => item.name === 'PivotValues')?.value ?? []);

                            return (
                                <div key={index} className={isTable ? classes.table : ''}>
                                    {(!isTable || (isTable && !isPivotTable && !isPivotTableOption)) && (
                                        <div>
                                            {'optional' in field ? `${field.name} (Optional)` : field.name}
                                        </div>
                                    )}
                                    {!isTable && (
                                        <div
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, field)}
                                        >
                                            {field.value && (
                                                <div
                                                    onClick={() => updateChartOptions('input', field.name, {
                                                        value: null,
                                                        dataType: null,
                                                        aggregate: false,
                                                        function: 'COUNT',
                                                    })}
                                                >
                                                    <span className={classes.text}>{field.value}</span>
                                                    <HighlightOffOutlinedIcon />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {isTable && !isPivotTable && field.name === 'Columns' && (
                                        <div
                                            className={classes.columnsDrop}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, field)}
                                        >
                                            {field.value.length === 0 && (
                                                <span className={classes.noChartType}>Drop your columns here. (Then you can reorder them)</span>
                                            )}
                                            {field.value.map((column, idx) => (
                                                <InputColumn
                                                    key={idx}
                                                    index={idx}
                                                    field={field}
                                                    column={column}
                                                    prepareItem={prepareItem}
                                                    reorderItems={reorderItems}
                                                    removeColumn={removeColumn}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    {isPivotTable && field.name === 'Columns' && (
                                        <PivotTableInputContent
                                            PivotRows={pivotRows}
                                            PivotColumns={pivotColumns}
                                            PivotValues={pivotValues}
                                            prepareItem={prepareItem}
                                            reorderItems={reorderItems}
                                            removeColumn={removeColumn}
                                            handleDrop={handleDrop}
                                            updateChartOptions={updateChartOptions}
                                        />
                                    )}
                                    {isPivotTableOption && (
                                        <div className={classes.pivotOption}>
                                            <Form.Group as={Row}>
                                                <Form.Label>Pivot Table:</Form.Label>
                                                <Checkbox
                                                    color="default"
                                                    disableRipple
                                                    checked={field.value ?? false}
                                                    onChange={(_, value) => updateChartOptions('input', 'PivotTable', { value })}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {'dataType' in field && (
                                        <SelectWidget
                                            nowrap
                                            size="sm"
                                            loading={false}
                                            name=""
                                            placeholder="Column's Data Type"
                                            className={classes.input}
                                            onChange={(_, value) => updateChartOptions('input', field.name, { dataType: value })}
                                            value={field.dataType || ''}
                                            options={
                                                field
                                                    .dataTypeOptions
                                                    .map((f) => ({
                                                        value: f,
                                                        label: `Data Type: ${f}`,
                                                    }))
                                            }
                                        />
                                    )}
                                    {'aggregate' in field && (
                                        <>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        disabled={!field.value}
                                                        checked={field.aggregate}
                                                        onClick={() => updateChartOptions('input', field.name, { aggregate: !field.aggregate })}
                                                    />
                                                )}
                                                label="Aggregate"
                                            />
                                            {field.aggregate && (
                                                <SelectWidget
                                                    nowrap
                                                    size="sm"
                                                    name=""
                                                    loading={false}
                                                    className={classes.input}
                                                    onChange={(_, value) => updateChartOptions('input', field.name, { function: value })}
                                                    value={field.function}
                                                    options={
                                                        field
                                                            .functionOptions
                                                            .map((f) => ({
                                                                value: f,
                                                                label: f,
                                                            }))
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    const availableColumnsForFormatting = queryColumns
        .map((col) => ({ ...col, type: BIHelper.getColumnDataType(col.name, selectedEntityLoadedColumns)?.type }))
        .filter((col) => !StringUtils.isEmpty(col.type) && [CHART_COLUMN_DATA_TYPE.ALPHANUMERIC, CHART_COLUMN_DATA_TYPE.NUMERIC].includes(col.type));
    const filterConditionsForColumnType = (column) => {
        if (StringUtils.isEmpty(column)) return [];

        const type = availableColumnsForFormatting.find((col) => col.name === column)?.type;
        if (!type) return [];

        const conditions = { ...FORMATTING_CONDITION };
        if (type === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC) {
            delete conditions.GREATER_THAN;
            delete conditions.GREATER_THAN_OR_EQUAL_TO;
            delete conditions.LESS_THAN;
            delete conditions.LESS_THAN_OR_EQUAL_TO;
        }

        return Object.keys(conditions)
            .map((key) => ({
                value: conditions[key],
                label: StringUtils.toPascalCase(key.replace(/_/g, ' ').toLowerCase()),
            }));
    };

    const renderStyleComponent = (type, value, options, name, propertyName, otherPropertyValue) => {
        const toUpdate = StringUtils.firstCharToLowerCase((name || '').replace(' ', ''));
        if (type === STYLE_COMPONENT.TEXT) {
            let currentValue = null;
            if (selectedEntityOptions && value && typeof value === 'object') {
                const section = selectedEntityOptions[value.section];
                const property = (section || []).find((el) => el.name === value.propertyName);
                if (property) currentValue = property[value.target];
            } else if (typeof value === 'string') {
                currentValue = value;
            }

            return (
                <Form.Control
                    placeholder={name}
                    className={classes.input}
                    type="text"
                    value={currentValue || ''}
                    onChange={({ target }) => updateChartOptions('style', propertyName, { [toUpdate]: target.value })}
                />
            );
        }

        if (type === STYLE_COMPONENT.NUMBER) {
            return (
                <>
                    <span>{name}</span>
                    <InputNumber
                        value={Number(value)}
                        onChange={(newValue) => updateChartOptions('style', propertyName, { [toUpdate]: newValue })}
                        decimalScale={0}
                        size="sm"
                    />
                </>
            );
        }

        if (type === STYLE_COMPONENT.SELECT) {
            return (
                <SelectWidget
                    nowrap
                    size="sm"
                    loading={false}
                    name=""
                    className={classes.input}
                    onChange={(_, newValue) => updateChartOptions('style', propertyName, { [toUpdate]: newValue })}
                    value={value}
                    options={
                        options
                            .map((f) => ({
                                value: f,
                                label: `${name}: ${f}`,
                            }))
                    }
                />
            );
        }

        if (type === STYLE_COMPONENT.CHECKBOX) {
            return (
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={value}
                            onClick={() => updateChartOptions('style', propertyName, { [toUpdate]: !value })}
                        />
                    )}
                    label={name}
                />
            );
        }

        if (type === STYLE_COMPONENT.ANNOTATION) {
            return (
                <div className={classes.annotations}>
                    <Button
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => addAnnotation(value)}
                    />
                    <div>
                        {(value || []).map((element, index) => (
                            <div key={index} className={classes.annotationRow}>
                                <Tooltip title="Label (Not required)">
                                    <Form.Control
                                        placeholder="Label"
                                        className={classes.input}
                                        type="text"
                                        value={element.text || ''}
                                        onChange={({ target }) => updateAnnotation(value, 'text', target.value, index)}
                                    />
                                </Tooltip>
                                <Tooltip title="Value in Axis">
                                    <InputNumber
                                        value={Number(element.axisValue || 0)}
                                        onChange={(newValue) => updateAnnotation(value, 'axisValue', newValue, index)}
                                        decimalScale={0}
                                        size="sm"
                                    />
                                </Tooltip>
                                <Tooltip title="Color">
                                    <input
                                        type="color"
                                        value={element.lineColor || '#ffffff'}
                                        onChange={({ target }) => updateAnnotation(value, 'lineColor', target.value, index)}
                                    />
                                </Tooltip>
                                <Button
                                    className={clsx(classes.containedError, classes.deleteButton)}
                                    size="small"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    onClick={() => removeAnnotation(value, index)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        if (type === STYLE_COMPONENT.CONDITIONAL_FORMATTING) {
            return (
                <div className={classes.conditionalFormatting}>
                    <Button
                        disabled={availableColumnsForFormatting.length === 0}
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => addConditionalFormat(value)}
                    />
                    <div>
                        {(value || []).map((element, index) => (
                            <div key={index} className={classes.conditionalRow}>
                                <div>
                                    <SelectWidget
                                        nowrap
                                        size="sm"
                                        loading={false}
                                        name=""
                                        placeholder="Column"
                                        className={clsx(classes.input, !element.column ? 'invalid-field' : '')}
                                        onChange={(_, newValue) => updateConditional(value, 'column', newValue, index)}
                                        value={element.column || ''}
                                        options={availableColumnsForFormatting.map((col) => ({ value: col.name, label: col.name }))}
                                    />
                                    <SelectWidget
                                        nowrap
                                        size="sm"
                                        loading={false}
                                        name=""
                                        placeholder="Condition"
                                        className={clsx(classes.input, !element.condition ? 'invalid-field' : '')}
                                        onChange={(_, newValue) => updateConditional(value, 'condition', newValue, index)}
                                        value={element.condition || ''}
                                        options={filterConditionsForColumnType(element.column)}
                                    />
                                    <Form.Control
                                        placeholder="Value"
                                        className={clsx(classes.input, !element.value ? 'invalid-field' : '')}
                                        type="text"
                                        value={element.value || ''}
                                        onChange={({ target }) => updateConditional(value, 'value', target.value, index)}
                                    />
                                    <SelectWidget
                                        nowrap
                                        size="sm"
                                        loading={false}
                                        name=""
                                        className={classes.input}
                                        onChange={(_, newValue) => updateConditional(value, 'fontSize', newValue, index)}
                                        value={element.fontSize || ''}
                                        options={[4, 7, 5, 9, 10, 11, 12, 14, 18].map((size) => ({ value: size, label: size }))}
                                    />
                                    <Button
                                        className={element.bold ? classes.bolded : ''}
                                        size="small"
                                        startIcon={<FormatBoldOutlinedIcon />}
                                        onClick={() => updateConditional(value, 'bold', !element.bold, index)}
                                    />
                                    <Button
                                        style={element.fontColor ? {
                                            border: `1px solid ${element.fontColor}`,
                                        } : {}}
                                        size="small"
                                        startIcon={<FormatColorTextOutlinedIcon />}
                                        onClick={() => fontColorRef.current[index].click()}
                                    />
                                    <input
                                        ref={(el) => { fontColorRef.current[index] = el; }}
                                        className={classes.hiddenColorSelector}
                                        type="color"
                                        value={element.fontColor || '#ffffff'}
                                        onChange={({ target }) => updateConditional(value, 'fontColor', target.value, index)}
                                    />
                                    <Button
                                        style={element.backgroundColor ? {
                                            border: `1px solid ${element.backgroundColor}`,
                                        } : {}}
                                        size="small"
                                        startIcon={<FormatColorFillOutlinedIcon />}
                                        onClick={() => backgroundColorRef.current[index].click()}
                                    />
                                    <input
                                        ref={(el) => { backgroundColorRef.current[index] = el; }}
                                        className={classes.hiddenColorSelector}
                                        type="color"
                                        value={element.backgroundColor || '#ffffff'}
                                        onChange={({ target }) => updateConditional(value, 'backgroundColor', target.value, index)}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className={clsx(classes.containedError, classes.deleteButton)}
                                        size="small"
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                        onClick={() => removeConditional(value, index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        if (type === STYLE_COMPONENT.DISPLAY_TOTALS) {
            const selectedCols = (selectedEntityOptions?.input || []).find((x) => x.name === 'Columns')?.value || [];
            const numericColumns = selectedCols
                .map((col) => {
                    const colIndex = queryColumns.findIndex((x) => x.name === col);
                    if (colIndex < 0) return { col, type: CHART_COLUMN_DATA_TYPE.ALPHANUMERIC };
                    return { col, ...BIHelper.getColumnDataType(col, selectedEntityLoadedColumns) };
                })
                .filter((x) => x.type === CHART_COLUMN_DATA_TYPE.NUMERIC);

            return (
                <>
                    <Checkbox
                        className={classes.displayTotals}
                        checked={value}
                        onClick={() => updateChartOptions('style', propertyName, { value: !value })}
                    />
                    <Select
                        disabled={!value}
                        displayEmpty
                        multiple
                        name="columnsSkipped"
                        value={otherPropertyValue || []}
                        onChange={({ target }) => updateChartOptions('style', propertyName, { columnsSkipped: target.value })}
                        renderValue={(selected) => {
                            if (selected.length === 0) return (<em>Columns to Skip</em>);
                            return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                    >
                        {numericColumns.map(({ col }, index) => (
                            <MenuItem key={index} value={col}>
                                <Checkbox checked={(otherPropertyValue || []).includes(col)} />
                                <ListItemText primary={col} />
                            </MenuItem>
                        ))}
                    </Select>
                </>
            );
        }

        return null;
    };

    const getStyleContent = () => {
        const chart = chartTypes.find((el) => el.name === selectedEntityType);
        const { configuration: { style } } = chart;

        const savedStyle = (selectedEntityOptions?.style || []).map((s) => {
            const prop = style.find((x) => x.name === s.name) ?? {};
            return { ...prop, ...s };
        });
        const newProps = style.filter((st) => !savedStyle.some((se) => se.name === st.name));

        return (
            <div className={classes.optionsWrapper}>
                <div className="style-tab">
                    {[...savedStyle, ...newProps].sort((a, b) => a.name.localeCompare(b.name)).map((field, index) => (
                        <div key={index} className={isTable ? classes.tableStyle : classes.inputField}>
                            <div>
                                {field.name}
                            </div>
                            {'enabled' in field && renderStyleComponent(STYLE_COMPONENT.CHECKBOX, field.enabled, null, 'Enabled', field.name)}
                            {'title' in field && renderStyleComponent(STYLE_COMPONENT.TEXT, field.title || field.titleDefault, null, 'Title', field.name)}
                            {'width' in field && renderStyleComponent(STYLE_COMPONENT.NUMBER, field.width, null, 'Width', field.name)}
                            {'size' in field && renderStyleComponent(STYLE_COMPONENT.NUMBER, field.size, null, 'Size', field.name)}
                            {'strokeWidth' in field && renderStyleComponent(STYLE_COMPONENT.NUMBER, field.strokeWidth, null, 'Stroke Width', field.name)}
                            {'positionOptions' in field && renderStyleComponent(
                                STYLE_COMPONENT.SELECT,
                                field.position,
                                field.positionOptions,
                                'Position', field.name,
                            )}
                            {'curveOptions' in field && renderStyleComponent(STYLE_COMPONENT.SELECT, field.curve, field.curveOptions, 'Curve', field.name)}
                            {'lineCapOptions' in field
                                && renderStyleComponent(STYLE_COMPONENT.SELECT, field.lineCap, field.lineCapOptions, 'Line Cap', field.name)}
                            {'shapeOptions' in field && renderStyleComponent(STYLE_COMPONENT.SELECT, field.shape, field.shapeOptions, 'Shape', field.name)}
                            {'showX-Axis' in field && renderStyleComponent(STYLE_COMPONENT.CHECKBOX, field['showX-Axis'], null, 'Show X-Axis', field.name)}
                            {'showY-Axis' in field && renderStyleComponent(STYLE_COMPONENT.CHECKBOX, field['showY-Axis'], null, 'Show Y-Axis', field.name)}
                            {field.name === 'Annotations' && renderStyleComponent(STYLE_COMPONENT.ANNOTATION, field.value, null, null, field.name)}
                            {field.name === STYLE_COMPONENT.CONDITIONAL_FORMATTING
                            && renderStyleComponent(STYLE_COMPONENT.CONDITIONAL_FORMATTING, field.value, null, null, field.name)}
                            {field.name === STYLE_COMPONENT.DISPLAY_TOTALS
                            && renderStyleComponent(STYLE_COMPONENT.DISPLAY_TOTALS, field.value, null, null, field.name, field.columnsSkipped)}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const getAvailableVariables = () => {
        const query = selectedEntityQuery ?? '';
        return [...new Set(
            (query.match(/\s{1}@\w+\s{1}/gmi) ?? [])
                .map((variable) => variable?.trim()?.replace('\n', ''))
                .filter((variable) => !StringUtils.isEmpty(variable)),
        )].map((variable) => ({ label: variable, value: variable }));
    };

    const paginationEnabled = ['@Offset', '@PageSize']
        .every((variable) => (selectedEntityQuery ?? '').toLowerCase().includes(variable.toLowerCase()))
        && selectedEntityType === 'Table';
    const getFiltersContent = () => {
        const chart = chartTypes.find((el) => el.name === selectedEntityType);
        const { configuration: { filters } } = chart;
        const current = (selectedEntityOptions?.filters || filters);

        return (
            <div className={classes.filtersWrapper}>
                <div
                    className={classes.filtersDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, null, true)}
                >
                    {current.length === 0 && (
                        <span className={classes.noChartType}>Drop your filters here. (Then you can reorder them)</span>
                    )}
                    {current.map(({
                        column, dataType, startDate, endDate,
                    }, index) => (
                        <div
                            key={index}
                            draggable
                            data-item-section="filters"
                            data-item-property={JSON.stringify({ value: current })}
                            data-item-index={index}
                            onDragStart={(e) => prepareItem(e)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => reorderItems(e, index)}
                        >
                            <span className={classes.text}>{`${index + 1}. ${column}`}</span>
                            <div>
                                {dataType === CHART_COLUMN_DATA_TYPE.DATE && (
                                    <>
                                        <Select
                                            displayEmpty
                                            name="Start Date"
                                            value={startDate ?? ''}
                                            className={paginationEnabled && !startDate ? 'invalid-field' : ''}
                                            onChange={({ target }) => updateChartOptions('filters', null, { value: column, dataType, startDate: target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            {getAvailableVariables().map(({ label, value }, idx) => (
                                                <MenuItem key={idx} value={value}>
                                                    <ListItemText primary={label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Select
                                            displayEmpty
                                            name="End Date"
                                            value={endDate ?? ''}
                                            className={paginationEnabled && !endDate ? 'invalid-field' : ''}
                                            onChange={({ target }) => updateChartOptions('filters', null, { value: column, dataType, endDate: target.value })}
                                            MenuProps={MenuProps}
                                        >
                                            {getAvailableVariables().map(({ label, value }, idx) => (
                                                <MenuItem key={idx} value={value}>
                                                    <ListItemText primary={label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                )}
                                <HighlightOffOutlinedIcon onClick={() => removeFilter(index)} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderDataPreview = () => {
        if (selectedEntityLoadedColumns.length === 0) return (<div />);

        const defaultColumnWidth = 150;
        const parentWidth = document.getElementById('preview-wrapper')?.clientWidth || 0;
        const columns = selectedEntityLoadedColumns[0].map((el) => StringUtils.toPascalCase(el.name));
        let tableWidth = columns.length * defaultColumnWidth;
        if (parentWidth > tableWidth) tableWidth = parentWidth - 30;

        return (
            <div className={clsx(classes.tableContainer, classes.extraStyle)}>
                <VirtualTable
                    loading={false}
                    rowHeight={35}
                    totalRecords={selectedEntityLoadedColumns.length}
                    data={selectedEntityLoadedColumns}
                    columns={columns.map((col) => ({
                        label: col,
                        dataKey: col,
                        width: defaultColumnWidth,
                        cellRenderer: (cell) => {
                            const { rowData: record } = cell;
                            const value = record.find((e) => e.name?.toLowerCase() === col.toLowerCase())?.value;

                            return (
                                <span className={classes.text}>{value === 'null' ? '' : value}</span>
                            );
                        },
                    }))}
                    width={tableWidth}
                />
            </div>
        );
    };

    const toggleScheduleReport = (record) => {
        const clone = cloneDeep(selectedEntityUsers)
            .filter((selected) => selected.userId !== record.userId);
        onChange({ target: { name: 'selectedEntityUsers', value: [...clone, { ...record, scheduleReport: !record.scheduleReport }] } });
    };

    const {
        total,
        defaultDateValues,
    } = selectedChartExtraOptions ?? {};
    const renderChartPreview = () => (
        <>
            <Button
                size="small"
                startIcon={<CachedOutlinedIcon />}
                onClick={() => {
                    getDataPreview({ variables: { statement: selectedEntityQuery } });
                    onChange({ target: { name: 'chartKey', value: TableUtils.generateUUID() } });
                }}
            />
            {StringUtils.isEmpty(chartKey) && (
                <div className={classes.noPreview}>
                    Click on preview to see the latest changes
                </div>
            )}
            {!StringUtils.isEmpty(chartKey) && (
                <Chart
                    cache
                    inSettings
                    tableWidth={document.getElementById('preview-wrapper')?.clientWidth - 35}
                    key={chartKey}
                    label={selectedEntityLabel}
                    type={selectedEntityType}
                    query={selectedEntityQuery}
                    totalRecords={total ?? selectedEntityLoadedColumns.length}
                    data={selectedEntityLoadedColumns}
                    options={selectedEntityOptions}
                    pullQueryResultsWithFilters={(filter) => getDataPreview({ variables: { statement: selectedEntityQuery } }, filter)}
                    defaultDateValues={defaultDateValues}
                    updateChartOptions={updateChartOptions}
                />
            )}
        </>
    );

    const getColumns = () => {
        const columns = [{
            headerClassName: classes.tableHeader,
            label: 'ID',
            dataKey: 'userId',
            width: 60,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.userId}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'First Name',
            dataKey: 'firstName',
            width: 170,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{StringUtils.toPascalCase(record.firstName?.toLowerCase())}</span>
                );
            },
        }];

        if (!isMobile) {
            columns.push({
                headerClassName: classes.tableHeader,
                label: 'Last Name',
                dataKey: 'lastName',
                width: 150,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;

                    return (
                        <span className={classes.text}>{StringUtils.toPascalCase(record.lastName?.toLowerCase())}</span>
                    );
                },
            },
            {
                headerClassName: classes.tableHeader,
                label: 'Employee Type',
                dataKey: 'employeeType',
                width: 200,
                cellRenderer: (cell) => {
                    const { rowData: record } = cell;

                    return (
                        <span className={classes.text}>{record.employeeType}</span>
                    );
                },
            });

            if (!isRuleEntity) {
                columns.push({
                    headerClassName: classes.tableHeader,
                    label: 'Schedule Report ',
                    dataKey: 'scheduleReport ',
                    width: 150,
                    cellRenderer: (cell) => {
                        const { rowData: record } = cell;
                        if (!canWrite) return null;

                        return (
                            <Checkbox
                                disabled={scheduleReport.frequency == null}
                                key={record.userId}
                                checked={record.scheduleReport}
                                onClick={() => toggleScheduleReport(record)}
                            />
                        );
                    },
                });
            }
        }

        columns.push({
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!canWrite) return null;

                return (
                    <Button
                        className={clsx(classes.containedError, classes.deleteButton)}
                        size="small"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={() => removeUser(record)}
                    />
                );
            },
        });

        return columns;
    };

    const onSave = () => {
        const someUserSelected = selectedEntityUsers.some((item) => item.scheduleReport);
        if (scheduleReport.frequency != null && !someUserSelected) {
            setOpenScheduleReport(true);
        } else {
            setSelectedTab(0);
            onSaveEntity();
        }
    };

    const formattedCategories = categories.map((cat) => ({ value: cat.id, label: cat.name?.toUpperCase() }));
    const filteredGoals = goals
        .filter((goal) => goal.category?.id === selectedGoalCategory)
        .sort((a, b) => a.label.localeCompare(b.label));
    const filteredUsers = users
        .filter((user) => !StringUtils.isEmpty(user.employeeType)
            && !['Integration', 'Other'].includes(user.employeeType)
            && !selectedEntityUsers.some((el) => el.userId === user.userId))
        .sort((a, b) => a.firstName.localeCompare(b.firstName));
    const isPreviewReady = selectedEntityLoadedColumns.length > 0
        && selectedEntityOptions
        && (
            !isTable
            || (isTable && !isPivotTable && selectedEntityOptions.input?.find((item) => item.name === 'Columns')?.value?.length > 0)
            || (
                isTable
                && isPivotTable
                && selectedEntityOptions.input?.find((item) => item.name === 'Columns')?.value?.length > 0
                && selectedEntityOptions.input?.find((item) => item.name === 'PivotRows')?.value?.length > 0
            )
        );
    return (
        <Dialog
            open={isDialogOpen}
            fullWidth
            maxWidth={isRuleEntity ? 'md' : 'lg'}
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={isRuleEntity ? 'Rule' : 'Chart'}
                onClose={() => { setSelectedTab(0); toggleDialog(); }}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item lg={9} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Label (160 Characters Max)</Form.Label>
                                <Form.Control
                                    ref={labelRef}
                                    autoFocus
                                    className={classes.input}
                                    spellCheck="false"
                                    type="text"
                                    name="selectedEntityLabel"
                                    value={selectedEntityLabel || ''}
                                    onChange={onChange}
                                    maxLength={160}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Category</Form.Label>
                                <SelectWidget
                                    nowrap
                                    size="sm"
                                    loading={false}
                                    className={classes.input}
                                    name="selectedEntityCategory"
                                    onChange={(name, value) => onChange({ target: { name, value } })}
                                    value={selectedEntityCategory || ''}
                                    options={formattedCategories}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.queryWrapper}>
                        <Grid item lg={9} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>
                                    {isRuleEntity ? 'Query' : 'Query (Add only the columns needed for the visualization)'}
                                </Form.Label>
                                <Form.Control
                                    ref={queryRef}
                                    disabled={loadingColumns}
                                    spellCheck="false"
                                    as="textarea"
                                    name="selectedEntityQuery"
                                    value={selectedEntityQuery || ''}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Goals</Form.Label>
                                <SelectWidget
                                    nowrap
                                    size="sm"
                                    loading={false}
                                    className={classes.input}
                                    name="selectedGoalCategory"
                                    onChange={(name, value) => onChange({ target: { name, value } })}
                                    value={selectedGoalCategory || ''}
                                    options={formattedCategories}
                                />
                                <div className={classes.goals}>
                                    {filteredGoals.map((goal, index) => {
                                        const variable = `[${StringUtils.toPascalCase(goal.label?.toLowerCase())}]`;

                                        return (
                                            <div
                                                key={index}
                                                className={classes.text}
                                                onMouseDown={(event) => {
                                                    event.preventDefault();
                                                    insertGoalInStatement(variable);
                                                }}
                                            >
                                                {variable}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    Single click to add. Make sure the field to use is focused and the caret in the insert spot.
                                    You can also highlight some text to replace
                                </div>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    {isRuleEntity && (
                        <Form.Group className={classes.instructionsWrapper} as={Col}>
                            <Form.Label className={classes.subLabel}>Instructions (2000 Characters Max)</Form.Label>
                            <Jodit
                                config={joditConfiguration}
                                value={selectedEntityInstructions || ''}
                                onChange={(newContent) => onChange({ target: { name: 'selectedEntityInstructions', value: newContent } })}
                            />
                        </Form.Group>
                    )}
                    {!isRuleEntity && (
                        <div className={classes.visualizationWrapper}>
                            <Form.Label className={classes.subLabel}>Visualization Set-Up</Form.Label>
                            <div>
                                <div>
                                    <span>1. Select a Chart Type</span>
                                    <div>
                                        {chartTypes.map((type, index) => (
                                            <Tooltip title={type.name} key={index}>
                                                <div
                                                    className={selectedEntityType === type.name ? classes.selectedChart : ''}
                                                    onClick={
                                                        () => {
                                                            setSelectedTab(0);
                                                            onChange({ target: { name: ENTITY_FIELD_NAME.SELECTED_ENTITY_TYPE, value: type.name } });
                                                        }
                                                    }
                                                >
                                                    {type.icon}
                                                </div>
                                            </Tooltip>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <span>2. Load Columns</span>
                                    <div className={classes.refresh}>
                                        <Button
                                            disabled={!selectedEntityQuery || loadingColumns}
                                            size="small"
                                            startIcon={<CachedOutlinedIcon />}
                                            onClick={loadColumns}
                                        />
                                    </div>
                                    <LoadedColumnsList
                                        columns={queryColumns}
                                        data={selectedEntityLoadedColumns}
                                    />
                                </div>
                                <div>
                                    <span>3. Configure the chart</span>
                                    <div className={classes.tabsWrapper}>
                                        {!selectedEntityType && (
                                            <span
                                                className={classes.noChartType}
                                            >
                                                Select a Chart Type First
                                            </span>
                                        )}
                                        {selectedEntityType && (
                                            <>
                                                <Tabs
                                                    value={selectedTab}
                                                    onChange={(_, value) => setSelectedTab(value)}
                                                    indicatorColor="secondary"
                                                    textColor="primary"
                                                    variant="standard"
                                                >
                                                    <Tab label="Input" />
                                                    {!isPivotTable && (
                                                        <Tab label="Style" />
                                                    )}
                                                    <Tab label="Filters" />
                                                </Tabs>
                                                {selectedTab === 0 && getInputContent()}
                                                {selectedTab === 1 && !isPivotTable && getStyleContent()}
                                                {selectedTab === (isPivotTable ? 1 : 2) && getFiltersContent()}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isRuleEntity && (
                        <div id="preview-wrapper" className={classes.previewWrapper}>
                            <Form.Label className={classes.subLabel}>Preview</Form.Label>
                            {isPreviewReady && (
                                <>
                                    {renderChartPreview()}
                                    {!isTable && renderDataPreview()}
                                </>
                            )}
                            {!isPreviewReady && (
                                <div className={classes.noPreview}>
                                    Load columns and set the chart up first
                                </div>
                            )}
                        </div>
                    )}
                    {!isRuleEntity && isTable && (
                        <div id="schedule-report" className={classes.previewWrapper}>
                            <Form.Label className={classes.subLabel}>Schedule Report</Form.Label>
                            <Row className={clsx('mb-3', classes.scheduleReport)}>
                                <Form.Group as={Col}>
                                    <Form.Label>1. Frequency</Form.Label>
                                    <SelectWidget
                                        size="sm"
                                        name="frequency"
                                        onChange={onChangeFrequency}
                                        options={[{ label: 'None', value: null }, ...frequencyOption]}
                                        value={scheduleReport.frequency}
                                        maxMenuHeight={100}
                                    />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>2. Time</Form.Label>
                                    <div className={classes.containerTime}>
                                        <NumericInput
                                            strict
                                            min={1}
                                            max={12}
                                            step={1}
                                            precision={0}
                                            maxLength={2}
                                            disabled={!scheduleReport.frequency}
                                            value={scheduleReport.timeHour}
                                            className="form-control"
                                            onChange={(value) => onChangeScheduleReport('timeHour', value)}
                                        />
                                        <NumericInput
                                            strict
                                            min={0}
                                            max={59}
                                            step={1}
                                            precision={0}
                                            maxLength={2}
                                            disabled={!scheduleReport.frequency}
                                            value={scheduleReport.timeMinute}
                                            className="form-control"
                                            onChange={(value) => onChangeScheduleReport('timeMinute', value)}
                                        />
                                        <div className={classes.selectContainer}>
                                            <SelectWidget
                                                size="sm"
                                                name="timeAmPm"
                                                placeholder="Select"
                                                value={scheduleReport.timeAmPm}
                                                options={amPmOptionList}
                                                onChange={onChangeScheduleReport}
                                                disabled={!scheduleReport.frequency}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} className="full-width-date-picker">
                                    <Form.Label>3. Start on</Form.Label>
                                    <DatePicker
                                        name="startOn"
                                        minDate={new Date()}
                                        placeholderText="mm/dd/yyyy"
                                        selected={scheduleReport.startOn}
                                        disabled={!scheduleReport.frequency}
                                        className={clsx('form-control form-control-sm')}
                                        onChange={(value) => onChangeScheduleReport('startOn', value)}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                    )}
                    <div className={classes.usersWrapper}>
                        <Form.Label className={classes.subLabel}>Assigned Users</Form.Label>
                        <div>
                            <SelectWidget
                                nowrap
                                size="sm"
                                placeholder="Select by user"
                                loading={loadingUsers}
                                className={classes.input}
                                name={isRuleEntity ? ENTITY_FIELD_NAME.SELECTED_RULE_USER : ENTITY_FIELD_NAME.SELECTED_CHART_USER}
                                onChange={(name, value) => onChange({ target: { name, value } })}
                                value=""
                                options={filteredUsers.map((user) => ({
                                    value: user.userId,
                                    label: StringUtils.toPascalCase(`${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()} (${user.employeeType})`),
                                }))}
                            />
                            <SelectWidget
                                nowrap
                                size="sm"
                                placeholder="Select by Employee Type"
                                loading={loadingUsers}
                                className={classes.input}
                                name={ENTITY_FIELD_NAME.SELECTED_EMPLOYEE_TYPE}
                                onChange={(name, value) => onChange({ target: { name, value } })}
                                value=""
                                options={[
                                    ...new Set(filteredUsers.map((user) => user.employeeType).sort((a, b) => a.localeCompare(b))),
                                ].map((employeeType) => ({
                                    value: employeeType,
                                    label: employeeType,
                                }))}
                            />
                        </div>
                        <div className={classes.tableContainer}>
                            <VirtualTable
                                loading={loadingUsers}
                                rowHeight={45}
                                totalRecords={selectedEntityUsers.length}
                                data={selectedEntityUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))}
                                columns={getColumns()}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {!isRuleEntity && (
                    <Form.Group as={Col}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isCustomReport}
                                    onClick={(event) => onChange({ target: { name: 'isCustomReport', value: event.target.checked } })}
                                />
                            )}
                            label="Custom Report"
                        />
                    </Form.Group>
                )}
                <Form.Group as={Col}>
                    <Button
                        size="small"
                        className={classes.containedSecondaryInfo}
                        disabled={
                            savingEntity
                            || StringUtils.isEmpty(selectedEntityLabel)
                            || StringUtils.isEmpty(selectedEntityQuery)
                            || (isRuleEntity && StringUtils.isEmpty(selectedEntityInstructions))
                            || (!isRuleEntity && StringUtils.isEmpty(selectedEntityType))
                            || (!isRuleEntity && selectedEntityOptions === null)
                            || (
                                !isRuleEntity
                                && paginationEnabled
                                && selectedEntityOptions?.filters
                                && selectedEntityOptions.filters
                                    .some((filter) => filter.dataType === CHART_COLUMN_DATA_TYPE.DATE && (!filter.startDate || !filter.endDate))
                            )
                            || (scheduleReport.frequency != null && scheduleReport.startOn === null)
                        }
                        onClick={onSave}
                    >
                        {savingEntity ? 'Saving...' : 'Save'}
                    </Button>
                </Form.Group>
            </DialogActions>
            {openScheduleReport && (
                <ConfirmDialog
                    title="Schedule Report"
                    description="Please select Start date and at least one person to receive the report by checking the Schedule Report check box"
                    open={openScheduleReport}
                    variant="outlined"
                    dividerFooter={false}
                    hiddenSecondaryButton
                    onClickPrimary={() => setOpenScheduleReport(false)}
                    onClose={() => setOpenScheduleReport(false)}
                />
            )}
        </Dialog>
    );
};

AddEntityDialog.defaultProps = {
    selectedGoalCategory: null,
    selectedEntityLabel: null,
    selectedEntityQuery: null,
    selectedEntityInstructions: null,
    selectedEntityType: null,
    selectedEntityOptions: null,
    selectedEntityCategory: null,
    selectedChart: null,
    selectedChartExtraOptions: null,
    chartKey: null,
    scheduleReport: {},
    onChangeScheduleReport: () => {},
    onChangeFrequency: () => {},
};

AddEntityDialog.propTypes = {
    scheduleReport: PropTypes.object,
    classes: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    canWrite: PropTypes.bool.isRequired,
    chartKey: PropTypes.string,
    isRuleEntity: PropTypes.bool.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    toggleDialog: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSaveEntity: PropTypes.func.isRequired,
    savingEntity: PropTypes.bool.isRequired,
    getDataPreview: PropTypes.func.isRequired,
    loadingColumns: PropTypes.bool.isRequired,
    loadingUsers: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    goals: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    selectedGoalCategory: PropTypes.string,
    selectedEntityLabel: PropTypes.string,
    selectedEntityQuery: PropTypes.string,
    selectedEntityInstructions: PropTypes.string,
    selectedEntityType: PropTypes.string,
    selectedEntityOptions: PropTypes.object,
    selectedEntityLoadedColumns: PropTypes.array.isRequired,
    selectedChartExtraOptions: PropTypes.object,
    selectedEntityCategory: PropTypes.string,
    selectedEntityUsers: PropTypes.array.isRequired,
    selectedChart: PropTypes.string,
    isCustomReport: PropTypes.bool.isRequired,
    onChangeScheduleReport: PropTypes.func,
    onChangeFrequency: PropTypes.func,
};

export default AddEntityDialog;

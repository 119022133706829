import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { useTheme } from '@material-ui/core';
import { FetchPolicy } from 'utils/enum/Core';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';

const useCompanyThemeColors = () => {
    const keyStore = new KeyStore();
    const theme = useTheme();
    const [colors, setColors] = useState({
        fontColor: theme.palette.border.logan,
        hoverFontColor: theme.palette.text.white,
        headerFontColor: theme.palette.text.white,
        selectionFontColor: theme.palette.text.white,
        backgroundColor: theme.palette.background.bigStone,
        headerBackgroundColor: theme.palette.text.chambray,
        selectionBackgroundColor: theme.palette.background.steelBlue,
    });
    const {
        data, loading, error, refetch: refreshThemeColor,
    } = useQuery(CompanyQuery.GET_COMPANY_THEME, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: !keyStore.exists(),
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getCompanyTheme)) {
            const { getCompanyTheme } = data;
            const fontColor = ArrayUtils.getJSONValueOnArrayByKey(getCompanyTheme, 'fontColor');
            const backgroundColor = ArrayUtils.getJSONValueOnArrayByKey(getCompanyTheme, 'backgroundColor');
            const headerBackgroundColor = ArrayUtils.getJSONValueOnArrayByKey(getCompanyTheme, 'headerBackgroundColor');
            if (!isEmpty(fontColor) && !isEmpty(backgroundColor) && !isEmpty(headerBackgroundColor)) {
                setColors({
                    fontColor: `rgba(${fontColor.r},${fontColor.g},${fontColor.b},0.6)`,
                    hoverFontColor: `rgba(${fontColor.r},${fontColor.g},${fontColor.b},1)`,
                    headerFontColor: `rgba(${fontColor.r},${fontColor.g},${fontColor.b},1)`,
                    selectionFontColor: `rgba(${fontColor.r},${fontColor.g},${fontColor.b},1)`,
                    backgroundColor: `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`,
                    selectionBackgroundColor: `rgba(${headerBackgroundColor.r},${headerBackgroundColor.g},${headerBackgroundColor.b},0.7)`,
                    headerBackgroundColor: `rgba(${headerBackgroundColor.r},${headerBackgroundColor.g},${headerBackgroundColor.b},${headerBackgroundColor.a})`,
                });
            }
        }
    }, [data, loading, error]);

    return {
        colors, refreshThemeColor,
    };
};

export default useCompanyThemeColors;

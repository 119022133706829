import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
    Box,
    Grid,
    Button,
} from '@material-ui/core';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditVehicleDialog from 'components/modules/inventory/create/dialogs/EditVehicle/EditVehicleDialog';

const useStyles = makeStyles((theme) => InventoryDetailStyle.leftPanel(theme));

const TopDetailPanel = ({
    detail, isExtended, stockNumber,
    invUserTabName, onReload, blockingStatus,
    currentUserId, changeBlockingStatus,
}) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const { pricing = {} } = detail;
    const tmuEnabled = detail.tmu || detail.exempt || detail.eml;
    let milesLabel = detail.tmu ? 'TMU' : '';
    milesLabel = detail.exempt && !detail.tmu ? 'Exempt' : milesLabel;
    const trim = !StringUtils.isEmpty(detail.trim) ? detail.trim : '';
    const miles = NumberUtils.applyThousandsFormat(detail.miles || 0);
    const age = NumberUtils.applyThousandsFormat(pricing.age || 0);
    const internetPrice = NumberUtils.applyCurrencyFormat(pricing?.internetPrice, '$0,0');
    const toggleModal = () => {
        if (openDialog) {
            changeBlockingStatus({
                variables: {
                    stockNumber,
                    isBlocking: false,
                },
            });
        } else {
            changeBlockingStatus({
                variables: {
                    stockNumber,
                    isBlocking: true,
                },
            });
        }

        setOpenDialog((prevState) => !prevState);
    };

    const isRecordBlocked = blockingStatus?.isEditing === true && blockingStatus.editingById !== currentUserId;
    return (
        <>
            <div>
                <Grid container direction="row" alignItems="center" className={classes.milesRow}>
                    <Grid item xs={4} container direction="column" alignItems="center">
                        <Grid item>
                            <Box fontWeight="fontWeightLight">Miles</Box>
                        </Grid>
                        <Grid item>
                            <Box fontWeight="fontWeightBold">{miles}</Box>
                            {tmuEnabled && <Box fontWeight="fontWeightBold">{milesLabel}</Box>}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container direction="column" alignItems="center">
                        <Grid item>
                            <span>Int. Price</span>
                        </Grid>
                        <Grid item>
                            <Box fontWeight="fontWeightBold">{internetPrice}</Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container direction="column" alignItems="center">
                        <Grid item>
                            <span>Age</span>
                        </Grid>
                        <Grid item>
                            <Box fontWeight="fontWeightBold">{age}</Box>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.labels}>
                    <Button
                        disabled={isRecordBlocked}
                        color="primary"
                        size="small"
                        className={classes.editButton}
                        startIcon={<EditOutlinedIcon />}
                        onClick={toggleModal}
                    >
                        Edit
                    </Button>
                    <p>{`${detail.year} ${detail.make}`}</p>
                    <p>{`${detail.model} ${trim}`}</p>
                    {isExtended && (
                        <div>
                            <p>{detail.engine}</p>
                            <p>{detail.vin?.toUpperCase()}</p>
                            <p>{`${detail.transmission} ${detail.drivetrain || ''}`}</p>
                            <p>{detail.extColor}</p>
                            <p>{detail.intColor}</p>
                            <p>{detail.lotName}</p>
                        </div>
                    )}
                </div>
            </div>
            {openDialog && (
                <EditVehicleDialog
                    stockNumber={stockNumber}
                    open={openDialog}
                    toggleModal={toggleModal}
                    data={detail}
                    invUserTabName={invUserTabName}
                    onReload={onReload}
                />
            )}
        </>
    );
};

TopDetailPanel.propTypes = {
    detail: PropTypes.object.isRequired,
    stockNumber: PropTypes.number.isRequired,
    invUserTabName: PropTypes.object.isRequired,
    isExtended: PropTypes.bool,
    onReload: PropTypes.func,
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    changeBlockingStatus: PropTypes.func,
};

TopDetailPanel.defaultProps = {
    isExtended: false,
    blockingStatus: null,
    currentUserId: 0,
    onReload: () => null,
    changeBlockingStatus: () => null,
};

export default TopDetailPanel;

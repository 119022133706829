import React from 'react';
import PropTypes from 'prop-types';
import FeatureNotFoundIcon from 'assets/inventory/feature-not-found.svg';
import StringUtils from 'lib/StringUtils';

const Image = (props) => {
    const { alt, src } = props;
    const notFound = FeatureNotFoundIcon;
    const image = StringUtils.isEmpty(src) ? notFound : src;

    return (
        <img
            alt={alt}
            onError={(e) => {
                e.target.src = FeatureNotFoundIcon;
            }}
            {...props}
            src={image}
        />
    );
};

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
};

Image.defaultProps = {
    src: '',
    alt: '',
};

export default Image;

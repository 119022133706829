import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import {
    concat, findIndex, isEmpty, orderBy,
} from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import EmailForm from 'components/modules/crm/activities/form/EmailForm';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import EmailActivityItem from 'components/modules/crm/activities/EmailActivityItem';
import MailActivityDetail from 'components/modules/crm/activities/MailActivityDetail';

// Materia UI
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';

// Apollo
import KeyStore from 'utils/KeyStore';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useSubscription } from '@apollo/client';
import MailQuery from 'services/graphQL/query/crm/MailQuery';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import MailSubscription from 'services/graphQL/subscription/crm/MailSubscription';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    body: {
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
    },
    container: {
        height: '100%',
        padding: theme.spacing(2),
    },
    disabled: {
        '& g': {
            fill: '#c3c3c3',
        },
    },
}));

const ACTION_TYPE = {
    SET_OFFSET: 'setOffset',
    ADD_RECORD: 'addRecord',
    SET_RECORDS: 'setRecords',
    TOGGLE_MODAL: 'toggleModal',
    UPDATE_RECORD: 'updateRecord',
    SELECT_RECORD: 'selectRecord',
    UNSELECT_RECORD: 'unselectRecord',
};

const reducer = (state, action) => {
    const index = findIndex(state.records, { threadId: action.payload?.threadId });
    switch (action.type) {
    case ACTION_TYPE.TOGGLE_MODAL:
        return update(state, {
            open: { $set: action.open },
        });
    case ACTION_TYPE.SET_RECORDS:
        const records = concat(state.records, action.payload?.data);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.payload?.total },
        });
    case ACTION_TYPE.UPDATE_RECORD:
        if (index >= 0) {
            return update(state, {
                records: {
                    [index]: {
                        lastStatus: { $set: action.payload.status },
                    },
                },
            });
        }

        return state;
    case ACTION_TYPE.ADD_RECORD:
        if (index >= 0) {
            const result = orderBy(update(state.records, {
                [index]: {
                    lastBody: { $set: action.payload.bodyPlain },
                    lastStatus: { $set: action.payload.status },
                    lastIncoming: { $set: action.payload.incoming },
                    createdOn: { $set: action.payload.createdOn },
                    toFull: { $set: action.payload.toFull },
                    cc: { $set: action.payload.cc },
                },
            }), ['createdOn'], ['desc']);

            return { ...state, records: result };
        }
        return update(state, {
            records: { $unshift: [action.payload] },
            totalCount: { $set: state.totalCount + 1 },
        });
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    case ACTION_TYPE.UNSELECT_RECORD:
        return update(state, {
            threadId: { $set: null },
        });

    case ACTION_TYPE.SELECT_RECORD:
        return update(state, {
            threadId: { $set: action.payload },
        });
    default:
        return state;
    }
};

const keyStore = new KeyStore();
const EmailActivity = ({
    crmId, customerCode, email, leadCode,
    locationState, prospectLanguage,
}) => {
    const classes = useStyles();
    const CRM_LEAD_ACTIVITY_WRITE = keyStore.hasPermission(Permission.CRM_LEAD_ACTIVITY_WRITE);
    const { data: subscriptionData } = useSubscription(MailSubscription.EMAIL_LIST_BY_CUSTOMER_CODE_CHANGED, {
        variables: { customerCode },
        skip: (customerCode == null),
    });
    const [state, dispatch] = useReducer(reducer, {
        open: false,
        records: [],
        totalCount: 0,
        offset: 0,
        threadId: null,
    });
    const {
        data, error, loading,
    } = useQuery(MailQuery.GET_EMAILS_BY_CRMID,
        {
            variables: { crmId, paginate: { start: state.offset, limit: 25 } },
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data?.getEmailsByCRMId,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (subscriptionData) {
            const { emailListByCustomerCodeChanged } = subscriptionData;
            if (emailListByCustomerCodeChanged?.type?.toUpperCase() === SubscriptionActionType.NEW) {
                dispatch({
                    type: ACTION_TYPE.ADD_RECORD,
                    payload: emailListByCustomerCodeChanged.email,
                });
            }

            if (emailListByCustomerCodeChanged?.type?.toUpperCase() === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPE.UPDATE_RECORD,
                    payload: emailListByCustomerCodeChanged.email,
                });
            }
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (!isEmpty(locationState?.data?.threadId)) {
            dispatch({
                type: ACTION_TYPE.SELECT_RECORD,
                payload: locationState.data.threadId,
            });
        }
    }, [locationState]);

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };

    const onClickBack = () => {
        dispatch({
            type: ACTION_TYPE.UNSELECT_RECORD,
        });
    };

    const onSelectRecord = (threadId) => {
        dispatch({
            type: ACTION_TYPE.SELECT_RECORD,
            payload: threadId,
        });
    };

    if (error) {
        ModalUtils.errorMessage(error.graphQLErrors);
        return null;
    }

    const onToggle = (value) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_MODAL,
            open: value,
        });
    };
    const emailIsEmpty = StringUtils.isEmpty(email);

    return (
        <div className={clsx('d-flex-column', classes.container)}>
            {CRM_LEAD_ACTIVITY_WRITE && (
                <div className={classes.header}>
                    <Tooltip title={emailIsEmpty ? 'Customer does not have email' : ''}>
                        <span>
                            <IconButton
                                size="small"
                                aria-label="Add"
                                disabled={emailIsEmpty}
                                onClick={() => onToggle(true)}
                            >
                                <AddCircleOutlineIcon className={clsx({ [classes.disabled]: emailIsEmpty })} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            )}
            <InfiniteScroll
                lengthRecord={state?.records.length}
                totalRecord={state?.totalCount}
                loadMore={loadMore}
                load={loading}
                loadAtScrollPercent={80}
            >
                {
                    state.records.map((item, index) => (
                        <EmailActivityItem
                            key={index}
                            record={item}
                            onSelectRecord={onSelectRecord}
                        />
                    ))
                }
            </InfiniteScroll>
            {state.open && (
                <EmailForm
                    open={state.open}
                    onClose={() => onToggle(false)}
                    crmId={crmId}
                    email={email}
                    prospectLanguage={prospectLanguage}
                    leadCode={leadCode}
                />
            )}
            {!!state.threadId && (<MailActivityDetail threadId={state.threadId} onClickBack={onClickBack} crmId={crmId} />)}
        </div>
    );
};

EmailActivity.propTypes = {
    email: PropTypes.string,
    leadCode: PropTypes.number,
    customerCode: PropTypes.number,
    locationState: PropTypes.object,
    crmId: PropTypes.string.isRequired,
    prospectLanguage: PropTypes.string,
};

EmailActivity.defaultProps = {
    customerCode: null,
    email: null,
    leadCode: null,
    locationState: {},
    prospectLanguage: 'English',
};
export default EmailActivity;

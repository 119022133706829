import * as yup from 'yup';

const AccountingSettingsMappingSchema = yup.object().shape({
    accountsPayable: yup.number().moreThan(0),
    defaultBankChecks: yup.number().moreThan(0),
    defaultCheckType: yup.string().required(),
    accountingLockDate: yup.date().required(),
});

export default AccountingSettingsMappingSchema;

import React, { useReducer, useEffect } from 'react';

import {
    Dialog, DialogContent,
    makeStyles, Typography, Paper, Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import { ProductType } from 'utils/enum/DealEnum';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import MiscCommissionsQuery from 'services/graphQL/query/payroll/MiscCommissions';
import MiscCommissionsMutation from 'services/graphQL/mutate/payroll/MiscCommissions';

const ownStyles = makeStyles((theme) => ({
    boxRow: {
        padding: theme.spacing(1),
        '& .content-label': {
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: 'end',
        },
        '& .content-value': {
            paddingTop: 0,
            paddingBottom: 0,
            '& p': {
                textAlign: 'left',
            },
        },
        '& .content-products-value': {
            paddingTop: 0,
            paddingBottom: 0,
            '& p': {
                textAlign: 'center',
            },
        },
        minHeight: 310,
    },
    lineDivider: {
        height: 2,
        maxWidth: 80,
        display: 'block',
        marginBottom: theme.spacing(1),
        background: theme.palette.primary.main,
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    sectionSpace: {
        marginLeft: theme.spacing(1),
    },
    vehicleSection: {
        maxWidth: 340,
        minWidth: 340,
    },
    frontEndSection: {
        maxWidth: 210,
        minWidth: 210,
        marginLeft: theme.spacing(1),
    },
    backEndSection: {
        maxWidth: 230,
        minWidth: 230,
        marginLeft: theme.spacing(1),
    },
    productSection: {
        maxWidth: 330,
        minWidth: 330,
        marginLeft: theme.spacing(1),
    },
    productSectionDivider: {
        textAlign: '-webkit-center',
    },
    totalProfit: {
        marginTop: 30,
    },
    flex: {
        display: 'flex',
    },
    commissionSection: {
        marginTop: 5,
        maxWidth: 1135,
        minWidth: 1135,
        minHeight: 230,
        padding: theme.spacing(1),
    },
    overridePanel: {
        marginTop: 5,
        paddingLeft: 5,
        marginBottom: 20,
        alignItems: 'center',
    },
    overrideCheckBox: {
        marginTop: 4,
        marginLeft: 5,
        marginRight: 20,
        alignSelf: 'baseline',
    },
}));

const ACTION_TYPES = {
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
    SET_COMMISSION_DATA: 'setCommissionData',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            commissions: {
                [action.path]: {
                    [action.field]: {
                        $set: action.value,
                    },
                },
            },
        });
    case ACTION_TYPES.SET_COMMISSION_DATA:
        const {
            salesRep1Commission,
            salesRep2Commission,
            salesManagerCommission,
            financeManagerCommission,
            bdcAgentCommission,
        } = action.value;

        return update(state, {
            commissions: {
                salesPerson1: {
                    id: { $set: salesRep1Commission.id },
                    amount: { $set: salesRep1Commission.amount },
                    override: { $set: salesRep1Commission.override },
                },
                salesPerson2: {
                    id: { $set: salesRep2Commission.id },
                    amount: { $set: salesRep2Commission.amount },
                    override: { $set: salesRep2Commission.override },
                },
                salesManager: {
                    id: { $set: salesManagerCommission.id },
                    amount: { $set: salesManagerCommission.amount },
                    override: { $set: salesManagerCommission.override },
                },
                financeManager: {
                    id: { $set: financeManagerCommission.id },
                    amount: { $set: financeManagerCommission.amount },
                    override: { $set: financeManagerCommission.override },
                },
                bdcAgent: {
                    id: { $set: bdcAgentCommission.id },
                    amount: { $set: bdcAgentCommission.amount },
                    override: { $set: bdcAgentCommission.override },
                },
            },
        });
    case ACTION_TYPES.SET_STATE_VALUES:
        return { ...state, ...action.values };
    default: return state;
    }
};

const DealCommissionDialog = ({ accountNumber, onClose, refetch }) => {
    const classes = ownStyles();
    const initState = {
        products: [],
        discount: 0,
        salesPrice: 0,
        reserveAmount: 0,
        totalTradeACV: 0,
        totalTradeAllowance: 0,
        discountOnFrontEnd: false,
        vehicle: {
            vin: '',
            cost: 0,
            pack: 0,
            year: 0,
            make: '',
            miles: 0,
            trim: '',
            model: '',
            style: '',
            engine: '',
            extColor: '',
            stockNumber: 0,
            minimumPrice: 0,
            stickerPrice: 0,
            internetPrice: 0,
        },
        commissions: {
            salesPerson1: {
                id: 0,
                list: [],
                amount: 0,
                override: false,
            },
            salesPerson2: {
                id: 0,
                amount: 0,
                override: false,
            },
            bdcAgent: {
                id: 0,
                list: [],
                amount: 0,
                override: false,
            },
            financeManager: {
                id: 0,
                list: [],
                amount: 0,
                override: false,
            },
            salesManager: {
                id: 0,
                list: [],
                amount: 0,
                override: false,
            },
        },
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        vehicle, products,
        commissions: {
            salesPerson1, salesPerson2, bdcAgent, financeManager, salesManager,
        },
    } = state;
    const {
        data, loading, error,
    } = useQuery(MiscCommissionsQuery.GET_DEAL_COMMISSION_DETAIL, {
        variables: { accountNumber },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getDealCommissionDetail } = data;
            dispatch({
                values: getDealCommissionDetail,
                type: ACTION_TYPES.SET_STATE_VALUES,
            });
        }
    }, [data, loading, error]);

    const [calculateCommissions, {
        loading: loadingCommissions, data: commissionsData, error: commissionsError,
    }] = useLazyQuery(MiscCommissionsQuery.CALCULATE_COMMISSIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (commissionsError) {
            ModalUtils.errorMessage(commissionsError?.graphQLErrors);
            return;
        }

        if (!loadingCommissions && commissionsData?.calculatePayrollDealCommission) {
            dispatch({
                type: ACTION_TYPES.SET_COMMISSION_DATA,
                value: commissionsData.calculatePayrollDealCommission,
            });
        }
    }, [commissionsData, loadingCommissions, commissionsError]);

    const [saveChanges, { loading: isUpdating }] = useMutation(MiscCommissionsMutation.SAVE_COMMISSIONS, {
        onCompleted: (mutationData) => {
            if (mutationData?.savePayrollDealCommissions) {
                ModalUtils.successMessage(null, 'Changes Saved Successfully!');
                onClose();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onChange = (path, field, value) => {
        if (field === 'id' || field === 'override') {
            calculateCommissions({
                variables: {
                    accountNumber,
                    commissionFields: {
                        salesRep1Commission: {
                            id: path === 'salesPerson1' && field === 'id' ? value : salesPerson1.id,
                            amount: salesPerson1.amount,
                            override: path === 'salesPerson1' && field === 'override' ? value : salesPerson1.override,
                        },
                        salesRep2Commission: {
                            id: path === 'salesPerson2' && field === 'id' ? value : salesPerson2.id,
                            amount: salesPerson2.amount,
                            override: path === 'salesPerson2' && field === 'override' ? value : salesPerson2.override,
                        },
                        salesManagerCommission: {
                            id: path === 'salesManager' && field === 'id' ? value : salesManager.id,
                            amount: salesManager.amount,
                            override: path === 'salesManager' && field === 'override' ? value : salesManager.override,
                        },
                        financeManagerCommission: {
                            id: path === 'financeManager' && field === 'id' ? value : financeManager.id,
                            amount: financeManager.amount,
                            override: path === 'financeManager' && field === 'override' ? value : financeManager.override,
                        },
                        bdcAgentCommission: {
                            id: path === 'bdcAgent' && field === 'id' ? value : bdcAgent.id,
                            amount: bdcAgent.amount,
                            override: path === 'bdcAgent' && field === 'override' ? value : bdcAgent.override,
                        },
                    },
                },
            });
        }
        dispatch({
            path,
            field,
            value,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const onSave = () => {
        saveChanges({
            variables: {
                accountNumber,
                commissions: {
                    salesRep1: {
                        id: salesPerson1.id,
                        amount: salesPerson1.amount,
                        override: salesPerson1.override,
                    },
                    salesRep2: {
                        id: salesPerson2.id,
                        amount: salesPerson2.amount,
                        override: salesPerson2.override,
                    },
                    salesManager: {
                        id: salesManager.id,
                        amount: salesManager.amount,
                        override: salesManager.override,
                    },
                    financeManager: {
                        id: financeManager.id,
                        amount: financeManager.amount,
                        override: financeManager.override,
                    },
                    bdcAgent: {
                        id: bdcAgent.id,
                        amount: bdcAgent.amount,
                        override: bdcAgent.override,
                    },
                },
            },
        });
    };

    const mapList = (list) => list.map((item) => ({ label: item.fullName, value: item.id }));

    const salesPersonList = mapList(salesPerson1.list);
    const salesManagerList = mapList(salesManager.list);
    const bdcAgentList = mapList(bdcAgent.list);
    const financeManagerList = mapList(financeManager.list);
    const gap = products.find((product) => product.type === ProductType.GAP);
    const warranty = products.find((product) => product.type === ProductType.VSC);
    const productsOneToSix = products.filter((product) => product.type !== ProductType.VSC && product.type !== ProductType.GAP);
    const totalFrontEnd = state.salesPrice - vehicle.cost + vehicle.pack + state.totalTradeACV - state.totalTradeAllowance
                        - salesPerson1.amount - salesPerson2.amount - bdcAgent.amount - salesManager.amount + (state.discountOnFrontEnd ? state.discount : 0);
    const totalBackEnd = (warranty?.price || 0) - (warranty?.cost || 0) + (gap?.price || 0) - (gap?.cost || 0)
                        + (productsOneToSix.reduce((sum, product) => sum + product.price - product.cost, 0) || 0)
                        + state.reserveAmount + financeManager.amount + (state.discountOnFrontEnd ? 0 : state.discount);

    const getVehicleLayout = () => (
        <div className={classes.vehicleSection}>
            <Typography variant="h5">Sold Vehicle</Typography>
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Stock #:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.stockNumber}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">VIN:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.vin}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Year:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.year}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Make:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.make}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Model:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.model}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Trim:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.trim}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Color:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.extColor}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Style:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.style}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Miles:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.miles}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Engine:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{vehicle.engine}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Sticker:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vehicle.stickerPrice)}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Internet:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vehicle.internetPrice)}</Typography>
                    </Grid>
                    <Grid item xs={3} className="content-label">
                        <Typography color="primary" variant="h6">Minimum:</Typography>
                    </Grid>
                    <Grid item xs={9} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vehicle.minimumPrice)}</Typography>
                    </Grid>
                </Grid>
            </Paper>

        </div>
    );

    const getFrontEndLayout = () => (
        <div className={classes.frontEndSection}>
            <Typography variant="h5">Front End</Typography>
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Sale:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(state.salesPrice)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Cost:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vehicle.cost + vehicle.pack)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Trade Equity:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(state.totalTradeACV - state.totalTradeAllowance)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Sales Person 1:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(salesPerson1.amount)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Sales Person 2:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(salesPerson2.amount)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">BDC Agent:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(bdcAgent.amount)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Sales Manager:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(salesManager.amount)}</Typography>
                    </Grid>
                    {state.discountOnFrontEnd && (
                        <>
                            <Grid item xs={7} className="content-label">
                                <Typography color="primary" variant="h6">Discount:</Typography>
                            </Grid>
                            <Grid item xs={5} className="content-value">
                                <Typography>{NumberUtils.applyCurrencyFormat(state.discount)}</Typography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={7} className="content-label" />
                    <Grid item xs={5} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Total:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(totalFrontEnd)}</Typography>
                    </Grid>
                </Grid>
            </Paper>

        </div>
    );

    const getBackEndLayout = () => (
        <div className={classes.backEndSection}>
            <Typography variant="h5">Back End</Typography>
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Warranty:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(warranty?.price - warranty?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Gap:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(gap?.price - gap?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Products:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>
                            {NumberUtils.applyCurrencyFormat(
                                productsOneToSix.reduce((sum, product) => sum + product.price - product.cost, 0),
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Reserve:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(state.reserveAmount)}</Typography>
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Finance Manager:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(financeManager.amount)}</Typography>
                    </Grid>
                    {!state.discountOnFrontEnd && (
                        <>
                            <Grid item xs={7} className="content-label">
                                <Typography color="primary" variant="h6">Discount:</Typography>
                            </Grid>
                            <Grid item xs={5} className="content-value">
                                <Typography>{NumberUtils.applyCurrencyFormat(state.discount)}</Typography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={7} className="content-label" />
                    <Grid item xs={5} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={7} className="content-label">
                        <Typography color="primary" variant="h6">Total:</Typography>
                    </Grid>
                    <Grid item xs={5} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(totalBackEnd)}</Typography>
                    </Grid>
                </Grid>
            </Paper>

        </div>
    );

    const getProductsLayout = () => (
        <div className={classes.productSection}>
            <Typography variant="h5">Products</Typography>
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={4} className="content-label" />
                    <Grid item xs={4} className="content-products-value">
                        <Typography>Sale</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>Cost</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label" />
                    <Grid item xs={4} className={classes.productSectionDivider}>
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={4} className={classes.productSectionDivider}>
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Warranty:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(warranty?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(warranty?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Gap:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(gap?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(gap?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[0]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[0]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[1]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[1]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[2]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[2]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[3]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[3]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[4]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[4]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-label">
                        <Typography color="primary" variant="h6">Product:</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[5]?.price)}</Typography>
                    </Grid>
                    <Grid item xs={4} className="content-products-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(productsOneToSix[5]?.cost)}</Typography>
                    </Grid>
                    <Grid item xs={7} className={clsx('content-label', classes.totalProfit)}>
                        <Typography variant="h4">Total Profit:</Typography>
                    </Grid>
                    <Grid item xs={5} className={clsx('content-value', classes.totalProfit)}>
                        <Typography variant="h4">{NumberUtils.applyCurrencyFormat(totalFrontEnd + totalBackEnd)}</Typography>
                    </Grid>
                </Grid>
            </Paper>

        </div>
    );

    const getCommissionsLayout = () => (
        <Paper elevation={0} className={classes.commissionSection} variant="outlined" square>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="h5">Sales Person 1</Typography>
                    <Select
                        size="sm"
                        name="id"
                        placeholder="Select"
                        value={salesPerson1.id}
                        options={salesPersonList}
                        onChange={(field, value) => onChange('salesPerson1', field, value)}
                    />
                    <div className={clsx(classes.flex, classes.overridePanel)}>
                        <Typography>Override:</Typography>
                        <Form.Check
                            className={classes.overrideCheckBox}
                            checked={salesPerson1.override}
                            onChange={(e) => onChange('salesPerson1', 'override', e.target.checked)}
                        />
                        <InputNumber
                            value={salesPerson1.amount}
                            disabled={!salesPerson1.override}
                            onChange={(value) => onChange('salesPerson1', 'amount', value)}
                            showCurrency
                            size="sm"
                        />
                    </div>
                    <Typography variant="h5">Sales Manager</Typography>
                    <Select
                        size="sm"
                        name="id"
                        placeholder="Select"
                        onChange={(field, value) => onChange('salesManager', field, value)}
                        value={salesManager.id}
                        options={salesManagerList}
                    />
                    <div className={clsx(classes.flex, classes.overridePanel)}>
                        <Typography>Override:</Typography>
                        <Form.Check
                            checked={salesManager.override}
                            className={classes.overrideCheckBox}
                            onChange={(e) => onChange('salesManager', 'override', e.target.checked)}
                        />
                        <InputNumber
                            value={salesManager.amount}
                            disabled={!salesManager.override}
                            onChange={(value) => onChange('salesManager', 'amount', value)}
                            showCurrency
                            size="sm"
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5">Sales Person 2</Typography>
                    <Select
                        size="sm"
                        name="id"
                        placeholder="Select"
                        onChange={(field, value) => onChange('salesPerson2', field, value)}
                        value={salesPerson2.id}
                        options={salesPersonList}
                    />
                    <div className={clsx(classes.flex, classes.overridePanel)}>
                        <Typography>Override:</Typography>
                        <Form.Check
                            className={classes.overrideCheckBox}
                            checked={salesPerson2.override}
                            onChange={(e) => onChange('salesPerson2', 'override', e.target.checked)}
                        />
                        <InputNumber
                            value={salesPerson2.amount}
                            disabled={!salesPerson2.override}
                            onChange={(value) => onChange('salesPerson2', 'amount', value)}
                            showCurrency
                            size="sm"
                        />
                    </div>
                    <Typography variant="h5">BDC Agent</Typography>
                    <Select
                        size="sm"
                        name="id"
                        placeholder="Select"
                        onChange={(field, value) => onChange('bdcAgent', field, value)}
                        value={bdcAgent.id}
                        options={bdcAgentList}
                    />
                    <div className={clsx(classes.flex, classes.overridePanel)}>
                        <Typography>Override:</Typography>
                        <Form.Check
                            className={classes.overrideCheckBox}
                            checked={bdcAgent.override}
                            onChange={(e) => onChange('bdcAgent', 'override', e.target.checked)}
                        />
                        <InputNumber
                            value={bdcAgent.amount}
                            disabled={!bdcAgent.override}
                            onChange={(value) => onChange('bdcAgent', 'amount', value)}
                            showCurrency
                            size="sm"
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5">Finance Manager</Typography>
                    <Select
                        size="sm"
                        name="id"
                        placeholder="Select"
                        onChange={(field, value) => onChange('financeManager', field, value)}
                        value={financeManager.id}
                        options={financeManagerList}
                    />
                    <div className={clsx(classes.flex, classes.overridePanel)}>
                        <Typography>Override:</Typography>
                        <Form.Check
                            className={classes.overrideCheckBox}
                            checked={financeManager.override}
                            onChange={(e) => onChange('financeManager', 'override', e.target.checked)}
                        />
                        <InputNumber
                            value={financeManager.amount}
                            disabled={!financeManager.override}
                            onChange={(value) => onChange('financeManager', 'amount', value)}
                            showCurrency
                            size="sm"
                        />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );

    return (
        <Dialog
            open
            maxWidth="lg"
        >
            <DialogAppBar
                onClose={onClose}
                title={`Editing Commissions for Account #${accountNumber}`}
            />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.flex}>
                    {getVehicleLayout()}
                    {getFrontEndLayout()}
                    {getBackEndLayout()}
                    {getProductsLayout()}
                </div>
                {getCommissionsLayout()}
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                variant="contained"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                disablePrimaryButton={isUpdating}
            />
        </Dialog>
    );
};

DealCommissionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
};

export default DealCommissionDialog;

export default class CardVehicleStyles {
    static CardVehicle(theme = {}) {
        return ({
            box: {
                display: 'flex',
                justifyContent: 'space-between',
            },
            image: {
                width: 96,
                display: 'block',
                height: '100%',
                objectFit: 'cover',
            },
            containerImage: {
                marginRight: theme.spacing(1),
                position: 'relative',
                overflow: 'hidden',
                height: 60,
            },
            root: {
                display: 'flex',
                alignItems: 'center',
                '& .picture-hover': {
                    minHeight: 0,
                    position: 'absolute',
                    height: 0,
                    transitionDuration: '300ms',
                    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    overflow: 'hidden',
                },
                '&:hover .picture-hover': {
                    height: 'calc(100% - 0px)',
                    overflow: 'visible',
                },
                '& .picture-hover button:last-child': {
                    color: theme.palette.error.main,
                },
            },
            containerDetails: {
                flex: 1,
            },
            textBold: {
                color: theme.palette.text.purpure,
                fontWeight: 'bold',
            },
            stock: {
                marginLeft: theme.spacing(0.5),
            },
            favorite: {
                cursor: 'pointer',
                color: theme.palette.rating,
            },
            notFavorite: {
                cursor: 'pointer',
            },
        });
    }
}

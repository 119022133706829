import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import update from 'immutability-helper';

import { useQuery, useMutation } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import KeyFeatureIcon from 'components/modules/inventory/create/dialogs/keyFeature/KeyFeatureIcon';

import {
    makeStyles,
} from '@material-ui/core';

import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles({
    boxFeature: {
        height: '100%',
        border: '1px solid #bfbfbf',
        width: '100%',
        marginTop: '8px',
        overflow: 'auto',
    },
});
const initState = {
    icons: [],
    showConfirmDialog: false,
    iconToDelete: null,
};
const ACTION_TYPES = {
    SET_ICONS: 'setIcons',
    TOGGLE_CONFIRM_DIALOG: 'toggleConfirmDialog',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_ICONS:
        return update(state, {
            icons: { $set: action.payload },
        });
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG:
        return update(state, {
            showConfirmDialog: { $set: !state.showConfirmDialog },
            iconToDelete: { $set: action?.payload },
        });
    default:
        return initState;
    }
};

const KeyFeatureIconList = ({
    editMode,
    feature,
    setSelectedIcon,
    newIcons,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const { data: response, error, loading } = useQuery(InventoryQuery.ICON_LIST, { fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const [deleteIcon, { loading: loadingDeleteIcon }] = useMutation(InventoryMutation.DELETE_ICON);

    useEffect(() => {
        if (!loading) {
            const {
                getIconList: { general, custom },
            } = response;
            const generalIcons = general.map((item) => ({
                selected: false,
                url: item.url,
                key: item.key,
            }));
            const customIcons = custom.map((item) => ({
                selected: false,
                url: item.url,
                key: item.key,
            }));
            const icons = Array.from(
                new Set(generalIcons.concat(customIcons)),
            );

            if (editMode) {
                const index = icons.findIndex((item) => feature.icon?.endsWith(item.key));
                if (index !== -1) {
                    icons[index].selected = true;
                }
            }

            dispatch({
                type: ACTION_TYPES.SET_ICONS,
                payload: icons,
            });
        }
    }, [response, loading, editMode, feature]);

    useEffect(() => {
        if (newIcons) {
            dispatch({
                type: ACTION_TYPES.SET_ICONS,
                payload: update(state.icons, { $push: newIcons }),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newIcons]);

    if (error) {
        ModalUtils.errorMessage(null, error?.graphQLErrors);
    }

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const onSelectIcon = (indexSelected) => {
        let icons = [...state.icons];

        icons = icons.map((item, index) => {
            const newItem = update(item, {
                selected: { $set: index === indexSelected },
            });

            return newItem;
        });

        dispatch({
            type: ACTION_TYPES.SET_ICONS,
            payload: icons,
        });
        setSelectedIcon(icons[indexSelected]?.url);
    };
    const toggleConfirmDialog = (iconToDelete = null) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG,
            payload: iconToDelete,
        });
    };
    const onDeleteIcon = async () => {
        if (INVENTORY_VEHICLE_WRITE) {
            if (state.iconToDelete) {
                try {
                    const { key, index } = state.iconToDelete;
                    const deleteIconResponse = await deleteIcon({ variables: { key } });

                    if (deleteIconResponse?.data?.deleteIcon) {
                        const newIconList = update(state.icons, {
                            $splice: [[index, 1]],
                        });

                        dispatch({
                            type: ACTION_TYPES.SET_ICONS,
                            payload: newIconList,
                        });
                        setSelectedIcon();
                        toggleConfirmDialog();
                    } else {
                        ModalUtils.errorMessage(null, MessageUtils.getGenericError('delete', 'Icon'));
                    }
                } catch (e) {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('delete', 'Icon'));
                }
            } else {
                ModalUtils.errorMessage(null, 'There is no icon selected to delete.');
            }
        }
    };

    return (
        <>
            <div className={classes.boxFeature}>
                {
                    map(state.icons, (item, index) => (
                        <KeyFeatureIcon
                            key={index}
                            icon={item}
                            index={index}
                            onSelectIcon={onSelectIcon}
                            toggleConfirmDialog={toggleConfirmDialog}
                        />
                    ))
                }
            </div>
            {state.showConfirmDialog && (
                <ConfirmDialog
                    title="Confirm remove Icon"
                    description="This icon will be removed from all Key features"
                    open={state.showConfirmDialog}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={toggleConfirmDialog}
                    onClickSecondary={toggleConfirmDialog}
                    onClickPrimary={onDeleteIcon}
                    disablePrimaryButton={loading || loadingDeleteIcon}
                />
            )}
        </>
    );
};

KeyFeatureIconList.propTypes = {
    setSelectedIcon: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    feature: PropTypes.object,
    newIcons: PropTypes.array,
};

KeyFeatureIconList.defaultProps = {
    feature: null,
    newIcons: null,
};

export default KeyFeatureIconList;

export default class AIStyle {
    static content(theme) {
        return ({
            box: {
                height: '100%',
                marginTop: '10px',
                padding: '10px',
                '& > div > button': {
                    marginRight: '10px',
                },
            },
            splitter: {
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                '& > div.gutter': {
                    marginTop: '33px',
                },
            },
            container: {
                width: '100%',
                height: '99%',
                paddingLeft: theme.spacing(0.4),
                paddingRight: theme.spacing(0.4),
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '50px',
                    height: '500px',
                },
            },
            label: {
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '13px',
                paddingLeft: '5px',
                marginBottom: theme.spacing(0.65),
                color: theme.palette.text.darkShark,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > button': {
                    minWidth: '32px',
                },
                '& > button span.MuiButton-startIcon': {
                    marginRight: 0,
                },
            },
            refreshButton: {
                marginTop: '2px',
                '& span.MuiButton-startIcon': {
                    backgroundColor: theme.palette.background.apple,
                    borderRadius: '50px',
                    '& > svg': {
                        fill: theme.palette.background.white,
                        fontSize: '22px',
                    },
                },
            },
            boxSearch: {
                marginBottom: theme.spacing(0.25),
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
                '& > div:nth-child(1)': {
                    width: '100%',
                    border: `1px solid ${theme.palette.border.ghost}`,
                },
            },
            list: {
                height: '90.5%',
                overflowY: 'auto',
                overflowX: 'hidden',
                border: `1px solid ${theme.palette.border.ghost}`,
                lineHeight: 2,
            },
            conversation: {
                marginTop: '-3px',
                height: '95.5%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden !important',
                '& > div:nth-child(1)': {
                    flexGrow: 1,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: '10px',
                },
                '& > div:nth-child(2)': {
                    height: '56px',
                    flexShrink: 0,
                },
            },
            globalFiles: {
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '10px',
                '& > button': {
                    borderRadius: 0,
                },
            },
            listItem: {
                userSelect: 'none',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                paddingLeft: '5px',
                fontSize: '12px',
                '&:hover': {
                    backgroundColor: theme.palette.background.silver,
                    color: theme.palette.text.darkShark,
                },
            },
            listItemSelected: {
                userSelect: 'none',
                whiteSpace: 'nowrap',
                position: 'relative',
                cursor: 'pointer',
                paddingLeft: '5px',
                fontSize: '12px',
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
                '& > span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    width: '85%',
                    height: '19px',
                },
                '& > div': {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    '& > span > button': {
                        minWidth: '25px',
                        backgroundColor: 'transparent',
                        color: theme.palette.text.white,
                        '& span.MuiButton-startIcon': {
                            margin: 0,
                        },
                    },
                },
            },
            main: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            assistantMessageBox: {
                flexDirection: 'row-reverse',
            },
            userMessage: {
                backgroundColor: theme.palette.background.gray,
                borderRadius: theme.spacing(1, 1, 1, 0),
                padding: '10px',
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(1),
                maxWidth: '65%',
                marginTop: '10px',
            },
            assistantMessage: {
                backgroundColor: theme.palette.background.pastelGreen,
                borderRadius: theme.spacing(1, 1, 0, 1),
                padding: '10px',
                marginLeft: 0,
                marginRight: theme.spacing(1),
                maxWidth: '65%',
                marginTop: '10px',
            },
            text: {
                color: theme.palette.text.tundora,
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                position: 'relative',
            },
        });
    }
}

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { BorderColorOutlined as EditIcon } from '@material-ui/icons';
import InsuranceTabContainer from 'components/containers/deals/read/buyer/InsuranceTabContainer';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import LabelValue from 'components/widgets/LabelValue';
import MultipleLineValues from 'components/widgets/customer/MultipleLineValues';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import InsuranceDialog from 'components/modules/deals/read/buyer/InsuranceDialog';

const styles = () => ({
    paper: {
        width: '100%',
    },
});

class InsuranceTab extends Component {
    componentDidMount() {
        const { props } = this;
        props.getServicesData();
    }

    renderActions() {
        const { props: { toggleModal, loading } } = this;
        return (
            <Button
                color="primary"
                size="small"
                onClick={toggleModal}
                disabled={loading}
                startIcon={<EditIcon />}
            >
                Edit
            </Button>
        );
    }

    renderInsurancePanel() {
        const { props: { classes, insurance } } = this;
        const {
            insuranceCompany,
            insuranceAgent,
            insurancePhone,
            insuranceDateFrom,
            insuranceDate,
            insuranceAddress,
            insuranceZip,
            insuranceCity,
            insuranceState,
            insuranceComp,
            insuranceColl,
            insurancePolicy,
        } = insurance;

        const address = [
            insuranceAddress,
            insuranceZip,
            `${insuranceCity}, ${insuranceState}`,
        ];

        const from = DateUtils.isValid(insuranceDateFrom) ? DateUtils.format(insuranceDateFrom) : '';
        const to = DateUtils.isValid(insuranceDate) ? DateUtils.format(insuranceDate) : '';
        const comp = NumberUtils.applyCurrencyFormat(insuranceComp, '$0,0');
        const coll = NumberUtils.applyCurrencyFormat(insuranceColl, '$0,0');
        const effective = [
            `From: ${from}`,
            `To: ${to}`,
        ];
        const deductible = [
            `Comp: ${comp}`,
            `Collision: ${coll}`,
        ];

        return (
            <div className={classes.rootPaper}>
                <BoxWidget
                    className={classes.paper}
                    typeBox="general"
                    rightHeaderSection={this.renderActions()}
                >
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={6}
                        >
                            <LabelValue
                                label="Company"
                                value={insuranceCompany}
                            />
                            <MultipleLineValues
                                label="Address"
                                values={address}
                            />
                            <MultipleLineValues
                                label="Effective"
                                values={effective}
                            />
                        </Grid>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="Agent"
                                value={insuranceAgent}
                            />
                            <LabelValue
                                label="Phone"
                                value={insurancePhone}
                            />
                            <LabelValue
                                label="Policy"
                                value={insurancePolicy}
                            />
                            <MultipleLineValues
                                label="Deductible"
                                values={deductible}
                            />
                        </Grid>
                    </Grid>
                </BoxWidget>
            </div>
        );
    }

    render() {
        const {
            props: {
                classes,
                openDialog,
                accountNumber,
                insurance,
                toggleModal,
                getServicesData,
            },
        } = this;

        return (
            <div className={classes.container}>
                { this.renderInsurancePanel() }
                { openDialog
                && <InsuranceDialog open={openDialog} toggleModal={toggleModal} reload={getServicesData} dealId={accountNumber} record={insurance} />}
            </div>
        );
    }
}

InsuranceTab.propTypes = {
    insurance: PropTypes.object.isRequired,
    accountNumber: PropTypes.number.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    getServicesData: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    openDialog: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(InsuranceTabContainer(InsuranceTab)));

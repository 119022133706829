import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import update from 'immutability-helper';
import {
    makeStyles, Button, Typography,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import Label from 'components/widgets/Label';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import ButtonStyles from 'styles/theme/Button';
import Permissions from 'utils/enum/Permissions';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import InputSearch from 'components/widgets/InputSearch';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import TemplateForm from 'components/modules/settings/crm/TemplateForm';
import EmailTemplateQuery from 'services/graphQL/query/setting/EmailTemplateQuery';
import { Communication } from 'utils/enum/SubModule';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& > button': {
            marginRight: theme.spacing(1),
            width: '95px',
        },
    },
    tableHeight: {
        display: 'contents',
    },
    search: {
        marginRight: theme.spacing(2),
        width: 300,
    },
    ...ButtonStyles.getStyle(theme),
}));
const keyStore = new KeyStore();
const ACTION_TYPE = {
    SET_RECORDS: 'setRecords',
    ON_OPEN_DIALOG: 'onOpenDialog',
    ON_CLOSE_DIALOG: 'onCloseDialog',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_SET_SELECT_RECORD: 'onSetSelectRecord',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_RECORDS:
        return update(state, {
            records: { $set: action.payload?.data },
            totalCount: { $set: action.payload?.totalCount },
        });
    case ACTION_TYPE.ON_SET_SELECT_RECORD:
        return update(state, {
            recordSelected: { $set: action.payload.record },
            open: { $set: action.payload.open },
            isEditing: { $set: action.payload.isEditing },
        });
    case ACTION_TYPE.ON_CLOSE_DIALOG:
        return update(state, {
            open: { $set: false },
            recordSelected: { $set: null },
            isEditing: { $set: false },
        });
    case ACTION_TYPE.ON_OPEN_DIALOG:
        return update(state, {
            open: { $set: true },
            isEditing: { $set: action?.payload?.isEditing || false },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.field]: { $set: action.payload },
            recordSelected: { $set: null },
            records: { $set: [] },
            totalCount: { $set: 0 },
        });
    default:
        return state;
    }
};

const Templates = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        records: [],
        totalCount: 0,
        open: false,
        active: true,
        recordSelected: null,
        searchTerm: '',
    });
    const CRM_SETTINGS_WRITE = keyStore.hasPermission(Permissions.CRM_SETTINGS_WRITE);
    // TODO: Change this in the next update
    const {
        data, error, loading, refetch,
    } = useQuery(EmailTemplateQuery.GET_CRM_TEMPLATE, {
        variables: { paging: { start: 0, limit: 100 }, active: state.active, searchTerm: state.searchTerm },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loading && data?.getCRMTemplate) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data.getCRMTemplate,
            });
        }
    }, [data, loading, refetch]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    const onDoubleClick = (recordSelected) => {
        dispatch({
            type: ACTION_TYPE.ON_SET_SELECT_RECORD,
            payload: {
                record: recordSelected,
                open: true,
                isEditing: true,
            },
        });
    };

    const onChangeValue = (field, payload) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            field,
            payload,
        });
    };

    const onSearch = (value) => {
        if (state.searchTerm !== value) onChangeValue('searchTerm', value);
    };

    const onSelectRecord = (recordSelected) => {
        dispatch({
            type: ACTION_TYPE.ON_SET_SELECT_RECORD,
            payload: {
                record: recordSelected,
            },
        });
    };

    const onClose = () => {
        dispatch({
            type: ACTION_TYPE.ON_CLOSE_DIALOG,
        });
    };

    const onEdit = () => {
        dispatch({
            type: ACTION_TYPE.ON_OPEN_DIALOG,
            payload: {
                isEditing: true,
            },
        });
    };

    const onCreate = () => {
        dispatch({
            type: ACTION_TYPE.ON_OPEN_DIALOG,
        });
    };

    const columns = [
        {
            Header: 'Template Name',
            accessor: 'templateName',
            maxWidth: 200,
        },
        {
            Header: 'Template Subject',
            accessor: 'templateSubject',
            maxWidth: 200,
        },
        {
            Header: 'Template Text',
            accessor: 'templateText',
        },
        {
            Header: 'Type',
            accessor: 'templateType',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => {
                const color = record.value?.toLowerCase() === Communication.SMS ? 'success' : 'error';
                return <Label color={color}>{record.value}</Label>;
            },
            maxWidth: 130,
        },
        {
            Header: 'Language',
            accessor: 'language',
            maxWidth: 100,
        },
        {
            Header: 'Status',
            accessor: 'active',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => {
                const result = record.value ? { color: 'secondary', text: 'Active' } : { color: 'error', text: 'Inactive' };
                return <Label color={result.color}>{result.text}</Label>;
            },
            maxWidth: 130,
        },
        {
            Header: 'Date',
            accessor: 'modifiedOn',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => DateUtils.format(record.value, DateFormat.DEFAULT_DATETIME),
            maxWidth: 200,
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {CRM_SETTINGS_WRITE && (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={onCreate}
                            className={clsx(classes.containedGreen)}
                            startIcon={<AddIcon />}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={onEdit}
                            className={clsx(classes.containedInfo)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={isEmpty(state.recordSelected)}
                        >
                            Edit
                        </Button>
                    </>
                )}
                <InputSearch
                    size="sm"
                    forceSearch
                    customClasses={classes.search}
                    executeWhenClearButton={() => onChangeValue('searchTerm', '')}
                    onSearch={(value) => onSearch(value)}
                />
                <div className="d-flex-center">
                    <Form.Group
                        className="form-check form-check-inline mb-0"
                    >
                        <Form.Check
                            key="radioActive"
                            type="radio"
                            name="radio"
                            id="radioActive"
                            label="Active"
                            value={state.active}
                            checked={state.active}
                            onChange={() => onChangeValue('active', true)}
                        />
                        <Form.Check
                            key="radioInactive"
                            type="radio"
                            name="radio"
                            id="radioInactive"
                            label="In-Active"
                            value={!state.active}
                            checked={!state.active}
                            onChange={() => onChangeValue('active', false)}
                        />
                    </Form.Group>
                </div>
                <Typography
                    variant="body1"
                >
                    {`Items: ${state.totalCount}`}
                </Typography>
            </div>
            <div className={classes.tableHeight}>
                <Table
                    load={loading}
                    columns={columns}
                    data={state.records}
                    totalRecords={state.totalCount}
                    unselectRow={state.recordSelected === null}
                    getTrProps={(_, rowInfo) => {
                        const record = rowInfo.original;
                        const selected = record.messageTemplateId === state.recordSelected?.messageTemplateId;

                        return {
                            onClick: () => {
                                onSelectRecord(record);
                            },
                            onDoubleClick: () => {
                                if (CRM_SETTINGS_WRITE) onDoubleClick(record);
                            },
                            className: selected ? 'active' : '',
                        };
                    }}
                />
            </div>
            {state.open && (
                <TemplateForm
                    open={state.open}
                    onClose={onClose}
                    refetch={refetch}
                    record={state.recordSelected}
                    isEditing={state.isEditing}
                />
            )}
        </div>
    );
};

export default Templates;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { Col, Form, Row } from 'react-bootstrap';
import {
    FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';

const ReportPrint = (props) => {
    const [option, setOption] = useState('currentPage');
    const { onChooseOption, onClose } = props;
    return (
        <ConfirmDialog
            title="What do you want to print?"
            open
            variant="outlined"
            titlePrimary="Print"
            titleSecondary="Cancel"
            onClose={onClose}
            onClickSecondary={onClose}
            onClickPrimary={() => onChooseOption(option)}
        >
            <Form.Group as={Row}>
                <Col xs={12}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row={false}
                            value={option}
                            onChange={(e, val) => setOption(val)}
                        >
                            <FormControlLabel
                                value="currentPage"
                                control={<Radio color="primary" />}
                                label="Print current page"
                            />
                            <FormControlLabel
                                value="all"
                                control={<Radio color="primary" />}
                                label="Print all pages"
                            />
                        </RadioGroup>
                    </FormControl>
                </Col>
            </Form.Group>
        </ConfirmDialog>
    );
};

ReportPrint.propTypes = {
    onChooseOption: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

ReportPrint.defaultProps = {
    onClose: () => null,
};

export default ReportPrint;

import { useContext, useEffect, useReducer } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import ModalUtils from 'utils/ModalUtils';
import { v1 as uuid } from 'uuid';
import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import UserContext from 'components/context/UserContext';
import AccountingSettingsQuery from 'services/graphQL/query/accounting/AccountingSettings';

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_LOADING: 'setLoading',
    SET_EXPAND_COLLAPSE_ALL: 'setExpandCollapseAll',
    SET_EXPAND_COLLAPSE_ITEM: 'setExpandCollapseItem',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            loading: false,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
            loading: true,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
            loading: true,
        };
    }
    case ACTION_TYPES.SET_LOADING: {
        return {
            ...state,
            loading: action.value,
        };
    }
    case ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL: {
        const currentRecords = state.table.records.map((item) => ({
            ...item,
            open: action.payload,
        }));

        return {
            ...state,
            globalOpen: action.payload,
            table: {
                ...state.table,
                records: currentRecords,
            },
        };
    }
    case ACTION_TYPES.SET_EXPAND_COLLAPSE_ITEM: {
        const { invoiceNumber, open } = action.payload;
        const currentRecords = state.table.records.map((item) => {
            if (item.invoiceNumber === invoiceNumber) {
                const cloneItem = { ...item };
                cloneItem.open = open;
                cloneItem.identifier = uuid();
                return cloneItem;
            }

            return item;
        });

        return {
            ...state,
            table: {
                ...state.table,
                records: currentRecords,
            },
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.payload,
        };
    }
    default: return action.value;
    }
};

const useServiceList = () => {
    const {
        availableLots = [],
        defaultLot = '',
    } = useContext(UserContext);

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 15,
            },
            status: [{
                value: ServiceInvoiceStatus.IN_PROGRESS,
                label: ServiceInvoiceStatus.IN_PROGRESS,
            }],
            search: '',
            lotName: [{
                label: defaultLot,
                value: defaultLot,
            }],
            advisor: [],
            ignoreFilterWhenSearch: false,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        loading: true,
        invoiceNumberHistory: 0,
        invoiceNumberNote: 0,
        globalOpen: false,
        postToAccountingAutomatic: false,
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params,
        params: {
            paginate,
            search,
            status,
            lotName,
            advisor,
            ignoreFilterWhenSearch,
        },
        table: {
            records,
        },
        loading,
        globalOpen,
        postToAccountingAutomatic,
    } = state;

    const {
        data, loading: loadingQuery, error,
    } = useQuery(ServiceQuery.GET_SERVICE_INVOICE_LIST, {
        variables: {
            paginate,
            filter: {
                search,
                status: status.map((item) => item.value),
                lotName: lotName.map((item) => item.value),
                advisor: advisor.map((item) => item.value),
                ignoreFilterWhenSearch: Boolean(ignoreFilterWhenSearch),
            },
            sort: [
                { fieldName: 'invoiceNumber', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getAccountingSettingsKeys] = useLazyQuery(AccountingSettingsQuery.GET_ACCOUNTING_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getAccountingDefaultSettings) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    payload: {
                        postToAccountingAutomatic: res.getAccountingDefaultSettings.postROsToAccounting,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = records?.length || 0;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const setDataFromSuscription = (values) => {
        dispatch({
            type: ACTION_TYPES.SET_TABLE,
            value: {
                records: values.records,
                totalCount: values.totalCount,
            },
        });
    };

    const expandAndCollapseAllJobs = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL,
            payload: val,
        });
    };

    const expandOrCollapse = ({ invoiceNumber, open }) => {
        dispatch({
            type: ACTION_TYPES.SET_EXPAND_COLLAPSE_ITEM,
            payload: { invoiceNumber, open },
        });
    };

    const onHistory = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                invoiceNumberHistory: val,
            },
        });
    };

    const onNotes = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                invoiceNumberNote: val,
            },
        });
    };

    useEffect(() => {
        if (error) {
            dispatch({
                type: ACTION_TYPES.SET_LOADING,
                value: false,
            });
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loadingQuery) {
            const { getROList: { data: roRecords, totalCount } } = data;

            if (roRecords?.length > 0) {
                roRecords.forEach((record) => {
                    records.push({ ...record, identifier: uuid(), open: globalOpen });
                });

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount,
                    },
                });
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_LOADING,
                    value: false,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingQuery, error]);

    useEffect(() => {
        getAccountingSettingsKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        state,
        loading,
        loadMore,
        onSearch,
        onFilterChange,
        setDataFromSuscription,
        expandAndCollapseAllJobs,
        expandOrCollapse,
        availableLots,
        onHistory,
        onNotes,
        postToAccountingAutomatic,
    };
};

export default useServiceList;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import { withRouter } from 'react-router-dom';
import PortfolioSettingsListContainer from 'components/containers/settings/portfolio/PortfolioSettingsListContainer';
import PortfolioSettingsDialog from 'components/modules/settings/bphp/portfolio/PortfolioSettingsDialog';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import InputSearch from 'components/widgets/InputSearch';
import { Checkbox } from '@material-ui/core';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import PortfolioSettingsStyles from 'styles/modules/settings/PortfolioSettingsStyles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/CheckBox';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => PortfolioSettingsStyles.listStyles(theme);

class PortfolioSettingsList extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SETTINGS_BHPH_PORTFOLIO_WRITE = keyStore.hasPermission(Permission.SETTINGS_BHPH_PORTFOLIO_WRITE);
    }

    getColumns() {
        const { props: { classes } } = this;

        return [
            {
                Header: 'Portfolio Code',
                accessor: 'portfolioId',
                className: classes.columnStyle,
            },
            {
                Header: 'Name',
                accessor: 'name',
                className: classes.columnStyle,
            },
            {
                Header: 'Type',
                accessor: 'type',
                className: classes.columnStyle,
            },
            {
                Header: 'Serviced In House',
                accessor: 'inHouse',
                className: classes.columnStyle,
                Cell: (record) => (record.value ? 'Yes' : 'No'),
            },
            {
                Header: 'Active',
                accessor: 'activelyUsed',
                className: classes.columnStyle,
                Cell: (record) => (record.value ? 'Yes' : 'No'),
            },
            {
                Header: 'Default',
                accessor: 'isDefault',
                style: { textAlign: 'center' },
                Cell: (props) => {
                    const isDefault = props.value;
                    return isDefault ? (
                        <div>
                            <Checkbox
                                checked={isDefault}
                                name="completed"
                                color="primary"
                            />
                        </div>
                    ) : null;
                },
            },
        ];
    }

    render() {
        const {
            props: {
                classes, openDialog, openPortfolioDialog, closePortfolioDialog,
                isEditingPortfolio, onSave, selectedPortfolioId, onSearch,
                tableContent, setSelectedPortfolioId, openConfirmDialog, openConfirmationDialog,
                searchTerm, onDefault, deletePortfolio, closeConfirmDialog,
            },
        } = this;
        const disableEditAndRemove = selectedPortfolioId === null;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {this.SETTINGS_BHPH_PORTFOLIO_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.newButton)}
                            startIcon={<AddIcon />}
                            onClick={() => openPortfolioDialog(false)}
                        >
                            New
                        </Button>
                    )}
                    {this.SETTINGS_BHPH_PORTFOLIO_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.editButton)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={disableEditAndRemove}
                            onClick={() => openPortfolioDialog(true)}
                        >
                            Edit
                        </Button>
                    )}
                    {this.SETTINGS_BHPH_PORTFOLIO_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.removeButton)}
                            startIcon={<DeleteIcon />}
                            disabled={disableEditAndRemove}
                            onClick={openConfirmDialog}
                        >
                            Delete
                        </Button>
                    )}
                    {this.SETTINGS_BHPH_PORTFOLIO_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.setDefaultButton)}
                            startIcon={<CheckIcon />}
                            disabled={disableEditAndRemove}
                            onClick={onDefault}
                        >
                            Set Default
                        </Button>
                    )}
                    <InputSearch
                        size="sm"
                        forceSearch
                        customClasses={classes.search}
                        onSearch={onSearch}
                        initialSearch={searchTerm}
                    />
                </div>
                <div className={classes.tableHeight}>
                    <Table
                        rowSelected
                        load={tableContent.loading}
                        totalRecords={tableContent.records.length}
                        sortable={tableContent.sortable}
                        data={tableContent.records}
                        columns={this.getColumns()}
                        unselectRow={disableEditAndRemove}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onClick: () => {
                                    setSelectedPortfolioId(record.portfolioId);
                                },
                            };
                        }}
                    />
                </div>
                {openDialog
                    && (
                        <PortfolioSettingsDialog
                            open={openDialog}
                            isEditing={isEditingPortfolio}
                            onClose={closePortfolioDialog}
                            portfolioId={selectedPortfolioId}
                            onSave={onSave}
                        />
                    )}
                {openConfirmationDialog
                    && (
                        <ConfirmDialog
                            title="Confirm delete portfolio"
                            description="Are you sure you want to remove this portfolio?"
                            open={openConfirmationDialog}
                            showHeader
                            titlePrimary="Yes"
                            titleSecondary="No"
                            variant="outlined"
                            dividerFooter={false}
                            onClickPrimary={() => {
                                deletePortfolio();
                                closeConfirmDialog();
                            }}
                            onClose={closeConfirmDialog}
                            onClickSecondary={closeConfirmDialog}
                        />
                    )}
            </div>
        );
    }
}

PortfolioSettingsList.propTypes = {
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    openPortfolioDialog: PropTypes.func.isRequired,
    closePortfolioDialog: PropTypes.func.isRequired,
    setSelectedPortfolioId: PropTypes.func.isRequired,
    isEditingPortfolio: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedPortfolioId: PropTypes.number,
    onSearch: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    tableContent: PropTypes.object.isRequired,
    onDefault: PropTypes.func.isRequired,
    openConfirmDialog: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.bool.isRequired,
    deletePortfolio: PropTypes.func.isRequired,
    closeConfirmDialog: PropTypes.func.isRequired,
};

PortfolioSettingsList.defaultProps = {
    selectedPortfolioId: null,
};

export default withRouter(withStyles(styles)(PortfolioSettingsListContainer(PortfolioSettingsList)));

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
} from '@material-ui/core';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import ButtonStyles from 'styles/theme/Button';
import WindowStickersQuery from 'services/graphQL/query/WindowStickersQuery';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

const useStyles = makeStyles((theme) => InventoryListStyle.formsDialog(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const FormsDialog = ({
    onClose,
    onSave,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [inventoryForms, setInventoryForms] = useState([]);
    const [availableForms, setAvailableForms] = useState([]);
    const [selectedInventoryForm, setSelectedInventoryForm] = useState('');
    const [selectedAvailableForm, setSelectedAvailableForm] = useState('');

    const {
        data: formsData,
        loading: formsLoading,
        error: formsError,
    } = useQuery(WindowStickersQuery.GET_FORMS_BY_DEALER, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: availableFormsData,
        loading: availableFormsLoading,
        error: availableFormsError,
    } = useQuery(WindowStickersQuery.GET_AVAILABLE_FORMS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (formsError) {
            ModalUtils.errorMessage(formsError?.graphQLErrors);
            return;
        }

        if (!formsLoading) {
            const { getFormsByDealer } = formsData;
            setInventoryForms(getFormsByDealer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formsLoading, formsError]);

    useEffect(() => {
        if (availableFormsError) {
            ModalUtils.errorMessage(availableFormsError?.graphQLErrors);
            return;
        }

        if (!availableFormsLoading) {
            const { getAvailableForms } = availableFormsData;
            setAvailableForms(getAvailableForms);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFormsLoading, availableFormsError]);

    const onSelectInventoryForm = (name) => {
        setSelectedInventoryForm(name);
        setSelectedAvailableForm('');
    };

    const onSelectAvailableForm = (name) => {
        setSelectedInventoryForm('');
        setSelectedAvailableForm(name);
    };

    const onAddForm = () => {
        if (StringUtils.isEmpty(selectedAvailableForm)) return;

        const selected = availableForms
            .find((form) => form.name === selectedAvailableForm);
        if (selected) {
            setInventoryForms([...inventoryForms, selected]);
            setAvailableForms(
                availableForms.filter((form) => form.name !== selectedAvailableForm),
            );

            setSelectedAvailableForm('');
        }
    };

    const onRemoveForm = () => {
        if (StringUtils.isEmpty(selectedInventoryForm)) return;

        const selected = inventoryForms
            .find((form) => form.name === selectedInventoryForm);
        if (selected) {
            setAvailableForms([...availableForms, selected]);
            setInventoryForms(
                inventoryForms.filter((form) => form.name !== selectedInventoryForm),
            );

            setSelectedInventoryForm('');
        }
    };

    const sortedInventoryForms = [...inventoryForms];
    sortedInventoryForms.sort((a, b) => a.name.localeCompare(b.name));
    const sortedAvailableForms = [...availableForms];
    sortedAvailableForms.sort((a, b) => a.name.localeCompare(b.name));
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Choose available Forms"
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.formsDialogContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={5} className={classes.innerSections}>
                            <div className={classes.label}>
                                Inventory Forms
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <List>
                                    {sortedInventoryForms
                                        .map((form) => (
                                            <ListItem
                                                key={form.name}
                                                button
                                                selected={
                                                    form.name === selectedInventoryForm
                                                }
                                                onClick={() => onSelectInventoryForm(form.name)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        form.commonName
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid
                                className={clsx(classes.innerSections, classes.middleSection)}
                                container
                            >
                                <IconButton
                                    aria-label="add-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(selectedInventoryForm)}
                                    onClick={onAddForm}
                                >
                                    <ArrowBackIosIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    aria-label="remove-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(selectedAvailableForm)}
                                    onClick={onRemoveForm}
                                >
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} className={classes.innerSections}>
                            <div className={classes.label}>
                                Forms Repository
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <List>
                                    {sortedAvailableForms
                                        .map((form) => (
                                            <ListItem
                                                key={form.name}
                                                button
                                                selected={
                                                    form.name === selectedAvailableForm
                                                }
                                                onClick={() => onSelectAvailableForm(form.name)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        form.commonName
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.containedSecondaryInfo}
                        disabled={formsLoading || availableFormsLoading}
                        onClick={() => onSave(inventoryForms)}
                    >
                        Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

FormsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default FormsDialog;

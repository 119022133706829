/* eslint-disable import/prefer-default-export */
const MailStatus = Object.freeze({
    RECEIVED: 'Received',
    READ: 'Read',
    FAILED: 'Failed',
    SENDING: 'Sending',
    DELIVERED: 'Delivered',
});

export { MailStatus };

import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import {
    Grid,
    Dialog,
    Button,
    makeStyles,
    Typography,
    DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import palette from 'styles/theme/palette';
import { FetchPolicy } from 'utils/enum/Core';
import Dropdown from 'components/widgets/dropdown';
import { TransferActions } from 'utils/enum/DealEnum';
import { useQuery, useMutation } from '@apollo/client';
import { Accordion } from 'components/widgets/Accordion';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import VehicleWidget from 'components/widgets/VehicleWidget';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import ExpandRow from 'components/modules/lead/list/ExpandRow';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import AvatarWithLabel from 'components/widgets/AvatarWithLabel';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import TransferLot from 'components/widgets/transfer/TransferLot';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CheckboxControl from 'components/widgets/editorControls/CheckboxControl';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
    backgroundRow: {
        background: palette.background.paper,
    },
    dialogContent: {
        padding: '15px 30px',
    },
    itemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '0',
        paddingLeft: '2px',
        cursor: 'default',
    },
    textLabelAlign: {
        textAlign: 'center',
    },
    tag: {
        padding: '2px 5px',
        borderRadius: '2px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },
    lotTag: {
        color: '#1e88e5',
        background: '#F4F5FD',
    },
    dealType: {
        color: '#388e3c',
        background: '#e8f5e9',
    },
    containerAvatarWithName: {
        paddingTop: theme.spacing(1),
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    columnExtended: {
        'justify-content': 'space-between',
        height: '100%',
        'margin-bottom': '5px',
    },
    textSoldDate: {
        color: theme.palette.text.boulderGray,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '13px',
    },
    boldColor: {
        color: theme.palette.text.minsk,
    },
    textBoulder: {
        color: theme.palette.text.boulder,
    },
    salesmanColumn: {
        flexDirection: 'column',
        maxWidth: 180,
        minWidth: 180,
    },
    actionsColumn: {
        maxWidth: '260px !important',
        minWidth: '260px !important',
    },
    moreActionsColumn: {
        position: 'relative',
    },
    vehicleWidget: {
        textDecoration: 'initial',
        cursor: 'pointer',
    },
    expansionCol: {
        color: '#382652',
        display: 'flex',
    },
    colProspect: {
        flexDirection: 'column',
        maxWidth: 185,
        minWidth: 185,
    },
    box: {
        overflow: 'hidden',
    },
    listMarginBottom: {
        marginBottom: '35px !important',
    },
    checkbox: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: '5px',
        cursor: 'pointer',
    },
}));

const initialState = {
    records: [],
    actionSelected: null,
    openLotDialog: false,
    recordSelected: null,
    openConfirmDialog: false,
    selectedRecords: [],
    allRecordsSelected: false,
    acceptMultiple: null,
};

const ACTION_TYPES = {
    ACCEPT: 'accept',
    REJECT: 'reject',
    SET_RECORDS: 'setRecords',
    SELECT_RECORD: 'selectRecord',
    TOGGLE_LOT_DIALOG: 'tootleLotDialog',
    SELECT_ALL_RECORDS: 'selectAllRecords',
    ACCEPT_REJECT_MULTIPLE: 'acceptReject',
    TOGGLE_CONFIRM_DIALOG: 'toogleConfirmDialog',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS:
        return {
            ...state,
            selectedRecords: [],
            allRecordsSelected: false,
            acceptMultiple: null,
            records: action.payload,
            recordSelected: null,
        };
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG:
        return {
            ...state,
            openConfirmDialog: !state.openConfirmDialog,
        };
    case ACTION_TYPES.TOGGLE_LOT_DIALOG:
        return {
            ...state,
            openLotDialog: !state.openLotDialog,
        };
    case ACTION_TYPES.REJECT:
        return {
            ...state,
            openConfirmDialog: true,
            recordSelected: action.payload,
            actionSelected: TransferActions.REJECT,
        };
    case ACTION_TYPES.ACCEPT:
        return {
            ...state,
            openLotDialog: true,
            recordSelected: action.payload,
            actionSelected: TransferActions.ACCEPT,
        };
    case ACTION_TYPES.SELECT_RECORD:
        const { selectedRecords } = state;
        let newSelectedRecords = [...selectedRecords];
        const { payload: { item: record, checked } } = action;
        if (checked) {
            newSelectedRecords.push(record);
        } else {
            const index = selectedRecords.findIndex((x) => x.accountNumber === record.accountNumber);
            if (index !== -1) {
                newSelectedRecords = selectedRecords.filter((x) => x.accountNumber !== record.accountNumber);
            }
        }
        return {
            ...state,
            selectedRecords: newSelectedRecords,
            allRecordsSelected: newSelectedRecords.length === state.records.length,
        };
    case ACTION_TYPES.SELECT_ALL_RECORDS:
        const { records } = state;
        const allRecords = cloneDeep(records);
        return {
            ...state,
            selectedRecords: state.allRecordsSelected ? [] : allRecords,
            allRecordsSelected: !state.allRecordsSelected,
        };
    case ACTION_TYPES.ACCEPT_REJECT_MULTIPLE:
        return {
            ...state,
            openLotDialog: action.payload.acceptMultiple,
            openConfirmDialog: !action.payload.acceptMultiple,
            acceptMultiple: action.payload.acceptMultiple,
            actionSelected: action.payload.acceptMultiple ? TransferActions.ACCEPT : TransferActions.REJECT,
        };
    default:
        return state;
    }
};

const DealTransferAccept = ({ closeDialog, stage }) => {
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, cloneDeep(initialState));

    const {
        data, loading, error, refetch,
    } = useQuery(DealsQuery.GET_DEALS_PENDING_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getDealsPendingList } = data;
            dispatch({ type: ACTION_TYPES.SET_RECORDS, payload: getDealsPendingList });
        }
    }, [data, loading, error]);

    const [rejectDeal, { loading: loadingReject }] = useMutation(DealsMutate.REJECT_DEAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.rejectDeal) {
                ModalUtils.successMessage(null, 'Deal Rejected Successfully!');
                refetch();
                if (state.openConfirmDialog) {
                    dispatch({ type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG });
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [acceptDeal, { loading: loadingAccept }] = useMutation(DealsMutate.ACCEPT_DEAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.acceptDeal) {
                ModalUtils.successMessage(null, `Deal #${mutationData?.acceptDeal} created Successfully!`);
                refetch();
                if (state.openLotDialog) {
                    dispatch({ type: ACTION_TYPES.TOGGLE_LOT_DIALOG });
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [acceptRejectMultipleDeal, { loading: loadingAcceptReject }] = useMutation(DealsMutate.ACCEPT_REJECT_MULTIPLE_DEAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.acceptRejectMultipleDeal) {
                if (state.acceptMultiple) {
                    ModalUtils.successMessage(null, `Deal #${mutationData?.acceptRejectMultipleDeal?.join(', ')} created Successfully!`);
                } else {
                    ModalUtils.successMessage(null, 'Deals Rejected Successfully!');
                }
                refetch();
                if (state.openLotDialog) {
                    dispatch({ type: ACTION_TYPES.TOGGLE_LOT_DIALOG });
                }
                if (state.openConfirmDialog) {
                    dispatch({ type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG });
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onActionConfirmed = (lotSelected = null) => {
        if (state.selectedRecords?.length > 0) {
            acceptRejectMultipleDeal({
                variables: {
                    transferQueueIds: state.selectedRecords?.map((item) => item.dealTransferQueueId),
                    stage,
                    lotName: lotSelected.lotName,
                    accept: state.acceptMultiple,
                },
            });
        } else {
            switch (state.actionSelected) {
            case TransferActions.REJECT:
                rejectDeal({
                    variables: {
                        transferQueueId: state.recordSelected.dealTransferQueueId,
                    },
                });
                break;
            case TransferActions.ACCEPT:
                acceptDeal({
                    variables: {
                        stage,
                        lotName: lotSelected.lotName,
                        transferQueueId: state.recordSelected.dealTransferQueueId,
                    },
                });
                break;
            default:
            }
        }
    };

    const onActionClick = (action) => {
        switch (action.value) {
        case TransferActions.REJECT:
            dispatch({ type: ACTION_TYPES.REJECT, payload: action.record });
            break;
        case TransferActions.ACCEPT:
            dispatch({ type: ACTION_TYPES.ACCEPT, payload: action.record });
            break;
        default:
        }
    };

    const renderMoreActions = (record) => {
        const actions = [
            {
                record,
                label: TransferActions.ACCEPT,
                value: TransferActions.ACCEPT,
            }, {
                record,
                label: TransferActions.REJECT,
                value: TransferActions.REJECT,
            },
        ];

        return actions.length > 0 && <Dropdown pullRight actions={actions} onSelect={onActionClick} />;
    };

    const description = state.selectedRecords.length > 0
        ? `Do you want to reject the Deals ${state.selectedRecords?.map((item) => item.accountNumber).join(', ')}?`
        : `Do you want to reject the Deal #${state.recordSelected?.accountNumber}?`;
    const recordCount = state.records.length;

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                titleVariant="h4"
                onClose={closeDialog}
                title="Deals Pending"
            />
            <DialogContent className={classes.dialogContent}>
                {state.records?.length > 0 && (
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL_RECORDS })}
                    >
                        {state.allRecordsSelected ? 'Uncheck All' : 'Check All'}
                    </Button>
                )}
                {state.selectedRecords.length > 0
                    && (
                        <Button
                            variant="contained"
                            disabled={loading}
                            onClick={() => dispatch({ type: ACTION_TYPES.ACCEPT_REJECT_MULTIPLE, payload: { acceptMultiple: true } })}
                        >
                            Accept Deals
                        </Button>
                    )}
                {state.selectedRecords.length > 0
                    && (
                        <Button
                            variant="contained"
                            disabled={loading}
                            onClick={() => dispatch({ type: ACTION_TYPES.ACCEPT_REJECT_MULTIPLE, payload: { acceptMultiple: false } })}
                        >
                            Reject Deals
                        </Button>
                    )}
                <InfiniteScroll
                    className={classes.box}
                    lengthRecord={recordCount}
                    totalRecord={recordCount}
                    loadMore={() => {}}
                    load={loading}
                >
                    {
                        state.records.map((item, index) => {
                            const {
                                term,
                                buyer,
                                status,
                                coBuyer,
                                lotName,
                                vehicle,
                                dealType,
                                sendDate,
                                soldDate,
                                sendingUser,
                                sourceDealer,
                                accountNumber,
                                financeAmount,
                                financeCompany,
                                firstPaymentDue,
                            } = item;
                            let frontRow = '';
                            let secondRow = '';
                            let thirdRow = '';
                            let fourthRow = '';
                            let thirdRowSuffix = '';

                            if (vehicle) {
                                const {
                                    make,
                                    trim,
                                    year,
                                    model,
                                    stockNumber,
                                } = vehicle;
                                thirdRow = `${year || ''} - ${make || ''}`;
                                thirdRowSuffix = stockNumber || '';
                                fourthRow = `${model || ''} - ${trim || ''}`;
                            }

                            if (buyer) {
                                const { firstName, lastName } = buyer;

                                frontRow = `${firstName || ''} ${lastName || ''}`;
                            }

                            if (coBuyer) {
                                const { firstName, lastName } = coBuyer;

                                secondRow = `${firstName || ''} ${lastName || ''}`;
                            }

                            const isChecked = state.selectedRecords?.findIndex((x) => x.accountNumber === accountNumber) >= 0 || false;
                            return (
                                <Accordion
                                    square
                                    elevation={0}
                                    className={clsx({ [classes.listMarginBottom]: index === recordCount - 1 })}
                                    key={accountNumber}
                                >
                                    <ExpandRow>
                                        <div className={clsx(classes.itemContainer, 'show-icon-when-hovering')}>
                                            <CheckboxControl
                                                name="isChecked"
                                                value={isChecked}
                                                className={classes.checkbox}
                                                onClick={() => dispatch({ type: ACTION_TYPES.SELECT_RECORD, payload: { checked: !isChecked, item } })}
                                            />
                                            <VehicleWidget
                                                frontRow={frontRow}
                                                secondRow={secondRow}
                                                thirdRow={thirdRow}
                                                thirdRowSuffix={thirdRowSuffix}
                                                fourthRow={fourthRow}
                                                image={vehicle?.thumbnail}
                                                bodyStyle={vehicle?.style}
                                                labelUnderVehicleImage="Deal"
                                                numberUnderVehicleimage={accountNumber}
                                            />
                                            <div className={`${classes.expansionCol} ${classes.colProspect}`}>
                                                <div className={`${classes.columnContainer} ${classes.columnExtended}`}>
                                                    <div className={classes.textLabelAlign}>
                                                        <Typography
                                                            variant="h6"
                                                            className={`${classes.textSoldDate} ${classes.textBoulder}`}
                                                        >
                                                            Sold Date
                                                        </Typography>
                                                        <Typography variant="h6" className={classes.boldColor}>{DateUtils.getOnlyDate(soldDate)}</Typography>
                                                    </div>
                                                    <Typography variant="h6" className={classes.boldColor}>{status.toUpperCase()}</Typography>
                                                    <Typography
                                                        variant="body1"
                                                    >
                                                        <span className={`${classes.tag} ${classes.dealType}`}>{dealType}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={`${classes.expansionCol} ${classes.colProspect}`}>
                                                <div className={`${classes.columnContainer} ${classes.columnExtended}`}>
                                                    <div className={classes.textLabelAlign}>
                                                        <Typography
                                                            variant="h6"
                                                            className={`${classes.textSoldDate} ${classes.textBoulder}`}
                                                        >
                                                            Finance Company
                                                        </Typography>
                                                        <Typography
                                                            variant="h6"
                                                            className={`${classes.boldColor} ${classes.textLabelAlign}`}
                                                        >
                                                            {financeCompany || ''}
                                                        </Typography>
                                                    </div>
                                                    <Typography
                                                        variant="h6"
                                                        className={classes.boldColor}
                                                    >
                                                        {NumberUtils.applyCurrencyFormat(financeAmount)}
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        className={classes.boldColor}
                                                    >
                                                        {`${DateUtils.getOnlyDate(firstPaymentDue)} - ${term}`}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={`${classes.expansionCol} ${classes.colProspect}`}>
                                                <div className={`${classes.columnContainer} ${classes.columnExtended}`}>
                                                    <div className={classes.textLabelAlign}>
                                                        <Typography
                                                            variant="h6"
                                                            className={`${classes.textSoldDate} ${classes.textBoulder}`}
                                                        >
                                                            Send Date
                                                        </Typography>
                                                        <Typography variant="h6" className={classes.boldColor}>{DateUtils.getOnlyDate(sendDate)}</Typography>
                                                    </div>
                                                    <Typography variant="h6" className={classes.boldColor}>{sourceDealer}</Typography>
                                                    <Typography
                                                        variant="body1"
                                                    >
                                                        <span className={`${classes.tag} ${classes.lotTag}`}>{lotName}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={`${classes.expansionCol} ${classes.salesmanColumn} ${classes.containerAvatarWithName}`}>
                                                <AvatarWithLabel person={sendingUser} />
                                            </div>
                                            {!state.selectedRecords?.length > 0
                                                ? (
                                                    <div
                                                        className={
                                                            `${classes.expansionCol} ${classes.actionsColumn}`
                                                        }
                                                    >
                                                        <Grid className={classes.gridRow} container>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                container
                                                                justify="center"
                                                                alignItems="center"
                                                                className={`with-icon-right ${classes.moreActionsColumn}`}
                                                            >
                                                                {renderMoreActions(item)}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                                : null}
                                        </div>
                                    </ExpandRow>
                                </Accordion>
                            );
                        })
                    }
                </InfiniteScroll>
            </DialogContent>
            <DialogActions
                divider={false}
                hiddenPrimaryButton
                hiddenSecondaryButton
            />
            <ConfirmDialog
                titlePrimary="Yes"
                variant="outlined"
                titleSecondary="No"
                title="Confirm Reject"
                description={description}
                open={state.openConfirmDialog}
                onClickPrimary={onActionConfirmed}
                disablePrimaryButton={loadingReject}
                disableSecondaryButton={loadingReject}
                onClose={() => dispatch({ type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG })}
                onClickSecondary={() => dispatch({ type: ACTION_TYPES.TOGGLE_CONFIRM_DIALOG })}
            />
            {state.openLotDialog && (
                <TransferLot
                    module="deal"
                    customTitle="Lot Selector"
                    open={state.openLotDialog}
                    onTransfer={onActionConfirmed}
                    lotName={state.acceptMultiple ? state.selectedRecords?.[0].sourceDealer : state.recordSelected?.sourceDealer}
                    recordId={state.acceptMultiple ? state.selectedRecords?.map((item) => item.accountNumber).join(', ') : state.recordSelected?.accountNumber}
                    disableConfirmDialogPrimaryButton={loadingAccept || loadingAcceptReject}
                    disableConfirmDialogSecondaryButton={loadingAccept || loadingAcceptReject}
                    closeDialog={() => dispatch({ type: ACTION_TYPES.TOGGLE_LOT_DIALOG })}
                />
            )}
        </Dialog>
    );
};

DealTransferAccept.propTypes = {
    stage: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

export default DealTransferAccept;

import React from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { modules } from 'utils/enum/modules';
import { Link as RouterLink } from 'react-router-dom';
import { InsertCommentOutlined, BlockOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    opportunitySection: {
        marginBottom: theme.spacing(1),
    },
    opportunityFormItem: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            marginLeft: theme.spacing(1),
            color: '#858585',
            fontWeight: 500,
        },
        '& label': {
            margin: 0,
        },
        marginBottom: theme.spacing(0.5),
    },
    link: {
        textDecoration: 'none',
        '& span': {
            color: theme.palette.text.dodgerBlue,
        },
    },
    iconContainer: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    message: {
        color: '#ed9a11',
    },
    block: {
        position: 'absolute',
        color: 'red',
    },
}));

const OpportunitySection = ({ opportunity, prospect }) => {
    const classes = useStyles();

    return (
        <div className={classes.opportunitySection}>
            {opportunity && (
                <Form.Group className={classes.opportunityFormItem}>
                    <Form.Label>Opportunity Code: </Form.Label>
                    <RouterLink
                        target="_blank"
                        to={{
                            pathname: `/${modules.OPPORTUNITIES}/${opportunity.crmId}`,
                        }}
                        className={classes.link}
                    >
                        <span>{opportunity.leadCode}</span>
                    </RouterLink>
                </Form.Group>
            )}
            {!isEmpty(prospect) && (
                <Form.Group className={classes.opportunityFormItem}>
                    <Form.Label>Customer: </Form.Label>
                    <span>{`${prospect.firstName} ${prospect.lastName}`}</span>
                </Form.Group>
            )}
            {!isEmpty(prospect?.cellPhone) && (
                <Form.Group className={classes.opportunityFormItem}>
                    <Form.Label>Cell Phone: </Form.Label>
                    <span>{`${prospect.cellPhone}`}</span>
                    <div className={classes.iconContainer}>
                        {!prospect.allowTextCellPhone && <BlockOutlined className={classes.block} />}
                        <InsertCommentOutlined fontSize="small" className={classes.message} />
                    </div>
                </Form.Group>
            )}
            {!isEmpty(prospect?.homePhone) && (
                <Form.Group className={classes.opportunityFormItem}>
                    <Form.Label>Home Phone: </Form.Label>
                    <span>{`${prospect.homePhone}`}</span>
                </Form.Group>
            )}
            {!isEmpty(prospect?.email) && (
                <Form.Group className={classes.opportunityFormItem}>
                    <Form.Label>Email: </Form.Label>
                    <span>{`${prospect.email}`}</span>
                </Form.Group>
            )}
        </div>
    );
};

OpportunitySection.propTypes = {
    opportunity: PropTypes.object.isRequired,
    prospect: PropTypes.object.isRequired,
};

export default OpportunitySection;

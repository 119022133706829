import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Container from 'components/widgets/Container';
import { makeStyles } from '@material-ui/core/styles';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

// widgets
import TabPanel from 'components/widgets/tabs/TabPanel';

// utils
import KeyStore from 'utils/KeyStore';
import { Helmet } from 'react-helmet-async';
import Permission from 'utils/enum/Permissions';
import { useHistory, useParams } from 'react-router-dom';
import HoursWorkedList from 'components/modules/payroll/read/hoursWorked/HoursWorkedList';
import PayrollCheckList from 'components/modules/payroll/read/payrollChecks/PayrollCheckList';
import MiscCommissionList from 'components/modules/payroll/read/miscCommissions/MiscCommissionList';
import EmployeeDeductionsMapping from 'components/modules/settings/payroll/EmployeeDeductionsMapping';
import GeneratedChecksList from 'components/modules/payroll/read/generatedChecks/GeneratedChecksList';
import TechnicianCommissionList from 'components/modules/payroll/read/technicianCommissions/TechnicianCommissionList';

const localStyle = makeStyles((theme) => ({
    panelContainer: {
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            left: 100,
        },
        border: `solid 2px ${theme.palette.border.primary}`,
        height: '-webkit-fill-available',
    },
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
}));

const tabStyle = makeStyles((theme) => ({
    tabPanel: {
        background: theme.palette.background.white,
        flex: 1,
        height: 'fit-content',
        overflow: 'auto',
    },
    box: {
        display: 'flex',
        height: 'fit-content',
        overflow: 'auto',
    },
}));

const keyStore = new KeyStore();
const tabs = [{
    url: 'hours-worked',
    text: 'Approve Hours Worked',
    icon: <ReceiptOutlinedIcon />,
    component: <HoursWorkedList />,
    permission: Permission.PAYROLL_HOURS_WORKED_READ,
}, {
    url: 'miscellaneous-commissions',
    text: 'Approve Commissions Earned',
    icon: <ReceiptOutlinedIcon />,
    component: <MiscCommissionList />,
    permission: Permission.PAYROLL_MISC_COMMISSIONS_READ,
}, {
    url: 'technician-commissions',
    text: 'Approve Flat Rate Earnings',
    icon: <ReceiptOutlinedIcon />,
    component: <TechnicianCommissionList />,
    permission: Permission.PAYROLL_TECHNICIAN_COMMISSIONS_READ,
}, {
    url: 'pay',
    text: 'Payroll Checks',
    icon: <ReceiptOutlinedIcon />,
    component: <PayrollCheckList />,
    permission: Permission.PAYROLL_GENERATED_CHECKS_READ,
}, {
    url: 'generated-checks',
    text: 'Generated Checks',
    icon: <ReceiptOutlinedIcon />,
    component: <GeneratedChecksList />,
    permission: Permission.PAYROLL_GENERATED_CHECKS_READ,
}, {
    url: 'deductions-report',
    text: 'Deductions per Employee',
    icon: <ReceiptOutlinedIcon />,
    component: <EmployeeDeductionsMapping />,
    permission: Permission.PAYROLL_DEDUCTIONS_REPORT_READ,
}];

const Panel = () => {
    const classes = tabStyle();
    delete classes.boxContainer;
    delete classes.panelContainer;
    const history = useHistory();
    const { subtab } = useParams();
    const items = tabs.filter((item) => keyStore.hasPermission(item.permission));
    const subTabs = items.map((item) => item.url);

    const getSelectedTab = (submodule) => {
        const tabIndex = subTabs.indexOf(submodule);
        return (tabIndex !== -1) ? tabIndex : 0;
    };

    const [value, setValue] = useState(getSelectedTab(subtab || 0));

    const handleChange = (_, newValue) => {
        history.push(`/payroll/${subTabs[newValue]}`);
        setValue(newValue);
    };

    return (
        <>
            <AppBar color="default" position="static">
                <Tabs value={value} onChange={handleChange}>
                    {items.map((item, index) => (
                        <Tab key={index} label={item.text} icon={item.icon} />
                    ))}
                </Tabs>
            </AppBar>
            {items.map((item, index) => (
                <TabPanel classes={classes} key={index} value={value} index={index}>
                    <Helmet
                        defer={false}
                    >
                        <title>{`Payroll / ${item.text}`}</title>
                    </Helmet>
                    {item.component}
                </TabPanel>
            ))}
        </>
    );
};

const PayrollMain = () => {
    const classes = localStyle();

    return (
        <Container className={classes.boxContainer}>
            <div className={classes.panelContainer}>
                <Panel />
            </div>
        </Container>
    );
};

export default PayrollMain;

/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';

const PlaidMapSchema = yup.object().shape({
    institutionName: yup.string().trim().required(),
    bankAccountNumber: yup.number().moreThan(0).required(),
    institutionAccountName: yup.string().trim().required(),
    plaidTransactionCategory: yup.string().trim().required(),
    institutionAccountLastFour: yup.string().trim().required(),
});

export default PlaidMapSchema;

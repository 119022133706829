/* eslint-disable no-unused-expressions */
import React, {
    useReducer, useRef, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip, makeStyles, Button, Dialog, DialogContent,
    AppBar, Toolbar, IconButton, Typography, useTheme,
    useMediaQuery,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Split from 'react-split';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { SaveIcon, CloseIcon } from 'components/icons';
import {
    Col, Form, Row,
} from 'react-bootstrap';
import { isValidField, isValidSchema, getErrorMessage } from 'utils/schema/utils';
import PurchaseOrderSchema from 'utils/schema/parts/PurchaseOrderSchema';
import { useLazyQuery, useMutation } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import PartsMapper from 'services/mapData/PartsMapper';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import PurchaseOrderReducer, { ACTION_TYPES } from 'components/modules/purchaseParts/reducer/PurchaseOrderReducer';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { DataSort, FetchPolicy } from 'utils/enum/Core';

// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import { PartsPurchaseSubModules, PurchaseOrderStatus } from 'utils/enum/PartsPurchaseEnum';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import InputNumber from 'components/widgets/InputNumber';
import UserQuery from 'services/graphQL/query/UserQuery';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import PurchaseOrderDetail from 'components/modules/purchaseParts/purchaseOrder/create/PurchaseOrderDetail';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import usePurchaseValidateActions from 'components/modules/purchaseParts/hooks/usePurchaseValidateActions';
import { VendorType } from 'utils/enum/Vendors';
import { EmployeeType } from 'utils/enum/UserEnum';
import PropTypes from 'prop-types';
import CatalogEnum from 'utils/enum/CatalogEnum';
import { DateFormat } from 'lib/DateUtils';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));
const ownStyle = makeStyles((theme) => ({
    datePickerWrapper: {
        '& .react-datepicker-wrapper': {
            width: '100%',
        },
    },
    toolBarColor: {
        backgroundColor: theme.palette.background.default,
    },
    paddingLeftRigth20: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
}));

const SortFields = {
    VENDOR_NAME: 'vendorName',
};

const keyStore = new KeyStore();

const PurchaseOrderCreate = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id, po } = useParams();

    const history = useHistory();

    const PURCHASE_WRITE = keyStore.hasPermission(Permission.PURCHASE_WRITE);

    const { isReturn, isView } = props;

    const initialState = {
        record: {
            purchaseOrderId: 0,
            vendorId: 0,
            lotId: 0,
            lotName: '',
            status: isReturn ? PurchaseOrderStatus.IN_RETURNED_PROCESS : PurchaseOrderStatus.NEW,
            roNumber: null,
            shippingMethodId: 0,
            orderDate: new Date(),
            deliveryDate: new Date(),
            partsClerk: '',
            salesTaxRate: 0,
            notes: '',
            estimateDelivery: '',
            purchaseOrderReturnId: null,
        },
        isDirty: false,
        couldLostData: false,
        isDirtyFiles: false,
        showPurchasePopup: true,
        vendorList: [],
        clerkList: [],
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const detailRef = useRef();

    const [state, dispatch] = useReducer(PurchaseOrderReducer, initialState);
    const {
        record, isDirty, couldLostData,
        isDirtyFiles, showPurchasePopup,
        vendorList, clerkList,
    } = state;

    const { isValid, errors } = isValidSchema(PurchaseOrderSchema, record);

    const { defaultLotId, defaultLot, lotsUser } = useContext(UserContext);

    const {
        editVendor,
        editShippingMethod,
        editEstimateDelivery,
        editOrderDate,
        editDeliveryDate,
        editPartsClerk,
        editSalesTaxRate,
        editLot,
        editNotes,
        editDetails,
        allowSaveAction,
    } = usePurchaseValidateActions(record.status, isView);

    const [getDefaultPartSettings] = useLazyQuery(PartsQuery.GET_PARTS_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getPartsSettings) {
                const shippingMethodSetup = res.getPartsSettings.find((c) => c.key === 'ShippingMethod');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: {
                            ...record,
                            shippingMethodId: Number(shippingMethodSetup?.value ?? 0),
                        },
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getClerkList] = useLazyQuery(UserQuery.GET_USERS, {
        variables: {
            types: [EmployeeType.PARTS_CLERK],
        },
        onCompleted: (res) => {
            if (res.getUsers) {
                const list = res?.getUsers?.map((item) => ({
                    userId: item.userId,
                    user: [item.firstName, item.lastName].join(' '),
                }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'clerkList',
                        value: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getVendorList] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            filter: {
                vendorType: [VendorType.REPAIR_VENDOR],
                active: true,
            },
            sort: {
                field: SortFields.VENDOR_NAME,
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const list = res.getVendorList?.map((item) => ({
                    vendorId: item.vendorId,
                    vendor: item.vendorName,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'vendorList',
                        value: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getPurchaseOrder, { loading }] = useLazyQuery(PartsQuery.GET_PURCHASE_ORDER, {
        onCompleted: (res) => {
            if (res.getPurchaseOrder) {
                const result = PartsMapper.mapPurchaseOrder(res.getPurchaseOrder, (isReturn && po > 0));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: { ...result },
                        isDirty: (isReturn && po > 0),
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                showPurchasePopup: false,
                isDirty: false,
            },
        });

        history.push(`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}`);
    };

    const onPopupClose = (event, forceClose = false) => {
        if ((isDirty || isDirtyFiles) && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        onCloseAction();
    };

    const [addRecord, { loading: isSaving }] = useMutation(PartsMutation.CREATE_UPDATE_PURCHASE_ORDER, {
        onCompleted: (mutationData) => {
            if (mutationData.createUpdatePurchase > 0) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: ({ graphQLErrors }) => {
            ModalUtils.errorMessage(graphQLErrors);
        },
    });

    const onSave = async (requestedNew) => {
        let resultSave = false;
        let recordToSave = { ...record };

        const { records, errors: detailLinesError } = detailRef.current.getRecords();

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'The detail lines are empty');
            return;
        }

        if (detailLinesError.length > 0) {
            ModalUtils.errorMessage(detailLinesError);
            return;
        }

        recordToSave.purchaseDetails = records;

        recordToSave = PartsMapper.mapPurchaseToSave(recordToSave);
        if (po > 0) {
            recordToSave.purchaseOrderReturnId = Number(po);
        }

        const resultAfterSave = await addRecord({
            variables: {
                record: recordToSave,
            },
        });

        const createdPurchaseOrderId = resultAfterSave?.data.createUpdatePurchase;
        resultSave = createdPurchaseOrderId > 0;

        if (resultSave) {
            if (!requestedNew) onCloseAction(true);
            else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW_PURCHASE,
                    value: initialState,
                });

                detailRef.current.clear();
            }
        }
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === PurchaseOrderStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record.status.toLowerCase() === PurchaseOrderStatus.DRAFT.toLowerCase()) badgeStatus = classes.badgeCleared;
        else if (record.status.toLowerCase() === PurchaseOrderStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record.status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
        else if (record.status.toLowerCase() === PurchaseOrderStatus.RECEIVED.toLowerCase()) badgeStatus = classes.badgePrinted;

        return <Chip size="small" label={record.status} className={badgeStatus} />;
    };

    useEffect(() => {
        if (id > 0) {
            getPurchaseOrder({
                variables: {
                    id: Number(id),
                },
            });
        } else {
            getDefaultPartSettings();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (po > 0) {
            getPurchaseOrder({
                variables: {
                    id: Number(po),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [po]);

    useEffect(() => {
        getVendorList();
        getClerkList();

        if (defaultLotId > 0 && record.lotId === 0 && record.lotName === '') {
            dispatch({
                type: ACTION_TYPES.SET_DEFAULT_LOT,
                value: {
                    lotId: defaultLotId,
                    lotName: defaultLot,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enableBtnSave = (isValid && isDirty && allowSaveAction && !isSaving) || (allowSaveAction && !isSaving && isDirtyFiles);

    const messageToAllowSave = [];

    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    return (
        <Dialog
            open={showPurchasePopup}
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={clsx(classes.centerItems, classes.toolBarColor)}>
                    <div className={classes.buttonSpacing}>
                        {PURCHASE_WRITE
                        && allowSaveAction
                                && (
                                    <ErrorMessageIndicator
                                        messages={messageToAllowSave}
                                    >
                                        <Button
                                            className={classes.buttonSave}
                                            startIcon={<SaveIcon />}
                                            size="small"
                                            disabled={!enableBtnSave}
                                            onClick={() => onSave(false)}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </ErrorMessageIndicator>
                                )}
                        {PURCHASE_WRITE
                        && allowSaveAction
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(true)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Save & New
                                    </Button>
                                )}
                    </div>
                    <div className={classes.centerItems}>
                        <Typography variant="h6">
                            { record.purchaseOrderId === 0 ? `New ${isReturn ? 'purchase return' : 'Purchase'}`
                                : `Edit ${isReturn ? 'purchase return' : 'Purchase'} ${record.purchaseOrderId}` }
                        </Typography>
                        <div className={classes.paddingLeftRigth20}>
                            { getStatusChip() }
                        </div>
                        <IconButton edge="start" color="primary" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                { loading ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Split
                                sizes={isMobile ? [70, 30] : [30, 70]}
                                className={isMobile ? classes.verticalSplit : 'split'}
                                direction={isMobile ? null : 'horizontal'}
                            >
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={isMobile ? 12 : 6}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Vendor *</Form.Label>
                                                <DropdownQuery
                                                    name="vendorId"
                                                    disabled={!editVendor}
                                                    className={isValidField(errors, 'vendorId') ? 'invalid-field' : ''}
                                                    value={record.vendorId}
                                                    placeHolder="select a vendor"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        localData: vendorList,
                                                        idField: 'vendorId',
                                                        descriptionField: 'vendor',
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Shipping Method</Form.Label>
                                                <DropdownQuery
                                                    name="shippingMethodId"
                                                    disabled={!editShippingMethod}
                                                    className={isValidField(errors, 'shippingMethodId') ? 'invalid-field' : ''}
                                                    value={record.shippingMethodId}
                                                    placeHolder="select a shipping method"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: CatalogQuery.ENUM_VALUES,
                                                        rootData: 'getEnumValues',
                                                        idField: 'enumValuesId',
                                                        descriptionField: 'description',
                                                        variables: {
                                                            descriptions: [CatalogEnum.SHIPPING_METHOD],
                                                        },
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">ETA</Form.Label>
                                                <Row>
                                                    <Col lg={12} className={classes.datePickerWrapper}>
                                                        <DatePicker
                                                            className="form-control form-control-sm"
                                                            selected={record.estimateDelivery}
                                                            onChange={(date) => onChangeValue('estimateDelivery', date)}
                                                            showTimeSelect
                                                            timeFormat={DateFormat.DEFAULT_TIME}
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            minDate={new Date()}
                                                            dateFormat={
                                                                DateFormat
                                                                    .DEFAULT_DATETIME_WITHOUT_SECONDS
                                                                    .replace('DD', 'dd')
                                                                    .replace('YYYY', 'yyyy')
                                                            }
                                                            popperPlacement="right-start"
                                                            popperContainer={CalendarContainer}
                                                            disabled={!editEstimateDelivery}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Lot</Form.Label>
                                                <DropdownQuery
                                                    name="lotName"
                                                    disabled={!editLot}
                                                    className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                                    value={record.lotName}
                                                    placeHolder="select a lot"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue === 0 ? '' : newValue)}
                                                    dataSource={{
                                                        idField: 'lotName',
                                                        descriptionField: 'lotName',
                                                        localData: lotsUser,
                                                    }}
                                                />
                                            </Form.Group>
                                            {(Number(po) > 0 || Number(record.purchaseOrderReturnId) > 0)
                                            && (
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">Return Purchase Order #</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={po || record.purchaseOrderReturnId}
                                                        placeholder=""
                                                        name="purchaseOrderReturnId"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            )}
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 6}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Order Date *</Form.Label>
                                                <DatePicker
                                                    selected={record.orderDate}
                                                    disabled={!editOrderDate}
                                                    name="orderDate"
                                                    size="sm"
                                                    className={clsx('form-control form-control-sm',
                                                        isValidField(errors, 'orderDate') ? 'invalid-field' : '')}
                                                    placeholderText="mm/dd/yyyy"
                                                    popperContainer={CalendarContainer}
                                                    wrapperClassName="col"
                                                    onChange={(date) => onChangeValue('orderDate', date)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Delivery Date *</Form.Label>
                                                <DatePicker
                                                    selected={record.deliveryDate}
                                                    disabled={!editDeliveryDate}
                                                    name="deliveryDate"
                                                    size="sm"
                                                    className={clsx('form-control form-control-sm',
                                                        isValidField(errors, 'deliveryDate') ? 'invalid-field' : '')}
                                                    placeholderText="mm/dd/yyyy"
                                                    popperContainer={CalendarContainer}
                                                    wrapperClassName="col"
                                                    onChange={(date) => onChangeValue('deliveryDate', date)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Parts Clerk</Form.Label>
                                                <DropdownQuery
                                                    name="partsClerk"
                                                    disabled={!editPartsClerk}
                                                    className={isValidField(errors, 'partsClerk') ? 'invalid-field' : ''}
                                                    value={record.partsClerk}
                                                    placeHolder="select a clerk"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue === 0 ? '' : newValue)}
                                                    dataSource={{
                                                        localData: clerkList,
                                                        idField: 'user',
                                                        descriptionField: 'user',
                                                    }}
                                                    defaultEmptyLineText="Empty"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Sales Tax Rate</Form.Label>
                                                <InputNumber
                                                    disabled={!editSalesTaxRate}
                                                    size="sm"
                                                    showCurrency={false}
                                                    allowNegative={false}
                                                    value={record.salesTaxRate}
                                                    thousandSeparator
                                                    placeholder="0"
                                                    onChange={(value) => onChangeValue('salesTaxRate', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Notes</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={!editNotes}
                                                    className={isValidField(errors, 'notes') ? 'invalid-field' : ''}
                                                    size="sm"
                                                    rows="2"
                                                    value={record.notes || ''}
                                                    placeholder="Notes"
                                                    onChange={(e) => onChangeValue('notes', e.target.value)}
                                                />
                                                <p className={classes.fontSmall}>{getErrorMessage('notes', errors)}</p>
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                </div>
                                <PurchaseOrderDetail
                                    recordId={record.purchaseOrderId}
                                    vendorId={record.vendorId}
                                    taxRate={record.salesTaxRate}
                                    editDetails={editDetails}
                                    writePermissions={PURCHASE_WRITE && !isView}
                                    ref={detailRef}
                                    onDirty={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { isDirty: true } })}
                                    lotName={record.lotName}
                                    po={Number(po ?? 0)}
                                    isReturn={isReturn}
                                />
                            </Split>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                        </Container>
                    )}
            </DialogContent>
            {isSaving && <DialogActionMessage message="Saving information... " />}
            {loading && Number(po) > 0 && <DialogActionMessage message="Getting purchase information... " />}
        </Dialog>
    );
};

PurchaseOrderCreate.propTypes = {
    isReturn: PropTypes.bool,
    isView: PropTypes.bool,
};

PurchaseOrderCreate.defaultProps = {
    isReturn: false,
    isView: false,
};

export default PurchaseOrderCreate;

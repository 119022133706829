import React, { useEffect, useReducer, useRef } from 'react';

import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Button } from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';
import { FilterIcon } from 'components/icons/index';
import { makeStyles } from '@material-ui/core/styles';
import useOutside from 'components/hook/core/useOutside';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import usePrevious from 'components/hook/core/usePrevious';

const useStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        '& .rdrDefinedRangesWrapper': {
            width: '160px',
        },
        '& svg': {
            width: '13px',
            height: '15px',
            fill: theme.palette.text.waterloo,
        },
    },
    filtered: {
        fill: `${theme.palette.background.red} !important`,
    },
    filterContainer: {
        position: 'fixed',
        zIndex: '999',
        boxShadow: '0 1px 6px 0 rgb(0 0 0 / 25%)',
        background: '#fff',
        borderRadius: theme.spacing(0.5),
        overflow: 'hidden',
        '-webkit-transition': 'width 200ms',
        '-moz-transition': 'width 200ms',
        '-o-transition': 'width 200ms',
        transition: 'width 200ms',
        '&.withoutFilter': {
            width: '150px',
        },
        top: ({ top }) => top + 26,
    },
    hiddenCalendar: {
        '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
            display: 'none',
        },
    },
    button: {
        margin: theme.spacing(0.8, 0),
        marginRight: theme.spacing(2),
        width: '64px',
    },
    footer: {
        borderTop: '1px solid #eff2f7',
    },
    selectedAllLabel: {
        '& .rdrStaticRanges button:first-child': {
            color: 'rgb(61, 145, 255)',
            fontWeight: 600,
        },
    },
    text: {
        color: theme.palette.text.outerSpace,
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    ...ButtonStyles.getStyle(theme),
}));

const ACTION_TYPE = {
    ON_CANCEL: 'onCancel',
    ON_TOGGLE: 'onToggle',
    ON_APPLY: 'onApply',
    ON_CHANGE_VALUE: 'onChangeValue',
};
const initState = {
    open: false,
    selectedValues: {},
    prevSelectedValues: {},
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.ON_TOGGLE:
        return update(state, {
            open: { $set: action.payload },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        if (action.payload?.isCustomRange) {
            return update(state, {
                selectedValues: { $set: action.payload },
            });
        }

        return update(state, {
            selectedValues: { $set: action.payload },
            prevSelectedValues: { $set: action.payload },
        });
    case ACTION_TYPE.ON_CANCEL:
        return update(state, {
            selectedValues: { $set: state.prevSelectedValues },
            open: { $set: false },
        });
    case ACTION_TYPE.SET_SELECTED_VALUES:
        return update(state, {
            selectedValues: { $set: action.payload },
            prevSelectedValues: { $set: action.payload },
        });
    case ACTION_TYPE.ON_APPLY:
        return update(state, {
            selectedValues: { $set: action.payload },
            open: { $set: false },
        });
    default:
        return state;
    }
};

const DateRange = ({
    className, onChangeValue, selectedValue, showIconOnly,
}) => {
    const ref = useRef();
    const classes = useStyle({ top: ref.current?.getBoundingClientRect()?.top });
    const [state, dispatch] = useReducer(reducer, initState);
    const previousSelectedValues = usePrevious(selectedValue);
    const dateRange = state.selectedValues || {};

    useEffect(() => {
        if (
            (!moment(previousSelectedValues?.startDate).isSame(selectedValue.startDate)
            && !moment(previousSelectedValues?.endDate).isSame(selectedValue.endDate))
            || (selectedValue.startDate === undefined && selectedValue.endDate === undefined)) {
            dispatch({
                type: ACTION_TYPE.SET_SELECTED_VALUES,
                payload: selectedValue,
            });
        }
    }, [selectedValue, previousSelectedValues]);

    const custom = createStaticRanges([
        {
            label: 'Today',
            range: () => ({
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
                label: 'Today',
                isCustomRange: false,
            }),
        },
        {
            label: 'Yesterday',
            range: () => ({
                startDate: moment().subtract(1, 'days').startOf('day').toDate(),
                endDate: moment().subtract(1, 'days').endOf('day').toDate(),
                label: 'Yesterday',
                isCustomRange: false,
            }),
        },
        {
            label: 'Last 7 Days',
            range: () => ({
                startDate: moment().subtract(6, 'days').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
                label: 'Last 7 Days',
                isCustomRange: false,
            }),
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: moment().subtract(29, 'days').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
                label: 'Last 30 Days',
                isCustomRange: false,
            }),
        },
        {
            label: 'This Month',
            range: () => ({
                startDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate(),
                label: 'This Month',
                isCustomRange: false,
            }),
        },
        {
            label: 'Last Month',
            range: () => ({
                startDate: moment().subtract(1, 'months').startOf('month').toDate(),
                endDate: moment().subtract(1, 'months').endOf('month').toDate(),
                label: 'Last Month',
                isCustomRange: false,
            }),
        },
    ]);

    const staticRangesCustom = createStaticRanges([
        {
            label: 'All',
            range: () => ({
                startDate: undefined,
                endDate: undefined,
                label: 'All',
                isCustomRange: false,
            }),
        },
        ...custom,
        {
            label: 'Custom Range',
            range: () => ({
                startDate: moment().add(1, 'days').startOf('day').toDate(),
                endDate: moment().add(1, 'days').endOf('day').toDate(),
                label: 'Custom Range',
                isCustomRange: true,
            }),
            isSelected() {
                return !custom.some((item) => item.isSelected(dateRange));
            },
        },
    ]);

    const onCancel = () => {
        dispatch({
            type: ACTION_TYPE.ON_CANCEL,
        });
    };

    const onToggle = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_TOGGLE,
            payload: value,
        });
    };

    const onChange = (value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: value,
        });
    };
    const onApply = () => {
        const result = {
            ...state.selectedValues,
            endDate: moment(state.selectedValues.endDate).endOf('day').toDate(),
        };
        dispatch({
            type: ACTION_TYPE.ON_APPLY,
            payload: result,
        });
        onChangeValue(result);
    };
    useOutside(ref, onCancel);

    return (
        <div
            ref={ref}
            className={clsx(classes.root, className)}
        >
            {
                showIconOnly ? (
                    <FilterIcon
                        onClick={() => onToggle(!state.open)}
                        className={clsx({ [classes.filtered]: dateRange.label?.toUpperCase() !== 'ALL' })}
                    />
                )
                    : (
                        <div
                            className={classes.text}
                            onClick={() => onToggle(!state.open)}
                        >
                            {dateRange.label}
                        </div>
                    )
            }
            <If condition={state.open}>
                <div
                    className={clsx(classes.filterContainer)}
                >
                    <DateRangePicker
                        onChange={(item) => {
                            if (!item.range1.isCustomRange) {
                                onChangeValue(item.range1);
                                onToggle(false);
                            }
                            onChange(item.range1);
                        }}
                        showDateDisplay={false}
                        months={2}
                        color="f6be00"
                        ranges={[state.selectedValues]}
                        direction="horizontal"
                        staticRanges={staticRangesCustom}
                        inputRanges={[]}
                        // * * Find a better solution to select the all option
                        className={clsx({ [classes.hiddenCalendar]: !dateRange.isCustomRange || false },
                            { [classes.selectedAllLabel]: dateRange.startDate === undefined && dateRange.endDate === undefined })}
                    />
                    <If condition={!!dateRange.isCustomRange}>
                        <div className={clsx(classes.footer, 'd-flex-justify-end')}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={clsx(classes.containedSecondaryInfo, classes.button)}
                                onClick={onApply}
                            >
                                Apply
                            </Button>
                        </div>
                    </If>
                </div>
            </If>
        </div>
    );
};

DateRange.propTypes = {
    className: PropTypes.string,
    showIconOnly: PropTypes.bool,
    onChangeValue: PropTypes.func.isRequired,
    selectedValue: PropTypes.object.isRequired,
};

DateRange.defaultProps = {
    className: null,
    showIconOnly: false,
};

export default DateRange;

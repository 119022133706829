import { gql } from '@apollo/client';

export default class VendorMutation {
    static CREATE_VENDOR_CATEGORY = gql`
        mutation createVendorCategory(
            $category: String!
        ) {
            createVendorCategory(
                category: $category
            )
        }
    `;

    static CREATE_VENDOR = gql`
        mutation addVendor($input: VendorInput) {
            addVendor(input: $input){
                vendorId
            }
        }
    `;

    static UPDATE_VENDOR = gql`
        mutation updateVendor(
            $id: Int!
            $input: VendorInput
        ) {
            updateVendor(id: $id, input: $input){
                vendorId
            }
        }
    `;
}

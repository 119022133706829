import React from 'react';

// Material UI
import {
    Divider, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import LeadHelper from 'components/modules/lead/LeadHelper';
import { LeadTypeStyle } from 'styles/modules/crm/LeadStyles';
import CardVehicle from 'components/modules/lead/widgets/CardVehicle';

const useStyle = makeStyles((theme = {}) => ({
    label: {
        color: `${theme.palette.text.minsk}`,
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
        whiteSpace: 'nowrap',
    },
    middleColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    vehicle: {
        padding: 0,
        border: 'none',
        minWidth: 210,
        maxWidth: 210,
    },
    item: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    ...LeadTypeStyle,
}));

const LeadWidgetItem = ({
    vehicle, source, leadType, createdOn,
}) => {
    const stockNumber = vehicle?.stockNumber;
    const date = DateUtils.calendarWithTime(createdOn);
    const type = LeadHelper.getLeadType(leadType);
    const typeIcon = LeadHelper.getLeadTypeIcon(type);
    const cardVehicle = vehicle || {};
    const classes = useStyle();
    return (
        <div>
            <Grid container className={classes.item}>
                <Grid item xs={4}>
                    <CardVehicle
                        className={classes.vehicle}
                        vehicle={{ ...cardVehicle, thumbnail: vehicle?.inventory?.thumbnail }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.middleColumn}>
                    <div>
                        <span className={classes.label}>Stock: </span>
                        <span className={classes.value}>
                            {stockNumber ?? 'None'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Source: </span>
                        <span className={clsx(classes.value)}>
                            {source ?? 'None'}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightColumn}>
                    <div>
                        <span className={classes.label}>Lead Type: </span>
                        <span className={clsx(classes.value, classes.leadType, typeIcon.name)}>
                            {typeIcon.icon}
                            <span>{type}</span>
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Date: </span>
                        <span className={clsx(classes.value)}>
                            {date}
                        </span>
                    </div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};

LeadWidgetItem.defaultProps = {
    vehicle: null,
    createdOn: null,
};

LeadWidgetItem.propTypes = {
    vehicle: PropTypes.object,
    createdOn: PropTypes.string,
    source: PropTypes.string.isRequired,
    leadType: PropTypes.string.isRequired,
};

export default LeadWidgetItem;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { v1 as uuid } from 'uuid';

// material
import { Button, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// bootstrap
import { Col, Form } from 'react-bootstrap';

// widgets
import Table from 'components/widgets/Table';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

// utils
import ModalUtils from 'utils/ModalUtils';
import { isEmpty, concat } from 'lodash';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';

// service
import CheckPrintingSettingsQuery from 'services/graphQL/query/setting/CheckPrintingSettings';
import CheckPrintingSettingsMutation from 'services/graphQL/mutate/setting/CheckPrintingSettingsMutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';

// custom
import SelectControl from 'components/widgets/editorControls/SelectControl';
import InputControl from 'components/widgets/editorControls/InputControl';

const useStyles = makeStyles((theme) => ({
    labels: {
        fontSize: '0.875rem',
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
        margin: '15px 0px 15px 20px',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    tableSpacing: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        maxHeight: '250px',
        minHeight: '100px',
        '& .rt-td': {
            padding: '0px 5px',
        },
    },
    columnLeft: {
        alignItems: 'left',
        justifyContent: 'left',
        textAlign: 'left',
    },
    highlight: {
        '& .rt-table': {
            border: 'none !important',
        },
    },
}));

const CheckPrintingSettings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    // table
    const [records, setRecords] = useState([]);
    const [tableId, setTableId] = useState(uuid());

    // delete
    const [openDelete, setOpenDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    // get
    const [getPrintingSettings, { loading }] = useLazyQuery(CheckPrintingSettingsQuery.GET_CHECK_PRINTING_SETTINGS, {
        onCompleted: (res) => {
            const { getAccountingCOABankList } = res;
            if (!getAccountingCOABankList?.data) {
                return;
            }

            const items = getAccountingCOABankList.data.map((item) => ({
                accountId: uuid(),
                accountNumber: item.accountNumber,
                bankAccountNumber: item.bankAccountNumber,
                routingNumber: item.routingNumber,
            }));
            setRecords(items);
            setTableId(uuid());
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getData = () => {
        getPrintingSettings({
            variables: {
                paginate: {
                    init: 0,
                    ignoreLimit: true,
                },
            },
        });
    };

    // update
    const [updateAccountBankInformation, { loading: updating }] = useMutation(CheckPrintingSettingsMutation.UPDATE_ACCOUNT_BANK_INFORMATION, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.updateAccountBankInformation) {
                ModalUtils.successMessage(null, 'saved successfully');
                getData();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCloseDeleteConfirm = () => {
        setOpenDelete(false);
        setIdToDelete(0);
    };

    const onDeleteConfirm = () => {
        const input = records.filter((c) => c.accountNumber === idToDelete)
            .map((item) => ({
                accountNumber: item.accountNumber,
                bankAccountNumber: null,
                routingNumber: null,
            }));
        updateAccountBankInformation({ variables: { records: input } });
        setRecords((prev) => prev.filter((item) => item.accountNumber !== idToDelete));
        onCloseDeleteConfirm();
    };

    const onUpdate = () => {
        const input = records.map((item) => ({
            accountNumber: item.accountNumber,
            bankAccountNumber: item.bankAccountNumber,
            routingNumber: item.routingNumber,
        }));
        updateAccountBankInformation({ variables: { records: input } });
    };

    const onAddNewLine = () => {
        setRecords((prev) => concat(prev, {
            accountId: uuid(),
            accountNumber: 0,
            bankAccountNumber: null,
            routingNumber: null,
        }));
    };

    const handleEditorChange = (columnId, newValue, cell) => {
        const keyValue = 'accountNumber';
        const keyId = 'accountId';
        let newValueData = newValue;
        setRecords((prev) => {
            if (columnId === keyValue && prev.filter((item) => Number(item[columnId]) === Number(newValueData)).length > 0) {
                ModalUtils.errorMessage(null, 'You must select a different Account');
                newValueData = 0;
            }

            return prev.map((item) => {
                const newItem = { ...item };
                if (item[keyId] === cell.original[keyId]) { newItem[columnId] = newValueData; }

                return newItem;
            });
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        if (event && (key === 'Tab' || keyCode === 9)
            && cell.original.accountNumber > 0
            && !isEmpty(cell.original.bankAccountNumber)
            && !isEmpty(cell.original.routingNumber)
        ) {
            onAddNewLine();
        }
    };

    const getColumns = () => {
        const columns = [
            {
                Header: 'Bank G/L Number',
                minWidth: 60,
                accessor: 'accountNumber',
                headerClassName: classes.columnLeft,
                Cell: (cell) => {
                    const { original: { accountNumber }, column: { id } } = cell;
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={id}
                                value={accountNumber}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypes: [AccountingCOAType.BANK],
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                }}
                                comparePropertyId={accountNumber}
                            />
                        );
                    }
                    return <span>{accountNumber}</span>;
                },
            },
            {
                Header: 'Account Number',
                headerClassName: classes.columnLeft,
                minWidth: 60,
                accessor: 'bankAccountNumber',
                Cell: (cell) => {
                    const { original: { bankAccountNumber, accountNumber }, column: { id } } = cell;
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <InputControl
                                name={id}
                                value={bankAccountNumber}
                                editorCellObject={cell}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                className={isEmpty(bankAccountNumber) ? 'invalid-field' : ''}
                                comparePropertyId={accountNumber}
                            />
                        );
                    }
                    return <span>{bankAccountNumber}</span>;
                },
            },
            {
                Header: 'Routing Number',
                headerClassName: classes.columnLeft,
                minWidth: 60,
                accessor: 'routingNumber',
                Cell: (cell) => {
                    const { original: { routingNumber, accountNumber }, column: { id } } = cell;
                    if (ACCOUNTING_SETTINGS_WRITE) {
                        return (
                            <InputControl
                                name={id}
                                value={routingNumber}
                                editorCellObject={cell}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                className={isEmpty(routingNumber) ? 'invalid-field' : ''}
                                comparePropertyId={accountNumber}
                            />
                        );
                    }
                    return <span>{routingNumber}</span>;
                },
            }];

        if (ACCOUNTING_SETTINGS_WRITE) {
            columns.push({
                Header: 'Actions',
                minWidth: 60,
                width: 70,
                Cell: (cell) => (
                    <IconButton onClick={() => { setOpenDelete(true); setIdToDelete(cell.original.accountNumber); }}>
                        <DeleteIcon className={classes.deleteButton} />
                    </IconButton>
                ),
            });
        }

        return columns;
    };

    return (
        <div>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Default Check Printing Settings</Form.Label>
                <Divider />
            </Form.Group>
            <Table
                key={tableId}
                className={clsx(classes.highlight, classes.tableSpacing)}
                data={records}
                columns={getColumns()}
                cursor="default"
                load={loading}
                totalRecords={records?.length}
                rowSelected
                enableRowHover
            />
            <div className={classes.buttonSpacing}>
                {ACCOUNTING_SETTINGS_WRITE && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleOutlineIcon />}
                            size="small"
                            onClick={onAddNewLine}
                        >
                            Add
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SaveOutlinedIcon />}
                            size="small"
                            disabled={updating}
                            onClick={onUpdate}
                        >
                            {updating ? 'Saving...' : 'Save'}
                            {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </>
                )}
                <Button
                    variant="outlined"
                    startIcon={<CachedIcon />}
                    onClick={getData}
                    disabled={loading}
                    size="small"
                >
                    Reload
                </Button>
            </div>
            <ConfirmDialog
                title="Confirm remove line"
                description="Are you sure you want to remove this line?"
                open={openDelete}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
        </div>
    );
};

export default CheckPrintingSettings;

import { useApolloClient } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';

const useFunnelStage = () => {
    const client = useApolloClient();

    const updateFunnelStage = async (
        crmId,
        stage,
        value,
        customerLeaving,
    ) => {
        try {
            await client.mutate({
                mutation: OpportunityMutation.UPDATE_OPPORTUNITY_FUNNEL_STAGE,
                variables: {
                    crmId,
                    stage,
                    value,
                    customerLeaving,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    return {
        updateFunnelStage,
    };
};

export default useFunnelStage;

import React, { useReducer } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Toolbar from 'components/modules/crm/calendar/Toolbar';
import ActivitiesForm from 'components/modules/crm/activities/form/ActivitiesForm';

const NAV_GROUP = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREV',
    GO_TODAY: 'TODAY',
};

const VIEW_GROUP = {
    MONTH: {
        value: 'MONTH',
        view: 'dayGridMonth',
    },
    WEEK: {
        value: 'WEEK',
        view: 'timeGridWeek',
    },
    DAY: {
        value: 'DAY',
        view: 'dayGridWeek',
    },
};
const calendarHeight = 700;
const initState = {
    currentDate: new Date(),
    selectedView: VIEW_GROUP.MONTH,
    record: {
        crmId: null,
        activityId: null,
        type: null,
    },
    openDialog: false,
};
const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_CURRENT_DATE: 'setCurrentDate',
    ON_CHANGE_VIEW_GROUP: 'onChangeViewGroup',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_DATE: {
        return update(state, {
            currentDate: { $set: action.payload },
        });
    }
    case ACTION_TYPES.ON_CHANGE_VIEW_GROUP: {
        const record = action.payload;
        return update(state, {
            currentDate: { $set: record.date },
            selectedView: { $set: record.selectedView },
        });
    }
    case ACTION_TYPES.ON_CLOSE:
        return update(state, {
            record: {
                type: { $set: null },
                crmId: { $set: null },
                lotId: { $set: null },
                activityId: { $set: null },
            },
            openDialog: { $set: false },
        });
    case ACTION_TYPES.SET_RECORD:
        const { opportunity, activityId, type } = action.payload;
        return update(state, {
            record: {
                activityId: { $set: activityId },
                crmId: { $set: opportunity.crmId },
                lotId: { $set: opportunity.lotId },
                type: { $set: type.toUpperCase() },
            },
            openDialog: { $set: true },
        });
    default:
        return state;
    }
};
const MainPanel = ({ updateDateRange, records }) => {
    const calendarRef = React.createRef();
    const [state, dispatch] = useReducer(reducer, initState);
    const onClickEvent = (info) => {
        const { record } = info.event.extendedProps;
        dispatch({
            type: ACTION_TYPES.SET_RECORD,
            payload: record,
        });
    };

    const onClose = () => {
        dispatch({
            type: ACTION_TYPES.ON_CLOSE,
        });
    };

    const onClickNavigationGroup = (action) => {
        const calendarApi = calendarRef.current.getApi();

        switch (action) {
        case NAV_GROUP.NEXT:
            calendarApi.next();
            break;
        case NAV_GROUP.PREVIOUS:
            calendarApi.prev();
            break;
        case NAV_GROUP.GO_TODAY:
            calendarApi.today();
            break;
        default:
            calendarApi.today();
            break;
        }

        const date = calendarApi.getDate();
        dispatch({
            type: ACTION_TYPES.SET_CURRENT_DATE,
            payload: date,
        });
        updateDateRange(date, state.selectedView.value.toLowerCase());
    };

    const onClickViewGroup = (newView) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(newView.view);
        const date = calendarApi.getDate();

        updateDateRange(date, newView.value);
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VIEW_GROUP,
            payload: {
                date,
                selectedView: newView,
            },
        });
    };

    return (
        <>
            <Toolbar
                currentDate={state.currentDate}
                currentView={state.selectedView}
                onClickNavigationGroup={onClickNavigationGroup}
                onClickViewGroup={onClickViewGroup}
            />
            <FullCalendar
                ref={calendarRef}
                headerToolbar={false}
                events={records}
                height={calendarHeight}
                eventContent={(arg) => {
                    const { extendedProps } = arg.event;
                    const isMonth = state.selectedView.value === VIEW_GROUP.MONTH.value;

                    if (extendedProps.record) {
                        return (
                            <div className="fc-event-main-frame">
                                <div className={clsx('fc-event-time', { 'd-flex-space-between': !isMonth })}>
                                    {arg.timeText}
                                    {!isMonth && <span>{extendedProps.icon}</span>}
                                </div>
                                <div className="fc-event-title-container">
                                    <div className="fc-event-title fc-sticky text-ellipsis">
                                        {arg.event._def.title}
                                    </div>
                                </div>
                                {isMonth && extendedProps.icon}
                            </div>
                        );
                    }

                    return null;
                }}
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, timeGridPlugin]}
                eventClick={onClickEvent}
            />
            {state.openDialog && (
                <ActivitiesForm
                    isEditing
                    onClose={onClose}
                    open={state.openDialog}
                    type={state.record.type}
                    crmId={state.record.crmId}
                    lotId={state.record.lotId}
                    activityId={state.record.activityId}
                />
            )}
        </>
    );
};

MainPanel.propTypes = {
    updateDateRange: PropTypes.func.isRequired,
    records: PropTypes.array.isRequired,
};

export default MainPanel;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { map, find } from 'lodash';
import NumberUtils from 'lib/NumberUtils';
import { Form, Col } from 'react-bootstrap';
import Loading from 'components/widgets/Loading';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import { DealSection, ProductType } from 'utils/enum/DealEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DealBackendContainer from 'components/containers/deals/read/dealTab/DealBackendContainer';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    Typography, Button, Paper, Grid,
    Checkbox, FormControlLabel, IconButton, Tooltip,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import { ReactComponent as DeleteOutlineOutlinedIcon } from 'assets/remove.svg';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as UnCheckIcon } from 'assets/uncheck.svg';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ProductSchema, ProductRowSchema } from 'utils/schema/deal/Product';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => DealStyles.dealStyles(theme, fade);

// TODO: Example list product (task in progres "UI")
class DealBackend extends Component {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_PRODUCTS_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_PRODUCTS_WRITE);
    }

    renderConfirmationDialogToRemove() {
        const { props: { open, onCloseDialog, onDelete } } = this;

        return (
            <ConfirmDialog
                title="Confirm remove product"
                description="Are you sure you want to remove this product?"
                open={open}
                showHeader
                titlePrimary="Yes"
                titleSecondary="No"
                variant="outlined"
                dividerFooter={false}
                onClickPrimary={() => {
                    onDelete();
                    onCloseDialog();
                }}
                onClose={onCloseDialog}
                onClickSecondary={onCloseDialog}
            />
        );
    }

    renderListProduct() {
        const {
            classes, products, onOpenDialog, editingMode,
            clientId, postedDate, editingStatus,
        } = this.props;

        const isNotPosted = postedDate === null;
        const { isEditing } = editingStatus;
        const productList = products.filter((x) => x.dealProductId !== null);

        return map(productList, (item) => {
            const {
                type, price, cost, policyNumber, dealProductId,
                vendor, addToSellingPrice, product, term,
            } = item;
            const currentVendorName = vendor && vendor.vendorName ? vendor.vendorName : '';
            const currentProductName = product && product.productName ? product.productName : '';

            return (
                <Paper elevation={0} className={classes.boxProduct} variant="outlined" square key={dealProductId}>
                    <Grid container spacing={1}>
                        <Grid item container spacing={1} xs={7}>
                            <Grid item xs={5} className={classes.columnFields}>
                                <Typography color="primary" variant="h6">Type:</Typography>
                                <Typography noWrap>{type}</Typography>
                            </Grid>
                            <Grid item xs={7} className={classes.columnFields}>
                                <Typography color="primary" variant="h6">Company:</Typography>
                                <Typography noWrap>{currentVendorName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} xs={5}>
                            <Grid item xs={12} className={classes.columnFields}>
                                <Typography color="primary" variant="h6">Plan:</Typography>
                                <Typography noWrap>{currentProductName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} xs={12}>
                            <Grid item container spacing={1} xs={6}>
                                <Grid item xs={6} container className={classes.columnFields}>
                                    <Typography color="primary" variant="h6">Price:</Typography>
                                    <Typography>{NumberUtils.applyCurrencyFormat(price)}</Typography>
                                </Grid>
                                <Grid item xs={6} container className={classes.columnFields}>
                                    <Typography color="primary" variant="h6">Cost:</Typography>
                                    <Typography>{NumberUtils.applyCurrencyFormat(cost)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1} xs={6}>
                                <Grid item container spacing={1} xs={8}>
                                    <Grid item xs={6} container className={classes.columnFields} wrap="nowrap">
                                        <Typography color="primary" variant="h6">Term:</Typography>
                                        <Tooltip placement="bottom" title={term}>
                                            <Typography className={classes.truncate}>{term}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} container className={classes.columnFields} wrap="nowrap">
                                        <Typography color="primary" variant="h6">Policy:</Typography>
                                        <Tooltip placement="bottom" title={policyNumber}>
                                            <Typography className={classes.truncate}>{policyNumber}</Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container justify="center" className={classes.columnFields}>
                                    {
                                        addToSellingPrice
                                            ? <CheckIcon className={classes.productsCheckIcon} />
                                            : <UnCheckIcon className={classes.productsCheckIcon} />
                                    }
                                    <Tooltip
                                        // eslint-disable-next-line max-len
                                        title="Checking this box will add the selling price of the product to the price of the vehicle on line 1 of the Retail Installment Contract."
                                    >
                                        <Typography>Line 1</Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.SALES_DEAL_PRODUCTS_WRITE && (
                        <div className={classes.deleteIcon}>
                            <IconButton
                                size="small"
                                aria-label="Remove Product"
                                disabled={isEditing || (editingMode.isEditing && editingMode.sectionName !== DealSection.BACK_END)}
                                onClick={() => onOpenDialog(dealProductId)}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </div>
                    )}
                </Paper>
            );
        });
    }

    renderFormProduct(record, index, limit) {
        const {
            props: {
                classes, onChange, getCurrentCompany, getCurrentPlan,
                onChangeType, removeProduct, onChangePlan, onChangeCompany,
            },
        } = this;
        const {
            policyNumber, price, cost, addToSellingPrice, type,
            vendor, product, dealProductId, term,
        } = record;
        const typeValue = { label: type, value: type };
        const optionVendor = getCurrentCompany(type);
        const vendorValue = find(optionVendor, { value: vendor.vendorId });
        const optionPlan = getCurrentPlan(type, vendorValue);
        const optionValue = find(optionPlan, { value: product.productId });
        const resetSelect = { label: '', value: '' };
        const showRemoveButton = !dealProductId;
        const isValidData = isValidSchema(ProductRowSchema, record);
        const { errors } = isValidData;
        const {
            limitGap,
            limitVsc,
            limitProducts,
        } = limit;
        let typeOptions = [
            {
                value: 'VSC',
                label: 'VSC',
            },
            {
                value: 'PRODUCT',
                label: 'Products',
            },
            {
                value: 'GAP',
                label: 'Gap',
            },
        ];

        if (limitGap) typeOptions = typeOptions.filter((x) => x.value !== ProductType.GAP);
        if (limitVsc) typeOptions = typeOptions.filter((x) => x.value !== ProductType.VSC);
        if (limitProducts) typeOptions = typeOptions.filter((x) => x.value !== ProductType.PRODUCT);

        return (
            <Paper elevation={0} className={classes.boxProduct} variant="outlined" square key={index}>
                <Form className={classes.formProduct}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Select
                                className={clsx('select-bootstrap', 'select-sm', isValidField(errors, 'type') ? 'invalid-field' : '')}
                                value={typeValue}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                options={typeOptions}
                                placeholder="Select"
                                onChange={(e) => onChangeType(e.value, index)}
                                size="select-sm"
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Select
                                className={clsx('select-bootstrap', 'select-sm', isValidField(errors, 'vendor.vendorId') ? 'invalid-field' : '')}
                                value={vendorValue || resetSelect}
                                options={optionVendor}
                                placeholder="Select"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                onChange={(e) => onChangeCompany(e.value, index)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Select
                                className={clsx('select-bootstrap', 'select-sm', isValidField(errors, 'product.productId') ? 'invalid-field' : '')}
                                value={optionValue || resetSelect}
                                options={optionPlan}
                                placeholder="Select"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                onChange={(e) => onChangePlan(e.value, index)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.lastRow}>
                        <Form.Group as={Col}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <InputNumber
                                        className={isValidField(errors, 'price') ? 'invalid-field' : ''}
                                        value={price}
                                        onChange={(value) => onChange('price', value, index)}
                                        placeholder="Price"
                                        thousandSeparator
                                        showCurrency
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <InputNumber
                                        className={isValidField(errors, 'cost') ? 'invalid-field' : ''}
                                        value={cost}
                                        onChange={(value) => onChange('cost', value, index)}
                                        placeholder="Cost"
                                        thousandSeparator
                                        showCurrency
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <InputNumber
                                        placeholder="Term"
                                        thousandSeparator
                                        decimalScale={0}
                                        value={term || ''}
                                        onChange={(value) => onChange('term', value, index)}
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Policy"
                                        maxLength="50"
                                        value={policyNumber || ''}
                                        onChange={(e) => onChange('policyNumber', e.target.value, index)}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col} className={classes.saleLineContainer}>
                            <Tooltip
                                // eslint-disable-next-line max-len
                                title="Checking this box will add the selling price of the product to the price of the vehicle on line 1 of the Retail Installment Contract."
                            >
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            className={classes.productCheckBox}
                                            checked={addToSellingPrice}
                                            color="primary"
                                            onChange={(e) => onChange('addToSellingPrice', e.target.checked, index)}
                                        />
                                    )}
                                    label="Line 1 sale"
                                />
                            </Tooltip>
                            { showRemoveButton && (
                                <div className={classes.deleteIcon}>
                                    <IconButton
                                        size="small"
                                        aria-label="Remove Product"
                                        onClick={() => removeProduct(index)}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Paper>
        );
    }

    renderListFormProduct() {
        const { props: { products } } = this;
        const limitGap = products.filter((x) => x.type === ProductType.GAP).length > 0;
        const limitVsc = products.filter((x) => x.type === ProductType.VSC).length > 0;
        const limitProducts = products.filter((x) => x.type === ProductType.PRODUCT).length === 8;
        const limitAll = (limitGap && limitVsc && limitProducts) || products.length >= 10;

        return map(products, (item, index) => this.renderFormProduct(item, index, {
            limitGap,
            limitVsc,
            limitProducts,
            limitAll,
        }));
    }

    renderActions() {
        const {
            props: {
                classes, onCancel, isReadOnly, addOrEdit, editingStatus,
                products, onSave, editingMode, isSaving, allowSave,
            },
        } = this;

        const { isEditing } = editingStatus;
        const isValidData = isValidSchema(ProductSchema, products);
        const { isValid } = isValidData;

        if (!isReadOnly) {
            return (
                <div>
                    <Button
                        className={classes.textSuccess}
                        size="small"
                        disabled={(!isValid || !allowSave) || isSaving}
                        startIcon={<SaveOutlinedIcon />}
                        onClick={onSave}
                    >
                        Save
                    </Button>
                    <Button
                        size="small"
                        onClick={onCancel}
                        disabled={isSaving}
                        className={classes.textError}
                        startIcon={<HighlightOffIcon />}
                    >
                        Cancel
                    </Button>
                </div>
            );
        }

        if (products.length > 0 && this.SALES_DEAL_PRODUCTS_WRITE) {
            return (
                <Button
                    color="primary"
                    size="small"
                    disabled={(editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.BACK_END}
                    onClick={() => addOrEdit(false)}
                    startIcon={<BorderColorOutlinedIcon />}
                >
                    Edit
                </Button>
            );
        }

        return null;
    }

    render() {
        const {
            classes, addOrEdit, isReadOnly, load, editingMode, products,
            clientId, postedDate, editingStatus,
        } = this.props;
        const { isEditing } = editingStatus;
        const currentBody = isReadOnly ? this.renderListProduct() : this.renderListFormProduct();
        const isNotPosted = postedDate === null;

        return (
            <div className={classes.containerProduct}>
                <div className={classes.header}>
                    <Typography variant="h5">Back End</Typography>
                    {DealUtils.clientIdIsWeb(clientId) && isNotPosted && this.renderActions()}
                </div>
                {!load && <div className={classes.overflowAuto}>{currentBody}</div>}
                {DealUtils.clientIdIsWeb(clientId) && isNotPosted && !load && products.length < 10 && this.SALES_DEAL_PRODUCTS_WRITE && (
                    <div className={classes.flexEnd}>
                        <IconButton
                            size="small"
                            className={classes.buttonAddProduct}
                            aria-label="Add Product"
                            disabled={(editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.BACK_END}
                            onClick={() => addOrEdit(true)}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </div>
                )}
                {load && <Loading />}
                {this.renderConfirmationDialogToRemove()}
            </div>
        );
    }
}

DealBackend.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        price: PropTypes.number,
        type: PropTypes.string,
        cost: PropTypes.number,
        policyNumber: PropTypes.string,
        dealProductId: PropTypes.string,
        addToSellingPrice: PropTypes.bool,
    })).isRequired,
    load: PropTypes.bool,
    open: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    getCurrentPlan: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    addOrEdit: PropTypes.func.isRequired,
    getCurrentCompany: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeType: PropTypes.func.isRequired,
    onOpenDialog: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onChangeCompany: PropTypes.func.isRequired,
    onChangePlan: PropTypes.func.isRequired,
    // General
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    removeProduct: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    allowSave: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    postedDate: PropTypes.string,
    editingStatus: PropTypes.object,
};

DealBackend.defaultProps = {
    isReadOnly: true,
    open: false,
    load: false,
    isSaving: false,
    allowSave: false,
    postedDate: null,
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(DealBackendContainer(DealBackend));

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DealStyles from 'styles/modules/DealStyles';
import { Form } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';

import {
    DialogContent, Dialog, Box,
} from '@material-ui/core';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';

const styles = (theme) => DealStyles.dialogEditfield(theme, fade);

class EditFieldDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.fieldValue,
        };

        this.onChangeValue = this.onChangeValue.bind(this);
    }

    onChangeValue(newValue) {
        this.setState({ value: newValue || 0 });
    }

    render() {
        const {
            props: {
                classes, actionSave, isSaving,
                actionCancel, title,
            },
            state: { value },
        } = this;

        const disableSave = (value === 0);

        return (
            <Dialog
                open
                maxWidth="sm"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={title} onClose={actionCancel} />
                <DialogContent className={classes.dialogContent}>
                    <Form>
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Form.Group className={classes.formStyle}>
                                <InputNumber
                                    value={value}
                                    onChange={(currentValue) => this.onChangeValue(currentValue)}
                                    thousandSeparator
                                    decimalScale={0}
                                    disabled={isSaving}
                                />
                            </Form.Group>
                        </Box>
                    </Form>
                </DialogContent>
                <DialogActions
                    titlePrimary="Save"
                    onClickSecondary={actionCancel}
                    onClickPrimary={() => {
                        actionSave(value);
                    }}
                    disablePrimaryButton={disableSave || isSaving}
                />
            </Dialog>
        );
    }
}

EditFieldDialog.propTypes = {
    title: PropTypes.string.isRequired,
    fieldValue: PropTypes.number.isRequired,
    actionCancel: PropTypes.func.isRequired,
    actionSave: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isSaving: PropTypes.bool,
};

EditFieldDialog.defaultProps = {
    isSaving: false,
};

export default withStyles(styles)(EditFieldDialog);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

// Material UI
import {
    makeStyles,
    CircularProgress,
    TextField,
    Tooltip,
} from '@material-ui/core';

import { useMutation } from '@apollo/client';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';

const useStyle = makeStyles((theme = {}) => ({
    content: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40px',
        maxWidth: '90%',
        minWidth: '90%',
        margin: 'auto',
        backgroundColor: theme.palette.background.white,
        fontSize: '14px',
    },
    circularProgress: {
        position: 'absolute',
        zIndex: 1,
    },
    truncate: {
        maxWidth: '90%',
        minWidth: '90%',
        minHeight: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        borderRadius: '4px',
        border: '2px solid',
        borderColor: theme.palette.border.cadetGrey,
        margin: 'auto',
        padding: '10px 10px',
        fontSize: '12px',
    },
}));

const keyStore = new KeyStore();

const QuickNoteEditable = (props) => {
    const [editable, setEditable] = useState(false);
    const classes = useStyle();
    const {
        dealId,
        note: currentQuickNote,
    } = props;
    const [updateQuickNote, { loading }] = useMutation(DealsMutate.UPDATE_DEAL_QUICKNOTE);
    const onChange = async (id, quickNote) => {
        const newQuickNote = trim(quickNote);

        if (!(StringUtils.isEmpty(newQuickNote) && StringUtils.isEmpty(currentQuickNote)) && newQuickNote !== currentQuickNote) {
            const variables = {
                dealId: id,
                notes: newQuickNote,
            };
            try {
                const response = await updateQuickNote({ variables });

                if (response?.data?.updateNote) {
                    setEditable(false);
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
                }
            } catch (error) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
            }
        } else {
            setEditable(false);
        }
    };
    const keyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();

            onChange(dealId, event.target.value);
        }
    };
    const editQuickNote = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditable(true);
    };
    const writePermission = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);

    if (writePermission && editable) {
        return (
            <div className={classes.content}>
                {loading && (
                    <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                    />
                )}
                {<TextField
                    variant="outlined"
                    size="small"
                    onBlur={(event) => onChange(dealId, event.target.value)}
                    onKeyDown={keyPress}
                    onClick={(event) => { event.stopPropagation(); event.preventDefault(); }}
                    defaultValue={currentQuickNote}
                    autoFocus
                    disabled={loading}
                />}
            </div>
        );
    }

    return (
        <Tooltip placement="bottom" title={currentQuickNote || ''} onClick={editQuickNote}>
            <span className={classes.truncate}>{currentQuickNote}</span>
        </Tooltip>
    );
};

QuickNoteEditable.propTypes = {
    dealId: PropTypes.number.isRequired,
    note: PropTypes.string,
};

QuickNoteEditable.defaultProps = {
    note: '',
};

export default QuickNoteEditable;

export default class ProductSettingsStyles {
    static listStyles(theme = {}) {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flex: 1,
                height: '100%',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                '& div.form-check label': {
                    fontSize: '13px',
                },
            },
            tableHeight: {
                display: 'contents',
            },
            button: {
                color: theme.palette.text.white,
                width: '95px',
                marginRight: '16px',
            },
            newButton: {
                backgroundColor: theme.palette.background.eucalyptus,
                '&:hover': {
                    backgroundColor: theme.palette.background.forestGreen,
                },
            },
            editButton: {
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            removeButton: {
                backgroundColor: '#E97C87',
                '&:hover': {
                    backgroundColor: '#D6727C',
                },
            },
            content: {
                height: '100%',
                flex: 1,
                overflow: 'hidden',
            },
            columnStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            columnLeftStyle: {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left',
            },
            columnRightStyle: {
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
            },
            search: {
                marginRight: theme.spacing(2),
                width: 300,
            },
            checkedGreen: {
                color: theme.palette.success.main,
            },
        });
    }

    static referenceDialog(theme = {}) {
        return ({
            dialog: {
                width: 892,
                '& > header h4': {
                    fontSize: '16px',
                },
            },
            dialogContent: {
                padding: theme.spacing(3),
                '& > div > div > div > div > div, & > div > div > div': {
                    '& > label, & > input, & > div > input, & > div > label, & > div.select-bootstrap, & > div textarea': {
                        '& > span:nth-child(2)': {
                            fontSize: '13px !important',
                        },
                        fontSize: '13px !important',
                    },
                    '& > div.select-bootstrap > div': {
                        minHeight: '30px',
                    },
                },
                '& > div > div > div > div': {
                    '& > label, & > ul > li': {
                        fontSize: '13px !important',
                    },
                },
            },
            labelCheck: {
                marginBottom: 0,
                paddingTop: '10px',
                textWrap: 'nowrap',
            },
            checkBox: {
                fontSize: '16px',
            },
            flexCenter: {
                display: 'flex',
                alignItems: 'center',
            },
            alignCheckBox: {
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
            },
            contractOptions: {
                marginBottom: '15px',
                '& > div:nth-child(1)': {
                    fontSize: '13px',
                    paddingLeft: '18px',
                    marginBottom: '5px',
                    fontWeight: 500,
                },
                '& > div:nth-child(2)': {
                    marginBottom: 0,
                },
            },
            radioGroup: {
                flexDirection: 'row',
            },
            lineOne: {
                '& > div': {
                    paddingLeft: 0,
                },
            },
            editor: {
                '& > div': {
                    border: `1px solid ${theme.palette.border.ghost} !important`,
                    '& > div.jodit-workplace': {
                        fontSize: '13px',
                    },
                },
            },
            checkers: {
                display: 'flex',
                '& > div': {
                    maxWidth: 'initial',
                    flexGrow: 'initial',
                    flexBasis: 'initial',
                },
                '& > div.notRegulated': {
                    width: '150px',
                },
                '& > div.isTaxable, & > div.active': {
                    width: '120px',
                },
            },
            termWrapper: {
                display: 'flex',
                alignItems: 'center',
                '& div.select-bootstrap': {
                    fontSize: '13px',
                    '& > div': {
                        minHeight: 'initial',
                        '& > div:nth-child(2)': {
                            height: '28px',
                        },
                    },
                },
            },
            uploadWrapper: {
                display: 'flex',
                '& > input': {
                    marginRight: '10px',
                },
            },
            dragzone: {
                width: 'auto',
                height: 'auto',
                border: 0,
                borderRadius: 0,
                '& > button': {
                    padding: '6px 8px',
                    backgroundColor: theme.palette.secondaryInfo.main,
                    color: theme.palette.secondaryInfo.contrastText,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: theme.palette.secondaryInfo.dark,
                        boxShadow: 'none',
                    },
                },
            },
            formulaList: {
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: '5px',
                padding: '5px',
                color: '#000000a6',
                fontSize: '15px',
            },
        });
    }
}

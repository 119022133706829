import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import NumberUtils from 'lib/NumberUtils';
import { Grid, Typography } from '@material-ui/core';

// HTTP
import { useQuery, useSubscription } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

import ModalUtils from 'utils/ModalUtils';
import { modules } from 'utils/enum/modules';
import { makeStyles } from '@material-ui/core/styles';
import VehicleImage from 'components/widgets/VehicleImage';
import AvatarWithLabel from 'components/widgets/AvatarWithLabel';
import VehicleWidgetStyles from 'styles/widgets/VehicleWidgetStyles';

const useStyle = makeStyles((theme = {}) => ({
    redLabel: {
        color: theme.palette.text.oldBrick,
    },
    label: {
        color: theme.palette.text.boulder,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '13px',
    },
    ...VehicleWidgetStyles.style(theme),
    root: {
        display: 'flex',
    },
    vehicleImage: {
        height: '50px !important',
        maxHeight: '50px !important',
    },
    employeesContainer: {
        padding: '10px',
    },
    numberBold: {
        fontWeight: 700,
        marginLeft: 0,
        color: theme.palette.text.waterloo,
        fontFamily: 'Arial',
        fontSize: 14,
    },
    vehicle: {
        marginBottom: 2,
        fontWeight: 'bold',
    },
    vehicleDetail: {
        display: 'flex',
        'flex-direction': 'row',
        height: 16,
    },
    deal: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '5px 0',
        minWidth: '150px',
        maxWidth: '150px',
    },
    customers: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '5px 0',
    },
    imageStock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: theme.spacing(1.2),
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    vehicleSection: {
        '& p': {
            lineHeight: '13px',
        },
    },
    vehicleColumn: {
        alignSelf: 'center',
    },
    dealNumber: {
        fontSize: 16,
    },
    lotLabel: {
        fontSize: 13,
        color: theme.palette.text.waterloo,
        fontFamily: 'Arial',
        marginTop: 5,
    },
}));

const ACTION_TYPE = {
    SET_SUMMARY: 'setSummary',
    UPDATE_SUMMARY: 'updateSummary',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_SUMMARY:
    case ACTION_TYPE.UPDATE_SUMMARY:
        return update(state, {
            summary: { $set: { ...action.payload } },
        });
    default:
        return state;
    }
};

const VehicleCard = (props) => {
    const classes = useStyle();
    const {
        vehicle: {
            thumbnail: image,
            style: bodyStyle,
            stockNumber,
            year = '',
            make = '',
            model = '',
            trim = '',
            miles,
        },
    } = props;

    return (
        <div className={clsx(classes.root, classes.vehicleSection)}>
            <div className={classes.imageStock}>
                <div className={`${classes.column} ${classes.image}`}>
                    <VehicleImage
                        image={image}
                        bodyStyle={bodyStyle}
                        className={classes.vehicleImage}
                    />
                </div>
            </div>
            <div className={`${classes.column} ${classes.vehicleInfo}`}>
                <div className={classes.vehicle}>
                    <Typography variant="body1" className={classes.textBuyer}>
                        <a href={`/${modules.INVENTORY}/${stockNumber}`} target="_blank" rel="noopener noreferrer">
                            <span className={classes.numberBold}>{stockNumber}</span>
                        </a>
                    </Typography>
                    <div className={classes.vehicleDetail}>
                        <Typography noWrap variant="body1" className={classes.lineHeight16}>{`${year} - ${make} ${model} - ${trim}`}</Typography>
                    </div>
                </div>
                <div className={classes.thirdRow}>
                    <Typography variant="body1" className={classes.numberBold}>{`${NumberUtils.applyThousandsFormat(miles)} Miles`}</Typography>
                </div>
            </div>
        </div>
    );
};

VehicleCard.propTypes = {
    vehicle: PropTypes.object,
};

VehicleCard.defaultProps = {
    vehicle: {},
};

const DealDetailHeader = (props) => {
    const classes = useStyle();
    const { dealId } = props;

    const [state, dispatch] = useReducer(reducer, {
        summary: {},
    });

    const { data = {}, loading, error } = useQuery(DealsQuery.GET_DEAL_SUMMARY, {
        variables: { dealId },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { data: subscriptionData } = useSubscription(DealSubscription.DEAL_SUMMARY_UPDATED, { variables: { dealId } });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getDealSummary: result = {} } = data;
            dispatch({ type: ACTION_TYPE.SET_SUMMARY, payload: result });
        }
    }, [data, loading, error]);

    useEffect(() => {
        const result = subscriptionData?.dealSummaryUpdated;
        if (!isEmpty(result)) {
            dispatch({ type: ACTION_TYPE.UPDATE_SUMMARY, payload: result });
        }
    }, [subscriptionData]);

    const { summary } = state;

    const {
        accountNumber,
        lotName, status,
        buyer, coBuyer,
        vehicle = {},
        salesPerson1, salesPerson2,
        bdcAgent, salesManager,
        financeManager,
    } = summary;

    return !loading ? (
        <div className={classes.root}>
            <div className={clsx(classes.deal)}>
                <p className={clsx('text-primary', classes.dealNumber)}>{`Deal ${accountNumber}`}</p>
                <p className={classes.lotLabel}>{`${lotName} ${status}`}</p>
            </div>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={2} className={classes.customers}>
                    <Typography variant="body1" className={classes.numberBold}>{`${buyer?.firstName || ''} ${buyer?.lastName || ''}`}</Typography>
                    <Typography variant="body1" className={classes.numberBold}>{`${coBuyer?.firstName || ''} ${coBuyer?.lastName || ''}`}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.vehicleColumn}>
                    <VehicleCard
                        vehicle={vehicle}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid orientation="horizontal" item xs={5}>
                    <Grid container className={classes.employeesContainer}>
                        <Grid item xs={4}>
                            <AvatarWithLabel person={salesPerson1} />
                            <AvatarWithLabel person={salesPerson2} />
                        </Grid>
                        <Grid item xs={4}>
                            <AvatarWithLabel person={bdcAgent} />
                            <AvatarWithLabel person={salesManager} />
                        </Grid>
                        <Grid item xs={4}>
                            <AvatarWithLabel person={financeManager} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

DealDetailHeader.propTypes = {
    dealId: PropTypes.number.isRequired,
};

export default DealDetailHeader;

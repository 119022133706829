import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { Photo as PhotoIcon } from '@material-ui/icons';
import KeyStore from 'utils/KeyStore';
import { modules } from 'utils/enum/modules';
import Permission from 'utils/enum/Permissions';
import { makeStyles } from '@material-ui/core/styles';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import Container from 'components/widgets/Container';
import CustomFieldsSettings from 'components/modules/settings/inventory/CustomFieldsSettings';
import TagsSettings from 'components/modules/settings/inventory/TagsSettings';
import ReconSettings from 'components/modules/settings/inventory/ReconSettings';
import MarketCompsSettings from 'components/modules/settings/inventory/MarketCompsSettings';
import AdvertisingSettings from 'components/modules/settings/inventory/AdvertisingSettings';
import DescriptionSettings from 'components/modules/settings/inventory/DescriptionSettings';
import GeneralSettings from 'components/modules/settings/inventory/GeneralSettings';

// Icons
import AppsIcon from '@material-ui/icons/Apps';
import LocalCarWashOutlinedIcon from '@material-ui/icons/LocalCarWashOutlined';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['general', 'custom-fields', 'advertising', 'tags', 'recon', 'market-comps', 'description'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    const INVENTORY_SETTINGS_READ = keyStore.hasPermission(Permission.INVENTORY_SETTINGS_READ);
    const INVENTORY_SETTINGS_WRITE = keyStore.hasPermission(Permission.INVENTORY_SETTINGS_WRITE);
    const INVENTORY_SETTINGS_TAG_ADD = keyStore.hasPermission(Permission.INVENTORY_SETTINGS_TAG_ADD);
    const INVENTORY_SETTINGS_TAG_DELETE = keyStore.hasPermission(Permission.INVENTORY_SETTINGS_TAG_DELETE);
    const RECON_SETTINGS_READ = keyStore.hasPermission(Permission.RECON_SETTINGS_READ);
    const RECON_SETTINGS_WRITE = keyStore.hasPermission(Permission.RECON_SETTINGS_WRITE);

    if (INVENTORY_SETTINGS_READ) {
        settings.push(
            {
                index: 0,
                text: 'General',
                icon: <AppsIcon />,
                component: <GeneralSettings canWrite={INVENTORY_SETTINGS_WRITE} />,
            },
            {
                index: 1,
                text: 'Custom Fields',
                icon: <InputOutlinedIcon />,
                component: <CustomFieldsSettings writable={INVENTORY_SETTINGS_WRITE} />,
            },
            {
                index: 2,
                text: 'Advertising',
                icon: <PhotoIcon />,
                component: <AdvertisingSettings
                    canWrite={INVENTORY_SETTINGS_WRITE}
                />,
            },
            {
                index: 3,
                text: 'Tags',
                icon: <LocalOfferOutlinedIcon />,
                component: <TagsSettings
                    canAdd={INVENTORY_SETTINGS_TAG_ADD}
                    canDelete={INVENTORY_SETTINGS_TAG_DELETE}
                />,
            },
            RECON_SETTINGS_READ ? {
                index: 4,
                text: 'Recon',
                icon: <LocalCarWashOutlinedIcon />,
                component: <ReconSettings
                    canWrite={RECON_SETTINGS_WRITE}
                />,
            } : null,
            {
                index: 5,
                text: 'Market Comps',
                icon: <MonetizationOnOutlinedIcon />,
                component: <MarketCompsSettings
                    canWrite={INVENTORY_SETTINGS_WRITE}
                />,
            },
            {
                index: 6,
                text: 'Description',
                icon: <DescriptionOutlinedIcon />,
                component: <DescriptionSettings
                    canWrite={INVENTORY_SETTINGS_WRITE}
                />,
            },
        );
    }

    return settings.filter((setting) => setting !== null);
};

const InventorySettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.SETTINGS}/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();
    return (
        <>
            <Container className={classes.rootContainer}>
                <List component="nav" className={classes.sideBar}>
                    {settingsItems.map((item) => (
                        <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.content}>
                    {settingsItems.map((item) => (
                        activeIndex === item.index && <div key={item.index}>{item.component}</div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default InventorySettings;

import { gql } from '@apollo/client';

export default class LeadQuery {
    static GET_LEADS_BY_CRM_ID = gql`
        query getLeadsByCRMId($crmId: ID!){
            getLeadsByCRMId(crmId: $crmId) {
                leadId
                leadType
                source
                createdOn
                modifiedOn
                vehicle {
                    leadVehicleId
                    stockNumber
                    year
                    make
                    model
                    trim
                    color
                    inventory {
                        thumbnail
                    }
                }
            }
        }
    `;

    static GET_LEADS_BY_CUSTOMER_ID = gql`
        query getLeadsByCustomerId($customerId: ID!){
            getLeadsByCustomerId(customerId: $customerId) {
                leadId
                leadType
                source
                createdOn
                modifiedOn
                vehicle {
                    leadVehicleId
                    stockNumber
                    year
                    make
                    model
                    trim
                    color
                    inventory {
                        thumbnail
                    }
                }
            }
        }
    `;
}

import * as yup from 'yup';
import { isEmpty } from 'lodash';
import PayrollUtils from 'utils/PayrollUtils';
import DateUtils from 'lib/DateUtils';

const testTimeOut = ({ parent }) => {
    const {
        timeInDate, timeInHour, openEndedDate,
        timeInMinute, timeInAmPm, timeOutDate,
        timeOutHour, timeOutMinute, timeOutAmPm,
    } = parent;

    if (openEndedDate) return true;
    const startDate = PayrollUtils.getParsedDate({
        dateOnly: timeInDate,
        hour: timeInHour,
        minutes: timeInMinute,
        amPm: timeInAmPm,
    });
    const endDate = PayrollUtils.getParsedDate({
        dateOnly: timeOutDate,
        hour: timeOutHour,
        minutes: timeOutMinute,
        amPm: timeOutAmPm,
    });
    if (DateUtils.isSameOrBefore(startDate, endDate)) return true;
    return false;
};

const EmployeeHoursSchema = yup.object().shape({
    timeInHour: yup
        .number()
        .positive()
        .min(1)
        .max(12)
        .required(),
    timeInAmPm: yup
        .string()
        .required(),
    timeInMinute: yup
        .number()
        .positive()
        .min(0)
        .max(59)
        .required(),
    timeInReason: yup
        .string()
        .required(),
    timeInDate: yup
        .date()
        .required(),
    timeOutDate: yup.date().test({
        name: 'time out date validation',
        message: 'Time out date invalid.',
        test() {
            return testTimeOut({ parent: this.parent });
        },
    }).nullable(),
    timeOutHour: yup.number().test({
        name: 'time out hour validation',
        message: 'Time out hour invalid.',
        test() {
            return testTimeOut({ parent: this.parent });
        },
    }).nullable(),
    timeOutMinute: yup.number().test({
        name: 'time out minutes validation',
        message: 'Time out minutes invalid.',
        test() {
            return testTimeOut({ parent: this.parent });
        },
    }).nullable(),
    timeOutAmPm: yup.string().test({
        name: 'time out pre/post meridiem validation',
        message: 'Time out pre/post meridiem invalid.',
        test() {
            return testTimeOut({ parent: this.parent });
        },
    }).nullable(),
    timeOutReason: yup.string().test({
        name: 'time out reason validation',
        message: 'Time out reason invalid.',
        test(value) {
            const { openEndedDate } = this.parent;

            if (openEndedDate) return true;
            return !isEmpty(value);
        },
    }).nullable(),
});

export default EmployeeHoursSchema;

/* eslint-disable no-restricted-globals */
import { GPS_COMMAND_URL, GPS_COMMAND } from 'utils/enum/GPSEnum';

export default {
    getCurrentIntegrationId(gpsCompany, providers, integrations) {
        const provider = providers.find((x) => x.name === gpsCompany);
        if (!provider) return null;

        return integrations.find((x) => x.integrationId === provider.id)?.id;
    },
    getCommandDropdownOptions() {
        return [
            {
                value: GPS_COMMAND.REQUEST_LOCATION,
                label: 'Locate Device',
            },
            {
                value: GPS_COMMAND.TONE_1_ON,
                label: 'Tone 1 On',
            },
            {
                value: GPS_COMMAND.TONE_2_ON,
                label: 'Tone 2 On',
            },
            {
                value: GPS_COMMAND.TONE_3_ON,
                label: 'Tone 3 On',
            },
            {
                value: GPS_COMMAND.TONE_OFF,
                label: 'Tone Off',
            },
            {
                value: GPS_COMMAND.DISABLE_STARTER_INTERRUPT,
                label: 'Enable Vehicle',
            },
            {
                value: GPS_COMMAND.ENABLE_STARTER_INTERRUPT,
                label: 'Disable Vehicle',
            },
            {
                value: GPS_COMMAND.REQUEST_LOCATION_HISTORY,
                label: 'Request Location History',
            },
            {
                value: GPS_COMMAND.REQUEST_COMMAND_HISTORY,
                label: 'Request Command History',
            },
            {
                value: GPS_COMMAND.GET_BATTERY_INFO,
                label: 'Get Battery Info',
            },
            {
                value: GPS_COMMAND.SET_ENDURANCE_MODE,
                label: 'Set Endurance Mode',
            },
            {
                value: GPS_COMMAND.SET_TRIP_MODE,
                label: 'Set Trip Mode',
            },
            {
                value: GPS_COMMAND.SET_ACTIVE_MODE,
                label: 'Set Active Mode',
            },
            {
                value: GPS_COMMAND.SET_RECOVERY_MODE,
                label: 'Set Recovery Mode',
            },
        ];
    },
    getCommandsLabelByUrl() {
        return [
            {
                url: GPS_COMMAND_URL.VALIDATE_DEVICE,
                label: 'Validate Device',
            },
            {
                url: GPS_COMMAND_URL.GET_LOCALTION_HISTORY,
                label: 'Request Location History',
            },
            {
                url: GPS_COMMAND_URL.GET_LOCATION,
                label: 'Locate Device',
            },
            {
                url: GPS_COMMAND_URL.GET_COMMAND_HISTORY,
                label: 'Request Command History',
            },
            {
                url: GPS_COMMAND_URL.SET_WARNING_TONE,
                label: 'Tone ~number~ On',
                enable: true,
            },
            {
                url: GPS_COMMAND_URL.SET_WARNING_TONE,
                label: 'Tone Off',
                enable: false,
            },
            {
                url: GPS_COMMAND_URL.SET_STARTER_INTERRUPT,
                label: 'Enable Starter Interrupt',
                enable: true,
            },
            {
                url: GPS_COMMAND_URL.SET_STARTER_INTERRUPT,
                label: 'Disable Starter Interrupt',
                enable: false,
            },
            {
                url: GPS_COMMAND_URL.GET_ENCORE_BATTERY_NOTIFICATION,
                label: 'Battery Info',
                enable: false,
            },
        ];
    },
    getIntegrationConfiguration(providers, integrations, gpsCompany) {
        if (!gpsCompany) return {};

        const currentProvider = providers.find((x) => x.name === gpsCompany) ?? {};
        const currentIntegration = integrations.find((x) => x.integrationId === currentProvider.id) ?? {};

        const {
            commandHistory,
            getBatteryInfo,
            setEnduranceMode,
            setTripMode,
            setActiveMode,
            setRecoveryMode,
            hasDeviceType,
        } = currentProvider?.configuration ?? {};

        return {
            ...(currentIntegration?.configuration ?? {}),
            commandHistory,
            getBatteryInfo,
            setEnduranceMode,
            setTripMode,
            setActiveMode,
            setRecoveryMode,
            hasDeviceType,
        };
    },
    getErrorMessageByCode(code, message, provider) {
        const errorCode = isNaN(code) ? 0 : parseInt(code, 10);
        switch (errorCode) {
        case 401:
            return `We're unable to complete your request a this time. Please validate credentials for ${provider} and try again.`;
        case 404:
            return 'Invalid device.';
        case 500:
            return 'Internal Server Error.';
        default:
            return message;
        }
    },
};

export const INITIAL_STATE = {
    currentColumns: [],
    availableColumns: [],
    selectedCurrentColumn: '',
    selectedAvailableColumn: '',
    isSaving: false,
};

export const ACTION_TYPES = {
    SET_COLUMNS_DATA: 'setColumnsData',
    TOGGLE_IS_SAVING: 'toggleIsSaving',
};

const TableSettingsReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_COLUMNS_DATA: {
        const {
            currentColumns,
            availableColumns,
            selectedCurrentColumn,
            selectedAvailableColumn,
        } = action.value;

        return {
            ...state,
            currentColumns,
            availableColumns,
            selectedCurrentColumn,
            selectedAvailableColumn,
        };
    }
    case ACTION_TYPES.TOGGLE_IS_SAVING: {
        return {
            ...state,
            isSaving: !state.isSaving,
        };
    }
    default:
        return state;
    }
};

export default TableSettingsReducer;

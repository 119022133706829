import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import { useLazyQuery } from '@apollo/client';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { isEmpty } from 'lodash';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    SET_TABLE: 'setTable',
    SET_PAGING: 'setPaging',
    SET_INITIAL_STATE: 'setInitialState',
    LOAD_MORE: 'setLoadMore',
};

const cashierPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE:
        return { ...state, table: action.value };
    case ACTION_TYPES.SET_PAGING:
        return { ...state, paginate: { ...state.paginate, init: action.value } };
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    default:
        return state;
    }
};

const keyStore = new KeyStore();

const CashierOpenCollections = (props) => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES);

    const classes = useStyle();

    const {
        userName, direction, date,
    } = props;

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
                ignoreLimit: true,
            },
            search: null,
        },
        table: {
            records: [],
            totalCount: 0,
        },
    };

    const [state, dispatch] = useReducer(cashierPaymentReducer, initState);
    const { params, table } = state;

    const [getData, { loading }] = useLazyQuery(AccountReceivableQuery.GET_CASHIER_COLLECIONS, {
        onCompleted: (res) => {
            if (res.getCashierOpenBalances) {
                const { getCashierOpenBalances } = res;
                const mapRecords = AccountReceivablesMapper.mapCashierCollections(getCashierOpenBalances.data);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: [...mapRecords],
                        totalCount: getCashierOpenBalances.totalCount,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (userName && !isEmpty(userName)) {
            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records: [], totalCount: 0,
                },
            });

            getData({
                variables: {
                    paginate: params.paginate,
                    filter: {
                        userName,
                        direction,
                        search: params.search,
                        onlyActive: true,
                        date,
                    },
                    sort: [
                        { fieldName: 'dateNTime', dir: DataSort.DESC },
                        { fieldName: 'TransactionNumber', dir: DataSort.DESC }],
                },
            });
        }
    }, [userName, direction, getData, params.paginate, params.search, date]);

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Receipt #',
            minWidth: 90,
            width: 90,
            id: 'TransactionNumber',
            accessor: 'transactionNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Account #',
            minWidth: 90,
            width: 90,
            id: 'accountNumber',
            accessor: 'accountNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Date',
            minWidth: 130,
            width: 130,
            id: 'Date',
            accessor: 'date',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
        },
        {
            Header: 'Customer',
            minWidth: 160,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Reason',
            minWidth: 150,
            id: 'Reason',
            accessor: 'reason',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Amount',
            minWidth: 100,
            width: 100,
            id: 'Amount',
            accessor: 'amount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Memo',
            minWidth: 200,
            id: 'Memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
    ];

    return (
        <>
            {ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES && (
                <Table
                    rowSelected
                    cursor="default"
                    load={loading}
                    loadMore={loadMore}
                    totalRecords={table.totalCount}
                    data={loading ? [] : table?.records}
                    columns={columns}
                    className="-highlight"
                />
            )}
            {!ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES
        && (
            <>
                <div className={classes.main}>
                    <Table
                        rowSelected
                        cursor="default"
                        load={loading}
                        loadMore={loadMore}
                        totalRecords={table.totalCount}
                        data={loading ? [] : table?.records}
                        columns={columns}
                        className="-highlight"
                    />
                </div>
            </>
        ) }
        </>
    );
};

CashierOpenCollections.propTypes = {
    userName: PropTypes.string,
    direction: PropTypes.string,
    date: PropTypes.string,
};

CashierOpenCollections.defaultProps = {
    userName: '',
    direction: '',
    date: null,
};

export default CashierOpenCollections;

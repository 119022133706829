import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import Placeholder from 'components/widgets/Placeholder';

// Components and Others
import PropTypes from 'prop-types';
import DealWidgetItem from 'components/widgets/deals/DealWidgetItem';

// HTTP
import { useQuery } from '@apollo/client';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyle = makeStyles(() => ({
    container: {
        overflow: 'auto',
    },
}));

const DealWidget = ({ customerId }) => {
    const classes = useStyle();
    const [records, setRecords] = useState([]);
    const { data, loading, error } = useQuery(DealsQuery.GET_DEALS_BY_CUSTOMER, {
        variables: { customerId, paging: { limit: 5 } },
        skip: (customerId == null),
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        // TODO: Find a better way to prevent the execution
        if (customerId != null) {
            if (!loading && !error) {
                setRecords(data?.getDealsByCustomer);
            } else if (error) {
                ModalUtils.errorMessage(error?.graphQLErrors);
            }
        }
    }, [data, loading, error, customerId]);

    return (
        <BoxWidget
            title={`Related Deals (${records.length})`}
            minHeight="auto"
        >
            {records.length > 0 ? (
                <div className={classes.container}>
                    {records.map((record, index) => <DealWidgetItem key={index} {...record} />)}
                </div>
            ) : <Placeholder text="No Deals" />}
        </BoxWidget>
    );
};

DealWidget.defaultProps = {
    customerId: null,
};

DealWidget.propTypes = {
    customerId: PropTypes.number,
};

export default DealWidget;

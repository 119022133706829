// TODO: Remove underscore when api update field
const MessageType = {
    QUICK_REPLAY: 'QUICK REPLY',
    CARD: 'CARD',
    TEXT: 'TEXT',
    JOINED_AGENT: 'JOINED_AGENT',
    DATETIME_PICKER: 'DATETIME_PICKER',
};

const ConversationStatus = {
    NEW: 'NEW',
    WAITING_FOR_AGENT: 'WAITING FOR AGENT',
    ANSWERED: 'ANSWERED',
};

const CustomerName = {
    ANONYMOUS: 'ANONYMOUS',
};

export { CustomerName, MessageType, ConversationStatus };

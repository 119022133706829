import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import PhoneNumberUtil from 'lib/PhoneUtils';
import { withRouter } from 'react-router-dom';
import Header from 'components/widgets/Header';
import Select from 'components/widgets/Select';
import Search from 'components/widgets/Search';
import SearchIcon from '@material-ui/icons/Search';
import If from 'components/widgets/conditional/If';
import DealStyles from 'styles/modules/DealStyles';
import Container from 'components/widgets/Container';
import BackButton from 'components/widgets/BackButton';
import CardVehicle from 'components/widgets/CardVehicle';
import CreateCustomer from 'components/modules/deals/create/dealTab/CreateCustomer';
import AddressSection from 'components/widgets/customer/AddressSection';
import NeoCustomer from 'components/modules/deals/create/dealTab/NeoCustomer';
import CRMCustomer from 'components/modules/deals/create/dealTab/CRMCustomer';
import PaperAddInformation from 'components/widgets/form/PaperAddInformation';
import VehicleDialog from 'components/modules/deals/read/vehicle/VehicleDialog';
import Employment from 'components/modules/customer/read/personalTab/Employment';
import CustomerDialog from 'components/modules/customer/read/CustomerDialog';
import CustomerInformation from 'components/modules/deals/read/buyer/CustomerInformation';
import EditCustomerDialog from 'components/widgets/customer/EditCustomerDialog';
import DealCreateContainer from 'components/containers/deals/create/dealTab/DealCreateContainer';
import DealStructureDealCreate from 'components/modules/deals/create/dealTab/DealStructureDealCreate';

// Material UI
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import { withStyles, fade } from '@material-ui/core/styles';
import {
    Button, Typography, Grid, Divider,
} from '@material-ui/core';
import { CustomerType, ImportCustomer } from 'utils/enum/DealEnum';
import { isEmpty } from 'lodash';
import { isValidField } from 'utils/schema/utils';

const styles = (theme) => ({
    ...DealStyles.dealCreate(theme, fade),
    title: {
        color: theme.palette.text.stormGray,
        padding: theme.spacing(1.4),
        display: 'inline-block',
    },
    searchBox: {
        display: 'inline-block',
        float: 'right',
    },
    overrideInput: {
        borderRadius: '0.2rem',
    },
});

class DealCreate extends Component {
    renderBoxCoBuyer() {
        const {
            coBuyer,
            toggleModal,
            toggleEditCustomer,
            classes,
        } = this.props;
        const currentCoBuyerName = `${coBuyer.firstName || ''} ${coBuyer.lastName || ''}`;

        if (coBuyer?.customerCode) {
            return (
                <Grid container spacing={0} className={classes.personalInformation}>
                    <Grid item xs={12} className="gridItem">
                        <CustomerInformation
                            title="Co Buyer"
                            customerName={currentCoBuyerName}
                            email={coBuyer.email}
                            cellPhone={PhoneNumberUtil.formatPhone(coBuyer.cellPhone) || ''}
                            homePhone={PhoneNumberUtil.formatPhone(coBuyer.homePhone) || ''}
                            workPhone={PhoneNumberUtil.formatPhone(coBuyer.workPhone) || ''}
                            onSearchCustomer={() => toggleModal(CustomerType.CO_BUYER)}
                            type={CustomerType.CO_BUYER}
                            toggleEditCustomer={() => toggleEditCustomer(coBuyer)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <AddressSection
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            customerId={coBuyer.customerId}
                            addressInformation={coBuyer.currentAddress}
                            showAddButton={isEmpty(coBuyer.currentAddress)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <Employment
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            showAddButton={isEmpty(coBuyer.currentEmployment)}
                            customerId={coBuyer.customerId}
                            record={coBuyer.currentEmployment}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <PaperAddInformation
                label="Add Co Buyer"
                icon={<SearchIcon />}
                onClickLeftLabel={() => toggleModal(CustomerType.CO_BUYER)}
            />
        );
    }

    renderBoxBuyer() {
        const {
            props: {
                buyer,
                toggleModal,
                toggleEditCustomer,
                classes,
            },
        } = this;
        const currentBuyerName = `${buyer.firstName || ''} ${buyer.lastName || ''}`;

        if (buyer?.customerCode) {
            return (
                <Grid container spacing={0} className={classes.personalInformation}>
                    <Grid item xs={12} className="gridItem">
                        <CustomerInformation
                            title="Buyer"
                            customerName={currentBuyerName}
                            email={buyer.email}
                            cellPhone={PhoneNumberUtil.formatPhone(buyer.cellPhone) || ''}
                            homePhone={PhoneNumberUtil.formatPhone(buyer.homePhone) || ''}
                            workPhone={PhoneNumberUtil.formatPhone(buyer.workPhone) || ''}
                            onSearchCustomer={() => toggleModal(CustomerType.BUYER)}
                            type={CustomerType.BUYER}
                            toggleEditCustomer={() => toggleEditCustomer(buyer)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <AddressSection
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            customerId={buyer.customerId}
                            addressInformation={buyer.currentAddress}
                            showAddButton={isEmpty(buyer.currentAddress)}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <Employment
                            showNoDataText
                            labelManage="Edit"
                            addIcon={<AddCircleOutlineIcon />}
                            showAddButton={isEmpty(buyer.currentEmployment)}
                            customerId={buyer.customerId}
                            record={buyer.currentEmployment}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <PaperAddInformation
                label="Add Buyer"
                icon={<SearchIcon />}
                onClickLeftLabel={() => toggleModal(CustomerType.BUYER)}
            />
        );
    }

    renderBoxVehicle() {
        const {
            classes, isSelectedVehicle, vehicle, getVehicles,
        } = this.props;
        const item = {
            stock: vehicle.stockNumber,
            ...vehicle,
        };
        const title = isSelectedVehicle ? 'Vehicle' : 'Add Vehicle';

        return (
            <div className={clsx(classes.boxVehicle, 'box-shadow')}>
                <div>
                    <Typography variant="h5" className={classes.title}>{title}</Typography>
                    <div className={classes.searchBox}>
                        <Search
                            autoFocus
                            fullWidth
                            type="text"
                            placeholder="Search Inventory"
                            onKeyDown={getVehicles}
                            overrideStyle={{ overrideInput: classes.overrideInput }}
                        />
                    </div>
                </div>
                <If condition={isSelectedVehicle}>
                    <CardVehicle
                        vehicle={item}
                        className="vehicle-item"
                    />
                </If>
            </div>
        );
    }

    renderGeneralInformationByDeals() {
        const {
            props: {
                onChangeValueDealStructure, classes, vendorList, onChangeDealType,
                dealStructure, openDeferredDownPayment, deferredDownPayment,
                onOpenDeferredDownPayment, onCloseDeferredDownPayment, userAvailableLots,
                setDeferredDownPayment, vehicle, isValidSalesPrice, formValidations, portfolioList,
            },
        } = this;

        return (
            <Grid container spacing={2} className={classes.containerCard}>
                <Grid item xs={4} className="d-flex-column">
                    {this.renderBoxVehicle()}
                    <DealStructureDealCreate
                        vehicle={vehicle}
                        vendorList={vendorList}
                        portfolioList={portfolioList}
                        dealStructure={dealStructure}
                        formValidations={formValidations}
                        onChangeDealType={onChangeDealType}
                        isValidSalesPrice={isValidSalesPrice}
                        userAvailableLots={userAvailableLots}
                        deferredDownPayment={deferredDownPayment}
                        setDeferredDownPayment={setDeferredDownPayment}
                        openDeferredDownPayment={openDeferredDownPayment}
                        onOpenDeferredDownPayment={onOpenDeferredDownPayment}
                        onCloseDeferredDownPayment={onCloseDeferredDownPayment}
                        onChangeValueDealStructure={onChangeValueDealStructure}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.renderBoxBuyer()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.renderBoxCoBuyer()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderLeftSectionHeader() {
        const {
            props: {
                isSelectClient, classes, cancelCreateDeal, onSave, saving, formValidations,
            },
        } = this;

        const { isValid } = formValidations;

        if (isSelectClient) {
            return (
                <>
                    <Button
                        variant="contained"
                        className={clsx(classes.button, classes.containedSecondaryInfo)}
                        onClick={onSave}
                        disabled={!isValid || saving}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        className={clsx(classes.button)}
                        onClick={cancelCreateDeal}
                    >
                        Cancel
                    </Button>
                </>
            );
        }

        return <Typography variant="h5" className={classes.textGray}>New Deal</Typography>;
    }

    renderImport() {
        const {
            toggleModal, onDoubleClick, open, customerTag, toggleCreateCustomer,
        } = this.props;

        switch (customerTag) {
        case ImportCustomer.CRM:
            return <CRMCustomer open={open} toggleModal={toggleModal} onSelectRow={onDoubleClick} />;
        case CustomerType.BUYER:
        case CustomerType.CO_BUYER:
            // eslint-disable-next-line max-len
            return <CustomerDialog open={open} toggleModal={toggleModal} onSelectRow={onDoubleClick} customerTag={customerTag} toggleCreateCustomerPanel={() => { toggleModal(); toggleCreateCustomer(customerTag); }} />;
        case ImportCustomer.NEO:
            return <NeoCustomer open={open} toggleModal={toggleModal} onSelectRow={onDoubleClick} />;
        case ImportCustomer.MAGILOOP:
            return <NeoCustomer title={`${ImportCustomer.MAGILOOP} Customer Lookup`} open={open} toggleModal={toggleModal} onSelectRow={onDoubleClick} />;
        default:
            return null;
        }
    }

    render() {
        const {
            classes, open, openCreateCustomer, openDialogVehicle, openEditCustomer, toggleModalVehicle, dealStructure, onDoubleClick, formValidations,
            onDoubleClickVehicle, toggleModal, toggleCreateCustomer, toggleEditCustomer, leadSourceList, onChangeValueDealStructure, recordToEdit,
            vehicleSearchData,
        } = this.props;

        const { errors } = formValidations;

        return (
            <Container className="floatBox">
                <Header>
                    <div className="d-flex-justify-center-align-center">
                        <BackButton route="/deals" />
                        {this.renderLeftSectionHeader()}
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            onClick={() => toggleModal(ImportCustomer.CRM)}
                            className={clsx(classes.outlinedInfo, classes.importButton)}
                        >
                            Import from CRM
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => toggleModal(ImportCustomer.NEO)}
                            className={clsx(classes.outlinedInfo, classes.importButton)}
                        >
                            Import from Neo
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => toggleModal(ImportCustomer.MAGILOOP)}
                            className={clsx(classes.outlinedInfo, classes.importButton)}
                        >
                            Import from MagiLoop
                        </Button>
                    </div>
                    <div />
                </Header>
                <Container className={classes.boxContainerDetails}>
                    <div className={clsx(classes.dealCreateContainer, 'box-shadow')}>
                        <div className={classes.dealCreateHeader}>
                            <Form.Group className="full-width-date-picker">
                                <Form.Label className={classes.labelColor}>Lead ID:</Form.Label>
                                <Typography className={classes.leadId}>None</Typography>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className={classes.labelColor}>Lead Source:</Form.Label>
                                <Select
                                    className={clsx('select-bootstrap', isValidField(errors, 'leadSource') ? 'invalid-field' : '')}
                                    name="leadSource"
                                    placeholder="Select"
                                    size="sm"
                                    value={dealStructure?.leadSource}
                                    options={leadSourceList}
                                    onChange={onChangeValueDealStructure}
                                />
                            </Form.Group>
                        </div>
                        <Divider />
                        {this.renderGeneralInformationByDeals()}
                    </div>
                    {open && this.renderImport()}
                    {
                        openDialogVehicle
                        && (
                            <VehicleDialog
                                toggleModal={toggleModalVehicle}
                                open={openDialogVehicle}
                                onSelectVehicle={onDoubleClickVehicle}
                                vehicleSearchData={vehicleSearchData}
                                includeDefaultLot={false}
                            />
                        )
                    }
                    {openCreateCustomer && <CreateCustomer toggleCreateCustomer={toggleCreateCustomer} onSetCustomer={onDoubleClick} />}
                    {openEditCustomer && <EditCustomerDialog open={openEditCustomer} record={recordToEdit} toggleModal={toggleEditCustomer} />}
                </Container>
            </Container>
        );
    }
}

DealCreate.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    isSelectClient: PropTypes.bool.isRequired,
    customerTag: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    coBuyer: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        customerCode: PropTypes.number,
        customerId: PropTypes.string,
        currentAddress: PropTypes.object,
        currentEmployment: PropTypes.object,
    }).isRequired,
    buyer: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        customerCode: PropTypes.number,
        customerId: PropTypes.string,
        currentAddress: PropTypes.object,
        currentEmployment: PropTypes.object,
    }).isRequired,
    openDialogVehicle: PropTypes.bool.isRequired,
    toggleModalVehicle: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    isSelectedVehicle: PropTypes.bool.isRequired,
    onDoubleClickVehicle: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({
        stockNumber: PropTypes.number,
        year: PropTypes.string,
        make: PropTypes.string,
        model: PropTypes.string,
        trim: PropTypes.string,
        thumbnail: PropTypes.string,
        minimumPrice: PropTypes.number,
    }).isRequired,
    cancelCreateDeal: PropTypes.func.isRequired,
    onChangeValueDealStructure: PropTypes.func.isRequired,
    dealStructure: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    openDeferredDownPayment: PropTypes.bool.isRequired,
    onOpenDeferredDownPayment: PropTypes.func.isRequired,
    onCloseDeferredDownPayment: PropTypes.func.isRequired,
    deferredDownPayment: PropTypes.arrayOf(PropTypes.object).isRequired,
    setDeferredDownPayment: PropTypes.func.isRequired,
    isValidSalesPrice: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    leadSourceList: PropTypes.array.isRequired,
    formValidations: PropTypes.object.isRequired,
    toggleCreateCustomer: PropTypes.func.isRequired,
    openCreateCustomer: PropTypes.bool.isRequired,
    vendorList: PropTypes.array.isRequired,
    portfolioList: PropTypes.array.isRequired,
    onChangeDealType: PropTypes.func.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    openEditCustomer: PropTypes.bool.isRequired,
    userAvailableLots: PropTypes.array.isRequired,
    recordToEdit: PropTypes.object,
    getVehicles: PropTypes.func.isRequired,
    vehicleSearchData: PropTypes.object,
};

DealCreate.defaultProps = {
    recordToEdit: null,
    vehicleSearchData: null,
};

export default withRouter(withStyles(styles)(DealCreateContainer(DealCreate)));

import React from 'react';

// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => createStyles({
    box: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    label: {
        width: ({ labelWidth }) => labelWidth,
        textAlign: 'right',
        marginRight: theme.spacing(1),
        lineHeight: '20px',
        fontWeight: 500,
        fontSize: 14,
    },
    value: {
        flex: 1,
        lineHeight: '20px',
        fontSize: 14,
        fontWeight: 500,
    },
    valueList: {
        flex: 1,
        color: theme.palette.text.gray,
        listStyle: 'none',
    },
    multipleValuesBox: {
        display: 'flex',
    },
    boxAddress: {
        marginTop: '3px',
    },
    noWrap: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    primary: {
        color: theme.palette.text.minsk,
    },
    gray: {
        color: theme.palette.text.gray,
    },
    green: {
        color: theme.palette.text.fruitSalad,
    },
}));

const LabelValue = ({
    label, value, labelClass, labelWidth, className,
    labelColor, noWrap, valueColor, valueClass,
}) => {
    const classes = useStyles({ labelWidth, labelColor, valueColor });

    return (
        <div className={clsx(classes.box, className)}>
            <dt className={clsx(labelClass, classes.label, {
                [classes[labelColor]]: labelColor,
            })}
            >
                {`${label}:`}
            </dt>
            <dd className={clsx(valueClass, classes.value, { [classes.noWrap]: noWrap, [classes[valueColor]]: valueColor })}>{value}</dd>
        </div>
    );
};

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
        PropTypes.node,
    ]),
    noWrap: PropTypes.bool,
    labelColor: PropTypes.oneOf(['primary', 'gray', 'green']),
    valueColor: PropTypes.oneOf(['primary', 'gray', 'green']),
    labelClass: PropTypes.string,
    valueClass: PropTypes.string,
    labelWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    className: PropTypes.string,
};

LabelValue.defaultProps = {
    value: '',
    labelClass: '',
    valueClass: '',
    labelWidth: 110,
    labelColor: 'primary',
    valueColor: 'gray',
    noWrap: true,
    className: '',
};

export default LabelValue;

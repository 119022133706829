/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormLabel, Grid } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import DataType from 'utils/enum/DataType';
import DisplayFieldStyle from 'styles/modules/inventory/read/DisplayFieldStyle';

const useStyles = makeStyles(DisplayFieldStyle.field());

const getValue = (type, value, options = []) => {
    let newValue = value;
    const noneValue = 'None';
    const {
        MONEY, NUMBER, DATE, PERCENTAGE,
        COMBO,
    } = DataType;

    if (type !== MONEY && (!newValue || newValue === 'null') && newValue !== 0) {
        newValue = noneValue;
    } else {
        newValue = typeof newValue === 'string' ? newValue.trim() : newValue;

        if (StringUtils.isEmpty(newValue) && type !== MONEY) {
            newValue = noneValue;
        } else if (type === DATE) {
            newValue = DateUtils.isValid(newValue, '')
                ? DateUtils.format(newValue)
                : noneValue;
        } else if (type === COMBO) {
            const comboOptions = options.map((option) => option.option);
            newValue = comboOptions.includes(value) ? value : noneValue;
        } else if (type === NUMBER || type === MONEY || type === PERCENTAGE) {
            if (isNaN(newValue) && type === NUMBER) {
                newValue = noneValue;
            } else if (type === MONEY) {
                newValue = NumberUtils.applyCurrencyFormat(newValue);
            } else if (type === PERCENTAGE) {
                newValue = NumberUtils.applyPercentageFormat(newValue);
            } else {
                newValue = NumberUtils.applyThousandsFormat(newValue);
            }
        }
    }

    return newValue;
};
const FIELDS_EVALUATE = {
    MARKUP: 'Markup',
    AGE: 'Age',
    INTERNET_PRICE: 'Internet Price',
    REDUCED_PRICE: 'Reduced Price',
};
const DisplayField = ({
    field,
    value,
    alternativeValue,
    type: inputType,
    options,
    display,
    displayFieldName,
    maxWidth,
    customStyle,
}) => {
    const type = inputType.toLowerCase();
    const classes = useStyles();
    const fieldStyle = [classes.labelRow];
    const {
        MARKUP,
        AGE,
        INTERNET_PRICE,
        REDUCED_PRICE,
    } = FIELDS_EVALUATE;

    if (maxWidth) {
        fieldStyle.push(classes.labelRowWidth);
    }
    if (display) {
        if (field === MARKUP) {
            if (value > 0) {
                fieldStyle.push(classes.greenColor);
            } else if (value < 0) {
                fieldStyle.push(classes.redColor);
            }
        } else if (field === AGE && value >= 90) {
            fieldStyle.push(classes.redColor);
        }

        if ([MARKUP, AGE, INTERNET_PRICE].includes(field)) {
            fieldStyle.push(classes.bold);
        }

        const { DATE } = DataType;
        let labelValue;

        if (field === REDUCED_PRICE && !StringUtils.isEmpty(alternativeValue)) {
            labelValue = (
                <div className={classes.reducePrice}>
                    <span>{getValue(type, value)}</span>
                    <span>{alternativeValue}</span>
                </div>
            );
        } else {
            labelValue = getValue(type, value, options);
        }

        return (
            <Grid
                item
                xs={12}
                sm={6}
                className={customStyle}
            >
                <FormLabel className={fieldStyle.join(' ')}>
                    {displayFieldName && <span>{`${field}:`}</span>}
                    {type === DATE && <CalendarTodayIcon fontSize="small" />}
                    {labelValue}
                </FormLabel>
            </Grid>
        );
    }

    return null;
};

DisplayField.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.any,
    alternativeValue: PropTypes.any,
    type: PropTypes.string,
    display: PropTypes.bool,
    displayFieldName: PropTypes.bool,
    maxWidth: PropTypes.bool,
    customStyle: PropTypes.string,
    options: PropTypes.array,
};

DisplayField.defaultProps = {
    type: 'text',
    value: null,
    display: true,
    displayFieldName: true,
    alternativeValue: '',
    maxWidth: false,
    customStyle: '',
    options: [],
};

export default DisplayField;

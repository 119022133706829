import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/client';
import History from 'components/widgets/History';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { FetchPolicy } from 'utils/enum/Core';
import PropTypes from 'prop-types';

const PartsHistory = forwardRef((props, ref) => {
    const { partsInventoryId } = props;
    const [getData, { data, loading }] = useLazyQuery(PartsQuery.GET_PARTS_INVENTORY_NOTES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const refreshData = (id) => {
        if (id > 0) {
            getData({
                variables: {
                    id,
                },
            });
        }
    };

    useEffect(() => {
        refreshData(partsInventoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partsInventoryId]);

    useImperativeHandle(ref, () => ({
        updateData(id) {
            refreshData(id);
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    return (
        <History
            loading={loading}
            records={data?.getPartsInventoryNotes ?? []}
        />
    );
});

PartsHistory.propTypes = {
    partsInventoryId: PropTypes.number,
};

PartsHistory.defaultProps = {
    partsInventoryId: 0,
};

export default PartsHistory;

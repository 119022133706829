import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import InputNumber from 'components/widgets/InputNumber';
import DialogActions from 'components/widgets/modal/DialogActions';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

// Material UI
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

// Bootstrap
import { Form, Row, Col } from 'react-bootstrap';

// Apollo Client
import { useMutation } from '@apollo/client';
import OpportunityMapper from 'services/mapData/OpportunityMapper';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
}));

const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_FIELD: 'setField',
};
const initState = {
    tradeVin: '',
    tradeYear: null,
    tradeMake: '',
    tradeModel: '',
    tradeTrim: '',
    tradeMiles: 0,
    tradeACV: 0,
    tradePayoff: 0,
    tradeLeinHolder: '',
    tradePayoffDate: null,
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPES.SET_RECORD:
        return payload;
    case ACTION_TYPES.SET_FIELD:
        return update(state, {
            [payload?.field]: { $set: payload?.value },
        });
    default:
        return initState;
    }
};

const TradeInForm = ({
    open, onClose, record, crmId,
}) => {
    const classes = useStyles();
    const [state, dispatchData] = useReducer(reducer, initState);
    const [updateTradeIn] = useMutation(OpportunityMutation.UPDATE_OPPORTUNITY_TRADE_IN);
    const onChange = (field, value) => {
        dispatchData({
            type: ACTION_TYPES.SET_FIELD,
            payload: {
                field,
                value,
            },
        });
    };

    useEffect(() => {
        const mapTradeIn = OpportunityMapper.mapTradeIn(record);

        dispatchData({
            type: ACTION_TYPES.SET_RECORD,
            payload: {
                ...mapTradeIn,
            },
        });
    }, [record]);

    const onError = () => {
        ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Trade In'));
    };

    const onSave = async () => {
        try {
            const mapTradeInToUpdate = OpportunityMapper.mapTradeInToUpdate(state);
            const input = {
                ...mapTradeInToUpdate,
            };
            const response = await updateTradeIn({ variables: { input, crmId } });

            if (response.data?.updateTradeInOpportunity) {
                ModalUtils.successMessage(null, 'Successfully saved!');
                onClose();
            } else {
                onError();
            }
        } catch (error) {
            onError();
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogAppBar
                title="Trade In"
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col md>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>VIN:</Form.Label>
                                    <Form.Control
                                        onChange={(e) => onChange('tradeVin', e.target.value)}
                                        value={state.tradeVin || ''}
                                        type="text"
                                        maxLength={18}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Year:</Form.Label>
                                    <InputNumber
                                        value={state.tradeYear}
                                        onChange={(value) => onChange('tradeYear', value)}
                                        size="sm"
                                        max={9999}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Make:</Form.Label>
                                    <Form.Control
                                        onChange={(e) => onChange('tradeMake', e.target.value)}
                                        value={state.tradeMake || ''}
                                        maxLength={25}
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Model:</Form.Label>
                                    <Form.Control
                                        onChange={(e) => onChange('tradeModel', e.target.value)}
                                        value={state.tradeModel || ''}
                                        maxLength={25}
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Trim:</Form.Label>
                                    <Form.Control
                                        onChange={(e) => onChange('tradeTrim', e.target.value)}
                                        value={state.tradeTrim || ''}
                                        maxLength={100}
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Miles:</Form.Label>
                                    <InputNumber
                                        thousandSeparator
                                        value={state.tradeMiles}
                                        onChange={(value) => onChange('tradeMiles', value)}
                                        max={999999999}
                                        decimalScale={0}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                        <Col md>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Trade ACV:</Form.Label>
                                    <InputNumber
                                        showCurrency
                                        thousandSeparator
                                        placeholder="$0.00"
                                        value={state.tradeACV}
                                        onChange={(value) => onChange('tradeACV', value)}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Payoff:</Form.Label>
                                    <InputNumber
                                        showCurrency
                                        thousandSeparator
                                        placeholder="$0.00"
                                        value={state.tradePayoff}
                                        onChange={(value) => onChange('tradePayoff', value)}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lien Holder:</Form.Label>
                                    <Form.Control
                                        onChange={(e) => onChange('tradeLeinHolder', e.target.value)}
                                        value={state.tradeLeinHolder || ''}
                                        type="text"
                                        maxLength={100}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Payoff Date:</Form.Label>
                                    <DatePicker
                                        className={clsx('form-control', 'form-control-sm')}
                                        onChange={(date) => onChange('tradePayoffDate', date)}
                                        selected={state.tradePayoffDate}
                                        popperContainer={CalendarContainer}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                variant="contained"
            />
        </Dialog>
    );
};

TradeInForm.propTypes = {
    open: PropTypes.bool,
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
};

TradeInForm.defaultProps = {
    open: false,
    record: {},
};

export default TradeInForm;

import { gql } from '@apollo/client';

export default class CustomQueriesMutate {
    static CREATE_CUSTOM_QUERY = gql`
        mutation createCustomQuery($input: QueryInput!) {
            createCustomQuery(input: $input){
                savedQuerieId
                queryName
                queryText
            }
        }
    `;

    static EDIT_CUSTOM_QUERY = gql`
        mutation editCustomQuery($input: QueryInput!) {
            editCustomQuery(input: $input)
        }
    `;

    static DELETE_CUSTOM_QUERY = gql`
        mutation deleteCustomQuery($savedQuerieId: Int!) {
            deleteCustomQuery(savedQuerieId: $savedQuerieId)
        }
    `;
}

import React, { Component } from 'react';

// Others
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import VendorService from 'services/modules/VendorService';
import VendorSettingMap from 'services/mapData/VendorSettingMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import VendorListSubscription from 'services/graphQL/subscription/VendorListSubscription';

const VendorSettingsListContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.vendorService = new VendorService();
        this.vendorListSubscription = null;

        this.initBind();
    }

    /* eslint-disable react/state-in-constructor */
    state = {
        openDialog: false,
        openConfirmationDialog: false,
        isEditingVendor: false,
        selectedVendorId: null,
        vendorStatus: true,
        searchTerm: '',
        tableContent: {
            records: [],
            tableLength: 0,
            loading: true,
            limit: 50,
            sortable: true,
            desc: false,
            orderBy: 'vendorId',
        },
        isMobile: window.matchMedia('(max-width: 900px)').matches,
    }

    componentDidMount() {
        this.getServicesData();
        const handler = (e) => this.setState({ isMobile: e.matches });
        window.matchMedia('(max-width: 900px)').addEventListener('change', handler);
    }

    componentWillUnmount() {
        this.unsubscribeVendorList();
    }

    onSave(input) {
        const { state: { isEditingVendor } } = this;

        if (isEditingVendor) {
            this.updateVendor(input);
        } else {
            this.createVendor(input);
        }
    }

    onSearch(searchTerm) {
        this.setState({ searchTerm }, () => {
            this.getServicesData(true);
        });
    }

    onChangeVendorStatus(vendorStatus) {
        this.setState(
            (prevState) => ({
                vendorStatus,
                tableContent: Object.assign(prevState.tableContent, {
                    records: [],
                }),
            }),
            () => {
                this.getServicesData();
            },
        );
    }

    onSortedChange(orderBy, desc) {
        this.setState(
            (prevState) => ({
                tableContent: Object.assign(prevState.tableContent, {
                    records: [],
                    sortable: false,
                    desc: !desc,
                    orderBy,
                }),
            }),
            () => {
                this.getServicesData();
            },
        );
    }

    setSelectedVendorId(selectedVendorId) {
        this.setState({ selectedVendorId });
    }

    getServicesData(isSearching = false) {
        const { state } = this;

        if (isSearching) {
            this.setState({
                tableContent: {
                    records: [],
                    tableLength: 0,
                    loading: true,
                    limit: 50,
                    sortable: true,
                    desc: false,
                },
            });
        } else {
            this.setState({
                tableContent: Object.assign(state.tableContent, {
                    loading: true,
                }),
            });
        }

        const start = !isSearching && state.tableContent.records && state.tableContent.records.length > 0
            ? state.tableContent.records.length + 1
            : 0;
        const { limit } = state.tableContent;
        const {
            searchTerm, vendorStatus,
        } = state;

        const dir = state.tableContent.desc ? 'DESC' : 'ASC';
        const { orderBy } = state.tableContent;

        const sort = {
            field: orderBy, // 'vendorId',
            dir,
        };

        const paginate = {
            start,
            limit,
        };

        const filter = {
            active: vendorStatus,
            searchTerm,
        };

        this.vendorService.getSettingList(filter, sort, paginate)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.vendors) {
                    const { vendors, totalCount } = data;

                    const currentRecords = isSearching || (isSearching && searchTerm === '')
                        ? []
                        : state.tableContent.records;

                    vendors.forEach((vendor) => {
                        currentRecords.push(vendor);
                    });

                    this.setState({
                        tableContent: {
                            records: currentRecords,
                            tableLength: totalCount,
                            sortable: true,
                            desc: state.tableContent.desc,
                            orderBy,
                            loading: false,
                            limit: 50,
                        },
                    });
                } else {
                    this.setState(
                        (prevState) => ({
                            selectedVendorId: null,
                            tableContent: Object.assign(prevState.tableContent, {
                                records: [],
                                tableLength: 0,
                            }),
                        }),
                    );
                }
            })
            .finally(() => {
                this.setState(
                    (prevState) => ({
                        selectedVendorId: null,
                        tableContent: Object.assign(prevState.tableContent, {
                            loading: false,
                            limit: 50,
                        }),
                    }),
                );
                this.subscribeVendorList();
            });
    }

    loadMoreData() {
        this.getServicesData();
    }

    createVendor(input) {
        const inputCreate = VendorSettingMap.mapVendorSettingToSave(input);

        this.vendorService.createVendor({ input: inputCreate })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.addVendor) {
                    ModalUtils.successMessage(null, 'Vendor created successfully');
                    this.closeVendorDialog();

                    const { searchTerm } = this.state;
                    this.getServicesData(!StringUtils.isEmpty(searchTerm));
                }
            });
    }

    updateVendor(input) {
        const { selectedVendorId } = this.state;
        const inputUpdate = VendorSettingMap.mapVendorSettingToUpdate(input, selectedVendorId);

        this.vendorService.updateVendor(inputUpdate)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateVendor) {
                    ModalUtils.successMessage(null, 'Vendor updated successfully');
                    this.closeVendorDialog();

                    const { searchTerm } = this.state;
                    this.getServicesData(!StringUtils.isEmpty(searchTerm));
                }
            });
    }

    openVendorDialog(isEditing) {
        this.setState({
            openDialog: true,
            isEditingVendor: isEditing,
        });
    }

    closeVendorDialog() {
        this.setState({ openDialog: false });
    }

    openConfirmDialog() {
        this.setState({
            openConfirmationDialog: true,
        });
    }

    closeConfirmDialog() {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    subscribeVendorList() {
        this.unsubscribeVendorList();
        const { searchTerm, vendorStatus } = this.state;
        const filter = {
            searchTerm,
            status: vendorStatus,
        };

        this.graphqlClient.subscribe(this.responseSubscription, VendorListSubscription.VENDOR_SETTINGS_LIST, filter)
            .then((response) => {
                this.vendorListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.vendorSettingsListChanged) {
            const { vendorSettingsListChanged: { type, vendor } } = data;

            switch (type) {
            case SubscriptionActionType.CREATED:
                this.addNewVendorOnList(vendor);
                break;
            case SubscriptionActionType.UPDATED:
                this.updateVendorOnList(vendor);
                break;
            default:
            }
        }
    }

    unsubscribeVendorList() {
        if (this.vendorListSubscription) {
            this.vendorListSubscription.unsubscribe();
        }
    }

    addNewVendorOnList(record) {
        this.setState(
            (prevState) => ({
                tableContent: Object.assign(prevState.tableContent, {
                    records: this.addVendorRecord(prevState.tableContent.records, record, prevState.vendorStatus),
                }),
            }),
        );
    }

    addVendorRecord(records, record, currentVendorStatus) {
        const newRecords = [...records];

        if (record.active === currentVendorStatus) {
            return update(newRecords, { $unshift: [record] });
        }

        return newRecords;
    }

    updateVendorOnList(record) {
        this.setState(
            (prevState) => ({
                tableContent: Object.assign(prevState.tableContent, {
                    records: this.updateVendorRecord(prevState.tableContent.records, record, prevState.vendorStatus),
                }),
            }),
        );
    }

    updateVendorRecord(records, record, currentVendorStatus) {
        const newRecords = [...records];
        const vendorIndex = newRecords.findIndex((item) => item.vendorId === record.vendorId);

        if (record.active === currentVendorStatus) {
            newRecords[vendorIndex] = { ...record };
        } else {
            newRecords.splice(vendorIndex, 1);
        }

        return newRecords;
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.createVendor = this.createVendor.bind(this);
        this.updateVendor = this.updateVendor.bind(this);
        this.openVendorDialog = this.openVendorDialog.bind(this);
        this.closeVendorDialog = this.closeVendorDialog.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.setSelectedVendorId = this.setSelectedVendorId.bind(this);
        this.subscribeVendorList = this.subscribeVendorList.bind(this);
        this.addNewVendorOnList = this.addNewVendorOnList.bind(this);
        this.updateVendorOnList = this.updateVendorOnList.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.onChangeVendorStatus = this.onChangeVendorStatus.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.onSortedChange = this.onSortedChange.bind(this);
        this.addVendorRecord = this.addVendorRecord.bind(this);
        this.updateVendorRecord = this.updateVendorRecord.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                openVendorDialog={this.openVendorDialog}
                closeVendorDialog={this.closeVendorDialog}
                openConfirmDialog={this.openConfirmDialog}
                closeConfirmDialog={this.closeConfirmDialog}
                setSelectedVendorId={this.setSelectedVendorId}
                onChangeVendorStatus={this.onChangeVendorStatus}
                onEnableDecoder={this.onEnableDecoder}
                loadMoreData={this.loadMoreData}
                onSortedChange={this.onSortedChange}
                onSearch={this.onSearch}
                onSave={this.onSave}
            />
        );
    }
};

export default VendorSettingsListContainer;

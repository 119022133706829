/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
import React, {
    useReducer, useEffect, useContext, useState,
} from 'react';
import {
    makeStyles, Button, Dialog, DialogContent,
    AppBar, Toolbar, IconButton, Typography,
    useTheme, useMediaQuery, ButtonGroup,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import Split from 'react-split';

import {
    Tabs, Tab,
} from 'react-bootstrap';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import ServiceInvoiceSchema from 'utils/schema/service/ServiceInvoiceSchema';
import { useLazyQuery, useMutation } from '@apollo/client';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import ServiceMapper from 'services/mapData/ServiceMapper';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import ServiceInvoiceReducer, { ACTION_TYPES } from 'components/modules/service/reducer/ServiceInvoiceReducer';
import { useParams, useHistory, Link } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import { ServiceSubModules, ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';
import ServiceJobs from 'components/modules/service/serviceInvoice/create/ServiceJobs';
import useServiceInvoiceValidateActions from 'components/modules/service/hooks/useServiceInvoiceValidateActions';
import { VendorType } from 'utils/enum/Vendors';
import BasicStyles from 'styles/modules/basic/basicStyles';
import TabContainer from 'components/widgets/tabs/TabContainer';
import TabStyles from 'styles/widgets/tabs/TabsStyles';
import clsx from 'clsx';
import {
    CloseIcon, ExpandLessIcon, ExpandMoreIcon, SaveIcon,
    MenuOutlinedIcon, MenuOpenOutlinedIcon,
} from 'components/icons';
import PropTypes from 'prop-types';
import { EmployeeType } from 'utils/enum/UserEnum';
import UserQuery from 'services/graphQL/query/UserQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import { Add } from '@material-ui/icons';
import MultiPointInspection from 'components/modules/service/serviceInvoice/read/MultiPointInspection';
import ServiceMainInfo from 'components/modules/service/serviceInvoice/create/ServiceMainInfo';
import { initServiceState } from 'components/modules/service/serviceInvoice/serviceInvoiceData';
import BadgeStyle from 'styles/theme/Badge';
import { ServiceJobsSchema } from 'utils/schema/service/ServiceJobsSchema';
import useWindowDimensions from 'components/hook/core/useWindowDimensions';
import NumberUtils from 'lib/NumberUtils';
import useSharedMethods from 'components/modules/service/serviceInvoice//hooks/useSharedMethods';
import ReconTab from 'components/modules/inventory/read/ReconTab';
import DropdownQuery from 'components/widgets/DropdownQuery';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';

const containerStyles = makeStyles((theme) => BasicStyles.containerDialogProcess(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const panelStyle = makeStyles((theme) => TabStyles.tabPanel(theme));
const useBadgeStyles = makeStyles((theme) => BadgeStyle.getStyle(theme));
const ownStyle = makeStyles((theme) => ({
    navbar: {
        '& > a': {
            color: theme.palette.text.outerSpace,
            'text-decoration': 'none',
            'background-color': 'transparent',
            'line-height': '1.5',
            'font-size': '14px',
            'font-weight': 500,
        },
    },
    tabContainerScrollable: {
        '& .tab-content': {
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: '#F7FAFF',
        },
        '& .tab-content > .tab-pane': {
            height: 'auto',
        },
    },
    elementsNextTabs: {
        position: 'absolute',
        right: '15px',
        display: 'flex',
        paddingTop: '1px',
        '& > *': {
            margin: theme.spacing(0.3),
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 45,
        },
    },
    tabForceHeight100: {
        height: '100% !important',
    },
    groupBtnCls: {
        '& svg': {
            fontSize: 22,
        },
    },
    jobTab: {
        '& .jobTabItemCls': {
            [theme.breakpoints.down('sm')]: {
                marginTop: 50,
            },
        },
    },
    budgetMessage: {
        fontSize: 14,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.text.white,
        padding: '5px',
        borderRadius: '4px',
        verticalAlign: 'middle',
    },
    lotNameClass: {
        width: '150px',
        paddingRight: '3px',
    },
    toolBarColor: {
        backgroundColor: theme.palette.background.default,
    },
    paddingRight20: {
        paddingRight: '20px',
    },
}));

const SortFields = {
    VENDOR_NAME: 'vendorName',
};

const keyStore = new KeyStore();

const ServiceInvoiceCreate = (props) => {
    const { isInternal } = props;
    const { id } = useParams();
    const history = useHistory();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [showPanel, setShowPanel] = useState(isTablet === false);
    const SERVICE_OPEN = keyStore.hasPermission(Permission.SERVICE_OPEN);
    const SERVICE_EDIT = keyStore.hasPermission(Permission.SERVICE_EDIT);
    const INVENTORY_RECON_READ = keyStore.hasPermission(Permission.INVENTORY_RECON_READ);
    const INVENTORY_RECON_WRITE = keyStore.hasPermission(Permission.INVENTORY_RECON_WRITE);

    const initialState = {
        ...initServiceState,
        record: {
            ...initServiceState.record,
            inHouse: isInternal,
            status: ServiceInvoiceStatus.NEW,
        },
    };

    const classes = {
        ...containerStyles(),
        ...basicFormStyle(),
        ...panelStyle(),
        ...ownStyle(),
    };
    const badgeClasses = useBadgeStyles();

    const { getBadgeStatus } = useSharedMethods(badgeClasses);

    const {
        appBar, centerItemsInToolbar, buttonSpacingInToolbar,
        dialogContent, splitContainer,
        tabContainerScrollable, borderContainer, navbar, tabForceHeight100,
        tab, elementsNextTabs, groupBtnCls, jobTab, budgetMessage, lotNameClass,
        titleInToolbarDefault, toolBarColor, paddingRight20,
    } = classes;

    const [state, dispatch] = useReducer(ServiceInvoiceReducer, initialState);
    const {
        record,
        jobs,
        isDirty,
        couldLostData,
        showServicePopup,
        identifierJobsMainInfo,
        identifierJobs,
        vendorList,
        userList,
        opCodesList,
        inspectionList,
        approvalInspection,
        reconCost,
    } = state;

    const {
        inspectionDone, lotName, invoiceNumber,
        laborDiscount, discountPercentage, discount,
        status, inHouse, vehicleStock, dealNumber,
    } = record;

    const [tabSelected, setTabSelected] = useState(inHouse ? 'inspection' : 'jobs');

    const isNew = invoiceNumber === 0;
    const { isValid, errors } = isValidSchema(ServiceInvoiceSchema, record);
    const { isValid: isValidJobs, errors: errorJobs } = isValidSchema(ServiceJobsSchema, jobs);

    const {
        userInformation, availableLots, defaultLotId, defaultLot, lotsUser,
    } = useContext(UserContext);

    // define container sizes
    const { width: windowWidth } = useWindowDimensions();
    const calculatedSize = isTablet ? [50, 100] : [35, 65];
    const splitSizes = showPanel ? (calculatedSize) : [0, 100];
    const calculatedContainerWidth = (splitSizes[0] / 100) * windowWidth;
    const [containerWidth, setContainerWidth] = useState(calculatedContainerWidth);

    const overrideAvailableLots = (availableLots || []).map((item) => ({ value: item.id, label: item.label }));

    if (defaultLotId > 0 && lotName === '') {
        dispatch({
            type: ACTION_TYPES.SET_DEFAULT_LOT,
            value: {
                lotId: defaultLotId,
                lotName: defaultLot,
            },
        });
    }

    const editFields = useServiceInvoiceValidateActions(status);
    const { allowSaveAction } = editFields;

    const [getServiceSettingsKeys] = useLazyQuery(ServiceQuery.GET_SERVICE_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getServiceSettingsKeys) {
                dispatch({
                    type: ACTION_TYPES.SET_SERVICE_SETTINGS,
                    payload: res.getServiceSettingsKeys,
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getMaxReconCostByVehicle] = useLazyQuery(ServiceQuery.GET_MAX_RECON_COST_BY_VEHICLE, {
        onCompleted: (res) => {
            if (res.getMaxReconCost) {
                dispatch({
                    type: ACTION_TYPES.SET_RECON_COST_SETTINGS,
                    payload: res.getMaxReconCost,
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getServiceInvoice, { loading }] = useLazyQuery(ServiceQuery.GET_SERVICE_INVOICE, {
        onCompleted: (res) => {
            if (res.getServiceInvoiceFull) {
                const recordMapped = ServiceMapper.mapServiceInvoice(res.getServiceInvoiceFull);
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    payload: recordMapped,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getVendorList] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            filter: {
                vendorType: [VendorType.REPAIR_VENDOR],
                active: true,
            },
            sort: {
                field: SortFields.VENDOR_NAME,
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const list = res.getVendorList?.map((item) => ({
                    vendorId: item.vendorId,
                    vendor: item.vendorName,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_VALUE_CATALOGS,
                    value: {
                        key: 'vendorList',
                        value: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getAllUsers] = useLazyQuery(UserQuery.GET_USER_AND_SALARY_INFO, {
        variables: {
            types: [
                EmployeeType.SERVICE_ADVISOR,
                EmployeeType.TECHNICIAN,
            ],
        },
        onCompleted: (res) => {
            if (res.getUsersAndSalary) {
                const list = res?.getUsersAndSalary?.map((item) => ({
                    ...item,
                    user: [item.firstName, item.lastName].join(' '),
                    payType: item.payType,
                    salary: item.salary,
                    userPicture: item.userPicture,
                }));

                const checkCurrentUser = list.find((c) => c.userId === userInformation?.userId && c.employeeType === EmployeeType.SERVICE_ADVISOR);

                if (!checkCurrentUser && userInformation) {
                    list.push({
                        userId: userInformation?.userId || 0,
                        user: [userInformation?.firstName, userInformation?.lastName].join(' '),
                        employeeType: EmployeeType.SERVICE_ADVISOR,
                    });
                }

                dispatch({
                    type: ACTION_TYPES.SET_VALUE_CATALOGS,
                    value: {
                        key: 'userList',
                        value: list,
                    },
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getAllLots] = useLazyQuery(LotQuery.GET_LOTS, {
        onCompleted: (res) => {
            if (res.lotList) {
                dispatch({
                    type: ACTION_TYPES.SET_VALUE_CATALOGS,
                    value: {
                        key: 'lotList',
                        value: res.lotList,
                    },
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getOperationCodes] = useLazyQuery(ServiceQuery.GET_SERVICE_OPERATION_CODE_LIST, {
        variables: {
            paginate: {
                init: 0,
                limit: 0,
                ignoreLimit: true,
            },
            sort: {
                fieldName: 'description',
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res?.getOperationCodes?.data) {
                const list = res?.getOperationCodes?.data?.map((item) => ({
                    ...item,
                    value: item.opCodeId,
                    label: [item.opCode, ' - ', item.description].join(''),
                }));

                dispatch({
                    type: ACTION_TYPES.SET_VALUE_CATALOGS,
                    value: {
                        key: 'opCodesList',
                        value: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const saveQuery = isNew ? ServiceMutation.CREATE_SERVICE_INVOICE : ServiceMutation.UPDATE_SERVICE_INVOICE;
    const [addRecord, { loading: isSaving }] = useMutation(saveQuery, {
        onError: ({ graphQLErrors }) => {
            ModalUtils.errorMessage(graphQLErrors);
        },
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                showServicePopup: false,
                isDirty: false,
            },
        });

        history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}`);
    };

    const onPopupClose = (_, forceClose = false) => {
        if ((isDirty && allowSaveAction) && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        onCloseAction();
    };

    const onSave = async (requestedNew) => {
        let recordToSave = { ...record };

        recordToSave = ServiceMapper.mapServiceInvoiceToSave(recordToSave, jobs, inspectionList);

        const jobsOpenStatus = jobs?.map((c) => ({
            open: c.open,
            jobNumber: c.jobNumber,
        }));

        const resultAfterSave = await addRecord({
            variables: {
                record: recordToSave,
            },
        });

        const {
            success = false, errorCode = 0, errorMessage = '', record: serviceInvoiceResult = {},
        } = (isNew ? resultAfterSave?.data.createServiceInvoice : resultAfterSave?.data.updateServiceInvoice) || {};

        if (errorCode && errorMessage) {
            ModalUtils.errorMessage(null, errorMessage);
        }

        if (success && serviceInvoiceResult?.invoiceNumber > 0) {
            ModalUtils.successMessage(null, 'Successfully saved!');
            if (!requestedNew) {
                const recordMapped = ServiceMapper.mapServiceInvoice(serviceInvoiceResult, jobsOpenStatus);
                if (isNew) {
                    history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/`
                        + `${inHouse ? 'internal' : 'customer'}/edit/${serviceInvoiceResult?.invoiceNumber}`);
                } else {
                    dispatch({
                        type: ACTION_TYPES.SET_RECORD,
                        payload: recordMapped,
                    });
                }
            } else if (!isNew) {
                history.push(`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/`
                        + `${inHouse ? 'internal' : 'customer'}/create`);
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW_SERVICE,
                    payload: {
                        isInternal,
                    },
                });
            }
        }
    };

    const onChangeRecord = (field, value) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_RECORD_MAIN_INFO,
            payload: {
                field,
                value,
            },
        });
    };

    const onChangeVehicleInfo = ({
        stockNumber,
        vehicleVin,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleTrim,
        vehicleEngine,
    }) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VEHICLE_INFO,
            payload: {
                vehicleStock: stockNumber,
                vehicleVin,
                vehicleMake,
                vehicleModel,
                vehicleYear,
                vehicleTrim,
                vehicleEngine,
            },
        });
    };

    const onChangeJobPart = (jobNumber, columnId, newValue, cell) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_JOB_PART,
            payload: {
                jobNumber,
                columnId,
                newValue,
                cell,
            },
        });
    };

    const onAddJobPart = (partsSelected, jobNumber) => {
        dispatch({
            type: ACTION_TYPES.ADD_NEW_JOB_PART,
            payload: {
                partsSelected,
                jobNumber,
            },
        });
    };

    const onDeleteAllJobPart = (jobNumber) => {
        dispatch({
            type: ACTION_TYPES.DELETE_ALL_JOB_PART,
            payload: {
                jobNumber,
            },
        });
    };

    const onDeleteJobPart = (serviceJobPartsId, jobNumber) => {
        dispatch({
            type: ACTION_TYPES.DELETE_JOB_PART,
            payload: {
                serviceJobPartsId,
                jobNumber,
            },
        });
    };

    const onChangeJobValue = (actionType, jobNumber, value, field) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_JOB_INFO,
            payload: {
                actionType,
                jobNumber,
                value,
                field,
                methodAddPart: field === 'laborOperationCode' ? onAddJobPart : null,
                methodDeleteAllPart: field === 'laborOperationCode' ? onDeleteAllJobPart : null,
            },
        });
    };

    const expandAndCollapseAllJobs = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL,
            payload: val,
        });
    };

    const scrollToBottom = () => {
        const container = document.getElementsByClassName('tab-content')?.[0] ?? null;
        if (container) container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
    };

    const addJob = () => {
        dispatch({
            type: ACTION_TYPES.ADD_NEW_JOB,
        });

        setTimeout(() => {
            scrollToBottom();
        }, 200);
    };

    const onDeleteJob = (jobNumber) => {
        dispatch({
            type: ACTION_TYPES.DELETE_JOB,
            payload: {
                jobNumber,
            },
        });
    };

    const updateInspectionItems = (data, pending = true) => {
        dispatch({
            type: ACTION_TYPES.SET_INSPECTION_LIST,
            payload: {
                data,
                pending,
            },
        });
    };

    const onCancelApprovalInspectionList = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                inspectionList: [],
                approvalInspection: false,
            },
        });
    };

    const onChangeLot = (value) => {
        onChangeRecord('lotId', value);
        const lotNameSelected = overrideAvailableLots?.find((c) => c.value === value);
        onChangeRecord('lotName', lotNameSelected.label);
    };

    useEffect(() => {
        if (id > 0) {
            getServiceInvoice({
                variables: {
                    id: Number(id),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const loadingCatalogs = async () => {
        getVendorList();
        getAllUsers();
        getAllLots();
        getOperationCodes();
    };

    useEffect(() => {
        loadingCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lotName !== '') {
            getServiceSettingsKeys({
                variables: {
                    lotName,
                },
            });

            if (record.lotId === 0 && overrideAvailableLots.length > 0) {
                const currentRecord = overrideAvailableLots.find((c) => c.label.toLowerCase() === lotName.toLowerCase());
                onChangeRecord('lotId', currentRecord.value);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotName]);

    useEffect(() => {
        if ((inspectionDone && invoiceNumber > 0) || inHouse) {
            setTabSelected('jobs');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectionDone, invoiceNumber]);

    useEffect(() => {
        if (Number(vehicleStock) > 0 && status !== ServiceInvoiceStatus.CLOSED) {
            getMaxReconCostByVehicle({
                variables: {
                    stockNumber: Number(vehicleStock),
                    invoiceNumber: Number(invoiceNumber || 0),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleStock]);

    const enableBtnSave = (isValid && isValidJobs && isDirty && allowSaveAction && !isSaving);

    const budgetToRepair = reconCost.maximumReconCost - reconCost.totalRepairs;

    const haveParts = jobs?.filter((c) => c?.parts?.length > 0)?.length > 0 ?? false;

    const messageToAllowSave = [];

    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    errorJobs?.forEach((error) => {
        const errorJob = error?.field?.split('.');
        const errorJobNumber = Number(errorJob?.[0]?.replace(/\D/g, '') ?? 0) + 1;
        const errorJobMessage = error?.message?.split('.')?.[1] ?? error.message;
        messageToAllowSave.push(['Job # ', errorJobNumber, ' - ', errorJobMessage].join(''));
    });

    return (
        <Dialog
            open={showServicePopup}
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
        >
            <AppBar className={appBar}>
                <Toolbar className={clsx(centerItemsInToolbar, toolBarColor)}>
                    <div className={buttonSpacingInToolbar}>
                        {(SERVICE_OPEN || SERVICE_EDIT)
                    && allowSaveAction
                            && (
                                <ErrorMessageIndicator
                                    messages={messageToAllowSave}
                                >
                                    <Button
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(false)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Save
                                    </Button>
                                </ErrorMessageIndicator>
                            )}
                        {(SERVICE_OPEN || SERVICE_EDIT) && allowSaveAction
                            && (
                                <Button
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    disabled={!enableBtnSave}
                                    onClick={() => onSave(true)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Save & New
                                </Button>
                            )}
                        {!isTablet && (
                            <Button
                                startIcon={showPanel ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
                                size="small"
                                onClick={() => setShowPanel(!showPanel)}
                                variant="contained"
                                color="secondary"
                            >
                                {showPanel ? 'Hide' : 'Show'}
                            </Button>
                        )}
                        {inHouse && (
                            <span className={budgetMessage}>
                                The maximum allowed repair expense is
                                {' '}
                                {NumberUtils.applyCurrencyFormat(NumberUtils.round(reconCost.maximumReconCost))}
                                {' '}
                                the remaining allowed balance is
                                {' '}
                                {NumberUtils.applyCurrencyFormat(NumberUtils.round(budgetToRepair))}
                            </span>
                        )}
                        {dealNumber && (
                            <Link to={`/deals/${dealNumber}`} target="_blank">
                                <Button variant="contained" color="primary" size="small">
                                    <strong>
                                        Deal #: &nbsp;
                                    </strong>
                                    {dealNumber}
                                </Button>
                            </Link>
                        ) }
                    </div>
                    <div className={centerItemsInToolbar}>
                        <Typography variant="h6" className={titleInToolbarDefault}>
                            { isNew ? 'New RO in ' : `Edit RO ${invoiceNumber} in ` }
                            {!(isNew && !haveParts) && lotName}
                        </Typography>
                        {isNew && !haveParts && (
                            <DropdownQuery
                                name="lotId"
                                disabled={!isNew || haveParts}
                                className={clsx(lotNameClass, isValidField(errors, 'lotId') ? 'invalid-field' : '')}
                                value={record.lotId}
                                placeHolder="select a lot"
                                onChange={(name, newValue) => onChangeLot(newValue)}
                                dataSource={{
                                    localData: overrideAvailableLots,
                                    idField: 'value',
                                    descriptionField: 'label',
                                }}
                            />
                        )}
                        <div className={paddingRight20}>
                            {getBadgeStatus(status)}
                        </div>
                        <IconButton edge="start" color="primary" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={dialogContent}>
                { loading ? <LoadingMask />
                    : (
                        <Split
                            // eslint-disable-next-line no-nested-ternary
                            sizes={splitSizes}
                            className={clsx('split', splitContainer)}
                            direction="horizontal"
                            minSize={15}
                            onDrag={(size) => {
                                setContainerWidth((size[0] / 100) * windowWidth);
                            }}
                        >
                            {!isTablet && (
                                <ServiceMainInfo
                                    record={record}
                                    lotsUser={lotsUser}
                                    errors={errors}
                                    onChangeValue={onChangeRecord}
                                    identifierJobsMainInfo={identifierJobsMainInfo}
                                    userList={userList}
                                    editFields={editFields}
                                    containerWidth={containerWidth}
                                    onChangeVehicleInfo={onChangeVehicleInfo}
                                />
                            )}
                            <TabContainer className={tabContainerScrollable}>
                                {tabSelected === 'jobs' && (
                                    <div className={elementsNextTabs}>
                                        {editFields.allowSaveAction && (
                                            <Button
                                                variant="outlined"
                                                startIcon={<Add />}
                                                disabled={loading}
                                                size="small"
                                                onClick={addJob}
                                            >
                                                Add Job
                                            </Button>
                                        ) }
                                        <ButtonGroup>
                                            <Button
                                                variant="outlined"
                                                disabled={loading}
                                                size="small"
                                                className={groupBtnCls}
                                                id="serviceInvoice-expand"
                                                onClick={() => expandAndCollapseAllJobs(true)}
                                            >
                                                <ExpandMoreIcon />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                disabled={loading}
                                                size="small"
                                                className={groupBtnCls}
                                                id="serviceInvoice-collapse"
                                                onClick={() => expandAndCollapseAllJobs(false)}
                                            >
                                                <ExpandLessIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                                <Tabs
                                    variant="tabs"
                                    className={`${borderContainer} ${navbar}`}
                                    activeKey={tabSelected}
                                    onSelect={(val) => setTabSelected(val)}
                                >
                                    {isTablet && (
                                        <Tab className={tabForceHeight100} eventKey="main" title="Main">
                                            <ServiceMainInfo
                                                record={record}
                                                lotsUser={lotsUser}
                                                errors={errors}
                                                onChangeValue={onChangeRecord}
                                                identifierJobsMainInfo={identifierJobsMainInfo}
                                                userList={userList}
                                                editFields={editFields}
                                                containerWidth={containerWidth}
                                                onChangeVehicleInfo={onChangeVehicleInfo}
                                            />
                                        </Tab>
                                    )}
                                    {!inHouse && (
                                        <Tab className={clsx(tab, jobTab)} eventKey="jobs" title="SERVICE JOBS">
                                            <ServiceJobs
                                                recordFields={{
                                                    laborDiscount,
                                                    discount,
                                                    discountPercentage,
                                                    lotName,
                                                    inHouse,
                                                }}
                                                jobs={jobs}
                                                editDetails={{ ...editFields.editJobLines, allowSaveAction: editFields.allowSaveAction }}
                                                identifierJobs={identifierJobs}
                                                onChangeJobValue={onChangeJobValue}
                                                onDeleteJob={onDeleteJob}
                                                onAddJobPart={onAddJobPart}
                                                onDeletePart={onDeleteJobPart}
                                                onChangeJobPart={onChangeJobPart}
                                                vendorList={vendorList}
                                                opCodesList={opCodesList}
                                                userList={userList}
                                            />
                                        </Tab>
                                    )}
                                    {!inHouse && (
                                        <Tab className={tabForceHeight100} eventKey="inspection" title="INSPECTION LIST">
                                            <MultiPointInspection
                                                invoiceNumber={invoiceNumber}
                                                stockNumber={vehicleStock || 0}
                                                inspectionComplete={inspectionDone}
                                                updateInspectionItems={updateInspectionItems}
                                            />
                                        </Tab>
                                    ) }
                                    {inHouse && vehicleStock > 0 && INVENTORY_RECON_READ && (
                                        <Tab className={tabForceHeight100} eventKey="inspection" title="RECON">
                                            <ReconTab
                                                stockNumber={vehicleStock}
                                                record={{
                                                    detail: {
                                                        pricing: {
                                                            maximumReconCost: reconCost.maximunReconInventory,
                                                        },
                                                    },
                                                }}
                                                defaultReconCost={reconCost.maximumReconCost}
                                                canWrite={INVENTORY_RECON_WRITE}
                                            />
                                        </Tab>
                                    )}
                                    {inHouse && (
                                        <Tab className={clsx(tab, jobTab)} eventKey="jobs" title="SERVICE JOBS">
                                            <ServiceJobs
                                                recordFields={{
                                                    laborDiscount,
                                                    discount,
                                                    discountPercentage,
                                                    lotName,
                                                    inHouse,
                                                }}
                                                jobs={jobs}
                                                editDetails={{ ...editFields.editJobLines, allowSaveAction: editFields.allowSaveAction }}
                                                identifierJobs={identifierJobs}
                                                onChangeJobValue={onChangeJobValue}
                                                onDeleteJob={onDeleteJob}
                                                onAddJobPart={onAddJobPart}
                                                onDeletePart={onDeleteJobPart}
                                                onChangeJobPart={onChangeJobPart}
                                                vendorList={vendorList}
                                                opCodesList={opCodesList}
                                                userList={userList}
                                            />
                                        </Tab>
                                    )}
                                </Tabs>
                            </TabContainer>
                        </Split>
                    )}
            </DialogContent>
            {isSaving && <DialogActionMessage message="Saving information... " />}
            {couldLostData && allowSaveAction && (
                <ConfirmDialog
                    title="Attention - You have unsaved changes!"
                    description="Do you want to close without saving?"
                    open={couldLostData && allowSaveAction}
                    variant="outlined"
                    titlePrimary="Close"
                    titleSecondary="Cancel"
                    onClose={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, payload: { couldLostData: false } })}
                    onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, payload: { couldLostData: false } })}
                    onClickPrimary={(e) => onPopupClose(e, true)}
                />
            )}
            {approvalInspection && (
                <ConfirmDialog
                    title="Attention - Complete the inspection"
                    description={'Are you sure you want finish the vehicle inspection? You won\'t be able do more changes later.'
                    + 'This action will generate new jobs grouped by Job Type'}
                    open={approvalInspection}
                    variant="outlined"
                    titlePrimary="Yes, finish"
                    titleSecondary="Cancel"
                    onClose={onCancelApprovalInspectionList}
                    onClickSecondary={onCancelApprovalInspectionList}
                    onClickPrimary={() => updateInspectionItems(inspectionList, false)}
                />
            )}
        </Dialog>
    );
};

ServiceInvoiceCreate.propTypes = {
    isInternal: PropTypes.bool,
};

ServiceInvoiceCreate.defaultProps = {
    isInternal: false,
};

export default ServiceInvoiceCreate;

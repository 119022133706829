import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import NumberFormat from 'react-number-format';
import InputNumber from 'components/widgets/InputNumber';
import PhoneInput from 'components/widgets/form/PhoneInput';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InsuranceDialogContainer from 'components/containers/deals/read/buyer/InsuranceDialogContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';

const styles = () => ({
    zip: {
        width: 120,
        marginLeft: 5,
    },
});

class InsuranceDialog extends Component {
    render() {
        const {
            props: {
                open, classes, toggleModal, record, onChangeValue,
                listCity, onChangeCity, isDecodingZip, onEnableDecoder, onSave,
                saving,
            },
        } = this;
        const {
            insuranceCompany,
            insuranceAgent,
            insurancePhone,
            insuranceDateFrom,
            insuranceDate,
            insuranceAddress,
            insuranceZip,
            insuranceCity,
            insuranceState,
            insuranceComp,
            insuranceColl,
            insurancePolicy,
        } = record;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Edit Insurance" onClose={toggleModal} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={6} className="am-form">
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={insuranceCompany}
                                    placeholder="Placeholder"
                                    maxLength="100"
                                    onChange={(e) => onChangeValue('insuranceCompany', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Agent</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={insuranceAgent}
                                    placeholder="Placeholder"
                                    maxLength="50"
                                    onChange={(e) => onChangeValue('insuranceAgent', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={insuranceAddress}
                                    placeholder="Placeholder"
                                    maxLength="50"
                                    onChange={(e) => onChangeValue('insuranceAddress', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="remove-mb-form-group">
                                <Form.Row className={classes.rowCountryZip}>
                                    <Form.Group className={classes.zip}>
                                        <Form.Label>Zip Code</Form.Label>
                                        <NumberFormat
                                            size="sm"
                                            className={clsx('form-control', 'form-control-sm')}
                                            disabled={isDecodingZip}
                                            value={insuranceZip}
                                            placeholder="Placeholder"
                                            format="#####"
                                            onFocus={onEnableDecoder}
                                            onValueChange={({ value }) => onChangeValue('insuranceZip', value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} className="remove-mb-form-group">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            disabled
                                            type="text"
                                            value={insuranceState}
                                            placeholder="Read Only"
                                            onChange={(e) => onChangeValue('insuranceState', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>City</Form.Label>
                                        <Select
                                            size="sm"
                                            name="city"
                                            allowToCreate
                                            value={insuranceCity}
                                            options={listCity}
                                            placeholder="Select"
                                            maxMenuHeight={170}
                                            onChange={onChangeCity}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Phone Number</Form.Label>
                                <PhoneInput
                                    size="sm"
                                    value={insurancePhone}
                                    onChange={(value) => onChangeValue('insurancePhone', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Comp</Form.Label>
                                <InputNumber
                                    size="sm"
                                    value={insuranceComp || ''}
                                    placeholder="Placeholder"
                                    showCurrency
                                    thousandSeparator
                                    onChange={(value) => onChangeValue('insuranceComp', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Collision</Form.Label>
                                <InputNumber
                                    size="sm"
                                    value={insuranceColl || ''}
                                    placeholder="Placeholder"
                                    showCurrency
                                    thousandSeparator
                                    onChange={(value) => onChangeValue('insuranceColl', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Policy</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={insurancePolicy}
                                    placeholder="Placeholder"
                                    maxLength="50"
                                    onChange={(e) => onChangeValue('insurancePolicy', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col} className="full-width-date-picker">
                                        <Form.Label>From</Form.Label>
                                        <DatePicker
                                            selected={Date.parse(insuranceDateFrom || new Date())}
                                            className={clsx('form-control', 'form-control-sm')}
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeValue('insuranceDateFrom', (date || new Date()))}
                                            maxDate={Date.parse(insuranceDate)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="full-width-date-picker">
                                        <Form.Label>To</Form.Label>
                                        <DatePicker
                                            selected={Date.parse(insuranceDate || new Date())}
                                            className={clsx('form-control', 'form-control-sm')}
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeValue('insuranceDate', (date || new Date()))}
                                            minDate={Date.parse(insuranceDateFrom)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    onClickSecondary={toggleModal}
                    onClickPrimary={onSave}
                    disablePrimaryButton={saving}
                />
            </Dialog>
        );
    }
}

InsuranceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    isDecodingZip: PropTypes.bool.isRequired,
    listCity: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeCity: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    saving: PropTypes.bool.isRequired,
};

export default withStyles(styles)(InsuranceDialogContainer(InsuranceDialog));

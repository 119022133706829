import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Dialog, DialogContent,
    makeStyles,
} from '@material-ui/core';
import { FetchPolicy, DataSort } from 'utils/enum/Core';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import DateUtils from 'lib/DateUtils';
import VirtualTable from 'components/widgets/VirtualTable';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';

const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            flexDirection: 'column',
        },
    },
    tableContainer: {
        width: '100%',
        height: '500px',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
        '& > div': {
            overflowY: 'hidden',
            overflowX: 'auto',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            marginLeft: '0px',
            paddingLeft: '5px',
            '& > div[class^="makeStyles-main"]': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '10px',
                },
            },
            '& > .react-datepicker-wrapper input': {
                textAlign: 'center',
                fontSize: '12px',
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    longText: {
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        whiteSpace: 'normal',
    },
}));

const DemosDetails = ({
    stockNumber,
    onlyLatest,
    onClose,
}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        demos: [],
        sort: {
            field: 'showedDate',
            dir: DataSort.DESC,
        },
    });

    const {
        data,
        loading,
        error,
    } = useQuery(InventoryQuery.PULL_DEMOS, {
        variables: {
            stockNumber,
            onlyLatest,
            sort: state.sort,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            setState((prevState) => ({
                ...prevState,
                demos: data.pullDemos,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onSort = ({ sortBy, sortDirection }) => {
        setState((prevState) => ({
            ...prevState,
            demos: [],
            sort: {
                field: sortBy,
                dir: sortDirection,
            },
        }));
    };

    const columns = [
        {
            label: 'Date',
            dataKey: 'showedDate',
            width: 100,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{DateUtils.format(record.showedDate)}</span>
                );
            },
        },
        {
            label: 'Lead Source',
            dataKey: 'leadSource',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.leadSource}</span>
                );
            },
        },
        {
            label: 'Deal Type',
            dataKey: 'dealType',
            width: 100,
            disableSort: false,
        },
        {
            label: 'Obstacle To Close',
            dataKey: 'obstacleToClose',
            width: 150,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={clsx(classes.text)}>{record.obstacleToClose}</span>
                );
            },
        },
        {
            label: 'Lost Reason',
            dataKey: 'lostReason',
            width: 150,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={clsx(classes.text)}>{record.lostReason}</span>
                );
            },
        },
        {
            label: 'Last Results',
            dataKey: 'lastResults',
            width: 400,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span title={record.lastResults} className={clsx(classes.text, classes.longText)}>{record.lastResults}</span>
                );
            },
        },
    ];

    const tableWidth = columns.reduce((a, b) => a + b.width, 0);
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Demos"
                onClose={() => onClose()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={loading}
                            rowHeight={65}
                            totalRecords={state.demos.length}
                            data={state.demos}
                            columns={columns}
                            sort={onSort}
                            sortBy={state.sort.field}
                            sortDirection={state.sort.dir}
                            width={tableWidth || 0}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

DemosDetails.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    onlyLatest: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DemosDetails;

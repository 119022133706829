/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import LoginStyles from 'styles/modules/LoginStyles';
import { Form, Button, InputGroup } from 'react-bootstrap';

// Material UI
import { makeStyles, CircularProgress, Tooltip } from '@material-ui/core';

// Http
import axios from 'axios';
import { isEmpty, isNaN } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { makeUseAxios } from 'axios-hooks';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useParams } from 'react-router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MainLayout from 'components/modules/login/MainLayout';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.43,
        color: theme.palette.text.white,
        marginBottom: '8px',
        display: 'block',
        textAlign: 'center',
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.background.white,
        borderRadius: '4px',
        '& input': {
            border: 'none',
        },
        '& input:focus': {
            outline: 'none',
            borderColor: 'transparent',
            boxShadow: 'none',
        },
        '& span': {
            border: 'none',
            background: 'none',
        },
    },
    tooltip: {
        background: 'rgba(0, 0, 0, 0.75)',
        width: '264px',
        fontSize: '14px',
        padding: theme.spacing(1.5),
        '& div div': {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '9px',
        },
        '& div div:last-child': {
            marginBottom: '0px',
        },
        '& div div svg': {
            marginRight: theme.spacing(1),
        },
    },
    checkIcon: {
        color: theme.palette.text.green,
    },
    closeIcon: {
        color: theme.palette.text.red,
    },
    ...LoginStyles.login(theme),
}));
const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: process.env.REACT_APP_API }),
});

/**
 * Verify that the password does not contain personal information
 * @param password {string}
 * @param personalInformation {object}
 * @returns {boolean}
 */
const validatePersonalInformation = (password = '', personalInformation = {}) => {
    for (const item of Object.values(personalInformation)) {
        if (
            item
            && typeof item === 'string'
            && item.length > 0
            && item.trim().length > 0
            && password.toLowerCase().includes(item.toLowerCase())
        ) {
            return true;
        }
    }

    return false;
};

/**
 *
 * @param password {string}
 * @param dateOfBirth {Date}
 * @returns {boolean}
 */
const validateDateOfBirth = (dateOfBirth, password = '') => {
    const currentDateOfBirth = dateOfBirth || new Date();
    const day = currentDateOfBirth.getDate();
    const month = currentDateOfBirth.getMonth() + 1;
    const year = currentDateOfBirth.getFullYear();

    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        // Build regular expression that doesn't allow the user's birth date
        const regex = new RegExp(
            `(0?(${day}|${month}))[\\/\\-\\.\\s]?(0?(${month}|${day}))[\\/\\-\\.\\s]?(${year}|${year
                .toString()
                .substring(2, 4)})`,
        );

        return password.match(regex) !== null;
    }

    return false;
};

const validateSpecialCharacters = (password) => {
    const specialCharacters = '!%*_+~-[]{}#=$'.split('');

    for (const element of specialCharacters) {
        if (password.includes(element)) return true;
    }

    return false;
};

const validatePassword = (password = '', confirmPassword = '', personalInformation) => {
    const dateOfBirth = new Date(personalInformation.birthday.toString());

    const validation = {
        match: password.length > 0 && password === confirmPassword,
        length: password.length >= 8,
        number: /\d/.test(password),
        lowercaseLetter: /[a-z]/.test(password),
        capitalLetter: /[A-Z]/.test(password),
        specialCharacter: validateSpecialCharacters(password),
        personalInformation: !(
            validateDateOfBirth(dateOfBirth, password)
            || validatePersonalInformation(password, personalInformation)
        ),
    };

    return {
        valid: Object.keys(validation).every((k) => validation[k] === true),
        validation,
    };
};

const PasswordReset = () => {
    const [confirmPassword, setConfirmPassword] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userInformation, setUserInformation] = useState({});
    const classes = useStyles();
    const { token } = useParams();
    const history = useHistory();

    const [{ loading, data }, getUserInformation] = useAxios({ url: `/users/password/passwordvalidatedata?token=${token}`, method: 'POST' }, { manual: true });
    const [{ loading: loadingForgotPassword }, forgotPassword] = useAxios({ url: '/users/password/reset', method: 'POST' }, { manual: true });

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            if (password !== confirmPassword) return;

            const result = await forgotPassword({ data: { password, token } });
            if (result?.data?.Success) {
                ModalUtils.successMessage([], result.data.Message);
                history.push('/login');
            } else {
                ModalUtils.errorMessage(result?.data?.Messages);
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.Messages);
        }
    };

    useEffect(() => {
        if (!isEmpty(data)) {
            setUserInformation({
                firstName: data.FirstName,
                lastName: data.LastName,
                birthday: data.DOB,
                email: data.Email,
                workEmail: data.WorkEmail,
                nationality: data.Nationality,
            });
        }
    }, [loading, data]);

    useEffect(() => {
        if (token) {
            getUserInformation();
        }
    }, [getUserInformation, token]);

    const renderIcon = (condition) => (condition
        ? <CheckIcon fontSize="small" className={classes.checkIcon} />
        : <CloseIcon fontSize="small" className={classes.closeIcon} />);

    const getValidationMessage = (validation) => {
        const {
            match,
            length,
            number,
            lowercaseLetter,
            capitalLetter,
            specialCharacter,
            personalInformation,
        } = validation;

        return (
            <div>
                <div>
                    {renderIcon(match)}
                    - Both passwords need to match.
                </div>
                <div>
                    {renderIcon(length)}
                    - 8-50 characters
                </div>
                <div>
                    {renderIcon(number)}
                    - At least one number.
                </div>
                <div>
                    {renderIcon(capitalLetter)}
                    - At least one capital letter.
                </div>
                <div>
                    {renderIcon(lowercaseLetter)}
                    - At least one lowercase letter.
                </div>
                <div>
                    {renderIcon(personalInformation)}
                    - Name, Email, Work Email, Birthday and Nationality are not allowed
                </div>
                <div>
                    {renderIcon(specialCharacter)}
                    - At least one of these special: !%*_+~-[]
                    {}
                    #=$
                </div>
            </div>
        );
    };

    const { valid = false, validation = {} } = isEmpty(userInformation) ? {} : validatePassword(password, confirmPassword, userInformation);
    const validationMessage = getValidationMessage(validation);

    return (
        <MainLayout>
            <Form onSubmit={(e) => handleLogin(e)}>
                <span className={classes.title}>Please enter your new password.</span>
                <Form.Group controlId="formPassword">
                    <Form.Label>New Password</Form.Label>
                    <Tooltip
                        placement="left"
                        title={validationMessage}
                        classes={{ tooltip: classes.tooltip }}
                        open={password?.trim()?.length > 0 && !valid}
                    >
                        <InputGroup className={classes.inputGroup}>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </InputGroup.Text>
                        </InputGroup>
                    </Tooltip>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className={classes.inputGroup}>
                        <Form.Control
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <InputGroup.Text onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Button variant="primary" type="submit">
                    {loadingForgotPassword ? <CircularProgress size="1em" color="inherit" />
                        : (
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                className=""
                                data-icon="login"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                {/* eslint-disable-next-line max-len */}
                                <path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z" />
                            </svg>
                        )}
                    <span className="icon">Reset Password</span>
                </Button>
            </Form>
        </MainLayout>
    );
};

export default PasswordReset;

import AccountingCBMapper from 'services/mapData/AccountingCBMapper';
import { ProcessType } from 'utils/enum/AccountingEnum';
import NumberUtils from 'lib/NumberUtils';

export default class AccountPayableMapper {
    static mapInvoiceToSave(record) {
        if (record) {
            return {
                invoiceId: record.invoiceId,
                vendorId: record.vendorId,
                vendorName: record.vendorName,
                invoiceNumber: record.invoiceNumber,
                billDate: record.billDate,
                invoiceAmount: NumberUtils.round(record.invoiceAmount),
                amountDue: NumberUtils.round(record.amountDue),
                dueDate: record.dueDate,
                memo: record.memo,
                isCredit: record.isCredit,
                lotId: record.lotId,
                accountNumber: record.accountNumber,
                accountingLines: AccountingCBMapper.mapGlLines(record.accountingLines),
                invoiceReferenceId: record.isCredit ? Number(record.invoiceReferenceId) : null,
            };
        }

        return {};
    }

    static mapInvoiceList(records) {
        if (records) {
            return records.map((record) => (
                {
                    invoiceId: record.invoiceId,
                    invoiceNumber: record.invoiceNumber,
                    billDate: record.billDate,
                    invoiceAmount: NumberUtils.round(record.invoiceAmount),
                    amountDue: NumberUtils.round(record.amountDue),
                    dueDate: record.dueDate,
                    memo: record.memo,
                    isCredit: record.isCredit,
                    status: record.status,
                    sysUser: record.sysUser,
                    vendorId: record.vendorId,
                    vendorName: record.vendorName,
                }
            ));
        }

        return [];
    }

    static mapBillData(records) {
        const result = {
            glLines: [],
        };

        records.forEach((item, index) => {
            result.glLines.push({
                entryId: (index + 1) * (-1),
                accountCOA: item.accountCOA,
                credit: NumberUtils.round(item.isCredit ? (-1 * item.amountDue) : item.amountDue),
                debit: 0,
                controlNumber: String(item.vendorId),
                lot: {
                    lotId: item.lotId,
                    lotName: '',
                },
                memo: item.invoiceNumber,
                processDetailRecordId: item.invoiceId,
                processTypeId: item.isCredit ? ProcessType.BILL_CREDIT : ProcessType.BILL,
            });
        });

        result.vendorId = records[0].vendorId;
        result.vendorName = records[0].vendorName;
        result.vendorAddress = records[0]?.vendor?.vendorAddress;

        return result;
    }

    static vendorBalanceMapper(records) {
        if (records) {
            return records.map((record) => (
                {
                    vendorId: Number(record.vendorId),
                    vendorName: record.vendorName,
                    vendorPhone: record.vendorPhone,
                    vendorAddress: record.vendorAddress,
                    vendorType: record.vendorType,
                    vendorNotes: record.vendorNotes,
                    balance: Number(record.balance),
                }
            ));
        }

        return [];
    }
}

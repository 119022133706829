const PRINTING_DOCUMENT_TYPE = Object.freeze({
    PDF: 'pdf',
    SPREADSHEET: 'spreadsheet',
});

const PRINTING_DOCUMENT_SOURCE_TYPE = Object.freeze({
    HTML: 'html',
    QUERY: 'query',
    NAMED_QUERY: 'named query',
    RECORDS: 'records',
});

const PAGE_FORMAT = Object.freeze({
    LETTER: 'Letter',
});

const PAGE_ORIENTATION = Object.freeze({
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape',
});

const FILE_TYPE = Object.freeze({
    SPREADSHEET: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
});

const NAMED_QUERY = Object.freeze({
    ACTIVE_VEHICLE_PARTS: 'ACTIVE_VEHICLE_PARTS',
    ACTIVE_VEHICLE_ZERO_QOH: 'ACTIVE_VEHICLE_ZERO_QOH',
    IN_ACTIVE_VEHICLE_PARTS: 'IN_ACTIVE_VEHICLE_PARTS',
    VEHICLE_PARTS_SEARCH: 'VEHICLE_PARTS_SEARCH',
});

const BUCKET_SOURCE = Object.freeze({
    IMAGES: 'Images',
});

const TRAINING_SECTION = Object.freeze({
    CATEGORIES: 'Categories',
    VIDEOS: 'Videos',
});

export {
    PRINTING_DOCUMENT_TYPE,
    PRINTING_DOCUMENT_SOURCE_TYPE,
    PAGE_FORMAT,
    PAGE_ORIENTATION,
    FILE_TYPE,
    NAMED_QUERY,
    BUCKET_SOURCE,
    TRAINING_SECTION,
};

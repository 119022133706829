import React from 'react';

// Material UI
import { makeStyles, Typography, Grid } from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import LabelValue from 'components/widgets/LabelValue';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.white,
        padding: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));

const CreditScoreCardHistory = ({ records, recordSelected, title }) => {
    const classes = useStyles();
    const placeHolder = '--';
    const record = records.find((item) => item.creditHistoryScoreId === recordSelected?.creditHistoryScoreId);

    return (
        <div className={clsx('box-shadow', classes.container)}>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <div className="flex-1">
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={6}
                    >
                        <LabelValue
                            label="Equifax"
                            value={record?.EQUIFAX || placeHolder}
                        />
                        <LabelValue
                            label="Experian"
                            value={record?.EXPERIAN || placeHolder}
                        />
                        <LabelValue
                            label="Transunion"
                            value={record?.TRANSUNION || placeHolder}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.customer || placeHolder}
                        xs={6}
                    >
                        <LabelValue
                            label="Date"
                            value={DateUtils.format(record?.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

CreditScoreCardHistory.propTypes = {
    title: PropTypes.string,
    records: PropTypes.array,
    recordSelected: PropTypes.object,
};

CreditScoreCardHistory.defaultProps = {
    records: [],
    recordSelected: null,
    title: 'Credit Score History',
};

export default CreditScoreCardHistory;

/* eslint-disable react-hooks/exhaustive-deps */
import KeyStore from 'utils/KeyStore';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import LoadingMask from 'components/widgets/LoadingMask';
import UserMutation from 'services/graphQL/mutate/core/UserMutation';

const keyStore = new KeyStore();

const SwitchToCompany = () => {
    const { token, loginmappingid: userLoginMappingId } = useParams();
    keyStore.clear();
    keyStore.save({ token });
    const [executeLogin] = useMutation(
        UserMutation.LOGIN_TO_COMPANY, { variables: { userLoginMappingId } },
    );

    useEffect(() => {
        executeLogin().then((result) => {
            const loginInformation = result?.data?.loginToCompany;
            if (loginInformation) {
                keyStore.clear();
                keyStore.save(loginInformation);
            }
        }).catch((errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        }).finally(() => {
            window.location.href = '/';
        });
    }, []);

    return (
        <LoadingMask />
    );
};

export default SwitchToCompany;

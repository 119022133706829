export default class TableStyles {
    static style() {
        return ({
            containerStyles: { height: '100%', flex: '1', overflow: 'hidden' },
            loadingStyle: {
                alignItems: 'center',
                display: 'flex',
                height: '40px',
                justifyContent: 'center',
            },
            footerStyle: {
                background: '#F5F5F5',
                display: 'block',
            },
        });
    }
}

import React from 'react';

import PropTypes from 'prop-types';
import {
    Grid,
    ButtonGroup,
    Button,
    makeStyles,
} from '@material-ui/core';
import ButtonStyle from 'styles/theme/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    title: {
        placeSelf: 'center',
        width: '200px',
        textAlign: 'center',
    },
    calendarTitle: {
        display: 'flex',
        flexDirection: 'row',
    },
    ...ButtonStyle.getStyle(theme),
}));

const NAV_GROUP = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREV',
    GO_TODAY: 'TODAY',
};

const VIEW_GROUP = {
    MONTH: {
        value: 'MONTH',
        view: 'dayGridMonth',
    },
    WEEK: {
        value: 'WEEK',
        view: 'timeGridWeek',
    },
    DAY: {
        value: 'DAY',
        view: 'timeGridDay',
    },
};

const Toolbar = (props) => {
    const classes = useStyles();
    const {
        onClickNavigationGroup,
        onClickViewGroup,
        currentDate,
        currentView,
    } = props;

    return (
        <Grid
            alignItems="flex-end"
            container
            justify="space-between"
            spacing={3}
            className={classes.root}
        >
            <Grid item>
                <ButtonGroup>
                    <Button
                        className={classes.outlinedInfo}
                        onClick={() => onClickNavigationGroup(NAV_GROUP.GO_TODAY)}
                    >
                        Today
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                <div className="d-flex-center">
                    <Button
                        variant="text"
                        onClick={() => onClickNavigationGroup(NAV_GROUP.PREVIOUS)}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                    <h3 className={classes.title}>{moment(currentDate).format('MMMM YYYY')}</h3>
                    <Button
                        variant="text"
                        onClick={() => onClickNavigationGroup(NAV_GROUP.NEXT)}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                </div>
            </Grid>
            <Grid item>
                <ButtonGroup>
                    <Button
                        className={VIEW_GROUP.MONTH.value === currentView.value ? classes.containedInfo : classes.outlinedInfo}
                        onClick={() => onClickViewGroup(VIEW_GROUP.MONTH)}
                    >
                        Month
                    </Button>
                    <Button
                        className={VIEW_GROUP.WEEK.value === currentView.value ? classes.containedInfo : classes.outlinedInfo}
                        onClick={() => onClickViewGroup(VIEW_GROUP.WEEK)}
                    >
                        Week
                    </Button>
                    <Button
                        className={VIEW_GROUP.DAY.value === currentView.value ? classes.containedInfo : classes.outlinedInfo}
                        onClick={() => onClickViewGroup(VIEW_GROUP.DAY)}
                    >
                        Day
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

Toolbar.propTypes = {
    currentDate: PropTypes.object.isRequired,
    currentView: PropTypes.object.isRequired,
    onClickNavigationGroup: PropTypes.func.isRequired,
    onClickViewGroup: PropTypes.func.isRequired,
};

export default Toolbar;

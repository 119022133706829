import React, { useReducer } from 'react';

import StringUtils from 'lib/StringUtils';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';
import If from 'components/widgets/conditional/If';
import { makeStyles } from '@material-ui/core/styles';
import MailList from 'components/modules/crm/mail/MailList';
import MailDetail from 'components/modules/crm/mail/MailDetail';
import MailSidebar from 'components/modules/crm/mail/MailSidebar';
import { modules } from 'utils/enum/modules';
import { Communication } from 'utils/enum/SubModule';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        flexDirection: 'row',
        color: 'rgb(33, 43, 54)',
        flex: 1,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: '#fff',
        overflow: 'hidden',
    },
    body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
    },
}));

const ACTION_TYPES = {
    TOGGLE_NEW_EMAIL: 'toggleNewEmail',
    TOGGLE_EMAIL_DETAIL: 'toggleEmailDetail',
    TOGGLE_EMAIL_CATEGORY: 'toggleEmailCategory',
};

const initState = {
    record: {},
    openDetail: false,
    category: 'Inbox',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.TOGGLE_EMAIL_DETAIL: {
        return update(state, {
            openDetail: { $set: action.value },
            record: { $set: action.record },
        });
    }
    case ACTION_TYPES.TOGGLE_EMAIL_CATEGORY: {
        return update(state, {
            category: { $set: action.category },
        });
    }
    default: return action.value;
    }
};

export default function Mail() {
    const { id } = useParams();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);

    const onOpenDetail = (record) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_EMAIL_DETAIL,
            value: true,
            record,
        });
    };

    const onItemClick = (category) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_EMAIL_CATEGORY,
            category,
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <MailSidebar onItemClick={onItemClick} category={state.category} />
                <main className={classes.body}>
                    <If condition={state.category === 'Inbox'}>
                        <MailList onOpen={onOpenDetail} />
                    </If>
                    <If condition={state.category === 'Spam'}>
                        <MailList category={state.category} onOpen={onOpenDetail} />
                    </If>
                    {!StringUtils.isEmpty(id) && <MailDetail threadId={id} to={`/${modules.COMMUNICATION}/${Communication.MAIL}`} />}
                </main>
            </div>
        </div>
    );
}

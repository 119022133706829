import React, { useContext, useState } from 'react';
import { makeStyles, fade, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from 'components/widgets/Container';
import { useHistory, useParams } from 'react-router-dom';
import SettingsStyles from 'styles/modules/accounting/AccountingStyles';
// widgets
import TabPanel from 'components/widgets/tabs/TabPanel';
import { modules } from 'utils/enum/modules';
import AccountingUtils from 'utils/AccountingUtils';
import { PartsPurchaseSubModules } from 'utils/enum/PartsPurchaseEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    AddShoppingCartOutlinedIcon, ExposureOutlinedIcon, PostAddOutlinedIcon, StoreOutlinedIcon, WidgetsOutlinedIcon,
} from 'components/icons';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import PartsUtils from 'utils/PartsUtils';
import PartsList from 'components/modules/purchaseParts/parts/list/PartsList';
import PartsTicketList from 'components/modules/purchaseParts/partsTicket/list/PartsTicketList';
import PurchaseList from 'components/modules/purchaseParts/purchaseOrder/list/PurchaseList';
import ReorderPartsList from 'components/modules/purchaseParts/parts/list/ReorderPartsList';
import UserContext from 'components/context/UserContext';
import { ReconCardType } from 'utils/enum/InventoryEnum';
import ReconCard from 'components/modules/dashboard/inventory/ReconCard';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanel(fade, theme));
const keyStore = new KeyStore();

const PurchasePartsTabPanel = () => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { submodule } = useParams();

    const subModules = PartsUtils.getSubModuleList();
    const [subModuleTab, setSubModuleTab] = useState(AccountingUtils.getIndexFromList(subModules, submodule || 0));

    const PARTS_READ = keyStore.hasPermission(Permission.PARTS_READ);
    const PARTS_TICKET_READ = keyStore.hasPermission(Permission.PARTS_TICKET_READ);
    const PURCHASE_READ = keyStore.hasPermission(Permission.PURCHASE_READ);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        history.push(`/${modules.PARTS}/${subModules[newValue]}`);
        setSubModuleTab(newValue);
    };

    return (
        <>
            <AppBar color="default" position="static">
                <Tabs value={subModuleTab} onChange={handleChange} scrollButtons="on">
                    {PARTS_READ
                    && <Tab label={isMobile ? '' : 'Parts Inventory'} icon={<WidgetsOutlinedIcon />} />}
                    {PARTS_READ
                    && <Tab label={isMobile ? '' : 'Reorder Parts'} icon={<ExposureOutlinedIcon />} />}
                    {PARTS_TICKET_READ
                    && <Tab label={isMobile ? '' : 'Parts Ticket'} icon={<PostAddOutlinedIcon />} />}
                    {PURCHASE_READ
                    && <Tab label={isMobile ? '' : 'Purchase Order'} icon={<AddShoppingCartOutlinedIcon />} />}
                    <Tab label={isMobile ? '' : 'Parts Request'} icon={<StoreOutlinedIcon />} />
                </Tabs>
            </AppBar>
            {PARTS_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, PartsPurchaseSubModules.PARTS)}
                    className={classes.box}
                >
                    <PartsList />
                </TabPanel>
            )}
            {PARTS_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, PartsPurchaseSubModules.REORDER_PARTS)}
                    className={classes.box}
                >
                    <ReorderPartsList />
                </TabPanel>
            )}
            {PARTS_TICKET_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, PartsPurchaseSubModules.PARTS_TICKET)}
                    className={classes.box}
                >
                    <PartsTicketList />
                </TabPanel>
            )}
            {PURCHASE_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, PartsPurchaseSubModules.PURCHASE_ORDER)}
                    className={classes.box}
                >
                    <PurchaseList />
                </TabPanel>
            )}
            <TabPanel
                value={subModuleTab}
                index={AccountingUtils.getIndexFromList(subModules, PartsPurchaseSubModules.PARTS_REQUEST)}
                className={classes.box}
            >
                <ReconCard type={ReconCardType.PARTS} />
            </TabPanel>
        </>
    );
};

const PurchasePartsLayout = () => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);

    if (!userInformation) return null;

    return (
        <Container className={classes.boxContainer}>
            <div className={classes.panelContainer}>
                <PurchasePartsTabPanel />
            </div>
        </Container>
    );
};

export default PurchasePartsLayout;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MenuItem from 'components/widgets/dropdown/MenuItem';

let timer = null;
const MOVE_DELAY = 500;

const Menu = ({
    items, pullRight, onSelect, setOpen, className,
}) => {
    const [activeItem, setActiveItem] = useState(null);
    const [pointer, setPointer] = useState({});

    const onMouseEnter = (event, item, index) => {
        // If the Menu is disabled, set its value to the initial state
        if (item.disabled) {
            setActiveItem(null);
            return;
        }

        // Get the current rect to set it in the callback to compare later the boundaries of the elements
        const rectangle = event.target.getBoundingClientRect();
        const callback = () => {
            setActiveItem({ ...item, index, rectangle });
        };

        // The timeout to enter has to be less than the timeout for leaving
        if (activeItem != null) {
            if (activeItem.index !== index) {
                if ((activeItem.items || []).length > 0) {
                    timer = setTimeout(callback, MOVE_DELAY);
                } else {
                    // If the previous active menu item doesn't have childs then set the current right away
                    callback();
                }
            }
        } else {
            callback();
        }
    };

    const onMouseLeave = () => {
        clearTimeout(timer);
    };

    const onMouseMove = (event, item, index) => {
        if (item.disabled) return;

        if (activeItem !== null && activeItem.index !== index) {
            const rectangle = event.target.getBoundingClientRect();
            const previousRectangle = activeItem.rectangle;

            const movingUp = rectangle.y < previousRectangle.y;
            const movingLeft = event.screenX < pointer.x;
            const movingRight = event.screenX > pointer.x;

            // If the mouse is moving up then we set immediately the current target
            // TODO: Include the condition for displaying the submenus when moving down
            if ((pullRight && (movingRight || movingUp)) || (!pullRight && (movingLeft || movingUp))) {
                clearTimeout(timer);
                setActiveItem({ ...item, index, rectangle });
            }
        }

        setPointer({ x: event.screenX, y: event.screenY });
    };

    const onSelectItem = (item) => {
        if (item.disabled) return;

        onSelect(item);

        setOpen(false);
    };

    const activeIndex = activeItem != null ? activeItem.index : null;

    return (
        <div className={clsx('dropdown-submenu', className, { 'pull-right': pullRight })}>
            {items.map((item, index) => (
                <MenuItem
                    key={`-${index}`}
                    {...item}
                    onSelect={onSelect}
                    pullRight={pullRight}
                    setOpen={setOpen}
                    open={activeIndex === index}
                    onClick={() => onSelectItem(item)}
                    onMouseEnter={(event) => onMouseEnter(event, item, index)}
                    onMouseLeave={(event) => onMouseLeave(event, item, index)}
                    onMouseMove={(event) => onMouseMove(event, item, index)}
                />
            ))}
        </div>
    );
};

Menu.propTypes = {
    items: PropTypes.array.isRequired,
    pullRight: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    className: PropTypes.string,
};

Menu.defaultProps = {
    className: '',
};

export default Menu;

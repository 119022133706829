import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
    map, find,
    isEqual, filter, findIndex,
} from 'lodash';
import DealMap from 'services/mapData/DealMap';
import DealService from 'services/modules/DealService';
import { ProductType, DealSection, DealTabsTitle } from 'utils/enum/DealEnum';

// Graphql
import ModalUtils from 'utils/ModalUtils';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import DealsProductQuery from 'services/graphQL/query/DealsProductQuery';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

const DealBackendContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        onChangeEditingMode: PropTypes.func.isRequired,
        callDealPreview: PropTypes.func.isRequired,
        dealId: PropTypes.number.isRequired,
        onCancelProduct: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.dealService = new DealService();
        this.graphqlClient = new GraphQLClient();

        this.state = {
            products: [],
            isAdding: false,
            previousProducts: [],
        };

        this.productsUpdated = null;
        this.initBind();
    }

    componentDidMount() {
        this.subscribeProductsUpdated();
        this.getServicesData();
    }

    componentWillUnmount() {
        this.unsubscribeProductsUpdated();
    }

    onDelete() {
        const { state: { productIdToRemove } } = this;

        if (productIdToRemove) {
            const input = {
                dealProductId: productIdToRemove,
            };

            this.graphqlClient
                .mutate(DealsMutate.DELETE_PRODUCT, input)
                .then((response) => {
                    const { data, graphQLErrors } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        return;
                    }

                    if (data && data.removeProduct) {
                        this.setState({
                            openDeleteProductDialog: false,
                        });
                    }
                });
        }
    }

    onChange(field, value, index) {
        const { props: { callDealPreview } } = this;
        this.setState((prevState) => ({
            products: update(prevState.products, {
                [index]: {
                    [field]: { $set: value },
                },
            }),
        }), () => {
            const { products } = this.state;
            const { dealId } = this.props;
            const record = map(products, (item) => ({
                price: item.price || 0,
                cost: item.cost || 0,
                type: item.type,
            }));
            const input = {
                dealId,
                products: record,
            };

            callDealPreview(input);
        });
    }

    onChangeType(value, index) {
        const { state: { products } } = this;
        const newRecord = update(products, {
            [index]: {
                type: { $set: value },
                vendor: {
                    vendorId: { $set: null },
                },
                product: {
                    productId: { $set: null },
                },
            },
        });

        this.setState({
            products: newRecord,
        });
    }

    onChangeCompany(value, index) {
        const { state: { products } } = this;
        const record = products[index];
        const optionPlan = this.getCurrentPlan(record.type, { value });
        const productId = optionPlan.length === 1 ? optionPlan[0].value : null;
        const newRecord = update(products, {
            [index]: {
                vendor: {
                    vendorId: { $set: value },
                },
                product: {
                    productId: { $set: productId },
                },
            },
        });

        this.setState({
            products: newRecord,
        });
    }

    onChangePlan(value, index) {
        const { state: { products } } = this;
        const newRecord = update(products, {
            [index]: {
                product: {
                    productId: { $set: value },
                },
            },
        });

        this.setState({
            products: newRecord,
        });
    }

    onSave() {
        const { state: { isSaving, products }, props: { onChangeEditingMode } } = this;
        if (isSaving) {
            return;
        }

        const productsCount = products.filter((x) => x.type === ProductType.PRODUCT).length;
        if (productsCount > 8) {
            ModalUtils.errorMessage([], 'You cannot save more than three Products not added to selling price.');
            return;
        }

        this.setState({
            isSaving: true,
        });

        const newRecord = this.getRecordsToSave();
        const data = map(newRecord, (item) => DealMap.mapSave(item));

        this.graphqlClient
            .mutate(DealsMutate.SAVE_PRODUCT, { data })
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (response.data) {
                    this.setState({
                        isReadOnly: true,
                    });
                    onChangeEditingMode();
                }
            }).finally(() => {
                this.setState({
                    isSaving: false,
                });
            });
    }

    onOpenDialog(productIdToRemove) {
        this.setState({
            open: true,
            productIdToRemove,
        });
    }

    onCloseDialog() {
        this.setState({
            open: false,
            productIdToRemove: null,
        });
    }

    onCancel() {
        const { props: { onChangeEditingMode, onCancelProduct }, state: { previousProducts } } = this;

        onChangeEditingMode();
        this.setState((prevState) => ({
            isReadOnly: !prevState.isReadOnly,
            products: update(prevState.products, { $set: previousProducts }),
        }));

        onCancelProduct();
    }

    getInitStateNewProduct() {
        const { props: { dealId } } = this;

        return {
            addToSellingPrice: false,
            cost: null,
            dealId,
            dealProductId: null,
            policyNumber: '',
            price: null,
            type: '',
            vendor: {
                vendorId: null,
            },
            product: {
                productId: null,
            },
        };
    }

    getServicesData() {
        const { props: { dealId } } = this;
        const args = { dealId };

        this.setState({ load: true });
        this.dealService
            .getProducts(args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data?.products) {
                    const { products } = data;

                    this.setState({
                        products,
                        previousProducts: products,
                    });
                }
            })
            .finally(() => {
                this.setState({ load: false });
                this.getVendorsAndProducts();
            });
    }

    getCurrentPlan(productType, record) {
        const { state: { vendorProducts } } = this;
        const currentProductType = productType === ProductType.VSC ? ProductType.WARRANTY : productType;

        if (record) {
            const currentCompany = find(vendorProducts, { productType: currentProductType });

            if (currentCompany && currentCompany.vendor) {
                const { vendor } = currentCompany;
                const currentPlan = find(vendor, (item) => item.vendorId === record.value);
                const currentProduct = map((currentPlan.products || []), (item) => ({ value: item.productId, label: item.productName }));

                return currentProduct;
            }

            return [];
        }

        return [];
    }

    getCurrentCompany(value) {
        const { state: { vendorProducts } } = this;
        const currentProductType = value === ProductType.VSC ? ProductType.WARRANTY : value;
        const currentProduct = find(vendorProducts, { productType: currentProductType });

        if (currentProduct) {
            const currentCompany = map(currentProduct.vendor, (item) => ({ value: item.vendorId, label: item.vendorName }));
            return currentCompany;
        }

        return [];
    }

    getVendorsAndProducts() {
        this.graphqlClient
            .query(DealsProductQuery.GET_VENDOR_DEAL_PRODUCTS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVendorProducts) {
                    const { getVendorProducts: vendorProducts } = data;
                    const currentVendors = DealMap.mapProduct(vendorProducts);

                    this.setState({
                        vendorProducts: currentVendors,
                    });
                }
            });
    }

    getRecordsToSave() {
        const { products, previousProducts } = this.state;

        return filter(products, (item) => {
            const currentProduct = find(previousProducts, { dealProductId: item.dealProductId });
            if (!isEqual(currentProduct, item)) {
                return item;
            }
            return null;
        });
    }

    removeProduct(index) {
        const { state } = this;
        const products = update(state.products, { $splice: [[index, 1]] });

        this.setState({ products });
    }

    addOrEdit(isAdding = true) {
        const { state: { products }, props: { onChangeEditingMode } } = this;
        const record = [...products];

        onChangeEditingMode(true, DealSection.BACK_END, DealTabsTitle.DEAL);

        if (isAdding) {
            record.push(this.getInitStateNewProduct());
        }

        this.setState((prevState) => ({
            isReadOnly: false,
            isAdding,
            products: update(prevState.products, { $set: record }),
        }));
    }

    subscribeProductsUpdated() {
        const { props: { dealId } } = this;
        const input = {
            dealId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.PRODUCTS_UPDATED, input)
            .then((response) => {
                this.productsUpdated = response;
            });
    }

    responseSubscription(record) {
        const { data: { productsUpdated } } = record;
        const { products, previousProducts } = this.state;
        let productsListUpdated = [...products];
        let previousProductsListUpdated = [...previousProducts];

        if (productsUpdated?.deleted) {
            const { deleted } = productsUpdated;
            const index = findIndex(products, { dealProductId: deleted });

            if (index > -1) {
                productsListUpdated = update(productsListUpdated, { $splice: [[index, 1]] });
                previousProductsListUpdated = update(previousProductsListUpdated, { $splice: [[index, 1]] });
            }
        }

        if (productsUpdated?.added?.length > 0) {
            const { added } = productsUpdated;

            productsListUpdated = update(filter(productsListUpdated, (item) => !!item.dealProductId), { $push: added });
            previousProductsListUpdated = update(filter(previousProductsListUpdated, (item) => !!item.dealProductId), { $push: added });
        }

        if (productsUpdated?.updated?.length > 0) {
            const { updated } = productsUpdated;

            updated.forEach((item) => {
                const index = findIndex(products, { dealProductId: item.dealProductId });

                if (index > -1) {
                    productsListUpdated = update(productsListUpdated, {
                        [index]: {
                            $set: item,
                        },
                    });
                    previousProductsListUpdated = productsListUpdated;
                }
            });
        }

        this.setState({
            products: productsListUpdated,
            previousProducts: previousProductsListUpdated,
        });
    }

    unsubscribeProductsUpdated() {
        if (this.productsUpdated) {
            this.productsUpdated.unsubscribe();
        }
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.addOrEdit = this.addOrEdit.bind(this);
        this.onOpenDialog = this.onOpenDialog.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangePlan = this.onChangePlan.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getCurrentPlan = this.getCurrentPlan.bind(this);
        this.getVendorsAndProducts = this.getVendorsAndProducts.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.getRecordsToSave = this.getRecordsToSave.bind(this);
        this.getCurrentCompany = this.getCurrentCompany.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeProductsUpdated = this.subscribeProductsUpdated.bind(this);
        this.unsubscribeProductsUpdated = this.unsubscribeProductsUpdated.bind(this);
    }

    render() {
        const { props, state } = this;
        const allowSave = this.getRecordsToSave().length > 0;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onChange={this.onChange}
                onDelete={this.onDelete}
                onCancel={this.onCancel}
                addOrEdit={this.addOrEdit}
                onChangePlan={this.onChangePlan}
                onOpenDialog={this.onOpenDialog}
                removeProduct={this.removeProduct}
                onCloseDialog={this.onCloseDialog}
                onChangeType={this.onChangeType}
                getCurrentPlan={this.getCurrentPlan}
                onChangeCompany={this.onChangeCompany}
                getCurrentCompany={this.getCurrentCompany}
                allowSave={allowSave}
            />
        );
    }
};

export default DealBackendContainer;

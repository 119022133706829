import { gql } from '@apollo/client';

export default class SettingsMutation {
    static CREATE_PURCHASED_FROM = gql`
        mutation createPurchasedFrom($name: String!) {
            createPurchasedFrom(name: $name)
        }
    `;

    static CREATE_PURCHASING_AGENT = gql`
        mutation createPurchasingAgent($name: String!) {
            createPurchasingAgent(name: $name)
        }
    `;

    static EDIT_PURCHASING_AGENT = gql`
        mutation editPurchasingAgent($id: Int!, $name: String!) {
            editPurchasingAgent(id: $id, name: $name)
        }
    `;

    static DELETE_PURCHASING_AGENT = gql`
        mutation deletePurchasingAgent($id: Int!) {
            deletePurchasingAgent(id: $id)
        }
    `;

    static SAVE_MENU_SETTING = gql`
        mutation saveSideMenuSetting($userSettingId: ID, $key: String!, $value: String!) {
            saveSideMenuSetting(userSettingId: $userSettingId, key: $key, value: $value)
        }
    `;
}

import { gql } from '@apollo/client';

export default class WindowStickersMutation {
    static SAVE_FORMS_BY_DEALER = gql`
        mutation mergeFormsByDealer(
            $input: [FormTableInput!]!
        ) {
            mergeFormsByDealer(
                input: $input
            )
        }
    `;

    static PRINT_FORMS = gql`
        mutation printWindowStickers($input: [PrintInput!]!) {
            printWindowStickers(input: $input)
        }
    `;

    static UPDATE_WARRANTY = gql`
        mutation updateWarranty(
            $input: WarrantyUpdateInput!
        ) {
            updateWarranty(
                input: $input
            )
        }
    `;

    static CREATE_WARRANTY = gql`
        mutation createWarranty(
            $input: WarrantyInput!
        ) {
            createWarranty(
                input: $input
            )
        }
    `;
}

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import update from 'immutability-helper';

import useAxios from 'axios-hooks';
import { useQuery, useMutation } from '@apollo/client';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import VendorMutation from 'services/graphQL/mutate/VendorMutation';
import MapInventoryData from 'services/mapData/MapInventoryData';

import {
    makeStyles,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import { Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';

import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import AsyncSelect from 'react-select/async';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { DataSort, HttpMethods, FetchPolicy } from 'utils/enum/Core';
import { VendorType } from 'utils/enum/Vendors';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import HttpClient from 'services/api/HttpClient';

const useStyles = makeStyles({
    dialog: {
        zIndex: '9000 !important',
    },
    dialogContent: {
        paddingTop: '30px',
        paddingBottom: '30px',
        overflow: 'hidden',
    },
    row: {
        margin: 0,
        marginBottom: 20,
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 70,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
        '.css-26l3qy-menu > div': {
            maxHeight: '130px',
        },
    },
});
const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_FIELD: 'setField',
    SET_MULTI_FIELDS: 'setMultiFields',
};
const initState = {
    vendorName: '',
    amount: 0,
    categoryName: '',
    checkNo: '',
    datePaid: DateUtils.getUTCDate().toDate(),
    comment: '',
    newCategory: null,
    vendorCategories: [],
};
const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPES.SET_RECORD:
        return payload;
    case ACTION_TYPES.SET_FIELD:
        return update(state, {
            [payload?.field]: { $set: payload?.value },
        });
    case ACTION_TYPES.SET_MULTI_FIELDS:
        const newValues = {};
        payload.forEach((item) => {
            const { name, value } = item;
            newValues[name] = value;
        });

        return {
            ...state,
            ...newValues,
        };
    default:
        return initState;
    }
};
const getRequiredFieldsCompleted = (vendorName, categoryName, datePaid, amount) => StringUtils.isEmpty(vendorName)
    || StringUtils.isEmpty(categoryName) || StringUtils.isEmpty(datePaid) || amount <= 0;
const SortFields = {
    VENDOR_NAME: 'vendorName',
    CATEGORY: 'category',
};

const RepairsExpensesForm = ({
    stockNumber,
    openDialog,
    onClose,
    onReload,
    isEditing,
    record,
    totalRepairs,
}) => {
    const classes = useStyles();
    const [data, dispatchData] = useReducer(reducer, initState);

    const [
        executeCreateCategory,
        { loading: loadingCreateCategory },
    ] = useMutation(VendorMutation.CREATE_VENDOR_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                const vendorCategoriesId = response?.createVendorCategory;
                dispatchData({
                    type: ACTION_TYPES.SET_MULTI_FIELDS,
                    payload: [
                        {
                            name: 'vendorCategories',
                            value: [...data.vendorCategories, {
                                vendorCategoriesId,
                                category: data.newCategory,
                            }],
                        },
                        {
                            name: 'categoryName',
                            value: data.newCategory,
                        },
                    ],
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [
        { loading: loadingCreate },
        executeCreate,
    ] = useAxios({
        url: HttpClient.getURLRepairCreate(stockNumber),
        method: HttpMethods.POST,
    }, { manual: true });

    const [
        { loading: loadingUpdate },
        executeUpdate,
    ] = useAxios({
        url: HttpClient.getURLRepairUpdate(stockNumber, record.inventoryRepairId),
        method: HttpMethods.PUT,
    }, { manual: true });

    useEffect(() => {
        const datePaid = record.datePaid || DateUtils.getUTCDate();

        dispatchData({
            type: ACTION_TYPES.SET_RECORD,
            payload: {
                vendorName: record.vendorName || '',
                amount: record.amount || 0,
                categoryName: record.categoryName || '',
                checkNo: record.checkNo || '',
                datePaid: DateUtils.toLocal(datePaid).toDate(),
                comment: record.comment || '',
            },
        });
    }, [record]);

    const vendorListVariables = {
        variables: {
            filter: {
                vendorType: [VendorType.EXPENSES, VendorType.REPAIR_VENDOR],
            },
            sort: {
                field: SortFields.VENDOR_NAME,
                dir: DataSort.ASC,
            },
        },
    };
    const vendorCategoriesVariables = {
        variables: {
            sort: {
                field: SortFields.CATEGORY,
                dir: DataSort.ASC,
            },
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    };

    const {
        data: vendorListResponse,
        loading: loadingVendorList,
        error: vendorListError,
    } = useQuery(VendorQuery.GET_VENDOR_LIST, vendorListVariables);

    const {
        data: vendorCategoriesResponse,
        loading: loadingVendorCategories,
        error: vendorCategoriesError,
    } = useQuery(VendorQuery.GET_VENDOR_CATEGORIES, vendorCategoriesVariables);

    if (vendorListError) {
        ModalUtils.errorMessage(vendorListError?.graphQLErrors);
    }

    useEffect(() => {
        if (vendorCategoriesError) {
            ModalUtils.errorMessage(vendorCategoriesError?.graphQLErrors);
        }

        if (!loadingVendorCategories) {
            dispatchData({
                type: ACTION_TYPES.SET_FIELD,
                payload: {
                    field: 'vendorCategories',
                    value: vendorCategoriesResponse?.getVendorCategories,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVendorCategories, vendorCategoriesError]);

    const vendorListData = map(vendorListResponse?.getVendorList, (item) => ({
        value: item.vendorName,
        label: item.vendorName,
    }));

    const vendorCategoriesData = map(data.vendorCategories, (item) => ({
        value: item.category,
        label: item.category,
    }));

    const onSave = async () => {
        try {
            if (isEditing) {
                const response = await executeUpdate({
                    data: MapInventoryData.mapSaveRepair(data),
                });

                if (response?.data?.Success) {
                    ModalUtils.successMessage(null, 'Record updated successfully!');

                    const newTotal = (totalRepairs - record.amount) + data.amount;

                    onReload(newTotal);
                    onClose();
                } else {
                    ModalUtils.errorMessage(response?.data?.Messages, response?.data?.Message);
                }
            } else {
                const response = await executeCreate({
                    data: MapInventoryData.mapSaveRepair(data),
                });

                if (response?.data?.Success) {
                    ModalUtils.successMessage(null, 'Record created successfully!');

                    const newTotal = totalRepairs + data.amount;

                    onReload(newTotal);
                    onClose();
                } else {
                    ModalUtils.errorMessage(response?.data?.Messages, response?.data?.Message);
                }
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.errors);
        }
    };
    const disableSaveButton = getRequiredFieldsCompleted(data.vendorName, data.categoryName, data.datePaid, data.amount)
        || loadingCreate || loadingUpdate;
    const onChange = (field, value) => {
        dispatchData({
            type: ACTION_TYPES.SET_FIELD,
            payload: {
                field,
                value,
            },
        });
    };

    const onCreateCategory = async (value) => {
        dispatchData({
            type: ACTION_TYPES.SET_FIELD,
            payload: {
                field: 'newCategory',
                value: value.toUpperCase(),
            },
        });

        executeCreateCategory({ variables: { category: value } });
    };

    const maximumVendorItemsShown = 20;
    const selectedVendor = vendorListData.find((option) => option.value === data.vendorName);
    const getAsyncOptionsFiltered = (inputValue) => new Promise((resolve) => {
        const filtered = vendorListData
            .filter((item) => item.value.toLowerCase().includes(inputValue.toLowerCase()));

        resolve(filtered.slice(0, maximumVendorItemsShown));
    });

    const title = isEditing ? `Edit Repair/Expenses: ${record.inventoryRepairId}` : 'Add Repair/Expenses';
    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    return (
        <Dialog
            open={openDialog}
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
        >
            <DialogAppBar
                title={title}
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Form.Row className={classes.row}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Vendor</Form.Label>
                            <AsyncSelect
                                className={clsx(
                                    'select-bootstrap select-sm',
                                    StringUtils.isEmpty(selectedVendor) ? 'invalid-field' : '',
                                )}
                                isLoading={loadingVendorList}
                                value={selectedVendor}
                                defaultOptions={vendorListData.slice(0, maximumVendorItemsShown)}
                                loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                                onChange={(option) => onChange('vendorName', option.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Amount</Form.Label>
                            <InputNumber
                                className={clsx({ 'invalid-field': data.amount <= 0 })}
                                showCurrency
                                thousandSeparator
                                placeholder="$0.00"
                                value={data.amount}
                                onChange={(value) => onChange('amount', value)}
                                size="sm"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.row}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Category</Form.Label>
                            <Select
                                sorted
                                nowrap
                                creatable
                                size="sm"
                                className={clsx({ 'invalid-field': StringUtils.isEmpty(data.categoryName) })}
                                name="categoryName"
                                onChange={onChange}
                                onCreate={(value) => onCreateCategory(value)}
                                value={data.categoryName}
                                options={vendorCategoriesData}
                                loading={loadingVendorCategories || loadingCreateCategory}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Check #</Form.Label>
                            <InputNumber
                                value={data.checkNo}
                                onChange={(value) => onChange('checkNo', value)}
                                size="sm"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.row}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Date Paid</Form.Label>
                            <DatePicker
                                className={clsx('form-control', 'form-control-sm', { 'invalid-field': StringUtils.isEmpty(data.datePaid) })}
                                onChange={(date) => onChange('datePaid', date)}
                                selected={data.datePaid}
                                popperContainer={CalendarContainer}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label>Details</Form.Label>
                            <Form.Control
                                onChange={(e) => onChange('comment', e.target.value)}
                                value={data.comment || ''}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                disablePrimaryButton={disableSaveButton}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                variant="contained"
                hiddenPrimaryButton={!INVENTORY_VEHICLE_WRITE}
            />
        </Dialog>
    );
};

RepairsExpensesForm.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    openDialog: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    record: PropTypes.object,
    onReload: PropTypes.func,
    totalRepairs: PropTypes.number.isRequired,
};

RepairsExpensesForm.defaultProps = {
    openDialog: false,
    isEditing: false,
    record: {},
    onReload: () => {},
};

export default RepairsExpensesForm;

import { gql } from '@apollo/client';

export default class LeadSourcesMutation {
    static DELETE_LEAD_SOURCE = gql`
        mutation deleteLeadSource($leadSourceId: Int!) {
            deleteLeadSource(leadSourceId: $leadSourceId)
        }
    `;

    static SAVE_LEAD_SOURCE = gql`
        mutation saveLeadSource($input: LeadSourceInput!) {
            saveLeadSource(input: $input)
        }
    `;
}

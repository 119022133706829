const PROVIDER = Object.freeze({
    NON_MENU: 'Non Menu',
    DEALER_PROVIDED: 'Dealer Provided',
    FI_EXPRESS: 'F&I Express',
    STONE_EAGLE: 'Stone Eagle',
});

export const PACKAGE = Object.freeze({
    PREMIUM: 'Premium',
    PREFERRED: 'Preferred',
    ECONOMY: 'Economy',
});

export const PRICING_SOURCE = Object.freeze({
    NO_PRICES: 'No Prices',
    SHOW_TOTAL_PRICE: 'Show Total Price',
    SHOW_COST_DETAILS: 'Show Cost Details',
});

export const PACKAGE_DISTRIBUTION = Object.freeze({
    PARTIAL_FILL: 'Partial Fill',
    FULL_FILL: 'Full Fill',
});

export const DEDUCTIBLE_TYPE = Object.freeze({
    STANDARD: '',
    DISAPPEARING: 'DISP',
    REDUCING: 'REDUCING',
    PERCENT: 'PERCENT',
});

export const REGULATED_RULE = Object.freeze({
    NON_REGULATED: 0,
    RETAIL_RATE_NON_CHANGABLE: 3,
    MARKUP_IN_RANGE: 5,
    CONDITIONED_RETAIL_RATE: 6,
});

export const ADDITIONAL_INFO_FIELD_TYPE = Object.freeze({
    ALPHANUMERIC: 'alphanumeric',
    NUMERIC: 'numeric',
    DATE: 'date',
    CURRENCY: 'currency',
});

export const KNOWN_ERROR = Object.freeze({
    GENERAL: 'there was an error trying to call the web service',
    TIMEOUT: 'timeout',
    XML: 'error in XML document',
    OTHER: 'reference not set to an instance of an object',
    NO_RATES: 'no rates available',
    AUTHENTICATION: 'authentication failed',
    TRIM: 'trim is invalid',
    PRE_RATING: 'specify all parameters returned by prerating',
    NO_COVERAGE: 'no coverage',
    UNABLE_FETCH_RATES: ' unable to fetch rates',
});

export const MENU_STAGE = Object.freeze({
    PRESENTATION: 'Presentation',
    CONTRACT: 'Contract',
});

export const MENU_CONTRACT_STATUS = Object.freeze({
    GENERATED: 'Generated',
    VOIDED: 'Voided',
});

export const PRE_RATING_SURCHARGE_TYPE = Object.freeze({
    SUPPLEMENTAL: 'supplemental',
    OPTIONAL: 'optional',
});

export const PRE_RATING_SURCHARGE_DATA_TYPE = Object.freeze({
    DATE: 'date',
    NUMERIC: 'numeric',
    BOOLEAN: 'boolean',
    STRING: 'string',
    DECIMAL: 'decimal',
    ENUM: 'enum',
});

export const PRODUCT_CODE = Object.freeze({
    MAINT: 'MAINT',
    VSCMAINT: 'VSCMAINT',
    PPM: 'PPM',
    TIRE: 'TIRE',
});

export default PROVIDER;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Divider } from '@material-ui/core';
import { ReactComponent as NoteIcon } from 'assets/crm/note.svg';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    body: {
        padding: theme.spacing(3, 2),
        maxHeight: ({ maxHeightBody }) => maxHeightBody,
        overflow: 'hidden',
        display: 'flex',
    },
    note: {
        wordBreak: 'break-word',
    },
}));

const ActivityItem = ({ record, maxHeightBody }) => {
    const classes = useStyles({ maxHeightBody });

    return (
        <div className={clsx(classes.container, 'box-shadow')}>
            <div className={classes.header}>
                <div className="d-flex-center">
                    <NoteIcon />
                    <span className={classes.title}>Note</span>
                </div>
                <CreatedOnWithAvatar
                    record={record}
                />
            </div>
            <Divider />
            <div className={classes.body}>
                <div className={clsx(classes.note, 'text-pre-line')}>
                    {record.note}
                </div>
            </div>
        </div>
    );
};

ActivityItem.propTypes = {
    record: PropTypes.object.isRequired,
    maxHeightBody: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

ActivityItem.defaultProps = {
    maxHeightBody: 'initial',
};

export default ActivityItem;

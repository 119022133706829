import { gql } from '@apollo/client';

export default class PayrollGeneralQuery {
    static GET_PAYROLL_EMPLOYEE_LIST = gql`
        query getPayrollEmployeesList($input: PayrollEmployeeListInput!) {
            getPayrollEmployeesList(input: $input) {
                id
                salary
                lotName
                payType
                department
                quickNotes
                loanBalance
                employeeName
                employeeType
                pendingFinalPayment
            }
        }
    `;

    static GET_PAY_PERIODS = gql`
        query getPayPeriods($lotName: String!, $year: Int!) {
            getPayPeriods(lotName: $lotName, year: $year)
        }
    `;

    static EMPLOYEE_HAS_CHECK = gql`
        query employeeHasCheck($employeeId: Int!, $payPeriod: String!) {
            employeeHasCheck(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;
}

import GraphQLClient from 'services/apollo/GraphQLClient';
import MenuQuery from 'services/graphQL/query/menu/MenuQuery';

export default class MenuService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Get External Providers
     */
    async getExternalProviders() {
        return this.graphqlClient
            .query(MenuQuery.PULL_EXTERNAL_PROVIDERS)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return {
                    data,
                };
            });
    }

    /**
     * Get External Providers
     */
    async getExternalProducts(providerId) {
        return this.graphqlClient
            .query(MenuQuery.PULL_EXTERNAL_PRODUCTS, { providerId })
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return {
                    data,
                };
            });
    }
}

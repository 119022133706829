import { gql } from '@apollo/client';

export default class ServiceSubscription {
static SERVICE_INVOICE_SUBSCRIPTION = gql`
subscription serviceInvoiceChanged {
  serviceInvoiceChanged {
    id
    data
    action
    companyCode
  }
}
`;

static OP_CODE_SUBSCRIPTION = gql`
subscription opCodeChanged {
  opCodeChanged {
    id
    data
    action
    companyCode
  }
}
`;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Dialog, DialogContent,
    makeStyles, Button,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import ButtonStyles from 'styles/theme/Button';
import Loading from 'components/widgets/Loading';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ImageViewer from 'components/modules/inventory/read/panels/gallery/ImageViewer';
import PhotosGallery from 'components/widgets/inventory/PhotosGallery';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import If from 'components/widgets/conditional/If';

// Icons
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
        position: 'relative',
    },
    dragzone: {
        cursor: 'pointer',
        border: `1px dashed ${theme.palette.border.alto}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 10px',
        transition: 'border-color .3s',
        '& > svg': {
            fill: theme.palette.border.ghost,
            width: '20px',
            height: '20px',
        },
    },
    uploadText: {
        color: theme.palette.text.darkShark,
        margin: '0 10px 0 0',
        fontSize: '14px',
    },
    dropzoneContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& > button': {
            marginLeft: '10px',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            '& > div': {
                width: '100%',
                marginBottom: '10px',
            },
            '& > button': {
                width: '100%',
                marginLeft: 0,
            },
        },
    },
    photosContainer: {
        marginTop: '20px',
        width: '100%',
        height: '50vh',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    loader: {
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: '999',
        backgroundColor: theme.palette.background.white,
        width: '100%',
        height: '100%',
        opacity: '0.5',
    },
}));

const InspectionImagesDialog = ({
    isPicturesFormOpen,
    isUploadingImages,
    openImagePreview,
    addImages,
    canWrite,
    togglePicturesForm,
    selectedItemImages,
    onOpenImage,
    photoIndex,
    toggleImagePreview,
    setPhotoIndex,
    moveType,
    isApproved,
    onDeleteImages,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        selectedImages: [],
        isDeleteWarningOpen: false,
    });

    const toggleImagesDeletionPrompt = (resetSelectedImages = false) => {
        setState({
            selectedImages: resetSelectedImages ? [] : state.selectedImages,
            isDeleteWarningOpen: !state.isDeleteWarningOpen,
        });
    };

    const toogleImageSelection = (index, value) => {
        let clone = [...state.selectedImages];

        if (value) {
            clone.push(index);
        } else {
            clone = clone.filter((ix) => ix !== index);
        }

        setState({
            ...state,
            selectedImages: clone,
        });
    };

    return (
        <Dialog
            open={isPicturesFormOpen}
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Pictures"
                onClose={() => togglePicturesForm()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <If condition={isUploadingImages}>
                        <Loading className={classes.loader} />
                    </If>
                    <If condition={canWrite && !isApproved}>
                        <div className={classes.dropzoneContainer}>
                            <Dropzone
                                disabled={isUploadingImages}
                                multiple
                                accept="image/*"
                                onDrop={addImages}
                                className={classes.dragzone}
                            >
                                <p className={classes.uploadText}>
                                    Click or drag file to this area to upload
                                </p>
                                <InboxOutlinedIcon />
                            </Dropzone>
                            <Button
                                disabled={state.selectedImages.length === 0 || isUploadingImages}
                                className={clsx(classes.containedError, classes.actionButton)}
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleImagesDeletionPrompt()}
                            >
                                Delete
                            </Button>
                        </div>
                    </If>
                    <div className={classes.photosContainer}>
                        {(selectedItemImages || []).map(({ imageURL }, index) => (
                            <ImageViewer
                                canWrite={canWrite && !isApproved}
                                image={imageURL}
                                openImage={onOpenImage}
                                isReorderModeOn={false}
                                index={index}
                                selectedImages={state.selectedImages}
                                toogleImageSelection={toogleImageSelection}
                                key={`image-${index}`}
                            />
                        ))}
                    </div>
                    <PhotosGallery
                        photos={(selectedItemImages || []).map((el) => el.imageURL)}
                        photoIndex={photoIndex}
                        isOpen={openImagePreview}
                        onCloseRequest={toggleImagePreview}
                        onMovePrevRequest={() => setPhotoIndex(moveType.PREVIOUS)}
                        onMoveNextRequest={() => setPhotoIndex(moveType.NEXT)}
                    />
                    <ConfirmDialog
                        title="Attention!"
                        description="Do you want to delete these images?"
                        open={state.isDeleteWarningOpen}
                        variant="outlined"
                        titlePrimary="Yes"
                        titleSecondary="Cancel"
                        onClose={() => toggleImagesDeletionPrompt()}
                        onClickSecondary={() => toggleImagesDeletionPrompt()}
                        onClickPrimary={() => onDeleteImages(state.selectedImages, toggleImagesDeletionPrompt)}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

InspectionImagesDialog.propTypes = {
    isPicturesFormOpen: PropTypes.bool.isRequired,
    isUploadingImages: PropTypes.bool,
    openImagePreview: PropTypes.bool.isRequired,
    addImages: PropTypes.func,
    canWrite: PropTypes.bool,
    togglePicturesForm: PropTypes.func.isRequired,
    selectedItemImages: PropTypes.array,
    onOpenImage: PropTypes.func.isRequired,
    photoIndex: PropTypes.number,
    toggleImagePreview: PropTypes.func.isRequired,
    setPhotoIndex: PropTypes.func.isRequired,
    moveType: PropTypes.object,
    isApproved: PropTypes.bool.isRequired,
    onDeleteImages: PropTypes.func,
};

InspectionImagesDialog.defaultProps = {
    selectedItemImages: [],
    photoIndex: -1,
    moveType: {},
    isUploadingImages: false,
    addImages: () => null,
    canWrite: false,
    onDeleteImages: () => null,
};

export default InspectionImagesDialog;

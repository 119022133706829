import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import History from 'components/widgets/History';
import { useQuery } from '@apollo/client';
import NotesQuery from 'services/graphQL/query/NotesQuery';

const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS:
        return action.payload;
    default:
        return [];
    }
};

const PortfolioHistoryTab = ({ portfolioId, className }) => {
    const [records, dispatch] = useReducer(reducer, []);
    const { data, error, loading } = useQuery(NotesQuery.GET_PORTFOLIO_NOTES, {
        variables: { portfolioId },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                payload: data?.getPortfolioNotes,
            });
        }
    }, [data, loading]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    return (
        <History
            loading={loading}
            records={records}
            className={className}
        />
    );
};

PortfolioHistoryTab.propTypes = {
    className: PropTypes.string,
    portfolioId: PropTypes.number.isRequired,
};

PortfolioHistoryTab.defaultProps = {
    className: null,
};

export default PortfolioHistoryTab;

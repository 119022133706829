import { cloneDeep, includes, keys } from 'lodash';
import StringUtils from 'lib/StringUtils';

// TODO: Pending create unit test
export default class ObjectUtils {
    /**
     * This method renames the key of the an object
     * @param obj {}
     * @param key Key to rename
     * @param newKey Name to the new Key
     * @example ({ a: 'test'}, 'a', 'newKey') => {newKey: 'test}
     * @example ({ a: 'test'}, 'other', 'newKey') => {}
    */

    static rename(obj, key, newKey) {
        if (!StringUtils.isEmpty(key) && !StringUtils.isEmpty(newKey) && typeof obj === 'object' && includes(keys(obj), key)) {
            const newObject = cloneDeep(obj);

            newObject[newKey] = newObject[key];
            delete newObject[key];

            return newObject;
        }

        return {};
    }
}

import React, {
    useReducer, useEffect, useContext,
} from 'react';

// Material UI Components
import ButtonStyles from 'styles/theme/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography, Button,
} from '@material-ui/core';

// Components and Others
import moment from 'moment';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import update from 'immutability-helper';
import ArrayUtils from 'lib/ArrayUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { modules } from 'utils/enum/modules';
import {
    concat, isEmpty, orderBy, isEqual, cloneDeep,
} from 'lodash';
import Filter from 'components/widgets/Filter';
import { useHistory } from 'react-router-dom';
import Header from 'components/widgets/Header';
import { leadType } from 'utils/enum/OpportunityEnum';
import If from 'components/widgets/conditional/If';
import useStage from 'components/hook/crm/useStage';
import Container from 'components/widgets/Container';
import UserContext from 'components/context/UserContext';
import InputSearch from 'components/widgets/InputSearch';
import LeadHelper from 'components/modules/lead/LeadHelper';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import useLeadSources from 'components/hook/crm/useLeadSources';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateRange from 'components/modules/crm/widgets/DateRange';
import OpportunityItem from 'components/modules/crm/list/OpportunityItem';
import QuickNoteEditable from 'components/modules/crm/list/QuickNoteEditable';
import OpportunityTransferLot from 'components/widgets/transfer/OpportunityTransferLot';
import FloorLogSummary from 'components/widgets/crm/FloorLogSummary';

// HTTP
import NumberUtils from 'lib/NumberUtils';
import MessageUtils from 'utils/MessageUtils';
import Permissions from 'utils/enum/Permissions';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import { OpportunityStatus } from 'components/modules/crm/enum/Opportunity';
import useSalespersonsByLots from 'components/hook/crm/useSalespersonsByLots';
import NotificationQuery from 'services/graphQL/query/core/NotificationQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import useRecordManagersByLots from 'components/hook/crm/useRecordManagersByLots';
import {
    useMutation, useQuery, useSubscription, useLazyQuery,
} from '@apollo/client';
import OpportunitySubscription from 'services/graphQL/subscription/crm/OpportunitySubscription';
import NotificationSubscription from 'services/graphQL/subscription/core/NotificationSubscription';
import clsx from 'clsx';
import useTags from 'components/hook/crm/useTags';

const useStyle = makeStyles((theme) => ({
    main: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        '& > header': {
            marginLeft: '16px',
            marginRight: '16px',
            width: 'initial',
        },
    },
    listContainer: {
        flex: 1,
        overflow: 'auto',
    },
    containerFilterSearch: {
        display: 'flex',
        alignItems: 'center',
    },
    boxContainer: {
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.boulderGray,
        display: 'inline-block',
    },
    routeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    totalCount: {
        color: theme.palette.text.outerSpace,
        fontSize: '15px',
        fontWeight: 500,
    },
    label: {
        fontSize: '15px',
        fontWeight: 500,
        marginRight: theme.spacing(1),
        color: theme.palette.text.gray,
        cursor: 'pointer',
    },
    subMenu: {
        overflow: 'auto',
        maxHeight: 196,
    },
    search: {
        width: '200px',
    },
    mainButton: {
        minWidth: 100,
        marginRight: theme.spacing(2),
    },
    filter: {
        marginRight: theme.spacing(1),
    },
    lotFilter: {
        width: '200px',
    },
    row: {
        marginBottom: theme.spacing(1.5),
    },
    item: {
        marginRight: theme.spacing(4),
    },
    flDateRange: {
        color: theme.palette.secondary.main,
    },
    ...ButtonStyles.getStyle(theme),
}));
const keyStore = new KeyStore();
const ACTION_TYPE = {
    SET_VALUE: 'setValue',
    LOAD_MORE: 'loadMore',
    ON_SEARCH: 'onSearch',
    SET_OFFSET: 'setOffset',
    ON_CHANGE_LOT: 'onChangeLot',
    SET_DATE_RANGE: 'setDateRange',
    ON_CHANGE_SORT: 'onChangeSort',
    ON_CLEAR_SEARCH: 'onClearSearch',
    UPDATE_OPPORTUNITY: 'updateOpportunity',
    DELETE_OPPORTUNITY: 'deleteOpportunity',
    ADD_NEW_OPPORTUNITY: 'addNewOpportunity',
    OPEN_DIALOG_TRANSFER: 'openDialogTransfer',
    CLOSE_DIALOG_TRANSFER: 'closeDialogTransfer',
    OPEN_DIALOG_QUICK_NOTE: 'openDialogQuickNote',
    CLOSE_DIALOG_QUICK_NOTE: 'closeDialogQuickNote',
    SET_OPPORTUNITY_NOTIFICATION: 'setOpportunityNotification',
    SET_OPPORTUNITY_NOTIFICATION_UPDATED: 'setOpportunityNotificationUpdated',
};

const crmInitialDate = {
    startDate: moment().subtract(6, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    label: 'Last 7 Days',
};

const floorLogInitialDate = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    label: 'Today',
};

const fieldsToSort = {
    LEAD_CODE: 'leadCode',
    CREATED_ON: 'createdOn',
    LEAD_TYPE: 'leadType',
    LEAD_SOURCE: 'leadSource',
    MODIFIED_ON: 'modifiedOn',
    PROCESS_STAGE: 'processStage',
    LAST_RESULTS: 'lastResults',
    RECORD_MANAGER: 'recordManager',
    APPOINTMENT_SALESMAN: 'appointmentSalesman',
    LOT_NAME: 'lotName',
    TAG: 'tag',
};

const getSortByAndSortDir = (state) => {
    const sortBy = [state.sortBy];
    const sortDir = [state.sortDir.toLowerCase()];

    if (!sortBy.includes(fieldsToSort.MODIFIED_ON)) {
        sortBy.push(fieldsToSort.MODIFIED_ON);
        sortDir.push(state.sortDir.toLowerCase());
    }

    return { sortBy, sortDir };
};

const getRecordsSortedByField = (state, newRecord, index) => {
    const { sortBy, sortDir } = getSortByAndSortDir(state);

    if (index >= 0) {
        return orderBy(update(state.records, { [index]: { $set: newRecord } }), sortBy, sortDir);
    }

    return orderBy(update(state.records, { $unshift: [newRecord] }), sortBy, sortDir);
};

const updateSummary = (floorLogSummary, opportunity, previousState) => {
    if (!floorLogSummary || Object.keys(floorLogSummary).length === 0) return null;

    return Object.keys(floorLogSummary).reduce((a, b) => {
        const clone = cloneDeep(a);

        if (b === 'totalCustomers' && !previousState) {
            clone.totalCustomers = floorLogSummary[b] + 1;
            return clone;
        }

        if (!previousState) {
            clone[b] = opportunity[b] ? floorLogSummary[b] + 1 : floorLogSummary[b];
        } else {
            const previous = previousState[b];
            const current = opportunity[b];
            if (previous !== current) clone[b] = current ? floorLogSummary[b] + 1 : floorLogSummary[b] - 1;
            if (previous === current) clone[b] = floorLogSummary[b];
        }

        return clone;
    }, {});
};

const listReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.LOAD_MORE:
        const { sortBy, sortDir } = getSortByAndSortDir(state);
        const records = orderBy(concat(state.records, action.records?.data), sortBy, sortDir);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.records?.totalCount },
            floorLogSummary: { $set: action.records?.floorLogSummary },
        });
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    case ACTION_TYPE.ADD_NEW_OPPORTUNITY:
        return update(state, {
            lastRecord: { $set: action.payload },
            records: { $set: getRecordsSortedByField(state, action.payload) },
            totalCount: { $set: state.totalCount + 1 },
            floorLogSummary: { $set: updateSummary(state.floorLogSummary, action.payload) },
        });
    case ACTION_TYPE.OPEN_DIALOG_TRANSFER:
        return update(state, {
            openDialog: { $set: true },
            recordSelected: { $set: action.value },
        });
    case ACTION_TYPE.CLOSE_DIALOG_TRANSFER:
        return update(state, {
            openDialog: { $set: false },
            recordSelected: { $set: {} },
        });
    case ACTION_TYPE.OPEN_DIALOG_QUICK_NOTE:
        return update(state, {
            openQuickNote: { $set: true },
            recordSelected: { $set: action.value },
        });
    case ACTION_TYPE.CLOSE_DIALOG_QUICK_NOTE:
        return update(state, {
            openQuickNote: { $set: false },
            recordSelected: { $set: {} },
        });
    case ACTION_TYPE.DELETE_OPPORTUNITY:
        const indexToRemove = state.records.findIndex((item) => item.crmId === action.payload.crmId);

        if (indexToRemove >= 0) {
            return update(state, {
                lastRecord: { $set: action.payload },
                records: { $splice: [[indexToRemove, 1]] },
                totalCount: { $set: state.totalCount - 1 },
            });
        }

        return state;
    case ACTION_TYPE.UPDATE_OPPORTUNITY:
        const record = action.payload;
        const index = state.records.findIndex((item) => item.crmId === record.crmId);

        if (index >= 0) {
            return update(state, {
                records: { $set: getRecordsSortedByField(state, record, index) },
                lastRecord: { $set: action.payload },
                floorLogSummary: { $set: updateSummary(state.floorLogSummary, action.payload, state.records[index]) },
            });
        }

        return update(state, {
            records: { $set: getRecordsSortedByField(state, record) },
            lastRecord: { $set: action.payload },
            totalCount: { $set: state.totalCount + 1 },
            floorLogSummary: { $set: updateSummary(state.floorLogSummary, action.payload) },
        });
    case ACTION_TYPE.ON_SEARCH:
        const isGlobalSearch = action.isGlobalSearch || false;
        const lots = isGlobalSearch && action.selectedLot ? [action.selectedLot] : [];

        keyStore.setCRMFilter({
            lastUpdatedRange: {
                label: 'All',
            },
            createdOnRange: {
                label: 'All',
            },
            status: '',
            lots: [],
            recordManagers: [],
            salespersons: [],
            processStage: [],
            leadType: [],
            leadSource: [],
        });
        return update(state, {
            isGlobalSearch: { $set: isGlobalSearch },
            searchTerm: { $set: action.value },
            lots: { $set: lots },
            lastUpdatedRange: {
                startDate: { $set: undefined },
                endDate: { $set: undefined },
                label: { $set: 'All' },
            },
            createdOnRange: {
                startDate: { $set: undefined },
                endDate: { $set: undefined },
                label: { $set: 'All' },
            },
            status: { $set: '' },
            recordManagers: { $set: [] },
            processStage: { $set: [] },
            leadType: { $set: [] },
            leadSource: { $set: [] },
            totalCount: { $set: 0 },
            records: { $set: [] },
            salespersons: { $set: [] },
        });
    case ACTION_TYPE.ON_CLEAR_SEARCH:
        keyStore.setCRMFilter({
            lastUpdatedRange: {
                ...crmInitialDate,
            },
            createdOnRange: {
                ...crmInitialDate,
            },
            lots: [],
            status: OpportunityStatus.OPEN,

        });
        return update(state, {
            isGlobalSearch: { $set: false },
            searchTerm: { $set: '' },
            lots: { $set: [] },
            leadType: { $set: [] },
            processStage: { $set: [] },
            leadSource: { $set: [] },
            lastUpdatedRange: {
                startDate: { $set: crmInitialDate.startDate },
                endDate: { $set: crmInitialDate.endDate },
                label: { $set: crmInitialDate.label },
            },
            createdOnRange: {
                startDate: { $set: crmInitialDate.startDate },
                endDate: { $set: crmInitialDate.endDate },
                label: { $set: crmInitialDate.label },
            },
            status: { $set: OpportunityStatus.OPEN },
            totalCount: { $set: 0 },
            records: { $set: [] },
        });
    case ACTION_TYPE.SET_VALUE:
        if (isEqual(state[action.field], action.value)) {
            return state;
        }
        keyStore.setCRMFilter({
            [action.field]: action.value,
        });
        return update(state, {
            [action.field]: { $set: action.value },
            totalCount: { $set: 0 },
            records: { $set: [] },
            offset: { $set: 0 },
        });
    case ACTION_TYPE.ON_CHANGE_LOT:
        if (isEqual(state.lots, action.value)) {
            return update(state, {
                isDefaultLotSet: { $set: true },
            });
        }
        keyStore.setCRMFilter({
            lots: action.value,
            recordManagers: [],
            salespersons: [],
        });
        return update(state, {
            isDefaultLotSet: { $set: true },
            lots: { $set: action.value },
            totalCount: { $set: 0 },
            records: { $set: [] },
            offset: { $set: 0 },
            recordManagers: { $set: [] },
            salespersons: { $set: [] },
        });
    case ACTION_TYPE.SET_DATE_RANGE:
        if (moment(state[action.field].startDate).isSame(action.record.startDate)
            && moment(state[action.field].endDate).isSame(action.record.endDate)) {
            return state;
        }

        keyStore.setCRMFilter({
            [action.field]: {
                ...action.record,
            },
        });
        return update(state, {
            [action.field]: {
                startDate: { $set: action.record.startDate },
                endDate: { $set: action.record.endDate },
                label: { $set: action.record.label },
                isCustomRange: { $set: action.record.isCustomRange },
            },
            totalCount: { $set: 0 },
            records: { $set: [] },
            offset: { $set: 0 },
        });
    case ACTION_TYPE.SET_OPPORTUNITY_NOTIFICATION:
        const notification = action.payload;
        const getRecord = (opportunityId) => notification.find((item) => item.opportunityId === opportunityId) || null;
        return update(state, {
            records: {
                $set: state.records.map((item) => ({
                    ...item,
                    isReadNotification: getRecord(item.crmId)?.read,
                })),
            },
        });
    case ACTION_TYPE.SET_OPPORTUNITY_NOTIFICATION_UPDATED:
        const { payload } = action;
        const indexToUpdate = state.records.findIndex((item) => item.crmId === payload.opportunityId);

        if (indexToUpdate >= 0) {
            return update(state, {
                records: {
                    [indexToUpdate]: {
                        isReadNotification: {
                            $set: !(payload.emailUnread > 0 || payload.smsUnread > 0),
                        },
                    },
                },
            });
        }

        return state;
    case ACTION_TYPE.ON_CHANGE_SORT:
        let currentSortDir;
        if (!isEqual(state.sortBy, action.value)) {
            currentSortDir = DataSort.DESC;
        } else if (state.sortDir === DataSort.DESC) {
            currentSortDir = DataSort.ASC;
        } else {
            currentSortDir = DataSort.DESC;
        }

        keyStore.setCRMFilter({
            sortBy: action.value,
        });
        return update(state, {
            sortBy: { $set: action.value },
            sortDir: { $set: currentSortDir },
            totalCount: { $set: 0 },
            records: { $set: [] },
            offset: { $set: 0 },
        });
    default:
        return state;
    }
};
const OpportunityList = () => {
    const classes = useStyle();
    const history = useHistory();
    const { stages } = useStage();
    const { userInformation = {} } = useContext(UserContext);
    const status = [OpportunityStatus.OPEN, OpportunityStatus.LOST, OpportunityStatus.SOLD];
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_WRITE);
    const allowOpportunityManagement = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_MANAGEMENT);
    const userLots = keyStore.getUserLots().map((c) => c.lotId);

    const path = history?.location?.pathname ?? '';
    const isFloorLog = path.includes(modules.FLOORLOG);
    const leadTypeOption = LeadHelper.getLeadTypeOption(isFloorLog);
    if (isFloorLog) keyStore.setCRMFilter = () => null;

    const savedFilters = keyStore.getCRMFilter() || {};
    const filter = !isFloorLog ? savedFilters : {};

    const initialDate = isFloorLog ? floorLogInitialDate : crmInitialDate;
    const lastUpdatedRange = !isEmpty(filter.lastUpdatedRange) ? {
        ...filter.lastUpdatedRange,
        startDate: moment(filter.lastUpdatedRange.startDate || null).isValid() ? moment(filter.lastUpdatedRange.startDate).toDate() : undefined,
        endDate: moment(filter.lastUpdatedRange.endDate || null).isValid() ? moment(filter.lastUpdatedRange.endDate).toDate() : undefined,
    } : initialDate;
    const createdOnRange = !isEmpty(filter.createdOnRange) ? {
        ...filter.createdOnRange,
        startDate: moment(filter.createdOnRange.startDate || null).isValid() ? moment(filter.createdOnRange.startDate).toDate() : undefined,
        endDate: moment(filter.createdOnRange.endDate || null).isValid() ? moment(filter.createdOnRange.endDate).toDate() : undefined,
    } : initialDate;

    const queryParams = new URLSearchParams(history?.location?.search || '');
    const search = queryParams.get('search');

    const [state, dispatch] = useReducer(listReducer, {
        records: [],
        lastRecord: {},
        totalCount: 0,
        lots: [],
        status: filter.status || OpportunityStatus.OPEN,
        recordManagers: filter.recordManagers || [],
        tags: filter.tags || [],
        searchTerm: search || '',
        salespersons: filter.salespersons || [],
        processStage: filter.processStage || [],
        leadType: filter.leadType || [],
        leadSource: filter.leadSource || [],
        offset: 0,
        createdOnRange: {
            ...createdOnRange,
        },
        sortBy: filter.sortBy || 'modifiedOn',
        sortDir: filter.sortDir || DataSort.DESC,
        openDialogProposal: false,
        openDialog: false,
        recordSelected: {},
        openQuickNote: false,
        lastUpdatedRange: {
            ...lastUpdatedRange,
        },
        isGlobalSearch: false,
        isDefaultLotSet: false,
        floorLogSummary: null,
    });
    const filters = {
        lots: state.lots.map((item) => item.label),
        ...(!isFloorLog ? { status: state.status } : {}),
        searchTerm: state.searchTerm,
        processStage: state.processStage.map((item) => item.value),
        leadType: state.leadType.map((item) => item.value),
        leadSourceId: state.leadSource.map((item) => item.value),
        tags: state.tags.map((item) => item.value),
        ...(!isFloorLog ? {
            lastUpdated: {
                end: state.lastUpdatedRange.endDate,
                start: state.lastUpdatedRange.startDate,
            },
        } : {}),
        createdOn: {
            end: state.createdOnRange.endDate,
            start: state.createdOnRange.startDate,
        },
        ...(isFloorLog ? {
            showedDate: {
                end: state.createdOnRange.endDate,
                start: state.createdOnRange.startDate,
            },
        } : {}),
        recordManagers: state.recordManagers.map((item) => item.value),
        salespersons: state.salespersons.map((item) => item.value),
    };

    // custom hooks
    const lots = state.lots.map((item) => item.value) || [];
    const { salespersons } = useSalespersonsByLots({ lots });
    const { recordManagers } = useRecordManagersByLots({ lots });
    const { leadSources } = useLeadSources({ notIncludeEmptyValue: true });
    const { tags: availableTags } = useTags({ notIncludeEmptyValue: true });

    const sort = { field: state.sortBy, dir: state.sortDir };
    const { data, loading, error } = useQuery(OpportunityQuery.GET_CRM_LIST, {
        variables: {
            limit: 50,
            offset: state.offset,
            filters,
            sort,
            searchColumns: state.isGlobalSearch ? ['stockNumber'] : null,
        },
        skip: (!StringUtils.isEmpty(search) && !state.isGlobalSearch) || !state.isDefaultLotSet,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    // TODO: Remove the lots as parameters. Just a workaround while it's added to the response on the oAuth Server
    const [transferLot] = useMutation(OpportunityMutation.TRANSFER_LOT);
    const { data: subscriptionData } = useSubscription(OpportunitySubscription.OPPORTUNITY_LIST_UPDATED, { variables: { filters, lots: userLots } });
    const { data: subscriptionNotificationData } = useSubscription(NotificationSubscription.OPPORTUNITY_NOTIFICATION_UPDATED);

    // Get sms or email unread by opportunities
    const [getNotificationByOpportunities] = useLazyQuery(
        NotificationQuery.GET_NOTIFICATION_BY_OPPORTUNITIES,
        {
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
            onCompleted: (res) => {
                if (res?.getNotificationByOpportunities?.length > 0) {
                    dispatch({ type: ACTION_TYPE.SET_OPPORTUNITY_NOTIFICATION, payload: res.getNotificationByOpportunities });
                }
            },
        },
    );

    const { defaultLot, lots: userCurrentLots = [] } = userInformation;
    useEffect(() => {
        const areLotsSaved = ArrayUtils.isNotEmpty(savedFilters.lots);
        if (!StringUtils.isEmpty(defaultLot)) {
            const lot = userCurrentLots.filter((x) => x.lotName.toLowerCase() === defaultLot.toLowerCase())?.map((x) => ({ value: x.lotId, label: x.lotName }));
            dispatch({ type: ACTION_TYPE.ON_CHANGE_LOT, value: !isFloorLog && areLotsSaved ? savedFilters.lots : (lot ?? []) });
        } else if (Object.keys(userInformation).length > 0 && !defaultLot) {
            dispatch({ type: ACTION_TYPE.ON_CHANGE_LOT, value: !isFloorLog && areLotsSaved ? savedFilters.lots : [] });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLot]);

    useEffect(() => {
        if (search && Object.keys(userInformation).length > 0) {
            const selectedLot = userInformation.lots.find((lot) => lot.lotName === (queryParams.get('lot') ?? ''));
            dispatch({
                type: ACTION_TYPE.ON_SEARCH,
                value: search,
                ...(selectedLot ? { selectedLot: { value: selectedLot.lotId, label: selectedLot.lotName } } : {}),
                isGlobalSearch: true,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getCRMList } = data;
            dispatch({ type: ACTION_TYPE.LOAD_MORE, records: getCRMList });

            if (getCRMList?.data?.length > 0) {
                const opportunities = getCRMList.data.map((item) => item.crmId);
                getNotificationByOpportunities({
                    variables: {
                        opportunities,
                    },
                });
            }
        }
    }, [data, loading, error, getNotificationByOpportunities]);

    useEffect(() => {
        const result = subscriptionData?.opportunityListUpdated;
        if (!isEmpty(result) && !isEqual(result.opportunity, state.lastRecord)) {
            const {
                opportunity: {
                    leadType: opportunityLeadType,
                    showed,
                },
            } = result;
            const notOpportunityForFloorLog = isFloorLog && opportunityLeadType !== leadType.WALK_IN && !showed;

            if (result.type === SubscriptionActionType.ADDED) {
                if (notOpportunityForFloorLog) return;

                dispatch({ type: ACTION_TYPE.ADD_NEW_OPPORTUNITY, payload: result.opportunity });
                return;
            }

            if (result.type === SubscriptionActionType.UPDATED) {
                if (notOpportunityForFloorLog) return;

                dispatch({ type: ACTION_TYPE.UPDATE_OPPORTUNITY, payload: result.opportunity });
            }

            if (result.type === SubscriptionActionType.DELETED) {
                dispatch({ type: ACTION_TYPE.DELETE_OPPORTUNITY, payload: result.opportunity });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData, state.lastRecord]);

    // Notification subscription
    useEffect(() => {
        const result = subscriptionNotificationData?.opportunityNotificationUpdated;
        if (!isEmpty(result)) {
            dispatch({ type: ACTION_TYPE.SET_OPPORTUNITY_NOTIFICATION_UPDATED, payload: result });
        }
    }, [subscriptionNotificationData]);

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };
    const onChangeValue = (value, field) => {
        dispatch({ type: ACTION_TYPE.SET_VALUE, value, field });
    };
    const onChangeSort = (value) => {
        dispatch({ type: ACTION_TYPE.ON_CHANGE_SORT, value });
    };
    const onSearch = (value) => {
        dispatch({ type: ACTION_TYPE.ON_SEARCH, value });
    };
    const onClearSearch = () => {
        queryParams.delete('search');
        queryParams.delete('lot');
        history.replace({ search: queryParams.toString() });

        dispatch({ type: ACTION_TYPE.ON_CLEAR_SEARCH });
    };
    const openDialogTransfer = (value) => {
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_TRANSFER, value });
    };
    const closeDialogTransfer = () => {
        dispatch({ type: ACTION_TYPE.CLOSE_DIALOG_TRANSFER });
    };
    const onNew = () => {
        history.push(`/${modules.OPPORTUNITIES}/create`, { isFloorLog });
    };
    const onChangeDateRange = (record, field) => {
        dispatch({ type: ACTION_TYPE.SET_DATE_RANGE, record, field });
    };
    const openDialogQuickNote = (value) => {
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_QUICK_NOTE, value });
    };
    const closeDialogQuickNote = () => {
        dispatch({ type: ACTION_TYPE.CLOSE_DIALOG_QUICK_NOTE });
    };
    const onChangeLot = (value) => {
        dispatch({ type: ACTION_TYPE.ON_CHANGE_LOT, value });
    };
    const getIconDir = () => (state.sortDir === DataSort.ASC ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />);

    const resultRecordManager = allowOpportunityManagement ? recordManagers
        : [{ value: userInformation.userId, label: `${userInformation.firstName} ${userInformation.lastName}` }];
    const resultSalesRep = allowOpportunityManagement ? salespersons
        : [{ value: userInformation.userId, label: `${userInformation.firstName} ${userInformation.lastName}` }];
    const availableLot = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    const onTransferLot = async (transferRecord) => {
        try {
            closeDialogTransfer();
            const { recordSelected = {} } = state;
            const input = {
                crmId: recordSelected.crmId,
                lotName: transferRecord.lotName,
            };

            const response = await transferLot({ variables: input });

            if (!response.data?.transferOpportunity) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'opportunity'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    /**
     * @returns {Component} listElement
     */
    const renderList = () => (
        <InfiniteScroll
            load={loading}
            loadMore={loadMore}
            loadAtScrollPercent={90}
            totalRecord={state?.totalCount}
            lengthRecord={state?.records.length}
        >
            {
                state?.records.map((item, index) => (
                    <OpportunityItem
                        key={index}
                        isFloorLog={isFloorLog}
                        record={item}
                        stages={stages}
                        openDialogTransfer={openDialogTransfer}
                        openDialogQuickNote={openDialogQuickNote}
                    />
                ))
            }
        </InfiniteScroll>
    );

    return (
        <>
            {isFloorLog && (
                <FloorLogSummary summary={state?.floorLogSummary ?? {}} />
            )}
            <div className={classes.main}>
                <Header>
                    <div className="d-flex-direction-column-align-baseline">
                        <div className={clsx('d-flex-space-between', classes.row)}>
                            <If condition={CRM_OPPORTUNITY_WRITE}>
                                <Button
                                    className={classes.mainButton}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={onNew}
                                >
                                    New
                                </Button>
                            </If>
                            <InputSearch
                                size="sm"
                                initialSearch={state.searchTerm}
                                customClasses={classes.search}
                                onSearch={onSearch}
                                executeWhenClearButton={onClearSearch}
                            />
                            {!isFloorLog && (
                                <div className="d-flex-center">
                                    <Form.Group
                                        className="form-check form-check-inline mb-0"
                                    >
                                        {status.map((item, index) => (
                                            <Form.Check
                                                key={index}
                                                type="radio"
                                                name="radio"
                                                id={`radio${index}`}
                                                label={item}
                                                value={item}
                                                checked={item === state.status}
                                                onChange={(e) => onChangeValue(e.target.value, 'status')}
                                            />
                                        ))}
                                    </Form.Group>
                                </div>
                            )}
                        </div>
                        <div className={clsx('d-flex-center', classes.row)}>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.LEAD_TYPE)}
                                >
                                    Lead Type
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={leadTypeOption}
                                    selectedValues={state.leadType}
                                    applyFilter={(record) => onChangeValue(record, 'leadType')}
                                    onClearFilter={() => onChangeValue([], 'leadType')}
                                />
                                {state.sortBy === fieldsToSort.LEAD_TYPE && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-space-between', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={clsx(classes.label, isFloorLog ? classes.flDateRange : '')}
                                    onClick={() => onChangeSort(fieldsToSort.CREATED_ON)}
                                >
                                    {isFloorLog ? 'Floor Log Date Range' : 'Opened On'}
                                </Typography>
                                <DateRange
                                    showIconOnly
                                    selectedValue={state.createdOnRange}
                                    onChangeValue={(value) => onChangeDateRange(value, 'createdOnRange')}
                                />
                                {state.sortBy === fieldsToSort.CREATED_ON && getIconDir()}
                            </div>
                            {!isFloorLog && (
                                <div className={clsx('d-flex-space-between', classes.item)}>
                                    <Typography
                                        variant="h5"
                                        className={classes.label}
                                        onClick={() => onChangeSort(fieldsToSort.MODIFIED_ON)}
                                    >
                                        { state.status === 'Open' || StringUtils.isEmpty(state.status) ? 'Last Update' : 'Close Date'}
                                    </Typography>
                                    <DateRange
                                        showIconOnly
                                        selectedValue={state.lastUpdatedRange}
                                        onChangeValue={(value) => onChangeDateRange(value, 'lastUpdatedRange')}
                                    />
                                    {state.sortBy === fieldsToSort.MODIFIED_ON && getIconDir()}
                                </div>
                            )}
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.PROCESS_STAGE)}
                                >
                                    Stage
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={stages}
                                    selectedValues={state.processStage}
                                    applyFilter={(record) => onChangeValue(record, 'processStage')}
                                    onClearFilter={() => onChangeValue([], 'processStage')}
                                />
                                {state.sortBy === fieldsToSort.PROCESS_STAGE && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.LEAD_SOURCE)}
                                >
                                    Lead Source
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={leadSources}
                                    selectedValues={state.leadSource}
                                    applyFilter={(record) => onChangeValue(record, 'leadSource')}
                                    onClearFilter={() => onChangeValue([], 'leadSource')}
                                />
                                {state.sortBy === fieldsToSort.LEAD_SOURCE && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.RECORD_MANAGER)}
                                >
                                    Record Manager
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={resultRecordManager}
                                    selectedValues={state.recordManagers}
                                    applyFilter={(record) => onChangeValue(record, 'recordManagers')}
                                    onClearFilter={() => onChangeValue([], 'recordManagers')}
                                />
                                {state.sortBy === fieldsToSort.RECORD_MANAGER && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.APPOINTMENT_SALESMAN)}
                                >
                                    Sales Rep
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={resultSalesRep}
                                    selectedValues={state.salespersons}
                                    applyFilter={(record) => onChangeValue(record, 'salespersons')}
                                    onClearFilter={() => onChangeValue([], 'salespersons')}
                                />
                                {state.sortBy === fieldsToSort.APPOINTMENT_SALESMAN && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                    onClick={() => onChangeSort(fieldsToSort.LOT_NAME)}
                                >
                                    Lot
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={availableLot}
                                    selectedValues={state.lots}
                                    applyFilter={(record) => onChangeLot(record)}
                                    onClearFilter={() => onChangeLot([])}
                                />
                                {state.sortBy === fieldsToSort.LOT_NAME && getIconDir()}
                            </div>
                            <div className={clsx('d-flex-justify-end-align-end', classes.item)}>
                                <Typography
                                    variant="h5"
                                    className={classes.label}
                                >
                                    Tag
                                </Typography>
                                <Filter
                                    showTooltip
                                    showIconOnly
                                    useInternalSearch
                                    maxWidthLabel={100}
                                    records={availableTags}
                                    selectedValues={state.tags}
                                    applyFilter={(record) => onChangeValue(record, 'tags')}
                                    onClearFilter={() => onChangeValue([], 'tags')}
                                />
                            </div>
                            {!isFloorLog && (
                                <div className="d-flex-center">
                                    <Typography
                                        variant="h5"
                                        className={classes.label}
                                    >
                                        Count:
                                    </Typography>
                                    <span className={classes.totalCount}>{NumberUtils.applyCurrencyFormat(state.totalCount, '0,0')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </Header>
                <Container className={classes.boxContainer}>
                    <div className={classes.listContainer}>
                        {renderList()}
                    </div>
                </Container>
                {state.openDialog && (
                    <OpportunityTransferLot
                        open={state.openDialog}
                        onTransfer={onTransferLot}
                        record={state.recordSelected}
                        closeDialog={closeDialogTransfer}
                    />
                )}
                {state.openQuickNote && (
                    <QuickNoteEditable
                        open={state.openQuickNote}
                        onClose={closeDialogQuickNote}
                        record={state.recordSelected}
                    />
                )}
            </div>
        </>
    );
};

export default OpportunityList;

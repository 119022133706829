import * as yup from 'yup';

const CustomFieldMappingSchema = yup.object().shape({
    displayCustomField: yup.boolean(),
    customFieldName: yup.string().when('displayCustomField', { is: true, then: yup.string().required() }),
    customFieldCreditAccountId: yup.number().when('displayCustomField', { is: true, then: yup.number().moreThan(0) }),
    customFieldDebitAccountId: yup.number().when('displayCustomField', { is: true, then: yup.number().moreThan(0) }),
});

export default CustomFieldMappingSchema;

const useDefaultLot = (userInformation) => {
    const availableLots = (userInformation?.lots || []).map((item) => ({ id: item.lotId, value: item.lotName, label: item.lotName }));
    const lotsUserHaveAccess = userInformation?.lots ?? [];

    const defaultUserLotName = userInformation?.defaultLot || userInformation?.usersLot;

    const lotItem = lotsUserHaveAccess.find((item) => item.lotName?.toLowerCase() === defaultUserLotName?.toLowerCase());
    const defaultUserLotId = lotItem?.lotId || 0;
    const defaultUserLot = lotItem?.lotName || '';

    return {
        availableLots, // this will be used for dropdown multiple made by Randy
        defaultLotId: defaultUserLotId,
        defaultLot: defaultUserLot,
        defaultUserLotId,
        defaultUserLot,
        lotsUser: lotsUserHaveAccess, // this will be used for dropdown of single selection
    };
};

export default useDefaultLot;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Split from 'react-split';
import cloneDeep from 'lodash/cloneDeep';
import {
    makeStyles, Grid, useTheme,
    Button, Tooltip, useMediaQuery,
} from '@material-ui/core';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { useQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { TRAINING_SECTION } from 'utils/enum/General';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import GeneralQuery from 'services/graphQL/query/GeneralQuery';
import GeneralMutation from 'services/graphQL/mutate/GeneralMutation';
import ButtonStyles from 'styles/theme/Button';
import SettingsStyle from 'styles/modules/businessIntelligence/SettingsStyle';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import VideoDialog from 'components/modules/settings/training/VideoDialog';
import AddCategoryDialog from 'components/modules/settings/businessIntelligence/AddCategoryDialog';
import Search from 'components/widgets/Search';

// icons
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => SettingsStyle.content(theme));

const VideoSettings = ({ canWrite }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        categories: [],
        selectedCategory: null,
        categoriesSearch: null,
        selectedVideo: null,
        videosSearch: null,
        isCategoryPanelOpen: false,
        isVideoPanelOpen: false,
        isDeletePromptVisible: false,
    });

    const {
        data: categoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
        refetch: refetchCategories,
    } = useQuery(GeneralQuery.PULL_TRAINING_CATEGORIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
            return;
        }

        if (!loadingCategories) {
            const categories = categoriesData?.pullTrainingCategories;
            if (categories) {
                let firstCategoryId = null;
                let firstCategoryVideos = null;

                const categoryAvailability = categories.length > 0 && !state.selectedCategory;
                if (categoryAvailability) {
                    firstCategoryId = categories[0]?.trainingCategoryId;
                    firstCategoryVideos = categories[0]?.videos ?? [];
                }

                setState((prevState) => ({
                    ...prevState,
                    categories,
                    ...(firstCategoryId ? { selectedCategory: firstCategoryId } : {}),
                    ...(firstCategoryVideos?.length > 0 ? { selectedVideo: firstCategoryVideos[0].trainingVideoId } : {}),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    const [addCategory, { loading: addingCategory }] = useMutation(GeneralMutation.ADD_TRAINING_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    categoriesSearch: null,
                    videosSearch: null,
                    isCategoryPanelOpen: false,
                    selectedCategory: response?.addTrainingCategory,
                }));

                refetchCategories();
                ModalUtils.successMessage(null, 'Category added sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [addVideo, { loading: addingVideo }] = useMutation(GeneralMutation.ADD_TRAINING_VIDEO, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    categoriesSearch: null,
                    videosSearch: null,
                    isVideoPanelOpen: false,
                    selectedVideo: response?.addTrainingVideo,
                }));

                refetchCategories();
                ModalUtils.successMessage(null, 'Video added sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [editCategory, { loading: editingCategory }] = useMutation(GeneralMutation.EDIT_TRAINING_CATEGORY, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    categoriesSearch: null,
                    videosSearch: null,
                    isDeletePromptVisible: false,
                    ...(state.isDeletePromptVisible ? { selectedCategory: null, selectedVideo: null } : {}),
                    deleteSection: null,
                }));

                refetchCategories();
                ModalUtils.successMessage(null, 'Category edited sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [editVideo, { loading: editingVideo }] = useMutation(GeneralMutation.EDIT_TRAINING_VIDEO, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    categoriesSearch: null,
                    videosSearch: null,
                    isDeletePromptVisible: false,
                    isVideoPanelOpen: false,
                    ...(state.isDeletePromptVisible ? { selectedVideo: null } : {}),
                    deleteSection: null,
                }));

                refetchCategories();
                ModalUtils.successMessage(null, 'Video edited sucessfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [setCategoriesOrder] = useMutation(GeneralMutation.SET_TRAINING_CATEGORIES_ORDER, {
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [setVideosOrder] = useMutation(GeneralMutation.SET_TRAINING_VIDEOS_ORDER, {
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const actionFilter = (section, input) => {
        switch (section) {
        case TRAINING_SECTION.CATEGORIES:
            setState((prevState) => ({
                ...prevState,
                selectedCategory: null,
                categoriesSearch: input,
            }));

            break;
        case TRAINING_SECTION.VIDEOS:
            setState((prevState) => ({
                ...prevState,
                selectedVideo: null,
                videosSearch: input,
            }));

            break;
        default:
            break;
        }
    };

    const setSelectedElement = (section, id) => {
        switch (section) {
        case TRAINING_SECTION.CATEGORIES:
            const videos = state.categories?.find((cat) => cat.trainingCategoryId === id)?.videos ?? [];

            setState((prevState) => ({
                ...prevState,
                selectedVideo: videos.length > 0 ? videos[0].trainingVideoId : null,
                selectedCategory: id,
            }));

            break;
        case TRAINING_SECTION.VIDEOS:
            setState((prevState) => ({
                ...prevState,
                selectedVideo: id,
            }));

            break;
        default:
            break;
        }
    };

    const toggleCreationDialog = (section, editing = false) => {
        if (section === TRAINING_SECTION.CATEGORIES) {
            setState((prevState) => ({
                ...prevState,
                isCategoryPanelOpen: !state.isCategoryPanelOpen,
                ...(!editing && !state.isCategoryPanelOpen ? { selectedCategory: null } : {}),
            }));

            return;
        }

        if (section === TRAINING_SECTION.VIDEOS) {
            setState((prevState) => ({
                ...prevState,
                isVideoPanelOpen: !state.isVideoPanelOpen,
                ...(!editing && !state.isVideoPanelOpen ? { selectedVideo: null } : {}),
            }));
        }
    };

    const toggleDeletePrompt = (section) => {
        let deleteDescription = null;
        if (section) {
            if (section === TRAINING_SECTION.CATEGORIES) deleteDescription = 'Removing this category will remove its associated videos, continue?';
            if (section === TRAINING_SECTION.VIDEOS) deleteDescription = 'Do you want to remove this video, continue?';
        }

        setState((prevState) => ({
            ...prevState,
            isDeletePromptVisible: !state.isDeletePromptVisible,
            deleteSection: section,
            deleteDescription,
        }));
    };

    const onSaveVideo = ({
        videoCategory,
        videoName,
        videoDescription,
        videoLink,
        matchingRoute,
    }) => {
        const videos = state.categories?.find((cat) => cat.trainingCategoryId === videoCategory)?.videos ?? [];
        setState((prevState) => ({
            ...prevState,
            selectedCategory: videoCategory,
        }));

        if (state.selectedVideo) {
            editVideo({
                variables: {
                    trainingVideoId: state.selectedVideo,
                    trainingCategoryId: videoCategory,
                    name: videoName,
                    description: videoDescription,
                    videoLink,
                    matchingRoute,
                    active: true,
                },
            });

            return;
        }

        addVideo({
            variables: {
                trainingCategoryId: videoCategory,
                name: videoName,
                description: videoDescription,
                videoLink,
                matchingRoute,
                order: videos.length + 1,
            },
        });
    };

    const onSaveCategory = (name) => {
        if (state.selectedCategory) {
            editCategory({
                variables: {
                    trainingCategoryId: state.selectedCategory,
                    name,
                    active: true,
                },
            });

            return;
        }

        addCategory({
            variables: {
                name,
                order: state.categories.length + 1,
            },
        });
    };

    const onDeleteAction = async () => {
        const {
            categories,
            deleteSection,
            selectedCategory,
            selectedVideo,
        } = state;

        if (deleteSection === TRAINING_SECTION.CATEGORIES) {
            const name = categories?.find((cat) => cat.trainingCategoryId === selectedCategory)?.name;
            editCategory({
                variables: {
                    trainingCategoryId: selectedCategory,
                    name,
                    active: false,
                },
            });

            return;
        }

        if (deleteSection === TRAINING_SECTION.VIDEOS) {
            const videos = categories?.find((cat) => cat.trainingCategoryId === selectedCategory)?.videos;
            const currentVideo = videos?.find((v) => v.trainingVideoId === selectedVideo);

            if (currentVideo) {
                const {
                    trainingVideoId,
                    name,
                    description,
                    videoLink,
                    matchingRoute,
                } = currentVideo;

                editVideo({
                    variables: {
                        trainingVideoId,
                        trainingCategoryId: selectedCategory,
                        name,
                        description,
                        videoLink,
                        matchingRoute,
                        active: false,
                    },
                });
            }
        }
    };

    const onSortEnd = (oldIndex, newIndex, list, section) => {
        if (oldIndex !== newIndex) {
            const sorted = arrayMove(
                list,
                oldIndex,
                newIndex,
            );

            if (section === TRAINING_SECTION.CATEGORIES) {
                setState((prevState) => ({
                    ...prevState,
                    categories: sorted,
                }));

                setCategoriesOrder({
                    variables: {
                        input: sorted.map(({ trainingCategoryId }, index) => ({ trainingCategoryId, order: index + 1 })),
                    },
                });

                return;
            }

            if (section === TRAINING_SECTION.VIDEOS) {
                const clone = cloneDeep(state.categories);
                const current = clone.find((c) => c.trainingCategoryId === state.selectedCategory);
                if (current) current.videos = sorted;

                setState((prevState) => ({
                    ...prevState,
                    categories: clone,
                }));

                setVideosOrder({
                    variables: {
                        input: sorted.map(({ trainingVideoId }, index) => ({ trainingVideoId, order: index + 1 })),
                    },
                });
            }
        }
    };

    const SortableItem = SortableElement(({
        id,
        element,
        sortIndex,
        isSelected,
        section,
    }) => (
        <div
            className={isSelected ? classes.listItemSelected : classes.listItem}
            onClick={() => setSelectedElement(section, id)}
            key={sortIndex}
        >
            <span>{element.name}</span>
            {isSelected && canWrite && (
                <div>
                    <Tooltip title="Edit">
                        <span>
                            <Button
                                size="small"
                                startIcon={<EditOutlinedIcon />}
                                onClick={() => toggleCreationDialog(section, true)}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <span>
                            <Button
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleDeletePrompt(section)}
                            />
                        </span>
                    </Tooltip>
                </div>
            )}
        </div>
    ));

    const SortableList = SortableContainer(({ items, selected, section }) => (
        <div className={classes.list}>
            {items.map((element, index) => {
                const id = element.trainingCategoryId ?? element.trainingVideoId;
                const isSelected = selected === id;

                return (
                    <SortableItem
                        key={`item-${section.toLowerCase()}-${index}`}
                        index={index}
                        sortIndex={index}
                        id={id}
                        element={element}
                        isSelected={isSelected}
                        section={section}
                    />
                );
            })}
        </div>
    ));

    const distanceInPixels = 5;
    const renderItems = (section) => {
        let list = [];
        let selected = null;

        switch (section) {
        case TRAINING_SECTION.CATEGORIES:
            list = StringUtils.isEmpty(state.categoriesSearch)
                ? state.categories?.map((cat) => ({ ...cat, name: cat.name?.toUpperCase() }))
                : state.categories?.filter((cat) => cat.name.toLowerCase().includes(state.categoriesSearch.toLowerCase()))
                    .map((cat) => ({ ...cat, name: cat.name?.toUpperCase() }));
            selected = state.selectedCategory;
            break;
        case TRAINING_SECTION.VIDEOS:
            selected = state.selectedVideo;
            if (state.selectedCategory) {
                list = state.categories?.find((cat) => cat.trainingCategoryId === state.selectedCategory)?.videos ?? [];
                if (!StringUtils.isEmpty(state.videosSearch)) {
                    list = list.filter((video) => video.name.toLowerCase().includes(state.videosSearch.toLowerCase()));
                }
            }
            break;
        default:
            break;
        }

        const isAddDisabled = section === TRAINING_SECTION.VIDEOS && !state.selectedCategory;
        return (
            <div className={classes.container}>
                <div className={classes.label}>
                    {section}
                    {canWrite && (
                        <Button
                            className={isAddDisabled ? classes.addDisabled : ''}
                            disabled={isAddDisabled}
                            size="small"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => toggleCreationDialog(section)}
                        />
                    )}
                </div>
                <div className={classes.boxSearch}>
                    <Search fullWidth onKeyDown={(input) => actionFilter(section, input)} />
                </div>
                <SortableList
                    axis="y"
                    distance={distanceInPixels}
                    items={list}
                    selected={selected}
                    section={section}
                    onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, list, section)}
                />
            </div>
        );
    };

    const categoryDialogInitValue = state.selectedCategory
        ? state.categories.find((cat) => cat.trainingCategoryId === state.selectedCategory)?.name
        : '';

    return (
        <>
            {isMobile && (
                <Grid container className={classes.box}>
                    {renderItems(TRAINING_SECTION.CATEGORIES)}
                    {renderItems(TRAINING_SECTION.VIDEOS)}
                </Grid>
            )}
            {!isMobile && (
                <Grid container className={classes.box}>
                    <Split
                        sizes={[20, 80]}
                        minSize={0}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        {renderItems(TRAINING_SECTION.CATEGORIES)}
                        {renderItems(TRAINING_SECTION.VIDEOS)}
                    </Split>
                </Grid>
            )}
            {state.isCategoryPanelOpen && (
                <AddCategoryDialog
                    initialValue={categoryDialogInitValue}
                    isSavingCategory={addingCategory}
                    onClose={() => toggleCreationDialog(TRAINING_SECTION.CATEGORIES)}
                    onSaveCategory={onSaveCategory}
                />
            )}
            {state.isVideoPanelOpen && (
                <VideoDialog
                    toggleDialog={() => toggleCreationDialog(TRAINING_SECTION.VIDEOS)}
                    onSave={onSaveVideo}
                    isSavingVideo={addingVideo || editingVideo}
                    categories={state.categories}
                    selectedCategory={state.selectedCategory}
                    selectedVideo={state.selectedVideo}
                />
            )}
            <ConfirmDialog
                title="Attention!"
                description={state.deleteDescription}
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={editingCategory || editingVideo}
                disableSecondaryButton={editingCategory || editingVideo}
            />
        </>
    );
};

VideoSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default VideoSettings;

import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';

export default class AccountingCBMapper {
    static mapAccountingCB(record) {
        if (record) {
            return ({
                ...record,
                date: new Date(DateUtils.getOnlyDate(record.date)),
                cbType: record.type,
                entityAddress: record.entityAddress || '',
                memo: record.memo || '',
            });
        }

        return {};
    }

    static mapGlLines(records) {
        if (records) {
            return records.map((data) => (
                {
                    entryId: data.entryId,
                    accountNumber: Number(data.accountNumber),
                    amount: NumberUtils.round(data.amount),
                    controlNumber: String(data.controlNumber),
                    departmentId: data.departmentId,
                    lotId: data.lotId,
                    memo: data.memo,
                    processDetailRecordId: data?.processDetailRecordId ?? null,
                    processTypeId: data?.processTypeId ?? null,
                }
            ));
        }

        return [];
    }

    static mapCheckToSave(record) {
        if (record) {
            return {
                cbId: record.cbId,
                checkNumber: record.isACH ? null : Number(record.checkNumber),
                achCheckNumber: record.isACH ? Number(record.checkNumber) : null,
                bankAccount: record.bankAccount,
                date: DateUtils.isValid(record.date) ? DateUtils.toLocal(record.date).toDate() : '',
                paidTo: record.paidTo,
                amount: NumberUtils.round(record.amount),
                memo: record.memo,
                status: record.status,
                type: record.type,
                lotId: record.lotId,
                lotName: record.lotName,
                departmentId: record.departmentId,
                department: record.department,
                isPaymentRefund: record.isPaymentRefund,
                isRepoRefund: record.isRepoRefund,
                isACH: record.isACH,
                is1099: record.is1099,
                isBillPaymentCheck: record.isBillPaymentCheck,
                entityAddress: record.entityAddress,
                refundAccount: record.refundAccount ?? null,
                paidToId: record.paidToId === 0 ? null : record.paidToId ?? null,
                isVehiclePurchase: record.isVehiclePurchase,
                processTypeId: record.processTypeId,
                processRecordId: record.processRecordId,
                accountingLines: this.mapGlLines(record.accountingLines),
            };
        }

        return {};
    }

    static mapDepositToSave(record) {
        if (record) {
            return {
                cbId: record.cbId,
                depositNumber: record.depositNumber,
                bankAccount: record.bankAccount,
                date: DateUtils.isValid(record.date) ? DateUtils.toLocal(record.date).toDate() : '',
                amount: NumberUtils.round(record.amount),
                memo: record.memo,
                status: record.status,
                lotId: record.lotId,
                lotName: record.lotName,
                departmentId: record.departmentId,
                department: record.department,
                isACH: record.isACH,
                batchOutData: record.batchOutData,
                processTypeId: record.processTypeId,
                processRecordId: record.processRecordId,
                accountingLines: this.mapGlLines(record.accountingLines),
            };
        }

        return {};
    }

    static mapCBLines(records) {
        if (records) {
            return records.map((data) => {
                let processNumber = 0;
                if (data.depositNumber > 0) processNumber = data.depositNumber;
                else if (data.checkNumber > 0) processNumber = data.checkNumber;
                else { processNumber = data.achCheckNumber; }

                return {
                    ...data,
                    cbId: Number(data.cbId),
                    checkNumber: Number(data.checkNumber),
                    depositNumber: Number(data.depositNumber),
                    achCheckNumber: data.achCheckNumber ? Number(data.achCheckNumber) : null,
                    amount: NumberUtils.round(data.amount),
                    processNumber,
                    accountCOA: {
                        accountNumber: data.bankAccount,
                        description: data.accountDescription,
                    },
                };
            });
        }

        return [];
    }

    static mapCBByInvoice(records) {
        if (records) {
            return records.map((data) => ({
                invoiceId: data.processRecordId,
                processTypeId: data.processTypeId,
                cbId: data.cbId,
                checkNumber: data.checkNumber > 0 ? data.checkNumber : data.achCheckNumber,
                amount: NumberUtils.round(data.amount),
                type: data.type,
                status: data.status,
                date: data.date,
                memo: data.memo,
                paidTo: data.paidTo,
                invoiceAmount: NumberUtils.round(data.invoiceAmount),
            }));
        }

        return [];
    }
}

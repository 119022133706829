import React, { Component } from 'react';

// GraphQL
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import GraphQLClient from 'services/apollo/GraphQLClient';
import UserQuery from 'services/graphQL/query/UserQuery';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealService from 'services/modules/DealService';
import CommissionService from 'services/modules/CommissionService';

// Enum
import PropTypes from 'prop-types';
import { EmployeeType } from 'utils/enum/UserEnum';

const emptyOption = { label: 'None', value: 0 };

const CommissionsDialogContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();

        this.dealService = new DealService();
        this.commissionService = new CommissionService();

        this.state = {
            commissions: {},
            salesmanList: [],
            salesManagerList: [],
            financeManagerList: [],
            BDCList: [],
            isCallingService: false,
        };

        this.initBind();
    }

    onChange(name, value) {
        // eslint-disable-next-line
        let { state: { commissions } } = this;

        commissions = update(commissions, {
            [name]: { $set: value },
        });

        this.setState({
            commissions,
        }, () => {
            if ((name === 'salesPersonId1' || name === 'salesPersonId2'
                || name === 'bdcAgentId' || name === 'salesManagerId'
                || name === 'financeManagerId')
                || ((name === 'commission1Override' || name === 'commission2Override'
                || name === 'bdcAgentOverride' || name === 'salesManagerOverride'
                || name === 'financeCommissionOverride') && value === false)) {
                this.getCalculatedCommissions();
            }
        });
    }

    onSave() {
        this.setState({ isCallingService: true });

        // eslint-disable-next-line react/prop-types
        const { props: { updateCommisions } } = this;
        const args = this.getCommissionsArgs(true);

        this.commissionService.saveCommissions(args).then((response) => {
            const { data, graphQLErrors } = response;

            this.setState({ isCallingService: false });

            if (graphQLErrors) {
                ModalUtils.errorMessage(graphQLErrors);
                return;
            }

            if (data) {
                updateCommisions();
                ModalUtils.successMessage([{ message: 'Commissions updated successfully' }]);
            }
        });
    }

    getServicesData() {
        const { accountNumber } = this.props;
        const args = {
            types: [EmployeeType.SALESMAN, EmployeeType.SALES_MANAGER,
                EmployeeType.FINANCE_MANAGER, EmployeeType.BDC_SALES,
                EmployeeType.ASSISTANT_BDC_MANAGER,
            ],
        };
        this.setState({
            load: true,
        });

        this.graphqlClient
            .query(UserQuery.GET_USERS, args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getUsers) {
                    this.mapList(data.getUsers);
                }
            });

        this.commissionService
            .getDealCommission({ dealId: accountNumber })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setData(data);
                }
            })
            .finally(() => {
                this.setState({
                    load: false,
                });
            });
    }

    getCalculatedCommissions() {
        this.setState({ isCallingService: true });

        const args = this.getCommissionsArgs();

        this.graphqlClient
            .query(DealsQuery.CALCULATE_COMMISSIONS, args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.calculateCommission) {
                    this.updateComissions(data.calculateCommission);
                }
            })
            .finally(() => {
                this.setState({ isCallingService: false });
            });
    }

    getCommissionsArgs(isSaving = false) {
        const { props: { accountNumber } } = this;
        const {
            state: {
                commissions: {
                    salesPersonId1, commissions1, commission1Override,
                    salesPersonId2, commission2Override, commissions2,
                    bdcAgentId, bdcAgentOverride, bdcAgentCommission,
                    salesManagerId, salesManagerOverride, salesManagerCommission,
                    financeManagerId, financeCommissionOverride, financeCommissions,
                },
            },
        } = this;

        const objectName = isSaving ? 'commissions' : 'commissionFields';
        const object1Name = isSaving ? 'salesRep1' : 'salesRep1Commission';
        const object2Name = isSaving ? 'salesRep2' : 'salesRep2Commission';
        const object3Name = isSaving ? 'bdcAgent' : 'bdcAgentCommission';
        const object4Name = isSaving ? 'salesManager' : 'salesManagerCommission';
        const object5Name = isSaving ? 'financeManager' : 'financeManagerCommission';

        const args = {
            accountNumber,
            [objectName]: {
                [object1Name]: {
                    id: salesPersonId1,
                    amount: commissions1,
                    override: commission1Override,
                },
                [object2Name]: {
                    id: salesPersonId2,
                    amount: commissions2,
                    override: commission2Override,
                },
                [object3Name]: {
                    id: bdcAgentId,
                    amount: bdcAgentCommission,
                    override: bdcAgentOverride,
                },
                [object4Name]: {
                    id: salesManagerId,
                    amount: salesManagerCommission,
                    override: salesManagerOverride,
                },
                [object5Name]: {
                    id: financeManagerId,
                    amount: financeCommissions,
                    override: financeCommissionOverride,
                },
            },
        };

        return args;
    }

    setData(data) {
        this.setState((prevState) => ({
            commissions: update(prevState.commissions, { $set: data }),
        }));
    }

    updateComissions(data) {
        let { state: { commissions } } = this;

        commissions = update(commissions, {
            commissions1: { $set: data.salesRep1Commission.amount },
            commissions2: { $set: data.salesRep2Commission.amount },
            bdcAgentCommission: { $set: data.bdcAgentCommission.amount },
            salesManagerCommission: { $set: data.salesManagerCommission.amount },
            financeCommissions: { $set: data.financeManagerCommission.amount },
        });
        this.setState({ commissions });
    }

    mapList(users) {
        const salesmanList = [emptyOption];
        const salesManagerList = [emptyOption];
        const financeManagerList = [emptyOption];
        const BDCList = [emptyOption];

        (users || []).forEach((user) => {
            const label = `${user.firstName || ''} ${user.lastName || ''}`;
            const item = { value: user.userId, label };

            if (user.employeeType === EmployeeType.SALESMAN) salesmanList.push(item);
            if (user.employeeType === EmployeeType.SALES_MANAGER) salesManagerList.push(item);
            if (user.employeeType === EmployeeType.FINANCE_MANAGER) financeManagerList.push(item);
            if (user.employeeType === EmployeeType.BDC_SALES || user.employeeType === EmployeeType.ASSISTANT_BDC_MANAGER) BDCList.push(item);
        });

        this.setState({
            salesmanList,
            salesManagerList,
            financeManagerList,
            BDCList,
        });
    }

    subscribeCommissionsUpdated() {
        const { props: { accountNumber } } = this;
        const input = {
            dealId: accountNumber,
        };

        this.commissionService.commissionSubscribe(this.responseSubscription, input)
            .then((response) => {
                this.commissionUpdatedSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data) {
            this.setData(data);
        }
    }

    unsubscribeCommissionUpdated() {
        if (this.commissionUpdatedSubscription) {
            this.commissionUpdatedSubscription.unsubscribe();
        }
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.setData = this.setData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeCommissionsUpdated = this.subscribeCommissionsUpdated.bind(this);
        this.unsubscribeCommissionUpdated = this.unsubscribeCommissionUpdated.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onChange={this.onChange}
                getServicesData={this.getServicesData}
                subscribeCommissionsUpdated={this.subscribeCommissionsUpdated}
                unsubscribeCommissionUpdated={this.unsubscribeCommissionUpdated}
            />
        );
    }
};

export default CommissionsDialogContainer;

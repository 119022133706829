import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

const BackButton = ({ route, size, color }) => (
    <IconButton
        to={route}
        size={size}
        color={color}
        component={RouterLink}
    >
        <ArrowBackIcon />
    </IconButton>
);

BackButton.propTypes = {
    route: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
};

BackButton.defaultProps = {
    size: 'medium',
    color: 'primary',
};

export default BackButton;

import { gql } from '@apollo/client';

export default class MessageCenterQuery {
    static GET_SMS_CHAT = gql`
        query getSMSChat($paging: DataPaging!, $search: String, $lots: [Int]) {
            getSMSChat(paging: $paging, search: $search, lots: $lots) {
                totalCount
                data {
                    smsChatId
                    cellPhone
                    lastUpdated
                    sms {
                        direction
                        status
                        createdOn
                        from
                        to
                        opportunity {
                            lotName
                            leadCode
                            crmId
                        }
                    }
                    customer {
                        customerId
                        email
                        cellPhone
                        firstName
                        lastName
                        allowTextCellPhone
                    }
                }
            }
        }
    `;

    static GET_SMS_BY_CHAT_ID = gql`
        query getSMSByChatId($smsChatId: ID!) {
            getSMSByChatId(smsChatId: $smsChatId) {
                totalCount
                data {
                    smsId
                    message
                    direction
                    createdOn
                    status
                    createdBy {
                        firstName
                        lastName
                        avatar
                    }
                    errorCode
                }
            }
        }
    `;
}

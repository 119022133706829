import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { configuration } from 'components/modules/crm/mail/Configuration';

const Jodit = (props) => {
    const editor = useRef(null);
    const { config } = props;

    return (
        <JoditEditor
            ref={editor}
            config={config}
            {...props}
        />
    );
};

Jodit.propTypes = {
    config: PropTypes.object,
};

Jodit.defaultProps = {
    config: configuration,
};

export default Jodit;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button, makeStyles,
    Grid,
} from '@material-ui/core';
import {
    useQuery,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import { FetchPolicy, AI_PLATFORM } from 'utils/enum/Core';
import { Form, Col } from 'react-bootstrap';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';
import AssistantFunctions from 'components/modules/settings/ai/AssistantFunctions';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        padding: '10px',
        '& > div > button': {
            marginRight: '10px',
        },
    },
    topSection: {
        display: 'flex',
        alignItems: 'end',
        paddingBottom: '10px',
        '& > div': {
            width: '50%',
            display: 'flex',
            fontSize: '13px',
        },
        '& > div:nth-child(1)': {
            marginBottom: 0,
            '& > div': {
                paddingLeft: 0,
            },
        },
        '& > div:nth-child(2)': {
            justifyContent: 'flex-end',
            '& > button': {
                marginRight: '10px',
                borderRadius: 0,
            },
        },
    },
    bottomSection: {
        '& > div': {
            marginTop: '15px',
            fontSize: '13px',
            padding: '5px',
            borderTop: `1px solid ${theme.palette.border.ghost}`,
            borderBottom: `1px solid ${theme.palette.border.ghost}`,
            '& > div > div:nth-child(2)': {
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2px',
                '& > div': {
                    width: '250px',
                    marginRight: '5px',
                    '& > div': {
                        height: '34px',
                    },
                },
                '& > input': {
                    width: '250px',
                    marginRight: '5px',
                },
            },
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
}));

const VoiceAssistantsSettings = ({
    canWrite,
}) => {
    const functionsReference = useRef();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        companies: [],
        voiceAssistants: [],
        selectedCompany: null,
        selectedAssistant: null,
    });

    const {
        companies,
        voiceAssistants,
        selectedCompany,
        selectedAssistant,
    } = state;

    const {
        data: enabledCompaniesData,
        loading: loadingEnabledCompanies,
        error: errorLoadingEnabledCompanies,
    } = useQuery(AIQuery.PULL_COMPANIES_AI_KEY_ADDED, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [loadAssistants, { loading: loadingAssistants }] = useLazyQuery(AIQuery.PULL_VOICE_ASSISTANTS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.getVoiceAssistants;
                setState((prevState) => ({
                    ...prevState,
                    voiceAssistants: data,
                }));
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [modifyVoiceAssistant, { loading: modifyingAssistant }] = useMutation(AIMutation.MODIFY_VOICE_ASSISTANT, {
        onCompleted: () => {
            loadAssistants({ variables: { companyCode: selectedCompany } });
            ModalUtils.successMessage(null, 'Assistant modified successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingEnabledCompanies) {
            ModalUtils.errorMessage(errorLoadingEnabledCompanies?.graphQLErrors);
            return;
        }

        if (!loadingEnabledCompanies) {
            const data = enabledCompaniesData?.pullCompaniesAIKeyAdded;
            if (companies) {
                setState((prevState) => ({
                    ...prevState,
                    companies: data.filter((i) => i.platform === AI_PLATFORM.VAPI),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEnabledCompanies, errorLoadingEnabledCompanies]);

    useEffect(() => {
        if (selectedCompany != null) loadAssistants({ variables: { companyCode: selectedCompany } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            ...(name === 'selectedCompany' ? { selectedAssistant: null } : {}),
        }));
    };

    const onModifyAssistant = () => {
        const selectedFunctions = functionsReference?.current?.getFunctionsSelected() ?? [];

        modifyVoiceAssistant({
            variables: {
                companyCode: selectedCompany,
                assistantId: selectedAssistant,
                functions: selectedFunctions,
            },
        });
    };

    const isSavedDisabled = StringUtils.isEmpty(selectedAssistant) || loadingAssistants;
    const existingFunctions = selectedAssistant && voiceAssistants?.length > 0
        ? (voiceAssistants.find((v) => v.id === selectedAssistant)?.model.tools ?? []).map((t) => ({
            description: t.function.description,
            function: t.function.name,
        }))
        : [];
    return (
        <>
            <Grid container className={classes.box}>
                <Grid className={classes.topSection} item xs={12}>
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Company</Form.Label>
                            <Select
                                disabled={modifyingAssistant}
                                size="sm"
                                loading={loadingEnabledCompanies}
                                className={classes.input}
                                name="selectedCompany"
                                onChange={(name, value) => onChange(name, value)}
                                value={selectedCompany ?? ''}
                                options={companies.map((c) => ({
                                    value: c.companyCode,
                                    label: `${c.companyCode} - ${c.companyName}`,
                                }))}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Assistant</Form.Label>
                            <Select
                                disabled={modifyingAssistant}
                                size="sm"
                                loading={loadingEnabledCompanies || loadingAssistants}
                                className={classes.input}
                                name="selectedAssistant"
                                onChange={(name, value) => onChange(name, value)}
                                value={selectedAssistant ?? ''}
                                options={voiceAssistants.map((v) => ({
                                    value: v.id,
                                    label: v.name,
                                }))}
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Button
                            disabled={isSavedDisabled || !canWrite || modifyingAssistant}
                            className={classes.containedSecondaryInfo}
                            size="small"
                            onClick={onModifyAssistant}
                        >
                            Save
                        </Button>
                    </div>
                </Grid>
                {selectedAssistant && (
                    <Grid id="main-container" className={classes.bottomSection} item xs={12}>
                        <AssistantFunctions
                            ref={functionsReference}
                            existingFunctions={existingFunctions}
                            parentActionInProgress={modifyingAssistant}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

VoiceAssistantsSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default VoiceAssistantsSettings;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import { SUPPORTED_FILES_EXT } from 'utils/enum/OpenAIEnum';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import FileEditor from 'components/modules/settings/ai/FileEditor';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';

// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
            fontSize: '15px',
        },
    },
    content: {
        padding: '20px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '450px',
        overflow: 'hidden',
        minWidth: '900px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(2), & > button:nth-child(3)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    addContainer: {
        marginTop: '10px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        '& > input': {
            width: '250px',
            fontSize: '13px',
        },
        '& > button': {
            marginLeft: '5px',
        },
    },
    uploader: {
        display: 'none',
    },
}));

const GlobalFilesForm = ({ canWrite, toggleDialog }) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const resourceUploader = useRef(null);
    const replacementUploader = useRef(null);
    const [state, setState] = useState({
        files: [],
        documentName: null,
        selectedFile: null,
        isUploadingResource: false,
        isDeletePromptVisible: false,
        isFileEditorOpen: false,
        updatedFiles: [],
    });

    const {
        files,
        documentName,
        selectedFile,
        isUploadingResource,
        isDeletePromptVisible,
        isFileEditorOpen,
        updatedFiles,
    } = state;

    const {
        data: filesData,
        loading: loadingFiles,
        error: errorLoadingFiles,
        refetch: refetchFiles,
    } = useQuery(AIQuery.LIST_GLOBAL_FILES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (errorLoadingFiles) {
            ModalUtils.errorMessage(errorLoadingFiles?.graphQLErrors);
            return;
        }

        if (!loadingFiles) {
            const data = filesData?.listGlobalFiles;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    files: data,
                    documentName: null,
                    selectedFile: null,
                    isDeletePromptVisible: false,
                    isUploadingResource: false,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFiles, errorLoadingFiles]);

    const [removeGlobalFile, { loading: removingFile }] = useMutation(AIMutation.DELETE_GLOBAL_FILE, {
        onCompleted: () => {
            refetchFiles();
            ModalUtils.successMessage(null, 'Global File removed successfully');
        },
        onError: (error) => {
            setState((prevState) => ({
                ...prevState,
                isDeletePromptVisible: false,
            }));

            ModalUtils.errorMessage(null, error);
        },
    });

    const [updateAssistantsFiles, { loading: updatingAssistants }] = useMutation(AIMutation.UPDATE_ASSISTANTS_GLOBAL_FILES, {
        onCompleted: () => {
            ModalUtils.successMessage(null, 'Assistants updated successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openFileDialog = (isReplacement = false, record) => {
        if (isReplacement) {
            setState((prevState) => ({
                ...prevState,
                selectedFile: record,
            }));

            const { current } = replacementUploader;
            if (current) current.click();

            return;
        }

        const { current } = resourceUploader;
        if (current) current.click();
    };

    const replaceFile = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        if (!selectedFile.url.endsWith(ext)) {
            ModalUtils.errorMessage(null, 'The same file type is required to update.');
            return;
        }

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = size / 1024 / 1024;
            const { data } = await client.mutate({
                mutation: AIMutation.EDIT_GLOBAL_FILE,
                variables: {
                    fileId: selectedFile.aiGlobalFileId,
                    path: selectedFile.url,
                    size: sizeInMB,
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.editGlobalFile;
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    updatedFiles: [...new Set([...updatedFiles, selectedFile.url])],
                }));

                ModalUtils.successMessage(null, 'File updated successfully');
                refetchFiles();
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const uploadResource = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        if (!Object.values(SUPPORTED_FILES_EXT).includes(ext)) {
            ModalUtils.errorMessage(null, `Only ${Object.values(SUPPORTED_FILES_EXT).map((ex) => ex.replace('.', '')).join(', ')} documents are supported`);
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = size / 1024 / 1024;
            const { data } = await client.mutate({
                mutation: AIMutation.CREATE_GLOBAL_FILE,
                variables: {
                    name: documentName ?? StringUtils.toPascalCase(name.replace(ext, '')),
                    size: sizeInMB,
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.createGlobalFile;
            if (response) {
                ModalUtils.successMessage(null, 'File created successfully');
                refetchFiles();
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const toggleFileEditor = (record, wasFileUpdated = false) => {
        if (isFileEditorOpen) refetchFiles();

        setState((prevState) => ({
            ...prevState,
            selectedFile: record,
            isFileEditorOpen: !isFileEditorOpen,
            ...(wasFileUpdated ? {
                updatedFiles: [...new Set([...updatedFiles, selectedFile.url])],
            } : {}),
        }));
    };

    const toggleDeletePrompt = (record) => {
        setState((prevState) => ({
            ...prevState,
            selectedFile: record,
            isDeletePromptVisible: !isDeletePromptVisible,
        }));
    };

    const removeFile = () => {
        removeGlobalFile({
            variables: {
                fileId: selectedFile.aiGlobalFileId,
            },
        });
    };

    const updateAssistants = () => {
        updateAssistantsFiles({
            variables: {
                modifiedFilesURLs: updatedFiles.length > 0 ? updatedFiles : files.map((f) => f.url),
            },
        });
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.name}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Size',
            dataKey: 'size',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{`${record.size} MB`}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'URL',
            dataKey: 'url',
            width: 400,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.url}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 130,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { url } = record;
                const isTxt = url?.endsWith('.txt');

                return (
                    <div className={classes.actionsContainer}>
                        <input
                            ref={replacementUploader}
                            className={classes.uploader}
                            type="file"
                            onChange={replaceFile}
                        />
                        <Button
                            disabled={!canWrite || isUploadingResource || updatingAssistants}
                            className={classes.containedSecondaryInfo}
                            size="small"
                            startIcon={<CloudUploadOutlinedIcon />}
                            onClick={() => openFileDialog(true, record)}
                        />
                        {isTxt && (
                            <Button
                                disabled={!canWrite || isUploadingResource || updatingAssistants}
                                className={classes.containedSecondaryInfo}
                                size="small"
                                startIcon={<EditOutlinedIcon />}
                                onClick={() => toggleFileEditor(record)}
                            />
                        )}
                        <Button
                            disabled={!canWrite || isUploadingResource || updatingAssistants}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => toggleDeletePrompt(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('table-container')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 30;
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Global Files"
                onClose={() => (!updatingAssistants ? toggleDialog() : null)}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.addContainer}>
                        <Form.Control
                            placeholder="Name the document (optional)"
                            maxLength={255}
                            disabled={!canWrite}
                            className={classes.input}
                            type="text"
                            name="documentName"
                            value={documentName || ''}
                            onChange={({ target: { name, value } }) => onChange(name, value)}
                        />
                        {canWrite && (
                            <>
                                <input
                                    ref={resourceUploader}
                                    className={classes.uploader}
                                    type="file"
                                    onChange={uploadResource}
                                />
                                <Button
                                    disabled={isUploadingResource || updatingAssistants}
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    onClick={() => openFileDialog()}
                                >
                                    Add
                                </Button>
                            </>
                        )}
                    </div>
                    <div id="table-container" className={classes.tableContainer}>
                        {parentWidth > 0 && (
                            <VirtualTable
                                loading={loadingFiles}
                                rowHeight={45}
                                totalRecords={files.length}
                                data={files}
                                columns={columns}
                                width={tableWidth}
                            />
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!canWrite || isUploadingResource || updatingAssistants || updatedFiles.length === 0}
                    className={classes.containedSecondaryInfo}
                    size="small"
                    onClick={updateAssistants}
                >
                    {updatingAssistants ? 'Updating...' : 'Update Assistants'}
                </Button>
            </DialogActions>
            {isFileEditorOpen && (
                <FileEditor file={selectedFile} toggleFileEditor={toggleFileEditor} />
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this file? Associated assistants to this file will not be interrupted"
                open={isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={removeFile}
                disablePrimaryButton={removingFile}
                disableSecondaryButton={removingFile}
            />
        </Dialog>
    );
};

GlobalFilesForm.propTypes = {
    canWrite: PropTypes.bool.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};

export default GlobalFilesForm;

/* eslint-disable react/no-danger */
import React, { useReducer } from 'react';
import {
    Avatar, Typography, Divider,
    IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isEmpty, uniq } from 'lodash';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import { MailStatus } from 'utils/enum/Mail';
import ReplyIcon from '@material-ui/icons/Reply';
import ImageIcon from '@material-ui/icons/Image';
import If from 'components/widgets/conditional/If';
import { makeStyles } from '@material-ui/core/styles';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ViewRecipient from 'components/modules/crm/mail/ViewRecipient';
import StatusIcon from 'components/modules/crm/mail/StatusIcon';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    to: {
        color: 'rgb(99, 115, 129)',
        fontSize: '13px',
        fontWeight: 500,
    },
    email: {
        marginLeft: theme.spacing(1),
    },
    headerMessage: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
    },
    bodyMessage: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 3),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    text: {
        fontSize: 14,
        fontWeight: 500,
        flex: 1,
        '& table': {
            margin: 'auto',
        },
        '& table p': {
            margin: '14px 0',
        },
    },
    emailFrom: {
        color: '#5f6368',
        fontSize: '13px',
        marginLeft: '6px',
        fontWeight: '400',
    },
    status: {
        marginLeft: theme.spacing(0.5),
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'block',
        textAlign: 'end',
    },
    replyIconButton: {
        marginLeft: theme.spacing(2),
    },
    attachmentContainer: {
        width: '100%',
        borderTop: '1px dotted #d8d8d8',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1),
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    itemAttachment: {
        width: '70px',
        height: '50px',
        border: '1px solid #e1e1e1',
        marginRight: theme.spacing(2),
    },
    download: {
        width: '100%',
        height: '100%',
        color: 'rgb(33, 43, 54)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleAttachment: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    visibilityIcon: {
        marginRight: theme.spacing(1),
    },
    popper: {
        zIndex: 16,
    },
}));
const regexUUID = new RegExp(/\b[A-F0-9a-f]{8}\b-[A-F0-9a-f]{4}-[A-F0-9a-f]{4}-[A-F0-9a-f]{4}-\b[A-F0-9a-f]{12}\b/g);
const regImage = new RegExp(/\.(jpe?g|png)$/i);

const replaceCID = (images, payload) => {
    let result = payload;

    if (images.length > 0) {
        const allRecordToReplace = payload.match(regexUUID);

        allRecordToReplace.forEach((item) => {
            const attachment = images.find((itemImage) => itemImage.emailAttachmentId?.toUpperCase() === item.toUpperCase());
            if (!isEmpty(attachment)) {
                result = result.replace(`cid:${item}${attachment.extension}`, attachment.url);
            }
        });

        return result;
    }

    return payload;
};

// TODO: Improve this method when the attachment preview is integrated.
const AttachmentPreview = ({ record }) => {
    const classes = useStyles();
    const result = record.map((item, index) => {
        const isImage = regImage.test(item.extension);

        return (
            <div key={index} className={classes.itemAttachment}>
                <a download={`${item.fileName}${item.extension}`} href={`${item.url}`} className={classes.download}>
                    {isImage ? (<ImageIcon />) : <InsertDriveFileIcon /> }
                </a>
            </div>
        );
    });

    return (<div className="d-flex-justify-start-align-center">{result}</div>);
};

AttachmentPreview.propTypes = {
    record: PropTypes.array.isRequired,
};

const initState = {
    anchorElement: null,
    open: false,
};

const ACTION_TYPES = {
    TOGGLE_OPENED_BY: 'ToggleOpenedBy',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.TOGGLE_OPENED_BY: {
        return update(state, {
            open: { $set: action.value },
            anchorElement: { $set: action.anchorElement },
        });
    }
    default:
        return state;
    }
};

const Message = ({ record, onReply }) => {
    const classes = useStyles();
    const emails = StringUtils.getEmailsByText(record.toFull);
    const emailsCc = StringUtils.getEmailsByText(record.cc);
    const allEmails = uniq([...emails, ...emailsCc]);
    const containsMultiEmails = allEmails.length > 1;
    const emailsTo = allEmails.join(', ');
    const attachments = record.attachments ?? [];
    const imageInline = attachments.filter((item) => item.inline);
    const attachmentsToDownload = attachments.filter((item) => !item.inline);
    const result = imageInline.length ? replaceCID(imageInline, record.bodyHTML) : record.bodyHTML;
    const [state, dispatch] = useReducer(reducer, initState);
    const { open, anchorElement } = state;

    const onViewRecipients = (event) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_OPENED_BY,
            value: !open,
            anchorElement: event.currentTarget,
        });
    };

    const onCloseViewRecipients = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_OPENED_BY,
            value: false,
            anchorElement: null,
        });
    };

    return (
        <div>
            {open && <ViewRecipient open={open} anchorElement={anchorElement} emailId={record.emailId} onCloseViewRecipients={onCloseViewRecipients} />}
            <div className={classes.headerMessage}>
                <Avatar alt={record.fromName} src={record.image} className={classes.avatar} />
                <div className="d-flex-space-between flex-1">
                    <div>
                        <Typography variant="h6">
                            {record.fromName}
                            <span className={classes.emailFrom}>{`<${record.from}>`}</span>
                        </Typography>
                        <span className={classes.to}>
                            To:
                            <span className={classes.email}>{emailsTo}</span>
                        </span>
                    </div>
                    <div>
                        <div className="d-flex-center">
                            {
                                record.status === MailStatus.READ
                                && (
                                    <IconButton className={classes.visibilityIcon} size="medium" onClick={onViewRecipients}>
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                )
                            }
                            <span className={classes.to}>{DateUtils.format(record.createdOn, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>
                            <IconButton className={classes.replyIconButton} size="medium" onClick={() => onReply(record)}>
                                <ReplyIcon fontSize="small" />
                            </IconButton>
                            {containsMultiEmails && (
                                <IconButton size="medium" onClick={() => onReply(record, true)}>
                                    <ReplyAllIcon fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                        <div className="d-flex-justify-end-align-center">
                            <StatusIcon status={record.status} />
                            <span className={classes.status}>{record.status}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.bodyMessage}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: result || record.bodyPlain,
                    }}
                    className={clsx(classes.text)}
                />
                <If condition={attachmentsToDownload.length > 0}>
                    <div className={classes.attachmentContainer}>
                        <Typography
                            className={classes.titleAttachment}
                        >
                            {attachmentsToDownload.length > 1 ? `${attachmentsToDownload.length} Attachments` : 'Attachment'}
                        </Typography>
                        <AttachmentPreview record={attachmentsToDownload} />
                    </div>
                </If>
            </div>
            <Divider />
        </div>
    );
};

Message.propTypes = {
    record: PropTypes.object.isRequired,
    onReply: PropTypes.func.isRequired,
};

export default Message;

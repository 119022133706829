import React, {
    useReducer, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles, Dialog, DialogContent, Typography, AppBar, Toolbar, Badge, Button, Tooltip,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import { useLazyQuery, useQuery } from '@apollo/client';
import clsx from 'clsx';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import InputSearch from 'components/widgets/InputSearch';
import {
    AddIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon, CheckOutlinedIcon, FiberManualRecordIcon, ThumbUpAltOutlinedIcon,
} from 'components/icons';
import Header from 'components/widgets/Header';
import LabelValue from 'components/widgets/LabelValue';
import { Form } from 'react-bootstrap';
import NumberUtils from 'lib/NumberUtils';
import Filter from 'components/widgets/Filter';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserContext from 'components/context/UserContext';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import PartsRequestCreate from 'components/modules/purchaseParts/parts/create/PartsRequestCreate';
import PartsInventoryCreate from 'components/modules/purchaseParts/parts/create/PartsInventoryCreate';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles((theme) => (
    {
        dialogContent: {
            height: '70vh',
            '& > div:first-child': {
                height: '70vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        filterWidth: {
            width: '170px',
            border: `solid 1px ${theme.palette.border.ghost}`,
            padding: '4px 5px',
            borderRadius: '0.2rem',
            paddingRight: '10px',
            '& div': {
                fontSize: '14px',
                fontWeight: 400,
            },
        },
        iconFilterCls: {
            position: 'relative',
            right: '25px',
            top: '6px',
            zIndex: '-1',
        },
        paddingRight10: {
            paddingRight: '10px',
        },
        paddingLeft10: {
            paddingLeft: '10px',
        },
        paddingTop10: {
            paddingTop: '10px',
        },
        labelFont: {
            fontSize: '14px',
        },
        labelFont12: {
            fontSize: '12px',
        },
        c: {
            margin: '5px 0px',
        },
        paddingLegend: {
            padding: '10px 10px 0px 10px',
        },
        textRight: {
            textAlign: 'right',
        },
    }
));

const ACTION_TYPES = {
    SET_TABLE: 'setTable',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValue',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE_APPROVED: 'setTableApproved',
    SET_SERVICE_SETTINGS: 'setServiceSettings',
};

const partsReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE_APPROVED: {
        const newRecords = state.table.records.map((item) => ({
            ...item,
            isChecked: item.partsInventoryId === action.value.partsInventoryId ? !item.isChecked : item.isChecked,
        }));

        return {
            ...state,
            table: {
                totalCount: state.table.totalCount,
                records: newRecords,
            },
            isDirty: newRecords.filter((c) => c.isChecked).length > 0,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_SERVICE_SETTINGS: {
        return {
            ...state,
            serviceSettings: {
                ...state.serviceSettings,
                ...action.payload,
            },
        };
    }
    default:
        return state;
    }
};

const PartsSelect = (props) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        open, onPopupClose, onSelectItems, vendorId,
        lotName: lotNameProcess,
        invoiceNumber, serviceJobId, showRequestPartsButton,
        technicianId, allowSelectNegative, isSellingParts,
    } = props;

    const initialState = {
        params: {
            paginate: {
                init: 0,
                limit: 100,
                ignoreLimit: false,
            },
            status: 'All',
            search: '',
            showAllVendors: false,
            lotSelected: [],
            includeInactive: false,
            includeZero: allowSelectNegative,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        isDirty: false,
        openRequestPart: false,
        openInventoryPart: false,
        serviceSettings: {
            allowAddInventoryPartsFromProcess: false,
        },
    };

    const [state, dispatch] = useReducer(partsReducer, initialState);
    const { userInformation = {} } = useContext(UserContext);
    const availableLots = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    const {
        params, table, isDirty, openRequestPart,
        openInventoryPart, serviceSettings,
    } = state;

    const {
        allowAddInventoryPartsFromProcess,
    } = serviceSettings;

    const {
        data, loading, error,
    } = useQuery(PartsQuery.GET_PARTS_INVENTORY_LIST_SELECT, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                status: params.status,
                vendorId,
                showAllVendors: params.showAllVendors,
                lotsName: params.lotSelected?.map((c) => c.label) ?? [],
                includeInactive: params.includeInactive,
                includeZero: params.includeZero,
            },
            sort: [
                { fieldName: 'description', dir: DataSort.ASC },
            ],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getServiceSettingsKeys] = useLazyQuery(ServiceQuery.GET_SERVICE_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getServiceSettingsKeys) {
                dispatch({
                    type: ACTION_TYPES.SET_SERVICE_SETTINGS,
                    payload: res.getServiceSettingsKeys,
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text.trim(),
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSelectedItems = () => {
        const selectedRecords = table?.records.filter((c) => c.isChecked);
        onSelectItems(selectedRecords);
    };

    const onLotChange = (lots) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                lotSelected: lots,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const openSelectPart = (opt) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openRequestPart: opt,
            },
        });
    };

    const openSelectInventoryPart = (opt, res = null) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openInventoryPart: opt,
            },
        });

        if (res) {
            onSelectItems([{
                ...res,
            }]);
        }
    };

    const {
        highlightedColor, columnHeaderStyle, columnCenter, columnStyle, columnLeft, columnRight,
        appBar, centerItems, title, dialogContent, main, containerFilterSearch, search, alignContent,
        labelFont, buttonSave, filterLabel, filterWidth, iconFilterCls, mainTable, paddingRight10,
        labelFont12, paddingLegend, paddingLeft10, marginTopBottom5, textRight,
    } = classes;

    const columns = [
        {

            Cell: (cell) => {
                const {
                    original: {
                        partsInventoryId, isChecked, lotName, active, quantityOnHand,
                    },
                } = cell;

                if (lotNameProcess !== lotName || !active || (quantityOnHand <= 0 && !allowSelectNegative)) return '';

                return (
                    isChecked ? (
                        <CheckBoxIcon
                            className={highlightedColor}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: { partsInventoryId } })}
                        />
                    )
                        : (
                            <CheckBoxOutlineBlankIcon
                                className={highlightedColor}
                                onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: { partsInventoryId } })}
                            />
                        )
                );
            },
            width: 60,
            headerClassName: clsx(columnHeaderStyle, columnCenter),
            className: clsx(columnStyle, columnCenter),
            sortable: false,
            disableSortBy: false,
            filterable: false,
        },
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Stock Number',
            minWidth: 180,
            width: 180,
            id: 'PartStockNumber',
            accessor: 'partStockNumber',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Description',
            id: 'Description',
            accessor: 'description',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'QOH',
            minWidth: 70,
            width: 70,
            id: 'QuantityOnHand',
            accessor: 'quantityOnHand',
            headerClassName: clsx(columnHeaderStyle, columnRight),
            className: clsx(columnStyle, columnRight),
        },
        {
            Header: 'Cost',
            minWidth: 90,
            width: 90,
            id: 'PartCost',
            accessor: 'partCost',
            headerClassName: clsx(columnHeaderStyle, columnRight),
            className: clsx(columnStyle, columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'List Price',
            minWidth: 90,
            width: 90,
            id: 'NetPrice',
            accessor: 'netPrice',
            headerClassName: clsx(columnHeaderStyle, columnRight),
            className: clsx(columnStyle, columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Lot Name',
            minWidth: 100,
            width: 100,
            id: 'LotName',
            accessor: 'lotName',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Is Taxable',
            minWidth: 70,
            width: 70,
            id: 'isTaxable',
            accessor: 'isTaxable',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const {
                    original: {
                        isTaxable,
                    },
                } = cellData;

                if (isTaxable) {
                    return <span><CheckOutlinedIcon fontSize="small" /></span>;
                }
                return <span />;
            },
        },
        {
            Header: '',
            minWidth: 90,
            width: 90,
            id: 'active',
            accessor: 'active',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { active, lotName, quantityOnHand } } = cellData;
                let color = '#20b118';

                if (lotNameProcess !== lotName && active) color = '#d6a000';
                if (quantityOnHand <= 0 && !allowSelectNegative && active) color = '#3366FF';
                if (!active) color = '#e53935';

                return (
                    <FiberManualRecordIcon
                        htmlColor={color}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getPartsInventoryList } = data;

            records.push(...getPartsInventoryList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getPartsInventoryList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        const currentLot = availableLots?.filter((c) => c.label.toLowerCase() === lotNameProcess.toLowerCase());
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                lotSelected: currentLot,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });

        if (lotNameProcess !== '') {
            getServiceSettingsKeys({
                variables: {
                    lotName: lotNameProcess,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotNameProcess, availableLots.length]);

    const approvedRecords = table?.records.filter((c) => c.isChecked).map((c) => c.partsInventoryId);

    return (
        <>
            <Dialog
                open={open}
                onClose={onPopupClose}
                maxWidth="md"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            Select parts
                        </Typography>
                        <div className={centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <div className={main}>
                        <Header>
                            <div className={containerFilterSearch}>
                                <InputSearch
                                    customClasses={clsx(search)}
                                    initialSearch={params.search || ''}
                                    onSearch={onSearch}
                                />
                                <div>
                                    <LabelValue
                                        label="Items"
                                        value={table.totalCount}
                                        className={paddingRight10}
                                    />
                                    <Form.Group
                                        controlId="checkboxIncludeInactiveId"
                                        className={clsx(alignContent, paddingRight10, marginTopBottom5, textRight)}
                                    >
                                        <Form.Check
                                            checked={params.includeInactive}
                                            type="checkbox"
                                            label="Search in inactive parts"
                                            className={labelFont}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: ACTION_TYPES.SET_PARAMS,
                                                    value: {
                                                        ...params,
                                                        includeInactive: e.target.checked,
                                                        paginate: {
                                                            ...params.paginate,
                                                            init: 0,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                {vendorId > 0
                                && (
                                    <Form.Group controlId="formBasicCheckbox" className={clsx(alignContent, paddingRight10)}>
                                        <Form.Check
                                            checked={params.showAllVendors}
                                            type="checkbox"
                                            label="Show all vendors"
                                            className={labelFont}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: ACTION_TYPES.SET_PARAMS,
                                                    value: {
                                                        ...params,
                                                        showAllVendors: e.target.checked,
                                                        paginate: {
                                                            ...params.paginate,
                                                            init: 0,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                ) }
                                <div className={`d-flex-align-baseline-space-between ${paddingRight10}`}>
                                    <Typography
                                        variant="h5"
                                        className={filterLabel}
                                    >
                                        Lot: &nbsp;
                                    </Typography>
                                    <Filter
                                        showTooltip
                                        useInternalSearch
                                        emptyRecord="All"
                                        maxWidthLabel={150}
                                        className={filterWidth}
                                        records={availableLots || []}
                                        selectedValues={params.lotSelected}
                                        applyFilter={(values) => onLotChange(values)}
                                        onClearFilter={() => onLotChange([])}
                                    />
                                    <ArrowDropDownIcon className={iconFilterCls} />
                                </div>
                                <Badge badgeContent={approvedRecords?.length ?? 0} color="primary">
                                    <Button
                                        className={buttonSave}
                                        startIcon={<ThumbUpAltOutlinedIcon />}
                                        size="small"
                                        disabled={!isDirty}
                                        onClick={onSelectedItems}
                                    >
                                        Select
                                    </Button>
                                </Badge>
                                {showRequestPartsButton && !allowAddInventoryPartsFromProcess && (
                                    <Tooltip
                                        placement="top-start"
                                        title={invoiceNumber === 0 || serviceJobId === '' ? 'You must save the changes before' : ''}
                                    >
                                        <Button
                                            startIcon={<AddIcon />}
                                            variant="outlined"
                                            size="small"
                                            disabled={invoiceNumber === 0 || serviceJobId === ''}
                                            onClick={() => openSelectPart(true)}
                                        >
                                            Request Part
                                        </Button>
                                    </Tooltip>
                                ) }
                                {allowAddInventoryPartsFromProcess && (
                                    <Tooltip
                                        placement="top-start"
                                        title="Add part to inventory"
                                    >
                                        <Button
                                            startIcon={<AddIcon />}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => openSelectInventoryPart(true)}
                                        >
                                            Add Part
                                        </Button>
                                    </Tooltip>
                                ) }
                            </div>
                        </Header>
                        <div className={mainTable}>
                            <Table
                                data={table?.records}
                                columns={columns}
                                cursor="default"
                                load={loading}
                                totalRecords={table?.totalCount}
                                loadMore={loadMore}
                                rowSelected
                                enableRowHover
                                className="-highlight"
                            />
                        </div>
                        <div className={clsx(containerFilterSearch, paddingLegend)}>
                            <FiberManualRecordIcon
                                htmlColor="#20b118"
                            />
                            <span className={labelFont12}>Selectable</span>
                            <FiberManualRecordIcon
                                htmlColor="#e53935"
                            />
                            <span className={labelFont12}>Not selectable due to inactive part</span>
                            <FiberManualRecordIcon
                                htmlColor="#d6a000"
                            />
                            <span className={labelFont12}>{`Not selectable due to the part belongs to other location different than ${lotNameProcess}`}</span>
                        </div>
                        <div className={clsx(containerFilterSearch, paddingLeft10)}>
                            {isSellingParts
                            && (
                                <>
                                    <FiberManualRecordIcon
                                        htmlColor="#3366FF"
                                    />
                                    <span className={labelFont12}>Not selectable because you can&apos;t sell parts less or equal to zero.</span>
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            { openRequestPart && (
                <PartsRequestCreate
                    invoiceNumber={invoiceNumber}
                    serviceJobId={serviceJobId}
                    technicianId={technicianId}
                    onPopupClose={() => openSelectPart(false)}
                />
            ) }
            { openInventoryPart && (
                <PartsInventoryCreate
                    fromProcess
                    onPopupClose={(res) => openSelectInventoryPart(false, res)}
                />
            ) }
        </>
    );
};

PartsSelect.propTypes = {
    vendorId: PropTypes.number,
    open: PropTypes.bool,
    onPopupClose: PropTypes.func,
    onSelectItems: PropTypes.func,
    lotName: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    allowSelectNegative: PropTypes.bool,
    invoiceNumber: PropTypes.number,
    serviceJobId: PropTypes.string,
    showRequestPartsButton: PropTypes.bool,
    technicianId: PropTypes.number,
    isSellingParts: PropTypes.bool,
};

PartsSelect.defaultProps = {
    vendorId: 0,
    open: false,
    onPopupClose: () => {},
    onSelectItems: () => {},
    lotName: '',
    allowSelectNegative: true,
    invoiceNumber: 0,
    serviceJobId: '',
    showRequestPartsButton: false,
    technicianId: 0,
    isSellingParts: false,
};

export default PartsSelect;

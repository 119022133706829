import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { GithubPicker } from 'react-color';
import AutoSuggest from 'react-autosuggest';
import {
    FormLabel, Chip, TextField, Paper, MenuItem, Dialog,
    DialogActions, DialogContent, DialogTitle,
    Button, IconButton, Menu,
} from '@material-ui/core';
import {
    Cancel,
    Save as SaveIcon,
    Add as AddIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line
import NestedMenuItem from 'material-ui-nested-menu-item';

import ArrayUtils from 'lib/ArrayUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { modules } from 'utils/enum/modules';

import HttpTagsModule from 'services/httpModules/HttpTagsModule';
import HttpDealsModule from 'services/httpModules/HttpDealsModule';
import HttpInventoryModule from 'services/httpModules/HttpInventoryModule';
import MapTagsData from 'services/mapData/MapTagsData';

import update from 'immutability-helper';
import { isEmpty, pullAt } from 'lodash';

const styles = (theme) => ({
    root: {
        height: 50,
        flexGrow: 1,
    },
    textField: {
        width: 150,
    },
    chip: {
        color: '#eeeeee',
        fontWeight: 'bold',
        height: 24,
        margin: '2px !important',
    },
    chipIcon: {
        color: '#eeeeee',
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
    button: {
        margin: theme.spacing(),
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 998,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    fabSize: {
        height: '24px',
        width: '26px',
        minHeight: '24px',
    },
    iconSize: {
        fontSize: '18px',
    },
    menu: {
        marginTop: '10px',
        border: '1px solid black',
    },
    deleteDialog: {
        margin: '10px 0',
    },
});

class CustomTags extends React.Component {
    constructor(props) {
        super(props);

        this.httpTagsModule = new HttpTagsModule();
        this.httpDealsModule = new HttpDealsModule();
        this.httpInventoryModule = new HttpInventoryModule();

        this.inputRef = React.createRef();
        this.lastChip = React.createRef();
        this.state = {
            value: '',
            tagOwnerId: props.id,
            tagsModule: props.module,
            tagsBehavior: [],
            suggestions: [],
            tagsList: [],
            selectedTag: {
                id: 0,
                name: '',
                module: '',
                color: '',
            },
            selectedTags: [],
            onEdit: false,
            onTagRename: false,
            isWarningDeleteOpened: false,
            tagIdToDelete: '',
            anchorEl: null,
        };

        this.initBind();

        const keyStore = new KeyStore();
        this.allowAddRemoveTag = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_TAG_WRITE);
        this.allowCreateModifyGlobalTags = keyStore.hasPermission(Permission.INVENTORY_SETTINGS_TAG_ADD);
    }

    componentDidMount() {
        const {
            state: { tagsModule },
        } = this;

        this.getAllTagsByModule();

        switch (tagsModule) {
        case modules.BHPH:
            this.loadAccountTags();
            break;
        case modules.INVENTORY:
            this.loadVehicleTags();
            break;
        default:
            break;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { state } = this;
        const selectedTags = state.selectedTags || [];
        const prevSelectedTags = prevState.selectedTags || [];

        if (prevSelectedTags.length !== selectedTags.length) {
            const tagsBehavior = this.getTagsBehavior(
                selectedTags,
                state.selectedTag,
            );

            // eslint-disable-next-line
            this.setState({ tagsBehavior });
        }
    }

    onBlurInput() {
        this.setState({ onEdit: false });
    }

    onCreateDealTag(tag) {
        const newTag = tag;
        const {
            state: { tagOwnerId, tagsList },
        } = this;
        let id = 0;
        let data = {};
        const tags = Object.assign([], tagsList);

        if (newTag.id) {
            data = {
                tagId: newTag.id,
                module: newTag.module,
            };
        } else {
            data = newTag;
            tags.push(data);
        }

        return this.httpDealsModule.addTag(tagOwnerId, data).then(
            (result) => {
                if (result) {
                    if (result.Success) {
                        id = result.Data || 0;
                        newTag.id = id;

                        const dataAfterSave = this.getDataAfterSave(id);

                        // TODO: This is bad, do not repeat yourself
                        this.setState((prevState) => update(prevState, {
                            selectedTags: { $push: [newTag] },
                            selectedTag: { $set: { id } },
                            tagsList: { $set: tags },
                            tagsBehavior: {
                                $set: dataAfterSave.tagsBehavior,
                            },
                        }));
                    } else {
                        ModalUtils.errorMessage(
                            result.Messages,
                            result.Message,
                        );
                    }
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onUpdateTagInformation(tag) {
        const newTag = tag;
        const {
            state: { tagsModule, tagsList, selectedTags },
        } = this;

        newTag.module = StringUtils.toPascalCase(tagsModule);
        // TODO: Check with someone from .net why they are updating the active field to false.
        newTag.active = true;

        this.httpTagsModule.updateTag(newTag.id, newTag).then(
            (result) => {
                if (result) {
                    if (result.Success) {
                        const tagsIndex = tagsList.findIndex(
                            (item) => item.id === newTag.id,
                        );
                        const selectedIndex = selectedTags.findIndex(
                            (item) => item.id === newTag.id,
                        );

                        tagsList[tagsIndex] = newTag;
                        selectedTags[selectedIndex] = newTag;

                        ModalUtils.successMessage(null, 'Tag updated successfully');

                        this.setState((prevState) => update(prevState, {
                            selectedTag: { $set: { id: newTag.id } },
                            tagsList: { $set: tagsList },
                            selectedTags: { $set: selectedTags },
                        }));
                    } else {
                        ModalUtils.errorMessage(
                            result.Messages,
                            result.Message,
                        );
                    }
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onCreateInventoryTag(tag) {
        const newTag = tag;
        const {
            state: { tagOwnerId, tagsList },
        } = this;
        let id = 0;
        let data = {};
        const tags = Object.assign([], tagsList);

        if (newTag.id) {
            data = {
                tagId: newTag.id,
                module: newTag.module,
            };
        } else {
            data = newTag;
            tags.push(data);
        }

        this.httpInventoryModule.createTag(tagOwnerId, data).then(
            (result) => {
                if (result) {
                    if (result.Success) {
                        id = result.Data || 0;
                        newTag.id = id;

                        const dataAfterSave = this.getDataAfterSave(id);

                        // TODO: This is bad, do not repeat yourself
                        this.setState((prevState) => update(prevState, {
                            selectedTags: { $push: [newTag] },
                            selectedTag: { $set: { id } },
                            tagsList: { $set: tags },
                            tagsBehavior: {
                                $set: dataAfterSave.tagsBehavior,
                            },
                        }), () => {
                            this.setState({ anchorEl: this.lastChip.current });
                        });
                    } else {
                        ModalUtils.errorMessage(
                            result.Messages,
                            result.Message,
                        );
                    }
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onDeleteDealTag(tagId) {
        const {
            state: { tagOwnerId, selectedTags, tagsBehavior },
        } = this;

        this.httpDealsModule.deleteTag(tagOwnerId, tagId).then(
            (result) => {
                if (result) {
                    if (result.Success) {
                        const index = selectedTags.findIndex(
                            (item) => item.id === tagId,
                        );
                        const indexBehavior = tagsBehavior.findIndex(
                            (item) => item.id === tagId,
                        );

                        pullAt(selectedTags, [index]);
                        pullAt(tagsBehavior, [indexBehavior]);

                        this.setState((prevState) => update(prevState, {
                            selectedTags: { $set: selectedTags },
                            selectedTag: { $set: { id: '' } },
                            tagsBehavior: { $set: tagsBehavior },
                        }));
                    } else {
                        ModalUtils.errorMessage(
                            result.Messages,
                            result.Message,
                        );
                    }
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onDeleteInventoryTag(tagId) {
        const {
            state: { tagOwnerId, selectedTags, tagsBehavior },
        } = this;

        this.httpInventoryModule.deleteTag(tagOwnerId, tagId).then(
            (result) => {
                if (result) {
                    if (result.Success) {
                        const index = selectedTags.findIndex(
                            (item) => item.id === tagId,
                        );
                        const indexBehavior = tagsBehavior.findIndex(
                            (item) => item.id === tagId,
                        );

                        pullAt(selectedTags, [index]);
                        pullAt(tagsBehavior, [indexBehavior]);

                        this.setState((prevState) => update(prevState, {
                            selectedTags: { $set: selectedTags },
                            selectedTag: { $set: { id: '' } },
                            tagsBehavior: { $set: tagsBehavior },
                        }));
                    } else {
                        ModalUtils.errorMessage(
                            result.Messages,
                            result.Message,
                        );
                    }
                }
            },
            (reject) => {
                ModalUtils.errorMessage(null, reject);
            },
        );
    }

    onClickButton() {
        this.setState(
            (prevState) => ({
                onEdit: !prevState.onEdit,
                onTagRename: false,
                value: '',
            }),
            () => {
                this.inputRef.focus();
            },
        );
    }

    onUpdateTag() {
        const { state } = this;
        const selectedTags = Object.assign([], state.selectedTags);
        const selectedTag = { ...state.selectedTag };

        const selectedTagIndex = selectedTags.findIndex(
            (tag) => tag.id === selectedTag.id,
            selectedTag,
        );
        selectedTags[selectedTagIndex] = selectedTag;

        this.onUpdateTagInformation(selectedTags[selectedTagIndex]);
        this.setState({ onTagRename: false });
    }

    onRenameTag(value) {
        const { state } = this;
        const selectedTag = { ...state.selectedTag };
        selectedTag.name = value;

        this.setState({ selectedTag });
    }

    onCloseDialog() {
        this.setState({ onTagRename: false });
    }

    onColorChange(color) {
        const {
            state,
        } = this;
        const { id: tagId } = state.selectedTag;
        const selectedTags = Object.assign([], state.selectedTags);
        const tagsBehavior = Object.assign([], state.tagsBehavior);

        const tagsBehaviorIndex = tagsBehavior.findIndex(
            (tag) => tag.id === tagId,
            tagId,
        );
        tagsBehavior[tagsBehaviorIndex].visible = false;
        tagsBehavior[tagsBehaviorIndex].firstTimeOpen = false;

        const selectedTagIndex = selectedTags.findIndex(
            (tag) => tag.id === tagId,
            tagId,
        );
        selectedTags[selectedTagIndex].color = color.hex;

        this.onUpdateTagInformation(selectedTags[selectedTagIndex]);
        this.setState({ tagsBehavior, anchorEl: null });
    }

    onInputChange(event, { newValue }) {
        this.setState({
            value: newValue,
        });
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            const {
                state,
                allowCreateModifyGlobalTags,
            } = this;
            const { value, tagsList } = state;
            const selectedTags = Object.assign([], state.selectedTags);
            const suggestionTag = tagsList.find((tag) => tag.name === value);
            const isExistingTag = !isEmpty(suggestionTag);

            if (!isExistingTag && !allowCreateModifyGlobalTags) {
                ModalUtils.warningMessage(null, "You don't have permission to create new Tags");
                return;
            }
            let newTag = {};

            if (value !== '') {
                if (isExistingTag) {
                    newTag = suggestionTag;
                } else {
                    newTag = {
                        name: value,
                        module: StringUtils.toPascalCase(state.tagsModule),
                        color: '#757575',
                        description: value,
                        priority: 'Medium',
                        active: true,
                    };
                }

                if (
                    selectedTags.filter((tag) => (
                        !StringUtils.isEmpty(tag.name) && !StringUtils.isEmpty(newTag.name)
                                && tag.name.toUpperCase() === newTag.name.toUpperCase()
                    )).length === 0
                ) {
                    this.addTag(newTag);
                }

                this.setState(
                    {
                        value: '',
                        onTagRename: false,
                        onEdit: false,
                    },
                );
            }
        }
    }

    onSuggestionsFetchRequested({ value }) {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        });
    }

    onSuggestionSelected(event, { suggestion }) {
        const { state } = this;
        const selectedTags = Object.assign([], state.selectedTags);

        if (event.key === 'Enter') return;

        if (selectedTags.filter((tag) => tag.id === suggestion.id).length === 0) {
            this.addTag(suggestion);
        }

        this.setState({
            onEdit: false,
            selectedTag: suggestion,
            value: '',
        });
    }

    onDeleteTag(tagId) {
        const {
            state: { tagsModule },
        } = this;

        try {
            this.onShowDeleteWarning();

            switch (tagsModule) {
            case modules.BHPH:
                this.onDeleteDealTag(tagId);
                break;
            case modules.INVENTORY:
                this.onDeleteInventoryTag(tagId);
                break;
            default:
                break;
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    }

    onMenuVisibleChange(visible, tagId) {
        const { state } = this;
        const tagsBehavior = Object.assign([], state.tagsBehavior);
        const index = tagsBehavior.findIndex((tag) => tag.id === tagId, tagId);

        if (index !== -1) {
            tagsBehavior[index].visible = visible;
            tagsBehavior[index].firstTimeOpen = false;

            this.setState({ tagsBehavior });
        }
    }

    onShowDeleteWarning(tagId = '') {
        const { props } = this;
        const {
            state: { isWarningDeleteOpened },
        } = this;

        if (props.isReadOnly) return;

        this.setState({
            isWarningDeleteOpened: !isWarningDeleteOpened,
            tagIdToDelete: tagId,
        });
    }

    onCloseMenu(visible, tagId) {
        this.onMenuVisibleChange(visible, tagId);
        this.setState({ anchorEl: null });
    }

    getAllTagsByModule() {
        const {
            state: { tagsModule },
        } = this;

        this.httpTagsModule.getTagsByModule(StringUtils.toPascalCase(tagsModule)).then(
            (result) => {
                if (result && result.StatusCode === 200) {
                    const records = [];

                    result.Data.forEach((item) => {
                        records.push(MapTagsData.mapTagsListByModule(item));
                    });

                    this.setState({ tagsList: records });
                }
            },
            (reject) => {
                if (reject && !reject.toLowerCase().startsWith('not found')) {
                    ModalUtils.errorMessage(null, reject);
                }
            },
        );
    }

    getSuggestionValue(suggestion) {
        return suggestion.name;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const { state } = this;
        const tags = Object.assign([], state.tagsList);

        return inputLength === 0
            ? []
            : tags.filter((lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue);
    }

    getDataAfterSave(id) {
        const { state } = this;
        const tagsBehavior = Object.assign([], state.tagsBehavior);

        tagsBehavior.push({
            id,
            firstTimeOpen: true,
            visible: true,
        });

        return tagsBehavior;
    }

    getTagsBehavior(tags, selectedTag) {
        const tagsBehavior = [];

        if (ArrayUtils.isNotEmpty(tags)) {
            tags.forEach((tag) => {
                tagsBehavior.push({
                    id: tag.id,
                    firstTimeOpen: selectedTag.id === tag.id,
                    visible: selectedTag.id === tag.id,
                });
            });
        }

        return tagsBehavior;
    }

    loadVehicleTags() {
        const {
            state: { tagOwnerId, selectedTag },
        } = this;

        this.httpInventoryModule.getAssignedTags(tagOwnerId).then(
            (result) => {
                if (result && result.StatusCode === 200) {
                    const records = [];

                    result.Data.forEach((item) => {
                        records.push(MapTagsData.mapListByModule(item));
                    });

                    this.setState({ selectedTags: records }, () => {
                        this.getTagsBehavior(records, selectedTag);
                    });
                }
            },
            (reject) => {
                if (reject && !reject.toLowerCase().startsWith('not found')) {
                    ModalUtils.errorMessage(null, reject);
                }
            },
        );
    }

    loadAccountTags() {
        const {
            state: { tagOwnerId, selectedTag },
        } = this;

        this.httpDealsModule.getAssignedTags(tagOwnerId).then(
            (result) => {
                if (result && result.StatusCode === 200) {
                    const records = [];

                    result.Data.forEach((item) => {
                        records.push(MapTagsData.mapListByModule(item));
                    });

                    this.setState({ selectedTags: records }, () => {
                        this.getTagsBehavior(records, selectedTag);
                    });
                }
            },
            (reject) => {
                if (reject && !reject.toLowerCase().startsWith('not found')) {
                    ModalUtils.errorMessage(null, reject);
                }
            },
        );
    }

    openMenu(event, visible, tagId) {
        this.onMenuVisibleChange(visible, tagId);

        this.setState({
            anchorEl: event.currentTarget,
            selectedTag: { id: tagId },
            onEdit: false,
        });
    }

    addTag(tag) {
        const {
            state: { tagsModule },
        } = this;

        try {
            switch (tagsModule) {
            case modules.BHPH:
                this.onCreateDealTag(tag);
                break;
            case modules.INVENTORY:
                this.onCreateInventoryTag(tag);
                break;
            default:
                break;
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        }
    }

    initBind() {
        this.addTag = this.addTag.bind(this);
        this.renameTag = this.renameTag.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlurInput = this.onBlurInput.bind(this);
        this.onRenameTag = this.onRenameTag.bind(this);
        this.onDeleteTag = this.onDeleteTag.bind(this);
        this.onUpdateTag = this.onUpdateTag.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onClickButton = this.onClickButton.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.getTagsBehavior = this.getTagsBehavior.bind(this);
        this.onMenuVisibleChange = this.onMenuVisibleChange.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onShowDeleteWarning = this.onShowDeleteWarning.bind(this);
        this.getAllTagsByModule = this.getAllTagsByModule.bind(this);
        this.loadAccountTags = this.loadAccountTags.bind(this);
        this.onCreateDealTag = this.onCreateDealTag.bind(this);
        this.onDeleteDealTag = this.onDeleteDealTag.bind(this);
        this.loadVehicleTags = this.loadVehicleTags.bind(this);
        this.onCreateInventoryTag = this.onCreateInventoryTag.bind(this);
        this.onDeleteInventoryTag = this.onDeleteInventoryTag.bind(this);
        this.onUpdateTagInformation = this.onUpdateTagInformation.bind(this);
        this.getDataAfterSave = this.getDataAfterSave.bind(this);
        this.renderChip = this.renderChip.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.onCloseMenu = this.onCloseMenu.bind(this);
    }

    renameTag() {
        const { state, state: { selectedTag } } = this;
        const selectedTags = Object.assign([], state.selectedTags);
        const tagsBehavior = Object.assign([], state.tagsBehavior);

        const tagsBehaviorIndex = tagsBehavior.findIndex((tag) => tag.id === selectedTag.id);
        tagsBehavior[tagsBehaviorIndex].visible = false;

        const selectedTagIndex = selectedTags.findIndex((tag) => tag.id === selectedTag.id);
        const selected = selectedTags[selectedTagIndex];

        this.setState({
            onTagRename: true, selectedTag: selected, tagsBehavior, anchorEl: null,
        });
    }

    renderSuggestion(suggestion, { query, isHighlighted }) {
        const selected = isHighlighted
            || query.toLowerCase() === suggestion.name.toLowerCase();

        return (
            <MenuItem selected={selected} component="div">
                {suggestion.name}
            </MenuItem>
        );
    }

    renderInputComponent(inputProps) {
        const {
            allowAddRemoveTag,
        } = this;
        const { classes, ref, ...other } = inputProps;

        if (allowAddRemoveTag) {
            return (
                <TextField
                    className={classes.textField}
                    InputProps={{
                        inputRef: (e) => {
                            ref(e);
                            this.inputRef = e;
                        },
                        classes: {
                            input: classes.input,
                        },
                        disableUnderline: true,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                        maxLength: 20,
                    }}
                    {...other}
                />
            );
        }

        return null;
    }

    renderTagMenu(tagId, visible) {
        const { state } = this;

        return (
            <Menu
                anchorEl={state.anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(state.anchorEl)}
                onClose={() => this.onCloseMenu(visible, tagId)}
            >
                <MenuItem onClick={this.renameTag}>
                    Rename
                </MenuItem>
                <NestedMenuItem
                    label="Change color"
                    parentMenuOpen
                >
                    <MenuItem className="custom-menu">
                        <GithubPicker
                            triangle="hide"
                            onChange={(color) => this.onColorChange(color)}
                        />
                    </MenuItem>
                </NestedMenuItem>
            </Menu>
        );
    }

    renderTagColorChooser(tagId, visible) {
        const { state } = this;

        return (
            <Menu
                anchorEl={state.anchorEl}
                keepMounted
                open={Boolean(state.anchorEl)}
                onClose={() => this.onCloseMenu(visible, tagId)}
            >
                <MenuItem className="custom-menu">
                    <GithubPicker
                        triangle="top-left"
                        onChange={(color) => this.onColorChange(color)}
                    />
                </MenuItem>
            </Menu>
        );
    }

    renderDeleteAlert() {
        const { props, state } = this;
        const { classes } = props;
        const { tagIdToDelete } = state;

        return (
            <Dialog
                open={state.isWarningDeleteOpened}
                onClose={this.onShowDeleteWarning}
                disableBackdropClick
                maxWidth="md"
            >
                <DialogContent className={classes.deleteDialog}>
                    <FormLabel>Are you sure you want to remove the tag?</FormLabel>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.onDeleteTag(tagIdToDelete)}
                            style={{ marginRight: '15px' }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={this.onShowDeleteWarning}
                            style={{ marginRight: '15px' }}
                        >
                            No
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

    renderTagPopup(tag, behavior, visible) {
        const { props } = this;

        if (!props.isReadOnly) {
            if (behavior.firstTimeOpen) {
                return this.renderTagColorChooser(tag.id, visible);
            }

            return this.renderTagMenu(tag.id, visible);
        }

        return <div />;
    }

    renderChip(tag, index) {
        const {
            allowAddRemoveTag,
            allowCreateModifyGlobalTags,
            props: { classes },
            state: { tagsBehavior },
        } = this;
        const hasTagsBehavior = ArrayUtils.isNotEmpty(tagsBehavior);
        const visible = hasTagsBehavior ? tagsBehavior[index].visible : false;
        const tagBehavior = hasTagsBehavior ? tagsBehavior[index] : {};
        const chipProp = {
            onDelete: () => this.onShowDeleteWarning(tag.id),
        };

        if (!allowAddRemoveTag) {
            delete chipProp.onDelete;
        }

        return (
            <React.Fragment key={`${tag.id}-${index}`}>
                <Chip
                    onClick={(event) => this.openMenu(event, visible, tag.id)}
                    label={tag.name}
                    className={`${classes.chip} item chip`}
                    deleteIcon={
                        <Cancel className={classes.chipIcon} fontSize="small" />
                    }
                    style={{
                        backgroundColor: tag.color,
                    }}
                    ref={this.lastChip}
                    {...chipProp}
                />
                { allowCreateModifyGlobalTags && (
                    this.renderTagPopup(tag, tagBehavior, visible)
                )}
            </React.Fragment>
        );
    }

    render() {
        const { props, state } = this;
        const { classes } = props;
        const {
            value,
            suggestions,
            onEdit,
            onTagRename,
            selectedTag,
        } = state;
        const selectedTags = state.selectedTags || [];
        const tagContainer = {
            flex: onEdit || ArrayUtils.isNotEmpty(selectedTags) ? '1' : '0',
        };
        const tagsStyle = {
            display: ArrayUtils.isNotEmpty(selectedTags) ? 'inline-flex' : 'none',
            flexWrap: 'wrap',
        };
        const buttonStyle = {
            display: onEdit ? 'none' : 'inline',
        };

        const inputProps = {
            onChange: this.onInputChange,
            onKeyDown: this.onKeyDown,
            onBlur: this.onBlurInput,
            placeholder: 'Enter tag name',
            value,
            classes,
            style: {
                display: onEdit ? 'inline' : 'none',
                margin: '2px',
            },
        };
        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.onSuggestionsClearRequested,
            onSuggestionSelected: this.onSuggestionSelected,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion: this.renderSuggestion,
            suggestions,
        };
        const { allowAddRemoveTag } = this;

        return (
            <>
                <div className="d-flex tag-component">
                    <FormLabel className="text-bold">Tags: </FormLabel>
                    <div style={tagContainer} className="tags-container">
                        <div className="tags-item">
                            <div style={tagsStyle}>
                                {selectedTags.map((tag, index) => this.renderChip(tag, index))}
                            </div>
                            <AutoSuggest
                                {...autosuggestProps}
                                inputProps={inputProps}
                                theme={{
                                    container: classes.container,
                                    suggestionsContainerOpen:
                                        classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={(options) => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        {allowAddRemoveTag && !props.isReadOnly && (
                            <IconButton
                                color="primary"
                                style={buttonStyle}
                                onClick={this.onClickButton}
                                size="small"
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
                <Dialog
                    open={onTagRename}
                    onClose={() => this.setState({ onTagRename: false })}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>Rename Tag</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            value={selectedTag.name}
                            onChange={(event) => this.onRenameTag(event.target.value)}
                            placeholder="Enter tag name"
                            fullWidth
                            inputProps={{
                                maxLength: 20,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={this.onCloseDialog}
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={this.onUpdateTag}
                            size="small"
                            className={classes.button}
                            color="primary"
                            disabled={selectedTag.name === ''}
                        >
                            <SaveIcon
                                className={clsx(
                                    classes.leftIcon,
                                    classes.iconSmall,
                                )}
                            />
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.renderDeleteAlert()}
            </>
        );
    }
}

CustomTags.propTypes = {
    classes: PropTypes.object.isRequired,
    module: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isReadOnly: PropTypes.bool,
};

CustomTags.defaultProps = {
    isReadOnly: false,
};

export default withStyles(styles)(CustomTags);

import { AccountingCBStatus } from 'utils/enum/AccountingEnum';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AMOUNT: 'setAmount',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
    SET_RECORD_AND_SETTING: 'setRecordANDSetting',
};

const CheckDepositReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.value, isDirty: action?.isDirty ?? state.isDirty };
    }
    case ACTION_TYPES.SET_RECORD_AND_SETTING: {
        return { ...state, record: action.value.record, editableCheckNumbers: action.value.editableCheckNumbers };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_AMOUNT: {
        if (state.record.amount === action.value) return { ...state };

        if (state.record?.status.toLowerCase() !== AccountingCBStatus.NEW.toLowerCase()
            && state.record?.status.toLowerCase() !== AccountingCBStatus.POSTED.toLowerCase()) {
            return {
                ...state,
                isDirty: state.record.amount !== action.value,
            };
        }

        return {
            ...state,
            record: {
                ...state.record,
                amount: action.value,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value,
            },
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW_CHECK: {
        const { record } = state;

        return {
            ...action.value,
            record: {
                ...action.value.record,
                bankAccount: record.bankAccount,
                type: record.type,
                isPaymentRefund: record.isPaymentRefund,
                isRepoRefund: record.isRepoRefund,
                lotId: record.lotId,
                lotName: record.lotName,
                isACH: record.isACH,
                amount: 0,
                date: record.date,
                paidToId: record.paidToId,
                paidTo: record.paidTo,
                entityAddress: record.entityAddress,
            },
            paidToList: state.paidToList,
            customerMapSetting: state.customerMapSetting,
            showInventoryPurchaseOptions: state.showInventoryPurchaseOptions,
            editableCheckNumbers: state.editableCheckNumbers,
        };
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW_DEPOSIT: {
        const { record } = state;

        return {
            ...action.value,
            record: {
                ...action.value.record,
                bankAccount: record.bankAccount,
                lotId: record.lotId,
                lotName: record.lotName,
                isACH: record.isACH,
                memo: record.memo,
                amount: 0,
            },
        };
    }
    default:
        return state;
    }
};

export default CheckDepositReducer;

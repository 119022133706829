export default class ReportMapper {
    static reconMapLines(records) {
        if (records) {
            return records.map((data) => {
                const details = data.details.map((det) => ({
                    assignee: det.assignee,
                    category: det.category,
                    repairItem: det.repairItem,
                    partDetail: !det.part && !det.partStatus ? '' : `${det.part} / ${det.partStatus ?? ''}`,
                    estimate: det.estimate && det.estimate > 0 ? `$${det.estimate}` : 'No estimated',
                    comment: det.comment,
                }));

                return {
                    stockNumber: data.stockNumber,
                    vehicleInfo: `${data.stockNumber} - ${data.make} - ${data.model} - ${data.trim} in ${data.lotName}`,
                    vehicleLocation: data.vehicleLocation,
                    details,
                    open: true,
                };
            });
        }

        return [];
    }
}

import React from 'react';
import clsx from 'clsx';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { MailStatus } from 'utils/enum/Mail';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { makeStyles, Divider, Typography } from '@material-ui/core';
import StatusIcon from 'components/modules/crm/mail/StatusIcon';

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    footer: {
        padding: theme.spacing(3, 2),
    },
    newMessage: {
        fontWeight: 'bold',
        color: '#000',
    },
    bodyMessage: {
        color: 'rgb(135 135 135)',
    },
    status: {
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'block',
        textAlign: 'center',
        marginLeft: theme.spacing(0.5),
    },
    emailFrom: {
        color: '#5f6368',
        fontSize: '13px',
        marginLeft: '6px',
        fontWeight: '400',
    },
    to: {
        color: 'rgb(99, 115, 129)',
        fontSize: '13px',
        fontWeight: 500,
    },
    email: {
        marginLeft: theme.spacing(1),
    },
    createdOn: {
        fontSize: '13px',
        minWidth: 'fit-content',
    },
    leftHeader: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
}));

const EmailActivityItem = ({ record, onSelectRecord }) => {
    const classes = useStyles();
    const placeholder = '--';
    const {
        lastBody, subject, lastIncoming, lastStatus,
        threadId, emailId,
    } = record;
    const emails = StringUtils.getEmailsByText(record.toFull);
    const emailsCc = StringUtils.getEmailsByText(record.cc);
    const allEmails = uniq([...emails, ...emailsCc]);
    const emailsTo = allEmails.join(', ');
    const createdOn = (record.createdOn != null ? DateUtils.format(record.createdOn, DateFormat.LONG_DATE_TIME_AM_PM) : placeholder);
    const isNewMessage = lastIncoming && lastStatus === MailStatus.RECEIVED;

    return (
        <div className={clsx(classes.container, 'box-shadow')} onClick={() => onSelectRecord(threadId)}>
            <div className={classes.header}>
                <div className={classes.leftHeader}>
                    <Typography variant="h6">
                        {record.fromName}
                        <span className={classes.emailFrom}>{`<${record.from}>`}</span>
                    </Typography>
                    <span className={classes.to}>
                        To:
                        <span className={classes.email}>{emailsTo}</span>
                    </span>
                </div>
                <div className={classes.createdOn}>
                    {createdOn}
                </div>
            </div>
            <Divider />
            <div className={clsx(classes.footer, 'text-pre-line')}>
                <div className="d-flex-space-between">
                    <Typography
                        noWrap
                        className={clsx({ [classes.newMessage]: isNewMessage })}
                    >
                        {subject}
                    </Typography>
                    <div className={classes.textIcon}>
                        <StatusIcon status={lastStatus} emailId={emailId} />
                        <span className={classes.status}>{lastStatus}</span>
                    </div>
                </div>
                <Typography noWrap className={classes.bodyMessage}>
                    {lastBody}
                </Typography>
            </div>
        </div>
    );
};

EmailActivityItem.propTypes = {
    record: PropTypes.object.isRequired,
    onSelectRecord: PropTypes.func.isRequired,
};

export default EmailActivityItem;

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { useQuery } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';

import {
    makeStyles,
    Divider,
    IconButton,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FeatureList from 'components/modules/inventory/read/panels/keyFeature/FeatureList';
import KeyFeatureDialog from 'components/modules/inventory/create/dialogs/keyFeature/KeyFeatureDialog';
import Loading from 'components/widgets/Loading';

import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const useStyles = makeStyles((theme) => ({
    featureDivider: {
        margin: '25px 0 10px 0',
        backgroundColor: theme.palette.background.silver,
        boxShadow: '0 1px 5px 0px rgba(51, 51, 51, 1)',
    },
    availableFeaturesHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    addFeature: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        '& > button': {
            color: theme.palette.text.green,
        },
    },
    messageText: {
        margin: '10px 0',
        textAlign: 'center',
    },
    loadingIndicator: {
        flex: 1,
    },
}));
const getAvailableFeatures = (vehicleFeatures, availableFeatures = []) => {
    const items = vehicleFeatures.map((item) => item.optionId);

    return availableFeatures.filter(
        (item) => items.indexOf(item.optionId) === -1,
    );
};

const initState = {
    openKeyFeatureDialog: false,
    editKeyFeature: false,
    selectedFeature: null,
};
const ACTION_TYPES = {
    ON_OPEN_KEY_FEATURE: 'onOpenKeyFeature',
    ON_CLOSE_KEY_FEATURE: 'onCloseKeyFeature',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.ON_OPEN_KEY_FEATURE:
        return update(state, {
            openKeyFeatureDialog: { $set: true },
            editKeyFeature: { $set: action.payload.editKeyFeature },
            selectedFeature: { $set: action.payload.selectedFeature },
        });
    case ACTION_TYPES.ON_CLOSE_KEY_FEATURE:
        return update(state, {
            openKeyFeatureDialog: { $set: false },
            editKeyFeature: { $set: false },
            selectedFeature: { $set: null },
        });
    default:
        return initState;
    }
};

const AvailableFeatures = ({
    disabled,
    onChange,
    allowAction,
    stockNumber,
    vehicleFeatures,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        data: availableFeatures,
        loading,
        error,
        refetch,
    } = useQuery(InventoryQuery.FEATURE_LIST, { variables: { stockNumber } });

    if (error) {
        const graphQLErrors = error?.graphQLErrors;

        ModalUtils.errorMessage(null, graphQLErrors);
    }
    if (loading) {
        return (
            <div className={classes.loadingIndicator}>
                <Loading />
            </div>
        );
    }

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const features = getAvailableFeatures(vehicleFeatures, availableFeatures?.getFeatureList);
    const messageText = features.length === 0
        ? 'All the features have already been added'
        : 'Available Features';

    const onOpenKeyFeature = (editKeyFeature = false, selectedFeature = null) => {
        dispatch({
            type: ACTION_TYPES.ON_OPEN_KEY_FEATURE,
            payload: {
                editKeyFeature,
                selectedFeature,
            },
        });
    };
    const onCloseKeyFeature = () => {
        dispatch({
            type: ACTION_TYPES.ON_CLOSE_KEY_FEATURE,
        });
    };

    return (
        <div>
            <Divider className={classes.featureDivider} />
            <div className={classes.availableFeaturesHeader}>
                <div className={classes.addFeature}>
                    <h4>Add New Feature</h4>
                    <IconButton
                        aria-label="add"
                        onClick={() => onOpenKeyFeature()}
                    >
                        <AddCircleIcon
                            fontSize="inherit"
                            className="green-icon"
                        />
                    </IconButton>
                </div>
                <h2 className={classes.messageText}>{messageText}</h2>
                <div />
            </div>
            <FeatureList
                features={features}
                allowAction={allowAction}
                checked={false}
                onChange={onChange}
                disabled={disabled}
                allowEdit
                onOpenKeyFeature={onOpenKeyFeature}
            />
            {INVENTORY_VEHICLE_WRITE && state.openKeyFeatureDialog && (
                <KeyFeatureDialog
                    open={state.openKeyFeatureDialog}
                    editMode={state.editKeyFeature}
                    feature={state.selectedFeature}
                    onClose={onCloseKeyFeature}
                    onReload={refetch}
                />
            )}
        </div>
    );
};

AvailableFeatures.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    allowAction: PropTypes.bool,
    vehicleFeatures: PropTypes.array,
};

AvailableFeatures.defaultProps = {
    vehicleFeatures: [],
    disabled: false,
    allowAction: false,
};

export default AvailableFeatures;

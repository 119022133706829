import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { DealSection, DealTabsTitle } from 'utils/enum/DealEnum';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';

import DealService from 'services/modules/DealService';
import DealMap from 'services/mapData/DealMap';
import InventoryService from 'services/modules/InventoryService';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

const VehiclesTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        onChangeEditingMode: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.inventoryService = new InventoryService();
        this.dealVehicleChangeSubscription = null;

        this.state = {
            vehicleInfo: {
                tmu: false,
                exempt: false,
                eml: false,
                title: false,
            },
            tradeIns: [],
            isReadOnly: true,
            isVisibleForm: false,
            showConfirmationDialog: false,
            idToRemove: null,
            tradeToEdit: null,
            tradeToEditMiles: 0,
            stockToEditMiles: 0,
            showEditMilesDialog: false,
            isSaving: false,
            tradeInsBackUp: [],
            removingTradeIns: false,
            loading: false,
        };

        this.initBind();
    }

    onRemove(idToRemove) {
        const { props: { onChangeEditingMode } } = this;
        this.setState({
            showConfirmationDialog: true,
            idToRemove,
        });

        onChangeEditingMode(true, DealSection.TRADE_IN, DealTabsTitle.VEHICLE);
    }

    onDeleteTradeIn() {
        const { state: { idToRemove } } = this;
        const args = {
            dealVehicleTradeId: idToRemove,
        };

        this.setState({ removingTradeIns: true });

        this.dealService.removeTradeIn(args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    this.hideConfirmationDialog();
                    return;
                }

                if (data && data.removeTradeIn) {
                    this.hideConfirmationDialog();
                }
            }).finally(() => {
                this.setState({ removingTradeIns: false });
            });
    }

    onEdit(dealVehicleTradeId) {
        this.setState({
            tradeToEdit: Number(dealVehicleTradeId),
            isVisibleForm: false,
        });
    }

    onOpenModalChangeVehicle() {
        this.setState({
            openDialogChangeVehicle: true,
        });
    }

    onSelectVehicle(record) {
        const { props: { accountNumber } } = this;
        const input = {
            stockNumber: record.stockNumber,
            dealId: accountNumber,
        };
        this.setState({
            saving: true,
        });

        this.dealService.changeDealVehicle(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.changeDealVehicle) {
                    this.onCloseModalChangeVehicle();
                    ModalUtils.successMessage([{ message: 'Vehicle updated successfully' }]);
                }
            })
            .finally(() => {
                this.setState({
                    saving: false,
                });
            });
    }

    onCloseModalChangeVehicle() {
        this.setState({
            openDialogChangeVehicle: false,
        });
    }

    getServicesData() {
        const { props: { accountNumber } } = this;
        const input = {
            accountNumber,
        };

        this.setState({ loading: true });

        this.dealService.getDealVehicles(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setData(data);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    setData(record) {
        this.setState({
            deal: record.deal,
            tradeInsBackUp: record.tradeIns,
            tradeIns: record.tradeIns,
            vehicleInfo: record.vehicle,
        });
    }

    openEditMilesDialog(isTradeIn, stockNumber) {
        if (isTradeIn) {
            this.setState({
                showEditMilesDialog: true,
                tradeToEditMiles: stockNumber,
                stockToEditMiles: 0,
            });
        } else {
            this.setState({
                showEditMilesDialog: true,
                stockToEditMiles: stockNumber,
                tradeToEditMiles: 0,
            });
        }
    }

    updateMiles(milesValue) {
        const { state: { stockToEditMiles, tradeToEditMiles } } = this;
        let stockNumber = 0;
        let updateVehicleInfo;

        this.setState({ isSaving: true });

        if (stockToEditMiles > 0) {
            stockNumber = stockToEditMiles;
            updateVehicleInfo = true;
        } else if (tradeToEditMiles > 0) {
            stockNumber = tradeToEditMiles;
            updateVehicleInfo = false;
        }

        const args = {
            stockNumber,
            input: {
                miles: milesValue,
            },
        };

        this.inventoryService.updateVehicle(args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                this.setState({ isSaving: false });

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateVehicle) {
                    ModalUtils.successMessage([{ message: 'Miles updated successfully' }]);
                    this.closeEditMilesDialog();
                    this.updateMilesOnInterface(updateVehicleInfo, milesValue, stockNumber);
                }
            });
    }

    updateMilesOnInterface(updateVehicleInfo, milesValue, stockNumber) {
        const { state } = this;
        const { tradeIns } = state;
        let { vehicleInfo } = state;

        if (updateVehicleInfo) {
            vehicleInfo = update(vehicleInfo, {
                miles: { $set: milesValue },
            });

            this.setState({ vehicleInfo });
        } else {
            const index = tradeIns.findIndex((item) => item.stockNumber === stockNumber);
            const record = tradeIns[index];

            record.vehicle.miles = milesValue;
            tradeIns[index] = record;

            this.setState({ tradeIns });
        }
    }

    closeEditMilesDialog() {
        const { props: { onChangeEditingMode } } = this;
        this.setState({
            showEditMilesDialog: false,
            stockToEditMiles: 0,
            tradeToEditMiles: 0,
        });

        onChangeEditingMode();
    }

    hideConfirmationDialog() {
        const { props: { onChangeEditingMode } } = this;
        this.setState({
            showConfirmationDialog: false,
            idToRemove: null,
        });

        onChangeEditingMode();
    }

    showTradeInForm() {
        const { props: { onChangeEditingMode } } = this;

        this.setState({ isVisibleForm: true, tradeToEdit: null });
        onChangeEditingMode(true, DealSection.TRADE_IN, DealTabsTitle.VEHICLE);
    }

    hideTradeInForm() {
        const { onChangeEditingMode } = this.props;
        const { tradeInsBackUp } = this.state;

        this.setState({
            isVisibleForm: false,
            tradeToEdit: null,
            tradeIns: tradeInsBackUp,
        });
        onChangeEditingMode();
    }

    subscribeVehiclesChanged() {
        const { props: { accountNumber } } = this;
        const input = {
            dealId: accountNumber,
        };

        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.UPDATED_DEAL_VEHICLES, input)
            .then((response) => {
                this.dealVehicleChangeSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.updatedDealVehicles) {
            const { updatedDealVehicles } = data;

            this.setData(DealMap.mapDealVehicles(updatedDealVehicles));
        }
    }

    unsubscribeVehiclesChanged() {
        if (this.dealVehicleChangeSubscription) {
            this.dealVehicleChangeSubscription.unsubscribe();
        }
    }

    initBind() {
        this.onEdit = this.onEdit.bind(this);
        this.setData = this.setData.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.updateMiles = this.updateMiles.bind(this);
        this.onDeleteTradeIn = this.onDeleteTradeIn.bind(this);
        this.showTradeInForm = this.showTradeInForm.bind(this);
        this.hideTradeInForm = this.hideTradeInForm.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onSelectVehicle = this.onSelectVehicle.bind(this);
        this.openEditMilesDialog = this.openEditMilesDialog.bind(this);
        this.closeEditMilesDialog = this.closeEditMilesDialog.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.hideConfirmationDialog = this.hideConfirmationDialog.bind(this);
        this.updateMilesOnInterface = this.updateMilesOnInterface.bind(this);
        this.onOpenModalChangeVehicle = this.onOpenModalChangeVehicle.bind(this);
        this.subscribeVehiclesChanged = this.subscribeVehiclesChanged.bind(this);
        this.onCloseModalChangeVehicle = this.onCloseModalChangeVehicle.bind(this);
        this.unsubscribeVehiclesChanged = this.unsubscribeVehiclesChanged.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onRemove={this.onRemove}
                onEdit={this.onEdit}
                updateMiles={this.updateMiles}
                getServicesData={this.getServicesData}
                onDeleteTradeIn={this.onDeleteTradeIn}
                hideTradeInForm={this.hideTradeInForm}
                showTradeInForm={this.showTradeInForm}
                onSelectVehicle={this.onSelectVehicle}
                openEditMilesDialog={this.openEditMilesDialog}
                closeEditMilesDialog={this.closeEditMilesDialog}
                hideConfirmationDialog={this.hideConfirmationDialog}
                onOpenModalChangeVehicle={this.onOpenModalChangeVehicle}
                subscribeVehiclesChanged={this.subscribeVehiclesChanged}
                onCloseModalChangeVehicle={this.onCloseModalChangeVehicle}
                unsubscribeVehiclesChanged={this.unsubscribeVehiclesChanged}
            />
        );
    }
};

export default VehiclesTabContainer;

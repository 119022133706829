import { AccountingCBStatus, CheckTypes, ProcessType } from 'utils/enum/AccountingEnum';

const useValidateActions = (status, processTypeId, cbType = '', isCustomerRefund = false) => {
    let editBankAccount = true;
    let editLot = true;
    let editMemo = true;
    let editDate = true;
    let editAddress = true;
    let editCheckType = true;
    let editPayTo = true;
    let editPaymentRefund = true;
    let editRepoRefund = true;
    let allowSaveAction = true;
    let allowClearAction = true;
    let glOptions;
    let editACH = true;
    let editCheckNumber = true;

    if (status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase()
    || cbType.toLowerCase().includes(CheckTypes.PAYROLL.toLowerCase())) {
        editBankAccount = false;
        editLot = false;
        editMemo = false;
        editDate = false;
        editAddress = false;
        editCheckType = false;
        editPayTo = false;
        editPaymentRefund = false;
        editRepoRefund = false;
        allowSaveAction = false;
        allowClearAction = false;
        editCheckNumber = false;
        editACH = false;
        glOptions = {
            editglAccount: false,
            editglAmount: false,
            editglControlNumber: false,
            editglLot: false,
            editglMemo: false,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: false,
        };
    } else if (processTypeId === ProcessType.BATCH_OUT && status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()) {
        glOptions = {
            editglAccount: true,
            editglAmount: false,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: true,
            showSplitBatchLine: true,
        };
    } else if (processTypeId === ProcessType.BATCH_OUT && status.toLowerCase() !== AccountingCBStatus.NEW.toLowerCase()) {
        glOptions = {
            editglAccount: true,
            editglAmount: false,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: true,
            showSplitBatchLine: true,
        };
        editACH = false;
    } else if (processTypeId === ProcessType.ACCOUNTS_PAYABLE
        && (status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()
            || status.toLowerCase() === AccountingCBStatus.POSTED.toLowerCase()
            || status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase())) {
        editCheckType = false;
        editPayTo = false;
        glOptions = {
            editglAccount: false,
            editglAmount: true,
            editglControlNumber: false,
            editglLot: true,
            editglMemo: true,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: true,
            showSplitBatchLine: false,
        };
    } else if (processTypeId === ProcessType.ACCOUNTS_PAYABLE
        && (status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase() || status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase())) {
        editBankAccount = false;
        editCheckType = false;
        editPayTo = false;
        editPaymentRefund = false;
        editRepoRefund = false;
        allowClearAction = false;
        editACH = false;
        editCheckNumber = false;
        glOptions = {
            editglAccount: false,
            editglAmount: true,
            editglControlNumber: false,
            editglLot: true,
            editglMemo: true,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: true,
            showSplitBatchLine: false,
        };
    } else if (status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()) {
        editBankAccount = false;
        editCheckType = false;
        editPayTo = true;
        editPaymentRefund = false;
        editRepoRefund = false;
        allowClearAction = false;
        editACH = false;
        editCheckNumber = false;
        glOptions = {
            editglAccount: true,
            editglAmount: true,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: true,
            showSplitAction: false,
            showDeleteAction: true,
        };
    } else if (status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase()) {
        editBankAccount = false;
        editCheckType = false;
        editPayTo = false;
        editPaymentRefund = false;
        editRepoRefund = false;
        allowClearAction = false;
        editACH = false;
        editCheckNumber = false;
        glOptions = {
            editglAccount: true,
            editglAmount: true,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: true,
            showSplitAction: false,
            showDeleteAction: true,
        };
    } else if (cbType.toLowerCase().includes(CheckTypes.BILL.toLowerCase())) {
        editBankAccount = false;
        editLot = false;
        editMemo = false;
        editDate = false;
        editAddress = false;
        editCheckType = false;
        editPayTo = false;
        editPaymentRefund = false;
        editRepoRefund = false;
        allowSaveAction = false;
        allowClearAction = false;
        editCheckNumber = false;
        glOptions = {
            editglAccount: false,
            editglAmount: false,
            editglControlNumber: false,
            editglLot: false,
            editglMemo: false,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: false,
        };
    }

    if (isCustomerRefund && status.toLowerCase() !== AccountingCBStatus.VOID.toLowerCase() && status.toLowerCase() !== AccountingCBStatus.NEW.toLowerCase()) {
        editCheckType = false;
        editPayTo = false;
        editPaymentRefund = false;
        editRepoRefund = false;
        glOptions = {
            editglAccount: true,
            editglAmount: false,
            editglControlNumber: true,
            editglLot: true,
            editglMemo: true,
            showNewAction: false,
            showSplitAction: false,
            showDeleteAction: false,
        };
    }

    return {
        editBankAccount,
        editLot,
        editMemo,
        editDate,
        editAddress,
        editCheckType,
        editPayTo,
        editPaymentRefund,
        editRepoRefund,
        allowSaveAction,
        allowClearAction,
        glOptions,
        editACH,
        editCheckNumber,
    };
};

export default useValidateActions;

import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { Communication } from 'utils/enum/SubModule';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import {
    Avatar, Typography, makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MailStatus } from 'utils/enum/Mail';
import StatusIcon from 'components/modules/crm/mail/StatusIcon';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(1),
    },
    mailItem: {
        position: 'relative',
        padding: theme.spacing(0, 2),
        color: 'rgb(99, 115, 129)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        display: 'flex',
        alignItems: 'center',
        '&.unread': {
            backgroundColor: 'rgb(244, 246, 248)',
        },
        textDecoration: 'none',
    },
    bodyMessageContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        padding: theme.spacing(2, 0),
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: theme.spacing(2),
    },
    subject: {
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: theme.spacing(0, 2),
        minWidth: '200px',
        fontSize: '14px',
        width: '200px',
    },
    createdOn: {
        fontSize: '0.75rem',
        fontWeight: 400,
        width: '60px',
        display: 'block',
        textAlign: 'right',
    },
    status: {
        fontSize: '12px',
        fontWeight: 'bold',
        display: 'block',
        marginLeft: theme.spacing(0.5),
    },
    zt: {
        margin: theme.spacing(1),
        color: 'rgb(135 135 135)',
    },
    bodyMessage: {
        color: 'rgb(135 135 135)',
    },
    newMessage: {
        fontWeight: 'bold',
        color: '#000',
    },
    containerStatus: {
        display: 'flex',
        alignItems: 'center',
        width: '80px',
        marginLeft: theme.spacing(0.5),
    },
}));

const MailItem = ({ record }) => {
    const {
        subject, createdOn, lastBody, unread,
        to, lastStatus, fromName, lastIncoming,
        emailId,
    } = record;
    const classes = useStyles();
    const currentCreatedOn = DateUtils.isSame(createdOn, new Date())
        ? DateUtils.format(createdOn, DateFormat.TIME_AMPM)
        : DateUtils.format(createdOn, DateFormat.DAY_MONTH);
    const isNewMessage = lastIncoming && lastStatus === MailStatus.RECEIVED;

    return (
        <Link
            className={clsx(classes.mailItem, { unread })}
            to={`/${modules.COMMUNICATION}/${Communication.MAIL}/${record.threadId}`}
        >
            <div className={classes.bodyMessageContainer}>
                <Avatar alt={to} className={classes.avatar} />
                <span
                    className={clsx(classes.subject, { [classes.newMessage]: isNewMessage })}
                >
                    {fromName}
                </span>
                <div className="d-flex-center">
                    <Typography
                        noWrap
                        className={clsx({ [classes.newMessage]: isNewMessage })}
                    >
                        {subject}
                    </Typography>
                    <Typography noWrap className={classes.bodyMessage}>
                        <span className={classes.zt}>-</span>
                        {lastBody}
                    </Typography>
                </div>
            </div>
            <div className="d-flex-center">
                <div className={classes.containerStatus}>
                    <StatusIcon status={lastStatus} emailId={emailId} />
                    <span className={classes.status}>{lastStatus}</span>
                </div>
                <span className={classes.createdOn}>{currentCreatedOn}</span>
            </div>
        </Link>
    );
};

MailItem.propTypes = {
    record: PropTypes.object.isRequired,
};

export default MailItem;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import {
    Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Tooltip, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    DeleteOutlineOutlinedIcon,
    ExpandMoreIcon,
} from 'components/icons';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import BasicStyles from 'styles/modules/basic/basicStyles';
import NumberUtils from 'lib/NumberUtils';
import { round } from 'lodash';
import ServiceJobsParts from 'components/modules/service/serviceInvoice/create/ServiceJobsParts';
import ServiceJobsHeader from 'components/modules/service/serviceInvoice/create/ServiceJobsHeader';
import ServiceJobsMoreInfo from 'components/modules/service/serviceInvoice/create/ServiceJobsMoreInfo';
import clsx from 'clsx';
import { v1 as uuid } from 'uuid';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { SERVICE_JOBS_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));
const ownStyle = makeStyles((theme) => (
    {
        customHeight: {
            minHeight: '80vh',
            paddingLeft: '16px',
        },
        fullWidth: {
            width: '100%',
        },
        splitOne: {
            display: 'flex',
            overflow: 'hidden',
            flex: 1,
        },
        accordionFeature: {
            marginBottom: '16px',
            minWidth: '1100px',
            '& .MuiAccordion-root': {
                marginBottom: '16px',
            },
            '& .MuiAccordionSummary-root': {
                backgroundColor: '#f7f7f7',
                minHeight: '50px',
                padding: '0 10px',
            },
            '& .MuiAccordionSummary-content': {
                margin: '5px 0',
                paddingLeft: '5px',
                fontSize: '14px',
            },
            '& .MuiAccordionSummary-expandIcon': {
                padding: '0 10px',
            },
            '& .MuiAccordionDetails-root': {
                padding: '0px',
            },
            [theme.breakpoints.down('md')]: {
                minWidth: '100vw',
            },
        },
        summaryTextInLine: {
            display: 'flex',
            overflow: 'auto',
            padding: 0,
            position: 'absolute',
            lineHeight: '12px',
            right: 50,
            '& > table > tbody > tr > td': {
                width: 80,
                fontSize: 12,
                textAlign: 'right',
            },
            '& > table > tbody > tr > td.status': {
                width: 150,
                textAlign: 'left',
                justifyContent: 'flex-start',
            },
            '& > table > tbody > tr > td > div.statusInSummary': {
                width: '100%',
                textAlign: 'left',
            },
            '& > table > tbody > tr > td.causeAndCorrection': {
                width: 300,
                textAlign: 'left',
                justifyContent: 'flex-start',
                '& div': {
                    fontSize: 12,
                    fontWeight: 400,
                    maxHeight: 40,
                    display: 'grid',
                },
                '& span': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 285,
                },
            },
        },
        summaryTextWithDescription: {
            lineHeight: '15px',
        },
        paddingRight10: {
            paddingRight: '10px',
        },
        minWidth: {
            minWidth: 800,
            [theme.breakpoints.down('sm')]: {
                minWidth: '100vw',
            },
        },
        headerJobCls: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tblMinCls: {
            minHeight: '60px',
        },
        techAvatar: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            paddingLeft: '10px',
            '& > img': {
                verticalAlign: 'middle',
                width: 30,
                height: 30,
                borderRadius: '50%',
            },
            '& > p': {
                fontSize: '10px',
            },
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
        },
        declinedJob: {
            backgroundColor: '#ffdddd !important',
        },
        deleteButton: {
            color: theme.palette.error.main,
        },
    }
));

const ServiceJobsItem = (props) => {
    const [idToDelete, setIdToDelete] = useState(null);

    const {
        jobItem,
        onChangeJob,
        onDeleteJob,
        onAddJobPart,
        onDeletePart,
        onChangeJobPart,
        vendorList,
        userList,
        recordFields,
        editDetails,
        opCodesList,
    } = props;

    const classes = {
        ...useStyles(),
        ...containerStyles(),
        ...ownStyle(),
        ...basicFormStyle(),
    };

    const {
        invoiceNumber,
        jobNumber,
        jobTypeId,
        jobTypeDescription,
        jobDescription,
        hours,
        laborTotal,
        subletPrice,
        partsTotal,
        status,
        identifierJobsHeader,
        identifierJobsMoreInfo,
        identifierJobsParts,
        technician,
        technicianImage,
        customerStates,
        correction,
        approved,
        serviceJobId,
        technicianId,
    } = jobItem;

    const { parts, open, ...rest } = jobItem;

    const {
        accordionFeature, accordionHeader, summaryTextInLine,
        accordionContent, summaryTextWithDescription, paddingRight10,
        minWidth, headerJobCls, tblMinCls, techAvatar, displayFlex,
        declinedJob, deleteButton,
    } = classes;

    const {
        allowSaveAction,
        editJobStatus,
    } = editDetails;

    const setExpandOrCollapse = (val) => {
        onChangeJob('expandeOrCollapse', jobNumber, val);
    };

    const onChangeJobHeaderValue = (field, val) => {
        onChangeJob('header', jobNumber, val, field);
    };

    const onChangeJobAdditionalValue = (field, val) => {
        onChangeJob('moreInfo', jobNumber, val, field);
    };

    const onChangePartValue = (columnId, newValue, cell) => {
        onChangeJobPart(jobNumber, columnId, newValue, cell);
    };

    const onCloseDeleteConfirm = () => {
        setIdToDelete(null);
    };

    const onDeleteConfirm = () => {
        onDeleteJob(jobNumber);

        onCloseDeleteConfirm();
    };

    return (
        <>
            <Accordion
                key={String(invoiceNumber) + String(jobNumber)}
                expanded={open}
                onChange={(_, val) => setExpandOrCollapse(val)}
                className={accordionFeature}
            >
                <AccordionSummary
                    className={clsx(accordionHeader, approved ? '' : declinedJob)}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography
                        variant="h6"
                    >
                        <table className={tblMinCls}>
                            <tbody>
                                <tr>
                                    <td className={displayFlex}>
                                        <div className={techAvatar}>
                                            <img src={technicianImage} alt={technician} title={technician} loading="lazy" />
                                        </div>
                                    &nbsp;
                                    &nbsp;
                                        {jobNumber < 0 ? (jobNumber * -1) : jobNumber}
                                        {jobTypeDescription && ` - ${jobTypeDescription}`}
                                    </td>
                                </tr>
                                {jobTypeId > 0 && (
                                    <tr>
                                        <td>
                                            {jobDescription?.split(',')?.map((desc) => (
                                                <span className={paddingRight10} key={uuid()}>
                                                    &#x2022;
                                                    { ' '}
                                                    {desc}
                                                </span>
                                            ))}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Typography>
                    <Typography
                        variant="h6"
                        className={clsx(summaryTextInLine, jobTypeId > 0 ? summaryTextWithDescription : '')}
                    >
                        <table className={headerJobCls}>
                            <tbody>
                                <tr>
                                    <td className="causeAndCorrection">
                                        <span>Customer State / Correction:</span>
                                    </td>
                                    <td className="status">
                                        <span>Status:</span>
                                    </td>
                                    <td>
                                        <span>Hours:</span>
                                    </td>
                                    <td>
                                        <span>Labor:</span>
                                    </td>
                                    <td>
                                        <span>Parts:</span>
                                    </td>
                                    <td>
                                        <span>Sublet:</span>
                                    </td>
                                    <td>
                                        <span>Job Total:</span>
                                    </td>
                                    {allowSaveAction && (
                                        <td rowSpan={2}>
                                            <IconButton
                                                onClick={() => setIdToDelete(jobNumber)}
                                            >
                                                <Tooltip title="Delete Job">
                                                    <DeleteOutlineOutlinedIcon
                                                        className={deleteButton}
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        </td>
                                    ) }
                                </tr>
                                <tr>
                                    <td className="causeAndCorrection">
                                        <div>
                                            <Tooltip title={<h2>{customerStates}</h2>}>
                                                <span>{customerStates}</span>
                                            </Tooltip>
                                            <Tooltip title={<h2>{correction}</h2>}>
                                                <span>{correction}</span>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td
                                        className="status"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                    >
                                        <DropdownQuery
                                            name="status"
                                            allowEmptyLine={false}
                                            value={status}
                                            placeHolder="select the status"
                                            onChange={(name, newValue) => onChangeJobHeaderValue(name, newValue)}
                                            dataSource={{
                                                localData: SERVICE_JOBS_STATUS_LIST,
                                                idField: 'value',
                                                descriptionField: 'label',
                                            }}
                                            disabled={!editJobStatus}
                                            className="statusInSummary"
                                        />
                                    </td>
                                    <td>
                                        <span>{round((hours), 2)}</span>
                                    </td>
                                    <td>
                                        <span>{NumberUtils.applyCurrencyFormat(round((laborTotal), 2))}</span>
                                    </td>
                                    <td>
                                        <span>{NumberUtils.applyCurrencyFormat(round((partsTotal), 2))}</span>
                                    </td>
                                    <td>
                                        <span>{NumberUtils.applyCurrencyFormat(round((subletPrice), 2))}</span>
                                    </td>
                                    <td>
                                        <span>{NumberUtils.applyCurrencyFormat(round((laborTotal + partsTotal + subletPrice), 2))}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={accordionContent}>
                    <Grid className={minWidth} container spacing={1}>
                        <Grid item xs={12}>
                            <ServiceJobsHeader
                                jobItem={rest}
                                onChangeJobValue={(field, value) => onChangeJobHeaderValue(field, value)}
                                vendorList={vendorList}
                                identifierJobsHeader={identifierJobsHeader}
                                userList={userList}
                                editDetails={editDetails}
                                recordFields={recordFields}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ServiceJobsMoreInfo
                                jobItem={rest}
                                onChangeJobValue={(field, value) => onChangeJobAdditionalValue(field, value)}
                                identifierJobsMoreInfo={identifierJobsMoreInfo}
                                editDetails={editDetails}
                                opCodesList={opCodesList}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ServiceJobsParts
                                parts={parts}
                                onAddJobPart={(values) => onAddJobPart(values, jobNumber)}
                                onDeletePart={(partId) => onDeletePart(partId, jobNumber)}
                                onChangeJobValue={onChangePartValue}
                                lotName={recordFields.lotName}
                                identifierJobsParts={identifierJobsParts}
                                editDetails={editDetails}
                                invoiceNumber={invoiceNumber}
                                serviceJobId={serviceJobId}
                                technicianId={technicianId}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {idToDelete && (
                <ConfirmDialog
                    title="Confirm remove job"
                    description="Are you sure you want to delete this job?"
                    open
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseDeleteConfirm}
                    onClickSecondary={onCloseDeleteConfirm}
                    onClickPrimary={onDeleteConfirm}
                />
            )}
        </>
    );
};

ServiceJobsItem.propTypes = {
    jobItem: PropTypes.object,
    vendorList: PropTypes.arrayOf(PropTypes.object).isRequired,
    editDetails: PropTypes.object,
    onAddJobPart: PropTypes.func.isRequired,
    onDeletePart: PropTypes.func.isRequired,
    onChangeJobPart: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    onDeleteJob: PropTypes.func.isRequired,
    identifierJob: PropTypes.string,
    userList: PropTypes.arrayOf(PropTypes.object),
    opCodesList: PropTypes.arrayOf(PropTypes.object).isRequired,
    recordFields: PropTypes.shape({
        laborDiscount: PropTypes.bool,
        discount: PropTypes.number,
        discountPercentage: PropTypes.number,
        lotName: PropTypes.string,
        inHouse: PropTypes.bool,
    }).isRequired,
};

ServiceJobsItem.defaultProps = {
    jobItem: {},
    identifierJob: '',
    userList: [],
    editDetails: {},
};

const areEqual = (prevProps, nextProps) => (prevProps.identifierJob === nextProps.identifierJob
    && nextProps.open === prevProps.open
);

export default React.memo(ServiceJobsItem, areEqual);

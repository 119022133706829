import React, { useReducer, useRef, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import {
    makeStyles,
    DialogContent,
    Dialog,
    Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import { useMutation, useApolloClient } from '@apollo/client';
import ImageIcon from '@material-ui/icons/Image';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';
import ImagesUtils from 'utils/ImagesUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { ComponentType } from 'utils/enum/BulletinBoardEnum';
import BulletinBoardStyles from 'styles/modules/home/BulletinBoardStyles';
import BulletingBoardMutation from 'services/graphQL/mutate/home/BulletingBoardMutation';
import CommonReducer, { ACTION_TYPES } from 'components/modules/home/reducer/CommonReducer';
import BulletingBoardQuery from 'services/graphQL/query/home/BulletingBoardQuery';

const useStyle = makeStyles((theme) => BulletinBoardStyles.board(theme));
const imageStyle = makeStyles((theme) => BulletinBoardStyles.image(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const Image = ({
    component,
    actionRemove,
    permission,
}) => {
    const classes = { ...useStyle(), ...buttonStyles() };
    const imageClasses = imageStyle();
    const uploader = useRef(null);
    const client = useApolloClient();

    const { elements, bulletinBoardComponentId } = component;
    const imageData = elements.find((element) => element.type === ComponentType.IMAGE)
    || { title: '', value: '' };

    const INITIAL_STATE = {
        data: { ...imageData, value: '' },
        currentTitle: imageData.title,
        currentURL: imageData.value,
        isEditFormOpen: false,
        isContentPreviewOpen: false,
        isFileBeingUploaded: false,
    };

    const [state, dispatch] = useReducer(CommonReducer, INITIAL_STATE);
    const {
        data,
        currentTitle,
        currentURL,
        isEditFormOpen,
        isContentPreviewOpen,
        isFileBeingUploaded,
    } = state;

    const isImageHostedOnAWS = (url) => {
        if (StringUtils.isEmpty(url)) return false;

        const { pathname } = new URL(url);
        const path = pathname.substring(pathname.indexOf('bulletin-board'));
        const extension = path.substring(path.lastIndexOf('.'))?.toLowerCase();

        return url.includes('amazonaws.com')
        && url.includes('bulletin-board')
        && ImagesUtils.isImage(extension);
    };

    const renewSecureImageURL = async (path) => {
        const { data: secureURLData } = await client.query({
            query: BulletingBoardQuery.GET_SECURE_URL_IMAGE,
            variables: { path },
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

        const secureURL = secureURLData?.getSecureURLImage;
        return secureURL;
    };

    useEffect(() => {
        const loadSignedURL = async () => {
            const { value } = imageData;
            if (!isImageHostedOnAWS(value)) {
                dispatch({
                    type: ACTION_TYPES.SET_DATA,
                    value: { name: 'value', value },
                });
                return;
            }

            const { pathname } = new URL(value);
            const path = pathname.substring(pathname.indexOf('bulletin-board'));

            const secureURL = await renewSecureImageURL(path);
            if (secureURL) {
                dispatch({
                    type: ACTION_TYPES.SET_DATA,
                    value: { name: 'value', value: secureURL },
                });
            }
        };

        loadSignedURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openFileDialog = () => {
        const { current } = uploader;
        if (current) current.click();
    };

    const onAddEditFormChange = ({ target: { name, value } }) => {
        dispatch({
            type: ACTION_TYPES.SET_DATA,
            value: { name, value },
        });
    };

    const openEditForm = () => {
        dispatch({
            type: ACTION_TYPES.OPEN_EDIT_FORM,
        });
    };

    const closeDialog = () => {
        dispatch({
            type: ACTION_TYPES.CLOSE_DIALOG,
        });
    };

    const [saveImageComponent, { loading }] = useMutation(BulletingBoardMutation.SAVE_IMAGE_COMPONENT, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SAVE_IMAGE_DATA,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const onImageUpload = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const extension = name.substr(name.lastIndexOf('.'))?.toLowerCase();
        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 1048576; // 1MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 1MB.');
            return;
        }

        if (!ImagesUtils.isImage(extension)) {
            ModalUtils.errorMessage(null, 'Only the following formats are accepted: png, jpeg and jpg.');
            return;
        }

        try {
            dispatch({
                type: ACTION_TYPES.SET_IMAGE_UPLOADING,
            });

            const { data: signedURLData } = await client.query({
                query: BulletingBoardQuery.GET_SIGNED_URL_UPLOAD_IMAGE,
                variables: { fileExtension: extension.replace('.', '') },
                fetchPolicy: FetchPolicy.NETWORK_ONLY,
            });

            const { url, path } = signedURLData?.getSignedURLUploadImage;
            if (url) {
                await axios.put(url, file);
                const secureURL = await renewSecureImageURL(path);
                if (secureURL) {
                    dispatch({
                        type: ACTION_TYPES.SET_DATA,
                        value: { name: 'url', value: secureURL },
                    });
                }
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const onDataSave = async () => {
        const { pathname } = new URL(currentURL);
        const path = pathname.substring(pathname.indexOf('bulletin-board'));
        const extension = path.substring(path.lastIndexOf('.'))?.toLowerCase();

        if (!StringUtils.validateURL(currentURL)) {
            ModalUtils.errorMessage(null, 'Double check the url typed in.');
            return;
        }

        if (!ImagesUtils.isImage(extension)) {
            ModalUtils.errorMessage(null, 'Only the following formats are accepted: png, jpeg and jpg.');
            return;
        }

        saveImageComponent({ variables: { bulletinBoardComponentId, data: { title: currentTitle, url: currentURL } } });
    };

    const openContentPreviewDialog = async () => {
        let content = currentURL;
        const hostedOnAWS = isImageHostedOnAWS(content);

        if (hostedOnAWS) {
            const { pathname } = new URL(content);
            const path = pathname.substring(pathname.indexOf('bulletin-board'));
            content = await renewSecureImageURL(path);
        }

        dispatch({
            type: ACTION_TYPES.OPEN_CONTENT_PREVIEW,
            value: content,
        });
    };

    const renderImage = (url = '') => (
        <img
            className={imageClasses.innerImage}
            alt={currentTitle}
            src={url}
        />
    );

    return (
        <div className={imageClasses.container}>
            <If condition={permission}>
                <Button
                    className={clsx(classes.containedError, classes.actionClosePanel)}
                    size="small"
                    onClick={() => actionRemove(component.bulletinBoardComponentId)}
                >
                    x
                </Button>
            </If>
            <div className={imageClasses.title}>
                <Form.Group>
                    <Form.Control
                        readOnly
                        as="input"
                        size="md"
                        placeholder="Title"
                        value={data.title}
                    />
                </Form.Group>
            </div>
            <div className={imageClasses.content}>
                <If condition={data.value === ''}>
                    <ImageIcon />
                </If>
                <If condition={data.value !== ''}>
                    {renderImage(data.value)}
                </If>
            </div>
            <If condition={permission}>
                <div className={imageClasses.elementActions}>
                    <IconButton
                        onClick={openEditForm}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        disabled={data.value === ''}
                        onClick={openContentPreviewDialog}
                    >
                        <LaunchIcon />
                    </IconButton>
                </div>
                <Dialog
                    open={isEditFormOpen || isContentPreviewOpen}
                    fullWidth
                    maxWidth={isEditFormOpen ? 'sm' : 'md'}
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={imageClasses.appBar}
                        title={isEditFormOpen ? 'Edit Image' : 'Content Preview'}
                        onClose={closeDialog}
                        toolbarSize="sm"
                    />
                    <DialogContent>
                        <If condition={isEditFormOpen}>
                            <Form.Group>
                                <Form.Label size="sm">Title</Form.Label>
                                <Form.Control
                                    as="input"
                                    size="sm"
                                    name="title"
                                    value={currentTitle}
                                    onChange={onAddEditFormChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label size="sm">Image URL</Form.Label>
                                <Form.Control
                                    as="input"
                                    size="sm"
                                    name="url"
                                    value={currentURL}
                                    onChange={onAddEditFormChange}
                                />
                            </Form.Group>
                            <Button
                                disabled={isFileBeingUploaded}
                                className={clsx(classes.containedInfo)}
                                size="small"
                                onClick={openFileDialog}
                            >
                                Upload
                            </Button>
                            <input
                                ref={uploader}
                                className={imageClasses.uploader}
                                type="file"
                                onChange={onImageUpload}
                            />
                        </If>
                        <If condition={isContentPreviewOpen}>
                            <div className={imageClasses.contentPreview}>
                                {renderImage(data.value)}
                            </div>
                        </If>
                    </DialogContent>
                    <If condition={isEditFormOpen}>
                        <DialogActions
                            titlePrimary="Save"
                            onClickSecondary={closeDialog}
                            onClickPrimary={onDataSave}
                            disablePrimaryButton={currentURL === '' || loading}
                        />
                    </If>
                </Dialog>
            </If>
        </div>
    );
};

Image.propTypes = {
    component: PropTypes.object.isRequired,
    actionRemove: PropTypes.func.isRequired,
    permission: PropTypes.bool.isRequired,
};

export default Image;

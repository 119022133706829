import React, { useReducer, useEffect } from 'react';

import clsx from 'clsx';
import {
    Form, Row, Col, Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import DatePicker from 'react-datepicker';
import { concat, isNumber } from 'lodash';
import NumberUtils from 'lib/NumberUtils';
import { FetchPolicy } from 'utils/enum/Core';
import PayrollUtils from 'utils/PayrollUtils';
import {
    Dialog, DialogContent, makeStyles, Tooltip,
    Button,
} from '@material-ui/core';
import If from 'components/widgets/conditional/If';
import IconButton from '@material-ui/core/IconButton';
import LotQuery from 'services/graphQL/query/LotQuery';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import { DeleteOutlineOutlinedIcon } from 'components/icons';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InputControl from 'components/widgets/editorControls/InputControl';
import PayrollSettingsQuery from 'services/graphQL/query/payroll/Settings';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import GeneratedCheckSchema from 'utils/schema/payroll/GeneratedCheckSchema';
import PayrollChecksQuery from 'services/graphQL/query/payroll/PayrollChecks';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import GeneratedChecksQuery from 'services/graphQL/query/payroll/GeneratedChecks';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import GeneratedChecksMutation from 'services/graphQL/mutate/payroll/GeneratedChecks';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const ownStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 5,
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    firstGroup: {
        flex: 1,
        margin: '0 5px',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *:last-child': {
            flex: 1,
            minWidth: 250,
        },
        '& > label': {
            minWidth: 70,
            fontWeight: 500,
            marginBottom: 0,
            marginRight: 10,
            fontSize: '14px',
            textAlign: 'end',
            color: theme.palette.text.minsk,
        },
    },
    secondGroup: {
        flex: 1,
        margin: '0 5px',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *:last-child': {
            flex: 1,
            maxWidth: 170,
        },
        '& > label': {
            minWidth: 120,
            fontWeight: 500,
            marginBottom: 0,
            marginRight: 10,
            fontSize: '14px',
            textAlign: 'end',
            color: theme.palette.text.minsk,
        },
    },
    tableHeight: {
        minHeight: 400,
    },
    addLineButton: {
        marginBottom: 10,
    },
    achCheckboxAlignment: {
        alignSelf: 'baseline',
    },
    errorText: {
        marginBottom: 5,
        textAlign: 'center',
        color: theme.palette.background.danger,
    },
}));

const ACTION_TYPES = {
    ADD_LINE: 'addLine',
    SET_CELL_VALUE: 'setCellValue',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.SET_STATE_VALUES:
        return { ...state, ...action.values };
    case ACTION_TYPES.ADD_LINE:
        const lastRow = state.checkLines.length > 0 ? state.checkLines[state.checkLines.length - 1] : null;

        const newLine = {
            amount: 0,
            memo: lastRow?.memo ?? '',
            entryId: state.entryId + 1,
            accountNumber: lastRow?.accountNumber ?? 0,
            controlNumber: lastRow?.controlNumber ?? '',
        };
        const data = concat(state.checkLines, newLine);

        return update(state, {
            checkLines: { $set: data },
            entryId: { $set: newLine.entryId },
        });
    case ACTION_TYPES.SET_CELL_VALUE:
        const index = state.checkLines.findIndex((item) => item.entryId === action.entryId);

        const newState = update(state, {
            checkLines: {
                [index]: {
                    [action.columnName]: {
                        $set: action.value,
                    },
                },
            },
        });

        if (action.columnName === 'amount') {
            const newAmount = newState.checkLines.reduce((sum, next) => sum + next.amount, 0);
            const roundedAmount = Math.round(newAmount * 100) / 100;
            return update(newState, {
                amount: { $set: roundedAmount },
            });
        }

        return newState;
    default: return state;
    }
};

const PayrollCheckDialog = ({
    onClose, checkCBID, refetch, employeeId, checkData: checkDataInput,
    enableCheckFields, enableCheckLinesFields, payPeriod,
}) => {
    const isEditing = checkCBID > 0;
    const keyStore = new KeyStore();
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };
    const initState = {
        lot: '',
        memo: '',
        lotId: 0,
        amount: 0,
        entryId: 0,
        paidTo: '',
        isACH: false,
        paidFrom: '',
        paidFromId: 0,
        checkLines: [],
        checkNumber: 0,
        date: new Date(),
        endingBalance: 0,
        editableCheckNumbers: false,
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        data: checkData, loading: loadingCheckData, error: checkDataError,
    } = useQuery(GeneratedChecksQuery.GET_PAYROLL_CHECK, {
        skip: !isEditing,
        variables: { cbId: checkCBID },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: newCheckData, loading: loadingNewCheckData, error: newCheckDataError,
    } = useQuery(PayrollChecksQuery.GET_NEW_PAYROLL_CHECK_GENERATED, {
        skip: isEditing,
        variables: {
            payPeriod,
            employeeId,
            checkData: checkDataInput,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveChanges, { loading: isUpdating }] = useMutation(GeneratedChecksMutation.SAVE_PAYROLL_CHECK, {
        onCompleted: (mutationData) => {
            if (mutationData?.savePayrollCheck) {
                ModalUtils.successMessage(null, 'Changes Saved Successfully!');
                onClose();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [createNewCheck, { loading: isCreating }] = useMutation(GeneratedChecksMutation.CREATE_PAYROLL_CHECK, {
        onCompleted: (mutationData) => {
            if (mutationData?.createNewPayrollCheck) {
                ModalUtils.successMessage(null, 'Changes Saved Successfully!');
                onClose();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(errorMessage?.graphQLErrors);
        },
    });

    const [
        getLastCheckNumber, {
            data: lastCheckData,
            error: lastCheckError,
            loading: loadingLastCheck,
        }] = useLazyQuery(AccountingQuery.GET_LAST_CHECK, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [
        getLastACHCheckNumber, {
            data: lastACHCheckData,
            error: lastACHCheckError,
            loading: loadingLastACHCheck,
        }] = useLazyQuery(AccountingQuery.GET_LAST_ACHCHECK, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [
        getEndingBalance, {
            data: endingBalanceData,
            error: getEndingBalanceError,
            loading: loadingEndingBalance,
        }] = useLazyQuery(AccountingQuery.GET_ENDING_BALANCE, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: payrollDefaults, loading: loadingPayrollDefaults, error: payrollDefaultsError,
    } = useQuery(PayrollSettingsQuery.GET_PAYROLL_DEFAULT_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        skip: isEditing || !state.paidFromId,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: { lotName: keyStore.getSelectedLot()?.lotName },
    });

    useEffect(() => {
        if (checkDataError) {
            ModalUtils.errorMessage(checkDataError?.graphQLErrors);
            return;
        }

        if (!loadingCheckData && checkData) {
            const data = PayrollUtils.getCheckMappedData(checkData.getPayrollCheck);
            dispatch({
                values: data,
                type: ACTION_TYPES.SET_STATE_VALUES,
            });
        }
    }, [checkData, loadingCheckData, checkDataError]);

    useEffect(() => {
        if (newCheckDataError) {
            ModalUtils.errorMessage(newCheckDataError?.graphQLErrors);
            onClose();
        }

        if (!loadingNewCheckData && newCheckData) {
            const data = PayrollUtils.getCheckMappedData(newCheckData.getNewPayrollCheckGenerated);
            dispatch({
                values: data,
                type: ACTION_TYPES.SET_STATE_VALUES,
            });
        }
    }, [newCheckData, loadingNewCheckData, newCheckDataError, onClose]);

    useEffect(() => {
        if (lastCheckError) {
            ModalUtils.errorMessage(lastCheckError?.graphQLErrors);
            return;
        }

        if (!loadingLastCheck && lastCheckData?.getLastCheck?.result) {
            dispatch({
                field: 'checkNumber',
                type: ACTION_TYPES.SET_STATE_VALUE,
                value: lastCheckData.getLastCheck.result,
            });
        }
    }, [lastCheckData, loadingLastCheck, lastCheckError]);

    useEffect(() => {
        if (lastACHCheckError) {
            ModalUtils.errorMessage(lastACHCheckError?.graphQLErrors);
            return;
        }

        if (!loadingLastACHCheck && lastACHCheckData?.getLastACHCheck?.result) {
            dispatch({
                field: 'checkNumber',
                type: ACTION_TYPES.SET_STATE_VALUE,
                value: lastACHCheckData.getLastACHCheck.result,
            });
        }
    }, [lastACHCheckData, loadingLastACHCheck, lastACHCheckError]);

    useEffect(() => {
        if (getEndingBalanceError) {
            ModalUtils.errorMessage(getEndingBalanceError?.graphQLErrors);
            return;
        }

        if (!loadingEndingBalance && isNumber(endingBalanceData?.getEndingBalance?.result)) {
            dispatch({
                field: 'endingBalance',
                type: ACTION_TYPES.SET_STATE_VALUE,
                value: endingBalanceData.getEndingBalance.result,
            });
        }
    }, [endingBalanceData, loadingEndingBalance, getEndingBalanceError]);

    useEffect(() => {
        if (state.paidFromId) {
            getEndingBalance({
                variables: {
                    bankAccount: state.paidFromId,
                },
            });
        }
    }, [state.paidFromId, getEndingBalance]);

    const onChange = (field, value) => {
        const isACHField = field === 'isACH';
        const isACHValue = isACHField ? value : state.isACH;
        dispatch({
            field,
            value,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
        if (field === 'paidFromId' || isACHField) {
            if (isACHValue) {
                getLastACHCheckNumber({
                    variables: {
                        bankAccount: isACHField ? state.paidFromId : value,
                    },
                });
            } else {
                getLastCheckNumber({
                    variables: {
                        bankAccount: isACHField ? state.paidFromId : value,
                    },
                });
            }
        }
    };

    useEffect(() => {
        if (payrollDefaultsError) {
            ModalUtils.errorMessage(payrollDefaultsError?.graphQLErrors);
            return;
        }

        if (!loadingPayrollDefaults && payrollDefaults?.getPayrollDefaultSettings) {
            const { defaultCheckType, editableCheckNumbers } = payrollDefaults.getPayrollDefaultSettings;
            onChange('isACH', defaultCheckType?.toLowerCase() === 'ach');
            onChange('editableCheckNumbers', editableCheckNumbers);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payrollDefaults, loadingPayrollDefaults, payrollDefaultsError]);

    const isValidData = isValidSchema(GeneratedCheckSchema, state);
    const { isValid, errors } = isValidData;

    const onDelete = (entryId) => {
        const dataAfterDeleted = state.checkLines.filter((item) => item.entryId !== entryId);
        const newAmount = dataAfterDeleted.reduce((sum, next) => sum + next.amount, 0);
        const roundedAmount = Math.round(newAmount * 100) / 100;

        dispatch({
            values: {
                amount: roundedAmount,
                checkLines: dataAfterDeleted,
            },
            type: ACTION_TYPES.SET_STATE_VALUES,
        });
    };

    const handleEditorChange = (columnName, value, cell) => {
        const { rowData: { entryId } } = cell;
        dispatch({
            value,
            entryId,
            columnName,
            type: ACTION_TYPES.SET_CELL_VALUE,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;
        if (event && (key === 'Tab' || keyCode === 9) && id === 'memo') dispatch({ type: ACTION_TYPES.ADD_LINE });

        if (event && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    useEffect(() => {
        if (state.entryId !== 0) {
            const rows = state.checkLines.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.amount-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    }, [state.entryId, state.checkLines.length]);

    const getColumns = () => {
        const columns = [
            {
                width: 40,
                label: 'Line',
                dataKey: 'line',
                className: classes.columnCenter,
                cellRenderer: (cell) => cell.rowIndex + 1,
            },
            {
                width: 225,
                label: 'Account',
                dataKey: 'accountNumber',
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            accountDescription, accountNumber, entryId, accountType,
                        }, dataKey,
                    } = cell;
                    const isExcludedAccount = [AccountingCOAType.BANK]?.includes(accountType) || false;
                    if (!isExcludedAccount) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                disabled={!enableCheckLinesFields}
                                value={accountNumber}
                                allowEmptyLine={false}
                                className={accountNumber > 0 ? '' : 'invalid-field'}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypesExcluded: [AccountingCOAType.BANK],
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{accountDescription}</span>;
                },
            },
            {
                width: 120,
                label: 'Amount',
                dataKey: 'amount',
                cellRenderer: (cell) => {
                    const { rowData: { amount, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    return (
                        <InputControl
                            name={dataKey}
                            value={amount}
                            disabled={!enableCheckLinesFields}
                            className={amount && amount !== 0 ? '' : 'invalid-field'}
                            editorCellObject={cellObject}
                            type="number"
                            onChange={handleEditorChange}
                            onKeyDown={handleEditorKeyDown}
                            comparePropertyId={entryId}
                        />
                    );
                },
            },
            {
                width: 120,
                label: 'Control #',
                dataKey: 'controlNumber',
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            isControlled, controlledBy, controlNumber, entryId,
                        }, dataKey,
                    } = cell;
                    const controlledByValue = isControlled ? controlledBy ?? '' : '';
                    const cellObject = { column: { id: dataKey }, ...cell };
                    return (
                        <InputControl
                            name={dataKey}
                            value={controlNumber}
                            disabled={!enableCheckLinesFields}
                            editorCellObject={cellObject}
                            className={controlledByValue === '' || (controlledByValue !== '' && controlNumber !== '') ? '' : 'invalid-field'}
                            onChange={handleEditorChange}
                            onKeyDown={handleEditorKeyDown}
                            comparePropertyId={entryId}
                        />
                    );
                },
            },
            {
                width: 290,
                label: 'Memo',
                dataKey: 'memo',
                cellRenderer: (cell) => {
                    const { rowData: { memo, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    return (
                        <InputControl
                            name={dataKey}
                            value={memo}
                            editorCellObject={cellObject}
                            onChange={handleEditorChange}
                            onKeyDown={handleEditorKeyDown}
                            comparePropertyId={entryId}
                        />
                    );
                },
            },
        ];

        if (enableCheckLinesFields) {
            columns.push({
                width: 30,
                dataKey: 'actions',
                cellRenderer: (cell) => {
                    const { rowData: { entryId } } = cell;
                    return (
                        <div className={classes.buttonSpacing}>
                            <IconButton
                                className={classes.buttonWrapper}
                                onClick={() => onDelete(entryId)}
                            >
                                <Tooltip title="Delete line">
                                    <DeleteOutlineOutlinedIcon
                                        className={clsx(classes.actionButtonSize, classes.deleteButton)}
                                    />
                                </Tooltip>
                            </IconButton>
                        </div>
                    );
                },
            });
        }

        return columns;
    };

    const onSave = () => {
        const input = PayrollUtils.getCheckInput(state);
        if (isEditing) {
            saveChanges({
                variables: {
                    input,
                    cbId: checkCBID,
                },
            });
        } else {
            createNewCheck({
                variables: {
                    input: {
                        payPeriod,
                        employeeId,
                        payrollCheckInput: checkDataInput,
                        generalCheckInput: { ...input, paidTo: state.paidTo },
                    },
                },
            });
        }
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
        >
            <DialogAppBar
                onClose={() => onClose()}
                title={isEditing ? 'Edit Check/ACH' : 'New Record'}
            />
            <DialogContent className={classes.dialogContent}>
                <Container>
                    <Row>
                        <Col xs={7}>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.firstGroup}>
                                    <Form.Label>Pay From:</Form.Label>
                                    <If condition={enableCheckFields}>
                                        <DropdownQuery
                                            name="paidFromId"
                                            onChange={onChange}
                                            placeholder="Select"
                                            allowEmptyLine={false}
                                            value={state.paidFromId}
                                            defaultEmptyLineText="Select"
                                            className={isValidField(errors, 'paidFromId') ? 'invalid-field' : ''}
                                            dataSource={{
                                                query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                variables: {
                                                    paginate: {
                                                        init: 0,
                                                        ignoreLimit: true,
                                                    },
                                                    filters: {
                                                        accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                    },
                                                },
                                                rootData: 'getAccountingCOAList.data',
                                                idField: 'accountNumber',
                                                descriptionField: 'fullDescription',
                                            }}
                                        />
                                    </If>
                                    <If condition={!enableCheckFields}>
                                        <Form.Control
                                            disabled
                                            size="sm"
                                            type="text"
                                            value={state.paidFromId ? state.paidFrom : ''}
                                            className={isValidField(errors, 'paidFromId') ? 'invalid-field' : ''}
                                        />
                                    </If>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.firstGroup}>
                                    <Form.Label>Pay To:</Form.Label>
                                    <Form.Control
                                        disabled
                                        size="sm"
                                        type="text"
                                        value={state.paidTo}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.firstGroup}>
                                    <Form.Label>Lot:</Form.Label>
                                    <If condition={enableCheckFields}>
                                        <DropdownQuery
                                            name="lotId"
                                            onChange={onChange}
                                            value={state.lotId}
                                            placeholder="Select"
                                            allowEmptyLine={false}
                                            defaultEmptyLineText="Select"
                                            className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                            dataSource={{
                                                query: LotQuery.GET_LOTS,
                                                rootData: 'lotList',
                                                idField: 'lotId',
                                                descriptionField: 'lotName',
                                            }}
                                        />
                                    </If>
                                    <If condition={!enableCheckFields}>
                                        <Form.Control
                                            disabled
                                            size="sm"
                                            type="text"
                                            value={state.lotId ? state.lot : ''}
                                            className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                        />
                                    </If>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.firstGroup}>
                                    <Form.Label>Memo:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.memo}
                                        onChange={(e) => onChange('memo', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                        <Col>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.secondGroup}>
                                    <Form.Label>ACH / WT:</Form.Label>
                                    <Form.Check
                                        id="isACH"
                                        type="checkbox"
                                        checked={state.isACH}
                                        disabled={!enableCheckFields}
                                        className={classes.achCheckboxAlignment}
                                        onChange={(e) => onChange('isACH', e.target.checked)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.secondGroup}>
                                    <Form.Label>Check #:</Form.Label>
                                    <InputNumber
                                        size="sm"
                                        decimalScale={0}
                                        name="checkNumber"
                                        value={state.checkNumber}
                                        disabled={!state.editableCheckNumbers || !enableCheckFields}
                                        onChange={(value) => onChange('checkNumber', value)}
                                        className={isValidField(errors, 'checkNumber') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.secondGroup}>
                                    <Form.Label>Date:</Form.Label>
                                    <DatePicker
                                        size="sm"
                                        name="date"
                                        selected={state.date}
                                        placeholderText="mm/dd/yyyy"
                                        disabled={!enableCheckFields}
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChange('date', date)}
                                        className={`form-control form-control-sm ${isValidField(errors, 'date') ? 'invalid-field' : ''}`}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.secondGroup}>
                                    <Form.Label>Amount:</Form.Label>
                                    <InputNumber
                                        disabled
                                        size="sm"
                                        showCurrency
                                        name="amount"
                                        thousandSeparator
                                        fixedDecimalScale
                                        value={state.amount}
                                        className={isValidField(errors, 'amount') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <If condition={isValidField(errors, 'amount')}>
                                <Form.Text className={classes.errorText}>
                                    {`*Amount must be ${NumberUtils.applyCurrencyFormat(state.originalAmount)}`}
                                </Form.Text>
                            </If>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.secondGroup}>
                                    <Form.Label>Ending Balance:</Form.Label>
                                    <InputNumber
                                        disabled
                                        size="sm"
                                        showCurrency
                                        allowNegative
                                        fixedDecimalScale
                                        thousandSeparator
                                        name="endingBalance"
                                        value={state.endingBalance}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                    <If condition={enableCheckLinesFields}>
                        <Row className={classes.addLineButton}>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.buttonGreen}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => dispatch({ type: ACTION_TYPES.ADD_LINE })}
                            >
                                New Line
                            </Button>
                        </Row>
                    </If>
                    <Row className={classes.tableHeight}>
                        <VirtualTable
                            height={400}
                            columns={getColumns()}
                            data={state.checkLines}
                            loading={isEditing && loadingCheckData}
                        />
                    </Row>
                </Container>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                variant="contained"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={() => onClose()}
                disablePrimaryButton={
                    !isValid
                    || isUpdating
                    || isCreating
                    || loadingCheckData
                    || loadingLastCheck
                    || loadingLastACHCheck
                    || loadingPayrollDefaults
                }
            />
        </Dialog>
    );
};

PayrollCheckDialog.propTypes = {
    checkCBID: PropTypes.number,
    payPeriod: PropTypes.string,
    checkData: PropTypes.object,
    employeeId: PropTypes.number,
    enableCheckFields: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    enableCheckLinesFields: PropTypes.bool,
};

PayrollCheckDialog.defaultProps = {
    checkCBID: 0,
    employeeId: 0,
    payPeriod: '',
    checkData: null,
    enableCheckFields: true,
    enableCheckLinesFields: true,
};

export default PayrollCheckDialog;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery, useMutation } from '@apollo/client';
import {
    makeStyles, Button, Grid,
    Checkbox,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        overflow: 'auto',
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        height: 'auto !important',
        '& > div > button': {
            marginRight: '10px',
        },
    },
    notification: {
        fontSize: '13px',
        fontWeight: 500,
        marginTop: '15px',
        height: 'auto !important',
    },
    aiSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        flexDirection: 'row',
        marginLeft: '15px',
        paddingTop: '5px',
        '& > span': {
            padding: 0,
        },
        '& > div': {
            display: 'flex',
            marginRight: '10px',
            '& > div': {
                width: '250px',
                marginLeft: '10px',
                '& > div': {
                    minHeight: '30px',
                },
            },
        },
        '& > button': {
            padding: 0,
            height: '36px',
            minWidth: '80px',
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
}));

const GeneralSettings = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        aiEnabled: false,
        aiAssistant: null,
        assistants: [],
    });

    const {
        data: assistantsData,
        loading: loadingSssistants,
        error: errorLoadingAssistants,
    } = useQuery(AIQuery.PULL_ASSISTANTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        AI_ENABLED_FOR_CHAT_COMPLETION,
        AI_ASSISTANT_FOR_CHAT_COMPLETION,
    } = LotDefaultskey;

    const {
        data: aiSettings,
        loading: aiSettingsLoading,
        error: aiSettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.AI,
            lotName: ALL_LOTS,
            key: [
                AI_ENABLED_FOR_CHAT_COMPLETION,
                AI_ASSISTANT_FOR_CHAT_COMPLETION,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveSettings, { loading: isSavingSettings }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (errorLoadingAssistants) {
            ModalUtils.errorMessage(errorLoadingAssistants?.graphQLErrors);
            return;
        }

        if (!loadingSssistants && assistantsData) {
            setState((prevState) => ({
                ...prevState,
                assistants: assistantsData?.pullAsistants,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSssistants, errorLoadingAssistants]);

    useEffect(() => {
        if (aiSettingsError) {
            ModalUtils.errorMessage(aiSettingsError?.graphQLErrors);
            return;
        }

        if (!aiSettingsLoading && aiSettings) {
            const { getSettings } = aiSettings;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                const aiEnabled = getSettings.find((setting) => setting.key === AI_ENABLED_FOR_CHAT_COMPLETION)?.value ?? 'false';
                const aiAssistant = getSettings.find((setting) => setting.key === AI_ASSISTANT_FOR_CHAT_COMPLETION)?.value;

                setState((prevState) => ({
                    ...prevState,
                    aiEnabled: aiEnabled === 'true',
                    aiAssistant,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiSettingsLoading, aiSettingsError]);

    const onChangeAI = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const saveAISettings = async () => {
        const { aiEnabled, aiAssistant } = state;

        saveSettings({
            variables: {
                category: LotsCategory.AI,
                key: AI_ENABLED_FOR_CHAT_COMPLETION,
                value: aiEnabled ? 'true' : 'false',
                lotName: ALL_LOTS,
                critical: false,
            },
        });

        saveSettings({
            variables: {
                category: LotsCategory.AI,
                key: AI_ASSISTANT_FOR_CHAT_COMPLETION,
                value: aiAssistant,
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    return (
        <>
            <div className={classes.notification}>Website AI Chat Enablement</div>
            <Grid container className={classes.box}>
                <div className={classes.aiSection}>
                    <Checkbox
                        color="primary"
                        value="selected"
                        name="aiEnabled"
                        style={{ padding: 0 }}
                        checked={state.aiEnabled}
                        onChange={(e) => onChangeAI(e.target.name, e.target.checked)}
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                    <Select
                        nowrap
                        placeholder="Assistant"
                        className={clsx(classes.input, state.aiEnabled && !state.aiAssistant ? 'invalid-field' : '')}
                        name="aiAssistant"
                        value={state.aiAssistant}
                        onChange={(name, value) => onChangeAI(name, value)}
                        options={state.assistants.map(({ aiAssistantId, name }) => ({ label: name, value: aiAssistantId }))}
                    />
                    <Button
                        disabled={(state.aiEnabled && !state.aiAssistant) || isSavingSettings}
                        size="small"
                        className={classes.containedSecondaryInfo}
                        onClick={saveAISettings}
                    >
                        Save
                    </Button>
                </div>
            </Grid>
        </>
    );
};

export default GeneralSettings;

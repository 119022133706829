/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// Components ant Others
import {
    clone, map, debounce, cloneDeep,
    isString, concat, isFinite,
} from 'lodash';
import moment from 'moment';
import DateUtils from 'lib/DateUtils';
import PropTypes from 'prop-types';
import DealUtils from 'utils/DealUtils';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import DealMap from 'services/mapData/DealMap';
import { DealSection, DealTabsTitle, DealType } from 'utils/enum/DealEnum';
import ErrorMessages from 'utils/enum/ErrorMessages';
import { modules } from 'utils/enum/modules';

// GraphQL
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';

// Permission
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const CALCULATE_PAYMENT_EXCEPTION_FIELDS = [
    'tagPlate', 'registerOutState', 'tagPlateType', 'leadSource',
    'financeCompany', 'discountOverride', 'discount', 'assigned',
    'dealType', 'buyRate', 'reserveOverride', 'reserveAmount',
];

const DealTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        onChangeEditingMode: PropTypes.func.isRequired,
        setClientId: PropTypes.func.isRequired,
        setLotName: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            dealRecap: {},
            openDeleteProductDialog: false,
            productIdToRemove: null,
            currentCompany: [],
            previousDealStructure: {},
            // Deal structure
            lotData: {},
            dealStructure: {},
            showTypeModal: '',
            deferredPayments: [],
            deferredDownPaymentToRemove: [],
            isReadOnlyDealStructure: true,
            openDialogDealStructure: false,
            dealsBackUp: {},
            vendorList: [],
            isCalculatingDeal: false,
            isSavingDeal: false,
            leadSourceList: [],
        };

        this.dealChangedSubscription = null;
        this.initBind();

        const keyStore = new KeyStore();
        this.SALES_DEAL_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);
    }

    onChangeSalesTax(name, value) {
        const { state: { dealStructure } } = this;
        const backUp = cloneDeep(dealStructure);
        backUp[name] = value;
        let shouldCalculate = false;

        if (name === 'taxExempt' && value) {
            backUp.salesTaxOverride = false;
            backUp.inventoryTaxOverride = false;
            backUp.overrideTaxRate = false;
            backUp.inventoryTaxAmount = 0;
            backUp.salesTax = 0;
            backUp.salesTaxRate = 0;
        }

        if (name === 'overrideTaxRate') {
            if (value) {
                backUp.salesTaxOverride = false;
                backUp.taxExempt = false;
                backUp.salesTax = 0;
            } else backUp.salesTaxRate = 0;
        }

        if (name === 'salesTaxOverride' && value) {
            backUp.overrideTaxRate = false;
            backUp.taxExempt = false;
            backUp.salesTaxRate = 0;
        }

        if (((name === 'taxExempt'
            || name === 'inventoryTaxOverride'
            || name === 'salesTaxOverride'
            || name === 'overrideTaxRate')
            && !value)
            || (name === 'salesTaxRate' && value > 0)
        ) {
            shouldCalculate = true;
        }

        this.setState({
            dealStructure: backUp,
        }, () => {
            if (shouldCalculate) {
                const record = this.getInputDealStructure();
                this.getDealPreview(record);
            }
        });
    }

    /**
     * Only restore the sales tax values. This is because not all the deal structure values are updated in this container,
     * so it is important not to return them to their original state.
     */
    onCancelSalesTax() {
        const { state: { dealStructure, previousDealStructure } } = this;
        const dealStructureSalesTax = cloneDeep(dealStructure);
        const backup = cloneDeep(previousDealStructure);

        dealStructureSalesTax.taxExempt = backup.taxExempt;
        dealStructureSalesTax.salesTaxOverride = backup.salesTaxOverride;
        dealStructureSalesTax.inventoryTaxOverride = backup.inventoryTaxOverride;
        dealStructureSalesTax.inventoryTaxAmount = backup.inventoryTaxAmount;
        dealStructureSalesTax.salesTax = backup.salesTax;
        dealStructureSalesTax.salesTaxRate = backup.salesTaxRate;
        dealStructureSalesTax.overrideTaxRate = backup.overrideTaxRate;

        this.setState({
            dealStructure: dealStructureSalesTax,
            openDialogDealStructure: false,
            showTypeModal: '',
        });
    }

    onSaveSalesTax() {
        const { state: { dealStructure } } = this;

        this.setState({
            previousDealStructure: cloneDeep(dealStructure),
            openDialogDealStructure: false,
            showTypeModal: '',
        }, () => {
            this.callDealPreviewDealStructure();
        });
    }

    onEditDealStructure() {
        const { props: { onChangeEditingMode } } = this;

        this.setState({
            isReadOnlyDealStructure: false,
        });
        onChangeEditingMode(true, DealSection.DEAL_STRUCTURE, DealTabsTitle.DEAL);
    }

    onCancelEditDealStructure() {
        const { props: { onChangeEditingMode } } = this;

        this.setState({
            isReadOnlyDealStructure: true,
        });
        this.resetDealInformation();

        onChangeEditingMode();
    }

    onChangeStructureValue(field, value = '') {
        this.setState((prevState) => {
            const { dealStructure: { financeCompany } } = prevState;
            const newValues = {
                [field]: value,
            };

            if (field === 'dealType') {
                const type = value.toUpperCase();
                const isCashOrWholesale = type === DealType.CASH.toUpperCase()
                  || type === DealType.WHOLESALE.toUpperCase();
                const newFinanceCompany = isCashOrWholesale ? 'CASH' : financeCompany;
                newValues.financeCompany = newFinanceCompany;
            }

            if (
                (field === 'term' && value === prevState.dealStructure.term)
             || CALCULATE_PAYMENT_EXCEPTION_FIELDS.includes(field)) newValues.hasBalloonPayment = prevState.dealStructure.hasBalloonPayment;
            else newValues.hasBalloonPayment = false;

            if (field === 'paymentOverride' && value === true) newValues.adjustLastPayment = true;

            return { dealStructure: update(prevState.dealStructure, { $merge: { ...newValues } }) };
        }, () => {
            this.callDealPreviewDealStructure(field, value);
        });
    }

    onUpdateDealStructure() {
        const {
            state: {
                dealStructure, deferredPayments, deferredDownPaymentToRemove, isSavingDeal,
            }, props: { accountNumber, onChangeEditingMode },
        } = this;

        if (isSavingDeal) {
            return;
        }

        this.setState({
            isSavingDeal: true,
        });
        const currentDealStructure = DealMap.mapToUpdate(dealStructure);
        const input = {
            accountNumber,
            input: {
                deal: currentDealStructure,
                deferredDownPayment: {
                    data: deferredPayments,
                    deleted: deferredDownPaymentToRemove,
                },
            },
        };

        this.dealService.update(input)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateDeal) {
                    this.setState({
                        isReadOnlyDealStructure: true,
                        deferredDownPaymentToRemove: [],
                    });

                    onChangeEditingMode();
                }
            }).finally(() => {
                this.setState({
                    isSavingDeal: false,
                });
            });
    }

    onApplyTitleRegistration({ registration, title, tags }) {
        const { state: { dealStructure } } = this;
        const backUp = clone(dealStructure);
        const totalTitleRegistration = registration + title + tags;
        backUp.registration = registration;
        backUp.title = title;
        backUp.tags = tags;
        backUp.totalTitleRegistration = totalTitleRegistration;

        this.setState({
            dealStructure: backUp,
        }, () => {
            this.callDealPreviewDealStructure();
        });
    }

    onApplyAmountFinanced(portfolioId, loanContractId) {
        const { state: { dealStructure } } = this;

        const newDealStructure = update(dealStructure, {
            loanContractId: { $set: loanContractId },
            portfolioId: { $set: portfolioId },
        });

        this.setState({ dealStructure: newDealStructure }, () => {
            this.callDealPreviewDealStructure();
        });
    }

    onChangeDayFirstPaymentDue(value) {
        const { state: { dealStructure } } = this;
        const currentFirstPaymentDue = isFinite(value) ? DateUtils.add(dealStructure.soldDate, value).toDate() : null;

        this.setState((prevState) => ({
            dealStructure: update(prevState.dealStructure, {
                $merge: {
                    firstPaymentDue: currentFirstPaymentDue,
                    firstPaymentDueDays: value,
                },
            }),
        }), () => {
            this.callDealPreviewDealStructure();
        });
    }

    onChangeDateFirstPaymentDue(value) {
        const { state: { dealStructure } } = this;
        const diffDay = DateUtils.diff(DateUtils.format(value), DateUtils.format(dealStructure.soldDate));

        this.setState((prevState) => ({
            dealStructure: update(prevState.dealStructure, {
                $merge: {
                    firstPaymentDueDays: diffDay,
                    firstPaymentDue: value,
                },
            }),
        }), () => {
            this.callDealPreviewDealStructure();
        });
    }

    onChangeSoldDate(value) {
        const { state: { dealStructure: { firstPaymentDueDays } } } = this;
        const currentFirstPaymentDue = isFinite(firstPaymentDueDays) && firstPaymentDueDays > 0 ? DateUtils.add(value, firstPaymentDueDays).toDate() : value;

        this.setState((prevState) => ({
            dealStructure: update(prevState.dealStructure, {
                $merge: {
                    soldDate: moment(value).startOf('day').toDate(),
                    firstPaymentDue: currentFirstPaymentDue,
                },
            }),
        }), () => {
            this.callDealPreviewDealStructure();
        });
    }

    onCancelProduct() {
        const { previousDealStructure } = this.state;

        this.setState((prevState) => ({
            dealStructure: update(prevState.dealStructure, { $set: previousDealStructure }),
            dealRecap: update(prevState.dealRecap, { $set: {} }),
        }));
    }

    getTagAndTitleSettings(lotName) {
        const input = {
            filter: {
                lotName,
            },
        };

        this.graphqlClient
            .query(LotQuery.GET_LOT_DISPLAY_SETTINGS, input)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const { lotList } = data || {};

                if (Array.isArray(lotList) && lotList.length > 0) {
                    this.setState({
                        lotData: lotList[0] || {},
                    });
                }
            });
    }

    getServicesData() {
        const { props } = this;
        const { accountNumber } = props;

        this.setState({ loading: true });

        this.dealService.get({ accountNumber })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    if (graphQLErrors?.some((x) => x.message.includes(ErrorMessages.LOT_ACCESS_DENIED) || x.message.includes(ErrorMessages.DEAL_NOT_FOUND))) {
                        props.history.push(`/${modules.DEALS}`);
                    }
                    return;
                }

                if (data) {
                    this.setData(data);
                    this.getTagAndTitleSettings(data.deal?.lotName);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
                const { dealStructure } = this.state;
                if (DealUtils.clientIdIsWeb(dealStructure.clientId) && !DateUtils.isValid(dealStructure.postedDate) && this.SALES_DEAL_WRITE) {
                    this.fetchVendorList();
                    this.getLeadSourceList();
                }
            });
    }

    async getLeadSourceList() {
        const response = await this.dealService.getLeadSources();
        const { data, graphQLErrors } = response;

        if (graphQLErrors) {
            ModalUtils.errorMessage(graphQLErrors);
            return;
        }

        if (data) {
            this.setState({
                leadSourceList: data,
            });
        }
    }

    setData(record) {
        const {
            deal, deferredPayments,
        } = record;

        const { setClientId, setLotName } = this.props;
        const currentDealStructure = DealMap.mapDetail(deal);
        const currentDeferredPayments = map(deferredPayments, (item) => DealMap.mapListDeferredDownPayment(item));

        setClientId(currentDealStructure.clientId);
        setLotName(deal.lotName);
        this.setState({
            dealStructure: currentDealStructure,
            deferredPayments: currentDeferredPayments || [],
            previousDealStructure: cloneDeep(currentDealStructure),
            // TODO: Delete the variables previousDealStructure, previousProducts and previousDealRecap and read the data from the variable dealsBackUp
            dealsBackUp: this.createDealsBackUp(record, currentDealStructure, currentDeferredPayments),
        });
    }

    getDealPreview(input) {
        const {
            state: {
                dealStructure: {
                    minimumPaymentOverride,
                    paymentAmount,
                    paymentOverride,
                    dealType,
                    financeAmount,
                    balanceDue,
                    balanceDueOverride,
                    balanceDueOverrideAmount,
                    postedDate,
                },
            },
        } = this;
        this.setState({
            isCalculatingDeal: true,
        });

        let isCashOrWholesale = false;
        if (dealType) {
            const type = dealType.toUpperCase();
            isCashOrWholesale = type === DealType.CASH.toUpperCase()
                || type === DealType.WHOLESALE.toUpperCase();
        }

        if (postedDate !== null) {
            this.setState({
                isCalculatingDeal: false,
            });
            return;
        }

        if (!StringUtils.isEmpty(balanceDueOverride) && balanceDue === balanceDueOverrideAmount) {
            this.setState({
                isCalculatingDeal: false,
            });
            return;
        }

        if (!isCashOrWholesale && paymentOverride) {
            if (paymentAmount < minimumPaymentOverride) {
                ModalUtils.errorMessage([], 'Payment selected is too low. Please enter a higher payment amount.');
                this.setState({
                    isCalculatingDeal: false,
                });
                return;
            }
            if (paymentAmount > (financeAmount / 2)) {
                ModalUtils.errorMessage([], "Payment can't be more than 50% of finance amount.");
                this.setState({
                    isCalculatingDeal: false,
                });
                return;
            }
        }

        this.graphqlClient
            .query(DealsQuery.GET_DEAL_PREVIEW, { input })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    const { state: { dealStructure, dealRecap } } = this;
                    const currentDealStructure = DealMap.mapDealPreview(dealStructure, data.getDealPreview.dealStructure);
                    const recapData = DealMap.mapRecapPreview(dealRecap, data.getDealPreview.dealRecap);

                    this.setState({
                        dealStructure: currentDealStructure,
                        dealRecap: recapData,
                    });
                }
            })
            .finally(() => {
                this.setState({ isCalculatingDeal: false });
            });
    }

    getInputDealStructure() {
        const { accountNumber } = this.props;
        const { dealStructure, deferredPayments } = this.state;

        const deferredPaymentsInput = deferredPayments.map((item, index) => ({
            number: item.paymentNumber || (index + 1),
            amount: item.paymentAmount,
            dueDate: DateUtils.getOnlyDate(item.paymentDate),
        }));
        const dealStructureInput = DealMap.mapInputPreview(dealStructure);
        const salesTaxInput = DealMap.mapSalesTaxInputPreview(dealStructure);

        return {
            dealId: accountNumber,
            deal: dealStructureInput,
            deferredPayments: deferredPaymentsInput,
            salesTaxs: salesTaxInput,
        };
    }

    getSumOfDeferredDownPayment(record = []) {
        let total = 0;

        record.forEach((item) => {
            total += item.paymentAmount;
        });

        return total;
    }

    setDeferredDownPayment(record = [], deferredToRemove = []) {
        const { state: { dealStructure, deferredDownPaymentToRemove } } = this;
        const backUp = clone(dealStructure);
        const totalDeferredDownPayment = this.getSumOfDeferredDownPayment(record);
        backUp.totalDeferredDownPayment = totalDeferredDownPayment;

        this.setState({
            deferredPayments: record,
            dealStructure: backUp,
            deferredDownPaymentToRemove: concat(deferredDownPaymentToRemove, deferredToRemove),
        }, () => {
            this.callDealPreviewDealStructure();
        });

        this.toggleModalDealStructure();
    }

    createDealsBackUp(record, currentDeal, currentDeferredPayments) {
        const backUp = cloneDeep(record);

        backUp.deal = cloneDeep(currentDeal);
        backUp.deferredPayments = [...currentDeferredPayments];
        return backUp;
    }

    resetDealInformation() {
        const { state: { dealsBackUp } } = this;
        this.setState({
            dealStructure: { ...dealsBackUp.deal },
            dealRecap: {},
            deferredPayments: cloneDeep(dealsBackUp.deferredPayments),
            deferredDownPaymentToRemove: [],
        });
    }

    // Deal Structure
    toggleModalDealStructure(value = '') {
        const currentValue = isString(value) && !StringUtils.isEmpty(value) ? value : '';

        this.setState((prevState) => ({
            openDialogDealStructure: !prevState.openDialogDealStructure,
            showTypeModal: currentValue,
        }));
    }

    updateFees(feesData) {
        const { state: { dealStructure } } = this;
        const feeNames = Object.keys(feesData);
        const feeValues = Object.values(feesData);
        let totalFees = 0;

        feeValues.forEach((item) => {
            totalFees += item || 0;
        });

        let newDealStructure = update(dealStructure, {
            totalFees: { $set: totalFees },
        });

        feeNames.forEach((item, i) => {
            newDealStructure = update(newDealStructure, {
                [item]: { $set: feeValues[i] || 0 },
            });
        });

        this.setState({
            dealStructure: newDealStructure,
        }, () => {
            this.callDealPreviewDealStructure();
        });
    }

    fetchVendorList() {
        const filter = {
            vendorType: ['Financial Institution'],
        };

        this.graphqlClient
            .query(VendorQuery.GET_VENDOR_LIST, { filter })
            .then((response) => {
                const vendorList = [{ label: 'CASH', value: 'CASH' }];
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVendorList) {
                    data.getVendorList.forEach((item) => {
                        vendorList.push({
                            value: item.vendorName,
                            label: item.vendorName,
                        });
                    });

                    this.setState({
                        vendorList,
                    });
                }
            });
    }

    subscribeDealChanged() {
        const { props: { accountNumber } } = this;

        this.dealService.dealSubscribe(this.dealSubscription, accountNumber)
            .then((response) => {
                this.dealChangedSubscription = response;
            });
    }

    dealSubscription(response) {
        const { data } = response;

        if (data) {
            this.setData(data);
        }
    }

    unsubscribeDealChanged() {
        if (this.dealChangedSubscription) {
            this.dealChangedSubscription.unsubscribe();
        }
    }

    calculateDeal() {
        const record = this.getInputDealStructure();
        this.getDealPreview(record);
    }

    /**
     * This method takes care of calling getDealPreview by adding a 3 second timeout.
     * There are other scenarios that this calculations must be performed without a delay.
     */
    callDealPreview(data) {
        this.getDealPreview(data);
    }

    callDealPreviewDealStructure(field = '', value = null) {
        // Do not call preview when user is modifying payment amounts it will be an interface with a button to fire this calculations.
        if ((field === 'paymentOverride' && value === true) || field === 'paymentAmount') return;

        const record = this.getInputDealStructure();
        this.getDealPreview(record);
    }

    initBind() {
        this.onCancelProduct = this.onCancelProduct.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onSaveSalesTax = this.onSaveSalesTax.bind(this);
        this.onCancelSalesTax = this.onCancelSalesTax.bind(this);
        this.onChangeSalesTax = this.onChangeSalesTax.bind(this);
        this.getInputDealStructure = this.getInputDealStructure.bind(this);
        this.onChangeStructureValue = this.onChangeStructureValue.bind(this);
        this.getDealPreview = this.getDealPreview.bind(this);
        this.callDealPreview = debounce(this.callDealPreview.bind(this), 3000, { trailing: true });
        this.callDealPreviewDealStructure = debounce(this.callDealPreviewDealStructure.bind(this), 3000, { trailing: true });
        this.calculateDeal = this.calculateDeal.bind(this);
        // Deal structure
        this.updateFees = this.updateFees.bind(this);
        this.onEditDealStructure = this.onEditDealStructure.bind(this);
        this.onUpdateDealStructure = this.onUpdateDealStructure.bind(this);
        this.getTagAndTitleSettings = this.getTagAndTitleSettings.bind(this);
        this.setDeferredDownPayment = this.setDeferredDownPayment.bind(this);
        this.toggleModalDealStructure = this.toggleModalDealStructure.bind(this);
        this.onApplyTitleRegistration = this.onApplyTitleRegistration.bind(this);
        this.onApplyAmountFinanced = this.onApplyAmountFinanced.bind(this);
        this.onCancelEditDealStructure = this.onCancelEditDealStructure.bind(this);
        this.getSumOfDeferredDownPayment = this.getSumOfDeferredDownPayment.bind(this);
        // General
        this.resetDealInformation = this.resetDealInformation.bind(this);
        this.fetchVendorList = this.fetchVendorList.bind(this);
        this.subscribeDealChanged = this.subscribeDealChanged.bind(this);
        this.unsubscribeDealChanged = this.unsubscribeDealChanged.bind(this);
        this.dealSubscription = this.dealSubscription.bind(this);
        this.onChangeDateFirstPaymentDue = this.onChangeDateFirstPaymentDue.bind(this);
        this.onChangeDayFirstPaymentDue = this.onChangeDayFirstPaymentDue.bind(this);
        this.onChangeSoldDate = this.onChangeSoldDate.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                getServicesData={this.getServicesData}
                onChangeStructureValue={this.onChangeStructureValue}
                calculateDeal={this.calculateDeal}
                // Deal Structure
                updateFees={this.updateFees}
                onSaveSalesTax={this.onSaveSalesTax}
                onChangeSalesTax={this.onChangeSalesTax}
                onCancelSalesTax={this.onCancelSalesTax}
                onEditDealStructure={this.onEditDealStructure}
                onUpdateDealStructure={this.onUpdateDealStructure}
                setDeferredDownPayment={this.setDeferredDownPayment}
                onApplyTitleRegistration={this.onApplyTitleRegistration}
                toggleModalDealStructure={this.toggleModalDealStructure}
                onApplyAmountFinanced={this.onApplyAmountFinanced}
                onCancelEditDealStructure={this.onCancelEditDealStructure}
                fetchVendorList={this.fetchVendorList}
                subscribeDealChanged={this.subscribeDealChanged}
                unsubscribeDealChanged={this.unsubscribeDealChanged}
                onChangeDayFirstPaymentDue={this.onChangeDayFirstPaymentDue}
                onChangeDateFirstPaymentDue={this.onChangeDateFirstPaymentDue}
                onChangeSoldDate={this.onChangeSoldDate}
                callDealPreview={this.callDealPreview}
                resetDealInformation={this.resetDealInformation}
                // Product
                onCancelProduct={this.onCancelProduct}
            />
        );
    }
};

export default DealTabContainer;

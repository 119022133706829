import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { Col, Form } from 'react-bootstrap';
import DialogActions from 'components/widgets/modal/DialogActions';
import InputNumber from 'components/widgets/InputNumber';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    styleColLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignTextEnd: {
        textAlign: 'end',
    },
    labelStyle: {
        marginBottom: '2px !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        color: '#767676',
    },
    switchMargin: {
        marginLeft: '10px',
    },
    checkGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingLeft: 0,
    },
    checkLabel: {
        fontSize: '16px !important',
        fontWeight: '500',
        textAlign: 'right',
    },
    checkInput: {
        position: 'initial',
        marginLeft: '0.5rem',
    },
}));

const LeasePaymentDialog = ({
    toggleModal,
    leasePaymentAmount,
    leasePaymentTax,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            aria-labelledby="roll-balance-dialog-title"
            aria-describedby="roll-balance-dialog-description"
        >
            <DialogAppBar title="Payment" onClose={toggleModal} iconSize="sm" titleVariant="h4" />
            <DialogContent className={classes.dialogContent} dividers>
                <Form className="am-form">
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labelStyle}>Payment Amount:</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <InputNumber
                                disabled
                                value={leasePaymentAmount}
                                placeholder="0.00"
                                fixedDecimalScale
                                thousandSeparator
                                showCurrency
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labelStyle}>Sales Tax:</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <InputNumber
                                disabled
                                value={leasePaymentTax}
                                placeholder="0.00"
                                fixedDecimalScale
                                thousandSeparator
                                showCurrency
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Ok"
                onClickPrimary={toggleModal}
                hiddenSecondaryButton
            />
        </Dialog>
    );
};

LeasePaymentDialog.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    leasePaymentAmount: PropTypes.number,
    leasePaymentTax: PropTypes.number,
};

LeasePaymentDialog.defaultProps = {
    leasePaymentAmount: 0,
    leasePaymentTax: 0,
};

export default LeasePaymentDialog;

import React, { PureComponent } from 'react';

// Material UI
import {
    Grid,
    Typography,
    IconButton,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LabelValue from 'components/widgets/LabelValue';
import SearchIcon from '@material-ui/icons/Search';
import { BorderColorOutlined as EditIcon } from '@material-ui/icons';

const styles = (theme) => ({
    customer: {
        minHeight: '216px',
        '& > div > h6': {
            color: theme.palette.text.minsk,
        },
        '& > div > h6 + h6': {
            color: theme.palette.text.stormGray,
        },
    },
    title: {
        color: theme.palette.text.stormGray,
        padding: theme.spacing(1.4),
    },
    searchIcon: {
        color: theme.palette.text.infoDark,
    },
    boxHeader: {
        minHeight: 38,
    },
});

class CustomerInformation extends PureComponent {
    render() {
        const {
            props: {
                classes, customerName, email, cellPhone, homePhone, workPhone,
                title, onSearchCustomer, toggleEditCustomer,
            },
        } = this;

        return (
            <div className="box-shadow">
                <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                    <Typography variant="h5" className={classes.title}>{title}</Typography>
                    <div>
                        {onSearchCustomer
                        && (
                            <IconButton
                                size="small"
                                onClick={onSearchCustomer}
                            >
                                <SearchIcon className={classes.searchIcon} />
                            </IconButton>
                        )}
                        {toggleEditCustomer
                        && (
                            <Button
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={toggleEditCustomer}
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                </div>
                <Grid container spacing={0}>
                    <Grid
                        item
                        className={classes.customer}
                        xs={12}
                    >
                        <LabelValue
                            label="Customer"
                            value={customerName}
                        />
                        <LabelValue
                            label="Email"
                            value={email || ''}
                        />
                        <LabelValue
                            label="Cell Phone"
                            value={cellPhone || ''}
                        />
                        <LabelValue
                            label="Home Phone"
                            value={homePhone || ''}
                        />
                        <LabelValue
                            label="Work Phone"
                            value={workPhone || ''}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CustomerInformation.propTypes = {
    customerName: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
    homePhone: PropTypes.string,
    workPhone: PropTypes.string,
    title: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onSearchCustomer: PropTypes.func,
    toggleEditCustomer: PropTypes.func,
};

CustomerInformation.defaultProps = {
    customerName: '',
    email: '',
    cellPhone: '',
    homePhone: '',
    workPhone: '',
    title: '',
    onSearchCustomer: null,
    toggleEditCustomer: null,
};

export default withStyles(styles)(CustomerInformation);

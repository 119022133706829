import React, { Component } from 'react';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import {
    Box, Typography, Checkbox, FormControlLabel,
} from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import moment from 'moment';
import { isFinite } from 'lodash';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import TimePicker from 'rc-time-picker';
import update from 'immutability-helper';
import 'rc-time-picker/assets/index.css';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import Loading from 'components/widgets/Loading';
import Assignee from 'components/widgets/Assignee';
import SwitchButton from 'components/widgets/SwitchButton';
import ActivitiesStyles from 'styles/modules/ActivitiesStyles';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import ActivityContent from 'components/modules/crm/activities/form/ActivityContent';
import { TaskStatusInput, TaskPrioritiesInput, TaskStatus } from 'utils/enum/ActivitiesEnum';

import { ActivityTaskSchema, ActivityTodoTaskSchema } from 'utils/schema/lead/Activity';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { reminderOption } from 'components/modules/crm/activities/form/ActivityHelper';

// GraphQL
import KeyStore from 'utils/KeyStore';
import GraphqlClient from 'services/apollo/GraphQLClient';
import ActivityQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import ActivityMutation from 'services/graphQL/mutate/ActivityMutation';
import OpportunitySection from 'components/modules/crm/activities/form/OpportunitySection';

const styles = (theme) => ActivitiesStyles.activitiesForm(theme);

class TaskForm extends Component {
    constructor(props) {
        super(props);

        this.selectRef = React.createRef();
        this.graphqlClient = new GraphqlClient();
        this.state = {
            data: {
                subject: '',
                assignee: new KeyStore().getUserId(),
                note: '',
                reminderOn: null,
                scheduleDuration: null,
                schedule: moment().toDate(),
                status: TaskStatus.TO_DO,
                leadCode: null,
            },
            prospect: {},
            opportunity: {},
        };

        this.initBind();
    }

    componentDidMount() {
        const { isEditing, crmId, activityId } = this.props;
        if (isEditing) {
            this.setState({
                loading: true,
            });

            this.graphqlClient.query(ActivityQuery.GET_ACTIVITY_TASK_BY_ID, { crmId, activityId })
                .then((response) => {
                    const { data, graphQLErrors } = response;
                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        return;
                    }

                    if (data?.getActivityTaskById) {
                        const { getActivityTaskById } = data;
                        const { opportunity = {}, reminder = {} } = getActivityTaskById;
                        const reminderOn = reminder && DateUtils.isValid(reminder.reminderOn)
                            ? DateUtils.diff(getActivityTaskById.realizationDate, reminder.reminderOn, 'minutes') / 60 : 0;

                        // TODO: Check the enum status to format it before saving the information.
                        this.setState((prevState) => ({
                            data: update(prevState.data, {
                                note: { $set: getActivityTaskById.note },
                                status: { $set: getActivityTaskById.task?.status?.toUpperCase() },
                                reminderOn: { $set: reminderOn },
                                subject: { $set: getActivityTaskById.subject },
                                assignee: { $set: getActivityTaskById.assignee },
                                schedule: { $set: DateUtils.toLocal(getActivityTaskById.realizationDate)?.toDate() },
                                scheduleDuration: { $set: DateUtils.toLocal(getActivityTaskById.realizationDate) },
                            }),
                            opportunity: update(prevState.opportunity, {
                                leadCode: { $set: opportunity.leadCode },
                                crmId: { $set: opportunity.crmId },
                                allowTextCellPhone: { $set: opportunity.allowTextCellPhone },
                            }),
                            prospect: opportunity.prospect,
                        }));
                    }
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    onChangeSwitch(value) {
        this.setState((prevState) => ({
            data: update(prevState.data, {
                status: { $set: value ? TaskStatus.TO_DO : TaskStatus.COMPLETED },
            }),
        }));
    }

    onChangeInformation(value = '', field) {
        this.setState((prevState) => update(prevState, {
            data: { [field]: { $set: value } },
        }));
    }

    onSaveActivity() {
        const { isEditing, crmId } = this.props;
        const {
            state: {
                data: {
                    subject, schedule, note, scheduleDuration,
                    assignee, status,
                },
                data,
            },
        } = this;
        const isTodo = status === TaskStatus.TO_DO;
        const currentSchedule = DateUtils.formatUTC(schedule);
        const input = {
            processRecordId: crmId,
            subject,
            assignee,
            note,
        };

        const currentScheduleDuration = scheduleDuration ? moment(currentSchedule).set({
            hour: scheduleDuration.hour(), minute: scheduleDuration.minute(), second: 0, millisecond: 0,
        }) : currentSchedule;

        input.realizationDate = DateUtils.formatUTC(currentScheduleDuration);

        // TODO: verify if normal is the default priority value
        if (isTodo) {
            input.task = { status: TaskStatusInput.TODO, priority: TaskPrioritiesInput.NORMAL };
            input.reminderOn = isFinite(data.reminderOn) && data.reminderOn !== 0
                ? DateUtils.subtractAndFormatUTC(currentScheduleDuration, data.reminderOn, 'hours') : null;
        } else {
            input.task = {
                status: TaskStatusInput.COMPLETED,
                priority: TaskPrioritiesInput.NORMAL,
            };
        }

        if (isEditing) {
            this.onUpdate(input);
        } else {
            this.onSave(input);
        }
    }

    onSave(input) {
        const { onClose } = this.props;
        this.setState({ isSaving: true });
        this.graphqlClient
            .mutate(ActivityMutation.CREATE_TASK_ACTIVITY, { input })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.createTaskActivity) {
                    onClose();
                }
            })
            .finally(() => {
                this.setState({ isSaving: false });
            });
    }

    onUpdate(input) {
        const { onClose, activityId } = this.props;
        this.setState({ isSaving: true });
        this.graphqlClient
            .mutate(ActivityMutation.UPDATE_TASK_ACTIVITY, { input, activityId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.updateTaskActivity) {
                    onClose();
                }
            })
            .finally(() => {
                this.setState({ isSaving: false });
            });
    }

    onChangeCheckbox(value) {
        this.setState((prevState) => ({
            data: update(prevState.data, {
                status: { $set: value ? TaskStatus.COMPLETED : TaskStatus.TO_DO },
            }),
        }));
    }

    initBind() {
        this.onSaveActivity = this.onSaveActivity.bind(this);
        this.onChangeSwitch = this.onChangeSwitch.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onChangeInformation = this.onChangeInformation.bind(this);
    }

    render() {
        const {
            data, loading, opportunity, prospect,
            isSaving,
        } = this.state;
        const {
            classes, onClose, isEditing, lotId,
        } = this.props;
        let currentTitle = '';
        const isTodo = TaskStatus.TO_DO === data.status;
        const isCompleted = TaskStatus.COMPLETED === data.status;
        if (!isEditing) currentTitle = isTodo ? 'Schedule' : 'Log';
        const taskSchema = isTodo ? ActivityTodoTaskSchema : ActivityTaskSchema;
        const isValidData = isValidSchema(taskSchema, { ...data, scheduleDuration: data.scheduleDuration?.toDate() });
        const { isValid, errors } = isValidData;
        const note = data.note || '';

        return (
            <ActivityContent
                title={`${currentTitle} Task`}
                onClose={onClose}
                disabledSaveButton={!isValid || isSaving}
                onSave={this.onSaveActivity}
            >
                <Form>
                    {isEditing && (
                        <OpportunitySection
                            prospect={prospect}
                            opportunity={opportunity}
                        />
                    )}
                    <Form.Group className="d-flex-justify-start-align-center">
                        {isEditing && (
                            <FormControlLabel
                                label="Completed"
                                className={classes.formControlLabel}
                                control={(
                                    <Checkbox
                                        checked={isCompleted}
                                        onChange={(e) => this.onChangeCheckbox(e.target.checked)}
                                        name="completed"
                                    />
                                )}
                            />
                        )}
                    </Form.Group>
                    <Box
                        display="flex"
                        alignItems="center"
                        marginBottom={2}
                    >
                        <Typography variant="subtitle1">Assignee:</Typography>
                        <Assignee
                            innerRef={this.selectRef}
                            value={data.assignee}
                            lotId={lotId}
                            onChange={(e) => {
                                this.selectRef.current.select.blur();
                                this.onChangeInformation(e?.value, 'assignee');
                            }}
                            className={clsx({ 'invalid-field': isValidField(errors, 'assignee') })}
                        />
                    </Box>
                    {!isEditing && (
                        <SwitchButton
                            className={classes.switchButton}
                            firstLabel="Schedule"
                            secondLabel="Log"
                            value={isTodo}
                            onChange={this.onChangeSwitch}
                        />
                    )}
                    {!isEditing && isCompleted && (
                        <Form.Group className="full-width-date-picker">
                            <Form.Label>Completed On:</Form.Label>
                            <DatePicker
                                className={clsx('form-control', { 'invalid-field': isValidField(errors, 'schedule') })}
                                selected={data.schedule}
                                popperContainer={CalendarContainer}
                                onChange={(date) => this.onChangeInformation(date, 'schedule')}
                            />
                        </Form.Group>
                    )}
                    {isEditing && isCompleted && (
                        <>
                            <Form.Label>Schedule On:</Form.Label>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <DatePicker
                                        className={clsx('form-control', { 'invalid-field': isValidField(errors, 'schedule') })}
                                        selected={data.schedule}
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => this.onChangeInformation(date, 'schedule')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <TimePicker
                                        use12Hours
                                        minuteStep={15}
                                        showSecond={false}
                                        value={data.scheduleDuration}
                                        placeholder="hh:mm"
                                        onChange={(e) => this.onChangeInformation(e, 'scheduleDuration')}
                                        className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'scheduleDuration') })}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Reminder</Form.Label>
                                <Select
                                    disabled
                                    name="reminderOn"
                                    maxMenuHeight={100}
                                    options={reminderOption}
                                    value={data.reminderOn}
                                />
                            </Form.Group>
                        </>
                    )}
                    {!isCompleted && (
                        <>
                            <Form.Label>Schedule On:</Form.Label>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <DatePicker
                                        className={clsx('form-control', { 'invalid-field': isValidField(errors, 'schedule') })}
                                        selected={data.schedule}
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => this.onChangeInformation(date, 'schedule')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <TimePicker
                                        use12Hours
                                        minuteStep={15}
                                        showSecond={false}
                                        value={data.scheduleDuration}
                                        placeholder="hh:mm"
                                        onChange={(e) => this.onChangeInformation(e, 'scheduleDuration')}
                                        className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'scheduleDuration') })}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Reminder</Form.Label>
                                <Select
                                    name="reminderOn"
                                    maxMenuHeight={100}
                                    options={reminderOption}
                                    value={data.reminderOn}
                                    disabled={data.scheduleDuration === null}
                                    onChange={(_, value) => this.onChangeInformation(value, 'reminderOn')}
                                />
                            </Form.Group>
                        </>
                    )}
                    <Form.Group id="notegroup">
                        <Box
                            display="flex"
                            justifyContent="end"
                        >
                            <Form.Label className={classes.noteLabel}>{`${note.length}/500`}</Form.Label>
                        </Box>
                        <Form.Control
                            as="textarea"
                            placeholder="Notes"
                            rows="4"
                            maxLength={500}
                            onChange={({ target }) => this.onChangeInformation(target.value, 'note')}
                            value={note}
                        />
                    </Form.Group>
                </Form>
                {loading && <Loading className={classes.loading} />}
            </ActivityContent>
        );
    }
}

TaskForm.propTypes = {
    lotId: PropTypes.number,
    isEditing: PropTypes.bool,
    activityId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TaskForm.defaultProps = {
    isEditing: false,
    activityId: null,
    lotId: null,
};

export default withStyles(styles)(TaskForm);

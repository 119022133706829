import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import { ListOutlinedIcon } from 'components/icons';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import SidemenuSettings from 'components/modules/settings/personalization/SidemenuSettings';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['side-menu'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const items = [
    {
        index: 0,
        text: 'Side Menu',
        icon: <ListOutlinedIcon />,
        component: <SidemenuSettings />,
    },
];

const Preferences = () => {
    const classes = useStyles();
    const history = useHistory();
    const { subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.PREFERENCES}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {items.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            <div className={classes.content}>
                {items.map((item) => (
                    activeIndex === item.index && <div key={item.index}>{item.component}</div>
                ))}
            </div>
        </Container>
    );
};

export default Preferences;

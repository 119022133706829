import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Dialog, DialogContent,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import GpsHelper from 'utils/GpsHelper';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        borderBottom: 'initial',
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    mapWrapper: {
        padding: 0,
        height: '500px',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 0,
    },
}));

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
const GOOGLE_MAPS_URL = 'https://www.google.com/maps/embed/v1/place';
const GpsLocation = ({
    gpsCompany,
    lastExecutedCommand,
    toggleLocationDialog,
}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        location: null,
    });

    const {
        body,
    } = lastExecutedCommand;

    const {
        location,
    } = state;

    useEffect(() => {
        if (body) {
            const parsedData = JSON.parse(body ?? '{}');
            const { data, errors } = parsedData;

            if (ArrayUtils.isNotEmpty(errors)) {
                const { code, message } = errors[0];
                const errorMessage = GpsHelper.getErrorMessageByCode(code, message, gpsCompany);

                ModalUtils.errorMessage(null, errorMessage);
                toggleLocationDialog();
            } else {
                const { location: locationData } = data ?? {};
                if (!locationData) {
                    ModalUtils.errorMessage(null, 'No location data available');
                    toggleLocationDialog();

                    return;
                }

                setState((previousState) => ({
                    ...previousState,
                    location: locationData,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body]);

    const { address, lat, lng } = location ?? {};
    const params = new URLSearchParams({
        key: MAPS_API_KEY,
        q: address,
        center: `${lat},${lng}`,
        zoom: 12,
        maptype: 'satellite',
    });
    return (
        <Dialog
            open
            maxWidth="md"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="GPS Location"
                onClose={toggleLocationDialog}
                toolbarSize="md"
            />
            <DialogContent className={classes.mapWrapper}>
                {location && (
                    <iframe
                        className={classes.iframe}
                        title="GPS Location"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`${GOOGLE_MAPS_URL}?${params.toString()}`}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

GpsLocation.propTypes = {
    gpsCompany: PropTypes.string,
    lastExecutedCommand: PropTypes.object,
    toggleLocationDialog: PropTypes.func.isRequired,
};

GpsLocation.defaultProps = {
    gpsCompany: '',
    lastExecutedCommand: {},
};

export default GpsLocation;

import React, { Component } from 'react';

// Internal UI
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import UnderwritingQuery from 'services/graphQL/query/setting/UnderwritingQuery';
import UnderwritingMutation from 'services/graphQL/mutate/setting/UnderwritingMutation';
import PropTypes from 'prop-types';

import { cloneDeep } from 'lodash';

const REQUEST_ERROR_MESSAGE = 'Something went wrong';

const NeoDialogContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        record: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        onReload: PropTypes.func.isRequired,
        isEditing: PropTypes.bool.isRequired,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        record: null,
    };

    constructor(props) {
        super(props);

        this.graphQLClient = new GraphQLClient();
        this.tokenBackup = '********';

        this.state = {
            loading: false,
            saving: false,
            validatingToken: false,
            isValidToken: false,
            statusesToFetch: [],
            loadingDetail: false,
            companyApiIntegrationId: null,
            active: false,
            token: '',
            isTokenModified: false,
        };

        this.initBinds();
    }

    componentDidMount() {
        const {
            props: {
                isEditing,
                record: { id },
            },
        } = this;

        if (isEditing) {
            this.getIntegrationDetail(id);
        } else {
            this.getStatusesToFetch();
        }
    }

    onSave() {
        if (!this.checkSelectedStatuses()) {
            this.showValidationStatusesMessage();
            return;
        }

        const {
            state: { active, token },
            props,
            props: { record },
        } = this;
        const activeStatuses = this.getActiveStatuses();

        const data = {
            apiIntegrationVendorId: record ? record.id : null,
            active,
            token,
            statuses: activeStatuses,
        };

        this.setState({ saving: true });

        this.graphQLClient
            .mutate(UnderwritingMutation.CREATE_NEO_INTEGRATION, {
                input: data,
            })
            .then((response) => {
                const { graphQLErrors, data: localData } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(
                        graphQLErrors,
                        REQUEST_ERROR_MESSAGE,
                    );
                } else {
                    const { createNeoIntegration } = localData;

                    if (createNeoIntegration) {
                        ModalUtils.successMessage(null, 'Integration created successfully');

                        if (props.onClose) {
                            props.onClose();
                        }
                        if (props.onReload) {
                            props.onReload();
                        }
                    } else {
                        ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
                    }
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    onUpdate() {
        if (!this.checkSelectedStatuses()) {
            this.showValidationStatusesMessage();
            return;
        }

        const {
            state: { active, token, companyApiIntegrationId },
            props,
            props: { record },
        } = this;
        const activeStatuses = this.getActiveStatuses();
        const newToken = token === this.tokenBackup ? null : token;

        const data = {
            companyApiIntegrationId,
            apiIntegrationVendorId: record ? record.id : null,
            active,
            token: newToken,
            statuses: activeStatuses,
        };

        this.setState({ saving: true });

        this.graphQLClient
            .mutate(UnderwritingMutation.UPDATE_NEO_INTEGRATION, {
                input: data,
            })
            .then((response) => {
                const { graphQLErrors, data: localData } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(
                        graphQLErrors,
                        REQUEST_ERROR_MESSAGE,
                    );
                } else {
                    const { updateNeoIntegraton } = localData;

                    if (updateNeoIntegraton) {
                        ModalUtils.successMessage(null, 'Integration updated successfully');

                        if (props.onClose) {
                            props.onClose();
                        }
                        if (props.onReload) {
                            props.onReload();
                        }
                    } else {
                        ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
                    }
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    onChange(value, fieldName) {
        const isTokenChanged = fieldName === 'token';

        this.setState(
            {
                [fieldName]: value,
                isValidToken: !isTokenChanged,
            },
            () => {
                if (isTokenChanged) this.setValidToken();
            },
        );
    }

    onChangeStatuses(value, index) {
        const { state } = this;
        const statusesToFetch = cloneDeep(state.statusesToFetch);

        statusesToFetch[index].active = value;

        this.setState({ statusesToFetch });
    }

    onValidateToken(value) {
        const {
            props: { isEditing },
        } = this;

        const token = isEditing && value === this.tokenBackup ? null : value;
        const invalidCredentialsMessages = 'Your credentials are not valid.';

        this.setState({ validatingToken: true });

        this.graphQLClient
            .mutate(UnderwritingMutation.VALIDATE_NEO_INTEGRATION, { token })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(
                        graphQLErrors,
                        REQUEST_ERROR_MESSAGE,
                    );
                } else {
                    const { validateNeoIntegration } = data;

                    if (validateNeoIntegration) {
                        ModalUtils.successMessage(null, 'Your credentials are valid.');

                        this.setState({
                            isValidToken: true,
                        });
                    } else {
                        ModalUtils.errorMessage(null, invalidCredentialsMessages);

                        this.setState({
                            isValidToken: false,
                        });
                    }
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
            })
            .finally(() => {
                this.setState({ validatingToken: false });
            });
    }

    getStatusesToFetch() {
        this.setState({ loading: true });

        this.graphQLClient
            .query(UnderwritingQuery.GET_STATUSES_TO_FETCH)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(
                        graphQLErrors,
                        REQUEST_ERROR_MESSAGE,
                    );
                } else {
                    const { getNEOStatuses } = data;

                    if (getNEOStatuses) {
                        getNEOStatuses.map((status) => ({
                            ...status,
                            active: false,
                        }));

                        this.setState({
                            statusesToFetch: getNEOStatuses,
                        });
                    }
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getIntegrationDetail(id) {
        this.setState({ loading: true });

        this.graphQLClient
            .query(UnderwritingQuery.GET_NEO_INTEGRATION_DETAIL, {
                apiIntegrationVendorId: id,
            })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(
                        graphQLErrors,
                        REQUEST_ERROR_MESSAGE,
                    );
                } else {
                    const { neoIntegrationDetail, getNEOStatuses } = data;

                    if (neoIntegrationDetail && getNEOStatuses) {
                        const activeStatuses = neoIntegrationDetail.statuses || [];

                        getNEOStatuses.map((status) => ({
                            ...status,
                            active: activeStatuses.includes(status.statusId),
                        }));

                        this.setState({
                            companyApiIntegrationId:
                                    neoIntegrationDetail.companyApiIntegrationId,
                            isValidToken: true,
                            active: neoIntegrationDetail.active,
                            statusesToFetch: getNEOStatuses,
                            token: this.tokenBackup,
                        });
                    }
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, REQUEST_ERROR_MESSAGE);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    setValidToken() {
        const { state, props } = this;
        const { token, isTokenModified } = state;
        const { isEditing } = props;

        if (isEditing) {
            if (!isTokenModified && this.tokenBackup !== token) {
                this.setState({
                    isValidToken: false,
                    token: '',
                    isTokenModified: true,
                });
            }
        }
    }

    getActiveStatuses() {
        const {
            state: { statusesToFetch },
        } = this;
        const statusIds = [];

        statusesToFetch.forEach((status) => {
            if (status.active) {
                statusIds.push(status.statusId);
            }
        });

        return statusIds;
    }

    checkSelectedStatuses() {
        const {
            state: { statusesToFetch },
        } = this;
        let isChecked = false;

        if (statusesToFetch.some((status) => status.active)) isChecked = true;

        return isChecked;
    }

    showValidationStatusesMessage() {
        const errorMessage = 'At least one active status is required';

        ModalUtils.errorMessage(null, errorMessage);
    }

    initBinds() {
        this.onSave = this.onSave.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onValidateToken = this.onValidateToken.bind(this);
        this.onChangeStatuses = this.onChangeStatuses.bind(this);
        this.getStatusesToFetch = this.getStatusesToFetch.bind(this);
        this.getIntegrationDetail = this.getIntegrationDetail.bind(this);
    }

    render() {
        const { props, state } = this;
        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onUpdate={this.onUpdate}
                onChange={this.onChange}
                onValidateToken={this.onValidateToken}
                onChangeStatuses={this.onChangeStatuses}
                getStatusesToFetch={this.getStatusesToFetch}
                getIntegrationDetail={this.getIntegrationDetail}
            />
        );
    }
};

export default NeoDialogContainer;

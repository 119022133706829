import React from 'react';
import Messages from 'components/modules/home/list/Messages';
import IFrame from 'components/modules/home/create/IFrame';
import Image from 'components/modules/home/create/Image';

export default {
    getComponentsAvailable() {
        return [
            {
                componentType: 'Messages',
                minimumWidth: 4,
                minimumHeight: 4,
                isDraggable: true,
                isResizable: true,
                isBounded: true,
                component: (component, actionRemove, actionChangeTitle, permission) => (
                    <Messages
                        component={component}
                        actionRemove={actionRemove}
                        actionChangeTitle={actionChangeTitle}
                        permission={permission}
                    />
                ),
            },
            {
                componentType: 'IFrame',
                minimumWidth: 2,
                minimumHeight: 2,
                isDraggable: true,
                isResizable: true,
                isBounded: true,
                component: (component, actionRemove, _, permission) => (
                    <IFrame
                        component={component}
                        actionRemove={actionRemove}
                        permission={permission}
                    />
                ),
            },
            {
                componentType: 'Image',
                minimumWidth: 2,
                minimumHeight: 2,
                isDraggable: true,
                isResizable: true,
                isBounded: true,
                component: (component, actionRemove, _, permission) => (
                    <Image
                        component={component}
                        actionRemove={actionRemove}
                        permission={permission}
                    />
                ),
            },
        ];
    },
};

const ConfirmDialogActions = Object.freeze({
    DELETE_COMPONENT: 'Delete Component',
});

const MessageContentType = Object.freeze({
    PLAIN_TEXT: 'Plain Text',
    HTML: 'HTML',
    PDF: 'PDF',
});

const ComponentType = Object.freeze({
    MESSAGE: 'Message',
    IFRAME: 'IFrame',
    IMAGE: 'Image',
});

export { ConfirmDialogActions, MessageContentType, ComponentType };

import React from 'react';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import { makeStyles } from '@material-ui/core';
import PayrollBaseList from 'components/modules/payroll/read/PayrollBaseList';
import HoursWorkedTablesContainer from 'components/modules/payroll/read/hoursWorked/HoursWorkedTablesContainer';

const useStyles = makeStyles(() => ({
    defaultText: {
        alignSelf: 'center',
        textAlign: 'center',
    },
}));

const HoursWorkedList = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const savedSizes = keyStore.getHoursWorkedSplitSize() || [70, 30];

    const onResizingSplit = (size) => {
        keyStore.setHoursWorkedSplitSize(size);
    };

    const getRightSideComponent = ({ employeeId, payPeriod, payType }) => (
        <div>
            {(employeeId <= 0 || !payPeriod) && (
                <div className={classes.defaultText}>No display Available</div>
            )}
            {employeeId > 0 && Boolean(payPeriod) && (
                <HoursWorkedTablesContainer
                    payType={payType}
                    payPeriod={payPeriod}
                    employeeId={employeeId}
                />
            )}
        </div>
    );

    getRightSideComponent.propTypes = {
        payType: PropTypes.string.isRequired,
        payPeriod: PropTypes.string.isRequired,
        employeeId: PropTypes.number.isRequired,
    };

    return (
        <PayrollBaseList
            splitSize={savedSizes}
            onResizingSplit={onResizingSplit}
            getRightSideComponent={getRightSideComponent}
        />
    );
};

export default HoursWorkedList;

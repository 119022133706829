import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Select from 'components/widgets/Select';
import useLostReasons from 'components/hook/crm/useLostReasons';
import DialogActions from 'components/widgets/modal/DialogActions';

// Material UI
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

// Bootstrap
import { Form, Row, Col } from 'react-bootstrap';

// Apollo Client
import { useMutation } from '@apollo/client';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import If from 'components/widgets/conditional/If';
import Loading from 'components/widgets/Loading';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
}));

const OpportunityStatusForm = ({
    onClose, crmId, action,
    refetchAutomatedResponseStatus,
}) => {
    const { lostReasons, loading } = useLostReasons();
    const classes = useStyles();
    const [lostReasonId, setLostReason] = useState(null);
    const [setOpportunityStatus] = useMutation(OpportunityMutation.SET_OPPORTUNITY_STATUS);

    const onSave = async () => {
        try {
            const response = await setOpportunityStatus({ variables: { crmId, status: action, lostReasonId } });

            if (response.data?.setOpportunityStatus) {
                ModalUtils.successMessage(null, 'Successfully saved!');

                refetchAutomatedResponseStatus();
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'opportunity'));
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    return (
        <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <If condition={loading}>
                <Loading className="loading-full-content" />
            </If>
            <DialogAppBar
                title="Mark as Lost"
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col md>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lost Reason:</Form.Label>
                                    <Select
                                        className="select-bootstrap"
                                        name="lostReasonId"
                                        placeholder="Select"
                                        size="sm"
                                        options={lostReasons}
                                        value={lostReasonId || ''}
                                        onChange={(field, value) => setLostReason(value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                disablePrimaryButton={StringUtils.isEmpty(lostReasonId)}
                onClickSecondary={onClose}
                variant="contained"
            />
        </Dialog>
    );
};

OpportunityStatusForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    refetchAutomatedResponseStatus: PropTypes.func,
};

OpportunityStatusForm.defaultProps = {
    refetchAutomatedResponseStatus: () => null,
};

export default OpportunityStatusForm;

export const ProductTypes = Object.freeze({
    WARRANTY: 'Warranty',
    GAP: 'GAP',
    PRODUCT: 'Product',
});

export default class ProductSettingUtils {
    static getProductTypes() {
        return Object.keys(ProductTypes).map((key) => {
            const product = ProductTypes[key];
            return { value: product, label: product };
        });
    }

    static getVendorOptions(vendorList, source) {
        const filtered = vendorList.filter((v) => v.provider === source);
        return Object.keys(filtered).map((key) => {
            const vendor = filtered[key];
            return { value: vendor.vendorId, label: vendor.vendorName };
        });
    }

    static getTerms() {
        const terms = [3, 6, 12, 18, 24, 36, 39, 42, 48, 60, 72, 84, 96, 120];
        return terms.map((t) => ({ value: t, label: t }));
    }
}

import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, Grid, Chip, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import ModalUtils from 'utils/ModalUtils';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import NumberUtils from 'lib/NumberUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import {
    AddIcon,
    BlockOutlinedIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon, EditOutlinedIcon, FilterListOutlinedIcon, LocalAtmOutlinedIcon,
    MoneyOffOutlinedIcon, PrintOutlinedIcon, VisibilityOutlinedIcon,
} from 'components/icons';
import {
    AccountsPayableType, GeneralAction, InvoicePaidType, InvoiceStatus,
} from 'utils/enum/AccountingEnum';
import AccountPayableQuery from 'services/graphQL/query/accounting/AccountPayableQuery';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import AccountPayableMapper from 'services/mapData/AccountPayableMapper';
import AccountPayableMutation from 'services/graphQL/mutate/accounting/AccountPayableMutation';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import { Form, Col } from 'react-bootstrap';
import InvoiceCreate from 'components/modules/accounting/accountsPayable/create/invoice/InvoiceCreate';
import CheckCreate from 'components/modules/accounting/banking/create/check/CheckCreate';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import Badge from '@material-ui/core/Badge';
import Split from 'react-split';
import BankingList from 'components/modules/accounting/banking/list/BankingList';
import printJS from 'print-js';
import VendorQuery from 'services/graphQL/query/VendorQuery';

import LabelValue from 'components/widgets/LabelValue';
import If from 'components/widgets/conditional/If';
import AccountingLockDate from 'components/widgets/accounting/AccountingLockDate';
import InvoiceListFilters from 'components/modules/accounting/accountsPayable/create/InvoiceListFilters';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyle = makeStyles((theme) => ({
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
    highlightText: {
        color: theme.palette.text.red,
    },
    paddingTop10: {
        paddingBottom: '10px',
    },
}));

const ACTION_TYPES = {
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_INITIAL_STATE: 'setInitialState',
    SET_SELECTED_ROW: 'setSelectedRow',
    SET_STATE_VALUES: 'setStateValues',
    SET_TABLE_APPROVED: 'setTableApproved',
    SET_ON_CLOSE_CHECK_OR_INVOICE: 'setOnCloseCheckOrDeposit',
    SET_APPROVE_ALL: 'setApproveAll',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            dataFromSubscription: action.dataFromSubscription,
            isSearching: false,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            params: {
                ...state.params,
                ...action.value,
            },
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_ON_CLOSE_CHECK_OR_INVOICE: {
        const newRecords = state.table.records.map((item) => ({
            ...item,
            isChecked: false,
        }));

        return {
            ...state,
            ...action.value,
            table: {
                ...state.table,
                records: newRecords,
            },
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        if (state.params.payType !== InvoicePaidType.DUE) {
            return state;
        }

        const newRecords = state.table.records.map((item) => ({
            ...item,
            isChecked: !action.value,
        }));

        return {
            ...state,
            table: {
                ...state.table,
                records: newRecords,
            },
        };
    }
    case ACTION_TYPES.SET_TABLE_APPROVED: {
        const newRecords = state.table.records.map((item) => ({
            ...item,
            isChecked: item.invoiceId === action.value.invoiceId ? !item.isChecked : item.isChecked,
        }));

        return {
            ...state,
            table: {
                ...state.table,
                records: newRecords,
            },
        };
    }
    default: return state;
    }
};

const InvoiceList = (props) => {
    const keyStore = new KeyStore();
    const INVOICE_READ = keyStore.hasPermission(Permission.INVOICE_READ);
    const INVOICE_WRITE = keyStore.hasPermission(Permission.INVOICE_WRITE);
    const INVOICE_VOID = keyStore.hasPermission(Permission.INVOICE_VOID);
    const INVOICE_PAYMENT = keyStore.hasPermission(Permission.INVOICE_PAYMENT);

    const {
        vendorSelectedId, vendorSelectedName, vendorSelectedAddress,
    } = props;

    const initState = {
        params: {
            search: '',
            payType: InvoicePaidType.DUE,
            init: 0,
            limit: 15,
            ignoreLimit: true,
            vendorId: null,
            vendorName: '',
            useDateRange: true,
            fromDate: new Date(DateUtils.subtractAndFormatUTC(new Date(), 3, 'months')),
            toDate: new Date(),
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: {
            invoiceId: null,
            invoiceNumber: '',
            vendorId: null,
            vendorName: '',
        },
        openCheck: false,
        openInvoice: false,
        invoiceReferenceId: null,
        creatingCreditForInvoice: false,
        invoiceSelectedId: 0,
        billData: null,
        showLockedDate: false,
        lockedDate: null,
        idToAction: 0,
        actionType: '',
        dataFromSubscription: null,
        openReportFilter: false,
        paidToList: [],
        isSearching: false,
        commentToVoid: '',
        showForceBecauseIsSold: false,
        alreadyForceVoidDate: null,
    };

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow, openCheck, openInvoice, creatingCreditForInvoice,
        invoiceReferenceId, invoiceSelectedId, billData, showLockedDate, idToAction,
        dataFromSubscription, actionType, openReportFilter, paidToList, isSearching,
        lockedDate, commentToVoid, showForceBecauseIsSold, alreadyForceVoidDate,
    } = state;

    const [getData, { loading }] = useLazyQuery(AccountPayableQuery.GET_INVOICE_LIST, {
        onCompleted: (res) => {
            if (res.getInvoiceList) {
                const { getInvoiceList: { data, totalCount } } = res;

                const mappedRecords = AccountPayableMapper.mapInvoiceList(data);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: mappedRecords,
                        totalCount,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { loading: loadingSuscription, data: dataSubscription } = useSubscription(AccountingSubscription.INVOICE_CHANGED);

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                idToAction: 0,
                showLockedDate: false,
                billData: null,
                commentToVoid: '',
                lockedDate: null,
                alreadyForceVoidDate: null,
                showForceBecauseIsSold: false,
            },
        });
    };

    const [voidInvoice, { loading: voiding }] = useMutation(AccountPayableMutation.VOID_INVOICE, {
        onCompleted: (mutationData) => {
            const { success, isLockedDate, lockedDate: currentLockDate } = mutationData?.voidInvoice;

            if (success) {
                ModalUtils.successMessage(null, 'Successfully void!');

                onCloseActionDialog();
            }

            if (isLockedDate) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showLockedDate: isLockedDate,
                        lockedDate: currentLockDate,
                    },
                });
                return;
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idToAction: 0,
                    actionType: '',
                    showLockedDate: false,
                    lockedDate: null,
                    alreadyForceVoidDate: null,
                    commentToVoid: '',
                },
            });
        },
        onError: (errorMessage) => {
            const errorIncludeSold = String(errorMessage).includes('sold vehicles');
            if (errorIncludeSold) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showForceBecauseIsSold: true,
                    },
                });
            } else ModalUtils.errorMessage([errorMessage]);

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idToAction: errorIncludeSold ? idToAction : 0,
                    actionType: '',
                    showLockedDate: false,
                    lockedDate: null,
                    alreadyForceVoidDate: errorIncludeSold ? alreadyForceVoidDate : null,
                    commentToVoid: errorIncludeSold ? commentToVoid : '',
                },
            });
        },
    });

    const execGetData = () => {
        getData({
            variables: {
                paginate: {
                    init: params.init,
                    limit: params.limit,
                    ignoreLimit: params.ignoreLimit,
                },
                filter: {
                    vendorId: params.vendorId,
                    payType: params.payType,
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                    search: params.search,
                    useDateRange: params.payType === InvoicePaidType.DUE ? false : params.useDateRange,
                },
                sort: [{
                    fieldName: 'dueDate',
                    dir: DataSort.ASC,
                }],
            },
        });
    };

    const [getVendorList] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            sort: {
                field: 'vendorName',
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const vendorPaidToList = res.getVendorList.map((item) => ({
                    paidToId: item.vendorId,
                    paidTo: item.vendorName,
                    address: item.vendorAddress,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        paidToList: vendorPaidToList,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onFilterSearch = (parameters) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openReportFilter: false,
                table: {
                    records: [],
                    totalCount: 0,
                },
                isSearching: true,
                invoiceReferenceId: null,
                creatingCreditForInvoice: false,
                invoiceSelectedId: 0,
                billData: null,
                showLockedDate: false,
                lockedDate: null,
                idToAction: 0,
                actionType: '',
                params: {
                    ...params,
                    ...parameters,
                },
            },
        });
    };

    useEffect(() => {
        getVendorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSearching) execGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearching]);

    useEffect(() => {
        if (vendorSelectedId > 0) {
            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                value:
                {
                    ...initState,
                    params: {
                        ...initState.params,
                        vendorId: vendorSelectedId,
                        vendorName: vendorSelectedName,
                    },
                    isSearching: true,
                    paidToList,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorSelectedId]);

    useEffect(() => {
        if (!loadingSuscription && dataSubscription) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: { dataFromSubscription: { ...dataSubscription.invoiceChanged } },
            });
        }
    }, [loadingSuscription, dataSubscription]);

    useEffect(() => {
        if (dataFromSubscription?.id > 0) {
            const { action, data: subsData, id } = dataFromSubscription;

            if (action === SubscriptionActionType.ADDED) {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                if (Number(currentData.vendorId) === Number(vendorSelectedId) || !vendorSelectedId || vendorSelectedId === 0) {
                    records.unshift({ ...currentData });

                    const mappedRecords = AccountPayableMapper.mapInvoiceList(records);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: mappedRecords,
                            totalCount: totalCount + 1,
                        },
                        dataFromSubscription: null,
                    });
                }
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                if (Number(currentData.vendorId) === Number(vendorSelectedId) || !vendorSelectedId || vendorSelectedId === 0) {
                    const currentIndex = currentRecords.findIndex((item) => Number(item.invoiceId) === Number(id));

                    if (currentIndex >= 0) {
                        currentRecords.splice(currentIndex, 1);
                        currentRecords.splice(currentIndex, 0, { ...currentData });

                        const mappedRecords = AccountPayableMapper.mapInvoiceList(currentRecords);

                        dispatch({
                            type: ACTION_TYPES.SET_TABLE,
                            value: {
                                records: mappedRecords,
                            },
                            dataFromSubscription: null,
                        });
                    }
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromSubscription]);

    const onCloseCheck = () => {
        dispatch({
            type: ACTION_TYPES.SET_ON_CLOSE_CHECK_OR_INVOICE,
            value: {
                openCheck: false,
                billData: null,
            },
        });
    };

    const onCloseInvoice = () => {
        dispatch({
            type: ACTION_TYPES.SET_ON_CLOSE_CHECK_OR_INVOICE,
            value: {
                openInvoice: false,
                openCheck: false,
                creatingCreditForInvoice: false,
                invoiceReferenceId: null,
                invoiceSelectedId: 0,
                billData: null,
            },
        });
    };

    const [getInvoiceInformation, { loading: generatingCheck }] = useLazyQuery(AccountPayableQuery.GET_INVOICE_BY_IDS, {
        onCompleted: (res) => {
            if (res.getInvoiceByIds) {
                const dataProcessed = AccountPayableMapper.mapBillData(res.getInvoiceByIds);

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        openCheck: true,
                        billData: dataProcessed,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onActionClick = (invoiceData, action) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                idToAction: invoiceData.invoiceId,
                actionType: action,
                billData: {
                    invoiceNumber: invoiceData.invoiceNumber,
                    vendorId: invoiceData.vendorId,
                    vendorName: invoiceData.vendorName,
                },
            },
        });
    };

    const onActionConfirm = async () => {
        if (actionType === GeneralAction.VOID) {
            await voidInvoice({
                variables: {
                    invoiceId: idToAction,
                    forceVoid: false,
                    dateToPost: new Date(),
                    comment: commentToVoid,
                    forceFromSold: false,
                },
            });
        }
    };

    const forceToVoid = async (values) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionType: '',
                alreadyForceVoidDate: values.voidDate,
            },
        });

        voidInvoice({
            variables: {
                invoiceId: idToAction,
                forceVoid: true,
                dateToPost: values.voidDate,
                comment: values.comment,
                forceFromSold: false,
            },
        });
    };

    const onActionConfirmVoidSold = async () => {
        await voidInvoice({
            variables: {
                invoiceId: idToAction,
                forceVoid: alreadyForceVoidDate !== null,
                dateToPost: alreadyForceVoidDate,
                comment: commentToVoid,
                forceFromSold: true,
            },
        });
    };

    const closePrintDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openReportFilter: false,
                isSearching: false,
            },
        });
    };

    const [printInvoiceList, { loading: printing }] = useMutation(AccountPayableMutation.PRINT_INVOICE_LIST, {
        onCompleted: (mutationData) => {
            if (mutationData?.printInvoiceList) {
                closePrintDialog();
                printJS({
                    printable: mutationData?.printInvoiceList,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onPrintReport = () => {
        const currentDate = new Date();
        printInvoiceList({
            variables: {
                filter: {
                    useDateRange: params.payType === InvoicePaidType.DUE ? false : params.useDateRange,
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                    payType: params.payType,
                    vendorId: params.vendorId,
                    printDate: DateUtils.format(`${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`, DateFormat.DATETIME_WITHOUT_SECONDS),
                },
                sort: [
                    {
                        fieldName: 'vendorName',
                        dir: DataSort.ASC,
                    },
                    {
                        fieldName: 'dueDate',
                        dir: DataSort.ASC,
                    }],
            },
        });
    };

    const onSearch = (param, value) => {
        const newParams = {
            [param]: value,
        };

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                isSearching: true,
                params: {
                    ...state.params,
                    ...newParams,
                },
            },
        });
    };

    const onFilterClear = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value:
            {
                ...initState,
                params: {
                    ...initState.params,
                    vendorId: params.vendorId,
                    vendorName: params.vendorName,
                },
                isSearching: false,
                openReportFilter: true,
                paidToList,
            },
        });
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: (header) => {
                const { data } = header;
                const allApproved = data.length > 0 && data.filter((c) => c._original.isChecked).length === data.length;

                return allApproved ? (
                    <CheckBoxIcon
                        fontSize="small"
                        className={classes.highlightedColor}
                        onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                    />
                )
                    : (
                        <CheckBoxOutlineBlankIcon
                            fontSize="small"
                            className={classes.highlightedColor}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                        />
                    );
            },
            Cell: (cell) => {
                const {
                    original: {
                        invoiceId, isChecked, status,
                    },
                } = cell;

                const itemChecked = { invoiceId };

                if (status !== InvoiceStatus.PAID && status !== InvoiceStatus.VOID) {
                    return (
                        isChecked ? (
                            <CheckBoxIcon
                                className={classes.highlightedColor}
                                onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: itemChecked })}
                            />
                        )
                            : (
                                <CheckBoxOutlineBlankIcon
                                    className={classes.highlightedColor}
                                    onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: itemChecked })}
                                />
                            )
                    );
                }

                return null;
            },
            width: 60,
            minWidth: 60,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            sortable: false,
            filterable: false,
            show: InvoicePaidType.DUE === params.payType,
        },
        {
            Header: 'Invoice #',
            minWidth: 120,
            width: 120,
            id: 'invoiceNumber',
            accessor: 'invoiceNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Vendor Name',
            minWidth: 120,
            width: 120,
            id: 'vendorName',
            accessor: 'vendorName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Post Date',
            minWidth: 90,
            width: 90,
            id: 'billDate',
            accessor: 'billDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Due Date',
            minWidth: 90,
            width: 90,
            id: 'dueDate',
            accessor: 'dueDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Amount',
            minWidth: 100,
            width: 100,
            id: 'invoiceAmount',
            accessor: 'invoiceAmount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Amount Due',
            minWidth: 100,
            width: 100,
            id: 'amountDue',
            accessor: 'amountDue',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Type',
            minWidth: 70,
            width: 60,
            id: 'isCredit',
            accessor: 'isCredit',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => {
                if (value) return AccountsPayableType.CREDIT;
                if (!value) return AccountsPayableType.BILL;
                return '';
            },
        },
        {
            Header: 'Memo',
            minWidth: 90,
            id: 'memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'User',
            minWidth: 90,
            width: 90,
            id: 'sysUser',
            accessor: 'sysUser',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            minWidth: 90,
            width: 90,
            id: 'status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;
                let badgeStatus = classes.primary;
                if (status.toLowerCase() === InvoiceStatus.PAID.toLowerCase()) badgeStatus = classes.badgeApproved;
                else if (status.toLowerCase() === InvoiceStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
                else if (status.toLowerCase() === InvoiceStatus.PARTIALLY_PAID.toLowerCase()) badgeStatus = classes.badgePrinted;

                return <Chip size="small" label={status} className={badgeStatus} />;
            },
        },
        {
            Header: 'Actions',
            width: 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, classes.hidden, 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        invoiceId, isCredit, status, vendorId, vendorName, invoiceNumber,
                    },
                } = cellData;

                return (
                    <div className={classes.buttonSpacing}>
                        {INVOICE_READ
                        && (
                            <Button
                                onClick={() => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: {
                                        openInvoice: true,
                                        invoiceSelectedId: invoiceId,
                                        billData: {
                                            vendorId,
                                            vendorName,
                                        },
                                    },
                                })}
                                variant="outlined"
                                startIcon={
                                    status.toLowerCase() === InvoiceStatus.VOID.toLowerCase()
                                        ? <VisibilityOutlinedIcon className={classes.actionButtonSuccess} />
                                        : <EditOutlinedIcon className={classes.actionButtonSuccess} />
                                }
                                size="small"
                            >
                                {status.toLowerCase() === InvoiceStatus.VOID.toLowerCase() ? 'View' : 'Edit'}
                            </Button>
                        )}
                        {INVOICE_VOID && status.toLowerCase() === InvoiceStatus.ACTIVE.toLowerCase()
                        && (
                            <Button
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                                onClick={() => onActionClick({
                                    invoiceId,
                                    vendorId,
                                    vendorName,
                                    invoiceNumber,
                                }, GeneralAction.VOID)}
                            >
                                Void
                            </Button>
                        )}
                        {INVOICE_WRITE && (status.toLowerCase() === InvoiceStatus.ACTIVE.toLowerCase()
                        || status.toLowerCase() === InvoiceStatus.PARTIALLY_PAID.toLowerCase())
                        && !isCredit
                        && (
                            <Button
                                onClick={() => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: {
                                        openInvoice: true,
                                        creatingCreditForInvoice: true,
                                        invoiceReferenceId: invoiceId,
                                        billData: {
                                            vendorId,
                                            vendorName,
                                            invoiceNumber,
                                        },
                                    },
                                })}
                                variant="outlined"
                                startIcon={<MoneyOffOutlinedIcon className={classes.actionButtonWarning} />}
                                size="small"
                            >
                                Create Credit
                            </Button>
                        )}
                        {INVOICE_PAYMENT && (status.toLowerCase() === InvoiceStatus.ACTIVE.toLowerCase()
                        || status.toLowerCase() === InvoiceStatus.PARTIALLY_PAID.toLowerCase())
                        && (
                            <Button
                                onClick={() => getInvoiceInformation({
                                    variables: {
                                        invoicesId: [invoiceId],
                                    },
                                })}
                                variant="outlined"
                                startIcon={<LocalAtmOutlinedIcon className={classes.actionButtonMain} />}
                                size="small"
                            >
                                Pay
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    const approvedRecords = table?.records.filter((c) => c.isChecked).map((c) => ({ amountDue: c.amountDue, invoiceId: c.invoiceId, isCredit: c.isCredit }));
    const amountDueSelected = approvedRecords.reduce((sum, item) => sum + (item.isCredit ? (item.amountDue * -1) : item.amountDue), 0);

    const onlyOneVendor = table?.records.filter((c) => c.isChecked).map((c) => c.vendorId).filter((v, i, a) => a.indexOf(v) === i);

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.toolbar}>
                    <div className={classes.containerFilterSearch}>
                        <InputSearch customClasses={classes.search} initialSearch={params.search || ''} onSearch={(val) => onSearch('search', val)} />
                        <Tooltip title="Advance Search" placement="top-start">
                            <Button
                                variant="outlined"
                                startIcon={<FilterListOutlinedIcon />}
                                disabled={loading}
                                size="small"
                                onClick={() => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: {
                                        openReportFilter: true,
                                    },
                                })}
                            >
                                Filter
                            </Button>
                        </Tooltip>
                        <Tooltip title="Export to Pdf" placement="top-start">
                            <Button
                                variant="outlined"
                                startIcon={<PrintOutlinedIcon />}
                                disabled={loading}
                                size="small"
                                onClick={onPrintReport}
                            >
                                Print
                            </Button>
                        </Tooltip>
                        <LabelValue
                            label="Vendor"
                            value={params.vendorName}
                        />
                    </div>
                </div>
                <div className={classes.buttonSpacing}>
                    <Tooltip
                        title={onlyOneVendor.length > 1 ? 'You can not pay because you selected multiple vendors.'
                            : 'Pay multiple invoices'}
                        placement="top-start"
                    >
                        <Badge
                            badgeContent={`${approvedRecords?.length ?? 0}
                            ${amountDueSelected > 0 ? (` - ${NumberUtils.applyCurrencyFormat(amountDueSelected)}`) : ''}`}
                            color="primary"
                        >
                            <Button
                                onClick={() => getInvoiceInformation({
                                    variables: {
                                        invoicesId: approvedRecords.map((c) => c.invoiceId),
                                    },
                                })}
                                variant="outlined"
                                startIcon={<LocalAtmOutlinedIcon className={classes.actionButtonMain} />}
                                disabled={loading || approvedRecords?.length <= 1 || InvoicePaidType.DUE !== params.payType || onlyOneVendor.length > 1}
                                size="small"
                            >
                                Pay multiple invoices
                            </Button>
                        </Badge>
                    </Tooltip>
                    <Tooltip title="New Bill / Credit" placement="top-start">
                        <Button
                            onClick={() => dispatch({
                                type: ACTION_TYPES.SET_STATE_VALUES,
                                value: {
                                    openInvoice: true,
                                    billData: {
                                        vendorId: vendorSelectedId,
                                        vendorName: vendorSelectedName,
                                        vendorAddress: vendorSelectedAddress,
                                    },
                                },
                            })}
                            variant="outlined"
                            startIcon={<AddIcon />}
                            disabled={loading}
                            size="small"
                        >
                            New Bill / Credit
                        </Button>
                    </Tooltip>
                </div>
            </Header>
            <div className={classes.vendorBalanceTable}>
                <Split
                    sizes={selectedRow && selectedRow.invoiceId ? [60, 40] : [100, 0]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        data={table?.records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={table?.totalCount}
                        rowSelected
                        className="-highlight actionsInLine"
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;
                            return {
                                onDoubleClick: () => {
                                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: record });
                                },
                            };
                        }}
                    />
                    <div>
                        {selectedRow && selectedRow.invoiceId && (
                            <BankingList invoiceSelectedId={selectedRow.invoiceId} comeFromInvoice />
                        )}
                    </div>
                </Split>
                {openCheck && (
                    <CheckCreate
                        comeFromInvoice
                        onCloseCheck={(success) => onCloseCheck(success)}
                        billData={billData}
                    />
                )}
                {openInvoice && (
                    <InvoiceCreate
                        vendorSelectedId={invoiceSelectedId > 0 ? vendorSelectedId : billData.vendorId}
                        vendorSelectedName={invoiceSelectedId > 0 ? vendorSelectedName : billData.vendorName}
                        onCloseInvoice={(success) => onCloseInvoice(success)}
                        invoiceReferenceSelectedId={invoiceReferenceId}
                        isCreditFromInvoice={creatingCreditForInvoice}
                        id={invoiceSelectedId}
                    />
                )}
                <ConfirmDialog
                    title={`${actionType} Record #${billData?.invoiceNumber ?? idToAction}`}
                    description={`Are you sure you want to ${actionType} this record?`}
                    open={idToAction > 0 && !(printing || voiding) && !showLockedDate}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseActionDialog}
                    onClickSecondary={onCloseActionDialog}
                    onClickPrimary={onActionConfirm}
                    disablePrimaryButton={!commentToVoid}
                >
                    {actionType === GeneralAction.VOID && (
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.voidPaymentInput}>
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    rows="2"
                                    className={!commentToVoid ? 'invalid-field' : ''}
                                    placeholder="Reason to Void"
                                    value={commentToVoid}
                                    onChange={(e) => dispatch({
                                        type: ACTION_TYPES.SET_STATE_VALUES,
                                        value: {
                                            commentToVoid: e.target.value,
                                        },
                                    })}
                                />
                            </Form.Group>
                        </Grid>
                    )}
                </ConfirmDialog>
                {openReportFilter && (
                    <InvoiceListFilters
                        vendorList={paidToList}
                        onFilterSearch={(parameters) => onFilterSearch(parameters)}
                        onClose={closePrintDialog}
                        onFilterClear={onFilterClear}
                        currentParams={{
                            payType: params.payType,
                            vendorId: params.vendorId,
                            vendorName: params.vendorName,
                            useDateRange: params.useDateRange,
                            fromDate: params.fromDate,
                            toDate: params.toDate,
                        }}
                        initParams={{
                            payType: initState.params.payType,
                            vendorId: initState.params.vendorId,
                            vendorName: initState.params.vendorName,
                            useDateRange: initState.params.useDateRange,
                            fromDate: initState.params.fromDate,
                            toDate: initState.params.toDate,
                        }}
                    />
                )}
                {showLockedDate && lockedDate && !voiding
                    && (
                        <AccountingLockDate
                            recordNumber={String(billData?.invoiceNumber || idToAction)}
                            onCloseActionDialog={onCloseActionDialog}
                            onForceToVoid={(values) => forceToVoid(values)}
                            lockedDate={new Date(lockedDate)}
                            showComment
                            defaultComment={commentToVoid}
                        />
                    )}
                {showForceBecauseIsSold && (
                    <ConfirmDialog
                        title="The system cannot void this record"
                        open={showForceBecauseIsSold && !(printing || voiding) && !showLockedDate}
                        variant="outlined"
                        titlePrimary="Yes"
                        titleSecondary="Cancel"
                        onClose={onCloseActionDialog}
                        onClickSecondary={onCloseActionDialog}
                        onClickPrimary={onActionConfirmVoidSold}
                        disablePrimaryButton={!commentToVoid}
                    >
                        <div>
                            <p className={classes.paddingTop10}>Some of the stock numbers in this Bill are sold and voiding this Bill will not reverse them.</p>
                            <p className={clsx(classes.paddingTop10, classes.highlightText)}>Reversing them in financials would require a General Journal.</p>
                            <p className={classes.paddingTop10}>Are you sure you want to void this Bill?</p>
                        </div>
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.voidPaymentInput}>
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    rows="2"
                                    className={!commentToVoid ? 'invalid-field' : ''}
                                    placeholder="Reason to Void"
                                    value={commentToVoid}
                                    onChange={(e) => dispatch({
                                        type: ACTION_TYPES.SET_STATE_VALUES,
                                        value: {
                                            commentToVoid: e.target.value,
                                        },
                                    })}
                                />
                            </Form.Group>
                        </Grid>
                    </ConfirmDialog>
                )}
                <If condition={voiding}>
                    <DialogActionMessage message="Voiding record... " />
                </If>
                <If condition={generatingCheck}>
                    <DialogActionMessage message="Getting information to create check... " />
                </If>
                <If condition={printing}>
                    <DialogActionMessage message="Printing... " />
                </If>
            </div>
        </div>
    );
};

InvoiceList.propTypes = {
    vendorSelectedId: PropTypes.number,
    vendorSelectedName: PropTypes.string,
    vendorSelectedAddress: PropTypes.string,
};

InvoiceList.defaultProps = {
    vendorSelectedId: 0,
    vendorSelectedName: '',
    vendorSelectedAddress: '',
};

export default InvoiceList;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
// TODO: Resolve import/no-unresolved immutability-helper
/* eslint-disable import/no-unresolved */
import update from 'immutability-helper';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, Typography,
} from '@material-ui/core';

const styles = (theme) => DealStyles.dealsForms(theme, fade);

class FeesDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feesData: props.feesData,
        };
    }

    onChange(fieldName, value) {
        let { state: { feesData } } = this;

        feesData = update(feesData, {
            [fieldName]: { $set: value },
        });

        this.setState({ feesData });
    }

    render() {
        const {
            props: {
                classes, toggleModal, open, updateFees,
            },
        } = this;
        const { state: { feesData } } = this;
        const { props: { lotData, isLease } } = this;

        return (
            <Dialog
                open={open}
                className={classes.feeDialogWidth}
                fullWidth
                maxWidth="xl"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Fees" onClose={toggleModal} iconSize="sm" titleVariant="h4" />
                <DialogContent className={classes.dialogContentFee} dividers>
                    <Form className={classes.formW100}>
                        <Form.Row>
                            <Col className={classes.formColumnLeftFee}>
                                {(lotData.displayDealerFee || feesData.dealerFee > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>Dealer Fee</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.dealerFee || ''}
                                                    onChange={(value) => this.onChange('dealerFee', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography className="mathematicExpression">+</Typography>
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayInspectionFee || feesData.inspectionFee > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>Inspection Fee</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.inspectionFee || ''}
                                                    onChange={(value) => this.onChange('inspectionFee', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography className="mathematicExpression">+</Typography>
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayTagAgencyFee || feesData.tagAgencyFee > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>Tag Agency Fee</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.tagAgencyFee || ''}
                                                    onChange={(value) => this.onChange('tagAgencyFee', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography className="mathematicExpression">+</Typography>
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(isLease && (lotData.displayAcquisitionFee || feesData.mbi > 0))
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>Acquisition Fee</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.mbi || ''}
                                                    onChange={(value) => this.onChange('mbi', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                            </Col>
                            <Col className={classes.formColumnRightFee} sm={{ span: 6, offset: 0 }}>
                                {(lotData.displayUserField1 || feesData.userField1 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField1Name || 'User Field 1'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField1 || ''}
                                                    onChange={(value) => this.onChange('userField1', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField2 || feesData.userField2 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField2Name || 'User Field 2'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField2 || ''}
                                                    onChange={(value) => this.onChange('userField2', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField3 || feesData.userField3 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField3Name || 'User Field 3'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField3 || ''}
                                                    onChange={(value) => this.onChange('userField3', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField4 || feesData.userField4 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField4Name || 'User Field 4'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField4 || ''}
                                                    onChange={(value) => this.onChange('userField4', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField5 || feesData.userField5 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField5Name || 'User Field 5'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField5 || ''}
                                                    onChange={(value) => this.onChange('userField5', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField6 || feesData.userField6 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField6Name || 'User Field 6'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField6 || ''}
                                                    onChange={(value) => this.onChange('userField6', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                                {(lotData.displayUserField7 || feesData.userField7 > 0)
                                && (
                                    <Form.Group>
                                        <Form.Label className={classes.labelDialogFee}>{lotData.userField7Name || 'User Field 7'}</Form.Label>
                                        <Grid container spacing={1}>
                                            <Grid className={classes.inputDialogFee} item xs={11}>
                                                <InputNumber
                                                    value={feesData.userField7 || ''}
                                                    onChange={(value) => this.onChange('userField7', value)}
                                                    placeholder="$0.00"
                                                    showCurrency
                                                    thousandSeparator
                                                    size="sm"
                                                />
                                            </Grid>
                                            <Grid className={classes.styleGrid} item xs={1}>
                                                <Typography />
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                )}
                            </Col>
                        </Form.Row>
                    </Form>
                </DialogContent>
                <DialogActions
                    titlePrimary="Apply"
                    onClickSecondary={toggleModal}
                    onClickPrimary={() => {
                        updateFees(feesData);
                        toggleModal();
                    }}
                />
            </Dialog>
        );
    }
}

FeesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    feesData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    updateFees: PropTypes.func.isRequired,
    lotData: PropTypes.object.isRequired,
    isLease: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FeesDialog);

import React, { PureComponent } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

// Material UI
import Loading from 'components/widgets/Loading';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography } from '@material-ui/core';
import DialogActions from 'components/widgets/modal/DialogActions';

const styles = (theme) => ({
    main: {
        zIndex: '9000 !important',
    },
    dialog: {
        width: 500,
    },
    body: {
        padding: theme.spacing(3),
    },
    description: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.text.secondaryPurpure,
    },
});

class ConfirmDialog extends PureComponent {
    render() {
        const {
            props: {
                description, open, titlePrimary, titleSecondary, onClickPrimary, onClickSecondary, onClose,
                dividerFooter, showHeader, title, classes, variant, hiddenPrimaryButton, hiddenSecondaryButton,
                children, disablePrimaryButton, disableSecondaryButton, loading,
            },
        } = this;

        return (
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: classes.dialog,
                }}
                onClick={(e) => e.stopPropagation()}
                className={classes.main}
            >
                {showHeader && <DialogAppBar title={title} onClose={() => (onClose !== null ? onClose() : onClickSecondary())} iconSize="sm" />}
                {!loading && (
                    <div className={classes.body}>
                        {description && <Typography className={classes.description}>{description}</Typography>}
                        {children}
                    </div>
                )}
                {loading && <div className={classes.body}><Loading /></div>}
                <DialogActions
                    divider={dividerFooter}
                    titlePrimary={titlePrimary}
                    onClickSecondary={onClickSecondary}
                    onClickPrimary={onClickPrimary}
                    titleSecondary={titleSecondary}
                    variant={variant}
                    hiddenPrimaryButton={hiddenPrimaryButton}
                    hiddenSecondaryButton={hiddenSecondaryButton}
                    disablePrimaryButton={loading || disablePrimaryButton}
                    disableSecondaryButton={loading || disableSecondaryButton}
                />
            </Dialog>
        );
    }
}

ConfirmDialog.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.string,
    showHeader: PropTypes.bool,
    dividerFooter: PropTypes.bool,
    description: PropTypes.string,
    open: PropTypes.bool,
    titlePrimary: PropTypes.string,
    onClickPrimary: PropTypes.func,
    onClickSecondary: PropTypes.func,
    onClose: PropTypes.func,
    titleSecondary: PropTypes.string,
    hiddenPrimaryButton: PropTypes.bool,
    hiddenSecondaryButton: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    children: PropTypes.node,
    disablePrimaryButton: PropTypes.bool,
    disableSecondaryButton: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
    title: '',
    description: null,
    loading: false,
    showHeader: true,
    titlePrimary: 'Ok',
    dividerFooter: true,
    variant: 'contained',
    titleSecondary: 'Cancel',
    onClickPrimary: () => {},
    onClickSecondary: () => {},
    onClose: () => {},
    open: false,
    hiddenPrimaryButton: false,
    hiddenSecondaryButton: false,
    children: null,
    disablePrimaryButton: false,
    disableSecondaryButton: false,
};

export default withStyles(styles)(ConfirmDialog);

import { RelationType } from 'utils/enum/DealEnum';

export default class RelationUtils {
    static getRelation() {
        return Object.keys(RelationType).map((key) => {
            const relation = RelationType[key];
            return { value: relation, label: relation };
        });
    }
}

import { toString } from 'lodash';
import StringUtils from './StringUtils';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

// TODO: Add unit test
export default class PhoneUtils {
    /**
     * This method returns if the number is true or false.
     * @param phoneNumber
     * @param country
     * @returns {bool}
     */
    static isValid(phoneNumber, country = 'US') {
        try {
            const number = phoneUtil.parse(phoneNumber, country);

            return phoneUtil.isValidNumber(number);
        } catch (ex) {
            return false;
        }
    }

    /**
     * This method returns phone number with the following format (000) 000-0000
     * @param phone
     * @param country
     * @returns {string}
     */
    static formatPhone(phone, country = 'US') {
        try {
            const expression = toString(phone);
            const number = phoneUtil.parseAndKeepRawInput(expression, country);

            return phoneUtil.formatInOriginalFormat(number, country);
        } catch (ex) {
            return '';
        }
    }

    /**
     * @param phone (000) 000-0000, 0000000000, 000-000-0000
     * @returns {string} 000-000-0000
     */
    static format(phone) {
        if (!StringUtils.isEmpty(phone)) {
            const phoneDigits = phone.replace(/\D/g, '');

            if (phoneDigits.length === 10) {
                const phoneNumber = phoneDigits.match(/(\d{3})(\d{3})(\d{4})/);
                return `${phoneNumber[1]}-${phoneNumber[2]}-${phoneNumber[3]}`;
            }
        }

        return phone;
    }
}

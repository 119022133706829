import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';

// icons
import AppsIcon from '@material-ui/icons/Apps';

import { useHistory, useParams } from 'react-router-dom';
// utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import PortfolioSettingsList from 'components/modules/settings/bphp/portfolio/PortfolioSettingsList';
import BHPHGeneralSettings from 'components/modules/settings/bphp/BHPHGeneralSettings';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['portfolio'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    settings.push(
        {
            index: 0,
            text: 'General',
            icon: <AppsIcon />,
            component: <BHPHGeneralSettings />,
        },
    );

    if (keyStore.hasPermission(Permission.SETTINGS_BHPH_PORTFOLIO_READ)) {
        settings.push(
            {
                index: 1,
                text: 'Portfolio',
                icon: <AppsIcon />,
                component: <PortfolioSettingsList />,
            },
        );
    }

    return settings;
};

const BHPHSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab || 'bhph'}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            {settingsItems.map((item) => (
                activeIndex === item.index && <div className={classes.content} key={item.index}>{item.component}</div>
            ))}
        </Container>
    );
};

export default BHPHSettings;

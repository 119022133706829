import { gql } from '@apollo/client';

export default class GeneratedChecksMutation {
    static VOID_PAYROLL_CHECKS = gql`
        mutation voidPayrollChecks(
            $checkCBID: Int!
            $forceToVoid: Boolean!
            $voidDate: Date
        ) {
            voidPayrollChecks(
                checkCBID: $checkCBID
                forceToVoid: $forceToVoid
                voidDate: $voidDate
            ) {
                success
                isLockedDate
                lockedDate
            }
        }
    `;

    static PRINT_PAYROLL_REPORT_BY_NAME_AND_PAY_PERIOD = gql`
        mutation printPayrollReportByNameAndPayPeriod($formName: String!, $payPeriod: String!) {
            printPayrollReportByNameAndPayPeriod(formName: $formName, payPeriod: $payPeriod)
        }
    `;

    static GENERATE_PAYROLL_PRINTING = gql`
        mutation generatePayrollPrinting($cbids: [Int!]!) {
            generatePayrollPrinting(cbids: $cbids)
        }
    `;

    static GENERATE_NACHA_FILE = gql`
        mutation generateNachaFile($effectiveDate: String!, $payPeriod: String!) {
            generateNachaFile(effectiveDate: $effectiveDate, payPeriod: $payPeriod)
        }
    `;

    static SAVE_PAYROLL_CHECK = gql`
        mutation savePayrollCheck($input: PayrollCheckInput!, $cbId: Int!) {
            savePayrollCheck(input: $input, cbId: $cbId)
        }
    `;

    static CREATE_PAYROLL_CHECK = gql`
        mutation createNewPayrollCheck($input: NewCheckInput!) {
            createNewPayrollCheck(input: $input)
        }
    `;

    static SEND_PAYROLL_CHECKS_BY_EMAIL = gql`
        mutation sendPayrollChecksByEmail($cbids: [Int!]!) {
            sendPayrollChecksByEmail(cbids: $cbids)
        }
    `;
}

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Form, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import {
    withStyles, Button, Select, Dialog, MenuItem,
    DialogActions, OutlinedInput, CircularProgress,
    Checkbox, TextField, InputLabel, FormControl, DialogContent,
} from '@material-ui/core';

// Helper libraries
import {
    map, isEqual, clone, cloneDeep,
} from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import IntegrationType from 'utils/enum/ThirdPartyIntegrationsEnum';

// GraphQL Modules
import GraphQLClient from 'services/apollo/GraphQLClient';
import UnderwritingQuery from 'services/graphQL/query/setting/UnderwritingQuery';
import UnderwritingMutation from 'services/graphQL/mutate/setting/UnderwritingMutation';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    button: {
        marginTop: 8,
        minWidth: 300,
    },
    buttonPrimaryLoading: {
        minWidth: 300,
        marginTop: 8,
        '&:disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8,
            color: '#FFF',
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        overflowX: 'hidden',
        display: 'flex',
        padding: theme.spacing(0.5),
    },
    inputStyle: {
        marginBottom: 8,
        minWidth: 300,
    },
    loadingCircle: {
        alignSelf: 'center',
    },
    formGroup: {
        width: 'min-content',
    },
});

const SEVEN_HUNDRED_CREDIT_LIST = [
    'SEVENHUNDREDCREDIT',
    'SEVENHUNDREDCREDIT_OFAC',
    'SEVENHUNDREDCREDIT_PRESCREEN',
    'SEVENHUNDREDCREDIT_PREQUALIFY',
];

class UnderwritingDialogForm extends Component {
    constructor(props) {
        super(props);

        // Instance GraphQL Client
        this.graphQLClient = new GraphQLClient();

        this.state = {
            validatingCredentials: false,
            companyApiIntegrationId: null,
            isValidCredentials: false,
            vendor: props.record,
            lotId: '',
            username: '',
            password: '',
            active: false,
            bureaus: [],
            loading: false,
            sending: false,
            flagPassword: true,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
    }

    onChangeBureaus(value, index, field) {
        const { state } = this;
        const { bureaus } = state;

        if (field === 'allowed') {
            if (!value) {
                bureaus[index].defaultCheck = false;
            }

            bureaus[index].active = value;
        } else {
            bureaus[index].defaultCheck = value;
        }

        this.setState({ bureaus });
    }

    onChange(value, fieldName) {
        this.setState({
            [fieldName]: value,
        });
    }

    onChangePassword(value, fieldName) {
        this.setState(
            {
                [fieldName]: value,
                isValidCredentials: false,
            },
            () => this.setValidCredentials(),
        );
    }

    getServicesData() {
        const {
            props,
            state: { vendor },
        } = this;
        const { record, isEditing } = props;
        const {
            type, bureaus, lotId, lotName,
        } = record;
        const apiIntegrationVendorId = record.id;
        const currentLot = StringUtils.isEmpty(lotId) ? null : lotId;
        const currentLotName = StringUtils.isEmpty(lotName) ? '' : lotName;

        this.setState({ loading: true });

        if (isEditing) {
            this.graphQLClient
                .query(UnderwritingQuery.GET_VENDOR_DETAILS, {
                    apiIntegrationVendorId,
                    lotId: currentLot,
                })
                .then((response) => {
                    const { graphQLErrors, data } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                    } else {
                        const { getCreditPullIntegration } = data;
                        const { credentials } = getCreditPullIntegration;

                        if (getCreditPullIntegration && credentials) {
                            const localBureaus = Array.isArray(
                                getCreditPullIntegration.bureaus,
                            ) && getCreditPullIntegration.bureaus.length
                                ? getCreditPullIntegration.bureaus.map(
                                    (item) => ({
                                        active: item.active,
                                        bureauCompanyId:
                                                      item.bureauCompanyId,
                                        defaultCheck:
                                                      item.defaultCheck,
                                    }),
                                )
                                : [];

                            this.backup = cloneDeep({
                                bureaus: localBureaus,
                                active: getCreditPullIntegration.active,
                                user: getCreditPullIntegration.credentials.user,
                                password: '**********',
                            });

                            const lot = this.getLotIntegration(
                                currentLot,
                                currentLotName,
                            );
                            const newVendor = clone(vendor);
                            newVendor.availableLotIntegration = lot;

                            this.setState({
                                bureaus: getCreditPullIntegration.bureaus,
                                companyApiIntegrationId:
                                    getCreditPullIntegration.companyApiIntegrationId,
                                isValidCredentials: true,
                                active: getCreditPullIntegration.active,
                                username: credentials.user,
                                password: '**********',
                                lotId: currentLot,
                                vendor: newVendor,
                            });
                        } else {
                            ModalUtils.errorMessage(null, 'Error getting credentials');
                        }
                    }
                })
                .catch((error) => {
                    ModalUtils.errorMessage(null, error);
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        } else if (type === IntegrationType.CREDIT_PULL) {
            this.setState({ bureaus, loading: false });
        } else {
            this.setState({ loading: false, active: true });
        }
    }

    getLotIntegration(lotId, lotName) {
        return [
            {
                lotId,
                lotName,
            },
        ];
    }

    getBureaus() {
        const { state } = this;
        const { bureaus } = state;

        return map(bureaus, (item) => ({
            active: item.active,
            bureauCompanyId: item.bureauCompanyId,
            defaultCheck: item.defaultCheck,
        }));
    }

    setValidCredentials() {
        const { state, props } = this;
        const { password, flagPassword } = state;
        const { isEditing } = props;
        const backUp = this.backup || {};

        if (isEditing) {
            if (flagPassword && backUp.password !== password) {
                this.setState({
                    isValidCredentials: false,
                    password: '',
                    flagPassword: false,
                });
            }
        }
    }

    validateAllowedOffices() {
        const {
            state: { bureaus },
        } = this;
        let result = true;

        if (bureaus && Array.isArray(bureaus)) {
            bureaus.forEach((item) => {
                if (item.active) result = false;
            });
        }

        return result;
    }

    /**
     * Call GraphQL Mutation to validate that the vendor's credentials are correct
     */
    validateCredentials() {
        const {
            state: {
                username, password, vendor, lotId,
            },
            state,
        } = this;
        const vendorName = state.vendor ? state.vendor.value : null;
        const isValidLot = SEVEN_HUNDRED_CREDIT_LIST.includes(vendorName)
            ? StringUtils.isEmpty(lotId)
            : false;
        const errorMessage = 'Something went wrong';
        const invalidCredentials = StringUtils.isEmpty(username)
            || StringUtils.isEmpty(password)
            || StringUtils.isEmpty(vendor)
            || isValidLot;

        if (!invalidCredentials) {
            const newPassword = password.includes('*') ? null : password;
            const currentLot = StringUtils.isEmpty(lotId) ? null : lotId;

            this.setState({
                validatingCredentials: true,
            });

            this.graphQLClient
                .mutate(UnderwritingMutation.VALIDATE_INTEGRATION_CREDENTIALS, {
                    vendorName,
                    user: username,
                    lotId: currentLot,
                    password: newPassword,
                    bureausSetting: this.getBureaus(),
                })
                .then((response) => {
                    const { data, graphQLErrors } = response;
                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                    } else {
                        const { validateIntegrationCredentials } = data;

                        if (validateIntegrationCredentials) {
                            ModalUtils.successMessage(null, 'Your credentials are valid.');

                            this.setState({
                                isValidCredentials: true,
                            });
                        } else {
                            ModalUtils.errorMessage(null, errorMessage);
                        }
                    }
                })
                .catch((error) => {
                    ModalUtils.errorMessage(null, error);
                })
                .finally(() => {
                    this.setState({
                        validatingCredentials: false,
                    });
                });
        }
    }

    saveNewVendorAPIIntegration() {
        const { props, state } = this;
        const { isEditing } = props;
        const {
            active, companyApiIntegrationId, password, lotId,
        } = state;
        const bureausSetting = this.getBureaus();
        const apiIntegrationVendorId = state.vendor ? state.vendor.id : null;
        const errorMessage = `There was an error trying to ${
            isEditing ? 'update' : 'created'
        } Integration`;

        this.setState({ sending: true });

        if (isEditing) {
            const newPassword = password.includes('*') ? null : password;
            const credentials = {
                user: state.username,
                password: newPassword,
            };
            const input = {
                companyApiIntegrationId,
                apiIntegrationVendorId,
                bureausSetting,
                active,
                credentials,
            };

            this.graphQLClient
                .mutate(
                    UnderwritingMutation.UPDATE_API_INTEGRATIONS_BY_COMPANY,
                    input,
                )
                .then((response) => {
                    const { graphQLErrors, data } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                    } else {
                        const { updateDealerIntegration } = data;

                        if (updateDealerIntegration) {
                            ModalUtils.successMessage(null, 'Integration updated successfully');
                            if (props.onClose) {
                                props.onClose();
                            }
                            if (props.onReload) {
                                props.onReload();
                            }
                        } else {
                            ModalUtils.errorMessage(null, errorMessage);
                        }
                    }
                })
                .catch((error) => {
                    ModalUtils.errorMessage(null, error);
                })
                .finally(() => {
                    this.setState({ sending: false });
                });
        } else {
            const credentials = {
                user: state.username,
                password: state.password,
            };
            const currentLot = StringUtils.isEmpty(lotId) ? null : lotId;
            const input = {
                apiIntegrationVendorId,
                bureausSetting,
                active,
                lotId: currentLot,
                credentials,
            };

            this.graphQLClient
                .mutate(UnderwritingMutation.NEW_API_VENDOR_INTEGRATION, {
                    ...input,
                })
                .then(({ graphQLErrors, data }) => {
                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                    } else {
                        const { setUpDealerIntegration } = data;

                        if (setUpDealerIntegration) {
                            ModalUtils.successMessage(null, 'New integration was created successfully');
                            if (props.onClose) {
                                props.onClose();
                            }
                            if (props.onReload) {
                                props.onReload();
                            }
                        } else {
                            ModalUtils.errorMessage(null, errorMessage);
                        }
                    }
                })
                .catch((error) => {
                    ModalUtils.errorMessage(null, error);
                })
                .finally(() => {
                    this.setState({ sending: false });
                });
        }
    }

    allowSave() {
        const { state } = this;
        const bureaus = this.getBureaus();
        const data = {
            bureaus,
            user: state.username,
            password: state.password,
            active: state.active,
        };
        const backUp = this.backup || {};

        if (state.password.includes('*')) {
            delete data.password;
            const previousRecord = cloneDeep(backUp);
            delete previousRecord.password;

            return isEqual(previousRecord, data);
        }
        return isEqual(backUp, data);
    }

    isValidCredentials() {
        const {
            state: { vendor, username, password },
        } = this;

        return StringUtils.isEmpty(vendor) || StringUtils.isEmpty(username) || StringUtils.isEmpty(password);
    }

    initBind() {
        this.onChange = this.onChange.bind(this);
        this.getBureaus = this.getBureaus.bind(this);
        this.onChangeBureaus = this.onChangeBureaus.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.isValidCredentials = this.isValidCredentials.bind(this);
        this.getLotIntegration = this.getLotIntegration.bind(this);
        this.validateCredentials = this.validateCredentials.bind(this);
        this.validateAllowedOffices = this.validateAllowedOffices.bind(this);
        this.saveNewVendorAPIIntegration = this.saveNewVendorAPIIntegration.bind(
            this,
        );
    }

    render() {
        const {
            state,
            props: {
                classes, onClose, isEditing,
            },
        } = this;
        const {
            lotId,
            vendor,
            loading,
            sending,
            isValidCredentials,
            validatingCredentials,
        } = state;
        const lotAvailable = vendor.availableLotIntegration || [];
        const invalidCredentials = this.isValidCredentials();
        const isUnderwriting = vendor.type === 'UNDERWRITING';
        const invalidOffices = isUnderwriting
            ? false
            : this.validateAllowedOffices();
        const allowSave = this.allowSave();
        const isSevenHundredCredit = !StringUtils.isEmpty(vendor.value)
            && SEVEN_HUNDRED_CREDIT_LIST.includes(vendor.value);
        const isValidLot = isSevenHundredCredit ? !StringUtils.isEmpty(lotId) : true;

        return (
            <Dialog
                open
                maxWidth="sm"
            >
                <DialogAppBar
                    iconSize="sm"
                    onClose={onClose}
                    title={`${isEditing ? 'Edit' : 'New'} Integration`}
                />
                <DialogContent className={classes.dialogContent}>
                    {!loading && (
                        <div className={classes.body}>
                            <Form.Group as={Col} className={classes.formGroup}>
                                <FormControl
                                    margin="dense"
                                    variant="outlined"
                                    className={classes.inputStyle}
                                >
                                    <InputLabel
                                        ref={(ref) => {
                                            this.InputLabelRef = ref;
                                        }}
                                        htmlFor="vendor"
                                        shrink
                                    >
                                        {' '}
                                        Vendor *
                                        {'    '}
                                    </InputLabel>
                                    <Select
                                        value={vendor}
                                        disabled
                                        input={(
                                            <OutlinedInput
                                                labelWidth={60}
                                                notched
                                                id="vendor"
                                            />
                                        )}
                                    >
                                        <MenuItem value={vendor}>
                                            {vendor.name}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {isSevenHundredCredit && (
                                    <FormControl
                                        margin="dense"
                                        variant="outlined"
                                        className={classes.inputStyle}
                                    >
                                        <InputLabel
                                            ref={(ref) => {
                                                this.InputLabelRef = ref;
                                            }}
                                            htmlFor="lotId"
                                            shrink
                                        >
                                            Lot *
                                        </InputLabel>
                                        <Select
                                            value={lotId}
                                            disabled={isEditing}
                                            displayEmpty
                                            input={(
                                                <OutlinedInput
                                                    labelWidth={40}
                                                    notched
                                                    id="lotId"
                                                />
                                            )}
                                            onChange={(event) => {
                                                const { value } = event.target;
                                                this.onChange(value, 'lotId');
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>None</em>
                                            </MenuItem>
                                            {lotAvailable.map((item) => (
                                                <MenuItem
                                                    key={item.lotId}
                                                    value={item.lotId}
                                                >
                                                    {item.lotName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <TextField
                                    label="Username *"
                                    placeholder=""
                                    value={state.username}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        this.onChange(value, 'username');
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    className={classes.inputStyle}
                                />
                                <TextField
                                    label="Password *"
                                    value={state.password}
                                    placeholder=""
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        this.onChangePassword(value, 'password');
                                    }}
                                    onFocus={(event) => event.target.select()}
                                    type="password"
                                    margin="dense"
                                    variant="outlined"
                                    className={classes.inputStyle}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        invalidCredentials
                                        || invalidOffices
                                        || validatingCredentials
                                    }
                                    className={
                                        validatingCredentials
                                            ? classes.buttonPrimaryLoading
                                            : classes.button
                                    }
                                    onClick={this.validateCredentials}
                                >
                                    {validatingCredentials ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={24}
                                        />
                                    ) : null}
                                    Test Credentials
                                </Button>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>Active</span>
                                    <Checkbox
                                        checked={state.active}
                                        onChange={(event) => {
                                            const value = event.target.checked;
                                            this.onChange(value, 'active');
                                        }}
                                        color="primary"
                                        inputProps={{
                                            'aria-label': 'secondary checkbox',
                                        }}
                                        disabled={isUnderwriting && !isEditing}
                                    />
                                </div>
                            </Form.Group>
                            {!isUnderwriting && (
                                <Form.Group as={Col} className={classes.formGroup}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>Allowed</th>
                                                <th>Default</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.bureaus.map((item, index) => (
                                                <tr key={item.name}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={item.active}
                                                            onChange={(event) => {
                                                                const value = event.target
                                                                    .checked;
                                                                this.onChangeBureaus(
                                                                    value,
                                                                    index,
                                                                    'allowed',
                                                                );
                                                            }}
                                                            color="primary"
                                                            inputProps={{
                                                                'aria-label':
                                                                'secondary checkbox',
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Checkbox
                                                            disabled={
                                                                !state.bureaus[
                                                                    index
                                                                ].active
                                                            }
                                                            checked={
                                                                item.defaultCheck
                                                            }
                                                            onChange={(event) => {
                                                                const value = event.target
                                                                    .checked;
                                                                this.onChangeBureaus(
                                                                    value,
                                                                    index,
                                                                    'default',
                                                                );
                                                            }}
                                                            color="primary"
                                                            inputProps={{
                                                                'aria-label':
                                                                'secondary checkbox',
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Form.Group>
                            )}
                        </div>
                    )}
                    {loading && (
                        <div className={classes.loadingCircle}>
                            <CircularProgress />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            allowSave
                            || sending
                            || !isValidLot
                            || invalidOffices
                            || invalidCredentials
                            || !isValidCredentials
                        }
                        className={sending ? classes.buttonPrimaryLoading : ''}
                        onClick={this.saveNewVendorAPIIntegration}
                    >
                        {sending ? (
                            <CircularProgress color="inherit" size={24} />
                        ) : null}
                        {isEditing ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UnderwritingDialogForm.propTypes = {
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
};

UnderwritingDialogForm.defaultProps = {
    record: null,
};

export default withRouter(withStyles(styles)(UnderwritingDialogForm));

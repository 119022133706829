import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import History from 'components/widgets/History';
import { FetchPolicy } from 'utils/enum/Core';
import PropTypes from 'prop-types';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';

const ServiceNotesHistory = ({ invoiceNumber }) => {
    const [getData, { data, loading }] = useLazyQuery(ServiceQuery.GET_SERVICE_INVOICE_NOTES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (invoiceNumber > 0) {
            getData({
                variables: {
                    id: invoiceNumber,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceNumber]);

    return (
        <History
            loading={loading}
            records={data?.getServiceInvoiceNotes ?? []}
        />
    );
};

ServiceNotesHistory.propTypes = {
    invoiceNumber: PropTypes.number,
};

ServiceNotesHistory.defaultProps = {
    invoiceNumber: 0,
};

export default ServiceNotesHistory;

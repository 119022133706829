import StrinUtils from 'lib/StringUtils';
import { isNaN } from 'lodash';
import { toWords } from 'number-to-words';
/* Helper Libraries */
import numeral from 'numeral';

export default class NumberUtils {
    /** This method returns true if it only contains numbers
     * @param {String} Value
     * @example "13412431234" return true
     * @example "asfd898" retunr false
    */
    static isOnlyNumber(value) {
        return !StrinUtils.isEmpty(value) && /^[0-9]+$/.test(value);
    }

    /** This method returns a string in currency format
     * @param {Number} Value
     * @example "1000.234" return $1,000.23
    */
    static applyCurrencyFormat(value, format = '$0,0.00') {
        if (value) {
            return numeral(value).format(format);
        }

        return numeral(0).format(format);
    }

    /** This method returns a string in percentage format
     * @param {Number} Value
     * @example "18.99" return 18.99%
    */
    static applyPercentageFormat(value, format = '0.00%') {
        let result = 0;

        if (value && (typeof value) === 'number') {
            result = value * 0.01;
        }

        return numeral(result).format(format);
    }

    /** This method returns a string in thousands format
     * @param {Number} Value
     * @example "210112" return 210,112
    */
    static applyThousandsFormat(value, format = '0,0') {
        if (value) {
            return numeral(value).format(format);
        }

        return numeral(0).format(format);
    }

    /** this method returns a number with the inverted sign of its original value
     * @param {Number} Value
     * @example 1000.234 return -1,000.234
     * @example 0 return 0
     * @example -10 return 10
     * @returns {Number}
    */
    static toggleSign(value) {
        if (value === 0) return 0;

        return -value;
    }

    static convertToTitlecase = (phrase) => phrase
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    static numberToWords = (number) => {
        if (!number || !Number(number)) return '';

        const value = Number(number);

        const numPart = toWords(value).replace(',', '').replace('-', ' ');
        const decimalPart = value.toFixed(2).split('.')[1];
        return `${this.convertToTitlecase(numPart)} Dollars and ${decimalPart}/100 Cents`;
    };

    /**
     * This method returns the second formatted to hms
     * @param {Number} sec
     * @example 3599, 3600
     * @returns {String} 59:59 min, 1:00:00 hr
     */
    static secondsToHms(sec) {
        if (typeof sec === 'number' && !Number.isNaN(sec)) {
            // Hours, minutes and seconds
            const hrs = Math.floor(sec / 3600);
            const mins = Math.floor((sec % 3600) / 60);
            const secs = Math.floor(sec % 60);

            let ret = '';
            let suffix = 'sec';

            if (hrs > 0) {
                ret += `${hrs}:${mins < 10 ? '0' : ''}`;
                suffix = 'hr';
            }

            if (mins > 0) {
                suffix = 'min';
            }

            ret += `${mins}:${secs < 10 ? '0' : ''}`;
            ret += `${secs}`;

            return `${ret} ${suffix}`;
        }

        return null;
    }

    static round(value = 0, decimals = 2) {
        const val = parseFloat(value);

        if (!isNaN(val)) {
            return Number(val.toFixed(decimals));
        }

        return 0;
    }

    static conditionalRoundUp(value = 0, conditional = 0.5, truncate = true) {
        const val = parseFloat(value);

        if (!isNaN(val)) {
            const integer = Math.trunc(val);
            const decimals = val - integer;

            if (decimals >= conditional) return integer + 1;
            if (decimals < conditional && truncate) return integer;
            return val;
        }

        return 0;
    }
}

import * as yup from 'yup';

const { string, number, object } = yup;

const ServiceInvoiceSchema = object().shape({
    customerCode: number().required().moreThan(0, 'Customer is required'),
    vehicleVin: string().when('invoiceNumber', {
        is: (invoiceNumber) => invoiceNumber === 0,
        then: string().required('VIN is required').length(17, 'VIN must be exactly 17 characters'),
        otherwise: string(),
    }),
    vehicleMake: string().required('Make is required'),
    vehicleModel: string().required('Model is required'),
    advisor: string().required('Advisor is required'),
    advisorId: number().required().moreThan(0, 'Advisor Id must be greater than 0'),
    quickNote: string().max(
        250,
        'Memo has a maximum limit of 250 characters',
    ),
    milesOut: number().test(
        'required',
        'Miles out must be equals to or greater than miles In',
        // eslint-disable-next-line func-names
        function (milesOut) {
            const { milesIn } = this.parent;
            return !(milesIn > 0 && milesOut > 0 && milesOut < milesIn);
        },
    ),
    lotName: string().required('Lot name is required'),
});

export default ServiceInvoiceSchema;

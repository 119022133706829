import { gql } from '@apollo/client';

export default class MailMutate {
    static SEND_USER_EMAIL = gql`
        mutation sendUserEmail($input: EmailInput!, $crmId: ID) {
            sendUserEmail(input: $input, crmId: $crmId)
        }
    `;

    static MARK_EMAIL_AS_READ = gql`
        mutation markEmailAsRead($threadId: String!) {
            markEmailAsRead(threadId: $threadId)
        }
    `;

    static SEND_EMAIL_BY_CRM_ID = gql`
        mutation sendEmailByCRMId($input: EmailCRMInput!, $crmId: ID!) {
            sendEmailByCRMId(input: $input, crmId: $crmId)
        }
    `;
}

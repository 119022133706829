import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core';
import LabelStyles from 'styles/widgets/LabelStyles';

const useStyles = makeStyles((theme) => LabelStyles.styles(theme, fade));

function Label({
    className, color, children, uppercase,
}) {
    const classes = useStyles({ uppercase });

    return (
        <span
            className={
                clsx(classes.root, {
                    [classes[color]]: color,
                }, className)
            }
        >
            {children}
        </span>
    );
}

Label.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    uppercase: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'purple']),
};

Label.defaultProps = {
    className: '',
    color: 'secondary',
    children: null,
    uppercase: false,
};

export default Label;

import { gql } from '@apollo/client';

export default class BulletingBoardMutation {
    static UPDATE_BULLETIN_BOARD_COMPONENT = gql`
        mutation updateBulletinBoardComponent($component: ComponentInput) {
            updateBulletinBoardComponent(component: $component)
        }
    `;

    static REMOVE_BULLETIN_BOARD_COMPONENT = gql`
        mutation removeBulletinBoardComponent($bulletinBoardComponentId: Int!) {
            removeBulletinBoardComponent(bulletinBoardComponentId: $bulletinBoardComponentId)
        }
    `;

    static SAVE_MESSAGE_COMPONENT = gql`
        mutation saveMessageComponent($bulletinBoardComponentId: Int!, $message: MessageInput) {
            saveMessageComponent(bulletinBoardComponentId: $bulletinBoardComponentId, message: $message)
        }
    `;

    static REMOVE_MESSAGE_COMPONENT = gql`
        mutation removeMessageComponent($messageId: Int!) {
            removeMessageComponent(messageId: $messageId)
        }
    `;

    static SAVE_IFRAME_COMPONENT = gql`
        mutation saveIFrameComponent($bulletinBoardComponentId: Int!, $data: IFrameInput) {
            saveIFrameComponent(bulletinBoardComponentId: $bulletinBoardComponentId, data: $data)
        }
    `;

    static SAVE_IMAGE_COMPONENT = gql`
        mutation saveImageComponent($bulletinBoardComponentId: Int!, $data: ImageInput) {
            saveImageComponent(bulletinBoardComponentId: $bulletinBoardComponentId, data: $data)
        }
    `;
}

import { gql } from '@apollo/client';

export default class AccountPayableQuery {
static GET_INVOICE_LIST = gql`
query getInvoiceList(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: InvoiceFilter
) {
  getInvoiceList(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      invoiceId
      invoiceNumber
      billDate
      invoiceAmount
      amountDue
      dueDate
      memo
      isCredit
      status
      sysUser
      vendorId
      vendorName
    }
  }
}
`;

static GET_INVOICE_LIST_DROPDOWN = gql`
query getInvoiceList(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: InvoiceFilter
) {
  getInvoiceList(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      invoiceId
      invoiceNumber
    }
  }
}
`;

static GET_INVOICE = gql`
    query getInvoice(
      $invoiceId: Int
    ) {
      getInvoice(invoiceId: $invoiceId) {
        invoiceId
        vendorId
        vendorName
        invoiceNumber
        billDate
        invoiceAmount
        amountDue
        dueDate
        memo
        isCredit
        lotId
        accountNumber
        status
        journalId
        invoiceReferenceId
      }
    }
  `;

  static GET_INVOICE_BY_IDS = gql`
  query getInvoiceByIds(
    $invoicesId: [Int!]
  ) {
    getInvoiceByIds(invoicesId: $invoicesId) {
      invoiceId
      vendorId
      vendorName
      invoiceNumber
      invoiceAmount
      amountDue
      isCredit
      memo
      status
      lotId
      accountNumber
      invoiceReferenceId
      accountCOA {
        accountNumber
        description
        isControlled
        controlledBy
      }
      vendor {
        vendorAddress
      }
    }
  }
`;

static GET_CB_BY_INVOICE_LIST = gql`
query getAccountingCBMatchProcessList(
  $processRecordId: Int!
  $processTypeId: Int!
  $sort: [SortField]
) {
  getAccountingCBMatchProcessList(processRecordId: $processRecordId, processTypeId: $processTypeId, sort: $sort) {
    data {
      processRecordId
      processTypeId
      cbId
      checkNumber
      achCheckNumber
      depositNumber
      amount
      type
      status
      date
      memo
      paidTo
      invoiceAmount
      sysUser
    }
  }
}
`;
}

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import DealService from 'services/modules/DealService';

const PostToAccountingContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        record: PropTypes.object.isRequired,
        onCloseModal: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.dealService = new DealService();
        this.state = {
            postDate: DateUtils.getDate(),
            saving: false,
        };
        this.initBind();
    }

    onChangeValue(value) {
        this.setState({
            postDate: value,
        });
    }

    onPostAccount() {
        const { props: { onCloseModal, record: { accountNumber } }, state: { postDate } } = this;
        const input = {
            accountNumber,
            postDate: DateUtils.format(postDate),
        };

        this.setState({
            saving: true,
        });

        this.dealService.postAccount(input)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors, null, {
                        title: 'Message',
                        description: 'There were some errors while trying to post this deal:',
                    });
                    return;
                }

                if (data && data.postAccount) {
                    ModalUtils.successMessage([{ message: 'Deal posted to accounting successfully' }]);
                    onCloseModal();
                }
            })
            .finally(() => {
                this.setState({
                    saving: false,
                });
            });
    }

    getServicesData() {
        const { props: { record: { lotName } } } = this;
        const input = {
            lotName,
        };

        this.dealService.lotClosingDate(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.lotClosingDate) {
                    const { lotClosingDate } = data;
                    const newLotClosingDate = DateUtils.isValid(lotClosingDate) ? DateUtils.add(lotClosingDate, 1).toDate() : null;

                    this.setState({
                        lotClosingDate: newLotClosingDate,
                    });
                }
            });
    }

    initBind() {
        this.onPostAccount = this.onPostAccount.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onPostAccount={this.onPostAccount}
                onChangeValue={this.onChangeValue}
                getServicesData={this.getServicesData}
            />
        );
    }
};

export default PostToAccountingContainer;

/* eslint-disable no-param-reassign */
import React, {
    useEffect,
    useReducer,
} from 'react';
import {
    Grid,
    Button,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import {
    PrintOutlinedIcon,
    ThumbUpAltOutlinedIcon,
} from 'components/icons';
import clsx from 'clsx';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import { useQuery, useMutation } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import useEmployeHasCheck from 'components/hook/payroll/useEmployeHasCheck';
import MiscCommissionsQuery from 'services/graphQL/query/payroll/MiscCommissions';
import MiscCommissionsMutation from 'services/graphQL/mutate/payroll/MiscCommissions';
import PurchasingAgentDealCommissionTable from 'components/widgets/payroll/MiscCommissions/PurchasingAgentDealCommissionTable';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        height: 'fit-content',
    },
    header: {
        marginBottom: 10,
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    content: {
        flexGrow: 1,
        marginTop: 10,
        height: 'fit-content',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    tableHeight: {
        height: 'calc(50vh - 170px)',
    },
    totalLabel: {
        marginLeft: 50,
    },
    grandTotalLabel: {
        marginLeft: 189,
    },
}));

const ACTION_TYPES = {
    APPLY_FILTER: 'applyFilter',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === action.accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: action.accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const PurchasingAgentCommissionTable = ({
    employeeId, payPeriod,
}) => {
    const keyStore = new KeyStore();
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };

    const initState = {
        items: [],
        printing: false,
        loadTable: false,
        filtersApplied: [],
        openPrintDialog: false,
        openApprovalDialog: false,
        dealCommissionItems: [],
        loadDealCommissionTable: false,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        loadTable, filtersApplied, items, openApprovalDialog, openPrintDialog,
        dealCommissionItems, loadDealCommissionTable,
    } = state;

    const { hasCheck } = useEmployeHasCheck({ employeeId, payPeriod });

    const {
        loading, data, error,
    } = useQuery(MiscCommissionsQuery.GET_PURCHASING_AGENT_COMMISSION_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getPurchasingAgentCommission } = data;
            const formattedData = getPurchasingAgentCommission.map((item) => {
                const { purchasedDate, ...rest } = item;
                return { ...rest, purchasedDate: DateUtils.getOnlyDate(purchasedDate) };
            });
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadTable: true,
                    items: formattedData,
                    filtersApplied: [],
                },
            });
        }
    }, [data, loading, error]);

    const {
        loading: loadingDealCommissions, data: dealCommissionsData, error: dealCommissionError,
    } = useQuery(MiscCommissionsQuery.GET_PURCHASING_AGENT_DEAL_COMMISSION_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
    });

    useEffect(() => {
        if (dealCommissionError) {
            ModalUtils.errorMessage(dealCommissionError?.graphQLErrors);
            return;
        }

        if (!loadingDealCommissions && dealCommissionsData) {
            const { getPurchasingAgentDealCommissionList } = dealCommissionsData;
            const formattedData = getPurchasingAgentDealCommissionList.map((item) => {
                const { soldDate, ...rest } = item;
                return { ...rest, soldDate: DateUtils.getOnlyDate(soldDate) };
            });
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadDealCommissionTable: true,
                    dealCommissionItems: formattedData,
                },
            });
        }
    }, [dealCommissionsData, loadingDealCommissions, dealCommissionError]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                loadTable: false,
                filtersApplied: [],
            },
        });
    }, [payPeriod, employeeId]);

    const toggleApprovalDialog = () => {
        dispatch({
            field: 'openApprovalDialog',
            value: !openApprovalDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [approve, { loading: isApproving }] = useMutation(MiscCommissionsMutation.APPROVE_GENERAL_COMMISSION, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveGeneralCommission) {
                ModalUtils.successMessage(null, 'Commission(s) Approved Successfully!');
                toggleApprovalDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const togglePrintDialog = () => {
        dispatch({
            value: !openPrintDialog,
            field: 'openPrintDialog',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [printPayrollReport, { loading: printing }] = useMutation(MiscCommissionsMutation.PRINT_PURCHASING_AGENT_COMMISSIONS_FORM, {
        onCompleted: (mutationData) => {
            if (mutationData?.printPurchasingAgentCommissionReport) {
                printJS({
                    type: 'pdf',
                    showModal: false,
                    onPrintDialogClose: togglePrintDialog,
                    printable: mutationData.printPurchasingAgentCommissionReport,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onPrintReport = () => {
        togglePrintDialog();
        printPayrollReport({
            variables: {
                payPeriod,
                employeeId,
            },
        });
    };

    const getColumns = () => [{
        Header: 'Date',
        id: 'purchasedDate',
        minWidth: 20,
        accessor: 'purchasedDate',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Stock #',
        id: 'stockNumber',
        minWidth: 20,
        accessor: 'stockNumber',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Vehicle',
        id: 'vehicle',
        minWidth: 40,
        accessor: 'vehicle',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Commissions',
        id: 'commissionAmount',
        minWidth: 20,
        accessor: 'commissionAmount',
        className: 'd-flex-justify-center-align-center',
    }];

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const loadColumns = () => {
        const columnNamesExcluded = [
            'checkbox-column', 'isApproved',
        ];
        const columns = getColumns();
        columns.forEach((column) => {
            if (!columnNamesExcluded.includes(column.id)) {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                filterId={column.id}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    const disablePrintingButton = isApproving || printing || openPrintDialog || (items.length === 0 && dealCommissionItems.length === 0);
    const selectedRecordsToApprove = items.filter((item) => (item.isChecked && !item.isApproved));
    const totalCommissionAmount = items.reduce((sum, item) => sum + item.commissionAmount, 0);
    const totalDealCommissionAmount = dealCommissionItems.reduce((sum, item) => sum + item.commissionAmount, 0);

    return (
        <div className={classes.content}>
            <Grid item className={clsx(classes.row, classes.header)}>
                <>
                    {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_WRITE) && !hasCheck && (
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={isApproving}
                            onClick={toggleApprovalDialog}
                            className={classes.localButtonSpacing}
                            startIcon={(
                                <ThumbUpAltOutlinedIcon
                                    className={clsx({ [classes.actionButtonApprove]: selectedRecordsToApprove.length })}
                                />
                            )}
                        >
                            Approve
                        </Button>
                    )}
                    {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_PRINT) && (
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={onPrintReport}
                            disabled={disablePrintingButton}
                            className={classes.localButtonSpacing}
                            startIcon={<PrintOutlinedIcon className={clsx({ [classes.actionButtonPrint]: !disablePrintingButton })} />}
                        >
                            Print
                        </Button>
                    )}
                </>
            </Grid>
            <FormLabel className={classes.localButtonSpacing}>
                Inventory Purchases:
            </FormLabel>
            <Grid item xs={12} className={classes.tableHeight}>
                {!loadTable && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {loadTable && (
                    <Table
                        enableRowHover
                        className="-highlight payrollTable"
                        columns={loadColumns()}
                        filtered={filtersApplied}
                        totalRecords={items.length}
                        data={items}
                        defaultFilterMethod={(localFilter, row) => {
                            const id = localFilter.pivotId || localFilter.id;
                            if (typeof localFilter.value === 'object') {
                                return row[id] !== undefined
                                    ? localFilter.value.indexOf(row[id]) > -1
                                    : true;
                            }
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(localFilter.value) > -1
                                : true;
                        }}
                    />
                )}
                <div>
                    <FormLabel className={classes.localButtonSpacing}>
                        {`Vehicle Count: ${items.length}`}
                    </FormLabel>
                    <FormLabel className={classes.totalLabel}>
                        {`Total: ${NumberUtils.applyCurrencyFormat(totalCommissionAmount)}`}
                    </FormLabel>
                </div>
            </Grid>
            <PurchasingAgentDealCommissionTable
                items={dealCommissionItems}
                loadTable={loadDealCommissionTable}
                totalPurchasingCommissionAmount={totalCommissionAmount}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Approve Commission(s)?"
                open={openApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleApprovalDialog}
                onClose={toggleApprovalDialog}
                onClickPrimary={() => approve({
                    variables: {
                        payPeriod,
                        employeeId,
                        amount: totalCommissionAmount + totalDealCommissionAmount,
                    },
                })}
            />
        </div>
    );
};

PurchasingAgentCommissionTable.propTypes = {
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
};

export default PurchasingAgentCommissionTable;

import { gql } from '@apollo/client';

export default class PlaidMappingsQuery {
    static GET_PLAID_MAPPING_LIST = gql`
        query getPlaidMappingList($active: Boolean!) {
            getPlaidMappingList(active: $active) {
                active
                institutionId
                plaidMappingId
                institutionName
                bankAccountNumber
                institutionAccountId
                institutionAccountName
                plaidTransactionCategory
                institutionAccountLastFour
            }
        }
    `;

    static GET_PLAID_MAPPING = gql`
        query getPlaidMapping($mapId: ID!) {
            getPlaidMapping(mapId: $mapId) {
                active
                institutionId
                institutionName
                plaidAccessToken
                bankAccountNumber
                institutionAccountId
                institutionAccountName
                plaidTransactionCategory
                institutionAccountLastFour
            }
        }
    `;

    static GET_PLAID_BANK_LIST = gql`
        query getPlaidBankList {
            getPlaidBankList {
                institutionId
                institutionName
            }
        }
    `;

    static GET_PLAID_BANK_ACCOUNTS_LIST = gql`
        query getPlaidBankAccountsList($bankId: String!) {
            getPlaidBankAccountsList(bankId: $bankId) {
                plaidAccessToken
                institutionAccountId
                institutionAccountName
                institutionAccountLastFour
            }
        }
    `;

    static GET_PLAID_LINK_TOKEN = gql`
        query getPlaidLinkToken {
            getPlaidLinkToken
        }
    `;
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Grid, Paper, Typography,
} from '@material-ui/core';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';

import { withStyles, fade } from '@material-ui/core/styles';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// UI
import Select from 'components/widgets/Select';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

// Container
import TradeInFormContainer from 'components/containers/deals/create/vehicle/TradeInFormContainer';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { TradeInSchema } from 'utils/schema/deal/TradeIn';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import DateUtils from 'lib/DateUtils';
import clsx from 'clsx';

const styles = (theme) => DealStyles.vehiclePanel(theme, fade);

const TITLE_STATUSES = ['0-Clean', '1-Salvage', '3-Rebuild', '4-Manufacturer Buyback', '5-Water Damage'];
const TRANSMISSIONS = ['Automatic', 'Standard'];

class TradeInForm extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_VEHICLE_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_VEHICLE_WRITE);
    }

    render() {
        // TODO: Add white background to checkbox No Sales Tax Credit
        const {
            props: {
                classes, tradeIn, onChangeVehicleInfo, onChangeTradeInInfo, engineList, trimList, onSave, saving,
                onCancel,
            },
            SALES_DEAL_VEHICLE_WRITE,
        } = this;
        const engineOptions = (engineList || []).map((item) => ({ value: item, label: item }));
        const trimOptions = (trimList || []).map((item) => ({ value: item, label: item }));
        const titleStatusOptions = (TITLE_STATUSES || []).map((item) => ({ value: item, label: item }));
        const transmissionOptions = (TRANSMISSIONS || []).map((item) => ({ value: item, label: item }));
        const engine = tradeIn?.vehicle?.engine;
        const trim = tradeIn?.vehicle?.trim;
        const titleStatus = tradeIn?.vehicle?.titleStatus;
        const transmission = tradeIn?.vehicle?.transmission;
        const { payOffGoodTill } = tradeIn;
        const isValidData = isValidSchema(TradeInSchema, tradeIn);
        const { isValid, errors } = isValidData;

        return (
            <Paper square className={classes.rootPaperTradeIn}>
                <div className={classes.actions}>
                    <Typography className={classes.title}><span>Trade-in</span></Typography>
                    <div>
                        {SALES_DEAL_VEHICLE_WRITE && (
                            <Button
                                className={classes.textSuccess}
                                size="small"
                                onClick={onSave}
                                startIcon={<SaveOutlinedIcon />}
                                disabled={saving || !isValid}
                            >
                                Save
                            </Button>
                        )}
                        <Button
                            className={classes.textError}
                            size="small"
                            onClick={onCancel}
                            startIcon={<HighlightOffIcon />}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <Grid className={classes.gridRow} container spacing={2}>
                    <Grid item xs={2}>
                        <Form.Label className="required">VIN</Form.Label>
                        <Form.Control
                            className={isValidField(errors, 'vehicle.vin') ? 'invalid-field' : ''}
                            type="text"
                            value={tradeIn.vehicle.vin}
                            onChange={(e) => onChangeVehicleInfo('vin', e.target.value.toUpperCase())}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label className="required">Year</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'vehicle.year') ? 'invalid-field' : ''}
                            value={tradeIn.vehicle.year || ''}
                            onChange={(value) => onChangeVehicleInfo('year', value)}
                            max={9999}
                            decimalScale={0}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label className="required">Make</Form.Label>
                        <Form.Control
                            className={isValidField(errors, 'vehicle.make') ? 'invalid-field' : ''}
                            type="text"
                            value={tradeIn.vehicle.make}
                            onChange={(e) => onChangeVehicleInfo('make', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label className="required">Model</Form.Label>
                        <Form.Control
                            className={isValidField(errors, 'vehicle.model') ? 'invalid-field' : ''}
                            type="text"
                            value={tradeIn.vehicle.model}
                            onChange={(e) => onChangeVehicleInfo('model', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Trim</Form.Label>
                        <Select
                            name="trim"
                            className="select-bootstrap select-sm"
                            options={trimOptions}
                            value={trim}
                            onChange={onChangeVehicleInfo}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Color</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradeIn.vehicle.extColor}
                            onChange={(e) => onChangeVehicleInfo('extColor', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label className="required">Miles</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'vehicle.miles') ? 'invalid-field' : ''}
                            value={tradeIn.vehicle.miles || ''}
                            onChange={(value) => onChangeVehicleInfo('miles', parseInt(value, 10))}
                            decimalScale={0}
                            thousandSeparator
                            size="sm"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        className={classes.checkboxSection}
                    >
                        <Grid className={classes.checkboxHeight} container spacing={1}>
                            <Grid item xs={12}>
                                <Form.Check
                                    label="TMU"
                                    inline
                                    className={classes.checkboxTitle}
                                    type="checkbox"
                                    checked={tradeIn.vehicle.tmu}
                                    onChange={(e) => onChangeVehicleInfo('tmu', e.target.checked)}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.checkboxHeight} container spacing={1}>
                            <Grid item xs={3}>
                                <Form.Check
                                    inline
                                    className={classes.checkboxTitle}
                                    type="checkbox"
                                    checked={tradeIn.vehicle.exempt}
                                    onChange={(e) => onChangeVehicleInfo('exempt', e.target.checked)}
                                    label="Exempt"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Check
                                    inline
                                    className={classes.checkboxTitle}
                                    type="checkbox"
                                    checked={tradeIn.vehicle.eml}
                                    onChange={(e) => onChangeVehicleInfo('eml', e.target.checked)}
                                    label="EML"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.gridRow} container spacing={2}>
                    <Grid item xs={2}>
                        <Form.Label>Engine</Form.Label>
                        <Select
                            name="engine"
                            className="select-bootstrap select-sm"
                            options={engineOptions}
                            value={engine}
                            onChange={onChangeVehicleInfo}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label className="required">Transmission</Form.Label>
                        <Select
                            name="transmission"
                            className={clsx('select-sm', isValidField(errors, 'vehicle.transmission') ? 'invalid-field' : '')}
                            options={transmissionOptions}
                            value={transmission}
                            placeholder="Select"
                            onChange={onChangeVehicleInfo}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Style</Form.Label>
                        <Form.Control
                            value={tradeIn.vehicle.style}
                            onChange={(e) => onChangeVehicleInfo('style', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Drive Train</Form.Label>
                        <Form.Control
                            value={tradeIn.vehicle.drivetrain}
                            onChange={(e) => onChangeVehicleInfo('drivetrain', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Title Status</Form.Label>
                        <Select
                            name="titleStatus"
                            className="select-bootstrap select-sm"
                            options={titleStatusOptions}
                            value={titleStatus}
                            onChange={onChangeVehicleInfo}
                            placeholder="Select"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Title</Form.Label>
                        <Grid container spacing={1}>
                            <Grid item xs={3} md={2} className="d-flex">
                                <Form.Check
                                    inline
                                    className={classes.checkboxTitle}
                                    type="checkbox"
                                    checked={tradeIn.vehicle.title}
                                    onChange={(e) => onChangeVehicleInfo('title', e.target.checked)}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.vehicle.titleNumber}
                                    onChange={(e) => onChangeVehicleInfo('titleNumber', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <Form.Label>Spare Key</Form.Label>
                        <Form.Control
                            type="text"
                            value={tradeIn.vehicle.spareKey}
                            onChange={(e) => onChangeVehicleInfo('spareKey', e.target.value)}
                            size="sm"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper className={classes.tradeInBox}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Form.Label>Allowance</Form.Label>
                                    <InputNumber
                                        required
                                        className={isValidField(errors, 'allowance') ? 'invalid-field' : ''}
                                        value={tradeIn.allowance || ''}
                                        onChange={(value) => onChangeTradeInInfo('allowance', parseFloat(value))}
                                        showCurrency
                                        thousandSeparator
                                        placeholder="0.00"
                                        size="sm"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Form.Label>ACV</Form.Label>
                                    <InputNumber
                                        required
                                        className={isValidField(errors, 'acv') ? 'invalid-field' : ''}
                                        value={tradeIn.acv || ''}
                                        onChange={(value) => onChangeTradeInInfo('acv', parseFloat(value))}
                                        showCurrency
                                        thousandSeparator
                                        placeholder="0.00"
                                        size="sm"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Form.Label>Pay Off</Form.Label>
                                    <InputNumber
                                        required
                                        value={tradeIn.payOff || ''}
                                        onChange={(value) => onChangeTradeInInfo('payOff', parseFloat(value))}
                                        showCurrency
                                        thousandSeparator
                                        placeholder="0.00"
                                        size="sm"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Form.Label className={classes.datePickerLabel}>Pay Off good till</Form.Label>
                                    <DatePicker
                                        className="form-control form-control-sm"
                                        selected={DateUtils.toLocal(payOffGoodTill)?.toDate()}
                                        placeholderText="mm/dd/yyyy"
                                        onChange={(date) => onChangeTradeInInfo('payOffGoodTill', date)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.noSalesTaxCreditStyle}>
                                <Form.Check
                                    inline
                                    className={classes.checkboxTitle}
                                    type="checkbox"
                                    checked={tradeIn.noSalesTaxCredit}
                                    onChange={(e) => onChangeTradeInInfo('noSalesTaxCredit', e.target.checked)}
                                    label="No Sales Tax Credit"
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Form.Label>Lien Holder</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.name}
                                    onChange={(e) => onChangeTradeInInfo('name', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.address}
                                    onChange={(e) => onChangeTradeInInfo('address', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Label>Zip</Form.Label>
                                <InputNumber
                                    value={tradeIn.zip || ''}
                                    onChange={(value) => onChangeTradeInInfo('zip', (value || '').toString())}
                                    decimalScale={0}
                                    max={99999}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.city}
                                    onChange={(e) => onChangeTradeInInfo('city', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Form.Label>County</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.county}
                                    onChange={(e) => onChangeTradeInInfo('county', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.state}
                                    onChange={(e) => onChangeTradeInInfo('state', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.phone}
                                    onChange={(e) => onChangeTradeInInfo('phone', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Form.Label>Note</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={tradeIn.notes}
                                    onChange={(e) => onChangeTradeInInfo('notes', e.target.value)}
                                    size="sm"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

TradeInForm.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    tradeIn: PropTypes.object,
    engineList: PropTypes.arrayOf(PropTypes.string),
    trimList: PropTypes.arrayOf(PropTypes.string),
    onChangeVehicleInfo: PropTypes.func.isRequired,
    onChangeTradeInInfo: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
};

TradeInForm.defaultProps = {
    tradeIn: {},
    engineList: [],
    trimList: [],
};

export default withStyles(styles)(TradeInFormContainer(TradeInForm));

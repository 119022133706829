import DateUtils from 'lib/DateUtils';

export default class PayrollUtils {
    static getYears() {
        const today = new Date();
        const currentYear = today.getFullYear();
        const nextYear = currentYear + 1;
        const previousYear = currentYear - 1;
        const penultimateYear = currentYear - 2;
        return [
            {
                label: nextYear,
                value: nextYear,
            },
            {
                label: currentYear,
                value: currentYear,
            },
            {
                label: previousYear,
                value: previousYear,
            },
            {
                label: penultimateYear,
                value: penultimateYear,
            },
        ];
    }

    static getCheckMappedData(data) {
        const finalResult = {
            lot: '',
            memo: '',
            lotId: 0,
            amount: 0,
            entryId: 0,
            paidTo: '',
            isACH: false,
            paidFrom: '',
            paidFromId: 0,
            checkLines: [],
            checkNumber: 0,
            date: new Date(),
            endingBalance: 0,
            originalAmount: 0,
        };
        if (data) {
            const lines = data.lines.map((element, index) => {
                const line = {
                    entryId: index + 1,
                    memo: element.memo || '',
                    accountNumber: element.accountNumber || 0,
                    controlNumber: element.controlNumber || '',
                    amount: element.debit || element.credit || 0,
                };
                return line;
            });
            finalResult.checkLines = lines;
            finalResult.lot = data.check.lotName;
            finalResult.entryId = lines.length || 0;
            finalResult.memo = data.check.memo || '';
            finalResult.lotId = data.check.lotId || 0;
            finalResult.amount = data.check.amount || 0;
            finalResult.paidTo = data.check.paidTo || '';
            finalResult.isACH = !!data.check.achCheckNumber;
            finalResult.originalAmount = data.check.amount || 0;
            finalResult.paidFromId = data.check.bankAccount || 0;
            finalResult.paidFrom = data.check.accountDescription || '';
            finalResult.date = new Date(DateUtils.getOnlyDate(data.check.date)) || new Date();
            finalResult.checkNumber = data.check.checkNumber || data.check.achCheckNumber || 0;
        }
        return finalResult;
    }

    static getCheckInput(data) {
        const lines = data.checkLines.map((element) => {
            const line = {
                memo: element.memo,
                amount: element.amount,
                accountNumber: element.accountNumber,
                controlNumber: String(element.controlNumber),
            };
            return line;
        });
        return {
            memo: data.memo,
            checkLines: lines,
            lotId: data.lotId,
            amount: data.amount,
            isACH: !!data.isACH,
            paidFromId: data.paidFromId,
            checkNumber: data.checkNumber,
            date: DateUtils.getOnlyDate(data.date),
        };
    }

    static getEmployeeListMappedData(data) {
        return data.map((element) => ({
            id: element.id || 0,
            salary: element.salary || 0,
            lotName: element.lotName || '',
            payType: element.payType || '',
            quickNotes: element.quickNotes || '',
            department: element.department || '',
            loanBalance: element.loanBalance || 0,
            employeeName: element.employeeName || '',
            employeeType: element.employeeType || '',
            pendingFinalPayment: element.pendingFinalPayment || false,
        }));
    }

    static calculateEmployeeLoanPayment({ amount, term }) {
        if (term === 0 || amount === 0) return 0;
        return Math.floor((amount / term) * 100) / 100;
    }

    static getParsedDate({
        dateOnly, hour, minutes, amPm,
    }) {
        const date = new Date(dateOnly?.getTime() || (0, 0));

        date.setUTCHours(PayrollUtils.getParseHour({
            hour, amPm,
        }), minutes, 0, 0);

        return date;
    }

    static getParseHour({ hour, amPm }) {
        if (hour !== 12 && amPm === 'PM') return hour + 12;
        if (hour === 12 && amPm === 'AM') return 0;
        return hour;
    }

    static calculateTotalHours({
        timeInDate, timeInHour, timeInMinute, timeInAmPm,
        timeOutDate, timeOutHour, timeOutMinute, timeOutAmPm,
    }) {
        if (timeInDate === null || timeOutDate === null) return '00:00';
        const startDate = PayrollUtils.getParsedDate({
            dateOnly: timeInDate,
            hour: timeInHour,
            minutes: timeInMinute,
            amPm: timeInAmPm,
        });
        const endDate = PayrollUtils.getParsedDate({
            dateOnly: timeOutDate,
            hour: timeOutHour,
            minutes: timeOutMinute,
            amPm: timeOutAmPm,
        });

        const milliseconds = Math.abs(startDate - endDate);

        const hour = String(Math.floor(milliseconds / 3600000)).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % 3600000) / 60000)).padStart(2, '0');

        return `${hour}:${minutes}`;
    }

    static getHoursAndMinutesFromDecimalHours(hours) {
        const parsedHour = String(Math.trunc(hours)).padStart(2, '0');
        const parsedMinutes = String(Math.abs(Math.round((hours - parsedHour) * 60))).padStart(2, '0');
        return `${parsedHour}:${parsedMinutes}`;
    }
}

import { useReducer, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import { DataSort, FetchPolicy } from 'utils/enum/Core';

const ACTION_TYPES = {
    SET_NEW: 'setNew',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    CLOSE_SCREEN: 'setCloseScreen',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            isEditing: false,
            openTakePayment: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            loading: false,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
            selectedRow: null,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.CLOSE_SCREEN: {
        return {
            ...state,
            selectedRow: null,
            isEditing: false,
            openTakePayment: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default: return state;
    }
};

const usePartList = () => {
    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 25,
            },
            search: null,
            includeBalanced: false,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: null,
        openTakePayment: false,
        loading: true,
    };
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow, loading,
    } = state;

    const {
        data, loading: loadingQuery, error,
    } = useQuery(AccountReceivableQuery.GET_UNPAID_PARTS_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                includeBalanced: params.includeBalanced,
            },
            sort: [{ fieldName: 'ticketNumber', dir: DataSort.DESC }],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loadingQuery) {
            const { records } = table;
            const { getUnpaidPartsTicketList } = data;

            records.push(...getUnpaidPartsTicketList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getUnpaidPartsTicketList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingQuery, error]);

    const onFilterChange = (param, value) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onSetNew = () => {
        dispatch({ type: ACTION_TYPES.SET_NEW });
    };

    const onSelectedRow = (record) => {
        dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: record });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    return {
        loadMore,
        onSearch,
        onFilterChange,
        onSetNew,
        onSelectedRow,
        params,
        table,
        selectedRow,
        loading,
    };
};

export default usePartList;

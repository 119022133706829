import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FormControlMemorize = (props) => {
    const {
        name, value, onChange, onKeyDown, type, ...other
    } = props;
    const { className, ...otherProps } = other;
    delete otherProps.comparePropertyId;

    const handleChange = (event) => {
        onChange(event);
    };

    const handleKeyDown = (event) => {
        onKeyDown(event);
    };

    const formControlType = type === 'textarea' ? 'textarea' : 'input';

    return (
        <Form.Control
            as={formControlType}
            size="sm"
            value={value || ''}
            onChange={(event) => handleChange(event)}
            onKeyDown={handleKeyDown}
            className={clsx(className)}
            {...otherProps}
        />

    );
};

FormControlMemorize.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    type: PropTypes.string,
    comparePropertyId: PropTypes.number,
};

FormControlMemorize.defaultProps = {
    onKeyDown: () => null,
    onChange: () => null,
    type: 'input',
    value: null,
    comparePropertyId: 0,
};

const areEqual = (prevProps, nextProps) => String(prevProps.value ?? '') === String(nextProps.value ?? '')
    && String(prevProps.className ?? '') === String(nextProps.className ?? '')
    && String(prevProps.comparePropertyId ?? '') === String(nextProps.comparePropertyId ?? '');
export default React.memo(FormControlMemorize, areEqual);

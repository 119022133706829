import { PurchaseOrderStatus } from 'utils/enum/PartsPurchaseEnum';

const usePurchaseValidateActions = (status, readOnly) => {
    let editVendor = true;
    let editShippingMethod = true;
    let editEstimateDelivery = true;
    let editOrderDate = true;
    let editDeliveryDate = true;
    let editPartsClerk = true;
    let editSalesTaxRate = true;
    let editNotes = true;
    let editDetails = {
        editDescription: true,
        editQuantity: true,
        editUnitPrice: true,
        allowAdd: true,
        allowDelete: true,
    };
    let editLot = true;
    let allowSaveAction = true;

    if (status.toLowerCase() === PurchaseOrderStatus.VOID.toLowerCase()
    || status.toLowerCase() === PurchaseOrderStatus.RECEIVED.toLowerCase()
    || status.toLowerCase() === PurchaseOrderStatus.RETURNED.toLowerCase()
    || readOnly
    ) {
        editVendor = false;
        editShippingMethod = false;
        editEstimateDelivery = false;
        editOrderDate = false;
        editDeliveryDate = false;
        editPartsClerk = false;
        editSalesTaxRate = false;
        editNotes = false;
        editDetails = {
            editDescription: false,
            editQuantity: false,
            editUnitPrice: false,
            allowAdd: false,
            allowDelete: false,
        };
        editLot = false;
        allowSaveAction = false;
    } else if (status.toLowerCase() === PurchaseOrderStatus.APPROVED.toLowerCase()) {
        editOrderDate = false;
        editDetails = {
            editDescription: false,
            editQuantity: false,
            editUnitPrice: false,
            allowAdd: false,
            allowDelete: false,
        };
    }

    return {
        editVendor,
        editShippingMethod,
        editEstimateDelivery,
        editOrderDate,
        editDeliveryDate,
        editPartsClerk,
        editSalesTaxRate,
        editNotes,
        editDetails,
        editLot,
        allowSaveAction,
    };
};

export default usePurchaseValidateActions;

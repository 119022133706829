import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const PhoneInput = ({
    onChange,
    value,
    placeholder,
    className,
    size,
}) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        setPhoneNumber(value);
    }, [value]);

    const handleChange = (newValue) => {
        const regExp = /^[0-9\b]+$/;
        const char = newValue.substring(newValue.length - 1);

        if (char === '' || char === '-' || regExp.test(char)) {
            let phone = newValue;
            const { length } = phone;

            if (char === '' || char === '-') {
                phone = char === '-' ? phone.substring(0, phone.length - 1) : phone;

                setPhoneNumber(phone);

                return phone;
            }

            if (length === 4 || length === 8) {
                phone = `${phone.substring(0, length - 1)}-${char}`;
            }

            setPhoneNumber(phone);

            return phone;
        }

        return phoneNumber;
    };

    return (
        <div>
            <Form.Control
                className={className}
                type="text"
                size={size}
                placeholder={placeholder}
                value={phoneNumber}
                onChange={({ target }) => onChange(handleChange(target.value))}
                maxLength={12}
            />
        </div>
    );
};

PhoneInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};

PhoneInput.defaultProps = {
    onChange: () => {},
    value: '',
    placeholder: '___-___-____',
    className: '',
    size: 'medium',
};

export default PhoneInput;

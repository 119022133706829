import React, { useEffect, useReducer } from 'react';

// Material UI
import { Grid, makeStyles } from '@material-ui/core';

// Components and Others
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import { useParams } from 'react-router';
import ModalUtils from 'utils/ModalUtils';
import { isEmpty, isFinite } from 'lodash';
import MessageUtils from 'utils/MessageUtils';
import Permissions from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import { CustomerType } from 'utils/enum/DealEnum';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import AddressSection from 'components/widgets/customer/AddressSection';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import Employment from 'components/modules/customer/read/personalTab/Employment';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';
import OpportunitySubscription from 'services/graphQL/subscription/crm/OpportunitySubscription';
import BasicCustomerInformation from 'components/modules/customer/read/BasicCustomerInformation';

const useStyles = makeStyles((theme) => ({
    personalInformation: {
        '& .gridItem': {
            marginBottom: theme.spacing(2),
        },
    },
    boxContainer: {
        background: theme.palette.background.default,
        height: '100%',
        overflow: 'auto',
        width: '100%',
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        overflow: 'auto',
        flexWrap: 'initial',
    },
    updateIcon: {
        height: '18px',
        width: '18px',
        color: theme.palette.text.infoDark,
    },
}));

const ACTION_TYPES = {
    SET_PROSPECT: 'setProspect',
    SET_CO_PROSPECT: 'setCoProspect',
    TOGGLE_PROSPECT: 'toggleProspect',
    TOGGLE_CO_PROSPECT: 'toggleCoProspect',
    ON_OPEN_CUSTOMER_DIALOG: 'onOpenCustomerDialog',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_PROSPECT:
        return {
            ...state,
            prospect: {
                ...action.payload,
            },
        };
    case ACTION_TYPES.SET_CO_PROSPECT:
        return {
            ...state,
            coProspect: {
                ...action.payload,
            },
        };
    case ACTION_TYPES.TOGGLE_PROSPECT:
        return update(state, {
            openProspect: { $set: action.payload },
        });
    case ACTION_TYPES.TOGGLE_CO_PROSPECT:
        return update(state, {
            openCoProspect: { $set: action.payload },
        });
    default:
        return state;
    }
};
const initState = {
    prospect: {},
    coProspect: {},
    openProspect: false,
    openCoProspect: false,
};
const keyStore = new KeyStore();
const ProspectDetail = () => {
    const { id } = useParams();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const CUSTOMER_WRITE = keyStore.hasPermission(Permissions.CUSTOMER_WRITE);
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permissions.CRM_OPPORTUNITY_WRITE);
    const {
        data: prospect, error: errorProspect, loading: loadingProspect,
    } = useQuery(OpportunityQuery.GET_PROSPECT_BY_CMR_ID, { variables: { crmId: id } });
    const {
        data: coProspect, error, loading,
    } = useQuery(OpportunityQuery.GET_CO_PROSPECT_BY_CMR_ID, { variables: { crmId: id } });
    const [updateProspect, { loading: isSavingProspect }] = useMutation(OpportunityMutation.ASSIGN_PROSPECT);
    const [updateCoProspect, { loading: isSavingCoProspect }] = useMutation(OpportunityMutation.ASSIGN_CO_PROSPECT);
    const [unassignCoProspect] = useMutation(OpportunityMutation.UNASSIGN_CO_PROSPECT);
    const { data: subscriptionProspect } = useSubscription(CustomerSubscription.CUSTOMER_EDITED,
        {
            variables: { customerId: state.prospect.customerId },
        });
    const { data: subscriptionCoProspect } = useSubscription(CustomerSubscription.CUSTOMER_EDITED,
        {
            variables: { customerId: state.coProspect.customerId },
            shouldResubscribe: state.coProspect.customerId != null,
        });

    const { data: subscriptionProspectOrCoProspect } = useSubscription(OpportunitySubscription.PROSPECT_OR_CO_PROSPECT_CHANGED,
        {
            variables: { crmId: id },
        });

    useEffect(() => {
        if (errorProspect) {
            ModalUtils.errorMessage(errorProspect?.graphQLErrors);
            return;
        }

        if (!loadingProspect && !isEmpty(prospect?.getProspectByCMRId)) {
            const { getProspectByCMRId } = prospect;
            dispatch({ type: ACTION_TYPES.SET_PROSPECT, payload: getProspectByCMRId });
        }
    }, [prospect, errorProspect, loadingProspect]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(coProspect?.getCoProspectByCMRId)) {
            const { getCoProspectByCMRId } = coProspect;
            dispatch({ type: ACTION_TYPES.SET_CO_PROSPECT, payload: getCoProspectByCMRId });
        }
    }, [coProspect, loading, error]);

    useEffect(() => {
        if (!isEmpty(subscriptionProspect?.customerEdited)) {
            dispatch({ type: ACTION_TYPES.SET_PROSPECT, payload: subscriptionProspect.customerEdited });
        }
    }, [subscriptionProspect]);

    useEffect(() => {
        if (!isEmpty(subscriptionCoProspect?.customerEdited)) {
            dispatch({ type: ACTION_TYPES.SET_CO_PROSPECT, payload: subscriptionCoProspect.customerEdited });
        }
    }, [subscriptionCoProspect]);

    useEffect(() => {
        if (!isEmpty(subscriptionProspectOrCoProspect?.prospectOrCoProspectChanged)) {
            const { prospectOrCoProspectChanged } = subscriptionProspectOrCoProspect;

            if (prospectOrCoProspectChanged.type.toUpperCase() === CustomerType.PROSPECT) {
                dispatch({ type: ACTION_TYPES.SET_PROSPECT, payload: prospectOrCoProspectChanged.prospect });
                return;
            }

            if (prospectOrCoProspectChanged.type.toUpperCase() === CustomerType.CO_PROSPECT) {
                dispatch({ type: ACTION_TYPES.SET_CO_PROSPECT, payload: prospectOrCoProspectChanged.prospect });
            }
        }
    }, [subscriptionProspectOrCoProspect]);

    const onRemoveCoProspect = async () => {
        try {
            const input = {
                crmId: id,
            };

            const response = await unassignCoProspect({ variables: input });

            if (!response.data?.unassignCoProspect) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'prospect'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const toggleProspectDialog = (open) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_PROSPECT,
            payload: open,
        });
    };

    const toggleCoProspectDialog = (open) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_CO_PROSPECT,
            payload: open,
        });
    };

    const onChangeProspect = async (record) => {
        try {
            const input = {
                customerId: record.customerId,
                crmId: id,
            };

            const response = await updateProspect({ variables: input });

            if (response.data?.assignProspect) {
                toggleProspectDialog(false);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'prospect'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onChangeCoProspect = async (record) => {
        try {
            const input = {
                customerId: record.customerId,
                crmId: id,
            };

            const response = await updateCoProspect({ variables: input });

            if (response.data?.assignCoProspect) {
                toggleCoProspectDialog(false);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'co prospect'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    return (
        <div className={classes.boxContainer}>
            <Grid container spacing={2} className={classes.container} alignContent="flex-start">
                <Grid item xs={4} className={classes.column}>
                    <Grid container spacing={0} className={classes.personalInformation}>
                        <Grid item xs={12} className="gridItem">
                            <BasicCustomerInformation
                                showMessageIcon
                                title="Prospect"
                                record={state.prospect}
                                open={state.openProspect}
                                loading={loadingProspect}
                                dialogTitle="Change Prospect"
                                showEditCustomer={CUSTOMER_WRITE}
                                onClickPrimaryDialog={onChangeProspect}
                                hasWritePermission={CRM_OPPORTUNITY_WRITE}
                                disablePrimaryButtonDialog={isSavingProspect}
                                onClickPrimary={() => toggleProspectDialog(true)}
                                onClickSecondaryDialog={() => toggleProspectDialog(false)}
                            />
                        </Grid>
                        <Grid item xs={12} className="gridItem">
                            <AddressSection
                                showNoDataText
                                labelManage="Edit"
                                loading={loadingProspect}
                                addIcon={<AddCircleOutlineIcon />}
                                customerId={state.prospect.customerId}
                                addressInformation={state.prospect.currentAddress}
                                showAddButton={isEmpty(state.prospect.currentAddress)}
                            />
                        </Grid>
                        <Grid item xs={12} className="gridItem">
                            <Employment
                                showNoDataText
                                labelManage="Edit"
                                loading={loadingProspect}
                                addIcon={<AddCircleOutlineIcon />}
                                showAddButton={isEmpty(state.prospect.currentEmployment)}
                                customerId={state.prospect.customerId}
                                record={state.prospect.currentEmployment}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.column} />
                <Grid item xs={4} className={classes.column}>
                    <Grid container spacing={0} className={classes.personalInformation}>
                        <Grid item xs={12} className="gridItem">
                            <BasicCustomerInformation
                                showMessageIcon
                                showDeleteOption
                                loading={loading}
                                title="Co Prospect"
                                record={state.coProspect}
                                open={state.openCoProspect}
                                dialogTitle="Change Co Prospect"
                                showEditCustomer={CUSTOMER_WRITE}
                                onClickRemove={onRemoveCoProspect}
                                onClickPrimaryDialog={onChangeCoProspect}
                                hasWritePermission={CRM_OPPORTUNITY_WRITE}
                                disablePrimaryButtonDialog={isSavingCoProspect}
                                onClickPrimary={() => toggleCoProspectDialog(true)}
                                onClickSecondaryDialog={() => toggleCoProspectDialog(false)}
                            />
                        </Grid>
                        <If condition={isFinite(state.coProspect?.customerCode)}>
                            <Grid item xs={12} className="gridItem">
                                <AddressSection
                                    showNoDataText
                                    labelManage="Edit"
                                    addIcon={<AddCircleOutlineIcon />}
                                    customerId={state.coProspect.customerId}
                                    addressInformation={state.coProspect.currentAddress}
                                    showAddButton={isEmpty(state.coProspect.currentAddress)}
                                />
                            </Grid>
                            <Grid item xs={12} className="gridItem">
                                <Employment
                                    showNoDataText
                                    labelManage="Edit"
                                    addIcon={<AddCircleOutlineIcon />}
                                    showAddButton={isEmpty(state.coProspect.currentEmployment)}
                                    customerId={state.coProspect.customerId}
                                    record={state.coProspect.currentEmployment}
                                />
                            </Grid>
                        </If>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProspectDetail;

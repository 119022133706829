const PayPeriodType = Object.freeze({
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    ANNUALLY: 'Annually',
    QUARTERLY: 'Quarterly',
    BI_WEEKLY: 'Bi-Weekly',
    SEMI_MONTHLY: 'Semi-Monthly',
    SEMI_ANNUALLY: 'Semi-Annually',
});

const PayPeriodStartOnDay = Object.freeze({
    FRIDAY: 'Friday',
    MONDAY: 'Monday',
    SUNDAY: 'Sunday',
    TUESDAY: 'Tuesday',
    SATURDAY: 'Saturday',
    THURSDAY: 'Thursday',
    WEDNESDAY: 'Wednesday',
});

const AdjustmentType = Object.freeze({
    ADDITION: 'Addition',
    DEDUCTION: 'Deduction',
});

const OvertimeRate = Object.freeze({
    1: 1,
    2: 2,
    1.5: 1.5,
    1.25: 1.25,
    1.75: 1.75,
});

const TaxType = Object.freeze({
    PRE_TAX: 'Pre',
    POST_TAX: 'Post',
    STANDARD_TAX: 'Standard',
});

const ReportName = Object.freeze({
    PAYROLL_REPORT: 'PayrollReport',
    ADDITIONS_REPORT: 'PayrollBonusesReport',
    DEDUCTIONS_REPORT: 'PayrollDeductionsReport',
});

const Adjustments = Object.freeze({
    SALES_BONUS: 'Sales Bonus',
});

const LineStatus = Object.freeze({
    ADDED: 'Added',
    EDITED: 'Edited',
    SYSTEM: 'System',
    DELETED: 'Deleted',
    SAVE_ADD: 'Save Add',
    SAVE_EDIT: 'Save Edit',
    SAVE_DELETE: 'Save Delete',
});

const TimeInReasons = Object.freeze({
    START_DAY: 'start day',
    AFTER_LUNCH: 'after lunch',
    AFTER_DOCTOR: 'after doctor',
    AFTER_VACATION: 'after vacation',
});

const TimeOutReasons = Object.freeze({
    LUNCH: 'lunch',
    DOCTOR: 'doctor',
    END_DAY: 'end day',
    OTHER_REASON: 'other reason',
});

const CheckDetailLabels = Object.freeze({
    HOURS: 'Hours:',
    WAGES: 'Wages:',
    SALARY: 'Salary:',
    NET_PAY: 'Net Pay:',
    MEDICARE: 'Medicare:',
    GROSS_PAY: 'Gross Pay:',
    STATE_TAX: 'State Tax:',
    CHECK_NUMBER: 'Check #:',
    HOURLY_PAY: 'Hourly Pay:',
    ANNUAL_SALARY: 'Annual Salary:',
    APPROVED_DRAW: 'Approved Draw:',
    TAXABLE_GROSS: 'Taxable Gross:',
    RATE_PER_HOUR: 'Rate Per Hour:',
    APPROVED_HOURS: 'Approved Hours:',
    SOCIAL_SECURITY: 'Social Security:',
    APPROVED_EARNINGS: 'Approved Earnings',
    PRE_TAX_ADDITIONS: 'Pre-Tax Additions:',
    COMMISSIONS_TOTAL: 'Commissions Total:',
    COMMISSION_BALANCE: 'Commission Balance:',
    PRE_TAX_DEDUCTIONS: 'Pre-Tax Deductions:',
    POST_TAX_ADDITIONS: 'Post-Tax Additions:',
    POST_TAX_DEDUCTIONS: 'Post-Tax Deductions:',
    FEDERAL_WITHHOLDING: 'Federal Withholding:',
    APPROVED_COMMISSIONS: 'Approved Commissions:',
    OVERTIME_RATE_PER_HOUR: 'Overtime Rate Per Hour:',
    APPROVED_OVERTIME_HOURS: 'Approved Overtime Hours:',
});

const PayType = Object.freeze({
    SALARY: 'Salary',
    FLAT_RATE: 'Flat Rate',
    HOURLY_LIMITED: 'Hourly Limited',
    COMMISSION_ONLY: 'Commission Only',
    HOURLY_OVERTIME: 'Hourly Overtime',
    COMMISSION_DRAW: 'Commission + Draw',
    COMMISSION_SALARY: 'Commission + Salary',
    COMMISSION_HOURLY_LIMITED: 'Commission + Hourly Limited',
    COMMISSION_HOURLY_OVERTIME: 'Commission + Hourly Overtime',
    COMMISSION_MINIMUM_WAGE_DRAW: 'Commission + Minimum Wage Draw',
});

const EmailStatus = Object.freeze({
    SENT: 'Sent',
    FAILED: 'Failed',
    SENDING: 'Sending',
    NOT_SENT: 'Not Sent',
});

export {
    PayType,
    TaxType,
    LineStatus,
    ReportName,
    Adjustments,
    EmailStatus,
    OvertimeRate,
    PayPeriodType,
    TimeInReasons,
    AdjustmentType,
    TimeOutReasons,
    CheckDetailLabels,
    PayPeriodStartOnDay,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from 'components/context/UserContext';

import {
    Grid,
    Hidden,
    makeStyles,
    Typography,
} from '@material-ui/core';

import PanelsContainers from 'components/modules/inventory/read/panels/PanelsContainers';
import PricingPanel from 'components/modules/inventory/read/panels/PricingPanel';
import PricingEditPanel from 'components/modules/inventory/create/panels/PricingEditPanel';
import BookValuePanel from 'components/modules/inventory/read/panels/BookValuePanel';
import BookValueEditPanel from 'components/modules/inventory/create/panels/BookValueEditPanel';
import FloorplanPanel from 'components/modules/inventory/read/panels/FloorplanPanel';
import FloorplanEditPanel from 'components/modules/inventory/create/panels/FloorplanEditPanel';
import AdvertisingSetupPanel from 'components/modules/inventory/read/panels/AdvertisingSetupPanel';
import AdvertisingSetupEditPanel from 'components/modules/inventory/create/panels/AdvertisingSetupEditPanel';
import CustomFieldsPanel from 'components/modules/inventory/read/panels/CustomFieldsPanel';
import CustomFieldsEditPanel from 'components/modules/inventory/create/panels/CustomFieldsEditPanel';
import PurchasePanel from 'components/modules/inventory/read/panels/PurchasePanel';
import VehicleInteractions from 'components/modules/inventory/read/panels/VehicleInteractions';
import SubscriptionPanel from 'components/modules/inventory/read/panels/SubscriptionPanel';

import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { InventoryPanels } from 'utils/enum/InventoryEnum';

const useStyles = makeStyles((theme) => ({
    tabContent: {
        background: theme.palette.background.white,
        flex: '1',
        flexWrap: 'wrap',
        padding: '10px 0',
        '& > div': {
            overflowX: 'auto',
            minWidth: '1150px',
            [theme.breakpoints.down('md')]: {
                overflowX: 'initial',
                minWidth: 'initial',
            },
        },
    },
    detailTab: {
        display: 'flex',
        padding: 10,
        '& > div': {
            flex: 1,
        },
    },
    tabTitle: {
        padding: 10,
    },
}));

const DetailTab = ({
    stockNumber,
    record,
    setVehicleDetail,
    setEditedSection,
    defaultReconCost,
    onReload,
    blockingStatus,
    currentUserId,
    changeBlockingStatus,
}) => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);

    const pricing = record.detail?.pricing || {};
    const bookValue = record.detail?.bookValue || {};
    const floorPlan = record.detail?.floorPlan || {};
    const advertising = record.detail?.advertising || {};
    const editedSection = record.editedSection || '';
    const detail = record.detail || {};
    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_COST_READ = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_COST_READ);

    return (
        <div className={classes.tabContent}>
            <Hidden mdUp>
                <div className={classes.tabTitle}>
                    <Typography variant="h3" gutterBottom>
                        Details
                    </Typography>
                </div>
            </Hidden>
            <Grid container alignItems="stretch">
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        onReload={onReload}
                        title={InventoryPanels.PRICING}
                        data={pricing}
                        stockNumber={stockNumber}
                        setVehicleDetail={setVehicleDetail}
                        editedSection={editedSection}
                        setEditedSection={setEditedSection}
                        allowEdit
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    >
                        <PricingPanel
                            readOnly
                            costPermission={INVENTORY_VEHICLE_COST_READ}
                            employeeType={userInformation?.employeeType}
                            defaultReconCost={defaultReconCost}
                        />
                        <PricingEditPanel
                            readOnly={false}
                            costPermission={INVENTORY_VEHICLE_COST_READ}
                            employeeType={userInformation?.employeeType}
                            defaultReconCost={defaultReconCost}
                        />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        title={InventoryPanels.PURCHASE_INFO}
                        stockNumber={stockNumber}
                        data={detail}
                    >
                        <PurchasePanel readOnly />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        onReload={onReload}
                        title={InventoryPanels.BOOK_VALUE}
                        data={bookValue}
                        stockNumber={stockNumber}
                        setVehicleDetail={setVehicleDetail}
                        editedSection={editedSection}
                        setEditedSection={setEditedSection}
                        allowEdit
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    >
                        <BookValuePanel readOnly />
                        <BookValueEditPanel readOnly={false} />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        onReload={onReload}
                        title={InventoryPanels.ADVERTISING_SETUP}
                        data={advertising}
                        stockNumber={stockNumber}
                        setVehicleDetail={setVehicleDetail}
                        editedSection={editedSection}
                        setEditedSection={setEditedSection}
                        allowEdit
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    >
                        <AdvertisingSetupPanel readOnly />
                        <AdvertisingSetupEditPanel readOnly={false} />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        onReload={onReload}
                        title={InventoryPanels.FLOORPLAN}
                        data={floorPlan}
                        stockNumber={stockNumber}
                        setVehicleDetail={setVehicleDetail}
                        editedSection={editedSection}
                        setEditedSection={setEditedSection}
                        allowEdit
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    >
                        <FloorplanPanel readOnly />
                        <FloorplanEditPanel readOnly={false} />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        title={InventoryPanels.INTERACTIONS}
                        stockNumber={stockNumber}
                    >
                        <VehicleInteractions stockNumber={stockNumber} lotname={record.detail?.lotName ?? ''} readOnly />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        title={InventoryPanels.DEPRECIATION}
                        stockNumber={stockNumber}
                    >
                        <SubscriptionPanel stockNumber={stockNumber} readOnly />
                    </PanelsContainers>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PanelsContainers
                        onReload={onReload}
                        title={InventoryPanels.CUSTOM_FIELDS}
                        stockNumber={stockNumber}
                        editedSection={editedSection}
                        setEditedSection={setEditedSection}
                        allowEdit
                        blockingStatus={blockingStatus}
                        currentUserId={currentUserId}
                        changeBlockingStatus={changeBlockingStatus}
                    >
                        <CustomFieldsPanel readOnly />
                        <CustomFieldsEditPanel readOnly={false} />
                    </PanelsContainers>
                </Grid>
            </Grid>
        </div>
    );
};

DetailTab.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    setVehicleDetail: PropTypes.func.isRequired,
    setEditedSection: PropTypes.func.isRequired,
    defaultReconCost: PropTypes.number,
    onReload: PropTypes.func.isRequired,
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    changeBlockingStatus: PropTypes.func,
};

DetailTab.defaultProps = {
    defaultReconCost: 0,
    blockingStatus: null,
    currentUserId: 0,
    changeBlockingStatus: () => null,
};

export default DetailTab;

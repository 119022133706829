export default class PortfolioUtils {
    static getPortfolioOwnerCompanies() {
        return [
            { text: 'AmeriFinance Repos' },
            { text: 'AmeriFinance, Inc.' },
            { text: 'AMF Account Servicing, LLC.' },
            { text: 'AMF Auto Leasing, LLC.' },
            { text: 'AMF Funding, LLC.' },
        ];
    }

    static getPortfolioAccountingClasses() {
        return [
            { text: 'Florida - Florida' },
            { text: 'Texas - Texas' },
        ];
    }

    static getPortfolioTypes() {
        return [
            { text: 'Flow Pool' },
            { text: 'Bulk Sale' },
            { text: 'Spec Pool' },
        ];
    }

    static getPortfolioBooleanOptions() {
        return [{ text: 'No' }, { text: 'Yes' }];
    }
}

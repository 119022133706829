import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import { withRouter } from 'react-router-dom';
import VendorSettingsListContainer from 'components/containers/settings/vendors/VendorSettingsListContainer';
import VendorSettingsDialog from 'components/modules/settings/general/vendors/VendorSettingsDialog';
import { Form } from 'react-bootstrap';
import InputSearch from 'components/widgets/InputSearch';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ProductSettingsStyles from 'styles/modules/settings/ProductSettingsStyles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => ProductSettingsStyles.listStyles(theme);

class VendorSettingsList extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SETTINGS_GENERAL_WRITE = keyStore.hasPermission(Permission.SETTINGS_GENERAL_WRITE);
    }

    getColumns() {
        const { props: { classes } } = this;

        return [{
            Header: 'Vendor Name',
            id: 'vendorName',
            minWidth: 150,
            accessor: 'vendorName',
            className: classes.columnStyle,
        }, {
            Header: 'Type',
            id: 'vendorType',
            minWidth: 150,
            accessor: 'vendorType',
            className: classes.columnStyle,
        }, {
            Header: 'Provider',
            id: 'provider',
            minWidth: 80,
            accessor: 'provider',
            className: classes.columnStyle,
        }, {
            Header: 'Address',
            id: 'vendorAddress',
            minWidth: 100,
            accessor: 'vendorAddress',
            className: classes.columnStyle,
        }, {
            Header: 'City',
            id: 'vendorCity',
            minWidth: 100,
            accessor: 'vendorCity',
            className: classes.columnStyle,
        }, {
            Header: 'State',
            id: 'vendorState',
            minWidth: 100,
            accessor: 'vendorState',
            className: classes.columnStyle,
        }];
    }

    render() {
        const {
            props: {
                classes, openDialog, openVendorDialog, closeVendorDialog,
                isEditingVendor, onSave, selectedVendorId, onSearch,
                tableContent, setSelectedVendorId, onChangeVendorStatus,
                searchTerm, vendorStatus, loadMoreData, onSortedChange, isMobile,
            },
        } = this;
        const disableEditAndRemove = selectedVendorId === null;

        return (
            <div className={classes.root}>
                {isMobile && (
                    <div>
                        <div className={classes.header}>
                            {this.SETTINGS_GENERAL_WRITE && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={clsx(classes.button, classes.newButton)}
                                    startIcon={<AddIcon />}
                                    onClick={() => openVendorDialog(false)}
                                >
                                    New
                                </Button>
                            )}
                            {this.SETTINGS_GENERAL_WRITE && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={clsx(classes.button, classes.editButton)}
                                    startIcon={<BorderColorOutlinedIcon />}
                                    disabled={disableEditAndRemove}
                                    onClick={() => openVendorDialog(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                        <div className={classes.header}>
                            <InputSearch
                                size="sm"
                                forceSearch
                                customClasses={classes.search}
                                onSearch={onSearch}
                                initialSearch={searchTerm}
                            />
                        </div>
                        <div className={classes.header}>
                            <div className="d-flex-center">
                                <Form.Group
                                    className="form-check form-check-inline mb-0"
                                >
                                    <Form.Check
                                        key="radioActive"
                                        type="radio"
                                        name="radio"
                                        id="radioActive"
                                        label="Active"
                                        value={vendorStatus}
                                        checked={vendorStatus}
                                        onChange={() => onChangeVendorStatus(true)}
                                    />
                                    <Form.Check
                                        key="radioInactive"
                                        type="radio"
                                        name="radio"
                                        id="radioInactive"
                                        label="In-Active"
                                        value={!vendorStatus}
                                        checked={!vendorStatus}
                                        onChange={() => onChangeVendorStatus(false)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                )}

                {!isMobile && (
                    <div className={classes.header}>
                        {this.SETTINGS_GENERAL_WRITE && (
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.newButton)}
                                startIcon={<AddIcon />}
                                onClick={() => openVendorDialog(false)}
                            >
                                New
                            </Button>
                        )}
                        {this.SETTINGS_GENERAL_WRITE && (
                            <Button
                                size="small"
                                variant="contained"
                                className={clsx(classes.button, classes.editButton)}
                                startIcon={<BorderColorOutlinedIcon />}
                                disabled={disableEditAndRemove}
                                onClick={() => openVendorDialog(true)}
                            >
                                Edit
                            </Button>
                        )}
                        <InputSearch
                            size="sm"
                            forceSearch
                            customClasses={classes.search}
                            onSearch={onSearch}
                            initialSearch={searchTerm}
                        />
                        <div className="d-flex-center">
                            <Form.Group
                                className="form-check form-check-inline mb-0"
                            >
                                <Form.Check
                                    key="radioActive"
                                    type="radio"
                                    name="radio"
                                    id="radioActive"
                                    label="Active"
                                    value={vendorStatus}
                                    checked={vendorStatus}
                                    onChange={() => onChangeVendorStatus(true)}
                                />
                                <Form.Check
                                    key="radioInactive"
                                    type="radio"
                                    name="radio"
                                    id="radioInactive"
                                    label="In-Active"
                                    value={!vendorStatus}
                                    checked={!vendorStatus}
                                    onChange={() => onChangeVendorStatus(false)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                )}

                <div className={classes.tableHeight}>
                    <Table
                        rowSelected
                        load={tableContent.loading}
                        totalRecords={tableContent.tableLength}
                        sortable={tableContent.sortable}
                        data={tableContent.records}
                        columns={this.getColumns()}
                        unselectRow={disableEditAndRemove}
                        loadMore={loadMoreData}
                        sortedChange={onSortedChange}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onClick: () => {
                                    setSelectedVendorId(record.vendorId);
                                },
                            };
                        }}
                    />
                </div>
                {openDialog
                    && (
                        <VendorSettingsDialog
                            open={openDialog}
                            isEditing={isEditingVendor}
                            onClose={closeVendorDialog}
                            vendorId={selectedVendorId}
                            onSave={onSave}
                        />
                    )}
            </div>
        );
    }
}

VendorSettingsList.propTypes = {
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    openVendorDialog: PropTypes.func.isRequired,
    closeVendorDialog: PropTypes.func.isRequired,
    setSelectedVendorId: PropTypes.func.isRequired,
    isEditingVendor: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedVendorId: PropTypes.number,
    onSearch: PropTypes.func.isRequired,
    onChangeVendorStatus: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    vendorStatus: PropTypes.bool.isRequired,
    tableContent: PropTypes.object.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    onSortedChange: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

VendorSettingsList.defaultProps = {
    selectedVendorId: null,
};

export default withRouter(withStyles(styles)(VendorSettingsListContainer(VendorSettingsList)));

import { filter, size } from 'lodash';

export default class CountryUtils {
    static getCountry() {
        return [
            {
                value: 'ABW',
                label: 'Aruba',
            },
            {
                value: 'AFG',
                label: 'Afghanistan',
            },
            {
                value: 'AGO',
                label: 'Angola',
            },
            {
                value: 'AIA',
                label: 'Anguilla',
            },
            {
                value: 'ALA',
                label: 'Åland Islands',
            },
            {
                value: 'ALB',
                label: 'Albania',
            },
            {
                value: 'AND',
                label: 'Andorra',
            },
            {
                value: 'ARE',
                label: 'United Arab Emirates',
            },
            {
                value: 'ARG',
                label: 'Argentina',
            },
            {
                value: 'ARM',
                label: 'Armenia',
            },
            {
                value: 'ASM',
                label: 'American Samoa',
            },
            {
                value: 'ATA',
                label: 'Antarctica',
            },
            {
                value: 'ATF',
                label: 'French Southern Territories',
            },
            {
                value: 'ATG',
                label: 'Antigua and Barbuda',
            },
            {
                value: 'AUS',
                label: 'Australia',
            },
            {
                value: 'AUT',
                label: 'Austria',
            },
            {
                value: 'AZE',
                label: 'Azerbaijan',
            },
            {
                value: 'BDI',
                label: 'Burundi',
            },
            {
                value: 'BEL',
                label: 'Belgium',
            },
            {
                value: 'BEN',
                label: 'Benin',
            },
            {
                value: 'BES',
                label: 'Bonaire, Sint Eustatius and Saba',
            },
            {
                value: 'BFA',
                label: 'Burkina Faso',
            },
            {
                value: 'BGD',
                label: 'Bangladesh',
            },
            {
                value: 'BGR',
                label: 'Bulgaria',
            },
            {
                value: 'BHR',
                label: 'Bahrain',
            },
            {
                value: 'BHS',
                label: 'Bahamas',
            },
            {
                value: 'BIH',
                label: 'Bosnia and Herzegovina',
            },
            {
                value: 'BLM',
                label: 'Saint Barthélemy',
            },
            {
                value: 'BLR',
                label: 'Belarus',
            },
            {
                value: 'BLZ',
                label: 'Belize',
            },
            {
                value: 'BMU',
                label: 'Bermuda',
            },
            {
                value: 'BOL',
                label: 'Bolivia (Plurinational State of)',
            },
            {
                value: 'BRA',
                label: 'Brazil',
            },
            {
                value: 'BRB',
                label: 'Barbados',
            },
            {
                value: 'BRN',
                label: 'Brunei Darussalam',
            },
            {
                value: 'BTN',
                label: 'Bhutan',
            },
            {
                value: 'BVT',
                label: 'Bouvet Island',
            },
            {
                value: 'BWA',
                label: 'Botswana',
            },
            {
                value: 'CAF',
                label: 'Central African Republic',
            },
            {
                value: 'CAN',
                label: 'Canada',
            },
            {
                value: 'CCK',
                label: 'Cocos (Keeling) Islands',
            },
            {
                value: 'CHE',
                label: 'Switzerland',
            },
            {
                value: 'CHL',
                label: 'Chile',
            },
            {
                value: 'CHN',
                label: 'China',
            },
            {
                value: 'CMR',
                label: 'Cameroon',
            },
            {
                value: 'COD',
                label: 'Congo, Democratic Republic of the',
            },
            {
                value: 'COG',
                label: 'Congo',
            },
            {
                value: 'COK',
                label: 'Cook Islands',
            },
            {
                value: 'COL',
                label: 'Colombia',
            },
            {
                value: 'COM',
                label: 'Comoros',
            },
            {
                value: 'CPV',
                label: 'Cabo Verde',
            },
            {
                value: 'CRI',
                label: 'Costa Rica',
            },
            {
                value: 'CUB',
                label: 'Cuba',
            },
            {
                value: 'CUW',
                label: 'Curaçao',
            },
            {
                value: 'CXR',
                label: 'Christmas Island',
            },
            {
                value: 'CYM',
                label: 'Cayman Islands',
            },
            {
                value: 'CYP',
                label: 'Cyprus',
            },
            {
                value: 'CZE',
                label: 'Czechia',
            },
            {
                value: 'DEU',
                label: 'Germany',
            },
            {
                value: 'DJI',
                label: 'Djibouti',
            },
            {
                value: 'DMA',
                label: 'Dominica',
            },
            {
                value: 'DNK',
                label: 'Denmark',
            },
            {
                value: 'DOM',
                label: 'Dominican Republic',
            },
            {
                value: 'DZA',
                label: 'Algeria',
            },
            {
                value: 'ECU',
                label: 'Ecuador',
            },
            {
                value: 'EGY',
                label: 'Egypt',
            },
            {
                value: 'ERI',
                label: 'Eritrea',
            },
            {
                value: 'ESH',
                label: 'Western Sahara',
            },
            {
                value: 'ESP',
                label: 'Spain',
            },
            {
                value: 'EST',
                label: 'Estonia',
            },
            {
                value: 'ETH',
                label: 'Ethiopia',
            },
            {
                value: 'FIN',
                label: 'Finland',
            },
            {
                value: 'FJI',
                label: 'Fiji',
            },
            {
                value: 'FLK',
                label: 'Falkland Islands (Malvinas)',
            },
            {
                value: 'FRA',
                label: 'France',
            },
            {
                value: 'FRO',
                label: 'Faroe Islands',
            },
            {
                value: 'FSM',
                label: 'Micronesia (Federated States of)',
            },
            {
                value: 'GAB',
                label: 'Gabon',
            },
            {
                value: 'GBR',
                label: 'United Kingdom of Great Britain and Northern Ireland',
            },
            {
                value: 'GEO',
                label: 'Georgia',
            },
            {
                value: 'GGY',
                label: 'Guernsey',
            },
            {
                value: 'GHA',
                label: 'Ghana',
            },
            {
                value: 'GIB',
                label: 'Gibraltar',
            },
            {
                value: 'GIN',
                label: 'Guinea',
            },
            {
                value: 'GLP',
                label: 'Guadeloupe',
            },
            {
                value: 'GMB',
                label: 'Gambia',
            },
            {
                value: 'GNB',
                label: 'Guinea-Bissau',
            },
            {
                value: 'GNQ',
                label: 'Equatorial Guinea',
            },
            {
                value: 'GRC',
                label: 'Greece',
            },
            {
                value: 'GRD',
                label: 'Grenada',
            },
            {
                value: 'GRL',
                label: 'Greenland',
            },
            {
                value: 'GTM',
                label: 'Guatemala',
            },
            {
                value: 'GUF',
                label: 'French Guiana',
            },
            {
                value: 'GUM',
                label: 'Guam',
            },
            {
                value: 'GUY',
                label: 'Guyana',
            },
            {
                value: 'HKG',
                label: 'Hong Kong',
            },
            {
                value: 'HMD',
                label: 'Heard Island and McDonald Islands',
            },
            {
                value: 'HND',
                label: 'Honduras',
            },
            {
                value: 'HRV',
                label: 'Croatia',
            },
            {
                value: 'HTI',
                label: 'Haiti',
            },
            {
                value: 'HUN',
                label: 'Hungary',
            },
            {
                value: 'IDN',
                label: 'Indonesia',
            },
            {
                value: 'IMN',
                label: 'Isle of Man',
            },
            {
                value: 'IND',
                label: 'India',
            },
            {
                value: 'IOT',
                label: 'British Indian Ocean Territory',
            },
            {
                value: 'IRL',
                label: 'Ireland',
            },
            {
                value: 'IRN',
                label: 'Iran (Islamic Republic of)',
            },
            {
                value: 'IRQ',
                label: 'Iraq',
            },
            {
                value: 'ISL',
                label: 'Iceland',
            },
            {
                value: 'ISR',
                label: 'Israel',
            },
            {
                value: 'ITA',
                label: 'Italy',
            },
            {
                value: 'JAM',
                label: 'Jamaica',
            },
            {
                value: 'JEY',
                label: 'Jersey',
            },
            {
                value: 'JOR',
                label: 'Jordan',
            },
            {
                value: 'JPN',
                label: 'Japan',
            },
            {
                value: 'KAZ',
                label: 'Kazakhstan',
            },
            {
                value: 'KEN',
                label: 'Kenya',
            },
            {
                value: 'KGZ',
                label: 'Kyrgyzstan',
            },
            {
                value: 'KHM',
                label: 'Cambodia',
            },
            {
                value: 'KIR',
                label: 'Kiribati',
            },
            {
                value: 'KNA',
                label: 'Saint Kitts and Nevis',
            },
            {
                value: 'KOR',
                label: 'Korea, Republic of',
            },
            {
                value: 'KWT',
                label: 'Kuwait',
            },
            {
                value: 'LBN',
                label: 'Lebanon',
            },
            {
                value: 'LBR',
                label: 'Liberia',
            },
            {
                value: 'LBY',
                label: 'Libya',
            },
            {
                value: 'LCA',
                label: 'Saint Lucia',
            },
            {
                value: 'LIE',
                label: 'Liechtenstein',
            },
            {
                value: 'LKA',
                label: 'Sri Lanka',
            },
            {
                value: 'LSO',
                label: 'Lesotho',
            },
            {
                value: 'LTU',
                label: 'Lithuania',
            },
            {
                value: 'LUX',
                label: 'Luxembourg',
            },
            {
                value: 'LVA',
                label: 'Latvia',
            },
            {
                value: 'MAC',
                label: 'Macao',
            },
            {
                value: 'MAF',
                label: 'Saint Martin (French part)',
            },
            {
                value: 'MAR',
                label: 'Morocco',
            },
            {
                value: 'MCO',
                label: 'Monaco',
            },
            {
                value: 'MDA',
                label: 'Moldova, Republic of',
            },
            {
                value: 'MDG',
                label: 'Madagascar',
            },
            {
                value: 'MDV',
                label: 'Maldives',
            },
            {
                value: 'MEX',
                label: 'Mexico',
            },
            {
                value: 'MHL',
                label: 'Marshall Islands',
            },
            {
                value: 'MKD',
                label: 'North Macedonia',
            },
            {
                value: 'MLI',
                label: 'Mali',
            },
            {
                value: 'MLT',
                label: 'Malta',
            },
            {
                value: 'MMR',
                label: 'Myanmar',
            },
            {
                value: 'MNE',
                label: 'Montenegro',
            },
            {
                value: 'MNG',
                label: 'Mongolia',
            },
            {
                value: 'MNP',
                label: 'Northern Mariana Islands',
            },
            {
                value: 'MOZ',
                label: 'Mozambique',
            },
            {
                value: 'MRT',
                label: 'Mauritania',
            },
            {
                value: 'MSR',
                label: 'Montserrat',
            },
            {
                value: 'MTQ',
                label: 'Martinique',
            },
            {
                value: 'MUS',
                label: 'Mauritius',
            },
            {
                value: 'MWI',
                label: 'Malawi',
            },
            {
                value: 'MYS',
                label: 'Malaysia',
            },
            {
                value: 'MYT',
                label: 'Mayotte',
            },
            {
                value: 'NAM',
                label: 'Namibia',
            },
            {
                value: 'NCL',
                label: 'New Caledonia',
            },
            {
                value: 'NER',
                label: 'Niger',
            },
            {
                value: 'NFK',
                label: 'Norfolk Island',
            },
            {
                value: 'NGA',
                label: 'Nigeria',
            },
            {
                value: 'NIC',
                label: 'Nicaragua',
            },
            {
                value: 'NIU',
                label: 'Niue',
            },
            {
                value: 'NLD',
                label: 'Netherlands',
            },
            {
                value: 'NOR',
                label: 'Norway',
            },
            {
                value: 'NPL',
                label: 'Nepal',
            },
            {
                value: 'NRU',
                label: 'Nauru',
            },
            {
                value: 'NZL',
                label: 'New Zealand',
            },
            {
                value: 'OMN',
                label: 'Oman',
            },
            {
                value: 'PAK',
                label: 'Pakistan',
            },
            {
                value: 'PAN',
                label: 'Panama',
            },
            {
                value: 'PCN',
                label: 'Pitcairn',
            },
            {
                value: 'PER',
                label: 'Peru',
            },
            {
                value: 'PHL',
                label: 'Philippines',
            },
            {
                value: 'PLW',
                label: 'Palau',
            },
            {
                value: 'PNG',
                label: 'Papua New Guinea',
            },
            {
                value: 'POL',
                label: 'Poland',
            },
            {
                value: 'PRI',
                label: 'Puerto Rico',
            },
            {
                value: 'PRT',
                label: 'Portugal',
            },
            {
                value: 'PRY',
                label: 'Paraguay',
            },
            {
                value: 'PSE',
                label: 'Palestine, State of',
            },
            {
                value: 'PYF',
                label: 'French Polynesia',
            },
            {
                value: 'QAT',
                label: 'Qatar',
            },
            {
                value: 'REU',
                label: 'Réunion',
            },
            {
                value: 'ROU',
                label: 'Romania',
            },
            {
                value: 'RUS',
                label: 'Russian Federation',
            },
            {
                value: 'RWA',
                label: 'Rwanda',
            },
            {
                value: 'SAU',
                label: 'Saudi Arabia',
            },
            {
                value: 'SDN',
                label: 'Sudan',
            },
            {
                value: 'SEN',
                label: 'Senegal',
            },
            {
                value: 'SGP',
                label: 'Singapore',
            },
            {
                value: 'SGS',
                label: 'South Georgia and the South Sandwich Islands',
            },
            {
                value: 'SHN',
                label: 'Saint Helena, Ascension and Tristan da Cunha',
            },
            {
                value: 'SJM',
                label: 'Svalbard and Jan Mayen',
            },
            {
                value: 'SLB',
                label: 'Solomon Islands',
            },
            {
                value: 'SLE',
                label: 'Sierra Leone',
            },
            {
                value: 'SLV',
                label: 'El Salvador',
            },
            {
                value: 'SMR',
                label: 'San Marino',
            },
            {
                value: 'SOM',
                label: 'Somalia',
            },
            {
                value: 'SPM',
                label: 'Saint Pierre and Miquelon',
            },
            {
                value: 'SRB',
                label: 'Serbia',
            },
            {
                value: 'SSD',
                label: 'South Sudan',
            },
            {
                value: 'STP',
                label: 'Sao Tome and Principe',
            },
            {
                value: 'SUR',
                label: 'Suriname',
            },
            {
                value: 'SVK',
                label: 'Slovakia',
            },
            {
                value: 'SVN',
                label: 'Slovenia',
            },
            {
                value: 'SWE',
                label: 'Sweden',
            },
            {
                value: 'SWZ',
                label: 'Eswatini',
            },
            {
                value: 'SXM',
                label: 'Sint Maarten (Dutch part)',
            },
            {
                value: 'SYC',
                label: 'Seychelles',
            },
            {
                value: 'SYR',
                label: 'Syrian Arab Republic',
            },
            {
                value: 'TCA',
                label: 'Turks and Caicos Islands',
            },
            {
                value: 'TCD',
                label: 'Chad',
            },
            {
                value: 'TGO',
                label: 'Togo',
            },
            {
                value: 'THA',
                label: 'Thailand',
            },
            {
                value: 'TJK',
                label: 'Tajikistan',
            },
            {
                value: 'TKL',
                label: 'Tokelau',
            },
            {
                value: 'TKM',
                label: 'Turkmenistan',
            },
            {
                value: 'TLS',
                label: 'Timor-Leste',
            },
            {
                value: 'TON',
                label: 'Tonga',
            },
            {
                value: 'TTO',
                label: 'Trinidad and Tobago',
            },
            {
                value: 'TUN',
                label: 'Tunisia',
            },
            {
                value: 'TUR',
                label: 'Turkey',
            },
            {
                value: 'TUV',
                label: 'Tuvalu',
            },
            {
                value: 'TWN',
                label: 'Taiwan, Province of China',
            },
            {
                value: 'TZA',
                label: 'Tanzania, United Republic of',
            },
            {
                value: 'UGA',
                label: 'Uganda',
            },
            {
                value: 'UKR',
                label: 'Ukraine',
            },
            {
                value: 'UMI',
                label: 'United States Minor Outlying Islands',
            },
            {
                value: 'URY',
                label: 'Uruguay',
            },
            {
                value: 'USA',
                label: 'United States',
            },
            {
                value: 'UZB',
                label: 'Uzbekistan',
            },
            {
                value: 'VAT',
                label: 'Holy See',
            },
            {
                value: 'VCT',
                label: 'Saint Vincent and the Grenadines',
            },
            {
                value: 'VEN',
                label: 'Venezuela (Bolivarian Republic of)',
            },
            {
                value: 'VGB',
                label: 'Virgin Islands (British)',
            },
            {
                value: 'VIR',
                label: 'Virgin Islands (U.S.)',
            },
            {
                value: 'VNM',
                label: 'Viet Nam',
            },
            {
                value: 'VUT',
                label: 'Vanuatu',
            },
            {
                value: 'WLF',
                label: 'Wallis and Futuna',
            },
            {
                value: 'WSM',
                label: 'Samoa',
            },
            {
                value: 'YEM',
                label: 'Yemen',
            },
            {
                value: 'ZAF',
                label: 'South Africa',
            },
            {
                value: 'ZMB',
                label: 'Zambia',
            },
            {
                value: 'ZWE',
                label: 'Zimbabwe',
            },
        ];
    }

    // TODO: If we change the select we remove this
    static getCountryByValue(value) {
        const listCountry = this.getCountry();
        const currentCountry = filter(listCountry, { value });

        return size(currentCountry) ? currentCountry[0] : '';
    }
}

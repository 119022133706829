import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
} from '@material-ui/core';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import ButtonStyles from 'styles/theme/Button';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';
import { WARRANTY_VALUES, FORM_FIELD_NAMES } from 'utils/enum/WindowStickersEnum';
import WindowStickersQuery from 'services/graphQL/query/WindowStickersQuery';

const useStyles = makeStyles((theme) => InventoryListStyle.warrantiesDialog(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const FIELDS_NUMBER = 9;
const INIT_STATE = {
    displayTemplateNameDialog: false,
    templateName: '',
    availableWarranties: [],
    isSaving: false,
    templateNameIndex: -1,
    isWarranty: WARRANTY_VALUES.IS,
    isFull: false,
    isLimited: false,
    laborRate: 0,
    partsRate: 0,
    isServiceContract: false,
    durationValues: new Array(FIELDS_NUMBER),
    systemCoveredValues: new Array(FIELDS_NUMBER),
};

const WarrantyTemplatesDialog = ({
    onClose,
    onSave,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState(INIT_STATE);
    const {
        displayTemplateNameDialog,
        availableWarranties,
        isSaving,
        templateName,
        templateNameIndex,
        isWarranty,
        isFull,
        isLimited,
        laborRate,
        partsRate,
        isServiceContract,
        durationValues,
        systemCoveredValues,
    } = state;

    const {
        data: warrantiesData,
        loading: warrantiesLoading,
        error: warrantiesError,
    } = useQuery(WindowStickersQuery.GET_WARRANTIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (warrantiesError) {
            ModalUtils.errorMessage(warrantiesError?.graphQLErrors);
            return;
        }

        if (!warrantiesLoading) {
            const { getWarranties } = warrantiesData;

            const stateClone = { ...state };
            stateClone.availableWarranties = getWarranties;
            setState(stateClone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warrantiesLoading, warrantiesError]);

    const onChangeTemplate = ({ target: { value: templateId } }) => {
        const stateClone = { ...INIT_STATE };
        stateClone.availableWarranties = state.availableWarranties;
        stateClone.isSaving = state.isSaving;

        if (templateId === -1) {
            setState(stateClone);
            return;
        }

        const templateIndex = availableWarranties.findIndex(
            (warranty) => warranty.id === templateId,
        );

        if (templateIndex === -1) return;
        const warranty = availableWarranties[templateIndex];
        stateClone.templateNameIndex = warranty.id;
        stateClone.isWarranty = warranty.asIs ? WARRANTY_VALUES.AS_IS_NO : WARRANTY_VALUES.IS;
        stateClone.isFull = warranty.fullWarranty;
        stateClone.isLimited = warranty.limitedWarranty;
        stateClone.laborRate = warranty.percentLabor;
        stateClone.partsRate = warranty.percentParts;
        stateClone.isServiceContract = warranty.serviceContract;

        const range = Array.from({ length: FIELDS_NUMBER }, (_, i) => i);
        stateClone.durationValues = range.map((index) => warranty[`duration${index}`]);
        stateClone.systemCoveredValues = range.map((index) => warranty[`systemsCovered${index}`]);
        setState(stateClone);
    };

    const onChangeField = (field, value) => {
        const {
            IS_FULL,
            IS_WARRANTY,
            IS_LIMITED,
            IS_LABOR_RATE,
            IS_PARTS_RATE,
        } = FORM_FIELD_NAMES;
        const stateClone = { ...state };
        stateClone[field] = value;

        if (field === IS_LABOR_RATE || field === IS_PARTS_RATE) {
            const convertion = String(Number(value));
            if (convertion === 'NaN') return;
        }

        switch (field) {
        case IS_FULL:
            stateClone.isLimited = !value;
            break;
        case IS_LIMITED:
            stateClone.isFull = !value;
            break;
        case IS_WARRANTY:
            stateClone.isFull = false;
            stateClone.isLimited = false;
            break;
        default:
            break;
        }

        setState(stateClone);
    };

    const onChangeBulletPoints = (field, index, value) => {
        if (typeof state[field] === 'undefined') return;

        const fieldValues = [...state[field]];
        fieldValues[index] = value;
        onChangeField(field, fieldValues);
    };

    const formatPercentaje = () => {
        const stateClone = { ...state };

        if (
            !StringUtils.isEmpty(laborRate)
            && String(laborRate).includes('.')
            && Number(laborRate) > 0) {
            stateClone.laborRate = NumberUtils.round(laborRate);
        }

        if (
            !StringUtils.isEmpty(partsRate)
            && String(partsRate).includes('.')
            && Number(partsRate) > 0) {
            stateClone.partsRate = NumberUtils.round(partsRate);
        }

        setState(stateClone);
    };

    const toggleTemplateNameDialog = () => {
        const stateClone = { ...state };
        stateClone.displayTemplateNameDialog = !stateClone.displayTemplateNameDialog;
        setState(stateClone);
    };

    const checkTemplateName = () => {
        const stateClone = { ...state };
        if (
            templateNameIndex === -1
            && StringUtils.isEmpty(templateName)) {
            toggleTemplateNameDialog();
            return;
        }

        if (displayTemplateNameDialog) stateClone.displayTemplateNameDialog = false;
        stateClone.isSaving = true;
        setState(stateClone);

        const durationData = {};
        const systemCoveredData = {};
        const range = Array.from({ length: FIELDS_NUMBER }, (_, i) => i);
        range.forEach((index) => {
            const currentDuration = durationValues[index];
            const currentSystemCovered = systemCoveredValues[index];

            durationData[`duration${index}`] = currentDuration || '';
            systemCoveredData[`systemsCovered${index}`] = currentSystemCovered || '';
        });

        const input = {
            ...(templateNameIndex !== -1 ? { id: templateNameIndex } : { name: templateName }),
            asIs: isWarranty === WARRANTY_VALUES.AS_IS_NO,
            fullWarranty: isFull,
            limitedWarranty: isLimited,
            percentLabor: StringUtils.isEmpty(laborRate) ? 0 : Number(laborRate),
            percentParts: StringUtils.isEmpty(partsRate) ? 0 : Number(partsRate),
            serviceContract: isServiceContract,
            ...durationData,
            ...systemCoveredData,
        };

        onSave(input);
    };

    const renderFieldsPanel = () => {
        const systemCoveredFields = [];
        const durationFields = [];

        for (let i = 0; i < FIELDS_NUMBER; i += 1) {
            systemCoveredFields.push(
                <Grid item xs={12} key={i}>
                    <TextField
                        className={classes.extraFields}
                        variant="outlined"
                        value={systemCoveredValues[i] || ''}
                        onChange={(e) => onChangeBulletPoints(
                            'systemCoveredValues',
                            i,
                            e.target.value,
                        )}
                        margin="dense"
                        fullWidth
                    />
                </Grid>,
            );

            durationFields.push(
                <Grid item xs={12} key={i}>
                    <TextField
                        className={classes.extraFields}
                        variant="outlined"
                        value={durationValues[i] || ''}
                        onChange={(e) => onChangeBulletPoints(
                            'durationValues',
                            i,
                            e.target.value,
                        )}
                        margin="dense"
                        fullWidth
                    />
                </Grid>,
            );
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <div className={classes.label}>
                            Systems Covered
                        </div>
                    </Grid>
                    {systemCoveredFields}
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <div className={classes.label}>
                            Duration
                        </div>
                    </Grid>
                    {durationFields}
                </Grid>
            </Grid>
        );
    };

    const disableCheckboxes = isWarranty === WARRANTY_VALUES.AS_IS_NO;
    const isTemplateNameDisabled = availableWarranties.length === 0;
    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Warranty Templates"
                    onClose={onClose}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.dialogContent}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.dialogContentTop}
                            >
                                <TextField
                                    select
                                    variant="outlined"
                                    label="Template Name"
                                    margin="dense"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isTemplateNameDisabled}
                                    value={templateNameIndex}
                                    onChange={onChangeTemplate}
                                >
                                    <MenuItem key={-1} value={-1}>
                                        None
                                    </MenuItem>
                                    {availableWarranties.map((warranty) => (
                                        <MenuItem key={warranty.id} value={warranty.id}>
                                            {warranty.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    disabled={isSaving}
                                    onClick={checkTemplateName}
                                >
                                    Save Template
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className={classes.fieldsContainer}
                        >
                            <Grid
                                item
                                lg={4}
                                md={5}
                                sm={6}
                                xs={12}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.innerSection}
                                    >
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                value={isWarranty}
                                                onChange={(e) => onChangeField('isWarranty', e.target.value)}
                                            >
                                                <FormControlLabel
                                                    value={
                                                        WARRANTY_VALUES.AS_IS_NO
                                                    }
                                                    control={<Radio />}
                                                    label="As Is - No Warranty"
                                                />
                                                <FormControlLabel
                                                    value={
                                                        WARRANTY_VALUES.IS
                                                    }
                                                    control={<Radio />}
                                                    label="Warranty"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        checked={isFull}
                                                        onChange={(e) => onChangeField('isFull', e.target.checked)}
                                                    />
                                                )}
                                                label="Full"
                                                disabled={disableCheckboxes}
                                            />
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        checked={isLimited}
                                                        onChange={(e) => onChangeField('isLimited', e.target.checked)}
                                                    />
                                                )}
                                                label="Limited"
                                                disabled={disableCheckboxes}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.innerSection}
                                    >
                                        <div className={classes.label}>
                                            The dealer will pay:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="% of the labor"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="0"
                                            InputLabelProps={{ shrink: true }}
                                            value={laborRate}
                                            onChange={(e) => onChangeField('laborRate', e.target.value)}
                                            onBlur={formatPercentaje}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="% of the parts"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="0"
                                            InputLabelProps={{ shrink: true }}
                                            value={partsRate}
                                            onChange={(e) => onChangeField('partsRate', e.target.value)}
                                            onBlur={formatPercentaje}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.innerSection}
                                    >
                                        <FormControl>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        checked={isServiceContract}
                                                        onChange={(e) => onChangeField(
                                                            'isServiceContract',
                                                            e.target.checked,
                                                        )}
                                                    />
                                                )}
                                                label="SERVICE CONTRACT"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={8}
                                md={7}
                                sm={6}
                                xs={12}
                            >
                                {renderFieldsPanel()}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={displayTemplateNameDialog}
                fullWidth
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Enter the Template Name"
                    onClose={toggleTemplateNameDialog}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.innerDialogContent}>
                        <TextField
                            label="Template Name"
                            variant="outlined"
                            value={templateName}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => onChangeField('templateName', e.target.value)}
                            margin="dense"
                            fullWidth
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.containedSecondaryInfo}
                        disabled={StringUtils.isEmpty(templateName)}
                        onClick={checkTemplateName}
                    >
                        Ok
                    </Button>
                    <Button
                        className={classes.containedError}
                        onClick={toggleTemplateNameDialog}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

WarrantyTemplatesDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default WarrantyTemplatesDialog;

import React, {
    useEffect,
    useReducer,
} from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';
import PayrollUtils from 'utils/PayrollUtils';
import NumericInput from 'react-numeric-input';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { TimeInReasons, TimeOutReasons } from 'utils/enum/PayrollEnum';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import EmployeeHoursSchema from 'utils/schema/payroll/EmployeeHoursSchema';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    baseGroup: {
        margin: 0,
        alignItems: 'center',
        '& > label': {
            minWidth: 111,
            marginBottom: 0,
            marginRight: 10,
            fontWeight: 500,
            textAlign: 'end',
            fontSize: '14px',
            color: theme.palette.text.minsk,
        },
        '& > *:last-child': {
            flex: 1,
            textAlign: 'justify',
        },
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        '& > *:last-child': {
            width: 0,
            minWidth: 0,
        },
    },
    termGroup: {
        '& > *:last-child': {
            maxWidth: 60,
            marginRight: 5,
        },
    },
    amPm: {
        minWidth: 60,
    },
    checkBox: {
        alignSelf: 'baseline',
        minWidth: '0px !important',
    },
    checkBoxRow: {
        marginTop: 35,
        marginBottom: 5,
    },
    checkBoxGroup: {
        margin: 0,
        alignItems: 'center',
        '& > label': {
            minWidth: 117,
            marginBottom: 0,
            marginRight: 10,
            fontWeight: 500,
            textAlign: 'end',
            fontSize: '14px',
            color: theme.palette.text.minsk,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    timeSpinner: {
        height: 31,
        fontSize: '0.9rem !important',
    },
}));

const ACTION_TYPES = {
    ON_CHANGE: 'onChange',
    INIT_STATE: 'initState',
};

const reducer = (state, action) => {
    const {
        type,
        field,
        value,
    } = action;
    switch (type) {
    case ACTION_TYPES.INIT_STATE:
        if (value) {
            const {
                timeIn, timeOut, timeInReason, timeOutReason,
            } = value;
            const timeInDate = new Date(timeIn);
            timeInDate.setMinutes(timeInDate.getMinutes() + timeInDate.getTimezoneOffset());
            const timeOutDate = new Date(timeOut || Date.now());
            timeOutDate.setMinutes(timeOutDate.getMinutes() + timeOutDate.getTimezoneOffset());
            const timeInString = timeInDate.toLocaleTimeString('en-US');
            const [timeInHour, timeInMinutes] = timeInString.split(':');
            const timeInAmPm = timeInString.split(' ')[1];
            timeInDate.setHours(0, 0, 0, 0);
            const timeOutString = timeOutDate.toLocaleTimeString('en-US');
            const [timeOutHour, timeOutMinutes] = timeOutString.split(':');
            const timeOutAmPm = timeOutString.split(' ')[1];
            timeOutDate.setHours(0, 0, 0, 0);
            const newState = update(state, {
                timeInAmPm: { $set: timeInAmPm },
                timeInDate: { $set: timeInDate },
                timeInReason: { $set: timeInReason },
                timeOutReason: { $set: timeOutReason },
                timeInHour: { $set: Number(timeInHour) },
                openEndedDate: { $set: timeOut === null },
                timeInMinute: { $set: Number(timeInMinutes) },
                timeOutAmPm: { $set: timeOut === null ? null : timeOutAmPm },
                timeOutDate: { $set: timeOut === null ? null : timeOutDate },
                timeOutHour: { $set: timeOut === null ? 0 : Number(timeOutHour) },
                timeOutMinute: { $set: timeOut === null ? 0 : Number(timeOutMinutes) },
            });
            return update(newState, {
                totalHours: { $set: PayrollUtils.calculateTotalHours(newState) },
            });
        }
        const currentDate = new Date();
        const timeString = currentDate.toLocaleTimeString('en-US');
        const [hour, minutes] = timeString.split(':');
        const amPm = timeString.split(' ')[1];
        currentDate.setHours(0, 0, 0, 0);
        return update(state, {
            timeOutHour: { $set: 0 },
            timeOutMinute: { $set: 0 },
            timeOutAmPm: { $set: null },
            timeOutDate: { $set: null },
            openEndedDate: { $set: true },
            timeOutReason: { $set: null },
            timeInDate: { $set: currentDate },
            timeInHour: { $set: Number(hour) },
            timeInMinute: { $set: Number(minutes) },
            timeInReason: { $set: TimeInReasons.START_DAY },
            timeInAmPm: { $set: amPm },
        });
    case ACTION_TYPES.ON_CHANGE:
        if (field === 'openEndedDate') {
            return update(state, {
                totalHours: { $set: '00:00' },
                openEndedDate: { $set: value },
                timeOutHour: { $set: value ? 0 : state.timeInHour },
                timeOutDate: { $set: value ? null : state.timeInDate },
                timeOutAmPm: { $set: value ? null : state.timeInAmPm },
                timeOutMinute: { $set: value ? 0 : state.timeInMinute },
                timeOutReason: { $set: value ? null : TimeOutReasons.END_DAY },
            });
        }
        return update(state, {
            [field]: { $set: value },
            totalHours: { $set: PayrollUtils.calculateTotalHours({ ...state, [field]: value }) },
        });
    default: return state;
    }
};

const EmployeeHoursDialog = ({
    record,
    onSave,
    onClose,
    loading,
}) => {
    const initialState = {
        timeInHour: 1,
        timeInAmPm: '',
        timeOutHour: 1,
        timeOutAmPm: '',
        timeInMinute: 0,
        timeInReason: '',
        timeOutMinute: 0,
        timeOutReason: '',
        openEndedDate: true,
        totalHours: '00:00',
        timeInDate: new Date(),
        timeOutDate: new Date(),
    };
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        timeInHour, timeOutHour, timeInMinute, timeInAmPm, timeInReason, timeInDate,
        timeOutDate, timeOutMinute, timeOutReason, timeOutAmPm, openEndedDate, totalHours,
    } = state;

    useEffect(() => {
        dispatch({
            value: record,
            type: ACTION_TYPES.INIT_STATE,
        });
    }, [record]);

    const onChange = (field, value) => {
        dispatch({
            field,
            value,
            type: ACTION_TYPES.ON_CHANGE,
        });
    };

    const closeDialog = (dismiss = true) => onClose(!dismiss);

    const isValidData = isValidSchema(EmployeeHoursSchema, state);
    const { isValid, errors } = isValidData;
    const mainClassesGroup = clsx(classes.baseGroup, classes.group);
    const amPmOptionList = [{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }];
    const timeInReasons = Object.values(TimeInReasons).map((item) => ({ label: item, value: item }));
    const timeOutReasons = Object.values(TimeOutReasons).map((item) => ({ label: item, value: item }));

    return (
        <Dialog
            open
            maxWidth="md"
        >
            <DialogAppBar
                title={!record ? 'New Line' : 'Editing Line'}
                onClose={closeDialog}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Time In Date:</Form.Label>
                                    <DatePicker
                                        size="sm"
                                        name="timeInDate"
                                        selected={timeInDate}
                                        placeholderText="mm/dd/yyyy"
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChange('timeInDate', date)}
                                        className={isValidField(errors, 'timeInDate')
                                            ? clsx('invalid-field', 'form-control form-control-sm')
                                            : 'form-control form-control-sm'}
                                        maxDate={new Date()}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.termGroup)}>
                                    <Form.Label>Time In:</Form.Label>
                                    <NumericInput
                                        strict
                                        min={1}
                                        max={12}
                                        step={1}
                                        precision={0}
                                        maxLength={2}
                                        value={timeInHour}
                                        onChange={(value) => onChange('timeInHour', value)}
                                        className={isValidField(errors, 'timeInHour')
                                            ? clsx('invalid-field', 'form-control', classes.timeSpinner)
                                            : clsx('form-control', classes.timeSpinner)}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.termGroup)}>
                                    <NumericInput
                                        strict
                                        min={0}
                                        max={59}
                                        step={1}
                                        precision={0}
                                        maxLength={2}
                                        value={timeInMinute}
                                        onChange={(value) => onChange('timeInMinute', value)}
                                        className={isValidField(errors, 'timeInMinute')
                                            ? clsx('invalid-field', 'form-control', classes.timeSpinner)
                                            : clsx('form-control', classes.timeSpinner)}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.amPm)}>
                                    <Select
                                        size="sm"
                                        name="timeInAmPm"
                                        value={timeInAmPm}
                                        onChange={onChange}
                                        placeholder="Select"
                                        options={amPmOptionList}
                                        className={isValidField(errors, 'timeInAmPm') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Time In Reason:</Form.Label>
                                    <Select
                                        size="sm"
                                        onChange={onChange}
                                        name="timeInReason"
                                        placeholder="Select"
                                        value={timeInReason}
                                        options={timeInReasons}
                                        className={isValidField(errors, 'timeInReason') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.checkBoxRow}>
                                <Form.Group as={Row} className={clsx(classes.checkBoxGroup, classes.group)}>
                                    <Form.Label>Open Ended Date:</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="openEndedDate"
                                        checked={openEndedDate}
                                        className={classes.checkBox}
                                        onChange={(e) => onChange('openEndedDate', e.target.checked)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Time Out Date:</Form.Label>
                                    <DatePicker
                                        size="sm"
                                        name="timeOutDate"
                                        selected={timeOutDate}
                                        disabled={openEndedDate}
                                        placeholderText="mm/dd/yyyy"
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChange('timeOutDate', date)}
                                        className={isValidField(errors, 'timeOutDate')
                                            ? clsx('invalid-field', 'form-control form-control-sm')
                                            : 'form-control form-control-sm'}
                                        maxDate={new Date()}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.termGroup)}>
                                    <Form.Label>Time Out:</Form.Label>
                                    <NumericInput
                                        strict
                                        min={1}
                                        max={12}
                                        step={1}
                                        precision={0}
                                        maxLength={2}
                                        value={timeOutHour}
                                        disabled={openEndedDate}
                                        onChange={(value) => onChange('timeOutHour', value)}
                                        className={isValidField(errors, 'timeOutHour')
                                            ? clsx('invalid-field', 'form-control', classes.timeSpinner)
                                            : clsx('form-control', classes.timeSpinner)}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.termGroup)}>
                                    <NumericInput
                                        strict
                                        min={0}
                                        max={59}
                                        step={1}
                                        precision={0}
                                        maxLength={2}
                                        value={timeOutMinute}
                                        disabled={openEndedDate}
                                        onChange={(value) => onChange('timeOutMinute', value)}
                                        className={isValidField(errors, 'timeOutMinute')
                                            ? clsx('invalid-field', 'form-control', classes.timeSpinner)
                                            : clsx('form-control', classes.timeSpinner)}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className={clsx(classes.baseGroup, classes.amPm)}>
                                    <Select
                                        size="sm"
                                        name="timeOutAmPm"
                                        value={timeOutAmPm}
                                        onChange={onChange}
                                        disabled={openEndedDate}
                                        options={amPmOptionList}
                                        className={isValidField(errors, 'timeOutAmPm') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Time Out Reason:</Form.Label>
                                    <Select
                                        size="sm"
                                        onChange={onChange}
                                        name="timeOutReason"
                                        value={timeOutReason}
                                        disabled={openEndedDate}
                                        options={timeOutReasons}
                                        className={isValidField(errors, 'timeOutReason') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Total hours:</Form.Label>
                                    <Form.Label>{totalHours}</Form.Label>
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                variant="contained"
                titleSecondary="Cancel"
                onClickSecondary={closeDialog}
                onClickPrimary={() => onSave(state)}
                disablePrimaryButton={!isValid || loading}
            />
        </Dialog>
    );
};

EmployeeHoursDialog.propTypes = {
    record: PropTypes.exact({
        timeOut: PropTypes.string,
        timeOutReason: PropTypes.string,
        timeIn: PropTypes.string.isRequired,
        timeId: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        timeInReason: PropTypes.string.isRequired,
    }),
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

EmployeeHoursDialog.defaultProps = {
    record: null,
};

export default EmployeeHoursDialog;

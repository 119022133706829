/* eslint-disable func-names */
import * as yup from 'yup';
import DateUtils from 'lib/DateUtils';

const ActivityCallSchema = yup.object().shape({
    subject: yup.string(),
    schedule: yup.date().required(),
    assignee: yup.number().required(),
    scheduleDuration: yup.date().required(),
});

const ActivityTaskSchema = yup.object().shape({
    subject: yup.string(),
    schedule: yup.date().required(),
    assignee: yup.number().required(),
});

const ActivityTodoTaskSchema = yup.object().shape({
    subject: yup.string(),
    schedule: yup.date().required(),
    assignee: yup.number().required(),
    scheduleDuration: yup.date().required(),
});

const ActivityEventSchema = yup.object().shape({
    subject: yup.string(),
    start: yup.date().required(),
    end: yup.date().required(),
    startTime: yup.date().required(),
    status: yup.string().required().matches(/^(.*)?\S+(.*)?$/),
    assignee: yup.number().required(),
    endTime: yup.date().nullable().test(
        'required',
        'End Time is required.',
        function (endTime) {
            const { startTime } = this.parent;
            return endTime == null ? true : DateUtils.isSameOrBefore(startTime, endTime);
        },
    ),
});

export {
    ActivityCallSchema,
    ActivityTaskSchema,
    ActivityEventSchema,
    ActivityTodoTaskSchema,
};

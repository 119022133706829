/* eslint-disable no-param-reassign */
import PROVIDER, {
    PACKAGE, REGULATED_RULE, KNOWN_ERROR,
    PRE_RATING_SURCHARGE_DATA_TYPE,
} from 'utils/enum/MenuEnum';
import { ProductTypes } from 'utils/ProductSettingUtils';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import PrintingUtils from 'utils/PrintingUtils';
import DateUtils from 'lib/DateUtils';

export default {
    getPackageColor(pack) {
        switch (pack) {
        case PACKAGE.PREMIUM:
            return '#5CB85C';
        case PACKAGE.PREFERRED:
            return '#3E9DD3';
        case PACKAGE.ECONOMY:
            return '#DBB333';
        case 'None':
            return 'rgba(0,0,0, 0.12)';
        default:
            return null;
        }
    },
    sortProductsBySettings(settings, products) {
        if (Object.keys(settings || {}).length === 0 || !settings?.products) return products;
        const sorted = settings.products.map((item) => {
            const { productId } = item;
            const current = products.find((p) => p.id === productId);
            if (current) return { ...current, available: item.available };

            return null;
        }).filter((item) => item != null);

        const others = products.filter((p) => !sorted.some((s) => s.id === p.id)).map((p) => ({ ...p, available: true }));
        return [...sorted, ...others];
    },
    getLowestPlan(options, includedInPayment, isExpress, productRate) {
        let output = {};
        let lowestTerm = null;

        if (isExpress) {
            options.forEach((coverage) => {
                const { name: coverageName, terms } = coverage;
                terms.forEach((t) => {
                    const {
                        value: termValue,
                        mileages,
                    } = t;

                    mileages.forEach((m) => {
                        const {
                            value: mileageValue,
                            deductibles,
                        } = m;

                        deductibles.forEach((d) => {
                            const {
                                value: deductibleValue,
                                surcharges,
                                dealerCost,
                                retailPrice,
                                contractFormID,
                                rateResponseID,
                                minPrice,
                                maxPrice,
                                serviceInterval,
                                tireRotations,
                            } = d;

                            if (
                                !lowestTerm
                                || termValue < lowestTerm
                                || (termValue === lowestTerm && mileageValue < output.mileage)
                                || (termValue === lowestTerm && mileageValue === output.mileage && retailPrice < output.retailRate)
                            ) {
                                lowestTerm = termValue;
                                output = {
                                    includedInPayment,
                                    replicateInAllPackages: false,
                                    coverageName,
                                    term: termValue,
                                    mileage: mileageValue,
                                    otherCharges: 0,
                                    netRate: dealerCost,
                                    retailRate: retailPrice,
                                    sellingPrice: retailPrice,
                                    deductible: deductibleValue,
                                    contractFormID,
                                    rateResponseID,
                                    minPrice,
                                    maxPrice,
                                    serviceInterval: serviceInterval ?? 1,
                                    tireRotations: tireRotations ?? 1,
                                    contractOptions: (productRate.formFields || [])
                                        .map((item) => ({
                                            ...item, value: null,
                                        })),
                                    surcharges: (surcharges || [])
                                        .map((item) => ({
                                            ...item, active: item.value,
                                        })),
                                };
                            }
                        });
                    });
                });
            });
        } else {
            const newOnes = options.N || [];
            const usedOnes = options.U || [];
            const current = [...newOnes, ...usedOnes];
            current.forEach((plan) => {
                const { planId, terms } = plan;
                terms.forEach((t) => {
                    if (
                        !lowestTerm
                        || t.term < lowestTerm
                        || (t.term === lowestTerm && t.mileage < output.mileage)
                        || (t.term === lowestTerm && t.mileage === output.mileage && t.rate.retailRate < output.retailRate)
                    ) {
                        const otherCharges = (field) => (t.options || []).filter((x) => x.isSurcharge).reduce((a, b) => a + b[field], 0);
                        lowestTerm = t.term;
                        output = {
                            includedInPayment,
                            replicateInAllPackages: false,
                            type: plan.newUsed,
                            planId,
                            term: t.term,
                            mileage: t.mileage,
                            otherCharges: otherCharges('retailRate'),
                            netRate: t.rate.netRate + otherCharges('netRate'),
                            retailRate: t.rate.retailRate,
                            sellingPrice: t.rate.retailRate + otherCharges('retailRate'),
                            deductible: t.deductible?.id,
                            contractOptions: (plan.additionalContractData || [])
                                .map((item) => ({
                                    ...item, value: null,
                                })),
                            surcharges: (t.options || [])
                                .map((item) => ({
                                    ...item, active: item.isSurcharge,
                                })),
                        };
                    }
                });
            });
        }

        return output;
    },
    setDefaultRateProducts(items, products, productId) {
        if (!items) return products;

        const includedProductTypes = [];
        return products.map((p) => {
            if (productId) {
                delete p.error;
                if (p.id !== productId) return p;
            }

            const { vendor: { provider } } = p;
            const isExpress = provider === PROVIDER.FI_EXPRESS;

            const vendor = p.vendor.vendorId;
            const vendorData = items.find((r) => r.vendorId && r.vendorId === vendor);
            const productData = items.find((r) => r.productId && r.productId === p.id);
            if (isExpress && (!productData || productData.error)) {
                p.error = productData?.error;
                return p;
            }

            if (!isExpress && (!vendorData || vendorData.error)) {
                p.error = vendorData?.error;
                return p;
            }

            if (isExpress) {
                const { rates, rates: { coverages, surcharges } } = productData;
                if ((coverages || []).length === 0) return p;

                p.productCode = rates.productCode;
                p.isDisabled = false;
                p.currentOptions = Object
                    .values(PACKAGE)
                    .reduce((a, b) => ({
                        ...a,
                        [b]: this.getLowestPlan(
                            coverages,
                            p.productType === ProductTypes.PRODUCT
                            || (p.productType !== ProductTypes.PRODUCT && !includedProductTypes.includes(p.productType)),
                            isExpress,
                            rates,
                        ),
                    }), {});
                p.surcharges = (surcharges || []).map((x) => {
                    let val = x.value;
                    if (x.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.BOOLEAN) val = val === 'true';
                    if (x.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.DATE) val = new Date(DateUtils.getFormattedDateInUserTimezone(val));
                    if ([PRE_RATING_SURCHARGE_DATA_TYPE.DECIMAL, PRE_RATING_SURCHARGE_DATA_TYPE.NUMERIC].includes(x.dataType.toLowerCase())) val = Number(val);

                    return {
                        surchargeCode: x.surchargeCode,
                        dataType: x.dataType,
                        value: val,
                        surchargeType: x.surchargeType,
                    };
                });

                includedProductTypes.push(p.productType);
            } else {
                const productCode = p.details.providerProductCode;
                const { rates: { plans } } = vendorData;

                const options = plans[productCode];
                if (!options) return p;

                p.isDisabled = false;
                p.currentOptions = Object
                    .values(PACKAGE)
                    .reduce((a, b) => ({
                        ...a,
                        [b]: this.getLowestPlan(
                            options,
                            p.productType === ProductTypes.PRODUCT
                            || (p.productType !== ProductTypes.PRODUCT && !includedProductTypes.includes(p.productType)),
                            isExpress,
                        ),
                    }), {});

                includedProductTypes.push(p.productType);
            }

            return p;
        });
    },
    getProductsSorted(items) {
        return [
            ...items.filter((i) => i.productType === ProductTypes.WARRANTY),
            ...items.filter((i) => i.productType === ProductTypes.GAP),
            ...items.filter((i) => i.productType === ProductTypes.PRODUCT),
        ];
    },
    groupProductTypes(input) {
        return input.map((item) => {
            const currentRates = item.rates;
            if (!item.error && currentRates?.plans) {
                item.rates = {
                    ...currentRates,
                    plans: currentRates.plans.reduce((a, b) => {
                        if (b.productTypeCode in a) {
                            let current = a[b.productTypeCode];
                            if (b.newUsed in current) {
                                const child = current[b.newUsed];
                                child.push(b);
                            } else {
                                current = { ...current, [b.newUsed]: [b] };
                            }
                        } else {
                            a[b.productTypeCode] = {
                                [b.newUsed]: [b],
                            };
                        }

                        return a;
                    }, {}),
                };
            }

            return item;
        });
    },
    getSellingPriceTooltipMessage(data, markUp = 0, deductibleData) {
        if (deductibleData) {
            if (!deductibleData.minPrice && !deductibleData.maxPrice) {
                return `This is a Non-Regulated Product. MarkUp: ${NumberUtils.applyCurrencyFormat(markUp)}`;
            }
            if (deductibleData.minPrice && deductibleData.maxPrice) {
                return `The retail price must be between 
                ${NumberUtils.applyCurrencyFormat(deductibleData.minPrice)} 
                and ${NumberUtils.applyCurrencyFormat(deductibleData.maxPrice)}`;
            }
            if (deductibleData.minPrice && !deductibleData.maxPrice) {
                return `The retail price must be equal or greater than ${NumberUtils.applyCurrencyFormat(deductibleData.minPrice)}`;
            }
            if (!deductibleData.minPrice && deductibleData.maxPrice) {
                return `The retail price must be equal or lower than ${NumberUtils.applyCurrencyFormat(deductibleData.maxPrice)}`;
            }
            return null;
        }

        const {
            rate: {
                markupMax,
                markupMin,
                regulatedRuleId,
            },
        } = data;

        switch (regulatedRuleId) {
        case REGULATED_RULE.NON_REGULATED:
            return `This is a Non-Regulated Product. MarkUp: ${NumberUtils.applyCurrencyFormat(markUp)}`;
        case REGULATED_RULE.RETAIL_RATE_NON_CHANGABLE:
            return 'Markups cannot be applied to this rate.';
        case REGULATED_RULE.MARKUP_IN_RANGE:
            return `
                Markup must be between (${NumberUtils.applyCurrencyFormat(markupMin)}) and (${NumberUtils.applyCurrencyFormat(markupMax)}).
                MarkUp: ${NumberUtils.applyCurrencyFormat(markUp)}
            `;
        case REGULATED_RULE.CONDITIONED_RETAIL_RATE:
            return `Selling Price is not greater than the Amount Financed minus the MSRP. MarkUp: ${NumberUtils.applyCurrencyFormat(markUp)}`;
        default:
            return '';
        }
    },
    formatErrorMessage(error = '') {
        if (error.toLowerCase().includes(KNOWN_ERROR.GENERAL_ERROR)) {
            return {
                message: 'Unkown Error found. Please refresh the tab',
                type: KNOWN_ERROR.GENERAL,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.TIMEOUT)) {
            return {
                message: 'Timeout getting a response from the API',
                type: KNOWN_ERROR.TIMEOUT,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.XML) || error.includes(KNOWN_ERROR.OTHER)) {
            return {
                message: 'There is an error in the XML Request. Please contact Support at 954228-8595 option 3',
                type: KNOWN_ERROR.XML,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.NO_RATES)) {
            return {
                message: 'Not rates available from vendor',
                type: KNOWN_ERROR.NO_RATES,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.AUTHENTICATION)) {
            return {
                message: 'Authentication issue. Please check the settings of this vendor',
                type: KNOWN_ERROR.AUTHENTICATION,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.TRIM)) {
            return {
                message: 'The trim provided is not valid',
                type: KNOWN_ERROR.TRIM,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.PRE_RATING)) {
            return {
                message: 'Specify all the pre-rating parameters before pulling rates',
                type: KNOWN_ERROR.PRE_RATING,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.NO_COVERAGE)) {
            return {
                message: 'No coverage for this vehicle',
                type: KNOWN_ERROR.PRE_RATING,
            };
        }

        if (error.toLowerCase().includes(KNOWN_ERROR.UNABLE_FETCH_RATES)) {
            return {
                message: 'No coverage for this vehicle',
                type: KNOWN_ERROR.PRE_RATING,
            };
        }

        return {
            message: StringUtils.isEmpty(error) ? 'Not rates available from vendor' : error,
            type: null,
        };
    },
    printMenu(element) {
        const customStyle = `
            <style>
                @page {
                    size: landscape;
                    margin: 0.5cm 0.5cm 0.5cm 0.5cm;
                }

                #printableElement {
                    zoom: 60% !important;
                    background-color: white !important;
                }

                .printingHiddenElement {
                    display: none !important;
                }

                .nonBreakable {
                    break-inside: avoid !important;
                }

                .signature {
                    display: flex !important;
                }
            </style>
        `;

        PrintingUtils.sendHTMLElementToPrintingDialog(element, 1850, customStyle);
    },
};

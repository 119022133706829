import { gql } from '@apollo/client';

export default class UserSettingMutation {
    static CREATE_EMAIL_SIGNATURE = gql`
        mutation createEmailSignature($emailSignature: String!) {
            createEmailSignature(emailSignature: $emailSignature) {
                userSettingId
            }
        }
    `;

    static UPDATE_EMAIL_SIGNATURE = gql`
        mutation updateEmailSignature($emailSignature: String!) {
           updateEmailSignature(emailSignature: $emailSignature)
        }
    `;
}

import * as yup from 'yup';

const { string, object } = yup;

const AddServiceVehicleSchema = object().shape({
    vin: string().required('VIN is required').length(17, 'VIN must be exactly 17 characters'),
    make: string().required('Make is required'),
    model: string().required('Model is required'),
});

export default AddServiceVehicleSchema;

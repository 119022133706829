export default class DrawerStyles {
    static getStyle(theme = {}, drawerWidth = 340) {
        return ({
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                zIndex: 999,
            },
            drawerOpen: {
                position: 'relative',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: 0,
                position: 'relative',
            },
        });
    }
}

export default class PortfolioSettingsStyles {
    static listStyles(theme = {}) {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flex: 1,
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
            },
            tableHeight: {
                display: 'contents',
            },
            button: {
                color: theme.palette.text.white,
                width: '95px',
                marginRight: '16px',
            },
            newButton: {
                backgroundColor: theme.palette.background.eucalyptus,
                '&:hover': {
                    backgroundColor: theme.palette.background.forestGreen,
                },
            },
            editButton: {
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            removeButton: {
                backgroundColor: '#E97C87',
                '&:hover': {
                    backgroundColor: '#D6727C',
                },
            },
            updateButton: {
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            setDefaultButton: {
                width: '140px',
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            content: {
                height: '100%',
                flex: 1,
                overflow: 'hidden',
            },
            columnStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            columnLeftStyle: {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left',
            },
            columnRightStyle: {
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
            },
            search: {
                marginRight: theme.spacing(2),
                width: 300,
            },
            checkedGreen: {
                color: theme.palette.success.main,
            },
            wrapperDate: {
                display: 'flex',
                flex: 1,
            },
        });
    }

    static referenceDialog(theme = {}) {
        return ({
            dialog: {
                width: 892,
            },
            dialogContent: {
                padding: theme.spacing(3),
            },
            labelCheck: {
                marginBottom: 0,
                paddingTop: '10px',
            },
            checkBox: {
                fontSize: '16px',
            },
            flexCenter: {
                display: 'flex',
                alignItems: 'center',
            },
            alignCheckBox: {
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
            },
            wrapperDate: {
                display: 'flex',
                flex: 1,
            },
            subtitle: {
                paddingTop: theme.spacing(1),
                fontWeight: 'bold',
                fontColor: '#00000073',
            },
            legend: {
                borderBottom: '1px solid #d9d9d9',
            },
            notes: {
                minHeight: '450px',
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
            },
        });
    }
}

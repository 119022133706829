import React, { Component } from 'react';

// Material UI
import {
    Typography, Box, Button, Grid, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CachedSharpIcon from '@material-ui/icons/CachedSharp';

// Component and others
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import Permissions from 'utils/enum/Permissions';
import DealStyles from 'styles/modules/DealStyles';
import VehicleCard from 'components/widgets/inventory/VehicleCard';
import { ReactComponent as RemoveOutlined } from 'assets/remove.svg';
import { DealSection, DealTabsTitle, DealStatus } from 'utils/enum/DealEnum';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => DealStyles.VehicleItem(theme);

class VehicleItem extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_VEHICLE_WRITE = keyStore.hasPermission(Permissions.SALES_DEAL_VEHICLE_WRITE);

        this.onEditMiles = this.onEditMiles.bind(this);
    }

    onEditMiles() {
        const {
            props: {
                openEditMilesDialog, vehicleInfo,
                isTradeIn, tradeInData,
            },
        } = this;

        if (isTradeIn) {
            openEditMilesDialog(isTradeIn, tradeInData.stockNumber);
        } else {
            openEditMilesDialog(isTradeIn, vehicleInfo.stockNumber);
        }
    }

    onEditTradeIn(dealVehicleTradeID) {
        const { props: { onChangeEditingMode, onEdit } } = this;

        onEdit(dealVehicleTradeID);
        onChangeEditingMode(true, DealSection.TRADE_IN, DealTabsTitle.VEHICLE);
    }

    renderTopRightButton(dealVehicleTradeID) {
        const {
            props:
            {
                classes, changeVehicle, isTradeIn, clientId, loading, editingStatus,
                editingMode, dealInformation: { status, postedDate }, vehicleInfo: { stockNumber },
            },
            SALES_DEAL_VEHICLE_WRITE,
        } = this;
        const { isEditing } = editingStatus;
        const isNotPosted = !DateUtils.isValid(postedDate);

        if (DealUtils.clientIdIsWeb(clientId) && isNotPosted && SALES_DEAL_VEHICLE_WRITE && isTradeIn) {
            return (
                <Button
                    size="small"
                    disabled={(editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.TRADE_IN}
                    startIcon={<BorderColorOutlinedIcon />}
                    className={classes.editMiles}
                    onClick={() => this.onEditTradeIn(dealVehicleTradeID)}
                >
                    Edit
                </Button>
            );
        }

        if ((DealUtils.clientIdIsWeb(clientId)
            && isNotPosted
            && KeyStore.isAllowed(Permissions.SALES_DEAL_EDIT)
            && (DealStatus.FNI === status || DealStatus.QUOTE === status))
            || (!loading && !stockNumber)) {
            return (
                <Button
                    size="small"
                    disabled={((editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.SOLD_VEHICLE)}
                    startIcon={<CachedSharpIcon className={classes.iconColor} />}
                    className={classes.changeVehicleStyle}
                    onClick={() => changeVehicle()}
                >
                    Change Vehicle
                </Button>
            );
        }

        return null;
    }

    render() {
        const {
            props:
            {
                classes, vehicleInfo, title, editingMode, clientId,
                isTradeIn, tradeInData, onRemove, onChangeEditingMode,
                dealInformation: { postedDate }, editingStatus,
            },
            SALES_DEAL_VEHICLE_WRITE,
        } = this;
        const { isEditing } = editingStatus;
        const firstRow = `${vehicleInfo.year || ''} ${vehicleInfo.make || ''}`;
        const secondRow = `${vehicleInfo.model || ''} ${vehicleInfo.trim || ''}`;
        const isNotPosted = !DateUtils.isValid(postedDate);

        return (
            <div>
                <div className={classes.topContainerStyle}>
                    <Typography className={classes.title}><span>{title}</span></Typography>
                    {this.renderTopRightButton(tradeInData.dealVehicleTradeID)}
                </div>
                <div className={classes.itemContainer}>
                    <VehicleCard
                        clientId={clientId}
                        frontRow={firstRow}
                        secondRow={secondRow}
                        thirdRow={vehicleInfo.vin}
                        fourthRow={vehicleInfo.miles}
                        vehiclePicture={vehicleInfo.thumbnail}
                        labelUnderVehicleImage="Stock"
                        numberUnderVehicleimage={vehicleInfo.stockNumber}
                        editingMode={editingMode}
                        onChangeEditingMode={onChangeEditingMode}
                        openEditMilesDialog={this.onEditMiles}
                        isTradeIn={isTradeIn}
                        navigateToDetail
                    />
                    <Box className={classes.vehicleDetail}>
                        <div className={classes.labelRow}>
                            <Typography className={`${classes.itemStyle} ${classes.labelLeft}`}>Engine:</Typography>
                            <Typography className={classes.itemStyle}>{vehicleInfo.engine}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography variant="body1" className={`${classes.itemStyle} ${classes.labelLeft}`}>Style:</Typography>
                            <Typography variant="body1" className={classes.itemStyle}>{vehicleInfo.style}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography variant="body1" className={`${classes.itemStyle} ${classes.labelLeft}`}>Transmission:</Typography>
                            <Typography variant="body1" className={classes.itemStyle}>{vehicleInfo.transmission}</Typography>
                        </div>
                    </Box>
                    <Box className={classes.vehicleDetail}>
                        <div className={classes.labelRow}>
                            <Typography className={`${classes.itemStyle} ${classes.labelLeft}`}>Drive Train:</Typography>
                            <Typography className={classes.itemStyle}>{vehicleInfo.drivetrain}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography variant="body1" className={`${classes.itemStyle} ${classes.labelLeft}`}>Title Status:</Typography>
                            <Typography variant="body1" className={classes.itemStyle}>{vehicleInfo.titleStatus}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography variant="body1" className={`${classes.itemStyle} ${classes.labelLeft}`}>Title:</Typography>
                            <Form.Check
                                checked={vehicleInfo.title}
                                type="checkbox"
                                disabled
                                className={classes.checkboxStyle}
                            />
                            <Typography variant="body1" className={classes.itemStyle}>{vehicleInfo.titleNumber}</Typography>
                        </div>
                    </Box>
                    <Box className={classes.vehicleDetail}>
                        <div className={classes.labelRow}>
                            <Form.Check
                                label="TMU"
                                checked={vehicleInfo.tmu}
                                type="checkbox"
                                disabled
                                className={`${classes.checkboxStyle} ${classes.labelLeft}`}
                            />
                        </div>
                        <div className={classes.labelRow}>
                            <Form.Check
                                label="Exempt"
                                checked={vehicleInfo.exempt}
                                type="checkbox"
                                disabled
                                className={`${classes.checkboxStyle} ${classes.labelLeft}`}
                            />
                            <Form.Check
                                label="EML"
                                checked={vehicleInfo.eml}
                                type="checkbox"
                                disabled
                                className={classes.checkboxStyle}
                            />
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={`${classes.itemStyle} ${classes.labelLeft}`}>Spare Key:</Typography>
                            <Typography className={classes.itemStyle}>{vehicleInfo.spareKey}</Typography>
                        </div>
                    </Box>
                </div>
                {isTradeIn
                && (
                    <Grid className={classes.bottomContainer} container>
                        <Grid item className={classes.tradeInSection} xs={8}>
                            <Box className={classes.tradeInBoxStyle}>
                                <Typography className={classes.tradeInTitleStyle}>Allowance:</Typography>
                                <Typography variant="body1" className={classes.tradeInStyle}>
                                    {NumberUtils.applyCurrencyFormat(tradeInData.allowance)}
                                </Typography>
                            </Box>
                            <Box className={classes.tradeInBoxStyle}>
                                <Typography className={classes.tradeInTitleStyle}>ACV:</Typography>
                                <Typography variant="body1" className={classes.tradeInStyle}>
                                    {NumberUtils.applyCurrencyFormat(tradeInData.acv)}
                                </Typography>
                            </Box>
                            <Box className={classes.tradeInBoxStyle}>
                                <Typography className={classes.tradeInTitleStyle}>Pay Off:</Typography>
                                <Typography variant="body1" className={classes.tradeInStyle}>
                                    {NumberUtils.applyCurrencyFormat(tradeInData.payOff)}
                                </Typography>
                            </Box>
                            <Box className={classes.tradeInBoxStyle}>
                                <Typography className={classes.tradeInTitleStyle}>Pay Off good till:</Typography>
                                <Typography variant="body1" className={classes.tradeInStyle}>
                                    {DateUtils.format(tradeInData.payOffGoodTill || '')}
                                </Typography>
                            </Box>
                            <Box className={classes.tradeInBoxStyle}>
                                <Form.Check
                                    label="No Sales Tax Credit"
                                    checked={tradeInData.noSalesTaxCredit}
                                    type="checkbox"
                                    disabled
                                    className={classes.checkboxStyle}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" justifyContent="flex-end" xs={12}>
                                {DealUtils.clientIdIsWeb(clientId) && isNotPosted && SALES_DEAL_VEHICLE_WRITE && (
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        disabled={(editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.TRADE_IN}
                                        className={classes.iconButtonDelete}
                                        onClick={() => onRemove(tradeInData.dealVehicleTradeID)}
                                    >
                                        <RemoveOutlined className={classes.deleteIcon} />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

VehicleItem.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    vehicleInfo: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    changeVehicle: PropTypes.func,
    tradeInData: PropTypes.object,
    isTradeIn: PropTypes.bool,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    openEditMilesDialog: PropTypes.func.isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }),
    onChangeEditingMode: PropTypes.func.isRequired,
    dealInformation: PropTypes.object,
    clientId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    editingStatus: PropTypes.object,
};

VehicleItem.defaultProps = {
    isTradeIn: false,
    tradeInData: {
        allowance: 0,
        acv: 0,
        payOff: 0,
        payOffGoodTill: '',
        noSalesTaxCredit: false,
    },
    onEdit: () => {},
    onRemove: () => {},
    editingMode: {
        isEditing: false,
        sectionName: '',
    },
    changeVehicle: () => {},
    dealInformation: {},
    loading: false,
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(VehicleItem);

export default class MessageStyles {
    static message(theme = {}) {
        return ({
            main: {
                flexGrow: 1,
                padding: theme.spacing(2),
                overflow: 'auto',
            },
        });
    }

    static ShortMessageService(theme = {}) {
        return ({
            flexEnd: {
                display: 'flex',
                justifyContent: 'flex-end',
            },
            message: {
                backgroundColor: theme.palette.background.gray,
                borderRadius: theme.spacing(1, 1, 1, 0),
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(1),
                maxWidth: '65%',
            },
            messageOutgoing: {
                backgroundColor: 'RGB(113, 222, 137)',
                borderRadius: theme.spacing(1, 1, 0, 1),
                marginLeft: 0,
                marginRight: theme.spacing(1),
            },
            text: {
                color: theme.palette.text.tundora,
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                position: 'relative',
                '& a': {
                    color: '#007AFF',
                },
            },
            dateTime: {
                marginTop: theme.spacing(1.5),
                fontSize: theme.spacing(1.6),
                color: theme.palette.text.tundora,
            },
            textOutgoing: {
                color: theme.palette.text.tundora,
            },
            customerName: {
                fontWeight: 'bold',
            },
            avatar: {
                marginBottom: theme.spacing(2),
                width: '30px',
                height: '30px',
            },
            main: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            boxMessageOut: {
                flexDirection: 'row-reverse',
            },
            avatarOutgoing: {
                '& .MuiAvatarGroup-avatar': {
                    width: '30px',
                    height: '30px',
                    marginBottom: '16px',
                },
            },
            iconFail: {
                position: 'absolute',
                color: theme.palette.error.light,
                top: '-6px',
                right: '-12px',
                cursor: 'pointer',
            },
        });
    }

    static crmMessageList(theme = {}) {
        return ({
            messageList: {
                minWidth: '400px',
                maxWidth: '400px',
                background: theme.palette.background.white,
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                overflow: 'hidden',
                marginLeft: theme.spacing(2),
            },
            toolbar: {
                padding: theme.spacing(0, 2),
                height: 56,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            select: {
                width: '180px',
            },
        });
    }
}

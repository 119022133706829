import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DateUtils from 'lib/DateUtils';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const AccountingLockDate = (props) => {
    const {
        recordNumber,
        onCloseActionDialog,
        onForceToVoid,
        showComment,
        lockedDate,
        defaultComment,
    } = props;

    const [voidDate, setVoidDate] = useState(new Date());
    const [comment, setComment] = useState(defaultComment);

    return (
        <ConfirmDialog
            title={`Record #${recordNumber} - Accounting Locked Date`}
            description={`The post date falls in a date range that is closed ${DateUtils.getOnlyDate(lockedDate)},`
            + ' Do you want to post the reversal on this date?'}
            open
            variant="outlined"
            titlePrimary="Void"
            titleSecondary="Cancel"
            disablePrimaryButton={!voidDate || (showComment && !comment)}
            onClose={onCloseActionDialog}
            onClickSecondary={onCloseActionDialog}
            onClickPrimary={() => onForceToVoid({ voidDate, comment })}
        >
            <Grid item xs={12}>
                <Form.Group>
                    Post Date
                    { ' ' }
                    <DatePicker
                        selected={voidDate}
                        name="fromDate"
                        size="sm"
                        className={`form-control form-control-sm ${!voidDate ? 'invalid-field' : ''}`}
                        placeholderText="mm/dd/yyyy"
                        popperContainer={CalendarContainer}
                        maxDate={new Date()}
                        minDate={new Date(DateUtils.add(DateUtils.getOnlyDate(lockedDate), 1))}
                        onChange={(date) => setVoidDate(date)}
                    />
                </Form.Group>
                {showComment && (
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            size="sm"
                            rows="3"
                            className={!comment ? 'invalid-field' : ''}
                            placeholder="Comments to Void"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                        />
                    </Form.Group>
                )}
            </Grid>
        </ConfirmDialog>
    );
};

AccountingLockDate.propTypes = {
    recordNumber: PropTypes.string.isRequired,
    onCloseActionDialog: PropTypes.func.isRequired,
    onForceToVoid: PropTypes.func.isRequired,
    showComment: PropTypes.bool,
    lockedDate: PropTypes.instanceOf(Date).isRequired,
    defaultComment: PropTypes.string,
};

AccountingLockDate.defaultProps = {
    showComment: false,
    defaultComment: '',
};

export default AccountingLockDate;

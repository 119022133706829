import React from 'react';
import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import { Chip } from '@material-ui/core';

const useSharedMethods = (classes) => {
    const getBadgeStatus = (status) => {
        let badgeStatus = classes.badgeGray;

        switch (status.toLowerCase()) {
        case ServiceInvoiceStatus.WRITE_UP.toLowerCase():
            badgeStatus = classes.badgeBlue;
            break;
        case ServiceInvoiceStatus.IN_PROGRESS.toLowerCase():
            badgeStatus = classes.badgeGreen;
            break;
        case ServiceInvoiceStatus.APPROVAL_REQUEST.toLowerCase():
            badgeStatus = classes.badgeYellow;
            break;
        case ServiceInvoiceStatus.ESTIMATE.toLowerCase():
            badgeStatus = classes.badgeDarkBlue;
            break;
        case ServiceInvoiceStatus.COMPLETED.toLowerCase():
            badgeStatus = classes.badgeDarkYellow;
            break;
        case ServiceInvoiceStatus.CLOSED.toLowerCase():
            badgeStatus = classes.badgePurple;
            break;
        case ServiceInvoiceStatus.VOID.toLowerCase():
            badgeStatus = classes.badgeRed;
            break;
        default:
            break;
        }
        return <Chip size="small" label={status} className={badgeStatus} />;
    };

    const getServiceClassStatus = (status) => {
        let iconStatus = classes.iconGray;

        switch (status.toLowerCase()) {
        case ServiceInvoiceStatus.WRITE_UP.toLowerCase():
            iconStatus = classes.iconBlue;
            break;
        case ServiceInvoiceStatus.IN_PROGRESS.toLowerCase():
            iconStatus = classes.iconGreen;
            break;
        case ServiceInvoiceStatus.APPROVAL_REQUEST.toLowerCase():
            iconStatus = classes.iconYellow;
            break;
        case ServiceInvoiceStatus.ESTIMATE.toLowerCase():
            iconStatus = classes.iconDarkBlue;
            break;
        case ServiceInvoiceStatus.COMPLETED.toLowerCase():
            iconStatus = classes.iconDarkYellow;
            break;
        case ServiceInvoiceStatus.CLOSED.toLowerCase():
            iconStatus = classes.iconPurple;
            break;
        case ServiceInvoiceStatus.VOID.toLowerCase():
            iconStatus = classes.iconRed;
            break;
        default:
            break;
        }

        return iconStatus;
    };

    return {
        getBadgeStatus,
        getServiceClassStatus,
    };
};

export default useSharedMethods;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import axios from 'axios';
import { isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { makeUseAxios } from 'axios-hooks';
import { makeStyles, Typography } from '@material-ui/core';
import DialogActions from 'components/widgets/modal/DialogActions';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';
import StringUtils from 'lib/StringUtils';

const VERIFY_METHOD = {
    SMS: 'sms',
    EMAIL: 'email',
};
const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: process.env.REACT_APP_GRAPHQL_HTTP }),
});
const useStyles = makeStyles((theme) => ({
    mainLogin: {
        height: '100%',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    main: {
        marginTop: '27px',
        '& button:disabled': {
            backgroundColor: theme.palette.background.mercury,
        },
    },
    title: {
        marginBottom: theme.spacing(0.8),
        color: theme.palette.text.white,
    },
    row: {
        marginBottom: theme.spacing(4),
        '& label, & span': {
            fontSize: '13px',
            color: theme.palette.text.white,
        },
    },
    containerCode: {
        marginBottom: theme.spacing(4),
        display: 'flex',
        '& input': {
            margin: theme.spacing(0, 1),
        },
    },
    body: {
        marginBottom: theme.spacing(2),
    },
}));

const TwoFactorAuthenticationForm = ({ onClose, record, setLogin }) => {
    const classes = useStyles();
    const [token, setToken] = useState('');
    const [verifyMethod, setVerifyMethod] = useState('');
    const [twoFactorAuthenticationId, setTwoFactorAuthenticationId] = useState(null);
    const [{ loading }, sendVerificationCode] = useAxios({ url: '/sendVerificationCode', method: 'POST' }, { manual: true });
    const [{ loading: loadingVerification }, verifyCode] = useAxios({ url: '/verifyCode', method: 'POST' }, { manual: true });

    const onSendVerificationCode = async () => {
        try {
            const result = await sendVerificationCode({ data: { userId: record.userId, verifyMethod } });

            if (result.status === 200 && !isEmpty(result.data)) setTwoFactorAuthenticationId(result.data.twoFactorAuthenticationId);
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.errors);
        }
    };

    const onVerifyCode = async () => {
        try {
            const result = await verifyCode({ data: { token, twoFactorAuthenticationId, password: record.password } });
            if (result.status === 200 && !isEmpty(result.data?.login)) setLogin(result.data.login);
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.errors);
        }
    };

    const onKeyDownVerificationCode = (event) => {
        if (!loading && token?.length === 6 && (event.key === 'Enter' || event.keyCode === 13)) onVerifyCode();
    };

    const onChangeValue = (event) => {
        setVerifyMethod(event.target.value);
    };

    const getVerifyMethod = (method) => {
        if (VERIFY_METHOD.SMS === method) return record.cellPhone;

        return record[method];
    };

    useEffect(() => {
        if (!isEmpty(record)) {
            if (StringUtils.isEmpty(record.email)) setVerifyMethod(VERIFY_METHOD.SMS);
            else setVerifyMethod(VERIFY_METHOD.EMAIL);
        }
    }, [record]);

    return (
        <div className={classes.main}>
            {!twoFactorAuthenticationId && (
                <>
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <Typography variant="h4" className={classes.title}>Two-Factor Authentication</Typography>
                            <span>For the security, we require verification before you can continue on to our site.</span>
                        </div>
                        <div className={clsx(classes.row, 'inline-radio')}>
                            <Typography variant="h4" className={classes.title}>Choose a verification method:</Typography>
                            {!StringUtils.isEmpty(record.email) && (
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    value={VERIFY_METHOD.EMAIL}
                                    id="inline-radio-1"
                                    onChange={onChangeValue}
                                    checked={verifyMethod === VERIFY_METHOD.EMAIL}
                                    label={`E-mail to ${record.email}`}
                                />
                            )}
                            {!StringUtils.isEmpty(record.cellPhone) && (
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    value={VERIFY_METHOD.SMS}
                                    id="inline-radio-2"
                                    onChange={onChangeValue}
                                    checked={verifyMethod === VERIFY_METHOD.SMS}
                                    label={`Text Message to ${record.cellPhone}`}
                                />
                            )}
                        </div>
                    </div>
                    <DialogActions
                        divider={false}
                        onClickSecondary={onClose}
                        titlePrimary="Send Verification Code"
                        disablePrimaryButton={loading || StringUtils.isEmpty(verifyMethod)}
                        onClickPrimary={onSendVerificationCode}
                    />
                </>
            )}
            {twoFactorAuthenticationId && (
                <>
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <Typography variant="h4" className={classes.title}>Two-Factor Authentication</Typography>
                            <span>{`Enter the authentication code below we sent to ${getVerifyMethod(verifyMethod)}.`}</span>
                        </div>
                        <div className={classes.containerCode}>
                            <Form.Control
                                autoFocus
                                type="text"
                                required
                                maxLength={6}
                                onKeyDown={onKeyDownVerificationCode}
                                onChange={(e) => setToken(e.target.value)}
                            />
                        </div>
                    </div>
                    <DialogActions
                        divider={false}
                        onClickSecondary={onClose}
                        titlePrimary="Verify"
                        onClickPrimary={onVerifyCode}
                        disablePrimaryButton={loadingVerification || token?.length !== 6}
                    />
                </>
            )}
        </div>
    );
};

TwoFactorAuthenticationForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    setLogin: PropTypes.func.isRequired,
};

export default TwoFactorAuthenticationForm;

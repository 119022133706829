import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Grid, Button,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { FetchPolicy, AI_PLATFORM } from 'utils/enum/Core';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';
import Select from 'components/widgets/Select';
import VirtualTable from 'components/widgets/VirtualTable';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        padding: '10px',
        '& > div > button': {
            marginRight: '10px',
        },
    },
    tableGrid: {
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '500px',
        overflow: 'hidden',
        minWidth: '1400px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
    topSection: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
        marginRight: '5px',
        overflow: 'hidden',
        '& > div': {
            width: '350px',
            marginRight: '10px',
            [theme.breakpoints.down('sm')]: {
                width: '100px',
            },
        },
        '& > input': {
            width: '350px',
            marginRight: '10px',
            height: '31px',
            [theme.breakpoints.down('sm')]: {
                width: '100px',
            },
        },
    },
}));

const KeysSettings = ({ canWrite }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        activeCompanies: [],
        enabledCompanies: [],
        selectedCompany: null,
        selectedPlatform: null,
        selectedKey: null,
        selectedRow: null,
        isDeletePromptVisible: false,
    });

    const {
        activeCompanies,
        enabledCompanies,
        selectedCompany,
        selectedPlatform,
        selectedKey,
        selectedRow,
        isDeletePromptVisible,
    } = state;

    const {
        data: companiesData,
        loading: loadingCompanies,
        error: errorLoadingCompanies,
    } = useQuery(AIQuery.PULL_ACTIVE_COMPANIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: enabledCompaniesData,
        loading: loadingEnabledCompanies,
        error: errorLoadingEnabledCompanies,
        refetch: refetchCompanies,
    } = useQuery(AIQuery.PULL_COMPANIES_AI_KEY_ADDED, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const [saveAPIKey, { loading: savingKey }] = useMutation(AIMutation.ADD_KEY_TO_COMPANY, {
        onCompleted: () => {
            refetchCompanies();
            ModalUtils.successMessage(null, 'API Key saved successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [removeAPIKey, { loading: removingKey }] = useMutation(AIMutation.REMOVE_KEY_TO_COMPANY, {
        onCompleted: () => {
            refetchCompanies();
            ModalUtils.successMessage(null, 'API Key removed successfully');
        },
        onError: (error) => {
            setState((prevState) => ({
                ...prevState,
                isDeletePromptVisible: false,
            }));

            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingCompanies) {
            ModalUtils.errorMessage(errorLoadingCompanies?.graphQLErrors);
            return;
        }

        if (!loadingCompanies) {
            const companies = companiesData?.pullActiveCompanies;
            if (companies) {
                setState((prevState) => ({
                    ...prevState,
                    activeCompanies: companies,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCompanies, errorLoadingCompanies]);

    useEffect(() => {
        if (errorLoadingEnabledCompanies) {
            ModalUtils.errorMessage(errorLoadingEnabledCompanies?.graphQLErrors);
            return;
        }

        if (!loadingEnabledCompanies) {
            const companies = enabledCompaniesData?.pullCompaniesAIKeyAdded;
            if (companies) {
                setState((prevState) => ({
                    ...prevState,
                    selectedCompany: null,
                    selectedPlatform: null,
                    selectedKey: null,
                    selectedRow: null,
                    isDeletePromptVisible: false,
                    enabledCompanies: companies,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEnabledCompanies, errorLoadingEnabledCompanies]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const addKey = () => {
        saveAPIKey({
            variables: {
                companyCode: selectedCompany,
                key: selectedKey,
                platform: selectedPlatform,
            },
        });
    };

    const removeKey = () => {
        removeAPIKey({
            variables: {
                companyCode: selectedRow.companyCode,
                platform: selectedRow.platform,
            },
        });
    };

    const toggleDeletePrompt = (record) => {
        setState((prevState) => ({
            ...prevState,
            selectedRow: record,
            isDeletePromptVisible: !isDeletePromptVisible,
        }));
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Company Code',
            dataKey: 'companyCode',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.companyCode}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Company Name',
            dataKey: 'companyName',
            width: 500,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.companyName}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'API Key',
            dataKey: 'apiKey',
            width: 300,
            cellRenderer: () => (
                <span className={classes.text}>**********</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Platform',
            dataKey: 'platform',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.platform}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            disabled={!canWrite}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => toggleDeletePrompt(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('table-container')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 30;
    return (
        <>
            <Grid container className={classes.box}>
                <Grid className={classes.topSection} item xs={12}>
                    <Select
                        placeholder="Company"
                        size="sm"
                        loading={loadingCompanies}
                        className={classes.input}
                        name="selectedCompany"
                        onChange={(name, value) => onChange(name, value)}
                        value={selectedCompany ?? ''}
                        options={activeCompanies.map((c) => ({
                            value: c.companyCode,
                            label: `${c.companyCode} - ${c.companyName}`,
                        }))}
                    />
                    <Select
                        placeholder="Platform"
                        size="sm"
                        loading={loadingCompanies}
                        className={classes.input}
                        name="selectedPlatform"
                        onChange={(name, value) => onChange(name, value)}
                        value={selectedPlatform ?? ''}
                        options={Object.values(AI_PLATFORM).map((p) => ({
                            value: p,
                            label: p,
                        }))}
                    />
                    <Form.Control
                        placeholder="API Key"
                        maxLength="400"
                        className={classes.input}
                        type="text"
                        name="selectedKey"
                        value={selectedKey ?? ''}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                    <Button
                        size="small"
                        className={classes.containedSecondaryInfo}
                        disabled={
                            !canWrite
                            || StringUtils.isEmpty(selectedCompany)
                            || StringUtils.isEmpty(selectedPlatform)
                            || StringUtils.isEmpty(selectedKey)
                            || savingKey
                        }
                        onClick={addKey}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid className={classes.tableGrid} item xs={12}>
                    <div id="table-container" className={classes.tableContainer}>
                        {parentWidth > 0 && (
                            <VirtualTable
                                loading={loadingEnabledCompanies}
                                rowHeight={45}
                                totalRecords={enabledCompanies.length}
                                data={enabledCompanies}
                                columns={columns}
                                width={tableWidth}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this key?"
                open={isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={removeKey}
                disablePrimaryButton={removingKey}
                disableSecondaryButton={removingKey}
            />
        </>
    );
};

KeysSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default KeysSettings;

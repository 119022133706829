import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import StringUtils from 'lib/StringUtils';
import onClickOutside from 'react-onclickoutside';
import CreateFormPacksContainer from 'components/containers/deals/create/form/CreateFormPacksContainer';

// Material UI
import FormTab from 'styles/modules/deals/FormsTab';
import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const styles = (theme) => FormTab.createFormPacks(theme);

class CreateFormPacks extends Component {
    componentDidMount() {
        this.packName.focus();
        this.packName.select();
    }

    handleClickOutside = () => {
        const { hiddenFormPack } = this.props;

        hiddenFormPack();
    };

    render() {
        const {
            classes, handleKeyDown, handleClick, onChangeValue,
            packName, isDuplicatePackName,
        } = this.props;
        const isDuplicateName = isDuplicatePackName(packName);
        const isEmptyName = StringUtils.isEmpty(packName);
        const invalidField = isDuplicateName ? 'invalid-field' : '';

        return (
            <div className={classes.contentItem}>
                <div className={classes.inputButton}>
                    <Form.Control
                        ref={(ref) => { this.packName = ref; }}
                        maxLength={100}
                        value={packName}
                        onChange={onChangeValue}
                        onKeyDown={handleKeyDown}
                        className={clsx(classes.newForm, invalidField)}
                    />
                    {!isDuplicateName && (
                        <Button
                            size="small"
                            disabled={isEmptyName}
                            onClick={handleClick}
                            className={classes.saveForm}
                            startIcon={<SaveOutlinedIcon />}
                        >
                            Save
                        </Button>
                    )}
                    {isDuplicateName && (
                        <Tooltip title="Form pack name duplicated" placement="top">
                            <ErrorOutlineIcon className={classes.errorIcon} />
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
}

CreateFormPacks.propTypes = {
    classes: PropTypes.object.isRequired,
    packName: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    isDuplicatePackName: PropTypes.func.isRequired,
    hiddenFormPack: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreateFormPacksContainer(onClickOutside(CreateFormPacks)));

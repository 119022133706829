import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputNumber from 'components/widgets/InputNumber';

const InputControl = (props) => {
    const {
        name, value, onChange, onKeyDown, type, showCurrency,
        thousandSeparator, allowNegative, editorCellObject, ...other
    } = props;
    const { className, ...otherProps } = other;
    // delete to not include as dom property
    delete otherProps.comparePropertyId;

    const handleChange = (newValue) => {
        onChange(name, newValue, editorCellObject);
    };

    const handleKeyDown = (event) => {
        onKeyDown(editorCellObject, event);
    };

    const formControlType = type === 'textarea' ? 'textarea' : 'input';
    const { id } = editorCellObject?.column || { id: 'input' };
    const editorCls = `${id}-ax-edit-ctrl`;

    return (
        <>
            { type === 'number'
                ? (
                    <InputNumber
                        value={value}
                        onChange={(val) => handleChange(val)}
                        onKeyDown={handleKeyDown}
                        showCurrency={showCurrency}
                        thousandSeparator={thousandSeparator}
                        allowNegative={allowNegative}
                        size="sm"
                        className={clsx(editorCls, className)}
                        {...otherProps}
                    />
                )
                : (
                    <Form.Control
                        as={formControlType}
                        size="sm"
                        value={value || ''}
                        onChange={(event) => handleChange(event.target.value)}
                        onKeyDown={handleKeyDown}
                        className={clsx(editorCls, className)}
                        {...otherProps}
                    />
                )}
        </>
    );
};

InputControl.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    type: PropTypes.string,
    editorCellObject: PropTypes.object,
    comparePropertyId: PropTypes.number,
    showCurrency: PropTypes.bool,
    thousandSeparator: PropTypes.bool,
    allowNegative: PropTypes.bool,
};

InputControl.defaultProps = {
    onKeyDown: () => null,
    type: 'input',
    value: null,
    editorCellObject: {},
    comparePropertyId: 0,
    showCurrency: true,
    thousandSeparator: true,
    allowNegative: true,
};

const areEqual = (prevProps, nextProps) => prevProps.value !== '' && nextProps.value !== ''
    && prevProps.value === nextProps.value
    && prevProps.className === nextProps.className
    && prevProps.comparePropertyId === nextProps.comparePropertyId;
export default React.memo(InputControl, areEqual);

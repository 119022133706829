import { cloneDeep } from 'lodash';
import axios from 'axios';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { FetchPolicy, DOCUMENT_REFERENCE_TYPE } from 'utils/enum/Core';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import ImagesUtils from 'utils/ImagesUtils';

const useFilesManagerFunctions = (state, setState, options) => {
    const client = useApolloClient();
    const isInventory = options.referenceType === DOCUMENT_REFERENCE_TYPE.INVENTORY;
    const isDeals = options.referenceType === DOCUMENT_REFERENCE_TYPE.DEALS;

    const {
        data: documentsData,
        loading: loadingDocuments,
        error: errorLoadingDocuments,
        refetch: refetchDocuments,
    } = useQuery(options.pullDocumentsQuery, {
        variables: {
            ...(isInventory ? { stockNumber: options.referenceId } : {}),
            ...(isDeals ? { accountNumber: options.referenceId } : {}),
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [deleteDocument, { loading: deletingDocument }] = useMutation(options.deleteMutation, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    documents: state.documents.filter((el) => el.documentId !== state.selectedDocumentId),
                    associatedDocuments: state.associatedDocuments.filter((el) => el.documentId !== state.selectedDocumentId),
                    selectedDocumentId: null,
                    isDeletePromptVisible: false,
                }));

                ModalUtils.successMessage(null, 'Document deleted successfully');
            }
        },
        onError: (error) => {
            setState((prevState) => ({
                ...prevState,
                selectedDocumentId: null,
                isDeletePromptVisible: false,
            }));

            ModalUtils.errorMessage(null, error);
        },
    });

    const [documentRename, { loading: renamingDocument }] = useMutation(options.editDocumentNameMutation, {
        onCompleted: (response) => {
            if (response) {
                const documentsClone = cloneDeep(state.documents);
                const associatedDocumentsClone = cloneDeep(state.associatedDocuments);
                const doc = documentsClone.find((el) => el.documentId === state.selectedDocumentId)
                    || associatedDocumentsClone.find((el) => el.documentId === state.selectedDocumentId);

                const [name] = Object.keys(response || {});
                if (!name) return;

                const path = response[name];
                if (doc) {
                    doc.documentName = StringUtils.getFileNameFromPath(path);
                    doc.documentUrl = path;
                }

                setState((prevState) => ({
                    ...prevState,
                    documents: documentsClone.sort((a, b) => a.documentName.localeCompare(b.documentName)),
                    associatedDocuments: associatedDocumentsClone.sort((a, b) => a.documentName.localeCompare(b.documentName)),
                    selectedDocumentId: null,
                    modifiedName: null,
                }));

                ModalUtils.successMessage(null, 'Document modified successfully');
            }
        },
        onError: (error) => {
            setState((prevState) => ({
                ...prevState,
                selectedDocumentId: null,
                modifiedName: null,
            }));

            ModalUtils.errorMessage(null, error);
        },
    });

    const [createDocuments] = useMutation(options.createDocumentsMutation, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    search: '',
                    isUploadingDocuments: false,
                }));

                refetchDocuments();
                ModalUtils.successMessage(null, 'Document(s) added successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [createAssociatedDocuments] = useMutation(options.createAssociatedDocumentsMutation, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    search: '',
                    isUploadingDocuments: false,
                }));

                refetchDocuments();
                ModalUtils.successMessage(null, 'Document(s) added successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const uploadDocuments = async (files, isAssociated = false) => {
        if (files && files.length > 0) {
            const anyNotAllowedFile = files.some((file) => file.size > options.maxFileSize);
            if (anyNotAllowedFile) {
                ModalUtils.errorMessage(null, `You can only upload files up to ${options.maxFileSize / 1024 / 1024}MB.`);
                return;
            }

            const exts = options.allowedFiles.split(',').map((ext) => ext?.trim());
            const anyNotAllowedExt = files.some((file) => !exts.includes(file.type?.toLowerCase()));
            if (anyNotAllowedExt) {
                ModalUtils.errorMessage(null, 'Only PDFs and Images are allowed');
                return;
            }

            const anyDuplicated = files.some((file) => (isAssociated ? state.associatedDocuments : state.documents)
                .some((doc) => doc.documentName?.toLowerCase() === file.name?.toLowerCase()));
            if (anyDuplicated) {
                ModalUtils.errorMessage(null, 'No duplicates allowed. Check files\' names');
                return;
            }

            setState((prevState) => ({
                ...prevState,
                isUploadingDocuments: true,
            }));

            const documentsToSave = await Promise.all(files.map(async (file) => {
                try {
                    let resized = file;
                    const imageSizeCapToResize = 512000; // 500KB

                    const split = resized.name.split('.');
                    if (split.length <= 1) return null;

                    const fileName = split.slice(0, split.length - 1).join('');
                    const ext = split[split.length - 1];
                    const purgedName = `${fileName}.${ext}`;

                    if (resized.size > imageSizeCapToResize) {
                        resized = await ImagesUtils.resizeImage(resized);
                    }

                    const { data } = await client.query({
                        query: isAssociated ? options.getAssociatedDocumentUploadSignedURL : options.getDocumentUploadSignedURL,
                        variables: {
                            ...(isInventory ? { stockNumber: options.referenceId } : {}),
                            ...(isDeals && !isAssociated ? { accountNumber: options.referenceId } : {}),
                            ...(isDeals && isAssociated ? { stockNumber: options.associatedReferenceId } : {}),
                            fileName: purgedName,
                        },
                        fetchPolicy: FetchPolicy.NO_CACHE,
                    });

                    const [name] = Object.keys(data || {});
                    if (!name) return null;

                    const { url, path } = data[name];
                    if (url && path) {
                        const { status } = await axios.put(url, resized);
                        if (status !== 200) return null;

                        return {
                            documentUrl: path,
                            documentSize: `${NumberUtils.round(resized.size / 1024 / 1024)}MB`,
                        };
                    }
                } catch (_) {
                    return null;
                }

                return null;
            }));

            const toSave = documentsToSave.filter((doc) => doc != null);
            if (toSave.length === 0) {
                setState((prevState) => ({
                    ...prevState,
                    isUploadingDocuments: false,
                }));

                return;
            }

            const payload = {
                variables: {
                    ...(isInventory ? { stockNumber: options.referenceId } : {}),
                    ...(isDeals && !isAssociated ? { accountNumber: options.referenceId } : {}),
                    ...(isDeals && isAssociated ? { stockNumber: options.associatedReferenceId } : {}),
                    input: toSave,
                },
            };

            if (isAssociated) createAssociatedDocuments(payload);
            if (!isAssociated) createDocuments(payload);
        }
    };

    return {
        documentsData,
        documentRename,
        deleteDocument,
        uploadDocuments,
        loadingDocuments,
        errorLoadingDocuments,
        renamingDocument,
        deletingDocument,
    };
};

export default useFilesManagerFunctions;

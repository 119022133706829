import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import EditorStyles from 'styles/widgets/EditorControlStyles';

const useStyles = makeStyles((theme) => EditorStyles.percentageControl(fade, theme));

const PercentageControl = ({ cell, onChange, onKeyDown }) => {
    const classes = useStyles();
    const { percentage } = cell.original;
    const percentageValue = percentage;

    const handleChange = (newValue) => {
        onChange(cell.column.id, newValue || 0, cell);
    };

    const handleKeyDown = (event) => {
        onKeyDown(cell, event);
    };

    return (
        <InputGroup className="mb-3">
            <Form.Control
                placeholder="Percentage"
                size="sm"
                value={percentageValue}
                onChange={(event) => handleChange(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event)}
            />
            <InputGroup.Append>
                <InputGroup.Text className={classes.inputGroupSM}>%</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};

PercentageControl.propTypes = {
    cell: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
};

PercentageControl.defaultProps = {
    onKeyDown: () => {},
};

export default PercentageControl;

import { gql } from '@apollo/client';

export default class PayrollSettingsMutation {
    static SAVE_PAYROLL_DEFAULT_SETTINGS = gql`
        mutation savePayrollDefaultSettings($input: PayrollDefaultSettingsInput!) {
            savePayrollDefaultSettings(input: $input)
        }
    `;

    static REMOVE_PAYROLL_MAPPING = gql`
        mutation removePayrollMappings($id: Int!) {
            removePayrollMappings(id: $id)
        }
    `;

    static SAVE_PAYROLL_MAP = gql`
        mutation savePayrollMapping($input: PayrollMappingInput!) {
            savePayrollMapping(input: $input)
        }
    `;

    static REMOVE_PAYROLL_DB_RECORD = gql`
        mutation removePayrollDBRecord($id: Int!) {
            removePayrollDBRecord(id: $id)
        }
    `;

    static SAVE_PAYROLL_ADJUSTMENT = gql`
        mutation savePayrollBDRecord($input: PayrollBDInput!) {
            savePayrollBDRecord(input: $input)
        }
    `;

    static SAVE_PAYROLL_CUSTOM_FIELDS = gql`
        mutation savePayrollCustomTaxFields($input: CustomTaxFieldsInput!) {
            savePayrollCustomTaxFields(input: $input)
        }
    `;
}

import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const CommentsField = React.memo(({
    className,
    currentValue,
    inspectionComplete,
    reconInspectionItemId,
    onChange,
}) => (
    <TextField
        disabled={inspectionComplete}
        className={className}
        value={currentValue}
        name="comment"
        onChange={({ target: { name, value } }) => onChange(name, value, reconInspectionItemId)}
        variant="outlined"
        size="small"
    />
), (prevProps, nextProps) => prevProps.inspectionComplete === nextProps.inspectionComplete
&& prevProps.currentValue === nextProps.currentValue);

CommentsField.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
    inspectionComplete: PropTypes.bool,
    reconInspectionItemId: PropTypes.number,
};

CommentsField.defaultProps = {
    inspectionComplete: false,
    reconInspectionItemId: 0,
};

export default CommentsField;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core';

const ownStyle = makeStyles((theme) => ({
    colorFooter: {
        background: theme.palette.background.default,
    },
}));

const ReportFooter = (props) => {
    const {
        currentPage, onPageChange, onRowsPerPageChange,
        rowsPerPage, totalRecords, labelRowsPerPage,
    } = props;

    const classes = {
        ...ownStyle(),
    };

    const [page, setPage] = useState(currentPage);

    const handleChangePage = (event, newPage) => {
        onPageChange({
            init: newPage * rowsPerPage,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        onRowsPerPageChange(Number(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        setPage(currentPage === 0 ? 0 : currentPage / rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return (
        <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={classes.colorFooter}
            labelRowsPerPage={labelRowsPerPage}
        />
    );
};

ReportFooter.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.number,
    totalRecords: PropTypes.number,
    labelRowsPerPage: PropTypes.string,
};

ReportFooter.defaultProps = {
    rowsPerPage: 25,
    totalRecords: 0,
    labelRowsPerPage: 'Rows per page',
};

export default ReportFooter;

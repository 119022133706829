import { gql } from '@apollo/client';

export default class Mutation {
    static SAVE_GOAL = gql`
        mutation saveGoal(
            $id: String
            $categoryId: String!
            $label: String!
            $type: String!
            $value: String!
            $active: Boolean
        ) {
            saveGoal(
                id: $id
                categoryId: $categoryId
                label: $label
                type: $type
                value: $value
                active: $active
            )
        }
    `;

    static SAVE_CATEGORY = gql`
        mutation saveCategory(
            $id: String
            $name: String!
            $active: Boolean
        ) {
            saveCategory(
                id: $id
                name: $name
                active: $active
            )
        }
    `;

    static SAVE_RULE = gql`
        mutation saveRule(
            $id: String
            $categoryId: String!
            $label: String!
            $query: String!
            $instructions: String!
            $active: Boolean
        ) {
            saveRule(
                id: $id
                categoryId: $categoryId
                label: $label
                query: $query
                instructions: $instructions
                active: $active
            )
        }
    `;

    static SAVE_ASSIGNED_USER = gql`
        mutation saveAssignedUser(
            $id: String
            $entityId: String
            $type: String
            $userId: Int!
            $active: Boolean
            $scheduleReport: Boolean
        ) {
            saveAssignedUser(
                id: $id
                entityId: $entityId
                type: $type
                userId: $userId
                active: $active
                scheduleReport: $scheduleReport
            )
        }
    `;

    static DELETE_CATEGORY = gql`
        mutation deleteCategory(
            $id: String!
        ) {
            deleteCategory(
                id: $id
            )
        }
    `;

    static SAVE_CHART = gql`
        mutation saveChart(
            $id: String
            $categoryId: String!
            $label: String!
            $query: String!
            $type: String!
            $options: String!
            $active: Boolean
            $frequency: String
            $startOn: Date
            $customReport: Boolean
        ) {
            saveChart(
                id: $id
                categoryId: $categoryId
                label: $label
                query: $query
                type: $type
                options: $options
                active: $active
                frequency: $frequency
                startOn: $startOn
                customReport: $customReport
            )
        }
    `;

    static SAVE_DASHBOARD_COMPONENTS = gql`
        mutation saveDashboardComponents(
            $input: [dashboardComponentInput!]!
        ) {
            saveDashboardComponents(
                input: $input
            ) {
                id
                staticName
                chartId
            }
        }
    `;

    static PROCESS_BUSINESS_INTELLIGENT_REPORT = gql`
        mutation processBusinessIntelligentReport($businessIntelligenceChartId: ID!) {
            processBusinessIntelligentReport(businessIntelligenceChartId: $businessIntelligenceChartId)
        }
    `;
}

import React, { Component } from 'react';

// Components and others
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import { Carousel as RBCarousel } from 'react-bootstrap';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Card, CardMedia, CardContent, CardActions, Typography,
    Button,
} from '@material-ui/core';

const styles = (theme) => ({
    root: {
        maxWidth: 430,
    },
    media: {
        height: 0,
        paddingTop: '76.25%',
        backgroundSize: 'contain',
    },
    button: {
        margin: theme.spacing(0, 1),
    },
});

class Carousel extends Component {
    render() {
        const { Item } = RBCarousel;
        const { props: { vehicles, classes } } = this;

        return (
            <RBCarousel prevLabel="" nextLabel="">
                { vehicles.map((item) => {
                    const {
                        title, imageURI, subTitle, buttons,
                    } = item;

                    return (
                        <Item key={imageURI}>
                            <Card className={classes.root}>
                                <CardMedia
                                    className={classes.media}
                                    image={imageURI}
                                    title="Paella dish"
                                />
                                <CardContent>
                                    <Typography variant="h5">
                                        {title}
                                    </Typography>
                                    <Typography variant="h6">
                                        {subTitle}
                                    </Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                    {isArray(buttons) && buttons.map((buttonItem) => {
                                        const { text } = buttonItem;

                                        return (<Button variant="contained" key={text} disabled fullWidth className={classes.button}>{text}</Button>);
                                    })}
                                </CardActions>
                            </Card>
                        </Item>
                    );
                }) }

            </RBCarousel>
        );
    }
}

Carousel.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(Carousel);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import {
    TaskStatus,
} from 'utils/enum/ActivitiesEnum';
import { makeStyles, Divider } from '@material-ui/core';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';

// Icon
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateUtils, { DateFormat } from 'lib/DateUtils';

const useStyles = makeStyles((theme) => ({
    blueIcon: {
        '& g': {
            stroke: theme.palette.info.main,
        },
        color: theme.palette.info.main,
    },
    redIcon: {
        '& g': {
            stroke: theme.palette.error.main,
        },
    },
    greenIcon: {
        '& g': {
            stroke: theme.palette.success.main,
        },
    },
    container: {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    subject: {
        marginLeft: theme.spacing(1),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    subHeader: {
        padding: theme.spacing(1, 2),
    },
    footer: {
        padding: theme.spacing(3, 2),
    },
}));

const getHeaderInformation = (record, type, classes) => {
    const { status } = record;
    const currentStatus = StringUtils.toUpperCase(status);
    const description = `${type} ${status}`;

    switch (currentStatus) {
    case TaskStatus.COMPLETED:
        return { icon: <CheckBoxOutlinedIcon className={classes.blueIcon} />, description };

    case TaskStatus.TO_DO:
        return { icon: <CheckBoxOutlineBlankIcon className={classes.blueIcon} />, description };

    default:
        return { icon: '', description: '' };
    }
};

const TaskActivityItem = ({ record, onOpen }) => {
    const classes = useStyles();
    const basicInformation = getHeaderInformation(record.task, record.type, classes);
    const date = DateUtils.format(record.realizationDate, DateFormat.LONG_DATE_TIME_AM_PM);

    return (
        <div className={clsx(classes.container, 'box-shadow')} onDoubleClick={() => onOpen(record, true)}>
            <div className={classes.header}>
                <div className="d-flex-center">
                    {basicInformation.icon}
                    <span className={classes.subject}>{basicInformation.description}</span>
                </div>
                <CreatedOnWithAvatar
                    record={record}
                />
            </div>
            <Divider />
            <div className={classes.subHeader}>
                <span>Date: </span>
                <span>{date}</span>
            </div>
            <Divider />
            <div className={clsx(classes.footer, 'text-pre-line')}>
                {record.note}
            </div>
        </div>
    );
};

TaskActivityItem.propTypes = {
    onOpen: PropTypes.func,
    record: PropTypes.object.isRequired,
};

TaskActivityItem.defaultProps = {
    onOpen: () => {},
};

export default TaskActivityItem;

import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

// material
import {
    Divider, Dialog,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import Jodit from 'components/widgets/editor/JoditEditor';
import usePrevious from 'components/hook/core/usePrevious';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import UserSettingMutation from 'services/graphQL/mutate/setting/UserSettingMutation';

export const configuration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 400,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    placeholder: 'Type a message',
    buttons: 'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush,source',
};

const initState = {
    emailSignature: '',
    userSettingId: null,
};

const ACTION_TYPE = {
    SET_INNIT_VALUES: 'setInitValues',
    SET_STATE_VALUE: 'setStateValue',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_STATE_VALUE: {
        return update(state, {
            emailSignature: { $set: action.payload },
        });
    }
    case ACTION_TYPE.SET_INNIT_VALUES: {
        const record = action.payload || {};
        return update(state, {
            emailSignature: { $set: record.emailSignature },
            userSettingId: { $set: record.userSettingId },
        });
    }
    default:
        return state;
    }
};

const EmailSignatureDialog = ({
    onClose, open, record, refetch,
}) => {
    const [state, dispatch] = useReducer(reducer, initState);
    const emailSignaturePrevious = usePrevious(record);
    const [saveEmailSignature, { loading: saving }] = useMutation(UserSettingMutation.CREATE_EMAIL_SIGNATURE);
    const [updateEmailSignature, { loading: updating }] = useMutation(UserSettingMutation.UPDATE_EMAIL_SIGNATURE);

    useEffect(() => {
        if (!isEqual(emailSignaturePrevious, record)) {
            dispatch({
                type: ACTION_TYPE.SET_INNIT_VALUES,
                payload: record,
            });
        }
    }, [emailSignaturePrevious, record]);

    const onChangeValue = (value) => {
        dispatch({
            type: ACTION_TYPE.SET_STATE_VALUE,
            payload: value,
        });
    };

    const onSave = async () => {
        try {
            const response = await saveEmailSignature({ variables: { emailSignature: state.emailSignature } });
            if (response?.data?.createEmailSignature) {
                onClose();
                refetch();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('creating', 'email signature'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onUpdate = async () => {
        try {
            const response = await updateEmailSignature({ variables: { emailSignature: state.emailSignature } });

            if (response?.data?.updateEmailSignature) {
                onClose();
                refetch();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('updating', 'email signature'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onClick = () => {
        if (state.userSettingId == null) {
            onSave();
        } else {
            onUpdate();
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
        >
            <DialogAppBar title="Email Signature" onClose={onClose} iconSize="sm" />
            <Jodit
                config={configuration}
                onChange={onChangeValue}
                value={state.emailSignature}
            />
            <Divider />
            <DialogActions
                divider={false}
                hiddenSecondaryButton
                onClickPrimary={onClick}
                disablePrimaryButton={saving || updating}
            />
        </Dialog>
    );
};

EmailSignatureDialog.propTypes = {
    open: PropTypes.bool,
    refetch: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

EmailSignatureDialog.defaultProps = {
    open: false,
};

export default EmailSignatureDialog;

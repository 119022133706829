import React, {
    useReducer, useRef, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, useTheme, useMediaQuery,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Split from 'react-split';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import CloseIcon from '@material-ui/icons/Close';
import { SaveIcon } from 'components/icons';
import JournalDistribution from 'components/modules/accounting/journal/list/JournalDistribution';
import { Col, Form } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema, getErrorMessage } from 'utils/schema/utils';
import InvoiceSchema from 'utils/schema/accounting/accountPayable/InvoiceSchema';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import ModalUtils from 'utils/ModalUtils';
import AccountPayableMapper from 'services/mapData/AccountPayableMapper';
import UserContext from 'components/context/UserContext';
import InvoiceReducer, { ACTION_TYPES } from 'components/modules/accounting/accountsPayable/reducer/InvoiceReducer';
import AccountPayableMutation from 'services/graphQL/mutate/accounting/AccountPayableMutation';
import AccountPayableQuery from 'services/graphQL/query/accounting/AccountPayableQuery';
import useValidateInvoiceActions from 'components/modules/accounting/accountsPayable/hooks/useValidateInvoiceActions';
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import NumberUtils from 'lib/NumberUtils';
import {
    AccountingCOAType, InvoiceStatus, ProcessType, TransactionType,
} from 'utils/enum/AccountingEnum';
import AsyncCreatableSelect from 'react-select/async-creatable';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import {
    DataSort, FetchPolicy, ProcessTypeDescription,
} from 'utils/enum/Core';
import DropdownQuery from 'components/widgets/DropdownQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import DateUtils from 'lib/DateUtils';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import PropTypes from 'prop-types';
import ProcessFiles from 'components/widgets/processFiles/processFiles';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const InvoiceCreate = (props) => {
    const keyStore = new KeyStore();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const INVOICE_WRITE = keyStore.hasPermission(Permission.INVOICE_WRITE);

    const {
        vendorSelectedId, vendorSelectedName, onCloseInvoice,
        invoiceReferenceSelectedId, isCreditFromInvoice, id,
    } = props;

    const initialState = {
        record: {
            invoiceId: 0,
            vendorId: 0,
            vendorName: vendorSelectedName,
            invoiceNumber: '',
            billDate: new Date(),
            invoiceAmount: 0,
            amountDue: 0,
            dueDate: new Date(),
            memo: '',
            isCredit: false,
            invoiceReferenceId: null,
            lotId: 0,
            accountNumber: 0,
            status: InvoiceStatus.NEW,
        },
        isDirty: false,
        couldLostData: false,
        paidToList: [],
        isDirtyFiles: false,
    };

    const classes = useStyle();
    const journalRef = useRef();
    const filesRef = useRef();

    const [state, dispatch] = useReducer(InvoiceReducer, initialState);
    const {
        record, isDirty, couldLostData, paidToList, isDirtyFiles,
    } = state;

    const isValidData = isValidSchema(InvoiceSchema, record);
    const { isValid, errors } = isValidData;

    const { defaultLotId } = useContext(UserContext);

    if (defaultLotId && defaultLotId > 0 && record.lotId === 0) {
        dispatch({ type: ACTION_TYPES.SET_DEFAULT_LOT, value: defaultLotId });
    }

    const {
        editAccountNumber,
        editLot,
        editPayTo,
        editMemo,
        editDate,
        editInvoiceNumber,
        editIsCredit,
        allowSaveAction,
        glOptions,
    } = useValidateInvoiceActions(record.status, ProcessType.ACCOUNTS_PAYABLE);
    const [getInvoice, { loading }] = useLazyQuery(AccountPayableQuery.GET_INVOICE, {
        onCompleted: (res) => {
            const currentRecord = res?.getInvoice;
            if (currentRecord) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: {
                            ...currentRecord,
                            billDate: new Date(DateUtils.getOnlyDate(currentRecord.billDate)),
                            dueDate: new Date(DateUtils.getOnlyDate(currentRecord.dueDate)),
                        },
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getDefaultBankSettings] = useLazyQuery(AccountingQuery.GET_ACCOUNTS_PAYABLE_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getAccountsPayableDefaultSettings) {
                const { accountsPayable } = res.getAccountsPayableDefaultSettings;

                const currentRecord = record;
                currentRecord.accountNumber = Number(accountsPayable);

                dispatch({ type: ACTION_TYPES.SET_RECORD, value: currentRecord });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                isDirty: false,
            },
        });

        onCloseInvoice();
    };

    const onPopupClose = (event, forceClose = false) => {
        if ((isDirty || isDirtyFiles) && !forceClose) {
            dispatch({ type: ACTION_TYPES.SET_ON_POPUP_CLOSE });
            return;
        }

        onClearForm();
        onCloseAction();
    };

    const mutationQuery = record.invoiceId > 0 ? AccountPayableMutation.UPDATE_INVOICE : AccountPayableMutation.CREATE_INVOICE;
    const [addRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.createInvoice || mutationData?.updateInvoice) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async (requestedNew) => {
        let resultSave = false;
        let recordToSave = { ...record };

        if (!isDirty && filesRef.current.getIsDirty()) {
            const resultUploadFiles = await filesRef.current.startToUpload();

            resultSave = resultUploadFiles;
        } else {
            const { records, errors: glLinesErrors } = journalRef.current.getRecords();

            if (records.length === 0) {
                ModalUtils.errorMessage(null, 'The GL lines are empty');
                return;
            }

            if (glLinesErrors.length > 0) {
                ModalUtils.errorMessage(glLinesErrors);
                return;
            }

            recordToSave.accountingLines = records;

            const linesSumAmount = records.reduce((sum, next) => sum + next.amount, 0);

            if (NumberUtils.round(record.invoiceAmount) !== NumberUtils.round(linesSumAmount)) {
                ModalUtils.errorMessage([{ message: 'The sum of the lines must be equals to the total amount of the invoice.' }]);
                return;
            }

            recordToSave = AccountPayableMapper.mapInvoiceToSave(recordToSave);

            const resultAfterSave = await addRecord({
                variables: {
                    record: recordToSave,
                },
            });

            const currentInvoiceId = Number(resultAfterSave?.data?.createInvoice) || Number(resultAfterSave?.data?.updateInvoice);
            resultSave = currentInvoiceId > 0;

            if (resultSave) {
                const resultUploadFiles = await filesRef.current.startToUpload(currentInvoiceId);
                resultSave = resultUploadFiles;

                if (!resultSave && record.invoiceId === 0) {
                    dispatch({
                        type: ACTION_TYPES.SET_RECORD,
                        value: {
                            ...record,
                            invoiceId: currentInvoiceId,
                        },
                        isDirty: false,
                    });
                }
            }
        }

        if (resultSave) {
            if (!requestedNew) {
                onPopupClose(null, true);
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW,
                    value: initialState,
                });

                journalRef.current.clear();

                filesRef.current.clear();
            }
        }
    };

    const onChangeValue = async (field, value) => {
        if (value === record[field]) return;

        if (field === 'invoiceAmount') {
            dispatch({
                type: ACTION_TYPES.SET_AMOUNT,
                value,
            });
            return;
        }

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const [getVendorList, { loading: loadingVendor }] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            sort: {
                field: 'vendorName',
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const vendorPaidToList = res.getVendorList.map((item) => ({
                    paidToId: item.vendorId,
                    paidTo: item.vendorName,
                    address: item.vendorAddress,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        paidToList: vendorPaidToList,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (id > 0) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: {
                    ...record,
                    invoiceId: Number(id),
                },
            });

            getInvoice({
                variables: {
                    invoiceId: Number(id),
                },
            });
        }

        getVendorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!id || id === 0) {
            getDefaultBankSettings();

            if (vendorSelectedId && vendorSelectedId > 0) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        vendorId: vendorSelectedId,
                        vendorName: vendorSelectedName,
                        isCredit: isCreditFromInvoice,
                        invoiceReferenceId: isCreditFromInvoice ? invoiceReferenceSelectedId : null,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDefaultBankSettings, id, vendorSelectedId, isCreditFromInvoice, invoiceReferenceSelectedId]);

    const onChangePaidToValue = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                ...value,
            },
        });
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    const getAsyncOptionsFiltered = (inputValue) => new Promise((resolve) => {
        const filtered = paidToList.filter((data) => data.paidTo.toLowerCase().includes(inputValue.toLowerCase()));
        resolve(filtered.slice(0, paidToList.length));
    });

    const invoiceLabel = record.isCredit ? 'Credit' : 'Invoice';

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === InvoiceStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record?.status.toLowerCase() === InvoiceStatus.PAID.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record?.status.toLowerCase() === InvoiceStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
        else if (record?.status.toLowerCase() === InvoiceStatus.PARTIALLY_PAID.toLowerCase()) badgeStatus = classes.badgePrinted;

        return (
            <div className={classes.margin5}>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </div>
        );
    };

    const enableBtnSave = (isValid && isDirty
        && allowSaveAction
        && !isSaving
    ) || (
        allowSaveAction
            && !isSaving
            && isDirtyFiles
    );

    return (
        <Dialog
            open
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        {INVOICE_WRITE && (
                            <Button
                                className={classes.buttonSave}
                                startIcon={<SaveIcon />}
                                size="small"
                                disabled={!enableBtnSave}
                                onClick={() => onSave(false)}
                            >
                                Save
                            </Button>
                        )}
                        {(INVOICE_WRITE && allowSaveAction)
                        && (record.status.toLowerCase() === InvoiceStatus.NEW.toLowerCase())
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(true)}
                                    >
                                        Save & New
                                    </Button>
                                )}
                    </div>
                    <div className={classes.centerItems}>
                        <Typography variant="h6" className={classes.title}>
                            { record.invoiceId === 0 ? `New ${invoiceLabel}` : `Edit ${invoiceLabel} ${record.invoiceNumber}` }
                        </Typography>
                        { getStatusChip() }
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                { loading ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Split
                                sizes={isMobile ? [70, 30] : [35, 65]}
                                className={isMobile ? classes.verticalSplit : 'split'}
                                direction={isMobile ? null : 'horizontal'}
                            >
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={isMobile ? 12 : 7}>
                                            <Form.Group as={Col}>
                                                <Form.Label />
                                                <Form.Check
                                                    id="isCreditInvoiceId"
                                                    checked={record.isCredit}
                                                    type="checkbox"
                                                    label="Is Credit"
                                                    className="am-mt-8"
                                                    onChange={(e) => onChangeValue('isCredit', e.target.checked)}
                                                    disabled={isCreditFromInvoice || !editIsCredit}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Vendor *</Form.Label>
                                                <AsyncCreatableSelect
                                                    defaultOptions={paidToList}
                                                    isValidNewOption={() => (false)}
                                                    getOptionLabel={(option) => option.paidTo ?? ''}
                                                    getOptionValue={(option) => option.paidToId}
                                                    isClearable
                                                    className={clsx('form-control select-sm',
                                                        classes.selectSM, isValidField(errors, 'vendorId') ? 'invalid-field' : '')}
                                                    classNamePrefix="select"
                                                    loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                                                    isLoading={loadingVendor}
                                                    isDisabled={loadingVendor || !editPayTo || isCreditFromInvoice}
                                                    value={!record.vendorId ? null : { paidToId: record.vendorId, paidTo: record.vendorName }}
                                                    onChange={(newValue) => {
                                                        onChangePaidToValue({
                                                            vendorId: newValue?.paidToId ?? 0,
                                                            vendorName: newValue?.paidTo ?? '',
                                                            entityAddress: newValue?.address ?? '',
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">{`${invoiceLabel} #`}</Form.Label>
                                                <Form.Control
                                                    className={isValidField(errors, 'invoiceNumber') ? 'invalid-field' : ''}
                                                    size="sm"
                                                    type="text"
                                                    value={record.invoiceNumber}
                                                    placeholder=""
                                                    name="invoiceNumber"
                                                    onChange={(e) => onChangeValue('invoiceNumber', e.target.value)}
                                                    disabled={!editInvoiceNumber}
                                                />
                                                <p className={classes.fontSmall}>{getErrorMessage('invoiceNumber', errors)}</p>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Account # *</Form.Label>
                                                <DropdownQuery
                                                    name="accountNumber"
                                                    disabled={!editAccountNumber}
                                                    className={isValidField(errors, 'accountNumber') ? 'invalid-field' : ''}
                                                    value={record.accountNumber}
                                                    placeHolder="select the account"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                        variables: {
                                                            paginate: {
                                                                init: 0,
                                                                ignoreLimit: true,
                                                            },
                                                            filters: {
                                                                accountTypes: [AccountingCOAType.ACCOUNTS_PAYABLE],
                                                            },
                                                        },
                                                        rootData: 'getAccountingCOAList.data',
                                                        idField: 'accountNumber',
                                                        descriptionField: 'fullDescription',
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Lot</Form.Label>
                                                <DropdownQuery
                                                    name="lotId"
                                                    disabled={!editLot}
                                                    className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                                    value={record.lotId}
                                                    placeHolder="select a lot"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: LotQuery.GET_LOTS,
                                                        rootData: 'lotList',
                                                        idField: 'lotId',
                                                        descriptionField: 'lotName',
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 5}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">{`${invoiceLabel} Date *`}</Form.Label>
                                                <DatePicker
                                                    selected={record.billDate}
                                                    disabled={!editDate}
                                                    name="billDate"
                                                    size="sm"
                                                    className={clsx('form-control form-control-sm',
                                                        isValidField(errors, 'billDate') ? 'invalid-field' : '')}
                                                    placeholderText="mm/dd/yyyy"
                                                    popperContainer={CalendarContainer}
                                                    wrapperClassName="col"
                                                    onChange={(date) => onChangeValue('billDate', date)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Due Date *</Form.Label>
                                                <DatePicker
                                                    selected={record.dueDate}
                                                    disabled={!editDate}
                                                    name="dueDate"
                                                    size="sm"
                                                    className={clsx('form-control form-control-sm',
                                                        isValidField(errors, 'dueDate') ? 'invalid-field' : '')}
                                                    placeholderText="mm/dd/yyyy"
                                                    popperContainer={CalendarContainer}
                                                    wrapperClassName="col"
                                                    onChange={(date) => onChangeValue('dueDate', date)}
                                                    minDate={record.billDate}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Amount Due</Form.Label>
                                                <InputNumber
                                                    disabled
                                                    size="sm"
                                                    showCurrency
                                                    value={record.amountDue}
                                                    thousandSeparator
                                                    placeholder="Amount Due"
                                                    name="amountDue"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Amount</Form.Label>
                                                <InputNumber
                                                    disabled
                                                    size="sm"
                                                    showCurrency
                                                    value={record.invoiceAmount}
                                                    thousandSeparator
                                                    placeholder="Invoice Amount"
                                                    onChange={(value) => onChangeValue('invoiceAmount', value)}
                                                    name="invoiceAmount"
                                                />
                                            </Form.Group>
                                            {record.isCredit && Number(record.vendorId) > 0
                                            && (
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">Invoice Reference #</Form.Label>
                                                    <DropdownQuery
                                                        name="invoiceReferenceId"
                                                        disabled={!editInvoiceNumber || record.vendorId <= 0}
                                                        value={record.invoiceReferenceId}
                                                        placeholder="Select"
                                                        onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                        dataSource={{
                                                            query: AccountPayableQuery.GET_INVOICE_LIST_DROPDOWN,
                                                            variables: {
                                                                paginate: {
                                                                    init: 0,
                                                                    limit: 50,
                                                                    ignoreLimit: true,
                                                                },
                                                                filter: {
                                                                    vendorId: Number(record.vendorId),
                                                                    payType: 'Due',
                                                                    onlyInvoice: true,
                                                                },
                                                                sort: [{
                                                                    fieldName: 'dueDate',
                                                                    dir: DataSort.ASC,
                                                                }],
                                                            },
                                                            rootData: 'getInvoiceList.data',
                                                            idField: 'invoiceId',
                                                            descriptionField: 'invoiceNumber',
                                                        }}
                                                    />
                                                </Form.Group>
                                            ) }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Memo</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={!editMemo}
                                                    size="sm"
                                                    rows="2"
                                                    value={record.memo}
                                                    placeholder="Memo"
                                                    onChange={(e) => onChangeValue('memo', e.target.value)}
                                                    name="memo"
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Form.Group as={Col}>
                                            <Form.Label className="required">Files Attachments</Form.Label>
                                            <ProcessFiles
                                                ref={filesRef}
                                                referenceId={Number(record.invoiceId)}
                                                referenceType={ProcessTypeDescription.BILL}
                                                notifyDirtyFiles={(val) => dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isDirtyFiles: val,
                                                    },
                                                })}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </div>
                                <JournalDistribution
                                    processRecordId={record.invoiceId}
                                    processTypeId={record.isCredit ? ProcessType.BILL_CREDIT : ProcessType.BILL}
                                    writePermissions={INVOICE_WRITE}
                                    ref={journalRef}
                                    onAmountChange={(value) => onChangeValue('invoiceAmount', value)}
                                    onDirty={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { isDirty: true } })}
                                    glOptions={glOptions}
                                    transactionAmount={record.invoiceAmount}
                                    transactionType={record.isCredit ? TransactionType.DEBIT : TransactionType.CREDIT}
                                />
                            </Split>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={onCloseFormWithoutSave}
                                onClickSecondary={onCloseFormWithoutSave}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                        </Container>
                    )}
            </DialogContent>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

InvoiceCreate.propTypes = {
    vendorSelectedId: PropTypes.number,
    vendorSelectedName: PropTypes.string,
    onCloseInvoice: PropTypes.func,
    invoiceReferenceSelectedId: PropTypes.number,
    isCreditFromInvoice: PropTypes.bool,
    id: PropTypes.number,
};

InvoiceCreate.defaultProps = {
    vendorSelectedId: 0,
    vendorSelectedName: '',
    onCloseInvoice: () => {},
    invoiceReferenceSelectedId: null,
    isCreditFromInvoice: false,
    id: 0,
};

export default InvoiceCreate;

import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import {
    CHART_COLUMN_DATA_TYPE,
    AGGREGATE_FUNCTIONS,
} from 'utils/enum/BusinessIntelligenceEnum';

// icons
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const useStyles = makeStyles(() => ({
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        fontSize: '12px',
    },
}));

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 150,
        },
    },
    getContentAnchorEl: null,
};

const InputColumn = ({
    index,
    field,
    column,
    dataType,
    aggregation,
    prepareItem,
    reorderItems,
    removeColumn,
    updateChartOptions,
}) => {
    const classes = useStyles();

    return (
        <div
            draggable
            data-item-section="input"
            data-item-property={JSON.stringify(field)}
            data-item-index={index}
            onDragStart={(e) => prepareItem(e)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => reorderItems(e, index)}
        >
            <span className={classes.text}>{`${index + 1}. ${column}`}</span>
            <div>
                {field.name === 'PivotValues' && (
                    <Select
                        disabled={dataType !== CHART_COLUMN_DATA_TYPE.NUMERIC}
                        displayEmpty
                        name="aggregation"
                        value={aggregation ?? ''}
                        onChange={({ target }) => updateChartOptions('input', 'PivotValues', { value: column, aggregation: target.value })}
                        MenuProps={MenuProps}
                    >
                        {Object.values(AGGREGATE_FUNCTIONS).map((func, idx) => (
                            <MenuItem key={idx} value={func}>
                                <ListItemText primary={func.toLocaleLowerCase()} />
                            </MenuItem>
                        ))}
                    </Select>
                )}
                <HighlightOffOutlinedIcon onClick={() => removeColumn(index, field.name)} />
            </div>
        </div>
    );
};

InputColumn.defaultProps = {
    index: -1,
    field: {},
    column: '',
    dataType: CHART_COLUMN_DATA_TYPE.NUMERIC,
    aggregation: AGGREGATE_FUNCTIONS.AVG,
    updateChartOptions: () => null,
};

InputColumn.propTypes = {
    index: PropTypes.number,
    field: PropTypes.object,
    column: PropTypes.string,
    dataType: PropTypes.string,
    aggregation: PropTypes.string,
    prepareItem: PropTypes.func.isRequired,
    reorderItems: PropTypes.func.isRequired,
    removeColumn: PropTypes.func.isRequired,
    updateChartOptions: PropTypes.func,
};

export default InputColumn;

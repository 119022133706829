import { gql } from '@apollo/client';

export default class PlaidMappingsMutation {
    static REMOVE_PLAID_MAPPING = gql`
        mutation removePlaidMapping($id: ID!) {
            removePlaidMapping(id: $id)
        }
    `;

    static UPSERT_PLAID_MAPPING = gql`
        mutation upsertPlaidMapping($input: PlaidMappingInput!, $mapId: ID) {
            upsertPlaidMapping(input: $input, mapId: $mapId)
        }
    `;

    static UPSERT_PLAID_BANK = gql`
        mutation upsertPlaidBank($input: PlaidBankInput!) {
            upsertPlaidBank(input: $input)
        }
    `;
}

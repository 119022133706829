import React from 'react';
import PropTypes from 'prop-types';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { FetchPolicy } from 'utils/enum/Core';
import { isEmpty } from 'lodash';

const SelectControl = (props) => {
    const {
        name, value, onChange, dataSource, className, ...other
    } = props;
    const otherProps = { ...other };
    // delete to not include as dom property
    delete otherProps.comparePropertyId;
    dataSource.fetchPolicy = FetchPolicy.CACHE_FIRST;

    return (
        <DropdownQuery
            name={name}
            className={className}
            value={value}
            onChange={(id, newValue, editorCellObject, additionalFieldsResult) => onChange(id, newValue, editorCellObject, additionalFieldsResult)}
            dataSource={dataSource}
            {...otherProps}
        />
    );
};

SelectControl.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    dataSource: PropTypes.object.isRequired,
    editorCellObject: PropTypes.object,
    className: PropTypes.string,
    comparePropertyId: PropTypes.number,
    defaultEmptyLineText: PropTypes.string,
};

SelectControl.defaultProps = {
    value: null,
    className: '',
    editorCellObject: {},
    comparePropertyId: 0,
    defaultEmptyLineText: 'Select ...',
};

const areEqual = (prevProps, nextProps) => prevProps.value === nextProps.value
    && prevProps.className === nextProps.className
    && prevProps.comparePropertyId === nextProps.comparePropertyId
    && (!isEmpty(prevProps.dataSource?.localData) && !isEmpty(nextProps.dataSource?.localData)
    && prevProps.dataSource?.localData?.length === nextProps.dataSource?.localData?.length
    && prevProps.dataSource?.query === nextProps.dataSource?.query
    );
export default React.memo(SelectControl, areEqual);

import React from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import ErrorMessages from 'utils/enum/ErrorMessages';

const globalOptions = {
    autoClose: 6000, // 6 seconds
};

class ModalUtils {
    static errorMessage(messages = [], message = null, record) {
        /* TODO: Remove the validation when graphql fixes the problem send multiple errors
        An array of errors is expected
        Currently sends an array containing the array of errors */
        const currentMessage = Array.isArray(messages) && Array.isArray(messages[0]) ? messages[0] : messages;

        if (messages?.some((x) => x.message === ErrorMessages.FORBIDDEN)) {
            toast.error("You don't have permission for this action");
            return;
        }

        const content = this.getMessageContent(currentMessage, message);
        if (record) {
            const options = {
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                draggable: false,
                toastId: 'customToast',
            };

            toast(() => (
                <ConfirmDialog
                    open
                    title={record.title}
                    variant="outlined"
                    titlePrimary="Ok"
                    hiddenSecondaryButton
                    onClose={() => toast.dismiss(options.toastId)}
                    onClickPrimary={() => toast.dismiss(options.toastId)}
                >
                    <Typography className="custom-toast-typography">{record.description}</Typography>
                    {currentMessage.map((item, index) => (
                        <Typography className="custom-toast-typography" key={index}>{`- ${item.message}`}</Typography>
                    ))}
                </ConfirmDialog>
            ), options);
        } else {
            toast.error(content, globalOptions);
        }
    }

    static warningMessage(messages = [], message = null) {
        const content = this.getMessageContent(messages, message);
        toast.warning(content, globalOptions);
    }

    static infoMessage(messages = [], message = null) {
        const content = this.getMessageContent(messages, message);
        toast.info(content, globalOptions);
    }

    static successMessage(messages = [], message = null) {
        const content = this.getMessageContent(messages, message);
        toast.success(content, globalOptions);
    }

    static getMessageContent(messages, message) {
        let content;

        if (Array.isArray(messages) && messages.length > 0) {
            content = messages.map((item, index) => (
                <p key={index}>{`${item.Message || item.message}`}</p>
            ));
        } else if (message !== null) {
            content = <p>{ `${message}` }</p>;
        } else {
            content = <p>Something went wrong</p>;
        }

        const Message = () => (
            <div>
                {content}
            </div>
        );

        return Message;
    }
}

export default ModalUtils;

import React from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));

const FloorplanPanel = ({ data }) => {
    const classes = useStyles();
    const floorPlanned = data.isFloorPlanned ? 'Yes' : 'No';

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            <DisplayField
                field="Floor Planned"
                value={floorPlanned}
            />
            <DisplayField
                field="Floor Date"
                value={data.floorPlannedDate}
                type="date"
            />
            <DisplayField
                field="Flooring Company"
                value={data.flooredBy}
            />
            <DisplayField
                field="Curtailment"
                value={data.curtailmentDate}
                type="date"
            />
            <DisplayField
                field="Amount Borrowed"
                value={data.borrowedAmount}
                type="money"
            />
            <DisplayField
                field="Floor Paid On"
                value={data.floorPaidOn}
                type="date"
            />
        </Grid>
    );
};

FloorplanPanel.propTypes = {
    data: PropTypes.object,
};

FloorplanPanel.defaultProps = {
    data: {},
};

export default FloorplanPanel;

/* eslint-disable brace-style */
import React, {
    useReducer, useEffect, useRef, useContext,
} from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import PropTypes from 'prop-types';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { JournalTypes, ShowJournalType } from 'utils/enum/AccountingEnum';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import Category, { LotDefaultskey } from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';
import useJournalMethods from 'components/modules/accounting/journal/hooks/useJournalMethods';
import HeaderJournalCommon from 'components/modules/accounting/journal/list/HeaderJournalCommon';
import CommonJournalDetailDistributionList from 'components/modules/accounting/journal/list/CommonJournalDetailDistributionList';
import AccountingContext from 'components/context/AccountingContext';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_JOURNALID: 'setSelectedRow',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_SELECTED_JOURNALID: {
        return {
            ...state,
            selectedJournal: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            selectedJournal: action.selectedJournal,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    default: return action.value;
    }
};

const CommonJournalList = (props) => {
    const classes = useStyle();
    const { combineAccounting, lotName } = useContext(AccountingContext);
    const { journalType } = props;
    const journalRef = useRef();

    const isJournalTypeInventory = journalType === JournalTypes.INVENTORY;

    const initState = {
        params: {
            init: 0,
            limit: 50,
            search: '',
            fromDate: '',
            toDate: '',
            radioOption: isJournalTypeInventory ? ShowJournalType.SHOW_NO_POSTED_ACTIVE : ShowJournalType.SHOW_NO_POSTED,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedJournal: '',
        dataFromSubscription: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        params, table, selectedJournal, params: { radioOption },
    } = state;

    const {
        graphQLMethod, processColumns, responseMethod, primaryKey, sort, dateFieldColumn, labelPostedOption,
    } = useJournalMethods(journalType, radioOption);

    const recordIdSelected = selectedJournal?.[primaryKey];

    const { data, loading, error } = useQuery(graphQLMethod, {
        variables: {
            paginate: {
                init: params.init,
                limit: params.limit,
            },
            filter: {
                search: params.search,
                fromDate: params.fromDate,
                toDate: params.toDate,
                showPosted: radioOption === ShowJournalType.SHOW_POSTED,
                showNoPosted: radioOption === ShowJournalType.SHOW_NO_POSTED,
                showNoPostedActive: radioOption === ShowJournalType.SHOW_NO_POSTED_ACTIVE,
                ...(!combineAccounting ? { lotName } : {}),
            },
            sort,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getLockedDate] = useLazyQuery(LotQuery.GET_SETTINGS, {
        onCompleted: (res) => {
            if (!res.getSettings[0]) {
                ModalUtils.errorMessage(null, "There isn't configuration for accounting lockout date, some features may not work as expected");
                return;
            }
            const currentLockDate = new Date(res.getSettings[0].value);
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    minPostDate: currentLockDate,
                },
            });
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const loadMore = () => {
        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                init: table?.records?.length || 0,
            },
        });
    };

    const setParams = (parameters) => {
        journalRef.current.clear();
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                init: 0,
                ...parameters,
            },
        });
    };

    useEffect(() => {
        getLockedDate({
            variables: {
                category: Category.ACCOUNTING,
                key: [LotDefaultskey.DATE_LOCK],
                lotName: ALL_LOTS,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const result = data[responseMethod];

            records.push(...result.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: result.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useSubscription(AccountingSubscription.POSTED_JOURNAL_PROCESS, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { id, data: journalTypeId, action } = subscriptionData?.data?.postedJournalProcess;
            if (action === SubscriptionActionType.UPDATED
                && journalTypeId.toLowerCase() === journalType.toLowerCase()) {
                const resultRecords = table.records.filter((c) => Number(c[primaryKey]) !== Number(id));
                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: resultRecords,
                        totalCount: state.table.totalCount,
                        selectedJournal: null,
                    },
                });
            }
        },
    });

    return (
        <>
            <HeaderJournalCommon
                journalType={journalType}
                setParameters={setParams}
                totalCount={table.totalCount}
                loading={loading}
                labelPostedOption={labelPostedOption}
            />
            <Container className={classes.containerSplit}>
                <Split
                    sizes={[45, 55]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        rowSelected
                        cursor="default"
                        load={loading}
                        loadMore={loadMore}
                        totalRecords={table.totalCount}
                        data={table.records}
                        columns={processColumns}
                        className="-highlight"
                        getTrProps={(_, rowInfo) => ({
                            onDoubleClick: () => {
                                if (selectedJournal?.[primaryKey] === rowInfo.original[primaryKey]) return;
                                journalRef.current.clear();
                                dispatch({ type: ACTION_TYPES.SET_SELECTED_JOURNALID, value: rowInfo.original });
                            },
                        })}
                    />
                    <CommonJournalDetailDistributionList
                        recordId={recordIdSelected}
                        journalType={journalType}
                        writePermissions={selectedJournal ? (selectedJournal.postedDate === null) : (radioOption !== ShowJournalType.SHOW_POSTED)}
                        ref={journalRef}
                        showPosted={selectedJournal ? (selectedJournal.postedDate !== null) : radioOption === ShowJournalType.SHOW_POSTED}
                        minPostDate={state.minPostDate}
                        initialPostedDate={selectedJournal?.[dateFieldColumn]}
                        onDirty={() => { dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { isDirty: true } }); }}
                    />
                </Split>
            </Container>
            <If condition={(radioOption === ShowJournalType.SHOW_POSTED && recordIdSelected > 0 && journalRef.current.isLoading) || false}>
                <DialogActionMessage message="Getting gl lines information to post journal.. " />
            </If>
        </>
    );
};

CommonJournalList.propTypes = {
    journalType: PropTypes.string.isRequired,
};

export default CommonJournalList;

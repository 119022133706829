import React from 'react';
import {
    makeStyles, Button, useTheme, useMediaQuery, Tooltip, Chip,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import DateUtils from 'lib/DateUtils';
import { LocalAtmIcon } from 'components/icons';
import CashierPaymentList from 'components/modules/accounting/accountsReceivable/list/CashierPaymentList';
import LabelValue from 'components/widgets/LabelValue';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router-dom';
import NumberUtils from 'lib/NumberUtils';
import { AccountingSubModules, AccountsReceivableEntities } from 'utils/enum/AccountingEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import usePartList from 'components/modules/accounting/accountsReceivable/hooks/usePartList';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const baseRoute = `/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/${AccountsReceivableEntities.PARTS}`;
const keyStore = new KeyStore();

const cellStatus = (cellData, classes) => {
    const { original: { status } } = cellData;
    let badgeStatus = classes.primary;
    if (status?.toLowerCase() === ServiceInvoiceStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;

    return <Chip size="small" label={status} className={badgeStatus} />;
};

const AccountReceivablePartList = () => {
    const ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyle();
    const {
        loadMore,
        onSearch,
        onFilterChange,
        onSetNew,
        onSelectedRow,
        params,
        table,
        selectedRow,
        loading,
    } = usePartList();

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Ticket #',
            minWidth: 100,
            width: 100,
            id: 'ticketNumber',
            accessor: 'ticketNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
        },
        {
            Header: 'Customer',
            minWidth: 250,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Counter Person',
            minWidth: 250,
            width: 250,
            id: 'counterPerson',
            accessor: 'counterPerson',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Ticket Open',
            minWidth: 100,
            width: 120,
            id: 'ticketOpen',
            accessor: 'ticketOpen',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.format(value),
        },
        {
            Header: 'Ticket Closed',
            minWidth: 100,
            width: 120,
            id: 'ticketClosed',
            accessor: 'ticketClosed',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.format(value),
        },
        {
            Header: 'Balance Due',
            minWidth: 150,
            width: 150,
            id: 'BalanceDue',
            accessor: 'balanceDue',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Status',
            minWidth: 150,
            width: 150,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => cellStatus(cellData, classes),
        },
    ];

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.containerFilterSearch}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <Form.Group controlId="formBasicCheckbox" className={classes.alignContent}>
                        <Form.Check
                            checked={params.includeBalanced}
                            type="checkbox"
                            label="Include paid accounts"
                            className={classes.labelFont}
                            onChange={(e) => onFilterChange('includeBalanced', e.target.checked)}
                        />
                    </Form.Group>
                    <LabelValue
                        label="Items"
                        value={table.totalCount}
                    />
                </div>
                <div className={classes.buttonSpacing}>
                    {ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS && (
                        <Tooltip title="Take Payment" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => {
                                        onSetNew();
                                        history.push(`${baseRoute}/${selectedRow?.ticketNumber}/payment`);
                                    }}
                                    variant="outlined"
                                    startIcon={<LocalAtmIcon />}
                                    disabled={loading || !selectedRow}
                                    size="small"
                                >
                                    Take Payment
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </div>
            </Header>
            <Container className={classes.containerSplit}>
                <Split
                    sizes={selectedRow && selectedRow.ticketNumber ? [60, 40] : [100, 0]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        data={table?.records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={table?.totalCount}
                        loadMore={loadMore}
                        rowSelected
                        className="-highlight"
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;
                            return {
                                onDoubleClick: () => {
                                    onSelectedRow(record);
                                },
                                onClick: () => {
                                    onSelectedRow(record);
                                },
                            };
                        }}
                    />
                    <div>
                        {selectedRow && selectedRow.ticketNumber && (
                            <CashierPaymentList
                                accountNumber={selectedRow.ticketNumber}
                            />
                        )}
                    </div>
                </Split>
            </Container>
        </div>
    );
};

export default AccountReceivablePartList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Grid, Button,
} from '@material-ui/core';
import LotsCategory from 'utils/enum/LotsCategory';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { MarketCompsDistanceOptions, InventoryCategory } from 'utils/enum/InventoryEnum';
import { Col, Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';
import Select from 'components/widgets/Select';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        '& > div > button': {
            marginRight: '10px',
        },
    },
    subLabel: {
        fontSize: '12px',
    },
    save: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    market: {
        display: 'flex',
        width: '100%',
        height: '32px',
        paddingLeft: '5px',
        fontSize: '14px',
    },
    title: {
        fontSize: '12px',
        marginLeft: '15px',
    },
    tableContainer: {
        marginTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        height: '300px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '600px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
}));

const MarketCompsSettings = ({ canWrite }) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        marketPercentage: '100',
        marketDistance: '75',
        lots: [],
    });

    const { INV_MARKET_PERCENTAGE, INV_MARKET_DISTANCE } = InventoryCategory;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [INV_MARKET_PERCENTAGE, INV_MARKET_DISTANCE],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: lotsLatLongData,
        loading: lotsLatLongLoading,
        error: lotsLatLongError,
    } = useQuery(LotQuery.GET_LOTS_LAT_LONG, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveInventorySettings] = useMutation(InventoryMutation.SAVE_INVENTORY_SETTINGS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Data saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the data');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading) {
            const settings = settingsData?.getSettings;
            if (settings) {
                setState({
                    ...state,
                    marketPercentage: settings.find((setting) => setting.key === INV_MARKET_PERCENTAGE)?.value || state.marketPercentage,
                    marketDistance: settings.find((setting) => setting.key === INV_MARKET_DISTANCE)?.value || state.marketDistance,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    useEffect(() => {
        if (lotsLatLongError) {
            ModalUtils.errorMessage(lotsLatLongError?.graphQLErrors);
            return;
        }

        if (!lotsLatLongLoading) {
            const lots = lotsLatLongData?.getLotsLatLong;
            if (lots) {
                setState({
                    ...state,
                    lots: lots.slice().sort((a, b) => a.lotName.localeCompare(b.lotName)),
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotsLatLongLoading, lotsLatLongError]);

    const onChange = (name, value) => {
        setState({
            ...state,
            [name]: value,
        });
    };

    const onCoordinatesChange = (lotId, name, value) => {
        const clone = cloneDeep(state.lots);
        const record = clone.find((element) => element.lotId === lotId);
        if (record) record[name] = value;

        onChange('lots', clone);
    };

    const onSave = () => {
        const input = [
            {
                value: state.marketPercentage,
                key: INV_MARKET_PERCENTAGE,
                lotName: ALL_LOTS,
            },
            {
                value: state.marketDistance,
                key: INV_MARKET_DISTANCE,
                lotName: ALL_LOTS,
            },
        ];

        saveInventorySettings({
            variables: {
                input,
            },
        });
    };

    const saveCoordinates = async (lotId) => {
        const { latitude, longitude } = state.lots?.find((element) => element.lotId === lotId) || {};
        if (!latitude || !longitude) return;

        try {
            const { data } = await client.mutate({
                mutation: LotMutation.SAVE_LOT_LAT_LONG,
                variables: {
                    lotId,
                    latitude,
                    longitude,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                ModalUtils.successMessage(null, 'Coordinates saved successfully');
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'lotName',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.lotName?.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Latitude',
            dataKey: 'latitude',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <InputNumber
                        className={record.latitude ? '' : 'invalid-field'}
                        onChange={(newValue) => onCoordinatesChange(record.lotId, 'latitude', newValue)}
                        showCurrency={false}
                        allowNegative
                        size="sm"
                        min={-90}
                        max={90}
                        removeDecimalScale
                        value={record.latitude}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Longitude',
            dataKey: 'longitude',
            width: 200,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <InputNumber
                        className={record.longitude ? '' : 'invalid-field'}
                        onChange={(newValue) => onCoordinatesChange(record.lotId, 'longitude', newValue)}
                        showCurrency={false}
                        allowNegative
                        size="sm"
                        min={-180}
                        max={180}
                        removeDecimalScale
                        value={record.longitude}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        lotId,
                        latitude,
                        longitude,
                    },
                } = cell;

                return (
                    <Button
                        disabled={!latitude || !longitude}
                        className={classes.containedSecondaryInfo}
                        size="small"
                        onClick={() => saveCoordinates(lotId)}
                    >
                        Save
                    </Button>
                );
            },
        },
    ];

    return settingsLoading ? null : (
        <>
            <Grid container className={classes.box}>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>% of Market</Form.Label>
                            <input
                                className={classes.market}
                                name="marketPercentage"
                                type="number"
                                min="0"
                                value={state.marketPercentage}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>Distance</Form.Label>
                            <Select
                                nowrap
                                size="sm"
                                className={classes.input}
                                name="marketDistance"
                                onChange={onChange}
                                value={state.marketDistance}
                                options={MarketCompsDistanceOptions.map((option) => ({
                                    value: option,
                                    label: option,
                                }))}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <If condition={canWrite}>
                    <Grid container>
                        <Grid item lg={6} md={8} xs={12}>
                            <Form.Group className={classes.save} as={Col}>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </If>
            </Grid>
            <Grid container className={classes.box}>
                <Grid item lg={11} xs={12}>
                    <div className={classes.title}>
                        Lots&apos; Latitude / Longitude
                    </div>
                </Grid>
                <Grid item lg={11} xs={12}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={lotsLatLongLoading}
                            rowHeight={45}
                            totalRecords={state.lots.length}
                            data={state.lots}
                            columns={getColumns()}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

MarketCompsSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default MarketCompsSettings;

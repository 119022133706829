export const bureaus = {
    EQUIFAX: {
        name: 'Equifax',
        code: 'EFX',
    },
    EXPERIAN: {
        name: 'Experian',
        code: 'XPN',
    },
    TRANSUNION: {
        name: 'Transunion',
        code: 'TU',
    },
};

export default {
    getCodeMultiBureaus(record) {
        return (record || []).map((item) => bureaus[item.toUpperCase()].code).join(':');
    },
};

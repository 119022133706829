/* eslint-disable no-param-reassign */
import React, { useReducer, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { Form, Col, Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import { DialogContent, Dialog } from '@material-ui/core';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { FormFieldsSchema } from 'utils/schema/settings/FormMapperSchema';
import FormsMapperQuery from 'services/graphQL/query/setting/FormsMapperQuery';
import FormsMapperMutation from 'services/graphQL/mutate/setting/FormsMapperMutation';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    containerFields: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        width: 250,
        minWidth: 250,
        maxHeight: 530,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '& label': {
            textAlign: 'center',
            fontWeight: 500,
            display: 'block',
            margin: '8px 0',
        },
    },
    listFields: {
        overflow: 'auto',
        '& li': {
            borderTop: '1px solid #e8e8e8',
            padding: theme.spacing(1, 2),
            fontSize: '14px',
            cursor: 'pointer',
        },
    },
    body: {
        overflow: 'hidden',
        display: 'flex',
        padding: theme.spacing(0.5),
    },
    textArea: {
        marginRight: theme.spacing(2),
        resize: 'none',
        minHeight: 335,
    },
    textField: {
        marginRight: theme.spacing(2),
    },
    formGroup: {
        marginBottom: 0,
    },
}));

const initState = {
    id: 0,
    label: '',
    value: '',
    fields: [],
    filteredFields: [],
    dataType: 'String',
};

const ACTION_TYPES = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
    ON_CHANGE_DATA_TYPE: 'onChangeDataType',
    INIT_AVAILABLE_FIELDS: 'initAvailableFields',
};

const reducer = (state, action) => {
    const {
        type, payload, field,
    } = action;
    switch (type) {
    case ACTION_TYPES.ON_CHANGE_VALUE: {
        return update(state, {
            [field]: { $set: payload },
        });
    }
    case ACTION_TYPES.ON_CHANGE_DATA_TYPE: {
        return update(state, {
            filteredFields: { $set: payload === 'Number' ? state.fields.filter((item) => item.type === 'Number') : state.fields },
        });
    }
    case ACTION_TYPES.INIT_AVAILABLE_FIELDS: {
        return update(state, {
            fields: { $set: payload },
            filteredFields: { $set: payload },
        });
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        if (!isEmpty(payload)) {
            const {
                name, dataType, columnName, fieldsTableMappingId,
            } = payload;
            return update(state, {
                label: { $set: name },
                value: { $set: columnName },
                dataType: { $set: dataType },
                id: { $set: fieldsTableMappingId },
            });
        } return state;
    }
    default:
        return state;
    }
};

const CustomFieldsDialog = ({
    onClose, record,
}) => {
    const classes = useStyles();
    const formulaFieldRef = useRef();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        id, value, label, dataType, filteredFields,
    } = state;

    const {
        data, loading, error,
    } = useQuery(FormsMapperQuery.GET_FORM_VIEW_FIELDS_AND_TYPES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getFormViewFieldsAndTypes } = data;
            const sortedList = ArrayUtils.sortByObjectField(getFormViewFieldsAndTypes, 'name');
            dispatch({
                payload: sortedList,
                type: ACTION_TYPES.INIT_AVAILABLE_FIELDS,
            });
        }
    }, [data, loading, error]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            payload: record,
        });
    }, [record]);

    const onChangeValue = (field, changingValue) => {
        dispatch({
            field,
            payload: changingValue,
            type: ACTION_TYPES.ON_CHANGE_VALUE,
        });
    };

    useEffect(() => {
        dispatch({
            payload: dataType,
            type: ACTION_TYPES.ON_CHANGE_DATA_TYPE,
        });
    }, [dataType, data]);

    const [saveData, { loading: updating }] = useMutation(FormsMapperMutation.SAVE_FORMS_MAPPED_FIELD, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.upsertFormsMappedField) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({
                    saving: true,
                });
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => saveData({
        variables: {
            input: {
                dataType,
                name: label,
                columnName: value,
                fieldsTableMappingId: id,
            },
        },
    });

    const insertAtCursor = (textToInsert) => {
        const input = formulaFieldRef.current;
        const { value: changingValue } = input;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const currentValue = changingValue.slice(0, start) + textToInsert + changingValue.slice(end);
        input.value = currentValue;
        input.selectionEnd = start + textToInsert.length;
        input.selectionStart = input.selectionEnd;

        onChangeValue('value', currentValue);
    };

    const isValidData = isValidSchema(FormFieldsSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                onClose={onClose}
                title={id === 0 ? 'Map new field' : 'Update mapping'}
            />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.body}>
                    <Form.Group as={Col} className={classes.formGroup}>
                        <Form.Group as={Row}>
                            <Form.Label className={classes.labels}>Label</Form.Label>
                            <Form.Control
                                type="text"
                                value={label}
                                placeholder="Enter the label"
                                onChange={(e) => onChangeValue('label', e.target.value)}
                                className={isValidField(errors, 'label') ? clsx('invalid-field', classes.textField) : classes.textField}
                            />
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={classes.labels}>Data Type Result</Form.Label>
                            <Form.Control
                                as="select"
                                value={dataType}
                                onChange={(e) => onChangeValue('dataType', e.target.value)}
                                className={isValidField(errors, 'dataType') ? clsx('invalid-field', classes.textField) : classes.textField}
                            >
                                <option>String</option>
                                <option>Number</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Row} className={classes.formGroup}>
                            <Form.Label className={classes.labels}>SQL Formula Value</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={value}
                                maxLength={255}
                                ref={formulaFieldRef}
                                placeholder="Enter your formula in SQL format"
                                onChange={(e) => onChangeValue('value', e.target.value)}
                                className={isValidField(errors, 'value') ? clsx('invalid-field', classes.textArea) : classes.textArea}
                            />
                        </Form.Group>
                    </Form.Group>
                    <div className={classes.containerFields}>
                        <Form.Label>Fields</Form.Label>
                        <ul className={classes.listFields}>
                            {filteredFields.map((item, index) => (
                                <li
                                    key={index}
                                    onDoubleClick={() => insertAtCursor(item.name)}
                                >
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

            </DialogContent>
            <DialogActions
                onClickPrimary={onSave}
                onClickSecondary={onClose}
                disableSecondaryButton={updating}
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

CustomFieldsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    record: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        columnName: PropTypes.string,
        fieldsTableMappingId: PropTypes.number,
    }),
};

CustomFieldsDialog.defaultProps = {
    record: null,
};

export default CustomFieldsDialog;

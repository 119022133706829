import React from 'react';
import PropTypes from 'prop-types';
import {
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
} from '@material-ui/core';

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 250,
        },
    },
    getContentAnchorEl: null,
};

const JobTypesSelect = React.memo(({
    classes,
    disabled,
    currentValue,
    jobTypes,
    reconInspectionItemId,
    onChange,
}) => (
    <Select
        disabled={disabled}
        className={classes.select}
        multiple
        value={currentValue}
        onChange={({ target: { value } }) => onChange('jobTypes', value, reconInspectionItemId)}
        renderValue={(selected) => selected.map((id) => jobTypes.find((el) => el.enumValuesId === id)?.description).join(', ')}
        MenuProps={MenuProps}
    >
        {jobTypes.map(({ enumValuesId, description }, index) => (
            <MenuItem key={index} value={enumValuesId}>
                <Checkbox checked={currentValue.includes(enumValuesId)} />
                <ListItemText primary={description} />
            </MenuItem>
        ))}
    </Select>
), (prevProps, nextProps) => prevProps.disabled === nextProps.disabled
&& prevProps.currentValue.length === nextProps.currentValue.length
&& prevProps.jobTypes.length === nextProps.jobTypes.length);

JobTypesSelect.propTypes = {
    disabled: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    currentValue: PropTypes.array,
    jobTypes: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    reconInspectionItemId: PropTypes.number,
};

JobTypesSelect.defaultProps = {
    currentValue: [],
    jobTypes: [],
    reconInspectionItemId: 0,
};

export default JobTypesSelect;

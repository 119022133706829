import React from 'react';
import PropTypes from 'prop-types';
import If from 'components/widgets/conditional/If';
import {
    makeStyles,
    IconButton,
    Checkbox,
    Button,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import VehicleImage from 'components/widgets/VehicleImage';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const useStyles = makeStyles((theme) => ({
    pictureCarRead: {
        width: 235,
        border: `1px solid ${theme.palette.border.geyser}`,
        borderRadius: 5,
        margin: 4,
        position: 'relative',
        overflow: 'hidden',
        height: 180,
        '&:hover > div:first-child': {
            opacity: 1,
            zIndex: 1,
        },
    },
    img: {
        width: '100%',
        maxHeight: 180,
        margin: 'auto 0',
        objectFit: 'contain',
    },
    actionContainer: {
        transition: 'all 0.5s',
        display: 'flex',
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        zIndex: -1,
        gap: '10px',
    },
    iconButton: {
        transition: 'all 0.5s',
        verticalAlign: 'middle',
        color: theme.palette.text.white,
        zIndex: 1,
        border: '1px solid',
        padding: 5,
    },
    pictureBG: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        background: theme.palette.background.black,
        opacity: 0.7,
    },
    selector: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: '2',
        backgroundColor: `rgba(${theme.palette.rgb.white},0.5)`,
        '& > span': {
            padding: 0,
            '& svg': {
                fill: theme.palette.primary.main,
            },
        },
    },
    preview: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: '2',
        backgroundColor: `rgba(${theme.palette.rgb.white},0.5)`,
        '& > button': {
            padding: 0,
            minWidth: 'initial',
            width: '24px',
            '& > span > span': {
                padding: 0,
                margin: 0,
                '& svg': {
                    fill: theme.palette.primary.main,
                },
            },
        },
    },
}));

const ImageViewer = ({
    canWrite,
    image,
    openImage,
    previewWatermark,
    isReorderModeOn,
    index,
    selectedImages,
    toogleImageSelection,
    watermarksAvailable,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.pictureCarRead}>
            <If condition={!isReorderModeOn}>
                <div className={classes.actionContainer}>
                    <IconButton
                        onClick={() => openImage(index)}
                        className={classes.iconButton}
                    >
                        <SearchIcon fontSize="large" />
                    </IconButton>
                    <div className={classes.pictureBG} />
                </div>
                <If condition={canWrite}>
                    <div className={classes.selector}>
                        <Checkbox
                            checked={selectedImages.includes(index)}
                            color="default"
                            size="small"
                            disableRipple
                            onChange={(_, value) => toogleImageSelection(index, value)}
                        />
                    </div>
                </If>
                <If condition={watermarksAvailable && canWrite}>
                    <div className={classes.preview}>
                        <Button
                            className={classes.containedInfo}
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => previewWatermark(index)}
                        />
                    </div>
                </If>
            </If>
            <VehicleImage
                image={image}
                className={classes.img}
            />
        </div>
    );
};

ImageViewer.propTypes = {
    image: PropTypes.string.isRequired,
    openImage: PropTypes.func.isRequired,
    previewWatermark: PropTypes.func,
    index: PropTypes.number.isRequired,
    isReorderModeOn: PropTypes.bool.isRequired,
    selectedImages: PropTypes.array,
    toogleImageSelection: PropTypes.func,
    watermarksAvailable: PropTypes.bool,
    canWrite: PropTypes.bool.isRequired,
};

ImageViewer.defaultProps = {
    selectedImages: [],
    toogleImageSelection: () => null,
    previewWatermark: () => null,
    watermarksAvailable: false,
};

export default ImageViewer;

import { gql } from '@apollo/client';

export default class MiscCommissionsQuery {
    static GET_DEAL_COMMISSION_LIST = gql`
        query getPayrollDealCommissionList($employeeId: Int!, $payPeriod: String!) {
            getPayrollDealCommissionList(employeeId: $employeeId, payPeriod: $payPeriod) {
                status
                soldDate
                buyerName
                postedDate
                isApproved
                soldVehicle
                accountNumber
                commissionAmount
            }
        }
    `;

    static GET_GENERAL_COMMISSION = gql`
        query getGeneralCommission($employeeId: Int!, $payPeriod: String!) {
            getGeneralCommission(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static GET_PURCHASING_AGENT_COMMISSION_LIST = gql`
        query getPurchasingAgentCommission($employeeId: Int!, $payPeriod: String!) {
            getPurchasingAgentCommission(employeeId: $employeeId, payPeriod: $payPeriod) {
                vehicle
                stockNumber
                purchasedDate
                commissionAmount
            }
        }
    `;

    static GET_PURCHASING_AGENT_DEAL_COMMISSION_LIST = gql`
        query getPurchasingAgentDealCommissionList($employeeId: Int!, $payPeriod: String!) {
            getPurchasingAgentDealCommissionList(employeeId: $employeeId, payPeriod: $payPeriod) {
                age
                profit
                vehicle
                soldDate
                buyerName
                accountNumber
                commissionAmount
            }
        }
    `;

    static GET_DEAL_COMMISSION_DETAIL = gql`
        query getDealCommissionDetail($accountNumber: Int!) {
            getDealCommissionDetail(accountNumber: $accountNumber) {
                vehicle {
                    vin
                    cost
                    make
                    pack
                    trim
                    year
                    miles
                    model
                    style
                    engine
                    extColor
                    stockNumber
                    stickerPrice
                    minimumPrice
                    internetPrice
                }
                products {
                    type
                    cost
                    price
                }
                commissions {
                    salesPerson1 {
                        id
                        amount
                        override
                        list {
                            id
                            fullName
                            employeeType
                        }
                    }
                    salesPerson2 {
                        id
                        amount
                        override
                    }
                    salesManager {
                        id
                        amount
                        override
                        list {
                            id
                            fullName
                            employeeType
                        }
                    }
                    bdcAgent {
                        id
                        amount
                        override
                        list {
                            id
                            fullName
                            employeeType
                        }
                    }
                    financeManager {
                        id
                        amount
                        override
                        list {
                            id
                            fullName
                            employeeType
                        }
                    }
                }
                discount
                salesPrice
                reserveAmount
                totalTradeACV
                discountOnFrontEnd
                totalTradeAllowance
            }
        }
    `;

    static CALCULATE_COMMISSIONS = gql`
        query calculatePayrollDealCommission($accountNumber: Int!, $commissionFields: CommissionsCalculationsInput){
            calculatePayrollDealCommission(accountNumber: $accountNumber, commissionFields: $commissionFields) {
            salesRep1Commission {
                id
                override
                amount
            }
            salesRep2Commission {
                id
                override
                amount
            }
            bdcAgentCommission {
                id
                override
                amount
            }
            salesManagerCommission {
                id
                override
                amount
            }
            financeManagerCommission {
                id
                override
                amount
            }
        }
    }`;
}

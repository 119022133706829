import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { Col, Form } from 'react-bootstrap';
import DialogActions from 'components/widgets/modal/DialogActions';
import InputNumber from 'components/widgets/InputNumber';
import StringUtils from 'lib/StringUtils';
import { RollBalanceOptions } from 'utils/enum/DealEnum';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    styleColLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignTextEnd: {
        textAlign: 'end',
    },
    labelStyle: {
        marginBottom: '2px !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        color: '#767676',
    },
    switchMargin: {
        marginLeft: '10px',
    },
    checkGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingLeft: 0,
    },
    checkLabel: {
        fontSize: '16px !important',
        fontWeight: '500',
        textAlign: 'right',
    },
    checkInput: {
        position: 'initial',
        marginLeft: '0.5rem',
    },
}));

const RollBalanceDialog = ({
    toggleModal,
    onChange,
    resetDealInformation,
    balanceDueOverride,
    balanceDueOverrideAmount,
}) => {
    const classes = useStyles();
    const [overrideValue, setOverrideValue] = useState(balanceDueOverride);
    const isOverride = !StringUtils.isEmpty(overrideValue);
    const [overrideAmount, setOverrideAmount] = useState(balanceDueOverrideAmount);

    const changeOverride = useCallback((value) => {
        setOverrideValue(value);
        onChange('balanceDueOverride', value);
    }, [onChange]);

    const changeOverrideAmount = useCallback((value) => {
        setOverrideAmount(value);
        onChange('balanceDueOverrideAmount', value);
    }, [onChange]);

    const onClose = () => {
        resetDealInformation();
        toggleModal();
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            aria-labelledby="roll-balance-dialog-title"
            aria-describedby="roll-balance-dialog-description"
        >
            <DialogAppBar title="Roll Balance Due" onClose={onClose} iconSize="sm" titleVariant="h4" />
            <DialogContent className={classes.dialogContent} dividers>
                <Form className="am-form">
                    <Form.Row className={classes.row}>
                        <Col>
                            <Form.Group>
                                <Form.Label className={classes.labelStyle}>Roll Balance Due to:</Form.Label>
                                <InputNumber
                                    disabled={!isOverride}
                                    value={overrideAmount}
                                    onChange={(value) => changeOverrideAmount(value)}
                                    placeholder="0.00"
                                    thousandSeparator
                                    showCurrency
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className={classes.labelStyle}>By Changing:</Form.Label>
                                <Form.Check type="checkbox" id="sales-price-override" className={clsx(classes.checkGroup, 'mt-8')}>
                                    <Form.Check.Label className={classes.checkLabel}>Sales Price</Form.Check.Label>
                                    <Form.Check.Input
                                        className={classes.checkInput}
                                        type="checkbox"
                                        onChange={(e) => changeOverride(e.target.checked ? RollBalanceOptions.SALES_PRICE : '')}
                                        checked={overrideValue === RollBalanceOptions.SALES_PRICE}
                                    />
                                </Form.Check>
                                <Form.Check type="checkbox" id="down-payment-override" className={clsx(classes.checkGroup, 'mt-8')}>
                                    <Form.Check.Label className={classes.checkLabel}>Down Payment</Form.Check.Label>
                                    <Form.Check.Input
                                        className={classes.checkInput}
                                        type="checkbox"
                                        onChange={(e) => changeOverride(e.target.checked ? RollBalanceOptions.DOWN_PAYMENT : '')}
                                        checked={overrideValue === RollBalanceOptions.DOWN_PAYMENT}
                                    />
                                </Form.Check>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Apply"
                onClickPrimary={toggleModal}
                onClickSecondary={onClose}
            />
        </Dialog>
    );
};

RollBalanceDialog.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    balanceDueOverride: PropTypes.string,
    balanceDueOverrideAmount: PropTypes.number.isRequired,
    resetDealInformation: PropTypes.func.isRequired,
};

RollBalanceDialog.defaultProps = {
    balanceDueOverride: '',
};

export default RollBalanceDialog;

import React, { useState } from 'react';
import CashierMappingsAccount from 'components/modules/settings/accounting/CashierMappingsAccount';
import {
    FormControl, FormControlLabel, makeStyles, Radio, RadioGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import Split from 'react-split';
import CashierMappingsPaymentType from 'components/modules/settings/accounting/CashierMappingsPaymentType';
import { LotDefaultskey } from 'utils/enum/LotsCategory';
import CatalogEnum from 'utils/enum/CatalogEnum';
import CashierMappingCustomField from 'components/modules/settings/accounting/CashierMappingCustomField';

const useStyles = makeStyles((theme) => ({
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        '& .ReactTable .rt-td': {
            padding: '0px 5px',
        },
    },
}));

const CashierMappingsLayout = () => {
    const classes = useStyles();

    const [tab, setTab] = useState(0);

    return (
        <div className={classes.box}>
            <div>
                <Split sizes={[75, 25]} className="split">
                    <div className={classes.box}>
                        <>
                            <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                                <RadioGroup row value={tab} onChange={(e, val) => setTab(Number(val))}>
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio color="primary" />}
                                        label="Deals"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label="Services"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label="Parts"
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<Radio color="primary" />}
                                        label="Other"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </>
                        <>
                            {tab === 0 && (
                                <CashierMappingsAccount
                                    lotDefaultKey={LotDefaultskey.CASHIER_MAPPING_DEALS_CREDIT}
                                    catalogEnumForReason={CatalogEnum.CASHIER_DEAL_REASON}
                                    catalogEnumForType={CatalogEnum.DEAL_TYPES}
                                />
                            )}
                            {tab === 1 && (
                                <CashierMappingsAccount
                                    lotDefaultKey={LotDefaultskey.CASHIER_MAPPING_SERVICE_CREDIT}
                                    catalogEnumForReason={CatalogEnum.CASHIER_SERVICE_REASON}
                                    catalogEnumForType={CatalogEnum.SERVICE_CUSTOMER_TYPE}
                                />
                            )}
                            {tab === 2 && (
                                <CashierMappingsAccount
                                    lotDefaultKey={LotDefaultskey.CASHIER_MAPPING_PARTS_CREDIT}
                                    catalogEnumForReason={CatalogEnum.CASHIER_PARTS_REASON}
                                />
                            )}
                            {tab === 3 && (
                                <CashierMappingsAccount
                                    lotDefaultKey={LotDefaultskey.CASHIER_MAPPING_OTHER_CREDIT}
                                    catalogEnumForReason={CatalogEnum.CASHIER_OTHER_REASON}
                                />
                            )}
                        </>
                    </div>
                    <div>
                        <CashierMappingsPaymentType />
                        <CashierMappingCustomField />
                    </div>
                </Split>
            </div>
        </div>
    );
};
export default CashierMappingsLayout;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Tabs as TabsBootstrap } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        border: '1px solid #dee2e6',
        borderRadius: '4px 4px 0 0',
        background: theme.palette.background.white,
    },
    navbar: {
        '& > a': {
            minWidth: '120px',
            textAlign: 'center',
            color: theme.palette.text.link,
            textDecoration: 'none',
            backgroundColor: 'transparent',
            lineHeight: '1.5',
            borderTop: '0px !important',
        },
        '& > a:first-child': {
            borderLeft: '0px',
        },
    },
}));

const Tabs = (props) => {
    const classes = useStyles();
    const { children, className } = props;

    return (
        <TabsBootstrap {...props} className={clsx(classes.tabPanel, classes.navbar, className)}>
            {children}
        </TabsBootstrap>
    );
};

Tabs.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Tabs.defaultProps = {
    children: null,
    className: null,
};

export default Tabs;

import React, { Component } from 'react';

// Components ant Others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import FormMap from 'services/mapData/FormMap';
import FormService from 'services/modules/FormService';

const FormLibraryTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        packSelected: PropTypes.object.isRequired,
        accountNumber: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);
        this.formService = new FormService();
        this.initBind();

        this.state = {
            customField: [],
            openDialogCustomField: false,
            printingFormWithCustomField: false,
        };
    }

    onAddFormToFormPack(formId) {
        const { packSelected } = this.props;
        const input = {
            packId: packSelected.packId,
            formId,
        };

        this.formService.addFormToFormPack(input)
            .then((response) => {
                const { graphQLError } = response;

                if (graphQLError) {
                    ModalUtils.errorMessage();
                }
            });
    }

    onPreview(record) {
        const { accountNumber } = this.props;
        this.formService.getCustomFieldByForms({ forms: [record.id], dealId: accountNumber })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    this.setLoadingPrint();
                }

                if (data?.getCustomFieldByForms.length > 0) {
                    if (data.getCustomFieldByForms.some((item) => item.customField)) {
                        this.setState({
                            openDialogCustomField: true,
                            customField: this.mapCustomField(data?.getCustomFieldByForms) || [],
                        });
                    } else {
                        this.openNewTab(FormMap.mapFormWithoutCustomField(data?.getCustomFieldByForms)[0]);
                    }
                } else {
                    this.openNewTab(FormMap.mapFormWithoutCustomField([record])[0]);
                }
            });
    }

    getCustomFieldInformation(records) {
        this.setState({
            printingFormWithCustomField: true,
        });
        const result = FormMap.mapCustomField(records);

        // Do not call the pdf micro services if no form is mapped
        if (result.length === 0) {
            this.setLoadingPrint();
            return;
        }

        this.openNewTab(result[0]);
    }

    setLoadingPrint() {
        this.setState({
            printingFormWithCustomField: false,
            openDialogCustomField: false,
        });
    }

    openNewTab(result = {}) {
        const { packSelected, accountNumber } = this.props;
        const input = {
            lotId: packSelected.lotId,
            form: result,
            dealId: accountNumber,
        };

        this.formService.previewFormPack({ input })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }

                if (data?.previewFormPack) {
                    const URL = data.previewFormPack;
                    window.open(URL, '_blank');
                }
            })
            .finally(() => {
                this.setLoadingPrint();
            });
    }

    mapCustomField(records) {
        return records.map((item) => {
            let fields = [];

            if (!StringUtils.isEmpty(item.customField)) {
                fields = JSON.parse(item.customField.data)?.customField;
            }

            return {
                data: fields,
                formId: item.id,
                name: item.name,
                commonName: item.commonName,
                originalName: item.originalName,
            };
        });
    }

    initBind() {
        this.onPreview = this.onPreview.bind(this);
        this.openNewTab = this.openNewTab.bind(this);
        this.setLoadingPrint = this.setLoadingPrint.bind(this);
        this.onAddFormToFormPack = this.onAddFormToFormPack.bind(this);
        this.getCustomFieldInformation = this.getCustomFieldInformation.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onPreview={this.onPreview}
                setLoadingPrint={this.setLoadingPrint}
                onAddFormToFormPack={this.onAddFormToFormPack}
                getCustomFieldInformation={this.getCustomFieldInformation}
            />
        );
    }
};

export default FormLibraryTabContainer;

import { AccountReceivableTransactionType, AccountingSubModules, AccountsReceivableEntities } from 'utils/enum/AccountingEnum';
import CatalogEnum from 'utils/enum/CatalogEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

export default class AccountingUtils {
    static getTransactionType = (entity) => {
        switch (entity) {
        case AccountsReceivableEntities.OTHER_COLLECTIONS:
            return AccountReceivableTransactionType.OTHER;
        case AccountsReceivableEntities.SERVICE:
            return AccountReceivableTransactionType.SERVICE;
        case AccountsReceivableEntities.PETTY_CASH:
            return AccountReceivableTransactionType.PETTY_CASH;
        case AccountsReceivableEntities.PARTS:
            return AccountReceivableTransactionType.PARTS;
        default:
            return AccountReceivableTransactionType.DEALS;
        }
    };

    static getEntityByTransactionType = (type) => {
        switch (type) {
        case AccountReceivableTransactionType.OTHER:
            return AccountsReceivableEntities.OTHER_COLLECTIONS;
        case AccountReceivableTransactionType.SERVICE:
            return AccountsReceivableEntities.SERVICE;
        case AccountReceivableTransactionType.PETTY_CASH:
            return AccountsReceivableEntities.PETTY_CASH;
        case AccountReceivableTransactionType.PARTS:
            return AccountsReceivableEntities.PARTS;
        default:
            return AccountsReceivableEntities.DEALS;
        }
    };

    static getCatalogReason = (entity) => {
        switch (entity) {
        case AccountsReceivableEntities.SERVICE:
            return CatalogEnum.CASHIER_SERVICE_REASON;
        case AccountsReceivableEntities.OTHER_COLLECTIONS:
            return CatalogEnum.CASHIER_OTHER_REASON;
        case AccountsReceivableEntities.PETTY_CASH:
            return CatalogEnum.CASHIER_PETTY_CASH_REASON;
        case AccountsReceivableEntities.PARTS:
            return CatalogEnum.CASHIER_PARTS_REASON;
        default:
            return CatalogEnum.CASHIER_DEAL_REASON;
        }
    };

    static getIndexFromList = (list, selected) => {
        const tabIndex = list.indexOf(selected);
        if (tabIndex !== -1) return tabIndex;

        return 0;
    };

    static getSubModuleList = () => {
        const subModules = [];
        const keyStore = new KeyStore();

        const ACCOUNTING_BANKING_READ = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_READ);
        const ACCOUNTING_GENERAL_JOURNAL_READ = keyStore.hasPermission(Permission.ACCOUNTING_GENERAL_JOURNAL_READ);
        const ACCOUNTING_ACCOUNT_RECEIVABLE_READ = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_READ);
        const ACCOUNTING_ACCOUNTS_PAYABLE_READ = keyStore.hasPermission(Permission.ACCOUNTING_ACCOUNTS_PAYABLE_READ);

        if (ACCOUNTING_ACCOUNT_RECEIVABLE_READ) subModules.push(AccountingSubModules.ACCOUNTS_RECEIVABLE);
        if (ACCOUNTING_ACCOUNTS_PAYABLE_READ) subModules.push(AccountingSubModules.ACCOUNTS_PAYABLE);
        if (ACCOUNTING_BANKING_READ) subModules.push(AccountingSubModules.BANKING);
        if (ACCOUNTING_GENERAL_JOURNAL_READ) {
            subModules.push(
                AccountingSubModules.JOURNALS,
                AccountingSubModules.DEAL_JOURNALS,
                AccountingSubModules.INVENTORY_JOURNALS,
                AccountingSubModules.SERVICE_JOURNALS,
                AccountingSubModules.PARTS_TICKET_JOURNALS,
            );
        }

        return subModules;
    };
}

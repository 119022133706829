import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import Placeholder from 'components/widgets/Placeholder';

// Components and Others
import PropTypes from 'prop-types';
import LeadWidgetItem from 'components/widgets/crm/LeadWidgetItem';

// HTTP
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import LeadQuery from 'services/graphQL/query/crm/LeadQuery';

const useStyle = makeStyles(() => ({
    widget: {
        marginBottom: '20px',
    },
    container: {
        overflow: 'auto',
    },
}));

const LeadWidget = ({ customerId }) => {
    const classes = useStyle();
    const [records, setRecords] = useState([]);
    const { data, loading, error } = useQuery(LeadQuery.GET_LEADS_BY_CUSTOMER_ID, {
        variables: { customerId },
        skip: (customerId == null),
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        setRecords([]);
    }, [customerId]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getLeadsByCustomerId) {
            setRecords(data.getLeadsByCustomerId);
        }
    }, [data, loading, error]);

    return (
        <BoxWidget
            title={`Leads (${records.length})`}
            minHeight="auto"
            maxHeight="auto"
            className={classes.widget}
        >
            {records.length > 0 ? (
                <div className={classes.container}>
                    {records.map((record, index) => <LeadWidgetItem {...record} key={index} />)}
                </div>
            ) : <Placeholder text="No Leads" />}
        </BoxWidget>
    );
};

LeadWidget.defaultProps = {
    customerId: null,
};

LeadWidget.propTypes = {
    customerId: PropTypes.string,
};

export default LeadWidget;

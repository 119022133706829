import StringUtils from 'lib/StringUtils';

export default class EmailUtils {
    static getCC(cc, to) {
        return StringUtils.getEmailsByText(cc)?.filter((item) => item !== to);
    }

    static getBCC(bcc, to) {
        return StringUtils.getEmailsByText(bcc)?.filter((item) => item !== to);
    }
}

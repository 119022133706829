/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
    Checkbox, Tooltip,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { Form, Col } from 'react-bootstrap';
import PROVIDER, {
    DEDUCTIBLE_TYPE, REGULATED_RULE, ADDITIONAL_INFO_FIELD_TYPE, MENU_STAGE,
    PRE_RATING_SURCHARGE_DATA_TYPE, PRODUCT_CODE,
} from 'utils/enum/MenuEnum';
import MenuHelper from 'utils/MenuHelper';
import NumberUtils from 'lib/NumberUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

// icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            flexDirection: 'column',
        },
        '& > div:nth-child(3)': {
            width: '100%',
            marginTop: '20px',
        },
        '& > div': {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& div.form-group': {
                paddingRight: 0,
                '& > label': {
                    fontSize: '13px',
                },
                '& > div > div, & > input': {
                    minHeight: '32px',
                    fontSize: '13px',
                },
                '& div.surcharge': {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            '& > div.copyAll': {
                fontSize: '13px',
                fontWeight: 500,
                marginTop: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '25px',
                },
                '& > span': {
                    padding: 0,
                    marginRight: '5px',
                    marginLeft: '10px',
                },
            },
            '& div.options': {
                border: `1px solid ${theme.palette.border.ghost}`,
                borderRadius: 5,
                height: '200px',
                overflowY: 'auto',
                overflowX: 'hidden',
                '& > div.customFieldInfo:nth-child(odd)': {
                    backgroundColor: theme.palette.background.whitesmoke,
                },
                '& > div.customFieldInfo': {
                    minHeight: 'initial',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginTop: '10px',
                    paddingBottom: '9px',
                    paddingTop: '9px',
                    '& > div.booleanComponent': {
                        flexDirection: 'row',
                        alignItems: 'center',
                    },
                    '& > div': {
                        paddingLeft: 0,
                        marginBottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        '& > label': {
                            marginBottom: '4px',
                        },
                        '& > span': {
                            padding: 0,
                            marginLeft: '5px',
                        },
                        '& > input': {
                            minHeight: 'initial',
                            width: '200px',
                        },
                        '& > div': {
                            width: '200px',
                        },
                    },
                },
            },
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: '5px',
            width: '16px',
            height: '16px',
            marginTop: '2px',
        },
    },
}));

const getProductRates = (selectedProduct, rates, isExpress) => (!isExpress
    ? rates.find((r) => r.vendorId === selectedProduct.vendor.vendorId)?.rates.plans[selectedProduct.details.providerProductCode]
    : rates.find((r) => r.productId === selectedProduct.id)?.rates.coverages);

const ProductSettingsForm = ({
    menuStage,
    rates,
    selectedProduct,
    updatePricing,
    onClose,
    pullRatesWithSurcharges,
}) => {
    const isExpress = selectedProduct.vendor.provider === PROVIDER.FI_EXPRESS;
    const keyStore = new KeyStore();
    const hasDealerCostPermission = keyStore.hasPermission(Permission.MENU_DEALER_COST);

    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        product: selectedProduct,
        rateData: getProductRates(selectedProduct, rates, isExpress),
        optionsBackup: selectedProduct.currentOptions,
        markUp: 0,
    });

    const {
        product,
        product: {
            pack,
            productCode,
            currentOptions,
        },
        rateData,
        optionsBackup,
        markUp,
    } = state;

    useEffect(() => {
        calculateMarkUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = currentOptions[pack];
    const plan = !isExpress
        ? rateData[options.type].find((item) => item.planId === options.planId)
        : rateData.find((item) => item.name === options.coverageName);
    const selectedTerm = !isExpress
        ? plan.terms.find((item) => item.term === options.term && item.mileage === options.mileage)
        : plan.terms.find((item) => item.value === options.term);
    const deductibleData = isExpress
        ? selectedTerm.mileages?.find((m) => m.value === options.mileage)?.deductibles.find((d) => d.value === options.deductible)
        : null;
    const getDeductibleLabel = (deductible = {}) => {
        const { amount, type, reduceAmount } = deductible;

        switch (type) {
        case DEDUCTIBLE_TYPE.DISAPPEARING:
            return `${NumberUtils.applyCurrencyFormat(amount)} Disappearing`;
        case DEDUCTIBLE_TYPE.REDUCING:
            return `${NumberUtils.applyCurrencyFormat(amount)}/${reduceAmount} Reducing`;
        case DEDUCTIBLE_TYPE.PERCENT:
            return `${amount}%`;
        default:
            return NumberUtils.applyCurrencyFormat(amount);
        }
    };

    const getDropdownOptions = (field, incomingType, incomingPlan, incomingOptions) => {
        let ddOptions = []; let term = null;
        const current = isExpress ? rateData : rateData[incomingType || options.type];

        switch (field) {
        case 'coverage':
            ddOptions = current.map((item) => ({ value: item[isExpress ? 'name' : 'planId'], label: isExpress ? item.name : item.planDescription }))
                .sort((a, b) => a.label.localeCompare(b.label));
            break;
        case 'term':
            ddOptions = [...(new Set((incomingPlan || plan).terms.map((item) => item.term || item.value)))]
                .map((item) => ({ value: item, label: String(item) }))
                .sort((a, b) => a.value > b.value);
            break;
        case 'mileage':
            term = (incomingPlan || plan).terms.filter((item) => (item.term || item.value) === (incomingOptions || options).term);
            ddOptions = [...(new Set((isExpress ? term[0].mileages : term).map((item) => item.mileage || item.value)))]
                .map((item) => ({ value: item, label: String(NumberUtils.applyThousandsFormat(item)) }))
                .sort((a, b) => a.value > b.value);
            break;
        case 'deductible':
            term = (incomingPlan || plan).terms.filter((item) => (item.term || item.value) === (incomingOptions || options).term);
            const deductibles = isExpress
                ? term[0].mileages.filter((item) => item.value === (incomingOptions || options).mileage)[0]?.deductibles
                : term.filter((item) => item.mileage === (incomingOptions || options).mileage);

            ddOptions = (deductibles || []).map((item) => item.deductible || item.value)
                .map((item) => (
                    {
                        value: isExpress ? item : item.id,
                        label: isExpress ? item : String(getDeductibleLabel(item)),
                        formatted: isExpress ? Number(item.split('')[0]) : null,
                    }
                ))
                .sort((a, b) => (isExpress ? a.formatted > b.formatted : a.value > b.value));
            break;
        default:
            break;
        }

        return ddOptions;
    };

    const onChange = (field, value, extraData) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.product);
            const data = clone.currentOptions[clone.pack];

            if (field === 'surcharge' && extraData) {
                const { surcharges } = data;
                const surcharge = surcharges
                    .find((item) => (isExpress ? item.surchargeCode === extraData.surchargeCode : item.optionId === extraData.optionId));
                if (surcharge) surcharge.active = value;

                const planRecord = isExpress
                    ? rateData.find((item) => item.name === data.coverageName)
                    : rateData[data.type].find((item) => item.planId === data.planId);
                const detail = isExpress
                    ? planRecord.terms
                        .find((item) => item.value === data.term)?.mileages
                        .find((item) => item.value === data.mileage)?.deductibles
                        .find((item) => item.value === data.deductible)
                    : planRecord.terms
                        .find((item) => item.term === data.term && item.mileage === data.mileage && item.deductible.id === data.deductible);

                const innerDetail = detail.rate || detail;
                const otherCharges = (f) => surcharges.filter((x) => (isExpress ? (!x.value && x.active) : x.active)).reduce((a, b) => a + b[f], 0);
                data.otherCharges = otherCharges(isExpress ? 'price' : 'retailRate');
                data.netRate = (innerDetail.netRate || innerDetail.dealerCost) + otherCharges(isExpress ? 'cost' : 'netRate');
                data.sellingPrice = data.retailRate + data.otherCharges + markUp;
            } else if (field === 'info' && extraData) {
                const { contractOptions } = data;
                const option = contractOptions.find((item) => (isExpress
                    ? item.fieldName === extraData.fieldName
                    : item.fieldOrder === extraData.fieldOrder && item.fieldLabel === extraData.fieldLabel));
                if (option) option.value = value;
            } else if (field === 'coverage') {
                if (isExpress) data.coverageName = value;
                if (!isExpress) data.planId = value;
            } else if (field === 'preRate') {
                if (!clone?.surcharges) clone.surcharges = [];
                const sc = clone.surcharges.find((item) => item.surchargeCode === extraData.surchargeCode);

                if (sc) {
                    sc.value = value;
                } else {
                    const {
                        surchargeCode,
                        dataType,
                        surchargeType,
                    } = extraData;

                    clone.surcharges.push({
                        surchargeCode,
                        dataType,
                        value,
                        surchargeType,
                    });
                }
            } else if (field === 'serviceInterval' || field === 'tireRotations') {
                data[field] = !value ? 1 : value;
            } else {
                data[field] = value;
            }

            let planData = isExpress
                ? rateData.find((item) => item.name === data.coverageName)
                : rateData[data.type].find((item) => item.planId === data.planId);
            switch (field) {
            case 'type':
                data.planId = getDropdownOptions('coverage', data.type)[0].value;

                planData = rateData[data.type].find((item) => item.planId === data.planId);
                data.term = getDropdownOptions('term', null, planData, data)[0].value;
                data.mileage = getDropdownOptions('mileage', null, planData, data)[0].value;
                data.deductible = getDropdownOptions('deductible', null, planData, data)[0].value;
                break;
            case 'coverage':
                data.term = getDropdownOptions('term', null, planData, data)[0].value;
                data.mileage = getDropdownOptions('mileage', null, planData, data)[0].value;
                data.deductible = getDropdownOptions('deductible', null, planData, data)[0].value;
                break;
            case 'term':
                data.mileage = getDropdownOptions('mileage', null, planData, data)[0].value;
                data.deductible = getDropdownOptions('deductible', null, planData, data)[0].value;
                break;
            case 'mileage':
                data.deductible = getDropdownOptions('deductible', null, planData, data)[0].value;
                break;
            default:
                break;
            }

            const isDropdownSource = ['type', 'coverage', 'term', 'mileage', 'deductible'].includes(field);
            if (isDropdownSource) {
                const detail = isExpress
                    ? planData.terms
                        .find((item) => item.value === data.term)?.mileages
                        .find((item) => item.value === data.mileage)?.deductibles
                        .find((item) => item.value === data.deductible)
                    : planData.terms
                        .find((item) => item.term === data.term && item.mileage === data.mileage && item.deductible.id === data.deductible);

                const innerDetail = detail.rate || detail;
                const otherCharges = (f) => (innerDetail.options || [])
                    .filter((x) => x.isSurcharge).reduce((a, b) => a + b[f], 0);
                data.retailRate = innerDetail.retailRate || innerDetail.retailPrice;
                data.otherCharges = isExpress ? 0 : otherCharges('retailRate');
                data.netRate = (innerDetail.netRate || innerDetail.dealerCost) + (isExpress ? 0 : otherCharges('netRate'));
                data.sellingPrice = data.retailRate + (isExpress ? 0 : otherCharges('retailRate'));
                data.surcharges = (detail.options || innerDetail.surcharges || [])
                    .map((item) => ({
                        ...item, active: item.isSurcharge || item.value,
                    }));

                if (isExpress) {
                    data.contractFormID = innerDetail.contractFormID;
                    data.rateResponseID = innerDetail.rateResponseID;
                    data.minPrice = innerDetail.minPrice;
                    data.maxPrice = innerDetail.maxPrice;
                    data.serviceInterval = innerDetail.serviceInterval;
                    data.tireRotations = innerDetail.tireRotations;
                }
            }

            if (field === 'coverage') {
                const formFields = isExpress ? rates.find((r) => r.productId === selectedProduct.id)?.rates.formFields : null;
                data.contractOptions = (planData.additionalContractData || formFields || [])
                    .map((item) => ({
                        ...item, value: null,
                    }));
            }

            return {
                ...prevState,
                markUp: isDropdownSource ? 0 : prevState.markUp,
                product: clone,
            };
        });
    };

    const calculateMarkUp = () => {
        setState((prevState) => {
            const { product: input } = prevState;
            const data = input.currentOptions[input.pack];

            return {
                ...prevState,
                markUp: data.sellingPrice - (data.retailRate + data.otherCharges),
            };
        });
    };

    const resetValues = () => {
        setState((prevState) => ({
            ...prevState,
            product: { ...product, currentOptions: optionsBackup },
            markUp: 0,
        }));
    };

    const renderCustomField = (data, index, isPreRate) => {
        const surchargeData = isPreRate
            ? (product.surcharges || []).find((s) => s.surchargeCode === data.surchargeCode)
            : null;

        let label = null;
        let val = null;
        let destination = null;
        if (isPreRate) {
            label = `${data.prompt} / ${data.surchargeType}`;
            val = surchargeData?.value;
            destination = 'preRate';
        }

        if (!isPreRate && isExpress) {
            label = `${data.prompt} / ${data.required ? 'Required' : 'Optional'}`;
            val = data.value;
            destination = 'info';
        }

        if (!isPreRate && !isExpress) {
            label = `${data.fieldLabel} / ${data.required ? 'Required' : 'Optional'}`;
            val = data.value;
            destination = 'info';
        }

        switch ((data.fieldType || data.dataType).toLowerCase()) {
        case PRE_RATING_SURCHARGE_DATA_TYPE.BOOLEAN:
            return (
                <div key={index} className="customFieldInfo">
                    <Form.Group as={Col} className="booleanComponent">
                        <Form.Label>{label}</Form.Label>
                        <Checkbox
                            disableRipple
                            name="booleanComponent"
                            checked={val || false}
                            onChange={(_, value) => onChange(destination, value, data)}
                        />
                    </Form.Group>
                </div>
            );
        case PRE_RATING_SURCHARGE_DATA_TYPE.DECIMAL:
        case PRE_RATING_SURCHARGE_DATA_TYPE.NUMERIC:
            return (
                <div key={index} className="customFieldInfo">
                    <Form.Group as={Col}>
                        <Form.Label>{label}</Form.Label>
                        <InputNumber
                            size="sm"
                            thousandSeparator
                            fixedDecimalScale
                            value={val || 0}
                            onChange={(value) => onChange(destination, value, data)}
                        />
                    </Form.Group>
                </div>
            );
        case PRE_RATING_SURCHARGE_DATA_TYPE.DATE:
            return (
                <div key={index} className="customFieldInfo">
                    <Form.Group as={Col}>
                        <Form.Label>{label}</Form.Label>
                        <DatePicker
                            allowSameDay
                            selected={val}
                            size="sm"
                            className={clsx('form-control form-control-sm')}
                            popperContainer={CalendarContainer}
                            onChange={(date) => onChange(destination, date, data)}
                        />
                    </Form.Group>
                </div>
            );

        case ADDITIONAL_INFO_FIELD_TYPE.ALPHANUMERIC:
        case PRE_RATING_SURCHARGE_DATA_TYPE.STRING:
            return (
                <div key={index} className="customFieldInfo">
                    <Form.Group as={Col}>
                        {isPreRate && (
                            <>
                                <Form.Label>{label}</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={val || ''}
                                    onChange={(e) => onChange(destination, e.target.value, data)}
                                />
                            </>
                        )}
                        {!isPreRate && (
                            <>
                                <Form.Label>{label}</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    {...(!isExpress ? { maxLength: data.length === 0 ? 20 : data.length } : {})}
                                    value={val || ''}
                                    onChange={(e) => onChange(destination, e.target.value, data)}
                                />
                            </>
                        )}
                    </Form.Group>
                </div>
            );
        case PRE_RATING_SURCHARGE_DATA_TYPE.ENUM:
            return (
                <div key={index} className="customFieldInfo">
                    <Form.Group as={Col}>
                        <Form.Label>{label}</Form.Label>
                        <Select
                            name="selectComponent"
                            size="sm"
                            value={val || ''}
                            options={(data.fieldOptions || []).map((k) => ({ value: k, label: k }))}
                            maxMenuHeight={140}
                            onChange={(_, value) => onChange(destination, value, data)}
                        />
                    </Form.Group>
                </div>
            );
        default:
            return null;
        }
    };

    const requiredContractOptions = options.contractOptions
        .sort((a, b) => (!isExpress ? a.fieldOrder > b.fieldOrder : a.fieldName.localeCompare(b.fieldName)));

    const notRegulated = product?.details?.notRegulated || false;
    const preRateSurcharges = isExpress ? rates.find((r) => r.productId === selectedProduct.id)?.rates.surcharges : null;
    const mileageOptions = getDropdownOptions('mileage');
    const deductibleOptions = getDropdownOptions('deductible');

    const canRenderMileage = mileageOptions.length > 0 && String(mileageOptions[0]?.value) !== '-1';
    const canRenderDeductible = deductibleOptions.length > 0 && String(deductibleOptions[0]?.value) !== '-1';
    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={`Product Settings | ${product.productName} - ${product.vendor.vendorName}`}
                onClose={() => onClose()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div>
                        {!isExpress && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Type</Form.Label>
                                    <Select
                                        name="type"
                                        size="sm"
                                        value={options.type}
                                        options={Object.keys(rateData).map((k) => ({ value: k, label: k === 'N' ? 'New' : 'Used' }))}
                                        maxMenuHeight={140}
                                        onChange={(name, value) => onChange(name, value)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label>Coverage</Form.Label>
                                <Select
                                    name="coverage"
                                    size="sm"
                                    value={isExpress ? options.coverageName : options.planId}
                                    options={getDropdownOptions('coverage')}
                                    maxMenuHeight={140}
                                    onChange={(name, value) => onChange(name, value)}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label>Term</Form.Label>
                                <Select
                                    name="term"
                                    size="sm"
                                    value={options.term}
                                    options={getDropdownOptions('term')}
                                    maxMenuHeight={140}
                                    onChange={(name, value) => onChange(name, value)}
                                />
                            </Form.Group>
                        </div>
                        {canRenderMileage && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Mileage</Form.Label>
                                    <Select
                                        name="mileage"
                                        size="sm"
                                        value={options.mileage}
                                        options={mileageOptions}
                                        maxMenuHeight={140}
                                        onChange={(name, value) => onChange(name, value)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {canRenderDeductible && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Deductible</Form.Label>
                                    <Select
                                        name="deductible"
                                        size="sm"
                                        value={options.deductible || 0}
                                        options={deductibleOptions}
                                        maxMenuHeight={140}
                                        onChange={(name, value) => onChange(name, value)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {isExpress && [PRODUCT_CODE.MAINT, PRODUCT_CODE.VSCMAINT, PRODUCT_CODE.PPM].includes(productCode) && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Service Interval</Form.Label>
                                    <InputNumber
                                        size="sm"
                                        min={1}
                                        decimalScale={0}
                                        thousandSeparator
                                        allowNegative={false}
                                        value={options.serviceInterval}
                                        onChange={(value) => onChange('serviceInterval', value)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {menuStage !== MENU_STAGE.CONTRACT && (
                            <div className="copyAll">
                                <Checkbox
                                    checked={options.replicateInAllPackages}
                                    onChange={(_, value) => onChange('replicateInAllPackages', value)}
                                />
                                Copy to All Packages
                            </div>
                        )}
                    </div>
                    <div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.tooltipWrapper}>
                                    Selling Price
                                    {!notRegulated
                                        && (
                                            <Tooltip
                                                title={
                                                    isExpress
                                                        ? MenuHelper.getSellingPriceTooltipMessage(selectedTerm, markUp, deductibleData)
                                                        : MenuHelper.getSellingPriceTooltipMessage(selectedTerm, markUp)
                                                }
                                                placement="top"
                                            >
                                                <InfoOutlinedIcon />
                                            </Tooltip>
                                        )}
                                </Form.Label>
                                <InputNumber
                                    disabled={
                                        !notRegulated
                                        && (!isExpress && selectedTerm.rate.regulatedRuleId === REGULATED_RULE.RETAIL_RATE_NON_CHANGABLE)
                                    }
                                    size="sm"
                                    showCurrency
                                    thousandSeparator
                                    fixedDecimalScale
                                    value={options.sellingPrice}
                                    onChange={(value) => onChange('sellingPrice', value)}
                                    onBlur={calculateMarkUp}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label>Surcharges / Options</Form.Label>
                                <InputNumber
                                    disabled
                                    size="sm"
                                    showCurrency
                                    thousandSeparator
                                    fixedDecimalScale
                                    value={options.otherCharges}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label>Base Retail Rate</Form.Label>
                                <InputNumber
                                    disabled
                                    size="sm"
                                    showCurrency
                                    thousandSeparator
                                    fixedDecimalScale
                                    value={options.retailRate}
                                />
                            </Form.Group>
                        </div>
                        {hasDealerCostPermission && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Dealer Cost</Form.Label>
                                    <InputNumber
                                        disabled
                                        size="sm"
                                        showCurrency
                                        thousandSeparator
                                        fixedDecimalScale
                                        value={options.netRate}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {isExpress && [PRODUCT_CODE.TIRE, PRODUCT_CODE.PPM].includes(productCode) && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Tire Rotations</Form.Label>
                                    <InputNumber
                                        size="sm"
                                        min={1}
                                        decimalScale={0}
                                        thousandSeparator
                                        allowNegative={false}
                                        value={options.tireRotations}
                                        onChange={(value) => onChange('tireRotations', value)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>
                    <div>
                        {options.surcharges.length > 0 && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Surcharges / Options</Form.Label>
                                    <div className="options">
                                        {options.surcharges.map((sc, index) => (
                                            <div key={index} className="surcharge">
                                                <Checkbox
                                                    disabled={isExpress ? sc.value && !sc.isEditable : sc.isSurcharge}
                                                    checked={sc.active}
                                                    onChange={(_, value) => onChange('surcharge', value, sc)}
                                                />
                                                {sc.description}
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                        )}
                        {requiredContractOptions.length > 0 && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>Additional Contract Information</Form.Label>
                                    <div className="options">
                                        {requiredContractOptions.map((co, index) => (
                                            renderCustomField(co, index, false)
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                        )}
                        {isExpress && menuStage !== MENU_STAGE.CONTRACT && (preRateSurcharges || []).length > 0 && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label>PreRate Surcharges</Form.Label>
                                    <div className="options">
                                        {preRateSurcharges.map((ps, index) => (
                                            renderCustomField(ps, index, true)
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    size="small"
                    className={classes.containedSecondaryInfo}
                    onClick={() => updatePricing(product)}
                >
                    Update Pricing
                </Button>
                {isExpress && menuStage !== MENU_STAGE.CONTRACT && (
                    <Button
                        disabled={(product.surcharges || []).length === 0}
                        size="small"
                        className={classes.containedSecondaryInfo}
                        onClick={() => pullRatesWithSurcharges(product, pack)}
                    >
                        Pull Rates
                    </Button>
                )}
                <Button
                    size="small"
                    className={classes.containedSecondaryInfo}
                    onClick={resetValues}
                >
                    Reset Values
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ProductSettingsForm.defaultProps = {
    selectedProduct: {},
    rates: [],
};

ProductSettingsForm.propTypes = {
    rates: PropTypes.array,
    selectedProduct: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    pullRatesWithSurcharges: PropTypes.func.isRequired,
    updatePricing: PropTypes.func.isRequired,
    menuStage: PropTypes.string.isRequired,
};

export default ProductSettingsForm;

import React from 'react';

import clsx from 'clsx';
import { isFinite } from 'lodash';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import CardVehicle from 'components/widgets/CardVehicle';
import { makeStyles, IconButton } from '@material-ui/core';
import CachedSharpIcon from '@material-ui/icons/CachedSharp';
import PaperAddInformation from 'components/widgets/form/PaperAddInformation';

const useStyles = makeStyles((theme) => ({
    boxVehicle: {
        padding: theme.spacing(0.5),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    changeVehicleStyle: {
        color: '#777777',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 0,
        marginTop: '-12px',
        height: 30,
        width: 30,
    },
    iconColor: {
        color: '#2748AC',
    },
    addVehicle: {
        marginBottom: theme.spacing(3),
    },
}));

const AddVehicle = ({ record, toggleModal }) => {
    const classes = useStyles();

    if (isFinite(record.stock)) {
        return (
            <div className={clsx(classes.boxVehicle, 'box-shadow')}>
                <CardVehicle
                    vehicle={record}
                    className="vehicle-item"
                    rightSection={(
                        <IconButton
                            size="small"
                            className={classes.changeVehicleStyle}
                            onClick={toggleModal}
                        >
                            <CachedSharpIcon className={classes.iconColor} />
                        </IconButton>
                    )}
                />
            </div>
        );
    }

    return (
        <PaperAddInformation
            icon={<SearchIcon />}
            label="Add Vehicle"
            className={classes.addVehicle}
            onClickLeftLabel={toggleModal}
        />
    );
};

AddVehicle.propTypes = {
    record: PropTypes.object,
    toggleModal: PropTypes.func.isRequired,
};

AddVehicle.defaultProps = {
    record: {},
};

export default AddVehicle;

export default class LoadingMaskStyle {
    static circularProgress(theme) {
        return ({
            loading: {
                alignItems: 'center',
                background: theme.palette.background.white,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 2000,
            },
        });
    }
}

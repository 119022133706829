import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Box, Typography, RadioGroup, FormControlLabel, Radio,
    Checkbox,
} from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import update from 'immutability-helper';
import 'rc-time-picker/assets/index.css';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import Loading from 'components/widgets/Loading';
import Assignee from 'components/widgets/Assignee';
import SwitchButton from 'components/widgets/SwitchButton';
import ActivitiesStyles from 'styles/modules/ActivitiesStyles';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { ActivityStatuses, InputResult } from 'utils/enum/ActivitiesEnum';
import ActivityContent from 'components/modules/crm/activities/form/ActivityContent';

import { ActivityCallSchema } from 'utils/schema/lead/Activity';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { reminderOption } from 'components/modules/crm/activities/form/ActivityHelper';

// Http
import DateUtils from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import GraphqlClient from 'services/apollo/GraphQLClient';
import ActivityQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import ActivityMutation from 'services/graphQL/mutate/ActivityMutation';
import OpportunitySection from 'components/modules/crm/activities/form/OpportunitySection';
import { isFinite } from 'lodash';

const optionResult = Object.keys(InputResult).map((item) => ({ value: item, label: InputResult[item] }));
const styles = (theme) => ActivitiesStyles.activitiesForm(theme);

class CallForm extends Component {
    constructor(props) {
        super(props);

        this.selectRef = React.createRef();
        this.graphqlClient = new GraphqlClient();
        this.state = {
            data: {
                subject: '',
                assignee: new KeyStore().getUserId(),
                note: '',
                reminderOn: null,
                result: null,
                scheduleDuration: null,
                durationMinutes: null,
                durationSeconds: null,
                schedule: moment().toDate(),
            },
            prospect: {},
            opportunity: {},
            activitiesStatus: ActivityStatuses.SCHEDULED,
        };

        this.initBind();
    }

    componentDidMount() {
        const { isEditing, crmId, activityId } = this.props;
        if (isEditing) {
            this.setState({
                loading: true,
            });

            this.graphqlClient.query(ActivityQuery.GET_ACTIVITY_CALL_BY_ID, { crmId, activityId })
                .then((response) => {
                    const { data, graphQLErrors } = response;
                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        return;
                    }

                    if (data?.getActivityCallById) {
                        const { getActivityCallById } = data;
                        const { opportunity = {}, reminder = {} } = getActivityCallById;
                        const minutes = Math.floor(((getActivityCallById?.call?.duration || 0) / 60) % 60);
                        const seconds = Math.floor((getActivityCallById?.call?.duration || 0) % 60);
                        const durationMinutes = getActivityCallById?.call?.duration ? moment().set({ hours: 0, minutes }) : null;
                        const durationSeconds = getActivityCallById?.call?.duration ? moment().set({ hours: 0, minutes: 0, seconds }) : null;
                        const reminderOn = reminder && DateUtils.isValid(reminder.reminderOn)
                            ? DateUtils.diff(getActivityCallById.realizationDate, reminder.reminderOn, 'minutes') / 60 : 0;

                        this.setState((prevState) => ({
                            data: update(prevState.data, {
                                note: { $set: getActivityCallById.note },
                                subject: { $set: getActivityCallById.subject },
                                assignee: { $set: getActivityCallById.assignee },
                                durationMinutes: { $set: durationMinutes },
                                durationSeconds: { $set: durationSeconds },
                                reminderOn: { $set: reminderOn },
                                schedule: { $set: DateUtils.toLocal(getActivityCallById.realizationDate)?.toDate() },
                                result: { $set: getActivityCallById?.call?.result?.toUpperCase().replace(' ', '_') || null },
                                scheduleDuration: { $set: DateUtils.toLocal(getActivityCallById.realizationDate) },
                            }),
                            opportunity: update(prevState.opportunity, {
                                leadCode: { $set: opportunity.leadCode },
                                crmId: { $set: opportunity.crmId },
                                allowTextCellPhone: { $set: opportunity.allowTextCellPhone },
                            }),
                            prospect: opportunity.prospect,
                            activitiesStatus: getActivityCallById?.call?.status.toUpperCase(),
                        }));
                    }
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    onChangeSwitch(value) {
        this.setState({
            activitiesStatus: value ? ActivityStatuses.SCHEDULED : ActivityStatuses.OUTGOING,
        });
    }

    onChangeGeneralInformation(value = '', field) {
        this.setState((prevState) => update(prevState, {
            data: { [field]: { $set: value } },
        }));
    }

    onChangeStatus(value) {
        this.setState({
            activitiesStatus: value,
        });
    }

    onSaveActivity() {
        const { isEditing, crmId } = this.props;
        const {
            state: {
                activitiesStatus,
                data: {
                    subject, schedule, note, scheduleDuration, result,
                    assignee,
                },
                data,
            },
        } = this;
        const isSchedule = activitiesStatus === ActivityStatuses.SCHEDULED;
        const currentSchedule = DateUtils.formatUTC(schedule);
        const input = {
            processRecordId: crmId,
            subject,
            assignee,
            note,
        };

        const currentScheduleDuration = scheduleDuration ? moment(currentSchedule).set({
            hour: scheduleDuration.hour(), minute: scheduleDuration.minute(), second: 0, millisecond: 0,
        }) : currentSchedule;

        input.realizationDate = DateUtils.formatUTC(currentScheduleDuration);

        if (isSchedule) {
            input.call = { status: ActivityStatuses.SCHEDULED };
            input.reminderOn = isFinite(data.reminderOn) && data.reminderOn !== 0
                ? DateUtils.subtractAndFormatUTC(currentScheduleDuration, data.reminderOn, 'hours') : null;
        } else if ([ActivityStatuses.OUTGOING, ActivityStatuses.INCOMING, ActivityStatuses.COMPLETED].includes(activitiesStatus)) {
            input.call = {
                status: activitiesStatus,
                duration: this.getDurationInSeconds(),
                result,
            };
        } else if (activitiesStatus === ActivityStatuses.MISSED) {
            input.call = {
                status: ActivityStatuses.MISSED,
            };
        }

        if (isEditing) {
            this.onUpdate(input);
        } else {
            this.onSave(input);
        }
    }

    onSave(input) {
        const { onClose } = this.props;
        this.setState({ isSaving: true });
        this.graphqlClient
            .mutate(ActivityMutation.CREATE_CALL_ACTIVITY, { input })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.createCallActivity) {
                    onClose();
                }
            })
            .finally(() => {
                this.setState({ isSaving: false });
            });
    }

    onUpdate(input) {
        const { onClose, activityId } = this.props;
        this.setState({ isSaving: true });
        this.graphqlClient
            .mutate(ActivityMutation.UPDATE_CALL_ACTIVITY, { input, activityId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.updateCallActivity) {
                    onClose();
                }
            })
            .finally(() => {
                this.setState({ isSaving: false });
            });
    }

    onChangeCheckbox(value) {
        this.setState({
            activitiesStatus: value ? ActivityStatuses.COMPLETED : ActivityStatuses.SCHEDULED,
        });
    }

    getDurationInSeconds() {
        const { state: { data: { durationMinutes, durationSeconds } } } = this;

        const minuteToSeconds = durationMinutes ? durationMinutes.minute() * 60 : 0;
        const second = durationSeconds ? durationSeconds.second() : 0;

        return minuteToSeconds + second;
    }

    initBind() {
        this.onSaveActivity = this.onSaveActivity.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeSwitch = this.onChangeSwitch.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.getDurationInSeconds = this.getDurationInSeconds.bind(this);
        this.onChangeGeneralInformation = this.onChangeGeneralInformation.bind(this);
    }

    renderResult() {
        const { state: { data, activitiesStatus } } = this;

        if (activitiesStatus !== ActivityStatuses.MISSED) {
            return (
                <div>
                    <Form.Label>Duration:</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <TimePicker
                                value={data.durationMinutes}
                                showHour={false}
                                showSecond={false}
                                placeholder="Minutes"
                                onChange={(e) => this.onChangeGeneralInformation(e, 'durationMinutes')}
                                className="am-time-picker form-control"
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <TimePicker
                                value={data.durationSeconds}
                                showHour={false}
                                showMinute={false}
                                placeholder="Seconds"
                                onChange={(e) => this.onChangeGeneralInformation(e, 'durationSeconds')}
                                className="am-time-picker form-control"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Result</Form.Label>
                        <Select
                            name="result"
                            options={optionResult}
                            value={data.result}
                            onChange={(_, value) => this.onChangeGeneralInformation(value, 'result')}
                            maxMenuHeight={100}
                        />
                    </Form.Group>
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            activitiesStatus, data, loading,
            opportunity, prospect, isSaving,
        } = this.state;
        const note = data.note || '';
        const {
            classes, onClose, isEditing, lotId,
        } = this.props;
        const isSchedule = activitiesStatus === ActivityStatuses.SCHEDULED;
        const isCompleted = activitiesStatus === ActivityStatuses.COMPLETED;
        const isLog = [ActivityStatuses.OUTGOING, ActivityStatuses.INCOMING, ActivityStatuses.MISSED].includes(activitiesStatus);
        let currentTitle = '';
        if (!isEditing) currentTitle = isSchedule ? 'Schedule' : 'Log';
        const isValidData = isValidSchema(ActivityCallSchema, { ...data, scheduleDuration: data.scheduleDuration?.toDate() });
        const { isValid, errors } = isValidData;
        let titleSchedule = '';
        if (isSchedule) {
            titleSchedule = 'Schedule On';
        } else {
            titleSchedule = activitiesStatus === ActivityStatuses.MISSED ? 'Call on' : 'Started on';
        }

        return (
            <ActivityContent
                title={`${currentTitle} Call`}
                onClose={onClose}
                disabledSaveButton={!isValid || isSaving}
                onSave={this.onSaveActivity}
            >
                <Form>
                    {isEditing && (
                        <OpportunitySection
                            prospect={prospect}
                            opportunity={opportunity}
                        />
                    )}
                    <Form.Group className="d-flex-justify-start-align-center">
                        {isEditing && (
                            <FormControlLabel
                                label="Completed"
                                className={classes.formControlLabel}
                                control={(
                                    <Checkbox
                                        checked={isCompleted}
                                        onChange={(e) => this.onChangeCheckbox(e.target.checked)}
                                        name="completed"
                                    />
                                )}
                            />
                        )}
                    </Form.Group>
                    <Box
                        display="flex"
                        alignItems="center"
                        marginBottom={2}
                    >
                        <Typography variant="subtitle1">Assignee:</Typography>
                        <Assignee
                            className={clsx({ 'invalid-field': isValidField(errors, 'assignee') })}
                            innerRef={this.selectRef}
                            value={data.assignee}
                            lotId={lotId}
                            onChange={(e) => {
                                this.selectRef.current.select.blur();
                                this.onChangeGeneralInformation(e?.value, 'assignee');
                            }}
                        />
                    </Box>
                    {!isEditing && (
                        <SwitchButton
                            firstLabel="Schedule"
                            secondLabel="Log"
                            value={isSchedule}
                            className={classes.switchButton}
                            onChange={this.onChangeSwitch}
                        />
                    )}
                    {isLog && (
                        <Box
                            marginBottom={2}
                            display="flex"
                            justifyContent="center"
                        >
                            <RadioGroup
                                row
                                aria-label="activitiesStatus"
                                name="activitiesStatus"
                                value={activitiesStatus}
                                onChange={(e) => this.onChangeStatus(e.target.value)}
                            >
                                <FormControlLabel value={ActivityStatuses.OUTGOING} control={<Radio className={classes.radioInfo} />} label="Outgoing" />
                                <FormControlLabel value={ActivityStatuses.MISSED} control={<Radio className={classes.radioInfo} />} label="Missed" />
                                <FormControlLabel value={ActivityStatuses.INCOMING} control={<Radio className={classes.radioInfo} />} label="Incoming" />
                            </RadioGroup>
                        </Box>
                    )}
                    <Form.Label>
                        {titleSchedule}
                        :
                    </Form.Label>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <DatePicker
                                className={clsx('form-control', { 'invalid-field': isValidField(errors, 'schedule') })}
                                selected={data.schedule}
                                popperContainer={CalendarContainer}
                                onChange={(date) => this.onChangeGeneralInformation(date, 'schedule')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <TimePicker
                                use12Hours
                                minuteStep={15}
                                showSecond={false}
                                value={data.scheduleDuration}
                                placeholder="hh:mm"
                                onChange={(e) => this.onChangeGeneralInformation(e, 'scheduleDuration')}
                                className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'scheduleDuration') })}
                            />
                        </Form.Group>
                    </Form.Row>
                    {(!isSchedule || isCompleted) && this.renderResult()}
                    {(isSchedule && !isCompleted) && (
                        <>
                            <Form.Group>
                                <Form.Label>Reminder</Form.Label>
                                <Select
                                    name="reminderOn"
                                    maxMenuHeight={100}
                                    options={reminderOption}
                                    value={data.reminderOn}
                                    disabled={data.scheduleDuration === null}
                                    onChange={(_, value) => this.onChangeGeneralInformation(value, 'reminderOn')}
                                />
                            </Form.Group>
                        </>
                    )}
                    <Form.Group id="notegroup">
                        <Box
                            display="flex"
                            justifyContent="end"
                        >
                            <Form.Label className={classes.noteLabel}>{`${note.length}/500`}</Form.Label>
                        </Box>
                        <Form.Control
                            as="textarea"
                            placeholder="Notes"
                            rows="4"
                            maxLength={500}
                            onChange={({ target }) => this.onChangeGeneralInformation(target.value, 'note')}
                            value={note}
                        />
                    </Form.Group>
                </Form>
                {loading && <Loading className={classes.loading} />}
            </ActivityContent>
        );
    }
}

CallForm.propTypes = {
    lotId: PropTypes.number,
    isEditing: PropTypes.bool,
    activityId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CallForm.defaultProps = {
    isEditing: false,
    activityId: null,
    lotId: null,
};

export default withStyles(styles)(CallForm);

import { gql } from '@apollo/client';

export default class LostReasonMutation {
    static ADD_LOST_REASON = gql`
        mutation addLostReason($description: String!) {
            addLostReason(description: $description) {
                lostReasonId
            }
        }
    `;

    static UPDATE_LOST_REASON = gql`
        mutation updateLostReason($description: String!, $lostReasonId: Int!) {
            updateLostReason(description: $description, lostReasonId: $lostReasonId)
        }
    `;

    static DELETE_LOST_REASON = gql`
        mutation deleteLostReason($lostReasonId: Int!) {
            deleteLostReason(lostReasonId: $lostReasonId)
        }
    `;
}

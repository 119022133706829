import { withStyles } from '@material-ui/core/styles';
import AccordionPanel from '@material-ui/core/Accordion';
import AccordionSummaryPanel from '@material-ui/core/AccordionSummary';

const Accordion = withStyles(() => ({
    root: {
        marginBottom: '10px',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
            marginBottom: '10px',
            marginLeft: '2px',
            marginRight: '2px',
        },
        textDecoration: 'initial',
        display: 'inline-block',
        width: 'fit-content',
        minWidth: 'calc(100% - 4px)',
        boxShadow: '1px 1px 4px rgba(0,0,0, 0.1)',
        marginLeft: '2px',
        marginRight: '2px',
        border: '1px solid transparent',
    },
    expanded: {},
}))(AccordionPanel);

const AccordionSummary = withStyles(() => ({
    root: {
        minHeight: 56,
        padding: '8px',
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
}))(AccordionSummaryPanel);

export { Accordion, AccordionSummary };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import { modules } from 'utils/enum/modules';
import { Link as RouteLink } from 'react-router-dom';
import ModalUtils from 'utils/ModalUtils';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import DemosDetails from 'components/modules/inventory/read/panels/DemosDetails';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));

const VehicleInteractions = ({ stockNumber, lotname }) => {
    const classes = useStyles();
    const variables = { variables: { stockNumber } };
    const { data, error } = useQuery(InventoryQuery.GET_VEHICLE_INTERACTIONS, variables);
    const [state, setState] = useState({
        isDemosPopupOpen: false,
        onlyLatest: false,
    });

    if (error) {
        ModalUtils.errorMessage(error?.graphQLErrors);
    }

    const records = data?.getVehicleInteractions || {};
    const {
        totalVDP = 0,
        totalLeads = 0,
        latestLeadsCount = 0,
        appointments = 0,
        appointmentsShowed = 0,
        totaldemos = 0,
        latestDemosCount = 0,
    } = records;

    const toggleDemosPopup = (onlyLatest) => {
        setState((prevState) => ({
            ...prevState,
            isDemosPopupOpen: !state.isDemosPopupOpen,
            onlyLatest,
        }));
    };

    const getOpportunitiesLink = (input) => {
        if (input <= 0) return input;

        return (
            <RouteLink
                to={{
                    pathname: `/${modules.OPPORTUNITIES}/`,
                    search: `?search=${stockNumber}&lot=${lotname}`,
                }}
                target="_blank"
            >
                {input}
            </RouteLink>
        );
    };

    const renderDemosButton = (input, onlyLatest) => {
        if (input <= 0) return input;

        return (
            <RouteLink
                to="#"
                onClick={() => toggleDemosPopup(onlyLatest)}
            >
                {input}
            </RouteLink>
        );
    };

    return (
        <>
            <Grid
                container
                orientation="column"
                className={classes.labelsGrid}
            >
                <DisplayField
                    field="Page Visit"
                    value={totalVDP}
                />
                <DisplayField
                    field="Total Leads"
                    value={getOpportunitiesLink(totalLeads)}
                />
                <DisplayField
                    field="Leads Last 15 Days"
                    value={getOpportunitiesLink(latestLeadsCount)}
                />
                <DisplayField
                    field="Appointments"
                    value={appointments}
                />
                <DisplayField
                    field="Demos Last 15 Days"
                    value={renderDemosButton(latestDemosCount, true)}
                />
                <DisplayField
                    field="Appointments Showed"
                    value={appointmentsShowed}
                />
                <DisplayField
                    field="Total Demos"
                    value={renderDemosButton(totaldemos, false)}
                />
            </Grid>
            {state.isDemosPopupOpen && (
                <DemosDetails
                    stockNumber={stockNumber}
                    onlyLatest={state.onlyLatest}
                    onClose={toggleDemosPopup}
                />
            )}
        </>
    );
};

VehicleInteractions.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    lotname: PropTypes.string.isRequired,
};

export default VehicleInteractions;

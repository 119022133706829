import { gql } from '@apollo/client';

export default class LeadSourcesQuery {
    static GET_LEAD_SOURCES = gql`
        query getLeadSourcesForSettings($active: Boolean!) {
            getLeadSourcesForSettings(active: $active) {
                active
                domain
                leadSource
                leadSourceId
                monthlyAdvertisingCost
                detail {
                    lotName
                    monthlyAdvertisingCost
                }
            }
        }
    `;
}

import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import { Form, Row, Col } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import LeadSourcesMutation from 'services/graphQL/mutate/setting/LeadSourcesMutation';
import LeadSourcesSettingsSchema from 'utils/schema/settings/LeadSourcesSettingsSchema';
import {
    Dialog, DialogContent, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        margin: '0 5px',
        alignItems: 'center',
        justifyContent: 'center',
        '& > label': {
            minWidth: 150,
            fontWeight: 500,
            marginRight: 10,
            marginBottom: 0,
            textAlign: 'end',
            fontSize: '14px',
            color: theme.palette.text.minsk,
        },
        '& > *:last-child': {
            flex: 1,
            minWidth: 400,
        },
    },
    monthlyCostLabel: {
        marginLeft: 100,
        marginBottom: 10,
    },
}));

const initialState = {
    lots: [],
    domain: '',
    active: true,
    leadSource: '',
    leadSourceId: null,
};

const LeadSourcesDialog = ({
    onClose, record, refetch, lots,
}) => {
    const classes = useStyles();
    const [state, setState] = useState(initialState);
    const [saveLeadSource, { loading: updating }] = useMutation(LeadSourcesMutation.SAVE_LEAD_SOURCE);

    useEffect(() => {
        if (isEmpty(record)) {
            setState((item) => ({
                ...item,
                lots: lots.map((lot) => ({ name: lot, value: 0 })),
            }));
        } else {
            setState(() => ({
                domain: record.domain,
                active: record.active,
                leadSource: record.leadSource,
                leadSourceId: record.leadSourceId,
                lots: lots.map((lot) => ({
                    name: lot,
                    value: record.detail?.find((item) => item.lotName.toLowerCase() === lot.toLowerCase())?.monthlyAdvertisingCost || 0,
                })),
            }));
        }
    }, [lots, record]);

    const onChange = ({ field, value }) => {
        setState((item) => ({
            ...item,
            [field]: value,
        }));
    };

    const onChangeLotValue = ({ name, value }) => {
        const index = state.lots.findIndex((lot) => lot.name === name);

        setState(
            update(state, {
                lots: {
                    [index]: {
                        value: {
                            $set: value,
                        },
                    },
                },
            }),
        );
    };

    const onSave = async () => {
        try {
            const response = await saveLeadSource({ variables: { input: state } });

            if (response.data?.saveLeadSource) {
                refetch();
                onClose();
                ModalUtils.successMessage(null, 'Saved Successfully');
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Payroll Mapping'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const isValidData = isValidSchema(LeadSourcesSettingsSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Dialog
            open
            maxWidth="md"
        >
            <DialogAppBar
                onClose={onClose}
                title={isEmpty(record) ? 'New lead source' : 'Editing lead source'}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lead Source:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.leadSource}
                                        className={isValidField(errors, 'leadSource') ? 'invalid-field' : ''}
                                        onChange={(e) => onChange({ field: 'leadSource', value: e.target.value })}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Lead Email Domain:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.domain}
                                        onChange={(e) => onChange({ field: 'domain', value: e.target.value })}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Typography variant="h5" className={classes.monthlyCostLabel}>Monthly Cost</Typography>
                            {state.lots.map(({ name, value }) => (
                                <Form.Row className={classes.row}>
                                    <Form.Group as={Row} className={classes.group}>
                                        <Form.Label>{`${name}:`}</Form.Label>
                                        <InputNumber
                                            size="sm"
                                            showCurrency
                                            value={value}
                                            thousandSeparator
                                            placeholder="0.00"
                                            onChange={(val) => onChangeLotValue({ name, value: val })}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            ))}
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                variant="contained"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                disableSecondaryButton={updating}
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

LeadSourcesDialog.propTypes = {
    record: PropTypes.object,
    lots: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
};

LeadSourcesDialog.defaultProps = {
    record: null,
};

export default LeadSourcesDialog;

const PartsPurchaseSubModules = Object.freeze({
    PARTS: 'parts',
    REORDER_PARTS: 'reorder-parts',
    PARTS_TICKET: 'parts-ticket',
    PURCHASE_ORDER: 'purchase-order',
    PURCHASE_APPROVAL: 'purchase-approval',
    PARTS_REQUEST: 'parts-request',
});

const PartsTicketStatus = Object.freeze({
    NEW: 'New',
    QUOTE: 'Quote',
    IN_PROGRESS: 'In Progress',
    CLOSED: 'Closed',
    VOID: 'Void',
});

const PurchaseOrderStatus = Object.freeze({
    NEW: 'New',
    DRAFT: 'Draft',
    APPROVED: 'Approved',
    RECEIVED: 'Received',
    VOID: 'Void',
    IN_RETURNED_PROCESS: 'In Return',
    RETURNED: 'Returned',
});

const PartsActions = Object.freeze({
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    TRANSFER: 'Transfer',
    DELETE: 'Delete',
});

const PurchaseActions = Object.freeze({
    APPROVE: 'Approve',
    VOID: 'Void',
    RECEIVE: 'Receive',
    PRINT: 'Print',
    RETURN: 'Return',
});

const PartsTicketAction = Object.freeze({
    PROGRESS: 'In Progress',
    CLOSE: 'Close',
    VOID: 'Void',
    QUOTE: 'Quote',
    PRINT: 'Print',
    POST: 'Post',
});

const PartsTicketSalesType = Object.freeze({
    RETAIL: 'Retail',
    WHOLESALE: 'Wholesale',
    WEOWE: 'WeOwe',
});

export {
    PartsPurchaseSubModules, PartsTicketStatus, PurchaseOrderStatus,
    PurchaseActions, PartsActions, PartsTicketAction, PartsTicketSalesType,
};

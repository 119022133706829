export const ACTION_TYPES = {
    OPEN_EDIT_FORM: 'openEditForm',
    SET_DATA: 'setData',
    SAVE_IFRAME_DATA: 'saveIFrameData',
    OPEN_CONTENT_PREVIEW: 'openContentPreview',
    CLOSE_DIALOG: 'closeDialog',
    SAVE_IMAGE_DATA: 'saveImageData',
    SET_IMAGE_UPLOADING: 'setImageUploading',
};

const CommonReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.OPEN_EDIT_FORM: {
        return { ...state, isEditFormOpen: !state.isEditFormOpen };
    }
    case ACTION_TYPES.OPEN_CONTENT_PREVIEW: {
        const { value } = action;

        return { ...state, isContentPreviewOpen: !state.isContentPreviewOpen, data: { ...state.data, value: value || state.data.value } };
    }
    case ACTION_TYPES.CLOSE_DIALOG: {
        return {
            ...state,
            isContentPreviewOpen: false,
            isEditFormOpen: false,
        };
    }
    case ACTION_TYPES.SET_DATA: {
        const { name, value } = action.value;

        if (name === 'value') {
            return { ...state, data: { ...state.data, value } };
        }

        if (name === 'title') {
            return { ...state, currentTitle: value };
        }

        return { ...state, currentURL: value, isFileBeingUploaded: false };
    }
    case ACTION_TYPES.SAVE_IFRAME_DATA: {
        return {
            ...state,
            data: { ...state.data, title: state.currentTitle, value: state.currentURL },
            isEditFormOpen: !state.isEditFormOpen,
        };
    }
    case ACTION_TYPES.SAVE_IMAGE_DATA: {
        return {
            ...state,
            data: { ...state.data, title: state.currentTitle, value: state.currentURL },
            isEditFormOpen: !state.isEditFormOpen,
        };
    }
    case ACTION_TYPES.SET_IMAGE_UPLOADING: {
        return {
            ...state,
            isFileBeingUploaded: true,
        };
    }
    default:
        return state;
    }
};

export default CommonReducer;

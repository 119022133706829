export default class BadgeStyle {
    static getStyle(theme = {}) {
        return ({
            badgeBlue: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.white,
            },
            badgeDarkBlue: {
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.text.white,
            },
            badgeRed: {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.white,
            },
            badgeGreen: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
            },
            badgeYellow: {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.text.white,
            },
            badgeDarkYellow: {
                backgroundColor: theme.palette.warning.dark,
                color: theme.palette.text.white,
            },
            badgePurple: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.white,
            },
            badgeGray: {
                backgroundColor: theme.palette.secondary.gray,
                color: theme.palette.text.primary,
            },
            iconBlue: {
                color: theme.palette.secondary.main,
            },
            iconDarkBlue: {
                color: theme.palette.secondary.dark,
            },
            iconRed: {
                color: theme.palette.error.main,
            },
            iconGreen: {
                color: theme.palette.success.main,
            },
            iconYellow: {
                color: theme.palette.warning.main,
            },
            iconDarkYellow: {
                color: theme.palette.warning.dark,
            },
            iconPurple: {
                color: theme.palette.primary.main,
            },
            iconGray: {
                color: theme.palette.secondary.gray,
            },
        });
    }
}

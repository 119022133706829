import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import OpportunityTagQuery from 'services/graphQL/query/crm/OpportunityTagQuery';

const useTags = (props = {}) => {
    const [tags, setTags] = useState([]);

    // TODO: Add on-demand load in the next changes
    const { data: suggestionResult, loading, error } = useQuery(OpportunityTagQuery.GET_CRM_TAG_BY_OPTION, {
        variables: { active: true, paginate: { start: 0, limit: 100 } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        const records = suggestionResult?.getCRMTagByOption;
        if (!loading && !isEmpty(records)) {
            const result = records.data.map((item) => ({ value: item.tagId, label: item.name }));
            setTags(props.notIncludeEmptyValue ? result : [{ label: 'None', value: null }, ...result]);
        }
    }, [suggestionResult, loading, error, props.notIncludeEmptyValue]);

    return {
        tags,
    };
};

export default useTags;

export const ACTION_TYPES = {
    SET_INIT_DATA: 'setInitData',
    SET_SELECTED_TAB: 'setSelectedTab',
    TOGGLE_DESCRIPTION_MODE: 'toggleDescriptionMode',
    TOGGLE_UPLOADING_IMAGES: 'toggleUploadingImages',
    SET_DESCRIPTION: 'setDescription',
    TOGGLE_CONFIRM_DIALOG_OPEN: 'toggleConfirmDialogOpen',
    TOGGLE_REORDER_MODE_ON: 'toggleReorderModeOn',
    SET_WATERMARKS: 'setWatermarks',
    SET_WATERMARK: 'setWatermark',
    SET_SELECTED_IMAGES: 'setSelectedImages',
    SET_MARKETING_IMAGES_DATA: 'setMarketingImagesData',
    TOGGLE_INSERTING_MARKETING_IMAGES: 'toggleInsertingMarketingImages',
    TOGGLE_GENERATING_VEHICLE_DESCRIPTION: 'toggleGeneratingVehicleDescription',
};

const AdvertisingTabReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_INIT_DATA: {
        return {
            ...state,
            data: action.value,
            imagesCounter: action.value.images?.length,
            isUploadingImages: false,
            isConfirmDialogOpen: false,
            selectedImageForDeletion: -1,
            selectedImages: [],
        };
    }
    case ACTION_TYPES.SET_SELECTED_TAB: {
        return { ...state, selectedTab: action.value };
    }
    case ACTION_TYPES.TOGGLE_DESCRIPTION_MODE: {
        return { ...state, isEditMode: !state.isEditMode };
    }
    case ACTION_TYPES.TOGGLE_UPLOADING_IMAGES: {
        return { ...state, isUploadingImages: !state.isUploadingImages, isConfirmDialogOpen: false };
    }
    case ACTION_TYPES.SET_DESCRIPTION: {
        return { ...state, data: { ...state.data, description: action.value }, ...(action.threadId ? { threadId: action.threadId } : {}) };
    }
    case ACTION_TYPES.TOGGLE_CONFIRM_DIALOG_OPEN: {
        return {
            ...state,
            isConfirmDialogOpen: !state.isConfirmDialogOpen,
            operation: action.value,
            generateByAI: action.generateByAI,
        };
    }
    case ACTION_TYPES.TOGGLE_REORDER_MODE_ON: {
        return { ...state, isReorderModeOn: !state.isReorderModeOn };
    }
    case ACTION_TYPES.SET_WATERMARKS: {
        const data = action.value;
        return {
            ...state,
            watermarks: data,
            watermark: data.length > 0 ? data[0].url : state.watermark,
        };
    }
    case ACTION_TYPES.SET_WATERMARK: {
        return {
            ...state,
            watermark: action.value,
        };
    }
    case ACTION_TYPES.SET_SELECTED_IMAGES: {
        return {
            ...state,
            selectedImages: action.value,
        };
    }
    case ACTION_TYPES.SET_MARKETING_IMAGES_DATA: {
        return {
            ...state,
            marketingImagesData: action.value,
        };
    }
    case ACTION_TYPES.TOGGLE_INSERTING_MARKETING_IMAGES: {
        return {
            ...state,
            isInsertingMarketingImages: !state.isInsertingMarketingImages,
        };
    }
    case ACTION_TYPES.TOGGLE_GENERATING_VEHICLE_DESCRIPTION: {
        return {
            ...state,
            isGeneratingDescription: !state.isGeneratingDescription,
        };
    }
    default:
        return state;
    }
};

export default AdvertisingTabReducer;

/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

const opportunitySchema = yup.object().shape({
    leadType: yup.string().required().matches(/^(.*)?\S+(.*)?$/),
});

export {
    opportunitySchema,
};

import React from 'react';
import PropTypes from 'prop-types';
import loginBackground from 'assets/loginBackground.jpg';
import LoginStyles from 'styles/modules/LoginStyles';
import { Container, Row, Col } from 'react-bootstrap';

// Material UI
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainLogin: {
        backgroundImage: `url(${loginBackground})`,
        height: '100%',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    ...LoginStyles.login(theme),
}));

const MainLayout = (props) => {
    const classes = useStyles();
    const { children } = props;

    return (
        <div className={classes.mainLogin}>
            <div className={classes.loginContainer}>
                <Container className="d-flex-center d-flex-auto">
                    <div className="login-content">
                        <div className="d-flex justify-content-center logo-container">
                            <img className="img" src="https://automatrix.com/wp-content/uploads/2018/10/logoAutomatrix.png" alt="Automatrix" />
                        </div>
                        {children}
                    </div>
                </Container>
                <Row>
                    <Col className="login-footer">
                        <span>{`Automatrix DMS, Copyright © ${new Date().getUTCFullYear()}`}</span>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;

import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import CatalogEnum from 'utils/enum/CatalogEnum';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';

const useDealTypes = () => {
    const [dealTypes, setDealTypes] = useState([]);
    const input = {
        enumDescription: CatalogEnum.DEAL_TYPES,
        sortDirection: DataSort.ASC,
    };
    const { data, loading, error } = useQuery(CatalogQuery.GET_ENUM_VALUES, {
        variables: input,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getEnumCatalogValues)) {
            const { getEnumCatalogValues } = data;
            const result = getEnumCatalogValues.map((item) => ({ value: item.description, label: item.description }));
            setDealTypes([{ label: 'None', value: null }, ...result]);
        }
    }, [data, loading, error]);

    return {
        dealTypes,
    };
};

export default useDealTypes;

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar,
    IconButton, Typography, Slide, Chip,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import CloseIcon from '@material-ui/icons/Close';
import {
    CachedIcon,
    CheckBoxIcon,
    ThumbUpAltOutlinedIcon,
    CheckBoxOutlineBlankIcon,
    PrintOutlinedIcon, ThumbDownAltOutlinedIcon,
} from 'components/icons';

// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useQuery, useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import { useHistory } from 'react-router';
import { modules } from 'utils/enum/modules';
import {
    AccountingCBStatus, AccountingSubModules,
    BankingProcessTypes, GeneralAction, ActionTypeLabels,
} from 'utils/enum/AccountingEnum';
import Header from 'components/widgets/Header';
import LabelValue from 'components/widgets/LabelValue';
import Table from 'components/widgets/Table';
import clsx from 'clsx';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import AccountingCBMapper from 'services/mapData/AccountingCBMapper';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import InputSearch from 'components/widgets/InputSearch';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import printJS from 'print-js';

const keyStore = new KeyStore();

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const printLimit = 30;

export const ACTION_TYPES = {
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValue',
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_TABLE_APPROVED: 'setTableApproved',
    SET_APPROVE_ALL: 'setApproveAll',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        const isChecked = action.value;
        const totalRecords = state.table.records.length;
        const newRecords = isChecked && totalRecords > printLimit
            ? state.table.records.map((item, index) => ({
                ...item,
                isChecked: index < printLimit,
            }))
            : state.table.records.map((item) => ({
                ...item,
                isChecked,
            }));

        const exceedLimit = isChecked && totalRecords > printLimit;

        return {
            ...state,
            table: {
                ...state.table,
                records: newRecords,
            },
            isDirty: newRecords.filter((c) => c.isChecked).length > 0,
            exceedPrintLimit: exceedLimit,
        };
    }
    case ACTION_TYPES.SET_TABLE_APPROVED: {
        const newRecords = state.table.records.map((item) => ({
            ...item,
            isChecked: item.cbId === action.value.cbId ? !item.isChecked : item.isChecked,
        }));

        const selectedRecords = newRecords.filter((c) => c.isChecked);
        let exceedLimit = false;
        if (selectedRecords.length > printLimit) {
            exceedLimit = true;
        }

        return {
            ...state,
            table: {
                ...state.table,
                records: exceedLimit ? state.table.records : newRecords,
            },
            isDirty: newRecords.filter((c) => c.isChecked).length > 0,
            exceedPrintLimit: exceedLimit,
        };
    }
    default:
        return state;
    }
};

const CheckActionList = (props) => {
    const { actionType } = props;
    const initialState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
                ignoreLimit: true,
            },
            search: '',
            useDateRange: true,
            fromDate: new Date(DateUtils.subtractAndFormatUTC(new Date(), 10, 'days')),
            toDate: new Date(),
            status: actionType === GeneralAction.APPROVE ? AccountingCBStatus.POSTED : AccountingCBStatus.APPROVED,
            processType: BankingProcessTypes.CHECK.toLowerCase(),
        },
        table: {
            records: [],
            totalCount: 0,
        },
        isDirty: false,
        couldLostData: false,
        openTakePayment: false,
        exceedPrintLimit: false,
        confirmApproveOpen: false,
        confirmUndoApproveOpen: false,
        confirmPrintOpen: false,
        printing: false,
    };

    const ACCOUNTING_BANKING_CHECK_APPROVE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_APPROVE);
    const ACCOUNTING_BANKING_CHECK_PRINT = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_PRINT);

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyle();

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        isDirty, couldLostData, params,
        table, exceedPrintLimit, confirmApproveOpen, confirmUndoApproveOpen, confirmPrintOpen, printing,
    } = state;

    const approvedRecords = table?.records.filter((c) => c.isChecked).map((c) => c.cbId);

    const {
        data, loading, error,
    } = useQuery(AccountingQuery.GET_ACCOUNTINGCB_CHECK_ACTION_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                fromDate: params.fromDate,
                toDate: params.toDate,
                status: params.status,
                useDateRange: params.useDateRange,
                isActionList: true,
            },
            sort: [
                { fieldName: 'date', dir: DataSort.DESC },
                { fieldName: 'checkNumber', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}`);
    };

    const reload = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const [addRecord, { loading: isSaving }] = useMutation(AccountingMutation.APPROVE_CHECKS, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveChecks) {
                ModalUtils.successMessage(null, 'Successfully approved!');

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        isDirty: false,
                    },
                });

                reload();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [undoApproveRecords] = useMutation(AccountingMutation.UNDO_APPROVAL_CHECKS, {
        onCompleted: (mutationData) => {
            if (mutationData?.undoApproveChecks) {
                ModalUtils.successMessage(null, 'Successfully undo approved!');

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        isDirty: false,
                    },
                });

                reload();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        await addRecord({
            variables: {
                checks: approvedRecords,
            },
        });
    };

    const onUndoApprove = async () => {
        await undoApproveRecords({
            variables: {
                checks: approvedRecords,
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const onSearch = (text) => {
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
                fromDate: null,
            },
        });
    };

    const onFilterChange = (param, value) => {
        if ((param === 'fromDate' || param === 'toDate') && DateUtils.getOnlyDate(params[param]) === DateUtils.getOnlyDate(value)) return;
        if (params[param] === value) return;
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const [printCheck] = useMutation(AccountingMutation.PRINT_CHECK, {
        onCompleted: (mutationData) => {
            if (mutationData?.printCheck) {
                printJS({
                    printable: mutationData?.printCheck,
                    type: 'pdf',
                    showModal: false,
                    onLoadingEnd: () => {
                        dispatch({
                            type: ACTION_TYPES.SET_STATE_VALUES,
                            value: {
                                printing: false,
                            },
                        });
                        reload();
                    },
                });
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        printing: false,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    printing: false,
                },
            });
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getAccountingCBList } = data;

            const processedData = AccountingCBMapper.mapCBLines(getAccountingCBList.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records: processedData,
                    totalCount: getAccountingCBList.totalCount,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    const columns = [
        {
            Header: (header) => {
                const { data: items } = header;
                const allApproved = items.length > 0 && items.filter((c) => c._original.isChecked).length === items.length;

                return allApproved ? (
                    <CheckBoxIcon
                        fontSize="small"
                        className={classes.highlightedColor}
                        onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: false })}
                    />
                )
                    : (
                        <CheckBoxOutlineBlankIcon
                            fontSize="small"
                            className={classes.highlightedColor}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: true })}
                        />
                    );
            },
            Cell: (cell) => {
                const {
                    original: {
                        cbId, isChecked,
                    },
                } = cell;

                const itemChecked = {
                    cbId,
                };

                return (
                    isChecked ? (
                        <CheckBoxIcon
                            className={classes.highlightedColor}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: itemChecked })}
                        />
                    )
                        : (
                            <CheckBoxOutlineBlankIcon
                                className={classes.highlightedColor}
                                onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: itemChecked })}
                            />
                        )
                );
            },
            width: 60,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            sortable: false,
            disableSortBy: false,
            filterable: false,
        },
        {
            Header: 'Bank Account',
            minWidth: 200,
            width: 200,
            id: 'BankAccount',
            accessor: 'bankAccount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => {
                const { original: { accountCOA: { accountNumber, description } } } = cell;
                return (
                    <span>{`${accountNumber} - ${description}`}</span>
                );
            },
        },
        {
            Header: 'Check / Deposit',
            minWidth: 120,
            width: 120,
            id: 'ProcessNumber',
            accessor: 'processNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Paid To',
            minWidth: 60,
            width: 150,
            id: 'PaidTo',
            accessor: 'paidTo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Amount',
            minWidth: 90,
            width: 90,
            id: 'Amount',
            accessor: 'amount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Date',
            minWidth: 90,
            width: 90,
            id: 'Date',
            accessor: 'date',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Memo',
            minWidth: 90,
            id: 'memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Type',
            minWidth: 90,
            width: 120,
            id: 'type',
            accessor: 'type',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Status',
            minWidth: 90,
            width: 90,
            id: 'Status',
            accessor: 'status',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cellData) => {
                const { original: { status } } = cellData;

                return <Chip size="small" label={status} className={actionType === GeneralAction.PRINT ? classes.badgeApproved : classes.primary} />;
            },
        },
        {
            Header: 'User',
            minWidth: 90,
            width: 90,
            id: 'SysUser',
            accessor: 'sysUser',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
    ];

    const checkedAll = table.records.length > 0 && table.records.filter((c) => c.isChecked).length === table.records.length;
    const selectedRecords = table.records.filter((c) => c.isChecked).map((record) => record.checkNumber);
    const recordsAsStrings = `#${selectedRecords.join(', #')}`;
    let printConfirmMessage = '';
    if (selectedRecords.length === 1) {
        printConfirmMessage = `Are you sure you want to print this record: ${recordsAsStrings}?`;
    } else {
        printConfirmMessage = `Are you sure you want to print these records: ${recordsAsStrings}?`;
    }

    const showMobileLayout = (
        <List className={classes.list}>
            {table?.records.map(({
                cbId, paidTo, amount, processNumber, memo, accountCOA: { accountNumber, description }, isChecked,
            }) => {
                const secondaryText = (
                    <>
                        <span className={classes.flex}>
                            <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                Amount : &nbsp;
                            </Typography>
                            <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                {NumberUtils.applyCurrencyFormat(amount)}
                            </Typography>
                        </span>
                        <span className={classes.flex}>
                            <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                Bank Account : &nbsp;
                            </Typography>
                            <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                {[accountNumber, description].join(' ')}
                            </Typography>
                        </span>
                        <span className={classes.flex}>
                            <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                Check # : &nbsp;
                            </Typography>
                            <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                {processNumber}
                            </Typography>
                        </span>
                        {memo
                                            && (
                                                <span className={classes.flex}>
                                                    <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                                        Memo : &nbsp;
                                                    </Typography>
                                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                                        {memo}
                                                    </Typography>
                                                </span>
                                            )}
                    </>
                );

                return (
                    <React.Fragment key={cbId}>
                        <ListItem
                            className={clsx(classes.listItem, isChecked ? classes.listItemApproved : '')}
                            button
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: { cbId } })}
                        >
                            <ListItemText primary={paidTo} secondary={secondaryText} />
                            <ListItemAvatar className={classes.checkboxSelector}>
                                {isChecked ? <CheckBoxIcon className={classes.highlightedColor} />
                                    : <CheckBoxOutlineBlankIcon className={classes.highlightedColor} />}
                            </ListItemAvatar>
                        </ListItem>
                    </React.Fragment>
                );
            })}
        </List>
    );

    const exceedPrintClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: { exceedPrintLimit: false },
        });
    };

    const confirmPrintClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: { confirmPrintOpen: false },
        });
    };

    const confirmPrintClick = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                printing: true,
            },
        });
        printCheck({
            variables: {
                cbIds: approvedRecords,
            },
        });
        confirmPrintClose();
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="lg"
            fullScreen={isMobile}
            fullWidth={!isMobile}
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        <Button
                            variant="contained"
                            startIcon={<CachedIcon />}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_INITIAL_STATE, value: initialState })}
                            disabled={loading}
                            size="small"
                        >
                            Reload
                        </Button>
                        {ACCOUNTING_BANKING_CHECK_APPROVE && actionType === GeneralAction.APPROVE
                        && (
                            <Badge badgeContent={approvedRecords?.length ?? 0} color="primary">
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={<ThumbUpAltOutlinedIcon />}
                                    size="small"
                                    disabled={!isDirty}
                                    onClick={() => dispatch({
                                        type: ACTION_TYPES.SET_STATE_VALUES,
                                        value: { confirmApproveOpen: true },
                                    })}
                                >
                                    Approve
                                </Button>
                            </Badge>
                        )}
                        {ACCOUNTING_BANKING_CHECK_APPROVE && actionType === GeneralAction.UNDO_APPROVE
                            && (
                                <Badge badgeContent={approvedRecords?.length ?? 0} color="primary">
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<ThumbDownAltOutlinedIcon />}
                                        size="small"
                                        disabled={!isDirty}
                                        onClick={() => dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: { confirmUndoApproveOpen: true },
                                        })}
                                    >
                                        Undo Approval
                                    </Button>
                                </Badge>
                            )}
                        {ACCOUNTING_BANKING_CHECK_PRINT && actionType === GeneralAction.PRINT
                        && (
                            <Badge badgeContent={approvedRecords?.length ?? 0} color="primary">
                                <Button
                                    className={classes.buttonSave}
                                    startIcon={<PrintOutlinedIcon />}
                                    size="small"
                                    disabled={!isDirty || printing}
                                    onClick={() => {
                                        dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: {
                                                confirmPrintOpen: true,
                                            },
                                        });
                                    }}
                                >
                                    Print
                                </Button>
                            </Badge>
                        )}
                    </div>
                    <div className={classes.centerItems}>
                        {!isMobile && (
                            <Typography variant="h6" className={classes.title}>
                                {Object.values(ActionTypeLabels).find((c) => c.value === actionType)?.label || 'Print Checks'}
                            </Typography>
                        )}
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.main}>
                    <Header>
                        <div className={classes.containerFilterSearch}>
                            <InputSearch
                                customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                                initialSearch={params.search || ''}
                                onSearch={onSearch}
                            />
                            <DatePicker
                                popperContainer={CalendarContainer}
                                className={clsx('form-control form-control-sm', classes.date)}
                                selected={params.fromDate}
                                maxDate={params.toDate}
                                onChange={(value) => onFilterChange('fromDate', value)}
                                placeholderText="mm/dd/yyyy"
                            />
                            <DatePicker
                                popperContainer={CalendarContainer}
                                className={clsx('form-control form-control-sm', classes.date)}
                                selected={params.toDate || new Date()}
                                minDate={params.fromDate}
                                onChange={(value) => onFilterChange('toDate', value)}
                                placeholderText="mm/dd/yyyy"
                            />
                            {!isMobile && (
                                <LabelValue
                                    label="Items"
                                    value={table.totalCount}
                                />
                            )}
                            {isMobile && (
                                <Button
                                    variant="contained"
                                    endIcon={checkedAll ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    size="small"
                                    onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: !checkedAll })}
                                >
                                    {checkedAll ? 'Deselect All' : 'Select All'}
                                </Button>
                            )}
                        </div>
                    </Header>
                    {isMobile && loading && <div className={classes.centerDiv}><CircularProgress size="1em" color="inherit" /></div>}
                    {isMobile && !loading && showMobileLayout }
                    {!isMobile && (
                        <div className={classes.mainTable}>
                            <Table
                                data={table?.records}
                                columns={columns}
                                cursor="default"
                                load={loading}
                                totalRecords={table?.totalCount}
                                loadMore={loadMore}
                                rowSelected
                                className="-highlight"
                            />
                        </div>
                    )}
                </div>
                <ConfirmDialog
                    title="Attention - You have unsaved changes!"
                    description="Do you want to close without saving?"
                    open={couldLostData}
                    variant="outlined"
                    titlePrimary="Close"
                    titleSecondary="Cancel"
                    onClose={onCloseFormWithoutSave}
                    onClickSecondary={onCloseFormWithoutSave}
                    onClickPrimary={(e) => onPopupClose(e, true)}
                />
            </DialogContent>

            <ConfirmDialog
                title="Exceeding print limit!"
                description={`You cannot print more than ${printLimit} checks at the same time.`}
                open={exceedPrintLimit}
                variant="outlined"
                titlePrimary="Ok"
                titleSecondary="Cancel"
                onClose={exceedPrintClose}
                onClickSecondary={exceedPrintClose}
                onClickPrimary={exceedPrintClose}
            />
            <ConfirmDialog
                title="Approving batch checks!"
                description="Are you sure you want to approve all these checks?"
                open={confirmApproveOpen && !isSaving}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { confirmApproveOpen: false } })}
                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { confirmApproveOpen: false } })}
                onClickPrimary={() => onSave()}
            />
            <ConfirmDialog
                title="Undo Approving batch checks!"
                description="Are you sure you want to undo approve all these checks?"
                open={confirmUndoApproveOpen && !isSaving}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { confirmUndoApproveOpen: false } })}
                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { confirmUndoApproveOpen: false } })}
                onClickPrimary={() => onUndoApprove()}
            />
            <ConfirmDialog
                title="Confirming print!"
                description={printConfirmMessage}
                open={confirmPrintOpen && !printing}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={confirmPrintClose}
                onClickSecondary={confirmPrintClose}
                onClickPrimary={confirmPrintClick}
            />
            {isSaving && <DialogActionMessage message="Approving checks... " />}
            {printing && <DialogActionMessage message="Printing... " />}
        </Dialog>
    );
};

CheckActionList.propTypes = {
    actionType: PropTypes.string,
};

CheckActionList.defaultProps = {
    actionType: GeneralAction.APPROVE,
};

export default CheckActionList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import { FetchPolicy } from 'utils/enum/Core';
import Permission from 'utils/enum/Permissions';
import If from 'components/widgets/conditional/If';
import { ReconTabs } from 'utils/enum/InventoryEnum';
import ModalUtils from 'utils/ModalUtils';
import RepairsListTab from 'components/modules/inventory/read/RepairsListTab';
import InspectionListTab from 'components/modules/inventory/read/InspectionListTab';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '300px',
        },
        '& div.MuiTabs-root div.MuiTabs-flexContainer > button': {
            '& > span': {
                fontSize: '12px',
            },
        },
    },
}));

const ReconTab = ({
    stockNumber,
    canWrite,
    record,
    defaultReconCost,
}) => {
    const client = useApolloClient();
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const saveRepairItem = async (input) => {
        try {
            const { data } = await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_REPAIR_ITEM,
                variables: {
                    input,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            return data?.saveVehicleRepairItem;
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
            return null;
        }
    };

    const keyStore = new KeyStore();
    const INVENTORY_RECON_APPROVE = keyStore.hasPermission(Permission.INVENTORY_RECON_APPROVE);

    const onTabChange = (_, value) => {
        setSelectedTab(value);
    };

    const maximumReconCost = record?.detail?.pricing?.maximumReconCost || defaultReconCost;

    return (
        <div className={classes.main}>
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="INSPECTION LIST" />
                    <Tab label="REPAIRS LIST" />
                </Tabs>
            </AppBar>
            <If condition={selectedTab === ReconTabs.INSPECTION_LIST}>
                <InspectionListTab
                    stockNumber={stockNumber}
                    canWrite={canWrite}
                    canApprove={INVENTORY_RECON_APPROVE}
                    saveRepairItem={saveRepairItem}
                    maximumReconCost={maximumReconCost}
                />
            </If>
            <If condition={selectedTab === ReconTabs.REPAIRS_LIST}>
                <RepairsListTab
                    stockNumber={stockNumber}
                    canWrite={canWrite}
                    canApprove={INVENTORY_RECON_APPROVE}
                    maximumReconCost={maximumReconCost}
                    saveRepairItem={saveRepairItem}
                />
            </If>
        </div>
    );
};

ReconTab.propTypes = {
    stockNumber: PropTypes.number,
    canWrite: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    defaultReconCost: PropTypes.number,
};

ReconTab.defaultProps = {
    stockNumber: 0,
    defaultReconCost: 0,
};

export default ReconTab;

/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
    Grid, makeStyles,
    Button,
} from '@material-ui/core';
import {
    useQuery,
    useApolloClient,
} from '@apollo/client';
import {
    ENTITY_TYPE,
    COMMON_CONSTANT,
} from 'utils/enum/BusinessIntelligenceEnum';
import { FetchPolicy } from 'utils/enum/Core';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import SettingsDialog from 'components/modules/dashboard/SettingsDialog';
import BIQuery from 'services/graphQL/query/businessIntelligence/Query';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import Chart from 'components/widgets/businessIntelligence/Chart';

// Icons
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(3),
        overflow: 'auto',
    },
    managementButton: {
        position: 'absolute',
        top: '6px',
        right: '0px',
        zIndex: 9999,
        '& > button': {
            padding: 0,
            minWidth: 'initial',
            borderRadius: '50px',
            '& > span > span': {
                margin: 0,
                '& > svg': {
                    fontSize: '30px !important',
                    fill: theme.palette.secondary.main,
                },
            },
        },
    },
    componentParent: {
        width: '100%',
        height: '530px',
        border: `0.5px solid ${theme.palette.border.ghost}`,
        padding: '5px',
        overflow: 'auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minHeight: '530px',
            height: 'auto',
        },
    },
    pivotTable: {
        height: '800px',
    },
    loadingChart: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '13px',
    },
}));

const staticComponents = BIHelper.getStaticDashboardComponents();
export default function Dashboard() {
    const classes = useStyles();
    const client = useApolloClient();
    const [state, setState] = useState({
        isSettingsOpen: false,
        charts: null,
        components: null,
        availableComponents: [],
        isCheckingComponents: true,
    });

    const {
        data: chartsData,
        loading: loadingCharts,
        error: errorCharts,
    } = useQuery(BIQuery.PULL_AVAILABLE_CHARTS_PER_USER, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: componentsData,
        loading: loadingComponents,
        error: errorComponents,
        refetch,
    } = useQuery(BIQuery.PULL_DASHBOARD_COMPONENTS_PER_USER, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (errorCharts) {
            ModalUtils.errorMessage(errorCharts?.graphQLErrors);
        }

        if (!loadingCharts) {
            const charts = chartsData?.pullAvailableChartsPerUser;
            if (!charts) return;

            setState((prevState) => ({
                ...prevState,
                charts: charts.filter((c) => !c.customReport),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCharts, errorCharts]);

    useEffect(() => {
        if (errorComponents) {
            ModalUtils.errorMessage(errorComponents?.graphQLErrors);
        }

        if (!loadingComponents) {
            const components = componentsData?.pullDashboardComponentsPerUser;
            if (!components) return;

            setState((prevState) => ({
                ...prevState,
                components,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingComponents, errorComponents]);

    const pullQueryData = async (chartId, componentsReference, removeOldRecords = false) => {
        const chart = state.charts.find((el) => el.id === chartId);
        if (!chart) return;

        const components = componentsReference ?? state.availableComponents;
        const component = components.find((el) => el.chartId === chartId);
        if (componentsReference) setState((prevState) => ({ ...prevState, availableComponents: components }));

        try {
            const { query = '', type } = chart;
            const paginationEnabled = ['@Offset', '@PageSize']
                .every((variable) => query.toLowerCase().includes(variable.toLowerCase()))
                && type === 'Table';
            if (paginationEnabled && !component.paginate) component.paginate = { start: 0, limit: COMMON_CONSTANT.PAGINATION_PAGE_SIZE };

            const { paginate, filters } = component;
            const { data } = await client.query({
                query: BIQuery.PULL_QUERY_RESULT_ENTITY,
                variables: {
                    id: chartId,
                    type: ENTITY_TYPE.CHART,
                    ...(paginate ? { paginate } : {}),
                    ...(filters ? { filters } : {}),
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                const { output: { records, total }, defaultDateValues } = data.pullQueryResultOfEntity;
                component.loading = false;
                component.error = false;
                component.totalRecords = total;
                component.data = removeOldRecords ? records : [...(component.data ?? []), ...records];
                component.paginationComplete = records.length === 0;
                component.loadingMoreData = false;
                component.defaultDateValues = defaultDateValues;
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
            component.loading = false;
            component.error = true;
            component.totalRecords = null;
            component.data = [];
            component.paginationComplete = true;
            component.loadingMoreData = false;
            component.defaultDateValues = [];
        }

        setState((prevState) => ({
            ...prevState,
            availableComponents: components,
        }));
    };

    const loadMoreData = (chartId) => {
        const components = state.availableComponents;
        const component = components.find((el) => el.chartId === chartId);
        const { paginationComplete, paginate, data } = component;

        if (!paginationComplete) {
            component.loadingMoreData = true;
            paginate.start = data.length;
            pullQueryData(chartId, components);
        }
    };

    const pullQueryResultsWithFilters = (chartId, filter) => {
        const components = state.availableComponents;
        const component = components.find((el) => el.chartId === chartId);
        component.paginate.start = 0;
        component.loadingMoreData = true;

        if (!component.filters) component.filters = [filter];
        if (component.filters) {
            const currentIndex = component.filters.findIndex((f) => f.column === filter.column);
            if (currentIndex >= 0) component.filters[currentIndex] = filter;
            if (currentIndex < 0) component.filters.push(filter);
        }

        pullQueryData(chartId, components, true);
    };

    useEffect(() => {
        const { current } = BIHelper.splitComponents(staticComponents, state.charts || [], state.components || []);

        setState((prevState) => ({
            ...prevState,
            availableComponents: current.map((el) => {
                if (el.chartId) {
                    el.loading = true;
                    el.error = false;
                    el.data = [];
                }

                return el;
            }),
            isCheckingComponents: !(state.charts && state.components),
        }));
    }, [state.charts, state.components]);

    useEffect(() => {
        if (!state.isCheckingComponents) {
            const toPull = [];
            state.availableComponents.forEach((component) => {
                if (component.loading) {
                    toPull.push(component.chartId);
                }
            });

            if (toPull.length > 0) ArrayUtils.processPromisesInSequence(toPull, pullQueryData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isCheckingComponents]);

    const toggleSettings = () => {
        setState((prevState) => ({
            ...prevState,
            isSettingsOpen: !prevState.isSettingsOpen,
        }));
    };

    const refetchComponents = () => {
        setState((prevState) => ({
            ...prevState,
            isCheckingComponents: true,
        }));

        refetch();
    };

    return (
        <>
            <div className={classes.managementButton}>
                <Button
                    startIcon={<SettingsIcon />}
                    size="small"
                    onClick={toggleSettings}
                />
            </div>
            <Grid container spacing={3} className={classes.body}>
                {state.availableComponents.map((component, index) => {
                    if (!component.chartId) {
                        const stc = staticComponents.find((el) => el.name === component.name);
                        if (!stc) return null;

                        return (
                            <Grid key={index} item md={(component.width * 12) / 100} sm={12} xs={12}>
                                <div className={classes.componentParent}>
                                    {stc.component()}
                                </div>
                            </Grid>
                        );
                    }

                    if (component.chartId) {
                        const chart = state.charts.find((el) => el.id === component.chartId);
                        if (!chart) return null;

                        let opt = null;
                        const {
                            label,
                            type,
                            query,
                            options,
                        } = chart;
                        try { opt = JSON.parse(options); } catch (ex) { return null; }

                        const isPivotTable = opt.input?.find((item) => item.name === 'PivotTable')?.value === true;
                        return (
                            <Grid id={component.chartId} key={index} item md={(component.width * 12) / 100} sm={12} xs={12}>
                                <div className={clsx(classes.componentParent, isPivotTable ? classes.pivotTable : '')}>
                                    {component.loading && (
                                        <div className={classes.loadingChart}>Loading component, please wait...</div>
                                    )}
                                    {!component.loading && component.error && (
                                        <div className={classes.loadingChart}>Error loading component, check settings...</div>
                                    )}
                                    {!component.loading && !component.error && (
                                        <Chart
                                            cache={false}
                                            tableWidth={document.getElementById(component.chartId)?.clientWidth - 57}
                                            height={400}
                                            label={label}
                                            type={type}
                                            query={query}
                                            totalRecords={component.totalRecords}
                                            data={component.data}
                                            options={opt}
                                            loadMore={component.paginate ? () => loadMoreData(component.chartId) : null}
                                            loadingMoreData={component.loadingMoreData}
                                            pullQueryResultsWithFilters={(filter) => pullQueryResultsWithFilters(component.chartId, filter)}
                                            defaultDateValues={component.defaultDateValues}
                                        />
                                    )}
                                </div>
                            </Grid>
                        );
                    }

                    return null;
                })}
            </Grid>
            {state.isSettingsOpen && (
                <SettingsDialog
                    staticComponents={staticComponents}
                    charts={state.charts || []}
                    components={state.components || []}
                    onClose={toggleSettings}
                    refetchComponents={refetchComponents}
                />
            )}
        </>
    );
}

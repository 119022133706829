import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles, Button, Grid,
    Switch,
} from '@material-ui/core';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import { FetchPolicy, ALL_LOTS, DataSort } from 'utils/enum/Core';
import { useQuery, useMutation } from '@apollo/client';
import LotsCategory from 'utils/enum/LotsCategory';
import ButtonStyles from 'styles/theme/Button';
import ModalUtils from 'utils/ModalUtils';
import { VendorType } from 'utils/enum/Vendors';
import InputNumber from 'components/widgets/InputNumber';
import LotQuery from 'services/graphQL/query/LotQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import Select from 'components/widgets/Select';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    noBorder: {
        border: 'initial !important',
    },
    title: {
        fontSize: '12px',
        marginLeft: '15px',
    },
    toggle: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
        paddingRight: '15px',
        fontSize: '12px',
        marginBottom: '15px',
        '& div.select-bootstrap': {
            width: '180px',
            marginTop: '10px',
        },
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > div:nth-child(2)': {
                display: 'flex',
                justifyContent: 'flex-end',
            },
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            marginRight: '10px',
            borderRight: `1px solid ${theme.palette.border.ghost}`,
            paddingRight: '10px',
            [theme.breakpoints.down('sm')]: {
                borderRight: 'initial',
                marginBottom: '10px',
                justifyContent: 'flex-end',
                paddingRight: 0,
                marginRight: 0,
                '& > button': {
                    width: '80px',
                },
            },
            '& > input': {
                width: '150px',
                marginRight: '10px',
            },
        },
    },
}));

const GeneralSettings = ({ canWrite }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        stockNumber: null,
        isRepairsPromptOpen: false,
        enableRepairs: false,
        enableDefaultFloorPlanned: true,
        defaultFlooringCompany: null,
        flooringCompanies: [],
    });

    const {
        stockNumber,
        isRepairsPromptOpen,
        enableRepairs,
    } = state;

    const {
        REPAIRS_MANAGEMENT_ENABLE,
        FLOOR_PLANNED_DEFAULT,
        FLOORING_COMPANY_DEFAULT,
    } = InventoryCategory;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [
                REPAIRS_MANAGEMENT_ENABLE,
                FLOOR_PLANNED_DEFAULT,
                FLOORING_COMPANY_DEFAULT,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: vendorListResponse,
        loading: loadingVendorList,
        error: vendorListError,
    } = useQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            filter: {
                vendorType: [VendorType.FLOORPLAN],
            },
            sort: {
                field: 'vendorName',
                dir: DataSort.ASC,
            },
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [unblockRecords] = useMutation(InventoryMutation.UNBLOCK_VEHICLE_RECORDS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Record(s) was/were unblocked successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'Error unblocking record(s)');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [saveInventorySettings, { loading: savingSettings }] = useMutation(InventoryMutation.SAVE_INVENTORY_SETTINGS, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => ({
                    ...prevState,
                    isRepairsPromptOpen: false,
                }));

                ModalUtils.successMessage(null, 'Option saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading) {
            const settings = settingsData?.getSettings;
            if (settings) {
                const savedEnableRepairs = settings.find((setting) => setting.key === REPAIRS_MANAGEMENT_ENABLE)?.value ?? 'false';
                const savedFloorPlanned = settings.find((setting) => setting.key === FLOOR_PLANNED_DEFAULT)?.value ?? 'true';
                const savedFlooringCompany = Number(settings.find((setting) => setting.key === FLOORING_COMPANY_DEFAULT)?.value ?? 0);

                setState({
                    ...state,
                    enableRepairs: savedEnableRepairs === 'true',
                    enableDefaultFloorPlanned: savedFloorPlanned === 'true',
                    defaultFlooringCompany: savedFlooringCompany,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    useEffect(() => {
        if (vendorListError) {
            ModalUtils.errorMessage(vendorListError?.graphQLErrors);
            return;
        }

        if (!loadingVendorList) {
            const vendors = vendorListResponse?.getVendorList;
            if (vendors) {
                setState({
                    ...state,
                    flooringCompanies: vendors,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVendorList, vendorListError]);

    const setStockNumber = (value) => {
        setState((prevState) => ({
            ...prevState,
            stockNumber: value,
        }));
    };

    const unblockVehicleRecord = (removeAll = false) => {
        if (!removeAll && (!stockNumber || stockNumber <= 0)) {
            ModalUtils.errorMessage(null, 'Please specify the stock number to unblock');
            return;
        }

        if (!removeAll) {
            unblockRecords({
                variables: {
                    stockNumber,
                },
            });
            return;
        }

        unblockRecords({
            variables: {
                stockNumber: null,
            },
        });
    };

    const toggleRepairsPrompt = () => {
        setState((prevState) => ({
            ...prevState,
            isRepairsPromptOpen: !prevState.isRepairsPromptOpen,
        }));
    };

    const toggleRepairsSwitch = (accepted = false) => {
        if (!enableRepairs && !accepted) {
            toggleRepairsPrompt();
            return;
        }

        saveInventorySettings({
            variables: {
                input: [
                    {
                        value: accepted ? 'true' : 'false',
                        key: REPAIRS_MANAGEMENT_ENABLE,
                        lotName: ALL_LOTS,
                    },
                ],
            },
        });

        setState((prevState) => ({
            ...prevState,
            enableRepairs: !prevState.enableRepairs,
        }));
    };

    const toggleFloorPlannedSwitch = () => {
        setState((prevState) => {
            const newValue = !prevState.enableDefaultFloorPlanned;

            saveInventorySettings({
                variables: {
                    input: [
                        {
                            value: newValue ? 'true' : 'false',
                            key: FLOOR_PLANNED_DEFAULT,
                            lotName: ALL_LOTS,
                        },
                    ],
                },
            });

            return {
                ...prevState,
                enableDefaultFloorPlanned: newValue,
            };
        });
    };

    const setFlooringCompany = (value) => {
        setState((prevState) => {
            saveInventorySettings({
                variables: {
                    input: [
                        {
                            value: String(value),
                            key: FLOORING_COMPANY_DEFAULT,
                            lotName: ALL_LOTS,
                        },
                    ],
                },
            });

            return {
                ...prevState,
                defaultFlooringCompany: value,
            };
        });
    };

    return (
        <>
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <div className={classes.title}>
                        Remove blocking the inventory from editing due to it is being edited by another user
                    </div>
                </Grid>
                <Grid className={classes.actions} item xs={12}>
                    <div>
                        <InputNumber
                            size="sm"
                            value={stockNumber}
                            removeDecimalScale
                            placeholder="Stock Number"
                            onChange={(value) => setStockNumber(value)}
                        />
                        <Button
                            disabled={!canWrite}
                            className={classes.containedSecondaryInfo}
                            size="small"
                            onClick={() => unblockVehicleRecord(false)}
                        >
                            Remove
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={!canWrite}
                            className={classes.containedSecondaryInfo}
                            size="small"
                            onClick={() => unblockVehicleRecord(true)}
                        >
                            Remove All
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid container className={clsx(classes.box, classes.noBorder)}>
                <Grid className={classes.toggle} item xs={12}>
                    <div>
                        Enable ability to add repairs to inventory
                    </div>
                    <div>
                        <Switch
                            disabled={savingSettings}
                            checked={enableRepairs}
                            onChange={() => toggleRepairsSwitch()}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </Grid>
                <Grid className={classes.toggle} item xs={12}>
                    <div>
                        Floor Planned by default
                    </div>
                    <div>
                        <Switch
                            disabled={savingSettings}
                            checked={state.enableDefaultFloorPlanned}
                            onChange={() => toggleFloorPlannedSwitch()}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </Grid>
                <Grid className={classes.toggle} item xs={12}>
                    <div>
                        Flooring Company by default
                    </div>
                    <div>
                        <Select
                            nowrap
                            size="sm"
                            loading={loadingVendorList}
                            disabled={savingSettings}
                            className={classes.input}
                            name="flooringCompany"
                            onChange={(_, value) => setFlooringCompany(value)}
                            value={state.defaultFlooringCompany ?? 0}
                            options={state.flooringCompanies.map((item) => ({
                                value: item.vendorId,
                                label: item.vendorName,
                            }))}
                        />
                    </div>
                </Grid>
            </Grid>
            <ConfirmDialog
                title="Warning"
                description="Activating this feature may cause discrepancy between Accounting and Inventory. Are you sure you want to proceed?"
                open={isRepairsPromptOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="No"
                onClose={toggleRepairsPrompt}
                onClickSecondary={toggleRepairsPrompt}
                onClickPrimary={() => toggleRepairsSwitch(true)}
                disablePrimaryButton={settingsLoading || savingSettings}
            />
        </>
    );
};

GeneralSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default GeneralSettings;

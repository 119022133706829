import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import StringUtils from 'lib/StringUtils';
import { funnelStages } from 'components/modules/crm/enum/Opportunity';

const useStyle = makeStyles((theme = {}) => ({
    container: {
        padding: 10,
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            height: '125px',
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '15px',
        fontSize: '13px',
        alignItems: 'center',
        border: `1px solid ${theme.palette.border.ghost}`,
        paddingTop: '5px',
        paddingBottom: '5px',
        '& > div:nth-child(1)': {
            color: theme.palette.secondary.main,
            borderBottom: `1px solid ${theme.palette.border.ghost}`,
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
}));

const calculateTotals = (summary) => {
    const { totalCustomers = 0 } = summary;
    const result = {
        totalCustomers,
    };

    Object.values(funnelStages).forEach((s) => {
        result[s] = summary[s] ?? 0;
        result[`${s}-percentage`] = totalCustomers !== 0 ? Math.ceil((result[s] / totalCustomers) * 100) : 0;
    });

    return result;
};

const FloorLogSummary = ({ summary }) => {
    const classes = useStyle();
    const totals = calculateTotals(summary);

    return (
        <div className={classes.container}>
            {Object.values(funnelStages).map((s, index) => (
                <div className={classes.item} key={`percentage-${index}`}>
                    <div>{`Ups to ${StringUtils.toPascalCase(s).replace('WriteUp', 'Write-Up')}`}</div>
                    <div>{`${totals[`${s}-percentage`]}%`}</div>
                </div>
            ))}
            <div className={classes.item}>
                <div>Total Customers</div>
                <div>{totals.totalCustomers}</div>
            </div>
            {Object.values(funnelStages).map((s, index) => (
                <div className={classes.item} key={`total-${index}`}>
                    <div>{StringUtils.toPascalCase(s).replace('WriteUp', 'Write-Up')}</div>
                    <div>{totals[s]}</div>
                </div>
            ))}
        </div>
    );
};

FloorLogSummary.propTypes = {
    summary: PropTypes.object.isRequired,
};

export default FloorLogSummary;

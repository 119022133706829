import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const ownStyle = makeStyles((theme) => ({
    borderTable: {
        borderTop: `1px solid ${theme.palette.border.primary}`,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
    },
    mainTable: {
        height: 'calc(100vh - 130px)',
    },
    mainTableMobile: {
        height: 'calc(100vh - 300px)',
    },
    centerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

const ReportContent = (props) => {
    const { loading, children } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...ownStyle(),
    };

    return (
        <div className={clsx(classes.borderTable,
            isMobile ? classes.mainTableMobile : classes.mainTable)}
        >
            {loading
                && <div className={classes.centerDiv}><CircularProgress size="1em" color="inherit" /></div>}
            {!loading && children}
        </div>
    );
};

ReportContent.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
};

ReportContent.defaultProps = {
    children: null,
    loading: false,
};

export default ReportContent;

/* eslint-disable import/prefer-default-export */
export const ACTIVITY_ACTION_TYPE = Object.freeze({
    OPPORTUNITY_CREATED: 'OPPORTUNITY_CREATED',
    OPPORTUNITY_UPDATED: 'OPPORTUNITY_UPDATED',
    OPPORTUNITY_LOST: 'OPPORTUNITY_LOST',
    OPPORTUNITY_SOLD: 'OPPORTUNITY_SOLD',
    OPPORTUNITY_OPEN: 'OPPORTUNITY_OPEN',
    PROCESS_STAGE_UPDATED: 'PROCESS_STAGE_UPDATED',
    TRADE_IN_ADDED: 'TRADE_IN_ADDED',
    TRADE_IN_UPDATED: 'TRADE_IN_UPDATED',
    TRADE_IN_REMOVED: 'TRADE_IN_REMOVED',
    VEHICLE_ADDED: 'VEHICLE_ADDED',
    VEHICLE_REMOVED: 'VEHICLE_REMOVED',
    VEHICLE_CHANGED_TO_MANUALLY: 'VEHICLE_CHANGED_TO_MANUALLY',
    VEHICLE_CHANGED_TO_INVENTORY: 'VEHICLE_CHANGED_TO_INVENTORY',
    VEHICLE_UPDATED: 'VEHICLE_UPDATED',
    VEHICLE_MANUALLY_ADDED: 'VEHICLE_MANUALLY_ADDED',
    VEHICLE_MANUALLY_UPDATED: 'VEHICLE_MANUALLY_UPDATED',
    CREDIT_PULL_REQUESTED: 'CREDIT_PULL_REQUESTED',
    MANAGER_SALESPERSON_ASSIGNED: 'MANAGER_SALESPERSON_ASSIGNED',
    RECORD_MANAGER_ASSIGNED: 'RECORD_MANAGER_ASSIGNED',
    SALESPERSON_ASSIGNED: 'SALESPERSON_ASSIGNED',
    QUICK_NOTE: 'QUICK_NOTE',
    LOT_CHANGED: 'LOT_CHANGED',
    SENT_EMAIL: 'SENT_EMAIL',
    SENT_SMS: 'SENT_SMS',
    AUTOMATED_RESPONSE_COMPLETE: 'AUTOMATED_RESPONSE_COMPLETE',
});

import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { useQuery, useSubscription } from '@apollo/client';
import Table from 'components/widgets/Table';
import { makeStyles } from '@material-ui/core';
import ActivitiesQuery from 'services/graphQL/query/crm/ActivitiesQuery';

import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { concat } from 'lodash';
import ActivitySubscription from 'services/graphQL/subscription/crm/ActivitySubscription';

const useStyles = makeStyles({
    main: {
        height: '250px',
        flex: 1,
        padding: '10px',
    },
    column: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    descriptionCol: {
        whiteSpace: 'inherit !important',
    },
});
const ACTION_TYPE = {
    ADD_RECORD: 'addRecord',
    SET_OFFSET: 'setOffset',
    SET_RECORDS: 'setRecords',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_RECORDS:
        const records = concat(state.records, action.payload?.data);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.payload?.totalCount },
        });
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    case ACTION_TYPE.ADD_RECORD:
        return update(state, {
            records: { $unshift: [action?.payload?.data] },
            totalCount: { $set: state.totalCount + 1 },
        });
    default:
        return state;
    }
};

const NoteTable = ({ crmId }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        open: false,
        records: [],
        totalCount: 0,
        offset: 0,
    });
    const {
        data, error, loading,
    } = useQuery(ActivitiesQuery.GET_NOTE_ACTIVITIES_BY_CRM_ID, {
        variables: { crmId, paging: { start: state.offset, limit: 25 } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const { data: subscriptionData } = useSubscription(ActivitySubscription.UPDATED_ACTIVITY_NOTE, {
        variables: {
            crmId,
        },
        shouldResubscribe: true,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(null, error?.message);
        }

        if (!loading) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data?.getNoteActivitiesByCRMId,
            });
        }
    }, [data, error, loading]);

    useEffect(() => {
        if (subscriptionData) {
            dispatch({
                type: ACTION_TYPE.ADD_RECORD,
                payload: subscriptionData.updatedActivityNote,
            });
        }
    }, [subscriptionData]);

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };

    const columns = [
        {
            Header: 'User',
            accessor: 'createdBy',
            maxWidth: 120,
            Cell: (record) => {
                const { firstName, lastName } = record.value;
                const fullName = `${firstName} ${lastName}`;

                return (
                    <span>{fullName}</span>
                );
            },
        },
        {
            Header: 'Date',
            accessor: 'createdOn',
            maxWidth: 150,
            className: classes.column,
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
        },
        {
            Header: 'Note',
            accessor: 'note',
            className: classes.descriptionCol,
        },
    ];

    return (
        <div className={classes.main}>
            <Table
                rowSelected
                loading={loading}
                columns={columns}
                loadMore={loadMore}
                data={state.records}
                totalRecords={state.totalCount}
            />
        </div>
    );
};

NoteTable.propTypes = {
    crmId: PropTypes.string.isRequired,
};

export default NoteTable;

/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import { makeStyles, Button } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import PayrollSettingsQuery from 'services/graphQL/query/payroll/Settings';
import PayrollSettingsMutation from 'services/graphQL/mutate/payroll/Settings';
import { AddIcon, EditOutlinedIcon, DeleteOutlineIcon } from 'components/icons';
import PayrollAdditionsDeductionsDialog from 'components/modules/settings/payroll/PayrollAdditionsDeductionsDialog';

const useStyles = makeStyles((theme) => ({
    tableHeight: {
        display: 'contents',
    },
    boxContent: {
        overflow: 'hidden',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
    actionButtonSuccess: {
        color: theme.palette.text.outerSpace,
    },
    actionButtonError: {
        color: theme.palette.error.main,
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
}));

const ACTION_TYPES = {
    INIT_STATE: 'initState',
    APPLY_FILTER: 'applyFilter',
    TOGGLE_DELETE_DIALOG: 'toggleDeleteDialog',
    TOGGLE_ADD_EDIT_DIALOG: 'toggleAddEditDialog',
};

const reducer = (state, action) => {
    const {
        type,
        value,
        accessor,
    } = action;
    switch (type) {
    case ACTION_TYPES.INIT_STATE:
        return update(state, {
            items: { $set: value },
            loadTable: { $set: true },
            selectedRecord: { $set: null },
            openAddEditDialog: { $set: false },
            openDeleteDialoog: { $set: false },
        });
    case ACTION_TYPES.TOGGLE_DELETE_DIALOG:
        const { openDeleteDialog } = state;
        return update(state, {
            selectedRecord: { $set: value },
            openDeleteDialog: { $set: !openDeleteDialog },
        });
    case ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG:
        const { openAddEditDialog } = state;
        return update(state, {
            selectedRecord: { $set: value },
            openAddEditDialog: { $set: !openAddEditDialog },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    default: return state;
    }
};

const initState = {
    items: [],
    loadTable: false,
    filtersApplied: [],
    selectedRecord: null,
    openDeleteDialog: false,
    openAddEditDialog: false,
};

const PayrollAdditionsDeductions = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, initState);

    const {
        items, openDeleteDialog, selectedRecord,
        openAddEditDialog, loadTable, filtersApplied,
    } = state;

    const {
        data, loading, error, refetch,
    } = useQuery(PayrollSettingsQuery.GET_PAYROLL_DB_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPayrollDBList } = data;
            const sortedList = ArrayUtils.sortByObjectField(getPayrollDBList, 'bdId');
            dispatch({
                value: sortedList,
                type: ACTION_TYPES.INIT_STATE,
            });
        }
    }, [data, loading, error]);

    const toggleDeleteDialog = ({ record }) => dispatch({
        value: record,
        type: ACTION_TYPES.TOGGLE_DELETE_DIALOG,
    });

    const [removePayrollDBRecord] = useMutation(PayrollSettingsMutation.REMOVE_PAYROLL_DB_RECORD, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.removePayrollDBRecord) {
                ModalUtils.successMessage(null, 'Deleted Successfully');
                toggleDeleteDialog({ record: null });
                refetch();
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const toggleAddEdit = ({ record, saving }) => {
        dispatch({
            value: record,
            type: ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG,
        });
        if (saving) refetch();
    };

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const getColumns = () => [{
        id: 'bdId',
        Header: 'ID',
        minWidth: 50,
        accessor: 'bdId',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 80,
        id: 'description',
        Header: 'Description',
        accessor: 'description',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'bdType',
        minWidth: 80,
        accessor: 'bdType',
        Header: 'Addition or Deduction',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'taxType',
        Header: 'Tax Type',
        accessor: 'taxType',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'actions',
        width: 'auto',
        Header: 'Actions',
        headerClassName: clsx(classes.hidden),
        className: clsx(classes.hidden, 'actionColumnTarget'),
        Cell: (cellData) => {
            const { original, original: { bdId } } = cellData;

            return (
                <div className={classes.buttonSpacing}>
                    {keyStore.hasPermission(Permission.PAYROLL_ADJUSTMENTS_WRITE) && bdId >= 9 && (
                        <>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => toggleAddEdit({ record: original, saving: false })}
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                            >
                                Edit
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => toggleDeleteDialog({ record: original })}
                                startIcon={<DeleteOutlineIcon className={classes.actionButtonError} />}
                            >
                                Delete
                            </Button>
                        </>
                    )}
                </div>
            );
        },
    }];

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            if (column.id !== 'bdId') {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    {keyStore.hasPermission(Permission.PAYROLL_ADJUSTMENTS_WRITE) && (
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => toggleAddEdit({ record: null, saving: false })}
                        >
                            New Adjustment
                        </Button>
                    )}
                </div>
                <div className={classes.tableHeight}>
                    {!loadTable && (
                        <div className={classes.loadingIndicator}>
                            <Loading />
                        </div>
                    )}
                    {loadTable && (
                        <Table
                            data={items}
                            load={loading}
                            columns={loadColumns()}
                            filtered={filtersApplied}
                            totalRecords={items.count}
                            className="-highlight actionsInLine payrollTable"
                            defaultFilterMethod={(localFilter, row) => {
                                const id = localFilter.pivotId || localFilter.id;
                                if (typeof localFilter.value === 'object') {
                                    return row[id] !== undefined
                                        ? localFilter.value.indexOf(row[id]) > -1
                                        : true;
                                }
                                return row[id] !== undefined
                                    ? String(row[id]).indexOf(localFilter.value) > -1
                                    : true;
                            }}
                        />
                    )}
                </div>
            </div>
            <ConfirmDialog
                titlePrimary="Yes"
                variant="outlined"
                open={openDeleteDialog}
                titleSecondary="Cancel"
                title="Confirm remove addition/deduction"
                onClose={() => toggleDeleteDialog({ record: null })}
                onClickSecondary={() => toggleDeleteDialog({ record: null })}
                description="Are you sure you want to remove this addition/deduction?"
                onClickPrimary={() => removePayrollDBRecord({ variables: { id: selectedRecord.bdId } })}
            />
            {openAddEditDialog && (
                <PayrollAdditionsDeductionsDialog
                    record={selectedRecord}
                    onClose={toggleAddEdit}
                />
            )}
        </>
    );
};
export default PayrollAdditionsDeductions;

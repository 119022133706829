import GraphQLClient from 'services/apollo/GraphQLClient';
import FormsQuery from 'services/graphQL/query/FormsQuery';
import FormMutation from 'services/graphQL/mutate/FormMutation';

export default class FormService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * @param {Object} input
     * @param {Number} input.lotId
    */
    async getFormPacks(input) {
        return this.graphqlClient
            .query(FormsQuery.GET_FORM_PACKS, input);
    }

    /**
     * @param {Object} input
     * @param {string} input.packName
     * @param {Number} input.lotId
    */
    async createFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.CREATE_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {string} input.packName
     * @param {Number} input.lotId
     * @param {Number} input.packId
    */
    async renameFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.RENAME_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {string} input.newName
     * @param {number} input.oldPackId
    */
    async duplicateFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.DUPLICATE_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {Number} input.lotId
     * @param {Number} input.packId
    */
    async deleteFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.DELETE_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.packId
     * @param {number} input.formId
    */
    async addFormToFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.ADD_FORM_TO_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.packId
     * @param {string} input.lotState
    */
    async getAllAvailableForms(input) {
        return this.graphqlClient
            .query(FormsQuery.GET_ALL_AVAILABLE_FORMS, input);
    }

    /**
     * @param {Object} input
     * @param {number} input.packId
    */
    async getFormByFormPacks(input) {
        return this.graphqlClient
            .query(FormsQuery.GET_FORM_BY_FORM_PACKS, input);
    }

    /**
     * @param {Object} input
     * @param {Number} input.dealId
     * @param {Number} input.lotId
     * @param {Array} input.forms
    */
    async printFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.PRINT_FORM_PACK, input);
    }

    /**
     * @param {Object} input
     * @param {Number} input.dealId
     * @param {Number} input.formId
     * @param {Number} input.lotId
    */
    async previewFormPack(input) {
        return this.graphqlClient
            .mutate(FormMutation.PREVIEW_FORM_PACK, input);
    }

    /**
     * @param {Array} forms
    */
    async getCustomFieldByForms(input) {
        return this.graphqlClient
            .query(FormsQuery.GET_CUSTOM_FIELD_BY_FORMS, input);
    }
}

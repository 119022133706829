/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Components and Ohters
import StringUtils from 'lib/StringUtils';
import update from 'immutability-helper';
import { modules } from 'utils/enum/modules';
import { CustomerType } from 'utils/enum/DealEnum';

// GraphQL
import ModalUtils from 'utils/ModalUtils';
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';

// Utilities
import KeyStore from 'utils/KeyStore';
import UserQuery from 'services/graphQL/query/UserQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import { DataSort } from 'utils/enum/Core';
import CatalogEnum from 'utils/enum/CatalogEnum';
import LeadHelper from 'components/modules/lead/LeadHelper';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

const DEFAULT_STATE = {
    open: false,
    isSelectClient: false,
    openEditCustomer: false,
    openDialogVehicle: false,
    openCreateCustomer: false,
    openConfirmationDialog: false,
    buyer: {},
    coBuyer: {},
    vehicle: {},
    saving: false,
    canSave: false,
    customerTag: '',
    dealTypeList: [],
    recordToEdit: null,
    leadSourceList: [],
    salesPersonList: [],
    recordManagers: [],
};

const OpportunityCreateContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.keyStore = new KeyStore();
        this.userAvailableLots = this.keyStore.getUserLots().map((item) => ({ label: item.lotName, value: item.lotId }));
        this.state = {
            ...DEFAULT_STATE,
            opportunityManagement: {
                lotId: this.keyStore.getSelectedLot().lotId,
            },
        };

        this.initBind();
    }

    componentDidMount() {
        this.getLeadSourceList();
        this.getRecordManagers();
        this.getSalesPersons();
        this.getDealType();
    }

    onChangeOpportunity(field, value) {
        this.setState((prevState) => ({
            opportunityManagement: update(prevState.opportunityManagement, {
                [field]: { $set: value },
            }),
        }));
    }

    onDoubleClick(record) {
        const { state: { customerTag } } = this;

        switch (customerTag) {
        case CustomerType.BUYER:
            this.setBuyerInformation(record);
            break;
        case CustomerType.CO_BUYER:
            this.setCoBuyerInformation(record);
            break;
        default:
        }
    }

    onDoubleClickVehicle(record) {
        this.setState({
            openDialogVehicle: false,
            vehicle: record || {},
        });
    }

    onSave() {
        const { saving, opportunityManagement } = this.state;
        if (saving) return;

        if (opportunityManagement.availableCash > 0) {
            this.saveOpportunity();
        } else {
            this.setState({
                openConfirmationDialog: true,
            });
        }
    }

    onChangeLot(field, value) {
        const { opportunityManagement } = this.state;
        if (opportunityManagement.lotId === value) return;

        this.setState((prevState) => ({
            opportunityManagement: update(prevState.opportunityManagement, {
                lotId: { $set: value },
                recordManagerId: { $set: null },
                salesPerson: { $set: null },
            }),
        }), () => {
            this.getRecordManagers();
            this.getSalesPersons();
        });
    }

    setBuyerInformation(record) {
        this.setState({
            isSelectClient: true,
            open: false,
            customerTag: '',
            buyer: record || {},
        }, () => {
            this.subscribeBuyer();
        });
    }

    setCoBuyerInformation(record) {
        const { buyer } = this.state;

        if (buyer.customerCode === record.customerCode) {
            ModalUtils.errorMessage([], "The Buyer and Co Buyer can't be the same.");
        } else {
            this.setState({
                open: false,
                customerTag: '',
                coBuyer: record || {},
            }, () => {
                this.subscribeCoBuyer();
            });
        }
    }

    getLeadSourceList() {
        this.graphqlClient.query(OpportunityQuery.GET_LEAD_SOURCES_FROM_CRM)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getLeadSourcesFromCRM) {
                    const leadSourceList = data.getLeadSourcesFromCRM?.map((item) => ({
                        label: item.leadSource,
                        value: item.leadSourceId,
                    }
                    )) || [];

                    this.setState({
                        leadSourceList,
                    });
                }
            });
    }

    getDealType() {
        const input = {
            enumDescription: CatalogEnum.DEAL_TYPES,
            sortDirection: DataSort.ASC,
        };

        this.graphqlClient.query(CatalogQuery.GET_ENUM_VALUES, input)
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getEnumCatalogValues) {
                    const dealTypeList = data.getEnumCatalogValues?.map((item) => ({
                        label: item.description,
                        value: item.description,
                    }
                    )) || [];

                    this.setState({
                        dealTypeList,
                    });
                }
            });
    }

    getRecordManagers() {
        const { opportunityManagement } = this.state;

        this.graphqlClient.query(UserQuery.GET_RECORD_MANAGERS, { lotId: opportunityManagement.lotId })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getRecordManagers) {
                    const recordManagers = data.getRecordManagers.map((item) => ({ value: item.userId, label: `${item.firstName} ${item.lastName}` }));

                    this.setState((prevState) => ({
                        recordManagers: [{ label: 'None', value: null }, ...recordManagers],
                        opportunityManagement: update(prevState.opportunityManagement, {
                            recordManagerId: { $set: recordManagers.find((item) => item.value === this.keyStore.getUserId())?.value || null },
                        }),
                    }));
                }
            });
    }

    getSalesPersons() {
        const { opportunityManagement } = this.state;

        this.graphqlClient.query(UserQuery.GET_SALESPERSONS, { lotId: opportunityManagement.lotId })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getSalesPersons) {
                    const salesPersonList = data.getSalesPersons.map((item) => ({ value: item.userId, label: `${item.firstName} ${item.lastName}` }));

                    this.setState({
                        salesPersonList: [{ label: 'None', value: null }, ...salesPersonList],
                    });
                }
            });
    }

    getBasicInformationToSaveDeal() {
        const {
            state: {
                buyer, coBuyer, vehicle,
            },
        } = this;

        return {
            buyerCode: buyer.customerCode,
            coBuyerCode: coBuyer.customerCode,
            stockNumber: vehicle.stockNumber,
        };
    }

    closeConfirmDialog() {
        this.setState({
            openConfirmationDialog: false,
            saving: false,
        });
    }

    subscribeBuyer() {
        const { buyer } = this.state;
        const input = {
            customerId: buyer.customerId,
        };

        this.graphqlClient.subscribe(this.responseBuyerSubscription, CustomerSubscription.CUSTOMER_EDITED, input)
            .then((response) => {
                this.buyerSubscription = response;
            });
    }

    subscribeCoBuyer() {
        const { coBuyer } = this.state;
        const input = {
            customerId: coBuyer.customerId,
        };

        this.graphqlClient.subscribe(this.responseCoBuyerSubscription, CustomerSubscription.CUSTOMER_EDITED, input)
            .then((response) => {
                this.coBuyerSubscription = response;
            });
    }

    responseBuyerSubscription(record) {
        const { data } = record;

        if (data?.customerEdited) {
            const { customerEdited } = data;
            this.setState((prevState) => ({
                buyer: update(prevState.buyer, {
                    $set: customerEdited,
                }),
            }));
        }
    }

    responseCoBuyerSubscription(record) {
        const { data } = record;

        if (data?.customerEdited) {
            const { customerEdited } = data;
            this.setState((prevState) => ({
                coBuyer: update(prevState.coBuyer, {
                    $set: customerEdited,
                }),
            }));
        }
    }

    unsubscribeBuyer() {
        if (this.buyerSubscription) {
            this.buyerSubscription.unsubscribe();
        }
    }

    unsubscribeCoBuyer() {
        if (this.coBuyerSubscription) {
            this.coBuyerSubscription.unsubscribe();
        }
    }

    // Check this letter
    validateRequiredFields() {
        const isEnabled = true;

        this.setState({
            canSave: isEnabled,
        });
    }

    saveOpportunity() {
        this.setState({ saving: true });
        const { history } = this.props;
        const {
            buyer, opportunityManagement, coBuyer, vehicle,
        } = this.state;
        const input = {
            prospectCode: buyer.customerCode,
            coProspectCode: coBuyer.customerCode,
            recordManagerId: opportunityManagement.recordManagerId,
            salespersonId: opportunityManagement.salesPerson,
            availableCash: opportunityManagement.availableCash,
            leadSourceId: opportunityManagement.leadSourceId,
            leadType: LeadHelper.mapLeadTypeToSave(opportunityManagement.leadType),
            dealType: opportunityManagement.dealType,
            lotId: opportunityManagement.lotId,
            stockNumber: vehicle.stockNumber,
        };

        this.graphqlClient.mutate(OpportunityMutation.CREATE_OPPORTUNITY, { input })
            .then((response) => {
                const { data, graphQLErrors } = response;

                this.setState({
                    saving: false,
                });

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.createOpportunity?.success) {
                    const { createOpportunity } = data;
                    history.push(`/${modules.OPPORTUNITIES}/${createOpportunity.crmId}`);
                }
            });
    }

    toggleModalVehicle() {
        this.setState((prevState) => ({
            openDialogVehicle: !prevState.openDialogVehicle,
        }));
    }

    cancelCreate() {
        this.unsubscribeBuyer();
        this.unsubscribeCoBuyer();
        const { history, location } = this.props;
        const { isFloorLog } = location?.state ?? {};

        history.push(isFloorLog ? `/${modules.FLOORLOG}` : `/${modules.OPPORTUNITIES}`);
    }

    toggleModal(value) {
        const currentValue = !StringUtils.isEmpty(value) ? value : '';

        this.setState((prevState) => ({
            open: !prevState.open,
            customerTag: currentValue,
        }));
    }

    toggleCreateCustomer(value) {
        const currentValue = !StringUtils.isEmpty(value) ? value : '';

        this.setState((prevState) => ({
            openCreateCustomer: !prevState.openCreateCustomer,
            customerTag: currentValue,
        }));
    }

    toggleEditCustomer(record) {
        this.setState((prevState) => ({
            recordToEdit: prevState.openEditCustomer ? null : record,
            openEditCustomer: !prevState.openEditCustomer,
        }));
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.onChangeLot = this.onChangeLot.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.subscribeBuyer = this.subscribeBuyer.bind(this);
        this.saveOpportunity = this.saveOpportunity.bind(this);
        this.subscribeCoBuyer = this.subscribeCoBuyer.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.toggleModalVehicle = this.toggleModalVehicle.bind(this);
        this.toggleEditCustomer = this.toggleEditCustomer.bind(this);
        this.onChangeOpportunity = this.onChangeOpportunity.bind(this);
        this.toggleCreateCustomer = this.toggleCreateCustomer.bind(this);
        this.onDoubleClickVehicle = this.onDoubleClickVehicle.bind(this);
        this.validateRequiredFields = this.validateRequiredFields.bind(this);
        this.responseBuyerSubscription = this.responseBuyerSubscription.bind(this);
        this.responseCoBuyerSubscription = this.responseCoBuyerSubscription.bind(this);
    }

    render() {
        const { props, state } = this;
        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onChangeLot={this.onChangeLot}
                toggleModal={this.toggleModal}
                cancelCreate={this.cancelCreate}
                onDoubleClick={this.onDoubleClick}
                saveOpportunity={this.saveOpportunity}
                userAvailableLots={this.userAvailableLots}
                closeConfirmDialog={this.closeConfirmDialog}
                toggleModalVehicle={this.toggleModalVehicle}
                toggleEditCustomer={this.toggleEditCustomer}
                onChangeOpportunity={this.onChangeOpportunity}
                onDoubleClickVehicle={this.onDoubleClickVehicle}
                toggleCreateCustomer={this.toggleCreateCustomer}
            />
        );
    }
};

export default OpportunityCreateContainer;

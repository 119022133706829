/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import * as yup from 'yup';

// eslint-disable-next-line
const urlRegex = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const phoneRegex = /^[+]?[0-9]{3}?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;

const PortfolioSettingSchema = yup.object().shape({
    type: yup.string().required(),
    description: yup.string().required(),
    name: yup.string().required(),
    addressName: yup.string().required(),
    address1: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required().test(
        'required',
        'Zip is required.',
        function (zip) {
            return zip.trim().length === 5 && zip !== '00000';
        },
    ),
    phone: yup.string().required().test(
        'required',
        'Cell phone is required.',
        function (phone) {
            return phone.trim().length > 0 && phoneRegex.test(phone.trim());
        },
    ),
    website: yup.string().notRequired().nullable().test(
        'invalid',
        'Enter a valid url.',
        function (website) {
            const websiteTrimmed = website.trim();
            return websiteTrimmed.length === 0 ? true : urlRegex.test(websiteTrimmed);
        },
    ),
});

export {
    PortfolioSettingSchema,
};

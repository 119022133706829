import React, { Component } from 'react';

import { clone } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Select from 'components/widgets/Select';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import {
    DialogContent, withStyles, Dialog, Grid,
} from '@material-ui/core';

const styles = {
    dropdown: {
        width: 400,
        marginTop: 10,
    },
};

class VendorsSelectionForm extends Component {
    constructor(props) {
        super(props);

        // Instance GraphQL Client
        this.graphQLClient = new GraphQLClient();

        this.state = {
            listAvailableIntegrations: clone(props.listAvailableIntegrations),
            vendor: {},
        };

        this.initBind();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { state } = this;
        const { listAvailableIntegrations } = state;

        if (listAvailableIntegrations !== nextProps.listAvailableIntegrations) {
            this.setState({
                listAvailableIntegrations: nextProps.listAvailableIntegrations,
            });
        }
    }

    onNext() {
        const { props, state } = this;
        const { vendor } = state;

        props.onNext(vendor);
    }

    onChange(fieldName, value) {
        this.setState({
            [fieldName]: value,
        });
    }

    initBind() {
        this.onNext = this.onNext.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const { state, props } = this;
        const { classes } = props;
        const { listAvailableIntegrations, vendor } = state;
        const disabledButton = Object.keys(vendor).length === 0;

        return (
            <Dialog
                open
                maxWidth="sm"
            >
                <DialogAppBar
                    iconSize="sm"
                    title="New Integration"
                    onClose={props.onClose}
                />
                <DialogContent>
                    <Grid container spacing={1} className="am-form">
                        <Form.Group as={Col} className={classes.dropdown}>
                            <Form.Label>Select a vendor to integrate</Form.Label>
                            <Select
                                name="vendor"
                                value={vendor}
                                maxMenuHeight={140}
                                placeholder="Select"
                                onChange={this.onChange}
                                className="select-bootstrap"
                                options={listAvailableIntegrations.map((item) => ({ value: item, label: item.name }))}
                            />
                        </Form.Group>
                    </Grid>
                </DialogContent>
                <DialogActions
                    titlePrimary="Next"
                    hiddenSecondaryButton
                    onClickPrimary={this.onNext}
                    disablePrimaryButton={disabledButton}
                />
            </Dialog>
        );
    }
}

VendorsSelectionForm.propTypes = {
    onNext: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    listAvailableIntegrations: PropTypes.array.isRequired,
};

export default withRouter(withStyles(styles)(VendorsSelectionForm));

import { useApolloClient } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import SettingsMutation from 'services/graphQL/mutate/SettingsMutation';

const usePurchasingActions = () => {
    const client = useApolloClient();

    const createFrom = async (
        dispatcher,
        loadingActionType,
        afterCreationActionType,
        value,
    ) => {
        dispatcher({
            type: loadingActionType,
        });

        try {
            const { data: purchasedFromResult } = await client.mutate({
                mutation: SettingsMutation.CREATE_PURCHASED_FROM,
                variables: { name: value },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const purchasedFromId = purchasedFromResult?.createPurchasedFrom;
            if (purchasedFromId) {
                dispatcher({
                    type: afterCreationActionType,
                    payload: {
                        purchasedFromId,
                        name: value.toUpperCase(),
                    },
                });
            }
        } catch (error) {
            dispatcher({
                type: loadingActionType,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const createAgent = async (
        dispatcher,
        loadingActionType,
        afterCreationActionType,
        value,
    ) => {
        dispatcher({
            type: loadingActionType,
        });

        try {
            const { data: purchasingAgentResult } = await client.mutate({
                mutation: SettingsMutation.CREATE_PURCHASING_AGENT,
                variables: { name: value },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const purchasingAgentsId = purchasingAgentResult?.createPurchasingAgent;
            if (purchasingAgentsId) {
                dispatcher({
                    type: afterCreationActionType,
                    payload: {
                        purchasingAgentsId,
                        name: value.toUpperCase(),
                    },
                });
            }
        } catch (error) {
            dispatcher({
                type: loadingActionType,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const editAgent = async (
        dispatcher,
        loadingActionType,
        afterCreationActionType,
        id,
        value,
    ) => {
        dispatcher({
            type: loadingActionType,
        });

        try {
            await client.mutate({
                mutation: SettingsMutation.EDIT_PURCHASING_AGENT,
                variables: { id, name: value },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            dispatcher({
                type: afterCreationActionType,
                payload: {
                    id,
                    name: value.toUpperCase(),
                },
            });
        } catch (error) {
            dispatcher({
                type: loadingActionType,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const deleteAgent = async (
        dispatcher,
        loadingActionType,
        afterCreationActionType,
        id,
    ) => {
        dispatcher({
            type: loadingActionType,
        });

        try {
            await client.mutate({
                mutation: SettingsMutation.DELETE_PURCHASING_AGENT,
                variables: { id },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            dispatcher({
                type: afterCreationActionType,
                payload: {
                    id,
                },
            });
        } catch (error) {
            dispatcher({
                type: loadingActionType,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    };

    return {
        createFrom,
        createAgent,
        editAgent,
        deleteAgent,
    };
};

export default usePurchasingActions;

import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AccordionSummary } from 'components/widgets/Accordion';
import ExpansionPanelStyles from 'styles/widgets/ExpansionPanelStyles';

const styles = (theme) => ExpansionPanelStyles.expandRow(theme);

class ExpandRow extends Component {
    render() {
        const { props: { classes, children, className } } = this;

        return (
            <AccordionSummary
                aria-controls="panel1bh-content"
                className={clsx(classes.expansionPanel, className)}
            >
                {children}
            </AccordionSummary>
        );
    }
}

ExpandRow.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ExpandRow.defaultProps = {
    className: '',
    children: null,
};

export default withStyles(styles)(ExpandRow);

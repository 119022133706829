import * as yup from 'yup';

const InvoiceSchema = yup.object().shape({
    billDate: yup.date().required(),
    dueDate: yup.date().required().min(
        yup.ref('billDate'),
        "due date can't be before bill date",
    ),
    vendorId: yup.number().required().moreThan(0),
    invoiceNumber: yup.string().required('').max(
        200,
        'Invoice number has a maximum limit of 200 characters',
    ),
    invoiceAmount: yup.number().required(),
    vendorName: yup.string().max(500),
    status: yup.string().max(100),
});

export default InvoiceSchema;

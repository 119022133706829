import React, { useEffect, useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    makeStyles, Button, Grid, fade,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import ButtonStyles from 'styles/theme/Button';
import LotQuery from 'services/graphQL/query/LotQuery';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import DealsSettingsMutate from 'services/graphQL/mutate/DealsSettingsMutate';
import ModalUtils from 'utils/ModalUtils';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
        height: 'auto !important',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const ACTION_TYPE = {
    SET_LOTS: 'setLots',
    SET_MAPPINGS: 'setMappings',
    SET_RECORD_CHANGED: 'setRecordChanged',
};

const reducer = (state, action) => {
    const {
        type,
        payload,
    } = action;

    switch (type) {
    case ACTION_TYPE.SET_LOTS:
        return {
            ...state,
            lots: payload,
            loading: false,
        };
    case ACTION_TYPE.SET_MAPPINGS:
        const { lots } = state;
        const records = [];
        const mappingList = payload === null ? [] : JSON.parse(payload);
        lots.forEach((lot) => {
            const mapping = mappingList.find((item) => item.target.trim().toLowerCase() === lot.lotName.trim().toLowerCase());

            if (mapping) {
                records.push(mapping);
            } else {
                records.push({
                    target: lot.lotName,
                    origin: '',
                });
            }
        });
        return {
            ...state,
            mappings: payload,
            records,
            loading: false,
        };
    case ACTION_TYPE.SET_RECORD_CHANGED: {
        const { currentRecord, index } = payload;
        const newRecords = [...state.records];
        newRecords[index] = currentRecord;
        return {
            ...state,
            records: newRecords,
        };
    }
    default:
        return state;
    }
};

const initialState = {
    lots: [],
    loading: true,
    mappings: [],
    records: [],
};

const BHPHGeneralSettings = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        data: lotData,
        error: lotDataError,
        loading: loadingLots,
    } = useQuery(LotQuery.GET_LOTS, {
        variables: { filter: { active: true } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: mappingsData,
        error: mappingsError,
        loading: mappingsLoading,
    } = useQuery(DealsSettingsQuery.GET_DEAL_TRANSFER_LOTS_MAPPINGS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loadingLots && lotData?.lotList) {
            dispatch({
                type: ACTION_TYPE.SET_LOTS,
                payload: lotData.lotList,
            });
        }
    }, [lotData, loadingLots]);

    useEffect(() => {
        if (!mappingsLoading && mappingsData) {
            dispatch({
                type: ACTION_TYPE.SET_MAPPINGS,
                payload: mappingsData?.getDealTransferLotsMappings,
            });
        }
    }, [mappingsData, mappingsLoading]);

    if (lotDataError) {
        ModalUtils.errorMessage(null, lotDataError?.message);
    }

    if (mappingsError) {
        ModalUtils.errorMessage(null, mappingsError?.message);
    }

    const [saveMappings, { loading: isSaving }] = useMutation(DealsSettingsMutate.SAVE_DEAL_TRANSFER_LOT_MAPPINGS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Deal Transfer Lot Mappings saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Deal Transfer Lot Mappings ');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const save = () => {
        const { records } = state;

        if (records.length > 0) {
            saveMappings({
                variables: {
                    input: records,
                },
            });
        }
    };

    const onChange = async (field, index, value) => {
        const { records } = state;
        if (value === records[index][field]) return;
        const currentRecord = { ...records[index] };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPE.SET_RECORD_CHANGED,
            payload: {
                currentRecord,
                index,
            },
        });
    };

    const shouldDisableButton = () => ((loadingLots || mappingsLoading || isSaving));
    const saveButtonText = () => ((isSaving) ? 'Saving' : 'Save');

    return (
        <>
            <Grid container className={classes.box}>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Lot</Form.Label>
                        </Form.Group>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Sales Lot</Form.Label>
                        </Form.Group>
                    </Grid>
                </Grid>
                {state.records.map((item, index) => (
                    <Grid container spacing={1} key={index}>
                        <Grid item lg={3} md={4} xs={6}>
                            <Form.Group as={Col}>
                                <Form.Control
                                    name={`targetLot${index + 1}`}
                                    readOnly
                                    value={item.target}
                                    type="text"
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item lg={3} md={4} xs={6}>
                            <Form.Group as={Col}>
                                <Form.Control
                                    name={`originLot${index + 1}`}
                                    type="text"
                                    value={item.origin}
                                    onChange={(e) => onChange('origin', index, e.target.value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                ))}

                {/* <If condition={writable}> */}
                <Grid container>
                    <Grid item lg={6} md={8} xs={12}>
                        <Form.Group className={classes.customTabsSave} as={Col}>
                            <Button
                                className={classes.containedSecondaryInfo}
                                size="small"
                                disabled={shouldDisableButton()}
                                onClick={save}
                            >
                                {saveButtonText()}
                            </Button>
                        </Form.Group>
                    </Grid>
                </Grid>
                {/* </If> */}
            </Grid>
        </>
    );
};

export default BHPHGeneralSettings;

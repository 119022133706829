import TimeZoneUtil from 'lib/TimeZoneUtil';

class CompanySettingMap {
    static mapCompanySettingToUpdate(record) {
        return {
            companyName: record.companyName,
            companyAddress: record.companyAddress,
            companyCity: record.companyCity,
            companyState: record.companyState,
            companyZip: record.companyZip,
            companyCountry: record.companyCountry,
            companyPhone: record.companyPhone,
            companyFax: record.companyFax,
            companyEmail: record.companyEmail,
            companyWebsite: record.companyWebsite,
            companyContact: record.companyContact,
            companyEin: record.companyEin,
            secureCloseDmsguid: record.secureCloseEnabled ? record.secureCloseDmsguid : '',
            secureCloseEnabled: record.secureCloseEnabled,
            companyTimeZoneId: record.companyTimeZoneId,
            companyTimezone: TimeZoneUtil.getTimeZoneOffSet(record.companyTimeZoneId),
            plaidClientId: record.plaidClientId,
            plaidSecretKey: record.plaidSecretKey,
        };
    }
}

export default CompanySettingMap;

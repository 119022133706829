import React, { useReducer, useEffect, useContext } from 'react';
import {
    makeStyles, Button, useTheme, useMediaQuery, Tooltip,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Container from 'components/widgets/Container';
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import DateUtils from 'lib/DateUtils';
import { useHistory } from 'react-router-dom';
import {
    AddIcon, EditOutlinedIcon,
    DeleteOutlineOutlinedIcon,
    FileCopyOutlinedIcon,
    PostAddOutlinedIcon,
} from 'components/icons';
import GeneralJournalDetailList from 'components/modules/accounting/journal/list/GeneralJournalDetailList';
import { AccountingSubModules, GeneralAction } from 'utils/enum/AccountingEnum';
import { modules } from 'utils/enum/modules';
import InputSearch from 'components/widgets/InputSearch';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { isEmpty } from 'lodash';
import UserContext from 'components/context/UserContext';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';
import DropdownQuery from 'components/widgets/DropdownQuery';
import PNLForm from 'components/modules/accounting/journal/create/PNLForm';

const keyStore = new KeyStore();

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_JOURNALID: 'setSelectedRow',
    SET_STATE_VALUES: 'setStateValues',
    SET_GJ_ACTION: 'setGJAction',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_SELECTED_JOURNALID: {
        return {
            ...state,
            selectedJournal: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            dataFromSubscription: action.dataFromSubscription,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_GJ_ACTION: {
        return {
            ...state,
            ...action.value,
        };
    }
    default: return action.value;
    }
};

const GeneralJournalList = () => {
    const ACCOUNTING_GENERAL_JOURNAL_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_GENERAL_JOURNAL_WRITE);

    const history = useHistory();
    const classes = useStyle();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const initState = {
        params: {
            search: '',
            offset: 0,
            limit: 50,
            lotName: '',
            userName: 0,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedJournal: '',
        actionRecord: null,
        actionType: '',
        dataFromSubscription: null,
        openPNLPopup: false,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        params, table, selectedJournal, dataFromSubscription,
        actionRecord, actionType, openPNLPopup,
    } = state;

    const { userInformation } = useContext(UserContext);
    const defaultLot = userInformation?.defaultLot || userInformation?.usersLot || ALL_LOTS;

    const {
        data, loading, error, subscribeToMore,
    } = useQuery(AccountingGLQuery.GENERAL_JOURNAL_LIST, {
        variables: {
            search: params.search,
            offset: params.offset,
            limit: params.limit,
            lotName: params.lotName,
            userId: params.userId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { journalList } = data;

            records.push(...journalList.Journals);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: journalList.recordsLength,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const loadMore = () => {
        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                offset: table?.records?.length || 0,
            },
        });
    };

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: AccountingSubscription.GENERAL_JOURNAL_CHANGED,
            updateQuery: (prev, { subscriptionData }) => {
                dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { dataFromSubscription: { ...subscriptionData.data.generalJournalChanged } } });
            },
        });
        return () => unsubscribe();
    }, [subscribeToMore]);

    useEffect(() => {
        if (!isEmpty(dataFromSubscription?.id || '')) {
            const { action, data: subsData, id } = dataFromSubscription;

            if (action === 'added') {
                const { records, totalCount } = table;
                const currentData = JSON.parse(subsData);

                records.unshift(...currentData);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount: totalCount + 1,
                    },
                    dataFromSubscription: null,
                });
            } else if (action === 'updated') {
                const currentRecords = [...table.records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => String(item.journalId) === String(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, ...currentData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                        },
                        dataFromSubscription: null,
                    });
                }
            } else if (action === 'deleted') {
                const { records, totalCount } = table;

                const currentIndex = records.findIndex((item) => String(item.journalId) === String(id));

                if (currentIndex >= 0) {
                    records.splice(currentIndex, 1);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records,
                            totalCount: totalCount - 1,
                        },
                        dataFromSubscription: null,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromSubscription]);

    const onCloseActionDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                actionRecord: null,
                actionType: '',
                showReprintCheck: false,
                showLockedDate: false,
                selectedJournal: actionType === GeneralAction.DELETE ? '' : selectedJournal,
            },
        });
    };

    const [deleteJournal, { loading: deleting }] = useMutation(AccountingGLQuery.DELETE_GENERAL_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.deleteJournal) {
                ModalUtils.successMessage(null, 'Record deleted successfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [duplicateJournal, { loading: duplicating }] = useMutation(AccountingGLQuery.DUPLICATE_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.duplicateJournal) {
                ModalUtils.successMessage(null, 'Record was duplicated successfully');

                onCloseActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onActionConfirm = async () => {
        if (actionType === GeneralAction.DELETE) {
            await deleteJournal({
                variables: {
                    journalId: actionRecord.journalId,
                    lotName: defaultLot,
                },
            });
        } else if (actionType === GeneralAction.DUPLICATE) {
            await duplicateJournal({
                variables: {
                    journalId: actionRecord.journalId,
                },
            });
        }
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                offset: 0,
            },
        });
    };

    const onActionClick = (val, action) => {
        dispatch({
            type: ACTION_TYPES.SET_GJ_ACTION,
            value: {
                actionRecord: val,
                actionType: action,
            },
        });
    };

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                offset: 0,
            },
        });
    };

    const onGenerateJournal = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPNLPopup: true,
            },
        });
    };

    const onCloseGeneratePNLPopup = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPNLPopup: false,
            },
        });
    };

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Reference #',
            accessor: 'referenceNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Posted Date',
            accessor: 'postedDate',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value }) => DateUtils.getOnlyDate(value),
        },
        {
            Header: 'Created By',
            accessor: 'createdBy',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Created On',
            accessor: 'createdOn',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: ({ value }) => DateUtils.format(value),
        },
        {
            Header: 'Memo',
            accessor: 'memo',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Actions',
            width: 'auto',
            id: 'actions',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter, classes.hidden),
            className: clsx(classes.columnStyle, classes.columnCenter, classes.hidden, 'actionColumnTarget'),
            Cell: (cellData) => {
                const { original } = cellData;

                return (
                    <div className={classes.buttonSpacing}>
                        <Button
                            onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.JOURNALS}/general/${original.journalId}`)}
                            variant="outlined"
                            startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                            size="small"
                        >
                            Edit
                        </Button>
                        {ACCOUNTING_GENERAL_JOURNAL_WRITE && (
                            <Button
                                onClick={() => onActionClick(original, GeneralAction.DUPLICATE)}
                                variant="outlined"
                                startIcon={<FileCopyOutlinedIcon className={classes.actionButtonMain} />}
                                size="small"
                            >
                                Duplicate
                            </Button>
                        )}
                        {ACCOUNTING_GENERAL_JOURNAL_WRITE && (
                            <Button
                                onClick={() => onActionClick(original, GeneralAction.DELETE)}
                                variant="outlined"
                                startIcon={<DeleteOutlineOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Header>
                <div className={clsx(classes.containerFilterSearch, isMobile ? classes.searchFull : '')}>
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <DropdownQuery
                        name="userId"
                        value={params.userId}
                        className={clsx('form-control-xs', classes.dropdownWidth150, isMobile ? classes.searchFull : '')}
                        placeholder="User"
                        onChange={(name, newValue) => onFilterChange(name, newValue)}
                        dataSource={{
                            query: AccountingGLQuery.GET_ACCOUNTING_GL_USER_LIST,
                            rootData: 'getAccountingGLUsers',
                            idField: 'userId',
                            descriptionField: 'userName',
                        }}
                        defaultEmptyLineText="All"
                    />
                </div>
                <div className={classes.buttonSpacing}>
                    {ACCOUNTING_GENERAL_JOURNAL_WRITE
                    && (
                        <Tooltip title="New Journal" placement="top-start">
                            <span>
                                <Button
                                    onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.JOURNALS}/general`)}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    New Journal
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    {isMobile
                    && (
                        <>
                            <Tooltip title="Edit Journal" placement="top-start">
                                <Button
                                    onClick={() => history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.JOURNALS}/general/${selectedJournal.journalId}`)}
                                    variant="outlined"
                                    startIcon={<EditOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Edit Journal
                                </Button>
                            </Tooltip>
                            <Tooltip title="Duplicate Journal" placement="top-start">
                                <Button
                                    onClick={() => onActionClick(selectedJournal, GeneralAction.DUPLICATE)}
                                    variant="outlined"
                                    startIcon={<FileCopyOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Duplicate
                                </Button>
                            </Tooltip>
                        </>
                    )}
                    {ACCOUNTING_GENERAL_JOURNAL_WRITE
                    && (
                        <Tooltip title="Generate P&L" placement="top-start">
                            <span>
                                <Button
                                    onClick={onGenerateJournal}
                                    variant="outlined"
                                    startIcon={<PostAddOutlinedIcon />}
                                    disabled={loading}
                                    size="small"
                                >
                                    Generate P&L
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </div>
            </Header>
            <Container className={classes.containerSplit}>
                <Split
                    sizes={[55, 45]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        rowSelected
                        cursor="default"
                        load={loading}
                        loadMore={loadMore}
                        totalRecords={table.totalCount}
                        data={table.records}
                        columns={columns}
                        className="-highlight actionsInLine"
                        getTrProps={(_, rowInfo) => ({
                            onDoubleClick: () => {
                                if (selectedJournal?.journalId === rowInfo.original.journalId) return;
                                dispatch({ type: ACTION_TYPES.SET_SELECTED_JOURNALID, value: rowInfo.original });
                            },
                            onClick: () => {
                                if (!isMobile) return;
                                if (selectedJournal?.journalId === rowInfo.original.journalId) return;
                                dispatch({ type: ACTION_TYPES.SET_SELECTED_JOURNALID, value: rowInfo.original });
                            },
                        })}
                    />
                    <GeneralJournalDetailList
                        journalId={selectedJournal?.journalId || ''}
                        printingData={{
                            createdBy: selectedJournal?.createdBy,
                            referenceNumber: selectedJournal?.referenceNumber,
                            postedDate: selectedJournal?.postedDate,
                            memo: selectedJournal?.memo,
                        }}
                    />
                </Split>
            </Container>
            <ConfirmDialog
                title={`${actionType} Record #${actionRecord?.referenceNumber}`}
                description={`Are you sure you want to ${actionType} this record?`}
                open={!isEmpty(actionRecord) && !(deleting || duplicating)}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseActionDialog}
                onClickSecondary={onCloseActionDialog}
                onClickPrimary={onActionConfirm}
            />
            {openPNLPopup && (
                <PNLForm
                    showAsPopup
                    onClose={onCloseGeneratePNLPopup}
                />
            )}
            {deleting && <DialogActionMessage message="Deleting Journal... " />}
            {duplicating && <DialogActionMessage message="Duplicating Journal... " />}
        </>
    );
};

export default GeneralJournalList;

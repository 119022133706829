/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';

const PayrollMapsSchema = yup.object().shape({
    mapField: yup.number().required(),
    department: yup.number().required(),
    employeeType: yup.number().required(),
    glAccount: yup.number().moreThan(0).required(),
});

export default PayrollMapsSchema;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import FormTab from 'styles/modules/deals/FormsTab';
import FormDetail from 'components/modules/deals/read/form/FormDetail';
import FormPacks from 'components/modules/deals/read/form/FormPacks';
import FormsTabContainer from 'components/containers/deals/read/forms/FormsTabContainer';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => FormTab.formsTab(theme);

class FormsTab extends Component {
    render() {
        const {
            classes, onSelectFormPacks, isNewFormPack,
            accountNumber, packSelected, lotName,
        } = this.props;
        const defaultActiveKey = isNewFormPack ? 1 : 0;

        return (
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={4} className="gridItem">
                    <FormPacks onSelectFormPacks={onSelectFormPacks} packSelected={packSelected} lotName={lotName} />
                </Grid>
                <Grid item xs={8} className="gridItem">
                    {packSelected?.packId && (
                        <FormDetail
                            packSelected={packSelected}
                            defaultActiveKey={defaultActiveKey}
                            accountNumber={accountNumber}
                        />
                    )}
                </Grid>
                <Grid item xs={4} className="gridItem" />
            </Grid>
        );
    }
}

FormsTab.propTypes = {
    lotName: PropTypes.string,
    isNewFormPack: PropTypes.bool,
    packSelected: PropTypes.object,
    accountNumber: PropTypes.number.isRequired,
    onSelectFormPacks: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

FormsTab.defaultProps = {
    lotName: null,
    packSelected: {},
    isNewFormPack: false,
};

export default withStyles(styles)(FormsTabContainer(FormsTab));

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import Table from 'components/widgets/Table';
import Permissions from 'utils/enum/Permissions';
import FormTab from 'styles/modules/deals/FormsTab';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import CustomFieldDialog from 'components/modules/deals/create/forms/CustomFieldDialog';
import FormLibraryTabContainer from 'components/containers/deals/read/forms/FormLibraryTabContainer';

// Material UI
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const styles = (theme) => FormTab.formLibraryTab(theme);

class FormLibraryTab extends Component {
    getColumns() {
        const {
            classes, onAddFormToFormPack, onPreview,
        } = this.props;

        const columns = [
            {
                Header: 'Form Name',
                minWidth: 100,
                accessor: 'commonName',
            },
            {
                Header: 'Category',
                minWidth: 30,
                accessor: 'category',
                className: classes.columnStyle,
            },
            {
                Header: 'Type',
                minWidth: 30,
                accessor: 'formPrintingType',
                className: classes.columnStyle,
            },
            {
                Header: 'Preview',
                id: 'preview',
                minWidth: 30,
                className: classes.columnStyle,
                Cell: ({ original }) => (
                    <IconButton size="small" onClick={() => onPreview(original)}>
                        <FindInPageIcon fontSize="small" className={classes.print} />
                    </IconButton>
                ),
            },
        ];

        if (KeyStore.isAllowed(Permissions.SALES_DEAL_FORMS_WRITE)) {
            columns.splice(0, 0, {
                Header: '',
                minWidth: 20,
                accessor: 'id',
                className: classes.columnStyle,
                Cell: ({ value }) => (
                    <IconButton size="small" onClick={() => onAddFormToFormPack(value)}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                ),
            });
        }

        return columns;
    }

    render() {
        const {
            records, openDialogCustomField, customField, getCustomFieldInformation,
            printingFormWithCustomField, setLoadingPrint,
        } = this.props;

        return (
            <>
                <Table
                    className="without-style"
                    resizable={false}
                    sortable={false}
                    cursor="default"
                    data={records}
                    columns={this.getColumns()}
                />
                {openDialogCustomField && (
                    <CustomFieldDialog
                        records={customField}
                        open={openDialogCustomField}
                        loading={printingFormWithCustomField}
                        onClose={setLoadingPrint}
                        onClickPrimaryButton={getCustomFieldInformation}
                    />
                )}
            </>
        );
    }
}

FormLibraryTab.propTypes = {
    records: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onPreview: PropTypes.func.isRequired,
    customField: PropTypes.array.isRequired,
    setLoadingPrint: PropTypes.func.isRequired,
    onAddFormToFormPack: PropTypes.func.isRequired,
    openDialogCustomField: PropTypes.bool.isRequired,
    getCustomFieldInformation: PropTypes.func.isRequired,
    printingFormWithCustomField: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FormLibraryTabContainer(FormLibraryTab));

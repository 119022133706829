/* eslint-disable max-len */
export default class Email {
    static isValidEmail(email) {
        const regex = new RegExp(
            /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi,
        );
        return regex.test(
            String(email)
                .trim(),
        );
    }
}

import GraphQLClient from 'services/apollo/GraphQLClient';
import PortfolioQuery from 'services/graphQL/query/setting/PortfolioQuery';
import PortfolioMutation from 'services/graphQL/mutate/setting/PortfolioMutation';

export default class PortfolioService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Get a list of products with filter
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getSettingList(filter, sort, paginate) {
        const input = {
            paginate, filter, sort,
        };

        return this.graphqlClient
            .query(PortfolioQuery.GET_SETTINGS_PORTFOLIOS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getPortfolioList } = data;

                return {
                    data: { listPortfolios: getPortfolioList },
                };
            });
    }

    /**
     * Get vendor detail
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getPortfolioDetail(input = {}) {
        return this.graphqlClient
            .query(PortfolioQuery.PORTFOLIO_DETAIL, input)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getPortfolio } = data;
                return {
                    data: { portfolio: getPortfolio },
                };
            });
    }

    async getPortfolioCreditCompanies() {
        return this.graphqlClient
            .query(PortfolioQuery.PORTFOLIO_CREDIT_COMPANIES)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getPortfolioCreditCompanies } = data;
                return {
                    data: { portfolioCreditCompanies: getPortfolioCreditCompanies },
                };
            });
    }

    /**
     * @param {Object} input
    */
    async createPortfolio(input) {
        return this.graphqlClient
            .mutate(PortfolioMutation.CREATE_PORTFOLIO, input);
    }

    /**
    * @param {Object} input
    */
    async updatePortfolio(input) {
        return this.graphqlClient
            .mutate(PortfolioMutation.UPDATE_PORTFOLIO, input);
    }

    /**
    * @param {Object} input
    */
    async setDefaultPortfolio(input) {
        return this.graphqlClient
            .mutate(PortfolioMutation.SET_DEFAULT_PORTFOLIO, input);
    }

    /**
    * @param {Object} input
    */
    async deletePortfolio(input) {
        return this.graphqlClient
            .mutate(PortfolioMutation.DELETE_PORTFOLIO, input);
    }
}

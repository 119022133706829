import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    makeStyles,
    Button,
} from '@material-ui/core';
import {
    Edit as EditIcon,
    Cancel as CancelIcon,
    Save as SaveIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '5px',
        '&:hover': {
            background: theme.palette.background.selago,
        },
    },
    edit: {
        color: theme.palette.secondaryInfo.main,
        '&:hover': {
            color: theme.palette.secondaryInfo.dark,
        },
    },
    save: {
        color: theme.palette.success.main,
        '&:hover': {
            color: theme.palette.success.dark,
        },
    },
    cancel: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
    },
}));

const EditButtons = ({
    onSave,
    onCancel,
    onEdit,
    editMode,
    loading,
    saving,
    hasPermission,
}) => {
    const classes = useStyles();

    if (!hasPermission) {
        return null;
    }

    if (editMode) {
        return (
            <div className="d-flex">
                <Button
                    className={clsx(classes.main, classes.save)}
                    onClick={onSave}
                    startIcon={<SaveIcon fontSize="small" />}
                    disabled={saving || loading}
                >
                    Save
                </Button>
                <Button
                    className={clsx(classes.main, classes.cancel)}
                    onClick={onCancel}
                    startIcon={<CancelIcon fontSize="small" />}
                    disabled={saving || loading}
                >
                    Cancel
                </Button>
            </div>
        );
    }

    return (
        <Button
            className={clsx(classes.main, classes.edit)}
            onClick={onEdit}
            startIcon={<EditIcon fontSize="small" />}
            disabled={loading}
        >
            Edit
        </Button>
    );
};

EditButtons.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
    hasPermission: PropTypes.bool,
};

EditButtons.defaultProps = {
    editMode: false,
    loading: false,
    hasPermission: false,
    saving: false,
};

export default EditButtons;

export default {
    SALES_READ: 'sales:read',
    SALES_DEAL_READ: 'sales.deal:read',
    SALES_DEAL_WRITE: 'sales.deal:write',
    SALES_DEAL_FLAG: 'sales.deal:flag',
    SALES_DEAL_FUNDING: 'sales.deal:funding',
    SALES_DEAL_VEHICLE_READ: 'sales.deal.vehicle:read',
    SALES_DEAL_PRODUCTS_READ: 'sales.deal.products:read',
    SALES_DEAL_PRODUCTS_WRITE: 'sales.deal.products:write',
    SALES_DEAL_SET_STAGE: 'sales.deal:set_stage',
    SALES_DEAL_DEACTIVATE: 'sales.deal:deactivate',
    SALES_DEAL_FORMS_WRITE: 'sales.deal.forms:write',
    SALES_DEAL_FORMS_READ: 'sales.deal.forms:read',
    SALES_DEAL_FORMS_SEND_TO_SECURE_CLOSE: 'sales.deal.forms:send_to_secure_close',
    SALES_DEAL_TRANSFER_TO_LOT: 'sales.deal:transfer_to_lot',
    SALES_DEAL_POST_ACCOUNTING: 'sales.deal:post_accounting',
    SALES_DEAL_WE_OWE_READ: 'sales.deal.we_owe:read',
    SALES_DEAL_WE_OWE_WRITE: 'sales.deal.we_owe:write',
    SALES_DEAL_EDIT: 'sales.deal:edit',
    SALES_COMMISSIONS_CHANGE_FINANCE_MANAGER: 'sale.commissions:change_finance_manager',
    SALES_COMMISSIONS_OVERRIDE: 'sale.commissions:override',
    SALES_DEAL_CUSTOMER_READ: 'sales.deal.customer:read',
    SALES_DEAL_CUSTOMER_WRITE: 'sales.deal.customer:write',
    SALES_DEAL_SEND_TO_TAG_AGENCY: 'sales.deal:send_to_tag_agency',
    SALES_DEAL_TRANSFER_TO_RFC: 'sales.deal:transfer_to_rfc',
    SALES_DEAL_VEHICLE_WRITE: 'sales.deal.vehicle:write',
    SALES_DEAL_VEHICLE_MILES_WRITE: 'sales.deal.vehicle.miles:write',
};

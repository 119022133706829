import { gql } from '@apollo/client';

export default class LotMutation {
    static SAVE_LOT_LAT_LONG = gql`
        mutation saveLotLatLong(
            $lotId: Int!,
            $latitude: Float!,
            $longitude: Float!
        ) {
            saveLotLatLong(
                lotId: $lotId,
                latitude: $latitude,
                longitude: $longitude
            )
        }
    `;

    static SAVE_LOT_DEFAULTS = gql`
        mutation saveLotDefaults(
            $category: String!,
            $key: String!,
            $value: String!,
            $lotName: String!,
            $critical: Boolean!
        ) {
            saveLotDefaults(
                category: $category,
                key: $key,
                value: $value,
                lotName: $lotName,
                critical: $critical
            )
        }
    `;

    static UPDATE_LOT_SETTINGS = gql`
        mutation updateLotSettings(
            $lotId: Int!,
            $lot: LotSettingsUpdate!
        ) {
            updateLotSettings(
                lotId: $lotId,
                lot: $lot
            )
        }
    `;

    static UPDATE_LOT_PICTURE = gql`
        mutation updateLotPicture(
            $lotId: Int!,
            $file: Upload!,
            $lotName: String!,
        ) {
            updateLotPicture(
                lotId: $lotId,
                file: $file,
                lotName: $lotName
            )
        }
    `;
}

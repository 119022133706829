export const ACTION_TYPES = {
    SET_GROUP_TITLE: 'setGroupTitle',
    OPEN_MESSAGE_FORM: 'openMessageForm',
    CLOSE_MESSAGE_FORM: 'closeMessageForm',
    SET_CONTENT_TYPE: 'setContentType',
    TOGGLE_IS_PINNED: 'toggleIsPinned',
    SET_CONTENT: 'setContent',
    SAVE_MESSAGE: 'addMessage',
    TOGGLE_IS_CONFIRM_DIALOG_OPEN: 'toggleIsConfirmDialogOpen',
    REMOVE_MESSAGE: 'removeMessage',
    EDIT_MESSAGE: 'editMessage',
    TOGGLE_IS_PDF_PREVIEW_OPEN: 'toggleIsPDFPreviewOpen',
    SET_LOADED_PDFS: 'setLoadedPDFS',
    SET_PDF_UPLOADING: 'setPDFUploading',
    TOGGLE_IS_MESSAGE_BEING_SAVED: 'toggleIsMessageBeingSaved',
};

const MesssagesReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_GROUP_TITLE: {
        return { ...state, groupTitle: action.value };
    }
    case ACTION_TYPES.OPEN_MESSAGE_FORM: {
        return { ...state, isMessageFormOpen: true };
    }
    case ACTION_TYPES.CLOSE_MESSAGE_FORM: {
        return {
            ...state,
            selectedMessageId: null,
            isMessageFormOpen: false,
            selectedContentType: null,
            isMessagePinned: false,
        };
    }
    case ACTION_TYPES.SET_CONTENT_TYPE: {
        return { ...state, selectedContentType: action.value, content: null };
    }
    case ACTION_TYPES.TOGGLE_IS_PINNED: {
        return { ...state, isMessagePinned: !state.isMessagePinned };
    }
    case ACTION_TYPES.SET_CONTENT: {
        return { ...state, content: action.value, isFileBeingUploaded: false };
    }
    case ACTION_TYPES.SAVE_MESSAGE: {
        const { pinnedElements, nonPinnedElements } = state;
        const message = action.value;
        const { id, pdf } = message;

        message.createdOn = new Date().toISOString();
        message.modifiedOn = new Date().toISOString();
        delete message.pdf;

        const otherProps = {
            selectedMessageId: null,
            isMessageFormOpen: false,
            selectedContentType: null,
            isMessagePinned: false,
            content: null,
        };

        const isPinnedElement = pinnedElements.some((element) => element.id === id);
        const isNonPinnedElement = nonPinnedElements.some((element) => element.id === id);
        if (isPinnedElement || isNonPinnedElement) {
            let allElements = [...pinnedElements, ...nonPinnedElements];
            allElements = allElements.map((element) => (element.id === id ? message : element));

            let sources = state.loadedPDFs;
            if (pdf !== null) {
                sources = sources.map((source) => (source.id === id ? { id, pdf } : source));
            }

            return {
                ...state,
                isMessageBeingSaved: false,
                pinnedElements: allElements.filter((element) => element.isPinned),
                nonPinnedElements: allElements.filter((element) => !element.isPinned),
                loadedPDFs: sources,
                ...otherProps,
            };
        }

        return {
            ...state,
            isMessageBeingSaved: false,
            pinnedElements: message.isPinned ? [message, ...pinnedElements] : pinnedElements,
            nonPinnedElements: message.isPinned ? nonPinnedElements : [message, ...nonPinnedElements],
            loadedPDFs: pdf !== null
                ? [...state.loadedPDFs, { id, pdf }] : state.loadedPDFs,
            ...otherProps,
        };
    }
    case ACTION_TYPES.TOGGLE_IS_CONFIRM_DIALOG_OPEN: {
        const messageId = action.value;
        const { confirmDialog } = state;

        return { ...state, confirmDialog: { isOpen: !confirmDialog.isOpen, options: { messageId } } };
    }
    case ACTION_TYPES.REMOVE_MESSAGE: {
        const { confirmDialog, confirmDialog: { options: { messageId } } } = state;

        return {
            ...state,
            pinnedElements: state.pinnedElements.filter((element) => element.id !== messageId),
            nonPinnedElements: state.nonPinnedElements.filter((element) => element.id !== messageId),
            confirmDialog: { isOpen: !confirmDialog.isOpen, options: {} },
        };
    }
    case ACTION_TYPES.EDIT_MESSAGE: {
        const { element, contentType } = action.value;

        return {
            ...state,
            isMessageFormOpen: true,
            selectedMessageId: element.id,
            selectedContentType: contentType,
            isMessagePinned: element.isPinned,
            content: element.value,
        };
    }
    case ACTION_TYPES.TOGGLE_IS_PDF_PREVIEW_OPEN: {
        return { ...state, isPDFPreviewDialogOpen: !state.isPDFPreviewDialogOpen, PDFPreviewContent: action.value };
    }
    case ACTION_TYPES.SET_LOADED_PDFS: {
        return { ...state, loadedPDFs: action.value };
    }
    case ACTION_TYPES.SET_PDF_UPLOADING: {
        return { ...state, isFileBeingUploaded: true };
    }
    case ACTION_TYPES.TOGGLE_IS_MESSAGE_BEING_SAVED: {
        return { ...state, isMessageBeingSaved: !state.isMessageBeingSaved };
    }
    default:
        return state;
    }
};

export default MesssagesReducer;

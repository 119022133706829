import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PhoneUtils from 'lib/PhoneUtils';
import StringUtils from 'lib/StringUtils';
import {
    size, clone, filter, isEqual, isNumber,
} from 'lodash';
import CustomerMap from 'services/mapData/CustomerMap';
import CardVehicle from 'components/widgets/CardVehicle';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VehicleForm from 'components/modules/customer/create/VehicleForm';
import ModalUtils from 'utils/ModalUtils';

// Material UI
import {
    Typography, Button, IconButton, Paper, Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, fade } from '@material-ui/core/styles';

// React Bootstrap
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

// HTTP
import GraphqlClient from 'services/apollo/GraphQLClient';
import LeadMutate from 'services/graphQL/mutate/LeadMutate';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';

const styles = (theme) => CustomerStyles.customerInformation(theme, fade);

class CustomerInformation extends Component {
    constructor(props) {
        super(props);

        this.graphqlClient = new GraphqlClient();
        this.state = {
            vehicleWishes: [],
            open: false,
            isEditing: false,
            vehicleSelected: {},
            customer: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
            },
            leadId: '',
            enabledLead: false,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { conversationId } = nextProps;

        this.getServicesData(conversationId);
    }

    onChangeValueCustomer(value = '', field = '') {
        const { state: { customer } } = this;
        const backUpCustomer = clone(customer);
        backUpCustomer[field] = value;

        this.setState({
            customer: backUpCustomer,
        });
    }

    getVehicleWishes(record) {
        const { props: { conversationId } } = this;
        const currentConversationId = record || conversationId;
        const input = {
            conversationId: currentConversationId,
        };

        this.graphqlClient
            .query(CustomerQuery.GET_VEHICLE_WISH_BY_CONVERSATION_ID, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVehicleWishes) {
                    this.setState({
                        vehicleWishes: data.getVehicleWishes,
                        open: false,
                    });
                }
            });
    }

    getServicesData(record) {
        const { props: { conversationId }, state: { customer } } = this;
        const currentConversationId = record || conversationId;
        const input = {
            conversationId: currentConversationId,
        };

        this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMER_INFORMATION, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerInformation) {
                    const { getCustomerInformation } = data;
                    const currentCustomer = CustomerMap.customerInformation(getCustomerInformation);

                    this.backUpCustomer = currentCustomer;
                    this.setState({
                        customer: Object.assign(customer, {
                            ...currentCustomer,
                        }),
                        leadId: getCustomerInformation.leadId || '',
                    }, () => {
                        this.enabledSubmitLead();
                    });
                }
            });
    }

    setFavoriteVehicle(vehicleWishId, isFavorite) {
        const { props: { conversationId } } = this;
        const input = {
            conversationId,
            vehicleWishId,
            isFavorite,
        };

        this.graphqlClient
            .mutate(CustomerMutate.SET_FAVORITE_VEHICLE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.setFavoriteVehicle) {
                    this.getServicesData();
                }
            });
    }

    setEnabledLead(value = false) {
        this.setState({
            enabledLead: value,
        });
    }

    enabledSubmitLead() {
        const {
            state: {
                customer: {
                    phone, firstName, lastName, email,
                },
            },
        } = this;

        if ((PhoneUtils.isValid(phone) || StringUtils.isValidEmail(email)) && (!StringUtils.isEmpty(firstName) || !StringUtils.isEmpty(lastName))) {
            if (!StringUtils.isEmpty(phone) && !PhoneUtils.isValid(phone)) {
                this.setEnabledLead();

                return;
            }

            if (!StringUtils.isEmpty(email) && !StringUtils.isValidEmail(email)) {
                this.setEnabledLead();

                return;
            }

            this.setEnabledLead(true);
        } else {
            this.setEnabledLead();
        }
    }

    removeVehicleWish(vehicleWishId) {
        const { state: { vehicleWishes } } = this;
        const backUpVehicles = clone(vehicleWishes);
        const currentVehicles = filter(backUpVehicles, (item) => item.vehicleWishId !== vehicleWishId);

        this.setState({
            vehicleWishes: currentVehicles,
        });
    }

    deleteVehicleWish(record) {
        const { vehicleWishId } = record;

        this.graphqlClient
            .mutate(CustomerMutate.REMOVE_VEHICLE_WISH, { vehicleWishId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                } else {
                    const { removeVehicleWish } = data;

                    if (removeVehicleWish) {
                        this.removeVehicleWish(vehicleWishId);
                    }
                }
            });
    }

    initBind() {
        this.submitLead = this.submitLead.bind(this);
        this.toggleVehicle = this.toggleVehicle.bind(this);
        this.setEnabledLead = this.setEnabledLead.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.getVehicleWishes = this.getVehicleWishes.bind(this);
        this.setFavoriteVehicle = this.setFavoriteVehicle.bind(this);
        this.onChangeValueCustomer = this.onChangeValueCustomer.bind(this);
        this.saveCustomerInformation = this.saveCustomerInformation.bind(this);
    }

    toggleVehicle(isEditing = false, record = {}) {
        this.setState((prevState) => ({
            open: !prevState.open,
            isEditing,
            vehicleSelected: record,
        }));
    }

    editVehicle(record) {
        this.toggleVehicle(true, record);
    }

    submitLead() {
        const { props: { conversationId } } = this;

        this.graphqlClient
            .mutate(LeadMutate.SAVE_LEAD, { conversationId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.saveLead) {
                    this.getServicesData();
                }
            });
    }

    saveCustomerInformation() {
        const {
            state: {
                customer: {
                    firstName, lastName, email, phone,
                },
            }, props: { conversationId },
        } = this;
        const input = {
            conversationId,
            input: {
                firstName,
                lastName,
                email,
                // TODO: do this with regex - phone number should be of 12 characters
                phone: phone.replace('(', '').replace(')', '').replace(' ', '-'),
            },
        };

        this.graphqlClient
            .mutate(CustomerMutate.SAVE_CUSTOMER_INFORMATION, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.saveCustomerInformation) {
                    this.getServicesData();
                }
            });
    }

    renderHoverPictureCoponent(record) {
        const { props: { classes } } = this;

        return (
            <Paper elevation={0} className={clsx(classes.paper, 'picture-hover')}>
                <Button
                    variant="contained"
                    className={clsx(classes.containedWhite, classes.buttonIcon)}
                    onClick={() => this.editVehicle(record)}
                >
                    <EditIcon />
                </Button>
                <Button
                    variant="contained"
                    className={clsx(classes.containedWhite, classes.buttonIcon)}
                    onClick={() => this.deleteVehicleWish(record)}
                >
                    <DeleteOutlineIcon />
                </Button>
            </Paper>
        );
    }

    renderlistVehicle() {
        const { state: { vehicleWishes } } = this;

        if (size(vehicleWishes)) {
            return (vehicleWishes.map((item) => (
                <>
                    <CardVehicle
                        showRating
                        key={item.vehicleWishId}
                        vehicle={item}
                        className="vehicle-item"
                        setFavoriteVehicle={this.setFavoriteVehicle}
                        hoverPictureComponent={this.renderHoverPictureCoponent(item)}
                    />
                    <Divider />
                </>
            )));
        }

        return null;
    }

    render() {
        const {
            props: { classes, onClose, conversationId }, state: {
                open, isEditing, vehicleSelected, customer: {
                    phone, firstName, lastName, email,
                },
                leadId,
                enabledLead,
                customer,
            },
        } = this;
        const currentTitle = open ? 'Vehicle' : 'Vehicles';
        const classAddVehicle = open ? classes.contentAddVehicle : '';
        const disableSaveChanges = isEqual(this.backUpCustomer, customer);
        const disableByLeadId = isNumber(leadId);
        // TODO: Remove this when functionality is added
        const hidden = true;

        return (
            <div className={classes.root}>
                <div
                    className={classes.headerDrawer}
                >
                    <Button
                        className={classes.buttonClose}
                        variant="outlined"
                        color="default"
                        size="small"
                        onClick={() => onClose(false)}
                    >
                        <CloseIcon className={classes.startIcon} />
                    </Button>
                </div>
                <Typography
                    variant="h5"
                    className={classes.text}
                >
                    Customer Information
                </Typography>
                <Form>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            onChange={(e) => this.onChangeValueCustomer(e.target.value, 'firstName')}
                            value={firstName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => this.onChangeValueCustomer(e.target.value, 'lastName')}
                            value={lastName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <NumberFormat
                            placeholder="Phone Number"
                            className="form-control"
                            format="(###) ###-####"
                            mask="_"
                            value={phone}
                            onChange={(e) => this.onChangeValueCustomer(e.target.value, 'phone')}
                        />
                    </Form.Group>
                    <Form.Group className={classes.lastFormGroup}>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            onChange={(e) => this.onChangeValueCustomer(e.target.value, 'email')}
                            value={email}
                        />
                    </Form.Group>
                </Form>
                {!hidden && (
                    <>
                        <div
                            className={classes.boxAddVehicle}
                        >
                            <Typography
                                variant="h5"
                                className={classes.textVehicle}
                            >
                                {currentTitle}
                            </Typography>

                            {!open && (
                                <IconButton
                                    color="primary"
                                    aria-label="Add vehicle"
                                    component="span"
                                    onClick={() => this.toggleVehicle(false)}
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                        </div>
                        <div className={clsx(classes.contentVehicle, classAddVehicle)}>
                            {open && (
                                <VehicleForm
                                    toggleVehicle={this.toggleVehicle}
                                    getServicesData={this.getServicesData}
                                    conversationId={conversationId}
                                    isEditing={isEditing}
                                    record={vehicleSelected}
                                />
                            )}
                            {!open && this.renderlistVehicle()}
                        </div>
                    </>
                )}
                {!open && (
                    <div>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={disableByLeadId || disableSaveChanges}
                            className={clsx(classes.button, classes.containedInfo)}
                            onClick={this.saveCustomerInformation}
                        >
                            Save Changes
                        </Button>
                        {!hidden && (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={disableByLeadId || !enabledLead}
                                className={classes.button}
                                onClick={this.submitLead}
                            >
                                Submit Lead
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

CustomerInformation.propTypes = {
    onClose: PropTypes.func.isRequired,
    conversationId: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(CustomerInformation);

import React from 'react';
import PropTypes from 'prop-types';
import { replace } from 'lodash';
import {
    makeStyles,
    FormLabel,
    Checkbox,
    Grid,
} from '@material-ui/core';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));

const AdvertisingSetupPanel = ({ data }) => {
    const classes = useStyles();

    const reducePrice = data.enableReduce
        ? replace(data.reducePrice, '$', '')
        : 0;
    const removeAd = StringUtils.isEmpty(data.removeAd) ? 'Not setup' : `${data.removeAd} after sale`;
    const isValidPrice = StringUtils.isEmpty(reducePrice) || reducePrice === 0;
    const reducedPrice = isValidPrice ? 'Not setup' : reducePrice;
    const reduceFrequency = isValidPrice ? null : data.reduceFrequency;

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            <Grid item xs={12} sm={6}>
                <FormLabel className={classes.contentValue}>
                    Place online ads for this vehicle
                    <Checkbox
                        checked={data.placeAd || false}
                        disabled
                    />
                </FormLabel>
            </Grid>
            <DisplayField
                field="Downpayment"
                value={data.websiteDownpayment}
                type="money"
            />
            <DisplayField
                field="Reduced Price"
                value={reducedPrice}
                alternativeValue={reduceFrequency}
            />
            <DisplayField
                field="Payment"
                value={data.websitePayment}
                type="money"
            />
            <DisplayField
                field="Remove ad"
                value={removeAd}
            />
            <DisplayField
                field="Frequency"
                value={data.paymentFrequency}
            />
        </Grid>
    );
};

AdvertisingSetupPanel.propTypes = {
    data: PropTypes.object,
};

AdvertisingSetupPanel.defaultProps = {
    data: {},
};

export default AdvertisingSetupPanel;

import { gql } from '@apollo/client';

export default class TecnicianCommissionsQuery {
    static GET_TECHNICIAN_COMMISSION_LIST = gql`
        query getTechnicianCommissionList($employeeId: Int!) {
            getTechnicianCommissionList(employeeId: $employeeId) {
                hours
                amount
                status
                vehicle
                jobNumber
                isApproved
                customerName
                invoiceNumber
                isFlaggedHours
            }
        }
    `;
}

import { gql } from '@apollo/client';

export default class EmailTemplateMutation {
    static UPDATE_CRM_TEMPLATE = gql`
        mutation updateCRMTemplate($input: MessageTemplateInput){
            updateCRMTemplate(input: $input)
        }
    `;

    static CREATE_CRM_TEMPLATE = gql`
        mutation createCRMTemplate($input: NewMessageTemplateInput) {
            createCRMTemplate(input: $input)
        }
    `;
}

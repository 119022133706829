import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { modules } from 'utils/enum/modules';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import { makeStyles } from '@material-ui/core/styles';
import usePrevious from 'components/hook/core/usePrevious';
import EmailTemplateQuery from 'services/graphQL/query/setting/EmailTemplateQuery';

const ACTION_TYPE = {
    SET_SMS_LIST: 'setSMSList',
    CHANGE_TEMPLATE: 'onChangeTemplate',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_SMS_LIST:
        const templateList = (action.payload || [])
            .filter((item) => item.language === action.language)
            .map((item) => ({ label: item.templateName, value: item.messageTemplateId, subject: item.templateSubject }));

        return update(state, {
            templateList: { $set: templateList },
        });
    case ACTION_TYPE.CHANGE_TEMPLATE:
        return update(state, {
            templateSelected: { $set: action.value },
        });
    default:
        return state;
    }
};
const useStyle = makeStyles(() => ({
    select: {
        width: '220px',
    },
}));

const SelectMessageTemplate = ({
    disabled, onChange, value: templateValue, prospectLanguage,
}) => {
    const classes = useStyle();
    const previousSelectedValues = usePrevious(templateValue);
    const [state, dispatch] = useReducer(reducer, {
        templateList: [],
        templateSelected: null,
    });
    const {
        loading: loadingTemplateList,
        data: templateList,
        error: errorTemplateList,
    } = useQuery(EmailTemplateQuery.GET_SMS_TEMPLATE_LIST, {
        variables: {
            moduleTemplate: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onChangeTemplate = (value) => {
        onChange(value);
        dispatch({
            type: ACTION_TYPE.CHANGE_TEMPLATE,
            value,
        });
    };

    useEffect(() => {
        if (previousSelectedValues !== templateValue) {
            dispatch({
                type: ACTION_TYPE.CHANGE_TEMPLATE,
                value: templateValue,
            });
        }
    }, [templateValue, previousSelectedValues]);

    useEffect(() => {
        if (errorTemplateList) {
            ModalUtils.errorMessage(errorTemplateList?.graphQLErrors);
            return;
        }

        if (!loadingTemplateList && templateList?.getSMSTemplateList) {
            dispatch({
                type: ACTION_TYPE.SET_SMS_LIST,
                payload: templateList.getSMSTemplateList,
                language: prospectLanguage,
            });
        }
    }, [templateList, errorTemplateList, loadingTemplateList, prospectLanguage]);

    return (
        <Select
            size="sm"
            name="lotName"
            placeholder="Select"
            className={classes.select}
            options={state.templateList}
            value={state.templateSelected}
            disabled={disabled || loadingTemplateList}
            onChange={(_, value) => onChangeTemplate(value)}
        />
    );
};

SelectMessageTemplate.propTypes = {
    value: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    prospectLanguage: PropTypes.string,
};

SelectMessageTemplate.defaultProps = {
    disabled: false,
    value: null,
    prospectLanguage: 'English',
};

export default SelectMessageTemplate;

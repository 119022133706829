import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Button,
} from '@material-ui/core';
import StringUtils from 'lib/StringUtils';
import TextField from '@material-ui/core/TextField';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import ButtonStyles from 'styles/theme/Button';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    dialogContent: {
        padding: '10px 0',
    },
}));

const PurchasingDialog = ({
    isPurchasingDialogOpen,
    title,
    field,
    current,
    onCreate,
    onEdit,
    onClose,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [name, setName] = useState('');

    useEffect(() => {
        if (current?.agent) {
            setName(current.agent);
        }
    }, [current]);

    const saveName = () => {
        const currentAgentId = current?.purchasingAgentsId;
        if (currentAgentId) onEdit(currentAgentId, name);
        if (!currentAgentId) onCreate(field, name);

        setName('');
        onClose();
    };

    return (
        <Dialog
            open={isPurchasingDialogOpen}
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title={title}
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.dialogContent}>
                    <TextField
                        autoFocus
                        label="Name"
                        variant="outlined"
                        value={name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setName(e.target.value)}
                        margin="dense"
                        fullWidth
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.containedSecondaryInfo}
                    disabled={StringUtils.isEmpty(name)}
                    onClick={saveName}
                >
                    Ok
                </Button>
                <Button
                    className={classes.containedError}
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PurchasingDialog.defaultProps = {
    current: null,
};

PurchasingDialog.propTypes = {
    isPurchasingDialogOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    current: PropTypes.object,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PurchasingDialog;

import React, {
    useEffect, useReducer, forwardRef, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import { useLazyQuery, useMutation } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import VirtualTable from 'components/widgets/VirtualTable';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import { ListAltSharpIcon, LowPriorityIcon } from 'components/icons';
import Summary from 'components/widgets/accounting/Summary';
import Header from 'components/widgets/Header';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import DateUtils from 'lib/DateUtils';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import AccountingGLMap from 'services/mapData/AccountingGLMap';
import CommonJournalReducer, { ACTION_TYPES, InitialState } from 'components/modules/accounting/journal/reducer/CommonJournalReducer';

const basicStyles = makeStyles((theme) => AccountingStyles.basicStyles(theme));
const columnStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));

const CommonJournalDetailDistributionList = React.memo(forwardRef((props, ref) => {
    const {
        writePermissions, recordId, glOptions, journalType,
        showPosted, minPostDate, initialPostedDate, onCompleted,
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...basicStyles(),
        ...columnStyles(),
        ...containerStyles(),
    };

    const mountedRef = useRef(true);
    const [state, dispatch] = useReducer(CommonJournalReducer, InitialState);
    const {
        totalDebit, totalCredit, lockedDate, postToDate, records,
    } = state;

    const [loadData, { loading, error }] = useLazyQuery(AccountingGLQuery.GET_JOURNAL_COMMON_DETAIL_LIST, {
        onCompleted: (res) => {
            const { getCommonJournalDetailList: { data } } = res;

            const dataMapped = AccountingGLMap.commonGlInformation(data);

            dispatch({ type: ACTION_TYPES.SET_FETCHED_RECORDS, value: dataMapped });
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [postJournal, { loading: isPosting }] = useMutation(AccountingGLQuery.CREATE_JOURNAL_PROCESS, {
        onCompleted: (mutationData) => {
            const { success, isLockedDate, lockedDate: currentLockDate } = mutationData.postJournalProcess;

            if (success) {
                ModalUtils.successMessage(null, 'Successfully journal posted!');
                if (onCompleted) onCompleted();
            }

            if (isLockedDate) {
                ModalUtils.errorMessage(null, 'The date falls in a date range that is closed, choose another date!');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        lockedDate: currentLockDate,
                    },
                });
                return;
            }

            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                value: {
                    ...InitialState,
                    lockedDate,
                },
            });
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
    });

    const [reversalJournal, { loading: isReverting }] = useMutation(AccountingGLQuery.CREATE_REVERSAL_JOURNAL_PROCESS, {
        onCompleted: (mutationData) => {
            const { success, isLockedDate, lockedDate: currentLockDate } = mutationData.revertJournalProcess;

            if (success) {
                ModalUtils.successMessage(null, 'Journal reverted successfully!');
                if (onCompleted) onCompleted();
            }

            if (isLockedDate) {
                ModalUtils.errorMessage(null, 'The date falls in a date range that is closed, choose another date!');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        lockedDate: currentLockDate,
                    },
                });
                return;
            }

            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                value: {
                    ...InitialState,
                    lockedDate,
                },
            });
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const getLineErrors = (items) => {
        const errors = [];

        items.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.accountNumber <= 0) message += ' Account Number is required,';
            if (item.debit + item.credit === 0) message += ' Debit or Credit is required and must be different than zero';
            if (isEmpty(item.lotName.trim())) message += ' Lot name is required,';
            if (item.isControlled && !isEmpty(item.controlledBy) && item.controlNumber.trim() === '') {
                message += ` The account ${item.accountDescription} must have a ${item.controlledBy} as Control number,`;
            }

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        return errors;
    };

    const onPostJournal = async () => {
        const recordToSave = {
            recordId,
            journalType,
            postDate: postToDate,
        };

        const errors = getLineErrors(records);

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'The GL lines are empty');
            return;
        }

        if (errors.length > 0) {
            ModalUtils.errorMessage(errors);
            return;
        }

        if (NumberUtils.round(totalDebit) !== NumberUtils.round(totalCredit)) {
            ModalUtils.errorMessage([{ message: 'The journal is unbalance, the debit should be the same as credit.' }]);
            return;
        }

        recordToSave.accountingLines = records.map((item) => (
            {
                accountNumber: Number(item.accountNumber),
                debit: Number(item.debit),
                credit: Number(item.credit),
                controlNumber: String(item.controlNumber) || '',
                lotId: item.lotId,
                lotName: item.lotName,
                memo: item.memo,
            }));

        postJournal({
            variables: {
                record: recordToSave,
            },
        });
    };

    const onReversal = () => {
        reversalJournal({
            variables: {
                processRecordId: recordId,
                journalType,
                postDate: postToDate,
            },
        });
    };

    useImperativeHandle(ref, () => ({
        clear() {
            dispatch({ type: ACTION_TYPES.CLEAR_LINES });
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [records]);

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        if (cell.value === newValue) return;

        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;

        if (event && id !== 'memo' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'memo' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const getColumns = () => {
        const columns = [
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'line',
                label: 'Line',
                width: 50,
                cellRenderer: (cell) => cell.rowIndex + 1,
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'accountNumber',
                label: 'Account',
                width: 250,
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            accountNumber, accountDescription, entryId, accountType,
                        }, dataKey,
                    } = cell;
                    const accountTypesExcluded = [AccountingCOAType.BANK];
                    const isExcludedAccount = accountTypesExcluded.includes(accountType) || false;
                    if (writePermissions && glOptions.editglAccount && !isExcludedAccount) {
                        return (
                            <SelectControl
                                name={dataKey}
                                value={accountNumber}
                                editorCellObject={cell}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                className={accountNumber > 0 ? '' : 'invalid-field'}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypesExcluded,
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span>{`${accountNumber} - ${accountDescription}`}</span>;
                },
            },
            {
                headerClassName: classes.columnRight,
                className: classes.columnRight,
                dataKey: 'debit',
                label: 'Debit',
                width: 120,
                cellRenderer: (cell) => {
                    const { rowData: { debit } } = cell;
                    return <span>{NumberUtils.applyCurrencyFormat(debit || 0)}</span>;
                },
            },
            {
                headerClassName: classes.columnRight,
                className: classes.columnRight,
                dataKey: 'credit',
                label: 'Credit',
                width: 120,
                cellRenderer: (cell) => {
                    const { rowData: { credit } } = cell;
                    return <span>{NumberUtils.applyCurrencyFormat(credit || 0)}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'controlNumber',
                label: 'Control #',
                width: 120,
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            isControlled, controlledBy, controlNumber, entryId,
                        }, dataKey,
                    } = cell;
                    const controlledByValue = isControlled ? controlledBy ?? '' : '';
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglControlNumber) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={controlNumber}
                                editorCellObject={cellObject}
                                className={controlledByValue === '' || (controlledByValue !== '' && controlNumber !== '') ? '' : 'invalid-field'}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span>{controlNumber}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'referenceNumber',
                label: 'Reference #',
                width: 120,
                cellRenderer: (cell) => {
                    const { rowData: { referenceNumber } } = cell;
                    return <span>{referenceNumber}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'lotName',
                label: 'Lot Name',
                width: 200,
                cellRenderer: (cell) => {
                    const { rowData: { lotName, entryId }, dataKey } = cell;
                    if (writePermissions && glOptions.editglLot) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                value={lotName}
                                className={isEmpty(lotName) ? 'invalid-field' : ''}
                                placeHolder="select a lot"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotName', descriptionField: 'lotName',
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span>{lotName}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'memo',
                label: 'Memo',
                width: 500,
                cellRenderer: (cell) => {
                    const { rowData: { memo, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglMemo) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={memo}
                                editorCellObject={cellObject}
                                type="textarea"
                                rows={1}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span>{memo}</span>;
                },
            },
        ];

        return columns;
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!mountedRef) return;

        if (recordId > 0) {
            loadData({
                variables: {
                    recordId,
                    type: journalType,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId, error]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                lockedDate: minPostDate,
            },
        });
    }, [minPostDate]);

    useEffect(() => {
        if (initialPostedDate) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    postToDate: DateUtils.getDateOverrideToLocalTime(initialPostedDate),
                },
            });
        }
    }, [initialPostedDate]);

    const haveLines = records.length > 0;

    return (
        <div className={classes.flexContainer}>
            <Header>
                <div className={classes.buttonSpacing}>
                    <DatePicker
                        popperContainer={CalendarContainer}
                        className={clsx('form-control form-control-sm', classes.date, isMobile ? classes.dateInRange : '')}
                        selected={postToDate}
                        minDate={new Date(DateUtils.add(DateUtils.getOnlyDate(lockedDate), 1))}
                        disabled={loading || !haveLines || isPosting || isReverting}
                        onChange={(value) => dispatch({
                            type: ACTION_TYPES.SET_STATE_VALUES,
                            value: {
                                postToDate: value,
                            },
                        })}
                        placeholderText="mm/dd/yyyy"
                    />
                    {showPosted
                && (
                    <Button
                        onClick={onReversal}
                        variant="outlined"
                        startIcon={<LowPriorityIcon />}
                        disabled={loading || !haveLines}
                        size="small"
                    >
                        Reversal from Accounting
                    </Button>
                ) }
                    {!showPosted
                && (
                    <Button
                        onClick={onPostJournal}
                        variant="outlined"
                        startIcon={<ListAltSharpIcon />}
                        disabled={loading || !haveLines || isPosting || isReverting}
                        size="small"
                    >
                        Post to Accounting
                    </Button>
                ) }
                </div>
                <>
                    <Summary displayInline debit={totalDebit} credit={totalCredit} />
                </>
            </Header>
            <div className={classes.bottomTableHeight}>
                <VirtualTable
                    loading={loading}
                    data={records}
                    columns={getColumns()}
                />
                {isPosting && <DialogActionMessage message="Posting Journal... " />}
                {isReverting && <DialogActionMessage message="Reverting Journal... " />}
            </div>
        </div>
    );
}), (prev, next) => prev.journalId !== next.journalId
&& prev.defaultMemo !== next.defaultMemo);

CommonJournalDetailDistributionList.propTypes = {
    recordId: PropTypes.number,
    journalType: PropTypes.string,
    writePermissions: PropTypes.bool.isRequired,
    glOptions: PropTypes.object,
    showPosted: PropTypes.bool.isRequired,
    minPostDate: PropTypes.instanceOf(Date),
    initialPostedDate: PropTypes.string,
    onCompleted: PropTypes.func,
};

CommonJournalDetailDistributionList.defaultProps = {
    recordId: 0,
    journalType: '',
    glOptions: {
        editglAccount: true,
        editglAmount: true,
        editglControlNumber: true,
        editglLot: true,
        editglMemo: true,
        showNewAction: true,
        showSplitAction: true,
        showDeleteAction: true,
    },
    minPostDate: new Date(),
    initialPostedDate: null,
    onCompleted: null,
};

export default CommonJournalDetailDistributionList;

export default class MarketCompsTabStyle {
    static getStyle(theme, isAppraisals) {
        return ({
            main: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                position: 'relative',
                minWidth: isAppraisals ? 'initial' : '900px',
                [theme.breakpoints.down('sm')]: {
                    minWidth: 'initial',
                    height: 'initial',
                    overflow: 'hidden',
                },
            },
            bottomRow: {
                display: 'flex',
                width: '97%',
                minWidth: isAppraisals ? '900px' : 'initial',
                marginTop: '15px',
                marginLeft: '15px',
                marginRight: '15px',
                position: 'relative',
                border: `1px solid ${theme.palette.border.mercury}`,
                padding: '5px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    minWidth: 'initial',
                    marginLeft: isAppraisals ? '0px' : '10px',
                },
            },
            chartContainer: {
                flexGrow: 1,
                height: '650px',
                [theme.breakpoints.down('sm')]: {
                    height: '600px',
                },
            },
            filtersContainer: {
                width: '250px',
                paddingTop: '28px',
                paddingBottom: '10px',
                paddingRight: '15px',
                position: 'relative',
                backgroundColor: theme.palette.background.white,
                '& > div:nth-child(2)': {
                    display: 'flex',
                },
                '& .form-group': {
                    paddingRight: '0px',
                    marginBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    '& > .MuiInputBase-root': {
                        height: '35px',
                        fontSize: '13px',
                    },
                },
                '& > button': {
                    width: '94%',
                    marginLeft: '15px',
                    marginTop: '5px',
                    [theme.breakpoints.down('sm')]: {
                        width: '96%',
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            filtersTitle: {
                textAlign: 'center',
                padding: '3px',
                color: 'white',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                fontSize: '13px',
                backgroundColor: theme.palette.secondaryInfo.dark,
            },
            '@global': {
                '[id^="menu-"] > div:nth-child(3) > ul > li .MuiListItemText-primary': {
                    fontSize: '13px',
                },
            },
            topRow: {
                display: 'flex',
                flexDirection: 'row',
                width: '97%',
                minWidth: isAppraisals ? '900px' : 'initial',
                marginTop: isAppraisals ? 0 : '10px',
                marginLeft: '15px',
                marginRight: '15px',
                border: `1px solid ${theme.palette.border.mercury}`,
                padding: '5px',
                borderRadius: '2px',
                height: '8.5%',
                minHeight: '70px',
                [theme.breakpoints.down('sm')]: {
                    height: 'auto',
                    minWidth: 'initial',
                    marginLeft: isAppraisals ? '0px' : '10px',
                    flexDirection: 'column',
                },
            },
            leftSection: {
                display: 'flex',
                width: '60%',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                },
                '& > button': {
                    marginTop: '12px',
                    height: '33px',
                    marginLeft: '10px',
                    [theme.breakpoints.down('sm')]: {
                        width: '130px',
                    },
                },
                '& > div': {
                    padding: '0px',
                    flexGrow: 'initial',
                    marginLeft: '10px',
                    marginBottom: '10px',
                    '& > label': {
                        marginBottom: '5px',
                    },
                    '& > input': {
                        width: '130px',
                    },
                },
            },
            rightSection: {
                display: 'flex',
                width: '40%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    display: !isAppraisals ? 'none' : 'flex',
                    justifyContent: 'center',
                    width: '100%',
                },
                '& > div': {
                    padding: '0px',
                    flexGrow: 'initial',
                    marginRight: '10px',
                    marginBottom: '10px',
                    '& > label': {
                        marginBottom: '5px',
                    },
                    '& > input': {
                        width: '130px',
                    },
                },
            },
            subLabel: {
                fontSize: '12px',
            },
            input: {
                fontSize: '14px',
                resize: 'none',
            },
            market: {
                display: 'flex',
                width: '100%',
                height: '32px',
                paddingLeft: '5px',
                fontSize: '14px',
            },
            loader: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.background.white,
                zIndex: 700,
            },
            markupPositive: {
                color: '#005c00',
                fontSize: 13,
                fontWeight: 500,
            },
            markupNegative: {
                color: '#990000',
                fontSize: 13,
            },
            counter: {
                width: '100%',
                display: 'flex',
                fontSize: '12px',
                fontWeight: 500,
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '15px',
                '& span': {
                    marginRight: '5px',
                },
                '& > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(3), & > div:nth-child(4)': {
                    marginRight: '5px',
                },
            },
            vinStyle: {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '& > span:nth-child(3)': {
                    width: '23px',
                    height: '15px',
                },
                '& svg': {
                    fontSize: '15px',
                    marginLeft: '5px',
                    cursor: 'pointer',
                    fill: theme.palette.secondaryInfo.dark,
                },
            },
            carfax: {
                marginTop: '12px',
                '& img': {
                    width: '150px',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '135px',
                    marginBottom: '10px',
                    marginRight: '-7px',
                },
            },
            results: {
                border: `1px solid ${theme.palette.border.mercury}`,
                marginLeft: '15px',
                marginRight: '30px',
                marginTop: '10px',
                marginBottom: isAppraisals ? '80px' : '0px',
                width: '97%',
                [theme.breakpoints.down('lg')]: {
                    marginRight: '0px',
                },
                [theme.breakpoints.down('sm')]: {
                    marginLeft: isAppraisals ? '0px' : '10px',
                    marginRight: '10px',
                    marginBottom: '10px',
                    overflow: 'auto',
                },
            },
            resultsTitle: {
                color: theme.palette.background.white,
                backgroundColor: theme.palette.background.bigStone,
                display: 'flex',
                padding: '10px',
                fontSize: '15px',
                marginLeft: '-1px',
                width: '100.15%',
                justifyContent: 'center',
                position: 'relative',
                '& > div': {
                    position: 'absolute',
                    top: '4px',
                    right: '6px',
                    '& > button': {
                        borderRadius: 0,
                    },
                },
                [theme.breakpoints.down('sm')]: {
                    minWidth: '1200px',
                    justifyContent: 'flex-start',
                },
            },
            tableContainer: {
                height: '500px',
                overflowX: 'hidden',
                overflowY: 'auto',
                [theme.breakpoints.down('sm')]: {
                    minWidth: '1200px',
                },
                '& > div': {
                    overflow: 'hidden',
                },
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    justifyContent: 'left',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    '& > .MuiTextField-root': {
                        width: '90%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableHeader: {
                textAlign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
            ellipsis: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            boldFilter: {
                fontWeight: 'bold',
                '& > span': {
                    fontWeight: 'bold',
                },
            },
        });
    }
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    Button,
    Tooltip,
} from '@material-ui/core';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import If from 'components/widgets/conditional/If';
import Jodit from 'components/widgets/editor/JoditEditor';
import ButtonStyles from 'styles/theme/Button';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DescriptionTabStyle from 'styles/modules/inventory/read/DescriptionTabStyle';

const useStyles = makeStyles((theme) => DescriptionTabStyle.getStyle(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const JoditConfig = {
    autofocus: true,
    allowResizeX: false,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    placeholder: 'Start typing the description',
};

const DescriptionTab = ({
    canWrite,
    savingDescription,
    onDescriptionChange,
    onDescriptionSave,
    onGenerateDescription,
    toggleDescriptionMode,
    isGeneratingDescription,
    isEditMode,
    description,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        isAIAvailable: false,
    });

    const {
        AI_ENABLED_FOR_VEHICLE_DESCRIPTION,
        AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION,
    } = LotDefaultskey;

    const {
        data: aiSettings,
        loading: aiSettingsLoading,
        error: aiSettingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [
                AI_ENABLED_FOR_VEHICLE_DESCRIPTION,
                AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION,
            ],
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (aiSettingsError) {
            ModalUtils.errorMessage(aiSettingsError?.graphQLErrors);
            return;
        }

        if (!aiSettingsLoading && aiSettings) {
            const { getSettings } = aiSettings;
            if (ArrayUtils.isNotEmpty(getSettings)) {
                const aiEnabled = getSettings.find((setting) => setting.key === AI_ENABLED_FOR_VEHICLE_DESCRIPTION)?.value ?? 'false';
                const aiAssistant = getSettings.find((setting) => setting.key === AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION)?.value;

                setState((prevState) => ({
                    ...prevState,
                    isAIAvailable: aiEnabled === 'true' && aiAssistant,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiSettingsLoading, aiSettingsError]);

    return (
        <div className={classes.main}>
            <div className={clsx('d-flex', classes.header)}>
                Vehicle Description
                <If condition={!isEditMode}>
                    <div>
                        <Button
                            disabled={!canWrite || isGeneratingDescription}
                            onClick={toggleDescriptionMode}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                        >
                            Edit
                        </Button>
                        <Tooltip title="Your general ad template will be added to the description" placement="top">
                            <Button
                                disabled={!canWrite || isGeneratingDescription}
                                onClick={() => onGenerateDescription(false)}
                                className={clsx(classes.containedInfo, classes.actionButton)}
                            >
                                {isGeneratingDescription ? 'Generating description...' : 'Paste Template'}
                            </Button>
                        </Tooltip>
                        {state.isAIAvailable && (
                            <Button
                                disabled={!canWrite || isGeneratingDescription}
                                onClick={() => onGenerateDescription(true)}
                                className={clsx(classes.containedInfo, classes.actionButton)}
                            >
                                {isGeneratingDescription ? 'Generating description...' : 'Generate AI Description'}
                            </Button>
                        )}
                    </div>
                </If>
                <If condition={isEditMode}>
                    <div>
                        <Button
                            disabled={savingDescription}
                            onClick={onDescriptionSave}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                            endIcon={<SaveOutlinedIcon />}
                        />
                        <Button
                            onClick={toggleDescriptionMode}
                            className={clsx(classes.containedError, classes.actionButton)}
                            endIcon={<CloseOutlinedIcon />}
                        />
                    </div>
                </If>
            </div>
            <If condition={isEditMode}>
                <Jodit
                    value={description}
                    config={JoditConfig}
                    onChange={(newContent) => onDescriptionChange(newContent)}
                />
            </If>
            <If condition={!isEditMode}>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={classes.descriptionContainer}
                />
            </If>
        </div>
    );
};

DescriptionTab.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    description: PropTypes.string,
    onDescriptionChange: PropTypes.func.isRequired,
    onDescriptionSave: PropTypes.func.isRequired,
    onGenerateDescription: PropTypes.func.isRequired,
    toggleDescriptionMode: PropTypes.func.isRequired,
    savingDescription: PropTypes.bool,
    canWrite: PropTypes.bool.isRequired,
    isGeneratingDescription: PropTypes.bool.isRequired,
};

DescriptionTab.defaultProps = {
    savingDescription: false,
    description: '',
};

export default DescriptionTab;

import React, { useState } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { Link as RouterLink } from 'react-router-dom';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import PhoneNumberUtil from 'lib/PhoneUtils';
import Skeleton from '@material-ui/lab/Skeleton';
import LabelValue from 'components/widgets/LabelValue';
import { ReactComponent as Update } from 'assets/update.svg';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CustomerDialog from 'components/modules/customer/read/CustomerDialog';
import { ReactComponent as DeleteOutlineOutlinedIcon } from 'assets/remove.svg';
import { BorderColorOutlined as EditIcon, InsertCommentOutlined, BlockOutlined } from '@material-ui/icons';

// Material UI
import {
    Grid, Paper, IconButton, Typography, makeStyles, Button,
} from '@material-ui/core';

// Utils
import If from 'components/widgets/conditional/If';
import EditCustomerDialog from 'components/widgets/customer/EditCustomerDialog';
import PaperAddInformation from 'components/widgets/form/PaperAddInformation';
import { isFinite } from 'lodash';

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 250,
        maxHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    main: {
        width: '100%',
    },
    updateIcon: {
        height: '18px',
        width: '18px',
        color: theme.palette.text.infoDark,
    },
    updateButton: {
        marginRight: theme.spacing(0.5),
        color: theme.palette.text.boulder,
        '&:disabled svg': {
            color: `rgba(${theme.palette.rgb.black}, 0.26)`,
        },
    },
    deleteButton: {
        marginRight: theme.spacing(0.5),
    },
    editIcon: {
        height: '18px',
        width: '18px',
    },
    editButton: {
        color: theme.palette.text.purpure,
    },
    boxHeader: {
        padding: theme.spacing(1, 2),
        minHeight: 38,
    },
    cellPhone: {
        display: 'flex',
        alignItems: 'center',
    },
    iconContainer: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    message: {
        color: '#ed9a11',
    },
    block: {
        position: 'absolute',
        color: 'red',
    },
    lineSkeleton: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 26px',
    },
}));

const BasicCustomerInformation = ({
    record, title, open, onClickPrimaryDialog, showDeleteOption, loading,
    showEditCustomer, hasWritePermission, onClickPrimary, onClickRemove,
    disablePrimaryButtonDialog, onClickSecondaryDialog, showMessageIcon,
}) => {
    const classes = useStyles();
    const hasCostumer = isFinite(record.customerCode);
    const [openDelete, setOpenDelete] = useState(false);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const fullName = `${record.firstName || ''} ${record.lastName || ''}`;
    const cellPhone = PhoneNumberUtil.formatPhone(record.cellPhone);
    const { allowTextCellPhone } = record;

    return (
        <div className={classes.main}>
            {loading && (
                <Paper
                    elevation={0}
                    square
                    className={clsx(classes.paper)}
                >
                    <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                        <Skeleton width="18%" height={20} />
                    </div>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                        </Grid>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                            <div className={classes.lineSkeleton}>
                                <Skeleton width="45%" height={20} />
                                <Skeleton width="45%" height={20} />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            )}

            <If condition={hasCostumer}>
                <Paper
                    elevation={0}
                    square
                    className={clsx(classes.paper)}
                >
                    <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                        <div>
                            <Typography variant="h5" color="primary" className={classes.title}>{title}</Typography>
                            <Typography variant="h6" color="primary" className={classes.title}>
                                <RouterLink
                                    to={{ pathname: `/${modules.CUSTOMERS}/${record.customerCode}` }}
                                    className="link"
                                    target="_blank"
                                >
                                    {`Customer #${record.customerCode}`}
                                </RouterLink>
                            </Typography>
                        </div>
                        <div>
                            <If condition={hasWritePermission && showDeleteOption}>
                                <IconButton
                                    size="small"
                                    aria-label="Remove"
                                    className={classes.deleteButton}
                                    onClick={() => setOpenDelete(true)}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </If>
                            <If condition={hasWritePermission}>
                                <IconButton
                                    size="small"
                                    className={classes.updateButton}
                                    onClick={onClickPrimary}
                                >
                                    <Update className={classes.updateIcon} />
                                </IconButton>
                            </If>
                            <If condition={showEditCustomer}>
                                <Button
                                    color="primary"
                                    size="small"
                                    startIcon={<EditIcon />}
                                    onClick={() => setOpenCustomerDialog(true)}
                                >
                                    Edit
                                </Button>
                            </If>
                        </div>
                    </div>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="Name"
                                value={fullName}
                            />
                            <LabelValue
                                label="Email"
                                value={record.email}
                            />
                            <LabelValue
                                label="Cell Phone"
                                value={(
                                    <div className={classes.cellPhone}>
                                        {cellPhone}
                                        <If condition={showMessageIcon}>
                                            <div className={classes.iconContainer}>
                                                {!allowTextCellPhone && <BlockOutlined className={classes.block} />}
                                                <InsertCommentOutlined fontSize="small" className={classes.message} />
                                            </div>
                                        </If>
                                    </div>
                                )}
                            />
                            <LabelValue
                                label="Home Phone"
                                value={PhoneNumberUtil.formatPhone(record.homePhone)}
                            />
                            <LabelValue
                                label="Work Phone"
                                value={PhoneNumberUtil.formatPhone(record.workPhone)}
                            />
                        </Grid>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="DOB"
                                value={DateUtils.getOnlyDate(record.dob)}
                            />
                            <LabelValue
                                label="SSN"
                                value={StringUtils.applySSNMask(record.ssn)}
                            />
                            <LabelValue
                                label="DLN"
                                value={record.dln}
                            />
                            <LabelValue
                                label="Language"
                                value={record.language}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </If>
            <If condition={open}>
                <CustomerDialog
                    open={open}
                    toggleModal={onClickSecondaryDialog}
                    title={title}
                    onSelectRow={onClickPrimaryDialog}
                    disablePrimaryButton={disablePrimaryButtonDialog}
                />
            </If>
            <If condition={openCustomerDialog}>
                <EditCustomerDialog
                    open={openCustomerDialog}
                    record={record}
                    toggleModal={() => setOpenCustomerDialog(false)}
                />
            </If>
            <If condition={!loading && !hasCostumer && hasWritePermission}>
                <PaperAddInformation
                    label={`Add ${title}`}
                    onClickLeftLabel={onClickPrimary}
                />
            </If>
            <If condition={openDelete}>
                <ConfirmDialog
                    title={`Confirm remove ${title}`}
                    description={`Are you sure you want to remove the ${title}?`}
                    open={openDelete}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    dividerFooter={false}
                    onClickPrimary={() => {
                        setOpenDelete(false);
                        onClickRemove();
                    }}
                    onClose={() => setOpenDelete(false)}
                    onClickSecondary={() => setOpenDelete(false)}
                />
            </If>

        </div>
    );
};

BasicCustomerInformation.propTypes = {
    loading: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
    record: PropTypes.object,
    onClickRemove: PropTypes.func,
    onClickPrimary: PropTypes.func,
    showEditCustomer: PropTypes.bool,
    showMessageIcon: PropTypes.bool,
    showDeleteOption: PropTypes.bool,
    hasWritePermission: PropTypes.bool,
    onClickPrimaryDialog: PropTypes.func,
    onClickSecondaryDialog: PropTypes.func,
    disablePrimaryButtonDialog: PropTypes.bool,
};

BasicCustomerInformation.defaultProps = {
    record: {},
    open: false,
    title: 'Buyer',
    loading: false,
    showMessageIcon: false,
    showDeleteOption: false,
    showEditCustomer: false,
    onClickRemove: () => {},
    onClickPrimary: () => {},
    hasWritePermission: false,
    onClickPrimaryDialog: () => {},
    onClickSecondaryDialog: () => {},
    disablePrimaryButtonDialog: false,
};

export default BasicCustomerInformation;

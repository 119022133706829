import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isEmpty, merge } from 'lodash';
import NumberUtils from 'lib/NumberUtils';
import { DealSection } from 'utils/enum/DealEnum';
import DealStyles from 'styles/modules/DealStyles';
import WeOweDialog from 'components/modules/deals/create/dealTab/WeOweDialog';
import CommissionsDialog from 'components/modules/deals/create/dealTab/CommissionsDialog';
import DealRecapContainer from 'components/containers/deals/read/dealTab/DealRecapContainer';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    Typography, Paper, Grid,
    Button,
    Tooltip,
} from '@material-ui/core';
import RateReviewIcon from '@material-ui/icons/RateReview';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => DealStyles.dealStyles(theme, fade);

class DealRecap extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_WE_OWE_READ = keyStore.hasPermission(Permission.SALES_DEAL_WE_OWE_READ);
        this.SALES_DEAL_WE_OWE_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WE_OWE_WRITE);
        this.SALES_DEAL_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);
    }

    componentDidMount() {
        const { getServicesData, subscribeDealRecapUpdated } = this.props;

        subscribeDealRecapUpdated();
        getServicesData();
    }

    componentWillUnmount() {
        const { unsubscribeDealRecapUpdated } = this.props;

        unsubscribeDealRecapUpdated();
    }

    onToggleModal = () => {
        const { props: { onOpenWeOwe, editingMode, loading } } = this;
        if (!editingMode.isEditing && editingMode.sectionName !== DealSection.DEAL_RECAP && !loading) onOpenWeOwe();
    }

    getName = (record) => {
        if (record) {
            const { firstName, lastName, middleName } = record;
            return {
                fullName: `${firstName} ${middleName} ${lastName}`,
                name: firstName ? `${firstName} -` : null,
            };
        }

        return {};
    }

    formatCommission = (value) => NumberUtils.applyCurrencyFormat(NumberUtils.toggleSign(value?.toFixed(2)))

    renderDealRecapDetails() {
        const {
            props: {
                classes, showCommissionsDialog,
                editingMode,
                dealRecap,
                previousDealRecap,
                loading,
                clientId,
                postedDate,
                editingStatus,
            },
        } = this;
        const { isEditing } = editingStatus;
        const record = isEmpty(previousDealRecap) ? dealRecap : merge({}, dealRecap, previousDealRecap);
        const {
            backEndGross, estimatedFloorPlanCost, fees, frontEndGross,
            gap, lenderDiscount, pack, products, purchasedPrice, repairs, reserve,
            totalDealProfit, tradeEquity, vehicleCost, totalCommissions,
            vehicleGross, vsc, weOwe, salesRep1Commission, salesRep2Commission,
            bdcAgentCommission, salesManagerCommission, financeManagerCommission,
            salesPerson1Employee, salesPerson2Employee, financeManagerEmployee, salesManagerEmployee,
            bdcAgentEmployee, commission1Override, commission2Override, salesManagerOverride,
            bdcAgentOverride, financeCommissionOverride,
        } = record;
        const salesPerson1 = this.getName(salesPerson1Employee);
        const salesPerson2 = this.getName(salesPerson2Employee);
        const financeManager = this.getName(financeManagerEmployee);
        const salesManager = this.getName(salesManagerEmployee);
        const bdcAgent = this.getName(bdcAgentEmployee);
        const override = 'Commission Overwritten';

        const isReadOnly = (editingMode.isEditing || isEditing) && editingMode.sectionName !== DealSection.DEAL_RECAP;
        const textDisabled = isReadOnly || loading ? classes.textDisabled : '';
        const clientIdIsWeb = DealUtils.clientIdIsWeb(clientId);
        const isNotPosted = postedDate === null;
        return (
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                <Grid container spacing={1}>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Vehicle Purchase Amount:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(purchasedPrice)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Pack:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(pack)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">RO&apos;s:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(repairs)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Estimated Floorplan Cost:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(estimatedFloorPlanCost)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography
                            color="primary"
                            variant="h6"
                            className={clsx(this.SALES_DEAL_WE_OWE_READ && isNotPosted ? classes.textInfo : null, isNotPosted ? textDisabled : null)}
                            onClick={this.SALES_DEAL_WE_OWE_READ && isNotPosted ? this.onToggleModal : null}
                        >
                            We Owe:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(weOwe)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label" />
                    <Grid item xs={6} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6" className="text-bold">Vehicle Cost:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className="text-bold">{NumberUtils.applyCurrencyFormat(vehicleCost)}</Typography>
                    </Grid>

                    <Grid item xs={12} className="space-between-sections" />
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Vehicle Gross:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vehicleGross)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Fees:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(fees)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Trade Gross:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className={tradeEquity < 0 ? classes.textRed : ''}>{NumberUtils.applyCurrencyFormat(tradeEquity)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Lender Discount:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(lenderDiscount)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label" />
                    <Grid item xs={6} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6" className="text-bold">Front End Gross:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className="text-bold">{NumberUtils.applyCurrencyFormat(frontEndGross)}</Typography>
                    </Grid>
                    <Grid item xs={12} className="space-between-sections" />

                    <Grid item xs={6} className={clsx({ 'd-flex-justify-end-important': !clientIdIsWeb }, classes.boxSalesRep1, 'content-label')}>
                        {clientIdIsWeb && isNotPosted && (
                            <Button
                                color="primary"
                                size="small"
                                disabled={isReadOnly || loading}
                                startIcon={<BorderColorOutlinedIcon />}
                                onClick={showCommissionsDialog}
                            >
                                Edit
                            </Button>
                        )}
                        <div className="d-flex-justify-end-align-center">
                            <Tooltip title={salesPerson1.fullName || ''} placement="left">
                                <span className={classes.nameEmployee}>{salesPerson1.name}</span>
                            </Tooltip>
                            <Typography color="primary" variant="h6">Sales Rep1:</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="content-value d-flex-center">
                        <Typography>{this.formatCommission(salesRep1Commission)}</Typography>
                        {commission1Override && (
                            <Tooltip title={override} placement="right">
                                <RateReviewIcon fontSize="small" className={classes.commissionOverrideIcon} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={6} className="content-label d-flex-justify-end-align-center">
                        <Tooltip title={salesPerson2.fullName || ''} placement="left">
                            <span className={classes.nameEmployee}>{salesPerson2.name}</span>
                        </Tooltip>
                        <Typography color="primary" variant="h6">Sales Rep2:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value d-flex-center">
                        <Typography>{this.formatCommission(salesRep2Commission)}</Typography>
                        {commission2Override && (
                            <Tooltip title={override} placement="right">
                                <RateReviewIcon fontSize="small" className={classes.commissionOverrideIcon} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={6} className="content-label d-flex-justify-end-align-center">
                        <Tooltip title={bdcAgent.fullName || ''} placement="left">
                            <span className={classes.nameEmployee}>{bdcAgent.name}</span>
                        </Tooltip>
                        <Typography color="primary" variant="h6">BDC Agent:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value d-flex-center">
                        <Typography>{this.formatCommission(bdcAgentCommission)}</Typography>
                        {bdcAgentOverride && (
                            <Tooltip title={override} placement="right">
                                <RateReviewIcon fontSize="small" className={classes.commissionOverrideIcon} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={6} className="content-label d-flex-justify-end-align-center">
                        <Tooltip title={salesManager.fullName || ''} placement="left">
                            <span className={classes.nameEmployee}>{salesManager.name}</span>
                        </Tooltip>
                        <Typography color="primary" variant="h6">Sales Manager:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value d-flex-center">
                        <Typography>{this.formatCommission(salesManagerCommission)}</Typography>
                        {salesManagerOverride && (
                            <Tooltip title={override} placement="right">
                                <RateReviewIcon fontSize="small" className={classes.commissionOverrideIcon} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={6} className="content-label d-flex-justify-end-align-center">
                        <Tooltip title={financeManager.fullName || ''} placement="left">
                            <span className={classes.nameEmployee}>{financeManager.name}</span>
                        </Tooltip>
                        <Typography color="primary" variant="h6">Finance Manager:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value d-flex-center">
                        <Typography>{this.formatCommission(financeManagerCommission)}</Typography>
                        {financeCommissionOverride && (
                            <Tooltip title={override} placement="right">
                                <RateReviewIcon fontSize="small" className={classes.commissionOverrideIcon} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={6} className="content-label" />
                    <Grid item xs={6} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6" className="text-bold">Total Commissions:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className="text-bold">{this.formatCommission(totalCommissions)}</Typography>
                    </Grid>
                    <Grid item xs={12} className="space-between-sections" />

                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">VSC:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(vsc)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">GAP:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(gap)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Products:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(products)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6">Reserve:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography>{NumberUtils.applyCurrencyFormat(reserve)}</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-label" />
                    <Grid item xs={6} className="content-value">
                        <div className={classes.lineDivider} />
                    </Grid>
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6" className="text-bold">Back End Gross:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className="text-bold">{NumberUtils.applyCurrencyFormat(backEndGross)}</Typography>
                    </Grid>
                    <Grid item xs={12} className="space-between-back-total" />
                    <Grid item xs={6} className="content-label">
                        <Typography color="primary" variant="h6" className="text-bold">Total Deal Profit:</Typography>
                    </Grid>
                    <Grid item xs={6} className="content-value">
                        <Typography className="text-bold">{NumberUtils.applyCurrencyFormat(totalDealProfit)}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    render() {
        const {
            props: {
                classes,
                onCloseWeOwe,
                open,
                openedCommissionsDialog,
                hideCommissionsDialog,
                accountNumber,
                updateCommisions,
                onChangeEditingMode,
            },
        } = this;

        return (
            <Paper square className={classes.rootPaper}>
                <div className={classes.header}>
                    <Typography variant="h5">Deal Recap</Typography>
                </div>
                {this.renderDealRecapDetails()}
                {open && <WeOweDialog toggleModal={onCloseWeOwe} open={open} accountNumber={accountNumber} writePermission={this.SALES_DEAL_WE_OWE_WRITE} />}
                {openedCommissionsDialog
                    && (
                        <CommissionsDialog
                            onChangeEditingMode={onChangeEditingMode}
                            hideCommissionsDialog={hideCommissionsDialog}
                            accountNumber={accountNumber}
                            updateCommisions={updateCommisions}
                            writePermission={this.SALES_DEAL_WRITE}
                        />
                    )}
            </Paper>
        );
    }
}

DealRecap.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseWeOwe: PropTypes.func.isRequired,
    showCommissionsDialog: PropTypes.func.isRequired,
    hideCommissionsDialog: PropTypes.func.isRequired,
    openedCommissionsDialog: PropTypes.bool.isRequired,
    accountNumber: PropTypes.number.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    updateCommisions: PropTypes.func.isRequired,
    dealRecap: PropTypes.object.isRequired,
    onOpenWeOwe: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    unsubscribeDealRecapUpdated: PropTypes.func.isRequired,
    subscribeDealRecapUpdated: PropTypes.func.isRequired,
    previousDealRecap: PropTypes.object.isRequired,
    // General
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    postedDate: PropTypes.string,
    editingStatus: PropTypes.object,
};

DealRecap.defaultProps = {
    loading: false,
    postedDate: null,
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(DealRecapContainer(DealRecap));

export default class CustomerMap {
    static customerInformation(record) {
        if (record) {
            return {
                email: record.email || '',
                firstName: record.firstName || '',
                lastName: record.lastName || '',
                phone: record.phone || '',
            };
        }

        return {};
    }

    static mapCity(record) {
        if (record) {
            return { label: record.city, value: record.city };
        }

        return { label: '', value: '' };
    }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'styles/Dropdown.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DropdownContent from 'components/widgets/dropdown/DropdownContent';
import Menu from 'components/widgets/dropdown/Menu';

function Dropdown({
    actions, pullRight, onSelect, icon,
    classButton, asLabel, value, classNameSubMenu,
    classText, disabled,
}) {
    const [open, setOpen] = useState(false);

    return (
        <DropdownContent
            open={open}
            icon={icon}
            value={value}
            setOpen={setOpen}
            asLabel={asLabel}
            disabled={disabled}
            classText={classText}
            classButton={classButton}
        >
            {open && <Menu items={actions} pullRight={pullRight} onSelect={onSelect} setOpen={setOpen} className={classNameSubMenu} />}
        </DropdownContent>
    );
}

Dropdown.propTypes = {
    actions: PropTypes.array,
    pullRight: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    icon: PropTypes.object,
    classButton: PropTypes.string,
    asLabel: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    classNameSubMenu: PropTypes.string,
    classText: PropTypes.string,
    disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
    pullRight: false,
    icon: <MoreVertIcon />,
    classButton: '',
    asLabel: false,
    value: null,
    actions: [],
    classNameSubMenu: '',
    classText: '',
    disabled: false,
};

export default Dropdown;

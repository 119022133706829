/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import printJS from 'print-js';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import Badge from '@material-ui/core/Badge';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import If from 'components/widgets/conditional/If';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import { AccountingCBStatus } from 'utils/enum/AccountingEnum';
import { ReportName, EmailStatus } from 'utils/enum/PayrollEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import PayrollTable from 'components/widgets/payroll/PayrollTable';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import PayrollGeneralQuery from 'services/graphQL/query/payroll/PayrollGeneral';
import NachaEffectiveDate from 'components/widgets/payroll/NachaEffectiveDate';
import PayrollCheckDialog from 'components/widgets/payroll/PayrollCheckDialog';
import AccountingLockDate from 'components/widgets/accounting/AccountingLockDate';
import GeneratedChecksQuery from 'services/graphQL/query/payroll/GeneratedChecks';
import GeneratedChecksMutation from 'services/graphQL/mutate/payroll/GeneratedChecks';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import GeneratedChecksSubscription from 'services/graphQL/subscription/payroll/GeneratedChecksSubscription';
import {
    useQuery,
    useMutation,
    useLazyQuery,
    useSubscription,
} from '@apollo/client';
import {
    Chip,
    Button,
    makeStyles,
} from '@material-ui/core';
import {
    SendIcon,
    CheckBoxIcon,
    EditOutlinedIcon,
    BlockOutlinedIcon,
    PrintOutlinedIcon,
    ThumbUpAltOutlinedIcon,
    CheckBoxOutlineBlankIcon,
} from 'components/icons';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles((theme) => ({
    customColumnStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        display: 'flex',
        height: 'fit-content',
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    tableHeight: {
        height: 'calc(95vh - 106px)',
    },
    boxContent: {
        overflow: 'auto',
        height: 'fit-content',
        padding: '9px',
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: 'fit-content',
        background: theme.palette.background.white,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0px',
            border: '0px',
        },
    },
    left: {
        minWidth: 'fit-content',
        marginRight: '15px',
    },
    tableContainer: {
        height: 'fit-content',
    },
    listStyle: {
        border: `1px solid ${theme.palette.border.primary}`,
        marginTop: '15px',
        height: 'calc(93vh - 100px)',
        overflow: 'auto',
        padding: '5px',
    },
    listItemStyle: {
        padding: '0px 5px 0px',
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    loadingPrintingButton: {
        height: 15,
        marginRight: -2,
        marginBottom: -2,
        marginLeft: 'inherit',
    },
    listItemRoot: {
        '&$selected': {
            backgroundColor: theme.palette.background.blueRibbon,
            '&:hover': {
                backgroundColor: theme.palette.background.blueRibbon,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.background.blueRibbon,
        },
    },
    selected: {},
}));

const ACTION_TYPES = {
    SELECT_ALL: 'selectAll',
    SELECT_ONE: 'selectOne',
    APPLY_FILTER: 'applyFilter',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
    SET_YEAR_SELECTED: 'setYearSelected',
    SET_PAY_PERIOD_SELECTED: 'setPayPeriodSelected',
};

const reducer = (state, action) => {
    const {
        value,
        accessor,
    } = action;
    const { filtersApplied } = state;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    case ACTION_TYPES.SET_YEAR_SELECTED:
        return update(state, {
            payPeriodYear: { $set: action.year },
            loadTable: { $set: state.payPeriodYear ? false : state.loadTable },
        });
    case ACTION_TYPES.SET_PAY_PERIOD_SELECTED:
        return update(state, {
            payPeriodSelected: { $set: action.value },
            loadTable: { $set: action.value !== state.payPeriodSelected ? false : state.loadTable },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
            items: { $set: state.items.map((item) => ({ ...item, isChecked: false })) },
        });
    case ACTION_TYPES.SELECT_ALL:
        const newRecords = state.items.map((item) => {
            let filteredElement = false;
            if (filtersApplied?.length > 0) {
                filteredElement = filtersApplied.reduce(
                    (previousValue, currentValue) => previousValue && currentValue.value.includes(item[currentValue.id]),
                    true,
                );
            }
            return {
                ...item,
                isChecked: filteredElement || filtersApplied?.length === 0 ? !value : false,
            };
        });

        return update(state, {
            items: { $set: newRecords },
        });
    case ACTION_TYPES.SELECT_ONE:
        const index = state.items.findIndex((item) => item.checkCBID === action.checkCBID);

        return update(state, {
            items: {
                [index]: {
                    isChecked: {
                        $set: !action.value,
                    },
                },
            },
        });
    default: return state;
    }
};

const GeneratedChecksList = () => {
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };
    const keyStore = new KeyStore();

    const initState = {
        items: [],
        lockDate: null,
        payPeriods: [],
        printing: false,
        loadTable: false,
        filteredData: [],
        searchValue: null,
        filtersApplied: [],
        checkCBIDSelected: 0,
        payPeriodSelected: 0,
        openVoidDialog: false,
        openApprovalDialog: false,
        openLockDateDialog: false,
        openEditCheckDialog: false,
        openNachaEffectiveDateDialog: false,
        payPeriodYear: new Date().getFullYear(),
        printingRequestId: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        payPeriodYear, loadTable, filtersApplied, printingRequestId,
        searchValue, items, filteredData, payPeriods, payPeriodSelected,
    } = state;

    const {
        data: lockDateData, loading: loadingLockDate, error: lockDateError,
    } = useQuery(GeneratedChecksQuery.GET_LOCK_DATE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (lockDateError) {
            ModalUtils.errorMessage(lockDateError?.graphQLErrors);
            return;
        }
        const lockDate = lockDateData?.getAccountingDefaultSettings?.accountingLockDate;
        if (!loadingLockDate) {
            dispatch({
                field: 'lockDate',
                type: ACTION_TYPES.SET_STATE_VALUE,
                value: lockDate?.slice(0, 10),
            });
        }
    }, [lockDateData, loadingLockDate, lockDateError]);

    const [getPayrollCheckListData, {
        loading, data, error, refetch,
    }] = useLazyQuery(GeneratedChecksQuery.GET_PAYROLL_CHECK_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [voidChecksMutate, { loading: voiding }] = useMutation(GeneratedChecksMutation.VOID_PAYROLL_CHECKS, {
        onCompleted: (mutationData) => {
            const { success, isLockedDate, lockedDate } = mutationData?.voidPayrollChecks;

            if (success) {
                ModalUtils.successMessage(null, 'Check(s) voided successfully.');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    values: {
                        checkCBIDSelected: 0,
                        openVoidDialog: false,
                        openLockDateDialog: false,
                    },
                });
                refetch();
            }

            if (isLockedDate) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    values: {
                        lockDate: lockedDate?.slice(0, 10),
                        openVoidDialog: false,
                        openLockDateDialog: true,
                    },
                });
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    openVoidDialog: false,
                    openLockDateDialog: false,
                },
            });
        },
    });

    const togglePrintDialog = (printing) => {
        dispatch({
            value: printing,
            field: 'printing',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [printPayrollReport] = useMutation(GeneratedChecksMutation.PRINT_PAYROLL_REPORT_BY_NAME_AND_PAY_PERIOD, {
        onCompleted: (mutationData) => {
            if (mutationData?.printPayrollReportByNameAndPayPeriod) {
                printJS({
                    printable: mutationData?.printPayrollReportByNameAndPayPeriod,
                    type: 'pdf',
                    showModal: false,
                    onPrintDialogClose: () => togglePrintDialog(false),
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [generatePayrollPrinting] = useMutation(GeneratedChecksMutation.GENERATE_PAYROLL_PRINTING, {
        onCompleted: (mutationData) => {
            if (mutationData?.generatePayrollPrinting) {
                dispatch({
                    field: 'printingRequestId',
                    type: ACTION_TYPES.SET_STATE_VALUE,
                    value: mutationData.generatePayrollPrinting,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(errorMessage?.graphQLErrors);
        },
    });

    const [sendPayrollChecksByEmail, { loading: sending }] = useMutation(GeneratedChecksMutation.SEND_PAYROLL_CHECKS_BY_EMAIL, {
        ignoreResults: true,
        onError: (errorMessage) => {
            ModalUtils.errorMessage(errorMessage?.graphQLErrors);
        },
    });

    const { data: subscriptionData } = useSubscription(GeneratedChecksSubscription.UPDATED_PAYROLL_PRINTING_STATUS, {
        shouldResubscribe: true,
        variables: { printingRequestId },
        skip: StringUtils.isEmpty(printingRequestId),
    });

    useEffect(() => {
        if (subscriptionData?.updatedPayrollPrintingStatus) {
            printJS({
                printable: subscriptionData.updatedPayrollPrintingStatus,
                type: 'pdf',
                showModal: false,
                onPrintDialogClose: () => {
                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        values: {
                            printing: false,
                            printingRequestId: null,
                        },
                    });
                    refetch();
                },
            });
        }
    }, [subscriptionData, refetch]);

    const [generateNachaFile] = useMutation(GeneratedChecksMutation.GENERATE_NACHA_FILE, {
        onCompleted: (mutationData) => {
            if (mutationData?.generateNachaFile) {
                window.open(mutationData?.generateNachaFile);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const toggleNachaEffectiveDateDialog = (date = null) => {
        if (date) {
            generateNachaFile({
                variables: {
                    effectiveDate: DateUtils.format(date, DateFormat.SHORT_DATE),
                    payPeriod: payPeriods[payPeriodSelected].replace(' (Current)', ''),
                },
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUE,
            field: 'openNachaEffectiveDateDialog',
            value: !state.openNachaEffectiveDateDialog,
        });
    };

    const toggleApprovalDialog = () => {
        dispatch({
            field: 'openApprovalDialog',
            value: !state.openApprovalDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [approveChecks, { loading: isApproving }] = useMutation(AccountingMutation.APPROVE_CHECKS, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveChecks) {
                ModalUtils.successMessage(null, 'Checks Approved Successfully!');
                toggleApprovalDialog();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onPrintReport = (formName) => {
        togglePrintDialog(true);
        printPayrollReport({
            variables: {
                formName,
                payPeriod: payPeriods[payPeriodSelected].replace(' (Current)', ''),
            },
        });
    };

    const voidChecks = () => {
        voidChecksMutate({
            variables: {
                forceToVoid: false,
                checkCBID: state.checkCBIDSelected,
            },
        });
    };

    const isLockDateValid = (today, lockDate) => !DateUtils.isSameOrBefore(today, lockDate);

    const openVoidDialog = (checkCBIDSelected) => {
        const { lockDate } = state;
        const today = DateUtils.getOnlyDate(new Date());
        if (isLockDateValid(today, lockDate)) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    openVoidDialog: true,
                    checkCBIDSelected,
                },
            });
        } else {
            ModalUtils.errorMessage([
                { message: 'Lock Date cannot be the same or greater than today, please check Accounting Settings.' },
                { message: `Today: ${today}` },
                { message: `Lock Date: ${lockDate}` },
            ]);
        }
    };

    const closeEditCheckDialog = () => {
        dispatch({
            value: !state.openEditCheckDialog,
            field: 'openEditCheckDialog',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const openEditCheckDialog = (checkCBID) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                openEditCheckDialog: true,
                checkCBIDSelected: checkCBID,
            },
        });
    };

    const getColumns = () => [{
        Header: (header) => {
            const { data: records } = header;
            const allApproved = records.length > 0 && records.filter((c) => c._original.isChecked).length === records.length;

            return allApproved ? (
                <CheckBoxIcon
                    fontSize="small"
                    className={classes.highlightedColor}
                    onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                />
            )
                : (
                    <CheckBoxOutlineBlankIcon
                        fontSize="small"
                        className={classes.highlightedColor}
                        onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                    />
                );
        },
        Cell: (cell) => {
            const {
                original: {
                    checkCBID, isChecked,
                },
            } = cell;

            return (
                isChecked ? (
                    <CheckBoxIcon
                        className={classes.highlightedColor}
                        onClick={() => dispatch({
                            checkCBID,
                            value: isChecked,
                            type: ACTION_TYPES.SELECT_ONE,
                        })}
                    />
                )
                    : (
                        <CheckBoxOutlineBlankIcon
                            className={classes.highlightedColor}
                            onClick={() => dispatch({
                                checkCBID,
                                value: isChecked,
                                type: ACTION_TYPES.SELECT_ONE,
                            })}
                        />
                    )
            );
        },
        width: 60,
        minWidth: 60,
        sortable: false,
        filterable: false,
        id: 'checkbox-column',
        className: clsx(classes.columnStyle, classes.columnCenter),
        headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
    },
    {
        Header: 'Bank Account',
        id: 'bankAccount',
        minWidth: 60,
        accessor: 'bankAccount',
        className: classes.customColumnStyle,
    }, {
        Header: 'Check Number',
        id: 'checkNumber',
        minWidth: 60,
        accessor: 'checkNumber',
        className: classes.customColumnStyle,
    }, {
        Header: 'Pay To',
        id: 'paidTo',
        minWidth: 120,
        accessor: 'paidTo',
        className: classes.customColumnStyle,
    }, {
        Header: 'Amount',
        id: 'amount',
        minWidth: 50,
        accessor: 'amount',
        className: classes.customColumnStyle,
    }, {
        Header: 'Status',
        id: 'status',
        minWidth: 50,
        accessor: 'status',
        className: classes.customColumnStyle,
        Cell: (cellData) => {
            const { original: { status } } = cellData;
            let badgeStatus = classes.primary;
            switch (status.toLowerCase()) {
            case AccountingCBStatus.CLEARED.toLowerCase():
                badgeStatus = classes.badgeCleared;
                break;
            case AccountingCBStatus.APPROVED.toLowerCase():
                badgeStatus = classes.badgeApproved;
                break;
            case AccountingCBStatus.PRINTED.toLowerCase():
                badgeStatus = classes.badgePrinted;
                break;
            default:
                break;
            }

            return <Chip size="small" label={status} className={badgeStatus} />;
        },
    }, {
        Header: 'Email Status',
        id: 'emailStatus',
        minWidth: 50,
        accessor: 'emailStatus',
        className: classes.customColumnStyle,
        Cell: (cellData) => {
            const { original: { emailStatus } } = cellData;
            let badgeStatus = classes.primary;
            switch (emailStatus.toLowerCase()) {
            case EmailStatus.SENT.toLowerCase():
                badgeStatus = classes.badgeCleared;
                break;
            case EmailStatus.SENDING.toLowerCase():
                badgeStatus = classes.badgeApproved;
                break;
            case EmailStatus.FAILED.toLowerCase():
                badgeStatus = classes.badgeVoid;
                break;
            default:
                break;
            }

            return <Chip size="small" label={emailStatus} className={badgeStatus} />;
        },
    }, {
        Header: 'Type',
        id: 'type',
        minWidth: 80,
        accessor: 'type',
        className: classes.customColumnStyle,
    }, {
        Header: 'Actions',
        width: 'auto',
        id: 'actions',
        headerClassName: clsx(classes.hidden),
        className: clsx(classes.hidden, 'actionColumnTarget'),
        Cell: (cellData) => {
            const { original: { checkCBID, status } } = cellData;

            return (
                <div className={classes.buttonSpacing}>
                    {keyStore.hasPermission(Permission.PAYROLL_GENERATED_CHECKS_WRITE)
                        && (
                            <Button
                                onClick={() => openEditCheckDialog(checkCBID)}
                                variant="outlined"
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                                size="small"
                            >
                                Edit
                            </Button>
                        )}
                    {keyStore.hasPermission(Permission.PAYROLL_GENERATED_CHECKS_VOID)
                    && status.toLowerCase() !== AccountingCBStatus.VOID.toLowerCase()
                    && status.toLowerCase() !== AccountingCBStatus.CLEARED.toLowerCase()
                    && !voiding
                        && (
                            <Button
                                onClick={() => openVoidDialog(checkCBID)}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={classes.actionButtonError} />}
                                size="small"
                            >
                                Void
                            </Button>
                        )}
                </div>
            );
        },
    }];

    const {
        data: payPeriodsData,
        loading: loadingPayPeriods,
        error: payPeriodsErrors,
    } = useQuery(PayrollGeneralQuery.GET_PAY_PERIODS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            lotName: keyStore.getSelectedLot()?.lotName || ALL_LOTS,
            year: state.payPeriodYear,
        },
    });

    useEffect(() => {
        if (payPeriodsErrors) {
            ModalUtils.errorMessage(payPeriodsErrors?.graphQLErrors);
            return;
        }

        if (!loadingPayPeriods) {
            const { getPayPeriods } = payPeriodsData;
            const currentPayPeriodIndex = getPayPeriods.findIndex((item) => item.includes('(Current)'));
            const selectedPayPeriodIndex = currentPayPeriodIndex >= 1 ? currentPayPeriodIndex - 1 : 0;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadTable: true,
                    payPeriods: getPayPeriods,
                    payPeriodSelected: selectedPayPeriodIndex,
                },
            });
        }
    }, [payPeriodsData, loadingPayPeriods, payPeriodsErrors]);

    useEffect(() => {
        if (payPeriods.length > 0 && payPeriodSelected >= 0) {
            getPayrollCheckListData({
                variables: { payPeriod: payPeriods[payPeriodSelected].replace(' (Current)', '') },
            });
        } else {
            dispatch({
                value: [],
                field: 'items',
                type: ACTION_TYPES.SET_STATE_VALUE,
            });
        }
    }, [payPeriods, payPeriodSelected, getPayrollCheckListData]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !loadingPayPeriods && data) {
            const { getPayrollCheckList } = data;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadTable: true,
                    filteredData: [],
                    items: getPayrollCheckList,
                    filtersApplied: [],
                },
            });
        }
    }, [data, loading, loadingPayPeriods, error]);

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const onSearch = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUE,
            field: 'searchValue',
            value,
        });
    };

    useEffect(() => {
        let filtered = null;
        if (searchValue) {
            filtered = items.filter((value) => (
                value.paidTo.toLowerCase().includes(searchValue.toLowerCase())
                || String(value.checkNumber).includes(searchValue)
                || String(value.amount).includes(searchValue)
            ));
        }
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUE,
            field: 'filteredData',
            value: filtered,
        });
    }, [searchValue, items]);

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            if (column.id !== 'checkbox-column') {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    const onChangeYear = (_, value) => {
        dispatch({
            type: ACTION_TYPES.SET_YEAR_SELECTED,
            year: value,
        });
    };

    const onChangePayPeriod = (index) => {
        dispatch({
            type: ACTION_TYPES.SET_PAY_PERIOD_SELECTED,
            value: index,
        });
    };

    const closeVoidDialog = () => {
        dispatch({
            value: false,
            field: 'openVoidDialog',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const closeLockDateDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                lockDate: null,
                checkCBIDSelected: 0,
                openLockDateDialog: false,
            },
        });
    };

    const forceToVoid = (values) => {
        const { voidDate } = values;
        voidChecksMutate({
            variables: {
                voidDate,
                forceToVoid: true,
                checkCBID: state.checkCBIDSelected,
            },
        });
    };

    const isFiltered = filteredData?.length > 0;
    const disablePrintingButtons = state.printing || payPeriodSelected < 0 || items.length === 0;
    const selectedRecordsToApprove = items.filter((item) => item.status === AccountingCBStatus.POSTED && item.isChecked);
    const selectedRecordsToPrint = items.filter((item) => item.status !== AccountingCBStatus.POSTED && item.isChecked);
    const itemSelected = items.find((item) => item.checkCBID === state.checkCBIDSelected);
    const selectedRecordsToSend = items.filter((item) => item.status !== AccountingCBStatus.POSTED
        && item.isChecked
        && item.emailStatus.toLowerCase() !== EmailStatus.SENDING.toLowerCase());

    const onPrintChecks = () => {
        togglePrintDialog(true);
        generatePayrollPrinting({
            variables: {
                cbids: selectedRecordsToPrint.map((item) => item.checkCBID),
            },
        });
    };

    const getPrintingButton = (label, reportName, className = null) => (
        <Button
            size="small"
            variant="outlined"
            className={className || ''}
            disabled={disablePrintingButtons}
            onClick={() => onPrintReport(reportName)}
            startIcon={<PrintOutlinedIcon className={clsx({ [classes.actionButtonPrint]: !disablePrintingButtons })} />}
        >
            {label}
        </Button>
    );

    const getPrintingIcon = () => {
        if (printingRequestId) {
            return <Loading className={classes.loadingPrintingButton} />;
        }
        return (
            <PrintOutlinedIcon
                className={clsx({ [classes.actionButtonPrint]: selectedRecordsToPrint.length && !state.printing })}
            />
        );
    };

    const getCustomHeader = () => (
        <>
            <Badge badgeContent={selectedRecordsToApprove?.length ?? 0} color="primary">
                <Button
                    variant="outlined"
                    startIcon={<ThumbUpAltOutlinedIcon className={clsx({ [classes.actionButtonPrint]: selectedRecordsToApprove.length })} />}
                    size="small"
                    disabled={!selectedRecordsToApprove.length || isApproving}
                    onClick={toggleApprovalDialog}
                >
                    Approve Checks
                </Button>
            </Badge>
            <If condition={keyStore.hasPermission(Permission.PAYROLL_REPORTS_PRINT)}>
                <Badge badgeContent={selectedRecordsToSend?.length ?? 0} color="primary">
                    <Button
                        variant="outlined"
                        startIcon={<SendIcon className={clsx({ [classes.actionButtonPrint]: selectedRecordsToSend.length })} />}
                        size="small"
                        className={classes.localButtonSpacing}
                        disabled={!selectedRecordsToSend.length || sending}
                        onClick={() => {
                            refetch();
                            ModalUtils.infoMessage(null, 'Sending Emails...');
                            sendPayrollChecksByEmail({
                                variables: {
                                    cbids: selectedRecordsToSend.map((item) => item.checkCBID),
                                },
                            });
                        }}
                    >
                        Email Stubs
                    </Button>
                </Badge>
                <Badge badgeContent={!printingRequestId ? (selectedRecordsToPrint?.length ?? 0) : 0} color="primary">
                    <Button
                        variant="outlined"
                        className={classes.localButtonSpacing}
                        startIcon={getPrintingIcon()}
                        size="small"
                        disabled={!selectedRecordsToPrint.length || state.printing}
                        onClick={onPrintChecks}
                    >
                        Print Checks
                    </Button>
                </Badge>
                {getPrintingButton('Print Payroll Report', ReportName.PAYROLL_REPORT, classes.localButtonSpacing)}
                {getPrintingButton('Print Deductions Report', ReportName.DEDUCTIONS_REPORT, classes.localButtonSpacing)}
                {getPrintingButton('Print Additions Report', ReportName.ADDITIONS_REPORT, classes.localButtonSpacing)}
            </If>
            <Button
                size="small"
                variant="outlined"
                className={classes.localButtonSpacing}
                onClick={() => toggleNachaEffectiveDateDialog()}
            >
                Generate Nacha File
            </Button>
        </>
    );

    return (
        <div className={classes.content}>
            <PayrollTable
                classes={classes}
                onSearch={onSearch}
                payPeriods={payPeriods}
                onChangeYear={onChangeYear}
                payPeriodYear={payPeriodYear}
                payPeriodSelected={payPeriodSelected}
                onChangePayPeriod={onChangePayPeriod}
                counterLabel="Checks"
                records={items}
                searchValue={searchValue}
                getCustomHeader={getCustomHeader}
                loadTable={loadTable}
                filtersApplied={filtersApplied}
                loadColumns={loadColumns}
                isFiltered={isFiltered}
                filteredData={filteredData}
            />
            <ConfirmDialog
                title="Confirm Void"
                description="Void selected check(s)?"
                open={state.openVoidDialog && !voiding}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={closeVoidDialog}
                onClose={closeVoidDialog}
                onClickPrimary={voidChecks}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Approve selected check(s)?"
                open={state.openApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleApprovalDialog}
                onClose={toggleApprovalDialog}
                onClickPrimary={() => approveChecks({
                    variables: {
                        checks: selectedRecordsToApprove.map((item) => item.checkCBID),
                    },
                })}
            />
            {state.openLockDateDialog && state.lockDate && !voiding && state.checkCBIDSelected
                    && (
                        <AccountingLockDate
                            onForceToVoid={forceToVoid}
                            lockedDate={new Date(state.lockDate)}
                            onCloseActionDialog={closeLockDateDialog}
                            recordNumber={String(itemSelected.checkNumber)}
                        />
                    )}
            {state.openNachaEffectiveDateDialog
                    && (
                        <NachaEffectiveDate
                            onCloseDialog={toggleNachaEffectiveDateDialog}
                        />
                    )}
            {state.openEditCheckDialog
                    && (
                        <PayrollCheckDialog
                            refetch={refetch}
                            onClose={closeEditCheckDialog}
                            checkCBID={state.checkCBIDSelected}
                            enableCheckFields={
                                itemSelected.status === AccountingCBStatus.POSTED
                            }
                            enableCheckLinesFields={
                                isLockDateValid(DateUtils.getOnlyDate(itemSelected.postedDate), state.lockDate)
                            }
                        />
                    )}
        </div>
    );
};

export default GeneratedChecksList;

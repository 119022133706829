import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography, Avatar, Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    small: {
        width: '24px',
        height: '24px',
        border: `1px solid ${theme.palette.border.dustyGray}`,
        backgroundColor: theme.palette.background.silver,
        marginRight: theme.spacing(1.5),
    },
    boxAvatarWithIcon: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(-0.7),
    },
    textAvatar: {
        color: theme.palette.text.scorpion,
        fontWeight: 400,
        fontFamily: 'Arial',
    },
    tooltip: {
        margin: 0,
    },
    avatar: {
        width: '100px',
        height: '100px',
        border: `1px solid ${theme.palette.border.dustyGray}`,
        backgroundColor: theme.palette.background.whiteTransparent,
    },
    avatarTooltip: {
        margin: 0,
        backgroundColor: 'transparent',
    },
}));

const AvatarTooltip = (props) => {
    const { url } = props;
    const classes = useStyles();
    return (
        <div>
            <Avatar className={classes.avatar} src={url} />
        </div>
    );
};

AvatarTooltip.propTypes = {
    url: PropTypes.string,
};

AvatarTooltip.defaultProps = {
    url: null,
};

const AvatarWithLabel = (props) => {
    const classes = useStyles();
    const { person } = props;
    if (!isEmpty(person)) {
        const { firstName, lastName, picture: url } = person || {};
        const name = `${firstName} ${lastName}`;
        return (
            <div
                className={classes.boxAvatarWithIcon}
            >
                <Tooltip title={<AvatarTooltip url={url} />} classes={{ tooltipPlacementBottom: classes.avatarTooltip }}>
                    <Avatar className={classes.small} src={url} />
                </Tooltip>
                <Tooltip title={name} classes={{ tooltipPlacementBottom: classes.tooltip }}>
                    <Typography variant="h6" className={classes.textAvatar} noWrap>{name}</Typography>
                </Tooltip>
            </div>
        );
    }

    return null;
};

AvatarWithLabel.propTypes = {
    person: PropTypes.object,
};

AvatarWithLabel.defaultProps = {
    person: null,
};

export default AvatarWithLabel;

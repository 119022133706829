import React, { Component } from 'react';

// Components and Others
import Select from 'components/widgets/Select';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog,
} from '@material-ui/core';

// Components
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import AmountFinancedContainer from 'components/containers/deals/read/dealTab/AmountFinancedContainer';
import DialogActions from 'components/widgets/modal/DialogActions';

const styles = (theme = {}) => ({
    dialogContent: {
        width: '520px',
        padding: theme.spacing(1, 1.8),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    formStyle: {
        margin: '10px',
    },
    valueDocStamp: {
        marginLeft: theme.spacing(1),
    },
    docStampColor: {
        color: '#767676',
    },
});

// TODO: Work in progress
class AmountFinancedForm extends Component {
    componentDidMount() {
        const { props } = this;

        props.fetchPortfolios();
        props.fetchContracts();
    }

    render() {
        const {
            props: {
                classes,
                toggleModal,
                open,
                onChange,
                onSave,
                portfolioList,
                contractList,
                docStamp,
                portfolioId,
                contractId,
            },
        } = this;

        const contractListSelect = contractList.map((item) => ({
            value: item.loanContractTemplateId,
            label: item.name,
        }));

        const portfolioListSelect = portfolioList.map((item) => ({
            value: item.portfolioId,
            label: item.description,
        }));

        return (
            <Dialog
                open={open}
                maxWidth="sm"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Amount Financed" onClose={toggleModal} iconSize="sm" titleVariant="h4" />
                <DialogContent className={classes.dialogContent}>
                    <Form className={classes.formStyle}>
                        <Form.Group>
                            <Form.Label className={classes.docStampColor}>Doc Stamp</Form.Label>
                            <span
                                className={clsx(classes.valueDocStamp, classes.docStampColor)}
                            >
                                {NumberUtils.applyCurrencyFormat(docStamp || '')}
                            </span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contract Name</Form.Label>
                            <Select
                                name="contractId"
                                placeholder="Select"
                                options={contractListSelect}
                                value={contractId}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Portfolio</Form.Label>
                            <Select
                                name="portfolioId"
                                placeholder="Select"
                                options={portfolioListSelect}
                                value={portfolioId}
                                onChange={onChange}
                                className="basic-multi-select select-bootstrap select-sm"
                            />
                        </Form.Group>
                        <Form.Group as={Col} />
                    </Form>
                </DialogContent>
                <DialogActions
                    titlePrimary="Apply"
                    onClickSecondary={toggleModal}
                    onClickPrimary={() => {
                        onSave();
                        toggleModal();
                    }}
                />
            </Dialog>
        );
    }
}

AmountFinancedForm.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
    fetchPortfolios: PropTypes.func.isRequired,
    fetchContracts: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    portfolioId: PropTypes.number.isRequired,
    contractId: PropTypes.number.isRequired,
    portfolioList: PropTypes.array.isRequired,
    contractList: PropTypes.array.isRequired,
    docStamp: PropTypes.number.isRequired,
};

export default withStyles(styles)(AmountFinancedContainer(AmountFinancedForm));

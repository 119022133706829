import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    FormLabel,
    FormControl,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import Select from 'components/widgets/Select';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles({
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
});

const InputEngine = ({
    engineList, engine, onChange, loading,
}) => {
    const classes = useStyles();

    if (engineList.length > 0) {
        const invalidEngine = StringUtils.isEmpty(engine);
        const engineOptions = engineList.map((item) => ({
            value: item,
            label: item,
        }));

        return (
            <FormControl>
                <FormLabel>Engine</FormLabel>
                <Select
                    name="engine"
                    onChange={(_, newValue) => onChange(newValue)}
                    value={engine}
                    options={engineOptions}
                    nowrap
                    size="sm"
                    className={clsx({ 'invalid-field': invalidEngine })}
                    loading={loading}
                />
                {invalidEngine && (<FormHelperText error>The Engine field is required</FormHelperText>)}
            </FormControl>
        );
    }

    return (
        <FormControl>
            <FormLabel>Engine</FormLabel>
            <TextField
                value={engine}
                onChange={({ target }) => onChange(target.value)}
                variant="outlined"
                size="small"
                className={clsx(classes.textFieldSmall)}
            />
        </FormControl>
    );
};

InputEngine.propTypes = {
    engine: PropTypes.string,
    engineList: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

InputEngine.defaultProps = {
    engineList: [],
    engine: '',
    loading: false,
};

export default InputEngine;

import React from 'react';

import clsx from 'clsx';
import {
    makeStyles,
} from '@material-ui/core';
import 'moment-timezone';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import DateUtils, { DateFormat } from 'lib/DateUtils';

const useStyles = makeStyles(() => ({
    main: {
        flex: 1,
        height: '100%',
    },
    column: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    descriptionCol: {
        whiteSpace: 'inherit !important',
    },
}));

const History = ({ loading, records, className }) => {
    const classes = useStyles();
    const columns = [
        {
            Header: 'User',
            accessor: 'noteUser',
            maxWidth: 110,
        },
        {
            Header: 'Date',
            accessor: 'noteDate',
            maxWidth: 200,
            className: classes.column,
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
        },
        {
            Header: 'Description',
            accessor: 'noteText',
            className: classes.descriptionCol,
            Cell: (record) => {
                const note = record.value;
                const regex = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4} ([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s*([AaPp][Mm])/g;
                return note.replace(regex, (item) => moment.utc(item)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format(DateFormat.DATETIME_WITHOUT_SECONDS));
            },
        },
        {
            Header: 'Platform',
            accessor: 'client',
            className: classes.column,
            maxWidth: 130,
        },
    ];

    return (
        <div className={clsx(className, classes.main)}>
            <Table
                loading={loading}
                columns={columns}
                data={records}
                rowSelected
            />
        </div>
    );
};

History.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    records: PropTypes.array.isRequired,
};

History.defaultProps = {
    className: null,
};

export default History;

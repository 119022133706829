import React, { useState, useContext, useEffect } from 'react';
import {
    makeStyles, Switch, Grid,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import MenuSettingKey from 'utils/enum/UserSettingEnum';
import { useMutation } from '@apollo/client';
import SettingsMutation from 'services/graphQL/mutate/SettingsMutation';

const useStyles = makeStyles(() => ({
    box: {
        paddingBottom: '10px',
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
    },
}));

const SidemenuSettings = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        openInNewTab: true,
        setting: null,
    });

    const { userSettings } = useContext(UserContext);
    const { menuSettings = [], menuSettingsCall } = userSettings ?? {};
    const {
        openInNewTab,
    } = state;

    useEffect(() => {
        if (menuSettings.length > 0) {
            const setting = menuSettings.find((s) => s.key === MenuSettingKey.OPENING_BEHAVIOR);
            const inNewTab = setting?.value ?? 'true';

            setState((prevState) => ({
                ...prevState,
                openInNewTab: inNewTab === 'true',
                setting,
            }));
        }
    }, [menuSettings]);

    const [updateOpeningBehavior] = useMutation(SettingsMutation.SAVE_MENU_SETTING, {
        onCompleted: (response) => {
            if (response) menuSettingsCall();
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const onChange = () => {
        if (!menuSettingsCall) return;

        setState((prevState) => {
            const newValue = !prevState.openInNewTab;
            const { setting } = state;

            updateOpeningBehavior({
                variables: {
                    ...(setting ? { userSettingId: setting.userSettingId } : {}),
                    key: MenuSettingKey.OPENING_BEHAVIOR,
                    value: newValue ? 'true' : 'false',
                },
            });

            return {
                ...prevState,
                openInNewTab: newValue,
            };
        });
    };

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12}>
                <div className={classes.switch}>
                    Open Modules in a new Tab
                    <Switch
                        checked={openInNewTab}
                        onChange={(event) => onChange('openInNewTab', event.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default SidemenuSettings;

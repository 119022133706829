import { gql } from '@apollo/client';

export default class ConversationSubscription {
    static ADDED_CRM_MESSAGE = gql`
        subscription addedCRMMessage($id: ID!) {
            addedCRMMessage(id: $id) {
                ... on QuickReply {
                    type
                    date
                    isCustomer
                    sentBy {
                        userId
                        username
                        profile {
                            firstName
                            lastName
                            userPicture
                        }
                    }
                    _id
                    quickReplyContent: content {
                        text
                        replies {
                            type
                            title
                            payload
                        }
                    }
                }
                ... on CardMessage {
                    type
                    sentBy {
                        userId
                        username
                        profile {
                            firstName
                            lastName
                            userPicture
                        }
                    }
                    _id
                    cardContent: content {
                        title
                        subTitle
                        imageURI
                        buttons {
                            type
                            text
                            postback
                        }
                    }
                }
                ... on TextMessage {
                    type
                    date
                    isCustomer
                    sentBy {
                        userId
                        username
                        profile {
                            firstName
                            lastName
                            userPicture
                        }
                    }
                    _id
                    content {
                        text
                    }
                }
                ... on JoinedAgentMessage {
                    type
                    date
                    _id
                    isCustomer
                    sentBy {
                        userId
                        username
                        profile {
                            firstName
                            lastName
                            userPicture
                        }
                    }
                }
            }
        }
    `;

    static CREATED_CONVERSATION = gql`
        subscription {
            createdConversation {
                _id
                userPlatformId
                platform {
                    name
                }
                lastMessage {
                    ... on QuickReply {
                        type
                        date
                        quickReplyContent: content {
                            text
                        }
                    }
                    ... on CardMessage {
                        type
                        date
                        cardContent: content {
                            title
                        }
                    }
                    ... on TextMessage {
                        type
                        date
                        content {
                            text
                        }
                    }
                    ... on DateTimePicker {
                        type
                    }
                }
                color
                status
                customerName
            }
        }
    `;

    static ADDED_CRM_LAST_MESSAGE = gql`
        subscription {
            addedCRMLastMessage {
                _id
                userPlatformId
                platform {
                    name
                }
                lastMessage {
                    ... on QuickReply {
                        type
                        date
                        isCustomer
                        sentBy {
                            username
                            profile {
                                firstName
                            }
                        }
                        quickReplyContent: content {
                            text
                        }
                    }
                    ... on CardMessage {
                        type
                        date
                        isCustomer
                        sentBy {
                            username
                            profile {
                                firstName
                            }
                        }
                        cardContent: content {
                            title
                        }
                    }
                    ... on TextMessage {
                        type
                        date
                        isCustomer
                        sentBy {
                            username
                            profile {
                                firstName
                            }
                        }
                        content {
                            text
                        }
                    }
                    ... on JoinedAgentMessage {
                        type
                        date
                        isCustomer
                        sentBy {
                            userId
                            profile {
                                firstName
                                lastName
                                userPicture
                            }
                        }
                    }
                }
                color
                status
                customerName
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import avatarUser from 'assets/bot.png';
import StringUtils from 'lib/StringUtils';
import ObjectUtils from 'lib/ObjectUtils';
import Carusel from 'components/widgets/Carusel';
import ConversationStyles from 'styles/modules/ConversationStyles';
import { MessageType, CustomerName } from 'utils/enum/ConversationEnum';
import KeyStore from 'utils/KeyStore';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Box, Avatar, Typography } from '@material-ui/core';

const styles = (theme) => ConversationStyles.message(theme);

class Message extends Component {
    getMessageTypeText(record, name) {
        const { props: { classes } } = this;
        const { isCustomer, content: { text } } = record;
        const messageOut = isCustomer ? '' : classes.messageOut;
        const textOut = isCustomer ? '' : classes.textOut;
        const currentDate = DateUtils.toLocal(record.date);
        const date = DateUtils.fromNow(currentDate);

        return (
            <Box
                className={clsx(classes.message, messageOut)}
            >
                {!record.isCustomer && (
                    <Box
                        display="flex"
                        flexGrow={1}
                        justifyContent="flex-end"
                    >
                        <Typography
                            variant="h5"
                            className={classes.customerName}
                        >
                            {name}
                        </Typography>
                    </Box>
                )}
                <Typography
                    className={clsx(classes.text, textOut)}
                >
                    {text}
                </Typography>
                <Box>
                    <Typography
                        className={clsx(classes.dateTime, textOut)}
                    >
                        {date}
                    </Typography>
                </Box>
            </Box>
        );
    }

    getMessageTypeCard(record) {
        const { content } = record;
        const { props: { classes } } = this;

        return <Box className={classes.cardMessage}><Carusel vehicles={content} /></Box>;
    }

    getCurrentMessage(record, currentType, name) {
        switch (currentType) {
        case MessageType.TEXT:
            return this.getMessageTypeText(record, name);

        case MessageType.QUICK_REPLAY:
            return this.getMessageTypeText(record, name);

        case MessageType.CARD:
            return this.getMessageTypeCard(record);

        default:
            return null;
        }
    }

    getMessage(item) {
        const { props: { classes, customerColor, customerName } } = this;
        const { isCustomer, type, sentBy } = item;

        if (StringUtils.isEmpty(type)) {
            return null;
        }

        const boxMessageOut = isCustomer ? '' : classes.boxMessageOut;
        let currentAvatar = '';
        let name = '';
        if (!isCustomer) {
            if (!isNull(sentBy)) {
                const { profile = {} } = sentBy;
                currentAvatar = profile.userPicture;
                name = `${profile.firstName} ${profile.lastName}`;
            } else {
                currentAvatar = avatarUser;
            }
        }

        const currentType = StringUtils.toUpperCase(type);
        const currentDataFormat = this.formatData(item, currentType);
        const currentMessage = this.getCurrentMessage(currentDataFormat, currentType, name);
        const userColor = !isCustomer && !StringUtils.isEmpty(customerColor) ? '' : customerColor;
        const currentCustomerName = StringUtils.isEmpty(customerName) ? CustomerName.ANONYMOUS.toLowerCase() : customerName;
        const shortCustomer = StringUtils.getCharacterFirstTwoWord(currentCustomerName);
        const { _id } = currentDataFormat;

        return (
            <Box
                display="flex"
                alignItems="flex-end"
                className={boxMessageOut}
                key={_id}
            >
                <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor: userColor }}
                    src={currentAvatar}
                >
                    {shortCustomer}
                </Avatar>
                {currentMessage}
            </Box>
        );
    }

    formatData(record, currentType) {
        switch (currentType) {
        case MessageType.QUICK_REPLAY:
            return ObjectUtils.rename(record, 'quickReplyContent', 'content');

        case MessageType.CARD:
            return ObjectUtils.rename(record, 'cardContent', 'content');

        case MessageType.TEXT:
            return record;

        default:
            return null;
        }
    }

    renderJoinItem(record) {
        const { props: { classes } } = this;
        const { sentBy: { profile = {}, userId }, _id } = record;
        const { firstName, lastName } = profile;

        const currentTitle = userId === new KeyStore().getUserId() ? 'You joined' : `${firstName} ${lastName} joined`;

        return (
            <Box
                className={classes.joinItem}
                key={_id}
            >
                <div className={clsx(classes.divider, classes.dividerLeft)} />
                <span className={classes.joinTitle}>{currentTitle}</span>
                <div className={clsx(classes.divider, classes.dividerRight)} />
            </Box>
        );
    }

    renderMessage() {
        let result = null;
        const { props: { record } } = this;

        result = record.map((item) => {
            if (StringUtils.toUpperCase(item.type) === MessageType.JOINED_AGENT) {
                return this.renderJoinItem(item);
            }
            return this.getMessage(item);
        });

        return result;
    }

    render() {
        return (
            <Box
                flexGrow={1}
                padding={2}
                overflow="auto"
            >
                {this.renderMessage()}
            </Box>
        );
    }
}

Message.propTypes = {
    customerColor: PropTypes.string,
    customerName: PropTypes.string,
    record: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Message.defaultProps = {
    customerColor: '',
    customerName: '',
};

export default withStyles(styles)(Message);

export default class MapTagsData {
    static mapTagsListByModule(record) {
        if (record) {
            return {
                id: record.TagId,
                name: record.Name,
                description: record.Description,
                module: record.Module,
                priority: record.Priority,
                color: record.Color,
                associatedRecords: record.AssociatedRecords,
                active: record.Active,
                createdBy: record.CreatedBy,
                createdOn: record.CreatedOn,
                modifiedBy: record.ModifiedBy,
                modifiedOn: record.ModifiedOn,
            };
        }

        return {};
    }

    static mapListByModule(record) {
        if (record) {
            return {
                id: record.TagId,
                name: record.Name,
                description: record.Description,
                priority: record.Priority,
                color: record.Color,
            };
        }

        return {};
    }

    static mapEditingTag(data) {
        if (data) {
            return {
                Name: data.name,
                Description: data.description,
                Module: data.module,
                Priority: data.priority,
                Color: data.color,
                Active: data.active,
            };
        }

        return {};
    }
}

import { useLazyQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import AccountingGLMap from 'services/mapData/AccountingGLMap';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import { JournalTypes } from 'utils/enum/AccountingEnum';
import NumberUtils from 'lib/NumberUtils';
import { isEmpty } from 'lodash';

const useAccountingGraphqlMethods = ({
    journalType,

}) => {
    let methodToPostJournal = null;
    let methodToGetJournalLines = null;

    if (journalType === JournalTypes.SERVICE) {
        methodToPostJournal = AccountingGLQuery.POST_SERVICE_JOURNAL_PROCESS;
        methodToGetJournalLines = AccountingGLQuery.GET_SERVICE_JOURNAL_DETAIL_LIST_TO_POST_AUTOMATIC;
    } else if (journalType === JournalTypes.PARTS_TICKET) {
        methodToPostJournal = AccountingGLQuery.POST_PARTS_TICKET_JOURNAL_PROCESS;
        methodToGetJournalLines = AccountingGLQuery.GET_PARTS_TICKET_JOURNAL_DETAIL_LIST_TO_POST_AUTOMATIC;
    }

    const [postJournalToAccounting, { loading: postingJournal }] = useMutation(methodToPostJournal, {
        onCompleted: (res) => {
            const { success, isLockedDate } = Object.values(res)[0];

            if (success) {
                ModalUtils.successMessage(null, 'Successfully journal posted!');
            }

            if (isLockedDate) {
                // eslint-disable-next-line max-len
                ModalUtils.errorMessage(null, 'The date falls in a date range that is closed, choose another date but you should go to accounting section in order to complete the posting.');
            }
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
    });

    const [getLinesAndPostAutomaticJournal, { loading: postingJournalAutomatic }] = useLazyQuery(methodToGetJournalLines, {
        onCompleted: (res) => {
            const { data } = Object.values(res)[0];

            const dataMapped = AccountingGLMap.commonGlInformation(data);
            const sumDebit = dataMapped.reduce((sum, item) => sum + item.debit, 0);
            const sumCredit = dataMapped.reduce((sum, item) => sum + item.credit, 0);

            if (dataMapped?.length === 0) {
                ModalUtils.errorMessage(null, 'There are NOT any lines to post this record.');
                return;
            }

            if ((NumberUtils.round(sumDebit) - NumberUtils.round(sumCredit)) !== 0) {
                ModalUtils.errorMessage(null, 'This record can NOT be posted to Accounting because it is out of balance.');
                return;
            }

            const errors = [];

            dataMapped.forEach((item, index) => {
                const lineId = index + 1;
                let message = '';
                if (item.accountNumber <= 0) message += ' Account Number is required,';
                if (item.debit + item.credit === 0) message += ' Debit or Credit is required and must be different than zero';
                if (isEmpty(item.lotName.trim())) message += ' Lot name is required,';
                if (item.isControlled && !isEmpty(item.controlledBy) && item.controlNumber.trim() === '') {
                    message += ` The account ${item.accountDescription} must have a ${item.controlledBy} as Control number,`;
                }

                if (!isEmpty(message)) {
                    message = message.substring(0, message.length - 1);
                    errors.push({ message: `Line # ${lineId} - ${message}` });
                }
            });

            if (errors.length > 0) {
                errors.unshift({ message: 'The journal has missing information to be posted.' });
                ModalUtils.errorMessage(errors);
                return;
            }

            const currentInvoiceNumber = Number(dataMapped[0].referenceNumber);

            const recordToSave = {
                recordId: currentInvoiceNumber,
                journalType,
                postDate: new Date(),
            };

            recordToSave.accountingLines = dataMapped.map((item) => (
                {
                    accountNumber: Number(item.accountNumber),
                    debit: Number(item.debit),
                    credit: Number(item.credit),
                    controlNumber: String(item.controlNumber) || '',
                    lotId: item.lotId,
                    lotName: item.lotName,
                    memo: item.memo,
                }));

            postJournalToAccounting({
                variables: {
                    record: recordToSave,
                },
            });
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const postJournalToAccountingAutomatic = (id, type) => {
        getLinesAndPostAutomaticJournal({
            variables: {
                recordId: id,
                type,
            },
        });
    };

    return {
        postingJournalAutomatic,
        postingJournal,
        postJournalToAccountingAutomatic,
    };
};

export default useAccountingGraphqlMethods;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import {
    concat, size, uniqBy, orderBy,
} from 'lodash';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import UserContext from 'components/context/UserContext';

// GraphQL
import UserService from 'services/modules/UserService';
import UserQuery from 'services/graphQL/query/UserQuery';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

// Utilities
import KeyStore from 'utils/KeyStore';
import UserUtils from 'utils/UserUtils';
import DateUtils from 'lib/DateUtils';
import { DealStatus, PostedStatus, TagAgencyStatus } from 'utils/enum/DealEnum';
import DealService from 'services/modules/DealService';

const ALL_LOTS = 'All Lots';

const DealListContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.keyStore = new KeyStore();
        this.dealListSubscription = null;
        this.dealService = new DealService();
        this.userService = new UserService();

        const { location } = props;
        const query = new URLSearchParams(location.search || '');
        const search = query.get('search');

        this.state = {
            contentList: {
                records: [],
                limit: 50,
                totalCount: 0,
                // TODO: When filters are integrated, use this state variable for the sort
                sortBy: 'soldDate',
                includeDeactivated: false,
                search: search || '',
                stage: search ? null : (this.keyStore.getStage() || DealStatus.FNI),
                lot: [ALL_LOTS],
                postedStatus: search ? null : PostedStatus.NOT_POSTED,
                tagAgencyStatus: search ? null : TagAgencyStatus.ALL,
            },
            load: false,
            openDealDialog: false,
            userAvailableLots: [],
            selectedRecord: {},
            settings: [],
            dealStatus: [],
            stagesTransitionList: [],
            open: false,
            timeZones: null,
            openTransferToRFCDialog: false,
            openAcceptDealDialog: false,
            sendingToRFC: false,
            openSecureCloseDialog: false,
            openRFCMultipleTransfer: false,
        };

        this.initBind();
    }

    componentDidMount() {
        this.subscribeToDealList();
    }

    componentWillUnmount() {
        this.unsubscribeDealList();
    }

    onCloseModal() {
        this.setState({
            open: false,
            selectedRecord: {},
        });
    }

    onOpenModal(record) {
        this.setState({
            open: true,
            selectedRecord: record,
        });
    }

    onSearch(value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                search: { $set: value },
            }),
        }), () => {
            this.getServicesData(true);
        });
    }

    onChangeStatus(value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                stage: { $set: value },
                includeDeactivated: { $set: false },
                search: { $set: '' },
            }),
        }), () => {
            this.keyStore.setStage(value);
            this.getServicesData(true);
        });
    }

    onChangeDeletedDeal(value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                includeDeactivated: { $set: value },
            }),
        }), () => {
            const { contentList: { search } } = this.state;
            if (!StringUtils.isEmpty(search)) {
                this.getServicesData(true);
            }
        });
    }

    onFilterByLot(lot) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                lot: { $set: lot },
            }),
        }), () => {
            this.getServicesData(true);
        });
    }

    onFilterByPostedStatus(name, value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                postedStatus: { $set: value },
                stage: { $set: this.keyStore.getStage() || DealStatus.FNI },
                includeDeactivated: { $set: false },
                search: { $set: '' },
                tagAgencyStatus: { $set: prevState.contentList.tagAgencyStatus || TagAgencyStatus.ALL },
            }),
        }), () => {
            this.getServicesData(true);
        });
    }

    onFilterByTagAgency(name, value) {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                tagAgencyStatus: { $set: value },
                stage: { $set: this.keyStore.getStage() || DealStatus.FNI },
                includeDeactivated: { $set: false },
                search: { $set: '' },
                postedStatus: { $set: prevState.contentList.postedStatus || PostedStatus.NOT_POSTED },
            }),
        }), () => {
            this.getServicesData(true);
        });
    }

    getServicesData(isSearching = false) {
        const {
            state: {
                contentList: {
                    records, limit, sortBy, includeDeactivated,
                    stage, search, lot, postedStatus, tagAgencyStatus,
                },
            },
        } = this;
        let offset = size(records) ? records.length : 0;
        offset = isSearching ? 0 : offset;
        const currentTextSearch = StringUtils.isEmpty(search) ? '' : search;
        const includeDeactivatedDeal = StringUtils.isEmpty(search) ? false : includeDeactivated;

        if (isSearching) {
            this.setState((prevState) => ({
                contentList: update(prevState.contentList, {
                    records: { $set: [] },
                    limit: { $set: limit },
                    totalRecords: { $set: 0 },
                    stage: { $set: !StringUtils.isEmpty(search) ? null : stage },
                    postedStatus: { $set: !StringUtils.isEmpty(search) ? null : postedStatus },
                    tagAgencyStatus: { $set: !StringUtils.isEmpty(search) ? null : tagAgencyStatus },
                }),
                load: true,
            }));
        } else {
            this.setState({ load: true });
        }

        const input = {
            paginate: {
                init: offset,
                limit,
            },
            sort: {
                columnName: sortBy,
                dir: tagAgencyStatus === TagAgencyStatus.SENT ? 'ASC' : 'DESC',
            },
            filter: {
                searchTerm: currentTextSearch,
                includeDeactivated: includeDeactivatedDeal,
                stage: [stage],
                lot,
                postedStatus,
                tagAgencyStatus,
            },
        };

        if (!StringUtils.isEmpty(search)) {
            delete input.filter.stage;
            delete input.filter.postedStatus;
            delete input.filter.tagAgencyStatus;
        }

        if (isSearching) {
            this.subscribeToDealList(currentTextSearch, includeDeactivatedDeal);
        }

        this.dealService.getDeals(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.getDeals) {
                    const recordServices = isSearching ? [] : records;
                    const { getDeals: { totalCount, deals } } = data;
                    const newRecords = concat(recordServices, deals);

                    this.setState((prevState) => ({
                        contentList: update(prevState.contentList, {
                            records: { $set: newRecords },
                            totalCount: { $set: totalCount },
                        }),
                        load: false,
                    }));
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    getUserAvailableLots() {
        this.graphqlClient
            .query(UserQuery.GET_USER_LOTS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getUserLots) {
                    this.setState({
                        userAvailableLots: data.getUserLots,
                    });
                }
            });
    }

    getStagesTransitionList() {
        this.dealService.getStagesTransitionList()
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.getStagesTransitionList) {
                    const { getStagesTransitionList } = data;
                    this.setState({
                        stages: uniqBy(getStagesTransitionList, (item) => item.stageFrom) || [],
                        stagesTransitionList: getStagesTransitionList || [],
                    });
                }
            });
    }

    async getTimeZones() {
        const selectedLot = this.keyStore.getSelectedLot();
        const input = {
            lotId: selectedLot.lotId,
        };
        const response = await this.userService.getTimeZones(input);
        const { data } = { ...response };
        const { getTimeZones } = { ...data };

        this.keyStore.setTimeZones(getTimeZones);

        this.setState({ timeZone: UserUtils.getUserTimeZone(getTimeZones) });
    }

    setInitFilter() {
        this.setState((prevState) => ({
            contentList: update(prevState.contentList, {
                stage: { $set: this.keyStore.getStage() || DealStatus.FNI },
                includeDeactivated: { $set: false },
                postedStatus: { $set: PostedStatus.NOT_POSTED },
                tagAgencyStatus: { $set: TagAgencyStatus.ALL },
            }),
        }));
    }

    subscribeToDealList(searchTerm = null, includeDeactivated = false) {
        this.unsubscribeDealList();
        const {
            contentList: {
                search,
                stage,
                lot,
                postedStatus,
                tagAgencyStatus,
            },
        } = this.state;

        const includeDeactivatedDeal = StringUtils.isEmpty(search) ? false : includeDeactivated;
        const currentStages = StringUtils.isEmpty(search) ? [stage] : [];
        const filter = {
            searchTerm,
            includeDeactivated: includeDeactivatedDeal,
            lotNames: lot,
            stages: currentStages,
            postedStatus,
            tagAgencyStatus,
        };

        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.UPDATED_DEAL_LIST, { filter })
            .then((response) => {
                this.dealListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;
        const { state, state: { contentList } } = this;
        const records = contentList.records || [];
        const lastRecord = records[records.length - 1];

        if (data && data.updatedDeal) {
            const { updatedDeal: { type, deal } } = data;

            const dir = contentList.tagAgencyStatus === TagAgencyStatus.SENT ? 'asc' : 'desc';

            if (type === SubscriptionActionType.UPDATED) {
                const index = records.findIndex((item) => item.accountNumber === deal.accountNumber);
                if (index >= 0) {
                    // Remove deal when status has changed
                    if ((deal.status !== contentList.stage && StringUtils.isEmpty(contentList.search))
                    || (!contentList.includeDeactivated && deal.status === DealStatus.DELETED)
                    || (contentList.postedStatus === PostedStatus.NOT_POSTED && deal.postedDate !== null)
                    || (contentList.postedStatus === PostedStatus.POSTED && deal.postedDate === null)
                    || (contentList.tagAgencyStatus === TagAgencyStatus.NOT_SENT && deal.sentToTagAgencyOn !== null)
                    || (contentList.tagAgencyStatus === TagAgencyStatus.SENT && deal.sentToTagAgencyOn === null)) {
                        this.setState((prevState) => ({
                            contentList: update(prevState.contentList, {
                                records: { $splice: [[index, 1]] },
                            }),
                        }));
                        return;
                    }

                    const currentRecord = orderBy(update(records, { [index]: { $set: deal } }), [contentList.sortBy], [dir]);

                    this.setState((prevState) => ({
                        contentList: update(prevState.contentList, { records: { $set: currentRecord } }),
                    }));
                } else if (DateUtils.isSameOrAfter(deal.soldDate, lastRecord.soldDate)) {
                    const newList = update(state.contentList, {
                        records: { $push: [deal] },
                    });

                    const orderedRecords = newList.records.sort((a, b) => {
                        const firstDate = new Date(a.soldDate);
                        const secondDate = new Date(b.soldDate);

                        if (secondDate < firstDate) return -1;
                        if (secondDate > firstDate) return 1;

                        return b.accountNumber - a.accountNumber;
                    });

                    this.setState({
                        contentList: update(newList, { records: { $set: orderedRecords } }),
                    });
                }
            }

            if (type === SubscriptionActionType.CREATED) {
                const newList = orderBy(update(records, { $unshift: [deal] }), [contentList.sortBy], [dir]);

                this.setState((prevState) => ({
                    contentList: update(prevState.contentList, { records: { $set: newList } }),
                }));
            }
        }
    }

    unsubscribeDealList() {
        if (this.dealListSubscription) {
            this.dealListSubscription.unsubscribe();
        }
    }

    toggleModal(record) {
        this.setState((prevState) => ({
            openDealDialog: !prevState.openDealDialog,
            selectedRecord: record,
        }));
    }

    toogleSecureCloseDialog(record) {
        this.setState((prevState) => ({
            selectedRecord: record,
            openSecureCloseDialog: !prevState.openSecureCloseDialog,
        }));
    }

    toggleTransferToRFCDialog(record) {
        this.setState((prevState) => ({
            sendingToRFC: false,
            selectedRecord: record,
            openTransferToRFCDialog: !prevState.openTransferToRFCDialog,
        }));
    }

    toggleAcceptDealDialog() {
        this.setState((prevState) => ({
            openAcceptDealDialog: !prevState.openAcceptDealDialog,
        }));
    }

    loadMore() {
        this.getServicesData();
    }

    newDeals() {
        const { props } = this;
        // eslint-disable-next-line react/prop-types
        props.history.push('/deals/create');
    }

    toggleRFCMultiple() {
        this.setState((prevState) => ({
            openRFCMultipleTransfer: !prevState.openRFCMultipleTransfer,
        }));
    }

    transferDeal(record) {
        const { state: { selectedRecord } } = this;
        const { accountNumber } = selectedRecord;

        this.dealService.transferDeal(accountNumber, record.lotName)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.transferDeal) {
                    ModalUtils.successMessage([{ message: 'Deal updated successfully' }]);

                    this.toggleModal(null);
                }
            });
    }

    sendToRFC(targetCompanyCode) {
        const { state: { selectedRecord: { accountNumber } } } = this;
        this.setState({ sendingToRFC: true });

        this.dealService.sendToRFC(targetCompanyCode, accountNumber)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.sendToRFC) {
                    ModalUtils.successMessage([{ message: 'Deal transferred successfully' }]);

                    this.toggleTransferToRFCDialog(null);
                }
            });
    }

    initBind() {
        this.onSearch = this.onSearch.bind(this);
        this.newDeals = this.newDeals.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.sendToRFC = this.sendToRFC.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.transferDeal = this.transferDeal.bind(this);
        this.onFilterByLot = this.onFilterByLot.bind(this);
        this.getTimeZones = this.getTimeZones.bind(this);
        this.setInitFilter = this.setInitFilter.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.toggleRFCMultiple = this.toggleRFCMultiple.bind(this);
        this.onChangeDeletedDeal = this.onChangeDeletedDeal.bind(this);
        this.subscribeToDealList = this.subscribeToDealList.bind(this);
        this.onFilterByTagAgency = this.onFilterByTagAgency.bind(this);
        this.getUserAvailableLots = this.getUserAvailableLots.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.onFilterByPostedStatus = this.onFilterByPostedStatus.bind(this);
        this.toggleAcceptDealDialog = this.toggleAcceptDealDialog.bind(this);
        this.getStagesTransitionList = this.getStagesTransitionList.bind(this);
        this.toggleTransferToRFCDialog = this.toggleTransferToRFCDialog.bind(this);
        this.toogleSecureCloseDialog = this.toogleSecureCloseDialog.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <UserContext.Consumer>
                { (data) => (
                    <WrappedComponent
                        {...props}
                        {...state}
                        onSearch={this.onSearch}
                        loadMore={this.loadMore}
                        newDeals={this.newDeals}
                        sendToRFC={this.sendToRFC}
                        onOpenModal={this.onOpenModal}
                        toggleModal={this.toggleModal}
                        getTimeZones={this.getTimeZones}
                        onCloseModal={this.onCloseModal}
                        transferDeal={this.transferDeal}
                        onFilterByLot={this.onFilterByLot}
                        setInitFilter={this.setInitFilter}
                        onChangeStatus={this.onChangeStatus}
                        getServicesData={this.getServicesData}
                        toggleRFCMultiple={this.toggleRFCMultiple}
                        onChangeDeletedDeal={this.onChangeDeletedDeal}
                        onFilterByTagAgency={this.onFilterByTagAgency}
                        getUserAvailableLots={this.getUserAvailableLots}
                        onFilterByPostedStatus={this.onFilterByPostedStatus}
                        toggleAcceptDealDialog={this.toggleAcceptDealDialog}
                        getStagesTransitionList={this.getStagesTransitionList}
                        toggleTransferToRFCDialog={this.toggleTransferToRFCDialog}
                        toogleSecureCloseDialog={this.toogleSecureCloseDialog}
                        secureCloseEnabled={!!data.userInformation?.company?.secureCloseEnabled}
                    />
                )}
            </UserContext.Consumer>
        );
    }
};

export default DealListContainer;

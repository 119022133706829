import { gql } from '@apollo/client';

export default class FormMutation {
    static CREATE_FORM_PACK = gql`
        mutation createFormPack($input: FormPacksInput!) {
            createFormPack(input: $input)
        }
    `;

    static RENAME_FORM_PACK = gql`
        mutation renameFormPack($lotId: Int!, $packId: Int!, $packName: String!) {
           renameFormPack(lotId: $lotId, packId: $packId, packName: $packName)
        }
    `;

    static DUPLICATE_FORM_PACK = gql`
        mutation duplicateFormPack($oldPackId: Int!, $newName: String!) {
            duplicateFormPack(oldPackId: $oldPackId, newName: $newName)
        }
    `;

    static DELETE_FORM_PACK = gql`
        mutation deleteFormPack($lotId: Int!, $packId: Int!) {
            deleteFormPack(lotId: $lotId, packId: $packId)
        }
    `;

    static ADD_FORM_TO_FORM_PACK = gql`
        mutation addFormToFormPack($packId: Int!, $formId: Int!) {
            addFormToFormPack(packId: $packId, formId: $formId)
        }
    `;

    static PRINT_FORM_PACK = gql`
        mutation printFormPack($input: FormPrintInput!) {
            printFormPack(input: $input)
        }
    `;

    static PREVIEW_FORM_PACK = gql`
        mutation previewFormPack($input: FormPreviewInput!) {
            previewFormPack(input: $input)
        }
    `;

    static savePrintListCheckedDefault = gql`
        mutation savePrintListCheckedDefault($packId: Int!, $formsId: [Int!]!) {
            savePrintListCheckedDefault(packId: $packId, formsId: $formsId)
        }
    `;

    static REMOVE_FORM_OF_FORM_PACK = gql`
        mutation removeFormOfFormPack($packId: Int!, $formId: Int!) {
            removeFormOfFormPack(packId: $packId, formId: $formId)
        }
    `;

    static SEND_FORMS_TO_SECURE_CLOSE = gql`
        mutation sendFormsToSecureClose($input: FormPrintInput!) {
            sendFormsToSecureClose(input: $input)
        }
    `;
}

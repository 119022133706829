import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

const StatusCheck = React.memo(({
    className,
    checked,
    property,
    inspectionComplete,
    reconInspectionItemId,
    onChange,
}) => (
    <Checkbox
        color="default"
        className={className}
        checked={checked}
        onChange={(_, value) => (!inspectionComplete ? onChange(property, value, reconInspectionItemId) : null)}
    />
), (prevProps, nextProps) => prevProps.inspectionComplete === nextProps.inspectionComplete
&& prevProps.checked === nextProps.checked);

StatusCheck.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    property: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    inspectionComplete: PropTypes.bool,
    reconInspectionItemId: PropTypes.number,
};

StatusCheck.defaultProps = {
    inspectionComplete: false,
    reconInspectionItemId: 0,
};

export default StatusCheck;

import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import {
    makeStyles, Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import MessageUtils from 'utils/MessageUtils';
import { FetchPolicy } from 'utils/enum/Core';
import ButtonStyles from 'styles/theme/Button';
import Permissions from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import LeadSourcesQuery from 'services/graphQL/query/setting/LeadSourcesQuery';
import LeadSourcesDialog from 'components/modules/settings/crm/LeadSourcesDialog';
import LeadSourcesMutation from 'services/graphQL/mutate/setting/LeadSourcesMutation';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& > button': {
            marginRight: theme.spacing(1),
            width: '95px',
        },
    },
    tableHeight: {
        display: 'contents',
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    ...ButtonStyles.getStyle(theme),
}));

const keyStore = new KeyStore();

const ACTION_TYPE = {
    SET_LOTS: 'setLots',
    SET_RECORDS: 'setRecords',
    ON_OPEN_DIALOG: 'onOpenDialog',
    ON_CLOSE_DIALOG: 'onCloseDialog',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_SET_SELECT_RECORD: 'onSetSelectRecord',
    TOGGLE_DIALOG_CONFIRM: 'toggleDialogConfirm',
};

const reducer = (state, action) => {
    const {
        type,
        payload,
    } = action;

    switch (type) {
    case ACTION_TYPE.SET_RECORDS:
        return update(state, {
            records: { $set: payload },
        });
    case ACTION_TYPE.SET_LOTS:
        return update(state, {
            lots: { $set: payload },
            loading: { $set: false },
        });
    case ACTION_TYPE.ON_SET_SELECT_RECORD:
        const { open, record } = payload;
        return update(state, {
            open: { $set: open },
            selectedRow: { $set: record },
            isEditing: { $set: payload.isEditing },
        });
    case ACTION_TYPE.ON_CLOSE_DIALOG:
        return update(state, {
            open: { $set: false },
            isEditing: { $set: false },
            selectedRow: { $set: null },
        });
    case ACTION_TYPE.ON_OPEN_DIALOG:
        return update(state, {
            open: { $set: true },
            isEditing: { $set: payload?.isEditing || false },
        });
    case ACTION_TYPE.TOGGLE_DIALOG_CONFIRM:
        if (payload) {
            return update(state, {
                confirmDialogOpen: { $set: payload },
            });
        }

        return update(state, {
            selectedRow: { $set: null },
            confirmDialogOpen: { $set: payload },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            records: { $set: [] },
            selectedRow: { $set: null },
            [action.field]: { $set: action.payload },
        });
    default:
        return state;
    }
};

const LeadSources = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        lots: [],
        records: [],
        open: false,
        active: true,
        loading: true,
        selectedRow: null,
        confirmDialogOpen: false,
    });
    const [deleteLeadSource, { loading: deleting }] = useMutation(LeadSourcesMutation.DELETE_LEAD_SOURCE);

    const {
        data, error, loading, refetch,
    } = useQuery(LeadSourcesQuery.GET_LEAD_SOURCES, {
        variables: { active: state.active },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loading && data?.getLeadSourcesForSettings) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data.getLeadSourcesForSettings,
            });
        }
    }, [data, loading]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    const {
        data: lotData, error: lotDataError, loading: loadingLots,
    } = useQuery(LotQuery.GET_LOTS, {
        variables: { filter: { active: true } },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (!loadingLots && lotData?.lotList) {
            dispatch({
                type: ACTION_TYPE.SET_LOTS,
                payload: lotData.lotList,
            });
        }
    }, [lotData, loadingLots]);

    if (lotDataError) {
        ModalUtils.errorMessage(null, lotDataError?.message);
    }

    const onChangeValue = (field, payload) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            field,
            payload,
        });
    };

    const toggleDialogConfirm = () => {
        dispatch({
            payload: !state.confirmDialogOpen,
            type: ACTION_TYPE.TOGGLE_DIALOG_CONFIRM,
        });
    };

    const onSelectRow = (selectedRow) => {
        dispatch({
            payload: { record: selectedRow },
            type: ACTION_TYPE.ON_SET_SELECT_RECORD,
        });
    };

    const onClose = () => {
        dispatch({
            type: ACTION_TYPE.ON_CLOSE_DIALOG,
        });
    };

    const onEdit = () => {
        dispatch({
            payload: { isEditing: true },
            type: ACTION_TYPE.ON_OPEN_DIALOG,
        });
    };

    const onCreate = () => {
        dispatch({
            type: ACTION_TYPE.ON_OPEN_DIALOG,
        });
    };

    const onDelete = async () => {
        try {
            const { leadSourceId } = state.selectedRow;
            const response = await deleteLeadSource({ variables: { leadSourceId } });

            if (response.data?.deleteLeadSource) {
                toggleDialogConfirm();
                ModalUtils.successMessage(null, 'Removed Successfully');
                refetch();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('removing', 'lead source'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const getColumns = (lots) => [
        {
            minWidth: 120,
            id: 'leadSource',
            Header: 'Lead Source',
            accessor: 'leadSource',
        }, {
            id: 'domain',
            accessor: 'domain',
            Header: 'Lead Email Domain',
        }, ...lots.map(({ lotName }) => ({
            id: lotName,
            Header: lotName,
            accessor: lotName,
            className: 'd-flex-justify-center-align-center',
            Cell: (cellData) => {
                const { original: { detail } } = cellData;
                const value = detail.find((item) => item.lotName.toLowerCase() === lotName.toLowerCase())?.monthlyAdvertisingCost;

                return (<span>{NumberUtils.applyCurrencyFormat(value)}</span>);
            },
        })), {
            Header: 'Total Cost',
            id: 'monthlyAdvertisingCost',
            accessor: 'monthlyAdvertisingCost',
            className: 'd-flex-justify-center-align-center',
            Cell: (record) => NumberUtils.applyCurrencyFormat(record.value),
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {keyStore.hasPermission(Permissions.CRM_SETTING_LOST_REASON_WRITE) && (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={onCreate}
                            startIcon={<AddIcon />}
                            className={clsx(classes.containedGreen)}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            onClick={onEdit}
                            variant="contained"
                            disabled={isEmpty(state.selectedRow)}
                            className={clsx(classes.containedInfo)}
                            startIcon={<BorderColorOutlinedIcon />}
                        >
                            Edit
                        </Button>
                        {state.active
                            && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<DeleteIcon />}
                                    onClick={toggleDialogConfirm}
                                    disabled={isEmpty(state.selectedRow)}
                                    className={clsx(classes.containedError)}
                                >
                                    Delete
                                </Button>
                            )}
                    </>
                )}
                <div className="d-flex-center">
                    <Form.Group
                        className="form-check form-check-inline mb-0"
                    >
                        <Form.Check
                            type="radio"
                            name="radio"
                            label="Active"
                            id="radioActive"
                            key="radioActive"
                            value={state.active}
                            checked={state.active}
                            onChange={() => onChangeValue('active', true)}
                        />
                        <Form.Check
                            type="radio"
                            name="radio"
                            label="In-Active"
                            id="radioInactive"
                            key="radioInactive"
                            value={!state.active}
                            checked={!state.active}
                            onChange={() => onChangeValue('active', false)}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className={classes.tableHeight}>
                {state.loading && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {!state.loading && (
                    <Table
                        sortable
                        load={loading}
                        cursor="default"
                        data={state.records}
                        columns={getColumns(state.lots)}
                        totalRecords={state.records.length}
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;
                            const selected = (!state.selectedRow ? false : state.selectedRow.leadSourceId === record.leadSourceId);
                            return {
                                onDoubleClick: onEdit,
                                onClick: () => onSelectRow(record),
                                className: selected ? 'active' : '',
                            };
                        }}
                    />
                )}
            </div>
            {state.open && (
                <LeadSourcesDialog
                    onClose={onClose}
                    refetch={refetch}
                    record={state.isEditing ? state.selectedRow : null}
                    lots={state.lots.map((lot) => lot.lotName)}
                />
            )}
            {state.confirmDialogOpen && (
                <ConfirmDialog
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    dividerFooter={false}
                    onClickPrimary={onDelete}
                    onClose={toggleDialogConfirm}
                    open={state.confirmDialogOpen}
                    disablePrimaryButton={deleting}
                    title="Confirm delete lead source"
                    onClickSecondary={toggleDialogConfirm}
                    description="Are you sure you want to remove this lead source?"
                />
            )}
        </div>
    );
};

export default LeadSources;

export default class ServiceListStyle {
    static serviceList(theme = {}) {
        return ({
            customColumns: {
                display: 'flex',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                alignItems: 'flex-start',
                flexDirection: 'column',
            },
            innerItem: {
                cursor: 'default',
            },
            listBox: {
                overflow: 'auto',
                width: '100%',
                height: 'calc(100vh - 175px)',
                backgroundColor: `rgba(${theme.palette.rgb.black}, 0.02)`,
            },
            rowItem: {
                marginBottom: '10px',
                marginRight: '5px',
                border: `solid 1px rgba(${theme.palette.rgb.black}, 0.12)`,
                '&:nth-child(even)': {
                    backgroundColor: theme.palette.background.titanWhite,
                },
                '& .MuiAccordionSummary-content': {
                    [theme.breakpoints.down('lg')]: {
                        display: 'block',
                    },
                },
                '& .actions': {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    display: 'none',
                    paddingRight: '50px',
                    [theme.breakpoints.down('md')]: {
                        display: 'grid',
                        marginTop: 12,
                    },
                    [theme.breakpoints.down('sm')]: {
                        position: 'relative',
                        paddingRight: 0,
                    },
                },
                '& .actionsStatus': {
                    position: 'absolute',
                    right: 0,
                    top: 40,
                    display: 'none',
                },
                '&:hover': {
                    backgroundColor: theme.palette.background.portica,
                    cursor: 'pointer',
                },
                '&:hover .actions': {
                    [theme.breakpoints.down('xl')]: {
                        display: 'block',
                    },
                    [theme.breakpoints.down('md')]: {
                        display: 'grid',
                    },
                },
            },
            innerTable: {
                float: 'left',
                borderCollapse: 'collapse',
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.87)',
                width: 'auto',
                height: 'max-content',
                marginRight: '10px',
                marginTop: '20px',
                marginBottom: '20px',
                '& > thead > tr > td, & > tbody > tr > td:not(.no-padding), & > tfoot > tr > td': {
                    padding: '4px 6px',
                    lineHeight: '14px',
                    minWidth: 85,
                    [theme.breakpoints.down('md')]: {
                        display: 'block',
                        textAlign: 'right',
                        '&:before': {
                            content: 'attr(data-label)',
                            float: 'left',
                        },
                    },
                },
                '& > tfoot > tr': {
                    backgroundColor: `rgba(${theme.palette.rgb.black}, 0.03)`,
                    fontWeight: 'bold',
                },
                [theme.breakpoints.down('md')]: {
                    width: '80%',
                    marginTop: 0,
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            largeCell: {
                minWidth: 200,
                width: 200,
                whiteSpace: 'break-spaces',
                overflowWrap: 'break-word',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            mediumCell: {
                width: 160,
                whiteSpace: 'break-spaces',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            minCell: {
                width: 50,
                minWidth: '50px !important',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
            mainLabel: {
                color: theme.palette.text.waterloo,
                fontSize: '13px',
                fontWeight: 700,
                paddingLeft: '12px !important',
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '1rem !important',
                },
                [theme.breakpoints.down('md')]: {
                    paddingLeft: '6px !important',
                },
            },
            jobTableCls: {
                width: '80%',
                margin: '0 auto',
                '& .MuiAccordionSummary-content': {
                    display: 'grid',
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    fontSize: '10px',
                },
            },
            ageCell: {
                paddingLeft: 8,
            },
            actionButton: {
                margin: 5,
                fontSize: 12,
                backgroundColor: theme.palette.background.white,
                '&:hover': {
                    backgroundColor: theme.palette.background.selago,
                },
            },
            onlyDesktop: {
                maxWidth: 80,
                [theme.breakpoints.down('md')]: {
                    display: 'none !important',
                },
            },
        });
    }

    static serviceJobItem(theme = {}) {
        return ({
            headerBg: {
                backgroundColor: 'rgb(0,0,0, 0.05)',
            },
            gridColumns: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr',
                margin: 0,
                alignItems: 'center',
            },
            footer: {
                margin: 0,
            },
            transparentRow: {
                backgroundColor: 'transparent',
            },
            whiteTable: {
                backgroundColor: theme.palette.background.white,
            },
            techAvatar: {
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                paddingLeft: '10px',
                '& > img': {
                    verticalAlign: 'middle',
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                },
                '& > p': {
                    fontSize: '10px',
                },
            },
            tableBorder: {
                border: 'solid 1px rgb(0,0,0,0.12)',
                margin: '0 auto',
                '& > tbody > tr, & > thead > tr': {
                    borderBottom: 'solid 1px rgb(0,0,0,0.12)',
                },
            },
            summaryDetail: {
                height: 'auto',
                minHeight: 35,
                padding: 0,
                margin: 0,
                '& .MuiAccordionSummary-content': {
                    margin: 0,
                },
                '& .MuiIconButton-edgeEnd': {
                    padding: '10px 25px',
                },
            },
            paddingTd: {
                padding: '5px',
            },
            partsTable: {
                width: '80% !important',
                margin: '0 auto !important',
            },
            alertCls: {
                color: theme.palette.text.red,
            },
            jobTblCls: {
                width: 'calc(100% + 60px)',
                cursor: 'default',
            },
        });
    }
}

import React from 'react';
import {
    makeStyles,
    CircularProgress,
} from '@material-ui/core';
import LoadingMaskStyle from 'styles/widgets/LoadingMaskStyle';

const useStyles = makeStyles((theme) => LoadingMaskStyle.circularProgress(theme));

const LoadingMask = () => {
    const classes = useStyles();

    return (
        <div className={classes.loading}>
            <CircularProgress />
        </div>
    );
};

export default LoadingMask;

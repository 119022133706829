import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, Typography, Container,
    Accordion, AccordionSummary, AccordionDetails,
    Chip,
} from '@material-ui/core';
import {
    CachedIcon,
    ExpandMoreIcon,
    ExpandLessIcon,
    PrintOutlinedIcon,
} from 'components/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { useLazyQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import clsx from 'clsx';
import ReportQuery from 'services/graphQL/query/ReportQuery';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import DropdownQuery from 'components/widgets/DropdownQuery';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import ReportMapper from 'services/mapData/ReportMapper';
import {
    DriveEtaOutlined as DriveEtaOutlinedIcon,
} from '@material-ui/icons';
import ReportHeader from 'components/reports/ReportHeader';
import ReportFooter from 'components/reports/ReportFooter';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import InputSearch from 'components/widgets/InputSearch';
import ReportContent from 'components/reports/ReportContent';
import printJS from 'print-js';
import ReportPrint from 'components/reports/ReportPrint';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));
const ownStyle = makeStyles((theme) => ({
    columnDescriptionMobile: {
        width: '35%',
        fontSize: 12,
        fontWeight: '500',
    },
    vehicleDetail: {
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        display: 'block',
    },
    filtersFull: {
        width: '100% !important',
    },
    filters: {
        width: '200px',
    },
    dropDownLot: {
        width: '200px',
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.border.primary}`,
    },
    dropDownLotFull: {
        width: '100% !important',
        background: theme.palette.background.white,
        borderRadius: 5,
        border: `1px solid ${theme.palette.border.primary}`,
        '& div': {
            width: '100% !important',
        },
        '& div > div': {
            width: '100% !important',
            textAlign: 'left',
        },
        '& div > div > div': {
            paddingLeft: '10px',
        },
    },
    search: {
        borderRadius: 10,
        backgroundColor: theme.palette.background.white,
        width: 230,
        '& .form-control': {
            height: 'calc(1.5em + 0.5rem + 2px)',
            padding: '0.25rem 0.5rem',
            fontSize: '0.875rem',
            lineHeight: '1.5',
            borderRadius: '0.2rem',
        },
    },
    container: {
        padding: '15px',
    },
    vehicleInfoWrapMobile: {
        display: 'block',
        width: '100%',
        color: theme.palette.text.outerSpace,
        fontSize: '14px',
        fontWeight: 500,
    },
    vehicleInfoWrap: {
        display: 'flex',
        width: '100%',
        color: theme.palette.text.outerSpace,
        fontSize: '14px',
        fontWeight: 500,
    },
    vehicleInfo: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
    },
    vehicleLocationStatus: {
        marginLeft: 10,
    },
    vehicleLocationStatusMobile: {
        width: '100%',
        textAlign: 'center',
    },
    desktopColumn: {
        flexWrap: 'wrap',
        width: '15%',
        display: 'inline-flex',
    },
    desktopColumnNumber: {
        width: '4%',
    },
    textBold: {
        fontSize: 12,
        fontWeight: '500',
    },
    text: {
        fontSize: 12,
        color: '#000000a8',
    },
    vehicleDetailDesktop: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '&:hover': {
            backgroundColor: theme.palette.background.cornFlowerBlueTrans,
        },
    },
    partsDetailDesktop: {
        display: 'grid',
        width: '100%',
    },
    partsDetailMobile: {
        display: 'grid',
        width: '100%',
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    accordionFeature: {
        marginBottom: '16px',
    },
}));

export const ACTION_TYPES = {
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValue',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_EXPAND_COLLAPSE_ALL: 'setExpandCollpaseAll',
    SET_EXPAND_COLLAPSE: 'setExpandCollpase',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL: {
        const currentRecords = state.table.records.map((item) => ({
            ...item,
            open: action.value,
        }));

        return {
            ...state,
            table: {
                totalCount: state.table.totalCount,
                records: [...currentRecords],
            },
        };
    }
    case ACTION_TYPES.SET_EXPAND_COLLAPSE: {
        const { stockNumber, val } = action.value;

        const currentRecords = state.table.records.map((item) => {
            if (item.stockNumber === stockNumber) {
                return {
                    ...item,
                    open: val,
                };
            }

            return item;
        });

        return {
            ...state,
            table: {
                totalCount: state.table.totalCount,
                records: [...currentRecords],
            },
        };
    }
    default:
        return state;
    }
};

const Recon = () => {
    const initialState = {
        params: {
            init: 0,
            limit: 25,
            ignoreLimit: false,
            search: '',
            assigneeId: -1,
            lotName: [ALL_LOTS],
        },
        table: {
            records: [],
            totalCount: 0,
        },
        printing: false,
        openPrint: false,
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        params,
        table,
        openPrint,
    } = state;

    const [getRecords, { loading, refetch }] = useLazyQuery(ReportQuery.GET_RECON_REPORT, {
        variables: {
            paginate: {
                init: params.init,
                limit: params.limit,
            },
            filter: {
                search: params.search,
                assigneeId: params.assigneeId,
                lotName: (params.lotName && params.lotName.length === 1 && params.lotName[0] === ALL_LOTS) ? [] : params.lotName,
            },
        },
        onCompleted: (res) => {
            const result = res?.getReconReport;
            if (result) {
                const processedData = ReportMapper.reconMapLines(result.data);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: processedData,
                        totalCount: result.totalCount,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(error?.graphQLErrors);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClosePringDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPrint: false,
            },
        });
    };

    const [printCheck, { loading: printing }] = useLazyQuery(ReportQuery.PRINT_RECON_REPORT, {
        onCompleted: (mutationData) => {
            if (mutationData?.printReconReport) {
                printJS({
                    printable: mutationData?.printReconReport,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onParamsChange = (param, value) => {
        let currentParams = params;
        if (param === 'page') {
            currentParams = {
                ...currentParams,
                ...value,
            };
        }

        if (param === 'assigneeId' || param === 'search') {
            currentParams = {
                ...currentParams,
                init: 0,
            };
        }

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...currentParams,
                [param]: value,
            },
        });
    };

    const reload = () => {
        dispatch({
            type: ACTION_TYPES.SET_TABLE,
            value: {
                records: [],
                totalCount: 0,
            },
        });

        refetch();
    };

    const onPrint = (option) => {
        onClosePringDialog();
        printCheck({
            variables: {
                paginate: {
                    init: params.init,
                    limit: params.limit,
                    ignoreLimit: option === 'all',
                },
                filter: {
                    search: params.search,
                    assigneeId: params.assigneeId,
                    lotName: (params.lotName && params.lotName.length === 1 && params.lotName[0] === ALL_LOTS) ? [] : params.lotName,
                },
            },
        });
    };

    useEffect(() => {
        getRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showMainData = (
            table?.records.map(({
                vehicleInfo, stockNumber, details, open, vehicleLocation,
            }, i) => {
                const vehicle = isMobile ? (
                    <div className={classes.vehicleInfoWrapMobile}>
                        <span className={classes.vehicleInfo}>
                            <DriveEtaOutlinedIcon />
                            {' '}
                            {vehicleInfo}
                            {' '}
                        </span>
                        <div className={classes.vehicleLocationStatusMobile}>
                            <Chip size="small" label={vehicleLocation} />
                        </div>
                    </div>
                ) : (
                    <div className={classes.vehicleInfoWrap}>
                        <DriveEtaOutlinedIcon />
                        <span className={classes.vehicleInfo}>
                            {' '}
                            {vehicleInfo}
                            {' '}
                        </span>
                        <Chip className={classes.vehicleLocationStatus} size="small" label={vehicleLocation} />
                    </div>
                );

                const secondaryText = isMobile ? (
                    <div className={classes.partsDetailMobile}>
                        {details?.map((det, j) => (
                            <div className={classes.vehicleDetail} key={j + stockNumber}>
                                <span className={classes.flex}>
                                    <Typography component="span" className={classes.columnDescriptionMobile} color="textPrimary" align="left" display="inline">
                                        Assignee : &nbsp;
                                    </Typography>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.assignee}
                                    </Typography>
                                </span>
                                <span className={classes.flex}>
                                    <Typography component="span" className={classes.columnDescriptionMobile} color="textPrimary" align="left" display="inline">
                                        Category : &nbsp;
                                    </Typography>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.category}
                                    </Typography>
                                </span>
                                <span className={classes.flex}>
                                    <Typography component="span" className={classes.columnDescriptionMobile} color="textPrimary" align="left" display="inline">
                                        Repair Item : &nbsp;
                                    </Typography>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.repairItem}
                                    </Typography>
                                </span>
                                <span className={classes.flex}>
                                    <Typography component="span" className={classes.columnDescriptionMobile} color="textPrimary" align="left" display="inline">
                                        Part / Part Status : &nbsp;
                                    </Typography>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.partDetail}
                                    </Typography>
                                </span>
                                <span className={classes.flex}>
                                    <Typography component="span" className={classes.columnDescriptionMobile} color="textPrimary" align="left" display="inline">
                                        Estimate : &nbsp;
                                    </Typography>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.estimate}
                                    </Typography>
                                </span>
                                {det?.comment && (
                                    <span className={classes.flex}>
                                        <Typography
                                            component="span"
                                            className={classes.columnDescriptionMobile}
                                            color="textPrimary"
                                            align="left"
                                            display="inline"
                                        >
                                            Comment : &nbsp;
                                        </Typography>
                                        <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                            {det.comment}
                                        </Typography>
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.partsDetailDesktop}>
                        <div className={classes.vehicleDetailDesktop}>
                            <span className={classes.desktopColumnNumber}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    #
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Assignee
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Category
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Repair Item
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Part / Part Status
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Estimate
                                </Typography>
                            </span>
                            <span className={classes.desktopColumn}>
                                <Typography component="span" className={classes.textBold} color="textPrimary" align="left" display="inline">
                                    Comment
                                </Typography>
                            </span>
                        </div>
                        {details?.map((det, j) => (
                            <div className={classes.vehicleDetailDesktop} key={j + stockNumber}>
                                <span className={classes.desktopColumnNumber}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {j + 1}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.assignee}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.category}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.repairItem}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.partDetail}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.estimate}
                                    </Typography>
                                </span>
                                <span className={classes.desktopColumn}>
                                    <Typography component="span" className={classes.text} color="textSecondary" align="left" display="inline">
                                        {det.comment}
                                    </Typography>
                                </span>
                            </div>
                        ))}
                    </div>
                );

                return (
                    <Accordion
                        key={i * stockNumber}
                        expanded={open}
                        onChange={(_, val) => dispatch({
                            type: ACTION_TYPES.SET_EXPAND_COLLAPSE,
                            value: {
                                stockNumber,
                                val,
                            },
                        })}
                        className={classes.accordionFeature}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {vehicle}
                        </AccordionSummary>
                        <AccordionDetails>
                            {secondaryText}
                        </AccordionDetails>
                    </Accordion>
                );
            })
    );

    return (
        <>
            <Container
                maxWidth={false}
                disableGutters
                className={clsx({ [classes.noPadding]: isMobile },
                    { [classes.container]: !isMobile })}
            >
                <ReportHeader
                    titleReport="Recon report"
                >
                    <InputSearch
                        customClasses={clsx(classes.search, isMobile ? classes.filtersFull : '')}
                        initialSearch={params.search || ''}
                        onSearch={(value) => onParamsChange('search', value)}
                    />
                    <DropdownQuery
                        name="assigneeId"
                        value={params.assigneeId}
                        className={clsx('form-control-xs', classes.filters, isMobile ? classes.filtersFull : '')}
                        placeholder="Assignee"
                        onChange={(_name, value) => onParamsChange('assigneeId', value)}
                        dataSource={{
                            query: ReportQuery.GET_RECON_ASSIGNEE_USERS,
                            rootData: 'getReconAssigneeUsers',
                            idField: 'userId',
                            descriptionField: 'userName',
                        }}
                        defaultEmptyLineText="All"
                    />
                    {isMobile && (
                        <div className={clsx({ [classes.dropDownLotFull]: isMobile })}>
                            <LotFilter
                                onSearch={(values) => onParamsChange('lotName', values)}
                                initialLots={params.lotName}
                                containerWidth={200}
                            />
                        </div>
                    )}
                    {!isMobile && (
                        <LotFilter
                            onSearch={(values) => onParamsChange('lotName', values)}
                            initialLots={params.lotName}
                            className={classes.dropDownLot}
                            containerWidth={200}
                        />
                    )}
                    <Button
                        variant="outlined"
                        startIcon={<CachedIcon />}
                        disabled={loading}
                        size="small"
                        onClick={reload}
                    >
                        Go
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<PrintOutlinedIcon />}
                        disabled={loading}
                        size="small"
                        onClick={() => dispatch({
                            type: ACTION_TYPES.SET_STATE_VALUES,
                            value: {
                                openPrint: true,
                            },
                        })}
                    >
                        Print
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<ExpandMoreIcon />}
                        disabled={loading}
                        size="small"
                        onClick={() => dispatch({
                            type: ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL,
                            value: true,
                        })}
                    >
                        Exp
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<ExpandLessIcon />}
                        disabled={loading}
                        size="small"
                        onClick={() => dispatch({
                            type: ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL,
                            value: false,
                        })}
                    >
                        Col
                    </Button>
                </ReportHeader>
                <ReportContent loading={loading}>
                    {showMainData}
                </ReportContent>
                <ReportFooter
                    onPageChange={(values) => onParamsChange('page', values)}
                    currentPage={params.init}
                    onRowsPerPageChange={(value) => onParamsChange('limit', value)}
                    rowsPerPage={params.limit}
                    totalRecords={table?.totalCount}
                    labelRowsPerPage="Vehicles per page"
                />
            </Container>
            <If condition={printing}>
                <DialogActionMessage message="Printing... " />
            </If>
            {openPrint
            && <ReportPrint onChooseOption={(val) => onPrint(val)} onClose={onClosePringDialog} /> }
        </>
    );
};

export default Recon;

import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

// material
import clsx from 'clsx';
import { styled } from '@material-ui/core/styles';
import {
    Button, Divider, IconButton, Typography,
    makeStyles, Input,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import CloseIcon from '@material-ui/icons/Close';
import Jodit from 'components/widgets/editor/JoditEditor';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import EmailTemplateQuery from 'services/graphQL/query/setting/EmailTemplateQuery';
import { FetchPolicy, HttpMethods } from 'utils/enum/Core';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import useAxios from 'axios-hooks';
import HttpCRMModule from 'services/httpModules/HttpCRMModule';
import { isEmpty } from 'lodash';
import UserSettingQuery from 'services/graphQL/query/setting/UserSettingQuery';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(0, 3),
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footer: {
        padding: theme.spacing(2, 3),
    },
    textArea: {
        border: 'none',
        resize: 'none',
        padding: theme.spacing(2, 3),
        flex: 1,
        '&:focus': {
            outline: 'none',
            borderColor: 'transparent',
            boxShadow: 'none',
        },
    },
    toInput: {
        padding: '4px 24px',
        '& > span[data-placeholder]': {
            position: 'relative',
            left: 0,
            top: 0,
            padding: 0,
            fontSize: '14px',
        },
    },
    disabledTo: {
        '& input': {
            display: 'none !important',
        },
    },
    templateContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5),
    },
    templateSelect: {
        marginLeft: theme.spacing(1),
        width: 300,
    },
    ...ButtonStyles.getStyle(theme),
}));

const initState = {
    record: {
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        bodyMessage: '',
    },
    templateList: [],
    templateSelected: null,
    emailSignature: '',
};

const ACTION_TYPE = {
    SET_EMAIL_LIST: 'setEmailList',
    SET_STATE_VALUES: 'setStateValues',
    SET_EMAIL_TEMPLATE: 'setEmailTemplate',
    SET_EMAIL_SIGNATURE: 'setEmailSignature',
    CHANGE_EMAIL_TEMPLATE: 'changeEmailTemplate',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_STATE_VALUES: {
        return update(state, {
            record: {
                [action.field]: { $set: action.value },
            },
        });
    }
    case ACTION_TYPE.SET_EMAIL_LIST: {
        const result = (action.payload || [])
            .filter((item) => item.language === action.language)
            .map((item) => ({ label: item.templateName, value: item.messageTemplateId, subject: item.templateSubject }));

        return update(state, {
            templateList: { $set: result },
        });
    }
    case ACTION_TYPE.CHANGE_EMAIL_TEMPLATE: {
        return update(state, {
            templateSelected: { $set: action.value },
        });
    }
    case ACTION_TYPE.SET_EMAIL_TEMPLATE: {
        const template = state.templateList.find((item) => item.value === state.templateSelected);
        return update(state, {
            record: {
                bodyMessage: { $set: `${action.value} <br/> ${state.emailSignature}` },
                subject: { $set: template.subject },
            },
        });
    }
    case ACTION_TYPE.SET_EMAIL_SIGNATURE: {
        return update(state, {
            emailSignature: { $set: action.payload },
            record: {
                bodyMessage: { $set: `<br/>${action.payload}` },
            },
        });
    }
    default:
        return state;
    }
};

const InputStyle = styled(Input)(({ theme }) => ({
    padding: theme.spacing(0.5, 3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
}));

const MailForm = ({
    onSendMessage, loading, onClose, email, disabledTo,
    leadCode, showEmailTemplate, prospectLanguage,
}) => {
    const classes = useStyles();
    const [isSelectCC, setCC] = useState(false);
    const [isSelectBCC, setBCC] = useState(false);
    const [state, dispatch] = useReducer(reducer, initState);
    const { record } = state;
    const {
        data: dataSignature,
    } = useQuery(UserSettingQuery.GET_EMAIL_SIGNATURE, { fetchPolicy: FetchPolicy.NETWORK_ONLY });

    const onChangeValue = (value, field) => {
        dispatch({
            type: ACTION_TYPE.SET_STATE_VALUES,
            value,
            field,
        });
    };
    const onChangeEmailTemplate = (value) => {
        dispatch({
            type: ACTION_TYPE.CHANGE_EMAIL_TEMPLATE,
            value,
        });
    };

    const isValidEmail = record.to?.length > 0 && !StringUtils.isEmpty(record.bodyMessage);
    const {
        data: templateList, error: errorTemplateList, loading: loadingTemplateList,
    } = useQuery(EmailTemplateQuery.GET_EMAIL_TEMPLATE_LIST, {
        variables: {
            moduleTemplate: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [
        { loading: loadingGetPreview },
        getPreview,
    ] = useAxios({
        url: HttpCRMModule.getURLEmailTemplatePreview(state.templateSelected, leadCode),
        method: HttpMethods.GET,
    }, { manual: true });

    useEffect(() => {
        if (errorTemplateList) {
            ModalUtils.errorMessage(errorTemplateList?.graphQLErrors);
            return;
        }

        if (!loadingTemplateList && templateList?.getEmailTemplateList) {
            dispatch({
                type: ACTION_TYPE.SET_EMAIL_LIST,
                payload: templateList.getEmailTemplateList,
                language: prospectLanguage,
            });
        }
    }, [templateList, errorTemplateList, loadingTemplateList, prospectLanguage]);

    useEffect(() => {
        if (!StringUtils.isEmpty(email)) {
            onChangeValue([email], 'to');
        }
    }, [email]);

    const onGetPreview = async () => {
        if (!StringUtils.isEmpty(state.templateSelected)) {
            try {
                const response = await getPreview();
                if (response?.data?.Success) {
                    dispatch({
                        type: ACTION_TYPE.SET_EMAIL_TEMPLATE,
                        value: response?.data?.Data,
                    });
                } else {
                    ModalUtils.errorMessage(response?.data?.Messages, response?.data?.Message);
                }
            } catch (error) {
                ModalUtils.errorMessage(error?.response?.data?.errors);
            }
        }
    };

    useEffect(() => {
        if (state.templateSelected) {
            onGetPreview();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.templateSelected]);

    useEffect(() => {
        if (!isEmpty(dataSignature?.getEmailSignature)) {
            dispatch({
                type: ACTION_TYPE.SET_EMAIL_SIGNATURE,
                payload: dataSignature.getEmailSignature?.value,
            });
        }
    }, [dataSignature]);

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h5">New Message</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider />
            <div className="custom-mail">
                <div className="flex-1">
                    <ReactMultiEmail
                        placeholder="To"
                        className={clsx(classes.toInput, { [classes.disabledTo]: disabledTo })}
                        emails={record.to}
                        onChange={(emails) => onChangeValue(emails, 'to')}
                        validateEmail={(value) => isEmail(value)}
                        getLabel={(value, index, removeEmail) => (
                            <div data-tag key={index} className="item">
                                {value}
                                {!disabledTo && (
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                )}
                            </div>
                        )}
                    />
                    {isSelectCC && (
                        <ReactMultiEmail
                            placeholder="Cc"
                            className={clsx(classes.toInput)}
                            emails={record.cc}
                            onChange={(emails) => onChangeValue(emails, 'cc')}
                            validateEmail={(value) => isEmail(value)}
                            getLabel={(value, index, removeEmail) => (
                                <div data-tag key={index} className="item">
                                    {value}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            )}
                        />
                    )}
                    {isSelectBCC && (
                        <ReactMultiEmail
                            placeholder="Bcc"
                            className={clsx(classes.toInput)}
                            emails={record.bcc}
                            onChange={(emails) => onChangeValue(emails, 'bcc')}
                            validateEmail={(value) => isEmail(value)}
                            getLabel={(value, index, removeEmail) => (
                                <div data-tag key={index} className="item">
                                    {value}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            )}
                        />
                    )}
                </div>
                <div className="container-copy">
                    {!isSelectCC && <span className="copy" onClick={() => setCC(true)}>CC</span>}
                    {!isSelectBCC && <span className="copy" onClick={() => setBCC(true)}>BCC</span>}
                </div>
            </div>
            <InputStyle disableUnderline placeholder="Subject" onChange={(e) => onChangeValue(e.target.value, 'subject')} value={record.subject} />
            {showEmailTemplate && (
                <div className={classes.templateContainer}>
                    <Typography>Template:</Typography>
                    <Select
                        size="sm"
                        name="templateSelected"
                        options={state.templateList}
                        disabled={loadingGetPreview}
                        value={state.templateSelected}
                        className={classes.templateSelect}
                        onChange={(_, value) => onChangeEmailTemplate(value)}
                    />
                </div>
            )}
            <Jodit
                value={record.bodyMessage}
                onChange={(newContent) => onChangeValue(newContent, 'bodyMessage')}
            />
            <Divider />
            <div className={classes.footer}>
                <Button
                    className={classes.containedInfo}
                    variant="contained"
                    onClick={() => onSendMessage(record)}
                    disabled={loading || !isValidEmail || loadingGetPreview}
                >
                    Send
                </Button>
            </div>
        </>
    );
};

MailForm.propTypes = {
    email: PropTypes.string,
    loading: PropTypes.bool,
    leadCode: PropTypes.number,
    disabledTo: PropTypes.bool,
    showEmailTemplate: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    prospectLanguage: PropTypes.string,
};

MailForm.defaultProps = {
    email: null,
    loading: false,
    leadCode: null,
    disabledTo: false,
    showEmailTemplate: false,
    prospectLanguage: 'English',
};

export default MailForm;

import NumberUtils from 'lib/NumberUtils';

export default class JournalDetailMap {
    static glLines(record) {
        if (record) {
            return record.map((data) => (
                {
                    entryId: data.entryId,
                    cbId: data.cbId,
                    accountNumber: data.accountCOA.accountNumber,
                    accountDescription: `${data.accountCOA.accountNumber} - ${data.accountCOA.description}`,
                    accountType: data.accountCOA.type,
                    debit: NumberUtils.round(data.debit),
                    credit: NumberUtils.round(data.credit),
                    amount: NumberUtils.round(data.debit + data.credit),
                    controlNumber: data.controlNumber,
                    referenceNumber: data.referenceNumber,
                    lotName: data?.lot?.lotName,
                    memo: data.memo,
                    postDate: data.postDate,
                    glType: data.glType,
                }
            ));
        }

        return [];
    }
}

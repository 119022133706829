import React, {
    useReducer, useRef, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, useTheme, useMediaQuery,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Split from 'react-split';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import CloseIcon from '@material-ui/icons/Close';
import { SaveIcon } from 'components/icons';
import JournalDistribution from 'components/modules/accounting/journal/list/JournalDistribution';
import { Col, Form } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema, getErrorMessage } from 'utils/schema/utils';
import DepositSchema from 'utils/schema/accounting/banking/DepositSchema';
import PropTypes from 'prop-types';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy, ProcessTypeDescription } from 'utils/enum/Core';
import AccountingCBMapper from 'services/mapData/AccountingCBMapper';
import {
    BankingProcessTypes, AccountingSubModules,
    ProcessType, AccountingCBStatus, AccountingCOAType, TransactionType, ErrorCodes,
} from 'utils/enum/AccountingEnum';
import LotQuery from 'services/graphQL/query/LotQuery';
import UserContext from 'components/context/UserContext';
import CheckDepositReducer, { ACTION_TYPES } from 'components/modules/accounting/banking/reducer/CheckDepositReducer';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import useValidateActions from 'components/modules/accounting/banking/hooks/useValidateActions';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import NumberUtils from 'lib/NumberUtils';
import ProcessFiles from 'components/widgets/processFiles/processFiles';
import { isEmpty } from 'lodash';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const keyStore = new KeyStore();

const DepositCreate = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id } = useParams();
    const history = useHistory();
    const { comeFromBatchOut, onCloseDeposit, depositData } = props;

    const ACCOUNTING_BANKING_DEPOSIT_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_DEPOSIT_WRITE);

    const initialState = {
        record: {
            cbId: 0,
            bankAccount: 0,
            depositNumber: 0,
            isACH: false,
            date: new Date(),
            amount: 0,
            endingBalance: 0,
            memo: '',
            lotId: 0,
            lotName: '',
            status: AccountingCBStatus.NEW,
            processTypeId: 0,
        },
        isDirty: false,
        couldLostData: false,
        showDialogDuplicateDeposit: false,
        showDepositPopup: true,
        isDirtyFiles: false,
    };

    const classes = useStyle();
    const journalRef = useRef();
    const filesRef = useRef();

    const [state, dispatch] = useReducer(CheckDepositReducer, initialState);
    const {
        record, isDirty, couldLostData, showDialogDuplicateDeposit,
        showDepositPopup, isDirtyFiles,
    } = state;

    const isValidData = isValidSchema(DepositSchema, record);
    const { isValid, errors } = isValidData;

    const { userInformation } = useContext(UserContext);
    const defaultLot = userInformation?.defaultLot || userInformation?.usersLot;
    const lots = userInformation?.lots ?? [];

    if (lots && record.lotId === 0) {
        const lotItem = lots.find((item) => item.lotName === defaultLot);
        const lotId = lotItem?.lotId || 0;
        if (lotId > 0) dispatch({ type: ACTION_TYPES.SET_DEFAULT_LOT, value: lotId });
    }

    const {
        editBankAccount,
        editLot,
        editMemo,
        editDate,
        allowSaveAction,
        glOptions,
        editACH,
    } = useValidateActions(record.status, record.processTypeId);

    const [getEndingBalance] = useLazyQuery(AccountingQuery.GET_ENDING_BALANCE, {
        onCompleted: (res) => {
            if (res.getEndingBalance) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        endingBalance: res.getEndingBalance.result,
                    },
                });
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getDeposit, { loading }] = useLazyQuery(AccountingQuery.GET_ACCOUNTING_DEPOSIT_CB, {
        onCompleted: (res) => {
            if (res.getDeposit) {
                const result = AccountingCBMapper.mapAccountingCB(res.getDeposit);
                result.isACH = result.type.toLowerCase().indexOf(BankingProcessTypes.ACH.toLowerCase()) >= 0;

                getEndingBalance({
                    variables: {
                        bankAccount: Number(result.bankAccount),
                    },
                });

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: { ...result },
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getLastDeposit] = useLazyQuery(AccountingQuery.GET_LAST_DEPOSIT, {
        onCompleted: (res) => {
            if (res.getLastDeposit) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        depositNumber: res.getLastDeposit.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getDefaultBankSettings] = useLazyQuery(AccountingQuery.GET_BANKING_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getBankingDefaultSettings) {
                const { defaultBankChecks } = res.getBankingDefaultSettings;

                const currentRecord = record;
                currentRecord.bankAccount = Number(defaultBankChecks);

                dispatch({ type: ACTION_TYPES.SET_RECORD, value: currentRecord });

                getLastDeposit({
                    variables: {
                        bankAccount: Number(currentRecord.bankAccount),
                    },
                });

                getEndingBalance({
                    variables: {
                        bankAccount: Number(currentRecord.bankAccount),
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = (saveSuccess = false) => {
        if (record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()
        && record.processTypeId === ProcessType.BATCH_OUT) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showDepositPopup: false,
                },
                fireAction: (record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()
                        && record.processTypeId === ProcessType.BATCH_OUT) ? onCloseDeposit : null,
                fireActionParam: saveSuccess,
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showDepositPopup: false,
                    isDirty: false,
                },
            });

            history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}`);
        }
    };

    const onPopupClose = (event, forceClose = false) => {
        // if not force to close just only clear the isDirty variable
        if ((isDirty || isDirtyFiles) && !forceClose) {
            dispatch({ type: ACTION_TYPES.SET_ON_POPUP_CLOSE });
            return;
        }

        // if you force to close clear the form and close it
        onClearForm();
        onCloseAction();
    };

    const isDuplicated = (data) => !isEmpty(data.filter((item) => item.extensions && item.extensions.code === ErrorCodes.DUPLICATE));

    const createQuery = comeFromBatchOut ? AccountingMutation.CREATE_UPDATE_DEPOSIT_BATCH_OUT : AccountingMutation.CREATE_UPDATE_DEPOSIT;
    const [addRecord, { loading: isSaving }] = useMutation(createQuery, {
        onCompleted: (mutationData) => {
            const aliasMethod = comeFromBatchOut ? 'createUpdateDepositBatchOut' : 'createUpdateDeposit';
            if (mutationData[aliasMethod] > 0) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: ({ graphQLErrors }) => {
            if (isDuplicated(graphQLErrors)) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: { key: 'showDialogDuplicateDeposit', value: true },
                });
            } else {
                ModalUtils.errorMessage(graphQLErrors);
            }
        },
    });

    const onSave = async (requestedNew) => {
        let resultSave = false;
        let recordToSave = { ...record };

        if (!isDirty && filesRef.current.getIsDirty()) {
            const resultUploadFiles = await filesRef.current.startToUpload();

            resultSave = resultUploadFiles;
        } else {
            const { records, errors: glLinesErrors } = journalRef.current.getRecords();

            if (records.length === 0) {
                ModalUtils.errorMessage(null, 'The GL lines are empty');
                return;
            }

            if (glLinesErrors.length > 0) {
                ModalUtils.errorMessage(glLinesErrors);
                return;
            }

            recordToSave.accountingLines = records;

            if (comeFromBatchOut) recordToSave.batchOutData = depositData.batchOutData;

            const linesSumAmount = records.reduce((sum, next) => sum + next.amount, 0);
            if (NumberUtils.round(record.amount) !== NumberUtils.round(linesSumAmount)) {
                ModalUtils.errorMessage([{ message: 'The sum of the lines must be equals to the total amount of the deposit.' }]);
                return;
            }

            recordToSave = AccountingCBMapper.mapDepositToSave(recordToSave);

            const resultAfterSave = await addRecord({
                variables: {
                    record: recordToSave,
                },
            });

            const aliasMethod = comeFromBatchOut ? 'createUpdateDepositBatchOut' : 'createUpdateDeposit';
            const createdCbId = resultAfterSave?.data[aliasMethod];
            resultSave = createdCbId > 0;

            if (resultSave) {
                const resultUploadFiles = await filesRef.current.startToUpload(createdCbId);
                resultSave = resultUploadFiles;

                if (!resultSave && record.cbId === 0) {
                    dispatch({
                        type: ACTION_TYPES.SET_RECORD,
                        value: {
                            ...record,
                            cbId: createdCbId,
                        },
                        isDirty: false,
                    });
                }
            }
        }

        if (resultSave) {
            if (!requestedNew) onCloseAction(true);
            else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW_DEPOSIT,
                    value: initialState,
                });

                journalRef.current.clear();

                filesRef.current.clear();

                getLastDeposit({
                    variables: {
                        bankAccount: Number(recordToSave.bankAccount),
                    },
                });

                getEndingBalance({
                    variables: {
                        bankAccount: Number(recordToSave.bankAccount),
                    },
                });
            }
        }
    };

    const onChangeValue = async (field, value) => {
        if (value === record[field]) return;

        if (field === 'amount') {
            dispatch({
                type: ACTION_TYPES.SET_AMOUNT,
                value,
            });
            return;
        }

        const currentRecord = { ...record };

        currentRecord[field] = value;

        if (field === 'bankAccount') {
            if (currentRecord.cbId === 0) {
                getLastDeposit({
                    variables: {
                        bankAccount: Number(value),
                    },
                });
            }

            getEndingBalance({
                variables: {
                    bankAccount: Number(value),
                },
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    useEffect(() => {
        if (id > 0) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: {
                    ...record,
                    cbId: Number(id),
                },
            });

            getDeposit({
                variables: {
                    cbId: Number(id),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (depositData) {
            record.bankAccount = depositData.debitBankAccount;
            record.memo = depositData.memo;
            record.processTypeId = ProcessType.BATCH_OUT;

            if (record.bankAccount > 0) {
                getLastDeposit({
                    variables: {
                        bankAccount: Number(record.bankAccount),
                    },
                });
                getEndingBalance({
                    variables: {
                        bankAccount: Number(record.bankAccount),
                    },
                });
            }

            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: record,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depositData]);

    useEffect(() => {
        if (comeFromBatchOut) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showDepositPopup: comeFromBatchOut,
                    record: {
                        ...record,
                        processTypeId: comeFromBatchOut ? ProcessType.BATCH_OUT : 0,
                    },
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comeFromBatchOut]);

    useEffect(() => {
        if (!comeFromBatchOut && (!id || Number(id) === 0)) getDefaultBankSettings();
    }, [comeFromBatchOut, getDefaultBankSettings, id]);

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record?.status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record?.status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
        else if (record?.status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase()) badgeStatus = classes.badgeCleared;
        else if (record?.status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()) badgeStatus = classes.badgePrinted;

        return (
            <div className={classes.margin5}>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </div>
        );
    };

    const enableBtnSave = (isValid && isDirty
    && allowSaveAction
    && !isSaving
    ) || (
        allowSaveAction
        && !isSaving
        && isDirtyFiles
    );

    const onDuplicateClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_VALUE,
            value: { key: 'showDialogDuplicateDeposit', value: false },
        });
    };

    const onDuplicateContinue = () => {
        getLastDeposit({
            variables: {
                bankAccount: Number(record.bankAccount),
            },
        });
        onDuplicateClose();
    };

    return (
        <Dialog
            open={showDepositPopup}
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        {(ACCOUNTING_BANKING_DEPOSIT_WRITE || comeFromBatchOut) && (record.status.toLowerCase() !== AccountingCBStatus.VOID.toLowerCase())
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(false)}
                                    >
                                        Save
                                    </Button>
                                )}
                        {(ACCOUNTING_BANKING_DEPOSIT_WRITE && !comeFromBatchOut && allowSaveAction)
                        && (record.status.toLowerCase() === AccountingCBStatus.POSTED.toLowerCase()
                                || record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase())
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(true)}
                                    >
                                        Save & New
                                    </Button>
                                )}
                    </div>
                    <div className={classes.centerItems}>
                        <Typography variant="h6" className={classes.title}>
                            { record.cbId === 0 ? 'New Deposit' : `Edit Deposit ${record.depositNumber}` }
                        </Typography>
                        { getStatusChip() }
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                { loading ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Split
                                sizes={isMobile ? [70, 30] : [35, 65]}
                                className={isMobile ? classes.verticalSplit : 'split'}
                                direction={isMobile ? null : 'horizontal'}
                            >
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={isMobile ? 12 : 5}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Deposit To *</Form.Label>
                                                <DropdownQuery
                                                    name="bankAccount"
                                                    disabled={!editBankAccount}
                                                    className={isValidField(errors, 'bankAccount') ? 'invalid-field' : ''}
                                                    value={record.bankAccount}
                                                    placeHolder="select the account"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                        variables: {
                                                            paginate: {
                                                                init: 0,
                                                                ignoreLimit: true,
                                                            },
                                                            filters: {
                                                                accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                            },
                                                        },
                                                        rootData: 'getAccountingCOAList.data',
                                                        idField: 'accountNumber',
                                                        descriptionField: 'fullDescription',
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Lot</Form.Label>
                                                <DropdownQuery
                                                    name="lotId"
                                                    disabled={!editLot}
                                                    className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                                    value={record.lotId}
                                                    placeHolder="select a lot"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: LotQuery.GET_LOTS,
                                                        rootData: 'lotList',
                                                        idField: 'lotId',
                                                        descriptionField: 'lotName',
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 7}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Deposit #</Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className={isValidField(errors, 'depositNumber') ? 'invalid-field' : ''}
                                                            size="sm"
                                                            type="text"
                                                            value={record.depositNumber}
                                                            placeholder="Deposit #"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Amount</Form.Label>
                                                        <InputNumber
                                                            disabled
                                                            size="sm"
                                                            showCurrency
                                                            allowNegative
                                                            value={record.amount}
                                                            thousandSeparator
                                                            placeholder="Amount"
                                                            onChange={(value) => onChangeValue('amount', value)}
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Date *</Form.Label>
                                                        <DatePicker
                                                            selected={record.date}
                                                            disabled={!editDate}
                                                            name="date"
                                                            size="sm"
                                                            className={clsx('form-control form-control-sm',
                                                                isValidField(errors, 'date') ? 'invalid-field' : '')}
                                                            placeholderText="mm/dd/yyyy"
                                                            popperContainer={CalendarContainer}
                                                            wrapperClassName="col"
                                                            onChange={(date) => onChangeValue('date', date)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label />
                                                        <Form.Check
                                                            id="isDepositACH"
                                                            checked={record.isACH}
                                                            type="checkbox"
                                                            label="IS ACH"
                                                            className="am-mt-8"
                                                            disabled={!editACH}
                                                            onChange={(e) => onChangeValue('isACH', e.target.checked)}
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Ending Balance</Form.Label>
                                                        <InputNumber
                                                            disabled
                                                            size="sm"
                                                            showCurrency
                                                            value={record.endingBalance}
                                                            thousandSeparator
                                                            placeholder="Ending balance"
                                                            allowNegative
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Memo</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={!editMemo}
                                                    className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                                                    size="sm"
                                                    rows="2"
                                                    value={record.memo}
                                                    placeholder="Memo"
                                                    onChange={(e) => onChangeValue('memo', e.target.value)}
                                                />
                                                <p className={classes.fontSmall}>{getErrorMessage('memo', errors)}</p>
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Form.Group as={Col}>
                                            <Form.Label className="required">Files Attachments</Form.Label>
                                            <ProcessFiles
                                                ref={filesRef}
                                                referenceId={Number(record.cbId)}
                                                referenceType={ProcessTypeDescription.DEPOSIT}
                                                notifyDirtyFiles={(val) => dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isDirtyFiles: val,
                                                    },
                                                })}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </div>
                                <JournalDistribution
                                    processRecordId={record.cbId}
                                    processTypeId={ProcessType.DEPOSIT}
                                    writePermissions={ACCOUNTING_BANKING_DEPOSIT_WRITE}
                                    ref={journalRef}
                                    onAmountChange={(value) => onChangeValue('amount', value)}
                                    glLines={depositData?.glLines}
                                    onDirty={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'isDirty', value: true } })}
                                    glOptions={glOptions}
                                    transactionAmount={record.amount}
                                    transactionType={TransactionType.DEBIT}
                                    accountTypesExcluded={[AccountingCOAType.BANK]}
                                />
                            </Split>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                            <ConfirmDialog
                                title="Duplicate deposit number!"
                                description="This deposit number has already been used. It will be changed to continue."
                                open={showDialogDuplicateDeposit}
                                variant="outlined"
                                titlePrimary="OK"
                                titleSecondary="Cancel"
                                onClose={onDuplicateClose}
                                onClickSecondary={onDuplicateClose}
                                onClickPrimary={onDuplicateContinue}
                            />
                        </Container>
                    )}
            </DialogContent>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

DepositCreate.propTypes = {
    comeFromBatchOut: PropTypes.bool,
    onCloseDeposit: PropTypes.func,
    depositData: PropTypes.object,
};

DepositCreate.defaultProps = {
    comeFromBatchOut: false,
    onCloseDeposit: () => {},
    depositData: null,
};

export default DepositCreate;

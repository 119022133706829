import PropTypes from 'prop-types';

const If = (props) => {
    const { condition } = props;

    if (typeof condition === 'boolean' && condition) {
        return props.children;
    }

    return null;
};

If.propTypes = {
    condition: PropTypes.bool.isRequired,
};

export default If;

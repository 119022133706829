import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CRMUtils from 'utils/CRMUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { Row, Form } from 'react-bootstrap';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useFunnelStage from 'components/hook/crm/useFunnelStage';
import { funnelStages } from 'components/modules/crm/enum/Opportunity';
import useIntervalExecutor from 'components/hook/core/useIntervalExecutor';

const useStyles = makeStyles((theme) => ({
    stages: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: '13px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
        '& > div': {
            width: '100%',
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& > div, & > div > div': {
                margin: 0,
                flexWrap: 'nowrap',
                '& > label': {
                    marginBottom: 0,
                    marginRight: '5px',
                    fontWeight: 'bold',
                },
                '& > span': {
                    padding: 0,
                    '& svg': {
                        width: '20px',
                        height: '20px',
                    },
                },
            },
        },
    },
    timeOnLot: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        fontSize: '14px',
        '& > span:nth-child(1)': {
            fontWeight: 'bold',
            marginRight: '10px',
            color: theme.palette.text.butterflyBush,
        },
    },
}));

const ProcessStage = ({ data = {}, disabled }) => {
    const classes = useStyles();
    const [isUpdatingStage, setIsUpdatingStage] = useState(false);
    const [timeOnLot, setTimeOnLot] = useState(0);
    const { updateFunnelStage } = useFunnelStage();

    const onUpdate = async (name, value) => {
        if (isUpdatingStage || disabled) return;
        const isSettingCustomerLeft = name === 'prospectLeftDate';

        setIsUpdatingStage(true);
        if (isSettingCustomerLeft) await updateFunnelStage(data.crmId, funnelStages.SHOWED, value, true);
        if (!isSettingCustomerLeft) await updateFunnelStage(data.crmId, name, value);
        setIsUpdatingStage(false);
    };

    const { showed = false, showedDate, prospectLeftDate } = data;
    useIntervalExecutor(() => CRMUtils.calculateTimeOnLot(prospectLeftDate, showedDate), 60, setTimeOnLot);
    return (
        <>
            <div className={classes.stages}>
                <div>
                    {Object.values(funnelStages).filter((s) => s !== funnelStages.SOLD).map((stage, index) => (
                        <div key={index}>
                            <Form.Group as={Row}>
                                <Form.Label
                                    className={classes.dialogLabels}
                                >
                                    {StringUtils.toPascalCase(stage)}
                                </Form.Label>
                                <Checkbox
                                    value="selected"
                                    name={stage}
                                    checked={data[stage] ?? false}
                                    onChange={(e) => onUpdate(e.target.name, e.target.checked)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            </Form.Group>
                            {stage === funnelStages.SHOWED && data[stage] && showedDate && (
                                <div>
                                    {DateUtils.getFormattedDateInUserTimezone(showedDate, DateFormat.TIME_AMPM)}
                                </div>
                            )}
                        </div>
                    ))}
                    <div>
                        <Form.Group as={Row}>
                            <Form.Label
                                className={classes.dialogLabels}
                            >
                                Customer Left
                            </Form.Label>
                            <Checkbox
                                disabled={!showed}
                                value="selected"
                                name="prospectLeftDate"
                                checked={showed && !StringUtils.isEmpty(prospectLeftDate)}
                                onChange={(e) => onUpdate(e.target.name, e.target.checked)}
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        </Form.Group>
                        {showed && prospectLeftDate && (
                            <div>
                                {DateUtils.getFormattedDateInUserTimezone(prospectLeftDate, DateFormat.TIME_AMPM)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showedDate && (
                <div className={classes.timeOnLot}>
                    <span>Time on lot:</span>
                    <span>{timeOnLot}</span>
                </div>
            )}
        </>
    );
};

ProcessStage.defaultProps = {
    disabled: false,
};

ProcessStage.propTypes = {
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default ProcessStage;

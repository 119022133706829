import React, { Component } from 'react';

const EmploymentContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openManageEmployment: false,
        };

        this.initBind();
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onClose() {
        this.setState({
            open: false,
        });
    }

    onOpenManage() {
        this.setState({
            openManageEmployment: true,
        });
    }

    onCloseManage() {
        this.setState({
            openManageEmployment: false,
        });
    }

    initBind() {
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onOpenManage = this.onOpenManage.bind(this);
        this.onCloseManage = this.onCloseManage.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onOpen={this.onOpen}
                onClose={this.onClose}
                onOpenManage={this.onOpenManage}
                onCloseManage={this.onCloseManage}
            />
        );
    }
};

export default EmploymentContainer;

import { isEmpty } from 'lodash';
import StringUtils from 'lib/StringUtils';
import PROVIDER from 'utils/enum/MenuEnum';

class ProductSettingMap {
    static mapProductSettingToSave(record) {
        if (!isEmpty(record)) {
            const sendDetails = [PROVIDER.STONE_EAGLE, PROVIDER.FI_EXPRESS, PROVIDER.DEALER_PROVIDED].includes(record.provider);

            return {
                vendorId: record.vendorId,
                productName: record.productName,
                productPackage: record.productPackage || '',
                productType: record.productType,
                productActive: record.productActive,
                imported: record.imported || false,
                isLine1: record.isLine1 || false,
                menuProductDetail: sendDetails ? {
                    providerProductId: record.providerProductId > 0 ? record.providerProductId : null,
                    providerProductCode: !StringUtils.isEmpty(record.productCode) ? record.productCode : null,
                    description: !StringUtils.isEmpty(record.description) ? record.description : null,
                    bulletPoints: !StringUtils.isEmpty(record.bulletPoints) ? record.bulletPoints : null,
                    isTaxable: record.isTaxable,
                    notRegulated: record.notRegulated,
                    pdfContract: !StringUtils.isEmpty(record.contract) ? record.contract : null,
                    terms: record.terms.length > 0 ? record.terms : null,
                } : null,
            };
        }

        return {};
    }

    static mapProductSettingToUpdate(record, selectedProductId) {
        if (!isEmpty(record) && selectedProductId === record.productId) {
            const sendDetails = [PROVIDER.STONE_EAGLE, PROVIDER.FI_EXPRESS, PROVIDER.DEALER_PROVIDED].includes(record.provider);

            return {
                id: selectedProductId,
                input: {
                    vendorId: record.vendorId,
                    productName: record.productName,
                    productPackage: record.productPackage || '',
                    productType: record.productType,
                    productActive: record.productActive,
                    imported: record.imported || false,
                    isLine1: record.isLine1 || false,
                    menuProductDetail: sendDetails ? {
                        ...(record.menuProductDetailId ? { menuProductDetailId: record.menuProductDetailId } : {}),
                        providerProductId: record.providerProductId > 0 ? record.providerProductId : null,
                        providerProductCode: !StringUtils.isEmpty(record.productCode) ? record.productCode : null,
                        description: !StringUtils.isEmpty(record.description) ? record.description : null,
                        bulletPoints: !StringUtils.isEmpty(record.bulletPoints) ? record.bulletPoints : null,
                        isTaxable: record.isTaxable,
                        notRegulated: record.notRegulated,
                        pdfContract: !StringUtils.isEmpty(record.contract) ? record.contract : null,
                        terms: record.terms.length > 0 ? record.terms : null,
                    } : null,
                },
            };
        }

        return {};
    }
}

export default ProductSettingMap;

import React, { Component } from 'react';

// Others
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

const InternalBuyerContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            buyer: {
                customerId: '',
            },
            buyerAddress: [],
            buyerEmployments: [],
            coBuyer: {
                customerId: '',
            },
            loading: false,
            loadingRequest: false,
            coBuyerAddress: [],
            coBuyerEmployments: [],
            openAddressHistory: false,
            openCustomer: false,
            openEditCustomer: false,
            recordToEdit: null,
        };

        this.dealBuyerEditedSubscription = null;
        this.initBind();
    }

    onOpenAddressHistory() {
        this.setState({
            openAddressHistory: true,
        });
    }

    onCloseAddressHistory() {
        this.setState({
            openAddressHistory: false,
        });
    }

    onOpenCustomer() {
        this.setState({
            openCustomer: true,
        });
    }

    onCloseCustomer() {
        this.setState({
            openCustomer: false,
        });
    }

    onDoubleClick(record = {}) {
        const { state: { buyer } } = this;

        if (buyer.customerCode === record.customerCode) {
            ModalUtils.errorMessage([], "The Buyer and Co Buyer can't be the same.");
            return;
        }

        this.setState({
            record,
        });
        this.assignCoBuyer(record.customerId);
    }

    getServicesData() {
        const { props: { accountNumber } } = this;

        this.setState({
            loading: true,
        });

        this.dealService.getBuyer(accountNumber)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setData(data);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    setData(record) {
        const {
            buyer, buyerAddress, buyerEmployments, deal,
            coBuyer, coBuyerAddress, coBuyerEmployments,
        } = record;

        // Buyer map
        const newBuyerEmployments = orderBy(buyerEmployments, ['isCurrentEmployment'], ['desc']);
        const newBuyerAddress = orderBy(buyerAddress, ['isCurrentAddress'], ['desc']);

        // Co Buyer map
        const newCoBuyerEmployments = orderBy(coBuyerEmployments, ['isCurrentEmployment'], ['desc']);
        const newCoBuyerAddress = orderBy(coBuyerAddress, ['isCurrentAddress'], ['desc']);

        this.setState((prevState) => ({
            buyer,
            buyerAddress: update(prevState.buyerAddress, {
                $set: newBuyerAddress,
            }),
            buyerEmployments: update(prevState.buyerEmployments, {
                $set: newBuyerEmployments,
            }),
            coBuyer,
            coBuyerAddress: update(prevState.coBuyerAddress, {
                $set: newCoBuyerAddress,
            }),
            coBuyerEmployments: update(prevState.coBuyerEmployments, {
                $set: newCoBuyerEmployments,
            }),
            deal: update(prevState.deal, {
                $set: deal,
            }),
        }));
    }

    toggleEditCustomer(record) {
        this.setState((prevState) => ({
            recordToEdit: record,
            openEditCustomer: !prevState.openEditCustomer,
        }));
    }

    assignCoBuyer(customerId) {
        const { props: { accountNumber } } = this;
        const input = {
            dealId: accountNumber,
            customerId,
        };

        this.dealService.assignCoBuyer(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.assignCoBuyer) {
                    this.onCloseCustomer();
                }
            });
    }

    removeCoBuyer() {
        const { props: { accountNumber } } = this;

        this.setState({
            loadingRequest: true,
        });

        this.dealService.removeCoBuyer(accountNumber)
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            })
            .finally(() => {
                this.setState({
                    loadingRequest: false,
                });
            });
    }

    swapBuyerCoBuyer() {
        const { props: { accountNumber } } = this;

        this.setState({
            loadingRequest: true,
        });

        this.dealService.swapBuyerCoBuyer(accountNumber)
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            })
            .finally(() => {
                this.setState({
                    loadingRequest: false,
                });
            });
    }

    subscribeEditBuyer() {
        const { props: { accountNumber } } = this;
        const input = {
            accountNumber,
        };

        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.DEAL_BUYERS_EDITED, input)
            .then((response) => {
                this.dealBuyerEditedSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.dealBuyersEdited) {
            const { dealBuyersEdited } = data;
            this.setData(dealBuyersEdited);
        }
    }

    unsubscribeEditBuyer() {
        if (this.dealBuyerEditedSubscription) {
            this.dealBuyerEditedSubscription.unsubscribe();
        }
    }

    initBind() {
        this.setData = this.setData.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.removeCoBuyer = this.removeCoBuyer.bind(this);
        this.onOpenCustomer = this.onOpenCustomer.bind(this);
        this.onCloseCustomer = this.onCloseCustomer.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.swapBuyerCoBuyer = this.swapBuyerCoBuyer.bind(this);
        this.toggleEditCustomer = this.toggleEditCustomer.bind(this);
        this.subscribeEditBuyer = this.subscribeEditBuyer.bind(this);
        this.onOpenAddressHistory = this.onOpenAddressHistory.bind(this);
        this.unsubscribeEditBuyer = this.unsubscribeEditBuyer.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.onCloseAddressHistory = this.onCloseAddressHistory.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onDoubleClick={this.onDoubleClick}
                removeCoBuyer={this.removeCoBuyer}
                onOpenCustomer={this.onOpenCustomer}
                onCloseCustomer={this.onCloseCustomer}
                getServicesData={this.getServicesData}
                swapBuyerCoBuyer={this.swapBuyerCoBuyer}
                toggleEditCustomer={this.toggleEditCustomer}
                subscribeEditBuyer={this.subscribeEditBuyer}
                onOpenAddressHistory={this.onOpenAddressHistory}
                unsubscribeEditBuyer={this.unsubscribeEditBuyer}
                onCloseAddressHistory={this.onCloseAddressHistory}
            />
        );
    }
};

export default InternalBuyerContainer;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    useQuery, useLazyQuery, useMutation, useApolloClient,
} from '@apollo/client';
import { FetchPolicy, ALL_LOTS, DataSort } from 'utils/enum/Core';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import PMT from 'formula-pmt';
import PROVIDER, {
    PACKAGE,
    PACKAGE_DISTRIBUTION,
    PRICING_SOURCE,
    KNOWN_ERROR,
    MENU_STAGE,
    MENU_CONTRACT_STATUS,
    PRE_RATING_SURCHARGE_DATA_TYPE,
} from 'utils/enum/MenuEnum';
import { DealType } from 'utils/enum/DealEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { ProductTypes } from 'utils/ProductSettingUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import ArrayUtils from 'lib/ArrayUtils';
import MenuHelper from 'utils/MenuHelper';
import {
    Button, fade, Checkbox, Tooltip,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import ButtonStyles from 'styles/theme/Button';
import LotQuery from 'services/graphQL/query/LotQuery';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import MenuQuery from 'services/graphQL/query/menu/MenuQuery';
import MenuMutation from 'services/graphQL/mutate/menu/MenuMutation';
import UserContext from 'components/context/UserContext';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import Select from 'components/widgets/Select';
import DatePicker from 'react-datepicker';
import InputNumber from 'components/widgets/InputNumber';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import UserSettingsForm from 'components/modules/deals/create/menu/UserSettingsForm';
import ProductSettingsForm from 'components/modules/deals/create/menu/ProductSettingsForm';
import ContractsViewer from 'components/modules/deals/read/menu/ContractsViewer';
import HistoryViewer from 'components/modules/deals/read/menu/HistoryViewer';
import MainMenuStyles from 'styles/modules/menu/MainMenuStyles';

// Images
import AutomatrixLogo from 'assets/automatrix-logo-black.png';

// icons
import SettingsIcon from '@material-ui/icons/Settings';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => MainMenuStyles.main(theme));

const MenuMain = () => {
    const keyStore = new KeyStore();
    const hasMenuSettings = keyStore.hasPermission(Permission.MENU_SETTINGS);

    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const history = useHistory();
    const { deal } = useParams();
    const dealNumber = Number(deal || 0);
    const { userInformation } = useContext(UserContext);
    const [state, setState] = useState({
        dealSummary: null,
        dealDetail: null,
        dealProducts: [],
        company: null,
        products: null,
        rates: null,
        preRateSurcharges: [],
        dealContracts: [],
        previewContracts: [],
        userSettings: null,
        isUserSettingsDialogOpen: false,
        isProductSettingsOpen: false,
        isPromptOpen: false,
        promptContent: null,
        isContractsViewerOpen: false,
        isHistoryViewerOpen: false,
        selectedProduct: null,
        selectedProductId: null,
        selectedVendor: null,
        isLoading: true,
        isRerequestingRates: false,
        loadingMessage: 'Loading data. Please wait...',
        selectedPackage: null,
        menuStage: MENU_STAGE.PRESENTATION,
        disclosure: null,
        specialTrims: [],
    });

    const {
        dealSummary,
        dealDetail,
        company,
        products,
        rates,
        dealProducts,
        userSettings,
        isUserSettingsDialogOpen,
        isProductSettingsOpen,
        isPromptOpen,
        promptContent,
        specialTrims,
        isContractsViewerOpen,
        isHistoryViewerOpen,
        selectedProduct,
        selectedProductId,
        selectedVendor,
        isLoading,
        isRerequestingRates,
        loadingMessage,
        selectedPackage,
        menuStage,
        disclosure,
        dealContracts,
        previewContracts,
    } = state;

    const {
        soldDate,
        firstPaymentDue,
        dealType = '',
        cashDownPayment = 0,
        financeAmount = 0,
        taxableAmount = 0,
        balanceDue = 0,
        salesTaxRate = 0,
        interestRate = 0,
        registerOutState = false,
        term = 0,
    } = dealDetail || {};

    const {
        data: dealSummaryData,
        loading: loadingDealSummaryData,
        error: errorLoadingDealSummaryData,
    } = useQuery(DealsQuery.GET_DEAL_SUMMARY, {
        variables: {
            dealId: dealNumber,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: dealDetailData,
        loading: loadingDealDetailData,
        error: errorLoadingDealDetailData,
    } = useQuery(DealsQuery.GET_DEAL, {
        variables: {
            accountNumber: dealNumber,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: dealProductsData,
        loading: loadingDealProductsData,
        error: errorLoadingDealProductsData,
    } = useQuery(DealsQuery.GET_PRODUCTS, {
        variables: {
            dealId: dealNumber,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: contractsData,
        loading: loadingContracts,
        error: errorLoadingContracts,
        refetch: refetchContracts,
    } = useQuery(MenuQuery.PULL_MENU_CONTRACTS, {
        variables: {
            dealNumber,
            sort: {
                field: 'menuContractId',
                dir: DataSort.DESC,
            },
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const {
        data: productsData,
        loading: loadingProductsData,
        error: errorLoadingProductsData,
    } = useQuery(MenuQuery.PULL_ACTIVE_PRODUCTS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: userSettingsData,
        loading: loadingUserSettingsData,
        error: errorLoadingUserSettingsData,
        refetch: refetchSettings,
    } = useQuery(MenuQuery.GET_USER_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: (products || []).length === 0,
    });

    const {
        data: menuSettingsData,
        loading: loadingMenuSettings,
        error: errorLoadingMenuSettings,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.MENU,
            key: [LotDefaultskey.MENU_DISCLOSURE],
            lotName: ALL_LOTS,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [decodeTrims] = useLazyQuery(MenuQuery.DECODE_VEHICLE_TRIMS, {
        onCompleted: (response) => {
            if (response) {
                const trims = response.decodeVehicleTrims;
                setState((prevState) => ({
                    ...prevState,
                    specialTrims: trims,
                }));
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const onChangePreRateSurcharge = (surcharge, value) => {
        setState((prevState) => {
            const clone = cloneDeep(prevState.products);
            const product = clone.find((p) => p.id === prevState.selectedProductId);
            if (product) {
                if (!product.surcharges) product.surcharges = [];
                const sc = product.surcharges.find((item) => item.surchargeCode === surcharge.surchargeCode);

                if (sc) {
                    sc.value = value;
                } else {
                    const {
                        surchargeCode,
                        dataType,
                        surchargeType,
                    } = surcharge;

                    product.surcharges.push({
                        surchargeCode,
                        dataType,
                        value,
                        surchargeType,
                    });
                }
            }

            return {
                ...prevState,
                products: clone,
                // eslint-disable-next-line no-use-before-define
                promptContent: renderPreRateSurcharges(prevState.preRateSurcharges, product),
            };
        });
    };

    const renderPreRateSurcharges = (surcharges, product) => {
        if (!product) return null;

        return (
            <div className={classes.surchargesWrapper}>
                <span>PreRating Surcharges</span>
                {surcharges.map((s, index) => {
                    const val = (product.surcharges || []).find((sc) => sc.surchargeCode === s.surchargeCode)?.value || s.value;
                    const isBoolean = s.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.BOOLEAN;

                    return (
                        <div className={isBoolean ? 'booleanComponent' : ''} key={index}>
                            <span>{`${s.prompt} / ${s.surchargeType}`}</span>
                            {[PRE_RATING_SURCHARGE_DATA_TYPE.DECIMAL, PRE_RATING_SURCHARGE_DATA_TYPE.NUMERIC].includes(s.dataType.toLowerCase()) && (
                                <InputNumber
                                    size="sm"
                                    thousandSeparator
                                    fixedDecimalScale
                                    value={val || 0}
                                    onChange={(value) => onChangePreRateSurcharge(s, value)}
                                />
                            )}
                            {isBoolean && (
                                <Checkbox
                                    disableRipple
                                    name={s.surchargeCode}
                                    checked={val || false}
                                    onChange={(_, value) => onChangePreRateSurcharge(s, value)}
                                />
                            )}
                            {s.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.DATE && (
                                <DatePicker
                                    allowSameDay
                                    selected={val ? new Date(DateUtils.getFormattedDateInUserTimezone(val)) : null}
                                    size="sm"
                                    className={clsx('form-control form-control-sm')}
                                    popperContainer={CalendarContainer}
                                    onChange={(date) => onChangePreRateSurcharge(s, date)}
                                />
                            )}
                            {s.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.STRING && (
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={val || ''}
                                    onChange={(e) => onChangePreRateSurcharge(s, e.target.value)}
                                />
                            )}
                            {s.dataType.toLowerCase() === PRE_RATING_SURCHARGE_DATA_TYPE.ENUM && (
                                <Select
                                    name={s.surchargeCode}
                                    size="sm"
                                    value={val || ''}
                                    options={(s.surchargeOptions || []).map((k) => ({ value: k, label: k }))}
                                    maxMenuHeight={140}
                                    onChange={(_, value) => onChangePreRateSurcharge(s, value)}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const [getPreRate] = useLazyQuery(MenuQuery.GET_PRODUCT_PRERATE, {
        onCompleted: (response) => {
            if (response) {
                setState((prevState) => {
                    const clone = cloneDeep(prevState.products);
                    const product = clone.find((p) => p.id === prevState.selectedProductId);
                    const surcharges = response.getProductPreRate;

                    if (!product.surcharges) product.surcharges = [];
                    (surcharges || []).forEach((x) => {
                        const sc = product.surcharges.find((y) => y.surchargeCode === x.surchargeCode);
                        if (sc && x.value) sc.value = x.value;
                        if (!sc) {
                            product.surcharges.push({
                                surchargeCode: x.surchargeCode,
                                dataType: x.dataType,
                                value: x.value,
                                surchargeType: x.surchargeType,
                            });
                        }
                    });

                    return {
                        ...prevState,
                        products: clone,
                        preRateSurcharges: surcharges,
                        promptContent: surcharges.length > 0 ? renderPreRateSurcharges(surcharges, product) : 'No pre-rate surcharges available',
                    };
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [updateSettings, { loading: updatingSettings }] = useMutation(MenuMutation.SAVE_USER_SETTINGS, {
        onCompleted: (response) => {
            const saved = response.saveUserSettings;
            if (saved) refetchSettings();

            // eslint-disable-next-line no-use-before-define
            toggleUserSettingsDialog();
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingDealProductsData) {
            ModalUtils.errorMessage(errorLoadingDealProductsData?.graphQLErrors);
            return;
        }

        if (!loadingDealProductsData) {
            const data = dealProductsData?.products;
            setState((prevState) => ({
                ...prevState,
                dealProducts: data,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDealProductsData, errorLoadingDealProductsData]);

    useEffect(() => {
        if (errorLoadingContracts) {
            ModalUtils.errorMessage(errorLoadingContracts?.graphQLErrors);
            return;
        }

        if (!loadingContracts) {
            const data = contractsData?.pullMenuContracts;
            setState((prevState) => ({
                ...prevState,
                dealContracts: data,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContracts, errorLoadingContracts]);

    useEffect(() => {
        if (errorLoadingMenuSettings) {
            ModalUtils.errorMessage(errorLoadingMenuSettings?.graphQLErrors);
            return;
        }

        if (!loadingMenuSettings) {
            const data = menuSettingsData?.getSettings;
            setState((prevState) => ({
                ...prevState,
                disclosure: data[0]?.value,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMenuSettings, errorLoadingMenuSettings]);

    useEffect(() => {
        if (errorLoadingDealSummaryData) {
            ModalUtils.errorMessage(errorLoadingDealSummaryData?.graphQLErrors);
            return;
        }

        if (!loadingDealSummaryData) {
            const data = dealSummaryData?.getDealSummary;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    dealSummary: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDealSummaryData, errorLoadingDealSummaryData]);

    useEffect(() => {
        if (errorLoadingDealDetailData) {
            ModalUtils.errorMessage(errorLoadingDealDetailData?.graphQLErrors);
            return;
        }

        if (!loadingDealDetailData) {
            const data = dealDetailData?.getDeal?.deal;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    dealDetail: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDealDetailData, errorLoadingDealDetailData]);

    useEffect(() => {
        if (errorLoadingProductsData) {
            ModalUtils.errorMessage(errorLoadingProductsData?.graphQLErrors);
            return;
        }

        if (!loadingProductsData) {
            const data = productsData?.pullActiveProducts;
            if (ArrayUtils.isNotEmpty(data)) {
                setState((prevState) => ({
                    ...prevState,
                    products: MenuHelper.getProductsSorted(data).map((p) => ({
                        ...p,
                        isDisabled: true,
                        available: true,
                    })),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    loadingMessage: 'No active products found. Check the settings first',
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProductsData, errorLoadingProductsData]);

    useEffect(() => {
        if (errorLoadingUserSettingsData) {
            ModalUtils.errorMessage(errorLoadingUserSettingsData?.graphQLErrors);
            return;
        }

        if (!loadingUserSettingsData) {
            const settings = userSettingsData?.getUserSettings;
            if (settings) {
                setState((prevState) => ({
                    ...prevState,
                    userSettings: settings,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUserSettingsData, errorLoadingUserSettingsData]);

    useEffect(() => {
        if (userSettings) {
            setState((prevState) => ({
                ...prevState,
                products: MenuHelper.sortProductsBySettings(prevState.userSettings, prevState.products),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSettings]);

    useEffect(() => {
        if (userInformation) {
            const { company: companyData } = userInformation;
            if (companyData) setState((prevState) => ({ ...prevState, company: companyData }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        if (rates) {
            setState((prevState) => ({
                ...prevState,
                products: MenuHelper.setDefaultRateProducts(prevState.rates, prevState.products),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rates]);

    const getRates = async (vendorId, trim, product) => {
        try {
            const { data } = await client.query({
                query: product ? MenuQuery.GET_PRODUCT_RATE : MenuQuery.GET_RATES,
                variables: product ? {
                    productId: product.details.providerProductId,
                    dealNumber,
                    surchargesInput: (product.surcharges || [])
                        .filter((sc) => sc.value && !StringUtils.isEmpty(sc.value))
                        .map((sc) => ({ ...sc, value: String(sc.value) })),
                } : {
                    vendorId,
                    dealNumber,
                    trim: trim || dealSummary.vehicle.trim,
                },
                fetchPolicy: FetchPolicy.NETWORK_ONLY,
            });

            const result = product ? data?.getProductRate : data?.getRates;
            if (!ArrayUtils.isNotEmpty(result?.plans || result?.coverages)) {
                return {
                    vendorId,
                    productId: product?.id,
                    rates: null,
                    error: 'No rates available from vendor',
                };
            }

            return {
                vendorId,
                productId: product?.id,
                rates: result,
                error: null,
            };
        } catch (error) {
            return {
                vendorId,
                productId: product?.id,
                rates: null,
                error: error.message,
            };
        }
    };

    useEffect(() => {
        const evaluateLoadingStatus = async () => {
            if (dealSummary && dealDetail && company && products && isLoading) {
                setState((prevState) => ({
                    ...prevState,
                    loadingMessage: 'Getting rates. Please wait...',
                }));

                const stoneEagleProducts = products.filter((p) => p.vendor.provider === PROVIDER.STONE_EAGLE);
                const expressProducts = products.filter((p) => p.vendor.provider === PROVIDER.FI_EXPRESS);
                const vendors = [...(new Set(stoneEagleProducts.map((p) => p.vendor.vendorId)))];

                const result = await Promise.all([
                    ...vendors.map((v) => getRates(v)),
                    ...expressProducts.map((p) => getRates(null, null, p)),
                ]);

                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    rates: MenuHelper.groupProductTypes(result),
                }));
            }
        };

        evaluateLoadingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealSummary, dealDetail, company, products]);

    useEffect(() => {
        if (dealNumber <= 0) history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealNumber]);

    const renderTrimsDropdown = (trim) => (
        <div className={classes.trimSelector}>
            <div>Select the right trim to pull rates</div>
            <div>
                <Select
                    name="trim"
                    size="sm"
                    value={trim}
                    options={specialTrims.map((k) => ({ value: k, label: k }))}
                    maxMenuHeight={140}
                    // eslint-disable-next-line no-use-before-define
                    onChange={(_, value) => onChangeTrim(value)}
                />
            </div>
        </div>
    );

    const onChangeTrim = (trim) => {
        const clone = cloneDeep(rates);
        const vendor = clone.find((r) => r.vendorId === selectedVendor.vendorId);
        if (vendor) vendor.trim = trim;

        setState((prevState) => ({
            ...prevState,
            rates: clone,
            promptContent: renderTrimsDropdown(vendor.trim),
        }));
    };

    useEffect(() => {
        if (specialTrims?.length > 0 && selectedVendor) {
            const first = specialTrims[0];
            const clone = cloneDeep(rates);
            const vendor = clone.find((r) => r.vendorId === selectedVendor.vendorId);
            vendor.trim = first;

            setState((prevState) => ({
                ...prevState,
                rates: clone,
                promptContent: renderTrimsDropdown(vendor.trim),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialTrims]);

    const toggleUserSettingsDialog = () => {
        setState((prevState) => ({
            ...prevState,
            isUserSettingsDialogOpen: !isUserSettingsDialogOpen,
        }));
    };

    const toggleProductSettingsDialog = (product, pack) => {
        setState((prevState) => ({
            ...prevState,
            selectedProduct: product ? { ...cloneDeep(product), pack } : null,
            isProductSettingsOpen: !isProductSettingsOpen,
        }));
    };

    const closeContractsViewerDialog = () => {
        setState((prevState) => ({
            ...prevState,
            isContractsViewerOpen: false,
        }));

        refetchContracts();
    };

    const toggleHistoryViewer = () => {
        setState((prevState) => ({
            ...prevState,
            isHistoryViewerOpen: !isHistoryViewerOpen,
        }));

        if (isHistoryViewerOpen) refetchContracts();
    };

    const getPromptContent = (errorType, vendor, productId) => {
        if (errorType === KNOWN_ERROR.TIMEOUT) return `Do you want to rerequest rates from ${vendor.vendorName}`;
        if (errorType === KNOWN_ERROR.TRIM) {
            decodeTrims({
                variables: {
                    vendorId: vendor.vendorId,
                    dealNumber,
                },
            });

            return 'Getting trims...';
        }

        if (errorType === KNOWN_ERROR.PRE_RATING) {
            const product = products.find((p) => p.id === productId);

            getPreRate({
                variables: {
                    productId: product.details.providerProductId,
                    dealNumber,
                },
            });

            return 'Getting surcharges...';
        }

        return null;
    };

    const togglePrompt = async (execute = false, vendor, errorType, productId) => {
        if (execute) {
            const product = products.find((p) => p.id === selectedProductId);
            const provider = product?.vendor?.provider;
            const isExpress = provider === PROVIDER.FI_EXPRESS;

            setState((prevState) => ({
                ...prevState,
                isRerequestingRates: true,
                selectedVendor: null,
                selectedProductId: null,
                promptContent: null,
                isPromptOpen: false,
                specialTrims: [],
            }));

            let clone = cloneDeep(rates);
            if (isExpress) {
                const response = await getRates(null, null, product);
                clone = clone.map((item) => (item.productId === response?.productId ? MenuHelper.groupProductTypes([response])[0] : item));
            } else {
                const vendorData = rates.find((r) => r.vendorId === selectedVendor.vendorId);
                const response = await getRates(selectedVendor.vendorId, vendorData?.trim);
                clone = clone.map((item) => (item.vendorId === response?.vendorId ? MenuHelper.groupProductTypes([response])[0] : item));
            }

            setState((prevState) => ({
                ...prevState,
                rates: clone,
                products: MenuHelper.setDefaultRateProducts(clone, prevState.products, selectedProductId),
                isRerequestingRates: false,
            }));

            return;
        }

        setState((prevState) => ({
            ...prevState,
            selectedVendor: vendor,
            selectedProductId: productId,
            promptContent: errorType ? getPromptContent(errorType, vendor, productId) : null,
            isPromptOpen: !isPromptOpen,
            specialTrims: [],
        }));
    };

    const pullRatesWithSurcharges = async (product, pack) => {
        setState((prevState) => ({
            ...prevState,
            isRerequestingRates: true,
            isProductSettingsOpen: false,
        }));

        const productsClone = cloneDeep(products);
        const current = productsClone.find((p) => p.id === product.id);
        if (current) current.surcharges = product.surcharges;

        let ratesClone = cloneDeep(rates);
        const response = await getRates(null, null, product);
        ratesClone = ratesClone.map((item) => (item.productId === response?.productId ? MenuHelper.groupProductTypes([response])[0] : item));
        if (response.error) current.isDisabled = true;

        const lowestRateProducts = MenuHelper.setDefaultRateProducts(ratesClone, productsClone, product.id);
        const ratedProduct = lowestRateProducts.find((p) => p.id === product.id);
        setState((prevState) => ({
            ...prevState,
            isRerequestingRates: false,
            rates: ratesClone,
            products: lowestRateProducts,
            ...(!response.error ? {
                selectedProduct: { ...ratedProduct, pack },
                isProductSettingsOpen: true,
            } : {}),
        }));
    };

    const updatePricing = (product) => {
        const { id, pack, currentOptions } = product;
        const packOptions = currentOptions[pack];
        const { sellingPrice, retailRate, otherCharges } = packOptions;
        if (sellingPrice < retailRate + otherCharges) {
            ModalUtils.errorMessage(null, 'The Selling Price cannot be lower than the Base Retail Price plus surcharges and options.');
            return;
        }

        setState((prevState) => {
            const clone = cloneDeep(prevState.products);
            const currentProduct = clone.find((p) => p.id === id);

            if (currentProduct) {
                const currentProductOptions = currentProduct.currentOptions;
                currentProductOptions[pack] = {
                    ...packOptions,
                    replicateInAllPackages: false,
                    includedInPayment: currentProductOptions[pack].includedInPayment,
                };

                if (packOptions.replicateInAllPackages) {
                    const packs = Object.keys(currentProductOptions);
                    packs.forEach((pk) => {
                        if (pk !== pack) {
                            currentProductOptions[pk] = {
                                ...currentProductOptions[pack],
                                includedInPayment: currentProductOptions[pk].includedInPayment,
                            };
                        }
                    });
                }
            }

            return {
                ...prevState,
                products: clone,
                selectedProduct: null,
                isProductSettingsOpen: false,
            };
        });
    };

    const onSave = (
        pricingVisibility,
        packageDistribution,
        saveSettings,
        innerProducts,
    ) => {
        const isThereAvailableProducts = innerProducts.some((p) => p.available);
        if (!isThereAvailableProducts) {
            ModalUtils.errorMessage(null, 'At least the availability of one product is required');
            return;
        }

        const settings = { ...(userSettings || {}) };
        settings.pricingVisibility = pricingVisibility;
        settings.packageDistribution = packageDistribution;
        settings.saveSettings = saveSettings;
        settings.products = innerProducts.map((item, index) => {
            const current = (settings.products || []).find((x) => x.productId === item.id);

            return {
                ...(current ? { id: current.id } : {}),
                productId: item.id,
                available: item.available,
                order: index + 1,
            };
        });

        if (saveSettings) {
            updateSettings({
                variables: settings,
            });
        } else if (userSettings?.id) {
            updateSettings({
                variables: { ...userSettings, saveSettings },
            });
        } else {
            toggleUserSettingsDialog();
        }

        setState((prevState) => ({
            ...prevState,
            userSettings: settings,
        }));
    };

    const onChange = (field, value, productId, pack) => {
        if (field === 'selectedPackage') {
            const offeredProducts = products.some((p) => p.available && !p.isDisabled);
            if (!offeredProducts) {
                ModalUtils.errorMessage(null, 'There are no products to offer. Check the package');
                return;
            }
        }

        setState((prevState) => {
            if (!productId && !pack) {
                return {
                    ...prevState,
                    selectedPackage: !selectedPackage ? value : (selectedPackage !== value ? value : null),
                };
            }

            const clone = cloneDeep(prevState.products);
            const product = clone.find((p) => p.id === productId);
            if (product) {
                product.currentOptions[pack][field] = value;

                const type = product.productType;
                if (field === 'includedInPayment' && type !== ProductTypes.PRODUCT) {
                    clone.forEach((p) => {
                        if (p.productType === type && p.id !== productId && p.currentOptions) p.currentOptions[pack][field] = false;
                    });
                }
            }

            return {
                ...prevState,
                products: clone,
            };
        });
    };

    const goNextStage = (rejectAll) => {
        setState((prevState) => ({
            ...prevState,
            products: prevState.products.map((p) => {
                if (rejectAll && p.currentOptions) p.currentOptions[selectedPackage].includedInPayment = false;
                return p;
            }),
            menuStage: MENU_STAGE.CONTRACT,
        }));
    };

    const loadContracts = (input) => {
        setState((prevState) => ({
            ...prevState,
            previewContracts: input,
            isContractsViewerOpen: true,
        }));
    };

    const goBack = () => {
        setState((prevState) => ({
            ...prevState,
            menuStage: MENU_STAGE.PRESENTATION,
        }));
    };

    const calculateProductCost = (price = 0, product) => {
        const fixedRateForOutState = 7;
        const { details: { isTaxable } } = product;
        const amountFinanced = isTaxable ? (price * (1 + ((registerOutState ? fixedRateForOutState : salesTaxRate) / 100))) : price;
        let monthly = amountFinanced <= 0 ? 0 : PMT((interestRate / 100) / 12, term, -amountFinanced);
        // eslint-disable-next-line no-restricted-globals
        if (monthly === Infinity || isNaN(monthly)) monthly = 0;

        return {
            monthly,
            daily: monthly / 30,
        };
    };

    const getCorrectFinanceAmount = () => {
        switch (dealType.toUpperCase()) {
        case DealType.LHPH:
        case DealType.LEASE:
            return balanceDue;
        case DealType.CASH:
            return taxableAmount;
        default:
            return financeAmount;
        }
    };

    const calculateBasePayment = () => {
        const averageDaysPerMonth = 28.71;
        const firstPaymentDaysDifference = DateUtils.diff(firstPaymentDue, soldDate);
        const totalInProducts = (dealProducts || []).reduce((a, b) => a + b.price, 0);
        const amountFinanced = getCorrectFinanceAmount();
        const baseAmountFinanced = amountFinanced - totalInProducts;

        if (baseAmountFinanced > 0 && term > 0) {
            const additionalInterestAmount = ((baseAmountFinanced * (interestRate / 100)) / 365) * (firstPaymentDaysDifference - averageDaysPerMonth);
            return PMT((interestRate / 100) / 12, term, -(baseAmountFinanced + additionalInterestAmount));
        }

        return 0;
    };

    const isContractStage = menuStage === MENU_STAGE.CONTRACT;
    const renderProduct = (
        index,
        product,
        currentOptions,
        isIncludedInPayment,
        priceVisibility,
        errorManager,
        pack,
        contract,
        isRejected = false,
    ) => {
        const cost = calculateProductCost(currentOptions?.sellingPrice, product);
        return (
            <div key={index} className={clsx('product', 'nonBreakable', product.isDisabled ? 'printingHiddenElement' : '')}>
                <div>
                    <div className={product.isDisabled ? 'name disabled' : 'name'}>
                        {isIncludedInPayment == null && (
                            <Checkbox
                                disabled
                                checked
                            />
                        )}
                        {isIncludedInPayment != null && (
                            <Checkbox
                                disabled={product.isDisabled}
                                checked={isIncludedInPayment}
                                onChange={(_, value) => onChange('includedInPayment', value, product.id, pack)}
                            />
                        )}
                        {`${product.productName} - ${product.vendor.vendorName}`}
                    </div>
                    {!product.isDisabled && currentOptions && (
                        <div className="term">
                            {Number(currentOptions.mileage) > 0 && (
                                `${currentOptions.term} Months / ${NumberUtils.applyThousandsFormat(currentOptions.mileage)} Miles`
                            )}
                            {Number(currentOptions.mileage) <= 0 && (
                                `${currentOptions.term} Months`
                            )}
                        </div>
                    )}
                    <div className={clsx('extra', 'printingHiddenElement')}>
                        {`${product.productType} / ${product.vendor.provider}`}
                    </div>
                    <div className="description">
                        {product.details.description}
                    </div>
                    <div
                        className="points"
                        dangerouslySetInnerHTML={{ __html: product.details.bulletPoints }}
                    />
                </div>
                <div>
                    <div className="pricing">
                        {
                            !product.isDisabled
                                && currentOptions
                                && priceVisibility !== PRICING_SOURCE.NO_PRICES
                                && (
                                    <>
                                        {priceVisibility === PRICING_SOURCE.SHOW_TOTAL_PRICE && (
                                            <div>{NumberUtils.applyCurrencyFormat(currentOptions.sellingPrice)}</div>
                                        )}
                                        {priceVisibility === PRICING_SOURCE.SHOW_COST_DETAILS && (
                                            <>
                                                <div>{`Monthly: ${NumberUtils.applyCurrencyFormat(cost.monthly)}`}</div>
                                                <div>{`Daily: ${NumberUtils.applyCurrencyFormat(cost.daily)}`}</div>
                                            </>
                                        )}
                                    </>
                                )
                        }
                    </div>
                    <div className="productActions">
                        <Button
                            disabled={product.isDisabled}
                            disableRipple
                            startIcon={<SettingsIcon />}
                            size="small"
                            onClick={() => toggleProductSettingsDialog(product, pack)}
                        />
                        {isContractStage && (!isRejected || (isRejected && contract)) && (
                            <Button
                                className={contract ? 'contractInPlace' : ''}
                                disableRipple
                                startIcon={<DescriptionOutlinedIcon />}
                                size="small"
                                onClick={() => loadContracts([{ product, contract }])}
                            />
                        )}
                        {product.isDisabled && errorManager && (
                            <Tooltip title={errorManager.message} placement="top">
                                <span>
                                    <Button
                                        disabled={
                                            errorManager.type == null
                                                || ![
                                                    KNOWN_ERROR.TIMEOUT,
                                                    KNOWN_ERROR.TRIM,
                                                    KNOWN_ERROR.PRE_RATING,
                                                ].includes(errorManager.type)
                                        }
                                        disableRipple
                                        startIcon={<WarningOutlinedIcon />}
                                        size="small"
                                        onClick={() => togglePrompt(false, product.vendor, errorManager.type, product.id)}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const {
        buyer,
        vehicle,
    } = dealSummary || {};

    const {
        firstName,
        lastName,
    } = buyer || {};

    const {
        year,
        make,
        model,
        miles,
    } = vehicle || {};

    let additionalPayment = 0;
    let updatedPayment = 0;
    const productsToLoadContracts = [];
    const priceVisibility = userSettings?.pricingVisibility || PRICING_SOURCE.SHOW_TOTAL_PRICE;
    const distribution = userSettings?.packageDistribution || PACKAGE_DISTRIBUTION.PARTIAL_FILL;
    const basePayment = calculateBasePayment();
    return (
        <div id="menuParent" className={classes.parent}>
            {(isLoading || isRerequestingRates) && (
                <div className={classes.loader}>
                    <div>
                        {loadingMessage}
                    </div>
                </div>
            )}
            <div className={classes.header}>
                <div className={classes.logo}>
                    {company && (
                        <img src={company.logo} alt="Company Logo" />
                    )}
                </div>
                <div className={clsx(classes.actions, 'printingHiddenElement')}>
                    {isContractStage && (
                        <Button
                            disableRipple
                            startIcon={<ArrowBackOutlinedIcon />}
                            size="small"
                            onClick={goBack}
                        />
                    )}
                    {hasMenuSettings && !isContractStage && (
                        <Button
                            disableRipple
                            startIcon={<SettingsIcon />}
                            size="small"
                            onClick={toggleUserSettingsDialog}
                        />
                    )}
                    <Button
                        disableRipple
                        startIcon={<ListAltOutlinedIcon />}
                        size="small"
                        onClick={toggleHistoryViewer}
                    />
                    <Button
                        disableRipple
                        startIcon={<PrintOutlinedIcon />}
                        size="small"
                        onClick={() => MenuHelper.printMenu('menuParent')}
                    />
                </div>
            </div>
            <div className={classes.info}>
                <div>
                    <div className="row base">
                        <div>Base Payment:</div>
                        <div>{NumberUtils.applyCurrencyFormat(basePayment)}</div>
                    </div>
                    <div className="row">
                        <div>Down Payment:</div>
                        <div>{NumberUtils.applyCurrencyFormat(cashDownPayment)}</div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div>Prepared For:</div>
                        <div>{`${firstName} ${lastName}`}</div>
                    </div>
                    <div className="row">
                        <div>Vehicle:</div>
                        <div>{`${year} ${make} ${model}`}</div>
                    </div>
                    <div className="row">
                        <div>Mileage:</div>
                        <div>{NumberUtils.applyThousandsFormat(miles)}</div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div>Amount Financed:</div>
                        <div>{NumberUtils.applyCurrencyFormat(getCorrectFinanceAmount())}</div>
                    </div>
                    <div className="row">
                        <div>ARP:</div>
                        <div>{`${interestRate}%`}</div>
                    </div>
                    <div className="row">
                        <div>Term:</div>
                        <div>{term}</div>
                    </div>
                </div>
            </div>
            <div className={classes.packages}>
                {isContractStage && (
                    <>
                        <div>
                            <div className={clsx('title', 'acceptedProducts')}>Products Accepted</div>
                            <div className="products">
                                {products.filter((product) => product.available).map((product, productIndex, array) => {
                                    if (
                                        distribution === PACKAGE_DISTRIBUTION.PARTIAL_FILL
                                        && productIndex >= (array.length - Object.keys(PACKAGE).findIndex((pack) => pack === selectedPackage.toUpperCase()))
                                    ) {
                                        return null;
                                    }

                                    const currentOptions = product.currentOptions?.[selectedPackage];
                                    if (!product.isDisabled && currentOptions?.includedInPayment) {
                                        const contract = dealContracts
                                            .find((c) => c.dealNumber === dealNumber
                                                && c.status === MENU_CONTRACT_STATUS.GENERATED
                                                && DateUtils.isSameOrAfter(c.expirationDate, new Date())
                                                && c.product.productId === product.id);
                                        updatedPayment += calculateProductCost(currentOptions.sellingPrice, product).monthly;
                                        productsToLoadContracts.push({ product, contract });

                                        return renderProduct(
                                            productIndex,
                                            product,
                                            currentOptions,
                                            true,
                                            priceVisibility,
                                            null,
                                            selectedPackage,
                                            contract,
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                            <div className={clsx('footer', 'acceptedFooterWrapper')}>
                                <div>
                                    <div>Estimated Payment:</div>
                                    <div>{NumberUtils.applyCurrencyFormat(basePayment + updatedPayment)}</div>
                                </div>
                                <div>
                                    <div>Initials:</div>
                                    <div />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={clsx('title', 'rejectedProducts')}>Products Rejected</div>
                            <div className="products">
                                {products.filter((product) => product.available).map((product, productIndex, array) => {
                                    if (
                                        distribution === PACKAGE_DISTRIBUTION.PARTIAL_FILL
                                        && productIndex >= (array.length - Object.keys(PACKAGE).findIndex((pack) => pack === selectedPackage.toUpperCase()))
                                    ) {
                                        return null;
                                    }

                                    const currentOptions = product.currentOptions?.[selectedPackage];
                                    if (!product.isDisabled && currentOptions?.includedInPayment === false) {
                                        const contract = dealContracts
                                            .find((c) => c.dealNumber === dealNumber
                                                && c.status === MENU_CONTRACT_STATUS.GENERATED
                                                && DateUtils.isSameOrAfter(c.expirationDate, new Date())
                                                && c.product.productId === product.id);
                                        additionalPayment += calculateProductCost(currentOptions.sellingPrice, product).monthly;

                                        return renderProduct(
                                            productIndex,
                                            product,
                                            currentOptions,
                                            false,
                                            priceVisibility,
                                            null,
                                            selectedPackage,
                                            contract,
                                            true,
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                            <div className={clsx('footer', 'rejectedFooterWrapper')}>
                                <div>
                                    <div>Cost Per Day:</div>
                                    <div>{NumberUtils.applyCurrencyFormat(additionalPayment / 30)}</div>
                                </div>
                                <div>
                                    <div>Additional Payment:</div>
                                    <div>{NumberUtils.applyCurrencyFormat(additionalPayment)}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={clsx('title', 'disclosure')}>Disclosure</div>
                            <div dangerouslySetInnerHTML={{ __html: disclosure }} className="disclosureWrapper" />
                            <div className={clsx('footer', 'disclosureFooterWrapper')}>
                                <div>
                                    <div>Buyer:</div>
                                    <div />
                                    <div>Date:</div>
                                    <div />
                                </div>
                                <div>
                                    <div>Co-Buyer:</div>
                                    <div />
                                    <div>Date:</div>
                                    <div />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!isContractStage && Object.values(PACKAGE).map((p, packageIndex) => {
                    let packageProducts = (products || []).filter((product) => product.available);
                    if (distribution === PACKAGE_DISTRIBUTION.PARTIAL_FILL) {
                        packageProducts = packageProducts.slice(0, packageProducts.length - packageIndex);
                    }

                    const menuMonthlyPayment = packageProducts
                        .filter((item) => item.currentOptions?.[p]?.includedInPayment || false)
                        .map((item) => calculateProductCost(item.currentOptions?.[p]?.sellingPrice || 0, item))
                        .reduce((a, b) => a + b.monthly, 0);

                    const monthlyPayment = basePayment + menuMonthlyPayment;
                    const costPerDay = monthlyPayment / 30;

                    const isPackageSelected = selectedPackage === p;
                    const isChooseButtonDisabled = packageProducts.filter((x) => !x.isDisabled).length === 0;
                    return (
                        <div key={packageIndex}>
                            <div style={{ backgroundColor: MenuHelper.getPackageColor(p) }} className="title">{p}</div>
                            <div className="products">
                                {packageProducts.map((product, productIndex) => {
                                    const currentOptions = product.currentOptions?.[p];
                                    const isIncludedInPayment = currentOptions?.includedInPayment;
                                    const errorManager = MenuHelper.formatErrorMessage(product.error);

                                    return renderProduct(
                                        productIndex,
                                        product,
                                        currentOptions,
                                        isIncludedInPayment,
                                        priceVisibility,
                                        errorManager,
                                        p,
                                    );
                                })}
                            </div>
                            <div
                                style={{ backgroundColor: isPackageSelected ? MenuHelper.getPackageColor(p) : 'initial' }}
                                className={isPackageSelected ? 'summary packageSelected' : 'summary'}
                            >
                                <div>{`Monthly Payment: ${NumberUtils.applyCurrencyFormat(monthlyPayment)}`}</div>
                                <div>{`Cost Per Day: ${NumberUtils.applyCurrencyFormat(costPerDay)}`}</div>
                                <Button
                                    disabled={isChooseButtonDisabled}
                                    style={{ backgroundColor: isChooseButtonDisabled ? MenuHelper.getPackageColor('None') : MenuHelper.getPackageColor(p) }}
                                    size="small"
                                    onClick={() => onChange('selectedPackage', p)}
                                >
                                    CHOOSE
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={clsx('signature', 'nonBreakable')}>
                <div>
                    {!isContractStage && (
                        <>
                            <div>Buyer</div>
                            <div>Co-Buyer</div>
                        </>
                    )}
                </div>
                <div>
                    Powered By
                    <img alt="Automatrix Logo" src={AutomatrixLogo} />
                </div>
            </div>
            {!isContractStage && (
                <>
                    <div className={clsx(classes.commands, 'printingHiddenElement')}>
                        <Button
                            disabled={!selectedPackage}
                            className={classes.containedInfo}
                            onClick={() => goNextStage(false)}
                        >
                            Next
                        </Button>
                        <Button
                            disabled={!selectedPackage}
                            className={classes.containedInfo}
                            onClick={() => goNextStage(true)}
                        >
                            Reject All
                        </Button>
                    </div>
                </>
            )}
            {isContractStage && (
                <div className={clsx(classes.commands, 'printingHiddenElement')}>
                    <Button
                        disabled={productsToLoadContracts.length === 0}
                        className={classes.containedInfo}
                        onClick={() => loadContracts(productsToLoadContracts)}
                    >
                        Generate Contracts
                    </Button>
                </div>
            )}
            {isUserSettingsDialogOpen && (
                <UserSettingsForm
                    userSettings={userSettings}
                    products={products}
                    onClose={toggleUserSettingsDialog}
                    onSave={onSave}
                    onSort={MenuHelper.sortProductsBySettings}
                    updatingSettings={updatingSettings}
                />
            )}
            {isProductSettingsOpen && (
                <ProductSettingsForm
                    menuStage={menuStage}
                    rates={rates}
                    selectedProduct={selectedProduct}
                    updatePricing={updatePricing}
                    onClose={toggleProductSettingsDialog}
                    pullRatesWithSurcharges={pullRatesWithSurcharges}
                />
            )}
            {isContractsViewerOpen && (
                <ContractsViewer
                    rates={rates}
                    dealNumber={dealNumber}
                    previewContracts={previewContracts}
                    selectedPackage={selectedPackage}
                    onClose={closeContractsViewerDialog}
                />
            )}
            {isHistoryViewerOpen && (
                <HistoryViewer onClose={toggleHistoryViewer} />
            )}
            <ConfirmDialog
                title="Attention!"
                {...(typeof promptContent === 'string' ? { description: promptContent } : { children: promptContent })}
                open={isPromptOpen}
                variant="outlined"
                titlePrimary="Continue"
                titleSecondary="Cancel"
                onClose={() => togglePrompt()}
                onClickSecondary={() => togglePrompt()}
                onClickPrimary={() => togglePrompt(true)}
            />
        </div>
    );
};

export default MenuMain;

import React, { useState, useEffect } from 'react';
import StringUtils from 'lib/StringUtils';
import { useQuery, useMutation } from '@apollo/client';

// material
import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// bootstrap
import { Col, Form } from 'react-bootstrap';

// Icons
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

// utils
import ModalUtils from 'utils/ModalUtils';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy } from 'utils/enum/Core';

// service
import EntityQuery from 'services/graphQL/query/setting/EntityQuery';
import EntityMutation from 'services/graphQL/mutate/setting/EntityMutation';

const useStyles = makeStyles((theme) => ({
    labels: {
        fontSize: '0.875rem',
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
        margin: '15px 0px 15px 20px',
    },
    inline: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: '0.75rem',
        marginTop: '5px',
    },
    label: {
        minWidth: '140px',
        marginTop: '5px',
    },
    leftSpace: {
        marginLeft: '0.75rem',
    },
    border: {
        margin: '4px',
        paddingTop: '10px',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
    },
    fees: {
        [theme.breakpoints.down('sm')]: {
            width: '97.5%',
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '800px',
            },
        },
    },
}));

const initRecords = [
    {
        entityMetadataId: '',
        property: 'No Pay Option 1',
        entityTable: 'DealPaymentTable',
        label: '',
        displayed: false,
    },
    {
        entityMetadataId: '',
        property: 'No Pay Option 2',
        entityTable: 'DealPaymentTable',
        label: '',
        displayed: false,
    },
    {
        entityMetadataId: '',
        property: 'No Pay Option 3',
        entityTable: 'DealPaymentTable',
        label: '',
        displayed: false,
    },
    {
        entityMetadataId: '',
        property: 'No Pay Option 4',
        entityTable: 'DealPaymentTable',
        label: '',
        displayed: false,
    },
];

const NoPayOptionSettings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const ACCOUNTING_SETTINGS_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE);

    // Records
    const [records, setRecords] = useState(initRecords);

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(EntityQuery.GET_ENTITY_METADATA, {
        variables: {
            property: initRecords.map((record) => record.property),
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getEntityMetadata)) {
            const { getEntityMetadata } = data;
            // Assigning the values in the default No Pay Options
            const mergedRecords = records.map((record) => {
                const dbRecord = getEntityMetadata.find((qr) => qr.property === record.property);

                return dbRecord ? { ...record, ...dbRecord } : record;
            });
            setRecords(mergedRecords);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    const onChangeValue = (key, field, value) => {
        setRecords((prev) => {
            const index = prev.findIndex((item) => item.property === key);
            if (index !== -1) {
                const updatedRecords = [...prev];
                const updatedItem = {
                    ...updatedRecords[index],
                    [field]: value,
                };

                // If there is no pay option text, set displayed to false
                if (field === 'label' && StringUtils.isEmpty(value)) {
                    updatedItem.displayed = false;
                }

                updatedRecords[index] = updatedItem;
                return updatedRecords;
            }
            return prev;
        });
    };

    // update
    const [saveNoPayOptions, { loading: updating }] = useMutation(EntityMutation.SAVE_ENTITY_DATA, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveEntityMetadata) {
                ModalUtils.successMessage(null, 'saved successfully');
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = () => {
        const input = records?.filter((x) => !StringUtils.isEmpty(x.label))?.map((x) => ({
            entityMetadataId: x.entityMetadataId, entityTable: x.entityTable, property: x.property, label: x.label, displayed: x.displayed,
        }));
        saveNoPayOptions({ variables: { records: input } });
    };

    const components = records.map((x, index) => (
        <div key={index}>
            <Form.Group as={Col} className={classes.inline}>
                <Form.Label className={classes.label}>{x.property}</Form.Label>
                <Form.Control
                    value={x.label}
                    type="text"
                    onChange={(e) => onChangeValue(x.property, 'label', e.target.value)}
                />
                <Form.Check
                    className={classes.leftSpace}
                    disabled={StringUtils.isEmpty(x.label)}
                    onChange={(e) => onChangeValue(x.property, 'displayed', e.target.checked)}
                    checked={x.displayed}
                    inline
                    label="Display"
                />
            </Form.Group>
        </div>
    ));

    return (
        <div>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>No Pay Option Settings</Form.Label>
                <Divider />
                {components}
            </Form.Group>
            <div className={classes.buttonSpacing}>
                {ACCOUNTING_SETTINGS_WRITE && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<SaveOutlinedIcon />}
                            size="small"
                            disabled={updating}
                            onClick={onSave}
                        >
                            {updating ? 'Saving...' : 'Save'}
                            {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default NoPayOptionSettings;

const Scope = Object.freeze({
    CRM_SETTING_TAG_WRITE: 'crm.setting.tag:write',
    CRM_SETTING_TAG_READ: 'crm.setting.tag:read',
    CRM_OPPORTUNITY_TAG_WRITE: 'crm.opportunity.tag:write',
    CRM_SETTING_LOST_REASON_READ: 'crm.setting.lost_reason:read',
    CRM_SETTING_LOST_REASON_WRITE: 'crm.setting.lost_reason:write',
    CRM_SETTING_LEAD_SOURCES_READ: 'crm.settings.lead_sources:read',
    CRM_SETTING_LEAD_SOURCES_WRITE: 'crm.settings.lead_sources:write',
});

export default Scope;

export default class CompanySettingsStyles {
    static listStyles(theme = {}) {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flex: 1,
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '& > button:nth-child(1)': {
                        marginBottom: '5px',
                    },
                    '& > div:nth-child(2)': {
                        marginBottom: '5px',
                        width: '177px',
                    },
                },
            },
            box: {
                display: 'flex',
                overflow: 'auto',
                marginBottom: theme.spacing(2),
                '& > div > div': {
                    '& > label, & > input, & > div > input, & > div > label, & > div.select-bootstrap': {
                        fontSize: '13px',
                    },
                },
            },
            button: {
                color: theme.palette.text.white,
                width: '95px',
                marginRight: '16px',
            },
            saveButton: {
                width: '140px',
                backgroundColor: theme.palette.secondaryInfo.main,
                color: theme.palette.secondaryInfo.contrastText,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: theme.palette.secondaryInfo.dark,
                    boxShadow: 'none',
                },
            },
            editButton: {
                width: '140px',
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            removeButton: {
                width: '140px',
                backgroundColor: '#E97C87',
                '&:hover': {
                    backgroundColor: '#D6727C',
                },
            },
            content: {
                height: '100%',
                flex: 1,
                overflow: 'hidden',
            },
            checkedGreen: {
                color: theme.palette.success.main,
            },
            alignCheckBox: {
                marginBottom: '10px',
            },
            companyImage: {
                padding: '10px',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
            },
            uploader: {
                display: 'none',
            },
            dragzone: {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '150px',
            },
            themeLink: {
                cursor: 'pointer',
                textDecoration: 'underline',
                marginLeft: theme.spacing(2),
                color: theme.palette.text.infoDark,
            },
        });
    }
}

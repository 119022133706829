import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import DealService from 'services/modules/DealService';
import { DealSection, DealTabsTitle } from 'utils/enum/DealEnum';
import ErrorMessages from 'utils/enum/ErrorMessages';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';

const DealRecapContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        onChangeEditingMode: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            open: false,
            dealRecap: {},
            openedCommissionsDialog: false,
        };
        this.initBind();
    }

    onOpenWeOwe() {
        const { props: { onChangeEditingMode } } = this;
        this.setState({
            open: true,
        });

        onChangeEditingMode(true, DealSection.DEAL_RECAP, DealTabsTitle.DEAL);
    }

    onCloseWeOwe() {
        const { state: { open }, props: { onChangeEditingMode } } = this;

        this.setState({
            open: !open,
        });
        onChangeEditingMode();
    }

    getServicesData() {
        const { accountNumber } = this.props;
        this.setState({
            loading: true,
        });

        this.dealService.getDealRecap({ dealId: accountNumber })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    if (graphQLErrors?.some((x) => !x.message.includes(ErrorMessages.LOT_ACCESS_DENIED))) {
                        ModalUtils.errorMessage(graphQLErrors);
                    }
                    return;
                }

                if (data) {
                    this.setData(data);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    setData(data) {
        this.setState((prevState) => ({
            dealRecap: update(prevState.dealRecap, { $set: data }),
        }));
    }

    updateCommisions() {
        this.hideCommissionsDialog();
    }

    showCommissionsDialog() {
        const { props: { onChangeEditingMode } } = this;
        this.setState({
            openedCommissionsDialog: true,
        });

        onChangeEditingMode(true, DealSection.DEAL_RECAP, DealTabsTitle.DEAL);
    }

    hideCommissionsDialog() {
        const { props: { onChangeEditingMode } } = this;

        this.setState({
            openedCommissionsDialog: false,
        });

        onChangeEditingMode();
    }

    subscribeDealRecapUpdated() {
        const { props: { accountNumber } } = this;
        const input = {
            dealId: accountNumber,
        };

        this.dealService.dealRecapSubscribe(this.responseSubscription, input)
            .then((response) => {
                this.dealRecapUpdatedSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;
        if (data) {
            this.setData(data);
        }
    }

    unsubscribeDealRecapUpdated() {
        if (this.dealRecapUpdatedSubscription) {
            this.dealRecapUpdatedSubscription.unsubscribe();
        }
    }

    initBind() {
        this.setData = this.setData.bind(this);
        this.onOpenWeOwe = this.onOpenWeOwe.bind(this);
        this.onCloseWeOwe = this.onCloseWeOwe.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.updateCommisions = this.updateCommisions.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.showCommissionsDialog = this.showCommissionsDialog.bind(this);
        this.hideCommissionsDialog = this.hideCommissionsDialog.bind(this);
        this.subscribeDealRecapUpdated = this.subscribeDealRecapUpdated.bind(this);
        this.unsubscribeDealRecapUpdated = this.unsubscribeDealRecapUpdated.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onCloseWeOwe={this.onCloseWeOwe}
                onOpenWeOwe={this.onOpenWeOwe}
                getServicesData={this.getServicesData}
                updateCommisions={this.updateCommisions}
                showCommissionsDialog={this.showCommissionsDialog}
                hideCommissionsDialog={this.hideCommissionsDialog}
                subscribeDealRecapUpdated={this.subscribeDealRecapUpdated}
                unsubscribeDealRecapUpdated={this.unsubscribeDealRecapUpdated}
            />
        );
    }
};

export default DealRecapContainer;

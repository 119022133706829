import React, { useState } from 'react';

import clsx from 'clsx';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import Container from 'components/widgets/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import PayrollMappings from 'components/modules/settings/payroll/PayrollMappings';
import PayrollDefaultSettings from 'components/modules/settings/payroll/PayrollDefaultSettings';
import PayrollAdditionsDeductions from 'components/modules/settings/payroll/PayrollAdditionsDeductions';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const ownStyles = makeStyles(() => ({
    container: {
        overflow: 'auto',
    },
}));
const subTabs = ['default', 'payroll-mappings', 'additions-deductions'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    if (keyStore.hasPermission(Permission.PAYROLL_SETTINGS_READ)) {
        settings.push(
            {
                index: 0,
                text: 'Default Settings',
                icon: <TuneOutlinedIcon />,
                component: <PayrollDefaultSettings />,
            },
        );
    }
    if (keyStore.hasPermission(Permission.PAYROLL_MAPPINGS_READ)) {
        settings.push(
            {
                index: settings.length,
                text: 'Payroll Mappings',
                icon: <LocalAtmOutlinedIcon />,
                component: <PayrollMappings />,
            },
        );
    }
    if (keyStore.hasPermission(Permission.PAYROLL_ADJUSTMENTS_READ)) {
        settings.push(
            {
                index: settings.length,
                text: 'Additions and Deductions',
                icon: <ExposureOutlinedIcon />,
                component: <PayrollAdditionsDeductions />,
            },
        );
    }

    return settings;
};

const PayrollSettings = () => {
    const classes = { ...useStyles(), ...ownStyles() };
    const history = useHistory();
    const { maintab, subtab } = useParams();

    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            {settingsItems.map((item) => (
                activeIndex === item.index && <div className={clsx(classes.content, classes.container)} key={item.index}>{item.component}</div>
            ))}
        </Container>
    );
};

export default PayrollSettings;

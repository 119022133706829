import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import UserQuery from 'services/graphQL/query/UserQuery';

const keyStore = new KeyStore();
const useSalespersonsByLots = (props = {}) => {
    const [salespersons, setSalespersons] = useState([]);
    const lots = (props.lots && props.lots.length > 0) ? props.lots : keyStore.getUserLots().map((c) => c.lotId);

    const { data, loading, error } = useQuery(UserQuery.GET_SALESPERSONS_BY_LOTS, {
        variables: { lots },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            const { getSalespersonsByLots } = data;
            const result = getSalespersonsByLots.map((item) => ({ value: item.userId, label: `${item.firstName} ${item.lastName}` }));
            setSalespersons([{ label: 'Unassigned', value: null }, ...result]);
        }
    }, [data, loading, error]);

    return {
        salespersons,
    };
};

export default useSalespersonsByLots;

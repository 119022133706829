/* eslint-disable import/prefer-default-export */
const Category = Object.freeze({
    ACCOUNTING: 'Accounting',
    BHPH: 'BHPH',
    DEALS: 'Deals',
    CRM: 'CRM',
    INVENTORY: 'Inventory',
    MENU: 'Menu',
    HR: 'HR',
    AI: 'AI',
});

export const LotDefaultskey = Object.freeze({
    SPLIT_AMOUNT_BETWEEN_LOTS: 'SplitAmountsBetweenLots',
    CASHIER_MAPPING_DEALS_CREDIT: 'Cashier Mapping Deals Credit',
    CASHIER_MAPPING_SERVICE_CREDIT: 'Cashier Mapping Service Credit',
    CASHIER_MAPPING_OTHER_CREDIT: 'Cashier Mapping Other Credit',
    CASHIER_MAPPING_DEBIT: 'Cashier Mapping Debit',
    CASHIER_MAPPING_CUSTOM_FIELD: 'CashierMappingCustomField',
    DATE_LOCK: 'DateLock',
    CASHIER_MAPPING_PARTS_CREDIT: 'Cashier Mapping Parts Credit',
    MENU_DISCLOSURE: 'Menu Disclosure',
    FI_GLOBAL_DEALER_ID: 'F&I Global Dealer Id',
    COMBINE_ACCOUNTING: 'combineAccounting',
    AUTOMATED_RESPONSE_DEFAULT_REPRESENTATIVE: 'Automated Response Default Rep',
    AUTOMATED_RESPONSE_START_TIME: 'Automated Response Start Time',
    AUTOMATED_RESPONSE_END_TIME: 'Automated Response End Time',
    AUTOMATED_RESPONSE_AVAILABILITY_ENABLED: 'Automated Response Availability Enabled',
    POST_VEHICLE_ACCOUNTING: 'postVehicleAccounting',
    AUTOMATIC_TRANSFER_TO_RFC: 'automaticTransferToRFC',
    AVAILABLE_FINANCE_COMPANIES: 'AvailableFinanceCompanies',
    PUSH_NOTIFICATION_REMINDER_INTERVAL: 'pushNotificationReminderInterval',
    LATE_PUSH_NOTIFICATION_USERS: 'latePushNotificationUsers',
    LATE_PUSH_NOTIFICATION_DELAY: 'latePushNotificationDelay',
    PUSH_NOTIFICATION_START_TIME: 'pushNotificationStartTime',
    PUSH_NOTIFICATION_END_TIME: 'pushNotificationEndTime',
    PUSH_NOTIFICATION_ON_SATURDAY: 'pushNotificationOnSaturday',
    PUSH_NOTIFICATION_ON_SUNDAY: 'pushNotificationOnSunday',
    POST_ROS_TO_ACCOUNTING: 'postROsToAccounting',
    AI_ENABLED_FOR_VEHICLE_DESCRIPTION: 'aiEnabledForVehicleDescription',
    AI_ASSISTANT_FOR_VEHICLE_DESCRIPTION: 'aiAssistantForVehicleDescription',
    FOLLOW_UP_START_TIME: 'Follow-Up Start Time',
    FOLLOW_UP_END_TIME: 'Follow-Up End Time',
    FOLLOW_UP_AVAILABILITY_ENABLED: 'Follow-Up Availability Enabled',
    OPTOUT_MESSAGE_BEHAVIOR: 'Optout Message Behavior',
    AI_ENABLED_FOR_CHAT_COMPLETION: 'aiEnabledForChatCompletion',
    AI_ASSISTANT_FOR_CHAT_COMPLETION: 'aiAssistantForChatCompletion',
    DEAL_TRADE_LOT: 'dealTradeLot',
});

export default Category;

import React, {
    useEffect, useReducer, forwardRef, useRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import ProcessFilesQuery from 'services/graphQL/query/ProcessFilesQuery';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Dropzone from 'react-dropzone';
import { AddCircleOutlineIcon, DeleteOutlineOutlinedIcon, InboxOutlinedIcon } from 'components/icons';
import clsx from 'clsx';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { v1 as uuid } from 'uuid';

const buttonStyles = makeStyles((theme) => AccountingStyles.buttonStyles(theme));
const ownStyle = makeStyles((theme) => ({
    dragzone: {
        cursor: 'pointer',
        border: `1px dashed ${theme.palette.border.alto}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 10px',
        transition: 'border-color .3s',
        '& > svg': {
            fill: theme.palette.border.ghost,
            width: '20px',
            height: '20px',
        },
    },
    uploadText: {
        color: theme.palette.text.darkShark,
        margin: '0 10px 0 0',
        fontSize: '14px',
    },
    noData: {
        textAlign: 'center',
        fontSize: 14,
        border: `1px solid ${theme.palette.border.alto}`,
        marginTop: '10px',
        padding: '10px',
    },
    mainDiv: {
        width: '100%',
    },
    table: {
        maxHeight: '280px',
        overflow: 'auto',
    },
    btnUploadFiles: {
        float: 'right',
        marginTop: '10px',
    },
}));

const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    SET_ON_DELETE: 'setOnDelete',
    SET_STATE_VALUES: 'setStateValues',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
    CLEAR_LINES: 'clearLines',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS: {
        return {
            ...state,
            files: action.value,
            documentId: action?.newDocumentId ?? state.documentId,
            isDirtyFiles: action.value.filter((c) => c?.documentId < 0).length > 0,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
            isDirtyFiles: state.files.filter((c) => c?.documentId < 0).length > 0,
        };
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.files.filter((c) => c.documentId !== action.value);

        return {
            ...state,
            files: newArr,
            idToDelete: 0,
            isModalDeleteOpen: false,
            isDirtyFiles: newArr.filter((c) => c?.documentId < 0).length > 0,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
            isDirtyFiles: state.files.filter((c) => c?.documentId < 0).length > 0,
        };
    }
    case ACTION_TYPES.CLEAR_LINES:
    {
        return {
            ...state,
            files: [],
            documentId: -1,
            isModalDeleteOpen: false,
            idToDelete: 0,
        };
    }
    default:
        return state;
    }
};

const ProcessFiles = forwardRef((props, ref) => {
    const {
        referenceType, referenceId, isAllowToSave, notifyDirtyFiles,
    } = props;

    const mountedRef = useRef(true);

    const classes = {
        ...ownStyle(),
        ...buttonStyles(),
    };

    const initialState = {
        files: [],
        isModalDeleteOpen: false,
        idToDelete: 0,
        documentId: 0,
        isDirtyFiles: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        files, isModalDeleteOpen, idToDelete, documentId, isDirtyFiles,
    } = state;

    const [getFiles, { loading }] = useLazyQuery(ProcessFilesQuery.GET_FILES_BY_PROCESS, {
        onCompleted: (res) => {
            const result = res?.getDocumentByReference;
            if (result) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORDS,
                    value: result,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [uploadFile, { loading: isUploading }] = useMutation(ProcessFilesQuery.UPLOAD_FILES);

    const onUploadFile = async (recorIdParam) => {
        if (files.filter((c) => c?.documentId < 0).length === 0) return true;

        const filesToUpload = files.filter((c) => c?.fileToUpload !== undefined && c.documentId < 0);
        const oldFiles = files.filter((c) => c?.fileToUpload === undefined && c.documentId > 0);

        if (filesToUpload.length > 0) {
            const promises = filesToUpload.map((file) => {
                const currentFl = { ...file };

                return uploadFile({
                    variables: {
                        file: currentFl.fileToUpload,
                        fileName: currentFl.documentName,
                        referenceId: referenceId > 0 ? referenceId : recorIdParam,
                        referenceType,
                    },
                })
                    .then((res) => {
                        const {
                            documentId: docId,
                            referenceId: refId,
                            referenceType: refType,
                            documentUrl,
                        } = res?.data?.uploadFilesByReference;

                        if (documentUrl) {
                            currentFl.documentId = docId;
                            currentFl.documentUrl = documentUrl;
                            currentFl.referenceId = refId;
                            currentFl.referenceType = refType;
                            delete currentFl.fileToUpload;
                        }

                        return currentFl;
                    }).catch(() => currentFl);
            });

            const resultFiles = await Promise.all(promises);
            const newFiles = [
                ...oldFiles,
                ...resultFiles,
            ];

            if (newFiles.filter((c) => c?.documentId < 0).length !== 0) { ModalUtils.errorMessage(null, 'Some files couldn\'t upload'); }

            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                value: newFiles,
            });

            return newFiles.filter((c) => c?.documentId < 0).length === 0;
        }

        return files.filter((c) => c?.documentId < 0).length === 0;
    };

    const onSelectFiles = async (items) => {
        const currentFiles = [...files];
        let cIndex = documentId;
        items.forEach((file) => {
            cIndex += -1;
            const newguid = uuid().split('-')[0];

            currentFiles.push({
                documentName: `${newguid}-${file.name}`,
                documentId: cIndex,
                fileToUpload: file,
            });
        });

        await dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: currentFiles,
            newDocumentId: cIndex,
        });

        notifyDirtyFiles(true);
    };

    const [deleteFile, { loading: isDeleting }] = useMutation(ProcessFilesQuery.DELETE_DOCUMENT, {
        onCompleted: (mutationData) => {
            if (mutationData.deleteFilesByReference) {
                dispatch({
                    type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
                    value: idToDelete,
                });

                notifyDirtyFiles(state.files.filter((c) => c?.documentId < 0).length > 0);
            } else {
                ModalUtils.errorMessage(null, 'The file could not be deleted.');
            }
        },
        onError: (errorMessage) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idToDelete: 0,
                    isModalDeleteOpen: false,
                },
            });

            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
    });

    const onDeleteFile = (item) => {
        if (item.documentId < 0) {
            dispatch({
                type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
                value: item.documentId,
            });
        } else { dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: item.documentId }); }
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = async () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                isModalDeleteOpen: false,
            },
        });

        deleteFile({
            variables: {
                documentId: idToDelete,
                referenceId,
                referenceType,
            },
        });
    };

    useImperativeHandle(ref, () => ({
        async startToUpload(recordId) {
            const resultUploadFiles = await onUploadFile(recordId);
            return resultUploadFiles;
        },
        clear() {
            dispatch({ type: ACTION_TYPES.CLEAR_LINES });
        },
        getIsDirty() {
            return isDirtyFiles;
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [files, isDirtyFiles]);

    useEffect(() => {
        getFiles({
            variables: {
                referenceId,
                referenceType,
            },
        });

        mountedRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pendingToUpload = files.filter((c) => c?.documentId < 0).length > 0;

    return (
        <div className={classes.mainDiv}>
            <Dropzone
                disabled={loading || isUploading || isDeleting}
                multiple
                onDrop={onSelectFiles}
                className={classes.dragzone}
            >
                <p className={classes.uploadText}>
                    Click or drag file to this area to upload
                </p>
                <InboxOutlinedIcon />
            </Dropzone>
            {files.length === 0 && (
                <div className={classes.noData}>
                    No files are available
                </div>
            )}
            { files.length > 0 && (
                <TableContainer className={classes.table} component={Paper}>
                    <Table>
                        <TableBody>
                            {files.map((file, index) => (
                                <TableRow key={`file-${index}`}>
                                    <TableCell variant="body" size="small" align="right">
                                        <Tooltip title="Delete File">
                                            <DeleteOutlineOutlinedIcon
                                                onClick={() => onDeleteFile(file)}
                                                className={clsx(classes.actionButtonSize, classes.deleteButton)}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell variant="body" size="small">
                                        {file?.documentUrl && file?.documentId > 0 && (
                                            <a
                                                href={file.documentUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file?.documentName}
                                            </a>
                                        )}
                                        {!file?.documentUrl && file?.documentId < 0 && file?.documentName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {pendingToUpload && isAllowToSave && referenceId > 0 && (
                <>
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        className={clsx(classes.buttonGreen, classes.btnUploadFiles)}
                        disabled={!pendingToUpload}
                        onClick={onUploadFile}
                    >
                        Pending to upload
                        {' '}
                        {files.length === 1 ? 'file' : 'files'}
                    </Button>
                </>
            ) }
            <ConfirmDialog
                title="Confirm remove file"
                description="Are you sure you want to remove this file?"
                open={isModalDeleteOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
            {isUploading && <DialogActionMessage message="Uploading files... " />}
            {isDeleting && <DialogActionMessage message="Deleting file... " />}
        </div>
    );
});

ProcessFiles.propTypes = {
    referenceType: PropTypes.string.isRequired,
    referenceId: PropTypes.number.isRequired,
    notifyDirtyFiles: PropTypes.func,
    isAllowToSave: PropTypes.bool,
};

ProcessFiles.defaultProps = {
    isAllowToSave: false,
    notifyDirtyFiles: () => null,
};

export default ProcessFiles;

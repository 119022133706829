/* eslint-disable max-len */
import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {
    AppBar, Toolbar, IconButton,
    Button, Divider, Typography,
} from '@material-ui/core';
import 'react-multi-email/style.css';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { MailStatus } from 'utils/enum/Mail';
import MessageUtils from 'utils/MessageUtils';
import ButtonStyles from 'styles/theme/Button';
import Loading from 'components/widgets/Loading';
import { isEmpty, findIndex, uniq } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink } from 'react-router-dom';
import Jodit from 'components/widgets/editor/JoditEditor';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Message from 'components/modules/crm/mail/Message';
import { configurationReply } from 'components/modules/crm/mail/Configuration';

// HTTP
import DateUtils from 'lib/DateUtils';
import { FetchPolicy } from 'utils/enum/Core';
import MailQuery from 'services/graphQL/query/crm/MailQuery';
import MailMutation from 'services/graphQL/mutate/crm/MailMutation';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import MailSubscription from 'services/graphQL/subscription/crm/MailSubscription';
import EmailUtils from 'lib/EmailUtils';

const mailFormatDate = '[On] ddd, MMM D, YYYY [at] h:mm a';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: '#fff',
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#fff',
        borderBottom: '1px solid #eeeeee',
        zIndex: 2,
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    footer: {
        borderTop: '1px solid #eeeeee',
    },
    to: {
        color: 'rgb(99, 115, 129)',
        fontSize: '13px',
        fontWeight: 500,
    },
    body: {
        overflow: 'auto',
        flex: 1,
    },
    button: {
        margin: theme.spacing(2),
    },
    toText: {
        fontSize: 14,
        margin: theme.spacing(0, 1),
    },
    closeEmail: {
        marginRight: theme.spacing(1),
    },
    subject: {
        marginLeft: theme.spacing(2),
    },
    ...ButtonStyles.getStyle(theme),
}));

const initState = {
    records: [],
    emails: [],
    cc: [],
    bcc: [],
    bodyMessage: '',
    isSelectBCC: false,
    isSelectCC: false,
    recordSelected: {},
};

const ACTION_TYPES = {
    ADD_RECORD: 'addRecord',
    SET_RECORD: 'setRecord',
    ON_CLEAR_REPLY: 'onClearReply',
    RECORD_UPDATED: 'recordUpdated',
    SET_STATE_VALUES: 'setStateValues',
    ON_SELECT_MESSAGE_TO_REPLY: 'onSelectMessageToReply',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.ON_SELECT_MESSAGE_TO_REPLY: {
        let emails = [];
        const record = action.value;
        const cc = EmailUtils.getCC(record.cc, record.to);
        const bcc = EmailUtils.getBCC(record.bcc, record.to);
        if (action.replyAll && record.incoming) {
            emails = uniq([...StringUtils.getEmailsByText(action.value.toFull).filter((item) => item !== record.to), record.from]);
        } else if (action.replyAll && !record.incoming) {
            emails = StringUtils.getEmailsByText(action.value.toFull);
        } else {
            emails = action.value.incoming ? [action.value.from] : [action.value.to];
        }

        return update(state, {
            recordSelected: { $set: action.value },
            emails: { $set: emails },
            cc: { $set: cc },
            bcc: { $set: bcc },
            isSelectCC: { $set: cc?.length > 0 },
            isSelectBCC: { $set: bcc?.length > 0 },
        });
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return update(state, {
            [action.field]: { $set: action.value },
        });
    }
    case ACTION_TYPES.ON_CLEAR_REPLY: {
        return update(state, {
            bodyMessage: { $set: '' },
            emails: { $set: [] },
            recordSelected: { $set: {} },
        });
    }
    case ACTION_TYPES.SET_RECORD: {
        return update(state, {
            records: { $set: action.value },
        });
    }
    case ACTION_TYPES.ADD_RECORD:
        const index = findIndex(state.records, { emailId: action.payload.emailId });
        if (index >= 0) {
            return update(state, {
                records: {
                    [index]: {
                        lastBody: { $set: action.payload.bodyPlain },
                        status: { $set: action.payload.status },
                    },
                },
            });
        }
        return update(state, {
            records: { $unshift: [action.payload] },
        });
    case ACTION_TYPES.RECORD_UPDATED:
        const indexRecord = findIndex(state.records, { emailId: action.payload.emailId });
        if (indexRecord >= 0) {
            return update(state, {
                records: {
                    [indexRecord]: {
                        status: { $set: action.payload.status },
                    },
                },
            });
        }

        return state;
    default:
        return state;
    }
};

const MailDetail = ({ threadId, to, onClickBack }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        loading, data, error,
    } = useQuery(MailQuery.GET_EMAIL_THREAD, { variables: { threadId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const [markMailAsRead] = useMutation(MailMutation.MARK_EMAIL_AS_READ);
    const [sendEmail, { loading: loadingSendMail }] = useMutation(MailMutation.SEND_USER_EMAIL);
    const { data: subscriptionData } = useSubscription(MailSubscription.CHANGED_USER_EMAIL_DETAIL, { variables: { threadId } });
    const onChangeValue = (value, field) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value,
            field,
        });
    };
    const onReply = (record, replyAll = false) => {
        dispatch({
            type: ACTION_TYPES.ON_SELECT_MESSAGE_TO_REPLY,
            value: record,
            replyAll,
        });
    };
    const clearReply = () => {
        dispatch({
            type: ACTION_TYPES.ON_CLEAR_REPLY,
        });
    };

    const sendMessage = async () => {
        try {
            const record = state.recordSelected;
            const dateLastMessage = DateUtils.format(record.createdOn, mailFormatDate);
            const emailFrom = record.incoming ? record.to : record.from;
            const input = {
                to: state.emails,
                cc: state.cc,
                bcc: state.bcc,
                subject: state.recordSelected.subject,
                body: `${state.bodyMessage}
                    <div class="im">
                        <br/>
                        <div class="mail_quote">
                            <div class="mail_attr">${dateLastMessage}, (<a href="${emailFrom}">${emailFrom}</a>) wrote:</div>
                            <blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
                                ${state.recordSelected.bodyHTML}
                            </blockquote>
                        </div>
                    </div>`,
                inReplyTo: state.recordSelected.messageId,
            };
            const response = await sendEmail({ variables: { input } });

            if (response?.data?.sendUserEmail) {
                clearReply();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('Send', 'Email'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('Send', 'Email'));
        }
    };

    useEffect(() => {
        if (!isEmpty(data)) {
            if (data?.getEmailThread) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: data.getEmailThread,
                });
            }
        }
    }, [loading, data, error, threadId]);

    useEffect(() => {
        if (threadId && state.records[0]?.incoming && state.records[0]?.status === MailStatus.RECEIVED) {
            markMailAsRead({ variables: { threadId } });
        }
    }, [threadId, markMailAsRead, state.records]);

    useEffect(() => {
        if (subscriptionData) {
            const { changedUserEmailDetail } = subscriptionData;
            if (changedUserEmailDetail?.type?.toUpperCase() === SubscriptionActionType.NEW) {
                dispatch({
                    type: ACTION_TYPES.ADD_RECORD,
                    payload: changedUserEmailDetail.email,
                });
            }

            if (changedUserEmailDetail?.type?.toUpperCase() === SubscriptionActionType.UPDATED) {
                dispatch({
                    type: ACTION_TYPES.RECORD_UPDATED,
                    payload: changedUserEmailDetail.email,
                });
            }
        }
    }, [subscriptionData]);

    const getBackButton = () => {
        if (!StringUtils.isEmpty(to)) {
            return (
                <IconButton className={classes.iconButton} component={RouterLink} to={to}>
                    <ArrowBackIosOutlinedIcon fontSize="small" />
                </IconButton>
            );
        }

        return (
            <IconButton className={classes.iconButton} onClick={onClickBack}>
                <ArrowBackIosOutlinedIcon fontSize="small" />
            </IconButton>
        );
    };

    return (
        <div className={classes.root}>
            {loading ? <Loading /> : (
                <>
                    <AppBar position="relative" className={classes.appBar}>
                        <Toolbar className="d-flex-space-between">
                            <div className="d-flex-center">
                                {getBackButton()}
                                <Typography variant="h5" className={classes.subject}>{state?.records[0]?.subject}</Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.body}>
                        {state.records.map((item, index) => <Message key={index} record={item} onReply={onReply} />)}
                    </div>
                    {state.recordSelected?.emailId && (
                        <div className={classes.footer}>
                            <div className="custom-mail-detail">
                                <div className="flex-1">
                                    <div className="custom-mail d-flex-center flex-1">
                                        <span className={classes.toText}>To</span>
                                        <ReactMultiEmail
                                            emails={state.emails}
                                            onChange={(emails) => onChangeValue(emails, 'emails')}
                                            validateEmail={(email) => isEmail(email)}
                                            getLabel={(email, index, removeEmail) => (
                                                <div data-tag key={index} className="item">
                                                    {email}
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                        ×
                                                    </span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {state.isSelectCC && (
                                        <div className="custom-mail d-flex-center flex-1">
                                            <span className={classes.toText}>Cc</span>
                                            <ReactMultiEmail
                                                emails={state.cc}
                                                onChange={(emails) => onChangeValue(emails, 'cc')}
                                                validateEmail={(email) => isEmail(email)}
                                                getLabel={(email, index, removeEmail) => (
                                                    <div data-tag key={index} className="item">
                                                        {email}
                                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                                            ×
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    )}
                                    {state.isSelectBCC && (
                                        <div className="custom-mail d-flex-center flex-1">
                                            <span className={classes.toText}>Bcc</span>
                                            <ReactMultiEmail
                                                emails={state.bcc}
                                                onChange={(emails) => onChangeValue(emails, 'bcc')}
                                                validateEmail={(email) => isEmail(email)}
                                                getLabel={(email, index, removeEmail) => (
                                                    <div data-tag key={index} className="item">
                                                        {email}
                                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                                            ×
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="container-copy">
                                    {!state.isSelectCC && <span className="copy" onClick={() => onChangeValue(true, 'isSelectCC')}>CC</span>}
                                    {!state.isSelectBCC && <span className="copy" onClick={() => onChangeValue(true, 'isSelectBCC')}>BCC</span>}
                                </div>
                            </div>
                            <Jodit
                                value={state.bodyMessage}
                                config={configurationReply}
                                onChange={(newContent) => onChangeValue(newContent, 'bodyMessage')}
                            />
                            <Divider />
                            <div className="d-flex-space-between">
                                <Button
                                    variant="contained"
                                    className={clsx(classes.button, classes.containedInfo)}
                                    onClick={sendMessage}
                                    disabled={StringUtils.isEmpty(state.bodyMessage) || loadingSendMail}
                                >
                                    Send
                                </Button>
                                <IconButton className={classes.closeEmail} onClick={clearReply}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

MailDetail.propTypes = {
    threadId: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClickBack: PropTypes.func,
};

MailDetail.defaultProps = {
    to: null,
    onClickBack: () => {},
};

export default MailDetail;

import React from 'react';
import PropTypes from 'prop-types';

// Internal components
import IntegrationType from 'utils/enum/ThirdPartyIntegrationsEnum';
import NeoDialogForm from 'components/modules/settings/underwriting/NEODialogForm';
import UnderwritingDialogForm from 'components/modules/settings/underwriting/UnderwritingDialogForm';

const IntegrationDialogFactory = (props) => {
    const { record } = props;
    const type = (record.type || '').toUpperCase();

    switch (type) {
    case IntegrationType.NEO:
        return <NeoDialogForm {...props} />;
    case IntegrationType.CREDIT_PULL:
    case IntegrationType.UNDERWRITING:
        return <UnderwritingDialogForm {...props} />;
    default:
        return null;
    }
};

IntegrationDialogFactory.propTypes = {
    record: PropTypes.object,
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

IntegrationDialogFactory.defaultProps = {
    record: null,
};

export default IntegrationDialogFactory;

import React from 'react';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import TabSetting from 'components/modules/settings/TabSetting';
import Templates from 'components/modules/settings/crm/Templates';
import LostReason from 'components/modules/settings/crm/LostReason';
import LeadSources from 'components/modules/settings/crm/LeadSources';
import TagsSettings from 'components/modules/settings/crm/TagsSetting';
import RecordManager from 'components/modules/settings/crm/RecordManager';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import ObstaclesToClose from 'components/modules/settings/crm/ObstaclesToClose';
import AutomatedResponse from 'components/modules/settings/crm/AutomatedResponse';
import EmailLeadDistribution from 'components/modules/settings/crm/EmailLeadDistribution';
import OpportunityReassignment from 'components/modules/settings/crm/OpportunityReassignment';
import UnsubscribeSettings from 'components/modules/settings/crm/UnsubscribeSettings';
import GeneralSettings from 'components/modules/settings/crm/GeneralSettings';

const keyStore = new KeyStore();
const getSettings = () => {
    const settings = [];

    if (keyStore.hasPermission(Permission.CRM_SETTINGS_READ)) {
        settings.push(
            {
                text: 'General',
                icon: <TuneOutlinedIcon />,
                url: 'general',
                component: <GeneralSettings />,
            },
            {
                text: 'Email Lead Distribution',
                icon: <TuneOutlinedIcon />,
                url: 'email-lead-distribution',
                component: <EmailLeadDistribution />,
            },
            {
                text: 'Record Manager',
                icon: <TuneOutlinedIcon />,
                url: 'record-manager',
                component: <RecordManager />,
            },
            {
                text: 'Templates',
                icon: <TuneOutlinedIcon />,
                url: 'templates',
                component: <Templates />,
            },
            {
                text: 'Automated Response',
                icon: <TuneOutlinedIcon />,
                url: 'automated-response',
                component: <AutomatedResponse />,
            },
            {
                text: 'Opportunity Reassignment',
                icon: <TuneOutlinedIcon />,
                url: 'Opportunity-reassignment',
                component: <OpportunityReassignment />,
            },
            {
                text: 'Obstacles To Close',
                icon: <TuneOutlinedIcon />,
                url: 'obstacles-to-close',
                component: <ObstaclesToClose />,
            },
        );
    }

    if (keyStore.hasPermission(Permission.CRM_SETTING_LEAD_SOURCES_READ)) {
        settings.push({
            text: 'Lead Sources',
            url: 'lead-sources',
            icon: <TuneOutlinedIcon />,
            component: <LeadSources />,
        });
    }

    if (keyStore.hasPermission(Permission.CRM_SETTING_LOST_REASON_READ)) {
        settings.push({
            text: 'Lost Reasons',
            icon: <TuneOutlinedIcon />,
            url: 'lost-reasons',
            component: <LostReason />,
        });
    }

    if (keyStore.hasPermission(Permission.CRM_SETTING_TAG_READ)) {
        settings.push({
            text: 'Tag',
            icon: <LocalOfferOutlinedIcon />,
            url: 'tag',
            component: <TagsSettings />,
        });
    }

    settings.push({
        text: 'Unsubscribe',
        icon: <TuneOutlinedIcon />,
        url: 'unsubscribe',
        component: <UnsubscribeSettings />,
    });

    return settings;
};

const CRMSettings = () => {
    const settings = getSettings();

    return <TabSetting records={settings} />;
};

export default CRMSettings;

import React, { Component } from 'react';

// Others
import { clone } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import moment from 'moment';

// GraphQL
import VendorService from 'services/modules/VendorService';
import CompanyService from 'services/modules/CompanyService';
import LotSettingsMap from 'services/mapData/LotSettingsMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerMap from 'services/mapData/CustomerMap';
import { InventoryCategory } from 'utils/enum/InventoryEnum';
import LotsCategory from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import UserContext from 'components/context/UserContext';
import StringUtils from 'lib/StringUtils';
import KeyStore from 'utils/KeyStore';

const LotSettingsContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.companyService = new CompanyService();
        this.vendorService = new VendorService();
        this.keyStore = new KeyStore();

        this.state = {
            record: {
                active: false,
                activeSince: moment().format('YYYY-MM-DD'),
                bhphInHouse: false,
                dbaName: '',
                dealerFee: 0.0,
                dealerFeeCapitalized: false,
                dealerFeeEditable: false,
                dealerFeeTaxable: false,
                displayAcquisitionFee: false,
                displayDealerFee: false,
                displayInspectionFee: false,
                displayRegistrationFee: false,
                displayTagAgencyFee: false,
                displayTagFee: false,
                displayTitleFee: false,
                displayUserField1: false,
                displayUserField2: false,
                displayUserField3: false,
                displayUserField4: false,
                displayUserField5: false,
                displayUserField6: false,
                displayUserField7: false,
                dispositionFee: 0.0,
                earlyTerminationFee: 0.0,
                gapCapitalized: false,
                inspectionFee: 0.0,
                inspectionFeeCapitalized: false,
                inspectionFeeTaxable: false,
                inventoryTax: 0.0,
                lotAddress: '',
                lotCity: '',
                lotContactPerson: '',
                lotDln: '',
                lotFax: '',
                lotId: 0,
                lotMvNumber: '',
                lotMvr: '',
                lotName: '',
                lotPhone: '',
                lotState: '',
                lotStateRegistration: '',
                lotTaxReg: '',
                lotTimeZone: 0,
                lotTimeZoneId: '',
                lotType: '',
                lotZip: '',
                mbi: 0.0,
                mbiCapitalized: false,
                mbiTaxable: false,
                noTaxOnRoLabor: false,
                overridePassword: '',
                packCost: 0.0,
                pictureUrl: '',
                productsTaxable: false,
                purchaseOptionFee: 0.0,
                registrationFee: 0.0,
                registrationFeeCapitalized: false,
                registrationFeeTaxable: false,
                serviceContractCapitalized: false,
                tagAgencyFee: 0.0,
                tagAgencyFeeCapitalized: false,
                tagAgencyFeeTaxable: false,
                tagFee: 0.0,
                tagFeeCapitalized: false,
                tagFeeTaxable: false,
                titleFee: 0.0,
                titleFeeCapitalized: false,
                titleFeeTaxable: false,
                userField1: 0.0,
                userField1Capitalized: false,
                userField1Name: '',
                userField1Taxable: false,
                userField2: 0.0,
                userField2Capitalized: false,
                userField2Name: '',
                userField2Taxable: false,
                userField3: 0.0,
                userField3Capitalized: false,
                userField3Name: '',
                userField3Taxable: false,
                userField4: 0.0,
                userField4Capitalized: false,
                userField4Name: '',
                userField4Taxable: false,
                userField5: 0.0,
                userField5Capitalized: false,
                userField5Name: '',
                userField5Taxable: false,
                userField6: 0.0,
                userField6Capitalized: false,
                userField6Name: '',
                userField6Taxable: false,
                userField7: 0.0,
                userField7Capitalized: false,
                userField7Name: '',
                userField7Taxable: false,
            },
            zipData: [],
            listCity: [],
            isDecoderEnabled: false,
            isDecodingZip: false,
            isLoading: false,
            currentLotName: this.keyStore?.getSelectedLot()?.lotName || '',
            lotWhitelistedIp: '',
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
    }

    componentWillUnmount() {
    }

    onEnableDecoder() {
        const { state: { isDecoderEnabled } } = this;
        if (!isDecoderEnabled) {
            this.setState({ isDecoderEnabled: true });
        }
    }

    onChangeValue(field, value) {
        if (field === 'currentLotName') {
            this.setState({ currentLotName: value }, () => {
                this.getServicesData();
            });
            return;
        }

        if (field === 'lotWhitelistedIp') {
            this.setState({ lotWhitelistedIp: value });
            return;
        }

        this.setState(({ record }) => {
            const newRecord = { ...record };

            if (field === 'activeSince') {
                newRecord[field] = moment(value).format('YYYY-MM-DD');
            } else {
                newRecord[field] = value;
            }

            return { record: newRecord };
        }, () => {
            if (field === 'lotZip' && value.length === 5) {
                const { state: { isDecoderEnabled } } = this;
                if (isDecoderEnabled) {
                    this.decodeZip(value);
                }
            }
        });
    }

    onSave() {
        const { TIME_CLOCK_IP } = InventoryCategory;
        const { currentLotName } = this.state;

        const { record, record: { lotId }, lotWhitelistedIp } = this.state;
        const validIpAddresses = this.validateIpAddresses(lotWhitelistedIp);
        if (!validIpAddresses) {
            ModalUtils.errorMessage(null, 'Double Check the comma-separated whithelisted IP Addresses. Only numbers, semicolons and periods allowed');
            return;
        }

        this.graphqlClient.mutate(LotMutation.SAVE_LOT_DEFAULTS, {
            category: LotsCategory.HR,
            key: TIME_CLOCK_IP,
            value: lotWhitelistedIp,
            lotName: currentLotName,
            critical: false,
        })
            .then((response) => {
                const { graphQLErrors } = response;
                if (graphQLErrors) ModalUtils.errorMessage(graphQLErrors);
            });

        const lot = LotSettingsMap.mapLotSettingToUpdate(record);
        const input = {
            lotId,
            lot,
        };

        this.graphqlClient.mutate(LotMutation.UPDATE_LOT_SETTINGS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateLotSettings) {
                    ModalUtils.successMessage(null, 'Lot updated successfully');
                    this.getServicesData();
                }
            });
    }

    onUploadFile(file) {
        const { record: { lotId, lotName } } = this.state;
        const input = {
            lotId,
            file,
            lotName,
        };

        this.graphqlClient.mutate(LotMutation.UPDATE_LOT_PICTURE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateLotPicture) {
                    ModalUtils.successMessage(null, 'Lot picture updated successfully');
                    this.getServicesData();
                }
            });
    }

    getServicesData() {
        const { TIME_CLOCK_IP } = InventoryCategory;
        const { currentLotName } = this.state;
        const input = {
            filter: {
                lotName: currentLotName,
            },
        };

        this.setState({ isLoading: true });
        this.graphqlClient.query(LotQuery.GET_SETTINGS, {
            category: LotsCategory.HR,
            lotName: currentLotName,
            key: [TIME_CLOCK_IP],
        })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const settings = data?.getSettings;
                if (settings) {
                    const savedValue = settings.find((setting) => setting.key === TIME_CLOCK_IP)?.value ?? '';
                    this.setState({
                        lotWhitelistedIp: savedValue,
                    });
                }
            });

        this.graphqlClient.query(LotQuery.GET_LOT_SETTINGS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const { lotList } = data || {};

                if (Array.isArray(lotList) && lotList.length > 0) {
                    const lot = lotList[0] || {};
                    this.setState({
                        record: lot,
                    });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    validateIpAddresses(input) {
        if (StringUtils.isEmpty(input)) return true;
        return !input
            .split(',')
            .some((address) => !StringUtils.isValidIpV4(address?.trim()) && !StringUtils.isValidSubdomain(address?.trim()));
    }

    decodeZip(zip = '', ignoreCity = false) {
        const input = {
            zip,
        };

        this.setState({ isDecodingZip: true });
        this.graphqlClient
            .query(CustomerQuery.DECODE_ZIP_CODE, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.decodeZip) {
                    const { decodeZip } = data;

                    /* In order to keep the city value, we need
                    to assign it using the record from database */
                    if (ignoreCity && decodeZip.length > 0) {
                        const { state: { record } } = this;
                        const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.lotCity));

                        if (!alreadyExist) {
                            const newData = clone(decodeZip[0]);
                            newData.city = record.insuranceCity;
                            decodeZip.unshift(newData);
                        }
                    }

                    const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                    if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                    const zipData = decodeZip;

                    this.setState(({ record, isDecoderEnabled }) => {
                        if (isDecoderEnabled) {
                            const newRecord = {
                                ...record,
                                lotCity: '',
                                lotState: '',
                            };
                            return {
                                listCity, zipData, record: newRecord, isDecodingZip: false,
                            };
                        }
                        return { listCity, zipData, isDecodingZip: false };
                    });

                    if (decodeZip.length === 1) {
                        const currentData = decodeZip[0];

                        this.setState(({ record }) => {
                            const newRecord = {
                                ...record,
                                lotCity: currentData.city,
                                lotState: currentData.state,
                            };

                            return { record: newRecord };
                        });
                    }
                }
            });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onEnableDecoder = this.onEnableDecoder.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
    }

    render() {
        const { props, state } = this;
        return (
            <UserContext.Consumer>
                {(data) => (
                    <WrappedComponent
                        {...props}
                        {...state}
                        onEnableDecoder={this.onEnableDecoder}
                        onChangeValue={this.onChangeValue}
                        onUploadFile={this.onUploadFile}
                        onSave={this.onSave}
                        availableLots={data.userInformation?.lots}
                    />
                )}
            </UserContext.Consumer>
        );
    }
};

export default LotSettingsContainer;

import React, { PureComponent } from 'react';

// Material UI
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components and Others
import PropTypes from 'prop-types';
import LabelValueStyles from 'styles/widgets/LabelValueStyles';

const styles = (theme) => LabelValueStyles.style(theme);

class MultipleLineValues extends PureComponent {
    renderValues() {
        const { props: { values, classes } } = this;
        // Todo: Change this. Use String utils
        const addressList = (values || []).filter((item) => (item && item !== ', '));

        return (
            <div className={classes.boxAddress}>
                { addressList.map((value, index) => <Typography key={index} className={classes.valueList} variant="h6">{value}</Typography>) }
            </div>
        );
    }

    render() {
        const { props: { label, classes } } = this;

        return (
            <div className={classes.multipleValuesBox}>
                <Typography className={classes.label} variant="h6" color="primary">{`${label}:`}</Typography>
                { this.renderValues() }
            </div>
        );
    }
}

MultipleLineValues.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    label: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(MultipleLineValues);

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import { AdjustmentType, TaxType } from 'utils/enum/PayrollEnum';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import PayrollBDSchema from 'utils/schema/settings/payroll/PayrollBDSchema';
import PayrollSettingsMutation from 'services/graphQL/mutate/payroll/Settings';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 80,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
            minWidth: 400,
        },
    },
    checkBox: {
        alignSelf: 'baseline',
        minWidth: '0px !important',
    },
}));

const initialState = {
    bdType: null,
    taxType: null,
    description: null,
    isLoan: false,
};

const getAdjustmentTypeList = () => Object.keys(AdjustmentType).map((item) => ({ label: AdjustmentType[item], value: item }));

const taxTypeList = [
    { label: 'Taxable - Bonus at 22% (Pre-Tax)', value: TaxType.PRE_TAX },
    { label: 'Taxable - Standard Rate (Pre-Tax)', value: TaxType.STANDARD_TAX },
    { label: 'Non-Taxable (Post-Tax)', value: TaxType.POST_TAX },
];

const PayrollAdditionsDeductionsDialog = ({ onClose, record }) => {
    const bdId = record ? record.bdId : 0;
    const classes = useStyles();
    const [state, setState] = useState(initialState);
    const [savePayrollAdjustment, { loading: updating }] = useMutation(PayrollSettingsMutation.SAVE_PAYROLL_ADJUSTMENT);

    useEffect(() => {
        if (record) {
            const { id, bdType, ...recordValues } = record;
            setState({
                ...recordValues,
                bdType: bdType.toUpperCase(),
            });
        }
    }, [record]);

    const onChange = (field, value) => {
        setState((item) => ({
            ...item,
            [field]: value,
        }));
    };

    const onSave = async () => {
        try {
            const input = { ...state, bdId };
            const response = await savePayrollAdjustment({ variables: { input } });

            if (response.data?.savePayrollBDRecord) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({ id: null, saving: true });
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Payroll Mapping'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const isValidData = isValidSchema(PayrollBDSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Dialog
            open
            maxWidth="md"
        >
            <DialogAppBar
                title={bdId === 0 ? 'New Record' : `Editing record id: ${bdId}`}
                onClose={() => onClose({ id: null, saving: false })}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Type:</Form.Label>
                                    <Select
                                        name="bdType"
                                        placeholder="Select"
                                        size="sm"
                                        options={getAdjustmentTypeList()}
                                        value={state.bdType}
                                        onChange={onChange}
                                        className={isValidField(errors, 'bdType') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control
                                        className={isValidField(errors, 'description') ? 'invalid-field' : ''}
                                        size="sm"
                                        type="text"
                                        onChange={(e) => onChange('description', e.target.value)}
                                        value={state.description}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Tax Type:</Form.Label>
                                    <Select
                                        name="taxType"
                                        placeholder="Select"
                                        size="sm"
                                        options={taxTypeList}
                                        value={state.taxType}
                                        onChange={onChange}
                                        className={isValidField(errors, 'taxType') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>This is for the employee loan:</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="isLoan"
                                        checked={state.isLoan}
                                        className={classes.checkBox}
                                        onChange={(e) => onChange('isLoan', e.target.checked)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={() => onClose({ id: null, saving: true })}
                variant="contained"
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

PayrollAdditionsDeductionsDialog.propTypes = {
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

PayrollAdditionsDeductionsDialog.defaultProps = {
    record: null,
};

export default PayrollAdditionsDeductionsDialog;

import React, {
    useState,
    useRef,
    useContext,
    useEffect,
} from 'react';
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import {
    PrintOutlinedIcon,
} from 'components/icons';
import {
    useLazyQuery,
} from '@apollo/client';
import {
    PRINTABLE_TYPE,
} from 'utils/enum/BusinessIntelligenceEnum';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import { Form, Row } from 'react-bootstrap';
import { FetchPolicy, DataSort, ALL_LOTS } from 'utils/enum/Core';
import { MinimumSalePrice } from 'utils/enum/InventoryEnum';
import { PaymentFrequency } from 'utils/enum/DealEnum';
import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import UserContext from 'components/context/UserContext';
import ButtonStyles from 'styles/theme/Button';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import BIHelper from 'utils/BusinessIntelligenceHelper';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    header: {
        color: theme.palette.text.white,
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: theme.palette.background.bigStone,
    },
    content: {
        display: 'flex',
        height: '100%',
        overflowY: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    topSection: {
        display: 'flex',
        width: '100%',
        flexShrink: 0,
        height: '40px',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.ghost}`,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('sm')]: {
            height: 'auto !important',
            flexDirection: 'column',
            padding: '5px',
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        '& > div:nth-child(1)': {
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            '& > span': {
                color: theme.palette.text.redBerry,
                marginLeft: '5px',
            },
        },
        '& > div:nth-child(2) > div': {
            marginRight: '10px',
            '& > div': {
                width: '200px',
                border: `1px solid ${theme.palette.border.ghost}`,
                background: theme.palette.background.white,
                borderRadius: '5px',
                borderBottom: 'initial',
                '& > div': {
                    fontSize: '13px',
                },
            },
        },
    },
    bottomSection: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flexGrow: 'initial !important',
        },
    },
    leftPanel: {
        width: '300px',
        flexShrink: 0,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        padding: '10px',
        paddingTop: '30px',
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            borderRight: 'initial !important',
        },
    },
    rightPanel: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexGrow: 'initial !important',
            width: '100%',
            height: '500px',
        },
    },
    tableContainer: {
        marginTop: '1px',
        height: '92%',
        overflowY: 'hidden',
        overflowX: 'auto',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'center',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    panelTitle: {
        color: theme.palette.background.white,
        backgroundColor: theme.palette.background.bigStone,
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        fontSize: '15px',
        marginBottom: '20px',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        flexWrap: 'nowrap',
        marginBottom: '20px',
        '& > label': {
            width: '120px',
            marginTop: '5px',
            fontSize: '14px',
            flexShrink: 0,
        },
        '& > input': {
            fontSize: '14px',
        },
        '& > div': {
            flexGrow: 1,
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const Desking = () => {
    const tableReference = useRef();
    const { userInformation = {} } = useContext(UserContext);
    const classes = { ...useStyles(), ...buttonStyles() };
    const { loadLocalFilterValues, resetFilters: resetLocalFilters, applyFilters: applyLocalFilters } = useFilterActions();
    const [state, setState] = useState({
        lots: [],
        selectedLots: [],
        vehicles: [],
        fixedSearchPrice: MinimumSalePrice.INTERNET_PRICE,
        searchPrice: MinimumSalePrice.INTERNET_PRICE,
        downpayment: 2000,
        payment: 450,
        paymentWithin: 25,
        paymentFrequency: null,
        term: null,
        rate: null,
        year: 2014,
        miles: 125000,
        sort: {
            dir: DataSort.DESC,
            field: 'stockNumber',
        },
        columnsData: [],
        filters: [],
    });

    const [getDealSettings] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_GENERAL, {
        onCompleted: (response) => {
            if (response) {
                const settings = response.getDealSettingsGeneral;
                if (settings) {
                    const { defaultInterestRate, defaultPaymentFrequency, defaultTerm } = settings;
                    const frequency = (defaultPaymentFrequency ?? PaymentFrequency.MONTHLY).toUpperCase().replace('_', '-');

                    setState((prevState) => ({
                        ...prevState,
                        paymentFrequency: [
                            PaymentFrequency.MONTHLY,
                            PaymentFrequency.BI_WEEKLY,
                            PaymentFrequency.WEEKLY,
                        ].includes(frequency) ? frequency : PaymentFrequency.MONTHLY,
                        term: defaultTerm ?? 60,
                        rate: defaultInterestRate ?? 12,
                    }));
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getVehicles, { loading }] = useLazyQuery(InventoryQuery.PULL_MATCHING_VEHICLES_DESKING, {
        onCompleted: (response) => {
            if (response) {
                const vehicles = response.pullMatchingVehiclesInDesking;
                setState((prevState) => ({
                    ...prevState,
                    fixedSearchPrice: state.searchPrice,
                    vehicles,
                }));
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (Object.keys(userInformation ?? {}).length > 0) {
            const defaultLot = userInformation?.defaultLot ?? userInformation?.usersLot;
            setState((prevState) => ({
                ...prevState,
                lots: userInformation?.lots ?? [],
                selectedLots: [defaultLot],
            }));

            getDealSettings({
                variables: {
                    lotName: defaultLot,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation]);

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        if (state.lots.length > 0) pullMatchingVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sort.field, state.sort.dir, state.filters]);

    const onFilterByLot = (lots = []) => {
        setState((prevState) => ({
            ...prevState,
            selectedLots: lots,
        }));
    };

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const pullMatchingVehicles = (resetFilters = false) => {
        const { lots, selectedLots } = state;
        const isAllLots = state.selectedLots.includes(ALL_LOTS);

        if (resetFilters) {
            setState((prevState) => ({
                ...prevState,
                filters: [],
                columnsData: [],
            }));

            return;
        }

        getVehicles({
            variables: {
                lotIds: isAllLots
                    ? lots.map((el) => el.lotId)
                    : lots.filter((el) => selectedLots.some((s) => s === el.lotName)).map((el) => el.lotId),
                searchPrice: state.searchPrice,
                downPayment: state.downpayment,
                payment: state.payment,
                paymentWithin: state.paymentWithin,
                paymentFrequency: state.paymentFrequency.replace('-', '_'),
                term: state.term,
                annualRate: state.rate,
                year: state.year,
                miles: state.miles,
                sort: state.sort,
                filters: resetFilters ? [] : state.filters,
            },
        });

        if (tableReference.current) tableReference.current.closeFilterBox();
    };

    const printVehicles = () => {
        const { vehicles } = state;
        const columns = Object.keys(vehicles[0]);

        BIHelper.printChart(PRINTABLE_TYPE.JSON, {
            rows: vehicles,
            columns,
            label: 'Payment Calls',
        });
    };

    const onSort = ({ sortBy, sortDirection }) => {
        setState((prevState) => ({
            ...prevState,
            sort: {
                dir: sortDirection,
                field: sortBy,
            },
        }));
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const { vehicles, columnsData } = state;
        const data = loadLocalFilterValues(vehicles, columnsData, column, searchInput);

        setState((prevState) => ({
            ...prevState,
            columnsData: data,
        }));
    };

    const onResetFilters = (column) => {
        const { columnsData, filters } = state;
        const data = resetLocalFilters(columnsData, column, filters);

        setState((prevState) => ({
            ...prevState,
            filters: data.filters,
            columnsData: data.columnsData,
        }));

        if (column && tableReference.current) tableReference.current.closeFilterBox();
    };

    const onApplyFilters = (column, selectedValues) => {
        const { columnsData, filters } = state;
        const data = applyLocalFilters(filters, column, selectedValues, false, columnsData);

        setState((prevState) => ({
            ...prevState,
            filters: data.filters,
            columnsData: data.columnsData,
        }));

        if (tableReference.current) tableReference.current.closeFilterBox();
    };

    const isInternetPrice = state.fixedSearchPrice === MinimumSalePrice.INTERNET_PRICE;
    const columns = [
        {
            label: 'Stock',
            dataKey: 'stockNumber',
            width: 150,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <a
                        href={`/inventory/${record.stockNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>{record.stockNumber}</span>
                    </a>
                );
            },
        },
        {
            label: 'Year',
            dataKey: 'year',
            width: 80,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{record.year}</span>;
            },
        },
        {
            label: 'Make',
            dataKey: 'make',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span className={classes.text}>{record.make}</span>;
            },
        },
        {
            label: 'Model',
            dataKey: 'model',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span className={classes.text}>{record.model}</span>;
            },
        },
        {
            label: 'Miles',
            dataKey: 'miles',
            width: 100,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{NumberUtils.applyThousandsFormat(record.miles)}</span>;
            },
        },
        {
            label: 'Body Style',
            dataKey: 'style',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span className={classes.text}>{record.style}</span>;
            },
        },
        {
            label: 'Price',
            dataKey: isInternetPrice ? 'internetPrice' : 'stickerPrice',
            width: 100,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{NumberUtils.applyCurrencyFormat(isInternetPrice ? record.internetPrice : record.stickerPrice)}</span>;
            },
        },
        {
            label: 'Payment',
            dataKey: 'payment',
            width: 100,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{NumberUtils.applyCurrencyFormat(record.payment)}</span>;
            },
        },
        {
            label: 'Finance Amount',
            dataKey: 'financeAmount',
            width: 140,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span>{NumberUtils.applyCurrencyFormat(record.financeAmount)}</span>;
            },
        },
        {
            label: 'Lot',
            dataKey: 'lotName',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return <span className={classes.text}>{record.lotName}</span>;
            },
        },
    ];

    const parentWidth = document.getElementById('table-container')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 5;

    if (!state.paymentFrequency) return null;
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                Payment Calls
            </div>
            <div className={classes.content}>
                <div className={classes.topSection}>
                    <div>
                        {`Items: ${state.vehicles.length}`}
                        <span>| Payments may differ due to rounding</span>
                    </div>
                    <div>
                        {state.selectedLots.length > 0 && (
                            <LotFilter onSearch={onFilterByLot} initialLots={state.selectedLots} />
                        )}
                        <Button
                            variant="outlined"
                            startIcon={<PrintOutlinedIcon />}
                            disabled={loading || state.vehicles.length === 0}
                            size="small"
                            onClick={printVehicles}
                        >
                            Print
                        </Button>
                    </div>
                </div>
                <div className={classes.bottomSection}>
                    <div className={classes.leftPanel}>
                        <div className={classes.panelTitle}>
                            Deal Information
                        </div>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Search Price:</Form.Label>
                            <Select
                                nowrap
                                size="sm"
                                disabled={loading}
                                className={classes.input}
                                name="searchPrice"
                                onChange={(name, value) => onChange(name, value)}
                                value={state.searchPrice}
                                options={[
                                    {
                                        value: MinimumSalePrice.INTERNET_PRICE,
                                        label: 'Internet Price',
                                    },
                                    {
                                        value: MinimumSalePrice.STICKER_PRICE,
                                        label: 'Sticker Price',
                                    },
                                ]}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Down Payment:</Form.Label>
                            <InputNumber
                                disabled={loading}
                                showCurrency
                                value={state.downpayment}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('downpayment', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Payment:</Form.Label>
                            <InputNumber
                                disabled={loading}
                                className={state.payment <= 0 ? 'invalid-field' : ''}
                                showCurrency
                                value={state.payment}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('payment', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Payment +/-:</Form.Label>
                            <InputNumber
                                disabled={loading}
                                showCurrency
                                value={state.paymentWithin}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('paymentWithin', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Frequency:</Form.Label>
                            <Select
                                nowrap
                                size="sm"
                                disabled={loading}
                                className={classes.input}
                                name="paymentFrequency"
                                onChange={(name, value) => onChange(name, value)}
                                value={state.paymentFrequency}
                                options={[
                                    PaymentFrequency.MONTHLY,
                                    PaymentFrequency.BI_WEEKLY,
                                    PaymentFrequency.WEEKLY,
                                ].map((f) => ({
                                    value: f,
                                    label: StringUtils.toPascalCase(f.toLowerCase()),
                                }))}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Term:</Form.Label>
                            <InputNumber
                                disabled={loading}
                                className={state.term <= 0 ? 'invalid-field' : ''}
                                value={state.term}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('term', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Annual Rate %:</Form.Label>
                            <InputNumber
                                disabled={loading}
                                className={state.rate < 0 ? 'invalid-field' : ''}
                                value={state.rate}
                                decimalScale={2}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('rate', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Year (From):</Form.Label>
                            <InputNumber
                                disabled={loading}
                                className={state.year < 1981 ? 'invalid-field' : ''}
                                value={state.year}
                                decimalScale={0}
                                size="sm"
                                onChange={(value) => onChange('year', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Miles (Up to):</Form.Label>
                            <InputNumber
                                disabled={loading}
                                value={state.miles}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('miles', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label />
                            <Button
                                fullWidth
                                disabled={
                                    state.payment <= 0
                                    || state.term <= 0
                                    || state.rate < 0
                                    || state.year < 1981
                                    || loading
                                }
                                className={classes.containedSecondaryInfo}
                                size="medium"
                                onClick={() => pullMatchingVehicles(true)}
                            >
                                Calculate
                            </Button>
                        </Form.Group>
                    </div>
                    <div className={classes.rightPanel}>
                        <div id="table-container" className={classes.tableContainer}>
                            {parentWidth && (
                                <VirtualTable
                                    ref={tableReference}
                                    loading={loading}
                                    rowHeight={35}
                                    totalRecords={state.vehicles.length}
                                    data={state.vehicles}
                                    columns={columns}
                                    sort={onSort}
                                    sortBy={state.sort.field}
                                    sortDirection={state.sort.dir}
                                    filters={state.filters}
                                    loadFilterValues={onLoadFilterValues}
                                    resetFilters={onResetFilters}
                                    applyFilters={onApplyFilters}
                                    columnsData={state.columnsData}
                                    width={tableWidth || 0}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Desking;

import React from 'react';

// Material UI
import { makeStyles, Typography } from '@material-ui/core';

// Components and Others
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import { round } from 'lodash';

const useStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
}));

const PartsTicketDetailSummary = ({
    subTotal, discount, tax, total, displayInline,
}) => {
    const classes = useStyles();

    return (
        <Typography
            variant="h6"
            className={displayInline ? classes.summaryTextInLine : classes.summaryText}
        >
            <span>Subtotal:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(subTotal)}</span>
            <span>Discount:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(discount)}</span>
            <span>Tax:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(tax)}</span>
            <span>Total:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(round((total), 2))}</span>
        </Typography>
    );
};

PartsTicketDetailSummary.propTypes = {
    subTotal: PropTypes.number,
    discount: PropTypes.number,
    tax: PropTypes.number,
    total: PropTypes.number,
    displayInline: PropTypes.bool,
};

PartsTicketDetailSummary.defaultProps = {
    subTotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    displayInline: true,
};

export default PartsTicketDetailSummary;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from 'components/icons';

const CheckboxControl = (props) => {
    const {
        name, value, onClick,
        editorCellObject, ...other
    } = props;
    const { className, ...otherProps } = other;
    // delete to not include as dom property
    delete otherProps.comparePropertyId;

    const handleClick = (newValue) => {
        onClick(name, newValue, editorCellObject);
    };
    const { id } = editorCellObject?.column || { id: 'input' };
    const editorCls = `${id}-ax-edit-ctrl`;

    return (
        value ? (
            <CheckBoxIcon
                name={name}
                className={clsx(editorCls, className)}
                onClick={() => handleClick(!value)}
            />
        )
            : (
                <CheckBoxOutlineBlankIcon
                    name={name}
                    className={clsx(editorCls, className)}
                    onClick={() => handleClick(!value)}
                    {...otherProps}
                />
            )
    );
};

CheckboxControl.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    editorCellObject: PropTypes.object,
    comparePropertyId: PropTypes.number,
};

CheckboxControl.defaultProps = {
    value: false,
    editorCellObject: {},
    comparePropertyId: 0,
};

const areEqual = (prevProps, nextProps) => prevProps.value !== '' && nextProps.value !== ''
    && prevProps.value === nextProps.value
    && prevProps.className === nextProps.className
    && prevProps.comparePropertyId === nextProps.comparePropertyId;
export default React.memo(CheckboxControl, areEqual);

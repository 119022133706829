import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';

export default class ServiceUtils {
    static getIndexFromList = (list, selected) => {
        const tabIndex = list.indexOf(selected);
        if (tabIndex !== -1) return tabIndex;

        return 0;
    };

    static getSubModuleList = () => {
        const subModules = [];
        const keyStore = new KeyStore();

        const SERVICE_READ = keyStore.hasPermission(Permission.SERVICE_READ);

        if (SERVICE_READ) subModules.push(ServiceSubModules.REPAIRS_ORDERS);
        if (SERVICE_READ) subModules.push(ServiceSubModules.TECHNICIAN_JOB_LIST);
        if (SERVICE_READ) subModules.push(ServiceSubModules.OPERATION_CODES);

        return subModules;
    };
}

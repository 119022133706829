import React, { Component } from 'react';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import {
    Divider, Button, Grid,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';

// Components and Others
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import { find, isFinite } from 'lodash';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import Tabs from 'components/widgets/tabs/Tabs';
import { Form, Col, Tab } from 'react-bootstrap';
import Loading from 'components/widgets/Loading';
import Dropdown from 'components/widgets/dropdown';
import { Link as RouterLink } from 'react-router-dom';
import { EventStatus } from 'utils/enum/ActivitiesEnum';
import UserQuery from 'services/graphQL/query/UserQuery';
import { LeadTypeStyle } from 'styles/modules/crm/LeadStyles';
import { ReactComponent as SMSIcon } from 'assets/crm/sms.svg';
import HistoryTab from 'components/modules/crm/read/history/HistoryTab';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ActivityEventSchema } from 'utils/schema/lead/Activity';
import { ReactComponent as NoteIcon } from 'assets/crm/note.svg';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import NoteForm from 'components/modules/crm/activities/form/NoteForm';
import NoteTable from 'components/modules/crm/activities/form/NoteTable';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { InsertCommentOutlined, BlockOutlined } from '@material-ui/icons';
import EmailHistory from 'components/modules/crm/activities/EmailHistory';
import MessageDialog from 'components/modules/crm/activities/form/MessageDialog';
import InterestVehicleCard from 'components/modules/crm/widgets/InterestVehicleCard';
import ActivityContent from 'components/modules/crm/activities/form/ActivityContent';

// Helpers
import NumberUtils from 'lib/NumberUtils';
import { modules } from 'utils/enum/modules';
import LeadHelper from 'components/modules/lead/LeadHelper';
import { reminderOption } from 'components/modules/crm/activities/form/ActivityHelper';

// GraphQL
import KeyStore from 'utils/KeyStore';
import GraphqlClient from 'services/apollo/GraphQLClient';
import ActivityQuery from 'services/graphQL/query/crm/ActivitiesQuery';
import ActivityMutation from 'services/graphQL/mutate/ActivityMutation';

const eventStatus = Object.keys(EventStatus).map((c) => ({ value: EventStatus[c], label: EventStatus[c] === EventStatus.SHOW ? 'Showed' : EventStatus[c] }));

const styles = (theme) => ({
    radioInfo: {
        color: theme.palette.info.main,
        '&.Mui-checked': {
            color: theme.palette.info.main,
        },
    },
    avatarSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1.5),
    },
    switchButton: {
        marginBottom: theme.spacing(2),
    },
    formControlLabel: {
        marginRight: 0,
    },
    loading: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 9,
        background: theme.palette.background.white,
    },
    noteLabel: {
        color: theme.palette.text.gray,
        fontSize: '14px',
    },
    //
    dataItem: {
        display: 'flex',
        padding: '5px 0px',
        '& > dt': {
            minWidth: '150px',
            textAlign: 'right',
        },
    },
    label: {
        color: theme.palette.text.minsk,
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        marginRight: theme.spacing(1),
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    time: {
        justifyContent: 'center',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
        '& button': {
            marginLeft: theme.spacing(1),
        },
    },
    row: {
        '& .form-group': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        '& .form-group label': {
            marginBottom: 0,
            marginRight: theme.spacing(1),
            minWidth: '150px',
            textAlign: 'right',
        },
        '& .form-group .form-control': {
            boxShadow: 'none',
            border: 'none',
            height: '22px',
            lineHeight: '18px',
            padding: 0,
            width: 100,
            color: theme.palette.text.gray,
        },
        '& .rc-time-picker-input': {
            border: 'none',
            textAlign: 'center',
            padding: 0,
            color: theme.palette.text.gray,
        },
        '& .am-time-picker.form-control': {
            width: 70,
        },
    },
    link: {
        textDecoration: 'none',
        color: '#007AFF',
    },
    smsIcon: {
        '& path': {
            fill: 'rgb(78, 181, 114)',
        },
    },
    iconContainer: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    message: {
        color: '#ed9a11',
    },
    block: {
        position: 'absolute',
        color: 'red',
    },
    textDropdown: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.gray,
        '&:after': {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderTop: `6px solid ${theme.palette.text.gray}`,
            display: 'block',
            marginLeft: theme.spacing(1),
        },
    },
    buttonSendMessage: {
        '&:disabled span svg path': {
            fill: 'rgba(0, 0, 0, 0.26)',
        },
    },
    overflowSubmenu: {
        maxHeight: '320px',
        overflow: 'auto',
    },
    tabContainer: {
        height: '300px',
    },
    to: {
        margin: theme.spacing(0, 0.5),
    },
    ...LeadTypeStyle,
    ...ButtonStyles.getStyle(theme),
});
class EventEditForm extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphqlClient();
        this.state = {
            data: {
                subject: '',
                assignee: new KeyStore().getUserId(),
                note: '',
                reminderOn: null,
                start: moment().toDate(),
                end: moment().toDate(),
                startTime: null,
                endTime: null,
                status: '',
            },
            opportunity: {},
            prospect: {},
            openMessageDialog: false,
            assignee: [],
            open: false,
            openConfirm: false,
        };

        this.initBind();
    }

    componentDidMount() {
        const { crmId, activityId } = this.props;
        this.setState({
            loading: true,
        });
        this.graphqlClient.query(ActivityQuery.GET_ACTIVITY_EVENT_BY_ID, { crmId, activityId })
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getActivityEventById) {
                    const { getActivityEventById } = data;
                    const { opportunity = {}, reminder = {} } = getActivityEventById;
                    const reminderOn = reminder && DateUtils.isValid(reminder.reminderOn)
                        ? DateUtils.diff(getActivityEventById.realizationDate, reminder.reminderOn, 'minutes') / 60 : 0;

                    this.setState((prevState) => ({
                        data: update(prevState.data, {
                            note: { $set: getActivityEventById.note },
                            subject: { $set: getActivityEventById.subject },
                            assignee: { $set: getActivityEventById.assignee },
                            status: { $set: getActivityEventById.event?.status },
                            reminderOn: { $set: reminderOption.map((item) => item.value).includes(reminderOn) ? reminderOn : 0 },
                            start: { $set: DateUtils.toLocal(getActivityEventById?.event.start)?.toDate() },
                            startTime: { $set: DateUtils.toLocal(getActivityEventById?.event.start) },
                            endTime: { $set: DateUtils.toLocal(getActivityEventById?.event.end) },
                        }),
                        opportunity: update(prevState.opportunity, {
                            crmId: { $set: opportunity.crmId },
                            vehiclePrice: { $set: opportunity.vehiclePrice },
                            vehicleModel: { $set: opportunity.vehicleModel },
                            vehicleTrim: { $set: opportunity.vehicleTrim },
                            vehicleYear: { $set: opportunity.vehicleYear },
                            vehicleMake: { $set: opportunity.vehicleMake },
                            leadCode: { $set: opportunity.leadCode },
                            leadType: { $set: opportunity.leadType },
                            dealType: { $set: opportunity.dealType },
                            leadSource: { $set: opportunity.leadSource },
                            recordManager: { $set: opportunity.recordManager },
                            availableCash: { $set: opportunity.availableCash },
                            lotId: { $set: opportunity.lotId },
                            vehicle: { $set: opportunity.vehicle },
                            chosenVehiclePrice: { $set: opportunity.chosenVehiclePrice },
                        }),
                        prospect: opportunity.prospect,
                    }));
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
                this.getRecordManagersAndSalespersonByLots();
            });
    }

    onDataChange(value = '', field) {
        this.setState((prevState) => update(prevState, {
            data: { [field]: { $set: value } },
        }));
    }

    onChangeDate(value) {
        const { data } = this.state;
        const startTime = moment(data.startTime);
        const endTime = moment(data.endTime);
        const currentEndTime = data.endTime != null ? moment(value).set({ minute: endTime.get('minute'), hour: endTime.get('hour') }) : null;
        const currentStartTime = moment(value).set({ minute: startTime.get('minute'), hour: startTime.get('hour') });

        this.setState((prevState) => update(prevState, {
            data: {
                start: { $set: value },
                endTime: { $set: currentEndTime },
                startTime: { $set: currentStartTime },
            },
        }));
    }

    onChangeStartTime(value) {
        const generatedEndTime = DateUtils.add(value, 1, 'hours');
        this.setState((prevState) => update(prevState, {
            data: {
                startTime: { $set: value },
                endTime: { $set: moment(value).set({ minute: generatedEndTime.get('minute'), hour: generatedEndTime.get('hour') }) },
            },
        }));
    }

    onChangeEndTime(value) {
        const { data } = this.state;

        if (data.endTime === null) {
            const time = moment(value, 'HH:mm');
            const currentEndTime = moment(data.start).set({
                hour: time.get('hour'),
                minute: time.get('minute'),
            });
            this.setState((prevState) => update(prevState, {
                data: { endTime: { $set: currentEndTime } },
            }));
        } else {
            this.setState((prevState) => update(prevState, {
                data: { endTime: { $set: value } },
            }));
        }
    }

    onUpdate() {
        const {
            state: {
                data: {
                    subject, start, endTime, status, note,
                    assignee,
                },
                data,
            },
        } = this;
        const { activityId, crmId, onClose } = this.props;

        const currentStart = DateUtils.formatUTC(start);

        const input = {
            processRecordId: crmId,
            subject: subject.trim(),
            assignee,
            note: note.trim(),
        };

        const currentStartTime = data.startTime ? moment(currentStart).set({
            hour: data.startTime.hour(), minute: data.startTime.minute(), second: 0, millisecond: 0,
        }) : currentStart;

        const currentEndTime = endTime ? moment(currentStart).set({
            hour: endTime.hour(), minute: endTime.minute(), second: 0, millisecond: 0,
        }) : currentStart;
        const startTime = DateUtils.formatUTC(currentStartTime);

        if ([EventStatus.SCHEDULED?.toUpperCase(), EventStatus.CONFIRMED.toUpperCase()].includes(data.status?.toUpperCase())) {
            input.reminderOn = isFinite(data.reminderOn) && data.reminderOn !== 0
                ? DateUtils.subtractAndFormatUTC(startTime, data.reminderOn, 'hours') : null;
        }

        input.realizationDate = startTime;
        input.event = {
            start: startTime,
            end: DateUtils.formatUTC(currentEndTime),
            status,
        };

        this.setState({ isSaving: true });
        this.graphqlClient
            .mutate(ActivityMutation.UPDATE_EVENT_ACTIVITY, { input, activityId })
            .then((response) => {
                const { data: result, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (result?.updateEventActivity) {
                    onClose();
                }
            })
            .finally(() => {
                this.setState({ isSaving: false });
            });
    }

    getRecordManagersAndSalespersonByLots() {
        const { opportunity } = this.state;

        this.graphqlClient.query(UserQuery.GET_RECORD_MANAGERS_AND_SALESPERSON_BY_LOTS, { lots: [opportunity.lotId] })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getRecordManagersAndSalespersonByLots) {
                    const assignee = data.getRecordManagersAndSalespersonByLots.map((item) => ({
                        value: item.userId,
                        label: `${item.firstName} ${item.lastName}`,
                    }));
                    this.setState({
                        assignee,
                    });
                }
            });
    }

    toggleSendMessage(value) {
        this.setState({
            openMessageDialog: value,
        });
    }

    toggleNote(value) {
        this.setState({
            open: value,
        });
    }

    toggleConfirm(value) {
        this.setState({
            openConfirm: value,
        });
    }

    removeAppointment() {
        const { activityId, crmId, onClose } = this.props;
        this.graphqlClient
            .mutate(ActivityMutation.DELETE_ACTIVITY, { activityId, processRecordId: crmId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.deleteActivity) {
                    onClose();
                }
            });
    }

    initBind() {
        this.onUpdate = this.onUpdate.bind(this);
        this.toggleNote = this.toggleNote.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.onChangeEndTime = this.onChangeEndTime.bind(this);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.removeAppointment = this.removeAppointment.bind(this);
        this.toggleSendMessage = this.toggleSendMessage.bind(this);
    }

    render() {
        const {
            data, loading, opportunity, prospect = {}, openConfirm,
            isSaving, openMessageDialog, assignee, open,
        } = this.state;
        const isValidData = isValidSchema(ActivityEventSchema, { ...data, startTime: data.startTime?.toDate(), endTime: data.endTime?.toDate() });
        const { isValid, errors } = isValidData;
        const {
            classes, onClose, crmId,
        } = this.props;
        const placeholder = '--';
        const leadType = LeadHelper.getLeadType(opportunity?.leadType);
        const typeIcon = LeadHelper.getLeadTypeIcon(leadType);
        const downPayment = opportunity.availableCash ?? 0;
        const reminder = find(reminderOption, { value: data.reminderOn }) || {};
        const disabledReminder = ![EventStatus.SCHEDULED.toUpperCase(), EventStatus.CONFIRMED.toUpperCase()].includes(data.status?.toUpperCase());
        const config = [
            {
                title: 'Notes',
                component: <NoteTable crmId={crmId} />,
            },
            {
                title: 'Email',
                component: <EmailHistory opportunityId={crmId} />,
            },
            {
                title: 'History',
                component: <HistoryTab opportunityId={crmId} />,
            },
        ];

        return (
            <ActivityContent
                title="Event"
                onClose={onClose}
                onSave={this.onUpdate}
                deleteButton={<Button variant="contained" className={classes.containedError} onClick={() => this.toggleConfirm(true)}>Delete</Button>}
                disabledSaveButton={!isValid || isSaving}
            >
                <Form>
                    <Form.Row className={classes.row}>
                        <Form.Group className="col-md-6">
                            <Form.Label>Status: </Form.Label>
                            <Dropdown
                                asLabel
                                actions={eventStatus}
                                classText={classes.textDropdown}
                                classNameSubMenu={classes.subMenu}
                                onSelect={(e) => this.onDataChange(e.value, 'status')}
                                value={find(eventStatus, { value: data.status })?.label}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="col-md-6">
                            <Form.Label>Date:</Form.Label>
                            <DatePicker
                                className={clsx('form-control', { 'invalid-field': isValidField(errors, 'start') })}
                                selected={data.start}
                                popperContainer={CalendarContainer}
                                onChange={(date) => this.onChangeDate(date)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.row}>
                        <Form.Group as={Col} className="col-md-6">
                            <Form.Label>Assignee:</Form.Label>
                            <Dropdown
                                asLabel
                                actions={assignee}
                                classText={classes.textDropdown}
                                classNameSubMenu={classes.overflowSubmenu}
                                onSelect={(e) => this.onDataChange(e.value, 'assignee')}
                                value={find(assignee, { value: data.assignee })?.label || 'None'}
                            />
                        </Form.Group>
                        <Form.Group className={clsx('col-md-6', classes.time)}>
                            <span>From:</span>
                            <TimePicker
                                use12Hours
                                minuteStep={15}
                                showSecond={false}
                                value={data.startTime}
                                placeholder="hh:mm"
                                onChange={(e) => this.onChangeStartTime(e)}
                                className="am-time-picker form-control"
                            />
                            <span className={classes.to}>to</span>
                            <TimePicker
                                use12Hours
                                minuteStep={15}
                                showSecond={false}
                                value={data.endTime}
                                placeholder="hh:mm"
                                disabled={data.startTime === null}
                                onChange={(e) => this.onChangeEndTime(e)}
                                className={clsx('am-time-picker form-control', { 'invalid-field': isValidField(errors, 'endTime') })}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={classes.row}>
                        <Form.Group as={Col} className="col-md-6" />
                        <Form.Group as={Col} className="col-md-6">
                            <Form.Label>Reminder:</Form.Label>
                            <Dropdown
                                asLabel
                                actions={reminderOption}
                                disabled={disabledReminder}
                                classText={classes.textDropdown}
                                value={reminder?.label || 'None'}
                                classNameSubMenu={classes.subMenu}
                                onSelect={(e) => this.onDataChange(e.value, 'reminderOn')}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item component="dl" xs={6}>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Customer: </dt>
                            <dd className={classes.value}>{`${prospect.firstName || ''} ${prospect.lastName || ''}` || placeholder}</dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Email: </dt>
                            <dd className={classes.value}>{prospect.email || placeholder}</dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Cell Phone: </dt>
                            <dd className={classes.value}>{prospect.cellPhone || placeholder}</dd>
                            <div className={classes.iconContainer}>
                                {!prospect.allowTextCellPhone && <BlockOutlined className={classes.block} />}
                                <InsertCommentOutlined fontSize="small" className={classes.message} />
                            </div>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Home Phone: </dt>
                            <dd className={classes.value}>{prospect.homePhone || placeholder}</dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Work Phone: </dt>
                            <dd className={classes.value}>{prospect.workPhone || placeholder}</dd>
                        </div>
                    </Grid>
                    <Grid item component="dl" xs={6}>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Opportunity Code: </dt>
                            <dd className={clsx(classes.value, classes.noWrap)}>
                                <RouterLink to={{ pathname: `/${modules.OPPORTUNITIES}/${crmId}` }} className={classes.link} target="_blank">
                                    {opportunity.leadCode}
                                </RouterLink>
                            </dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Lead Source: </dt>
                            <dd className={clsx(classes.value, classes.noWrap)} title={opportunity.leadSource}>{opportunity.leadSource || placeholder}</dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Deal Type: </dt>
                            <dd className={classes.value}>{opportunity.dealType || placeholder}</dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Lead Type: </dt>
                            <dd className={clsx(classes.value, classes.leadType, typeIcon.name)}>
                                {typeIcon.icon}
                                <span>{leadType || placeholder}</span>
                            </dd>
                        </div>
                        <div className={classes.dataItem}>
                            <dt className={classes.label}>Down Payment: </dt>
                            <dd className={classes.value}>{NumberUtils.applyCurrencyFormat(downPayment, '$0,0')}</dd>
                        </div>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <InterestVehicleCard
                    record={opportunity}
                />
                <Divider className={classes.divider} />
                <div className={classes.actionContainer}>
                    <Button
                        color="primary"
                        size="small"
                        className={classes.buttonSendMessage}
                        disabled={!prospect.allowTextCellPhone}
                        onClick={() => this.toggleSendMessage(true)}
                        startIcon={<SMSIcon className={classes.smsIcon} />}
                    >
                        Send SMS
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        className={classes.buttonSendMessage}
                        onClick={() => this.toggleNote(true)}
                        startIcon={<NoteIcon />}
                    >
                        Add Note
                    </Button>
                </div>
                <div className={clsx('d-flex-column overflow-hidden tab-container', classes.tabContainer)}>
                    <Tabs
                        id="controlled-tab"
                        mountOnEnter
                        className={classes.tab}
                    >
                        {config.map((item, index) => (
                            <Tab eventKey={index} mountOnEnter title={item.title} key={index}>
                                {item.component}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                {openMessageDialog && (
                    <MessageDialog
                        crmId={crmId}
                        open={openMessageDialog}
                        onClose={() => this.toggleSendMessage(false)}
                        leadCode={opportunity.leadCode}
                    />
                )}
                {loading && <Loading className={classes.loading} />}
                {open && (
                    <NoteForm
                        crmId={crmId}
                        open={open}
                        onClose={() => this.toggleNote(false)}
                    />
                )}
                <ConfirmDialog
                    title="Confirm remove appointment"
                    description="Are you sure you want to remove this appointment?"
                    open={openConfirm}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    onClose={() => this.toggleConfirm(false)}
                    onClickSecondary={() => this.toggleConfirm(false)}
                    onClickPrimary={this.removeAppointment}
                />
            </ActivityContent>
        );
    }
}

EventEditForm.propTypes = {
    activityId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

EventEditForm.defaultProps = {
    activityId: null,
};

export default withStyles(styles)(EventEditForm);

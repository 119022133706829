import React, { Component } from 'react';

// Components and Others
import { map } from 'lodash';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Loading from 'components/widgets/Loading';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DialogActions from 'components/widgets/modal/DialogActions';
import EmploymentDialog from 'components/widgets/customer/EmploymentDialog';
import EmploymentContent from 'components/widgets/customer/EmploymentContent';
import ManageEmploymentContainer from 'components/containers/customer/read/ManageEmploymentContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, Button,
} from '@material-ui/core';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => CustomerStyles.manageAddress(theme);

class ManageEmployment extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    onClickMakeCurrent(record) {
        const { props: { setCurrentEmployment } } = this;
        const { customerId, isCurrentEmployment, customerEmploymentId } = record;

        if (!isCurrentEmployment) {
            setCurrentEmployment({ customerId, customerEmploymentId });
        }
    }

    renderItem(record, index) {
        const { props: { classes, onEdit, onOpenConfirm }, CUSTOMER_WRITE } = this;
        const { isCurrentEmployment, customerEmploymentId } = record;

        return (
            <Grid container spacing={0} className="row-address" key={index}>
                <Grid
                    item
                    className={classes.customer}
                    xs={9}
                >
                    <EmploymentContent mainInformation record={record} />
                </Grid>
                <Grid
                    item
                    className={`${classes.customer} ${classes.actionPanel}`}
                    xs={3}
                >
                    <div>
                        <Button
                            color="primary"
                            size="small"
                            className={isCurrentEmployment ? 'current-address' : 'make-current'}
                            onClick={() => this.onClickMakeCurrent(record)}
                            disabled={!CUSTOMER_WRITE}
                        >
                            {isCurrentEmployment ? 'Current Employment' : 'Make Current'}
                        </Button>
                    </div>
                    {CUSTOMER_WRITE && (
                        <div>
                            <Button
                                color="primary"
                                size="small"
                                className="edit"
                                onClick={() => onEdit(record)}
                            >
                                Edit
                            </Button>
                            {isCurrentEmployment ? null : (
                                <Button color="primary" size="small" className="delete" onClick={() => onOpenConfirm(customerEmploymentId)}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    }

    render() {
        const {
            CUSTOMER_WRITE,
            props: {
                open, classes, onClose, records,
                onOpenEmployment, load, openConfirm,
                onCloseConfirm, deleteCustomerEmployment,
                openEmployment, customerId, currentEmployment,
                getServicesData, isEditing, onCloseEmployment,
            },
        } = this;
        const newEmploymentButton = CUSTOMER_WRITE ? (
            <Button
                color="primary"
                size="small"
                className={classes.newAddressButton}
                startIcon={<AddIcon />}
                onClick={onOpenEmployment}
            >
                Add new employment
            </Button>
        ) : null;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Manage Employment" onClose={onClose} iconSize="sm" secondaryButton={newEmploymentButton} />
                <DialogContent className={classes.dialogContent}>
                    {map(records, (item, index) => this.renderItem(item, index))}
                    {load && <Loading />}
                </DialogContent>
                <DialogActions onClickPrimary={onClose} titlePrimary="Close" hiddenSecondaryButton variant="outlined" />
                {openEmployment && (
                    <EmploymentDialog
                        open={openEmployment}
                        isEditing={isEditing}
                        customerId={customerId}
                        record={currentEmployment}
                        callback={getServicesData}
                        onClose={onCloseEmployment}
                    />
                )}
                <ConfirmDialog
                    title="Confirm delete employment"
                    description="Are you sure you want to remove this employment?"
                    open={openConfirm}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={onCloseConfirm}
                    onClickSecondary={onCloseConfirm}
                    onClickPrimary={deleteCustomerEmployment}
                />
            </Dialog>
        );
    }
}

ManageEmployment.propTypes = {
    open: PropTypes.bool.isRequired,
    load: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    customerId: PropTypes.string.isRequired,
    onOpenConfirm: PropTypes.func.isRequired,
    onCloseConfirm: PropTypes.func.isRequired,
    openEmployment: PropTypes.bool.isRequired,
    getServicesData: PropTypes.func.isRequired,
    onOpenEmployment: PropTypes.func.isRequired,
    onCloseEmployment: PropTypes.func.isRequired,
    currentEmployment: PropTypes.object.isRequired,
    setCurrentEmployment: PropTypes.func.isRequired,
    deleteCustomerEmployment: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(ManageEmploymentContainer(ManageEmployment));

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { map } from 'lodash';
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { ReactComponent as RemoveOutlined } from 'assets/remove.svg';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DeferredDownPaymentDialogContainer from 'components/containers/deals/create/dealTab/DeferredDownPaymentDialogContainer';

// Material UI
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { withStyles, fade } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, IconButton,
} from '@material-ui/core';
import DateUtils from 'lib/DateUtils';
import clsx from 'clsx';

const styles = (theme) => DealStyles.dealsForms(theme, fade);

class DeferredDownPaymentDialog extends Component {
    disableApplyButton() {
        const { props: { deferredPayments } } = this;

        if (deferredPayments.length > 1) {
            return deferredPayments.some((item) => !item.paymentAmount || item.paymentAmount === 0 || !DateUtils.isValid(item.paymentDate));
        }
        if (deferredPayments.length === 1 && deferredPayments[0].paymentAmount > 0 && !DateUtils.isValid(deferredPayments[0].paymentDate)) return true;

        return false;
    }

    renderButtonDelete(index, deferredDownPaymentId, showDeleteButton) {
        const { props: { classes, showConfirm } } = this;

        if (showDeleteButton) {
            return (
                <IconButton
                    aria-label="delete"
                    size="small"
                    className={clsx(classes.iconButtonDelete, 'show-hidden-item')}
                    onClick={() => showConfirm(index, deferredDownPaymentId)}
                >
                    <RemoveOutlined className={classes.deleteIcon} />
                </IconButton>
            );
        }

        return <div className={classes.spaceButtonDelete} />;
    }

    renderRow() {
        const { props: { deferredPayments, onChangeValue, classes } } = this;

        const showDeleteButton = deferredPayments.length > 1;

        return map(deferredPayments, (item, index) => {
            const { paymentDate, paymentAmount } = item;
            const countDeferredDownPayment = index + 1;

            return (
                <Form.Row key={`${paymentDate}${index}`} className="show-icon-when-hovering">
                    <Form.Group as={Col} className={classes.deferredDownPaymentContainer}>
                        <div>
                            <Form.Label className={classes.labelDialogDeferred}>{`Deferred Down ${countDeferredDownPayment}`}</Form.Label>
                            <div className={clsx(classes.inputDialogDeferred, 'content-plus-input')}>
                                <InputNumber
                                    value={paymentAmount || ''}
                                    onChange={(value) => onChangeValue('paymentAmount', value, index)}
                                    placeholder="0.00"
                                    thousandSeparator
                                    showCurrency
                                    size="sm"
                                />
                                <Form.Label className={clsx(classes.labelDialogDeferred, 'plus-right-input')}>+</Form.Label>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} className="full-width-date-picker">
                        <Form.Label className={classes.labelDialogDeferred}>Due Date</Form.Label>
                        <div className="with-icon-right">
                            <DatePicker
                                className={clsx(classes.labelDialogDeferred, 'form-control', 'form-control-sm')}
                                selected={paymentDate}
                                placeholderText="mm/dd/yyyy"
                                onChange={(date) => onChangeValue('paymentDate', date, index)}
                            />
                            {this.renderButtonDelete(index, item.deferredDownPaymentId, showDeleteButton)}
                        </div>
                    </Form.Group>
                </Form.Row>
            );
        });
    }

    renderButtonAdd() {
        const { props: { deferredPayments, classes, addRow } } = this;
        let disableButtonAdd = false;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < deferredPayments.length; i++) {
            if (deferredPayments[i].paymentAmount === 0
                || deferredPayments[i].paymentAmount === null
                || !DateUtils.isValid(deferredPayments[i].paymentDate)) {
                disableButtonAdd = true;
                break;
            }
        }
        if (deferredPayments.length < 3) {
            return (
                <div className={classes.contentAddRow}>
                    <div className={classes.spaceButtonAdd} />
                    <IconButton
                        aria-label="add"
                        size="small"
                        className={clsx(classes.textSuccess, classes.addButton)}
                        onClick={addRow}
                        disabled={disableButtonAdd}
                    >
                        <AddCircleIcon />
                    </IconButton>
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            props: {
                classes, toggleModal, open, onApply,
                onDelete, cancelConfirm, openConfirm,
            },
        } = this;
        const disableApplyButton = this.disableApplyButton();

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                className={classes.deferredDownPaymentWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Deferred Down Payments" onClose={toggleModal} />
                <DialogContent className={classes.dialogContentDeferredDownPayment}>
                    <Form className={classes.formW100}>
                        {this.renderRow()}
                    </Form>
                    {this.renderButtonAdd()}
                </DialogContent>
                <DialogActions
                    titlePrimary="Apply"
                    disablePrimaryButton={disableApplyButton}
                    onClickSecondary={toggleModal}
                    onClickPrimary={onApply}
                />
                <ConfirmDialog
                    title="Confirm remove deferred down payment"
                    description="Are you sure you want to remove this deferred down payment?"
                    open={openConfirm}
                    showHeader
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    dividerFooter={false}
                    onClickPrimary={onDelete}
                    onClose={cancelConfirm}
                    onClickSecondary={cancelConfirm}
                />
            </Dialog>
        );
    }
}

DeferredDownPaymentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    addRow: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showConfirm: PropTypes.func.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    cancelConfirm: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    deferredPayments: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(DeferredDownPaymentDialogContainer(DeferredDownPaymentDialog));

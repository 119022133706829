import React from 'react';
import PropTypes from 'prop-types';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';
import DisplaySelect from 'components/modules/inventory/create/panels/DisplaySelect';

import StringUtils from 'lib/StringUtils';
import InventoryHelper from 'utils/InventoryHelper';

const useStyles = makeStyles({
    root: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
});

const AdvertisingSetupEditPanel = (props) => {
    const classes = useStyles();
    const { data, writePermission } = props;

    if (!writePermission) {
        return null;
    }

    const reducePrice = data.enableReduce
        && !StringUtils.isEmpty(data.reducePrice)
        && data.reducePrice !== '0' ? data.reducePrice : '';
    const reduceFrequency = data.enableReduce && !StringUtils.isEmpty(data.reduceFrequency) ? data.reduceFrequency : '';
    let reducedPriceOptions = InventoryHelper.reducedPrice;
    const reduceFrequencyOptions = InventoryHelper.reduceFrequency;

    if (!data.enableReduce) {
        reducedPriceOptions = [
            {
                label: 'Not Setup',
                value: '',
            },
        ];
    }

    return (
        <Grid
            container
            orientation="column"
            className={classes.root}
        >
            <Grid container item xs={12} sm={6}>
                <Grid item xs={12} sm={5}>
                    <FormLabel>Place online ads for this vehicle:</FormLabel>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Checkbox
                        checked={data.placeAd}
                        color="primary"
                        size="small"
                        onChange={(_, value) => props.onChange('placeAd', value)}
                    />
                </Grid>
            </Grid>
            <DisplayInput
                label="Downpayment"
                value={data.websiteDownpayment}
                onChange={(value) => props.onChange('websiteDownpayment', value)}
            />
            <DisplaySelect
                label={(
                    <>
                        <FormLabel>Reduced Price:</FormLabel>
                        <Checkbox
                            checked={data.enableReduce}
                            color="primary"
                            size="small"
                            onChange={(_, value) => props.onChange('enableReduce', value)}
                        />
                    </>
                )}
                value={reducePrice}
                name="reducePrice"
                onChange={(value) => props.onChange('reducePrice', value)}
                options={reducedPriceOptions}
                disabled={!data.enableReduce}
            />
            <DisplayInput
                label="Payment"
                value={data.websitePayment}
                onChange={(value) => props.onChange('websitePayment', value)}
            />
            <DisplaySelect
                label="Every"
                value={reduceFrequency}
                name="reduceFrequency"
                onChange={(value) => props.onChange('reduceFrequency', value)}
                options={reduceFrequencyOptions}
                disabled={!data.enableReduce}
            />
            <DisplaySelect
                label="Frequency"
                value={data.paymentFrequency}
                name="paymentFrequency"
                onChange={(value) => props.onChange('paymentFrequency', value)}
                options={InventoryHelper.frequency}
            />
            <DisplaySelect
                label="Remove ad"
                value={data.removeAd}
                name="removeAd"
                onChange={(value) => props.onChange('removeAd', value)}
                options={InventoryHelper.removeAd}
            />
        </Grid>
    );
};

AdvertisingSetupEditPanel.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    writePermission: PropTypes.bool,
};

AdvertisingSetupEditPanel.defaultProps = {
    data: {},
    writePermission: false,
    onChange: () => {},
};

export default AdvertisingSetupEditPanel;

import React, {
    useReducer, useRef, useEffect, useContext, useCallback,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import CloseIcon from '@material-ui/icons/Close';
import { ClearIcon, SaveIcon } from 'components/icons';
import { Col, Form } from 'react-bootstrap';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import JournalSchema from 'utils/schema/accounting/journal/JournalSchema';
import { useLazyQuery, useMutation } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { AccountingSubModules, RecurringType } from 'utils/enum/AccountingEnum';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { FetchPolicy } from 'utils/enum/Core';
import useValidateActions from 'components/modules/accounting/banking/hooks/useValidateActions';

import 'react-datepicker/dist/react-datepicker.css';
import { RefreshOutlined } from '@material-ui/icons';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import GeneralJournalDetailDistribution from 'components/modules/accounting/journal/create/generalJournal/GeneralJournalDetailDistribution';
import DialogActionMessage from 'components/widgets/DialogActionMessage';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const keyStore = new KeyStore();

const frequencyData = [
    { label: 'Daily', value: 'DAILY' },
    { label: 'Weekly', value: 'WEEKLY' },
    { label: 'Monthly', value: 'MONTHLY' },
];

const dropdownEndData = [
    { label: 'End After', value: RecurringType.END_AFTER },
    { label: 'End By', value: RecurringType.END_BY },
    { label: 'Indefinitely', value: RecurringType.INDEFINITELY },
];

const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AMOUNT: 'setAmount',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
};

const CheckDepositReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.value };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_AMOUNT: {
        if (state.record.amount === action.value) return { ...state };

        return {
            ...state,
            record: {
                ...state.record,
                amount: action.value,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const GeneralJournalCreate = () => {
    const { id } = useParams();
    const history = useHistory();

    const ACCOUNTING_GENERAL_JOURNAL_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_GENERAL_JOURNAL_WRITE);

    const initialState = {
        record: {
            journalId: '',
            postedDate: new Date(),
            referenceNumber: 0,
            memo: '',
            debit: 0.0,
            credit: 0.0,
        },
        recurringData: {
            frequencyType: '',
            frequency: 0,
            startDate: new Date(),
            recurringType: '',
            endBy: new Date(),
            endAfter: 0,
            recurringJournalId: '',
        },
        isDirty: false,
        isNew: true,
        couldLostData: false,
        showRecurringForm: false,
        hasRecurringData: false,
        recurringBackUp: {},
        showDialogDuplicateReference: false,
        copyMemo: false,
    };

    const classes = useStyle();
    const journalRef = useRef();

    const [state, dispatch] = useReducer(CheckDepositReducer, initialState);
    const {
        record, isDirty, couldLostData, showRecurringForm,
        recurringData, hasRecurringData, recurringBackUp,
        showDialogDuplicateReference, copyMemo,
    } = state;

    const isEditing = !isEmpty(record.journalId);

    const isValidData = isValidSchema(JournalSchema, { ...record, ...recurringData, showRecurringForm });
    const { isValid, errors } = isValidData;

    const { userInformation, defaultLotId, defaultLot } = useContext(UserContext);

    if (defaultLotId && defaultLotId > 0 && record.lotId === 0) {
        dispatch({ type: ACTION_TYPES.SET_DEFAULT_LOT, value: defaultLotId });
    }

    const {
        editMemo,
        editDate,
        allowSaveAction,
        glOptions,
    } = useValidateActions('', '');

    const [getLastReference] = useCallback(useLazyQuery(AccountingGLQuery.NEXT_REFERENCE_NUMBER, {
        onCompleted: (res) => {
            if (res.nextJournalReferenceNumber) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        referenceNumber: res.nextJournalReferenceNumber,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    }), []);

    const [getJournal, { loading }] = useCallback(useLazyQuery(AccountingGLQuery.GENERAL_JOURNAL_DETAIL, {
        onCompleted: (res) => {
            if (res.journalDetail) {
                const { journalDetail } = res;

                const journalData = journalDetail ? { ...journalDetail } : {};
                journalData.debit = 0;
                journalData.credit = 0;
                const { recurring, postedDate } = journalData;
                const recurringDataDB = recurring ? { ...recurring } : null;

                if (recurringDataDB) {
                    delete recurringDataDB.__typename;

                    if (typeof recurringDataDB.startDate === 'string' && !isEmpty(recurringDataDB.startDate)) {
                        recurringDataDB.startDate = new Date(recurringDataDB.startDate);
                    }

                    if (typeof recurringDataDB.endBy === 'string' && !isEmpty(recurringDataDB.endBy)) recurringDataDB.endBy = new Date(recurringDataDB.endBy);

                    const recurringType = recurringDataDB.recurringType || '';
                    recurringDataDB.recurringType = recurringType.toLowerCase();
                }

                if (typeof postedDate === 'string' && !isEmpty(postedDate)) journalData.postedDate = new Date(postedDate);

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: { ...journalData },
                        showRecurringForm: !isEmpty(recurringDataDB),
                        hasRecurringData: !isEmpty(recurringDataDB),
                        recurringData: !isEmpty(recurringDataDB) ? recurringDataDB : recurringData,
                        recurringBackUp: !isEmpty(recurringDataDB) ? recurringDataDB : {},
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    }), []);

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onPopupClose = (event, forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.JOURNALS}`);
    };

    const isDuplicateReferecenNumber = (data) => !isEmpty(data.filter((item) => item.extensions && item.extensions.code === '100'));

    const [addRecord, { loading: isSaving }] = useMutation(AccountingGLQuery.ADD_GENERAL_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.addJournal) {
                ModalUtils.successMessage(null, 'Record created successfully!');

                onPopupClose(null, true);
            } else {
                ModalUtils.errorMessage(null, 'There was an error trying to save this record.');
            }
        },
        onError: ({ graphQLErrors }) => {
            const isDuplicate = isDuplicateReferecenNumber(graphQLErrors);

            if (isDuplicate) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showDialogDuplicateReference: true,
                    },
                });
            } else {
                ModalUtils.errorMessage(graphQLErrors);
            }
        },
    });

    const [updateRecord, { loading: isUpdating }] = useMutation(AccountingGLQuery.UPDATE_GENERAL_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateJournal) {
                ModalUtils.successMessage(null, 'Record updated successfully!');

                onPopupClose(null, true);
            } else {
                ModalUtils.errorMessage(null, 'There was an error trying to save this record.');
            }
        },
        onError: ({ graphQLErrors }) => {
            const isDuplicate = isDuplicateReferecenNumber(graphQLErrors);

            if (isDuplicate) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        showDialogDuplicateReference: true,
                    },
                });
            } else {
                ModalUtils.errorMessage(graphQLErrors);
            }
        },
    });

    const onSave = async () => {
        const { records, errors: glLinesErrors } = journalRef.current.getRecords();

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'The GL lines are empty');
            return;
        }

        if (glLinesErrors.length > 0) {
            ModalUtils.errorMessage(glLinesErrors);
            return;
        }

        const debitAmount = records.reduce((sum, { debit }) => NumberUtils.round(sum + debit), 0);
        const creditAmount = records.reduce((sum, { credit }) => NumberUtils.round(sum + credit), 0);

        if (debitAmount !== creditAmount) {
            ModalUtils.errorMessage([{
                message: `The sum of debit and credit must be the same, there is a difference of 
                ${NumberUtils.applyCurrencyFormat(debitAmount - creditAmount)}`,
            }]);
            return;
        }

        const recordToSave = {
            referenceNumber: Number(record.referenceNumber),
            memo: record.memo,
            postedDate: new Date(record.postedDate).toISOString(),
        };

        // eslint-disable-next-line no-use-before-define
        const recurringDataToSave = getRecurringData();

        if (!isEditing) {
            recordToSave.createdBy = userInformation.username;
        }

        const journalDetail = [];
        records.forEach((item) => (
            journalDetail.push({
                accountNumber: Number(item.accountNumber),
                debit: Number(item.debit),
                credit: Number(item.credit),
                controlNumber: String(item.controlNumber).trim() || '',
                memo: item.memo.trim(),
                lotName: item.lotName.trim(),
            })));

        if (isEditing) {
            await updateRecord({
                variables: {
                    journalId: record.journalId,
                    journal: recordToSave,
                    journalDetail,
                    lotName: defaultLot,
                },
            });
        } else {
            await addRecord({
                variables: {
                    journal: recordToSave,
                    journalDetail,
                    lotName: defaultLot,
                    recurring: showRecurringForm ? recurringDataToSave : null,
                },
            });
        }
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        if (field === 'memo' && copyMemo) {
            journalRef.current.updatingMemo(value);
        }
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: { ...record, [field]: value },
        });
    };

    useEffect(() => {
        if (!isEmpty(id)) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: {
                    ...record,
                    journalId: id,
                },
            });

            getJournal({
                variables: {
                    journalId: id,
                },
            });
        } else getLastReference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getFrequencyLabel = (type = '') => {
        if (type === 'DAILY') {
            return 'day/s';
        } if (type === 'WEEKLY') {
            return 'week/s';
        } if (type === 'MONTHLY') {
            return 'month/s';
        }

        return '';
    };

    const validateifCanSave = () => {
        let canSave = false;

        if ((!isEditing
            && isValid
            && isDirty
            && !showRecurringForm
        ) || (!isEditing
            && isValid
            && isDirty
            && showRecurringForm
            && ((recurringData.recurringType === RecurringType.END_AFTER && Number(recurringData.endAfter) !== 0)
               || (recurringData.recurringType === RecurringType.END_BY && !isNull(recurringData.endBy)))
        ) || (isEditing
            && isValid
            && isDirty
        )) canSave = true;

        return canSave;
    };

    // #region Recurring methods

    const onChangeRecurringValue = (field, value) => {
        if (value === recurringData[field]) return;

        const currentRecurringData = { ...recurringData };

        currentRecurringData[field] = value;

        if (
            recurringData?.recurringType?.toLowerCase() === RecurringType.END_BY
            && field === 'startDate'
            && moment(value).isAfter(recurringData.endBy)
        ) {
            currentRecurringData.endBy = value;
        }

        if (field === 'recurringType' && value === RecurringType.END_BY) {
            currentRecurringData.endBy = recurringData.startDate;
            currentRecurringData.endAfter = null;
        } else if (field === 'recurringType' && value === RecurringType.END_AFTER) {
            currentRecurringData.endBy = null;
        } else if (field === 'recurringType' && value === RecurringType.INDEFINITELY) {
            currentRecurringData.endBy = null;
            currentRecurringData.endAfter = null;
        }

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                recurringData: currentRecurringData,
            },
        });
    };

    const isCompleteRequiredFields = () => {
        const {
            frequencyType,
            frequency,
            startDate,
            endAfter,
            endBy,
        } = recurringData;
        const recurringType = recurringData.recurringType.toLowerCase();

        if (recurringType === RecurringType.END_AFTER) {
            return (
                !isEmpty(frequencyType)
                && Number(frequency) > 0
                && DateUtils.isValid(startDate)
                && moment(startDate).isAfter(new Date())
                && Number(endAfter)
            );
        } if (recurringType === RecurringType.END_BY) {
            return (
                !isEmpty(frequencyType)
                && Number(frequency) > 0
                && DateUtils.isValid(startDate)
                && moment(startDate).isAfter(new Date())
                && DateUtils.isValid(endBy)
                && moment(endBy).isAfter(new Date())
            );
        } if (recurringType === RecurringType.INDEFINITELY) {
            return (
                !isEmpty(frequencyType)
                && Number(frequency) > 0
                && DateUtils.isValid(startDate)
                && moment(startDate).isAfter(new Date())
            );
        }

        return false;
    };

    const allowUpdate = () => {
        if (hasRecurringData) {
            const data = { ...recurringData };
            delete data.journalId;
            delete data.recurringJournalId;
            const currentBackup = { ...recurringBackUp };
            delete currentBackup.journalId;
            delete currentBackup.recurringJournalId;

            return !(JSON.stringify(data) === JSON.stringify(currentBackup));
        }

        return true;
    };

    const getRecurringData = () => {
        if (!showRecurringForm) return null;

        const recurringDataToSave = {
            frequencyType: recurringData.frequencyType || '',
            frequency: Number(recurringData.frequency) || '',
            recurringType: recurringData.recurringType || '',
            startDate: recurringData.startDate,
        };

        recurringDataToSave.endBy = recurringData.endBy && recurringData.recurringType === RecurringType.END_BY ? recurringData.endBy : null;
        recurringDataToSave.endAfter = recurringData.endAfter
        && recurringData.recurringType === RecurringType.END_AFTER ? (Number(recurringData.endAfter) || 0) : null;

        if (isEditing) {
            recurringDataToSave.journalId = record.journalId;
            recurringDataToSave.recurringJournalId = recurringData.recurringJournalId;
        }

        return recurringDataToSave;
    };

    const [getRecurringJournalId] = useLazyQuery(AccountingGLQuery.RECURRING_JOURNAL_ID, {
        onCompleted: (res) => {
            if (res.data) {
                const { journalDetail: { recurring } } = res.data;

                recurringData.recurringJournalId = recurring.recurringJournalId;

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        recurringData,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [createRecurring, { loading: isSavingRecurring }] = useMutation(AccountingGLQuery.SAVE_RECURRING_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveRecurringJournal) {
                ModalUtils.successMessage(null, 'Recurring information created successfully!');

                getRecurringJournalId({
                    variables: {
                        journalId: record.journalId,
                    },
                });

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        recurringBackUp: !isEmpty(recurringData) ? recurringData : {},
                        hasRecurringData: true,
                    },
                });
            } else {
                ModalUtils.errorMessage(null, 'There was an error trying to save the Recurring Journal.');
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        hasRecurringData: false,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    hasRecurringData: false,
                },
            });
        },
    });

    const [updateRecurring, { loading: isUpdatingRecurring }] = useMutation(AccountingGLQuery.SAVE_RECURRING_JOURNAL, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveRecurringJournal) {
                ModalUtils.successMessage(null, 'Recurring information updated successfully!');

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        recurringBackUp: !isEmpty(recurringData) ? recurringData : {},
                    },
                });
            } else {
                ModalUtils.errorMessage(null, 'There was an error trying to update the Recurring Journal.');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSaveRecurring = () => {
        if (isCompleteRequiredFields()) {
            const recurringDataToSave = getRecurringData();

            if (hasRecurringData) {
                updateRecurring({
                    variables: {
                        input: recurringDataToSave,
                    },
                });
            } else {
                delete recurringDataToSave.recurringJournalId;

                createRecurring({
                    variables: {
                        input: recurringDataToSave,
                    },
                });
            }
        } else {
            ModalUtils.errorMessage(null, 'Please complete the required fields in recurring section.');
        }
    };

    const onCloseDuplicateReferenceDialog = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                showDialogDuplicateReference: false,
            },
        });
    };

    const onContinueDuplicateReferenceDialog = () => {
        getLastReference();
        onCloseDuplicateReferenceDialog();
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    // #endregion

    return (
        <Dialog
            open
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        {ACCOUNTING_GENERAL_JOURNAL_WRITE
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!(validateifCanSave()) || isSaving || !allowSaveAction}
                                        onClick={onSave}
                                    >
                                        Save
                                    </Button>
                                )}
                    </div>
                    <div className={classes.centerItems}>
                        <Typography variant="h6" className={classes.title}>
                            { isEditing ? 'Edit General Journal' : 'New General Journal' }
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContentGeneralJournal}>
                { loading && <LoadingMask />}
                { !loading && (
                    <Container className={classes.containerSplit}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Form.Group as={Col}>
                                            <Form.Label className="required">Reference #</Form.Label>
                                            <Form.Control
                                                className={isValidField(errors, 'referenceNumber') ? 'invalid-field' : ''}
                                                size="sm"
                                                type="number"
                                                disabled
                                                value={record.referenceNumber}
                                                placeholder=""
                                                onChange={(e) => onChangeValue('referenceNumber', Number(e.target.value))}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Form.Group as={Col}>
                                            <Form.Label className="required">Post Date *</Form.Label>
                                            <DatePicker
                                                selected={record.postedDate}
                                                disabled={!editDate}
                                                name="date"
                                                size="sm"
                                                className={clsx('form-control form-control-sm',
                                                    classes.date, isValidField(errors, 'postedDate') ? 'invalid-field' : '')}
                                                wrapperClassName="col"
                                                placeholderText="mm/dd/yyyy"
                                                popperContainer={CalendarContainer}
                                                onChange={(date) => onChangeValue('postedDate', date)}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Form.Group as={Col}>
                                            <Form.Label className="required">
                                                <Form.Check
                                                    checked={copyMemo}
                                                    type="checkbox"
                                                    id="copyMemoCheckboxId"
                                                    label="Copy memo to every line"
                                                    className={classes.labelFont}
                                                    onChange={(e) => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { copyMemo: e.target.checked } })}
                                                />
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                disabled={!editMemo}
                                                className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                                                size="sm"
                                                rows="2"
                                                value={record.memo}
                                                placeholder="Memo"
                                                onChange={(e) => onChangeValue('memo', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!showRecurringForm && (
                                <Grid item xs={8}>
                                    <Button
                                        variant="contained"
                                        startIcon={<RefreshOutlined />}
                                        size="small"
                                        onClick={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { showRecurringForm: true } })}
                                    >
                                        MAKE RECURRING
                                    </Button>
                                </Grid>
                            )}
                            {showRecurringForm && (
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Frequency</Form.Label>
                                                <DropdownQuery
                                                    name="frequencyType"
                                                    value={recurringData.frequencyType}
                                                    placeHolder="select"
                                                    className={clsx('form-control-xs', classes.searchFull)}
                                                    onChange={(name, newValue) => onChangeRecurringValue('frequencyType', newValue === 0 ? '' : newValue)}
                                                    dataSource={{
                                                        localData: frequencyData,
                                                        idField: 'type',
                                                        descriptionField: 'type',
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {recurringData.frequencyType !== 0 && !isEmpty(recurringData.frequencyType) && (
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">
                                                        {`Every ${recurringData.frequency} ${getFrequencyLabel(recurringData.frequencyType)}`}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="frequency"
                                                        className={isValidField(errors, 'frequency') ? 'invalid-field' : ''}
                                                        size="sm"
                                                        type="number"
                                                        value={recurringData.frequency}
                                                        placeholder="0"
                                                        onChange={(e) => onChangeRecurringValue('frequency', Number(e.target.value))}
                                                    />
                                                </Form.Group>
                                            )}
                                        </Grid>
                                        <Grid item xs={4} className={clsx(classes.buttonSpacing, classes.textRight)}>
                                            {isEditing && ACCOUNTING_GENERAL_JOURNAL_WRITE
                                            && (
                                                <Button
                                                    className={classes.buttonSave}
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    size="small"
                                                    onClick={onSaveRecurring}
                                                    disabled={!isCompleteRequiredFields() || !allowUpdate()}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                            {!hasRecurringData
                                            && (
                                                <Button
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    size="small"
                                                    onClick={() => dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { showRecurringForm: false } })}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Start Date *</Form.Label>
                                                <DatePicker
                                                    selected={recurringData.startDate}
                                                    disabled={!editDate}
                                                    name="startDate"
                                                    size="sm"
                                                    className={clsx('form-control form-control-sm',
                                                        classes.date,
                                                        isValidField(errors, 'startDate') ? 'invalid-field' : '')}
                                                    wrapperClassName="col"
                                                    placeholderText="mm/dd/yyyy"
                                                    popperContainer={CalendarContainer}
                                                    onChange={(date) => onChangeRecurringValue('startDate', date)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">End</Form.Label>
                                                <DropdownQuery
                                                    name="endBy"
                                                    value={recurringData.recurringType}
                                                    placeHolder="select"
                                                    className={clsx('form-control-lg', classes.searchFull)}
                                                    onChange={(name, newValue) => onChangeRecurringValue('recurringType', newValue === 0 ? '' : newValue)}
                                                    dataSource={{
                                                        localData: dropdownEndData,
                                                        idField: 'type',
                                                        descriptionField: 'type',
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        {recurringData.recurringType === RecurringType.END_AFTER && (
                                            <Grid item xs={4}>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">Ocurrences</Form.Label>
                                                    <Form.Control
                                                        className={recurringData.recurringType === RecurringType.END_AFTER
                                                        && showRecurringForm
                                                        && Number(recurringData.endAfter) === 0 ? 'invalid-field' : ''}
                                                        size="sm"
                                                        type="number"
                                                        value={recurringData.endAfter}
                                                        placeholder="0"
                                                        onChange={(e) => onChangeRecurringValue('endAfter', Number(e.target.value))}
                                                    />
                                                </Form.Group>
                                            </Grid>
                                        )}
                                        {recurringData.recurringType === RecurringType.END_BY && (
                                            <Grid item xs={4}>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">End Date</Form.Label>
                                                    <DatePicker
                                                        selected={recurringData.endBy}
                                                        disabled={!editDate}
                                                        name="startDate"
                                                        size="sm"
                                                        minDate={recurringData.startDate}
                                                        className={clsx('form-control form-control-sm',
                                                            classes.date,
                                                            recurringData.recurringType === RecurringType.END_BY
                                                            && showRecurringForm
                                                            && isNull(recurringData.endBy) ? 'invalid-field' : '')}
                                                        placeholderText="mm/dd/yyyy"
                                                        popperContainer={CalendarContainer}
                                                        wrapperClassName="col"
                                                        onChange={(date) => onChangeRecurringValue('endBy', date)}
                                                    />
                                                </Form.Group>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <GeneralJournalDetailDistribution
                            journalId={record.journalId}
                            writePermissions={ACCOUNTING_GENERAL_JOURNAL_WRITE}
                            ref={journalRef}
                            defaultMemo={record.memo}
                            onDirty={() => { dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { isDirty: true } }); }}
                            glOptions={glOptions}
                        />
                        <ConfirmDialog
                            title="Attention - You have unsaved changes!"
                            description="Do you want to close without saving?"
                            open={couldLostData}
                            variant="outlined"
                            titlePrimary="Close"
                            titleSecondary="Cancel"
                            onClose={onCloseFormWithoutSave}
                            onClickSecondary={onCloseFormWithoutSave}
                            onClickPrimary={(e) => onPopupClose(e, true)}
                        />
                        <ConfirmDialog
                            title="Duplicate reference number!"
                            description="This reference number has already been used. It will be changed to continue."
                            open={showDialogDuplicateReference}
                            variant="outlined"
                            titlePrimary="OK"
                            titleSecondary="Cancel"
                            onClose={onCloseDuplicateReferenceDialog}
                            onClickSecondary={onCloseDuplicateReferenceDialog}
                            onClickPrimary={onContinueDuplicateReferenceDialog}
                        />
                    </Container>
                )}
            </DialogContent>
            {(isSaving || isUpdating) && <DialogActionMessage message="Saving information... " />}
            {(isSavingRecurring || isUpdatingRecurring) && <DialogActionMessage message="Saving recurring information... " />}
        </Dialog>
    );
};

export default GeneralJournalCreate;

export default class ChartStyle {
    static content(theme) {
        return ({
            chartWrapper: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '& > div.message': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    zIndex: 0,
                    position: 'absolute',
                    bottom: '30px',
                    left: 0,
                },
                '& > div.options': {
                    position: 'absolute',
                    top: 0,
                    right: '5px',
                    textAlign: 'end',
                    fontSize: '12px',
                    backgroundColor: theme.palette.background.white,
                    '& > button:nth-child(1)': {
                        marginRight: '10px',
                    },
                    '& > button': {
                        padding: 0,
                        minWidth: 'initial',
                        border: 0,
                        '& > span > span': {
                            margin: 0,
                            '& > svg': {
                                fontSize: '20px',
                            },
                        },
                    },
                },
                '& > div.tableStyle': {
                    top: '13px',
                },
                '& div.apexcharts-legend': {
                    maxHeight: '90%',
                },
            },
            noBorder: {
                borderBottom: 'initial !important',
            },
            input: {
                fontSize: '13px',
                '& > div:nth-child(1)': {
                    fontSize: '13px !important',
                },
            },
            '@global': {
                '.css-26l3qy-menu div, .css-2b097c-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
            },
            filtersParent: {
                display: 'flex',
                flexDirection: 'row',
                width: '92%',
                flexWrap: 'wrap',
                overflow: 'hidden',
                marginBottom: '10px',
                minHeight: '30px',
                flexShrink: 0,
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                    paddingTop: '60px',
                },
                '& > div': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginRight: '15px',
                    marginTop: '10px',
                    position: 'relative',
                    height: '44px',
                    [theme.breakpoints.down('sm')]: {
                        marginTop: '20px',
                    },
                    '& > button': {
                        marginLeft: '5px',
                        height: '37px',
                        marginTop: '6px',
                    },
                    '& > div.MuiFormControl-root': {
                        paddingTop: '8px',
                    },
                    '& > div.MuiFormControl-root > label': {
                        fontWeight: 'bold',
                        zIndex: 100,
                        top: '2px',
                        fontSize: '12px',
                    },
                    '& > div.MuiFormControl-root > label.dateLabel': {
                        top: '-24px',
                        left: '-12px',
                    },
                    '& > div.MuiFormControl-root > label.numericLabel': {
                        top: '-14px',
                    },
                    '& > div.MuiFormControl-root > div.numericWrapper': {
                        display: 'flex',
                        alignItems: 'center',
                        '& > div': {
                            padding: '10px 2px 10px 10px',
                            fontWeight: 'initial',
                            width: '175px',
                            paddingLeft: 0,
                            [theme.breakpoints.down('sm')]: {
                                width: '124px',
                            },
                            '& > div': {
                                height: '36px',
                            },
                        },
                        '& > input': {
                            width: '70px',
                            height: '36px',
                        },
                    },
                    '& > div.MuiFormControl-root > div:nth-child(2) > div': {
                        minHeight: 'initial',
                        padding: '10px 32px 10px 10px',
                        minWidth: 'initial',
                        width: '150px',
                        fontSize: '13px',
                    },
                    '& > div.MuiFormControl-root > div.react-datepicker-wrapper': {
                        '& > div': {
                            width: 'initial',
                            padding: 0,
                            '& > input': {
                                height: '36px',
                            },
                        },
                    },
                },
            },
            remover: {
                position: 'absolute',
                top: '-4px',
                right: '-8px',
                cursor: 'pointer',
                zIndex: 100,
                '& > svg': {
                    borderRadius: '50%',
                    fill: theme.palette.background.white,
                    backgroundColor: theme.palette.background.red,
                    width: '20px',
                    height: '20px',
                },
            },
            tableContainer: {
                marginTop: '15px',
                overflowY: 'hidden',
                overflowX: 'hidden',
                flexGrow: 1,
                marginBottom: '25px',
                '& > div': {
                    overflow: 'hidden',
                },
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    height: '95%',
                    '& > div': {
                        width: '100%',
                        paddingLeft: '7px',
                        lineHeight: 2.5,
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            extraStyle: {
                overflowX: 'auto',
                '& > div': {
                    overflow: 'initial',
                },
            },
            preview: {
                height: '600px',
            },
            tableLabel: {
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 500,
                fontSize: '14px',
                marginTop: '10px !important',
                marginBottom: '10px !important',
            },
            dateParent: {
                '& > div:nth-child(1)': {
                    marginRight: '5px',
                },
                [theme.breakpoints.down('sm')]: {
                    height: 'auto !important',
                    flexDirection: 'column',
                    '& > div': {
                        marginBottom: '10px',
                    },
                    '& > button': {
                        width: '100%',
                    },
                },
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            actionsWrapper: {
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '10px',
                '& > button': {
                    padding: 0,
                    minWidth: 'initial',
                    border: 0,
                    marginRight: '10px',
                    '& > span > span': {
                        margin: 0,
                        '& > svg': {
                            fontSize: '20px',
                        },
                    },
                },
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            totalTitle: {
                fontWeight: 'bold',
            },
            numericColumn: {
                fontWeight: 'bold',
            },
            filterOption: {
                textWrap: 'wrap',
                alignItems: 'flex-start',
                '& > div': {
                    marginTop: '8px',
                },
            },
            printing: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0.9,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                backgroundColor: theme.palette.background.white,
                fontWeight: 'bold',
                fontSize: '14px',
                '& > span': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.text.white,
                    padding: '5px',
                },
            },
            paginationMessage: {
                color: theme.palette.text.red,
                maxWidth: '200px',
            },
            pivotLayout: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                height: '85%',
                minHeight: '600px',
                paddingBottom: '20px',
                '& > div:nth-child(1)': {
                    flexDirection: 'column',
                    display: 'flex',
                    flexShrink: 0,
                    width: '250px',
                    border: `1px solid ${theme.palette.border.ghost}`,
                    '& > div:nth-child(1)': {
                        flexGrow: 1,
                        padding: '5px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    },
                    '& > div:nth-child(2)': {
                        flexDirection: 'column',
                        height: 'auto',
                        flexShrink: 0,
                        '& > div': {
                            width: '100% !important',
                            '& > div:nth-child(2)': {
                                height: '130px !important',
                            },
                        },
                    },
                },
                '& > div:nth-child(2)': {
                    flexGrow: 1,
                    overflow: 'auto',
                    marginLeft: '10px',
                },
            },
            formatting: {
                position: 'absolute',
                left: 0,
                '& > button': {
                    padding: 0,
                    minWidth: '32px',
                    '& > span > span': {
                        margin: 0,
                        '& > svg': {
                            fill: theme.palette.background.red,
                            fontSize: '24px !important',
                        },
                    },
                },
            },
            refreshButton: {
                position: 'absolute',
                left: '30px',
                '& > button': {
                    padding: 0,
                    minWidth: '32px',
                    '& > span > span': {
                        margin: 0,
                        '& > svg': {
                            fill: theme.palette.success.main,
                            fontSize: '24px !important',
                        },
                    },
                },
            },
        });
    }
}

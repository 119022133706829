import React, { Component } from 'react';

import clsx from 'clsx';
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import { withRouter } from 'react-router-dom';
import Permission from 'utils/enum/Permissions';
import { withStyles } from '@material-ui/core/styles';
import InputSearch from 'components/widgets/InputSearch';
import GraphQLClient from 'services/apollo/GraphQLClient';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import UnderwritingQuery from 'services/graphQL/query/setting/UnderwritingQuery';
import ProductSettingsStyles from 'styles/modules/settings/ProductSettingsStyles';
import VendorsSelectionForm from 'components/modules/settings/underwriting/VendorsSelectionForm';
import IntegrationDialogFactory from 'components/modules/settings/underwriting/IntegrationDialogFactory';
import {
    Button, Checkbox,
} from '@material-ui/core';

const styles = (theme) => ProductSettingsStyles.listStyles(theme);

class Underwriting extends Component {
    constructor(props) {
        super(props);

        // Instance GraphQL Client
        this.graphQLClient = new GraphQLClient();
        const keyStore = new KeyStore();
        this.SETTINGS_GENERAL_WRITE = keyStore.hasPermission(Permission.SETTINGS_GENERAL_WRITE);

        this.state = {
            opened: false,
            isOpenedVendorSelectionForm: false,
            search: '',
            selectedRecord: null,
            record: {},
            tableContent: {
                records: [],
            },
            listAvailableIntegrations: [],
            vendorStatus: true,
            loading: false,
        };

        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
    }

    onReload() {
        this.getServicesData(true);
    }

    onSearch(value) {
        this.setState({ search: value }, () => {
            this.getServicesData(true);
        });
    }

    onNewIntegration() {
        this.setState({ isOpenedVendorSelectionForm: true });
    }

    onNext(nextRecordIntegration = {}) {
        this.setState({
            isOpenedVendorSelectionForm: false,
            opened: true,
            isEditing: false,
            record: nextRecordIntegration,
        });
    }

    onCloseNewIntegration() {
        this.setState({ isOpenedVendorSelectionForm: false });
    }

    onCreate() {
        this.setState({ opened: true, isEditing: false });
    }

    onEdit(selectedRecord) {
        const { state } = this;
        const record = selectedRecord || state.selectedRecord;

        if (record) {
            this.setState({
                opened: true,
                isEditing: true,
                record: clone(record),
            });
        }
    }

    onClose() {
        this.setState({
            opened: false,
            record: null,
        });
    }

    onRowDoubleClick(record) {
        this.setState({ opened: true, record, isEditing: true });
    }

    onRowClick(record) {
        this.setState({
            selectedRecord: record,
        });
    }

    onChangeRadios(value) {
        this.setState({ vendorStatus: value }, () => {
            this.getServicesData(true);
        });
    }

    /**
     * Define the list of columns to render in the table.
     * @returns {*[]}
     */
    getColumns() {
        const columnStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        const columns = [
            {
                Header: 'Name',
                id: 'Name',
                accessor: 'name',
                style: columnStyle,
            },
            {
                Header: 'Type',
                id: 'Type',
                accessor: 'type',
                style: columnStyle,
            },
            {
                Header: 'Lot',
                id: 'Lot',
                accessor: 'lotName',
                style: columnStyle,
                Cell: (record) => {
                    const name = record.value;
                    return <span>{name}</span>;
                },
            },
            {
                Header: 'Active',
                id: 'Active',
                accessor: 'active',
                style: columnStyle,
                maxWidth: 140,
                minWidth: 140,
                Cell: (record) => <Checkbox checked={record ? record.value : false} />,
            },
        ];

        return columns;
    }

    /**
     * Fetch a list of third party vendors from GraphQL API
     */
    getServicesData(isSearching = false) {
        const { state } = this;
        const { vendorStatus, search } = state;
        const input = {
            searchText: search,
            active: vendorStatus,
        };

        if (isSearching) {
            this.setState({
                loading: true,
                selectedRecord: null,
                tableContent: {
                    records: [],
                },
            });
        } else {
            this.setState({
                loading: true,
            });
        }

        this.graphQLClient
            .query(UnderwritingQuery.THIRD_API_INTEGRATION_LIST, { ...input })
            .then((response) => {
                const { graphQLErrors, data } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                } else {
                    this.setState({
                        tableContent: {
                            records: data.listDealerIntegrations,
                        },
                        listAvailableIntegrations:
                            data.listAvailableIntegrations,
                    });
                }
            })
            .catch(() => {
                ModalUtils.errorMessage(null, 'There was an error to call the service');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getRecords() {
        const {
            state: {
                tableContent: { records },
            },
        } = this;
        return records;
    }

    initBind() {
        this.onEdit = this.onEdit.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onReload = this.onReload.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getRecords = this.getRecords.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onChangeRadios = this.onChangeRadios.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onNewIntegration = this.onNewIntegration.bind(this);
        this.onCloseNewIntegration = this.onCloseNewIntegration.bind(this);
    }

    render() {
        const { state, props: { classes } } = this;
        const { isEditing, loading } = state;
        const listAvailableIntegrations = state.listAvailableIntegrations || [];

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.newButton)}
                            startIcon={<AddIcon />}
                            onClick={this.onNewIntegration}
                        >
                            New
                        </Button>
                    )}
                    {this.SETTINGS_GENERAL_WRITE && (
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.editButton)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={!state.selectedRecord}
                            onClick={() => this.onEdit(state.selectedRecord)}
                        >
                            Edit
                        </Button>
                    )}
                    <InputSearch
                        size="sm"
                        forceSearch
                        onSearch={this.onSearch}
                        customClasses={classes.search}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                    />
                    <div className="d-flex-center">
                        <Form.Group
                            className="form-check form-check-inline mb-0"
                        >
                            <Form.Check
                                key="radioActive"
                                type="radio"
                                name="radio"
                                id="radioActive"
                                label="Active"
                                value={state.vendorStatus}
                                checked={state.vendorStatus}
                                onChange={() => this.onChangeRadios(true)}
                            />
                            <Form.Check
                                key="radioInactive"
                                type="radio"
                                name="radio"
                                id="radioInactive"
                                label="In-Active"
                                value={!state.vendorStatus}
                                checked={!state.vendorStatus}
                                onChange={() => this.onChangeRadios(false)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={classes.tableHeight}>
                    <Table
                        columns={this.getColumns()}
                        data={this.getRecords()}
                        loading={loading}
                        rowSelected
                        getTrProps={(_, rowInfo) => {
                            const item = rowInfo.original;

                            return {
                                onClick: () => this.onRowClick(item),
                                onDoubleClick: () => this.onRowDoubleClick(item),
                            };
                        }}
                    />
                </div>
                {state.isOpenedVendorSelectionForm && (
                    <VendorsSelectionForm
                        onNext={this.onNext}
                        onClose={this.onCloseNewIntegration}
                        listAvailableIntegrations={listAvailableIntegrations}
                    />
                )}
                {state.opened && (
                    <IntegrationDialogFactory
                        onClose={this.onClose}
                        isEditing={isEditing}
                        record={state.record}
                        onReload={this.onReload}
                    />
                )}
            </div>
        );
    }
}

Underwriting.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Underwriting));

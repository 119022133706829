import * as yup from 'yup';

const PartsTicketSchema = yup.object().shape({
    lotId: yup.number().required().moreThan(0, 'Lot is required'),
    salesType: yup.string().required('Sales type is required'),
    customerCode: yup.number().required().moreThan(0, 'Customer is required'),
    discountPercent: yup.number(),
    quickNote: yup.string().max(
        200,
        'Quick note has a maximum limit of 200 characters',
    ),
});

export default PartsTicketSchema;

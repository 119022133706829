import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import TabStyles from 'styles/widgets/tabs/TabsStyles';

const styles = (theme) => TabStyles.tabPanel(theme);

class TabPanel extends Component {
    render() {
        const {
            props: {
                children, value, index, classes, className,
            },
        } = this;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                className={classes.tabPanel}
            >
                {value === index && (
                    <div className={clsx(classes.box, className)}>
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    className: PropTypes.string,
};

TabPanel.defaultProps = {
    children: null,
    className: '',
};

export default withStyles(styles)(TabPanel);

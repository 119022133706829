import { gql } from '@apollo/client';

export default class UserSettingQuery {
    static GET_EMAIL_SIGNATURE = gql`
        query getEmailSignature {
            getEmailSignature {
                userSettingId
                value
            }
        }
    `;

    static GET_EMPLOYEE_TYPES = gql`
    query getEmployeeTypes {
        getEmployeeTypes {
            employeeType
        }
    }
    `;
}

export default class SearchStyles {
    static search(theme = {}, fade) {
        return ({
            search: {
                position: 'relative',
                borderRadius: theme.spacing(2),
                backgroundColor: fade('#F3F4FD', 0.5),
                '&:hover': {
                    backgroundColor: fade('#F3F4FD', 1),
                },
                marginRight: theme.spacing(1),
                width: 'auto',
                border: '1px solid',
                borderColor: '#cecece',
            },
            searchIcon: {
                padding: theme.spacing(0, 2),
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#4751B8',
            },
            inputInput: {
                color: '#4751B8',
                padding: 0,
                height: 32,
                paddingRight: theme.spacing(1),
                paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                transition: theme.transitions.create('width'),
                width: '28ch',
                '&:focus': {
                    width: '36ch',
                },
            },
            searchFullWidth: {
                marginRight: 0,
                borderRadius: 0,
                '& .input-root-mui': {
                    width: '100%',
                },
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
            },
            inputFullWidth: {
                width: '100%',
                '&:focus': {
                    width: '100%',
                },
            },
        });
    }
}

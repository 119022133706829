// eslint-disable-next-line import/no-extraneous-dependencies
import XLSX from 'xlsx';
import ArrayUtils from 'lib/ArrayUtils';
import NumberUtils from 'lib/NumberUtils';

export default class SpreadsheetUtils {
    constructor(name) {
        this.name = name;
    }

    async createWorbook() {
        this.workbook = XLSX.utils.book_new();

        if (!this.workbook.Props) this.workbook.Props = {};
        this.workbook.Props.CreatedDate = new Date();
        this.workbook.Props.Title = this.name;
    }

    setColumnNumberFormat(sheet, totalColumns) {
        if (totalColumns <= 0) return;

        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (let row = range.s.r + 1; row <= range.e.r; row += 1) {
            for (let col = 0; col < totalColumns; col += 1) {
                const ref = XLSX.utils.encode_cell({ r: row, c: col });
                // eslint-disable-next-line no-param-reassign, no-restricted-globals
                if (sheet[ref] && !isNaN(sheet[ref].v)) sheet[ref].t = 'n';
            }
        }
    }

    // rows: [{ col: value, ... }]
    async addWorkSheet(rows, columnsProperties, name = this.name) {
        if (!this.workbook) {
            await this.createWorbook();
        }

        const { workbook } = this;
        const sheet = XLSX.utils.json_to_sheet(rows);
        this.setColumnNumberFormat(
            sheet,
            ArrayUtils.isNotEmpty(rows) ? Object.keys(rows[0]).length : 0,
        );

        const maxCharactersAllowed = 31;
        XLSX.utils.book_append_sheet(workbook, sheet, name.length > maxCharactersAllowed ? name.substring(0, maxCharactersAllowed) : name);

        const { sizes } = columnsProperties;
        if (sizes) {
            sheet['!cols'] = sizes.map((size) => ({ wpx: size }));
        }
    }

    async download() {
        const { workbook, name } = this;

        if (!workbook) {
            return;
        }

        XLSX.writeFile(workbook, `${name}.xlsx`);
    }

    static formatRowsToExport(records, columns, columnsWithTotal = []) {
        const rows = records.map((record) => {
            const rowData = {};
            record.forEach((column) => {
                if (!columns || columns.some((col) => col.toLowerCase() === column.name.toLowerCase())) {
                    let value = (column.value === 'null' || column.value === null) ? '' : column.value;

                    // eslint-disable-next-line no-restricted-globals
                    if (columnsWithTotal.includes(column.name) && !isNaN(value)) value = NumberUtils.applyThousandsFormat(value ?? 0, '0,0.[00]');
                    rowData[column.name] = value;
                }
            });

            return rowData;
        });

        return rows;
    }
}

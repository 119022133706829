/* eslint-disable import/prefer-default-export */
export const configuration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 400,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    placeholder: 'Type a message',
    buttons: 'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush,image,link,source',
};

// TODO: look for a way to pass the height without rendering the component
export const configurationReply = {
    readonly: false,
    disablePlugins: 'paste',
    minHeight: 200,
    maxHeight: 400,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    placeholder: 'Type a message',
    buttons: 'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush,image,link,source',
};

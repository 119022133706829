import React, { Component } from 'react';

const AddressSectionContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openDialogManage: false,
            currentAddress: {},
            isEditing: false,
            reloadManagerAddress: false,
        };

        this.initBind();
    }

    onEdit(record) {
        this.setState({
            open: true,
            isEditing: true,
            currentAddress: record,
            reloadManagerAddress: false,
        });
    }

    makeReloadAddress(isSave = false) {
        this.setState({
            reloadManagerAddress: isSave,
        });
    }

    toggleModal() {
        this.setState((prev) => ({
            open: !prev.open,
            isEditing: false,
        }));
    }

    toggleManageAddress() {
        this.setState((prev) => ({
            openDialogManage: !prev.openDialogManage,
        }));
    }

    openManage() {
        this.setState({
            openDialogManage: true,
        });
    }

    initBind() {
        this.onEdit = this.onEdit.bind(this);
        this.openManage = this.openManage.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleManageAddress = this.toggleManageAddress.bind(this);
        this.makeReloadAddress = this.makeReloadAddress.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onEdit={this.onEdit}
                openManage={this.openManage}
                toggleModal={this.toggleModal}
                toggleManageAddress={this.toggleManageAddress}
                makeReloadAddress={this.makeReloadAddress}
            />
        );
    }
};

export default AddressSectionContainer;

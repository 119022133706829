import { ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';

const useServiceInvoiceValidateActions = (status) => {
    let edits = {
        editCustomer: true,
        editVehicle: true,
        editServiceAdvisor: true,
        editEstimateDelivery: true,
        editRef: true,
        editRentalCar: true,
        editSaveOldParts: true,
        editNotes: true,
        editWarrantyDeductable: true,
        editLaborDiscountOption: true,
        editLaborDiscount: true,
        editRoDiscount: true,
        editFee: true,
        editCancellationFee: true,
        editStorageFee: true,
        editCustomerWaiting: true,
        editJobLines: {
            editJobStatus: true,
            editApproved: true,
            editIsSublet: true,
            editJobDiscount: true,
            editTechniccian: true,
            editPayType: true,
            editCostByHours: true,
            editSoldHours: true,
            editFlaggedHours: true,
            editLaborAmount: true,
            editSubletVendor: true,
            editSubletCost: true,
            editSubletPrice: true,
            editOperationCode: true,
            editOverrideSubletPrice: true,
            editCustomerState: true,
            editCause: true,
            editCorrection: true,
            editNotes: true,
            editRecommends: true,
            editAddParts: true,
            editDeleteParts: true,
            editParts: true,
        },
        allowSaveAction: true,
    };

    if (status === ServiceInvoiceStatus.CLOSED || status === ServiceInvoiceStatus.COMPLETED || status === ServiceInvoiceStatus.VOID) {
        edits = {
            editCustomer: false,
            editVehicle: false,
            editServiceAdvisor: false,
            editEstimateDelivery: false,
            editRef: false,
            editRentalCar: false,
            editSaveOldParts: false,
            editNotes: false,
            editWarrantyDeductable: false,
            editLaborDiscountOption: false,
            editLaborDiscount: false,
            editRoDiscount: false,
            editFee: false,
            editCancellationFee: false,
            editStorageFee: false,
            editCustomerWaiting: false,
            editJobLines: {
                editJobStatus: false,
                editApproved: false,
                editIsSublet: false,
                editJobDiscount: false,
                editTechniccian: false,
                editPayType: false,
                editCostByHours: false,
                editSoldHours: false,
                editFlaggedHours: false,
                editLaborAmount: false,
                editSubletVendor: false,
                editSubletCost: false,
                editSubletPrice: false,
                editOverrideSubletPrice: false,
                editOperationCode: false,
                editCustomerState: false,
                editCause: false,
                editCorrection: false,
                editNotes: false,
                editRecommends: false,
                editAddParts: false,
                editDeleteParts: false,
                editParts: false,
            },
            allowSaveAction: false,
        };
    }

    return edits;
};

export default useServiceInvoiceValidateActions;

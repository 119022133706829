/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

const TradeInSchema = yup.object().shape({
    vehicle: yup.object().shape({
        vin: yup.string().length(17).required(),
        year: yup.number().min(1).required(),
        make: yup.string().required(),
        model: yup.string().required(),
        miles: yup.number().min(1).required(),
        transmission: yup.string().required(),
    }),
    allowance: yup.number().min(1).required(),
    acv: yup.number().min(1).required(),
});

export {
    TradeInSchema,
};

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import FormTab from 'styles/modules/deals/FormsTab';
import InputSearch from 'components/widgets/InputSearch';
import TabContainer from 'components/widgets/tabs/TabContainer';
import FormLibraryTab from 'components/modules/deals/read/form/FormLibraryTab';
import FormDetailContainer from 'components/containers/deals/read/forms/FormDetailContainer';

// Bootstrap
import { Tabs, Tab } from 'react-bootstrap';

// Material UI
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormPacksTab from 'components/modules/deals/read/form/FormPacksTab';
import CustomFieldDialog from 'components/modules/deals/create/forms/CustomFieldDialog';

const styles = (theme) => FormTab.formDetail(theme);

class FormDetail extends Component {
    render() {
        const {
            classes, formPackList, formLibrary, packSelected,
            defaultActiveKey, onChangeValueFormPack, accountNumber,
            onPrintList, loadingPrint, onPreview, formPackSelected,
            openDialogCustomField, customField, getCustomFieldInformation,
            printingFormWithCustomField, onRemoveFormOfFormPack, formPackListBackUp,
            onSavePrintListCheckedDefault, onSearchForm, search, onCloseCustomField,
        } = this.props;
        const listFormSelected = formPackSelected();
        const disabledFormPack = !listFormSelected?.length || loadingPrint;

        return (
            <div className={classes.containerDetail}>
                <TabContainer className={classes.tabContainer}>
                    <Tabs
                        id="controlled-tab-example"
                        mountOnEnter
                        defaultActiveKey={defaultActiveKey}
                        className={`${classes.borderContainer} ${classes.navbar}`}
                    >
                        <Tab className={classes.tab} eventKey={0} mountOnEnter title="Form Packs">
                            <FormPacksTab
                                onPreview={onPreview}
                                records={formPackList}
                                packSelected={packSelected}
                                onChangeValueFormPack={onChangeValueFormPack}
                                onRemoveFormOfFormPack={onRemoveFormOfFormPack}
                            />
                        </Tab>
                        <Tab className={classes.tab} eventKey={1} mountOnEnter title="Form Library">
                            <div className="d-flex-column">
                                <InputSearch
                                    size="sm"
                                    forceSearch
                                    onSearch={onSearchForm}
                                    customClasses={classes.search}
                                    initialSearch={search}
                                    executeWhenClearButton={() => onSearchForm('')}
                                />
                                <FormLibraryTab
                                    records={formLibrary}
                                    packSelected={packSelected}
                                    accountNumber={accountNumber}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </TabContainer>
                <div className={classes.footer}>
                    <Button
                        variant="contained"
                        className={classes.save}
                        onClick={onSavePrintListCheckedDefault}
                        disabled={isEqual(formPackList, formPackListBackUp)}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.printButton}
                        onClick={onPrintList}
                        disabled={disabledFormPack}
                    >
                        Print List
                    </Button>
                </div>
                {openDialogCustomField && (
                    <CustomFieldDialog
                        records={customField}
                        open={openDialogCustomField}
                        loading={printingFormWithCustomField}
                        onClose={onCloseCustomField}
                        onClickPrimaryButton={getCustomFieldInformation}
                    />
                )}
            </div>
        );
    }
}

FormDetail.propTypes = {
    defaultActiveKey: PropTypes.number,
    search: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onPreview: PropTypes.func.isRequired,
    onPrintList: PropTypes.func.isRequired,
    formLibrary: PropTypes.array.isRequired,
    onSearchForm: PropTypes.func.isRequired,
    loadingPrint: PropTypes.bool.isRequired,
    customField: PropTypes.array.isRequired,
    formPackList: PropTypes.array.isRequired,
    packSelected: PropTypes.object.isRequired,
    accountNumber: PropTypes.number.isRequired,
    formPackSelected: PropTypes.func.isRequired,
    onCloseCustomField: PropTypes.func.isRequired,
    formPackListBackUp: PropTypes.array.isRequired,
    onChangeValueFormPack: PropTypes.func.isRequired,
    openDialogCustomField: PropTypes.bool.isRequired,
    onRemoveFormOfFormPack: PropTypes.func.isRequired,
    getCustomFieldInformation: PropTypes.func.isRequired,
    printingFormWithCustomField: PropTypes.bool.isRequired,
    onSavePrintListCheckedDefault: PropTypes.func.isRequired,
};

FormDetail.defaultProps = {
    defaultActiveKey: 0,
};

export default withStyles(styles)(FormDetailContainer(FormDetail));

import React from 'react';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import TabSetting from 'components/modules/settings/TabSetting';
import GeneralSetting from 'components/modules/settings/user/GeneralSetting';

const keyStore = new KeyStore();
const getSettings = () => {
    const settings = [];

    if (keyStore.hasPermission(Permission.USER_EMAIL_SIGNATURE_READ)) {
        settings.push(
            {
                text: 'Email signature',
                icon: <TuneOutlinedIcon />,
                url: 'email-signature',
                component: <GeneralSetting />,
            },
        );
    }

    return settings;
};

const UserTabSetting = () => {
    const settings = getSettings();

    return <TabSetting records={settings} />;
};

export default UserTabSetting;

/* eslint no-param-reassign: ["error", { "props": false }] */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
    Tooltip,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import IconButton from '@material-ui/core/IconButton';
import { FilterRemovalIcon } from 'components/icons/index';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';
import TableSettingsReducer, { ACTION_TYPES, INITIAL_STATE } from 'components/modules/inventory/reducer/TableSettingsReducer';

const useStyles = makeStyles((theme) => InventoryListStyle.tableSettingsDialog(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const TableSettingsDialog = ({
    columns,
    columnsSet,
    saveColumnSettings,
    resetFilters,
    filters,
    onClose,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(TableSettingsReducer, INITIAL_STATE);
    const {
        currentColumns,
        availableColumns,
        selectedCurrentColumn,
        selectedAvailableColumn,
        isSaving,
    } = state;

    const isColumnAvailable = (column) => {
        const { label } = column;
        return !StringUtils.isEmpty(label);
    };

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_COLUMNS_DATA,
            value: {
                ...state,
                currentColumns: columns.filter((column) => column.show && isColumnAvailable(column)),
                availableColumns: columns.filter((column) => !column.show && isColumnAvailable(column)),
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectCurrentColumn = (dataKey) => {
        dispatch({
            type: ACTION_TYPES.SET_COLUMNS_DATA,
            value: {
                ...state,
                selectedCurrentColumn: dataKey,
                selectedAvailableColumn: '',
            },
        });
    };

    const onSelectAvailableColumn = (dataKey) => {
        dispatch({
            type: ACTION_TYPES.SET_COLUMNS_DATA,
            value: {
                ...state,
                selectedCurrentColumn: '',
                selectedAvailableColumn: dataKey,
            },
        });
    };

    const onAddColumn = () => {
        if (StringUtils.isEmpty(selectedAvailableColumn)) return;

        const selected = availableColumns
            .find((column) => column.dataKey === selectedAvailableColumn);
        if (selected) {
            const original = columnsSet.find((cs) => cs.dataKey === selectedAvailableColumn);

            selected.show = true;
            selected.order = original?.order || selected.order;
            const cols = [...currentColumns, selected];
            cols.sort((a, b) => a.order - b.order);

            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: {
                    ...state,
                    currentColumns: cols,
                    availableColumns: availableColumns.filter((column) => column.dataKey !== selectedAvailableColumn),
                    selectedAvailableColumn: '',
                },
            });
        }
    };

    const onRemoveColumn = () => {
        if (StringUtils.isEmpty(selectedCurrentColumn)) return;

        const selected = currentColumns
            .find((column) => column.dataKey === selectedCurrentColumn);
        if (selected) {
            const displayedColumns = currentColumns.filter((column) => column.dataKey !== selectedCurrentColumn);
            if (displayedColumns <= 1) {
                ModalUtils.errorMessage(null, 'At least one column is required.');
                return;
            }

            const original = columnsSet.find((cs) => cs.dataKey === selectedCurrentColumn);
            selected.show = false;
            selected.order = original?.order || selected.order;
            const cols = [...availableColumns, selected];
            cols.sort((a, b) => a.order - b.order);

            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: {
                    ...state,
                    currentColumns: displayedColumns,
                    availableColumns: cols,
                    selectedCurrentColumn: '',
                },
            });
        }
    };

    const onSave = async (skip) => {
        const clone = [...columnsSet];
        const all = [...currentColumns, ...availableColumns];

        if (!skip) {
            clone.forEach((column) => {
                const current = all.find((item) => item.dataKey === column.dataKey);
                if (current) {
                    column.show = current.show;
                    column.order = current.order;
                    if (current.width) column.width = current.width;
                }
            });
        }

        dispatch({ type: ACTION_TYPES.TOGGLE_IS_SAVING });
        await saveColumnSettings(clone);
        dispatch({ type: ACTION_TYPES.TOGGLE_IS_SAVING });
    };

    const resetColumns = () => {
        dispatch({
            type: ACTION_TYPES.SET_COLUMNS_DATA,
            value: {
                ...state,
                currentColumns: columnsSet.filter((column) => column.show && isColumnAvailable(column)),
                availableColumns: columnsSet.filter((column) => !column.show && isColumnAvailable(column)),
            },
        });

        onSave(true);
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Table Settings"
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent className={classes.main}>
                <div className={classes.tableSettingsDialogContent}>
                    <Grid container spacing={3}>
                        <Grid item sm={5} xs={12} className={classes.innerSections}>
                            <div className={classes.label}>
                                Current Columns
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <List>
                                    {currentColumns
                                        .map((column) => {
                                            const original = columnsSet.find((cs) => cs.dataKey === column.dataKey);

                                            return (
                                                <ListItem
                                                    key={column.dataKey}
                                                    button
                                                    selected={column.dataKey === selectedCurrentColumn}
                                                    onClick={() => onSelectCurrentColumn(column.dataKey)}
                                                >
                                                    <ListItemText
                                                        primary={original.label}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item sm={2} xs={12}>
                            <Grid
                                className={clsx(classes.innerSections, classes.middleSection)}
                                container
                            >
                                <IconButton
                                    aria-label="add-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(selectedCurrentColumn)}
                                    onClick={onAddColumn}
                                >
                                    <ArrowBackIosIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    aria-label="remove-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(selectedAvailableColumn)}
                                    onClick={onRemoveColumn}
                                >
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item sm={5} xs={12} className={classes.innerSections}>
                            <div className={classes.label}>
                                Available Columns
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <List>
                                    {availableColumns
                                        .map((column) => {
                                            const original = columnsSet.find((cs) => cs.dataKey === column.dataKey);

                                            return (
                                                <ListItem
                                                    key={column.dataKey}
                                                    button
                                                    selected={column.dataKey === selectedAvailableColumn}
                                                    onClick={() => onSelectAvailableColumn(column.dataKey)}
                                                >
                                                    <ListItemText
                                                        primary={original.label}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className={classes.actionsContainer}>
                <div>
                    <Tooltip
                        title="Clear all the filters applied in each column."
                        placement="top"
                    >
                        <span>
                            <Button
                                className={classes.containedSecondaryInfo}
                                startIcon={<FilterRemovalIcon />}
                                disabled={isSaving || filters.length === 0}
                                onClick={() => resetFilters()}
                            >
                                Clear all filters
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title="Put back into place the default (basic) columns. All columns and current positions of those will be removed."
                        placement="top"
                    >
                        <Button
                            className={classes.containedSecondaryInfo}
                            disabled={isSaving}
                            onClick={resetColumns}
                        >
                            Reset all columns
                        </Button>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip
                        title="Save the current selection of columns on the left panel. Brand new added columns will be placed in their default positions."
                        placement="top"
                    >
                        <Button
                            className={classes.containedSecondaryInfo}
                            disabled={isSaving}
                            onClick={() => onSave(false)}
                        >
                            Save
                        </Button>
                    </Tooltip>
                </div>
            </DialogActions>
        </Dialog>
    );
};

TableSettingsDialog.propTypes = {
    columns: PropTypes.array.isRequired,
    columnsSet: PropTypes.array.isRequired,
    saveColumnSettings: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TableSettingsDialog;

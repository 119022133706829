const ActivityType = {
    TASK: 'TASK',
    EVENT: 'EVENT',
    CALL: 'CALL',
    APPOINTMENT: 'APPOINTMENT',
    NOTE: 'NOTE',
    ACTION: 'ACTION',
};

const ActivityStatuses = {
    SCHEDULED: 'SCHEDULED',
    MISSED: 'MISSED',
    OUTGOING: 'OUTGOING',
    INCOMING: 'INCOMING',
    COMPLETED: 'COMPLETED',
};

// TODO: Change this value when it is updated in the database
const ActivityResult = {
    LEFT_VOICEMAIL: 'LEFT VOICEMAIL',
    ANSWERED: 'ANSWERED',
    NOANSWER: 'NOANSWER',
};

const InputResult = {
    LEFT_VOICEMAIL: 'Left Voicemail',
    NO_ANSWER: 'No Answer',
    CANCELLED: 'Cancelled',
    RESCHEDULED: 'Rescheduled',
    ANSWERED: 'Answered',
};

const TaskStatus = {
    COMPLETED: 'COMPLETED',
    TO_DO: 'TO DO',
};

const TaskStatusInput = {
    COMPLETED: 'COMPLETED',
    TODO: 'TODO',
};

const TaskPrioritiesInput = {
    LOW: 'LOW',
    NORMAL: 'NORMAL',
    HIGH: 'HIGH',
};

export const SMSStatus = {
    OUTGOING: 'Outgoing',
    INCOMING: 'Incoming',
};

export const EventStatus = {
    SCHEDULED: 'Scheduled',
    CONFIRMED: 'Confirmed',
    SHOW: 'Show',
    NOSHOW: 'No Show',
    CANCELLED: 'Cancelled',
};

export const EventColors = {
    SCHEDULED: {
        backgroundColor: '#5C6BC0',
        color: '#263238',
        textColor: '#FFF',
    },
    CONFIRMED: {
        backgroundColor: 'rgb(0, 128, 255)',
        color: 'rgb(0, 128, 255)',
        textColor: '#fff',
    },
    SHOW: {
        backgroundColor: 'rgb(43, 175, 70)',
        color: 'rgb(43, 175, 70)',
        textColor: '#fff',
    },
    NOSHOW: {
        backgroundColor: 'rgb(241, 51, 59)',
        color: 'rgb(241, 51, 59)',
        textColor: '#fff',
    },
    CANCELLED: {
        backgroundColor: 'rgb(243, 107, 34)',
        color: 'rgb(243, 107, 34)',
        textColor: '#fff',
    },
};

export {
    ActivityType, ActivityStatuses, ActivityResult, TaskStatus, InputResult, TaskStatusInput, TaskPrioritiesInput,
};

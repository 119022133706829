import React, { useEffect, useReducer } from 'react';

import update from 'immutability-helper';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { CodeType } from 'utils/enum/NotesEnum';
import History from 'components/widgets/History';
import { useQuery, useSubscription } from '@apollo/client';
import NotesQuery from 'services/graphQL/query/NotesQuery';
import NotesSubscription from 'services/graphQL/subscription/NotesSubscription';

const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    ADD_RECORD: 'addRecord',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS:
        return action.payload;
    case ACTION_TYPES.ADD_RECORD:
        return update(state, { $unshift: [action.payload] });
    default:
        return [];
    }
};

const HistoryTab = ({ customerCode, className }) => {
    const [records, dispatch] = useReducer(reducer, []);
    const { data, error, loading } = useQuery(NotesQuery.GET_CUSTOMER_NOTES, {
        variables: { customerCode },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const { data: subscriptionData } = useSubscription(NotesSubscription.NOTE_ADDED, {
        variables: {
            codeType: CodeType.CUSTOMER,
            code: customerCode,
        },
        shouldResubscribe: true,
    });

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                payload: data?.getCustomerNotes,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (subscriptionData) {
            dispatch({
                type: ACTION_TYPES.ADD_RECORD,
                payload: subscriptionData.noteAdded,
            });
        }
    }, [subscriptionData]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    return (
        <History
            loading={loading}
            records={records}
            className={className}
        />
    );
};

HistoryTab.propTypes = {
    className: PropTypes.string,
    customerCode: PropTypes.number.isRequired,
};

HistoryTab.defaultProps = {
    className: null,
};

export default HistoryTab;

import StringUtils from 'lib/StringUtils';

const useVinValidation = (year, vin, enterManually = false) => {
    let providedYear = year;
    const notAllowedCharsInVIN = ['i', 'o', 'q'];
    const vinNumberLength = 17;
    const vinYearStandardization = 1981;
    const response = {
        isInvalidVINLength: true,
        isInvalidVINChars: true,
        vinNumberLength,
        notAllowedCharsInVIN,
        vinYearStandardization,
    };

    if (!enterManually && (StringUtils.isEmpty(providedYear) || StringUtils.isEmpty(vin))) return response;
    if (providedYear === 'none') providedYear = vinYearStandardization;
    const isInvalidVINLength = Number(providedYear) >= vinYearStandardization && vin.trim().length < vinNumberLength;
    const isInvalidVINChars = Number(providedYear) >= vinYearStandardization && StringUtils.checkIfCharsIncludedInString(notAllowedCharsInVIN, vin.trim());

    return {
        ...response,
        isInvalidVINLength: enterManually ? false : isInvalidVINLength,
        isInvalidVINChars,
    };
};

export default useVinValidation;

import { gql } from '@apollo/client';

export default class LeadDistributionUserExceptionQuery {
    static GET_LEAD_DISTRIBUTION_USER = gql`
        query getLeadDistributionUser {
            getLeadDistributionUser {
                leadDistributionUserExceptionId
                userId
                createdBy
            }
        }
    `;
}

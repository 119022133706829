import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import {
    Button, makeStyles,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Form, Col } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import VirtualTable from 'components/widgets/VirtualTable';

// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        overflow: 'hidden',
        minWidth: '800px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(2), & > button:nth-child(3)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
}));

const AssistantFunctions = forwardRef((props, ref) => {
    const { existingFunctions, parentActionInProgress, tableHight } = props;
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        functions: [],
        selectedFunction: null,
        selectedFunctionDescription: null,
        selectedFunctions: existingFunctions?.length > 0 ? existingFunctions.map((f) => ({
            description: f.description,
            functionName: f.function,
        })) : [],
    });

    const {
        functions,
        selectedFunction,
        selectedFunctionDescription,
        selectedFunctions,
    } = state;

    const {
        data: functionsData,
        loading: loadingFunctions,
        error: errorLoadingFunctions,
    } = useQuery(AIQuery.GET_CUSTOM_FUNCTIONS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorLoadingFunctions) {
            ModalUtils.errorMessage(errorLoadingFunctions?.graphQLErrors);
            return;
        }

        if (!loadingFunctions) {
            const data = functionsData?.getCustomFunctions;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    functions: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFunctions, errorLoadingFunctions]);

    useImperativeHandle(ref, () => ({
        getFunctionsSelected() {
            return selectedFunctions;
        },
    }));

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const addFunction = () => {
        const clone = cloneDeep(selectedFunctions);
        if (clone.some((f) => f.functionName === selectedFunction)) {
            ModalUtils.errorMessage(null, 'Function already added');
            return;
        }

        clone.push({
            description: selectedFunctionDescription,
            functionName: selectedFunction,
        });

        setState((prevState) => ({
            ...prevState,
            selectedFunction: null,
            selectedFunctionDescription: null,
            selectedFunctions: clone,
        }));
    };

    const deleteFunction = (record) => {
        const clone = cloneDeep(selectedFunctions);
        setState((prevState) => ({
            ...prevState,
            selectedFunctions: clone.filter((f) => f.functionName !== record.functionName),
        }));
    };

    const functionsColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Description',
            dataKey: 'description',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.description}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Function',
            dataKey: 'function',
            width: 300,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.functionName}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 130,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            disabled={parentActionInProgress}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => deleteFunction(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('main-container')?.clientWidth;
    let functionsTableWidth = functionsColumns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > functionsTableWidth) functionsTableWidth = parentWidth - 40;
    return (
        <div>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Functions</Form.Label>
                <div>
                    <Select
                        placeholder="Function"
                        size="sm"
                        loading={loadingFunctions}
                        className={classes.input}
                        name="selectedFunction"
                        onChange={(name, value) => onChange(name, value)}
                        value={selectedFunction ?? ''}
                        options={functions.map((f) => ({
                            value: f,
                            label: f,
                        }))}
                    />
                    <Form.Control
                        placeholder="Description"
                        className={classes.input}
                        type="text"
                        name="selectedFunctionDescription"
                        value={selectedFunctionDescription || ''}
                        onChange={({ target: { name, value } }) => onChange(name, value)}
                    />
                    <Button
                        disabled={
                            StringUtils.isEmpty(selectedFunction)
                            || StringUtils.isEmpty(selectedFunctionDescription)
                            || parentActionInProgress
                        }
                        className={classes.containedSecondaryInfo}
                        size="small"
                        onClick={addFunction}
                    >
                        Add
                    </Button>
                </div>
                <div className={classes.tableContainer} style={{ height: `${tableHight}px` }}>
                    {parentWidth > 0 && (
                        <VirtualTable
                            loading={loadingFunctions}
                            rowHeight={45}
                            totalRecords={selectedFunctions.length}
                            data={selectedFunctions}
                            columns={functionsColumns}
                            width={functionsTableWidth}
                        />
                    )}
                </div>
            </Form.Group>
        </div>
    );
});

AssistantFunctions.defaultProps = {
    existingFunctions: [],
    parentActionInProgress: false,
    tableHight: 300,
};

AssistantFunctions.propTypes = {
    existingFunctions: PropTypes.any,
    parentActionInProgress: PropTypes.bool,
    tableHight: PropTypes.number,
};

export default AssistantFunctions;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles, Dialog, DialogContent, AppBar, Toolbar, Typography, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Header from 'components/widgets/Header';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputSearch from 'components/widgets/InputSearch';
import DialogActions from 'components/widgets/modal/DialogActions';
import useCustomerAndVehicleMethods from 'components/modules/service//serviceInvoice/hooks/useCustomerAndVehicleMethods';
import { AddIcon } from 'components/icons';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles(() => (
    {
        dialogContent: {
            height: '62vh',
            '& > div:first-child': {
                height: '62vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        search: {
            borderRadius: 10,
            width: 400,
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
    }
));

const VehiclesByCustomerList = (props) => {
    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        appBar, centerItems, title,
        dialogContent, search,
    } = classes;

    const {
        inHouse, code, onClose, onSelectVehicle,
        onClickNewVehicleButton,
    } = props;

    const [selectedRecord, onSelectedRecord] = useState(null);
    const {
        getVehicleData, loadingVehicleInfo,
        vehicles, getVehicleColumns,
    } = useCustomerAndVehicleMethods({ inHouse });

    useEffect(() => {
        if (code) {
            getVehicleData({
                variables: {
                    code,
                    inHouse,
                    search: '',
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = (value) => {
        getVehicleData({
            variables: {
                code,
                inHouse,
                search: value?.trim() ?? '',
            },
        });
    };

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth={inHouse ? 'lg' : 'md'}
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            Select Vehicle
                        </Typography>
                        <div className={centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <div className={classes.main}>
                        <Header>
                            <InputSearch
                                autoFocus
                                customClasses={clsx(search)}
                                onSearch={onSearch}
                            />
                            {!inHouse && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    size="small"
                                    onClick={onClickNewVehicleButton}
                                >
                                    Add new Vehicle
                                </Button>
                            ) }
                        </Header>
                        <div className={classes.mainTable}>
                            <Table
                                cursor="default"
                                load={loadingVehicleInfo}
                                totalvehicles={vehicles.length || 0}
                                data={loadingVehicleInfo ? [] : vehicles}
                                columns={getVehicleColumns()}
                                className="-highlight"
                                rowSelected
                                enableRowHover
                                getTrProps={(_, rowInfo) => ({
                                    onDoubleClick: () => {
                                        onSelectedRecord(rowInfo.original);
                                    },
                                })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSelectVehicle(selectedRecord)}
                    disablePrimaryButton={!selectedRecord}
                />
            </Dialog>
        </>
    );
};

VehiclesByCustomerList.propTypes = {
    code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    inHouse: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSelectVehicle: PropTypes.func.isRequired,
    onClickNewVehicleButton: PropTypes.func,
};

VehiclesByCustomerList.defaultProps = {
    inHouse: false,
    onClickNewVehicleButton: () => {},
};

export default VehiclesByCustomerList;

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import ConversationStyles from 'styles/modules/ConversationStyles';

// Material Ui
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ConversationStyles.conversationPlaceholder(theme);

class ConversationPlaceholder extends PureComponent {
    render() {
        const { props: { classes } } = this;

        return (
            <Box
                display="flex"
                alignItems="center"
                flexGrow={1}
                justifyContent="center"
            >
                <Box
                    textAlign="center"
                >
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        Select conversation to display
                    </Typography>
                    <Typography
                        variant="subtitle1"
                    >
                        To start a conversation just click the message button from a person
                        profile
                    </Typography>
                </Box>
            </Box>
        );
    }
}

ConversationPlaceholder.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(ConversationPlaceholder);

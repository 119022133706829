import React from 'react';
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';

// HTTP
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import LotQuery from 'services/graphQL/query/LotQuery';

import ModalUtils from 'utils/ModalUtils';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme = {}) => ({
    redLabel: {
        color: theme.palette.text.oldBrick,
    },
    label: {
        color: theme.palette.text.boulder,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '13px',
    },
}));

const DownPaymentReceived = (props) => {
    const classes = useStyle();
    const { dealId, lotName, cashDownPayment } = props;

    const { data = {}, loading, error } = useQuery(DealsQuery.GET_DOWNPAYMENT_RECEIVED, {
        variables: { dealId },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { data: lotData = {}, loading: lotLoading, error: lotError } = useQuery(LotQuery.GET_SETTINGS, {
        variables: { category: 'Deals', key: 'allowTransferWithoutDownpayment', lotName },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const allowTransfer = JSON.parse(lotData.getSettings?.[0]?.value || true);

    if (error) {
        ModalUtils.errorMessage(error?.graphQLErrors);
    }

    if (lotError) {
        ModalUtils.errorMessage(lotError?.graphQLErrors);
    }

    const { getDownPaymentReceived = 0 } = data;

    const markRed = !allowTransfer && getDownPaymentReceived < cashDownPayment;

    return !loading && !lotLoading ? (
        <>
            <div className={classes.label}>Down Payment Received</div>
            <div className={markRed ? classes.redLabel : ''}>{` ${NumberUtils.applyCurrencyFormat(getDownPaymentReceived)}`}</div>
        </>
    ) : null;
};

DownPaymentReceived.propTypes = {
    dealId: PropTypes.number.isRequired,
    lotName: PropTypes.string.isRequired,
    cashDownPayment: PropTypes.number,
};

DownPaymentReceived.defaultProps = {
    cashDownPayment: 0,
};

export default DownPaymentReceived;

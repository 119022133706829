import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import EditorControlStyles from 'styles/widgets/EditorControlStyles';
import ModalUtils from 'utils/ModalUtils';
import Select from 'components/widgets/Select';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CatalogList from 'components/widgets/catalogs/CatalogList';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { DataSort, FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles(() => EditorControlStyles.selectStyles());

const keyStore = new KeyStore();

const CatalogSelect = (props) => {
    const CORE_CATALOGS_WRITE = keyStore.hasPermission(Permission.CORE_CATALOGS_WRITE);

    const [records, setRecords] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();
    const {
        name, value, onChange, catalogEnum, sortDirection, lotName, editorCellObject,
        useEnumValueId, enableAddOption, catalogTitle, className, ...other
    } = props;

    const [loadData] = useLazyQuery(
        CatalogQuery.GET_ENUM_VALUES,
        {
            onCompleted: (data) => {
                const dataList = data.getEnumCatalogValues.map((item) => ({
                    value: useEnumValueId ? item.enumValuesId : item.description,
                    label: item.description,
                }));
                dataList.unshift({ value: 0, label: 'select...' });

                if (enableAddOption) dataList.push({ value: -1, label: 'Add new item' });

                setRecords(dataList);
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage(null, errorMessage);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const handleChange = (id, newValue) => {
        if (newValue === -1) {
            setOpenDialog(true);
            return;
        }

        onChange(id, newValue, editorCellObject);
    };

    useEffect(() => {
        loadData({
            variables: {
                enumDescription: catalogEnum,
                sortDirection,
                lotName,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClosePopup = () => {
        loadData({
            variables: {
                enumDescription: catalogEnum,
                sortDirection,
                lotName,
            },
        });
        setOpenDialog(false);
    };

    return (
        <>
            <Select
                name={name}
                options={records}
                value={value}
                onChange={handleChange}
                className={clsx('basic-multi-select select-bootstrap form-control-sm', classes.selectSM, className)}
                {...other}
            />
            { openDialog && enableAddOption && CORE_CATALOGS_WRITE
            && (
                <CatalogList
                    openDialog={openDialog}
                    catalogEnum={catalogEnum}
                    catalogTitle={catalogTitle}
                    lotName={lotName}
                    sortDirection={sortDirection}
                    onClose={onClosePopup}
                />
            )}
        </>
    );
};

CatalogSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    catalogEnum: PropTypes.string.isRequired,
    sortDirection: PropTypes.string,
    lotName: PropTypes.string,
    useEnumValueId: PropTypes.bool,
    enableAddOption: PropTypes.bool,
    catalogTitle: PropTypes.string.isRequired,
    editorCellObject: PropTypes.object,
};

CatalogSelect.defaultProps = {
    value: null,
    className: '',
    sortDirection: DataSort.ASC,
    lotName: null,
    useEnumValueId: false,
    enableAddOption: false,
    editorCellObject: {},
};

export default CatalogSelect;

import React from 'react';
import PropTypes from 'prop-types';

const PhoneIcon = ({ width, height, color }) => (
    <svg
        width={width}
        height={height}
        style={{ enableBackground: 'new -430 431 139 139' }}
        version="1.1"
        viewBox="-430 431 139 139"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g>
            <path
                fill={color}
                d="M-372.8,509.2c0,0,14,13.8,19.7,14.9l11.7-11.7c0,0,4.6-4.4,9.5,0.1l19.1,19.1
                c0,0,3.1,5.7-0.5,9.2l-9.3,9.3c0,0-16.4,10.5-42.7-6.3c0,0-12.4-6.5-23.9-18.2"
            />
            <path
                fill={color}
                d="M-371.8,510.2c0,0-13.8-14-15-19.7l11.7-11.7c0,0,4.4-4.6-0.1-9.5l-19.2-19.1
                c0,0-5.7-3.1-9.2,0.5l-9.3,9.3c0,0-10.5,16.4,6.4,42.7c0,0,6.5,12.4,18.3,23.9"
            />
        </g>
    </svg>
);

PhoneIcon.defaultProps = {
    width: null,
    height: null,
    color: null,
};

PhoneIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default PhoneIcon;

import React, { useEffect, useReducer } from 'react';

import update from 'immutability-helper';
import {
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { concat, isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import MailQuery from 'services/graphQL/query/crm/MailQuery';

const useStyles = makeStyles(() => ({
    main: {
        flex: 1,
        padding: '10px',
        height: '100%',
    },
}));

const ACTION_TYPE = {
    SET_OFFSET: 'setOffset',
    SET_RECORDS: 'setRecords',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_RECORDS:
        const records = concat(state.records, action.payload?.data);
        return update(state, {
            records: { $set: records },
            totalCount: { $set: action.payload?.total },
        });
    case ACTION_TYPE.SET_OFFSET:
        return update(state, {
            offset: { $set: action.value },
        });
    default:
        return [];
    }
};
const initState = {
    records: [],
    totalCount: 0,
    offset: 0,
};

const EmailHistory = ({ opportunityId, className }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        data, error, loading,
    } = useQuery(MailQuery.GET_EMAILS_HISTORY_BY_OPPORTUNITY_ID,
        {
            variables: { opportunityId, paginate: { start: state.offset, limit: 25 } },
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    useEffect(() => {
        if (!loading && !isEmpty(data?.getEmailsHistoryByOpportunityId)) {
            dispatch({
                type: ACTION_TYPE.SET_RECORDS,
                payload: data?.getEmailsHistoryByOpportunityId,
            });
        }
    }, [data, loading]);

    const loadMore = () => {
        const currentOffset = state.records.length;
        dispatch({ type: ACTION_TYPE.SET_OFFSET, value: currentOffset });
    };

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
        return null;
    }

    const columns = [
        {
            Header: 'User',
            accessor: 'createdBy',
            maxWidth: 120,
            Cell: (record) => {
                const { firstName, lastName } = record.value;
                const fullName = `${firstName} ${lastName}`;

                return (
                    <span>{fullName}</span>
                );
            },
        },
        {
            Header: 'Date',
            accessor: 'createdOn',
            maxWidth: 150,
            className: classes.column,
            Cell: (record) => DateUtils.format(record.value, DateFormat.DATETIME_WITHOUT_SECONDS),
        },
        {
            Header: 'Direction',
            accessor: 'incoming',
            maxWidth: 90,
            className: classes.descriptionCol,
            Cell: (record) => (
                <span>{record.value ? 'Incoming' : 'Outgoing'}</span>
            ),
        },
        {
            Header: 'Message',
            accessor: 'bodyPlain',
            className: classes.descriptionCol,
        },
    ];

    return (
        <div className={clsx(className, classes.main)}>
            <Table
                rowSelected
                load={loading}
                columns={columns}
                loadMore={loadMore}
                data={state.records}
                totalRecords={state.totalCount}
            />
        </div>
    );
};

EmailHistory.propTypes = {
    className: PropTypes.string,
    opportunityId: PropTypes.string.isRequired,
};

EmailHistory.defaultProps = {
    className: null,
};

export default EmailHistory;

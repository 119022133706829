import React, { useReducer, useEffect } from 'react';
import {
    makeStyles, Button, Typography, Divider, Avatar, Tooltip, useTheme, useMediaQuery,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import Split from 'react-split';
import ModalUtils from 'utils/ModalUtils';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import { useLazyQuery, useQuery } from '@apollo/client';
import NumberUtils from 'lib/NumberUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import {
    ListAltSharpIcon, SaveAltIcon, FormatListBulletedOutlinedIcon,
} from 'components/icons';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
import LabelValue from 'components/widgets/LabelValue';
import CashierBatchOut from 'components/modules/accounting/accountsReceivable/create/CashierBatchout';
import CashierOpenCollections from 'components/modules/accounting/accountsReceivable/list/CashierOpenCollections';
import { CashierDirectionOperation } from 'utils/enum/AccountingEnum';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import printJS from 'print-js';
import PropTypes from 'prop-types';

const useStyle = makeStyles((theme) => AccountingStyles.cashierOpenBalance(theme));

const ACTION_TYPES = {
    SET_NEW: 'setNew',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    SET_INITIAL_STATE: 'setInitialState',
    SET_OPEN_BATCHOUT: 'setOpenBatchOut',
    SET_CUSTOM_FIELD_DATA: 'setCustomField',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_NEW: {
        return {
            ...state,
            isEditing: false,
            openTakePayment: true,
        };
    }
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
            hasCustomFieldValues: action.hasCustomFieldValues,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_OPEN_BATCHOUT: {
        return {
            ...state,
            openBatchOut: action.value.open,
            collectionData: action?.value?.collectionData ?? null,
            selectedRow: action?.value.selectedRow ?? null,
        };
    }
    case ACTION_TYPES.SET_CUSTOM_FIELD_DATA: {
        return {
            ...state,
            customFieldData: action.value,
            isSettingsReady: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    default: return state;
    }
};

const CashierOpenBalances = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        userNameLogged, haveAccessToOpenBalances,
    } = props;

    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
                ignoreLimit: true,
            },
            search: null,
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: null,
        openBatchOut: false,
        customFieldData: {
            displayCustomField: true,
            customFieldName: '',
        },
        hasCustomFieldValues: false,
        isSettingsReady: false,
        collectionData: null,
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow,
        openBatchOut, customFieldData,
        isSettingsReady, hasCustomFieldValues,
    } = state;

    const {
        data, loading, error,
    } = useQuery(AccountReceivableQuery.GET_CASHIER_OPEN_BALANCES, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
                onlyActive: true,
                direction: CashierDirectionOperation.IN,
                userName: !haveAccessToOpenBalances ? userNameLogged : null,
            },
            sort: [{ fieldName: 'userName', dir: DataSort.ASC },
                { fieldName: 'dateNTime', dir: DataSort.DESC },
            ],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [printReport, { loading: printing }] = useLazyQuery(AccountReceivableQuery.PRINT_SHIFT_COLLECTIONS_REPORT, {
        onCompleted: (res) => {
            if (res.printShiftCollectionReport) {
                const { printShiftCollectionReport } = res;

                printJS({
                    printable: printShiftCollectionReport,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getCashierCustomField, { loading: loadingCustomField }] = useLazyQuery(AccountReceivableQuery.GET_CASHIER_CUSTOM_FIELD, {
        onCompleted: (res) => {
            if (res.getCashierCustomField) {
                dispatch({
                    type: ACTION_TYPES.SET_CUSTOM_FIELD_DATA,
                    value: res.getCashierCustomField,
                });
            }
        },
        onError: (customFieldError) => {
            ModalUtils.errorMessage([customFieldError]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getCashierOpenBalances } = data;
            const mapRecords = AccountReceivablesMapper.mapCashierOpenBalances(getCashierOpenBalances.data);
            let hasCustomField = false;
            mapRecords.every((element) => {
                if (element.records?.length > 0) {
                    hasCustomField = element.records.some((item) => item.customField > 0);
                    if (hasCustomField) return false;
                }
                if (hasCustomField) return false;
                return true;
            });

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records: mapRecords,
                    totalCount: mapRecords.length,
                },
                hasCustomFieldValues: hasCustomField,
            });

            getCashierCustomField();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onSearch = (text) => {
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onPrintList = (username, dateParam = null) => {
        const currentDate = new Date();

        printReport({
            variables: {
                userName: username ?? null,
                printDate: DateUtils.format(`${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`, DateFormat.DATETIME_WITHOUT_SECONDS),
                date: dateParam,
            },
        });
    };

    const columns = [
        {
            Header: () => (
                <div className={classes.itemContainer}>
                    <div className={clsx(classes.expansionCol, classes.columnLeft)}>
                        <Typography
                            className={clsx(classes.columnLeft, classes.boldHeaderLeft)}
                            variant="h6"
                            color="primary"
                        >
                            Cashier
                        </Typography>
                    </div>
                </div>
            ),
            minWidth: 80,
            width: 80,
            id: 'userName',
            accessor: 'userName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (val) => {
                const { original: { userName, userPicture } } = val;

                return (
                    <div className={clsx(classes.expansionCol, classes.containerAvatarWithName)}>
                        <div
                            className={classes.boxAvatarWithIcon}
                        >
                            <Avatar className={classes.small} src={userPicture} />
                            <Typography variant="h6" className={classes.textAvatar} noWrap>{userName}</Typography>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div className={classes.itemContainer}>
                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderLeft}
                            variant="h6"
                            color="primary"
                        >
                            Date
                        </Typography>
                    </div>

                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderColor}
                            variant="h6"
                            color="primary"
                        >
                            Cash
                        </Typography>
                    </div>

                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderColor}
                            variant="h6"
                            color="primary"
                        >
                            Check
                        </Typography>
                    </div>

                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderColor}
                            variant="h6"
                            color="primary"
                        >
                            Charge
                        </Typography>
                    </div>

                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderColor}
                            variant="h6"
                            color="primary"
                        >
                            Cashier`s
                            <br />
                            Check
                        </Typography>
                    </div>

                    {(customFieldData.displayCustomField || hasCustomFieldValues) && (
                        <div className={classes.expansionCol}>
                            <Typography
                                className={classes.boldHeaderColor}
                                variant="h6"
                                color="primary"
                            >
                                {customFieldData.customFieldName.replace(' ', '\n')}
                            </Typography>
                        </div>
                    )}

                    <div className={classes.expansionCol}>
                        <Typography
                            className={classes.boldHeaderColor}
                            variant="h6"
                            color="primary"
                        >
                            Total
                        </Typography>
                    </div>
                    <div className={clsx(classes.expansionCol, classes.columnRight)}>
                        <Typography
                            className={clsx(classes.boldHeaderColor, classes.columnRight)}
                            variant="h6"
                            color="primary"
                        >
                            Actions
                        </Typography>
                    </div>
                </div>
            ),
            minWidth: 100,
            id: 'dateNTime',
            accessor: (record) => record,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (val) => {
                const { original: { records } } = val;
                let sumAmount = 0;
                let currentUserName = '';
                let currentRecord = null;
                return (
                    <>
                        {records.map((record, index) => {
                            sumAmount += record.amount;
                            currentUserName = record.userName;
                            currentRecord = record;
                            return (
                                <div className={classes.itemContainer} key={index}>
                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumnLeft}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {DateUtils.format(record.date)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumn}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {NumberUtils.applyCurrencyFormat(record.cash)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumn}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {NumberUtils.applyCurrencyFormat(record.check)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumn}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {NumberUtils.applyCurrencyFormat(record.charge)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumn}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {NumberUtils.applyCurrencyFormat(record.cashiersCheck)}
                                            </Typography>
                                        </div>
                                    </div>

                                    {(customFieldData.displayCustomField || hasCustomFieldValues) && (
                                        <div className={classes.expansionCol}>
                                            <div className={classes.operationColumn}>
                                                <Typography
                                                    className={classes.boldColor}
                                                    variant="h6"
                                                    color="primary"
                                                >
                                                    {NumberUtils.applyCurrencyFormat(record.customField)}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes.expansionCol}>
                                        <div className={classes.operationColumn}>
                                            <Typography
                                                className={classes.boldColor}
                                                variant="h6"
                                                color="primary"
                                            >
                                                {NumberUtils.applyCurrencyFormat(record.amount)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.expansionCol, classes.paddingLeft10)}>
                                        <div className={clsx(classes.columnFlexEnd, classes.buttonSpacing)}>
                                            {haveAccessToOpenBalances && (
                                                <Tooltip title="Create Batchout">
                                                    <SaveAltIcon
                                                        onClick={() => dispatch({
                                                            type: ACTION_TYPES.SET_OPEN_BATCHOUT,
                                                            value: {
                                                                open: true,
                                                                collectionData: record,
                                                                selectedRow: record,
                                                            },
                                                        })}
                                                        className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Show detail by date">
                                                <FormatListBulletedOutlinedIcon
                                                    onClick={() => dispatch({
                                                        type: ACTION_TYPES.SET_STATE_VALUES,
                                                        value: {
                                                            collectionData: record,
                                                            selectedRow: record,
                                                        },
                                                    })}
                                                    className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Print by date">
                                                <ListAltSharpIcon
                                                    onClick={() => onPrintList(record.userName, record.date)}
                                                    className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className={classes.itemContainer}>
                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    />
                                </div>
                            </div>

                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    />
                                </div>
                            </div>

                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    />
                                </div>
                            </div>

                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    />
                                </div>
                            </div>

                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    />
                                </div>
                            </div>

                            {(customFieldData.displayCustomField || hasCustomFieldValues) && (
                                <div className={classes.expansionCol}>
                                    <div className={classes.operationColumn}>
                                        <Typography
                                            className={classes.boldColor}
                                            variant="h6"
                                            color="primary"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Divider className={classes.dividerPrimary} />
                                    <Typography
                                        className={classes.boldColor}
                                        variant="h6"
                                        color="primary"
                                    >
                                        {NumberUtils.applyCurrencyFormat(sumAmount)}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.expansionCol}>
                                <div className={classes.operationColumn}>
                                    <Divider className={classes.dividerPrimary} />
                                    <div className={clsx(classes.expansionCol, classes.paddingLeft3)}>
                                        <div className={clsx(classes.columnFlexEnd, classes.buttonSpacing)}>
                                            <Tooltip title="Print shift list">
                                                <FormatListBulletedOutlinedIcon
                                                    onClick={() => dispatch({
                                                        type: ACTION_TYPES.SET_STATE_VALUES,
                                                        value: {
                                                            collectionData: null,
                                                            selectedRow: currentRecord,
                                                        },
                                                    })}
                                                    className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Print shift list">
                                                <ListAltSharpIcon
                                                    onClick={() => onPrintList(currentUserName)}
                                                    className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <div className={classes.main}>
            {haveAccessToOpenBalances && (
                <Header>
                    <div className={classes.containerFilterSearch}>
                        <InputSearch
                            customClasses={clsx(classes.search, isMobile ? classes.searchFull : '')}
                            initialSearch={params.search || ''}
                            onSearch={onSearch}
                        />
                        <LabelValue
                            label="Items"
                            value={table.totalCount}
                        />
                    </div>

                    <div className={classes.buttonSpacing}>
                        <Tooltip title="Print all shift" placement="top-start">
                            <span>
                                <Button
                                    variant="outlined"
                                    startIcon={<ListAltSharpIcon />}
                                    disabled={loading || printing}
                                    onClick={() => onPrintList()}
                                    size="small"
                                >
                                    Print All Shift List
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Print current shift list" placement="top-start">
                            <span>
                                <Button
                                    variant="outlined"
                                    startIcon={<ListAltSharpIcon />}
                                    disabled={loading || !selectedRow || printing}
                                    onClick={() => onPrintList(selectedRow.userName)}
                                    size="small"
                                >
                                    Print Current Shift List
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </Header>
            )}
            <Split
                sizes={[60, 40]}
                className={classes.splitOne}
                minSize={0}
            >
                <div>
                    {!isSettingsReady && <div className={classes.columnCenter}><CircularProgress size="1em" color="inherit" /></div>}
                    {isSettingsReady && (
                        <Table
                            data={table?.records}
                            columns={columns}
                            cursor="default"
                            load={loading || loadingCustomField}
                            totalRecords={table?.totalCount}
                            rowSelected
                            className="-highlight"
                            getTrProps={(_, rowInfo) => {
                                const record = rowInfo.original;
                                return {
                                    onDoubleClick: () => {
                                        dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: {
                                                collectionData: null,
                                                selectedRow: record,
                                            },
                                        });
                                    },
                                };
                            }}
                        />
                    )}
                </div>
                <div className={classes.customHeight}>
                    <CashierOpenCollections
                        userName={haveAccessToOpenBalances ? selectedRow?.userName : userNameLogged}
                        date={state.collectionData?.date ?? null}
                    />
                </div>
            </Split>
            {openBatchOut && (
                <CashierBatchOut
                    openBatchOut
                    onPopupClose={() => dispatch({
                        type: ACTION_TYPES.SET_OPEN_BATCHOUT,
                        value: {
                            open: false,
                            collectionData: state.collectionData,
                        },
                    })}
                    userName={selectedRow.userName}
                    userPicture={selectedRow.userPicture}
                    collectionData={state.collectionData}
                    customFieldData={{ ...customFieldData, hasCustomFieldValues }}
                />
            )}
            {printing && <DialogActionMessage message="Printing... " />}
        </div>
    );
};

CashierOpenBalances.propTypes = {
    userNameLogged: PropTypes.string,
    haveAccessToOpenBalances: PropTypes.bool,
};

CashierOpenBalances.defaultProps = {
    userNameLogged: '',
    haveAccessToOpenBalances: true,
};

export default CashierOpenBalances;

import React from 'react';

// Components and Others
import PropTypes from 'prop-types';
import Loading from 'components/widgets/Loading';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import useCustomField from 'components/modules/deals/create/forms/useCustomField';
import {
    CustomFieldCheckBox, CustomFieldInteger, CustomFieldDate, CustomFieldText,
    CustomFieldTitle, CustomFieldCheckBoxText, CustomFieldRadio, CustomFieldSelect,
    CustomFieldFloat,
} from 'components/modules/deals/create/forms/CustomField';
import { MetaData } from 'utils/enum/CustomField';
import { Form, Col } from 'react-bootstrap';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';
import If from 'components/widgets/conditional/If';

const useStyles = makeStyles((theme) => DealStyles.customField(theme));

const CustomField = ({
    record, indexForm, indexCustomField, onChange, onChangeCheckbox,
}) => {
    const type = record.type?.toUpperCase();

    if (type === MetaData.TITLE) {
        return (
            <CustomFieldTitle
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.TEXT) {
        return (
            <CustomFieldText
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.DATE) {
        return (
            <CustomFieldDate
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.INTEGER) {
        return (
            <CustomFieldInteger
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.FLOAT) {
        return (
            <CustomFieldFloat
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.CHECKBOX) {
        return (
            <CustomFieldCheckBox
                {...record}
                indexForm={indexForm}
                onChange={onChangeCheckbox}
                indexCustomField={indexCustomField}
                key={`${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.CHECKBOX_TEXT) {
        return (
            <CustomFieldCheckBoxText
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`radio-${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.RADIO) {
        return (
            <CustomFieldRadio
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`radio-${indexForm}${indexCustomField}`}
            />
        );
    }

    if (type === MetaData.SELECT) {
        return (
            <CustomFieldSelect
                {...record}
                onChange={onChange}
                indexForm={indexForm}
                indexCustomField={indexCustomField}
                key={`select-${indexCustomField}`}
            />
        );
    }

    return null;
};

CustomField.propTypes = {
    record: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    indexForm: PropTypes.number.isRequired,
    indexCustomField: PropTypes.number.isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
};

const CustomFieldDialog = ({
    open, records, onClickPrimaryButton,
    loading, showCommonName, title, onClose,
    className, maxWidth, textPrimaryButton,
}) => {
    const classes = useStyles();
    const { customField, onChange, onChangeCheckbox } = useCustomField(records);

    const renderItem = (record, indexForm) => {
        if (record.data.length > 0) {
            return (
                <Grid item xs={12} key={indexForm}>
                    <If condition={showCommonName}>
                        <Form.Group as={Col}>
                            <h4>{record.commonName}</h4>
                        </Form.Group>
                    </If>
                    {record.data?.map((item, indexCustomField) => CustomField({
                        record: item,
                        onChange,
                        indexForm,
                        indexCustomField,
                        onChangeCheckbox,
                    }))}
                </Grid>
            );
        }

        return null;
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth}
            PaperProps={{
                className: classes.dialog,
            }}
            className={className}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogAppBar title={title} iconSize="sm" onClose={() => onClose(customField)} disabled={loading} />
            <DialogContent className={classes.dialogContent}>
                {loading && <Loading className={classes.loading} />}
                <Grid container spacing={6} className="am-form">
                    {customField.map((record, indexForm) => renderItem(record, indexForm))}
                </Grid>
            </DialogContent>
            <DialogActions
                onClickSecondary={() => onClose(customField)}
                onClickPrimary={() => onClickPrimaryButton(customField)}
                titlePrimary={textPrimaryButton}
                disablePrimaryButton={loading}
                disableSecondaryButton={loading}
            />
        </Dialog>
    );
};

CustomFieldDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    records: PropTypes.array.isRequired,
    showCommonName: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    maxWidth: PropTypes.string,
    textPrimaryButton: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onClickPrimaryButton: PropTypes.func.isRequired,
};

CustomFieldDialog.defaultProps = {
    showCommonName: true,
    title: 'Custom Field',
    className: '',
    maxWidth: 'md',
    textPrimaryButton: 'Save',
};

export default CustomFieldDialog;

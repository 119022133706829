import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { AccountsReceivableEntities } from 'utils/enum/AccountingEnum';
import {
    FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import UserContext from 'components/context/UserContext';
import AccountingUtils from 'utils/AccountingUtils';
import SettingsStyles from 'styles/modules/accounting/AccountingStyles';
import CashierPaymentList from 'components/modules/accounting/accountsReceivable/list/CashierPaymentList';
import AccountReceivableDealList from 'components/modules/accounting/accountsReceivable/list/AccountReceivableDealList';
import AccountReceivableServiceList from 'components/modules/accounting/accountsReceivable/list/AccountReceivableServiceList';
import CashierOpenBalances from 'components/modules/accounting/accountsReceivable/list/CashierOpenBalances';
import AccountReceivablePartList from 'components/modules/accounting/accountsReceivable/list/AccountReceivablePartList';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanel(fade, theme));
const keyStore = new KeyStore();

const AccountReceivableList = () => {
    const userData = useContext(UserContext);
    const ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES = keyStore.hasPermission(
        Permission.ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES,
    );
    const { submoduleParam, entity } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [arEntity, setAREntity] = useState(entity || AccountsReceivableEntities.DEALS);

    const subModules = AccountingUtils.getSubModuleList();
    const handleEntityChange = (entitySelected) => {
        history.push(`/${modules.ACCOUNTING}/${subModules[0]}/${entitySelected}`);
        setAREntity(entitySelected);
    };

    useEffect(() => {
        if (!submoduleParam) {
            history.push(`/${modules.ACCOUNTING}/${subModules[0]}/${arEntity}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FormControl component="fieldset" className={clsx(classes.boxContainer, classes.paddingLeft25)}>
                <RadioGroup row value={arEntity} onChange={(e, val) => handleEntityChange(val)}>
                    <FormControlLabel
                        value={AccountsReceivableEntities.DEALS}
                        control={<Radio color="primary" />}
                        label="Deals"
                    />
                    <FormControlLabel
                        value={AccountsReceivableEntities.SERVICE}
                        control={<Radio color="primary" />}
                        label="Service"
                    />
                    <FormControlLabel
                        value={AccountsReceivableEntities.PARTS}
                        control={<Radio color="primary" />}
                        label="Parts"
                    />
                    <FormControlLabel
                        value={AccountsReceivableEntities.OTHER_COLLECTIONS}
                        control={<Radio color="primary" />}
                        label="Other Collections"
                    />
                    {!ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES
                        && (
                            <FormControlLabel
                                value={AccountsReceivableEntities.SHIFT_COLLECTIONS}
                                control={<Radio color="primary" />}
                                label="Shift Collections"
                            />
                        )}
                    {ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES
                        && (
                            <FormControlLabel
                                value={AccountsReceivableEntities.CASHIER_OPEN_BALANCES}
                                control={<Radio color="primary" />}
                                label="Cashier's Open Balances"
                            />
                        )}
                </RadioGroup>
            </FormControl>
            {arEntity === AccountsReceivableEntities.DEALS && <AccountReceivableDealList />}
            {arEntity === AccountsReceivableEntities.SERVICE && <AccountReceivableServiceList />}
            {arEntity === AccountsReceivableEntities.PARTS && <AccountReceivablePartList />}
            {arEntity === AccountsReceivableEntities.OTHER_COLLECTIONS && <CashierPaymentList />}
            {arEntity === AccountsReceivableEntities.PETTY_CASH && <CashierPaymentList />}
            {arEntity === AccountsReceivableEntities.SHIFT_COLLECTIONS
                && userData?.userInformation
                && !ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES
                && <CashierOpenBalances userNameLogged={userData?.userInformation?.username} haveAccessToOpenBalances={false} />}
            {arEntity === AccountsReceivableEntities.CASHIER_OPEN_BALANCES
                && ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES
                && <CashierOpenBalances />}
        </>
    );
};

export default AccountReceivableList;

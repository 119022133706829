import update from 'immutability-helper';
import { filters } from 'utils/enum/CustomQueriesEnum';

export const INITIAL_STATE = {
    tables: [],
    filteredTables: [],
    columns: [],
    filteredColumns: [],
    queries: [],
    filteredQueries: [],
    selectedTable: null,
    editor: {
        savedQuerieId: 0,
        queryName: '',
        queryText: '',
        locked: false,
    },
    isQueryNameValid: true,
    isQueryTextValid: true,
    confirmDialog: {
        opened: false,
        description: '',
        actionOnProceed: '',
    },
    previewData: {
        records: [],
    },
    pullingPreviewData: false,
    screenLoaded: false,
    params: null,
};

export const ACTION_TYPES = {
    SET_TABLES: 'setTables',
    SET_COLUMNS: 'setColumns',
    SET_QUERIES: 'setQueries',
    SET_SELECTED_TABLE: 'setSelectedTable',
    SET_EDITOR_CONTENT: 'setEditorContent',
    SET_INITIAL_EDITOR: 'setInitialEditor',
    ADD_NEW_QUERY: 'addNewQuery',
    SET_IS_QUERY_NAME_VALID: 'setIsQueryNameValid',
    SET_IS_QUERY_TEXT_VALID: 'setIsQueryTextValid',
    EDIT_QUERY: 'editQuery',
    REMOVE_QUERY: 'removeQuery',
    SET_IS_CONFIRM_DIALOG_OPEN: 'setIsConfrimDialogOpen',
    SET_PREVIEW_DATA: 'setPreviewData',
    START_PULLING_PREVIEW_DATA: 'startPullingPreviewData',
    REMOVE_SPINNER_ON_ERROR: 'removeSpinnerOnError',
    FILTER_LIST: 'filterList',
    SET_PARAMS: 'setParams',
};

const QueriesReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLES: {
        const tables = action.value;
        return {
            ...state,
            tables,
            filteredTables: tables,
            selectedTable: tables.length > 0 ? tables[0] : null,
        };
    }
    case ACTION_TYPES.SET_COLUMNS: {
        const columns = action.value;
        return {
            ...state,
            columns,
            filteredColumns: columns,
            screenLoaded: true,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return { ...state, params: action.value };
    }
    case ACTION_TYPES.SET_QUERIES: {
        const queries = action.value;
        return { ...state, queries, filteredQueries: queries };
    }
    case ACTION_TYPES.SET_SELECTED_TABLE: {
        return { ...state, selectedTable: action.value };
    }
    case ACTION_TYPES.SET_EDITOR_CONTENT: {
        return {
            ...state,
            editor: action.value,
            isQueryNameValid: true,
            isQueryTextValid: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_EDITOR: {
        return {
            ...state,
            editor: INITIAL_STATE.editor,
            isQueryNameValid: true,
            isQueryTextValid: true,
        };
    }
    case ACTION_TYPES.ADD_NEW_QUERY: {
        return {
            ...state,
            queries: [action.value, ...state.queries].sort((a, b) => a.queryName.localeCompare(b.queryName)),
            filteredQueries: [action.value, ...state.filteredQueries].sort((a, b) => a.queryName.localeCompare(b.queryName)),
            editor: action.value,
        };
    }
    case ACTION_TYPES.SET_IS_QUERY_NAME_VALID: {
        return { ...state, isQueryNameValid: action.value };
    }
    case ACTION_TYPES.SET_IS_QUERY_TEXT_VALID: {
        return { ...state, isQueryTextValid: action.value };
    }
    case ACTION_TYPES.EDIT_QUERY: {
        const { editor, queries, filteredQueries } = state;
        const index = queries.findIndex((query) => query.savedQuerieId === editor.savedQuerieId);
        const indexFilteredQueries = filteredQueries.findIndex((query) => query.savedQuerieId === editor.savedQuerieId);

        return {
            ...state,
            queries: update(queries, {
                [index]: {
                    queryName: { $set: editor.queryName },
                    queryText: { $set: editor.queryText },
                },
            }),
            filteredQueries: update(filteredQueries, {
                [indexFilteredQueries]: {
                    queryName: { $set: editor.queryName },
                    queryText: { $set: editor.queryText },
                },
            }),
        };
    }
    case ACTION_TYPES.REMOVE_QUERY: {
        return {
            ...state,
            editor: INITIAL_STATE.editor,
            isQueryNameValid: true,
            isQueryTextValid: true,
            confirmDialog: INITIAL_STATE.confirmDialog,
            queries: state.queries.filter((query) => query.savedQuerieId !== action.value),
            filteredQueries: state.filteredQueries.filter((query) => query.savedQuerieId !== action.value),
        };
    }
    case ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN: {
        return { ...state, confirmDialog: action.value };
    }
    case ACTION_TYPES.START_PULLING_PREVIEW_DATA: {
        return { ...state, pullingPreviewData: true, confirmDialog: INITIAL_STATE.confirmDialog };
    }
    case ACTION_TYPES.SET_PREVIEW_DATA: {
        return {
            ...state,
            previewData: action.value,
            pullingPreviewData: false,
        };
    }
    case ACTION_TYPES.REMOVE_SPINNER_ON_ERROR: {
        return { ...state, pullingPreviewData: false };
    }
    case ACTION_TYPES.FILTER_LIST: {
        const { tables, columns, queries } = state;
        const { filter, input } = action.value;

        if (filter === filters.FILTER_TABLES) {
            if (input === '') {
                return {
                    ...state,
                    filteredTables: tables,
                    selectedTable: tables.length > 0 ? tables[0] : null,
                };
            }

            return {
                ...state,
                filteredTables: tables.filter((table) => table.toLowerCase().includes(input.toLowerCase())),
                selectedTable: null,
                filteredColumns: INITIAL_STATE.filteredColumns,
            };
        }

        if (filter === filters.FILTER_COLUMNS) {
            if (input === '') {
                return {
                    ...state,
                    filteredColumns: columns,
                };
            }

            return {
                ...state,
                filteredColumns: columns.filter((column) => column.toLowerCase().includes(input.toLowerCase())),
            };
        }

        if (filter === filters.FILTER_QUERIES) {
            if (input === '') {
                return {
                    ...state,
                    filteredQueries: queries,
                };
            }

            return {
                ...state,
                filteredQueries: queries.filter((query) => query.queryName.toLowerCase().includes(input.toLowerCase())),
            };
        }

        return state;
    }
    default:
        return state;
    }
};

export default QueriesReducer;

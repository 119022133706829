import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import PayrollGeneralQuery from 'services/graphQL/query/payroll/PayrollGeneral';

const useEmployeHasCheck = ({ employeeId, payPeriod }) => {
    const [hasCheck, setHasCheck] = useState(false);
    const {
        data, loading, error, refetch,
    } = useQuery(PayrollGeneralQuery.EMPLOYEE_HAS_CHECK, {
        skip: !employeeId || !payPeriod,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            setHasCheck(data?.employeeHasCheck || false);
        }
    }, [data, loading, error]);

    return {
        hasCheck, refetch,
    };
};

export default useEmployeHasCheck;

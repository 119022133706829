import { gql } from '@apollo/client';

export default class CheckPrintingSettings {
    static GET_CHECK_PRINTING_SETTINGS = gql`
        query getAccountingCOABankList($paginate: PaginationInput){
            getAccountingCOABankList(paginate: $paginate){
                data {
                    accountNumber
                    bankAccountNumber
                    routingNumber
                },
                totalCount
            }
        }
    `;
}

/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import NumberUtils from 'lib/NumberUtils';
import { PayType } from 'utils/enum/PayrollEnum';
import { PAY_TYPE } from 'utils/enum/ServiceInvoiceEnum';

export default class CalculationServiceMethodsHelper {
    static getLaborCostLocal(payInfo) {
        const {
            payType = '',
            salary = 0,
            hours = 0,
        } = payInfo;

        let laborCost = 0;

        const dividerSalaryRate = 2080; // TODO: this should be part of settings

        if (payType) {
            if (payType?.toLowerCase() === 'salary') laborCost = (salary / dividerSalaryRate) * hours;
            else laborCost = salary * hours;
        }

        return NumberUtils.round(laborCost);
    }

    static setCalculationsLocal(record, serviceSettings) {
        const { jobs } = record;

        const {
            hourlyLaborRate,
            customerPayHourlyRate,
            shopSuppliesPercent,
            shopSuppliesLimit,
            surplusTax,
            customTaxRateValue,
            customTaxRateEnabled,
            noTaxOnRoLabor,
            technicianSettings,
            chargeSalesTaxASR,
            chargeSalesTaxOnWarrantyDeductable,
            chargeSalesTaxOnWarrantyParts,
            shopSuppliesType,
        } = serviceSettings;

        const jobsApproved = jobs.filter((c) => c.approved);

        let warrantyCovered = 0;
        let internalCovered = 0;
        let partCost = 0;

        let totalParts = 0;
        let totalLabor = 0;
        let totalSubletCost = 0;
        let totalSubletPrice = 0;
        let totalLaborCost = 0;
        let totalDiscount = 0;
        let totalCustomerPay = 0;
        let totalCustomerTax = 0;

        const defaultSurplusSalesTax = 7; // TODO: this should be in settings
        const surplusSalesTax = customTaxRateEnabled ? customTaxRateValue : (surplusTax ?? defaultSurplusSalesTax);
        const surplusTaxRate = surplusSalesTax / 100;

        jobsApproved.forEach((item) => {
            const payTypeUpper = item.payType.toUpperCase();
            const isASR = payTypeUpper === PAY_TYPE.AFTER_SALE_REPAIR;

            if (record.inHouse || (!record.inHouse && (payTypeUpper === PAY_TYPE.WE_OWE || isASR))) {
                item.hourlyRate = hourlyLaborRate;
            } else {
                item.hourlyRate = customerPayHourlyRate;
            }

            item.laborTotal = NumberUtils.round(item.isHrsChecked ? item.hours * item.hourlyRate : item.laborTotal);

            const payInfo = technicianSettings.find((c) => Number(c.userId) === Number(item.technicianId));
            item.isFlatRate = payInfo?.payType?.toUpperCase() === PayType.FLAT_RATE.toUpperCase();
            item.laborCost = this.getLaborCostLocal({
                ...payInfo,
                hours: item.hours,
            });

            item.partsTotal = 0;
            item.partsTax = 0;
            const newParts = [];
            item?.parts?.forEach((part) => {
                const clonePart = { ...part };
                const calcPartTaxAmount = record.isTaxable && part.isTaxable && (
                    (
                        [PAY_TYPE.CUSTOMER_PAY, PAY_TYPE.WARRANTY_LABOR_ONLY, PAY_TYPE.WARRANTY_PAY_SUBLET].includes(payTypeUpper)
                    || (isASR && chargeSalesTaxASR)
                    || ([PAY_TYPE.WARRANTY_PARTS_ONLY, PAY_TYPE.WARRANTY_PARTS_LABOR].includes(payTypeUpper) && chargeSalesTaxOnWarrantyParts)
                    )
                    // only payType "Customer Pay" and "After Sale Repair with the option enable on settings"
                ) ? NumberUtils.round(part.netPrice * part.quantity) * surplusTaxRate : 0;
                clonePart.partTaxAmount = NumberUtils.round(calcPartTaxAmount);

                item.partsTotal += NumberUtils.round(part.netPrice * part.quantity);
                item.partsTax += NumberUtils.round(calcPartTaxAmount);
                partCost += NumberUtils.round(part.partCost * part.quantity);
                newParts.push(clonePart);
            });

            item.partsTotal = NumberUtils.round(item.partsTotal);
            item.partsTax = NumberUtils.round(item.partsTax);
            item.parts = newParts;

            if (record.laborDiscount) item.discount = 0;

            switch (payTypeUpper) {
            case PAY_TYPE.WARRANTY_PARTS_LABOR:
                warrantyCovered += item.partsTotal + item.laborTotal;
                item.warrantyCovered = item.partsTotal + item.laborTotal;
                break;
            case PAY_TYPE.WARRANTY_LABOR_ONLY:
                warrantyCovered += item.laborTotal;
                item.warrantyCovered = item.laborTotal;
                break;
            case PAY_TYPE.WARRANTY_PARTS_ONLY:
                warrantyCovered += item.partsTotal;
                item.warrantyCovered = item.partsTotal;
                break;
            case PAY_TYPE.WARRANTY_PAY_SUBLET:
                warrantyCovered += item.subletPrice;
                item.warrantyCovered = item.subletPrice;
                break;
            default:
                warrantyCovered += 0;
                break;
            }

            if (([PAY_TYPE.INTERNAL, PAY_TYPE.AFTER_SALE_REPAIR, PAY_TYPE.WE_OWE].includes(payTypeUpper)) && !record.inHouse) {
                internalCovered += NumberUtils.round(item.partsTotal + item.laborTotal + item.subletPrice);
                item.internalCovered = NumberUtils.round(item.partsTotal + item.laborTotal + item.subletPrice);
            }

            item.customerPay = payTypeUpper === PAY_TYPE.CUSTOMER_PAY ? NumberUtils.round(item.partsTotal + item.laborTotal + item.subletPrice) : 0;

            item.laborTax = NumberUtils.round((
                [PAY_TYPE.CUSTOMER_PAY, PAY_TYPE.WARRANTY_PARTS_ONLY, PAY_TYPE.WARRANTY_PAY_SUBLET].includes(payTypeUpper) && record.isTaxable)
                ? (noTaxOnRoLabor ? 0 : item.laborTotal) * surplusTaxRate : 0);

            item.customerTax = NumberUtils.round(Number(item.partsTax) + Number(item.laborTax)
            + NumberUtils.round((
                [PAY_TYPE.CUSTOMER_PAY, PAY_TYPE.WARRANTY_PARTS_LABOR, PAY_TYPE.WARRANTY_PARTS_ONLY, PAY_TYPE.WARRANTY_LABOR_ONLY].includes(payTypeUpper)
                && record.isTaxable)
                ? (Number(item.subletPrice) * Number(surplusTaxRate)) : 0));

            totalParts += Number(item.partsTotal);
            totalLabor += Number(item.laborTotal);
            totalSubletCost += Number(item.subletCost);
            totalSubletPrice += Number(item.subletPrice);
            totalLaborCost += Number(item.laborCost);
            totalDiscount += Number(item.discount);
            totalCustomerPay += Number(item.customerPay);
            totalCustomerTax += Number(item.customerTax);
        });

        const totalWarrantyCovered = NumberUtils.round(warrantyCovered);
        const totalInternalCovered = NumberUtils.round(internalCovered);
        const totalPartCost = NumberUtils.round(partCost);

        const discountAmount = NumberUtils.round(record.laborDiscount ? totalLabor * record.discountPercentage / 100 : totalDiscount);

        let shopSupplies = NumberUtils.round(record.overrideShopSuppliesFee ? record.shopSupplies : 0);
        let shopAndFees = NumberUtils.round(Number(record.fees || 0) + Number(record.cancellationFee || 0)
            + Number(record.storageFee || 0) + shopSupplies);

        if (shopSuppliesPercent > 0 && !record.overrideShopSuppliesFee) {
            const shopSuppliesAmt = (
                (['all', 'parts'].includes(shopSuppliesType.toLowerCase()) ? totalParts : 0)
                + (['all', 'labor'].includes(shopSuppliesType.toLowerCase()) ? totalLabor : 0)
            ) * (shopSuppliesPercent / 100);

            shopSupplies = NumberUtils.round((shopSuppliesAmt > shopSuppliesLimit && shopSuppliesLimit > 0)
                ? shopSuppliesLimit : shopSuppliesAmt);

            shopAndFees = NumberUtils.round(Number(record.fees || 0) + Number(record.cancellationFee || 0)
                + Number(record.storageFee || 0) + Number(shopSupplies));
        }

        totalCustomerPay += NumberUtils.round(record.warrantyDeductable + shopAndFees);
        if (record.isTaxable) {
            totalCustomerTax += NumberUtils.round((shopAndFees) * surplusTaxRate)
            + (chargeSalesTaxOnWarrantyDeductable ? (Number(record.warrantyDeductable) * Number(surplusTaxRate)) : 0);
        } else totalCustomerTax = 0;

        const totalInvoice = NumberUtils.round(totalParts + totalLabor + totalSubletPrice + shopAndFees + totalCustomerTax + record.warrantyDeductable
            - totalWarrantyCovered - totalInternalCovered - discountAmount);

        record.shopSupplies = shopSupplies;
        record.warranty = totalWarrantyCovered;
        record.discount = discountAmount;
        record.totalLabor = totalLabor;
        record.totalParts = totalParts;
        record.partsProfit = totalParts - totalPartCost;
        record.laborProfit = totalLabor - totalLaborCost;
        record.total = totalInvoice;
        record.tax = NumberUtils.round(totalCustomerTax);
        record.totalSubletCost = totalSubletCost;
        record.totalSubletPrice = totalSubletPrice;
        record.customerPay = totalCustomerPay;
        record.surplusSalesTax = surplusSalesTax;

        return record;
    }
}

import GraphQLClient from 'services/apollo/GraphQLClient';
import VehicleQuery from 'services/graphQL/query/VehicleQuery';

export default class VehicleService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Decode a Vin and returns the vehicle data
     * @param {string} vin
     * @returns {Promise<Object>}
     */
    async decodeVIN(vin) {
        return this.graphqlClient.query(VehicleQuery.DECODE_VIN, { vin });
    }
}

/* eslint no-param-reassign: "error" */
import React from 'react';
import placeholderLogo from 'assets/settings/placeholderLogo.png';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';

const CompanyImage = (record) => {
    const {
        image,
        width,
        height,
        ...props
    } = record;
    const src = !StringUtils.isEmpty(image) ? image : placeholderLogo;

    return (
        <img
            alt=""
            src={src}
            width={width}
            {...props}
        />
    );
};

CompanyImage.propTypes = {
    image: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

CompanyImage.defaultProps = {
    image: null,
    height: '100%',
    width: '100%',
};

export default CompanyImage;

import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import payrollSettingsQuery from 'services/graphQL/query/payroll/Settings';

const usePayrollDBList = () => {
    const [payrollDBList, setPayrollDBList] = useState([]);
    const { data, loading, error } = useQuery(payrollSettingsQuery.GET_PAYROLL_DB_DESCRIPTION_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getPayrollDBList)) {
            const { getPayrollDBList } = data;
            const result = getPayrollDBList.map((item) => ({ value: item.bdId, label: item.description }));
            setPayrollDBList([...result]);
        }
    }, [data, loading, error]);

    return {
        payrollDBList,
    };
};

export default usePayrollDBList;

const WINDOW_STICKERS_CATEGORY = {
    WINDOW_LABEL: 'WINDOWLABEL',
    OTHER: 'OTHER',
};

const WARRANTY_VALUES = {
    AS_IS_FULL: 'As-Is No Warranty',
    AS_IS_NO: 'noWarranty',
    IS: 'warranty',
};

const FORM_FIELD_NAMES = {
    IS_FULL: 'isFull',
    IS_LIMITED: 'isLimited',
    IS_WARRANTY: 'isWarranty',
    IS_LABOR_RATE: 'laborRate',
    IS_PARTS_RATE: 'partsRate',
};

export {
    WINDOW_STICKERS_CATEGORY,
    WARRANTY_VALUES,
    FORM_FIELD_NAMES,
};

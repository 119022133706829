import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    FormLabel,
    makeStyles,
    Checkbox,
    TextField,
    Button,
    fade,
} from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import { Form, Row } from 'react-bootstrap';
import { ReconAssigneeType } from 'utils/enum/InventoryEnum';
import DateUtils from 'lib/DateUtils';
import ButtonStyles from 'styles/theme/Button';
import InputNumber from 'components/widgets/InputNumber';
import If from 'components/widgets/conditional/If';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    editPanel: {
        padding: '5px 5px 0',
        border: `1px solid ${theme.palette.border.mercuryLight}`,
        boxShadow: `1px 2px 5px 0px ${theme.palette.background.silver}`,
        margin: '0 10px 15px 10px',
    },
    itemNameContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
        backgroundColor: theme.palette.cancel.main,
        '& > label': {
            color: `rgb(${theme.palette.rgb.black})`,
        },
    },
    title: {
        color: theme.palette.text.white,
        fontSize: 16,
        fontWeight: 'bold',
    },
    element: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        '& > div:nth-child(2)': {
            width: '60%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > div.MuiFormControl-root': {
                width: '100%',
            },
        },
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        marginLeft: '0px',
        '& > *:last-child': {
            flex: 1,
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    redUploadButton: {
        backgroundColor: `${theme.palette.background.red} !important`,
    },
    buttonDisabled: {
        opacity: 0.8,
    },
    normalText: {
        fontSize: '12px !important',
    },
    actionButton: {
        fontSize: '12px',
        borderRadius: '0px',
        minWidth: '14px',
        color: 'white !important',
        '& > span:nth-child(1) > span': {
            marginRight: '0px',
        },
    },
    actions: {
        display: 'flex',
        width: '100%',
        marginTop: '10px',
        '& > button': {
            width: '100%',
            height: '35px',
        },
        '& > div': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '& > button': {
                width: '50%',
                height: '35px',
            },
        },
    },
    shortDesign: {
        display: 'flex',
        height: '40px',
        marginTop: '-5px',
        fontSize: '17px',
        backgroundColor: theme.palette.background.white,
        '& > div:nth-child(1)': {
            width: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.white,
            backgroundColor: theme.palette.secondaryInfo.main,
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '10px',
            overflow: 'hidden',
        },
    },
}));

const RepairItemPanel = ({
    data,
    canWrite,
    onChange,
    togglePicturesForm,
    isApproved,
    loadingAssignees,
    maximumVendorItemsShown,
    assigneeList,
    getAsyncOptionsFiltered,
    saveComment,
    startRepairItem,
    completeRepairItem,
    cancelRepairItem,
    isReorderModeOn,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const {
        vehicleRepairItemId,
        order,
        partStatus,
        assigneeId,
        vendorId,
        startOn,
        completeOn,
        inspectionItem: {
            vehicleInspectionItemId,
            part,
            estimation,
            comment,
            images,
            reconItem: {
                name: itemName,
            },
        },
    } = data;

    const selectedAssignee = assigneeList
        .find((option) => {
            const assignee = assigneeId
                            && option.value.id === assigneeId
                            && option.value.type === ReconAssigneeType.INDIVIDUAL;

            const vendor = vendorId
                            && option.value.id === vendorId
                            && option.value.type === ReconAssigneeType.VENDOR;

            return assignee || vendor;
        });

    return (
        <div className={clsx(classes.root, classes.editPanel)}>
            <If condition={isReorderModeOn}>
                <div className={classes.shortDesign}>
                    <div>{order}</div>
                    <div>{itemName}</div>
                </div>
            </If>
            <If condition={!isReorderModeOn}>
                <div className={classes.itemNameContainer}>
                    <FormLabel className={classes.title}>
                        { itemName }
                    </FormLabel>
                    <FormLabel className={classes.title}>
                        { order }
                    </FormLabel>
                </div>
                <div className={classes.element}>
                    <div>Assignee</div>
                    <div>
                        <Form.Group as={Row} className={classes.group}>
                            <AsyncSelect
                                isDisabled={!isApproved || startOn}
                                className={clsx('select-bootstrap select-sm')}
                                isLoading={loadingAssignees}
                                value={selectedAssignee}
                                defaultOptions={assigneeList.slice(0, maximumVendorItemsShown)}
                                loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                                onChange={(option) => onChange(vehicleRepairItemId, 'assignee', option.value)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={classes.element}>
                    <div>Part</div>
                    <div>
                        <Checkbox
                            checked={part}
                        />
                    </div>
                </div>
                <div className={classes.element}>
                    <div>Part Status</div>
                    <div>
                        {partStatus}
                    </div>
                </div>
                <div className={classes.element}>
                    <div>Comments</div>
                    <If condition={canWrite}>
                        <div>
                            <TextField
                                disabled={!isApproved}
                                className={classes.textFieldSmall}
                                value={comment || ''}
                                name="comment"
                                onChange={({ target: { name, value } }) => onChange(vehicleRepairItemId, name, value)}
                                onBlur={() => saveComment(vehicleRepairItemId)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    </If>
                </div>
                <div className={classes.element}>
                    <div>Estimate</div>
                    <div>
                        <InputNumber
                            disabled
                            value={estimation}
                            placeholder="$0"
                            showCurrency
                            min={0}
                            decimalScale={0}
                            thousandSeparator
                            size="sm"
                        />
                    </div>
                </div>
                <div className={classes.element}>
                    <div>Pictures</div>
                    <If condition={canWrite}>
                        <div>
                            <Button
                                disabled={!isApproved}
                                size="small"
                                className={clsx(
                                    classes.containedSecondaryInfo,
                                    classes.uploadButton,
                                    (images || 0) > 0 ? classes.greenUploadButton : '',
                                    !isApproved ? classes.buttonDisabled : '',
                                )}
                                onClick={() => togglePicturesForm({ current: { vehicleInspectionItemId } })}
                            >
                                {`View (${images || 0})`}
                            </Button>
                        </div>
                    </If>
                </div>
                <div className={classes.element}>
                    <div>StartOn</div>
                    <div>
                        <span className={classes.normalText}>{DateUtils.getFormattedDateInUserTimezone(startOn)}</span>
                    </div>
                </div>
                <div className={classes.element}>
                    <div>CompleteOn</div>
                    <div>
                        <span className={classes.normalText}>{DateUtils.getFormattedDateInUserTimezone(completeOn)}</span>
                    </div>
                </div>
                <div className={classes.actions}>
                    {!startOn && (
                        <Button
                            disabled={!isApproved}
                            size="small"
                            className={clsx(
                                classes.containedSecondaryInfo,
                                classes.actionButton,
                                classes.greenUploadButton,
                                !isApproved ? classes.buttonDisabled : '',
                            )}
                            onClick={() => startRepairItem(data)}
                        >
                            Start
                        </Button>
                    )}
                    {startOn && !completeOn && (
                        <div>
                            <Button
                                disabled={!isApproved || completeOn}
                                size="small"
                                className={clsx(
                                    classes.containedSecondaryInfo,
                                    classes.actionButton,
                                    classes.greenUploadButton,
                                    !isApproved ? classes.buttonDisabled : '',
                                )}
                                onClick={() => completeRepairItem(data)}
                            >
                                Complete
                            </Button>
                            <Button
                                disabled={!isApproved || completeOn}
                                size="small"
                                className={clsx(
                                    classes.containedSecondaryInfo,
                                    classes.actionButton,
                                    classes.redUploadButton,
                                    !isApproved ? classes.buttonDisabled : '',
                                )}
                                onClick={() => cancelRepairItem(data)}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>
            </If>
        </div>
    );
};

RepairItemPanel.propTypes = {
    data: PropTypes.object.isRequired,
    canWrite: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    togglePicturesForm: PropTypes.func.isRequired,
    isApproved: PropTypes.bool.isRequired,
    loadingAssignees: PropTypes.bool.isRequired,
    maximumVendorItemsShown: PropTypes.number.isRequired,
    assigneeList: PropTypes.array,
    getAsyncOptionsFiltered: PropTypes.func.isRequired,
    saveComment: PropTypes.func.isRequired,
    startRepairItem: PropTypes.func.isRequired,
    completeRepairItem: PropTypes.func.isRequired,
    cancelRepairItem: PropTypes.func.isRequired,
    isReorderModeOn: PropTypes.bool.isRequired,
};

RepairItemPanel.defaultProps = {
    assigneeList: [],
};

export default RepairItemPanel;

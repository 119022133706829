import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import PropTypes from 'prop-types';
import ServiceListStyle from 'styles/modules/services/list/ServiceListStyle';
import ServiceInvoiceItem from 'components/modules/service/serviceInvoice/list/ServiceInvoiceItem';
import useServiceSetting from 'components/modules/service/serviceInvoice/hooks/useServiceSetting';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { useMutation } from '@apollo/client';
import { ServiceActions, ServiceInvoiceStatus } from 'utils/enum/ServiceInvoiceEnum';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';
import printJS from 'print-js';
import ModalUtils from 'utils/ModalUtils';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import ChangeStatusInvoicePopup from 'components/modules/service/serviceInvoice/list/ChangeStatusInvoicePopup';
import useAccountingGraphqlMethods from 'components/modules/accounting/journal/hooks/useAccountingGraphqlMethods';
import { JournalTypes } from 'utils/enum/AccountingEnum';

const useStyles = makeStyles((theme) => ServiceListStyle.serviceList(theme));
const ownStyle = makeStyles((theme) => (
    {
        paddingTop10: {
            paddingTop: '10px',
        },
        milesOutInput: {
            paddingTop: '5px',
            paddingLeft: 0,
        },
        inventoryLink: {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
    }
));

const ServiceInvoiceList = ({
    table, loadMore, loading,
    expandOrCollapse,
    isHistory,
    onHistory,
    onNotes,
    postToAccountingAutomatic,
}) => {
    const classes = {
        ...useStyles(),
        ...ownStyle(),
    };

    const {
        listBox,
    } = classes;
    const { records, totalCount } = table;
    const { minimumGPList } = useServiceSetting();
    const [currentState, setCurrentState] = useState({
        actionRecord: 0,
        actionType: '',
        statusSelected: '',
    });
    const [copyText, setCopyText] = useState('Click to copy');

    const [jobStateStatus, setJobStateStatus] = useState({
        jobStatus: '',
        jobNumberStatus: 0,
        invoiceNumberStatus: 0,
    });

    const { actionType, actionRecord, statusSelected } = currentState;
    const { jobStatus, jobNumberStatus, invoiceNumberStatus } = jobStateStatus;

    const {
        postingJournalAutomatic,
        postingJournal,
        postJournalToAccountingAutomatic,
    } = useAccountingGraphqlMethods({
        journalType: JournalTypes.SERVICE,
    });

    const onCloseActionDialog = () => {
        setCurrentState({
            actionRecord: 0,
            actionType: '',
            statusSelected: '',
        });
    };

    const onCloseJobActionDialog = () => {
        setJobStateStatus({
            jobStatus: '',
            jobNumberStatus: 0,
            invoiceNumberStatus: 0,
        });
    };

    const [printInvoice, { loading: printing }] = useMutation(ServiceMutation.PRINT_SERVICE_INVOICES, {
        onCompleted: (mutationData) => {
            if (mutationData?.printServiceInvoices) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printServiceInvoices,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [printInspectionList, { loading: printingInspectionList }] = useMutation(ServiceMutation.PRINT_INSPECTION_LIST, {
        onCompleted: (mutationData) => {
            if (mutationData?.printInspectionList) {
                onCloseActionDialog();
                printJS({
                    printable: mutationData?.printInspectionList,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [changeStatus, { loading: changingStatus }] = useMutation(ServiceMutation.CHANGE_STATUS_SERVICE_INVOICES, {
        onCompleted: (mutationData) => {
            if (mutationData?.changeServiceInvoiceStatus) {
                ModalUtils.successMessage(null, 'Change Status sucessfully');
                onCloseJobActionDialog();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [changeJobStatus, { loading: changingJobStatus }] = useMutation(ServiceMutation.CHANGE_JOB_SERVICE_STATUS, {
        onCompleted: (mutationData) => {
            if (mutationData?.chagenJobServiceStatus) {
                ModalUtils.successMessage(null, 'Change Job Status sucessfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onActionConfirm = async (milesOut) => {
        onCloseActionDialog();

        if (actionType === ServiceActions.CHANGE_STATUS) {
            const resultChangeStatus = await changeStatus({
                variables: {
                    id: actionRecord,
                    status: statusSelected,
                    milesOut: Number(milesOut),
                },
            });

            if (resultChangeStatus?.data?.changeServiceInvoiceStatus
                && statusSelected === ServiceInvoiceStatus.CLOSED
                && postToAccountingAutomatic) {
                postJournalToAccountingAutomatic(Number(actionRecord), JournalTypes.SERVICE);
            }
        }
    };

    const onJobActionConfirm = () => {
        setJobStateStatus({
            ...jobStateStatus,
            jobStatus: '',
        });

        changeJobStatus({
            variables: {
                status: jobStatus,
                jobNumber: jobNumberStatus,
                invoiceNumber: invoiceNumberStatus,
            },
        });
    };

    const onChangeJobStatusClick = (newStatus, jobNumber, invoiceNumber) => {
        setJobStateStatus({
            jobStatus: newStatus,
            jobNumberStatus: jobNumber,
            invoiceNumberStatus: invoiceNumber,
        });
    };

    const onActionClick = (val, action, statusSelect) => {
        if (action === ServiceActions.PRINT_CONSENT || action === ServiceActions.PRINT_INVOICE) {
            const { invoiceNumber } = val;
            printInvoice({
                variables: {
                    ids: [Number(invoiceNumber)],
                    documentType: action === ServiceActions.PRINT_CONSENT ? 'consent' : 'invoice',
                },
            });
        } else if (action === ServiceActions.PRINT_INSPECTION_LIST) {
            const { invoiceNumber, vehicleStock } = val;
            printInspectionList({
                variables: {
                    invoiceNumber: Number(invoiceNumber),
                    stockNumber: Number(vehicleStock),
                },
            });
        } else {
            const { invoiceNumber } = val;
            setCurrentState({
                actionRecord: invoiceNumber,
                actionType: action,
                statusSelected: statusSelect,
            });
        }
    };

    let dialogMessage = '';
    if (printing) dialogMessage = 'Printing...';
    if (printingInspectionList) dialogMessage = 'Printing inspection list...';
    if (changingStatus)dialogMessage = 'Changing Status...';

    const recordVerifyMiles = (ServiceActions.CHANGE_STATUS
    && statusSelected === ServiceInvoiceStatus.CLOSED && records?.find((c) => Number(c.invoiceNumber) === Number(actionRecord))) || null;

    return (
        <>
            <InfiniteScroll
                className={listBox}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={loading}
            >
                {
                    records.map((item) => (
                        <ServiceInvoiceItem
                            key={`accordion-item-${item.invoiceNumber}`}
                            invoiceItem={item}
                            classes={classes}
                            minimumGPList={minimumGPList}
                            onPrint={(val, action) => onActionClick(val, action)}
                            onChangeStatus={(invoiceNumber, val) => onActionClick({ invoiceNumber }, ServiceActions.CHANGE_STATUS, val)}
                            open={item.open}
                            onChangeJobStatus={onChangeJobStatusClick}
                            expandOrCollapse={expandOrCollapse}
                            identifier={item.identifier}
                            onHistory={onHistory}
                            isHistory={isHistory}
                            onNotes={onNotes}
                            setCopyText={setCopyText}
                            copyText={copyText}
                        />
                    ))
                }
            </InfiniteScroll>
            {actionType && actionRecord > 0 && (
                <ChangeStatusInvoicePopup
                    actionType={actionType}
                    status={statusSelected}
                    onClose={onCloseActionDialog}
                    onActionConfirm={(val) => onActionConfirm(val ?? 0)}
                    milesIn={recordVerifyMiles?.milesIn ?? 0}
                    milesOut={recordVerifyMiles?.milesOut ?? 0}
                />
            ) }
            {jobStatus !== '' && (
                <ConfirmDialog
                    title="Change Job Status"
                    description={`Are you sure you want to change the status to ${jobStatus}?`}
                    open={jobStatus !== ''}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseJobActionDialog}
                    onClickSecondary={onCloseJobActionDialog}
                    onClickPrimary={onJobActionConfirm}
                />
            ) }
            <If condition={changingJobStatus}>
                <DialogActionMessage message={`Changing job status for job #: ${jobNumberStatus} and Invoice #: ${invoiceNumberStatus}`} />
            </If>
            <If condition={postingJournalAutomatic || postingJournal}>
                <DialogActionMessage message="Posting to accounting" />
            </If>
            <If condition={printing || printingInspectionList || changingStatus}>
                <DialogActionMessage message={dialogMessage} />
            </If>
        </>
    );
};

ServiceInvoiceList.propTypes = {
    table: PropTypes.shape({
        records: PropTypes.array.isRequired,
        totalCount: PropTypes.number,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    expandOrCollapse: PropTypes.func.isRequired,
    isHistory: PropTypes.bool,
    onHistory: PropTypes.func,
    onNotes: PropTypes.func,
    postToAccountingAutomatic: PropTypes.bool,
};

ServiceInvoiceList.defaultProps = {
    isHistory: false,
    onHistory: null,
    onNotes: null,
    postToAccountingAutomatic: false,
};

export default ServiceInvoiceList;

import React from 'react';
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';
import If from 'components/widgets/conditional/If';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Feature from 'components/modules/inventory/read/panels/keyFeature/Feature';

const useStyles = makeStyles({
    featuresList: {
        display: 'grid',
        gridAutoFlow: 'dense',
        gridAutoRows: 'max-content',
        gridGap: 20,
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    },
});

const FeatureList = ({
    features,
    allowAction,
    checked,
    onChange,
    onSortEnd,
    disabled,
    allowEdit,
    onOpenKeyFeature,
}) => {
    const distanceInPixels = 5;
    const classes = useStyles();
    const getChild = (data) => (
        <Feature
            key={`feature-${data.optionNumber}`}
            data={data}
            allowAction={allowAction}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            allowEdit={allowEdit}
            onOpenKeyFeature={onOpenKeyFeature}
        />
    );

    const SortableItem = SortableElement(({ value }) => getChild(value));
    const SortableList = SortableContainer(({ items }) => (
        <div className={classes.featuresList}>
            {
                items.map((feature, index) => (
                    <SortableItem
                        key={`feature-${feature.optionNumber}`}
                        index={index}
                        value={feature}
                    />
                ))
            }
        </div>
    ));

    return (
        <>
            <If condition={checked && allowAction}>
                <SortableList
                    axis="xy"
                    distance={distanceInPixels}
                    items={features}
                    onSortEnd={onSortEnd}
                />
            </If>
            <div className={classes.featuresList}>
                <If condition={!checked || (checked && !allowAction)}>
                    {features.map((feature) => getChild(feature))}
                </If>
            </div>
        </>
    );
};

FeatureList.propTypes = {
    features: PropTypes.array,
    allowAction: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    allowEdit: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func,
    onOpenKeyFeature: PropTypes.func,
};

FeatureList.defaultProps = {
    features: [],
    allowAction: false,
    checked: true,
    disabled: false,
    allowEdit: false,
    onSortEnd: () => {},
    onOpenKeyFeature: () => {},
};

export default FeatureList;

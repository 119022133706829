/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import clsx from 'clsx';
import 'styles/Dropdown.scss';
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 15,
        fontWeight: 500,
        cursor: 'pointer',
        color: theme.palette.text.outerSpace,
        lineHeight: '20px',
        minWidth: 26,
    },
}));

const DropdownContent = ({
    children, setOpen, open, icon,
    classButton, asLabel, value,
    classText, disabled,
}) => {
    const classes = useStyles();
    return (
        <div
            className="dropdown"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {asLabel
                ? (
                    <div
                        tabIndex={0}
                        className={clsx(classText, classes.text, { disabled })}
                        onClick={() => (disabled ? null : setOpen(!open))}
                        onBlur={() => setOpen(false)}
                    >
                        {value}
                    </div>
                ) : (
                    <IconButton
                        size="small"
                        className={classButton}
                        onClick={() => setOpen(!open)}
                        onBlur={() => setOpen(false)}
                    >
                        {icon}
                    </IconButton>
                )}
            <div className={clsx('dropdown-menu', { open })}>
                {children}
            </div>
        </div>
    );
};

DropdownContent.propTypes = {
    children: PropTypes.node.isRequired,
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    icon: PropTypes.object.isRequired,
    classButton: PropTypes.string.isRequired,
    asLabel: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    classText: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

DropdownContent.defaultProps = {
    value: null,
};

export default DropdownContent;

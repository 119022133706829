import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import { ProcessType, AccountingProcessControl } from 'utils/enum/AccountingEnum';

const useAvailableControlNumbers = () => {
    const [availableControlNumbers, setAvailableControlNumbers] = useState([]);
    const [load, { loading: loadingControlNumbers }] = useLazyQuery(
        AccountingGLQuery.GET_AVAILABLE_CONTROL_NUMBERS,
        {
            onCompleted: (res) => {
                const { getAvailableControlNumbers: data } = res;
                setAvailableControlNumbers(data);
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage([errorMessage]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        },
    );

    const getTransactionType = (glLines) => {
        if (glLines.filter((item) => item.processDetailRecordId > 0 && item.processTypeId > 0)?.length > 0) {
            const batchOutProcess = [
                ProcessType.BATCH_OUT,
                ProcessType.BATCH_OUT_CASH,
                ProcessType.BATCH_OUT_CHECK,
                ProcessType.BATCH_OUT_CASHIER_CHECK,
                ProcessType.BATCH_OUT_CHARGE,
                ProcessType.BATCH_OUT_CUSTOM,
            ];

            const batchOutLines = glLines.filter((line) => batchOutProcess.includes(line.processTypeId));
            if (batchOutLines.length > 0) {
                return {
                    type: AccountingProcessControl.BATCH_OUT,
                    ids: [...new Set(batchOutLines.map((item) => String(item.processDetailRecordId)))],
                };
            }
        }

        const controlledByItems = glLines.filter((item) => item.controlledBy && item.controlledBy !== '')
            .map((item) => item.controlledBy);
        const distinctControlledBy = [...new Set(controlledByItems)];

        return {
            type: AccountingProcessControl.DEFAULT,
            ids: distinctControlledBy,
        };
    };

    const loadAvailableControlNumbers = (glLines) => {
        const { ids, type } = getTransactionType(glLines);

        load({
            variables: {
                type,
                ids,
            },
        });
    };

    return {
        loadAvailableControlNumbers,
        loadingControlNumbers,
        availableControlNumbers,
    };
};

export default useAvailableControlNumbers;

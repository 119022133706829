/* eslint-disable no-param-reassign */
import React, {
    useEffect,
    useReducer,
} from 'react';
import clsx from 'clsx';
import {
    Tooltip,
    TextField,
    makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import PayrollUtils from 'utils/PayrollUtils';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import PayrollTable from 'components/widgets/payroll/PayrollTable';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import PayrollGeneralQuery from 'services/graphQL/query/payroll/PayrollGeneral';
import PayrollGeneralMutation from 'services/graphQL/mutate/payroll/PayrollGeneral';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles((theme) => ({
    customColumnStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    header: {
        display: 'flex',
        height: 'fit-content',
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    tableHeight: {
        height: 'calc(95vh - 106px)',
    },
    boxContent: {
        overflow: 'auto',
        height: 'fit-content',
        padding: '9px',
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: 'fit-content',
        background: theme.palette.background.white,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0px',
            border: '0px',
        },
    },
    left: {
        minWidth: 'fit-content',
        marginRight: '15px',
    },
    right: {
        minWidth: '30%',
        marginLeft: '15px',
    },
    tableContainer: {
        height: 'fit-content',
    },
    listStyle: {
        border: `1px solid ${theme.palette.border.primary}`,
        marginTop: '15px',
        height: 'calc(93vh - 100px)',
        overflow: 'auto',
        padding: '5px',
    },
    listItemStyle: {
        padding: '0px 5px 0px',
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    emptyDiv: {
        width: '100%',
        height: '100%',
    },
    splitOne: {
        display: 'flex',
        overflow: 'hidden',
        flex: 1,
        height: 'calc(94vh - 106px)',

    },
    containerSplit: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    listItemRoot: {
        '&$selected': {
            backgroundColor: theme.palette.background.blueRibbon,
            '&:hover': {
                backgroundColor: theme.palette.background.blueRibbon,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.background.blueRibbon,
        },
    },
    selected: {},
}));

const getPendingFinalPaymentStyle = (_, rowInfo) => {
    if (rowInfo && rowInfo.row) {
        return {
            style: {
                background: rowInfo.row.pendingFinalPayment ? '#FFFFE0' : null,
            },
        };
    }
    return {};
};

const getFilterValues = (values, columnId) => {
    if (values?.length > 0) {
        const mappedRecords = [
            ...new Map(values
                .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                .map((item) => [item.label, item])).values(),
        ];
        const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
        return sortedResult;
    }
    return [];
};

const getQuickNoteFilters = (filters, values) => {
    if (filters) {
        const quickNoteFilters = filters.find((item) => item.id === 'quickNotes')?.value;
        if (quickNoteFilters) {
            const quickNoteValues = values.map((item) => item.quickNotes);
            const quickNoteFilterValues = quickNoteFilters.filter((item) => quickNoteValues.indexOf(item) > -1);
            if (quickNoteFilterValues.length > 0) {
                return {
                    id: 'quickNotes',
                    value: quickNoteFilterValues,
                };
            }
            return [];
        }
        return [];
    }
    return [];
};

const ACTION_TYPES = {
    APPLY_FILTER: 'applyFilter',
    SET_LIST_VALUE: 'setListValue',
    SET_QUICK_NOTES: 'setQuickNotes',
    SET_STATE_VALUES: 'setStateValues',
    SET_YEAR_SELECTED: 'setYearSelected',
    SET_RECORD_SELECTED: 'setRecordSelected',
    SET_PAY_PERIOD_SELECTED: 'setPayPeriodSelected',
    SET_QUICK_NOTES_FILTER_VALUES: 'setQuickNotesFilterValues',
};

const reducer = (state, action) => {
    const {
        id,
        type,
        year,
        value,
        values,
        accessor,
        columnName,
        recordIndex,
    } = action;
    const index = state?.items?.findIndex((item) => item.id === id);
    switch (type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...values };
    }
    case ACTION_TYPES.SET_YEAR_SELECTED:
        return update(state, {
            payPeriodYear: { $set: year },
        });
    case ACTION_TYPES.SET_PAY_PERIOD_SELECTED:
        return update(state, {
            payPeriodSelected: { $set: value },
        });
    case ACTION_TYPES.SET_RECORD_SELECTED:
        return update(state, {
            selectedTableIndex: { $set: recordIndex === -1 ? state.selectedTableIndex : recordIndex },
            selectedTableRecord: { $set: value },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    case ACTION_TYPES.SET_LIST_VALUE:
        return update(state, {
            items: {
                [index]: {
                    [columnName]: {
                        $set: value,
                    },
                },
            },
        });
    case ACTION_TYPES.SET_QUICK_NOTES:
        const newState = update(state, {
            items: {
                [index]: {
                    quickNotes: { $set: value },
                    quickNotesEnabled: { $set: false },
                },
            },
        });
        const filters = newState.filtersApplied;
        const quickNoteFilters = getQuickNoteFilters(filters, newState.items);
        const filtersWithoutQuickNote = filters.filter((item) => item.id !== 'quickNotes');
        if (quickNoteFilters.length === 0) {
            if (newState.resetFiltersCallback) newState.resetFiltersCallback([]);
            return update(newState, {
                filtersApplied: { $set: filtersWithoutQuickNote },
            });
        }
        filtersWithoutQuickNote.push(quickNoteFilters);
        if (newState.resetFiltersCallback) newState.resetFiltersCallback(quickNoteFilters.value.map((item) => ({ label: item, value: item })));

        return update(newState, {
            filtersApplied: { $set: filtersWithoutQuickNote },
        });
    case ACTION_TYPES.SET_QUICK_NOTES_FILTER_VALUES:
        action.callback(getFilterValues(state.items, 'quickNotes'));
        return state;
    default: return state;
    }
};

const PayrollBaseList = ({
    getRightSideComponent, splitSize,
    onResizingSplit, employeeType, commissionOnly,
}) => {
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };
    const keyStore = new KeyStore();

    const initState = {
        items: [],
        payPeriods: [],
        searchValue: '',
        loadTable: false,
        filtersApplied: [],
        payPeriodSelected: 0,
        selectedTableIndex: -1,
        selectedTableRecord: null,
        includeInactiveEmployees: false,
        payPeriodYear: new Date().getFullYear(),
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        includeInactiveEmployees, selectedTableIndex,
        payPeriods, payPeriodYear, payPeriodSelected,
        searchValue, items, loadTable, filtersApplied,
        selectedTableRecord,
    } = state;

    const [getPayrollEmployeeData, {
        loading, data, error,
    }] = useLazyQuery(PayrollGeneralQuery.GET_PAYROLL_EMPLOYEE_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: payPeriodsData,
        loading: loadingPayPeriods,
        error: payPeriodsErrors,
    } = useQuery(PayrollGeneralQuery.GET_PAY_PERIODS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            lotName: keyStore.getSelectedLot()?.lotName || ALL_LOTS,
            year: state.payPeriodYear,
        },
    });

    const [updateQuickNoteMutate] = useMutation(PayrollGeneralMutation.UPDATE_USER_QUICK_NOTE, {
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    useEffect(() => {
        if (payPeriodsErrors) {
            ModalUtils.errorMessage(payPeriodsErrors?.graphQLErrors);
            return;
        }

        if (!loadingPayPeriods) {
            const { getPayPeriods } = payPeriodsData;
            const currentPayPeriodIndex = getPayPeriods.findIndex((item) => item.includes('(Current)'));
            const selectedPayPeriodIndex = currentPayPeriodIndex >= 1 ? currentPayPeriodIndex - 1 : 0;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    payPeriods: getPayPeriods,
                    payPeriodSelected: selectedPayPeriodIndex,
                },
            });
        }
    }, [payPeriodsData, loadingPayPeriods, payPeriodsErrors]);

    useEffect(() => {
        getPayrollEmployeeData({
            variables: {
                input: {
                    employeeType,
                    commissionOnly,
                    search: searchValue,
                    includeInactiveEmployees,
                },
            },
        });
    }, [searchValue, includeInactiveEmployees, getPayrollEmployeeData, employeeType, commissionOnly]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getPayrollEmployeesList } = data;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadTable: true,
                    items: PayrollUtils.getEmployeeListMappedData(getPayrollEmployeesList),
                    filtersApplied: [],
                    selectedTableIndex: -1,
                    selectedTableRecord: null,
                },
            });
        }
    }, [data, loading, error]);

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const onSearch = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                loadTable: false,
                searchValue: value,
            },
        });
    };

    const onChangeYear = (_, value) => {
        dispatch({
            type: ACTION_TYPES.SET_YEAR_SELECTED,
            year: value,
        });
    };

    const onChangePayPeriod = (index) => {
        dispatch({
            type: ACTION_TYPES.SET_PAY_PERIOD_SELECTED,
            value: index,
        });
    };

    const onChangeIncludeInactiveEmployees = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                loadTable: false,
                includeInactiveEmployees: value,
            },
        });
    };

    const onChangeQuickNote = async (event, id, previousValue) => {
        const { value } = event.target;
        if ((value || '') !== (previousValue || '')) {
            const response = await updateQuickNoteMutate({
                variables: {
                    userId: id,
                    quickNote: value || '',
                },
            });
            if (response?.data?.updateUserQuickNote) {
                dispatch({
                    id,
                    value,
                    type: ACTION_TYPES.SET_QUICK_NOTES,
                });
            } else {
                dispatch({
                    id,
                    value: false,
                    columnName: 'quickNotesEnabled',
                    type: ACTION_TYPES.SET_LIST_VALUE,
                });
            }
        } else {
            dispatch({
                id,
                value: false,
                columnName: 'quickNotesEnabled',
                type: ACTION_TYPES.SET_LIST_VALUE,
            });
        }
    };

    const onKeyDownQuickNote = (event, id, previousValue) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            onChangeQuickNote(event, id, previousValue);
        }
    };

    const onEnableQuickNote = (enableQuickNote, id) => {
        if (enableQuickNote) {
            dispatch({
                id,
                value: true,
                columnName: 'quickNotesEnabled',
                type: ACTION_TYPES.SET_LIST_VALUE,
            });
        }
    };

    const onOpenFilterCallback = (callback) => {
        dispatch({
            type: ACTION_TYPES.SET_QUICK_NOTES_FILTER_VALUES,
            callback,
        });
    };

    const onResetFiltersCallBack = (callback) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                resetFiltersCallback: callback,
            },
        });
    };

    const getColumns = () => [{
        id: 'id',
        minWidth: 1,
        Header: 'ID',
        accessor: 'id',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
    }, {
        minWidth: 3,
        id: 'employeeName',
        Header: 'Employee Name',
        accessor: 'employeeName',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
    }, {
        minWidth: 3,
        id: 'quickNotes',
        Header: 'Quick Notes',
        accessor: 'quickNotes',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
        Cell: (cell) => {
            const { original: { quickNotesEnabled, id }, value } = cell;
            const USER_QUICK_NOTE_WRITE = keyStore.hasPermission(Permission.USER_QUICK_NOTE_WRITE);
            const div = (
                <div
                    className={clsx(classes.emptyDiv, classes.customColumnStyle)}
                    onDoubleClick={() => onEnableQuickNote(USER_QUICK_NOTE_WRITE, id)}
                >
                    {value}
                </div>
            );

            if (USER_QUICK_NOTE_WRITE && quickNotesEnabled) {
                return (
                    <TextField
                        autoFocus
                        size="small"
                        variant="outlined"
                        defaultValue={value || ''}
                        onBlur={(event) => onChangeQuickNote(event, id, value)}
                        onKeyDown={(event) => onKeyDownQuickNote(event, id, value)}
                    />
                );
            }
            if (value) {
                return (
                    <Tooltip placement="bottom" title={value}>
                        {div}
                    </Tooltip>
                );
            }
            return div;
        },
    }, {
        minWidth: 2,
        id: 'lotName',
        Header: 'Lot',
        accessor: 'lotName',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
    }, {
        minWidth: 2,
        id: 'department',
        Header: 'Department',
        accessor: 'department',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
    }, {
        minWidth: 2,
        id: 'employeeType',
        Header: 'Employee Type',
        accessor: 'employeeType',
        className: classes.customColumnStyle,
        getProps: getPendingFinalPaymentStyle,
    }, {
        show: false,
        id: 'pendingFinalPayment',
        accessor: 'pendingFinalPayment',
    }];

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            if (column.id !== 'checkbox-column') {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                filterId={column.id}
                                onOpenCallback={onOpenFilterCallback}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                onResetFiltersCallback={onResetFiltersCallBack}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    const getCustomHeader = () => (
        <Form.Check
            inline
            type="checkbox"
            onChange={(e) => onChangeIncludeInactiveEmployees(e.target.checked)}
            label="Include inactive employees"
            checked={includeInactiveEmployees}
        />
    );

    const onTableClick = (record, index = -1) => {
        dispatch({
            value: record,
            recordIndex: index,
            type: ACTION_TYPES.SET_RECORD_SELECTED,
        });
    };

    const loanBalance = selectedTableRecord?.loanBalance;

    const localGetRightSideComponent = () => getRightSideComponent({
        loanBalance: loanBalance || 0,
        payPeriodYear: payPeriodYear || 0,
        employeeId: selectedTableRecord?.id || 0,
        salary: selectedTableRecord?.salary || 0,
        payType: selectedTableRecord?.payType || '',
        employeeType: selectedTableRecord?.employeeType || '',
        pendingFinalPayment: selectedTableRecord?.pendingFinalPayment || false,
        payPeriod: payPeriods[payPeriodSelected]?.replace(' (Current)', '') || '',
    });

    return (
        <div className={classes.content}>
            <PayrollTable
                records={items}
                classes={classes}
                onSearch={onSearch}
                loadTable={loadTable}
                splitSize={splitSize}
                payPeriods={payPeriods}
                counterLabel="Employees"
                loadColumns={loadColumns}
                searchValue={searchValue}
                onChangeYear={onChangeYear}
                onTableClick={onTableClick}
                payPeriodYear={payPeriodYear}
                filtersApplied={filtersApplied}
                getCustomHeader={getCustomHeader}
                onResizingSplit={onResizingSplit}
                payPeriodSelected={payPeriodSelected}
                onChangePayPeriod={onChangePayPeriod}
                selectedTableIndex={selectedTableIndex}
                getRightSideComponent={localGetRightSideComponent}
            />
        </div>
    );
};

PayrollBaseList.propTypes = {
    commissionOnly: PropTypes.bool,
    employeeType: PropTypes.string,
    splitSize: PropTypes.array.isRequired,
    onResizingSplit: PropTypes.func.isRequired,
    getRightSideComponent: PropTypes.func.isRequired,
};

PayrollBaseList.defaultProps = {
    employeeType: '',
    commissionOnly: false,
};

export default PayrollBaseList;

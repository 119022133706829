import { gql } from '@apollo/client';

export default class AccountingSubscription {
    static GENERAL_JOURNAL_CHANGED = gql`
    subscription generalJournalChanged {
        generalJournalChanged {
            id
            data
            action
            companyCode
        }
    }`;

static CHECK_OR_DEPOSIT_CHANGED = gql`
subscription checkOrDepositChanged {
  checkOrDepositChanged {
    id
    data
    action
    companyCode
  }
}
`;

static INVOICE_CHANGED = gql`
    subscription invoiceChanged {
      invoiceChanged {
        id
        data
        action
        companyCode
      }
    }
`;

static CASHIER_PAYMENT_CHANGED = gql`
    subscription cashierPaymentChanged {
      cashierPaymentChanged {
        id
        data
        action
        companyCode
      }
    }
`;

static ACCOUNTS_PAYABLE_VENDOR_BALANCE = gql`
    subscription vendorListBalanceChanged {
      vendorListBalanceChanged {
        id
        data
        action
        companyCode
      }
    }
`;

static POSTED_JOURNAL_PROCESS = gql`
    subscription postedJournalProcess {
      postedJournalProcess {
        id
        data
        action
        companyCode
      }
    }
`;
}

import TimeZoneUtil from 'lib/TimeZoneUtil';

class LotSettingsMap {
    static mapLotSettingToUpdate(record) {
        return {
            active: record.active,
            activeSince: record.activeSince,
            bhphInHouse: record.bhphInHouse,
            dbaName: record.dbaName,
            dealerFee: record.dealerFee,
            dealerFeeCapitalized: record.dealerFeeCapitalized,
            dealerFeeEditable: record.dealerFeeEditable,
            dealerFeeTaxable: record.dealerFeeTaxable,
            displayAcquisitionFee: record.displayAcquisitionFee,
            displayDealerFee: record.displayDealerFee,
            displayInspectionFee: record.displayInspectionFee,
            displayRegistrationFee: record.displayRegistrationFee,
            displayTagAgencyFee: record.displayTagAgencyFee,
            displayTagFee: record.displayTagFee,
            displayTitleFee: record.displayTitleFee,
            displayUserField1: record.displayUserField1,
            displayUserField2: record.displayUserField2,
            displayUserField3: record.displayUserField3,
            displayUserField4: record.displayUserField4,
            displayUserField5: record.displayUserField5,
            displayUserField6: record.displayUserField6,
            displayUserField7: record.displayUserField7,
            dispositionFee: record.dispositionFee,
            earlyTerminationFee: record.earlyTerminationFee,
            gapCapitalized: record.gapCapitalized,
            inspectionFee: record.inspectionFee,
            inspectionFeeCapitalized: record.inspectionFeeCapitalized,
            inspectionFeeTaxable: record.inspectionFeeTaxable,
            inventoryTax: record.inventoryTax,
            lotAddress: record.lotAddress,
            lotCity: record.lotCity,
            lotContactPerson: record.lotContactPerson,
            lotDln: record.lotDln,
            lotFax: record.lotFax,
            lotMvNumber: record.lotMvNumber,
            lotMvr: record.lotMvr,
            lotName: record.lotName,
            lotPhone: record.lotPhone,
            lotState: record.lotState,
            lotStateRegistration: record.lotStateRegistration,
            lotTaxReg: record.lotTaxReg,
            lotTimeZone: TimeZoneUtil.getTimeZoneOffSet(record.lotTimeZoneId),
            lotTimeZoneId: record.lotTimeZoneId,
            lotType: record.lotType,
            lotZip: record.lotZip,
            mbi: record.mbi,
            mbiCapitalized: record.mbiCapitalized,
            mbiTaxable: record.mbiTaxable,
            noTaxOnRoLabor: record.noTaxOnRoLabor,
            overridePassword: record.overridePassword,
            packCost: record.packCost,
            pictureUrl: record.pictureUrl,
            productsTaxable: record.productsTaxable,
            purchaseOptionFee: record.purchaseOptionFee,
            registrationFee: record.registrationFee,
            registrationFeeCapitalized: record.registrationFeeCapitalized,
            registrationFeeTaxable: record.registrationFeeTaxable,
            serviceContractCapitalized: record.serviceContractCapitalized,
            tagAgencyFee: record.tagAgencyFee,
            tagAgencyFeeCapitalized: record.tagAgencyFeeCapitalized,
            tagAgencyFeeTaxable: record.tagAgencyFeeTaxable,
            tagFee: record.tagFee,
            tagFeeCapitalized: record.tagFeeCapitalized,
            tagFeeTaxable: record.tagFeeTaxable,
            titleFee: record.titleFee,
            titleFeeCapitalized: record.titleFeeCapitalized,
            titleFeeTaxable: record.titleFeeTaxable,
            userField1: record.userField1,
            userField1Capitalized: record.userField1Capitalized,
            userField1Name: record.userField1Name,
            userField1Taxable: record.userField1Taxable,
            userField2: record.userField2,
            userField2Capitalized: record.userField2Capitalized,
            userField2Name: record.userField2Name,
            userField2Taxable: record.userField2Taxable,
            userField3: record.userField3,
            userField3Capitalized: record.userField3Capitalized,
            userField3Name: record.userField3Name,
            userField3Taxable: record.userField3Taxable,
            userField4: record.userField4,
            userField4Capitalized: record.userField4Capitalized,
            userField4Name: record.userField4Name,
            userField4Taxable: record.userField4Taxable,
            userField5: record.userField5,
            userField5Capitalized: record.userField5Capitalized,
            userField5Name: record.userField5Name,
            userField5Taxable: record.userField5Taxable,
            userField6: record.userField6,
            userField6Capitalized: record.userField6Capitalized,
            userField6Name: record.userField6Name,
            userField6Taxable: record.userField6Taxable,
            userField7: record.userField7,
            userField7Capitalized: record.userField7Capitalized,
            userField7Name: record.userField7Name,
            userField7Taxable: record.userField7Taxable,
        };
    }
}

export default LotSettingsMap;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Row } from 'react-bootstrap';
import If from 'components/widgets/conditional/If';
import { FetchPolicy } from 'utils/enum/Core';
import {
    makeStyles, Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import CreatePNLSchema from 'utils/schema/accounting/banking/CreatePNLSchema';
import DropdownQuery from 'components/widgets/DropdownQuery';
import Select from 'components/widgets/Select';
import ModalUtils from 'utils/ModalUtils';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const useStyles = makeStyles(() => ({
    alignContentCenter: {
        verticalAlign: 'sub',
    },
    zindex10000: {
        zIndex: '10000 !important',
    },
}));

const PNLForm = (props) => {
    const classes = useStyles();

    const { onClose, showAsPopup } = props;

    const [record, setRecord] = useState(
        {
            yearToClose: new Date().getFullYear(),
            accountNumber: null,
            memo: '',
            fiscalYearData: [],
        },
    );

    const isValidData = isValidSchema(CreatePNLSchema, record);
    const { isValid, errors } = isValidData;

    const [generatePNL, { loading: generatingPNL }] = useMutation(AccountingGLQuery.GENERATE_PNL, {
        onCompleted: (res) => {
            if (res.generatePNL) {
                ModalUtils.successMessage(null, 'P&L generated successfully.');

                onClose();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [getFiscalYear] = useLazyQuery(AccountingGLQuery.GET_FISCAL_YEAR_LIST, {
        onCompleted: (res) => {
            if (res.getFiscalYearList) {
                const mapData = res.getFiscalYearList.map((item) => ({
                    label: item,
                    value: Number(item),
                }));

                setRecord({ ...record, fiscalYearData: mapData });
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onChangePNL = (field, value) => {
        setRecord({ ...record, [field]: value });
    };

    const onConfirmGeneratePNL = () => {
        generatePNL({
            variables: {
                record: {
                    yearToClose: record.yearToClose,
                    accountNumber: record.accountNumber,
                    memo: record.memo,
                },
            },
        });
    };

    useEffect(() => {
        getFiscalYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mainForm = (
        <Grid item xs={12}>
            <Form.Group as={Row}>
                <Form.Label column="sm" xs={4} className="required">Year To Close</Form.Label>
                <Col xs={8}>
                    <Select
                        name="yearToClose"
                        value={record.yearToClose}
                        options={record.fiscalYearData}
                        size="sm"
                        onChange={(name, newValue) => onChangePNL(name, newValue)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column="sm" xs={4} className="required">Account Number</Form.Label>
                <Col xs={8}>
                    <DropdownQuery
                        name="accountNumber"
                        value={record.accountNumber}
                        placeholder="Account Number"
                        className={clsx('form-control-xs', isValidField(errors, 'accountNumber') ? 'invalid-field' : '')}
                        onChange={(name, newValue) => onChangePNL('accountNumber', newValue)}
                        dataSource={{
                            query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                            variables: {
                                paginate: {
                                    init: 0,
                                    ignoreLimit: true,
                                },
                                filters: {
                                    accountTypes: [AccountingCOAType.EQUITY],
                                },
                            },
                            rootData: 'getAccountingCOAList.data',
                            idField: 'accountNumber',
                            descriptionField: 'fullDescription',
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column="sm" xs={4} className="required">Reference Number</Form.Label>
                <Col xs={8}>
                    <If condition={record.yearToClose !== null}>
                        <Form.Label className={classes.alignContentCenter}>{`DPRYE${record.yearToClose}`}</Form.Label>
                    </If>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column="sm" xs={4} className="required">Post Date</Form.Label>
                <Col xs={8}>
                    <If condition={record.yearToClose !== null}>
                        <Form.Label className={classes.alignContentCenter}>{`${Number(record.yearToClose) + 1}/01/01`}</Form.Label>
                    </If>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column="sm" xs={4} className="required">Memo</Form.Label>
                <Col xs={8}>
                    <Form.Control
                        as="textarea"
                        className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                        size="sm"
                        rows="3"
                        value={record.memo || ''}
                        placeholder="Memo"
                        onChange={(e) => onChangePNL('memo', e.target.value)}
                    />
                </Col>
            </Form.Group>
        </Grid>
    );

    if (showAsPopup) {
        return (
            <>
                <ConfirmDialog
                    title="Generate P&L"
                    open
                    variant="outlined"
                    titlePrimary="Generate"
                    titleSecondary="Cancel"
                    onClose={onClose}
                    onClickSecondary={onClose}
                    onClickPrimary={onConfirmGeneratePNL}
                    disablePrimaryButton={!isValid || generatingPNL}
                >
                    {mainForm}
                </ConfirmDialog>
                {generatingPNL && <DialogActionMessage className={classes.zindex10000} message="Generating P&L... " />}
            </>
        );
    }

    return mainForm;
};

PNLForm.propTypes = {
    onClose: PropTypes.func,
    showAsPopup: PropTypes.bool,
};

PNLForm.defaultProps = {
    onClose: () => null,
    showAsPopup: false,
};

export default PNLForm;

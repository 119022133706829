import { gql } from '@apollo/client';

export default class GpsMutation {
    static UPDATE_DEALER_GPS_INTEGRATION = gql`
        mutation updateDealerGpsIntegration(
            $input: GpsIntegrationData!
        ) {
            updateDealerGpsIntegration(
                input: $input
            )
        }
    `;

    static UPDATE_VEHICLE_GPS = gql`
        mutation updateVehicleGps(
            $stockNumber: Int!,
            $integrationId: String,
            $deviceId: String,
            $deviceType: String
        ) {
            updateVehicleGps(
                stockNumber: $stockNumber,
                integrationId: $integrationId,
                deviceId: $deviceId,
                deviceType: $deviceType
            )
        }
    `;

    static SEND_COMMAND_TO_GPS_DEVICE = gql`
        mutation sendCommandToGPSDevice(
            $input: sendCommand!
        ) {
            sendCommandToGPSDevice(
                input: $input
            ) {
                success
                body
            }
        }
    `;

    static UPDATE_GPS_ACTIVE_TONE = gql`
        mutation updateGPSActiveTone(
            $stockNumber: Int,
            $gpsTone: String
        ) {
            updateGPSActiveTone(
                stockNumber: $stockNumber,
                gpsTone: $gpsTone
            )
        }
    `;
}

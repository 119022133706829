import React from 'react';

// Material UI
import {
    Dialog, makeStyles,
} from '@material-ui/core';

// Components and Others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { ActivityType } from 'utils/enum/ActivitiesEnum';
import CallForm from 'components/modules/crm/activities/form/CallForm';
import TaskForm from 'components/modules/crm/activities/form/TaskForm';
import EventForm from 'components/modules/crm/activities/form/EventForm';
import EventEditForm from 'components/modules/crm/activities/form/EventEditForm';

const useStyles = makeStyles(() => ({
    dialog: {
        maxWidth: ({ width }) => width,
    },
}));

const ActivitiesForm = ({
    open = false, onClose, type, isEditing,
    activityId, crmId, lotId,
}) => {
    const width = isEditing && ![ActivityType.CALL, ActivityType.TASK].includes(StringUtils.toUpperCase(type)) ? 750 : 350;
    const classes = useStyles({ width });
    const renderForm = () => {
        const currentType = StringUtils.toUpperCase(type);

        switch (currentType) {
        case ActivityType.CALL:
            return (
                <CallForm
                    lotId={lotId}
                    crmId={crmId}
                    onClose={onClose}
                    isEditing={isEditing}
                    activityId={activityId}
                />
            );
        case ActivityType.TASK:
            return (
                <TaskForm
                    lotId={lotId}
                    crmId={crmId}
                    onClose={onClose}
                    isEditing={isEditing}
                    activityId={activityId}
                />
            );
        // TODO: Check this later to handle only one type
        case ActivityType.APPOINTMENT:
        case ActivityType.EVENT:

            if (isEditing) {
                return (
                    <EventEditForm
                        crmId={crmId}
                        onClose={onClose}
                        activityId={activityId}
                    />
                );
            }

            return (
                <EventForm
                    lotId={lotId}
                    crmId={crmId}
                    onClose={onClose}
                    activityId={activityId}
                />
            );
        default:
            return null;
        }
    };

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="xs"
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            scroll="paper"
            PaperProps={{
                className: classes.dialog,
            }}
        >
            {renderForm()}
        </Dialog>
    );
};

ActivitiesForm.propTypes = {
    isEditing: PropTypes.bool,
    activityId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
    lotId: PropTypes.number,
};

ActivitiesForm.defaultProps = {
    isEditing: false,
    activityId: null,
    lotId: null,
};

export default ActivitiesForm;

import {
    useReducer, useEffect, useCallback,
} from 'react';
import { isEqual } from 'lodash';
import update from 'immutability-helper';
import usePrevious from 'components/hook/core/usePrevious';

const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_FIELD: 'setField',
    SET_CHECKBOX: 'setCheckbox',
};

function reducer(state, action) {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPES.SET_FIELD:
        return update(state, {
            [payload.indexForm]: {
                data: {
                    [payload.indexCustomField]: {
                        [payload.field]: { $set: payload.value },
                        record: { $set: payload.record },
                    },
                },
            },
        });
    case ACTION_TYPES.SET_CHECKBOX:
        return update(state, {
            [payload.indexForm]: {
                data: {
                    [payload.indexCustomField]: {
                        values: {
                            [payload.index]: {
                                [payload.field]: { $set: payload.value },
                            },
                        },
                    },
                },
            },
        });
    case ACTION_TYPES.SET_RECORD:
        return payload;
    default:
        return [];
    }
}

const useCustomField = (records) => {
    const [customField, dispatch] = useReducer(reducer, []);
    const prev = usePrevious(records);

    useEffect(() => {
        if (!isEqual(prev, records)) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                payload: records,
            });
        }
    }, [records, prev]);

    const onChange = useCallback(({
        field, value, indexForm, indexCustomField,
        record = {},
    }) => dispatch({
        type: ACTION_TYPES.SET_FIELD,
        payload: {
            field,
            value,
            record,
            indexForm,
            indexCustomField,
        },
    }), [dispatch]);

    const onChangeCheckbox = useCallback(({
        field, index, value, indexForm, indexCustomField,
    }) => dispatch({
        type: ACTION_TYPES.SET_CHECKBOX,
        payload: {
            value,
            index,
            field,
            indexForm,
            indexCustomField,
        },
    }), [dispatch]);

    return {
        onChange,
        customField,
        onChangeCheckbox,
    };
};

export default useCustomField;

import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import ModalUtils from 'utils/ModalUtils';
import DealService from 'services/modules/DealService';

const InsuranceTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            openDialog: false,
            loading: false,
            insurance: {
                insuranceCompany: '',
                insuranceAgent: '',
                insuranceAddress: '',
                insuranceCity: '',
                insuranceState: '',
                insuranceZip: '',
                insurancePhone: '',
                insurancePolicy: '',
                insuranceDate: '',
                insuranceDateFrom: '',
                insuranceComp: 0,
                insuranceColl: 0,
            },
        };

        this.initBind();
    }

    getServicesData() {
        const { props: { accountNumber } } = this;
        this.setState({ loading: true });

        this.dealService.getInsurance(accountNumber)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    this.setState({
                        insurance: data,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    toggleModal() {
        this.setState((prevState) => ({
            openDialog: !prevState.openDialog,
        }));
    }

    initBind() {
        this.getServicesData = this.getServicesData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                getServicesData={this.getServicesData}
                toggleModal={this.toggleModal}
            />
        );
    }
};

export default InsuranceTabContainer;

import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

const useLostReasons = () => {
    const [lostReasons, setLostReasons] = useState([]);
    const { data, loading, error } = useQuery(OpportunityQuery.GET_LOST_REASONS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            const { getLostReasons } = data;
            const result = getLostReasons.map((item) => ({ value: item.lostReasonId, label: item.description }));
            setLostReasons([{ label: 'None', value: null }, ...result]);
        }
    }, [data, loading, error]);

    return {
        lostReasons,
        loading,
    };
};

export default useLostReasons;

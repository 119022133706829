import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
    Hidden,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useQuery, useSubscription } from '@apollo/client';
import NotesQuery from 'services/graphQL/query/NotesQuery';
import NotesSubscription from 'services/graphQL/subscription/NotesSubscription';
import Table from 'components/widgets/Table';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { CodeType } from 'utils/enum/NotesEnum';

const useStyles = makeStyles(() => ({
    main: {
        flex: 1,
        padding: '20px 10px',
        minWidth: '600px',
    },
    column: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    descriptionCol: {
        whiteSpace: 'inherit !important',
    },
}));

const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    ADD_RECORD: 'addRecord',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS:
        return action.payload;
    case ACTION_TYPES.ADD_RECORD:
        return update(state, { $unshift: [action.payload] });
    default:
        return [];
    }
};

const HistoryTab = ({ stockNumber }) => {
    const classes = useStyles();
    const [records, dispatch] = useReducer(reducer, []);
    const { data, error, loading } = useQuery(NotesQuery.GET_INVENTORY_NOTES, {
        variables: { stockNumber },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const { data: subscriptionData } = useSubscription(NotesSubscription.NOTE_ADDED, {
        variables: {
            codeType: CodeType.INVENTORY,
            code: stockNumber,
        },
        shouldResubscribe: true,
    });

    useEffect(() => {
        if (!loading) {
            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                payload: data?.getInventoryNotes,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (subscriptionData) {
            dispatch({
                type: ACTION_TYPES.ADD_RECORD,
                payload: subscriptionData.noteAdded,
            });
        }
    }, [subscriptionData]);

    if (error) {
        ModalUtils.errorMessage(null, error?.message);
    }

    const columns = [
        {
            Header: 'User',
            accessor: 'noteUser',
            maxWidth: 110,
        },
        {
            Header: 'Date',
            accessor: 'noteDate',
            maxWidth: 200,
            className: classes.column,
            Cell: (record) => DateUtils.format(record.value, DateFormat.DEFAULT_DATETIME),
        },
        {
            Header: 'Description',
            accessor: 'noteText',
            className: classes.descriptionCol,
        },
        {
            Header: 'Platform',
            accessor: 'client',
            className: classes.column,
            maxWidth: 180,
        },
    ];

    return (
        <div className={classes.main}>
            <Hidden mdUp>
                <div className={classes.tabTitle}>
                    <Typography variant="h3" gutterBottom>
                        History
                    </Typography>
                </div>
            </Hidden>
            <Table
                loading={loading}
                columns={columns}
                data={records}
                rowSelected
            />
        </div>
    );
};

HistoryTab.propTypes = {
    stockNumber: PropTypes.number.isRequired,
};

export default HistoryTab;

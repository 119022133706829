import { gql } from '@apollo/client';

export default class MessageCenterSubscription {
    static MESSAGE_CENTER_UPDATED = gql`
        subscription messageCenterUpdated($smsChatId: ID!) {
            messageCenterUpdated(smsChatId: $smsChatId) {
                type
                sms {
                    smsId
                    message
                    direction
                    createdOn
                    status
                    createdBy {
                        firstName
                        lastName
                        avatar
                    }
                    errorCode
                }
            }
        }
    `;

    static SMS_CHAT_UPDATED = gql`
        subscription smsChatUpdated {
            smsChatUpdated {
                type
                smsChat {
                    smsChatId
                    cellPhone
                    lastUpdated
                    customer {
                        cellPhone
                        firstName
                        lastName
                    }
                    sms {
                        status
                        direction
                        createdBy {
                            firstName
                            lastName
                            avatar
                        }
                        opportunity {
                            lotName
                        }
                    }
                }
            }
        }
    `;
}

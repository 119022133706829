import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
    boxAddInformation: {
        background: theme.palette.background.white,
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textAdd: {
        color: theme.palette.text.infoDark,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    textRight: {
        color: theme.palette.text.infoDark,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        marginLeft: theme.spacing(1),
    },
    marginRightIcon: {
        marginRight: theme.spacing(1),
    },
});

class PaperAddInformation extends Component {
    render() {
        const {
            classes, label, onClickLeftLabel, className,
            icon, labelRight, iconRight, onClickRightLabel,
        } = this.props;

        return (
            <div
                className={clsx(classes.boxAddInformation, className, 'box-shadow')}
            >
                <Typography className={classes.textAdd} onClick={onClickLeftLabel}>
                    {icon || (
                        <AddCircleOutlineIcon
                            fontSize="small"
                        />
                    )}
                    <span className={classes.text}>{label}</span>
                </Typography>
                <Typography className={classes.textRight} onClick={onClickRightLabel}>
                    <span className={clsx(classes.text, { [classes.marginRightIcon]: iconRight })}>{labelRight}</span>
                    {iconRight}
                </Typography>
            </div>
        );
    }
}

PaperAddInformation.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    className: PropTypes.string,
    labelRight: PropTypes.string,
    iconRight: PropTypes.element,
    onClickLeftLabel: PropTypes.func,
    onClickRightLabel: PropTypes.func,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

PaperAddInformation.defaultProps = {
    label: '',
    className: '',
    icon: null,
    labelRight: '',
    iconRight: null,
    onClickLeftLabel: () => {},
    onClickRightLabel: () => {},
};

export default withStyles(styles)(PaperAddInformation);

import React, { PureComponent } from 'react';

// Base component
import clsx from 'clsx';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

const customStyles = (nowrap) => {
    const optionStyle = nowrap ? {
        option: (base) => ({
            ...base,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '13px',
        }),
    } : {
        option: (base) => ({
            ...base,
            fontSize: '13px',
        }),
    };

    return {
        ...optionStyle,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
};

class Select extends PureComponent {
    getOptionsSorted(options) {
        const nonOrderableOptions = options.filter((option) => typeof option?.label !== 'string');
        const orderableOptions = options.filter((option) => typeof option?.label === 'string');
        const ordered = orderBy(orderableOptions, [(option) => option?.label?.toLowerCase()], ['asc']);

        return [...ordered, ...nonOrderableOptions];
    }

    render() {
        const {
            props, props: {
                options, onChange, value, name, disabled,
                sorted, className, loading, nowrap, size,
                allowToCreate,
                creatable, onCreate,
            },
        } = this;
        const selectedValue = options.find((option) => option.value === value);
        const currentOptions = sorted
            ? this.getOptionsSorted(options)
            : options;

        if (allowToCreate) {
            return (
                <CreatableSelect
                    {...props}
                    isDisabled={disabled || loading}
                    options={currentOptions}
                    onChange={(e) => {
                        e.label = e.label?.toString().toUpperCase();
                        e.value = e.value?.toString().toUpperCase();
                        onChange(name, e.value, e);
                    }}
                    menuPortalTarget={document.body}
                    styles={customStyles(nowrap)}
                    className={clsx('select-bootstrap', className, { 'select-sm': size === 'sm' })}
                    value={selectedValue}
                    isLoading={loading}
                />
            );
        }

        if (creatable) {
            return (
                <CreatableSelect
                    {...props}
                    menuPortalTarget={document.body}
                    isLoading={loading}
                    isDisabled={disabled || loading}
                    styles={customStyles(nowrap)}
                    className={clsx('select-bootstrap', className, { 'select-sm': size === 'sm' })}
                    onChange={(e) => onChange(name, e.value, e)}
                    onCreateOption={(inputValue) => onCreate(inputValue)}
                    options={currentOptions}
                    value={selectedValue}
                />
            );
        }

        // * The conditional "|| null" has been added on the component to returns to its initial state in case no record is found in the list.
        return (
            <ReactSelect
                {...props}
                isDisabled={disabled || loading}
                options={currentOptions}
                onChange={(e) => {
                    onChange(name, e.value, e);
                }}
                menuPortalTarget={document.body}
                styles={customStyles(nowrap)}
                className={clsx('select-bootstrap', className, { 'select-sm': size === 'sm' })}
                value={selectedValue || null}
                isLoading={loading}
            />
        );
    }
}

Select.propTypes = {
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    options: PropTypes.array,
    disabled: PropTypes.bool,
    sorted: PropTypes.bool,
    loading: PropTypes.bool,
    nowrap: PropTypes.bool,
    size: PropTypes.string,
    allowToCreate: PropTypes.bool,
    creatable: PropTypes.bool,
};

Select.defaultProps = {
    value: null,
    options: [],
    onChange: () => {},
    onCreate: () => {},
    disabled: false,
    sorted: false,
    loading: false,
    nowrap: false,
    size: '',
    allowToCreate: false,
    creatable: false,
};

export default (Select);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogContent,
    makeStyles, Slide,
    Button,
} from '@material-ui/core';
import {
    MENU_CONTRACT_STATUS,
} from 'utils/enum/MenuEnum';
import { useQuery, useApolloClient } from '@apollo/client';
import { FetchPolicy, DataSort } from 'utils/enum/Core';
import { modules } from 'utils/enum/modules';
import StringUtils from 'lib/StringUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import MenuQuery from 'services/graphQL/query/menu/MenuQuery';
import MenuMutation from 'services/graphQL/mutate/menu/MenuMutation';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import InputSearch from 'components/widgets/InputSearch';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '5px 5px',
        display: 'flex',
        height: '80vh',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& > div:nth-child(1)': {
            width: '300px',
        },
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            height: '71%',
        },
        '& > div': {
            overflowY: 'hidden',
            overflowX: 'auto',
            minWidth: '1200px',
            [theme.breakpoints.down('sm')]: {
                minWidth: '300px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            marginLeft: '0px',
            paddingLeft: '5px',
            '& > div[class^="makeStyles-main"]': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '10px',
                },
            },
            '& > .react-datepicker-wrapper input': {
                textAlign: 'center',
                fontSize: '12px',
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const HistoryViewer = ({
    onClose,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        contracts: [],
        params: {
            start: 0,
            limit: 50,
            orderBy: 'createdOn',
            dir: DataSort.DESC,
            search: null,
        },
        requestMoreData: true,
        selectedContract: null,
        isPromptOpen: false,
        isVoidingContract: false,
    });

    const {
        contracts,
        params,
        requestMoreData,
        selectedContract,
        isPromptOpen,
        isVoidingContract,
    } = state;

    const {
        data: contractsData,
        loading: loadingContracts,
        error: errorLoadingContracts,
    } = useQuery(MenuQuery.PULL_MENU_CONTRACTS, {
        variables: {
            paginate: {
                start: params.start,
                limit: params.limit,
            },
            sort: {
                field: params.orderBy,
                dir: params.dir,
            },
            search: params.search,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorLoadingContracts) {
            ModalUtils.errorMessage(errorLoadingContracts?.graphQLErrors);
            return;
        }

        if (!loadingContracts) {
            const data = contractsData?.pullMenuContracts;
            setState((prevState) => ({
                ...prevState,
                contracts: [...prevState.contracts, ...data],
                requestMoreData: data.length === params.limit,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContracts, errorLoadingContracts]);

    const loadMoreData = () => {
        if (!requestMoreData) return;
        const { length } = contracts;

        setState((prevState) => ({
            ...prevState,
            params: {
                ...prevState.params,
                start: length + 1,
            },
        }));
    };

    const onSort = ({ sortBy, sortDirection }) => {
        setState((prevState) => ({
            ...prevState,
            contracts: [],
            params: {
                ...prevState.params,
                start: 0,
                orderBy: sortBy,
                dir: sortDirection,
            },
        }));
    };

    const onSearch = (searchValue) => {
        setState((prevState) => ({
            ...prevState,
            contracts: [],
            params: {
                ...prevState.params,
                start: 0,
                search: StringUtils.isEmpty(searchValue) ? null : searchValue,
            },
        }));
    };

    const togglePrompt = (contract) => {
        setState((prevState) => ({
            ...prevState,
            selectedContract: contract,
            isPromptOpen: !isPromptOpen,
        }));
    };

    const onVoidContract = async () => {
        const {
            product, menuContractId, contractNumber, vin,
        } = selectedContract;

        try {
            setState((prevState) => ({
                ...prevState,
                isVoidingContract: true,
            }));

            const { data } = await client.mutate({
                mutation: MenuMutation.VOID_CONTRACT,
                variables: {
                    vendorId: product.vendorDealProduct.vendorId,
                    contractNumber,
                    vin,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const result = data?.voidContract;
            if (result) {
                setState((prevState) => ({
                    ...prevState,
                    isPromptOpen: false,
                    isVoidingContract: false,
                    contracts: prevState.contracts.map((c) => (c.menuContractId === menuContractId
                        ? { ...c, status: MENU_CONTRACT_STATUS.VOIDED }
                        : c)),
                }));
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isPromptOpen: false,
                isVoidingContract: false,
            }));
            ModalUtils.errorMessage(null, error);
        }
    };

    const columns = [
        {
            label: 'Contract Number',
            dataKey: 'contractNumber',
            width: 200,
            disableSort: false,
        },
        {
            label: 'Deal',
            dataKey: 'dealNumber',
            width: 100,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <a target="_blank" rel="noopener noreferrer" href={`/${modules.DEALS}/${record.dealNumber}`}>{record.dealNumber}</a>
                );
            },
        },
        {
            label: 'Product',
            dataKey: 'productName',
            width: 200,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.product.productName}</span>
                );
            },
        },
        {
            label: 'Vendor',
            dataKey: 'vendorName',
            width: 200,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.product.vendorDealProduct.vendorName}</span>
                );
            },
        },
        {
            label: 'Provider',
            dataKey: 'provider',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.product.vendorDealProduct.provider}</span>
                );
            },
        },
        {
            label: 'Expiration Date',
            dataKey: 'expirationDate',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.format(record.expirationDate, DateFormat.DEFAULT_DATE)}</span>
                );
            },
        },
        {
            label: 'Created By',
            dataKey: 'createdBy',
            width: 150,
            disableSort: true,
        },
        {
            label: 'Created On',
            dataKey: 'createdOn',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn)}</span>
                );
            },
        },
        {
            label: 'Status',
            dataKey: 'status',
            width: 100,
            disableSort: false,
        },
        {
            label: 'Actions',
            dataKey: 'actions',
            width: 200,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { product: { vendorDealProduct: { menuVendordetail } } } = record;
                const { allowVoidContracts } = menuVendordetail;

                return (
                    <div className={classes.actionsContainer}>
                        {record.status !== MENU_CONTRACT_STATUS.VOIDED && allowVoidContracts && (
                            <Button
                                className={classes.containedError}
                                size="small"
                                onClick={() => togglePrompt(record)}
                            >
                                Void
                            </Button>
                        )}
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            onClick={() => window.open(record.documentPDF, '_blank')}
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('menuParent')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 15;
    return (
        <Dialog
            open
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            TransitionComponent={Transition}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Contracts Viewer"
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <InputSearch
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={loadingContracts}
                            loadMore={loadMoreData}
                            rowHeight={50}
                            totalRecords={Infinity}
                            data={contracts}
                            columns={columns}
                            sort={onSort}
                            sortBy={params.orderBy}
                            sortDirection={params.dir}
                            width={tableWidth || 0}
                        />
                    </div>
                </div>
                <ConfirmDialog
                    title="Attention!"
                    description={`Do you want to void the contract ${selectedContract?.contractNumber}?`}
                    open={isPromptOpen}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={() => togglePrompt()}
                    disablePrimaryButton={isVoidingContract}
                    disableSecondaryButton={isVoidingContract}
                    onClickSecondary={() => togglePrompt()}
                    onClickPrimary={onVoidContract}
                />
            </DialogContent>
        </Dialog>
    );
};

HistoryViewer.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default HistoryViewer;

import React from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
    FormLabel,
    Button,
} from '@material-ui/core';
import {
    AddCircle as AddCircleIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import Image from 'components/widgets/Image';
import { Actions } from 'utils/enum/InventoryEnum';
import ButtonStyles from 'styles/theme/Button';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const useStyles = makeStyles((theme) => ({
    main: {
        textAlign: 'center',
        minHeight: 100,
        border: '1px dotted lightgray',
        padding: 5,
        zIndex: 3,
        position: 'relative',
    },
    featureImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        width: 60,
    },
    action: {
        color: theme.palette.text.green,
        top: 0,
        right: 0,
        position: 'absolute',
        minWidth: 32,
        minHeight: 32,
        display: 'flex',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editFeature: {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '0 8px',
        minWidth: 23,
        color: theme.palette.text.royalBlue,
    },
    ...ButtonStyles.getStyle(theme),
}));

const Feature = ({
    allowAction,
    data,
    checked,
    onChange,
    disabled,
    allowEdit,
    onOpenKeyFeature,
}) => {
    const classes = useStyles();

    const action = checked ? Actions.REMOVE : Actions.ADD;
    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    return (
        <div className={clsx('d-flex-column', classes.main)}>
            {INVENTORY_VEHICLE_WRITE && allowAction && (
                <>
                    {allowEdit && (
                        <Button
                            color="primary"
                            className={classes.editFeature}
                            onClick={() => onOpenKeyFeature(true, data)}
                        >
                            Edit
                        </Button>
                    )}
                    <Button
                        className={clsx({ [classes.outlinedError]: checked }, classes.action)}
                        variant="outlined"
                        color="secondary"
                        onClick={() => onChange(data, action)}
                        disabled={disabled}
                    >
                        {checked ? (
                            <RemoveCircleOutlineIcon fontSize="small" />
                        ) : (
                            <AddCircleIcon fontSize="small" />
                        )}
                    </Button>
                </>
            )}
            <div className={classes.featureImage}>
                <Image
                    src={data.icon}
                    className={classes.img}
                />
            </div>
            <FormLabel>{data.optionName}</FormLabel>
        </div>
    );
};

Feature.propTypes = {
    data: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    allowAction: PropTypes.bool.isRequired,
    allowEdit: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onOpenKeyFeature: PropTypes.func.isRequired,
};

Feature.defaultProps = {
    disabled: false,
    allowEdit: false,
};

export default Feature;

import React, { useEffect, useState, Fragment } from 'react';

import {
    Typography, Paper, Grid, Button,
} from '@material-ui/core';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { LocalAtmIcon } from 'components/icons';
import Loading from 'components/widgets/Loading';
import { useQuery, useLazyQuery } from '@apollo/client';
import { CheckDetailLabels } from 'utils/enum/PayrollEnum';
import PayrollChecksQuery from 'services/graphQL/query/payroll/PayrollChecks';
import PayrollCheckDialog from 'components/widgets/payroll/PayrollCheckDialog';
import UnpaidEmployeeInformation from 'components/widgets/payroll/PayrollChecks/UnpaidEmployeeInformation';

const CommissionSalaryPanel = ({
    userTaxName, displayUserTax, displayStateTax, classes, unpaidList,
    calculateCheckDetail, setCalculateCheckDetail, reloadEmployeeHasCheck,
    hasCheck, payPeriod, employeeId, pendingFinalPayment, reloadUnpaidList,
}) => {
    const [state, setState] = useState({
        netPay: 0,
        userTax: 0,
        grossPay: 0,
        medicare: 0,
        stateTax: 0,
        salaryPaid: 0,
        checkNumber: 0,
        commissions: 0,
        withholdings: 0,
        preTaxBonuses: 0,
        postTaxBonuses: 0,
        socialSecurity: 0,
        commissionList: [],
        preTaxDeductions: 0,
        postTaxDeductions: 0,
        standardTaxBonuses: 0,
        openCheckDialog: false,
        standardTaxDeductions: 0,
    });

    const {
        data, loading, error, refetch,
    } = useQuery(PayrollChecksQuery.GET_PAYROLL_CHECK_DETAIL_COMMISSION_SALARY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
            pendingFinalPayment,
        },
        skip: !employeeId || !payPeriod,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getPayrollCheckDetail) {
            const { getPayrollCheckDetail: { check, ...rest } } = data;
            setState({
                ...rest,
                netPay: check ? check.amount : rest.netPay,
                checkNumber: check ? check.checkNumber || check.achCheckNumber : 0,
            });
        } else {
            setState({
                netPay: 0,
                userTax: 0,
                grossPay: 0,
                medicare: 0,
                stateTax: 0,
                salaryPaid: 0,
                checkNumber: 0,
                commissions: 0,
                withholdings: 0,
                preTaxBonuses: 0,
                postTaxBonuses: 0,
                socialSecurity: 0,
                commissionList: [],
                preTaxDeductions: 0,
                postTaxDeductions: 0,
                standardTaxBonuses: 0,
                openCheckDialog: false,
                standardTaxDeductions: 0,
            });
        }
        setCalculateCheckDetail(false);
    }, [data, loading, error, setCalculateCheckDetail]);

    const [validatePayrollCheck, {
        loading: loadingValidations, data: validationResult, error: validationErrors,
    }] = useLazyQuery(PayrollChecksQuery.CAN_GENERATE_PAYROLL_CHECK, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (validationErrors) {
            ModalUtils.errorMessage(validationErrors?.graphQLErrors);
        }
        if (!loadingValidations && validationResult?.canGeneratePayrollCheck) {
            setState((previousState) => ({
                ...previousState,
                openCheckDialog: true,
            }));
        }
    }, [loadingValidations, validationResult, validationErrors]);

    useEffect(() => {
        if (calculateCheckDetail) refetch();
    }, [calculateCheckDetail, refetch]);

    const toggleCheckDialog = () => {
        if (!state.openCheckDialog) {
            validatePayrollCheck({
                variables: {
                    payPeriod,
                    employeeId,
                },
            });
        } else {
            setState((previousState) => ({
                ...previousState,
                openCheckDialog: false,
            }));
        }
    };

    const checkDataInput = {
        netPay: state.netPay,
        userTax: state.userTax,
        grossPay: state.grossPay,
        medicare: state.medicare,
        stateTax: state.stateTax,
        commissions: state.commissions,
        withholdings: state.withholdings,
        preTaxBonuses: state.preTaxBonuses,
        commissionList: state.commissionList,
        socialSecurity: state.socialSecurity,
        postTaxBonuses: state.postTaxBonuses,
        preTaxDeductions: state.preTaxDeductions,
        postTaxDeductions: state.postTaxDeductions,
        standardTaxBonuses: state.standardTaxBonuses,
        standardTaxDeductions: state.standardTaxDeductions,
    };

    const refetchData = () => {
        reloadUnpaidList();
        reloadEmployeeHasCheck();
        setCalculateCheckDetail(true);
    };

    const checkDetailRows = [
        { label: CheckDetailLabels.APPROVED_COMMISSIONS, value: state.commissions },
        { label: CheckDetailLabels.SALARY, value: state.salaryPaid },
        { label: CheckDetailLabels.PRE_TAX_ADDITIONS, value: state.preTaxBonuses + state.standardTaxBonuses },
        { label: CheckDetailLabels.GROSS_PAY, value: state.grossPay + state.preTaxDeductions + state.standardTaxDeductions },
        { label: CheckDetailLabels.PRE_TAX_DEDUCTIONS, value: state.preTaxDeductions + state.standardTaxDeductions },
        { label: CheckDetailLabels.TAXABLE_GROSS, value: state.grossPay },
        { label: CheckDetailLabels.FEDERAL_WITHHOLDING, value: state.withholdings },
        { label: CheckDetailLabels.SOCIAL_SECURITY, value: state.socialSecurity },
        { label: CheckDetailLabels.MEDICARE, value: state.medicare },
        ...((displayStateTax || state.stateTax > 0) ? [{ label: CheckDetailLabels.STATE_TAX, value: state.stateTax }] : []),
        ...((displayUserTax || state.userTax > 0) ? [{ label: userTaxName, value: state.userTax }] : []),
        { label: CheckDetailLabels.POST_TAX_ADDITIONS, value: state.postTaxBonuses },
        { label: CheckDetailLabels.POST_TAX_DEDUCTIONS, value: state.postTaxDeductions },
        { label: CheckDetailLabels.NET_PAY, value: state.netPay },
    ];

    const isBoldText = (text) => text === CheckDetailLabels.GROSS_PAY
    || text === CheckDetailLabels.TAXABLE_GROSS
    || text === CheckDetailLabels.NET_PAY;

    return (
        <div className={classes.root}>
            <Grid item className={classes.header}>
                <Typography variant="h6" className={classes.title}>Commission + Salary</Typography>
                {pendingFinalPayment && (
                    <UnpaidEmployeeInformation list={unpaidList} />
                )}
            </Grid>
            <Paper elevation={0} className={classes.boxRow} variant="outlined" square>
                {loading && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {!loading && (
                    <Grid container spacing={1}>
                        {hasCheck && (
                            <>
                                <Grid item xs={7} className="content-label">
                                    <Typography color="primary" variant="h6">{CheckDetailLabels.CHECK_NUMBER}</Typography>
                                </Grid>
                                <Grid item xs={5} className="content-value">
                                    <Typography>{state.checkNumber}</Typography>
                                </Grid>

                            </>
                        )}
                        {checkDetailRows.map((item, index) => (
                            <Fragment key={index}>
                                <Grid item xs={7} className="content-label">
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        className={clsx({ [classes.textBold]: isBoldText(item.label) })}
                                    >
                                        {item.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} className="content-value">
                                    <Typography>{NumberUtils.applyCurrencyFormat(item.value)}</Typography>
                                </Grid>
                            </Fragment>
                        ))}
                        {!hasCheck && state.netPay > 0 && (
                            <Grid item xs={12} className={classes.button}>
                                <Button
                                    size="small"
                                    disabled={loading}
                                    variant="contained"
                                    onClick={toggleCheckDialog}
                                    startIcon={<LocalAtmIcon />}
                                    className={classes.newButton}
                                >
                                    Generate Check
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Paper>
            {state.openCheckDialog && (
                <PayrollCheckDialog
                    payPeriod={payPeriod}
                    refetch={refetchData}
                    employeeId={employeeId}
                    checkData={checkDataInput}
                    onClose={toggleCheckDialog}
                />
            )}
        </div>
    );
};

CommissionSalaryPanel.propTypes = {
    hasCheck: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
    userTaxName: PropTypes.string.isRequired,
    displayUserTax: PropTypes.bool.isRequired,
    displayStateTax: PropTypes.bool.isRequired,
    reloadUnpaidList: PropTypes.func.isRequired,
    pendingFinalPayment: PropTypes.bool.isRequired,
    calculateCheckDetail: PropTypes.bool.isRequired,
    reloadEmployeeHasCheck: PropTypes.func.isRequired,
    setCalculateCheckDetail: PropTypes.func.isRequired,
    unpaidList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CommissionSalaryPanel;

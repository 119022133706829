export default class EditorControlStyles {
    static percentageControl() {
        return ({
            inputGroupSM: {
                lineHeight: '0.5',
            },
        });
    }

    static selectStyles() {
        return ({
            selectSM: {
                padding: 0,
                '& > *': {
                    minHeight: 'auto !important',
                    fontSize: '13px',
                    height: '31px',
                    lineHeight: '1.4',
                },
            },
        });
    }
}

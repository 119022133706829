import React, {
    useEffect,
    useReducer,
} from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import MessageUtils from 'utils/MessageUtils';
import PayrollUtils from 'utils/PayrollUtils';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import If from 'components/widgets/conditional/If';
import { useMutation, useQuery } from '@apollo/client';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import { AdjustmentType, Adjustments } from 'utils/enum/PayrollEnum';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import PayrollAdditionsSchema from 'utils/schema/payroll/PayrollAdditionsSchema';
import PayrollAdjustmentsQuery from 'services/graphQL/query/payroll/PayrollAdjustments';
import PayrollAdjustmentsMutation from 'services/graphQL/mutate/payroll/PayrollAdjustments';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    baseGroup: {
        margin: '0 5px',
        alignItems: 'center',
        '& > label': {
            minWidth: 92,
            marginBottom: 0,
            marginRight: 10,
            fontWeight: 500,
            textAlign: 'end',
            fontSize: '14px',
            color: theme.palette.text.minsk,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        '& > *:last-child': {
            minWidth: 320,
        },
    },
    termGroup: {
        '& > *:last-child': {
            maxWidth: 50,
            marginRight: 10,
        },
    },
}));

const initialState = {
    term: 0,
    memo: '',
    amount: 0,
    payment: 0,
    descriptionId: 0,
    controlNumber: '',
    descriptionList: [],
};

const ACTION_TYPES = {
    ON_CHANGE: 'onChange',
    INIT_RECORD_VALUES: 'initRecordValues',
    INIT_DESCRIPTION_LIST: 'initDescriptionList',
};

const reducer = (state, action) => {
    const {
        list,
        type,
        field,
        isNew,
        value,
        values,
    } = action;
    switch (type) {
    case ACTION_TYPES.INIT_DESCRIPTION_LIST: {
        return update(state, {
            descriptionList: { $set: list },
            ...(isNew && { descriptionId: { $set: list?.find((item) => item.description === Adjustments.SALES_BONUS)?.bdId || -1 } }),
        });
    }
    case ACTION_TYPES.INIT_RECORD_VALUES:
        const {
            bdId,
            memo,
            term,
            amount,
            controlNumber,
        } = values;
        return update(state, {
            memo: { $set: memo },
            term: { $set: term },
            amount: { $set: amount },
            descriptionId: { $set: bdId },
            controlNumber: { $set: controlNumber },
            payment: {
                $set: term > 0 && amount > 0 ? PayrollUtils.calculateEmployeeLoanPayment({
                    amount,
                    term,
                }) : 0,
            },
        });
    case ACTION_TYPES.ON_CHANGE:
        const fields = ['amount', 'term', 'descriptionId'];
        if (fields.includes(field)) {
            const localTerm = field === 'term' ? value : state.term;
            const localAmount = field === 'amount' ? value : state.amount;
            const localDescriptionId = field === 'descriptionId' ? value : state.descriptionId;
            const isLoan = Boolean(localDescriptionId)
                && Boolean(state.descriptionList?.find((item) => item.bdId === localDescriptionId)?.isLoan);
            return update(state, {
                [field]: { $set: value },
                term: { $set: isLoan ? localTerm : 0 },
                payment: {
                    $set: isLoan ? PayrollUtils.calculateEmployeeLoanPayment({
                        amount: localAmount,
                        term: localTerm,
                    }) : 0,
                },
            });
        }
        return update(state, {
            [field]: { $set: value },
        });
    default: return state;
    }
};

const PayrollAdditionsDialog = ({
    open,
    year,
    record,
    onClose,
    payPeriod,
    employeeId,
    setCalculateCheckDetail,
}) => {
    const payrollAdditionId = record?.payrollAdditionId || 0;
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { term, amount } = state;
    const isLoan = Boolean(state.descriptionId) && Boolean(state.descriptionList?.find((item) => item.bdId === state.descriptionId)?.isLoan);
    const keyStore = new KeyStore();

    const {
        data, loading, error,
    } = useQuery(PayrollAdjustmentsQuery.GET_PAYROLL_DB_DESCRIPTION_LIST_BY_TYPE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            bdType: AdjustmentType.ADDITION.toUpperCase(),
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPayrollDBListByType } = data;
            dispatch({
                list: getPayrollDBListByType,
                isNew: payrollAdditionId === 0,
                type: ACTION_TYPES.INIT_DESCRIPTION_LIST,
            });
        }
    }, [data, loading, error, payrollAdditionId]);

    const [savePayrollAdjustment, { loading: updating }] = useMutation(PayrollAdjustmentsMutation.SAVE_PAYROLL_ADDITION);

    useEffect(() => {
        if (record) {
            dispatch({
                values: record,
                type: ACTION_TYPES.INIT_RECORD_VALUES,
            });
        }
    }, [record]);

    const onChange = (field, value) => {
        dispatch({
            field,
            value,
            type: ACTION_TYPES.ON_CHANGE,
        });
    };

    const onSave = async () => {
        try {
            const { descriptionList, payment, ...filteredState } = state;
            const input = {
                year,
                payPeriod,
                employeeId,
                ...filteredState,
                ...(payrollAdditionId > 0 && { payrollAdditionId }),
                lotName: keyStore.getSelectedLot()?.lotName || ALL_LOTS,
            };
            const response = await savePayrollAdjustment({ variables: { input } });

            if (response.data?.savePayrollAddition) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose();
                setCalculateCheckDetail(true);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Payroll Additions'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const isValidData = isValidSchema(PayrollAdditionsSchema, state);
    const { isValid, errors } = isValidData;
    const mappedDescriptionList = state.descriptionList?.map((item) => ({
        value: item.bdId,
        label: item.description,
    }));
    const loanText = isLoan ? 'Loan ' : '';
    const mainClassesGroup = clsx(classes.baseGroup, classes.group);

    return (
        <Dialog
            maxWidth="md"
            open={open}
        >
            <DialogAppBar
                title={payrollAdditionId === 0 ? 'New Addition' : 'Editing Addition'}
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Addition for:</Form.Label>
                                    <Select
                                        size="sm"
                                        name="descriptionId"
                                        placeholder="Select"
                                        onChange={onChange}
                                        value={state.descriptionId}
                                        options={mappedDescriptionList}
                                        className={isValidField(errors, 'descriptionId') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Control #:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.controlNumber}
                                        onChange={(e) => onChange('controlNumber', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>{`${loanText}Amount:`}</Form.Label>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        name="amount"
                                        value={amount}
                                        thousandSeparator
                                        fixedDecimalScale
                                        onChange={(value) => onChange('amount', value)}
                                        className={isValidField(errors, 'amount') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <If condition={isLoan}>
                                <Form.Row className={classes.row}>
                                    <Form.Group as={Row} className={clsx(classes.baseGroup, classes.termGroup)}>
                                        <Form.Label>Term:</Form.Label>
                                        <InputNumber
                                            size="sm"
                                            name="term"
                                            value={term}
                                            decimalScale={0}
                                            onChange={(value) => onChange('term', value)}
                                            className={isValidField(errors, 'term') ? 'invalid-field' : ''}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className={classes.baseGroup}>
                                        <Form.Label>{`Deduction per pay period: ${NumberUtils.applyCurrencyFormat(state.payment)}`}</Form.Label>
                                    </Form.Group>
                                </Form.Row>
                            </If>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={mainClassesGroup}>
                                    <Form.Label>Memo:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={state.memo}
                                        onChange={(e) => onChange('memo', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                variant="contained"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

PayrollAdditionsDialog.propTypes = {
    year: PropTypes.number,
    record: PropTypes.object,
    payPeriod: PropTypes.string,
    employeeId: PropTypes.number,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setCalculateCheckDetail: PropTypes.func.isRequired,
};

PayrollAdditionsDialog.defaultProps = {
    year: 0,
    record: null,
    employeeId: 0,
    payPeriod: null,
};

export default PayrollAdditionsDialog;

import React, {
    useEffect, useReducer,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { concat, isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import InputControl from 'components/widgets/editorControls/InputControl';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import { useLazyQuery, useMutation } from '@apollo/client';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import VirtualTable from 'components/widgets/VirtualTable';
import { Button, Tooltip } from '@material-ui/core';
import {
    AddCircleOutlineIcon, CachedIcon, CloseIcon, DeleteOutlineOutlinedIcon, EditOutlinedIcon, SaveOutlinedIcon,
} from 'components/icons';
import Header from 'components/widgets/Header';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import InputSearch from 'components/widgets/InputSearch';
import CheckboxControl from 'components/widgets/editorControls/CheckboxControl';
import clsx from 'clsx';
import { AccountingCOAType, AccountTypeList, ControlledByList } from 'utils/enum/AccountingEnum';
import If from 'components/widgets/conditional/If';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import KeyStore from 'utils/KeyStore';
import Permissions from 'utils/enum/Permissions';
import CatalogList from 'components/widgets/catalogs/CatalogList';
import CatalogEnum from 'utils/enum/CatalogEnum';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';

const basicStyles = makeStyles((theme) => AccountingStyles.basicStyles(theme));
const columnStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));
const buttonStyles = makeStyles((theme) => AccountingStyles.buttonStyles(theme));
const ownStyle = makeStyles((theme) => ({
    mainHeight: {
        height: 'calc(100vh - 200px)',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 10,
        },
        '& > button': {
            minWidth: 104,
        },
        flexWrap: 'wrap',
    },
    containerFilterSearch: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(0.3),
        },
    },
    search: {
        borderRadius: 10,
        width: 230,
        '& .form-control': {
            height: 'calc(1.5em + 0.5rem + 2px)',
            padding: '0.25rem 0.5rem',
            fontSize: '0.875rem',
            lineHeight: '1.5',
            borderRadius: '0.2rem',
        },
    },
    overflowX: {
        overflowX: 'scroll',
    },
    isDirtyClass: {
        height: '10px',
        width: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        display: 'inline-block',
    },
    dirtyWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    lineClass: {
        width: '28px',
    },
}));

const ACTION_TYPES = {
    SET_INITIAL_STATE: 'setInitialState',
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    SET_STATE_VALUES: 'setStateValues',
    ADD_NEW_LINE: 'addNewLine',
    SET_TABLE_APPROVED: 'setTableApproved',
    SET_APPROVE_ALL: 'setApproveAll',
    ON_DELETE_ACCOUNT: 'onDeleteAccount',
    SET_CATEGORY_DROPDOWN: 'setCategoryDropdown',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return {
            ...action.value,
        };
    }
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        const records = action.value.map(((item) => ({
            ...item,
            accountId: item.accountNumber,
        })));

        return {
            ...state,
            records,
            recordsFiltered: records,
            lotList: action.lotList,
        };
    }
    case ACTION_TYPES.SET_CATEGORY_DROPDOWN: {
        const categories = action.payload.filter((c) => c.enumId === 5).map((c) => ({ value: c.description }));
        const subCategories = action.payload.filter((c) => c.enumId === 6).map((c) => ({ value: c.description }));

        return {
            ...state,
            categoryList: categories,
            subCategoryList: subCategories,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const {
            value, columnId, cell,
        } = action;
        const keyValue = 'accountId';
        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;
                newItem.isDirty = true;
            }

            return newItem;
        });

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const newLine = {
            accountId: (state.accountId || 0) - 1,
            accountNumber: 0,
            description: '',
            type: '',
            lotName: '',
            isControlled: false,
            controlledBy: '',
            printCheckNumbers: false,
            expandByLot: false,
            category: '',
            subCategory: '',
            category2: '',
            subCategory2: '',
            category3: '',
            subCategory3: '',
        };

        const data = concat(newLine, state.records);

        return {
            ...state,
            records: data,
            accountId: newLine.accountId,
            idSelected: newLine.accountId,
        };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        const newRecords = state.records.map((item) => ({
            ...item,
            isChecked: !action.value,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.SET_TABLE_APPROVED: {
        const newRecords = state.records.map((item) => ({
            ...item,
            isChecked: item.accountId === action.value ? !item.isChecked : item.isChecked,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.ON_DELETE_ACCOUNT: {
        const newRecords = state.records.filter((c) => c.accountId !== action.value);

        return {
            ...state,
            records: newRecords,
        };
    }
    default:
        return state;
    }
};

const AccountingCOA = () => {
    const keyStore = new KeyStore();
    const ACCOUNTING_COA_WRITE = keyStore.hasPermission(Permissions.ACCOUNTING_COA_WRITE);

    const classes = {
        ...basicStyles(),
        ...columnStyles(),
        ...containerStyles(),
        ...buttonStyles(),
        ...ownStyle(),
    };

    const initialState = {
        records: [],
        categoryList: [],
        subCategoryList: [],
        lotList: [],
        showDeletePopup: false,
        itemToDelete: null,
        search: '',
        idSelected: 0,
        openCategory: false,
        openSubcategory: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        records,
        categoryList,
        subCategoryList,
        lotList,
        showDeletePopup,
        itemToDelete,
        search,
        idSelected,
        openCategory,
        openSubcategory,
    } = state;

    const [loadCatalogs] = useLazyQuery(CatalogQuery.ENUM_VALUES,
        {
            variables: {
                descriptions: [CatalogEnum.CATEGORY, CatalogEnum.SUB_CATEGORY],
                onlyActive: false,
            },
            onCompleted: (res) => {
                dispatch({ type: ACTION_TYPES.SET_CATEGORY_DROPDOWN, payload: res.getEnumValues });
            },
            onError: (errorMessage) => {
                ModalUtils.errorMessage(null, errorMessage);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const [loadData, { loading, refetch }] = useLazyQuery(AccountingCOAQuery.GET_COA_LIST, {
        onCompleted: (res) => {
            const { getAccountingCOAChartList: { data }, lotList: lots } = res;

            dispatch({ type: ACTION_TYPES.SET_FETCHED_RECORDS, value: data, lotList: lots });
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getLineErrors = (items) => {
        const errors = [];

        items.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.accountNumber <= 0) message += ' Account Number,';
            if (isEmpty(item.description.trim())) message += ' Description,';
            if (isEmpty(item.type)) message += ' Type,';
            if (isEmpty(item.lotName.trim())) message += ' Lot name,';
            if (item.isControlled && isEmpty(item.controlledBy)) message += ' Controlled By,';

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message} are required.` });
            }
        });

        return errors;
    };

    const reloadData = () => {
        dispatch({ type: ACTION_TYPES.SET_INITIAL_STATE, value: initialState });
        refetch();
    };

    const [saveData, { loading: saving }] = useMutation(AccountingCOAQuery.CREATE_UPDATE_ACCOUNT, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.createUpdateAccount) {
                ModalUtils.successMessage(null, 'saved successfully');
                refetch();
            }
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;

            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
    });

    const openOrCloseDialog = (value, item = null) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                showDeletePopup: value,
                itemToDelete: item,
            },
        });
    };

    const showPopup = (popupType) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                [popupType]: true,
            },
        });
    };

    const onClosePopup = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openCategory: false,
                openSubcategory: false,
            },
        });

        loadCatalogs();
    };

    const [deleteAccount, { loading: deleting }] = useMutation(AccountingCOAQuery.DELETE_ACCOUNT, {
        onCompleted: (mutationData) => {
            if (mutationData?.deleteAccount) {
                ModalUtils.successMessage(null, 'Account deleted successfully!');

                openOrCloseDialog(false);
                reloadData();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        const errors = getLineErrors(records.filter((c) => c.isDirty));

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'There are not changes to save');
            return;
        }

        if (errors.length > 0) {
            ModalUtils.errorMessage(errors);
            return;
        }

        const recordsToSave = records.filter((c) => c.isDirty).map((item) => (
            {
                accountId: Number(item.accountId),
                accountNumber: Number(item.accountNumber),
                description: String(item.description),
                type: String(item.type),
                lotName: String(item.lotName),
                isControlled: item.isControlled,
                controlledBy: item.controlledBy && item.controlledBy !== 0 ? item.controlledBy : '',
                printCheckNumbers: item.printCheckNumbers,
                expandByLot: item.expandByLot,
                category: item.category === 0 || isEmpty(item.category) ? '' : item.category,
                subCategory: item.subCategory === 0 || isEmpty(item.subCategory) ? '' : item.subCategory,
                category2: item.category2 === 0 || isEmpty(item.category2) ? '' : item.category2,
                subCategory2: item.subCategory2 === 0 || isEmpty(item.subCategory2) ? '' : item.subCategory2,
                category3: item.category3 === 0 || isEmpty(item.category3) ? '' : item.category3,
                subCategory3: item.subCategory3 === 0 || isEmpty(item.subCategory3) ? '' : item.subCategory3,
            }));

        saveData({
            variables: {
                records: recordsToSave,
            },
        });
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        if (cell.value === newValue) return;

        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
        });
    };

    const onAddNewLine = () => {
        dispatch({ type: ACTION_TYPES.ADD_NEW_LINE });
    };

    const onSearch = (inputValue) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                search: inputValue,
            },
        });
    };

    const onDeleteAccount = () => {
        const itemToBeDeleted = itemToDelete.filter((c) => c.accountNumber > 0).map((c) => c.accountNumber);
        deleteAccount({
            variables: {
                ids: itemToBeDeleted,
            },
        });
    };

    const widthColumns = [50, 30, 70, 80, 250, 150, 150, 100, 150, 100, 120, 200, 200, 200, 200, 200, 200];

    const getColumns = () => {
        const columns = [
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'line',
                label: 'Line',
                width: widthColumns[0],
                cellRenderer: (cell) => {
                    const { rowData: { isDirty } } = cell;
                    return (
                        <div className={classes.dirtyWrapper}>
                            <span className={classes.lineClass}>{cell.rowIndex + 1}</span>
                            {isDirty && <span className={classes.isDirtyClass} /> }
                        </div>
                    );
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'isChecked',
                headerRenderer: () => {
                    const allApproved = records.length > 0 && records.filter((c) => c.isChecked).length === records.length;

                    return (
                        <CheckboxControl
                            name="isCheckedAll"
                            value={allApproved}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                        />
                    );
                },
                width: widthColumns[1],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, isChecked } } = cell;

                    return (
                        <CheckboxControl
                            name="isChecked"
                            value={isChecked}
                            className={classes.highlightedColor}
                            onClick={() => dispatch({ type: ACTION_TYPES.SET_TABLE_APPROVED, value: accountId })}
                            comparePropertyId={accountId}
                        />
                    );
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'actions',
                label: 'Actions',
                width: widthColumns[2],
                cellRenderer: (cell) => {
                    const { rowData: { accountId }, rowData } = cell;

                    return (
                        <>
                            {idSelected === accountId
                                ? (
                                    <IconButton
                                        className={classes.buttonWrapper}
                                        onClick={() => {
                                            dispatch({
                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                value: {
                                                    idSelected: 0,
                                                },
                                            });
                                        }}
                                    >
                                        <Tooltip title="Edit account">
                                            <CloseIcon className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)} />
                                        </Tooltip>
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        className={classes.buttonWrapper}
                                        onClick={() => {
                                            dispatch({
                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                value: {
                                                    idSelected: accountId,
                                                },
                                            });
                                        }}
                                    >
                                        <Tooltip title="Cancel edit">
                                            <EditOutlinedIcon className={clsx(classes.actionButtonSize, classes.actionButtonSuccess)} />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            <IconButton
                                className={classes.buttonWrapper}
                                onClick={() => {
                                    if (accountId < 0) {
                                        dispatch({
                                            type: ACTION_TYPES.ON_DELETE_ACCOUNT,
                                            value: accountId,
                                        });
                                    } else {
                                        openOrCloseDialog(true, [rowData]);
                                    }
                                }}
                            >
                                <Tooltip title="Delete account">
                                    <DeleteOutlineOutlinedIcon className={clsx(classes.actionButtonSize, classes.deleteButton)} />
                                </Tooltip>
                            </IconButton>
                        </>
                    );
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'accountNumber',
                label: 'Account #',
                width: widthColumns[3],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, accountNumber }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <InputControl
                                name={dataKey}
                                type="number"
                                showCurrency={false}
                                thousandSeparator={false}
                                allowNegative={false}
                                value={accountNumber}
                                editorCellObject={cellObject}
                                className={isEmpty(String(accountNumber)) || accountNumber === 0 ? 'invalid-field' : ''}
                                onChange={handleEditorChange}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnCenter}>{accountNumber}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                className: classes.columnLeft,
                dataKey: 'description',
                label: 'Description',
                width: widthColumns[4],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, description }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={description}
                                editorCellObject={cellObject}
                                onChange={handleEditorChange}
                                className={isEmpty(description) ? 'invalid-field' : ''}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnCenter}>{description}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'type',
                label: 'Type',
                width: widthColumns[5],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, type }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={type}
                                className={isEmpty(type) ? 'invalid-field' : ''}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: AccountTypeList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{type}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'lotName',
                label: 'Lot Name',
                width: widthColumns[6],
                cellRenderer: (cell) => {
                    const { rowData: { lotName, accountId }, dataKey } = cell;
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                value={lotName}
                                className={isEmpty(lotName) ? 'invalid-field' : ''}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: lotList,
                                    idField: 'lotName',
                                    descriptionField: 'lotName',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{lotName}</span>;
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'isControlled',
                label: 'Is Controlled',
                width: widthColumns[7],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, isControlled }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    return (
                        <CheckboxControl
                            editorCellObject={cellObject}
                            name={dataKey}
                            value={isControlled}
                            className={classes.highlightedColor}
                            onClick={handleEditorChange}
                            comparePropertyId={accountId}
                        />
                    );
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'controlledBy',
                label: 'Controlled By',
                width: widthColumns[8],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, isControlled, controlledBy }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={controlledBy}
                                className={`${isControlled && isEmpty(controlledBy) ? 'invalid-field' : ''} ${isControlled ? '' : 'disabled'}`}
                                disabled={!isControlled}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: ControlledByList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{controlledBy}</span>;
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'printCheckNumbers',
                label: 'Print Check #',
                width: widthColumns[9],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, printCheckNumbers, type }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (type?.toLowerCase() === AccountingCOAType.BANK.toLowerCase()) {
                        return (
                            <CheckboxControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={printCheckNumbers}
                                className={classes.highlightedColor}
                                onClick={handleEditorChange}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return null;
                },
            },
            {
                headerClassName: classes.columnCenter,
                className: classes.columnCenter,
                dataKey: 'expandByLot',
                label: 'Expand By Lot',
                title: 'Expand By Lot Name in Balance Sheet',
                width: widthColumns[10],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, expandByLot }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    return (
                        <CheckboxControl
                            editorCellObject={cellObject}
                            name={dataKey}
                            value={expandByLot}
                            className={classes.highlightedColor}
                            onClick={handleEditorChange}
                            comparePropertyId={accountId}
                        />
                    );
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'category',
                label: 'Category',
                width: widthColumns[11],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, category }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={category}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: categoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{category}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'subCategory',
                label: 'Sub Category',
                width: widthColumns[12],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, subCategory }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={subCategory}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: subCategoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{subCategory}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'category2',
                label: 'Category 2',
                width: widthColumns[13],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, category2 }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={category2}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: categoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{category2}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'subCategory2',
                label: 'Sub Category 2',
                width: widthColumns[14],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, subCategory2 }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={subCategory2}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: subCategoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{subCategory2}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'category3',
                label: 'Category 3',
                width: widthColumns[15],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, category3 }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={category3}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: categoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{category3}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'subCategory3',
                label: 'Sub Category 3',
                width: widthColumns[16],
                cellRenderer: (cell) => {
                    const { rowData: { accountId, subCategory3 }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (idSelected === accountId) {
                        return (
                            <SelectControl
                                editorCellObject={cellObject}
                                name={dataKey}
                                value={subCategory3}
                                placeholder="select"
                                onChange={handleEditorChange}
                                dataSource={{
                                    localData: subCategoryList,
                                    idField: 'value',
                                    descriptionField: 'value',
                                }}
                                comparePropertyId={accountId}
                            />
                        );
                    }

                    return <span className={classes.columnLeft}>{subCategory3}</span>;
                },
            },
        ];

        return columns;
    };

    useEffect(() => {
        loadCatalogs();

        loadData({
            variables: {
                paginate: {
                    init: 0,
                    limit: 10,
                    ignoreLimit: true,
                },
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.accountId !== 0) {
            const nextElement = document.querySelector('[aria-rowIndex="1"] input.accountNumber-ax-edit-ctrl');
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.accountId]);

    const recordsFiltered = search === '' ? records
        : records?.filter((data) => data.description.toLowerCase().includes(state.search.toLowerCase())
        || data.accountNumber.toString().includes(state.search.toLowerCase())
        || data.type.toLowerCase().includes(state.search.toLowerCase())
        || data.accountId < 0);

    const approvedRecords = records?.filter((c) => c.isChecked).map((c) => ({ accountNumber: c.accountNumber }));

    const isDirty = records?.filter((c) => c.isDirty).length > 0;

    return (
        <div className={clsx(classes.flexContainer, classes.mainHeight)}>
            <Header>
                <div className={classes.toolbar}>
                    <div className={classes.containerFilterSearch}>
                        <InputSearch
                            customClasses={classes.search}
                            onSearch={(val) => onSearch(val)}
                            initialSearch={search || ''}
                            executeWhenClearButton={() => onSearch('')}
                        />
                        <If condition={ACCOUNTING_COA_WRITE}>
                            <Tooltip title="Add Account">
                                <Button
                                    onClick={onAddNewLine}
                                    variant="outlined"
                                    startIcon={<AddCircleOutlineIcon />}
                                    disabled={loading || saving}
                                    size="small"
                                >
                                    Add
                                </Button>
                            </Tooltip>
                            <Tooltip title="Save Account">
                                <span>
                                    <Button
                                        onClick={onSave}
                                        variant="outlined"
                                        startIcon={<SaveOutlinedIcon />}
                                        disabled={loading || saving || !isDirty}
                                        size="small"
                                    >
                                        Save
                                    </Button>
                                </span>
                            </Tooltip>
                            {approvedRecords.length > 1
                            && (
                                <Tooltip
                                    title="Delete selection"
                                    placement="top-start"
                                >
                                    <Badge
                                        badgeContent={approvedRecords?.length}
                                        color="primary"
                                    >
                                        <Button
                                            onClick={() => openOrCloseDialog(true, approvedRecords)}
                                            variant="outlined"
                                            startIcon={<DeleteOutlineOutlinedIcon className={classes.actionButtonMain} />}
                                            disabled={loading || approvedRecords?.length <= 1}
                                            size="small"
                                        >
                                            Delete selection
                                        </Button>
                                    </Badge>
                                </Tooltip>
                            ) }
                        </If>
                        <Tooltip title="Reload data">
                            <Button
                                variant="outlined"
                                startIcon={<CachedIcon />}
                                onClick={() => reloadData()}
                                disabled={loading}
                                size="small"
                            >
                                Reload
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add Category">
                            <Button
                                variant="outlined"
                                onClick={() => showPopup('openCategory')}
                                disabled={loading}
                                size="small"
                            >
                                Add Category
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add Sub Category">
                            <Button
                                variant="outlined"
                                onClick={() => showPopup('openSubcategory')}
                                disabled={loading}
                                size="small"
                            >
                                Add Sub Category
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </Header>
            <div className={clsx(classes.bottomTableHeight, classes.overflowX)}>
                <VirtualTable
                    loading={loading}
                    data={loading ? [] : recordsFiltered}
                    columns={getColumns()}
                    width={widthColumns.reduce((a, b) => a + b, 0)}
                />
            </div>
            {showDeletePopup && (
                <ConfirmDialog
                    title={approvedRecords.length > 1 ? 'Delete accounts selected'
                        : `Delete Account ${itemToDelete[0].accountNumber} -  ${itemToDelete[0].description}`}
                    description="Are you sure you want to delete this account?"
                    open={showDeletePopup && !deleting}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={() => openOrCloseDialog(false)}
                    onClickSecondary={() => openOrCloseDialog(false)}
                    onClickPrimary={onDeleteAccount}
                />
            )}
            {openCategory
            && (
                <CatalogList
                    openDialog={openCategory}
                    catalogEnum={CatalogEnum.CATEGORY}
                    catalogTitle={CatalogEnum.CATEGORY}
                    sortDirection={DataSort.ASC}
                    onClose={onClosePopup}
                />
            )}
            { openSubcategory
            && (
                <CatalogList
                    openDialog={openSubcategory}
                    catalogEnum={CatalogEnum.SUB_CATEGORY}
                    catalogTitle={CatalogEnum.SUB_CATEGORY}
                    sortDirection={DataSort.ASC}
                    onClose={onClosePopup}
                />
            )}
            <If condition={saving}><DialogActionMessage message="Saving data... " /></If>
            <If condition={deleting}><DialogActionMessage message="Deleting account... " /></If>
        </div>
    );
};

export default AccountingCOA;

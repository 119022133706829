/* eslint-disable no-param-reassign */
import React, { useReducer, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import { Form, Col, Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import PayrollSettingsMutation from 'services/graphQL/mutate/payroll/Settings';

const fields = ['AnnualGross', 'GrossPay', 'MunicipalTaxRate', 'TaxableGrossPay'];

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    containerFields: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        width: '200px',
        minWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '& label': {
            textAlign: 'center',
            fontWeight: 500,
            display: 'block',
            margin: '8px 0',
        },
    },
    listFields: {
        overflow: 'auto',
        '& li': {
            borderTop: '1px solid #e8e8e8',
            padding: theme.spacing(1, 2),
            fontSize: '14px',
            cursor: 'pointer',
        },
    },
    body: {
        overflow: 'hidden',
        display: 'flex',
        padding: theme.spacing(0.5),
    },
    textArea: {
        marginRight: theme.spacing(2),
        resize: 'none',
        minHeight: 300,
    },
    stateTaxTextArea: {
        marginRight: theme.spacing(2),
        resize: 'none',
        minHeight: 383,
    },
    textField: {
        marginRight: theme.spacing(2),
    },
    formGroup: {
        marginBottom: 0,
    },
}));

const initState = {
    userTax: '',
    stateTax: '',
    userTaxName: '',
    textFieldRef: null,
};

const ACTION_TYPES = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    const {
        type, payload, field,
    } = action;
    switch (type) {
    case ACTION_TYPES.ON_CHANGE_VALUE: {
        return update(state, {
            [field]: { $set: payload },
        });
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const { stateTax, userTax, userTaxName } = payload;
        return update(state, {
            userTax: { $set: userTax },
            stateTax: { $set: stateTax },
            userTaxName: { $set: userTaxName },
        });
    }
    default:
        return state;
    }
};

const CustomFieldsDialog = ({
    onClose, stateTax, userTax, lotName, userTaxName,
}) => {
    const classes = useStyles();
    const userTaxRef = useRef();
    const stateTaxRef = useRef();
    const [state, dispatch] = useReducer(reducer, initState);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            payload: {
                userTax,
                stateTax,
                userTaxName,
            },
        });
    }, [stateTax, userTax, userTaxName]);

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            payload: value,
        });
    };

    const [saveData, { loading: updating }] = useMutation(PayrollSettingsMutation.SAVE_PAYROLL_CUSTOM_FIELDS, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.savePayrollCustomTaxFields) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({
                    isSaving: true,
                    userTax: state.userTax,
                    stateTax: state.stateTax,
                    userTaxName: state.userTaxName,
                });
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => saveData({
        variables: {
            input: {
                lotName,
                userTax: state.userTax || '',
                stateTax: state.stateTax || '',
                userTaxName: state.userTaxName || '',
            },
        },
    });

    const insertAtCursor = (input, textToInsert) => {
        const { value } = input;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const currentValue = value.slice(0, start) + textToInsert + value.slice(end);
        input.value = currentValue;
        input.selectionEnd = start + textToInsert.length;
        input.selectionStart = input.selectionEnd;

        onChangeValue(state.textFieldRef === stateTaxRef ? 'stateTax' : 'userTax', currentValue);
    };

    const onSelectField = (value) => {
        if (state.textFieldRef) insertAtCursor(state.textFieldRef.current, value);
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                onClose={onClose}
                title="Update Custom Tax Fields"
            />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.body}>
                    <Form.Group as={Col} className={classes.formGroup}>
                        <Form.Label className={classes.labels}>State Tax Formula</Form.Label>
                        <Form.Control
                            as="textarea"
                            maxLength={500}
                            ref={stateTaxRef}
                            value={state.stateTax}
                            className={classes.stateTaxTextArea}
                            placeholder="Enter your formula"
                            onFocus={() => onChangeValue('textFieldRef', stateTaxRef)}
                            onChange={(e) => onChangeValue('stateTax', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={classes.formGroup}>
                        <Form.Group as={Row}>
                            <Form.Label className={classes.labels}>Custom Tax Name</Form.Label>
                            <Form.Control
                                value={state.userTaxName}
                                placeholder="Enter the name"
                                type="text"
                                className={classes.textField}
                                onChange={(e) => onChangeValue('userTaxName', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.formGroup}>
                            <Form.Label className={classes.labels}>Custom Tax Formula</Form.Label>
                            <Form.Control
                                as="textarea"
                                maxLength={500}
                                ref={userTaxRef}
                                value={state.userTax}
                                className={classes.textArea}
                                placeholder="Enter your formula"
                                onFocus={() => onChangeValue('textFieldRef', userTaxRef)}
                                onChange={(e) => onChangeValue('userTax', e.target.value)}
                            />
                        </Form.Group>
                    </Form.Group>
                    <div className={classes.containerFields}>
                        <Form.Label>Fields</Form.Label>
                        <ul className={classes.listFields}>
                            {fields.map((item, index) => (
                                <li
                                    key={index}
                                    onDoubleClick={() => onSelectField(item)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

            </DialogContent>
            <DialogActions
                onClickPrimary={onSave}
                onClickSecondary={onClose}
                disablePrimaryButton={updating}
            />
        </Dialog>
    );
};

CustomFieldsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    lotName: PropTypes.string.isRequired,
    userTax: PropTypes.string.isRequired,
    stateTax: PropTypes.string.isRequired,
    userTaxName: PropTypes.string.isRequired,
};

export default CustomFieldsDialog;

export const LeadTypeStyle = {
    leadType: {
        color: '#ffb300',
        verticalAlign: 'middle',
        '& > svg': {
            verticalAlign: 'middle',
            marginRight: '4px',
        },
        '& > span': {
            verticalAlign: 'middle',
        },
        '&.email': {
            color: '#e57373',
        },
        '&.phone': {
            color: '#4caf50',
        },
        '&.walkin': {
            color: '#2196f3',
        },
    },
};

export default class LeadStyles {
    static leadList(theme = {}) {
        return ({
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            listContainer: {
                flex: 1,
                overflow: 'auto',
            },
            containerFilterSearch: {
                display: 'flex',
                alignItems: 'center',
            },
            boxContainer: {
                flexDirection: 'column',
            },
            title: {
                color: theme.palette.text.boulderGray,
                display: 'inline-block',
            },
            routeButton: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            label: {
                fontSize: '15px',
                fontWeight: 500,
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(1),
                color: theme.palette.text.boulderGray,
            },
            select: {
                width: '140px',
            },
            subMenu: {
                overflow: 'auto',
                maxHeight: 196,
            },
            search: {
                width: '300px',
            },
        });
    }

    static leadListItem(theme = {}) {
        return ({
            container: {
                cursor: 'pointer',
                margin: 0,
                marginBottom: '10px',
                marginLeft: '2px',
                marginRight: '2px',
                padding: '8px',
                background: theme.palette.background.white,
                textDecoration: 'initial',
                display: 'flex',
                width: 'fit-content',
                minWidth: 'calc(100% - 4px)',
                boxShadow: '1px 1px 4px rgba(0,0,0, 0.1)',
                border: '1px solid transparent',
                position: 'relative',
            },
            link: {
                textDecoration: 'none',
                color: 'initial',
            },
            quickNote: {
                minWidth: 120,
                maxWidth: 120,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            editQuickNote: {
                minWidth: '36px',
                '& .MuiButton-startIcon': {
                    margin: 0,
                },
            },
            table: {
                borderSpacing: '3px',
            },
            tableRow: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
            },
            tableCell: {
                display: 'table-cell',
            },
            label: {
                color: `${theme.palette.text.gray}`,
                paddingRight: '5px',
                whiteSpace: 'nowrap',
                minWidth: '112px',
            },
            alignRight: {
                textAlign: 'right',
            },
            bold: {
                fontWeight: 600,
            },
            overflowHidden: {
                overflow: 'hidden',
            },
            // VehicleWidget
            column: {
                color: '#382652',
                display: 'flex',
            },
            imageContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: theme.spacing(1.2),
            },
            vehicleImageWrapper: {
                alignItems: 'center',
                maxWidth: 115,
                minWidth: 115,
                '& > img': {
                    border: `1px solid ${theme.palette.border.secondary}`,
                    maxHeight: 75,
                    height: 75,
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            stockNumberWrapper: {
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
            },
            stockNumberLabel: {
                color: theme.palette.text.scorpion,
                lineHeight: '14px',
                fontSize: 14,
                fontWeight: 400,
                fontFamily: 'Arial',
            },
            stockNumberValue: {
                fontWeight: 700,
                marginLeft: theme.spacing(0.5),
                color: theme.palette.text.waterloo,
                fontFamily: 'Arial',
                fontSize: 14,
            },
            prospect: {
                marginBottom: theme.spacing(1),
                fontWeight: 'bold',
            },
            prospectName: {
                color: theme.palette.text.waterloo,
                fontWeight: 700,
                fontFamily: 'Arial',
                fontSize: '14px',
            },
            leadCodeValue: {
                fontWeight: 700,
                marginLeft: theme.spacing(0.5),
                color: theme.palette.text.waterloo,
                fontFamily: 'Arial',
                fontSize: 14,
            },
            coProspect: {
                display: 'flex',
                'flex-direction': 'row',
                height: 16,
            },
            coProspectName: {
                lineHeight: `${theme.spacing(2.2)}px`,
                color: theme.palette.text.boulder,
                fontWeight: 500,
            },
            coProspectIcon: {
                'margin-start': '3px',
                'margin-top': '-1px',
            },
            vehicleMeta: {
                display: 'flex',
                alignItems: 'center',
                height: 16,
            },
            vehicleTextColor: {
                color: theme.palette.text.boulderGray,
            },
            ...LeadTypeStyle,
            leadType: {
                color: '#ffb300',
                verticalAlign: 'middle',
                '& > svg': {
                    verticalAlign: 'middle',
                    marginRight: '4px',
                },
                '& > span': {
                    verticalAlign: 'middle',
                },
                '&.email': {
                    color: '#e57373',
                },
                '&.phone': {
                    color: '#4caf50',
                },
                '&.walkin': {
                    color: '#2196f3',
                },
            },
            processStage: {
                color: theme.palette.text.waterloo,
                padding: '0px 6px',
                borderRadius: '2px',
                '&.new': {
                    color: '#fff',
                    background: '#707090',
                    textAlign: 'center',
                    borderRadius: theme.spacing(0.5),
                    display: 'inline-block',
                },
            },
            lastResultText: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            lotTag: {
                color: '#1e88e5',
                padding: '2px 5px',
                background: '#F4F5FD',
                borderRadius: '2px',
                fontWeight: 400,
                whiteSpace: 'nowrap',
            },
            statusBadge: {
                '&::before': {
                    content: "' '",
                    zIndex: '-1',
                    overflow: 'hidden',
                    transform: 'rotate(-135deg)',
                    width: '120px',
                    display: 'block',
                    background: '#4caf50',
                    boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
                    position: 'absolute',
                    top: '34px',
                    left: '-16px',
                    clipPath: 'polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px)',
                    height: '20px',
                },
                '&::after': {
                    content: "' '",
                    overflow: 'hidden',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: '#FFF',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    lineHeight: '20px',
                    width: '120px',
                    display: 'block',
                    background: '#4caf50',
                    boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
                    position: 'absolute',
                    top: '0px',
                    left: '-30px',
                    clipPath: 'polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px)',
                },
                '&.opened:after': {
                    content: "'Open'",
                    background: '#1e88e5',
                },
                '&.sold:after': {
                    content: "'Sold'",
                    background: '#4caf50',
                    transform: 'rotate(-45deg)',
                    top: '24px',
                },
                '&.lost:after': {
                    content: "'Lost'",
                    background: '#f44336',
                    transform: 'rotate(-45deg)',
                    top: '24px',
                },
            },
            actions: {
                marginRight: theme.spacing(3),
            },
            cellPhone: {
                marginLeft: theme.spacing(1),
            },
            lastAction: {
                marginLeft: theme.spacing(2),
            },
            newEmailLead: {
                background: '#FFFFE0',
            },
            notificationIcon: {
                color: 'red',
                fontSize: 48,
            },
            automatedResponse: {
                fontSize: '11px',
                color: theme.palette.text.white,
                fontWeight: 500,
                borderRadius: '5px',
                paddingLeft: '3px',
                paddingRight: '3px',
                paddingTop: '2px',
                lineHeight: 1.5,
                width: '111px',
                textAlign: 'center',
            },
            automatedResponseTemplates: {
                backgroundColor: theme.palette.background.mediumPurple,
            },
            automatedResponseAI: {
                backgroundColor: theme.palette.background.havelockBlue,
            },
            stages: {
                display: 'flex',
                width: '100%',
                fontSize: '13px',
                flexWrap: 'wrap',
                '& > div': {
                    margin: 0,
                    marginRight: '10px',
                    flexWrap: 'nowrap',
                    '& > label': {
                        marginBottom: 0,
                        marginRight: '5px',
                        fontWeight: 'bold',
                    },
                    '& > span': {
                        padding: 0,
                        '& svg': {
                            width: '20px',
                            height: '20px',
                        },
                    },
                },
            },
        });
    }

    static leadDetail(theme = {}) {
        return ({
            main: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                background: theme.palette.background.white,
            },
            boxContainer: {
                background: theme.palette.background.default,
            },
            boxDetails: {
                display: 'flex',
                flex: 1,
                '& .MuiGrid-container': {
                    overflow: 'auto',
                },
            },
            container: {
                display: 'flex',
                overflow: 'hidden',
                flexWrap: 'initial',
            },
            column: {
                flex: 1,
                overflow: 'auto',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            },
        });
    }
}

import React, { useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
    Col, Form, Row,
} from 'react-bootstrap';
import BasicStyles from 'styles/modules/basic/basicStyles';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import clsx from 'clsx';
import FieldsetTitle from 'components/widgets/fieldsetTitle/fieldsetTitle';
import FormControlMemorize from 'components/widgets/form/FormControlMemorize';
import LabelMemorize from 'components/widgets/form/LabelMemorize';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ServiceJobsItemSchema } from 'utils/schema/service/ServiceJobsSchema';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const ownStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
}));

const ServiceJobsMoreInfo = (props) => {
    const {
        jobItem, onChangeJobValue, editDetails, opCodesList,
    } = props;
    const classes = {
        ...useStyles(),
        ...basicFormStyle(),
        ...ownStyles(),
    };

    const {
        customerStates, cause, correction, notes,
        recommend1, laborOperationCode,
    } = jobItem;

    const { errors } = isValidSchema(ServiceJobsItemSchema, jobItem);

    const {
        editCustomerState,
        editCause,
        editCorrection,
        editNotes,
        editRecommends,
        editOperationCode,
    } = editDetails;

    const onChangeValue = useCallback((field, value) => {
        if (jobItem.value === value) return;
        jobItem[field] = value;
        onChangeJobValue(field, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { divScrollable, compactForm, removePaddingGroup } = classes;

    return (
        <Grid container spacing={0} className={clsx(divScrollable, compactForm, removePaddingGroup)}>
            <Col xs={12}>
                <FieldsetTitle>
                    <Form as={Row}>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <Form.Label className={classes.formLabelSmall}>Operation Code</Form.Label>
                            <DropdownQuery
                                name="laborOperationCode"
                                value={Number(laborOperationCode)}
                                placeHolder="select a code"
                                onChange={(name, newValue) => onChangeValue('laborOperationCode', newValue)}
                                dataSource={{
                                    localData: opCodesList,
                                    idField: 'value',
                                    descriptionField: 'label',
                                }}
                                allowEmptyLine={false}
                                disabled={!editOperationCode}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <LabelMemorize text="Customer States" />
                            <FormControlMemorize
                                as="textarea"
                                size="sm"
                                rows="2"
                                value={customerStates || ''}
                                placeholder=""
                                name="customerStates"
                                onChange={(e) => onChangeValue('customerStates', e.target.value)}
                                disabled={!editCustomerState}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <LabelMemorize text="Cause" />
                            <FormControlMemorize
                                as="textarea"
                                size="sm"
                                rows="2"
                                value={cause || ''}
                                placeholder=""
                                name="cause"
                                onChange={(e) => onChangeValue('cause', e.target.value)}
                                disabled={!editCause}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <LabelMemorize text="Correction" />
                            <FormControlMemorize
                                as="textarea"
                                size="sm"
                                rows="2"
                                value={correction || ''}
                                placeholder=""
                                name="correction"
                                onChange={(e) => onChangeValue('correction', e.target.value)}
                                disabled={!editCorrection}
                                className={isValidField(errors, 'correction') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <LabelMemorize text="Notes" />
                            <FormControlMemorize
                                as="textarea"
                                size="sm"
                                rows="2"
                                value={notes || ''}
                                placeholder=""
                                name="notes"
                                onChange={(e) => onChangeValue('notes', e.target.value)}
                                disabled={!editNotes}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={12}>
                            <LabelMemorize text="Recommends" />
                            <FormControlMemorize
                                as="textarea"
                                size="sm"
                                rows="2"
                                value={recommend1 || ''}
                                placeholder=""
                                name="recommend1"
                                onChange={(e) => onChangeValue('recommend1', e.target.value)}
                                disabled={!editRecommends}
                            />
                        </Form.Group>
                    </Form>
                </FieldsetTitle>
            </Col>
        </Grid>
    );
};

ServiceJobsMoreInfo.propTypes = {
    jobItem: PropTypes.object,
    onChangeJobValue: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifierJobsMoreInfo: PropTypes.string,
    editDetails: PropTypes.object,
    opCodesList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ServiceJobsMoreInfo.defaultProps = {
    jobItem: {},
    identifierJobsMoreInfo: '',
    editDetails: {},
};

const areEqual = (prevProps, nextProps) => prevProps.identifierJobsMoreInfo === nextProps.identifierJobsMoreInfo;
export default React.memo(ServiceJobsMoreInfo, areEqual);

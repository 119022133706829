import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import {
    makeStyles, Dialog, DialogContent, Typography, AppBar, Toolbar, Slide, Button, Avatar,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import { useLazyQuery } from '@apollo/client';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import NumberUtils from 'lib/NumberUtils';
import clsx from 'clsx';
import AccountReceivablesMapper from 'services/mapData/AccountReceivablesMapper';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { isEmpty } from 'lodash';
import Container from 'components/widgets/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SaveAltIcon } from 'components/icons';
import { Alert } from 'react-bootstrap';
import { Link as RouteLink } from 'react-router-dom';
import CashierMappingsLayout from 'components/modules/settings/accounting/CashierMappingsLayout';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DepositCreate from 'components/modules/accounting/banking/create/deposit/DepositCreate';
import { CashierDirectionOperation, CashierPaymentType } from 'utils/enum/AccountingEnum';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import PaperComponent from 'components/modules/accounting/PaperComponent';
import DialogActionMessage from 'components/widgets/DialogActionMessage';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const ACTION_TYPES = {
    SET_TABLE: 'setTable',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValue',
};

const cashierPaymentReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        const totalRecords = !isEmpty(action.value) && action.value.map((item) => item.total).reduce((prev, next) => prev + next);
        if (totalRecords === 0) action.onPopupClose();

        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    default:
        return state;
    }
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CashierBatchOut = (props) => {
    const keyStore = new KeyStore();
    const classes = useStyle();

    const {
        userName, openBatchOut, onPopupClose, userPicture, customFieldData, collectionData,
    } = props;

    const initialState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
                ignoreLimit: true,
            },
        },
        records: [],
        openDeposit: false,
        paymentType: '',
        depositData: null,
        errorsFromMapping: [],
        openSettings: false,
    };

    const [state, dispatch] = useReducer(cashierPaymentReducer, initialState);

    const {
        params, records, openDeposit, paymentType, depositData, errorsFromMapping, openSettings,
    } = state;

    const [getData, { loading }] = useLazyQuery(AccountReceivableQuery.GET_CASHIER_COLLECIONS_BY_PAYMENT_TYPE, {
        onCompleted: (res) => {
            if (res.getCashierOpenBalances) {
                const { data } = res?.getCashierOpenBalances;

                const result = AccountReceivablesMapper.mapBatchOutCollections(data, customFieldData);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: result,
                    onPopupClose,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getBachoutInformation, { loading: generatingDeposit }] = useLazyQuery(AccountReceivableQuery.GET_BATCHOUT_INFORMATION, {
        onCompleted: (res) => {
            if (res.getBachoutInformation) {
                const result = AccountReceivablesMapper.mapLinesFromBatchOutToCheck(paymentType, res.getBachoutInformation, customFieldData);

                if (result.errorMessages.length > 0) {
                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: { errorsFromMapping: result.errorMessages },
                    });
                    return;
                }

                const type = paymentType.toLowerCase() === CashierPaymentType.CUSTOM_FIELD.toLowerCase() ? customFieldData.customFieldName : paymentType;
                result.memo = `Batch out ${type} for ${userName}`;

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        depositData: result,
                        openDeposit: true,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onCloseDeposit = (success) => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { openDeposit: false } });

        if (success) {
            getData({
                variables: {
                    paginate: params.paginate,
                    filter: {
                        userName,
                        onlyActive: true,
                        direction: CashierDirectionOperation.IN,
                        date: collectionData.date,
                    },
                    sort: [
                        { fieldName: 'dateNTime', dir: DataSort.DESC },
                        { fieldName: 'TransactionNumber', dir: DataSort.ASC },
                    ],
                },
            });
        }
    };

    useEffect(() => {
        if (userName && !isEmpty(userName)) {
            dispatch({ type: ACTION_TYPES.SET_TABLE, value: [] });

            getData({
                variables: {
                    paginate: params.paginate,
                    filter: {
                        userName,
                        onlyActive: true,
                        direction: CashierDirectionOperation.IN,
                        date: collectionData.date,
                    },
                    sort: [
                        { fieldName: 'dateNTime', dir: DataSort.DESC },
                        { fieldName: 'TransactionNumber', dir: DataSort.ASC },
                    ],
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName, collectionData.date]);

    const onCreateDeposit = (paymentMethod) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                paymentType: paymentMethod,
                errorsFromMapping: '',
            },
        });

        getBachoutInformation({
            variables: {
                filter: {
                    userName,
                    paymentType: paymentMethod,
                    date: collectionData?.date ?? null,
                },
            },
        });
    };

    const onCloseSettings = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openSettings: false,
                errorsFromMapping: [],
            },
        });
    };

    const columns = [
        {
            Header: 'Receipt #',
            minWidth: 100,
            width: 100,
            id: 'TransactionNumber',
            accessor: 'transactionNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Account #',
            minWidth: 100,
            width: 100,
            id: 'accountNumber',
            accessor: 'accountNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.alignCenter),
            className: clsx(classes.columnStyle, classes.alignCenter),
        },
        {
            Header: 'Date',
            minWidth: 100,
            width: 100,
            id: 'Date',
            accessor: 'date',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
        },
        {
            Header: 'Customer',
            minWidth: 160,
            id: 'Customer',
            accessor: 'customer',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Reason',
            minWidth: 200,
            width: 200,
            id: 'Reason',
            accessor: 'reason',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Amount',
            minWidth: 100,
            width: 100,
            id: 'Amount',
            accessor: 'amount',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
    ];

    return (
        <>
            <Dialog
                open={openBatchOut}
                onClose={onPopupClose}
                maxWidth="md"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                TransitionComponent={Transition}
                PaperComponent={PaperComponent}
            >
                <AppBar className={classes.appBarMove}>
                    <Toolbar className={classes.centerItems}>
                        <Avatar className={classes.small} src={userPicture} />
                        <Typography variant="h6" className={classes.title}>
                        &nbsp;&nbsp;&nbsp;
                            {`Batching :  ${userName}`}
                        </Typography>
                        <div className={classes.centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.noPaddingSides}>
                    { loading ? <div className={classes.columnCenter}><CircularProgress /></div>
                        : (
                            <Container className={classes.containerSplit}>
                                {
                                    records.filter((item) => item.total > 0).map((item, index) => (
                                        <div className={classes.tablePanel} key={index}>
                                            <div className={classes.toolbar}>
                                                <Typography
                                                    variant="h6"
                                                    color="primary"
                                                >
                                                    {`${item.description} :  ${NumberUtils.applyCurrencyFormat(item?.total || 0)}`}
                                                </Typography>
                                                <div className={classes.buttonSpacing}>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<SaveAltIcon />}
                                                        onClick={() => onCreateDeposit(item.paymentType)}
                                                        disabled={loading}
                                                        size="small"
                                                    >
                                                        Create Deposit
                                                    </Button>
                                                </div>
                                            </div>
                                            { paymentType.toLowerCase() === item.paymentType.toLowerCase() && errorsFromMapping.length > 0 && (
                                                <Alert className={classes.alert} variant="warning">
                                                    { errorsFromMapping.join('. ') }
                                                    &nbsp;&nbsp;
                                                    {keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_WRITE)
                                                    && (
                                                        <RouteLink
                                                            to="#"
                                                            onClick={() => dispatch({
                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                value: { openSettings: true },
                                                            })}
                                                        >
                                                            Click here to mapping
                                                        </RouteLink>
                                                    )}
                                                </Alert>
                                            )}
                                            <Table
                                                rowSelected
                                                cursor="default"
                                                load={loading}
                                                totalRecords={item?.total || 0}
                                                data={loading ? [] : item.data}
                                                columns={columns}
                                                className="-highlight"
                                            />
                                        </div>
                                    ))
                                }
                            </Container>
                        )}
                </DialogContent>
            </Dialog>
            {openDeposit && (
                <DepositCreate
                    comeFromBatchOut
                    onCloseDeposit={(success) => onCloseDeposit(success)}
                    depositData={depositData}
                />
            )}
            {
                openSettings
                && (
                    <Dialog
                        open={openSettings}
                        onClose={onCloseSettings}
                        maxWidth="lg"
                        fullWidth
                        disableBackdropClick
                        disableEscapeKeyDown
                        scroll="paper"
                        TransitionComponent={Transition}
                        PaperComponent={PaperComponent}
                    >
                        <AppBar className={classes.appBarMove}>
                            <Toolbar className={classes.centerItems}>
                                <Typography variant="h6" className={classes.title}>
                                    Cashier Mappings
                                </Typography>
                                <div className={classes.centerItems}>
                                    <IconButton edge="start" color="inherit" onClick={onCloseSettings}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <DialogContent className={classes.noPaddingSides}>
                            <CashierMappingsLayout />
                        </DialogContent>
                    </Dialog>
                )
            }
            {generatingDeposit && <DialogActionMessage message="Getting information to create deposit... " />}
        </>
    );
};

CashierBatchOut.propTypes = {
    userName: PropTypes.string,
    userPicture: PropTypes.string,
    openBatchOut: PropTypes.bool,
    onPopupClose: PropTypes.func,
    customFieldData: PropTypes.shape({
        displayCustomField: PropTypes.bool,
        customFieldName: PropTypes.string,
        hasCustomFieldValues: PropTypes.bool,
    }).isRequired,
    collectionData: PropTypes.object,
};

CashierBatchOut.defaultProps = {
    userName: '',
    userPicture: '',
    openBatchOut: false,
    onPopupClose: () => {},
    collectionData: null,
};

export default CashierBatchOut;

export default class SideBarStyles {
    static main({ theme = {}, colors }) {
        const {
            headerBackgroundColor, headerFontColor,
            backgroundColor, fontColor, hoverFontColor,
            selectionBackgroundColor, selectionFontColor,
        } = colors;
        return ({
            menuCollapsed: {
                margin: '5px 25px',
                cursor: 'pointer',
            },
            menuOpened: {
                textAlign: 'right',
                margin: '5px 10px',
                cursor: 'pointer',
            },
            mobileMenuShow: {
                zIndex: '1111',
            },
            menuActive: {
                backgroundColor: `${selectionBackgroundColor} !important`,
                color: `${selectionFontColor} !important`,
                '& div > span > span > svg > g > g > *': {
                    stroke: selectionFontColor,
                },
                '& div > span > span > svg > g > g > g > *': {
                    stroke: selectionFontColor,
                },
                '& div svg path': {
                    fill: selectionFontColor,
                },
            },
            subMenuActive: {
                '& > div > span': {
                    color: selectionFontColor,
                },
                '& > div > span > span > svg > g > g > *': {
                    stroke: selectionFontColor,
                },
                '& > div > span > span > svg > g > g > g > *': {
                    stroke: selectionFontColor,
                },
                '& > div > span > span > svg > path': {
                    fill: selectionFontColor,
                },
                '& > div > span > span > svg > g > path': {
                    fill: selectionFontColor,
                },
                '& > div > span > .pro-arrow': {
                    borderColor: `${selectionFontColor} !important`,
                },
            },
            navHeaderClass: {
                '& > div': {
                    display: 'flex',
                    fontSize: '12px',
                    padding: '8px 0px',
                    maxHeight: '100px',
                    color: headerFontColor,
                    borderBottom: 'none !important',
                    backgroundColor: headerBackgroundColor,
                },
                '& > div:nth-child(2)': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '-9px',
                },
            },
            displayRow: {
                flexDirection: 'row !important',
                '& > div': {
                    marginRight: '10px',
                },
            },
            userAvatar: {
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                width: '38px',
                height: '38px',
                cursor: 'pointer',
            },
            icon: {
                '& > g > g > *': {
                    stroke: fontColor,
                },
                '& > g > g > g > *': {
                    stroke: fontColor,
                },
            },
            contactContainer: {
                margin: theme.spacing(1, 0),
                '& span': {
                    margin: '0px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '12px',
                    lineHeight: 1.5,
                    fontWeight: 'bold',
                },
                '& > span:nth-child(1)': {
                    fontSize: '15px',
                },
            },
            customIcon: {
                display: 'block',
                width: 20,
                height: 20,
                '& path, & g path': {
                    fill: fontColor,
                },
            },
            supportIcon: {
                fill: theme.palette.background.lightGreen,
            },
            videoCallIcon: {
                fill: theme.palette.background.orangePeel,
            },
            helpIcon: {
                fill: theme.palette.background.butterCup,
            },
            menuHover: {
                '&:hover > div > span > span > svg > g > g > *': {
                    stroke: hoverFontColor,
                },
                '&:hover > div > span > span > svg > g > g > g > *': {
                    stroke: hoverFontColor,
                },
                '&:hover > div > span > span > svg > path, &:hover > div > span > span > svg > circle': {
                    fill: hoverFontColor,
                },
                '&:hover div svg path': {
                    fill: hoverFontColor,
                },
                '&:hover > div > .pro-item-content': {
                    color: hoverFontColor,
                },
                backgroundColor,
            },
            subMenuHover: {
                '&:hover > div > span > span > svg > path': {
                    fill: hoverFontColor,
                },
                '&:hover > div > span > span > svg > g > path': {
                    fill: hoverFontColor,
                },
                '&:hover > div > span > span > svg > g > g > *': {
                    stroke: hoverFontColor,
                },
                '&:hover > div > span > span > svg > g > g > g > *': {
                    stroke: hoverFontColor,
                },
                '&:hover > div > span > .pro-arrow': {
                    borderColor: `${hoverFontColor} !important`,
                },
                '& > div > .popper-inner, & > div > .popper-arrow': {
                    backgroundColor: `${backgroundColor} !important`,
                },
                '& > .react-slidedown': {
                    backgroundColor: `${backgroundColor} !important`,
                },
                '& > div > .popper-arrow': {
                    borderLeft: `7px solid ${backgroundColor} !important`,
                    backgroundColor: 'transparent !important',
                },
                backgroundColor,
                '& > div > span > .pro-arrow': {
                    borderColor: `${fontColor} !important`,
                },
                '&:hover > div > .pro-item-content': {
                    color: hoverFontColor,
                },
            },
            contentSideBar: {
                backgroundColor,
                color: fontColor,
            },
            footerSideBar: {
                backgroundColor,
                color: fontColor,
            },
        });
    }
}

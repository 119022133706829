import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';
import InputSearch from 'components/widgets/InputSearch';

const PayrollSearch = ({
    classes,
    counter,
    onSearch,
    searchValue,
    counterLabel,
}) => (
    <>
        <div className={classes.searchBox}>
            <InputSearch
                initialSearch={searchValue || ''}
                onSearch={onSearch}
                size="sm"
            />
        </div>
        <div className={classes.itemsCounter}>
            <FormLabel>
                {`${counterLabel}: ${numeral(counter).format('0,0')}`}
            </FormLabel>
        </div>
    </>
);

PayrollSearch.propTypes = {
    counter: PropTypes.number,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    counterLabel: PropTypes.string.isRequired,
};

PayrollSearch.defaultProps = {
    counter: 0,
    searchValue: '',
};

export default PayrollSearch;

export default class EnumCatalogMap {
    static enumCatalog(records) {
        if (records) {
            return records.map((data, index) => (
                {
                    lineId: index + 1,
                    enumValuesId: data.enumValuesId,
                    description: data.description,
                    lotName: data.lotName,
                    isCore: data.isCore,
                }
            ));
        }

        return [];
    }

    static enumCatalogToSave(records) {
        if (records) {
            return records.map((data) => (
                {
                    enumValuesId: data.enumValuesId,
                    description: data.description,
                    lotName: data.lotName,
                    type: data.type,
                }
            ));
        }

        return [];
    }
}

import { gql } from '@apollo/client';

export default class NotesQuery {
    static GET_INVENTORY_NOTES = gql`
        query getInventoryNotes($stockNumber: Int!) {
            getInventoryNotes(stockNumber: $stockNumber) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;

    static GET_DEAL_NOTES = gql`
        query getDealNotes($dealId: Int!) {
            getDealNotes(dealId: $dealId) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;

    static GET_OPPORTUNITY_NOTES = gql`
        query getOpportunityNotes($opportunityId: ID!) {
            getOpportunityNotes(opportunityId: $opportunityId) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;

    static GET_CUSTOMER_NOTES = gql`
        query getCustomerNotes($customerCode: Int!) {
            getCustomerNotes(customerCode: $customerCode) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;

    static GET_PORTFOLIO_NOTES = gql`
        query getPortfolioNotes($portfolioId: Int!) {
            getPortfolioNotes(portfolioId: $portfolioId) {
                noteUser
                noteDate
                noteText
                client
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import { ImportCustomer } from 'utils/enum/DealEnum';

// GraphQL
import DealService from 'services/modules/DealService';

const NeoCustomer = (WrappedComponent) => class extends Component {
    static propTypes = {
        onSelectRow: PropTypes.func.isRequired,
        excludeBusiness: PropTypes.bool,
        title: PropTypes.string,
    }

    static defaultProps = {
        excludeBusiness: false,
        title: 'NEO Customer Lookup',
    }

    constructor(props) {
        super(props);
        this.dealServices = new DealService();

        this.state = {
            records: [],
            isLoading: false,
            totalCount: 0,
            selectedRecord: null,
        };
        this.initBind();
    }

    onSearch(searchTerm) {
        const { state: { previousSearchTerm } } = this;
        if (searchTerm && (searchTerm === previousSearchTerm)) return;

        this.setState({ searchTerm });

        if (!StringUtils.isEmpty(searchTerm)) {
            this.getServicesData(searchTerm, true);
        } else {
            this.clearList();
        }
    }

    onClickRecord(record) {
        this.setState({ selectedRecord: record });
    }

    onClickPrimary() {
        const { props: { onSelectRow }, state: { selectedRecord } } = this;

        if (selectedRecord) {
            onSelectRow(selectedRecord);
        }
    }

    getServicesData(searchTerm, isSearching = false) {
        const { records } = this.state;
        const { title } = this.props;
        let init = records.length;
        const isNeo = !title?.includes(ImportCustomer.MAGILOOP);

        if (isSearching) {
            init = 0;
            this.setState({
                records: [],
                totalCount: 0,
            });
        }

        const input = {
            isNeo,
            filter: searchTerm,
            init,
            limit: 50,
        };

        this.setState({ isLoading: true, previousSearchTerm: searchTerm });
        this.dealServices.getDealFromNEO(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getDealFromNEO) {
                    const { getDealFromNEO } = data;

                    if (getDealFromNEO.totalCount === 0 && isNeo) {
                        this.refetchNeo();
                    }

                    this.setState((prevState) => ({
                        records: update(prevState.records, { $push: getDealFromNEO.data }),
                        totalCount: getDealFromNEO.totalCount,
                    }));
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    async refetchNeo() {
        ModalUtils.infoMessage(null, 'Record not found, neo information will be updated.');
        this.dealServices.refetchNeo()
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }
                ModalUtils.successMessage(null, 'Neo database was update, please search again.');
            });
    }

    loadMore() {
        const { searchTerm } = this.state;
        this.getServicesData(searchTerm);
    }

    clearList() {
        const { state: { records } } = this;

        if (records.length > 0) {
            this.setState({
                records: [],
                totalCount: 0,
                previousSearchTerm: '',
            });
        }
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clearList = this.clearList.bind(this);
        this.onClickRecord = this.onClickRecord.bind(this);
        this.onClickPrimary = this.onClickPrimary.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSearch={this.onSearch}
                loadMore={this.loadMore}
                onClickRecord={this.onClickRecord}
                onClickPrimary={this.onClickPrimary}
            />
        );
    }
};

export default NeoCustomer;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import ButtonStyles from 'styles/theme/Button';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { Button, makeStyles } from '@material-ui/core';

// Components
import VirtualTable from 'components/widgets/VirtualTable';
import If from 'components/widgets/conditional/If';

// Style
import QueriesBaseLayoutStyles from 'styles/modules/queries/QueriesBaseLayoutStyles';

const useStyles = makeStyles((theme) => QueriesBaseLayoutStyles.previewList(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const DataPreviewList = ({ data, actionExport }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const records = data.records || [];

    let headers = [];
    if (records.length > 0) {
        const firstRecord = records[0];
        headers = firstRecord.map((column) => column.name);
    }

    const remap = records.map((record) => {
        const output = {};
        record.forEach((column) => {
            let value = column.value === 'null' ? '' : column.value;
            if (value.includes('GMT')) value = DateUtils.format(new Date(value));

            output[column.name.toLowerCase()] = value;
        });

        return output;
    });

    const { innerWidth } = window;
    const columnWidth = 150;
    let tableWidth = headers.length * columnWidth;
    if (innerWidth > tableWidth) tableWidth = innerWidth;

    const getColumns = () => headers.map((header) => (
        {
            headerClassName: classes.tableHeader,
            dataKey: header?.toLowerCase(),
            label: header?.toUpperCase(),
            width: columnWidth,
        }
    ));

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerLeft}>
                    {`Preview Data | Results Found: ${remap.length}`}
                </div>
                <div className={classes.headerRight}>
                    <Button
                        className={clsx(classes.containedInfo, classes.actionExport)}
                        disabled={remap.length <= 0}
                        size="small"
                        onClick={actionExport}
                        startIcon={<ReceiptOutlinedIcon />}
                    >
                        Export to Excel
                    </Button>
                </div>
            </div>
            <If condition={remap.length > 0}>
                <VirtualTable
                    loading={false}
                    data={remap}
                    columns={getColumns()}
                    headerHeight={20}
                    rowHeight={20}
                    width={tableWidth}
                />
            </If>
        </div>
    );
};

DataPreviewList.defaultProps = {
    data: {},
};

DataPreviewList.propTypes = {
    data: PropTypes.object,
    actionExport: PropTypes.func.isRequired,
};

export default DataPreviewList;

import React, { PureComponent } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PhoneUtils from 'lib/PhoneUtils';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import { withStyles } from '@material-ui/core/styles';
import LabelValue from 'components/widgets/LabelValue';
import MultipleLineValues from 'components/widgets/customer/MultipleLineValues';

// Material UI
import { Grid } from '@material-ui/core';

const styles = (theme) => ({
    boxAddressContent: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none',
        },
    },
});

class AddressContent extends PureComponent {
    render() {
        const {
            props: {
                record, className, classes, rightSection,
                classNameFooter,
            },
        } = this;
        const {
            phone, housingStatus, mortgageOrRent, address1, address2,
            city, state, zipCode, country, county, totalMonths,
        } = record;
        const currentCity = StringUtils.isEmpty(city) ? '' : `${city},`;
        const address = [
            address1,
            address2,
            `${currentCity} ${state || ''}`,
            zipCode,
            county,
            country,
        ];

        const years = Math.floor((totalMonths || 0) / 12);
        const months = Math.floor((totalMonths || 0) % 12);
        const yearString = `${years} year + `;
        const period = `${years > 0 ? yearString : ''} ${months} months`;

        return (
            <div className={clsx(classes.boxAddressContent, className)}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={6}
                    >
                        <MultipleLineValues
                            label="Address"
                            values={address}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <LabelValue
                            label="Phone Number"
                            value={PhoneUtils.formatPhone(phone)}
                        />
                        <LabelValue
                            label="Housing Status"
                            value={housingStatus}
                        />
                        <LabelValue
                            label="Rent/Morgage"
                            value={NumberUtils.applyCurrencyFormat(mortgageOrRent)}
                        />
                        <LabelValue
                            label="Period"
                            value={period}
                        />
                    </Grid>
                </Grid>
                {rightSection && (<div className={classNameFooter || ''}>{rightSection}</div>)}
            </div>
        );
    }
}

AddressContent.propTypes = {
    record: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        county: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
        housingStatus: PropTypes.string,
        mortgageOrRent: PropTypes.number,
        totalMonths: PropTypes.number,
    }),
    rightSection: PropTypes.node,
    className: PropTypes.string,
    classNameFooter: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

AddressContent.defaultProps = {
    record: {},
    className: '',
    rightSection: null,
    classNameFooter: '',
};

export default withStyles(styles)(AddressContent);

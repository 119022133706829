import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Dialog, DialogContent,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { CirclePicker } from 'react-color';
import { Col, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import TagMutation from 'services/graphQL/mutate/crm/TagMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    appBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    labels: {
        fontSize: '14px',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
}));
const colors = ['#b80000', '#db3e00', '#fccb00', '#008b02', '#006b76', '#1273de',
    '#004dcf', '#5300eb', '#eb9694', '#fad0c3', '#fef3bd', '#c1e1c5',
    '#bedadc', '#c4def6', '#bed3f3', '#d4c4fb'];

const initState = {
    name: '',
    description: '',
    priority: '',
    color: '#b80000',
};

const ACTION_TYPES = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
    ON_CHANGE_TEMPLATE_TYPE: 'onChangeTemplateType',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.ON_CHANGE_VALUE:

        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const record = action.payload;
        return update(state, {
            name: { $set: record.name },
            description: { $set: record.description },
            priority: { $set: record.priority },
            tagId: { $set: record.tagId },
            color: { $set: record.color },
        });
    }
    default:
        return state;
    }
};

const TagDialog = ({
    open, onClose, refetch, record,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, initState);
    const [createTag, { loading: saving }] = useMutation(TagMutation.CREATE_CRM_TAG);
    const [updateTag, { loading: updating }] = useMutation(TagMutation.UPDATE_CRM_TAG);

    const onCreate = async () => {
        try {
            const response = await createTag({ variables: { input: state } });

            if (!isEmpty(response.data?.createCRMTag)) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    useEffect(() => {
        if (record) {
            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                payload: record,
            });
        }
    }, [record]);

    const onUpdate = async () => {
        try {
            const { tagId, ...rest } = state;
            const response = await updateTag({ variables: { input: rest, tagId } });

            if (!isEmpty(response.data?.updateCRMTag)) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            value,
        });
    };

    const priorityOptions = ['High', 'Medium', 'Low', 'Informational']
        .map((item) => ({
            value: item,
            label: item,
        }));
    const isValidData = StringUtils.isEmpty(state.name) || StringUtils.isEmpty(state.description) || StringUtils.isEmpty(state.priority);
    const title = `${isEmpty(record) ? 'New' : 'Edit'} Tag`;

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogAppBar title={title} onClose={onClose} iconSize="sm" />
            <DialogContent>
                <div className={classes.content}>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Name (10 Chars Max)</Form.Label>
                        <Form.Control
                            maxLength="10"
                            className={clsx(classes.input, { 'invalid-field': StringUtils.isEmpty(state.name) })}
                            type="text"
                            name="name"
                            value={state.name}
                            onChange={(e) => onChangeValue(e.target.name, e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Description</Form.Label>
                        <Form.Control
                            className={clsx(classes.input, { 'invalid-field': StringUtils.isEmpty(state.description) })}
                            as="textarea"
                            name="description"
                            value={state.description}
                            onChange={(e) => onChangeValue(e.target.name, e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Priority</Form.Label>
                        <Select
                            nowrap
                            className={clsx(classes.input, { 'invalid-field': StringUtils.isEmpty(state.priority) })}
                            name="priority"
                            onChange={(name, value) => onChangeValue(name, value)}
                            value={state.priority}
                            options={priorityOptions}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Color</Form.Label>
                        <CirclePicker
                            triangle="hide"
                            className={`_${state.color.slice(1, 7)}`}
                            color={state.color}
                            colors={colors}
                            onChange={(color) => onChangeValue('color', color.hex)}
                        />
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                disablePrimaryButton={isValidData || saving || updating}
                onClickPrimary={isEmpty(record) ? onCreate : onUpdate}
            />
        </Dialog>
    );
};

TagDialog.propTypes = {
    onClose: PropTypes.func,
    refetch: PropTypes.func,
    record: PropTypes.object,
    open: PropTypes.bool.isRequired,
};

TagDialog.defaultProps = {
    onClose: () => {},
    refetch: () => {},
    record: null,
};

export default TagDialog;

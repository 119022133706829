import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import CountryUtils from 'utils/CountryUtils';
import Select from 'components/widgets/Select';
import NumberFormat from 'react-number-format';
import RelationUtils from 'utils/RelationUtils';
import DealStyles from 'styles/modules/DealStyles';
import PhoneInput from 'components/widgets/form/PhoneInput';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ReferenceDialogContainer from 'components/containers/deals/read/buyer/ReferenceDialogContainer';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ReferenceSchema } from 'utils/schema/deal/Reference';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';

const styles = (theme) => DealStyles.referenceDialog(theme);

class ReferenceDialog extends Component {
    render() {
        const {
            props: {
                classes, onSave, open, onClose, onChangeZipCode,
                onChangeValue, onChangeCity, isEditing, record,
                cities, onEnableDecoder, isDecodingZip, isLoading,
            },
        } = this;
        const {
            firstName, lastName, phoneNo, county, state,
            relation, address, address2, email, zip, city,
            country,
        } = record;
        const prefixTitle = isEditing ? 'Edit' : 'New';

        const currentCities = cities.map((item) => ({ value: item, label: item }));
        if (currentCities.length > 1) currentCities.unshift({ label: 'None', value: '' });

        const isValidData = isValidSchema(ReferenceSchema, record);
        const { isValid, errors } = isValidData;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Reference`} onClose={onClose} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={6} className="am-form">
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'firstName') ? 'invalid-field' : ''}
                                    value={firstName}
                                    type="text"
                                    placeholder="Placeholder"
                                    onChange={(e) => onChangeValue('firstName', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'lastName') ? 'invalid-field' : ''}
                                    value={lastName}
                                    type="text"
                                    placeholder="Placeholder"
                                    onChange={(e) => onChangeValue('lastName', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Relation</Form.Label>
                                <Select
                                    name="relation"
                                    value={relation}
                                    options={RelationUtils.getRelation()}
                                    placeholder="Select"
                                    className="select-bootstrap"
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Cell Phone</Form.Label>
                                <PhoneInput
                                    value={phoneNo}
                                    onChange={(value) => onChangeValue('phoneNo', value)}
                                    className={isValidField(errors, 'phoneNo') ? 'invalid-field' : ''}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={email}
                                    type="text"
                                    placeholder="Placeholder"
                                    onChange={(e) => onChangeValue('email', e.target.value)}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control
                                    className={isValidField(errors, 'address') ? 'invalid-field' : ''}
                                    value={address}
                                    type="text"
                                    placeholder="Placeholder"
                                    onChange={(e) => onChangeValue('address', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                    value={address2}
                                    type="text"
                                    placeholder="Placeholder"
                                    onChange={(e) => onChangeValue('address2', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.marginBottom0}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Zip Code</Form.Label>
                                        <NumberFormat
                                            className={clsx('form-control', isValidField(errors, 'zip') ? 'invalid-field' : '')}
                                            disabled={isDecodingZip || isLoading}
                                            onValueChange={({ value }) => onChangeZipCode(value)}
                                            value={zip}
                                            placeholder="Placeholder"
                                            format="#####"
                                            onFocus={onEnableDecoder}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>City</Form.Label>
                                        <Select
                                            name="city"
                                            allowToCreate
                                            value={city}
                                            options={currentCities}
                                            placeholder="Select"
                                            className="basic-multi-select select-bootstrap"
                                            maxMenuHeight={170}
                                            onChange={onChangeCity}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} className={classes.marginBottom0}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>County</Form.Label>
                                        <Form.Control
                                            value={county}
                                            type="text"
                                            placeholder="Placeholder"
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            value={state}
                                            type="text"
                                            placeholder="Placeholder"
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    value={country}
                                    options={CountryUtils.getCountry()}
                                    placeholder="Select"
                                    className="basic-multi-select select-bootstrap"
                                    maxMenuHeight={170}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    disablePrimaryButton={!isValid}
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSave(record)}
                />
            </Dialog>
        );
    }
}

ReferenceDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    onChangeZipCode: PropTypes.func.isRequired,
    record: PropTypes.shape({
        dealReferenceId: PropTypes.number,
        accountNumber: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNo: PropTypes.string,
        relation: PropTypes.string,
        address: PropTypes.string,
        address2: PropTypes.string,
        email: PropTypes.string,
        zip: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        county: PropTypes.string,
        country: PropTypes.string,
    }),
    isEditing: PropTypes.bool.isRequired,
    isDecodingZip: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    cities: PropTypes.array.isRequired,
};

ReferenceDialog.defaultProps = {
    record: {},
};

export default withStyles(styles)(ReferenceDialogContainer(ReferenceDialog));

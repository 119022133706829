import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import PortfolioSettingsStyles from 'styles/modules/settings/PortfolioSettingsStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import PortfolioSettingsDialogContainer from 'components/containers/settings/portfolio/PortfolioSettingsDialogContainer';
import InputNumber from 'components/widgets/InputNumber';
import NumberFormat from 'react-number-format';
import If from 'components/widgets/conditional/If';
import PhoneInput from 'components/widgets/form/PhoneInput';
import moment from 'moment';

import { isValidField, isValidSchema } from 'utils/schema/utils';
import VendorUtils from 'utils/VendorUtils';
import PortfolioUtils from 'utils/PortfolioUtils';
import { PortfolioSettingSchema } from 'utils/schema/settings/PortfolioSettings';
import PortfolioTabs from 'utils/enum/PortfolioEnum';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PortfolioHistoryTab from 'components/modules/settings/bphp/portfolio/PortfolioHistoryTab';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, TextField, Tabs, Tab, Box,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

const styles = (theme) => PortfolioSettingsStyles.referenceDialog(theme);

class PortfolioSettingsDialog extends Component {
    getStates() {
        const fields = VendorUtils.getVendorStates();
        return fields.map((item) => ({ value: item, label: item }));
    }

    getCreditCompanies() {
        const {
            props: {
                listCreditCompany, record: {
                    creditReportingCompanyId,
                },
            },
        } = this;

        const listCreditCompanyActive = listCreditCompany.filter(
            (item) => item.active
                || item.creditReportingId === creditReportingCompanyId,
        );

        return listCreditCompanyActive.map((item) => ({ value: item.creditReportingId, label: item.furnisherName }));
    }

    getPortfolioOptions(field) {
        let fields = [];

        if (field === 'ownerCompany') {
            fields = PortfolioUtils.getPortfolioOwnerCompanies();
        } else if (field === 'accountingClass') {
            fields = PortfolioUtils.getPortfolioAccountingClasses();
        } else if (field === 'type') {
            fields = PortfolioUtils.getPortfolioTypes();
        } else if (field === 'booleans') {
            fields = PortfolioUtils.getPortfolioBooleanOptions();
        }

        return fields.map((item) => ({ value: item.text, label: item.text }));
    }

    getBooleanValue(value) {
        return value === true || value === 'Yes' ? 'Yes' : 'No';
    }

    render() {
        const {
            props: {
                classes, onSave, open, onClose,
                onChangeValue, isEditing, record,
                onEnableDecoder, onTabChange, selectedTab,
            },
        } = this;
        const prefixTitle = isEditing ? 'Edit' : 'New';

        const isValidData = isValidSchema(PortfolioSettingSchema, record);
        const { isValid, errors } = isValidData;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Portfolio`} onClose={onClose} iconSize="sm" />
                <DialogContent>
                    <Tabs
                        value={selectedTab}
                        onChange={onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                    >
                        <Tab label="Detail" />
                        {isEditing
                            && (
                                <Tab label="Notes" />
                            )}
                    </Tabs>
                    <If condition={selectedTab === PortfolioTabs.DETAIL}>
                        <Box className={classes.dialogContent}>
                            <Grid container spacing={1} className="am-form">
                                <Grid item xs={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>What Bookkeeping Company Owns This Portfolio</Form.Label>
                                        <Select
                                            name="ownerCompany"
                                            value={record.ownerCompany}
                                            options={this.getPortfolioOptions('ownerCompany')}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Accounting Class</Form.Label>
                                        <Select
                                            name="accountingClass"
                                            value={record.accountingClass}
                                            options={this.getPortfolioOptions('accountingClass')}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Portfolio Type</Form.Label>
                                        <Select
                                            name="type"
                                            value={record.type}
                                            options={this.getPortfolioOptions('type')}
                                            placeholder="Select"
                                            className={isValidField(errors, 'type') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Portfolio Description</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'description') ? 'invalid-field' : ''}
                                            value={record.description}
                                            type="text"
                                            onChange={(e) => onChangeValue('description', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Portfolio Name</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'name') ? 'invalid-field' : ''}
                                            value={record.name}
                                            type="text"
                                            onChange={(e) => onChangeValue('name', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Origination Date</Form.Label>
                                        <DatePicker
                                            selected={isEmpty(record.originationDate) ? '' : moment(record.originationDate, 'YYYY-MM-DD').toDate()}
                                            size="sm"
                                            name="originationDate"
                                            className="form-control"
                                            wrapperClassName={classes.wrapperDate}
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(value) => onChangeValue('originationDate', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Portfolio Actively Used</Form.Label>
                                        <Select
                                            name="activelyUsed"
                                            value={this.getBooleanValue(record.activelyUsed)}
                                            options={this.getPortfolioOptions('booleans')}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Portfolio Serviced In House</Form.Label>
                                        <Select
                                            name="inHouse"
                                            value={this.getBooleanValue(record.inHouse)}
                                            options={this.getPortfolioOptions('booleans')}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Use Stock number as Loan Number</Form.Label>
                                        <Select
                                            name="stockIsLoanNumber"
                                            value={this.getBooleanValue(record.stockIsLoanNumber)}
                                            options={this.getPortfolioOptions('booleans')}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loan Number Prefix</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'loanNumberPrefix') ? 'invalid-field' : ''}
                                            value={record.loanNumberPrefix}
                                            type="number"
                                            onChange={(e) => onChangeValue('loanNumberPrefix', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Next Loan Number</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'nextLoanNumber') ? 'invalid-field' : ''}
                                            value={record.nextLoanNumber}
                                            type="number"
                                            onChange={(e) => onChangeValue('nextLoanNumber', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.subtitle}>Convenience Fees</Form.Label>
                                        <div className={classes.legend} />
                                    </Form.Group>
                                    <Grid container spacing={1} className="am-form">
                                        <Grid item xs={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Credit Card</Form.Label>
                                                <InputNumber
                                                    // placeholder="0"
                                                    decimalScale={2}
                                                    value={record.convenienceFee}
                                                    onChange={(value) => onChangeValue('convenienceFee', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Form.Group as={Col}>
                                                <Form.Label>ACH</Form.Label>
                                                <InputNumber
                                                    // placeholder="0"
                                                    decimalScale={2}
                                                    value={record.achConvenienceFee}
                                                    onChange={(value) => onChangeValue('achConvenienceFee', value)}
                                                />
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Address Name</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'addressName') ? 'invalid-field' : ''}
                                            value={record.addressName}
                                            type="text"
                                            onChange={(e) => onChangeValue('addressName', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Text Name</Form.Label>
                                        <Form.Control
                                            value={record.textName}
                                            type="text"
                                            onChange={(e) => onChangeValue('textName', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Address 1</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'address1') ? 'invalid-field' : ''}
                                            value={record.address1}
                                            type="text"
                                            onChange={(e) => onChangeValue('address1', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control
                                            value={record.address2}
                                            type="text"
                                            onChange={(e) => onChangeValue('address2', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Zip</Form.Label>
                                        <NumberFormat
                                            className={isValidField(errors, 'zip') ? 'form-control invalid-field' : 'form-control'}
                                            value={record.zip}
                                            placeholder="Placeholder"
                                            format="#####"
                                            onFocus={onEnableDecoder}
                                            onValueChange={({ value }) => onChangeValue('zip', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'city') ? 'invalid-field' : ''}
                                            value={record.city}
                                            type="text"
                                            onChange={(e) => onChangeValue('city', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Select
                                            name="state"
                                            value={record.state}
                                            options={this.getStates()}
                                            placeholder="Select"
                                            className={isValidField(errors, 'state') ? 'select-bootstrap invalid-field' : 'select-bootstrap'}
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone</Form.Label>
                                        <PhoneInput
                                            size="sm"
                                            value={record.phone}
                                            className={isValidField(errors, 'phone') ? 'invalid-field' : ''}
                                            onChange={(value) => onChangeValue('phone', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Fax</Form.Label>
                                        <PhoneInput
                                            size="sm"
                                            value={record.fax}
                                            onChange={(value) => onChangeValue('fax', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control
                                            className={isValidField(errors, 'website') ? 'invalid-field' : ''}
                                            value={record.website}
                                            type="text"
                                            onChange={(e) => onChangeValue('website', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Pmt Receipt Message</Form.Label>
                                        <TextField
                                            multiline
                                            fullWidth
                                            rows={3}
                                            variant="outlined"
                                            value={record.receiptMessage}
                                            onChange={(e) => onChangeValue('receiptMessage', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Credit Company</Form.Label>
                                        <Select
                                            name="creditReportingCompanyId"
                                            value={record.creditReportingCompanyId}
                                            options={this.getCreditCompanies()}
                                            placeholder="Select"
                                            maxMenuHeight={140}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                </Grid>
                            </Grid>
                        </Box>
                    </If>
                    <If condition={selectedTab === PortfolioTabs.NOTES}>
                        <Box className={classes.dialogContent}>
                            <Grid container spacing={1} className="am-form">
                                <Box className={classes.notes}>
                                    <PortfolioHistoryTab portfolioId={parseInt(record.portfolioId, 10)} className={classes.historyTab} />
                                </Box>
                            </Grid>
                        </Box>
                    </If>
                </DialogContent>
                <DialogActions
                    disablePrimaryButton={!isValid}
                    onClickSecondary={onClose}
                    onClickPrimary={() => onSave(record)}
                />
            </Dialog>
        );
    }
}

PortfolioSettingsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onTabChange: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    listCreditCompany: PropTypes.array.isRequired,
};

export default withStyles(styles)(PortfolioSettingsDialogContainer(PortfolioSettingsDialog));

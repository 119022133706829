import React from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import PhoneNumberUtil from 'lib/PhoneUtils';

// Material UI
import { Typography, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import CustomerStyles from 'styles/modules/CustomerStyles';

const useStyles = makeStyles((theme) => CustomerStyles.customerItems(theme, fade));

const CustomerItems = ({ record }) => {
    const classes = useStyles();
    const {
        customerCode, firstName, lastName, cellPhone, email, sysDate,
    } = record;
    const fullName = `${firstName || ''} ${lastName || ''}`;

    return (
        <div className="d-flex">
            <div className={classes.min250}>
                <Typography variant="h6" className={classes.nameText}>{fullName}</Typography>
                <div className="d-flex-center">
                    <Typography className={classes.label}>
                        Customer #
                        <span className={classes.value}>{customerCode}</span>
                    </Typography>
                </div>
            </div>
            <div className={classes.min280}>
                <div className="d-flex-center">
                    <Typography className={classes.label}>Phone:</Typography>
                    <Typography className={classes.value}>{PhoneNumberUtil.formatPhone(cellPhone)}</Typography>
                </div>
                <div className="d-flex-center">
                    <Typography className={classes.label}>Email:</Typography>
                    <Typography className={classes.value}>{email}</Typography>
                </div>
            </div>
            <div className={classes.min250}>
                <div className="d-flex-center">
                    <Typography className={classes.label}>Type:</Typography>
                    <Typography className={classes.value}>Lead</Typography>
                </div>
                <div className="d-flex-center">
                    <Typography className={classes.label}>Customer Since:</Typography>
                    <Typography className={classes.value}>{DateUtils.format(sysDate)}</Typography>
                </div>
            </div>
        </div>
    );
};

CustomerItems.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CustomerItems;

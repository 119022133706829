import GraphQLClient from 'services/apollo/GraphQLClient';
import ProductsQuery from 'services/graphQL/query/ProductsQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import ProductMutate from 'services/graphQL/mutate/ProductMutate';
import { VendorType } from 'utils/enum/Vendors';

export default class ProductService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Get a list of products with filter
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getList(filter = {}) {
        return this.graphqlClient
            .query(ProductsQuery.LIST_PRODUCTS_VENDOR, filter)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getProductsVendor } = data;

                return {
                    data: { products: getProductsVendor },
                };
            });
    }

    /**
     * Get product detail
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getProductDetail(input = {}) {
        return this.graphqlClient
            .query(ProductsQuery.PRODUCT_VENDOR_DETAIL, input)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                const { getProductVendor } = data;
                return {
                    data: { product: getProductVendor },
                };
            });
    }

    /**
     * Get a list of product vendors
     * @param {Object} filter
     * @returns {Promise<any|void>}
     */
    async getProductVendorList() {
        const filter = {
            vendorType: [VendorType.PRODUCT_VENDOR],
        };

        return this.graphqlClient
            .query(VendorQuery.GET_PRODUCT_VENDOR_LIST, { filter });
    }

    /**
     * @param {Object} input
    */
    async createProduct(input) {
        return this.graphqlClient
            .mutate(ProductMutate.CREATE_PRODUCT_VENDOR, input);
    }

    /**
    * @param {Object} input
    */
    async updateProduct(input) {
        return this.graphqlClient
            .mutate(ProductMutate.UPDATE_PRODUCT_VENDOR, input);
    }

    /**
    * @param {Object} input
    */
    async uploadContract(vendorName, file) {
        return this.graphqlClient
            .mutate(ProductMutate.UPLOAD_CONTRACT, { vendorName, file });
    }
}

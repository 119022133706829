import { isEmpty } from 'lodash';

class PortfolioSettingMap {
    static mapPortfolioSettingToSave(record) {
        if (!isEmpty(record)) {
            return {
                creditReportingCompanyId: record.creditReportingCompanyId,
                ownerCompany: record.ownerCompany,
                creditCompany: record.creditCompany,
                accountingClass: record.accountingClass,
                type: record.type,
                description: record.description,
                name: record.name,
                originationDate: record.originationDate,
                activelyUsed: record.activelyUsed || false,
                inHouse: record.inHouse || false,
                stockIsLoanNumber: record.stockIsLoanNumber || false,
                loanNumberPrefix: record.loanNumberPrefix,
                nextLoanNumber: record.nextLoanNumber,
                addressName: record.addressName,
                textName: record.textName,
                address1: record.address1,
                address2: record.address2,
                zip: record.zip,
                city: record.city,
                state: record.state,
                phone: record.phone,
                fax: record.fax,
                website: record.website,
                receiptMessage: record.receiptMessage,
                convenienceFee: record.convenienceFee || 0,
                imported: record.imported || false,
                isDefault: record.isDefault || false,
                achConvenienceFee: record.achConvenienceFee || 0,
            };
        }

        return {};
    }

    static mapPortfolioSettingToUpdate(record, selectedPortfolioId) {
        if (!isEmpty(record) && selectedPortfolioId === record.portfolioId) {
            return {
                id: selectedPortfolioId,
                input: {
                    creditReportingCompanyId: record.creditReportingCompanyId,
                    ownerCompany: record.ownerCompany,
                    creditCompany: record.creditCompany,
                    accountingClass: record.accountingClass,
                    type: record.type,
                    description: record.description,
                    name: record.name,
                    originationDate: record.originationDate,
                    activelyUsed: record.activelyUsed || false,
                    inHouse: record.inHouse || false,
                    stockIsLoanNumber: record.stockIsLoanNumber || false,
                    loanNumberPrefix: record.loanNumberPrefix,
                    nextLoanNumber: record.nextLoanNumber,
                    addressName: record.addressName,
                    textName: record.textName,
                    address1: record.address1,
                    address2: record.address2,
                    zip: record.zip,
                    city: record.city,
                    state: record.state,
                    phone: record.phone,
                    fax: record.fax,
                    website: record.website,
                    receiptMessage: record.receiptMessage,
                    convenienceFee: record.convenienceFee || 0,
                    imported: record.imported || false,
                    isDefault: record.isDefault || false,
                    achConvenienceFee: record.achConvenienceFee || 0,
                },
            };
        }

        return {};
    }
}

export default PortfolioSettingMap;

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const LabelMemorize = (props) => {
    const { text, ...rest } = props;

    return (
        <Form.Label
            {...rest}
        >
            {text}
        </Form.Label>
    );
};

LabelMemorize.propTypes = {
    text: PropTypes.string.isRequired,
};

const areEqual = (prevProps, nextProps) => prevProps.text === nextProps.text;
export default React.memo(LabelMemorize, areEqual);

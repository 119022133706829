export const InitialState = {
    records: [],
    totalDebit: 0,
    totalCredit: 0,
    postToDate: new Date(),
    lockedDate: new Date(),
};

export const ACTION_TYPES = {
    SET_INITIAL_STATE: 'setInitialState',
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    SET_STATE_VALUES: 'setStateValues',
    CLEAR_LINES: 'clearLines',
};

const CommonJournalReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return {
            ...action.value,
        };
    }
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        let totalDebit = 0;
        let totalCredit = 0;

        action.value.forEach((item) => {
            totalDebit += item.debit;
            totalCredit += item.credit;
        });

        return {
            ...state,
            records: action.value,
            totalDebit,
            totalCredit,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        let totalDebit = 0;
        let totalCredit = 0;
        const {
            value, additionalFields, columnId, cell,
        } = action;
        const keyValue = 'entryId';
        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;

                if (columnId === 'accountNumber' && additionalFields) {
                    newItem.isControlled = action.additionalFields.isControlled;
                    newItem.controlledBy = additionalFields.controlledBy;
                    newItem.accountDescription = additionalFields.fullDescription;
                }

                if (columnId === 'debit' && value > 0) newItem.credit = 0;
                else if (columnId === 'credit' && value > 0) newItem.debit = 0;
            }

            totalDebit += newItem.debit;
            totalCredit += newItem.credit;

            return newItem;
        });

        return {
            ...state,
            records: newRecords,
            totalDebit,
            totalCredit,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.CLEAR_LINES:
    {
        return {
            ...state,
            records: [],
        };
    }
    default:
        return state;
    }
};

export default CommonJournalReducer;

import DateUtils from 'lib/DateUtils';

export default class CRMUtils {
    static calculateTimeOnLot = (prospectLeftDate, showedDate) => {
        if (!showedDate) return '';

        const now = new Date().toISOString();
        const start = DateUtils.getFormattedDateInUserTimezone(showedDate);
        const end = prospectLeftDate ? DateUtils.getFormattedDateInUserTimezone(prospectLeftDate) : DateUtils.getFormattedDateInUserTimezone(now);

        const diff = DateUtils.diff(end, start, 'minutes');
        const hours = Math.floor(diff / 60);
        const minutes = diff - (hours * 60);
        return hours > 0 ? `${hours} hours ${minutes} minutes` : `${minutes} minutes`;
    };
}

import React, { Component } from 'react';

// Marerial-UI
import { withStyles } from '@material-ui/core/styles';
import {
    AppBar, Toolbar, Typography, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { ReactComponent as CloseOutlined } from 'assets/close.svg';

const styles = (theme) => ({
    toolbarSm: {
        minHeight: 36,
    },
    appBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid${theme.palette.divider}`,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(0, 2),
    },
    title: {
        color: theme.palette.text.darkShark,
        fontFamily: 'Arial',
    },
    iconCloseSmall: {
        width: 9,
        height: 9,
    },
});

class DialogAppBar extends Component {
    render() {
        const { props } = this;
        const {
            title,
            classes,
            onClose,
            iconSize,
            disabled,
            toolbarSize,
            titleVariant,
            appBarClassName,
            secondaryButton,
        } = props;
        const toolbarClassName = toolbarSize === 'sm' ? classes.toolbarSm : '';
        const appBarClass = StringUtils.isEmpty(appBarClassName) ? '' : appBarClassName;
        const customTitleVariant = StringUtils.isEmpty(titleVariant)
            ? 'h4'
            : titleVariant;
        const currentIcon = !StringUtils.isEmpty(iconSize) && iconSize === 'sm' ? <CloseOutlined className={classes.iconCloseSmall} /> : <CloseIcon />;

        return (
            <AppBar
                className={clsx(classes.appBar, appBarClass)}
                position="relative"
                elevation={0}
                color="transparent"
            >
                <Toolbar className={clsx(classes.toolbar, toolbarClassName)}>
                    <Typography
                        variant={customTitleVariant}
                        color="inherit"
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    <div>
                        { secondaryButton }
                        <IconButton
                            size={toolbarSize === 'sm' ? 'small' : 'medium'}
                            color="inherit"
                            disabled={disabled}
                            aria-label="Close"
                            onClick={onClose}
                        >
                            {currentIcon}
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

DialogAppBar.propTypes = {
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
    iconSize: PropTypes.string,
    toolbarSize: PropTypes.string,
    titleVariant: PropTypes.string,
    appBarClassName: PropTypes.string,
    secondaryButton: PropTypes.element,
    title: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

DialogAppBar.defaultProps = {
    disabled: false,
    toolbarSize: '',
    iconSize: '',
    onClose: () => {},
    appBarClassName: '',
    titleVariant: '',
    secondaryButton: null,
};

export default withStyles(styles)(DialogAppBar);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles({
    main: {
        flex: 1,
    },
    iframe: {
        minHeight: '83vh',
        height: '100%',
        width: '100%',
    },
    error: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    icon: {
        marginBottom: 10,
    },
});

const CustomTabs = ({ url, title }) => {
    const classes = useStyles();

    if (StringUtils.isSecureURL(url)) {
        return (
            <div className={classes.main}>
                <iframe
                    className={classes.iframe}
                    title={title}
                    src={url}
                    frameBorder="0"
                    allowFullScreen
                />
            </div>
        );
    }

    return (
        <div className={clsx('d-flex', classes.main)}>
            <div className={clsx('d-flex-column', classes.error)}>
                <NotInterestedIcon className={classes.icon} fontSize="large" />
                There was an issue while loading the content.
            </div>
        </div>
    );
};

CustomTabs.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
};

CustomTabs.defaultProps = {
    title: '',
};

export default CustomTabs;

import React, { Component } from 'react';

// Components and others
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';

const CustomerDialogContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        onSelectRow: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();

        this.state = {
            records: [],
            isLoading: false,
            totalCount: 0,
            selectedRecord: null,
        };
        this.initBind();
    }

    onSearch(searchTerm) {
        const { state: { previousSearchTerm } } = this;
        if (searchTerm && (searchTerm === previousSearchTerm)) return;

        this.setState({ searchTerm });

        if (!StringUtils.isEmpty(searchTerm)) {
            this.getServicesData(searchTerm, true);
        } else {
            this.clearList();
        }
    }

    onClickRecord(record) {
        this.setState({ selectedRecord: record });
    }

    onClickPrimary() {
        const { props: { onSelectRow }, state: { selectedRecord } } = this;

        if (selectedRecord) {
            onSelectRow(selectedRecord);
        }
    }

    getServicesData(searchTerm, isSearching = false) {
        const { records } = this.state;
        let start = records.length;

        if (isSearching) {
            start = 0;
            this.setState({ records: [], totalCount: 0 });
        }

        const input = {
            paginate: {
                start,
                limit: 50,
            },
            filter: {
                searchTerm,
            },
        };

        this.setState({ isLoading: true, previousSearchTerm: searchTerm });
        this.graphqlClient
            .query(DealsQuery.GET_CUSTOMERS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.getCustomers) {
                    const { getCustomers: { customers, totalCount } } = data;

                    this.setState((prevState) => ({
                        records: prevState.records.concat(customers),
                        totalCount,
                    }));
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    loadMore() {
        const { searchTerm } = this.state;
        this.getServicesData(searchTerm);
    }

    clearList() {
        const { state: { records } } = this;

        if (records.length > 0) {
            this.setState({
                records: [],
                totalCount: 0,
                previousSearchTerm: '',
            });
        }
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clearList = this.clearList.bind(this);
        this.onClickRecord = this.onClickRecord.bind(this);
        this.onClickPrimary = this.onClickPrimary.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSearch={this.onSearch}
                loadMore={this.loadMore}
                onClickRecord={this.onClickRecord}
                onClickPrimary={this.onClickPrimary}
            />
        );
    }
};

export default CustomerDialogContainer;

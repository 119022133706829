import { InvoiceStatus } from 'utils/enum/AccountingEnum';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AMOUNT: 'setAmount',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
    SET_SAVE_AND_NEW: 'setSaveAndNew',
};

const InvoiceReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.value, isDirty: action?.isDirty ?? state.isDirty };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return { ...state, record: action.value, isDirty: true };
    }
    case ACTION_TYPES.SET_AMOUNT: {
        if (state.record.invoiceAmount === action.value) return state;

        if (state.record?.status.toLowerCase() !== InvoiceStatus.NEW.toLowerCase()
            && state.record?.status.toLowerCase() !== InvoiceStatus.ACTIVE.toLowerCase()) {
            return state;
        }

        return {
            ...state,
            record: {
                ...state.record,
                invoiceAmount: action.value,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value,
            },
        };
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW: {
        const { record } = state;

        return {
            ...action.value,
            record: {
                ...action.value.record,
                vendorId: record.vendorId,
                vendorName: record.vendorName,
                billDate: record.billDate,
                invoiceAmount: 0,
                amountDue: 0,
                dueDate: record.dueDate,
                lotId: record.lotId,
                accountNumber: record.accountNumber,
                status: InvoiceStatus.NEW,
            },
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

export default InvoiceReducer;

import GraphQLClient from 'services/apollo/GraphQLClient';
import UserQuery from 'services/graphQL/query/UserQuery';

export default class UserService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Get the timezone by lot and company
     * @param {Object} input
     * @param {Object} input.lotId
     */
    async getTimeZones(input) {
        return this.graphqlClient
            .query(UserQuery.GET_TIME_ZONES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return { data };
            });
    }
}

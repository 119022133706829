import { gql } from '@apollo/client';

export default class EmailTemplateQuery {
    static GET_CRM_TEMPLATE = gql`
       query getCRMTemplate($paging: DataPaging!, $active: Boolean, $searchTerm: String) {
            getCRMTemplate(paging: $paging, active: $active, searchTerm: $searchTerm) {
                totalCount
                data {
                    templateName
                    templateType
                    templateText
                    templateSubject
                    messageTemplateId
                    moduleTemplate
                    language
                    active
                    entityAliasId
                    imported
                    modifiedOn
                }
            }
        }
    `;

    static GET_EMAIL_TEMPLATE_LIST = gql`
        query getEmailTemplateList($moduleTemplate: String!) {
            getEmailTemplateList(moduleTemplate: $moduleTemplate) {
                templateName
                language
                messageTemplateId
                templateSubject
            }
        }
    `;

    static GET_SMS_TEMPLATE_LIST = gql`
        query getSMSTemplateList($moduleTemplate: String!) {
            getSMSTemplateList(moduleTemplate: $moduleTemplate) {
                templateName
                language
                messageTemplateId
                templateSubject
            }
        }
    `;

    static GET_CRM_TEMPLATE_FIELDS = gql`
        query getCRMTemplateFields {
            getCRMTemplateFields{
                displayAs
                fieldName
            }
        }
    `;
}

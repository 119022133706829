import React, {
    useEffect,
    useReducer,
} from 'react';
import {
    Grid,
    Button,
    makeStyles,
} from '@material-ui/core';
import {
    AddIcon,
    PrintOutlinedIcon,
    ThumbUpAltOutlinedIcon,
} from 'components/icons';
import clsx from 'clsx';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import PayrollUtils from 'utils/PayrollUtils';
import Permission from 'utils/enum/Permissions';
import { useQuery, useMutation } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import HoursWorkedQuery from 'services/graphQL/query/payroll/HoursWorked';
import useEmployeHasCheck from 'components/hook/payroll/useEmployeHasCheck';
import HoursWorkedMutation from 'services/graphQL/mutate/payroll/HoursWorked';
import ActualHoursTable from 'components/widgets/payroll/HoursWorked/ActualHoursTable';
import AdjustedHoursTable from 'components/widgets/payroll/HoursWorked/AdjustedHoursTable';
import EmployeeHoursDialog from 'components/widgets/payroll/HoursWorked/EmployeeHoursDialog';
import EmployeeHoursErrors from 'components/widgets/payroll/HoursWorked/EmployeeHoursErrors';

const useStyles = makeStyles((theme) => ({
    defaultText: {
        alignSelf: 'center',
        textAlign: 'center',
    },
    content: {
        flexGrow: 1,
        marginTop: 10,
        height: 'fit-content',
    },
    header: {
        display: 'flex',
        marginBottom: 10,
        height: 'fit-content',
    },
    actionButtonPrint: {
        color: theme.palette.text.royalBlue,
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
}));

const ACTION_TYPES = {
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    default: return state;
    }
};

const HoursWorkedTablesContainer = ({
    employeeId, payPeriod, payType,
}) => {
    const classes = useStyles();
    const keyStore = new KeyStore();

    const initState = {
        actualHours: [],
        adjustedHours: [],
        actualTotal: '00:00',
        adjustedTotal: '00:00',
        actualRegular: '00:00',
        actualOvertime: '00:00',
        adjustedRegular: '00:00',
        adjustedOvertime: '00:00',
        openApprovalDialog: false,
        loadActualHoursTable: false,
        openAdjustHoursDialog: false,
        loadAdjustedHoursTable: false,
        openTwelveHoursExceedDialog: false,
        openTwelveHoursExceedFromCreateDialog: false,
        recordToSave: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        adjustedOvertime, actualTotal, adjustedTotal,
        actualHours, openApprovalDialog, loadActualHoursTable, openAdjustHoursDialog,
        adjustedHours, loadAdjustedHoursTable, actualRegular, actualOvertime, adjustedRegular,
        openTwelveHoursExceedDialog, openTwelveHoursExceedFromCreateDialog, recordToSave,
    } = state;

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                loadActualHoursTable: false,
                loadAdjustedHoursTable: false,
            },
        });
    }, [payPeriod, employeeId]);

    const { hasCheck } = useEmployeHasCheck({ employeeId, payPeriod });

    const {
        loading, data, error, refetch,
    } = useQuery(HoursWorkedQuery.GET_HOURS_WORKED_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payType,
            payPeriod,
            employeeId,
            lotName: keyStore?.getSelectedLot()?.lotName || '',
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getEmployeeHoursList } = data;
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadActualHoursTable: true,
                    loadAdjustedHoursTable: true,
                    actualHours: getEmployeeHoursList?.actualHours || [],
                    adjustedHours: getEmployeeHoursList?.adjustedHours || [],
                    actualTotal: getEmployeeHoursList?.actualTotal || '00:00',
                    actualRegular: getEmployeeHoursList?.actualRegular || '00:00',
                    adjustedTotal: getEmployeeHoursList?.adjustedTotal || '00:00',
                    actualOvertime: getEmployeeHoursList?.actualOvertime || '00:00',
                    adjustedRegular: getEmployeeHoursList?.adjustedRegular || '00:00',
                    adjustedOvertime: getEmployeeHoursList?.adjustedOvertime || '00:00',
                },
            });
        }
    }, [data, loading, error]);

    const toggleApprovalDialog = () => {
        dispatch({
            field: 'openApprovalDialog',
            value: !openApprovalDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const toggleTwelveHoursExceedDialog = () => {
        dispatch({
            field: 'openTwelveHoursExceedDialog',
            value: !openTwelveHoursExceedDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const toggleTwelveHoursExceedFromCreateDialog = (record = null) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                openTwelveHoursExceedFromCreateDialog: !openTwelveHoursExceedFromCreateDialog,
                recordToSave: record,
            },
        });
    };

    const openApprovalDialogs = () => {
        const totalHours = adjustedHours.filter((item) => item.status.toUpperCase().includes('SAVE')).map((item) => item.totalHours);
        const totalHoursExceedTwelve = totalHours.some((hourFull) => {
            const [hours, minutes] = hourFull.split(':');
            return Number(hours) > 12 || (Number(hours) === 12 && Number(minutes) > 0);
        });
        if (totalHoursExceedTwelve) toggleTwelveHoursExceedDialog();
        else toggleApprovalDialog();
    };

    const [approve, { loading: isApproving }] = useMutation(HoursWorkedMutation.APPROVE_HOURS, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveHours) {
                ModalUtils.successMessage(null, 'Hours Approved Successfully!');
                if (openApprovalDialog) toggleApprovalDialog();
                else if (openTwelveHoursExceedDialog) toggleTwelveHoursExceedDialog();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const toggleAdjustHoursDialog = (isSaving = false) => {
        if (isSaving) refetch();
        dispatch({
            field: 'openAdjustHoursDialog',
            value: !openAdjustHoursDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [addLine, { loading: adding }] = useMutation(HoursWorkedMutation.ADD_TIME_LINE);

    const onSave = async (record) => {
        const {
            timeInDate, timeInAmPm, timeInHour, timeInMinute,
            timeOutDate, timeOutAmPm, timeOutHour, timeOutMinute,
            timeInReason, totalHours, openEndedDate, timeOutReason,
        } = record;
        try {
            const timeIn = PayrollUtils.getParsedDate({
                dateOnly: timeInDate,
                hour: timeInHour,
                minutes: timeInMinute,
                amPm: timeInAmPm,
            });
            const timeOut = PayrollUtils.getParsedDate({
                dateOnly: timeOutDate,
                hour: timeOutHour,
                minutes: timeOutMinute,
                amPm: timeOutAmPm,
            });
            const input = {
                timeIn,
                totalHours,
                timeInReason,
                employeeId,
                timeOut: openEndedDate ? null : timeOut,
                timeOutReason: openEndedDate ? null : timeOutReason,
            };
            const response = await addLine({ variables: { input } });

            if (response.data?.addTimeLine) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                if (openTwelveHoursExceedFromCreateDialog) toggleTwelveHoursExceedFromCreateDialog();
                toggleAdjustHoursDialog(true);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Time Line'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const openApprovalFromCreateDialogs = (record) => {
        const { totalHours } = record;
        const [hours, minutes] = totalHours.split(':');
        const totalHoursExceedTwelve = Number(hours) > 12 || (Number(hours) === 12 && Number(minutes) > 0);
        if (totalHoursExceedTwelve) toggleTwelveHoursExceedFromCreateDialog(record);
        else onSave(record);
    };

    const [printTimeSheet, { loading: printing }] = useMutation(HoursWorkedMutation.PRINT_TIME_SHEET, {
        onCompleted: (mutationData) => {
            if (mutationData?.printPayrollTimeSheet) {
                printJS({
                    type: 'pdf',
                    showModal: false,
                    printable: mutationData.printPayrollTimeSheet,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onPrintTimeSheet = () => {
        if (adjustedHours.some((record) => record.status.toUpperCase().includes('SAVE'))) {
            ModalUtils.errorMessage(
                null,
                `You cannot print the time sheet because you have one or more hours to save,
                Please save the adjusted time to print the time sheet`,
            );
        } else {
            printTimeSheet({
                variables: {
                    payPeriod,
                    employeeId,
                    lotId: keyStore?.getSelectedLot()?.lotId || 0,
                },
            });
        }
    };

    const invalidLine = adjustedHours.some((hour) => hour.errors.length > 0);
    const containsWarnings = adjustedHours.some((hour) => hour.warnings.length > 0);
    const disableButtons = (!actualHours.length && !adjustedHours.length) || printing || isApproving;

    return (
        <div className={classes.content}>
            <Grid item className={classes.header}>
                <>
                    {keyStore.hasPermission(Permission.PAYROLL_HOURS_WORKED_WRITE) && !hasCheck && (
                        <>
                            <Button
                                onClick={toggleAdjustHoursDialog}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                size="small"
                                className={classes.localButtonSpacing}
                            >
                                New Line
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                disabled={disableButtons || invalidLine}
                                onClick={openApprovalDialogs}
                                className={classes.localButtonSpacing}
                                startIcon={(
                                    <ThumbUpAltOutlinedIcon
                                        className={clsx({ [classes.actionButtonApprove]: !disableButtons && !invalidLine })}
                                    />
                                )}
                            >
                                Approve Hours
                            </Button>

                        </>
                    )}
                    {keyStore.hasPermission(Permission.PAYROLL_HOURS_WORKED_PRINT) && (
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={disableButtons}
                            onClick={onPrintTimeSheet}
                            className={classes.localButtonSpacing}
                            startIcon={<PrintOutlinedIcon className={clsx({ [classes.actionButtonPrint]: !disableButtons })} />}
                        >
                            Print
                        </Button>
                    )}
                    {(invalidLine || containsWarnings) && (
                        <EmployeeHoursErrors records={adjustedHours} />
                    )}
                </>
            </Grid>
            <ActualHoursTable
                items={actualHours}
                total={actualTotal}
                regular={actualRegular}
                overtime={actualOvertime}
                loadTable={loadActualHoursTable}
            />
            <AdjustedHoursTable
                refetch={refetch}
                hasCheck={hasCheck}
                items={adjustedHours}
                total={adjustedTotal}
                regular={adjustedRegular}
                overtime={adjustedOvertime}
                loadTable={loadAdjustedHoursTable}
                hasWritePermission={keyStore.hasPermission(Permission.PAYROLL_HOURS_WORKED_WRITE)}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Approve Hours?"
                open={openApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleApprovalDialog}
                onClose={toggleApprovalDialog}
                onClickPrimary={() => approve({
                    variables: {
                        payPeriod,
                        employeeId,
                    },
                })}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Do you want to save adjusted time with more than 12 hours?"
                open={openTwelveHoursExceedDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleTwelveHoursExceedDialog}
                onClose={toggleTwelveHoursExceedDialog}
                onClickPrimary={() => approve({
                    variables: {
                        payPeriod,
                        employeeId,
                    },
                })}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Do you want to save adjusted time with more than 12 hours?"
                open={openTwelveHoursExceedFromCreateDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleTwelveHoursExceedFromCreateDialog}
                onClose={toggleTwelveHoursExceedFromCreateDialog}
                onClickPrimary={() => onSave(recordToSave)}
            />
            { openAdjustHoursDialog && (
                <EmployeeHoursDialog
                    loading={adding}
                    onSave={openApprovalFromCreateDialogs}
                    onClose={toggleAdjustHoursDialog}
                />
            )}
        </div>
    );
};

HoursWorkedTablesContainer.propTypes = {
    payType: PropTypes.string.isRequired,
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
};

export default HoursWorkedTablesContainer;

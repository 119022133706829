import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button, Dialog, DialogContent, Slide, AppBar, Toolbar, DialogActions, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from 'components/widgets/Table';
import Container from 'components/widgets/Container';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputControl from 'components/widgets/editorControls/InputControl';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { SaveIcon } from 'components/icons';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import ModalUtils from 'utils/ModalUtils';
import { concat, isEmpty } from 'lodash';
import NumberUtils from 'lib/NumberUtils';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    ADD_NEW_LINE: 'addNewLine',
    SET_ON_DELETE: 'setOnDelete',
    SET_STATE_VALUES: 'setStateValues',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    VALIDATE_ADD_LINE: 'validateAddLine',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS: {
        const minNumber = Math.min(...action.value.map((c) => c.entryId));

        return {
            ...state,
            records: action.value,
            entryId: minNumber,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const keyValue = 'entryId';

        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === action.cell.original[keyValue]) {
                newItem[action.columnId] = action.value;

                if (action.columnId === 'accountNumber' && action.additionalFields) {
                    newItem.isControlled = action.additionalFields.isControlled;
                    newItem.controlledBy = action.additionalFields.controlledBy;
                    newItem.accountDescription = action.additionalFields.fullDescription;
                }
            }

            return newItem;
        });

        return {
            ...state,
            records: newRecords,
            isDirty: true,

        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const glLine = action.value;

        const newLine = {
            accountNumber: 0,
            amount: 0,
            controlNumber: glLine.controlNumber,
            isControlled: false,
            controlledBy: '',
            department: glLine.department,
            departmentId: glLine.departmentId,
            entryId: state.entryId - 1,
            lineId: glLine.lineId + 1,
            lotId: glLine.lotId,
            lotName: glLine.lotName,
            memo: glLine.memo,
            processDetailRecordId: glLine.processDetailRecordId,
            processTypeId: glLine.processTypeId,
        };
        const data = concat(state.records, newLine);

        return {
            ...state,
            records: data,
            entryId: newLine.entryId,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
        };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.VALIDATE_ADD_LINE: {
        const { rowIndex, event } = action;

        if (rowIndex === state.records.length - 1) action.method(state.records, event);

        return {
            ...state,
        };
    }
    default:
        return state;
    }
};

const initialState = {
    records: [],
    isModalDeleteOpen: false,
    idToDelete: 0,
    entryId: 0,
    couldLostData: false,
};

const SplitJournalLine = (props) => {
    const classes = useStyles();

    const {
        splitLines, glLine, onSplitLine, onCloseSplitModal,
    } = props;

    const [state, dispatch] = useReducer(reducer, initialState);

    const getLineErrors = (records) => {
        const errors = [];

        records.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.accountNumber <= 0) message += ' Account Number is required,';
            if (!item.amount || item.amount === 0) message += ' Amount is required and must be different than zero,';
            if (item.isControlled && !isEmpty(item.controlledBy) && item.controlNumber.trim() === '') {
                message += ` The account ${item.accountDescription} must have a ${item.controlledBy} as Control number,`;
            }

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        const totalAmount = records.reduce((prev, next) => prev + next.amount, 0);

        const totalToSplit = splitLines?.length > 0 ? splitLines.reduce((prev, next) => prev + next.amount, 0) : glLine.amount;

        if (NumberUtils.round(totalAmount) > NumberUtils.round(totalToSplit)) errors.push({ message: `The sum of lines should be equals to ${totalToSplit}` });

        return errors;
    };

    const onAddNewLine = (_records, event) => {
        const errors = getLineErrors(_records || []);

        if (errors.length > 0) {
            errors.unshift({ message: 'Please check the following before adding a new line: ' });
            ModalUtils.errorMessage(errors);
            if (event) event.preventDefault();
            return;
        }

        dispatch({ type: ACTION_TYPES.ADD_NEW_LINE, value: glLine });
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
            currentGlLine: glLine,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        if (event && (key === 'Tab' || keyCode === 9)) {
            dispatch({
                type: ACTION_TYPES.VALIDATE_ADD_LINE,
                method: onAddNewLine,
                rowIndex: cell.index,
                cell,
                event,
            });
        }
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = () => {
        const dataAfterDeleted = state.records.filter((item) => item.entryId !== state.idToDelete);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: dataAfterDeleted,
        });
        onCloseDeleteConfirm();
    };

    const onPopupClose = (event, forceClose = false) => {
        if (state.isDirty && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onCloseSplitModal();
    };

    useEffect(() => {
        if (glLine) {
            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                value: splitLines?.length > 0 ? splitLines : [glLine],
            });

            dispatch({
                type: ACTION_TYPES.ADD_NEW_LINE,
                value: glLine,
            });
        }
    }, [glLine, splitLines]);

    const splitLine = (event) => {
        const errors = getLineErrors(state.records || []);

        const totalAmount = state.records.reduce((prev, next) => prev + next.amount, 0);

        const totalToSplit = splitLines?.length > 0 ? splitLines.reduce((prev, next) => prev + next.amount, 0) : glLine.amount;

        if (NumberUtils.round(totalAmount) < NumberUtils.round(totalToSplit)) errors.push({ message: `The sum of lines should be equals to ${totalToSplit}` });

        if (errors.length > 0) {
            errors.unshift({ message: 'Please check the following before adding a new line: ' });
            ModalUtils.errorMessage(errors);
            if (event) event.preventDefault();
            return;
        }

        onSplitLine(state.records);
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    const columns = [
        {
            Header: 'Line',
            minWidth: 50,
            width: 50,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
            className: clsx(classes.columnStyle, classes.columnCenter),
            Cell: (cell) => cell.index + 1,
        },
        {
            Header: 'Account',
            minWidth: 100,
            headerClassName: classes.columnHeaderStyle,
            accessor: 'accountNumber',
            Cell: (cell) => {
                const { column: { id }, original: { accountNumber, entryId } } = cell;
                return (
                    <SelectControl
                        name={id}
                        value={accountNumber}
                        editorCellObject={cell}
                        placeHolder="select an account"
                        onChange={handleEditorChange}
                        className={accountNumber > 0 ? '' : 'invalid-field'}
                        dataSource={{
                            query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                            variables: {
                                paginate: {
                                    init: 0,
                                    ignoreLimit: true,
                                },
                            },
                            rootData: 'getAccountingCOAList.data',
                            idField: 'accountNumber',
                            descriptionField: 'fullDescription',
                            additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                        }}
                        comparePropertyId={entryId}
                    />
                );
            },
        },
        {
            Header: 'Amount',
            minWidth: 100,
            width: 100,
            headerClassName: classes.columnHeaderStyle,
            accessor: 'amount',
            Cell: (cell) => {
                const { column: { id }, original: { amount, entryId } } = cell;
                return (
                    <InputControl
                        name={id}
                        value={amount}
                        className={amount && amount !== 0 ? '' : 'invalid-field'}
                        editorCellObject={cell}
                        type="number"
                        onChange={handleEditorChange}
                        onKeyDown={handleEditorKeyDown}
                        comparePropertyId={entryId}
                    />
                );
            },
        },
        {
            Header: 'Control #',
            minWidth: 100,
            width: 100,
            headerClassName: classes.columnHeaderStyle,
            accessor: 'controlNumber',
        },
        {
            Header: 'Actions',
            minWidth: 60,
            headerClassName: classes.columnHeaderStyle,
            width: 70,
            Cell: (cell) => (
                <IconButton onClick={() => {
                    dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: cell.original.entryId });
                }}
                >
                    <DeleteIcon className={classes.buttonDelete} />
                </IconButton>
            ),
        },
    ];
    const totalSum = state.records.reduce((prev, next) => prev + next.amount, 0);
    const totalToSplit = splitLines?.length > 0 ? splitLines.reduce((prev, next) => prev + next.amount, 0) : glLine.amount;

    return (
        <Dialog
            open
            onClose={onPopupClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        Split Amount: &nbsp;
                        {NumberUtils.applyCurrencyFormat(totalToSplit || 0)}
                        &nbsp;&nbsp;&nbsp;
                        Difference: &nbsp;
                        { NumberUtils.applyCurrencyFormat((NumberUtils.round(totalSum) - NumberUtils.round(totalToSplit)) || 0)}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onPopupClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                <Container className={classes.containerSplit}>
                    <Table
                        className={clsx('-highlight', classes.table)}
                        data={state.records}
                        columns={columns}
                        cursor="default"
                        totalRecords={state.records?.length}
                        rowSelected
                    />
                    <ConfirmDialog
                        title="Confirm remove line"
                        description="Are you sure you want to remove this line?"
                        open={state.isModalDeleteOpen}
                        variant="outlined"
                        titlePrimary="Yes"
                        titleSecondary="Cancel"
                        onClose={(onCloseDeleteConfirm)}
                        onClickSecondary={onCloseDeleteConfirm}
                        onClickPrimary={onDeleteConfirm}
                        disablePrimaryButton={state.isDeleting}
                        disableSecondaryButton={state.isDeleting}
                    />
                    <ConfirmDialog
                        title="Attention - You have unsaved changes!"
                        description="Do you want to close without saving?"
                        open={state.couldLostData}
                        variant="outlined"
                        titlePrimary="Close"
                        titleSecondary="Cancel"
                        onClose={onCloseFormWithoutSave}
                        onClickSecondary={onCloseFormWithoutSave}
                        onClickPrimary={(e) => onPopupClose(e, true)}
                    />
                </Container>
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            size="small"
                            className={classes.buttonNew}
                            onClick={() => onAddNewLine(state.records)}
                        >
                            New Item
                        </Button>
                        <Button
                            startIcon={<SaveIcon />}
                            size="small"
                            className={classes.buttonSave}
                            disabled={state.isSaving || !state.isDirty}
                            onClick={() => splitLine()}
                        >
                            Split Amount
                        </Button>
                    </div>
                </Toolbar>
            </DialogActions>
        </Dialog>
    );
};

SplitJournalLine.propTypes = {
    splitLines: PropTypes.array,
    glLine: PropTypes.object,
    onSplitLine: PropTypes.func.isRequired,
    onCloseSplitModal: PropTypes.func.isRequired,
};

SplitJournalLine.defaultProps = {
    splitLines: null,
    glLine: null,
};

export default SplitJournalLine;

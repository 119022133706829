import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles,
    FormLabel,
} from '@material-ui/core';
import EquipmentItem from 'components/modules/inventory/read/panels/equipment/EquipmentItem';

const useStyles = makeStyles({
    title: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    box: {
        marginBottom: 25,
        padding: '0 5px',
    },
    column: {
        overflow: 'auto',
    },
});

const EquipmentCategory = ({ record, isEditable, onChangeItem }) => {
    const classes = useStyles();

    const { data, section } = record;

    if (data.length <= 0) {
        return (
            <div className={clsx('d-flex-column', classes.box)} />
        );
    }

    return (
        <div className={clsx('d-flex-column', classes.box)}>
            <FormLabel className={classes.title}>{record.title}</FormLabel>
            <div className={clsx('d-flex-column', classes.column)}>
                {data.map((equipment, index) => (
                    <EquipmentItem
                        equipment={equipment}
                        key={`key-${index}`}
                        index={index}
                        section={section}
                        isEditable={isEditable}
                        onChangeItem={onChangeItem}
                    />
                ))}
            </div>
        </div>
    );
};

EquipmentCategory.propTypes = {
    onChangeItem: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
};

EquipmentCategory.defaultProps = {
    isEditable: false,
};

export default EquipmentCategory;

/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
} from '@material-ui/core';
import {
    COMPONENT_WIDTH,
} from 'utils/enum/BusinessIntelligenceEnum';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import {
    useMutation,
} from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import Select from 'components/widgets/Select';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import BIMutation from 'services/graphQL/mutate/businessIntelligence/Mutation';

// Icons
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    main: {
        [theme.breakpoints.down('sm')]: {
            overflowY: 'initial',
        },
    },
    settingsDialogContent: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                flexDirection: 'column',
            },
            '& > div > div': {
                padding: '0px !important',
            },
        },
    },
    innerSections: {
        height: '400px',
    },
    middleSection: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '150px',
        },
    },
    label: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
    },
    paper: {
        height: '100%',
        overflowY: 'auto',
    },
    actions: {
        paddingRight: '40px',
        paddingLeft: '40px',
    },
    staticIcon: {
        fill: theme.palette.secondary.main,
    },
    currentElement: {
        zIndex: 9999,
        paddingTop: '5px',
        paddingBottom: '5px',
        position: 'relative',
        '& div.MuiAvatar-root': {
            backgroundColor: theme.palette.background.white,
            border: `1px solid ${theme.palette.border.ghost}`,
            '& > svg': {
                width: '24px',
                height: '24px',
            },
        },
        '& div.MuiListItemText-root > span': {
            fontSize: '13px',
            lineHeight: 'initial',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '75%',
        },
        '& > div.select-bootstrap': {
            width: '75px',
            position: 'absolute',
            top: '18px',
            right: '4px',
        },
    },
}));

const chartTypes = BIHelper.getChartType();
const SettingsDialog = ({
    staticComponents,
    charts,
    components,
    onClose,
    refetchComponents,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        currentComponents: [],
        availableComponents: [],
        selectedCurrent: null,
        selectedAvailable: null,
    });

    const [saveDashboardSettings, { loading: savingComponents }] = useMutation(BIMutation.SAVE_DASHBOARD_COMPONENTS, {
        onCompleted: (response) => {
            if (response?.saveDashboardComponents) {
                ModalUtils.successMessage(null, 'Components saved successfully');

                const saved = response.saveDashboardComponents;
                if (Array.isArray(saved)) {
                    const clone = cloneDeep(state.currentComponents);
                    saved.forEach(({ id, staticName, chartId }) => {
                        const component = clone.find((el) => (!el.chartId && el.name === staticName) || (el.chartId && el.chartId === chartId));
                        if (component) component.componentId = id;
                    });

                    setState((prevState) => ({
                        ...prevState,
                        currentComponents: clone,
                    }));

                    refetchComponents();
                }

                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the components');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        const split = BIHelper.splitComponents(staticComponents, charts, components);
        setState((prevState) => ({
            ...prevState,
            currentComponents: split.current,
            availableComponents: split.available,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charts, components]);

    const onSelectComponent = (component, isCurrent) => {
        setState((prevState) => ({
            ...prevState,
            selectedCurrent: isCurrent ? component : null,
            selectedAvailable: isCurrent ? null : component,
        }));
    };

    const onMoving = () => {
        const {
            selectedCurrent,
            selectedAvailable,
            currentComponents,
            availableComponents,
        } = state;

        let cloneCurrent = cloneDeep(currentComponents);
        let cloneAvailable = cloneDeep(availableComponents);
        if (!StringUtils.isEmpty(selectedCurrent)) {
            cloneAvailable.order = -1;
            cloneAvailable.width = COMPONENT_WIDTH[0];
            cloneAvailable.push(selectedCurrent);
            cloneAvailable.sort((a, b) => a.name.localeCompare(b.name));
            cloneCurrent = cloneCurrent.filter((c) => c.name !== selectedCurrent.name);
        }

        if (!StringUtils.isEmpty(selectedAvailable)) {
            selectedAvailable.order = cloneCurrent.length + 1;
            cloneCurrent.push(selectedAvailable);
            cloneAvailable = cloneAvailable.filter((c) => c.name !== selectedAvailable.name);
        }

        setState((prevState) => ({
            ...prevState,
            currentComponents: cloneCurrent,
            availableComponents: cloneAvailable,
            selectedCurrent: null,
            selectedAvailable: null,
        }));
    };

    const changeSize = (component, value) => {
        const cloneCurrent = cloneDeep(state.currentComponents);
        const element = cloneCurrent.find((c) => c.name === component.name);
        if (element) element.width = Number(value);

        setState((prevState) => ({
            ...prevState,
            currentComponents: cloneCurrent,
        }));
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let componentsSorted = arrayMove(
                cloneDeep(state.currentComponents),
                oldIndex,
                newIndex,
            );

            componentsSorted = componentsSorted.map((component, index) => ({
                ...component,
                order: index + 1,
            }));

            setState((prevState) => ({
                ...prevState,
                currentComponents: componentsSorted,
            }));
        }
    };

    const saveSettings = () => {
        const { currentComponents } = state;
        if (currentComponents.length === 0) return;

        const toDelete = [];
        components.forEach((c) => {
            if (!currentComponents.some((current) => current.componentId === c.id)) toDelete.push(c);
        });

        saveDashboardSettings({
            variables: {
                input: [
                    ...currentComponents.map((c) => ({
                        id: c.componentId,
                        staticName: !c.chartId ? c.name : null,
                        chartId: c.chartId,
                        order: c.order,
                        width: c.width,
                    })),
                    ...toDelete.map((c) => ({
                        id: c.id,
                        staticName: c.staticName,
                        chartId: c.chart?.id,
                        order: c.order,
                        width: c.width,
                        active: false,
                    })),
                ],
            },
        });
    };

    const SortableItem = SortableElement(({ value: c, sortIndex }) => (
        <div
            key={sortIndex}
            index={sortIndex}
            className={classes.currentElement}
        >
            <ListItem
                button
                selected={state.selectedCurrent?.name === c.name}
                onClick={() => onSelectComponent(c, true)}
            >
                <ListItemAvatar>
                    <Avatar>
                        {
                            chartTypes.find((chart) => chart.name === c.chartType)?.icon
                            || <AppsOutlinedIcon className={classes.staticIcon} />
                        }
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={c.name}
                />
            </ListItem>
            <Select
                nowrap
                size="sm"
                loading={false}
                name=""
                className={classes.input}
                onChange={(_, value) => changeSize(c, value)}
                value={c.width}
                options={
                    COMPONENT_WIDTH
                        .map((f) => ({
                            value: f,
                            label: `${f}%`,
                        }))
                }
            />
        </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
        <List>
            {
                items.map((component, index) => (
                    <SortableItem
                        key={`component-${index}`}
                        index={index}
                        sortIndex={index}
                        value={component}
                    />
                ))
            }
        </List>
    ));

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Dashboard Settings"
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent className={classes.main}>
                <div className={classes.settingsDialogContent}>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={12} className={classes.innerSections}>
                            <div className={classes.label}>
                                Current Components
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <SortableList
                                    axis="y"
                                    distance={10}
                                    items={state.currentComponents}
                                    onSortEnd={onSortEnd}
                                />
                            </Paper>
                        </Grid>
                        <Grid item md={1} sm={12}>
                            <Grid
                                className={clsx(classes.innerSections, classes.middleSection)}
                                container
                            >
                                <IconButton
                                    aria-label="add-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(state.selectedCurrent)}
                                    onClick={onMoving}
                                >
                                    <ArrowBackIosIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                    aria-label="remove-form"
                                    size="small"
                                    disabled={!StringUtils.isEmpty(state.selectedAvailable)}
                                    onClick={onMoving}
                                >
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item md={5} sm={12} className={classes.innerSections}>
                            <div className={classes.label}>
                                Available Components
                            </div>
                            <Paper elevation={2} className={classes.paper}>
                                <List>
                                    {state.availableComponents.map((c, index) => (
                                        <div key={index} className={classes.currentElement}>
                                            <ListItem
                                                button
                                                selected={state.selectedAvailable?.name === c.name}
                                                onClick={() => onSelectComponent(c, false)}
                                                className={classes.currentElement}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {
                                                            chartTypes.find((chart) => chart.name === c.chartType)?.icon
                                                            || <AppsOutlinedIcon className={classes.staticIcon} />
                                                        }
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={c.name}
                                                />
                                            </ListItem>
                                        </div>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    className={classes.containedSecondaryInfo}
                    disabled={state.currentComponents.length === 0 || savingComponents}
                    onClick={saveSettings}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SettingsDialog.defaultProps = {
    staticComponents: [],
    charts: [],
    components: [],
};

SettingsDialog.propTypes = {
    staticComponents: PropTypes.array,
    charts: PropTypes.array,
    components: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    refetchComponents: PropTypes.func.isRequired,
};

export default SettingsDialog;

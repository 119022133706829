import { gql } from '@apollo/client';

export default class UnderwritingQuery {
    static THIRD_API_INTEGRATION_LIST = gql`
        query listDealerIntegrations($searchText: String, $active: Boolean) {
            listDealerIntegrations(searchText: $searchText, active: $active) {
                id: apiIntegrationVendorId
                name: vendorName
                type: integrationType
                value: uniqueCode
                active
                lotId
                lotName
                bureaus {
                    bureauCompanyId
                    name
                    active
                    defaultCheck
                }
            }
            listAvailableIntegrations {
                id: apiIntegrationVendorId
                name: vendorName
                type: integrationType
                value: uniqueCode
                active
                bureaus {
                    bureauCompanyId
                    name
                    active
                    defaultCheck
                }
                availableLotIntegration {
                    lotId
                    lotName
                }
            }
        }
    `;

    static GET_VENDOR_DETAILS = gql`
        query getCreditPullIntegration(
            $apiIntegrationVendorId: Int!
            $lotId: Int
        ) {
            getCreditPullIntegration(
                apiIntegrationVendorId: $apiIntegrationVendorId
                lotId: $lotId
            ) {
                companyApiIntegrationId
                name: vendorName
                value: uniqueCode
                vendorDescription
                type: integrationType
                bureaus {
                    bureauCompanyId
                    name
                    defaultCheck
                    active
                }
                active
                credentials {
                    user
                }
            }
        }
    `;

    static GET_AUTOZOOM_SCORE_SHEET_ID = gql`
        query getAutoZoomScoreSheetId($accountNumber: Int!) {
            getAutoZoomScoreSheetId(accountNumber: $accountNumber)
        }
    `;

    static GET_AUTOZOOM_SCORE_SHEET = gql`
        query getAutoZoomScoreSheet($scoreSheetId: Int!) {
            getAutoZoomScoreSheet(scoreSheetId: $scoreSheetId) {
                stabilityScore
                dealStructureScore
                totalScore
            }
        }
    `;

    static GET_HISTORY_CREDITS = gql`
        query getHistoryCredits($recordId: Int!, $recordType: String!) {
            getHistoryCredits(recordId: $recordId, recordType: $recordType) {
                creditPullHistories {
                    type
                    bureaus
                    transactionId
                    reportType
                    createdOn
                    creditHistoryScoreId
                }
                prospectHistory {
                    createdOn
                    TRANSUNION
                    EXPERIAN
                    EQUIFAX
                    creditHistoryScoreId
                }
                coProspectHistory {
                    createdOn
                    TRANSUNION
                    EXPERIAN
                    EQUIFAX
                    creditHistoryScoreId
                }
            }
        }
    `;

    static GET_NEO_INTEGRATION_DETAIL = gql`
        query getCreditPullIntegration($apiIntegrationVendorId: Int!) {
            neoIntegrationDetail: getCreditPullIntegration(
                apiIntegrationVendorId: $apiIntegrationVendorId
            ) {
                companyApiIntegrationId
                active
                statuses
            }

            getNEOStatuses {
                statusId: neoStatusId
                statusLabel
                statusValue
            }
        }
    `;

    static GET_STATUSES_TO_FETCH = gql`
        query getNEOStatuses {
            getNEOStatuses {
                statusId: neoStatusId
                statusLabel
                statusValue
            }
        }
    `;
}

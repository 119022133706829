import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import CountryUtils from 'utils/CountryUtils';
import NumberFormat from 'react-number-format';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import EmploymentDialogContainer from 'components/containers/customer/read/EmploymentDialogContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const styles = (theme) => CustomerStyles.employmentDialog(theme);

class EmploymentDialog extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    render() {
        const {
            CUSTOMER_WRITE,
            props: {
                classes, isEditing, open, onClose,
                record, onChangeValue, listCity,
                onChangeCity, onChangeEndDate, beforeSaving,
                openConfirm, onCloseDialogConfirm, saveOrEdit,
                onEnableDecoder, isDecodingZip,
            },
        } = this;
        const {
            employer, title, workPhone, monthlyIncome, otherIncomeMonthly,
            otherIncomeSource, start, end, isCurrentEmployment, address1, address2,
            zipCode, county, state, country, city,
        } = record;
        const prefixTitle = isEditing ? 'Edit' : 'New';
        const isValidDateEnd = DateUtils.isValid(end);

        const recordToValidate = { ...record };
        delete recordToValidate.isCurrentEmployment;

        const allowToSave = !Object.values(recordToValidate).every((i) => isEmpty(i) || i == null || i === 0);

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Employment`} onClose={onClose} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={6} className="am-form">
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={employer}
                                    onChange={(e) => onChangeValue('employer', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={title}
                                    onChange={(e) => onChangeValue('title', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Work Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={workPhone}
                                    onChange={(e) => onChangeValue('workPhone', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Monthly Income</Form.Label>
                                <InputNumber
                                    showCurrency
                                    value={monthlyIncome}
                                    thousandSeparator
                                    onChange={(value) => onChangeValue('monthlyIncome', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col} className={classes.marginBottom0}>
                                        <Form.Label>Other Income</Form.Label>
                                        <InputNumber
                                            showCurrency
                                            value={otherIncomeMonthly}
                                            thousandSeparator
                                            placeholder="Amount"
                                            onChange={(value) => onChangeValue('otherIncomeMonthly', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className={classes.marginBottom0}>
                                        <Form.Label className="blank-label" />
                                        <Form.Control
                                            type="text"
                                            value={otherIncomeSource}
                                            placeholder="Source"
                                            onChange={(e) => onChangeValue('otherIncomeSource', e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} className={classes.marginBottom0}>
                                <Form.Row>
                                    <Form.Group as={Col} className={clsx(classes.marginBottom0, 'full-width-date-picker')}>
                                        <Form.Label>From</Form.Label>
                                        <DatePicker
                                            selected={start}
                                            className="form-control"
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeValue('start', date)}
                                            maxDate={end}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className={clsx(classes.marginBottom0, 'full-width-date-picker')}>
                                        <Form.Label>To</Form.Label>
                                        <DatePicker
                                            selected={end}
                                            className="form-control"
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeEndDate(date)}
                                            minDate={start}
                                        />
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check
                                                checked={isCurrentEmployment}
                                                disabled={isValidDateEnd}
                                                type="checkbox"
                                                label="Is current employment"
                                                className="am-mt-8"
                                                onChange={(e) => onChangeValue('isCurrentEmployment', e.target.checked)}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address1}
                                    onChange={(e) => onChangeValue('address1', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address2}
                                    onChange={(e) => onChangeValue('address2', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className={classes.marginBottom0}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Zip Code</Form.Label>
                                        <NumberFormat
                                            format="#####"
                                            value={zipCode}
                                            className="form-control"
                                            disabled={isDecodingZip}
                                            onFocus={onEnableDecoder}
                                            onValueChange={({ value }) => onChangeValue('zipCode', value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>City</Form.Label>
                                        <Select
                                            name="city"
                                            allowToCreate
                                            value={city}
                                            options={listCity}
                                            placeholder="Select"
                                            maxMenuHeight={170}
                                            onChange={onChangeCity}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} className={classes.marginBottom0}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>County</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            value={county}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            value={state}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    value={country}
                                    placeholder="Select"
                                    options={CountryUtils.getCountry()}
                                    maxMenuHeight={140}
                                    onChange={onChangeValue}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    onClickSecondary={onClose}
                    onClickPrimary={beforeSaving}
                    disablePrimaryButton={!allowToSave}
                    hiddenPrimaryButton={!CUSTOMER_WRITE}
                />
                <ConfirmDialog
                    title="Confirm current employment"
                    description="You didn't provided an End Date. Do you want to make this the current employment?"
                    open={openConfirm}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    onClose={onCloseDialogConfirm}
                    onClickSecondary={() => saveOrEdit(false)}
                    onClickPrimary={() => saveOrEdit(true)}
                />
            </Dialog>
        );
    }
}

EmploymentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isDecodingZip: PropTypes.bool.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    record: PropTypes.shape({
        employer: PropTypes.string,
        title: PropTypes.string,
        workPhone: PropTypes.string,
        monthlyIncome: PropTypes.number,
        otherIncomeMonthly: PropTypes.number,
        otherIncomeSource: PropTypes.string,
        end: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        start: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        isCurrentEmployment: PropTypes.bool,
        address1: PropTypes.string,
        address2: PropTypes.string,
        zipCode: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        city: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]),
        county: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]),
    }),
    saveOrEdit: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
    beforeSaving: PropTypes.func.isRequired,
    onChangeEndDate: PropTypes.func.isRequired,
    onCloseDialogConfirm: PropTypes.func.isRequired,
    listCity: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
};

EmploymentDialog.defaultProps = {
    record: {},
};

export default withStyles(styles)(EmploymentDialogContainer(EmploymentDialog));

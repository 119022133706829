export default class PhotosGalleryStyle {
    static gallery() {
        return ({
            main: {
                display: 'flex',
                '& > div': {
                    padding: 5,
                },
            },
        });
    }
}

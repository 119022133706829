import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import {
    Button, makeStyles,
} from '@material-ui/core';
import {
    useQuery,
    useApolloClient,
} from '@apollo/client';
import { Form, Col } from 'react-bootstrap';
import { SUPPORTED_FILES_EXT } from 'utils/enum/OpenAIEnum';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import VirtualTable from 'components/widgets/VirtualTable';
import FileEditor from 'components/modules/settings/ai/FileEditor';

// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        overflow: 'hidden',
        minWidth: '800px',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(2), & > button:nth-child(3)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
    uploader: {
        display: 'none',
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
}));

const AssistantFiles = forwardRef((props, ref) => {
    const { files, parentActionInProgress, tableHight } = props;
    const classes = { ...useStyles(), ...buttonStyles() };
    const client = useApolloClient();
    const resourceUploader = useRef(null);
    const replacementUploader = useRef(null);

    const [state, setState] = useState({
        globalFiles: [],
        selectedFile: null,
        selectedFiles: files?.length > 0 ? files.map((f) => ({
            name: f.name,
            size: f.size,
            path: f.url,
            fileId: f.aiAssistantExternalFileId,
        })) : [],
        documentName: null,
        isUploadingResource: false,
        isFileEditorOpen: false,
        selectedLocalFile: null,
    });

    const {
        globalFiles,
        selectedFile,
        selectedFiles,
        documentName,
        selectedLocalFile,
        isUploadingResource,
        isFileEditorOpen,
    } = state;

    const {
        data: filesData,
        loading: loadingFiles,
        error: errorLoadingFiles,
    } = useQuery(AIQuery.LIST_GLOBAL_FILES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorLoadingFiles) {
            ModalUtils.errorMessage(errorLoadingFiles?.graphQLErrors);
            return;
        }

        if (!loadingFiles) {
            const data = filesData?.listGlobalFiles;
            if (data) {
                setState((prevState) => ({
                    ...prevState,
                    globalFiles: data,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFiles, errorLoadingFiles]);

    useImperativeHandle(ref, () => ({
        getFilesSelected() {
            return selectedFiles;
        },
    }));

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const uploadResource = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        if (!Object.values(SUPPORTED_FILES_EXT).includes(ext)) {
            ModalUtils.errorMessage(null, `Only ${Object.values(SUPPORTED_FILES_EXT).map((ex) => ex.replace('.', '')).join(', ')} documents are supported`);
            return;
        }

        const clone = cloneDeep(selectedFiles);
        if (clone.some((f) => f.name === documentName)) {
            ModalUtils.errorMessage(null, 'There is a file with that name already added');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = NumberUtils.round(size / 1024 / 1024);
            const { data } = await client.mutate({
                mutation: AIMutation.UPLOAD_FILE_ON_BUCKET,
                variables: {
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const path = data?.uploadFileOnBucket;
            if (path) {
                clone.push({
                    path,
                    name: documentName ?? StringUtils.toPascalCase(name.replace(ext, '')),
                    size: sizeInMB,
                });

                setState((prevState) => ({
                    ...prevState,
                    documentName: null,
                    selectedFiles: clone,
                    isUploadingResource: false,
                }));

                ModalUtils.successMessage(null, 'File uploaded successfully');
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const openFileDialog = (isReplacement = false, record) => {
        if (isReplacement) {
            setState((prevState) => ({
                ...prevState,
                selectedLocalFile: record,
            }));

            const { current } = replacementUploader;
            if (current) current.click();

            return;
        }

        const { current } = resourceUploader;
        if (current) current.click();
    };

    const addGlobalFile = () => {
        const clone = cloneDeep(selectedFiles);
        const current = globalFiles.find((f) => f.aiGlobalFileId === selectedFile);
        if (clone.some((f) => f.name === current.name)) {
            ModalUtils.errorMessage(null, 'File already added');
            return;
        }

        clone.push({
            path: current.url,
            name: current.name,
            size: current.size,
        });

        setState((prevState) => ({
            ...prevState,
            selectedFile: null,
            selectedFiles: clone,
        }));
    };

    const deleteFile = (record) => {
        const clone = cloneDeep(selectedFiles);
        setState((prevState) => ({
            ...prevState,
            selectedFiles: clone.filter((f) => f.name !== record.name),
        }));
    };

    const toggleFileEditor = (record, _, filedEditedSize) => {
        const clone = cloneDeep(selectedFiles);
        if (filedEditedSize) {
            const current = clone.find((f) => f.name === selectedLocalFile.name && f.path === selectedLocalFile.path);
            if (current) current.size = filedEditedSize;
        }

        setState((prevState) => ({
            ...prevState,
            selectedLocalFile: record ? { ...record, url: record.path } : null,
            selectedFiles: clone,
            isFileEditorOpen: !isFileEditorOpen,
        }));
    };

    const replaceFile = async ({ target }) => {
        const file = target.files[0];
        const { size, name } = file;
        const ext = name.substr(name.lastIndexOf('.'))?.toLowerCase();

        // eslint-disable-next-line no-param-reassign
        target.value = null;

        if (!selectedLocalFile.path.endsWith(ext)) {
            ModalUtils.errorMessage(null, 'The same file type is required to update.');
            return;
        }

        const maxSizeAllowed = 20971520; // 20MB in bytes
        if (size > maxSizeAllowed) {
            ModalUtils.errorMessage(null, 'Max file allowed is 20MB.');
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isUploadingResource: true,
        }));

        try {
            const sizeInMB = NumberUtils.round(size / 1024 / 1024);
            const { data } = await client.mutate({
                mutation: AIMutation.EDIT_FILE_ON_BUCKET,
                variables: {
                    path: selectedLocalFile.path,
                    file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.editFileOnBucket;
            if (response) {
                const clone = cloneDeep(selectedFiles);
                const current = clone.find((f) => f.name === selectedLocalFile.name && f.path === selectedLocalFile.path);
                if (current) current.size = sizeInMB;

                setState((prevState) => ({
                    ...prevState,
                    selectedLocalFile: null,
                    selectedFiles: clone,
                    isUploadingResource: false,
                }));

                ModalUtils.successMessage(null, 'File updated successfully');
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isUploadingResource: false,
            }));

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const actionInProgress = isUploadingResource
    || parentActionInProgress;
    const filesColumns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 250,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.name}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Size',
            dataKey: 'size',
            width: 70,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{`${record.size} MB`}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'URL',
            dataKey: 'url',
            width: 350,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                return (
                    <span className={classes.text}>{record.path}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Action',
            dataKey: 'action',
            width: 130,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { path } = record;
                const isTxt = path?.endsWith('.txt');
                const isGlobal = path?.includes('global');

                return (
                    <div className={classes.actionsContainer}>
                        {!isGlobal && (
                            <>
                                <input
                                    ref={replacementUploader}
                                    className={classes.uploader}
                                    type="file"
                                    onChange={replaceFile}
                                />
                                <Button
                                    disabled={actionInProgress}
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    startIcon={<CloudUploadOutlinedIcon />}
                                    onClick={() => openFileDialog(true, record)}
                                />
                                {isTxt && (
                                    <Button
                                        disabled={actionInProgress}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        startIcon={<EditOutlinedIcon />}
                                        onClick={() => toggleFileEditor(record)}
                                    />
                                )}
                            </>
                        )}
                        <Button
                            disabled={actionInProgress}
                            className={classes.containedError}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => deleteFile(record)}
                        />
                    </div>
                );
            },
        },
    ];

    const parentWidth = document.getElementById('main-container')?.clientWidth;
    let filesTableWidth = filesColumns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > filesTableWidth) filesTableWidth = parentWidth - 40;
    return (
        <>
            <div>
                <Form.Group as={Col}>
                    <Form.Label className={classes.labels}>Files</Form.Label>
                    <div>
                        <div>
                            <Form.Control
                                placeholder="Name the document (optional)"
                                maxLength={255}
                                className={classes.input}
                                type="text"
                                name="documentName"
                                value={documentName || ''}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                            <input
                                ref={resourceUploader}
                                className={classes.uploader}
                                type="file"
                                onChange={uploadResource}
                            />
                            <Button
                                disabled={actionInProgress}
                                className={classes.containedSecondaryInfo}
                                size="small"
                                onClick={() => openFileDialog()}
                            >
                                Add
                            </Button>
                        </div>
                        <div>
                            <Select
                                placeholder="Global File"
                                size="sm"
                                loading={loadingFiles}
                                className={classes.input}
                                name="selectedFile"
                                onChange={(name, value) => onChange(name, value)}
                                value={selectedFile ?? ''}
                                options={globalFiles.map((gf) => ({
                                    value: gf.aiGlobalFileId,
                                    label: gf.name,
                                }))}
                            />
                            <Button
                                disabled={StringUtils.isEmpty(selectedFile) || actionInProgress}
                                className={classes.containedSecondaryInfo}
                                size="small"
                                onClick={addGlobalFile}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                    <div className={classes.tableContainer} style={{ height: `${tableHight}px` }}>
                        {parentWidth > 0 && (
                            <VirtualTable
                                loading={loadingFiles}
                                rowHeight={45}
                                totalRecords={selectedFiles.length}
                                data={selectedFiles}
                                columns={filesColumns}
                                width={filesTableWidth}
                            />
                        )}
                    </div>
                </Form.Group>
            </div>
            {isFileEditorOpen && (
                <FileEditor file={selectedLocalFile} toggleFileEditor={toggleFileEditor} />
            )}
        </>
    );
});

AssistantFiles.defaultProps = {
    files: [],
    parentActionInProgress: false,
    tableHight: 300,
};

AssistantFiles.propTypes = {
    files: PropTypes.any,
    parentActionInProgress: PropTypes.bool,
    tableHight: PropTypes.number,
};

export default AssistantFiles;

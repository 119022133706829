import React, { Component } from 'react';

import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: theme.spacing(2),
    },
    hiddenPadding: {
        padding: 0,
    },
    container: {
        background: theme.palette.background.default,
    },
});

class Container extends Component {
    render() {
        const {
            props: {
                classes, padding, className, backgroundColor,
            }, props,
        } = this;
        const hiddenPadding = padding ? '' : classes.hiddenPadding;
        const currentBackground = backgroundColor ? classes.container : '';

        return (
            <div className={clsx(classes.root, currentBackground)}>
                <div className={clsx(classes.content, hiddenPadding, className)}>
                    {props.children}
                </div>
            </div>
        );
    }
}

Container.propTypes = {
    padding: PropTypes.bool,
    className: PropTypes.string,
    backgroundColor: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Container.defaultProps = {
    backgroundColor: true,
    padding: true,
    className: '',
};

export default withStyles(styles)(Container);

import React, { Component } from 'react';

// Materia UI
import { InputGroup } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// Components and Others
import PropTypes from 'prop-types';
import InputNumber from 'components/widgets/InputNumber';
import clsx from 'clsx';

const styles = () => ({
    input: {
        '& .form-control': {
            backgroundColor: '#E9ECEF',
        },
    },
    button: {
        '& :hover': {
            backgroundColor: '#e3e6ea',
            borderColor: '#dce0e4',
            color: '#777777',
            transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        },
        '& :active': {
            backgroundColor: '#e3e6ea',
            borderColor: '#dce0e4',
            color: '#777777',
            opacity: '0.7',
            transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        },
    },
    white: {
        backgroundColor: '#FFFFFF',
        transition: '0.15s',
    },
    defaultCursor: {
        cursor: 'default',
    },
});

class InputNumberWithButton extends Component {
    render() {
        const {
            props: {
                value,
                classes,
                onClick,
                placeholder,
                showCurrency,
                allowNegative,
                thousandSeparator,
                fixedDecimalScale,
                disableButton,
                className,
                size,
            },
        } = this;

        const inputGroupAppendProps = { };
        const inputGroupTextProps = { };

        if (size === 'sm') {
            inputGroupTextProps.style = { paddingTop: '0px', paddingBottom: '0px' };
        }

        if (!disableButton) {
            inputGroupAppendProps.onClick = onClick;
            inputGroupAppendProps.className = classes.button;
            inputGroupTextProps.className = classes.white;
        } else {
            inputGroupTextProps.className = classes.defaultCursor;
        }

        return (
            <InputGroup className={clsx(classes.input, (size ? `input-group-${size}` : null))}>
                <InputNumber
                    disabled
                    className={className}
                    value={value}
                    showCurrency={showCurrency}
                    onChange={() => {}}
                    thousandSeparator={thousandSeparator}
                    fixedDecimalScale={fixedDecimalScale}
                    allowNegative={allowNegative}
                    placeholder={placeholder}
                    size={size}
                />
                <InputGroup.Append {...inputGroupAppendProps}>
                    <InputGroup.Text {...inputGroupTextProps}>
                        <MoreHorizIcon fontSize={(size === 'sm' ? 'small' : 'default')} />
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

InputNumberWithButton.propTypes = {
    onClick: PropTypes.func,
    showCurrency: PropTypes.bool,
    placeholder: PropTypes.string,
    allowNegative: PropTypes.bool,
    disableButton: PropTypes.bool,
    thousandSeparator: PropTypes.bool,
    fixedDecimalScale: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.string,
};

InputNumberWithButton.defaultProps = {
    onClick: () => {},
    value: '',
    placeholder: '',
    showCurrency: false,
    allowNegative: false,
    thousandSeparator: false,
    fixedDecimalScale: false,
    disableButton: false,
    className: '',
    size: '',
};

export default withStyles(styles)(InputNumberWithButton);

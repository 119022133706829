import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import payrollSettingsQuery from 'services/graphQL/query/payroll/Settings';

const useEmployeeTypeList = () => {
    const [employeeTypeList, setEmployeeTypeList] = useState([]);
    const { data, loading, error } = useQuery(payrollSettingsQuery.GET_EMPLOYEE_TYPE_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getEmployeeTypeList)) {
            const { getEmployeeTypeList } = data;
            const result = getEmployeeTypeList.map((item) => ({ value: item.employeeTypeId, label: item.description }));
            setEmployeeTypeList([{
                label: 'All Employee Types',
                value: 0,
            }, ...result]);
        }
    }, [data, loading, error]);

    return {
        employeeTypeList,
    };
};

export default useEmployeeTypeList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
    Radio, RadioGroup, FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { PRICING_SOURCE, PACKAGE_DISTRIBUTION } from 'utils/enum/MenuEnum';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    '@global': {
        '.MuiDialog-root': {
            zIndex: 'initial !important',
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '17px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& > div:nth-child(1)': {
                width: '50%',
                marginRight: '5px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                '& > div:nth-child(1)': {
                    marginBottom: '15px',
                },
                '& div.title': {
                    fontSize: '13px',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    paddingBottom: '2px',
                },
                '& div.radios > div': {
                    marginTop: '10px',
                    marginLeft: '10px',
                    '& > label > span:nth-child(1)': {
                        padding: 0,
                    },
                    '& > label > span:nth-child(2)': {
                        fontSize: '13px',
                        marginLeft: '5px',
                    },
                },
            },
            '& > div:nth-child(2)': {
                width: '50%',
                height: '500px',
                overflowX: 'hidden',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                border: `1px solid ${theme.palette.border.ghost}`,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginTop: '15px',
                },
                '& > div': {
                    padding: '10px',
                    cursor: 'pointer',
                },
            },
        },
        '& > div:nth-child(2)': {
            borderTop: `1px solid ${theme.palette.border.ghost}`,
            marginTop: '10px',
            '& > div:nth-child(1)': {
                fontSize: '13px',
                fontWeight: 500,
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'center',
                '& > span': {
                    padding: 0,
                    marginRight: '5px',
                    '& svg': {
                        width: '20px',
                        height: '20px',
                    },
                },
            },
            '& > div:nth-child(2)': {
                fontSize: '13px',
            },
        },
    },
    productWrapper: {
        '& > div.disabled': {
            color: `${theme.palette.text.secondary} !important`,
        },
        '& > div.name': {
            fontSize: '11px',
            color: theme.palette.secondaryInfo.main,
            textTransform: 'uppercase',
            overflow: 'hidden',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '& > span': {
                padding: 0,
                '& svg': {
                    width: '16px',
                    height: '16px',
                },
            },
        },
        '& > div.extra': {
            fontWeight: 500,
            fontSize: '10px',
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
}));

const UserSettingsForm = ({
    userSettings,
    products,
    onClose,
    onSave,
    onSort,
    updatingSettings,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        pricingVisibility: userSettings?.pricingVisibility || PRICING_SOURCE.SHOW_TOTAL_PRICE,
        packageDistribution: userSettings?.packageDistribution || PACKAGE_DISTRIBUTION.PARTIAL_FILL,
        saveSettings: userSettings?.saveSettings || false,
        innerProducts: onSort(userSettings, products.map((p) => ({ ...p, available: true }))),
    });

    const {
        pricingVisibility,
        packageDistribution,
        saveSettings,
        innerProducts,
    } = state;

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const productsSorted = arrayMove(
                innerProducts,
                oldIndex,
                newIndex,
            );

            setState((prevState) => ({
                ...prevState,
                innerProducts: productsSorted,
            }));
        }
    };

    const onChange = (field, value, productId) => {
        if (productId) {
            const clone = cloneDeep(innerProducts);
            const product = clone.find((item) => item.id === productId);
            if (product) product[field] = value;

            setState((prevState) => ({
                ...prevState,
                innerProducts: clone,
            }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const SortableItem = SortableElement(({ value: product, sortIndex }) => (
        <div className={classes.productWrapper} key={sortIndex}>
            <div className={product.isDisabled ? 'name disabled' : 'name'}>
                <Checkbox
                    checked={product.available}
                    onChange={(_, value) => onChange('available', value, product.id)}
                />
                {`${product.productName} - ${product.vendor.vendorName}`}
            </div>
            <div className="extra">
                {`${product.productType} / ${product.vendor.provider}`}
            </div>
        </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
        <div className="products">
            {
                items.map((product, index) => (
                    <SortableItem
                        key={`category-${index}`}
                        index={index}
                        sortIndex={index}
                        value={product}
                    />
                ))
            }
        </div>
    ));

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Deals Settings"
                onClose={onClose}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div>
                        <div className="options">
                            <div>
                                <div className="title">Display Menu Pricing</div>
                                <div className="radios">
                                    <RadioGroup value={pricingVisibility} onChange={(event) => onChange('pricingVisibility', event.target.value)}>
                                        <FormControlLabel value={PRICING_SOURCE.NO_PRICES} control={<Radio />} label="No Prices" />
                                        <FormControlLabel value={PRICING_SOURCE.SHOW_TOTAL_PRICE} control={<Radio />} label="Show Total Price" />
                                        <FormControlLabel value={PRICING_SOURCE.SHOW_COST_DETAILS} control={<Radio />} label="Show Daily and Monthly Cost" />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div>
                                <div className="title">Package&apos;s Products Behavior</div>
                                <div className="radios">
                                    <RadioGroup value={packageDistribution} onChange={(event) => onChange('packageDistribution', event.target.value)}>
                                        <FormControlLabel
                                            value={PACKAGE_DISTRIBUTION.PARTIAL_FILL}
                                            control={<Radio />}
                                            label="Populate Columns Automatically"
                                        />
                                        <FormControlLabel
                                            value={PACKAGE_DISTRIBUTION.FULL_FILL}
                                            control={<Radio />}
                                            label="Populate All Columns with Selected Products"
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                        <SortableList
                            axis="y"
                            distance={10}
                            items={innerProducts}
                            onSortEnd={onSortEnd}
                        />
                    </div>
                    <div>
                        <div>
                            <Checkbox
                                checked={saveSettings}
                                onChange={(_, value) => onChange('saveSettings', value)}
                            />
                            Save Profile
                        </div>
                        <div>
                            This will save your custom settings in the menu.
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    disabled={updatingSettings}
                    size="small"
                    className={classes.containedSecondaryInfo}
                    onClick={() => onSave(
                        pricingVisibility,
                        packageDistribution,
                        saveSettings,
                        innerProducts,
                    )}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UserSettingsForm.defaultProps = {
    products: [],
    userSettings: {},
};

UserSettingsForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    updatingSettings: PropTypes.bool.isRequired,
    products: PropTypes.array,
    userSettings: PropTypes.object,
};

export default UserSettingsForm;

import React, { Component } from 'react';

// Components and Others
import { clone, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import PortfolioService from 'services/modules/PortfolioService';
import moment from 'moment';

import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerMap from 'services/mapData/CustomerMap';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import PortfolioTabs from 'utils/enum/PortfolioEnum';

import StringUtils from 'lib/StringUtils';

const PortfolioSettingsDialogContainer = (WrappedComponent) => class extends Component {
  static propTypes = {
      isEditing: PropTypes.bool,
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      onSave: PropTypes.func.isRequired,
      productId: PropTypes.number,
      portfolioId: PropTypes.number,
  };

  static defaultProps = {
      isEditing: false,
      productId: null,
      portfolioId: null,
  }

  constructor(props) {
      super(props);
      this.graphqlClient = new GraphQLClient();
      this.portfolioService = new PortfolioService();

      this.initBind();
  }

  /* eslint-disable react/state-in-constructor */
  state = {
      record: {
          creditReportingCompanyId: null,
          ownerCompany: '',
          creditCompany: '',
          accountingClass: '',
          type: '',
          description: '',
          name: '',
          originationDate: moment().format('YYYY-MM-DD'),
          activelyUsed: false,
          inHouse: false,
          stockIsLoanNumber: false,
          loanNumberPrefix: 0,
          nextLoanNumber: 0,
          addressName: '',
          textName: '',
          address1: '',
          address2: '',
          zip: '',
          city: '',
          state: '',
          phone: '',
          fax: '',
          website: '',
          receiptMessage: '',
          convenienceFee: 0,
          imported: false,
          isDefault: false,
          achConvenienceFee: 0,
      },
      isLoading: false,
      selectedTab: PortfolioTabs.DETAIL,
      zipData: [],
      listCity: [],
      isDecoderEnabled: false,
      isDecodingZip: false,
      listCreditCompany: [],
  };

  componentDidMount() {
      const { props: { isEditing } } = this;
      if (isEditing) {
          this.getPortfolioDetail();
      }
      this.getPortfolioCreditCompany();
  }

  onEnableDecoder() {
      const { state: { isDecoderEnabled } } = this;
      if (!isDecoderEnabled) {
          this.setState({ isDecoderEnabled: true });
      }
  }

  onTabChange(_, value) {
      if (value === PortfolioTabs.DETAIL) {
          this.setState({ selectedTab: PortfolioTabs.DETAIL });
      } else if (value === PortfolioTabs.NOTES) {
          this.setState({ selectedTab: PortfolioTabs.NOTES });
      }
  }

  onChangeValue(field, value) {
      this.setState(({ record }) => {
          const newRecord = { ...record };

          const numericFields = ['loanNumberPrefix', 'nextLoanNumber'];
          const boolFields = ['activelyUsed', 'inHouse', 'stockIsLoanNumber'];
          if (numericFields.includes(field)) {
              newRecord[field] = isEmpty(value) ? 0 : Number(value);
          } else if (boolFields.includes(field)) {
              newRecord[field] = !!(value === true || value === 'Yes');
          } else if (field === 'originationDate') {
              newRecord[field] = moment(value).format('YYYY-MM-DD');
          } else {
              newRecord[field] = value;
          }

          return { record: newRecord };
      }, () => {
          if (field === 'zip' && value.length === 5) {
              const { state: { isDecoderEnabled } } = this;
              if (isDecoderEnabled) {
                  this.decodeZip(value);
              }
          }
      });
  }

  getPortfolioDetail() {
      const { props: { portfolioId } } = this;

      this.setState({ isLoading: true });
      this.portfolioService.getPortfolioDetail({ id: portfolioId })
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data && data.portfolio) {
                  const { portfolio } = data;
                  this.setState({ record: portfolio, isLoading: false });
              }
          });
  }

  getPortfolioCreditCompany() {
      this.portfolioService.getPortfolioCreditCompanies()
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }
              if (data && data.portfolioCreditCompanies) {
                  const { portfolioCreditCompanies } = data;
                  this.setState({ listCreditCompany: portfolioCreditCompanies });
              }
          });
  }

  decodeZip(zip = '', ignoreCity = false) {
      const input = {
          zip,
      };

      this.setState({ isDecodingZip: true });
      this.graphqlClient
          .query(CustomerQuery.DECODE_ZIP_CODE, input)
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data?.decodeZip) {
                  const { decodeZip } = data;

                  /* In order to keep the city value, we need
              to assign it using the record from database */
                  if (ignoreCity && decodeZip.length > 0) {
                      const { state: { record } } = this;
                      const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.insuranceCity));

                      if (!alreadyExist) {
                          const newData = clone(decodeZip[0]);
                          newData.city = record.insuranceCity;
                          decodeZip.unshift(newData);
                      }
                  }

                  const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                  if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                  const zipData = decodeZip;

                  this.setState(({ record, isDecoderEnabled }) => {
                      if (isDecoderEnabled) {
                          const newRecord = {
                              ...record,
                              city: '',
                              state: '',
                          };
                          return {
                              listCity, zipData, record: newRecord, isDecodingZip: false,
                          };
                      }
                      return { listCity, zipData, isDecodingZip: false };
                  });

                  if (decodeZip.length === 1) {
                      const currentData = decodeZip[0];

                      this.setState(({ record }) => {
                          const newRecord = {
                              ...record,
                              city: currentData.city,
                              state: currentData.state,
                          };

                          return { record: newRecord };
                      });
                  }
              }
          });
  }

  initBind() {
      this.onChangeValue = this.onChangeValue.bind(this);
      this.getPortfolioDetail = this.getPortfolioDetail.bind(this);
      this.decodeZip = this.decodeZip.bind(this);
      this.onEnableDecoder = this.onEnableDecoder.bind(this);
      this.onTabChange = this.onTabChange.bind(this);
  }

  render() {
      const { props, state } = this;

      return (
          <WrappedComponent
              {...props}
              {...state}
              onSave={props.onSave}
              onClose={props.onClose}
              onChangeValue={this.onChangeValue}
              onCloseDialogConfirm={this.onCloseDialogConfirm}
              onEnableDecoder={this.onEnableDecoder}
              onTabChange={this.onTabChange}
          />
      );
  }
};

export default PortfolioSettingsDialogContainer;

import { gql } from '@apollo/client';

export default class PayrollGeneralMutation {
    static UPDATE_USER_QUICK_NOTE = gql`
        mutation updateUserQuickNote($userId: Int!, $quickNote: String!) {
            updateUserQuickNote(userId: $userId, quickNote: $quickNote)
        }
    `;

    static CLEAR_DRAW_BALANCE = gql`
        mutation clearDrawBalance($employeeId: Int!) {
            clearDrawBalance(employeeId: $employeeId)
        }
    `;
}

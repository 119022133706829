import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/widgets/Container';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import SplitAmountSettings from 'components/modules/settings/accounting/SplitAmountSettings';
import CashierMappingsLayout from 'components/modules/settings/accounting/CashierMappingsLayout';
import AccountDefaultSettings from 'components/modules/settings/accounting/AccountDefaultSettings';
import AccountingCOA from 'components/modules/accounting/accounts/AccountingCOA';
import {
    ListAltOutlinedIcon, LocalAtmOutlinedIcon,
    ExposureOutlinedIcon, TuneOutlinedIcon, SyncAltOutlinedIcon,
} from 'components/icons';
import MovingGLBetweenAccount from 'components/modules/settings/accounting/MovingGLBetweenAccount';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = [];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    if (keyStore.hasPermission(Permission.ACCOUNTING_SETTINGS_READ)) { // accounting_tools
        settings.push(
            {
                index: 0,
                text: 'Default Settings',
                icon: <TuneOutlinedIcon />,
                component: <AccountDefaultSettings />,
            },
            {
                index: 1,
                text: 'Split Amount Between Lots',
                icon: <ExposureOutlinedIcon />,
                component: <SplitAmountSettings />,
            },
            {
                index: 2,
                text: 'Batchout Mappings',
                icon: <LocalAtmOutlinedIcon />,
                component: <CashierMappingsLayout />,
            },
            {
                index: 3,
                text: 'Move GLs between accounts',
                icon: <SyncAltOutlinedIcon />,
                component: <MovingGLBetweenAccount />,
            },
        );
        subTabs.push('default', 'split-amount', 'cashier-mappings', 'moving-gl-transactions');
    }

    if (keyStore.hasPermission(Permission.ACCOUNTING_COA_READ)) {
        settings.push(
            {
                index: 4,
                text: 'Chart of Accounts',
                icon: <ListAltOutlinedIcon />,
                component: <AccountingCOA />,
            },
        );
        subTabs.push('coa');
    }

    return settings;
};

const AccountingSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();

    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/settings/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();

    return (
        <>
            <Container className={classes.rootContainer}>
                <List component="nav" className={classes.sideBar}>
                    {settingsItems.map((item) => (
                        <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.content}>
                    {settingsItems.map((item) => (
                        activeIndex === item.index && <div key={item.index}>{item.component}</div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default AccountingSettings;

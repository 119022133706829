import { gql } from '@apollo/client';

export default class LostReasonQuery {
    static GET_LOST_REASON_LIST = gql`
        query getLostReasonList($filters: LostReasonFilterInput) {
            getLostReasonList(filters: $filters) {
                description
                lostReasonId
                createdOn
                modifiedOn
            }
        }
    `;
}

/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import StringUtils from 'lib/StringUtils';

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;
const phoneRegex = /^[+]?[0-9]{3}?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
const replaceInvalidPhoneRegex = /[-0]/gi;
const lastNameRegex = /^(.*)?\S+(.*)?$/;

const CustomerSchema = yup.object().shape({
    firstName: yup.string().required().matches(/^(.*)?\S+(.*)?$/),
    lastName: yup.string().nullable().test(
        'required',
        'Last Name is required.',
        function (lastName) {
            const { isBusiness } = this.parent;
            const lastNameValue = lastName?.trim();

            return ((lastNameValue?.length > 0 && lastNameRegex.test(lastNameValue))
                || (lastNameValue?.length === 0 && isBusiness));
        },
    ),
    email: yup.string().nullable().test(
        'required',
        'Email is required.',
        function (email) {
            const { cellPhone } = this.parent;
            const emailValue = (email || '')?.trim();
            const cellPhoneValue = cellPhone.trim();

            return (StringUtils.isValidEmail(emailValue)
                || ((!StringUtils.isValidEmail(emailValue))
                    && (cellPhoneValue.replaceAll(replaceInvalidPhoneRegex, '').length > 0 && phoneRegex.test(cellPhoneValue))));
        },
    ),
    cellPhone: yup.string().test(
        'required',
        'Cell phone is required.',
        function (cellPhone) {
            const { email } = this.parent;
            const emailValue = (email || '')?.trim();
            const cellPhoneValue = cellPhone.trim();

            return ((StringUtils.isValidEmail(emailValue)
                    && (cellPhoneValue.length === 0 || phoneRegex.test(cellPhoneValue)))
                || (cellPhoneValue.replaceAll(replaceInvalidPhoneRegex, '').length > 0 && phoneRegex.test(cellPhoneValue)));
        },
    ),
});

const BusinessSchema = yup.object().shape({
    firstName: yup.string().required().matches(/^(.*)?\S+(.*)?$/),
    email: yup.string().nullable().test(
        'required',
        'Email is required.',
        function (email) {
            const { cellPhone } = this.parent;
            const emailValue = (email || '')?.trim();
            const cellPhoneValue = cellPhone.trim();

            return ((emailValue?.length > 0 && emailRegex.test(emailValue))
                || ((emailValue?.length === 0 || emailRegex.test(emailValue))
                    && (cellPhoneValue.replaceAll(replaceInvalidPhoneRegex, '').length > 0 && phoneRegex.test(cellPhoneValue))));
        },
    ),
    cellPhone: yup.string().test(
        'required',
        'Cell phone is required.',
        function (cellPhone) {
            const { email } = this.parent;
            const emailValue = (email || '')?.trim();
            const cellPhoneValue = cellPhone.trim();

            return ((emailValue?.length > 0 && emailRegex.test(emailValue)
                    && (cellPhoneValue.length === 0 || phoneRegex.test(cellPhoneValue)))
                || (cellPhoneValue.replaceAll(replaceInvalidPhoneRegex, '').length > 0 && phoneRegex.test(cellPhoneValue)));
        },
    ),
});

export {
    CustomerSchema,
    BusinessSchema,
};

/* eslint-disable react/no-danger */
import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import update from 'immutability-helper';
import { useQuery } from '@apollo/client';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles, Typography, Button } from '@material-ui/core';
import UserSettingQuery from 'services/graphQL/query/setting/UserSettingQuery';
import EmailSignatureDialog from 'components/modules/settings/user/EmailSignatureDialog';
import Loading from 'components/widgets/Loading';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        alignItems: 'flex-start',
        overflow: 'auto',
        padding: theme.spacing(1),
    },
    aboutUserContainer: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4),
        width: '900px',
    },
    subTitle: {
        fontSize: '1.125rem',
        marginBottom: theme.spacing(1.5),
    },
    emailSignatureContainer: {
        width: '100%',
        height: '320px',
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid #d6d6d6',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1.5),
    },
    button: {
        color: theme.palette.background.eucalyptus,
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    emailSignature: {
        height: '100%',
        overflow: 'auto',
    },
}));

const initialState = {
    open: false,
    record: {
        userSettingId: null,
        emailSignature: '',
    },
};

const ACTION_TYPE = {
    TOGGLE_DIALOG: 'toggleDialog',
    SET_EMAIL_SIGNATURE: 'setEmailSignature',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.TOGGLE_DIALOG: {
        return update(state, {
            open: { $set: action.payload },
        });
    }
    case ACTION_TYPE.SET_EMAIL_SIGNATURE:
        const result = action.payload;
        return update(state, {
            record: {
                emailSignature: { $set: result.value },
                userSettingId: { $set: result.userSettingId },
            },
        });
    default:
        return state;
    }
};

const GeneralSetting = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        loading, data, error, refetch,
    } = useQuery(UserSettingQuery.GET_EMAIL_SIGNATURE, { fetchPolicy: FetchPolicy.NETWORK_ONLY });

    const toggleDialog = (value) => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_DIALOG,
            payload: value,
        });
    };

    useEffect(() => {
        if (!isEmpty(data?.getEmailSignature)) {
            dispatch({
                type: ACTION_TYPE.SET_EMAIL_SIGNATURE,
                payload: data.getEmailSignature,
            });
        }
    }, [data, error, loading]);
    const icon = state.record.userSettingId != null ? <CreateIcon fontSize="small" /> : null;
    const text = state.record.userSettingId != null ? 'Edit' : 'Add';

    return (
        <div className={classes.main}>
            <div className={clsx(classes.aboutUserContainer, 'box-shadow')}>
                <Typography variant="h4" className={classes.subTitle}>Email signature</Typography>
                <div className={classes.emailSignatureContainer}>
                    {loading && <Loading />}
                    {!loading && (
                        <>
                            <div
                                dangerouslySetInnerHTML={{ __html: state.record.emailSignature }}
                                className={classes.emailSignature}
                            />
                            <Button
                                size="small"
                                className={classes.button}
                                onClick={() => toggleDialog(true)}
                                startIcon={icon}
                            >
                                {text}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {state.open && (
                <EmailSignatureDialog
                    open={state.open}
                    refetch={refetch}
                    record={state.record}
                    onClose={() => toggleDialog(false)}
                />
            )}
        </div>
    );
};

export default GeneralSetting;

import { gql } from '@apollo/client';

export default class GpsQuery {
    static GET_GPS_INTEGRATION_PROVIDERS = gql`
        query getGpsIntegrationProviders {
            getGpsIntegrationProviders {
                id
                name
                hasDealerNumber
                configuration {
                    ptpOverride
                    warning
                    tone1
                    tone2
                    tone3
                    starterInterrupt
                    commandHistory
                    getBatteryInfo
                    setEnduranceMode
                    setTripMode
                    setActiveMode
                    setRecoveryMode
                    hasDeviceType
                }
            }
        }
    `;

    static GET_DEALER_GPS_INTEGRATIONS = gql`
        query getDealerGpsIntegrations {
            getDealerGpsIntegrations {
                id
                integrationId
                username
                password
                dealerNumber
                configuration {
                    ptpOverride
                    warningDays
                    warning
                    tone1Days
                    tone1
                    tone2Days
                    tone2
                    tone3Days
                    tone3
                    starterInterrupt
                    starterInterruptDays
                }
                active
                createdBy
                createdOn
                modifiedBy
                modifiedOn
            }
        }
    `;

    static GET_DEALER_GPS_INTEGRATIONS_BASIC = gql`
        query getDealerGpsIntegrations {
            getDealerGpsIntegrations {
                id
                integrationId
                dealerNumber
                configuration {
                    ptpOverride
                    warningDays
                    warning
                    tone1Days
                    tone1
                    tone2Days
                    tone2
                    tone3Days
                    tone3
                    starterInterrupt
                    starterInterruptDays
                }
            }
        }
    `;

    static TEST_GPS_INTEGRATION_CREDENTIALS = gql`
        query testGpsIntegrationCredentials(
            $provider: String!,
            $username: String!,
            $password: String!,
            $dealerNumber: String
        ) {
            testGpsIntegrationCredentials(
                provider: $provider,
                username: $username,
                password: $password,
                dealerNumber: $dealerNumber
            ) 
        }
    `;

    static VALIDATE_GPS_DEVICE = gql`
        query validateGpsDevice(
            $stockNumber: Int!,
            $integrationId: String!,
            $deviceId: String!
        ) {
            validateGpsDevice(
                stockNumber: $stockNumber,
                integrationId: $integrationId,
                deviceId: $deviceId
            ) 
        }
    `;

    static GET_GPS_DEVICE_TYPE = gql`
        query getGpsDeviceType(
            $stockNumber: Int!,
            $integrationId: String!,
            $deviceId: String!
        ) {
            getGpsDeviceType(
                stockNumber: $stockNumber,
                integrationId: $integrationId,
                deviceId: $deviceId
            ) {
                serialNumber
                imei
                productType
                productTypeDisplay
                unit
                eliteType
                installdate
            }
        }
    `;

    static GET_GPS_REQUESTS_LOG = gql`
        query getGpsRequestsLog(
            $stockNumber: Int!
        ) {
            getGpsRequestsLog(
                stockNumber: $stockNumber
            ) {
                id
                requestdate
                request {
                    resource
                    dealerNumber
                    manufacturerId
                    body
                    status
                    tone
                }
                response {
                    body
                    statusCode
                }
                username
                requestStatus
                restClient
                createdAt
            }
        }
    `;

    static GET_PROVIDER_COMMAND_LIST = gql`
        query getProviderCommandList(
            $deviceType: String!
        ) {
            getProviderCommandList(
                deviceType: $deviceType
            ) {
                id
                deviceTypes
                value
                label
                createdOn
            }
        }
    `;
}

import { isEmpty } from 'lodash';
import * as yup from 'yup';

const JournalSchema = yup.object().shape({
    referenceNumber: yup.number().required().moreThan(0),
    memo: yup.string(),
    postedDate: yup.date().required(),
    frequency: yup.number().when(['frequencyType'], {
        is: (val) => val !== 0 && !isEmpty(val),
        then: yup.number().required().moreThan(0),
        otherwise: yup.number(),
    }),
});

export default JournalSchema;

export default class VehicleErrorPageStyle {
    static main(theme) {
        return ({
            main: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                background: theme.palette.background.default,
                // TODO: remove this prop
                zIndex: 9000,
                justifyContent: 'center',
                alignItems: 'center',
            },
            labels: {
                color: theme.palette.primary.main,
            },
            button: {
                marginTop: 20,
                textDecoration: 'none',
            },
        });
    }
}

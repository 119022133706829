export default class ExpansionPanelStyles {
    static expansionPanel() {
        return ({
            root: {
                marginBottom: '10px',
                '&:before': {
                    display: 'none',
                },
                '&$expanded': {
                    margin: 0,
                    marginBottom: '10px',
                    marginLeft: '2px',
                    marginRight: '2px',
                },
                textDecoration: 'initial',
                display: 'inline-block',
                width: 'fit-content',
                minWidth: 'calc(100% - 4px)',
                boxShadow: '1px 1px 4px rgba(0,0,0, 0.1)',
                marginLeft: '2px',
                marginRight: '2px',
                border: '1px solid transparent',
            },
            expanded: {},
        });
    }

    static expandRow(theme = {}) {
        return ({
            expansionCol: {
                color: '#382652',
                display: 'flex',
            },
            textGray: {
                color: `${theme.palette.text.gray} !important`,
            },
            spacingVehicleOpenOn: {
                marginRight: theme.spacing(8),
            },
            colProspect: {
                flexDirection: 'column',
                maxWidth: 185,
                minWidth: 185,
            },
        });
    }
}

import React, { Component } from 'react';

// Others
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import ProductService from 'services/modules/ProductService';
import ProductSettingMap from 'services/mapData/ProductSettingMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import ProductsSubscription from 'services/graphQL/subscription/ProductsSubscription';

const ProductSettingsListContainer = (WrappedComponent) => class extends Component {
    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.productService = new ProductService();
        this.productListSubscription = null;

        this.initBind();
    }

    /* eslint-disable react/state-in-constructor */
    state = {
        products: [],
        loading: false,
        openDialog: false,
        openConfirmationDialog: false,
        isEditingProduct: false,
        selectedProductId: null,
        productStatus: true,
        searchTerm: '',
        isMobile: window.matchMedia('(max-width: 900px)').matches,
    }

    componentDidMount() {
        this.getServicesData();
        const handler = (e) => this.setState({ isMobile: e.matches });
        window.matchMedia('(max-width: 900px)').addEventListener('change', handler);
    }

    componentWillUnmount() {
        this.unsubscribeProductList();
    }

    onSave(input) {
        const { state: { isEditingProduct } } = this;

        if (isEditingProduct) {
            this.updateProduct(input);
        } else {
            this.createProduct(input);
        }
    }

    onSearch(searchTerm) {
        this.setState({ searchTerm }, () => {
            this.getServicesData();
        });
    }

    onChangeProductStatus(productStatus) {
        this.setState({ productStatus }, () => {
            this.getServicesData();
        });
    }

    setSelectedProductId(selectedProductId) {
        this.setState({ selectedProductId });
    }

    getServicesData() {
        const { searchTerm, productStatus } = this.state;
        const filter = {
            searchTerm,
            status: productStatus,
        };

        this.setState({ loading: true });
        this.productService.getList(filter)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.products) {
                    const { products } = data;
                    this.setState({ products });
                }
            })
            .finally(() => {
                this.setState({ loading: false, selectedProductId: null });
                this.subscribeProductList();
            });
    }

    createProduct(input) {
        const inputCreate = ProductSettingMap.mapProductSettingToSave(input);

        this.productService.createProduct({ input: inputCreate })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.addProduct) {
                    ModalUtils.successMessage(null, 'Product created successfully');
                    this.closeProductDialog();
                    this.getServicesData();
                }
            });
    }

    updateProduct(input) {
        const { selectedProductId } = this.state;
        const inputUpdate = ProductSettingMap.mapProductSettingToUpdate(input, selectedProductId);

        this.productService.updateProduct(inputUpdate)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateProduct) {
                    ModalUtils.successMessage(null, 'Product updated successfully');
                    this.closeProductDialog();
                    this.getServicesData();
                }
            });
    }

    openProductDialog(isEditing) {
        this.setState({
            openDialog: true,
            isEditingProduct: isEditing,
        });
    }

    closeProductDialog() {
        this.setState({ openDialog: false });
    }

    openConfirmDialog() {
        this.setState({
            openConfirmationDialog: true,
        });
    }

    closeConfirmDialog() {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    subscribeProductList() {
        this.unsubscribeProductList();
        const { searchTerm, productStatus } = this.state;
        const filter = {
            searchTerm,
            status: productStatus,
        };

        this.graphqlClient.subscribe(this.responseSubscription, ProductsSubscription.PRODUCT_VENDOR_LIST, filter)
            .then((response) => {
                this.productListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.productVendorList) {
            const { productVendorList: { type, product } } = data;

            switch (type) {
            case SubscriptionActionType.CREATED:
                this.addNewProduct(product);
                break;
            case SubscriptionActionType.UPDATED:
                this.updateProductOnList(product);
                break;
            default:
            }
        }
    }

    unsubscribeProductList() {
        if (this.productListSubscription) {
            this.productListSubscription.unsubscribe();
        }
    }

    addNewProduct(record) {
        this.setState(({ products }) => {
            const newRecords = update(products, { $unshift: [record] });

            return { products: newRecords };
        });
    }

    updateProductOnList(record) {
        this.setState(({ products }) => {
            const newRecords = [...products];

            const productIndex = newRecords.findIndex((item) => item.productId === record.productId);
            newRecords[productIndex] = { ...record };

            return { products: newRecords };
        });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.createProduct = this.createProduct.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
        this.openProductDialog = this.openProductDialog.bind(this);
        this.closeProductDialog = this.closeProductDialog.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.setSelectedProductId = this.setSelectedProductId.bind(this);
        this.subscribeProductList = this.subscribeProductList.bind(this);
        this.addNewProduct = this.addNewProduct.bind(this);
        this.updateProductOnList = this.updateProductOnList.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.onChangeProductStatus = this.onChangeProductStatus.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                openProductDialog={this.openProductDialog}
                closeProductDialog={this.closeProductDialog}
                openConfirmDialog={this.openConfirmDialog}
                closeConfirmDialog={this.closeConfirmDialog}
                setSelectedProductId={this.setSelectedProductId}
                onChangeProductStatus={this.onChangeProductStatus}
                onSearch={this.onSearch}
                onSave={this.onSave}
            />
        );
    }
};

export default ProductSettingsListContainer;

import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';

import Category, { LotDefaultskey } from 'utils/enum/LotsCategory';
import LotQuery from 'services/graphQL/query/LotQuery';
import UserContext from 'components/context/UserContext';

const initCombineAccountingState = { lotName: ALL_LOTS, combineAccounting: true };

const useCombineAccounting = (userInformation) => {
    const { defaultLot } = useContext(UserContext);
    const [state, setState] = useState(initCombineAccountingState);
    const { combineAccounting, lotName } = state;

    const [getCombineAccounting] = useLazyQuery(LotQuery.GET_SETTINGS, {
        onCompleted: (res) => {
            if (!res.getSettings[0]) {
                return;
            }
            setState({
                combineAccounting: (/^true$/i.test(res.getSettings[0].value)),
                lotName: defaultLot,
            });
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (userInformation && defaultLot) {
            getCombineAccounting({
                variables: {
                    category: Category.ACCOUNTING,
                    key: [LotDefaultskey.COMBINE_ACCOUNTING],
                    lotName: defaultLot,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation, defaultLot]);

    return {
        combineAccounting,
        lotName,
    };
};

export default useCombineAccounting;

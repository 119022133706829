import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import update from 'immutability-helper';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';

// GraphQL
import DealService from 'services/modules/DealService';
import ReferenceMap from 'services/mapData/ReferenceMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealSubscription from 'services/graphQL/subscription/DealSubscription';

const ReferenceTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        dealId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.referenceListSubscription = null;

        this.initBind();
    }

    /* eslint-disable react/state-in-constructor */
    state = {
        references: [],
        loading: false,
        openDialog: false,
        openConfirmationDialog: false,
        isEditingReference: false,
        selectedReferenceId: null,
    }

    componentDidMount() {
        this.getServicesData();
        this.subscribeReferencetList();
    }

    componentWillUnmount() {
        this.unsubscribeReferenceList();
    }

    onSave(input) {
        const { state: { isEditingReference } } = this;

        if (isEditingReference) {
            this.updateReference(input);
        } else {
            this.createReference(input);
        }
    }

    setSelectedReferenceId(selectedReferenceId) {
        this.setState({ selectedReferenceId });
    }

    getServicesData() {
        const { props: { dealId } } = this;
        const input = { accountNumber: dealId };

        this.setState({ loading: true });
        this.dealService.getDealReferenceList(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.references) {
                    const { references } = data;

                    this.setState({ references });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    createReference(input) {
        const { dealId } = this.props;
        const inputCreate = ReferenceMap.mapReferenceToSave(input, dealId);

        this.dealService.createReference({ input: inputCreate })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.createDealReference) {
                    ModalUtils.successMessage(null, 'Reference created successfully');
                    this.closeReferenceDialog();
                }
            });
    }

    updateReference(input) {
        const { selectedReferenceId } = this.state;
        const inputUpdate = ReferenceMap.mapReferenceToUpdate(input, selectedReferenceId);

        this.dealService.updateReference(inputUpdate)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateDealReference) {
                    ModalUtils.successMessage(null, 'Reference updated successfully');
                    this.closeReferenceDialog();
                }
            });
    }

    deleteReference() {
        const { state: { selectedReferenceId } } = this;

        if (selectedReferenceId) {
            this.dealService.deleteReference({ dealReferenceId: selectedReferenceId })
                .then((response) => {
                    const { data, graphQLErrors } = response;

                    if (graphQLErrors) {
                        ModalUtils.errorMessage(graphQLErrors);
                        return;
                    }

                    if (data && data.deleteDealReference) {
                        ModalUtils.successMessage(null, 'Reference deleted successfully');
                        this.setState({ selectedReferenceId: null });
                    }
                });
        } else {
            ModalUtils.warningMessage(null, 'There is not a reference selected yet');
        }
    }

    openReferenceDialog(isEditing) {
        this.setState({
            openDialog: true,
            isEditingReference: isEditing,
        });
    }

    closeReferenceDialog() {
        this.setState({ openDialog: false });
    }

    openConfirmDialog() {
        this.setState({
            openConfirmationDialog: true,
        });
    }

    closeConfirmDialog() {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    subscribeReferencetList() {
        const { props: { dealId } } = this;
        const input = {
            accountNumber: dealId,
        };
        this.graphqlClient.subscribe(this.responseSubscription, DealSubscription.DEAL_REFERENCE_LIST, input)
            .then((response) => {
                this.referenceListSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.dealReferenceList) {
            const { dealReferenceList: { type, reference } } = data;

            switch (type) {
            case SubscriptionActionType.CREATED:
                this.addNewReference(reference);
                break;
            case SubscriptionActionType.UPDATED:
                this.updateReferenceOnList(reference);
                break;
            case SubscriptionActionType.DELETED:
                this.removeReference(reference);
                break;
            default:
            }
        }
    }

    unsubscribeReferenceList() {
        if (this.referenceListSubscription) {
            this.referenceListSubscription.unsubscribe();
        }
    }

    addNewReference(record) {
        this.setState(({ references }) => {
            const newRecords = update(references, { $unshift: [record] });

            return { references: newRecords };
        });
    }

    updateReferenceOnList(record) {
        this.setState(({ references }) => {
            const newRecords = [...references];

            const referenceIndex = newRecords.findIndex((item) => item.dealReferenceId === record.dealReferenceId);
            newRecords[referenceIndex] = { ...record };

            return { references: newRecords };
        });
    }

    removeReference(record) {
        this.setState(({ references }) => {
            const newRecords = [...references];
            newRecords.splice(newRecords.findIndex((item) => item.dealReferenceId === record.dealReferenceId), 1);

            return { references: newRecords };
        });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.deleteReference = this.deleteReference.bind(this);
        this.createReference = this.createReference.bind(this);
        this.openReferenceDialog = this.openReferenceDialog.bind(this);
        this.closeReferenceDialog = this.closeReferenceDialog.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.setSelectedReferenceId = this.setSelectedReferenceId.bind(this);
        this.subscribeReferencetList = this.subscribeReferencetList.bind(this);
        this.addNewReference = this.addNewReference.bind(this);
        this.updateReferenceOnList = this.updateReferenceOnList.bind(this);
        this.removeReference = this.removeReference.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                openReferenceDialog={this.openReferenceDialog}
                closeReferenceDialog={this.closeReferenceDialog}
                deleteReference={this.deleteReference}
                openConfirmDialog={this.openConfirmDialog}
                closeConfirmDialog={this.closeConfirmDialog}
                setSelectedReferenceId={this.setSelectedReferenceId}
                onSave={this.onSave}
            />
        );
    }
};

export default ReferenceTabContainer;

export const INITIAL_STATE = {
    components: [],
    isComponentsPanelOpen: false,
    isComponentsPanelTopSet: false,
    isComponentBeingCreated: false,
    confirmDialog: {
        opened: false,
        description: '',
        actionOnProceed: '',
        options: {},
    },
    modifiedComponent: {},
};

export const ACTION_TYPES = {
    SET_COMPONENTS: 'setComponents',
    OPEN_COMPONENTS_PANEL: 'openComponentsPanel',
    CLOSE_COMPONENTS_PANEL: 'closeComponentsPanel',
    COMPLETE_COMPONENTS_PANEL_ANIMATION: 'completeComponentsPanelAnimation',
    CREATE_COMPONENT_BOARD: 'createComponentBoard',
    FINALIZE_COMPONENT_BOARD_CREATION: 'finalizeComponentBoardCreation',
    UPDATE_COMPONENT: 'updateComponent',
    REMOVE_COMPONENT: 'removeComponent',
    SET_IS_CONFIRM_DIALOG_OPEN: 'setIsConfrimDialogOpen',
};

const BulletinBoardReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_COMPONENTS: {
        return { ...state, components: action.value };
    }
    case ACTION_TYPES.OPEN_COMPONENTS_PANEL: {
        return { ...state, isComponentsPanelOpen: true };
    }
    case ACTION_TYPES.CLOSE_COMPONENTS_PANEL: {
        return { ...state, isComponentsPanelOpen: false, isComponentsPanelTopSet: false };
    }
    case ACTION_TYPES.COMPLETE_COMPONENTS_PANEL_ANIMATION: {
        return { ...state, isComponentsPanelTopSet: true };
    }
    case ACTION_TYPES.CREATE_COMPONENT_BOARD: {
        return { ...state, isComponentBeingCreated: true, modifiedComponent: action.value };
    }
    case ACTION_TYPES.FINALIZE_COMPONENT_BOARD_CREATION: {
        return { ...state, isComponentBeingCreated: false, modifiedComponent: INITIAL_STATE.modifiedComponent };
    }
    case ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN: {
        return { ...state, confirmDialog: action.value };
    }
    case ACTION_TYPES.REMOVE_COMPONENT: {
        const { componentId } = state.confirmDialog.options;
        return {
            ...state,
            components: state.components.filter((component) => component.bulletinBoardComponentId !== componentId),
            confirmDialog: INITIAL_STATE.confirmDialog,
        };
    }
    case ACTION_TYPES.UPDATE_COMPONENT: {
        const bulletinBoardComponentId = action.value;
        const { modifiedComponent, components } = state;
        const isNew = (modifiedComponent.bulletinBoardComponentId === null);

        return {
            ...state,
            components: isNew
                ? [...components, { ...modifiedComponent, bulletinBoardComponentId }]
                : components.map((component) => (component.bulletinBoardComponentId === bulletinBoardComponentId
                    ? modifiedComponent
                    : component)),
            isComponentBeingCreated: false,
            isComponentsPanelOpen: false,
            isComponentsPanelTopSet: false,
            modifiedComponent: INITIAL_STATE.modifiedComponent,
        };
    }
    default:
        return state;
    }
};

export default BulletinBoardReducer;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import PostToAccountingContainer from 'components/containers/deals/create/dealTab/PostToAccountingContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog } from '@material-ui/core';
import DateUtils from 'lib/DateUtils';

const styles = () => DealStyles.postToAccounting();

class PostToAccounting extends Component {
    componentDidMount() {
        const { props: { getServicesData } } = this;
        getServicesData();
    }

    render() {
        const {
            props: {
                classes, open, postDate, lotClosingDate,
                onCloseModal, onChangeValue, onPostAccount, saving,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                PaperProps={{
                    className: classes.dialog,
                }}
                maxWidth="xs"
            >
                <DialogAppBar title="Post to Accounting" iconSize="sm" onClose={onCloseModal} />
                <DialogContent className={classes.dialogContent} dividers>
                    <Form.Group as={Col} className="full-width-date-picker">
                        <Form.Label>Post Date</Form.Label>
                        <DatePicker
                            popperContainer={CalendarContainer}
                            className="form-control"
                            selected={postDate}
                            onChange={(value) => onChangeValue(value)}
                            placeholderText="mm/dd/yyyy"
                            minDate={lotClosingDate}
                        />
                    </Form.Group>
                </DialogContent>
                <DialogActions
                    onClickSecondary={onCloseModal}
                    disablePrimaryButton={!DateUtils.isValid(postDate) || saving}
                    onClickPrimary={onPostAccount}
                />
            </Dialog>
        );
    }
}

PostToAccounting.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    getServicesData: PropTypes.func.isRequired,
    lotClosingDate: PropTypes.instanceOf(Date),
    postDate: PropTypes.instanceOf(Date),
    onPostAccount: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

PostToAccounting.defaultProps = {
    lotClosingDate: null,
    postDate: null,
};

export default withStyles(styles)(PostToAccountingContainer(PostToAccounting));

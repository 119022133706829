import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { modules } from 'utils/enum/modules';
import { ENTITY_TYPE } from 'utils/enum/BusinessIntelligenceEnum';
import Container from 'components/widgets/Container';
import GoalsSettings from 'components/modules/settings/businessIntelligence/GoalsSettings';
import EntitySettings from 'components/modules/settings/businessIntelligence/EntitySettings';
import QueriesSettings from 'components/modules/settings/general/queries/QueriesSettings';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';

// icons
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PlaylistPlayOutlinedIcon from '@material-ui/icons/PlaylistPlayOutlined';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));
const subTabs = ['goals', 'rules', 'dashboard', 'custom-reports', 'queries'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    const BI_SETTINGS_READ = keyStore.hasPermission(Permission.BI_SETTINGS_READ);
    const BI_SETTINGS_WRITE = keyStore.hasPermission(Permission.BI_SETTINGS_WRITE);

    if (BI_SETTINGS_READ) {
        settings.push(
            {
                index: 0,
                text: 'Goals',
                icon: <TrendingUpOutlinedIcon />,
                component: <GoalsSettings
                    canWrite={BI_SETTINGS_WRITE}
                />,
            },
            {
                index: 1,
                text: 'Rules',
                icon: <ReceiptOutlinedIcon />,
                component: <EntitySettings
                    canWrite={BI_SETTINGS_WRITE}
                    type={ENTITY_TYPE.RULE}
                />,
            },
            {
                index: 2,
                text: 'Dashboard',
                icon: <BarChartOutlinedIcon />,
                component: <EntitySettings
                    canWrite={BI_SETTINGS_WRITE}
                    type={ENTITY_TYPE.CHART}
                />,
            },
            {
                index: 3,
                text: 'Custom Reports',
                icon: <AssignmentOutlinedIcon />,
                component: <EntitySettings
                    canWrite={BI_SETTINGS_WRITE}
                    type={ENTITY_TYPE.CUSTOM_REPORT}
                />,
            },
            {
                index: 4,
                text: 'Queries',
                icon: <PlaylistPlayOutlinedIcon />,
                component: <QueriesSettings />,
            },
        );
    }

    return settings.filter((setting) => setting !== null);
};

const BISettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.SETTINGS}/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const settingsItems = getSettings();
    return (
        <>
            <Container className={classes.rootContainer}>
                <List component="nav" className={classes.sideBar}>
                    {settingsItems.map((item) => (
                        <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.content}>
                    {settingsItems.map((item) => (
                        activeIndex === item.index && <div key={item.index}>{item.component}</div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default BISettings;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// GraphQL
import StringUtils from 'lib/StringUtils';
import GraphQLClient from 'services/apollo/GraphQLClient';
import VehicleQuery from 'services/graphQL/query/VehicleQuery';
import ModalUtils from 'utils/ModalUtils';
import PropTypes from 'prop-types';

// Utilities
import KeyStore from 'utils/KeyStore';

const VehicleDialogContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        includeDefaultLot: PropTypes.bool,
    }

    static defaultProps = {
        includeDefaultLot: true,
    }

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.keyStore = new KeyStore();

        const { vehicleSearchData } = props;
        const { vehicles = [], totalCount: vehiclesCount = 0 } = vehicleSearchData || {};

        this.state = {
            records: vehicles,
            isLoading: false,
            searchTerm: '',
            previousSearchTerm: '',
            totalCount: vehiclesCount,
            selectedRecord: null,
        };
        this.initBind();
    }

    onSearch(searchTerm) {
        const { state: { previousSearchTerm } } = this;
        if (searchTerm && (searchTerm === previousSearchTerm)) return;

        this.setState({ searchTerm });

        if (!StringUtils.isEmpty(searchTerm)) {
            this.getServicesData(searchTerm, true);
        } else {
            this.clearList();
        }
    }

    onClickRecord(record) {
        this.setState({ selectedRecord: record });
    }

    onClickPrimary() {
        const { props: { onSelectVehicle }, state: { selectedRecord } } = this;

        if (selectedRecord) {
            onSelectVehicle(selectedRecord);
        }
    }

    getServicesData(searchTerm, isSearching = false) {
        const { props: { includeDefaultLot }, state: { records } } = this;
        let start = records.length;
        const selectedLot = this.keyStore.getSelectedLot();

        if (isSearching) {
            start = 0;
            this.setState({ records: [], totalCount: 0 });
        }

        const input = {
            paginate: {
                start,
                limit: 50,
            },
            filter: {
                searchTerm,
                lotName: (includeDefaultLot ? selectedLot.lotName : null),
            },
        };

        this.setState({ isLoading: true, previousSearchTerm: searchTerm });
        this.graphqlClient
            .query(VehicleQuery.GET_VEHICLES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getVehicles) {
                    const { getVehicles: { vehicles, totalCount } } = data;

                    this.setState((prevState) => ({
                        records: prevState.records.concat(vehicles),
                        totalCount,
                    }));
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    loadMore() {
        const { state: { searchTerm } } = this;
        this.getServicesData(searchTerm);
    }

    clearList() {
        const { state: { records } } = this;

        if (records.length > 0) {
            this.setState({
                records: [],
                totalCount: 0,
                previousSearchTerm: '',
            });
        }
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clearList = this.clearList.bind(this);
        this.onClickRecord = this.onClickRecord.bind(this);
        this.onClickPrimary = this.onClickPrimary.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSearch={this.onSearch}
                loadMore={this.loadMore}
                onClickRecord={this.onClickRecord}
                onClickPrimary={this.onClickPrimary}
            />
        );
    }
};

export default VehicleDialogContainer;

/* eslint-disable no-param-reassign */
import React, {
    useEffect,
    useReducer,
} from 'react';
import {
    Chip,
    Grid,
    Button,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import {
    CheckBoxIcon,
    PrintOutlinedIcon,
    ThumbUpAltOutlinedIcon,
    CheckBoxOutlineBlankIcon,
    ThumbDownAltOutlinedIcon,
    DeleteOutlineOutlinedIcon,
} from 'components/icons';
import clsx from 'clsx';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import Badge from '@material-ui/core/Badge';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import { useQuery, useMutation } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import useEmployeHasCheck from 'components/hook/payroll/useEmployeHasCheck';
import MiscCommissionsQuery from 'services/graphQL/query/payroll/MiscCommissions';
import MiscCommissionsMutation from 'services/graphQL/mutate/payroll/MiscCommissions';
import DealCommissionDialog from 'components/widgets/payroll/MiscCommissions/DealCommissionDialog';

const useStyles = makeStyles((theme) => AccountingStyles.mainList(theme));

const ownStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        height: 'fit-content',
    },
    header: {
        marginBottom: 10,
    },
    itemsCounter: {
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
        width: 'fit-content',
        height: 'fit-content',
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    content: {
        flexGrow: 1,
        marginTop: 10,
        height: 'fit-content',
    },
    localButtonSpacing: {
        marginLeft: '8px',
    },
    tableHeight: {
        height: 'calc(93vh - 170px)',
    },
    totalLabel: {
        marginLeft: '50px',
    },
}));

const ACTION_TYPES = {
    SELECT_ALL: 'selectAll',
    SELECT_ONE: 'selectOne',
    APPLY_FILTER: 'applyFilter',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    const { value } = action;
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === action.accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: action.accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    case ACTION_TYPES.SELECT_ALL:
        const newRecords = state.items.map((item) => ({
            ...item,
            isChecked: !value,
        }));

        return update(state, {
            items: { $set: newRecords },
        });
    case ACTION_TYPES.SELECT_ONE:
        const index = state.items.findIndex((item) => item.accountNumber === action.accountNumber);

        return update(state, {
            items: {
                [index]: {
                    isChecked: {
                        $set: !value,
                    },
                },
            },
        });
    default: return state;
    }
};

const DealCommissionTable = ({
    employeeId, payPeriod,
}) => {
    const keyStore = new KeyStore();
    const classes = {
        ...useStyles(),
        ...ownStyles(),
    };

    const initState = {
        items: [],
        printing: false,
        loadTable: false,
        filtersApplied: [],
        openPrintDialog: false,
        accountNumberSelected: 0,
        openApprovalDialog: false,
        openMarkAsPaidDialog: false,
        openUndoApprovalDialog: false,
        openDealCommissionDialog: false,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        loadTable, filtersApplied, items, openMarkAsPaidDialog,
        openApprovalDialog, openUndoApprovalDialog, openPrintDialog,
        openDealCommissionDialog, accountNumberSelected,
    } = state;

    const { hasCheck } = useEmployeHasCheck({ employeeId, payPeriod });

    const {
        loading, data, error, refetch,
    } = useQuery(MiscCommissionsQuery.GET_DEAL_COMMISSION_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getPayrollDealCommissionList } = data;
            const formattedData = getPayrollDealCommissionList.map((item) => {
                const { soldDate, ...rest } = item;
                return { ...rest, soldDate: DateUtils.getOnlyDate(soldDate) };
            });
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    loadTable: true,
                    items: formattedData,
                    filtersApplied: [],
                },
            });
        }
    }, [data, loading, error]);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                loadTable: false,
                filtersApplied: [],
            },
        });
    }, [payPeriod, employeeId]);

    const toggleMarkAsPaidDialog = () => {
        dispatch({
            field: 'openMarkAsPaidDialog',
            value: !openMarkAsPaidDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const [markAsPaid, { loading: updatingCommissionStatus }] = useMutation(MiscCommissionsMutation.MARK_COMMISSION_AS_PAID, {
        onCompleted: (mutationData) => {
            if (mutationData?.markDealCommissionAsPaid) {
                ModalUtils.successMessage(null, 'Commission(s) Updated Successfully!');
                toggleMarkAsPaidDialog();
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const toggleApprovalDialog = () => {
        dispatch({
            field: 'openApprovalDialog',
            value: !openApprovalDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const toggleUndoApprovalDialog = () => {
        dispatch({
            field: 'openUndoApprovalDialog',
            value: !openUndoApprovalDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const togglePrintDialog = () => {
        dispatch({
            value: !openPrintDialog,
            field: 'openPrintDialog',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const toggleDealCommissionDialog = (accountNumber = 0) => {
        dispatch({
            field: 'openDealCommissionDialog',
            value: !openDealCommissionDialog,
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            values: {
                accountNumberSelected: accountNumber,
                openDealCommissionDialog: !openDealCommissionDialog,
            },
        });
    };

    const [approve, { loading: isApproving }] = useMutation(MiscCommissionsMutation.APPROVE_DEAL_COMMISSION, {
        onCompleted: (mutationData) => {
            if (mutationData?.approveDealCommission) {
                if (openApprovalDialog) {
                    ModalUtils.successMessage(null, 'Commission(s) Approved Successfully!');
                    toggleApprovalDialog();
                } else if (openUndoApprovalDialog) {
                    ModalUtils.successMessage(null, 'Undo Approved Commission(s) Successfully!');
                    toggleUndoApprovalDialog();
                }
                refetch();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [printPayrollReport, { loading: printing }] = useMutation(MiscCommissionsMutation.PRINT_COMMISSIONS_FORM, {
        onCompleted: (mutationData) => {
            if (mutationData?.printDealCommissionReport) {
                printJS({
                    type: 'pdf',
                    showModal: false,
                    onPrintDialogClose: togglePrintDialog,
                    printable: mutationData.printDealCommissionReport,
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onPrintReport = () => {
        togglePrintDialog();
        printPayrollReport({
            variables: {
                payPeriod,
                employeeId,
            },
        });
    };

    const getColumns = () => [{
        Header: (header) => {
            const { data: records } = header;
            const allApproved = records.length > 0 && records.filter((c) => c._original.isChecked).length === records.length;

            return allApproved ? (
                <CheckBoxIcon
                    fontSize="small"
                    className={classes.highlightedColor}
                    onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                />
            )
                : (
                    <CheckBoxOutlineBlankIcon
                        fontSize="small"
                        className={classes.highlightedColor}
                        onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                    />
                );
        },
        Cell: (cell) => {
            const {
                original: {
                    accountNumber, isChecked,
                },
            } = cell;
            const onSelectOne = () => dispatch({
                accountNumber,
                value: isChecked,
                type: ACTION_TYPES.SELECT_ONE,
            });

            return (
                isChecked ? (
                    <CheckBoxIcon
                        className={classes.highlightedColor}
                        onClick={onSelectOne}
                    />
                )
                    : (
                        <CheckBoxOutlineBlankIcon
                            className={classes.highlightedColor}
                            onClick={onSelectOne}
                        />
                    )
            );
        },
        width: 50,
        sortable: false,
        filterable: false,
        id: 'checkbox-column',
        className: clsx(classes.columnStyle, classes.columnCenter),
        headerClassName: clsx(classes.columnHeaderStyle, classes.columnCenter),
    },
    {
        Header: 'Date',
        id: 'soldDate',
        width: 80,
        accessor: 'soldDate',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Acct #',
        id: 'accountNumber',
        minWidth: 10,
        accessor: 'accountNumber',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Buyer',
        id: 'buyerName',
        minWidth: 10,
        accessor: 'buyerName',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Sold Vehicle',
        id: 'soldVehicle',
        minWidth: 10,
        accessor: 'soldVehicle',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Commissions',
        id: 'commissionAmount',
        minWidth: 10,
        accessor: 'commissionAmount',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Approved',
        id: 'isApproved',
        minWidth: 10,
        accessor: 'isApproved',
        className: 'd-flex-justify-center-align-center',
        Cell: (cellData) => (cellData.original.isApproved && (
            <CheckBoxIcon className={classes.highlightedColor} />
        )),
    }, {
        Header: 'Status',
        id: 'status',
        minWidth: 10,
        accessor: 'status',
        className: 'd-flex-justify-center-align-center',
        Cell: (cellData) => <Chip size="small" label={cellData.original.status} className={classes.primary} />,
    }];

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const loadColumns = () => {
        const columnNamesExcluded = [
            'checkbox-column', 'isApproved',
        ];
        const columns = getColumns();
        columns.forEach((column) => {
            if (!columnNamesExcluded.includes(column.id)) {
                column.Header = (
                    <div
                        className={classes.filterContainer}
                    >
                        {column.Header}
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Filter
                                showTooltip
                                showIconOnly
                                useInternalSearch
                                maxWidthLabel={200}
                                filterId={column.id}
                                records={getFilterValues(items, column.id)}
                                onClearFilter={() => resetFilters(column.id)}
                                applyFilter={(record) => onFilteredChangeCustom(
                                    record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                                )}
                            />
                        </div>
                    </div>
                );
            }
        });
        return columns;
    };

    const disablePrintingButton = updatingCommissionStatus || isApproving || printing || openPrintDialog || items.length === 0;
    const selectedRecordsToApprove = items.filter((item) => (item.isChecked && !item.isApproved));
    const selectedRecordsToUndoApprove = items.filter((item) => (item.isChecked && item.isApproved));
    const selectedCommissionsToMarkAsPaid = items.filter((item) => item.isChecked);

    return (
        <div className={classes.content}>
            <Grid item className={clsx(classes.row, classes.header)}>
                <>
                    {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_WRITE) && !hasCheck && (
                        <>
                            <Badge badgeContent={selectedRecordsToApprove?.length ?? 0} color="primary">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={toggleApprovalDialog}
                                    className={classes.localButtonSpacing}
                                    disabled={!selectedRecordsToApprove.length || isApproving}
                                    startIcon={(
                                        <ThumbUpAltOutlinedIcon
                                            className={clsx({ [classes.actionButtonApprove]: selectedRecordsToApprove.length })}
                                        />
                                    )}
                                >
                                    Approve
                                </Button>
                            </Badge>
                            <Badge badgeContent={selectedRecordsToUndoApprove?.length ?? 0} color="primary">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={toggleUndoApprovalDialog}
                                    className={classes.localButtonSpacing}
                                    disabled={!selectedRecordsToUndoApprove.length || isApproving}
                                    startIcon={(
                                        <ThumbDownAltOutlinedIcon
                                            className={clsx({ [classes.actionButtonPrint]: selectedRecordsToUndoApprove.length })}
                                        />
                                    )}
                                >
                                    Undo Approval
                                </Button>
                            </Badge>
                        </>
                    )}
                    {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_PAID) && !hasCheck && (
                        <Badge badgeContent={selectedCommissionsToMarkAsPaid?.length ?? 0} color="primary">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={toggleMarkAsPaidDialog}
                                className={classes.localButtonSpacing}
                                disabled={!selectedCommissionsToMarkAsPaid.length || isApproving}
                                startIcon={(
                                    <DeleteOutlineOutlinedIcon
                                        className={clsx({ [classes.actionButtonPrint]: selectedCommissionsToMarkAsPaid.length })}
                                    />
                                )}
                            >
                                Mark as Paid
                            </Button>
                        </Badge>
                    )}
                    {keyStore.hasPermission(Permission.PAYROLL_MISC_COMMISSIONS_PRINT) && (
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={onPrintReport}
                            disabled={disablePrintingButton}
                            className={classes.localButtonSpacing}
                            startIcon={<PrintOutlinedIcon className={clsx({ [classes.actionButtonPrint]: !disablePrintingButton })} />}
                        >
                            Print
                        </Button>
                    )}
                </>
            </Grid>
            <Grid item xs={12} className={classes.tableHeight}>
                {!loadTable && (
                    <div className={classes.loadingIndicator}>
                        <Loading />
                    </div>
                )}
                {loadTable && (
                    <Table
                        enableRowHover
                        className="-highlight payrollTable"
                        columns={loadColumns()}
                        filtered={filtersApplied}
                        totalRecords={items.length}
                        data={items}
                        defaultFilterMethod={(localFilter, row) => {
                            const id = localFilter.pivotId || localFilter.id;
                            if (typeof localFilter.value === 'object') {
                                return row[id] !== undefined
                                    ? localFilter.value.indexOf(row[id]) > -1
                                    : true;
                            }
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(localFilter.value) > -1
                                : true;
                        }}
                        getTrProps={(_, rowInfo) => {
                            const { original: { accountNumber, postedDate } } = rowInfo;
                            return {
                                onDoubleClick: () => {
                                    if (postedDate) {
                                        ModalUtils.errorMessage(
                                            null,
                                            `Deal #${accountNumber}: has been posted in accounting and values cannot be changed unless un-posted`,
                                        );
                                    } else toggleDealCommissionDialog(accountNumber);
                                },
                            };
                        }}
                    />
                )}
                <div>
                    <FormLabel className={classes.localButtonSpacing}>
                        {`Deals Made: ${items.length}`}
                    </FormLabel>
                    <FormLabel className={classes.totalLabel}>
                        {`Total: ${NumberUtils.applyCurrencyFormat(items.reduce((sum, item) => sum + item.commissionAmount, 0))}`}
                    </FormLabel>
                </div>
            </Grid>
            { openDealCommissionDialog && (
                <DealCommissionDialog
                    refetch={refetch}
                    accountNumber={accountNumberSelected}
                    onClose={toggleDealCommissionDialog}
                />
            )}
            <ConfirmDialog
                title="Confirm"
                description="Mark selected commission(s) as paid?"
                open={openMarkAsPaidDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleMarkAsPaidDialog}
                onClose={toggleMarkAsPaidDialog}
                onClickPrimary={() => markAsPaid({
                    variables: {
                        employeeId,
                        accountNumberList:
                            selectedCommissionsToMarkAsPaid.map((item) => item.accountNumber),
                    },
                })}
            />
            <ConfirmDialog
                title="Confirm Approval"
                description="Approve Selected Commission(s)?"
                open={openApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleApprovalDialog}
                onClose={toggleApprovalDialog}
                onClickPrimary={() => approve({
                    variables: {
                        payPeriod,
                        employeeId,
                        input:
                            selectedRecordsToApprove.map((item) => ({
                                amount: item.commissionAmount,
                                accountNumber: item.accountNumber,
                            })),
                    },
                })}
            />
            <ConfirmDialog
                title="Confirm Undo"
                description="Undo Selected Approved Commission(s)?"
                open={openUndoApprovalDialog}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={toggleUndoApprovalDialog}
                onClose={toggleUndoApprovalDialog}
                onClickPrimary={() => approve({
                    variables: {
                        payPeriod,
                        employeeId,
                        input:
                            selectedRecordsToUndoApprove.map((item) => ({
                                amount: item.commissionAmount,
                                accountNumber: item.accountNumber,
                            })),
                    },
                })}
            />
        </div>
    );
};

DealCommissionTable.propTypes = {
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
};

export default DealCommissionTable;

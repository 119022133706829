import { PartsTicketStatus } from 'utils/enum/PartsPurchaseEnum';

const usePartsTicketValidateActions = (status) => {
    let editCustomer = true;
    let editLot = true;
    let editIsTaxable = true;
    let editSalesType = true;
    let editCounterPerson = true;
    let editDiscountPercent = true;
    let editQuickNote = true;
    let allowSaveAction = true;
    let editDetails = {
        editQuantity: true,
        editNetPrice: true,
        allowAdd: true,
        allowDelete: true,
    };

    if (status.toLowerCase() === PartsTicketStatus.CLOSED.toLowerCase()
    || status.toLowerCase() === PartsTicketStatus.VOID.toLowerCase()
    ) {
        editCustomer = false;
        editLot = false;
        editIsTaxable = false;
        editSalesType = false;
        editCounterPerson = false;
        editDiscountPercent = false;
        editQuickNote = false;
        allowSaveAction = false;
        editDetails = {
            editDescription: false,
            editBin: false,
            editQuantity: false,
            editListPrice: false,
            editNetPrice: false,
            editDiscount: false,
            allowAdd: false,
            allowDelete: false,
        };
    }

    return {
        editCustomer,
        editLot,
        editIsTaxable,
        editSalesType,
        editCounterPerson,
        editDiscountPercent,
        editQuickNote,
        allowSaveAction,
        editDetails,
    };
};

export default usePartsTicketValidateActions;

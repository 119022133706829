import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import UserQuery from 'services/graphQL/query/UserQuery';

const keyStore = new KeyStore();
const useAssigneeByLots = (props = {}) => {
    const [assignee, setAssignee] = useState([]);
    const lots = (props.lots && props.lots.filter((item) => item).length > 0) ? props.lots : keyStore.getUserLots().map((c) => c.lotId);

    const { data, loading, error } = useQuery(UserQuery.GET_RECORD_MANAGERS_AND_SALESPERSON_BY_LOTS, {
        variables: { lots },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data)) {
            const { getRecordManagersAndSalespersonByLots } = data;
            const result = getRecordManagersAndSalespersonByLots.map((item) => ({
                value: item.userId,
                label: `${item.firstName} ${item.lastName}`,
                image: item.avatar,
            }));
            setAssignee(props.notIncludeEmptyValue ? result : [{ label: 'None', value: null }, ...result]);
        }
    }, [data, loading, props.notIncludeEmptyValue, error]);

    return {
        assignee,
        loading,
    };
};

export default useAssigneeByLots;

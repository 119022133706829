const ConfirmDialogActions = {
    DELETE_CUSTOM_QUERY: 'Delete Custom Query',
};

const filters = {
    FILTER_TABLES: 'Filter Tables',
    FILTER_COLUMNS: 'Filter Columns',
    FILTER_QUERIES: 'Filter Queries',
};

const SalesTaxQueries = {
    SALES_TAX_DEALS: 'SALES TAX - DEALS (INCLUDING OUT OF STATE AS GROSS AND EXEMPT)',
    SALES_TAX__OUT_OF_STATE: 'SALES TAX - OUT OF STATE',
    SALES_TAX_REPAIR_ORDERS: 'SALES TAX - REPAIR ORDERS',
    SALES_TAX_DELETED_DEALS: 'SALES TAX - DELETED DEALS',
    LEASE_SALES_TAX_DEALS: 'SALES TAX - LEASE DEALS',
    LEASE_PAYMENT_SALES_TAX: 'SALES TAX - LEASE PAYMENT',
    SALES_TAX_DELETED_LEASE_DEALS: 'SALES TAX - DELETED LEASE DEALS',
};

export { ConfirmDialogActions, filters, SalesTaxQueries };

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
    makeStyles,
    FormLabel,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import { Link as RouteLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';

import ModalUtils from 'utils/ModalUtils';
import { DealStatus } from 'utils/enum/DealEnum';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const useStyles = makeStyles((theme) => InventoryDetailStyle.dealBanner(theme));

const InventoryDealBanner = ({ stockNumber, blockingStatus, currentUserId }) => {
    const classes = useStyles();
    const { data, error } = useQuery(DealsQuery.DEAL_BY_STOCKNUMBER, { variables: { stockNumber } });

    if (error) {
        ModalUtils.errorMessage(error?.graphQLErrors);
    }

    const keyStore = new KeyStore();
    const READ = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_READ);
    const dealRecords = data?.getDealByStockNumber;

    if (READ && Array.isArray(dealRecords) && dealRecords.length > 0) {
        const dealSold = dealRecords.find(
            (deal) => deal.status !== DealStatus.QUOTE && deal.status !== DealStatus.DELETED,
        );

        if (!isEmpty(dealSold)) {
            const { accountNumber, soldDate, buyer } = dealSold;

            return (
                <>
                    <div className={`${classes.headerBanner} ${classes.greenBanner}`}>
                        <FormLabel>
                            <span>Deal Number:</span>
                            <RouteLink
                                to={{
                                    pathname: `/${modules.DEALS}/${accountNumber}`,
                                }}
                                target="_blank"
                            >
                                {accountNumber}
                            </RouteLink>
                        </FormLabel>
                        <FormLabel>
                            <span>Sold Date:</span>
                            {DateUtils.getUSFormattedDateFromUTC(soldDate, DateFormat.DEFAULT_DATE)}
                        </FormLabel>
                        <FormLabel>
                            <span>Buyer:</span>
                            {`${buyer.firstName} ${buyer.lastName}`}
                        </FormLabel>
                    </div>
                    {blockingStatus?.isEditing && blockingStatus.editingById !== currentUserId && (
                        <div className={`${classes.headerBanner} ${classes.yellowBanner}`}>
                            <FormLabel>{`This vehicle is being edited by ${blockingStatus.editingBy ?? 'another user'}`}</FormLabel>
                        </div>
                    )}
                </>
            );
        }

        const dealInQuotes = dealRecords.find((item) => item.status === DealStatus.QUOTE);

        if (!isEmpty(dealInQuotes)) {
            return (
                <>
                    <div className={`${classes.headerBanner} ${classes.yellowBanner}`}>
                        <FormLabel>Deal Pending</FormLabel>
                    </div>
                    {blockingStatus?.isEditing && blockingStatus.editingById !== currentUserId && (
                        <div className={`${classes.headerBanner} ${classes.yellowBanner}`}>
                            <FormLabel>{`This vehicle is being edited by ${blockingStatus.editingBy ?? 'another user'}`}</FormLabel>
                        </div>
                    )}
                </>
            );
        }
    } else if (blockingStatus?.isEditing && blockingStatus.editingById !== currentUserId) {
        return (
            <div className={`${classes.headerBanner} ${classes.yellowBanner}`}>
                <FormLabel>{`This vehicle is being edited by ${blockingStatus.editingBy ?? 'another user'}`}</FormLabel>
            </div>
        );
    }

    return null;
};

InventoryDealBanner.defaultProps = {
    blockingStatus: null,
    currentUserId: 0,
};

InventoryDealBanner.propTypes = {
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    stockNumber: PropTypes.number.isRequired,
};

export default InventoryDealBanner;

import HttpClientModule from 'services/httpModules/HttpClientModule';

export const URL = {
    DELETE_TAG: '/deals/~dealId~/tags/~tagId~',
    ADD_TAG: '/deals/~dealId~/tags',
    GET_ACCOUNT_TAGS: '/loans/~accountNumber~/tags',
};

export default class HttpDealsModule {
    constructor() {
        this.http = new HttpClientModule();
    }

    async deleteTag(dealId = 0, tagId = '0') {
        let url = URL.DELETE_TAG;
        url = url.replace('~dealId~', dealId);
        url = url.replace('~tagId~', tagId);

        const response = await this.http.PUT(url, {});

        return response;
    }

    async addTag(dealId = 0, tagValue = {}) {
        let url = URL.ADD_TAG;
        url = url.replace('~dealId~', dealId);

        const body = tagValue;

        const response = await this.http.POST(url, body);

        return response;
    }

    async getAssignedTags(accountNumber = 0) {
        let url = URL.GET_ACCOUNT_TAGS;
        url = url.replace('~accountNumber~', accountNumber);
        const response = await this.http.GET(url);

        return response;
    }
}

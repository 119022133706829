import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';
import If from 'components/widgets/conditional/If';
import update from 'immutability-helper';
import { makeStyles } from '@material-ui/core';
import ImageViewer from 'components/modules/inventory/read/panels/gallery/ImageViewer';
import PhotosGallery from 'components/widgets/inventory/PhotosGallery';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginTop: '25px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
}));

const moveType = {
    NEXT: 'next',
    PREVIOUS: 'previous',
};
const initState = {
    openPreview: false,
    photoIndex: 0,
};
const ACTION_TYPES = {
    ON_OPEN: 'onOpen',
    SET_PHOTO_INDEX: 'setPhotoIndex',
};
const reducer = (state, action) => {
    switch (action?.type) {
    case ACTION_TYPES.ON_OPEN:
        return update(state, {
            openPreview: { $set: true },
            photoIndex: { $set: action.payload },
        });
    case ACTION_TYPES.SET_PHOTO_INDEX:
        return update(state, {
            photoIndex: { $set: action.payload },
        });
    default:
        return initState;
    }
};

const ImageList = ({
    canWrite,
    images,
    onSortEnd,
    isReorderModeOn,
    selectedImages,
    previewWatermark,
    toogleImageSelection,
    watermarksAvailable,
}) => {
    const distanceInPixels = 5;
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);

    const onClose = () => {
        dispatch();
    };

    const onOpen = (index) => {
        dispatch({
            type: ACTION_TYPES.ON_OPEN,
            payload: index,
        });
    };

    const setPhotoIndex = (type) => {
        const { photoIndex } = state;
        const { length } = images;
        let index = 0;

        if (type === moveType.NEXT) {
            index = (photoIndex + 1) % length;
        } else if (type === moveType.PREVIOUS) {
            index = (photoIndex + length - 1) % length;
        }

        dispatch({
            type: ACTION_TYPES.SET_PHOTO_INDEX,
            payload: index,
        });
    };

    const getChild = (image, index) => (
        <ImageViewer
            canWrite={canWrite}
            image={image}
            index={index}
            openImage={onOpen}
            previewWatermark={previewWatermark}
            isReorderModeOn={isReorderModeOn}
            selectedImages={selectedImages}
            toogleImageSelection={toogleImageSelection}
            watermarksAvailable={watermarksAvailable}
            key={`image-${index}`}
        />
    );

    const SortableItem = SortableElement(({ value, sortIndex }) => getChild(value, sortIndex));
    const SortableList = SortableContainer(({ items }) => (
        <div className={classes.main}>
            {
                items.map((image, index) => (
                    <SortableItem
                        key={`image-${index}`}
                        index={index}
                        sortIndex={index}
                        value={image}
                    />
                ))
            }
        </div>
    ));

    return (
        <>
            <If condition={isReorderModeOn}>
                <SortableList
                    axis="xy"
                    distance={distanceInPixels}
                    items={images}
                    onSortEnd={onSortEnd}
                />
            </If>
            <If condition={!isReorderModeOn}>
                <div className={classes.main}>
                    {images.map((image, index) => getChild(image, index)) }
                </div>
            </If>
            <PhotosGallery
                photos={images}
                photoIndex={state.photoIndex}
                isOpen={state.openPreview}
                onCloseRequest={onClose}
                onMovePrevRequest={() => setPhotoIndex(moveType.PREVIOUS)}
                onMoveNextRequest={() => setPhotoIndex(moveType.NEXT)}
            />
        </>
    );
};

ImageList.propTypes = {
    images: PropTypes.array,
    onSortEnd: PropTypes.func.isRequired,
    isReorderModeOn: PropTypes.bool.isRequired,
    selectedImages: PropTypes.array,
    toogleImageSelection: PropTypes.func,
    watermarksAvailable: PropTypes.bool,
    previewWatermark: PropTypes.func,
    canWrite: PropTypes.bool.isRequired,
};

ImageList.defaultProps = {
    images: [],
    selectedImages: [],
    previewWatermark: () => null,
    toogleImageSelection: () => null,
    watermarksAvailable: false,
};

export default ImageList;

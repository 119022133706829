/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
import * as yup from 'yup';
import { DefaultCountry } from 'utils/enum/Customer';
import { isEmpty } from 'lodash';

const DefaultAddressSchema = yup.object().shape({
    address1: yup.string().required(),
    zipCode: yup.string().required().test(
        'required',
        'Zip Code is required.',
        function (zipCode) {
            const { country } = this.parent;
            return country !== DefaultCountry.COUNTRY || zipCode?.trim()?.length === 5;
        },
    ),
    city: yup.string().required().test(
        'required',
        'City is required.',
        function (city) {
            const { country } = this.parent;
            return country !== DefaultCountry.COUNTRY || !isEmpty(city);
        },
    ),
});

const OptionalAddressSchema = yup.object().shape({
    address1: yup.string().max(50),
    zipCode: yup.string().max(5),
    city: yup.string().max(50),
});

export {
    DefaultAddressSchema,
    OptionalAddressSchema,
};

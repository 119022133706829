import React from 'react';

// Components and Others
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import clsx from 'clsx';

const CustomFieldText = (record) => (
    <Form.Group as={Col} key={`${record.label}-${record.index}`}>
        {!record.hiddenLabel && <Form.Label>{record.label}</Form.Label>}
        <Form.Control
            type="text"
            value={record.value}
            placeholder={record.placeholder}
            maxLength={record.maxLength || null}
            onChange={(e) => record.onChange({
                field: 'value',
                value: e.target.value,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
        />
    </Form.Group>
);

const CustomFieldDate = (record) => (
    <Form.Group as={Col} className="full-width-date-picker" key={`${record.label}-${record.indexCustomField}`}>
        <Form.Label>{record.label}</Form.Label>
        <DatePicker
            name="date"
            size="sm"
            selected={record.value}
            className="form-control"
            placeholderText="mm/dd/yyyy"
            onChange={(date) => record.onChange({
                field: 'value',
                value: date,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
            popperContainer={CalendarContainer}
        />
    </Form.Group>
);

const CustomFieldInteger = (record) => (
    <Form.Group as={Col} key={`${record.label}-${record.index}`}>
        <Form.Label>{record.label}</Form.Label>
        <InputNumber
            value={record.value}
            placeholder={record.placeholder}
            decimalScale={0}
            onChange={(value) => record.onChange({
                field: 'value',
                value,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
        />
    </Form.Group>
);

const CustomFieldFloat = (record) => (
    <Form.Group as={Col} key={`${record.label}-${record.index}`} className={clsx({ hidden: record.hidden })}>
        <Form.Label>{record.label}</Form.Label>
        <InputNumber
            thousandSeparator
            value={record.value}
            placeholder={record.placeholder}
            disabled={record.disabled}
            decimalScale={record?.decimalScale || 2}
            onChange={(value) => record.onChange({
                field: 'value',
                value,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
        />
    </Form.Group>
);

const CustomFieldCheckBoxText = (record) => (
    <Form.Group as={Col} key={`${record.label}-${record.indexCustomField}`} controlId={`formBasicCheckbox${record.indexCustomField}`}>
        {!record.hiddenLabel && <Form.Label>{record.label}</Form.Label>}
        <Form.Check
            key={`${record.label}-${record.indexCustomField}-check`}
            label={record.labelCheck}
            type="checkbox"
            id={`formBasicCheckbox${record.indexCustomField}`}
            checked={record.valueCheck}
            className="margin-button-8"
            onChange={(e) => record.onChange({
                field: 'valueCheck',
                value: e.target.checked,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
        />
        <Form.Control
            type="text"
            value={record.value}
            disabled={!record.valueCheck}
            placeholder="Placeholder"
            onChange={(e) => record.onChange({
                field: 'valueText',
                value: e.target.value,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
        />
    </Form.Group>
);

const CustomFieldCheckBox = (record) => (
    <Form.Group
        className="form-group col d-flex-column"
        controlId={`formBasicCheckbox${record.indexForm}${record.indexCustomField}`}
        key={`${record.label}-${record.indexCustomField}`}
    >
        <Form.Label>{record.label}</Form.Label>
        {record.values.map((item, index) => (
            <Form.Check
                key={`${record.label}-${record.indexCustomField}${index}-check`}
                label={item.label}
                type="checkbox"
                disabled={item.disabled}
                id={`formBasicCheckbox${record.indexForm}${record.indexCustomField}-${index}`}
                checked={item.value}
                onChange={(e) => record.onChange({
                    index,
                    field: 'value',
                    value: e.target.checked,
                    indexForm: record.indexForm,
                    indexCustomField: record.indexCustomField,
                })}
                className="margin-button-8"
            />
        ))}
    </Form.Group>
);

const CustomFieldRadio = (record) => (
    <Form.Group
        className="form-group col d-flex-column"
        key={`${record.label}-${record.indexCustomField}`}
    >
        <Form.Label>{record.label}</Form.Label>
        {record.values.map((item, index) => (
            <Form.Check
                key={`${record.label}-${record.indexCustomField}${index}-check`}
                label={item.label}
                type="radio"
                value={item.value}
                name={`radio${record.indexCustomField}`}
                id={`radio${record.indexCustomField}${index}`}
                checked={record.value === item.value}
                onChange={(e) => record.onChange({
                    field: 'value',
                    value: e.target.value,
                    indexForm: record.indexForm,
                    indexCustomField: record.indexCustomField,
                })}
                className="margin-button-8"
            />
        ))}
    </Form.Group>
);

const CustomFieldTitle = (record) => (
    <Form.Group as={Col} key={`${record.value}-${record.index}`}>
        <p>{record.value}</p>
    </Form.Group>
);

const CustomFieldSelect = (record) => (
    <Form.Group as={Col} key={`${record.value}-${record.index}`}>
        <Form.Label>{record.label}</Form.Label>
        <Select
            name={record.customField}
            placeholder="Select"
            options={record.values}
            value={record.value}
            onChange={(e, value, labelValue) => record.onChange({
                field: 'value',
                value,
                record: labelValue,
                indexForm: record.indexForm,
                indexCustomField: record.indexCustomField,
            })}
            className="basic-multi-select select-bootstrap"
        />
    </Form.Group>
);

export {
    CustomFieldText, CustomFieldDate, CustomFieldInteger, CustomFieldCheckBox, CustomFieldTitle, CustomFieldCheckBoxText,
    CustomFieldFloat, CustomFieldRadio, CustomFieldSelect,
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import React from 'react';
import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import { DataSort } from 'utils/enum/Core';
import { CHART_COLUMN_DATA_TYPE } from 'utils/enum/BusinessIntelligenceEnum';
import BIHelper from 'utils/BusinessIntelligenceHelper';

// Icons
import {
    MinusSquare,
    PlusSquare,
} from 'components/icons/index';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
    header: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '11px',
        color: theme.palette.text.waterloo,
        fontWeight: 700,
        borderLeft: `1px solid ${theme.palette.border.ghost}`,
        borderBottom: `1px solid ${theme.palette.border.ghost}`,
        borderTop: `1px solid ${theme.palette.border.ghost}`,
        backgroundColor: theme.palette.background.white,
        userSelect: 'none',
    },
    table: {
        display: 'grid',
        gridAutoRows: '25px',
        backgroundColor: theme.palette.background.white,
        '& > div:nth-child(1) > div:nth-child(1)': {
            borderLeft: `1px solid ${theme.palette.border.ghost}`,
        },
    },
    activeRow: {
        '& > div': {
            backgroundColor: `${theme.palette.background.cornFlowerBlueTrans} !important`,
        },
    },
    rowParent: {
        display: 'contents',
        '& > div:last-child': {
            justifyContent: 'flex-start',
            paddingLeft: '20px',
        },
    },
    highLightRow: {
        '&:hover > div': {
            backgroundColor: `${theme.palette.background.cornFlowerBlueTrans} !important`,
        },
    },
    cell: {
        lineHeight: 2.5,
        paddingLeft: '7px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: theme.palette.text.outerSpace,
        fontSize: '11px',
        fontWeight: 500,
        '& > svg': {
            width: '12px !important',
            height: '12px !important',
            marginRight: '3px',
            cursor: 'pointer',
        },
    },
    zebra: {
        backgroundColor: theme.palette.background.whitesmoke,
    },
    dragHandleIcon: {
        flex: 'initial',
        position: 'absolute',
        top: '4px',
        right: 0,
        fontSize: '12px',
        cursor: 'col-resize',
        color: theme.palette.text.waterloo,
    },
    noShow: {
        display: 'none',
    },
    bold: {
        fontWeight: 'bold',
    },
    center: {
        textAlign: 'center',
    },
    pointerContext: {
        cursor: 'context-menu',
    },
    stickyRow: {
        position: 'sticky',
        left: 0,
        boxSizing: 'border-box',
    },
    stickyColumn: {
        position: 'sticky',
        boxSizing: 'border-box',
        zIndex: 999,
    },
    sorting: {
        cursor: 'pointer',
    },
    sortingIcon: {
        position: 'absolute',
        top: '4px',
        left: 0,
        width: '15px',
        height: '15px',
        display: 'none',
        backgroundColor: theme.palette.background.white,
    },
}));

const PivotTableInner = React.memo(({
    parentId,
    parentWidth,
    tableColumns,
    upperColumns,
    mainData,
    formattingData,
    pivotDefaultColumns,
    openDetails,
}) => {
    const classes = useStyles();

    const getNewColumnWidth = (currentWidth, deltaX) => Math.round(currentWidth + deltaX);
    const getGridTemplateColumns = (cols) => cols.reduce((a, b, index, array) => (index === array.length - 1 ? `${a}auto` : `${a}${b.width}px `), '');

    const setActiveRow = (element) => {
        const parent = element.parentNode;
        const list = parent.classList;

        if (list.contains(classes.activeRow)) {
            parent.classList.remove(classes.activeRow);
        } else {
            const elements = document.getElementsByClassName(classes.activeRow);
            Array.from(elements).forEach((e) => e.classList.remove(classes.activeRow));

            element.parentNode.classList.add(classes.activeRow);
        }
    };

    const collapseDeeperElements = (rowUUID) => {
        const elements = document.querySelectorAll(`[data-uuid='${rowUUID}']`);
        if (elements.length > 0) {
            elements.forEach((i) => {
                i.style.display = 'none';

                const expandIcon = i.getElementsByClassName('expand-icon')[0];
                const collapseIcon = i.getElementsByClassName('collapse-icon')[0];

                if (expandIcon) expandIcon.style.display = 'initial';
                if (collapseIcon) collapseIcon.style.display = 'none';

                const nextLevelSetIdentifier = i.dataset.nextLevelUuid;
                collapseDeeperElements(nextLevelSetIdentifier);
            });
        }
    };

    const toggleChildren = (rowUUID, element) => {
        const elements = document.querySelectorAll(`[data-uuid='${rowUUID}']`);
        if (elements.length > 0) {
            const parent = element.parentNode.parentNode;
            const isShown = elements[0].style.display === 'contents';
            const expandIcon = parent.getElementsByClassName('expand-icon')[0];
            const collapseIcon = parent.getElementsByClassName('collapse-icon')[0];

            if (isShown) {
                expandIcon.style.display = 'initial';
                collapseIcon.style.display = 'none';
                elements.forEach((i) => {
                    i.style.display = 'none';

                    const childExpandIcon = i.getElementsByClassName('expand-icon')[0];
                    const childCollapseIcon = i.getElementsByClassName('collapse-icon')[0];

                    if (childExpandIcon) childExpandIcon.style.display = 'initial';
                    if (childCollapseIcon) childCollapseIcon.style.display = 'none';

                    const nextLevelSetIdentifier = i.dataset.nextLevelUuid;
                    collapseDeeperElements(nextLevelSetIdentifier);
                });
            }

            if (!isShown) {
                expandIcon.style.display = 'none';
                collapseIcon.style.display = 'initial';
                elements.forEach((i) => { i.style.display = 'contents'; });
            }
        }
    };

    const onSorting = (element, columnIndex) => {
        const parent = element.parentNode;
        const { children } = parent;
        Array.from(children).forEach((e, index) => {
            if (columnIndex !== index) {
                delete e.dataset.sortingDirection;
                e.getElementsByClassName('asc-icon')[0].style.display = 'none';
                e.getElementsByClassName('desc-icon')[0].style.display = 'none';
            }
        });

        const { dataset } = element;
        let { sortingDirection } = dataset;

        if (!sortingDirection) {
            sortingDirection = DataSort.ASC;
            element.getElementsByClassName('asc-icon')[0].style.display = 'initial';
            element.getElementsByClassName('desc-icon')[0].style.display = 'none';
        } else {
            const isASC = sortingDirection === DataSort.ASC;
            sortingDirection = isASC ? DataSort.DESC : DataSort.ASC;

            element.getElementsByClassName('asc-icon')[0].style.display = isASC ? 'none' : 'initial';
            element.getElementsByClassName('desc-icon')[0].style.display = isASC ? 'initial' : 'none';
        }

        element.dataset.sortingDirection = sortingDirection;
        const rows = Array.from(document.querySelectorAll('[data-level]'));

        let currentParentRow = 0;
        let subRows = [];
        const rowsInOrder = [];
        rows.forEach((r, index) => {
            if (Number(r.dataset.level) === 0) {
                if (subRows.length > 0) {
                    rowsInOrder[currentParentRow].children = subRows;

                    currentParentRow += 1;
                    subRows = [];
                }

                rowsInOrder.push({ element: r, children: [] });
            } else {
                subRows.push(r);
                if (index === rows.length - 1) rowsInOrder[currentParentRow].children = subRows;
            }
        });

        const sorted = rowsInOrder
            .sort((a, b) => {
                const firstElementValue = String(Array.from(a.element.children)[columnIndex].dataset.currentValue);
                const secondElementValue = String(Array.from(b.element.children)[columnIndex].dataset.currentValue);

                if (columnIndex === 0) {
                    const firstColumnName = tableColumns[0].name;
                    const firstColumnType = pivotDefaultColumns.find((pc) => pc.column === firstColumnName)?.dataType;

                    if (firstColumnType === CHART_COLUMN_DATA_TYPE.ALPHANUMERIC) {
                        return sortingDirection === DataSort.ASC
                            ? firstElementValue.localeCompare(secondElementValue)
                            : secondElementValue.localeCompare(firstElementValue);
                    }
                }

                return sortingDirection === DataSort.ASC
                    ? Number(StringUtils.isEmpty(firstElementValue) ? 0 : firstElementValue)
                        - Number(StringUtils.isEmpty(secondElementValue) ? 0 : secondElementValue)
                    : Number(StringUtils.isEmpty(secondElementValue) ? 0 : secondElementValue)
                        - Number(StringUtils.isEmpty(firstElementValue) ? 0 : firstElementValue);
            })
            .reduce((a, b, index) => {
                const isEven = index % 2 === 0;
                if (isEven) Array.from(b.element.children).forEach((c) => c.classList.add(classes.zebra));
                if (!isEven) Array.from(b.element.children).forEach((c) => c.classList.remove(classes.zebra));

                return [...a, b.element, ...b.children];
            }, []);

        rows.forEach((e) => e.remove());
        const wrapper = document.getElementById(parentId);
        sorted.forEach((e) => wrapper.appendChild(e));
    };

    const getCellCustomStyle = (columnPath = '', columnPathValues = '', name, value) => {
        let selectedCondition = null;
        const cols = columnPath.split('|').map((c) => c.trim()).filter((c) => !StringUtils.isEmpty(c));
        const vals = columnPathValues.split('|').map((c) => c.trim()).filter((c) => !StringUtils.isEmpty(c));

        for (let x = 0; x < formattingData.length; x += 1) {
            const current = formattingData[x];
            const {
                Calculation,
            } = current;

            if (
                (Calculation.includes('All') || Calculation.some((c) => name.startsWith(c)))
                && (
                    cols.length === 0
                    || cols.every((c, idx) => current[c] && (current[c].includes('All') || current[c].includes(vals[idx])))
                )
            ) {
                const {
                    Background,
                    Bold,
                    Condition,
                    ConditionValue,
                    Color,
                    Size,
                } = current;

                const passed = BIHelper.evaluateCondition(Condition, Number(value), ConditionValue);
                if (passed) {
                    selectedCondition = {
                        ...(Color ? { color: Color } : {}),
                        ...(Background ? { backgroundColor: Background } : {}),
                        ...(Size ? { fontSize: Size } : {}),
                        ...(Bold ? { fontWeight: 'bold' } : {}),
                    };

                    break;
                }
            }
        }

        return selectedCondition;
    };

    const renderRows = (records, rowUUID, level = 0, parentPath = []) => {
        let output = [];
        records.forEach(({ columns, children }, index) => {
            const rowIdentifier = uuid();
            const currentPath = [...parentPath, columns[0]];

            output.push((
                <div
                    key={uuid()}
                    className={clsx(classes.rowParent, classes.highLightRow, rowUUID ? classes.noShow : '')}
                    data-uuid={rowUUID ?? ''}
                    data-next-level-uuid={rowIdentifier}
                    data-level={level}
                    onClick={(event) => setActiveRow(event.target)}
                >
                    {columns.map(({
                        name, value, columnPath, columnPathValues,
                    }, idx) => {
                        const currentValue = cloneDeep(value ?? '');
                        const isEven = index % 2 === 0;
                        const isFirstColumn = idx === 0;
                        const isLastColumn = idx === columns.length - 1;
                        const firstColumnWithChildren = isFirstColumn && children.length > 0;
                        const customStyle = !isFirstColumn && value != null ? getCellCustomStyle(columnPath, columnPathValues, name, value) : null;

                        if (idx > 0 && value != null && !isNaN(value)) {
                            value = String(NumberUtils.applyThousandsFormat(Math.trunc(value), '0,0'));
                            if (value.includes('-')) value = `(${value.replace('-', '')})`;
                        }

                        return (
                            <div
                                key={uuid()}
                                {...(isFirstColumn ? { style: { paddingLeft: 10 * level } } : {})}
                                className={clsx(
                                    classes.cell,
                                    isEven && level % 2 === 0 ? classes.zebra : '',
                                    level === 0 ? classes.bold : '',
                                    !isFirstColumn && !isLastColumn ? classes.center : '',
                                    !isFirstColumn ? classes.pointerContext : '',
                                    isFirstColumn ? classes.stickyRow : '',
                                )}
                                {...(customStyle ? { style: customStyle } : {})}
                                {...(!isFirstColumn && !StringUtils.isEmpty(value) ? {
                                    onDoubleClick: () => openDetails(currentPath, columnPath, columnPathValues),
                                } : {})}
                                data-current-value={currentValue}
                            >
                                {firstColumnWithChildren && (
                                    <>
                                        <PlusSquare
                                            className="expand-icon"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                toggleChildren(rowIdentifier, event.target);
                                            }}
                                        />
                                        <MinusSquare
                                            className={clsx(classes.noShow, 'collapse-icon')}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                toggleChildren(rowIdentifier, event.target);
                                            }}
                                        />
                                    </>
                                )}
                                {value ?? ''}
                            </div>
                        );
                    })}
                </div>
            ));

            if (children.length > 0) output = [...output, ...renderRows(children, rowIdentifier, level + 1, currentPath)];
        });

        return output;
    };

    let tableWidth = tableColumns.reduce((a, b) => a + b.width, 0);
    if (tableWidth < parentWidth) tableWidth = parentWidth - 30;
    return (
        <div
            id={parentId}
            className={classes.table}
            style={{
                width: `${tableWidth}px`,
                gridTemplateColumns: getGridTemplateColumns(tableColumns),
            }}
        >
            {upperColumns.map((up, index) => (
                <div key={uuid()} className={classes.rowParent}>
                    {up.map((ele) => (
                        <React.Fragment key={uuid()}>
                            {StringUtils.isEmpty(ele) && (
                                <div style={{ top: 26 * index }} className={clsx(classes.header, classes.stickyColumn)} />
                            )}
                            {!StringUtils.isEmpty(ele) && (
                                <div style={{ top: 26 * index }} className={clsx(classes.header, classes.stickyColumn)}>
                                    {ele}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            ))}
            <div className={classes.rowParent}>
                {tableColumns.map((tc, index) => (
                    <div
                        key={uuid()}
                        style={{ top: 26 * upperColumns.length }}
                        className={clsx(classes.header, classes.stickyColumn, classes.sorting)}
                        onClick={(event) => onSorting(event.target, index)}
                        {...(index === 0 ? { 'data-sorting-direction': DataSort.ASC } : {})}
                    >
                        {tc.name}
                        <ArrowUpwardIcon
                            style={{ display: index === 0 ? 'initial' : 'none' }}
                            className={clsx(classes.sortingIcon, 'asc-icon')}
                            onClick={(event) => event.stopPropagation()}
                        />
                        <ArrowDownwardIcon
                            className={clsx(classes.sortingIcon, 'desc-icon')}
                            onClick={(event) => event.stopPropagation()}
                        />
                        {index < tableColumns.length - 1 && (
                            <div
                                draggable
                                onDrag={({ nativeEvent: { offsetX } }) => {
                                    const newWidth = getNewColumnWidth(tc.width, offsetX);
                                    if (newWidth >= 100) {
                                        const newWidths = tableColumns.map((item, idx) => {
                                            if (index === idx) item.width = newWidth;
                                            return item;
                                        });

                                        const wrapper = document.getElementById(parentId);
                                        let newTableWidth = newWidths.reduce((a, b) => a + b.width, 0);
                                        if (newTableWidth < parentWidth) newTableWidth = parentWidth - 30;

                                        wrapper.style.width = `${newTableWidth}px`;
                                        wrapper.style.gridTemplateColumns = getGridTemplateColumns(newWidths);
                                    }
                                }}
                                className={classes.dragHandleIcon}
                            >
                                ⋮
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {renderRows(mainData)}
        </div>
    );
}, (prevProps, nextProps) => prevProps.parentId === nextProps.parentId);

PivotTableInner.defaultProps = {
    parentId: '',
    parentWidth: -1,
    tableColumns: [],
    upperColumns: [],
    mainData: [],
    formattingData: [],
    pivotDefaultColumns: [],

};

PivotTableInner.propTypes = {
    parentId: PropTypes.string,
    parentWidth: PropTypes.number,
    tableColumns: PropTypes.array,
    upperColumns: PropTypes.array,
    mainData: PropTypes.array,
    formattingData: PropTypes.array,
    pivotDefaultColumns: PropTypes.array,
    openDetails: PropTypes.func.isRequired,
};

export default PivotTableInner;

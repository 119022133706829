import React, { Component } from 'react';

// Component and others
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import onClickOutside from 'react-onclickoutside';

// Material UI
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        zIndex: 9999,
    },
});

class Emoji extends Component {
    handleClickOutside = () => {
        const { props: { onClose } } = this;

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    render() {
        const { props: { classes, onChange } } = this;

        return (
            <div className={classes.root}>
                <Picker
                    emojiSize={20}
                    onSelect={(emoji) => onChange(emoji.native)}
                    title="Pick your emoji…"
                    emoji="point_up"
                />
            </div>
        );
    }
}

Emoji.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(onClickOutside(Emoji));

import { gql } from '@apollo/client';

export default class TagQuery {
    static GET_TAG_BY_CRM_MODULE = gql`
        query getTagByCRMModule($paginate: DataPaging, $active: Boolean) {
            getTagByCRMModule(paginate: $paginate, active: $active) {
                totalCount
                data {
                    tagId
                    name
                    color
                    description
                    module
                    priority
                }
            }
        }
    `;
}

import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Dialog, DialogContent,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import LostReasonMutation from 'services/graphQL/mutate/setting/LostReasonMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    appBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    labels: {
        fontSize: '14px',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
}));

const initState = {
    description: '',
};

const ACTION_TYPES = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
    ON_CHANGE_TEMPLATE_TYPE: 'onChangeTemplateType',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.ON_CHANGE_VALUE:

        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const record = action.payload;
        return update(state, {
            description: { $set: record.description },
            lostReasonId: { $set: record.lostReasonId },
        });
    }
    default:
        return state;
    }
};

const LostReasonDialog = ({
    open, onClose, refetch, record,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(reducer, initState);
    const [addLostReason, { loading: saving }] = useMutation(LostReasonMutation.ADD_LOST_REASON);
    const [updateLostReason, { loading: updating }] = useMutation(LostReasonMutation.UPDATE_LOST_REASON);

    const onCreate = async () => {
        try {
            const response = await addLostReason({ variables: { description: state.description } });

            if (!isEmpty(response.data?.addLostReason)) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    useEffect(() => {
        if (record) {
            dispatch({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                payload: record,
            });
        }
    }, [record]);

    const onUpdate = async () => {
        try {
            const { lostReasonId, description } = state;
            const response = await updateLostReason({ variables: { description, lostReasonId } });

            if (response.data?.updateLostReason) {
                onClose();
                // TODO: Remove this when implement real-time
                refetch();
            } else {
                ModalUtils.errorMessage(null, 'Error ');
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            value,
        });
    };

    const isValidData = StringUtils.isEmpty(state.description);
    const title = `${isEmpty(record) ? 'New' : 'Edit'} Lost Reason`;

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogAppBar title={title} onClose={onClose} iconSize="sm" />
            <DialogContent>
                <div className={classes.content}>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.labels}>Description (50 Chars Max)</Form.Label>
                        <Form.Control
                            maxLength="50"
                            className={clsx(classes.input, { 'invalid-field': StringUtils.isEmpty(state.description) })}
                            type="text"
                            name="description"
                            value={state.description}
                            onChange={(e) => onChangeValue(e.target.name, e.target.value)}
                        />
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                disablePrimaryButton={isValidData || saving || updating}
                onClickPrimary={isEmpty(record) ? onCreate : onUpdate}
            />
        </Dialog>
    );
};

LostReasonDialog.propTypes = {
    onClose: PropTypes.func,
    refetch: PropTypes.func,
    record: PropTypes.object,
    open: PropTypes.bool.isRequired,
};

LostReasonDialog.defaultProps = {
    onClose: () => {},
    refetch: () => {},
    record: null,
};

export default LostReasonDialog;

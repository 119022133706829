import React, { useReducer, useEffect } from 'react';

import {
    Dialog,
    makeStyles,
    DialogContent,
} from '@material-ui/core';
import {
    CheckBoxIcon,
    CheckBoxOutlineBlankIcon,
} from 'components/icons';
import clsx from 'clsx';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import FormMap from 'services/mapData/FormMap';
import Loading from 'components/widgets/Loading';
import If from 'components/widgets/conditional/If';
import FormsQuery from 'services/graphQL/query/FormsQuery';
import FormMutation from 'services/graphQL/mutate/FormMutation';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import CustomFieldDialog from 'components/modules/deals/create/forms/CustomFieldDialog';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '15px 30px',
    },
    highlightedColor: {
        color: theme.palette.secondary.main,
    },
    checkBoxSize: {
        fontSize: '1.3rem',
    },
    columnCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    headerBackgroundColor: {
        background: 'rgba(245, 245, 245, 0.5) !important',
    },
    columnLeft: {
        alignItems: 'left',
        justifyContent: 'left',
        textAlign: 'left',
        fontWeight: 'initial !important',
        color: theme.palette.text.boulderGray,
    },
    emptyRecordMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));

const ACTION_TYPES = {
    SELECT_ALL: 'selectAll',
    SELECT_ONE: 'selectOne',
    SET_STATE_VALUE: 'setStateValue',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUE:
        return update(state, {
            [action.field]: { $set: action.value },
        });
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.values };
    }
    case ACTION_TYPES.SELECT_ALL:
        const newRecords = state.records.map((record) => ({
            ...record,
            isChecked: !action.value,
        }));

        return update(state, {
            records: { $set: newRecords },
        });
    case ACTION_TYPES.SELECT_ONE:
        const index = state.records.findIndex((record) => record.id === action.id);

        return update(state, {
            records: {
                [index]: {
                    isChecked: {
                        $set: !action.value,
                    },
                },
            },
        });
    default: return state;
    }
};

const SecureCloseDialog = ({ closeDialog, accountNumber }) => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const initialState = {
        records: [],
        customFields: [],
        openCustomFieldDialog: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const getColumns = () => [{
        Header: (header) => {
            const { data: records } = header;
            const allApproved = records.length > 0 && records.filter((c) => c._original.isChecked).length === records.length;

            return allApproved ? (
                <CheckBoxIcon
                    fontSize="small"
                    className={clsx(classes.highlightedColor, classes.checkBoxSize)}
                    onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                />
            )
                : (
                    <CheckBoxOutlineBlankIcon
                        fontSize="small"
                        className={classes.checkBoxSize}
                        onClick={() => dispatch({ type: ACTION_TYPES.SELECT_ALL, value: allApproved })}
                    />
                );
        },
        Cell: (cell) => {
            const {
                original: {
                    id, isChecked,
                },
            } = cell;

            return (
                isChecked ? (
                    <CheckBoxIcon
                        className={clsx(classes.highlightedColor, classes.checkBoxSize)}
                        onClick={() => dispatch({
                            id,
                            value: isChecked,
                            type: ACTION_TYPES.SELECT_ONE,
                        })}
                    />
                )
                    : (
                        <CheckBoxOutlineBlankIcon
                            className={classes.checkBoxSize}
                            onClick={() => dispatch({
                                id,
                                value: isChecked,
                                type: ACTION_TYPES.SELECT_ONE,
                            })}
                        />
                    )
            );
        },
        width: 60,
        minWidth: 60,
        sortable: false,
        filterable: false,
        id: 'checkbox-column',
        className: classes.columnCenter,
        headerClassName: clsx(classes.columnCenter, classes.headerBackgroundColor),
    }, {
        Header: 'All',
        accessor: 'commonName',
        headerClassName: clsx(classes.columnLeft, classes.headerBackgroundColor),
    }];

    const selectedLot = keyStore.getSelectedLot();

    const {
        data, loading, error,
    } = useQuery(FormsQuery.GET_SECURE_CLOSE_FORMS_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            state: selectedLot?.lotState || '',
        },
    });

    const mapCustomFields = (records) => records.map((item) => {
        let fields = [];

        if (!StringUtils.isEmpty(item.customField)) {
            fields = JSON.parse(item.customField.data)?.customField;
        }

        return {
            data: fields,
            formId: item.id,
            commonName: item.commonName,
            originalName: item.originalName,
            secureCloseMapID: item.secureCloseMapID,
            secureCloseDocumentName: item.secureCloseDocumentName,
        };
    });

    const [printForms, { loading: printingForms }] = useMutation(FormMutation.SEND_FORMS_TO_SECURE_CLOSE, {
        onCompleted: (mutationData) => {
            if (mutationData?.sendFormsToSecureClose) {
                printJS({
                    printable: mutationData?.sendFormsToSecureClose,
                    type: 'pdf',
                    showModal: false,
                    onPrintDialogClose: () => closeDialog(),
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onCloseCustomFieldsDialog = () => {
        dispatch({
            value: false,
            field: 'openCustomFieldDialog',
            type: ACTION_TYPES.SET_STATE_VALUE,
        });
    };

    const onAcceptCustomFields = (customFieldData) => {
        const mappedCustomFields = FormMap.mapCustomField(customFieldData);
        const input = {
            forms: mappedCustomFields,
            lotId: selectedLot?.lotId || 0,
            dealId: accountNumber,
        };
        printForms({
            variables: {
                input,
            },
        });
        onCloseCustomFieldsDialog();
    };

    const [loadData, { loading: loadingCustomFields }] = useLazyQuery(FormsQuery.GET_CUSTOM_FIELD_BY_FORMS, {
        onCompleted: (customFieldsData) => {
            const mappedCustomFields = mapCustomFields(customFieldsData.getCustomFieldByForms);

            const openCustomFieldDialog = mappedCustomFields.some((item) => item.data.length !== 0);

            if (!openCustomFieldDialog) {
                onAcceptCustomFields(mappedCustomFields);
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                values: {
                    openCustomFieldDialog,
                    customFields: mappedCustomFields,
                },
            });
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(errorMessage?.graphQLErrors);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getSecureCloseFormsList } = data;
            dispatch({
                field: 'records',
                value: getSecureCloseFormsList,
                type: ACTION_TYPES.SET_STATE_VALUE,
            });
        }
    }, [data, loading, error]);

    const selectedFormsIds = state.records.filter((record) => record.isChecked).map((record) => record.id);
    const isTableEmpty = !loading && state.records.length <= 0;
    const hasRecordsToDisplay = !loading && state.records.length > 0;

    const onLoadCustomFields = () => {
        loadData({
            variables: {
                dealId: accountNumber,
                forms: selectedFormsIds,
            },
        });
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                titleVariant="h4"
                onClose={closeDialog}
                title="Send to Secure Close"
            />
            <DialogContent className={classes.dialogContent}>
                <If condition={loading}>
                    <Loading />
                </If>
                <If condition={isTableEmpty}>
                    <div className={classes.emptyRecordMessage}>No documents to display</div>
                </If>
                <If condition={hasRecordsToDisplay}>
                    <Table
                        className="without-style"
                        resizable={false}
                        sortable={false}
                        cursor="default"
                        data={state.records}
                        columns={getColumns()}
                        totalRecords={state.records.length}
                    />
                </If>
            </DialogContent>
            <If condition={isTableEmpty}>
                <DialogActions
                    divider={false}
                    hiddenPrimaryButton
                    onClickSecondary={closeDialog}
                />
            </If>
            <If condition={hasRecordsToDisplay}>
                <DialogActions
                    divider={false}
                    titlePrimary="Send"
                    hiddenSecondaryButton
                    onClickPrimary={onLoadCustomFields}
                    disablePrimaryButton={loadingCustomFields || printingForms}
                />
            </If>
            {state.openCustomFieldDialog && (
                <CustomFieldDialog
                    maxWidth="sm"
                    loading={loadingCustomFields}
                    records={state.customFields}
                    open={state.openCustomFieldDialog}
                    onClose={onCloseCustomFieldsDialog}
                    onClickPrimaryButton={onAcceptCustomFields}
                />
            )}
        </Dialog>
    );
};

SecureCloseDialog.propTypes = {
    closeDialog: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
};

export default SecureCloseDialog;

import ButtonStyle from 'styles/theme/Button';
import palette from 'styles/theme/palette';
import ExpansionPanelStyles from 'styles/widgets/ExpansionPanelStyles';

export default class DealStyles {
    static dealList(theme = {}, fade) {
        return ({
            main: {
                flex: 1,
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'column',
            },
            listContainer: {
                flex: 1,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    left: 100,
                },
            },
            boxContainer: {
                flexDirection: 'column',
                background: theme.palette.background.default,
            },
            optionContainer: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(1),
            },
            optionHeader: {
                display: 'flex',
                alignItems: 'center',
            },
            optionLabel: {
                background: palette.background.gray,
                padding: theme.spacing(1.3),
                border: `1px solid ${palette.border.primary}`,
                borderRadius: '3px',
            },
            ...ButtonStyle.getStyle(theme, fade),
            box: {
                overflow: 'auto',
            },
            button: {
                minWidth: 100,
                marginRight: theme.spacing(2),
            },
            backgroundRow: {
                background: palette.background.paper,
            },
            rowFlagged3: {
                background: palette.background.lightShadeRed,
                border: `1px solid ${palette.border.darkRed}`,
                borderRadius: '2px',
            },
            rowFlagged2: {
                background: palette.background.lightShadeYellow,
                border: `1px solid ${palette.border.darkYellow}`,
                borderRadius: '2px',
            },
            rowFlagged1: {
                background: palette.background.lightShadeGreen,
                border: `1px solid ${palette.border.darkGreen}`,
                borderRadius: '2px',
            },
            search: {
                marginRight: theme.spacing(2),
                width: 300,
            },
            select: {
                width: '120px',
                marginRight: '20px',
                '&>div': {
                    borderColor: `${palette.border.cadetGrey} !important`,
                    borderRadius: 'initial',
                    borderWidth: '0 0 1px 0',
                    height: '30px !important',
                    boxShadow: '0 0 0 0 transparent !important',
                    '&>div[class$="IndicatorsContainer"]': {
                        '&>div[class$="indicatorContainer"]::before': {
                            display: 'none',
                        },
                    },
                },
            },
            sentIcon: {
                marginRight: theme.spacing(2),
                color: theme.palette.text.white,
                background: theme.palette.secondary.main,
                '&:hover': {
                    backgroundColor: theme.palette.background.scienceBlue,
                },
            },
        });
    }

    static expansionPanel() {
        return ({
            root: {
                marginBottom: '12px',
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                '&$expanded': {
                    margin: 0,
                    marginBottom: '12px',
                },
            },
            expanded: {},
        });
    }

    static expansionPanelSummary() {
        return ({
            root: {
                minHeight: 56,
                padding: 9,
                paddingBottom: 7,
                '&$expanded': {
                    minHeight: 56,
                },
            },
            content: {
                margin: 0,
                '&$expanded': {
                    margin: 0,
                },
            },
            expanded: {},
        });
    }

    static expandRow(theme = {}) {
        return ({
            expansionPanel: {
                border: `1px solid ${theme.palette.divider}`,
            },
            expansionCol: {
                color: '#382652',
                display: 'flex',
            },
            textGray: {
                color: theme.palette.text.gray,
            },
            spacingVehicleOpenOn: {
                marginRight: theme.spacing(8),
            },
            colProspect: {
                flexDirection: 'column',
                maxWidth: 185,
                minWidth: 185,
            },
        });
    }

    static dealStructure(theme = {}, fade) {
        return ({
            reserveCheck: {
                marginRight: 0,
                marginLeft: 0,
                marginTop: '6px',
            },
            marginBottom0: {
                marginBottom: 0,
            },
            discountCheck: {
                marginRight: 0,
                marginLeft: '2px',
            },
            flexCenter: {
                display: 'flex',
                alignItems: 'center',
            },
            formW100: {
                width: '100%',
            },
            rootPaper: {
                padding: theme.spacing(2),
                overflow: 'auto',
                width: '100%',
            },
            paddingLeft0: {
                paddingLeft: '0px !important',
            },
            endItem: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(3),
            },
            spaceBetween: {
                display: 'flex',
                justifyContent: 'space-between',
                '& .mathematicExpression': {
                    marginRight: theme.spacing(2),
                    fontSize: 14,
                    fontWeight: 500,
                },
            },
            lastRow: {
                '& .col': {
                    marginBottom: 0,
                },
            },
            dialogActions: {
                padding: theme.spacing(2),
            },
            columnFields: {
                '& > p': {
                    width: '35%',
                    'text-align': 'start',
                    color: theme.palette.text.grayLabel,
                },
                '& > h6': {
                    width: '65%',
                    'padding-right': '20px',
                    'text-align': 'end',
                    color: theme.palette.text.grayLabel,
                },
            },
            alignCheckBox: {
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                marginBottom: '4px',
            },
            checkIcon: {
                width: 14,
                height: 14,
                color: theme.palette.text.stormGray,
                'margin-right': '4px',
                'align-self': 'center',
            },
            maxRateRow:
                {
                    marginTop: '-15px',
                    marginBottom: '-20px',
                },
            finalOddPaymentContainer: {
                display: 'flex',
                margin: 0,
                marginTop: -10,
                justifyContent: 'space-between',
            },
            switchMargin: {
                marginRight: 10,
                marginTop: -5,
            },
            ...ButtonStyle.getStyle(theme, fade),
        });
    }

    static dealHeader(theme = {}) {
        return ({
            rootPaper: {
                marginBottom: theme.spacing(2),
                padding: theme.spacing(0.6),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            iconButton: {
                marginRight: theme.spacing(0.5),
            },
            textGray: {
                color: theme.palette.text.gray,
            },
            vehicleInfo: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                '& .MuiTypography-root': {
                    margin: theme.spacing(0, 0.5),
                },
            },
            boxItem: {
                display: 'flex',
                alignItems: 'center',
            },
            groupName: {
                marginLeft: theme.spacing(6),
                marginRight: theme.spacing(6),
            },
            boxAvatarWithName: {
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(2),
            },
            imageVehicle: {
                width: theme.spacing(7),
                height: theme.spacing(5),
                objectFit: 'cover',
                marginRight: theme.spacing(1),
            },
            avatar: {
                width: theme.spacing(3),
                height: theme.spacing(3),
                marginRight: theme.spacing(1),
            },
            avatarWithName: {
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(-1),
            },
        });
    }

    static details(theme = {}) {
        return ({
            header: {
                width: '100%',
            },
            numberContainer: {
                width: '200px',
                minWidth: '200px',
                '&>*': {
                    display: 'inline-block',
                },
            },
            headerInfoContainer: {
                marginLeft: '10px',
                width: '100%',
            },
            dealNumber: {
                marginLeft: 8,
                fontSize: 16,
            },
            boxContainer: {
                position: 'relative;',
                background: theme.palette.background.default,
                flex: 1,
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            },
            menuButton: {
                position: 'absolute',
                right: '16px',
                borderRadius: 0,
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '10px',
                    width: '100%',
                    position: 'initial',
                },
            },
            dealContainer: {
                width: '48px',
                minWidth: '48px',
            },
        });
    }

    static dealStyles(theme = {}, fade) {
        return ({
            overflowAuto: {
                overflow: 'auto',
            },
            rootPaper: {
                padding: theme.spacing(2),
                overflow: 'auto',
                width: '100%',
            },
            container: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            },
            paddingLeft0: {
                paddingLeft: '0px !important',
            },
            endItem: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(3),
                minHeight: theme.spacing(4),
            },
            deleteIcon: {
                color: theme.palette.error.main,
                width: '16px',
                marginBottom: '-2px',
                '& button:disabled svg g': {
                    stroke: `rgba(${theme.palette.rgb.black},0.26)`,
                },
            },
            buttonAddProduct: {
                cursor: 'pointer',
                '&:disabled svg g': {
                    fill: `rgba(${theme.palette.rgb.black},0.26)`,
                },
            },
            boxProduct: {
                marginBottom: theme.spacing(1.6),
                padding: theme.spacing(1),
                background: `rgb(${theme.palette.rgb.lightWhite})`,
                display: 'flex',
                alignItems: 'flex-end',
                '& .row-product': {
                    display: 'flex',
                },
                '& .row-product p': {
                    marginLeft: theme.spacing(0.6),
                },
            },
            boxSalesRep1: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            spaceBetween: {
                display: 'flex',
                justifyContent: 'space-between',
                '& .mathematicExpression': {
                    marginRight: theme.spacing(2),
                    fontSize: 14,
                    fontWeight: 500,
                },
            },
            flexEnd: {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 6px',
            },
            textRed: {
                color: theme.palette.error.main,
            },
            textInfo: {
                color: theme.palette.info.main,
                textDecoration: 'underline',
                cursor: 'pointer',
            },
            textDisabled: {
                color: `rgba(${theme.palette.rgb.black},0.26)`,
            },
            formProduct: {
                display: 'block',
                width: '100%',
            },
            lineDivider: {
                display: 'block',
                maxWidth: '120px',
                height: '2px',
                background: theme.palette.primary.main,
                marginLeft: theme.spacing(2),
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            boxRow: {
                '& .space-between-sections': {
                    marginBottom: theme.spacing(3),
                },
                '& .space-between-back-total': {
                    marginBottom: theme.spacing(1),
                },
                padding: theme.spacing(2),
                '& .content-label': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    textAlign: 'end',
                },
                '& .content-value': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    '& p': {
                        maxWidth: '100px',
                        width: '100px',
                        textAlign: 'right',
                        marginLeft: theme.spacing(2),
                    },
                },
            },
            lastRow: {
                '& .col': {
                    marginBottom: 0,
                },
            },
            rowWeOwe: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'inherit',
            },
            boxIconDelete: {
                width: '48px',
            },
            dialogContent: {
                padding: theme.spacing(2),
            },
            weOweDialogWidth: {
                '& > div > div': {
                    width: '690px',
                },
            },
            weOweLabels: {
                width: '100%',
                textAlign: 'center',
            },
            addWeOweIcon: {
                paddingLeft: 0,
            },
            dialogContentWeOwe: {
                padding: '16px 30px 16px 21px',
            },
            dialogActions: {
                padding: theme.spacing(2),
            },
            columnFields: {
                '& > p, h6': {
                    color: theme.palette.text.grayLabel,
                },
                '& > h6': {
                    'margin-right': '3px',
                },
                display: 'flex',
            },
            productsCheckIcon: {
                width: 14,
                height: 14,
                color: theme.palette.text.stormGray,
                'margin-right': '4px',
                'align-self': 'center',
            },
            productCheckBox: {
                margin: '0px 5px 0px 10px',
                padding: '0px',
            },
            ...ButtonStyle.getStyle(theme, fade),
            saleLineContainer: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            truncate: {
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            containerProduct: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                marginBottom: theme.spacing(1.5),
            },
            nameEmployee: {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '20px',
                marginRight: 6,
                color: theme.palette.text.dodgerBlue,
            },
            commissionOverrideIcon: {
                marginLeft: theme.spacing(1),
                color: theme.palette.background.tacao,
            },
        });
    }

    static vehiclePanel(theme = {}, fade) {
        return ({
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                padding: theme.spacing(2),
            },
            rootPaper: {
                width: '100%',
                padding: theme.spacing(2),
                paddingTop: '8px',
                paddingLeft: '0px',
                marginBottom: theme.spacing(2),
                boxSizing: 'border-box',
                boxShadow: '0px 1px 4px 1px rgba(0,0,0,0.1)',
            },
            rootPaperTradeIn: {
                width: '100%',
                padding: theme.spacing(2),
                boxSizing: 'border-box',
                boxShadow: '0px 1px 4px 1px rgba(0,0,0,0.1)',
            },
            paddingLeft0: {
                paddingLeft: '0px !important',
            },
            endItem: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(3),
            },
            actions: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1),
            },
            gridRow: {
                marginBottom: theme.spacing(1),
            },
            alignCenter: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            alignLeft: {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
            tradeInBox: {
                marginBottom: theme.spacing(2),
                padding: theme.spacing(1),
                background: '#BEFEFD',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
            },
            tradeInGridItem: {
                padding: theme.spacing(1),
            },
            tradeInForm: {
                display: 'block',
                width: '100%',
            },
            ...ButtonStyle.getStyle(theme, fade),
            addButton: {
                width: 'fit-content',
            },
            actionsSection: {
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            },
            removeIcon: {
                margin: '8px',
                cursor: 'pointer',
            },
            datePickerLabel: {
                width: '100%',
            },
            checkboxSection: {
                height: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
            },
            checkboxHeight: {
                height: 'auto',
                '& .MuiGrid-item': {
                    display: 'flex',
                    paddingBottom: '0px',
                },
            },
            title: {
                color: '#727082',
                fontSize: '16px',
                fontWeight: 700,
            },
            noSalesTaxCreditStyle: {
                paddingTop: '20px',
                paddingBottom: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            checkboxTitle: {
                '& .form-check-input': {
                    width: '18px',
                    height: '18px',
                    paddingRight: '20px',
                },
            },
        });
    }

    static dealsForms(theme = {}, fade) {
        return ({
            formW100: {
                width: '100%',
            },
            lastRow: {
                '& .col': {
                    marginBottom: 0,
                },
            },
            dialogContent: {
                padding: theme.spacing(3),
            },
            registrationDialogWidth: {
                '& > div > div': {
                    width: '500px',
                },
            },
            deferredDownPaymentWidth: {
                '& > div > div': {
                    width: '700px',
                    height: '650px',
                },
            },
            feeDialogWidth: {
                '& > div > div': {
                    width: '684px',
                },
            },
            dialogContentRegistration: {
                padding: '15px 23px 42px 43px',
            },
            dialogContentDeferredDownPayment: {
                padding: '15px 29px 15px 43px',
            },
            dialogContentFee: {
                padding: '22px 9px 22px 31px',
            },
            dialogActions: {
                padding: theme.spacing(2),
            },
            cancelStyle: {
                color: '#818090',
                backgroundColor: '#e3e3e3',
                borderColor: '#e3e3e3',
            },
            styleGrid: {
                display: 'flex',
                alignItems: 'center',
                '& > p': {
                    color: theme.palette.text.emperor,
                },
            },
            minHeight300: {
                minHeight: 300,
            },
            deleteIcon: {
                width: 20,
                height: 20,
            },
            iconButtonDelete: {
                color: theme.palette.error.main,
            },
            spaceButtonAdd: {
                height: 44,
            },
            spaceButtonDelete: {
                minWidth: 26,
                height: 44,
            },
            dialog: {
                height: 430,
                minHeight: 430,
            },
            deferredDownPaymentContainer: {
                display: 'flex',
                alignItems: 'center',
            },
            addButton: {
                marginTop: theme.spacing(-3),
                marginLeft: theme.spacing(-0.6),
            },
            contentAddRow: {
                display: 'flex',
                alignItems: 'center',
            },
            titleDialogTags: {
                color: theme.palette.text.darkShark,
            },
            labelDialogTags: {
                color: theme.palette.text.shark,
                fontSize: '14px',
            },
            labelDialogDeferred: {
                color: theme.palette.text.shark,
                fontSize: '14px',
            },
            inputDialogDeferred: {
                '& > input[type=text]': {
                    fontSize: '14px',
                },
            },
            inputDialogTags: {
                '& > input[type=text]': {
                    fontSize: '14px',
                },
            },
            labelDialogFee: {
                color: theme.palette.text.shark,
                fontSize: '16px',
            },
            formColumnLeftFee: {
                paddingRight: '34px !important',
            },
            formColumnRightFee: {
                paddingLeft: '34px !important',
            },
            ...ButtonStyle.getStyle(theme, fade),
        });
    }

    static insuranceStyles(theme = {}) {
        return ({
            container: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: theme.spacing(2),
                overflowY: 'auto',
            },
            rootPaper: {
                width: '100%',
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
                boxSizing: 'border-box',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBottom: theme.spacing(1),
            },
            gridRow: {
                marginBottom: theme.spacing(2.5),
            },
            typographyContainer: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: theme.spacing(1),
            },
        });
    }

    static referenceStyles(theme = {}) {
        return ({
            root: {
                overflow: 'hidden',
                background: theme.palette.background.white,
                margin: '12px 30px',
                flex: 1,
                paddingBottom: '12px',
                width: 'auto',
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(1),
            },
            button: {
                color: theme.palette.text.white,
                width: '95px',
                marginRight: '16px',
            },
            newButton: {
                backgroundColor: theme.palette.background.eucalyptus,
                '&:hover': {
                    backgroundColor: theme.palette.background.forestGreen,
                },
            },
            editButton: {
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            removeButton: {
                backgroundColor: '#E97C87',
                '&:hover': {
                    backgroundColor: '#D6727C',
                },
            },
            content: {
                height: '100%',
                flex: 1,
                overflow: 'hidden',
            },
            columnStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        });
    }

    static dealCreate(theme = {}, fade) {
        return ({
            dealCreateContainer: {
                background: theme.palette.background.white,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                overflow: 'auto',
            },
            dealCreateHeader: {
                display: 'flex',
                padding: theme.spacing(2, 3),
                '& .form-group': {
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: theme.spacing(3),
                    marginBottom: 0,
                },
                '& .form-group label': {
                    minWidth: 64,
                    marginBottom: 0,
                    lineHeight: '21px',
                },
                '& .form-group .select-bootstrap': {
                    minWidth: 180,
                    marginLeft: theme.spacing(2),
                },
            },
            containerCard: {
                flex: 1,
                padding: theme.spacing(1),
                margin: 0,
                width: '100%',
            },
            leadId: {
                fontSize: 16,
            },
            addVehicle: {
                marginBottom: theme.spacing(3),
            },
            textOr: {
                fontWeight: 'bold',
            },
            boxButtonAndText: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            textGray: {
                color: theme.palette.text.gray,
            },
            textInformation: {
                textDecoration: 'underline',
                color: theme.palette.text.infoDark,
                marginTop: theme.spacing(1.8),
                fontSize: '15px',
                cursor: 'pointer',
            },
            ...ButtonStyle.getStyle(theme, fade),
            importButton: {
                color: theme.palette.text.blueGrey,
                fontWeight: 'bold',
                margin: theme.spacing(2),
                border: '2px solid',
                backgroundColor: '#fff',
                borderColor: theme.palette.secondaryInfo.main,
                minWidth: 180,
                '&:hover': {
                    border: '2px solid',
                    backgroundColor: '#fff',
                    borderColor: theme.palette.secondaryInfo.main,
                },
            },
            boxContainerDetails: {
                overflow: 'auto',
                background: theme.palette.background.default,
            },
            boxVehicle: {
                padding: theme.spacing(0.5),
                paddingRight: theme.spacing(3),
                marginBottom: theme.spacing(3),
            },
            title: {
                color: theme.palette.text.stormGray,
                padding: theme.spacing(1.4),
            },
            formDealStructure: {
                padding: theme.spacing(0.5, 4, 3.2),
            },
            button: {
                marginRight: theme.spacing(2),
                minWidth: 100,
            },
            labelColor: {
                color: theme.palette.text.grayShark,
            },
            changeVehicleStyle: {
                color: '#777777',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 0,
                marginTop: '-12px',
                height: 30,
                width: 30,
            },
            iconColor: {
                color: '#2748AC',
            },
            personalInformation: {
                '& .gridItem': {
                    marginBottom: theme.spacing(2),
                },
            },
            iconAdd: {
                color: theme.palette.background.green,
            },
            defaultCalculationDropdown: {
                paddingTop: '0px',
            },
        });
    }

    static dialogSearch(theme = {}) {
        return ({
            dialogContent: {
                height: 350,
                padding: theme.spacing(1, 1.8),
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            },
            dialogContentMd: {
                height: 420,
                padding: theme.spacing(1, 1.8),
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            },
            dialog: {
                maxWidth: 1000,
            },
            dialogMd: {
                maxWidth: 980,
            },
            contantList: {
                flex: 1,
                overflow: 'auto',
                '& .customer-item': {
                    border: `1px solid ${theme.palette.divider}`,
                    fontSize: 14,
                    borderBottom: 0,
                },
                '& .customer-item:last-child': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    marginBottom: 1,
                },
            },
            textItem: {
                fontWeight: 'bold',
                padding: theme.spacing(1),
                cursor: 'pointer',
            },
            boxSearch: {
                marginBottom: theme.spacing(1.8),
            },
        });
    }

    static dialogTransferDeal(theme = {}) {
        return ({
            containtList: {
                flex: 1,
                overflow: 'auto',
                '& .customer-item': {
                    border: `1px solid ${theme.palette.divider}`,
                    fontSize: 14,
                    borderBottom: 0,
                    paddingRight: '16px',
                },
                '& .customer-item:last-child': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    marginBottom: 1,
                },
            },
            dialogWidth: {
                '& > div > div': {
                    width: '600px',
                },
            },
            dialogContent: {
                padding: '15px 30px',
            },
            textItem: {
                fontWeight: 'bold',
                padding: theme.spacing(1),
                cursor: 'pointer',
            },
            noHover: {
                color: 'black',
                backgroundColor: 'transparent !important',
            },
        });
    }

    static dialogEditfield(theme = {}) {
        return ({
            dialogContent: {
                width: '400px',
                padding: theme.spacing(1, 1.8),
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            },
            formStyle: {
                margin: '10px',
            },
        });
    }

    static dialogHistory(theme = {}) {
        return ({
            dialog: {
                width: 742,
                height: 411,
            },
            dialogContent: {
                padding: 0,
            },
            buttonPlus: {
                color: theme.palette.text.minsk,
            },
            contentButtonPlus: {
                minWidth: 100,
                textAlign: 'center',
            },
            rowDialog: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(1, 0),
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:last-child': {
                    borderBottom: 'none',
                },
            },
            boxContent: {
                flex: 1,
            },
        });
    }

    static postToAccounting() {
        return {
            dialogContent: {
                '& .full-width-date-picker': {
                    marginBottom: 0,
                },
            },
            dialog: {
                width: '400px',
            },
        };
    }

    static referenceDialog(theme = {}) {
        return ({
            dialog: {
                width: 892,
            },
            dialogContent: {
                padding: theme.spacing(3),
            },
            marginBottom0: {
                marginBottom: 0,
            },
        });
    }

    static customField(theme = {}) {
        return ({
            radioInfo: {
                color: theme.palette.info.main,
                padding: 4,
                marginLeft: 4,
                '&.Mui-checked': {
                    color: theme.palette.info.main,
                },
            },
            dialog: {
                width: 892,
            },
            dialogContent: {
                padding: theme.spacing(3),
                position: 'relative',
            },
            marginBottom0: {
                marginBottom: 0,
            },
            radioGroup: {
                marginBottom: 16,
            },
            loading: {
                position: 'absolute',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                top: 0,
                left: 0,
                zIndex: 3,
            },
        });
    }

    static VehicleItem(theme = {}) {
        return {
            itemContainer: {
                display: 'flex',
                width: '100%',
            },
            title: {
                color: '#727082',
                fontSize: '16px',
                paddingLeft: '10px',
                paddingBottom: '14px',
                fontWeight: 700,
            },
            itemStyle: {
                color: '#767676',
                lineHeight: '24px',
                width: '66%',
            },
            vehicleDetail: {
                flexDirection: 'column',
                minWidth: 300,
                marginLeft: '8px',
                '& > div:last-child span': {
                    fontWeight: 'bold',
                    marginLeft: 5,
                },
            },
            labelRow: {
                display: 'flex',
                fontSize: '14px',
                fontWeight: '500',
            },
            labelLeft: {
                width: '34%',
                fontWeight: 500,
            },
            topContainerStyle: {
                display: 'flex',
                justifyContent: 'space-between',
            },
            iconColor: {
                color: '#2748AC',
            },
            changeVehicleStyle: {
                color: '#777777',
                '& > div:last-child span': {
                    color: '#2748AC',
                    fontWeight: '500',
                },
                '&:disabled svg': {
                    color: `rgba(${theme.palette.rgb.black}, 0.26)`,
                },
            },
            tradeInSection: {
                display: 'flex',
                flexDirection: 'row',
            },
            tradeInStyle: {
                color: '#767676',
                lineHeight: '24px',
                marginLeft: '10px',
            },
            tradeInTitleStyle: {
                color: '#767676',
                lineHeight: '24px',
                fontWeight: 500,
            },
            bottomContainer: {
                paddingLeft: '10px',
            },
            checkboxStyle: {
                color: '#767676',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                fontFamily: 'Roboto, Helvetica, Arial sans-serif',
            },
            editMiles: {
                color: '#2748AC',
                '& > div:last-child span': {
                    color: '#2748AC',
                    fontWeight: '500',
                },
            },
            tradeInBoxStyle: {
                display: 'flex',
                marginRight: '28px',
            },
            iconButtonDelete: {
                '&:disabled svg g': {
                    stroke: `rgba(${theme.palette.rgb.black},0.26)`,
                },
            },
            ...ExpansionPanelStyles.expandRow(theme),
        };
    }
}

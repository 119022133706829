import { gql } from '@apollo/client';

export default class AccountingGLQuery {
  static GET_ACCOUNTING_GL_CHECK_LIST = gql`
    query getAccountingGLCheckList($processRecordId: ID, $processTypeId: Int) {
      getAccountingGLCheckList(
        processRecordId: $processRecordId
        processTypeId: $processTypeId
      ) {
        data {
          entryId
          debit
          credit
          controlNumber
          department
          departmentId
          accountCOA {
            accountNumber
            description
            controlledBy
            isControlled
            type
          }
          lot {
            lotId
            lotName
          }
          departmentData {
            departmentId
            description
          }
          memo
          processDetailRecordId
          processTypeId
        }
        totalCount
      }
    }
  `;

  static GET_ACCOUNTING_GL_LIST_FOR_BANKING = gql`
    query getAccountingGLList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingGLFilter
    ) {
      getAccountingGLList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          debit
          credit
          controlNumber
          referenceNumber
          memo
          postDate
          glType
          accountCOA {
            accountNumber
            description
          }
          lot {
            lotId
            lotName
          }
        }
      }
    }
  `;

  static GET_ACCOUNTING_GL_LIST_FOR_GENERAL_JOURNAL = gql`
    query getAccountingGLList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingGLFilter
    ) {
      getAccountingGLList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          debit
          credit
          controlNumber
          referenceNumber
          memo
          postDate
          lotName
          accountCOA {
            accountNumber
            description
          }
          lot {
            lotId
            lotName
          }
        }
      }
    }
  `;

  static GET_ACCOUNTING_GL_LIST = gql`
    query getAccountingGLListForDistribution(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingGLFilter
    ) {
      getAccountingGLListForDistribution(
        paginate: $paginate
        sort: $sort
        filter: $filter
      ) {
        totalCount
        data {
          entryId
          debit
          credit
          controlNumber
          referenceNumber
          memo
          lotName
          accountCOA {
            accountNumber
            description
            type
          }
          lot {
            lotId
            lotName
          }
        }
      }
    }
  `;

  static GENERAL_JOURNAL_LIST = gql`
    query (
      $search: String
      $offset: Int
      $limit: Int
      $lotName: String!
      $userId: Int
    ) {
      journalList(
        search: $search
        offset: $offset
        limit: $limit
        lotName: $lotName
        userId: $userId
      ) {
        Journals {
          journalId
          referenceNumber
          postedDate
          memo
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
        recordsLength
      }
    }
  `;

  static GENERAL_JOURNAL_DETAIL_LIST = gql`
    query journalDetailList(
      $journalId: ID!
      $offset: Int
      $limit: Int
      $ignoreLimit: Boolean
    ) {
      journalDetailList(
        journalId: $journalId
        offset: $offset
        limit: $limit
        ignoreLimit: $ignoreLimit
      ) {
        entryId
        accountNumber
        debit
        credit
        controlNumber
        memo
        lotId
        lotName
        recordsLength
      }
    }
  `;

  static GENERAL_JOURNAL_DETAIL = gql`
    query ($journalId: ID!) {
      journalDetail(journalId: $journalId) {
        journalId
        referenceNumber
        postedDate
        memo
        recurring {
          recurringJournalId
          frequencyType
          frequency
          startDate
          recurringType
          endBy
          endAfter
        }
      }
    }
  `;

  static RECURRING_JOURNAL_ID = gql`
    query ($journalId: ID!) {
      journalDetail(journalId: $journalId) {
        recurring {
          recurringJournalId
        }
      }
    }
  `;

  static NEXT_REFERENCE_NUMBER = gql`
    query {
      nextJournalReferenceNumber
    }
  `;

  static ADD_GENERAL_JOURNAL = gql`
    mutation addJournal(
      $journal: JournalInput!
      $journalDetail: [AccountGLInput!]!
      $lotName: String!
      $recurring: RecurringJournalInput
    ) {
      addJournal(
        journal: $journal
        journalDetail: $journalDetail
        lotName: $lotName
        recurring: $recurring
      )
    }
  `;

  static UPDATE_GENERAL_JOURNAL = gql`
    mutation updateJournal(
      $journalId: ID!
      $journal: JournalInput!
      $journalDetail: [AccountGLInput!]!
      $lotName: String!
    ) {
      updateJournal(
        journalId: $journalId
        journal: $journal
        journalDetail: $journalDetail
        lotName: $lotName
      )
    }
  `;

  static DELETE_GENERAL_JOURNAL = gql`
    mutation deleteJournal($journalId: ID!, $lotName: String!) {
      deleteJournal(journalId: $journalId, lotName: $lotName)
    }
  `;

  static SAVE_RECURRING_JOURNAL = gql`
    mutation saveRecurringJournal($input: RecurringJournalInput!) {
      saveRecurringJournal(input: $input)
    }
  `;

  static DUPLICATE_JOURNAL = gql`
    mutation duplicateJournal($journalId: ID!) {
      duplicateJournal(journalId: $journalId)
    }
  `;

  static GET_ACCOUNTING_GL_USER_LIST = gql`
    query getAccountingGLUsers {
      getAccountingGLUsers {
        userId
        userName
      }
    }
  `;

  static GET_AVAILABLE_CONTROL_NUMBERS = gql`
    query getAvailableControlNumbers($type: String!, $ids: [String]) {
      getAvailableControlNumbers(type: $type, ids: $ids) {
        recordId
        controlNumbers {
          id
          description
        }
      }
    }
  `;

  static DEALS_JOURNAL_LIST = gql`
    query getDealsJournalList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingJournalFilter
    ) {
      getDealsJournalList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          accountNumber
          soldDate
          postedDate
          stockNumber
          dealType
          dealFunded
          status
          lotName
          notes
          firstName
          lastName
        }
      }
    }
  `;

  static SERVICE_JOURNAL_LIST = gql`
    query getServiceJournalList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingJournalFilter
    ) {
      getServiceJournalList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          invoiceNumber
          roOpen
          roClosed
          postedDate
          lotName
          firstName
          lastName
          roType
          status
        }
      }
    }
  `;

  static INVENTORY_JOURNAL_LIST = gql`
    query getInventoryJournalList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingJournalFilter
    ) {
      getInventoryJournalList(
        paginate: $paginate
        sort: $sort
        filter: $filter
      ) {
        totalCount
        data {
          stockNumber
          purchasedDate
          postedDate
          vin
          year
          make
          model
          trim
          extColor
          isFloorPlanned
          lotName
        }
      }
    }
  `;

  static PARTS_JOURNAL_LIST = gql`
    query getPartsJournalList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: AccountingJournalFilter
    ) {
      getPartsJournalList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          ticketNumber
          ticketOpen
          postedDate
          salesType
          status
          lotName
          customer {
            firstName
            lastName
          }
        }
      }
    }
  `;

  static GET_JOURNAL_COMMON_DETAIL_LIST = gql`
    query getCommonJournalDetailList($recordId: ID, $type: String) {
      getCommonJournalDetailList(recordId: $recordId, type: $type) {
        data {
          entryId
          accountNumber
          debit
          credit
          controlNumber
          referenceNumber
          memo
          lotId
          lotName
          accountCOA {
            description
            isControlled
            controlledBy
          }
        }
      }
    }
  `;

  static CREATE_JOURNAL_PROCESS = gql`
    mutation postJournalProcess($record: JournalProcessInput!) {
      postJournalProcess(record: $record) {
        success
        isLockedDate
        lockedDate
      }
    }
  `;

  static CREATE_REVERSAL_JOURNAL_PROCESS = gql`
    mutation revertJournalProcess(
      $processRecordId: Int!
      $journalType: String!
      $postDate: Date!
    ) {
      revertJournalProcess(
        processRecordId: $processRecordId
        journalType: $journalType
        postDate: $postDate
      ) {
        success
        isLockedDate
        lockedDate
      }
    }
  `;

static GET_SERVICE_JOURNAL_DETAIL_LIST = gql`
  query getServiceJournalDetailList($recordId: ID, $type: String) {
    getServiceJournalDetailList(recordId: $recordId, type: $type) {
      data {
        entryId
        accountNumber
        debit
        credit
        controlNumber
        referenceNumber
        memo
        lotId
        lotName
        accountCOA {
          description
          isControlled
          controlledBy
        }
      }
    }
  }
  `;

  static GET_SERVICE_JOURNAL_DETAIL_LIST_TO_POST_AUTOMATIC = gql`
  query getServiceJournalDetailListToPostAutomatic($recordId: ID, $type: String) {
    getServiceJournalDetailListToPostAutomatic(recordId: $recordId, type: $type) {
      data {
        entryId
        accountNumber
        debit
        credit
        controlNumber
        referenceNumber
        memo
        lotId
        lotName
        accountCOA {
          description
          isControlled
          controlledBy
        }
      }
    }
  }
  `;

  static POST_SERVICE_JOURNAL_PROCESS = gql`
  mutation postServiceJournalProcess($record: JournalProcessInput!) {
    postServiceJournalProcess(record: $record) {
      success
      isLockedDate
      lockedDate
    }
  }
  `;

  static REVERT_SERVICE_JOURNAL_PROCESS = gql`
  mutation revertServiceJournalProcess(
  $processRecordId: Int!
  $journalType: String!
  $postDate: Date!
  ) {
  revertServiceJournalProcess(
    processRecordId: $processRecordId
    journalType: $journalType
    postDate: $postDate
  ) {
    success
    isLockedDate
    lockedDate
  }
  }
  `;

  static GENERATE_PNL = gql`
    mutation generatePNL($record: GeneratePNLInput) {
      generatePNL(record: $record)
    }
  `;

  static GET_FISCAL_YEAR_LIST = gql`
    query getFiscalYearList {
      getFiscalYearList
    }
  `;

  static GET_MAPPING_BY_TYPE = gql`
    query getAccountingMappingByMapType($mapType: String) {
      getAccountingMappingByMapType(mapType: $mapType) {
        mapId
        glAccount
        controlledItem
      }
    }
  `;

static GET_PARTS_TICKET_JOURNAL_DETAIL_LIST = gql`
query getPartsTicketJournalDetailList($recordId: ID, $type: String) {
  getPartsTicketJournalDetailList(recordId: $recordId, type: $type) {
    data {
      entryId
      accountNumber
      debit
      credit
      controlNumber
      referenceNumber
      memo
      lotId
      lotName
      accountCOA {
        description
        isControlled
        controlledBy
      }
    }
  }
}
`;

static POST_PARTS_TICKET_JOURNAL_PROCESS = gql`
  mutation postPartsTicketJournalProcess($record: JournalProcessInput!) {
    postPartsTicketJournalProcess(record: $record) {
      success
      isLockedDate
      lockedDate
    }
  }
  `;

static REVERT_PARTS_TICKET_JOURNAL_PROCESS = gql`
  mutation revertPartsTicketJournalProcess(
  $processRecordId: Int!
  $journalType: String!
  $postDate: Date!
  ) {
  revertPartsTicketJournalProcess(
    processRecordId: $processRecordId
    journalType: $journalType
    postDate: $postDate
  ) {
    success
    isLockedDate
    lockedDate
  }
}`;

static GET_PARTS_TICKET_JOURNAL_DETAIL_LIST_TO_POST_AUTOMATIC = gql`
  query getPartsTicketJournalDetailListToPostAutomatic($recordId: ID, $type: String) {
    getPartsTicketJournalDetailListToPostAutomatic(recordId: $recordId, type: $type) {
      data {
        entryId
        accountNumber
        debit
        credit
        controlNumber
        referenceNumber
        memo
        lotId
        lotName
        accountCOA {
          description
          isControlled
          controlledBy
        }
      }
    }
  }
  `;
}

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DealUtils from 'utils/DealUtils';
import NumberUtils from 'lib/NumberUtils';
import { modules } from 'utils/enum/modules';
import If from 'components/widgets/conditional/If';
import VehicleImage from 'components/widgets/VehicleImage';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, Button } from '@material-ui/core';
import { DealSection, DealTabsTitle } from 'utils/enum/DealEnum';
import VehicleWidgetStyles from 'styles/widgets/VehicleWidgetStyles';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

const styles = (theme) => VehicleWidgetStyles.style(theme);

class VehicleCard extends Component {
    onEditMiles = () => {
        const { props: { openEditMilesDialog, onChangeEditingMode } } = this;

        openEditMilesDialog();
        onChangeEditingMode(true, DealSection.SOLD_VEHICLE, DealTabsTitle.VEHICLE);
    }

    render() {
        const { props } = this;
        const {
            classes,
            labelUnderVehicleImage,
            numberUnderVehicleimage,
            vehiclePicture,
            frontRow,
            secondRow,
            thirdRow,
            fourthRow,
            editingMode,
            isTradeIn,
            clientId,
            isNotPosted,
            navigateToDetail,
        } = props;

        return (
            <div className={classes.root}>
                <div className={classes.vehicleImageStock}>
                    <div className={`${classes.column} ${classes.vehicleImage}`}>
                        <VehicleImage image={vehiclePicture} />
                    </div>
                    {numberUnderVehicleimage && (
                        <Typography variant="body1" className={classes.itemStock}>
                            {`${labelUnderVehicleImage}:`}
                            <If condition={!navigateToDetail}>
                                <span className={classes.numberBold}>{numberUnderVehicleimage}</span>
                            </If>
                            <If condition={navigateToDetail}>
                                <a href={`/${modules.INVENTORY}/${numberUnderVehicleimage}`} target="_blank" rel="noopener noreferrer">
                                    <span className={classes.numberBold}>{numberUnderVehicleimage}</span>
                                </a>
                            </If>
                        </Typography>
                    )}
                </div>
                <div className={`${classes.column} ${classes.vehicleDetail} ${classes.vehicleColumn}`}>
                    <div className={clsx(classes.nameCustomer, 'full-width-date-picker')}>
                        <Typography variant="body1" className={classes.itemStyle}>{frontRow}</Typography>
                        <Tooltip title={secondRow} classes={{ tooltipPlacementBottom: classes.tooltip }}>
                            <Typography variant="body1" className={classes.itemStyle} noWrap>{secondRow}</Typography>
                        </Tooltip>
                        <Typography variant="body1" className={classes.itemStyle}>{`VIN ${thirdRow}`}</Typography>
                        <div>
                            <div display="flex" className="with-icon-right">
                                <Typography variant="body1" className={clsx(classes.itemStyle, 'with-icon-right')}>
                                    Miles:
                                    <span className={classes.numberBoldBlack}>{NumberUtils.applyThousandsFormat(fourthRow)}</span>
                                </Typography>
                                {DealUtils.clientIdIsWeb(clientId) && isNotPosted && !isTradeIn && (
                                    <Button
                                        size="small"
                                        disabled={editingMode.isEditing && editingMode.sectionName !== DealSection.SOLD_VEHICLE}
                                        startIcon={<BorderColorOutlinedIcon />}
                                        className={clsx(classes.editMiles, 'show-hidden-item')}
                                        onClick={this.onEditMiles}
                                    >
                                        Edit Miles
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VehicleCard.propTypes = {
    frontRow: PropTypes.string,
    thirdRow: PropTypes.string,
    secondRow: PropTypes.string,
    fourthRow: PropTypes.number,
    vehiclePicture: PropTypes.string,
    numberUnderVehicleimage: PropTypes.number,
    labelUnderVehicleImage: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    openEditMilesDialog: PropTypes.func,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }),
    onChangeEditingMode: PropTypes.func,
    isTradeIn: PropTypes.bool,
    clientId: PropTypes.string,
    isNotPosted: PropTypes.bool,
    navigateToDetail: PropTypes.bool,
};

VehicleCard.defaultProps = {
    thirdRow: '',
    frontRow: '',
    secondRow: '',
    fourthRow: null,
    vehiclePicture: '',
    numberUnderVehicleimage: null,
    openEditMilesDialog: () => { },
    editingMode: {
        isEditing: false,
        sectionName: '',
    },
    onChangeEditingMode: () => {},
    isTradeIn: true,
    clientId: '',
    isNotPosted: true,
    navigateToDetail: false,
};

export default withStyles(styles)(VehicleCard);

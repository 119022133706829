import { gql } from '@apollo/client';

export default class NotificationSubscription {
    static NOTIFICATION_UPDATED = gql`
        subscription notificationUpdated($type: String!) {
            notificationUpdated(type: $type) {
                type
                notification {
                    data
                    type
                    createdOn
                    notificationId
                    processRecordId
                    userNotification {
                        read
                    }
                }
            }
        }
    `;

    static RECEIVED_NOTIFICATION = gql`
        subscription receivedNotification($type: String!) {
            receivedNotification(type: $type) {
                data
                type
                createdOn
                notificationId
                processRecordId
            }
        }
    `;

    static OPPORTUNITY_NOTIFICATION_UPDATED = gql`
        subscription opportunityNotificationUpdated {
            opportunityNotificationUpdated {
                opportunityId
                emailUnread
                smsUnread
            }
        }
    `;
}

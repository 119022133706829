import React, { useContext, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { useQuery, useLazyQuery } from '@apollo/client';
import DatePicker from 'react-datepicker';
import { FetchPolicy } from 'utils/enum/Core';
import { useTheme } from '@material-ui/core/styles';
import DateUtils from 'lib/DateUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    makeStyles,
    Container,
    Button,
} from '@material-ui/core';
import { Form, Row } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import ButtonStyles from 'styles/theme/Button';
import { CachedIcon } from 'components/icons';
import ModalUtils from 'utils/ModalUtils';
import UserContext from 'components/context/UserContext';
import ReportHeader from 'components/reports/ReportHeader';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import LotQuery from 'services/graphQL/query/LotQuery';
import ReportQuery from 'services/graphQL/query/ReportQuery';
import printJS from 'print-js';

const calculatedStartMonth = new Date(DateUtils.subtract(Date.now(), 1, 'months'));
calculatedStartMonth.setDate(1);

const INIT_STATE = {
    selectedLot: -1,
    lotsAvailable: [],
    selectedDate: calculatedStartMonth,
};

const ACTION_TYPES = {
    SET_AVAILABLE_LOTS: 'setAvailableLots',
    CHANGE_FIELD: 'changeField',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_AVAILABLE_LOTS:
        const { lots, selected } = action.payload;

        return {
            ...state,
            selectedLot: selected,
            lotsAvailable: lots,
        };
    case ACTION_TYPES.CHANGE_FIELD:
        const { name, value } = action.payload;

        return {
            ...state,
            [name]: value,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyle = makeStyles((theme) => ({
    container: {
        padding: '15px',
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    subLabel: {
        fontSize: '13px',
        marginLeft: '5px',
        marginRight: '10px',
        marginTop: '8px',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
        width: '150px',
    },
    field: {
        fontSize: '14px',
        backgroundColor: `${theme.palette.background.white} !important`,
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    goButton: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30px',
        },
    },
    box: {
        padding: '20px',
        '& > div': {
            paddingRight: '200px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                '& > div': {
                    margin: 0,
                },
            },
        },
    },
    ranOn: {
        marginTop: '30px',
        display: 'flex',
        width: '100%',
        '& > div': {
            marginRight: '20px',
        },
        '& > button': {
            height: '31px',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& > div': {
                marginBottom: '10px !important',
            },
            '& > button': {
                width: '100px',
                marginLeft: 0,
                marginRight: '20px',
            },
        },
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        width: '100%',
        lineHeight: 1.5,
        fontSize: '13px',
        marginLeft: '-10px',
        '& > a': {
            color: theme.palette.link,
        },
        '& > div': {
            textDecoration: 'underline',
        },
    },
    disabledLink: {
        pointerEvents: 'none',
        color: `${theme.palette.grey[600]} !important`,
    },
    headers: {
        fontSize: '13px',
        marginBottom: '15px',
        marginLeft: '-1px',
        textDecoration: 'none',
    },
    datePicker: {
        maxWidth: 100,
    },
}));

const InventoryTax = () => {
    const classes = { ...useStyle(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const {
        selectedLot,
        lotsAvailable,
        selectedDate,
    } = state;

    const {
        data: lotsData,
        loading: loadingLots,
        error: errorLoadingLots,
    } = useQuery(LotQuery.GET_LOTS, {
        variables: {
            filter: {
                active: true,
            },
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [pullReport, { loading }] = useLazyQuery(ReportQuery.GET_INVENTORY_TAX_REPORT, {
        onCompleted: (response) => {
            const data = response?.getInventoryTaxReport;

            if (data) {
                printJS({
                    printable: data,
                    type: 'pdf',
                    showModal: false,
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorLoadingLots) {
            ModalUtils.errorMessage(errorLoadingLots?.graphQLErrors);
        }

        if (!loadingLots) {
            const lots = lotsData?.lotList;
            if (lots) {
                dispatch({
                    type: ACTION_TYPES.SET_AVAILABLE_LOTS,
                    payload: {
                        selected: selectedLot,
                        lots: lots.map((lot) => ({
                            value: lot.lotId,
                            label: lot.lotName,
                        })),
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingLots, errorLoadingLots]);

    useEffect(() => {
        if (userInformation && lotsAvailable.length > 0) {
            const defaultLot = userInformation?.defaultLot;

            dispatch({
                type: ACTION_TYPES.SET_AVAILABLE_LOTS,
                payload: {
                    selected: lotsAvailable.find((lot) => lot.label?.toLowerCase() === defaultLot?.toLowerCase())?.value || -1,
                    lots: lotsAvailable,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation, lotsAvailable]);

    const onChange = (name, value) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_FIELD,
            payload: {
                name,
                value,
            },
        });
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            className={clsx({ [classes.noPadding]: isMobile },
                { [classes.container]: !isMobile })}
        >
            <ReportHeader
                titleReport="Inventory Tax"
            >
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Lot Name:</Form.Label>
                    <Select
                        nowrap
                        size="sm"
                        className={classes.input}
                        name="selectedLot"
                        onChange={(name, value) => onChange(name, value)}
                        value={selectedLot}
                        options={lotsAvailable}
                    />
                </Form.Group>
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Month / Year:</Form.Label>
                    <DatePicker
                        size="sm"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        dateFormat="MM/yyyy"
                        selected={selectedDate}
                        className={clsx('form-control form-control-sm', classes.datePicker)}
                        popperContainer={CalendarContainer}
                        maxDate={new Date()}
                        onChange={(date) => onChange('selectedDate', date)}
                    />
                </Form.Group>
                <Button
                    className={classes.goButton}
                    variant="outlined"
                    startIcon={<CachedIcon />}
                    disabled={selectedLot < 0 || !selectedDate || loading}
                    size="small"
                    onClick={() => pullReport({
                        variables: {
                            lotId: selectedLot,
                            reportDate: DateUtils.format(selectedDate),
                        },
                    })}
                >
                    Print Report
                </Button>
            </ReportHeader>
        </Container>
    );
};

export default InventoryTax;

import {
    PACKAGE,
} from 'utils/enum/MenuEnum';
import MenuHelper from 'utils/MenuHelper';

export default class MainMenuStyles {
    static main(theme = {}) {
        return ({
            parent: {
                width: '100%',
                height: '100%',
                position: 'relative',
                backgroundColor: theme.palette.background.catskillWhite,
                overflowX: 'auto',
                overflowY: 'auto',
                paddingBottom: '50px',
                '& > div.signature': {
                    width: '600px',
                    display: 'none',
                    flexDirection: 'column',
                    margin: '0 auto',
                    paddingTop: '60px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '& > div:nth-child(1)': {
                        display: 'flex',
                        '& > div': {
                            width: '49%',
                            fontSize: '14px',
                            fontWeight: 500,
                            textAlign: 'center',
                            borderTop: `1px solid ${theme.palette.border.ghost}`,
                            padding: '10px',
                        },
                        '& > div:nth-child(2)': {
                            marginLeft: '2%',
                        },
                    },
                    '& > div:nth-child(2)': {
                        display: 'flex',
                        fontSize: '13px',
                        color: theme.palette.secondaryInfo.main,
                        marginTop: '20px',
                        width: '51%',
                        margin: '0 auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontWeight: 500,
                        '& > img': {
                            width: '200px',
                        },
                    },
                },
            },
            loader: {
                position: 'absolute',
                fontWeight: 500,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 100,
                backgroundColor: theme.palette.background.white,
                '& > div': {
                    color: theme.palette.text.white,
                    backgroundColor: theme.palette.secondaryInfo.main,
                    padding: '5px',
                    fontSize: '14px',
                },
            },
            header: {
                backgroundColor: theme.palette.border.chambray,
                height: '68px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '20px',
                paddingRight: '20px',
                minWidth: '1400px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    height: 'auto',
                    alignItems: 'center',
                    padding: '5px',
                    minWidth: 'initial',
                },
                '& > div:nth-child(1)': {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        marginBottom: '10px',
                    },
                    '& > img': {
                        height: '70%',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            height: 'auto',
                        },
                    },
                },
                '& > div:nth-child(2)': {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        width: 'auto',
                    },
                    '& > button': {
                        padding: 0,
                        minWidth: '40px',
                        '& > span > span': {
                            margin: 0,
                            '& > svg': {
                                fontSize: '25px',
                                fill: theme.palette.background.white,
                            },
                        },
                    },
                },
            },
            info: {
                width: '90%',
                height: '100px',
                display: 'flex',
                paddingTop: '20px',
                margin: '0 auto',
                minWidth: '1400px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    flexDirection: 'column',
                    height: 'auto',
                    minWidth: 'initial',
                },
                '& > div': {
                    width: '33%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '100px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                    '& > div.row': {
                        marginBottom: '5px',
                        fontSize: '13px',
                        overflow: 'hidden',
                        '& > div:nth-child(1)': {
                            fontWeight: 500,
                            width: '120px',
                            marginRight: '15px',
                            textAlign: 'end',
                            whiteSpace: 'nowrap',
                        },
                        '& > div:nth-child(2)': {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px',
                            [theme.breakpoints.down('sm')]: {
                                maxWidth: '150px',
                            },
                        },
                    },
                    '& > div.base': {
                        fontSize: '16px',
                        fontWeight: 500,
                    },
                },
            },
            packages: {
                display: 'flex',
                width: '100%',
                padding: '10px',
                justifyContent: 'space-between',
                minWidth: '1400px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    minWidth: 'initial',
                },
                '& > div': {
                    width: '32%',
                    padding: '10px',
                    border: `1px solid ${theme.palette.border.ghost}`,
                    position: 'relative',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginBottom: '20px',
                    },
                    '& > div.footer': {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        backgroundColor: `rgba(${theme.palette.rgb.black}, 0.05)`,
                        width: '100%',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        fontWeight: 500,
                        fontSize: '13px',
                        height: '100px',
                        overflow: 'hidden',
                        [theme.breakpoints.down('sm')]: {
                            position: 'initial',
                            bottom: 'initial',
                            left: 'initial',
                        },
                    },
                    '& > div.acceptedFooterWrapper > div:nth-child(1)': {
                        width: '90%',
                        margin: '0 auto',
                        display: 'flex',
                        fontSize: '13px',
                        marginTop: '16px',
                        alignItems: 'center',
                        fontWeight: 500,
                        marginBottom: '11px',
                        justifyContent: 'space-between',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            '& > div:nth-child(1)': {
                                width: 'initial !important',
                            },
                        },
                        '& > div:nth-child(1)': {
                            width: '150px',
                            textAlign: 'right',
                        },
                    },
                    '& > div.acceptedFooterWrapper > div:nth-child(2)': {
                        width: '90%',
                        margin: '0 auto',
                        display: 'flex',
                        fontSize: '13px',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        fontWeight: 500,
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            '& > div:nth-child(1)': {
                                width: 'initial !important',
                            },
                        },
                        '& > div:nth-child(1)': {
                            width: '150px',
                            textAlign: 'right',
                        },
                        '& > div:nth-child(2)': {
                            width: '200px',
                            borderBottom: `1px solid ${theme.palette.background.black}`,
                        },
                    },
                    '& > div.rejectedFooterWrapper > div:nth-child(1)': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '90%',
                        margin: '0 auto',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '11px',
                        marginTop: '10px',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            '& > div:nth-child(1)': {
                                width: 'initial !important',
                            },
                        },
                        '& > div:nth-child(1)': {
                            width: '150px',
                            textAlign: 'right',
                        },
                    },
                    '& > div.rejectedFooterWrapper > div:nth-child(2)': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '90%',
                        margin: '0 auto',
                        fontSize: '13px',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            '& > div:nth-child(1)': {
                                width: 'initial !important',
                            },
                        },
                        '& > div:nth-child(1)': {
                            width: '150px',
                            textAlign: 'right',
                        },
                    },
                    '& > div.disclosureFooterWrapper': {
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '30px',
                        },
                    },
                    '& > div.disclosureFooterWrapper > div': {
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                        alignItems: 'baseline',
                        marginTop: '5px',
                        '& > div:nth-child(1), & > div:nth-child(3)': {
                            marginRight: '20px',
                        },
                        '& > div:nth-child(2), & > div:nth-child(4)': {
                            width: '120px',
                            borderBottom: `1px solid ${theme.palette.background.black}`,
                            marginRight: '20px',
                            [theme.breakpoints.down('sm')]: {
                                width: '70px',
                            },
                        },
                    },
                    '& > div.title': {
                        border: `1px solid ${theme.palette.border.ghost}`,
                        padding: '5px',
                        textAlign: 'center',
                        color: theme.palette.text.white,
                    },
                    '& > div.disclosureWrapper': {
                        padding: '5px',
                        fontSize: '13px',
                        paddingBottom: '95px',
                    },
                    '& > div.acceptedProducts': {
                        backgroundColor: theme.palette.background.fern,
                    },
                    '& > div.rejectedProducts': {
                        backgroundColor: theme.palette.background.red,
                    },
                    '& > div.disclosure': {
                        backgroundColor: theme.palette.background.bronze,
                    },
                    '& > div.products': {
                        marginTop: '10px',
                        padding: '5px',
                        overflowX: 'hidden',
                        paddingBottom: '95px',
                        '& > div.product:not(:last-child)': {
                            borderBottom: `1px solid ${theme.palette.border.ghost}`,
                        },
                        '& > div.product': {
                            marginBottom: '10px',
                            display: 'flex',
                            '& > div:nth-child(1)': {
                                width: '80%',
                                paddingBottom: '10px',
                                [theme.breakpoints.down('sm')]: {
                                    width: '70%',
                                },
                            },
                            '& > div:nth-child(2)': {
                                width: '20%',
                                display: 'flex',
                                flexDirection: 'column',
                                [theme.breakpoints.down('sm')]: {
                                    width: '30%',
                                },
                            },
                            '& > div > div.disabled': {
                                color: `${theme.palette.text.secondary} !important`,
                            },
                            '& > div > div.name': {
                                fontSize: '13px',
                                color: theme.palette.secondaryInfo.main,
                                textTransform: 'uppercase',
                                overflow: 'hidden',
                                fontWeight: 500,
                                '& > span': {
                                    padding: 0,
                                    '& svg': {
                                        width: '16px',
                                        height: '16px',
                                    },
                                },
                            },
                            '& > div > div.term, & > div > div.extra': {
                                fontWeight: 500,
                                fontSize: '11px',
                            },
                            '& > div > div.description': {
                                fontSize: '12px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                whiteSpace: 'break-spaces',
                            },
                            '& > div > div.points': {
                                fontSize: '12px',
                                marginLeft: '5px',
                                listStylePosition: 'inside',
                            },
                            '& > div > div.pricing': {
                                textAlign: 'right',
                                fontWeight: 500,
                                fontSize: '11px',
                                height: '50%',
                            },
                            '& > div > div.productActions': {
                                display: 'flex',
                                height: '50%',
                                alignItems: 'end',
                                justifyContent: 'flex-end',
                                paddingBottom: '10px',
                                '& > button.contractInPlace svg': {
                                    fill: MenuHelper.getPackageColor(PACKAGE.PREMIUM),
                                },
                                '&  button': {
                                    padding: 0,
                                    minWidth: 'initial',
                                    '& > span > span': {
                                        margin: 0,
                                    },
                                },
                                '& > button': {
                                    '& svg': {
                                        fill: theme.palette.text.secondary,
                                    },
                                },
                                '& > span > button': {
                                    marginLeft: '10px',
                                    '& svg': {
                                        fill: theme.palette.text.anzac,
                                    },
                                },
                            },
                        },
                    },
                    '& > div.packageSelected': {
                        '& > div': {
                            color: theme.palette.text.white,
                        },
                        '& > button:nth-child(3)': {
                            border: `1px solid ${theme.palette.text.white}`,
                        },
                    },
                    '& > div.summary': {
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '13px',
                        paddingTop: '10px',
                        paddingBottom: '15px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        borderTop: `1px solid ${theme.palette.border.ghost}`,
                        '& > div:nth-child(1)': {
                            fontWeight: 500,
                            fontSize: '16px',
                            marginBottom: '5px',
                        },
                        '& > button:nth-child(3)': {
                            color: theme.palette.text.white,
                            marginTop: '10px',
                            width: '80%',
                            border: `1px solid ${theme.palette.border.black}`,
                        },
                    },
                },
            },
            commands: {
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                paddingRight: '10px',
                minWidth: '1400px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    minWidth: 'initial',
                },
                '& > button': {
                    marginBottom: '5px',
                    minWidth: 'initial',
                    width: '150px',
                    fontSize: '13px',
                },
            },
            trimSelector: {
                fontSize: '14px',
                '& > div': {
                    marginBottom: '5px',
                },
            },
            surchargesWrapper: {
                display: 'flex',
                flexDirection: 'column',
                fontSize: '12px',
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '200px',
                '& > span': {
                    fontWeight: 500,
                    marginBottom: '10px',
                    fontSize: '14px',
                },
                '& > div.booleanComponent': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px',
                    '& > span': {
                        padding: 0,
                        marginLeft: '5px',
                    },
                },
                '& > div:nth-child(even)': {
                    backgroundColor: theme.palette.background.whitesmoke,
                },
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '5px',
                    paddingTop: '5px',
                    '& > span': {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        marginBottom: '5px',
                    },
                    '& > div, & > input': {
                        width: '200px',
                    },
                },
            },
        });
    }
}

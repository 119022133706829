export default class JournalStyles {
    static journalDistributionStyles(theme = {}) {
        return ({
            rootContainer: {
                flexDirection: 'column',
                overflow: 'auto',
                minHeight: '80vh',
            },
            rootContainerCustomHeight: {
                minHeight: '72vh',
            },
            containerHeader: {
                display: 'flex',
                flexWrap: 'wrap',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            buttonSpacing: {
                display: 'flex',
                flexWrap: 'wrap',
                '& > *': {
                    margin: theme.spacing(0.3),
                },
                textAlign: 'center',
            },
            deleteButton: {
                color: theme.palette.error.main,
            },
            table: {
                maxHeight: 'calc(100vh - 308px)',
                minHeight: '450px',
                '& .rt-td': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
            InputGroupPrepend: {
                marginRight: '-4px',
            },
            inputGroupSM: {
                lineHeight: '0.5',
                fontSize: '12px',
            },
            splitAmountGroup: {
                marginLeft: theme.spacing(0.3),
                marginRight: theme.spacing(0.3),
            },
            alert: {
                margin: theme.spacing(3),
            },
            columnStyle: {
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
            toolbar: {
                padding: '10px',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                margin: '0 0 10px;',
                backgroundColor: theme.palette.background.selago,
            },
            buttonGreen: {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.text.white,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                },
            },
            tableReadMode: {
                maxHeight: 'calc(100vh - 260px)',
                minHeight: '450px',
            },
            centerDiv: {
                textAlign: 'center',
                marginTop: 15,
            },
            width160: {
                width: '160px',
            },
            widthFull: {
                width: '100%',
            },
            width55Percentage: {
                width: '55%',
            },
            footerStyle: {
                fontSize: 14,
                fontWeight: 'bold',
            },
            tableGeneralJournal: {
                maxHeight: 'calc(100vh - 260px)',
                '& .rt-td': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
            dangerFooter: {
                backgroundColor: theme.palette.background.danger,
                color: theme.palette.text.white,
            },
            hide: {
                display: 'none',
            },
            actionButtonSize: {
                fontSize: '1.2rem',
                cursor: 'pointer',
            },
            buttonWrapper: {
                padding: '6px 3px',
            },
            appBarMove: {
                position: 'relative',
                cursor: 'move',
            },
            title: {
                marginRight: theme.spacing(5),
                flex: 1,
                color: theme.palette.text.white,
            },
            centerItems: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 0,
            },
            noPaddingSides: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        });
    }
}

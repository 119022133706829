import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Tooltip,
} from '@material-ui/core';
import { orderBy } from 'lodash';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery } from '@apollo/client';
import { GPS_STATUS, GPS_COMMAND_URL, GPS_COMMAND } from 'utils/enum/GPSEnum';
import GpsHelper from 'utils/GpsHelper';
import GpsQuery from 'services/graphQL/query/gps/GpsQuery';
import VirtualTable from 'components/widgets/VirtualTable';

// Icons
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: '15px',
        height: '65vh',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '900px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'center',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    commandCell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    failed: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            fill: theme.palette.text.anzac,
            width: '20px',
            height: '20px',
            marginLeft: '5px',
        },
    },
}));

const GpsCommandHistory = ({
    stockNumber,
    gpsCompany,
    openCommandResultDialog,
}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        history: [],
    });

    const {
        history,
    } = state;

    const {
        data: requestsData,
        loading: loadingRequests,
        error: errorLoadingRequests,
    } = useQuery(GpsQuery.GET_GPS_REQUESTS_LOG, {
        variables: {
            stockNumber,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    useEffect(() => {
        if (errorLoadingRequests) {
            ModalUtils.errorMessage(errorLoadingRequests?.graphQLErrors);
            return;
        }

        if (!loadingRequests && requestsData) {
            const { getGpsRequestsLog } = requestsData;
            if (ArrayUtils.isNotEmpty(getGpsRequestsLog)) {
                setState((previousState) => ({
                    ...previousState,
                    history: orderBy(
                        getGpsRequestsLog,
                        ['requestdate'],
                        ['desc'],
                    ),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRequests, errorLoadingRequests]);

    const getEncoreMode = (request) => {
        // eslint-disable-next-line camelcase
        const { encore_mode } = JSON.parse(request.body ?? '{}');
        return `Set ${StringUtils.toPascalCase(encore_mode)} Mode`;
    };

    const getCommand = (request) => {
        let {
            status,
            tone,
        } = request;

        const {
            resource,
        } = request;

        const body = JSON.parse(request.body ?? '{}');
        if (body?.status) status = body.status;
        if (body?.tone) tone = body.tone;

        const requestEnable = status === GPS_STATUS.ENABLE;
        const command = GpsHelper
            .getCommandsLabelByUrl()
            .find((x) => resource.indexOf(x.url) > -1 && (x.enable == null || x.enable === requestEnable));

        if (command) return command.label.replace('~number~', tone);
        if (!command && resource.includes(GPS_COMMAND_URL.SET_ENCORE_MODE)) return getEncoreMode(request);
        return null;
    };

    const getBatteryInfo = (response) => {
        const { data } = JSON.parse(response.body ?? '{}');
        const {
            encoreInternalBattery,
            encorePowerMode,
            message,
        } = data ?? {};

        return encoreInternalBattery
            ? `${encoreInternalBattery}% / ${encorePowerMode}`
            : message;
    };

    const getResult = (request, response) => {
        const { resource } = request;
        const { data, errors } = JSON.parse(response.body ?? '{}');

        if (ArrayUtils.isNotEmpty(errors)) {
            const { code, message } = errors[0];

            return (
                <div className={classes.failed}>
                    <span>Failed</span>
                    <Tooltip
                        title={GpsHelper.getErrorMessageByCode(code, message, gpsCompany)}
                        placement="top-end"
                    >
                        <WarningOutlinedIcon />
                    </Tooltip>
                </div>
            );
        }

        if (resource.includes(GPS_COMMAND_URL.GET_COMMAND_HISTORY)) {
            return (
                <a
                    href="/"
                    onClick={(event) => {
                        event.preventDefault();

                        const { commandHistory } = data ?? {};
                        if (!commandHistory) return;
                        openCommandResultDialog(
                            GPS_COMMAND.REQUEST_COMMAND_HISTORY,
                            { data: { commandHistory } },
                        );
                    }}
                >
                    View
                </a>
            );
        }

        if (resource.includes(GPS_COMMAND_URL.GET_LOCALTION_HISTORY)) {
            return (
                <a
                    href="/"
                    onClick={(event) => {
                        event.preventDefault();

                        const { locationHistory } = data ?? {};
                        if (!locationHistory) return;
                        openCommandResultDialog(
                            GPS_COMMAND.REQUEST_LOCATION_HISTORY,
                            { data: { locationHistory } },
                        );
                    }}
                >
                    View
                </a>
            );
        }

        if (resource.includes(GPS_COMMAND_URL.GET_LOCATION)) {
            return (
                <a
                    href="/"
                    onClick={(event) => {
                        event.preventDefault();

                        const { location } = data ?? {};
                        if (!location) return;
                        openCommandResultDialog(
                            GPS_COMMAND.REQUEST_LOCATION,
                            { data: { location } },
                        );
                    }}
                >
                    View
                </a>
            );
        }

        if (resource.includes(GPS_COMMAND_URL.GET_ENCORE_BATTERY_NOTIFICATION)) return (<span>{getBatteryInfo(response)}</span>);
        return 'Success';
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Date',
            dataKey: 'requestdate',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        requestdate,
                    },
                } = cell;

                return (<span>{DateUtils.getFormattedDateInUserTimezone(requestdate, DateFormat.DATETIME_WITHOUT_SECONDS)}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Command/Request',
            dataKey: 'requestdate',
            width: 350,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        request,
                        response: {
                            body,
                        },
                    },
                } = cell;

                const location = JSON.parse(body ?? '{}')?.data?.location;
                return (
                    <div className={classes.commandCell}>
                        <div>{getCommand(request)}</div>
                        <div>{location ? location.address : ''}</div>
                    </div>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Results',
            dataKey: 'response',
            width: 150,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        request,
                        response,
                    },
                } = cell;

                return getResult(request, response);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'User',
            dataKey: 'username',
            width: 150,
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Source',
            dataKey: 'restClient',
            width: 150,
        },
    ];

    const parentWidth = document.getElementById('tableContainer')?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 10;
    return (
        <div id="tableContainer" className={classes.tableContainer}>
            {parentWidth > 0 && (
                <VirtualTable
                    loading={loadingRequests}
                    rowHeight={45}
                    totalRecords={history.length}
                    data={history}
                    columns={columns}
                    width={tableWidth}
                />
            )}
        </div>
    );
};

GpsCommandHistory.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    gpsCompany: PropTypes.string.isRequired,
    openCommandResultDialog: PropTypes.func.isRequired,
};

export default GpsCommandHistory;

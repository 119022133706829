import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';

import Select from 'components/widgets/Select';

// HTTP
import { useQuery, useMutation } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import VehicleLocationStyle from 'styles/widgets/inventory/VehicleLocationStyle';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(VehicleLocationStyle.location());

const VehicleLocation = (props) => {
    const { stockNumber, vehicleLocation } = props;

    const classes = useStyles();
    const { data, loading, error } = useQuery(InventoryQuery.VEHICLE_LOCATION_LIST);
    const [updateLocation, { loading: saving }] = useMutation(InventoryMutation.UPDATE_VEHICLE_LOCATION);

    let options = [];

    if (error) {
        ModalUtils.errorMessage(error?.graphQLErrors);
    }
    if (!isEmpty(data) && Array.isArray(data.vehicleLocationList)) {
        const list = map(data.vehicleLocationList, 'location');

        if (!list.includes(vehicleLocation)) {
            list.push(vehicleLocation);
        }

        options = list.map((item) => (
            {
                value: item,
                label: item,
            }
        ));
    }

    const onChange = async (value) => {
        if (vehicleLocation !== value) {
            try {
                const variables = {
                    stockNumber,
                    location: value,
                };
                const response = await updateLocation({ variables });

                if (response?.data?.updateVehicleLocation) {
                    props.onChange(value);

                    ModalUtils.successMessage(null, 'Vehicle location updated successfully!');
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Location'));
                }
            } catch (ex) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle Location'));
            }
        }
    };

    return (
        <div className={classes.main}>
            <Select
                className={classes.select}
                name="vehicleLocation"
                onChange={(name, value) => onChange(value)}
                value={vehicleLocation}
                options={options}
                placeholder="Location"
                loading={loading || saving}
                nowrap
            />
        </div>
    );
};

VehicleLocation.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    vehicleLocation: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

VehicleLocation.defaultProps = {
    vehicleLocation: '',
};

export default VehicleLocation;

import React, { useContext, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import DatePicker from 'react-datepicker';
import { FetchPolicy } from 'utils/enum/Core';
import { useTheme } from '@material-ui/core/styles';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    makeStyles,
    Container,
    Button,
    Grid,
    Tooltip,
} from '@material-ui/core';
import { Form, Row } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import ButtonStyles from 'styles/theme/Button';
import { CachedIcon } from 'components/icons';
import SALES_TAX_DEAL_TYPE from 'utils/enum/ReportsEnum';
import { SalesTaxQueries } from 'utils/enum/CustomQueriesEnum';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import UserContext from 'components/context/UserContext';
import ReportHeader from 'components/reports/ReportHeader';
import ReportContent from 'components/reports/ReportContent';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import LotQuery from 'services/graphQL/query/LotQuery';
import ReportQuery from 'services/graphQL/query/ReportQuery';
import ReportMutation from 'services/graphQL/mutate/ReportMutation';
import CustomQueries from 'services/graphQL/query/CustomQueries';

const calculatedStartMonth = new Date(DateUtils.subtract(new Date(), 1, 'months'));
const INIT_STATE = {
    selectedLot: -1,
    lotsAvailable: [],
    selectedDate: calculatedStartMonth,
    reportRanOn: new Date(),
    reportData: {},
    queries: [],
    dealType: SALES_TAX_DEAL_TYPE.REGULAR_DEALS,
    lastRunDateSaved: null,
};

const ACTION_TYPES = {
    SET_AVAILABLE_LOTS: 'setAvailableLots',
    CHANGE_FIELD: 'changeField',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_AVAILABLE_LOTS:
        const { lots, selected } = action.payload;

        return {
            ...state,
            selectedLot: selected,
            lotsAvailable: lots,
        };
    case ACTION_TYPES.CHANGE_FIELD:
        const { name, value } = action.payload;

        return {
            ...state,
            [name]: value,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyle = makeStyles((theme) => ({
    container: {
        padding: '15px',
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    subLabel: {
        fontSize: '13px',
        marginLeft: '5px',
        marginRight: '10px',
        marginTop: '8px',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
        width: '150px',
    },
    field: {
        fontSize: '14px',
        backgroundColor: `${theme.palette.background.white} !important`,
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    goButton: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30px',
        },
    },
    box: {
        padding: '20px',
        '& > div': {
            paddingRight: '200px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                '& > div': {
                    margin: 0,
                },
            },
        },
    },
    ranOn: {
        marginTop: '30px',
        display: 'flex',
        width: '100%',
        '& > div': {
            marginRight: '20px',
        },
        '& > button': {
            height: '31px',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            '& > div': {
                marginBottom: '10px !important',
            },
            '& > button': {
                width: '100px',
                marginLeft: 0,
                marginRight: '20px',
            },
        },
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        lineHeight: 1.5,
        fontSize: '13px',
        marginLeft: '-10px',
        paddingRight: '0px !important',
        '& > a': {
            color: theme.palette.link,
        },
        '& > div': {
            textDecoration: 'underline',
        },
    },
    disabledLink: {
        pointerEvents: 'none',
        color: `${theme.palette.grey[600]} !important`,
    },
    headers: {
        fontSize: '13px',
        marginBottom: '15px',
        marginLeft: '-1px',
        textDecoration: 'none',
    },
}));

const SalesTax = () => {
    const classes = { ...useStyle(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { userInformation } = useContext(UserContext);
    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const {
        selectedLot,
        lotsAvailable,
        selectedDate,
        reportRanOn,
        reportData,
        queries,
        dealType,
        lastRunDateSaved,
    } = state;

    const {
        data: lastRunData,
        loading: loadingLastRun,
        error: errorLoadingLastRun,
        refetch,
    } = useQuery(ReportQuery.GET_REPORT_LAST_RUN_DATE, {
        variables: {
            lotId: selectedLot,
            dealType,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: selectedLot === -1,
    });

    const {
        data: lotsData,
        loading: loadingLots,
        error: errorLoadingLots,
    } = useQuery(LotQuery.GET_LOTS, {
        variables: {
            filter: {
                active: true,
            },
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: queriesData,
        loading: loadingQueries,
        error: errorLoadingQueries,
    } = useQuery(CustomQueries.GET_ALL_SAVED_QUERIES, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [pullReport, { loading }] = useLazyQuery(ReportQuery.GET_SALES_TAX_REPORT, {
        onCompleted: (response) => {
            const data = response?.getSalesTaxReport;

            if (data) {
                dispatch({
                    type: ACTION_TYPES.CHANGE_FIELD,
                    payload: {
                        name: 'reportData',
                        value: data,
                    },
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);

            dispatch({
                type: ACTION_TYPES.CHANGE_FIELD,
                payload: {
                    name: 'reportData',
                    value: {},
                },
            });
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveLastRun, { loading: savingLastRun }] = useMutation(ReportMutation.LOG_SALES_TAX_REPORT_RUNS, {
        onCompleted: (response) => {
            if (response) {
                refetch();
                ModalUtils.successMessage(null, 'Date of last run saved successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingLastRun) {
            ModalUtils.errorMessage(errorLoadingLastRun?.graphQLErrors);
        }

        if (!loadingLastRun) {
            const lastRunDate = lastRunData?.getReportLastRunDate;

            dispatch({
                type: ACTION_TYPES.CHANGE_FIELD,
                payload: {
                    name: 'lastRunDateSaved',
                    value: lastRunDate,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingLastRun, errorLoadingLastRun]);

    useEffect(() => {
        if (errorLoadingLots) {
            ModalUtils.errorMessage(errorLoadingLots?.graphQLErrors);
        }

        if (!loadingLots) {
            const lots = lotsData?.lotList;
            if (lots) {
                dispatch({
                    type: ACTION_TYPES.SET_AVAILABLE_LOTS,
                    payload: {
                        selected: selectedLot,
                        lots: lots.map((lot) => ({
                            value: lot.lotId,
                            label: lot.lotName,
                        })),
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingLots, errorLoadingLots]);

    useEffect(() => {
        if (userInformation && lotsAvailable.length > 0) {
            const defaultLot = userInformation?.defaultLot;

            dispatch({
                type: ACTION_TYPES.SET_AVAILABLE_LOTS,
                payload: {
                    selected: lotsAvailable.find((lot) => lot.label?.toLowerCase() === defaultLot?.toLowerCase())?.value || -1,
                    lots: lotsAvailable,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation, lotsAvailable]);

    useEffect(() => {
        if (errorLoadingQueries) return;
        if (!loadingQueries) {
            const data = queriesData?.getAllSavedQueries;
            if (data) {
                dispatch({
                    type: ACTION_TYPES.CHANGE_FIELD,
                    payload: {
                        name: 'queries',
                        value: data,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingQueries, errorLoadingQueries]);

    const onChange = (name, value) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_FIELD,
            payload: {
                name,
                value,
            },
        });
    };

    const pullReportData = () => {
        const { month, year } = DateUtils.splitDate(selectedDate);
        if (!month || !year) return;

        pullReport({
            variables: {
                lotId: selectedLot,
                dealType,
                month,
                year,
            },
        });
    };

    const logLastRun = () => {
        const { month, year } = DateUtils.splitDate(selectedDate);
        const { lastDay } = DateUtils.getFirstLastDaysOfGivenMonth(month, year);
        if (!lastDay) return;

        saveLastRun({
            variables: {
                input: {
                    ranOn: DateUtils.getISOStringFromLocal(reportRanOn),
                    endDate: DateUtils.getISOStringFromLocal(`${month}/${lastDay}/${year}`),
                    lotId: selectedLot,
                    dealType,
                },
            },
        });
    };

    const getLinks = () => {
        const isRegularDeals = dealType === SALES_TAX_DEAL_TYPE.REGULAR_DEALS;
        const values = isRegularDeals ? [
            SalesTaxQueries.SALES_TAX_DEALS,
            SalesTaxQueries.SALES_TAX__OUT_OF_STATE,
            SalesTaxQueries.SALES_TAX_REPAIR_ORDERS,
            SalesTaxQueries.SALES_TAX_DELETED_DEALS,
        ] : [
            SalesTaxQueries.LEASE_SALES_TAX_DEALS,
            SalesTaxQueries.LEASE_PAYMENT_SALES_TAX,
            SalesTaxQueries.SALES_TAX_DELETED_LEASE_DEALS,
        ];

        const { month, year } = DateUtils.splitDate(selectedDate);
        const { firstDay, lastDay } = DateUtils.getFirstLastDaysOfGivenMonth(month, year);
        if (!firstDay || !lastDay || lotsAvailable.length === 0) return null;

        const lotName = lotsAvailable.find((lot) => lot.value === selectedLot)?.label;
        const startDate = `${month}/${firstDay}/${year}`;
        const endDate = `${month}/${lastDay}/${year}`;
        const lastRun = DateUtils.formatUTC(lastRunDateSaved, DateFormat.DEFAULT_DATETIME);
        return values.map((value, index) => {
            const query = queries.find((q) => q.queryName?.toLowerCase() === value?.toLowerCase());

            return (
                <a
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                        query
                            ? `/queries?queryId=${query.savedQuerieId}&lotName=${lotName}&startDate=${startDate}&endDate=${endDate}&lastRun=${lastRun}`
                            : '#'
                    }
                    className={!query ? classes.disabledLink : ''}
                >
                    {value}
                </a>
            );
        });
    };

    const outputFormat = '0,0.00';
    return (
        <Container
            maxWidth={false}
            disableGutters
            className={clsx({ [classes.noPadding]: isMobile },
                { [classes.container]: !isMobile })}
        >
            <ReportHeader
                titleReport="Sales Tax"
            >
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Deal Type:</Form.Label>
                    <Select
                        nowrap
                        size="sm"
                        className={classes.input}
                        name="dealType"
                        onChange={(name, value) => onChange(name, value)}
                        value={dealType}
                        options={Object.values(SALES_TAX_DEAL_TYPE).map((type) => ({ label: type, value: type }))}
                    />
                </Form.Group>
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Lot Name:</Form.Label>
                    <Select
                        nowrap
                        size="sm"
                        className={classes.input}
                        name="selectedLot"
                        onChange={(name, value) => onChange(name, value)}
                        value={selectedLot}
                        options={lotsAvailable}
                    />
                </Form.Group>
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Month / Year:</Form.Label>
                    <DatePicker
                        size="sm"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        dateFormat="MM/yyyy"
                        selected={selectedDate}
                        className={clsx('form-control form-control-sm')}
                        popperContainer={CalendarContainer}
                        maxDate={new Date()}
                        onChange={(date) => onChange('selectedDate', date)}
                    />
                </Form.Group>
                <Button
                    className={classes.goButton}
                    variant="outlined"
                    startIcon={<CachedIcon />}
                    disabled={selectedLot < 0 || !selectedDate || loading || savingLastRun}
                    size="small"
                    onClick={pullReportData}
                >
                    Run Report
                </Button>
            </ReportHeader>
            <ReportContent loading={loading}>
                <Grid container className={classes.box}>
                    <div className={classes.headers}>
                        {`
                            Select the Lot Name and the Month which you would like to run the report for and click “Run Report”.
                            Once the report (which will be for the purpose of filing with the State) has been run then enter the date & time and save.
                            This will record the date & time when you run the report for the next State filing
                            so it can lookup deleted deals from that time to the next time you run it.
                        `}
                    </div>
                    <Grid item orientation="column" lg={4} xs={12}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label className={classes.subLabel}>1. Gross Sales:</Form.Label>
                            <Form.Control
                                readOnly
                                className={classes.field}
                                type="text"
                                value={NumberUtils.applyThousandsFormat(reportData.grossSales || 0, outputFormat)}
                            />
                            <Form.Label className={classes.subLabel}>2. Exempt Sales:</Form.Label>
                            <Form.Control
                                readOnly
                                className={classes.field}
                                type="text"
                                value={NumberUtils.applyThousandsFormat(reportData.exemptSales || 0, outputFormat)}
                            />
                            <Form.Label className={classes.subLabel}>3. Taxable Amount:</Form.Label>
                            <Form.Control
                                readOnly
                                className={classes.field}
                                type="text"
                                value={NumberUtils.applyThousandsFormat(reportData.taxableAmount || 0, outputFormat)}
                            />
                            <Form.Label className={classes.subLabel}>4. Tax Collected:</Form.Label>
                            <Form.Control
                                readOnly
                                className={classes.field}
                                type="text"
                                value={NumberUtils.applyThousandsFormat(reportData.taxCollected || 0, outputFormat)}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item orientation="column" lg={4} xs={12}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label className={classes.subLabel}>5. 15a:</Form.Label>
                            <Tooltip
                                title={(
                                    <div>
                                        <strong>15(a). Exempt Amount of Items Over $5,000</strong>
                                        <br />
                                        Enter the amount in excess of $5,000 on each single sale of taxable tangible personal property (reported on Line A)
                                        and the amount in excess of $5,000 for each single purchase for which sales tax and discretionary
                                        sales surtax is due (reported on Line B).Example: If you sold a single item for $7,000,
                                        include $2,000 (the amount over $5,000) on Line 15(a). Do NOT include exempt sales reported in Column 2.
                                    </div>
                                )}
                                placement="top"
                            >
                                <Form.Control
                                    readOnly
                                    className={classes.field}
                                    type="text"
                                    value={NumberUtils.applyThousandsFormat(reportData.amount15a || 0, outputFormat)}
                                />
                            </Tooltip>
                            <Form.Label className={classes.subLabel}>6. 15b:</Form.Label>
                            <Tooltip
                                title={(
                                    <div>
                                        <strong>15(b). Other Taxable Amounts NOT Subject to Surtax</strong>
                                        <br />
                                        Enter the amount of taxable sales or purchases included in Line A, Column 3, that are not subject to discretionary
                                        sales surtax. This includes services and tangible personal property delivered into a non-surtax county subject to
                                        sales tax, but not subject to discretionary sales surtax. Do NOT include exempt sales reported in Line A, Column 2.
                                    </div>
                                )}
                                placement="top"
                            >
                                <Form.Control
                                    readOnly
                                    className={classes.field}
                                    type="text"
                                    value={NumberUtils.applyThousandsFormat(reportData.amount15b || 0, outputFormat)}
                                />
                            </Tooltip>
                            <Form.Label className={classes.subLabel}>7. 15c:</Form.Label>
                            <Tooltip
                                title={(
                                    <div>
                                        <strong>15(c). Amounts Subject to Surtax at a Rate Different Than Your County Surtax Rate</strong>
                                        <br />
                                        You must report the total amount of taxable sales for which you collected discretionary sales surtax at a rate
                                        different than the rate of the county in which you are located on Line 15(c). Enter the taxable amounts from
                                        Line A, Column 3, for which you collected discretionary sales surtax at a rate different than the county in which you
                                        are located. This amount would consist of taxable sales where you delivered the merchandise into a county with a
                                        different discretionary sales surtax rate. Example: A business located in a county with a 1% discretionary
                                        sales surtax rate sells a single taxable item for $3,000 and delivers the merchandise into a county with a 1.5%
                                        discretionary sales surtax rate. The discretionary sales surtax is to be collected at 1.5%. The business will report
                                        the $3,000 on Line 15(c), since this is the taxable amount that was subject to a different county discretionary
                                        sales surtax rate. The business will report the surtax collected at 1.5% on Line 15(d).
                                    </div>
                                )}
                                placement="top"
                            >
                                <Form.Control
                                    readOnly
                                    className={classes.field}
                                    type="text"
                                    value={NumberUtils.applyThousandsFormat(reportData.amount15c || 0, outputFormat)}
                                />
                            </Tooltip>
                            <Form.Label className={classes.subLabel}>8. 15d:</Form.Label>
                            <Tooltip
                                title={(
                                    <div>
                                        <strong>15(d). Total Amount of Discretionary Sales Surtax Due</strong>
                                        <br />
                                        Enter the total amount of discretionary sales surtax due on Line 15(d). Do not include state sales tax in this amount.
                                    </div>
                                )}
                                placement="top"
                            >
                                <Form.Control
                                    readOnly
                                    className={classes.field}
                                    type="text"
                                    value={NumberUtils.applyThousandsFormat(reportData.amount15d || 0, outputFormat)}
                                />
                            </Tooltip>
                        </Form.Group>
                    </Grid>
                    <Grid item orientation="column" lg={4} xs={12}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label className={classes.subLabel}>9. Deleted Deals:</Form.Label>
                            <Form.Control
                                readOnly
                                className={classes.field}
                                type="text"
                                value={NumberUtils.applyThousandsFormat(reportData.deletedDealsAmount || 0, outputFormat)}
                            />
                        </Form.Group>
                    </Grid>
                    <div className={classes.ranOn}>
                        <Form.Group as={Row} className={classes.group}>
                            <Form.Label className={classes.subLabel}>Tax Report ran on:</Form.Label>
                            <DatePicker
                                size="sm"
                                showTimeSelect
                                timeIntervals={1}
                                timeFormat={DateFormat.DEFAULT_TIME}
                                dateFormat={
                                    DateFormat
                                        .DEFAULT_DATETIME_WITHOUT_SECONDS
                                        .replace('DD', 'dd')
                                        .replace('YYYY', 'yyyy')
                                }
                                selected={reportRanOn}
                                className={clsx('form-control form-control-sm')}
                                popperPlacement={!isMobile ? 'left-start' : 'bottom-end'}
                                popperContainer={CalendarContainer}
                                onChange={(date) => onChange('reportRanOn', date)}
                            />
                        </Form.Group>
                        <Button
                            className={classes.goButton}
                            variant="outlined"
                            disabled={loading || savingLastRun}
                            size="small"
                            onClick={logLastRun}
                        >
                            Save
                        </Button>
                    </div>
                    <div className={classes.links}>
                        <div>DETAILED REPORTS</div>
                        <span className={classes.headers}>
                            {`
                                The links below would run an itemized report in the Query Panel based on the above information
                                (Permission required).
                            `}
                        </span>
                        {getLinks()}
                    </div>
                </Grid>
            </ReportContent>
        </Container>
    );
};

export default SalesTax;

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StringUtils from 'lib/StringUtils';
import { Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as IconUser } from 'assets/user.svg';
import { DealSection, DealTabsTitle } from 'utils/enum/DealEnum';
import VehicleWidgetStyles from 'styles/widgets/VehicleWidgetStyles';
import VehicleImage from 'components/widgets/VehicleImage';

const styles = (theme) => VehicleWidgetStyles.style(theme);

class VehicleWidget extends Component {
    onEditMiles = () => {
        const { props: { openEditMilesDialog, onChangeEditingMode } } = this;

        openEditMilesDialog();
        onChangeEditingMode(true, DealSection.SOLD_VEHICLE, DealTabsTitle.VEHICLE);
    }

    render() {
        const { props } = this;
        const {
            classes,
            labelUnderVehicleImage,
            numberUnderVehicleimage,
            image,
            bodyStyle,
            frontRow,
            secondRow,
            thirdRow,
            fourthRow,
            thirdRowSuffix,
        } = props;

        return (
            <div className={classes.root}>
                <div className={classes.imageStock}>
                    <div className={`${classes.column} ${classes.image}`}>
                        <VehicleImage
                            image={image}
                            bodyStyle={bodyStyle}
                        />
                    </div>
                    {numberUnderVehicleimage && (
                        <div className={classes.containerUnderVehicle}>
                            <Typography variant="body1" className={classes.titleStock}>
                                {`${labelUnderVehicleImage}:`}
                            </Typography>
                            <span className={classes.numberBold}>{numberUnderVehicleimage}</span>
                        </div>
                    )}
                </div>
                <div className={`${classes.column} ${classes.vehicleInfo}`}>
                    <div className={classes.nameCustomer}>
                        <Typography variant="body1" className={classes.textBuyer}>{frontRow}</Typography>
                        <div className={classes.cobuyerAlign}>
                            <Tooltip title={secondRow} classes={{ tooltipPlacementBottom: classes.tooltip }}>
                                <Typography noWrap variant="body1" className={classes.lineHeight16}>{secondRow}</Typography>
                            </Tooltip>
                            {!StringUtils.isEmpty(secondRow) && (
                                <div className={classes.iconContainer}>
                                    <IconUser className={classes.iconSpace} width="14px" height="15px" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.thirdRow}>
                        <Typography variant="body1" className={classes.textVehicleColor}>{thirdRow}</Typography>
                        <Typography variant="body1" className={clsx(classes.numberBold, classes.stockNumberMargin)}>{thirdRowSuffix}</Typography>
                    </div>
                    <Typography variant="body1" className={classes.textVehicleColor}>{fourthRow}</Typography>
                </div>
            </div>
        );
    }
}

VehicleWidget.propTypes = {
    frontRow: PropTypes.string,
    thirdRow: PropTypes.string,
    secondRow: PropTypes.string,
    fourthRow: PropTypes.string,
    image: PropTypes.string,
    bodyStyle: PropTypes.string,
    thirdRowSuffix: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    numberUnderVehicleimage: PropTypes.number,
    labelUnderVehicleImage: PropTypes.string.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    openEditMilesDialog: PropTypes.func,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }),
    onChangeEditingMode: PropTypes.func,
};

VehicleWidget.defaultProps = {
    thirdRow: '',
    frontRow: '',
    secondRow: '',
    fourthRow: '',
    image: '',
    bodyStyle: '',
    thirdRowSuffix: '',
    numberUnderVehicleimage: null,
    openEditMilesDialog: () => { },
    editingMode: {
        isEditing: false,
        sectionName: '',
    },
    onChangeEditingMode: () => {},
};

export default withStyles(styles)(VehicleWidget);

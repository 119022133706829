import { ZoneId, ZonedDateTime } from '@js-joda/core';
import '@js-joda/timezone';

export default class TimeZoneUtil {
    static getTimeZoneIds() {
        return ZoneId.getAvailableZoneIds();
    }

    static getTimeZoneOffSet(timeZoneId) {
        try {
            const offset = ZonedDateTime.now(ZoneId.of(timeZoneId)).offset().toString();
            let sanitizedOffset = offset.includes(':') ? offset : '00:00';
            sanitizedOffset = sanitizedOffset.substring(0, offset.indexOf(':'));
            return parseInt(sanitizedOffset, 10);
        } catch (error) {
            return 0;
        }
    }
}

import { gql } from '@apollo/client';

export default class GeneratedChecksQuery {
    static GET_PAYROLL_CHECK_LIST = gql`
        query getPayrollCheckList($payPeriod: String!) {
            getPayrollCheckList(payPeriod: $payPeriod) {
                checkCBID
                bankAccount
                checkNumber
                paidTo
                amount
                status
                type
                postedDate
                emailStatus
            }
        }
    `;

    static GET_LOCK_DATE = gql`
        query getAccountingDefaultSettings {
            getAccountingDefaultSettings {
                accountingLockDate
            }
        }   
    `;

    static GET_PAYROLL_CHECK = gql`
        query getPayrollCheck($cbId: Int!) {
            getPayrollCheck(cbId: $cbId) {
                check {
                    paidTo
                    bankAccount
                    lotId
                    lotName
                    memo
                    achCheckNumber
                    checkNumber
                    date
                    amount
                    accountDescription
                }
                lines {
                    accountNumber
                    debit
                    credit
                    controlNumber
                    memo
                }
            }
        }
    `;
}

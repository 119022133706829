import If from 'components/widgets/conditional/If';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import React, { useState } from 'react';
import Permission from 'utils/enum/Permissions';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { BorderColorOutlined as EditIcon } from '@material-ui/icons';
import VehicleOfInterest from 'components/modules/lead/create/VehicleOfInterest';
import InterestVehicleCard from 'components/modules/crm/widgets/InterestVehicleCard';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.white,
        padding: theme.spacing(1),
    },
    header: {
        marginBottom: theme.spacing(1.5),
        display: 'flex',
    },
    editButton: {
        marginLeft: 'auto',
    },
    titleLabel: {
        marginTop: theme.spacing(0.5),
    },
}));

const keyStore = new KeyStore();
const InterestVehicle = ({ record, crmId, loading }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const toggleEditModal = () => setIsOpen(!isOpen);
    const CRM_OPPORTUNITY_WRITE = keyStore.hasPermission(Permission.CRM_OPPORTUNITY_WRITE);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography
                    variant="h6"
                    color="primary"
                    className={classes.titleLabel}
                >
                    Vehicle of Interest
                </Typography>
                <If condition={CRM_OPPORTUNITY_WRITE}>
                    <Button
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={toggleEditModal}
                        disabled={loading}
                        className={classes.editButton}
                    >
                        Edit
                    </Button>
                </If>
            </div>
            <InterestVehicleCard
                record={record}
            />
            {isOpen && (
                <VehicleOfInterest
                    open={isOpen}
                    crmId={crmId}
                    record={record}
                    onClose={toggleEditModal}
                />
            )}
        </div>
    );
};

InterestVehicle.propTypes = {
    crmId: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default InterestVehicle;

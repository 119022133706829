import React, { useReducer, useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
    Tooltip,
} from '@material-ui/core';
import PDFUtils from 'utils/PDFUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import { Form } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import VirtualTable from 'components/widgets/VirtualTable';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import WarrantyTemplatesDialog from 'components/modules/inventory/create/dialogs/WarrantyTemplatesDialog';
import FormsDialog from 'components/modules/inventory/create/dialogs/FormsDialog';
import InputSearch from 'components/widgets/InputSearch';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ButtonStyles from 'styles/theme/Button';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';
import MapInventoryData from 'services/mapData/MapInventoryData';
import If from 'components/widgets/conditional/If';
import Loading from 'components/widgets/Loading';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import WindowStickersQuery from 'services/graphQL/query/WindowStickersQuery';
import WindowStickersMutation from 'services/graphQL/mutate/WindowStickersMutation';
import { WINDOW_STICKERS_CATEGORY, WARRANTY_VALUES } from 'utils/enum/WindowStickersEnum';
import StickersDialogReducer, { INITIAL_STATE, ACTION_TYPES } from 'components/modules/inventory/reducer/StickersDialogReducer';

const useStyles = makeStyles((theme) => InventoryListStyle.stickersDialog(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const StickersDialog = ({
    onClose,
    selectedLots,
    writePermission,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, dispatch] = useReducer(StickersDialogReducer, INITIAL_STATE);
    const {
        isPrinting,
        table,
        params,
        search,
        warranties,
        forms,
        selectedForms,
        showFormsDialog,
        showWarrantyTemplatesDialog,
    } = state;

    const selectedVehicles = table
        .records
        .filter((record) => record.selected === true);

    const parameters = { ...params, search };
    if (StringUtils.isEmpty(search)) {
        delete parameters.search;
    }

    const {
        data: vehiclesData,
        loading: vehiclesLoading,
        error: vehiclesError,
    } = useQuery(InventoryQuery.getPullInventorySchema([
        'stockNumber',
        'year',
        'make',
        'model',
        'trim',
        'miles',
    ]), {
        variables: {
            ...parameters,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: !params.lots || warranties.length === 0,
    });

    const {
        data: warrantiesData,
        loading: warrantiesLoading,
        error: warrantiesError,
        refetch: warrantiesRefetch,
    } = useQuery(WindowStickersQuery.GET_WARRANTIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: formsData,
        loading: formsLoading,
        error: formsError,
    } = useQuery(WindowStickersQuery.GET_FORMS_BY_DEALER, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                lots: selectedLots,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLots]);

    useEffect(() => {
        if (vehiclesError) {
            ModalUtils.errorMessage(vehiclesError?.graphQLErrors);
            return;
        }

        if (!vehiclesLoading && vehiclesData) {
            const vehicles = vehiclesData.pullInventory;
            const records = [];

            (vehicles ?? []).forEach((vehicle) => {
                const mapped = MapInventoryData.mapWindowStickerRecord(vehicle);
                records.push({
                    selected: false,
                    warranty: warranties.find((w) => w.name === WARRANTY_VALUES.AS_IS_FULL)?.id,
                    warranties,
                    ...mapped,
                });
            });

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: { records, keep: true },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesLoading, vehiclesError]);

    useEffect(() => {
        if (warrantiesError) {
            ModalUtils.errorMessage(warrantiesError?.graphQLErrors);
            return;
        }

        if (!warrantiesLoading) {
            const { getWarranties } = warrantiesData;

            dispatch({
                type: ACTION_TYPES.SET_WARRANTIES,
                value: getWarranties,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warrantiesLoading, warrantiesError]);

    useEffect(() => {
        if (formsError) {
            ModalUtils.errorMessage(formsError?.graphQLErrors);
            return;
        }

        if (!formsLoading) {
            const { getFormsByDealer } = formsData;

            dispatch({
                type: ACTION_TYPES.SET_FORMS,
                value: getFormsByDealer,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formsLoading, formsError]);

    const onSearch = (value) => {
        dispatch({
            type: ACTION_TYPES.SET_SEARCH,
            value: {
                search: value,
                selectedVehicles,
            },
        });
    };

    const onChangeIncludeSold = (event) => {
        const { target: { checked } } = event;
        dispatch({
            type: ACTION_TYPES.SET_ACTIVE,
            value: {
                checked,
            },
        });
    };

    const onFormPicked = (form, isChecked) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_FORMS,
            value: {
                form,
                isChecked,
            },
        });
    };

    const onVehiclePicked = (vehicle, isChecked) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_VEHICLE,
            value: {
                vehicle,
                isChecked,
            },
        });
    };

    const onWarrantyPicked = (vehicle, warranty) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_VEHICLE_WARRANTY,
            value: {
                vehicle,
                warranty,
            },
        });
    };

    const printForms = async () => {
        const maxAllowedFormsToPrint = 20;

        if (selectedVehicles.length > maxAllowedFormsToPrint) {
            ModalUtils.errorMessage(
                null,
                'You can only print stickers for 20 vehicles at a time. Please reduce the number of vehicles and try again.',
            );
            return;
        }

        try {
            dispatch({
                type: ACTION_TYPES.TOGGLE_PRINTING,
            });

            const input = [];
            selectedVehicles.forEach((vehicle) => {
                selectedForms.forEach((form) => {
                    input.push({
                        stockNumber: vehicle.stockNumber,
                        warrantyId: vehicle.warranty,
                        formName: form.name,
                    });
                });
            });

            const { data: printResult } = await client.mutate({
                mutation: WindowStickersMutation.PRINT_FORMS,
                variables: { input },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const signedURL = printResult?.printWindowStickers;
            if (signedURL) {
                PDFUtils.sendPDFToPrinter(signedURL);
                dispatch({
                    type: ACTION_TYPES.TOGGLE_PRINTING,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_PRINTING,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    };

    const toggleFormsDialog = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_FORMS_DIALOG,
        });
    };

    const toggleWarrantiesDialog = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_WARRANTIES_DIALOG,
        });
    };

    const onSaveInventoryForms = async (records) => {
        try {
            const input = [];
            records.forEach((form) => {
                input.push({
                    id: form.id,
                    name: form.name,
                });
            });

            await client.mutate({
                mutation: WindowStickersMutation.SAVE_FORMS_BY_DEALER,
                variables: { input },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            ModalUtils.successMessage(null, 'Forms updated successfully.');
            dispatch({
                type: ACTION_TYPES.REFRESH_FORMS,
                value: records,
            });
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const saveWarrantyTemplate = async (input) => {
        try {
            const isNew = StringUtils.isEmpty(input.id);
            await client.mutate({
                mutation: isNew
                    ? WindowStickersMutation.CREATE_WARRANTY : WindowStickersMutation.UPDATE_WARRANTY,
                variables: { input },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            ModalUtils.successMessage(null, 'Warranty updated successfully.');
            toggleWarrantiesDialog();

            if (isNew) {
                setTimeout(() => {
                    warrantiesRefetch();
                }, 1000);
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
            toggleWarrantiesDialog();
        }
    };

    const getTableColumns = () => [
        {
            headerClassName: classes.tableHeader,
            dataKey: 'selected',
            label: '',
            width: 50,
            cellRenderer: (cell) => {
                const { rowData: vehicle } = cell;

                return (
                    <Checkbox
                        color="primary"
                        value="selected"
                        style={{ padding: 0 }}
                        checked={vehicle.selected}
                        onChange={(e) => onVehiclePicked(vehicle, e.target.checked)}
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'stockNumber',
            label: 'Stock',
            width: 80,
            cellRenderer: (cell) => {
                const { cellData } = cell;

                return (
                    <span>{cellData}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'year',
            label: 'Year',
            width: 80,
            cellRenderer: (cell) => {
                const { cellData } = cell;

                return (
                    <span>{cellData}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'make',
            label: 'Make',
            width: 160,
            cellRenderer: (cell) => {
                const { cellData } = cell;

                return (
                    <span>{cellData}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'model',
            label: 'Model',
            width: 160,
            cellRenderer: (cell) => {
                const { cellData } = cell;

                return (
                    <span>{cellData}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'trim',
            label: 'Trim',
            width: 160,
            cellRenderer: (cell) => {
                const { cellData } = cell;

                return (
                    <span>{cellData}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Miles',
            dataKey: 'miles',
            width: 100,
            cellRenderer: (cell) => {
                const { cellData } = cell;
                return <span>{NumberUtils.applyThousandsFormat(cellData ?? 0)}</span>;
            },
        },
        {
            headerClassName: classes.tableHeader,
            dataKey: 'warranty',
            label: 'Warranty',
            width: 160,
            cellRenderer: (cell) => {
                const { rowData: vehicle } = cell;

                return (
                    <TextField
                        select
                        fullWidth
                        value={vehicle.warranty}
                        onChange={(e) => onWarrantyPicked(vehicle, e.target.value)}
                    >
                        <MenuItem key={-1} value={-1}>
                            None
                        </MenuItem>
                        {vehicle.warranties.map((warranty) => (
                            <MenuItem key={warranty.id} value={warranty.id}>
                                {warranty.name}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
    ];

    const isPrintButtonDisabled = isPrinting
            || selectedForms.length === 0
            || selectedVehicles.length === 0;

    const columnsInLargeSize = 3;
    const columnsInMediumSize = 4;
    const columnsInSmallSize = 5;

    const sortedForms = [...forms];
    sortedForms.sort((a, b) => a.name.localeCompare(b.name));
    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Choose forms to print"
                    onClose={onClose}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.content}>
                        <Grid container spacing={3}>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    lg={columnsInLargeSize}
                                    md={columnsInMediumSize}
                                    sm={columnsInSmallSize}
                                >
                                    <Button
                                        className={isPrintButtonDisabled
                                            ? classes.containedFlat : classes.containedSecondaryInfo}
                                        startIcon={<PrintIcon />}
                                        onClick={printForms}
                                        disabled={isPrintButtonDisabled}
                                    >
                                        Print
                                        {isPrinting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                                disableShrink
                                            />
                                        )}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    lg={12 - columnsInLargeSize}
                                    md={12 - columnsInMediumSize}
                                    sm={12 - columnsInSmallSize}
                                >
                                    <Grid container className={classes.searchContainer}>
                                        <div>
                                            <InputSearch
                                                customClasses={classes.inputSearch}
                                                placeholder="Enter text to search"
                                                executeWhenClearButton={onSearch}
                                                onSearch={onSearch}
                                            />
                                            <Tooltip
                                                title="Results limited to most recent 2000 results"
                                                placement="bottom"
                                            >
                                                <span>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Include sold vehicles"
                                                        className={classes.includeSold}
                                                        checked={!params.active}
                                                        onChange={onChangeIncludeSold}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <If condition={writePermission}>
                                            <Button
                                                className={classes.containedSecondaryInfo}
                                                startIcon={
                                                    <AssignmentIcon />
                                                }
                                                onClick={toggleWarrantiesDialog}
                                            >
                                                Warranty Templates
                                            </Button>
                                        </If>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                className={classes.downSection}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    lg={columnsInLargeSize}
                                    md={columnsInMediumSize}
                                    sm={12}
                                >
                                    <Grid container className={classes.EditFormRow}>
                                        <div className={classes.label}>
                                            Forms
                                        </div>
                                        <If condition={writePermission}>
                                            <Button
                                                className={classes.outlinedSecondaryInfo}
                                                size="small"
                                                onClick={toggleFormsDialog}
                                            >
                                                Edit
                                            </Button>
                                        </If>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.formsContainer}
                                    >
                                        <Paper elevation={2} className={classes.formsListContainer}>
                                            <If condition={formsLoading}>
                                                <Loading className="loading-table" />
                                            </If>
                                            <If condition={!formsLoading && forms.length === 0}>
                                                <div className={classes.noForms}>
                                                    <Typography variant="body1">
                                                        There are no available forms, please add some
                                                    </Typography>
                                                </div>
                                            </If>
                                            <If condition={!formsLoading && forms.length > 0}>
                                                <List>
                                                    {sortedForms.map((form) => {
                                                        const labelId = `checkbox-list-label-${form.id}`;
                                                        const isChecked = selectedForms
                                                            .some((selected) => selected.id === form.id);

                                                        let isDisabled = false;
                                                        if (!isChecked && selectedForms.length > 0) {
                                                            const firstForm = selectedForms[0];
                                                            if (firstForm.category === WINDOW_STICKERS_CATEGORY.WINDOW_LABEL) {
                                                                isDisabled = true;
                                                            } else if (firstForm.category !== form.category) {
                                                                isDisabled = true;
                                                            }
                                                        }

                                                        return (
                                                            <ListItem
                                                                key={form.id}
                                                                dense
                                                                button
                                                                disabled={isDisabled}
                                                            >
                                                                <ListItemIcon className={classes.listItemIcon}>
                                                                    <Checkbox
                                                                        checked={isChecked}
                                                                        color="primary"
                                                                        edge="start"
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                        onChange={
                                                                            (e) => onFormPicked(
                                                                                form,
                                                                                e.target.checked,
                                                                            )
                                                                        }
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    className={classes.formName}
                                                                    id={labelId}
                                                                    primary={form.commonName}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </If>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    lg={12 - columnsInLargeSize}
                                    md={12 - columnsInMediumSize}
                                    sm={12}
                                    className={classes.tableGrid}
                                >
                                    <div className={classes.formsContainer}>
                                        <VirtualTable
                                            customTableContainerStyle={classes.tableContainer}
                                            rowHeight={50}
                                            loading={vehiclesLoading}
                                            totalRecords={table.records.length}
                                            data={table.records}
                                            columns={getTableColumns()}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
            <If condition={writePermission && showFormsDialog}>
                <FormsDialog
                    onSave={onSaveInventoryForms}
                    onClose={toggleFormsDialog}
                />
            </If>
            <If condition={writePermission && showWarrantyTemplatesDialog}>
                <WarrantyTemplatesDialog
                    onSave={saveWarrantyTemplate}
                    onClose={toggleWarrantiesDialog}
                />
            </If>
        </>
    );
};

StickersDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    selectedLots: PropTypes.array,
    writePermission: PropTypes.bool.isRequired,
};

StickersDialog.defaultProps = {
    selectedLots: [],
};

export default StickersDialog;

export default class EditableTableStyles {
    static settingsTable(theme = {}) {
        return ({
            rootContainer: {
                display: 'flex',
                flexDirection: 'row',
                background: theme.palette.background.white,
                overflow: 'auto',
            },
            containerHeader: {
                display: 'flex',
                alignItems: 'center',
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            deleteButton: {
                color: theme.palette.error.main,
            },
            tableSpacing: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                maxHeight: 'calc(100vh - 325px)',
            },
            alert: {
                color: '#856404',
                backgroundColor: '#fff3cd',
                borderColor: '#ffeeba',
                position: 'relative',
                padding: '.75rem 1.25rem',
                marginBottom: '1rem',
                border: '1px solid transparent',
                borderRadius: '.25rem',
                margin: theme.spacing(3),
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
        });
    }
}

import React from 'react';
import PropTypes from 'prop-types';

// material
import { Dialog } from '@material-ui/core';
import MailForm from 'components/modules/crm/mail/MailForm';

// HTTP
import ModalUtils from 'utils/ModalUtils';
import { useMutation } from '@apollo/client';
import MessageUtils from 'utils/MessageUtils';
import MailMutation from 'services/graphQL/mutate/crm/MailMutation';

const EmailForm = ({
    open, onClose, crmId, email, leadCode,
    prospectLanguage,
}) => {
    const [sendEmail, { loading }] = useMutation(MailMutation.SEND_EMAIL_BY_CRM_ID);
    const sendMessage = async (record) => {
        try {
            const input = {
                cc: record.cc,
                bcc: record.bcc,
                subject: record.subject,
                body: record.bodyMessage,
            };

            const response = await sendEmail({ variables: { input, crmId } });

            if (response?.data?.sendEmailByCRMId) {
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('Send', 'Email'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('Send', 'Email'));
        }
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="md"
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            scroll="paper"
            disableEnforceFocus
        >
            <MailForm
                disabledTo
                email={email}
                prospectLanguage={prospectLanguage}
                showEmailTemplate
                loading={loading}
                onClose={onClose}
                leadCode={leadCode}
                onSendMessage={sendMessage}
            />
        </Dialog>
    );
};

EmailForm.propTypes = {
    email: PropTypes.string,
    leadCode: PropTypes.number,
    open: PropTypes.bool.isRequired,
    crmId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    prospectLanguage: PropTypes.string,
};

EmailForm.defaultProps = {
    email: null,
    leadCode: null,
    prospectLanguage: 'English',
};

export default EmailForm;

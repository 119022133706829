import * as yup from 'yup';

const DepositSchema = yup.object().shape({
    bankAccount: yup.number().required().moreThan(0),
    date: yup.date().required(),
    amount: yup.number().required(),
    lotId: yup.number().required().moreThan(0),
    memo: yup.string().max(
        250,
        'Memo has a maximum limit of 250 characters',
    ),
    status: yup.string().max(12),
    lotName: yup.string().max(50),
});

export default DepositSchema;

export default class QueriesBaseLayoutStyles {
    static layout(theme) {
        return ({
            '@global': {
                '#root > div:nth-child(1)': {
                    overflow: 'initial',
                    height: '100%',
                    '& > main': {
                        overflow: 'initial',
                        height: '100%',
                        '& > div:nth-child(2)': {
                            overflow: 'initial',
                        },
                    },
                },
            },
            loader: {
                position: 'absolute',
                backgroundColor: theme.palette.background.white,
                opacity: '0.5',
                width: '98%',
                height: '96%',
                marginTop: '0px',
            },
            boxContainer: {
                minWidth: '1024px',
                overflowX: 'auto !important',
                backgroundColor: theme.palette.background.white,
                '& > div:nth-child(1)': {
                    width: '100%',
                },
            },
            boxItemTop: {
                width: '100%',
                backgroundColor: theme.palette.background.white,
            },
            boxItemDown: {
                width: '100%',
                position: 'relative',
                backgroundColor: theme.palette.background.white,
            },
            splitter: {
                display: 'flex',
                overflow: 'hidden',
                height: '99%',
                '& > .gutter': {
                    marginTop: '20px',
                },
            },
        });
    }

    static childList(theme = {}) {
        return ({
            container: {
                width: '100%',
                height: '95.5%',
                paddingLeft: theme.spacing(0.4),
                paddingRight: theme.spacing(0.4),
            },
            label: {
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '12px',
                marginBottom: theme.spacing(0.65),
                color: theme.palette.text.darkShark,
            },
            boxSearch: {
                marginBottom: theme.spacing(0.25),
                border: '1px solid #ced4da',
            },
            list: {
                height: '91%',
                overflowY: 'auto',
                overflowX: 'hidden',
                border: '1px solid #ced4da',
            },
            listItem: {
                cursor: 'pointer',
                paddingLeft: '5px',
                marginBotton: '5px',
                fontSize: '11px',
                '&:hover': {
                    backgroundColor: theme.palette.background.silver,
                    color: theme.palette.text.darkShark,
                },
            },
            listItemSelected: {
                cursor: 'pointer',
                paddingLeft: '5px',
                marginBotton: '5px',
                fontSize: '12px',
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
            },
        });
    }

    static sqlEditor(theme = {}) {
        return ({
            topSplitter: {
                display: 'flex',
                flexDirection: 'row',
            },
            queryName: {
                marginBottom: '5px',
                width: '95%',
                '& > input': {
                    borderRadius: '0 !important',
                },
            },
            editorContainer: {
                height: '82.2%',
                border: '1px solid #ced4da',
                overflow: 'hidden',
                '& > div': {
                    height: '100%',
                },
                '& textarea': {
                    fontSize: '14px',
                    resize: 'none',
                    height: '100%',
                    wordBreak: 'break-word',
                },
            },
            actions: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '5px',
                padding: '3px',
                border: '1px solid #ced4da',
            },
            actionButton: {
                width: 'fit-content',
                marginRight: '5px',
            },
            actionRemove: {
                marginTop: '-2px',
                padding: '5px',
                borderRadius: 0,
                backgroundColor: 'transparent',
                '& svg': {
                    fill: 'red',
                },
            },
            actionDisabled: {
                '& svg': {
                    fill: theme.palette.background.gray,
                },
            },
            invalidInput: {
                borderColor: 'red',
            },
        });
    }

    static previewList(theme) {
        return ({
            container: {
                width: '100%',
                height: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingTop: '37px',
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    marginLeft: '0px',
                    padding: '7px 5px',
                    fontSize: '11px',
                    color: theme.palette.text.outerSpace,
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            header: {
                display: 'flex',
                justifyContent: 'space-between',
                border: '1px solid #ced4da',
                paddingLeft: '4px',
                paddingTop: '2px',
                paddingBottom: '2px',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
            },
            headerLeft: {
                fontSize: '13px',
                paddingTop: '5px',
            },
            headerRight: {
                fontSize: '13px',
            },
            actionExport: {
                width: 'fit-content',
                marginRight: '5px',
            },
        });
    }
}

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import Select from 'components/widgets/Select';
import { Form, Row, Col } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogActions from 'components/widgets/modal/DialogActions';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import usePayrollDBList from 'components/hook/payroll/usePayrollDBList';
import useDepartmentList from 'components/hook/payroll/useDepartmentList';
import PayrollSettingsQuery from 'services/graphQL/query/payroll/Settings';
import PayrollSettingsMutation from 'services/graphQL/mutate/payroll/Settings';
import useEmployeeTypeList from 'components/hook/payroll/useEmployeeTypeList';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import PayrollMapsSchema from 'utils/schema/settings/payroll/PayrollMapsSchema';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 150,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
            minWidth: 400,
        },
    },
}));

const initialState = {
    glAccount: 0,
    mapField: null,
    department: null,
    employeeType: null,
};

const PayrollMappingsDialog = ({ onClose, mapId }) => {
    const classes = useStyles();
    const { payrollDBList } = usePayrollDBList();
    const { departmentList } = useDepartmentList();
    const [state, setState] = useState(initialState);
    const { employeeTypeList } = useEmployeeTypeList();
    const [savePayrollMap, { loading: updating }] = useMutation(PayrollSettingsMutation.SAVE_PAYROLL_MAP);

    const {
        data, loading, error,
    } = useQuery(PayrollSettingsQuery.GET_PAYROLL_MAP, {
        variables: { mapId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        skip: mapId === 0,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getPayrollMap } = data;
            setState({ ...getPayrollMap });
        }
    }, [data, loading, error]);

    const onChange = (field, value) => {
        setState((item) => ({
            ...item,
            [field]: value,
        }));
    };

    const onSave = async () => {
        try {
            const input = { ...state, mapId };
            const response = await savePayrollMap({ variables: { input } });

            if (response.data?.savePayrollMapping) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({ id: 0, saving: true });
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Payroll Mapping'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    };

    const isValidData = isValidSchema(PayrollMapsSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Dialog
            open
            maxWidth="md"
        >
            <DialogAppBar
                title={mapId === 0 ? 'New Mapping' : `Editing mapping id: ${mapId}`}
                onClose={() => onClose({ id: 0, saving: false })}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className="g-2">
                        <Col xl>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Employee Department:</Form.Label>
                                    <Select
                                        size="sm"
                                        name="department"
                                        onChange={onChange}
                                        placeholder="Select"
                                        options={departmentList}
                                        value={state.department}
                                        className={isValidField(errors, 'department') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Employee Type:</Form.Label>
                                    <Select
                                        name="employeeType"
                                        placeholder="Select"
                                        size="sm"
                                        options={employeeTypeList}
                                        value={state.employeeType}
                                        onChange={onChange}
                                        className={isValidField(errors, 'employeeType') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Mapped Field:</Form.Label>
                                    <Select
                                        className={isValidField(errors, 'mapField') ? 'invalid-field' : ''}
                                        name="mapField"
                                        placeholder="Select"
                                        size="sm"
                                        options={payrollDBList}
                                        value={state.mapField}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label className={classes.labels}>Account:</Form.Label>
                                    <DropdownQuery
                                        name="glAccount"
                                        value={state.glAccount}
                                        placeholder="Select"
                                        allowEmptyLine={false}
                                        defaultEmptyLineText="Select"
                                        onChange={onChange}
                                        className={isValidField(errors, 'glAccount') ? 'invalid-field' : ''}
                                        dataSource={{
                                            query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                            variables: {
                                                paginate: {
                                                    init: 0,
                                                    ignoreLimit: true,
                                                },
                                            },
                                            rootData: 'getAccountingCOAList.data',
                                            idField: 'accountNumber',
                                            descriptionField: 'fullDescription',
                                        }}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                titleSecondary="Cancel"
                onClickSecondary={() => onClose({ id: 0, saving: false })}
                variant="contained"
                disablePrimaryButton={!isValid || updating}
            />
        </Dialog>
    );
};

PayrollMappingsDialog.propTypes = {
    mapId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
};

PayrollMappingsDialog.defaultProps = {
    mapId: 0,
};

export default PayrollMappingsDialog;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import clsx from 'clsx';
import {
    makeStyles, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { ExpandMoreIcon } from 'components/icons';
import NumberUtils from 'lib/NumberUtils';
import PropTypes from 'prop-types';
import ArrayUtils from 'lib/ArrayUtils';
import ServiceListStyle from 'styles/modules/services/list/ServiceListStyle';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { ServiceInvoiceStatus, SERVICE_JOBS_STATUS_LIST } from 'utils/enum/ServiceInvoiceEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const useStyles = makeStyles((theme) => ServiceListStyle.serviceJobItem(theme));

const keyStore = new KeyStore();

const ServiceJobsItem = (props) => {
    const {
        serviceInvoiceId,
        jobs,
        classes: parentClasses,
        minmumGPValue,
        changeJobStatus,
        invoiceIdentifier,
        roStatus,
    } = props;

    const SERVICE_TRANSFER = keyStore.hasPermission(Permission.SERVICE_TRANSFER);

    const [data, setData] = useState(jobs || []);
    const classes = {
        ...parentClasses,
        ...useStyles(),
    };

    const {
        innerTable, tableBorder, whiteTable,
        headerBg, gridColumns, transparentRow,
        summaryDetail, paddingTd, techAvatar,
        partsTable, footer, alertCls, jobTblCls,
    } = classes;

    const onExpandChange = (jobNumber, val) => {
        const records = cloneDeep(data);
        const newData = records.map((item) => {
            if (item.jobNumber === jobNumber) {
                // eslint-disable-next-line no-param-reassign
                item.expanded = val;
            }
            return item;
        });

        setData(newData);
    };

    const getTotalGP = (item) => {
        const { laborTotal, laborCost } = item;
        const gp = laborTotal > 0 ? ((laborTotal - laborCost) / laborTotal) * 100 : 0;
        const classCls = Number(gp) < Number(minmumGPValue) ? alertCls : '';
        return <span className={classCls}>{NumberUtils.applyPercentageFormat(gp, '0%')}</span>;
    };

    const getTotalELR = (item) => {
        const { laborTotal, hours } = item;
        const elr = hours > 0 ? laborTotal / hours : 0;
        return NumberUtils.applyCurrencyFormat(elr);
    };

    const onChangeJobStatus = (status, newStatus, jobNumber) => {
        if (status === newStatus) return;
        changeJobStatus(newStatus, jobNumber);
    };

    useEffect(() => {
        setData(jobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceIdentifier]);

    const validRoStatus = [ServiceInvoiceStatus.CLOSED.toLowerCase(), ServiceInvoiceStatus.COMPLETED.toLowerCase(), ServiceInvoiceStatus.VOID.toLowerCase()];

    return (
        <table className={clsx(innerTable, tableBorder, whiteTable)}>
            <thead>
                <tr className={clsx(headerBg, gridColumns)}>
                    <td>Technician</td>
                    <td>Status</td>
                    <td>Customer States</td>
                    <td>Correction</td>
                    <td>HRS</td>
                    <td>Labor Cost</td>
                    <td>Labor Total</td>
                    <td>Parts Total</td>
                    <td>GP %</td>
                    <td>ELR</td>
                </tr>
            </thead>
            <tbody>
                {data?.map((item) => {
                    const {
                        jobNumber, expanded, parts = [],
                        technicianImage, technician, status,
                        customerStates, correction, hours,
                        laborCost, laborTotal, partsTotal,
                    } = item;

                    return (
                        <tr key={['item-job-', serviceInvoiceId, jobNumber].join('')}>
                            <td className="no-padding" colSpan={7}>
                                <Accordion
                                    expanded={expanded || false}
                                    onChange={(_, val) => onExpandChange(jobNumber, val)}
                                    elevation={0}
                                    className={transparentRow}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        className={summaryDetail}
                                    >
                                        <table
                                            className={jobTblCls}
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                        >
                                            <tbody>
                                                <tr className={gridColumns}>
                                                    <td>
                                                        <div className={techAvatar}>
                                                            <img src={technicianImage} alt={technician} title={technician} loading="lazy" />
                                                            <p>{technician}</p>
                                                        </div>
                                                    </td>
                                                    <td className={paddingTd}>
                                                        {SERVICE_TRANSFER && !validRoStatus.includes(roStatus.toLowerCase()) && (
                                                            <DropdownQuery
                                                                name="status"
                                                                allowEmptyLine={false}
                                                                value={status}
                                                                placeHolder="select the status"
                                                                onChange={(name, newValue) => onChangeJobStatus(status, newValue, jobNumber)}
                                                                dataSource={{
                                                                    localData: SERVICE_JOBS_STATUS_LIST,
                                                                    idField: 'value',
                                                                    descriptionField: 'label',
                                                                }}
                                                            />
                                                        )}
                                                        {!(SERVICE_TRANSFER && !validRoStatus.includes(roStatus.toLowerCase())) && <p>{status}</p>}
                                                    </td>
                                                    <td className={paddingTd}>{customerStates || ''}</td>
                                                    <td className={paddingTd}>{correction || ''}</td>
                                                    <td className={paddingTd}>{hours || 0}</td>
                                                    <td className={paddingTd}>{NumberUtils.applyCurrencyFormat(laborCost)}</td>
                                                    <td className={paddingTd}>{NumberUtils.applyCurrencyFormat(laborTotal)}</td>
                                                    <td className={paddingTd}>{NumberUtils.applyCurrencyFormat(partsTotal)}</td>
                                                    <td className={paddingTd}>{getTotalGP(item)}</td>
                                                    <td className={paddingTd}>{getTotalELR(item)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(item?.parts?.length || 0) === 0 && <span>No parts available</span>}
                                        {item.parts?.length > 0 && (
                                            <table className={clsx(partsTable, innerTable, tableBorder, whiteTable)}>
                                                <thead>
                                                    <tr className={headerBg}>
                                                        <td>Part Stock Number</td>
                                                        <td>Description</td>
                                                        <td>Quantity</td>
                                                        <td>Part Cost</td>
                                                        <td>Total</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.parts.map(({
                                                        jobNumber: partJobNumber, partStockNumber,
                                                        description, quantity, partCost, total,
                                                    }, index) => (
                                                        <tr key={['part-item-', partJobNumber, index].join('')}>
                                                            <td>{partStockNumber}</td>
                                                            <td>{description || ''}</td>
                                                            <td>{quantity}</td>
                                                            <td>{NumberUtils.applyCurrencyFormat(partCost)}</td>
                                                            <td>{NumberUtils.applyCurrencyFormat(total)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td>{ArrayUtils.getTotalByItem(parts, 'quantity', false)}</td>
                                                        <td>{ArrayUtils.getTotalByItem(parts, 'partCost')}</td>
                                                        <td>{ArrayUtils.getTotalByItem(parts, 'total')}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
            <tfoot>
                <tr className={clsx(footer, gridColumns)}>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{ArrayUtils.getTotalByItem(data, 'hours', false)}</td>
                    <td>{ArrayUtils.getTotalByItem(data, 'laborCost')}</td>
                    <td>{ArrayUtils.getTotalByItem(data, 'laborTotal')}</td>
                    <td>{ArrayUtils.getTotalByItem(data, 'partsTotal')}</td>
                </tr>
            </tfoot>
        </table>
    );
};

ServiceJobsItem.propTypes = {
    serviceInvoiceId: PropTypes.number.isRequired,
    jobs: PropTypes.array,
    minmumGPValue: PropTypes.number,
    classes: PropTypes.oneOfType([PropTypes.object]),
    changeJobStatus: PropTypes.func.isRequired,
    invoiceIdentifier: PropTypes.string,
    roStatus: PropTypes.string,
};

ServiceJobsItem.defaultProps = {
    jobs: [],
    classes: {},
    minmumGPValue: 0,
    invoiceIdentifier: '',
    roStatus: '',
};

export default ServiceJobsItem;

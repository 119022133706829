import React, { useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import DynamicColor from 'utils/DynamicColor';
import { Apartment } from '@material-ui/icons';
import Container from 'components/widgets/Container';
import {
    IconButton, Popover, makeStyles, Typography, Tooltip, ListItemAvatar, Avatar, ListItem,
} from '@material-ui/core';

const SwitchCompany = ({ iconColor }) => {
    const keyStore = new KeyStore();
    const availableCompaniesToSwitch = keyStore.getAvailableCompaniesToSwitch();
    const classes = makeStyles((theme) => ({
        popover: {
            minHeight: 60,
            maxHeight: '80%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            borderRadius: theme.spacing(1),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: '16px 20px',
        },
        button: {
            padding: '6px',
            '& svg': {
                fill: iconColor,
            },
        },
        container: {
            justifyContent: 'center',
        },
        listItem: {
            width: 'auto',
            marginRight: 30,
            display: 'block',
            padding: 'inherit',
            textAlign: '-webkit-center',
            border: '1px solid lightgray',
            marginBottom: theme.spacing(1),
            borderRadius: theme.spacing(1),
            '&:last-child': {
                marginRight: 0,
            },
        },
        avatar: {
            marginBottom: 10,
        },
        ...DynamicColor.getStyles(theme),
    }))();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCompanyClick = (event) => {
        if (event.type === 'click') {
            event.preventDefault();
        }
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const title = 'Right click to choose the Chrome account to open your company.';
    return (
        <div>
            <Tooltip title="Switch Company">
                <IconButton aria-describedby={id} onClick={handleClick} className={classes.button}>
                    <Apartment />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 45,
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <div className={classes.header}>
                    <Typography variant="h6">{title}</Typography>
                </div>
                <Container className={classes.container}>
                    {availableCompaniesToSwitch.map((company, index) => (
                        <ListItem
                            button
                            component="a"
                            className={classes.listItem}
                            href={`/switchtocompany/${company?.userLoginMappingId}/${keyStore.getToken()}`}
                            key={`company-list-${index}`}
                            onClick={handleCompanyClick}
                        >
                            <ListItemAvatar>
                                <Avatar className={clsx(classes.avatar, classes[DynamicColor.getClassByIndex(index)])}>
                                    {company?.companyName.charAt(0)}
                                </Avatar>
                            </ListItemAvatar>
                            <Typography
                                noWrap
                                variant="h6"
                                color="textPrimary"
                            >
                                {company?.companyName}
                            </Typography>
                        </ListItem>
                    ))}
                    {availableCompaniesToSwitch.length === 0 && (
                        <Typography
                            noWrap
                            variant="h4"
                            color="textPrimary"
                        >
                            There is not company available to switch to.
                        </Typography>
                    )}
                </Container>
            </Popover>
        </div>
    );
};

SwitchCompany.propTypes = {
    iconColor: PropTypes.string,
};

SwitchCompany.defaultProps = {
    iconColor: 'white',
};

export default SwitchCompany;

import { modules } from 'utils/enum/modules';
import StringUtils from 'lib/StringUtils';

const URL = {
    GET_CREATE_VEHICLES: '/vehicles',
    REPAIRS_LIST: '/vehicles/~stockNumber~/repairs',
    CREATE_REPAIRS: '/vehicles/~stockNumber~/repairs',
    UPDATE_REPAIRS: '/vehicles/~stockNumber~/repairs/~repairId~',
    DELETE_REPAIRS: '/vehicles/~stockNumber~/repairs/~repairId~',
    GET_INVENTORY_TAGS: '/tags/~module~',
    CREATE_INVENTORY_TAG: '/tags/create',
    UPDATE_INVENTORY_TAG: '/tag/~tagId~',
    DELETE_INVENTORY_TAG: '/tags/~module~/tag/~id~',
    CARFAX: 'https://www.carfax.com/cfm/ccc_DisplayHistoryRpt.cfm?partner=~carfaxId~&vin=~vin~',
};

export default class HttpClient {
    static getURLListVehicle() {
        return URL.GET_CREATE_VEHICLES;
    }

    static getURLCreateVehicle() {
        return URL.GET_CREATE_VEHICLES;
    }

    static getURLRepairList(stockNumber) {
        return URL.REPAIRS_LIST.replace('~stockNumber~', stockNumber);
    }

    static getURLRepairCreate(stockNumber) {
        return URL.CREATE_REPAIRS.replace('~stockNumber~', stockNumber);
    }

    static getURLRepairUpdate(stockNumber, repairId) {
        return URL.UPDATE_REPAIRS.replace(
            '~stockNumber~',
            stockNumber,
        ).replace('~repairId~', repairId);
    }

    static getURLRepairDelete(stockNumber, repairId) {
        return URL.DELETE_REPAIRS.replace(
            '~stockNumber~',
            stockNumber,
        ).replace('~repairId~', repairId);
    }

    static getURLInventoryTags() {
        return URL.GET_INVENTORY_TAGS
            .replace('~module~', StringUtils.toPascalCase(modules.INVENTORY));
    }

    static getURLInventoryTagUpdate(tagId) {
        return URL.UPDATE_INVENTORY_TAG
            .replace('~tagId~', tagId);
    }

    static getURLInventoryTagDelete(module, tagId) {
        return URL.DELETE_INVENTORY_TAG
            .replace('~module~', module)
            .replace('~id~', tagId);
    }

    static getURLInventoryTagCreate() {
        return URL.CREATE_INVENTORY_TAG;
    }

    static getCarFaxURL(carfaxId, vin) {
        return URL.CARFAX
            .replace('~carfaxId~', carfaxId)
            .replace('~vin~', vin);
    }
}

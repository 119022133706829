import React, { useEffect, useState } from 'react';
import {
    makeStyles, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import Permission from 'utils/enum/Permissions';
import Loading from 'components/widgets/Loading';
import If from 'components/widgets/conditional/If';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from 'components/icons';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PayrollAdjustmentsQuery from 'services/graphQL/query/payroll/PayrollAdjustments';
import PayrollDeductionsDialog from 'components/widgets/payroll/PayrollDeductionsDialog';
import PayrollAdjustmentsMutation from 'services/graphQL/mutate/payroll/PayrollAdjustments';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '95px',
        marginRight: '16px',
        color: theme.palette.text.white,
    },
    newButton: {
        backgroundColor: theme.palette.background.eucalyptus,
        '&:hover': {
            backgroundColor: theme.palette.background.forestGreen,
        },
    },
    editButton: {
        backgroundColor: theme.palette.background.malibu,
        '&:hover': {
            backgroundColor: theme.palette.background.havelockBlue,
        },
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
    boxContent: {
        overflow: 'hidden',
    },
    root: {
        zIndex: 1,
        marginLeft: '2px',
        background: 'white',
        position: 'relative',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    tableHeight: {
        height: '22vh',
    },
    highlightedColor: {
        color: theme.palette.text.wildBlueYonder,
        fontSize: '1rem',
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
}));

const PayrollDeductions = ({
    payPeriod, employeeId, hasCheck, setCalculateCheckDetail,
    calculateCheckDetail,
}) => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    let tableRef;

    const [state, setState] = useState(
        {
            items: [],
            openDelete: false,
            openAddEdit: false,
            selectedIndex: null,
            selectedRecord: null,
        },
    );

    const {
        data, loading, error, refetch,
    } = useQuery(PayrollAdjustmentsQuery.GET_PAYROLL_DEDUCTIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            payPeriod,
            employeeId,
        },
        skip: !employeeId || !payPeriod,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getPayrollDeductions } = data;
            const sortedList = ArrayUtils.sortByObjectField(getPayrollDeductions, 'payrollAdditionId');
            setState((previousState) => ({
                ...previousState,
                items: sortedList.filter((item) => (hasCheck ? item.payrollAdditionId : true)),
                selectedIndex: null,
                selectedRecord: null,
            }));
        }
    }, [data, loading, error, hasCheck]);

    useEffect(() => {
        if (!employeeId || !payPeriod) {
            setState((previousState) => ({
                ...previousState,
                items: [],
                selectedIndex: null,
                selectedRecord: null,
            }));
        }
    }, [employeeId, payPeriod]);

    const [removePayrollAdjustment, { loading: removing }] = useMutation(PayrollAdjustmentsMutation.REMOVE_PAYROLL_ADJUSTMENT, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.removePayrollAdjustment) {
                ModalUtils.successMessage(null, 'Deleted Successfully');
                setCalculateCheckDetail(true);
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const closeDelete = (isDeleting) => {
        setState((previousState) => ({
            ...previousState,
            openDelete: false,
            selectedIndex:
                isDeleting
                && previousState.selectedIndex >= previousState.items.length - 1
                    ? null : previousState.selectedIndex,
            selectedRecord:
                isDeleting
                && previousState.selectedIndex >= previousState.items.length - 1
                    ? null
                    : tableRef.table.state.sortedData[previousState.selectedIndex]._original,
        }));
    };

    const openDelete = () => {
        setState((previousState) => ({
            ...previousState,
            openDelete: true,
        }));
    };

    const onDelete = () => {
        const recordToDelete = tableRef.table.state.sortedData[state.selectedIndex]._original;
        removePayrollAdjustment({
            variables: {
                ...(recordToDelete.payrollDeductionsMappingId && {
                    payrollDeductionsMappingId: recordToDelete.payrollDeductionsMappingId,
                }),
                ...(!recordToDelete.payrollDeductionsMappingId && {
                    payrollAdditionId: recordToDelete.payrollAdditionId,
                }),
            },
        });
        closeDelete(true);
    };

    const closeAddEdit = () => {
        setState((previousState) => ({
            ...previousState,
            openAddEdit: false,
            selectedRecord: state.selectedIndex !== null ? tableRef.table.state.sortedData[state.selectedIndex]._original : null,
        }));
    };

    useEffect(() => {
        if (calculateCheckDetail) refetch();
    }, [calculateCheckDetail, refetch]);

    const openAddEdit = (isEditing = false) => {
        setState((previousState) => ({
            ...previousState,
            openAddEdit: true,
            selectedRecord: isEditing ? tableRef.table.state.sortedData[state.selectedIndex]._original : null,
        }));
    };

    const setSelectedRecordAndIndex = (record, index = null) => {
        setState((previousState) => ({
            ...previousState,
            selectedIndex: index === null ? previousState.selectedIndex : index,
            selectedRecord: record,
        }));
    };

    const getColumns = () => [{
        Header: 'Reocurring',
        Cell: (cell) => {
            const { original: { payrollAdditionId } } = cell;

            return (
                payrollAdditionId ? (
                    <CheckBoxOutlineBlankIcon
                        className={classes.highlightedColor}
                    />
                )
                    : (
                        <CheckBoxIcon
                            className={classes.highlightedColor}
                        />
                    )
            );
        },
        minWidth: 6,
        sortable: false,
        filterable: false,
        id: 'checkbox-column',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Deduction',
        id: 'description',
        minWidth: 30,
        accessor: 'description',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Control #',
        id: 'controlNumber',
        minWidth: 17,
        accessor: 'controlNumber',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Amount',
        id: 'amount',
        minWidth: 17,
        accessor: 'amount',
        className: 'd-flex-justify-center-align-center',
    }, {
        Header: 'Memo',
        id: 'memo',
        minWidth: 30,
        accessor: 'memo',
        className: 'd-flex-justify-center-align-center',
    }];
    const disableButtons = state.selectedRecord === null;

    const sorting = () => {
        if (state.selectedIndex !== null) setSelectedRecordAndIndex(tableRef.table.state.sortedData[state.selectedIndex]._original);
    };

    return (
        <>
            <div className={classes.root}>
                {keyStore.hasPermission(Permission.PAYROLL_ADJUSTMENTS_WRITE) && !hasCheck && (
                    <div className={classes.header}>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.newButton)}
                            startIcon={<AddIcon />}
                            disabled={removing || !employeeId || !payPeriod}
                            onClick={() => openAddEdit(false)}
                        >
                            New
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.editButton)}
                            startIcon={<BorderColorOutlinedIcon />}
                            disabled={disableButtons || removing}
                            onClick={() => openAddEdit(true)}
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className={clsx(classes.button, classes.deleteButton)}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={disableButtons || removing}
                            onClick={openDelete}
                        >
                            Delete
                        </Button>

                    </div>
                )}
                <div className={classes.tableHeight}>
                    <If condition={loading}>
                        <div className={classes.loadingIndicator}>
                            <Loading />
                        </div>
                    </If>
                    <If condition={!loading}>
                        <Table
                            rowSelected
                            // eslint-disable-next-line no-return-assign
                            ref={(t) => (tableRef = t)}
                            load={loading}
                            totalRecords={state.items.count}
                            data={state.items}
                            columns={getColumns()}
                            className="-highlight payrollTable"
                            getTrProps={(_, rowInfo) => ({
                                onClick: (event) => {
                                    event.currentTarget.classList.add('active');
                                    setSelectedRecordAndIndex(rowInfo.original, rowInfo.viewIndex);
                                },
                            })}
                            sortedChange={sorting}
                        />
                    </If>
                </div>
            </div>
            <ConfirmDialog
                title="Confirm remove deduction"
                description="Are you sure you want to remove this deduction?"
                open={state.openDelete}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClickSecondary={() => closeDelete(false)}
                onClose={() => closeDelete(false)}
                onClickPrimary={onDelete}
            />
            {state.openAddEdit && (
                <PayrollDeductionsDialog
                    open={state.openAddEdit}
                    record={state.selectedRecord}
                    onClose={closeAddEdit}
                    employeeId={employeeId}
                    payPeriod={payPeriod}
                    setCalculateCheckDetail={setCalculateCheckDetail}
                />
            )}
        </>
    );
};

PayrollDeductions.propTypes = {
    hasCheck: PropTypes.bool.isRequired,
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
    calculateCheckDetail: PropTypes.bool.isRequired,
    setCalculateCheckDetail: PropTypes.func.isRequired,
};

export default PayrollDeductions;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogContent,
    makeStyles,
} from '@material-ui/core';
import PROVIDER, {
    PACKAGE,
} from 'utils/enum/MenuEnum';
import {
    useApolloClient,
} from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import MenuHelper from 'utils/MenuHelper';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import Loading from 'components/widgets/Loading';
import ArrayUtils from 'lib/ArrayUtils';
import MenuMutation from 'services/graphQL/mutate/menu/MenuMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '5px 5px',
        display: 'flex',
        height: '80vh',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            flexDirection: 'column',
        },
        '& > div.sidebar': {
            width: '200px',
            flexShrink: 0,
            borderRight: `1px solid ${theme.palette.border.ghost}`,
            paddingRight: '5px',
            fontSize: '13px',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            marginRight: '5px',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'row',
                width: 'auto',
                height: 'auto',
                borderRight: 'initial',
                marginBottom: '10px',
                overflowX: 'auto',
                overflowY: 'hidden',
                flexShrink: 'initial',
                '& > div.contract': {
                    marginRight: '20px',
                },
            },
            '& > div.contract': {
                paddingBottom: '5px',
                cursor: 'pointer',
                overflow: 'hidden',
                marginBottom: '10px',
                lineHeight: 1.5,
                paddingLeft: '5px',
                '& > div': {
                    fontSize: '12px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                },
                '& > div:nth-child(1)': {
                    color: theme.palette.secondaryInfo.main,
                    fontWeight: 500,
                    width: '100%',
                },
                '& > div:nth-child(4)': {
                    fontWeight: 500,
                    fontSize: '11px',
                },
            },
        },
        '& > div.pdfViewer': {
            flexGrow: 1,
            position: 'relative',
            '& > div.error': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '13px',
                fontWeight: 500,
            },
        },
    },
    loader: {
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: '999',
        backgroundColor: theme.palette.background.white,
        width: '100%',
        height: '100%',
        opacity: '0.5',
    },
    iframe: {
        height: '100%',
        width: '100%',
    },
}));

const ContractsViewer = ({
    dealNumber,
    rates,
    previewContracts,
    selectedPackage,
    onClose,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        contracts: previewContracts.map((item) => ({ ...item, loading: StringUtils.isEmpty(item.contract?.documentPDF), error: null })),
        currentContract: 0,
    });

    const generateContract = async (item) => {
        const { product } = item;
        const isExpress = product.vendor.provider === PROVIDER.FI_EXPRESS;
        const currentOptions = product.currentOptions[selectedPackage];
        const rateData = isExpress
            ? rates.find((r) => r.productId === product.id)?.rates
            : rates.find((r) => r.vendorId === product.vendor.vendorId)?.rates;

        let plan = null;
        let term = null;
        if (!isExpress) {
            const plans = rateData.plans[product.details.providerProductCode];
            plan = plans[currentOptions.type].find((x) => x.planId === currentOptions.planId);
            term = plan.terms.find((t) => t.term === currentOptions.term
                && t.mileage === currentOptions.mileage
                && t.deductible.id === currentOptions.deductible);
        }

        try {
            const { data } = await client.mutate({
                mutation: MenuMutation.GENERATE_CONTRACT,
                variables: {
                    vendorId: product.vendor.vendorId,
                    productId: product.id,
                    dealNumber,
                    rateData: !isExpress ? {
                        formNo: plan.formNo,
                        quoteId: rateData.quoteId,
                        planCode: plan.planCode,
                        rateBook: plan.rateBook,
                        netCost: currentOptions.netRate,
                        retailCost: currentOptions.sellingPrice,
                        isTaxExempt: false,
                        term: {
                            id: term.termId,
                            term: term.term,
                            mileage: term.mileage,
                        },
                        deductible: {
                            id: term.deductible.id,
                            amount: term.deductible.amount,
                            type: term.deductible.type,
                            reduceAmount: term.deductible.reduceAmount,
                        },
                        options: (currentOptions.surcharges || []).filter((s) => s.active).map((s) => ({
                            id: s.optionId,
                            description: s.description,
                            isSurcharge: s.isSurcharge,
                            netCost: s.netRate,
                        })),
                        additionalContractInfo: (currentOptions.contractOptions || []).filter((co) => !StringUtils.isEmpty(co.value)).map((co) => ({
                            fieldLabel: co.fieldLabel,
                            fieldValue: co.value,
                            fieldDataType: co.fieldType,
                        })),
                        vehicleType: currentOptions.type,
                    } : {
                        rateResponseId: currentOptions.rateResponseID,
                        formNo: currentOptions.contractFormID,
                        retailCost: currentOptions.retailRate + currentOptions.otherCharges,
                        netCost: currentOptions.netRate,
                        sellingPrice: currentOptions.sellingPrice,
                        coverage: currentOptions.coverageName,
                        term: {
                            term: currentOptions.term,
                            mileage: currentOptions.mileage,
                        },
                        deductible: {
                            otherAmount: currentOptions.deductible,
                        },
                        serviceInterval: currentOptions.serviceInterval,
                        tireRotations: currentOptions.tireRotations,
                        options: (currentOptions.surcharges || []).map((s) => ({
                            description: s.surchargeCode,
                            isSurcharge: s.active,
                            netCost: s.cost,
                        })),
                        additionalContractInfo: (currentOptions.contractOptions || []).filter((co) => !StringUtils.isEmpty(co.value)).map((co) => ({
                            fieldLabel: co.fieldName,
                            fieldValue: String(co.value),
                            fieldDataType: co.dataType,
                        })),
                    },
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = data?.generateContract;
            if (response) {
                const { contractNumber, pdf } = response;
                setState((prevState) => ({
                    ...prevState,
                    contracts: prevState.contracts.map((c) => (c.product.id === item.product.id
                        ? { ...c, loading: false, contract: { contractNumber, documentPDF: pdf } }
                        : c)),
                }));
            }
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                contracts: prevState.contracts.map((c) => (c.product.id === item.product.id
                    ? { ...c, loading: false, error: error.message }
                    : c)),
            }));
        }
    };

    const { contracts, currentContract } = state;
    useEffect(() => {
        if (ArrayUtils.isNotEmpty(contracts)) {
            contracts.forEach((item) => {
                if (item.loading) generateContract(item);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const anyLoading = contracts.some((item) => item.loading);
    const contractInView = contracts[currentContract];
    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Contracts Viewer"
                onClose={() => (!anyLoading ? onClose() : null)}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <div className="sidebar">
                        {contracts.map(({ product, contract }, index) => {
                            const currentOptions = product.currentOptions?.[selectedPackage];
                            return (
                                <div
                                    style={{ borderLeft: currentContract === index ? `2px solid ${MenuHelper.getPackageColor(PACKAGE.PREMIUM)}` : 'initial' }}
                                    className="contract"
                                    key={index}
                                    onClick={() => setState((prevState) => ({ ...prevState, currentContract: index }))}
                                >
                                    <div>{`Product: ${product.productName}`}</div>
                                    <div>{`Vendor: ${product.vendor.vendorName}`}</div>
                                    <div>{`Contract: ${contract?.contractNumber || 'Retrieving...'}`}</div>
                                    {currentOptions.mileage > 0 && (
                                        <div>{`${currentOptions.term} Months / ${NumberUtils.applyThousandsFormat(currentOptions.mileage)} Miles`}</div>
                                    )}
                                    {currentOptions.mileage <= 0 && (
                                        <div>{`${currentOptions.term} Months`}</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="pdfViewer">
                        {contractInView.loading && (
                            <Loading className={classes.loader} />
                        )}
                        {!contractInView.loading && contractInView.error && (
                            <div className="error">{MenuHelper.formatErrorMessage(contractInView.error).message}</div>
                        )}
                        {!contractInView.loading && !contractInView.error && contractInView.contract?.documentPDF && (
                            <iframe
                                className={classes.iframe}
                                title="Preview"
                                src={`${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${contractInView.contract.documentPDF}`}
                                frameBorder="0"
                                allowFullScreen
                            />
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

ContractsViewer.defaultProps = {
    rates: [],
};

ContractsViewer.propTypes = {
    rates: PropTypes.array,
    dealNumber: PropTypes.number.isRequired,
    previewContracts: PropTypes.array.isRequired,
    selectedPackage: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContractsViewer;

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import UserQuery from 'services/graphQL/query/UserQuery';
import SettingsQuery from 'services/graphQL/query/SettingsQuery';

// Router
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

// Leads Modules
import Calendar from 'components/modules/crm/calendar/Calendar';
import Opportunity from 'components/modules/crm/read/Opportunity';
import OpportunityList from 'components/modules/crm/list/OpportunityList';

// Inventory Module
import InventoryList from 'components/modules/inventory/list/InventoryList';
import VehicleDetail from 'components/modules/inventory/read/VehicleDetail';
import CreateForm from 'components/modules/inventory/create/CreateForm';
import Appraisals from 'components/modules/inventory/read/Appraisals';
import Desking from 'components/modules/inventory/read/Desking';

// Deals Module
import DealList from 'components/modules/deals/list/DealList';
import DealDetail from 'components/modules/deals/read/dealTab/DealDetail';
import DealCreate from 'components/modules/deals/create/dealTab/DealCreate';
import PaymentCalculator from 'components/modules/deals/read/PaymentCalculator';

// Component
import BaseLayout from 'components/layout/BaseLayout';
import Conversation from 'components/modules/conversation/list/Conversation';
import CustomerDetail from 'components/modules/customer/read/CustomerDetail';
import Login from 'components/modules/login/Login';
import ProtectedRoute from 'components/containers/authorization/ProtectedRoute';
import RedirectNotFound from 'components/containers/authorization/RedirectNotFound';
import Dashboard from 'components/modules/dashboard/Dashboard';
import CustomReports from 'components/modules/dashboard/CustomReports';
import SettingsMain from 'components/modules/settings/SettingsMain';
import Preferences from 'components/modules/settings/Preferences';
import AccountingLayout from 'components/modules/accounting/AccountingLayout';
import HomeBaseLayout from 'components/modules/home/HomeBaseLayout';
import QueriesBaseLayout from 'components/modules/queries/QueriesBaseLayout';
import PayrollMain from 'components/modules/payroll/read/PayrollMain';
import AppraisalHistory from 'components/reports/inventory/AppraisalHistory';
import SalesTax from 'components/reports/financials/SalesTax';
import BusinessIntelligencePanel from 'components/modules/businessIntelligence/BusinessIntelligencePanel';
import MenuMain from 'components/modules/deals/read/menu/MenuMain';
import InventoryTax from 'components/reports/financials/InventoryTax';
import TrainingVideos from 'components/modules/training/TrainingVideos';
import SwitchToCompany from 'components/modules/login/SwitchToCompany';

// External dependencies
import CustomerList from 'components/modules/customer/list/CustomerList';
import CustomerCreate from 'components/modules/customer/create/CustomerCreate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';

// Utils
import KeyStore from 'utils/KeyStore';
import 'services/api/Axios.configuration';
import { modules } from 'utils/enum/modules';
import Permission from 'utils/enum/Permissions';
import Mail from 'components/modules/crm/mail/Mail';
import { Communication } from 'utils/enum/SubModule';
import Recon from 'components/reports/inventory/Recon';
import UserContext from 'components/context/UserContext';
import { ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';
import PasswordReset from 'components/modules/login/PasswordReset';
import ServiceLayout from 'components/modules/service/ServiceLayout';
import { PartsPurchaseSubModules } from 'utils/enum/PartsPurchaseEnum';
import useCompanyThemeColors from 'components/hook/core/useCompanyThemeColors';
import MessageCenter from 'components/modules/messageCenter/list/MessageCenter';
import OpportunityCreate from 'components/modules/lead/create/OpportunityCreate';
import Communications from 'components/modules/messageCenter/read/Communications';
import OperationCodeCreate from 'components/modules/service/opCode/OperationCodeCreate';
import CheckCreate from 'components/modules/accounting/banking/create/check/CheckCreate';
import CheckActionList from 'components/modules/accounting/banking/list/CheckActionList';
import DepositCreate from 'components/modules/accounting/banking/create/deposit/DepositCreate';
import AssignPayment from 'components/modules/accounting/accountsReceivable/create/AssignPayment';
import TransferCreate from 'components/modules/accounting/banking/create/transfer/TransferCreate';
import { AccountingSubModules, AccountsReceivableEntities, GeneralAction } from 'utils/enum/AccountingEnum';
import CashierPaymentCreate from 'components/modules/accounting/accountsReceivable/create/CashierPaymentCreate';
import GeneralJournalCreate from 'components/modules/accounting/journal/create/generalJournal/GeneralJournalCreate';
import PurchasePartsLayout from 'components/modules/purchaseParts/PurchasePartsLayout';
import PurchaseOrderCreate from 'components/modules/purchaseParts/purchaseOrder/create/PurchaseOrderCreate';
import PurchaseActionList from 'components/modules/purchaseParts/purchaseOrder/list/PurchaseActionList';
import PartsInventoryCreate from 'components/modules/purchaseParts/parts/create/PartsInventoryCreate';
import PartsTicketCreate from 'components/modules/purchaseParts/partsTicket/create/PartsTicketCreate';
import ServiceInvoiceCreate from 'components/modules/service/serviceInvoice/create/ServiceInvoiceCreate';
import useDefaultLot from 'components/hook/core/useDefaultLot';
import GeneralUtils from 'utils/GeneralUtils';
import { FetchPolicy } from 'utils/enum/Core';

const App = () => {
    const keyStore = new KeyStore();
    const token = !keyStore.exists();
    const [userInformation, setUserInformation] = useState();
    const [userSettings, setUserSettings] = useState({});
    const {
        availableLots, defaultLotId, defaultLot, lotsUser,
    } = useDefaultLot(userInformation);
    const { loading, data, error } = useQuery(UserQuery.USER_DETAILS, { skip: token });
    const {
        loading: loadingMenuSettings,
        data: menuSettingsData,
        error: errorLoadingMenuSettings,
        refetch: refetchMenuSettings,
    } = useQuery(SettingsQuery.GET_SIDE_MENU_SETTINGS, {
        skip: token,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const updateUserInformation = (record) => {
        setUserInformation(record);
    };

    const SALES_DEAL_READ = keyStore.hasPermission(Permission.SALES_DEAL_READ);
    const INVENTORY_READ = keyStore.hasPermission(Permission.INVENTORY_READ);
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const CRM_OPPORTUNITY_READ = keyStore.hasPermission(Permission.CRM_OPPORTUNITY_READ);
    const CRM_CONVERSATION_READ = keyStore.hasPermission(Permission.CRM_CONVERSATION_READ);
    const SETTINGS_READ = keyStore.hasPermission(Permission.SETTINGS_READ) || keyStore.hasPermission(Permission.USER_SETTINGS_READ);
    const ACCOUNTING_READ = keyStore.hasPermission(Permission.ACCOUNTING_READ);
    const SALES_DEAL_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_WRITE);
    const CUSTOMER_READ = keyStore.hasPermission(Permission.CUSTOMER_READ);
    const CRM_MAILBOX_READ = keyStore.hasPermission(Permission.CRM_MAILBOX_READ);
    const CRM_MESSAGE_CENTER_READ = keyStore.hasPermission(Permission.CRM_MESSAGE_CENTER_READ);
    const QUERIES_WRITE = keyStore.hasPermission(Permission.QUERIES_WRITE);
    const PAYROLL_READ = keyStore.hasPermission(Permission.PAYROLL_READ);
    const INVENTORY_APPRAISALS_READ = keyStore.hasPermission(Permission.INVENTORY_APPRAISALS_READ);
    const PARTS_READ = keyStore.hasPermission(Permission.PARTS_READ);
    const SERVICE_READ = keyStore.hasPermission(Permission.SERVICE_READ);
    const SERVICE_OPEN = keyStore.hasPermission(Permission.SERVICE_OPEN);
    const SERVICE_EDIT = keyStore.hasPermission(Permission.SERVICE_EDIT);

    useEffect(() => {
        if (!error && !loading) {
            setUserInformation(data?.userDetails);
        }
    }, [data, error, loading]);

    useEffect(() => {
        if (!errorLoadingMenuSettings && !loadingMenuSettings) {
            setUserSettings((prevState) => ({
                ...prevState,
                menuSettings: menuSettingsData?.getSideMenuSettings,
                menuSettingsCall: () => refetchMenuSettings(),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorLoadingMenuSettings, loadingMenuSettings]);

    useEffect(() => {
        GeneralUtils.addExternalScript(`${process.env.PUBLIC_URL}/liveChat.js`);
    }, []);

    const { refreshThemeColor, colors } = useCompanyThemeColors();

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event?.key === 'token') {
                const parts = event?.url.split('/');
                const oldToken = parts[parts.length - 1];
                const allTokens = [event.oldValue, event.newValue];
                if (!allTokens.includes(oldToken)) window.location.reload();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <UserContext.Provider value={{
            userInformation,
            userSettings,
            availableLots,
            defaultLotId,
            defaultLot,
            lotsUser,
            colors,
            refreshThemeColor,
            updateUserInformation,
        }}
        >
            <Router>
                <Switch>
                    <Route
                        path="/switchtocompany/:loginmappingid/:token"
                        sensitive={false}
                        render={() => (<SwitchToCompany />)}
                    />
                    <Route
                        path="/password/reset/:token"
                        sensitive={false}
                        render={() => (!keyStore.exists() ? <PasswordReset /> : <Redirect to={{ pathname: '/' }} />)}
                    />
                    <Route
                        path="/login"
                        sensitive={false}
                        render={() => (!keyStore.exists() ? <Login /> : <Redirect to={{ pathname: '/' }} />)}
                    />
                    <Route path="/">
                        <BaseLayout>
                            <Switch>
                                <ProtectedRoute
                                    path="/"
                                    exact
                                >
                                    <Redirect to={{ pathname: '/home' }} />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path="/home"
                                    title="Home"
                                    exact
                                >
                                    <HomeBaseLayout />
                                </ProtectedRoute>
                                {(CRM_MESSAGE_CENTER_READ || CRM_MAILBOX_READ) && (
                                    <ProtectedRoute
                                        path={`/${modules.COMMUNICATION}/:submodule?`}
                                    >
                                        <Communications />
                                        {CRM_MESSAGE_CENTER_READ && (
                                            <ProtectedRoute
                                                path={[`/${modules.COMMUNICATION}/${Communication.SMS}/:id`,
                                                    `/${modules.COMMUNICATION}/${Communication.SMS}`]}
                                                title="Communication / SMS"
                                                description="Show sms by customer"
                                                exact
                                            >
                                                <MessageCenter />
                                            </ProtectedRoute>
                                        )}
                                        {CRM_MAILBOX_READ && (
                                            <ProtectedRoute
                                                path={[`/${modules.COMMUNICATION}/${Communication.MAIL}/:id`,
                                                    `/${modules.COMMUNICATION}/${Communication.MAIL}`]}
                                                title="Communication / Mailbox"
                                                description="Show email by customer"
                                                exact
                                            >
                                                <Mail />
                                            </ProtectedRoute>
                                        )}
                                    </ProtectedRoute>
                                )}
                                <ProtectedRoute
                                    path={`/${modules.DASHBOARD}`}
                                    title="Dashboard"
                                    exact
                                >
                                    <Dashboard />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.CUSTOM_REPORTS}`}
                                    title="Custom Reports"
                                    exact
                                >
                                    <CustomReports />
                                </ProtectedRoute>
                                {CRM_CONVERSATION_READ && (
                                    <ProtectedRoute
                                        path={['/conversations/:id', '/conversations']}
                                        exact
                                    >
                                        <Conversation />
                                    </ProtectedRoute>
                                )}
                                {CRM_OPPORTUNITY_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.OPPORTUNITIES}`}
                                        title="Opportunities"
                                        description="Show opportunities list"
                                    >
                                        <OpportunityList />
                                        <Switch>
                                            <ProtectedRoute
                                                path={`/${modules.OPPORTUNITIES}/create`}
                                                title="Opportunity / Create"
                                                description="Create an opportunity"
                                                exact
                                            >
                                                <OpportunityCreate />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/${modules.OPPORTUNITIES}/:id`}
                                                title="Opportunity / Detail"
                                                description="Show opportunity detail"
                                                exact
                                            >
                                                <Opportunity />
                                            </ProtectedRoute>
                                        </Switch>
                                    </ProtectedRoute>
                                )}
                                {CRM_OPPORTUNITY_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.CALENDAR}`}
                                        title="Calendar"
                                        description="Show appointment by customer"
                                        exact
                                    >
                                        <Calendar />
                                    </ProtectedRoute>
                                )}
                                {CRM_OPPORTUNITY_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.FLOORLOG}`}
                                        title="Floor Log"
                                        description="Floor Log"
                                        exact
                                    >
                                        <OpportunityList />
                                    </ProtectedRoute>
                                )}
                                {SALES_DEAL_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.DEALS}/:deal/menu`}
                                        title="Menu"
                                        description="Do Menu"
                                        exact
                                    >
                                        <MenuMain />
                                    </ProtectedRoute>
                                )}
                                {SALES_DEAL_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.DEALS}`}
                                        title="Deals"
                                        description="Show Deals list"
                                    >
                                        <DealList />
                                        <Switch>
                                            {SALES_DEAL_WRITE && (
                                                <ProtectedRoute
                                                    path={`/${modules.DEALS}/create`}
                                                    title="Deals / Create"
                                                    description="Create a deal"
                                                    exact
                                                >
                                                    <DealCreate />
                                                </ProtectedRoute>
                                            )}

                                            <ProtectedRoute
                                                path={`/${modules.DEALS}/:id`}
                                                title="Deals / Detail"
                                                description="Deal detail"
                                                exact
                                            >
                                                <DealDetail />
                                            </ProtectedRoute>
                                        </Switch>
                                    </ProtectedRoute>
                                )}
                                {CUSTOMER_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.CUSTOMERS}`}
                                        title="Customers"
                                    >
                                        <CustomerList />
                                        <Switch>
                                            <ProtectedRoute
                                                path={`/${modules.CUSTOMERS}/create`}
                                                title="Customer / Create"
                                                exact
                                            >
                                                <CustomerCreate />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/${modules.CUSTOMERS}/:customerCode`}
                                                title="Customer / Detail"
                                                exact
                                            >
                                                <CustomerDetail />
                                            </ProtectedRoute>
                                        </Switch>
                                    </ProtectedRoute>
                                )}
                                <ProtectedRoute
                                    path={`/${modules.RULES}`}
                                    title="Rules"
                                    exact
                                >
                                    <BusinessIntelligencePanel />
                                </ProtectedRoute>
                                {INVENTORY_APPRAISALS_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.INVENTORY}/appraisals`}
                                        title="Appraisals"
                                    >
                                        <Appraisals />
                                    </ProtectedRoute>
                                )}
                                <ProtectedRoute
                                    path={`/${modules.INVENTORY}/payment-calls`}
                                    title="Payment Calls"
                                >
                                    <Desking />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.OTHER}/payment-calculator`}
                                    title="Payment Calculator"
                                >
                                    <PaymentCalculator />
                                </ProtectedRoute>
                                {INVENTORY_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.INVENTORY}`}
                                        title="Inventory"
                                    >
                                        <InventoryList />
                                        <Switch>
                                            {INVENTORY_VEHICLE_WRITE && (
                                                <ProtectedRoute
                                                    path={`/${modules.INVENTORY}/create`}
                                                    title="Inventory / Create"
                                                    exact
                                                >
                                                    <CreateForm />
                                                </ProtectedRoute>
                                            )}
                                            <ProtectedRoute
                                                path={`/${modules.INVENTORY}/:stockNumber`}
                                                title="Inventory / Detail"
                                                exact
                                            >
                                                <VehicleDetail />
                                            </ProtectedRoute>
                                        </Switch>
                                    </ProtectedRoute>
                                )}
                                <ProtectedRoute
                                    path={`/${modules.PREFERENCES}/:subtab?`}
                                    title="Preferences"
                                    exact
                                >
                                    <Preferences />
                                </ProtectedRoute>
                                {SETTINGS_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.SETTINGS}/:maintab?/:subtab?`}
                                        title="Settings"
                                        exact
                                    >
                                        <SettingsMain />
                                    </ProtectedRoute>
                                )}
                                {QUERIES_WRITE && (
                                    <ProtectedRoute
                                        path={`/${modules.QUERIES}`}
                                        title="Queries"
                                        exact
                                    >
                                        <QueriesBaseLayout />
                                    </ProtectedRoute>
                                )}
                                {ACCOUNTING_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.ACCOUNTING}/:submodule?/:entity?`}
                                        title="Accounting"
                                    >
                                        <AccountingLayout />
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}`
                                            + `/${AccountsReceivableEntities.CASHIER_OPEN_BALANCES}/check`}
                                            title="Accounting / Receivable"
                                            exact
                                        >
                                            <CheckCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/check/:id?`}
                                            title="Accounting / Banking / Check"
                                            exact
                                        >
                                            <CheckCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/deposit/:id?`}
                                            title="Accounting / Banking / Deposit"
                                            exact
                                        >
                                            <DepositCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/transfer/:id?`}
                                            title="Accounting / Banking / Transfer"
                                            exact
                                        >
                                            <TransferCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/approve-checks`}
                                            title="Accounting / Banking / Approve Checks"
                                            exact
                                        >
                                            <CheckActionList actionType={GeneralAction.APPROVE} />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/undo-approve-checks`}
                                            title="Accounting / Banking / Undo Approve Checks"
                                            exact
                                        >
                                            <CheckActionList actionType={GeneralAction.UNDO_APPROVE} />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}/print-checks`}
                                            title="Accounting / Banking / Print Checks"
                                            exact
                                        >
                                            <CheckActionList actionType={GeneralAction.PRINT} />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/:entity/assign/:id`}
                                            title="Accounting / Receivable / Assign"
                                            exact
                                        >
                                            <AssignPayment />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/:entity/payout/:id?`}
                                            title="Accounting / Receivable / Payout"
                                            exact
                                        >
                                            <CashierPaymentCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/:entity/payment/:id?`}
                                            title="Accounting / Receivable / Payment"
                                            exact
                                        >
                                            <CashierPaymentCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_RECEIVABLE}/:entity/:accountId/payment/:id?`}
                                            title="Accounting / Receivable / Payment"
                                            exact
                                        >
                                            <CashierPaymentCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.ACCOUNTING}/${AccountingSubModules.JOURNALS}/general/:id?`}
                                            title="Accounting / General Journal"
                                            exact
                                        >
                                            <GeneralJournalCreate />
                                        </ProtectedRoute>
                                    </ProtectedRoute>
                                )}
                                {PARTS_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.PARTS}/:submodule?/`}
                                        title="Parts"
                                    >
                                        <PurchasePartsLayout />
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/view/:id`}
                                            title="Parts / Purchase Order"
                                            exact
                                        >
                                            <PurchaseOrderCreate isView />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/return-from-purchase/:po`}
                                            title="Parts / Purchase Order / Return"
                                            exact
                                        >
                                            <PurchaseOrderCreate isReturn />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/return/:id?`}
                                            title="Parts / Purchase Order / Return"
                                            exact
                                        >
                                            <PurchaseOrderCreate isReturn />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/create`}
                                            title="Parts / Purchase Order"
                                            exact
                                        >
                                            <PurchaseOrderCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/edit/:id`}
                                            title="Parts / Purchase Order"
                                            exact
                                        >
                                            <PurchaseOrderCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/approve-purchases`}
                                            title="Parts / Purchase Order / Approve"
                                            exact
                                        >
                                            <PurchaseActionList actionType={GeneralAction.APPROVE} />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PURCHASE_ORDER}/print-purchases`}
                                            title="Parts / Purchase Order / Print"
                                            exact
                                        >
                                            <PurchaseActionList actionType={GeneralAction.PRINT} />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/create`}
                                            title="Parts / Create"
                                            exact
                                        >
                                            <PartsInventoryCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/edit/:id`}
                                            title="Parts / Edit"
                                            exact
                                        >
                                            <PartsInventoryCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PARTS_TICKET}/create`}
                                            title="Parts / Ticket / Create"
                                            exact
                                        >
                                            <PartsTicketCreate />
                                        </ProtectedRoute>
                                        <ProtectedRoute
                                            path={`/${modules.PARTS}/${PartsPurchaseSubModules.PARTS_TICKET}/edit/:id`}
                                            title="Parts / Ticket / Edit"
                                            exact
                                        >
                                            <PartsTicketCreate />
                                        </ProtectedRoute>
                                    </ProtectedRoute>
                                )}
                                {SERVICE_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.SERVICE}/:submodule?/`}
                                        title="Service"
                                    >
                                        <ServiceLayout />
                                        {SERVICE_OPEN && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/customer/create`}
                                                title="New Customer RO"
                                                exact
                                            >
                                                <ServiceInvoiceCreate />
                                            </ProtectedRoute>
                                        ) }
                                        {SERVICE_EDIT && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/customer/edit/:id`}
                                                title="Edit Customer RO"
                                                exact
                                            >
                                                <ServiceInvoiceCreate />
                                            </ProtectedRoute>
                                        )}
                                        {SERVICE_OPEN && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/internal/create`}
                                                title="New Internal RO"
                                                exact
                                            >
                                                <ServiceInvoiceCreate isInternal />
                                            </ProtectedRoute>
                                        )}
                                        {SERVICE_EDIT && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.REPAIRS_ORDERS}/internal/edit/:id`}
                                                title="Edit Internal RO"
                                                exact
                                            >
                                                <ServiceInvoiceCreate isInternal />
                                            </ProtectedRoute>
                                        )}
                                        {SERVICE_OPEN && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.OPERATION_CODES}/create`}
                                                title="New Op Code"
                                                exact
                                            >
                                                <OperationCodeCreate />
                                            </ProtectedRoute>
                                        ) }
                                        {SERVICE_EDIT && (
                                            <ProtectedRoute
                                                path={`/${modules.SERVICE}/${ServiceSubModules.OPERATION_CODES}/edit/:id`}
                                                title="Edit Op Code"
                                                exact
                                            >
                                                <OperationCodeCreate />
                                            </ProtectedRoute>
                                        )}
                                    </ProtectedRoute>
                                )}
                                {PAYROLL_READ && (
                                    <ProtectedRoute
                                        path={`/${modules.PAYROLL}/:subtab?`}
                                        title="Payroll"
                                        exact
                                    >
                                        <PayrollMain />
                                    </ProtectedRoute>
                                )}
                                <ProtectedRoute
                                    path={`/${modules.REPORTS}/recon`}
                                    title="Reports"
                                    exact
                                >
                                    <Recon />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.REPORTS}/appraisal-history`}
                                    title="Reports"
                                    exact
                                >
                                    <AppraisalHistory />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.REPORTS}/sales-tax`}
                                    title="Reports"
                                    exact
                                >
                                    <SalesTax />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.REPORTS}/inventory-tax`}
                                    title="Reports"
                                    exact
                                >
                                    <InventoryTax />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path={`/${modules.TRAINING}`}
                                    title="Training"
                                    exact
                                >
                                    <TrainingVideos />
                                </ProtectedRoute>
                                <RedirectNotFound />
                            </Switch>
                        </BaseLayout>
                    </Route>
                </Switch>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                />
            </Router>
        </UserContext.Provider>
    );
};

export default Sentry.withProfiler(App);

import { map, findIndex, isEmpty } from 'lodash';

/**
 * @TODO The fieldInformation property for schema of type array is not supported
 * @Note Currently only the first node is returning
 */
const getFieldsMeta = (schema) => {
    const fieldsInformation = {};
    if (!isEmpty(schema)) {
        const fields = schema.type === 'object' ? schema.fields : schema.innerType.fields;
        const keyFields = Object.keys(fields);

        keyFields.forEach((item) => {
            fieldsInformation[item] = {
                isRequired: fields[item]?._exclusive.required || false,
            };
        });
    }

    return fieldsInformation;
};

/**
 * @param {*} schema
 * @param {*} record
 * @information In the next improvement, send the errors in each field to not use the isValidField function
 */
const isValidSchema = (schema, record) => {
    try {
        schema.validateSync(record, { abortEarly: false, strict: true });
        return {
            isValid: true,
            errors: [],
            fields: getFieldsMeta(schema),
        };
    } catch (err) {
        const mapError = map(err?.inner || [], (item) => ({
            field: item.path,
            message: item.message,
        }));

        return {
            isValid: (err?.inner || []).length === 0,
            errors: mapError,
            fields: getFieldsMeta(schema),
        };
    }
};

const isValidField = (errors = [], field) => {
    const index = findIndex(errors, { field });
    return index > -1;
};

const getErrorMessage = (field, errors) => errors.find((c) => c.field === field)?.message || '';

export {
    isValidSchema,
    isValidField,
    getErrorMessage,
};

import React, { Component } from 'react';

// Components and Other
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Materia UI
import { Toolbar, AppBar, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 50,
        flexWrap: 'wrap',
    },
    showDivider: {
        borderBottom: `1px solid${theme.palette.divider}`,
    },
});

class Header extends Component {
    render() {
        const { props: { classes, divider, className }, props } = this;
        const currenDivider = divider ? classes.showDivider : '';

        return (
            <AppBar
                elevation={0}
                color="inherit"
                position="relative"
                className={clsx(classes.appBar, currenDivider, className)}
            >
                <Toolbar className={classes.toolbar}>
                    <Box
                        display="flex"
                        alignItems="center"
                        flex={1}
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        {props.children}
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }
}

Header.propTypes = {
    divider: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Header.defaultProps = {
    divider: false,
    className: null,
};

export default withStyles(styles)(Header);

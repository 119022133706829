import HttpClientModule from 'services/httpModules/HttpClientModule';

export const URL = {
    CREATE_TAG: '/vehicles/~stockNumber~/tags',
    DELETE_TAG: '/vehicles/~stockNumber~/tags?tagId=~tagId~',
    GET_VEHICLE_TAGS: '/vehicles/~stockNumber~/tags',
};

export default class HttpInventoryModule {
    constructor() {
        this.http = new HttpClientModule();
    }

    async createTag(stockNumber, data = {}) {
        const url = URL.CREATE_TAG.replace('~stockNumber~', stockNumber);

        const response = await this.http.PUT(url, data);

        return response;
    }

    async deleteTag(stockNumber, tagId) {
        let url = URL.DELETE_TAG;
        url = url.replace('~stockNumber~', stockNumber);
        url = url.replace('~tagId~', tagId);

        const response = await this.http.DELETE(url);

        return response;
    }

    async getAssignedTags(stockNumber = 0) {
        let url = URL.GET_VEHICLE_TAGS;
        url = url.replace('~stockNumber~', stockNumber);

        const response = await this.http.GET(url);

        return response;
    }
}

import ButtonStyles from 'styles/theme/Button';

export default class ActivitiesStyles {
    static styles(theme = {}) {
        return ({
            scheduleIcon: {
                '& g': {
                    stroke: theme.palette.info.main,
                },
            },
            missedIcon: {
                '& g': {
                    stroke: theme.palette.error.main,
                },
            },
            outgoingIcon: {
                '& g': {
                    stroke: theme.palette.success.main,
                },
            },
            taskIcon: {
                color: theme.palette.info.main,
            },
            boxContentIcon: {
                margin: theme.spacing(1),
                width: 24,
                textAlign: 'center',
            },
            boxUserName: {
                display: 'flex',
                alignItem: 'center',
                '& span': {
                    fontSize: '14px',
                    marginRight: theme.spacing(0.6),
                },
            },
            userName: {
                fontWeight: 'bold',
            },
        });
    }

    static activitiesForm(theme = {}) {
        return ({
            radioInfo: {
                color: theme.palette.info.main,
                '&.Mui-checked': {
                    color: theme.palette.info.main,
                },
            },
            avatarSmall: {
                width: theme.spacing(3),
                height: theme.spacing(3),
                marginRight: theme.spacing(1.5),
            },
            switchButton: {
                marginBottom: theme.spacing(2),
            },
            dialog: {
                maxWidth: 350,
            },
            formControlLabel: {
                marginRight: 0,
                '& > span:nth-child(2)': {
                    fontSize: '16px',
                },
            },
            loading: {
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 9,
                background: theme.palette.background.white,
            },
            noteLabel: {
                color: theme.palette.text.gray,
                fontSize: '14px',
            },
        });
    }

    static activityContent(theme = {}) {
        return ({
            dialogTitle: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(1),
            },
            dialogContent: {
                padding: theme.spacing(2),
            },
            ...ButtonStyles.getStyle(theme),
        });
    }
}

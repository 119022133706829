import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CalendarContainer from 'components/widgets/form/CalendarContainer';

const useStyles = makeStyles(() => ({
    dateFieldPosition: {
        display: 'table',
        marginTop: '15px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const NachaEffectiveDate = (props) => {
    const {
        onCloseDialog,
    } = props;
    const classes = useStyles();

    const [effectiveDate, setEffectiveDate] = useState(new Date());

    return (
        <ConfirmDialog
            description="Select the effective date which the amounts would be withdraw from your bank account."
            open
            variant="outlined"
            titlePrimary="Ok"
            titleSecondary="Cancel"
            disablePrimaryButton={!effectiveDate}
            onClose={() => onCloseDialog()}
            onClickSecondary={() => onCloseDialog()}
            onClickPrimary={() => onCloseDialog(effectiveDate)}
        >
            <div className={classes.dateFieldPosition}>
                <DatePicker
                    selected={effectiveDate}
                    name="fromDate"
                    size="sm"
                    className={`form-control form-control-sm ${!effectiveDate ? 'invalid-field' : ''}`}
                    placeholderText="mm/dd/yyyy"
                    popperContainer={CalendarContainer}
                    onChange={(date) => setEffectiveDate(date)}
                />
            </div>
        </ConfirmDialog>
    );
};

NachaEffectiveDate.propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
};

export default NachaEffectiveDate;

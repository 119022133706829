import { gql } from '@apollo/client';

export default class EmailEventQuery {
    static GET_STATUS_EMAIL_BY_EMAIL_ID = gql`
        query getStatusEmailByEmailId($emailId: ID!) {
            getStatusEmailByEmailId(emailId: $emailId) {
                data
                emailEventId
            }
        }
    `;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));
const CustomFieldsPanel = ({
    data,
}) => {
    const classes = useStyles();

    const renderDisplayField = (item) => (
        <DisplayField
            key={item.entityMetadataId}
            field={item.label}
            type={item.type}
            value={item.value}
            options={item.options}
        />
    );

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            {data.map((item) => renderDisplayField(item))}
        </Grid>
    );
};

CustomFieldsPanel.propTypes = {
    data: PropTypes.array,
};

CustomFieldsPanel.defaultProps = {
    data: [],
};

export default CustomFieldsPanel;

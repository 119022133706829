import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import Placeholder from 'components/widgets/Placeholder';

// Components and Others
import PropTypes from 'prop-types';
import OpportunityWidgetItem from 'components/widgets/crm/OpportunityWidgetItem';

// HTTP
import { useQuery } from '@apollo/client';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

// Helpers
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyle = makeStyles(() => ({
    widget: {
        marginBottom: '20px',
    },
    container: {
        overflow: 'auto',
    },
}));

const OpportunityWidget = ({ customerId }) => {
    const classes = useStyle();
    const [records, setRecords] = useState([]);
    const { data, loading, error } = useQuery(OpportunityQuery.GET_OPPORTUNITIES_BY_CUSTOMER_ID, {
        variables: { customerId },
        skip: (customerId == null),
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        setRecords([]);
    }, [customerId]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getOpportunitiesByCustomerId) {
            setRecords(data?.getOpportunitiesByCustomerId);
        }
    }, [data, loading, error]);

    return (
        <BoxWidget
            title={`Opportunities (${records.length})`}
            className={classes.widget}
            minHeight="auto"
        >
            {records.length > 0 ? (
                <div className={classes.container}>
                    {records.map((record, index) => <OpportunityWidgetItem key={index} {...record} openNewTab />)}
                </div>
            ) : <Placeholder text="No Opportunities" />}
        </BoxWidget>
    );
};

OpportunityWidget.defaultProps = {
    customerId: null,
};

OpportunityWidget.propTypes = {
    customerId: PropTypes.string,
};

export default OpportunityWidget;

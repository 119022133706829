import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as SMSIcon } from 'assets/crm/sms.svg';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    containerSMSNotification: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#fff',
    },
    smsNotificationHeader: {
        fontWeight: 'bold',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& svg g path': {
            fill: '#fff',
        },
    },
    smsNotificationBody: {
        fontSize: '13px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
}));

const SMSNotification = ({ record }) => {
    const classes = useStyles();
    const { message, opportunity = {}, processRecordId } = record;
    const { leadCode, prospect = {} } = opportunity;
    const fullName = isEmpty(prospect) ? '' : `${prospect.firstName || ''} ${prospect.lastName || ''}:`;

    return (
        <RouterLink
            to={`/${modules.OPPORTUNITIES}/${processRecordId}`}
            className={classes.containerSMSNotification}
        >
            <span className={classes.smsNotificationHeader}>
                <SMSIcon />
                {`New SMS from Opp Code: ${leadCode}`}
            </span>
            <span className={classes.smsNotificationBody}>{`${fullName} ${message}`}</span>
        </RouterLink>
    );
};

SMSNotification.propTypes = {
    record: PropTypes.object.isRequired,
};

export default SMSNotification;

import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { TYPE } from 'components/layout/NotificationBadge';
import { useMutation } from '@apollo/client';
import UserNotificationMutation from 'services/graphQL/mutate/core/UserNotificationMutation';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import NotificationHelpers from './NotificationHelpers';

const useStyles = makeStyles((theme) => ({
    containerEmailNotification: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#fff',
    },
    emailNotificationHeader: {
        fontWeight: 'bold',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '& svg g path': {
            fill: '#fff',
        },
    },
    emailNotificationBody: {
        fontSize: '13px',
        display: '-webkit-box',
        maxWidth: '100%',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    icon: {
        fontSize: '19px',
    },
}));

const ToastNotification = ({ record }) => {
    const classes = useStyles();
    const history = useHistory();
    const generalInformation = JSON.parse(record?.data) || {};
    const { customerName } = generalInformation;
    const fullName = isEmpty(generalInformation) ? '' : `${customerName}`;
    const notification = NotificationHelpers.getNotificationTypeIcon(record.type);
    const realizationDate = DateUtils.format(generalInformation?.realizationDate, DateFormat.DATETIME_WITHOUT_SECONDS);
    const leadCode = generalInformation.opportunityCode || '';
    const [markUserNotificationAsRead] = useMutation(UserNotificationMutation.MARK_USER_NOTIFICATION_AS_READ_BY_NOTIFICATION_ID,
        { variables: { type: TYPE.NOTIFICATION } });

    const onClick = async () => {
        try {
            if (record) await markUserNotificationAsRead({ variables: { notificationId: record.notificationId } });
            history.push(`/${modules.OPPORTUNITIES}/${generalInformation.opportunityId}`, {
                type: record.type,
                ...record,
                data: { ...generalInformation },
            });
        } catch (ex) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('updating', 'Notification'));
        }
    };

    return (
        <div
            onClick={onClick}
            className={classes.containerEmailNotification}
        >
            <span className={classes.emailNotificationHeader}>
                {notification.icon}
                {`New ${notification.type} from Opp Code: ${leadCode}`}
            </span>
            <span className={classes.emailNotificationBody}>{`The ${notification.activityName} with ${fullName} start at ${realizationDate}`}</span>
        </div>
    );
};

ToastNotification.propTypes = {
    record: PropTypes.object.isRequired,
};

export default ToastNotification;

import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

// Material UI
import {
    makeStyles,
    CircularProgress,
    TextField,
    Tooltip,
} from '@material-ui/core';

import { useMutation } from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

const useStyles = makeStyles(InventoryListStyle.quickNoteEditable());

const QuickNoteEditable = (props) => {
    const keyStore = new KeyStore();
    const classes = useStyles();
    const {
        stockNumber,
        quickNote: currentQuickNote,
        index,
        enableRow,
        editable,
        onChangeQuickNote,
    } = props;
    const [updateQuickNote, { loading }] = useMutation(InventoryMutation.UPDATE_VEHICLE_QUICKNOTE);
    const onChange = async (stock, quickNote) => {
        const newQuickNote = trim(quickNote);

        if (newQuickNote !== currentQuickNote) {
            const variables = {
                stockNumber: stock,
                quickNote: newQuickNote,
            };
            try {
                const response = await updateQuickNote({ variables });

                if (response?.data?.updateVehicleQuickNote) {
                    onChangeQuickNote(index, newQuickNote);
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
                }
            } catch (error) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Quick Note'));
            }
        } else {
            enableRow(index);
        }
    };
    const keyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();

            onChange(stockNumber, event.target.value);
        }
    };
    const vehicleWrite = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    if (vehicleWrite && editable) {
        return (
            <div className={classes.content}>
                {loading && (
                    <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                    />
                )}
                {<TextField
                    className={classes.input}
                    variant="outlined"
                    size="small"
                    onBlur={(event) => onChange(stockNumber, event.target.value)}
                    onKeyDown={keyPress}
                    defaultValue={currentQuickNote}
                    autoFocus
                    disabled={loading}
                />}
            </div>
        );
    }

    return (
        <Tooltip placement="bottom" title={currentQuickNote}>
            <span className={classes.truncate}>{currentQuickNote}</span>
        </Tooltip>
    );
};

QuickNoteEditable.propTypes = {
    index: PropTypes.number.isRequired,
    stockNumber: PropTypes.number.isRequired,
    quickNote: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    enableRow: PropTypes.func.isRequired,
    onChangeQuickNote: PropTypes.func.isRequired,
};

QuickNoteEditable.defaultProps = {
    quickNote: '',
};

export default QuickNoteEditable;

import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';

import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));

const BookValuePanel = ({ data }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.labelsGrid}
        >
            <Grid item xs={12} sm={12}>
                <Grid container>
                    <Grid item xs={4} sm={4} />
                    <Grid item xs={4} sm={4}>
                        <FormLabel className={classes.slashedPrice}>
                            <span>RETAIL</span>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormLabel className={classes.slashedPrice}>
                            <span>TRADE-IN</span>
                        </FormLabel>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Grid container className={classes.topSpace}>
                    <Grid item xs={4} sm={4}>
                        <FormLabel className={classes.slashedPrice}>
                            <span>At Purchase:</span>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <DisplayField
                            field="Retail"
                            displayFieldName={false}
                            value={data.nadaRetail}
                            type="money"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <DisplayField
                            field="Trade In"
                            displayFieldName={false}
                            value={data.nadaTrade}
                            type="money"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

BookValuePanel.propTypes = {
    data: PropTypes.object,
};

BookValuePanel.defaultProps = {
    data: {},
};

export default BookValuePanel;

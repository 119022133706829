import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import numeral from 'numeral';
import Select from 'components/widgets/Select';
import PropTypes from 'prop-types';
import DealUtils from 'utils/DealUtils';
import { Form, Col, Row } from 'react-bootstrap';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import InputNumberWithButton from 'components/widgets/InputNumberWithButton';
import DeferredDownPaymentDialog from 'components/modules/deals/create/dealTab/DeferredDownPaymentDialog';

import { isValidField } from 'utils/schema/utils';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DealType, FinanceDefaultCalculation } from 'utils/enum/DealEnum';

const styles = (theme) => DealStyles.dealCreate(theme, fade);

class DealStructureDealCreate extends Component {
    render() {
        const {
            props: {
                classes, openDeferredDownPayment, setDeferredDownPayment, vendorList, userAvailableLots, portfolioList,
                dealStructure, deferredDownPayment, onChangeValueDealStructure, onChangeDealType,
                onCloseDeferredDownPayment, onOpenDeferredDownPayment, vehicle, isValidSalesPrice, formValidations,
            },
        } = this;
        const { minimumSalePrice } = vehicle;
        const {
            totalDeferredDownPayment, dealType, cashDownPayment, salesPrice,
            financeCompany, lotName, portfolioId, defaultFinanceCalculation, term, paymentAmount,
        } = dealStructure;
        const feedbackMessage = `Price can't be less than ${numeral(minimumSalePrice).format('0,0.00')}`;
        const { errors } = formValidations;

        const isLease = dealType === DealType.LEASE || dealType === DealType.LHPH;

        return (
            <div className="box-shadow flex-1">
                <Typography variant="h5" className={classes.title}>Deal Structure</Typography>
                <Form className={clsx(classes.formDealStructure, classes.formW100)} noValidate>
                    <Form.Group as={Row}>
                        <Form.Label column sm="12" xl="5" className={classes.labelColor}>Deal Type</Form.Label>
                        <Col sm="12" xl="7">
                            <Select
                                size="sm"
                                name="dealType"
                                placeholder="Select"
                                value={dealType || ''}
                                options={DealUtils.getDealType()}
                                onChange={(e, value) => onChangeDealType(value)}
                                className={clsx('select-bootstrap', isValidField(errors, 'dealType') ? 'invalid-field' : '')}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="12" xl="5" className={classes.labelColor}>Lot</Form.Label>
                        <Col sm="12" xl="7">
                            <Select
                                size="sm"
                                name="lotName"
                                placeholder="Select"
                                value={lotName || ''}
                                options={userAvailableLots}
                                onChange={(e, value) => onChangeValueDealStructure('lotName', value)}
                                className={clsx('select-bootstrap', isValidField(errors, 'lotName') ? 'invalid-field' : '')}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="full-width-date-picker">
                        <Form.Label column sm="12" xl="5" className={classes.labelColor}>{isLease ? 'Cap Cost Reduction' : 'Cash Down Payment'}</Form.Label>
                        <Col sm="12" xl="7">
                            <InputNumber
                                size="sm"
                                showCurrency
                                placeholder="0"
                                thousandSeparator
                                value={cashDownPayment || ''}
                                onChange={(value) => onChangeValueDealStructure('cashDownPayment', value)}
                                className={isValidField(errors, 'cashDownPayment') ? 'invalid-field' : ''}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="full-width-date-picker">
                        <Form.Label column sm="12" xl="5" className={classes.labelColor}>Price</Form.Label>
                        <Col sm="12" xl="7">
                            <InputNumber
                                size="sm"
                                className={
                                    clsx(
                                        isValidField(errors, 'vehicle.stockNumber') ? 'invalid-field' : '',
                                        isValidField(errors, 'salesPrice') ? 'invalid-field' : '',
                                    )
                                }
                                showCurrency
                                placeholder="0"
                                thousandSeparator
                                value={salesPrice || ''}
                                onChange={(value) => onChangeValueDealStructure('salesPrice', value)}
                            />
                            { !!vehicle && !!vehicle.minimumSalePrice && !!salesPrice && !isValidSalesPrice && (
                                <div className="text-danger">
                                    {feedbackMessage}
                                </div>
                            )}
                        </Col>
                    </Form.Group>
                    {(!isLease) && (
                        <>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Deferred Down Payment</Form.Label>
                                <Col sm="12" xl="7">
                                    <InputNumberWithButton
                                        showCurrency
                                        size="sm"
                                        placeholder="0.00"
                                        thousandSeparator
                                        onClick={onOpenDeferredDownPayment}
                                        value={totalDeferredDownPayment || ''}
                                        className={isValidField(errors, 'totalDeferredDownPayment') ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                        </>
                    )}
                    {(dealType === DealType.FINANCE || dealType === DealType.OUTSIDE_FINANCE || dealType === DealType.BHPH || isLease) && (
                        <>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={classes.labelColor}>Lender</Form.Label>
                                <Col sm="12" xl="7">
                                    <Select
                                        size="sm"
                                        name="financeCompany"
                                        placeholder="Select"
                                        value={financeCompany || ''}
                                        options={vendorList}
                                        onChange={onChangeValueDealStructure}
                                        className={clsx('select-bootstrap', isValidField(errors, 'financeCompany') ? 'invalid-field' : '')}
                                    />
                                </Col>
                            </Form.Group>
                        </>
                    )}
                    <Form.Group as={Row}>
                        <Form.Label column sm="12" xl="5" className={classes.labelColor}>Portfolio</Form.Label>
                        <Col sm="12" xl="7">
                            <Select
                                size="sm"
                                name="portfolioId"
                                placeholder="Select"
                                value={portfolioId || ''}
                                options={portfolioList}
                                onChange={onChangeValueDealStructure}
                                className={clsx('select-bootstrap', isValidField(errors, 'portfolioId') ? 'invalid-field' : '')}
                            />
                        </Col>
                    </Form.Group>
                    {(dealType === DealType.FINANCE || dealType === DealType.BHPH || dealType === DealType.OUTSIDE_FINANCE) && (
                        <>
                            <Form.Group as={Row}>
                                <Form.Label column sm="12" xl="5" className={clsx(classes.labelColor, classes.defaultCalculationDropdown)}>
                                    <Select
                                        size="sm"
                                        name="defaultFinanceCalculation"
                                        placeholder="Select"
                                        value={defaultFinanceCalculation}
                                        options={DealUtils.getFinanceDefaultCalculation()}
                                        onChange={onChangeValueDealStructure}
                                    />
                                </Form.Label>
                                <Col sm="12" xl="7">
                                    {(defaultFinanceCalculation === FinanceDefaultCalculation.TERM.toLowerCase()) && (
                                        <InputNumber
                                            size="sm"
                                            className={
                                                clsx(
                                                    isValidField(errors, 'term') ? 'invalid-field' : '',
                                                )
                                            }
                                            placeholder="0"
                                            thousandSeparator
                                            value={term || ''}
                                            onChange={(value) => onChangeValueDealStructure('term', value)}
                                        />
                                    )}
                                    {(defaultFinanceCalculation !== FinanceDefaultCalculation.TERM.toLowerCase()) && (
                                        <InputNumber
                                            size="sm"
                                            className={
                                                clsx(
                                                    isValidField(errors, 'paymentAmount') ? 'invalid-field' : '',
                                                )
                                            }
                                            showCurrency
                                            placeholder="0"
                                            thousandSeparator
                                            value={paymentAmount || ''}
                                            onChange={(value) => onChangeValueDealStructure('paymentAmount', value)}
                                        />
                                    )}
                                </Col>
                            </Form.Group>
                        </>
                    )}
                </Form>
                {openDeferredDownPayment && (
                    <DeferredDownPaymentDialog
                        open={openDeferredDownPayment}
                        toggleModal={onCloseDeferredDownPayment}
                        deferredPayments={deferredDownPayment}
                        setDeferredDownPayment={setDeferredDownPayment}
                    />
                )}
            </div>
        );
    }
}

DealStructureDealCreate.propTypes = {
    dealStructure: PropTypes.shape({
        dealType: PropTypes.string,
        lotName: PropTypes.string,
        salesPrice: PropTypes.number,
        financeCompany: PropTypes.string,
        cashDownPayment: PropTypes.number,
        totalDeferredDownPayment: PropTypes.number,
        portfolioId: PropTypes.number,
        defaultFinanceCalculation: PropTypes.string,
        term: PropTypes.number,
        paymentAmount: PropTypes.number,
    }).isRequired,
    openDeferredDownPayment: PropTypes.bool.isRequired,
    onChangeValueDealStructure: PropTypes.func.isRequired,
    setDeferredDownPayment: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    deferredDownPayment: PropTypes.arrayOf(PropTypes.object).isRequired,
    onOpenDeferredDownPayment: PropTypes.func.isRequired,
    onCloseDeferredDownPayment: PropTypes.func.isRequired,
    vehicle: PropTypes.shape({
        minimumSalePrice: PropTypes.number,
    }).isRequired,
    isValidSalesPrice: PropTypes.bool.isRequired,
    formValidations: PropTypes.object.isRequired,
    vendorList: PropTypes.array.isRequired,
    userAvailableLots: PropTypes.array.isRequired,
    onChangeDealType: PropTypes.func.isRequired,
    portfolioList: PropTypes.array.isRequired,
};

export default withStyles(styles)(DealStructureDealCreate);

import { gql } from '@apollo/client';

export default class GeneralMutation {
    static UPLOAD_COMMON_RESOURCE = gql`
        mutation uploadCommonResource(
            $module: String!,
            $section: String!,
            $bucket: String!,
            $resourceName: String!,
            $file: Upload!
        ) {
            uploadCommonResource(
                module: $module,
                section: $section,
                bucket: $bucket,
                resourceName: $resourceName,
                file: $file
            )
        }
    `;

    static DELETE_COMMON_RESOURCE = gql`
        mutation deleteCommonResource(
            $commonResourceId: ID!
        ) {
            deleteCommonResource(
                commonResourceId: $commonResourceId
            )
        }
    `;

    static ADD_TRAINING_CATEGORY = gql`
        mutation addTrainingCategory(
            $name: String!,
            $order: Int!
        ) {
            addTrainingCategory(
                name: $name,
                order: $order
            )
        }
    `;

    static EDIT_TRAINING_CATEGORY = gql`
        mutation editTrainingCategory(
            $trainingCategoryId: ID!,
            $name: String!,
            $active: Boolean!
        ) {
            editTrainingCategory(
                trainingCategoryId: $trainingCategoryId,
                name: $name,
                active: $active
            )
        }
    `;

    static ADD_TRAINING_VIDEO = gql`
        mutation addTrainingVideo(
            $trainingCategoryId: ID!,
            $name: String!,
            $description: String,
            $videoLink: String!,
            $matchingRoute: String,
            $order: Int!
        ) {
            addTrainingVideo(
                trainingCategoryId: $trainingCategoryId,
                name: $name,
                description: $description,
                videoLink: $videoLink,
                matchingRoute: $matchingRoute,
                order: $order
            )
        }
    `;

    static EDIT_TRAINING_VIDEO = gql`
        mutation editTrainingVideo(
            $trainingVideoId: ID!,
            $trainingCategoryId: ID!,
            $name: String!,
            $description: String,
            $videoLink: String!,
            $matchingRoute: String,
            $active: Boolean!
        ) {
            editTrainingVideo(
                trainingVideoId: $trainingVideoId,
                trainingCategoryId: $trainingCategoryId,
                name: $name,
                description: $description,
                videoLink: $videoLink,
                matchingRoute: $matchingRoute,
                active: $active
            )
        }
    `;

    static SET_TRAINING_CATEGORIES_ORDER = gql`
        mutation setTrainingCategoriesOrder(
            $input: [TrainingCategoryOrder!]!
        ) {
            setTrainingCategoriesOrder(
                input: $input
            )
        }
    `;

    static SET_TRAINING_VIDEOS_ORDER = gql`
        mutation setTrainingVideosOrder(
            $input: [TrainingVideoOrder!]!
        ) {
            setTrainingVideosOrder(
                input: $input
            )
        }
    `;
}

export default class InventoryDetailStyle {
    static leftPanel(theme) {
        return ({
            leftPanel: {
                display: 'flex',
                flexDirection: 'column',
                witdh: '100%',
                overflow: 'hidden',
                [theme.breakpoints.up('md')]: {
                    '& > div:last-child': {
                        padding: 10,
                    },
                },
            },
            hideDetails: {
                display: 'none',
            },
            basicInfo: {
                padding: '10px 0',
                [theme.breakpoints.up('md')]: {
                    marginBottom: '15px',
                },
            },
            purchasedInfo: {
                display: 'flex',
                flexDirection: 'column',
                padding: '0 15px',
            },
            displayField: {
                color: theme.palette.text.darkBlue,
                marginRight: 5,
                minWidth: 115,
            },
            row: {
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 5,
                '& > span': {
                    color: theme.palette.text.doveGray,
                },
            },
            title: {
                marginRight: 5,
                padding: 0,
            },
            milesRow: {
                borderTop: `1px solid ${theme.palette.border.mercuryLight}`,
                borderBottom: `1px solid ${theme.palette.border.mercuryLight}`,
                display: 'flex',
                fontSize: 14,
                marginBottom: 5,
                marginTop: 5,
                '& > div': {
                    borderRight: `1px solid ${theme.palette.border.mercuryLight}`,
                    padding: 5,
                },
                '& img': {
                    marginLeft: 5,
                    maxHeight: 16,
                    verticalAlign: 'middle',
                },
                '& > div:last-child': {
                    border: 0,
                },
            },
            labels: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.text.rhino,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
                lineHeight: 1,
                '& p': {
                    marginBottom: 5,
                },
            },
            imageContain: {
                maxWidth: '310px',
                maxHeight: '300px',
                minHeight: '200px',
                position: 'relative',
                overflow: 'hidden',
            },
            editButton: {
                position: 'absolute',
                top: 0,
                right: 0,
            },
            externalPages: {
                border: `1px solid ${theme.palette.border.mercuryLight}`,
                borderRadius: '4px',
                padding: '5px',
                marginLeft: '10px',
                marginRight: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    '& > button:nth-child(1), & > button:nth-child(2)': {
                        '& img': {
                            width: '60%',
                            [theme.breakpoints.down('lg')]: {
                                width: '80%',
                            },
                        },
                    },
                },
                '& > button': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    width: '50%',
                    '& > span': {
                        fontSize: '11px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& > img': {
                            width: '90%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            },
                        },
                    },
                },
            },
            carfaxButton: {
                maxHeight: 50,
            },
        });
    }

    static dealBanner(theme) {
        return ({
            headerBanner: {
                transitionDelay: '4s',
                transitionTimingFunction: 'ease-in',
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                },
                '& > label': {
                    color: theme.palette.text.tuna,
                    fontSize: 16,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                        marginBottom: '5px',
                    },
                    '& > span': {
                        fontWeight: 'bold',
                        marginRight: 5,
                    },
                },
            },
            yellowBanner: {
                backgroundColor: theme.palette.background.yellow,
                justifyContent: 'center',

                '& > label': {
                    fontWeight: 'bold',
                },
            },
            greenBanner: {
                backgroundColor: theme.palette.background.malachite,
                justifyContent: 'flex-start',
                '& > label': {
                    marginRight: 40,
                },
            },
        });
    }

    static detailPanels(theme) {
        return ({
            contentValue: {
                alignItems: 'center',
                color: theme.palette.text.tundora,
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 7,
                '& > *': {
                    marginLeft: 3,
                    padding: 0,
                },
            },
            labelsGrid: {
                padding: '5px 10px',
            },
            topSpace: {
                paddingTop: '6px',
            },
            title: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                '& > span': {
                    padding: 0,
                },
                '& > div.title-number': {
                    marginRight: '5px',
                },
            },
            purchasedFrom: {
                display: 'flex',
                alignItems: 'center',
            },
            purchasedPrice: {
                paddingTop: '6px',
            },
            slashedPrice: {
                alignItems: 'center',
                color: theme.palette.text.tundora,
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                '& > span': {
                    color: theme.palette.text.darkBlue,
                    fontWeight: 500,
                    marginRight: 5,
                    width: '130px',
                    wordBreak: 'break-word',
                },
                '& > div': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                '& > div > span': {
                    padding: '0px',
                    paddingLeft: '5px',
                },
            },
            labelRow: {
                alignItems: 'center',
                color: theme.palette.text.tundora,
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 7,
                '& > span': {
                    color: theme.palette.text.darkBlue,
                    fontWeight: 500,
                    marginRight: 5,
                    minWidth: '130px',
                    wordBreak: 'break-word',
                },
                '& > div > span': {
                    paddingLeft: '0px',
                },
                '& > svg': {
                    marginRight: 5,
                    fontSize: 14,
                },
            },
        });
    }

    static displayField(theme) {
        return ({
            labelRow: {
                alignItems: 'center',
                color: theme.palette.text.tundora,
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 7,
                '& > span': {
                    color: theme.palette.text.darkBlue,
                    fontWeight: 500,
                    marginRight: 5,
                    minWidth: 138,
                },
                '& > svg': {
                    marginRight: 5,
                    fontSize: 14,
                },
            },
            bold: {
                fontWeight: 'bold',
            },
            redColor: {
                color: theme.palette.text.redBerry,
            },
            greenColor: {
                color: theme.palette.text.camarone,
            },
        });
    }
}

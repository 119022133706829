import printJS from 'print-js';

export default class PrintingUtils {
    static sendHTMLElementToPrintingDialog = (element, width, customStyle) => {
        const wrapper = document.createElement('div');
        wrapper.style.display = 'none';
        document.body.appendChild(wrapper);

        const cloneElement = document.getElementById(element).cloneNode(true);
        cloneElement.id = 'printableElement';
        if (customStyle) cloneElement.insertAdjacentHTML('afterbegin', customStyle);

        wrapper.appendChild(cloneElement);
        printJS({
            printable: 'printableElement',
            type: 'html',
            maxWidth: width,
            targetStyles: ['*'],
        });

        wrapper.remove();
    }
}

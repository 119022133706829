import { gql } from '@apollo/client';

export default class TagMutation {
    static CREATE_CRM_TAG = gql`
        mutation createCRMTag($input: TagInput!) {
            createCRMTag(input: $input) {
                tagId
            }
        }
    `;

    static UPDATE_CRM_TAG = gql`
        mutation updateCRMTag($input: TagInput!, $tagId: Int!) {
            updateCRMTag(input: $input, tagId: $tagId) {
                tagId
            }
        }
    `;

    static DELETE_CRM_TAG = gql`
        mutation deleteCRMTag($tagId: Int!) {
            deleteCRMTag(tagId: $tagId) {
                tagId
            }
        }
    `;

    static REMOVE_OPPORTUNITY_TAG = gql`
        mutation removeOpportunityTag($opportunityId: ID!, $tagId: Int!) {
            removeOpportunityTag(opportunityId: $opportunityId, tagId: $tagId)
        }
    `;

    static ADD_OPPORTUNITY_TAG = gql`
        mutation addOpportunityTag($opportunityId: ID!, $tagId: Int!) {
            addOpportunityTag(opportunityId: $opportunityId, tagId: $tagId)
        }
    `;

    static CREATE_ASSIGN_CRM_TAG = gql`
        mutation createAssignCRMTag($opportunityId: ID!, $tagName: String!) {
            createAssignCRMTag(opportunityId: $opportunityId, tagName: $tagName)
        }
    `;
}

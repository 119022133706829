import S from 'string';
import { isNumber } from 'lodash';

export default class StringUtils {
    /* TODO: Check this commit https://bitbucket.org/automatrix/crm-web/pull-requests/1761
    after reviewing the deal structure (Edit) and other places where the isEmpty is being used */
    static isEmpty(value) {
        return S(value).isEmpty();
    }

    /**
        * This method returns the first character of the first two words
        * @param {String} Value returns the first character of the first two words
        * @example "Example test" => "ET"
    */
    static getCharacterFirstTwoWord(value) {
        if (!this.isEmpty(value)) {
            const currentValue = S(value).trim().s;
            const twoWord = S(currentValue).splitLeft(' ', -1, 2);
            let result = '';

            twoWord.forEach((item) => {
                result += S(item).left(1);
            });

            return result.toUpperCase();
        }

        return '';
    }

    /**
     * Return the formatted string to uppercase
     * @param {String} Value
     * @example "test" => "TEST"
     * @example null => null
     * @example undefined => null
     * @example 134134134 => null
    */
    static toUpperCase(value) {
        if (!this.isEmpty(value)) {
            return value.toUpperCase();
        }

        return null;
    }

    /**
     * Returns a string with the first letter of each word uppercased, including hyphenated words
     * @param {String} value
     * @return {String}
     * @example 'Like ice in the sunshine' => 'Like Ice In The Sunshine'
     * @example 'data_rate' => 'Data_Rate'
     * @example 'background-color' => 'Background-Color'
    */
    static toPascalCase(value) {
        return S(value).titleCase().s;
    }

    /**
     * @param {String} Value
    */
    static isValidEmail(value) {
        if (!S(value).isEmpty()) {
            // eslint-disable-next-line max-len
            const regex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(value);
        }

        return false;
    }

    static validateURL(path) {
        if (!StringUtils.isEmpty(path)) {
            // eslint-disable-next-line max-len
            const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

            return regex.test(path);
        }

        return false;
    }

    static isSecureURL(path) {
        if (!StringUtils.isEmpty(path)) {
            // eslint-disable-next-line max-len
            const regex = /^(?:(?:https):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

            return regex.test(path);
        }

        return false;
    }

    static applySSNMask(ssn, showLast4 = true) {
        if (!this.isEmpty(ssn)) {
            const newSSN = ssn.replaceAll('-', '');
            const valueHidden = newSSN.replace(/[\d]/g, '*');

            if (valueHidden.length <= 3) {
                return valueHidden;
            }

            if (valueHidden.length <= 5) {
                return `${valueHidden.slice(0, 3)}-${valueHidden.slice(3, 5)}`;
            }

            if (showLast4) {
                return (
                    `${valueHidden.slice(0, 3)}-${valueHidden.slice(3, 5)}-${newSSN.substr(5)}`
                );
            }

            return (
                `${valueHidden.slice(0, 3)}-${valueHidden.slice(3, 5)}-${valueHidden.slice(5, 9)}`
            );
        }

        return '';
    }

    static validateSecureURL(path) {
        if (!StringUtils.isEmpty(path)) {
            // eslint-disable-next-line
            const regex = /^(?:(?:https):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

            return regex.test(path);
        }

        return false;
    }

    static getEmailsByText(text) {
        if (typeof text === 'string' && !StringUtils.isEmpty(text)) {
            return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
        }

        return [];
    }

    static isHTML(string) {
        if (!StringUtils.isEmpty(string)) {
            const regex = /<\/?[a-z][\s\S]*>/i;
            return regex.test(string);
        }

        return false;
    }

    static checkIfCharsIncludedInString = (characters = [], string) => {
        if (StringUtils.isEmpty(string)) return false;

        return characters
            .some((char) => string?.toLowerCase().includes(char));
    };

    static insertAt = (text, insertion, pos) => `${text.slice(0, pos)}${insertion}${text.slice(pos)}`;

    static firstCharToLowerCase = (string) => {
        if (StringUtils.isEmpty(string)) return string;

        return `${string.charAt(0).toLowerCase()}${string.slice(1)}`;
    };

    static getFileNameFromPath(value) {
        return value.substring(value.lastIndexOf('/') + 1);
    }

    static capitalizeFirstLetterWords(value) {
        return value
            .split(' ')
            .filter((word) => !this.isEmpty(word))
            .map((word) => word?.trim())
            .map((word) => `${word[0]?.toUpperCase()}${word?.substring(1)}`)
            .join(' ');
    }

    static removeNumbers(value) {
        if (!StringUtils.isEmpty(value)) return value.replace(/[0-9]/g, '');
        return value;
    }

    static getOnlyNumbers(value) {
        const onlyNumbers = isNumber(value) ? toString(value) : value;
        return (onlyNumbers || '').replace(/[^0-9]/g, '');
    }

    static isValidIpV4(value) {
        const regex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
        return regex.test(value);
    }

    static isValidSubdomain(value) {
        const regex = /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)?([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/;
        return regex.test(value);
    }
}

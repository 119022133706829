import { gql } from '@apollo/client';

export default class InventoryModuleSubscription {
    static VEHICLE_BLOCKING_STATUS_CHANGED = gql`
        subscription vehicleRecordBlockingStatusChanged(
            $stockNumber: Int!
        ) {
            vehicleRecordBlockingStatusChanged(
                stockNumber: $stockNumber
            ) {
                isEditing
                editingById
                editingBy
                stockNumber
            }
        }
    `;
}

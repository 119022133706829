import React from 'react';
import PropTypes from 'prop-types';

import { compact } from 'lodash';
import Dropzone from 'react-dropzone';
import {
    makeStyles,
    Button,
} from '@material-ui/core';

import { useMutation } from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import ImagesUtils from 'utils/ImagesUtils';

const useStyles = makeStyles((theme) => ({
    buttonTextSize: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        minWidth: 125,
    },
    dropZone: {
        width: 'auto',
        height: 'auto',
        border: 'none',
    },
    buttonGreen: {
        color: '#FFF',
        background: theme.palette.background.green,
        maxWidth: '247px',
        '&:hover': {
            backgroundColor: theme.palette.background.green,
        },
    },
    textSize: {
        position: 'absolute',
        bottom: '-19px',
        fontSize: '12px',
    },
}));
const allowedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/PNG',
    'image/JPEG',
    'image/JPG',
    'image/svg',
    'image/SVG',
    'image/svg+xml',
    'image/SVG+xml',
];

const UploadIcon = ({ disabled, addIcon }) => {
    const classes = useStyles();
    const [uploadIcon, { loading, error }] = useMutation(InventoryMutation.UPLOAD_ICON);

    const keyStore = new KeyStore();
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const uploadImages = async (file, maxSize) => {
        if (INVENTORY_VEHICLE_WRITE) {
            if (file.size > maxSize) {
                ModalUtils.errorMessage(null, 'Maximum file size is 100KB.');
            } else if (error) {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('uploading', 'Icon'));
            } else {
                const uploadIconResponse = await uploadIcon({ variables: { file } });

                if (uploadIconResponse?.data?.uploadIcon) {
                    const { url, key } = uploadIconResponse?.data?.uploadIcon || {};

                    const newIcon = {
                        selected: false,
                        url,
                        key,
                    };

                    return Promise.resolve(newIcon);
                }

                ModalUtils.errorMessage(null, MessageUtils.getGenericError('uploading', 'Icon'));
            }
        }

        return null;
    };
    const addImage = async (files, maxSize) => {
        try {
            const iconList = await Promise.all(files.map((currentFile) => uploadImages(currentFile, maxSize)));

            addIcon(compact(iconList));
        } catch (err) {
            ModalUtils.errorMessage(null, MessageUtils.getGenericError('uploading', 'Icon'));
        }
    };
    const { MAX_SIZES: { ICONS } } = ImagesUtils;
    const { maxSize, text } = ImagesUtils.getSizes(ICONS);

    return (
        <div className={classes.buttonTextSize}>
            <Dropzone
                className={classes.dropZone}
                multiple
                accept={allowedFileTypes.join(',')}
                onDrop={(files) => addImage(files, maxSize)}
            >
                <Button
                    variant="contained"
                    color="primary"
                    margin="dense"
                    className={classes.buttonGreen}
                    disabled={disabled || loading}
                >
                    Upload Icon
                </Button>
            </Dropzone>
            <span className={classes.textSize}>{ text }</span>
        </div>
    );
};

UploadIcon.propTypes = {
    disabled: PropTypes.bool,
    addIcon: PropTypes.func.isRequired,
};

UploadIcon.defaultProps = {
    disabled: false,
};

export default UploadIcon;

import HttpClientModule from 'services/httpModules/HttpClientModule';

export const URL = {
    EMAIL_TEMPLATE_PREVIEW: '/emailTemplates/~emailTemplateId~/preview/~recordId~',
    SMS_TEMPLATE_PREVIEW: '/smsTemplates/~smsTemplateId~/preview/~recordId~',
};

export default class HttpCRMModule {
    constructor() {
        this.http = new HttpClientModule();
    }

    static getURLEmailTemplatePreview(emailTemplateId, recordId) {
        return URL.EMAIL_TEMPLATE_PREVIEW.replace('~emailTemplateId~', emailTemplateId)
            .replace('~recordId~', recordId);
    }

    static getURLSMSTemplatePreview(smsTemplateId, recordId) {
        return URL.SMS_TEMPLATE_PREVIEW.replace('~smsTemplateId~', smsTemplateId)
            .replace('~recordId~', recordId);
    }

    async getSMSTemplatePreview(smsTemplateId, recordId) {
        let url = URL.SMS_TEMPLATE_PREVIEW;
        url = url.replace('~smsTemplateId~', smsTemplateId);
        url = url.replace('~recordId~', recordId);

        const response = await this.http.GET(url);

        return response;
    }
}

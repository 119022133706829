import { gql } from '@apollo/client';

export default class LotQuery {
    static GET_LOT_DISPLAY_SETTINGS = gql`
        query lotList($filter: LotFilter) {
            lotList(filter: $filter) {
                displayTagFee
                displayTitleFee
                displayRegistrationFee
                displayDealerFee
                displayInspectionFee
                displayTagAgencyFee
                displayAcquisitionFee
                userField1
                userField1Name
                displayUserField1
                userField2
                userField2Name
                displayUserField2
                userField3
                userField3Name
                displayUserField3
                userField4
                userField4Name
                displayUserField4
                userField5
                userField5Name
                displayUserField5
                userField6
                userField6Name
                displayUserField6
                userField7
                userField7Name
                displayUserField7
                inventoryTax
                lotState
            }
        }
    `;

    static LOT_CLOSING_DATE = gql`
        query lotClosingDate($lotName: String){
            lotClosingDate(lotName: $lotName)
        }
    `;

    static GET_SETTINGS = gql`
        query getSettings($category: String, $key: [String], $lotName: String){
            getSettings(category: $category, key: $key, lotName: $lotName) {
                category
                key
                value
                lotName
            }
        }
    `;

    static GET_LOTS = gql`
        query lotList($filter: LotFilter) {
            lotList(filter: $filter) {
                lotId
                lotName
            }
        }
    `;

    static GET_EMAIL_LEAD_DISTRIBUTION_SETTINGS = gql`
        query getEmailLeadDistributionSettings($lotId: Int!){
            getEmailLeadDistributionSettings(lotId: $lotId) {
                category
                key
                value
                lotName
            }
        }
    `;

    static GET_LOTS_LAT_LONG = gql`
        query getLotsLatLong {
            getLotsLatLong {
                lotId
                lotName
                latitude
                longitude
            }
        }
    `;

    static GET_LOT_SETTINGS = gql`
        query lotList($filter: LotFilter) {
            lotList(filter: $filter) {
                active
                activeSince
                bhphInHouse
                dbaName
                dealerFee
                dealerFeeCapitalized
                dealerFeeEditable
                dealerFeeTaxable
                displayAcquisitionFee
                displayDealerFee
                displayInspectionFee
                displayRegistrationFee
                displayTagAgencyFee
                displayTagFee
                displayTitleFee
                displayUserField1
                displayUserField2
                displayUserField3
                displayUserField4
                displayUserField5
                displayUserField6
                displayUserField7
                dispositionFee
                earlyTerminationFee
                gapCapitalized
                inspectionFee
                inspectionFeeCapitalized
                inspectionFeeTaxable
                inventoryTax
                lotAddress
                lotCity
                lotContactPerson
                lotDln
                lotFax
                lotId
                lotMvNumber
                lotMvr
                lotName
                lotPhone
                lotState
                lotStateRegistration
                lotTaxReg
                lotTimeZone
                lotTimeZoneId
                lotType
                lotZip
                mbi
                mbiCapitalized
                mbiTaxable
                noTaxOnRoLabor
                overridePassword
                packCost
                pictureUrl
                productsTaxable
                purchaseOptionFee
                registrationFee
                registrationFeeCapitalized
                registrationFeeTaxable
                serviceContractCapitalized
                tagAgencyFee
                tagAgencyFeeCapitalized
                tagAgencyFeeTaxable
                tagFee
                tagFeeCapitalized
                tagFeeTaxable
                titleFee
                titleFeeCapitalized
                titleFeeTaxable
                userField1
                userField1Capitalized
                userField1Name
                userField1Taxable
                userField2
                userField2Capitalized
                userField2Name
                userField2Taxable
                userField3
                userField3Capitalized
                userField3Name
                userField3Taxable
                userField4
                userField4Capitalized
                userField4Name
                userField4Taxable
                userField5
                userField5Capitalized
                userField5Name
                userField5Taxable
                userField6
                userField6Capitalized
                userField6Name
                userField6Taxable
                userField7
                userField7Capitalized
                userField7Name
                userField7Taxable
            }
        }
    `;

    static GET_LOTS_STATE = gql`
        query lotList($filter: LotFilter) {
            lotList(filter: $filter) {
                lotState
            }
        }
    `;
}

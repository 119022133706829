import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { SketchPicker } from 'react-color';
import { Form, Col } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import ButtonStyles from 'styles/theme/Button';
import { useQuery, useMutation } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import React, { useReducer, useEffect, useContext } from 'react';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';
import { ReactComponent as PaletteColorIcon } from 'assets/color-palette.svg';
import {
    makeStyles, Button, Dialog, DialogContent, DialogActions, Popover, Tooltip,
} from '@material-ui/core';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    appBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    labels: {
        marginTop: 4,
    },
    popover: {
        overflow: 'hidden',
    },
    colorPicker: {
        width: '230px !important',
    },
}));

const DEFAULT_COLOR = {
    background: {
        a: 1, b: 51, g: 30, r: 21,
    },
    headerBackground: {
        a: 1, b: 149, g: 92, r: 64,
    },
    font: {
        a: 1, b: 255, g: 255, r: 255,
    },
};

const FIELD_NAMES = {
    fontColor: 'fontColor',
    backgroundColor: 'backgroundColor',
    headerBackgroundColor: 'headerBackgroundColor',
};

const initState = {
    popover: null,
    colors: [{
        isEditing: false,
        companySettingId: null,
        key: FIELD_NAMES.fontColor,
        value: JSON.stringify(DEFAULT_COLOR.font),
    }, {
        isEditing: false,
        companySettingId: null,
        key: FIELD_NAMES.backgroundColor,
        value: JSON.stringify(DEFAULT_COLOR.background),
    }, {
        isEditing: false,
        companySettingId: null,
        value: JSON.stringify(DEFAULT_COLOR.headerBackground),
        key: FIELD_NAMES.headerBackgroundColor,
    }],
};

const ACTION_TYPES = {
    ON_CHOOSE_COLOR: 'onChooseColor',
    SET_INITIAL_STATE: 'setInitialState',
    ON_OPEN_COLOR_PICKER: 'onOpenColorPicker',
    ON_CLOSE_COLOR_PICKER: 'onCloseColorPicker',
};

const reducer = (state, action) => {
    const {
        type, payload,
    } = action;

    switch (type) {
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const { colors } = payload;
        const newColors = state.colors.map((color) => ({
            ...color,
            value: colors.find((item) => item.key === color.key)?.value || color.value,
            companySettingId: colors.find((item) => item.key === color.key)?.companySettingId || color.companySettingId,
        }));
        return update(state, {
            popover: { $set: null },
            colors: { $set: newColors },
        });
    }
    case ACTION_TYPES.ON_OPEN_COLOR_PICKER: {
        const { fieldName, popover } = payload;
        const index = state.colors.findIndex((item) => item.key === fieldName);
        return update(state, {
            popover: { $set: popover },
            colors: {
                [index]: {
                    isEditing: {
                        $set: true,
                    },
                },
            },
        });
    }
    case ACTION_TYPES.ON_CLOSE_COLOR_PICKER: {
        const newColors = state.colors.map((color) => ({ ...color, isEditing: false }));
        return update(state, {
            popover: { $set: null },
            colors: { $set: newColors },
        });
    }
    case ACTION_TYPES.ON_CHOOSE_COLOR: {
        const { color } = payload;
        const index = state.colors.findIndex((item) => item.isEditing);
        return update(state, {
            colors: {
                [index]: {
                    value: {
                        $set: JSON.stringify(color),
                    },
                },
            },
        });
    }
    default:
        return state;
    }
};

const CompanyThemeDialog = ({ toogleDialog }) => {
    const classes = {
        ...useStyles(),
        ...buttonStyles(),
    };

    const [state, setState] = useReducer(reducer, initState);
    const { loading, data, error } = useQuery(CompanyQuery.GET_COMPANY_THEME, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const [updateThemeColors, { loading: updatingThemeColors }] = useMutation(CompanyMutation.UPDATE_COMPANY_THEME);
    const [createThemeColors, { loading: creatingThemeColors }] = useMutation(CompanyMutation.CREATE_COMPANY_THEME);
    const { refreshThemeColor } = useContext(UserContext);

    const {
        popover, colors,
    } = state;

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getCompanyTheme) {
            setState({
                type: ACTION_TYPES.SET_INITIAL_STATE,
                payload: { colors: data.getCompanyTheme },
            });
        }
    }, [data, loading, error]);

    const onOpenColorPicker = (currentTarget, fieldName) => setState({
        payload: {
            fieldName,
            popover: currentTarget,
        },
        type: ACTION_TYPES.ON_OPEN_COLOR_PICKER,
    });

    const onCloseColorPicker = () => setState({ type: ACTION_TYPES.ON_CLOSE_COLOR_PICKER });

    const onChooseColor = ({ rgb }) => setState({
        payload: {
            color: rgb,
        },
        type: ACTION_TYPES.ON_CHOOSE_COLOR,
    });

    const onSave = async () => {
        const recordsToUpdate = colors.filter((color) => color.companySettingId !== null).map((item) => ({
            key: item.key,
            value: item.value,
            companySettingId: item.companySettingId,
        }));

        const recordsToCreate = colors.filter((color) => color.companySettingId === null).map((item) => ({
            key: item.key,
            value: item.value,
        }));

        if (recordsToCreate.length > 0) {
            try {
                const createResult = await createThemeColors({
                    variables: {
                        input: recordsToCreate,
                    },
                });
                if (createResult?.data?.createCompanyTheme) ModalUtils.successMessage(null, 'Theme colors created successfully.');
                else ModalUtils.errorMessage(null, MessageUtils.getGenericError('creating', 'theme colors'));
            } catch (ex) {
                ModalUtils.errorMessage(null, ex.message);
            }
        } else if (recordsToUpdate.length > 0) {
            try {
                const updateResult = await updateThemeColors({
                    variables: {
                        input: recordsToUpdate,
                    },
                });
                if (updateResult?.data?.updateCompanyTheme) ModalUtils.successMessage(null, 'Theme colors updated successfully.');
                else ModalUtils.errorMessage(null, MessageUtils.getGenericError('updating', 'theme colors'));
            } catch (ex) {
                ModalUtils.errorMessage(null, ex.message);
            }
        }

        refreshThemeColor();
        toogleDialog();
    };

    const open = Boolean(popover);
    const id = open ? 'simple-popover' : undefined;

    const currentColor = JSON.parse(colors.find((color) => color.isEditing)?.value || JSON.stringify(DEFAULT_COLOR.font)) || DEFAULT_COLOR.font;

    return (
        <Dialog
            open
            fullWidth
            maxWidth="xs"
            scroll="paper"
            disableBackdropClick
            disableEscapeKeyDown
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                title="Choose Company Theme"
                toolbarSize="md"
                onClose={toogleDialog}
                appBarClassName={classes.appBar}
            />
            <DialogContent>
                <div className={classes.content}>
                    <Form.Group className={classes.row}>
                        <Form.Label className={classes.labels}>Font Color</Form.Label>
                        <Tooltip title="Click to choose color">
                            <PaletteColorIcon cursor="pointer" onClick={(e) => onOpenColorPicker(e.currentTarget, FIELD_NAMES.fontColor)} />
                        </Tooltip>
                    </Form.Group>
                    <Form.Group className={classes.row}>
                        <Form.Label className={classes.labels}>Left menu background color</Form.Label>
                        <Tooltip title="Click to choose color">
                            <PaletteColorIcon cursor="pointer" onClick={(e) => onOpenColorPicker(e.currentTarget, FIELD_NAMES.backgroundColor)} />
                        </Tooltip>
                    </Form.Group>
                    <Form.Group className={classes.row}>
                        <Form.Label className={classes.labels}>Left menu header background color</Form.Label>
                        <Tooltip title="Click to choose color">
                            <PaletteColorIcon cursor="pointer" onClick={(e) => onOpenColorPicker(e.currentTarget, FIELD_NAMES.headerBackgroundColor)} />
                        </Tooltip>
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Form.Group as={Col}>
                    <Button
                        size="small"
                        className={classes.containedSecondaryInfo}
                        disabled={
                            updatingThemeColors || creatingThemeColors
                        }
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Form.Group>
            </DialogActions>
            <Popover
                id={id}
                open={open}
                anchorEl={popover}
                onClose={() => onCloseColorPicker()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 45,
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <SketchPicker
                    color={currentColor}
                    onChange={onChooseColor}
                    className={classes.colorPicker}
                />
            </Popover>
        </Dialog>
    );
};

CompanyThemeDialog.propTypes = {
    toogleDialog: PropTypes.func.isRequired,
};

export default CompanyThemeDialog;

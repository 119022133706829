import SelectControl from 'components/widgets/editorControls/SelectControl';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import {
    Button, Grid, makeStyles, Divider,
    FormControlLabel, Checkbox,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ModalUtils from 'utils/ModalUtils';
import { useMutation, useQuery } from '@apollo/client';
import AccountReceivableQuery from 'services/graphQL/query/accounting/AccountReceivableQuery';
import AccountReceivableMutation from 'services/graphQL/mutate/accounting/AccountReceivableMutation';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import CustomFieldMappingSchema from 'utils/schema/accounting/settings/CustomFieldMappingSchema';
import { FetchPolicy } from 'utils/enum/Core';

const useStyles = makeStyles(() => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        display: 'block',
        marginLeft: '10px',
        border: 'solid 1px #e5e5e5',
    },
    labels: {
        fontSize: '0.875rem',
    },
    centerDiv: {
        textAlign: 'center',
        marginTop: 15,
    },
}));

const CashierMappingCustomField = () => {
    const classes = useStyles();
    const [record, setRecord] = useState({
        entityMetadataId: '',
        displayCustomField: false,
        customFieldName: '',
        customFieldCreditAccountId: 0,
        customFieldDebitAccountId: 0,
    });

    const handleChange = (columnId, newValue) => {
        setRecord((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    const {
        data, loading, error,
    } = useQuery(AccountReceivableQuery.GET_CASHIER_MAPPING_CUSTOM_FIELD, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getCashierMappingCustomField } = data;
            setRecord(getCashierMappingCustomField);
        }
    }, [data, loading, error]);

    const [saveData, { loading: updating }] = useMutation(AccountReceivableMutation.SAVE_CASHIER_MAPPING_CUSTOM_FIELD, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveCashierMappingCustomField) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        saveData({ variables: { input: record } });
    };

    const isValidData = isValidSchema(CustomFieldMappingSchema, record);
    const { isValid, errors } = isValidData;

    if (loading) return <div className={classes.centerDiv}><CircularProgress size="1em" color="inherit" /></div>;

    return (
        <Grid item className={classes.box}>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Custom field for payments</Form.Label>
                <Divider />
            </Form.Group>
            <Form.Group as={Col}>
                <FormControlLabel
                    onChange={(e, val) => handleChange('displayCustomField', val)}
                    checked={record.displayCustomField}
                    control={(
                        <Checkbox />
                    )}
                    label="Display"
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Field Name</Form.Label>
                <Form.Control
                    className={isValidField(errors, 'customFieldName') ? 'invalid-field' : ''}
                    size="sm"
                    type="text"
                    onChange={(e) => handleChange('customFieldName', e.target.value)}
                    value={record.customFieldName}
                    placeholder=""
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Credit</Form.Label>
                <SelectControl
                    name="customFieldCreditAccountId"
                    value={record.customFieldCreditAccountId}
                    placeHolder="select an account"
                    onChange={handleChange}
                    className={isValidField(errors, 'customFieldCreditAccountId') ? 'invalid-field' : ''}
                    dataSource={{
                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                        variables: {
                            paginate: {
                                init: 0,
                                ignoreLimit: true,
                            },
                        },
                        rootData: 'getAccountingCOAList.data',
                        idField: 'accountNumber',
                        descriptionField: 'fullDescription',
                        additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                    }}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label className={classes.labels}>Debit</Form.Label>
                <SelectControl
                    name="customFieldDebitAccountId"
                    value={record.customFieldDebitAccountId}
                    placeHolder="select an account"
                    onChange={handleChange}
                    className={isValidField(errors, 'customFieldDebitAccountId') ? 'invalid-field' : ''}
                    dataSource={{
                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                        variables: {
                            paginate: {
                                init: 0,
                                ignoreLimit: true,
                            },
                        },
                        rootData: 'getAccountingCOAList.data',
                        idField: 'accountNumber',
                        descriptionField: 'fullDescription',
                        additionalFieldsReturned: ['controlledBy', 'fullDescription'],
                    }}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Button
                    variant="outlined"
                    startIcon={<SaveOutlinedIcon />}
                    size="small"
                    disabled={updating || !isValid}
                    onClick={onSave}
                >
                    {updating ? 'Saving...' : 'Save'}
                    {updating && <CircularProgress size={20} />}
                </Button>
            </Form.Group>
        </Grid>
    );
};

export default CashierMappingCustomField;

export default class LabelValueStyles {
    static style(theme = {}) {
        return ({
            box: {
                display: 'flex',
                alignItems: 'center',
            },
            label: {
                width: 110,
                textAlign: 'right',
                marginRight: theme.spacing(1),
                lineHeight: '26px',
            },
            value: {
                flex: 1,
                color: theme.palette.text.gray,
                lineHeight: '26px',
            },
            valueList: {
                flex: 1,
                color: theme.palette.text.gray,
                listStyle: 'none',
            },
            multipleValuesBox: {
                display: 'flex',
            },
            boxAddress: {
                marginTop: '3px',
            },
        });
    }
}

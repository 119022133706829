import React, { useReducer, useEffect } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import update from 'immutability-helper';
import DatePicker from 'react-datepicker';
import { Form, Col, Row } from 'react-bootstrap';
import { Language } from 'utils/enum/Customer';
import InputSSN from 'components/widgets/form/InputSSN';
import PhoneInput from 'components/widgets/form/PhoneInput';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { CustomerSchema, BusinessSchema } from 'utils/schema/customer/Customer';
import DialogActions from 'components/widgets/modal/DialogActions';
import Select from 'components/widgets/Select';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';
import PhoneUtils from 'lib/PhoneUtils';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';
import { useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    businessCheckBox: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    checkLabel: {
        fontSize: '13px !important',
        fontWeight: '500',
    },
    secondColumnMargin: {
        marginTop: '34px',
        '& > div:nth-child(5)': {
            marginTop: '15px',
        },
    },
    topRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '15px',
        '& > div:nth-child(2)': {
            marginLeft: '10px',
        },
    },
    row: {
        '& > div:nth-child(2)': {
            marginBottom: 0,
        },
    },
}));

const initState = {
};

const ACTION_TYPES = {
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.ON_CHANGE_VALUE: {
        return update(state, {
            [action.field]: { $set: action.payload },
        });
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        const record = action.payload;
        return update(state, {
            firstName: { $set: record.firstName },
            lastName: { $set: record.lastName },
            ssn: { $set: record.ssn },
            email: { $set: record.email },
            workPhone: { $set: PhoneUtils.format(record.workPhone) },
            middleName: { $set: record.middleName },
            dob: { $set: record.dob },
            dln: { $set: record.dln },
            cellPhone: { $set: PhoneUtils.format(record.cellPhone) },
            homePhone: { $set: PhoneUtils.format(record.homePhone) },
            isBusiness: { $set: record.isBusiness },
            allowTextCellPhone: { $set: record.allowTextCellPhone },
            allowCallCellPhone: { $set: record.allowCallCellPhone },
            allowCallHomePhone: { $set: record.allowCallHomePhone },
            allowCallWorkPhone: { $set: record.allowCallWorkPhone },
            isTaxable: { $set: record.isTaxable },
            language: { $set: record.language },
        });
    }
    default:
        return state;
    }
};

const EditCustomerDialog = ({ open, record, toggleModal }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const [updateCustomer] = useMutation(CustomerMutate.UPDATE_CUSTOMER);
    const schema = state.isBusiness ? BusinessSchema : CustomerSchema;
    const isValidData = isValidSchema(schema, Object.assign(state, { dob: new Date(state.dob) }));
    const { isValid, errors } = isValidData;

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_VALUE,
            field,
            payload: value,
        });
    };

    const onSave = async () => {
        try {
            const input = {
                customerId: record.customerId,
                input: {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    ssn: state.ssn,
                    email: !StringUtils.isEmpty(state.email) ? state.email.trim() : null,
                    workPhone: state.workPhone,
                    middleName: state.middleName,
                    dob: state.dob ? DateUtils.getOnlyDate(state.dob) : null,
                    dln: state.dln,
                    cellPhone: state.cellPhone,
                    homePhone: state.homePhone,
                    isBusiness: state.isBusiness,
                    allowTextCellPhone: state.allowTextCellPhone,
                    allowCallCellPhone: state.allowCallCellPhone,
                    allowCallHomePhone: state.allowCallHomePhone,
                    allowCallWorkPhone: state.allowCallWorkPhone,
                    isTaxable: state.isTaxable,
                    language: state.language,
                },
            };

            const response = await updateCustomer({ variables: input });

            if (response.data?.updateCustomer) {
                toggleModal();
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            payload: record,
        });
    }, [record]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            PaperProps={{
                className: clsx(classes.dialog, state.isBusiness ? classes.businessDialogHeigh : classes.dialogHeight),
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Edit" onClose={toggleModal} iconSize="sm" />
            <DialogContent className={classes.dialogContent}>
                <Grid container className="am-form">
                    <Grid item xs={6}>
                        <Form.Group className={classes.topRow} as={Row}>
                            <Form.Check type="checkbox" id="businessDeals">
                                <Form.Check.Input
                                    type="checkbox"
                                    checked={state.isBusiness}
                                    onChange={(e) => onChangeValue('isBusiness', e.target.checked)}
                                />
                                <Form.Check.Label className={classes.checkLabel}>Business</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="checkbox" id="isTaxableId" className="mt-8">
                                <Form.Check.Input
                                    type="checkbox"
                                    checked={state.isTaxable}
                                    onChange={(e) => onChangeValue('isTaxable', e.target.checked)}
                                />
                                <Form.Check.Label className={classes.checkLabel}>Is taxable</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className="required">{state.isBusiness ? 'Business Name' : 'First Name'}</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder="First Name"
                                value={state.firstName}
                                maxLength={70}
                                onChange={(e) => onChangeValue('firstName', e.target.value)}
                                className={isValidField(errors, 'firstName') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        {!state.isBusiness && (
                            <Form.Group as={Col}>
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder="Middle Name"
                                    value={state.middleName || ''}
                                    maxLength={70}
                                    onChange={(e) => onChangeValue('middleName', e.target.value)}
                                />
                            </Form.Group>
                        )}
                        {!state.isBusiness && (
                            <Form.Group as={Col}>
                                <Form.Label className="required">Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder="Last Name"
                                    value={state.lastName || ''}
                                    maxLength={70}
                                    onChange={(e) => onChangeValue('lastName', e.target.value)}
                                    className={isValidField(errors, 'lastName') ? 'invalid-field' : ''}
                                />
                            </Form.Group>
                        )}
                        <Form.Group as={Col}>
                            <Form.Label>SSN</Form.Label>
                            <InputSSN
                                size="sm"
                                value={state.ssn}
                                onChange={(value) => onChangeValue('ssn', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder="email@address.com"
                                value={state.email}
                                maxLength={100}
                                onChange={(e) => onChangeValue('email', e.target.value)}
                                className={isValidField(errors, 'email') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Language</Form.Label>
                            <Select
                                nowrap
                                size="sm"
                                name="language"
                                loading={false}
                                className={classes.input}
                                onChange={(name, value) => onChangeValue(name, value)}
                                value={state.language || ''}
                                options={Object.values(Language).map((l) => ({
                                    value: l,
                                    label: l,
                                }))}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={6} className={classes.secondColumnMargin}>
                        <Form.Group as={Col} className="full-width-date-picker">
                            <Form.Label className={clsx(classes.datePicker, 'required')}>DOB</Form.Label>
                            <DatePicker
                                portalId="dob"
                                selected={new Date(DateUtils.getOnlyDate(state.dob))}
                                placeholderText="mm/dd/yyyy"
                                maxDate={new Date()}
                                onChange={(date) => onChangeValue('dob', date)}
                                className={clsx('form-control', 'form-control-sm', isValidField(errors, 'dob') ? 'invalid-field' : '')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>DLN</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder="DLN"
                                value={state.dln || ''}
                                maxLength={20}
                                onChange={(e) => onChangeValue('dln', e.target.value)}
                            />
                        </Form.Group>
                        <Row className="mb-3 col">
                            <Form.Group as={Col}>
                                <Form.Label>Cell Phone</Form.Label>
                                <PhoneInput
                                    size="sm"
                                    value={state.cellPhone}
                                    onChange={(value) => onChangeValue('cellPhone', value)}
                                    className={isValidField(errors, 'cellPhone') ? 'invalid-field' : ''}
                                />
                            </Form.Group>
                            <div className="form-group d-flex-direction-column-justify-flex-end">
                                <Form.Check type="checkbox" id="allow-messages">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={state.allowTextCellPhone}
                                        onChange={(e) => onChangeValue('allowTextCellPhone', e.target.checked)}
                                    />
                                    <Form.Check.Label className={classes.checkLabel}>Allow messages</Form.Check.Label>
                                </Form.Check>

                                <Form.Check type="checkbox" id="allow-calls" className="mt-8">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={state.allowCallCellPhone}
                                        onChange={(e) => onChangeValue('allowCallCellPhone', e.target.checked)}
                                    />
                                    <Form.Check.Label className={classes.checkLabel}>Allow calls</Form.Check.Label>
                                </Form.Check>
                            </div>
                        </Row>
                        <Row className={clsx('mb-3 col', classes.row)}>
                            <Form.Group as={Col} className="mb-0">
                                <Form.Label>Work Phone</Form.Label>
                                <PhoneInput
                                    size="sm"
                                    value={state.workPhone}
                                    onChange={(value) => onChangeValue('workPhone', value)}
                                />
                            </Form.Group>
                            <div className="form-group d-flex-direction-column-justify-flex-end">
                                <Form.Label />
                                <Form.Check type="checkbox" id="allow-calls-work-phone" className="mt-8">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={state.allowCallWorkPhone}
                                        onChange={(e) => onChangeValue('allowCallWorkPhone', e.target.checked)}
                                    />
                                    <Form.Check.Label className={classes.checkLabel}>Allow calls</Form.Check.Label>
                                </Form.Check>
                            </div>
                        </Row>
                        <Row className={clsx('mb-3 col', classes.row)}>
                            <Form.Group as={Col} className="mb-0">
                                <Form.Label>Home Phone</Form.Label>
                                <PhoneInput
                                    size="sm"
                                    value={state.homePhone}
                                    onChange={(value) => onChangeValue('homePhone', value)}
                                />
                            </Form.Group>
                            <div className="form-group d-flex-direction-column-justify-flex-end">
                                <Form.Label />
                                <Form.Check type="checkbox" id="allow-calls-home-phone" className="mt-8">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={state.allowCallHomePhone}
                                        onChange={(e) => onChangeValue('allowCallHomePhone', e.target.checked)}
                                    />
                                    <Form.Check.Label className={classes.checkLabel}>Allow calls</Form.Check.Label>
                                </Form.Check>
                            </div>
                        </Row>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                onClickSecondary={toggleModal}
                onClickPrimary={onSave}
                disablePrimaryButton={!isValid}
            />
        </Dialog>
    );
};

EditCustomerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
};

export default EditCustomerDialog;

import ButtonStyles from 'styles/theme/Button';

export default class ObstacleStyle {
    static content(theme) {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flex: 1,
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
                justifyContent: 'space-between',
                paddingLeft: '15px',
                paddingRight: '15px',
                '& > button': {
                    width: '95px',
                },
            },
            tableGrid: {
                overflowX: 'auto',
                overflowY: 'hidden',
            },
            tableContainer: {
                marginTop: '15px',
                paddingLeft: '15px',
                paddingRight: '15px',
                height: '450px',
                overflow: 'hidden',
                minWidth: '1000px',
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    justifyContent: 'left',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    '& > .MuiTextField-root': {
                        width: '90%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableHeader: {
                textAlign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            actionsContainer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > button:nth-child(1)': {
                    marginRight: '2px',
                },
                '& > button': {
                    minWidth: '32px',
                    '& .MuiButton-startIcon': {
                        marginRight: '0px',
                    },
                },
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    fontSize: '15px',
                    color: theme.palette.text.white,
                },
            },
            content: {
                padding: '10px 10px',
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
                '& > div > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    '& > input': {
                        marginBottom: '10px',
                    },
                    '& > div': {
                        margin: 0,
                        '& > label': {
                            marginTop: '4px',
                        },
                    },
                },
            },
            dialogActions: {
                paddingRight: '45px',
                '& > .form-group': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '10px',
                },
            },
            input: {
                fontSize: '13px',
                resize: 'none',
            },
            dialogLabels: {
                fontSize: '13px',
            },
            ...ButtonStyles.getStyle(theme),
        });
    }
}

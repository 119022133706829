/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
    makeStyles, Grid, Button,
    Dialog, DialogContent,
    DialogActions, Checkbox,
} from '@material-ui/core';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form } from 'react-bootstrap';
import GpsQuery from 'services/graphQL/query/gps/GpsQuery';
import GpsMutation from 'services/graphQL/mutate/gps/GpsMutation';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StringUtils from 'lib/StringUtils';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        padding: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    tableContainer: {
        marginTop: '15px',
        height: '450px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            '& > div': {
                minWidth: '1200px',
            },
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    actionButton: {
        minWidth: '30px',
        '& .MuiButton-startIcon': {
            marginRight: 0,
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '5px 5px',
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
        },
    },
    labels: {
        fontSize: '14px',
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    activeWrapper: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > div': {
            marginRight: '10px',
            fontSize: '13px',
        },
    },
    testerWrapper: {
        textAlign: 'right',
    },
    secondColumn: {
        position: 'relative',
    },
    notUsed: {
        position: 'absolute',
        top: '2px',
        right: 0,
        fontSize: '13px',
    },
    divider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > input': {
            width: '208px',
        },
    },
    tableCell: {
        textAlign: 'center',
        width: '100%',
    },
}));

const INIT_INTEGRATION_SHAPE = {
    id: null,
    integrationId: null,
    username: null,
    password: null,
    dealerNumber: null,
    configuration: {
        ptpOverride: false,
        warningDays: 2,
        warning: false,
        tone1Days: 0,
        tone1: false,
        tone2Days: 0,
        tone2: false,
        tone3Days: 0,
        tone3: false,
        starterInterrupt: false,
        starterInterruptDays: 0,
    },
    active: true,
};

const GpsSettings = ({ canWrite }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        providers: [],
        integrations: [],
        selectedIntegration: null,
        isFormOpen: false,
        confirmDialogOpen: false,
    });

    const {
        providers,
        integrations,
        selectedIntegration,
        isFormOpen,
        confirmDialogOpen,
    } = state;

    const {
        data: IntegrationsData,
        loading: loadingIntegrations,
        error: errorLoadingIntegrations,
        refetch: refetchIntegrations,
    } = useQuery(GpsQuery.GET_DEALER_GPS_INTEGRATIONS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
        skip: providers.length === 0,
    });

    const {
        data: providersData,
        loading: loadingProviders,
        error: errorLoadingProviders,
    } = useQuery(GpsQuery.GET_GPS_INTEGRATION_PROVIDERS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [testCredentials, { loading: isTestingCredentials }] = useLazyQuery(GpsQuery.TEST_GPS_INTEGRATION_CREDENTIALS, {
        onCompleted: (response) => {
            if (response) {
                const passed = response.testGpsIntegrationCredentials;
                if (passed) ModalUtils.successMessage(null, 'Your credentials are valid');
                if (!passed) ModalUtils.errorMessage(null, 'Invalid credentials');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveIntegration, { loading: isSavingIntegration }] = useMutation(GpsMutation.UPDATE_DEALER_GPS_INTEGRATION, {
        onCompleted: (response) => {
            if (response) {
                refetchIntegrations();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingIntegrations) {
            ModalUtils.errorMessage(errorLoadingIntegrations?.graphQLErrors);
            return;
        }

        if (!loadingIntegrations && IntegrationsData) {
            const { getDealerGpsIntegrations } = IntegrationsData;
            if (ArrayUtils.isNotEmpty(getDealerGpsIntegrations)) {
                setState((previousState) => ({
                    ...previousState,
                    isFormOpen: false,
                    confirmDialogOpen: false,
                    integrations: getDealerGpsIntegrations,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingIntegrations, errorLoadingIntegrations]);

    useEffect(() => {
        if (errorLoadingProviders) {
            ModalUtils.errorMessage(errorLoadingProviders?.graphQLErrors);
            return;
        }

        if (!loadingProviders && providersData) {
            const { getGpsIntegrationProviders } = providersData;
            if (ArrayUtils.isNotEmpty(getGpsIntegrationProviders)) {
                setState((previousState) => ({
                    ...previousState,
                    providers: getGpsIntegrationProviders,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProviders, errorLoadingProviders]);

    const toggleForm = (isNew = false, currentSelection) => {
        setState((previousState) => ({
            ...previousState,
            isFormOpen: !isFormOpen,
            ...(!isFormOpen ? {
                selectedIntegration: isNew ? cloneDeep(INIT_INTEGRATION_SHAPE) : currentSelection,
            } : {}),
        }));
    };

    const toggleDeletePrompt = (currentSelection) => {
        setState((previousState) => ({
            ...previousState,
            confirmDialogOpen: !confirmDialogOpen,
            ...(!confirmDialogOpen ? {
                selectedIntegration: currentSelection,
            } : {}),
        }));
    };

    const onDeleteIntegration = () => {
        onSaveIntegration(true);
    };

    const onChange = ({ target: { name, value, checked } }, isConfiguration = false) => {
        const isActiveField = name === 'active';
        const clone = cloneDeep(selectedIntegration);
        if (!isActiveField && !isConfiguration) clone[name] = value;
        if (isActiveField && !isConfiguration) clone[name] = checked;
        if (isConfiguration) clone.configuration[name] = checked ?? value;

        if (name === 'integrationId') {
            const provider = providers.find((x) => x.id === value);
            if (provider) {
                clone.configuration.ptpOverride = provider.configuration.ptpOverride;
                clone.configuration.warning = provider.configuration.warning;
                clone.configuration.tone1 = provider.configuration.tone1;
                clone.configuration.tone2 = provider.configuration.tone2;
                clone.configuration.tone3 = provider.configuration.tone3;
                clone.configuration.starterInterrupt = provider.configuration.starterInterrupt;
            }
        }

        setState((previousState) => ({
            ...previousState,
            selectedIntegration: clone,
        }));
    };

    const onSaveIntegration = (isDeleting) => {
        if (!selectedIntegration) return;

        const clone = cloneDeep(selectedIntegration);
        const { username, password, configuration } = clone;
        delete clone.username;
        delete clone.password;
        delete clone.createdBy;
        delete clone.createdOn;
        delete clone.modifiedBy;
        delete clone.modifiedOn;

        if (isDeleting) clone.active = false;
        saveIntegration({
            variables: {
                input: {
                    ...clone,
                    ...(username ? {
                        credentials: {
                            username,
                            password,
                        },
                    } : {}),
                    configuration,
                },
            },
        });
    };

    const onTestCredentials = () => {
        if (!selectedIntegration) return;

        const {
            integrationId,
            username,
            password,
            dealerNumber,
        } = selectedIntegration;
        const provider = providers.find((x) => x.id === integrationId);

        testCredentials({
            variables: {
                provider: provider.name,
                username,
                password,
                dealerNumber,
            },
        });
    };

    const columns = [
        {
            headerClassName: classes.tableHeader,
            label: 'Provider',
            dataKey: 'provider',
            width: 200,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        integrationId,
                    },
                } = cell;

                const provider = providers.find((element) => element.id === integrationId);
                return provider ? (<span>{provider.name}</span>) : null;
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Username',
            dataKey: 'username',
            width: 250,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        username,
                    },
                } = cell;

                return (<span>{StringUtils.isEmpty(username) ? '-' : username}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Warning Days',
            dataKey: 'warningDays',
            width: 120,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            warningDays,
                            warning,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{warning ? '-' : warningDays}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Tone 1',
            dataKey: 'tone1Days',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            tone1Days,
                            tone1,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{tone1 ? '-' : tone1Days}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Tone 2',
            dataKey: 'tone2Days',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            tone2Days,
                            tone2,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{tone2 ? '-' : tone2Days}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Tone 3',
            dataKey: 'tone3Days',
            width: 100,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            tone3Days,
                            tone3,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{tone3 ? '-' : tone3Days}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Starter Interrupt',
            dataKey: 'starterInterrupt',
            width: 140,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            starterInterruptDays,
                            starterInterrupt,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{starterInterrupt ? '-' : starterInterruptDays}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'PTP Override',
            dataKey: 'ptpOverride',
            width: 120,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        configuration: {
                            ptpOverride,
                        },
                    },
                } = cell;

                return (<span className={classes.tableCell}>{ptpOverride ? 'true' : 'false'}</span>);
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 150,
            cellRenderer: (cell) => {
                const {
                    rowData,
                } = cell;

                return (
                    <>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, classes.actionButton)}
                            size="small"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleForm(false, rowData)}
                        />
                        <Button
                            className={clsx(classes.containedError, classes.actionButton)}
                            size="small"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => toggleDeletePrompt(rowData)}
                        />
                    </>
                );
            },
        },
    ];

    const selectedProvider = providers.find((p) => p.id === selectedIntegration?.integrationId);
    const hasDealerNumber = selectedProvider?.hasDealerNumber ?? false;

    const isProviderValid = Number(selectedIntegration?.integrationId ?? 0) > 0;
    const isDealerNumberValid = !hasDealerNumber || (hasDealerNumber && !StringUtils.isEmpty(selectedIntegration?.dealerNumber));
    const isUsernameValid = !StringUtils.isEmpty(selectedIntegration?.username);
    const isPassswordValid = !StringUtils.isEmpty(selectedIntegration?.password);

    const {
        warningDays,
        warning,
        tone1Days,
        tone1,
        tone2Days,
        tone2,
        tone3Days,
        tone3,
        starterInterruptDays,
        starterInterrupt,
    } = selectedIntegration?.configuration ?? {};
    const isWarningDaysValid = warning || (!warning && Number(warningDays ?? 0) >= 0);
    const isTone1DaysValid = tone1 || (!tone1 && Number(tone1Days ?? 0) >= 0);
    const isTone2DaysValid = tone2 || (!tone2 && Number(tone2Days ?? 0) >= 0 && Number(tone2Days ?? 0) > Number(tone1Days ?? 0));
    const isTone3DaysValid = tone3 || (!tone3 && Number(tone3Days ?? 0) >= 0 && Number(tone3Days ?? 0) > Number(tone2Days ?? 0));
    const isstarterInterruptDaysValid = starterInterrupt
        || (!starterInterrupt && Number(starterInterruptDays ?? 0) >= 0 && Number(starterInterruptDays ?? 0) > Number(tone3Days ?? 0));
    const isSaveEnabled = isProviderValid
        && isDealerNumberValid
        && isUsernameValid
        && isPassswordValid
        && isWarningDaysValid
        && isTone1DaysValid
        && isTone2DaysValid
        && isTone3DaysValid
        && isstarterInterruptDaysValid;

    const isProviderDisabled = selectedIntegration?.id != null;
    const providerOptions = (isProviderDisabled ? providers : providers.filter((item) => !integrations.map((i) => i.integrationId).includes(item.id)))
        .map((item) => ({
            value: item.id,
            label: item.name,
        }));

    return (
        <>
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <Button
                        disabled={!canWrite || loadingProviders || loadingIntegrations || providers.length === integrations.length}
                        className={classes.containedSecondaryInfo}
                        size="small"
                        startIcon={<AddOutlinedIcon />}
                        onClick={() => toggleForm(true)}
                    >
                        New
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            loading={loadingProviders || loadingIntegrations}
                            rowHeight={35}
                            totalRecords={integrations.length}
                            data={integrations}
                            columns={columns}
                        />
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={isFormOpen}
                fullWidth
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="GPS Form"
                    onClose={() => toggleForm(false)}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.content}>
                        <Grid container>
                            <Grid item sm={6} xs={12}>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Provider</Form.Label>
                                    <Select
                                        nowrap
                                        className={clsx(classes.input, !isProviderValid ? 'invalid-field' : '')}
                                        name="integrationId"
                                        onChange={(name, value) => onChange(
                                            { target: { name, value } },
                                            false,
                                        )}
                                        disabled={isProviderDisabled}
                                        value={selectedIntegration?.integrationId || 0}
                                        options={providerOptions}
                                    />
                                </Form.Group>
                                {hasDealerNumber && (
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Dealer Number</Form.Label>
                                        <Form.Control
                                            maxLength="100"
                                            className={clsx(classes.input, !isDealerNumberValid ? 'invalid-field' : '')}
                                            type="text"
                                            name="dealerNumber"
                                            value={selectedIntegration?.dealerNumber || ''}
                                            onChange={(event) => onChange(event, false)}
                                        />
                                    </Form.Group>
                                )}
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Username</Form.Label>
                                    <Form.Control
                                        maxLength="100"
                                        className={clsx(classes.input, !isUsernameValid ? 'invalid-field' : '')}
                                        type="text"
                                        name="username"
                                        value={selectedIntegration?.username || ''}
                                        onChange={(event) => onChange(event, false)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Password</Form.Label>
                                    <Form.Control
                                        maxLength="100"
                                        className={clsx(classes.input, !isPassswordValid ? 'invalid-field' : '')}
                                        type="text"
                                        name="password"
                                        value={selectedIntegration?.password || ''}
                                        onChange={(event) => onChange(event, false)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className={classes.testerWrapper}>
                                    <Button
                                        size="small"
                                        className={classes.containedSecondaryInfo}
                                        disabled={
                                            !selectedIntegration?.integrationId
                                            || (!hasDealerNumber && StringUtils.isEmpty(selectedIntegration?.username))
                                            || (!hasDealerNumber && StringUtils.isEmpty(selectedIntegration?.password))
                                            || (hasDealerNumber && StringUtils.isEmpty(selectedIntegration?.dealerNumber))
                                            || isTestingCredentials
                                        }
                                        onClick={onTestCredentials}
                                    >
                                        Test Credentials
                                    </Button>
                                </Form.Group>
                            </Grid>
                            <Grid item sm={6} xs={12} className={classes.secondColumn}>
                                <div className={classes.notUsed}>Not Used</div>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Warning Days (2-49)</Form.Label>
                                    <div className={classes.divider}>
                                        <InputNumber
                                            disabled={warning ?? false}
                                            value={warningDays ?? 0}
                                            className={!isWarningDaysValid ? 'invalid-field' : ''}
                                            min={2}
                                            max={49}
                                            decimalScale={0}
                                            size="sm"
                                            onChange={(value) => onChange({ target: { name: 'warningDays', value } }, true)}
                                        />
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="warning"
                                            style={{ padding: 0 }}
                                            checked={warning || false}
                                            onChange={(event) => onChange(event, true)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Tone 1 Days</Form.Label>
                                    <div className={classes.divider}>
                                        <InputNumber
                                            disabled={tone1 ?? false}
                                            value={tone1Days ?? 0}
                                            className={!isTone1DaysValid ? 'invalid-field' : ''}
                                            min={0}
                                            max={9999}
                                            decimalScale={0}
                                            size="sm"
                                            onChange={(value) => onChange({ target: { name: 'tone1Days', value } }, true)}
                                        />
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="tone1"
                                            style={{ padding: 0 }}
                                            checked={tone1 || false}
                                            onChange={(event) => onChange(event, true)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Tone 2 Days</Form.Label>
                                    <div className={classes.divider}>
                                        <InputNumber
                                            disabled={tone2 ?? false}
                                            value={tone2Days ?? 0}
                                            className={!isTone2DaysValid ? 'invalid-field' : ''}
                                            min={0}
                                            max={9999}
                                            decimalScale={0}
                                            size="sm"
                                            onChange={(value) => onChange({ target: { name: 'tone2Days', value } }, true)}
                                        />
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="tone2"
                                            style={{ padding: 0 }}
                                            checked={tone2 || false}
                                            onChange={(event) => onChange(event, true)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Tone 3 Days</Form.Label>
                                    <div className={classes.divider}>
                                        <InputNumber
                                            disabled={tone3 ?? false}
                                            value={tone3Days ?? 0}
                                            className={!isTone3DaysValid ? 'invalid-field' : ''}
                                            min={0}
                                            max={9999}
                                            decimalScale={0}
                                            size="sm"
                                            onChange={(value) => onChange({ target: { name: 'tone3Days', value } }, true)}
                                        />
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="tone3"
                                            style={{ padding: 0 }}
                                            checked={tone3 || false}
                                            onChange={(event) => onChange(event, true)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Starter Interrupt Days</Form.Label>
                                    <div className={classes.divider}>
                                        <InputNumber
                                            disabled={starterInterrupt ?? false}
                                            value={starterInterruptDays ?? 0}
                                            className={!isstarterInterruptDaysValid ? 'invalid-field' : ''}
                                            min={0}
                                            max={9999}
                                            decimalScale={0}
                                            size="sm"
                                            onChange={(value) => onChange({ target: { name: 'starterInterruptDays', value } }, true)}
                                        />
                                        <Checkbox
                                            color="primary"
                                            value="selected"
                                            name="starterInterrupt"
                                            style={{ padding: 0 }}
                                            checked={starterInterrupt || false}
                                            onChange={(event) => onChange(event, true)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} className={classes.activeWrapper}>
                                    <div>PTP Override</div>
                                    <Checkbox
                                        color="primary"
                                        value="selected"
                                        name="ptpOverride"
                                        style={{ padding: 0 }}
                                        checked={selectedIntegration?.configuration?.ptpOverride || false}
                                        onChange={(event) => onChange(event, true)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Form.Group>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Form.Group as={Col}>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            disabled={!isSaveEnabled || isSavingIntegration}
                            onClick={() => onSaveIntegration(false)}
                        >
                            Save
                        </Button>
                    </Form.Group>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this integration?"
                open={confirmDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                disablePrimaryButton={isSavingIntegration}
                disableSecondaryButton={isSavingIntegration}
                onClose={toggleDeletePrompt}
                onClickSecondary={toggleDeletePrompt}
                onClickPrimary={onDeleteIntegration}
            />
        </>
    );
};

GpsSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default GpsSettings;

import { gql } from '@apollo/client';

export default class AccountingCOAQuery {
    static GET_ACCOUNTING_COA_LIST = gql`
    query getAccountingCOAList( $paginate: PaginationInput
      $filters: AccountingCOAFilter) {
        getAccountingCOAList(paginate: $paginate, filters: $filters) {
            data{
                accountNumber
                description
                fullDescription
                type
                isControlled
                controlledBy
            }
            totalCount
        }
    }`;

    static GET_COA_LIST = gql`
    query getAccountingCOAChartList( $paginate: PaginationInput
    $filters: AccountingCOAFilter) {
        getAccountingCOAChartList(paginate: $paginate, filters: $filters) {
            data{
                accountNumber
                description
                type
                lotName
                isControlled
                controlledBy
                printCheckNumbers
                category
                subCategory
                category2
                subCategory2
                category3
                subCategory3
                expandByLot
            }
        }
        lotList {
            lotName
        }
    }`;

    static CREATE_UPDATE_ACCOUNT = gql`
        mutation createUpdateAccount($records: [AccountCOAInput!]!) {
            createUpdateAccount(records: $records)
        }`;

    static DELETE_ACCOUNT = gql`
    mutation deleteAccount($ids: [Int!]!) {
        deleteAccount(ids: $ids)
    }`;

    static GET_SERVICE_COA_LIST = gql`
        query getServiceCOAList( $paginate: PaginationInput
        $filters: AccountingCOAFilter) {
            getServiceCOAList(paginate: $paginate, filters: $filters) {
                data{
                    accountNumber
                    description
                    fullDescription
                    type
                    isControlled
                    controlledBy
                }
                totalCount
            }
        }`;

    static GET_PURCHASE_ORDER_COA_LIST = gql`
    query getPurchaseOrderCOAList( $paginate: PaginationInput
    $filters: AccountingCOAFilter) {
        getPurchaseOrderCOAList(paginate: $paginate, filters: $filters) {
            data{
                accountNumber
                description
                fullDescription
                type
                isControlled
                controlledBy
            }
            totalCount
        }
    }`;
}

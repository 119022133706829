import React from 'react';
import { Portal } from 'react-overlays';
import PropTypes from 'prop-types';

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return (
        <Portal container={el}>
            {children}
        </Portal>
    );
};

CalendarContainer.propTypes = {
    children: PropTypes.node,
};

CalendarContainer.defaultProps = {
    children: null,
};

export default CalendarContainer;

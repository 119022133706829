import React from 'react';

// Material UI
import { makeStyles, Grid } from '@material-ui/core';

// Components and Others
import PropTypes from 'prop-types';
import LabelValue from 'components/widgets/LabelValue';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    label: {
        fontSize: 12,
        width: 60,
        color: theme.palette.text.minsk,
        lineHeight: '20px',
        fontWeight: 500,
    },
    value: {
        fontSize: 12,
        color: '#858585',
        whiteSpace: 'initial',
        textOverflow: 'initial',
    },
    textarea: {
        fontSize: 12,
        width: '90%',
        backgroundColor: `${theme.palette.background.white} !important`,
    },
}));

const VendorInformation = ({ vendor }) => {
    const classes = useStyles();
    const {
        phone,
        type,
        address,
        email,
        notes,
    } = vendor;

    return (
        <Grid container spacing={0}>
            <Grid
                item
                className={classes.main}
                xs={6}
            >
                <LabelValue
                    label="Address"
                    value={address}
                    labelClass={classes.label}
                    valueClass={classes.value}
                />
                <LabelValue
                    label="Email"
                    value={email}
                    labelClass={classes.label}
                    valueClass={classes.value}
                />
                <LabelValue
                    label="Phone"
                    value={phone}
                    labelClass={classes.label}
                    valueClass={classes.value}
                />
                <LabelValue
                    label="Type"
                    value={type}
                    labelClass={classes.label}
                    valueClass={classes.value}
                />
            </Grid>
            <Grid
                item
                className={clsx(classes.main)}
                xs={6}
            >
                <dt className={classes.label}>Notes:</dt>
                <Form.Control
                    as="textarea"
                    size="sm"
                    value={notes}
                    disabled
                    rows={4}
                    className={classes.textarea}
                />
            </Grid>
        </Grid>
    );
};

VendorInformation.propTypes = {
    vendor: PropTypes.object.isRequired,
};

export default VendorInformation;

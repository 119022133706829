export default class PhotosTabStyle {
    static getStyle(theme) {
        return ({
            main: {
                flex: 1,
                padding: '20px 10px',
                position: 'relative',
                [theme.breakpoints.down('sm')]: {
                    overflow: 'hidden',
                    '& > div:nth-child(2)': {
                        display: 'none',
                    },
                },
            },
            header: {
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: '5px',
                marginLeft: '7px',
                position: 'relative',
                '& > div:nth-child(2), & > button:nth-child(3)': {
                    display: 'none',
                },
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    '& > div:nth-child(2), & > button:nth-child(3)': {
                        display: 'initial',
                    },
                    '& > div:nth-child(2)': {
                        marginTop: '10px',
                        width: '300px',
                        maxWidth: '100%',
                    },
                    '& > button': {
                        marginTop: '8px',
                        width: '85px',
                    },
                },
            },
            counter: {
                position: 'absolute',
                right: '15px',
                top: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                [theme.breakpoints.down('sm')]: {
                    position: 'relative',
                    alignItems: 'center',
                    top: '8px',
                    right: '2px',
                    '& > button': {
                        width: '85px',
                        marginLeft: '12px',
                    },
                },
            },
            dragzone: {
                cursor: 'pointer',
                border: `1px solid ${theme.palette.border.alto}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 10px',
                transition: 'border-color .3s',
                backgroundColor: theme.palette.success.main,
                '& > p': {
                    color: theme.palette.text.white,
                },
                '& > svg': {
                    fill: theme.palette.text.white,
                    width: '20px',
                    height: '20px',
                },
            },
            disabledDragzone: {
                backgroundColor: `rgba(${theme.palette.rgb.black}, 0.12)`,
                '& > p': {
                    color: `rgba(${theme.palette.rgb.black}, 0.26)`,
                },
                '& > svg': {
                    fill: `rgba(${theme.palette.rgb.black}, 0.26)`,
                },
            },
            uploadText: {
                color: theme.palette.text.darkShark,
                margin: '0 10px 0 0',
                fontSize: '14px',
            },
            photoInfo: {
                fontSize: 12,
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
            },
            photoIcon: {
                fill: theme.palette.border.ghost,
                marginRight: '5px',
                width: '20px',
                height: '20px',
            },
            noData: {
                textAlign: 'center',
                fontSize: 14,
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                },
            },
            actionButton: {
                height: '32px',
                marginLeft: '10px',
                fontSize: '13px',
                '& svg': {
                    fontSize: '16px !important',
                },
            },
            loader: {
                position: 'absolute',
                left: '0',
                top: '0',
                zIndex: '999',
                backgroundColor: theme.palette.background.white,
                width: '100%',
                height: '100%',
                opacity: '0.5',
            },
            input: {
                fontSize: '13px',
                resize: 'none',
                width: '300px',
                [theme.breakpoints.down('sm')]: {
                    width: '250px',
                },
            },
            '@global': {
                '.css-26l3qy-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            previewedImage: {
                overflow: 'hidden',
                '& > img': {
                    width: '100%',
                },
            },
            watermarksContainer: {
                [theme.breakpoints.down('sm')]: {
                    position: 'relative',
                    marginBottom: '20px',
                    '& > div:nth-child(1)': {
                        width: '100%',
                    },
                },
            },
            previewWarning: {
                position: 'absolute',
                bottom: '-17px',
                left: '1px',
                fontSize: '11px',
                display: 'flex',
                alignItems: 'center',
                '& > svg': {
                    fontSize: '16px',
                    marginLeft: '2px',
                    marginRight: '3px',
                    marginTop: '2px',
                },
            },
        });
    }
}

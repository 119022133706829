import DealMap from 'services/mapData/DealMap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CommissionQuery from 'services/graphQL/query/CommissionQuery';
import CommissionsMutate from 'services/graphQL/mutate/CommissionMutate';
import CommissionSubscription from 'services/graphQL/subscription/CommissionSubscription';

export default class CommissionService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Save commissions
     * @param {Object[]} localData
     * @param {number} localData.accountNumber
     * @param {Object} localData.commissions
    */
    async saveCommissions(data) {
        return this.graphqlClient
            .mutate(CommissionsMutate.SAVE_COMMISSIONS, data);
    }

    /**
     * @param {Object} input
     * @param {number} input.dealId
    */
    async getDealCommission(input) {
        return this.graphqlClient
            .query(CommissionQuery.GET_DEAL_COMMISSION, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }
                const newRecord = DealMap.mapCommissions(data?.getDealCommissions);

                return { data: newRecord };
            });
    }

    /**
     * Deal Subscribe
     * @param {Object} input
     * @param {number} input.dealId
     * @param {function} callback
     */
    async commissionSubscribe(callback, input) {
        const responseSubscription = (record) => {
            const { data } = record;

            if (data && data.commissionsUpdated) {
                const newRecord = DealMap.mapCommissions(data?.commissionsUpdated);

                return callback({
                    data: { ...newRecord },
                });
            }

            return callback({});
        };

        return this.graphqlClient.subscribe(responseSubscription, CommissionSubscription.COMMISSION_UPDATED, input)
            .then((response) => response);
    }
}

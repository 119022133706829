import React, { useEffect, useReducer, useState } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import StringUtils from 'lib/StringUtils';
import update from 'immutability-helper';
import { debounce, isEmpty } from 'lodash';
import { modules } from 'utils/enum/modules';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory, useParams } from 'react-router';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import { Form, Col } from 'react-bootstrap';
import { automatedResponseStatus } from 'utils/enum/OpportunityEnum';
import TradeIn from 'components/modules/lead/read/TradeIn';
import LeadInfo from 'components/modules/lead/read/LeadInfo';
import MessageList from 'components/modules/lead/read/MessageList';
import LeadWidget from 'components/modules/lead/widgets/LeadWidget';
import DealWidget from 'components/modules/lead/widgets/DealWidget';
import ActivityTabs from 'components/modules/crm/activities/ActivityTabs';
import InterestVehicle from 'components/modules/lead/read/InterestVehicle';
import OpportunityWidget from 'components/modules/lead/widgets/OpportunityWidget';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Material UI
import {
    Grid, Divider, makeStyles,
    Button,
} from '@material-ui/core';

// Apollo
import ErrorMessages from 'utils/enum/ErrorMessages';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { FetchPolicy, NOTIFICATION_TYPE } from 'utils/enum/Core';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import OpportunitySubscription from 'services/graphQL/subscription/crm/OpportunitySubscription';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    boxContainer: {
        background: theme.palette.background.default,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
    },
    boxSkeletonTab: {
        border: '1px solid #dee2e6',
        borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        height: '100%',
        display: 'flex',
        justifyContent: 'space-around',
    },
    container: {
        display: 'flex',
        overflow: 'auto',
        flexWrap: 'initial',
    },
    column: {
        flex: 1,
        overflow: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    rightSideColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                padding: 0,
                marginRight: '5px',
                marginLeft: '15px',
                '& > label': {
                    fontSize: '13px',
                    marginBottom: '2px',
                },
                '& > div > div': {
                    minHeight: 'initial',
                },
            },
            '& > button:nth-child(2)': {
                marginRight: '5px',
            },
            '& > button': {
                marginTop: '4px',
            },
        },
    },
}));
const ACTION_TYPES = {
    SET_VALUE: 'setValue',
    SET_LANGUAGE: 'setLanguage',
    UPDATE_OPPORTUNITY: 'updateOpportunity',
    SET_DEFAULT_KEY_ACTIVE: 'setLocationState',
    SET_AUTOMATED_RESPONSE_LIST: 'setAutomatedResponseList',
    SET_AUTOMATED_RESPONSE: 'setAutomatedResponse',
    TOGGLE_DELETE_PROMPT: 'toggleDeletePrompt',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_DEFAULT_KEY_ACTIVE:
        return {
            ...state,
            defaultActiveKey: 5,
        };
    case ACTION_TYPES.SET_VALUE:
        return {
            ...state,
            ...action.payload,
        };
    case ACTION_TYPES.SET_LANGUAGE:
        return update(state, {
            prospect: { language: { $set: action.payload } },
        });
    case ACTION_TYPES.SET_AUTOMATED_RESPONSE_LIST:
        return update(state, {
            automatedResponseList: { $set: action.payload },
        });
    case ACTION_TYPES.SET_AUTOMATED_RESPONSE:
        return update(state, {
            automatedResponse: { $set: action.payload },
        });
    case ACTION_TYPES.TOGGLE_DELETE_PROMPT:
        return update(state, {
            isDeletePromptVisible: { $set: !state.isDeletePromptVisible },
        });
    default:
        return state;
    }
};

const initState = {};
const OpportunityDetail = ({
    setOpportunity,
    canWrite,
    arStatus,
    refetchAutomatedResponseStatus,
}) => {
    const { id } = useParams();
    const history = useHistory();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [obstacles, setObstacles] = useState([]);
    const [state, dispatch] = useReducer(reducer, initState);
    const redirect = debounce(() => history.push(`/${modules.OPPORTUNITIES}`), 2000);
    const {
        status: arCurrentStatus = automatedResponseStatus.HALTED,
        automatedResponseId = null,
    } = arStatus;
    const selectedAutomatedResponse = state.automatedResponse ?? automatedResponseId ?? '';

    const {
        data, error, loading,
    } = useQuery(OpportunityQuery.GET_OPPORTUNITY_BY_CRM_ID, { variables: { crmId: id }, fetchPolicy: FetchPolicy.NO_CACHE });
    const { data: subscriptionData } = useSubscription(OpportunitySubscription.OPPORTUNITY_UPDATED, { variables: { crmId: id } });

    const {
        data: obstacleData,
        loading: loadingObstacle,
        error: errorLoadingObstacle,
    } = useQuery(OpportunityQuery.GET_OBSTACLE_TO_CLOSE, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: responsesData,
        loading: loadingResponses,
        error: errorLoadingResponses,
    } = useQuery(OpportunityQuery.GET_AUTOMATED_RESPONSE_LIST, {
        variables: {
            target: modules.CRM,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [enqueueAutomatedResponse, { loading: enqueuingAutomatedResponse }] = useMutation(OpportunityMutation.ENQUEUE_MANUAL_AUTOMATED_RESPONSE, {
        onCompleted: (response) => {
            if (response) refetchAutomatedResponseStatus();
        },
        onError: (err) => {
            ModalUtils.errorMessage(null, err);
        },
    });

    const [cancelAutomatedResponse, { loading: cancelingAutomatedResponse }] = useMutation(OpportunityMutation.CANCEL_AUTOMATED_RESPONSE, {
        onCompleted: (response) => {
            if (response) {
                refetchAutomatedResponseStatus();
                dispatch({ type: ACTION_TYPES.TOGGLE_DELETE_PROMPT });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage(null, err);
        },
    });

    useEffect(() => {
        if (errorLoadingObstacle) {
            ModalUtils.errorMessage(errorLoadingObstacle?.graphQLErrors);
            return;
        }

        if (!loadingObstacle && obstacleData) {
            const { getObstaclesToClose } = obstacleData;
            if (ArrayUtils.isNotEmpty(getObstaclesToClose)) setObstacles(getObstaclesToClose);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingObstacle, errorLoadingObstacle]);

    useEffect(() => {
        const result = subscriptionData?.opportunityUpdated;

        if (!isEmpty(result)) {
            const { leadCode, status, lotId } = result;

            setOpportunity({ leadCode, status, lotId });
            dispatch({ type: ACTION_TYPES.SET_VALUE, payload: result });
        }
    }, [subscriptionData, setOpportunity]);

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getOpportunityByCRMId)) {
            const { getOpportunityByCRMId } = data;
            const { leadCode, status, lotId } = getOpportunityByCRMId;

            // Currently the leadCode and status were added but in the future more properties could be added
            setOpportunity({ leadCode, status, lotId });
            dispatch({ type: ACTION_TYPES.SET_VALUE, payload: getOpportunityByCRMId });
        }
    }, [data, loading, error, setOpportunity]);

    useEffect(() => {
        const stateLocation = history.location.state;
        if (stateLocation?.type === NOTIFICATION_TYPE.EMAIL_RECEIVED) {
            dispatch({ type: ACTION_TYPES.SET_DEFAULT_KEY_ACTIVE });
        }
    }, [history]);

    useEffect(() => {
        if (errorLoadingResponses) {
            ModalUtils.errorMessage(errorLoadingResponses?.graphQLErrors);
            return;
        }

        if (!loadingResponses && responsesData) {
            const { getAutomatedResponseList } = responsesData;
            if (ArrayUtils.isNotEmpty(getAutomatedResponseList)) {
                dispatch({ type: ACTION_TYPES.SET_AUTOMATED_RESPONSE_LIST, payload: getAutomatedResponseList });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingResponses, errorLoadingResponses]);

    if (error) {
        ModalUtils.errorMessage(error.graphQLErrors);

        if (error.graphQLErrors?.some((x) => x.message.includes(ErrorMessages.ACCESS_DENIED('OPPORTUNITY')))) {
            redirect();
        }
    }

    const setCustomerLanguage = (value) => {
        dispatch({ type: ACTION_TYPES.SET_LANGUAGE, payload: value });
    };

    const onChangeAutomatedResponse = (value) => {
        dispatch({ type: ACTION_TYPES.SET_AUTOMATED_RESPONSE, payload: value });
    };

    const onSetManualAutomatedResponse = () => {
        enqueueAutomatedResponse({
            variables: {
                crmId: id,
                automatedResponseId: state.automatedResponse,
            },
        });
    };

    const onCancelAutomatedResponse = () => {
        cancelAutomatedResponse({
            variables: {
                crmId: id,
            },
        });
    };

    const toggleDeletePrompt = () => {
        dispatch({ type: ACTION_TYPES.TOGGLE_DELETE_PROMPT });
    };

    // * Remove the leadCode props and use CRM ID When the functionality is in graphql.
    return (
        <div className={classes.boxContainer}>
            <Grid container spacing={2} className={classes.container} alignContent="flex-start">
                <Grid item xs={6} className={classes.column}>
                    <LeadInfo
                        crmId={id}
                        data={state}
                        loading={loading}
                        updateLanguage={setCustomerLanguage}
                        obstacles={obstacles}
                        refetchAutomatedResponseStatus={refetchAutomatedResponseStatus}
                    />
                    <Divider />
                    <InterestVehicle
                        record={state}
                        crmId={id}
                        loading={loading}
                    />
                    <Divider />
                    <TradeIn
                        crmId={id}
                        record={state}
                        loading={loading}
                    />
                    <LeadWidget
                        crmId={id}
                        maxHeight="auto"
                    />
                    <OpportunityWidget
                        crmId={id}
                        minHeight="auto"
                    />
                    <DealWidget
                        customerId={state.prospect?.customerCode}
                        minHeight="auto"
                    />
                </Grid>
                <Grid item xs={6} className={classes.column}>
                    {isEmpty(state) ? (
                        <div
                            className={classes.boxSkeletonTab}
                        >
                            <Skeleton width="18%" height={40} />
                            <Skeleton width="18%" height={40} />
                            <Skeleton width="18%" height={40} />
                            <Skeleton width="18%" height={40} />
                            <Skeleton width="18%" height={40} />
                        </div>
                    ) : (
                        <ActivityTabs
                            crmId={id}
                            lotId={state.lotId}
                            leadCode={state.leadCode}
                            email={state.prospect?.email}
                            prospectLanguage={state.prospect?.language}
                            locationState={history.location.state}
                            defaultActiveKey={state.defaultActiveKey}
                            customerCode={state.prospect?.customerCode}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.rightSideColumn}>
                {canWrite && (
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label>Automated Follow-Up</Form.Label>
                            <Select
                                size="sm"
                                name="automatedResponse"
                                value={selectedAutomatedResponse}
                                options={(state.automatedResponseList ?? []).map((ar) => ({ label: ar.name, value: ar.automatedResponseId }))}
                                onChange={(_, value) => onChangeAutomatedResponse(value)}
                                disabled={
                                    arCurrentStatus === automatedResponseStatus.RUNNING
                                    || enqueuingAutomatedResponse
                                    || cancelingAutomatedResponse
                                }
                            />
                        </Form.Group>
                        <Button
                            size="small"
                            className={classes.containedSecondaryInfo}
                            onClick={onSetManualAutomatedResponse}
                            disabled={
                                arCurrentStatus === automatedResponseStatus.RUNNING
                                || StringUtils.isEmpty(selectedAutomatedResponse)
                                || enqueuingAutomatedResponse
                                || cancelingAutomatedResponse
                            }
                        >
                            Apply
                        </Button>
                        <Button
                            size="small"
                            className={classes.containedError}
                            onClick={() => toggleDeletePrompt()}
                            disabled={
                                arCurrentStatus === automatedResponseStatus.HALTED
                                || enqueuingAutomatedResponse
                                || cancelingAutomatedResponse
                            }
                        >
                            Cancel
                        </Button>
                    </div>
                )}
                <MessageList
                    crmId={id}
                    prospectLanguage={state.prospect?.language}
                    allowSendMessages={state.prospect?.allowTextCellPhone}
                    leadCode={state.leadCode}
                    refetchAutomatedResponseStatus={refetchAutomatedResponseStatus}
                />
            </div>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to cancel the current Automated Response in place?"
                open={state.isDeletePromptVisible ?? false}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onCancelAutomatedResponse}
                disablePrimaryButton={cancelingAutomatedResponse}
                disableSecondaryButton={cancelingAutomatedResponse}
            />
        </div>
    );
};

OpportunityDetail.propTypes = {
    setOpportunity: PropTypes.func.isRequired,
    canWrite: PropTypes.bool.isRequired,
    arStatus: PropTypes.object.isRequired,
    refetchAutomatedResponseStatus: PropTypes.func,
};

OpportunityDetail.defaultProps = {
    refetchAutomatedResponseStatus: () => null,
};

export default OpportunityDetail;

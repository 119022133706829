import React, { useReducer, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Table from 'components/widgets/Table';
import ModalUtils from 'utils/ModalUtils';
import InputSearch from 'components/widgets/InputSearch';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import NumberUtils from 'lib/NumberUtils';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import AccountingSubscription from 'services/graphQL/subscription/accounting/accountingSubscription';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import AccountPayableMapper from 'services/mapData/AccountPayableMapper';
import { CachedIcon } from 'components/icons';
import Split from 'react-split';
import VendorInformation from 'components/widgets/vendor/VendorInformation';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_SELECTED_ROW: 'setSelectedRow',
    SET_INITIAL_STATE: 'setInitialState',
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_SELECTED_ROW: {
        return {
            ...state,
            selectedRow: action.value,
        };
    }
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    default: return action.value;
    }
};

const VendorListBalance = (props) => {
    const { onVendorSelected } = props;
    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 50,
            },
            search: '',
        },
        table: {
            records: [],
            totalCount: 0,
        },
        selectedRow: {
            vendorId: 0,
            vendorName: '',
            vendorPhone: '',
            vendorType: '',
            vendorAddress: '',
            vendorNotes: '',
        },
        dataFromSubscription: null,
    };

    const classes = useStyle();

    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params, table, selectedRow, dataFromSubscription,
    } = state;

    const vendor = {
        name: selectedRow.vendorName,
        type: selectedRow.vendorType,
        phone: selectedRow.vendorPhone,
        address: selectedRow.vendorAddress,
        notes: selectedRow.vendorNotes,
    };

    const {
        data, loading, error, subscribeToMore, refetch,
    } = useQuery(AccountingQuery.GET_VENDOR_BALANCE_LIST, {
        variables: {
            paginate: params.paginate,
            filter: {
                search: params.search,
            },
            sort: [{ fieldName: 'vendorName', dir: DataSort.ASC }],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { records } = table;
            const { getVendorWithBalance } = data;
            records.push(...getVendorWithBalance.data);

            dispatch({
                type: ACTION_TYPES.SET_TABLE,
                value: {
                    records,
                    totalCount: getVendorWithBalance.totalCount,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    useEffect(() => {
        const { vendorId } = selectedRow;
        if (vendorId > 0) {
            onVendorSelected(selectedRow);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow]);

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: AccountingSubscription.ACCOUNTS_PAYABLE_VENDOR_BALANCE,
            updateQuery: (prev, { subscriptionData }) => {
                dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { dataFromSubscription: { ...subscriptionData.data.vendorListBalanceChanged } } });
            },
        });

        return () => unsubscribe();
    }, [subscribeToMore]);

    useEffect(() => {
        if (dataFromSubscription?.id > 0) {
            const { action, data: subsData, id } = dataFromSubscription;

            if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = table?.records ?? [];
                const currentData = AccountPayableMapper.vendorBalanceMapper(JSON.parse(subsData));
                const currentIndex = currentRecords.findIndex((item) => Number(item.vendorId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, ...currentData);

                    dispatch({
                        type: ACTION_TYPES.SET_TABLE,
                        value: {
                            records: currentRecords,
                        },
                        dataFromSubscription: null,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromSubscription]);

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...params.paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = table?.records?.length || 0;
        const { paginate } = params;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    const reload = () => {
        dispatch({ type: ACTION_TYPES.SET_INITIAL_STATE, value: initState });
        refetch();
        onVendorSelected(null);
    };

    const columns = [
        {
            Header: 'Id',
            minWidth: 60,
            width: 60,
            id: 'vendorId',
            accessor: 'vendorId',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Vendor Name',
            minWidth: 120,
            id: 'vendorName',
            accessor: 'vendorName',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Balance',
            minWidth: 90,
            width: 90,
            id: 'Balance',
            accessor: 'balance',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
    ];

    return (
        <div className={classes.main}>
            <Header>
                <div className={classes.toolbar}>
                    <div className={classes.containerFilterSearch}>
                        <InputSearch
                            customClasses={classes.search}
                            initialSearch={params.search || ''}
                            onSearch={onSearch}
                        />
                    </div>
                </div>
                <div className={classes.buttonSpacing}>
                    <Button
                        variant="outlined"
                        startIcon={<CachedIcon />}
                        onClick={reload}
                        disabled={loading}
                        size="small"
                    >
                        Reload
                    </Button>
                </div>
            </Header>
            <div className={classes.vendorBalanceTable}>
                <Split
                    sizes={selectedRow && selectedRow.vendorId ? [80, 20] : [100, 0]}
                    className={classes.split}
                    direction="vertical"
                    minSize={0}
                >
                    <Table
                        data={table?.records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={table?.totalCount}
                        loadMore={loadMore}
                        rowSelected
                        className="-highlight"
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;
                            return {
                                onDoubleClick: () => {
                                    dispatch({ type: ACTION_TYPES.SET_SELECTED_ROW, value: record });
                                },
                            };
                        }}
                    />
                    <div>
                        {selectedRow && selectedRow.vendorId > 0 && (
                            <VendorInformation vendor={vendor} />
                        )}
                    </div>
                </Split>
            </div>
        </div>
    );
};

VendorListBalance.propTypes = {
    onVendorSelected: PropTypes.func,
};

VendorListBalance.defaultProps = {
    onVendorSelected: () => null,
};

export default VendorListBalance;

import React from 'react';
import PropTypes from 'prop-types';
import ServiceJobsItem from 'components/modules/service/serviceInvoice/create/ServiceJobsItem';

const ServiceJobs = (props) => {
    const {
        jobs,
        editDetails,
        onAddJobPart,
        onDeletePart,
        onChangeJobValue,
        onChangeJobPart,
        vendorList,
        opCodesList,
        userList,
        recordFields,
        onDeleteJob,
    } = props;

    return (
        <div className="jobTabItemCls">
            {jobs.map((job) => (
                <ServiceJobsItem
                    key={job.jobNumber}
                    jobItem={job}
                    vendorList={vendorList}
                    editDetails={editDetails}
                    onAddJobPart={onAddJobPart}
                    onDeletePart={onDeletePart}
                    onChangeJobPart={onChangeJobPart}
                    onChangeJob={onChangeJobValue}
                    onDeleteJob={onDeleteJob}
                    identifierJob={job.identifierJob}
                    userList={userList}
                    recordFields={recordFields}
                    opCodesList={opCodesList}
                />
            ))}
        </div>
    );
};

ServiceJobs.propTypes = {
    recordFields: PropTypes.shape({
        laborDiscount: PropTypes.bool,
        discount: PropTypes.number,
        discountPercentage: PropTypes.number,
        lotName: PropTypes.string,
        inHouse: PropTypes.bool,
    }).isRequired,
    editDetails: PropTypes.object,
    jobs: PropTypes.arrayOf(PropTypes.object),
    onAddJobPart: PropTypes.func.isRequired,
    onDeletePart: PropTypes.func.isRequired,
    onChangeJobValue: PropTypes.func.isRequired,
    onDeleteJob: PropTypes.func.isRequired,
    onChangeJobPart: PropTypes.func.isRequired,
    vendorList: PropTypes.arrayOf(PropTypes.object).isRequired,
    opCodesList: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifierJobs: PropTypes.string,
    userList: PropTypes.arrayOf(PropTypes.object),
};

ServiceJobs.defaultProps = {
    jobs: [],
    editDetails: {},
    identifierJobs: '',
    userList: [],
};

const areEqual = (prevProps, nextProps) => prevProps.identifierJobs === nextProps.identifierJobs;

export default React.memo(ServiceJobs, areEqual);

import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer, makeStyles, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import MailIcon from '@material-ui/icons/Mail';
import { Communication } from 'utils/enum/SubModule';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    newEmail: {
        width: drawerWidth - 32,
        color: theme.palette.text.white,
        backgroundColor: theme.palette.green.main,
        '&:hover': {
            backgroundColor: theme.palette.green.dark,
            boxShadow: 'none',
        },
    },
    toolbar: {
        display: 'flex',
        ...theme.mixins.toolbar,
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    list: {
        '& .active': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& svg, & span': {
            color: 'rgb(33, 43, 54)',
            fontWeight: 500,
        },
    },
}));

const MailSidebar = ({ onItemClick, category }) => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <List className={classes.list}>
                {['Inbox', 'Spam'].map((text) => (
                    <ListItem
                        button
                        key={text}
                        onClick={() => onItemClick(text)}
                        className={text === category ? 'active' : ''}
                        to={`/${modules.COMMUNICATION}/${Communication.MAIL}`}
                        component={Link}
                    >
                        <ListItemIcon><MailIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

MailSidebar.propTypes = {
    category: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
};

MailSidebar.defaultProps = {
    category: 'Inbox',
};

export default MailSidebar;

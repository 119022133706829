import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FormLabel, Grid, Tooltip } from '@material-ui/core';
import Select from 'components/widgets/Select';

const getLabel = (label, bold) => (
    <FormLabel
        className={clsx({ 'text-bold': bold })}
    >
        { `${label}:` }
    </FormLabel>
);

const DisplaySelect = ({
    label,
    value,
    className,
    disabled,
    loading,
    bold,
    alignRight,
    options,
    name,
    tooltip,
    onChange,
}) => {
    let node = getLabel(label, bold);

    if (tooltip) {
        node = (
            <Tooltip title={tooltip} placement="top">
                { getLabel(label, bold) }
            </Tooltip>
        );
    }

    if (React.isValidElement(label)) {
        node = label;
    }

    return (
        <Grid container item xs={12} sm={6}>
            <Grid
                container
                item
                wrap="nowrap"
                className={clsx({ 'justify-end': alignRight })}
                xs={12}
                sm={5}
            >
                { node }
            </Grid>
            <Grid item xs={12} sm={7}>
                <Select
                    name={name}
                    onChange={(_, newValue) => onChange(newValue)}
                    value={value}
                    options={options}
                    nowrap
                    loading={loading}
                    size="sm"
                    disabled={disabled}
                    className={className}
                />
            </Grid>
        </Grid>
    );
};

DisplaySelect.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    bold: PropTypes.bool,
    alignRight: PropTypes.bool,
    options: PropTypes.array,
    name: PropTypes.string,
    tooltip: PropTypes.string,
};

DisplaySelect.defaultProps = {
    disabled: false,
    loading: false,
    bold: false,
    alignRight: false,
    className: '',
    name: '',
    tooltip: null,
    value: '',
    options: [],
    onChange: () => {},
};

export default DisplaySelect;

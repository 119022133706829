import React from 'react';

import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useDropdownStyles = makeStyles(() => ({
    button: {
        backgroundColor: 'transparent',
    },
    listItemIcon: {
        minWidth: '40px',
    },
}));

const FilterDropdown = (props) => {
    const { value, options, onSelect } = props;
    const classes = useDropdownStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onItemClick = (option) => {
        if (onSelect) onSelect(option);

        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectedOption = options.filter((c) => c.value === value)[0];
    const displayText = selectedOption.value == null ? 'Filter' : `Show: ${selectedOption.text}`;

    return (
        <div>
            <Button
                id="filter-button"
                aria-controls="filter-button"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableRipple
                size="small"
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<ExpandMoreIcon />}
                classes={{ root: selectedOption.value === null && !open ? classes.button : null }}
            >
                {displayText}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={`filter-${index}`} onClick={() => onItemClick(option, index)}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            {option.value === value && <CheckIcon fontSize="small" />}
                        </ListItemIcon>
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

FilterDropdown.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
};

FilterDropdown.defaultProps = {
    value: null,
    onSelect: null,
};

export default FilterDropdown;

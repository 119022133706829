import React, { useState, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    isEqual,
    pull,
    map,
    compact,
} from 'lodash';
import { ALL_LOTS } from 'utils/enum/Core';
import UserContext from 'components/context/UserContext';

import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

/* External UI Components */
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {
    makeStyles,
    Select,
    FormControl,
    Button,
    Input,
    MenuItem,
    ListItemText,
    Checkbox as MaterialCheckbox,
} from '@material-ui/core';
import clsx from 'clsx';

const customTheme = getMuiTheme({
    palette: {
        accent1Color: '#3F51B5',
        textColor: 'rgba(0, 0, 0, 0.65)',
    },
    checkbox: {
        checkedColor: '#3F51B5',
    },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = (containerWidth) => ({
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: containerWidth,
        },
    },
});

const useStyles = makeStyles(InventoryListStyle.lotFilter());

const getLotOptions = (lotList) => compact(lotList);

const getMenuItem = (key, value, checked, className) => (
    <MenuItem key={key} value={value} className={className}>
        <MaterialCheckbox
            color="primary"
            checked={checked}
        />
        <ListItemText primary={value} />
    </MenuItem>
);

const LotFilter = ({
    initialLots, onSearch, className, containerWidth,
}) => {
    const classes = useStyles();
    const { userInformation } = useContext(UserContext);
    const availableLots = userInformation?.lots || [];

    const [lots, dispatchLots] = useReducer((state, action) => {
        switch (action.type) {
        case 'REPLACE_ALL':
            return action.payload;
        case 'ADD':
            return [
                ...state,
                action.payload,
            ];
        case 'REMOVE':
            return pull(map(availableLots, 'lotName'), action.payload);
        default:
            return initialLots;
        }
    }, initialLots);
    const [openLotsSelect, toggleOpenSelector] = useState(false);

    const search = () => {
        if (isEqual(initialLots, lots)) return;

        onSearch(lots);
    };
    const onFilterByLot = (event) => {
        if (Array.isArray(getLotOptions(lots))) {
            search();
        }

        toggleOpenSelector(false);

        event.stopPropagation();
    };
    const getLots = () => {
        const lotValues = getLotOptions(lots);
        const buttonStyle = isEqual(initialLots, lots)
            ? classes.doneButtonEqual : classes.doneButton;

        const lotOptions = [getMenuItem(ALL_LOTS, ALL_LOTS, (lotValues.includes(ALL_LOTS)), classes.menuItemStyle)];

        availableLots.map((lot, index) => {
            const checked = (lotValues.includes(lot.lotName) || lotValues.includes(ALL_LOTS));

            return lotOptions.push(
                getMenuItem(index, lot.lotName, checked, classes.menuItemStyle),
            );
        });

        lotOptions.push(
            <div key="done" className={classes.buttonContainer}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={buttonStyle}
                    onClick={onFilterByLot}
                >
                    Apply
                </Button>
            </div>,
        );

        return lotOptions;
    };
    const setAllLots = () => {
        dispatchLots({
            type: 'REPLACE_ALL',
            payload: [ALL_LOTS],
        });
    };
    const onChangeLot = (currentLots, value) => {
        if (value === ALL_LOTS) {
            if (currentLots.includes(value)) {
                setAllLots();
            } else {
                dispatchLots({
                    type: 'REPLACE_ALL',
                    payload: [],
                });
            }
        } else if (currentLots.length === 0) {
            setAllLots();
        } else if (lots.includes(ALL_LOTS)) {
            dispatchLots({
                type: 'REMOVE',
                payload: value,
            });
        } else if (currentLots.length === availableLots.length) {
            setAllLots();
        } else if (!lots.includes(value)) {
            dispatchLots({
                type: 'ADD',
                payload: value,
            });
        } else {
            dispatchLots({
                type: 'REPLACE_ALL',
                payload: currentLots,
            });
        }
    };

    const lotValues = getLotOptions(lots);

    return (
        <MuiThemeProvider muiTheme={customTheme}>
            <FormControl>
                <Select
                    open={openLotsSelect}
                    onOpen={() => toggleOpenSelector(true)}
                    onClose={() => toggleOpenSelector(false)}
                    className={clsx(classes.select, className)}
                    labelId="select-multiple-lots-label"
                    id="select-multiple-lots"
                    multiple
                    value={lotValues}
                    onChange={(event, selected) => {
                        onChangeLot(
                            event.target.value,
                            selected.props.value,
                        );
                    }}
                    input={<Input placeholder="Please select a lot" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps(containerWidth)}
                >
                    {getLots()}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
};

LotFilter.propTypes = {
    className: PropTypes.string,
    initialLots: PropTypes.array,
    onSearch: PropTypes.func.isRequired,
    containerWidth: PropTypes.number,
};

LotFilter.defaultProps = {
    className: null,
    initialLots: [ALL_LOTS],
    containerWidth: 250,
};

export default LotFilter;

export default class LotSettingsStyles {
    static listStyles(theme = {}) {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                flex: 1,
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '& > div:nth-child(1)': {
                        marginBottom: '5px',
                        width: '200px',
                        marginRight: 0,
                    },
                    '& > button:nth-child(2)': {
                        marginBottom: '5px',
                        marginRight: 0,
                        padding: '6px 16px',
                        width: '200px',
                    },
                    '& > div:nth-child(3)': {
                        display: 'block',
                        width: '200px',
                        '& > button': {
                            width: '100%',
                        },
                    },
                },
            },
            box: {
                display: 'flex',
                marginBottom: theme.spacing(2),
                overflowY: 'auto',
                overflowX: 'hidden',
                '& > div > div > div': {
                    '& > label, & > input, & > div > input, & > div > label, & > div.select-bootstrap': {
                        fontSize: '13px',
                    },
                    '& > div.select-bootstrap > div': {
                        minHeight: 'initial',
                    },
                    '& > div.react-datepicker-wrapper input': {
                        fontSize: '13px',
                    },
                },
            },
            button: {
                color: theme.palette.text.white,
                width: '95px',
                marginRight: '16px',
            },
            saveButton: {
                width: '140px',
                backgroundColor: theme.palette.secondaryInfo.main,
                color: theme.palette.secondaryInfo.contrastText,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: theme.palette.secondaryInfo.dark,
                    boxShadow: 'none',
                },
            },
            editButton: {
                width: '160px',
                backgroundColor: '#59A9FF',
                '&:hover': {
                    backgroundColor: '#4D91DB',
                },
            },
            content: {
                height: '100%',
                flex: 1,
                overflow: 'hidden',
            },
            checkedGreen: {
                color: theme.palette.success.main,
            },
            alignCheckBox: {
                marginBottom: '10px',
            },
            selectLot: {
                width: '250px',
                marginRight: '30px',
                fontSize: '13px',
            },
            companyImage: {
                padding: '5px',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
            },
            imageContainer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            border: {
                margin: '4px',
                paddingTop: '10px',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
            },
            fees: {
                [theme.breakpoints.down('sm')]: {
                    width: '97.5%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& > div': {
                        minWidth: '800px',
                    },
                },
            },
            customFee: {
                [theme.breakpoints.down('sm')]: {
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& > div:nth-child(1)': {
                        minWidth: '200px',
                    },
                    '& > div:nth-child(2)': {
                        minWidth: '400px',
                    },
                },
            },
            uploader: {
                display: 'none',
            },
            dragzone: {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '170px',
            },
            wrapperDate: {
                display: 'flex',
                flex: 1,
            },
            centerCheckbox: {
                marginTop: '35px',
            },
            centerCheckboxX: {
                marginTop: '2.25rem',
                marginBottom: '0px',
                backgroundColor: theme.palette.secondaryInfo.main,
            },
            centerCheckboxX2: {
                marginTop: '60px',
                marginBottom: '0px',
                backgroundColor: '#59A9FF',
            },
            centerCheckbox3: {
                marginTop: '50px',
            },
            topSpace: {
                marginTop: '10px',
            },
            inline: {
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '0.75rem',
            },
            inlineUserFieldCheckbox: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5px',
            },
            inlineUserFieldTitle: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            label: {
                minWidth: '140px',
                marginTop: '5px',
            },
            leftSpace: {
                marginLeft: '0.75rem',
            },
            labelUserField: {
                maxWidth: '20px',
                marginTop: '5px',
                marginRight: '10px',
            },
        });
    }
}

import palette from 'styles/theme/palette';

export default class SettingsStyles {
    static settingsPanel(theme = {}) {
        return ({
            rootContainer: {
                flexDirection: 'row',
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
            panelContainer: {
                flex: 1,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.background.white,
                boxShadow: '1px 1px 4px rgb(0 0 0 / 10%)',
                [theme.breakpoints.down('sm')]: {
                    border: '0px',
                },
            },
            title: {
                color: theme.palette.text.boulderGray,
            },
            biIcon: {
                width: '24px',
            },
        });
    }

    static settingsPanelItem(theme = {}) {
        return ({
            rootContainer: {
                display: 'flex',
                flexDirection: 'row',
                background: theme.palette.background.white,
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            },
            content: {
                display: 'flex',
                flexFlow: 'column',
                flex: 1,
                flexGrow: 1,
                height: '100%',
                padding: theme.spacing(2),
                background: theme.palette.background.white,
                border: `solid 1px ${palette.border.primary}`,
                overflowY: 'auto',
                '& > div': {
                    height: '100%',
                },
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                    border: '0px',
                },
            },
            sideBar: {
                width: 280,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                borderTop: `solid 1px ${palette.border.primary}`,
                overflowY: 'hidden',
                overflowX: 'auto',
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    width: '100% !important',
                    borderTop: '0px',
                    '& > div > div:nth-child(2)': {
                        display: 'none',
                    },
                },
            },
            desking: {
                width: '24px',
                height: '24px',
            },
        });
    }

    static splitAmountStyles(theme = {}) {
        return ({
            rootContainer: {
                display: 'flex',
                flexDirection: 'row',
                background: theme.palette.background.white,
                overflow: 'auto',
            },
            containerHeader: {
                display: 'flex',
                alignItems: 'center',
            },
            buttonSpacing: {
                '& > *': {
                    margin: theme.spacing(0.3),
                },
            },
            deleteButton: {
                color: theme.palette.error.main,
            },
            tableSpacing: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                maxHeight: 'calc(100vh - 300px)',
                minHeight: '250px',
                '& .rt-td': {
                    padding: '0px 5px',
                },
            },
            alert: {
                color: '#856404',
                backgroundColor: '#fff3cd',
                borderColor: '#ffeeba',
                position: 'relative',
                padding: '.75rem 1.25rem',
                marginBottom: '1rem',
                border: '1px solid transparent',
                borderRadius: '.25rem',
                margin: theme.spacing(3),
            },
            columnLeft: {
                alignItems: 'left',
                justifyContent: 'left',
                textAlign: 'left',
            },
            columnRight: {
                alignItems: 'right',
                justifyContent: 'right',
                textAlign: 'right',
            },
            columnCenter: {
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            },
        });
    }
}

import React, { Component } from 'react';

// Components and Others
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import VendorService from 'services/modules/VendorService';
import MenuService from 'services/modules/MenuService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerMap from 'services/mapData/CustomerMap';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import { VendorFormula, VendorType } from 'utils/enum/Vendors';
import PROVIDER from 'utils/enum/MenuEnum';

import StringUtils from 'lib/StringUtils';

const VendorSettingsDialogContainer = (WrappedComponent) => class extends Component {
  static propTypes = {
      isEditing: PropTypes.bool,
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      onSave: PropTypes.func.isRequired,
      productId: PropTypes.number,
      vendorId: PropTypes.number,
  };

  static defaultProps = {
      isEditing: false,
      productId: null,
      vendorId: null,
  }

  constructor(props) {
      super(props);
      this.graphqlClient = new GraphQLClient();
      this.vendorService = new VendorService();
      this.menuService = new MenuService();

      this.initBind();
  }

  /* eslint-disable react/state-in-constructor */
  state = {
      record: {
          vendorName: '',
          vendorAddress: '',
          vendorZip: '',
          vendorCity: '',
          vendorState: '',
          vendorPhone: '',
          vendorFax: '',
          vendorContact: '',
          vendorFednid: '',
          vendorCrNumber: '',
          provider: PROVIDER.NON_MENU,
          menuVendorDetailId: null,
          username: '',
          password: '',
          companyCode: '',
          dealerCode: '',
          lotName: '',
          lotCode: '',
          allowVoid: true,
          allowMultiple: true,
          lotCodes: [],
          companyId: 0,
          active: true,
          is1099: false,
      },
      isLoading: false,
      zipData: [],
      listCity: [],
      externalProviders: [],
      isDecoderEnabled: false,
      isDecodingZip: false,
      currentActiveFormula: VendorFormula.RESERVE,
  };

  componentDidMount() {
      const { props: { isEditing } } = this;

      this.getExternalProviders();
      if (isEditing) {
          this.getVendorDetail();
      }
  }

  onEnableDecoder() {
      const { state: { isDecoderEnabled } } = this;
      if (!isDecoderEnabled) {
          this.setState({ isDecoderEnabled: true });
      }
  }

  onToggleFormulas(activeFormula) {
      this.setState({ currentActiveFormula: activeFormula });
  }

  onChangeValue(field, value) {
      this.setState(({ record }) => {
          const newRecord = { ...record };

          if (field === 'companyId') {
              newRecord[field] = value.providerId;
              newRecord.vendorName = value.providerName;
          } else {
              newRecord[field] = value;
          }

          if (field === 'vendorType' && value !== VendorType.PRODUCT_VENDOR) newRecord.provider = PROVIDER.NON_MENU;
          if (field === 'lotName') newRecord.lotCode = record.lotCodes?.find((l) => l.lotName === value)?.lotCode || '';
          if (field === 'lotCode') {
              const { lotName, lotCodes } = newRecord;
              const lot = lotCodes.find((l) => l.lotName === lotName);
              if (!lot) newRecord.lotCodes = [...lotCodes, { lotName, lotCode: value }];
              if (lot) lot.lotCode = value;
          }

          return { record: newRecord };
      }, () => {
          if (field === 'vendorZip' && value.length === 5) {
              const { state: { isDecoderEnabled } } = this;
              if (isDecoderEnabled) {
                  this.decodeZip(value);
              }
          }
      });
  }

  getExternalProviders() {
      this.menuService.getExternalProviders()
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data?.pullExternalProviders) {
                  this.setState({ externalProviders: data.pullExternalProviders });
              }
          });
  }

  getVendorDetail() {
      const { props: { vendorId } } = this;

      this.setState({ isLoading: true });
      this.vendorService.getVendorDetail({ vendorId })
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);

                  this.setState({ isLoading: false });
                  return;
              }

              if (data && data.vendor) {
                  const { vendor } = data;

                  let details = { ...(vendor.menuVendordetail || {}) };
                  delete vendor.menuVendordetail;
                  if (Object.keys(details).length > 0) {
                      const { record: { lotName } } = this.state;
                      const codes = (details.lotCodeList || []).map((c) => ({ lotName: c.lot, lotCode: c.code }));

                      details = {
                          menuVendorDetailId: details.menuVendorDetailId,
                          username: details.userName,
                          password: details.password,
                          companyCode: details.companyCode,
                          dealerCode: details.dealerCode,
                          lotName: codes.length > 0 ? codes[0].lotName : lotName,
                          lotCode: codes.length > 0 ? codes[0].lotCode : '',
                          allowVoid: details.allowVoidContracts,
                          allowMultiple: details.allowMultipleContractsSubmit,
                          lotCodes: codes,
                          companyId: details.providerCompanyId || 0,
                      };
                  } else {
                      details = {
                          menuVendorDetailId: null,
                          username: '',
                          password: '',
                          companyCode: '',
                          dealerCode: '',
                          lotName: '',
                          lotCode: '',
                          allowVoid: true,
                          allowMultiple: true,
                          lotCodes: [],
                          companyId: 0,
                      };
                  }

                  this.setState({ record: { ...vendor, ...details }, isLoading: false });
              }
          });
  }

  decodeZip(zip = '', ignoreCity = false) {
      const input = {
          zip,
      };

      this.setState({ isDecodingZip: true });
      this.graphqlClient
          .query(CustomerQuery.DECODE_ZIP_CODE, input)
          .then((response) => {
              const { data, graphQLErrors } = response;

              if (graphQLErrors) {
                  ModalUtils.errorMessage(graphQLErrors);
                  return;
              }

              if (data?.decodeZip) {
                  const { decodeZip } = data;

                  /* In order to keep the city value, we need
              to assign it using the record from database */
                  if (ignoreCity && decodeZip.length > 0) {
                      const { state: { record } } = this;
                      const alreadyExist = decodeZip.find((ele) => StringUtils.toUpperCase(ele.city) === StringUtils.toUpperCase(record.insuranceCity));

                      if (!alreadyExist) {
                          const newData = clone(decodeZip[0]);
                          newData.city = record.insuranceCity;
                          decodeZip.unshift(newData);
                      }
                  }

                  const listCity = decodeZip.map((item) => CustomerMap.mapCity(item));
                  if (listCity.length > 1) listCity.unshift({ label: 'None', value: '' });
                  const zipData = decodeZip;

                  this.setState(({ record, isDecoderEnabled }) => {
                      if (isDecoderEnabled) {
                          const newRecord = {
                              ...record,
                              vendorCity: '',
                              vendorState: '',
                          };
                          return {
                              listCity, zipData, record: newRecord, isDecodingZip: false,
                          };
                      }
                      return { listCity, zipData, isDecodingZip: false };
                  });

                  if (decodeZip.length === 1) {
                      const currentData = decodeZip[0];

                      this.setState(({ record }) => {
                          const newRecord = {
                              ...record,
                              vendorCity: currentData.city,
                              vendorState: currentData.state,
                          };

                          return { record: newRecord };
                      });
                  }
              }
          });
  }

  initBind() {
      this.onChangeValue = this.onChangeValue.bind(this);
      this.getVendorDetail = this.getVendorDetail.bind(this);
      this.decodeZip = this.decodeZip.bind(this);
      this.onEnableDecoder = this.onEnableDecoder.bind(this);
      this.onToggleFormulas = this.onToggleFormulas.bind(this);
  }

  render() {
      const { props, state } = this;

      return !state.isLoading ? (
          <WrappedComponent
              {...props}
              {...state}
              onSave={props.onSave}
              onClose={props.onClose}
              onChangeValue={this.onChangeValue}
              onCloseDialogConfirm={this.onCloseDialogConfirm}
              onEnableDecoder={this.onEnableDecoder}
              onToggleFormulas={this.onToggleFormulas}
          />
      ) : null;
  }
};

export default VendorSettingsDialogContainer;

import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import {
    Divider, Fade, List, Paper, Popper, ClickAwayListener,
} from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';

// HTTP
import { FetchPolicy } from 'utils/enum/Core';
import MailQuery from 'services/graphQL/query/crm/MailQuery';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(() => ({
    popper: {
        zIndex: 16,
    },
    listItem: {
        color: 'rgb(99, 115, 129)',
        fontSize: '13px',
        fontWeight: 500,
    },
}));

const ViewRecipient = ({
    open,
    anchorElement,
    emailId,
    onCloseViewRecipients,
}) => {
    const classes = useStyles();
    const {
        loading, data, error,
    } = useQuery(MailQuery.GET_EMAIL_OPENED_BY_LIST, { variables: { emailId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });

    if (loading) return null;

    if (error) {
        ModalUtils.errorMessage(null, MessageUtils.getGenericError('getting', 'recipients'));
        return null;
    }

    const records = data.getEmailOpenedByList;
    const countRecords = records.length;

    return (
        <ClickAwayListener onClickAway={onCloseViewRecipients}>
            <Popper className={classes.popper} open={open} anchorEl={anchorElement} placement="bottom" transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper elevation={3} variant="outlined">
                            <List>
                                {records.map((item, index) => (
                                    <div key={index}>
                                        <ListItem>
                                            <span className={classes.listItem}>{item.recipient}</span>
                                        </ListItem>
                                        {countRecords !== (index + 1) && <Divider variant="middle" />}
                                    </div>
                                ))}
                            </List>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </ClickAwayListener>
    );
};

ViewRecipient.propTypes = {
    open: PropTypes.bool.isRequired,
    emailId: PropTypes.string.isRequired,
    anchorElement: PropTypes.object.isRequired,
    onCloseViewRecipients: PropTypes.func.isRequired,
};

export default ViewRecipient;

export default class LoadingStyles {
    static loading(theme = {}) {
        return ({
            root: {
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            container: {
                position: 'relative',
            },
            backCircle: {
                color: '#6798e5',
                opacity: '0.2',
            },
            frontCircle: {
                color: '#6798e5',
                animationDuration: '1000ms',
                position: 'absolute',
                left: 0,
            },
            minHeight: {
                height: theme.spacing(5),
            },
            marginTop1: {
                marginTop: theme.spacing(1),
            },
            marginTop2: {
                marginTop: theme.spacing(2),
            },
            marginTop3: {
                marginTop: theme.spacing(3),
            },
            marginTop4: {
                marginTop: theme.spacing(4),
            },
        });
    }
}

export default class VehicleNoteStyle {
    static notePanel(theme) {
        return ({
            main: {
                display: 'flex',
                position: 'relative',
                '& > div': {
                    flex: 1,
                    marginTop: 0,
                    marginBottom: 10,
                },
                padding: '10px',
            },
            editButton: {
                color: theme.palette.text.purplelight,
                fontSize: 13,
                right: 15,
                position: 'absolute',
                top: 15,
                zIndex: 1,
                padding: '0 5px',
                borderRadius: 2,
                '& svg': {
                    marginRight: 5,
                },
            },
            dialog: {
                // TODO: remove this param
                zIndex: '9000 !important',
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                    fontSize: '15px',
                },
            },
            dialogContent: {
                display: 'flex',
            },
            textField: {
                flex: 1,
            },
            navbar: {
                '& > a': {
                    color: theme.palette.text.link,
                    'text-decoration': 'none',
                    'background-color': 'transparent',
                    'line-height': '1',
                    fontSize: '13px',
                },
            },
            borderContainer: {
                '& + div': {
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                    marginLeft: '0.5px',
                },
            },
            tab: {
                height: '100%',
                position: 'relative',
                '& > div.MuiTextField-root': {
                    width: '95%',
                    marginTop: '10px',
                    marginLeft: '10px',
                },
            },
        });
    }
}

/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery, useApolloClient } from '@apollo/client';
import Header from 'components/widgets/Header';
import Container from 'components/widgets/Container';
import VirtualTable from 'components/widgets/VirtualTable';
import InputSearch from 'components/widgets/InputSearch';
import CheckboxControl from 'components/widgets/editorControls/CheckboxControl';
import ModalUtils from 'utils/ModalUtils';
import DealMap from 'services/mapData/DealMap';

import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import DealsQuery from 'services/graphQL/query/DealsQuery';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import {
    DataSort,
    FetchPolicy,
    ALL_LOTS,
} from 'utils/enum/Core';

import {
    makeStyles,
    Button,
    IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

const useStyles = makeStyles((theme) => ({
    ...InventoryListStyle.inventoryList(theme),
    title: {
        color: theme.palette.text.stormGray,
        padding: theme.spacing(1.4),
        display: 'inline-block',
    },
    searchBox: {
        display: 'inline-block',
        float: 'right',
    },
    search: {
        marginRight: theme.spacing(2),
        width: 300,
    },
}));

const initState = {
    params: {
        sort: {
            dir: DataSort.DESC,
            field: 'accountNumber',
        },
        active: true,
        lots: [ALL_LOTS],
        filters: [],
    },
    table: {
        records: [],
        backup: [],
    },
    search: '',
    flags: {
        toggleStickersDialog: false,
        toggleTableSettingsDialog: false,
    },
    tableIncome: {
        columns: [],
        columnSettings: null,
        customFields: null,
        columnsData: [],
        availableLots: undefined,
    },
    selectedRecords: [],
    tableWrapperWidth: 0,
};

const ACTION_TYPES = {
    ON_SEARCH: 'onSearch',
    ENABLE_ROW: 'enableRow',
    SELECT_RECORD: 'selectRecord',
    SET_MAX_TABLE_WIDTH: 'setMaxTableWidth',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_MAX_TABLE_WIDTH:
        return {
            ...state,
            tableWrapperWidth: action.payload,
        };
    case ACTION_TYPES.CHANGE_ALL_FIELDS:
        const { table } = action.payload;
        if (table) {
            const { backup } = table;
            if (!backup) table.backup = state.table.backup;
        }

        return {
            ...state,
            ...action.payload,
        };
    case ACTION_TYPES.ON_SEARCH:
        return {
            ...state,
            search: action.value,
        };
    case ACTION_TYPES.SELECT_RECORD:
        const { selectedRecords } = state;
        let newSelectedRecords = [...selectedRecords];
        const { payload: { accountNumber, checked } } = action;
        if (checked) {
            newSelectedRecords.push(accountNumber);
        } else {
            const index = selectedRecords.indexOf(accountNumber);
            if (index !== -1) {
                newSelectedRecords = selectedRecords.filter((x) => x !== accountNumber);
            }
        }
        return {
            ...state,
            selectedRecords: newSelectedRecords,
        };
    default:
        return state;
    }
};

const DealRFCTransfer = ({ close }) => {
    const client = useApolloClient();
    const classes = useStyles();
    const {
        loadLocalFilterValues,
        resetFilters: resetLocalFilters,
        applyFilters: applyLocalFilters,
        filterLocalData,
    } = useFilterActions();
    const [state, dispatch] = useReducer(reducer, cloneDeep(initState));
    const {
        params,
        table,
        search,
        tableIncome,
        tableWrapperWidth,
        selectedRecords,
    } = state;

    const columns = [{
        dataKey: 'send',
        disableSort: true,
        filterEnabled: false,
        label: 'Send',
        order: 1,
        show: true,
        width: 130,
        cellRenderer: (cell) => {
            const { rowData: { accountNumber } } = cell;
            const isChecked = selectedRecords?.find((x) => x === accountNumber) || false;
            return (
                <CheckboxControl
                    key={accountNumber}
                    name="isChecked"
                    value={isChecked}
                    className={classes.highlightedColor}
                    onClick={() => dispatch({ type: ACTION_TYPES.SELECT_RECORD, payload: { checked: !isChecked, accountNumber } })}
                />
            );
        },
    },
    {
        dataKey: 'accountNumber',
        disableSort: false,
        filterEnabled: false,
        isNumeric: true,
        label: 'Account #',
        order: 1,
        show: true,
        width: 130,
    },
    {
        dataKey: 'customerName',
        disableSort: true,
        filterEnabled: true,
        label: 'Customer Name',
        order: 2,
        show: true,
        width: 220,
    },
    {
        dataKey: 'soldDate',
        disableSort: false,
        filterEnabled: false,
        label: 'Sold Date',
        order: 3,
        show: true,
        width: 130,
    },
    {
        dataKey: 'status',
        disableSort: false,
        filterEnabled: true,
        label: 'Status',
        order: 4,
        show: true,
        width: 130,
    },
    {
        dataKey: 'lotName',
        disableSort: false,
        filterEnabled: true,
        label: 'Lot Name',
        order: 5,
        show: true,
        width: 180,
    },
    {
        dataKey: 'postedDate',
        disableSort: false,
        filterEnabled: false,
        label: 'Posted Date',
        order: 6,
        show: true,
        width: 130,
    }];

    const updateColumnsData = (value) => {
        const clone = cloneDeep(tableIncome);
        clone.columnsData = value;

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                tableIncome: clone,
            },
        });
    };

    const filterData = (filters, column, records) => {
        const reorderedFilters = [];
        for (let x = 0; x < columns.length; x += 1) {
            const current = filters.find((f) => f.columnName === columns[x].dataKey);
            if (current && (!column || column !== columns[x].dataKey)) reorderedFilters.push(current);
        }

        return filterLocalData(reorderedFilters, records ?? table.backup);
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const currentColumn = columns.find((x) => x.dataKey === column);
        const data = loadLocalFilterValues(
            filterData(params.filters, column),
            tableIncome.columnsData,
            column,
            searchInput,
            true,
            currentColumn?.isNumeric ?? false,
        );
        if (data) updateColumnsData(data);
    };

    const onResetFilters = (column, keepColumnData = false) => {
        const data = resetLocalFilters(tableIncome.columnsData, column, params.filters);

        const paramsClone = cloneDeep(params);
        paramsClone.filters = data.filters;

        const tableIncomeClone = cloneDeep(tableIncome);
        if (keepColumnData) {
            const columnsDataClone = cloneDeep(tableIncome.columnsData);
            const columnData = columnsDataClone.find((x) => x.column === column);
            columnData.selected = columnData.records;

            tableIncomeClone.columnsData = columnsDataClone;
        } else {
            tableIncomeClone.columnsData = data.columnsData;
        }
        const filteredRecords = filterData(data.filters);
        filteredRecords.forEach((x) => { x.accounts = [...new Set(filteredRecords.map((record) => record.accountNumber))]; });

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                tableIncome: tableIncomeClone,
                table: {
                    records: filteredRecords,
                },
            },
        });
    };

    const onApplyFilters = (column, selectedValues) => {
        const data = applyLocalFilters(params.filters, column, selectedValues, true, tableIncome.columnsData);

        const paramsClone = cloneDeep(params);
        paramsClone.filters = data.filters;

        const tableIncomeClone = cloneDeep(tableIncome);
        tableIncomeClone.columnsData = data.columnsData;

        const filteredRecords = filterData(data.filters);
        filteredRecords.forEach((x) => { x.accounts = [...new Set(filteredRecords.map((record) => record.accountNumber))]; });

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                tableIncome: tableIncomeClone,
                table: {
                    records: filteredRecords,
                },
            },
        });
    };

    const onSortedChange = ({ sortBy, sortDirection }) => {
        const paramsClone = cloneDeep(params);
        paramsClone.sort = {
            dir: sortDirection,
            field: sortBy,
        };

        dispatch({
            type: ACTION_TYPES.CHANGE_ALL_FIELDS,
            payload: {
                params: paramsClone,
                table: {
                    records: [],
                },
            },
        });
    };

    const filter = {
        searchTerm: state.search,
        sort: {
            columnName: params.sort.field,
            dir: params.sort.dir,
        },
    };

    const {
        data,
        loading,
        error: rfcErrors,
        refetch,
    } = useQuery(DealsQuery.GET_DEALS_TO_RFC, {
        variables: {
            filter,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (rfcErrors) {
            ModalUtils.errorMessage(rfcErrors?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            let records = [];
            const { deals } = data?.getDealsToRFC || {};

            deals.forEach((item) => {
                records.push(DealMap.mapRFCList(item));
            });

            records = records.map((item) => {
                const clone = { ...item };
                clone.accounts = [...new Set(records.map((record) => record.accountNumber))];
                return clone;
            });

            const tableClone = cloneDeep(table);
            // eslint-disable-next-line no-use-before-define
            tableClone.records = filterData(params.filters, null, records);
            tableClone.backup = records;
            dispatch({
                type: ACTION_TYPES.CHANGE_ALL_FIELDS,
                payload: {
                    table: tableClone,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, rfcErrors]);

    const onSearch = (value) => {
        dispatch({ type: ACTION_TYPES.ON_SEARCH, value });
    };

    useEffect(() => {
        if (search) dispatch({ type: ACTION_TYPES.ON_SEARCH, value: search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const maxTableWidth = document.getElementById('dealsContainer')?.offsetWidth;
    useEffect(() => {
        if (rfcErrors) {
            ModalUtils.errorMessage(rfcErrors?.graphQLErrors);
            return;
        }
        if (maxTableWidth > 0) {
            dispatch({
                type: ACTION_TYPES.SET_MAX_TABLE_WIDTH,
                payload: maxTableWidth,
            });
        }
    }, [rfcErrors, maxTableWidth]);

    const transferDeals = async () => {
        const input = {
            deals: selectedRecords,
        };

        try {
            const response = await client.mutate({
                mutation: DealsMutate.SEND_MULTIPLE_TO_RFC,
                variables: { ...input },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const { graphQLErrors } = response;

            if (graphQLErrors) {
                ModalUtils.errorMessage(graphQLErrors);
            } else {
                ModalUtils.successMessage(null, 'Deals were send to RFC.');
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error);
        } finally {
            refetch();
        }
    };

    let tableWidth = columns.reduce((a, b) => b.width + a, 0);
    if (tableWrapperWidth > tableWidth) tableWidth = tableWrapperWidth - 30;

    return (
        <Container className="floatBox">
            <Header>
                <div className="d-flex-justify-center-align-center">
                    <IconButton
                        size="medium"
                        color="primary"
                        onClick={close}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <InputSearch
                        size="sm"
                        forceSearch
                        customClasses={classes.search}
                        initialSearch={search}
                        onSearch={onSearch}
                        executeWhenClearButton={onSearch}
                    />
                    <Button
                        variant="contained"
                        disabled={loading || selectedRecords?.length <= 0}
                        onClick={transferDeals}
                    >
                        Transfer Deals
                    </Button>
                </div>
            </Header>
            <div id="dealsContainer" className={classes.container}>
                <VirtualTable
                    useAdvancedFeatures
                    loading={loading}
                    width={tableWidth}
                    rowHeight={35}
                    totalRecords={table?.records?.length}
                    data={table?.records}
                    columns={columns}
                    sort={onSortedChange}
                    sortBy={params.sort.field}
                    sortDirection={params.sort.dir}
                    filters={params.filters}
                    loadFilterValues={onLoadFilterValues}
                    resetFilters={onResetFilters}
                    applyFilters={onApplyFilters}
                    columnsData={tableIncome.columnsData}
                />
            </div>
        </Container>
    );
};

DealRFCTransfer.propTypes = {
    close: PropTypes.func.isRequired,
};

export default DealRFCTransfer;

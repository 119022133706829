import React from 'react';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Draggable from 'react-draggable';

export const TransitionComponent = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export default PaperComponent;

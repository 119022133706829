import React, { useReducer, useEffect, useRef } from 'react';
import Split from 'react-split';
import { cloneDeep } from 'lodash';
import DateUtils from 'lib/DateUtils';
import {
    useQuery,
    useMutation,
    useApolloClient,
} from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SpreadsheetUtils from 'utils/SpreadsheetUtils';
import { ConfirmDialogActions, filters } from 'utils/enum/CustomQueriesEnum';
import { useLocation } from 'react-router-dom';

// Queries
import CustomQueries from 'services/graphQL/query/CustomQueries';

// Mutations
import CustomQueriesMutate from 'services/graphQL/mutate/CustomQueriesMutate';

// Style
import QueriesBaseLayoutStyles from 'styles/modules/queries/QueriesBaseLayoutStyles';

// Components
import Container from 'components/widgets/Container';
import TableList from 'components/modules/queries/list/TableList';
import ColumnList from 'components/modules/queries/list/ColumnList';
import QueryList from 'components/modules/queries/list/QueryList';
import SQLEditor from 'components/modules/queries/create/SQLEditor';
import DataPreviewList from 'components/modules/queries/list/DataPreviewList';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import Loading from 'components/widgets/Loading';

// Reducer
import QueriesReducer, { INITIAL_STATE, ACTION_TYPES } from 'components/modules/queries/reducer/QueriesReducer';

const useStyles = makeStyles((theme) => QueriesBaseLayoutStyles.layout(theme));

// Base Layout
const QueriesBaseLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const location = useLocation();
    const client = useApolloClient();
    const queryNameInput = useRef(null);
    const classes = useStyles();
    const [state, dispatch] = useReducer(QueriesReducer, INITIAL_STATE);
    const {
        filteredTables,
        filteredColumns,
        filteredQueries,
        selectedTable,
        editor,
        isQueryNameValid,
        isQueryTextValid,
        confirmDialog,
        screenLoaded,
        pullingPreviewData,
        previewData,
        params,
    } = state;

    const tablesResponse = useQuery(CustomQueries.GET_DATABASE_TABLES_NAME, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const queriesResponse = useQuery(CustomQueries.GET_ALL_SAVED_QUERIES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [deleteCustomQuery] = useMutation(CustomQueriesMutate.DELETE_CUSTOM_QUERY, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.REMOVE_QUERY,
                    value: editor.savedQuerieId,
                });

                ModalUtils.successMessage(null, 'Query was remove successfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [editCustomQuery] = useMutation(CustomQueriesMutate.EDIT_CUSTOM_QUERY, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.EDIT_QUERY,
                });

                ModalUtils.successMessage(null, 'Query was edited successfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [createCustomQuery] = useMutation(CustomQueriesMutate.CREATE_CUSTOM_QUERY, {
        onCompleted: (response) => {
            if (response && response?.createCustomQuery) {
                dispatch({
                    type: ACTION_TYPES.ADD_NEW_QUERY,
                    value: response?.createCustomQuery,
                });

                ModalUtils.successMessage(null, 'Query was created successfully');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (tablesResponse.error) {
            ModalUtils.errorMessage(tablesResponse.error?.graphQLErrors);
            return;
        }

        if (queriesResponse.error) {
            ModalUtils.errorMessage(queriesResponse.error?.graphQLErrors);
            return;
        }

        if (!tablesResponse.loading) {
            const { getDatabaseTablesName } = tablesResponse.data;

            dispatch({
                type: ACTION_TYPES.SET_TABLES,
                value: getDatabaseTablesName,
            });
        }

        if (!queriesResponse.loading) {
            const { getAllSavedQueries } = queriesResponse.data;

            dispatch({
                type: ACTION_TYPES.SET_QUERIES,
                value: getAllSavedQueries,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tablesResponse.loading, tablesResponse.error, queriesResponse.loading, queriesResponse.error]);

    useEffect(() => {
        const search = location?.search;
        if (search && filteredQueries.length > 0 && params === null) {
            const fields = new URLSearchParams(search);
            const queryId = fields.get('queryId');
            const lotName = fields.get('lotName');
            const startDate = fields.get('startDate');
            const endDate = fields.get('endDate');
            const lastRun = fields.get('lastRun');

            if (queryId) {
                dispatch({
                    type: ACTION_TYPES.SET_PARAMS,
                    value: {
                        queryId,
                        lotName,
                        startDate,
                        endDate,
                        lastRun,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.search, filteredQueries]);

    useEffect(() => {
        if (params) {
            // eslint-disable-next-line no-use-before-define
            onQuerySelection({ target: { dataset: { queryid: params.queryId } } }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const onTableSelection = ({ target: { innerText } }) => {
        dispatch({
            type: ACTION_TYPES.SET_SELECTED_TABLE,
            value: innerText,
        });
    };

    const onQuerySelection = ({ target: { dataset: { queryid } } }, modifyCriteria = false) => {
        const id = Number(queryid);
        const query = filteredQueries
            .find((item) => item.savedQuerieId === id);

        if (query) {
            const clone = cloneDeep(query);

            if (modifyCriteria) {
                clone.queryText = (clone.queryText || '')
                    .replace(
                        /Set\s+@LotName\s+=\s+'\w+'/gmi,
                        `Set @LotName = '${params.lotName}'`,
                    )
                    .replace(
                        /Set\s+@StartDate\s+=\s+'\S+'/gmi,
                        `Set @StartDate = '${params.startDate}'`,
                    )
                    .replace(
                        /Set\s+@EndDate\s+=\s+'\S+'/gmi,
                        `Set @EndDate = '${params.endDate}'`,
                    )
                    .replace(
                        /Set\s+@DateLastRun\s+=\s+('\S+'|'\S+\s+\S+'|\w+)/gmi,
                        `Set @DateLastRun = ${params.lastRun === 'null' ? params.lastRun : `'${params.lastRun}'`}`,
                    );

                // eslint-disable-next-line no-use-before-define
                pullRecordsSavedQuery(clone.queryText);
            }

            dispatch({
                type: ACTION_TYPES.SET_EDITOR_CONTENT,
                value: clone,
            });
        }
    };

    // Name can be either queryName or queryText
    const changeEditorContent = ({ target: { name, value } }) => {
        dispatch({
            type: ACTION_TYPES.SET_EDITOR_CONTENT,
            value: { ...editor, [name]: value },
        });
    };

    const resetEditorForAdding = () => {
        queryNameInput.current.focus();
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_EDITOR,
        });
    };

    const saveQuery = () => {
        const { savedQuerieId, queryName, queryText } = editor;

        if (queryName === '') {
            dispatch({
                type: ACTION_TYPES.SET_IS_QUERY_NAME_VALID,
                value: !isQueryNameValid,
            });
            return;
        }

        if (queryText === '') {
            dispatch({
                type: ACTION_TYPES.SET_IS_QUERY_TEXT_VALID,
                value: !isQueryTextValid,
            });
            return;
        }

        if (savedQuerieId > 0) {
            editCustomQuery({
                variables: {
                    input: { savedQuerieId, queryName, queryText },
                },
            });
            return;
        }

        createCustomQuery({
            variables: {
                input: { savedQuerieId, queryName, queryText },
            },
        });
    };

    const removeQuery = () => {
        const { savedQuerieId } = editor;

        if (savedQuerieId <= 0) {
            ModalUtils.errorMessage(['Please pick a query to remove']);
            return;
        }

        dispatch({
            type: ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN,
            value: {
                opened: true,
                description: 'Do you want to remove the selected query?',
                actionOnProceed: ConfirmDialogActions.DELETE_CUSTOM_QUERY,
            },
        });
    };

    const onConfirmDialogClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_IS_CONFIRM_DIALOG_OPEN,
            value: {
                opened: false,
                description: '',
                actionOnProceed: '',
            },
        });
    };

    async function getAllColumns() {
        try {
            const { data } = await client.query({
                query: CustomQueries.GET_ALL_COLUMNS_TABLE,
                variables: { tableName: selectedTable },
                fetchPolicy: FetchPolicy.NETWORK_ONLY,
            });

            const records = data?.getAllColumnsTable;
            if (records) {
                dispatch({
                    type: ACTION_TYPES.SET_COLUMNS,
                    value: records,
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    }

    useEffect(() => {
        if (selectedTable) getAllColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTable]);

    async function pullRecordsSavedQuery(queryText) {
        dispatch({
            type: ACTION_TYPES.START_PULLING_PREVIEW_DATA,
        });

        try {
            const { data } = await client.query({
                query: CustomQueries.PULL_RECORDS_SAVED_QUERY,
                variables: { statement: queryText || editor.queryText },
                fetchPolicy: FetchPolicy.NETWORK_ONLY,
            });

            const result = data?.pullRecordsSavedQuery;
            if (result) {
                dispatch({
                    type: ACTION_TYPES.SET_PREVIEW_DATA,
                    value: result,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTION_TYPES.REMOVE_SPINNER_ON_ERROR,
            });

            ModalUtils.errorMessage(null, error.message);
        }
    }

    const takeActionAfterProceed = () => {
        switch (confirmDialog.actionOnProceed) {
        case ConfirmDialogActions.DELETE_CUSTOM_QUERY:
            deleteCustomQuery({ variables: { savedQuerieId: editor.savedQuerieId } }); break;
        default:
            break;
        }
    };

    const filterTableList = (input) => {
        dispatch({
            type: ACTION_TYPES.FILTER_LIST,
            value: {
                filter: filters.FILTER_TABLES,
                input,
            },
        });
    };

    const filterColumnList = (input) => {
        dispatch({
            type: ACTION_TYPES.FILTER_LIST,
            value: {
                filter: filters.FILTER_COLUMNS,
                input,
            },
        });
    };

    const filterQueryList = (input) => {
        dispatch({
            type: ACTION_TYPES.FILTER_LIST,
            value: {
                filter: filters.FILTER_QUERIES,
                input,
            },
        });
    };

    async function exportToExcel() {
        const data = previewData.records;
        const name = `${editor.queryName.toUpperCase()} - ${DateUtils.format(new Date(), 'MM-DD-YYYY')}`;
        const spreadSheet = new SpreadsheetUtils(name);

        const rows = data.map((record) => {
            const rowData = {};
            record.forEach((column) => {
                let value = column.value === 'null' ? '' : column.value;
                if (value.includes('GMT')) value = DateUtils.format(new Date(value));

                rowData[column.name.toUpperCase()] = value;
            });

            return rowData;
        });

        const widthSizePixel = 100;
        const firstRow = rows[0];
        const columnsProperties = { sizes: Object.keys(firstRow).map(() => widthSizePixel) };
        await spreadSheet.addWorkSheet(rows, columnsProperties, 'Report');
        await spreadSheet.download();
    }

    return !screenLoaded ? (null) : (
        <Container className={classes.boxContainer}>
            <Split
                sizes={isMobile ? [12, 12] : [60, 40]}
                minSize={0}
                gutterSize={10}
                gutterAlign="center"
                direction="vertical"
                cursor="row-resize"
            >
                <div className={classes.boxItemTop}>
                    <Split
                        sizes={[20, 20, 20, 40]}
                        minSize={0}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        <TableList
                            data={filteredTables}
                            selected={selectedTable}
                            action={onTableSelection}
                            actionFilter={filterTableList}
                        />
                        <ColumnList actionFilter={filterColumnList} data={filteredColumns} />
                        <QueryList
                            data={filteredQueries}
                            selected={editor.savedQuerieId}
                            action={onQuerySelection}
                            actionFilter={filterQueryList}
                        />
                        <SQLEditor
                            queryNameInput={queryNameInput}
                            actionAdd={resetEditorForAdding}
                            actionSave={saveQuery}
                            actionRemove={removeQuery}
                            actionPreview={pullRecordsSavedQuery}
                            actionChangeEditorContent={changeEditorContent}
                            isQueryNameValid={isQueryNameValid}
                            isQueryTextValid={isQueryTextValid}
                            query={editor}
                        />
                    </Split>
                </div>
                <div className={classes.boxItemDown}>
                    <DataPreviewList data={previewData} actionExport={exportToExcel} />
                </div>
            </Split>
            <ConfirmDialog
                title="Attention!"
                description={confirmDialog.description}
                open={confirmDialog.opened}
                variant="outlined"
                titlePrimary="Proceed"
                titleSecondary="Cancel"
                onClose={onConfirmDialogClose}
                onClickSecondary={onConfirmDialogClose}
                onClickPrimary={takeActionAfterProceed}
            />
            {pullingPreviewData && <Loading className={classes.loader} />}
        </Container>
    );
};

export default QueriesBaseLayout;

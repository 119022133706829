import StringUtils from 'lib/StringUtils';
import { DealType, FinanceDefaultCalculation } from 'utils/enum/DealEnum';
import {
    CreateDealFinanceSchema,
    CreateDealCashSchema,
    BHPHSchema,
    FinanceSchema,
    DraftSchema,
    CashSchema,
    WholesaleSchema,
    LeaseSchema,
    CreateDealLeaseSchema,
} from 'utils/schema/deal/DealStructure';
import { ClientId } from 'utils/enum/Core';

export default class DealUtils {
    // TODO: When all dealTypes are allowed, return the changes to how they are in this committee for this function
    // https://bitbucket.org/automatrix/crm-web/commits/b4725159f7c804a66ce2126021126c5e724841de
    static getDealType() {
        return [
            {
                value: DealType.CASH,
                label: DealType.CASH,
            },
            {
                value: DealType.FINANCE,
                label: DealType.FINANCE,
            },
            {
                value: DealType.WHOLESALE,
                label: DealType.WHOLESALE,
            },
            {
                value: DealType.OUTSIDE_FINANCE,
                label: DealType.OUTSIDE_FINANCE,
            },
            {
                value: DealType.BHPH,
                label: DealType.BHPH,
            },
            {
                value: DealType.LEASE,
                label: DealType.LEASE,
            },
            {
                value: DealType.LHPH,
                label: DealType.LHPH,
            },
        ];
    }

    static getSchemaByDealType(dealType) {
        switch (dealType) {
        case DealType.BHPH:
            return BHPHSchema;
        case DealType.FINANCE:
        case DealType.OUTSIDE_FINANCE:
            return FinanceSchema;
        case DealType.Draft:
            return DraftSchema;
        case DealType.CASH:
            return CashSchema;
        case DealType.WHOLESALE:
            return WholesaleSchema;
        case DealType.LEASE:
        case DealType.LHPH:
            return LeaseSchema;
        default:
            return null;
        }
    }

    static getCreateSchemaByDealType(dealType) {
        switch (dealType) {
        case DealType.BHPH:
        case DealType.FINANCE:
        case DealType.Draft:
        default:
            return CreateDealFinanceSchema;
        case DealType.CASH:
        case DealType.WHOLESALE:
            return CreateDealCashSchema;
        case DealType.LEASE:
        case DealType.LHPH:
            return CreateDealLeaseSchema;
        }
    }

    static getDealTypeByType(dealType) {
        if (typeof dealType === 'string' && !StringUtils.isEmpty(dealType)) {
            const records = this.getDealType();
            return records.find((item) => item.value?.toUpperCase() === dealType.toUpperCase()) || null;
        }

        return null;
    }

    static clientIdIsWeb(clientId = '') {
        return clientId?.toUpperCase() === ClientId.WEB.toUpperCase();
    }

    static getFinanceDefaultCalculation() {
        const defaultCalculationOptions = Object.keys(FinanceDefaultCalculation)
            .map((key) => ({ value: key.toLowerCase(), label: FinanceDefaultCalculation[key] }));

        return defaultCalculationOptions;
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from 'components/App';
import 'styles/App.scss';
import 'dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';

// react-grid-layout
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';

// Material UI
import palette from 'styles/theme/palette';
import shadows, { customShadows } from 'styles/theme/shadows';
import typography from 'styles/theme/typography';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo/ApolloClient';

const theme = createMuiTheme({
    palette,
    typography,
    shadows,
    customShadows,
});

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
});

const RootComponent = () => (
    <MuiThemeProvider theme={theme}>
        <Sentry.ErrorBoundary fallback="An error has ocurred">
            <ApolloProvider client={client}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </ApolloProvider>
        </Sentry.ErrorBoundary>
    </MuiThemeProvider>
);

ReactDOM.render(<RootComponent />, document.getElementById('root'));

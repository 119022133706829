import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from 'components/widgets/Table';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { useLazyQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import PartsMapper from 'services/mapData/PartsMapper';

const basicStyles = makeStyles((theme) => AccountingStyles.basicStyles(theme));
const columStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));

const PurchaseOrderDetailList = (props) => {
    const {
        recordId,
    } = props;

    const classes = {
        ...basicStyles(),
        ...columStyles(),
        ...containerStyles(),
    };

    const [getRecords, { data, loading }] = useLazyQuery(PartsQuery.GET_PURCHASE_DETAIL_LIST,
        {
            onError: (error) => {
                ModalUtils.errorMessage([error]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Part #',
            minWidth: 180,
            width: 180,
            id: 'partStockNumber',
            accessor: 'partStockNumber',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Description',
            minWidth: 250,
            id: 'description',
            accessor: 'description',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnLeft),
            className: clsx(classes.columnStyle, classes.columnLeft),
        },
        {
            Header: 'Quantity',
            minWidth: 100,
            width: 100,
            id: 'quantity',
            accessor: 'quantity',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
        {
            Header: 'Unit Price',
            minWidth: 100,
            width: 100,
            id: 'unitPrice',
            accessor: 'unitPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
        {
            Header: 'Net Price',
            minWidth: 100,
            width: 100,
            id: 'netPrice',
            accessor: 'netPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
        {
            Header: 'List Price',
            minWidth: 100,
            width: 100,
            id: 'listPrice',
            accessor: 'listPrice',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
        {
            Header: 'Sub total',
            minWidth: 100,
            width: 100,
            id: 'subTotal',
            accessor: 'subTotal',
            headerClassName: clsx(classes.columnHeaderStyle, classes.columnRight),
            className: clsx(classes.columnStyle, classes.columnRight),
        },
    ];

    useEffect(() => {
        if (recordId > 0) {
            getRecords({
                variables: {
                    id: recordId,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    const records = PartsMapper.mapPurchaseOrderDetail(data?.getPurchaseOrderDetails) ?? [];

    return (
        <div className={classes.flexContainer}>
            <div className={clsx(classes.bottomTableHeight, classes.overFlowAuto)}>
                <Table
                    rowSelected
                    cursor="default"
                    load={loading}
                    totalRecords={records.length}
                    data={records}
                    columns={columns}
                />
            </div>
        </div>
    );
};

PurchaseOrderDetailList.propTypes = {
    recordId: PropTypes.number,
};

PurchaseOrderDetailList.defaultProps = {
    recordId: 0,
};

export default PurchaseOrderDetailList;

/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

const ProductRowSchema = yup.object().shape({
    type: yup.string().required(),
    vendor: yup.object().shape({
        vendorId: yup.number().required().moreThan(0),
    }),
    product: yup.object().shape({
        productId: yup.number().required().moreThan(0),
    }),
    price: yup.number().required().min(0),
    cost: yup.number().required().min(0),
});

const ProductSchema = yup.array().of(
    ProductRowSchema,
);

export {
    ProductRowSchema,
    ProductSchema,
};

import { useEffect } from 'react';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

const useWindowDimensions = () => {
    let dimensions = getWindowDimensions();
    useEffect(() => {
        function handleResize() {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dimensions = getWindowDimensions();
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
};

export default useWindowDimensions;

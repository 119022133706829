import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    CancelOutlined as CancelOutlinedIcon,
    EditOutlined as EditOutlinedIcon,
    SaveOutlined as SaveOutlinedIcon,
} from '@material-ui/icons';

import {
    Button,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > button:first-child': {
            marginRigth: 5,
        },
    },
    editButton: {
        color: theme.palette.secondaryInfo.main,
        '&:hover': {
            color: theme.palette.secondaryInfo.dark,
        },
    },
    saveButton: {
        color: theme.palette.success.main,
        '&:hover': {
            color: theme.palette.success.dark,
        },
    },
    cancelButton: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
    },
}));

const GroupActionsButtons = ({
    onSave,
    disabled,
    loading,
    editMode,
    allowSave,
    toggleEditMode,
}) => {
    const classes = useStyles();

    if (editMode) {
        return (
            <div className={clsx('d-flex', classes.root)}>
                <Button
                    color="primary"
                    size="small"
                    className={classes.saveButton}
                    startIcon={<SaveOutlinedIcon fontSize="small" />}
                    onClick={onSave}
                    disabled={disabled || loading || !allowSave}
                >
                    Save
                </Button>
                <Button
                    color="primary"
                    size="small"
                    className={classes.cancelButton}
                    startIcon={<CancelOutlinedIcon fontSize="small" />}
                    onClick={() => toggleEditMode(false)}
                    disabled={disabled || loading}
                >
                    Cancel
                </Button>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Button
                color="primary"
                size="small"
                className={classes.editButton}
                startIcon={<EditOutlinedIcon fontSize="small" />}
                onClick={() => toggleEditMode(true)}
                disabled={disabled || loading}
            >
                Edit
            </Button>
        </div>
    );
};

GroupActionsButtons.propTypes = {
    onSave: PropTypes.func.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    editMode: PropTypes.bool,
    allowSave: PropTypes.bool,
};

GroupActionsButtons.defaultProps = {
    disabled: false,
    loading: false,
    editMode: false,
    allowSave: false,
};

export default GroupActionsButtons;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles, Grid, Button,
    Dialog, DialogContent,
    DialogActions,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import GpsHelper from 'utils/GpsHelper';
import { Col, Form } from 'react-bootstrap';
import ButtonStyles from 'styles/theme/Button';
import GpsQuery from 'services/graphQL/query/gps/GpsQuery';
import GpsMutation from 'services/graphQL/mutate/gps/GpsMutation';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '5px 5px',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '25px',
        '& > button': {
            marginRight: '5px',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    labels: {
        fontSize: '14px',
    },
    validDevice: {
        backgroundColor: theme.palette.background.green,
    },
}));

const GpsDeviceDialog = ({
    stockNumber,
    providers,
    integrations,
    gpsCompany,
    gpsInfo,
    gpsDeviceType,
    toggleDeviceForm,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        selectedIntegrationId: gpsCompany
            ? GpsHelper.getCurrentIntegrationId(gpsCompany, providers, integrations)
            : null,
        selectedDeviceId: gpsInfo,
        lastValidatedDeviceId: gpsInfo,
        isValidDevice: !!gpsCompany,
        deviceType: gpsDeviceType,
    });

    const {
        selectedIntegrationId,
        selectedDeviceId,
        isValidDevice,
        lastValidatedDeviceId,
        deviceType,
    } = state;

    const [getDeviceType, { loading: isGettingDeviceType }] = useLazyQuery(GpsQuery.GET_GPS_DEVICE_TYPE, {
        onCompleted: (response) => {
            const data = response?.getGpsDeviceType;
            if (data) {
                const {
                    productType,
                    eliteType,
                } = data;

                if (!StringUtils.isEmpty(productType)) {
                    let newDeviceType = null;
                    if (productType === eliteType) newDeviceType = productType.includes('TRAX') ? 'TRAX' : StringUtils.removeNumbers(productType);
                    if (productType !== eliteType) newDeviceType = StringUtils.removeNumbers(`${productType} ${eliteType}`);

                    setState((previousState) => ({
                        ...previousState,
                        deviceType: newDeviceType,
                    }));
                }
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [validateDevice, { loading: isValidatingDevice }] = useLazyQuery(GpsQuery.VALIDATE_GPS_DEVICE, {
        onCompleted: (response) => {
            if (response?.validateGpsDevice) {
                setState((previousState) => ({
                    ...previousState,
                    isValidDevice: true,
                    lastValidatedDeviceId: selectedDeviceId,
                }));

                const providerId = integrations.find((x) => x.id === selectedIntegrationId)?.integrationId;
                const provider = providers.find((x) => x.id === providerId);
                const hasDeviceType = provider?.configuration?.hasDeviceType ?? false;
                if (hasDeviceType) {
                    getDeviceType({
                        variables: {
                            stockNumber,
                            integrationId: selectedIntegrationId,
                            deviceId: selectedDeviceId,
                        },
                    });
                }

                ModalUtils.successMessage(null, 'Device validated successfully');
            } else {
                ModalUtils.errorMessage(null, 'Error validating device');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveGpsData, { loading: isSavingGpsData }] = useMutation(GpsMutation.UPDATE_VEHICLE_GPS, {
        onCompleted: (response) => {
            if (response?.updateVehicleGps) {
                toggleDeviceForm(true);
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const onChange = ({ target: { name, value } }) => {
        const isRecoveryCompany = name === 'selectedIntegrationId';
        const isSameDevice = lastValidatedDeviceId === value;

        setState((previousState) => ({
            ...previousState,
            [name]: value,
            isValidDevice: isRecoveryCompany ? false : isSameDevice,
            ...(isRecoveryCompany ? { lastValidatedDeviceId: null } : {}),
        }));
    };

    const onValidateDevice = () => {
        validateDevice({
            variables: {
                stockNumber,
                integrationId: selectedIntegrationId,
                deviceId: selectedDeviceId,
            },
        });
    };

    const onSave = () => {
        saveGpsData({
            variables: {
                stockNumber,
                integrationId: selectedIntegrationId,
                deviceId: selectedDeviceId,
                deviceType,
            },
        });
    };

    const providerOptions = integrations
        .map((item) => ({
            value: item.id,
            label: providers.find((x) => x.id === item.integrationId)?.name,
        }));

    const isLoading = isValidatingDevice || isSavingGpsData || isGettingDeviceType;
    return (
        <Dialog
            open
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="GPS Device"
                onClose={() => toggleDeviceForm()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Recovery Company</Form.Label>
                                <Select
                                    disabled={isLoading}
                                    nowrap
                                    className={clsx(classes.input, !selectedIntegrationId ? 'invalid-field' : '')}
                                    name="selectedIntegrationId"
                                    onChange={(name, value) => onChange({ target: { name, value } })}
                                    value={selectedIntegrationId || ''}
                                    options={providerOptions}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Device</Form.Label>
                                <Form.Control
                                    maxLength="100"
                                    disabled={isLoading}
                                    className={clsx(classes.input, !selectedDeviceId ? 'invalid-field' : '')}
                                    type="text"
                                    name="selectedDeviceId"
                                    value={selectedDeviceId || ''}
                                    onChange={(event) => onChange(event)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    size="small"
                    className={clsx(classes.containedSecondaryInfo, isValidDevice ? classes.validDevice : '')}
                    disabled={!selectedIntegrationId || StringUtils.isEmpty(selectedDeviceId) || isLoading}
                    onClick={onValidateDevice}
                >
                    Validate Device
                </Button>
                <Button
                    size="small"
                    className={classes.containedSecondaryInfo}
                    disabled={!isValidDevice || isLoading}
                    onClick={onSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

GpsDeviceDialog.propTypes = {
    stockNumber: PropTypes.number,
    providers: PropTypes.array,
    integrations: PropTypes.array,
    gpsCompany: PropTypes.string,
    gpsInfo: PropTypes.string,
    gpsDeviceType: PropTypes.string,
    toggleDeviceForm: PropTypes.func.isRequired,
};

GpsDeviceDialog.defaultProps = {
    stockNumber: 0,
    providers: [],
    integrations: [],
    gpsCompany: '',
    gpsInfo: '',
    gpsDeviceType: '',
};

export default GpsDeviceDialog;

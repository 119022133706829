import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';

const useStage = () => {
    const [stages, setStages] = useState([]);
    const { data, loading, error } = useQuery(OpportunityQuery.GET_CRM_STAGE_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getCRMStageList)) {
            const { getCRMStageList } = data;
            const result = getCRMStageList.map((item) => ({ value: item.name, label: item.name }));
            setStages(result);
        }
    }, [data, loading, error]);

    return {
        stages,
    };
};

export default useStage;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

// Components
import { size, clone } from 'lodash';
import { MessageType } from 'utils/enum/ConversationEnum';
import Message from 'components/modules/conversation/read/Message';
import MessageAdd from 'components/modules/conversation/read/MessageAdd';
import ConversationDetailToolbar from 'components/modules/conversation/read/ConversationDetailsToolbar';

// Material UI
import { Box, Divider } from '@material-ui/core';

// Http
import GraphQLClient from 'services/apollo/GraphQLClient';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import ConversationQuery from 'services/graphQL/query/ConversationQuery';
import ConversationSubscription from 'services/graphQL/subscription/ConversationSubscription';
import StringUtils from 'lib/StringUtils';

// Utils
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import Permission from 'utils/enum/Permissions';

class ConversationDetail extends Component {
    constructor(props) {
        super(props);

        this.graphqlClient = new GraphQLClient();

        this.state = {
            contentMessage: {
                messages: [],
                limit: 15,
                totalCount: 0,
            },
            customerName: '',
            customerColor: '',
            idUser: null,
            load: false,
        };

        this.messageSubscription = null;
        this.initBind = this.initBind();
    }

    componentDidMount() {
        const { props: { conversationId } } = this;

        this.getServicesData(conversationId);
        this.subscribeToNewMessage(conversationId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { props } = this;

        if (props.conversationId !== nextProps.conversationId) {
            this.unsubscribeToNewMessage();
            this.getServicesData(nextProps.conversationId, true);
            this.subscribeToNewMessage(nextProps.conversationId);
        }
    }

    componentWillUnmount() {
        this.unsubscribeToNewMessage();
    }

    getServicesData(conversationId = null, isDifferentConversation = false) {
        const { state: { contentMessage: { messages, limit }, contentMessage } } = this;

        if (isDifferentConversation) {
            this.setState({
                contentMessage: Object.assign(contentMessage, {
                    messages: [],
                    limit,
                    offset: 0,
                    totalCount: 0,
                }),
                load: true,
            });
        } else {
            this.setState({
                load: true,
            });
        }

        let offset = size(messages) ? messages.length : 0;
        offset = isDifferentConversation ? 0 : offset;
        const input = {
            id: conversationId,
            offset,
            limit,
        };

        this.graphqlClient
            .query(ConversationQuery.GET_CONVERSATION_DETAILS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCRMConversation) {
                    const recordsService = isDifferentConversation ? [] : messages;
                    const { conversation, totalCount } = data.getCRMConversation;
                    recordsService.unshift(...conversation.messages);

                    const currentAssignee = conversation.assignee ? conversation.assignee.userId : -1;

                    this.setState({
                        contentMessage: Object.assign(contentMessage, {
                            messages: recordsService,
                            totalCount,
                        }),
                        idUser: currentAssignee,
                        customerName: conversation.customerName,
                        customerColor: conversation.color,
                    });
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    unsubscribeToNewMessage() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
    }

    subscribeToNewMessage(conversationId) {
        const input = {
            id: conversationId,
        };

        this.graphqlClient.subscribe(this.validateMessage, ConversationSubscription.ADDED_CRM_MESSAGE, input)
            .then((response) => {
                this.messageSubscription = response;
            });
    }

    // TODO: Pending add graphqlError
    validateMessage(record) {
        const { data: { addedCRMMessage } } = record;

        if (typeof addedCRMMessage === 'object') {
            this.addNewMessage(addedCRMMessage);
        }
    }

    // TODO: Check if the sms is added through the subscription
    addNewMessage(record) {
        const { state: { contentMessage: { messages, totalCount }, contentMessage, idUser } } = this;
        const backUpMessage = clone(messages || []);
        backUpMessage.splice(messages.length, 0, record);
        // TODO: validate for assignee
        let assignee = '';

        if (StringUtils.toUpperCase(record.type) === MessageType.JOINED_AGENT) {
            const { sentBy: { userId } } = record;
            assignee = userId;
        } else {
            assignee = idUser;
        }

        this.setState({
            contentMessage: Object.assign(contentMessage, {
                messages: backUpMessage,
                totalCount: totalCount + 1,
            }),
            idUser: assignee,
        });
    }

    loadMore() {
        const { props: { conversationId } } = this;
        this.getServicesData(conversationId);
    }

    initBind() {
        this.loadMore = this.loadMore.bind(this);
        this.addNewMessage = this.addNewMessage.bind(this);
        this.validateMessage = this.validateMessage.bind(this);
    }

    render() {
        const {
            props: { onToggleDrawer, conversationId }, state: {
                contentMessage: { messages, totalCount }, load, customerName, customerColor, idUser,
            },
        } = this;

        return (
            <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
            >
                <ConversationDetailToolbar
                    onToggleDrawer={onToggleDrawer}
                    customerName={customerName}
                />
                <Divider />
                <InfiniteScroll
                    lengthRecord={messages.length}
                    totalRecord={totalCount}
                    loadMore={this.loadMore}
                    scroll="top"
                    autoScrollBottom
                    load={load}
                >
                    <Message
                        record={messages}
                        customerName={customerName}
                        customerColor={customerColor}
                    />
                </InfiniteScroll>
                {KeyStore.isAllowed(Permission.CRM_CHAT_WRITE) && (
                    <>
                        <Divider />
                        <MessageAdd
                            idUser={idUser}
                            conversationId={conversationId}
                        />
                    </>
                )}
            </Box>
        );
    }
}

ConversationDetail.propTypes = {
    onToggleDrawer: PropTypes.func.isRequired,
    conversationId: PropTypes.string.isRequired,
};

export default ConversationDetail;

/* eslint-disable import/prefer-default-export */

const MetaData = Object.freeze({
    CHECKBOX: 'CHECKBOX',
    CHECKBOX_TEXT: 'CHECKBOX TEXT',
    INTEGER: 'INTEGER',
    DATE: 'DATE',
    TEXT: 'TEXT',
    TITLE: 'TITLE',
    RADIO: 'RADIO',
    FLOAT: 'FLOAT',
    SELECT: 'SELECT',
});

export { MetaData };

import { gql } from '@apollo/client';

export default class SMSQuery {
    static GET_SMS_BY_CRM_ID = gql`
        query getSMSByCRMId($paginate: SMSPaginate!, $crmId: ID!) {
            getSMSByCRMId(paginate: $paginate, crmId: $crmId) {
                totalCount
                data {
                    smsId
                    status
                    message
                    smsChatId
                    direction
                    createdOn
                    createdBy {
                        firstName
                        lastName
                        avatar
                    }
                    errorCode
                }
            }
        }
    `;
}

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import DealStyles from 'styles/modules/DealStyles';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import TitleRegistrationContainer from 'components/containers/deals/create/dealTab/TitleRegistrationContainer';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid, Typography,
} from '@material-ui/core';

const styles = (theme) => DealStyles.dealsForms(theme, fade);

class TitleRegistrationDialog extends Component {
    render() {
        const {
            props: {
                classes, toggleModal, open, lotData: {
                    displayRegistrationFee,
                    displayTagFee,
                    displayTitleFee,
                },
                onChange,
                onApply,
                registration,
                title,
                tags,
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                className={classes.registrationDialogWidth}
                maxWidth="sm"
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title="Title & Registration" onClose={toggleModal} />
                <DialogContent className={classes.dialogContentRegistration}>
                    <Form className={classes.formW100}>
                        <Form.Row>
                            {displayTagFee && (
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labelDialogTags}>Tag</Form.Label>
                                    <Grid container spacing={1}>
                                        <Grid className={classes.inputDialogTags} item xs={10}>
                                            <InputNumber
                                                value={tags || ''}
                                                onChange={(value) => onChange('tags', value)}
                                                showCurrency
                                                thousandSeparator
                                                placeholder="$0.00"
                                                size="sm"
                                            />
                                        </Grid>
                                        <Grid className={classes.styleGrid} item xs={2}>
                                            <Typography className="mathematicExpression">+</Typography>
                                        </Grid>
                                    </Grid>
                                </Form.Group>
                            )}
                            { displayRegistrationFee && (
                                <Form.Group as={Col} className="full-width-date-picker">
                                    <Form.Label className={classes.labelDialogTags}>Registration</Form.Label>
                                    <Grid container spacing={1}>
                                        <Grid className={classes.inputDialogTags} item xs={10}>
                                            <InputNumber
                                                value={registration || ''}
                                                onChange={(value) => onChange('registration', value)}
                                                showCurrency
                                                thousandSeparator
                                                placeholder="$0.00"
                                                size="sm"
                                            />
                                        </Grid>
                                        <Grid className={classes.styleGrid} item xs={2}>
                                            <Typography className="mathematicExpression">+</Typography>
                                        </Grid>
                                    </Grid>
                                </Form.Group>
                            )}
                        </Form.Row>
                        <Form.Row className={classes.lastRow}>
                            { displayTitleFee && (
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labelDialogTags}>Title</Form.Label>
                                    <Grid container spacing={1}>
                                        <Grid className={classes.inputDialogTags} item xs={10}>
                                            <InputNumber
                                                value={title || ''}
                                                onChange={(value) => onChange('title', value)}
                                                showCurrency
                                                thousandSeparator
                                                placeholder="$0.00"
                                                size="sm"
                                            />
                                        </Grid>
                                        <Grid className={classes.styleGrid} item xs={2}>
                                            <Typography className="mathematicExpression">+</Typography>
                                        </Grid>
                                    </Grid>
                                </Form.Group>
                            )}
                            <Form.Group as={Col} />
                        </Form.Row>
                    </Form>
                </DialogContent>
                <DialogActions
                    titlePrimary="Apply"
                    onClickSecondary={toggleModal}
                    onClickPrimary={onApply}
                />
            </Dialog>
        );
    }
}

TitleRegistrationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    lotData: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    registration: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    tags: PropTypes.number.isRequired,
};

export default withStyles(styles)(TitleRegistrationContainer(TitleRegistrationDialog));

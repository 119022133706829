import { gql } from '@apollo/client';

export default class UserNotificationMutation {
    static MARK_USER_NOTIFICATION_BY_TYPE_AS_READ = gql`
        mutation markUserNotificationByTypeAsRead($type: String!) {
            markUserNotificationByTypeAsRead(type: $type)
        }
    `;

    static MARK_USER_NOTIFICATION_AS_READ_BY_NOTIFICATION_ID = gql`
        mutation markUserNotificationAsReadByNotificationId($notificationId: ID!, $type: String!) {
            markUserNotificationAsReadByNotificationId(notificationId: $notificationId, type: $type)
        }
    `;
}

import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';

// Components and Others
import clsx from 'clsx';
import { isFinite } from 'lodash';
import { Button, fade } from '@material-ui/core';
import Header from 'components/widgets/Header';
import ButtonStyles from 'styles/theme/Button';
import DealStyles from 'styles/modules/DealStyles';
import Container from 'components/widgets/Container';
import BackButton from 'components/widgets/BackButton';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import DealDetailHeader from 'components/widgets/deals/DealDetailHeader';
import DealTabs from 'components/modules/deals/read/dealTab/DealTabs';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => DealStyles.details(theme));

const DealDetail = () => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const history = useHistory();
    const { id } = useParams();
    const accountNumber = parseInt(id, 10);

    // Implement redirect to error page 403 from ProtectedRouter component
    if (!isFinite(accountNumber)) {
        history.push('/');
        return null;
    }

    return (
        <Container className="floatBox">
            <Header>
                <div className={clsx('d-flex-center', classes.header)}>
                    <div className={clsx(classes.numberContainer, classes.dealContainer)}>
                        <BackButton route="/deals" />
                    </div>
                    <div className={classes.headerInfoContainer}>
                        <DealDetailHeader dealId={accountNumber} />
                    </div>
                </div>
            </Header>
            <Container className={classes.boxContainer}>
                <a href={`/${modules.DEALS}/${accountNumber}/menu`} target="_blank" rel="noopener noreferrer">
                    <Button
                        className={clsx(classes.menuButton, classes.containedSecondaryInfo)}
                        size="small"
                    >
                        Menu
                    </Button>
                </a>
                <DealTabs accountNumber={accountNumber} />
            </Container>
        </Container>
    );
};

export default DealDetail;

import { concat, isEmpty, round } from 'lodash';

export const ACTION_TYPES = {
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    ADD_NEW_LINE: 'addNewLine',
    SET_SPLIT_AMOUNT_SETTTINGS: 'setSplitAmountSettings',
    SET_ON_DELETE: 'setOnDelete',
    ADJUST_AMOUNT: 'adjustAmount',
    SET_RECORDS_FROM_PROCESS: 'setRecordsFromProcess',
    SET_STATE_VALUES: 'setStateValues',
    VALIDATE_ADD_LINE: 'validateAddLine',
    SET_ON_SPLIT: 'setOnSplit',
    SET_RECORDS_FROM_SPLIT: 'setRecordsFromSplit',
    CLEAR_LINES: 'clearLines',
};

const JournalDistributionReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.SET_RECORDS: {
        const newAmount = action.value.reduce((sum, next) => sum + next.amount, 0);
        if (action.notifyChange) action.notifyChange(newAmount);
        if (action.notifyDirty) action.notifyDirty();

        let amountSettings = 0;
        let newRecords = action.value;

        if (state.differenceToAdjust !== 0) amountSettings = state.splitSettings.amount;

        if (state.differenceToAdjust === 0) {
            // remove group id
            newRecords = action.value.map(({ groupId, ...item }) => (item));
        }

        return {
            ...state,
            records: newRecords,
            splitSettings: { ...state.splitSettings, amount: amountSettings },
            entryId: action?.newEntryId ?? state.entryId,
            deletingMultipleLines: action?.deletingMultipleLines ?? false,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const keyValue = 'entryId';
        let amountSettings = 0;
        let hasPurchasedCreditAccount = false;
        const {
            value, additionalFields, columnId, cell,
        } = action;

        let newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;

                if (columnId === 'accountNumber' && additionalFields) {
                    newItem.isControlled = additionalFields.isControlled;
                    newItem.controlledBy = additionalFields.controlledBy;
                    newItem.accountDescription = additionalFields.fullDescription;
                }
            }

            if (columnId === 'accountNumber'
                && state.inventoryPurchasedCreditAccounts.length > 0
                && state.inventoryPurchasedCreditAccounts.includes(newItem.accountNumber)
            ) {
                hasPurchasedCreditAccount = true;
            }

            return newItem;
        });

        let differenceToAdjust = 0;
        if (state.differenceToAdjust !== 0) {
            const sumAmount = newRecords.filter((item) => !isEmpty(item.groupId)).reduce((sum, next) => round(sum + next.amount, 2), 0);
            if (sumAmount !== state.splitSettings.amount) differenceToAdjust = round(sumAmount - state.splitSettings.amount, 2);
            amountSettings = state.splitSettings.amount;
        }

        if (differenceToAdjust === 0) {
            // remove group id
            newRecords = newRecords.map(({ groupId, ...item }) => (item));
            amountSettings = 0;
        }

        if (columnId === 'accountNumber') action.notifyInventoryPurchaseLineAdded(hasPurchasedCreditAccount);

        if (columnId === 'amount') {
            const newAmount = newRecords.reduce((sum, next) => sum + next.amount, 0);
            if (action.notifyChange) action.notifyChange(newAmount);
        } else if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            records: newRecords,
            differenceToAdjust,
            splitSettings: { ...state.splitSettings, amount: amountSettings },
        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const lastRow = state.records.length > 0 ? state.records[state.records.length - 1] : null;

        const newLine = {
            entryId: state.entryId - 1,
            accountNumber: lastRow?.accountNumber ?? 0,
            amount: 0,
            controlNumber: lastRow?.controlNumber ?? '',
            memo: lastRow?.memo ?? '',
            isControlled: lastRow?.isControlled ?? false,
            controlledBy: lastRow?.controlledBy ?? '',
            lotId: lastRow?.lotId ?? 0,
        };
        const data = concat(state.records, newLine);

        return {
            ...state,
            records: data,
            entryId: newLine.entryId,
        };
    }
    case ACTION_TYPES.SET_SPLIT_AMOUNT_SETTTINGS:
    {
        return {
            ...state,
            splitSettings: {
                ...state.splitSettings,
                [action.id]: action.value,
                isControlled: action.additionalFields?.isControlled || state.splitSettings.isControlled,
                controlledBy: action.additionalFields?.controlledBy || state.splitSettings.controlledBy,
                accountDescription: action.additionalFields?.fullDescription || state.splitSettings.accountDescription,
            },
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        let multipleLines = [];
        const currentRecord = state.records.find((item) => item.entryId === action.value);

        if (currentRecord?.processDetailRecordId) {
            multipleLines = state.records.filter((item) => item.processDetailRecordId === currentRecord.processDetailRecordId);
        }

        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
            deletingMultipleLines: multipleLines?.length > 1,
        };
    }
    case ACTION_TYPES.ADJUST_AMOUNT:
        return { ...state, differenceToAdjust: action.differenceToAdjust };
    case ACTION_TYPES.SET_RECORDS_FROM_PROCESS: {
        const newAmount = action.value.reduce((sum, next) => sum + next.amount, 0);
        action.notifyChange(newAmount);
        action.notifyDirty();

        const maxEntryId = action.value.length > 0 ? (action.value.length * -1) : 0;

        return {
            ...state,
            records: action.value,
            entryId: maxEntryId,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.VALIDATE_ADD_LINE: {
        const { rowIndex, event } = action;

        if (rowIndex === state.records.length - 1) action.method(state.records, event);

        return {
            ...state,
        };
    }
    case ACTION_TYPES.SET_ON_SPLIT: {
        const splitLines = state.records.filter((item) => item.processDetailRecordId === action.value.processDetailRecordId);

        return {
            ...state,
            recordToSplit: action.value,
            splitedRecords: splitLines.length > 1 ? splitLines : null,
        };
    }
    case ACTION_TYPES.SET_RECORDS_FROM_SPLIT: {
        const items = action.value;
        const itemId = items[0].processDetailRecordId;
        let { entryId } = state;

        const newRecords = state.records.filter((item) => item.processDetailRecordId !== itemId);

        items.forEach((element, index) => {
            entryId = state.entryId - (index + 1);
            newRecords.push({
                ...element,
                entryId,
            });
        });

        if (action.notifyDirty) action.notifyDirty();

        return {
            ...state,
            recordToSplit: null,
            splitedRecords: null,
            records: newRecords,
            entryId,
        };
    }
    case ACTION_TYPES.CLEAR_LINES:
    {
        const newLine = {
            entryId: -1,
            accountNumber: 0,
            amount: 0,
            controlNumber: '',
            memo: '',
            isControlled: false,
            controlledBy: '',
            lotId: 0,
        };

        return {
            ...state,
            records: [newLine],
            entryId: newLine.entryId,
        };
    }
    default:
        return state;
    }
};

export default JournalDistributionReducer;

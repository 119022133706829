import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ModalUtils from 'utils/ModalUtils';
import { CloudUploadOutlinedIcon } from 'components/icons';
import { FetchPolicy } from 'utils/enum/Core';
import LotQuery from 'services/graphQL/query/LotQuery';
import { useLazyQuery } from '@apollo/client';
import { ExcelRenderer } from 'react-excel-renderer';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';

const UploadRowsExcel = ({ setLoadRecords }) => {
    const [excelData, setExcelData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [getCompanyLots] = useLazyQuery(LotQuery.GET_LOTS, {
        onCompleted: (res) => {
            const lots = res.lotList;
            if (lots) {
                setLoadRecords({ records: excelData, lots });
            }
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const fileHandler = (event) => {
        if (event) {
            const { target: { files } } = event;
            const fileObj = files[0];
            setLoading(true);

            // just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, response) => {
                if (err) {
                    ModalUtils.errorMessage(err);
                } else {
                    setExcelData(response);
                    getCompanyLots();
                }
            });
        }
    };

    return (
        <>
            <input
                id="contained-button-file"
                onChange={fileHandler}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
            />
            <Button
                htmlFor="contained-button-file"
                variant="outlined"
                startIcon={<CloudUploadOutlinedIcon />}
                size="small"
                onClick={() => document.getElementById('contained-button-file').click()}
            >
                Upload Rows
            </Button>
            <If condition={loading}>
                <DialogActionMessage message="loading excel data... " />
            </If>
        </>
    );
};

UploadRowsExcel.propTypes = {
    setLoadRecords: PropTypes.func,
};

UploadRowsExcel.defaultProps = {
    setLoadRecords: () => null,
};

export default UploadRowsExcel;

import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import ArrayUtils from 'lib/ArrayUtils';
import payrollSettingsQuery from 'services/graphQL/query/payroll/Settings';

const useDepartmentList = () => {
    const [departmentList, setDepartmentList] = useState([]);
    const { data, loading, error } = useQuery(payrollSettingsQuery.GET_DEPARTMENT_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.departmentList)) {
            const { departmentList: departments } = data;
            const sortedList = ArrayUtils.sortByObjectField(departments, 'description');
            const result = sortedList.map((item) => ({ value: item.departmentId, label: item.description }));
            setDepartmentList([{
                label: 'Department Wide',
                value: 0,
            }, ...result]);
        }
    }, [data, loading, error]);

    return {
        departmentList,
    };
};

export default useDepartmentList;

import * as yup from 'yup';

const {
    object, number, array, string,
} = yup;

const OperationCodeSchema = object().shape({
    opCode: string().required(),
    customerStates: string().required(),
    cause: string().required(),
    hours: number().required(),
    laborAmount: number().required(),
});

const OperationCodeDetailSchema = object().shape({
    partInventoryId: number().required().moreThan(0),
    quantity: number().required().moreThan(0),
});

const OperationCodeDetailSchemaList = array().of(
    OperationCodeDetailSchema,
);

export {
    OperationCodeDetailSchemaList,
    OperationCodeDetailSchema,
    OperationCodeSchema,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import {
    makeStyles, Button, Grid,
} from '@material-ui/core';
import { remove } from 'lodash';
import { FetchPolicy } from 'utils/enum/Core';
import { GPS_COMMAND } from 'utils/enum/GPSEnum';
import StringUtils from 'lib/StringUtils';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import GpsHelper from 'utils/GpsHelper';
import GpsQuery from 'services/graphQL/query/gps/GpsQuery';
import GpsMutation from 'services/graphQL/mutate/gps/GpsMutation';
import GpsLocation from 'components/modules/inventory/read/GpsLocation';
import GpsDeviceDialog from 'components/modules/inventory/create/dialogs/GpsDeviceDialog';
import GpsCommandHistory from 'components/modules/inventory/list/GpsCommandHistory';
import GpsHistoryVehicle from 'components/modules/inventory/read/GpsHistoryVehicle';
import GpsHistoryCommand from 'components/modules/inventory/read/GpsHistoryCommand';

// Icons
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        padding: '20px',
    },
    header: {
        justifyContent: 'flex-end',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        '& > button': {
            marginRight: '5px',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '14px',
            lineHeight: '1.4',
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginTop: '30px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                width: '100% !important',
                marginBottom: '15px',
            },
        },
        '& > div:nth-child(1)': {
            width: '65%',
        },
        '& > div:nth-child(2)': {
            width: '35%',
            marginRight: '5px',
            justifyContent: 'flex-end',
            '& > div:nth-child(1)': {
                color: theme.palette.text.link,
                fontSize: '13px',
                fontWeight: 'bold',
                marginRight: '10px',
                [theme.breakpoints.down('md')]: {
                    marginBottom: '5px',
                },
            },
            '& > div:nth-child(2)': {
                width: '200px',
                marginRight: '10px',
                [theme.breakpoints.down('md')]: {
                    marginBottom: '5px',
                    marginRight: 0,
                },
            },
            [theme.breakpoints.down('md')]: {
                '& > div:nth-child(3) > button': {
                    width: '200px',
                },
            },
        },
        '& > div:nth-child(1) > div': {
            fontSize: '14px',
            display: 'flex',
            marginRight: '20px',
            '& > div:nth-child(1)': {
                color: theme.palette.text.link,
                fontWeight: 'bold',
                marginRight: '10px',
            },
        },
    },
    warningMessage: {
        fontSize: '13px',
        fontWeight: 500,
    },
}));

const GpsTab = ({
    onReload,
    record,
    canWrite,
}) => {
    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        commandHistoryKey: uuid(),
        providers: [],
        integrations: [],
        availableCommands: [],
        stockNumber: null,
        gpsCompany: null,
        gpsInfo: null,
        gpsDeviceType: null,
        selectedCommand: null,
        lastExecutedCommand: null,
        isDeviceFormOpen: false,
        confirmDialogOpen: false,
        isLocationDialogOpen: false,
        isLocationHistoryDialogOpen: false,
        isCommandHistoryDialogOpen: false,
    });

    const {
        commandHistoryKey,
        providers,
        integrations,
        availableCommands,
        stockNumber,
        gpsCompany,
        gpsInfo,
        gpsDeviceType,
        selectedCommand,
        lastExecutedCommand,
        isDeviceFormOpen,
        confirmDialogOpen,
        isLocationDialogOpen,
        isLocationHistoryDialogOpen,
        isCommandHistoryDialogOpen,
    } = state;
    const isDeviceAttached = !!gpsCompany && !!gpsInfo;

    const {
        data: IntegrationsData,
        loading: loadingIntegrations,
        error: errorLoadingIntegrations,
    } = useQuery(GpsQuery.GET_DEALER_GPS_INTEGRATIONS_BASIC, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: providers.length === 0,
    });

    const {
        data: providersData,
        loading: loadingProviders,
        error: errorLoadingProviders,
    } = useQuery(GpsQuery.GET_GPS_INTEGRATION_PROVIDERS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveGpsData, { loading: isSavingGpsData }] = useMutation(GpsMutation.UPDATE_VEHICLE_GPS, {
        onCompleted: (response) => {
            if (response?.updateVehicleGps) {
                onReload();
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [sendCommand, { loading: isSendingCommand }] = useMutation(GpsMutation.SEND_COMMAND_TO_GPS_DEVICE, {
        onCompleted: (response) => {
            const data = response?.sendCommandToGPSDevice;
            if (data) {
                const { success, body } = data;
                if (success) ModalUtils.successMessage(null, 'Command executed successfully');

                setState((previousState) => ({
                    ...previousState,
                    commandHistoryKey: uuid(),
                    lastExecutedCommand: {
                        command: selectedCommand,
                        success,
                        body,
                    },
                }));
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [updateGPSTone] = useMutation(GpsMutation.UPDATE_GPS_ACTIVE_TONE, {
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        const detail = record?.detail;
        if (detail) {
            setState((prevState) => ({
                ...prevState,
                stockNumber: detail.stockNumber,
                gpsCompany: detail.gpsCompany,
                gpsInfo: detail.gpsInfo,
                gpsDeviceType: detail.gpsDeviceType,
            }));
        }
    }, [record]);

    useEffect(() => {
        if (errorLoadingIntegrations) {
            ModalUtils.errorMessage(errorLoadingIntegrations?.graphQLErrors);
            return;
        }

        if (!loadingIntegrations && IntegrationsData) {
            const { getDealerGpsIntegrations } = IntegrationsData;
            if (ArrayUtils.isNotEmpty(getDealerGpsIntegrations)) {
                setState((previousState) => ({
                    ...previousState,
                    integrations: getDealerGpsIntegrations,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingIntegrations, errorLoadingIntegrations]);

    useEffect(() => {
        if (errorLoadingProviders) {
            ModalUtils.errorMessage(errorLoadingProviders?.graphQLErrors);
            return;
        }

        if (!loadingProviders && providersData) {
            const { getGpsIntegrationProviders } = providersData;
            if (ArrayUtils.isNotEmpty(getGpsIntegrationProviders)) {
                setState((previousState) => ({
                    ...previousState,
                    providers: getGpsIntegrationProviders,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProviders, errorLoadingProviders]);

    useEffect(() => {
        if (lastExecutedCommand?.command && lastExecutedCommand.success) {
            const { command } = lastExecutedCommand;

            switch (command) {
            case GPS_COMMAND.REQUEST_LOCATION:
                setState((previousState) => ({
                    ...previousState,
                    isLocationDialogOpen: true,
                }));

                break;
            case GPS_COMMAND.REQUEST_LOCATION_HISTORY:
                setState((previousState) => ({
                    ...previousState,
                    isLocationHistoryDialogOpen: true,
                }));

                break;
            case GPS_COMMAND.REQUEST_COMMAND_HISTORY:
                setState((previousState) => ({
                    ...previousState,
                    isCommandHistoryDialogOpen: true,
                }));

                break;
            case GPS_COMMAND.TONE_1_ON:
            case GPS_COMMAND.TONE_2_ON:
            case GPS_COMMAND.TONE_3_ON:
            case GPS_COMMAND.TONE_OFF:
                const enableTone = command.indexOf('_ON') > -1;
                updateGPSTone({
                    variables: {
                        stockNumber,
                        gpsTone: enableTone ? `CTON${StringUtils.getOnlyNumbers(command)}` : null,
                    },
                });

                break;
            case GPS_COMMAND.ENABLE_STARTER_INTERRUPT:
            case GPS_COMMAND.DISABLE_STARTER_INTERRUPT:
                const enable = command.indexOf('ENABLE') > -1;
                updateGPSTone({
                    variables: {
                        stockNumber,
                        gpsTone: enable ? 'STARTER_INTERRUPT' : null,
                    },
                });

                break;
            default:
                break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastExecutedCommand]);

    const getDeviceTypeCommandList = async (deviceType) => {
        try {
            const { data } = await client.query({
                query: GpsQuery.GET_PROVIDER_COMMAND_LIST,
                variables: {
                    deviceType,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                const response = data.getProviderCommandList;
                return response;
            }

            return null;
        } catch (error) {
            return null;
        }
    };

    const filterCommandsBasedOnConfiguration = (commands, configuration) => {
        if (!commands || !configuration) return null;

        const {
            tone1 = false,
            tone2 = false,
            tone3 = false,
            starterInterrupt = false,
            commandHistory = false,
            getBatteryInfo = false,
            setEnduranceMode = false,
            setTripMode = false,
            setActiveMode = false,
            setRecoveryMode = false,
        } = configuration;

        if (tone1) remove(commands, { value: GPS_COMMAND.TONE_1_ON });
        if (tone2) remove(commands, { value: GPS_COMMAND.TONE_2_ON });
        if (tone3) remove(commands, { value: GPS_COMMAND.TONE_3_ON });
        if (starterInterrupt) remove(commands, (item) => [GPS_COMMAND.ENABLE_STARTER_INTERRUPT, GPS_COMMAND.DISABLE_STARTER_INTERRUPT].includes(item.value));
        if (!commandHistory) remove(commands, { value: GPS_COMMAND.REQUEST_COMMAND_HISTORY });
        if (!getBatteryInfo) remove(commands, { value: GPS_COMMAND.GET_BATTERY_INFO });
        if (!setEnduranceMode) remove(commands, { value: GPS_COMMAND.SET_ENDURANCE_MODE });
        if (!setTripMode) remove(commands, { value: GPS_COMMAND.SET_TRIP_MODE });
        if (!setActiveMode) remove(commands, { value: GPS_COMMAND.SET_ACTIVE_MODE });
        if (!setRecoveryMode) remove(commands, { value: GPS_COMMAND.SET_RECOVERY_MODE });

        return commands;
    };

    useEffect(() => {
        const getCommandList = async () => {
            if (
                gpsCompany
                && gpsInfo
                && ArrayUtils.isNotEmpty(providers)
                && ArrayUtils.isNotEmpty(integrations)
            ) {
                const configuration = GpsHelper.getIntegrationConfiguration(providers, integrations, gpsCompany);
                const { hasDeviceType } = configuration;

                let commandDropdownOptions = null;
                if (!hasDeviceType) commandDropdownOptions = GpsHelper.getCommandDropdownOptions();
                if (hasDeviceType && gpsDeviceType) commandDropdownOptions = await getDeviceTypeCommandList(gpsDeviceType);

                setState((previousState) => ({
                    ...previousState,
                    availableCommands: filterCommandsBasedOnConfiguration(commandDropdownOptions, configuration) ?? [],
                }));
            }
        };

        getCommandList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gpsCompany, gpsInfo, gpsDeviceType, providers, integrations]);

    const toggleDeviceForm = (wasSaved = false) => {
        if (wasSaved) {
            onReload();
            return;
        }

        setState((previousState) => ({
            ...previousState,
            isDeviceFormOpen: !isDeviceFormOpen,
        }));
    };

    const toggleDeletePrompt = () => {
        setState((previousState) => ({
            ...previousState,
            confirmDialogOpen: !confirmDialogOpen,
        }));
    };

    const toggleLocationDialog = () => {
        setState((previousState) => ({
            ...previousState,
            isLocationDialogOpen: !isLocationDialogOpen,
        }));
    };

    const toggleLocationHistoryDialog = () => {
        setState((previousState) => ({
            ...previousState,
            isLocationHistoryDialogOpen: !isLocationHistoryDialogOpen,
        }));
    };

    const toggleCommandHistoryDialog = () => {
        setState((previousState) => ({
            ...previousState,
            isCommandHistoryDialogOpen: !isCommandHistoryDialogOpen,
        }));
    };

    const openCommandResultDialog = (command, body) => {
        setState((previousState) => ({
            ...previousState,
            isLocationDialogOpen: command === GPS_COMMAND.REQUEST_LOCATION,
            isLocationHistoryDialogOpen: command === GPS_COMMAND.REQUEST_LOCATION_HISTORY,
            isCommandHistoryDialogOpen: command === GPS_COMMAND.REQUEST_COMMAND_HISTORY,
            lastExecutedCommand: {
                body: JSON.stringify(body),
            },
        }));
    };

    const onDeleteDevice = () => {
        saveGpsData({
            variables: {
                stockNumber,
                integrationId: null,
            },
        });
    };

    const onChange = (name, value) => {
        setState((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };

    const onSendCommand = () => {
        if (!selectedCommand) return;

        sendCommand({
            variables: {
                input: {
                    command: selectedCommand,
                    stockNumber,
                },
            },
        });
    };

    return (
        <>
            <Grid className={classes.main} container>
                {canWrite && (
                    <Grid item xs={12} className={classes.header}>
                        {!isDeviceAttached && (
                            <Button
                                disabled={integrations.length === 0}
                                className={classes.containedSecondaryInfo}
                                size="medium"
                                startIcon={<AddOutlinedIcon />}
                                onClick={() => toggleDeviceForm()}
                            >
                                Add Device
                            </Button>
                        )}
                        {isDeviceAttached && (
                            <>
                                <Button
                                    disabled={integrations.length === 0}
                                    className={classes.containedSecondaryInfo}
                                    size="medium"
                                    startIcon={<EditOutlinedIcon />}
                                    onClick={() => toggleDeviceForm()}
                                >
                                    Edit Device
                                </Button>
                                <Button
                                    disabled={integrations.length === 0}
                                    className={classes.containedError}
                                    size="medium"
                                    startIcon={<HighlightOffOutlinedIcon />}
                                    onClick={toggleDeletePrompt}
                                >
                                    Remove Device
                                </Button>
                            </>
                        )}
                    </Grid>
                )}
                {isDeviceAttached && (
                    <>
                        <Grid item xs={12} className={classes.info}>
                            <div>
                                <div>
                                    <div>Recovery Company:</div>
                                    <div>{gpsCompany}</div>
                                </div>
                                <div>
                                    <div>Device:</div>
                                    <div>{gpsInfo}</div>
                                </div>
                                {gpsDeviceType && (
                                    <div>
                                        <div>Device Type:</div>
                                        <div>{gpsDeviceType}</div>
                                    </div>
                                )}
                            </div>
                            {canWrite && (
                                <div>
                                    <div>Command/Request:</div>
                                    <div>
                                        <Select
                                            nowrap
                                            disabled={isSendingCommand}
                                            className={classes.input}
                                            name="selectedCommand"
                                            onChange={(name, value) => onChange(name, value)}
                                            value={selectedCommand}
                                            options={availableCommands}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            disabled={integrations.length === 0 || !selectedCommand || isSendingCommand}
                                            className={classes.containedSecondaryInfo}
                                            size="medium"
                                            startIcon={<NavigateNextOutlinedIcon />}
                                            onClick={onSendCommand}
                                        >
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <GpsCommandHistory
                                key={commandHistoryKey}
                                stockNumber={stockNumber}
                                gpsCompany={gpsCompany}
                                openCommandResultDialog={openCommandResultDialog}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.warningMessage}>
                            Some commands&apos; executions will be listed eventually. Refresh if needed.
                        </Grid>
                    </>
                )}
            </Grid>
            {isDeviceFormOpen && (
                <GpsDeviceDialog
                    stockNumber={stockNumber}
                    providers={providers}
                    integrations={integrations}
                    gpsCompany={gpsCompany}
                    gpsInfo={gpsInfo}
                    gpsDeviceType={gpsDeviceType}
                    toggleDeviceForm={toggleDeviceForm}
                />
            )}
            {isLocationDialogOpen && (
                <GpsLocation
                    gpsCompany={gpsCompany}
                    lastExecutedCommand={lastExecutedCommand}
                    toggleLocationDialog={toggleLocationDialog}
                />
            )}
            {isLocationHistoryDialogOpen && (
                <GpsHistoryVehicle
                    gpsCompany={gpsCompany}
                    lastExecutedCommand={lastExecutedCommand}
                    toggleLocationHistoryDialog={toggleLocationHistoryDialog}
                />
            )}
            {isCommandHistoryDialogOpen && (
                <GpsHistoryCommand
                    gpsCompany={gpsCompany}
                    lastExecutedCommand={lastExecutedCommand}
                    toggleCommandHistoryDialog={toggleCommandHistoryDialog}
                />
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this device?"
                open={confirmDialogOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                disablePrimaryButton={isSavingGpsData}
                disableSecondaryButton={isSavingGpsData}
                onClose={toggleDeletePrompt}
                onClickSecondary={toggleDeletePrompt}
                onClickPrimary={onDeleteDevice}
            />
        </>
    );
};

GpsTab.propTypes = {
    onReload: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    canWrite: PropTypes.bool.isRequired,
};

export default GpsTab;

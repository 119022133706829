import React, {
    useEffect,
    useContext,
    useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid,
    fade,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import Select from 'components/widgets/Select';
import { isEmpty } from 'lodash';

import { FetchPolicy } from 'utils/enum/Core';
import { useLazyQuery, useMutation } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import DealsSettingsMutate from 'services/graphQL/mutate/DealsSettingsMutate';
import { Col, Form } from 'react-bootstrap';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';

const ACTION_TYPES = {
    ON_CHANGE_LOT: 'onChangeLot',
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_AUTOZOOM_CATALOG: 'setAutoZoomCatalog',
    SET_AUTOZOOM_LOT_CONFIG: 'setAutoZoomLotConfig',
    SET_AUTOZOOM_LOT_CONFIG_CHANGED: 'setAutoZoomLotConfigChanged',
    SET_AUTOZOOM_AVAILABLE: 'setAutoZoomAvailable',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD:
    case ACTION_TYPES.SET_RECORD_CHANGED:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.SET_AUTOZOOM_LOT_CONFIG:
    case ACTION_TYPES.SET_AUTOZOOM_LOT_CONFIG_CHANGED:
        return {
            ...state,
            autoZoomRecord: action.value,
        };
    case ACTION_TYPES.SET_AUTOZOOM_CATALOG:
        return {
            ...state,
            autoZoomCatalogs: action.value,
        };
    case ACTION_TYPES.ON_CHANGE_LOT:
        return update(state, {
            lotId: { $set: action.payload },
        });
    case ACTION_TYPES.SET_AUTOZOOM_AVAILABLE:
        return {
            ...state,
            isAutoZoomAvailable: action.value,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '0.875rem',
    },
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    input: {
        fontSize: '14px',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    alignCheckBox: {
        marginBottom: '10px',
        marginLeft: '10px',
    },
    labelCheck: {
        marginBottom: 0,
        paddingTop: '10px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const DealsIntegrationSettings = ({ writable }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation = {} } = useContext(UserContext);
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, {
        lotId: keyStore.getSelectedLot().lotId,
        isAutoZoomAvailable: false,
        record: {
            secureCloseEnabled: false,
        },
        autoZoomRecord: {
            approverId: 0,
            locationId: 0,
            salespersonId: 0,
        },
        autoZoomCatalogs: {
            autoZoomApprovers: [],
            autoZoomLocations: [],
            autoZoomSalesPersons: [],
        },
    });

    const availableLots = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    const {
        record,
        autoZoomCatalogs,
        autoZoomRecord,
        isAutoZoomAvailable,
        lotId,
    } = state;

    const [getSettings, { loading }] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_INTEGRATION, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: response.getDealSettingsIntegration || {},
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getLotname = () => {
        let { lotName } = keyStore.getSelectedLot();
        if (!isEmpty(availableLots)) {
            const currentLot = availableLots.find((obj) => obj.value === state.lotId);
            if (!isEmpty(currentLot)) {
                lotName = currentLot.label;
            }
        }

        return lotName;
    };

    useEffect(() => {
        const lotName = getLotname();

        if (lotName) {
            getSettings({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lotId]);

    const [getAutoZoomLotConfig, { loading: loadingAutoZoomLotConfig }] = useLazyQuery(DealsSettingsQuery.AUTOZOOM_LOT_CONFIG, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_AUTOZOOM_LOT_CONFIG,
                    value: response.getDealSettingsAutoZoomLotConfig || {},
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        const lotName = getLotname();

        if (lotName) {
            getAutoZoomLotConfig({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lotId]);

    const [getAutoZoomCatalogs, { loading: loadingAutoZoomCatalogs }] = useLazyQuery(DealsSettingsQuery.AUTOZOOM_CATALOGS_LIST, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_AUTOZOOM_CATALOG,
                    value: response.getDealSettingsAutoZoomCatalogs || {},
                });
                dispatch({
                    type: ACTION_TYPES.SET_AUTOZOOM_AVAILABLE,
                    value: true,
                });
            }
        },
        onError: () => {},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        getAutoZoomCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [saveIntegrationSettings, { loading: isSavingSettings }] = useMutation(DealsSettingsMutate.SAVE_DEALS_SETTINGS_INTEGRATIONS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Deal Settings saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Deal Settings');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [saveAutoZoomLotConfig, { loading: isSavingAutoZoom }] = useMutation(DealsSettingsMutate.SAVE_AUTOZOOM_LOT_CONFIG, {
        onCompleted: (response) => {
            if (!response) {
                ModalUtils.errorMessage(null, 'There was an error trying to save AutoZoom Settings');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const saveSettings = () => {
        const currentLot = availableLots.find((obj) => obj.value === lotId);

        if (currentLot) {
            saveIntegrationSettings({
                variables: {
                    input: record,
                    lotName: currentLot.label,
                },
            });

            if (isAutoZoomAvailable) {
                saveAutoZoomLotConfig({
                    variables: {
                        input: {
                            locationId: autoZoomRecord.locationId || 0,
                            approverId: autoZoomRecord.approverId || 0,
                            salespersonId: autoZoomRecord.salespersonId || 0,
                            lotName: currentLot.label,
                        },
                    },
                });
            }
        }
    };

    const onChange = async (field, value) => {
        if (value === record[field]) return;
        const currentRecord = { ...record };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeLot = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_LOT,
            payload: value,
        });
    };

    const onChangeAutoZoom = (field, value) => {
        if (value === autoZoomRecord[field]) return;
        const currentRecord = { ...autoZoomRecord };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_AUTOZOOM_LOT_CONFIG_CHANGED,
            value: currentRecord,
        });
    };

    const getCatalog = (field) => {
        let result = [];

        if (!isEmpty(autoZoomCatalogs)) {
            const catalog = autoZoomCatalogs[field];
            if (!isEmpty(catalog)) {
                result = catalog.map((item) => ({ value: Number(item.id), label: item.name }));
            }
        }
        return result;
    };

    const shouldDisableButton = () => ((loading || loadingAutoZoomLotConfig || loadingAutoZoomCatalogs || isSavingAutoZoom || isSavingSettings));
    const saveButtonText = () => ((isSavingAutoZoom || isSavingSettings) ? 'Saving' : 'Save');

    return (
        <>
            <Grid container className={classes.box}>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Lot</Form.Label>
                            <Select
                                size="sm"
                                name="lotId"
                                value={state.lotId}
                                placeholder="Select"
                                options={availableLots}
                                onChange={onChangeLot}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>AutoZoom Location Id</Form.Label>
                            <Select
                                name="locationId"
                                value={(autoZoomRecord.locationId)}
                                options={getCatalog('autoZoomLocations')}
                                disabled={!isAutoZoomAvailable}
                                placeholder="Select"
                                maxMenuHeight={140}
                                onChange={onChangeAutoZoom}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>AutoZoom Default Sales Person</Form.Label>
                            <Select
                                name="salespersonId"
                                value={autoZoomRecord.salespersonId}
                                options={getCatalog('autoZoomSalesPersons')}
                                disabled={!isAutoZoomAvailable}
                                placeholder="Select"
                                maxMenuHeight={140}
                                onChange={onChangeAutoZoom}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>AutoZoom Default Approver</Form.Label>
                            <Select
                                name="approverId"
                                value={autoZoomRecord.approverId}
                                options={getCatalog('autoZoomApprovers')}
                                placeholder="Select"
                                disabled={!isAutoZoomAvailable}
                                maxMenuHeight={140}
                                onChange={onChangeAutoZoom}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Form.Group as={Col}>
                            <Form.Check
                                onChange={(e) => onChange('secureCloseEnabled', e.target.checked)}
                                checked={record.secureCloseEnabled}
                                className={classes.alignCheckBox}
                                inline
                                label="Use Secure Close"
                            />
                        </Form.Group>
                    </Grid>
                </Grid>

                <If condition={writable}>
                    <Grid container>
                        <Grid item lg={3} md={4} xs={6}>
                            <Form.Group className={classes.customTabsSave} as={Col}>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    disabled={shouldDisableButton()}
                                    onClick={saveSettings}
                                >
                                    {saveButtonText()}
                                </Button>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </If>
            </Grid>
        </>
    );
};

DealsIntegrationSettings.propTypes = {
    writable: PropTypes.bool.isRequired,
};

export default DealsIntegrationSettings;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cancel } from '@material-ui/icons';
import { makeStyles, Chip } from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    chip: {
        color: theme.palette.border.gallery,
        fontWeight: 'bold',
        height: 24,
        lineHeight: 24,
        margin: '2px',
    },
    chipIcon: {
        color: theme.palette.border.gallery,
    },
}));

const TagItem = ({
    record, index, onDelete, allowRemove,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const toggleOpen = () => { setOpen(!open); };

    const chipProp = {};
    if (allowRemove) chipProp.onDelete = toggleOpen;

    return (
        <React.Fragment key={`${record.id}-${index}`}>
            <Chip
                label={record.name}
                className={`${classes.chip} item chip`}
                deleteIcon={
                    <Cancel className={classes.chipIcon} fontSize="small" />
                }
                style={{
                    backgroundColor: record.color,
                }}
                key={`${record.id}-${index}`}
                {...chipProp}
            />
            {open && (
                <ConfirmDialog
                    title="Confirm remove Tag"
                    description="Are you sure you want to remove the Tag?"
                    dividerFooter={false}
                    open={open}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={toggleOpen}
                    onClickSecondary={toggleOpen}
                    onClickPrimary={() => {
                        setOpen(false);
                        onDelete(record);
                    }}
                />
            )}
        </React.Fragment>
    );
};

TagItem.propTypes = {
    onDelete: PropTypes.func,
    allowRemove: PropTypes.bool,
    record: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

TagItem.defaultProps = {
    allowRemove: false,
    onDelete: () => {},
};

export default TagItem;

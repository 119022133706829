export default class DisplayFieldStyle {
    static field() {
        return ({
            labelRow: {
                alignItems: 'center',
                color: '#4a4a4a',
                display: 'flex',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 7,
                '& > span': {
                    color: '#0221c3',
                    fontWeight: 500,
                    marginRight: 5,
                    minWidth: '130px',
                    wordBreak: 'break-word',
                },
                '& > svg': {
                    marginRight: 5,
                    fontSize: 14,
                },
            },
            labelRowWidth: {
                '& > span': {
                    minWidth: 185,
                },
            },
            bold: {
                fontWeight: 'bold',
            },
            redColor: {
                color: '#990000',
            },
            greenColor: {
                color: '#006600',
            },
            reducePrice: {
                display: 'flex',
                flex: 1,
                '& > span': {
                    minWidth: 'fit-content',
                    marginRight: 10,
                },
            },
        });
    }
}

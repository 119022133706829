import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import {
    size, concat, clone, filter,
} from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import ConversationStyles from 'styles/modules/ConversationStyles';
import { MessageType, CustomerName, ConversationStatus } from 'utils/enum/ConversationEnum';
import ModalUtils from 'utils/ModalUtils';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import {
    Box, List, ListItem, ListItemAvatar, Avatar,
    Typography, Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

// Reactbootstrap
import { InputGroup, FormControl } from 'react-bootstrap';

// HTTP
import GraphQLClient from 'services/apollo/GraphQLClient';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import ConversationQuery from 'services/graphQL/query/ConversationQuery';
import ConversationSubscription from 'services/graphQL/subscription/ConversationSubscription';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const styles = (theme) => ConversationStyles.sidebar(theme);

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.graphqlClient = new GraphQLClient();
        this.state = {
            contentList: {
                records: [],
                limit: 50,
                totalCount: 0,
            },
            search: '',
            previousSearch: '',
            load: false,
        };

        this.conversationSubscription = null;
        this.lastMessageOfConversationSubscription = null;
        this.initBind();
    }

    componentDidMount() {
        this.getServicesData();
        this.subscribeToNewConversation();
        this.subscribeToAddedCRMLastMessage();
    }

    componentWillUnmount() {
        if (this.conversationSubscription) {
            this.conversationSubscription.unsubscribe();
        }
    }

    onKeyDownInput(e) {
        const { state: { search, previousSearch } } = this;

        if (e.keyCode === 13) {
            if (!StringUtils.isEmpty(search) || (StringUtils.isEmpty(search) && !StringUtils.isEmpty(previousSearch))) this.getServicesData(true);
        }
    }

    onChangeInputSearch(value) {
        this.setState({
            search: value,
        });
    }

    onSearch() {
        this.getServicesData(true);
    }

    getServicesData(isSearching = false) {
        const { state: { contentList: { records, limit }, contentList, search } } = this;

        if (isSearching) {
            this.setState({
                contentList: Object.assign(contentList, {
                    records: [],
                    limit,
                    totalRecords: 0,
                }),
                load: true,
                previousSearch: search,
            });
        } else {
            this.setState({
                load: true,
                previousSearch: search,
            });
        }

        let offset = size(records) ? records.length : 0;
        offset = isSearching ? 0 : offset;
        const currentSearch = StringUtils.isEmpty(search) ? '' : search;

        const input = {
            offset,
            limit,
            filter: {
                contains: currentSearch,
            },
        };

        this.graphqlClient
            .query(ConversationQuery.CONVERSATION_LIST, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.conversationList) {
                    const { conversationList } = data;
                    const recordServices = isSearching ? [] : records;
                    const { conversations, totalCount } = conversationList;
                    const newRecord = concat(recordServices, conversations);

                    this.setState({
                        contentList: Object.assign(contentList, {
                            records: newRecord,
                            totalCount,
                        }),
                    });
                }
            })
            .finally(() => {
                this.setState({ load: false });
            });
    }

    getRecorByType(record = {}) {
        const { type } = record;

        switch (type.toUpperCase()) {
        case MessageType.QUICK_REPLAY:
            return {
                text: record.quickReplyContent.text,
                date: record.date,
            };

        case MessageType.CARD:
            return {
                text: record.cardContent[0].title,
                date: record.date,
            };
        case MessageType.TEXT:
            return {
                text: record.content.text,
                date: record.date,
            };

        case MessageType.JOINED_AGENT:
            return {
                text: '',
                date: record.date,
            };

        default:
            return {
                text: '',
                date: DateUtils.getUTCDateFormat(),
            };
        }
    }

    initBind() {
        this.onSearch = this.onSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onKeyDownInput = this.onKeyDownInput.bind(this);
        this.addNewConversation = this.addNewConversation.bind(this);
        this.onChangeInputSearch = this.onChangeInputSearch.bind(this);
        this.showLastMessageOfConversation = this.showLastMessageOfConversation.bind(this);
    }

    subscribeToNewConversation() {
        this.graphqlClient.subscribe(this.addNewConversation, ConversationSubscription.CREATED_CONVERSATION)
            .then((response) => {
                this.conversationSubscription = response;
            });
    }

    subscribeToAddedCRMLastMessage() {
        this.graphqlClient.subscribe(this.showLastMessageOfConversation, ConversationSubscription.ADDED_CRM_LAST_MESSAGE)
            .then((response) => {
                this.lastMessageOfConversationSubscription = response;
            });
    }

    // TODO: Pending add graphqlError
    showLastMessageOfConversation(record) {
        const { data: { addedCRMLastMessage } } = record;

        if (typeof addedCRMLastMessage === 'object') {
            const { state: { contentList } } = this;
            const recordsBackUp = clone(contentList.records);
            const currentMessages = filter(recordsBackUp, (item) => item._id !== addedCRMLastMessage._id);
            currentMessages.splice(0, 0, addedCRMLastMessage);

            this.setState({
                contentList: Object.assign(contentList, {
                    records: currentMessages,
                }),
            });
        }
    }

    addNewConversation(newConversation) {
        const { state: { contentList: { records, totalCount }, contentList } } = this;
        const { data: { createdConversation } } = newConversation;

        const backUpListConversation = clone(records || []);
        backUpListConversation.splice(0, 0, createdConversation);

        this.setState({
            contentList: Object.assign(contentList, {
                records: backUpListConversation,
                totalCount: totalCount + 1,
            }),
        });
    }

    loadMore() {
        this.getServicesData();
    }

    clearSearch() {
        this.setState({
            search: '',
        }, () => this.getServicesData(true));
    }

    renderListConversation() {
        let result = null;
        const { props: { classes, conversationId }, state: { contentList: { records } } } = this;

        result = records.map((item) => {
            const { _id, lastMessage, status } = item;
            const content = this.getRecorByType(lastMessage);
            const currentDate = DateUtils.toLocal(content.date);
            const date = DateUtils.fromNow(currentDate);
            const customerName = StringUtils.isEmpty(item.customerName) ? CustomerName.ANONYMOUS.toLowerCase() : item.customerName;
            const shortCustomer = StringUtils.getCharacterFirstTwoWord(customerName);
            const isSelected = conversationId === _id ? classes.conversationSelected : '';
            const userColor = StringUtils.isEmpty(item.color) ? '#9668D3' : item.color;
            const currentStatus = StringUtils.toUpperCase(status) === ConversationStatus.ANSWERED ? '' : status;
            const urlConversationId = KeyStore.isAllowed(Permission.CRM_CHAT_READ) ? `/${_id}` : '';

            return (
                <ListItem
                    className={clsx(classes.listItem, isSelected)}
                    component={RouterLink}
                    key={_id}
                    to={`/conversations${urlConversationId}`}
                >
                    <div>
                        <span className={classes.statusConversation}>{currentStatus}</span>
                    </div>
                    <div className={classes.bodyConversation}>
                        <ListItemAvatar>
                            <Avatar
                                style={{ backgroundColor: userColor }}
                            >
                                {shortCustomer}
                            </Avatar>
                        </ListItemAvatar>
                        <Box className={classes.customerDetails}>
                            <Box className={classes.nameLastConnection}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    noWrap
                                    className={classes.customerName}
                                >
                                    {customerName}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {date}
                                </Typography>
                            </Box>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                className={classes.lastMessage}
                                noWrap
                            >
                                {content.text}
                            </Typography>
                        </Box>
                    </div>
                </ListItem>
            );
        });

        return result;
    }

    render() {
        const { props: { classes }, state: { load, contentList: { records, totalCount }, search } } = this;
        const isEmptyInputSearch = !StringUtils.isEmpty(search);

        return (
            <Box
                className={classes.containerSidebar}
            >
                <InputGroup className={classes.inputSearchContainer}>
                    <div className={classes.inputIcon}>
                        <FormControl
                            placeholder="Search for messages"
                            aria-label="Search"
                            onKeyDown={this.onKeyDownInput}
                            value={search}
                            onChange={(e) => this.onChangeInputSearch(e.target.value)}
                            aria-describedby="basic-addon1"
                        />
                        {isEmptyInputSearch && <CancelIcon onClick={this.clearSearch} className={classes.clearSearch} />}
                        <Button
                            onClick={this.onSearch}
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                </InputGroup>
                <InfiniteScroll
                    className={classes.box}
                    lengthRecord={records.length}
                    totalRecord={totalCount}
                    load={load}
                    loadMore={this.loadMore}
                >
                    <List className={classes.list}>
                        {this.renderListConversation()}
                    </List>
                </InfiniteScroll>
            </Box>
        );
    }
}

Sidebar.propTypes = {
    conversationId: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Sidebar.defaultProps = {
    conversationId: '',
};

export default withStyles(styles)(Sidebar);

import React, { useReducer, useEffect } from 'react';

// Components and others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import StringUtils from 'lib/StringUtils';
import Emojic from 'components/widgets/Emoji';
import { ReactComponent as Send } from 'assets/send.svg';
import { count } from 'sms-length';

// Material UI
import {
    Tooltip, IconButton, Button,
    makeStyles,
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import clsx from 'clsx';

export const calculateSMSInfo = (text) => {
    const messageInfo = count(text);
    const { remaining } = messageInfo;
    const numberOfSMS = messageInfo.messages;

    return { remaining, numberOfSMS };
};

export const ErrorMessageSegments = 'The SMS cannot contain more than 4 message segments';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.palette.background.lavender,
    },
    inputContainer: {
        flex: 1,
        padding: theme.spacing(1, 0),
    },
    textAreaAutoSize: {
        outline: 0,
        resize: 'none',
        width: '100%',
        background: 'transparent',
        border: 'none',
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    iconButtonSendMessage: {
        width: 40,
        height: 40,
        minWidth: 40,
        borderRadius: '50%',
        padding: 0,
        margin: theme.spacing(1, 0),
    },
    counterMessage: {
        fontSize: '13px',
    },
    error: {
        color: theme.palette.text.red,
        cursor: 'default',
    },
}));
const ACTION_TYPE = {
    TOGGLE_EMOJI: 'toggleEmoji',
    SET_TEXT_MESSAGE: 'setTextMessage',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.TOGGLE_EMOJI:
        return update(state, {
            open: { $set: action.value },
        });
    case ACTION_TYPE.SET_TEXT_MESSAGE:
        return update(state, {
            text: { $set: action.value },
        });
    default:
        return state;
    }
};
const initState = {
    open: false,
    text: '',
};
const MessageAdd = ({ onSubmit, textMessage, disabled }) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const clearInputMessage = () => {
        dispatch({
            type: ACTION_TYPE.SET_TEXT_MESSAGE,
            value: '',
        });
    };
    const sendMessage = () => {
        onSubmit({ text: state.text });
        clearInputMessage();
    };
    const onChangeMessage = (value) => {
        dispatch({
            type: ACTION_TYPE.SET_TEXT_MESSAGE,
            value,
        });
    };
    const onKeyDownInputMessage = (e) => {
        if (e.keyCode === 13 && !StringUtils.isEmpty(state.text)) {
            sendMessage();
        }
    };
    const toggleEmoji = () => {
        dispatch({
            type: ACTION_TYPE.TOGGLE_EMOJI,
            value: !state.open,
        });
    };
    const addEmoji = (value) => {
        dispatch({
            type: ACTION_TYPE.SET_TEXT_MESSAGE,
            value: state.text + value,
        });
    };

    useEffect(() => {
        if (!StringUtils.isEmpty(textMessage)) {
            dispatch({
                type: ACTION_TYPE.SET_TEXT_MESSAGE,
                value: textMessage,
            });
        }
    }, [textMessage]);

    const result = calculateSMSInfo(state.text);
    const disableSendMessage = StringUtils.isEmpty(state.text) || result.numberOfSMS > 4 || disabled;

    return (
        <div
            className={classes.root}
        >
            <div className={classes.inputContainer}>
                {state.open && (
                    <Emojic
                        onChange={addEmoji}
                        onClose={toggleEmoji}
                    />
                )}
                <TextareaAutosize
                    rowsMax={6}
                    maxLength={550}
                    value={state.text}
                    placeholder="Enter your message"
                    className={classes.textAreaAutoSize}
                    onKeyDown={onKeyDownInputMessage}
                    onChange={(e) => onChangeMessage(e.target.value)}
                />
            </div>
            <Tooltip title={result.numberOfSMS > 4 ? ErrorMessageSegments : ''}>
                <div className={clsx(classes.counterMessage, { [classes.error]: result.numberOfSMS > 4 })}>{`${result.remaining} / ${result.numberOfSMS}`}</div>
            </Tooltip>
            <Tooltip title="Emoji">
                <IconButton
                    onClick={toggleEmoji}
                    className={classes.iconButton}
                >
                    <SentimentVerySatisfiedIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Press enter to send a new sms">
                <span>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disableSendMessage}
                        className={classes.iconButtonSendMessage}
                        onClick={sendMessage}
                    >
                        <Send />
                    </Button>
                </span>
            </Tooltip>
        </div>
    );
};

MessageAdd.propTypes = {
    textMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

MessageAdd.defaultProps = {
    textMessage: null,
    disabled: false,
};

export default MessageAdd;

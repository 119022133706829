import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import axios from 'axios';
import ModalUtils from 'utils/ModalUtils';
import { makeUseAxios } from 'axios-hooks';
import { Button, Form } from 'react-bootstrap';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';

const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: process.env.REACT_APP_API }),
});
const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: '27px',
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.43,
        color: theme.palette.text.white,
        marginBottom: '8px',
        display: 'block',
        textAlign: 'center',
    },
    arrow: {
        color: theme.palette.background.white,
    },
    button: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
    },
}));

const ForgotPassword = ({ onClose }) => {
    const classes = useStyles();
    const [userName, setUserName] = useState();
    const [{ loading }, forgotPassword] = useAxios({ url: '/users/password/forgot', method: 'POST' }, { manual: true });

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const result = await forgotPassword({ data: { UserName: userName } });
            if (result.status === 200) {
                ModalUtils.successMessage([], result.data.Message);
                onClose();
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.Messages);
        }
    };

    return (
        <div className={classes.main}>
            <Form onSubmit={(e) => handleLogin(e)}>
                <div>
                    <IconButton aria-label="back" className={classes.button} onClick={onClose}>
                        <ArrowBackRoundedIcon fontSize="small" className={classes.arrow} />
                    </IconButton>
                </div>
                <span className={classes.title}>You will receive an email with a link to reset your password.</span>
                <Form.Group controlId="formUserName">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Username"
                        required
                        onChange={
                            (e) => setUserName(e.target.value)
                        }
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {loading ? <CircularProgress size="1em" color="inherit" />
                        : (
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                className=""
                                data-icon="login"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                {/* eslint-disable-next-line max-len */}
                                <path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z" />
                            </svg>
                        )}
                    <span className="icon">Sign In</span>
                </Button>
            </Form>
        </div>
    );
};

ForgotPassword.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ForgotPassword;

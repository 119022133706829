import React, { useCallback, useEffect, useReducer } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Form, Col } from 'react-bootstrap';
import Loading from 'components/widgets/Loading';
import InputNumber from 'components/widgets/InputNumber';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import usePrevious from 'components/hook/core/usePrevious';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    radioInfo: {
        color: theme.palette.info.main,
        padding: 4,
        marginLeft: 4,
        '&.Mui-checked': {
            color: theme.palette.info.main,
        },
    },
    dialog: {
        width: 892,
    },
    dialogContent: {
        padding: theme.spacing(3),
        position: 'relative',
    },
    marginBottom0: {
        marginBottom: 0,
    },
    radioGroup: {
        marginBottom: 16,
    },
    loading: {
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        top: 0,
        left: 0,
        zIndex: 3,
    },
}));
const ACTION_TYPE = {
    SET_RECORD: 'setRecord',
    SET_VALUE: 'setValue',
    CALCULATE_SALES_TAX: 'calculateSalesTax',
};

function reducer(state, action) {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPE.SET_VALUE:
        return update(state, {
            [payload.field]: { $set: payload.value },
        });
    case ACTION_TYPE.CALCULATE_SALES_TAX:
        const taxable = (state.salesPrice + state.dealerFee + (state?.userField1 || 0) + (state?.userField2 || 0)) - state.tradeIn;
        const salesTax = taxable * (state.originalSalesTax / 100);

        return update(state, {
            salesTax: { $set: salesTax },
            taxable: { $set: taxable },
        });
    case ACTION_TYPE.SET_RECORD:
        return {
            formId: action.formId,
            ...payload,
        };
    default:
        return {};
    }
}

const PurchaseWorksheetDialog = ({
    open, records, onClickPrimaryButton,
    loading, onClose,
    className, maxWidth, textPrimaryButton,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {});
    const prev = usePrevious(records);

    useEffect(() => {
        if (!isEqual(prev, records)) {
            dispatch({
                type: ACTION_TYPE.SET_RECORD,
                payload: records?.customField?.data || {},
                formId: records.id,
            });
        }
    }, [records, prev]);

    const onBlur = () => {
        dispatch({
            type: ACTION_TYPE.CALCULATE_SALES_TAX,
        });
    };

    const onChange = useCallback(({
        field, value,
    }) => dispatch({
        type: ACTION_TYPE.SET_VALUE,
        payload: {
            field,
            value,
        },
    }), [dispatch]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth}
            PaperProps={{
                className: classes.dialog,
            }}
            className={className}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogAppBar title="Purchase Worksheet" iconSize="sm" onClose={() => onClose(state)} disabled={loading} />
            <DialogContent className={classes.dialogContent}>
                {loading && <Loading className={classes.loading} />}
                <Grid container spacing={6} className="am-form">
                    <Grid item xs={12}>
                        <Form.Group as={Col}>
                            <Form.Label>Sales Price:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.salesPrice}
                                decimalScale={2}
                                onBlur={onBlur}
                                onChange={(value) => onChange({ value, field: 'salesPrice' })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Down Payment:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.downPayment}
                                decimalScale={2}
                                onChange={(value) => onChange({ value, field: 'downPayment' })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Trade Value:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.tradeIn}
                                decimalScale={2}
                                onBlur={onBlur}
                                onChange={(value) => onChange({ value, field: 'tradeIn' })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Trade Payoff:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.tradePayoff}
                                decimalScale={2}
                                onChange={(value) => onChange({ value, field: 'tradePayoff' })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Dealer Fee:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.dealerFee}
                                decimalScale={2}
                                onBlur={onBlur}
                                disabled={!state.dealerFeeEditable}
                                onChange={(value) => onChange({ value, field: 'dealerFee' })}
                            />
                        </Form.Group>
                        {!StringUtils.isEmpty(state.userField1Name) && (
                            <Form.Group as={Col}>
                                <Form.Label>{`${state.userField1Name}:`}</Form.Label>
                                <InputNumber
                                    thousandSeparator
                                    value={state.userField1}
                                    decimalScale={2}
                                    onBlur={onBlur}
                                    onChange={(value) => onChange({ value, field: 'userField1' })}
                                />
                            </Form.Group>
                        )}
                        {!StringUtils.isEmpty(state.userField2Name) && (
                            <Form.Group as={Col}>
                                <Form.Label>{`${state.userField2Name}:`}</Form.Label>
                                <InputNumber
                                    thousandSeparator
                                    value={state.userField2}
                                    decimalScale={2}
                                    onBlur={onBlur}
                                    onChange={(value) => onChange({ value, field: 'userField2' })}
                                />
                            </Form.Group>
                        )}
                        <Form.Group as={Col}>
                            <Form.Label>Sales Tax:</Form.Label>
                            <InputNumber
                                thousandSeparator
                                value={state.salesTax}
                                decimalScale={2}
                                disabled
                                onChange={(value) => onChange({ value, field: 'salesTax' })}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                onClickSecondary={() => onClose(state)}
                onClickPrimary={() => onClickPrimaryButton(state)}
                titlePrimary={textPrimaryButton}
                disablePrimaryButton={loading}
                disableSecondaryButton={loading}
            />
        </Dialog>
    );
};

PurchaseWorksheetDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    records: PropTypes.object.isRequired,
    className: PropTypes.string,
    maxWidth: PropTypes.string,
    textPrimaryButton: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onClickPrimaryButton: PropTypes.func.isRequired,
};

PurchaseWorksheetDialog.defaultProps = {
    className: '',
    maxWidth: 'md',
    textPrimaryButton: 'Save',
};

export default PurchaseWorksheetDialog;

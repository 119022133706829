import React from 'react';

// Material UI
import { makeStyles, Typography } from '@material-ui/core';

// Components and Others
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import { round } from 'lodash';

const useStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
}));

const PurchaseOrderDetailSummary = ({
    subTotal, tax, displayInline,
}) => {
    const classes = useStyles();

    return (
        <Typography
            variant="h6"
            className={displayInline ? classes.summaryTextInLine : classes.summaryText}
        >
            <span>Subtotal:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(subTotal)}</span>
            <span>Tax:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(tax)}</span>
            <span>Total:&nbsp;</span>
            <span className={classes.contentSpace}>{NumberUtils.applyCurrencyFormat(round((subTotal + tax), 2))}</span>
        </Typography>
    );
};

PurchaseOrderDetailSummary.propTypes = {
    subTotal: PropTypes.number,
    tax: PropTypes.number,
    displayInline: PropTypes.bool,
};

PurchaseOrderDetailSummary.defaultProps = {
    subTotal: 0,
    tax: 0,
    displayInline: true,
};

export default PurchaseOrderDetailSummary;

import React, { useState } from 'react';

// Material UI Components
import {
    makeStyles,
} from '@material-ui/core';

// Components and Others
import Split from 'react-split';

import VendorListBalance from 'components/modules/accounting/accountsPayable/list/VendorListBalance';
import InvoiceList from 'components/modules/accounting/accountsPayable/list/InvoiceList';

const useStyle = makeStyles({
    splitOne: {
        display: 'flex',
        overflow: 'hidden',
        flex: 1,
    },
    main: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    containerSplit: {
        flexDirection: 'column',
        padding: '0px',
    },
    invoiceAndPadiSection: {
        height: 'calc(100vh - 105px)',
    },
});

const AccountsPayableLayout = () => {
    const [vendorSelected, setVendorSelected] = useState({});

    const classes = useStyle();

    return (
        <div className={classes.main}>
            <Split
                sizes={[30, 70]}
                className={classes.splitOne}
                minSize={0}
            >
                <div>
                    <VendorListBalance onVendorSelected={(val) => setVendorSelected(val)} />
                </div>
                <div>
                    <InvoiceList
                        vendorSelectedId={vendorSelected?.vendorId}
                        vendorSelectedName={vendorSelected?.vendorName}
                        vendorSelectedAddress={vendorSelected?.vendorAddress}
                    />
                </div>
            </Split>
        </div>
    );
};

export default AccountsPayableLayout;

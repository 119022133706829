/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react';

import clsx from 'clsx';
import {
    makeStyles, Grid, IconButton,
    Button, Tooltip, CircularProgress,
} from '@material-ui/core';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Form, Row } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import ButtonStyles from 'styles/theme/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import UserQuery from 'services/graphQL/query/UserQuery';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SequentialRoundRobin } from 'round-robin-js';
import OpportunityQuery from 'services/graphQL/query/crm/OpportunityQuery';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import MessageUtils from 'utils/MessageUtils';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '& button': {
            marginRight: theme.spacing(1),
        },
    },
    labels: {
        fontSize: '0.875rem',
        margin: 0,
        marginRight: theme.spacing(1),
    },
    select: {
        width: '216px',
        marginRight: theme.spacing(1),
    },
    body: {
        marginBottom: theme.spacing(1),
        flex: 1,
        overflow: 'hidden',
        '& .MuiGrid-root': {
            height: '100%',
            overflow: 'hidden',
        },
    },
    footer: {
        '& button': {
            marginLeft: theme.spacing(1),
        },
    },
    deleteIcon: {
        fontSize: '1.1rem',
    },
    iconButton: {
        width: '6px',
        height: '6px',
    },
    ...ButtonStyles.getStyle(theme),
}));

const ACTION_TYPE = {
    SET_INIT_STATE: 'setInitState',
    ON_CHANGE_VALUE: 'onChangeValue',
    SET_OPPORTUNITY: 'setOpportunity',
    SET_SALESPERSON: 'setSalesperson',
    REMOVER_SALESPERSON: 'removeSalesperson',
    ON_CHANGE_SALESPERSON: 'onChangeSalesperson',
    REMOVER_ALL_SALESPERSON: 'removeAllSalesperson',
    SET_USER_AVAILABLE_BY_LOT: 'setUserAvailableByLot',
    ADD_SALESPERSON_TO_ASSIGN: 'addSalespersonToAssign',
};

const removerUser = (record) => record.map((item) => {
    const { user, ...rest } = item;
    return rest;
});

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_SALESPERSON:
        const salespersonList = (action.payload || [])
            .map((item) => ({ label: `${item.firstName} ${item.lastName}`, value: item.userId, isRecordManager: item.isRecordManager }));

        return update(state, {
            salespersonList: { $set: [{ label: 'None', value: null }, ...salespersonList] },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.payload.field]: { $set: action.payload.value },
        });
    case ACTION_TYPE.ON_CHANGE_SALESPERSON:
        return update(state, {
            usersAssigned: { $set: [] },
            salespersonId: { $set: action.payload },
            salespersonIdByOpportunityLot: { $set: null },
            salespersonByOpportunityLot: { $set: [] },
        });
    case ACTION_TYPE.SET_INIT_STATE:
        return update(state, {
            usersAssigned: { $set: [] },
            salespersonId: { $set: null },
            salespersonByOpportunityLot: { $set: [] },
            salespersonIdByOpportunityLot: { $set: null },
            data: {
                records: { $set: [] },
                totalCount: { $set: 0 },
            },
        });
    case ACTION_TYPE.REMOVER_SALESPERSON:
        return update(state, {
            data: {
                records: { $set: removerUser(state.data.records) },
            },
            salespersonByOpportunityLot: { $push: [action.payload] },
            usersAssigned: { $set: state.usersAssigned.filter((item) => item.userId !== action.payload.userId) },
        });
    case ACTION_TYPE.REMOVER_ALL_SALESPERSON:
        return update(state, {
            data: {
                records: { $set: removerUser(state.data.records) },
            },
            salespersonIdByOpportunityLot: { $set: null },
            salespersonByOpportunityLot: { $set: [...state.salespersonByOpportunityLot, ...state.usersAssigned] },
            usersAssigned: { $set: [] },
        });
    case ACTION_TYPE.SET_OPPORTUNITY:
        return update(state, {
            data: {
                records: { $set: action.payload.data },
                totalCount: { $set: action.payload.totalCount },
            },
        });
    case ACTION_TYPE.ADD_SALESPERSON_TO_ASSIGN:
        const currentUser = state.salespersonByOpportunityLot.find((item) => item.value === action.payload);

        return update(state, {
            data: {
                records: { $set: removerUser(state.data.records) },
            },
            usersAssigned: { $push: [currentUser] },
            salespersonByOpportunityLot: { $set: state.salespersonByOpportunityLot.filter((item) => item.userId !== currentUser.userId) },
            salespersonIdByOpportunityLot: { $set: null },
        });
    case ACTION_TYPE.SET_USER_AVAILABLE_BY_LOT:
        const salespersonByOpportunityLot = (action.payload || []).map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.userId,
            ...item,
        }));
        return update(state, {
            salespersonByOpportunityLot: { $set: salespersonByOpportunityLot },
        });
    default:
        return state;
    }
};

const OpportunityReassignment = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, {
        salespersonId: null,
        salespersonList: [],
        data: {
            records: [],
            totalCount: 0,
        },
        openConfirm: false,
        usersAssigned: [],
        salespersonByOpportunityLot: [],
        salespersonIdByOpportunityLot: null,
    });

    const [reassignOpportunity, { loading: loadingReassign }] = useMutation(OpportunityMutation.REASSIGN_OPPORTUNITY);
    const {
        loading: loadingSalespersonList, data: salespersonList, error: errorSalespersonList,
    } = useQuery(UserQuery.GET_SALESPERSONS_LIST, {
        variables: {
            includeRecordManagers: true,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getRecordManagersAndSalespersonByLots, {
        data: recordManagersAndSalespersonByLots,
        loading: loadingRecordManagersAndSalespersonByLots,
        error: errorRecordManagersAndSalespersonByLots,
    }] = useLazyQuery(
        UserQuery.GET_RECORD_MANAGERS_AND_SALESPERSON_BY_LOTS,
        { fetchPolicy: FetchPolicy.NETWORK_ONLY },
    );

    const [getOpportunity, { data, loading, error }] = useLazyQuery(
        OpportunityQuery.GET_OPPORTUNITY_BY_OPTION,
        {
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
            onCompleted: (res) => {
                if (res?.getOpportunityByOption?.data?.length > 0) {
                    const lotsId = res.getOpportunityByOption.data.map((item) => item.lotId).filter((item) => item);
                    getRecordManagersAndSalespersonByLots({ variables: { lots: [...new Set(lotsId)] } });
                }
            },
        },
    );

    //* useEffect to set available user to assigned
    useEffect(() => {
        if (errorRecordManagersAndSalespersonByLots) {
            ModalUtils.errorMessage(errorRecordManagersAndSalespersonByLots?.graphQLErrors);
            return;
        }

        const result = recordManagersAndSalespersonByLots?.getRecordManagersAndSalespersonByLots;
        if (!loadingRecordManagersAndSalespersonByLots && result) {
            dispatch({
                type: ACTION_TYPE.SET_USER_AVAILABLE_BY_LOT,
                payload: result.filter((item) => item.userId !== state.salespersonId),
            });
        }
    }, [recordManagersAndSalespersonByLots, loadingRecordManagersAndSalespersonByLots, errorRecordManagersAndSalespersonByLots]);

    //* useEffect to set opportunity in table
    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getOpportunityByOption) {
            const { getOpportunityByOption } = data;
            dispatch({ type: ACTION_TYPE.SET_OPPORTUNITY, payload: getOpportunityByOption });
        }
    }, [data, loading, error]);

    //* useEffect to set salesperson list
    useEffect(() => {
        if (errorSalespersonList) {
            ModalUtils.errorMessage(errorSalespersonList?.graphQLErrors);
            return;
        }

        if (!loadingSalespersonList && salespersonList?.getSalesPersonsList) {
            const { getSalesPersonsList } = salespersonList;
            dispatch({ type: ACTION_TYPE.SET_SALESPERSON, payload: getSalesPersonsList });
        }
    }, [loadingSalespersonList, salespersonList, errorSalespersonList]);

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field,
                value,
            },
        });
    };

    const toggleConfirm = (value = false) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field: 'openConfirm',
                value,
            },
        });
    };

    //* process Opportunity
    const onProcess = async () => {
        try {
            toggleConfirm();
            const origin = state.salespersonList.find((person) => person.value === state.salespersonId) ?? {};
            const input = (state.data.records || []).map((item) => {
                const assignation = {};
                if (origin.isRecordManager && item.user.isRecordManager) { assignation.recordManagerId = item.user.userId; assignation.switchable = true; }
                if (!origin.isRecordManager && !item.user.isRecordManager) { assignation.salespersonId = item.user.userId; assignation.switchable = true; }
                if (origin.isRecordManager && !item.user.isRecordManager) { assignation.recordManagerId = null; assignation.salespersonId = item.user.userId; }
                if (!origin.isRecordManager && item.user.isRecordManager) { assignation.recordManagerId = item.user.userId; assignation.salespersonId = null; }

                return {
                    ...assignation,
                    opportunityId: item.opportunityId,
                };
            });
            const response = await reassignOpportunity({ variables: { input } });

            if (response.data?.reassignOpportunity?.reassigned) {
                dispatch({ type: ACTION_TYPE.SET_INIT_STATE });
                ModalUtils.successMessage(null, `${response.data?.reassignOpportunity?.reassigned} Opportunity reassigned.`);
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('update', 'opportunity'));
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, ex);
        }
    };

    //* assign Salesperson to Opportunity
    const onAssignSalesperson = () => {
        const cpusTable = new SequentialRoundRobin(state.usersAssigned);

        const newRecords = state.data.records.map((item) => {
            const currentUser = cpusTable.next();
            return ({ ...item, user: currentUser.value });
        });

        dispatch({ type: ACTION_TYPE.SET_OPPORTUNITY, payload: { data: newRecords, totalCount: newRecords.length } });
    };

    const onChangeSalesperson = (_, value) => {
        if (value == null) {
            dispatch({ type: ACTION_TYPE.SET_INIT_STATE });
        } else {
            dispatch({
                type: ACTION_TYPE.ON_CHANGE_SALESPERSON,
                payload: value,
            });

            const selectedPerson = state.salespersonList.find((person) => person.value === value) ?? {};
            const { isRecordManager = false } = selectedPerson;

            getOpportunity({
                variables: {
                    ...(isRecordManager ? {
                        recordManagerId: value,
                    } : {
                        salespersonId: value,
                    }),
                    status: 'Open',
                },
            });
        }
    };

    const addSalespersonToAssign = () => {
        dispatch({
            type: ACTION_TYPE.ADD_SALESPERSON_TO_ASSIGN,
            payload: state.salespersonIdByOpportunityLot,
        });
    };

    const removerSalesperson = (record) => {
        dispatch({
            type: ACTION_TYPE.REMOVER_SALESPERSON,
            payload: record,
        });
    };

    const onRemoveAll = () => {
        dispatch({
            type: ACTION_TYPE.REMOVER_ALL_SALESPERSON,
        });
    };

    const columns = [
        {
            Header: 'Lead Code',
            accessor: 'leadCode',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 120,
        },
        {
            Header: 'Record Manager',
            accessor: 'recordManager',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 180,
        },
        {
            Header: 'Sales Rep',
            accessor: 'appointmentSalesman',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 180,
        },
        {
            Header: 'LotName',
            accessor: 'lotName',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 150,
            Cell: ({ original }) => {
                const { lotName } = original;
                return <span className="lotTag">{lotName}</span>;
            },
        },
        {
            Header: 'Process Stage',
            accessor: 'processStage',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 180,
        },
        {
            Header: 'To Assign',
            accessor: 'processStage',
            className: 'd-flex-justify-center-align-center',
            maxWidth: 180,
            Cell: ({ original }) => {
                const { user = {} } = original;
                return <span>{user.label}</span>;
            },
        },
    ];

    const userColumns = [
        {
            Header: 'Salesperson',
            width: 170,
            minRows: 3,
            id: 'salesperson',
            Cell: ({ original }) => {
                const { firstName, lastName } = original;

                const fullName = `${firstName} ${lastName}`;
                return <span>{fullName}</span>;
            },
        },
        {
            Header: 'Lots',
            minRows: 3,
            id: 'lot',
            Cell: ({ original }) => {
                const { lots } = original;

                return <span>{lots}</span>;
            },
        },
        {
            Header: '',
            minRows: 3,
            id: 'action',
            width: 70,
            className: 'd-flex-justify-center-align-center',
            Cell: ({ original }) => (
                <IconButton
                    aria-label="add"
                    className={clsx(classes.containedError, classes.iconButton)}
                    onClick={() => removerSalesperson(original)}
                >
                    <DeleteIcon fontSize="small" className={classes.deleteIcon} />
                </IconButton>
            ),
        },
    ];

    return (
        <div className="d-flex-column">
            <div>
                <Grid container>
                    <Grid item xs={7}>
                        <Form.Group as={Row} className={classes.row}>
                            <Form.Label className={classes.labels}>From:</Form.Label>
                            <Select
                                size="sm"
                                name="salespersonId"
                                className={classes.select}
                                value={state.salespersonId ?? 0}
                                onChange={onChangeSalesperson}
                                options={state.salespersonList}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={5}>
                        <Form.Group as={Row} className={classes.row}>
                            <Form.Label className={classes.labels}>Assign to:</Form.Label>
                            <Select
                                size="sm"
                                onChange={onChangeValue}
                                className={classes.select}
                                name="salespersonIdByOpportunityLot"
                                value={state.salespersonIdByOpportunityLot}
                                options={state.salespersonByOpportunityLot}
                                disabled={state.salespersonByOpportunityLot.length === 0}
                            />
                            <IconButton
                                aria-label="add"
                                disabled={state.salespersonIdByOpportunityLot == null || state.usersAssigned.length >= 5}
                                className={clsx(classes.containedInfo, classes.iconButton)}
                                onClick={addSalespersonToAssign}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <Tooltip title="Remove all">
                                <span>
                                    <IconButton
                                        aria-label="add"
                                        disabled={state.usersAssigned.length === 0}
                                        className={clsx(classes.containedError, classes.iconButton)}
                                        onClick={onRemoveAll}
                                    >
                                        <DeleteIcon fontSize="small" className={classes.deleteIcon} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Form.Group>
                    </Grid>
                </Grid>
            </div>
            <Grid container className={classes.body}>
                <Grid item xs={7}>
                    <Table
                        loading={loading}
                        columns={columns}
                        data={state.data.records}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Table
                        columns={userColumns}
                        data={state.usersAssigned}
                    />
                </Grid>
            </Grid>
            <div className={clsx(classes.footer, 'd-flex-space-between')}>
                <div>
                    <span>{`${state.data.records.length} / ${state.data.totalCount}`}</span>
                </div>
                <div>
                    <Button
                        onClick={onAssignSalesperson}
                        className={classes.containedGreen}
                        disabled={!(state.data.records.length > 0 && state.usersAssigned.length > 0) || loadingReassign}
                    >
                        Preview
                    </Button>
                    <Button
                        startIcon={loadingReassign ? <CircularProgress size={16} /> : null}
                        onClick={() => toggleConfirm(true)}
                        disabled={!state.data.records.some((item) => item.user) || loadingReassign}
                        className={classes.containedInfo}
                    >
                        Reassign
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                title="Confirm reassign Opportunity"
                description={`Are you sure you want to reassign ${state.data.records.length} opportunities?`}
                open={state.openConfirm}
                titlePrimary="Yes"
                titleSecondary="No"
                variant="outlined"
                onClose={() => toggleConfirm(false)}
                onClickSecondary={() => toggleConfirm(false)}
                onClickPrimary={onProcess}
            />
        </div>
    );
};

export default OpportunityReassignment;

import React, {
    useEffect, useReducer, forwardRef, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import {
    Button, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DeleteOutlineOutlinedIcon } from 'components/icons';
import IconButton from '@material-ui/core/IconButton';
import If from 'components/widgets/conditional/If';
import VirtualTable from 'components/widgets/VirtualTable';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { isEmpty, round } from 'lodash';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import { useLazyQuery } from '@apollo/client';
import PartsMapper from 'services/mapData/PartsMapper';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import PurchaseOrderDetailSummary from 'components/modules/purchaseParts/purchaseOrder/create/PurchaseOrderDetailSummary';
import PartsSelect from 'components/modules/purchaseParts/parts/list/PartsSelect';
import PurchaseOrderDetailReducer, { ACTION_TYPES } from 'components/modules/purchaseParts/reducer/PurchaseOrderDetailReducer';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));
const ownStyle = makeStyles(() => (
    {
        customHeight: {
            minHeight: '80vh',
            paddingLeft: '16px',
        },
    }
));

const keyStore = new KeyStore();

const PurchaseOrderDetail = forwardRef((props, ref) => {
    const PARTS_TICKET_WRITE_ADD_DELETE_PARTS = keyStore.hasPermission(Permission.PARTS_TICKET_WRITE_ADD_DELETE_PARTS);

    const {
        writePermissions, recordId, onDirty,
        editDetails, taxRate, vendorId, lotName,
        po, isReturn,
    } = props;

    const classes = {
        ...useStyles(),
        ...containerStyles(),
        ...ownStyle(),
    };

    const mountedRef = useRef(true);

    const initialState = {
        records: [],
        isModalDeleteOpen: false,
        idToDelete: 0,
        purchaseOrderDetailId: 0,
        openPartsSelect: false,
    };
    const [state, dispatch] = useReducer(PurchaseOrderDetailReducer, initialState);

    const [getRecords, { loading }] = useLazyQuery(PartsQuery.GET_PURCHASE_DETAIL_LIST,
        {
            onCompleted: (res) => {
                const mapValues = PartsMapper.mapPurchaseOrderDetail(res.getPurchaseOrderDetails, (isReturn && po > 0));

                dispatch({ type: ACTION_TYPES.SET_FETCHED_RECORDS, value: mapValues });
            },
            onError: (error) => {
                ModalUtils.errorMessage([error]);
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const getLineErrors = (records) => {
        const errors = [];

        records.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (item.description <= 0) message += ' Description is required,';
            if (!item.quantity || item.quantity === 0) message += ' Quantity is required and must be different than zero,';
            if (!item.quantity || item.quantity === 0) message += ' Unit Price is required and must be different than zero,';

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        return errors;
    };

    useImperativeHandle(ref, () => ({
        getRecords() {
            const { records } = state;
            const errors = getLineErrors(records);

            return {
                errors,
                records,
            };
        },
        clear() {
            dispatch({ type: ACTION_TYPES.CLEAR_LINES });
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state.records]);

    const onAddNewLine = () => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                openPartsSelect: true,
            },
        });
    };

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = () => {
        const dataAfterDeleted = state.records.filter((item) => item.purchaseOrderDetailId !== state.idToDelete);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: dataAfterDeleted,
            notifyDirty: onDirty,
        });

        onCloseDeleteConfirm();
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
            notifyDirty: onDirty,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;

        if (event && id !== 'description' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'description' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const addSelectedParts = (values) => {
        dispatch({
            type: ACTION_TYPES.ADD_SELECTED_PARTS,
            value: values,
            purchaseOrderId: recordId,
            notifyDirty: onDirty,
        });
    };

    const getColumns = (columnOptions) => {
        const columns = [
            {
                label: 'Line',
                width: 40,
                className: clsx(classes.columnStyle, classes.columnLeft),
                dataKey: 'line',
                cellRenderer: (cell) => cell.rowIndex + 1,
            },
            {
                label: 'Part #',
                width: 120,
                dataKey: 'partStockNumber',
                cellRenderer: (cell) => {
                    const { rowData: { partStockNumber } } = cell;
                    return <span className={classes.paddingLine}>{partStockNumber}</span>;
                },
            },
            {
                label: 'Description',
                width: 250,
                dataKey: 'description',
                cellRenderer: (cell) => {
                    const { rowData: { description, purchaseOrderDetailId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editDescription) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={description}
                                className={description && description !== '' ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="text"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={purchaseOrderDetailId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{description}</span>;
                },
            },
            {
                label: 'Quantity',
                width: 80,
                dataKey: 'quantity',
                cellRenderer: (cell) => {
                    const { rowData: { quantity, purchaseOrderDetailId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editQuantity) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={quantity}
                                className={quantity && quantity !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={purchaseOrderDetailId}
                                showCurrency={false}
                                allowNegative={false}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{quantity}</span>;
                },
            },
            {
                label: 'Unit Price',
                width: 80,
                dataKey: 'unitPrice',
                cellRenderer: (cell) => {
                    const { rowData: { unitPrice, purchaseOrderDetailId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editUnitPrice && po === 0) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={unitPrice}
                                className={unitPrice && unitPrice !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={purchaseOrderDetailId}
                                allowNegative={false}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(unitPrice || 0)}</span>;
                },
            },
            {
                label: 'List Price',
                width: 80,
                dataKey: 'netPrice',
                cellRenderer: (cell) => {
                    const { rowData: { netPrice, purchaseOrderDetailId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && columnOptions.editUnitPrice && po === 0) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={netPrice}
                                className={netPrice && netPrice !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={purchaseOrderDetailId}
                                allowNegative={false}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(netPrice || 0)}</span>;
                },
            },
            {
                label: 'Sub Total',
                width: 100,
                dataKey: 'subTotal',
                cellRenderer: (cell) => {
                    const { rowData: { subTotal } } = cell;
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(subTotal || 0)}</span>;
                },
            },
        ];

        if (writePermissions && (columnOptions.allowDelete)) {
            columns.push({
                label: 'Actions',
                width: 100,
                dataKey: 'actions',
                cellRenderer: (cell) => (
                    <div className={classes.buttonSpacing}>
                        {writePermissions && columnOptions.allowDelete
                          && (
                              <IconButton
                                  className={classes.buttonWrapper}
                                  onClick={() => dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: cell.rowData.purchaseOrderDetailId })}
                              >
                                  <Tooltip title="Delete line">
                                      <DeleteOutlineOutlinedIcon
                                          className={clsx(classes.actionButtonSize, classes.deleteButton)}
                                      />
                                  </Tooltip>
                              </IconButton>
                          )}
                    </div>
                ),
            });
        }

        return columns;
    };

    useEffect(() => {
        if (!mountedRef) return;
        if (recordId > 0) {
            getRecords({
                variables: {
                    id: recordId,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    useEffect(() => {
        if (!mountedRef) return;
        if (po > 0) {
            getRecords({
                variables: {
                    id: Number(po),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [po]);

    useEffect(() => () => {
        mountedRef.current = false;
    }, []);

    useEffect(() => {
        if (state.purchaseOrderDetailId !== 0) {
            const rows = state.records.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.quantity-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.purchaseOrderDetailId]);

    const sumSubTotal = state.records.reduce((sum, next) => round(sum + next.subTotal, 2), 0);
    const sumTax = round(sumSubTotal * (taxRate / 100), 2);

    return (
        <div className={clsx(classes.flexContainer, classes.customHeight)}>
            <If condition={!(editDetails.allowAdd)}>
                <Header classes={{ toolbar: classes.toolbar }}>
                    <PurchaseOrderDetailSummary displayInline subTotal={sumSubTotal} tax={sumTax} />
                </Header>
            </If>
            <If condition={writePermissions && (editDetails.allowAdd)}>
                <Header classes={{ toolbar: classes.toolbar }}>
                    <>
                        {writePermissions && editDetails.allowAdd && PARTS_TICKET_WRITE_ADD_DELETE_PARTS && (
                            <div className={classes.buttonSpacing}>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddCircleOutlineIcon />}
                                    size="small"
                                    className={classes.buttonGreen}
                                    onClick={onAddNewLine}
                                >
                                    Add part
                                </Button>
                            </div>
                        )}
                    </>
                    <>
                        <PurchaseOrderDetailSummary subTotal={sumSubTotal} tax={sumTax} />
                    </>
                </Header>
            </If>
            <div className={clsx(classes.bottomTableHeight)}>
                <VirtualTable
                    loading={loading}
                    data={state.records}
                    columns={getColumns(editDetails)}
                />
            </div>
            {state.openPartsSelect && (
                <PartsSelect
                    open={state.openPartsSelect}
                    vendorId={vendorId}
                    onPopupClose={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: {
                            openPartsSelect: false,
                        },
                    })}
                    onSelectItems={(values) => addSelectedParts(values)}
                    lotName={lotName}
                />
            )}
            <ConfirmDialog
                title={state.deletingMultipleLines ? 'Confirm remove multiple lines' : 'Confirm remove line'}
                description={
                    state.deletingMultipleLines ? 'This line is part of the split process. \n Are you sure you want to remove all the lines associated.'
                        : 'Are you sure you want to remove this line?'
                }
                open={state.isModalDeleteOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
        </div>
    );
});

PurchaseOrderDetail.propTypes = {
    recordId: PropTypes.number,
    vendorId: PropTypes.number,
    taxRate: PropTypes.number,
    writePermissions: PropTypes.bool.isRequired,
    onDirty: PropTypes.func,
    editDetails: PropTypes.object,
    lotName: PropTypes.string,
    po: PropTypes.number,
    isReturn: PropTypes.bool,
};

PurchaseOrderDetail.defaultProps = {
    recordId: 0,
    vendorId: 0,
    taxRate: 0,
    onDirty: () => null,
    editDetails: {
        detailDescription: false,
        detailQuantity: false,
        detailUnitPrice: false,
        allowAdd: false,
        allowDelete: false,
    },
    lotName: '',
    po: 0,
    isReturn: false,
};

export default PurchaseOrderDetail;

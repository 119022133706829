export const GPS_STATUS = {
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
};

export const GPS_COMMAND = {
    REQUEST_LOCATION: 'REQUEST_LOCATION',
    TONE_1_ON: 'TONE_1_ON',
    TONE_2_ON: 'TONE_2_ON',
    TONE_3_ON: 'TONE_3_ON',
    TONE_OFF: 'TONE_OFF',
    DISABLE_STARTER_INTERRUPT: 'DISABLE_STARTER_INTERRUPT',
    ENABLE_STARTER_INTERRUPT: 'ENABLE_STARTER_INTERRUPT',
    REQUEST_LOCATION_HISTORY: 'REQUEST_LOCATION_HISTORY',
    REQUEST_COMMAND_HISTORY: 'REQUEST_COMMAND_HISTORY',
    GET_BATTERY_INFO: 'GET_BATTERY_INFO',
    SET_ENDURANCE_MODE: 'SET_ENDURANCE_MODE',
    SET_TRIP_MODE: 'SET_TRIP_MODE',
    SET_ACTIVE_MODE: 'SET_ACTIVE_MODE',
    SET_RECOVERY_MODE: 'SET_RECOVERY_MODE',
};

export const GPS_COMMAND_URL = {
    VALIDATE_DEVICE: '/ValidateDevice',
    GET_LOCALTION_HISTORY: '/GetLocationHistory',
    GET_LOCATION: '/GetLocation',
    GET_COMMAND_HISTORY: '/GetCommandHistory',
    SET_WARNING_TONE: '/SetWarningTone',
    SET_STARTER_INTERRUPT: '/SetStarterInterrupt',
    GET_ENCORE_BATTERY_NOTIFICATION: '/GetEncoreBatteryNotification',
    SET_ENCORE_MODE: '/SetEncoreMode',
};

/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import {
    makeStyles,
    FormLabel,
    FormControl,
    FormHelperText,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import VirtualTable from 'components/widgets/VirtualTable';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

// Icons
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            flexDirection: 'column',
        },
    },
    tableContainer: {
        width: '100%',
        height: '500px',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
        '& > div': {
            overflowY: 'hidden',
            overflowX: 'auto',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            marginLeft: '0px',
            paddingLeft: '5px',
            '& > div[class^="makeStyles-main"]': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '10px',
                },
            },
            '& > .react-datepicker-wrapper input': {
                textAlign: 'center',
                fontSize: '12px',
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    dialogActions: {
        paddingRight: '33px',
        '& > input': {
            width: '200px',
            [theme.breakpoints.down('sm')]: {
                width: '135px',
            },
        },
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& > button': {
            padding: 0,
            minWidth: '32px',
            '& > span > span': {
                margin: 0,
            },
        },
        '& > button:nth-child(1) svg': {
            fill: theme.palette.info.main,
        },
        '& > button:nth-child(2) svg': {
            fill: theme.palette.error.main,
        },
    },
}));

const VehicleMakeDropdown = ({
    make,
    onChange,
}) => {
    const invalidMake = StringUtils.isEmpty(make);
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        makeList: [],
        dealerMakes: [],
        newMake: null,
        selectedMake: null,
        isFormOpen: false,
        isAddingFieldShown: false,
        isDeletePromptShown: false,
    });

    const {
        makeList,
        dealerMakes,
        newMake,
        selectedMake,
        isFormOpen,
        isAddingFieldShown,
        isDeletePromptShown,
    } = state;

    const {
        data,
        error,
        loading,
        refetch: refetchMakeList,
    } = useQuery(InventoryQuery.GET_MAKES_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [getDealerMakes, { loading: loadingDealerMakes }] = useLazyQuery(InventoryQuery.GET_DEALER_SPECIFIC_MAKES, {
        onCompleted: (response) => {
            setState((prevState) => ({
                ...prevState,
                dealerMakes: (response.getDealerSpecificMakesList ?? []).sort((a, b) => a.name.localeCompare(b.name)),
                newMake: null,
                selectedMake: null,
                isAddingFieldShown: false,
                isDeletePromptShown: false,
            }));
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [
        updateVehicleMake, { loading: savingMake },
    ] = useMutation(InventoryMutation.UPDATE_VEHICLE_MAKE, {
        onCompleted: (response) => {
            if (response) {
                getDealerMakes();
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data) {
            const { getMakesList } = data;
            setState((prevState) => ({
                ...prevState,
                makeList: getMakesList,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const toggleForm = () => {
        setState((prevState) => ({
            ...prevState,
            isFormOpen: !prevState.isFormOpen,
        }));

        if (!isFormOpen) getDealerMakes();
        if (isFormOpen) refetchMakeList();
    };

    const cancelAdding = () => {
        setState((prevState) => ({
            ...prevState,
            newMake: null,
            isAddingFieldShown: false,
        }));
    };

    const prepareField = () => {
        setState((prevState) => ({
            ...prevState,
            isAddingFieldShown: true,
        }));
    };

    const addNewMake = () => {
        updateVehicleMake({
            variables: {
                name: newMake,
            },
        });
    };

    const onChangeMake = (value) => {
        setState((prevState) => ({
            ...prevState,
            newMake: value,
        }));
    };

    const cancelEdit = (record) => {
        const clone = cloneDeep(dealerMakes);
        const current = clone.find((item) => item.id === record.id);
        current.isEdited = false;

        setState((prevState) => ({
            ...prevState,
            newMake: null,
            dealerMakes: clone,
        }));
    };

    const prepareEdit = (record) => {
        const clone = cloneDeep(dealerMakes);
        clone.forEach((item) => {
            if (item.id === record.id) {
                item.isEdited = true;
            } else {
                item.isEdited = false;
            }
        });

        setState((prevState) => ({
            ...prevState,
            newMake: record.name,
            dealerMakes: clone,
        }));
    };

    const onEditMake = (id) => {
        updateVehicleMake({
            variables: {
                id,
                name: newMake,
            },
        });
    };

    const toggleDeletePrompt = (record) => {
        setState((prevState) => ({
            ...prevState,
            selectedMake: record,
            isDeletePromptShown: !prevState.isDeletePromptShown,
        }));
    };

    const onDeleteAction = () => {
        const { id, name } = selectedMake;

        updateVehicleMake({
            variables: {
                id,
                name,
                active: false,
            },
        });
    };

    const loadingInProgress = loadingDealerMakes || savingMake;
    const columns = [
        {
            label: 'Make',
            dataKey: 'name',
            width: 150,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { name, isEdited = false } = record;

                return isEdited ? (
                    <Form.Control
                        autoFocus
                        type="text"
                        size="sm"
                        value={newMake || ''}
                        maxLength={50}
                        onChange={({ target }) => onChangeMake(target.value)}
                    />
                ) : (
                    <div className={classes.tableCell}>{name}</div>
                );
            },
        },
        {
            label: 'Actions',
            dataKey: 'actions',
            width: 200,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { id, isEdited = false } = record;
                if (!id) return null;

                return (
                    <div className={classes.tableCell}>
                        <Button
                            disabled={loadingInProgress || isAddingFieldShown}
                            size="small"
                            startIcon={isEdited ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
                            onClick={() => (isEdited ? onEditMake(id) : prepareEdit(record))}
                        />
                        {!isEdited && (
                            <Button
                                disabled={loadingInProgress || isAddingFieldShown}
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleDeletePrompt(record)}
                            />
                        )}
                        {isEdited && (
                            <Button
                                size="small"
                                startIcon={<CloseOutlinedIcon />}
                                onClick={() => cancelEdit(record)}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    const anyBeingEdited = dealerMakes.some((m) => m.isEdited);
    return (
        <>
            <FormControl>
                <FormLabel>Make</FormLabel>
                <Select
                    name="make"
                    onChange={(_, newValue) => onChange('make', newValue)}
                    value={make}
                    options={[
                        ...makeList.map((item) => ({
                            value: item,
                            label: item,
                        })),
                        {
                            label: (
                                <Button
                                    className={classes.containedInfo}
                                    size="small"
                                    fullWidth
                                    onClick={toggleForm}
                                >
                                    Add / Edit
                                </Button>
                            ),
                            value: '-1',
                            isDisabled: true,
                        },
                    ]}
                    size="sm"
                    className={clsx({ 'invalid-field': invalidMake })}
                    loading={loading}
                />
                {invalidMake && (<FormHelperText error>The Make field is required</FormHelperText>)}
            </FormControl>
            {isFormOpen && (
                <Dialog
                    open
                    fullWidth
                    maxWidth="sm"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Makes"
                        onClose={toggleForm}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.content}>
                            <div className={classes.tableContainer}>
                                <VirtualTable
                                    loading={loadingDealerMakes}
                                    rowHeight={35}
                                    totalRecords={dealerMakes.length}
                                    data={dealerMakes}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {isAddingFieldShown && (
                            <Form.Control
                                autoFocus
                                type="text"
                                size="sm"
                                value={newMake || ''}
                                maxLength={50}
                                onChange={({ target }) => onChangeMake(target.value)}
                            />
                        )}
                        <Button
                            disabled={loadingInProgress || (isAddingFieldShown && StringUtils.isEmpty(newMake)) || anyBeingEdited}
                            size="small"
                            className={classes.containedSecondaryInfo}
                            onClick={() => (isAddingFieldShown ? addNewMake() : prepareField())}
                        >
                            {isAddingFieldShown ? 'Save' : 'Add'}
                        </Button>
                        {isAddingFieldShown && (
                            <Button
                                disabled={loadingInProgress}
                                size="small"
                                className={classes.containedError}
                                onClick={cancelAdding}
                            >
                                Cancel
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this make?"
                open={isDeletePromptShown}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={loadingInProgress}
                disableSecondaryButton={loadingInProgress}
            />
        </>
    );
};

VehicleMakeDropdown.propTypes = {
    make: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

VehicleMakeDropdown.defaultProps = {
    make: '',
};

export default VehicleMakeDropdown;

import * as yup from 'yup';

const {
    object, number, array, string,
} = yup;

const ServiceJobsItemSchema = object().shape({
    subletVendorId: number().when('isSublet', {
        is: (isSublet) => Boolean(isSublet) === true,
        then: number().required().moreThan(0, 'Sublet vendor is required'),
        otherwise: number().nullable(),
    }),
    payType: string().required('Pay type is required'),
    correction: string().required('Correction is required'),
    subletCost: number().when('isSublet', {
        is: (isSublet) => Boolean(isSublet) === true,
        then: number().required().moreThan(0, 'Sublet cost must be greater than 0'),
        otherwise: number(),
    }),
    subletPrice: number().when('isSublet', {
        is: (isSublet) => Boolean(isSublet) === true,
        then: number().required().moreThan(0, 'Sublet price must be greater than 0'),
        otherwise: number(),
    }),
    subletInvoice: string().when('isSublet', {
        is: (isSublet) => Boolean(isSublet) === true,
        then: string().required('Invoice is required'),
        otherwise: string().nullable(),
    }),
    flaggedHours: number().test(
        'required',
        'Flagged hours could not be greather than hours',
        // eslint-disable-next-line func-names
        function (flaggedHours) {
            const { hours } = this.parent;
            return !(flaggedHours > hours);
        },
    ),
});

const ServiceJobsSchema = array().of(
    ServiceJobsItemSchema,
);

export {
    ServiceJobsItemSchema,
    ServiceJobsSchema,
};

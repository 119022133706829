import { v1 as uuid } from 'uuid';

export default class ServiceMapper {
    static mapServiceInvoice(record, jobsOpenStatus = null) {
        if (record) {
            const cloneRecord = {
                ...record,
                customerName: `${record.customer.firstName} ${record.customer.lastName}`,
                customerAddress: record.customer.address,
                email: record.customer.email,
                cellPhone: record.customer.cellPhone,
                rentalCar: Boolean(record.rentalCar),
                estimateDelivery: record.estimateDelivery ? new Date(record.estimateDelivery) : null,
                jobs: record?.jobs?.map((c) => {
                    const currentJobStatus = jobsOpenStatus?.find((x) => x.jobNumber === c.jobNumber);
                    return {
                        ...c,
                        open: currentJobStatus?.open ?? false,
                        technicianImage: c.technicianImage || 'https://images.automatrix.com/1/HR/ProfilePicture/employeeDefault.jpg',
                        identifierJob: uuid(),
                        identifierJobsHeader: uuid(),
                        identifierJobsParts: uuid(),
                    };
                }) ?? [],
            };
            return cloneRecord;
        }

        return {};
    }

    static mapServiceJobs(records) {
        if (records) {
            return records.map((data) => ({
                ...data,
                description: data.description,
                laborTotal: Number(data.laborTotal),
                laborCost: Number(data.laborCost),
                partsTotal: Number(data.partsTotal),
                subletCost: Number(data.subletCost),
            }));
        }

        return [];
    }

    static mapServiceInvoiceToSave(record, jobs, inspectionList) {
        if (record) {
            const recordMap = {
                invoiceNumber: Number(record.invoiceNumber),
                customerCode: Number(record.customerCode),
                date: record.date,
                advisorId: Number(record.advisorId),
                advisor: record.advisor,
                milesIn: record.milesIn,
                milesOut: record.milesOut,
                saveParts: record.saveParts,
                shopSupplies: record.shopSupplies,
                fees: Number(record.fees),
                discount: Number(record.discount),
                cancellationFee: Number(record.cancellationFee),
                storageFee: Number(record.storageFee),
                discountPercentage: Number(record.discountPercentage),
                vehicleStock: record.vehicleStock,
                vehicleYear: record.vehicleYear,
                vehicleMake: record.vehicleMake,
                vehicleModel: record.vehicleModel,
                vehicleTrim: record.vehicleTrim,
                vehicleVin: record.vehicleVin,
                vehicleEngine: record.vehicleEngine,
                isTaxable: record.isTaxable,
                status: record.status,
                quickNote: record.quickNote,
                hatNumber: record.hatNumber,
                lotName: record.lotName,
                inHouse: record.inHouse,
                laborDiscount: record.laborDiscount,
                tag: record.tag,
                postedDate: record.postedDate,
                warrantyDeductable: Number(record.warrantyDeductable),
                vehicleStyle: record.vehicleStyle,
                lotId: Number(record.lotId),
                licensePlate: record.licensePlate,
                inspectionDone: record.inspectionDone,
                rentalCar: record.rentalCar,
                estimateDelivery: record?.estimateDelivery ?? '',
                jobs: this.mapServiceJobLines(jobs),
                inspectionList: this.mapInspectionList(inspectionList),
                hashRecord: record.hashRecord,
                userField1: record.userField1,
                customerWaiting: record.customerWaiting,
                customerPay: record.customerPay,
                surplusSalesTax: record.surplusSalesTax,
                overrideShopSuppliesFee: record.overrideShopSuppliesFee,
            };

            return recordMap;
        }

        return {};
    }

    static mapServiceJobLines(records) {
        if (records) {
            return records.map((data) => (
                {
                    invoiceNumber: Number(data.invoiceNumber),
                    jobNumber: Number(data.jobNumber),
                    customerStates: data.customerStates,
                    cause: data.cause,
                    correction: data.correction,
                    notes: data.notes,
                    technician: data.technician,
                    hours: Number(data.hours),
                    laborCost: Number(data.laborCost),
                    laborTotal: Number(data.laborTotal),
                    subletPrice: Number(data.subletPrice),
                    subletCost: Number(data.subletCost),
                    subletInvoice: data.subletInvoice,
                    payType: data.payType,
                    hourlyRate: data.hourlyRate,
                    isFlatRate: data.isFlatRate,
                    problemWhat: data.problemWhat,
                    problemWhen: data.problemWhen,
                    problemCause: data.problemCause,
                    laborOperationCode: String(data.laborOperationCode),
                    recommend1: data.recommend1,
                    discount: Number(data.discount),
                    approved: data.approved,
                    status: data.status,
                    estimatedHours: Number(data.estimatedHours),
                    actualHours: Number(data.actualHours),
                    requestedParts: data.requestedParts,
                    isHrsChecked: data.isHrsChecked,
                    subletVendorId: Number(data.subletVendorId),
                    technicianId: Number(data.technicianId),
                    flaggedHours: Number(data.flaggedHours),
                    overrideSubletPrice: data.overrideSubletPrice,
                    vehicleInspectionItemIds: data.vehicleInspectionItemIds,
                    payTypeId: Number(data.payTypeId),
                    isSublet: data.isSublet,
                    jobTypeId: data.jobTypeId,
                    serviceJobId: data.serviceJobId,
                    parts: this.mapJobPartsLines(data.parts),
                    warrantyCovered: data.warrantyCovered,
                    internalCovered: data.internalCovered,
                    customerPay: data.customerPay,
                    customerTax: data.customerTax,
                    partsTax: data.partsTax,
                    laborTax: data.laborTax,
                }
            ));
        }

        return [];
    }

    static mapJobPartsLines(records) {
        if (records) {
            return records.map((data) => (
                {
                    serviceJobPartsId: data.serviceJobPartsId,
                    invoiceNumber: Number(data.invoiceNumber),
                    jobNumber: Number(data.jobNumber),
                    partInventoryId: Number(data.partInventoryId),
                    partStockNumber: data.partStockNumber,
                    quantity: Number(data.quantity),
                    description: data.description,
                    listPrice: Number(data.listPrice),
                    netPrice: Number(data.netPrice),
                    partCost: Number(data.partCost),
                    isTaxable: Boolean(data.isTaxable),
                    partTaxAmount: Number(data.partTaxAmount),
                }
            ));
        }

        return [];
    }

    static mapInspectionList(records) {
        if (records) {
            return records.map((data) => (
                {
                    reconInspectionItemId: Number(data.reconInspectionItemId),
                    name: data.name,
                    pass: data.pass,
                    fail: data.fail,
                    repair: data.repair,
                    soon: data.soon,
                    comment: data.comment,
                    jobTypesIds: data.jobTypes,
                    pictures: data.pictures,
                }
            ));
        }

        return [];
    }

    static mapOperationCodeToSave(record) {
        if (record) {
            const recordMap = {
                opCodeId: Number(record.opCodeId),
                opCode: String(record.opCode),
                customerStates: String(record.customerStates),
                cause: String(record.cause),
                correction: String(record.correction),
                note: String(record.note),
                hours: Number(record.hours),
                laborAmount: Number(record.laborAmount),
                detail: this.mapOperationCodeDetailToSave(record.detail),
            };

            return recordMap;
        }

        return {};
    }

    static mapOperationCodeDetailToSave(records) {
        if (records) {
            return records.map((data) => (
                {
                    jobTemplateDetailId: Number(data.jobTemplateDetailId),
                    jobTemplateId: Number(data.jobTemplateId),
                    partInventoryId: Number(data.partInventoryId),
                    quantity: Number(data.quantity),
                    partStockNumber: String(data.partStockNumber),
                }
            ));
        }

        return [];
    }

    static mapOperationCode(record) {
        if (record) {
            const cloneRecord = {
                ...record,
                detail: record?.detail?.map((c) => ({
                    jobTemplateDetailId: Number(c.jobTemplateDetailId),
                    jobTemplateId: Number(c.jobTemplateId),
                    quantity: Number(c.quantity),
                    partInventoryId: Number(c.partInventoryId),
                    partStockNumber: String(c.partsInventory.partStockNumber),
                    description: String(c.partsInventory.description),
                    partCost: Number(c.partsInventory.partCost),
                    listPrice: Number(c.partsInventory.listPrice),
                    netPrice: Number(c.partsInventory.netPrice),
                })) ?? [],
            };
            return cloneRecord;
        }

        return {};
    }
}

import { gql } from '@apollo/client';

// Task in progress
export default class CustomerSubscription {
    static CUSTOMER_EDITED = gql`
        subscription customerEdited($customerId: ID!) {
            customerEdited(customerId: $customerId) {
                customerCode
                customerId
                firstName
                middleName
                lastName
                cellPhone
                workPhone
                homePhone
                email
                dob
                ssn
                dln
                isBusiness
                allowCallCellPhone
                allowTextCellPhone
                allowCallHomePhone
                allowCallWorkPhone
                isTaxable
                language
                currentAddress {
                    customerAddressId
                    customerId
                    parentId
                    address1
                    address2
                    housingStatus
                    mortgageOrRent
                    start
                    end
                    totalMonths
                    phone
                    city
                    state
                    county
                    zipCode
                    country
                }
                currentEmployment {
                    customerId
                    customerEmploymentId
                    status
                    employer
                    title
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    county
                    zipCode
                    country
                    start
                    totalMonths
                }
            }
        }
    `;

    static CUSTOMER_LIST = gql`
        subscription {
            customerList {
                type
                data {
                    customer {
                        customerCode
                        firstName
                        lastName
                        cellPhone
                        sysDate
                        cellPhone
                        email
                    }
                    totalCount
                }
            }
        }
    `;

    static CUSTOMER_ADDRESS_LIST = gql`
        subscription customerAddressList($customerId: ID!) {
            customerAddressList(customerId: $customerId) {
                type
                data {
                    customerId
                    customerAddressId
                    address1
                    address2
                    county
                    country
                    state
                    zipCode
                    city
                    phone
                    isCurrentAddress
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    start
                    end
                    createdOn
                }
            }
        }
    `;

    static CUSTOMER_EMPLOYMENT_LIST = gql`
        subscription customerEmploymentListChanged($customerId: ID!) {
            customerEmploymentListChanged(customerId: $customerId) {
                type
                customerEmployment {
                    customerId
                    customerEmploymentId
                    parentId
                    status
                    employer
                    title
                    start
                    end
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    county
                    zipCode
                    country
                    createdOn
                    createdByUserId
                    modifiedOn
                    modifiedByUserId
                    totalMonths
                    isCurrentEmployment
                }
            }
        }
    `;
}

import React, { useState } from 'react';
import {
    Badge, makeStyles, Popover,
} from '@material-ui/core';
import { ErrorOutlineOutlinedIcon } from 'components/icons';
import PropTypes from 'prop-types';

const ownStyle = makeStyles((theme) => ({
    iconSize: {
        fontSize: '15px',
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    headerErrors: {
        fontWeight: 'bold',
        color: '#dc3545',
        display: 'flex',
        alignItems: 'center',
    },
    fontSmall: {
        fontSize: '12px',
    },
    typographyMessage: {
        fontSize: '14px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '-0.05px',
    },
}));

const ErrorMessageIndicator = (props) => {
    const { messages, children } = props;

    const classes = {
        ...ownStyle(),
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const {
        popover, paper, iconSize, headerErrors,
        fontSmall,
    } = classes;

    return (
        <Badge
            badgeContent={<ErrorOutlineOutlinedIcon className={iconSize} />}
            color="error"
            invisible={messages?.length === 0}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            {messages.length > 0 && (
                <Popover
                    className={popover}
                    classes={{
                        paper,
                    }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className={classes.typographyMessage}>
                        <span className={headerErrors}>
                            Please check all required fields.
                            {'  '}
                            <ErrorOutlineOutlinedIcon className={iconSize} />
                        </span>
                        <ul>
                            {
                                messages.map((c, i) => (
                                    <li className={fontSmall} key={i}>
                                        {' '}
                                        &#x2022;
                                        {' '}
                                        {c}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </Popover>
            )}
            {children}
        </Badge>
    );
};

ErrorMessageIndicator.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
};

export default ErrorMessageIndicator;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NumberUtils from 'lib/NumberUtils';
import StringUtils from 'lib/StringUtils';
import { CarGurusIcon } from 'components/icons/index';

const CarGurusBox = ({
    info,
}) => {
    const isInfoEmpty = Object.keys(info).length === 0;
    const useStyles = makeStyles((theme) => ({
        container: {
            width: '97%',
            marginLeft: '15px',
            marginRight: '25px',
            borderRadius: '2px',
            border: `1px solid ${theme.palette.border.mercuryLight}`,
            padding: '5px',
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginLeft: '10px',
                marginRight: '15px',
            },
        },
        header: {
            width: '300px',
            flexShrink: 0,
            '& > svg': {
                width: '90px',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                display: 'flex',
                height: '30px',
                width: 'initial',
                flexShrink: 'initial',
            },
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: isInfoEmpty ? 'center' : 'space-between',
            fontSize: '13px',
            color: theme.palette.text.darkBlue,
            '& > div:nth-child(1) > span': {
                fontWeight: 'bold',
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '150px',
                height: '100px',
                flexGrow: 'initial',
            },
        },
        element: {
            '& > span:nth-child(2)': {
                marginLeft: '7px',
                color: theme.palette.text.tundora,
            },
            [theme.breakpoints.down('sm')]: {
                '& > span:nth-child(1)': {
                    display: 'inline-block',
                    width: '50px',
                    textAlign: 'right',
                },
            },
        },
    }));

    const classes = useStyles();
    const { rating, ratingBoundaries } = info;
    const ratingInLowerCase = rating?.split('_')[0]?.toLowerCase();
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <CarGurusIcon />
            </div>
            <div className={classes.content}>
                {!isInfoEmpty && (
                    <>
                        <div className={classes.element}>
                            <span>Rating:</span>
                            <span>{StringUtils.toPascalCase(ratingInLowerCase === 'poor' ? 'high' : ratingInLowerCase)}</span>
                        </div>
                        <div className={classes.element}>
                            <span>Great:</span>
                            <span>{NumberUtils.applyThousandsFormat(ratingBoundaries?.greatPrice)}</span>
                        </div>
                        <div className={classes.element}>
                            <span>Good:</span>
                            <span>{NumberUtils.applyThousandsFormat(ratingBoundaries?.goodPrice)}</span>
                        </div>
                        <div className={classes.element}>
                            <span>Fair:</span>
                            <span>{NumberUtils.applyThousandsFormat(ratingBoundaries?.fairPrice)}</span>
                        </div>
                        <div className={classes.element}>
                            <span>High:</span>
                            <span>{NumberUtils.applyThousandsFormat(ratingBoundaries?.highPrice)}</span>
                        </div>
                    </>
                )}
                {isInfoEmpty && (
                    <div className={classes.element}>
                        No Price Analysis
                    </div>
                )}
            </div>
        </div>
    );
};

CarGurusBox.propTypes = {
    info: PropTypes.object.isRequired,
};

export default CarGurusBox;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsQuery from 'services/graphQL/query/DealsQuery';

const CommissionsDialogContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        applyAmountFinancedValue: PropTypes.func.isRequired,
        portfolioId: PropTypes.number.isRequired,
        contractId: PropTypes.number.isRequired,
        docStamp: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();

        this.state = {
            portfolioList: [],
            contractList: [],
            portfolioId: 0,
            contractId: 0,
        };

        this.initBind();
    }

    componentDidMount() {
        const { props: { portfolioId, contractId } } = this;

        this.setState({
            portfolioId,
            contractId,
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value || null,
        });
    }

    onSave() {
        const { props, state: { contractId, portfolioId } } = this;

        props.applyAmountFinancedValue(portfolioId, contractId);
    }

    setDefaultContract() {
        const { state: { contractId, contractList } } = this;

        if (contractId === null) {
            const contract = contractList.find((item) => item.isDefault);

            if (contract) {
                this.setState({ contractId: contract.loanContractTemplateId });
            }
        }
    }

    fetchPortfolios() {
        this.graphqlClient
            .query(DealsQuery.GET_PORTFOLIOS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.listPortfolios) {
                    this.setState({
                        portfolioList: data.listPortfolios,
                    });
                }
            });
    }

    fetchContracts() {
        this.graphqlClient
            .query(DealsQuery.GET_CONTRACTS)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getLoanContractTemplateList) {
                    this.setState({
                        contractList: data.getLoanContractTemplateList,
                    }, () => this.setDefaultContract());
                }
            });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.fetchPortfolios = this.fetchPortfolios.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                fetchContracts={this.fetchContracts}
                fetchPortfolios={this.fetchPortfolios}
                onChange={this.onChange}
            />
        );
    }
};

export default CommissionsDialogContainer;

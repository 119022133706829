import { concat } from 'lodash';

export const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    ADD_NEW_LINE: 'addNewLine',
    SET_ON_DELETE: 'setOnDelete',
    SET_RECORD_VALUE: 'setRecordValue',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_AFTER_DELETE_RECORDS: 'setAfterDeleteRecords',
};

const CatalogListReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS: {
        return {
            ...state,
            records: action.value,
            lineId: action.value.length,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item.enumValuesId === action.cell.original.enumValuesId) {
                newItem[action.columnId] = action.value;
                newItem.dirty = true;
            }

            return newItem;
        });

        return {
            ...state,
            records: newRecords,
            isDirty: true,
        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const newLine = {
            lineId: state.lineId + 1,
            enumValuesId: (state.lineId + 1) * -1,
            description: '',
            dirty: true,
        };
        const data = concat(state.records, newLine);
        return {
            ...state,
            records: data,
            lineId: newLine.lineId,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
        };
    }
    case ACTION_TYPES.SET_RECORD_VALUE: {
        return { ...state, [action.value.key]: action.value.value };
    }
    case ACTION_TYPES.SET_AFTER_DELETE_RECORDS: {
        return {
            ...state,
            records: action.value,
            idToDelete: 0,
        };
    }
    default:
        return state;
    }
};

export default CatalogListReducer;

import React, { Component } from 'react';

// Components and Ohters
import moment from 'moment';
import PropTypes from 'prop-types';
import { clone, isFinite, pullAllBy } from 'lodash';
import update from 'immutability-helper';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';

const DeferredDownPaymentContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        deferredPayments: PropTypes.arrayOf(PropTypes.object),
        setDeferredDownPayment: PropTypes.func,
        accountNumber: PropTypes.number,
    };

    static defaultProps = {
        deferredPayments: {
            accountNumber: null,
            paymentAmount: null,
            paymentDate: moment().startOf('day').toDate(),
        },
        accountNumber: null,
        setDeferredDownPayment: () => {},
    }

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        const newDeferedDowPayment = props.deferredPayments && props.deferredPayments.length >= 1 ? props.deferredPayments : [this.getNewDeferredPaymentLine()];

        this.state = {
            deferredPayments: newDeferedDowPayment,
            openConfirm: false,
            itemToDelete: null,
            deferredDownPaymentId: null,
            deferredDownPaymentToRemove: [],
        };

        this.initBind();
    }

    onChangeValue(field, value, index) {
        this.setState((prevState) => ({
            deferredPayments: update(prevState.deferredPayments, {
                [index]: {
                    [field]: { $set: value },
                },
            }),
        }));
    }

    onApply() {
        const { props: { setDeferredDownPayment }, state: { deferredPayments, deferredDownPaymentToRemove } } = this;

        if (Array.isArray(deferredPayments)) {
            // This validation was added because when there is only one deferredDownPayment it cannot be removed from the UI and remove it when the amount is 0
            if (deferredPayments.length === 1 && (!deferredPayments[0].paymentAmount || deferredPayments[0].paymentAmount === 0)) {
                const { deferredDownPaymentId } = deferredPayments[0];

                if (deferredDownPaymentId) {
                    deferredDownPaymentToRemove.push(deferredDownPaymentId);
                }

                pullAllBy(deferredPayments, [{ deferredDownPaymentId }], 'deferredDownPaymentId');
            }
        }

        if (typeof setDeferredDownPayment === 'function') setDeferredDownPayment(deferredPayments, deferredDownPaymentToRemove);
    }

    onDelete() {
        const {
            state: {
                deferredPayments, itemToDelete, deferredDownPaymentId, deferredDownPaymentToRemove,
            },
        } = this;
        const backUp = clone(deferredPayments);

        if (isFinite(deferredDownPaymentId)) deferredDownPaymentToRemove.push(deferredDownPaymentId);
        backUp.splice(itemToDelete, 1);

        this.setState({
            deferredPayments: backUp,
            openConfirm: false,
            deferredDownPaymentId: null,
        });
    }

    getNewDeferredPaymentLine() {
        const { props: { accountNumber } } = this;

        return {
            accountNumber,
            paymentAmount: null,
            paymentDate: moment().startOf('day').toDate(),
        };
    }

    showConfirm(index, deferredDownPaymentId) {
        this.setState({
            openConfirm: true,
            itemToDelete: index,
            deferredDownPaymentId,
        });
    }

    cancelConfirm() {
        this.setState({
            openConfirm: false,
            itemToDelete: null,
            deferredDownPaymentId: null,
        });
    }

    addRow() {
        const { state: { deferredPayments } } = this;
        const backUp = clone(deferredPayments);
        backUp.push(this.getNewDeferredPaymentLine());

        this.setState({
            deferredPayments: backUp,
        });
    }

    initBind() {
        this.addRow = this.addRow.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.cancelConfirm = this.cancelConfirm.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.getNewDeferredPaymentLine = this.getNewDeferredPaymentLine.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                addRow={this.addRow}
                onApply={this.onApply}
                onDelete={this.onDelete}
                showConfirm={this.showConfirm}
                cancelConfirm={this.cancelConfirm}
                onChangeValue={this.onChangeValue}
            />
        );
    }
};

export default DeferredDownPaymentContainer;

import { gql } from '@apollo/client';

export default class MenuQuery {
    static GET_USER_SETTINGS = gql`
        query getUserSettings {
            getUserSettings {
                id
                pricingVisibility
                packageDistribution
                saveSettings
                products {
                    id
                    productId
                    available
                    order
                }
            }
        }
    `;

    static PULL_ACTIVE_PRODUCTS = gql`
        query pullActiveProducts {
            pullActiveProducts {
                id
                productName
                productType
                isLine1
                vendor {
                    vendorId
                    vendorName
                    vendorNotes
                    provider
                }
                details {
                    id
                    providerProductId
                    providerProductCode
                    description
                    bulletPoints
                    isTaxable
                    notRegulated
                    pdfContract
                }
            }
        }
    `;

    static DECODE_VEHICLE_TRIMS = gql`
        query decodeVehicleTrims($vendorId: Int!, $dealNumber: Int!) {
            decodeVehicleTrims(vendorId: $vendorId, dealNumber: $dealNumber)
        }
    `;

    static GET_RATES = gql`
        query getRates($vendorId: Int!, $dealNumber: Int!, $trim: String) {
            getRates(vendorId: $vendorId, dealNumber: $dealNumber, trim: $trim) {
                quoteId
                plans {
                    productTypeCode
                    productTypeDescription
                    planCode
                    planDescription
                    contractType
                    planId
                    rateBook
                    ownershipTypeCode
                    programDescription
                    programID
                    formNo
                    newUsed
                    terms {
                        termId
                        term
                        mileage
                        deductible {
                            id
                            amount
                            type
                            reduceAmount
                        }
                        rate {
                            rateId
                            retailRate
                            netRate
                            maxRetailRate
                            minRetailRate
                            regulatedRuleId
                            markupMin
                            markupMax
                        }
                        options {
                            optionId
                            description
                            retailRate
                            netRate
                            isSurcharge
                        }
                    }
                    additionalContractData {
                        fieldOrder
                        fieldLabel
                        fieldType
                        length
                        required
                        unique
                    }
                }
                manufactureWarranties {
                    type
                    term
                    mile
                }
            }
        }
    `;

    static PULL_MENU_CONTRACTS = gql`
        query pullMenuContracts(
            $dealNumber: Int,
            $paginate: DataPaging,
            $sort: DataSort!,
            $search: String
        ) {
            pullMenuContracts(
                dealNumber: $dealNumber,
                paginate: $paginate,
                sort: $sort,
                search: $search
            ) {
                menuContractId
                contractNumber
                expirationDate
                expirationOdometer
                documentPDF
                dealNumber
                vin
                product {
                    productId
                    productName
                    productType
                    isLine1
                    vendorDealProduct {
                        vendorId
                        vendorName
                        provider
                        menuVendordetail {
                            menuVendorDetailId
                            allowVoidContracts
                            allowMultipleContractsSubmit
                        }
                    }
                }
                netCost
                retailCost
                term
                status
                createdOn
                createdBy
            }
        }
    `;

    static PULL_EXTERNAL_PROVIDERS = gql`
        query pullExternalProviders {
            pullExternalProviders {
                providerId
                providerName
            }
        }
    `;

    static PULL_EXTERNAL_PRODUCTS = gql`
        query pullExternalProducts($providerId: Int!) {
            pullExternalProducts(providerId: $providerId) {
                productId
                productName
                productCode
            }
        }
    `;

    static GET_PRODUCT_PRERATE = gql`
        query getProductPreRate($productId: Int!, $dealNumber: Int!) {
            getProductPreRate(productId: $productId, dealNumber: $dealNumber) {
                surchargeCode
                dataType
                prompt
                surchargeType
                surchargeOptions
                value
            }
        }
    `;

    static GET_PRODUCT_RATE = gql`
        query getProductRate($productId: Int!, $dealNumber: Int!, $surchargesInput: [surchargeInput!]) {
            getProductRate(productId: $productId, dealNumber: $dealNumber, surchargesInput: $surchargesInput) {
                productCode
                providerId
                productId
                productName
                multipleAllowed
                coverages {
                    name
                    terms {
                        value
                        mileages {
                            value
                            deductibles {
                                value
                                retailPrice
                                dealerCost
                                remitPrice
                                contractFormID
                                rateResponseID
                                minPrice
                                maxPrice
                                serviceInterval
                                tireRotations
                                surcharges {
                                    surchargeCode
                                    value
                                    price
                                    cost
                                    description
                                    isEditable
                                }
                            }
                        }
                    }
                }
                surcharges {
                    surchargeCode
                    dataType
                    prompt
                    surchargeType
                    surchargeOptions
                    value
                }
                formFields {
                    formName
                    fieldName
                    dataType
                    prompt
                    fieldOptions
                    required
                }
            }
        }
    `;
}

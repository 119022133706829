/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

// Reactbootstrap
import { InputGroup, FormControl } from 'react-bootstrap';

import StringUtils from 'lib/StringUtils';
import InputSearchStyle from 'styles/widgets/InputSearchStyle';

const useStyles = makeStyles((theme) => InputSearchStyle.input(theme));

const InputSearch = (props) => {
    const {
        size,
        initialSearch,
        placeholder,
        customClasses,
        executeWhenClearButton,
    } = props;
    const [search, setSearch] = useState(initialSearch);
    const classes = useStyles();

    const onChangeInputSearch = (event) => {
        const { target: { value = '' } } = event;

        setSearch(value);
    };

    useEffect(() => {
        if (StringUtils.isEmpty(initialSearch)) {
            setSearch('');
        }
    }, [initialSearch]);

    const onSearch = () => {
        if (props.forceSearch) {
            props.onSearch(search);
            return;
        }

        if (search !== initialSearch) {
            props.onSearch(search);
        }
    };

    const onKeyDownInput = (event) => {
        event.stopPropagation();

        if (event.keyCode === 13) {
            event.preventDefault();

            onSearch();
        }
    };

    const clearSearch = () => {
        setSearch('');
        if (search === initialSearch) {
            props.onSearch('');
        }

        if (executeWhenClearButton) {
            props.executeWhenClearButton();
        }
    };

    const isEmptyInputSearch = !StringUtils.isEmpty(search);

    return (
        <InputGroup className={clsx(customClasses, classes.inputSearchContainer)}>
            <div className={classes.inputIcon}>
                <FormControl
                    size={size}
                    value={search}
                    aria-label="Search"
                    placeholder={placeholder}
                    onKeyDown={onKeyDownInput}
                    onChange={onChangeInputSearch}
                    aria-describedby="basic-addon1"
                />
                {isEmptyInputSearch && (
                    <CancelIcon
                        onClick={clearSearch}
                        className={classes.clearSearch}
                    />
                )}
                <Button
                    onClick={onSearch}
                >
                    <SearchIcon />
                </Button>
            </div>
        </InputGroup>
    );
};

InputSearch.propTypes = {
    size: PropTypes.string,
    forceSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    initialSearch: PropTypes.string,
    customClasses: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    executeWhenClearButton: PropTypes.func,
};

InputSearch.defaultProps = {
    size: null,
    initialSearch: '',
    customClasses: '',
    forceSearch: false,
    placeholder: 'Enter text to search',
    executeWhenClearButton: () => {},
};

export default InputSearch;
